import { useMemo } from 'react';
import { Cart } from 'models/Cart';

export const useTotalItemCount = (cart?: Cart) => {
  return useMemo(
    () =>
      cart?.Cart?.reduce((cur, item) => {
        const itemCount = item.WgtCnt === 'Qty' ? item.QtySelected : 1;
        return cur + itemCount;
      }, 0) ?? 0,
    [cart]
  );
};
