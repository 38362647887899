import { useDispatch } from "react-redux";
import { checkManagerPin } from 'util/Helpers';
import { popCashDrawer } from 'store/actions/SettingsActions';
import { errorNotification } from "store/actions/NotificationsActions";

/** @deprecated - use popCashDrawer() method on ReceiptPrinter from @dutchie/capacitor-hardware */
export const useCashDrawerDeprecated = () => {
  const dispatch = useDispatch();

  const openCashDrawer = async (managerPassword: string) => {
      try {
        await checkManagerPin(managerPassword);
        dispatch(popCashDrawer());
      } catch (err) {
        if (err instanceof Error) {
          dispatch(errorNotification(err.message));
        } else {
          dispatch(errorNotification('Error when opening cash drawer'));
        }
      }
    };

  return {
    openCashDrawer,
  };
};
