import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { getAllProductsKeys } from './query-key-factory';
import { transformToProductSearchResult } from './types';
import { customEventKeys, logger } from 'util/logger';
import { ProductsLoadedContext } from 'util/logger/types/products';

import type { GetAllProductsServerResponse, ProductSearchResult } from './types';

const GET_ALL_PRODUCTS = 'v2/product/product_SearchV2';

export type GetAllProductsPayload = {
  customerId?: number;
  registerId?: number;
  searchString?: string;
};

const transformToServerPayload = (payload: GetAllProductsPayload) => {
  return {
    CustomerId: payload.customerId,
    Register: payload.registerId,
    SearchString: payload.searchString,
  };
};

type GetAllProductsOptions<TData = ProductSearchResult[]> = Partial<
  Omit<UseQueryOptions<ProductSearchResult[], unknown, TData>, 'select'>
> & {
  select?: (products: ProductSearchResult[]) => TData;
};

export const useGetAllProductsQuery = <TData = ProductSearchResult[]>(
  payload: GetAllProductsPayload,
  options?: GetAllProductsOptions<TData>
) => {
  return useQuery({
    queryKey: [getAllProductsKeys.all, { ...payload, customerId: payload.customerId || 0 }],
    queryFn: async () => {
      const response = await post<GetAllProductsServerResponse[]>(GET_ALL_PRODUCTS, transformToServerPayload(payload));

      logger.debug<ProductsLoadedContext>(
        `[ReactQuery] Products were loaded for ${payload.customerId ? payload.customerId : 'all customers'}`,
        {
          key: customEventKeys.productsList.productsLoaded,
          payload,
          queryKey: getAllProductsKeys.all.toString(),
        }
      );

      return response.map(transformToProductSearchResult);
    },
    cacheTime: 0,
    enabled: !!payload.customerId,
    ...options,
  });
};
