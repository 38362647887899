import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { colors } from 'css/Theme';
import {
  removeNotification,
  removeAllNotifications,
  successNotificationInternal,
  warningNotificationInternal,
  errorNotification,
} from 'store/actions/NotificationsActions';

export enum NotificationType {
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

type Notification = {
  id: string;
  type: NotificationType;
  color: string;
  message: string;
};

export type NotificationsState = Array<Notification>;

const initialState: NotificationsState = [];

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(removeNotification, (state, action) =>
    state.filter((item: Notification) => item.id !== action.payload)
  );
  builder.addCase(successNotificationInternal, (state, action) => [
    ...state,
    { id: action.payload.id, type: NotificationType.Success, color: colors.success, message: action.payload.message },
  ]);
  builder.addCase(warningNotificationInternal, (state, action) => [
    ...state,
    { id: action.payload.id, type: NotificationType.Warning, color: '#ffb300', message: action.payload.message },
  ]);
  builder.addCase(errorNotification, (state, action) => [
    ...state,
    { id: uuidv4(), type: NotificationType.Error, color: colors.red, message: action.payload },
  ]);
  builder.addCase(removeAllNotifications, () => []);
});
