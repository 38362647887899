import { ActionReducerMapBuilder, createSlice, createSelector } from '@reduxjs/toolkit';
import { filterAndSortGuests, searchAndFilter as _searchAndFilter } from 'util/Helpers';
import { State } from 'store';

import {
  CheckedInGuest,
  GuestStatus,
  GuestFilterOption,
  Zone,
  GuestListSortOptions,
  PusherConfiguration,
  CardStatusDisplayOptionsResponse,
} from 'models/Guest';
import { SettingsState } from './SettingsReducer';
import { Register } from 'models/Misc';
import { sortOrdersByStatus } from 'pages/GuestListPage/components/kanban/BoardView/useOrdersByStatus';
import {
  setGuestStatus,
  joinGroup,
  getGuestStatuses,
  getZones,
  getGuests,
  updateGuestList,
  createAnonymousTransactionWithDemographics,
  setGuestSearchQuery,
  clearGuestSearchQuery,
  setGuestFilterQuery,
  clearGuestFilters,
  applyCustomersFilters,
  applyStatusFilters,
  applyCustomersSort,
  setShowEmptyStatusColumns,
  notifyConectionLost,
  getPusherConfiguration,
  notifyPusherConnected,
  cleanPusherConfiguration,
  loadCardStatusDisplayOptions,
  addOrUpdateGuest,
  removeGuestFromList,
  removeGuestByTransactionIdFromList,
} from 'store/actions/GuestListActions';

type UpdateGuestListAction = {
  payload: {
    response: Array<CheckedInGuest>;
    settings: SettingsState;
  };
};

export type GuestListState = {
  guests: Array<CheckedInGuest>;
  loadingGuests: boolean;
  filterOptions: Array<GuestFilterOption>;
  filterSelections: Array<GuestFilterOption>;
  allStatuses: Array<GuestStatus>;
  statuses: Array<GuestStatus>;
  loadingStatuses: boolean;
  noStatusesConfigured: boolean;
  nonEmptyStatuses: Array<string>;
  zones: Array<Zone>;
  filterQuery: string;
  query: string;
  submitting: boolean;
  showEmptyStatusColumns: boolean;
  pusherConnected: boolean;
  pusherConfiguration: PusherConfiguration;
  sortOptions: GuestListSortOptions;
  cardStatusDisplayOptions: Array<CardStatusDisplayOptionsResponse>;
  version: string | undefined;
};

export const initialState: GuestListState = {
  guests: [],
  loadingGuests: true,
  filterOptions: [],
  filterSelections: [],
  allStatuses: [],
  statuses: [],
  loadingStatuses: false,
  noStatusesConfigured: false,
  filterQuery: '',
  query: '',
  submitting: false,
  zones: [],
  nonEmptyStatuses: [],
  showEmptyStatusColumns: false,
  pusherConnected: false,
  pusherConfiguration: {
    ChannelName: '',
    PusherCluster: '',
    PusherKey: '',
  },
  sortOptions: {
    timeReceivedSortAscending: true,
    timeExpectedSortAscending: false,
    alphaSortAscending: false,
    isAlphaSort: false,
  },
  cardStatusDisplayOptions: [],
  version: undefined,
};

const searchAndFilter = (
  allGuests: Array<CheckedInGuest>,
  filters: Array<GuestFilterOption>,
  searchQuery: string
): Array<CheckedInGuest> => {
  const keysToSearch: Array<keyof CheckedInGuest> = ['FullName', 'TransactionReference', 'MJStateIDNo'];

  return _searchAndFilter<CheckedInGuest>(allGuests, filters, keysToSearch, searchQuery);
};

const filterStatuses = (allStatuses: Array<GuestStatus>, filters: Array<GuestFilterOption>): Array<GuestStatus> => {
  //grabs status related filters only (there are other non-status filters)
  const selectedFilters = filters?.find(({ key }) => key === 'TransactionStatus')?.options;

  return allStatuses.filter((status) => {
    if (selectedFilters && selectedFilters.length > 0) {
      return selectedFilters.includes(status.POSStatus);
    } else {
      return status;
    }
  });
};

const updateGuestListInState = (state: GuestListState, action: UpdateGuestListAction) => {
  const allGuests: Array<CheckedInGuest> = action.payload.response;
  const roomsOptionsSet = new Set<CheckedInGuest['Room']>();
  const statusOptionsSet = new Set<CheckedInGuest['TransactionStatus']>();
  const carOptionsSet = new Set<CheckedInGuest['CarName']>();
  const orderSourceSet = new Set<CheckedInGuest['OrderSource']>();

  if (state.showEmptyStatusColumns) {
    state.statuses.forEach(({ POSStatus }) => {
      statusOptionsSet.add(POSStatus);
    });
  }

  allGuests.forEach((g) => {
    if (g.Room?.length) {
      roomsOptionsSet.add(g.Room);
    }
    if (g.TransactionStatus?.length) {
      statusOptionsSet.add(g.TransactionStatus);
    }
    if (g.CarName?.length) {
      carOptionsSet.add(g.CarName);
    }
    if (g.OrderSource?.length) {
      orderSourceSet.add(g.OrderSource);
    }
  });

  const filterOptions: Array<GuestFilterOption> = [
    { label: 'Rooms', key: 'Room', options: [...roomsOptionsSet] },
    { label: 'Status', key: 'TransactionStatus', options: [...statusOptionsSet] },
    { label: 'Source', key: 'OrderSource', options: [...orderSourceSet] },
  ];

  if (action.payload.settings.features.LocationBasedDelivery && carOptionsSet.size) {
    filterOptions.push({ label: 'Cars', key: 'CarName', options: [...carOptionsSet] });
  }

  const currentGuestlistVersion = JSON.stringify(allGuests);
  if (currentGuestlistVersion !== state.version) {
    state.guests = allGuests;
    state.filterOptions = filterOptions;
    state.nonEmptyStatuses = [...statusOptionsSet];
    state.version = currentGuestlistVersion;
  }
};

function checkedInGuestsCombiner(
  guests: CheckedInGuest[],
  statuses: GuestStatus[],
  sortOptions: GuestListSortOptions,
  onlyShowGuestsAssignedToRegister: boolean,
  selectedRegister: Register | undefined
): CheckedInGuest[] {
  if (guests && statuses) {
    return filterAndSortGuests({
      guests,
      onlyShowGuestsAssignedToRegister,
      statuses,
      selectedRegister,
      sortOptions,
    });
  }

  return [];
}

const filterQuerySelector = (state: State) => state.guestList.filterQuery;
const filterSelectionsSelector = (state: State) => state.guestList.filterSelections;
const guestSelector = (state: State) => state.guestList.guests;
const statusesSelector = (state: State) => state.guestList.statuses;
const sortOptionsSelector = (state: State) => state.guestList.sortOptions;
const showGuestsSelector = (state: State) => state.settings.userSettings.showGuests;
const selectedRegisterSelector = (state: State) => state.settings.selectedRegister;
const defaultPosStatusSelector = (state: State) => state.settings.defaultStatuses?.DefaultPosStatus;

/**
 * Returns a filtered subset of `guests` based on search string and filter selections.
 */
export const displayGuestsSelector = createSelector(
  [guestSelector, filterSelectionsSelector, filterQuerySelector],
  (guests, filterSelections, filterQuery) => searchAndFilter(guests, filterSelections, filterQuery)
);

/**
 * Returns a filtered, sorted, and transformed subset of `displayGuests` specific for the guestList page
 */
export const checkedInGuestsSelector = createSelector(
  [displayGuestsSelector, statusesSelector, sortOptionsSelector, showGuestsSelector, selectedRegisterSelector],
  checkedInGuestsCombiner
);

/**
 * Returns an object mapping of checkedInGuests to their statuses.
 *
 * Example:
 * {
 *   'Pending': [ { Status: 'Pending', ... }, { Status: 'Pending', ... } ],
 *   'Awaiting Delivery': [ { Status: 'Awaiting Delivery', ... }, { Status: 'Awaiting Delivery', ... } ],
 * }
 */
export const ordersByStatusSelector = createSelector(
  [checkedInGuestsSelector, statusesSelector, defaultPosStatusSelector],
  (checkedInGuests, statuses, defaultPosStatusSelector) =>
    sortOrdersByStatus(statuses, checkedInGuests, defaultPosStatusSelector)
);

export const guestListSlice = createSlice({
  name: 'guestList',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GuestListState>) => {
    builder.addCase(getGuestStatuses.pending, (state) => {
      state.loadingStatuses = true;
    });

    builder.addCase(getGuestStatuses.fulfilled, (state, action) => {
      state.allStatuses = action.payload;
      state.statuses =
        state.filterSelections.length > 0 ? filterStatuses(action.payload, state.filterSelections) : action.payload;
      state.loadingStatuses = false;
      state.noStatusesConfigured = !action.payload.length;
    });

    builder.addCase(applyStatusFilters, (state, action) => {
      state.statuses = filterStatuses(state.statuses, state.filterSelections);
    });

    builder.addCase(getZones.pending, (state) => {
      state.zones = [];
    });

    builder.addCase(getZones.fulfilled, (state, action) => {
      state.zones = action.payload;
    });

    builder.addCase(getGuests.pending, (state) => {
      state.loadingGuests = false;
    });

    builder.addCase(getGuests.fulfilled, (state, action) => {
      updateGuestListInState(state, action);
      state.loadingGuests = false;
    });

    builder.addCase(updateGuestList, (state, action) => {
      updateGuestListInState(state, {
        payload: { response: action.payload.response, settings: action.payload.settings },
      });
    });

    builder.addCase(createAnonymousTransactionWithDemographics.pending, (state) => {
      state.submitting = true;
    });

    builder.addCase(createAnonymousTransactionWithDemographics.fulfilled, (state, action) => {
      state.submitting = false;
    });

    builder.addCase(createAnonymousTransactionWithDemographics.rejected, (state, action) => {
      state.submitting = false;
    });

    builder.addCase(setGuestStatus.fulfilled, (state, action) => {
      state.guests = action.payload || [];
    });

    builder.addCase(setGuestStatus.rejected, (state, action) => {});

    builder.addCase(setGuestSearchQuery, (state, action) => {
      state.query = action.payload;
    });

    builder.addCase(clearGuestSearchQuery, (state) => {
      state.query = '';
    });

    builder.addCase(setGuestFilterQuery, (state, action) => {
      state.filterQuery = action.payload;
    });

    builder.addCase(clearGuestFilters, (state) => {
      state.filterSelections = [];
      state.filterQuery = '';
      state.query = '';
    });

    builder.addCase(applyCustomersFilters, (state, action) => {
      const filterSelections = action.payload;
      state.filterSelections = filterSelections;
    });

    builder.addCase(applyCustomersSort, (state, action) => {
      const sortSelections = action.payload;
      state.sortOptions = sortSelections;
    });

    builder.addCase(setShowEmptyStatusColumns, (state, action) => {
      state.showEmptyStatusColumns = action.payload;
      if (!state.showEmptyStatusColumns) {
        const statusOptionsSet = new Set<CheckedInGuest['TransactionStatus']>();
        state.guests.forEach((g) => {
          if (g.TransactionStatus?.length) {
            statusOptionsSet.add(g.TransactionStatus);
          }
        });
        state.nonEmptyStatuses = [...statusOptionsSet];
        state.filterOptions = state.filterOptions.map((option) =>
          option.key === 'TransactionStatus' ? { ...option, options: [...state.nonEmptyStatuses] } : { ...option }
        );
      } else {
        state.filterOptions = state.filterOptions.map((option) =>
          option.key === 'TransactionStatus'
            ? { ...option, options: state.statuses.map((status) => status.POSStatus) }
            : { ...option }
        );
      }
    });

    builder.addCase(joinGroup.fulfilled, (state) => {
      state.pusherConnected = true;
    });

    builder.addCase(notifyConectionLost, (state) => {
      state.pusherConnected = false;
    });

    builder.addCase(getPusherConfiguration.fulfilled, (state, action) => {
      if (action.payload) {
        state.pusherConfiguration = action.payload;
      }
    });

    builder.addCase(notifyPusherConnected, (state) => {
      state.pusherConnected = true;
    });

    builder.addCase(cleanPusherConfiguration.fulfilled, (state, action) => {
      state.pusherConfiguration.ChannelName = action.payload.ChannelName;
      state.pusherConfiguration.PusherCluster = action.payload.PusherCluster;
      state.pusherConfiguration.PusherKey = action.payload.PusherKey;
    });

    builder.addCase(loadCardStatusDisplayOptions.fulfilled, (state, action) => {
      state.cardStatusDisplayOptions = action.payload;
    });

    builder.addCase(addOrUpdateGuest, (state, action) => {
      const currentGuestList: Array<CheckedInGuest> = JSON.parse(JSON.stringify(state.guests));
      const { guestUpdated } = action.payload;
      const guestFound = currentGuestList.find(
        (guest) => guest.Guest_id === guestUpdated.Guest_id && guest.ShipmentId === guestUpdated.ShipmentId
      );
      if (guestFound) {
        guestFound.TransactionStatus = guestUpdated.TransactionStatus;
        guestFound.Room = guestUpdated.Room;
        guestFound.Register = guestUpdated.Register;
        guestFound.RegisterId = guestUpdated.RegisterId;
        guestFound.ShipmentId = guestUpdated.ShipmentId;
        guestFound.ScheduleId = guestUpdated.ScheduleId;
        guestFound.FullName = guestUpdated.FullName;
        guestFound.IsLoyaltyMember = guestUpdated.IsLoyaltyMember;
        guestFound.MJStateIDNo = guestUpdated.MJStateIDNo;
        guestFound.PatientType = guestUpdated.PatientType;
        guestFound.TransactionReference = guestUpdated.TransactionReference;
        guestFound.AttestationExpirationDate = guestUpdated.AttestationExpirationDate;
        guestFound.nickname = guestUpdated.nickname;
        guestFound.OrderTotal = guestUpdated.OrderTotal;
        guestFound.TotalItems = guestUpdated.TotalItems;
      } else {
        currentGuestList.push(guestUpdated);
      }
      updateGuestListInState(state, {
        payload: { response: currentGuestList, settings: action.payload.settings },
      });
    });

    builder.addCase(removeGuestFromList, (state, action) => {
      const currentGuestList: Array<CheckedInGuest> = JSON.parse(JSON.stringify(state.guests));
      const guestIndexFound = currentGuestList.findIndex((guest) => guest.Guest_id === action.payload.guestId);
      if (guestIndexFound > -1) {
        currentGuestList.splice(guestIndexFound, 1);
      }
      updateGuestListInState(state, {
        payload: { response: currentGuestList, settings: action.payload.settings },
      });
    });

    builder.addCase(removeGuestByTransactionIdFromList, (state, action) => {
      const currentGuestList: Array<CheckedInGuest> = JSON.parse(JSON.stringify(state.guests));
      const guestIndexFound = currentGuestList.findIndex(
        (guest) => guest.ShipmentId.toString() === action.payload.transactionId.toString()
      );
      if (guestIndexFound > -1) {
        currentGuestList.splice(guestIndexFound, 1);
      }
      updateGuestListInState(state, {
        payload: { response: currentGuestList, settings: action.payload.settings },
      });
    });
  },
});
