import React, { FC, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import queryString from 'query-string';

import { colors } from 'css/Theme';
import { useStickyHeaderHeight } from './useStickyHeaderHeight';

export type SideTabPage = {
  label: string;
  component: React.ReactElement;
  hidden?: boolean;
  tabContentOverflowHidden?: boolean;
  tabContentNoPadding?: string;
};

type SideTabContainerProps = {
  pages: Array<SideTabPage>;
  headerRef: React.RefObject<HTMLDivElement>;
};

export const SideTabContainer: FC<SideTabContainerProps> = ({ pages, headerRef }) => {
  const [selectedTab, setTab] = useState(0);
  const sideTabRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const search = queryString.parse(history.location.search);
  const stickyHeaderHeight = useStickyHeaderHeight({ headerRef });

  useEffect(() => {
    const tabIndex = Number(search.tabIndex) || 0;
    setTab(tabIndex);
  }, [search]);

  useEffect(() => {
    if (sideTabRef.current) {
      sideTabRef.current.style.top = `${stickyHeaderHeight}px`;
    }
  }, [stickyHeaderHeight]);

  const selectTab = (tabIndex: number) => {
    setTab(tabIndex);
    history.replace({
      search: queryString.stringify({ ...search, tabIndex }),
    });
  };

  return (
    <SideTabContainerDiv>
      <SideTabColumnDiv ref={sideTabRef}>
        {pages.map((page, index) => (
          <SideTabDiv
            key={page.label}
            onClick={() => selectTab(index)}
            data-testid={'side-tab-container_side-tab-div'.concat(page.label)}
            hidden={page.hidden}
            selected={selectedTab === index}
          >
            {page.label}
          </SideTabDiv>
        ))}
      </SideTabColumnDiv>
      <TabContentColumnDiv
        tabContentOverflowHidden={pages[selectedTab].tabContentOverflowHidden}
        tabContentNoPadding={pages[selectedTab].tabContentNoPadding}
      >
        {pages[selectedTab].component}
      </TabContentColumnDiv>
    </SideTabContainerDiv>
  );
};

const SideTabColumnDiv = styled.div`
  display: flex;
  flex: unset;
  flex-shrink: 0;
  flex-direction: column;
  position: sticky;
  height: 100%;
  min-width: 215px;
  padding: 1.5rem;
`;

const SideTabDiv = styled.div<{ selected: boolean }>`
  line-height: 2.25rem;
  color: ${colors.black};
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;

  ${({ selected }) =>
    selected &&
    `
        font-weight: bold;
        background: ${colors.dutchie.backgroundGrey};
    `}
`;

const TabContentColumnDiv = styled.div<{ tabContentOverflowHidden?: boolean; tabContentNoPadding?: string }>`
  flex: unset;
  width: 100%;
  padding: 1.5rem;
  overflow: ${({ tabContentOverflowHidden }) => (tabContentOverflowHidden ? 'hidden' : 'auto')};
  border-left: 1px solid ${colors.dutchie.shadowGrey};
`;

const SideTabContainerDiv = styled.div`
  display: flex;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: unset;
`;
