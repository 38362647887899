import React, { FC } from 'react';
import { CartItem } from 'models/Cart';

import { CartLineItem } from './CartLineItem';
import { CartItemsContainer } from '../../CFD.styles';
import { AppliedDiscount } from 'models/Discounts';

type CartItemsProps = {
  cartItems: CartItem[];
  discounts: AppliedDiscount[];
};

export const CartItems: FC<CartItemsProps> = ({ cartItems, discounts }) => {
  return (
    <CartItemsContainer>
      {cartItems.map((cartItem) => (
        <CartLineItem
          key={cartItem.Id}
          cartItem={cartItem}
          discounts={discounts}
          automationId='cart-items_cart-line-item'
        />
      ))}
    </CartItemsContainer>
  );
};
