import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { KeypadHeader } from '../PinLoginPage/KeypadHeader';
import { PinPopup } from '../PinLoginPage/PinPopup';
import { PopupType } from '../PinLoginPage/types';

interface Props {
  isSSOLogin?: boolean;
  children: React.ReactNode;
}

export const LoginPageV2 = ({ isSSOLogin, children }: Props) => {
  const isSSOLoginPage = isSSOLogin || window.location.pathname === '/ssologin';
  const [popupModalHidden, setPopupModalHidden] = useState<boolean>(true);
  const [popupType, setPopupType] = useState<PopupType>('forgotPassword');

  const handleOpenForgotPassword = () => {
    setPopupModalHidden(false);
    setPopupType('forgotPassword');
  };

  return (
    <StyledPage>
      {!popupModalHidden && (
        <PinPopup
          popupType={popupType}
          setNewPIn={() => false}
          openForgotPassword={() => false}
          openForgotPasswordSuccessful={() => setPopupType('passwordResetSuccess')}
          hide={() => setPopupModalHidden(true)}
        />
      )}
      <KeypadHeader />
      <FormContainer>
        <div>
          <StyledTitle data-testid='login-title'>Login to get started</StyledTitle>
          {children}
          {!isSSOLoginPage && (
            <StyledLinks>
              <StyledLink data-testid='login-page_button_forgot-password' onClick={handleOpenForgotPassword}>
                Forgot password
              </StyledLink>
            </StyledLinks>
          )}
        </div>
      </FormContainer>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  height: 100%;
  min-height: 705px;
  background-color: ${colors.dutchie.opal90};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledTitle = styled.div`
  text-align: center;
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
  color: ${colors.dutchie.primaryWhite};
  margin: 0 auto 40px;
`;

const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const StyledLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const StyledLink = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  color: #8197ac;
`;
