import styled, { css } from 'styled-components';
import { colors } from 'css/Theme';

export const Container = styled.div<{ align?: 'top' | 'bottom' }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: ${({ align }) => (align === 'top' ? 'flex-start' : 'flex-end')};
  gap: 0.75rem;
`;

export const SubtotalsContainer = styled.div``;

const BaseFontStyles = css`
  color: ${colors.dutchie.grey30};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.5%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  ${BaseFontStyles}
`;

export const SubTotalRow = styled(Row)`
  font-weight: 700;
`;

export const TotalRow = styled(Row)`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  font-weight: 700;
  line-height: 170%;
  letter-spacing: 0.005em;
  text-transform: uppercase;
`;
