import React from 'react';
import styled from 'styled-components';

import { CheckedInGuest } from 'models/Guest';

import { ConfirmationModal } from 'components/modals/ConfirmationModal';
import { useBannedCustomerPopup } from './useBannedCustomerPopup';

type BannedCustomerPopupProps = {
  hide: () => void;
  guest: CheckedInGuest;
};

export const BannedCustomerPopup = ({ hide, guest }: BannedCustomerPopupProps): JSX.Element => {
  const { handleEditProfile, handleCancelTransaction } = useBannedCustomerPopup({ guest });

  return (
    <ConfirmationModal
      header='Continue with order?'
      message={Message}
      modalName='BannedCustomerPopup'
      rightButton={{
        text: 'Edit profile',
        onClick: handleEditProfile,
      }}
      leftButton={{
        text: 'Cancel transaction',
        transparentRed: true,
        onClick: handleCancelTransaction,
      }}
      hide={hide}
    />
  );
};

const BoldText = styled.span`
  font-weight: bold;
`;

const Message = (
  <div>
    This customer's status is set to <BoldText>banned</BoldText>. To create an order for this customer, please change their status in their profile.
  </div>
);
