import styled from 'styled-components';

import { zIndices } from 'css/Theme';

export const Overlay = styled.div<{ isActive: boolean }>`
background-color: white;
position: absolute;
height: 100%;
width: 100%;
z-index: ${zIndices.cartSidePanel};
visibility: hidden;
opacity: 0;
transition: all 0.3s ease;

${({ isActive }) =>
  isActive &&
  `
  visibility: visible;
  opacity: 0.7;
`}
`;
