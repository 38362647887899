import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';

const TableContainer = styled.div`
  border: 1px solid ${colors.dutchie.backgroundGrey};
  border-radius: 0.5rem;

  table {
    width: 100%;
  }
`;

export const StyledTable: FC = ({ children }) => {
  return (
    <TableContainer>
      <table>{children}</table>
    </TableContainer>
  );
};

export const StyledRow = styled.tr`
  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.5%;
  border-top: 1px solid ${colors.dutchie.backgroundGrey};
`;

export const StyledHeaderRow = styled(StyledRow)`
  color: ${colors.dutchie.grey30};
  font-size: 0.8125rem;
  line-height: 1.125rem;
  border: none;
`;

export const StyledCell = styled.td`
  padding: 1rem;
  vertical-align: middle;
`;
