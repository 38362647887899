import React, { FC } from 'react';
import styled from 'styled-components';
import { Preorder } from 'models/Cart';
import { colors } from 'css/Theme';
import { ReactComponent as ActionElipsisIcon } from 'assets/icons/actions-elipsis.svg';
import { Button } from 'components/backoffice/button';
import { PreorderAssignmentTag } from './PreorderAssignmentTag';
import { OverflowMenu, OverflowMenuOption } from 'components/menus/OverflowMenu';

type PreorderLineItemProps = {
  item: Preorder;
  overflowOptions: OverflowMenuOption[];
};

export const PreorderLineItem: FC<PreorderLineItemProps> = ({ item, overflowOptions, children }) => {
  return (
    <SidebarItemContainer data-testid='sidebar-item_sidebar-item-container_preorder-item-container'>
      <SidebarItemWrapper>
        <ItemsInfoWrapper data-testid='sidebar-item_two-column-title_quantity-name-serial'>
          <ItemsInfoProduct>
            {item.BrandName && <ItemBrandName>{item.BrandName}</ItemBrandName>}
            {item?.Product}
          </ItemsInfoProduct>
          <PreorderAssignmentTag preorder={item} />
        </ItemsInfoWrapper>
        <ItemsInfoActions>
          <ItemsCost>Qty: {item?.QtySelected}</ItemsCost>
          <ItemsCost>${item?.TotalCost.toFixed(2)}</ItemsCost>
          <OverflowMenu
            anchor={<StyledActionButton label={<ActionElipsisIcon />} variant='white' />}
            automationId='preorder-line-item'
            menuOptions={overflowOptions}
          />
        </ItemsInfoActions>
      </SidebarItemWrapper>
      {children}
    </SidebarItemContainer>
  );
};

const ItemsCost = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
  min-width: 67px;
  text-align: right;
`;

const ItemsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`;

const ItemsInfoActions = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`;

const ItemsInfoProduct = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
`;

const ItemBrandName = styled.div`
  color: ${colors.dutchie.grey50};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.125rem;
`;

const SidebarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.dutchie.primaryWhite};
  padding: 1rem 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
`;

const SidebarItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledActionButton = styled(Button)`
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  min-width: 0;
  border-radius: 0;

  svg {
    width: 24px;
  }

  &:hover {
    background: transparent;
  }

  &:focus {
    border: 0;
    outline: 0;
  }
`;
