import React, { FC } from 'react';
import styled from 'styled-components';
import { Popup } from 'components/popups';
import { popupWidth } from 'css/Theme';
import { Journal } from 'components/inputs';

const JournalPopupBase = styled(Popup)`
  width: 70%;
  max-width: ${popupWidth.medium};
`;

const JournalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 550px;
  overflow: auto;
  padding: 0 24px;
`;

type JournalPopupProps = {
  hide: () => void;
  guestId?: number;
};

export const JournalPopup: FC<JournalPopupProps> = ({ hide, guestId }) => {
  return (
    <JournalPopupBase caption='Journal' isVisible hide={hide}>
      <JournalContainer data-testid='journal-popup_div_container'>
        <Journal guestId={guestId} rows={3} />
      </JournalContainer>
    </JournalPopupBase>
  );
};
