import React, { FC, useState } from 'react';
import { ceil, floor, round } from 'lodash';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmationPopup } from 'components/popups';
import { Input, TextArea } from 'components/inputs';
import { Label } from 'components/text';
import { colors } from 'css/Theme';
import { editItemInCart } from 'store/actions/CartActions';
import { State } from 'store';
import { CartItem } from 'models/Cart';
import { callback } from 'models/Misc';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type EditPopupProps = {
  item?: CartItem;
  hide: callback;
};

export const EditProductPopup: FC<EditPopupProps> = ({ item, hide }) => {
  const dispatch = useDispatch();
  const patientType = useSelector((state: State) => state.customer.details?.PatientType);
  const isUseMnEnabled = useIsUseMnEnabled({ PatientType: patientType });
  const locationSettings = useSelector((state: State) => state.settings.locationSettings);
  const [instructions, setInstructions] = useState<string | undefined>(item?.Instructions);
  const [daysSupply, setDaysSupply] = useState<number | undefined>(item?.DaysSupply);

  const useDaysSupplyTotal = isUseMnEnabled || locationSettings?.State?.toLowerCase() === 'ny';

  const { shipmentId } = useTransactionManager();

  const save = () => {
    if (item && shipmentId) {
      dispatch(
        editItemInCart({
          ProductId: item.ProductId,
          SerialNo: item.SerialNo,
          Instructions: instructions || '',
          UnitPrice: item.TotalCost / (item.WgtCnt === 'Wgt' ? item.Grams || 1 : item.PricedQuantity || 1),
          DiscountAmt: item.DiscountAmt ? item?.DiscountAmt / item.QtyAllocated : 0,
          DaysSupply: daysSupply || 0,
          ShipmentId: shipmentId,
        })
      );
      hide();
    }
  };

  const handleDaysTotalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== null && e.target.value !== '' && item) {
      setDaysSupply(floor(Number(e.target.value) / item.QtyAllocated, 2));
    } else {
      setDaysSupply(Number(e.target.value));
    }
  };

  const onSupplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDaysSupply(Number(e.target.value) ? round(Number(e.target.value)) : undefined);
  };

  return (
    <ConfirmationPopup
      title='Edit Product'
      small
      hide={hide}
      isVisible
      confirm={{
        text: 'Save',
        onClick: save,
        disabled: !(item && shipmentId),
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <Container>
        <Section>
          <Info>Description: {item?.Product}</Info>
          <Info>Package ID: {item?.SerialNo}</Info>
          <Info>Weight: {item?.Grams ?? 0}g</Info>
          <Info>Discount: ${item?.DiscountAmt ?? 0}</Info>
          <Info>Price: ${item?.TotalCost ?? 0}</Info>
        </Section>
        <Section>
          {useDaysSupplyTotal ? (
            <Row>
              <Input
                label='Days Per Item'
                type='number'
                disabled
                value={daysSupply ?? 0}
                onChange={(e) => setDaysSupply(Number.parseInt(e.target.value, 10))}
              />
              <Input
                label='Days Total'
                type='number'
                value={daysSupply ? ceil(daysSupply * (item ? item.QtyAllocated : 0)) : ''}
                onChange={(e) => handleDaysTotalChange(e)}
              />
            </Row>
          ) : (
            <Cell>
              <Input
                label='Days Supply Per Item'
                type='number'
                value={daysSupply}
                onChange={(e) => onSupplyChange(e)}
              />
            </Cell>
          )}
          <Cell>
            <Label>Instructions</Label>
            <InstructionsInput value={instructions} onChange={(e) => setInstructions(e.target.value)} />
          </Cell>
        </Section>
      </Container>
    </ConfirmationPopup>
  );
};

const Container = styled.div`
  padding: 0 1.5rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const Cell = styled.div`
  padding-top: 1rem;
`;

const Row = styled(Cell)`
  display: flex;
  gap: 1rem;
`;

const Info = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${colors.dutchie.almostBlack};
`;

const InstructionsInput = styled(TextArea)`
  flex-grow: 1;
  min-height: 20vh;
`;
