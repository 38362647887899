import { AssignRegisterSettingsContextData } from "../types";
import { useFormikContext } from "formik";
import { HardwareSettings } from "../../types";
import { useAppSelector, useAppDispatch } from "util/hooks";
import { SettingsSelectOption } from "pages/SettingsPage/Select/SettingsSelect";
import { loadRegisters } from "store/actions/SettingsActions";

export const useAssignRegisterSettingsProvider = (): AssignRegisterSettingsContextData => {
  const dispatch = useAppDispatch();
  const { values: formValues, setFieldValue } = useFormikContext<HardwareSettings>();
  const { registers } = useAppSelector((state) => state.settings);
  const { assignedRegisterId } = formValues;

  const options: SettingsSelectOption[] = registers.map(it => ({ label: it.TerminalName, value: it.id.toString() }));

  const refreshRegisters = () => {
    dispatch(loadRegisters(assignedRegisterId?.toString()));
  };

  const setAssignedRegisterId = (value: number) => {
    setFieldValue('assignedRegisterId', value);
  };

  return {
    assignedRegisterId,
    options,
    refreshRegisters,
    setAssignedRegisterId,
  };
};
