import { useDispatch } from 'react-redux';

import { usePreviewLabels } from './printJobs/usePreviewLabels';
import { usePreviewManifest } from './printJobs/usePreviewManifest';
import { usePrintCart } from './printJobs/usePrintCart';
import { usePrintDeliveryReceipts } from './printJobs/usePrintDeliveryReceipts';
import { usePrintLabels } from './printJobs/usePrintLabels';
import { usePrintPickTicket } from './printJobs/usePrintPickTicket';
import { usePrintReceipt } from './printJobs/usePrintReceipt';
import { usePrintRegalaReceipt } from './printJobs/usePrintRegalaReceipt';
import { usePrintZReport } from './printJobs/usePrintZReport';

import {
  PrintReceiptArgs,
  previewLabels as previewLabelsOld,
  printLabels as printLabelsOld,
} from 'store/actions/PrintActions';
import { CartItem } from 'models/Cart';
import { errorNotification } from 'store/actions/NotificationsActions';

import type { Delivery } from 'models/Delivery';
import { useHardwareLibrary } from '../launch-darkly/useHardwareLibrary';

export const usePrintJob = () => {
  const dispatch = useDispatch();
  const isHardwareLibraryActive = useHardwareLibrary();

  const previewLabelsNew = usePreviewLabels();
  const previewManifestNew = usePreviewManifest();
  const printCartNew = usePrintCart();
  const printDeliveryReceiptsNew = usePrintDeliveryReceipts();
  const printLabelsNew = usePrintLabels();
  const printPickTicketNew = usePrintPickTicket();
  const printReceiptNew = usePrintReceipt();
  const printZReportNew = usePrintZReport();
  const printRegalaReceiptNew = usePrintRegalaReceipt();

  const previewLabels = ({
    guest,
    items,
    labelId,
    printAll,
  }: {
    guest?: { ShipmentId: number };
    items?: CartItem[];
    labelId?: number;
    printAll: boolean;
  }) => {
    if (isHardwareLibraryActive) {
      if (!items || !guest?.ShipmentId) {
        dispatch(errorNotification('Missing items or shipmentId'));
        return;
      }
      previewLabelsNew({ items, labelId, printAll, shipmentId: guest?.ShipmentId });
    } else {
      dispatch(previewLabelsOld({ guest: { ShipmentId: guest?.ShipmentId ?? 0 }, items, labelId, printAll }));
    }
  };

  const previewManifest = ({ PosId }: { PosId: number }) => {
    previewManifestNew({ shipmentId: PosId });
  };

  const printCart = ({ guest, autoPrint }: { guest?: { Guest_id: number; ShipmentId: number }; autoPrint?: boolean }) => {
    if (!guest?.Guest_id || !guest?.ShipmentId) {
      dispatch(errorNotification('Missing guestId or shipmentId'));
      return;
    }
    printCartNew({ guestId: guest.Guest_id, shipmentId: guest.ShipmentId });
  };

  const printDeliveryReceipts = ({
    deliveries,
    IsLocalPrinter,
    PrinterId,
  }: {
    deliveries: Delivery[];
    IsLocalPrinter: boolean;
    PrinterId?: number | string;
  }) => {
    printDeliveryReceiptsNew({ deliveries });
  };

  const printLabels = ({
    guest: { ShipmentId },
    items,
    printAll,
    autoPrint,
    transactionId,
  }: {
    guest: { ShipmentId: number };
    items: CartItem[] | CartItem;
    printAll: boolean;
    autoPrint: boolean;
    transactionId?: string;
  }) => {
    if (isHardwareLibraryActive) {
      printLabelsNew({ items, printAll, shipmentId: ShipmentId, transactionId });
    } else {
      dispatch(printLabelsOld({ guest: { ShipmentId }, items, printAll, autoPrint, transactionId }));
    }
  };

  const printPickTicket = ({ guest }: { guest?: { Guest_id: number; ShipmentId: number } }) => {
    if (!guest?.Guest_id || !guest?.ShipmentId) {
      dispatch(errorNotification('Missing guestId or shipmentId'));
      return;
    }
    printPickTicketNew({ guestId: guest.Guest_id, shipmentId: guest.ShipmentId });
  };

  const printReceipt = ({
    ForDelivery,
    localPrinter,
    popCashDrawer,
    PrinterId,
    ReceiptParameters,
    ReceiptType,
    subtotal,
    total,
  }: {
    ForDelivery?: boolean;
    localPrinter: boolean;
    popCashDrawer: boolean;
    PrinterId: number | string;
    ReceiptParameters: number;
    ReceiptType: string;
    subtotal?: number;
    total?: number;
  }) => {
    printReceiptNew({
      popCashDrawer,
      receiptParameters: ReceiptParameters,
      receiptType: ReceiptType,
      showDeliveryDetails: ForDelivery,
      subtotal,
      total,
    });
  };

  const printRegalaReceipt = (args: PrintReceiptArgs) => {
    printRegalaReceiptNew({
      popCashDrawer: args.popCashDrawer,
      popCashDrawerBeforePrint: false,
      receiptParameters: args.ReceiptParameters,
      receiptType: args.ReceiptType,
      receiptPrinterId: args.PrinterId,
      showDeliveryDetails: args.ForDelivery,
      total: args.total,
      subtotal: args.subtotal,
    });
  };

  const printZReport = () => {
    printZReportNew();
  };

  return {
    previewLabels,
    previewManifest,
    printCart,
    printDeliveryReceipts,
    printLabels,
    printPickTicket,
    printReceipt,
    printRegalaReceipt,
    printZReport,
  };
};
