import React, { FC } from 'react';
import { CFDGlobalStyles, CFDContainer, CustomIdleContainer, IdleImage, WelcomeMessage } from '../CFD.styles';

type IdleScreenProps = {
  customIdleImage?: string;
};

export const IdleScreen: FC<IdleScreenProps> = ({ customIdleImage }) => {
  if (customIdleImage) {
    return (
      <CustomIdleContainer data-testid='custom-idle-screen'>
        <IdleImage alt='custom-idle-screen' src={customIdleImage} />
      </CustomIdleContainer>
    );
  }

  return (
    <CFDContainer data-testid='idle-screen'>
      <CFDGlobalStyles />
      <WelcomeMessage>Welcome</WelcomeMessage>
    </CFDContainer>
  );
};
