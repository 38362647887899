import { post } from 'api/HttpHelpers';
import {
  ValidatedFormsResponse,
  DefaultStatuses,
  Reason,
  CustomerType,
  CloseoutRequest,
  CloseoutResponse,
  DropCashRequest,
  WithdrawCashRequest,
  DepositCashRequest,
  RegisterTranferRequest,
  SetMassDPHCreds,
  MassDphCreds,
  MassDphSettings,
  LogoResponse,
  OpenCashDrawerRequest,
  BioTrackValidateUserCredentialResult,
  ResetBioTrackUserCredentials,
} from 'models/Settings';
import {
  HardwareRegisterResponse,
  RegisterTransactionRecord,
  RegisterTransactionsRequest,
  CustomerSearchConfig,
  PreorderConfig,
  ResultResponse,
  PosApiResponse,
} from 'models/Misc';

export const getValidatedForms = () => post<Array<ValidatedFormsResponse>>('posv3/maintenance/get_validated_forms');
export const getDefaultStatuses = () => post<DefaultStatuses>('posv3/maintenance/get-default-pos-status');
export const getCancellationReasons = () => post<Array<Reason>>('posv3/maintenance/get-cancellation-reasons');
export const getVisitReasons = () => post<Array<Reason>>('posv3/maintenance/get-customer-visit-reasons');
export const getVoidReasons = () => post<Array<Reason>>('posv3/maintenance/get-void-reasons');
export const getCustomerTypes = () => post<Array<CustomerType>>('posv3/maintenance/get-customer-types');
export const registerCloseout = (body: CloseoutRequest) =>
  post<CloseoutResponse>('posv3/backendpos/CloseOutPost', body, { ignoreFormat: true });
export const registerDropCash = (body: DropCashRequest) => post<void>('posv3/backendpos/DropCash', body);
export const registerWithdrawal = (body: WithdrawCashRequest) =>
  post<void>('posv3/backendpos/RegisterWithdrawal', body);
export const registerDeposit = (body: DepositCashRequest) => post<void>('posv3/backendpos/RegisterDeposit', body);
export const registerTransfer = (body: RegisterTranferRequest) =>
  post<void>('posv3/backendpos/TransferRegisterTransaction', body);
export const getMassDPHCreds = () => post<MassDphSettings>('dph/get-user');
export const setMassDPHCreds = (body: SetMassDPHCreds) => post<void>('dph/update-user', body);
export const validateBioTrackUserCredentials = () =>
  post<BioTrackValidateUserCredentialResult>('biotrack/validate-user-credential');
export const resetBioTrackUserCredentialsRequest = (body: ResetBioTrackUserCredentials) =>
  post<PosApiResponse<ResultResponse>>('biotrack/reset-user-credential-request', body);
export const updateBioTrackPassword = (body: ResetBioTrackUserCredentials) =>
  post<PosApiResponse<ResultResponse>>('biotrack/update-user-credential', body);
export const enableMassDPHOverride = () => post<void>('dph/enable-manager-override', {});
export const disableMassDPHOverride = () => post<void>('dph/disable-manager-override', {});
export const validateMassDPHSavedCreds = () => post<void>('dph/v2/check-credentials');
export const validateMassDPHEnteredCreds = (body: MassDphCreds) => post<void>('dph/v2/check-credentials', body);
export const setRegisterHardwareId = (hardwareId: string, registerId?: number) =>
  post<void>('posv3/registers/assign', { HardwareId: hardwareId, RegisterId: registerId });
export const popCashDrawer = (body: OpenCashDrawerRequest) => post<void>('v2/print-jobs/pop-cash-drawer', body);
export const getAllRegisters = () => post<Array<HardwareRegisterResponse>>('posv3/registers/get-all');
export const getRegisterTransactions = (body: RegisterTransactionsRequest) =>
  post<Array<RegisterTransactionRecord>>('posv3/backendpos/get-register-transactions', body);
export const getUserSettings = () => post<Array<CustomerSearchConfig>>('posv3/user/GetPOSUserSettings');
export const saveUserTableConfig = (body: Array<CustomerSearchConfig>) =>
  post<void>('posv3/user/SetSingleUserSettings', {
    Setting: 'pos-customers-table',
    SettingType: 'table',
    Value: JSON.stringify(body),
  });
export const getPreorderConfig = () => post<PreorderConfig>('preorder/config');
export const getLocationLogo = () => post<Array<LogoResponse>>('posv3/maintenance/GetLocationLogo');
export const getLspLogo = () => post<Array<LogoResponse>>('posv3/maintenance/GetLspLogo');
export const getExternalWebsites = () => post<Array<string>>('v2/devices/get-external-websites');

export enum DeliveryProvider {
  OnFleet = 1,
  Tookan = 2,
  WorkWave = 3,
}

export function deliveryProviderToString(provider: DeliveryProvider | undefined): string {
  if (provider === DeliveryProvider.OnFleet) {
    return 'OnFleet';
  }

  if (provider === DeliveryProvider.Tookan) {
    return 'Tookan';
  }

  if (provider === DeliveryProvider.WorkWave) {
    return 'WorkWave';
  }

  return 'Unknown';
}
