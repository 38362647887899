import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { Button } from 'components/buttons';
import { CartActionButton } from '../CartActionButton';
import { breakpoints, colors } from 'css/Theme';
import { DutchiePayPreauthButton } from '../DutchiePayPreauthButton';
import { SidePanelSection } from 'components/layout';
import { TotalMinimized } from 'components/cart/TotalMinimized';
import { useCartTotalsAndActions } from './useCartTotalsAndActions';

import type { Cart } from 'models/Cart';

const PREAUTH_MESSAGE = 'Pre-authorization amount updates as new items are added.';

export type CartTotalsAndActionsProps = {
  actionButtonStyles?: FlattenSimpleInterpolation;
  cart: Cart;
  className?: string;
  isLoading?: boolean;
  areTotalsOpen?: boolean;
  onToggleTotals?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CartTotalsAndActions = ({
  actionButtonStyles,
  cart,
  className,
  isLoading,
  areTotalsOpen,
  onToggleTotals,
}: CartTotalsAndActionsProps) => {
  const {
    handleNotifyCustomer,
    handleReleaseCart,
    handleSaveCart,
    handleSetTotalsDrawer,
    isCheckoutDisabled,
    isStatefulCheckoutEnabled,
    isTotalsDrawerOpen,
    showDutchiePayPreAuthCheckout,
    showPreauthMessage,
  } = useCartTotalsAndActions({
    cart,
    areTotalsOpen,
    onToggleTotals,
  });

  return (
    <Container className={className}>
      <TotalMinimized
        cart={cart}
        isCartLoading={isLoading}
        isStatefulCheckoutEnabled={isStatefulCheckoutEnabled}
        isOpen={isTotalsDrawerOpen}
        setIsOpen={handleSetTotalsDrawer}
      />
      <ActionButtonsContainer>
        <ActionButton
          secondary
          styles={actionButtonStyles}
          onClick={handleReleaseCart}
          automationId='running-totals_dutchie-button_release'
        >
          Release
        </ActionButton>
        <ActionButton
          secondary
          styles={actionButtonStyles}
          onClick={handleSaveCart}
          automationId='running-totals_dutchie-button_save'
        >
          Save
        </ActionButton>
        <ActionButton
          secondary
          styles={actionButtonStyles}
          onClick={handleNotifyCustomer}
          automationId='running-totals_dutchie-button_notify'
        >
          Notify
        </ActionButton>
        {showDutchiePayPreAuthCheckout ? (
          <DutchiePayPreauthButton disabled={isCheckoutDisabled} />
        ) : (
          <CartActionButton disabled={isCheckoutDisabled} />
        )}
      </ActionButtonsContainer>
      {showPreauthMessage && <PreauthMessage>{PREAUTH_MESSAGE}</PreauthMessage>}
    </Container>
  );
};

const Container = styled(SidePanelSection)`
  padding: 1rem 1.5rem 1.25rem 1.5rem;
  gap: 0.5rem;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
  justify-content: space-between;

  ${breakpoints.wideTablet.exclusiveQuery} {
    flex-wrap: wrap;

    & > :last-child {
      width: 100%;
      flex: unset;
    }
  }
`;

const ActionButton = styled(Button)<{ styles?: FlattenSimpleInterpolation }>`
  height: 52px;
  padding: 0;

  ${({ styles }) => styles}
`;

const PreauthMessage = styled.div`
  color: ${colors.dutchie.gray70};
  text-align: center;
  font-size: 12px;
  font-weight: 600;
`;
