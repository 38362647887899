import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useCloseBirthdateModalFix = () => {
  const ldClient = useLDClient();
  const isCloseBirthdateModalFixEnabled = ldClient?.variation('pos.register.close-birthdate-modal-fix', false);
  return {
    /** pos.register.close-birthdate-modal-fix */
    isCloseBirthdateModalFixEnabled,
  };
};
