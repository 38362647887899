import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cart, CartTotal } from 'models/Cart';
import { clearCFDCart, updateCFDCartTotals, updateCFDData } from 'store/actions/CFDActions';

export type CFDState = {
  locationName: string | undefined;
  loggedIn: boolean | undefined;
  customIdleImage: string | undefined;
  features: Record<string, boolean>;
  cart: Cart | undefined;
  checkoutComplete: boolean | undefined;
  changeDue: number | undefined;
};

const initialState: CFDState = {
  locationName: undefined,
  loggedIn: undefined,
  customIdleImage: undefined,
  features: {},
  cart: undefined,
  checkoutComplete: undefined,
  changeDue: undefined,
};

export const cfdSlice = createSlice({
  name: 'cfd',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CFDState>) => {
    builder.addCase(clearCFDCart, (state: CFDState) => {
      state.cart = undefined;
      state.checkoutComplete = false;
      state.changeDue = 0;
    });
    builder.addCase(updateCFDCartTotals, (state: CFDState, action: PayloadAction<CartTotal>) => {
      if (state.cart) {
        // CartTotal is essentially a partial Cart so just update the changes here
        const newTotals: Partial<Cart> = action.payload;
        const updatedCart: Cart = {
          ...state.cart,
          ...newTotals,
        };
        state.cart = updatedCart;
      }
    });
    builder.addCase(updateCFDData, (state: CFDState, action: PayloadAction<Partial<CFDState>>) => {
      const { locationName, loggedIn, features, customIdleImage, cart, checkoutComplete, changeDue } = action.payload;
      state.locationName = locationName ?? state.locationName;
      state.loggedIn = loggedIn ?? state.loggedIn;
      state.features = features ?? state.features;
      state.customIdleImage = customIdleImage ?? state.customIdleImage;
      state.cart = cart ?? state.cart;
      state.checkoutComplete = checkoutComplete ?? state.checkoutComplete;
      state.changeDue = changeDue ?? state.changeDue;
    });
  },
});
