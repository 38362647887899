// Caregiver List Types

/** It's unclear why both CaregiverMJStateIDNo and CaregiverMJStateIdNo
 * are both sent inconsistently from the server. This is a temporary fix
 */
export type CaregiversServerResponse = {
  CaregiverId: number;
  CaregiverName: string;
  CaregiverMJStateIdNo?: string;
  CaregiverMJStateIDNo?: string;
};

export type Caregiver = ReturnType<typeof transformToCaregiver>;

export const transformToCaregiver = (caregiver: CaregiversServerResponse) => {
  return {
    caregiverId: caregiver.CaregiverId,
    caregiverName: caregiver.CaregiverName,
    caregiverMJStateIdNo: caregiver?.CaregiverMJStateIdNo ?? caregiver?.CaregiverMJStateIDNo,
  };
};

// Secondary Identification Types

export type SecondaryIdentificationServerResponse = {
  IdentityId: number;
  Image: string;
  Name: string;
  StateId: string;
};

export type SecondaryIdentification = ReturnType<typeof transformToSecondaryIdentification>;

export const transformToSecondaryIdentification = (identification: SecondaryIdentificationServerResponse) => {
  return {
    identityId: identification.IdentityId,
    image: identification.Image,
    name: identification.Name,
    stateId: identification.StateId,
  };
};

// Checked In Guests Types

export type CustomerIdentification = {
  additionalStateIdentifiers?: string;
  expirationDate: string;
  imageUrl: string;
  number: string;
  startDate?: string;
  type: string;
};

export type CheckedInGuestsServerResponse = {
  Attestation: string;
  AttestationExpirationDate?: string;
  avatar: string;
  CarName?: string;
  CellPhone: string;
  CheckInDate: string;
  CheckInDateUTC: string;
  City?: string;
  DeliveryArrivalDate?: string;
  DeliveryDepartureDate?: string;
  DeliveryStatus?: string;
  DiscountGroup1?: string;
  DiscountGroup2?: string;
  DiscountGroup3?: string;
  DiscountGroup4?: string;
  DiscountGroup5?: string;
  Dob: string;
  doctor?: number;
  EmailAddress: string;
  FullName: string;
  Guest_id: number;
  GuestStatus: string;
  HasDutchiePay: boolean;
  HasPreorder: boolean;
  identifications: {
    additionalStateIdentifiers?: string;
    ExpirationDate: string;
    image_url: string;
    number: string;
    StartDate?: string;
    type: string;
  }[];
  IsAnonymous: boolean;
  IsDutchiePayUser: boolean;
  IsLoyaltyMember: boolean;
  IsMedical: boolean;
  IsNewCustomer: boolean;
  IsPrePaid: boolean;
  LastPurchaseDate: string;
  LoyaltyPillBackgroundColor?: string;
  LoyaltyPillTextColor?: string;
  LoyaltyTextColor?: string;
  LoyaltyTier?: string;
  ManifestTitle: string;
  MJStateIDNo: string;
  nickname: string;
  OrderSource: string;
  OrderTotal: number;
  OrderType: string;
  PatientPhone: string;
  PatientType: string;
  PostalCode: string;
  PreOrderCnt: number;
  Pronoun: string;
  Register: string;
  RegisterId: string;
  RemotePayTransactionId?: string;
  Room: string;
  ScheduleId: string;
  ShipmentDateUTC: string;
  ShipmentId: string;
  State: string;
  Street1: string;
  Street2?: string;
  TimeWindowEndDate?: string;
  TimeWindowStartDate?: string;
  TotalItems: number;
  TransactionCaregiver: string;
  TransactionReference?: string;
  TransactionStatus: string;
  ValidPrescription: boolean;
};

export type CheckedInGuest = ReturnType<typeof transformToCheckedInGuest>;

export const transformToCheckedInGuest = (response: CheckedInGuestsServerResponse) => {
  const identifications: CustomerIdentification[] = response.identifications.map((identification) => ({
    additionalStateIdentifiers: identification.additionalStateIdentifiers,
    expirationDate: identification.ExpirationDate,
    imageUrl: identification.image_url,
    number: identification.number,
    startDate: identification.StartDate,
    type: identification.type,
  }));

  return {
    attestation: response.Attestation,
    attestationExpirationDate: response.AttestationExpirationDate,
    avatar: response.avatar,
    carName: response.CarName,
    cellPhone: response.CellPhone,
    checkInDate: response.CheckInDate,
    checkInDateUTC: response.CheckInDateUTC,
    city: response.City,
    deliveryArrivalDate: response.DeliveryArrivalDate,
    deliveryDepartureDate: response.DeliveryDepartureDate,
    deliveryStatus: response.DeliveryStatus,
    discountGroup1: response.DiscountGroup1,
    discountGroup2: response.DiscountGroup2,
    discountGroup3: response.DiscountGroup3,
    discountGroup4: response.DiscountGroup4,
    discountGroup5: response.DiscountGroup5,
    dob: response.Dob,
    doctor: response.doctor,
    emailAddress: response.EmailAddress,
    fullName: response.FullName,
    guestId: response.Guest_id,
    guestStatus: response.GuestStatus,
    hasDutchiePay: response.HasDutchiePay,
    hasPreorder: response.HasPreorder,
    identifications,
    isAnonymous: response.IsAnonymous,
    isDutchiePayUser: response.IsDutchiePayUser,
    isLoyaltyMember: response.IsLoyaltyMember,
    isMedical: response.IsMedical,
    isNewCustomer: response.IsNewCustomer,
    isPrePaid: response.IsPrePaid,
    lastPurchaseDate: response.LastPurchaseDate,
    loyaltyPillBackgroundColor: response.LoyaltyPillBackgroundColor,
    loyaltyPillTextColor: response.LoyaltyPillTextColor,
    loyaltyTextColor: response.LoyaltyTextColor,
    loyaltyTier: response.LoyaltyTier,
    manifestTitle: response.ManifestTitle,
    mjStateIdNo: response.MJStateIDNo,
    nickname: response.nickname,
    orderSource: response.OrderSource,
    orderTotal: response.OrderTotal,
    orderType: response.OrderType,
    patientPhone: response.PatientPhone,
    patientType: response.PatientType,
    postalCode: response.PostalCode,
    preOrderCnt: response.PreOrderCnt,
    pronoun: response.Pronoun,
    registerName: response.Register,
    registerId: parseInt(response.RegisterId),
    remotePayTransactionId: response.RemotePayTransactionId,
    roomName: response.Room,
    scheduleId: parseInt(response.ScheduleId),
    shipmentDateUTC: response.ShipmentDateUTC,
    shipmentId: parseInt(response.ShipmentId),
    state: response.State,
    street1: response.Street1,
    street2: response.Street2,
    timeWindowEndDate: response.TimeWindowEndDate,
    timeWindowStartDate: response.TimeWindowStartDate,
    totalItems: response.TotalItems,
    transactionCaregiver: response.TransactionCaregiver,
    transactionReference: response.TransactionReference,
    transactionStatus: response.TransactionStatus,
    validPrescription: response.ValidPrescription,
  };
};

// Customer Search Types

export type CustomerSearchServerResponse = {
  avatar: string;
  DOB: string;
  DriversLicenseId: string;
  Email: string;
  Guest_id: number;
  LastTransaction: string;
  MJStateIDNo: string;
  MMJIDExpiration: string;
  Name: string;
  PatientType: string;
  PhoneNo: string;
  TransactionReference: null;
};

export type CustomerSearchResult = ReturnType<typeof transformToCustomerSearchResult>;

export const transformToCustomerSearchResult = (result: CustomerSearchServerResponse) => {
  return {
    avatar: result.avatar,
    customerType: result.PatientType,
    dob: result.DOB,
    driversLicenseId: result.DriversLicenseId,
    email: result.Email,
    guestId: result.Guest_id,
    lastTransaction: result.LastTransaction,
    mmjIdNumber: result.MJStateIDNo,
    mmjIdExpiration: result.MMJIDExpiration,
    name: result.Name,
    phoneNumber: result.PhoneNo,
    transactionReference: result.TransactionReference,
  };
};

export type IdentificationData = {
  IDNumber: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Suffix: string;
  Address1: string;
  Address2: string;
  Address3: string;
  City: string;
  State: string;
  PostalCode: string;
  ExpirationDate: string;
  DateOfBirth: string;
  IssueDate: string;
  IsMedicalId: boolean;
  DocumentType: string;
  BarcodeVersion: number;
  ParserVersion: string;
};
