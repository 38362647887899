import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorNotification } from 'store/actions/NotificationsActions';
import { isInteger } from 'lodash';

import {
  Container,
  StyledBaseInput,
  MinusButton,
  PlusButton,
} from 'components/cart/ProductSelectionControls/ProductSelection.styles';

type QuantityPickerProps = {
  isDisabled?: boolean;
  maxQuantity: number;
  initialQuantity: number;
  onQuantityChange: (val: number) => void;
  allowDecimal: boolean;
};

export const QuantityPicker: FC<QuantityPickerProps> = ({
  isDisabled = false,
  maxQuantity,
  initialQuantity,
  onQuantityChange,
  allowDecimal,
}) => {
  const [quantityInputFocused, setQuantityInputFocused] = useState(false);
  const [quantity, setQuantity] = useState(initialQuantity);
  const [inputValue, setInputValue] = useState(initialQuantity.toString());
  const dispatch = useDispatch();

  const changeItemInCart = useCallback((update: number) => {
    setQuantity((qty) => qty + update);
  }, []);

  useEffect(() => {
    setInputValue(quantity.toString());
  }, [quantity]);

  useEffect(() => {
    setQuantity(initialQuantity);
    setInputValue(initialQuantity.toString());
  }, [initialQuantity]);

  useEffect(() => {
    onQuantityChange(quantity);
  }, [quantity, onQuantityChange]);

  const handleQtySet = (key: string) => {
    if (key === 'Enter') {
      const input = Number(inputValue);

      let error = false;
      if (isNaN(input)) {
        dispatch(errorNotification(`Please enter a valid number`));
        error = true;
      }
      if (input > maxQuantity) {
        dispatch(errorNotification(`The max quantity available is ${maxQuantity}`));
        error = true;
      }
      if (input < 0) {
        dispatch(errorNotification(`Quantity can't be negative`));
        error = true;
      }

      if (!isInteger(input) && !allowDecimal) {
        dispatch(errorNotification(`Please enter a whole number`));
        error = true;
      }

      if (error) {
        setInputValue(quantity.toString());
        return;
      }

      changeItemInCart(-(quantity - input));
    }
  };

  return (
    <Container>
      <MinusButton
        disabled={isDisabled || quantity <= 1 || quantityInputFocused}
        onClick={() => changeItemInCart(-1)}
        automationId='quantity-selector_selector-button_minus'
      />
      <StyledBaseInput
        type='number'
        value={inputValue}
        disabled={isDisabled || quantity <= 0}
        onChange={(e: { target: { value: string } }) => setInputValue(e.target.value)}
        onKeyPress={(e: { key: string }) => handleQtySet(e.key)}
        automationId='quantity-selector_selector-button_input'
        onFocus={() => {
          setQuantityInputFocused(true);
        }}
        onBlur={() => {
          setQuantityInputFocused(false);
          handleQtySet('Enter');
        }}
      />
      <PlusButton
        disabled={isDisabled || quantity >= maxQuantity || quantityInputFocused}
        onClick={() => changeItemInCart(1)}
        automationId='quantity-selector_selector-button_plus'
      />
    </Container>
  );
};
