import { useMutation, useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { PaymentType } from 'models/Checkout';
import { useGeneriPay } from 'util/hooks/launch-darkly/useGeneriPay';

export const endpoints = {
  GET_PAYMENT_INTEGRATIONS_POS: 'generipay/get-payment-integrations-pos',
  CANCEL_PAYMENT: 'generipay/cancel',
};

export const generiPayQueryKey = 'generipay-payment-integrations-pos';

export type GeneriPayPaymentIntegration = {
  CamelizedIntegrationType: string;
  IntegrationType: string;
  Name: string;
  PosButtonText: string;
  PosReportingField: string;
  PosReportingFieldId: PaymentType;
  PaymentCaptureFields: string[];
  PaymentIntegrationDefinitionId: string;
};

export type GeneriPayCancelPaymentRequest = {
  ShipmentId: number | undefined;
  CamelizedIntegrationType: string;
};

export function useGeneriPayPaymentIntegrationsPOSQuery(RegisterId?: number) {
  const generiPayEnabled = useGeneriPay();

  return useQuery({
    queryKey: [generiPayQueryKey, RegisterId],
    queryFn: async () => {
      return  await post<GeneriPayPaymentIntegration[]>(endpoints.GET_PAYMENT_INTEGRATIONS_POS, { RegisterId });
    },
    enabled: generiPayEnabled,
  });
}

export function useGeneriPayCancelPaymentMutation() {
  const generiPayEnabled = useGeneriPay();

    return useMutation({
      mutationFn: async (body: GeneriPayCancelPaymentRequest) => {
        if (generiPayEnabled) {
          return await post(endpoints.CANCEL_PAYMENT, body);
        } else {
          return await Promise.reject();
        }
      },
  });
}
