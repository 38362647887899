import React from 'react';
import styled from 'styled-components';
import { Virtuoso } from 'react-virtuoso';

import { eloOriPadBreakpoint } from 'css/Theme';
import { Loader } from 'components/backoffice/loader';
import { CheckedInGuest } from 'models/Guest';
import { useOptionsStatus } from '../../useOptionsStatus';
import { DraggableCard } from './DraggableCard';

type Props = {
  orders: Array<CheckedInGuest>;
  posStatusId: number;
};

export const CardList = React.memo(CardListImpl);

function CardListImpl({ orders, posStatusId }: Props) {
  return (
    <VirtuosoComponent
      totalCount={orders?.length}
      data={orders}
      components={{
        Footer: () => <BottomPaddedSpace />,
      }}
      itemContent={(_, order) => <VirtuosoItem guest={order} posStatusId={posStatusId} />}
    />
  );
}

type VirtuosoItemProps = {
  guest: CheckedInGuest;
  posStatusId: number;
};

const VirtuosoItem = ({ guest, posStatusId }: VirtuosoItemProps) => {
  const { cardOptions, cardOptionsStatus } = useOptionsStatus({ posStatusId });

  return (
    <VirtuosoWrapper>
      {cardOptions && (
        <DraggableCard key={guest.ShipmentId} guest={guest} cardOptionsStatus={cardOptionsStatus} listMode={false} />
      )}
      {!cardOptions && (
        <SpinnerWrapperDiv>
          <Loader variant='grey' size='2x' data-testid='order-kaban-card_div_loading-spinner' />
        </SpinnerWrapperDiv>
      )}
    </VirtuosoWrapper>
  );
};

const VirtuosoComponent = styled(Virtuoso)`
  height: 100%;
  flex: 1 0 0%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const VirtuosoWrapper = styled.div`
  padding: 9px 12px 0;
`;

export const Container = styled.div`
  flex: 1 0 0%;
  margin-top: 7px;
  z-index: 1;
`;

export const BottomPaddedSpace = styled.div`
  pointer-events: none;
  height: 50px;
  flex: 0 0 50px;

  ${eloOriPadBreakpoint} {
    height: 28px;
    flex: 0 0 28px;
  }
`;
export const HeaderPadding = styled.div`
  height: 16px;
`;

export const SpinnerWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
