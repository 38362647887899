import React from 'react';

type GuestListIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function GuestListIcon(props: GuestListIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.2868 2.57129H18.8583C19.3129 2.57129 19.749 2.75191 20.0704 3.07339C20.3919 3.39488 20.5725 3.83091 20.5725 4.28557V21.4284C20.5725 21.8831 20.3919 22.3192 20.0704 22.6406C19.749 22.962 19.3129 23.1427 18.8583 23.1427H5.14397C4.68931 23.1427 4.25328 22.962 3.93178 22.6406C3.6103 22.3192 3.42969 21.8831 3.42969 21.4284V4.28557C3.42969 3.83091 3.6103 3.39488 3.93178 3.07339C4.25328 2.75191 4.68931 2.57129 5.14397 2.57129H7.7154'
        stroke={color}
        strokeWidth='1.71429'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.572 0.856934H9.42913C8.48236 0.856934 7.71484 1.62444 7.71484 2.57122V3.42836C7.71484 4.37513 8.48236 5.14265 9.42913 5.14265H14.572C15.5188 5.14265 16.2863 4.37513 16.2863 3.42836V2.57122C16.2863 1.62444 15.5188 0.856934 14.572 0.856934Z'
        stroke={color}
        strokeWidth='1.71429'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 10.9811C7 10.44 7.44608 10.0015 7.99634 10.0015H8.00879C8.55905 10.0015 9.00513 10.44 9.00513 10.9811C9.00513 11.5221 8.55905 11.9606 8.00879 11.9606H7.99634C7.44608 11.9606 7 11.5221 7 10.9811Z'
        fill={color}
      />
      <path
        d='M10.7363 10.9811C10.7363 10.44 11.1823 10.0015 11.7326 10.0015H15.4689C16.0191 10.0015 16.4652 10.44 16.4652 10.9811C16.4652 11.5221 16.0191 11.9606 15.4689 11.9606H11.7326C11.1823 11.9606 10.7363 11.5221 10.7363 10.9811Z'
        fill={color}
      />
      <path
        d='M7 15.879C7 15.338 7.44608 14.8994 7.99634 14.8994H8.00879C8.55905 14.8994 9.00513 15.338 9.00513 15.879C9.00513 16.42 8.55905 16.8586 8.00879 16.8586H7.99634C7.44608 16.8586 7 16.42 7 15.879Z'
        fill={color}
      />
      <path
        d='M10.7363 15.879C10.7363 15.338 11.1823 14.8994 11.7326 14.8994H13.6007H15.4689C16.0191 14.8994 16.4652 15.338 16.4652 15.879C16.4652 16.42 16.0191 16.8586 15.4689 16.8586H11.7326C11.1823 16.8586 10.7363 16.42 10.7363 15.879Z'
        fill={color}
      />
    </svg>
  );
}
