import styled from 'styled-components';

import { colors } from 'css/Theme';
import { LinkButton } from 'components/backoffice/button';
import { StyledSkeleton } from 'components/misc/Skeleton';

export const Container = styled.div<{ gap?: string; showBottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  gap: ${({ gap }) => (gap ? gap : '0.75rem')};

  ${({ showBottomBorder }) => showBottomBorder && `border-bottom: 1px solid ${colors.dutchie.grey90};`}
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

export const StyledLinkButton = styled(LinkButton)`
  padding: 0;
  min-width: unset;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
`;

export const AllotmentHeaderContainer = styled(Header)`
  padding: 1rem 1.5rem 0;
`;

export const AllotmentBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  position: sticky;
  top: 0;
  z-index: 1;

  background: white;
  border-bottom: 1px solid ${colors.dutchie.grey90};
  padding: 1rem 1.5rem;
`;

export const ProgressBar = styled.div`
  flex: 1;
  height: 8px;
  background: ${colors.dutchie.gray30};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
`;

export const Fillbar = styled.div<{ used: number; over: boolean }>`
  width: ${({ used }) => used.toFixed(2)}%;
  border-radius: 4px;
  height: 8px;
  background: ${({ over }) => (over ? colors.dutchie.red : colors.dutchie.green)};
`;

export const AllotmentAmounts = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.dutchie.grey30};
  font-size: 0.875rem;
  line-height: 1.25rem;
  gap: 0.25rem;
`;

export const ProgressBarSkeleton = styled(StyledSkeleton)`
  height: 12px;
  width: 100%;
  flex-grow: 1;
`;

export const LockedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${colors.dutchie.gray80};
  background: ${colors.dutchie.grey10};
  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 0.75rem;
`;
