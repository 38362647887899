import { useSelector } from 'react-redux';
import { State } from 'store';

type LoyaltySettings = {
  loyaltyEnabled: boolean;
  loyaltyPanelEnabled: boolean;
  showLoyaltyPaymentOption: boolean;
  applyLoyaltyAsDiscount: boolean;
};

export const useLoyaltySettings = (): LoyaltySettings => {
  const ShowLeafLogixLoyalty = useSelector((state: State) => state.settings.features.ShowLeafLogixLoyalty);
  const ShowExternalLoyalty = useSelector((state: State) => state.settings.features.ShowExternalLoyalty);
  const loyaltyEnabled = ShowLeafLogixLoyalty || ShowExternalLoyalty;

  const ApplyLoyaltyAsCashValue = useSelector((state: State) => state.settings.features.ApplyLoyaltyAsCashValue);
  const LoyaltyAsDiscount = useSelector((state: State) => state.settings.features.LoyaltyAsDiscount);

  const loyaltyPanelEnabled = loyaltyEnabled && ApplyLoyaltyAsCashValue;
  const showLoyaltyPaymentOption = loyaltyPanelEnabled && !LoyaltyAsDiscount;

  return {
    loyaltyEnabled,
    loyaltyPanelEnabled,
    showLoyaltyPaymentOption,
    applyLoyaltyAsDiscount: LoyaltyAsDiscount,
  };
};
