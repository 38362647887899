import React, { useEffect } from 'react';
import { ItemReturnValue, ItemReturnDetail, RefundType } from 'api/TransactionsApi';
import { ProductHistoryDetail } from 'models/Transactions';
import { callback } from 'models/Misc';
import { useDispatch, useSelector } from 'react-redux';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { loadReturnReasons } from 'store/actions/CustomerActions';
import { State } from 'store';
import { colors } from 'css/Theme';
import { LoadingButton } from 'components/buttons';
import { Label } from 'components/text';
import { Select } from 'components/inputs';

export type ReturnPopupProps = {
  isVisible: boolean;
  hide: callback;
  item: ProductHistoryDetail;
  returnInfo?: ItemReturnValue;
};
export type ReturnPopupEnhancedProps = {
  isVisible: boolean;
  hide: callback;
  item: ProductHistoryDetail;
  returnInfo: ItemReturnDetail;
};

export type useReturnPopupProps = {
  setManagerPin: (arg: string) => void;
  onRefund: () => void;
};

type ButtonStyleProps = {
  styles?: FlattenSimpleInterpolation;
};

type RefundDescriptionProps = {
  amount: number;
  cardNumberLastFour: string | null;
  refundType: string;
};

type ValueReturnedToPaymentMethodLabelProps = {
  amount: number;
  refundType: string;
};

type ReturnValueWithMethodPromptProps = {
  amount: number;
  refundType: string;
  defaultValue: 'Yes' | 'No';
  refundTypeMethods: { [key: string]: (arg: boolean) => void };
  disabled: boolean;
};

export const useReturnReasons = () => {
  const dispatch = useDispatch();

  return useEffect(() => {
    dispatch(loadReturnReasons());
  }, [dispatch]);
};

export const YesNoSelectOptions = [
  { key: 'Yes', value: 'Yes', label: 'Yes' },
  { key: 'No', value: 'No', label: 'No' },
];

export const useReturnPopup = ({ setManagerPin, onRefund }: useReturnPopupProps) => {
  const cart = useSelector((state: State) => state.cart);

  const onChangeManagerPin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManagerPin(e.target.value);
  };

  const confirmReturn = () => {
    !cart.returningItem && onRefund();
  };

  return { onChangeManagerPin, confirmReturn };
};

export const RefundDescription = ({ amount, refundType, cardNumberLastFour }: RefundDescriptionProps) => {
  const formattedAmount = `$${amount.toFixed(2)}`;
  switch (refundType) {
    case RefundType.Credit:
      return (
        <RefundDescriptionText>
          <RefundDescriptionBold>{formattedAmount}</RefundDescriptionBold> will be refunded to card ending in{' '}
          <RefundDescriptionBold>····{cardNumberLastFour}</RefundDescriptionBold>
        </RefundDescriptionText>
      );
    case RefundType.Debit:
      return (
        <RefundDescriptionText>
          <RefundDescriptionBold>{formattedAmount}</RefundDescriptionBold> will be refunded to card ending in{' '}
          <RefundDescriptionBold>····{cardNumberLastFour}</RefundDescriptionBold>
        </RefundDescriptionText>
      );
    case RefundType.Cash:
      return (
        <RefundDescriptionText>
          <RefundDescriptionBold>{formattedAmount}</RefundDescriptionBold> will be refunded in Cash
        </RefundDescriptionText>
      );
    default:
      return (
        <RefundDescriptionText>
          <RefundDescriptionBold>{formattedAmount}</RefundDescriptionBold> will be refunded in Cash
        </RefundDescriptionText>
      );
  }
};

export const ValueReturnedToPaymentMethodLabel = ({ refundType, amount }: ValueReturnedToPaymentMethodLabelProps) => {
  const formattedAmount = `$${amount.toFixed(2)}`;
  switch (refundType) {
    case RefundType.Credit:
      return <Label data-testid={'refund-method-label'}>{formattedAmount} Returned to Card via Credit?</Label>;
    case RefundType.Debit:
      return <Label data-testid={'refund-method-label'}>{formattedAmount} Returned to Card via Debit?</Label>;
    case RefundType.Cash:
      return <Label data-testid={'refund-method-label'}>{formattedAmount} Returned via Cash?</Label>;
    default:
      return <Label data-testid={'refund-method-label'}>{formattedAmount} Returned via Cash?</Label>;
  }
};

export const ReturnValueWithMethodPrompt = ({
  refundType,
  amount,
  refundTypeMethods,
  defaultValue,
  disabled,
}: ReturnValueWithMethodPromptProps) => {
  let setReturnMethodBoolean = (arg: boolean) => {};
  switch (refundType) {
    case RefundType.Cash:
      setReturnMethodBoolean = refundTypeMethods.Cash;
      break;
    case RefundType.Credit:
      setReturnMethodBoolean = refundTypeMethods.Credit;
      break;
    case RefundType.Debit:
      setReturnMethodBoolean = refundTypeMethods.Debit;
      break;
    default:
      setReturnMethodBoolean = (arg: boolean) => {};
      break;
  }
  return (
    <FieldWrapper data-testid={'refund-value-fieldset'}>
      <ValueReturnedToPaymentMethodLabel refundType={refundType} amount={amount} />
      <Select
        onChange={(value) => setReturnMethodBoolean(value === 'Yes')}
        defaultValue={defaultValue}
        options={YesNoSelectOptions}
        disabled={disabled}
      />
    </FieldWrapper>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
`;

export const ButtonsContainer = styled(Container)`
  box-shadow: 0px -1px 0px ${colors.dutchie.shadowGrey};
  margin-top: 32px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ProductLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${colors.dutchie.grey50};
  margin-bottom: 8px;
`;

export const RefundDescriptionText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.dutchie.darkGrey};
  margin-bottom: 24px;
`;

export const RefundDescriptionBold = styled.span`
  font-weight: 700;
`;

export const Buttons = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const CancelButton = styled.button<ButtonStyleProps>`
  font-size: 1rem;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: ${colors.white};
  border: none;
  cursor: pointer;
  ${({ styles }) => styles};
`;

export const ConfirmButton = styled(LoadingButton)<ButtonStyleProps>`
  ${({ styles }) => styles};
`;
