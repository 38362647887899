import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { DeliveryRoutesTable } from './DeliveryRoutesTable';
import { clamp, round } from 'lodash';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { DeliveryRoutesTableV2 } from './DeliveryRoutesTableV2';
import { useDeliveryPageEnhancements } from 'util/hooks/launch-darkly/useDeliveryPageEnhancements';

export const DeliveryTableSection: FC = React.memo(() => {
  const isUseDeliveryPageEnhancements = useDeliveryPageEnhancements();

  const deliveryMaxTotalInventoryDollars =
    useSelector((state: State) => state.settings.locationSettings?.DeliveryMaxTotalInventoryDollars) || 0;
  const hasDeliveryMaxTotalInventoryDollars = !!useSelector(
    (state: State) => state.settings.locationSettings?.DeliveryMaxTotalInventoryDollars
  );
  const selectedDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);
  const selectedDollarValue = round(
    selectedDeliveries.reduce((acc, d) => acc + d.InvoiceTotal, 0),
    2
  );

  const selectedDollarPercent = clamp(selectedDollarValue / deliveryMaxTotalInventoryDollars, 0, 100) * 100;

  return (
    <TableWrapper>
      {hasDeliveryMaxTotalInventoryDollars && (
        <>
          <ThresholdContainer>
            <ThresholdLabel>Selected Inventory Threshold</ThresholdLabel>
            <ThresholdStats>
              ${selectedDollarValue.toFixed(2)} / ${deliveryMaxTotalInventoryDollars.toFixed(2)}
            </ThresholdStats>
          </ThresholdContainer>
          <ProgressBar>
            <Fillbar used={selectedDollarPercent} over={selectedDollarValue > deliveryMaxTotalInventoryDollars} />
          </ProgressBar>
        </>
      )}
      {isUseDeliveryPageEnhancements ? <DeliveryRoutesTableV2 /> : <DeliveryRoutesTable />}
    </TableWrapper>
  );
});

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  & tr[draggable='true'] {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`;

export const ThresholdContainer = styled.div`
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dutchie.almostBlack};
  margin: 0 1rem 0 1rem;
`;

const ThresholdLabel = styled.div`
  height: 14px;
  text-align: left;
`;

const ThresholdStats = styled.div`
  text-align: right;
  align-self: flex-end !important;
  color: ${colors.dutchie.grey};
`;

const ProgressBar = styled.div`
  height: 8px;
  background: ${colors.dutchie.shadowGrey};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin: 1rem;
`;

const Fillbar = styled.div<{ used: number; over: boolean }>`
  width: ${({ used }) => used.toFixed(2)}%;
  border-radius: 8px;
  height: 8px;
  background: ${({ over }) => (over ? colors.dutchie.red : colors.dutchie.moss)};
`;
