import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { BackButton } from 'components/buttons';
import { colors } from 'css/Theme';
import { DrawerHeader } from '../../PanelDrawerWrapper';
import { PrepaymentPayInfo } from 'models/Cart';
import { PaymentType } from 'models/Checkout';
import { State } from 'store';
import { setDutchiePayPreAuthError } from 'store/actions/CartActions';
import { addPaymentMethod, quitCheckout } from 'store/actions/CheckoutActions';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';
import { CheckoutContext } from '../CheckoutContext';
import { PaymentButtons } from '../PaymentButtons';
import {
  PaymentCash,
  PaymentCheck,
  PaymentCredit,
  PaymentDebit,
  PaymentDigital,
  PaymentDutchiePay,
  PaymentGift,
  PaymentMMAP,
  PaymentHub,
  PaymentManual,
  useCancelHubTransaction,
} from '../payments';
import * as paymentIcons from 'assets/icons/payments';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useUpdateCartFeesMutation } from 'queries/v2/cart/update-cart-fees';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { GeneriPayPaymentIntegration } from 'queries/v2/payments/generipay';
import { PaymentGeneriPay } from '../payments/PaymentGeneriPay';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { showHubBackClickPopup } from 'store/actions/PopupsActions';
import { useConfirmationModalOnHubBackClick } from 'util/hooks/launch-darkly/useConfirmationModalOnHubBackClick';

export const PaymentOptions: FC = () => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const { integrations } = useSelector((state: State) => state.settings);
  const checkout = useSelector((state: State) => state.checkout);
  const isDutchiePayError = useSelector((state: State) => state.cart.isDutchiePayError);
  const { selectedPaymentType, setSelectedPaymentType, paymentTypeMetaData, setPaymentTypeMetaData } =
    useContext(CheckoutContext);

  const { dutchiePayPreAuthError, showDutchiePayPreAuthCheckout } = useDutchiePayPreAuth();
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);

  const { guestId, shipmentId } = useTransactionManager();

  const { data: cart } = useGetCartDetails();
  const { mutateAsync: updateCartFees } = useUpdateCartFeesMutation();

  const preAuthInfo = cart.PreauthInfo;
  const isPreAuth = preAuthInfo && preAuthInfo.PreauthAmount > 0;
  const isPaymentsHubDutchiePay = isPreAuth && selectedPaymentType === PaymentType.Hub;

  const isDutchiePayPreAuth = isPreAuth && preAuthInfo.PaymentType === 'dutchiepay';

  const isPaymentTypeSelected = selectedPaymentType !== null;
  const showConfirmationModalOnHubBackClick = useConfirmationModalOnHubBackClick();

  useEffect(() => {
    //Aeropay Remote Pay hack to add the payment data into the list of items already paid, and not allowing the budtender to remove it
    if (
      cart.Payment?.ElectronicType === 'gtipay-remotepay' &&
      !checkout.payment?.methods?.some((x) => x.name === 'Bank Transfer')
    ) {
      dispatch(
        addPaymentMethod({
          id: uuidv4(),
          name: 'Bank Transfer',
          type: PaymentType.Digital,
          amount: cart.Payment?.ElectronicAmount,
          finalized: true,
        })
      );
    }
    if (
      cart.PrepaymentPayInfo &&
      cart.PrepaymentPayInfo.length > 0 &&
      !checkout.payment?.methods?.some((x) => x.type === PaymentType.Prepayment)
    ) {
      const prePaymentInfo = cart.PrepaymentPayInfo;
      let amount = 0;
      let type = '';
      prePaymentInfo.forEach((item: PrepaymentPayInfo) => {
        amount += item.Amount;
        type = item.PrepaymentType;
      });
      dispatch(addPaymentMethod({ id: uuidv4(), name: type, type: PaymentType.Prepayment, amount, finalized: true }));
    }
    if (
      !isDutchiePayError &&
      isDutchiePayPreAuth &&
      !checkout.payment?.methods?.some((x) => x.type === PaymentType.Dutchie)
    ) {
      const amount = cart.GrandTotalRounded;
      const tipAmount = cart.TipAmount;
      const type = preAuthInfo.PaymentType.toString();
      const dutchiePayDoubleChargeFixRollout = ldClient?.variation(
        'fintech.retail-payments.pos.dutchiepay-double-charge.rollout',
        false
      );
      const preauthId = dutchiePayDoubleChargeFixRollout ? preAuthInfo.PreauthId : uuidv4();

      dispatch(
        addPaymentMethod({
          id: preauthId,
          name: type,
          type: PaymentType.Dutchie,
          amount,
          tipAmount,
          finalized: true,
          dutchiePayDoubleChargeFixRollout,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.Payment, cart.PrepaymentPayInfo, preAuthInfo, dispatch]);

  const onPaymentButtonClick =
    (paymentType: PaymentType, paymentTypeMetaData: GeneriPayPaymentIntegration | null = null) =>
    async () => {
      await updateCartFees({
        guestId: guestId ?? 0,
        selectedPaymentType: paymentType,
        shipmentId: shipmentId ?? 0,
      });
      setSelectedPaymentType(paymentType);
      setPaymentTypeMetaData(paymentTypeMetaData);
    };

  const cancelPaymentsHub = useCancelHubTransaction();

  const clearPaymentAndReloadCart = useCallback(() => {
    updateCartFees({
      guestId: guestId ?? 0,
      selectedPaymentType: null,
      shipmentId: shipmentId ?? 0,
    });

    setSelectedPaymentType(null);
    setPaymentTypeMetaData(null);
  }, [guestId, setPaymentTypeMetaData, setSelectedPaymentType, shipmentId, updateCartFees]);

  const onBackClick = useCallback(async () => {
    if (isPaymentTypeSelected) {
     if (digitalPaymentLoading && selectedPaymentType === PaymentType.Hub && integrations?.UsePaymentsHub) {
        if (showConfirmationModalOnHubBackClick) {
          dispatch(showHubBackClickPopup({
            onCancel: async () => {
              await cancelPaymentsHub();
              clearPaymentAndReloadCart();
            },
          }));

          return;
        }

        await cancelPaymentsHub();
      }
      clearPaymentAndReloadCart();
    } else {
      dispatch(setDutchiePayPreAuthError(false));
      dispatch(quitCheckout());
    }
  }, [
    cancelPaymentsHub,
    clearPaymentAndReloadCart,
    digitalPaymentLoading,
    dispatch,
    integrations,
    isPaymentTypeSelected,
    selectedPaymentType,
    showConfirmationModalOnHubBackClick,
  ]);

  const renderCurrentPayment = () => {
    switch (selectedPaymentType) {
      case PaymentType.Cash:
        return <PaymentCash />;
      case PaymentType.Debit:
        return <PaymentDebit />;
      case PaymentType.Credit:
        return <PaymentCredit />;
      case PaymentType.MMAP:
        return <PaymentMMAP />;
      case PaymentType.Check:
        return <PaymentCheck />;
      case PaymentType['Gift Card']:
        return <PaymentGift />;
      case PaymentType.Digital:
        return <PaymentDigital />;
      case PaymentType.DutchiePay:
        return <PaymentDutchiePay />;
      case PaymentType.Hub:
        return <PaymentHub />;
      case PaymentType.Manual:
        return <PaymentManual />;
      case PaymentType.GeneriPay:
        return <PaymentGeneriPay PaymentTypeMetaData={paymentTypeMetaData} />;
    }
  };

  const sectionIcon = useMemo(() => {
    if (!isPaymentTypeSelected) {
      return null;
    }

    if (showDutchiePayPreAuthCheckout) {
      return null;
    }

    switch (selectedPaymentType) {
      case PaymentType.Cash:
        return <paymentIcons.CashIcon />;
      case PaymentType.Check:
        return <paymentIcons.CheckIcon />;
      case PaymentType.Credit:
        return <paymentIcons.CardIcon />;
      case PaymentType.Debit:
        return <paymentIcons.DebitIcon />;
      case PaymentType.Digital:
        return <paymentIcons.DigitalIcon />;
      case PaymentType['Gift Card']:
        return <paymentIcons.GiftCardIcon />;
      case PaymentType.MMAP:
        return <paymentIcons.MMAPIcon />;
      case PaymentType.Hub:
        return <paymentIcons.HubIcon />;
      case PaymentType.Manual:
        return <paymentIcons.ManualIcon />;
      case PaymentType.GeneriPay:
        return <paymentIcons.DebitIcon />;
    }
  }, [
    isPaymentTypeSelected,
    selectedPaymentType,
    showDutchiePayPreAuthCheckout,
  ]);

  const sectionTitle = useMemo(() => {
    if (isPaymentsHubDutchiePay) {
      return 'Dutchie Hub';
    }

    if (showDutchiePayPreAuthCheckout) {
      return 'Back to cart';
    }

    const notInPreAuthFlow = !isPreAuth || isDutchiePayError || dutchiePayPreAuthError;

    if (notInPreAuthFlow && isPaymentTypeSelected) {
      if (selectedPaymentType === PaymentType['MMAP']) {
        return 'Medical Marijuana Assistance Program';
      }
      // See ENG-62820 and comment in `PaymentButtons.tsx` for context
      if (selectedPaymentType === PaymentType.Debit && integrations?.DebitProcessor === 'usag') {
        return 'Terminal';
      }
      if (selectedPaymentType === PaymentType.Hub) {
        return 'Dutchie Hub';
      }
      if (paymentTypeMetaData != null) {
        return paymentTypeMetaData.Name;
      }
      return PaymentType[selectedPaymentType as PaymentType];
    }

    return 'Select payment type';
  }, [
    dutchiePayPreAuthError,
    integrations,
    isDutchiePayError,
    isPaymentTypeSelected,
    isPaymentsHubDutchiePay,
    isPreAuth,
    paymentTypeMetaData,
    selectedPaymentType,
    showDutchiePayPreAuthCheckout,
  ]);

  const { isCompactLayout } = useCompactCartLayout();

  return (
    <Container>
      {isCompactLayout ? (
        <DrawerHeader
          onGoBack={isPaymentsHubDutchiePay || !isPaymentTypeSelected ? undefined : onBackClick}
          actionProps={{
            label: !isPaymentTypeSelected ? 'Back to cart' : 'Close',
            disabled: isPaymentsHubDutchiePay,
            onClick: onBackClick,
          }}
        >
          {sectionIcon}
          {sectionTitle}
        </DrawerHeader>
      ) : (
        <TitleBox>
          {!isPaymentsHubDutchiePay && <BackButton onClick={onBackClick} />}
          <Title>
            {sectionIcon}
            {sectionTitle}
          </Title>
        </TitleBox>
      )}
      {isPaymentTypeSelected ? (
        <Payment>{renderCurrentPayment()}</Payment>
      ) : (
        <PaymentButtons handleClick={onPaymentButtonClick} />
      )}
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const TitleBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  gap: 1rem;
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
`;

const Payment = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.dutchie.primaryWhite};
  flex-grow: 1;
`;
