import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { DatePicker } from 'components/inputs';
import { backdateTransaction } from 'api/CartApi';
import { isValid, format, setHours } from 'date-fns';
import { errorNotification } from 'store/actions/NotificationsActions';
import { callback } from 'models/Misc';
import { useDispatch } from 'react-redux';

const WrapperDiv = styled.div`
  padding: 0 1.5rem;
`;

type BackdateTransactionPopupProps = {
  hide: callback;
  shipmentId?: number;
};

export const BackdateTransactionPopup: FC<BackdateTransactionPopupProps> = ({ hide, shipmentId }) => {
  const dispatch = useDispatch();
  const [backDate, setBackDate] = useState<Date | undefined>();

  const doBackdate = async () => {
    if (!isValid(backDate)) {
      dispatch(errorNotification('Add a valid date'));
      return;
    }
    if (!shipmentId) {
      dispatch(errorNotification('Invalid shipment id'));
      return;
    }

    if (backDate) {
      await backdateTransaction({
        ShipmentId: shipmentId,
        ActualDate: format(setHours(backDate, 12), 'yyyy-MM-dd hh:mm:ss'),
      });
    }
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Back Date'
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
      confirm={{
        text: 'Save',
        disabled: !backDate,
        onClick: async () => {
          await doBackdate();
          hide();
        },
      }}
    >
      <WrapperDiv>
        <DatePicker selected={backDate} onChange={(val: Date) => setBackDate(val)} />
      </WrapperDiv>
    </ConfirmationPopup>
  );
};
