import { useFormikContext } from "formik";

import { CfdSettingsContextData, ExperimentalWindow, ScreenDetailed } from "../types";
import { HardwareSettings } from "../../types";
import { useEffect, useState } from "react";
import { SettingsSelectOption } from "pages/SettingsPage/Select/SettingsSelect";
import { getScreenDetailedId } from "../utils";

export const useCfdSettingsProvider = (): CfdSettingsContextData => {
  const { values: formValues, setFieldValue, initialValues } = useFormikContext<HardwareSettings>();
  const expWindow = window as ExperimentalWindow;
  const [hasGrantedScreenPermission, setHasGrantedScreenPermission] = useState<boolean>(false);
  const [screens, setScreens] = useState<ScreenDetailed[]>([]);

  const setOpenOnLogin = (value: boolean) => {
    setFieldValue('openCfdOnLogin', value);
  };

  const defaultScreenName = 'Primary screen (default)';
  const screenOptions = [
    {
      label: defaultScreenName,
      value: 'primary-window',
    },
    ...screens.map(screen => {
      return {
        label: screen.label,
        value: getScreenDetailedId(screen),
      } as SettingsSelectOption;
    }),
  ];

  const preferredScreenName = screenOptions.find(option => option.value === initialValues.secondaryScreenId)?.label ?? defaultScreenName;

  const isScreenDetailsAvailable = !!expWindow.getScreenDetails;

  const requestScreenPermissions = () => updateAvailableScreens();

  const setSecondaryScreen = (value: string) => {
    setFieldValue('secondaryScreenId', value);
    window.dispatchEvent(new CustomEvent('closeCartDisplay'));
  };

  const isScreenPermissionGranted = async () => {
    try {
      if ('permissions' in navigator) {
        /* @ts-ignore - window-management is not in normal type definitions */
        const result = await navigator.permissions.query({ name: 'window-management' });
        return result.state === 'granted';
      }
      return false;
    } catch (e) {
      // browser does not support the window-management api
      return false;
    }
  };

  const updateAvailableScreens = async (): Promise<ScreenDetailed[]> => {
    try {
      if (!expWindow.getScreenDetails) {
        return [];
      }

      // Request screen details. Will prompt user for permission first time
      const result = await expWindow.getScreenDetails();
      setScreens(result.screens);
      return result.screens;
    } catch (e) {
      /* no-op */
    }
    return [];
  };

  useEffect(() => {
    (async () => {
      const result = await isScreenPermissionGranted();
      setHasGrantedScreenPermission(result);
    })();
  }, []);

  useEffect(() => {
    if (!hasGrantedScreenPermission) {
      return;
    }

    updateAvailableScreens();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGrantedScreenPermission]);

  return {
    availableScreens: screens,
    hasGrantedScreenPermission,
    isScreenDetailsAvailable,
    formValues,
    availableScreensOptions: screenOptions,
    preferredScreenName,
    requestScreenPermissions,
    setOpenOnLogin,
    setSecondaryScreen,
  };
};
