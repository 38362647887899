import React from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { Button } from 'components/buttons';
import { PopupOverlay } from 'components/popups';
import { ModalCloseButton, ModalContent, ModalFooter } from 'components/modals/Modal';
import { Input } from 'components/inputs';
import { PasswordIcon } from 'assets/icons/password-icon';
import { UsernameInputIcon } from 'assets/username-input-icon';
import { resetPinLogin, forgotPassword } from 'api/PosApi';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { endSessionLogout } from './utils/endSessionLogout';
import { PopupType } from './types';
import { ssoLogin } from 'store/actions/UserActions';
import { redirectTo } from 'util/helpers/redirectTo';

type PinPopupProps = {
  hide: () => void;
  openForgotPassword: () => void;
  openForgotPasswordSuccessful: () => void;
  setNewPIn: (pin: string) => void;
  popupType: PopupType;
};

interface PinPopupDisplayProps {
  popupType?: PopupType;
}

export function PinPopup({
  openForgotPassword,
  openForgotPasswordSuccessful,
  hide,
  setNewPIn,
  popupType,
}: PinPopupProps) {
  const selectedLsp = useSelector((state: State) => state.user.selectedLsp);
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [formHasError, setFormHasError] = React.useState<boolean>(false);
  const [resetWithSSO, setResetWithSSO] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const isForgotPasswordType = popupType === 'forgotPassword';
  const isPinResetType = popupType === 'resetPin';
  const isPasswordResetSuccess = popupType === 'passwordResetSuccess';
  const isContinueDisabled = isPinResetType ? !username || !password : !username;
  const isSSODisabled = isPinResetType ? !username : false;
  const secondaryBtnCopy = isPinResetType ? 'Forgot password' : 'Cancel';
  const headerText = isPinResetType ? 'Reset your PIN' : isPasswordResetSuccess ? 'Email sent!' : 'Forgot password';
  const bodyText = isPinResetType
    ? 'To reset your PIN, start by entering your username and password'
    : isPasswordResetSuccess
    ? "If your email is in our system, you'll get a password reset link. If you need further assistance, please contact your manager."
    : '';
  const resetText = resetWithSSO ? 'Reset with username and password' : 'Reset with SSO';
  const shouldDisplayPasswordInput =
    !isForgotPasswordType &&
    !isPasswordResetSuccess &&
    isPinResetType &&
    !resetWithSSO;

  const handleContinue = async () => {
    const isLoginPage = window.location.pathname === '/login' || window.location.pathname === '/';
    if (isPasswordResetSuccess && !isLoginPage) {
      endSessionLogout().then(() => {
        redirectTo('/login');
      });
    } else if (isPasswordResetSuccess && isLoginPage) {
      hide();
    } else if (isPinResetType) {
      const result = await resetPinLogin(username, password, selectedLsp?.OrgId ?? 0);
      const isSuccessful = result?.data?.Result === true;
      if (isSuccessful) {
        hide();
        setNewPIn(result?.data?.Data?.Pin);
      } else {
        setFormHasError(true);
      }
    } else if (isForgotPasswordType) {
      await forgotPassword(username);
      openForgotPasswordSuccessful();
    } else if (isPasswordResetSuccess) {
      hide();
    }
  };

  const handleSecondaryBtn = () => {
    if (isPinResetType) {
      openForgotPassword();
    } else {
      hide();
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isContinueDisabled && (isPinResetType || isForgotPasswordType)) {
      handleContinue();
    }
  };

  const handleContinueSSO = async () => {
    const isResetPinLogin = true;
    dispatch(ssoLogin({ username, isResetPinLogin }));
  };

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer data-testid='pin-login-modal-container' onKeyDown={handleEnterKey}>
        <ModalHeader>
          <ModalCloseButton onClick={hide} />
        </ModalHeader>
        <ModalContent>
          <Content>
            <DefaultState>
              <HeaderText data-testid='pin-login-modal-title'>{headerText}</HeaderText>
              {!isForgotPasswordType && <BodyText popupType={popupType}>{bodyText}</BodyText>}
              {!isPasswordResetSuccess && (
                <div style={{ width: '100%', marginBottom: !isForgotPasswordType ? 16 : 0 }}>
                  <Input
                    automationId='pin-login-modal-username-input'
                    hasError={formHasError}
                    autoFocus={true}
                    width={'100%'}
                    placeholder='Username'
                    startAdornment={<UsernameInputIcon />}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                </div>
              )}
              {shouldDisplayPasswordInput && (
                <div style={{ width: '100%' }}>
                  <Input
                    automationId='pin-login-modal-password-input'
                    hasError={formHasError}
                    type='password'
                    width={'100%'}
                    placeholder='Password'
                    startAdornment={<PasswordIcon />}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>
              )}
            </DefaultState>
            {formHasError && isPinResetType && (
              <BodyText
                data-testid='pin-login-error-message'
                style={{ color: colors.dutchie.red, marginTop: 8, textAlign: 'left' }}
              >
                Invalid username or password
              </BodyText>
            )}
          </Content>
        </ModalContent>
        <ModalFooter>
          {isPasswordResetSuccess ? (
            <Button automationId='pin-login-modal-primary-btn' cta fullWidth onClick={handleContinue}>
              Return to login
            </Button>
          ) : (
            <>
              {!resetWithSSO && (
                <>
                  <Button
                    automationId='pin-login-modal-secondary-btn'
                    cta
                    tertiary
                    fullWidth
                    onClick={() => handleSecondaryBtn()}
                  >
                    {secondaryBtnCopy}
                  </Button>

                  <Button
                    automationId='pin-login-modal-primary-btn'
                    cta
                    disabled={isContinueDisabled}
                    fullWidth
                    onClick={handleContinue}
                  >
                    Continue
                  </Button>
                </>
              )}
              {resetWithSSO && (
                <Button
                  automationId='pin-login-sso-modal-primary-btn'
                  cta
                  disabled={isSSODisabled}
                  fullWidth
                  onClick={handleContinueSSO}
                >
                  Continue with SSO
                </Button>
              )}
            </>
          )}
        </ModalFooter>
        {!isForgotPasswordType && !isPasswordResetSuccess && (
          <ResetLinkDiv>
            <ResetLink
              data-testid='pin-login-modal-reset-link'
              onClick={() => {
                setResetWithSSO(!resetWithSSO);
              }}
            >
              {resetText}
            </ResetLink>
          </ResetLinkDiv>
        )}
      </ModalContainer>
    </PopupOverlay>
  );
}

const Content = styled.div`
  padding: 0 40px 32px;
`;

const DefaultState = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HeaderText = styled.span`
  display: block;
  color: ${colors.dutchie.almostBlack};
  font-size: 32px;
  line-height: 30px;
  font-weight: 700;
  top: -20px;
  position: relative;
  margin-bottom: 25px;
`;

const BodyText = styled.span<PinPopupDisplayProps>`
  display: block;
  text-align: center;
  color: ${colors.dutchie.black};
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: ${(props) => (props.popupType !== 'passwordResetSuccess' ? '24px' : '0')};
`;

const ModalHeader = styled.div`
  padding: 16px 16px 0;
`;

const ModalContainer = styled.div`
  background: ${colors.dutchie.primaryWhite};
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
`;

const ResetLink = styled.a`
  color: ${colors.dutchie.blue};
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-top: 16px;
  cursor: pointer;
  text-align: center;
`;

const ResetLinkDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 40px;
`;
