import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';

import { compareObjects } from 'util/helpers/compareObjects';
import { createCustomer } from 'store/actions/CustomerActions';
import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { customEventKeys, logger } from 'util/logger';
import { useAppDispatch } from 'util/hooks';

import type { CustomerDetails } from 'models/Customer';
import type { PotentialDuplicateCustomerModalProps } from './PotentialDuplicateCustomerModal';

const customDateFormatter = (value: CustomerDetails[keyof CustomerDetails]) => {
  if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
    return formatDate({ date: new Date(value) });
  }
};

export const usePotentialDuplicateCustomerModal = ({
  existingCustomerDetails,
  newCustomerDetails,
  hide,
}: Pick<PotentialDuplicateCustomerModalProps, 'existingCustomerDetails' | 'newCustomerDetails' | 'hide'>) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isCreatingNewCustomer, setIsCreatingNewCustomer] = useState(false);

  const matchingAttributes = useMemo(() => {
    const attributes: Record<string, boolean> = {};

    const attributesToCheck = [
      'FirstName',
      'LastName',
      'Guest_id',
      'DOB',
      'PhoneNo',
      'CellPhone',
      'Email',
      'CustomerTypeId',
      'MJStateIDNo',
    ] as const;

    attributesToCheck.forEach((key) => {
      attributes[key] = compareObjects({
        key,
        left: existingCustomerDetails,
        right: newCustomerDetails,
        format: key === 'DOB' ? customDateFormatter : undefined,
      });
    });

    return attributes;
  }, [existingCustomerDetails, newCustomerDetails]);

  const showMJStateId = useMemo(() => {
    return !!existingCustomerDetails?.MJStateIDNo || !!newCustomerDetails?.MJStateIDNo;
  }, [existingCustomerDetails, newCustomerDetails]);

  const handleClickCreateNewProfile = async () => {
    setIsCreatingNewCustomer(true);
    const createdCustomer = await dispatch(createCustomer(newCustomerDetails)).unwrap();
    if (createdCustomer) {
      history.push({ search: `?id=${createdCustomer.Guest_id}` });
    }
    setIsCreatingNewCustomer(false);
    hide();
  };

  const handleClickUseExistingProfile = () => {
    hide();
    history.push({ search: `?id=${existingCustomerDetails.Guest_id}` });
  };

  useEffect(() => {
    logger.debug('potential duplicate customer modal mounted', {
      key: customEventKeys.modalMounted,
      existingCustomerDetails,
      newCustomerDetails,
    });
  }, [existingCustomerDetails, newCustomerDetails]);

  return {
    handleClickCreateNewProfile,
    handleClickUseExistingProfile,
    isCreatingNewCustomer,
    matchingAttributes,
    showMJStateId,
  };
};
