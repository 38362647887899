import { CardStatusDisplayOptionsResponse } from 'models/Guest';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { DEFAULT_CARD_OPTIONS } from './constants';

type UseOptionsStatusArgs = {
  posStatusId?: number;
};
export const useOptionsStatus = ({ posStatusId }: UseOptionsStatusArgs) => {
  const cardStatusDisplayOptions = useSelector((state: State) => state.guestList.cardStatusDisplayOptions);

  // Find the display options for the status of these orders.
  const cardOptions: CardStatusDisplayOptionsResponse | undefined = useMemo(() => {
    if (cardStatusDisplayOptions.length > 0 && posStatusId) {
      return cardStatusDisplayOptions.find((cardStatusDisplayOption: { StatusId: number | undefined }) => {
        return cardStatusDisplayOption.StatusId === posStatusId;
      });
    } else {
      return DEFAULT_CARD_OPTIONS;
    }
  }, [posStatusId, cardStatusDisplayOptions]);

  // Transform the display options to a structure where the key is the name of the option and the value is a boolean indicating if it's enabled or not.
  const cardOptionsStatus: { [key: string]: boolean } = useMemo(() => {
    const status: { [key: string]: boolean } = {};
    cardOptions?.Options.forEach((option) => {
      status[option.DisplayOptionName] = option.IsEnabled;
    });
    return status;
  }, [cardOptions]);

  return { cardOptions, cardOptionsStatus };
};
