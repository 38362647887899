import React, { useMemo } from 'react';
import { Delivery } from 'models/Delivery';
import { DisplayDateRange } from 'components/text';
import { DecoratedDeliveryRoute } from './useDecoratedDeliveryRoutes';
import styled from 'styled-components';
import { DeliveryStatus } from 'models/DeliveryRoute';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/notification-warning-alt.svg';
import { utcToZonedTime, format } from 'date-fns-tz';
import { GreenPill, OrangePill, DarkBluePill, LightGreenPill, RedPill, BluePill, GreyPill } from 'components/misc';

export const PillWrapper = styled.span`
  white-space: nowrap;
`;

const OrangeWarningIcon = styled(WarningIcon)`
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-bottom: -6px;
  cursor: help;
  g {
    stroke: orange;
  }
`;

const GreenRefreshIcon = styled(RefreshIcon)`
  color: #34d2af;
`;

type CellProps<Item> = {
  item: Item;
  propKey?: keyof Item;
  cellFormat?: 'date' | 'dollars' | 'time' | 'yes-no' | 'string' | 'number';
  bold?: boolean;
};

export function TableCell<Item extends Record<string, unknown>>({
  item,
  propKey,
  cellFormat = 'string',
  bold,
}: CellProps<Item>) {
  const rawValue = propKey ? item[propKey] : null;

  const value = useMemo(() => {
    if (rawValue === undefined || rawValue === null) {
      return '';
    }

    const valueAsString = `${rawValue ?? ''}`;
    switch (cellFormat) {
      case 'date':
        return format(utcToZonedTime(valueAsString, Intl.DateTimeFormat().resolvedOptions().timeZone), 'yyyy-MM-dd');
      case 'time':
        return format(
          utcToZonedTime(valueAsString, Intl.DateTimeFormat().resolvedOptions().timeZone),
          'yyyy-MM-dd hh:mm'
        );
      case 'dollars':
        if (typeof rawValue !== 'number') {
          return '$0.00';
        }
        return `${rawValue < 0 ? '-' : ''}$${Math.abs(rawValue).toFixed(2)}`;
      case 'yes-no':
        return rawValue ? 'Yes' : 'No';
      case 'string':
      case 'number':
        return valueAsString;
    }
  }, [cellFormat, rawValue]);

  const isNumerical = ['dollars', 'number'].includes(cellFormat);

  return (
    <div style={{ fontWeight: bold ? 'bold' : 'inherit', textAlign: isNumerical ? 'right' : 'left' }}>{value}</div>
  );
}

export const RouteCell = (props: CellProps<DecoratedDeliveryRoute>) => <TableCell {...props} />;
export const DeliveryCell = (props: CellProps<Delivery>) => <TableCell {...props} />;

export const RouteTimeCell = ({ item }: { item: DecoratedDeliveryRoute }) => (
  <div>
    <DisplayDateRange from={item.FromTime} to={item.ToTime} />
  </div>
);

export const DeliveryWindowCell = ({ item }: { item: Delivery }) => (
  <div>
    <DisplayDateRange from={item.DepartureDate} to={item.ArrivalDate} />
  </div>
);

export const DeliveryAddressCell = ({ item }: { item: Delivery }) => {
  const streetAddr = `${item.street} ${item.street2}`.trim();
  return (
    <div>
      {streetAddr}, {item.city}, {item.state}, {item.postal_code}
    </div>
  );
};
export const DeliveryDispatchCell = ({ item }: { item: Delivery }) => (
  <div>{item.DeliveryRouteId && (!item.IsAutoDispatched ? item.AssignedToRouteByName : <GreenRefreshIcon />)}</div>
);

export const DeliveryStatusCell = ({ item }: { item: Delivery }) => {
  return deliveryStatusCell(item.DeliveryStatusId, item.DeliveryStatus || 'Created');
};

export const RouteStatusCell = ({ item }: { item: DecoratedDeliveryRoute }) => {
  return (
    <PillWrapper>
      {deliveryStatusCell(item.undecoratedRoute?.DeliveryStatusId, item.Status || 'Created')}
      {item.NeedsDirections ? (
        <OrangeWarningIcon title='If stop order is changed, directions should be updated via the Edit Route / Get Directions buttons' />
      ) : null}
    </PillWrapper>
  );
};

function deliveryStatusCell(statusId: number | undefined, text: string) {
  if (statusId === DeliveryStatus.Delivered) {
    return <GreenPill>{text}</GreenPill>;
  } else if (statusId === DeliveryStatus.Undeliverable) {
    return <OrangePill>{text}</OrangePill>;
  } else if (statusId === DeliveryStatus.OutForDelivery) {
    return <DarkBluePill>{text}</DarkBluePill>;
  } else if (statusId === DeliveryStatus.Ready) {
    return <BluePill>{text}</BluePill>;
  } else if (statusId === DeliveryStatus.Completed) {
    return <LightGreenPill>{text}</LightGreenPill>;
  } else if (statusId === DeliveryStatus.Cancelled) {
    return <RedPill>{text}</RedPill>;
  }
  return <GreyPill>{text}</GreyPill>;
}
