import { createAction } from '@reduxjs/toolkit';
import { CartPopupsPayloads } from 'store/reducers/PopupsReducer/CartPopupsReducer';

export const showChangeCustomerTypePopup = createAction('showChangeCustomerTypePopup', () => ({ payload: {} }));

export const showLast4Popup = createAction('showLast4Popup', (payload: CartPopupsPayloads['showLast4Popup']) => ({
  payload,
}));

export const showNotesPopup = createAction('showNotesPopup', (payload: CartPopupsPayloads['showNotesPopup']) => ({
  payload,
}));

export const showPaymentMethodDisabledPopup = createAction(
  'showPaymentMethodDisabledPopup',
  (payload: CartPopupsPayloads['showPaymentMethodDisabledPopup']) => ({ payload })
);

export const showProductBeingAddedPastUsedByDatePopup = createAction(
  'showProductBeingAddedPastUsedByDatePopup',
  (payload: CartPopupsPayloads['showProductBeingAddedPastUsedByDatePopup']) => ({ payload })
);

export const showRemoveCartItemPopup = createAction(
  'showRemoveCartItemPopup',
  (payload: CartPopupsPayloads['showRemoveCartItemPopup']) => ({ payload })
);

export const showScanFailedPopup = createAction(
  'showScanFailedPopup',
  (payload: CartPopupsPayloads['showScanFailedPopup']) => ({ payload })
);

export const showProductUpsellPopup = createAction(
  'showProductUpsellPopup',
  (payload: CartPopupsPayloads['showProductUpsellPopup']) => ({ payload })
);

export const showHubBackClickPopup = createAction(
  'showHubBackClickPopup',
  (payload: CartPopupsPayloads['showHubBackClickPopup']) => ({ payload })
);
