import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reduce } from 'lodash';
import match from 'match-sorter';
import {
  CustomerDetails,
  CustomerDetailsNew,
  CustomerDetailsLight,
  CustomerIdentification,
  Doctor,
  ReferralSource,
  ReturnReason,
  ProductHistoryItem,
  Document,
  GetDocumentsResponse,
  CustomerCaregiver,
  QualifyingCondition,
  Prescription,
  LoyaltyAdjustmentReason,
  PatientPolicyAgreement,
  PreorderMetadataValues,
} from 'models/Customer';
import { AvailableDiscount, DiscountGroup } from 'models/Discounts';
import { logout } from 'store/actions/UserActions';
import { JournalEntry } from 'models/Journal';
import { formatDate } from 'util/Helpers';
import { formatDate as formatDateNew } from 'util/helpers/date-helpers/formatDate';
import {
  getCustomerDetails,
  getCustomerDetailsLight,
  getPrescriptions,
  createCustomer,
  getDoctorList,
  getQualifyingConditions,
  getReferralList,
  getAvailableGroups,
  getAvailableDiscounts,
  updateDaysSupplyRemaining,
  getCustomerJournal,
  getPatientPolicy,
  createUpdateJournalEntry,
  saveNotes,
  loadReturnReasons,
  loadAdjustmentLoyaltyReasons,
  loadProductHistory,
  editPrimaryIdentification,
  getDocuments,
  uploadDocument,
  downloadDocument,
  deleteDocument,
  clearCustomerDetails,
  clearCustomerPreviewSelected,
  updateCustomerDetails,
  adjustLoyaltyPoints,
  adjustAllotment,
  clearAllotmentOverride,
  searchProductHistory,
  loadCollectors,
  clearCustomerDocuments,
  removeAddressBookItem,
  setPreOrderMetadata,
  clearPreOrderMetadata,
  setTransactionReference,
  clearSelectedTransaction,
} from 'store/actions/CustomerActions';
import { loadCart } from 'store/actions/CartActions';
import { Cart } from 'models/Cart';
import { getIsFixedGuestDetailsLDFlagEnabled } from 'util/hooks/launch-darkly/getIsFixedGuestDetailsEnabled';

export type CustomerState = {
  details?: CustomerDetails;
  previewSelected?: CustomerDetailsLight;
  detailsLightLoading: boolean;
  detailsLoading: boolean;
  options: {
    doctors: Array<Doctor>;
    doctorsLoading: boolean;
    referralSources: Array<ReferralSource>;
    referralSourcesLoading: boolean;
    returnReasons: Array<ReturnReason>;
    adjustLoyaltyReasons: Array<LoyaltyAdjustmentReason>;
  };
  submitting: boolean;
  availableDiscounts: Array<AvailableDiscount>;
  availableGroups: Array<DiscountGroup>;
  pendingLoyaltyAdjustment: boolean;
  pendingDaysSupplyRemainingUpdate: boolean;
  pendingAllotmentAdjustment: boolean;
  pendingIdentificationEdit: boolean;
  journal: {
    entries: Array<JournalEntry>;
    loading: boolean;
  };
  history: {
    products: Array<ProductHistoryItem>;
    items: Array<ProductHistoryItem>;
    searchQuery: string;
    loading: boolean;
  };
  documents: Array<Document>;
  documentsLoading: boolean;
  documentUploading: boolean;
  downloadDocument: boolean;
  deletingDocument: boolean;
  collectors: Array<CustomerCaregiver>;
  qualifyingConditions: Array<QualifyingCondition>;
  prescriptions: Array<Prescription>;
  patientPolicy?: PatientPolicyAgreement;
  removingAddressBookItem: boolean;
  PreOrderMetadata?: PreorderMetadataValues;
};

export const initialState: CustomerState = {
  details: undefined,
  previewSelected: undefined,
  detailsLoading: true,
  detailsLightLoading: false,
  options: {
    doctors: [],
    doctorsLoading: true,
    referralSources: [],
    referralSourcesLoading: true,
    returnReasons: [],
    adjustLoyaltyReasons: [],
  },
  submitting: false,
  availableDiscounts: [],
  journal: {
    entries: [],
    loading: false,
  },
  availableGroups: [],
  pendingLoyaltyAdjustment: false,
  pendingDaysSupplyRemainingUpdate: false,
  pendingAllotmentAdjustment: false,
  pendingIdentificationEdit: false,
  history: {
    products: [],
    items: [],
    searchQuery: '',
    loading: false,
  },
  documents: [],
  documentsLoading: false,
  documentUploading: false,
  downloadDocument: false,
  deletingDocument: false,
  collectors: [],
  qualifyingConditions: [],
  prescriptions: [],
  patientPolicy: undefined,
  removingAddressBookItem: false,
  PreOrderMetadata: undefined,
};

const formatDocumentsFromResponse = (documents: GetDocumentsResponse) => {
  return reduce(
    documents,
    (acc: Document[], val, key) => {
      acc.push({
        name: key,
        UploadedDate: val.UploadedDate,
        UploadedBy: val.UploadedBy,
        FileId: val.FileId,
        ExpirationDate: val.ExpirationDate,
        FileType: val.FileType,
      });
      return acc;
    },
    []
  );
};

const formatCustomerDetailsFromResponseNew = (customer: CustomerDetails | CustomerDetailsNew): CustomerDetails => {
  const { identifications, ...customerDetails } = customer;

  const formattedIdentifications: CustomerIdentification[] = identifications.map((identification) => ({
    ...identification,
    ExpirationDate: formatDateNew({ date: identification.ExpirationDate }),
    StartDate: formatDateNew({ date: identification.StartDate }),
  }));

  const result: CustomerDetails = {
    ...customerDetails,
    additionalStateIdentifiers: formattedIdentifications[0].additionalStateIdentifiers,
    CreationDate: formatDateNew({ date: customerDetails.CreationDate }),
    DLExpirationDate: formatDateNew({ date: formattedIdentifications[0].ExpirationDate }),
    DOB: formatDateNew({ date: customerDetails.DOB }),
    DriversLicenseId: formattedIdentifications[0].number,
    identifications: formattedIdentifications,
    LastPurchase: formatDateNew({ date: customerDetails.LastPurchase }),
    MJExpirationDate: formatDateNew({ date: formattedIdentifications[1].ExpirationDate }),
    MJStartDate: formatDateNew({ date: formattedIdentifications[1].StartDate }),
    RegisterId: customerDetails.CurrentRegister, // Not returned from the API, need to change to CurrentRegister
  };

  applyCartInformation(result);
  return result;
};

// Had to add ShipmentId to this formatter since the API sends it as a string, but it should be a number
// It also sends and empty string for the ShipmentId if it's not set, but it should be 0: Number('') === 0
// https://github.com/GetDutchie/leaflogix/blob/774df40fe5c737ba7958e57ee36400af8217b7e4/LeafLogix.Web/LeafLogix.Web.Api/Controllers/POS/GuestController.cs#L619
const formatCustomerDetailsFromResponseOld = (customer: CustomerDetails) => {
  let result: CustomerDetails;
  if (customer.identifications.length >= 2) {
    customer.identifications[0].ExpirationDate = formatDate(customer.identifications[0].ExpirationDate);
    customer.identifications[1].ExpirationDate = formatDate(customer.identifications[1].ExpirationDate);
    result = {
      ...customer,
      // Remove after cleaning up pos.register.anon-cart-workflow.rollout
      ShipmentId: Number(customer.ShipmentId), // ShipmentId is not a number in the response, but it should be
      DOB: formatDate(customer.DOB),
      CreationDate: formatDate(customer.CreationDate),
      LastPurchase: formatDate(customer.LastPurchase),
      DLExpirationDate: formatDate(customer.identifications[0].ExpirationDate),
      DriversLicenseId: customer.identifications[0].number,
      additionalStateIdentifiers: customer.identifications[0].additionalStateIdentifiers,
      MJStartDate: formatDate(customer.identifications[1].StartDate),
      MJExpirationDate: formatDate(customer.identifications[1].ExpirationDate),
    };
  } else {
    result = {
      ...customer,
      // Remove after cleaning up pos.register.anon-cart-workflow.rollout
      ShipmentId: Number(customer.ShipmentId), // ShipmentId is not a number in the response, but it should be
      DOB: formatDate(customer.DOB),
      CreationDate: formatDate(customer.CreationDate),
      LastPurchase: formatDate(customer.LastPurchase),
    };
  }

  applyCartInformation(result);
  return result;
};

const formatCustomerDetailsFromResponse = (customer: CustomerDetails) => {
  const { isFixedGuestDetailsLDFlagEnabled } = getIsFixedGuestDetailsLDFlagEnabled();
  return isFixedGuestDetailsLDFlagEnabled
    ? formatCustomerDetailsFromResponseNew(customer)
    : formatCustomerDetailsFromResponseOld(customer);
};

/** @deprecated Will need to clean up after removing pos.register.anon-cart-workflow */
const applyCartInformation = (target: CustomerDetails) => {
  if (loadedCart && target.Guest_id === loadedCart.CustomerId) {
    target.ShipmentId = loadedCart.ShipmentId;
    target.ScheduleId = loadedCart.ScheduleId;
    target.TransactionReference = loadedCart.TransactionReference;
    target.TransactionStatus = loadedCart.TransactionStatus;
    target.CurrentRoom = loadedCart.CurrentRoom;
    target.CurrentRegister = loadedCart.CurrentRegister;
  }
};

let loadedCart: Cart | null = null;

const searchProductHistoryItems = (
  allItems: Array<ProductHistoryItem>,
  searchQuery: string
): Array<ProductHistoryItem> => {
  if (searchQuery.length === 0) {
    return allItems;
  }

  const keysToSearch: Array<keyof ProductHistoryItem> = [
    'ReceiptNo',
    'SerialNo',
    'ProductDesc',
    'ProductSku',
    'Instructions',
    'OwnerLocation',
    'PosDate',
    'PrescriptionNumber',
  ];
  return match(allItems, searchQuery, { keys: keysToSearch });
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CustomerState>) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(clearCustomerDetails, (state) => {
      state.details = undefined;
      state.detailsLoading = false;
    });
    builder.addCase(getCustomerDetails.pending, (state: CustomerState, { meta }) => {
      if (!meta.arg.noLoading) {
        // Resets the customer content on every page load
        // fix for incident incident-20221004-debit-paid-field-not-clearing-on-cart-load
        state.details = undefined;
        state.detailsLoading = true;
      }
    });
    builder.addCase(getCustomerDetails.fulfilled, (state: CustomerState, { payload }) => {
      state.details = payload && formatCustomerDetailsFromResponse(payload);
      state.detailsLoading = false;
    });
    builder.addCase(getCustomerDetails.rejected, (state: CustomerState) => {
      state.details = undefined;
      state.detailsLoading = false;
    });
    builder.addCase(getCustomerDetailsLight.pending, (state: CustomerState, { meta }) => {
      state.detailsLightLoading = true;
    });
    builder.addCase(getCustomerDetailsLight.fulfilled, (state: CustomerState, { payload }) => {
      state.previewSelected = payload;
      state.detailsLightLoading = false;
    });
    builder.addCase(getCustomerDetailsLight.rejected, (state: CustomerState) => {
      state.previewSelected = undefined;
      state.detailsLightLoading = false;
    });
    builder.addCase(clearCustomerPreviewSelected, (state: CustomerState) => {
      state.previewSelected = undefined;
    });
    builder.addCase(getPrescriptions.fulfilled, (state: CustomerState, { payload }) => {
      state.prescriptions = payload ? payload : [];
    });
    builder.addCase(getPrescriptions.rejected, (state: CustomerState) => {
      state.prescriptions = [];
    });
    builder.addCase(updateCustomerDetails.pending, (state: CustomerState) => {
      state.submitting = true;
    });
    builder.addCase(updateCustomerDetails.fulfilled, (state: CustomerState, { payload }) => {
      state.details = payload && payload.details && formatCustomerDetailsFromResponse(payload.details);
      state.submitting = false;
    });
    builder.addCase(updateCustomerDetails.rejected, (state: CustomerState) => {
      state.submitting = false;
    });
    builder.addCase(createCustomer.pending, (state: CustomerState) => {
      state.submitting = true;
    });
    builder.addCase(createCustomer.fulfilled, (state: CustomerState, { payload }) => {
      state.details = payload && formatCustomerDetailsFromResponse(payload);
      state.submitting = false;
    });
    builder.addCase(createCustomer.rejected, (state: CustomerState) => {
      state.submitting = false;
    });
    builder.addCase(getDoctorList.pending, (state: CustomerState) => {
      state.options.doctors = [];
      state.options.doctorsLoading = true;
    });
    builder.addCase(getDoctorList.fulfilled, (state: CustomerState, { payload }) => {
      state.options.doctors = payload ?? [];
      state.options.doctorsLoading = false;
    });
    builder.addCase(getDoctorList.rejected, (state: CustomerState) => {
      state.options.doctorsLoading = false;
    });
    builder.addCase(getQualifyingConditions.fulfilled, (state: CustomerState, { payload }) => {
      state.qualifyingConditions = payload || [];
    });
    builder.addCase(getReferralList.pending, (state: CustomerState) => {
      state.options.referralSources = [];
      state.options.referralSourcesLoading = true;
    });
    builder.addCase(getReferralList.fulfilled, (state: CustomerState, { payload }) => {
      state.options.referralSources = payload ? payload.ReferralSources : [];
      state.options.referralSourcesLoading = false;
    });
    builder.addCase(getReferralList.rejected, (state: CustomerState) => {
      state.options.referralSourcesLoading = false;
    });
    builder.addCase(getAvailableGroups.fulfilled, (state: CustomerState, { payload }) => {
      state.availableGroups = payload || [];
    });
    builder.addCase(getAvailableDiscounts.fulfilled, (state: CustomerState, { payload }) => {
      state.availableDiscounts = payload && payload.Data ? payload.Data : [];
    });
    builder.addCase(adjustLoyaltyPoints.pending, (state: CustomerState) => {
      state.pendingLoyaltyAdjustment = true;
    });
    builder.addCase(adjustLoyaltyPoints.fulfilled, (state: CustomerState, { payload }) => {
      state.pendingLoyaltyAdjustment = false;
      state.details = payload && formatCustomerDetailsFromResponse(payload);
    });
    builder.addCase(adjustLoyaltyPoints.rejected, (state: CustomerState) => {
      state.pendingLoyaltyAdjustment = false;
    });
    builder.addCase(updateDaysSupplyRemaining.pending, (state: CustomerState) => {
      state.pendingDaysSupplyRemainingUpdate = true;
    });
    builder.addCase(updateDaysSupplyRemaining.fulfilled, (state: CustomerState, { payload }) => {
      state.pendingDaysSupplyRemainingUpdate = false;
      state.details = payload && formatCustomerDetailsFromResponse(payload);
    });
    builder.addCase(updateDaysSupplyRemaining.rejected, (state: CustomerState) => {
      state.pendingDaysSupplyRemainingUpdate = false;
    });
    builder.addCase(adjustAllotment.pending, (state: CustomerState) => {
      state.pendingAllotmentAdjustment = true;
    });
    builder.addCase(adjustAllotment.fulfilled, (state: CustomerState, { payload }) => {
      state.pendingAllotmentAdjustment = false;
      state.details = payload && formatCustomerDetailsFromResponse(payload);
    });
    builder.addCase(adjustAllotment.rejected, (state: CustomerState) => {
      state.pendingAllotmentAdjustment = false;
    });
    builder.addCase(clearAllotmentOverride.fulfilled, (state: CustomerState, { payload }) => {
      state.details = payload && formatCustomerDetailsFromResponse(payload);
    });
    builder.addCase(getCustomerJournal.pending, (state: CustomerState) => {
      state.journal.loading = true;
    });
    builder.addCase(getCustomerJournal.fulfilled, (state: CustomerState, { payload }) => {
      state.journal.loading = false;
      state.journal.entries = payload;
    });
    builder.addCase(getCustomerJournal.rejected, (state: CustomerState) => {
      state.journal.loading = false;
    });
    builder.addCase(getPatientPolicy.pending, (state: CustomerState, { meta }) => {
      state.patientPolicy = undefined;
    });
    builder.addCase(getPatientPolicy.fulfilled, (state: CustomerState, { payload }) => {
      state.patientPolicy = payload ? payload : undefined;
    });
    builder.addCase(getPatientPolicy.rejected, (state: CustomerState) => {
      state.patientPolicy = undefined;
    });
    builder.addCase(createUpdateJournalEntry.fulfilled, (state: CustomerState, { payload }) => {
      state.journal.entries = payload;
    });
    builder.addCase(saveNotes.fulfilled, (state: CustomerState, { payload }) => {
      if (state.details) {
        state.details.notes = payload;
      }
    });
    builder.addCase(loadReturnReasons.fulfilled, (state: CustomerState, { payload }) => {
      state.options.returnReasons = payload || [];
    });
    builder.addCase(loadAdjustmentLoyaltyReasons.fulfilled, (state: CustomerState, { payload }) => {
      state.options.adjustLoyaltyReasons = payload || [];
    });
    builder.addCase(searchProductHistory, (state, action) => {
      const searchQuery = action.payload;
      const searchResults = searchProductHistoryItems(state.history.items, searchQuery);
      state.history.searchQuery = searchQuery;
      state.history.products = searchResults;
    });
    builder.addCase(setTransactionReference.fulfilled, (state: CustomerState, { payload }) => {
      if (state.details) {
        state.details.TransactionReference = payload || '';
      }
    });
    builder.addCase(loadProductHistory.pending, (state: CustomerState) => {
      state.history.loading = !state.history.products.length;
    });
    builder.addCase(loadProductHistory.fulfilled, (state: CustomerState, { payload }) => {
      state.history.products = payload || [];
      state.history.items = payload || [];
      state.history.loading = false;
    });
    builder.addCase(loadProductHistory.rejected, (state: CustomerState) => {
      state.history.loading = false;
    });
    builder.addCase(editPrimaryIdentification.pending, (state: CustomerState) => {
      state.pendingIdentificationEdit = true;
    });
    builder.addCase(editPrimaryIdentification.fulfilled, (state: CustomerState, { payload }) => {
      state.details = payload && formatCustomerDetailsFromResponse(payload);
      state.pendingIdentificationEdit = false;
    });
    builder.addCase(editPrimaryIdentification.rejected, (state: CustomerState) => {
      state.pendingIdentificationEdit = false;
    });
    builder.addCase(getDocuments.pending, (state: CustomerState) => {
      state.documentsLoading = true;
    });
    builder.addCase(getDocuments.fulfilled, (state: CustomerState, { payload }) => {
      state.documentsLoading = false;
      state.documents = formatDocumentsFromResponse(payload);
    });
    builder.addCase(getDocuments.rejected, (state: CustomerState) => {
      state.documentsLoading = false;
    });
    builder.addCase(uploadDocument.pending, (state: CustomerState) => {
      state.documentUploading = true;
    });
    builder.addCase(uploadDocument.fulfilled, (state: CustomerState, { payload }) => {
      state.documentUploading = false;
      state.documents = payload ? formatDocumentsFromResponse(payload) : [];
    });
    builder.addCase(uploadDocument.rejected, (state: CustomerState) => {
      state.documentUploading = false;
    });
    builder.addCase(downloadDocument.pending, (state: CustomerState) => {
      state.downloadDocument = true;
    });
    builder.addCase(downloadDocument.fulfilled, (state: CustomerState, { payload }) => {
      state.downloadDocument = false;
    });
    builder.addCase(downloadDocument.rejected, (state: CustomerState) => {
      state.downloadDocument = false;
    });
    builder.addCase(deleteDocument.pending, (state: CustomerState) => {
      state.deletingDocument = true;
    });
    builder.addCase(deleteDocument.fulfilled, (state: CustomerState, { payload }) => {
      state.deletingDocument = false;
      state.documents = formatDocumentsFromResponse(payload);
    });
    builder.addCase(deleteDocument.rejected, (state: CustomerState) => {
      state.deletingDocument = false;
    });
    builder.addCase(loadCollectors.pending, (state: CustomerState) => {
      state.collectors = [];
    });
    builder.addCase(loadCollectors.fulfilled, (state: CustomerState, { payload }) => {
      state.collectors = payload ?? [];
    });
    builder.addCase(clearCustomerDocuments, (state: CustomerState) => {
      state.documents = [];
    });
    builder.addCase(removeAddressBookItem.pending, (state: CustomerState) => {
      state.removingAddressBookItem = true;
    });
    builder.addCase(removeAddressBookItem.fulfilled, (state: CustomerState, { payload }) => {
      if (state.details?.AddressBook) {
        state.details.AddressBook = state.details.AddressBook.filter((addressItem) => {
          return addressItem.AddressId !== payload;
        });
      }
      state.removingAddressBookItem = false;
    });
    builder.addCase(removeAddressBookItem.rejected, (state: CustomerState) => {
      state.removingAddressBookItem = false;
    });
    builder.addCase(setPreOrderMetadata, (state: CustomerState, action: PayloadAction<PreorderMetadataValues>) => {
      state.PreOrderMetadata = action.payload;
    });
    builder.addCase(clearPreOrderMetadata, (state: CustomerState) => {
      state.PreOrderMetadata = undefined;
    });

    // Remove after cleaning up pos.register.anon-cart-workflow.rollout
    //this is a hack.  Customers can have multiple carts, but 300 places in the code reference state.customer.details.ShipmentId
    //this case makes sure that the shipmentId on the customer matches the loaded cart.
    builder.addCase(loadCart.fulfilled, (state: CustomerState, action: PayloadAction<Cart>) => {
      loadedCart = action.payload;
      if (state.details) {
        applyCartInformation(state.details);
      }
    });

    builder.addCase(clearSelectedTransaction, (state: CustomerState) => {
      loadedCart = null;
    });
  },
});
