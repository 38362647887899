export const CART_LINE_ITEM_TEST_IDS = {
  brandNameTestId: 'cart-line-item_items-info-brand_brand-name',
  discountedCostTestId: 'cart-line-item_item-cost_cost-discounted',
  itemCostStrikethroughTestId: 'cart-line-item_item-cost_cost-strikethrough',
  itemCostTestId: 'cart-line-item_item-cost_cost',
  packageIdCheckMarkIconTestId: 'cart-line-item_check-mark-icon_packageId',
  productNameTestId: 'cart-line-item_items-info-product_name',
  quantityToDisplayTestId: 'cart-line-item-quantity-to-display',
  discountItemTestId: 'discount-item_div_discount',
  discountDescriptionTestId: 'discount-item_title_discount-name',
  discountAmountTestId: 'discount-item_strong_discount-amount',
  showLoyaltyPanelTestId: 'item_chevron-icon_open-loyalty',
  removeDiscountTestId: 'item_style-delete-svg_delete-icon',
};
