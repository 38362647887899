import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { KeypadHeader } from 'pages/PinLoginPage/KeypadHeader';

export const ResetPageV2: FC = ({ children }) => {
  return (
    <StyledPage>
      <KeypadHeader />
      <FormContainer>
        <FormWrapper>
          <StyledTitle>Reset password</StyledTitle>
          {children}
        </FormWrapper>
      </FormContainer>
    </StyledPage>
  );
};

const FormWrapper = styled.div`
  width: 100%;
`;

const StyledPage = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${colors.dutchie.opal90};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledTitle = styled.div`
  text-align: center;
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
  color: ${colors.dutchie.primaryWhite};
  margin: 0 auto 40px;
`;

const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
