import React, { ComponentProps, FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ConfirmationModal } from 'components/modals/ConfirmationModal';
import { ConfirmationPopup } from './ConfirmationPopup';
import { ModalVariation } from 'components/modals/Modal';
import { colors } from 'css/Theme';

type ConfirmationPopupProps = ComponentProps<typeof ConfirmationPopup>;

export const NewConfirmationModalBridge: FC<ConfirmationPopupProps> = ({
  cancel,
  children,
  confirm,
  hide,
  large,
  medium,
  width,
  automationId,
  title,
  className,
  isVisible,
  contentMaxHeight,
  canClose,
}) => {
  const modalVariation = useMemo(() => {
    if (medium) {
      return ModalVariation.Medium;
    } else if (large) {
      return ModalVariation.Large;
    } else {
      return ModalVariation.Standard;
    }
  }, [medium, large]);

  if (isVisible === false) {
    return null;
  }

  return (
    <ConfirmationModalWithWidthProp
      automationId={automationId ?? 'popup_popup-body-div_section'}
      modalName={automationId ?? 'NewConfirmationModalBridge'}
      className={className}
      variation={modalVariation}
      width={width}
      hide={hide}
      header={title}
      message=''
      canClose={canClose}
      contentMaxHeight={contentMaxHeight}
      footerStyles={contentMaxHeight !== undefined ? StickyFooterStyles : undefined}
      leftButton={
        cancel
          ? {
              text: cancel.text ?? 'Cancel',
              automationId: 'confirmation-popup_cancel-button_cancel',
              onClick: cancel.onClick,
              disabled: cancel.disabled,
              loading: cancel.loading,
              tertiary: true,
            }
          : undefined
      }
      rightButton={
        confirm
          ? {
              text: confirm.text ?? 'Confirm',
              automationId: 'confirmation-popup_confirm-button_confirm',
              onClick: confirm.onClick,
              disabled: confirm.disabled,
              loading: confirm.loading,
            }
          : undefined
      }
    >
      {children}
    </ConfirmationModalWithWidthProp>
  );
};

const ConfirmationModalWithWidthProp = styled(ConfirmationModal)<{ contentMaxHeight?: string; width?: string }>`
  position: relative;

  ${({ width }) =>
    width
      ? css`
          width: clamp(400px, calc(100% - 2.5rem), ${width});
        `
      : ''}

  ${({ contentMaxHeight }) =>
    contentMaxHeight
      ? css`
          max-height: ${contentMaxHeight};
          overflow-y: auto;
        `
      : css`
          max-height: 100%;
        `}
`;

const StickyFooterStyles = css`
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: ${colors.dutchie.primaryWhite};
`;
