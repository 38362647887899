import styled from 'styled-components';

import { colors } from 'css/Theme';
import { LinkButton } from 'components/backoffice/button';

export const ResultsContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem 2.5rem 0;
`;

export const ResultsMessage = styled.h1`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 11rem 2rem 0;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.44rem;
`;

export const Message = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.62rem;

  color: ${colors.dutchie.gray80};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  padding: 0;
  margin: 0;
`;

export const SecondaryMessage = styled.p`
  color: ${colors.dutchie.gray80};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
  padding: 0;
  margin: 0;
`;

export const Link = styled(LinkButton)`
  color: ${colors.dutchie.blue};
  background: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;
