import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  size?: number;
  color?: string;
};

export const UserAvatarIcon = ({
  width = 24,
  height = 24,
  size = undefined,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={size ?? width} height={size ?? height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 14.1667C8.89681 14.1667 7.62838 14.4595 6.66667 15C5.70495 15.5405 4.74026 16.5577 4.16667 17.5C3.96155 17.837 3.86416 17.8153 3.52718 17.6101C3.19021 17.405 3.08332 16.9656 3.28843 16.6286C3.98949 15.4769 4.97515 14.5249 6.15058 13.8643C7.32601 13.2037 8.65166 12.8567 10 12.8567C11.3483 12.8567 12.674 13.2037 13.8494 13.8643C15.0249 14.5249 16.0105 15.4769 16.7116 16.6286C16.9167 16.9656 16.8098 17.405 16.4728 17.6101C16.1358 17.8153 15.6218 17.4203 15.4167 17.0833C14.8431 16.141 14.295 15.5405 13.3333 15C12.3716 14.4595 11.1032 14.1667 10 14.1667Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.92893 2.92893C4.8043 1.05357 7.34783 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34783 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34783 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34783 1.05357 4.8043 2.92893 2.92893ZM10 1.42857C7.72672 1.42857 5.54654 2.33163 3.93908 3.93908C2.33163 5.54654 1.42857 7.72672 1.42857 10C1.42857 12.2733 2.33163 14.4535 3.93908 16.0609C5.54654 17.6684 7.72672 18.5714 10 18.5714C12.2733 18.5714 14.4535 17.6684 16.0609 16.0609C17.6684 14.4535 18.5714 12.2733 18.5714 10C18.5714 7.72672 17.6684 5.54654 16.0609 3.93908C14.4535 2.33163 12.2733 1.42857 10 1.42857Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 8.33333C13.75 10.4044 12.0711 12.0833 10 12.0833C7.92893 12.0833 6.25 10.4044 6.25 8.33333C6.25 6.26227 7.92893 4.58333 10 4.58333C12.0711 4.58333 13.75 6.26227 13.75 8.33333ZM10 10.8333C11.3807 10.8333 12.5 9.71404 12.5 8.33333C12.5 6.95262 11.3807 5.83333 10 5.83333C8.61929 5.83333 7.5 6.95262 7.5 8.33333C7.5 9.71404 8.61929 10.8333 10 10.8333Z" fill={color} />
    </svg>
  );
};
