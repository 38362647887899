import React from 'react';
import { SettingsListItem, SettingsListItemChevronPlaceholder } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { CustomerDisplayIcon } from 'assets/icons/settings/CustomerDisplayIcon';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { useCfd } from './hooks/useCfd';
import { CfdSettingsProvider } from './components/CfdSettingsProvider';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { CfdPreferredScreenListItem } from './components/CfdPreferredScreenListItem';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { CfdTestOpenListItem } from './components/CfdTestOpenListItem';
import { isAndroid } from 'util/hooks';
import { CfdOpenOnLoginListItem } from './components/CfdOpenOnLoginListItem';
import { CfdStatusPill } from './components/CfdStatusPill';
import { CfdStatusBanner } from './components/CfdStatusBanner';

export const CfdSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();
  const { showCartDisplay } = useCfd();

  if (!showCartDisplay) {
    return null;
  }

  return (
    <>
      <SettingsListItem
        icon={<CustomerDisplayIcon />}
        automationId='cfd_settings'
        title='Customer-facing display'
        subtitle={isAndroid ? undefined : 'Settings for customer-facing display'}
        onClick={isAndroid ? undefined : showDrawer}
        actions={[
          <CfdStatusPill />,
          // keep status pill alignment consistent with other settings
          ...(isAndroid ? [<SettingsListItemChevronPlaceholder />] : []),
        ]}
      />
      <SettingsBottomDrawerForm
        automationId='cfd_settings-drawer'
        title='Customer-facing display'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <CfdSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <CfdStatusBanner />
            <CfdPreferredScreenListItem />
            <SettingsDivider />
            <CfdTestOpenListItem />
            <SettingsDivider />
            <CfdOpenOnLoginListItem />
          </SettingsCard>
        </CfdSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
