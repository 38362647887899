import { post } from 'api/HttpHelpers';
import {
    AppliedRewards,
    ApplyFylloRewardRequest,
    FylloRewardsInfo,
    GetAppliedFylloRewards,
    GetRewardsRequest,
    RemoveRewardRequest,
} from 'models/Fyllo';
import { logger, customEventKeys } from 'util/logger';

export const getRewards = (req: GetRewardsRequest) => post<FylloRewardsInfo>('fyllo/get-rewards', req);
export const addReward = (body: ApplyFylloRewardRequest) => {
  logger.debug<{ body: ApplyFylloRewardRequest }>('Applying Fyllo Reward', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('fyllo/apply-reward', body);
};
export const getAppliedRewards = (body: GetAppliedFylloRewards) =>
  post<AppliedRewards>('fyllo/get-applied-rewards', body);
export const removeReward = (body: RemoveRewardRequest) => {
  logger.debug<{ body: RemoveRewardRequest }>('Removing Fyllo Reward', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('fyllo/remove-redemption', body);
};
