import { AppliedDiscount } from 'models/Discounts';
import { useMemo } from 'react';

export const useTotalDiscountAmount = (rolledUpDiscounts: AppliedDiscount[]) => {
  return useMemo(
    () =>
      rolledUpDiscounts?.length > 0
        ? rolledUpDiscounts.reduce((accum, item) => {
            return accum + item.DiscountAmt;
          }, 0)
        : rolledUpDiscounts[0]?.DiscountAmt ?? 0,
    [rolledUpDiscounts]
  );
};
