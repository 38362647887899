import { useTableStore } from './useTableStore';

export enum SelectionState {
  All = 'on',
  Indeterminate = 'mixed',
  None = 'off',
}

const getSelectionState = (numberOfItems: number, numberOfSelectedItems: number) => {
  if (numberOfSelectedItems === 0) {
    return SelectionState.None;
  }

  if (numberOfSelectedItems === numberOfItems) {
    return SelectionState.All;
  }

  return SelectionState.Indeterminate;
};

export const useRowSelect = <ItemType>() => {
  const {
    props: { onChangeSelected, selected, data },
  } = useTableStore<ItemType>();
  const numberOfItems = data.length;
  const numberOfSelectedItems = selected?.length ?? 0;
  const selectionState = getSelectionState(numberOfItems, numberOfSelectedItems);
  const itemSet = new Set(selected);

  return {
    isEnabled: !!onChangeSelected,
    selectionState,
    toggleSelectAllState: () => {
      onChangeSelected(selectionState === SelectionState.All ? [] : data);
    },
    toggleItem: (item: ItemType) => {
      const isItemSelected = itemSet.has(item);
      onChangeSelected(isItemSelected ? selected.filter((i) => i !== item) : [...selected, item]);
    },
    isSelected: (item: ItemType) => itemSet.has(item),
  };
};
