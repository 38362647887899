import React from 'react';
import styled from 'styled-components';

import { Flex } from 'components/layout';
import { Chevron, ChevronDirections } from 'assets/icons/chevron';
import { FilterName, FilterSectionDiv } from '../FilterMenu';
import { NullableDate, TimeWindowState } from '../hooks/useTimeWindowFilterState';
import { DatePicker } from 'components/inputs';
import { LinkButton } from 'components/backoffice/button';

export type TimeWindowFilterProps = {
  description?: string;
  endDate: NullableDate;
  label: string;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindowState>>;
  setTimeWindowValidationError: React.Dispatch<React.SetStateAction<{ start: boolean; end: boolean }>>;
  startDate: NullableDate;
  timeWindowValidationError: { start: boolean; end: boolean };
};

type handleChangeArgs = { isStart: boolean; val: NullableDate };

export function TimeWindowFilter({
  description,
  endDate,
  label,
  setTimeWindow,
  setTimeWindowValidationError,
  startDate,
  timeWindowValidationError,
}: TimeWindowFilterProps): JSX.Element {
  const handleChange = ({ isStart, val }: handleChangeArgs): void => {
    if (isStart) {
      setTimeWindow([val, endDate]);
      setTimeWindowValidationError({ ...timeWindowValidationError, start: false });
    } else {
      setTimeWindow([startDate, val]);
      setTimeWindowValidationError({ ...timeWindowValidationError, end: false });
    }
  };

  const clearTimeWindow = (): void => {
    setTimeWindow([]);
    setTimeWindowValidationError({ start: false, end: false });
  };

  const showResetButton = !!startDate || !!endDate;

  return (
    <FilterSectionDiv>
      <Flex direction='column' justifyContent='space-between' marginBottom='12px' marginTop='20px'>
        <FilterName>{label}</FilterName>
        {description && <DescriptionCopy data-testid='description-copy'>{description}</DescriptionCopy>}
        <DatePickerContainer>
          <DatePicker
            automationId='start-date-input'
            endAdornment={<Chevron direction={ChevronDirections.DOWN} />}
            endDate={endDate}
            hasError={timeWindowValidationError.start}
            hideCalendarIcon
            mode='datetime'
            onChange={(val: NullableDate) => handleChange({ isStart: true, val })}
            placeholder='Start date and time'
            popperPlacement='bottom-start'
            selected={startDate}
            selectsStart
            startDate={startDate}
          />
          <Separator>-</Separator>
          <DatePicker
            automationId='end-date-input'
            endAdornment={<Chevron direction={ChevronDirections.DOWN} />}
            endDate={endDate}
            hasError={timeWindowValidationError.end}
            hideCalendarIcon
            minDate={startDate}
            mode='datetime'
            onChange={(val: NullableDate) => handleChange({ isStart: false, val })}
            placeholder='End date and time'
            popperPlacement='bottom-end'
            selected={endDate}
            selectsEnd
            startDate={startDate}
          />
          {showResetButton && (
            <StyledLinkButton label='Reset' onClick={clearTimeWindow} automationId='time-window-filter-reset-button' />
          )}
        </DatePickerContainer>
      </Flex>
    </FilterSectionDiv>
  );
}

const DescriptionCopy = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding: 0.5rem 0;
`;

const DatePickerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-width: 500px;
  > div {
    margin-bottom: unset;
  }
`;

const Separator = styled.span`
  margin: 0 0.5rem;
`;

const StyledLinkButton = styled(LinkButton)`
  color: #0091ff;
  font-size: 14px;
`;
