import React from 'react';

import { colors } from 'css/Theme';

const originalDimensions = {
  width: 56,
  height: 56,
};

type SearchCustomerIconProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export const SearchCustomerIcon = ({
  fill = colors.dutchie.gray70,
  width = originalDimensions.width,
  height = originalDimensions.height,
}: SearchCustomerIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M25.6667 3.38337C13.36 3.38337 3.38337 13.36 3.38337 25.6667C3.38337 37.9734 13.36 47.95 25.6667 47.95C37.9734 47.95 47.95 37.9734 47.95 25.6667C47.95 13.36 37.9734 3.38337 25.6667 3.38337ZM0.116699 25.6667C0.116699 11.5558 11.5558 0.116699 25.6667 0.116699C39.7776 0.116699 51.2167 11.5558 51.2167 25.6667C51.2167 39.7776 39.7776 51.2167 25.6667 51.2167C11.5558 51.2167 0.116699 39.7776 0.116699 25.6667Z'
          fill={fill}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M19.5631 14.3118C21.1821 12.6928 23.3781 11.7832 25.6678 11.7832C27.9575 11.7832 30.1534 12.6928 31.7724 14.3118C33.3915 15.9309 34.3011 18.1268 34.3011 20.4165C34.3011 22.7062 33.3915 24.9022 31.7724 26.5212C30.1534 28.1403 27.9575 29.0499 25.6678 29.0499C23.3781 29.0499 21.1821 28.1403 19.5631 26.5212C17.944 24.9022 17.0344 22.7062 17.0344 20.4165C17.0344 18.1268 17.944 15.9309 19.5631 14.3118ZM25.6678 15.0499C24.2444 15.0499 22.8794 15.6153 21.873 16.6217C20.8665 17.6282 20.3011 18.9932 20.3011 20.4165C20.3011 21.8399 20.8665 23.2049 21.873 24.2113C22.8794 25.2178 24.2444 25.7832 25.6678 25.7832C27.0911 25.7832 28.4561 25.2178 29.4626 24.2113C30.469 23.2049 31.0344 21.8399 31.0344 20.4165C31.0344 18.9932 30.469 17.6282 29.4626 16.6217C28.4561 15.6153 27.0911 15.0499 25.6678 15.0499Z'
          fill={fill}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M18.6754 31.5116C20.7179 30.0607 23.1613 29.2812 25.6667 29.2812C28.1721 29.2812 30.6155 30.0607 32.6581 31.5116C34.7006 32.9625 36.2412 35.013 37.0661 37.3787C37.3632 38.2304 36.9135 39.1617 36.0617 39.4587C35.2099 39.7558 34.2787 39.3061 33.9816 38.4543C33.3799 36.7287 32.2562 35.2331 30.7663 34.1748C29.2764 33.1165 27.4942 32.5479 25.6667 32.5479C23.8392 32.5479 22.057 33.1165 20.5671 34.1748C19.0773 35.2331 17.9536 36.7287 17.3518 38.4543C17.0548 39.3061 16.1235 39.7558 15.2717 39.4587C14.42 39.1617 13.9703 38.2304 14.2673 37.3787C15.0923 35.013 16.6328 32.9625 18.6754 31.5116Z'
          fill={fill}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M41.4237 41.4237C42.0616 40.7858 43.0957 40.7858 43.7336 41.4237L55.4049 53.095C56.0428 53.7329 56.0428 54.7671 55.4049 55.4049C54.7671 56.0428 53.7329 56.0428 53.095 55.4049L41.4237 43.7336C40.7858 43.0957 40.7858 42.0616 41.4237 41.4237Z'
          fill={fill}
        />
      </g>
    </svg>
  );
};
