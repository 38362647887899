// Load cart types

import type { AppliedDiscount } from 'models/Discounts';
import type { AppliedFeeDonation, PaymentTypeFee } from 'models/FeeDonation';
import type { ExistingPaymentMethod } from 'models/Checkout';
import type { PrescriptionData } from 'models/MCDMS';
import type { Cart, CartTotal, CartItem as OldCartItem } from 'models/Cart';

export type Allotment = {
  currentLimit: number;
  details: AllotmentCategory[];
  error: boolean;
  errorMessage?: string;
  maxLimit: number;
  totalInCart: number;
  totalLimit: number;
  totalRemaining: number;
  totalUsed: number;
  validFrom?: Date;
  validUntil?: Date;
  warning: boolean;
  warningMessage?: string;
};

export type AllotmentCategory = {
  allowDeviceDispensation?: boolean;
  category: string;
  customerTypeId: number;
  inCart: number;
  limit: number;
  orderId?: number;
  orderType?: string;
  unitId?: number;
  used: number;
};

export type CartItem = {
  allowSaleToMinor?: number;
  autoPrintLabel?: number;
  batchId?: number;
  brandId?: number;
  brandName?: string;
  cannabisProduct?: string;
  customer?: number;
  daysSupply?: number;
  daysSupplyPerItem?: number;
  defaultLabelId?: number;
  defaultUnitId?: number;
  discountAmount?: number;
  equivalent?: number;
  grams?: number;
  id: number;
  instructions?: string;
  inventoryId?: number;
  loyaltyAmount?: number;
  mmurDeviceId?: number;
  mmurFormId?: number;
  mmurOrderId?: number;
  multiplier?: number;
  orderDate?: number;
  orderNo: string;
  packageItemId?: number;
  packageItemSerialNumber?: string;
  pricedQuantity?: number;
  productCategory?: string;
  productDescription: string;
  productId: number;
  productType: 'Wgt' | 'Qty';
  quantityAllocated: number;
  quantitySelected: number;
  rowInventoryIds?: number[];
  serialNo: string;
  taxable?: number;
  taxAmt?: number;
  totalCost: number;
};

export type Loyalty = {
  appliedLoyaltyFromDiscounts: number;
  appliedLoyaltyPoints: number;
  transactionId: number;
};

export type Payment = {
  electronicAmount: number;
  electronicType: string;
};

export type PreauthInfo = {
  currency: string;
  finalAmount?: number;
  orderId: number;
  paymentType: string;
  preauthAmount: number;
  preauthDate?: Date;
  preauthId: string;
  shipmentId: number;
};

export type Preorder = {
  abbreviation: string;
  brandId?: number;
  brandName?: string;
  category: string;
  customer: string;
  grams: number;
  grower: string;
  id: number;
  inventoryDate: Date;
  location: string;
  orderDate: Date;
  orderId: number;
  orderItemId: number;
  orderNo: string;
  orderStatus: string;
  packageItemSerialNumber?: string;
  productDescription: string;
  productId: number;
  productImageURL?: string;
  qtySelected: number;
  serialNo: number;
  strain: string;
  totalCost: number;
  unitId: number;
  unitName: number;
};

export type PreorderRedemption = {
  alpineId: string;
  callbackUrl: string;
  fylloId: number;
  isRedeemed: boolean;
  leafLogixCode: string;
  orderId: number;
  orderRedemptionId: number;
  productId?: number;
  redemptionDescription: string;
  springBigId: string;
  springBigType: string;
};

export type PrepaymentInfo = {
  amount: number;
  orderId: number;
  prepaidOn?: Date;
  prepaymentId: string;
  prepaymentType: string;
  shipmentId: number;
};

export type LoadCartResponse = {
  Allotment: {
    CurrentLimit: number;
    Details: {
      AllowDeviceDispensation?: boolean;
      Category: string;
      CustomerTypeId: number;
      InCart: number;
      Limit: number;
      OrderId?: number;
      OrderType?: string;
      UnitId?: number;
      Used: number;
    }[];
    Error: boolean;
    ErrorMessage?: string;
    MaxLimit: number;
    TotalInCart: number;
    TotalLimit: number;
    TotalRemaining: number;
    TotalUsed: number;
    ValidFrom?: Date;
    ValidUntil?: Date;
    Warning: boolean;
    WarningMessage?: string;
  };
  Cart: OldCartItem[];
  CurrentRegister: number;
  CurrentRoom: string;
  CustomerId: number;
  Deliverable: boolean;
  Discounts: AppliedDiscount[];
  ExistingPayments: ExistingPaymentMethod[];
  FeesAndDonations: number;
  FeesByPaymentType: PaymentTypeFee[];
  FeesDonations: AppliedFeeDonation[];
  GrandTotal: number;
  GrandTotalRounded: number;
  Locked: boolean;
  Loyalty?: {
    AppliedLoyaltyFromDiscounts: number;
    AppliedLoyaltyPoints: number;
    TransactionId: number;
  };
  Payment: {
    ElectronicAmount: number;
    ElectronicType: string;
  };
  PreauthInfo: {
    Currency: string;
    FinalAmount?: number;
    OrderId: number;
    PaymentType: string;
    PreauthAmount: number;
    PreauthDate?: Date;
    PreauthId: string;
    ShipmentId: number;
  };
  PreOrderRedemptions: {
    AlpineId: string;
    CallbackUrl: string;
    FylloId: number;
    IsRedeemed: boolean;
    LeafLogixCode: string;
    OrderId: number;
    OrderRedemptionId: number;
    ProductId?: number;
    RedemptionDescription: string;
    SpringBigId: string;
    SpringBigType: string;
  }[];
  PreOrders: {
    Abbreviation: string;
    BrandId?: number;
    BrandName?: string;
    Category: string;
    Customer: string;
    Grams: number;
    Grower: string;
    Id: number;
    InventoryDate: Date;
    Location: string;
    OrderDate: Date;
    OrderId: number;
    OrderItemId: number;
    OrderNo: string;
    OrderStatus: string;
    PackageItemSerialNumber?: string;
    Product: string;
    ProductId: number;
    ProductImageURL?: string;
    QtySelected: number;
    SerialNo: number;
    Strain: string;
    TotalCost: number;
    UnitId: number;
    UnitName: number;
  }[];
  PrepaymentPayInfo?: {
    Amount: number;
    OrderId: number;
    PrepaidOn?: Date;
    PrepaymentId: string;
    PrepaymentType: string;
    ShipmentId: number;
  }[];
  PrescriptionData?: PrescriptionData;
  RoundingAmount: number;
  ScheduleId: string;
  ShipmentId: number;
  SubTotal: number;
  Tax: number;
  TipAmount?: number;
  TotalDiscount: number;
  TotalDiscountAndLoyalty: number;
  TotalItems: number;
  TransactionReference: string;
  TransactionStatus: string;
  VerifiedBy?: number;
};

export type CartDetails = ReturnType<typeof transformToCartDetails>;

export const transformToCartDetails = (response: LoadCartResponse) => {
  const allotment: Allotment = {
    currentLimit: response.Allotment.CurrentLimit,
    details: response.Allotment.Details.map((allotment) => ({
      allowDeviceDispensation: allotment.AllowDeviceDispensation,
      category: allotment.Category,
      customerTypeId: allotment.CustomerTypeId,
      inCart: allotment.InCart,
      limit: allotment.Limit,
      orderId: allotment.OrderId,
      orderType: allotment.OrderType,
      unitId: allotment.UnitId,
      used: allotment.Used,
    })),
    error: response.Allotment.Error,
    errorMessage: response.Allotment.ErrorMessage,
    maxLimit: response.Allotment.MaxLimit,
    totalInCart: response.Allotment.TotalInCart,
    totalLimit: response.Allotment.TotalLimit,
    totalRemaining: response.Allotment.TotalRemaining,
    totalUsed: response.Allotment.TotalUsed,
    validFrom: response.Allotment.ValidFrom,
    validUntil: response.Allotment.ValidUntil,
    warning: response.Allotment.Warning,
    warningMessage: response.Allotment.WarningMessage,
  };

  const cartItems: CartItem[] = response.Cart.map((cartItem) => ({
    allowSaleToMinor: cartItem.AllowSaleToMinor,
    autoPrintLabel: cartItem.AutoPrintLabel,
    batchId: cartItem.BatchId,
    brandId: cartItem.BrandId,
    brandName: cartItem.BrandName,
    cannabisProduct: cartItem.CannbisProduct,
    customer: cartItem.Customer,
    daysSupply: cartItem.DaysSupply,
    daysSupplyPerItem: cartItem.DaysSupplyPerItem,
    defaultLabelId: cartItem.DefaultLabelId,
    defaultUnitId: cartItem.DefaultUnitId,
    discountAmount: cartItem.DiscountAmt,
    equivalent: cartItem.Equivalent,
    grams: cartItem.Grams,
    id: cartItem.Id,
    instructions: cartItem.Instructions,
    inventoryId: cartItem.InventoryId,
    loyaltyAmount: cartItem.LoyaltyAmt,
    mmurDeviceId: cartItem.MMURDeviceId,
    mmurFormId: cartItem.MMURFormId,
    mmurOrderId: cartItem.MMUROrderId,
    multiplier: cartItem.Multiplier,
    orderDate: cartItem.OrderDate,
    orderNo: cartItem.OrderNo,
    packageItemId: cartItem.PackageItemId,
    packageItemSerialNumber: cartItem.PackageItemSerialNumber,
    pricedQuantity: cartItem.PricedQuantity,
    productCategory: cartItem.ProductCategory,
    productDescription: cartItem.Product,
    productId: cartItem.ProductId,
    productType: cartItem.WgtCnt,
    quantityAllocated: cartItem.QtyAllocated,
    quantitySelected: cartItem.QtySelected,
    rowInventoryIds: cartItem.RowInventoryIds,
    serialNo: cartItem.SerialNo,
    taxable: cartItem.Taxable,
    taxAmt: cartItem.TaxAmt,
    totalCost: cartItem.TotalCost,
  }));

  const loyalty: Loyalty | undefined = response.Loyalty && {
    appliedLoyaltyFromDiscounts: response.Loyalty.AppliedLoyaltyFromDiscounts,
    appliedLoyaltyPoints: response.Loyalty.AppliedLoyaltyPoints,
    transactionId: response.Loyalty.TransactionId,
  };

  const payment: Payment = {
    electronicAmount: response.Payment.ElectronicAmount,
    electronicType: response.Payment.ElectronicType,
  };

  const preauthInfo: PreauthInfo = {
    currency: response.PreauthInfo.Currency,
    finalAmount: response.PreauthInfo.FinalAmount,
    orderId: response.PreauthInfo.OrderId,
    paymentType: response.PreauthInfo.PaymentType,
    preauthAmount: response.PreauthInfo.PreauthAmount,
    preauthDate: response.PreauthInfo.PreauthDate,
    preauthId: response.PreauthInfo.PreauthId,
    shipmentId: response.PreauthInfo.ShipmentId,
  };

  const preorderRedemptions: PreorderRedemption[] = response.PreOrderRedemptions.map((redemption) => ({
    alpineId: redemption.AlpineId,
    callbackUrl: redemption.CallbackUrl,
    fylloId: redemption.FylloId,
    isRedeemed: redemption.IsRedeemed,
    leafLogixCode: redemption.LeafLogixCode,
    orderId: redemption.OrderId,
    orderRedemptionId: redemption.OrderRedemptionId,
    productId: redemption.ProductId,
    redemptionDescription: redemption.RedemptionDescription,
    springBigId: redemption.SpringBigId,
    springBigType: redemption.SpringBigType,
  }));

  const preorders = response.PreOrders.map((preorder) => ({
    abbreviation: preorder.Abbreviation,
    brandId: preorder.BrandId,
    brandName: preorder.BrandName,
    category: preorder.Category,
    customer: preorder.Customer,
    grams: preorder.Grams,
    grower: preorder.Grower,
    id: preorder.Id,
    inventoryDate: preorder.InventoryDate,
    location: preorder.Location,
    orderDate: preorder.OrderDate,
    orderId: preorder.OrderId,
    orderItemId: preorder.OrderItemId,
    orderNo: preorder.OrderNo,
    orderStatus: preorder.OrderStatus,
    packageItemSerialNumber: preorder.PackageItemSerialNumber,
    productDescription: preorder.Product,
    productId: preorder.ProductId,
    productImageURL: preorder.ProductImageURL,
    qtySelected: preorder.QtySelected,
    serialNo: preorder.SerialNo,
    strain: preorder.Strain,
    totalCost: preorder.TotalCost,
    unitId: preorder.UnitId,
    unitName: preorder.UnitName,
  }));

  const prepaymentInfo = response.PrepaymentPayInfo?.map((prepayment) => ({
    amount: prepayment.Amount,
    orderId: prepayment.OrderId,
    prepaidOn: prepayment.PrepaidOn,
    prepaymentId: prepayment.PrepaymentId,
    prepaymentType: prepayment.PrepaymentType,
    shipmentId: prepayment.ShipmentId,
  }));

  return {
    allotment,
    /** List of all fees that have been applied to a cart */
    appliedFeesAndDonations: response.FeesDonations,
    cartItems,
    currentRegister: response.CurrentRegister,
    currentRoom: response.CurrentRoom,
    discounts: response.Discounts,
    existingPayments: response.ExistingPayments,
    /** Reference list of all fees that could be applied to cart.  Enables display of available fees and associated values in UI */
    feesByPaymentType: response.FeesByPaymentType,
    grandTotal: response.GrandTotal,
    grandTotalRounded: response.GrandTotalRounded,
    guestId: response.CustomerId,
    isCartLocked: response.Locked,
    isDeliverable: response.Deliverable,
    loyalty,
    payment,
    preauthInfo,
    preorderRedemptions,
    preorders,
    prepaymentInfo,
    prescriptionData: response.PrescriptionData,
    roundingAmount: response.RoundingAmount,
    scheduleId: response.ScheduleId,
    shipmentId: response.ShipmentId,
    subtotal: response.SubTotal,
    tax: response.Tax,
    tipAmount: response.TipAmount,
    totalDiscounts: response.TotalDiscount,
    totalDiscountsAndLoyalty: response.TotalDiscountAndLoyalty,
    /** The dollar value of all fees and donations applied to a cart */
    totalFeesAndDonations: response.FeesAndDonations,
    totalItems: response.TotalItems,
    transactionReference: response.TransactionReference,
    transactionStatus: response.TransactionStatus,
    verifiedBy: response.VerifiedBy,
  };
};

export const emptyCartDetails: CartDetails = {
  allotment: {
    currentLimit: 0,
    details: [],
    error: false,
    errorMessage: undefined,
    maxLimit: 0,
    totalInCart: 0,
    totalLimit: 0,
    totalRemaining: 0,
    totalUsed: 0,
    validFrom: undefined,
    validUntil: undefined,
    warning: false,
    warningMessage: undefined,
  },
  appliedFeesAndDonations: [],
  cartItems: [],
  currentRegister: 0,
  currentRoom: '',
  discounts: [],
  existingPayments: [],
  feesByPaymentType: [],
  grandTotal: 0,
  grandTotalRounded: 0,
  guestId: 0,
  isCartLocked: false,
  isDeliverable: false,
  loyalty: {
    appliedLoyaltyFromDiscounts: 0,
    appliedLoyaltyPoints: 0,
    transactionId: 0,
  },
  payment: {
    electronicAmount: 0,
    electronicType: '',
  },
  preauthInfo: {
    currency: '',
    finalAmount: 0,
    orderId: 0,
    paymentType: 'cash',
    preauthAmount: 0,
    preauthDate: new Date(),
    preauthId: '',
    shipmentId: 0,
  },
  preorderRedemptions: [],
  preorders: [],
  prepaymentInfo: undefined,
  prescriptionData: undefined,
  roundingAmount: 0,
  scheduleId: '0',
  shipmentId: 0,
  subtotal: 0,
  tax: 0,
  tipAmount: undefined,
  totalDiscounts: 0,
  totalDiscountsAndLoyalty: 0,
  totalFeesAndDonations: 0,
  totalItems: 0,
  transactionReference: '',
  transactionStatus: '',
  verifiedBy: undefined,
};

export const convertToOldCartItems = (cartItems: CartItem[]): OldCartItem[] => {
  return cartItems.map((cartItem) => ({
    AllowSaleToMinor: cartItem.allowSaleToMinor,
    AutoPrintLabel: cartItem.autoPrintLabel,
    BatchId: cartItem.batchId,
    BrandId: cartItem.brandId,
    BrandName: cartItem.brandName,
    CannbisProduct: cartItem.cannabisProduct,
    Customer: cartItem.customer,
    DaysSupply: cartItem.daysSupply,
    DaysSupplyPerItem: cartItem.daysSupplyPerItem,
    DefaultLabelId: cartItem.defaultLabelId,
    DefaultUnitId: cartItem.defaultUnitId,
    DiscountAmt: cartItem.discountAmount,
    Equivalent: cartItem.equivalent,
    Grams: cartItem.grams,
    Id: cartItem.id,
    Instructions: cartItem.instructions,
    InventoryId: cartItem.inventoryId,
    LoyaltyAmt: cartItem.loyaltyAmount,
    MMURDeviceId: cartItem.mmurDeviceId,
    MMURFormId: cartItem.mmurFormId,
    MMUROrderId: cartItem.mmurOrderId,
    Multiplier: cartItem.multiplier,
    OrderDate: cartItem.orderDate,
    OrderNo: cartItem.orderNo,
    PackageItemId: cartItem.packageItemId,
    PackageItemSerialNumber: cartItem.packageItemSerialNumber,
    PricedQuantity: cartItem.pricedQuantity,
    Product: cartItem.productDescription,
    ProductCategory: cartItem.productCategory,
    ProductId: cartItem.productId,
    QtyAllocated: cartItem.quantityAllocated,
    QtySelected: cartItem.quantitySelected,
    RowInventoryIds: cartItem.rowInventoryIds,
    SerialNo: cartItem.serialNo,
    Taxable: cartItem.taxable,
    TaxAmt: cartItem.taxAmt,
    TotalCost: cartItem.totalCost,
    WgtCnt: cartItem.productType,
  }));
};

export const convertToOldCartDetails = (cartDetails: CartDetails): Cart => {
  return {
    Allotment: {
      CurrentLimit: cartDetails.allotment.currentLimit,
      Details: cartDetails.allotment.details.map((allotment) => ({
        AllowDeviceDispensation: allotment.allowDeviceDispensation,
        Category: allotment.category,
        CustomerTypeId: allotment.customerTypeId,
        InCart: allotment.inCart,
        Limit: allotment.limit,
        OrderId: allotment.orderId,
        OrderType: allotment.orderType,
        UnitId: allotment.unitId,
        Used: allotment.used,
      })),
      Error: cartDetails.allotment.error,
      ErrorMessage: cartDetails.allotment.errorMessage,
      MaxLimit: cartDetails.allotment.maxLimit,
      TotalInCart: cartDetails.allotment.totalInCart,
      TotalLimit: cartDetails.allotment.totalLimit,
      TotalRemaining: cartDetails.allotment.totalRemaining,
      TotalUsed: cartDetails.allotment.totalUsed,
      ValidFrom: cartDetails.allotment.validFrom,
      ValidUntil: cartDetails.allotment.validUntil,
      Warning: cartDetails.allotment.warning,
      WarningMessage: cartDetails.allotment.warningMessage,
    },
    Cart: convertToOldCartItems(cartDetails.cartItems),
    CurrentRegister: cartDetails.currentRegister,
    CurrentRoom: cartDetails.currentRoom,
    CustomerId: cartDetails.guestId,
    Deliverable: cartDetails.isDeliverable,
    Discounts: cartDetails.discounts,
    ExistingPayments: cartDetails.existingPayments,
    FeesAndDonations: cartDetails.totalFeesAndDonations,
    FeesByPaymentType: cartDetails.feesByPaymentType,
    FeesDonations: cartDetails.appliedFeesAndDonations,
    GrandTotal: cartDetails.grandTotal,
    GrandTotalRounded: cartDetails.grandTotalRounded,
    Locked: cartDetails.isCartLocked,
    Loyalty: cartDetails.loyalty && {
      AppliedLoyaltyFromDiscounts: cartDetails.loyalty.appliedLoyaltyFromDiscounts,
      AppliedLoyaltyPoints: cartDetails.loyalty.appliedLoyaltyPoints,
      TransactionId: cartDetails.loyalty.transactionId,
    },
    Payment: {
      ElectronicAmount: cartDetails.payment.electronicAmount,
      ElectronicType: cartDetails.payment.electronicType,
    },
    PreauthInfo: {
      Currency: cartDetails.preauthInfo.currency,
      FinalAmount: cartDetails.preauthInfo.finalAmount,
      OrderId: cartDetails.preauthInfo.orderId,
      PaymentType: cartDetails.preauthInfo.paymentType,
      PreauthAmount: cartDetails.preauthInfo.preauthAmount,
      PreauthDate: cartDetails.preauthInfo.preauthDate,
      PreauthId: cartDetails.preauthInfo.preauthId,
      ShipmentId: cartDetails.preauthInfo.shipmentId,
    },
    PreOrderRedemptions: cartDetails.preorderRedemptions.map((redemption) => ({
      AlpineId: redemption.alpineId,
      CallbackUrl: redemption.callbackUrl,
      FylloId: redemption.fylloId,
      IsRedeemed: redemption.isRedeemed,
      LeafLogixCode: redemption.leafLogixCode,
      OrderId: redemption.orderId,
      OrderRedemptionId: redemption.orderRedemptionId,
      ProductId: redemption.productId,
      RedemptionDescription: redemption.redemptionDescription,
      SpringBigId: redemption.springBigId,
      SpringBigType: redemption.springBigType,
    })),
    PreOrders: cartDetails.preorders.map((preorder) => ({
      Abbreviation: preorder.abbreviation,
      BrandId: preorder.brandId,
      BrandName: preorder.brandName,
      Category: preorder.category,
      Customer: preorder.customer,
      Grams: preorder.grams,
      Grower: preorder.grower,
      Id: preorder.id,
      InventoryDate: preorder.inventoryDate,
      Location: preorder.location,
      OrderDate: preorder.orderDate,
      OrderId: preorder.orderId,
      OrderItemId: preorder.orderItemId,
      OrderNo: preorder.orderNo,
      OrderStatus: preorder.orderStatus,
      PackageItemSerialNumber: preorder.packageItemSerialNumber,
      Product: preorder.productDescription,
      ProductId: preorder.productId,
      ProductImageURL: preorder.productImageURL,
      QtySelected: preorder.qtySelected,
      SerialNo: preorder.serialNo,
      Strain: preorder.strain,
      TotalCost: preorder.totalCost,
      UnitId: preorder.unitId,
      UnitName: preorder.unitName,
    })),
    PrepaymentPayInfo: cartDetails.prepaymentInfo?.map((prepayment) => ({
      Amount: prepayment.amount,
      OrderId: prepayment.orderId,
      PrepaidOn: prepayment.prepaidOn,
      PrepaymentId: prepayment.prepaymentId,
      PrepaymentType: prepayment.prepaymentType,
      ShipmentId: prepayment.shipmentId,
    })),
    PrescriptionData: cartDetails.prescriptionData,
    RoundingAmount: cartDetails.roundingAmount,
    ScheduleId: cartDetails.scheduleId,
    ShipmentId: cartDetails.shipmentId,
    SubTotal: cartDetails.subtotal,
    Tax: cartDetails.tax,
    TipAmount: cartDetails.tipAmount,
    TotalDiscount: cartDetails.totalDiscounts,
    TotalDiscountAndLoyalty: cartDetails.totalDiscountsAndLoyalty,
    TotalItems: cartDetails.totalItems,
    TransactionReference: cartDetails.transactionReference,
    TransactionStatus: cartDetails.transactionStatus,
    VerifiedBy: cartDetails.verifiedBy,
  };
};

// Calculate cart total types

export type CalculateCartTotalResponse = {
  Cart: OldCartItem[];
  Discounts: AppliedDiscount[];
  FeesAndDonations: number;
  FeesDonations: AppliedFeeDonation[];
  GrandTotal: number;
  GrandTotalRounded: number;
  Loyalty?: {
    AppliedLoyaltyFromDiscounts: number;
    AppliedLoyaltyPoints: number;
    TransactionId: number;
  };
  Payment: {
    ElectronicAmount: number;
    ElectronicType: string;
  };
  RoundingAmount: number;
  SubTotal: number;
  Tax: number;
  TipAmount?: number;
  TotalDiscount: number;
  TotalDiscountAndLoyalty: number;
};

export type CartTotals = ReturnType<typeof transformToCartTotals>;

export const transformToCartTotals = (response: CalculateCartTotalResponse) => {
  const cartItems: CartItem[] = response.Cart.map((cartItem) => ({
    allowSaleToMinor: cartItem.AllowSaleToMinor,
    autoPrintLabel: cartItem.AutoPrintLabel,
    batchId: cartItem.BatchId,
    brandId: cartItem.BrandId,
    brandName: cartItem.BrandName,
    cannabisProduct: cartItem.CannbisProduct,
    customer: cartItem.Customer,
    daysSupply: cartItem.DaysSupply,
    daysSupplyPerItem: cartItem.DaysSupplyPerItem,
    defaultLabelId: cartItem.DefaultLabelId,
    defaultUnitId: cartItem.DefaultUnitId,
    discountAmount: cartItem.DiscountAmt,
    equivalent: cartItem.Equivalent,
    grams: cartItem.Grams,
    id: cartItem.Id,
    instructions: cartItem.Instructions,
    inventoryId: cartItem.InventoryId,
    loyaltyAmount: cartItem.LoyaltyAmt,
    mmurDeviceId: cartItem.MMURDeviceId,
    mmurFormId: cartItem.MMURFormId,
    mmurOrderId: cartItem.MMUROrderId,
    multiplier: cartItem.Multiplier,
    orderDate: cartItem.OrderDate,
    orderNo: cartItem.OrderNo,
    packageItemId: cartItem.PackageItemId,
    packageItemSerialNumber: cartItem.PackageItemSerialNumber,
    pricedQuantity: cartItem.PricedQuantity,
    productCategory: cartItem.ProductCategory,
    productDescription: cartItem.Product,
    productId: cartItem.ProductId,
    productType: cartItem.WgtCnt,
    quantityAllocated: cartItem.QtyAllocated,
    quantitySelected: cartItem.QtySelected,
    rowInventoryIds: cartItem.RowInventoryIds,
    serialNo: cartItem.SerialNo,
    taxable: cartItem.Taxable,
    taxAmt: cartItem.TaxAmt,
    totalCost: cartItem.TotalCost,
  }));

  const loyalty: Loyalty | undefined = response.Loyalty && {
    appliedLoyaltyFromDiscounts: response.Loyalty.AppliedLoyaltyFromDiscounts,
    appliedLoyaltyPoints: response.Loyalty.AppliedLoyaltyPoints,
    transactionId: response.Loyalty.TransactionId,
  };

  const payment: Payment = {
    electronicAmount: response.Payment.ElectronicAmount,
    electronicType: response.Payment.ElectronicType,
  };

  return {
    appliedFeesAndDonations: response.FeesDonations,
    cartItems,
    discounts: response.Discounts,
    grandTotal: response.GrandTotal,
    grandTotalRounded: response.GrandTotalRounded,
    loyalty,
    payment,
    roundingAmount: response.RoundingAmount,
    subtotal: response.SubTotal,
    tax: response.Tax,
    tipAmount: response.TipAmount,
    totalDiscounts: response.TotalDiscount,
    totalDiscountsAndLoyalty: response.TotalDiscountAndLoyalty,
    totalFeesAndDonations: response.FeesAndDonations,
  };
};

export const convertToOldCartTotals = (cartTotals?: CartTotals): CartTotal | undefined => {
  if (!cartTotals) {
    return undefined;
  }

  return {
    Cart: cartTotals.cartItems.map((cartItem) => ({
      AllowSaleToMinor: cartItem.allowSaleToMinor,
      AutoPrintLabel: cartItem.autoPrintLabel,
      BatchId: cartItem.batchId,
      BrandId: cartItem.brandId,
      BrandName: cartItem.brandName,
      CannbisProduct: cartItem.cannabisProduct,
      Customer: cartItem.customer,
      DaysSupply: cartItem.daysSupply,
      DaysSupplyPerItem: cartItem.daysSupplyPerItem,
      DefaultLabelId: cartItem.defaultLabelId,
      DefaultUnitId: cartItem.defaultUnitId,
      DiscountAmt: cartItem.discountAmount,
      Equivalent: cartItem.equivalent,
      Grams: cartItem.grams,
      Id: cartItem.id,
      Instructions: cartItem.instructions,
      InventoryId: cartItem.inventoryId,
      LoyaltyAmt: cartItem.loyaltyAmount,
      MMURDeviceId: cartItem.mmurDeviceId,
      MMURFormId: cartItem.mmurFormId,
      MMUROrderId: cartItem.mmurOrderId,
      Multiplier: cartItem.multiplier,
      OrderDate: cartItem.orderDate,
      OrderNo: cartItem.orderNo,
      PackageItemId: cartItem.packageItemId,
      PackageItemSerialNumber: cartItem.packageItemSerialNumber,
      PricedQuantity: cartItem.pricedQuantity,
      Product: cartItem.productDescription,
      ProductCategory: cartItem.productCategory,
      ProductId: cartItem.productId,
      QtyAllocated: cartItem.quantityAllocated,
      QtySelected: cartItem.quantitySelected,
      RowInventoryIds: cartItem.rowInventoryIds,
      SerialNo: cartItem.serialNo,
      Taxable: cartItem.taxable,
      TaxAmt: cartItem.taxAmt,
      TotalCost: cartItem.totalCost,
      WgtCnt: cartItem.productType,
    })),
    Discounts: cartTotals.discounts,
    FeesAndDonations: cartTotals.totalFeesAndDonations,
    FeesDonations: cartTotals.appliedFeesAndDonations,
    GrandTotal: cartTotals.grandTotal,
    GrandTotalRounded: cartTotals.grandTotalRounded,
    Loyalty: cartTotals.loyalty && {
      AppliedLoyaltyFromDiscounts: cartTotals.loyalty.appliedLoyaltyFromDiscounts,
      AppliedLoyaltyPoints: cartTotals.loyalty.appliedLoyaltyPoints,
      TransactionId: cartTotals.loyalty.transactionId,
    },
    Payment: {
      ElectronicAmount: cartTotals.payment.electronicAmount,
      ElectronicType: cartTotals.payment.electronicType,
    },
    RoundingAmount: cartTotals.roundingAmount,
    SubTotal: cartTotals.subtotal,
    Tax: cartTotals.tax,
    TipAmount: cartTotals.tipAmount,
    TotalDiscount: cartTotals.totalDiscounts,
    TotalDiscountAndLoyalty: cartTotals.totalDiscountsAndLoyalty,
  };
};

// Add items types

export type AddItemToShoppingCartResponse = {
  InvId: number;
  PackageItemId: number;
  ItemLabelId: number;
};

export type AddItemResponse = ReturnType<typeof transformToAddItemResponse>;

export const transformToAddItemResponse = (response: AddItemToShoppingCartResponse) => {
  return {
    inventoryId: response.InvId,
    packageItemId: response.PackageItemId,
    itemLabelId: response.ItemLabelId,
  };
};

// Void Transaction Types

export type VoidTransactionServerResponse = {
  BioTrackResponse: string;
  CannabisInventory: string;
  CanceledDate?: string;
  CancelReason?: string;
  Equivalent: number;
  FlowerEquiv: number;
  Instructions: string;
  LotNo: string;
  OwnerLocation: string;
  OwnerName: string;
  PackageItemId: string;
  PatientName: string;
  PosDate: string;
  PosId: number;
  PosStatus: string;
  Prescription: string;
  PrescriptionNumber: number;
  ProdId: number;
  ProductDesc: string;
  ProductGrams: number;
  ProductSku: string;
  Quantity: number;
  ReceiptNo: string;
  ReturnBy: string;
  ReturnReason: string;
  SerialNo: string;
  SoldBy: string;
  UnitPrice: number;
  UnitTypeId: number;
};

export type TransactionDetail = ReturnType<typeof transformToTransactionDetail>;

export const transformToTransactionDetail = (transaction: VoidTransactionServerResponse) => {
  return {
    bioTrackResponse: transaction.BioTrackResponse,
    cannabisInventory: transaction.CannabisInventory,
    canceledDate: transaction.CanceledDate,
    cancelReason: transaction.CancelReason,
    equivalent: transaction.Equivalent,
    flowerEquiv: transaction.FlowerEquiv,
    instructions: transaction.Instructions,
    lotNo: transaction.LotNo,
    ownerLocation: transaction.OwnerLocation,
    ownerName: transaction.OwnerName,
    packageItemId: transaction.PackageItemId,
    patientName: transaction.PatientName,
    posDate: transaction.PosDate,
    posId: transaction.PosId,
    posStatus: transaction.PosStatus,
    prescription: transaction.Prescription,
    prescriptionNumber: transaction.PrescriptionNumber,
    prodId: transaction.ProdId,
    productDesc: transaction.ProductDesc,
    productGrams: transaction.ProductGrams,
    productSku: transaction.ProductSku,
    quantity: transaction.Quantity,
    receiptNo: transaction.ReceiptNo,
    returnBy: transaction.ReturnBy,
    returnReason: transaction.ReturnReason,
    serialNo: transaction.SerialNo,
    soldBy: transaction.SoldBy,
    unitPrice: transaction.UnitPrice,
    unitTypeId: transaction.UnitTypeId,
  };
};

// Anonymous Transaction Types

export type AnonymousTransactionResponse = {
  AcctId: number;
  Id: number;
}[];

export type AnonymousTransaction = ReturnType<typeof transformToAnonymousTransaction>;

export const transformToAnonymousTransaction = (response: AnonymousTransactionResponse) => {
  return {
    guestId: response[0].AcctId,
    shipmentId: response[0].Id,
  };
};

// Switch Customer Types

export type SwitchCustomerResponse = {
  CustomerId: number;
  ShipmentId: number;
};

export type NewCartDetails = ReturnType<typeof transformToNewCartDetails>;

export const transformToNewCartDetails = (response: SwitchCustomerResponse) => {
  return {
    guestId: response.CustomerId,
    shipmentId: response.ShipmentId,
  };
};
