import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { callback } from 'models/Misc';
import { showActionableError, hideActionableError } from 'store/actions/ActionableErrorActions';

export type Action = {
  label: string;
  onClick: callback;
};

export type ActionableErrorState = {
  showErrorMessage: boolean;
  message: string;
  action: Action;
  secondaryAction?: Action;
};

const initialState: ActionableErrorState = {
  showErrorMessage: false,
  message: '',
  action: {
    label: '',
    onClick: () => {},
  },
};

export const actionableErrorSlice = createSlice({
  name: 'actionableError',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ActionableErrorState>) => {
    builder.addCase(showActionableError, (state: ActionableErrorState, action) => {
      state.showErrorMessage = true;
      state.message = action.payload.message;
      state.action = action.payload.action;
      state.secondaryAction = action.payload.secondaryAction;
    });
    builder.addCase(hideActionableError, (state: ActionableErrorState) => {
      state.showErrorMessage = false;
      state.message = '';
    });
  },
});
