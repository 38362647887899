import { datadogRum } from '@datadog/browser-rum';
import { RumPublicApi } from '@datadog/browser-rum-core';

import { LoggingProvider } from '../LoggingProvider';
import { sanitizeData } from './sanitizeData';

import type { LoggingProviderInitProps, UserData } from '../LoggingProvider';
import type { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';
import { BaseCustomEventData } from '../customEventKeys';

let GLOBAL_DD_CLIENT: RumPublicApi;

export class DatadogLoggingProvider extends LoggingProvider {
  public providerId = 'datadog';

  public init = (props?: LoggingProviderInitProps) => {
    if (process.env.NODE_ENV !== 'development' || props?.turnOnForDevelopment) {
      if (GLOBAL_DD_CLIENT) {
        return GLOBAL_DD_CLIENT;
      }

      datadogRum.init({
        applicationId: '372d3f1f-dcc9-4391-aa4c-24a20bb2a25d',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.com',
        service: 'pos-2020',
        version: process.env.REACT_APP_VERSION_NUMBER || '',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [
          /https:\/\/.*\.leaflogix\.net/,
          /https:\/\/.*\.pos\.dutchie\.com/,
          /http:\/\/localhost:\d+/,
        ],
        enableExperimentalFeatures: ['feature_flags'],
      });

      datadogRum.startSessionReplayRecording();

      GLOBAL_DD_CLIENT = datadogRum;

      return datadogRum;
    }
  };



  public getMiddleware = (): Middleware => {
    return (_store: unknown) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
      datadogRum.addAction(`Register-Redux-Action_${action.type}`, action.payload);
      return next(action);
    };
  };

  public setGlobalProperty = (key: string, value: unknown) => {
    datadogRum.setGlobalContextProperty(key, value);
  };

  public setUser = (userId: string, userData?: UserData) => {
    datadogRum.setUser({
      id: userId,
      ...userData,
    });
  };

  public track = <CustomEventData extends BaseCustomEventData>(customEventDescription: string, data?: CustomEventData) => {
    datadogRum.addAction(customEventDescription, sanitizeData(data));
  };

  public error = (e: unknown, data?: object) => {
    datadogRum.addError(e, data);
  };
}

export const getDDClient = () => GLOBAL_DD_CLIENT;

