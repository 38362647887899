import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';

import { CartLineItem } from 'pages/CartPage/CartSummary/components/CartLineItem';
import { Discount } from './components/CartLineItem/components/Discount';
import { useApplicableItemDiscounts, useRolledUpDiscounts, useTotalDiscountAmount } from 'util/hooks/cart';
import { useCartItemOverflowOptions } from './useCartItemOverflowOptions';

import type { AppliedDiscount } from 'models/Discounts';
import type { callback1 } from 'models/Misc';
import type { CartItem } from 'models/Cart';

type ItemProps = {
  item: CartItem;
  discounts: AppliedDiscount[];
  onRemoveItem?: callback1<CartItem>;
  onPrintOneLabel?: callback1<CartItem>;
  onPrintAllLabels?: callback1<CartItem>;
  onDownloadAllLabels?: () => void;
};

export const Item: FC<ItemProps> = (props) => {
  const { item, discounts } = props;

  const itemDiscounts = useApplicableItemDiscounts(item, discounts);
  const rolledUpDiscounts = useRolledUpDiscounts(itemDiscounts);
  const totalDiscountAmount = useTotalDiscountAmount(rolledUpDiscounts);

  const actions = useCartItemOverflowOptions(props);

  return (
    <CartLineItem
      data-testid='cart-sidebar-item_side-bar-item_item'
      item={item}
      overflowOptions={actions}
      totalDiscountAmount={totalDiscountAmount}
    >
      {rolledUpDiscounts.length > 0 && (
        <DiscountsAndLoyaltyContainer>
          {rolledUpDiscounts.map((discount: AppliedDiscount) => (
            <Discount key={discount.AppliedDiscountId} item={item} discount={discount} />
          ))}
        </DiscountsAndLoyaltyContainer>
      )}
    </CartLineItem>
  );
};

const DiscountsAndLoyaltyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid ${colors.dutchie.grey90};
`;
