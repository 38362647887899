import React, { FC } from 'react';

import { NavButton } from 'components/buttons';

type ResetFilterButtonProps = {
  onResetFilters: () => unknown;
  activeFilterCount: number;
};

export const ResetFilterButton: FC<ResetFilterButtonProps> = ({ onResetFilters, activeFilterCount }) => {
  if (activeFilterCount === 0) {
    return null;
  }
  return (
    <NavButton onClick={onResetFilters} automationId='action-bar_button_clear-filters' margin='0'>
      Clear Filters ({activeFilterCount})
    </NavButton>
  );
};
