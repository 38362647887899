import { Peripheral, PlayAction } from 'util/capacitor/peripheral';

import { isAndroid } from './hooks';
import { logger, customEventKeys } from './logger';
import { getSearchScannersDescription } from './logger/helpers/scanning';

import type { ScannerIdLookupContext, PlayedSoundContext, SearchScannersContext } from './logger/types/scanning';

const getScannerId = async (scannerId?: string): Promise<string | undefined> => {
  try {
    const { results: availableScanners } = await Peripheral.searchScanners();

    logger.debug<SearchScannersContext>(getSearchScannersDescription(availableScanners, false), {
      key: customEventKeys.scanning.bluetooth.search,
      availableScanners,
      userInitiated: false,
    });

    if (!availableScanners.length) {
      return undefined;
    }

    const providedScannerIdIsAvailable = scannerId && availableScanners.some((scanner) => scanner.id === scannerId);

    if (providedScannerIdIsAvailable) {
      logger.debug<ScannerIdLookupContext>(`capacitor was able to find scanner that was just used to scan (ID: ${scannerId})`, {
        key: customEventKeys.scanning.bluetooth.idLookup,
        usedProvidedScannerId: true,
        scannerId,
      });
      return scannerId;
    } else {
      const connectedScanner = availableScanners.find((scanner) => !!scanner.connected);
      const foundScannerId = connectedScanner?.id ?? availableScanners?.[0].id;

      logger.warn<ScannerIdLookupContext>(`capacitor was unable to find scanner that was just used to scan, but found a different one (ID: ${foundScannerId})`, {
        key: customEventKeys.scanning.bluetooth.idLookup,
        scannerId: foundScannerId,
        usedProvidedScannerId: false,
      });

      return foundScannerId;
    }
  } catch (e) {
    logger.error(e, { message: 'searchScanners failed', userInitiated: false });
  }
};

type PlayScannerActionParams = {
  scannerId?: string | number;
  isGood: boolean;
};

// This functionality is not available in the hardware library so this code will be left as-is
// and continue communicating through the Peripheral package.
export const playScannerAction = async ({ isGood, scannerId }: PlayScannerActionParams): Promise<void> => {
  if (isAndroid) {
    try {
      const connectedScannerId = await getScannerId(scannerId?.toString());

      if (connectedScannerId) {
        Peripheral.playScannerAction({
          id: connectedScannerId.toString(),
          action: isGood ? PlayAction.good : PlayAction.bad,
        });

        logger.debug<PlayedSoundContext>(`${isGood ? 'good' : 'bad'} beep sounds were played on the customer's scanner with ID ${connectedScannerId}`, {
          key: customEventKeys.scanning.bluetooth.playedSound,
          isGood,
          selectedScannerId: connectedScannerId,
        });
      } else {
        logger.error('Not able to find connected scanner', { providedScannerId: scannerId });
      }
    } catch (e) {
      logger.error(e, { message: 'Error playing sound', isGood });
    }
  }
};
