import React, { FC } from 'react';

import { ConfirmTransactionPopup } from '../ConfirmTransactionPopup';
import { Label } from 'components/text';
import { LoadingButton } from 'components/buttons';
import { Select, TextArea } from 'components/inputs';
import { useTransfer } from './useTransfer';

import {
  ButtonsContainer,
  Container,
  CurrencyInput,
  InputContainer,
  Fields,
  Row,
  TabTitle,
  TextAreaContainer,
} from '../Styles';

export type TransferProps = {
  onTransfer: () => void;
  registerId: number;
};

export const Transfer: FC<TransferProps> = (props) => {
  const {
    cashCounted,
    comment,
    handleTransfer,
    isConfirmPopupVisible,
    isLoading,
    isTransferDisabled,
    registerOptions,
    selectedRegister,
    setCashCounted,
    setComment,
    setSelectedRegister,
    setTransactionAdjustmentReason,
    toggleConfirmPopup,
    transactionAdjustmentReason,
    transactionAdjustmentReasonOptions,
  } = useTransfer(props);

  return (
    <Container>
      <TabTitle>Transfer Between Registers</TabTitle>
      <Fields>
        <Row>
          <InputContainer>
            <Label>To Register</Label>
            <Select
              value={selectedRegister}
              onChange={setSelectedRegister}
              options={registerOptions}
              placeholder='Choose a register'
            />
          </InputContainer>
          <CurrencyInput
            label='Amount'
            placeholder='Enter amount'
            allowNegative={false}
            value={cashCounted.formattedValue}
            onValueChange={setCashCounted}
          />
        </Row>
        <Row>
          <InputContainer>
            <Label>Reason</Label>
            <Select
              options={transactionAdjustmentReasonOptions}
              onChange={setTransactionAdjustmentReason}
              value={transactionAdjustmentReason}
              placeholder='Choose a reason'
            />
          </InputContainer>
        </Row>
        <TextAreaContainer>
          <Label>Comment</Label>
          <TextArea value={comment} onChange={(e) => setComment(e.target.value)} />
        </TextAreaContainer>
      </Fields>
      <ButtonsContainer>
        <LoadingButton loading={isLoading} disabled={isTransferDisabled} onClick={toggleConfirmPopup}>
          Transfer
        </LoadingButton>
      </ButtonsContainer>
      {/* Popups */}
      <ConfirmTransactionPopup
        title='Confirm Register Transfer'
        message={`Proceed with transfer?`}
        onConfirm={handleTransfer}
        isVisible={isConfirmPopupVisible}
        hide={toggleConfirmPopup}
      />
    </Container>
  );
};
