import React from 'react';

import { colors } from 'css/Theme';

type CaregiverIcon = {
  color?: string;
  size?: number;
  automationId?: string;
};

export const CaregiverIcon = ({ color = colors.dutchie.almostBlack, size = 16, automationId }: CaregiverIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_7796_81497)'>
        <path
          d='M7.99777 10.8549C9.57572 10.8549 10.8549 9.57572 10.8549 7.99777C10.8549 6.41981 9.57572 5.14062 7.99777 5.14062C6.41981 5.14062 5.14062 6.41981 5.14062 7.99777C5.14062 9.57572 6.41981 10.8549 7.99777 10.8549Z'
          stroke={color}
          stroke-width='1.2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M3.11719 15.3116C3.62718 14.4745 4.34394 13.7826 5.19857 13.3026C6.05319 12.8225 7.01695 12.5703 7.99719 12.5703C8.97742 12.5703 9.94118 12.8225 10.7958 13.3026C11.6504 13.7826 12.3672 14.4745 12.8772 15.3116'
          stroke={color}
          stroke-width='1.2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M15.4275 7.93031C15.4286 7.77168 15.3966 7.61457 15.3336 7.46897C15.2707 7.32336 15.1781 7.19246 15.0618 7.0846L7.99891 0.570312L0.936053 7.0846C0.819738 7.19246 0.727153 7.32336 0.66419 7.46897C0.601227 7.61457 0.569265 7.77168 0.570339 7.93031V14.2846C0.570339 14.5877 0.690747 14.8784 0.905074 15.0927C1.1194 15.307 1.41009 15.4275 1.7132 15.4275H14.2846C14.5877 15.4275 14.8784 15.307 15.0927 15.0927C15.3071 14.8784 15.4275 14.5877 15.4275 14.2846V7.93031Z'
          stroke={color}
          stroke-width='1.2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7796_81497'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
