import React, { FC, useContext, useEffect, useMemo, useCallback } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { BackButton } from 'components/buttons';
import { colors } from 'css/Theme';
import { DrawerHeader } from '../../PanelDrawerWrapper';
import { PrepaymentPayInfo } from 'models/Cart';
import { FeePaymentMethod, PaymentType } from 'models/Checkout';
import { State } from 'store';
import { loadCart, setDutchiePayPreAuthError } from 'store/actions/CartActions';
import { addPaymentMethod, quitCheckout } from 'store/actions/CheckoutActions';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';
import { CheckoutContext } from '../CheckoutContext';
import { PaymentButtons } from '../PaymentButtons';
import {
  PaymentCash,
  PaymentCheck,
  PaymentCredit,
  PaymentDebit,
  PaymentDigital,
  PaymentDutchiePay,
  PaymentGift,
  PaymentMMAP,
  PaymentHub,
  PaymentManual,
  useCancelHubTransaction,
} from '../payments';
import * as paymentIcons from 'assets/icons/payments';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { PaymentGeneriPay } from '../payments/PaymentGeneriPay';
import { GeneriPayPaymentIntegration } from 'queries/v2/payments/generipay';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useConfirmationModalOnHubBackClick } from 'util/hooks/launch-darkly/useConfirmationModalOnHubBackClick';
import { showHubBackClickPopup } from 'store/actions/PopupsActions';

export const PaymentOptions: FC = () => {
  const ldClient = useLDClient();
  const { integrations, selectedRegister } = useSelector((state: State) => state.settings);
  const checkout = useSelector((state: State) => state.checkout);
  const cart = useSelector((state: State) => state.cart);
  const { selectedPaymentType, setSelectedPaymentType, paymentTypeMetaData, setPaymentTypeMetaData } =
    useContext(CheckoutContext);
  const dispatch = useDispatch();

  const { dutchiePayPreAuthError, showDutchiePayPreAuthCheckout } = useDutchiePayPreAuth();
  const preAuthInfo = cart.details.PreauthInfo;
  const isPreAuth = preAuthInfo && preAuthInfo.PreauthAmount > 0;
  const isPaymentsHubDutchiePay = isPreAuth && selectedPaymentType === PaymentType.Hub;
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const isDutchiePayPreAuth = isPreAuth && preAuthInfo.PaymentType === 'dutchiepay';
  const isPaymentTypeSelected = selectedPaymentType !== null;
  const showConfirmationModalOnHubBackClick = useConfirmationModalOnHubBackClick();

  const { guestId: AcctId, shipmentId: ShipmentId } = useTransactionManager();

  useEffect(() => {
    //Aeropay Remote Pay hack to add the payment data into the list of items already paid, and not allowing the budtender to remove it
    if (
      cart.details.Payment?.ElectronicType === 'gtipay-remotepay' &&
      !checkout.payment?.methods?.some((x) => x.name === 'Bank Transfer')
    ) {
      dispatch(
        addPaymentMethod({
          id: uuidv4(),
          name: 'Bank Transfer',
          type: PaymentType.Digital,
          amount: cart.details.Payment?.ElectronicAmount,
          finalized: true,
        })
      );
    }
    if (
      cart.details.PrepaymentPayInfo &&
      cart.details.PrepaymentPayInfo.length > 0 &&
      !checkout.payment?.methods?.some((x) => x.type === PaymentType.Prepayment)
    ) {
      const prePaymentInfo = cart.details.PrepaymentPayInfo;
      let amount = 0;
      let type = '';
      prePaymentInfo.forEach((item: PrepaymentPayInfo) => {
        amount += item.Amount;
        type = item.PrepaymentType;
      });
      dispatch(addPaymentMethod({ id: uuidv4(), name: type, type: PaymentType.Prepayment, amount, finalized: true }));
    }
    if (
      !cart.isDutchiePayError &&
      isDutchiePayPreAuth &&
      !checkout.payment?.methods?.some((x) => x.type === PaymentType.Dutchie)
    ) {
      const amount = cart.details.GrandTotalRounded;
      const tipAmount = cart.details.TipAmount;
      const type = preAuthInfo.PaymentType.toString();
      const dutchiePayDoubleChargeFixRollout = ldClient?.variation(
        'fintech.retail-payments.pos.dutchiepay-double-charge.rollout',
        false
      );
      const preauthId = dutchiePayDoubleChargeFixRollout ? preAuthInfo.PreauthId : uuidv4();

      dispatch(
        addPaymentMethod({
          id: preauthId,
          name: type,
          type: PaymentType.Dutchie,
          amount,
          tipAmount,
          finalized: true,
          dutchiePayDoubleChargeFixRollout,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.details.Payment, cart.details.PrepaymentPayInfo, preAuthInfo, dispatch]);

  const onPaymentButtonClick =
    (paymentType: PaymentType, paymentTypeMetaData: GeneriPayPaymentIntegration | null = null) =>
    async () => {
      await applyPaymentFees(paymentType);
      setSelectedPaymentType(paymentType);
      setPaymentTypeMetaData(paymentTypeMetaData);
    };

  const applyPaymentFees = async (paymentType: PaymentType) => {
    const dutchiePayFeeIds = _.chain(cart.details.FeesByPaymentType)
      .filter((x) => x.FeePaymentMethod === FeePaymentMethod.DutchiePay)
      .map((x) => x.FeeDonationId)
      .value();
    const pinDebitFeeIds = _.chain(cart.details.FeesByPaymentType)
      .filter((x) => x.FeePaymentMethod === FeePaymentMethod.PinDebit)
      .map((x) => x.FeeDonationId)
      .value();

    const creditFeeIds = _.chain(cart.details.FeesByPaymentType)
      .filter((x) => x.FeePaymentMethod === FeePaymentMethod.Credit)
      .map((x) => x.FeeDonationId)
      .value();

    if (paymentType === PaymentType.DutchiePay && dutchiePayFeeIds.length > 0) {
      const appliedFeesIds = cart.details.FeesDonations.map((x) => x.FeeDonationId);
      if (_.intersection(dutchiePayFeeIds, appliedFeesIds).length !== dutchiePayFeeIds.length) {
        const feePaymentMethodIds = [FeePaymentMethod.DutchiePay];
        if (cart.details.ExistingPayments.some((ep) => ep.PaymentProviderType === 'paynetworx')) {
          feePaymentMethodIds.push(FeePaymentMethod.PinDebit);
        }
        dispatch(
          loadCart({
            ShipmentId,
            Timestamp: +new Date(),
            AcctId,
            Register: Number(selectedRegister?.value),
            UpdateCache: true,
            FeePaymentMethodIds: feePaymentMethodIds,
          })
        );
      }
    } else if (paymentType === PaymentType.Debit && pinDebitFeeIds.length > 0) {
      const appliedFeesIds = cart.details.FeesDonations.map((x) => x.FeeDonationId);
      if (_.intersection(pinDebitFeeIds, appliedFeesIds).length !== pinDebitFeeIds.length) {
        const feePaymentMethodIds = [FeePaymentMethod.PinDebit];

        // TODO: This doesn't account for Dutchie Pay split payments (which aren't currently a thing)
        dispatch(
          loadCart({
            ShipmentId,
            Timestamp: +new Date(),
            AcctId,
            Register: Number(selectedRegister?.value),
            UpdateCache: true,
            FeePaymentMethodIds: feePaymentMethodIds,
          })
        );
      }
    } else if (paymentType === PaymentType.Credit && creditFeeIds.length > 0) {
      const appliedFeesIds = cart.details.FeesDonations.map((x) => x.FeeDonationId);
      if (_.intersection(creditFeeIds, appliedFeesIds).length !== creditFeeIds.length) {
        const feePaymentMethodIds = [FeePaymentMethod.Credit];
        // TODO: This doesn't account for Dutchie Pay split payments (which aren't currently a thing)
        dispatch(
          loadCart({
            ShipmentId,
            Timestamp: +new Date(),
            AcctId,
            Register: Number(selectedRegister?.value),
            UpdateCache: true,
            FeePaymentMethodIds: feePaymentMethodIds,
          })
        );
      }
    } else {
      const paymentFeesIds = dutchiePayFeeIds.concat(pinDebitFeeIds.concat(creditFeeIds));
      const appliedFeesIds = cart.details.FeesDonations.map((x) => x.FeeDonationId);
      if (_.intersection(paymentFeesIds, appliedFeesIds).length > 0) {
        dispatch(
          loadCart({
            ShipmentId,
            Timestamp: +new Date(),
            AcctId,
            Register: Number(selectedRegister?.value),
            UpdateCache: true,
          })
        );
      }
    }
  };

  const clearPaymentAndReloadCart = useCallback(() => {
    setSelectedPaymentType(null);
    setPaymentTypeMetaData(null);
    dispatch(
      loadCart({
        ShipmentId,
        Timestamp: +new Date(),
        AcctId,
        Register: Number(selectedRegister?.value),
        UpdateCache: true,
      })
    );
  }, [AcctId, dispatch, selectedRegister, setPaymentTypeMetaData, setSelectedPaymentType, ShipmentId]);

  const cancelPaymentsHub = useCancelHubTransaction();

  const onBackClick = useCallback(async () => {
    if (isPaymentTypeSelected) {
      if (digitalPaymentLoading && selectedPaymentType === PaymentType.Hub && integrations?.UsePaymentsHub) {
        if (showConfirmationModalOnHubBackClick) {
          dispatch(showHubBackClickPopup({
            onCancel: async () => {
              await cancelPaymentsHub();
              clearPaymentAndReloadCart();
            },
          }));

          return;
        }

        await cancelPaymentsHub();
      }
      clearPaymentAndReloadCart();
    } else {
      dispatch(setDutchiePayPreAuthError(false));
      dispatch(quitCheckout());
    }
  }, [isPaymentTypeSelected, selectedPaymentType, integrations, digitalPaymentLoading, clearPaymentAndReloadCart, showConfirmationModalOnHubBackClick, cancelPaymentsHub, dispatch]);

  const renderCurrentPayment = () => {
    switch (selectedPaymentType) {
      case PaymentType.Cash:
        return <PaymentCash />;
      case PaymentType.Debit:
        return <PaymentDebit />;
      case PaymentType.Credit:
        return <PaymentCredit />;
      case PaymentType.MMAP:
        return <PaymentMMAP />;
      case PaymentType.Check:
        return <PaymentCheck />;
      case PaymentType['Gift Card']:
        return <PaymentGift />;
      case PaymentType.Digital:
        return <PaymentDigital />;
      case PaymentType.DutchiePay:
        return <PaymentDutchiePay />;
      case PaymentType.Hub:
        return <PaymentHub />;
      case PaymentType.Manual:
        return <PaymentManual />;
      case PaymentType.GeneriPay:
        return <PaymentGeneriPay PaymentTypeMetaData={paymentTypeMetaData} />;
    }
  };

  const sectionIcon = useMemo(() => {
    if (!isPaymentTypeSelected) {
      return null;
    }

    if (showDutchiePayPreAuthCheckout) {
      return null;
    }

    switch (selectedPaymentType) {
      case PaymentType.Cash:
        return <paymentIcons.CashIcon />;
      case PaymentType.Check:
        return <paymentIcons.CheckIcon />;
      case PaymentType.Credit:
        return <paymentIcons.CardIcon />;
      case PaymentType.Debit:
        return <paymentIcons.DebitIcon />;
      case PaymentType.Digital:
        return <paymentIcons.DigitalIcon />;
      case PaymentType['Gift Card']:
        return <paymentIcons.GiftCardIcon />;
      case PaymentType.MMAP:
        return <paymentIcons.MMAPIcon />;
      case PaymentType.Hub:
        return <paymentIcons.HubIcon />;
      case PaymentType.Manual:
        return <paymentIcons.ManualIcon />;
      case PaymentType.GeneriPay:
        return <paymentIcons.DebitIcon />;
    }
  }, [isPaymentTypeSelected, selectedPaymentType, showDutchiePayPreAuthCheckout]);

  const sectionTitle = useMemo(() => {
    if (isPaymentsHubDutchiePay) {
      return 'Dutchie Hub';
    }

    if (showDutchiePayPreAuthCheckout) {
      return 'Back to cart';
    }

    const notInPreAuthFlow = !isPreAuth || cart.isDutchiePayError || dutchiePayPreAuthError;

    if (notInPreAuthFlow && isPaymentTypeSelected) {
      if (selectedPaymentType === PaymentType['MMAP']) {
        return 'Medical Marijuana Assistance Program';
      }
      // See ENG-62820 and comment in `PaymentButtons.tsx` for context
      if (selectedPaymentType === PaymentType.Debit && integrations?.DebitProcessor === 'usag') {
        return 'Terminal';
      }
      if (selectedPaymentType === PaymentType.Hub) {
        return 'Dutchie Hub';
      }
      if (paymentTypeMetaData != null) {
        return paymentTypeMetaData.Name;
      }
      return PaymentType[selectedPaymentType as PaymentType];
    }

    return 'Select payment type';
  }, [
    cart.isDutchiePayError,
    dutchiePayPreAuthError,
    integrations,
    isPaymentTypeSelected,
    isPaymentsHubDutchiePay,
    isPreAuth,
    paymentTypeMetaData,
    selectedPaymentType,
    showDutchiePayPreAuthCheckout,
  ]);

  const { isCompactLayout } = useCompactCartLayout();

  return (
    <Container>
      {isCompactLayout ? (
        <DrawerHeader
          onGoBack={isPaymentsHubDutchiePay || !isPaymentTypeSelected ? undefined : onBackClick}
          actionProps={{
            label: !isPaymentTypeSelected ? 'Back to cart' : 'Close',
            disabled: isPaymentsHubDutchiePay,
            onClick: onBackClick,
          }}
        >
          {sectionIcon}
          {sectionTitle}
        </DrawerHeader>
      ) : (
        <TitleBox>
          {!isPaymentsHubDutchiePay && <BackButton onClick={onBackClick} />}
          <Title>
            {sectionIcon}
            {sectionTitle}
          </Title>
        </TitleBox>
      )}
      {isPaymentTypeSelected ? (
        <Payment>{renderCurrentPayment()}</Payment>
      ) : (
        <PaymentButtons handleClick={onPaymentButtonClick} />
      )}
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const TitleBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  gap: 1rem;
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
`;

const Payment = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.dutchie.primaryWhite};
  flex-grow: 1;
`;
