import React from 'react';
import { useDeliveryPageEnhancements } from 'util/hooks/launch-darkly/useDeliveryPageEnhancements';
import { EmbeddedParentTable } from './EmbeddedParentTable';
import { EmbeddedParentTableV2 } from './EmbeddedParentTableV2';
import { EmbeddedTableProps } from './EmbeddedTableProps';
import { ProvideEmbeddedTableStore } from './useEmbeddedTableStore';

export function EmbeddedTable<ParentItem, ChildItem>(props: EmbeddedTableProps<ParentItem, ChildItem>) {
  const isUseDeliveryPageEnhancements = useDeliveryPageEnhancements();

  return (
    <ProvideEmbeddedTableStore props={props}>
      {isUseDeliveryPageEnhancements ? (
        <EmbeddedParentTableV2<ParentItem, ChildItem> />
      ) : (
        <EmbeddedParentTable<ParentItem, ChildItem> />
      )}
    </ProvideEmbeddedTableStore>
  );
}
