import { calcCartTotal } from 'store/actions/CartActions';
import { convertToOldCartTotals } from 'queries/v2/cart/types';
import { useAnonymousCart } from './useAnonymousCart';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useTransactionManager } from './useTransactionManager';
import { useUpdateCartTotalsMutation } from 'queries/v2/cart/calculate-cart-total';

import type { CartTotal } from 'models/Cart';
import type { CalculateCartTotalsPayload } from 'queries/v2/cart/calculate-cart-total';

type MockReactMutation<T extends object> = {
  isLoading: boolean;
  mutate: (props?: Partial<CalculateCartTotalsPayload>) => void;
  mutateAsync: (props?: Partial<CalculateCartTotalsPayload>) => Promise<T>;
};

/**
 * @deprecated This method "React Queryifies" the old Redux cart store until we're ready to fully switch to React Query.
 */
const useUpdateCartTotalsRedux = (): MockReactMutation<CartTotal> => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.cart.areCartTotalsLoading);
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const { guestId, shipmentId } = useTransactionManager();

  const mutate = (props?: Partial<CalculateCartTotalsPayload>) => {
    dispatch(
      calcCartTotal({
        AcctId: props?.guestId ?? guestId,
        IsCheckout: props?.isCheckout,
        Register: props?.registerId ?? registerId,
        ShipmentId: props?.shipmentId ?? shipmentId,
        Timestamp: props?.timestamp ?? +new Date(),
      })
    );
  };

  const mutateAsync = async (props?: Partial<CalculateCartTotalsPayload>) => {
    const cartTotal = await dispatch(
      calcCartTotal({
        AcctId: props?.guestId ?? guestId,
        IsCheckout: props?.isCheckout,
        Register: props?.registerId ?? registerId,
        ShipmentId: props?.shipmentId ?? shipmentId,
        Timestamp: props?.timestamp ?? +new Date(),
      })
    ).unwrap();

    return cartTotal;
  };

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};

export const useUpdateCartTotals = () => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const { guestId, shipmentId } = useTransactionManager();

  const { mutate, mutateAsync, ...rest } = useUpdateCartTotalsMutation();

  const alteredMutate = (props?: Partial<CalculateCartTotalsPayload>) => {
    mutate({
      ...props,
      guestId: props?.guestId ?? guestId ?? 0,
      shipmentId: props?.shipmentId ?? shipmentId ?? 0,
    });
  };

  const alteredMutateAsync = async (props?: Partial<CalculateCartTotalsPayload>) => {
    const cartTotals = await mutateAsync({
      ...props,
      guestId: props?.guestId ?? guestId ?? 0,
      shipmentId: props?.shipmentId ?? shipmentId ?? 0,
    });
    return convertToOldCartTotals(cartTotals);
  };

  const reduxMutation = useUpdateCartTotalsRedux();

  return isAnonymousCartLDFlagEnabled
    ? { mutateAsync: alteredMutateAsync, mutate: alteredMutate, ...rest }
    : reduxMutation;
};
