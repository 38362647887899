import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { useServerMigrationConfig } from './useServerMigrationConfig';

export function ServerMigrationBanner() {
  const { registerURL, dayBeforeDeadline } = useServerMigrationConfig();
  const deadlineText = dayBeforeDeadline
    ? `As of ${dayBeforeDeadline} this URL will be disabled in preparation for your account environment upgrade the following day.`
    : `Your account will soon be migrated to an upgraded environment.`;

  if (!registerURL) {
    return null;
  }

  return (
    <Banner>
      <Text>
        <span>
          {deadlineText} Please <Link href={registerURL}>click here</Link> to access and log into your new URL. Visit
          the <Link href='https://support.dutchie.com/hc/en-us/articles/28929066112147'>help center</Link> for more
          information.
        </span>
      </Text>
      <StyledButton tertiary onClick={() => (window.location.href = registerURL)}>
        Go to new URL
      </StyledButton>
    </Banner>
  );
}

export const Banner = styled.div`
  background-color: #3c96fe;
  color: #fff;
  width: 100%;
  height: 48px;
  padding: var(--sizes-40);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--sizes-20);
`;

export const Text = styled.div`
  font: var(--font-extra-small-12pt-semibold);
  display: inline-block;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 90%;
`;

export const Link = styled.a`
  color: ${colors.dutchie.primaryWhite} !important;
  text-decoration: underline !important;
`;

export const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${colors.dutchie.primaryWhite};
  color: ${colors.dutchie.primaryWhite};
  font: var(--font-extra-small-12pt-semibold);
  height: 32px;
  width: 132px;

  :hover {
    background-color: ${colors.dutchie.primaryWhite};
  }
`;
