import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorNotification } from 'store/actions/NotificationsActions';
import { getProductId } from 'util/helpers/products/getProductId';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useCartItems } from 'util/hooks/cart/useCartItems';
import { useProducts } from 'util/hooks/products/useProducts';
import { useProductsById } from 'util/hooks/products/useProductsById';

import { Container, StyledBaseInput, InputLoading, MinusButton, PlusButton } from './ProductSelection.styles';

import type { CartItem } from 'models/Cart';
import type { ProductSearchResult } from 'queries/v2/product/types';
import type { State } from 'store';

type QuantitySelectorProps = {
  product: ProductSearchResult | CartItem;
  count: number;
  includeCartQtyInMax: boolean;
  onCountChange: (countChange: number) => void;
  isInCartSummary: boolean;
};

export const QuantitySelector: FC<QuantitySelectorProps> = ({
  product,
  count,
  onCountChange,
  includeCartQtyInMax,
  isInCartSummary,
}) => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const id = getProductId(product);

  const cartItemFromRQ = useCartItems((cartItemsById) => cartItemsById[id]);
  const cartItemFromRedux = useSelector((state: State) => state.cart.itemsById[id]);
  const cartItem = isAnonymousCartLDFlagEnabled ? cartItemFromRQ : cartItemFromRedux;

  const productItemNew = useProducts((productsById) => productsById[id]);
  const { productsById } = useProductsById();
  const productItem = isAnonymousCartLDFlagEnabled ? productItemNew : productsById?.[id];

  const itemsLoading = useSelector((state: State) => state.cartItems.loading);
  const [quantityInputFocused, setQuantityInputFocused] = useState(false);
  const dispatch = useDispatch();
  const qtyInCart = cartItem?.QtySelected ?? 0;
  const isLoading = itemsLoading[id];
  const [tempQtyInCart, setTempQtyInCart] = useState(count);
  const maxQtyAvailable = (productItem?.totalAvailable ?? 0) + (includeCartQtyInMax ? qtyInCart : 0);

  const handleInputChange = () => {
    if (tempQtyInCart > count) {
      if (tempQtyInCart > maxQtyAvailable) {
        dispatch(errorNotification(`The max quantity available is ${maxQtyAvailable}`));
        setTempQtyInCart(count);
      } else {
        onCountChange(tempQtyInCart - count);
      }
    } else {
      onCountChange(tempQtyInCart - count);
    }
  };

  const handleKeyPress = (key: string) => {
    if (key === 'Enter') {
      handleInputChange();
    }
  };

  useEffect(() => {
    setTempQtyInCart(count);
  }, [count]);

  return (
    <Container data-testid='product-selection-controls-container'>
      <MinusButton
        item={cartItem}
        isInCartSummary={isInCartSummary}
        count={count}
        disabled={count < 1 || isLoading || quantityInputFocused}
        onClick={() => onCountChange(-1)}
        automationId='quantity-selector_selector-button_minus'
      />
      {isLoading ? (
        <InputLoading automationId='quantity-selector_selector-button_input-loading' />
      ) : (
        <StyledBaseInput
          type='number'
          value={Number(tempQtyInCart).toString()}
          onChange={({ target }) => setTempQtyInCart(Math.trunc(Number(target.value)))}
          onKeyPress={({ key }) => handleKeyPress(key)}
          onFocus={() => {
            setQuantityInputFocused(true);
          }}
          onBlur={() => {
            handleInputChange();
            setQuantityInputFocused(false);
          }}
          automationId='quantity-selector_selector-button_input'
          ddActionName='quantity selector input'
        />
      )}
      <PlusButton
        disabled={tempQtyInCart >= maxQtyAvailable || isLoading || quantityInputFocused}
        onClick={() => onCountChange(1)}
        automationId='quantity-selector_selector-button_plus'
      />
    </Container>
  );
};
