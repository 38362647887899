import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const MMAPIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <path
            d='M1.75 3C1.75 2.30964 2.30964 1.75 3 1.75H13.1716C13.5031 1.75 13.821 1.8817 14.0555 2.11612L21.8839 9.94454C22.1183 10.179 22.25 10.4969 22.25 10.8284V21C22.25 21.6904 21.6904 22.25 21 22.25H3C2.30964 22.25 1.75 21.6904 1.75 21V3Z'
            stroke='#828A8F'
            stroke-width='1.5'
          />
          <path d='M14 16H19' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M7.5 7.5V5' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path
            d='M5.14453 14.3102C5.14453 15.5602 6.2612 15.9769 7.64453 15.9769C9.02786 15.9769 10.1445 15.9769 10.1445 14.3102C10.1445 11.8102 5.14453 11.8102 5.14453 9.31022C5.14453 7.64355 6.2612 7.64355 7.64453 7.64355C9.02786 7.64355 10.1445 8.27689 10.1445 9.31022'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path d='M7.5 16V18.5' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path
            d='M14 3V9C14 9.55228 14.4477 10 15 10H21'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='square'
            stroke-linejoin='round'
          />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <path
          d='M1 3C1 1.89543 1.89543 1 3 1H13.1716C13.702 1 14.2107 1.21071 14.5858 1.58579L22.4142 9.41421C22.7893 9.78929 23 10.298 23 10.8284V21C23 22.1046 22.1046 23 21 23H3C1.89543 23 1 22.1046 1 21V3Z'
          fill='#D2F9F9'
        />
        <path
          d='M1.75 3C1.75 2.30964 2.30964 1.75 3 1.75H13.1716C13.5031 1.75 13.821 1.8817 14.0555 2.11612L21.8839 9.94454C22.1183 10.179 22.25 10.4969 22.25 10.8284V21C22.25 21.6904 21.6904 22.25 21 22.25H3C2.30964 22.25 1.75 21.6904 1.75 21V3Z'
          stroke='#14BDBA'
          stroke-width='1.5'
        />
        <path d='M14 16H19' stroke='#14BDBA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M7.5 7.5V5' stroke='#14BDBA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path
          d='M5.14453 14.3102C5.14453 15.5602 6.2612 15.9769 7.64453 15.9769C9.02786 15.9769 10.1445 15.9769 10.1445 14.3102C10.1445 11.8102 5.14453 11.8102 5.14453 9.31022C5.14453 7.64355 6.2612 7.64355 7.64453 7.64355C9.02786 7.64355 10.1445 8.27689 10.1445 9.31022'
          stroke='#14BDBA'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path d='M7.5 16V18.5' stroke='#14BDBA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path
          d='M14 3V9C14 9.55228 14.4477 10 15 10H21'
          stroke='#14BDBA'
          stroke-width='1.5'
          stroke-linecap='square'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  );
};
