import { useSelector } from 'react-redux';

import type { State } from 'store';

type UseShouldBypassDOBCheckProps = {
  customerIsMedical: boolean;
};

export const useShouldBypassDOBCheck = ({ customerIsMedical }: UseShouldBypassDOBCheckProps) => {
  const isBypassBirthdayCheckForMedicalEnabled = useSelector(
    (state: State) => state.settings.features.BypassBirthdayCheckForMedical
  );

  return isBypassBirthdayCheckForMedicalEnabled && customerIsMedical;
};
