import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const CheckIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <path
            d='M8.212 15.637L4.5 16.167L5.03 12.455L14.576 2.90901C14.7849 2.70008 15.033 2.53435 15.306 2.42127C15.5789 2.3082 15.8715 2.25 16.167 2.25C16.4625 2.25 16.7551 2.3082 17.028 2.42127C17.301 2.53435 17.5491 2.70008 17.758 2.90901C17.9669 3.11795 18.1327 3.36599 18.2457 3.63897C18.3588 3.91196 18.417 4.20454 18.417 4.50001C18.417 4.79549 18.3588 5.08807 18.2457 5.36106C18.1327 5.63404 17.9669 5.88208 17.758 6.09101L8.212 15.637Z'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M6.25 7.25H2.25C1.85218 7.25 1.47064 7.40804 1.18934 7.68934C0.908035 7.97064 0.75 8.35218 0.75 8.75V20.25C0.75 20.6478 0.908035 21.0294 1.18934 21.3107C1.47064 21.592 1.85218 21.75 2.25 21.75H21.75C22.1478 21.75 22.5294 21.592 22.8107 21.3107C23.092 21.0294 23.25 20.6478 23.25 20.25V8.75C23.25 8.35218 23.092 7.97064 22.8107 7.68934C22.5294 7.40804 22.1478 7.25 21.75 7.25H20.25'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path d='M19.5 18H12' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M19.5 15H15' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <path
          d='M8.212 15.637L4.5 16.167L5.03 12.455L14.576 2.90901C14.7849 2.70008 15.033 2.53435 15.306 2.42127C15.5789 2.3082 15.8715 2.25 16.167 2.25C16.4625 2.25 16.7551 2.3082 17.028 2.42127C17.301 2.53435 17.5491 2.70008 17.758 2.90901C17.9669 3.11795 18.1327 3.36599 18.2457 3.63897C18.3588 3.91196 18.417 4.20454 18.417 4.50001C18.417 4.79549 18.3588 5.08807 18.2457 5.36106C18.1327 5.63404 17.9669 5.88208 17.758 6.09101L8.212 15.637Z'
          fill='#FFD0C2'
        />
        <path
          d='M8.212 15.637L4.5 16.167L5.03 12.455L14.576 2.90901C14.7849 2.70008 15.033 2.53435 15.306 2.42127C15.5789 2.3082 15.8715 2.25 16.167 2.25C16.4625 2.25 16.7551 2.3082 17.028 2.42127C17.301 2.53435 17.5491 2.70008 17.758 2.90901C17.9669 3.11795 18.1327 3.36599 18.2457 3.63897C18.3588 3.91196 18.417 4.20454 18.417 4.50001C18.417 4.79549 18.3588 5.08807 18.2457 5.36106C18.1327 5.63404 17.9669 5.88208 17.758 6.09101L8.212 15.637Z'
          stroke='#FF6B3E'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6.25 7.25H2.25C1.85218 7.25 1.47064 7.40804 1.18934 7.68934C0.908035 7.97064 0.75 8.35218 0.75 8.75V20.25C0.75 20.6478 0.908035 21.0294 1.18934 21.3107C1.47064 21.592 1.85218 21.75 2.25 21.75H21.75C22.1478 21.75 22.5294 21.592 22.8107 21.3107C23.092 21.0294 23.25 20.6478 23.25 20.25V8.75C23.25 8.35218 23.092 7.97064 22.8107 7.68934C22.5294 7.40804 22.1478 7.25 21.75 7.25H20.25'
          stroke='#FF6B3E'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path d='M19.5 18H12' stroke='#FF6B3E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M19.5 15H15' stroke='#FF6B3E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      </g>
    </svg>
  );
};
