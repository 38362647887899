import { createReducer } from '@reduxjs/toolkit';

import { setScannerAction, clearScannerAction } from 'store/actions/ScannerActions';

export type ScannerAction = (scanInput: string, scannerId?: number | string) => void;

export type ScannerState = {
  onScan: ScannerAction | null;
};

const initialState: ScannerState = {
  onScan: null,
};

export const scannerReducer = createReducer(initialState, (builder) => {
  builder.addCase(setScannerAction, (state, action) => {
    const { onScan, shouldForceUpdate } = action.payload;
    // New behavior is to check if onScan has changed before updating Redux
    if (state.onScan !== onScan || shouldForceUpdate) {
      state.onScan = onScan;
    }
  });

  builder.addCase(clearScannerAction, (state, _action) => ({
    ...state,
    onScan: null,
  }));
});
