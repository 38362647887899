import React, { forwardRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { CashAmount } from 'components/text';
import { DutchiePayLogo } from 'assets/DutchiePayLogo';

import { useTotalBreakdownDrawer } from './useTotalBreakdownDrawer';
import { TotalBreakdownDrawerLoadingSkeleton } from './components/TotalBreakdownDrawerLoadingSkeleton';
import {
  Container,
  Row,
  SubTotalRow,
  PreAuthRow,
  PreAuthLineItemText,
  NegativeValueContainer,
  TOTAL_BREAKDOWN_DRAWER_TRANSITION_DURATION,
} from './TotalBreakdownDrawer.styles';

import type { Cart } from 'models/Cart';

type TotalBreakdownDrawerProps = {
  cart: Partial<Cart>;
  className?: string;
  isOpen: boolean;
  isLoading?: boolean;
  automationId?: string;
};

/**
 * Prop drilling for cart is only used because this component is used by both the regular cart and the pre cart.
 * Once we get rid of the precart, we can just get these values inside useTotalBreakdownDrawer
 */
export const TotalBreakdownDrawer = forwardRef<HTMLDivElement, TotalBreakdownDrawerProps>(
  ({ cart, className, isOpen, automationId, isLoading = false }, ref) => {
    const {
      subtotal,
      totalDiscount,
      tax,
      feesAndDonations,
      roundingAmount,
      tipAmount,
      grandTotalRounded,
      totalLoyalty,
      paymentFee,
      appliedDutchiePayFees,
      hideTotals,
      showLoyaltyAsDiscount,
      showFeesAndDonations,
      showDutchiePayFee,
      showNonCashAdjustmentFee,
      showCardActivationFee,
      showRounding,
      showTipAmount,
      showDutchiePayPreAuthCheckout,
    } = useTotalBreakdownDrawer({ cart });

    if (isLoading) {
      return (
        <CSSTransition
          in={isOpen}
          timeout={TOTAL_BREAKDOWN_DRAWER_TRANSITION_DURATION}
          classNames='total-breakdown-drawer'
          unmountOnExit
        >
          <TotalBreakdownDrawerLoadingSkeleton />
        </CSSTransition>
      );
    }

    return (
      <CSSTransition
        in={isOpen}
        timeout={TOTAL_BREAKDOWN_DRAWER_TRANSITION_DURATION}
        classNames='total-breakdown-drawer'
        unmountOnExit
      >
        <Container ref={ref} className={className} data-testid={automationId ?? 'total-breakdown-drawer'}>
          <SubTotalRow>
            <div>Subtotal</div>
            <CashAmount automationId='total-breakdown-drawer-subtotal-amount' value={subtotal} />
          </SubTotalRow>
          {showLoyaltyAsDiscount ? (
            <Row data-testid='total-breakdown-drawer-discounts-and-loyalty'>
              <div>Discounts & loyalty</div>
              <NegativeValueContainer data-testid='total-breakdown-drawer-discounts-and-loyalty-amount'>
                {'- '}
                <CashAmount value={totalDiscount} hideValue={hideTotals} />
              </NegativeValueContainer>
            </Row>
          ) : (
            <>
              <Row data-testid='total-breakdown-drawer-discounts'>
                <div>Discounts</div>
                <NegativeValueContainer data-testid='total-breakdown-drawer-discounts-amount'>
                  {'- '}
                  <CashAmount
                    automationId='total-breakdown-drawer-discounts-value'
                    value={totalDiscount}
                    hideValue={hideTotals}
                  />
                </NegativeValueContainer>
              </Row>
              <Row data-testid='total-breakdown-drawer-loyalty'>
                <div>Loyalty</div>
                <NegativeValueContainer data-testid='total-breakdown-drawer-loyalty-amount'>
                  {'- '}
                  <CashAmount value={totalLoyalty} hideValue={hideTotals} />
                </NegativeValueContainer>
              </Row>
            </>
          )}
          <Row>
            <div>Tax</div>
            <CashAmount automationId='total-breakdown-drawer-tax-amount' value={tax} hideValue={hideTotals} />
          </Row>
          {showFeesAndDonations && (
            <Row>
              <div>Fees & donations</div>
              <CashAmount
                automationId='total-breakdown-drawer-fees-and-donations-amount'
                value={feesAndDonations}
                hideValue={hideTotals}
              />
            </Row>
          )}
          {showDutchiePayFee && (
            <Row>
              <div>Dutchie Pay fee</div>
              <CashAmount
                automationId='total-breakdown-drawer-dutchiepay-fee-amount'
                value={appliedDutchiePayFees}
                hideValue={hideTotals}
              />
            </Row>
          )}
          {showNonCashAdjustmentFee && (
            <Row>
              <div>Non-cash adjustment fee</div>
              <CashAmount
                automationId='total-breakdown-drawer-non-cash-adjustment-fee-amount'
                value={paymentFee}
                hideValue={hideTotals}
              />
            </Row>
          )}
          {showCardActivationFee && (
            <Row>
              <div>Card activation fee</div>
              <CashAmount
                automationId='total-breakdown-drawer-card-activation-fee-amount'
                value={paymentFee}
                hideValue={hideTotals}
              />
            </Row>
          )}
          {showRounding && (
            <Row>
              <div>Rounding</div>
              <CashAmount
                automationId='total-breakdown-drawer-rounding-amount'
                value={roundingAmount}
                hideValue={hideTotals}
              />
            </Row>
          )}
          {showTipAmount && (
            <Row>
              <div>Tip</div>
              <CashAmount automationId='total-breakdown-drawer-tip-amount' value={tipAmount} hideValue={hideTotals} />
            </Row>
          )}
          {showDutchiePayPreAuthCheckout && (
            <PreAuthRow>
              <PreAuthLineItemText>
                <DutchiePayLogo height={14} width={77} /> (Pre-auth)
              </PreAuthLineItemText>
              <CashAmount automationId='total-breakdown-drawer-dutchiepay-preauth-amount' value={grandTotalRounded} />
            </PreAuthRow>
          )}
        </Container>
      </CSSTransition>
    );
  }
);
