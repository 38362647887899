import React from 'react';
import { isKeyboardScanner } from '@dutchie/capacitor-hardware';
import {
  ConnectionStatusBanner,
  PeripheralSetupConnectionStatus,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { useScannerSettings } from '../hooks/useScannerSettings';

export const ScannerStatusBanner = () => {
  const { formValues, scanners } = useScannerSettings();

  const selectedScanner = scanners.find((scanner) => scanner.id === formValues.scannerId);

  if (!selectedScanner) {
    return null;
  }

  // If keyboard scanner is selected, show additional setup banner
  if (isKeyboardScanner(selectedScanner)) {
    return (
      <ConnectionStatusBanner
        deviceTypeName='Scanner'
        status={PeripheralSetupConnectionStatus.additionalSetupRequired}
        customText='Default scanner connected, but may not work with ID scanning. Configure scanner to proceed.'
      />
    );
  }

  return (
    <ConnectionStatusBanner
      deviceTypeName='Scanner'
      deviceName={selectedScanner.name}
      status={PeripheralSetupConnectionStatus.connected}
    />
  );
};
