import styled, { createGlobalStyle, css } from 'styled-components';
import { colors } from 'css/Theme';
import { ReactComponent as _DutchieLogo } from 'assets/icons/dutchie-logo-cfd.svg';
import { ReactComponent as _DollarSign } from 'assets/icons/dollar-sign.svg';
import { ReactComponent as DiscountsRedIcon } from 'assets/icons/discounts-red.svg';
import { ReactComponent as Confetti } from 'assets/icons/cfd-confetti-bg.svg';

// Global Styles and Animations

export const CFDGlobalStyles = createGlobalStyle`
  html {
    // Need to scale down for differences in resolution
    font-size: 10px;
  }

  @keyframes bubble-pop-animation {
    0% { transform: scale(1) }
    50% { transform: scale(1.15) }
    100% { transform: scale(1) }
  }
`;

export const BubblePopAnimation = css`
  animation-name: bubble-pop-animation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`;

// Idle Screens

export const CFDContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.dutchie.opal90};
`;

export const CustomIdleContainer = styled(CFDContainer)`
  background: black;
`;

export const IdleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const WelcomeMessage = styled.div`
  color: ${colors.dutchie.opal80};
  font-family: Matter;
  font-size: 6.25rem;
  line-height: 6.25rem;
  font-weight: 700;
  letter-spacing: 0.5%;
`;

export const DutchieLogo = styled(_DutchieLogo)<{ align?: 'bottom' }>`
  width: 6rem;
  height: 6rem;

  ${({ align }) =>
    align === 'bottom' &&
    `
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    `}
`;

// Cart Screen

export const CFDCartContainer = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  background: ${colors.dutchie.grey10};
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;

  ${({ show }) =>
    show &&
    `
    pointer-events: unset;
    opacity: 1;
  `}
`;

// Cart Screen - Left Panel

export const SummaryContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 2.5rem 2rem;
`;

export const SummaryHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  color: ${colors.dutchie.almostBlack};
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  padding: 0 0.5rem;
`;

export const updateCountClassName = 'updateCount';
export const NumItemsLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: ${colors.dutchie.primaryWhite};
  background: ${colors.dutchie.blue50};
  padding: 0.125rem 0.75rem;
  border-radius: 6.25rem;

  &.updateCount {
    ${BubblePopAnimation}
  }
`;

export const CartItemsContainer = styled.div`
  flex-grow: 1;
  padding: 0.5rem;
  overflow-y: auto;
`;

export const CartItemContainer = styled.div`
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${colors.dutchie.grey20};
  border-radius: 0.75rem;
  background: ${colors.dutchie.primaryWhite};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
`;

export const CartItemRow = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0.5rem;
`;

export const ItemDiscounts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
  border-top: 1px solid ${colors.dutchie.grey90};
`;

export const DiscountRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const DiscountLabel = styled.div`
  color: ${colors.dutchie.flora};
  font-weight: 600;
`;

export const DiscountIcon = styled(DiscountsRedIcon)`
  height: 1rem;
  width: 1rem;
`;

export const DiscountAmount = styled.div`
  margin-left: auto;

  b {
    font-weight: 700;
  }
`;

export const ItemInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const BrandLabel = styled.div`
  color: ${colors.dutchie.grey50};
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
`;

export const ItemQuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4.25rem;
  min-height: 2rem;
  padding: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
  background: ${colors.dutchie.grey10};
  border-radius: 0.25rem;
`;

export const ItemCost = styled.div`
  min-width: 20%;
  max-width: 7.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
`;

export const StrikeThrough = styled.div`
  color: ${colors.dutchie.gray70};
  font-weight: 400;
  text-decoration: line-through;
`;

// Cart Screen - Right Panel

export const TotalsContainer = styled(SummaryContainer)`
  max-width: 45%;
  padding: 1.5rem;
  border-left: 1px solid ${colors.dutchie.grey20};
`;

export const TotalsSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem;
  border: 1px solid ${colors.dutchie.grey20};
  border-radius: 1.25rem;
  background: ${colors.dutchie.primaryWhite};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
`;

export const BalanceDueSection = styled.section`
  margin-top: 2.5rem;
  margin-bottom: 3.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  color: ${colors.dutchie.blue100};
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
`;

export const BalanceAmount = styled.div`
  font-family: 'Matter';
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.32rem;
  color: ${colors.dutchie.green50};
  border-radius: 0.75rem;
  border: 0.1875rem solid ${colors.dutchie.grey20};
  padding: 1.5rem 2.5rem;
`;

export const TotalAmount = styled.div`
  text-align: right;
`;

export const TotalRow = styled.div<{ highlight?: boolean }>`
  display: flex;
  padding: 0 1.25rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  font-family: 'Matter';
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.5%;

  ${({ highlight }) =>
    highlight &&
    `
    font-weight: 700;

    ${TotalAmount} {
      color: ${colors.dutchie.green50};
    }
  `}
`;

// Discounts Banner

export const DiscountContainer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 1.25rem;
  color: ${colors.dutchie.primaryWhite};
  background: ${colors.dutchie.green50};
  border-radius: 0.75rem;

  b {
    font-weight: 600;
  }
`;

export const DiscountTitle = styled.div`
  font-weight: 600;
`;

export const DollarSign = styled(_DollarSign)`
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: ${colors.dutchie.primaryWhite};

  path {
    fill: ${colors.dutchie.green60};
  }
`;

// Thank You Screen

export const ThankYouContainer = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 6rem;
  gap: 0.5rem;
  background: ${colors.dutchie.opal90};
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;

  ${({ show }) => show && `opacity: 1;`}

  * {
    z-index: 1;
  }
`;

export const ConfettiBackground = styled(Confetti)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
  z-index: 0;
`;

export const ThankYouTitle = styled.div`
  font-family: 'Matter';
  color: ${colors.dutchie.opal10};
  font-size: 3rem;
  line-height: 3.25rem;
  font-weight: 700;
`;

export const ThankYouSubtitle = styled.div`
  font-family: 'Matter';
  color: ${colors.dutchie.opal10};
  font-size: 2.5rem;
  line-height: 2.7rem;
  font-weight: 700;
`;

export const ChangeDueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 3rem;
`;

export const ChangeDueAmount = styled.div`
  font-family: 'Matter';
  color: ${colors.dutchie.green50};
  font-size: 9rem;
  line-height: 9.1rem;
  font-weight: 500;
`;

export const ChangeDueLabel = styled.div`
  font-family: 'Matter';
  color: ${colors.dutchie.opal50};
  font-size: 1.5rem;
  line-height: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
`;
