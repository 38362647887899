import React from 'react';

type SingleChevronIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function SingleChevronIcon(props: SingleChevronIconProps) {
  const { height = 16, width = 16, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.19285 11.0442C0.941553 10.8039 0.942086 10.4147 1.19404 10.175L5.57647 6.00538L1.20588 1.82449C0.954603 1.58412 0.955174 1.19495 1.20715 0.955254C1.45913 0.715556 1.86711 0.7161 2.11838 0.95647L6.71623 5.35475C6.8058 5.44039 6.87716 5.54234 6.92558 5.65415C6.97405 5.76611 6.99892 5.88609 6.99874 6.00722C6.99857 6.12835 6.97337 6.24826 6.92458 6.36009C6.87584 6.47178 6.80454 6.57322 6.71472 6.65863L2.10408 11.0454C1.85212 11.2851 1.44415 11.2846 1.19285 11.0442Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.11207 11.1211C0.816265 10.8382 0.816892 10.3801 1.11347 10.0979L5.41512 6.00518L1.12509 1.90135C0.829312 1.61842 0.829984 1.16033 1.12659 0.878182C1.42319 0.596037 1.9034 0.596678 2.19918 0.879615L6.79702 5.27789C6.89717 5.37364 6.9769 5.48758 7.03104 5.6126C7.08524 5.73779 7.11304 5.87193 7.11285 6.00737C7.11265 6.1428 7.08448 6.27687 7.02992 6.40192C6.97543 6.5268 6.8957 6.64022 6.79528 6.73571L2.18466 11.1224C1.88808 11.4046 1.40787 11.404 1.11207 11.1211ZM1.27462 10.252C1.06728 10.4493 1.06684 10.7696 1.27364 10.9674C1.48044 11.1651 1.81616 11.1656 2.0235 10.9683L6.6338 6.58189C6.71301 6.50657 6.77626 6.41676 6.81923 6.31827C6.86227 6.21964 6.88449 6.11389 6.88464 6.00707C6.8848 5.90025 6.86287 5.79444 6.82012 5.69571C6.77742 5.5971 6.71479 5.50748 6.6358 5.43195L2.03759 1.03333C1.83081 0.835522 1.49508 0.835074 1.28772 1.03233C1.08036 1.22958 1.07989 1.54983 1.28667 1.74764L5.65727 5.92853C5.70176 5.97109 5.70166 6.04 5.65705 6.08245L1.27462 10.252ZM5.72347 6.14606C5.76807 6.1036 5.8403 6.10367 5.88481 6.1462C5.9293 6.18875 5.92961 6.25803 5.88501 6.30048C5.84042 6.34293 5.76819 6.34287 5.72367 6.30034C5.67918 6.25778 5.67888 6.18851 5.72347 6.14606ZM5.72387 5.86509C5.6794 5.82252 5.6795 5.75362 5.72411 5.71118C5.76875 5.66877 5.84137 5.66852 5.88585 5.71109C5.93032 5.75365 5.93022 5.82255 5.88561 5.86499C5.84097 5.90741 5.76835 5.90766 5.72387 5.86509Z'
        fill={color}
      />
    </svg>
  );
}
