import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import styled from 'styled-components';
import { colors } from 'css/Theme';

import { ItemCount } from 'components/cart/CartComponents.styles';
import { PreorderCartItem } from '..';

type PreOrderInfoHeaderProps = {
  cartItems: PreorderCartItem[];
};

export const PreOrderInfoHeader: FC<PreOrderInfoHeaderProps> = ({ cartItems }) => {
  const transactionReference = useSelector((state: State) => state.customer.details?.TransactionReference);

  const totalItems = useMemo(() => {
    let total = 0;
    for (const cartItem of cartItems) {
      if (cartItem.unitId === 1) {
        // UnitId of 1 means it's a quantity-based product so add the quantity
        total += cartItem.quantity;
      } else {
        // If weight-based, just add 1 per line item
        total += 1;
      }
    }
    return total;
  }, [cartItems]);

  return (
    <ProductsTotalDiv>
      <PreCartHeader>
        Cart
        <ItemCount data-testid='preorder-info-header_item-count'>{totalItems}</ItemCount>
      </PreCartHeader>
      {transactionReference && (
        <ReferenceNumber data-testid='cart-summary_div_cart-subtotal-and-total-item-count'>
          Ref: {transactionReference}
        </ReferenceNumber>
      )}
    </ProductsTotalDiv>
  );
};

const ProductsTotalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  margin-right: auto;
`;

const PreCartHeader = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
`;

const ReferenceNumber = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
`;
