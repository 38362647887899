import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type UseNavigationWrapperReturn = {
  isHintOpen: boolean;
  isDrawerOpen: boolean;
  openHint: () => void;
  closeHint: () => void;
  openDrawer: () => void;
  closeDrawer: () => void;
};

export function useNavigationWrapper(): UseNavigationWrapperReturn {
  const [isHintOpen, setIsHintOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // hooks
  const location = useLocation();

  // methods
  const openHint = () => {
    setIsHintOpen(true);
  };

  const closeHint = () => {
    setIsHintOpen(false);
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  // effects
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return {
    isHintOpen,
    isDrawerOpen,
    openHint,
    closeHint,
    openDrawer,
    closeDrawer,
  };
}
