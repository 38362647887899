import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { post } from 'api/HttpHelpers';

import { getCartDetailsKeys } from './query-key-factory';
import { getProductId } from 'util/helpers/products/getProductId';
import { setCartItemsLoading } from 'store/actions/CartItemsActions';
import { updateProductQuantities } from './helpers/updateProductQuantities';
import { transformToAddItemResponse } from './types';
import { getAllProductsKeys } from '../product/query-key-factory';
import { useRefetchProductsAfterFailedAddItem } from 'util/hooks/launch-darkly/useRefetchProductsAfterFailedAddItem';

import type { AddItemToShoppingCartResponse, AddItemResponse } from './types';
import type { ProductSearchResult } from '../product/types';

const ADD_ITEM_TO_SHOPPING_CART = 'v2/cart/add_item_to_shopping_cart';

export type AddItemToShoppingCartPayload = {
  availableOz: number;
  batchId?: number;
  cannabisProduct?: string;
  count: number;
  defaultLabelId?: number;
  defaultUnitId?: number;
  grouping: string;
  guestId: number;
  inventoryId?: number;
  isLoyaltyAsDiscountEnabled: boolean;
  isQuantityItem: boolean;
  isRunAutoDiscountEnabled: boolean;
  isRunAutoPriceEnabled: boolean;
  isUsingDaysSupply: boolean;
  itemLabelId?: number | null;
  packageItemId?: number | null;
  productDescription: string;
  productId: number;
  recUnitPrice: number;
  registerId: number;
  serialNo: string;
  shipmentId: number;
  unitPrice: number;
  weight: number;
};

const transformToServerPayload = (payload: AddItemToShoppingCartPayload) => {
  return {
    AcctId: payload.guestId,
    AvailOz: payload.availableOz,
    BatchId: payload.batchId,
    CannbisProduct: payload.cannabisProduct,
    Cnt: payload.count,
    DefaultLabelId: payload.defaultLabelId,
    DefaultUnitId: payload.defaultUnitId,
    Grouping: payload.grouping,
    InventoryId: payload.inventoryId,
    ItemLabelId: payload.itemLabelId,
    LoyaltyAsDiscount: payload.isLoyaltyAsDiscountEnabled,
    PackageItemId: payload.packageItemId,
    ProductDesc: payload.productDescription,
    ProductId: payload.productId,
    QuantityItem: payload.isQuantityItem,
    RecUnitPrice: payload.recUnitPrice,
    Register: payload.registerId,
    RunAutoDiscount: payload.isRunAutoDiscountEnabled,
    RunAutoPrice: payload.isRunAutoPriceEnabled,
    SerialNo: payload.serialNo,
    ShipmentId: payload.shipmentId,
    UnitPrice: payload.unitPrice,
    UsingDaysSupply: payload.isUsingDaysSupply,
    Weight: payload.weight,
  };
};

export const useAddItemToShoppingCartMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { isRefetchProductsAfterFailedAddItemEnabled } = useRefetchProductsAfterFailedAddItem();

  return useMutation(
    async (payload: AddItemToShoppingCartPayload): Promise<AddItemResponse> => {
      const productId = getProductId({ SerialNo: payload.serialNo, ProductId: payload.productId });

      // Set the loading state for this product to true
      dispatch(setCartItemsLoading({ productId, isLoading: true }));

      // Make the request
      const response = await post<AddItemToShoppingCartResponse>(
        ADD_ITEM_TO_SHOPPING_CART,
        transformToServerPayload(payload)
      );

      return transformToAddItemResponse(response);
    },
    {
      onMutate: (payload) => {
        const productList = queryClient.getQueryData<ProductSearchResult[]>(getAllProductsKeys.all);

        const newProductList = updateProductQuantities({
          quantityChanged: payload.count,
          serialNumber: payload.serialNo,
          productList,
        });

        if (newProductList) {
          queryClient.setQueryData(getAllProductsKeys.all, newProductList);
        }

        return { productList };
      },
      onError: (_error, _payload, context) => {
        if (isRefetchProductsAfterFailedAddItemEnabled) {
          queryClient.invalidateQueries(getAllProductsKeys.all);
        } else {
          queryClient.setQueryData(getAllProductsKeys.all, context?.productList);
        }

        // Wait to throw error until onSettled
      },
      onSettled: (_data, error, payload) => {
        // Set the loading to false on success or failure
        const productId = getProductId({ SerialNo: payload.serialNo, ProductId: payload.productId });
        dispatch(setCartItemsLoading({ productId, isLoading: false }));

        // Refetch the cart details
        queryClient.invalidateQueries(
          getCartDetailsKeys.one({ guestId: payload.guestId, shipmentId: payload.shipmentId })
        );

        if (error) {
          throw error;
        }
      },
    }
  );
};
