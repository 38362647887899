import React from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useCfdSettings } from '../hooks/useCfdSettings';
import { SettingsSelect, SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';

export const CfdPreferredScreenListItem = () => {
  const { formValues, availableScreensOptions, requestScreenPermissions, setSecondaryScreen } = useCfdSettings();

  const handleChange = (option?: SettingsSelectOption | undefined): void => {
    setSecondaryScreen(option?.value ?? '');
  };

  return (
    <SettingsListItem
      title='Monitor'
      subtitle='Select monitor for customer-facing display'
      actions={[
        <SettingsSelect
          value={formValues.secondaryScreenId}
          options={availableScreensOptions}
          onChange={handleChange}
          onRefresh={async () => requestScreenPermissions()}
          placeholder='Select monitor'
        />,
      ]}
    />
  );
};
