import { useMemo } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useAllotmentLimit } from './AllotmentLimit/useAllotmentLimit';
import { useAppSelector } from 'util/hooks';
import { useIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';
import { useDaysSupplyLimit } from './DaysSupplyLimit/useDaysSupplyLimit';
import { useMMCEULimit } from './MMCEULimit/useMMCEULimit';

import type { PreorderCartItem } from 'pages/CreatePreOrderPage';

type LegalLimitSummaryProps = {
  preorderCartItems?: PreorderCartItem[];
};

export const useLimitSummary = (props?: LegalLimitSummaryProps) => {
  const preorderCartItems = props?.preorderCartItems;

  const ldClient = useLDClient();

  const isMMCEUFlagEnabled = ldClient?.variation('pos.register.enable-mmceu-calculator.rollout', false) === true;

  const isDaysSupplyEnabled = useIsDaysSupplyFeatureEnabled();
  const isMississippiLocation = useAppSelector(
    (state) => state.settings.locationSettings?.State.toLowerCase() === 'ms'
  );

  const { used, remaining } = useAllotmentLimit({ preorderCartItems });
  const { daysSupplyInCart, daysSupplyRemaining } = useDaysSupplyLimit({ preorderCartItems });
  const { mmceuInCart, mmceuRemaining } = useMMCEULimit({ preorderCartItems });

  const showMMCEUSupply = isMississippiLocation && isMMCEUFlagEnabled;
  const showDaysSupply = isDaysSupplyEnabled && !showMMCEUSupply;

  const allotmentSummary = useMemo(() => {
    if (showMMCEUSupply) {
      const mmceuUsed = mmceuInCart.replace(' MMCEU', '');
      return `${mmceuUsed}/${mmceuRemaining}`;
    } else if (showDaysSupply) {
      const daysSupplyUsed = daysSupplyInCart.replace(' days', '');
      return `${daysSupplyUsed}/${daysSupplyRemaining}`;
    } else {
      return `${used}/${remaining}`;
    }
  }, [
    used,
    remaining,
    daysSupplyInCart,
    daysSupplyRemaining,
    mmceuInCart,
    mmceuRemaining,
    showMMCEUSupply,
    showDaysSupply,
  ]);

  return {
    allotmentSummary,
  };
};
