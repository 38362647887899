import React, { useReducer, useContext, createContext, PropsWithChildren, Dispatch, useEffect } from 'react';
import { TableProps } from '../TableProps';
import { Action, defaultState, reducer, State } from './tableReducer';

type ContextState<ItemType> = [State<ItemType>, Dispatch<Action<ItemType>>] | null;

const context = createContext<ContextState<unknown>>(null);

export function useTableStore<ItemType>() {
  const contextState = useContext(context) as ContextState<ItemType>;
  if (contextState === null) {
    throw new Error('State not initialized - did you use ProvideTableStore?');
  }
  const [state, dispatch] = contextState;

  return { ...state, dispatch };
}

export function ProvideTableStore<ItemType>({ children, props }: PropsWithChildren<{ props: TableProps<ItemType> }>) {
  const initialState = {
    props,
    ...defaultState,
    pageSize: props.pageSize ?? defaultState.pageSize,
    sortColumn: props.sortColumn ?? defaultState.sortColumn,
    sortDirection: props.sortDirection ?? defaultState.sortDirection,
  };
  const contextState: ContextState<ItemType> = useReducer(reducer, initialState);

  // Side effects

  const [, dispatch] = contextState;
  useEffect(() => {
    dispatch({ type: 'updateProps', props });
  }, [props, dispatch]);

  return <context.Provider value={contextState as ContextState<unknown>}>{children}</context.Provider>;
}
