import { cloneDeep } from 'lodash';

type CompareObjectProps<Data extends object, FormattedData> = {
  /** The key from the objects to compare */
  key: keyof Data;
  /** Left object to compare */
  left: Data;
  /** Right object to compare */
  right: Data;
  /** Format the data of a given key for comparison */
  format?: (value: Data[keyof Data]) => FormattedData;
};

export const compareObjects = <Data extends object, FormattedData>({
  key,
  left,
  right,
  format,
}: CompareObjectProps<Data, FormattedData>): boolean => {
  const leftValue = cloneDeep(left)[key];
  const rightValue = cloneDeep(right)[key];

  if (!leftValue || !rightValue) {
    return false;
  }

  // Check for custom formatter first
  if (format) {
    if (!format(leftValue) || !format(rightValue)) {
      return false;
    }
    return format(leftValue) === format(rightValue);
  }

  // Ignore case for strings
  if (typeof leftValue === 'string' || typeof rightValue === 'string') {
    return leftValue.toString().toLowerCase() === rightValue.toString().toLowerCase();
  }

  // Stringify child objects
  if (typeof leftValue === 'object' || typeof rightValue === 'object') {
    return JSON.stringify(leftValue) === JSON.stringify(rightValue);
  }

  return leftValue === rightValue;
};
