import { AutocompleteCloseReason, createFilterOptions } from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { constant } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { LinkButton } from '../../button';
import { PopperComponent } from './popper-component';
import type { DropdownMenuBaseProps, DropdownMenuOption } from './types';
import {
  NO_OPTIONS_TEXT_DEFAULT,
  POPPER_MODIFIERS,
  POPPER_PLACEMENT_DEFAULT,
  SEARCH_PLACEHOLDER_DEFAULT,
} from './constants';
import {
  AutocompleteContainer,
  DropdownMenuFooter,
  DropdownMenuItem,
  DropdownMenuPopper,
  PopperLayout,
  SearchInput,
} from './dropdown-menu.styles';
import NoResults from './no-results';
import { LoadingSkeleton } from './loading-skeleton';

export type DropdownMenuMultipleProps = DropdownMenuBaseProps & {
  handleClose: (newValue?: DropdownMenuOption[]) => void;
  loading?: boolean;
  onChange?: (event: React.KeyboardEvent | React.KeyboardEvent<HTMLLIElement>, value: DropdownMenuOption[]) => void;
  value: DropdownMenuOption[];
};

export function DropdownMenuMultiple({
  anchorEl,
  footerContent,
  handleClose,
  id,
  limit = 100,
  loading = false,
  noOptionsText = NO_OPTIONS_TEXT_DEFAULT,
  onChange,
  open,
  options,
  placement = POPPER_PLACEMENT_DEFAULT,
  searchPlaceholder = SEARCH_PLACEHOLDER_DEFAULT,
  value,
}: DropdownMenuMultipleProps) {
  const [workingValue, setWorkingValue] = useState<DropdownMenuOption[]>(value);

  const handleSetValue = (event, selections: DropdownMenuOption[]) => {
    const sanitizedValue = selections.filter(({ disabled }) => !disabled);

    if (onChange) {
      onChange(event, sanitizedValue);
    }

    setWorkingValue(sanitizedValue);
  };

  const selectAll = (event) => handleSetValue(event, [...options]);
  const selectNone = (event) => handleSetValue(event, []);

  const handleOnChange = (
    event: React.KeyboardEvent<HTMLLIElement> | React.MouseEvent<HTMLLIElement>,
    newValue,
    reason
  ) => {
    // This conditional ignores backspace key presses when removing a selected item
    if (event.type === 'keydown' && 'key' in event && event.key === 'Backspace' && reason === 'removeOption') {
      return;
    }

    handleSetValue(event, newValue);
  };

  const handleAutocompleteOnClose = (event: React.BaseSyntheticEvent, reason: AutocompleteCloseReason) => {
    if (reason === 'escape') {
      handleClose(workingValue);
    }
  };

  const handleOnClickAway = () => {
    handleClose(workingValue);
  };

  const zeroOptions = useMemo(() => options.length === 0, [options]);

  // keep workingValue reactive to changes in value
  useEffect(() => {
    setWorkingValue(value);
  }, [value]);

  return (
    <DropdownMenuPopper
      $emptyMultiselect={zeroOptions}
      $singleSelectionEnabled={false}
      anchorEl={anchorEl}
      id={id}
      modifiers={POPPER_MODIFIERS}
      open={open}
      placement={placement}
    >
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <PopperLayout>
          {loading && <LoadingSkeleton />}
          {!loading && zeroOptions && <NoResults />}
          {!loading && !zeroOptions && (
            <AutocompleteContainer
              disableCloseOnSelect
              filterOptions={createFilterOptions({ limit })}
              isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
              multiple
              noOptionsText={noOptionsText}
              open
              options={[...options].sort((firstOption, secondOption) => {
                // Display the selected labels first.
                let firstOptionIndex = value.findIndex((selection) => firstOption?.label === selection?.label);
                firstOptionIndex =
                  firstOptionIndex === -1
                    ? value.length + options.findIndex((selection) => firstOption?.label === selection?.label)
                    : firstOptionIndex;
                let secondOptionIndex = value.indexOf(secondOption);
                secondOptionIndex =
                  secondOptionIndex === -1 ? value.length + options.indexOf(secondOption) : secondOptionIndex;
                return firstOptionIndex - secondOptionIndex;
              })}
              PopperComponent={PopperComponent}
              renderInput={(params) => (
                <SearchInput
                  inputProps={params.inputProps}
                  inputRef={params.InputProps.ref}
                  placeholder={searchPlaceholder}
                />
              )}
              renderOption={(props, option: DropdownMenuOption, { selected }) => (
                <DropdownMenuItem
                  $hasSecondaryLabel={Boolean(option.labelSecondary)}
                  aria-disabled={props['aria-disabled']}
                  aria-selected={props['aria-selected']}
                  checkboxLabel={option.label}
                  checked={selected}
                  className='dropdown--option'
                  danger={option.danger}
                  data-option-index={props['data-option-index']}
                  disabled={option.disabled}
                  footer={option.footer}
                  id={props.id}
                  key={option.key ?? props.id}
                  labelSecondary={option.labelSecondary}
                  role={props.role}
                  tabIndex={props.tabIndex}
                  title={option.label}
                  value={option.id}
                  variant='checkbox'
                  onClick={props.onClick}
                  onMouseOver={props.onMouseOver}
                  onTouchStart={props.onTouchStart}
                />
              )}
              renderTags={constant(null)}
              value={workingValue}
              onChange={handleOnChange}
              onClose={handleAutocompleteOnClose}
            />
          )}
          <DropdownMenuFooter>
            <LinkButton buttonSize='small' disabled={zeroOptions} label='Select all' onClick={selectAll} />
            <LinkButton buttonSize='small' disabled={zeroOptions} label='Select none' onClick={selectNone} />
            {footerContent}
          </DropdownMenuFooter>
        </PopperLayout>
      </ClickAwayListener>
    </DropdownMenuPopper>
  );
}
