import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { colors } from 'css/Theme';
import { DrawerHeader } from './PanelDrawerWrapper';
import { AppliedDiscount } from 'models/Discounts';
import { successNotification } from 'store/actions/NotificationsActions';
import { hideDiscountPanel } from 'store/actions/CartActions';
import { Loader } from 'components/backoffice/loader';
import { removeAppliedDiscount } from 'store/actions/CartItemsActions';
import { BackButton, ButtonWithIcon } from 'components/buttons';
import { ReactComponent as DiscountIcon } from 'assets/icons/discounts-icon.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { SidePanel, SidePanelSection, SectionHeader } from 'components/layout';
import { ApplyDiscountCode } from '../CartComponents/ApplyDiscountCode';
import { useRemoveDiscounts } from 'util/hooks/cart/useRemoveDiscounts';
import { useGetCartDetails } from '../hooks/useGetCartDetails';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';

type RolledUpDiscount = {
  DiscountName: string;
  TotalAmt: number;
  Items: AppliedDiscount[];
};

type RolledUpDiscounts = Record<string | number, RolledUpDiscount>;

export const ManageDiscounts: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const removeDiscounts = useRemoveDiscounts();

  const { data: cart, isLoading: isCartLoading, refetch: refetchCartDetails } = useGetCartDetails();

  const discounts: AppliedDiscount[] = cart?.Discounts ?? [];
  const rolledUpDiscounts: RolledUpDiscounts = {};

  for (const d of discounts) {
    if (d.IsLoyalty) {
      // Skip any discounts that are loyalty
      continue;
    }
    if (rolledUpDiscounts[d.DiscountId] === undefined) {
      rolledUpDiscounts[d.DiscountId] = {
        DiscountName: d.DiscountReason.split(') ')[1],
        TotalAmt: 0,
        Items: [],
      };
    }
    rolledUpDiscounts[d.DiscountId].TotalAmt += d.DiscountAmt;
    rolledUpDiscounts[d.DiscountId].Items.push(d);
  }

  const onRemoveSingleDiscount = async (discount: AppliedDiscount) => {
    try {
      setLoading(true);
      const cartItem = cart?.Cart.find((cartItem) => discount.InvId === cartItem.InventoryId);
      await dispatch(
        removeAppliedDiscount({
          InvId: discount.AppliedDiscountId,
          ShipmentId: cart.ShipmentId,
          ProductId: cartItem?.ProductId,
          SerialNo: cartItem?.SerialNo,
        })
      );
    } finally {
      await refetchCartDetails();
      setLoading(false);
    }
  };

  const onRemoveAllDiscounts = async (discountId: string) => {
    setLoading(true);
    await removeDiscounts(discountId);
    await refetchCartDetails();
    setLoading(false);
    dispatch(successNotification('All Selected Discounts Removed Successfully'));
  };

  const onBackClick = () => {
    dispatch(hideDiscountPanel());
  };

  const { isCompactLayout } = useCompactCartLayout();

  return (
    <SidePanel data-testid={'manage-discounts_div_container'}>
      <SidePanelSection>
        {isCompactLayout ? (
          <DrawerHeader actionProps={{ label: 'Close', onClick: onBackClick }}>Manage Discounts</DrawerHeader>
        ) : (
          <TitleContainer>
            <BackButton onClick={onBackClick} />
            <Title>Manage Discounts</Title>
          </TitleContainer>
        )}
        <ApplyDiscountCode />
      </SidePanelSection>

      <SidePanelSection scroll flex gap='1rem'>
        <SubTitleContainer>
          <SectionHeader>Cart Discounts</SectionHeader>
          <TotalItems>
            {discounts.length} {discounts.length === 1 ? 'discount' : 'discounts'}
          </TotalItems>
        </SubTitleContainer>

        {loading || isCartLoading ? (
          <SpinnerWrapperDiv>
            <Loader variant='black' size='2x' />
          </SpinnerWrapperDiv>
        ) : (
          <>
            {Object.keys(rolledUpDiscounts).map((discountId) => (
              <Discount key={discountId}>
                <DiscountInfo>
                  <DiscountName>
                    <StyledDiscountIcon />
                    {rolledUpDiscounts[discountId].DiscountName}
                  </DiscountName>
                  <DiscountAmount>
                    <b>Total:</b>
                    {`$${rolledUpDiscounts[discountId].TotalAmt.toFixed(2)}`}
                  </DiscountAmount>
                </DiscountInfo>
                {rolledUpDiscounts[discountId].Items.map((discount) => (
                  <Product>
                    <ProductName>{discount.ProductName}</ProductName>
                    <DiscountAmount>{`$${discount.DiscountAmt.toFixed(2)}`}</DiscountAmount>
                    <DeleteIcon onClick={() => onRemoveSingleDiscount(discount)} />
                  </Product>
                ))}
                {rolledUpDiscounts[discountId].Items.length > 1 && (
                  <>
                    <DeleteButton
                      tertiary
                      icon={DeleteIcon}
                      label='Remove All'
                      onClick={() => onRemoveAllDiscounts(discountId)}
                    />
                  </>
                )}
              </Discount>
            ))}
          </>
        )}
      </SidePanelSection>
    </SidePanel>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin: 0 0 1rem;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TotalItems = styled.p`
  color: ${colors.dutchie.grey};
`;

const Discount = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  color: ${colors.dutchie.almostBlack};
  border: 1px solid ${colors.dutchie.backgroundGrey};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
`;

const DiscountName = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
`;

const StyledDiscountIcon = styled(DiscountIcon)`
  flex: 0 0 1rem;
  margin-right: 0.5rem;
`;

const DiscountAmount = styled.div`
  color: ${colors.dutchie.grey40};
  display: flex;
  gap: 0.25rem;

  b {
    font-weight: 500;
  }
`;

const DiscountInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${colors.dutchie.backgroundGrey};

  & ${DiscountAmount} {
    color: ${colors.dutchie.almostBlack};
  }
`;

const Product = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ProductName = styled.span`
  margin-right: auto;
`;

const DeleteIcon = styled(Delete)`
  color: ${colors.dutchie.grey30};
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const DeleteButton = styled(ButtonWithIcon)`
  height: 2.75rem;
  margin-top: 1rem;
  justify-self: flex-end;
`;

const SpinnerWrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
