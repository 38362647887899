import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { SearchIcon } from 'assets/icons/SearchIcon';
import { useSearch } from './useSearch';

type SearchBarProps = {
  /** Enable auto to search on typing */
  auto?: boolean;
  disabled?: boolean;
  onSearch?: (value: string) => unknown;
  onAsyncSearch?: (value: string) => Promise<unknown>;
  placeholder: string;
  automationId?: string;
  initialValue?: string;
  width?: string;
  showSearchButton?: boolean;
};

export const Search = React.memo(SearchImpl);

export function SearchImpl({
  auto = false,
  disabled = false,
  onSearch,
  onAsyncSearch,
  placeholder,
  automationId = 'search-bar_input-field',
  initialValue = '',
  width,
  showSearchButton = false,
}: SearchBarProps): JSX.Element {
  const {
    handleChange,
    handleFocusInput,
    handleKeyPress,
    handleSearchButtonClicked,
    hasFocus,
    inputRef,
    isProcessingAsyncSearch,
    searchString,
    setHasFocus,
    value,
  } = useSearch({ initialValue, isAutoSearchEnabled: auto, onSearch, onAsyncSearch });

  return (
    <SearchContainer onKeyPress={handleKeyPress} width={width}>
      <SearchBar onClick={handleFocusInput} hasFocus={hasFocus} showSearchButton={showSearchButton}>
        <SearchIcon onClick={handleFocusInput} />
        <SearchInput
          ref={inputRef}
          value={value}
          disabled={isProcessingAsyncSearch || disabled}
          onChange={handleChange}
          placeholder={placeholder}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          data-testid={automationId}
          enterKeyHint='search'
        />
      </SearchBar>
      {showSearchButton && (
        <SearchButton
          onClick={handleSearchButtonClicked}
          disabled={isProcessingAsyncSearch || disabled || searchString === ''}
          automationId='search-bar_search-button'
        >
          <SearchIcon fill='white' />
        </SearchButton>
      )}
    </SearchContainer>
  );
}

export const SearchContainer = styled.div<{ width?: string }>`
  display: flex;
  height: 2.75rem;
  border-radius: 0.375rem;
  ${({ width }) => (width ? `width: ${width};` : 'flex-grow: 1;')}
`;

const SearchBar = styled.div<{ hasFocus?: boolean; showSearchButton?: boolean }>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  color: ${colors.dutchie.gray80};
  background: ${colors.dutchie.opal10};
  border-radius: inherit;
  cursor: pointer;

  ${({ showSearchButton }) =>
    showSearchButton &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const SearchInput = styled.input.withConfig({ shouldForwardProp: (prop) => true })`
  flex-grow: 1;
  background: none;
  border: none;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.00438em;
  color: ${colors.dutchie.gray80};
  outline: none;
  text-overflow: ellipsis;
`;

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
`;
