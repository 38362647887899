import React, { FC, useEffect } from 'react';
import { Popup } from 'components/popups';
import { Column, Table } from 'components/tables';
import styled from 'styled-components';
import { LabData } from 'models/Labs';
import { State } from 'store';
import { clearLabData, loadLabData } from 'store/actions/ProductsActions';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/backoffice/loader';
import { colors } from 'css/Theme';

type LabsPopupProps = {
  hide: () => void;
  batchId?: number;
};

export const LabsPopup: FC<LabsPopupProps> = ({ hide, batchId }) => {
  const labData = useSelector((state: State) => state.products.labData);
  const loadingLabData = useSelector((state: State) => state.products.loadingLabData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLabData(batchId));
  }, [dispatch, batchId]);

  const columns: Array<Column<LabData>> = [
    { header: 'Lab', field: 'Name' },
    { header: 'Value', field: 'Value' },
    { header: 'Unit', field: 'Unit' },
  ];

  const handleClose = () => {
    dispatch(clearLabData());
    hide();
  };

  return (
    <PopupDiv isVisible hide={handleClose} caption='Labs'>
      {loadingLabData ? (
        <LabsBody>
          <Loader size='2x' variant='black' />
        </LabsBody>
      ) : (
        <LabsBody>
          <LabsTable>
            <Table<LabData> data={labData?.filter((d) => d.IsCannabinoid) || []} columns={columns} />
          </LabsTable>
          <TableDivider />
          <LabsTable>
            <Table<LabData> data={labData?.filter((d) => !d.IsCannabinoid) || []} columns={columns} />
          </LabsTable>
        </LabsBody>
      )}
    </PopupDiv>
  );
};

const PopupDiv = styled(Popup)`
  height: 80vh;
  width: 80vw;
`;

const LabsBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(80vh - 60px);
  margin-top: -0.5rem;
`;

const TableDivider = styled.div`
  border-left: 1px solid ${colors.dutchie.shadowGrey};
`;

const LabsTable = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 1.5rem);
  overflow-y: auto;
  overflow-x: none;

  table {
    margin-bottom: 1.5rem;
  }

  &:first-child {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  &:last-child {
    padding-right: 1.5rem;
  }
`;
