import styled, { css } from 'styled-components';
import { FormControl as MUIFormControl } from '@mui/material';

export const RebrandFormControl = styled(MUIFormControl)<{
  $gridColumns?: string;
  $labelPlacement: 'bottom' | 'end' | 'start' | 'toggle' | 'top';
  $width?: string;
  $fullWidth?: boolean;
  $multilineLabel?: boolean;
}>`
  ${({ $gridColumns, $labelPlacement, $width, $fullWidth, $multilineLabel }) => css`
    display: grid;
    grid-template-columns: repeat(${$gridColumns ?? 6}, 1fr);
    width: ${$width ?? '100%'};
    grid-column-gap: var(--sizes-60);
    align-items: center;

    ${$labelPlacement === 'top' &&
    css`
      display: flex;
      flex-direction: column;
      > label {
        font: var(--font-extra-small-12pt-normal);
        margin-bottom: 5px;
        align-self: start;
      }
    `}

    ${$labelPlacement === 'bottom' &&
    css`
      flex-direction: column-reverse;
      > label {
        margin-top: 5px;
      }
    `}

    ${$labelPlacement === 'start' &&
    css`
      flex-direction: row;

      > label {
        align-self: ${$multilineLabel ? 'start' : 'center'};
        margin-top: ${$multilineLabel ? 'var(--sizes-40)' : '0'};
        margin-bottom: 0;
      }
    `}

    ${$labelPlacement === 'end' &&
    css`
      flex-direction: row-reverse;
      > label {
        align-self: center;
        margin-left: 5px;
      }
    `}

    ${($labelPlacement === 'toggle' || $labelPlacement === 'image-uploader') &&
    css`
      > label {
        align-self: start;
        margin-top: 6px;
      }
    `}

    ${$fullWidth &&
    css`
      margin-bottom: 0;
      display: block;
    `}
  `}
`;
