import { createSlice, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { setStorage, SetStoragePayload, StorageType } from 'store/actions/StorageActions';

export type StorageState = Record<StorageType, Record<string, string | null>>;

const initialState = {
  local: {},
  session: {},
};

export const StorageSlice = createSlice({
  name: 'browserStorage',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<StorageState>) => {
    builder.addCase(setStorage, (state: StorageState, action: PayloadAction<SetStoragePayload>) => {
      const { storageType, key, value } = action.payload;
      state[storageType][key] = value;
    });
  },
});
