import { useLDClient } from 'launchdarkly-react-client-sdk';

type GeneralBannerConfig = {
  visible: boolean;
  copy?: string;
};

export const useGeneralHeaderBannerConfig = () => {
  const ldClient = useLDClient();
  const generalHeaderBannerConfig: GeneralBannerConfig = ldClient?.variation(
    'backoffice.platform.general-header-banner.entitlement',
    { visible: false }
  );
  return { generalHeaderBannerConfig };
};
