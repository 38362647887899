import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { State } from 'store';
import { setGuestStatus } from 'store/actions/GuestListActions';
import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';
import { colors } from 'css/Theme';
import { Loader } from 'components/backoffice/loader';
import { ReactComponent as Check } from 'assets/icons/checkmark-icon.svg';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { Header } from 'components/modals/ConfirmationModal';

import type { CheckedInGuest } from 'models/Guest';

type SetOrderStatusPopupProps = {
  hide: () => void;
  guest?: CheckedInGuest;
  action?: callback;
  title?: string;
};

export const SetOrderStatusPopup: FC<SetOrderStatusPopupProps> = ({ guest, hide, action, title }) => {
  const dispatch = useDispatch();
  const statuses = useSelector((state: State) => state.guestList.allStatuses);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>();

  const {
    data: { ShipmentId, TransactionStatus },
  } = useGetCartDetails();

  // Set up the fallback values if the CheckedInGuest object is not provided
  // That indicates this was opened from the cart page
  const shipmentIdToUse = guest?.ShipmentId ?? ShipmentId;
  const transactionStatusToUse = guest?.TransactionStatus ?? TransactionStatus;

  const showSpinner = (status: string) => status === selectedStatus;

  const updateStatus = async (newStatus: string) => {
    setSelectedStatus(newStatus);
    setLoading(true);
    // Complete provided action first
    await action?.();
    // Then if new status is selected, update status to override
    if (newStatus !== transactionStatusToUse) {
      await dispatch(
        setGuestStatus({
          TransId: shipmentIdToUse,
          TransactionStatus: newStatus,
          reloadCurrentCustomer: true,
        })
      );
    }
    setLoading(false);
    setSelectedStatus(undefined);
    hide();
  };

  return (
    <StyledPopup isVisible hide={hide} title={title ?? 'Order Status'}>
      <ContentDiv>
        <Message>What's the status of this order now?</Message>
        <StatusTiles>
          {statuses.map((status) => (
            <StatusTile
              key={status.PosStatusId}
              onClick={() => updateStatus(status.POSStatus)}
              disabled={loading && !showSpinner(status.POSStatus)}
            >
              <ColorBar color={status.Color} />
              {showSpinner(status.POSStatus) ? (
                <Loader variant='custom' color={colors.dutchie.almostBlack} />
              ) : (
                status.POSStatus
              )}
              {transactionStatusToUse === status.POSStatus && (
                <CurrentLabel>
                  <CheckIcon />
                </CurrentLabel>
              )}
            </StatusTile>
          ))}
        </StatusTiles>
      </ContentDiv>
    </StyledPopup>
  );
};

const StyledPopup = styled(ConfirmationPopup)`
  width: 47.75rem;
  background: ${colors.dutchie.lightGrey};

  ${Header} {
    background: ${colors.dutchie.lightGrey};
  }
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem 3rem;
`;

const Message = styled.div`
  color: black;
  font-size: 0.825rem;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
`;

const StatusTiles = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

const StatusTile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  width: 9rem;
  height: 5.375rem;
  background: white;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.1);
  position: relative;
  &:disabled {
    opacity: 0.6;
  }
`;

const ColorBar = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  ${({ color }) => color && `background: ${color};`}
`;

const CurrentLabel = styled.div`
  width: 2rem;
  height: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${colors.dutchie.green};
  border-radius: 1.25rem;
  padding: 3px 8px;
  position: absolute;
  bottom: 0.25rem;
  right: 0.28125rem;
`;

const CheckIcon = styled(Check)`
  width: 9px;
  height: 7px;
`;
