import React, { FC, useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { errorNotification } from 'store/actions/NotificationsActions';
import * as springBigApi from 'api/SpringBigApi';
import { Popup } from 'components/popups';
import { Loader } from 'components/backoffice/loader';
import { ImageAsset } from 'components/image';
import { addSpringBigReward, addSpringBigOffer } from 'store/actions/CartActions';
import { callback } from 'models/Misc';
import { SpringBigInfo, SpringBigReward, SpringBigAppliedReward, SpringBigOffer } from 'models/SpringBig';
import { CartItem } from 'models/Cart';
import { borderStyles, colors, popupWidth } from 'css/Theme';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { format, parseISO } from 'date-fns';
import { DutchieLogoImage } from 'assets/Assets';
import { Button } from 'components/buttons';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useAppSelector } from 'util/hooks';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type SpringBigRedeemPopupProps = {
  hide: callback;
  item?: CartItem;
};

export const SpringBigRedeemPopup: FC<SpringBigRedeemPopupProps> = ({ hide, item }) => {
  const [springBigInfo, setSpringBigInfo] = useState<SpringBigInfo | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const refetchCartDetails = useRefetchCartDetails();
  const moveExternalLoyaltyButtonsToCartLevelMenu = useAppSelector(
    (state) => state.settings.features.LoyaltyAsCartDiscount
  );

  const { guestId, shipmentId } = useTransactionManager();

  const getSpringBigInfo = useCallback(async () => {
    if (guestId && shipmentId) {
      try {
        const { Data } = await springBigApi.getSpringBigInfo(guestId, shipmentId);
        setSpringBigInfo(Data);
      } catch ({ message }) {
        dispatch(errorNotification(message));
        setSpringBigInfo(undefined);
      }
    }
  }, [dispatch, guestId, shipmentId]);

  useEffect(() => {
    getSpringBigInfo();
  }, [getSpringBigInfo]);

  const addReward = async (reward: SpringBigReward) => {
    setLoading(true);
    try {
      if (moveExternalLoyaltyButtonsToCartLevelMenu) {
        dispatch(
          addSpringBigReward({
            ShipmentId: shipmentId,
            CustomerId: guestId,
            RewardToApply: reward,
          })
        );
      } else {
        await dispatch(
          addSpringBigReward({
            ShipmentId: shipmentId,
            AllocatedInventoryId: item?.InventoryId,
            ProductId: item?.ProductId,
            CustomerId: guestId,
            SerialNumber: item?.SerialNo,
            RewardToApply: reward,
          })
        );
      }
      await getSpringBigInfo();
    } catch (error) {
      dispatch(errorNotification(error));
    }
    setLoading(false);
  };

  const addOffer = async (offer: SpringBigOffer) => {
    setLoading(true);
    try {
      if (moveExternalLoyaltyButtonsToCartLevelMenu) {
        dispatch(
          addSpringBigOffer({
            ShipmentId: shipmentId,
            CustomerId: guestId,
            OfferToApply: offer,
          })
        );
      } else {
        await dispatch(
          addSpringBigOffer({
            ShipmentId: shipmentId,
            AllocatedInventoryId: item?.InventoryId,
            ProductId: item?.ProductId,
            CustomerId: guestId,
            SerialNumber: item?.SerialNo,
            OfferToApply: offer,
          })
        );
      }
      getSpringBigInfo();
    } catch (error) {
      dispatch(errorNotification(error));
    }
    setLoading(false);
  };

  const removeReward = async (reward: SpringBigAppliedReward) => {
    setLoading(true);
    try {
      await springBigApi.removeSpringBigReward({
        ShipmentId: shipmentId,
        LoyaltyDiscountRedemptionId: reward.LoyaltyDiscountRedemptionId,
      });

      await refetchCartDetails();

      await getSpringBigInfo();
    } catch (message) {
      dispatch(errorNotification(message));
    }
    setLoading(false);
  };

  const balance = springBigInfo?.member?.balance || 0;
  const pointsSpent = springBigInfo?.pointsSpent || 0;
  const pointsRemaining = balance - pointsSpent;

  return (
    <RedeemPopup caption='Redeem Spring Big Offers and Rewards' hide={hide} isVisible>
      {loading ? (
        <SpinnerWrapperDiv>
          <Loader size='2x' variant='black' />
        </SpinnerWrapperDiv>
      ) : (
        <PopupContent>
          <PopupSection>
            <p>Balance Info</p>
            <PopupSectionContentTop>
              <PopupText>Available Points: {springBigInfo ? balance : ''}</PopupText>
              <PopupText>Points Redeemed: {springBigInfo ? pointsSpent : ''}</PopupText>
              <PopupText>Points Remaining: {springBigInfo ? pointsRemaining : ''}</PopupText>
            </PopupSectionContentTop>
          </PopupSection>
          <PopupSection>
            <p>Redeemed Rewards and Offers</p>
            <PopupSectionContentTop>
              {!!springBigInfo?.appliedRewards &&
                springBigInfo?.appliedRewards.map((reward) => (
                  <PopupSectionContentCard>
                    <PopupText>
                      {reward.RedemptionName} - {reward.LoyaltyPointCost} points
                    </PopupText>
                    <StyledDelete onClick={() => removeReward(reward)} />
                  </PopupSectionContentCard>
                ))}
            </PopupSectionContentTop>
          </PopupSection>
          <PopupSection>
            <p>Offers</p>
            <PopupSectionContent>
              {springBigInfo?.offers.map((offer) => (
                <PopupSectionContentCardBordered>
                  {offer.images && offer.images.length > 0 ? (
                    <CardImg src={offer.images[0].thumb} />
                  ) : (
                    <CardImgAsset asset={DutchieLogoImage} />
                  )}
                  <div>
                    <PopupText>{offer.name}</PopupText>
                    <PopupText>
                      Expires: {offer.expiration ? format(parseISO(offer.expiration), 'MM/dd/yyyy') : 'N/A'}
                    </PopupText>
                    <PopupText>Max Redemptions: {offer.max_redemptions}</PopupText>
                    <PopupText>Redemptions Remaining: {offer.redemptions_available}</PopupText>
                  </div>
                  <Button onClick={() => addOffer(offer)}>Add</Button>
                </PopupSectionContentCardBordered>
              ))}
            </PopupSectionContent>
          </PopupSection>
          <PopupSection>
            <p>Rewards</p>
            <PopupSectionContent>
              {springBigInfo?.rewards.map((reward) => {
                const canAfford = pointsRemaining >= reward.item_value;
                return (
                  <PopupSectionContentCardBordered>
                    {reward.images && reward.images.length > 0 ? (
                      <CardImg src={reward.images[0].thumb} />
                    ) : (
                      <CardImgAsset asset={DutchieLogoImage} />
                    )}
                    <div>
                      <PopupText>{reward.name}</PopupText>
                      <PopupTextAfford afford={canAfford}>Point Value:{reward.item_value}</PopupTextAfford>
                      <PopupText>{reward.description}</PopupText>
                    </div>
                    <Button disabled={!canAfford} onClick={() => addReward(reward)}>
                      Add
                    </Button>
                  </PopupSectionContentCardBordered>
                );
              })}
            </PopupSectionContent>
          </PopupSection>
        </PopupContent>
      )}
    </RedeemPopup>
  );
};

const imageStyles = css`
  border: ${borderStyles.border};
  border-radius: ${borderStyles.borderRadius};
  width: 100px;
  height: 100px;
  margin-right: 0.75rem;
  padding: 0.5rem;
`;

const RedeemPopup = styled(Popup)`
  height: 90vh;
  width: ${popupWidth.large};
  overflow-y: auto;
`;

const SpinnerWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const PopupContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

const PopupSection = styled.div`
  flex-basis: 49.5%;
`;

const PopupSectionContent = styled.div`
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 470px);
  overflow-y: auto;
`;

const PopupSectionContentTop = styled(PopupSectionContent)`
  justify-content: center;
  height: 150px;
  border: ${borderStyles.border};
  border-radius: ${borderStyles.borderRadius};
  padding: 1rem 1.25rem;
`;

const PopupSectionContentCard = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  padding: 1.5rem 1.25rem;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  & > *:last-child {
    margin-left: auto;
  }
`;

const PopupSectionContentCardBordered = styled(PopupSectionContentCard)`
  border: ${borderStyles.border};
  border-radius: ${borderStyles.borderRadius};
`;

const PopupText = styled.p`
  color: ${colors.dutchie.grey};
`;

const PopupTextAfford = styled(PopupText)<{ afford: boolean }>`
  ${({ afford }) => !afford && `color: ${colors.dutchie.red}`};
`;

const CardImg = styled.img`
  ${imageStyles};
`;

const CardImgAsset = styled(ImageAsset)`
  ${imageStyles};
`;

const StyledDelete = styled(Delete)`
  color: ${colors.dutchie.grey};
`;
