import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import { warningNotification } from 'store/actions/NotificationsActions';
import { useTransactionsPopups } from 'components/TransactionsPopups';

import type { callback } from 'models/Misc';
import type { Transaction } from 'models/Transactions';

export const useTransactionsPage = (transactions: Transaction[], reloadTransactions: callback) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | undefined>();
  const search = queryString.parse(location.search);
  const transactionId = Number(search.transactionId) || undefined;
  const transactionPopups = useTransactionsPopups();

  const handleVoidTransaction = (providedTransaction?: Transaction) => {
    const transaction = providedTransaction ?? selectedTransaction;

    if (transaction?.PosStatus?.includes('Voided') || transaction?.PosStatus?.includes('Canceled')) {
      dispatch(warningNotification('Transaction has already been voided'));
      return;
    }

    transactionPopups.showVoidTransactionPopup(transaction?.PosId ?? transactionId ?? 0, reloadTransactions);
  };

  useEffect(() => {
    reloadTransactions();
  }, [reloadTransactions]);

  useEffect(() => {
    if (transactionId) {
      setSelectedTransaction(transactions.find((item) => item.PosId === transactionId));
    }
  }, [transactionId, transactions]);

  return {
    handleVoidTransaction: handleVoidTransaction,
    selectedTransaction,
    setSelectedTransaction,
    transactionId,
  };
};
