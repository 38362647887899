import { useCallback, useMemo, useState } from 'react';
import { logger, customEventKeys } from 'util/logger';
import { GetPrintNodePrintersContext, UserSelectedPrinterContext } from 'util/logger/types/printing';
import { getPrintNodePrintersDescription } from 'util/logger/helpers/printing';
import { isPrintNodeLabelPrinter } from 'hardware/label-printer';
import { HardwareService, LabelPrinter, useLabelPrinters } from '@dutchie/capacitor-hardware';
import { errorNotification } from 'store/actions/NotificationsActions';
import { PrinterListHookData, PrinterPickerHookData } from '../types';
import { isNativeLabelPrinter } from '@dutchie/capacitor-peripheral';
import { HARDWARE_LIBRARY_STORAGE_KEYS } from 'util/hardwareLibrary/hardware-library-utils';
import { TEST_FOOTER, TEST_HEADER } from '../Constants/ZPLEncodingConstants';
import { useLocalStorage, useAppDispatch } from 'util/hooks';
import { HardwareLibraryStorageManager } from 'util/hardwareLibrary/hardware-library-storage';

export const useHardwareLibraryLabelPicker = ({ connectionType }: { connectionType: 'network' | 'local' }): PrinterPickerHookData => {
  const dispatch = useAppDispatch();
  const { printers } = useHardwareLibraryLabelPrinters({ connectionType });
  const options = useMemo(() => {
    return printers.map((printers) => {
      return {
        key: printers.id,
        label: printers.name,
        value: printers.id,
      };
    });
  }, [printers]);

  const storage = new HardwareLibraryStorageManager(dispatch);
  const { value: selectedLabelPrinterId } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.LABEL_PRINTER_ID);
  const setSelectedLabelPrinterId = (id: string) => {
    storage.labelPrinterId = id;
  };

  const testPrint = async (): Promise<void> => {
    const bytes: number[] = [
      ...TEST_HEADER,
      ...Array.from(new TextEncoder().encode('Successful Test Print!')),
      ...TEST_FOOTER,
    ];
    await printers.find(it => it.id === selectedPrinterId)?.print(Uint8Array.from(bytes));
  };

  // To avoid the Select component from resetting to the first option when the list changes, we need to make sure the selected option is in the list
  const selectedPrinterId: string | undefined = useMemo(() => {
    return printers.find((option) => {
      return option.id === selectedLabelPrinterId;
    })?.id;
  }, [printers, selectedLabelPrinterId]);

  const handleLabelPrinterChange = (value: string) => {
    // update stored value for future sessions
    setSelectedLabelPrinterId(value);

    logger.info<UserSelectedPrinterContext>(`user selected ${connectionType} printer ${value}`, {
      key: customEventKeys.printing.userSelectedPrinter,
      type: connectionType,
      selectedPrinterId: value,
    });
  };

  return {
    changeSelection: handleLabelPrinterChange,
    dropdownOptions: options,
    selectedPrinterId,
    testPrint,
  };
};

export const useHardwareLibraryLabelPrinters = ({ connectionType }: { connectionType: 'network' | 'local' }): PrinterListHookData<LabelPrinter> => {
  const dispatch = useAppDispatch();

  const { labelPrinters: availablePrinters } = useLabelPrinters();
  const labelPrinters = useMemo(() => {
    return availablePrinters.filter((printer) => {
      if (connectionType === 'local') {
        return isNativeLabelPrinter(printer);
      }
      return isPrintNodeLabelPrinter(printer);
    });
  }, [availablePrinters, connectionType]);

  const [isLoadingLabelPrinters, setIsLoadingLabelPrinters] = useState(false);

  const handleSearchLabelPrinters = useCallback(
    async ({ userInitiated = false }: { userInitiated?: boolean }): Promise<void> => {
      try {
        setIsLoadingLabelPrinters(true);

        // Searching will add any missing printers
        const availablePrinters = await HardwareService.labelPrinter.search();
        logger.info<GetPrintNodePrintersContext>(getPrintNodePrintersDescription(availablePrinters, userInitiated, connectionType), {
          key: customEventKeys.printing.network.getPrinters,
          availablePrinters,
          userInitiated,
          type: 'label',
        });
      } catch (e) {
        dispatch(errorNotification(`Error loading label printers: ${e}`));
        logger.error(e, { message: 'searchLabelPrinters failed', userInitiated });
      } finally {
        setIsLoadingLabelPrinters(false);
      }
    },
    [dispatch, connectionType]
  );

  return {
    isLoading: isLoadingLabelPrinters,
    printers: labelPrinters,
    refreshList: handleSearchLabelPrinters,
  };
};
