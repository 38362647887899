import { useDispatch, useSelector } from 'react-redux';

import { logger } from 'util/logger';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useRemoveItemFromCartMutation } from 'queries/v2/cart/remove-item-from-cart';
import { warningNotification } from 'store/actions/NotificationsActions';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { RemoveItemFromCartPayload } from 'queries/v2/cart/remove-item-from-cart';
import type { State } from 'store';

export type UseRemoveItemFromCart = {
  count?: number;
  inventoryId?: number;
  productId: number;
  serialNo: string;
};

export const useRemoveItemFromCart = () => {
  const dispatch = useDispatch();

  // Global state

  const isLoyaltyAsDiscountEnabled = useSelector((state: State) => state.settings.features.LoyaltyAsDiscount);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  // Hooks

  const { guestId, shipmentId } = useTransactionManager();
  const { mutateAsync: removeItemFromCart } = useRemoveItemFromCartMutation();
  const refetchCartDetails = useRefetchCartDetails();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  return async ({ count, inventoryId, productId, serialNo }: UseRemoveItemFromCart) => {
    if (!guestId || !registerId || !shipmentId) {
      return;
    }

    const payload: RemoveItemFromCartPayload = {
      count,
      guestId,
      inventoryId,
      isLoyaltyAsDiscountEnabled,
      productId,
      registerId,
      serialNo,
      shipmentId,
    };

    try {
      await removeItemFromCart(payload);
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : 'There was an error removing the item from the cart';
      dispatch(warningNotification(errorMessage));
      logger.error(error, { message: errorMessage, payload });
      // Need to throw the error to prevent the success notification from displaying
      throw error;
    } finally {
      // Will not need to do this manually if React Query is enabled
      // The mutation invalidates the cache automatically
      if (!isAnonymousCartLDFlagEnabled) {
        // Refetch the cart with Redux
        refetchCartDetails();
      }
    }
  };
};
