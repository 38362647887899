import React, { createContext, FC, useContext } from 'react';
import { css, createGlobalStyle } from 'styled-components';

const PricesLoadingStyles = createGlobalStyle`
    @keyframes precart-price-loading {
        0% { opacity: 1 }
        50% { opacity: 0.4 }
        100% { opacity: 1 }
    }
`;

export const PriceLoadingAnimation = css`
  animation-name: precart-price-loading;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

type PricesLoadingType = {
  pricesLoading: boolean;
  setPricesLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PricesLoadingContext = createContext<PricesLoadingType>({
  pricesLoading: false,
  setPricesLoading: () => Boolean,
});

export const usePricesLoading = () => useContext(PricesLoadingContext);

type ProvidePricesLoadingType = {
  value: PricesLoadingType;
};

export const ProvidePricesLoading: FC<ProvidePricesLoadingType> = ({ value, children }) => (
  <PricesLoadingContext.Provider value={value}>
    <PricesLoadingStyles />
    {children}
  </PricesLoadingContext.Provider>
);
