import { useMemo } from 'react';
import { useServerMigrationConfig } from './useServerMigrationConfig';
import { RegionList } from 'util/RegionList';

export function useNewRegion() {
  const { registerURL } = useServerMigrationConfig();

  const newRegion: string | undefined = useMemo(() => {
    if (registerURL) {
      return Object.keys(RegionList).find((key) => RegionList[key].includes(registerURL));
    }
    return '';
  }, [registerURL]);

  return newRegion;
}
