import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { logger } from 'util/logger';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import { resetPrintJobStatus } from 'store/actions/PrintActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';

const SELECT_GUEST_TO_REGISTER = 'v2/guest/Select_Guest_To_Register';

export type AssignGuestToRegisterRequest = {
  guestId: number;
  registerId?: number;
  scheduleId?: number;
  shipmentId: number;
};

const transformToServerPayload = (payload: AssignGuestToRegisterRequest) => {
  return {
    Guest_id: payload.guestId,
    RegisterId: payload.registerId,
    ScheduleId: payload.scheduleId,
    ShipmentId: payload.shipmentId,
  };
};

export const useAssignGuestToRegisterMutation = () => {
  const dispatch = useAppDispatch();

  const registerIdFromState = useAppSelector((state) => state.settings.selectedRegister?.value);

  return useMutation(
    async (payload: AssignGuestToRegisterRequest) => {
      const registerId = payload.registerId ?? registerIdFromState;
      if (registerId === undefined) {
        throw new Error('No register selected');
      }

      const customer = await dispatch(getCustomerDetails({ guestId: payload.guestId })).unwrap();
      const scheduleId = payload.scheduleId ?? Number(customer?.ScheduleId);

      return await post<void>(
        SELECT_GUEST_TO_REGISTER,
        transformToServerPayload({ ...payload, registerId, scheduleId })
      );
    },
    {
      onSuccess: () => {
        dispatch(resetPrintJobStatus());
      },
      onError: (error) => {
        logger.error(error, { message: 'Unable to assign guest to register' });
      },
    }
  );
};
