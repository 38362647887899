import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { colors, zIndices } from 'css/Theme';
import { Button } from 'components/buttons/Button';
import { useNavigationHint } from 'components/layout/NavigationHint/useNavigationHint';

type NavigationHintProps = {
  isOpen: boolean;
  onCloseHint: () => void;
};

const HINT_TRANSITION_DURATION = 200;

export function NavigationHint(props: NavigationHintProps) {
  const { isOpen, onCloseHint } = props;

  const { ref, coordinates } = useNavigationHint({ isOpen });

  return (
    <CSSTransition in={isOpen} timeout={HINT_TRANSITION_DURATION} unmountOnExit classNames='navigation-hint-overlay'>
      <Overlay>
        <Container ref={ref} top={coordinates.top} left={coordinates.left}>
          <Headline>Change location/register</Headline>
          <Instruction>You can now change your location or register within the new navigation!</Instruction>
          <Button fullWidth onClick={onCloseHint}>
            Close
          </Button>
        </Container>
      </Overlay>
    </CSSTransition>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(16, 48, 77, 0.3);
  z-index: ${zIndices.navigationHint};

  &.navigation-hint-overlay-enter {
    opacity: 0;
  }
  &.navigation-hint-overlay-enter-active {
    opacity: 1;
    transition: all ${HINT_TRANSITION_DURATION}ms ease;
  }
  &.navigation-hint-overlay-exit {
    opacity: 1;
  }
  &.navigation-hint-overlay-exit-active {
    opacity: 0;
    transition: all ${HINT_TRANSITION_DURATION}ms ease;
  }
`;

const Container = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ top, left }) => `translate(${left}px, ${top}px)`};
  padding: 16px 20px;
  background: ${colors.dutchie.primaryWhite};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  max-width: 316px;

  &:after {
    position: absolute;
    top: 50%;
    left: -7px;
    transform: translateY(-50%);
    content: '';
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;

    border-right: 8px solid ${colors.dutchie.primaryWhite};
  }
`;

const Headline = styled.span`
  color: ${colors.dutchie.black};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;

const Instruction = styled.span`
  color: ${colors.dutchie.black};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;
`;
