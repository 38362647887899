import React from 'react';
import styled, { css } from 'styled-components';
import { Peripheral } from '@dutchie/capacitor-hardware';

import { Select } from 'components/backoffice/select';
import { MenuItem } from 'components/backoffice/menu-item';
import { colors } from 'css/Theme';
import { useSettingsSelect } from './useSettingsSelect';

export type SettingsSelectOption = {
  icon?: React.ReactNode;
  label: string;
  secondaryLabel?: string;
  value: string;
};

type ActionProps = {
  label?: string;
  onClick: () => void | Promise<void>;
};

type BaseSettingsSelectProps = {
  automationId?: string;
  ddActionName?: string;
  emptyOptionsMessage?: string;
  onChange?: (option?: { label: string; value: string }) => void;
  onRefresh: () => Promise<void>;
  placeholder: string;
  secondaryAction?: ActionProps;
  value?: string;
};

type SettingsDeviceSelectProps = BaseSettingsSelectProps & {
  devices: Peripheral[];
  options?: never; // Prevent options from being passed in
};

type SettingsOptionSelectProps = BaseSettingsSelectProps & {
  devices?: never; // Prevent devices from being passed in
  options: SettingsSelectOption[];
};

export type SettingsSelectProps = SettingsDeviceSelectProps | SettingsOptionSelectProps;

export const SettingsSelect = ({
  automationId,
  ddActionName,
  emptyOptionsMessage = 'No options available',
  placeholder,
  secondaryAction,
  value,
  ...rest
}: SettingsSelectProps) => {
  const { availableValue, handleChange, handleClickSecondaryAction, handleRefresh, isRefreshing, options } =
    useSettingsSelect({ ...rest, secondaryAction, value });

  return (
    <StyledSelect
      automationId={automationId}
      ddActionName={ddActionName ?? placeholder}
      placeholder={placeholder}
      value={availableValue}
      onChange={handleChange}
      renderValue={(selected: unknown) => {
        const preferredDevice = options.find((option) => option.value === selected);
        const hasPreferredDevice = !!`${selected}`.trim().length;
        const isPreferredDeviceAvailable = hasPreferredDevice && !!preferredDevice;
        const showPlaceholder = hasPreferredDevice && !isPreferredDeviceAvailable;
        return (
          <>
            {!hasPreferredDevice && <StyledPlaceholder>{placeholder}</StyledPlaceholder>}
            {hasPreferredDevice && showPlaceholder && (
              <StyledPlaceholder>Preferred device unavailable</StyledPlaceholder>
            )}
            {hasPreferredDevice && !showPlaceholder && (
              <StyledSelectedValue>{preferredDevice?.label ?? 'Missing label'}</StyledSelectedValue>
            )}
          </>
        );
      }}
    >
      {options.length === 0 && <NoOptionsAvailable>{emptyOptionsMessage}</NoOptionsAvailable>}
      {options.map((option) => (
        <StyledMenuItem key={option.value} selected={value === option.value} value={option.value}>
          <PrimaryLabel data-testid='settings_select-option_label'>
            {option.icon ?? null}
            {option.label}
          </PrimaryLabel>
          {option.secondaryLabel && <SecondaryLabel>{option.secondaryLabel}</SecondaryLabel>}
        </StyledMenuItem>
      ))}
      <Footer>
        {secondaryAction && (
          <StyledLink
            data-testid='settings_select-secondary_action'
            refreshing={isRefreshing}
            onClick={handleClickSecondaryAction}
          >
            {secondaryAction.label}
          </StyledLink>
        )}
        <StyledLink data-testid='settings_select-refresh_button' refreshing={isRefreshing} onClick={handleRefresh}>
          Refresh
        </StyledLink>
      </Footer>
    </StyledSelect>
  );
};

const NoOptionsAvailable = styled.div`
  color: ${colors.dutchie.grey50};
  padding: 1em;
  text-align: center;
`;

const StyledLink = styled.a<{ refreshing: boolean }>`
  color: ${colors.dutchie.blue};
  cursor: pointer;
  display: inline-block;
  font-size: 0.8125rem;
  font-weight: 600;
  text-decoration: none;

  ${(ref) =>
    ref.refreshing &&
    css`
      pointer-events: none;
      color: ${colors.dutchie.grey50};
    `}
`;

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  ${({ selected }) =>
    selected &&
    css`
      &:not(:hover) {
        background-color: ${colors.dutchie.blue10};
      }
      &.Mui-selected:not(:hover) {
        background-color: ${colors.dutchie.blue10} !important;
        font-weight: unset;
      }
    `}

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem 2rem;
`;

const BaseLabelStyles = css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

const PrimaryLabel = styled.div`
  ${BaseLabelStyles}
  color: ${colors.dutchie.black};
  font-weight: 600;

  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const SecondaryLabel = styled.span`
  ${BaseLabelStyles}
  color: ${colors.dutchie.gray80};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.dutchie.grey90};
  font-weight: bold;
  padding: 0.75rem 1.25rem 0.3125rem 1.25rem;

  & > *:only-child {
    margin-left: auto;
    justify-self: flex-end;
  }
`;

const StyledSelectedValue = styled.div`
  padding: 0.5rem 0rem;
`;

const StyledPlaceholder = styled(StyledSelectedValue)`
  color: var(--color-greyscale-grey-50);
`;

const StyledSelect = styled(Select)`
  min-width: 300px;
`;
