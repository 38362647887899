import { post } from 'api/HttpHelpers';

export const loadPreorderProducts = () => post<PreorderProductSearchResult[]>('preorder/inventory');
export const createPreorder = (body: CreatePreorderRequest) => post<CreatePreorderResponse>('preorder', body);
export const pricePreorder = (body: PricePreorderRequest) => post<PricePreorderResponse>('preorder/price', body);

export type PricePreorderResponse = {
  CartItemPrices: CartItemPrice[];
  SubTotal: number;
  Taxes: number;
  Discount: number;
  Total: number;
  OriginalTotal: number;
  RoundedDifference: number;
};

type Discount = {
  Name: string;
  Amount: number;
};

export type CartItemPrice = {
  ProductName: string;
  ProductId: number;
  Quantity: number;
  Subtotal: number;
  Discounts: Discount[];
  Tax: number;
};

export type PricePreorderRequest = {
  cartItems: CartItem[];
  customerId: number;
  isDelivery?: boolean;
  deliveryStreet?: string;
  deliveryStreet2?: string;
  deliveryCity?: string;
  deliveryState?: string;
  deliveryPostalCode?: string;
};

type CartItem = {
  ProductId: number;
  Quantity: number;
};

export type CreatePreorderResponse = {
  ShipmentId: number;
  OrderId: number;
};

export type CreatePreorderRequest = {
  orderSourceId: number;
  orderTypeId: number;
  customerId: number;
  deliveryAddressId?: number;
  createNewAddress: boolean;
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  items: PreorderItem[];
  dueDate?: string;
  responsibleForSaleUserId: number;
};

export type PreorderItem = {
  productId: number;
  unitId: number;
  unitPrice: number;
  quantity: number;
};

export type PreorderProductSearchResult = {
  ProductId: number;
  ProductNo: string;
  ProductName: string;
  ProductDescription?: string;
  ProductImageURL: string;
  UnitId: number;
  Quantity: number;
  Category?: string;
  MasterCategory?: string;
  VendorName?: string;
  StrainName?: string;
  BrandName?: string;
  RecUnitPrice: number;
  UnitPrice: number;
  UnitAbbr: string;
  FlowerEquivalent: number;
  RecFlowerEquivalent: number;
  CBDContent?: number;
  CBDContentUnitId?: number;
  THCContent?: number;
  THCContentUnitId?: number;
};
