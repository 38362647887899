import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as ElipsisIcon } from 'assets/icons/ellipsis-fill-icon.svg';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { DragDropTarget } from 'components/layout/DragDrop';
import { Checkbox } from 'components/inputs/Checkbox';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';

type EmbeddedTableChildRowV2Props = {
  id: string;
  isSelectionEnabled: boolean;
  dragDropDisabled: boolean;
  selected: boolean;
  numColumns: number;
  onDragStart: () => void;
  onDragStop: () => void;
  onDragOver: () => void;
  onToggleSelection: () => void;
};

export function EmbeddedChildTableRowV2<ParentItem, ChildItem>(props: EmbeddedTableChildRowV2Props) {
  const {
    id,
    dragDropDisabled,
    onDragStart,
    onDragStop,
    onDragOver,
    selected,
    numColumns,
    isSelectionEnabled,
    onToggleSelection,
  } = props;
  const {
    childItemMap,
    childColumns,
    childActions,
    onDragStart: storeDragStart,
    onDragStop: storeDragStop,
  } = useEmbeddedTableStore<ParentItem, ChildItem>();
  const item = childItemMap[id];
  const actions = useMemo(
    () => (typeof childActions === 'function' ? childActions(item) : childActions),
    [childActions, item]
  );

  const combinedDragStart = useCallback(() => {
    storeDragStart && storeDragStart();
    onDragStart();
  }, [onDragStart, storeDragStart]);

  const combinedDragStop = useCallback(() => {
    storeDragStop && storeDragStop();
    onDragStop();
  }, [onDragStop, storeDragStop]);

  if (!item) {
    console.warn(`No child with ID ${id}`); // eslint-disable-line
    return null;
  }

  return (
    <DragDropTarget
      className='child'
      as='tr'
      effect='move'
      payload={id}
      dragDisabled={dragDropDisabled}
      dropDisabled={dragDropDisabled}
      onDragActive={combinedDragStart}
      onDragInactive={combinedDragStop}
      onDropActive={onDragOver}
      dragPlaceholder={
        <td className='child placeholder' colSpan={numColumns}>
          &nbsp;
        </td>
      }
      style={{
        borderBottom: '1px solid #e3e7e9',
        whiteSpace: 'nowrap',
      }}
    >
      {isSelectionEnabled && (
        <TDDefaultStyle className='child'>
          <StyledCheckbox state={selected ? 'on' : 'off'} onClick={() => onToggleSelection()} />
        </TDDefaultStyle>
      )}
      {childColumns.map(({ Cell, cellProps, width }, i) => (
        <TDCellInfoStyle className='child' key={`child-${id}-column-${i}`} width={width}>
          <TDCellInfoWrapper>
            <Cell {...cellProps} item={item} />
          </TDCellInfoWrapper>
        </TDCellInfoStyle>
      ))}
      {!!actions?.length && (
        <TDActionsStyle className='child actions'>
          <OverflowMenu
            anchor={<ElipsisIcon />}
            anchorProps={{ padding: '1.5rem 1rem', showCursor: true }}
            menuOptions={actions.map((action) => ({
              text: action.label,
              onClick: () => action.onClick(item),
            }))}
          />
        </TDActionsStyle>
      )}
    </DragDropTarget>
  );
}

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: center;
  padding: 1.3rem 1rem;
  & > svg {
    width: 1.25rem;
    height: 1.25rem;
    path {
      fill: #363636;
    }
  }
`;

const TDActionsStyle = styled.td`
  display: flex;
  justify-content: center;
  position: sticky;
  right: 0;
  background: #f5f6f7;
  box-shadow: -3px 0px 3px -2px #b0b0b0;
  svg {
    height: 100%;
    color: #121516;
  }
`;

const TDDefaultStyle = styled.td`
  width: 3rem;
  height: 2.5rem;
  content: '';
`;

const TDCellInfoStyle = styled.td<{ width?: string }>`
  width: ${({ width }) => width};
  padding: 1.3rem 1rem;
  font-size: 0.8125rem;
  vertical-align: middle;
`;

const TDCellInfoWrapper = styled.div`
  display: flex;
`;
