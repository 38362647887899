import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { WarnMessageIcon } from 'assets/icons/warning-message';
import { Button } from 'components/buttons';

import type { State } from 'store';

type IntegrationStatus = {
  integrationFlagName: string;
  states?: Array<string>;
};

type PosStatus = {
  affectsApps: Array<string>;
  status: string;
  message: string;
  integration?: IntegrationStatus;
};

export const StatusBanner: FC = () => {
  const dispensaryState = useSelector((state: State) => state.settings.locationSettings?.State);
  const integrations = useSelector((state: State) => state.settings.integrations);

  const ldClient = useLDClient();
  const statuses: PosStatus[] = ldClient?.variation('alert.llx-pos-incident', []) ?? [];

  if (!statuses || statuses.length === 0) {
    return null;
  }

  const registerAlerts = statuses.filter((x) => x.affectsApps.indexOf('register') >= 0);

  if (registerAlerts.length === 0) {
    return null;
  }

  const integrationAlerts = registerAlerts.filter((alert) => {
    if (!alert.integration) {
      return true;
    }

    if (!integrations) {
      return false;
    }

    if (Object.keys(integrations).includes(alert.integration?.integrationFlagName)) {
      if (
        !alert.integration.states ||
        alert.integration.states.length === 0 ||
        alert.integration.states[0].toLowerCase() === 'all' ||
        alert.integration.states.filter((state) => state.toLowerCase() === dispensaryState?.toLowerCase()).length > 0
      ) {
        return true;
      }
    }

    return false;
  });

  if (integrationAlerts.length === 0) {
    return null;
  }

  return (
    <BannerContainer>
      <BannerContent>
        <BannerText data-testid='status-banner_banner-text_text'>
          <WarnMessageIcon height={18} width={18} />
          {integrationAlerts.length > 1
            ? 'Multiple incidents are ongoing, please check the status page for additional information'
            : integrationAlerts[0].message}
        </BannerText>
        <BannerLink secondary onClick={() => window.open('https://status.leaflogix.net/', '_blank')}>
          View Status
        </BannerLink>
      </BannerContent>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: none;
  font-size: 0.875rem;
  letter-spacing: 0.5%;
`;

const BannerText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const BannerContent = styled.div`
  padding: 0.4rem 1.5rem;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fefdec;
`;

const BannerLink = styled(Button)`
  font-size: 0.875rem;
  height: 1.75rem;
  padding: 0 0.5rem;
  background: none;
`;
