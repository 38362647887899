import React from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { rgba } from 'polished';
import { ReactComponent as PinSvg } from 'assets/icons/pin.svg';
import { ReactComponent as DispensarySvg } from 'assets/icons/dispensary.svg';
import { Delivery } from 'models/Delivery';
import { DeliveryStatus } from 'models/DeliveryRoute';

export type LLLatLng = {
  Lat: number;
  Lng: number;
};

export type LLPin = LLLatLng & {
  Deliveries: Array<Delivery>;
};

type DispensaryPinProps = {
  lat?: number;
  lng?: number;
  onClick?: () => void;
};

type StatusPinProps = {
  lat?: number;
  lng?: number;
  deliverystatusid: DeliveryStatus;
  onClick?: () => void;
};

const pinStatusColors = {
  [DeliveryStatus.Created as DeliveryStatus]: {
    pinColor: colors.white,
    pinShadowColor: rgba(26, 57, 82, 0.5),
  },
  [DeliveryStatus.Cancelled as DeliveryStatus]: {
    pinColor: '#C84040',
    pinShadowColor: rgba('#C84040', 0.25),
  },
  [DeliveryStatus.Completed as DeliveryStatus]: {
    pinColor: rgba(colors.lightGreen, 0.5),
    pinShadowColor: rgba(colors.lightGreen, 0.25),
  },
  [DeliveryStatus.Ready as DeliveryStatus]: {
    pinColor: colors.blue2,
    pinShadowColor: rgba(colors.blue2, 0.25),
  },
  [DeliveryStatus.OutForDelivery as DeliveryStatus]: {
    pinColor: colors.primary,
    pinShadowColor: rgba(colors.primary, 0.25),
  },
  [DeliveryStatus.Undeliverable as DeliveryStatus]: {
    pinColor: colors.yellow,
    pinShadowColor: rgba(colors.yellow, 0.25),
  },
  [DeliveryStatus.Delivered as DeliveryStatus]: {
    pinColor: colors.lightGreen,
    pinShadowColor: rgba(colors.lightGreen, 0.25),
  },
};

export const Pin = (props: StatusPinProps) => (
  <MapPin deliverystatusid={props.deliverystatusid} onClick={props.onClick} />
);

export const PinSelected = (props: StatusPinProps) => (
  <MapPinShadow deliverystatusid={props.deliverystatusid}>
    <MapPinInShadow onClick={props.onClick} deliverystatusid={props.deliverystatusid} />
  </MapPinShadow>
);

export const PinSplit = (props: StatusPinProps) => (
  <MapPinShadow deliverystatusid={props.deliverystatusid}>
    <MapPinInShadow deliverystatusid={props.deliverystatusid} onClick={props.onClick} />
  </MapPinShadow>
);

export const Dispensary = (props: DispensaryPinProps) => (
  <DispensaryPinShadow>
    <DispensaryPin onClick={props.onClick} />
  </DispensaryPinShadow>
);

const PinStyle = `
  position: absolute;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    z-index: 1;
  }
`;

const PinInShadowStyle = `
  ${PinStyle};
  left: 50%;
  top: 50%;
`;

const PinShadowStyles = `
  position: absolute;
  border-radius: 50%;
  transform: translate(-50%,-50%);
`;

const MapPin = styled(PinSvg)<{ deliverystatusid: DeliveryStatus }>`
  ${PinStyle};
  color: ${({ deliverystatusid }) => (deliverystatusid ? pinStatusColors[deliverystatusid].pinColor : colors.black)};
`;

const MapPinInShadow = styled(PinSvg)<{ deliverystatusid: DeliveryStatus }>`
  ${PinInShadowStyle};
  color: ${({ deliverystatusid }) => (deliverystatusid ? pinStatusColors[deliverystatusid].pinColor : colors.black)};
`;

const MapPinShadow = styled.span<{ deliverystatusid: DeliveryStatus }>`
  ${PinShadowStyles};
  width: 45px;
  height: 45px;
  background: ${({ deliverystatusid }) =>
    deliverystatusid ? pinStatusColors[deliverystatusid].pinShadowColor : rgba(colors.black, 0.25)};
`;

const DispensaryPin = styled(DispensarySvg)`
  ${PinInShadowStyle};
  color: ${colors.white};
`;

const DispensaryPinShadow = styled.span`
  ${PinShadowStyles};
  background: ${colors.primary};
  border: 3px solid ${colors.white};
  width: 35px;
  height: 35px;
`;
