import React from 'react';
import {
  ConnectionStatusBanner,
  PeripheralSetupConnectionStatus,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { isPrintNodeLabelPrinter } from 'hardware/label-printer';
import { useLabelPrinterSettings } from '../hooks/useLabelPrinterSettings';

export const LabelPrinterStatusBanner = () => {
  const { formValues, printers } = useLabelPrinterSettings();

  const selectedPrinter = printers.find((printer) => printer.id === formValues.labelPrinterId);

  if (!selectedPrinter) {
    return null;
  }

  if (isPrintNodeLabelPrinter(selectedPrinter)) {
    return (
      <ConnectionStatusBanner
        deviceTypeName='Printer'
        status={PeripheralSetupConnectionStatus.unavailable}
        customText='Backoffice printer. Connection status unavailable at the register.'
      />
    );
  }

  return (
    <ConnectionStatusBanner
      deviceTypeName='Printer'
      deviceName={selectedPrinter.name}
      status={PeripheralSetupConnectionStatus.connected}
    />
  );
};
