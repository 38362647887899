import { useAppSelector, useLocalStorage, isAndroid, isWebViewApp } from 'util/hooks';

export const useCfd = () => {
  const showCartDisplay = useAppSelector((state) => state.settings.features.UseCartDisplay);
  const { value: openOnLogin } = useLocalStorage('hardware.cfd.openOnLogin');
  const isOpenOnLogin = openOnLogin === 'true';
  const isConnected = useAppSelector((state) => state.settings.isCfdConnected);

  const openCartDisplay = async (screenId?: string): Promise<void> => {
    if (isAndroid || isWebViewApp) {
      return;
    }

    // Event handled in useMonitoredCfdWindow.ts which has a single usage in AppComponent.
    // This avoids setting up a provider to have a single instance of the monitoring logic.
    window.dispatchEvent(new CustomEvent('openCartDisplay', { detail: { screenId } }));
  };

  return {
    isConnected,
    isOpenOnLogin,
    openCartDisplay,
    showCartDisplay,
  };
};
