import { logger } from 'util/logger';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { post } from 'api/HttpHelpers';
import { CartItem } from 'models/Cart';
import { errorNotification } from 'store/actions/NotificationsActions';
import { SettingsState } from 'store/reducers/SettingsReducer';
import { printToNetworkPrinter } from './printToNetworkPrinter';
import { PrintLabelsActionArgs } from './types';

type PrintLabelsWithLabelIdFromSettingsArgs = {
  args: PrintLabelsActionArgs;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  itemsToPrint: CartItem[];
  registerId: number;
  settings: SettingsState;
};

export async function printLabelsWithDefaultLabelId({
  args,
  dispatch,
  itemsToPrint,
  registerId,
  settings,
}: PrintLabelsWithLabelIdFromSettingsArgs): Promise<void> {
  const requests = itemsToPrint.map(async (item) => {
    const params = {
      SerialNumber: item.SerialNo,
      ShipmentId: args.guest.ShipmentId,
      Count: args.printAll ? item.QtySelected : 1,
      BatchId: args.guest.ShipmentId,
      LabelId: item.DefaultLabelId ?? settings?.userSettings?.selectedLabel?.id ?? 1,
      PackagesToPrint: [
        {
          ShipmentId: args.transactionId ?? args.guest.ShipmentId,
          AllocatedInventoryId: item.InventoryId,
          Count: args.printAll ? item.QtySelected : 1,
          SerialNumber: item.SerialNo,
          PackageItemId: item.PackageItemId,
          PackageQuantity: item.WgtCnt === 'Wgt' ? (item.Grams ?? 0) / item.QtyAllocated : 1,
          BatchId: item.BatchId,
        },
      ],
      LabelType: 'ProductProd',
      PrinterId: settings.userSettings.selectedLabelPrinter?.PrinterId,
    };

    const getLabelParams = {
      LabelId: params.LabelId,
      TerminalId: registerId,
    };

    await post<number>('posv3/print/get-label-printer-for-terminal', getLabelParams)
      .then((res) => {
        if (res !== null) {
          params.PrinterId = res;
        }
      })
      .catch((e: string) => {
        dispatch(errorNotification(`Error getting printer: ${e}`));
        logger.error(e);
      });

    await printToNetworkPrinter({ dispatch, params });
  });

  const results = await Promise.allSettled(requests);
  const failed = results.some((result) => result.status === 'rejected');
  if (failed) {
    return Promise.reject('One or more labels failed to print');
  }
}
