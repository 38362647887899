import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { getPopupSize } from './helpers';
import { colors, zIndices } from 'css/Theme';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';
import { NewModalBridge } from './NewModalBridge';

type PopupProps = {
  caption: ReactNode;
  subCaption?: string;
  isVisible: boolean;
  canClose?: boolean;
  hide: () => void;
  className?: string;
  contentMaxHeight?: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  width?: string;
  portalContainer?: Element;
  automationId?: string;
};

export const Popup: FC<PopupProps> = ({
  caption,
  subCaption,
  isVisible,
  width,
  hide,
  className,
  children,
  canClose = true,
  contentMaxHeight,
  small = false,
  large = false,
  medium = false,
  portalContainer,
  automationId,
}) => {
  const { isModalBridgeEnabled } = useModalBridge();

  if (isModalBridgeEnabled) {
    return (
      <NewModalBridge
        {...{
          caption,
          subCaption,
          isVisible,
          width,
          hide,
          className,
          children,
          canClose,
          contentMaxHeight,
          small,
          large,
          medium,
          portalContainer,
          automationId,
        }}
      />
    );
  }

  return isVisible
    ? createPortal(
        <PopupOverlay>
          <PopupContainer
            className={className}
            data-testid={automationId ?? 'popup_popup-body-div_section'}
            width={getPopupSize({ small, medium, large, width })}
          >
            <PopupHeader data-testid='popup_popup-header-div_section'>
              <PopupTitle>{caption}</PopupTitle>
              {subCaption && <PopupSubtitle>{subCaption}</PopupSubtitle>}
              {canClose && <CloseIcon onClick={hide} data-testid='popup_popup-body-div_close-icon' />}
            </PopupHeader>
            <PopupContent contentMaxHeight={contentMaxHeight} data-testid='popup_popup-content-div_section'>
              {children}
            </PopupContent>
          </PopupContainer>
        </PopupOverlay>,
        portalContainer ?? document.body
      )
    : null;
};

const PopupOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: ${zIndices.popup};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PopupContainer = styled.div<{ width: string }>`
  background-color: ${colors.dutchie.primaryWhite};
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  position: relative;
  width: ${({ width }) => width};
`;

const PopupHeader = styled.div`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background: ${colors.dutchie.primaryWhite};
  padding: 0.875rem 0;
  box-shadow: 0 1px 0 ${colors.dutchie.shadowGrey};
  position: relative;
`;

const PopupContent = styled.div<{ contentMaxHeight?: string }>`
  padding-top: 1.5rem;
  margin: 0;
  max-height: ${({ contentMaxHeight }) => contentMaxHeight ?? 'calc(100vh - 200px)'};
  overflow-y: auto;
`;

const PopupTitle = styled.h3<{ margin?: string }>`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: ${({ margin }) => margin || '0 auto'};
`;

const PopupSubtitle = styled.h4`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.05rem;
  text-align: center;
  margin: -1rem auto 1rem;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  color: ${colors.dutchie.grey};
`;
