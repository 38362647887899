import React from 'react';
import styled from 'styled-components';

import { StyleVariant, Table } from 'components/tables';
import { Flex, SearchBar, SearchResults } from 'components/layout';
import { useTransactionHistory } from './useTransactionHistory';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

import type { Transaction } from 'queries/v2/transaction-history/types';
import { ConfirmationPopup } from 'components/popups';

export const TransactionHistory = () => {
  const {
    areTransactionsLoading,
    driverImage,
    handleChangeSearch,
    handleClosePhoto,
    handleSearch,
    maxTableHeight,
    searchValue,
    tableColumns,
    tableContainerRef,
    transactions,
  } = useTransactionHistory();

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <Container>
      <SearchBar
        placeholder='Search'
        initialValue={searchValue}
        onChange={handleChangeSearch}
        onSearch={handleSearch}
        maxWidth='100%'
      />
      <SearchResults
        loading={areTransactionsLoading}
        loadingText='Loading transactions'
        isEmpty={transactions?.length === 0}
        emptyMessage='There are no transactions or they could not be retrieved'
      >
        <>
          <TableContainer ref={tableContainerRef} maxHeight={maxTableHeight}>
            <Table<Transaction>
              data={transactions ?? []}
              sortColumn={2}
              sortDirection='desc'
              pageSize={10}
              columns={tableColumns}
              styleVariant={StyleVariant.roundedWithWhiteBackground}
            />
          </TableContainer>
          <ConfirmationPopup title='View Photo' isVisible={!!driverImage} hide={handleClosePhoto} buttonsSectionVisible>
            <PhotoWrapper justifyContent='center' alignItems='center' width={isModalBridgeEnabled ? '100%' : '648px'}>
              <Photo alt='Drivers License' src={`data:image/jpeg;base64,${driverImage}`} />
            </PhotoWrapper>
          </ConfirmationPopup>
        </>
      </SearchResults>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
`;

const TableContainer = styled.div<{ maxHeight?: number }>`
  overflow: auto;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'calc(100% - 7rem)')};

  table {
    border: none;
    max-height: 100%;
  }
`;

const Photo = styled.img`
  max-height: 250px;
  object-fit: contain;
`;

const PhotoWrapper = styled(Flex)`
  height: 300px;
`;
