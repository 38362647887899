import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { customEventKeys, logger } from 'util/logger';
import { errorNotification } from 'store/actions/NotificationsActions';
import { getTransactionHistoryKeys } from './query-key-factory';
import { transformToTransaction } from './types';
import { useAppDispatch } from 'util/hooks';

import type { Transaction, TransactionServerResponse } from './types';

const TRANSACTION_HISTORY_DEPRECATED = 'v2/product/transaction_history';
const TRANSACTION_HISTORY = 'v2/transaction_history/transaction_history';

export type TransactionHistoryPayload = {
  guestId?: number;
  includeCanceledTransactions?: boolean;
};

const transformToServerPayload = (payload: TransactionHistoryPayload) => {
  return {
    guest_id: payload.guestId,
    includeCanceledTransactions: payload.includeCanceledTransactions,
  };
};

type GetTransactionHistoryOptions<TData = Transaction[]> = Partial<
  Omit<UseQueryOptions<Transaction[], unknown, TData>, 'select'>
> & {
  select?: (transactions: Transaction[]) => TData;
};

export const useGetTransactionHistoryQuery = <TData = Transaction[]>(
  payload: TransactionHistoryPayload,
  options?: GetTransactionHistoryOptions<TData>
) => {
  const dispatch = useAppDispatch();
  const ldClient = useLDClient();
  const isNewTransactionHistoryEndpointEnabled =
    ldClient?.variation('growth.partners-and-delivery.use-new-transaction-history-controller', false) === true;

  const apiEndpoint = isNewTransactionHistoryEndpointEnabled ? TRANSACTION_HISTORY : TRANSACTION_HISTORY_DEPRECATED;

  return useQuery({
    queryKey: getTransactionHistoryKeys.one(payload),
    queryFn: async () => {
      try {
        const response = await post<TransactionServerResponse[]>(apiEndpoint, transformToServerPayload(payload));

        logger.debug(
          `[ReactQuery] transaction history was loaded for ${payload.guestId ? payload.guestId : 'all guests'}`,
          {
            key: customEventKeys.reactQuery,
            payload,
          }
        );

        return response.map(transformToTransaction);
      } catch (e) {
        dispatch(errorNotification('Could not load transactions list'));
        return Promise.reject(e);
      }
    },
    ...options,
  });
};
