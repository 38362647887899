import { clone, reduce } from 'lodash';

import { getProductId } from 'util/helpers/products/getProductId';
import { useAppSelector } from 'util/hooks/useAppDispatch';
import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';

import type { ProductSearchResult } from 'queries/v2/product/types';

/** @deprecated Use useProducts instead. Will be removed with pos.register.anon-cart-workflow clean up */
export const useProductsById = () => {
  const customerId = useAppSelector((state) => state.customer.details?.Guest_id);
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const {
    data: products,
    isLoading: areProductsLoading,
    isError: wasErrorLoadingProducts,
  } = useGetAllProductsQuery({ customerId, registerId });

  const productsById = reduce(
    products,
    (acc: Record<string, ProductSearchResult>, product: ProductSearchResult) => {
      const productId = getProductId(product);
      if (acc[productId] && product.productType === 'Wgt') {
        const productItem = clone(acc[productId]);
        if (productItem.unitGrams && product.unitGrams) {
          productItem.unitGrams += product.unitGrams;
        }
        acc[productId] = productItem;
      } else {
        acc[productId] = product;
      }
      return acc;
    },
    {}
  );

  return {
    productsById,
    areProductsLoading,
    wasErrorLoadingProducts,
  };
};
