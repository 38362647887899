/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as ListViewIcon } from 'assets/icons/icon-list-view.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/icon-table-location.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { NavButton } from 'components/buttons';
import { SearchBar } from 'components/layout';
import { debounce } from 'lodash';
import { Delivery } from 'models/Delivery';
import { DeliveryRoute } from 'models/DeliveryRoute';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { applyDeliveryFilters, getDeliveries, moveRoute, setDeliveryFilterQuery } from 'store/actions/DeliveryActions';
import { showConfigureDeliveriesPopup, showConfirmTransactionPopup } from 'store/actions/PopupsActions';
import styled from 'styled-components';
import MapTableToggle from './MapTableToggle';

export type ActionBarV2Props = {
  selectedFilterCount: number;
  setDrawerIsOpen: (isOpen: boolean) => unknown;
};

export const ActionBarV2: FC<ActionBarV2Props> = ({ selectedFilterCount, setDrawerIsOpen }) => {
  const filterQuery = useSelector((state: State) => state.deliveryList.filterQuery);
  const timeWindowForFilter = useSelector((state: State) => state.deliveryList.timeWindowForFilter);
  const selectedDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);
  const mapsOpen = useSelector((state: State) => state.deliveryList.mapsOpen);
  const userSettings = useSelector((state: State) => state.settings.userSettings);
  const permissions = useSelector((state: State) => state.settings.permissions);
  const UsePlaceholderDirections = useSelector((state: State) => state.settings.features.UsePlaceholderDirections);
  const expandedRoute = useSelector((state: State) => state.deliveryList.expandedRoute);
  const dispatch = useDispatch();

  const onSearch = useCallback(
    debounce((search: string) => {
      dispatch(setDeliveryFilterQuery(search));
    }, 300),
    [dispatch]
  );

  const onResetFilters = useCallback(() => {
    dispatch(applyDeliveryFilters({ filters: [] }));
  }, [dispatch]);

  const addSelectionToRoute = useCallback(
    (deliveries: Delivery[], toRoute: DeliveryRoute | null) => {
      if (permissions.EditRoutes && !!toRoute) {
        // endpoint on the delivery - delivery controller "delivery/add-shipments-to-route" { deliveryRouteId: number|null, shipmentIds: number[] }
        dispatch(
          moveRoute({
            ShipmentIds: deliveries.map(({ ShipmentId }) => ShipmentId),
            DeliveryRouteId: toRoute.DeliveryRouteId,
            UsePlaceholderDirections,
          })
        ).finally(() => dispatch(getDeliveries()));
      }
    },
    [UsePlaceholderDirections, dispatch, permissions.EditRoutes]
  );

  const addToRouteEnabled = React.useMemo(
    () =>
      !!expandedRoute &&
      selectedDeliveries.filter((d) => d.DeliveryRouteId !== expandedRoute?.DeliveryRouteId).length > 0,
    [expandedRoute, selectedDeliveries]
  );

  const hasSelectedDeliveriesInRoute = () => {
    //allow users to create empty routes
    if (selectedDeliveries.length > 0) {
      return selectedDeliveries.some((delivery: Delivery) => {
        return delivery.DeliveryRouteId === null;
      });
    } else {
      return true;
    }
  };

  const showClearFiltersButton = !!selectedFilterCount || !!timeWindowForFilter.length;
  const filterCount = timeWindowForFilter.length ? selectedFilterCount + 1 : selectedFilterCount;

  return (
    <Toolbar>
      <ToolbarAction>
        {userSettings.showSearch && (
          <SearchBar
            onChange={onSearch}
            onSearch={onSearch}
            initialValue={filterQuery}
            placeholder='Customer search...'
            inputAutomationId='delivery-list-page_search-bar-large_delivery-list-input'
          />
        )}

        <NavButton
          onClick={() => dispatch(showConfigureDeliveriesPopup({ createRoute: true }))}
          disabled={!permissions.EditRoutes || !hasSelectedDeliveriesInRoute()}
          automationId='action-bar_button_create-route'
        >
          Create Route
        </NavButton>

        <NavButton
          onClick={() =>
            dispatch(
              showConfirmTransactionPopup({
                message: `Add these ${selectedDeliveries.length} deliveries to route?`,
                onConfirm: () => {
                  addSelectionToRoute(selectedDeliveries, expandedRoute);
                },
                pinRequired: false,
                title: 'Add to Route?',
              })
            )
          }
          disabled={!(addToRouteEnabled && permissions.EditRoutes)}
          automationId='action-bar_button_add-to-route'
        >
          Add To Route
        </NavButton>
      </ToolbarAction>
      <ToolbarAction>
        <MapTableToggle icons={[<ListViewIcon />, <LocationIcon />]} mapsOpen={mapsOpen} />
        <VerticalDivider />
        <NavButton
          title='Refresh'
          onClick={() => {
            dispatch(getDeliveries());
          }}
          icon={<RefreshIcon />}
          secondary
          automationId='action-bar_button_refresh'
        />
        <NavButton
          title='Filters'
          onClick={() => {
            setDrawerIsOpen(true);
          }}
          icon={<FilterIcon />}
          secondary
          automationId='action-bar_button_filters'
        />

        {showClearFiltersButton && (
          <NavButton onClick={onResetFilters} secondary automationId='action-bar_button_clear-filters'>
            Clear Filters ({filterCount})
          </NavButton>
        )}
      </ToolbarAction>
    </Toolbar>
  );
};

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #e3e7e9;
`;

const ToolbarAction = styled.div`
  display: flex;
  align-items: center;

  div {
    height: 100%;
  }

  div input {
    height: 100%;
    font-size: 0.8125rem;
  }
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 2.75rem;
  background-color: #ccc;
  margin: 0 0.5rem;
`;
