import React, { FC, useMemo, useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { v4 as uuidv4 } from 'uuid';
import styled, { keyframes } from 'styled-components';
import { colors } from 'css/Theme';
import { digitalPayment, checkPinForDigitalPayment, setManagerPin } from 'store/actions/CheckoutActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { PaymentType } from 'models/Checkout';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import { Flex, Box } from 'components/layout';
import { CheckoutContext } from '../CheckoutContext';
import { cancelPaymentsHubTransaction } from 'api/CheckoutApi';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { Container } from '@mui/material';
import { ReactComponent as PaymentsHubIcon } from 'assets/icons/payments_hub-transaction.svg';
import { FeatureSwitchKey, useFeatureSwitches } from 'pages/CartPage/hooks/useFeatureSwitches';
import { PaymentsHubDutchiePayWarning, PaymentsHubRegalaWarning, PaymentsHubWarning } from './PaymentsHubWarning';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useAppSelector } from 'util/hooks';
import { usePaymentsHubSplitPayments } from 'util/hooks/launch-darkly/usePaymentsHubSplitPayments';
import { usePaymentsHubAlertMessage } from 'util/hooks/launch-darkly/usePaymentsHubAlertMessage';
import { usePaymentsHubCreatePaymentMethod } from '../usePaymentsHubCreatePaymentMethod';
import { usePaymentsHubAlert } from 'util/hooks/launch-darkly/usePaymentsHubAlert';
import { PaymentsHubAlert } from './PaymentsHubAlert';

export const useCancelHubTransaction = () => {
  const dispatch = useDispatch();

  const { shipmentId } = useTransactionManager();

  const handlePaymentsHubCancel = async () => {
    if (shipmentId) {
      try {
        await cancelPaymentsHubTransaction({ ShipmentId: shipmentId });
      } catch (e) {
        dispatch(errorNotification(e));
      }
    }
  };

  return handlePaymentsHubCancel;
};

export const PaymentHub: FC = () => {
  const { getFeatureSwitch } = useFeatureSwitches();
  const isDutchiePayEnabled = useAppSelector((state) => state.settings.integrations?.UseDutchiePay);
  const isDebitProcessorUSAG = useAppSelector((state) => state.settings.integrations?.DebitProcessor === 'usag');
  const dispatch = useDispatch();
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const managerPin = useSelector((state: State) => state.checkout.managerPin);
  const invalidManagerPin = useSelector((state: State) => state.checkout.invalidManagerPin);
  const checkOutPinRequired = useSelector((state: State) => state.settings.features.CheckOutPinRequired);
  const useIntegratedDebit = useSelector((state: State) => state.settings.integrations?.UseIntegratedDebit);
  const usePaymentsHub = useSelector((state: State) => state.settings.integrations?.UsePaymentsHub);
  const totalDue = useSelector((state: State) => state.checkout.totalDue);
  const totalRemaining = useSelector((state: State) => state.checkout.totalRemaning);
  const { paymentSummary, setPaymentProcessing, setSelectedPaymentType } = useContext(CheckoutContext);
  const debit = paymentSummary[PaymentType.Hub];
  const [transactionAmount, setTransactionAmount] = useState<number>();
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const handlePaymentsHubCancel = useCancelHubTransaction();
  const [paymentMethodId] = useState(uuidv4());
  const paymentsHubAlertMessage = usePaymentsHubAlertMessage();
  const paymentsHubAlert = usePaymentsHubAlert();
  const createPaymentMethod = usePaymentsHubCreatePaymentMethod();

  const { data: cart } = useGetCartDetails();

  const [hasPaymentBeenAdded, setHasPaymentBeenAdded] = useState(false);
  const { addPaymentBtnVisible } = useContext(CheckoutContext);
  const hasPreAuth = cart.PreauthInfo.PreauthAmount > 0;
  const isRegalaFeatureEnabled = getFeatureSwitch(FeatureSwitchKey.REGALA).Enabled;
  const isDutchiePayFeatureEnabled = getFeatureSwitch(FeatureSwitchKey.DUTCHIEPAY).Enabled;
  const displayHubSubtitle = isDutchiePayEnabled && isDebitProcessorUSAG && digitalPaymentLoading;
  const hubSplitPaymentsRollout = usePaymentsHubSplitPayments();

  const paymentStatusMessage = useMemo(() => {
    if (!digitalPaymentLoading) {
      return addPaymentBtnVisible ? 'Unable to complete payment.' : 'Payment Successful!';
    }
    return 'Waiting for customer to finalize payment on payment terminal.';
  }, [digitalPaymentLoading, addPaymentBtnVisible]);

  useEffect(() => {
    setCancelDisabled(true);
    let timeout: NodeJS.Timeout | null = null;
    if (digitalPaymentLoading) {
      timeout = setTimeout(() => {
        setCancelDisabled(false);
      }, 3_000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [digitalPaymentLoading]);

  useEffect(() => {
    setPaymentProcessing(digitalPaymentLoading);
    if (transactionAmount && !digitalPaymentLoading && debit >= transactionAmount) {
      setSelectedPaymentType(null);
    }
  }, [digitalPaymentLoading, debit, transactionAmount, setPaymentProcessing, setSelectedPaymentType]);

  const onAddPayment = useMemo(
    () => (amount: number) => {
      if (digitalPaymentLoading) {
        dispatch(errorNotification('The transaction is currently being processed. Please be patient as it completes.'));
        return;
      }

      if (amount <= 0) {
        dispatch(errorNotification('Invalid amount.'));
        return;
      }

      setTransactionAmount(amount);

      const paymentMethod = createPaymentMethod(amount, paymentMethodId);

      if (checkOutPinRequired) {
        dispatch(checkPinForDigitalPayment({ code: managerPin, paymentMethod }));
      } else {
        dispatch(digitalPayment(paymentMethod));
      }
    },
    [digitalPaymentLoading, dispatch, checkOutPinRequired, managerPin, paymentMethodId, createPaymentMethod]
  );

  useEffect(() => {
    if (hubSplitPaymentsRollout) {
      if (!hasPaymentBeenAdded && totalRemaining > 0) {
        onAddPayment(totalRemaining);
        setHasPaymentBeenAdded(true);
      }
    } else {
      if (!hasPaymentBeenAdded && totalDue > 0) {
        onAddPayment(totalDue);
        setHasPaymentBeenAdded(true);
      }
    }
  }, [totalDue, onAddPayment, hasPaymentBeenAdded, totalRemaining, hubSplitPaymentsRollout]);

  const onChangeManagerPin = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setManagerPin(e.target.value));
  };

  const handlePaymentsHubCancelClick = () => {
    handlePaymentsHubCancel();
    setSelectedPaymentType(null);
  };

  const handlePaymentsHubRetryClick = () => {
    if (hubSplitPaymentsRollout) {
      onAddPayment(totalRemaining);
      setHasPaymentBeenAdded(true);
    } else {
      onAddPayment(totalDue);
      setHasPaymentBeenAdded(true);
    }
  };

  return (
    <Container>
      {!!checkOutPinRequired && !!useIntegratedDebit && (
        <Box marginBottom={6}>
          <Input
            hasError={invalidManagerPin}
            placeholder='Employee PIN'
            type='password'
            value={managerPin}
            onChange={onChangeManagerPin}
            autoComplete='new-password'
          />
          {invalidManagerPin && <ErrorMessage>Wrong Pin</ErrorMessage>}
        </Box>
      )}
      <Flex direction='row' alignItems='center'>
        {usePaymentsHub && (
          <HubContainer>
            {paymentsHubAlert && (
              <PaymentsHubAlert message={paymentsHubAlert.message} messageStyle={paymentsHubAlert.style} />
            )}
            {paymentsHubAlertMessage && <PaymentsHubWarning message={paymentsHubAlertMessage} />}
            {!isRegalaFeatureEnabled && <PaymentsHubRegalaWarning />}
            {!isDutchiePayFeatureEnabled && <PaymentsHubDutchiePayWarning />}
            <PaymentsHubIcon />
            <HubContainerHeader>{paymentStatusMessage}</HubContainerHeader>
            {displayHubSubtitle && (
              <HubContainerSubtitle>{'Customer must scan Dutchie Pay QR code, or select Regala'}</HubContainerSubtitle>
            )}
            {hasPreAuth && (
              <SecondaryTextContainer>{'Click "Complete order" below to finalize this payment'}</SecondaryTextContainer>
            )}
            {digitalPaymentLoading && (
              <BarLoader>
                <Bar />
              </BarLoader>
            )}
            {totalRemaining !== 0 && (
              <Flex direction='row' alignItems='center' justifyContent='space-between' width='100%'>
                <Button
                  tertiary
                  onClick={handlePaymentsHubCancelClick}
                  disabled={!digitalPaymentLoading || cancelDisabled}
                  automationId='payment-hub-options_button_cancel'
                  width='100px'
                >
                  Cancel
                </Button>
                <Button
                  tertiary
                  onClick={handlePaymentsHubRetryClick}
                  disabled={digitalPaymentLoading}
                  automationId='payment-hub-options_button_retry'
                  width='100px'
                >
                  Retry
                </Button>
              </Flex>
            )}
          </HubContainer>
        )}
      </Flex>
    </Container>
  );
};

const ErrorMessage = styled.span`
  color: ${colors.dutchie.red};
  font-size: 14px;
  margin-top: 4px;
`;

const HubContainer = styled.div`
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
`;

const HubContainerHeader = styled.h2`
  color: ${colors.dutchie.almostBlack};
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const HubContainerSubtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-greyscale-grey-40);
`;

const SecondaryTextContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  font-feature-settings: 'calt' off;
  color: ${colors.dutchie.darkGrey};
`;

const BarLoader = styled.div`
  width: 100%;
  background-color: ${colors.dutchie.lightGrey};
  border-radius: 5px;
  overflow: hidden;
`;

const load = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

const Bar = styled.div`
  width: 0;
  height: 4px;
  background-color: ${colors.dutchie.green};
  animation: ${load} 2s ease-out infinite;
`;
