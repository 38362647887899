import { useCallback, useEffect } from 'react';

import { convertToOldCartDetails, emptyCartDetails } from 'queries/v2/cart/types';
import { customEventKeys, logger } from 'util/logger';
import { getCartDetailsKeys } from 'queries/v2/cart/query-key-factory';
import { getQueryClient } from 'util/reactQuery';
import { loadCart } from 'store/actions/CartActions';
import { useAnonymousCart, getIsAnonymousCartLDFlagEnabled } from './useAnonymousCart';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useGetCartDetailsQuery } from 'queries/v2/cart/load';
import { useTransactionManager, getLoadedTransactionInfo } from './useTransactionManager';

import type { Cart } from 'models/Cart';
import type { CartDetails } from 'queries/v2/cart/types';
import type { GetCartDetailsPayload } from 'queries/v2/cart/load';
import type { State } from 'store';

type MockReactQuery<T extends object> = {
  data: T;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  refetch: () => Promise<void>;
};

type MockReactQueryOptions<TData extends object> = {
  enabled?: boolean;
  refetchInterval?: number | false | ((data: TData | undefined) => number | false);
};

/**
 * @deprecated This method "React Queryifies" the old Redux cart store until we're ready to fully switch to React Query.
 */
const useGetCartDetailsRedux = (
  payload: GetCartDetailsPayload,
  options?: MockReactQueryOptions<Cart>
): MockReactQuery<Cart> => {
  const dispatch = useAppDispatch();
  const enabled = options?.enabled ?? true;

  const cart = useAppSelector((state) => state.cart.details);
  const isLoading = useAppSelector((state) => state.cart.loading);
  const isLoadCartError = useAppSelector((state) => state.cart.isLoadCartError);
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  // Refetch does not take the staleTime into account and will force a call to the API
  const refetch = useCallback(async () => {
    if (!enabled) {
      return;
    }

    await dispatch(
      loadCart({
        AcctId: payload?.guestId,
        FeePaymentMethodIds: payload.feePaymentMethodIds,
        Register: payload?.registerId ?? registerId,
        ShipmentId: payload?.shipmentId,
        Timestamp: +new Date(),
      })
    );
  }, [dispatch, payload, enabled, registerId]);

  useEffect(() => {
    if (options?.refetchInterval) {
      const interval =
        typeof options.refetchInterval === 'function' ? options.refetchInterval(cart) : options.refetchInterval;

      if (!interval) {
        return; // Don't start the interval if it's falsey
      }

      const id = setInterval(() => {
        refetch();
      }, interval);

      return () => {
        clearInterval(id);
      };
    }
  }, [cart, options, refetch]);

  return {
    data: cart,
    isFetching: isLoading,
    isLoading,
    isError: isLoadCartError,
    refetch,
  };
};

/** Use this hook anywhere you need to access the cart data, loading state,
 * and optionally refetch the cart data */
export const useGetCartDetails = (payload?: Partial<GetCartDetailsPayload>, options?: MockReactQueryOptions<Cart>) => {
  const enabled = options?.enabled ?? true; // Default to true if not specified
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const { guestId, shipmentId } = useTransactionManager();

  const request: GetCartDetailsPayload = {
    ...payload,
    guestId: payload?.guestId ?? guestId ?? 0,
    shipmentId: payload?.shipmentId ?? shipmentId ?? 0,
  };

  // REACT QUERY
  const { data, ...rest } = useGetCartDetailsQuery(request, {
    enabled: enabled && isAnonymousCartLDFlagEnabled, // Only use React Query if the LD flag is enabled
    refetchInterval: (data) => {
      if (options?.refetchInterval) {
        return typeof options.refetchInterval === 'function'
          ? options.refetchInterval(data ? convertToOldCartDetails(data) : undefined)
          : options.refetchInterval;
      }
      return false;
    },
  });

  // MOCK REDUX QUERY

  // Check if shipmentId or guestId is 0. React Query already does this check in the hook
  const isPayloadInvalid = !request.shipmentId || !request.guestId;

  if (!isAnonymousCartLDFlagEnabled && isPayloadInvalid) {
    // Add this logging for Mock Redux Query. Already tracked in React Query hook
    logger.warn('get cart request missing shipmentId', {
      key: customEventKeys.cart.missingShipmentId,
      comment: 'Returned an empty cart object instead of calling the API',
      payload,
    });
  }

  const reduxQuery = useGetCartDetailsRedux(request, {
    enabled: enabled && !isAnonymousCartLDFlagEnabled && !isPayloadInvalid,
    refetchInterval: options?.refetchInterval,
  });

  // Return the React Query data if the LD flag is enabled, otherwise return the Redux Query data
  return isAnonymousCartLDFlagEnabled
    ? { data: convertToOldCartDetails(data ?? emptyCartDetails), ...rest }
    : reduxQuery;
};

/**
 * Helper to retrieve the currently loaded cart details
 * It does not refetch the cart details, only returns the current data
 */
export const getCartDetails = (reduxStoreData: Pick<State, 'customer' | 'cart'>) => {
  const queryClient = getQueryClient();

  const { isAnonymousCartLDFlagEnabled } = getIsAnonymousCartLDFlagEnabled();
  const { guestId, shipmentId } = getLoadedTransactionInfo(reduxStoreData.customer.details);

  const payload = { guestId: guestId ?? 0, shipmentId: shipmentId ?? 0 };

  const currentCartFromReactQuery =
    queryClient.getQueryData<CartDetails>(getCartDetailsKeys.one(payload)) ?? emptyCartDetails;

  const cartDetails = isAnonymousCartLDFlagEnabled
    ? convertToOldCartDetails(currentCartFromReactQuery)
    : reduxStoreData.cart.details;

  return { data: cartDetails, ...payload };
};
