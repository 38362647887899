import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { checkedInGuestsSelector } from 'store/reducers/GuestListReducer';

import { colors } from 'css/Theme';
import { ListViewOrderColumn } from './ListViewOrderColumn';
import { CheckedInGuest } from 'models/Guest';
import ListViewSkeleton from './ListViewSkeleton';

export const ListView: FC = () => {
  const checkedInGuests = useSelector(checkedInGuestsSelector);
  const statuses = useSelector((state: State) => state.guestList.statuses);
  const loading = useSelector((state: State) => state.guestList.loadingGuests || state.guestList.loadingStatuses);

  const guestHasStatus = (guest: CheckedInGuest) =>
    !guest.Status || statuses.findIndex((status) => status.POSStatus === guest.Status?.POSStatus) >= 0;

  const guestsWithStatus = checkedInGuests.filter(guestHasStatus);

  return loading ? (
    <ListViewSkeleton />
  ) : (
    <ListViewOrderColumn
      key={0}
      orders={guestsWithStatus}
      status={{
        PosStatusId: 0,
        POSStatus: 'Pre-Cart',
        Color: colors.dutchie.terra,
      }}
    />
  );
};
