import { getAllotmentValueToDisplay } from 'util/helpers/allotment';
import { getUsedPercentageData } from '../index';
import { useAppSelector } from 'util/hooks';
import { useCartPopups } from 'components/CartPopups';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

import type { AllotmentLimitProps } from './AllotmentLimit';

export const useAllotmentLimit = ({ preorderCartItems }: Pick<AllotmentLimitProps, 'preorderCartItems'>) => {
  const guestAllotmentData = useAppSelector((state) => state.customer.details?.AllotmentData);

  const { data: cartDetails } = useGetCartDetails();

  const cartAllotmentData = cartDetails?.Allotment;

  const allotment = preorderCartItems ? guestAllotmentData : cartAllotmentData;

  // If CurrentLimit is defined, we want to use that. If it's not defined, we're likely
  // looking at "realtime" allotment data, in which case we need to use TotalLimit.
  const limit = allotment?.CurrentLimit ?? allotment?.TotalLimit ?? 0;
  const totalRemaining = allotment?.TotalRemaining ?? 0;
  const totalInCart = allotment?.TotalInCart ?? 0;

  const preorderGramsInCart = preorderCartItems
    ? preorderCartItems.reduce((next, cur) => next + (cur.flowerEquivalent ?? 0) * cur.quantity, 0)
    : 0;
  const remaining = totalRemaining - preorderGramsInCart;

  // Previously, the math here was (limit - remaining) but had bugs due to limit being 0. Either
  // way, totalInCart + preorderGramsInCart is a better way to ensure we get the correct value
  // without relying on limit/remaining being accurate.
  const used = totalInCart + preorderGramsInCart;

  const { usedPercentage, over } = getUsedPercentageData(used, limit);

  const cartPopups = useCartPopups();

  const handleClickDetails = () => {
    cartPopups.showViewAllotmentDetailPopup();
  };

  return {
    handleClickDetails,
    over,
    remaining: `${getAllotmentValueToDisplay(remaining)}g`,
    used: `${getAllotmentValueToDisplay(used)}g`,
    usedPercentage,
  };
};
