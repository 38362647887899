import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { useHistory } from 'react-router';
import { validateBiotrackUser } from 'api/GuestApi';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { useDispatch } from 'react-redux';
import { BackButton, Button } from 'components/buttons';
import { Input } from 'components/inputs';

const BiotrackPageBase = styled.div`
  margin: 2rem 8rem;
`;

const TitleContainerDiv = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black};
  font-size: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  margin-left: 1rem;
`;

const ValidateIdButton = styled(Button)`
  border: none;
  color: ${colors.dutchie.blue};
  font-weight: 500;
  font-size: 13px;
  padding: 0;
  min-width: fit-content;
  height: unset;
`;

export const BiotrackPage: FC = () => {
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState<string>();
  const history = useHistory();

  const checkId = async () => {
    try {
      await validateBiotrackUser({
        PatientScan: cardNumber || '',
      });
      dispatch(successNotification('Validated'));
    } catch {
      dispatch(errorNotification('Validation failed'));
    }
  };

  return (
    <BiotrackPageBase>
      <TitleContainerDiv>
        <BackButton onClick={history.goBack} />
        <Title>Biotrack</Title>
      </TitleContainerDiv>
      <Input
        containerMargin='1rem 0'
        containerWidth='420px'
        placeholder='...'
        label='MJ State Id'
        value={cardNumber}
        onChange={(e) => {
          setCardNumber(e.target.value);
        }}
        endAdornment={
          <ValidateIdButton onClick={checkId} secondary>
            Validate ID
          </ValidateIdButton>
        }
      />
    </BiotrackPageBase>
  );
};
