import { createGlobalStyle } from 'styled-components';

export const Universals = createGlobalStyle`
    html, body {
        height: 100%;
    }

    html {
        /* Text rendering settings */
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;

        /*
          Set up global font properties so they can be overwritten by either individual or shorthand properties lower
          in the cascade
        */
        font-size: var(--font-body-default-size);
        font-family: var(--font-family-text);
    }

    body {
        background-color: var(--color-primary-white);
        color: var(--color-greyscale-black);
        font: var(--font-body-default);
        overflow-x: hidden;

        .MuiPopover-paper {
            border-radius: var(--button-border-radius-large);
            box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
        }
    }
`;
