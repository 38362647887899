import { isAndroid } from "../../hooks";

export function getDeviceSession() {
  if (isAndroid) {
    return `Pos-Device-SessionTime=${window.sessionStorage.getItem('DeviceSessionTime')}`;
  }

  const cookies = document.cookie.split(';');
  return cookies.find((cookie) => cookie.trim().startsWith('Pos-Device-SessionTime='));
}

/**
 * Util function for determining if a PIN login device session is expired
 * @param deviceSession The Pos-Device-SessionTime cookie string
 * @returns boolean
 */
export const isDeviceSessionExpired = (deviceSession: string | null = ''): boolean => {
  if (!deviceSession) {
    return true;
  }

  const parts = deviceSession.split('=');
  // if device session is in unexpected format, assume it is expired
  if (parts.length < 2) {
    return true;
  }

  const expiration = parseFloat(parts[1]);

  if (Number.isNaN(expiration)) {
    return true;
  }

  const expirationDate = new Date(expiration);
  const currentTime = new Date();
  return expirationDate <= currentTime;
};
