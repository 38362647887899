import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useAppSelector } from 'util/hooks';
import { useCartPopups } from 'components/CartPopups';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';

import type { CartTotalsAndActionsProps } from './CartTotalsAndActions';

export const useCartTotalsAndActions = ({ cart, areTotalsOpen, onToggleTotals }: CartTotalsAndActionsProps) => {
  const history = useHistory();
  const cartPopups = useCartPopups();
  const { showDutchiePayPreAuthCheckout } = useDutchiePayPreAuth();

  const isStatefulCheckoutEnabled = useAppSelector((state) => state.settings.features.StatefulCheckout);

  const [isTotalsDrawerOpen, setIsTotalsDrawerOpen] = useState(areTotalsOpen ?? false);

  useEffect(() => {
    if (areTotalsOpen !== undefined) {
      setIsTotalsDrawerOpen(areTotalsOpen);
    }
  }, [areTotalsOpen]);

  // Computed values

  const isCheckoutDisabled = cart.Cart.length === 0;
  const showPreauthMessage = showDutchiePayPreAuthCheckout;

  // Handlers

  const handleSetTotalsDrawer: React.Dispatch<React.SetStateAction<boolean>> = (isOpen) => {
    setIsTotalsDrawerOpen(isOpen);
    onToggleTotals?.(isOpen);
  };

  const handleReleaseCart = () => cartPopups.showConfirmReleasePopup();

  const handleSaveCart = () => cartPopups.showSetStatusPopup(() => history.push('/guestlist'));

  const handleNotifyCustomer = () => cartPopups.showNotifyCustomerPopup();

  return {
    handleNotifyCustomer,
    handleReleaseCart,
    handleSaveCart,
    handleSetTotalsDrawer,
    isCheckoutDisabled,
    isStatefulCheckoutEnabled,
    isTotalsDrawerOpen,
    showDutchiePayPreAuthCheckout,
    showPreauthMessage,
  };
};
