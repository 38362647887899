import React, { SVGAttributes } from 'react';

export type Direction = 'up' | 'down' | 'left' | 'right';

export enum ChevronDirections {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

type ChevronProps = SVGAttributes<unknown> & {
  fill?: string;
  height?: number;
  width?: number;
  direction?: Direction;
  onClick?: () => void;
};

const originalDimensions = {
  width: 20,
  height: 20,
};

export const Chevron = ({
  fill,
  height = originalDimensions.height,
  width = originalDimensions.width,
  direction = ChevronDirections.RIGHT,
  onClick,
  className,
}: ChevronProps): JSX.Element => {
  const rotationDegrees = {
    [ChevronDirections.UP]: 270,
    [ChevronDirections.DOWN]: 90,
    [ChevronDirections.LEFT]: 180,
    [ChevronDirections.RIGHT]: 0,
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      style={{ cursor: 'pointer' }}
      className={className}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g transform={`rotate(${rotationDegrees[direction]}, 10, 10)`}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.48911 16.3045C6.17499 16.0041 6.17565 15.5176 6.4906 15.218L11.9686 10.006L6.5054 4.77989C6.1913 4.47942 6.19201 3.99296 6.50699 3.69334C6.82197 3.39371 7.33193 3.39439 7.64602 3.69486L13.3933 9.1927C13.5053 9.29975 13.5945 9.42719 13.655 9.56696C13.7156 9.70691 13.7467 9.85688 13.7465 10.0083C13.7463 10.1597 13.7148 10.3096 13.6538 10.4494C13.5929 10.589 13.5037 10.7158 13.3915 10.8226L7.62815 16.306C7.3132 16.6056 6.80324 16.605 6.48911 16.3045Z'
          fill={fill ?? '#464F53'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.38813 16.4006C6.01838 16.047 6.01916 15.4744 6.38988 15.1217L11.7669 10.0057L6.4044 4.87595C6.03469 4.52229 6.03553 3.94968 6.40628 3.597C6.77703 3.24431 7.3773 3.24512 7.74702 3.59879L13.4943 9.09663C13.6195 9.21632 13.7192 9.35874 13.7868 9.51502C13.8546 9.6715 13.8893 9.83918 13.8891 10.0085C13.8889 10.1778 13.8536 10.3454 13.7854 10.5017C13.7173 10.6578 13.6177 10.7995 13.4921 10.9189L7.72887 16.4023C7.35815 16.755 6.75788 16.7543 6.38813 16.4006ZM6.59132 15.3143C6.33215 15.5609 6.3316 15.9612 6.5901 16.2085C6.8486 16.4557 7.26825 16.4562 7.52743 16.2096L13.2903 10.7266C13.3893 10.6325 13.4684 10.5202 13.5221 10.3971C13.5759 10.2738 13.6037 10.1416 13.6039 10.0081C13.604 9.87458 13.5766 9.74232 13.5232 9.6189C13.4698 9.49564 13.3915 9.38361 13.2928 9.28921L7.54503 3.79093C7.28656 3.54367 6.8669 3.54311 6.6077 3.78967C6.3485 4.03624 6.34792 4.43656 6.60639 4.68381L12.0696 9.90993C12.1252 9.96313 12.1251 10.0493 12.0694 10.1023L6.59132 15.3143ZM12.1524 10.1818C12.2081 10.1288 12.2984 10.1289 12.3541 10.182C12.4097 10.2352 12.4101 10.3218 12.3543 10.3749C12.2986 10.4279 12.2083 10.4279 12.1526 10.3747C12.097 10.3215 12.0966 10.2349 12.1524 10.1818ZM12.1529 9.83063C12.0973 9.77742 12.0974 9.6913 12.1532 9.63825C12.209 9.58523 12.2998 9.58492 12.3554 9.63812C12.411 9.69133 12.4108 9.77746 12.3551 9.83051C12.2993 9.88352 12.2085 9.88384 12.1529 9.83063Z'
          fill={fill ?? '#464F53'}
        />
      </g>
    </svg>
  );
};
