import React, { FC } from 'react';
import { callback } from 'models/Misc';
import { ConfirmationPopup } from 'components/popups';
import { colors } from 'css/Theme';
import styled from 'styled-components';

type PaymentMethodDisabledPopupProps = {
  hide: callback;
  paymentMethod: string;
  message: string;
};

export const PaymentMethodDisabledPopup: FC<PaymentMethodDisabledPopupProps> = ({ hide, paymentMethod, message }) => {
  const title = `${paymentMethod} Payment Method Unavailable`;
  return (
    <ConfirmationPopup
      hide={hide}
      isVisible
      title={title}
      confirm={{
        text: 'Close',
        onClick: hide,
      }}
      small
    >
      <Body>
        <MessageDiv>{message}</MessageDiv>
      </Body>
    </ConfirmationPopup>
  );
};

const MessageDiv = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.75rem 0 2.5rem;
  text-align: center;
`;

const Body = styled.div`
  margin: 0 1.5rem;
`;
