import React, { FC } from 'react';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import { reduce } from 'lodash';

import { Popup } from 'components/popups';
import { callback1, callback } from 'models/Misc';
import { Label } from 'components/text';
import { Input } from 'components/inputs';
import { colors } from 'css/Theme';
import { Button, CancelButton } from 'components/buttons';

const billDenominations = ['100', '50', '20', '10', '5', '2', '1'];

const coinDenominations = ['1.00', '0.50', '0.25', '0.10', '0.05', '0.01', 'total'];

const totalDenominations = billDenominations.concat(coinDenominations);

type CashDenomination = typeof totalDenominations[number];
export type CashDetails = Record<CashDenomination, string>;

export const initialCashValues = totalDenominations.reduce(
  (acc, denomination) => ({ ...acc, [denomination]: '' }),
  {}
) as CashDetails;

type CashDetailsPopupProps = {
  isVisible: boolean;
  hide: callback;
  details: CashDetails;
  onCashDetailsChange: callback1<CashDetails>;
  onSave: callback1<number>;
};

export const CashDetailsPopup: FC<CashDetailsPopupProps> = ({
  isVisible,
  hide,
  details,
  onCashDetailsChange,
  onSave,
}) => {
  const handleValueChange = (value: string, denomination: CashDenomination) => {
    if (value.length && !Number(value)) {
      return;
    }
    onCashDetailsChange({ ...details, [denomination]: value });
  };

  const calcCashValues = reduce(
    details,
    (acc, count, key) => {
      if (key === 'total') {
        return acc + Number(count);
      }
      return acc + Number(count) * Number(key);
    },
    0
  );

  const handleSave = () => {
    onSave(calcCashValues);
    hide();
  };

  const handleClear = () => {
    onCashDetailsChange(initialCashValues);
  };

  return (
    <StyledDutchiePopup contentMaxHeight='calc(100vh - 150px)' caption='Cash Details' isVisible={isVisible} hide={hide}>
      <CashDetailsContainer>
        <div>
          <CurrencyTypeHeader>Bills</CurrencyTypeHeader>
          <div>
            {billDenominations.map((billDenomination) => (
              <CurrencyContainer key={billDenomination}>
                <StyledLabel>${billDenomination}</StyledLabel>
                <Input
                  containerWidth='275px'
                  containerMargin='0'
                  type='number'
                  allowNegative={false}
                  decimalScale={0}
                  value={details[billDenomination]}
                  onValueChange={(v: NumberFormatValues) => handleValueChange(v.formattedValue, billDenomination)}
                />
                {details[billDenomination].length > 0 ? (
                  <CurrencyTotal>
                    $ {(Number(details[billDenomination]) * Number(billDenomination)).toFixed(2)}
                  </CurrencyTotal>
                ) : (
                  <CurrencyTotal>$ ##.##</CurrencyTotal>
                )}
              </CurrencyContainer>
            ))}
          </div>
        </div>
        <div>
          <CurrencyTypeHeader>Coins</CurrencyTypeHeader>
          <div>
            {coinDenominations.map((coinDenomination) => (
              <CurrencyContainer key={coinDenomination}>
                {coinDenomination === 'total' ? (
                  <StyledLabel>Total</StyledLabel>
                ) : (
                  <StyledLabel>${coinDenomination}</StyledLabel>
                )}
                <Input
                  containerWidth='275px'
                  containerMargin='0'
                  type='number'
                  allowNegative={false}
                  decimalScale={coinDenomination === 'total' ? 2 : 0}
                  value={details[coinDenomination]}
                  onValueChange={(v: NumberFormatValues) => handleValueChange(v.formattedValue, coinDenomination)}
                />
                {coinDenomination !== 'total' && details[coinDenomination].length > 0 ? (
                  <CurrencyTotal>
                    $ {(Number(details[coinDenomination]) * Number(coinDenomination)).toFixed(2)}
                  </CurrencyTotal>
                ) : coinDenomination === 'total' && details[coinDenomination].length > 0 ? (
                  <CurrencyTotal>$ {Number(details[coinDenomination]).toFixed(2)}</CurrencyTotal>
                ) : (
                  <CurrencyTotal>$ ##.##</CurrencyTotal>
                )}
              </CurrencyContainer>
            ))}
          </div>
        </div>
      </CashDetailsContainer>
      <ButtonsContainer>
        <CancelButton onClick={handleClear} secondary>
          Clear
        </CancelButton>
        <Button onClick={handleSave}>Save</Button>
      </ButtonsContainer>
    </StyledDutchiePopup>
  );
};

const StyledDutchiePopup = styled(Popup)`
  width: 1150px;
  max-width: 98%;
`;

const CashDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
`;

const CurrencyTypeHeader = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

const StyledLabel = styled(Label)`
  font-weight: 500;
  width: 70px;
  margin-bottom: 0;
`;

const CurrencyTotal = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.dutchie.grey};
  margin-left: 4rem;
  width: 90px;
`;

const ButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem;
  box-shadow: 0 -1px 0 ${colors.dutchie.shadowGrey};
`;
