/**
 * BaseCustomEventData is the base type for all custom event data objects.
 *
 * key - unique identifier for the custom event
 * comment - optional comment to add to the custom event to add more verbose context
 */
export type BaseCustomEventData = { key: string; comment?: string };

/**
 * WARNING: changing keys will potentially break existing dashboards/queries in DataDog and/or LogRocket
 * PLEASE LEAVE A COMMENT DESCRIBING WHAT THE EVENT IS TRACKING
 */
export const customEventKeys = {
  cart: {
    missingShipmentId: 'register-cart_missing_shipment_id', // User landed on cart page without a shipment ID
    addItemsPanel: {
      selectItem: 'register-cart_add-items-panel_select-item', // User clicked on a product in the add items panel
    },
    addScannedItem: 'register-cart_add-scanned-item', // The code found a product which matched the user-scanned item and added it to cart
    checkout: 'register-cart_checkout', // User performed checked
    itemsChange: 'register-cart_items-change', // There was a change to the number of items in the cart
    preorderCreated: 'register-cart_preorder-created', // User saved and released a preorder (created)
    loadCartShipmentIdMismatch: 'register-cart_load-cart-shipment-id-mismatch', // Loaded cart has mismatched shipment ID with redux
    loaded: 'register-cart_loaded', // User landed on cart page and it completed load
    zeroQtyPreorderReceived: 'register-cart_received-zero-qty-preorder', // System received a preorder with 1 or more items with 0 quantity
    calcCartTotalsCacheError: 'register-cart_calculate-cart-totals-cache-error', // No cart details were found in RQ cache
  },
  checkInCustomer: 'register-check_in_customer', // Customer was checked in
  checkout: {
    requestSent: 'register-checkout_request-sent', // Checkout request sent to server
    cartRefetchedError: 'register-checkout_cart-refetched-error', // Error - refetched cart after checkout
  },
  cfd: {
    opened: 'register-cfd_opened', // CFD window opened
    status: 'register-cfd_connection-status', // CFD connection status changed
  },
  guestlist: {
    search: 'register-guestlist_search', // User performed search from the guestlist
  },
  logRocket100PercentSampling: 'register_log-rocket-100-percent-sampling', // Turn on 100 percent sampling for session
  hardwareLibrary: {
    debugEvent: 'register-hardware_library_debug_event', // Hardware library debug event
    disposeEnd: 'register-hardware_library_dispose_end', // Hardware library disposed
    disposeStart: 'register-hardware_library_dispose_start', // Hardware library disposing
    flagChanged: 'register-hardware_library_flag_changed', // Hardware library flag changed
    initializeEnd: 'register-hardware_library_init_end', // Hardware library initialized
    initializeStart: 'register-hardware_library_init_start', // Hardware library initializing
    migratePeripherals: 'register-hardware_library_migrate_peripherals', // User migrated peripherals from old Redux values
    missingPrinter: 'register-hardware_library_missing_printer', // Valid printer did not exist in local storage
    reduxUpdate: 'register-hardware_library_redux_update', // Hardware library redux state updated
    revertPeripheralMigration: 'register-hardware_library_revert_migrate_peripherals', // Reverted migration when code flipped off
    unexpectedEvent: 'register-hardware_library_unexpected_event', // Hardware library unexpected event
    verboseLog: 'register-hardware_library_verbose_log', // Hardware library verbose log
  },
  messaging: {
    ably: {
      messageReceipt: 'ably-message-receipt', // Ably Message receipt
    },
    pusher: {
      messageReceipt: 'pusher-message-receipt', // Pusher Message receipt
    },
  },
  modalMounted: 'register-modal_mounted', // A modal mounted on the page
  modalNotification: 'register-modal-notification', // User received a notification in a modal
  navigation: {
    header: {
      contextAttributesRageClick: 'register-navigation-header-context_attributes_rage_click', // User rage clicked on the context attributes
    },
  },
  notification: 'register-notification', // User received a notification
  peripheralError: 'register_peripheral-error', // User received an error from a peripheral
  postResponse: 'register_post-response', // POST response from server
  postRequest: 'register_post-request', // POST request to server
  productsList: {
    inventoryTagsLoaded: 'register_products-list_inventory-tags-loaded', // Inventory tags loaded
    updatedQuantities: 'register_products-list_updated-quantities', // Products list updated quantities
    productsMightBeFetched: 'register_products-list_products-might-be-fetched', // Products list might be fetched if not cached
    productsLoaded: 'register_products-list_products-loaded', // Products list loaded
  },
  printing: {
    autoConnected: 'register_printer-auto_connected_to_printer', // Code auto-connected to a printer
    initialSettings: 'register_printer-initial_printer_settings', // Code set initial printer settings
    jobEnded: 'register_printer-print_job_ended', // Print or download job ended
    jobStarted: 'register_printer-print_job_started', // Print or download job started
    local: {
      label: {
        search: 'register_printer-search_local_label_printers', // Local label printer search initiated (code or user)
        searchLabels: 'register_printer-search_labels', // Search labels initiated (code or user)
        status: 'register_printer-label_printer_status', // Local label printer status changed
      },
      printerNotAvailable: 'register_printer-selected_local_printer_not_available', // User selected a local printer that is not available
      receipt: {
        search: 'register_printer-search_local_receipt_printers', // Local receipt printer search initiated (code or user)
        status: 'register_printer-receipt_printer_status', // Local receipt printer status changed
      },
    },
    network: {
      getPrinters: 'register_printer-get_network_printers', // Network printer search initiated (code or user)
    },
    printerNotFound: 'register_printer-printer_not_found', // Printer not found
    userChangedPrinterSettings: 'register_printer-user_changed_printer_settings', // User changed one or more printer settings
    userSelectedPrinter: 'register_printer-user_selected_printer', // User selected a printer
    userSelectedPrinterNotFound: 'register_printer-selected_printer_not_found', // User selected printer not found
    useStoredPrinterId: 'register_printer-use_stored_printer_id', // The selected printer id in Redux is undefined and used an old value from storage
  },
  reactQuery: 'register_react-query', // React Query event took place
  reduxAction: 'register_redux-action', // Redux action took place
  scanning: {
    bluetooth: {
      idLookup: 'register_scanner-scanner_id_lookup', // Code looked up scanner that was used to scan something
      playedSound: 'register_scanner-played_sound', // Scanner played a sound
      search: 'register_scanner-search_scanners', // Scanner search initiated (code or user)
      status: 'register_scanner-status_event', // Scanner status changed
      userSelectedScanner: 'register_scanner-user_selected_scanner', // User selected a scanner
    },
    data: 'register_scanner-data_event', // Scanner data event
    id: {
      scanDetailsPopupMounted: 'register-scanner-id_scan-details-popup-mounted', // ScanDetailsPopup mounted on the page
      scanResult: 'register-scanner-id_scan-result', // Result of scanning an ID (create customer, check in customer, display ScanDetailsPopup)
    },
    invalidBarcode: 'register_scanner-invalid_barcode', // User scanned an invalid barcode
    itemFoundInRedux: 'register_scanner-item_found_in_redux', // Scanned item was found in redux, so no API request was needed
    unableToConvertToProduct: 'register_scanner-unable_to_convert_to_product', // Scanned item was not found in redux, and API request failed
    mismatchedBarcode: 'register_scanner-mismatched_barcode', // Decoded bytes do not match the provided string barcode
  },
  settings: {
    scale: {
      autoConfigureStart: 'register_settings-scale_auto_configure_start', // User clicked the auto-configure button for a scale
      autoConfigureSuccess: 'register_settings-scale_auto_configured', // Scale was auto-configured
    },
    scanner: {
      parseLicense: 'register_settings-scanner_parse_license', // User clicked the parse license button for a scanner
    },
  },
  settingUserInfo: 'register_setting-user-info',
  usbDevice: {
    status: 'register_usb_device-status_event', // USB device status changed
  },
  metrics: {
    timestamps: {
      anonymousTransactionStarted: 'register_timestamp-metric_new-anonymous-transaction-started', // User started a new anonymous transaction
      clickAnonymousGuestButton: 'register_timestamp-metric_click-anonymous-guest-button', // User clicked the anonymous guest button
      clickTransactionDoneButton: 'register_timestamp-metric_click-transaction-done-button', // User clicked the done button from payment complete panel
    },
    durations: {
      anonymousCartLoaded: 'register_duration-metric_anonymous-cart-loaded', // Anonymous cart loaded
      completedPayment: 'register_duration-metric_completed-payment', // User completed a payment
    },
    count: {
      ordersCompleted: 'register_count-metric_orders-completed', // Number of orders completed
    },
  },
};
