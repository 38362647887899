import { createSlice } from '@reduxjs/toolkit';
import { LabDataMapping, LabData } from 'models/Labs';
import { logout } from 'store/actions/UserActions';
import {
  selectPreorderProduct,
  deselectPreorderProduct,
  loadLabData,
  clearLabData,
} from 'store/actions/ProductsActions';
import { PreorderProductSearchResult } from 'api/PreorderApi';

export type ProductState = {
  selectedPreorderProduct?: PreorderProductSearchResult;
  labRefData: Array<LabDataMapping> | undefined;
  labData: Array<LabData>;
  loadingLabData: boolean;
};

const initialState: ProductState = {
  selectedPreorderProduct: undefined,
  labRefData: undefined,
  labData: [],
  loadingLabData: false,
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(selectPreorderProduct, (state, action) => {
      state.selectedPreorderProduct = action.payload;
    });
    builder.addCase(deselectPreorderProduct, (state) => {
      state.selectedPreorderProduct = undefined;
    });
    builder.addCase(loadLabData.pending, (state) => {
      state.loadingLabData = true;
    });
    builder.addCase(loadLabData.fulfilled, (state, action) => {
      state.loadingLabData = false;
      state.labData = action.payload.LabData;
      state.labRefData = action.payload.LabRefData;
    });
    builder.addCase(loadLabData.rejected, (state) => {
      state.labData = [];
      state.loadingLabData = true;
    });
    builder.addCase(clearLabData, (state) => {
      state.labData = [];
    });
  },
});
