import { AT_SIGN, d, DC4, ESC, EXCLAMATION_POINT, i, NEW_LINE, NUL, p, SOH } from "./ASCIIConstants";

export const TEST_HEADER = [
    ESC,
    AT_SIGN,
    ESC,
    EXCLAMATION_POINT,
    SOH,
];

export const FOOTER_LINES = [
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
    NEW_LINE,
];

export const PRINT_LINES = [
    ESC,
    d,
    NUL,
];

export const PARTIAL_CUT = [
    ESC,
    i,
];

export const POP_CASH_DRAWER = [
    ESC,
    p,
];

export const END_STREAM = [
    NUL,
    DC4,
    DC4,
];

export const TEST_FOOTER = FOOTER_LINES.concat(PRINT_LINES).concat(PARTIAL_CUT).concat(END_STREAM);
