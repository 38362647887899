import React from 'react';

import { colors } from 'css/Theme';

type PhoneIconProps = {
  color?: string;
  size?: number;
  automationId?: string;
};

export const PhoneIcon = ({ color = colors.dutchie.almostBlack, size = 20, automationId }: PhoneIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5331 18.2252L12.5431 18.2307C13.4282 18.7661 14.479 18.9986 15.522 18.8899C16.565 18.7813 17.5378 18.3379 18.2798 17.633L18.9248 17.0203C19.2128 16.7463 19.3746 16.3748 19.3746 15.9876C19.3746 15.6003 19.2128 15.2288 18.9248 14.9548L16.2073 12.3748C15.9188 12.1011 15.5278 11.9474 15.1202 11.9474C14.7125 11.9474 14.3215 12.1011 14.0331 12.3748V12.3748C13.8905 12.5105 13.7211 12.6181 13.5346 12.6915C13.3481 12.765 13.1483 12.8028 12.9464 12.8028C12.7446 12.8028 12.5447 12.765 12.3583 12.6915C12.1718 12.6181 12.0024 12.5105 11.8598 12.3748L7.51143 8.24308C7.22335 7.96906 7.06154 7.59762 7.06154 7.21035C7.06154 6.82307 7.22335 6.45164 7.51143 6.17762V6.17762C7.65425 6.04211 7.76754 5.88119 7.84485 5.70405C7.92215 5.52692 7.96193 5.33704 7.96193 5.14529C7.96193 4.95353 7.92215 4.76366 7.84485 4.58652C7.76754 4.40938 7.65425 4.24846 7.51143 4.11295L4.79476 1.52895C4.50633 1.25528 4.11534 1.10156 3.70768 1.10156C3.30002 1.10156 2.90903 1.25528 2.6206 1.52895L1.9756 2.1417C1.23379 2.84659 0.767107 3.77067 0.652572 4.76145C0.538038 5.75224 0.782493 6.75051 1.3456 7.59154L1.35226 7.60104C4.33011 11.7888 8.12558 15.3953 12.5331 18.2252V18.2252Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
