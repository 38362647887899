import { post } from 'api/HttpHelpers';
import {
  CreateDutchieInStoreCartRequest,
  CreateDutchieInStoreCartResponse,
  LookupPinCodeRequest,
  PollingFallbackRequest,
  PollingFallbackResponse,
} from 'models/DutchiePay';

export const lookUpPinCodeRequest = (body: LookupPinCodeRequest) => post<unknown>('dutchiepay/lookup-pin-code', body);

export const createDutchieInStoreCartRequest = (body: CreateDutchieInStoreCartRequest) =>
  post<CreateDutchieInStoreCartResponse>('dutchiepay/create-dutchie-instorecart', body);

export const pollingFallbackApiRequest = (body: PollingFallbackRequest) => {
  return post<PollingFallbackResponse>('dutchiepay/polling-pusher-fallback', body);
};
