import { useSelector } from 'react-redux';

import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';
import { usePrintJob } from '../printing/usePrintJob';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CartItem } from 'models/Cart';
import type { State } from 'store';

type AutoPrintLabelsParams = {
  cartItem: CartItem;
};

export const useAutoPrintLabels = () => {
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  const { guestId, shipmentId } = useTransactionManager();

  const { data: products } = useGetAllProductsQuery({
    customerId: guestId ?? 0,
    registerId,
  });

  const isAutoPrintLabelsEnabled = useSelector((state: State) => state.settings.userSettings.autoPrintLabels);
  const { printLabels } = usePrintJob();

  return async ({ cartItem }: AutoPrintLabelsParams) => {
    // If auto print labels is not enabled, return early
    if (!shipmentId || !isAutoPrintLabelsEnabled || !products) {
      return;
    }

    // Make sure the product can be auto printed
    const matchingProduct = products.find((product) => product.productId === cartItem.ProductId);
    const isAutoPrintProductEnabled = matchingProduct?.autoPrintLabel ?? true;
    if (!isAutoPrintProductEnabled) {
      return;
    }

    printLabels({
      guest: { ShipmentId: shipmentId },
      items: cartItem,
      printAll: true,
      autoPrint: true,
    });
  };
};
