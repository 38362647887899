import React, { FC } from 'react';
import styled from 'styled-components';

import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { ModalContainer, ModalCloseButton, ModalVariation } from 'components/modals/Modal';
import { PopupOverlay } from 'components/popups';
import { DatePicker } from 'components/inputs';
import { useVerifyBirthdateModal } from './useVerifyBirthdateModal';

export type VerifyBirthdateModalProps = {
  guestDob?: string;
  guestId?: number;
  hide: () => void;
  isGuestAnonymous?: boolean;
  onSuccess: (dob: Date | null) => void;
  shipmentId?: number;
};

export const VerifyBirthdateModal: FC<VerifyBirthdateModalProps> = (props) => {
  const { birthdate, handleChangeBirthdate, handleKeyDown, isSubmitDisabled, onCancel, onSubmit } =
    useVerifyBirthdateModal(props);

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer data-testid='verify-birthdate-modal' variation={ModalVariation.Standard}>
        <Header>
          <Title>Enter birthdate</Title>
          <ModalCloseButton onClick={onCancel} />
        </Header>
        <Content>
          <DatePicker autoFocus selected={birthdate} onChange={handleChangeBirthdate} onKeyDown={handleKeyDown} />
        </Content>
        <Footer>
          <Button cta tertiary onClick={onCancel}>
            Cancel
          </Button>
          <Button cta onClick={onSubmit} disabled={isSubmitDisabled}>
            Confirm
          </Button>
        </Footer>
      </ModalContainer>
    </PopupOverlay>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1.5rem;
`;

const Title = styled.h3`
  flex: 1;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  text-align: center;
  color: ${colors.dutchie.almostBlack};
  margin: 0;
  margin-left: 2.75rem;
`;

const Content = styled.div`
  padding: 0 2rem;

  & > * {
    margin: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 2rem;
  gap: 1rem;
`;
