import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { breakpoints, colors, eloOriPadBreakpoint } from 'css/Theme';

const RightPanelWidth = 'clamp(370px, 35%, 520px)';

export const RightPanelDimensions = css`
  height: 100%;
  width: ${RightPanelWidth};

  ${breakpoints.smallTablet.maxQuery} {
    width: 100%;
    max-width: 600px;
  }
`;

export const LeftContainerDimensions = css`
  height: 100%;
  width: calc(100% - ${RightPanelWidth});

  ${breakpoints.smallTablet.maxQuery} {
    width: 100%;
  }
`;

type SidePanelProps = {
  automationId?: string;
  'data-testid'?: string;
};

export const SidePanel = styled.div.attrs((props: SidePanelProps) => ({
  'data-testid': props.automationId ?? props['data-testid'],
}))<SidePanelProps>`
  ${RightPanelDimensions}
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 0;
  border-left: 1px solid ${colors.dutchie.shadowGrey};
  overflow: auto;

  ${breakpoints.smallTablet.maxQuery} {
    border: none;
  }
`;

const StyledSidePanelSection = styled.section<{
  showBorder?: boolean;
  align: 'top' | 'bottom' | 'inBetween';
  flex?: boolean;
  scroll?: boolean;
  sticky?: boolean;
  gap?: string;
  height?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ align }) => {
    if (align === 'top') {
      return 'flex-start';
    }
    if (align === 'bottom') {
      return 'flex-end';
    }
    if (align === 'inBetween') {
      return 'space-between';
    }
  }};
  width: 100%;
  ${({ height }) => height && `height: ${height};`}
  padding: 1.5rem 2rem;

  & > button {
    border-radius: 8px;
  }

  ${eloOriPadBreakpoint} {
    padding: 1.5rem 2rem;
  }
  ${({ showBorder }) =>
    showBorder &&
    `
        border-bottom: 1px solid ${colors.dutchie.backgroundGrey};
    `}
  ${({ flex }) => flex && `flex-grow: 1;`}
    ${({ scroll }) => scroll && `overflow-y: auto;`}
    ${({ sticky }) =>
    sticky &&
    `
        position: sticky;
        bottom: 0;
        border: none;
        background: ${colors.dutchie.primaryWhite};
        border-bottom: none;
        border-top: 1px solid ${colors.dutchie.backgroundGrey};
    `}
    ${({ gap }) => gap && `gap: ${gap};`}
`;

type SidePanelSectionProps = {
  showBorder?: boolean;
  flex?: boolean;
  align?: 'top' | 'bottom' | 'inBetween';
  scroll?: boolean;
  sticky?: boolean;
  gap?: string;
  height?: string;
  children?: React.ReactNode;
  className?: string;
};

export const SidePanelSection = forwardRef<HTMLElement, SidePanelSectionProps>(
  ({ children, showBorder = true, align = 'top', ...rest }, ref) => {
    return (
      <StyledSidePanelSection ref={ref} showBorder={showBorder} align={align} {...rest}>
        {children}
      </StyledSidePanelSection>
    );
  }
);

export const SectionHeader = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 170%;
  font-weight: 700;
  letter-spacing: 0.5%;
`;
