import { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { isAndroid } from './useAndroidPeripherals';
import { successNotification, warningNotification } from 'store/actions/NotificationsActions';

const useAndroidCfdStatusNotifications = () => {
  const dispatch = useDispatch();
  const connectedRef = useRef<boolean>();
  const isCfdConnected = useSelector((state: State) => state.settings.isCfdConnected);

  const notifyCFDStatusChange = useCallback(() => {
    const cfdNotInitialized = connectedRef.current === undefined && !isCfdConnected;
    const cfdStateNotChanged = connectedRef.current === isCfdConnected;

    // Don't show a 'disconnected' notification if the CFD hasn't loaded yet
    // Or if current state equals new state or not on Elo
    if (!isAndroid || cfdNotInitialized || cfdStateNotChanged) {
      return;
    }

    connectedRef.current = isCfdConnected;
    dispatch(
      isCfdConnected
        ? successNotification(`Customer Facing Display is connected`)
        : warningNotification(`Customer Facing Display is disconnected`)
    );
  }, [dispatch, isCfdConnected]);

  useEffect(() => {
    notifyCFDStatusChange();
  }, [notifyCFDStatusChange]);
};

export const AndroidCfdNotificationListener = () => {
  useAndroidCfdStatusNotifications();
  return null;
};

