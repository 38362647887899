import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import {
  CustomerDetails,
  AdjustLoyaltyPointsRequest,
  AdjustAllotmentRequest,
  AdjustExternalPatientAllotmentRequest,
  EditAllotmentTypeEnum,
  ClearAllotmentRequest,
  UploadDocumentRequest,
  EditPrimaryIdentificationRequest,
  SelectCollectorRequest,
  EditCollectorRequest,
  UpdateAttestationRequest,
  CustomerCaregiver,
  UpdateDaysSupplyRequest,
  AddPrescriptionRequest,
  CompletePrescriptionRequest,
  RegisterCustomerForLoyaltyRequest,
  DownloadDocumentRequest,
  DeletePrescriptionRequest,
  RemoveAddressBookItemRequest,
  PreorderMetadataValues,
  CheckInCustomerResponse,
} from 'models/Customer';
import * as CustomerApi from 'api/CustomerApi';
import { State } from 'store';
import { BuildCreateCustomerRequest, BuildEditCustomerDetailsRequest } from 'util/CustomerRequestBuilder';
import { checkManagerPin } from 'util/Helpers';
import { openPDFInWindow } from 'util/Printing';
import { SetTransactionReferenceRequest } from 'models/Guest';
import { logger, customEventKeys } from 'util/logger';

type AdjustAllotmentArgs = {
  pin?: string;
  type: EditAllotmentTypeEnum;
} & AdjustAllotmentRequest;

/** @deprecated Use the new query, clean up with pos.register.anon-cart-workflow.rollout */
export const convertGuestToNonAnonymous = createAsyncThunk(
  'convertGuestToNonAnonymous',
  async (args: { Guest_id: number }, { dispatch }) => {
    try {
      await CustomerApi.convertGuestToNonAnonymous({ Guest_id: args.Guest_id });
      await dispatch(getCustomerDetails({ guestId: args.Guest_id }));
    } catch {
      dispatch(errorNotification('Unable to convert anonymous guest to non-anonymous'));
    }
  }
);

export const getAvailableGroups = createAsyncThunk('getAvailableGroups', async () => {
  return await CustomerApi.getAvailableGroups();
});

export const getAvailableDiscounts = createAsyncThunk(
  'getAvailableDiscounts',
  async (guestId: number, { dispatch }) => {
    try {
      return CustomerApi.getAvailableDiscounts(guestId);
    } catch {
      dispatch(warningNotification('Unable to load available discounts'));
    }
  }
);

export const getDoctorList = createAsyncThunk('getDoctorList', async (args, { dispatch }) => {
  try {
    return CustomerApi.getDoctorList();
  } catch {
    dispatch(warningNotification('Unable to load doctors list'));
  }
});

export const getQualifyingConditions = createAsyncThunk('getQualifyingConditions', async (args, { dispatch }) => {
  try {
    return CustomerApi.getQualifyingConditions();
  } catch (error) {
    dispatch(errorNotification(error ? JSON.stringify(error) : 'Unable to get qualifying conditions'));
  }
});

export const getReferralList = createAsyncThunk('getReferralList', async (args, { dispatch }) => {
  try {
    return CustomerApi.getReferralList();
  } catch {
    dispatch(warningNotification('Unable to load referral options'));
  }
});

export const getCustomerDetails = createAsyncThunk(
  'getCustomerDetails',
  async (arg: { guestId: number; noLoading?: boolean }, { dispatch }) => {
    try {
      const result = await CustomerApi.getCustomerDetails({ Guest_id: arg.guestId });
      if (result.Errors && result.Errors.length > 0) {
        result.Errors.forEach((error) => {
          dispatch(errorNotification(error));
        });
      }
      return result;
    } catch {
      dispatch(warningNotification('Unable to load customer details'));
    }
  }
);

export const getCustomerDetailsLight = createAsyncThunk(
  'getCustomerDetailsLight',
  async (arg: { guestId: number; noLoading?: boolean }, { dispatch }) => {
    try {
      return await CustomerApi.getCustomerDetailsLight({ CustomerId: arg.guestId });
    } catch {
      dispatch(warningNotification('Unable to load customer details'));
    }
  }
);

export const clearCustomerPreviewSelected = createAction('clearCustomerPreviewSelected');

export const getPrescriptions = createAsyncThunk('getPrescriptions', async (arg: { guestId: number }, { dispatch }) => {
  try {
    return CustomerApi.getPrescriptions({ AcctId: arg.guestId });
  } catch (error) {
    dispatch(errorNotification(error ? JSON.stringify(error) : 'There was an error retrieving the prescriptions'));
  }
});

export const addPrescription = createAsyncThunk(
  'addPrescription',
  async (args: AddPrescriptionRequest, { dispatch }) => {
    const response = await CustomerApi.addPrescription({ ...args })
      .then(() => {
        if (args.PrescriptionId === 0) {
          dispatch(successNotification('Prescription successfully added'));
        } else {
          dispatch(successNotification('Prescription successfully saved'));
        }
      })
      .catch((error: string) => {
        if (args.PrescriptionId === 0) {
          dispatch(errorNotification(error ? error : 'There was an error saving the prescription'));
        } else {
          dispatch(errorNotification(error ? error : 'There was an error adding the prescription'));
        }
      });
    return response;
  }
);

export const completePrescription = createAsyncThunk(
  'completePrescription',
  async (args: CompletePrescriptionRequest, { dispatch }) => {
    const response = await CustomerApi.completePrescription({ ...args })
      .then(() => {
        dispatch(successNotification('Prescription successfully completed'));
      })
      .catch((error: string) => {
        dispatch(errorNotification(error ? error : 'There was an error completing the prescription'));
      });
    return response;
  }
);

export const deletePrescription = createAsyncThunk(
  'deletePrescription',
  async (args: DeletePrescriptionRequest, { dispatch }) => {
    const response = await CustomerApi.deletePrescription({ ...args })
      .then(() => {
        dispatch(successNotification('Prescription deleted'));
      })
      .catch((error: string) => {
        dispatch(errorNotification(error ? error : 'There was an error deleting the prescription'));
      });
    return response;
  }
);

export const updateCustomerDetails = createAsyncThunk(
  'updateCustomerDetails',
  async (customerDetails: CustomerDetails, { dispatch }) => {
    try {
      const resp = await CustomerApi.editCustomerDetails(BuildEditCustomerDetailsRequest(customerDetails));
      if (resp[0].PatientUpdated === 0) {
        //The type of this response changes depending on if it succeeds :(
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(errorNotification((resp as any)[0].PatientResponse));
        return {
          success: false,
          details: customerDetails,
        };
      } else {
        dispatch(successNotification('Customer info saved'));
      }
      const details = await CustomerApi.getCustomerDetails({ Guest_id: customerDetails.Guest_id });

      return {
        success: true,
        details,
      };
    } catch (e) {
      dispatch(errorNotification(`Unable to edit customer details: ${e}`));
    }
  }
);

export const createCustomer = createAsyncThunk(
  'createNewCustomer',
  async (customerDetails: CustomerDetails, { dispatch }) => {
    try {
      const createdCustomer = await CustomerApi.createCustomer(BuildCreateCustomerRequest(customerDetails));
      const newCustomerId = createdCustomer[0]?.Guest_id;
      if (newCustomerId) {
        // This line is going to be removed as part of this work ENG-38408
        await CustomerApi.editCustomerDetails({
          ...BuildEditCustomerDetailsRequest(customerDetails),
          Guest_id: newCustomerId,
        });
        dispatch(successNotification('Customer Created'));
        return await CustomerApi.getCustomerDetails({ Guest_id: newCustomerId });
      } else {
        dispatch(errorNotification('Error creating customer'));
      }
    } catch (error) {
      dispatch(errorNotification('Error creating customer'));
    }
  }
);

export const removeAddressBookItem = createAsyncThunk(
  'removeAddressBookItem',
  async (args: RemoveAddressBookItemRequest, { dispatch }) => {
    try {
      await CustomerApi.removeAddressBookItem(args);
      dispatch(successNotification('Address deleted'));

      return args.AddressId;
    } catch {
      dispatch(errorNotification('An error occurred while removing customer Address'));
      return Promise.reject();
    }
  }
);

export const adjustLoyaltyPoints = createAsyncThunk(
  'adjustLoyaltyPoints',
  async (args: AdjustLoyaltyPointsRequest, { getState, dispatch }) => {
    const state = getState() as State;
    if (state.settings.features.ManagerPasswordForLoyaltyAdjustments) {
      if (!args.pin) {
        dispatch(errorNotification('Error: Invalid Manager Pin'));
        return Promise.reject();
      }

      try {
        const managerApprovalId = await checkManagerPin(args.pin);
        args.PinUserId = managerApprovalId[0].UserId;
      } catch (error) {
        dispatch(errorNotification(`${error}`));
        return Promise.reject();
      }
    }

    try {
      await CustomerApi.adjustCustomerLoyaltyPoints({
        ...args,
        TransactionBy: Number(window.sessionStorage.getItem('UserId')),
      });
      dispatch(successNotification('Loyalty points adjusted'));
      return CustomerApi.getCustomerDetails({ Guest_id: args.GuestId });
    } catch {
      dispatch(errorNotification('An error occurred while adjusting loyalty points'));
      return Promise.reject();
    }
  }
);

export const updateDaysSupplyRemaining = createAsyncThunk(
  'updateDaysSupplyRemaining',
  async (args: UpdateDaysSupplyRequest, { dispatch }) => {
    try {
      await CustomerApi.updateDaysSupplyRemaining(args);
      return CustomerApi.getCustomerDetails({ Guest_id: args.PatientId });
    } catch {
      dispatch(errorNotification('An error occurred while updating days supply'));
      return Promise.reject();
    }
  }
);

export const adjustAllotment = createAsyncThunk(
  'adjustAllotment',
  async (args: AdjustAllotmentArgs, { getState, dispatch }) => {
    const state = getState() as State;
    if (state.settings.features.RequireManagerPasswordForAllotmentAdjust) {
      await checkManagerPin(args.pin);
    }

    try {
      switch (args.type) {
        case EditAllotmentTypeEnum.Max:
          await CustomerApi.adjustCustomerMaxAllotment(args);
          break;
        case EditAllotmentTypeEnum.Current:
          await CustomerApi.adjustCustomerCurrentAllotment(args);
          break;
        case EditAllotmentTypeEnum.External:
        case EditAllotmentTypeEnum.BackfillExternal:
          await CustomerApi.adjustCustomerExternalDispensed(args);
          break;
      }
      return CustomerApi.getCustomerDetails({ Guest_id: args.PatientId });
    } catch {
      dispatch(errorNotification('An error occurred while adjusting allotment'));
      return Promise.reject();
    }
  }
);

export const adjustExternalPatientAllotment = createAsyncThunk(
  'adjustExternalPatientAllotment',
  async (args: AdjustExternalPatientAllotmentRequest, { getState, dispatch }) => {
    try {
      await CustomerApi.adjustExternalPatientAllotment(args);
      dispatch(successNotification('Allotments updated'));
      return CustomerApi.getCustomerDetails({ Guest_id: args.PatientId });
    } catch (error) {
      dispatch(errorNotification(`An error occurred while adjusting patient allotment: ${error}`));
      return Promise.reject();
    }
  }
);

type ClearAllotmentOverrideArgs = {
  type: EditAllotmentTypeEnum;
} & ClearAllotmentRequest;

export const clearAllotmentOverride = createAsyncThunk(
  'clearAllotmentOverride',
  async (args: ClearAllotmentOverrideArgs, { dispatch }) => {
    try {
      if (args.type === EditAllotmentTypeEnum.Max) {
        await CustomerApi.clearCustomerMaxAllotment(args);
      } else {
        await CustomerApi.clearCustomerCurrentAllotment(args);
      }
      return CustomerApi.getCustomerDetails({ Guest_id: args.PatientId });
    } catch {
      dispatch(errorNotification('An error occurred while clearing allotment override'));
      return Promise.reject();
    }
  }
);

export const checkInCustomer = createAsyncThunk(
  'checkInCustomer',
  async (
    args: { guestId: number; caregiverId?: string; mjStateId?: string; register?: number; roomId?: string },
    { getState, dispatch }
  ) => {
    try {
      const response = await CustomerApi.checkInCustomer({
        AcctId: args.guestId,
        CaregiverMJStateIdNo: args.caregiverId,
        MJStateIDNo: args.mjStateId,
        Register: args.register,
        RoomId: args.roomId,
      });

      const { Data, Message, Result, IntegrationMessage } = response;
      if (Message) {
        if (Result) {
          const [checkInCustomerRes] = Data as [CheckInCustomerResponse];
          if (checkInCustomerRes) {
            logger.info(`customer ${args.guestId} checked in`, {
              key: customEventKeys.checkInCustomer,
              guestId: args.guestId,
              shipmentId: checkInCustomerRes.ShipmentId,
              scanId: checkInCustomerRes.ScanId,
              scanResult: checkInCustomerRes.ScanResult,
            });
          }
          dispatch(successNotification(Message));
        } else {
          dispatch(errorNotification(`${Message} ${IntegrationMessage ?? ''}`));
        }
      }
      return Data;
    } catch (error) {
      dispatch(errorNotification(error ? JSON.stringify(error) : 'An error occurred while checking the customer in'));
    }
  }
);

export const loadReturnReasons = createAsyncThunk('loadReturnReasons', async (args, { dispatch }) => {
  try {
    return await CustomerApi.loadReturnReasons();
  } catch {
    dispatch(warningNotification('Unable to load return reasons'));
  }
});

export const loadAdjustmentLoyaltyReasons = createAsyncThunk('loadAdjustmentLoyaltyReasons', async () => {
  try {
    return await CustomerApi.loadAdjustmentLoyaltyReasons();
  } catch {
    //might show up empty - do nothing.
  }
});

export const searchProductHistory = createAction<string>('searchProductHistory');

export const loadProductHistory = createAsyncThunk('loadProductHistory', async (guestId: number, { dispatch }) => {
  try {
    return await CustomerApi.loadProductHistory({ Guest_id: guestId });
  } catch {
    dispatch(warningNotification('Unable to load product history'));
  }
});

export const getCustomerJournal = createAsyncThunk('getCustomerJournal', async (guestId: number, { dispatch }) => {
  try {
    return await CustomerApi.getCustomerJournal({
      Guest_Id: guestId,
      NoteSource: 'Dispensary',
    });
  } catch {
    dispatch(warningNotification('An error occurred while retrieving the journal'));
    return [];
  }
});

export const createUpdateJournalEntry = createAsyncThunk(
  'createUpdateJournalEntry',
  async (args: { guestId: number; noteId: number; note: string; subject: string }, { dispatch }) => {
    try {
      await CustomerApi.createUpdateJournalEntry({
        Guest_Id: args.guestId,
        NoteId: args.noteId,
        Note: args.note,
        Subject: args.subject,
      });
      return await CustomerApi.getCustomerJournal({
        Guest_Id: args.guestId,
        NoteSource: 'Dispensary',
      });
    } catch {
      dispatch(errorNotification('An error occurred while updating the journal'));
      return Promise.reject();
    }
  }
);

export const getPatientPolicy = createAsyncThunk('getPatientPolicy', async (args: { AcctId: number }, { dispatch }) => {
  try {
    return await CustomerApi.getPatientPolicy(args);
  } catch (e) {
    dispatch(warningNotification(`Could not load policy agreement: ${e} `));
  }
});

export const setPatientPolicy = createAsyncThunk(
  'setPatientPolicy',
  async (args: { guest_id: number; signature64: string; wasAccepted: boolean }, { dispatch }) => {
    try {
      const response = await CustomerApi.setPatientPolicy({
        AcctId: args.guest_id,
        SignatureBase64: args.signature64,
        WasAccepted: args.wasAccepted,
      });
      dispatch(getPatientPolicy({ AcctId: args.guest_id }));

      if (args.wasAccepted) {
        dispatch(successNotification('Policy agreement signed successfully'));
      } else {
        dispatch(warningNotification('Policy declined'));
      }

      return response;
    } catch (e) {
      dispatch(warningNotification(`Policy agreement could not be signed: ${e}`));
    }
  }
);

export const editPrimaryIdentification = createAsyncThunk(
  'editPrimaryIdentification',
  async (args: EditPrimaryIdentificationRequest, { dispatch }) => {
    try {
      await CustomerApi.editPrimaryIdentification(args);
      return CustomerApi.getCustomerDetails({ Guest_id: args.Guest_id });
    } catch {
      dispatch(errorNotification('An error occurred while editing customer identification'));
      return Promise.reject();
    }
  }
);

export const saveNotes = createAsyncThunk(
  'saveNotes',
  async (args: { guestId?: number; notes: string }, { dispatch }) => {
    try {
      await CustomerApi.saveNotes({
        GuestId: args.guestId,
        Note: args.notes,
      });
      return args.notes;
    } catch {
      dispatch(errorNotification('An error occurred while saving the note'));
      return Promise.reject();
    }
  }
);

export const getDocuments = createAsyncThunk('getDocuments', async (args: { PatientId: number }, { dispatch }) => {
  try {
    return await CustomerApi.getDocuments(args);
  } catch {
    dispatch(errorNotification('An error occurred while retrieving customer documents'));
    return Promise.reject();
  }
});

export const uploadDocument = createAsyncThunk('uploadDocument', async (args: UploadDocumentRequest, { dispatch }) => {
  try {
    await CustomerApi.uploadDocument(args);
    return await CustomerApi.getDocuments({ PatientId: args.PatientId });
  } catch (error) {
    dispatch(errorNotification(error ? JSON.stringify(error) : 'An error occurred while uploading document'));
  }
});

export const downloadDocument = createAsyncThunk(
  'downloadDocument',
  async (args: DownloadDocumentRequest, { dispatch }) => {
    try {
      const data = await CustomerApi.downloadDocument(args);
      openPDFInWindow(data, `${args.FileName}`);
      dispatch(successNotification('Customer file generated'));
      return data;
    } catch (error) {
      dispatch(errorNotification(error ? JSON.stringify(error) : 'An error occurred while generating customer file'));
      return Promise.reject();
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'deleteDocument',
  async (args: { PatientId: number; FileId: number | undefined }, { dispatch }) => {
    try {
      await CustomerApi.deleteDocument({ FileId: args.FileId });
      return await CustomerApi.getDocuments({ PatientId: args.PatientId });
    } catch {
      dispatch(errorNotification('An error occurred while deleting document'));
      return Promise.reject();
    }
  }
);

export const loadCollectors = createAsyncThunk('loadCollectors', async (args: { CustomerId: number }, { dispatch }) => {
  try {
    const collectors = await CustomerApi.getCollectors(args.CustomerId);
    const filtered: Array<CustomerCaregiver> = [];
    for (const collector of collectors) {
      if (
        !!collector &&
        !!collector.CaregiverMJStateIdNo &&
        !!(collector.CaregiverRelationship || collector.Notes) &&
        //filter dups
        filtered.findIndex((x) => {
          return (
            x.CaregiverMJStateIdNo === collector.CaregiverMJStateIdNo &&
            (collector.CaregiverRelationship || collector.Notes) === (x.CaregiverRelationship || x.Notes)
          );
        }) <= -1
      ) {
        filtered.push(collector);
      }
    }

    return filtered;
  } catch (error) {
    dispatch(errorNotification(error ? JSON.stringify(error) : 'Error loading collectors'));
  }
});

export const selectCollector = createAsyncThunk(
  'selectCollector',
  async (args: SelectCollectorRequest, { dispatch }) => {
    CustomerApi.selectCollector({
      CustomerId: args.CustomerId,
      ShipmentId: args.ShipmentId,
      CaregiverId: args.CaregiverId,
      CaregiverName: args.CaregiverName,
      CaregiverRelationship: args.CaregiverRelationship,
      CaregiverDob: args.CaregiverDob,
      CaregiverEmail: args.CaregiverEmail,
      caregiverNotes: args.caregiverNotes,
      CaregiverPhone: args.CaregiverPhone,
      caregiverIndex: args.caregiverIndex,
      Register: args.Register,
    }).catch((error) => {
      dispatch(errorNotification(error));
    });
  }
);

export const editCollector = createAsyncThunk('editCollector', async (args: EditCollectorRequest, { dispatch }) => {
  try {
    await CustomerApi.editCollector(args);
  } catch (error) {
    dispatch(errorNotification(error ? JSON.stringify(error) : 'Error editing collector'));
  }
});

export const updateAttestation = createAsyncThunk(
  'updateAttestation',
  async (args: UpdateAttestationRequest, { dispatch }) => {
    try {
      await CustomerApi.updateAttestation(args);
    } catch (error) {
      dispatch(errorNotification(error ? JSON.stringify(error) : 'Error updating Attestation'));
    }
  }
);

export const registerForLoyalty = createAsyncThunk(
  'registerForLoyalty',
  async (args: RegisterCustomerForLoyaltyRequest, { dispatch, getState }) => {
    try {
      await CustomerApi.registerCustomerForLoyalty(args);
      dispatch(successNotification('Text Message Sent'));

      await dispatch(getCustomerDetails({ guestId: args.AcctId }));
    } catch (error) {
      dispatch(errorNotification(error ? JSON.stringify(error) : 'Error registering for loyalty'));
    }
  }
);

export const clearCustomerDetails = createAction('clearCustomerDetails');

export const setTransactionReference = createAsyncThunk(
  'setTransactionReference',
  async (args: SetTransactionReferenceRequest, { dispatch }) => {
    try {
      await CustomerApi.setTransactionReference(args);
      dispatch(successNotification('Transaction Reference updated!'));
      return args.TransactionReference;
    } catch {
      dispatch(errorNotification('Unable to update Transaction Reference'));
    }
  }
);

export const clearCustomerDocuments = createAction('clearCustomerDocuments');

export const setPreOrderMetadata = createAction('setPreOrderMetadata', (payload: PreorderMetadataValues) => ({
  payload,
}));

export const clearPreOrderMetadata = createAction('clearPreOrderMetadata');

export const clearSelectedTransaction = createAction('clearSelectedTransaction');
