import { FormikProps } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { HardwareSettings } from '../types';
import { saveUserSettings, assignRegisterToHardware } from 'store/actions/SettingsActions';
import { useAppDispatch, useAppSelector, useLocalStorage, isWebViewApp } from 'util/hooks';
import { UserSettings } from 'models/Misc';
import { useLabels } from 'pages/SettingsPage/Printers/hooks/useLabels';
import { logger } from 'util/logger';
import { HARDWARE_LIBRARY_STORAGE_KEYS, getStoredHardware } from 'util/hardwareLibrary/hardware-library-utils';
import { useEffect } from 'react';
import { getPersistedValue, PersistedValueKey, setPersistedValue } from 'util/persisted-values';
import { store } from 'store';
import { successNotification } from 'store/actions/NotificationsActions';
import { HardwareService, WebviewAppReceiptPrinter } from '@dutchie/capacitor-hardware';

export type HardwareSettingsFormData = {
  formRef: React.MutableRefObject<FormikProps<HardwareSettings> | null>;
  initialValues: HardwareSettings;
  saveForm: () => void;
};

export const useHardwareSettingsForm = ({
  formRef,
}: {
  formRef: React.MutableRefObject<FormikProps<HardwareSettings> | null>;
}): HardwareSettingsFormData => {
  const dispatch = useAppDispatch();
  const { autoPrintLabels, autoPrintFulfillmentTicket, autoPrintReceipts, selectedLabel } = useAppSelector(
    (state) => state.settings.userSettings
  );

  const { labelDropdownOptions } = useLabels();

  const { value: storedFulfillmentPrinterId, setValue: setFulfillmentPrinterId } = useLocalStorage(
    HARDWARE_LIBRARY_STORAGE_KEYS.FULFILLMENT_PRINTER_ID
  );
  const { value: storedLabelPrinterId, setValue: setLabelPrinterId } = useLocalStorage(
    HARDWARE_LIBRARY_STORAGE_KEYS.LABEL_PRINTER_ID
  );
  const { value: storedReceiptPrinterId, setValue: setReceiptPrinterId } = useLocalStorage(
    HARDWARE_LIBRARY_STORAGE_KEYS.RECEIPT_PRINTER_ID
  );
  const { value: storedScaleId, setValue: setScaleId } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.SCALE_ID);
  const { value: storedScannerId, setValue: setScannerId } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.SCANNER_ID);
  const { value: storedZReportPrinterId, setValue: setZReportPrinterId } = useLocalStorage(
    HARDWARE_LIBRARY_STORAGE_KEYS.Z_REPORT_PRINTER_ID
  );

  const initialValues: HardwareSettings = {
    assignedRegisterId: undefined, // loaded from cookie
    labelPrinterId: storedLabelPrinterId ?? '',
    autoPrintLabels: autoPrintLabels,
    defaultLabelId: selectedLabel?.id?.toString() ?? '',
    receiptPrinterId: storedReceiptPrinterId ?? '',
    autoPrintReceipts: autoPrintReceipts,
    fulfillmentPrinterId: storedFulfillmentPrinterId ?? '',
    autoPrintFulfillmentTickets: autoPrintFulfillmentTicket,
    scaleId: storedScaleId ?? '',
    scannerId: storedScannerId ?? '',
    secondaryScreenId: localStorage.getItem('hardware.cfd.screenId') ?? '',
    openCfdOnLogin: localStorage.getItem('hardware.cfd.openOnLogin') === 'true',
    zReportPrinterId: storedZReportPrinterId ?? '',
  };

  const saveForm = () => {
    if (!formRef.current) {
      return;
    }

    formRef.current.setSubmitting(true);

    try {
      const { values } = formRef.current;

      // Updated redux fields
      const updatedSettings: Partial<UserSettings> = {};

      // Assigned Register
      if (values.assignedRegisterId && values.assignedRegisterId !== initialValues.assignedRegisterId) {
        // Assign a new id to work around the API not properly removing this id from other registers.
        const newId = uuidv4();
        const assignedId = values.assignedRegisterId;
        setPersistedValue(PersistedValueKey.hardwareId, newId).then(() => {
          dispatch(assignRegisterToHardware(assignedId));
        });
      }

      // CFD
      if (values.secondaryScreenId !== initialValues.secondaryScreenId) {
        if (values.secondaryScreenId) {
          localStorage.setItem('hardware.cfd.screenId', values.secondaryScreenId);
        } else {
          localStorage.removeItem('hardware.cfd.screenId');
        }
      }

      if (values.openCfdOnLogin !== initialValues.openCfdOnLogin) {
        if (values.openCfdOnLogin) {
          localStorage.setItem('hardware.cfd.openOnLogin', 'true');
        } else {
          localStorage.removeItem('hardware.cfd.openOnLogin');
        }
      }

      // Fulfillment Printer
      if (values.autoPrintFulfillmentTickets !== autoPrintFulfillmentTicket) {
        updatedSettings.autoPrintFulfillmentTicket = values.autoPrintFulfillmentTickets;
      }

      if (values.fulfillmentPrinterId !== initialValues.fulfillmentPrinterId) {
        setFulfillmentPrinterId(values.fulfillmentPrinterId);
      }

      // Label Printer
      if (values.autoPrintLabels !== autoPrintLabels) {
        updatedSettings.autoPrintLabels = values.autoPrintLabels;
      }

      if (values.defaultLabelId && values.defaultLabelId !== initialValues.defaultLabelId) {
        const labelOption = labelDropdownOptions.find((option) => option.value.toString() === values.defaultLabelId);
        updatedSettings.selectedLabel = {
          id: parseInt(values.defaultLabelId),
          LabelDescription: labelOption?.label ?? 'Unknown Label',
        };
      }

      if (values.labelPrinterId !== initialValues.labelPrinterId) {
        setLabelPrinterId(values.labelPrinterId);
      }

      // Receipt Printer
      if (values.autoPrintReceipts !== autoPrintReceipts) {
        updatedSettings.autoPrintReceipts = values.autoPrintReceipts;
      }

      if (values.receiptPrinterId !== initialValues.receiptPrinterId) {
        setReceiptPrinterId(values.receiptPrinterId);

        if (isWebViewApp) {
          // On iOS, the receipt printer must be 'connected' to support a connected USB Zebra scanner
          const { receiptPrinter } = getStoredHardware();
          if (receiptPrinter && !receiptPrinter.isConnected) {
            receiptPrinter.connect();
          }

          // Disconnect other printers to avoid conflicts with connections from other registers
          const disconnectPrinters = HardwareService.receiptPrinter.devices.filter(
            (it) => it instanceof WebviewAppReceiptPrinter && it.isConnected && it.id !== receiptPrinter?.id
          );
          disconnectPrinters.forEach((it) => it.disconnect());
        }

        // Use the selected printer as the default for the other printers if they are not set
        if (!storedFulfillmentPrinterId) {
          setFulfillmentPrinterId(values.receiptPrinterId);
        }
        if (!storedZReportPrinterId) {
          setZReportPrinterId(values.receiptPrinterId);
        }
      }

      // Scale
      if (values.scaleId !== initialValues.scaleId) {
        setScaleId(values.scaleId);
      }

      // Scanner
      if (values.scannerId !== initialValues.scannerId) {
        setScannerId(values.scannerId);
      }

      // Z Report Printer
      if (values.zReportPrinterId !== initialValues.zReportPrinterId) {
        setZReportPrinterId(values.zReportPrinterId);
      }

      // Save user settings
      if (Object.keys(updatedSettings).length > 0) {
        dispatch(saveUserSettings(updatedSettings));
      }

      // Reset form with new values so that it is not dirty
      formRef.current.resetForm({ values });

      dispatch(successNotification('Settings successfully saved'));
    } catch (e) {
      logger.error(e);
    } finally {
      formRef.current.setSubmitting(false);
    }
  };

  useEffect(() => {
    // Load register assignment from cookie
    const form = formRef.current;
    if (!form) {
      return;
    }

    (async () => {
      // Set the default assigned register id
      const value = await getPersistedValue(PersistedValueKey.hardwareId);
      if (value) {
        const register = store.getState().settings.registers.find((it) => it.HardwareId === value);
        if (register) {
          form.resetForm({
            values: {
              ...form.values,
              assignedRegisterId: register.id,
            },
          });
        }
      }
    })();
  }, [formRef]);

  return {
    formRef,
    initialValues,
    saveForm,
  };
};
