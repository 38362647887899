import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { PatientPolicyAgreement } from 'models/Customer';

const FormContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
`;

const Title = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`;

const PolicyBase = styled.div`
  min-height: 180px;
  width: 100%;
  overflow-y: scroll;
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 6px;
`;

type PolicyFormProps = {
  className?: string;
  policy?: PatientPolicyAgreement;
};

export const Policy: FC<PolicyFormProps> = ({ className, policy }) => {
  return (
    <FormContainerDiv className={className}>
      <Title>Patient Policy Agreement</Title>
      {policy && <PolicyBase dangerouslySetInnerHTML={{ __html: policy.AgreementHTML }} />}
    </FormContainerDiv>
  );
};
