import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerCaregiver } from 'models/Customer';
import { selectCollector, editCollector } from 'store/actions/CustomerActions';
import { State } from 'store';
import { isAfter } from 'date-fns';
import { errorNotification } from 'store/actions/NotificationsActions';
import { Popup } from 'components/popups';
import { colors } from 'css/Theme';
import { Button } from 'components/buttons';
import { DatePicker, Input, Select } from 'components/inputs';
import { Spacer } from 'components/layout';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

const CollectorSection = styled.div`
  height: 102px;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

const AddEditCollectorSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
`;

export const DutchieInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  margin-top: 8px;
`;

const Buttons = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;
  box-shadow: 0 -1px 0 ${colors.dutchie.shadowGrey};
  gap: 1rem;
`;

type SelectCollectorProps = {
  customerId?: number;
  shipmentId: number;
  hide: () => void;
  isVisible?: boolean;
};

export const SelectCollectorPopup: FC<SelectCollectorProps> = ({ customerId, shipmentId, hide, isVisible }) => {
  const collectors = useSelector((state: State) => state.customer.collectors);
  const { selectedRegister } = useSelector((state: State) => state.settings);
  const [selectedCollector, setSelectedCollector] = useState<CustomerCaregiver>();
  const [addCollector, setAddCollector] = useState(false);
  const [editSelectedCollector, setEditCollector] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [collectorId, setCollectorId] = useState('');
  const [dob, setDob] = useState<Date | undefined>();
  const [relationship, setRelationship] = useState('');
  const dispatch = useDispatch();

  const { goToCart } = useTransactionManager();

  const selectCustomerCollector = () => {
    if (!selectedCollector && addCollector) {
      const careGiver: CustomerCaregiver = {
        Id: collectorId,
        name: `${firstName} ${lastName}`,
        CaregiverRelationship: relationship,
        DOB: dob,
        Notes: '',
        Phone: '',
        CaregiverMJStateIdNo: '',
        Email: '',
        Address: '',
        ExpirationDate: '',
        CaregiverIndex: 0,
      };

      dispatch(
        selectCollector({
          CustomerId: customerId,
          ShipmentId: shipmentId,
          CaregiverId: careGiver?.Id,
          CaregiverName: careGiver?.name,
          CaregiverRelationship: careGiver?.CaregiverRelationship,
          CaregiverDob: careGiver?.DOB,
          CaregiverPhone: careGiver?.Phone,
          caregiverNotes: careGiver?.Notes,
          caregiverIndex: careGiver?.CaregiverIndex,
        })
      );
    } else if (editSelectedCollector) {
      dispatch(
        editCollector({
          CustomerId: customerId,
          CaregiverId: collectorId,
          CaregiverName: `${firstName} ${lastName}`,
          CaregiverRelationship: relationship,
          CaregiverDob: dob,
          CaregiverPhone: selectedCollector?.Phone,
          CaregiverEmail: selectedCollector?.Email,
          caregiverNotes: selectedCollector?.Notes,
          caregiverIndex: selectedCollector?.CaregiverIndex,
          InitialCaregiverId: selectedCollector?.Id,
          InitialCaregiverName: selectedCollector?.name,
          InitialCaregiverRelationship: selectedCollector?.CaregiverRelationship,
          InitialCaregiverDob: selectedCollector?.DOB,
          InitialCaregiverPhone: selectedCollector?.Phone,
          InitialCaregiverEmail: selectedCollector?.Email,
          InitialCaregiverNotes: selectedCollector?.Notes,
        })
      );
    } else {
      dispatch(
        selectCollector({
          CustomerId: customerId,
          ShipmentId: shipmentId,
          CaregiverId: selectedCollector?.Id ?? '',
          CaregiverName: selectedCollector?.name ?? ' ',
          CaregiverRelationship: selectedCollector?.CaregiverRelationship ?? '',
          CaregiverDob: selectedCollector?.DOB,
          CaregiverEmail: selectedCollector?.Email ?? '',
          CaregiverPhone: selectedCollector?.Phone ?? '',
          caregiverNotes: selectedCollector?.Notes ?? '',
          caregiverIndex: selectedCollector?.CaregiverIndex ?? -1,
          Register: selectedRegister?.value,
        })
      );
    }

    goToCart({ guestId: customerId, shipmentId });
    hide();
  };

  const onSelectCollector = (collectorId: string | null) => {
    const collector = collectors.filter((x) => x.CareGiverId === parseInt(collectorId ?? '-1')); // if null, clear collector
    if (collector.length > 0) {
      setSelectedCollector(collector[0]);
      setFirstName(collector[0].name.split(' ')[0]);
      setLastName(collector[0].name.split(' ')[1]);
      setCollectorId(collector[0].CaregiverMJStateIdNo);
      if (collector[0].DOB) {
        const date = new Date(collector[0].DOB);
        setDob(date);
      }
      setRelationship(collector[0].CaregiverRelationship);
    } else {
      const date = new Date();
      setSelectedCollector(undefined);
      setFirstName('');
      setLastName('');
      setCollectorId('');
      setDob(date);
      setRelationship('');
    }
  };

  const onCollectorEdit = () => {
    setEditCollector(!editSelectedCollector);
    setAddCollector(!addCollector);
  };

  const isValid = () => {
    return !addCollector || (firstName && lastName && dob && collectorId && relationship && dob);
  };

  const handleDateChange = (value: Date) => {
    setDob(value);

    if (dob && isAfter(dob, new Date())) {
      dispatch(errorNotification('Date of Birth cannot be in the future.'));
      return;
    }
  };

  return (
    <Popup medium caption='Select Collector' isVisible={isVisible ?? true} hide={hide}>
      {!addCollector && (
        <CollectorSection>
          <label>Collector</label>
          <Select
            automationId='select-collector_select_select-collectors'
            value={selectedCollector?.CareGiverId}
            options={[
              { label: '--None--' },
              ...collectors.map((collector) => ({
                label: collector.name,
                value: String(collector.CareGiverId),
              })),
            ]}
            onChange={(c) => onSelectCollector(c)}
          />
        </CollectorSection>
      )}
      {addCollector && (
        <AddEditCollectorSection>
          <Input
            name='firstName'
            placeholder='First Name'
            type='text'
            label='Collector First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            containerMargin='0.5rem 0'
          />
          <Input
            name='Last Name'
            label='Collector Last Name'
            placeholder='Last Name'
            type='text'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            containerMargin='0.5rem 0'
          />
          <Input
            name='collectorId'
            label='Collector ID'
            placeholder='ID Number'
            type='text'
            value={collectorId}
            onChange={(e) => setCollectorId(e.target.value)}
            containerMargin='0.5rem 0'
          />
          <DutchieInputWrapper>
            <label>Collector DOB</label>
            <DatePicker
              data-testid='select-collector_div_react-datepicker'
              selected={dob}
              onChange={(val: Date) => handleDateChange(val)}
            />
          </DutchieInputWrapper>
          <DutchieInputWrapper>
            <label>Collector Relationship</label>
            <Select
              automationId='select-collector_select_select-relationship'
              value={relationship}
              options={[
                { label: '', value: '' },
                { label: 'Parent/Legal Guardian', value: 'PLG' },
                { label: 'Caregiver', value: 'CAREGIVER' },
              ]}
              onChange={(val: string) => setRelationship(val)}
            />
          </DutchieInputWrapper>
        </AddEditCollectorSection>
      )}
      <Buttons>
        <Button
          automationId='select-collector_button_selectexisting-addnew'
          type='button'
          onClick={() => {
            onSelectCollector(null);
            setAddCollector(!addCollector);
          }}
          secondary
        >
          {addCollector ? 'Select Existing' : 'Add New Collector'}
        </Button>
        <Button
          automationId='select-collector_button_edit'
          type='button'
          onClick={() => onCollectorEdit()}
          disabled={!selectedCollector || addCollector}
          secondary
        >
          Edit Collector
        </Button>
        <Spacer />
        <Button
          automationId='select-collector_button_continue'
          type='button'
          disabled={!isValid()}
          onClick={selectCustomerCollector}
        >
          Continue
        </Button>
      </Buttons>
    </Popup>
  );
};
