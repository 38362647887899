import { useQuery } from '@tanstack/react-query';

import { post } from 'api/HttpHelpers';

import type { QueryKey } from '@tanstack/react-query';

const GET_ROOMS = 'v2/room/get-rooms';

type RoomPayload = {
  RoomId?: number;
};

export const roomsQueryKey: QueryKey = ['rooms'];

export type Room = {
  CheckOutRoom?: string;
  CultivationStage?: string;
  DispensaryLimitedRoom?: string;
  DispensaryRestrictedRoom?: string;
  EcommerceRoom: string;
  ExamRoom?: string;
  ExternalId?: string;
  InspectionRoom?: string;
  InventoryRoom: string;
  IsMsuOwned: boolean;
  LocationType?: string;
  MedicationRoom?: string;
  MenuList: [];
  PosRoom: string;
  PreOrderRoom?: string;
  PublishToMenus: boolean;
  QuarantineRoom?: string;
  RoomArea?: number;
  RoomAreaUnits?: string;
  RoomId: number;
  RoomName: string;
  RoomPurpose?: string;
  TerminalDetails: { RoomId: number; TerminalId: number; TerminalName: string }[];
  Terminals: [];
  VaultRoom?: string;
  WaitingRoom: string;
  IsDeliveryStagingRoom: boolean;
};

export function useRoomsQuery({ isEnabled = true }: { isEnabled: boolean }) {
  return useQuery({
    queryKey: roomsQueryKey,
    enabled: isEnabled,
    queryFn: () =>
        post<Room[]>(GET_ROOMS, {}),
  });
}
