import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { getFeatureSwitchesKeys } from './query-key-factory';
import { FeatureSwitch, FeatureSwitchResponse } from './types';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const GET_FEATURE_SWITCHES = 'payments-hub/get-feature-switches';
const QUERY_REFETCH_INTERVAL = 1000 * 60 * 3; // 3 minutes
export const LD_FLAG_FEATURE_SWITCH_PAYMENT_BUTTONS = 'fintech.payment-processing.use-internal-feature-switches';

export const useFeatureSwitchesQuery = () => {
  const ldClient = useLDClient();
  const launchDarklyFlag = ldClient?.variation(LD_FLAG_FEATURE_SWITCH_PAYMENT_BUTTONS, false);

  return useQuery({
    queryKey: getFeatureSwitchesKeys.all,
    queryFn: async () => {
      const response = await post<FeatureSwitchResponse>(GET_FEATURE_SWITCHES, {}, { ignoreFormat: true }).catch(() => {
        // Ensure that when an errant response is returned, all provisioned tender methods are enabled by default
        return { Data: [], Result: false, Message: 'Error fetching feature switches' };
      });
      const output: { [Id: string]: FeatureSwitch } = {};

      // Handle malformed or errant data
      if (!response.Data || !response.Result) {
        return output;
      }

      // Restructure the response data to be index on payment type keys
      response.Data.forEach((item: FeatureSwitch) => {
        const { Key } = item;
        output[Key] = item;
      });

      return output;
    },
    refetchInterval: QUERY_REFETCH_INTERVAL,
    enabled: launchDarklyFlag,
  });
};
