import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { logger, customEventKeys } from 'util/logger';
import { handleLogout } from 'store/actions/UserActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useRageClickDetector } from 'util/hooks/useRageClickDetector';
import { usePinLoginActive } from 'util/hooks/pin-login/usePinLoginActive';

export type UseNavigationHeaderParams = {
  onOpenDrawer: () => void;
  onOpenHint: () => void;
};

export type UseNavigationHeaderReturn = {
  ref: React.RefObject<HTMLDivElement>;
  isPinLoginActive: boolean;
  companyName: string;
  locationName: string;
  registerName: string;
  userName: string;
  handleMenuButtonClick: () => void;
  handleLogOutClick: () => void;
};

export function useNavigationHeader(params: UseNavigationHeaderParams): UseNavigationHeaderReturn {
  const { onOpenDrawer, onOpenHint } = params;

  // refs
  const ref = useRef<HTMLDivElement>(null);

  // hooks
  const history = useHistory();
  const dispatch = useAppDispatch();

  // global state
  const isPinLoginActive = usePinLoginActive();
  const companyName = useAppSelector((state) => state.user.selectedLsp?.LspName) ?? 'Company';
  const locationName = useAppSelector((state) => state.user.selectedLocation?.location_name) ?? 'Location';
  const registerName = useAppSelector((state) => state.settings.selectedRegister?.label) ?? 'Register';
  const userName = useAppSelector((state) => state.user.FullName) ?? 'User';

  // handlers
  const handleLogOutClick = () => {
    dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));
  };

  const handleMenuButtonClick = () => {
    onOpenDrawer();
  };

  const handleRageClick = () => {
    onOpenHint();
    logger.warn(`navigation header context attributes rage click`, {
      key: customEventKeys.navigation.header.contextAttributesRageClick,
    });
  };

  useRageClickDetector({ elementRef: ref, onRageClick: handleRageClick });

  return {
    ref,
    isPinLoginActive,
    companyName,
    locationName,
    registerName,
    userName,
    handleMenuButtonClick,
    handleLogOutClick,
  };
}
