import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as DOMPurify from 'dompurify';

import { JournalEntry } from 'models/Journal';
import { colors } from 'css/Theme';
import { Button } from 'components/buttons';
import { Input } from 'components/inputs/Input';
import { TextArea } from 'components/inputs/TextArea';
import { Label } from 'components/text';

const JournalTextAreaLabel = styled(Label)`
  margin-top: 24px;
`;
const JournalTextArea = styled(TextArea)`
  padding-left: 20px;
  padding-top: 20px;
  border: 1px solid ${colors.dutchie.borderGrey};
`;

const FormContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 16px;
  width: 100%;
`;

const SubjectInput = styled(Input)`
  width: 100%;
  height: 48px;
  padding-left: 20px;
  border: 1px solid ${colors.dutchie.borderGrey};
`;

const ButtonsContainerDiv = styled.div`
  display: block;
  margin-top: 16px;

  button + button {
    margin-left: 16px;
  }
`;

const CreateButton = styled(Button)`
  padding: 12px 20px;
`;

type JournalFormProps = {
  className?: string;
  disabled?: boolean;
  entry?: JournalEntry;
  rows?: number;
  onSubmit: (subject: string, note: string) => void;
};

export const JournalForm: FC<JournalFormProps> = ({ className, entry, disabled, rows, onSubmit }) => {
  const [subject, setSubject] = useState('');
  const [note, setNote] = useState('');
  const doneLabel = entry ? 'Update' : 'Create';
  const hasEmptyFields = !subject.length || !note.length;

  useEffect(() => {
    setSubject(entry?.NoteSubject ?? '');
    setNote(entry?.Note ?? '');
  }, [entry]);

  const onClick = () => {
    const cleanNote = DOMPurify.sanitize(note);
    onSubmit(subject, cleanNote);
    setSubject('');
    setNote('');
  };

  return (
    <FormContainerDiv className={className}>
      <SubjectInput placeholder='...' value={subject} label='Subject' onChange={(e) => setSubject(e.target.value)} />
      <JournalTextAreaLabel>Journal Notes</JournalTextAreaLabel>
      <JournalTextArea
        rows={rows ?? 6}
        placeholder='Write something...'
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <ButtonsContainerDiv>
        <CreateButton disabled={disabled || hasEmptyFields} onClick={onClick}>
          {doneLabel}
        </CreateButton>
      </ButtonsContainerDiv>
    </FormContainerDiv>
  );
};
