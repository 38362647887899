import { useState } from 'react';

import { getInitialNumberValue } from '../helpers';
import { registerTransfer } from 'api/SettingsApi';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { usePopup } from 'components/popups';
import { useTransactionAdjustmentReasonsQuery } from 'queries/posv3/maintenance/get-txn-adjustment-reasons';

import type { TransferProps } from './Transfer';

export const useTransfer = ({ onTransfer, registerId }: TransferProps) => {
  const dispatch = useAppDispatch();

  const [cashCounted, setCashCounted] = useState(getInitialNumberValue());
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRegister, setSelectedRegister] = useState('');
  const [transactionAdjustmentReason, setTransactionAdjustmentReason] = useState('');

  const registers = useAppSelector((state) => state.settings.registers);
  const { isVisible: isConfirmPopupVisible, toggle: toggleConfirmPopup } = usePopup();
  const { data: transactionAdjustmentReasons } = useTransactionAdjustmentReasonsQuery();

  // Computed values

  const registerOptions = registers.map((register) => ({
    key: register.id,
    value: register.id,
    label: register.TerminalName,
  }));

  const transactionAdjustmentReasonOptions =
    transactionAdjustmentReasons?.map((reason) => ({
      key: reason.TransactionAdjustmentReasonId,
      value: reason.TransactionAdjustmentReasonId,
      label: reason.Reason,
    })) ?? [];

  // Handlers

  const handleTransfer = async () => {
    const toRegister = Number(selectedRegister);
    if (cashCounted.floatValue && toRegister) {
      try {
        setIsLoading(true);
        await registerTransfer({
          RegisterId: registerId,
          ToRegisterId: toRegister,
          TransactionAmt: cashCounted.floatValue,
          Comment: comment,
          TransactionAdjustmentReasonId: transactionAdjustmentReason ? Number(transactionAdjustmentReason) : undefined,
        });
        setCashCounted(getInitialNumberValue());
        setComment('');
        setTransactionAdjustmentReason('');
        setSelectedRegister('');
        onTransfer();
        dispatch(successNotification('Register transfer successful'));
        setIsLoading(false);
      } catch {
        dispatch(errorNotification('There was an error closing out the register'));
        setIsLoading(false);
      }
    }
  };

  const isTransferDisabled = !cashCounted.value.length || !selectedRegister.length || isLoading;

  return {
    cashCounted,
    comment,
    handleTransfer,
    isConfirmPopupVisible,
    isLoading,
    isTransferDisabled,
    registerOptions,
    selectedRegister,
    setCashCounted,
    setComment,
    setSelectedRegister,
    setTransactionAdjustmentReason,
    toggleConfirmPopup,
    transactionAdjustmentReason,
    transactionAdjustmentReasonOptions,
  };
};
