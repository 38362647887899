import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { buildBbox } from './utils';
import { State } from 'store';

export function useBounds() {
  const displayDeliveries = useSelector((state: State) => state.deliveryList.displayDeliveries);
  const dispensaryLat = useSelector((state: State) => state.settings.Lat);
  const dispensaryLng = useSelector((state: State) => state.settings.Lng);

  return useMemo(() => {
    const allCoords = displayDeliveries.filter(({ Lat, Lng }) => Boolean(Lat && Lng)).map(({ Lat, Lng }) => [Lng, Lat]);

    if (dispensaryLat && dispensaryLng) {
      allCoords.push([dispensaryLng, dispensaryLat]);
    }

    return buildBbox(allCoords);
  }, [displayDeliveries, dispensaryLat, dispensaryLng]);
}

