import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';
import { CashAmount } from 'components/text';
import { SidePanelSection } from 'components/layout';
import { roundChangeDue } from 'util/Helpers';

import { TotalPaymentButton } from '../TotalPaymentButton';
import { useTotalDetailsSection } from './useTotalDetailsSection';

type TotalDetailsSectionProps = {
  paymentInProgress: boolean | undefined;
  imgRef: React.RefObject<HTMLInputElement>;
  children?: React.ReactNode;
};

export const TotalDetailsSection = ({ paymentInProgress, imgRef, children }: TotalDetailsSectionProps) => {
  const { addPaymentBtnVisible, amountPortalContainerRef, cart, checkout, features, guest, totalPaid } =
    useTotalDetailsSection();

  if (!guest) {
    return null;
  }

  return (
    <SidePanelSection flex sticky>
      <TotalsSummary>
        <TotalRow>
          <div>Order Total</div>
          <CashAmount automationId='index_cash-amount_total' value={cart?.GrandTotalRounded} />
        </TotalRow>
        {paymentInProgress && (
          <>
            <Row>
              <div>Payment</div>
              <CashAmount automationId='index_cash-amount_paid' value={totalPaid} />
            </Row>
            {checkout.totalRemaning > 0 && (
              <Row>
                <div>Remaining</div>
                <RedText>
                  <CashAmount automationId='index_cash-amount_remaining' value={checkout.totalRemaning} />
                </RedText>
              </Row>
            )}
            {checkout.changeDue > 0 && (
              <Row>
                <div>Change</div>
                <GreenText>
                  <CashAmount
                    automationId='index_cash-amount_change'
                    value={roundChangeDue(features, checkout.changeDue)}
                  />
                </GreenText>
              </Row>
            )}
          </>
        )}
      </TotalsSummary>

      {/* this div is a Portal target, content is rendered from PaymentOption component when `addPaymentBtnVisible` is true */}
      <div ref={amountPortalContainerRef} />

      {!addPaymentBtnVisible && !children && <TotalPaymentButton imgRef={imgRef} />}
      {children}
    </SidePanelSection>
  );
};

const TotalsSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  margin-bottom: 1rem;
`;

const RedText = styled.div`
  color: ${colors.dutchie.red};
`;

const GreenText = styled.div`
  color: ${colors.dutchie.green};
`;

const BaseFontStyles = css`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 0.005em;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  ${BaseFontStyles}

  & > :first-child {
    margin-right: auto;
  }
`;

const TotalRow = styled(Row)`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`;
