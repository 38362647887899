import React from 'react';

const originalDimensions = {
  width: 17,
  height: 17,
};

type FilterIconProps = {
  width?: number;
  height?: number;
};

export const FilterIcon = ({
  width = originalDimensions.width,
  height = originalDimensions.height,
}: FilterIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M4.33333 6.42669C5.25381 6.42669 6 5.69144 6 4.78446C6 3.87748 5.25381 3.14223 4.33333 3.14223C3.41286 3.14223 2.66667 3.87748 2.66667 4.78446C2.66667 5.69144 3.41286 6.42669 4.33333 6.42669ZM4.33333 8.06891C6.17428 8.06891 7.66667 6.59841 7.66667 4.78446C7.66667 2.9705 6.17428 1.5 4.33333 1.5C2.49238 1.5 1 2.9705 1 4.78446C1 6.59841 2.49238 8.06891 4.33333 8.06891Z'
          fill='#464F53'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M11.6667 10.5733C10.7462 10.5733 10 11.3086 10 12.2155C10 13.1225 10.7462 13.8578 11.6667 13.8578C12.5871 13.8578 13.3333 13.1225 13.3333 12.2155C13.3333 11.3086 12.5871 10.5733 11.6667 10.5733ZM11.6667 8.93108C9.82572 8.93108 8.33333 10.4016 8.33333 12.2155C8.33333 14.0295 9.82572 15.5 11.6667 15.5C13.5076 15.5 15 14.0295 15 12.2155C15 10.4016 13.5076 8.93108 11.6667 8.93108Z'
          fill='#464F53'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M6 4.78446C6 4.24027 6.44772 3.79912 7 3.79912H13.6667C14.219 3.79912 14.6667 4.24027 14.6667 4.78446C14.6667 5.32864 14.219 5.76979 13.6667 5.76979H7C6.44772 5.76979 6 5.32864 6 4.78446Z'
          fill='#464F53'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10 12.2155C10 12.7597 9.55229 13.2009 9 13.2009L2.33333 13.2009C1.78105 13.2009 1.33333 12.7597 1.33333 12.2155C1.33333 11.6714 1.78105 11.2302 2.33333 11.2302H9C9.55229 11.2302 10 11.6714 10 12.2155Z'
          fill='#464F53'
        />
      </g>
    </svg>
  );
};
