import React, { PropsWithChildren, createContext } from 'react';

import { useScaleSettingsProvider } from '../hooks/useScaleSettingsProvider';
import { ScaleSettingsContextData } from '../types';

export const ScaleSettingsContext = createContext<ScaleSettingsContextData>({} as ScaleSettingsContextData);

export const ScaleSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useScaleSettingsProvider();

  return <ScaleSettingsContext.Provider value={data}>{children}</ScaleSettingsContext.Provider>;
};
