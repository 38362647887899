import { post } from 'api/HttpHelpers';
import {
    AddSpringBigOfferRequest,
    AddSpringBigRewardRequest,
    RemoveRewardRequest,
    SpringBigInfo,
} from 'models/SpringBig';
import { logger, customEventKeys } from 'util/logger';

export const getSpringBigInfo = (AcctId: number, ShipmentId: number) =>
  post<{ Data?: SpringBigInfo }>('Springbig/get-offers-rewards', { AcctId, ShipmentId }, { ignoreFormat: true });
export const addSpringBigReward = (body: AddSpringBigRewardRequest) => {
  logger.debug<{ body: AddSpringBigRewardRequest }>('Applying SpringBig Reward', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('Springbig/apply-reward', body);
};
export const removeSpringBigReward = (body: RemoveRewardRequest) => {
  logger.debug<{ body: RemoveRewardRequest }>('Removing SpringBig Reward', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('Springbig/remove-redemption', body);
};
export const addSpringBigOffer = (body: AddSpringBigOfferRequest) => {
  logger.debug<{ body: AddSpringBigOfferRequest }>('Applying SpringBig Offer', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('Springbig/apply-offer', body);
};
