import React, { FC, useEffect, useState } from 'react';
import { cfdRequestState, useCfdInitEvent, useUpdateCartEvent } from 'util/hooks/android/useAndroidCfdPlugin';
import { CFDProps, CustomerFacingDisplay } from '../CustomerFacingDisplay';

/**
 * Data loader for Android version of CFD
 * @returns CustomerFacingDisplay
 */
export const AndroidCustomerFacingDisplay: FC = () => {
  const [cfdProps, setCfdProps] = useState<CFDProps>({ features: {} });

  useCfdInitEvent(({ details: sessionData }) => {
    const { locationName, loggedIn, features, customIdleImage } = sessionData;
    setCfdProps((curState) => ({
      ...curState,
      locationName,
      loggedIn,
      features,
      customIdleImage,
    }));
  });

  useUpdateCartEvent(({ details: cartData }) => {
    const { cart, checkoutComplete, changeDue } = cartData;
    setCfdProps((curState) => ({
      ...curState,
      cart,
      checkoutComplete,
      changeDue,
    }));
  });

  useEffect(() => {
    cfdRequestState();
  }, []);

  return <CustomerFacingDisplay {...cfdProps} />;
};
