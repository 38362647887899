import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { ConfirmationPopup } from 'components/popups';
import { callback, callback1 } from 'models/Misc';
import { colors } from 'css/Theme';
import { Input } from 'components/inputs';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';

type ConfirmPopupProps = {
  hide: callback;
  message: string;
  onConfirm: callback1<string>;
  pinRequired?: boolean;
  title: string;
};

export const ConfirmTransactionPopup: FC<ConfirmPopupProps> = ({ hide, message, onConfirm, pinRequired, title }) => {
  const [pin, setPin] = useState('');
  const disabled = pinRequired && !pin.length;

  const onContinue = () => {
    hide();
    onConfirm(pin);
  };

  return (
    <ConfirmationPopup
      hide={hide}
      title={title}
      confirm={{
        text: 'Continue',
        onClick: onContinue,
        disabled,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
      isVisible
    >
      <Container>
        <Message>{message}</Message>
        {pinRequired && (
          <Input
            placeholder='Enter your PIN...'
            startAdornment={<KeySvg />}
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            type='password'
          />
        )}
      </Container>
    </ConfirmationPopup>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0 1.5rem;
`;

const Message = styled.div`
  color: ${colors.dutchie.gray90};
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
`;

const KeySvg = styled(KeyIcon)`
  margin: 0.25rem;
  color: ${rgba(colors.realBlack, 0.25)};
`;
