import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from 'css/Theme';

export type StyleProps = {
  secondary?: boolean;
  title?: string;
};

export type NavButtonProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: ReactNode;
  automationId?: string;
  disabled?: boolean;
  margin?: string;
} & StyleProps;

export const NavButton: FC<NavButtonProps> = ({
  children,
  icon,
  onClick,
  disabled = false,
  automationId,
  margin,
  ...styleProps
}) => {
  return (
    <StyledNavButton
      {...styleProps}
      onClick={onClick}
      icononly={!!icon && !children}
      data-testid={automationId}
      disabled={disabled}
      margin={margin}
      type='button'
    >
      {icon}
      {children}
    </StyledNavButton>
  );
};

type StyledNavButtonProps = {
  icononly: boolean;
  margin?: string;
} & StyleProps;

export const StyledNavButton = styled.button.attrs<StyledNavButtonProps>((props) => ({
  className: `${props.secondary ? 'secondary' : 'primary'}`,
  title: props.title,
}))<StyledNavButtonProps>`
  background-color: ${colors.dutchie.primaryWhite};
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 6px;
  box-sizing: border-box;
  color: ${colors.dutchie.darkGrey};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  min-width: ${({ icononly }) => (icononly ? '3rem' : '3.125rem')};
  overflow: hidden;
  padding: ${({ icononly }) => (icononly ? '0.875rem 1rem' : '0.75rem 1.5rem')};
  margin: ${({ margin }) => margin || '0 0.375rem'};
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  gap: 0.5rem;
  justify-content: center;
  height: 45px;

  svg,
  img {
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
  }

  &:hover {
    background-color: ${colors.dutchie.backgroundGrey};
  }
  &:focus {
    background-color: ${colors.dutchie.primaryWhite};
    color: ${colors.dutchie.almostBlack};
    outline: none;
  }

  &:active {
    opacity: 1;
    outline: none;
  }

  &:disabled {
    border: 1px solid ${colors.dutchie.borderGrey};
    color: ${colors.dutchie.borderGrey};
    opacity: 0.5;
    pointer-events: none;
  }

  ${breakpoints.wideTablet.maxQuery} {
    font-size: 14px;
    padding: ${({ icononly }) => (icononly ? '15px' : '12px 20px')};
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
`;
