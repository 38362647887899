import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { colors } from 'css/Theme';
import { IdentificationImage } from 'pages/EditCustomerPage/components/IdentificationImage';
import { ReactComponent as _EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as _DeleteIcon } from 'assets/icons/delete.svg';
import { EditIdentificationTypeEnum, CustomerIdentification } from 'models/Customer';
import { usePopup } from 'components/popups';
import { RemoveIdentificationPopup } from 'pages/EditCustomerPage/components/RemoveIdentificationPopup';
import { EditIdentificationPopup } from 'pages/EditCustomerPage/components/EditIdentificationPopup';

import type { SecondaryIdentification } from 'queries/v2/guest/types';

type IdentificationProps =
  | { type: EditIdentificationTypeEnum.Primary; id: CustomerIdentification; guestId: number }
  | { type: EditIdentificationTypeEnum.Secondary; id: SecondaryIdentification; guestId: number };

type DisplayValues = {
  label: string;
  image: string;
  number?: string;
  expiration?: string;
};

export const Identification: FC<IdentificationProps> = (props) => {
  const [displayValues, setDisplayValues] = useState<DisplayValues>();
  const { isVisible: removePopupVisible, toggle: toggleRemovePopup } = usePopup();
  const { isVisible: editPopupVisible, toggle: toggleEditPopup } = usePopup();

  useEffect(() => {
    if (props.type === EditIdentificationTypeEnum.Primary) {
      setDisplayValues({
        label: 'MMJ ID',
        image: props.id.image_url,
        number: props.id.number,
        expiration: props.id.ExpirationDate,
      });
    } else {
      setDisplayValues({
        label: props.id.name,
        image: props.id.image,
        number: props.id.stateId,
      });
    }
  }, [props, setDisplayValues]);

  if (!displayValues) {
    return null;
  }

  return (
    <>
      {props.type === EditIdentificationTypeEnum.Secondary && (
        <RemoveIdentificationPopup
          isVisible={removePopupVisible}
          hide={toggleRemovePopup}
          guestId={props.guestId}
          id={props.id}
        />
      )}
      <EditIdentificationPopup isVisible={editPopupVisible} hide={toggleEditPopup} {...props} />
      <IdentificationDiv data-testid='customer-profile_identification'>
        <IdLabel data-testid='customer-profile_identification_label'>{displayValues.label}</IdLabel>
        <IdentificationImage imageUrl={displayValues.image} />
        <FieldsDiv>
          {displayValues.number && (
            <FieldGroupSpan>
              <FieldLabel>Number:</FieldLabel>
              <FieldSpan data-testid='customer-profile_identification_number'>{displayValues.number}</FieldSpan>
            </FieldGroupSpan>
          )}
          {displayValues.expiration && (
            <FieldGroupSpan>
              <FieldLabel>Expiration:</FieldLabel>
              <FieldSpan data-testid='customer-profile_identification_expiration'>{displayValues.expiration}</FieldSpan>
            </FieldGroupSpan>
          )}
          <ButtonsDiv>
            <EditIcon onClick={toggleEditPopup} data-testid='customer-profile_identification_edit-button' />
            {props.type === EditIdentificationTypeEnum.Secondary && (
              <DeleteIcon onClick={toggleRemovePopup} data-testid='customer-profile_identification_delete-button' />
            )}
          </ButtonsDiv>
        </FieldsDiv>
      </IdentificationDiv>
    </>
  );
};

const IdentificationDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  max-width: 27rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const IdLabel = styled.label`
  font-size: 1rem;
  color: ${colors.black};
  height: 1rem;
  line-height: 24px;
  letter-spacing: 0.5%;
  display: block;
  margin-bottom: 1rem;
`;

const FieldsDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.25rem;
`;

const FieldGroupSpan = styled.span`
  margin-right: 1rem;
`;

const FieldLabel = styled.label`
  font-size: 0.75rem;
  color: ${rgba(colors.black, 0.5)};
  margin-right: 0.25rem;
`;

const FieldSpan = styled.span`
  font-size: 0.75rem;
`;

const ButtonsDiv = styled.div`
  display: flex;
  flex: 1 0;
  justify-content: flex-end;
  align-items: center;
`;

const EditIcon = styled(_EditIcon)`
  flex: 0 0 1rem;
  cursor: pointer;
`;

const DeleteIcon = styled(_DeleteIcon)`
  flex: 0 0 1rem;
  color: ${colors.dutchie.red};
  margin-left: 0.5rem;
  cursor: pointer;
`;
