import React, { FC, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentType } from 'models/Checkout';
import { v4 as uuidv4 } from 'uuid';
import { addPaymentMethod } from 'store/actions/CheckoutActions';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { PaymentOption } from '../PaymentOption';
import { CheckoutContext } from '../CheckoutContext';

export const PaymentCheck: FC = () => {
  const dispatch = useDispatch();
  const { setSelectedPaymentType } = useContext(CheckoutContext);

  const onAddCheck = useMemo(
    () => (amount: number) => {
      if (amount <= 0) {
        dispatch(errorNotification('Invalid check amount.'));
        return;
      }

      dispatch(addPaymentMethod({ id: uuidv4(), name: 'Check', type: PaymentType.Check, amount }));
      dispatch(successNotification('Check Payment added'));
      setSelectedPaymentType(null);
    },
    [dispatch, setSelectedPaymentType]
  );

  return <PaymentOption onAddAmount={onAddCheck} />;
};
