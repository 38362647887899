import { post } from 'api/HttpHelpers';
import { AppliedFeeDonation, ApplyFeeDonationRequest } from 'models/FeeDonation';
import { logger, customEventKeys } from 'util/logger';

export const getAppliedFeeDonation = (ShipmentId?: number) => {
  return post<Array<AppliedFeeDonation>>('v2/fee-donation/get-shipment-fees-donations', { ShipmentId });
};

export const applyFeeDonation = (body: ApplyFeeDonationRequest) => {
  logger.debug<{ body: ApplyFeeDonationRequest }>('Applying Fee or Donation', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('v2/fee-donation/apply-fee-donation', body);
};

export const removeAppliedFeeDonation = (AppliedFeeDonationId: number, ShipmentId?: number) => {
  logger.debug<{ body: { AppliedFeeDonationId: number; ShipmentId?: number } }>('Removing Fee or Donation', {
    key: customEventKeys.postRequest,
    body: { AppliedFeeDonationId, ShipmentId },
  });

  return post('v2/fee-donation/remove-fee-donation', {
    AppliedFeeDonationId,
    ShipmentId,
  });
};
