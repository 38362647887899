import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { useMemo } from 'react';
import { RegionList } from 'util/RegionList';
import { isAndroid } from 'util/hooks';

export type LDServerMigrationConfig = {
  deadlineDateLabel: string;
  dayBeforeDeadline: string;
  shouldShowBlockingUI: boolean;
  registerURL: string;
  useUpdatedMessaging?: boolean;
};

const MigrationFlag = 'pos.platform.database-migration-configs.operational';

export function useServerMigrationConfig(): LDServerMigrationConfig {
  const ldClient = useLDClient();
  const serverMigrationConfig: LDServerMigrationConfig = ldClient?.variation(MigrationFlag, {});
  const inAppServerMigrationConfig = useSelector((state: State) => state.settings.serverMigrationConfig);
  const currentRegion = useSelector((state: State) => state.settings.region);
  const registerURL = serverMigrationConfig?.registerURL || inAppServerMigrationConfig?.registerURL;
  const deadlineDateLabel =
    serverMigrationConfig?.deadlineDateLabel || inAppServerMigrationConfig?.deadlineDateLabel || '';
  const dayBeforeDeadline =
    serverMigrationConfig?.dayBeforeDeadline || inAppServerMigrationConfig?.dayBeforeDeadline || '';
  const useUpdatedMessaging =
    serverMigrationConfig?.useUpdatedMessaging || inAppServerMigrationConfig?.useUpdatedMessaging || false;

  const newRegion: string | undefined = useMemo(() => {
    if (registerURL) {
      return Object.keys(RegionList).find((key) => RegionList[key].includes(registerURL));
    }
    return '';
  }, [registerURL]);

  if (currentRegion === newRegion || (!currentRegion && isAndroid)) {
    return {
      deadlineDateLabel: '',
      dayBeforeDeadline: '',
      shouldShowBlockingUI: false,
      registerURL: '',
      useUpdatedMessaging: false,
    };
  }

  return {
    deadlineDateLabel,
    dayBeforeDeadline,
    useUpdatedMessaging,
    shouldShowBlockingUI:
      serverMigrationConfig?.shouldShowBlockingUI || inAppServerMigrationConfig?.shouldShowBlockingUI || false,
    registerURL: serverMigrationConfig?.registerURL || inAppServerMigrationConfig?.registerURL || '',
  };
}
