import { QueryClient } from '@tanstack/react-query';
import { logger } from 'util/logger';

let GLOBAL_QUERY_CLIENT: QueryClient;

export const initializeReactQueryClient = () => {
  GLOBAL_QUERY_CLIENT = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
        onError: (error: unknown) => {
          logger.error(error, { type: 'react-query-error' });
        },
      },
    },
  });

  return GLOBAL_QUERY_CLIENT;
};

export const getQueryClient = () => GLOBAL_QUERY_CLIENT;
