import { LabDataMapping } from 'models/Labs';
import { post } from 'api/HttpHelpers';

export const LoadLabResultInventory = async (BatchId?: number) => {
  const ret = await post<Array<Record<string, number>>>('lab/LoadLabResultInventory', { BatchId });
  if (ret.length > 0) {
    return ret[0];
  } else {
    return {};
  }
};

export const GetLabDataFieldMapping = () => {
  return post<Array<LabDataMapping>>('lab/GetLabDataFieldMapping');
};
