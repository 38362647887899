import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { CartItem } from 'models/Cart';
import { errorNotification } from 'store/actions/NotificationsActions';
import { SettingsState } from 'store/reducers/SettingsReducer';
import { printToLocalPrinter } from './printToLocalPrinter';
import { printToNetworkPrinter } from './printToNetworkPrinter';
import { PrintLabelsActionArgs } from './types';

type PrintLabelsWithLabelIdFromSettingsArgs = {
  args: PrintLabelsActionArgs;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  itemsToPrint: CartItem[];
  settings: SettingsState;
};

export async function printLabelsWithLabelIdFromSettings({
  args,
  dispatch,
  itemsToPrint,
  settings,
}: PrintLabelsWithLabelIdFromSettingsArgs): Promise<void> {
  const labelId = settings.userSettings.selectedLabel?.id;

  if (!labelId) {
    dispatch(errorNotification(`Error Printing Label: No label selected`));
    return Promise.reject('No label selected');
  }

  const packagesToPrint = itemsToPrint.map((item) => ({
    ShipmentId: args.transactionId ?? args.guest.ShipmentId,
    AllocatedInventoryId: item.InventoryId,
    Count: args.printAll ? item.QtySelected : 1,
    SerialNumber: item.SerialNo,
    PackageItemId: item.PackageItemId,
    PackageQuantity: item.WgtCnt === 'Wgt' ? (item.Grams ?? 0) / item.QtyAllocated : 1,
    BatchId: item.BatchId,
  }));

  const params = {
    BatchId: args.guest.ShipmentId,
    LabelId: labelId,
    LabelType: 'ProductProd',
    PackagesToPrint: packagesToPrint,
    PrinterId: settings.userSettings.selectedLabelPrinter?.PrinterId,
    ShipmentId: args.guest.ShipmentId,
  };

  if (settings.userSettings.selectedLabelPrinter?.LocalPrinter) {
    await printToLocalPrinter({
      dispatch,
      params,
      localPrinterId: settings.userSettings.selectedLabelPrinter?.PrinterId.toString(),
    });
  } else {
    if (!params.PrinterId) {
      dispatch(errorNotification(`Error Printing Label: No printer selected`));
      return Promise.reject('No printer selected');
    }

    await printToNetworkPrinter({ dispatch, params });
  }
}
