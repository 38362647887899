import { FormControlLabel as MUIFormControlLabel, FormGroup as MUIFormGroup } from '@mui/material';
import React from 'react';

import { FormControl } from '../form-control';
import {
  RebrandFormContainer,
  RebrandFormSection,
  RebrandFormTitle,
  StyledFormFieldContainer,
  StyledFormFieldSubContent,
} from './form-rebrand.styles';

export const FormGroup = MUIFormGroup;
export const FormControlLabel = MUIFormControlLabel;

type FormContainerProps = {
  children: React.ReactNode;
  className?: string;
};

// This should wrap the entire form (Dividers, Button, FormSections, FormTitles, etc)
export function FormContainer({ children, className = '' }: FormContainerProps): JSX.Element {
  return <RebrandFormContainer className={`${className} form-form-container`}>{children}</RebrandFormContainer>;
}

type FormSectionProps = {
  children: React.ReactNode;
  className?: string;
};

// This should wrap only groups/sections of form fields (Input, Select, Toggle, etc)
export function FormSection({ children, className, ...other }: FormSectionProps): JSX.Element {
  return (
    <RebrandFormSection {...other} className={`${className} form-form-section`}>
      {children}
    </RebrandFormSection>
  );
}

type FormTitleProps = {
  children: React.ReactNode;
  className?: string;
  noMargin?: boolean;
  styleOverrides?: unknown;
};

export function FormTitle({ children, className = '', noMargin, styleOverrides = {} }: FormTitleProps): JSX.Element {
  return (
    <RebrandFormTitle $overrides={styleOverrides} className={useRebrandStyles ? '' : className} noMargin={noMargin}>
      {children}
    </RebrandFormTitle>
  );
}

type FormFieldContainerProps = {
  children: React.ReactNode;
  className?: string;
  subContent?: React.ReactNode | string;
};

export function FormFieldContainer({ children, className = '', subContent }: FormFieldContainerProps): JSX.Element {
  return (
    <StyledFormFieldContainer className={className}>
      {children}
      {subContent && (
        <FormControl>
          <StyledFormFieldSubContent>{subContent}</StyledFormFieldSubContent>
        </FormControl>
      )}
    </StyledFormFieldContainer>
  );
}
