import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { LoadingButton } from 'components/buttons';

import { MigrationIcon } from './MigrationIcon';
import * as PosApi from 'api/PosApi';
import { store, State } from 'store';
import { useServerMigrationConfig } from './useServerMigrationConfig';
import {
  Text,
  CopyableURLContainer,
  DisplayURL,
  LargeButton,
  Link,
  LinksSection,
  SmallButton,
  StyledHeading,
  TextSection,
} from './ServerMigrationPromptModal';
import { successNotification } from 'store/actions/NotificationsActions';

import { handleLogout } from 'store/actions/UserActions';
import { useHistory } from 'react-router';

export function FullPageMigrationTakeover() {
  const { registerURL, useUpdatedMessaging } = useServerMigrationConfig();
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const permissions = useSelector((state: State) => state.settings.permissions);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCopyURLToClipboard = async () => {
    await navigator.clipboard.writeText(registerURL);
    dispatch(successNotification('URL copied to clipboard'));
  };

  async function handleTestingOnlyButton() {
    setIsLoading(true);

    window.sessionStorage.setItem('LspId', '1062');
    window.sessionStorage.setItem('LocId', '1141');

    await PosApi.updateDefaultLsp(1062);
    await PosApi.updateDefaultLocation(1141);

    await dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));
    setIsLoading(false);
    window.location.reload();
  }

  return (
    <Container>
      <Box>
        <MigrationIcon />
        <TextSection>
          <StyledHeading>This URL has expired</StyledHeading>
          <Text>
            This URL has been disabled. Please click below to access and log into your new URL.{' '}
            {!useUpdatedMessaging && 'Visit the help center to learn more.'}
          </Text>
        </TextSection>
        <CopyableURLContainer>
          <DisplayURL>{registerURL}</DisplayURL>
          <SmallButton onClick={handleCopyURLToClipboard}>Copy</SmallButton>
        </CopyableURLContainer>
        <LinksSection>
          <LargeButton onClick={() => (window.location.href = registerURL)}>Go to new URL</LargeButton>
          {!useUpdatedMessaging && (
            <Link onClick={() => window.open('https://support.dutchie.com/hc/en-us/articles/28929066112147')}>
              Visit help center
            </Link>
          )}
        </LinksSection>
      </Box>
      {permissions?.SuperUser && (
        <TESTONLYBUTTON onClick={handleTestingOnlyButton} loading={isLoading}>
          (for testing purposes only) Take me back to LL Training Co
        </TESTONLYBUTTON>
      )}
    </Container>
  );
}

export const TESTONLYBUTTON = styled(LoadingButton)`
  position: absolute;
  bottom: 30px;
  right: 30px;
`;

export const Container = styled.div`
  background-color: #f5f6f7;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  width: 600px;
  height: auto;
  padding: 32px;
  background-color: #fff;
  border: 1px #d2d7da solid;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
