import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';

import { store, State } from 'store';
import { CheckedInGuest } from 'models/Guest';
import { ConfirmationPopup } from 'components/popups';
import { cancelGuest, getGuests } from 'store/actions/GuestListActions';
import { Select } from 'components/inputs';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';
import { useEnforceSelectingCancellationReason } from 'util/hooks/launch-darkly/useEnforceSelectingCancellationReason';

type CancelTransactionProps = {
  hide: () => void;
  guest: Pick<CheckedInGuest, 'Guest_id' | 'ShipmentId' | 'ScheduleId' | 'FullName' | 'DeliveryStatus'>;
  onCancel?: () => void;
};

export const CancelTransaction: FC<CancelTransactionProps> = ({ hide, guest, onCancel }) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();

  const cancelReasons = useSelector((state: State) => state.settings.cancellationReasons);
  const [selectedCancelReason, setSelectedCancelReason] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const useMETRC = useSelector((state: State) => state.settings.integrations?.UseMETRC);
  const warnOutForDelivery = useMETRC && guest.DeliveryStatus === 'Out For Delivery';

  const isSmartCheckinActive = useSmartCheckinActive();

  const isEnforceSelectingCancellationReasonEnabled = useEnforceSelectingCancellationReason();
  const isConfirmationButtonDisabled = cancelReasons && isEnforceSelectingCancellationReasonEnabled ? cancelReasons?.length > 0 && !selectedCancelReason : false;

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title={`Cancel Transaction`}
      confirm={{
        text: 'Confirm',
        loading: loading,
        disabled: isConfirmationButtonDisabled,
        onClick: async () => {
          setLoading(true);
          dispatch(
            cancelGuest({
              guestId: guest.Guest_id,
              shipmentId: guest.ShipmentId,
              scheduleId: guest.ScheduleId,
              cancelReason: selectedCancelReason || '',
            })
          ).then(() => {
            if (onCancel) {
              onCancel();
            } else {
              if (!isSmartCheckinActive) {
                dispatch(getGuests());
              }

              if (history.location.pathname !== '/guestlist') {
                history.push('/guestlist');
              }
            }

            setLoading(false);
            hide();
          });
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      <ContentDiv>
        <Message>
          Are you sure you want to cancel transaction #{guest.ShipmentId} from {guest.FullName}?
        </Message>

        {warnOutForDelivery && (
          <Message>
            <br />
            Warning: This transaction has been marked 'Out For Delivery' in METRC and will need to be cancelled in METRC
            as well.
          </Message>
        )}

        {!isEmpty(cancelReasons) && (
          <CancelSelect
            defaultValue={selectedCancelReason}
            options={
              !cancelReasons
                ? []
                : cancelReasons.map((reason) => ({ key: reason.ReasonId, label: reason.Reason, value: reason.Reason }))
            }
            onChange={(val) => setSelectedCancelReason(val)}
            placeholder='Select a reason'
            automationId='cancel-transaction-popup_select_cancellation-reasons'
          />
        )}
      </ContentDiv>
    </ConfirmationPopup>
  );
};

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
`;

const Message = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const CancelSelect = styled(Select)`
  width: 100%;
  margin: 1.5rem 0 0;
`;
