import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { Label } from 'components/text';
import { Select } from 'components/inputs';
import { SelectContainer } from '../../SettingPageComponents';
import { UserSelectedPrinterContext } from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { logger, customEventKeys } from 'util/logger';

export const NetworkLabelPrinterPicker: FC = () => {
  const dispatch = useDispatch();
  const { labelPrinters } = useSelector((state: State) => state.settings);
  const { selectedLabelPrinter } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintLabels } = useSelector((state: State) => state.settings.userSettings);

  const options = labelPrinters.map((item) => ({ value: item.PrinterId, label: item.Name, key: item.PrinterId }));

  const changePrinter = (value: string) => {
    const selectedLabelPrinter = labelPrinters.find((item) => item.PrinterId === parseInt(value, 10));
    if (selectedLabelPrinter) {
      dispatch(saveUserSettings({ selectedLabelPrinter }));

      logger.info<UserSelectedPrinterContext>(`user selected network label printer ${value}`, {
        key: customEventKeys.printing.userSelectedPrinter,
        job: PrintJob.LABELS,
        type: 'network',
        selectedPrinterId: value,
      });
    }
  };

  return (
    <SelectContainer>
      <Label>Network Label Printer</Label>
      <Select
        placeholder='Select Printer'
        defaultValue={selectedLabelPrinter?.PrinterId}
        onChange={changePrinter}
        options={options}
        errorMessage={!selectedLabelPrinter && autoPrintLabels && 'Please select a default label printer'}
      />
    </SelectContainer>
  );
};
