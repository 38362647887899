import { useRef } from 'react';

import { isElementVisible } from 'util/helpers/isElementVisible';
import { useOnClickOutside } from 'util/hooks';
import { useUpdateCartTotals } from 'pages/CartPage/hooks/useUpdateCartTotals';

import type { Cart } from 'models/Cart';

type UseTotalMinimizedParams = {
  cart: Partial<Cart>;
  isCartLoading: boolean;
  isStatefulCheckoutEnabled: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useTotalMinimized = ({
  cart,
  isCartLoading,
  isStatefulCheckoutEnabled,
  isOpen,
  setIsOpen,
}: UseTotalMinimizedParams) => {
  const floatingTotalsRef = useRef<HTMLDivElement | null>(null);

  // Hooks
  const { mutateAsync: updateCartTotals } = useUpdateCartTotals();

  // Computed values
  const grandTotalRounded = cart.GrandTotalRounded ?? 0;
  const totalDiscountAndLoyalty = cart.TotalDiscountAndLoyalty ?? 0;
  const totalWithDiscountsAndLoyalty = grandTotalRounded + totalDiscountAndLoyalty;
  const showStrikethroughSubtotal = totalDiscountAndLoyalty > 0;
  const hasNotCalculatedTotalYet = cart.GrandTotalRounded === 0;
  const showRecalculateTotalIcon = isStatefulCheckoutEnabled && !hasNotCalculatedTotalYet;
  const showGetTotalButton = isStatefulCheckoutEnabled && hasNotCalculatedTotalYet;

  // Handlers
  const handleClickArrowButton = () => {
    // If the floating totals is open, let the useOnClickOutside hook
    // handle closing the itself
    const isFloatingTotalsOpen = isElementVisible(floatingTotalsRef.current);
    if (isFloatingTotalsOpen) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const handleClickGetTotal = async () => {
    if (isStatefulCheckoutEnabled && !isCartLoading) {
      await updateCartTotals({ isCheckout: true });
    }
  };

  useOnClickOutside(floatingTotalsRef, (_event) => {
    const isFloatingTotalsOpen = isElementVisible(floatingTotalsRef.current);
    if (isFloatingTotalsOpen && isOpen) {
      setIsOpen(false);
    }
  });

  return {
    grandTotalRounded,
    floatingTotalsRef,
    totalWithDiscountsAndLoyalty,
    isLoading: isCartLoading,
    showStrikethroughSubtotal,
    showRecalculateTotalIcon,
    showGetTotalButton,
    handleClickArrowButton,
    handleClickGetTotal,
  };
};
