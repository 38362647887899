/* eslint-disable no-console */
import LogRocket from 'logrocket';
import { LoggingProvider } from '../LoggingProvider';

import type { LoggingProviderInitProps, UserData } from '../LoggingProvider';
import type { Middleware } from '@reduxjs/toolkit';
import { isAndroid } from 'util/hooks/android/useAndroidPeripherals';
import { isProduction } from 'util/is-production';

export class LogRocketLoggingProvider extends LoggingProvider {
  public providerId = 'logrocket';

  public init = (props?: LoggingProviderInitProps) => {
    try {
      /**
       * Elo devices have localhost as their host so we need to check the isAndroid flag as well
       */
      if (isProduction() || props?.turnOnForDevelopment || isAndroid) {
        LogRocket.init('zg2tcu/leaflogix-foh', {
          dom: {
            disableVisibleElement: true,
          },
          network: {
            requestSanitizer: (request) => {
              const url = request.url.toLowerCase();

              if (url.includes('/posv3/user/employeelogin')) {
                request.body = undefined;
              }

              if (url.includes('/posv3/user/resetpassword')) {
                request.body = undefined;
              }

              return request;
            },
          },
        });
      }
    } catch (error) {
      console.error('Failed to initialize LogRocket', error);
    }
  };

  public getMiddleware = (): Middleware => {
    return LogRocket.reduxMiddleware();
  };

  public setUser = (userId: string, userData?: UserData) => {
    LogRocket.identify(userId, {
      ...userData,
    });
  };

  // LogRocket API doesn't support the second parameter, so just send the event description
  public track = (customEventDescription: string) => {
    LogRocket.track(customEventDescription);
  };

  public error = (e: unknown, data?: object) => {
    LogRocket.error(e, data);
  };
}
/* eslint-enable no-console */
