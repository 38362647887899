import React, { useCallback } from 'react';

// assets
import { HeartIcon } from 'assets/icons/insights/heart';
import { MoneyIcon } from 'assets/icons/insights/money';

import type { SegmentIcon } from 'queries/personalization/segments';

const iconMap: Record<SegmentIcon, JSX.Element> = {
  money: <MoneyIcon />,
  heart: <HeartIcon />,
};

export function useGetSegmentIcon() {
  const getSegmentIcon = useCallback((iconName: SegmentIcon): JSX.Element | null => {
    return iconMap[iconName] || null;
  }, []);

  return getSegmentIcon;
}
