import React, { FC } from 'react';
import styled from 'styled-components';
import { utcToZonedTime, format } from 'date-fns-tz';
import { callback } from 'models/Misc';
import { Popup } from 'components/popups';
import { colors } from 'css/Theme';
import { DeliveryHistory } from 'models/Delivery';

const TimestampText = styled.div`
  float: right;
  text-align: right;
`;
const HistoryItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1rem;
  color: ${colors.dutchie.almostBlack};
`;
const Column = styled.div`
  flex-direction: column;
  flex: 1;
`;

const HistoryContainer = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
  margin: 0 20px 10px 20px;
`;

const TitleText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
`;

type DeliveryRouteHistoryPopupProps = {
  isVisible: boolean;
  hide: callback;
  deliveryHistory?: Array<DeliveryHistory>;
};

export const DeliveryRouteHistoryPopup: FC<DeliveryRouteHistoryPopupProps> = ({ isVisible, hide, deliveryHistory }) => {
  return (
    <Popup medium caption='Route History' isVisible={isVisible} hide={hide}>
      <HistoryContainer>
        {deliveryHistory?.map((h, i) => (
          <HistoryItemContainer key={i}>
            <TitleText>{h.ActionName}</TitleText>
            <Column>
              <div>{h.Comment}</div>
              <div>{h.FullName}</div>
            </Column>
            <Column>
              <TimestampText>
                {format(
                  utcToZonedTime(h.TimestampUtc, Intl.DateTimeFormat().resolvedOptions().timeZone),
                  'MMMM do h:mm:ss a'
                )}
              </TimestampText>
            </Column>
          </HistoryItemContainer>
        ))}
      </HistoryContainer>
    </Popup>
  );
};
