import { createAction } from '@reduxjs/toolkit';

import { getIsAnonymousCartLDFlagEnabled } from 'pages/CartPage/hooks/useAnonymousCart';

import {
  showCreatePreOrderPopup as showCreatePreOrderGlobalPopup,
  showFulfillmentSelectorPopup as showFulfillmentSelectorGlobalPopup,
  showSelectCollectorPopup as showSelectCollectorGlobalPopup,
  showVerifyBirthdatePopup as showVerifyBirthdateGlobalPopup,
} from './GlobalPopupsActions';

import {
  showCreatePreOrderPopup as showCreatePreOrderGuestListPopup,
  showFulfillmentSelectorPopup as showFulfillmentSelectorGuestListPopup,
  showSelectCollectorPopup as showSelectCollectorGuestListPopup,
  showVerifyBirthdatePopup as showVerifyBirthdateGuestListPopup,
} from './GuestListPopupsActions';

const { isAnonymousCartLDFlagEnabled: isGlobalPopupsEnabled } = getIsAnonymousCartLDFlagEnabled();

export const closePopup = createAction('closePopup', (payload: string) => ({ payload }));

export * from './DeliveryPopupsActions';
// Can uncomment once we clean up the deprecated popups
// export * from './GuestListPopupsActions';
export * from './CartPopupsActions';
export * from './CustomerPopupsActions';
// Can uncomment once we clean up the deprecated popups
// export * from './GlobalPopupsActions';

// Can remove everything below once we clean up the deprecated popups

// Export all other popups from GuestListPopupsActions
export {
  showAnonymousTransactionPopup,
  showCancelTransactionPopup,
  showCustomerInsightsPopup,
  showBannedCustomerPopup,
  showNotifyCustomerPopup,
  showReleasePopup,
  showScanDetailsPopup,
  showScanPopup,
  showSecurityCheckinPopup,
  showSetStatusPopup,
  showSetTransactionReferencePopup,
  showVersionCheckerPopup,
  showVisitReasonPopup,
  closeAllPopups,
} from './GuestListPopupsActions';

// Export the global popups if the flag is enabled, otherwise export the deprecated guest list popups
export const showCreatePreOrderPopup = isGlobalPopupsEnabled
  ? showCreatePreOrderGlobalPopup
  : showCreatePreOrderGuestListPopup;
export const showFulfillmentSelectorPopup = isGlobalPopupsEnabled
  ? showFulfillmentSelectorGlobalPopup
  : showFulfillmentSelectorGuestListPopup;
export const showSelectCollectorPopup = isGlobalPopupsEnabled
  ? showSelectCollectorGlobalPopup
  : showSelectCollectorGuestListPopup;
export const showVerifyBirthdatePopup = isGlobalPopupsEnabled
  ? showVerifyBirthdateGlobalPopup
  : showVerifyBirthdateGuestListPopup;
