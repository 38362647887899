import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { MenuItem } from 'components/backoffice/menu-item';
import { ModalVariation } from 'components/modals/Modal';
import { ScannerBarcodeAsync } from '../setup/ScannerBarcode';
import { Select } from 'components/backoffice/select';
import { SetupInstructions } from '../setup/SetupInstructions';
import { SimpleModal } from 'components/modals/SimpleModal';
import { useBarcodeScannerHelpCenterLink } from 'util/hooks/launch-darkly/useBarcodeScannerHelpCenterLink';
import { useConfigureBarcodeScannerModal } from './useConfigureBarcodeScannerModal';

export const ConfigureBarcodeScannerModal = ({ hide }: { hide: () => void }) => {
  const { handleSelectConfiguration, selectedConfiguration, scannerConfigurations } = useConfigureBarcodeScannerModal();

  const { helpCenterLink, isHelpCenterLinkDefined } = useBarcodeScannerHelpCenterLink();

  return (
    <SimpleModal
      modalName='configure-barcode-scanner'
      header='Configure barcode scanner'
      variation={ModalVariation.SettingModal}
      showHeaderBorder
      hide={hide}
      footer={
        <Button cta onClick={hide}>
          Done
        </Button>
      }
    >
      <Container>
        <ConfigurationContainer>
          <SelectionContainer>
            <Heading>Select scanner configuration</Heading>
            <Select value={selectedConfiguration.title} onChange={handleSelectConfiguration}>
              {scannerConfigurations.map((configuration) => (
                <MenuItem key={configuration.title} value={configuration.title}>
                  {configuration.title}
                  {configuration.subtitle && <Subtitle>{configuration.subtitle}</Subtitle>}
                </MenuItem>
              ))}
            </Select>
            <Text>
              See instructions for configuring your scanner by selecting it from the dropdown.
              <br />
              {isHelpCenterLinkDefined && (
                <>
                  Don't see your scanner model?{' '}
                  <Link href={helpCenterLink} target='blank'>
                    Visit our help center
                  </Link>{' '}
                  for more information.
                </>
              )}
            </Text>
          </SelectionContainer>
        </ConfigurationContainer>
        <SetupInstructions
          listStyles={listStyles}
          steps={selectedConfiguration.barcodes.map(({ instruction, ...barcodeProps }) => (
            <BarcodeStep key={barcodeProps.title}>
              {instruction}
              <ScannerBarcodeAsync maxHeight={61} {...barcodeProps} />
            </BarcodeStep>
          ))}
        />
      </Container>
    </SimpleModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  gap: 2rem;
`;

const ConfigurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Heading = styled.h3`
  color: ${colors.dutchie.gray70};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

const Subtitle = styled.span`
  color: ${colors.dutchie.gray70};
  font-weight: 400;
  margin-left: 0.25rem;
`;

const Text = styled.div`
  color: ${colors.dutchie.gray70};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Link = styled.a`
  color: ${colors.dutchie.blue};
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: ${colors.dutchie.blue};
    border-bottom: 2px solid ${colors.dutchie.blue};
  }
`;

const listStyles = css`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

const BarcodeStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;
