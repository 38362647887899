import React from 'react';

import { colors } from 'css/Theme';
import { Loader } from 'components/backoffice/loader';
import { TextContainer, MessageContainer, Message } from './styles';

type LoadingResultsProps = {
  message?: string;
};

export const LoadingResults = (props?: LoadingResultsProps) => {
  return (
    <TextContainer>
      <MessageContainer>
        <Loader size='3x' variant='custom' color={colors.dutchie.gray80} />
        <Message>{props?.message ?? 'Loading results. Please wait...'}</Message>
      </MessageContainer>
    </TextContainer>
  );
};
