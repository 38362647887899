import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { Peripheral, ReceiptPrinter } from '@dutchie/capacitor-peripheral';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { SectionGridContainer, SectionHeader, SectionContent } from '../../SettingPageComponents';
import { Switch } from 'components/inputs';
import { LocalReceiptPicker } from './LocalReceiptPicker';
import { NetworkReceiptPicker } from './NetworkReceiptPicker';
import { isAndroid } from 'util/hooks';
import { switchPrinterSettings } from '../utils';
import { logger, customEventKeys } from 'util/logger';
import {
  AutoConnectedToPrinterContext,
  SearchReceiptPrintersContext,
  UserChangedPrinterSettingsContext,
} from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { getSearchReceiptPrintersDescription } from 'util/logger/helpers/printing';

export const ReceiptPrinterPicker: FC = () => {
  const dispatch = useDispatch();
  const { receiptPrinters } = useSelector((state: State) => state.settings);
  const { useConnectedReceiptPrinter } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintReceipts } = useSelector((state: State) => state.settings.userSettings);
  const [connectedReceiptPrinters, setConnectedReceiptPrinters] = useState<ReceiptPrinter[]>([]);

  useEffect(() => {
    const setLocalPrinters = async () => {
      if (isAndroid) {
        const { results } = await Peripheral.searchReceiptPrinters();
        setConnectedReceiptPrinters(results);

        logger.info<SearchReceiptPrintersContext>(getSearchReceiptPrintersDescription(results, false), {
          key: customEventKeys.printing.local.receipt.search,
          availablePrinters: results,
          userInitiated: false,
        });
      }
    };

    setLocalPrinters();
  }, []);

  const handleSwitchReceiptPrinterTypes = (useConnectedReceiptPrinter: boolean) => {
    const newPrinterSettings = switchPrinterSettings(
      useConnectedReceiptPrinter,
      connectedReceiptPrinters,
      receiptPrinters
    );

    const newUserSettings = newPrinterSettings
      ? {
          useConnectedReceiptPrinter,
          selectedReceiptPrinter: newPrinterSettings,
        }
      : { useConnectedReceiptPrinter };

    dispatch(saveUserSettings(newUserSettings));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled receipt printer to ${useConnectedReceiptPrinter ? 'local' : 'network'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.RECEIPT,
      printerSettings: { isLocalPrinter: useConnectedReceiptPrinter },
    });

    if (newPrinterSettings) {
      logger.info<AutoConnectedToPrinterContext>(`auto-connected to ${newPrinterSettings.LocalPrinter ? 'local' : 'network'} receipt printer ${newPrinterSettings.PrinterId}`, {
        key: customEventKeys.printing.autoConnected,
        job: PrintJob.RECEIPT,
        type: newPrinterSettings.LocalPrinter ? 'local' : 'network',
        autoSelectedPrinterId: newPrinterSettings.PrinterId,
      });
    }
  };

  const handleSwitchAutoPrintReceipts = (autoPrintReceipts: boolean) => {
    dispatch(saveUserSettings({ autoPrintReceipts }));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled auto printing for receipt printer ${autoPrintReceipts ? 'ON' : 'OFF'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.RECEIPT,
      printerSettings: { isAutoPrintEnabled: autoPrintReceipts },
    });
  };

  const printerPicker =
    useConnectedReceiptPrinter && isAndroid ? (
      <LocalReceiptPicker
        connectedReceiptPrinters={connectedReceiptPrinters}
        setConnectedReceiptPrinters={setConnectedReceiptPrinters}
      />
    ) : (
      <NetworkReceiptPicker />
    );

  return (
    <SectionGridContainer>
      <SectionHeader>Receipt Printers</SectionHeader>
      <SectionContent>
        <Switch label='Auto Print Receipts' onChange={handleSwitchAutoPrintReceipts} checked={autoPrintReceipts} />
        {isAndroid && (
          <Switch
            label='Use Local Receipt Printer'
            onChange={handleSwitchReceiptPrinterTypes}
            checked={useConnectedReceiptPrinter}
          />
        )}
        {printerPicker}
      </SectionContent>
    </SectionGridContainer>
  );
};
