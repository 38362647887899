import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

import { callback } from 'models/Misc';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionNotes, setTransactionNotes } from 'store/actions/CheckoutActions';
import { TextArea } from 'components/inputs';
import { State } from 'store';
import { ConfirmationPopup } from 'components/popups';
import { successNotification } from 'store/actions/NotificationsActions';

const StyledWrapper = styled.div`
  padding: 0 24px;
  display: flex;
`;

type TransactionPopupProps = {
  hide: callback;
  guestId?: number;
  shipmentId?: number;
};

export const TransactionNotesPopup: FC<TransactionPopupProps> = ({ hide, guestId, shipmentId }) => {
  const allNotes = useSelector((state: State) => state.checkout.transactionNotes);
  const existingNote = allNotes?.find((n) => n.TransactionNotes)?.TransactionNotes;
  const [currentNote, setCurrentNote] = useState(existingNote ?? '');
  const dispatch = useDispatch();

  useEffect(() => {
    if (shipmentId) {
      dispatch(getTransactionNotes({ TransactionId: shipmentId }));
    }
  }, [dispatch, shipmentId]);

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => setCurrentNote(e.currentTarget.value);

  const handleSubmit = () => {
    if (shipmentId && guestId) {
      dispatch(
        setTransactionNotes({
          TransactionNote: currentNote,
          GuestId: guestId,
          TransId: shipmentId,
          TransactionId: shipmentId,
        })
      );
      dispatch(getTransactionNotes({ TransactionId: shipmentId }));
      dispatch(successNotification('Notes saved!'));
      hide();
    }
  };

  return (
    <ConfirmationPopup
      small
      title='Transaction Note'
      isVisible
      hide={hide}
      confirm={{
        text: 'Save',
        disabled: currentNote === '',
        onClick: handleSubmit,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <StyledWrapper>
        <TextArea rows={5} value={currentNote} onChange={handleNoteChange} />
      </StyledWrapper>
    </ConfirmationPopup>
  );
};
