import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, store } from 'store';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';
import { Input, Select } from 'components/inputs';
import { saveVisitReason } from 'store/actions/CartActions';
import { loadVisitReasons } from 'store/actions/SettingsActions';
import { getVisitReason } from 'api/CartApi';
import { Loader } from 'components/backoffice/loader';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

const VisitReasonPopupContainer = styled(ConfirmationPopup)`
  width: 29.5rem;
  min-height: 13em;
  justify-content: center;
  & h3 {
    padding-bottom: 0;
    margin: 0 auto 1em;
  }
  div > div {
    margin-top: 0;
  }
`;

const WrapperDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 1.5rem;
  margin: 0 0 0.75rem 0;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem;
  gap: 1rem;

  & > * {
    flex-grow: 1;
    width: 100%;
  }
`;

const SpinnerWrapperDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 75px;
`;

const VisitReasonSelect = styled(Select)`
  width: 100%;
  margin: 0 0 1.5rem 0;
`;

type VisitReasonPopupProps = {
  hide: callback;
  shipmentId: number;
};

export const VisitReasonPopup: FC<VisitReasonPopupProps> = ({ hide, shipmentId }) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const visitReasons = useSelector((state: State) => state.settings.visitReasons);
  const [visitReason, setVisitReason] = useState('');
  const [otherInputVisible, setOtherInputVisible] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(loadVisitReasons());
    setIsLoading(true);
    getVisitReason(shipmentId).then((resp) => {
      if (resp && resp[0]?.VisitReason && visitReasons) {
        if (resp[0].ReasonId) {
          setVisitReason(resp[0].VisitReason);
        } else {
          setOtherInputVisible(true);
          setOtherInputValue(resp[0].VisitReason);
          setVisitReason('other');
        }
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shipmentId]);

  const resetOther = () => {
    setOtherInputVisible(false);
    setOtherInputValue('');
  };

  const handleReasonSelect = (val: string) => {
    val === 'other' ? setOtherInputVisible(true) : resetOther();
    setVisitReason(val);
  };

  const doVisitReason = async () => {
    if (otherInputValue) {
      dispatch(
        saveVisitReason({
          ShipmentId: shipmentId,
          Reason: otherInputValue,
        })
      );
    } else {
      dispatch(
        saveVisitReason({
          ShipmentId: shipmentId,
          Reason: visitReason,
        })
      );
    }
  };

  const handleHide = () => {
    setVisitReason('');
    setOtherInputVisible(false);
    setOtherInputValue('');
    hide();
  };

  const { isModalBridgeEnabled } = useModalBridge();

  const PopupToUse = isModalBridgeEnabled ? ConfirmationPopup : VisitReasonPopupContainer;
  const ContainerToUse = isModalBridgeEnabled ? Container : WrapperDiv;
  const SelectToUse = isModalBridgeEnabled ? Select : VisitReasonSelect;

  return (
    <PopupToUse
      small={isModalBridgeEnabled}
      isVisible
      hide={handleHide}
      title='Visit Reason'
      confirm={{
        disabled: (otherInputVisible && !otherInputValue) || !visitReason ? true : false,
        text: 'Save',
        onClick: async () => {
          await doVisitReason();
          hide();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      {isLoading ? (
        <SpinnerWrapperDiv>
          <Loader size='2x' variant='black' />
        </SpinnerWrapperDiv>
      ) : (
        <ContainerToUse>
          <SelectToUse
            defaultValue={visitReason}
            options={
              !visitReasons
                ? []
                : visitReasons.map((reason) => ({
                    key: reason.ReasonId,
                    label: reason.Reason,
                    value: reason.Reason,
                  }))
            }
            placeholder='Select a reason'
            onChange={(val) => handleReasonSelect(val)}
            allowOther
          />
          {otherInputVisible && (
            <Input
              defaultValue={otherInputValue ? otherInputValue : ''}
              placeholder='reason for visit'
              onChange={(e: { target: { value: React.SetStateAction<string> } }) => {
                setOtherInputValue(e.target.value);
              }}
            />
          )}
        </ContainerToUse>
      )}
    </PopupToUse>
  );
};
