import React from 'react';

import { ConnectionStatusPill } from 'pages/SettingsPage/Status/ConnectionStatusPill';
import { useConnectedScale } from 'util/hooks/hardware/useConnectedHardware';
import { isNativeScale } from '@dutchie/capacitor-peripheral';

export const ScaleStatusPill = () => {
  const { device } = useConnectedScale();

  return (
    <ConnectionStatusPill
      automationId='scale_settings-connection_status'
      deviceName={device?.name}
      connected={device?.isConnected === true || (device && isNativeScale(device)) === true}
    />
  );
};
