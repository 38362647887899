import { PaymentResultPusherNotification, PaymentsHubProductUpsellPusherNotification } from 'models/Checkout';
import { PusherChannelType } from 'models/Pusher';
import { usePusherChannel } from 'messaging/pusher/usePusherChannel';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { notifyConectionLost, notifyPusherConnected } from 'store/actions/CheckoutActions';
import { useOnPusherPaymentCompleted } from './useOnPusherPaymentCompleted';
import { useOnPusherProductUpsell } from './useOnPusherProductUpsell';
import { logger } from 'util/logger';
import { usePaymentsHubUpsellPoc } from 'util/hooks/launch-darkly/usePaymentsHubUpsellPoc';

export const useOnPaymentsPusherMessage = () => {
  const onPusherPaymentCompleted = useOnPusherPaymentCompleted();
  const onPusherProductUpsell = useOnPusherProductUpsell();
  const useUpsellPoc = usePaymentsHubUpsellPoc();

  const onNewPusherMessages = useCallback(
    async (eventName: string, data: string | object) => {
      let notification = null;
      if (typeof data === 'string') {
        notification = JSON.parse(data);
      } else {
        notification = data;
      }

      switch (eventName) {
        case 'payment-completed': {
          const paymentNotification: PaymentResultPusherNotification = notification;
          await onPusherPaymentCompleted(paymentNotification);
          break;
        }

        case 'upsell-requested': {
          if (useUpsellPoc) {
            const upsellNotification: PaymentsHubProductUpsellPusherNotification = notification;
            await onPusherProductUpsell(upsellNotification);
          }
          break;
        }
        default:
          logger.debug(`Unknown pusher payment event occured: ${eventName}`, { key: eventName, pusherData: data });
          break;
      }
    },
    [onPusherPaymentCompleted, onPusherProductUpsell, useUpsellPoc]
  );
  return onNewPusherMessages;
};

export const usePaymentsPusherChannel = () => {
  const onChannelMessage = useOnPaymentsPusherMessage();
  const dispatch = useDispatch();

  return usePusherChannel({
    channelType: PusherChannelType.Payments,
    enabled: true,
    onChannelMessage,
    onConnected: () => dispatch(notifyPusherConnected()),
    onDisconnected: () => dispatch(notifyConectionLost()),
  });
};
