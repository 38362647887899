import styled from 'styled-components';

import { Button } from './Button';
import { colors } from 'css/Theme';

export const CancelButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: normal;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: ${colors.dutchie.primaryWhite};
  border: none;
`;
