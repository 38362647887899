import React from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { Container, Header, Title, StyledLinkButton } from '../TotalsSidebarSection.styles';
import { useGuestNotes } from './useGuestNotes';

export const GuestNotes = () => {
  const { areNotesEmpty, handleClickEdit, handleClickSeeMore, isEditDisabled, notes, showSeeMore } = useGuestNotes();

  return (
    <Container data-testid='guest-notes-section'>
      <Header>
        <Title>Notes</Title>
        <StyledLinkButton
          label='Edit'
          onClick={handleClickEdit}
          disabled={isEditDisabled}
          ddActionName='edit guest notes button'
        />
      </Header>
      <Content
        onClick={handleClickSeeMore}
        isEmpty={areNotesEmpty}
        data-testid='guest-notes-section_content'
        data-dd-action-name='see more on guest notes'
      >
        {areNotesEmpty ? 'No notes available' : notes}
        {showSeeMore && <SeeMore data-testid='guest-notes-section_see-more-button'>See more</SeeMore>}
      </Content>
    </Container>
  );
};

const Content = styled.div<{ isEmpty?: boolean }>`
  color: ${({ isEmpty }) => (isEmpty ? colors.dutchie.gray70 : colors.dutchie.almostBlack)};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const SeeMore = styled.div`
  display: inline-block;
  color: ${colors.dutchie.gray70};
  font-weight: 600;
  cursor: pointer;
`;
