import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useInfiniteStaleTime = () => {
  const ldClient = useLDClient();
  const isInfiniteStaleTimeEnabled =
    ldClient?.variation('pos.register.new-default-react-query-options', false) === true;
  return {
    /** pos.register.new-default-react-query-options */
    isInfiniteStaleTimeEnabled,
  };
};
