import { ReceiptPrinter, ConnectionType, ReceiptLanguage, ReceiptLanguages } from '@dutchie/capacitor-hardware';
import { printBytes } from 'api/PosApi';
import { popCashDrawer as apiPopCashDrawer } from 'api/SettingsApi';
import { PrinterResponse, PrintNodePrinterType, PrintNodePrinterTypes } from 'models/Misc';
import { GetPrintJobResponse, selectHardwarePrinterType } from 'util/hardwareLibrary/hardware-library-utils';
import { getIsPrintResultLoggingEnabled } from 'util/hooks/launch-darkly/getIsPrintResultLoggingEnabled';

export class PrintNodeReceiptPrinter extends ReceiptPrinter {
  private config: PrinterResponse;
  job: GetPrintJobResponse | undefined;

  constructor(config: PrinterResponse) {
    super(ConnectionType.lan);
    this.config = config;
  }

  get id(): string {
    return `pn-receipt-${this.printNodeId}`;
  }

  // Indicates whether the code is actively listening for printer events
  get isConnected(): boolean {
    return false;
  }

  get language(): ReceiptLanguage {
    // This field identifies the print language from the hardware library and is used
    // to determine the printer type when calling the API.
    // PDF is not a valid printer language and will still return as ESC, but the code will
    // use the configured printer type from back-office when printing to PrintNode printers.
    switch (this.printNodeType) {
      case PrintNodePrinterTypes.STAR_GRAPHIC:
        return ReceiptLanguages.starGraphic;
      case PrintNodePrinterTypes.STAR_PRINT:
        return ReceiptLanguages.starPrint;
    }

    return super.language;
  }

  get name(): string {
    return this.config.Name;
  }

  // Enables finding printer info in db by the print node id
  get printNodeId(): number {
    return this.config.PrinterId;
  }

  // Passed to receipt job endpoints to avoid an extra db lookup
  get printNodeType(): PrintNodePrinterType {
    return this.config.PrinterType ?? PrintNodePrinterTypes.ESC;
  }

  async doConnect(): Promise<boolean> {
    return true;
  }

  async doDisconnect(): Promise<boolean> {
    return true;
  }

  async popCashDrawer(): Promise<boolean> {
    const locId = window.sessionStorage.getItem('LocId');
    await apiPopCashDrawer({
      LocId: locId,
      PrinterId: this.printNodeId,
    });
    return true;
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    const result: { JobId: number } = await printBytes({
      bytes,
      printerId: this.printNodeId,
      printerType: selectHardwarePrinterType(this),
      title: this.job?.title,
    });
    if (getIsPrintResultLoggingEnabled()) {
      if (typeof result === 'number') {
        // Response is different when receipt branding is enabled
        return result && result > 0;
      }
    }

    return result && result.JobId > 0;
  }
}
