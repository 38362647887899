import styled, { createGlobalStyle } from 'styled-components';

export const ToolTipStyles = createGlobalStyle`
  [role='tooltip'] {
    max-width: 200px;
    border-radius: 0.75rem;
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 1.5rem;

  b {
    font-weight: 600;
  }

  :last-child {
    margin-bottom: 1.5rem;
  }
`;

export const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OrderNumber = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const TransactionPaymentTypes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const PaymentTypesHeader = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const Payment = styled.div``;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.div<{ bold?: boolean }>`
  ${({ bold }) => bold && `font-weight: 600;`}
`;
