import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BypassStateSystemPopup } from './components/BypassStateSystemPopup';
import { ConfigureDeliveries } from './components/ConfigureDeliveries';
import { ConfirmTransactionPopup } from './components/ConfirmTransactionPopup';
import { DeliverySettingsPopup } from '../sharedPopups/DeliverySettingsPopup';
import { TransactionPopup } from '../sharedPopups/TransactionPopup';
import { closePopup } from 'store/actions/PopupsActions';

import type { State } from 'store';

export const DeliveryPopups = (): JSX.Element => {
  const dispatch = useDispatch();
  const bypassStateSystemPopup = useSelector((state: State) => state.popups.bypassStateSystemPopup);
  const configureDeliveriesPopup = useSelector((state: State) => state.popups.configureDeliveriesPopup);
  const confirmTransactionPopup = useSelector((state: State) => state.popups.confirmTransactionPopup);
  const deliverySettingsPopup = useSelector((state: State) => state.popups.deliverySettingsPopup);
  const transactionPopup = useSelector((state: State) => state.popups.transactionPopup);

  return (
    <>
      {bypassStateSystemPopup && (
        <BypassStateSystemPopup
          {...bypassStateSystemPopup}
          hide={() => dispatch(closePopup('bypassStateSystemPopup'))}
        />
      )}
      {configureDeliveriesPopup && (
        <ConfigureDeliveries
          {...configureDeliveriesPopup}
          hide={() => dispatch(closePopup('configureDeliveriesPopup'))}
        />
      )}
      {confirmTransactionPopup && (
        <ConfirmTransactionPopup
          {...confirmTransactionPopup}
          hide={() => dispatch(closePopup('confirmTransactionPopup'))}
        />
      )}
      {deliverySettingsPopup && (
        <DeliverySettingsPopup {...deliverySettingsPopup} hide={() => dispatch(closePopup('deliverySettingsPopup'))} />
      )}
      {transactionPopup && (
        <TransactionPopup {...transactionPopup} hide={() => dispatch(closePopup('transactionPopup'))} />
      )}
    </>
  );
};
