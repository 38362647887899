import React, { CSSProperties } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { OrderKanbanCard } from '../OrderKanbanCard';
import { useOptionsStatus } from '../useOptionsStatus';
import { CheckedInGuest } from 'models/Guest';
import { useBooleanLDFlag } from 'util/launchDarkly';

// https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  height: '100%',
};

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

type Item = {
  guest: CheckedInGuest;
};

export const CustomDragLayer = () => {
  const useDragFix = useBooleanLDFlag('pos.register.drag-card-fix.rollback', true);
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const guest = (item as Item)?.guest;
  const posStatusId = guest?.Status?.PosStatusId;
  const { cardOptionsStatus } = useOptionsStatus({ posStatusId });

  if (useDragFix && (!isDragging || !guest)) {
    return null;
  } else if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <OrderKanbanCard guest={guest} cardOptionsStatus={cardOptionsStatus} listMode={false} />
      </div>
    </div>
  );
};
