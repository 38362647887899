import { CustomSortArgs, CustomSortType, DataMatrixRow } from 'components/tables';

import type { ProductSearchResult } from 'queries/v2/product/types';

const unitKeyToSortOrderMap = {
  2: 3, // percent
  1: 2, // mg
  3: 1, // mg per g
  4: 0, // N.D. e.g. none
  5: 0, // <LOQ e.g. none
  0: 0, // no unitKey or contentKey, e.g. missing/incomplete data
};

export const potencyUnitIdToDisplayNameMap = {
  1: 'mg',
  2: '%',
  3: 'mg/g',
};

type ItemTypeForSort = DataMatrixRow<ProductSearchResult>;

type SortByPotencyArgs = CustomSortArgs<ItemTypeForSort> & {
  potencyType: 'cbd' | 'thc';
};

type UnitKey = 'cbdContentUnitId' | 'thcContentUnitId';
type ContentKey = 'cbdContent' | 'thcContent';

export const sortByCBD: CustomSortType<ItemTypeForSort> = ({ items, sortDirection }) =>
  sortByPotency({ items, sortDirection, potencyType: 'cbd' });

export const sortByTHC: CustomSortType<ItemTypeForSort> = ({ items, sortDirection }) =>
  sortByPotency({ items, sortDirection, potencyType: 'thc' });

export function sortByPotency({ items, sortDirection, potencyType }: SortByPotencyArgs): ItemTypeForSort[] {
  const unitKey: UnitKey = `${potencyType}ContentUnitId`;
  const contentKey: ContentKey = `${potencyType}Content`;

  return items.sort(({ item: itemA }, { item: itemB }) => {
    const itemAUnit = itemA[unitKey] ?? 0;
    const itemBUnit = itemB[unitKey] ?? 0;
    const itemAContentData = itemA[contentKey];
    const itemBContentData = itemB[contentKey];
    const itemAContentForSort = itemAContentData ?? 0;
    const itemBContentForSort = itemBContentData ?? 0;

    // if we don't have data for content value, we want to sort those items to bottom of list
    const itemASortOrder =
      typeof itemAContentData === 'number' ? unitKeyToSortOrderMap[itemAUnit as keyof typeof unitKeyToSortOrderMap] : 0;
    const itemBSortOrder =
      typeof itemBContentData === 'number' ? unitKeyToSortOrderMap[itemBUnit as keyof typeof unitKeyToSortOrderMap] : 0;

    if (itemASortOrder === itemBSortOrder) {
      if (itemASortOrder === 0) {
        return 0;
      }

      if (itemAContentForSort > itemBContentForSort) {
        return sortDirection === 'asc' ? 1 : -1;
      } else {
        return sortDirection === 'asc' ? -1 : 1;
      }
    }

    return itemASortOrder > itemBSortOrder ? -1 : 1;
  });
}
