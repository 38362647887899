import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { NavButton } from 'components/buttons';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';

type RefreshButtonProps = {
  onClick: () => Promise<void>;
};

export const RefreshButton: FC<RefreshButtonProps> = ({ onClick }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleClick = () => {
    setIsRefreshing(true);
    onClick().finally(() => setIsRefreshing(false));
  };

  return (
    <StyledRefreshButton
      disabled={isRefreshing}
      title='Refresh'
      onClick={handleClick}
      icon={<RefreshIcon />}
      secondary
    />
  );
};

export const SectionGridContainer = styled.div`
  position: relative;
  width: 550px;
`;

export const SectionHeader = styled.h2`
  color: ${colors.dutchie.almostBlack};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 1.5rem;
`;

export const SectionContent = styled.div`
  margin-bottom: 1rem;
`;

export const SelectContainer = styled.div`
  margin-bottom: 1rem;
`;

export const Caption = styled.p`
  color: ${colors.dutchie.grey50};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 4px;
`;

const StyledRefreshButton = styled(NavButton)`
  margin-left: 15px;
  max-height: 48px;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;
