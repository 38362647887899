import React from 'react';

import { CartFooter as NewCartFooter } from './CartFooter';
import { CartFooter as OldCartFooter } from './CartFooter-deprecated';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

import type { CartFooterProps } from './CartFooter';

export const CartFooter = (props: CartFooterProps) => {
  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  return isCustomerInsightsEnabled ? <NewCartFooter {...props} /> : <OldCartFooter {...props} />;
};
