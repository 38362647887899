import React, { FC } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';

import { breakpoints, colors } from 'css/Theme';
import { CustomerInfo } from './components/CustomerInfo';
import { ReturnPopup, ReturnPopupEnhanced } from './components/ReturnPopup';
import { SearchResults } from 'components/layout';
import { Table, StyleVariant } from 'components/tables';
import { TransactionDetailsHeader } from './components/TransactionDetailsHeader';
import { useTransactionDetails } from './useTransactionDetails';

import type { Transaction } from 'models/Transactions';

type TransactionDetailsProps = {
  selected?: Transaction;
  onVoid: () => void;
};

export const TransactionDetails: FC<TransactionDetailsProps> = ({ selected, onVoid }) => {
  const {
    areCreditRefundsEnabled,
    customerName,
    isLoyaltyEnabled,
    isReturnPopupVisible,
    isTransactionHistoryLoading,
    productList,
    selectedProduct,
    returnDetail,
    returnValue,
    tableColumns,
    toggleReturnPopup,
    transactionQuantity,
    transactionWeight,
  } = useTransactionDetails({
    selectedTransaction: selected,
  });

  if (!selected) {
    return null;
  }

  return (
    <Container>
      <LeftPanel>
        {selectedProduct &&
          isReturnPopupVisible &&
          (areCreditRefundsEnabled ? (
            <>
              {!!returnDetail && (
                <ReturnPopupEnhanced
                  isVisible={isReturnPopupVisible}
                  item={selectedProduct}
                  returnInfo={returnDetail}
                  hide={toggleReturnPopup}
                />
              )}
            </>
          ) : (
            <ReturnPopup
              isVisible={isReturnPopupVisible}
              item={selectedProduct}
              returnInfo={returnValue}
              hide={toggleReturnPopup}
            />
          ))}
        <TransactionDetailsHeader selected={selected} onVoid={onVoid} />
        <Content>
          <Details>
            <Column>
              <Row>
                <Key>Receipt No.</Key>
                <Value>{selected.PosId}</Value>
              </Row>
              <Row>
                <Key>Date</Key>
                <Value>{format(new Date(selected.PosDate ?? selected.CancelDate), 'MM/dd/yyyy')}</Value>
              </Row>
              <Row>
                <Key>Sold By</Key>
                <Value>{selected.TransactionBy}</Value>
              </Row>
              <Row>
                <Key>Total Items</Key>
                <Value>{selected.PosTotalItems}</Value>
              </Row>
              <Row>
                <Key>Total Cost</Key>
                <Value>{selected.PosProductCost}</Value>
              </Row>
              <Row>
                <Key>Discount</Key>
                <Value>{selected.DiscountAmt}</Value>
              </Row>
              <Row>
                <Key>Tax</Key>
                <Value>{selected.PosTax}</Value>
              </Row>
              <Row>
                <Key>Total Invoice</Key>
                <Value>{selected.TotalInvoice}</Value>
              </Row>
              <Row>
                <Key>Register</Key>
                <Value>{selected.Register}</Value>
              </Row>
            </Column>
            <Column>
              {customerName && (
                <CustomerNameRow>
                  <Key>Customer</Key>
                  <Value>{customerName}</Value>
                </CustomerNameRow>
              )}
              {isLoyaltyEnabled && (
                <Row>
                  <Key>Loyalty Used</Key>
                  <Value>{selected.LoyaltyUsed}</Value>
                </Row>
              )}
              {isLoyaltyEnabled && (
                <Row>
                  <Key>Loyalty Earned</Key>
                  <Value>{selected.LoyaltyEarned}</Value>
                </Row>
              )}
              <Row>
                <Key>Quantity</Key>
                <Value>{transactionQuantity}</Value>
              </Row>
              <Row>
                <Key>Weight</Key>
                <Value>{transactionWeight} g</Value>
              </Row>
              <Row>
                <Key>Return Status</Key>
                <Value>{selected.hasReturn}</Value>
              </Row>
              <Row>
                <Key>Status</Key>
                <Value>{selected.PosStatus}</Value>
              </Row>
              <Row>
                <Key>Location</Key>
                <Value>{selected.Location}</Value>
              </Row>
              <Row>
                <Key>Payment</Key>
                <Value>{selected.PosPaymentType}</Value>
              </Row>
              {selected.CancelDate && (
                <>
                  <Key>Cancel Reason</Key>
                  <Value>{selected.CancelReason ?? 'Not provided'}</Value>
                </>
              )}
              <Row>
                <Key>Doctor</Key>
                <Value>{selected.DoctorsName}</Value>
              </Row>
            </Column>
          </Details>
          <TableContainer>
            <SearchResults
              loading={isTransactionHistoryLoading}
              isEmpty={!productList.length}
              emptyMessage='There are no product history records or they could not be retrieved'
            >
              <Table columns={tableColumns} data={productList} pageSize={10} styleVariant={StyleVariant.noWrap} />
            </SearchResults>
          </TableContainer>
        </Content>
      </LeftPanel>
      <CustomerInfo />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const LeftPanel = styled.div`
  width: 65%;
  height: 100%;
  border-right: 1px solid ${colors.dutchie.shadowGrey};
  overflow-y: auto;

  ${breakpoints.wideTablet.maxQuery} {
    width: 100%;
    border: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 1.5rem;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1.5rem;
  gap: 4.5rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const Key = styled.div`
  color: ${colors.dutchie.gray70};
`;

export const Value = styled.div`
  text-align: right;
  color: ${colors.dutchie.almostBlack};
`;

export const CustomerNameRow = styled(Row)`
  display: none;

  ${breakpoints.wideTablet.maxQuery} {
    display: flex;
  }

  ${Value} {
    font-weight: 600;
  }
`;

const TableContainer = styled.div`
  clear: both;
  max-width: 100%;
  border-top: 1px solid ${colors.dutchie.shadowGrey};
  overflow-x: scroll;
`;
