import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from 'store';

import { isCartItemOrProductSearchResult } from 'models/Cart';
import { useCartPopups } from 'components/CartPopups';
import { showProductBeingAddedPastUsedByDatePopup } from 'store/actions/PopupsActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { useRegisterSettings } from 'util/hooks';
import { removeLeadingZeros } from 'util/Helpers';
import { getProductId } from 'util/helpers/products/getProductId';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useCartItems } from 'util/hooks/cart/useCartItems';
import { useProducts } from 'util/hooks/products/useProducts';
import { useProductsById } from 'util/hooks/products/useProductsById';
import { useMETRCUseByDateCheck } from 'pages/CartPage/hooks/useMETRCUseByDateCheck';

import { Container, StyledBaseInput, CalculatorButton, InputLoading, MinusButton } from './ProductSelection.styles';

import type { CartItem } from 'models/Cart';
import type { ProductSearchResult } from 'queries/v2/product/types';

type BulkSelectorProps = {
  product: ProductSearchResult | CartItem;
  grams: number;
  orderQty?: number;
  onWeightChange: (weightChange: number) => void;
  setTotalCost?: (cost: number) => void;
  includeCartQtyInMax: boolean;
  isInCartSummary: boolean;
  closeProductPreview?: () => void;
};

export const BulkSelector: FC<BulkSelectorProps> = ({
  product,
  grams,
  onWeightChange,
  setTotalCost,
  includeCartQtyInMax,
  isInCartSummary,
  closeProductPreview,
}) => {
  const dispatch = useDispatch();
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const id = getProductId(product);

  const cartItemFromRQ = useCartItems((cartItemsById) => cartItemsById[id]);
  const cartItemFromRedux = useSelector((state: State) => state.cart.itemsById[id]);
  const cartItem = isAnonymousCartLDFlagEnabled ? cartItemFromRQ : cartItemFromRedux;

  const productItemNew = useProducts((productsById) => productsById[id]);
  const { productsById } = useProductsById();
  const productItem = isAnonymousCartLDFlagEnabled ? productItemNew : productsById?.[id];

  const itemsLoading = useSelector((state: State) => state.cartItems.loading);
  const [quantityInputFocused, setQuantityInputFocused] = useState(false);
  const registerSettings = useRegisterSettings();
  const restrictToScale = registerSettings?.RestrictToScaleWeighing ?? false;
  const wgtInCart = cartItem?.Grams ?? 0;
  const isLoading = itemsLoading[id];
  const [weight, setWeight] = useState(grams.toString());
  const maxWgtAvailable = (productItem?.totalGrams ?? 0) + (includeCartQtyInMax ? wgtInCart : 0);
  const cartPopups = useCartPopups();

  const { showUseByDateAlert } = useMETRCUseByDateCheck({ product: productItem });

  const handleWeightChange = (newWeight?: string) => {
    const weightNumber = parseFloat(newWeight ?? weight);

    if (weightNumber > grams && weightNumber > maxWgtAvailable) {
      dispatch(errorNotification(`The max weight available is ${maxWgtAvailable}`));
      setWeight(grams.toString());
    } else {
      onWeightChange(weightNumber - grams);
    }
  };

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newWeight = removeLeadingZeros(target.value) || '0';
    setWeight(newWeight);
    if (!isInCartSummary) {
      handleWeightChange(newWeight);
    }
  };

  const handleKeyPress = (key: string) => {
    if (key === 'Enter') {
      handleWeightChange();
    }
  };

  const removeItem = useCallback(() => {
    onWeightChange(-1 * grams);
  }, [onWeightChange, grams]);

  function showCalculatorPopup() {
    const productToUse = isAnonymousCartLDFlagEnabled ? product : productItem ?? product;
    cartPopups.showBulkCalculatorPopup(productToUse, weight.toString(), {
      closeProductPreview,
      isPreorder: true,
      setTotalCost,
    });
  }

  function handleCalculatorButtonClick() {
    if (showUseByDateAlert) {
      dispatch(showProductBeingAddedPastUsedByDatePopup({ onContinue: showCalculatorPopup }));
    } else {
      showCalculatorPopup();
    }
  }

  useEffect(() => {
    setWeight(grams.toString());
  }, [grams]);

  return (
    <Container data-testid='product-selection-controls-container'>
      {isCartItemOrProductSearchResult(product) && (
        <MinusButton
          item={cartItem}
          isInCartSummary={isInCartSummary}
          grams={grams}
          disabled={grams <= 0 || isLoading || quantityInputFocused}
          onClick={removeItem}
          automationId='manage-cell_left-manage-button_minus'
        />
      )}
      {isLoading ? (
        <InputLoading automationId='quantity-selector_selector-button_input-loading' />
      ) : (
        <StyledBaseInput
          type='number'
          width={!isInCartSummary ? '4rem' : undefined}
          value={weight}
          onChange={handleInputChange}
          onKeyPress={({ key }) => handleKeyPress(key)}
          automationId='quantity-selector_selector-button_input'
          ddActionName='bulk selector input'
          disabled={restrictToScale}
          onFocus={() => {
            if (weight === '0') {
              setWeight('');
            }
            setQuantityInputFocused(true);
          }}
          onBlur={() => {
            if (weight === '') {
              setWeight('0');
            }
            handleWeightChange();
            setQuantityInputFocused(false);
          }}
        />
      )}
      <CalculatorButton
        disabled={!productItem?.totalGrams || isLoading || quantityInputFocused || grams !== parseFloat(weight)}
        onClick={handleCalculatorButtonClick}
        automationId='bulk-calculator-index_button_calculator'
      />
    </Container>
  );
};
