import { AppDispatch } from 'store';
import { getHelpDeskLink } from 'api/PosApi';
import { getQueryClient } from 'util/reactQuery';
import { getHelpDeskKeys } from './query-key-factory';
import { updateHelpDeskLink } from 'store/actions/SettingsActions';
import isEqual from 'lodash/isEqual';

export const getHelpDeskLinkQuery = async (dispatch: AppDispatch) => {
      const helpDeskLink = await getHelpDeskLinkRequest();
      if (helpDeskLink) {
        const queryClient = getQueryClient();
        const lspId = window.sessionStorage.getItem('LspId') || 0;
        const cachedHelpDeskLink = queryClient.getQueryData(getHelpDeskKeys.one(lspId));

        // Only update the cache if the data has changed or there is no data
        if (!cachedHelpDeskLink || !isEqual(cachedHelpDeskLink, helpDeskLink)) {
          queryClient.setQueryData(getHelpDeskKeys.one(lspId), helpDeskLink);
          dispatch(updateHelpDeskLink(helpDeskLink));
        }
      }
};


/** The API call helper to get the help desk link */
const getHelpDeskLinkRequest = async () => {
  const resp = await getHelpDeskLink();
  return resp;
};
