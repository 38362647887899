/**
 * Parses an error message from an error object to use for notifications
 */
export const parseErrorMessage = (error: unknown): string | unknown => {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  return error;
};

