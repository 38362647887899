type UseUnsavedChangesPopupParams = {
  save: () => Promise<boolean>;
  navigate: () => void;
  hide: () => void;
};
export const useUnsavedChangesPopup = ({ save, navigate, hide }: UseUnsavedChangesPopupParams) => {
  const handleSaveAndContinue = async () => {
    const didSave = await save();

    if (didSave) {
      navigate();
      hide();
    } else {
      hide();
    }
  };

  const handleDiscard = () => {
    navigate();
    hide();
  };

  return { handleSaveAndContinue, handleDiscard };
};
