import { ReactNode } from 'react';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { callback } from 'models/Misc';
import { CartItem } from 'models/Cart';
import {
  showChangeCustomerTypePopup,
  showHubBackClickPopup,
  showLast4Popup,
  showNotesPopup,
  showPaymentMethodDisabledPopup,
  showProductBeingAddedPastUsedByDatePopup,
  showProductUpsellPopup,
  showRemoveCartItemPopup,
  showScanFailedPopup,
} from 'store/actions/PopupsActions';
import { PopupsState } from '.';
import { GuestNotesModalMode } from 'components/CartPopups/components/GuestNotesModal/GuestNotesModal';
import { PaymentsHubProductUpsell } from 'models/Checkout';

export type CartPopupsPayloads = {
  showChangeCustomerTypePopup: {};
  showLast4Popup: { onSuccess: callback; serialNumber: string; onCancel?: callback };
  showNotesPopup: { guestId: number; mode?: GuestNotesModalMode; notes: string | undefined };
  showPaymentMethodDisabledPopup: { paymentMethod: string; message: string };
  showProductBeingAddedPastUsedByDatePopup: { onContinue: callback };
  showRemoveCartItemPopup: { item: CartItem; onRemoveItem: () => void };
  showScanFailedPopup: { message: ReactNode };
  showProductUpsellPopup: { productDetails: PaymentsHubProductUpsell };
  showHubBackClickPopup: { onCancel: callback };
};

export type CartPopupsState = {
  changeCustomerTypePopup: {} | null;
  last4Popup: { onSuccess: callback; serialNumber: string; onCancel?: callback } | null;
  notesPopup: { guestId: number; mode?: GuestNotesModalMode; notes: string | undefined } | null;
  paymentMethodDisabledPopup: { paymentMethod: string; message: string } | null;
  productBeingAddedPastUsedByDatePopup: { onContinue: callback } | null;
  removeCartItemPopup: { item: CartItem; onRemoveItem: () => void } | null;
  scanFailedPopup: { message: ReactNode } | null;
  productUpsellPopup: { productDetails: PaymentsHubProductUpsell } | null;
  hubBackClickPopup: { onCancel: callback } | null;
};

export const cartPopupsInitialState: CartPopupsState = {
  changeCustomerTypePopup: null,
  last4Popup: null,
  notesPopup: null,
  paymentMethodDisabledPopup: null,
  productBeingAddedPastUsedByDatePopup: null,
  removeCartItemPopup: null,
  scanFailedPopup: null,
  productUpsellPopup: null,
  hubBackClickPopup: null,
};

export const buildCartPopupsCases = (builder: ActionReducerMapBuilder<PopupsState>, initialState: PopupsState) => {
  builder.addCase(showChangeCustomerTypePopup, (_state, action) => ({
    ...initialState,
    changeCustomerTypePopup: action.payload,
  }));
  builder.addCase(showLast4Popup, (_state, action) => ({
    ...initialState,
    last4Popup: action.payload,
  }));
  builder.addCase(showNotesPopup, (_state, action) => ({
    ...initialState,
    notesPopup: action.payload,
  }));
  builder.addCase(showPaymentMethodDisabledPopup, (_state, action) => ({
    ...initialState,
    paymentMethodDisabledPopup: action.payload,
  }));
  builder.addCase(showProductBeingAddedPastUsedByDatePopup, (_state, action) => ({
    ...initialState,
    productBeingAddedPastUsedByDatePopup: action.payload,
  }));
  builder.addCase(showRemoveCartItemPopup, (_state, action) => ({
    ...initialState,
    removeCartItemPopup: action.payload,
  }));
  builder.addCase(showScanFailedPopup, (_state, action) => ({
    ...initialState,
    scanFailedPopup: action.payload,
  }));
  builder.addCase(showProductUpsellPopup, (_state, action) => ({
    ...initialState,
    productUpsellPopup: action.payload,
  }));
  builder.addCase(showHubBackClickPopup, (_state, action) => ({
    ...initialState,
    hubBackClickPopup: action.payload,
  }));
};
