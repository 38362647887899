import { Skeleton } from 'components/misc';
import React from 'react';
import styled from 'styled-components';

const ListViewSkeleton = () => {
  return (
    <ListViewSkeletonContainer>
      <CardColumnContainer>
        <CardColumnHeader />
        <CardItem />
        <CardItem />
        <CardItem />
        <CardItem />
      </CardColumnContainer>
    </ListViewSkeletonContainer>
  );
};

const CardItem = () => (
  <Card>
    <CardInfoHeader>
      <Skeleton width={163} height={24} rounded />
      <Skeleton width={163} height={24} rounded />
    </CardInfoHeader>
    <CardInfoHeader>
      <Skeleton width={706} height={69} rounded />
      <Skeleton width={93} height={24} rounded />
    </CardInfoHeader>
    <Skeleton width={528} height={24} rounded />
  </Card>
);

const CardColumnHeader = () => (
  <>
    <Skeleton height={2} />
    <Skeleton width={142} height={16} marginBottom={20} rounded />
  </>
);

export default ListViewSkeleton;

const ListViewSkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 100%;
`;

const CardColumnContainer = styled.div`
  min-width: 312px;
  width: 100%;
  color: black;
`;

const Card = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => `${height}px` || '100%'};
  background-color: white;
  border-radius: 6px;
  border-left: 5px solid #e3e7e9;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const CardInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
