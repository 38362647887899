import { PeripheralServiceConfig, PeripheralType } from "@dutchie/capacitor-hardware";
import { MockedPeripheralService } from "../../shared/mock/MockedPeripheralService";


import { MockLabelPrinter } from "./MockLabelPrinter";

export class MockLabelPrinterService extends MockedPeripheralService<MockLabelPrinter> {
  constructor(config?: { service?: Partial<PeripheralServiceConfig>; isSupported?: () => boolean}) {
    super(PeripheralType.labelPrinter, {
      defaultConfig: { name: 'MockLabelPrinterService', autoConnect: false, autoEnable: true },
      userConfig: config?.service,
    });

    this.devices = [
      this.createDevice('1111', 'Mock Label Printer 1'),
      this.createDevice('2222', 'Mock Label Printer 2'),
    ];
  }

  createDevice(id: string, name: string): MockLabelPrinter {
    return new MockLabelPrinter({ id, name, serviceName: this.name });
  }
}
