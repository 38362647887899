import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { PopupsState } from '.';
import { showAddNewDevicePopup } from 'store/actions/PopupsActions/SettingsPopupActions';

import type { AddNewDeviceModalProps } from 'pages/SettingsPage/Tabs/Hardware/components/AddDeviceListItem/AddNewDeviceModal';

export type SettingsPopupPayloads = {
  addNewDevicePopup: AddNewDeviceModalProps;
};

export type SettingsPopupState = {
  addNewDevicePopup: SettingsPopupPayloads['addNewDevicePopup'] | null;
};

export const settingsPopupInitialState: SettingsPopupState = {
  addNewDevicePopup: null,
};

export const buildSettingsPopupCases = (builder: ActionReducerMapBuilder<PopupsState>, initialState: PopupsState) => {
  builder.addCase(showAddNewDevicePopup, (_state, action) => ({
    ...initialState,
    addNewDevicePopup: action.payload,
  }));
};
