import { useAppSelector } from 'util/hooks';
import { State } from 'store';

// Determine if the Biotrack settings feature is enabled for determining component visibility.
export const useBiotrackSettingsFeature = (): boolean => {
  const integrations = useAppSelector((state: State) => state.settings.integrations);
  const locationSettings = useAppSelector((state: State) => state.settings.locationSettings);
  const showBioTrackCredentialsMgmt = integrations?.UseBioTrack && locationSettings?.State.toLowerCase() === 'fl';
  return !!showBioTrackCredentialsMgmt;
};
