import { useSelector } from 'react-redux';
import { differenceInYears } from 'date-fns';

import type { State } from 'store';
import type { CustomerDetails } from 'models/Customer';
import type { CheckedInGuest } from 'models/Guest';

type GuestData = (CustomerDetails & { Dob?: string }) | (CheckedInGuest & { DOB?: string });

type GuestAgeInfoProps = {
  guest?: GuestData;
  isCheckedInWithCaregiver?: boolean;
};

type GuestAgeInfoResponse = {
  isBlocked: boolean;
  defaultMinorAge: number;
};

export const useGuestAgeInfo = ({ guest, isCheckedInWithCaregiver }: GuestAgeInfoProps): GuestAgeInfoResponse => {
  const isBlockMinorsFromCheckInEnabled = useSelector(
    (state: State) => state.settings.features.BlockMinorsFromCheckInFF
  );
  const isCollectAnonymousDemoEnabled = useSelector((state: State) => state.settings.features.CollectAnonymousDemo);
  const DefaultMinorAge = useSelector((state: State) => state.settings.locationSettings?.DefaultMinorAge);
  const DefaultRecMinorAge = useSelector((state: State) => state.settings.locationSettings?.DefaultRecMinorAge);

  const guestAge = guest?.DOB ?? guest?.Dob;
  const guestYears = differenceInYears(new Date(), new Date(guestAge ?? new Date()));
  const defaultMinorAge = guest?.IsMedical
    ? DefaultMinorAge ?? DefaultRecMinorAge ?? 18
    : DefaultRecMinorAge ?? DefaultMinorAge ?? 18;

  if (!isBlockMinorsFromCheckInEnabled || isCheckedInWithCaregiver) {
    return { isBlocked: false, defaultMinorAge };
  }

  const isGuestAMinor = guestYears < defaultMinorAge;
  const isDOBRequired = !guest?.IsAnonymous || isCollectAnonymousDemoEnabled;
  if (isGuestAMinor && isDOBRequired) {
    return { isBlocked: true, defaultMinorAge };
  }

  return { isBlocked: false, defaultMinorAge };
};
