import React from 'react';
import styled from 'styled-components';
import { usePreview, usePreviewState, usePreviewStateContent } from 'react-dnd-preview';

import { CheckedInGuest } from 'models/Guest';
import { OrderKanbanCard } from '../OrderKanbanCard';
import { useOptionsStatus } from '../useOptionsStatus';

type Item = {
  guest: CheckedInGuest;
};

type Preview = usePreviewState & usePreviewStateContent<Item, HTMLDivElement>;

export const DragPreview = () => {
  const { display, item, style } = usePreview<Item, HTMLDivElement>() as Preview;
  const guest = (item as Item)?.guest;
  const posStatusId = guest?.Status?.PosStatusId;
  const { cardOptionsStatus } = useOptionsStatus({ posStatusId });

  if (!display) {
    return null;
  }

  return (
    <DragPreviewWrapper style={style}>
      <OrderKanbanCard guest={guest} cardOptionsStatus={cardOptionsStatus} listMode={false} />
    </DragPreviewWrapper>
  );
};

const DragPreviewWrapper = styled.div`
  z-index: 3;
`;
