import { colors } from 'css/Theme';
import { clamp, round } from 'lodash';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import styled from 'styled-components';
import { DeliveryRoutesTableV2 } from './DeliveryRoutesTableV2';

export const DeliveryTableSectionV2: FC = React.memo(() => {
  const deliveryMaxTotalInventoryDollars =
    useSelector((state: State) => state.settings.locationSettings?.DeliveryMaxTotalInventoryDollars) || 0;
  const hasDeliveryMaxTotalInventoryDollars = !!useSelector(
    (state: State) => state.settings.locationSettings?.DeliveryMaxTotalInventoryDollars
  );
  const selectedDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);
  const selectedDollarValue = round(
    selectedDeliveries.reduce((acc, d) => acc + d.InvoiceTotal, 0),
    2
  );

  const selectedDollarPercent = clamp(selectedDollarValue / deliveryMaxTotalInventoryDollars, 0, 100) * 100;

  return (
    <div>
      {hasDeliveryMaxTotalInventoryDollars && (
        <>
          <ThresholdContainer>
            <ThresholdWrapper>
              <div>Selected Inventory Threshold</div>
              <ThresholdStats>
                ${selectedDollarValue.toFixed(2)} / ${deliveryMaxTotalInventoryDollars.toFixed(2)}
              </ThresholdStats>
            </ThresholdWrapper>
            <ProgressBar>
              <Fillbar used={selectedDollarPercent} over={selectedDollarValue > deliveryMaxTotalInventoryDollars} />
            </ProgressBar>
          </ThresholdContainer>
        </>
      )}
      <TableWrapper>
        <DeliveryRoutesTableV2 />
      </TableWrapper>
    </div>
  );
});

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  overflow-x: scroll;
  & tr[draggable='true'] {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`;

export const ThresholdContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${colors.dutchie.almostBlack};
  padding: 1.25rem 2rem;
  border-bottom: 1px solid #e3e7e9;
`;

const ThresholdWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ThresholdStats = styled.div`
  color: #121516;
`;

const ProgressBar = styled.div`
  height: 8px;
  background: ${colors.dutchie.shadowGrey};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
`;

const Fillbar = styled.div<{ used: number; over: boolean }>`
  width: ${({ used }) => used.toFixed(2)}%;
  border-radius: 8px;
  height: 8px;
  background: ${({ over }) => (over ? colors.dutchie.red : colors.dutchie.moss)};
`;
