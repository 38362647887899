export const RECEIPT_PRINTER_LOCAL_STORAGE_KEYS = {
  IS_LOCAL: 'useConnectedReceiptPrinter',
  SELECTED_OBJECT: 'LocalReceiptPrinter',
};

export const FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS = {
  IS_LOCAL: 'useConnectedFulfillmentPrinter',
  SELECTED_OBJECT: 'LocalFulfillmentPrinter',
};

export const LABEL_PRINTER_LOCAL_STORAGE_KEYS = {
  IS_LOCAL: 'useConnectedLabelPrinter',
  SELECTED_OBJECT: 'LocalLabelPrinter',
};

