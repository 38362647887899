import React from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { CardLabel, CardText } from '../styles';
import { thousandsFormatter } from 'util/Helpers';

type Props = {
  displayValueInCart: boolean;
  displayNumberInCart: boolean;
  orderTotal: number;
  totalItems: number;
};

export const OrderTotal = React.memo(({ orderTotal, totalItems, displayValueInCart, displayNumberInCart }: Props) => {
  return (
    <CardText>
      <CardLabel>Order total:</CardLabel>
      <CardOrderContainer>
        <CardOrderValue>
          {displayValueInCart && (
            <>
              <span data-testid='order-card_value-of-items_span'>{`$${thousandsFormatter(orderTotal)}`}</span>
              <CardOrderValueSeperator>{'|'}</CardOrderValueSeperator>
            </>
          )}
        </CardOrderValue>
        <CardOrderValue>
          {displayNumberInCart && (
            <span data-testid='order-card_number-of-items_span'>
              {`${totalItems} ${totalItems > 1 ? 'items' : 'item'}`}
            </span>
          )}
        </CardOrderValue>
      </CardOrderContainer>
    </CardText>
  );
});

const CardOrderContainer = styled.div`
  display: flex;
  width: 60%;
`;

const CardOrderValue = styled.div`
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
`;

const CardOrderValueSeperator = styled.span`
  margin: 0 8px;
  font-weight: 400;
  color: ${colors.dutchie.grey};
`;
