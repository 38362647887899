import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isProductSearchResult } from 'util/helpers/products/isProductSearchResult';

import type { AnyProductSearchResult } from 'util/helpers/products/isProductSearchResult';
import type { State } from 'store';

/**
 * Clean up when we update the PreorderProductSearchResult type
 */
enum PriceField {
  RecUnitPrice = 'RecUnitPrice',
  UnitPrice = 'UnitPrice',
}

enum NewPriceField {
  RecUnitPrice = 'recUnitPrice',
  UnitPrice = 'unitPrice',
}

export type UseProductSearchPricingReturn = {
  getPriceField: () => PriceField;
  getNewPriceField: () => NewPriceField;
  getPrice: (product?: AnyProductSearchResult) => number;
};

export const useProductSearchPricing = (): UseProductSearchPricingReturn => {
  const { DualPricing } = useSelector((state: State) => state.settings.features);
  const isMedical = useSelector((state: State) => state.customer.details?.IsMedical);

  const isRecreationalPricing = DualPricing && !isMedical;

  const getNewPriceField = useCallback(() => {
    return isRecreationalPricing ? NewPriceField.RecUnitPrice : NewPriceField.UnitPrice;
  }, [isRecreationalPricing]);

  const getPriceField = useCallback(() => {
    return isRecreationalPricing ? PriceField.RecUnitPrice : PriceField.UnitPrice;
  }, [isRecreationalPricing]);

  const getPrice = useCallback(
    (product?: AnyProductSearchResult) => {
      if (isProductSearchResult(product)) {
        return isRecreationalPricing ? product?.recUnitPrice : product?.unitPrice ?? 0.0;
      }
      return (isRecreationalPricing ? product?.RecUnitPrice : product?.UnitPrice) ?? 0.0;
    },
    [isRecreationalPricing]
  );

  return {
    getNewPriceField,
    getPriceField,
    getPrice,
  };
};
