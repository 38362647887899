import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { pendoInit } from './pendo';
import { QueryClientProvider } from '@tanstack/react-query';
import { initializeReactQueryClient } from 'util/reactQuery';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { initializeLDClient } from 'util/launchDarkly';
import { logger } from 'util/logger';

import { App } from 'App';
import 'css/index.scss';
import { getEnvironmentDetails } from 'api/PosApi';
import { loadRegion } from 'store/actions/SettingsActions';
import { store } from 'store';
import * as process from 'process';

if (process.env.NODE_ENV !== 'development') {
  pendoInit('28a64b1a-322b-48a4-6319-f6c7ad86b901');
}

(async () => {
  let ldKey = '';
  let region = '';
  try {
    //hack.  if the android app was hooked up to a specific region before, use the same region to determine the API that we're going to hit for the LaunchDarkly key (e.g. parallel)
    //on first load ever, this will fail and we'll default to the production launchDarkly key
    await store.dispatch(loadRegion());

    const envDetails = await getEnvironmentDetails();
    ldKey = envDetails.LaunchDarklyClientKey;
    region = envDetails.Region;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to load LaunchDarkly client key: ${e}`);
  }

  const clientSideID = ldKey || process.env.REACT_APP_LD_PRODUCTION_KEY || '';

  const ldClient = await initializeLDClient(clientSideID, region);

  // Initializes the logger with providers specified in LaunchDarkly
  // Note: To test logs in development, set turnOnForDevelopment to true
  logger.init({ turnOnForDevelopment: process.env.ENABLE_DATADOG_FOR_DEVELOPMENT === 'true' });

  const queryClient = initializeReactQueryClient();

  const AppWithReactQuery = () => (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );

  const AppWithLDProvider = withLDProvider({
    ldClient,
    clientSideID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
  })(AppWithReactQuery);

  ReactDOM.render(<AppWithLDProvider />, document.getElementById('react-root'));
})();
