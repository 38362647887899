import { isAndroid } from 'util/hooks/android/useAndroidPeripherals';
import { useAppSelector } from 'util/hooks/useAppDispatch';
import { getLDClient } from 'util/launchDarkly';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const key = 'pos.register.smart-checkin-killswitch';
const getIsSmartCheckinKillswitchActive = () => {
  const client = getLDClient();
  return client?.variation(key, false) === true;
};

const useIsSmartCheckinKillswitchActive = () => {
  const client = useLDClient();
  return client?.variation(key, false) === true;
};

/**
 * Determines if the Smart Checkin (Pusher for guestlist) feature should be used
 * @note Can be used if FF is on and the device is not Android
 */
export const getIsSmartCheckinActive = (isUseSmartCheckinEnabled: boolean) => {
  const isLdKillswitchActive = getIsSmartCheckinKillswitchActive();

  if (isLdKillswitchActive) {
    return false;
  }

  return isUseSmartCheckinEnabled && !isAndroid;
};

/**
 * Determines if the Smart Checkin (Pusher for guestlist) feature should be used
 * @note Can be used if FF is on and the device is not Android
 */
export const useSmartCheckinActive = () => {
  const isLdKillswitchActive = useIsSmartCheckinKillswitchActive();
  const isUseSmartCheckinEnabled = useAppSelector((state) => state.settings.features.UseSmartCheckin);

  if (isLdKillswitchActive) {
    return false;
  }

  return getIsSmartCheckinActive(isUseSmartCheckinEnabled);
};
