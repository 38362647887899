import { css } from 'styled-components';

export const fontFaces = css`
  @font-face {
    font-family: Matter;
    src: local('Matter Regular'), local('Matter-Regular'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Regular.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Regular.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Regular Italic'), local('Matter-RegularItalic'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-RegularItalic.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-RegularItalic.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Light'), local('Matter-Light'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Light.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Light.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Light Italic'), local('Matter-LightItalic'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-LightItalic.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-LightItalic.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Medium'), local('Matter-Medium'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Medium.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Medium.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Medium Italic'), local('Matter-MediumItalic'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-MediumItalic.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-MediumItalic.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter SemiBold'), local('Matter-SemiBold'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-SemiBold.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-SemiBold.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter SemiBold Italic'), local('Matter-SemiBoldItalic'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-SemiBoldItalic.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-SemiBoldItalic.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Bold'), local('Matter-Bold'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Bold.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Bold.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Bold Italic'), local('Matter-BoldItalic'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-BoldItalic.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-BoldItalic.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Heavy'), local('Matter-Heavy'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Heavy.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Heavy.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-Heavy.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: Matter;
    src: local('Matter Heavy Italic'), local('Matter-HeavyItalic'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-HeavyItalic.woff2') format('woff2'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-HeavyItalic.woff') format('woff'),
      url('https://assets2.dutchie.com/platform-assets/Matter/Matter-HeavyItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
  }
`;
