import { memoized } from './memoized';

export const getPaginationDetails = memoized((numItems: number, pageSize = Infinity, unclampedCurrentPage: number) => {
  const pageCount = Math.ceil(numItems / pageSize);
  const currentPage = Math.min(Math.max(1, unclampedCurrentPage), pageCount);
  const zeroBasedCurrentPage = currentPage - 1;
  const isAtStart = currentPage === 1;
  const isAtEnd = currentPage === pageCount;
  const zeroBasedCurrentPageStartIndex = pageSize * zeroBasedCurrentPage;
  const zeroBasedNextPageStartIndex = zeroBasedCurrentPageStartIndex + pageSize;
  const zeroBasedCurrentPageEndIndex = zeroBasedNextPageStartIndex - 1;

  return {
    numItems,
    pageSize,
    pageCount,
    currentPage,
    zeroBasedCurrentPageStartIndex,
    zeroBasedCurrentPageEndIndex,
    zeroBasedNextPageStartIndex,
    oneBasedCurrentPageStartIndex: zeroBasedCurrentPageStartIndex + 1,
    oneBasedCurrentPageEndIndex: zeroBasedCurrentPageEndIndex + 1,
    oneBasedNextPageStartIndex: zeroBasedNextPageStartIndex + 1,
    isAtStart,
    isAtEnd,
    hasPagination: pageCount > 1,
  };
});
