import React from 'react';

type IconProps = {
  height?: number;
  size?: number;
  width?: number;
  color?: string;
};

export const ChevronIcon = ({
  width = 24,
  height = 24,
  size,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={size ?? width} height={size ?? height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.6909 13.0437C5.4396 12.8034 5.44013 12.4142 5.69209 12.1745L10.0745 8.00489L5.70393 3.82401C5.45265 3.58364 5.45322 3.19446 5.7052 2.95477C5.95718 2.71507 6.36515 2.71561 6.61643 2.95598L11.2143 7.35426C11.3038 7.4399 11.3752 7.54185 11.4236 7.65367C11.4721 7.76563 11.497 7.8856 11.4968 8.00673C11.4966 8.12786 11.4714 8.24777 11.4226 8.3596C11.3739 8.47129 11.3026 8.57273 11.2128 8.65814L6.60213 13.0449C6.35017 13.2846 5.9422 13.2841 5.6909 13.0437Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.61011 13.1206C5.31431 12.8377 5.31494 12.3796 5.61151 12.0974L9.91316 8.00469L5.62313 3.90086C5.32736 3.61793 5.32803 3.15984 5.62463 2.87769C5.92123 2.59555 6.40145 2.59619 6.69722 2.87913L11.2951 7.2774C11.3952 7.37315 11.4749 7.48709 11.5291 7.61211C11.5833 7.7373 11.6111 7.87144 11.6109 8.00688C11.6107 8.14231 11.5825 8.27638 11.528 8.40143C11.4735 8.52631 11.3937 8.63973 11.2933 8.73522L6.6827 13.1219C6.38613 13.4041 5.90591 13.4035 5.61011 13.1206ZM5.77267 12.2516C5.56533 12.4488 5.56489 12.7691 5.77169 12.9669C5.97849 13.1647 6.31421 13.1651 6.52155 12.9678L11.1318 8.5814C11.2111 8.50608 11.2743 8.41628 11.3173 8.31778C11.3603 8.21915 11.3825 8.1134 11.3827 8.00658C11.3828 7.89976 11.3609 7.79395 11.3182 7.69522C11.2755 7.59661 11.2128 7.50699 11.1338 7.43146L6.53563 3.03284C6.32885 2.83503 5.99313 2.83459 5.78577 3.03184C5.57841 3.22909 5.57794 3.54934 5.78472 3.74715L10.1553 7.92804C10.1998 7.9706 10.1997 8.03951 10.1551 8.08196L5.77267 12.2516ZM10.2215 8.14557C10.2661 8.10312 10.3383 8.10318 10.3829 8.14571C10.4273 8.18826 10.4277 8.25754 10.3831 8.29999C10.3385 8.34244 10.2662 8.34238 10.2217 8.29985C10.1772 8.25729 10.1769 8.18802 10.2215 8.14557ZM10.2219 7.8646C10.1774 7.82203 10.1776 7.75313 10.2222 7.7107C10.2668 7.66828 10.3394 7.66803 10.3839 7.7106C10.4284 7.75316 10.4283 7.82206 10.3837 7.8645C10.339 7.90692 10.2664 7.90717 10.2219 7.8646Z" fill={color}/>
    </svg>


  );
};
