import React, { FC, useState, useMemo } from 'react';
import { useHistory } from 'react-router';

import {
  closePopup,
  showAnonymousTransactionPopup,
  showCreatePreOrderPopup,
  showFulfillmentSelectorPopup,
  showVerifyBirthdatePopup,
} from 'store/actions/PopupsActions';
import { createAnonymousCustomer } from 'api/GuestApi';
import { createAnonymousTransaction } from 'store/actions/GuestListActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { clearCustomerDetails, getCustomerDetails } from 'store/actions/CustomerActions';
import { has } from 'lodash';
import { Loader } from 'components/backoffice/loader';
import { NavButton } from 'components/buttons';
import { ReactComponent as GhostIcon } from 'assets/icons/ghost.svg';
import { useAppDispatch, useAppSelector, useCheckPreorderEligible } from 'util/hooks';
import { useMetrics } from 'util/metrics/useMetrics';
import { customEventKeys } from 'util/logger';

import type { PreorderMetadataValues } from 'models/Customer';

export const AnonymousGuestButton: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const metrics = useMetrics();

  const features = useAppSelector((state) => state.settings.features);

  const [isCreatingAnonymousTransaction, setIsCreatingAnonymousTransaction] = useState(false);
  const checkPreorderEligible = useCheckPreorderEligible();

  const onCreateAnonymousPreorder = async (val: PreorderMetadataValues) => {
    try {
      const response = await createAnonymousCustomer({
        Dob: val.dob?.toDateString(),
        Gender: val.gender,
        PostalCode: val.postalCode,
        CustomerTypeId: val.customerTypeId,
      });

      await dispatch(getCustomerDetails({ guestId: response.AcctId }));
      dispatch(closePopup('createPreOrderPopup'));
      history.push('/create-preorder');
    } catch (e) {
      dispatch(errorNotification(`Error creating anonymous transaction: ${e}`));
    }
  };

  const onCreateAnonymousTransaction = async (anonymousDob?: Date) => {
    if (!features['CollectAnonymousDemo']) {
      metrics.addTimestamp(customEventKeys.metrics.timestamps.clickAnonymousGuestButton);
      setIsCreatingAnonymousTransaction(true);
      const res = await dispatch(createAnonymousTransaction());
      if (!has(res, 'error')) {
        // Make sure customer details are cleared to avoid race condition where old customer details are used in new cart
        dispatch(clearCustomerDetails());

        history.push(`/cart`);
      }
      setIsCreatingAnonymousTransaction(false);
    } else {
      dispatch(showAnonymousTransactionPopup({ dob: anonymousDob }));
    }
  };

  const tryCreateAnonymousTransaction = (anonymousDob?: Date) => {
    // Check if the birthdate on check in flag is enabled and open popup accordingly
    if (features.ConfirmBirthdayOnCheckin && anonymousDob === undefined) {
      dispatch(
        showVerifyBirthdatePopup({
          onSuccess: (anonymousCheckinDob) => {
            tryCreateAnonymousTransaction(anonymousCheckinDob);
          },
        })
      );
      return;
    }

    // Check if fulfillment model is enabled and show selector popup as necessary
    if (checkPreorderEligible()) {
      dispatch(
        showFulfillmentSelectorPopup({
          onGoToCart: async () => {
            await onCreateAnonymousTransaction(anonymousDob);
          },
          onCreatePreorder: async () => {
            dispatch(showCreatePreOrderPopup({ onCheckIn: onCreateAnonymousPreorder, anonymousDob: anonymousDob }));
          },
        })
      );
      return;
    }

    // Default to showing the anonymous transaction popup
    onCreateAnonymousTransaction(anonymousDob);
  };

  const AnonButtonIcon = useMemo(
    () => (isCreatingAnonymousTransaction ? <Loader variant='grey' /> : <GhostIcon />),
    [isCreatingAnonymousTransaction]
  );

  if (!features.RecreationalFlag) {
    return null;
  }

  return (
    <NavButton
      onClick={() => tryCreateAnonymousTransaction()}
      icon={AnonButtonIcon}
      disabled={isCreatingAnonymousTransaction}
      automationId='action-bar_button_anon-guest'
      margin='0'
    >
      {isCreatingAnonymousTransaction ? 'Loading...' : 'Anonymous Guest'}
    </NavButton>
  );
};
