export function isDateBeforeToday(dateToCompare: Date | string): boolean {
  const dateToCompareAsDateObject = new Date(dateToCompare);

  if (dateToCompareAsDateObject.toString() === 'Invalid Date') {
    return false;
  }

  const todayDate = new Date();

  // :keanu-whoaaaaa
  const todayDateWithoutTime = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());

  return dateToCompareAsDateObject < todayDateWithoutTime;
}
