import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { Key } from './Key';
import { initiatePinLogin } from 'api/PinLoginApi';
import { handlePinLogin } from 'store/actions/UserActions';
import { useDispatch } from 'react-redux';
import { endSessionLogout } from './utils/endSessionLogout';
import { LDServerMigrationConfig } from 'components/layout/ServerMigrationUI/useServerMigrationConfig';
import { setServerMigrationConfig } from 'store/actions/SettingsActions';
import { useFlags } from "launchdarkly-react-client-sdk";

// keep ORDERED_KEYPAD_KEYS in sync with the order of the keys in the keypad
const ORDERED_KEYPAD_KEYS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Clear', '0'];
const ALL_KEYPAD_OPTIONS = [...ORDERED_KEYPAD_KEYS, 'Delete', 'Backspace'];
const MAX_PIN_LENGTH = 10;

type KeypadKeysPropsType = {
  handleOpenResetPin: () => void;
  welcomeMessage: string;
  isAndroid?: boolean;
};
interface StyledKeypadInputValueProps {
  loginAttemptState?: 'success' | 'error' | undefined;
  pin: string;
}

export const KeypadKeys = ({ welcomeMessage, handleOpenResetPin, isAndroid = false }: KeypadKeysPropsType): JSX.Element => {
  const [pin, setPin] = useState<string>('');
  const [loginAttemptState, setLoginAttemptState] = useState<'success' | 'error' | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>('Unable to log in. Please try again.');
  const flags = useFlags();

  const initiateLogin = async () => initiatePinLogin({ Pin: pin, ldFlagSet: flags });
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (pin.length < 1) {
      return;
    }
    initiateLogin()
      .then(() => {
        setLoginAttemptState('success');
        dispatch(handlePinLogin());
      })
      .catch(({ message, data }: { message: string; data: LDServerMigrationConfig }) => {
        if (data?.shouldShowBlockingUI) {
          dispatch(setServerMigrationConfig(data));
          return;
        } else if (message.includes('Incorrect PIN')) {
          setErrorMessage(message);
        } else if (message.includes('Session has expired')) {
          setErrorMessage('Your session has expired. Please log in with your username and password.');
        } else if (message.includes('You have exceeded')) {
          setErrorMessage(message);
          setTimeout(() => endSessionLogout(), 1500);
        } else if (message.includes('User does not have access')) {
          setErrorMessage(message);
        } else {
          setErrorMessage('Unable to log in. An unknown error occurred.');
        }
        setLoginAttemptState('error');
        setTimeout(() => handleSetPin('Clear'), 750);
      });
  };

  const handleSetPin = (value: string) => {
    setPin((prevPin: string) => {
      switch (value) {
        case 'Clear':
          return '';
        case 'Delete':
        case 'Backspace':
          return prevPin.slice(0, -1);
        default:
          return prevPin.length < MAX_PIN_LENGTH ? prevPin + value : prevPin;
      }
    });
  };

  const handleResetPin = () => handleOpenResetPin();

  const addAsterisksForPinLength = (pin: string) =>
    pin.length === 0 ? (
      <span>&nbsp;</span>
    ) : (
      pin
        .split('')
        .map(() => '•')
        .join('')
    );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const keyPressed = event.key;
      if (ALL_KEYPAD_OPTIONS.includes(keyPressed)) {
        event.preventDefault();
        handleSetPin(keyPressed);
      }
      if (keyPressed === 'Enter') {
        handleSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  return (
    <StyledKeypad>
      <StyledKeypadTitle data-testid='pin-login-welcome-message'>{welcomeMessage}</StyledKeypadTitle>
      <StyledKeypadInputValue data-testid='pin-login-input' pin={pin} loginAttemptState={loginAttemptState}>
        {addAsterisksForPinLength(pin)}
      </StyledKeypadInputValue>
      {loginAttemptState === 'error' && (
        <StyledPINError data-testid='pin-login-error-message'>{errorMessage}</StyledPINError>
      )}
      <StyledKeypadKeys>
        {ORDERED_KEYPAD_KEYS.map((key) => (
          <Key isAndroid={isAndroid} value={key} key={key} onClick={() => handleSetPin(key)} testid={`pin-login-key-${key}`} />
        ))}
        <Key isAndroid={isAndroid} testid='pin-login-enter-key' value='Enter' onClick={() => handleSubmit()} />
      </StyledKeypadKeys>
      <StyledResetLink data-testid='reset-pin-login-link' onClick={handleResetPin}>
        Reset PIN
      </StyledResetLink>
    </StyledKeypad>
  );
};

const StyledKeypad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  max-width: 430px;
  width: 100%;
  margin: 0 auto;
  background-color: ${colors.dutchie.opal90};
`;

const StyledKeypadTitle = styled.div`
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  color: ${colors.dutchie.primaryWhite};
  margin-bottom: 24px;
`;

const StyledKeypadInputValue = styled.div<StyledKeypadInputValueProps>`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: ${({ loginAttemptState, pin }) => {
    switch (loginAttemptState) {
      case 'success':
        return colors.dutchie.green;
      case 'error':
        return `#EB3F50`;
      default:
        return colors.dutchie.primaryWhite;
    }
  }};
  margin-bottom: 32px;
  border-bottom: ${({ loginAttemptState, pin }) => {
    switch (loginAttemptState) {
      case 'success':
        return `1px solid ${colors.dutchie.green}`;
      case 'error':
        return `1px solid #EB3F50`;
      default:
        return `1px solid ${colors.dutchie.primaryWhite}`;
    }
  }};
  padding-bottom: 16px;
  padding-left: 16px;
  letter-spacing: 16px;
`;

const StyledKeypadKeys = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const StyledResetLink = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #8197ac;
  margin-top: 24px;
  cursor: pointer;
`;

const StyledPINError = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #eb3f50;
  margin-bottom: 40px;
`;
