import React from 'react';
import styled, { css } from 'styled-components';

import { BottomDrawer, TabbedContent } from 'components/layout';
import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { ProvideTransactionsPopups } from 'components/TransactionsPopups';
import { useCustomerHistoryPanel } from './useCustomerHistoryPanel';

export enum CustomerHistoryPanelTabs {
  Transactions = 'Transaction history',
  Products = 'Product history',
}

export const CustomerHistoryPanel = () => {
  const { customerHistoryTabs, FullScreenPortalId, handleClose, isCustomerHistoryPanelOpen, selectedTab } =
    useCustomerHistoryPanel();

  return (
    <BottomDrawer
      fullHeight
      open={isCustomerHistoryPanelOpen}
      onClose={handleClose}
      portalId={FullScreenPortalId}
      marginX={0}
      marginY={40}
    >
      {isCustomerHistoryPanelOpen && (
        <CustomerHistoryContainer data-testid='customer-history-container'>
          <Header>
            <Title>Customer history</Title>
            <Button tertiary onClick={handleClose}>
              Close
            </Button>
          </Header>
          <Content>
            <ProvideTransactionsPopups>
              <TabbedContent
                containerStyles={StyledTabContainer}
                tabs={customerHistoryTabs}
                selectedTab={selectedTab}
              />
            </ProvideTransactionsPopups>
          </Content>
        </CustomerHistoryContainer>
      )}
    </BottomDrawer>
  );
};

const CustomerHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  align-items: center;
  justify-content: space-between;
  padding: 1.1875rem 2rem;
  gap: 1rem;
  border-bottom: 1px solid ${colors.dutchie.grey90};
`;

const Title = styled.h1`
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
`;

const StyledTabContainer = css`
  background: white;
  border-radius: 0.75rem;
  max-height: 100%;
  padding: 1rem 1.25rem;
  gap: 1.5rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background: ${colors.dutchie.grey10};
  padding: 2rem 2.5rem 0;
`;
