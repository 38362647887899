import { useCallback } from 'react';
import { clone, reduce } from 'lodash';

import { getProductId } from 'util/helpers/products/getProductId';
import { useAppSelector } from 'util/hooks/useAppDispatch';
import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';

import type { ProductSearchResult } from 'queries/v2/product/types';

type ProductsById = Record<string, ProductSearchResult>;
type ProductSelector<TData = ProductsById> = (productsById: ProductsById) => TData;

export const transformProducts = <TData = ProductsById>(
  products: ProductSearchResult[],
  selector?: ProductSelector<TData>
) => {
  const productsById = reduce(
    products,
    (acc: ProductsById, product: ProductSearchResult) => {
      const productId = getProductId(product);
      if (acc[productId] && product.productType === 'Wgt') {
        const productItem = clone(acc[productId]);
        if (productItem.unitGrams && product.unitGrams) {
          productItem.unitGrams += product.unitGrams;
        }
        acc[productId] = productItem;
      } else {
        acc[productId] = product;
      }
      return acc;
    },
    {}
  );

  // This ensures the type is always forwarded up as TData and doesn't explicitly include ProductsById as a potential type
  // If a selector is passed in, the type will always be the return type of the selector transformation function
  const selectorToUse = selector ?? (<TData = ProductsById>(productsById: ProductsById) => productsById as TData);

  return selectorToUse(productsById);
};

export const useProducts = <TData = ProductsById>(selector?: ProductSelector<TData>) => {
  const customerId = useAppSelector((state) => state.customer.details?.Guest_id);
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const { data } = useGetAllProductsQuery(
    { customerId, registerId },
    {
      select: useCallback((products) => transformProducts(products, selector), [selector]),
    }
  );

  // This data type will either be ProductsById (default) or the return type of the selector function
  return data;
};
