import { GuestNotesModalMode } from 'components/CartPopups/components/GuestNotesModal/GuestNotesModal';
import { useMemo } from 'react';

import { showNotesPopup } from 'store/actions/PopupsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';

/** The maximum amount of characters to show in the sidebar preview */
const CharacterLimit = 250;

export const useGuestNotes = () => {
  const dispatch = useAppDispatch();

  const guestId = useAppSelector((state) => state.customer.details?.Guest_id);
  const notes = useAppSelector((state) => state.customer.details?.notes);

  const notesToDisplay = useMemo(() => {
    if ((notes?.length ?? 0) > CharacterLimit) {
      return `${notes?.slice(0, CharacterLimit)}...`;
    }

    return notes;
  }, [notes]);

  const areNotesEmpty = !notes || notes.length === 0;
  const isEditDisabled = !guestId;
  const showSeeMore = (notes?.length ?? 0) > CharacterLimit;

  const handleClickEdit = () => {
    if (guestId) {
      dispatch(showNotesPopup({ guestId: guestId, mode: GuestNotesModalMode.Edit, notes: notes }));
    }
  };

  const handleClickSeeMore = () => {
    if (guestId) {
      dispatch(showNotesPopup({ guestId: guestId, notes: notes }));
    }
  };

  return {
    areNotesEmpty,
    handleClickEdit,
    handleClickSeeMore,
    isEditDisabled,
    notes: notesToDisplay,
    showSeeMore,
  };
};
