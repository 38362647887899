import React from 'react';

import { colors } from 'css/Theme';

const originalDimensions = {
  width: 16,
  height: 16,
};

type SearchIconProps = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
};

export const SearchIcon = ({
  fill = colors.dutchie.gray70,
  height = originalDimensions.height,
  width = originalDimensions.width,
  onClick,
}: SearchIconProps) => {
  return (
    <svg onClick={onClick} xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}></g>
      <g clip-path='url(#clip0_2181_3080)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M2.58906 2.58881C3.75893 1.41894 5.34561 0.761719 7.00006 0.761719C8.6545 0.761719 10.2412 1.41894 11.4111 2.58881C12.5809 3.75868 13.2382 5.34537 13.2382 6.99981C13.2382 8.65426 12.5809 10.2409 11.4111 11.4108C10.2412 12.5807 8.6545 13.2379 7.00006 13.2379C5.34561 13.2379 3.75893 12.5807 2.58906 11.4108C1.41919 10.2409 0.761963 8.65426 0.761963 6.99981C0.761963 5.34537 1.41919 3.75868 2.58906 2.58881ZM7.00006 1.90458C5.64872 1.90458 4.35272 2.44139 3.39718 3.39694C2.44164 4.35248 1.90482 5.64847 1.90482 6.99981C1.90482 8.35115 2.44164 9.64715 3.39718 10.6027C4.35272 11.5582 5.64872 12.0951 7.00006 12.0951C8.3514 12.0951 9.64739 11.5582 10.6029 10.6027C11.5585 9.64715 12.0953 8.35115 12.0953 6.99981C12.0953 5.64847 11.5585 4.35248 10.6029 3.39694C9.64739 2.44139 8.3514 1.90458 7.00006 1.90458Z'
          fill={fill}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.9293 10.9291C11.1525 10.7059 11.5143 10.7059 11.7375 10.9291L15.0708 14.2624C15.2939 14.4856 15.2939 14.8474 15.0708 15.0705C14.8476 15.2937 14.4858 15.2937 14.2627 15.0705L10.9293 11.7372C10.7062 11.5141 10.7062 11.1522 10.9293 10.9291Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2181_3080'>
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
