import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'store';
import { closePopup } from 'store/actions/PopupsActions';

import { AnonymousTransactionPopup } from '../GuestListPopups/components/AnonymousTransactionPopup';
import { ChangeCustomerTypeModal } from './components/ChangeCustomerTypeModal';
import { Last4Popup } from './components/Last4Popup';
import { CartItemRemovalPopup } from './components/CartItemRemovalPopup';
import { GuestNotesModal } from './components/GuestNotesModal';
import { PaymentMethodDisabledPopup } from './components/PaymentMethodDisabledPopup';
import { ProductBeingAddedPastUsedByDatePopup } from './components/ProductBeingAddedPastUsedByDatePopup';
import { ScanFailedPopup } from './components/ScanFailedPopup';
import ProductUpsellPopUp from './components/ProductUpsellPopup';
import { ConfirmHubBackClickPopup } from './components/ConfirmHubBackClickPopup';

export const CartPopups: FC = () => {
  const dispatch = useDispatch();

  const anonymousTransactionPopup = useSelector((state: State) => state.popups.anonymousTransactionPopup);
  const changeCustomerTypePopup = useSelector((state: State) => state.popups.changeCustomerTypePopup);
  const last4Popup = useSelector((state: State) => state.popups.last4Popup);
  const notesPopup = useSelector((state: State) => state.popups.notesPopup);
  const paymentMethodDisabledPopup = useSelector((state: State) => state.popups.paymentMethodDisabledPopup);
  const productBeingAddedPastUsedByDatePopup = useSelector(
    (state: State) => state.popups.productBeingAddedPastUsedByDatePopup
  );
  const removeCartItemPopup = useSelector((state: State) => state.popups.removeCartItemPopup);
  const scanFailedPopup = useSelector((state: State) => state.popups.scanFailedPopup);
  const productUpsell = useSelector((state: State) => state.popups.productUpsellPopup);
  const hubBackClick = useSelector((state: State) => state.popups.hubBackClickPopup);

  return (
    <>
      {anonymousTransactionPopup && (
        <AnonymousTransactionPopup
          {...anonymousTransactionPopup}
          hide={() => dispatch(closePopup('anonymousTransactionPopup'))}
        />
      )}
      {changeCustomerTypePopup && (
        <ChangeCustomerTypeModal hide={() => dispatch(closePopup('changeCustomerTypePopup'))} />
      )}
      {last4Popup && <Last4Popup {...last4Popup} hide={() => dispatch(closePopup('last4Popup'))} />}
      {notesPopup && <GuestNotesModal {...notesPopup} hide={() => dispatch(closePopup('notesPopup'))} />}
      {paymentMethodDisabledPopup && (
        <PaymentMethodDisabledPopup
          {...paymentMethodDisabledPopup}
          hide={() => dispatch(closePopup('paymentMethodDisabledPopup'))}
        />
      )}
      {productBeingAddedPastUsedByDatePopup && (
        <ProductBeingAddedPastUsedByDatePopup
          {...productBeingAddedPastUsedByDatePopup}
          hide={() => dispatch(closePopup('productBeingAddedPastUsedByDatePopup'))}
        />
      )}
      {removeCartItemPopup && (
        <CartItemRemovalPopup {...removeCartItemPopup} hide={() => dispatch(closePopup('removeCartItemPopup'))} />
      )}
      {scanFailedPopup && <ScanFailedPopup {...scanFailedPopup} hide={() => dispatch(closePopup('scanFailedPopup'))} />}
      {productUpsell && (
        <ProductUpsellPopUp
          data={productUpsell.productDetails}
          hide={() => dispatch(closePopup('productUpsellPopup'))}
        />
      )}
      {hubBackClick && (
        <ConfirmHubBackClickPopup
          {...hubBackClick}
          hide={() => dispatch(closePopup('hubBackClickPopup'))}
        />
      )}
    </>
  );
};
