import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useBarcodeScannerHelpCenterLink = () => {
  const ldClient = useLDClient();
  const helpCenterLink: string = ldClient?.variation('pos.register.barcode-scanner-help-center-link', '');

  return {
    /** Link to the help center article for configuring barcode scanners */
    helpCenterLink,
    /** pos.register.barcode-scanner-help-center-link */
    isHelpCenterLinkDefined: Boolean(helpCenterLink),
  };
};
