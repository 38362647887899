import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { PusherChannelConfiguration, PusherChannelType } from 'models/Pusher';
import { warningNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch } from 'util/hooks';

export const useGetFeatureFlagPusherConfiguration = (options: UseQueryOptions<PusherChannelConfiguration>) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: ['Pusher-Feature-Flag-Config'],
    queryFn: async () => {
      const response = await post<PusherChannelConfiguration>(`pusher/config/${PusherChannelType.FeatureFlags}`)
        .catch(() => {
          const pusherResult: PusherChannelConfiguration = { PusherKey: '', PusherCluster: '', ChannelName: '' };
          dispatch(warningNotification('Unable to connect for real-time notifications'));
          return pusherResult;
        });

      return response;
    },
    ...options,
  });
};
