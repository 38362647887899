import React, { FC } from 'react';
import { ProductSearchMessage } from 'components/tables/ProductSearchFilter/components/ProductSearchMessage';
import { SearchResultsContainer } from 'components/tables/ProductSearchFilter/ProductSearchFilter.styles';

type PreorderProductSearchResultsProps = {
  wasErrorLoadingProducts: boolean;
  noInventoryFound: boolean;
  noMatchingProductsFound: boolean;
  reloadProducts: () => void;
};

export const PreorderProductSearchResults: FC<PreorderProductSearchResultsProps> = ({
  wasErrorLoadingProducts,
  noInventoryFound,
  noMatchingProductsFound,
  reloadProducts,
  children,
}) => {
  if (wasErrorLoadingProducts) {
    return (
      <SearchResultsContainer>
        <ProductSearchMessage
          message='Inventory could not be loaded'
          action={{ label: 'Reload inventory', onClick: reloadProducts }}
        />
      </SearchResultsContainer>
    );
  }

  if (noInventoryFound) {
    return (
      <SearchResultsContainer>
        <ProductSearchMessage
          message='No inventory found'
          instruction='Check inventory and rooms configuration in Dutchie Backoffice.'
        />
      </SearchResultsContainer>
    );
  }

  if (noMatchingProductsFound) {
    return (
      <SearchResultsContainer>
        <ProductSearchMessage message='No matching products found' instruction='Refine search and try again.' />
      </SearchResultsContainer>
    );
  }

  return <SearchResultsContainer>{children}</SearchResultsContainer>;
};
