import { useSelector } from 'react-redux';
import { chain } from 'lodash';

import { FeePaymentMethod } from 'models/Checkout';
import { cartHasRoundingNew } from 'util/Helpers';
import { useUpdateCartTotals } from 'pages/CartPage/hooks/useUpdateCartTotals';

import type { State } from 'store';
import type { Cart } from 'models/Cart';
import type { AppliedFeeDonation } from 'models/FeeDonation';

type UseOrderTotalsProps = {
  cart: Partial<Cart>;
  hideGetTotalBtn: boolean;
};

export const useOrderTotals = ({ cart, hideGetTotalBtn }: UseOrderTotalsProps) => {
  // Rounding
  const isCurbsideRoundDownToNearestFiverEnabled = useSelector(
    (state: State) => state.settings.features.CurbsideRoundDownToNearestFiver
  );
  const isRoundDownToDollarEnabled = useSelector((state: State) => state.settings.features.RoundDownToDollar);
  const isRoundDownToNearestFiverEnabled = useSelector(
    (state: State) => state.settings.features.RoundDownToNearestFiver
  );
  const isRoundDownToQuarterEnabled = useSelector((state: State) => state.settings.features.RoundDownToQuarter);
  const isRoundPercentDiscountsEnabled = useSelector((state: State) => state.settings.features.RoundPercentDiscounts);
  const isRoundToNearestDollarEnabled = useSelector((state: State) => state.settings.features.RoundToNearestDollar);
  const isRoundToNearestNickelEnabled = useSelector((state: State) => state.settings.features.RoundToNearestNickel);
  const showRounding = cartHasRoundingNew({
    isCurbsideRoundDownToNearestFiverEnabled,
    isRoundDownToDollarEnabled,
    isRoundDownToNearestFiverEnabled,
    isRoundDownToQuarterEnabled,
    isRoundPercentDiscountsEnabled,
    isRoundToNearestDollarEnabled,
    isRoundToNearestNickelEnabled,
  });

  // Stateful checkout
  const isStatefulCheckoutEnabled = useSelector((state: State) => state.settings.features.StatefulCheckout);
  const useStatefulCheckout = isStatefulCheckoutEnabled && !hideGetTotalBtn;
  const hasNotCalculatedTotalYet = cart.GrandTotalRounded === 0;
  const hideTotals = useStatefulCheckout && hasNotCalculatedTotalYet;

  const { mutateAsync: updateCartTotals, isLoading: areCartTotalsLoading } = useUpdateCartTotals();

  // Loyalty
  const showLoyaltyAsDiscount = useSelector((state: State) => state.settings.features.LoyaltyAsDiscount);
  const totalLoyalty = (cart.TotalDiscountAndLoyalty ?? 0) - (cart.TotalDiscount ?? 0);

  // Fees and donations
  const isUseFeesDonationsEnabled = useSelector((state: State) => state.settings.features.UseFeesDonations);
  const showFeesAndDonations = isUseFeesDonationsEnabled && (cart?.FeesAndDonations ?? 0) !== 0;
  const useUSAG = useSelector((state: State) => state.settings.integrations?.DebitProcessor) === 'usag';

  // Dutchie Pay fees
  const appliedDutchiePayFees = chain(cart.FeesDonations)
    .filter((appliedFee: AppliedFeeDonation) => appliedFee.FeePaymentMethod === FeePaymentMethod.DutchiePay)
    .first()
    .value()?.CashValue;
  const showDutchiePayFee = appliedDutchiePayFees > 0;

  // Non cash adjustment fees
  const paymentFee = chain(cart.FeesDonations)
    .filter(
      (appliedFee: AppliedFeeDonation) =>
        appliedFee.FeePaymentMethod === FeePaymentMethod.PinDebit ||
        appliedFee.FeePaymentMethod === FeePaymentMethod.Credit
    )
    .first()
    .value()?.CashValue;
  const showNonCashAdjustmentFee = paymentFee > 0 && !useUSAG;
  const showCardActivationFee = paymentFee > 0 && useUSAG;

  // Tip
  const showTipAmount = !!cart.TipAmount;

  const getTotal = async () => {
    if (useStatefulCheckout && !areCartTotalsLoading) {
      await updateCartTotals({ isCheckout: true });
    }
  };

  return {
    areCartTotalsLoading,
    useStatefulCheckout,
    hideTotals,
    showLoyaltyAsDiscount,
    totalLoyalty,
    showFeesAndDonations,
    appliedDutchiePayFees,
    showDutchiePayFee,
    appliedNonCashAdjustmentFee: paymentFee,
    showNonCashAdjustmentFee,
    showCardActivationFee,
    showRounding,
    showTipAmount,
    getTotal,
  };
};
