import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { colors, zIndices, headerHeight } from 'css/Theme';

export const StickyHeader = ({
  children,
  headerRef,
}: {
  children: ReactNode;
  headerRef: React.RefObject<HTMLDivElement>;
}) => {
  const { ref: interceptRef, inView: isInterceptDivInView } = useInView({ rootMargin: `-${headerHeight}` });

  return (
    <>
      <InterceptDiv ref={interceptRef} />
      <StickyHeaderDiv
        ref={headerRef}
        isScrolled={!isInterceptDivInView}
      >
        {children}
      </StickyHeaderDiv>
    </>
  );
};

const StickyHeaderDiv = styled.div<{ isScrolled: boolean }>`
  position: sticky;
  width: 100%;
  top: 0;
  padding: 1.5rem;
  z-index: ${zIndices.stickyHeader};
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.dutchie.shadowGrey};
  ${({ isScrolled }) =>
    isScrolled &&
    `
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  `}
`;

const InterceptDiv = styled.div``;
