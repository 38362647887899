import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'css/Theme';
import { DutchiePayInvoiceConfirmationNotification, FeePaymentMethod, PaymentType } from 'models/Checkout';
import { v4 as uuidv4 } from 'uuid';

import { BackButton, Button } from 'components/buttons';
import { DrawerHeader } from './PanelDrawerWrapper';
import { quitCheckout, addPaymentMethod } from 'store/actions/CheckoutActions';
import { DPInStorePinCodeCapture } from './CheckoutSidebar/payments/DutchiePayInStore';
import { State } from 'store';
import { ReactComponent as DutchiePayLogoMedium } from 'assets/icons/logo-dutchiepay-medium.svg';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { SidePanel, SidePanelSection } from 'components/layout';
import { ManualPinCodeInputModal } from './CheckoutSidebar/payments/DutchiePayInStore/ManualPinCodeInputModal';
import {
  createDutchieInStoreCart,
  DPInStoreStep,
  sendPollingFallbackRequest,
} from './CheckoutSidebar/payments/DutchiePayInStore/Common';
import Lottie from 'lottie-react';
import qrCodeAnimation from 'assets/animations/dutchie-pay-instore/qr_code.json';
import waitAnimation from 'assets/animations/dutchie-pay-instore/wait.json';
import { ReactComponent as CircleCheckmark } from 'assets/icons/in-store/circle-checkmark.svg';
import { DutchiePayPusherChannel } from './CheckoutSidebar/DutchiePayPusherChannel';
import { TotalPaymentButton } from './CheckoutSidebar/TotalPaymentButton';
import { useGetCartDetails } from '../hooks/useGetCartDetails';
import { Loader } from 'components/backoffice/loader';
import { Cart, PreauthInfo } from 'models/Cart';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import { OrderDetailsSection } from './CheckoutSidebar/OrderDetailsSection';
import { usePollingFallback } from './CheckoutSidebar/usePollingFallback';
import { PollingFallbackResponse } from 'models/DutchiePay';
import { getDutchiePayErrorMessage } from '@dutchie/error-messaging';
import { useUpdateCartFeesMutation } from 'queries/v2/cart/update-cart-fees';
import { useAnonymousCart } from '../hooks/useAnonymousCart';
import { useRefetchCartDetails } from '../hooks/useRefetchCartDetails';
import { useUpdateCartTotals } from '../hooks/useUpdateCartTotals';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { usePaymentsServiceErrorMessaging } from 'util/hooks/launch-darkly/usePaymentsServiceErrorMessaging';

export const DPInStoreStates = {
  customerPrompt: 'customerPrompt',
  ready: 'ready',
  linking: 'linking',
  confirming: 'confirming',
  orderConfirmed: 'orderConfirmed',
  error: 'error',
};

type ContinueButtonProps = {
  setDPInStoreState: (arg0: string) => void;
  handleDutchiePayInStoreCartCreation: (arg0?: string) => void;
};

const ContinueButton = ({ setDPInStoreState, handleDutchiePayInStoreCartCreation }: ContinueButtonProps) => {
  const isDutchiePayUser = useSelector((state: State) => state.customer.details?.IsDutchiePayUser);

  const handleClick = () => {
    if (isDutchiePayUser) {
      handleDutchiePayInStoreCartCreation();
    } else {
      setDPInStoreState(DPInStoreStates.ready);
    }
  };

  return (
    <StyledContinueButton secondary onClick={handleClick} automationId='dutchiepay-in-store-continue-button'>
      <ContinueButtonContent>
        <ContinueButtonText>Continue</ContinueButtonText>
      </ContinueButtonContent>
    </StyledContinueButton>
  );
};

const LoadingIllustration = () => (
  <LoadingContainer>
    <Loader size='4x' variant='green' />
  </LoadingContainer>
);

const customerPromptContent = {
  primaryText: 'Ask customer to scan QR Code',
  secondaryText: 'Customer must open camera and scan QR code to begin.',
  illustration: <Lottie animationData={qrCodeAnimation} loop={true} />,
};

const linkingCustomerContent = {
  primaryText: "Linking the customer's account...",
  secondaryText: 'This will take a few seconds and then the screen will automatically update.',
  illustration: <LoadingIllustration />,
};

const confirmingCustomerOrderContent = {
  primaryText: 'Wait for customer to confirm order',
  secondaryText: 'The customer must confirm the order on their phone.',
  illustration: <Lottie animationData={waitAnimation} loop={true} />,
};

const orderConfirmedContent = {
  primaryText: 'Success, order confirmed!',
  secondaryText: 'Click "Complete order" below to finalize this payment.',
  illustration: <CircleCheckmark />,
};

const validatePreauthInfo = (preauthInfo: PreauthInfo, cart: Cart) => {
  if (preauthInfo.PaymentType !== 'dutchiepay' || preauthInfo.ShipmentId !== cart.ShipmentId) {
    // eslint-disable-next-line no-console
    console.warn(
      `cart.PreauthInfo invalid, preauthInfo.PaymentType: ${preauthInfo.PaymentType} preauthInfo.ShipmentId: ${preauthInfo.ShipmentId} cart.ShipmentId: ${cart.ShipmentId}`
    );
    return false;
  } else {
    return true;
  }
};

export const DutchiePayInStorePanel = () => {
  const checkout = useSelector((state: State) => state.checkout);
  const features = useSelector((state: State) => state.settings.features);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const [showOrderDetails, setShowOrderDetails] = useState(true);
  const [addingPayment, setAddingPayment] = useState(false);
  const ldClient = useLDClient();
  const imgRef = React.useRef<HTMLInputElement>(null);
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const [showTotalPaymentButton, setShowTotalPaymentButton] = useState(false);
  const dispatch = useDispatch();
  const [dpInStoreState, setDPInStoreState] = useState(DPInStoreStates.customerPrompt);
  const { 'pos.payments.pusher.polling-fallback': posPaymentsPusherPollingFallback } = useFlags(); //'pos.payments.pusher.polling-fallback'
  const isErrorMessagingRolloutEnabled = usePaymentsServiceErrorMessaging();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const { data: cart } = useGetCartDetails(undefined, {
    refetchInterval: () => {
      // Need to refetch cart details if the cart is in the confirming state
      return dpInStoreState === DPInStoreStates.confirming ? 2000 : false;
    },
  });

  const { mutateAsync: updateCartTotals } = useUpdateCartTotals();

  // Cart properties
  const grandTotalRounded = cart?.GrandTotalRounded;
  const preauthInfo = cart?.PreauthInfo;
  const tipAmount = cart?.TipAmount;

  const { mutateAsync: updateCartFees } = useUpdateCartFeesMutation();
  const refetchCartDetails = useRefetchCartDetails();

  const apiRequest = () => {
    return sendPollingFallbackRequest(cart.ShipmentId);
  };
  const shouldPollAgain = (response: PollingFallbackResponse) => {
    return response.IsFinal === false;
  };
  const { startPolling, pollingResult } = usePollingFallback<PollingFallbackResponse>({
    request: apiRequest,
    shouldPollAgain,
  });

  const onBackClick = useCallback(() => {
    dispatch(quitCheckout());
  }, [dispatch]);

  const fetchUpdatedCustomer = async () => {
    if (cart.CustomerId) {
      await dispatch(getCustomerDetails({ guestId: cart.CustomerId }));
    }
  };

  // Check if statefulcheckout is being used and
  // checkout has already started. If so, calculate totals
  const calculateStatefulCart = async () => {
    if (registerId) {
      await updateCartTotals();
    }
  };

  const handleDutchiePayInStoreCartCreation = async (customerPinCode?: string) => {
    if (customerPinCode) {
      setDPInStoreState(DPInStoreStates.linking);
    } else {
      setDPInStoreState(DPInStoreStates.confirming);
    }

    let processedCustomerPinCode = customerPinCode;
    const devTestBypass = window.sessionStorage.getItem('DevDutchiePayInStoreMode');
    if (devTestBypass === 'develop') {
      if (customerPinCode) {
        processedCustomerPinCode = `${customerPinCode}_isdp`;
      }
    }
    await createDutchieInStoreCart(cart.ShipmentId, processedCustomerPinCode)
      .then(async (response: unknown) => {
        if (devTestBypass === 'develop') {
          // eslint-disable-next-line no-console
          console.info(response);
        }
        if (customerPinCode) {
          setDPInStoreState(DPInStoreStates.confirming);
        }
        // eslint-disable-next-line no-console
        console.info('createDutchieInStoreCart was successful.');

        // At this point we know that the customer has been successfully linked. Update the customer in the UI.
        await fetchUpdatedCustomer();
      })
      .catch((error: unknown) => {
        const defaultErrorMessage = 'Unable to link DutchiePay customer and cart';
        if (isErrorMessagingRolloutEnabled) {
          const errorMessage = (error as { message: string | undefined }).message;
          dispatch(errorNotification(errorMessage ?? defaultErrorMessage));
        } else {
          const errorCode = (error as { code: string | undefined }).code;
          const errorMessage = getDutchiePayErrorMessage(errorCode, defaultErrorMessage);
          dispatch(errorNotification(`${errorMessage.pos} ${errorCode ?? ''}`));
        }
        // eslint-disable-next-line no-console
        console.warn(error);
      });
  };

  const advanceToPaymentFinalizationScreen = async (
    preauthAmount: number,
    tipAmount: number | undefined,
    preauthId: string
  ) => {
    if (dpInStoreState === DPInStoreStates.confirming && !addingPayment) {
      setAddingPayment(true);

      const amount = preauthAmount + (tipAmount || 0);
      const dutchiePayDoubleChargeFixRollout = ldClient?.variation(
        'fintech.retail-payments.pos.dutchiepay-double-charge.rollout',
        false
      );
      const id = dutchiePayDoubleChargeFixRollout ? preauthId : uuidv4();
      const payment = {
        id,
        name: 'dutchiepay',
        type: PaymentType.DutchiePay,
        amount,
        tipAmount,
        dutchiePayDoubleChargeFixRollout,
      };
      await dispatch(addPaymentMethod(payment));
      await dispatch(successNotification('DutchiePay Payment added'));

      if (features.StatefulCheckout) {
        await calculateStatefulCart();
      }

      setDPInStoreState(DPInStoreStates.orderConfirmed);
      setShowTotalPaymentButton(true);
      setAddingPayment(false);
    }
  };

  React.useEffect(() => {
    if (validatePreauthInfo(preauthInfo, cart)) {
      advanceToPaymentFinalizationScreen(grandTotalRounded, tipAmount, preauthInfo.PreauthId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalRounded, preauthInfo, cart, checkout, tipAmount]);

  React.useEffect(() => {
    if (dpInStoreState === DPInStoreStates.confirming && posPaymentsPusherPollingFallback === true) {
      startPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpInStoreState, posPaymentsPusherPollingFallback]);

  React.useEffect(() => {
    async function handleCallback(notification: DutchiePayInvoiceConfirmationNotification | null) {
      await handlePusherMessage(notification || null);
    }
    if (pollingResult && pollingResult !== null && pollingResult.IsFinal === true) {
      const { DutchiePayInvoiceConfirmationNotification: notification } = pollingResult;
      handleCallback(notification || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingResult]);

  const handlePusherMessage = async (notification: DutchiePayInvoiceConfirmationNotification | null) => {
    if (dpInStoreState === DPInStoreStates.confirming) {
      if (notification && cart.ShipmentId === notification.ShipmentId) {
        if (validatePusherMessage(notification)) {
          if (isAnonymousCartLDFlagEnabled) {
            // Update cart fees using new mutation that overrides the cache
            await updateCartFees({
              guestId: cart.CustomerId,
              registerId,
              selectedPaymentType: PaymentType.DutchiePay,
              shipmentId: cart.ShipmentId,
              timestamp: +new Date(),
            });
          } else {
            // Update cart fees using the loadCart action with manually inserted feePaymentMethodIds defined above
            await refetchCartDetails({
              feePaymentMethodIds: [FeePaymentMethod.DutchiePay],
            });
          }
        } else {
          const defaultErrorMessage = 'Error completing DutchiePay transaction';
          if (isErrorMessagingRolloutEnabled) {
            dispatch(errorNotification(notification.ErrorMessage ?? defaultErrorMessage));
          } else {
            const errorMessage = getDutchiePayErrorMessage(notification.ErrorCode, defaultErrorMessage);

            dispatch(errorNotification(`${errorMessage.pos} ${notification.ErrorCode ?? ''}`));
          }

          dispatch(quitCheckout());
        }
      }
    }
  };

  const validatePusherMessage = (notification: DutchiePayInvoiceConfirmationNotification | null): boolean => {
    //  Must not be null
    if (notification === null) {
      // eslint-disable-next-line no-console
      console.warn('Pusher notification was null.');
      return false;
    }

    // Transaction must be approved
    if (notification.Approved !== true) {
      // eslint-disable-next-line no-console
      console.warn('Pusher notification was not Approved === true');
      return false;
    }
    return true;
  };

  const { isCompactLayout } = useCompactCartLayout();

  return (
    <SidePanel>
      <SidePanelSection gap='0.25rem' data-testid='dutchie-pay-in-store-panel'>
        <Container>
          {isCompactLayout ? (
            <DrawerHeader
              actionProps={{
                label: 'Back to cart',
                onClick: onBackClick,
                disabled: dpInStoreState === DPInStoreStates.orderConfirmed,
              }}
            >
              <PanelTitleContainer>
                Pay with <StyledDutchiePayLogoMedium />
              </PanelTitleContainer>
            </DrawerHeader>
          ) : (
            <TitleBox>
              {dpInStoreState !== DPInStoreStates.orderConfirmed && <BackButton onClick={onBackClick} />}
              <Title>
                Pay with <StyledDutchiePayLogoMedium />
              </Title>
            </TitleBox>
          )}
          {dpInStoreState === DPInStoreStates.customerPrompt && <DPInStoreStep {...customerPromptContent} />}

          {dpInStoreState === DPInStoreStates.ready && (
            <DPInStorePinCodeCapture handleDutchiePayInStoreCartCreation={handleDutchiePayInStoreCartCreation} />
          )}
          {dpInStoreState === DPInStoreStates.linking && <DPInStoreStep {...linkingCustomerContent} />}
          {dpInStoreState === DPInStoreStates.confirming && <DPInStoreStep {...confirmingCustomerOrderContent} />}
          {dpInStoreState === DPInStoreStates.orderConfirmed && <DPInStoreStep {...orderConfirmedContent} />}
        </Container>
      </SidePanelSection>
      <OrderDetailsSection setShowOrderDetails={setShowOrderDetails} showOrderDetails={showOrderDetails} cart={cart} />
      <ButtonContainer>
        {dpInStoreState === DPInStoreStates.ready && (
          <ManualCodeEntryButton
            secondary
            onClick={() => setPopupIsVisible(true)}
            automationId='manual-code-entry-button'
          >
            Barcode scan not working? <ManualCodeEntryButtonCTAText>Enter code</ManualCodeEntryButtonCTAText>
          </ManualCodeEntryButton>
        )}
        {dpInStoreState === DPInStoreStates.customerPrompt && (
          <StyledButtonContainer>
            <ContinueButton
              setDPInStoreState={setDPInStoreState}
              handleDutchiePayInStoreCartCreation={handleDutchiePayInStoreCartCreation}
            />
          </StyledButtonContainer>
        )}
      </ButtonContainer>
      {showTotalPaymentButton && (
        <StyledButtonContainer>
          <TotalPaymentButton imgRef={imgRef} dutchiePayInStore />
        </StyledButtonContainer>
      )}
      <ManualPinCodeInputModal
        popupIsVisible={popupIsVisible}
        setPopupIsVisible={setPopupIsVisible}
        handleDutchiePayInStoreCartCreation={handleDutchiePayInStoreCartCreation}
      />

      <DutchiePayPusherChannel onPusherMessageCallback={handlePusherMessage} />
    </SidePanel>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const PanelTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
`;

const StyledDutchiePayLogoMedium = styled(DutchiePayLogoMedium)`
  margin-left: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 22px 32px;
  gap: 16px;
  flex-grow: 1;
  width: 100%;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  padding: 22px 32px;
  width: 100%;
`;

const ManualCodeEntryButton = styled(Button)`
  width: 100%;
  height: 56px;
  font-weight: 400;
  color: #5e696e;
  border: none;
  &:hover {
    color: #5e696e;
  }
`;

const ManualCodeEntryButtonCTAText = styled.span`
  font-weight: 700;
  color: #0075e0;
`;

const StyledContinueButton = styled(Button)`
  width: 100%;
  height: 56px;
  align-items: center;
  letter-spacing: 0.005em;
  display: flex;
  font-feature-settings: 'calt' off;
  text-align: center;
  padding: 16px;
  background: ${colors.dutchie.green};
  border: none;
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
  }

  &:disabled {
    background: ${colors.dutchie.grey70};
    opacity: 1;
  }
`;
const ContinueButtonContent = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const ContinueButtonText = styled.span`
  margin-left: 12px;
`;

const LoadingContainer = styled.div`
  width: 152px;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
