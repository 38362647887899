import React from 'react';

import { colors } from 'css/Theme';

type MedicalIDIconProps = {
  color?: string;
  size?: number;
  automationId?: string;
};

export const MedicalIDIcon = ({ color = colors.dutchie.almostBlack, size = 16, automationId }: MedicalIDIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g transform='matrix(1,0,0,1,-0.84263,0.001094)'>
        <path
          d='M16.271,7.427C16.271,7.124 16.151,6.834 15.937,6.619C15.722,6.405 15.432,6.285 15.128,6.285L10.557,6.285L10.557,1.713C10.557,1.41 10.437,1.119 10.222,0.905C10.008,0.691 9.717,0.57 9.414,0.57L8.271,0.57C7.968,0.57 7.677,0.691 7.463,0.905C7.249,1.119 7.128,1.41 7.128,1.713L7.128,6.285L2.557,6.285C2.254,6.285 1.963,6.405 1.749,6.619C1.534,6.834 1.414,7.124 1.414,7.427L1.414,8.57C1.414,8.873 1.534,9.164 1.749,9.378C1.963,9.593 2.254,9.713 2.557,9.713L7.128,9.713L7.128,14.285C7.128,14.588 7.249,14.878 7.463,15.093C7.677,15.307 7.968,15.428 8.271,15.428L9.414,15.428C9.717,15.428 10.008,15.307 10.222,15.093C10.437,14.878 10.557,14.588 10.557,14.285L10.557,9.713L15.128,9.713C15.432,9.713 15.722,9.593 15.937,9.378C16.151,9.164 16.271,8.873 16.271,8.57L16.271,7.427Z'
          stroke={color}
          stroke-width='1.2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  );
};
