import { chain, intersection } from 'lodash';

import { FeePaymentMethod, PaymentType } from 'models/Checkout';
import { getQueryClient } from 'util/reactQuery';
import { getCartDetailsKeys } from '../query-key-factory';

import type { CartDetails } from '../types';
import type { UpdateCartFeesPayload } from '../update-cart-fees';

type ValidateFeesInCartResponse = {
  shouldRecalculateFees: boolean;
  newFeePaymentMethodIds?: FeePaymentMethod[];
};

const getFeeIdsByPaymentMethod = (paymentMethod: FeePaymentMethod, cart?: CartDetails) => {
  const feesByPaymentType = cart?.feesByPaymentType ?? [];

  const matchingFees = chain(feesByPaymentType)
    .filter((method) => method.FeePaymentMethod === paymentMethod)
    .map((props) => props.FeeDonationId)
    .value();

  return matchingFees;
};

export const validateFeesInCart = (payload: UpdateCartFeesPayload): ValidateFeesInCartResponse => {
  const { selectedPaymentType } = payload;

  if (!selectedPaymentType) {
    return { shouldRecalculateFees: false }; // No payment type selected
  }

  const queryClient = getQueryClient();
  const currentCart = queryClient.getQueryData<CartDetails>(getCartDetailsKeys.one(payload));

  // Compare the current fees in the cache with the new selected payment type
  const appliedFeesAndDonations = currentCart?.appliedFeesAndDonations ?? [];
  const appliedFeeIds = appliedFeesAndDonations.map((fee) => fee.FeeDonationId);
  const existingPayments = currentCart?.existingPayments ?? [];

  // Get the fee ids for each payment type
  const creditFeeIds = getFeeIdsByPaymentMethod(FeePaymentMethod.Credit, currentCart);
  const dutchiePayFeeIds = getFeeIdsByPaymentMethod(FeePaymentMethod.DutchiePay, currentCart);
  const pinDebitFeeIds = getFeeIdsByPaymentMethod(FeePaymentMethod.PinDebit, currentCart);

  // If we're missing any applied fees in the cache, fetch those fees with this load cart request
  if (selectedPaymentType === PaymentType.DutchiePay && dutchiePayFeeIds.length > 0) {
    if (intersection(appliedFeeIds, dutchiePayFeeIds).length !== dutchiePayFeeIds.length) {
      const newFeePaymentMethodIds = [FeePaymentMethod.DutchiePay];
      if (existingPayments.some((payment) => payment.PaymentProviderType.toLowerCase() === 'paynetworx')) {
        newFeePaymentMethodIds.push(FeePaymentMethod.PinDebit);
      }
      return {
        shouldRecalculateFees: true,
        newFeePaymentMethodIds,
      };
    }
  } else if (selectedPaymentType === PaymentType.Debit && pinDebitFeeIds.length > 0) {
    if (intersection(appliedFeeIds, pinDebitFeeIds).length !== pinDebitFeeIds.length) {
      // TODO: This doesn't account for Dutchie Pay split payments (which aren't currently a thing)
      return {
        shouldRecalculateFees: true,
        newFeePaymentMethodIds: [FeePaymentMethod.PinDebit],
      };
    }
  } else if (selectedPaymentType === PaymentType.Credit && creditFeeIds.length > 0) {
    if (intersection(appliedFeeIds, creditFeeIds).length !== creditFeeIds.length) {
      // TODO: This doesn't account for Dutchie Pay split payments (which aren't currently a thing)
      return {
        shouldRecalculateFees: true,
        newFeePaymentMethodIds: [FeePaymentMethod.Credit],
      };
    }
  } else {
    const paymentFeeIds = dutchiePayFeeIds.concat(pinDebitFeeIds.concat(creditFeeIds)); // Combine all the fee ids
    if (intersection(appliedFeeIds, paymentFeeIds).length > 0) {
      // If none of the selected payment types have fees, but the cart has fees, we need to reload the cart with no fees applied
      return {
        shouldRecalculateFees: true,
        newFeePaymentMethodIds: undefined,
      };
    }
  }

  return { shouldRecalculateFees: false };
};
