import React, { ChangeEvent } from 'react';
import { SxProps } from '@mui/material';

import { RebrandCheck } from '../icons/rebrand-check';
import { RebrandUncheck } from '../icons/rebrand-uncheck';
import { RebrandCheckbox } from './checkbox.styles';

export type CheckboxBaseProps = {
  checked?: boolean;
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: unknown;
  indeterminate?: boolean;
  sx?: SxProps;
  className?: string;
};

export function CheckboxBase(props: CheckboxBaseProps) {
  const { checked, indeterminate, name, onChange, disabled, sx, className, value } = props;

  return (
    <RebrandCheckbox
      checked={checked}
      checkedIcon={<RebrandCheck fill={disabled ? 'var(--color-greyscale-grey-70' : undefined} />}
      className={className}
      disabled={disabled}
      icon={<RebrandUncheck />}
      indeterminate={indeterminate}
      name={name}
      sx={sx}
      value={value}
      onChange={onChange}
    />
  );
}
