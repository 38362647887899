import React, { FC } from 'react';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';
import { colors } from 'css/Theme';

const Message = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 16px;
  line-height: 24px;
  margin: 24px;
`;
type BatchCheckoutPopupProps = {
  isVisible: boolean;
  onConfirm: callback;
  hide: callback;
  message: string;
  confirmButtonMessage: string;
};

export const BatchCheckoutPopup: FC<BatchCheckoutPopupProps> = ({
  isVisible,
  onConfirm,
  hide,
  message,
  confirmButtonMessage,
}) => {
  return (
    <ConfirmationPopup
      medium
      isVisible={isVisible}
      hide={hide}
      title={`Batch Checkout?`}
      cancel={{
        text: 'No',
        onClick: () => {
          hide();
        },
      }}
      confirm={{
        text: confirmButtonMessage,
        onClick: () => {
          onConfirm();
        },
      }}
    >
      <Message>{message}</Message>
    </ConfirmationPopup>
  );
};
