import React, { FC, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { PaymentType } from 'models/Checkout';
import { v4 as uuidv4 } from 'uuid';
import { addPaymentMethod, updatePaymentMethod } from 'store/actions/CheckoutActions';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { PaymentOption } from '../PaymentOption';
import { PaymentShortcuts } from './PaymentShortcuts';
import { CheckoutContext } from '../CheckoutContext';

export const PaymentCash: FC = () => {
  const dispatch = useDispatch();
  const checkout = useSelector((state: State) => state.checkout);
  const { setSelectedPaymentType } = useContext(CheckoutContext);

  const onAddCash = useMemo(
    () => (amount: number) => {
      if (amount <= 0) {
        dispatch(errorNotification('Invalid cash amount.'));
        return;
      }

      const index = checkout.payment?.methods.findIndex((o) => o.name === 'Cash') ?? -1;
      if (index >= 0) {
        const oldAmount = checkout.payment?.methods[index].amount ?? 0;
        const newAmount = amount + oldAmount;
        const payload = {
          index: index,
          method: { id: uuidv4(), name: 'Cash', type: PaymentType.Cash, amount: newAmount },
        };

        dispatch(updatePaymentMethod(payload));
        dispatch(successNotification('Cash Payment updated'));
        setSelectedPaymentType(null);
        return;
      }

      dispatch(addPaymentMethod({ id: uuidv4(), name: 'Cash', type: PaymentType.Cash, amount }));
      dispatch(successNotification('Cash Payment added'));
      setSelectedPaymentType(null);
    },
    [checkout.payment, dispatch, setSelectedPaymentType]
  );

  return (
    <PaymentOption onAddAmount={onAddCash}>
      <PaymentShortcuts
        shortcuts={[{ name: 'Exact' }, { amount: 20 }, { amount: 40 }, { amount: 60 }, { amount: 80 }, { amount: 100 }]}
        onAddAmount={onAddCash}
      />
    </PaymentOption>
  );
};
