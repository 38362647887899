import React from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { RebrandMenu } from './menu-rebrand.styles';

export function Menu(props) {
  return <RebrandMenu {...props} />;
}

export const Divider = styled.hr`
  color: ${colors.dutchie.grey20};
  margin: 0.5rem 0;
  padding: 0;
`;
