import { PeripheralService, PeripheralType } from '@dutchie/capacitor-hardware';
import { PrintNodeLabelPrinter } from './PrintNodeLabelPrinter';
import { getLabelPrintersList } from 'api/PosApi';
import { PrinterResponse } from 'models/Misc';
import { logger } from 'util/logger';

export class PrintNodeLabelPrinterService extends PeripheralService<PrintNodeLabelPrinter> {
  static serviceName = 'PrintNodeLabelPrinterService';

  // Prevent additional requests while the first is still in progress
  private requestPromise: Promise<PrinterResponse[]> | undefined;

  constructor() {
    super(PeripheralType.labelPrinter, {
      autoEnable: true,
      autoConnect: true,
      name: PrintNodeLabelPrinterService.serviceName,
    });
  }

  get isSupported(): boolean {
    return true;
  }

  async availableDevices(): Promise<PrintNodeLabelPrinter[]> {
    try {
      if (!this.requestPromise) {
        this.requestPromise = getLabelPrintersList();
      }
      const results = await this.requestPromise;
      this.requestPromise = undefined;
      return results.map((result) => new PrintNodeLabelPrinter(result));
    } catch (e) {
      logger.error(e, { message: 'availableDevices for PrintNodeLabelPrinterService failed' });
    }
    return [];
  }
}
