import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'store';
import { closePopup } from 'store/actions/PopupsActions';

import { UnsavedChangesPopup } from './components/UnsavedChangesPopup';
import { ConvertToNonAnomyousPopup } from './components/ConvertToNonAnomyousPopup';
import { CheckinCaregiverPopup } from './components/CheckinCaregiverPopup';
import { SelectCollectorPopup } from 'components/sharedPopups/SelectCollectorPopup';
import { PotentialDuplicateCustomerModal } from './components/PotentialDuplicateCustomerModal';
import { VerifyBirthdatePopup } from 'components/sharedPopups/VerifyBirthdatePopup';
import { StartOrderPopup } from 'components/sharedPopups/StartOrderPopup';
import { CreatePreOrderPopup } from 'components/sharedPopups/CreatePreOrderPopup';

export const CustomerPopups: FC = () => {
  const dispatch = useDispatch();

  const unsavedChangesPopup = useSelector((state: State) => state.popups.unsavedChangesPopup);
  const convertToNonAnonymousPopup = useSelector((state: State) => state.popups.convertToNonAnonymousPopup);
  const checkinCaregiverPopup = useSelector((state: State) => state.popups.checkinCaregiverPopup);
  /** @deprecated To be removed once we migrate to global popups */
  const selectCollectorFromProfilePopup = useSelector((state: State) => state.popups.selectCollectorFromProfilePopup);
  const potentialDuplicatePopup = useSelector((state: State) => state.popups.potentialDuplicatePopup);
  /** @deprecated To be removed once we migrate to global popups */
  const verifyBirthdateFromProfilePopup = useSelector((state: State) => state.popups.verifyBirthdateFromProfilePopup);
  /** @deprecated To be removed once we migrate to global popups */
  const startOrderPopup = useSelector((state: State) => state.popups.startOrderPopup);
  /** @deprecated To be removed once we migrate to global popups */
  const createPreOrderFromProfilePopup = useSelector((state: State) => state.popups.createPreOrderFromProfilePopup);

  return (
    <>
      {unsavedChangesPopup && (
        <UnsavedChangesPopup {...unsavedChangesPopup} hide={() => dispatch(closePopup('unsavedChangesPopup'))} />
      )}
      {convertToNonAnonymousPopup && (
        <ConvertToNonAnomyousPopup
          {...convertToNonAnonymousPopup}
          hide={() => dispatch(closePopup('convertToNonAnonymousPopup'))}
        />
      )}
      {checkinCaregiverPopup && (
        <CheckinCaregiverPopup
          {...checkinCaregiverPopup}
          isVisible
          hide={() => dispatch(closePopup('checkinCaregiverPopup'))}
        />
      )}
      {selectCollectorFromProfilePopup && (
        <SelectCollectorPopup
          {...selectCollectorFromProfilePopup}
          hide={() => dispatch(closePopup('selectCollectorFromProfilePopup'))}
        />
      )}
      {potentialDuplicatePopup && (
        <PotentialDuplicateCustomerModal
          {...potentialDuplicatePopup}
          hide={() => dispatch(closePopup('potentialDuplicatePopup'))}
        />
      )}
      {verifyBirthdateFromProfilePopup && (
        <VerifyBirthdatePopup
          {...verifyBirthdateFromProfilePopup}
          hide={() => dispatch(closePopup('verifyBirthdateFromProfilePopup'))}
        />
      )}
      {startOrderPopup && <StartOrderPopup {...startOrderPopup} hide={() => dispatch(closePopup('startOrderPopup'))} />}
      {createPreOrderFromProfilePopup && (
        <CreatePreOrderPopup
          {...createPreOrderFromProfilePopup}
          isVisible
          hide={() => dispatch(closePopup('createPreOrderFromProfilePopup'))}
        />
      )}
    </>
  );
};
