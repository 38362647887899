import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { useCallback } from 'react';
import { CustomerDetails } from 'models/Customer';
import { getPatientPolicy } from 'api/CustomerApi';
import { warningNotification } from 'store/actions/NotificationsActions';

export const usePatientPolicyValidator = () => {
  const dispatch = useDispatch();
  const features = useSelector((x: State) => x.settings.features);

  const callback = useCallback(
    async (guest: CustomerDetails) => {
      if (!features.RequirePolicySignature) {
        return true;
      }

      if (guest.IsAnonymous || !guest.IsMedical) {
        return true;
      }

      try {
        const policy = await getPatientPolicy({
          AcctId: guest.Guest_id,
        });

        if (!policy.AgreementHTML) {
          return true;
        }
        return policy.IsValid;
      } catch (e) {
        dispatch(warningNotification(`Could not load verify policy agreement: ${e}`));
        return false;
      }
    },
    [dispatch, features]
  );

  return callback;
};
