import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import { State } from 'store';
import { successNotification } from 'store/actions/NotificationsActions';
import { handleLogout } from 'store/actions/UserActions';
import * as PosApi from 'api/PosApi';
import { Box, Container, TESTONLYBUTTON } from './FullPageMigrationTakeover';
import { MigrationIcon } from './MigrationIcon';
import {
  CopyableURLContainer,
  DisplayURL,
  LargeButton,
  Link,
  LinksSection,
  SmallButton,
  StyledHeading,
  Text,
  TextSection,
} from './ServerMigrationPromptModal';
import { useNewRegion } from './useNewRegion';
import { useServerMigrationConfig } from './useServerMigrationConfig';
import { selectUserRegion } from 'store/actions/SettingsActions';

export function AndroidFullPageMigrationTakeover() {
  const { registerURL, useUpdatedMessaging } = useServerMigrationConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  const permissions = useSelector((state: State) => state.settings.permissions);
  const [isLoading, setIsLoading] = React.useState(false);
  const newRegion = useNewRegion();

  const handleCopyURLToClipboard = async () => {
    await navigator.clipboard.writeText(registerURL);
    dispatch(successNotification('URL copied to clipboard'));
  };

  async function handleTestingOnlyButton() {
    setIsLoading(true);

    window.sessionStorage.setItem('LspId', '1062');
    window.sessionStorage.setItem('LocId', '1141');

    await PosApi.updateDefaultLsp(1062);
    await PosApi.updateDefaultLocation(1141);

    await dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));
    setIsLoading(false);
    window.location.reload();
  }

  async function logout() {
    await dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));
    dispatch(selectUserRegion(newRegion));
  }

  return (
    <Container>
      <Box>
        <MigrationIcon />
        <TextSection>
          <StyledHeading>This URL has expired</StyledHeading>
          <Text>
            This server has been disabled. Please click below to copy your new server name and login.{' '}
            {!useUpdatedMessaging && 'Visit the help center to learn more.'}
          </Text>
        </TextSection>
        <CopyableURLContainer>
          <DisplayURL>{newRegion}</DisplayURL>
          <SmallButton onClick={handleCopyURLToClipboard}>Copy</SmallButton>
        </CopyableURLContainer>
        <LinksSection>
          <LargeButton onClick={logout}>Switch Server</LargeButton>
          {!useUpdatedMessaging && (
            <Link onClick={() => window.open('https://support.dutchie.com/hc/en-us/articles/28929066112147')}>
              Visit help center
            </Link>
          )}
        </LinksSection>
      </Box>
      {permissions?.SuperUser && (
        <TESTONLYBUTTON onClick={handleTestingOnlyButton} loading={isLoading}>
          (for testing purposes only) Take me back to LL Training Co
        </TESTONLYBUTTON>
      )}
    </Container>
  );
}
