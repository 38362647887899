import React, { MouseEventHandler } from 'react';
import { SxProps } from '@mui/material';

import { Loader } from '../loader';
import { LinkButton } from '.';
import { StyledButton } from './button.styles';

export type ButtonProps = {
  variant?: 'danger' | 'link' | 'primary' | 'secondary' | 'white';
  buttonSize?: 'extra-small' | 'large' | 'medium' | 'small';
  label: React.ReactNode | string;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps;
  fontSize?: string;
  loading?: boolean;
  automationId?: string;
  id?: string;
  visible?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
  const {
    variant = 'primary',
    buttonSize = 'medium',
    disabled,
    onClick,
    startIcon,
    endIcon,
    label,
    sx,
    fontSize = '1rem',
    loading = false,
    automationId = '',
    id = '',
  } = props;

  const validLabel = typeof label === 'string' ? label : label;

  const getValidLoader = () => {
    // the loader must be the white variant when a primary or danger button is disabled in order to be visible
    if (disabled && (variant === 'primary' || variant === 'danger')) {
      return <Loader variant='default' />;
    }

    return <Loader variant='grey' />;
  };

  const validLoader = getValidLoader();

  return variant === 'link' ? (
    <LinkButton
      buttonSize={buttonSize}
      data-testid={automationId}
      disabled={disabled}
      endIcon={endIcon}
      fontSize={fontSize}
      id={id}
      label={label}
      startIcon={startIcon}
      sx={sx}
      onClick={onClick}
    />
  ) : (
    <StyledButton
      {...props}
      $buttonSize={buttonSize}
      $fontSize={fontSize}
      $variant={variant}
      data-testid={automationId}
      disabled={disabled}
      endIcon={endIcon}
      id={id}
      ref={ref}
      startIcon={startIcon}
      sx={sx}
      variant='contained'
      onClick={onClick}
    >
      {loading ? validLoader : validLabel}
    </StyledButton>
  );
});
