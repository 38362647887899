import { ScaleMeasurementHeader, ScaleMeasurementPayload, ScaleMeasurementUnit } from 'util/capacitor/peripheral';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { warningNotification } from 'store/actions/NotificationsActions';
import { HardwareService, ScaleMeasurement, Scale } from '@dutchie/capacitor-hardware';
/**
 * The key configured in 232key to end the input string
 * Note: Should not be configured with an alternating ending
 * @see https://www.232key.com/documentation/index.html#format-tab
 */
export const END_INPUT_KEY = 'Escape';

/**
 * The time in milliseconds to wait for end character
 * before cancelling input
 */
const SCALE_TIMEOUT_MILLISECONDS = 300;

/**
 * Removes any character that isn't 0-9, a period, or comma
 * @param char The received key from keyboard event
 * @return The sanitized input
 */
const sanitizeInput = (char: string) => {
  const validChars = new RegExp(/[0-9.,]/g);
  return char.match(validChars) ? char : '';
};

type ScaleStatus = 'In Progress' | 'Complete' | 'Inactive';
enum ScaleState {
  InProgress = 'In Progress',
  Complete = 'Complete',
  Inactive = 'Inactive',
}

type HardwareScaleListenerProps = {
  onReceivedMeasurement: (measurement: ScaleMeasurement) => void;
};

export const HardwareServiceScaleListener = ({ onReceivedMeasurement }: HardwareScaleListenerProps) => {
  const dispatch = useDispatch();
  const [unitWarningSent, setUnitWarningSent] = useState(false);

  useEffect(() => {
    const handleMeasurementEvent = (event: Event) => {
      const { measurement } = (event as CustomEvent<{device: Scale; measurement: ScaleMeasurement}>).detail;
      const isScaleSetToGrams = measurement.unit === ScaleMeasurementUnit.gram;
      if (isScaleSetToGrams) {
        // Send the current weight and stability indicator from scale
        onReceivedMeasurement(measurement);
      } else if (!unitWarningSent) {
        dispatch(warningNotification('Scale must be configured to grams'));

        // Update the state for the warning so we don't show the notification repeatedly
        setUnitWarningSent(!isScaleSetToGrams);
      }
    };

    HardwareService.scale.events.addEventListener('measurement', handleMeasurementEvent);
    return () => {
      HardwareService.scale.events.removeEventListener('measurement', handleMeasurementEvent);
    };
  }, [dispatch, onReceivedMeasurement, unitWarningSent]);

  return null;
};

type ScaleListenerProps = {
  onReceivedWeight: (weight: string) => void;
};

export const ScaleListener: FC<ScaleListenerProps> = ({ onReceivedWeight }) => {
  const weightString = useRef('');
  const scaleStatus = useRef<ScaleStatus>(ScaleState.Inactive);

  const timeoutHandle = useRef<NodeJS.Timeout>();

  /**
   * Resets the weight string and optionally resets the scale status
   * @param resetStatus optionally resets status
   */
  const resetWeight = (resetStatus?: boolean) => {
    weightString.current = '';
    if (resetStatus) {
      scaleStatus.current = ScaleState.Inactive;
    }
  };

  /**
   * Sets the status to in progress and starts a timeout to reset the status to inactive
   */
  const startInput = useCallback(() => {
    resetWeight();
    scaleStatus.current = ScaleState.InProgress;
    timeoutHandle.current = setTimeout(() => {
      scaleStatus.current = ScaleState.Inactive;
    }, SCALE_TIMEOUT_MILLISECONDS);
  }, []);

  /**
   * Clears the timeout if any and sets status to complete
   */
  const completeInput = () => {
    timeoutHandle.current && clearTimeout(timeoutHandle.current);
    scaleStatus.current = ScaleState.Complete;
  };

  /**
   * Updates the state of the scale based on the sequence of characters received from the input
   * Statuses: In Progress, Complete, Inactive
   * @param char The received key from keyboard event
   * @returns new status of the scale
   */
  const getNewScaleStatus = useCallback(
    (char: string) => {
      if (scaleStatus.current === ScaleState.InProgress) {
        if (char === END_INPUT_KEY) {
          completeInput();
        }
      } else {
        startInput();
      }
      return scaleStatus.current;
    },
    [startInput]
  );

  /**
   * Handle keyup events for scale input
   */
  const upHandler = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e;
      const char = sanitizeInput(key);

      const scaleState = getNewScaleStatus(key);
      const weight = weightString.current;

      switch (scaleState) {
        case ScaleState.Complete:
          resetWeight(true);
          onReceivedWeight(weight);
          break;
        case ScaleState.InProgress:
          weightString.current += char;
          break;
        case ScaleState.Inactive:
        default:
          break;
      }
    },
    [getNewScaleStatus, onReceivedWeight]
  );

  // Set up event listeners and clean up listeners
  useEffect(() => {
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [upHandler]);

  return null;
};

type NativeScaleListenerProps = {
  onReceivedWeight: (weight: string, stable: boolean) => void;
};

/**
 * Accepts a callback to send the current weight and stability to the subscriber
 * @param onReceiveWeight callback to use when new weight is received from scale
 * @returns null
 */
export const NativeScaleListener: FC<NativeScaleListenerProps> = ({ onReceivedWeight }) => {
  const dispatch = useDispatch();
  const [unitWarningSent, setUnitWarningSent] = useState(false);

  const handleScaleData = useCallback(
    (data) => {
      const weight = data as ScaleMeasurementPayload;
      const scaleSetToGrams = weight.unit === ScaleMeasurementUnit.gram;
      if (scaleSetToGrams) {
        // Send the current weight and stability indicator from scale
        onReceivedWeight(weight.value.toString(), weight.header === ScaleMeasurementHeader.stable);
      } else if (!unitWarningSent) {
        dispatch(warningNotification('Scale must be configured to grams'));
      }
      // Update the state for the warning so we don't show the notification repeatedly
      setUnitWarningSent(!scaleSetToGrams);
    },
    [dispatch, onReceivedWeight, unitWarningSent]
  );

  // Set up event listeners and clean up listeners
  useEffect(() => {
    window.addEventListener('onScaleData', handleScaleData);
    return () => {
      window.removeEventListener('onScaleData', handleScaleData);
    };
  }, [handleScaleData]);

  return null;
};
