import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/buttons';
import { ModalCloseButton, ModalContainer, ModalContent, ModalVariation } from 'components/modals/Modal';
import { PopupOverlay } from 'components/popups';
import { colors } from 'css/Theme';

import { MigrationIconWarning } from './MigrationIconWarning';
import { useServerMigrationConfig } from './useServerMigrationConfig';
import { successNotification } from 'store/actions/NotificationsActions';

export function ServerMigrationPromptModal() {
  const dispatch = useDispatch();
  const { deadlineDateLabel, registerURL, shouldShowBlockingUI, dayBeforeDeadline } = useServerMigrationConfig();
  const [open, setOpen] = useState<boolean | null>(null);

  const shouldShowServerMigrationPromptModal = !shouldShowBlockingUI && !!registerURL;

  useEffect(() => {
    if (shouldShowServerMigrationPromptModal && open === null) {
      setOpen(true);
    }
  }, [shouldShowServerMigrationPromptModal, open]);

  const openingText = deadlineDateLabel?.length
    ? `Your account will be migrated to an upgraded environment on ${deadlineDateLabel}.`
    : 'Your account will soon be migrated to an upgraded environment.';
  const dayBeforeText = `This URL will be disabled one day ahead of time on ${dayBeforeDeadline}.`;

  const handleCopyURLToClipboard = async () => {
    await navigator.clipboard.writeText(registerURL);
    dispatch(successNotification('URL copied to clipboard'));
  };

  if (!open) {
    return null;
  }

  return (
    <PopupOverlay hide={() => {} /* intentionally only close when X is clicked */}>
      <ModalContainer variation={ModalVariation.Standard}>
        <ModalContent>
          <Container>
            <FloatingModalHeader>
              <ModalCloseButton onClick={() => setOpen(false)} />
            </FloatingModalHeader>
            <MigrationIconWarning />
            <TextSection>
              <StyledHeading>Your URL has changed</StyledHeading>
              <Text>
                {openingText} {dayBeforeText} Please click below to access and log into your new URL or copy and paste
                the link below. Visit the help center to learn more.
              </Text>
            </TextSection>
            <CopyableURLContainer>
              <DisplayURL>{registerURL}</DisplayURL>
              <SmallButton onClick={handleCopyURLToClipboard}>Copy</SmallButton>
            </CopyableURLContainer>
            <LinksSection>
              <LargeButton onClick={() => (window.location.href = registerURL)}>Go to new URL</LargeButton>
              <Link onClick={() => window.open('https://support.dutchie.com/hc/en-us/articles/28929066112147')}>
                Visit help center
              </Link>
            </LinksSection>
          </Container>
        </ModalContent>
      </ModalContainer>
    </PopupOverlay>
  );
}

export const FloatingModalHeader = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
`;

export const SmallButton = styled(Button)`
  height: 32px;
  width: 54px;
  background: transparent;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.06px;
  color: ${colors.dutchie.blue80};
  padding: 8px 12px;
  border: 1px solid ${colors.dutchie.blue80};
  line-height: 12px;
  :hover {
    background: ${colors.dutchie.blue80};
    color: ${colors.dutchie.primaryWhite};
  }
`;

export const DisplayURL = styled.span`
  font-weight: 600;
  color: ${colors.dutchie.blue80};
  font-size: 16px;
  font-style: normal;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.08px;
`;

export const CopyableURLContainer = styled.div`
  background-color: #edf1fa;
  border-radius: 8px;
  height: 58px;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 59px 32px 32px 32px;
  gap: 32px;
  position: relative;
`;

export const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #121516;
`;

export const StyledHeading = styled.p`
  display: block;
  color: ${colors.dutchie.almostBlack};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px; /* 125% */
  margin-bottom: 26px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
  text-align: center;
  color: ${colors.dutchie.grey30};
`;

export const LinksSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const Link = styled.a`
  color: ${colors.dutchie.blue} !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: 0.065px;
`;

export const LargeButton = styled(Button)`
  height: 56px;
  font-size: 16px;
  width: 100%;
`;
