import React from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';

// utils
import { logger } from 'util/logger';
import { useAppDispatch } from 'util/hooks';

// actions
import { errorNotification } from 'store/actions/NotificationsActions';

// global components
import {
  ModalContainer,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalVariation,
} from 'components/modals/Modal';
import { Button } from 'components/buttons';
import { PopupOverlay } from 'components/popups';

// local components
import { Title } from './components/Title';
import { InsightsSection } from './components/InsightsSection';
import { HistorySection } from './components/HistorySection';
import { NotesSection } from './components/NotesSection';

// types
type CustomerInsightsPopupProps = {
  customerId: number;
  hide: () => void;
  onGoToCart: () => Promise<void>;
};

function CustomerInsightsPopupImplementation(props: CustomerInsightsPopupProps) {
  const { customerId, hide, onGoToCart } = props;

  const handleBackToGuestListButtonClick = () => {
    hide();
  };

  const handleGoToCartButtonClick = () => {
    onGoToCart();
  };

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer data-testid='customer-insights-popup' variation={ModalVariation.Standard}>
        <ModalHeader>
          <Title customerId={customerId} />
          <ModalCloseButton data-testid='customer-insights-popup-close-button' onClick={hide} />
        </ModalHeader>
        <Content>
          <InsightsSection customerId={customerId} />
          <HistorySection customerId={customerId} />
          <NotesSection customerId={customerId} />
        </Content>
        <ModalFooter>
          <Button automationId='customer-insights-popup-back-to-guest-list-button' cta tertiary fullWidth onClick={handleBackToGuestListButtonClick}>
            Back to guest list
          </Button>
          <Button automationId='customer-insights-popup-go-to-cart-button' cta fullWidth onClick={handleGoToCartButtonClick}>
            Go to cart
          </Button>
        </ModalFooter>
      </ModalContainer>
    </PopupOverlay>
  );
}

export function CustomerInsightsPopup(props: CustomerInsightsPopupProps) {
  const { hide } = props;

  const dispatch = useAppDispatch();

  const handleError = (error: Error, errorInfo: { componentStack: string }) => {
    dispatch(errorNotification(`There was an error rendering the customer insights`));
    logger.error(error, { componentStack: errorInfo.componentStack });
    hide();
  };

  return (
    <ErrorBoundary fallback={null} onError={handleError}>
      <CustomerInsightsPopupImplementation {...props} />
    </ErrorBoundary>
  );
}

const Content = styled.div`
  padding: 24px;
`;
