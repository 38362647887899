import styled from 'styled-components';

import { OutlinedInput as MUIOutlinedInput, Select as MUISelect } from '@mui/material';

export const StyledPlaceholder = styled.span`
  color: var(--color-greyscale-grey-50);
`;

export const StyledOutlinedInput = styled(MUIOutlinedInput)`
  .MuiInputBase-input {
    padding: var(--sizes-30) var(--sizes-90) var(--sizes-30) var(--sizes-50) !important;
  }
`;

type RebrandSelectProps = {
  $borderColor?: string;
};

export const RebrandSelect = styled(MUISelect)<RebrandSelectProps>`
  grid-column: 2 / span 3;
  background-color: var(--color-primary-white);
  color: var(--color-greyscale-black);
  font: var(--font-regular-14pt-normal);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 44px;

  input {
    height: 100%;
  }

  .MuiInputBase-root {
    border-radius: 6px;
    padding-right: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ $borderColor }) => $borderColor ?? 'var(--color-greyscale-grey-65)'};
    border-radius: 6px;
  }

  .MuiSelect-select {
    min-height: var(--sizes-50);
    padding: 0;
  }

  .MuiSelect-icon {
    margin-right: var(--sizes-40);
    top: 19px;
    height: 7px;
    width: 14px;

    path {
      fill: var(--color-greyscale-grey-50);
    }
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    outline: 0;
    border: 1px solid var(--color-greyscale-black);
  }

  &.Mui-focused .MuiSelect-icon {
    path {
      fill: var(--color-greyscale-black);
    }
  }

  &.Mui-disabled .MuiSelect-icon {
    path {
      fill: var(--color-greyscale-grey-65);
    }
  }

  &.Mui-disabled {
    background-color: var(--color-greyscale-grey-95);
    :hover {
      cursor: not-allowed;
    }
  }

  legend {
    width: auto;
  }
`;
