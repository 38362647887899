import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'css/Theme';

import { Button } from 'components/backoffice/button';
import { BaseInput } from 'components/backoffice/input';
import { Loader } from 'components/backoffice/loader';
import { ReactComponent as CalculatorIcon } from 'assets/icons/calculator.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/new-plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/new-minus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/icon-trash.svg';
import { showRemoveCartItemPopup } from 'store/actions/PopupsActions';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

import type { CartItem } from 'models/Cart';

type BaseProps = {
  automationId?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledBaseInput = styled(BaseInput)<{ width?: string }>`
  .MuiInputBase-formControl {
    height: 2.5rem;
    width: ${({ width }) => (width ? width : '3rem')};
  }

  .MuiInputBase-input {
    padding: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
`;

const StyledLoadingButton = styled(Button)`
  background: ${colors.dutchie.lightGrey};
  border: 1px solid ${colors.dutchie.gray80};
  height: 2.5rem;
  width: 3rem;
  min-width: 0;
  padding: 0;

  &:disabled {
    background: ${colors.dutchie.lightGrey};
    border-color: ${colors.dutchie.gray80};
  }
`;

export const InputLoading: FC<BaseProps> = ({ automationId }) => {
  return (
    <StyledLoadingButton
      label={<Loader variant='custom' color={colors.dutchie.gray80} />}
      disabled={true}
      onClick={() => {}}
      automationId={automationId}
    />
  );
};

const StyledButton = styled(Button)`
  color: ${colors.dutchie.almostBlack};
  background: ${colors.dutchie.grey20};
  height: 2.5rem;
  width: 2.5rem;
  min-width: 0;
  padding: 0.625rem;

  &:hover {
    background: ${colors.dutchie.grey80};
  }

  &:disabled {
    background: ${colors.dutchie.lightGrey};
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

type MinusButtonProps = {
  item?: CartItem;
  count?: number;
  grams?: number;
  isInCartSummary?: boolean;
} & BaseProps;

export const MinusButton: FC<MinusButtonProps> = ({ item, count, grams, isInCartSummary, onClick, ...props }) => {
  const dispatch = useDispatch();

  const {
    data: { PreOrders: preorderItems },
  } = useGetCartDetails();

  const itemIsInPreorder = preorderItems.some((preorder) => preorder.ProductId === item?.ProductId);

  const showTrashIcon = isInCartSummary && ((count && count === 1) || grams);
  const Icon = showTrashIcon ? TrashIcon : MinusIcon;

  const decreaseOrRemoveItem = () => {
    if (onClick) {
      if (item && showTrashIcon && !itemIsInPreorder) {
        dispatch(showRemoveCartItemPopup({ item, onRemoveItem: onClick }));
      } else {
        onClick();
      }
    }
  };

  return (
    <StyledButton
      label={<Icon data-testid={`product-selection-controls_${showTrashIcon ? 'trash-icon' : 'minus-icon'}`} />}
      onClick={decreaseOrRemoveItem}
      {...props}
    />
  );
};

export const PlusButton: FC<BaseProps> = ({ onClick, ...props }) => {
  return (
    <StyledButton
      label={<PlusIcon data-testid='product-selection-controls_plus-icon' />}
      onClick={onClick}
      {...props}
    />
  );
};

export const CalculatorButton: FC<BaseProps> = ({ onClick, ...props }) => {
  return (
    <StyledButton
      label={<CalculatorIcon data-testid='product-selection-controls_calculator-icon' />}
      onClick={onClick}
      {...props}
    />
  );
};
