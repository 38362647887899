import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { callback } from 'models/Misc';
import { colors } from 'css/Theme';
import { ConfirmationPopup } from 'components/popups';
import { ReactComponent as ProductScan } from 'assets/icons/scan-icon.svg';
import { ReactComponent as AddItemIcon } from 'assets/icons/add-items-list.svg';
import { Loader } from 'components/backoffice/loader';
import { Header } from 'components/modals/ConfirmationModal';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type StartOrderPopupProps = {
  hide: callback;
  onGoToCart: () => Promise<void>;
  onCreatePreorder: () => Promise<void>;
};

export const StartOrderPopup: FC<StartOrderPopupProps> = ({ hide, onGoToCart, onCreatePreorder }) => {
  const [preorderLoading, setPreorderLoading] = useState<boolean>(false);
  const [enterPosLoading, setEnterPosLoading] = useState<boolean>(false);

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <StyledPopup
      isVisible
      hide={hide}
      automationId='start-an-order-modal'
      title='Start an order'
      isModalBridgeEnabled={isModalBridgeEnabled}
    >
      <Container>
        <StyledButton
          data-testid='start-an-order-modal_fulfillment'
          disabled={preorderLoading || enterPosLoading}
          onClick={async () => {
            setPreorderLoading(true);
            try {
              await onCreatePreorder();
            } finally {
              setPreorderLoading(false);
            }
          }}
        >
          <IconContainer color={colors.dutchie.blueAccent}>
            {preorderLoading ? <Loader variant='custom' color={colors.dutchie.primaryWhite} /> : <AddItemIcon />}
          </IconContainer>
          <ButtonTitle>Fulfillment</ButtonTitle>
          <ButtonDesc>Prepare an order to be fulfilled later.</ButtonDesc>
        </StyledButton>
        <StyledButton
          data-testid='start-an-order-modal_standard'
          disabled={enterPosLoading || preorderLoading}
          onClick={async () => {
            setEnterPosLoading(true);
            try {
              await onGoToCart();
              hide();
            } finally {
              setEnterPosLoading(false);
            }
          }}
        >
          <IconContainer color={colors.dutchie.green50}>
            {enterPosLoading ? <Loader variant='custom' color={colors.dutchie.primaryWhite} /> : <ProductScan />}
          </IconContainer>
          <ButtonTitle>Standard</ButtonTitle>
          <ButtonDesc>Select your items and proceed to checkout.</ButtonDesc>
        </StyledButton>
      </Container>
    </StyledPopup>
  );
};

const StyledPopup = styled(ConfirmationPopup)<{ isModalBridgeEnabled?: boolean }>`
  background: ${colors.dutchie.lightGrey};

  ${Header} {
    background: ${colors.dutchie.lightGrey};
  }

  ${({ isModalBridgeEnabled }) =>
    isModalBridgeEnabled &&
    css`
      width: unset;
    `}
`;

const Container = styled.div`
  padding: 0.5rem 2rem 2rem;
  display: flex;
  gap: 1.5rem;
`;

const IconContainer = styled.div<{ color?: string }>`
  width: 3.5rem;
  height: 3.5rem;
  ${({ color }) => color && `background: ${color};`}
  flex: 0 0 3.5rem;
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 1rem;

  & > * {
    width: 100%;
    height: 100%;

    path {
      stroke: ${colors.dutchie.primaryWhite};
    }
  }
`;

const StyledButton = styled.button`
  width: 13.5rem;
  height: 13.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2.125rem;
  border: none;
  background: ${colors.dutchie.primaryWhite};
  border-radius: 0.625rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ButtonTitle = styled.div`
  color: black;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.3rem;
`;

const ButtonDesc = styled.div`
  color: ${colors.dutchie.grey30};
  font-size: 13px;
  line-height: 1.125rem;
`;
