import React, { useEffect } from 'react';
import { useAnonymousCart } from './hooks/useAnonymousCart';
import { CartPage as NewCartPage } from './CartPage';
import { CartPage as OldCartPage } from './CartPage_deprecated';
import { customEventKeys, logger } from 'util/logger';

export const CartPage = () => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  useEffect(() => {
    logger.debug('product list could be refetched', {
      key: customEventKeys.productsList.productsMightBeFetched,
      comment: 'Control event: Products may be fetched if not already cached',
    });
  }, []);
  return isAnonymousCartLDFlagEnabled ? <NewCartPage /> : <OldCartPage />;
};
