import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useInventoryTagFilteringInCart = () => {
  const ldClient = useLDClient();
  const isInventoryTagFilteringInCartEnabled: boolean = ldClient?.variation(
    'pos.register.inventory-tag-filtering-in-cart.rollout',
    false
  );
  return { isInventoryTagFilteringInCartEnabled };
};
