import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { callback } from 'models/Misc';
import { ConfirmationPopup } from 'components/popups';

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem;
`;

const Message = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
`;

type DeleteCustomerAddressPopupProps = {
  isVisible: boolean;
  onConfirm: () => Promise<void>;
  hide: callback;
};

export const DeleteCustomerAddressPopup: FC<DeleteCustomerAddressPopupProps> = ({ isVisible, hide, onConfirm }) => {
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
      setLoading(false);
      hide();
    } catch {
      setLoading(false);
      return Promise.reject();
    }
  };

  return (
    <ConfirmationPopup
      isVisible={isVisible}
      hide={hide}
      title='Delete'
      confirm={{
        text: 'Confirm',
        loading: loading,
        disabled: loading,
        onClick: confirm,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <ContentDiv>
        <Message>Are you sure you want to remove this address?</Message>
      </ContentDiv>
    </ConfirmationPopup>
  );
};
