import { useDispatch } from 'react-redux';

import { clearSelectedPaymentData, startCheckout } from 'store/actions/CheckoutActions';

import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

export const useDutchiePayPreauthButton = () => {
  // Hooks
  const dispatch = useDispatch();

  const { data: cart } = useGetCartDetails();

  // Computed values
  const grandTotalRounded = cart.GrandTotalRounded;

  // Handlers
  const handleDutchiePayCheckout = () => {
    dispatch(clearSelectedPaymentData());
    dispatch(startCheckout(grandTotalRounded));
  };

  return {
    handleClick: handleDutchiePayCheckout,
  };
};
