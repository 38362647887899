import React from 'react';
import { useScanner } from 'components/Scanner';
import { DPInStoreStep, validatePinInput } from './Common';
import scanAnimation from 'assets/animations/dutchie-pay-instore/scan.json';
import Lottie from 'lottie-react';

const pinCodeCaptureContent = {
  primaryText: "Scan barcode on customer's phone",
  secondaryText: 'After a customer logs in, scan the barcode on their phone.',
  illustration: <Lottie animationData={scanAnimation} loop={true} />,
};

type DPInStorePinCodeCaptureProps = {
  handleDutchiePayInStoreCartCreation: (arg0: string) => void;
};

export const DPInStorePinCodeCapture = ({ handleDutchiePayInStoreCartCreation }: DPInStorePinCodeCaptureProps) => {
  const scanner = useScanner();

  const onScan = React.useCallback(
    async (scanData: string) => {
      const sanitizedScanData = scanData.replace(/[^a-zA-Z0-9]/gi, '');
      if (validatePinInput(sanitizedScanData)) {
        handleDutchiePayInStoreCartCreation(sanitizedScanData);
      }
    },
    [handleDutchiePayInStoreCartCreation]
  );

  React.useEffect(() => {
    scanner.changeAction(onScan);
  }, [scanner, onScan]);

  return <DPInStoreStep {...pinCodeCaptureContent} />;
};
