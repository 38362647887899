
import { cancelPaymentsHubTransaction } from 'api/CheckoutApi';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useState, useEffect } from 'react';
import { logger } from 'util/logger/Logger';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { usePaymentsHubStreamCancellationV2 } from './launch-darkly/usePaymentsHubStreamCancellationV2';

export function useCancelHubTransactionOnCartLeave(path: string) {
  const [previouslyInCart, setPreviouslyInCart] = useState(false);
  const guest = useSelector((state: State) => state.customer.details);
  const isUsePaymentsHubEnabled = useSelector((state: State) => state.settings.integrations?.UsePaymentsHub);
  const ldClient = useLDClient();
  const usePaymentsHubStreamCancellationV2Flag = usePaymentsHubStreamCancellationV2();

  useEffect(() => {
    // If we're using v2 of cart streaming cancellations, this hook is obsolete.
    // Once this new flag is true everywhere, we should delete this hook.
    if (usePaymentsHubStreamCancellationV2Flag) {
      return;
    }

    const isInCart = path === '/cart';

    if (ldClient?.variation('payments.payments-hub-stream-cancellation.rollout', false)) {

      // When first loading the cart page, set the cartPageDisplayed flag to true
      if (!isInCart && previouslyInCart) {
        // When leaving the cart page, reset the cartPageDisplayed flag to false and attempt to cancel the order
        if (guest?.ShipmentId && isUsePaymentsHubEnabled) {
          cancelPaymentsHubTransaction({ ShipmentId: guest.ShipmentId }).catch((error) => {
            logger.error('cancelPaymentsHubTransaction listener caught error', { error });
          });
        }
      }
    }
    setPreviouslyInCart(isInCart);
  }, [guest, isUsePaymentsHubEnabled, ldClient, path, previouslyInCart, usePaymentsHubStreamCancellationV2Flag]);
}
