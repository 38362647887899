import React, { FC } from 'react';

import { CashDetailsPanel } from '../CashDetailsPanel';
import { ConfirmTransactionPopup } from '../ConfirmTransactionPopup';
import { LoadingButton } from 'components/buttons';
import { Select, TextArea } from 'components/inputs';
import { Label } from 'components/text';
import { useDeposit } from './useDeposit';

import {
  ButtonsContainer,
  CashDetailsButton,
  CashInputContainer,
  Container,
  CurrencyInput,
  Fields,
  InputContainer,
  Row,
  TabTitle,
  TextAreaContainer,
} from '../Styles';

export type DepositProps = {
  onDeposit: () => void;
  registerId: number;
};

export const Deposit: FC<DepositProps> = (props) => {
  const {
    cashCounted,
    handleClickEnterCashDetails,
    handleDeposit,
    isConfirmPopupVisible,
    isDepositDisabled,
    isLoading,
    note,
    setCashCounted,
    setNote,
    setTransactionAdjustmentReason,
    toggleConfirmPopup,
    transactionAdjustmentReason,
    transactionAdjustmentReasonOptions,
  } = useDeposit(props);

  return (
    <Container data-testid='deposit-container'>
      <TabTitle>Deposit</TabTitle>
      <Fields>
        <Row>
          <CashInputContainer>
            <CurrencyInput
              label='Cash Counted'
              allowNegative={false}
              value={cashCounted.formattedValue}
              onValueChange={setCashCounted}
              data-testid='deposit_input_cash-counted'
            />
            <CashDetailsButton $variant='primary' onClick={handleClickEnterCashDetails}>
              Edit cash details
            </CashDetailsButton>
          </CashInputContainer>
          <InputContainer>
            <Label>Reason</Label>
            <Select
              options={transactionAdjustmentReasonOptions}
              onChange={setTransactionAdjustmentReason}
              value={transactionAdjustmentReason}
              placeholder='Choose a reason'
            />
          </InputContainer>
        </Row>
        <TextAreaContainer>
          <Label>Note</Label>
          <TextArea value={note} onChange={(e) => setNote(e.target.value)} data-testid='deposit_textarea_notes' />
        </TextAreaContainer>
      </Fields>
      <ButtonsContainer>
        <LoadingButton loading={isLoading} disabled={isDepositDisabled} onClick={toggleConfirmPopup}>
          Deposit
        </LoadingButton>
      </ButtonsContainer>
      {/* Popups */}
      <ConfirmTransactionPopup
        title='Confirm Deposit'
        message={`Total: $ ${cashCounted.formattedValue}`}
        onConfirm={handleDeposit}
        isVisible={isConfirmPopupVisible}
        hide={toggleConfirmPopup}
      />
      <CashDetailsPanel onSave={setCashCounted} />
    </Container>
  );
};
