import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { callback, callback1 } from 'models/Misc';
import { CustomerDetails } from 'models/Customer';
import {
  showUnsavedChangesPopup,
  showPotentialDuplicatePopup,
  showConvertToNonAnonymousPopup,
  showCheckinCaregiverPopup,
  showSelectCollectorFromProfilePopup,
  showVerifyBirthdateFromProfilePopup,
  showStartOrderPopup,
  showCreatePreOrderFromProfilePopup,
} from 'store/actions/PopupsActions';
import { PopupsState } from '.';

export type CustomerPopupsPayloads = {
  showConvertToNonAnonymousPopup: { customer: CustomerDetails };
  showCheckinCaregiverPopup: {
    onCheckin: callback1<{ andEnterCart?: boolean; andEnterPreOrderCart?: boolean; caregiverId?: string }>;
    guestId: number;
  };
  /** @deprecated To be removed once we migrate to global popups */
  showSelectCollectorFromProfilePopup: { customerId: number; shipmentId: number };
  showPotentialDuplicatePopup: {
    existingCustomerDetails: CustomerDetails;
    newCustomerDetails: CustomerDetails;
  };
  /** @deprecated To be removed once we migrate to global popups */
  showVerifyBirthdateFromProfilePopup: {
    guestDob: string;
    isAnonymous: boolean;
    acctId?: number;
    onSuccess: callback1<Date | undefined>;
  };
  /** @deprecated To be removed once we migrate to global popups */
  showStartOrderPopup: { onGoToCart: () => Promise<void>; onCreatePreorder: () => Promise<void> };
  /** @deprecated To be removed once we migrate to global popups */
  showCreatePreOrderFromProfilePopup: { onCheckIn: callback };
  showUnsavedChangesPopup: { save: () => Promise<boolean>; navigate: () => void };
};

export type CustomerPopupsState = {
  convertToNonAnonymousPopup: { customer: CustomerDetails } | null;
  checkinCaregiverPopup: {
    onCheckin: callback1<{ andEnterCart?: boolean; andEnterPreOrderCart?: boolean; caregiverId?: string }>;
    guestId: number;
  } | null;
  /** @deprecated To be removed once we migrate to global popups */
  selectCollectorFromProfilePopup: { customerId: number; shipmentId: number } | null;
  potentialDuplicatePopup: {
    existingCustomerDetails: CustomerDetails;
    newCustomerDetails: CustomerDetails;
  } | null;
  /** @deprecated To be removed once we migrate to global popups */
  verifyBirthdateFromProfilePopup: {
    guestDob: string;
    isAnonymous: boolean;
    acctId?: number;
    onSuccess: callback1<Date | undefined>;
  } | null;
  /** @deprecated To be removed once we migrate to global popups */
  startOrderPopup: { onGoToCart: () => Promise<void>; onCreatePreorder: () => Promise<void> } | null;
  /** @deprecated To be removed once we migrate to global popups */
  createPreOrderFromProfilePopup: { onCheckIn: callback } | null;
  unsavedChangesPopup: { save: () => Promise<boolean>; navigate: () => void } | null;
};

export const customerPopupsInitialState: CustomerPopupsState = {
  convertToNonAnonymousPopup: null,
  checkinCaregiverPopup: null,
  selectCollectorFromProfilePopup: null,
  potentialDuplicatePopup: null,
  verifyBirthdateFromProfilePopup: null,
  startOrderPopup: null,
  createPreOrderFromProfilePopup: null,
  unsavedChangesPopup: null,
};

export const buildCustomerPopupsCases = (builder: ActionReducerMapBuilder<PopupsState>, initialState: PopupsState) => {
  builder.addCase(showPotentialDuplicatePopup, (_state, action) => ({
    ...initialState,
    potentialDuplicatePopup: action.payload,
  }));
  builder.addCase(showUnsavedChangesPopup, (_state, action) => ({
    ...initialState,
    unsavedChangesPopup: action.payload,
  }));
  builder.addCase(showConvertToNonAnonymousPopup, (_state, action) => ({
    ...initialState,
    convertToNonAnonymousPopup: action.payload,
  }));
  builder.addCase(showCheckinCaregiverPopup, (_state, action) => ({
    ...initialState,
    checkinCaregiverPopup: action.payload,
  }));
  builder.addCase(showSelectCollectorFromProfilePopup, (_state, action) => ({
    ...initialState,
    selectCollectorFromProfilePopup: action.payload,
  }));
  builder.addCase(showVerifyBirthdateFromProfilePopup, (_state, action) => ({
    ...initialState,
    verifyBirthdateFromProfilePopup: action.payload,
  }));
  builder.addCase(showStartOrderPopup, (_state, action) => ({
    ...initialState,
    startOrderPopup: action.payload,
  }));
  builder.addCase(showCreatePreOrderFromProfilePopup, (_state, action) => ({
    ...initialState,
    createPreOrderFromProfilePopup: action.payload,
  }));
};
