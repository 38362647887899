import React, { FC, Suspense, useEffect, useState } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { getUserDetails, consumeLoginToken, getEloUserDetails } from 'api/PosApi';
import {
  useScanStatus,
  useExternalWebsites,
  useUsbDeviceConnection,
  CFD_PATH,
  ANDROID_CFD_PATH,
  isAndroid,
  isWebViewApp,
} from 'util/hooks';
import { NavigationWrapper } from 'components/layout/NavigationWrapper';
import { Loader } from 'components/backoffice/loader';
import { callback } from 'models/Misc';
import { StatusBanner } from 'components/StatusBanner';
import { Notifications } from 'components/Notifications';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { WebCustomerFacingDisplay, AndroidCustomerFacingDisplay } from 'pages/CFD';
import { BiotrackPage } from 'pages/BiotrackPage';
import { CartPage } from 'pages/CartPage';
import { CreatePreOrderPage } from 'pages/CreatePreOrderPage';
import { CustomersPage } from 'pages/CustomersPage';
import { EditCustomerPage } from 'pages/EditCustomerPage';
import { FleetPage } from 'pages/FleetPage';
import { GuestListPage } from 'pages/GuestListPage';
import { LocationSelectionPage } from 'pages/LocationSelectionPage';
import { LoginPage } from 'pages/LoginPage';
import { LoginPageLoadingVersion } from 'pages/LoginPageLoadingVersion';
import { PinLoginPage } from 'pages/PinLoginPage';
import { ProductsPage } from 'pages/ProductsPage';
import { RegisterSelectionPage } from 'pages/RegisterSelectionPage';
import { CashManagementPage } from 'pages/RegisterTransactionsPage';
import { ResetPage } from 'pages/ResetPage';
import { SettingsPage } from 'pages/SettingsPage';
import { TransactionsPage } from 'pages/TransactionsPage';
import { SamlPage } from 'pages/SamlPage';
import { DeliveryPage } from 'pages/DeliveryPage';
import { DeliveryRoutePage } from 'pages/DeliveryRoutePage';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { State } from 'store';
import { setLaunchDarklyFlags } from 'store/actions/LaunchDarklyActions';
import { loadRegion, loadBackOfficeSettings, loadEnvironmentSettings } from 'store/actions/SettingsActions';
import { getCurrentServer, handleAutoLogin, handleSSOLogin, logout, ssoLoginEnabled } from 'store/actions/UserActions';
import styled from 'styled-components';
import { PathListener } from './PathListener';
import { clearGuestSearchQuery } from 'store/actions/GuestListActions';
import { FullScreenPortal } from 'components/FullScreenPortal';
import { PersistedValueKey, setPersistedValue } from 'util/persisted-values';
import { ActionableError } from 'components/ActionableError';
import { logger, customEventKeys } from 'util/logger';
import { ProvideCFDListeners } from 'components/ProvideCFDListeners';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { Peripheral } from 'util/capacitor/peripheral';

import { Main } from 'css/DutchieGlobalStyles';
import { ScannerStatusEventContext } from 'util/logger/types/scanning';
import type { SearchScannersContext } from 'util/logger/types/scanning';
import type { UsbDeviceStatusEventContext } from 'util/logger/types/usbDevice';
import IdleTimer from 'components/IdleTimer';
import { getSearchScannersDescription } from 'util/logger/helpers/scanning';
import { RegisterSelectionPageV2 } from 'pages/LoginPageV2/RegisterSelectionPageV2';
import { LocationSelectionPageV2 } from 'pages/LoginPageV2/LocationSelectionPageV2';
import { GlobalPopups } from 'components/GlobalPopups';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { FeatureFlagsPusherChannel } from 'components/FeatureFlagsPusherChannel';
import { LoggingLevel } from 'util/logger/LoggingProvider';
import { useHardwareLibraryPackage } from 'util/hooks/useHardwareLibraryPackage';
import { useEnable100PercentLogRocketSampling } from 'util/hooks/launch-darkly/useEnable100PercentLogRocketSampling';
import { useHardwareLibrary } from 'util/hooks/launch-darkly/useHardwareLibrary';
import { ServerMigrationPromptModal } from 'components/layout/ServerMigrationUI/ServerMigrationPromptModal';
import { FullPageMigrationTakeover } from 'components/layout/ServerMigrationUI/FullPageMigrationTakeover';
import { ServerMigrationBanner } from 'components/layout/ServerMigrationUI/ServerMigrationBanner';
import { useServerMigrationConfig } from 'components/layout/ServerMigrationUI/useServerMigrationConfig';
import { AndroidServerMigrationBanner } from 'components/layout/ServerMigrationUI/AndroidServerMigrationBanner';
import { AndroidServerMigrationPromptModal } from 'components/layout/ServerMigrationUI/AndroidServerMigrationPromptModal';
import { AndroidFullPageMigrationTakeover } from 'components/layout/ServerMigrationUI/AndroidFullPageMigrationTakeover';

import { useNewSettingsUi } from 'util/hooks/launch-darkly/useNewSettingsUi';
import { useMonitoredCfdWindow } from 'pages/SettingsPage/Tabs/Hardware/Cfd/hooks/useMonitoredCfdWindow';
import { useCancelHubTransactionOnCartLeave } from 'util/hooks/useCancelHubTransactionOnCartLeave';
import { ServerMigrationPromptModalWithUpdatedMessaging } from 'components/layout/ServerMigrationUI/ServerMigrationPromptModalUpdatedMessaging';
import { AndroidServerMigrationPromptModalWithUpdatedMessaging } from 'components/layout/ServerMigrationUI/AndroidServerMigrationPromptModalUpdatedMessaging';
import { ServerMigrationBannerWithUpdatedMessaging } from 'components/layout/ServerMigrationUI/ServerMigrationBannerUpdatedMessaging';
import { AndroidServerMigrationBannerWithUpdatedMessaging } from 'components/layout/ServerMigrationUI/AndroidServerMigrationBannerUpdatedMessaging';
import { GeneralHeaderBanner } from 'components/layout/Banners/GeneralHeaderBanner';
import { NonSandboxBanner } from 'components/layout/Banners/NonSandboxBanner';
import { useNonSandboxBannerRollout } from 'util/hooks/launch-darkly/useNonSandboxBannerRollout';
import { useHeightOffset } from 'components/layout/Banners/use-height-offset';
import { useScrollInputsIntoView } from 'util/hooks/useScrollInputsIntoView';

export const AppComponent: FC = () => {
  const dispatch = useDispatch();
  const buildNumber = useSelector((state: State) => state.settings.buildNumber?.BuildNumber);
  const fullName = useSelector((state: State) => state.user.FullName);
  const [loading, setLoading] = useState(true);
  const [loadingLoginDetails, setLoadingLoginDetails] = useState(false);
  const [needToRefreshBackOfficeSettings, setNeedToRefreshBackOfficeSettings] = useState(true);
  const user = useSelector((state: State) => state.user);
  const selectedLocation = useSelector((state: State) => state.user.selectedLocation);
  const selectedLsp = useSelector((state: State) => state.user.selectedLsp);
  const selectedRegister = useSelector((state: State) => state.settings.selectedRegister);
  const isSamlLogin = window.location.search.toLowerCase().indexOf('samllogin=true') > 0;
  const url = window.location.pathname; // The actual pathname in browser
  const [registerPath, setRegisterPath] = useState(url); // The route computed by react-router
  const ldClient = useLDClient();
  const isLoginPage = !fullName || url.toLowerCase() === '/ssologin';

  const isHardwareLibraryActive = useHardwareLibrary();
  const isNewSettingsUi = useNewSettingsUi();
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  // Reference hardware library hook to manage initialization and disposal
  useHardwareLibraryPackage();

  const isNewLoginEnabled = ldClient?.variation('pos.iam.register-ux-updates-2023-11.rollout', false);
  const is100PercentLogRocketSamplingEnabled = useEnable100PercentLogRocketSampling();
  const isPinLoginEloEnabled = ldClient?.variation('pos.iam.pin-login-elo.rollout', false);
  const { nonSandboxBannerRollout } = useNonSandboxBannerRollout();
  const heightOffset = useHeightOffset();

  const { shouldShowBlockingUI, useUpdatedMessaging } = useServerMigrationConfig();
  const MigrationPromptModalComponent = useUpdatedMessaging
    ? ServerMigrationPromptModalWithUpdatedMessaging
    : ServerMigrationPromptModal;
  const AndroidMigrationPromptModalComponent = useUpdatedMessaging
    ? AndroidServerMigrationPromptModalWithUpdatedMessaging
    : AndroidServerMigrationPromptModal;
  const ServerMigrationBannerComponent = useUpdatedMessaging
    ? ServerMigrationBannerWithUpdatedMessaging
    : ServerMigrationBanner;
  const AndroidServerMigrationBannerComponent = useUpdatedMessaging
    ? AndroidServerMigrationBannerWithUpdatedMessaging
    : AndroidServerMigrationBanner;

  useMonitoredCfdWindow();

  useEffect(() => {
    const registerVersion = 'v1';
    logger.setGlobalProperty('register_version', registerVersion);
    logger.setGlobalProperty('backend_build_number', buildNumber);

    const registerPrinterVersion = !isHardwareLibraryActive
      ? 'legacy'
      : isNewSettingsUi
      ? 'new_settings'
      : 'hardware_package';
    logger.setGlobalProperty('register_printer_version', registerPrinterVersion);
    // Used to track the difference in metrics for anonymous cart workflow vs old cart page
    logger.setGlobalProperty(
      'register_cart_version',
      isAnonymousCartLDFlagEnabled ? 'anonymous-cart-workflow' : 'legacy-cart'
    );
    logger.setGlobalProperty('user', {
      id: user.UserId,
      name: user.username,
      lspId: selectedLsp?.LspId,
      locId: selectedLocation?.location_id,
    });

    // Default logging level, mostly for automatic click events
    // Custom attributes at the individual log level will override this
    logger.setGlobalProperty('level', LoggingLevel.INFO);

    const platform = isAndroid ? 'android' : isWebViewApp ? 'ios' : 'browser';
    logger.setGlobalProperty('platform', platform);
  }, [
    buildNumber,
    isAnonymousCartLDFlagEnabled,
    isHardwareLibraryActive,
    isNewSettingsUi,
    selectedLocation,
    selectedLsp,
    user.UserId,
    user.username,
  ]);

  // Resets hub device ui to idle state and attempts to cancel the order when leaving cart screen.
  useCancelHubTransactionOnCartLeave(registerPath);

  // Used to trigger collection of all sessions in LogRocket with specific LD flag enabled
  useEffect(() => {
    if (is100PercentLogRocketSamplingEnabled) {
      logger.debug('LOG_ROCKET_100_PERCENT_SAMPLING', {
        key: customEventKeys.logRocket100PercentSampling,
      });
    }
  }, [is100PercentLogRocketSamplingEnabled]);

  useEffect(() => {
    // Signals to capacitor to load peripheral services: scanners, printers, and scales
    if (isAndroid) {
      try {
        Peripheral.startService();
      } catch (e) {
        dispatch(errorNotification('Peripheral service failed to start'));
        logger.error(e, { message: 'peripheral startService failed' });
      }
    }
  }, [dispatch]);

  useScanStatus(async ({ statusName, peripheral }) => {
    logger.info<ScannerStatusEventContext>(`scanner ${peripheral.id} status changed to ${statusName}`, {
      key: customEventKeys.scanning.bluetooth.status,
      peripheral,
      statusName,
    });

    if (statusName.toLowerCase() === 'connected') {
      dispatch(successNotification('Scanner connected'));
    }

    if (statusName.toLowerCase() === 'disconnected') {
      dispatch(warningNotification('Scanner lost connection'));
    }

    // This try/catch block is only used for logging
    try {
      const { results: availableScanners } = await Peripheral.searchScanners();

      logger.debug<SearchScannersContext>(getSearchScannersDescription(availableScanners, false), {
        key: customEventKeys.scanning.bluetooth.search,
        availableScanners,
        userInitiated: false,
      });
    } catch (e) {
      logger.error(e, { message: 'searchScanners failed', userInitiated: false });
    }
  });

  useEffect(() => {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (canGoBack) {
        window.history.back();
      }
    });
  }, []);

  useUsbDeviceConnection(({ attached, device }) => {
    logger.info<UsbDeviceStatusEventContext>(
      `USB device ${device.deviceId} was ${attached ? 'attached' : 'unattached'}`,
      {
        key: customEventKeys.usbDevice.status,
        attached,
        device,
      }
    );
  });

  /**
   * On page refresh, load settings from back office. Skip if we're on the login page.
   */
  useEffect(() => {
    if (needToRefreshBackOfficeSettings && !loadingLoginDetails) {
      if (!isLoginPage) {
        dispatch(loadBackOfficeSettings());
      }

      setNeedToRefreshBackOfficeSettings(false);
    }
    // Reason not to include needToRefreshBackOfficeSettings: we need to wait for the page to refresh before loading the settings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoginPage, ldClient, loadingLoginDetails]);

  useEffect(() => {
    (async () => {
      dispatch(loadRegion());
      dispatch(ssoLoginEnabled());
      if (!isSamlLogin) {
        setLoadingLoginDetails(true);
        await checkSession(() => dispatch(logout()), isPinLoginEloEnabled);
        setLoadingLoginDetails(false);
      }
    })();
  }, [dispatch, isPinLoginEloEnabled, isSamlLogin]);

  useEffect(() => {
    if (isSamlLogin) {
      dispatch(handleSSOLogin());
    }
  }, [dispatch, isSamlLogin]);

  useEffect(() => {
    if (window.location.host.toLowerCase().indexOf('azurewebsites.net') > -1) {
      dispatch(getCurrentServer());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(loadEnvironmentSettings());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        const query = new URLSearchParams(window.location.search);

        const token = query.get('dutchie_token');
        if (!token) {
          return;
        }

        setLoading(true);

        const data = await consumeLoginToken(token);
        if (data.HardwareId) {
          const expires = new Date();
          expires.setFullYear(expires.getFullYear() + 1);
          setPersistedValue(PersistedValueKey.hardwareId, data.HardwareId, { path: '/', expires });
        }

        // logout of previous session
        await dispatch(logout());

        // login with token session
        await dispatch(
          handleAutoLogin({
            sessionId: data.SessionId,
            locId: data.LocId,
            registerId: data.RegisterId,
            hardwareId: data.HardwareId,
          })
        );
      } finally {
        setLoading(false);
        const url = new URL(window.location.href);
        url.searchParams.delete('dutchie_token');
        window.history.replaceState(null, '', url.toString());
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    url !== '/customers' && dispatch(clearGuestSearchQuery());
  }, [dispatch, url]);

  const region = window.sessionStorage.getItem('Region') || '';

  useEffect(() => {
    if (ldClient && selectedLocation && selectedLsp && user) {
      ldClient.identify({
        key: user.username,
        email: user.username,
        custom: {
          locId: selectedLocation.location_id,
          lspId: selectedLsp.Id,
          region: region,
          host: window.location.hostname,
        },
      });
    }
  }, [selectedLocation, selectedLsp, user, ldClient, region]);

  useExternalWebsites();

  const stringifiedFlags = JSON.stringify(ldClient?.allFlags());

  useEffect(() => {
    if (ldClient) {
      dispatch(
        setLaunchDarklyFlags({
          ...ldClient?.allFlags(),
        })
      );
    }
  }, [dispatch, ldClient, stringifiedFlags]);

  const checkSession = async (cb: callback, _isPinLoginEloEnabled: boolean) => {
    const getUserDetailsFn = isAndroid && _isPinLoginEloEnabled ? getEloUserDetails : getUserDetails;
    await getUserDetailsFn()
      .then((resp) => {
        if (!resp?.UserName) {
          throw resp;
        }
      })
      .catch(cb);
  };

  useScrollInputsIntoView();

  const pathMatches = (url: string) => {
    return window.location.pathname === url;
  };

  if (shouldShowBlockingUI) {
    return (
      <>
        <Notifications />
        {!isAndroid && <FullPageMigrationTakeover />}
        {isAndroid && <AndroidFullPageMigrationTakeover />}
      </>
    );
  }

  if (loading || loadingLoginDetails) {
    return (
      <SpinnerWrapperDiv>
        <Loader variant='black' size='2x' />
      </SpinnerWrapperDiv>
    );
  }

  if (url.startsWith('/api/saml')) {
    return (
      <ProvideCFDListeners path={url}>
        <Router>
          <Main>
            <Notifications />
            <Switch>
              <Route path='/api/saml' component={SamlPage} />
            </Switch>
          </Main>
        </Router>
      </ProvideCFDListeners>
    );
  }
  if (url === '/reset' || url === '//reset') {
    return (
      <ProvideCFDListeners path={url}>
        <Router>
          <Main>
            {!isNewLoginEnabled && <Notifications />}

            <Switch>
              <Route path={['/reset', '//reset']} component={ResetPage} />
              <Route path='/login' component={LoginPage} />
            </Switch>
          </Main>
        </Router>
      </ProvideCFDListeners>
    );
  }

  if (url === '/pinlogin') {
    return (
      <ProvideCFDListeners path={url}>
        <Router>
          <Main>
            <Notifications />
            <Switch>
              <Route path='/pinlogin' component={PinLoginPage} />
            </Switch>
          </Main>
        </Router>
      </ProvideCFDListeners>
    );
  }

  if (url.toLowerCase() === '/ssologin') {
    return (
      <ProvideCFDListeners path={url}>
        <Main>
          {!isNewLoginEnabled && <Notifications />}
          <LoginPageLoadingVersion isSSO={true} />
        </Main>
      </ProvideCFDListeners>
    );
  }

  // Android CFD
  if (pathMatches(`/${ANDROID_CFD_PATH}`)) {
    return (
      <Main>
        <AndroidCustomerFacingDisplay />
      </Main>
    );
  }

  // Web CFD
  if (pathMatches(`/${CFD_PATH}`)) {
    return (
      <Main>
        <WebCustomerFacingDisplay />
      </Main>
    );
  }

  if (!fullName) {
    return (
      <ProvideCFDListeners path='/signin'>
        <Main>
          {!isNewLoginEnabled && <Notifications />}
          <LoginPageLoadingVersion isSSO={false} />
        </Main>
      </ProvideCFDListeners>
    );
  }

  if (!selectedLocation) {
    return (
      <ProvideCFDListeners path='/select_location'>
        <Router>
          <Main>
            {isNewLoginEnabled ? (
              <LocationSelectionPageV2 />
            ) : (
              <>
                <Notifications />
                <LocationSelectionPage />
              </>
            )}
          </Main>
        </Router>
      </ProvideCFDListeners>
    );
  }

  if (!selectedRegister) {
    return (
      <ProvideCFDListeners path='/select_register'>
        <Router>
          <Main>
            {isNewLoginEnabled ? (
              <RegisterSelectionPageV2 />
            ) : (
              <>
                <Notifications />
                <RegisterSelectionPage />
              </>
            )}
          </Main>
        </Router>
      </ProvideCFDListeners>
    );
  }

  return (
    <ProvideCFDListeners path={registerPath}>
      <Router>
        <FullScreenPortal>
          <NavigationWrapper>
            <Notifications />
            <ActionableError />
            <StatusBanner />
            <IdleTimer />
            <FeatureFlagsPusherChannel />
            <GeneralHeaderBanner />
            {nonSandboxBannerRollout && <NonSandboxBanner />}
            {!isAndroid && <MigrationPromptModalComponent />}
            {isAndroid && <AndroidMigrationPromptModalComponent />}
            {!isAndroid && <ServerMigrationBannerComponent />}
            {isAndroid && <AndroidServerMigrationBannerComponent />}
            <div style={{ height: `calc(100% - ${heightOffset})` }}>
              <Suspense
                fallback={
                  <SpinnerWrapperDiv>
                    <Loader variant='black' size='2x' />
                  </SpinnerWrapperDiv>
                }
              >
                <PathListener onChange={setRegisterPath} />
                {isAnonymousCartLDFlagEnabled && <GlobalPopups />}
                <Switch>
                  <Route path='/guestlist' component={GuestListPage} />
                  <Route path='/delivery' component={DeliveryPage} />
                  <Route path='/deliveryRoute' component={DeliveryRoutePage} />
                  <Route path='/fleet' component={FleetPage} />
                  <Route path='/products' component={ProductsPage} />
                  <Route path='/customers' component={CustomersPage} />
                  <Route path='/settings' component={SettingsPage} />
                  <Route path='/edit-customer' component={EditCustomerPage} />
                  <Route path='/cart' component={CartPage} />
                  <Route path='/create-preorder' component={CreatePreOrderPage} />
                  <Route path='/transactions' component={TransactionsPage} />
                  <Route path='/saleshistory' component={TransactionsPage} />
                  <Route path='/register' component={CashManagementPage} />
                  <Route path='/register/:selectedTab' component={CashManagementPage} />
                  <Route path='/biotrack' component={BiotrackPage} />
                  <Redirect to='/guestlist' />
                </Switch>
              </Suspense>
            </div>
          </NavigationWrapper>
        </FullScreenPortal>
      </Router>
    </ProvideCFDListeners>
  );
};

const SpinnerWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`;
