import { useFeatureSwitches, FeatureSwitchKey } from 'pages/CartPage/hooks/useFeatureSwitches';
import { useState, useEffect } from 'react';

export const usePaymentsHubFeatureSwitch = () => {
  const { getFeatureSwitch } = useFeatureSwitches();
  const regalaFeatureSwitch = getFeatureSwitch(FeatureSwitchKey.REGALA);
  const dutchiePayFeatureSwitch = getFeatureSwitch(FeatureSwitchKey.DUTCHIEPAY);

  const [showPaymentsHubFeatureSwitch, setPaymentsHubFeatureSwitch] = useState(regalaFeatureSwitch.Enabled);

  useEffect(() => {
    setPaymentsHubFeatureSwitch(regalaFeatureSwitch.Enabled || dutchiePayFeatureSwitch.Enabled);
  }, [regalaFeatureSwitch.Enabled, dutchiePayFeatureSwitch.Enabled]);

  return showPaymentsHubFeatureSwitch;
};
