import type { TransactionHistoryPayload } from './transaction-history';

export const getTransactionHistoryKeys = {
  all: ['transaction_history'] as const,
  one: (payload: TransactionHistoryPayload) =>
    [
      ...getTransactionHistoryKeys.all,
      'one',
      payload.guestId,
      payload.includeCanceledTransactions ? 'withCancelations' : 'withoutCancelations',
    ] as const,
};
