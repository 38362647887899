import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { State } from 'store';
import { saveUserTableConfig, loadUserSettings } from 'store/actions/SettingsActions';
import { DragReorder } from 'components/layout/DragDrop';
import { CustomerSearchConfig } from 'models/Misc';
import { customerSearchConfigCols } from 'util/Helpers';
import { SectionGridContainer, SectionHeader } from './SettingPageComponents';
import { Switch } from 'components/inputs';

const SwitchContainer = styled.div`
  text-transform: capitalize;
`;

export const ConfigureGuestSearch: FC = () => {
  const userSettings = useSelector((state: State) => state.settings.userSettings);
  const currentConfig = userSettings.customerSearchConfig;
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<CustomerSearchConfig[]>(currentConfig || customerSearchConfigCols);

  useEffect(() => {
    if (currentConfig) {
      setColumns(currentConfig);
    }
  }, [currentConfig]);

  const handleCheckBoxClick = (colName: string, isHidden: boolean) => {
    const colsCopy = columns.map((i) => ({ ...i }));
    for (const i in colsCopy) {
      if (colsCopy[i].name === colName) {
        colsCopy[i].hidden = isHidden;
        break;
      }
    }
    return colsCopy;
  };

  const handleReorder = (cols: CustomerSearchConfig[]) => {
    const reorderColumns = cols.map(({ name, hidden }, i) => {
      return { name, hidden, order: i };
    });

    setColumns(reorderColumns);
  };

  const save = async (item: CustomerSearchConfig) => {
    const newColumns = handleCheckBoxClick(item.name, !item.hidden);
    await dispatch(saveUserTableConfig(newColumns));
    await dispatch(loadUserSettings());
  };

  return (
    <SectionGridContainer>
      <SectionHeader>Guest Search</SectionHeader>
      <DragReorder
        items={columns}
        Item={({ item }) => (
          <SwitchContainer>
            <Switch
              label={item.name}
              checked={!item.hidden}
              onChange={() => save(item)}
              data-testid='checkbox_div_toggle-customer-config-search'
            />
          </SwitchContainer>
        )}
        onReorder={(items) => handleReorder(items)}
      />
    </SectionGridContainer>
  );
};
