import React, { useMemo } from 'react';

import { Chevron, ChevronDirections } from 'assets/icons/chevron';
import { ReactComponent as ElipsisIcon } from 'assets/icons/ellipsis-fill-icon.svg';
import { DropTarget } from 'components/layout/DragDrop';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import styled from 'styled-components';
import { EmbeddedChildTableV2 } from './EmbeddedChildTableV2';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';

type EmbeddedParentTableRowV2Props = {
  id: string;
};

export function EmbeddedParentTableRowV2<ParentItem, ChildItem>(props: EmbeddedParentTableRowV2Props) {
  const { id } = props;
  const {
    expandedParentId,
    parentItemMap,
    parentColumns,
    childItems,
    getChildId,
    onChangeParent,
    parentActions,
    dispatch,
    onExpandParent,
  } = useEmbeddedTableStore<ParentItem, ChildItem>();

  const item = parentItemMap[id];
  const isExpanded = id === expandedParentId;
  const dragMoveDisabled = !onChangeParent;
  const toggleExpanded = () => {
    dispatch({ type: 'toggleExpandedParent', parentId: id });
    if (onExpandParent) {
      onExpandParent(parentItemMap[id] ?? null);
    }
  };
  const extraColumns = 1 /* the expansion indicator */ + (parentActions ? 1 : 0);
  const actions = useMemo(
    () => (typeof parentActions === 'function' ? parentActions(item) : parentActions),
    [parentActions, item]
  );

  if (!item) {
    console.warn(`No parent item for ID ${id}`); // eslint-disable-line
    return null;
  }

  const handleOnDrop = (idPayload: string) => {
    const payloadChild = childItems.find((item) => getChildId(item) === idPayload);
    if (!payloadChild) {
      return;
    }
    if (onChangeParent) {
      onChangeParent([payloadChild], item);
    }
  };

  return (
    <React.Fragment>
      <DropTarget
        className={['parent', isExpanded ? 'expanded' : 'collapsed'].join(' ')}
        as='tr'
        effect='copy'
        onDrop={handleOnDrop}
        dropDisabled={dragMoveDisabled}
        style={{
          borderBottom: '1px solid #e3e7e9',
        }}
      >
        <TDChevronStyle onClick={() => toggleExpanded()} className='parent expansion-indicator'>
          <TDChevronWrapper>
            <Chevron direction={isExpanded ? ChevronDirections.DOWN : ChevronDirections.RIGHT} />
          </TDChevronWrapper>
        </TDChevronStyle>
        {parentColumns.map(({ Cell, cellProps, width }, i) => (
          <TDCellInfoStyle className='parent' key={`parent-${id}-column-${i}`} width={width} isExpanded={isExpanded}>
            <TDCellInfoWrapper>
              <Cell {...cellProps} item={item} />
            </TDCellInfoWrapper>
          </TDCellInfoStyle>
        ))}
        {!!actions?.length && (
          <TDActionsStyle className='parent actions'>
            <OverflowMenu
              anchor={<ElipsisIcon />}
              anchorProps={{ padding: '1rem 1rem', showCursor: true }}
              menuOptions={actions.map((action) => ({
                text: action.label,
                onClick: () => action.onClick(item),
              }))}
            />
          </TDActionsStyle>
        )}
      </DropTarget>
      {isExpanded && (
        <tr className='embedded'>
          <td colSpan={parentColumns.length + extraColumns} className='embedded'>
            <EmbeddedChildTableV2<ParentItem, ChildItem> parentId={id} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}

const TDActionsStyle = styled.td`
  display: flex;
  justify-content: center;
  position: sticky;
  right: 0;
  background: white;
  box-shadow: -3px 0px 3px -2px #b0b0b0;
  svg {
    height: 100%;
    color: #121516;
  }
`;

const TDChevronStyle = styled.td`
  display: flex;
  justify-content: center;
  padding: 0.75rem 1rem;
  svg {
    path {
      fill: #121516;
    }
  }
`;

const TDChevronWrapper = styled.div`
  display: flex;
`;

const TDCellInfoStyle = styled.td<{ width?: string; isExpanded?: boolean }>`
  width: ${({ width }) => width};
  padding: 0.75rem 1rem;
  font-size: 0.8125rem;
  font-weight: ${({ isExpanded }) => (isExpanded ? '600' : 'normal')};
`;

const TDCellInfoWrapper = styled.div`
  display: flex;
`;
