import React from 'react';
import styled from 'styled-components';

import { breakpoints } from 'css/Theme';
import { CartDisplayItem, CartItemType } from './CartItems.types';
import { useCartItems } from './useCartItems';
import { Item } from '../Item';
import { PreorderItem } from '../PreorderItem';

export const CartItems = () => {
  const {
    cartDiscounts,
    isCartLocked,
    cartItems,
    onRemoveItem,
    onPrintOneLabel,
    onPrintAllLabel,
    onDownloadAllLabels,
  } = useCartItems();

  if (!cartItems.length) {
    return null;
  }

  return (
    <Cart data-testid='cart-summary-container'>
      {cartItems.map((item: CartDisplayItem) => {
        if (item.type === CartItemType.Preorder) {
          return <PreorderItem data-testid='cart-summary_item_cart-item' key={item.Id} item={item} />;
        } else {
          return (
            <Item
              data-testid='cart-summary_item_cart-item'
              key={item.InventoryId}
              item={item}
              discounts={cartDiscounts}
              onRemoveItem={isCartLocked ? undefined : onRemoveItem}
              onPrintOneLabel={() => onPrintOneLabel(item)}
              onPrintAllLabels={() => onPrintAllLabel(item)}
              onDownloadAllLabels={() => onDownloadAllLabels()}
            />
          );
        }
      })}
    </Cart>
  );
};

const Cart = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
  gap: 0.5rem;

  ${breakpoints.smallTablet.maxQuery} {
    padding: 0 1.5rem 1.5rem;
    overflow-y: unset;
  }
`;
