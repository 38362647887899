import React from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { LogoutIcon } from 'assets/icons/logout-icon';
import { UserIcon } from 'assets/icons/user-icon';
import { endSessionLogout } from './utils/endSessionLogout';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleLogout } from 'store/actions/UserActions';

interface StyledFooterDisplayProps {
  isAndroid: boolean;
}

type KeypadFooterProps = {
  isAndroid: boolean;
  pathname?: string;
};

export const KeypadFooter = ({ isAndroid = false, pathname }: KeypadFooterProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const path = pathname || window.location.pathname;
  const isPinLoginPage = path === '/pinlogin';

  const handleLogoutUser = async () => {
    /**
     * Remove the device session cookies here to prevent a double redirect.
     */
    document.cookie = 'Pos-Device-SessionTime=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'Pos-Device-Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    if (isAndroid) {
      window.sessionStorage.removeItem('DeviceSessionId');
      window.sessionStorage.removeItem('DeviceSessionTime');
    }

    dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));
    endSessionLogout();
  };

  return (
    <StyledFooter isAndroid={isAndroid}>
      <StyledLogout onClick={handleLogoutUser} data-testid='end-device-session'>
        <LogoutIcon />
        <span style={{ marginLeft: 8 }}>Logout</span>
      </StyledLogout>
      {isPinLoginPage && (
        <StyledLoginUsername onClick={handleLogoutUser} data-testid='username-password-login'>
          <span style={{ marginRight: 8 }}>Login with username</span>
          <UserIcon />
        </StyledLoginUsername>
      )}
    </StyledFooter>
  );
};

const StyledFooter = styled.div<StyledFooterDisplayProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isAndroid ? '0 48px 48px' : '0 64px 64px')};
  color: white;
  background-color: ${colors.dutchie.opal90};
`;

const StyledLogout = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.dutchie.opal20};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledLoginUsername = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.dutchie.opal20};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
