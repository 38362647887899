import { isArray } from 'lodash';

// Types

export type ContextData = Record<string, unknown>;

export type PersonalInfo = ContextData & {
  phone?: string;
  Phone?: string;
  PhoneNo?: string;
  phoneNumber?: string;
  PhoneNumber?: string;
  phoneNumbers?: string[];
  PhoneNumbers?: string[];
  email?: string;
  Email?: string;
  emails?: string[];
  Emails?: string[];
};

// Helpers

const isContextData = (data: unknown): data is ContextData => typeof data === 'object' && !isArray(data);

export const dataContainsContactInfo = (data: ContextData | object): data is PersonalInfo => {
  const personalInfo = data as PersonalInfo;
  return (
    personalInfo.phone !== undefined ||
    personalInfo.Phone !== undefined ||
    personalInfo.PhoneNo !== undefined ||
    personalInfo.phoneNumber !== undefined ||
    personalInfo.PhoneNumber !== undefined ||
    personalInfo.phoneNumbers !== undefined ||
    personalInfo.PhoneNumbers !== undefined ||
    personalInfo.email !== undefined ||
    personalInfo.Email !== undefined ||
    personalInfo.emails !== undefined ||
    personalInfo.Emails !== undefined
  );
};

// Redaction Methods

const redactPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/.(?=.{4})/g, '*');
const redactEmail = (email: string) =>
  email.replace(/^(.{2}).*?(@.*)$/, (_match, p1, p2) => p1 + '*'.repeat(email.indexOf('@') - 2 - p1.length) + p2);

const redactPersonalInfo = (data: ContextData | object) => {
  const redactedData = { ...data };
  if (dataContainsContactInfo(redactedData)) {
    const {
      phone,
      Phone,
      PhoneNo,
      phoneNumber,
      PhoneNumber,
      phoneNumbers,
      PhoneNumbers,
      email,
      Email,
      emails,
      Emails,
    } = redactedData;
    redactedData.phone = phone && redactPhoneNumber(phone);
    redactedData.Phone = Phone && redactPhoneNumber(Phone);
    redactedData.PhoneNo = PhoneNo && redactPhoneNumber(PhoneNo);
    redactedData.phoneNumber = phoneNumber && redactPhoneNumber(phoneNumber);
    redactedData.PhoneNumber = PhoneNumber && redactPhoneNumber(PhoneNumber);
    redactedData.phoneNumbers = phoneNumbers && phoneNumbers.map(redactPhoneNumber);
    redactedData.PhoneNumbers = PhoneNumbers && PhoneNumbers.map(redactPhoneNumber);
    redactedData.email = email && redactEmail(email);
    redactedData.Email = Email && redactEmail(Email);
    redactedData.emails = emails && emails.map(redactEmail);
    redactedData.Emails = Emails && Emails.map(redactEmail);
  }
  return redactedData;
};

/**
 * Recursively redacts personal information from the data object
 * @param data object to sanitize
 * @returns sanitized data object
 */
export const sanitizeData = (data?: ContextData | object): object | undefined => {
  if (!data) {
    return undefined;
  }

  // Make sure we're only sanitizing a copy of the data being passed in
  let sanitizedData = { ...data };
  try {
    if (isContextData(sanitizedData)) {
      sanitizedData = redactPersonalInfo(sanitizedData);
      const keys = Object.keys(sanitizedData);
      for (const key of keys) {
        const childData = sanitizedData[key];
        // Check if the child data is an object or array and recursively sanitize
        if (childData && isContextData(childData)) {
          sanitizedData[key] = sanitizeData(childData);
        } else if (childData && isArray(childData)) {
          sanitizedData[key] = childData.map((arrayData) => {
            if (isContextData(arrayData)) {
              return sanitizeData(arrayData);
            }
            return arrayData;
          });
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to sanitize personal information from logs', e);
  }

  return sanitizedData;
};
