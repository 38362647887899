import React from 'react';
import { PeripheralType } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { BarcodeScannerIcon } from 'assets/icons/settings/BarcodeScannerIcon';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { ScannerConfigListItem } from './components/ScannerConfigListItem';
import { ScannerSettingsProvider } from './components/ScannerSettingsProvider';
import { ScannerStatusPill } from './components/ScannerStatusPill';
import { ScannerStatusBanner } from './components/ScannerStatusBanner';
import { ScannerSelectListItem } from './components/ScannerSelectListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { ScannerTestListItem } from './components/ScannerTestListItem';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const ScannerSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  return (
    <>
      <SettingsListItem
        automationId='scanner_settings'
        icon={<BarcodeScannerIcon />}
        title={'Barcode scanner'}
        subtitle={'Settings for barcode scanner'}
        onClick={showDrawer}
        actions={[<ScannerStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='scanner_settings-drawer'
        title='Barcode scanner'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <ScannerSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <ScannerStatusBanner />
            <ScannerSelectListItem />
            <SettingsDivider />
            <ScannerConfigListItem />
            <SettingsDivider />
            <ScannerTestListItem />
          </SettingsCard>
          <AddDeviceListItem deviceType={PeripheralType.scanner} />
        </ScannerSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
