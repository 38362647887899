import { createAction } from '@reduxjs/toolkit';

export type StorageType = 'local' | 'session';

export type SetStoragePayload = {
  key: string;
  value: string | null;
  storageType: StorageType;
};

export const setStorage = createAction('setStorage', (payload: SetStoragePayload) => ({ payload }));
