import { createAction } from '@reduxjs/toolkit';
import { PrintJob, PrintPreviewJob, PrintPreviewStatus, PrintStatus } from 'models/Printing';

type PrintStatusPayload = {
  printJob: PrintJob;
  status: PrintStatus;
};

type PrintPreviewStatusPayload = {
  printPreviewJob: PrintPreviewJob;
  status: PrintPreviewStatus;
};

export const resetPrintJobStatus = createAction('resetPrintJobStatus');

export const setPrintJobStatus = createAction('setPrintJobStatus', (payload: PrintStatusPayload) => ({ payload }));

export const resetPrintPreviewStatus = createAction('resetPrintPreviewStatus');

export const setPrintPreviewStatus = createAction('setPrintPreviewStatus', (payload: PrintPreviewStatusPayload) => ({
  payload,
}));
