import React from 'react';

type UnavailableIconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const UnavailableIcon = ({
  width = 12,
  height = 12,
  color = 'currentColor',
}: UnavailableIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6C12 4.81331 11.6481 3.65328 10.9888 2.66658C10.3295 1.67989 9.39246 0.910851 8.2961 0.456725C7.19975 0.00259971 5.99334 -0.11622 4.82946 0.115291C3.66557 0.346802 2.59648 0.918247 1.75736 1.75736C0.918247 2.59648 0.346802 3.66557 0.115291 4.82946C-0.11622 5.99334 0.00259971 7.19974 0.456725 8.2961C0.910851 9.39246 1.67989 10.3295 2.66658 10.9888C3.65328 11.6481 4.81331 12 6 12C7.59055 11.9976 9.11526 11.3646 10.2399 10.2399C11.3646 9.11526 11.9976 7.59055 12 6ZM6 10.1538C5.32649 10.1518 4.66388 9.98373 4.07077 9.66462C4.03865 9.64656 4.01111 9.62134 3.9903 9.59093C3.96949 9.56051 3.95596 9.52572 3.95077 9.48923C3.94594 9.45413 3.94925 9.41838 3.96045 9.38477C3.97166 9.35115 3.99046 9.32057 4.01539 9.29539L9.30462 4.00616C9.33018 3.98179 9.36081 3.96336 9.39432 3.95219C9.42783 3.94102 9.46339 3.93738 9.49846 3.94154C9.53403 3.94589 9.56793 3.95918 9.59698 3.98016C9.62603 4.00115 9.6493 4.02914 9.66462 4.06154C9.98703 4.65669 10.1552 5.32313 10.1538 6C10.1538 6.54549 10.0464 7.08564 9.83765 7.58961C9.6289 8.09358 9.32293 8.55149 8.93721 8.93721C8.55149 9.32293 8.09358 9.6289 7.58961 9.83765C7.08564 10.0464 6.54549 10.1538 6 10.1538ZM1.84616 6C1.83756 5.28067 2.01676 4.57154 2.36605 3.94266C2.71535 3.31377 3.22266 2.78688 3.83787 2.41402C4.45308 2.04116 5.15491 1.83524 5.87405 1.8166C6.59319 1.79795 7.30475 1.96722 7.93846 2.30769C7.97059 2.32575 7.99812 2.35097 8.01893 2.38138C8.03974 2.4118 8.05327 2.44659 8.05846 2.48308C8.0633 2.51818 8.05998 2.55393 8.04878 2.58754C8.03757 2.62116 8.01878 2.65174 7.99385 2.67693L2.70462 7.99385C2.67905 8.01822 2.64842 8.03664 2.61491 8.04781C2.58141 8.05898 2.54585 8.06262 2.51077 8.05846C2.4752 8.05411 2.44131 8.04082 2.41225 8.01984C2.3832 7.99886 2.35993 7.97086 2.34462 7.93846C2.01936 7.34401 1.848 6.67762 1.84616 6Z"
        fill={color}/>
    </svg>
  );
};
