import * as api from 'api/FeeDonationApi';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { SectionWithHeader, Flex } from 'components/layout';
import { AppliedFeeDonation } from 'models/FeeDonation';
import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { callback, callback1 } from 'models/Misc';
import { colors } from 'css/Theme';
import { CashAmount, P3 } from 'components/text';
import { logger } from 'util/logger';
import { warningNotification } from 'store/actions/NotificationsActions';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

const StyledSectionWithHeader = styled(SectionWithHeader)`
  height: 25%;
  width: 100%;
`;

const Container = styled.div<{ loading: boolean }>`
  cursor: ${({ loading }) => (loading ? 'progress' : 'default')};
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 6px;
  height: 100%;
  color: ${colors.dutchie.mediumGrey};
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Delete = styled.div`
  width: 1rem;
  color: ${colors.dutchie.mediumGrey};
  cursor: pointer;
  display: flex;

  & > svg {
    width: 1rem;
    height: 1rem;
  }
`;

type AppliedFeesDonationsProps = {
  appliedFeesDonation: Array<AppliedFeeDonation>;
  loadApplied: callback;
  loading: boolean;
  setLoading: callback1<boolean>;
};

export const AppliedFeesDonations: FC<AppliedFeesDonationsProps> = (props) => {
  const { appliedFeesDonation, loadApplied, loading, setLoading } = props;
  const dispatch = useDispatch();

  const { shipmentId } = useTransactionManager();

  const removeDiscount = (AppliedFeeDonationId: number) => {
    if (!loading) {
      setLoading(true);
      api
        .removeAppliedFeeDonation(AppliedFeeDonationId, shipmentId)
        .finally(() => {
          setLoading(false);
          loadApplied();
        })
        .catch((e) => {
          const message = 'Unable to remove applied fee or donation';
          logger.error(e, { message });
          dispatch(warningNotification(e ? e : 'There was an error adding the item to the cart'));
        });
    }
  };

  const sectionTitle = useMemo(() => {
    if (appliedFeesDonation.length === 0) {
      return 'Apply Fees & Donations';
    } else {
      return 'Fees & Donations Already Applied';
    }
  }, [appliedFeesDonation]);

  return (
    <StyledSectionWithHeader title={sectionTitle}>
      <Container loading={loading}>
        {appliedFeesDonation.map((item) => (
          <Item key={item.FeeDonationId}>
            <Flex>
              <P3 data-testid='applied-fees-donations_item-label_name'>
                ({item.FeeDonationId}) {item.Name} - {item.CalculationMethod}
              </P3>
              <P3 data-testid='applied-fees-donations_item-price_calculated-value' style={{ marginLeft: 4 }}>
                <CashAmount value={item.CashValue} />
              </P3>
            </Flex>
            {!item.IsAutomatic && (
              <Delete
                onClick={() => removeDiscount(item.AppliedFeeDonationId)}
                data-testid='applied-fees-donations_delete_delete-icon'
              >
                <DeleteIcon />
              </Delete>
            )}
          </Item>
        ))}
      </Container>
    </StyledSectionWithHeader>
  );
};
