import React, { useState } from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';

import { ScannerTestBarcodeTypesModal } from './setup/ScannerTestBarcodeTypesModal';

export const ScannerTestListItem = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SettingsListItem
        title={'Test scanner'}
        subtitle={'Test scanner functionality'}
        onClick={() => setShowModal(true)}
      />
      {showModal && <ScannerTestBarcodeTypesModal hide={() => setShowModal(false)} />}
    </>
  );
};
