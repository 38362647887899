import { useTrackedContext } from 'util/hooks';
import { TransactionsPopupsState, emptyTransactionsPopupsState } from './TransactionsPopupsState';
import { TransactionsPopupsContext, TransactionsPopupsContextProps } from './ProvideTransactionsPopups';
import { ProductHistoryOrder } from 'models/Customer';
import { Transaction } from 'models/Transactions';
import { Reason } from 'models/Settings';
import { callback1 } from 'models/Misc';
import { Delivery } from 'models/Delivery';

type TransactionPopups = {
  showChangePaymentTypePopup: (selectedTransaction?: Transaction | ProductHistoryOrder) => void;
  showManagerPinPopup: (
    onSuccess: callback1<number>,
    title: string,
    reasons?: Array<Reason>,
    selectedReason?: string,
    setSelectedReason?: callback1<string>
  ) => void;
  showTransactionPopup: (selectedTransaction?: Transaction | ProductHistoryOrder | Delivery) => void;
  showEmailPopup: (onEmail: callback1<string>) => void;
  transactionsPopupsState: TransactionsPopupsState;
  showVoidTransactionPopup: (shipmentId: number, reloadTransactions: () => void) => void;
};

const createPopupHandlers = ([
  transactionsPopupsState,
  setTransactionsPopupsState,
]: TransactionsPopupsContextProps): TransactionPopups => {
  const showPopup = (props: Partial<TransactionsPopupsState>) =>
    setTransactionsPopupsState({ ...emptyTransactionsPopupsState, ...props });
  return {
    showChangePaymentTypePopup: (selectedTransaction?: Transaction | ProductHistoryOrder) =>
      showPopup({ changePaymentTypePopup: { selectedTransaction } }),
    showManagerPinPopup: (
      onSuccess: callback1<number>,
      title: string,
      reasons?: Array<Reason>,
      selectedReason?: string,
      setSelectedReason?: callback1<string>
    ) => showPopup({ managerPinPopup: { onSuccess, title, reasons, selectedReason, setSelectedReason } }),
    showTransactionPopup: (selectedTransaction?: Transaction | ProductHistoryOrder | Delivery) => {
      showPopup({ transactionPopup: { selectedTransaction } });
    },
    showEmailPopup: (onEmail: callback1<string>) => showPopup({ emailPopup: { onEmail } }),
    showVoidTransactionPopup: (shipmentId, reloadTransactions) =>
      showPopup({ voidTransactionPopup: { shipmentId, reloadTransactions } }),
    transactionsPopupsState,
  };
};

export const useTransactionsPopups = () =>
  useTrackedContext<TransactionsPopupsState, TransactionPopups>(TransactionsPopupsContext, createPopupHandlers);
