import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import type { UseQueryOptions } from '@tanstack/react-query';

export type AnalyticsQueryPayload = {
  customerId: number;
};

export type RptCustomerRow = {
  _storage: string | null;
  lsp_id: number;
  loc_id: number;
  customer_id: number;
  customer_type_id: number | null;
  customer_state: string | null;
  is_out_of_state_customer: boolean;
  date_of_birth: string | null; // ISO 8601
  last_birthday_occurrence: string | null; // ISO 8601
  next_birthday_occurrence: string | null; // ISO 8601
  age: number | null;
  gender: string | null;
  first_order_date_local: string | null; // ISO 8601
  last_order_date_local: string | null; // ISO 8601
  zip_code: string | null;
  medid_expiration_date: string | null; // ISO 8601
  top_category_id: number | null;
  total_orders: number;
  total_spend_last_7: number;
  total_spend_last_30: number;
  total_spend_last_90: number;
  total_spend_last_365: number;
  total_spend_lifetime: number;
  avg_spend_last_7: number;
  avg_spend_last_30: number;
  avg_spend_last_90: number;
  avg_spend_last_365: number;
  avg_spend_lifetime: number;
  discount_transactions_last_7: number;
  discount_transactions_last_30: number;
  discount_transactions_last_90: number;
  discount_transactions_last_365: number;
  discount_transactions_lifetime: number;
  spend_percentile_rank_last_7: number | null;
  spend_percentile_rank_last_30: number | null;
  spend_percentile_rank_last_90: number | null;
  spend_percentile_rank_last_365: number | null;
  spend_percentile_rank_lifetime: number | null;
  avg_spend_percentile_rank_last_7: number | null;
  avg_spend_percentile_rank_last_30: number | null;
  avg_spend_percentile_rank_last_90: number | null;
  avg_spend_percentile_rank_last_365: number | null;
  avg_spend_percentile_rank_lifetime: number | null;
};

export type AnalyticsQueryResponse = RptCustomerRow | null;

export const useAnalyticsQuery = (payload: AnalyticsQueryPayload, options?: Partial<Omit<UseQueryOptions<AnalyticsQueryResponse>, 'queryFn'>>) => {
  const { customerId } = payload;

  return useQuery({
    queryKey: ['analytics', customerId],
    queryFn: async () => {
      const response = await post<AnalyticsQueryResponse>(`personalization/${customerId}/analytics`);

      return response;
    },
    ...options,
  });
};
