import type { ProductSearchResult } from '../../product/types';
import { cloneDeep } from 'lodash';

type UpdateProductQuantitiesPayload = {
  quantityChanged: number;
  serialNumber: string;
  productList?: ProductSearchResult[];
};

/**
 * Helper function to avoid refetching the product list for quantity updates
 */
export const updateProductQuantities = ({
  quantityChanged,
  serialNumber,
  productList,
}: UpdateProductQuantitiesPayload) => {
  // If product list is empty or locally cache flag is off, return null to trigger a refetch
  if (productList === undefined) {
    return null;
  }

  const indexToUpdate = productList.findIndex((product) => product.serialNo === serialNumber);

  if (indexToUpdate === -1) {
    return null;
  }

  const foundItemToUpdate = productList[indexToUpdate];

  if (!foundItemToUpdate) {
    return null;
  }

  const isWeightedProduct = foundItemToUpdate.productType === 'Wgt';
  const fieldToUpdate = isWeightedProduct ? `totalGrams` : `totalAvailable`;

  const oldTotal = foundItemToUpdate[fieldToUpdate];
  const newTotal = oldTotal - quantityChanged;

  const editedList = cloneDeep(productList);
  editedList[indexToUpdate][fieldToUpdate] = newTotal;

  return editedList;
};
