import { post } from 'api/HttpHelpers';
import {
    AppliedDiscount,
    ApplyDiscountRequest,
    RemoveAllAppliedDiscountsRequest,
    RemoveAppliedDiscountRequest,
} from 'models/Discounts';
import { logger, customEventKeys } from 'util/logger';

export const getAppliedDiscounts = (Register?: number, InvId?: number) => {
  return post<Array<AppliedDiscount>>('v2/discount/get_discounts_applied', { Register, InvId });
};

export const applyDiscount = (body: ApplyDiscountRequest) => {
  logger.debug<{ body: ApplyDiscountRequest }>('applyDiscount', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('v2/discount/apply_selected_discount', body);
};

export const applyDiscountCode = (body: ApplyDiscountRequest) => {
  logger.debug<{ body: ApplyDiscountRequest }>('applyDiscountCode', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('v2/discount/apply-by-code', body);
};

export const removeAppliedDiscount = (body: RemoveAppliedDiscountRequest) => {
  logger.debug<{ body: RemoveAppliedDiscountRequest }>('removeAppliedDiscount', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('v2/discount/delete_selected_discount', body);
};

export const removeAllAppliedDiscounts = (body: RemoveAllAppliedDiscountsRequest) => {
  logger.debug<{ body: RemoveAllAppliedDiscountsRequest }>('removeAllAppliedDiscounts', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('v2/discount/remove-discounts', body);
};
