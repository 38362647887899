import React, { FC } from 'react';
import { CashAmount } from 'components/text';

import {
  CFDGlobalStyles,
  ThankYouContainer,
  ConfettiBackground,
  ThankYouTitle,
  ThankYouSubtitle,
  ChangeDueContainer,
  ChangeDueAmount,
  ChangeDueLabel,
} from '../CFD.styles';

type ThankYouScreenProps = {
  show?: boolean;
  locationName?: string;
  changeDue: number;
};

export const ThankYouScreen: FC<ThankYouScreenProps> = ({ show, locationName, changeDue }) => {
  return (
    <ThankYouContainer show={show} data-testid='thank-you-screen'>
      <CFDGlobalStyles />
      <ConfettiBackground />
      <ChangeDueContainer data-testid='thank-you-screen_change-due-container'>
        <ChangeDueLabel>Your change</ChangeDueLabel>
        <ChangeDueAmount>
          <CashAmount value={changeDue} automationId='thank-you-screen_change-due-container_amount' />
        </ChangeDueAmount>
      </ChangeDueContainer>
      <ThankYouTitle>Thank you</ThankYouTitle>
      <ThankYouSubtitle>for shopping {locationName ? `at ${locationName}` : 'with us'}!</ThankYouSubtitle>
    </ThankYouContainer>
  );
};
