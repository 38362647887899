import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ProductHistoryOrder } from 'models/Customer';
import { Delivery } from 'models/Delivery';
import { Transaction } from 'models/Transactions';
import { callback1 } from 'models/Misc';
import {
  showBypassStateSystemPopup,
  showConfigureDeliveriesPopup,
  showConfirmTransactionPopup,
  showDeliverySettingsPopup,
  showTransactionPopup,
} from 'store/actions/PopupsActions';
import { PopupsState } from '.';

import type { DeliveryOrderProps } from 'components/sharedPopups/DeliverySettingsPopup';

export type DeliveryPopupsPayloads = {
  showTransactionPopup: { selectedTransaction?: Transaction | ProductHistoryOrder | Delivery };
  showConfigureDeliveriesPopup: { createRoute: boolean };
  showDeliverySettingsPopup: { delivery: DeliveryOrderProps; onSave?: () => void };
  showConfirmTransactionPopup: { message: string; onConfirm: callback1<string>; pinRequired: boolean; title: string };
  showBypassStateSystemPopup: { onSuccess: callback1<string>; title?: string };
};

export type DeliveryPopupsState = {
  transactionPopup: { selectedTransaction?: Transaction | ProductHistoryOrder | Delivery } | null;
  configureDeliveriesPopup: { createRoute: boolean } | null;
  deliverySettingsPopup: { delivery: DeliveryOrderProps; onSave?: () => void } | null;
  confirmTransactionPopup: {
    message: string;
    onConfirm: callback1<string>;
    pinRequired: boolean;
    title: string;
  } | null;
  bypassStateSystemPopup: { onSuccess: callback1<string>; title?: string } | null;
};

export const deliveryPopupsInitialState: DeliveryPopupsState = {
  transactionPopup: null,
  configureDeliveriesPopup: null,
  deliverySettingsPopup: null,
  confirmTransactionPopup: null,
  bypassStateSystemPopup: null,
};

export const buildDeliveryPopupsCases = (builder: ActionReducerMapBuilder<PopupsState>, initialState: PopupsState) => {
  builder.addCase(showTransactionPopup, (_state, action) => ({
    ...initialState,
    transactionPopup: action.payload,
  }));
  builder.addCase(showConfigureDeliveriesPopup, (_state, action) => ({
    ...initialState,
    configureDeliveriesPopup: action.payload,
  }));
  builder.addCase(showDeliverySettingsPopup, (_state, action) => ({
    ...initialState,
    deliverySettingsPopup: action.payload,
  }));
  builder.addCase(showConfirmTransactionPopup, (_state, action) => ({
    ...initialState,
    confirmTransactionPopup: action.payload,
  }));
  builder.addCase(showBypassStateSystemPopup, (_state, action) => ({
    ...initialState,
    bypassStateSystemPopup: action.payload,
  }));
};
