import React, { createContext } from 'react';
import { usePopupsState } from 'util/hooks/usePopupsState';
import { TransactionsPopups } from './TransactionsPopups';
import { emptyTransactionsPopupsState, TransactionsPopupsState } from './TransactionsPopupsState';

export type TransactionsPopupsContextProps = [
  TransactionsPopupsState,
  (transactionsPopupsState: TransactionsPopupsState) => void
];
export const TransactionsPopupsContext = createContext<TransactionsPopupsContextProps | null>(null);

export const ProvideTransactionsPopups: React.FC = ({ children }) => {
  const { stateTuple, closePopup } = usePopupsState<TransactionsPopupsState>(emptyTransactionsPopupsState);
  const [state] = stateTuple;

  return (
    <TransactionsPopupsContext.Provider value={stateTuple}>
      <TransactionsPopups transactionsPopupsState={state} closePopup={closePopup} />
      {children}
    </TransactionsPopupsContext.Provider>
  );
};
