import React, { FC } from 'react';
import { CartItem } from 'models/Cart';
import { AppliedDiscount } from 'models/Discounts';
import { round } from 'lodash';

import {
  CartItemContainer,
  CartItemRow,
  BrandLabel,
  ItemInfo,
  ItemQuantity,
  ItemCost,
  StrikeThrough,
  ItemDiscounts,
  DiscountRow,
  DiscountLabel,
  DiscountIcon,
  DiscountAmount,
} from '../../CFD.styles';
import { CashAmount } from 'components/text';
import { useApplicableItemDiscounts, useRolledUpDiscounts, useTotalDiscountAmount } from 'util/hooks/cart';
import { sanitizeDiscountReason } from 'util/helpers/cart/sanitizeDiscountReason';

type CartLineItemProps = {
  cartItem: CartItem;
  discounts: AppliedDiscount[];
  automationId?: string;
};

export const CartLineItem: FC<CartLineItemProps> = ({ cartItem, discounts, automationId }) => {
  const itemDiscounts = useApplicableItemDiscounts(cartItem, discounts);
  const rolledUpDiscounts = useRolledUpDiscounts(itemDiscounts);
  const totalItemDiscount = useTotalDiscountAmount(rolledUpDiscounts);
  const adjustedPrice = cartItem.TotalCost - (totalItemDiscount ?? 0);
  const hasDiscounts = (totalItemDiscount ?? 0) > 0;

  return (
    <CartItemContainer data-testid={automationId ?? 'cart-item-container'}>
      <CartItemRow>
        <ItemInfo data-testid='cart-item_item-info'>
          {cartItem.BrandName && (
            <BrandLabel data-testid='cart-item_item-info_brand-label'>{cartItem.BrandName}</BrandLabel>
          )}
          {cartItem.Product}
        </ItemInfo>
        <ItemQuantity data-testid='cart-item_item-quantity'>
          {cartItem.WgtCnt === 'Qty' ? cartItem.QtySelected : `${cartItem.Grams}g`}
        </ItemQuantity>
        <ItemCost>
          <CashAmount automationId='cart-item_final-adjusted-price' value={adjustedPrice} />
          {hasDiscounts && (
            <StrikeThrough>
              <CashAmount automationId='cart-item_price-before-discounts' value={cartItem.TotalCost} />
            </StrikeThrough>
          )}
        </ItemCost>
      </CartItemRow>
      {hasDiscounts && (
        <ItemDiscounts data-testid='cart-item_discounts-container'>
          {rolledUpDiscounts.map((discount) => (
            <DiscountRow key={discount.DiscountId} data-testid='cart-item_discount-row'>
              <DiscountIcon />
              <DiscountLabel>Discount applied</DiscountLabel>
              {sanitizeDiscountReason(discount.DiscountReason)}
              <DiscountAmount data-testid='cart-item_discount-row_amount-label'>
                Discount: <b>{round((discount.DiscountAmt / cartItem.TotalCost) * 100, 2)}% off</b>
              </DiscountAmount>
            </DiscountRow>
          ))}
        </ItemDiscounts>
      )}
    </CartItemContainer>
  );
};
