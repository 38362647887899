import React, { FC } from 'react';
import styled from 'styled-components';

import { LoyaltyRedemption } from 'models/Fyllo';
import { ReactComponent as Trash } from 'assets/icons/icon-trash.svg';
import { callback } from 'models/Misc';

const AppliedRewardCard = styled.div`
  background: #ebebeb;
  padding: 5px;

  &:nth-child(odd) {
    background: white;
  }
`;

const DeleteIcon = styled(Trash)`
  padding-top: 5px;
  vertical-align: text-bottom;

  &:hover {
    cursor: pointer;
    color: red;
  }
`;

type FylloAppliedRewardCardProps = {
  reward: LoyaltyRedemption;
  onRemove: callback;
};

export const FylloAppliedRewardCard: FC<FylloAppliedRewardCardProps> = ({ reward, onRemove }) => {
  return (
    <AppliedRewardCard>
      <div>
        <span>{reward.RedemptionName}</span> - <span>{reward.LoyaltyPointCost} points</span>
        <DeleteIcon onClick={onRemove}></DeleteIcon>
      </div>
    </AppliedRewardCard>
  );
};
