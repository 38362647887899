import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/inputs/Checkbox';
import { useRowSelect } from '../util/rowSelect';
import { useTableStore } from '../util/useTableStore';
import { SortControls } from './SortControls';

export const TableHead: React.FC = () => {
  const store = useTableStore();
  const rowSelect = useRowSelect();
  return (
    <>
      {rowSelect.isEnabled && <col style={{ width: '16px' }} />}
      <colgroup>
        {store.props.columns.map(({ width }, columnIndex) => (
          <col key={columnIndex} style={{ width }} />
        ))}
      </colgroup>
      <thead data-testid='tablehead_thead_header'>
        <tr data-testid='tablehead_tr_row'>
          {rowSelect.isEnabled && (
            <th data-testid='tablehead_th_column'>
              <StyledCheckbox
                data-testid='tablehead_checkbox_checkbox'
                state={rowSelect.selectionState}
                onClick={() => rowSelect.toggleSelectAllState()}
              />
            </th>
          )}
          {store.props.columns.map(({ header, disableSort }, columnIndex) => (
            <th data-testid='tablehead_th_column-sort' key={columnIndex}>
              {disableSort ? (
                header
              ) : (
                <SortControls data-testid='tablehead_sort-control_column' columnIndex={columnIndex}>
                  {header}
                </SortControls>
              )}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  & > svg {
    width: 1rem;
    height: 1rem;
  }
`;
