import React, { useMemo, useState } from 'react';
import { Input } from 'components/inputs';
import { Box } from 'components/layout';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { setApprovalCode, setManagerPin } from 'store/actions/CheckoutActions';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { LoadingButton } from 'components/buttons';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { uploadId } from 'api/CheckoutApi';
import { useOnCheckout } from './useOnCheckout';
import imageCompression from 'browser-image-compression';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';
import { useTotalDetailsSection } from './TotalDetailsSection/useTotalDetailsSection';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

type TotalPaymentButtonProps = {
  imgRef: React.RefObject<HTMLInputElement>;
  dutchiePayInStore?: boolean;
};

export const TotalPaymentButton = ({ imgRef, dutchiePayInStore }: TotalPaymentButtonProps) => {
  const settings = useSelector((state: State) => state.settings);
  const checkout = useSelector((state: State) => state.checkout);
  const guest = useSelector((state: State) => state.customer.details);
  const isUseMnEnabled = useIsUseMnEnabled(guest);

  const [attemptingCheckout, setAttemptingCheckout] = useState(false);
  const cartLoading = useSelector((state: State) => state.cart.loading);
  const cartItemsLoading = useSelector((state: State) => state.cartItems.loading);
  const isAnyItemLoading = Object.values(cartItemsLoading).some(Boolean);
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);

  const dispatch = useDispatch();
  const { onCheckout, dlPhotoRequired, signatureRequired, setDlSaved } = useOnCheckout({ imgRef });
  const { dutchiePayPreAuthError, showDutchiePayPreAuthCheckout } = useDutchiePayPreAuth();
  const { totalPaid } = useTotalDetailsSection();

  const { data: cart } = useGetCartDetails();

  const hasCannabis = cart.Cart.filter((x) => x.CannbisProduct?.toLowerCase() === 'yes').length > 0;
  const showApprovalCode = isUseMnEnabled && hasCannabis;
  const cartNeedsVerification = settings.features.PharmacistVerification && !cart.VerifiedBy;

  const buttonLoading = useMemo(() => {
    return attemptingCheckout || checkout.loading || cartLoading || digitalPaymentLoading || isAnyItemLoading;
  }, [attemptingCheckout, checkout.loading, cartLoading, digitalPaymentLoading, isAnyItemLoading]);

  const buttonDisabled = useMemo(() => {
    // if we have a dutchiePay pre-auth payment but pre-auth checkout has failed, we have to calculate our paid amount differently
    // checkout.totalRemaning will include the pre-auth payment and be off in this case
    const orderIsPaidFor = dutchiePayPreAuthError ? totalPaid < checkout.totalDue : checkout.totalRemaning > 0;
    return orderIsPaidFor && !cartNeedsVerification;
  }, [dutchiePayPreAuthError, totalPaid, checkout.totalDue, checkout.totalRemaning, cartNeedsVerification]);

  if (!guest) {
    return null;
  }

  const onChangeApprovalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setApprovalCode(e.target.value));
  };

  const onChangeManagerPin = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setManagerPin(e.target.value));
  };

  const onImageUpload = () => {
    const [file] = imgRef?.current?.files || [];

    if (!file) {
      return;
    }

    if (file && file['type'].split('/')[0] !== 'image') {
      dispatch(errorNotification('Please upload an image file only'));
      return;
    }

    const reader = new FileReader();

    reader.onload = async (upload: ProgressEvent<FileReader>) => {
      const result = reader.result as string;

      if (result.split(',')[0].indexOf('base64') >= 0) {
        const [, byteString] = result.split(',');

        try {
          await uploadId({ ShipmentId: cart.ShipmentId, Image: byteString });
          dispatch(successNotification(`Driver's License Image saved`));
          setDlSaved(true);
        } catch (e) {
          dispatch(errorNotification(`Failed to save Driver's License: ${e}`));
        }
      }
    };

    imageCompression(file, {
      maxSizeMB: 4,
    })
      .then((compressedFile) => {
        reader.readAsDataURL(compressedFile);
      })
      .catch((error) => {
        dispatch(errorNotification(error));
      });
  };

  const hasPreAuth = cart.PreauthInfo.PreauthAmount > 0;

  const getCheckoutText = () => {
    if (cartNeedsVerification) {
      return 'Verify Cart';
    }

    if (signatureRequired()) {
      return 'Customer Signature';
    }

    if (dlPhotoRequired()) {
      return "Take Driver's License Image";
    }

    if (dutchiePayInStore && cart.PreauthInfo.PreauthAmount > 0) {
      return 'Complete';
    }

    if (showDutchiePayPreAuthCheckout || hasPreAuth) {
      return 'Complete order';
    }

    return 'Pay';
  };

  return (
    <>
      {showApprovalCode && (
        <Box marginBottom='14px'>
          <Input
            hasError={checkout.invalidApprovalCode}
            placeholder='Approval Code'
            value={checkout.approvalCode}
            onChange={onChangeApprovalCode}
          />
          {checkout.invalidApprovalCode && <ErrorMessage>Invalid Approval Code</ErrorMessage>}
        </Box>
      )}

      {settings.features.CheckOutPinRequired && (
        <Box marginBottom='14px'>
          <Input
            hasError={checkout.invalidManagerPin}
            placeholder='Employee PIN'
            type='password'
            value={checkout.managerPin}
            onChange={onChangeManagerPin}
          />
          {checkout.invalidManagerPin && <ErrorMessage>Wrong Pin</ErrorMessage>}
        </Box>
      )}

      <input
        style={{ display: 'none' }}
        accept='image/*'
        type='file'
        capture='environment'
        ref={imgRef}
        onChange={onImageUpload}
      />

      <LoadingButton
        automationId='check-out-side-bar_checkout-button_pay'
        cta
        disabled={buttonDisabled}
        dutchiePayThemed={dutchiePayInStore || showDutchiePayPreAuthCheckout}
        loading={buttonLoading}
        onClick={async () => {
          setAttemptingCheckout(true);
          await onCheckout();
          setAttemptingCheckout(false);
        }}
      >
        {getCheckoutText()}
      </LoadingButton>
    </>
  );
};

const ErrorMessage = styled.span`
  color: ${colors.dutchie.red};
  font-size: 14px;
  margin-top: 4px;
`;
