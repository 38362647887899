import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export interface NavigateOptions {
  replace?: boolean;
  state?: unknown;
}

export type URLSearchParamsInit = string | string[][] | Record<string, string> | URLSearchParams;
export type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions
) => void;

export const useSearchParams = (init?: URLSearchParamsInit): [URLSearchParams, SetURLSearchParams] => {
  const history = useHistory();

  const setSearchParams = useCallback(
    (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions
    ) => {
      if (!nextInit && nextInit !== '') {
        return;
      }

      const currentParams = new URLSearchParams(history?.location.search ?? window.location.search);

      const nextParamsInit = typeof nextInit === 'function' ? nextInit(currentParams) : nextInit;
      const nextParams = new URLSearchParams(nextParamsInit);

      // Update the URL with the new query params
      if (navigateOpts?.replace) {
        history.replace({ search: nextParams.toString(), state: navigateOpts?.state });
      } else {
        history.push({ search: nextParams.toString(), state: navigateOpts?.state });
      }
    },
    [history]
  );

  return [new URLSearchParams(history?.location.search ?? window.location.search), setSearchParams] as const;
};
