// TODO: ENG-14667 - Refactor this to be in the /popups and used by the cart and deliveries popup contexts
import React, { FC } from 'react';

import { DutchieManagerSection } from './DutchieManagerSection';
import { Popup } from 'components/popups';
import { callback, callback1 } from 'models/Misc';

type BypassStateSystemPopupProps = {
  hide: callback;
  onSuccess: callback1<string>;
  title?: string;
};

export const BypassStateSystemPopup: FC<BypassStateSystemPopupProps> = ({ hide, onSuccess, title = 'Void' }) => (
  <Popup small caption='Enter manager PIN' isVisible hide={hide}>
    <DutchieManagerSection
      title={title}
      needPin
      onSuccess={(ApprovingManagerUserId, managerPin) => {
        onSuccess(managerPin);
        hide();
      }}
    />
  </Popup>
);
