import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { CartItem } from 'models/Cart';
import { callback1 } from 'models/Misc';
import { useCartPopups } from 'components/CartPopups';
import { showRemoveCartItemPopup } from 'store/actions/PopupsActions';

import type { OverflowMenuOption } from 'components/menus/OverflowMenu';
import { useAppSelector } from 'util/hooks';
import { useGetCartDetails } from '../hooks/useGetCartDetails';

type CartItemOverflowProps = {
  item: CartItem;
  onRemoveItem?: callback1<CartItem>;
  onPrintOneLabel?: callback1<CartItem>;
  onPrintAllLabels?: callback1<CartItem>;
  onDownloadAllLabels?: () => void;
};

export const useCartItemOverflowOptions = ({
  item,
  onRemoveItem,
  onPrintOneLabel,
  onPrintAllLabels,
  onDownloadAllLabels,
}: CartItemOverflowProps): OverflowMenuOption[] => {
  const dispatch = useDispatch();

  const integrations = useSelector((state: State) => state.settings.integrations);
  const isStateFlorida = useSelector((state: State) => state.settings.locationSettings?.State) === 'FL';

  const {
    data: { PreOrders: preorderItems },
  } = useGetCartDetails();

  const cartPopups = useCartPopups();
  const moveExternalLoyaltyButtonsToCartLevelMenu = useAppSelector(
    (state) => state.settings.features.LoyaltyAsCartDiscount
  );

  const divider: OverflowMenuOption = { divider: true };

  const edit: OverflowMenuOption = { text: 'Edit', onClick: () => cartPopups.showEditProductPopup(item) };
  const applyDiscount: OverflowMenuOption = {
    text: 'Apply discount',
    onClick: () => cartPopups.showDiscountPopup(item),
  };
  const printOneLabel: OverflowMenuOption = {
    text: 'Print one label',
    onClick: () => onPrintOneLabel && onPrintOneLabel(item),
  };
  const printLabels: OverflowMenuOption = {
    text: 'Print labels',
    onClick: () => onPrintAllLabels && onPrintAllLabels(item),
  };
  const downloadLabels: OverflowMenuOption = {
    text: 'Download labels',
    onClick: () => onDownloadAllLabels && onDownloadAllLabels(),
  };
  const reroute: OverflowMenuOption = {
    text: 'Re-Route',
    onClick: () => cartPopups.showRerouteProductPopup(item),
  };
  const editDevice: OverflowMenuOption = {
    text: 'Edit device',
    onClick: () => cartPopups.showEditDevicePopup(item),
  };
  const redeemSB: OverflowMenuOption = {
    text: 'Redeem SB',
    onClick: () => cartPopups.showSpringBigRedeemPopup({ item }),
  };
  const redeemAIQ: OverflowMenuOption = {
    text: 'Redeem AIQ',
    onClick: () => cartPopups.showAlpineIQRedeemPopup({ item }),
  };
  const redeemFyllo: OverflowMenuOption = {
    text: 'Redeem Fyllo',
    onClick: () => cartPopups.showFylloRedeemPopup({ item }),
  };

  const handleRemoveItem = () => {
    if (onRemoveItem) {
      const itemIsInPreorder = preorderItems.some((preorder) => preorder.ProductId === item.ProductId);
      if (itemIsInPreorder) {
        onRemoveItem(item);
      } else {
        dispatch(showRemoveCartItemPopup({ item, onRemoveItem: () => onRemoveItem(item) }));
      }
    }
  };

  // Only show menu item with red text and divider if LD flag is on
  const removeItem: OverflowMenuOption = {
    text: 'Remove item',
    danger: true,
    onClick: handleRemoveItem,
  };

  // Return menu items depending on LD flag
  const actions = [applyDiscount, divider, printOneLabel, printLabels, downloadLabels, divider];

  if (integrations?.UseMMUR) {
    if (item.CannbisProduct === 'Yes') {
      actions.push(reroute);
    } else {
      actions.push(editDevice);
    }
  } else if (integrations?.UseBioTrack && isStateFlorida) {
    if (item.CannbisProduct === 'Yes') {
      actions.push(reroute);
    }
  }

  if (!moveExternalLoyaltyButtonsToCartLevelMenu) {
    if (integrations?.UseSpringBig) {
      actions.push(redeemSB);
    }

    if (integrations?.UseAlpineIQ) {
      actions.push(redeemAIQ);
    }

    if (integrations?.UseFyllo) {
      actions.push(redeemFyllo);
    }
  }

  actions.push(edit, divider);

  if (onRemoveItem) {
    actions.push(removeItem);
  }

  return actions;
};
