import React, { FC, useState } from 'react';
import { callback } from 'models/Misc';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { ConfirmationPopup } from 'components/popups';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import styled from 'styled-components';

const InputBox = styled.div`
  padding: 0 1.5rem;
  &:not(:first-child) {
    padding-top: 1.5rem;
  }
`;

type DutchiePayPopupProps = {
  hide: callback;
  shipmentId?: number;
};

export const DutchiePayPopup: FC<DutchiePayPopupProps> = ({ hide, shipmentId }) => {
  const dispatch = useDispatch();
  const { details } = useSelector((state: State) => state.customer);

  const [phone, setPhone] = useState<string>(details?.CellPhone || details?.PhoneNo || '');

  const sendText = async () => {
    try {
      if (shipmentId && phone) {
        // Send Dutchie Pay Text
        dispatch(successNotification('Text Sent'));
        hide();
      }
    } catch {
      dispatch(errorNotification('Text failed to send'));
    }
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Dutchie Pay'
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <InputBox>
        <Input
          placeholder='Cell Phone #'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          endAdornment={
            <Button isAdornment disabled={phone === ''} onClick={sendText}>
              Send Text
            </Button>
          }
        />
      </InputBox>
    </ConfirmationPopup>
  );
};
