import React from 'react';
import { EmbeddedParentTableRow } from './EmbeddedParentTableRow';
import { StyledParentTable } from './Styles';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';

export function EmbeddedParentTable<ParentItem, ChildItem>() {
  const { parentItemMap, parentColumns, parentActions } = useEmbeddedTableStore();

  return (
    <StyledParentTable>
      <colgroup>
        <col width='36px' />
        {parentColumns.map(({ width, style }, i) => (
          <col key={`parent-column-${i}`} width={width} style={style} />
        ))}
        {parentActions && <col width='48px' />}
      </colgroup>
      <thead className='parent'>
        <tr className='parent'>
          <th className='parent expansion-indicator'>&nbsp;{/* for expansion indicator */}</th>
          {parentColumns.map(({ header, style }, i) => (
            <th className='parent' key={`parent-header-${i}`} style={style}>
              {header}
            </th>
          ))}
          {parentActions && <th className='parent action-menu'>&nbsp;{/* for action menu */}</th>}
        </tr>
      </thead>
      <tbody className='parent'>
        {Object.keys(parentItemMap).map((id) => (
          <EmbeddedParentTableRow<ParentItem, ChildItem> id={id} key={id} />
        ))}
      </tbody>
    </StyledParentTable>
  );
}
