import React from 'react';
import { useCfd } from '../hooks/useCfd';
import {
  PeripheralSetupConnectionStatus,
  ConnectionStatusBanner,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { useCfdSettings } from '../hooks/useCfdSettings';

export const CfdStatusBanner = () => {
  const { isConnected } = useCfd();
  const { preferredScreenName } = useCfdSettings();

  const status = isConnected ? PeripheralSetupConnectionStatus.connected : PeripheralSetupConnectionStatus.unavailable;
  return <ConnectionStatusBanner deviceTypeName='Cfd' deviceName={preferredScreenName} status={status} />;
};
