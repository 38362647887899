import React from 'react';
import { useAssignedRegisterSettings } from '../hooks/useAssignRegisterSettings';
import { SettingsSelectOption, SettingsSelect } from 'pages/SettingsPage/Select/SettingsSelect';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';

export const AssignRegisterSelectListItem = () => {
  const { assignedRegisterId, options, refreshRegisters, setAssignedRegisterId } = useAssignedRegisterSettings();

  const handleChange = (option: SettingsSelectOption | undefined): void => {
    try {
      if (!option) {
        return;
      }

      const id = parseInt(option.value);
      setAssignedRegisterId(id);
    } catch (e) {
      /* no-op */
    }
  };

  return (
    <SettingsListItem
      title='Assign register'
      subtitle='Select a default register for this device'
      actions={[
        <SettingsSelect
          value={assignedRegisterId?.toString()}
          options={options}
          onChange={handleChange}
          onRefresh={async () => refreshRegisters()}
          placeholder='Select register'
        />,
      ]}
    />
  );
};
