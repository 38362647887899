/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeliveryPopups } from 'components/DeliveryPopups';
import { BottomDrawer } from 'components/layout';
import { DeliveryPageFilterMenu } from 'components/menus/FilterMenu/FilterMenu';
import { breakpoints, colors } from 'css/Theme';
import { DeliveryFilterOption } from 'models/Delivery';
import { ConfirmTransactionPopup } from 'pages/RegisterTransactionsPage/ConfirmTransactionPopup';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { State } from 'store';
import {
  applyDeliveryFilters,
  cancelRoute,
  getDeliveries,
  getInitialDeliveries,
  selectRoute,
  setRouteToCancel,
  setRouteToStart,
  setRouteToSuspend,
  startRoute,
  suspendRoute,
} from 'store/actions/DeliveryActions';
import { TimeWindow } from 'store/reducers/DeliveryReducer';
import styled from 'styled-components';
import { useDeliveryPageEnhancements } from 'util/hooks/launch-darkly/useDeliveryPageEnhancements';
import { ActionBar } from './ActionBar';
import { ActionBarV2 } from './ActionBarV2';
import DeliveryPageSkeleton from './DeliveryPageSkeleton';
import { DeliveryTableSection } from './DeliveryTableSection';
import { DeliveryTableSectionV2 } from './DeliveryTableSectionV2';
import { MapboxMap } from './MapboxMap';

export type TimeWindowFilterMetaData = {
  description?: string;
  label: string;
  setShouldResetTimeWindow: React.Dispatch<React.SetStateAction<boolean>>;
  shouldResetTimeWindow: boolean;
};

export const DeliveryPageWithoutPopups: FC = () => {
  const resizeRefPanel = useRef<ImperativePanelHandle>(null);
  const isUseDeliveryPageEnhancements = useDeliveryPageEnhancements();

  const selectedFiltersFromAppState = useSelector((state: State) => state.deliveryList.filterSelections);
  const deliveriesLoading = useSelector((state: State) => state.deliveryList.deliveriesLoading);
  const mapsOpen = useSelector((state: State) => state.deliveryList.mapsOpen);
  const disableAutoRefresh = useSelector((state: State) => state.deliveryList.disableAutoRefresh);
  const routeToCancel = useSelector((state: State) => state.deliveryList.routeToCancel);
  const routeToStart = useSelector((state: State) => state.deliveryList.routeToStart);
  const routeToSuspend = useSelector((state: State) => state.deliveryList.routeToSuspend);
  const buildNumber = useSelector((state: State) => state.settings.buildNumber?.BuildNumber);
  const refreshRate = useSelector((state: State) => state.settings.locationSettings?.GuestListRefreshRate);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [shouldResetTimeWindow, setShouldResetTimeWindow] = useState(false);
  const dispatch = useDispatch();

  const timeWindowFilterMetaData: TimeWindowFilterMetaData = {
    description: 'Time selection only applies to unassigned orders and routes. Does not apply to orders within routes.',
    label: 'Time Window',
    setShouldResetTimeWindow,
    shouldResetTimeWindow,
  };

  useEffect(() => {
    if (disableAutoRefresh) {
      return;
    }

    const milliseconds = refreshRate && refreshRate >= 10 ? refreshRate * 1000 : 10000;

    const interval = setInterval(() => {
      dispatch(getDeliveries());
    }, milliseconds);

    return () => {
      clearInterval(interval);
    };
  }, [disableAutoRefresh, dispatch, refreshRate]);

  useEffect(() => {
    dispatch(getInitialDeliveries());
    dispatch(selectRoute(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (mapsOpen && isUseDeliveryPageEnhancements) {
      expandPanel();
    } else if (!mapsOpen && isUseDeliveryPageEnhancements) {
      collapsePanel();
    }
  }, [mapsOpen, isUseDeliveryPageEnhancements]);

  const onApplyFilters = (filters: Array<DeliveryFilterOption>, timeWindow?: TimeWindow) => {
    const filtersAreEmpty = filters.every((filter) => !filter.options.length);
    dispatch(
      applyDeliveryFilters({ filters: filtersAreEmpty ? [] : filters, timeWindow: timeWindow ? timeWindow : [] })
    );
    setDrawerIsOpen(false);
  };

  // route table handlers

  const handleConfirmedCancelRoute = useCallback(async () => {
    if (!routeToCancel) {
      return;
    }
    await dispatch(cancelRoute({ route: routeToCancel }));
    dispatch(getDeliveries());
  }, [dispatch, routeToCancel]);

  const handleConfirmedStartRoute = useCallback(async () => {
    if (!routeToStart) {
      return;
    }
    await dispatch(startRoute({ route: routeToStart }));
    dispatch(getDeliveries());
  }, [dispatch, routeToStart]);

  const handleConfirmedSuspendRoute = useCallback(async () => {
    if (!routeToSuspend) {
      return;
    }
    await dispatch(suspendRoute({ route: routeToSuspend }));
    dispatch(getDeliveries());
  }, [dispatch, routeToSuspend]);

  const selectedFilterCount = selectedFiltersFromAppState.reduce(
    (totalSelectedFilters, { options: currOptions }) => totalSelectedFilters + currOptions?.length,
    0
  );

  const collapsePanel = () => {
    const panel = resizeRefPanel.current;
    if (panel) {
      panel.resize(0, 'pixels');
    }
  };

  const expandPanel = () => {
    const panel = resizeRefPanel.current;
    if (panel) {
      panel.resize(640, 'pixels');
    }
  };

  return (
    <>
      <ConfirmTransactionPopup
        title='Delete Route'
        message={`This will remove all deliveries from this route and make them available for dispatch on other routes`}
        onConfirm={handleConfirmedCancelRoute}
        isVisible={!!routeToCancel}
        hide={() => dispatch(setRouteToCancel(null))}
        successButtonText={`Delete`}
      />
      <ConfirmTransactionPopup
        title='Start Route'
        message={`This delivery route will be set to out for delivery.`}
        onConfirm={handleConfirmedStartRoute}
        isVisible={!!routeToStart}
        hide={() => dispatch(setRouteToStart(null))}
        successButtonText={`Yes, start route`}
      />
      <ConfirmTransactionPopup
        title='Suspend Route'
        message={`This delivery route will be set to ready and will need to be restarted to continue.`}
        onConfirm={handleConfirmedSuspendRoute}
        isVisible={!!routeToSuspend}
        hide={() => dispatch(setRouteToSuspend(null))}
        successButtonText={`Yes, suspend route`}
      />
      <DeliveryListPageDiv isUseDeliveryPageEnhancements={isUseDeliveryPageEnhancements}>
        <BottomDrawer open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
          <DeliveryPageFilterMenu
            appliedFilters={selectedFiltersFromAppState}
            onApply={onApplyFilters}
            onCancel={() => {
              setShouldResetTimeWindow(true);
              setDrawerIsOpen(false);
            }}
            timeWindowFilterMetaData={timeWindowFilterMetaData}
          />
        </BottomDrawer>

        {isUseDeliveryPageEnhancements ? (
          <>
            {deliveriesLoading ? (
              <DeliveryPageSkeleton />
            ) : (
              <>
                <DeliveryMapContainer>
                  <PanelGroup direction='horizontal' units='pixels'>
                    <Panel collapsible defaultSize={640} maxSize={640} ref={resizeRefPanel}>
                      <NewMapContainer>
                        <MapboxMap />
                      </NewMapContainer>
                    </Panel>
                    <PanelResizeHandle
                      css={{
                        width: '0.75rem',
                        backgroundColor: colors.dutchie.opal90,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <PanelResizeHandleMarker />
                    </PanelResizeHandle>
                    <Panel>
                      <TablePanel>
                        <ActionBarV2 selectedFilterCount={selectedFilterCount} setDrawerIsOpen={setDrawerIsOpen} />
                        <DeliveryTableSectionV2 />
                      </TablePanel>
                    </Panel>
                  </PanelGroup>
                </DeliveryMapContainer>
              </>
            )}
          </>
        ) : (
          <>
            <ActionBar selectedFilterCount={selectedFilterCount} setDrawerIsOpen={setDrawerIsOpen} />
            {deliveriesLoading ? (
              <DeliveryPageSkeleton />
            ) : (
              <>
                {mapsOpen ? (
                  <MapSplitContainer>
                    <MapContainer>
                      <MapboxMap />
                    </MapContainer>
                    <TableContainer>
                      <DeliveryTableSection />
                    </TableContainer>
                  </MapSplitContainer>
                ) : (
                  <DeliveryTableSection />
                )}
              </>
            )}
          </>
        )}

        {buildNumber && <BuildNumberContainer>Build: {buildNumber}</BuildNumberContainer>}
      </DeliveryListPageDiv>
    </>
  );
};

export const DeliveryPage = () => (
  <>
    <DeliveryPopups />
    <DeliveryPageWithoutPopups />
  </>
);

const DeliveryListPageDiv = styled.div<{ isUseDeliveryPageEnhancements?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  ${(props) =>
    props.isUseDeliveryPageEnhancements &&
    `
    overflow: hidden;
  `}
`;

const MapSplitContainer = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  ${breakpoints.wideTablet.maxQuery} {
    flex-direction: column;
  }
`;

const DeliveryMapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${breakpoints.wideTablet.maxQuery} {
    flex-direction: column;
  }
`;

const BuildNumberContainer = styled.div`
  font-size: 10px;
  color: ${colors.accent1};
  font-weight: bold;
  position: absolute;
  left: 0%;
  bottom: 0;
`;

const TableContainer = styled.div`
  flex: 1;

  ${breakpoints.wideTablet.maxQuery} {
    width: 100%;
  }
`;

const MapContainer = styled.div`
  height: 80vh;
  max-width: 600px;
  width: 100%;

  ${breakpoints.wideTablet.maxQuery} {
    height: 40vh;
    max-width: none;
    width: 100%;
  }
`;

const PanelResizeHandleMarker = styled.div`
  width: 0.1875rem;
  height: 2rem;
  border-radius: 6.25rem;
  background-color: #828a8f;
  align-self: center;
`;

const TablePanel = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: inset 3px 0px 3px 0px rgba(0, 0, 0, 0.25);
  overflow-y: auto;

  input {
    height: 100%;
  }
`;

const NewMapContainer = styled.div`
  position: relative;
  max-width: 640px;
  height: 100%;
  width: 100%;
`;
