import React from 'react';
import styled from 'styled-components';

import { Input } from 'components/inputs';
import { ReactComponent as DollarSign } from 'assets/icons/dollar-sign.svg';
import { StyledLinkButton } from 'components/backoffice/button/link-button/link-button.styles';

type CurrencyInputProps = React.ComponentProps<typeof Input>;

export const CurrencyInput = (props: CurrencyInputProps) => {
  return <Input {...props} type='number' decimalScale={2} fixedDecimalScale={true} startAdornment={<DollarSign />} />;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TabTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-width: 56.5rem;
`;

export const Row = styled.div`
  display: flex;
  gap: 1.25rem;
  justify-items: flex-start;

  & > * {
    flex: 1;
    width: unset;
  }
`;

export const CashInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const CashDetailsButton = styled(StyledLinkButton)`
  padding: 0;
  margin: 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0.75rem;
`;
