import { useAppDispatch, useAppSelector } from 'util/hooks';

import { CartItem, PreOrderRedemption } from 'models/Cart';
import { markRedemptionRedeemed } from 'api/CartApi';
import { applyDiscountCode } from 'api/DiscountApi';
import * as AlpineAPI from 'api/AlpineIQApi';
import * as SpringBigApi from 'api/SpringBigApi';
import * as FylloApi from 'api/FylloApi';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type RewardRedemptionProps = {
  reward: PreOrderRedemption;
  guest?: { guestId?: number; shipmentId?: number; PhoneNo?: string; CellPhone?: string };
  product?: CartItem;
};

const applyLeafLogixReward = async ({ reward, guest }: RewardRedemptionProps) => {
  return await applyDiscountCode({
    DiscountCode: reward.LeafLogixCode,
    ShipmentId: guest?.shipmentId,
    DiscountId: 0,
    OverideDesc: '',
    Apply: 'ONE',
    DiscountAmt: 0,
    DiscountPercent: 0,
    ProductId: 0,
    SerialNo: '',
    CustomerId: guest?.guestId,
  });
};

const applyFylloReward = async ({ reward, guest, product }: RewardRedemptionProps) => {
  return await FylloApi.addReward({
    ShipmentId: guest?.shipmentId ?? 0,
    ProductId: reward.ProductId,
    SerialNumber: product?.SerialNo,
    CustomerId: guest?.guestId ?? 0,
    Reward: {
      id: reward.FylloId,
      name: reward.RedemptionDescription,
      description: reward.RedemptionDescription,
      points: 0,
    },
  });
};

const applyAlpineIQReward = async ({ reward, guest, product }: RewardRedemptionProps) => {
  return await AlpineAPI.addReward({
    ShipmentId: guest?.shipmentId ?? 0,
    ProductId: reward.ProductId,
    CustomerId: guest?.guestId ?? 0,
    SerialNumber: product?.SerialNo,
    PhoneNumber: guest?.PhoneNo || guest?.CellPhone,
    Reward: {
      id: reward.AlpineId,
      redemptionURL: reward.CallbackUrl,
      name: reward.RedemptionDescription,
      inStoreRedemption: true,
      pointsDeduction: 0,
    },
  });
};

const applySpringBigReward = async ({ reward, guest, product }: RewardRedemptionProps) => {
  return await SpringBigApi.addSpringBigReward({
    ShipmentId: guest?.shipmentId,
    ProductId: reward.ProductId,
    SerialNumber: product?.SerialNo,
    CustomerId: guest?.guestId,
    RewardToApply: {
      active: '',
      description: '',
      id: parseInt(reward.SpringBigId),
      images: [],
      item_value: 0,
      meta: '',
      name: '',
    },
  });
};

const applySpringBigOffer = async ({ reward, guest, product }: RewardRedemptionProps) => {
  return await SpringBigApi.addSpringBigOffer({
    ShipmentId: guest?.shipmentId,
    ProductId: reward.ProductId,
    SerialNumber: product?.SerialNo,
    CustomerId: guest?.guestId,
    OfferToApply: {
      active: '',
      expiration: '',
      id: parseInt(reward.SpringBigId),
      max_redemptions: 1,
      images: [],
      meta: '',
      name: '',
      redemptions_available: 1,
      status: '',
    },
  });
};

export const useRedeemReward = () => {
  const dispatch = useAppDispatch();

  const { guestId, shipmentId } = useTransactionManager();

  const CellPhone = useAppSelector((state) => state.customer.details?.CellPhone);
  const PhoneNo = useAppSelector((state) => state.customer.details?.PhoneNo);

  const guest = { guestId, shipmentId, PhoneNo, CellPhone };

  const { data: cart, refetch: refetchCartDetails } = useGetCartDetails();

  return async (reward: PreOrderRedemption) => {
    try {
      const product = cart.Cart.find((item) => item.ProductId === reward.ProductId);
      if (reward.LeafLogixCode) {
        await applyLeafLogixReward({ reward, guest });
        dispatch(successNotification('Reward Applied'));
      } else if (reward.FylloId) {
        await applyFylloReward({ reward, guest, product });
        dispatch(successNotification('Fyllo Reward Added'));
      } else if (reward.AlpineId) {
        await applyAlpineIQReward({ reward, guest, product });
        dispatch(successNotification('Alpine IQ Reward Added'));
      } else if (reward.SpringBigId) {
        if (reward.SpringBigType.toLocaleLowerCase() === 'reward') {
          await applySpringBigReward({ reward, guest, product });
          dispatch(successNotification('Spring Big Reward Added'));
        } else if (reward.SpringBigType.toLocaleLowerCase() === 'offer') {
          await applySpringBigOffer({ reward, guest, product });
          dispatch(successNotification('Spring Big Offer Added'));
        }
      }
      await markRedemptionRedeemed({
        OrderRedemptionId: reward.OrderRedemptionId,
        OrderId: reward.OrderId,
      });
    } catch (e) {
      const message = e as unknown as string;
      logger.error(e, { message });
      dispatch(errorNotification(message.replace(/leaflogix/g, 'Dutchie Back Office')));
    } finally {
      refetchCartDetails();
    }
  };
};
