import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { PopupOverlay } from 'components/popups';
import { ModalCloseButton } from 'components/modals/Modal';
import { LoadingButton } from 'components/buttons';
import { ErrorMessageIcon } from 'assets/icons/error-message';
import { logger, customEventKeys } from 'util/logger';

type ErrorNotificationModalProps = {
  closeButtonLabel: string;
  continueButtonLabel?: string;
  handleContinue?: () => void;
  header: string;
  hide: () => void;
  message: ReactNode;
};

export const ErrorNotificationModal = ({
  closeButtonLabel,
  continueButtonLabel = 'Continue',
  handleContinue,
  header,
  hide,
  message,
}: ErrorNotificationModalProps): JSX.Element => {
  useEffect(() => {
    logger.info('ErrorNotificationModal mounted', {
      key: customEventKeys.modalMounted,
      notificationType: 'error',
      header,
      message,
    });
  }, [header, message]);

  return (
    <PopupOverlay hide={() => {}}>
      <Container data-testid='error-notification-modal'>
        <CloseButtonContainer>
          <ModalCloseButton onClick={hide} data-testid='error-notification-modal-close-button' />
        </CloseButtonContainer>
        <Header>
          <ErrorMessageIcon height={40} width={40} />
          <HeaderText data-testid='error-notification-modal-header'>{header}</HeaderText>
        </Header>
        <Content>
          <Info data-testid='error-notification-modal-info'>{message}</Info>
        </Content>
        <Footer>
          <LoadingButton cta onClick={hide} tertiary data-dd-action-name={`click on Close (ErrorNotificationModal)`}>
            {closeButtonLabel}
          </LoadingButton>
          {handleContinue && (
            <LoadingButton cta onClick={handleContinue}>
              {continueButtonLabel}
            </LoadingButton>
          )}
        </Footer>
      </Container>
    </PopupOverlay>
  );
};

const Container = styled.div`
  background: ${colors.dutchie.primaryWhite};
  border-radius: 12px;
  overflow: hidden;
  width: 600px;
  position: relative;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const Header = styled.div`
  padding: 32px 0px;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 24px;
  color: ${colors.dutchie.red};
`;

const HeaderText = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 32px;
`;

const Info = styled.div`
  color: ${colors.dutchie.darkGrey};
  text-align: center;
  padding: 0 50px;
  font-size: 18px;
`;

const Content = styled.div``;

const Footer = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
  gap: 16px;
`;
