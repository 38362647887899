import React, { useRef } from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from 'css/Theme';
import { BackButton, Button, ButtonWithDropDown } from 'components/buttons';
import { StickyHeader } from 'components/layout';
import { useTransactionsPopups } from 'components/TransactionsPopups';
import { useTransactionDetails } from '../../useTransactionDetails';

import type { PrintAction } from '../../useTransactionDetails';
import { Transaction } from 'models/Transactions';

type TransactionDetailsHeaderProps = {
  selected: Transaction;
  onVoid: () => void;
};

export const TransactionDetailsHeader = ({ selected, onVoid }: TransactionDetailsHeaderProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const transactionPopups = useTransactionsPopups();

  const { emailReceipt, handleClickBack, handleClickProfile, isVoidDisabled, printActions, renderPrintAction } =
    useTransactionDetails({
      selectedTransaction: selected,
    });

  return (
    <StickyHeader headerRef={headerRef}>
      <Header>
        <Title>
          <BackButton onClick={handleClickBack} />
          Transaction details
        </Title>
        <ButtonsContainer>
          <ButtonWithDropDown<PrintAction>
            secondary
            items={printActions}
            customItemRender={renderPrintAction}
            dropDownWidth='12rem'
            buttonHeight='2.75rem'
            buttonPadding='0 0.75rem'
            automationId='transaction-details_print-button'
          >
            Print
          </ButtonWithDropDown>
          <Button
            secondary
            onClick={() => transactionPopups.showEmailPopup(emailReceipt)}
            disabled={!!selected.CancelDate}
          >
            Email Receipt
          </Button>
          <Button secondary onClick={() => transactionPopups.showTransactionPopup(selected)}>
            User Activity
          </Button>
          <Button secondary disabled={isVoidDisabled} onClick={onVoid}>
            Void
          </Button>
          <ProfileButton secondary onClick={handleClickProfile}>
            Profile
          </ProfileButton>
        </ButtonsContainer>
      </Header>
    </StickyHeader>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const Title = styled.h2`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${colors.dutchie.almostBlack};
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  white-space: nowrap;
  margin: 0;
  padding: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
`;

const ProfileButton = styled(Button)`
  display: none;

  ${breakpoints.wideTablet.maxQuery} {
    display: unset;
  }
`;
