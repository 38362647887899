import styled, { css } from 'styled-components';

import type { AnchorPosition } from '../OverflowMenu.types';

export const MenuAnchor = styled.div<{ position?: AnchorPosition; padding?: string; showCursor?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}

  ${({ position }) =>
    position &&
    css`
      position: absolute;
      ${position.left && `left: ${position.left};`}
      ${position.right && `right: ${position.right};`}
      ${position.top && `top: ${position.top};`}
      ${position.bottom && `bottom: ${position.bottom};`}
    `}

  ${({ showCursor }) => showCursor && `cursor: pointer;`}
`;

