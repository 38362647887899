import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { ApplyLoyaltyPointsRequest } from 'models/Cart';
import { useLoyaltyCalculations } from './useLoyaltyCalculations';
import { applyLoyaltyPoints } from 'store/actions/CartActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { useSessionStorage } from 'util/hooks';
import { useLoyaltySettings } from './useLoyaltySettings';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

export const useApplyLoyaltyPoints = () => {
  const dispatch = useDispatch();
  const selectedRegister = useSelector((state: State) => state.settings.selectedRegister?.value);
  const { value: userId } = useSessionStorage('UserId');
  const guestHasPaid = useSelector((state: State) => state.customer.details?.IsPaid);

  const { guestId, shipmentId } = useTransactionManager();

  const { availablePoints } = useLoyaltyCalculations();
  const { applyLoyaltyAsDiscount } = useLoyaltySettings();

  const baseRequestParams: ApplyLoyaltyPointsRequest = {
    TransactionId: shipmentId ?? 0,
    Register: selectedRegister ?? 0,
    UserId: parseInt(userId ?? '0'),
    GuestId: guestId ?? 0,
    LoyaltyAmt: 0,
    LoyaltyAsDiscount: applyLoyaltyAsDiscount,
  };

  const setLoyaltyPoints = async (amount: number) => {
    if (guestHasPaid) {
      dispatch(errorNotification('Customer has already paid'));
      return;
    }
    return dispatch(applyLoyaltyPoints({ ...baseRequestParams, LoyaltyAmt: amount }));
  };

  return {
    apply: async (amount: number) => {
      return setLoyaltyPoints(amount);
    },
    applyAvailable: () => {
      return setLoyaltyPoints(availablePoints);
    },
    clearApplied: () => {
      return setLoyaltyPoints(0);
    },
  };
};
