import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from 'store';
import { useScanner } from 'components/Scanner';
import { showScanDetailsPopup } from 'store/actions/PopupsActions';
import { successNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { customEventKeys } from 'util/logger/customEventKeys';
import { IDScanResultContext, IDScanResult } from 'util/logger/types/scanning';
import { useBarcodeScanEntryQuery } from 'queries/v2/guest/barcode-scan-entry';
import type { BarcodeScanEntryResponse } from 'queries/v2/guest/barcode-scan-entry';

const getRUMContext = (isAutoCheckInWithIdScanEnabled: boolean, isAutoCreateWithIdScanEnabled: boolean, data?: BarcodeScanEntryResponse): Partial<IDScanResultContext> => ({
  isAutoCheckInWithIdScanEnabled,
  isAutoCreateWithIdScanEnabled,
  data: {
    CreatedCustomerId: data?.CreatedCustomerId,
    CheckedInShipmentId: data?.CheckedInShipmentId,
    ValidatedIdentificationCustomer: {
      IsBanned: data?.ValidatedIdentificationCustomer?.IsBanned ?? false,
      IsDriversLicenseExpired: data?.ValidatedIdentificationCustomer?.IsDriversLicenseExpired ?? false,
      IsLoyaltyMember: data?.ValidatedIdentificationCustomer?.IsLoyaltyMember ?? false,
      IsMedical: data?.ValidatedIdentificationCustomer?.IsMedical ?? false,
      IsMJStateIdExpired: data?.ValidatedIdentificationCustomer?.IsMJStateIdExpired ?? false,
      IsUnderMedicalAge: data?.ValidatedIdentificationCustomer?.IsUnderMedicalAge ?? false,
      IsUnderRecreationalAge: data?.ValidatedIdentificationCustomer?.IsUnderRecreationalAge ?? false,
    },
  },
});

type UseScanPopupParams = {
  hide: () => void;
};

export function useScanPopup(params: UseScanPopupParams) {
  const { hide } = params;

  // state
  const [barcode, setBarcode] = useState<string>('');

  // hooks
  const scanner = useScanner();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAutoCheckInWithIdScanEnabled = useSelector((state: State) => state.settings.features['AutoCheckInWithIdScan']);
  const isAutoCreateWithIdScanEnabled = useSelector((state: State) => state.settings.features['AutoCreateWithIdScan']);
  const { data, error, isInitialLoading, isError } = useBarcodeScanEntryQuery({ ID: barcode }, { enabled: !!barcode });

  // handlers
  const handleScan = useCallback((barcode) => {
    setBarcode(barcode);
  }, []);

  const handleAutoCheckin = useCallback((trackingContext: Partial<IDScanResultContext>) => {
    logger.info<IDScanResultContext>('ID scan result: customer auto checked in', {
      key: customEventKeys.scanning.id.scanResult,
      result: IDScanResult.AUTO_CHECKIN,
      ...trackingContext,
    });

    dispatch(successNotification('Customer checked in'));
    hide();
  }, [dispatch, hide]);

  const handleAutoCreation = useCallback(
    (data: BarcodeScanEntryResponse, trackingContext: Partial<IDScanResultContext>) => {
      logger.info<IDScanResultContext>('ID scan result: customer auto created', {
        key: customEventKeys.scanning.id.scanResult,
        result: IDScanResult.AUTO_CREATE,
        ...trackingContext,
      });

      dispatch(successNotification('Customer created'));
      history.push(`/edit-customer?id=${data.CreatedCustomerId}`);
      hide();
    },
    [dispatch, hide, history]
  );

  const handleDefaultResponse = useCallback(
    (data: BarcodeScanEntryResponse, trackingContext: Partial<IDScanResultContext>) => {
      logger.info<IDScanResultContext>('ID scan result: scan details popup shown', {
        key: customEventKeys.scanning.id.scanResult,
        result: IDScanResult.SHOW_SCAN_DETAILS,
        ...trackingContext,
      });

      dispatch(showScanDetailsPopup({ barcodeScanEntryResponse: data }));
    },
    [dispatch]
  );

  // effects
  useEffect(() => {
    scanner.changeAction(handleScan);
  }, [scanner, handleScan]);

  useEffect(() => {
    const trackingContext = getRUMContext(isAutoCheckInWithIdScanEnabled, isAutoCreateWithIdScanEnabled, data);

    if (data?.CheckedInShipmentId) {
      handleAutoCheckin(trackingContext);
    } else if (data?.CreatedCustomerId) {
      handleAutoCreation(data, trackingContext);
    } else if (data) {
      handleDefaultResponse(data, trackingContext);
    }
  // Don't need isAutoCheckInWithIdScanEnabled and isAutoCreateWithIdScanEnabled because they're only used for tracking
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, handleAutoCheckin, handleAutoCreation, handleDefaultResponse]);

  return {
    error,
    isError,
    isLoading: isInitialLoading,
  };
}
