import { Transaction as OldTransaction } from 'models/Transactions';
export type TransactionServerResponse = {
  BiotEndAvail: number;
  BiotStartAvail: number;
  BiotUsed: number;
  CancelDate?: string;
  CanceledBy?: string;
  CancelReason?: string;
  CustomerId: number;
  CustomerType?: string;
  DiscountAmt: number;
  DiscountCode: string;
  DoctorsName?: string;
  EducationalMaterialsAccepted: boolean;
  hasAdjustment: boolean;
  hasReturn: boolean;
  isCanceled?: 0 | 1;
  isReturn: 0 | 1;
  isVoided: 0 | 1;
  Location: string;
  LoyaltyEarned: number;
  LoyaltyUsed: number;
  ManifestId: number;
  NonRevenueCashValue: number;
  OrderNo: string;
  OrderSource: string;
  OrderType: string;
  PatientName: string;
  PosDate: string;
  PosDeliveredDate: string;
  PosId: number;
  PosInvoicedDate: string;
  PosPaymentType: string;
  PosPostedDate?: string;
  PosProductCost: number;
  PosStatus: string;
  PosTax: number;
  PosTotalItems: number;
  Register: string;
  RevenueCashValue: number;
  TipAmount?: number;
  TotalInvoice: number;
  TransactionBy: string;
  VisitReason?: string;
  VoidTransactionId: string;
};

export type Transaction = ReturnType<typeof transformToTransaction>;

export const transformToTransaction = (response: TransactionServerResponse) => ({
  biotrackEndAvail: response.BiotEndAvail,
  biotrackStartAvail: response.BiotStartAvail,
  biotrackUsed: response.BiotUsed,
  cancelDate: response.CancelDate,
  canceledBy: response.CanceledBy,
  cancelReason: response.CancelReason,
  customerId: response.CustomerId,
  customerType: response.CustomerType,
  deliveredDate: response.PosDeliveredDate,
  discountAmount: response.DiscountAmt,
  discountCode: response.DiscountCode,
  doctorsName: response.DoctorsName,
  hasAdjustment: response.hasAdjustment,
  hasReturn: response.hasReturn,
  invoicedDate: response.PosInvoicedDate,
  isCanceled: response.isCanceled === 1,
  isReturn: response.isReturn === 1,
  isVoided: response.isVoided === 1,
  locationName: response.Location,
  loyaltyEarned: response.LoyaltyEarned,
  loyaltyUsed: response.LoyaltyUsed,
  manifestId: response.ManifestId,
  nonRevenueCashValue: response.NonRevenueCashValue,
  orderNumber: response.OrderNo,
  orderSource: response.OrderSource,
  orderType: response.OrderType,
  patientName: response.PatientName,
  paymentType: response.PosPaymentType,
  postedDate: response.PosPostedDate,
  productCost: response.PosProductCost,
  registerName: response.Register,
  revenueCashValue: response.RevenueCashValue,
  shipmentDate: response.PosDate,
  shipmentId: response.PosId,
  shipmentStatus: response.PosStatus,
  tax: response.PosTax,
  tipAmount: response.TipAmount,
  totalInvoice: response.TotalInvoice,
  totalItems: response.PosTotalItems,
  transactionBy: response.TransactionBy,
  visitReason: response.VisitReason,
  voidTransactionId: response.VoidTransactionId,
  wereEducationalMaterialsAccepted: response.EducationalMaterialsAccepted,
});

export const transformToOldTransaction = (transaction: Transaction): OldTransaction => ({
  BiotEndAvail: transaction.biotrackEndAvail,
  BiotStartAvail: transaction.biotrackStartAvail,
  BiotUsed: transaction.biotrackUsed,
  CancelDate: transaction.cancelDate,
  CanceledBy: transaction.canceledBy,
  CancelReason: transaction.cancelReason,
  CustomerId: transaction.customerId,
  CustomerType: transaction.customerType,
  PosDeliveredDate: transaction.deliveredDate,
  DiscountAmt: transaction.discountAmount,
  DiscountCode: transaction.discountCode,
  DoctorsName: transaction.doctorsName ?? '',
  EducationalMaterialsAccepted: transaction.wereEducationalMaterialsAccepted,
  hasAdjustment: transaction.hasAdjustment,
  hasReturn: transaction.hasReturn,
  isCanceled: transaction.isCanceled,
  isReturn: transaction.isReturn,
  isVoided: transaction.isVoided,
  Location: transaction.locationName,
  LoyaltyEarned: transaction.loyaltyEarned,
  LoyaltyUsed: transaction.loyaltyUsed,
  ManifestId: transaction.manifestId,
  NonRevenueCashValue: transaction.nonRevenueCashValue,
  OrderNo: transaction.orderNumber,
  OrderSource: transaction.orderSource,
  OrderType: transaction.orderType,
  PatientName: transaction.patientName,
  PosDate: transaction.shipmentDate,
  PosId: transaction.shipmentId,
  PosInvoicedDate: transaction.invoicedDate,
  PosPaymentType: transaction.paymentType,
  PosPostedDate: transaction.postedDate,
  PosProductCost: transaction.productCost,
  PosStatus: transaction.shipmentStatus,
  PosTax: transaction.tax,
  PosTotalItems: transaction.totalItems,
  Register: transaction.registerName,
  RevenueCashValue: transaction.revenueCashValue,
  TipAmount: transaction.tipAmount,
  TotalInvoice: transaction.totalInvoice,
  TransactionBy: transaction.transactionBy,
  VisitReason: transaction.visitReason,
  VoidTransactionId: transaction.voidTransactionId,
});
