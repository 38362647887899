import React from "react";

import { Select } from "components/inputs";
import { SelectOption, SelectProps } from "components/inputs/Select";
import { useNonIntegratedPaymentsConfiguredProcessorsQuery } from "queries/v2/payments/manual-payments";

type ManualPaymentTypeSelectProps = Omit<SelectProps, "options">;

export function ManualPaymentTypeSelect(props: ManualPaymentTypeSelectProps) {
  const { data } = useNonIntegratedPaymentsConfiguredProcessorsQuery();
  const defaultOption: SelectOption = {
    label: "Option",
    value: -1,
    key: -1,
  };

  const manualPaymentOptions: SelectOption[] = data?.map((processor) => ({
    label: processor.ManualPaymentProcessorName,
    value: processor.ManualPaymentProcessorId,
    key: processor.ManualPaymentProcessorId,
  })) || [];

  const options = [defaultOption, ...manualPaymentOptions];

  return <Select {...props} options={options} />;
}
