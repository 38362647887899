import { post } from 'api/HttpHelpers';
import { openPDFInWindow } from 'util/Printing';

import type { CartItem } from 'models/Cart';
import { isFulfilled, isDefined } from 'util/helpers/typeGaurds';

const REQUEST_URLS = {
  PreviewLabels: 'v2/print-jobs/preview-pos-label',
  PreviewManifest: 'v2/manifest/print-manifest',
  PreviewReceipt: 'v2/print-jobs/preview-receipt',
};

type PreviewLabelsParams = {
  items: CartItem[];
  labelId?: number;
  printAll: boolean;
  shipmentId: number;
  allowsProductLabels: boolean;
};

export const previewLabels = async ({
  allowsProductLabels,
  items,
  labelId,
  printAll,
  shipmentId,
}: PreviewLabelsParams) => {
  const itemsByLabelId = items.reduce((acc, item) => {
    const itemLabelId = allowsProductLabels && item.DefaultLabelId ? item.DefaultLabelId : labelId ?? 1;

    if (!acc[itemLabelId]) {
      acc[itemLabelId] = [];
    }

    acc[itemLabelId].push(item);
    return acc;
  }, {} as { [key: string]: CartItem[] });

  const requests = Object.entries(itemsByLabelId).map(([labelId, items]) => {
    return post<string>(REQUEST_URLS.PreviewLabels, {
      BatchId: shipmentId,
      LabelId: labelId,
      LabelType: 'ProductProd',
      PackagesToPrint: items.map((item) => ({
        ShipmentId: shipmentId,
        AllocatedInventoryId: item.InventoryId,
        Count: printAll ? item.QtySelected : 1,
        SerialNumber: item.SerialNo,
        PackageItemId: item.PackageItemId,
        PackageQuantity: item.WgtCnt === 'Wgt' ? (item.Grams ?? 0) / item.QtyAllocated : 1,
      })),
      PrinterId: 0, // Not needed by endpoint
      ShipmentId: shipmentId,
    });
  });

  const results = await Promise.allSettled(requests);
  const fulFilledResults = results.filter(isFulfilled).map((p) => p.value);
  const datas = fulFilledResults.filter(isDefined);

  datas.forEach((data) => {
    openPDFInWindow(data, 'Labels.pdf');
  });
};

type PreviewManifestParams = {
  shipmentId: number;
};

export const previewManifest = async ({ shipmentId }: PreviewManifestParams) => {
  const data = await post<string>(REQUEST_URLS.PreviewManifest, {
    PosId: shipmentId,
  });

  openPDFInWindow(data, `PrintedManifest-${shipmentId}.pdf`);
};
