import React from 'react';
import styled from 'styled-components';
import { SidePanelSection, SectionHeader } from 'components/layout';
import { ReactComponent as DownArrowIcon } from 'assets/icons/chevron-down.svg';
import { OrderTotals } from 'components/cart/OrderTotals';
import { Cart } from 'models/Cart';

const OrderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Chevron = styled(DownArrowIcon)<{ open?: boolean }>`
  cursor: pointer;

  ${({ open }) =>
    open &&
    `
        transform: rotate(180deg);
    `}
`;
type OrderDetailsSectionProps = {
  setShowOrderDetails: (arg0: boolean) => void;
  showOrderDetails: boolean;
  cart: Cart;
};
export const OrderDetailsSection = ({ setShowOrderDetails, showOrderDetails, cart }: OrderDetailsSectionProps) => {
  return (
    <SidePanelSection gap='1rem' showBorder={false}>
      <OrderHeader
        onClick={(e) => {
          e.preventDefault();
          setShowOrderDetails(!showOrderDetails);
        }}
      >
        <SectionHeader>Order details</SectionHeader>
        <Chevron data-testid='index_chevron_open' open={showOrderDetails} />
      </OrderHeader>
      {showOrderDetails && <OrderTotals cart={cart} showTotal={false} hideGetTotalBtn />}
    </SidePanelSection>
  );
};
