/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from 'lodash';
import React, { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as ColumnsIcon } from 'assets/icons/columns.svg';
import { ReactComponent as MapsIcon } from 'assets/icons/map.svg';
import { Delivery } from 'models/Delivery';
import {
  applyDeliveryFilters,
  getDeliveries,
  setDeliveryFilterQuery,
  moveRoute,
  setMapsOpen,
} from 'store/actions/DeliveryActions';
import { DeliveryRoute } from 'models/DeliveryRoute';
import { SearchBar, Spacer, Toolbar } from 'components/layout';
import { NavButton } from 'components/buttons';
import { showConfigureDeliveriesPopup, showConfirmTransactionPopup } from 'store/actions/PopupsActions';

export type ActionBarProps = {
  selectedFilterCount: number;
  setDrawerIsOpen: (isOpen: boolean) => unknown;
};

export const ActionBar: FC<ActionBarProps> = ({ selectedFilterCount, setDrawerIsOpen }) => {
  const filterQuery = useSelector((state: State) => state.deliveryList.filterQuery);
  const timeWindowForFilter = useSelector((state: State) => state.deliveryList.timeWindowForFilter);
  const selectedDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);
  const mapsOpen = useSelector((state: State) => state.deliveryList.mapsOpen);
  const userSettings = useSelector((state: State) => state.settings.userSettings);
  const permissions = useSelector((state: State) => state.settings.permissions);
  const UsePlaceholderDirections = useSelector((state: State) => state.settings.features.UsePlaceholderDirections);
  const expandedRoute = useSelector((state: State) => state.deliveryList.expandedRoute);
  const dispatch = useDispatch();

  const onSearch = useCallback(
    debounce((search: string) => {
      dispatch(setDeliveryFilterQuery(search));
    }, 300),
    [dispatch]
  );

  const onResetFilters = useCallback(() => {
    dispatch(applyDeliveryFilters({ filters: [] }));
  }, [dispatch]);

  const addSelectionToRoute = useCallback(
    (deliveries: Delivery[], toRoute: DeliveryRoute | null) => {
      if (permissions.EditRoutes && !!toRoute) {
        // endpoint on the delivery - delivery controller "delivery/add-shipments-to-route" { deliveryRouteId: number|null, shipmentIds: number[] }
        dispatch(
          moveRoute({
            ShipmentIds: deliveries.map(({ ShipmentId }) => ShipmentId),
            DeliveryRouteId: toRoute.DeliveryRouteId,
            UsePlaceholderDirections,
          })
        ).finally(() => dispatch(getDeliveries()));
      }
    },
    [UsePlaceholderDirections, dispatch, permissions.EditRoutes]
  );

  const addToRouteEnabled = React.useMemo(
    () =>
      !!expandedRoute &&
      selectedDeliveries.filter((d) => d.DeliveryRouteId !== expandedRoute?.DeliveryRouteId).length > 0,
    [expandedRoute, selectedDeliveries]
  );

  const hasSelectedDeliveriesInRoute = () => {
    //allow users to create empty routes
    if (selectedDeliveries.length > 0) {
      return selectedDeliveries.some((delivery: Delivery) => {
        return delivery.DeliveryRouteId === null;
      });
    } else {
      return true;
    }
  };

  const showClearFiltersButton = !!selectedFilterCount || !!timeWindowForFilter.length;
  const filterCount = timeWindowForFilter.length ? selectedFilterCount + 1 : selectedFilterCount;

  return (
    <Toolbar>
      {userSettings.showSearch && (
        <SearchBar
          onChange={onSearch}
          onSearch={onSearch}
          initialValue={filterQuery}
          placeholder='Customer search...'
          inputAutomationId='delivery-list-page_search-bar-large_delivery-list-input'
        />
      )}

      <NavButton
        onClick={() => dispatch(showConfigureDeliveriesPopup({ createRoute: true }))}
        disabled={!permissions.EditRoutes || !hasSelectedDeliveriesInRoute()}
        automationId='action-bar_button_create-route'
      >
        Create Route
      </NavButton>

      <NavButton
        onClick={() =>
          dispatch(
            showConfirmTransactionPopup({
              message: `Add these ${selectedDeliveries.length} deliveries to route?`,
              onConfirm: () => {
                addSelectionToRoute(selectedDeliveries, expandedRoute);
              },
              pinRequired: false,
              title: 'Add to Route?',
            })
          )
        }
        disabled={!(addToRouteEnabled && permissions.EditRoutes)}
        automationId='action-bar_button_add-to-route'
      >
        Add To Route
      </NavButton>

      <Spacer />

      <NavButton
        title='Refresh'
        onClick={() => {
          dispatch(getDeliveries());
        }}
        icon={<RefreshIcon />}
        secondary
        automationId='action-bar_button_refresh'
      />
      <NavButton
        title='Filters'
        onClick={() => {
          setDrawerIsOpen(true);
        }}
        icon={<FilterIcon />}
        secondary
        automationId='action-bar_button_filters'
      />
      <NavButton
        title='Open List'
        onClick={() => {
          dispatch(setMapsOpen(!mapsOpen));
        }}
        icon={mapsOpen ? <ColumnsIcon /> : <MapsIcon />}
        secondary
        automationId='action-bar_button_open-list'
      />

      {showClearFiltersButton && (
        <NavButton onClick={onResetFilters} secondary automationId='action-bar_button_clear-filters'>
          Clear Filters ({filterCount})
        </NavButton>
      )}
    </Toolbar>
  );
};
