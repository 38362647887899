import { useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import { checkInCustomer } from 'store/actions/CustomerActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { closeCustomerSelectionPanel } from 'store/actions/CartActions';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useConvertToNonAnonymousMutation } from 'queries/v2/guest/convert-to-non-anonymous';
import { useCustomerSearchQuery } from 'queries/v2/guest/checkin-search-by-string';
import { useGetCheckedInGuestsQuery } from 'queries/v2/guest/checked-in';
import { useSwitchCustomerMutation } from 'queries/v2/cart/switch-customer';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CustomerSearchResult } from 'queries/v2/guest/types';

export const useCustomerSelectionPanel = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isCustomerSelectionPanelOpen = useAppSelector((state) => state.cart.isCustomerSelectionPanelOpen);
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const [searchValue, setSearchValue] = useState('');

  const isSearchEmpty = searchValue === '';

  const { data: customerSearchResults, isLoading: isLoadingResults } = useCustomerSearchQuery(
    { searchString: searchValue },
    { enabled: !isSearchEmpty }
  );

  const { data: checkedInGuestIds, refetch: refetchCheckedInGuests } = useGetCheckedInGuestsQuery({
    enabled: !isSearchEmpty && (customerSearchResults?.length ?? 0) > 0,
    select: (checkedInGuests) => [...new Set(checkedInGuests.map((guest) => guest.guestId))],
  });

  const { mutateAsync: convertToNonAnonymous, isLoading: isConvertingGuest } = useConvertToNonAnonymousMutation();
  const { mutateAsync: switchCustomer, isLoading: isSwitchingCustomer } = useSwitchCustomerMutation();

  const { guestId, shipmentId, goToCart, startTransaction } = useTransactionManager();

  const handleClose = useCallback(() => {
    setSearchValue('');
    dispatch(closeCustomerSelectionPanel());
  }, [dispatch]);

  const getCheckedInGuestDetails = async (selectedCustomer: CustomerSearchResult) => {
    // Might need to add check in validation here (See CustomerPreview.tsx)
    const checkedInGuestResponse = await dispatch(
      checkInCustomer({
        guestId: selectedCustomer.guestId,
        mjStateId: selectedCustomer.mmjIdNumber,
        register: registerId,
      })
    ).unwrap();

    if (!checkedInGuestResponse) {
      dispatch(errorNotification('Failed to check in customer'));
      return;
    }

    const [checkedInGuest] = checkedInGuestResponse;

    return {
      guestId: selectedCustomer.guestId,
      shipmentId: checkedInGuest.ShipmentId,
    };
  };

  const handleAttachCustomerToCart = async (selectedCustomer: CustomerSearchResult) => {
    const isGuestCheckedIn = checkedInGuestIds?.includes(selectedCustomer.guestId);

    // If a matching guest is found in the guest list, do nothing and return
    if (isGuestCheckedIn) {
      return;
    }

    // If anonymous guest has not been created yet, attempt to check in the customer and reload the page
    if (!guestId || !shipmentId) {
      const newCartParams = await getCheckedInGuestDetails(selectedCustomer);

      if (newCartParams) {
        goToCart(newCartParams);
        handleClose();
      }

      return;
    }

    try {
      const newCartParams = await switchCustomer({
        currentCustomerId: guestId,
        newCustomerId: selectedCustomer.guestId,
        shipmentId,
      });

      goToCart(newCartParams);
      handleClose();
    } catch (error) {
      logger.error(error);
      if (typeof error === 'string') {
        dispatch(errorNotification(error));
      }
    }
  };

  const handleCreateNewProfile = async () => {
    if (guestId) {
      await convertToNonAnonymous({ guestId });
      history.push(`/edit-customer?id=${guestId}`);
      return;
    }

    const newTransactionDetails = await startTransaction();
    if (newTransactionDetails) {
      await convertToNonAnonymous({ guestId: newTransactionDetails.guestId });
      history.push(`/edit-customer?id=${newTransactionDetails.guestId}`);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value.trim());
    refetchCheckedInGuests();
  };

  const searchInputPlaceholder = 'Search by customer name, phone number or drivers license';
  const switchCustomerLoadingMessage = isSwitchingCustomer
    ? 'Attempting to attach customer to cart. Please wait...'
    : isConvertingGuest
    ? 'Creating a new profile. Please wait...'
    : undefined;

  return {
    customerSearchResults,
    FullScreenPortalId,
    handleAttachCustomerToCart,
    handleClose,
    handleCreateNewProfile,
    handleSearch,
    isCustomerSelectionPanelOpen,
    isLoadingResults: isLoadingResults && !isSearchEmpty,
    isSearchEmpty,
    isSwitchingCustomer: isSwitchingCustomer || isConvertingGuest,
    searchInputPlaceholder,
    searchValue,
    switchCustomerLoadingMessage,
  };
};
