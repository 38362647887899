import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { Peripheral } from '@dutchie/capacitor-peripheral';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { Loader } from 'components/backoffice/loader';
import { errorNotification } from 'store/actions/NotificationsActions';
import { Label } from 'components/text';
import { Select } from 'components/inputs';
import { ActionBar, RefreshButton, SelectContainer } from '../../SettingPageComponents';
import { LocalLabelPrinterPickerProps } from '../types';
import { getLocalPrinterSettings } from '../utils';
import { Button } from 'components/buttons';
import { TEST_FOOTER, TEST_HEADER } from '../Constants/ZPLEncodingConstants';
import { logger, customEventKeys } from 'util/logger';
import {
  SearchLabelPrintersContext,
  UserSelectedPrinterContext,
} from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { getSearchLabelPrintersDescription } from 'util/logger/helpers/printing';

export const LocalLabelPrinterPicker: FC<LocalLabelPrinterPickerProps> = ({
  connectedLabelPrinters,
  setConnectedLabelPrinters,
}) => {
  const dispatch = useDispatch();
  const { selectedLabelPrinter } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintLabels } = useSelector((state: State) => state.settings.userSettings);
  const [loadingLabelPrinters, setLoadingLabelPrinters] = useState<boolean>(false);

  const options = connectedLabelPrinters.map((item) => ({
    value: item.id,
    label: `${item.name}`,
    key: item.id,
  }));

  const searchLabelPrinters = useCallback(
    async ({ userInitiated = false }: { userInitiated?: boolean }) => {
      setLoadingLabelPrinters(true);
      try {
        const { results } = await Peripheral.searchLabelPrinters();
        setConnectedLabelPrinters(results);

        logger.info<SearchLabelPrintersContext>(getSearchLabelPrintersDescription(results, userInitiated), {
          key: customEventKeys.printing.local.label.search,
          availablePrinters: results,
          userInitiated,
        });
      } catch (e) {
        dispatch(errorNotification(`Error loading label printers ${e}`));
        logger.error(e, { message: 'searchLabelPrinters failed', userInitiated });
      } finally {
        setLoadingLabelPrinters(false);
      }
    },
    [setConnectedLabelPrinters, dispatch]
  );

  useEffect(() => {
    searchLabelPrinters({ userInitiated: false });
  }, [searchLabelPrinters]);

  const changeLabelPrinter = (value: string) => {
    const newPrinterSettings = getLocalPrinterSettings(value, connectedLabelPrinters);
    dispatch(saveUserSettings({ selectedLabelPrinter: newPrinterSettings }));
    // Fire connectPrinter here

    logger.info<UserSelectedPrinterContext>(`user selected local label printer ${newPrinterSettings?.PrinterId}`, {
      key: customEventKeys.printing.userSelectedPrinter,
      job: PrintJob.LABELS,
      type: 'local',
      selectedPrinterId: newPrinterSettings?.PrinterId ?? '',
    });
  };

  const testPrint = () => {
    const testString = 'TEST LOCAL LABEL PRINT';
    const printerId = selectedLabelPrinter?.PrinterId.toString() ?? '';
    let bytes: number[] = [];
    bytes = bytes.concat(TEST_HEADER);
    for (let i = 0; i < testString.length; i++) {
      bytes.push(testString.charCodeAt(i));
    }
    bytes = bytes.concat(TEST_FOOTER);

    Peripheral.printLabel({
      id: printerId,
      bytes: bytes,
    });
  };

  const labelPicker = loadingLabelPrinters ? (
    <Loader variant='black' />
  ) : (
    <Select
      placeholder='Select Printer'
      defaultValue={selectedLabelPrinter?.PrinterId}
      onChange={changeLabelPrinter}
      options={options}
      errorMessage={!selectedLabelPrinter && autoPrintLabels && 'Please select a default label printer'}
    />
  );

  return (
    <SelectContainer>
      <ActionBar>
        <Label>Local Label Printer</Label>
        <RefreshButton onClick={() => searchLabelPrinters({ userInitiated: true })} />
        <Button onClick={testPrint}>Test Print</Button>
      </ActionBar>
      {labelPicker}
    </SelectContainer>
  );
};
