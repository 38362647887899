import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useNonSandboxBannerRollout = () => {
  const ldClient = useLDClient();
  const nonSandboxBannerRollout: boolean = ldClient?.variation(
    'backoffice.platform.non-sandbox-banner-alert.rollout',
    false
  );
  return { nonSandboxBannerRollout };
};
