import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const HubIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <path
            d="M22.1167 17.6529C20.2508 20.8428 16.7994 22.9541 13.0691 23.1774L13.061 23.1779L13.0529 23.1786C8.94382 23.514 5.30902 19.6342 6.37042 15.7309L6.37044 15.7309L6.37173 15.726C6.38463 15.6773 6.40171 15.6398 6.41775 15.6136C6.42677 15.599 6.43458 15.5893 6.44012 15.5832C6.4471 15.5889 6.45749 15.5981 6.4717 15.6123C6.4996 15.6402 6.53106 15.676 6.57811 15.7321C6.58503 15.7404 6.59257 15.7494 6.60064 15.7591C6.63957 15.8058 6.69074 15.8671 6.74273 15.9245C8.53323 17.93 11.1169 19.1357 13.7696 19.358L13.7696 19.3581L13.7758 19.3585C15.3249 19.4754 16.8811 19.3585 18.397 19.016L18.399 19.0156C19.6377 18.7324 20.9197 18.268 22.1167 17.6529ZM6.42888 15.5751C6.42887 15.575 6.42941 15.5753 6.43055 15.576C6.42946 15.5755 6.42889 15.5751 6.42888 15.5751Z"
            stroke="#828A8F"
            stroke-width="1.5"
          />
          <path
            d="M12.468 0.801017C16.1969 0.808424 19.767 2.71129 21.8278 5.8012L21.8324 5.80802L21.8371 5.81474C24.1731 9.15652 22.628 14.2077 18.6644 15.2603C18.573 15.2833 18.5158 15.2775 18.4909 15.2721C18.4923 15.266 18.4941 15.2585 18.4966 15.2494C18.5064 15.2137 18.5216 15.1703 18.5473 15.1022C18.5514 15.0913 18.556 15.0793 18.561 15.0663C18.5827 15.0094 18.6113 14.9343 18.6359 14.8614L18.6363 14.86C19.4868 12.3159 19.257 9.49912 18.1242 7.10318L18.1243 7.10316L18.1203 7.09502C17.444 5.70806 16.5632 4.43011 15.5017 3.30036L12.468 0.801017ZM12.468 0.801017C13.5931 1.52019 14.6389 2.38033 15.5014 3.30008L12.468 0.801017Z"
            stroke="#828A8F"
            stroke-width="1.5"
          />
          <path
            d="M12.7819 9.30762L12.7827 9.30774C13.513 9.41277 14.149 9.79576 14.5752 10.3743L14.5752 10.3743L14.5796 10.3802C15.2274 11.2418 15.293 12.4053 14.7481 13.34L14.7463 13.343C14.5601 13.6659 14.3102 13.9427 14.0059 14.163L14.0059 14.1629L14.0023 14.1656C13.417 14.5948 12.6882 14.7718 11.9571 14.6658C11.2352 14.5611 10.5964 14.1784 10.1608 13.5951L10.1608 13.5951L10.1594 13.5932C9.51151 12.7316 9.44592 11.5681 9.99084 10.6334L9.9926 10.6303C10.1788 10.3075 10.4288 10.0307 10.733 9.81042L10.733 9.81043L10.7366 9.80778C11.3219 9.37862 12.0508 9.20161 12.7819 9.30762Z"
            stroke="#828A8F"
            stroke-width="1.5"
          />
          <path
            d="M11.7635 5.17566L11.7601 5.17636C9.11543 5.71325 6.76905 7.31845 5.23607 9.48943L5.23603 9.48941L5.2312 9.49641C4.35012 10.7747 3.6733 12.1704 3.21685 13.6441C2.84262 14.8511 2.61341 16.1857 2.55136 17.515C0.691346 14.3152 0.572997 10.3045 2.23902 6.9895L2.2426 6.98239L2.24602 6.97519C3.99952 3.29329 9.20745 2.08925 12.1066 4.96826L12.1066 4.9683L12.1131 4.97463C12.1681 5.02794 12.1911 5.06996 12.2004 5.09235C12.1926 5.09496 12.1823 5.09807 12.169 5.10154C12.1311 5.11148 12.0839 5.12065 12.0125 5.13256C11.9993 5.13477 11.9844 5.13721 11.9681 5.13985C11.9093 5.14943 11.8338 5.16175 11.7635 5.17566Z"
            stroke="#828A8F"
            stroke-width="1.5"
          />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <path
          id="vector"
          d="M22.1167 17.6529C20.2508 20.8428 16.7994 22.9541 13.0691 23.1774L13.061 23.1779L13.0529 23.1786C8.94382 23.514 5.30902 19.6342 6.37042 15.7309L6.37044 15.7309L6.37173 15.726C6.38463 15.6773 6.40171 15.6398 6.41775 15.6136C6.42677 15.599 6.43458 15.5893 6.44012 15.5832C6.4471 15.5889 6.45749 15.5981 6.4717 15.6123C6.4996 15.6402 6.53106 15.676 6.57811 15.7321C6.58503 15.7404 6.59257 15.7494 6.60064 15.7591C6.63957 15.8058 6.69074 15.8671 6.74273 15.9245C8.53323 17.93 11.1169 19.1357 13.7696 19.358L13.7696 19.3581L13.7758 19.3585C15.3249 19.4754 16.8811 19.3585 18.397 19.016L18.399 19.0156C19.6377 18.7324 20.9197 18.268 22.1167 17.6529ZM6.42888 15.5751C6.42887 15.575 6.42941 15.5753 6.43055 15.576C6.42946 15.5755 6.42889 15.5751 6.42888 15.5751Z"
          fill="#FFCCD1"
          stroke="#FF3E51"
          stroke-width="1.5"
        />
        <path
          id="vector_2"
          d="M12.468 0.801017C16.1969 0.808424 19.767 2.71129 21.8278 5.8012L21.8324 5.80802L21.8371 5.81474C24.1731 9.15652 22.628 14.2077 18.6644 15.2603C18.573 15.2833 18.5158 15.2775 18.4909 15.2721C18.4923 15.266 18.4941 15.2585 18.4966 15.2494C18.5064 15.2137 18.5216 15.1703 18.5473 15.1022C18.5514 15.0913 18.556 15.0793 18.561 15.0663C18.5827 15.0094 18.6113 14.9343 18.6359 14.8614L18.6363 14.86C19.4868 12.3159 19.257 9.49912 18.1242 7.10318L18.1243 7.10316L18.1203 7.09502C17.444 5.70806 16.5632 4.43011 15.5017 3.30036L12.468 0.801017ZM12.468 0.801017C13.5931 1.52019 14.6389 2.38033 15.5014 3.30008L12.468 0.801017Z"
          fill="#FFCCD1"
          stroke="#FF3E51"
          stroke-width="1.5"
        />
        <path
          id="vector_3"
          d="M12.7819 9.30762L12.7827 9.30774C13.513 9.41277 14.149 9.79576 14.5752 10.3743L14.5752 10.3743L14.5796 10.3802C15.2274 11.2418 15.293 12.4053 14.7481 13.34L14.7463 13.343C14.5601 13.6659 14.3102 13.9427 14.0059 14.163L14.0059 14.1629L14.0023 14.1656C13.417 14.5948 12.6882 14.7718 11.9571 14.6658C11.2352 14.5611 10.5964 14.1784 10.1608 13.5951L10.1608 13.5951L10.1594 13.5932C9.51151 12.7316 9.44592 11.5681 9.99084 10.6334L9.9926 10.6303C10.1788 10.3075 10.4288 10.0307 10.733 9.81042L10.733 9.81043L10.7366 9.80778C11.3219 9.37862 12.0508 9.20161 12.7819 9.30762Z"
          fill="#FFCCD1"
          stroke="#FF3E51"
          stroke-width="1.5"
        />
        <path
          id="vector_4"
          d="M11.7635 5.17568L11.7601 5.17637C9.11543 5.71327 6.76905 7.31846 5.23607 9.48945L5.23603 9.48942L5.2312 9.49642C4.35012 10.7747 3.6733 12.1704 3.21685 13.6441C2.84262 14.8511 2.61341 16.1857 2.55136 17.515C0.691346 14.3152 0.572997 10.3045 2.23902 6.98952L2.2426 6.9824L2.24602 6.97521C3.99952 3.29331 9.20745 2.08927 12.1066 4.96828L12.1066 4.96832L12.1131 4.97464C12.1681 5.02795 12.1911 5.06998 12.2004 5.09236C12.1926 5.09497 12.1823 5.09809 12.169 5.10156C12.1311 5.1115 12.0839 5.12067 12.0125 5.13258C11.9993 5.13478 11.9844 5.13722 11.9681 5.13987C11.9093 5.14945 11.8338 5.16176 11.7635 5.17568Z"
          fill="#FFCCD1"
          stroke="#FF3E51"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};
