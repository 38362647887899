import { useTrackedContext } from 'util/hooks';
import { CartItem, Preorder } from 'models/Cart';
import { callback, callback1 } from 'models/Misc';
import { CartPopupsState, emptyCartPopupsState } from './CartPopupsState';
import { cartPopupsContext, CartPopupsContext } from './ProvideCartPopups';
import { Reason } from 'models/Settings';
import { PaymentMethod } from 'models/Checkout';
import { PointSet } from 'components/misc';

import type { CancelTransactionGuestProps } from 'components/sharedPopups/CancelTransactionPopup';
import type { DeliveryOrderProps } from 'components/sharedPopups/DeliverySettingsPopup';
import type { ProductSearchResult } from 'queries/v2/product/types';

type ShowBulkCalculatorOptions = {
  isPreorder?: boolean;
  setWeight?: callback1<string>;
  setTotalCost?: callback1<number>;
  closeProductPreview?: () => void;
};

type CartPopups = {
  showTransactionNotesPopup: (guestId?: number, shipmentId?: number) => void;
  showRegisterForLoyaltyPopup: () => void;
  showVerifyTransactionPopup: (guestId: number, shipmentId: number) => void;
  showRemotePayPopup: (shipmentId?: number) => void;
  showDutchiePayPopup: (shipmentId?: number) => void;
  showBackdateTransactionPopup: (shipmentId?: number) => void;
  showSetTransactionReferencePopup: () => void;
  showConfirmReleasePopup: () => void;
  showDeliverySettingsPopup: (delivery: DeliveryOrderProps) => void;
  showJournalPopup: (guestId?: number) => void;
  showEditProductPopup: (item: CartItem) => void;
  showDiscountPopup: (item: CartItem) => void;
  showSpringBigRedeemPopup: (args?: { item?: CartItem }) => void;
  showAlpineIQRedeemPopup: (args?: { item?: CartItem }) => void;
  showFylloRedeemPopup: (args?: { item?: CartItem }) => void;
  showFeeDonationPopup: () => void;
  showRerouteProductPopup: (item: CartItem) => void;
  showEditDevicePopup: (item: CartItem) => void;
  showClearCartPopup: () => void;
  showViewAllotmentDetailPopup: () => void;
  showNotifyCustomerPopup: () => void;
  showEducationalMaterialPopup: () => void;
  showBulkCalculatorPopup: (
    product: ProductSearchResult | CartItem,
    inputWeight: string,
    options?: ShowBulkCalculatorOptions
  ) => void;
  showVisitReasonPopup: (shipmentId: number) => void;
  showManagerPinPopup: (
    onSuccess: callback,
    title: string,
    reasons?: Array<Reason>,
    selectedReason?: string,
    setSelectedReason?: callback1<string>,
    onCancel?: callback
  ) => void;
  showCancelTransactionPopup: (guest: CancelTransactionGuestProps) => void;
  showSetStatusPopup: (action?: callback, title?: string) => void;
  showLabsPopup: (batchId?: number) => void;
  /** @deprecated: Use the redux popups instead (CartPopupsReducer) */
  showLast4Popup: (onSuccess: callback, serialNumber: string, onCancel?: callback) => void;
  showConfirmRemovalPopup: (item: CartItem) => void;
  showOverLimitPopup: (onConfirm: callback, message: string) => void;
  showPastExpirationDatePopup: (onConfirm: callback, expiredItemsSerialNumbers: string[]) => void;
  showHypurCheckoutPopup: (args: { paymentMethod: PaymentMethod; shipmentId?: number }) => void;
  showEmailPopup: (onEmail: callback1<string>) => void;
  showSignaturePopup: (args: {
    setSignatureSaved: callback1<boolean>;
    shipmentId: number;
    signatureData?: PointSet;
  }) => void;
  showAddMoreItemsInPreOrderPopup: (onSuccess: callback, onCancel?: callback) => void;
  showCanPayCheckoutPopup: (args: { paymentMethod: PaymentMethod; shipmentId?: number }) => void;
  showDigitalManifestPopup: (args: {
    setSignatureSaved?: callback1<boolean>;
    manifestLink: string;
    signatureData?: PointSet;
  }) => void;
  showVerifyBirthdatePopup: (
    guestDob: string,
    isAnonymous: boolean,
    onSuccess: callback1<Date | undefined>,
    shipmentId?: number,
    acctId?: number
  ) => void;
  showAssignPackageIDsPopup: (selectedPreorderItem: Preorder) => void;
  /** @deprecated: Use the redux popups instead (CartPopupsReducer) */
  showCartItemRemovalPopup: (item: CartItem, onRemoveItem: () => void) => void;
  cartPopupsState: CartPopupsState;
};

const createPopupHandlers = ([cartPopupsState, setCartPopupsState]: CartPopupsContext): CartPopups => {
  const showPopup = (props: Partial<CartPopupsState>) => setCartPopupsState({ ...emptyCartPopupsState, ...props });
  return {
    showTransactionNotesPopup: (guestId?: number, shipmentId?: number) =>
      showPopup({ transactionNotesPopup: { guestId, shipmentId } }),
    showRegisterForLoyaltyPopup: () => showPopup({ registerForLoyaltyPopup: {} }),
    showVerifyTransactionPopup: (guestId: number, shipmentId: number) =>
      showPopup({ verifyTransactionPopup: { guestId, shipmentId } }),
    showRemotePayPopup: (shipmentId?: number) => showPopup({ remotePayPopup: { shipmentId } }),
    showDutchiePayPopup: (shipmentId?: number) => showPopup({ dutchiePayPopup: { shipmentId } }),
    showBackdateTransactionPopup: (shipmentId?: number) => showPopup({ backdateTransactionPopup: { shipmentId } }),
    showSetTransactionReferencePopup: () => showPopup({ setTransactionReferencePopup: {} }),
    showConfirmReleasePopup: () => showPopup({ confirmReleasePopup: {} }),
    showDeliverySettingsPopup: (delivery: DeliveryOrderProps) => showPopup({ deliverySettingsPopup: { delivery } }),
    showJournalPopup: (guestId?: number) => showPopup({ journalPopup: { guestId } }),
    showEditProductPopup: (item: CartItem) => showPopup({ editProductPopup: { item } }),
    showDiscountPopup: (item: CartItem) => showPopup({ discountPopup: { item } }),
    showSpringBigRedeemPopup: (args?: { item?: CartItem }) => showPopup({ springBigRedeemPopup: args ?? {} }),
    showAlpineIQRedeemPopup: (args?: { item?: CartItem }) => showPopup({ alpineIQRedeemPopup: args ?? {} }),
    showFylloRedeemPopup: (args?: { item?: CartItem }) => showPopup({ fylloRedeemPopup: args ?? {} }),
    showFeeDonationPopup: () => showPopup({ feeDonationPopup: {} }),
    showRerouteProductPopup: (item: CartItem) => showPopup({ rerouteProductPopup: { item } }),
    showEditDevicePopup: (item: CartItem) => showPopup({ editDevicePopup: { item } }),
    showClearCartPopup: () => showPopup({ clearCartPopup: {} }),
    showViewAllotmentDetailPopup: () => showPopup({ viewAllotmentDetailPopup: {} }),
    showNotifyCustomerPopup: () => showPopup({ notifyCustomerPopup: {} }),
    showEducationalMaterialPopup: () => showPopup({ educationalMaterialPopup: {} }),
    showBulkCalculatorPopup: (product, inputWeight, options) =>
      showPopup({ bulkCalculatorPopup: { product, inputWeight, ...options } }),
    showVisitReasonPopup: (shipmentId: number) => showPopup({ visitReasonPopup: { shipmentId } }),
    showManagerPinPopup: (
      onSuccess: callback,
      title: string,
      reasons?: Array<Reason>,
      selectedReason?: string,
      setSelectedReason?: callback1<string>,
      onCancel?: callback
    ) => showPopup({ managerPinPopup: { onSuccess, title, reasons, selectedReason, setSelectedReason, onCancel } }),
    showCancelTransactionPopup: (guest: CancelTransactionGuestProps) =>
      showPopup({ cancelTransactionPopup: { guest } }),
    showSetStatusPopup: (action?: callback, title?: string) => showPopup({ setStatusPopup: { action, title } }),
    showLabsPopup: (batchId?: number) => showPopup({ labsPopup: { batchId } }),
    showLast4Popup: (onSuccess: callback, serialNumber: string, onCancel?: callback) =>
      showPopup({ last4Popup: { onSuccess, serialNumber, onCancel } }),
    showConfirmRemovalPopup: (item: CartItem) => showPopup({ confirmRemovalPopup: { item } }),
    showOverLimitPopup: (onConfirm: callback, message: string) => showPopup({ overLimitPopup: { onConfirm, message } }),
    showPastExpirationDatePopup: (onConfirm: callback, expiredItemsSerialNumbers: string[]) =>
      showPopup({ pastExpirationDatePopup: { onConfirm, expiredItemsSerialNumbers } }),
    showHypurCheckoutPopup: (args: { paymentMethod: PaymentMethod; shipmentId?: number }) =>
      showPopup({ hypurCheckoutPopup: args }),
    showEmailPopup: (onEmail: callback1<string>) => showPopup({ emailPopup: { onEmail } }),
    showSignaturePopup: (args: {
      setSignatureSaved: callback1<boolean>;
      shipmentId: number;
      signatureData?: PointSet;
    }) => showPopup({ signaturePopup: args }),
    showAddMoreItemsInPreOrderPopup: (onSuccess: callback, onCancel?: callback) =>
      showPopup({ addMoreItemsToPreOrderPopup: { onSuccess, onCancel } }),
    showCanPayCheckoutPopup: (args: { paymentMethod: PaymentMethod; shipmentId?: number }) =>
      showPopup({ canPayCheckoutPopup: args }),
    showDigitalManifestPopup: (args: {
      setSignatureSaved?: callback1<boolean>;
      manifestLink: string;
      signatureData?: PointSet;
    }) => showPopup({ digitalManifestPopup: args }),
    showVerifyBirthdatePopup: (
      guestDob: string,
      isAnonymous: boolean,
      onSuccess: callback1<Date | undefined>,
      shipmentId?: number,
      acctId?: number
    ) => showPopup({ verifyBirthdatePopup: { guestDob, isAnonymous, onSuccess, shipmentId, acctId } }),
    showAssignPackageIDsPopup: (selectedPreorderItem: Preorder) =>
      showPopup({ assignPackageIDsPopup: { selectedPreorderItem } }),
    showCartItemRemovalPopup: (item, onRemoveItem) => showPopup({ removeCartItem: { item, onRemoveItem } }),
    cartPopupsState,
  };
};

/**
 * @deprecated: Use the redux popups instead (CartPopupsReducer)
 */
export const useCartPopups = () =>
  useTrackedContext<CartPopupsState, CartPopups>(cartPopupsContext, createPopupHandlers);
