
import styled from "styled-components";
import { Column } from ".";

export const ScrollLayout = styled(Column)`
  height: 100%;
`;

export const ScrollHeader = styled.div`
  background-color: white; 
`;

export const ScrollBody = styled.div`
  overflow: auto; 
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;
