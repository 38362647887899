import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { format, startOfToday, differenceInCalendarDays, addDays } from 'date-fns';
import styled from 'styled-components';

import { store } from 'store';
import { Popup } from 'components/popups';
import { callback } from 'models/Misc';
import { DatePicker, Input } from 'components/inputs';
import { CancelButton as _CancelButton, LoadingButton } from 'components/buttons';
import { setDaysSupplyInPeriod } from 'store/actions/CartActions';
import { updateDaysSupplyRemaining, getPrescriptions } from 'store/actions/CustomerActions';
import { CustomerDetails, Prescription } from 'models/Customer';
import { Label } from 'components/text';
import { colors } from 'css/Theme';
import { Box } from 'components/layout';

type DiscountDialogProps = {
  hide: callback;
  customer?: CustomerDetails;
};

export const DaysSupplyCalculator: FC<DiscountDialogProps> = ({ hide, customer }) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [selected, setSelected] = useState<Date | null>();
  const [fillDates, setFillDates] = useState('');
  const [daysInPeriod, setDaysInPeriod] = useState(0);
  const [periodPurchased, setPeriodPurchased] = useState(0);
  const [loading, setLoading] = useState(false);

  const onRecommendationDateChange = useCallback(
    (recommendationStart: Date) => {
      setSelected(recommendationStart);
      const now = startOfToday();
      const dayNumber = differenceInCalendarDays(now, recommendationStart);
      const periodNumber = dayNumber === 0 ? 1 : Math.ceil(dayNumber / 45);
      const periodMoreThanYear = periodNumber > 8;
      const firstPeriodOffset = periodNumber === 1 ? 1 : 0;
      const daysToPeriodStart = 45 * (periodNumber - 1) + 1 - firstPeriodOffset;
      const daysInPeriod = 45 + firstPeriodOffset;
      const periodStart = addDays(recommendationStart, daysToPeriodStart);
      const periodEnd = addDays(periodStart, daysInPeriod - 1);

      setFillDates(`${format(periodStart, 'M/dd/yyy')} - ${format(periodEnd, 'M/dd/yyy')}`);
      setDaysInPeriod(daysInPeriod);
      dispatch(setDaysSupplyInPeriod(daysInPeriod));

      if (periodMoreThanYear) {
        setFillDates('Expired Date Entered');
        setDaysInPeriod(0);
        dispatch(setDaysSupplyInPeriod(0));
      }

      if (differenceInCalendarDays(recommendationStart, now) > 0) {
        setFillDates('Future Date entered');
        setDaysInPeriod(0);
        dispatch(setDaysSupplyInPeriod(0));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (customer) {
      dispatch(getPrescriptions({ guestId: customer.Guest_id })).then((res) => {
        const incompletePrescriptions = (res.payload as Prescription[]).filter((x) => !x.CompletedDate);
        if (incompletePrescriptions.length > 0) {
          const [latestPrescription] = [...incompletePrescriptions].sort((a, b) =>
            new Date(a.PrescriptionDate) > new Date(b.PrescriptionDate) ? -1 : 1
          );
          onRecommendationDateChange(new Date(latestPrescription.PrescriptionDate));
        }
      });
    }
  }, [dispatch, onRecommendationDateChange, customer]);

  const onClear = () => {
    setSelected(null);
    setFillDates('');
    setDaysInPeriod(0);
    setPeriodPurchased(0);
  };

  const onConfirm = async () => {
    if (customer) {
      setLoading(true);
      await dispatch(
        updateDaysSupplyRemaining({
          PatientId: customer.Guest_id,
          DaysSupplyRemaining: daysInPeriod - periodPurchased,
        })
      );
      onClear();
      setLoading(false);
      hide();
    }
  };

  return (
    <Popup caption='Fill Calculator' hide={hide} isVisible width='500px'>
      <DaysSupplySection>
        <Box>
          <Label>Recommendation Date</Label>
          <DatePicker
            selected={selected}
            onChange={(date) => date && onRecommendationDateChange(date)}
            automationId='days-supply-calc_recommendation-date'
          />
        </Box>
        <Box marginBottom='1rem'>
          <Input
            label='Fill Period Start / End'
            value={fillDates}
            disabled={true}
            automationId='days-supply-calc_fill-period'
          />
        </Box>

        <Box marginBottom='1rem'>
          <Input
            label='Days Purchased This Fill'
            automationId='days-supply-calc_days-purchased'
            type='number'
            value={periodPurchased}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPeriodPurchased(parseFloat(e.target.value));
            }}
          />
        </Box>
        <Box marginBottom='1rem'>
          <Input
            label='Days Supply Remaining'
            disabled={true}
            value={daysInPeriod - periodPurchased}
            automationId='days-supply-calc_days-supply-remaining'
          />
        </Box>
      </DaysSupplySection>
      <ButtonContainer>
        <CancelButton disabled={loading} secondary onClick={hide}>
          Cancel
        </CancelButton>
        <LoadingButton loading={loading} onClick={onConfirm}>
          Confirm
        </LoadingButton>
      </ButtonContainer>
    </Popup>
  );
};

const DaysSupplySection = styled.div`
  margin: 0 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;
  box-shadow: 0 -1px 0 ${colors.dutchie.shadowGrey};
`;

const CancelButton = styled(_CancelButton)`
  padding-left: 0;
`;
