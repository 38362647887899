import { useCallback, useEffect } from 'react';

export type OnEvent<T> = (data: T) => void;
export const useEventData = <T>(eventName: string, onEvent: OnEvent<T>) => {
  //we have to wrap the callbacks to get around the fact that the
  //addEventListener callback takes an any and not the type we want
  const scanCallback = useCallback(
    (data) => {
      const res = data as T;
      onEvent(res);
    },
    [onEvent]
  );

  useEffect(() => {
    window.addEventListener(eventName, scanCallback);
    return () => {
      window.removeEventListener(eventName, scanCallback);
    };
  }, [scanCallback, eventName]);
};
