import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useChangeCartQtyFromCartItem } from 'util/hooks';
import type { State } from 'store';
import type { CartItem } from 'models/Cart';
import type { ProductSelectionChangeProps } from 'components/cart/ProductSelectionControls';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

type UseCartLineItemParams = {
  item: CartItem;
  totalDiscountAmount?: number;
};

export type UseCartLineItemReturn = {
  canUseQuantityControls: boolean;
  discountedCostToDisplay: string;
  grams: number;
  handleQuantityChange: (change: ProductSelectionChangeProps) => Promise<void>;
  isItemFromPreorder: boolean;
  packageId: string;
  quantity: number;
  quantityToDisplay: string;
  totalCostToDisplay: string;
};

export function useCartLineItem({ item, totalDiscountAmount }: UseCartLineItemParams): UseCartLineItemReturn {
  const {
    data: { PreOrders: preorderItemsInCart, Locked: isCartLocked },
  } = useGetCartDetails();

  // global state
  const isPackageItemIdsFeatureActive = useSelector((state: State) => state.settings.features.PackageItemIds);
  const isEnableCartPlusMinusFeatureActive = useSelector((state: State) => state.settings.features.EnableCartPlusMinus);

  // local state
  const [grams, setGrams] = useState(item.Grams ?? 0);
  const [quantity, setQuantity] = useState(item.QtySelected);

  // hooks
  const changeCartQty = useChangeCartQtyFromCartItem();

  // computed
  const isItemFromPreorder = preorderItemsInCart.some((preorder) => preorder.ProductId === item.ProductId);
  const canUseQuantityControls = isEnableCartPlusMinusFeatureActive && !isCartLocked;
  const packageId =
    isPackageItemIdsFeatureActive && item.PackageItemSerialNumber ? item.PackageItemSerialNumber : item.SerialNo;
  const quantityToDisplay = item.WgtCnt === 'Wgt' ? `${grams}g` : `${quantity}`;
  const totalCostToDisplay = `$${item?.TotalCost.toFixed(2)}`;
  const discountedCostToDisplay = `$${(item?.TotalCost - (totalDiscountAmount ?? 0)).toFixed(2)}`;

  // handlers
  const handleQuantityChange = async (change: ProductSelectionChangeProps) => {
    if (change.countChange) {
      const newQty = quantity + change.countChange;
      setQuantity(newQty);
      try {
        await changeCartQty(item, newQty);
      } catch (e) {
        setQuantity(quantity);
      }
    } else if (change.weightChange) {
      const newGrams = (item.Grams || 0) + (change.weightChange || 0);
      setGrams(newGrams);
      try {
        await changeCartQty(item, newGrams);
      } catch (e) {
        setGrams(grams);
      }
    }
  };

  // effects
  useEffect(() => {
    setQuantity(item.QtySelected);
    setGrams(item.Grams ?? 0);
  }, [item]);

  return {
    canUseQuantityControls,
    discountedCostToDisplay,
    grams,
    handleQuantityChange,
    isItemFromPreorder,
    packageId,
    quantity,
    quantityToDisplay,
    totalCostToDisplay,
  };
}
