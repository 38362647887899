import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { BottomDrawer, LeftContainerDimensions } from 'components/layout';
import { colors, zIndices, headerHeight, panelWidth, eloOriPadBreakpoint, breakpoints } from 'css/Theme';
import { ProvideCartPopups, CartPopups } from 'components/CartPopups';
import { AddItemPanel } from './CartComponents/AddItemPanel';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';
import { CartHeader } from 'components/cart/CartHeader';
import { CartPanels, extendedPanelPortalID } from './CartPanels';
import { CartSummary } from './CartSummary';
import { DeliveryPopups } from 'components/DeliveryPopups';
import { OnScanUpdater } from './CartComponents/OnScanUpdater';
import { useCartPage } from './useCartPage';
import { CustomerHistoryPanel } from 'components/cart/CustomerHistoryPanel';
import { CustomerSelectionPanel } from './CartComponents/CustomerSelectionPanel';
import { CartFooter } from './CartPanels/CartFooter';

const CartPageWithoutPopups: FC = () => {
  const {
    extendedCartPanelHeight,
    handleCloseAddItemsPanel,
    isAddItemsPanelOpen,
    isCartLoading,
    isCartLocked,
    isCheckoutRunning,
    setIsAddItemsPanelOpen,
  } = useCartPage();

  return (
    <>
      <OnScanUpdater />
      <Container>
        <CartHeader showInitialPopup={true} loading={isCartLoading} />
        <BaseContainer>
          <LeftContainer>
            <CartSummary loading={isCartLoading} setAddItemPanelIsOpen={setIsAddItemsPanelOpen} />
            <CartFooter isLoading={isCartLoading} />
          </LeftContainer>
          <CartPanels loading={isCartLoading} />
        </BaseContainer>
        <BottomDrawer
          fullHeight
          open={isAddItemsPanelOpen}
          onClose={handleCloseAddItemsPanel}
          portalId={FullScreenPortalId}
          marginX={0}
          marginY={40}
        >
          {isAddItemsPanelOpen && <AddItemPanel onClose={handleCloseAddItemsPanel} />}
        </BottomDrawer>
        <CustomerHistoryPanel />
        <CustomerSelectionPanel />
        {isCheckoutRunning && (
          <CheckoutOverlay>
            {isCartLocked && (
              <LockedCartText>
                Cart has been locked by the state system. Additional items cannot be added to this transaction
              </LockedCartText>
            )}
          </CheckoutOverlay>
        )}
        <ExtendedCartPanelPortal id={extendedPanelPortalID} height={extendedCartPanelHeight} />
      </Container>
    </>
  );
};

export const CartPage = () => {
  return (
    <>
      <DeliveryPopups />
      <ProvideCartPopups>
        <CartPopups />
        <CartPageWithoutPopups />
      </ProvideCartPopups>
    </>
  );
};

const BaseContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const LeftContainer = styled.div`
  ${LeftContainerDimensions}
  display: flex;
  flex-direction: column;
  background-color: ${colors.dutchie.lightGrey};
  padding: 2rem 3.5rem;
  gap: 1rem;
  ${eloOriPadBreakpoint} {
    padding: 2rem 2.5rem 2rem 3.5rem;
  }
`;

const CheckoutOverlay = styled.div`
  display: flex;
  position: absolute;
  z-index: ${zIndices.overlay};
  height: 100%;
  background: ${rgba(colors.dutchie.opal12, 0.5)};
  left: 0;
  right: clamp(520px, ${panelWidth.width}, ${panelWidth.width});

  ${breakpoints.smallTablet.maxQuery} {
    display: none;
  }
`;

const LockedCartText = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExtendedCartPanelPortal = styled.div<{ height?: string }>`
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${panelWidth.width};
  min-width: 520px;
  height: ${({ height }) => (height ? height : `calc(100vh - ${headerHeight})`)};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  pointer-events: none;
  z-index: 3;

  & > * {
    width: 100%;
    pointer-events: all;
  }

  ${breakpoints.smallTablet.maxQuery} {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  height: 100%;

  ${LeftContainer} {
    padding: 1.5rem;
  }

  ${ExtendedCartPanelPortal} {
    position: absolute;
    height: 100%;
  }

  ${breakpoints.smallTablet.maxQuery} {
    ${LeftContainer} {
      padding: 0;
      gap: 0;
      overflow-y: auto;
    }
  }
`;
