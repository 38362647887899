import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { lookupTab } from './RouteMappings';
import { useAppSelector, useSearchParams } from 'util/hooks';
import { useCloseoutDetailsQuery } from 'queries/posv3/backendpos/CurrentCloseoutDetails';

import { Closeout } from 'pages/RegisterTransactionsPage/Closeout';
import { Deposit } from 'pages/RegisterTransactionsPage/Deposit';
import { DropCash } from 'pages/RegisterTransactionsPage/DropCash';
import { RegisterTransactions } from 'pages/RegisterTransactionsPage/RegisterTransactions';
import { Transfer } from 'pages/RegisterTransactionsPage/Transfer';
import { Withdraw } from 'pages/RegisterTransactionsPage/Withdraw';

import type { Tab } from 'components/layout';

export const useCashManagementPage = () => {
  const history = useHistory();

  // Router Params

  const match = useRouteMatch<{ selectedTab: string }>('/register/:selectedTab');
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = searchParams.get('tabIndex');
  const registerIdOverride = searchParams.get('registerId');

  // Global State

  const currentRegister = useAppSelector((state) => state.settings.selectedRegister);
  const registers = useAppSelector((state) => state.settings.registers);
  const isBlindCloseoutsEnabled = useAppSelector((state) => state.settings.features.UseBlindRegisterCloseOut);

  const registerToUse = useMemo(() => {
    if (!registerIdOverride) {
      return currentRegister;
    }

    const registerId = Number(registerIdOverride);
    const foundRegister = registers.find((r) => r.id === registerId);

    return {
      value: foundRegister?.id ?? currentRegister?.value,
      label: foundRegister?.TerminalName ?? currentRegister?.label,
    };
  }, [currentRegister, registerIdOverride, registers]);

  // Query Data

  const {
    data: closeoutDetails,
    isError: wasCloseoutError,
    isLoading: isLoadingCloseout,
    refetch: refetchCloseout,
  } = useCloseoutDetailsQuery({ registerId: registerToUse?.value });

  // Computed Values

  const endingBalance = closeoutDetails?.endingBalance ?? 0;
  const registerName = registerToUse?.label;
  const selectedTab = lookupTab({ route: match?.params.selectedTab })?.label ?? undefined;

  // Memoized Values

  const errorMessage = useMemo(() => {
    if (registerToUse?.value === undefined) {
      return 'Could not load register';
    } else if (wasCloseoutError || !closeoutDetails) {
      return 'There was an error retrieving closeout details';
    } else if (closeoutDetails?.startingBalance === null && closeoutDetails?.endingBalance === null) {
      return 'No closeout details found';
    }
  }, [closeoutDetails, registerToUse, wasCloseoutError]);

  const tabs: Tab[] = useMemo(() => {
    const registerId = registerToUse?.value;
    if (!registerId || !closeoutDetails) {
      return [];
    }

    const getTabProps = (route: string, content: JSX.Element): Tab => ({
      label: lookupTab({ route })?.label ?? '',
      onClick: () => {
        const searchString = searchParams.toString();
        history.push(`/register/${route}${searchString ? `?${searchString}` : ''}`);
      },
      content,
    });

    return [
      getTabProps('transactions', <RegisterTransactions registerId={registerId} />),
      getTabProps('closeout', <Closeout {...closeoutDetails} onCloseout={refetchCloseout} registerId={registerId} />),
      getTabProps('dropcash', <DropCash onDropCash={refetchCloseout} registerId={registerId} />),
      getTabProps('transfer', <Transfer onTransfer={refetchCloseout} registerId={registerId} />),
      getTabProps('withdraw', <Withdraw onWithdraw={refetchCloseout} registerId={registerId} />),
      getTabProps('deposit', <Deposit onDeposit={refetchCloseout} registerId={registerId} />),
    ];
  }, [history, registerToUse, closeoutDetails, refetchCloseout, searchParams]);

  // Effects

  useEffect(() => {
    if (!match?.params.selectedTab) {
      const route = lookupTab({ index: tabIndex })?.route;
      const newPathname = `/register/${route ?? 'transactions'}`;

      history.replace({
        pathname: newPathname,
        search: searchParams.toString(),
      });
    } else if (searchParams.has('tabIndex')) {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params);
        newParams.delete('tabIndex');
        return newParams;
      });
    }
  }, [history, match, tabIndex, searchParams, setSearchParams]);

  return {
    endingBalance,
    errorMessage,
    isBlindCloseoutsEnabled,
    isLoadingCloseout,
    registerName,
    selectedTab,
    tabs,
  };
};
