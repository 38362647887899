import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { Delivery, DeliveryFilterOption } from 'models/Delivery';
import { DeliveryRoute } from 'models/DeliveryRoute';
import { GuestStatus, Zone } from 'models/Guest';
import {
  getDeliveries,
  getInitialDeliveries,
  setDeliveryFilterQuery,
  applyDeliveryFilters,
  toggleSelectedDelivery,
  selectDeliveries,
  setMultipleReadyForDelivery,
  selectRoute,
  moveRoute,
  setDisableAutorefresh,
  setRouteOrder,
  getRouteDirections,
  getOptimizedRouteDirections,
  setExpandedRoute,
  setMapsOpen,
  setRouteToCancel,
  setRouteToStart,
  setRouteToSuspend,
} from 'store/actions/DeliveryActions';
import { searchAndFilterDeliveryList } from 'util/Helpers';
import { isEqual } from 'lodash';

export type TimeWindow = [Date, Date] | [];

export type DeliveryListState = {
  deliveries: Array<Delivery>;
  deliveriesLoading: boolean;
  routes: Array<DeliveryRoute>;
  selectedRoute?: DeliveryRoute;
  displayDeliveries: Array<Delivery>;
  displayRoutes: Array<DeliveryRoute>;
  filterOptions: Array<DeliveryFilterOption>;
  filterSelections: Array<DeliveryFilterOption>;
  statuses: Array<GuestStatus>;
  zones: Array<Zone>;
  filterQuery: string;
  submitting: boolean;
  selectedDeliveries: Array<Delivery>;
  updatingDeliveryManifests: boolean;
  disableAutoRefresh: boolean;
  routesWithDirtyDirections: Array<number>;
  timeWindowForFilter: TimeWindow;
  expandedRoute: DeliveryRoute | null;
  mapsOpen: boolean;
  routeToCancel: DeliveryRoute | null;
  routeToStart: DeliveryRoute | null;
  routeToSuspend: DeliveryRoute | null;
};

export const initialState: DeliveryListState = {
  deliveries: [],
  routes: [],
  selectedRoute: undefined,
  deliveriesLoading: false,
  displayDeliveries: [],
  displayRoutes: [],
  filterOptions: [],
  filterSelections: [],
  statuses: [],
  filterQuery: '',
  submitting: false,
  zones: [],
  selectedDeliveries: [],
  updatingDeliveryManifests: false,
  disableAutoRefresh: false,
  routesWithDirtyDirections: [],
  timeWindowForFilter: [],
  expandedRoute: null,
  mapsOpen: true,
  routeToCancel: null,
  routeToStart: null,
  routeToSuspend: null,
};

export type SearchAndFilterReturn = {
  filteredRoutes: DeliveryRoute[];
  filteredDeliveries: Delivery[];
};

const searchAndFilter = (
  allDeliveries: Delivery[],
  filters: DeliveryFilterOption[],
  searchQuery: string,
  routes: DeliveryRoute[],
  timeWindowForFilter?: TimeWindow
): SearchAndFilterReturn => {
  const keysToSearch: Array<keyof Delivery> = [
    'TransactionStatus',
    'CarModel',
    'CarMake',
    'FullName',
    'street',
    'street2',
    'city',
    'state',
    'TimeWindowStartDate',
    'TimeWindowEndDate',
  ];
  return searchAndFilterDeliveryList(allDeliveries, filters, keysToSearch, searchQuery, routes, timeWindowForFilter);
};

export const deliveryListSlice = createSlice({
  name: 'deliveryList',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DeliveryListState>) => {
    builder.addCase(getInitialDeliveries.pending, (state: DeliveryListState) => {
      state.deliveriesLoading = true;
    });
    builder.addCase(getInitialDeliveries.rejected, (state: DeliveryListState) => {
      state.deliveriesLoading = false;
    });
    builder.addCase(getInitialDeliveries.fulfilled, (state, action) => {
      const allDeliveries = action.payload.fetchedDeliveries;
      const allRoutes = action.payload.fetchedRoutes;

      const carModelsOptionsSet = new Set<Delivery['CarModel']>();
      const statusOptionsSet = new Set<Delivery['TransactionStatus']>();

      allDeliveries.forEach((g) => {
        if (g.CarModel?.length) {
          carModelsOptionsSet.add(g.CarModel);
        }
        if (g.TransactionStatus?.length) {
          statusOptionsSet.add(g.TransactionStatus);
        }
      });

      const filterOptions: Array<DeliveryFilterOption> = [
        { label: 'Vehicle', key: 'CarModel', options: [...carModelsOptionsSet] },
        { label: 'Delivery Status', key: 'TransactionStatus', options: [...statusOptionsSet] },
      ];

      const { filteredRoutes, filteredDeliveries } = searchAndFilter(
        allDeliveries,
        state.filterSelections,
        state.filterQuery,
        allRoutes,
        state.timeWindowForFilter
      );

      const dataHasChanged = !isEqual(state.deliveries, allDeliveries) || !isEqual(state.routes, allRoutes);

      if (dataHasChanged) {
        state.deliveries = allDeliveries;
        state.routes = allRoutes;
        state.filterOptions = filterOptions;
        state.displayRoutes = filteredRoutes;
        state.displayDeliveries = filteredDeliveries;
        state.selectedDeliveries = allDeliveries.filter(
          (d) =>
            filteredDeliveries.some((dd) => dd.ShipmentId === d.ShipmentId) &&
            state.selectedDeliveries.some((dd) => dd.ShipmentId === d.ShipmentId)
        );
      }

      state.deliveriesLoading = false;
    });

    builder.addCase(getDeliveries.fulfilled, (state, action) => {
      const allDeliveries = action.payload.fetchedDeliveries;
      const allRoutes = action.payload.fetchedRoutes;

      const carModelsOptionsSet = new Set<Delivery['CarModel']>();
      const statusOptionsSet = new Set<Delivery['TransactionStatus']>();

      allDeliveries.forEach((g) => {
        if (g.CarModel?.length) {
          carModelsOptionsSet.add(g.CarModel);
        }
        if (g.TransactionStatus?.length) {
          statusOptionsSet.add(g.TransactionStatus);
        }
      });

      const filterOptions: Array<DeliveryFilterOption> = [
        { label: 'Vehicle', key: 'CarModel', options: [...carModelsOptionsSet] },
        { label: 'Delivery Status', key: 'TransactionStatus', options: [...statusOptionsSet] },
      ];

      const { filteredRoutes, filteredDeliveries } = searchAndFilter(
        allDeliveries,
        state.filterSelections,
        state.filterQuery,
        allRoutes,
        state.timeWindowForFilter
      );

      const dataHasChanged = !isEqual(state.deliveries, allDeliveries) || !isEqual(state.routes, allRoutes);

      if (dataHasChanged) {
        state.deliveries = allDeliveries;
        state.routes = allRoutes;
        state.filterOptions = filterOptions;
        state.displayRoutes = filteredRoutes;
        state.displayDeliveries = filteredDeliveries;
        state.selectedDeliveries = allDeliveries.filter(
          (d) =>
            filteredDeliveries.some((dd) => dd.ShipmentId === d.ShipmentId) &&
            state.selectedDeliveries.some((dd) => dd.ShipmentId === d.ShipmentId)
        );
      }
    });

    builder.addCase(setDeliveryFilterQuery, (state, action) => {
      state.filterQuery = action.payload;
      const { filteredRoutes, filteredDeliveries } = searchAndFilter(
        state.deliveries,
        state.filterSelections,
        state.filterQuery,
        state.routes,
        state.timeWindowForFilter
      );
      state.displayRoutes = filteredRoutes;
      state.routes = filteredRoutes;
      state.displayDeliveries = filteredDeliveries;
      state.selectedDeliveries = state.selectedDeliveries.filter((d) =>
        state.displayDeliveries.some((dd) => dd.ShipmentId === d.ShipmentId)
      );
    });

    builder.addCase(applyDeliveryFilters, (state, action) => {
      const { filters: filterSelections, timeWindow: timeWindowForFilter } = action.payload;
      state.timeWindowForFilter = timeWindowForFilter ?? [];
      state.filterSelections = filterSelections;
      const { filteredRoutes, filteredDeliveries } = searchAndFilter(
        state.deliveries,
        state.filterSelections,
        state.filterQuery,
        state.routes,
        state.timeWindowForFilter
      );
      state.displayRoutes = filteredRoutes;
      state.displayDeliveries = filteredDeliveries;
      state.selectedDeliveries = state.selectedDeliveries.filter((d) =>
        state.displayDeliveries.some((dd) => dd.ShipmentId === d.ShipmentId)
      );
    });

    builder.addCase(toggleSelectedDelivery, (state, action) => {
      if (action.payload.length > 0) {
        const clickedDeliveries = new Set(action.payload.map((d) => d.ShipmentId));
        const selectedDeliveryIds = new Set(state.selectedDeliveries.map((d) => d.ShipmentId));
        if (action.payload.every((d) => selectedDeliveryIds.has(d.ShipmentId))) {
          state.selectedDeliveries = state.deliveries.filter(
            (d) => selectedDeliveryIds.has(d.ShipmentId) && !clickedDeliveries.has(d.ShipmentId)
          );
        } else {
          state.selectedDeliveries = state.deliveries.filter(
            (d) => selectedDeliveryIds.has(d.ShipmentId) || clickedDeliveries.has(d.ShipmentId)
          );
        }
      }
    });

    builder.addCase(selectDeliveries, (state, action) => {
      state.selectedDeliveries = action.payload;
    });

    builder.addCase(setMultipleReadyForDelivery.pending, (state, action) => {
      state.updatingDeliveryManifests = true;
    });

    builder.addCase(setMultipleReadyForDelivery.fulfilled, (state, action) => {
      state.updatingDeliveryManifests = false;
    });

    builder.addCase(setMultipleReadyForDelivery.rejected, (state, action) => {
      state.updatingDeliveryManifests = false;
    });

    builder.addCase(setRouteOrder.pending, (state, action) => {
      const shipIds = action.meta.arg.ShipmentIds;
      for (const deliv of state.displayDeliveries) {
        if (deliv.DeliveryRouteId === action.meta.arg.DeliveryRouteId) {
          const index = shipIds.findIndex((x) => x === deliv.ShipmentId);
          deliv.ManifestStopNumber = index === -1 ? undefined : index + 1;
        }
      }
    });

    //when the user reorders a route, mark the directions as out of date (if UsePlaceholderDirections FF is disabled)
    builder.addCase(setRouteOrder.fulfilled, (state, action) => {
      const { UsePlaceholderDirections, DeliveryRouteId } = action.meta.arg;

      if (!UsePlaceholderDirections && DeliveryRouteId) {
        state.routesWithDirtyDirections.push(DeliveryRouteId);
      }
    });

    builder.addCase(selectRoute.fulfilled, (state, action) => {
      state.selectedRoute = action.payload.route;
      state.selectedDeliveries = action.payload.routeDeliveries;
    });

    builder.addCase(setDisableAutorefresh, (state, action) => {
      state.disableAutoRefresh = action.payload;
    });

    builder.addCase(moveRoute.pending, (state, action) => {
      const { DeliveryRouteId, ShipmentIds } = action.meta.arg;
      const shipmentIdSet = new Set(ShipmentIds);
      state.displayDeliveries = state.displayDeliveries.map((d) =>
        shipmentIdSet.has(d.ShipmentId) ? { ...d, DeliveryRouteId, ManifestStopNumber: undefined } : d
      );
    });

    //when the user adds an order to a route, mark the directions as out of date (if UsePlaceholderDirections FF is disabled)
    builder.addCase(moveRoute.fulfilled, (state, action) => {
      const { UsePlaceholderDirections, DeliveryRouteId } = action.meta.arg;

      if (!UsePlaceholderDirections && DeliveryRouteId) {
        state.routesWithDirtyDirections.push(DeliveryRouteId);
      }
    });

    //these two mark the directions as up-to-date if directions are fetched
    builder.addCase(getRouteDirections.fulfilled, (state, action) => {
      const routeId = action.meta.arg.DeliveryRouteId;
      if (routeId) {
        state.routesWithDirtyDirections = state.routesWithDirtyDirections.filter((x) => x !== routeId);
      }
    });

    builder.addCase(getOptimizedRouteDirections.fulfilled, (state, action) => {
      const routeId = action.meta.arg.DeliveryRouteId;
      if (routeId) {
        state.routesWithDirtyDirections = state.routesWithDirtyDirections.filter((x) => x !== routeId);
      }
    });

    builder.addCase(setExpandedRoute, (state, action) => {
      state.expandedRoute = action.payload;
    });

    builder.addCase(setMapsOpen, (state, action) => {
      state.mapsOpen = action.payload;
    });

    builder.addCase(setRouteToCancel, (state, action) => {
      state.routeToCancel = action.payload;
    });

    builder.addCase(setRouteToStart, (state, action) => {
      state.routeToStart = action.payload;
    });

    builder.addCase(setRouteToSuspend, (state, action) => {
      state.routeToSuspend = action.payload;
    });
  },
});
