import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { Transaction } from 'models/Transactions';
import { warningNotification } from 'store/actions/NotificationsActions';
import { callback1 } from 'models/Misc';
import { useTransactionsPopups } from 'components/TransactionsPopups';
import { getDeliveryImageForShipment } from 'store/actions/DeliveryActions';
import { Table, Column, WithPossibleAlert, StyleVariant } from 'components/tables';
import { SplitHeaderCell } from 'components/tables/Table';
import { ConfirmationPopup } from 'components/popups';
import { Flex } from 'components/layout';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { ReactComponent as ElipsisIcon } from 'assets/icons/elipsis-icon.svg';
import { useIsSalesHistory } from 'pages/TransactionsPage/hooks/useIsSalesHistory/useIsSalesHistory';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

const TableWrapper = styled.div`
  margin: 2em 0;
  overflow-x: scroll;
`;

const Photo = styled.img`
  max-height: 250px;
  object-fit: contain;
`;

const PhotoWrapper = styled(Flex)`
  height: 300px;
`;

type TransactionsListTableProps = {
  transactions: WithPossibleAlert<Transaction>[];
  setSelectedTransaction: callback1<Transaction>;
  setIsTransactionVoided: (transaction?: Transaction) => void;
};

const largeValueFormattedDiv = (value?: string) => {
  return (
    <div title={value} style={{ overflowWrap: 'anywhere', paddingRight: '10px' }}>
      {(value?.length ?? 0) > 135 ? `${value?.substring(0, 135)}...` : value}
    </div>
  );
};

export const TransactionsListTable: FC<TransactionsListTableProps> = ({
  transactions,
  setSelectedTransaction,
  setIsTransactionVoided,
}) => {
  const dispatch = useDispatch();
  const transactionsPopups = useTransactionsPopups();
  const history = useHistory();
  const settings = useSelector((state: State) => state.settings);
  const [driverImage, setDriverImage] = useState<String | undefined>(undefined);
  const transactionPopups = useTransactionsPopups();
  const isSalesHistory = useIsSalesHistory();
  const { previewManifest } = usePrintJob();

  const tableColumns: Column<Transaction>[] = [
    {
      header: '',
      disableSort: true,
      Cell: ({ item }) => (
        <OverflowMenu
          anchor={<ElipsisIcon />}
          anchorProps={{
            padding: '.5rem 0',
            showCursor: true,
          }}
          menuProps={{
            fontSize: '1rem',
            textAlign: 'center',
          }}
          automationId={'transaction-list_context_menu'}
          menuOptions={[
            {
              text: 'Details',
              onClick: () =>
                isSalesHistory
                  ? history.push(`/saleshistory?transactionId=${item.PosId}`)
                  : history.push(`/transactions?transactionId=${item.PosId}`),
            },
            {
              text: 'Activity',
              onClick: () => {
                setSelectedTransaction(item);
                transactionPopups.showTransactionPopup(item);
              },
            },
            {
              text: 'Void',
              onClick: () => {
                if (item.isReturn) {
                  dispatch(warningNotification('Return Transaction cannot be voided.'));
                  return;
                } else if (item.hasReturn) {
                  dispatch(warningNotification('Transaction contains a returned item and cannot be voided.'));
                  return;
                }
                setSelectedTransaction(item);
                setIsTransactionVoided(item);
              },
              disabled: !!item.isCanceled,
            },
            {
              text: 'Payment',
              onClick: () => {
                setSelectedTransaction(item);
                if (item.isVoided) {
                  dispatch(warningNotification('Payment Type cannot be changed for a Voided Transaction.'));
                } else if (item.hasAdjustment) {
                  dispatch(
                    warningNotification(
                      'Payment Type cannot be changed because another Change Payment adjustment has been made for this transaction.  Please void the other adjustment first.'
                    )
                  );
                } else {
                  transactionsPopups.showChangePaymentTypePopup(item);
                }
              },
              disabled: !!item.isCanceled,
            },
            {
              text: 'Download manifest',
              onClick: () => {
                previewManifest({ PosId: item.PosId });
              },
              disabled: !item.ManifestId,
            },
            {
              text: 'View photo',
              onClick: () => {
                showVerificationImage({ ShipmentId: item.PosId });
              },
              disabled: !item.ManifestId,
            },
          ]}
        />
      ),
    },
    ...(isSalesHistory ? ([{ header: 'Customer Name', field: 'PatientName' }] as Column<Transaction>[]) : []),
    { header: 'Receipt No', field: 'PosId', Cell: ({ value }) => largeValueFormattedDiv(value) },
    {
      header: 'Date',
      field: 'PosDate',
      Cell: ({ item, value }) => {
        const date = item.isCanceled && item.CancelDate ? item.CancelDate : value;
        return <>{format(new Date(date), 'MM/dd/yyyy')}</>;
      },
    },
    { header: 'Order Source', field: 'OrderSource' },
    { header: 'Order Type', field: 'OrderType' },
    { header: 'Total Items', field: 'PosTotalItems' },
    { header: 'Total Cost', field: 'PosProductCost', Cell: ({ value }) => largeValueFormattedDiv(value) },
    { header: 'Discount', field: 'DiscountAmt' },
    {
      header: <SplitHeaderCell line1='Fees/Donations' line2='(Revenue)' />,
      field: 'RevenueCashValue',
      Cell: ({ value }) => largeValueFormattedDiv(Math.abs(Number(value)).toString()),
    },
    {
      header: <SplitHeaderCell line1='Fees/Donations' line2='(Non-Revenue)' />,
      field: 'NonRevenueCashValue',
      Cell: ({ value }) => largeValueFormattedDiv(Math.abs(Number(value)).toString()),
    },
    { header: 'Tax', field: 'PosTax', Cell: ({ value }) => largeValueFormattedDiv(value) },
    ...(settings.integrations?.UseJory ? ([{ header: 'tip', field: 'TipAmount' }] as Column<Transaction>[]) : []),
    { header: 'Total Invoice', field: 'TotalInvoice', Cell: ({ value }) => largeValueFormattedDiv(value) },
    { header: 'Payment', field: 'PosPaymentType' },
    { header: 'Customer Type', field: 'CustomerType' },
    {
      header: 'Status',
      field: 'PosStatus',
      Cell: ({ item, value }) => largeValueFormattedDiv(item.isCanceled ? 'Canceled' : value),
    },
    { header: 'Register', field: 'Register' },
    { header: 'Sold By', field: 'TransactionBy' },
    { header: 'Location', field: 'Location' },
    ...(settings.features.CustomerVisitReason
      ? ([
          {
            header: 'Visit Reason',
            field: 'VisitReason',
          },
        ] as Column<Transaction>[])
      : []),
    ...(settings.features.ShowLeafLogixLoyalty || settings.features.ShowExternalLoyalty
      ? ([
          {
            header: 'Loyalty Used',
            field: 'LoyaltyUsed',
            Cell: ({ value }) => largeValueFormattedDiv(Number(value).toFixed(2).toString()),
          },
          {
            header: 'Loyalty Earned',
            field: 'LoyaltyEarned',
            Cell: ({ value }) => largeValueFormattedDiv(Number(value).toFixed(2).toString()),
          },
        ] as Column<Transaction>[])
      : []),
    ...(settings.features.UseBioTrack
      ? ([
          {
            header: 'BioTrack Start',
            field: 'BiotStartAvail',
            Cell: ({ value }) => largeValueFormattedDiv(Number(value).toFixed(2).toString()),
          },
          {
            header: 'BioTrack Used',
            field: 'BiotUsed',
            Cell: ({ value }) => largeValueFormattedDiv(Number(value).toFixed(2).toString()),
          },
          {
            header: 'BioTrack End',
            field: 'BiotEndAvail',
            Cell: ({ value }) => largeValueFormattedDiv(Number(value).toFixed(2).toString()),
          },
        ] as Column<Transaction>[])
      : []),
    ...(settings.features.EducationalMaterialsAcceptReject || settings.features.EducationalMaterialsAcceptOnly
      ? ([
          {
            header: 'Educational Materials',
            field: 'EducationalMaterialsAccepted',
            Cell: ({ value }) => (isNil(value) ? null : value ? 'Accepted' : 'Rejected'),
          },
        ] as Column<Transaction>[])
      : []),
  ];

  const sortedTransactions = transactions?.concat().sort((a, b) => Date.parse(b.PosDate) - Date.parse(a.PosDate));

  const showVerificationImage = async (args: { ShipmentId: number }) => {
    const image = await getDeliveryImageForShipment({ ShipmentId: args.ShipmentId });
    if (image) {
      setDriverImage(image);
    } else {
      dispatch(warningNotification('No image available'));
    }
  };

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <>
      <TableWrapper>
        <Table<Transaction>
          data={sortedTransactions}
          pageSize={10}
          columns={tableColumns}
          styleVariant={StyleVariant.middleAlign}
        />
      </TableWrapper>
      <ConfirmationPopup
        title='View Photo'
        isVisible={!!driverImage}
        hide={() => setDriverImage(undefined)}
        buttonsSectionVisible
      >
        <PhotoWrapper justifyContent='center' alignItems='center' width={isModalBridgeEnabled ? '100%' : '648px'}>
          <Photo alt='Drivers License' src={`data:image/jpeg;base64,${driverImage}`} />
        </PhotoWrapper>
      </ConfirmationPopup>
    </>
  );
};
