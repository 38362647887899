import { ValidatedField } from 'models/Settings';
import type { FormSchema } from './types';

export const requiredField = (field: FormSchema, required: boolean) => {
  return required ? field.required() : field.nullable();
};

export const invalidValue = (requiredFieldConfig: ValidatedField, param: string): string[] => {
  return requiredFieldConfig?.[param]?.Required ?? false ? ['N/A'] : [];
};
