import * as Cookies from 'es-cookie';
import { Storage } from '@capacitor/storage';
import {
  HardwareLogEvent,
  HardwareService,
  PeripheralService,
  LabelPrinter,
  ReceiptPrinter,
  Scale,
  KeyboardScaleService,
  Scanner,
  KeyboardScannerService,
  PeripheralServiceManager,
  Peripheral,
  UsbLabelPrinterService,
  UsbReceiptPrinterService,
  SerialScaleService,
  PeripheralServiceConfig,
  HidScannerService,
  isKeyboardScale,
  isKeyboardScanner,
  WebviewAppLabelPrinterService,
  WebviewAppReceiptPrinterService,
  WebviewAppScaleService,
  WebviewAppScannerService,
} from '@dutchie/capacitor-hardware';
import {
  NativeLabelPrinterService,
  NativeReceiptPrinterService,
  NativeScaleService,
  NativeScannerService,
  isNativeLabelPrinter,
  isNativeReceiptPrinter,
} from '@dutchie/capacitor-peripheral';

import { getStoredHardware, peripheralInfo } from 'util/hardwareLibrary/hardware-library-utils';
import { store, State, AppDispatch } from 'store';
import { customEventKeys, logger } from 'util/logger';
import { PrinterSelection, RegisterResponse, UserSettings } from 'models/Misc';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { SettingsState } from 'store/reducers/SettingsReducer';
import { getIsHardwareLibraryActive } from 'util/hooks/launch-darkly/useHardwareLibrary';
import { isPrintNodeReceiptPrinter, PrintNodeReceiptPrinterService } from 'hardware/receipt-printer';
import { isPrintNodeLabelPrinter, PrintNodeLabelPrinterService } from 'hardware/label-printer';
import { isAndroid, isWebViewApp } from 'util/hooks';
import {
  FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS,
  LABEL_PRINTER_LOCAL_STORAGE_KEYS,
  RECEIPT_PRINTER_LOCAL_STORAGE_KEYS,
} from 'util/local-printing';
import { HardwareLibraryStorageManager } from './hardware-library-storage';
import { MockLabelPrinterService } from 'hardware/label-printer/mock/MockLabelPrinterService';
import { MockReceiptPrinterService } from 'hardware/receipt-printer/mock/MockReceiptPrinterService';
import { MockScannerService } from 'hardware/scanner/mock/MockScannerService';
import { MockScaleService } from 'hardware/scale/mock/MockScaleService';
import { getUseNewSettingsUi } from 'util/hooks/launch-darkly/useNewSettingsUi';

type HardwareLibraryLoadingState = 'idle' | 'initializing' | 'disposing' | 'loaded';

export class HardwareManager {
  private dispatch: AppDispatch;
  private storage: HardwareLibraryStorageManager;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.storage = new HardwareLibraryStorageManager(dispatch);
  }

  loadingState: HardwareLibraryLoadingState = 'idle';

  private get isE2E() {
    return Cookies.get('isE2ENetworkRequest') === 'true';
  }

  private get reduxLabelPrinter(): PrinterSelection | undefined {
    return this.settings.userSettings.selectedLabelPrinter;
  }

  private get reduxReceiptPrinter(): PrinterSelection | undefined {
    return this.settings.userSettings.selectedReceiptPrinter;
  }

  private get reduxFulfillmentPrinter(): PrinterSelection | undefined {
    return this.settings.userSettings.selectedFulfillmentPrinter;
  }

  // Get register details containing default printer ids
  private get selectedRegisterDetails(): RegisterResponse | undefined {
    const { registers, selectedRegister } = this.settings;
    return registers.find((it) => it.id === selectedRegister?.value);
  }

  private get settings(): SettingsState {
    const state = store.getState() as State;
    return state.settings;
  }

  private debug = (msg: string, obj?: object): void => {
    const { fulfillmentPrinter, labelPrinter, receiptPrinter } = getStoredHardware();

    const data = {
      key: customEventKeys.hardwareLibrary.verboseLog,
      isLDFlagEnabled: getIsHardwareLibraryActive(),
      ...(obj ?? {}),
      ...{
        peripherals: {
          fulfillmentPrinter: fulfillmentPrinter ? peripheralInfo(fulfillmentPrinter) : undefined,
          labelPrinter: labelPrinter ? peripheralInfo(labelPrinter) : undefined,
          receiptPrinter: receiptPrinter ? peripheralInfo(receiptPrinter) : undefined,
        },
      },
    };

    logger.debug(`[HW] ${msg}`, { key: customEventKeys.hardwareLibrary.debugEvent, details: data });
  };

  dispose = async (): Promise<void> => {
    if (this.loadingState !== 'loaded') {
      return;
    }

    try {
      this.debug('start disposeHardwareLibrary');

      // Mark disposing to avoid being called again
      this.loadingState = 'disposing';

      // Unregister device listeners
      HardwareService.labelPrinter.removeEventListener('devices-updated', this.handleLabelPrinterUpdateEvent);
      HardwareService.receiptPrinter.removeEventListener('devices-updated', this.handleReceiptPrinterUpdateEvent);
      HardwareService.scale.removeEventListener('devices-updated', this.handleScaleUpdateEvent);
      HardwareService.scanner.removeEventListener('devices-updated', this.handleScannerUpdateEvent);

      this.debug('removed devices-updated event listeners');

      // Set data back to its old state
      if (!getIsHardwareLibraryActive()) {
        await this.revertToReduxState();
      }

      // Dispose service which clears all device lists
      HardwareService.dispose();
      HardwareService.logger.removeEventListener(this.handleHardwareLog);
      this.debug('disposed hardware library');
    } catch (e) {
      logger.error(e, { message: 'Hardware Library failed to dispose' });
    } finally {
      this.info('disposed hardware library', customEventKeys.hardwareLibrary.revertPeripheralMigration);
      this.loadingState = 'idle';
    }
  };

  private handleHardwareLog = (event: Event) => {
    const evt = event as CustomEvent<HardwareLogEvent>;
    const { detail } = evt;
    const data = { key: 'hardware-service-log', ...detail };

    switch (detail.level) {
      case 'debug':
        // Do not log messages from scale events to prevent spamming Datadog
        if (data.details?.measurement) {
          return;
        }
        logger.debug(detail.message, data);
        break;
      case 'error':
        logger.error(detail.error, data);
        break;
      case 'info':
        logger.info(detail.message, data);
        break;
      case 'warn':
        logger.warn(detail.message, data);
        break;
    }
  };

  private handleLabelPrinterUpdateEvent = (_: Event) => this.setDefaultLabelPrinter();

  private handleReceiptPrinterUpdateEvent = async (_: Event) => {
    this.setDefaultReceiptPrinters();

    if (isWebViewApp) {
      // We must connect to the receipt printer to support Zebra scanners connected to it via USB
      const { receiptPrinter } = getStoredHardware();
      if (receiptPrinter && !receiptPrinter.isConnected) {
        await receiptPrinter.connect();
      }
    }
  };

  private handleScaleUpdateEvent = async (_: Event) => {
    // ensure a preferred stored value exists
    const scales = HardwareService.scale.devices;

    // Ensure a default is set
    if (!this.storage.scaleId) {
      const defaultScale = scales.find((it) => !isKeyboardScale(it));
      const fallbackScaleId = isAndroid ? null : scales[0]?.id ?? 'keyboard-scale';
      this.storage.scaleId = defaultScale?.id ?? fallbackScaleId;
    }

    const { scale } = getStoredHardware();
    if (scale && !scale.isConnected) {
      await scale.connect();
    }
  };

  private handleScannerUpdateEvent = async (_: Event) => {
    // ensure a preferred stored value exists
    const scanners = HardwareService.scanner.devices;

    // Ensure a default is set
    if (!this.storage.scannerId) {
      const defaultScanner = scanners.find((it) => !isKeyboardScanner(it));
      // Do not set a default value for Android. The native layer auto-connects to all peripherals
      // and should be the preferred value if none exists.
      const fallbackScannerId = isAndroid ? null : scanners[0]?.id ?? 'keyboard-scanner';
      this.storage.scannerId = defaultScanner?.id ?? fallbackScannerId;
    }

    const { scanner } = getStoredHardware();
    if (scanner && !scanner.isConnected) {
      await scanner.connect();
    }
  };

  private info = (msg: string, key: string, obj?: object): void => {
    logger.info(`[HW] ${msg}`, { key, ...(obj ?? {}) });
  };

  private registerOrInvalidateManagerServices = async <T extends Peripheral>(
    mgr: PeripheralServiceManager<T>,
    services: PeripheralService<T>[]
  ): Promise<void> => {
    const promises = services.map((service) => {
      if (mgr.state.find((it) => it.service.name === service.name)?.enabled === true) {
        return mgr.invalidate(service.name);
      }
      return mgr.register(service);
    });
    await Promise.all(promises);
  };

  // Register services with no dependencies on user data
  private registerServices = async () => {
    const isNewSettingsUi = getUseNewSettingsUi();
    const labelPrinterServices: PeripheralService<LabelPrinter>[] = [
      ...(isAndroid ? [new NativeLabelPrinterService()] : []),
      ...(isNewSettingsUi ? [new UsbLabelPrinterService()] : []),
      ...(isWebViewApp ? [new WebviewAppLabelPrinterService()] : []),
    ];

    const receiptPrinterServices: PeripheralService<ReceiptPrinter>[] = [
      ...(isAndroid ? [new NativeReceiptPrinterService()] : []),
      ...(isNewSettingsUi ? [new UsbReceiptPrinterService()] : []),
      ...(isWebViewApp ? [new WebviewAppReceiptPrinterService()] : []),
    ];

    // Backward compatible hardware library enables the keyboard services by default.
    // With new settings UI, only the preferred device should be listened to so auto-connect is disabled
    const noAutoConnectWithSettingsUi: Partial<PeripheralServiceConfig> = isNewSettingsUi ? { autoConnect: false } : {};

    const scaleServices: PeripheralService<Scale>[] = [
      new KeyboardScaleService({ service: noAutoConnectWithSettingsUi }),
      ...(isAndroid ? [new NativeScaleService()] : []), // Android connects natively and would require an update
      ...(isNewSettingsUi ? [new SerialScaleService(noAutoConnectWithSettingsUi)] : []),
      ...(isWebViewApp ? [new WebviewAppScaleService()] : []),
    ];

    // Initialize hardware service scanner. Hardware library begins listening to keypress
    // events when registered and will conflict with the existing ReactBarcodeReader
    // logic since they each cancel the keypress event propagation.
    const scannerServices: PeripheralService<Scanner>[] = [
      // 250ms matches current keypress timeout. A timeout of 100ms (service default) has worked well while testing
      // the library with the hope it could be lowered further to avoid conflicts with normal user input. I have
      // confirmed Android can take even longer than 400ms at times which causes the barcode to be emitted in two parts.
      // For this reason, the hardware library supports a configurable timeout. Android devices experiencing this issue
      // should use the USB implementation, but we have many devices that have not completed setup and emit keyboard events.
      new KeyboardScannerService({
        buffer: { timeout: isAndroid ? 500 : 250, maxAverageInterval: 75 },
        service: noAutoConnectWithSettingsUi,
      }),
      ...(isAndroid ? [new NativeScannerService()] : []),
      ...(isNewSettingsUi ? [new HidScannerService(noAutoConnectWithSettingsUi)] : []),
      ...(isWebViewApp ? [new WebviewAppScannerService()] : []),
    ];

    // Wait until each service is registered which includes searching for devices from supported services
    await Promise.all([
      this.registerOrInvalidateManagerServices(HardwareService.labelPrinter, labelPrinterServices),
      this.registerOrInvalidateManagerServices(HardwareService.receiptPrinter, receiptPrinterServices),
      this.registerOrInvalidateManagerServices(HardwareService.scale, scaleServices),
      this.registerOrInvalidateManagerServices(HardwareService.scanner, scannerServices),
    ]);
  };

  // Handle registering and unregistering services based on settings-ui flag state.
  // Already registered services will just be ignored
  registerBrowserApiServices = (settingsUiEnabled: boolean) => {
    const usbLabelPrinterService = new UsbLabelPrinterService();
    const usbReceiptPrinterService = new UsbReceiptPrinterService();
    const serialScaleService = new SerialScaleService({ autoConnect: false });
    const hidScannerService = new HidScannerService({ autoConnect: false });

    if (settingsUiEnabled) {
      logger.debug('Register browser API services', { key: customEventKeys.hardwareLibrary.debugEvent });
      HardwareService.labelPrinter.register(usbLabelPrinterService);
      HardwareService.receiptPrinter.register(usbReceiptPrinterService);
      HardwareService.scale.register(serialScaleService);
      HardwareService.scanner.register(hidScannerService);
    } else {
      // Unregistering uses service name and does not need to match the instance
      logger.debug('Unregister browser API services', { key: customEventKeys.hardwareLibrary.debugEvent });
      HardwareService.labelPrinter.unregister(usbLabelPrinterService);
      HardwareService.receiptPrinter.unregister(usbReceiptPrinterService);
      HardwareService.scale.unregister(serialScaleService);
      HardwareService.scanner.unregister(hidScannerService);
    }
  };

  private revertToReduxState = async () => {
    this.debug('start revertToReduxState');
    // Revert data back to redux and remove values from local storage
    // Track which printers need reverted
    const revertData: Partial<UserSettings> = {};

    // Get new values from storage
    const { fulfillmentPrinter, labelPrinter, receiptPrinter } = getStoredHardware();
    if (fulfillmentPrinter) {
      revertData.selectedFulfillmentPrinter = {
        PrinterId: isPrintNodeReceiptPrinter(fulfillmentPrinter)
          ? fulfillmentPrinter.printNodeId
          : fulfillmentPrinter.id,
        Name: fulfillmentPrinter.name,
        LocalPrinter: isNativeReceiptPrinter(fulfillmentPrinter),
        Status: '',
      };
    }

    if (labelPrinter) {
      revertData.selectedLabelPrinter = {
        PrinterId: isPrintNodeLabelPrinter(labelPrinter) ? labelPrinter.printNodeId : labelPrinter.id,
        Name: labelPrinter.name,
        LocalPrinter: isNativeLabelPrinter(labelPrinter),
        Status: '',
      };
    }

    if (receiptPrinter) {
      revertData.selectedReceiptPrinter = {
        PrinterId: isPrintNodeReceiptPrinter(receiptPrinter) ? receiptPrinter.printNodeId : receiptPrinter.id,
        Name: receiptPrinter.name,
        LocalPrinter: isNativeReceiptPrinter(receiptPrinter),
        Status: '',
      };
    }

    // Sync reverted data with Android storage
    if (isAndroid) {
      if (revertData.selectedFulfillmentPrinter) {
        await Storage.set({
          key: FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT,
          value: JSON.stringify(revertData.selectedFulfillmentPrinter),
        });
      }
      if (revertData.selectedLabelPrinter) {
        await Storage.set({
          key: LABEL_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT,
          value: JSON.stringify(revertData.selectedLabelPrinter),
        });
      }
      if (revertData.selectedReceiptPrinter) {
        await Storage.set({
          key: RECEIPT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT,
          value: JSON.stringify(revertData.selectedReceiptPrinter),
        });
      }
    }

    this.info(`revertToReduxState complete`, customEventKeys.hardwareLibrary.revertPeripheralMigration, { revertData });

    // Set Redux values
    this.dispatch(saveUserSettings(revertData));
  };

  // Select label printer defined by register or continue using existing stored value
  // when that printer is still available
  private setDefaultLabelPrinter = async () => {
    // Determine the target label printer id in order of priority
    // 1. On Android, use the stored values from Capacitor's storage object
    // 2. Local storage value value with new key if it has been set
    // 3. Register's assigned PrintNode printer from back-office
    // 4. Selected label printer from Redux for mid-session initialization if flag is flipped
    let targetLabelPrinterId: string | undefined;
    if (isAndroid) {
      const labelPrinterDetails = await Storage.get({ key: LABEL_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT });
      if (labelPrinterDetails.value !== null) {
        targetLabelPrinterId = JSON.parse(labelPrinterDetails.value)?.PrinterId?.toString() || targetLabelPrinterId;
      }
    }
    targetLabelPrinterId = targetLabelPrinterId ?? this.selectedRegisterDetails?.LabelPrinterId?.toString();

    // Update value in local storage. Only replace PrintNode printers. This enables local printer selections to persist.
    if (
      targetLabelPrinterId &&
      targetLabelPrinterId !== this.storage.labelPrinterId &&
      (!this.storage.labelPrinterId || this.storage.labelPrinterId.startsWith('pn-'))
    ) {
      const matchingPrinter = HardwareService.labelPrinter.devices.find(
        (it) =>
          it.id === targetLabelPrinterId ||
          (isPrintNodeLabelPrinter(it) && it.printNodeId.toString() === targetLabelPrinterId)
      );

      if (matchingPrinter && matchingPrinter.id !== this.storage.labelPrinterId) {
        this.storage.labelPrinterId = matchingPrinter.id;

        this.debug('set label printer', {
          id: this.storage.labelPrinterId,
          targetId: targetLabelPrinterId,
        });
      }
    }
  };

  // Select receipt printers defined by register or continue using existing stored value
  // when that printer is still available
  private setDefaultReceiptPrinters = async () => {
    // Determine the target receipt printer id in order of priority
    // 1. On Android, use the stored values from Capacitor's storage object
    // 2. Register's assigned PrintNode printer from back-office
    let targetFulfillmentPrinterId: string | undefined;
    let targetReceiptPrinterId: string | undefined;

    if (isAndroid) {
      const fulfillmentDetails = await Storage.get({ key: FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT });
      if (fulfillmentDetails.value !== null) {
        targetFulfillmentPrinterId =
          JSON.parse(fulfillmentDetails.value)?.PrinterId?.toString() || targetFulfillmentPrinterId;
      }

      const receiptDetails = await Storage.get({ key: RECEIPT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT });
      if (receiptDetails.value !== null) {
        targetReceiptPrinterId = JSON.parse(receiptDetails.value)?.PrinterId?.toString() || targetReceiptPrinterId;
      }
    }

    targetFulfillmentPrinterId =
      targetFulfillmentPrinterId ?? this.selectedRegisterDetails?.FulfillmentPrinterId?.toString();

    targetReceiptPrinterId = targetReceiptPrinterId ?? this.selectedRegisterDetails?.ReceiptPrinterId?.toString();

    // Update value in local storage. Only replace PrintNode printers. This enables local printer selections to persist.
    if (
      targetFulfillmentPrinterId &&
      targetFulfillmentPrinterId !== this.storage.fulfillmentPrinterId &&
      (!this.storage.fulfillmentPrinterId || this.storage.fulfillmentPrinterId.startsWith('pn-'))
    ) {
      const matchingPrinter = HardwareService.receiptPrinter.devices.find(
        (it) =>
          it.id === targetFulfillmentPrinterId ||
          (isPrintNodeReceiptPrinter(it) && it.printNodeId.toString() === targetFulfillmentPrinterId)
      );
      if (matchingPrinter && matchingPrinter.id !== this.storage.fulfillmentPrinterId) {
        this.storage.fulfillmentPrinterId = matchingPrinter.id;

        this.debug('set fulfillment printer', {
          id: this.storage.fulfillmentPrinterId,
          targetId: targetFulfillmentPrinterId,
        });
      }
    }

    if (
      targetReceiptPrinterId &&
      targetReceiptPrinterId !== this.storage.receiptPrinterId &&
      (!this.storage.receiptPrinterId || this.storage.receiptPrinterId.startsWith('pn-'))
    ) {
      const matchingPrinter = HardwareService.receiptPrinter.devices.find(
        (it) =>
          it.id === targetReceiptPrinterId ||
          (isPrintNodeReceiptPrinter(it) && it.printNodeId.toString() === targetReceiptPrinterId)
      );
      if (matchingPrinter && matchingPrinter.id !== this.storage.receiptPrinterId) {
        this.storage.receiptPrinterId = matchingPrinter.id;

        this.debug('set receipt printer', {
          id: this.storage.receiptPrinterId,
          targetId: targetReceiptPrinterId,
        });
      }
    }
  };

  initialize = async (): Promise<void> => {
    if (this.loadingState !== 'idle') {
      return;
    }

    try {
      this.debug('start initializeHardwareLibrary');
      this.loadingState = 'initializing';

      // Listen for hardware service log events and forward to logger
      HardwareService.logger.addEventListener(this.handleHardwareLog);

      // Listen for changes to available device list
      HardwareService.labelPrinter.addEventListener('devices-updated', this.handleLabelPrinterUpdateEvent);
      HardwareService.receiptPrinter.addEventListener('devices-updated', this.handleReceiptPrinterUpdateEvent);
      HardwareService.scale.addEventListener('devices-updated', this.handleScaleUpdateEvent);
      HardwareService.scanner.addEventListener('devices-updated', this.handleScannerUpdateEvent);

      // Register services which will execute the initial device search
      await this.registerServices();
    } catch (e) {
      logger.error('hardware library failed to initialize', { error: e });
    } finally {
      this.info('initialized hardware library', customEventKeys.hardwareLibrary.initializeEnd);
    }

    this.loadingState = 'loaded';
  };

  invalidatePrintNodeServices = async () => {
    this.debug('invalidate print node services');
    // Force library to replace peripherals that are tied to location data in back-office.
    await Promise.all([
      HardwareService.labelPrinter.invalidate(PrintNodeLabelPrinterService.serviceName),
      HardwareService.receiptPrinter.invalidate(PrintNodeReceiptPrinterService.serviceName),
    ]);
  };

  registerPrintNodeServices = async () => {
    this.debug('register print node services');

    // Register PrintNode services which will execute the initial device search and emit device status events.
    // [handleXUpdateEvent] methods will set default printers based on the register settings.
    await Promise.all([
      this.registerOrInvalidateManagerServices(HardwareService.labelPrinter, [new PrintNodeLabelPrinterService()]),
      this.registerOrInvalidateManagerServices(HardwareService.receiptPrinter, [new PrintNodeReceiptPrinterService()]),
    ]);

    if (this.isE2E) {
      await Promise.all([
        // Register mock services after the register details are available, when non-E2E would load PrintNode services.
        // This supports mocking a default printer in back-office and sets local storage if the mocked printer id matches.
        HardwareService.labelPrinter.register(new MockLabelPrinterService()),
        HardwareService.receiptPrinter.register(new MockReceiptPrinterService()),
        // Scales and scanners are registered with printers for test consistency, though immediate registration is possible.
        HardwareService.scale.register(new MockScaleService()),
        HardwareService.scanner.register(new MockScannerService()),
      ]);
    }
  };

  resetDefaultPrinters = async () => {
    this.debug('reset default printers');

    await Promise.all([this.setDefaultLabelPrinter(), this.setDefaultReceiptPrinters()]);
  };
}
