import React from 'react';
import { BarcodeScanner } from './BarcodeScanner';

export const ProvideScanner = ({ children }: { children: React.ReactNode }): JSX.Element => {

  return (
    <>
      <BarcodeScanner />
      {children}
    </>
  );
};
