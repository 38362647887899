import React, { FC, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, FormikProps } from 'formik';

import { State } from 'store';
import { ConfirmationPopup } from 'components/popups';
import { CustomerDetails, EditAllotmentTypeEnum } from 'models/Customer';
import { adjustAllotment } from 'store/actions/CustomerActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { InputField, SelectField } from 'components/inputs';

const ContentDiv = styled.div`
  margin: 0 2rem;
`;

type EditAllotmentPopupProps = {
  isVisible: boolean;
  hide: () => void;
  customer: CustomerDetails;
  type: EditAllotmentTypeEnum;
};

type EditAllotmentFormValues = {
  allotment: number;
  unit: 'g' | 'oz';
  pin?: string;
};

export const EditAllotmentPopup: FC<EditAllotmentPopupProps> = ({ customer, type, isVisible, hide }) => {
  const dispatch = useDispatch();
  const pendingAllotmentAdjustment = useSelector((state: State) => state.customer.pendingAllotmentAdjustment);
  const pinRequired = useSelector((state: State) => state.settings.features.RequireManagerPasswordForAllotmentAdjust);
  const formRef = useRef<FormikProps<EditAllotmentFormValues> | null>(null);
  let initialAllotment = 0;
  switch (type) {
    case EditAllotmentTypeEnum.Max:
      initialAllotment = customer.AllotmentData?.MaxLimit;
      break;
    case EditAllotmentTypeEnum.External:
      initialAllotment = customer.AllotmentData?.ExternalDispensed;
      break;
    case EditAllotmentTypeEnum.Current:
    case EditAllotmentTypeEnum.BackfillExternal:
      initialAllotment = customer.AllotmentData?.CurrentLimit;
      break;
  }

  const ouncesToGrams = 28.3495;
  const onSubmit = async (values: EditAllotmentFormValues) => {
    const valueInGrams = values.unit === 'g' ? values.allotment : values.allotment * ouncesToGrams;
    const requestArgs = {
      PatientId: customer.Guest_id,
      pin: values.pin,
      type,
      Allotment: valueInGrams,
    };

    if (type === EditAllotmentTypeEnum.BackfillExternal) {
      const amountToChangeExternalDispensed = customer.AllotmentData?.CurrentLimit - valueInGrams;
      const newExternalDispensedAmount = customer.AllotmentData?.ExternalDispensed + amountToChangeExternalDispensed;
      if (newExternalDispensedAmount < 0) {
        dispatch(errorNotification('Cannot adjust current allotment. External dispensed amount would be negative.'));
        return Promise.reject();
      }
      requestArgs.Allotment = newExternalDispensedAmount;
    }

    await dispatch(adjustAllotment(requestArgs));
    hide();
  };

  return (
    <ConfirmationPopup
      small
      isVisible={isVisible}
      hide={hide}
      title={`Adjust ${type} Allotment`}
      confirm={{
        text: 'Save',
        disabled: pendingAllotmentAdjustment,
        onClick: () => {
          formRef.current?.handleSubmit();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <ContentDiv>
        <Formik
          initialValues={
            {
              allotment: initialAllotment,
              unit: 'g',
              pin: undefined,
            } as EditAllotmentFormValues
          }
          onSubmit={(values) => onSubmit(values)}
          innerRef={(instance) => (formRef.current = instance)}
        >
          <Form>
            <InputField
              automationId='edit-allotment-input'
              type='number'
              label='Allotment'
              name='allotment'
              placeholder='...'
              endAdornment={
                <SelectField
                  isInputAdornment={true}
                  name='unit'
                  options={[
                    { label: 'Grams', value: 'g' },
                    { label: 'Ounces', value: 'oz' },
                  ]}
                />
              }
            />
            {pinRequired && <InputField type='password' name='pin' label='Manager PIN' placeholder='Manager PIN' />}
          </Form>
        </Formik>
      </ContentDiv>
    </ConfirmationPopup>
  );
};
