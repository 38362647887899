import React from 'react';

type SwitchIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
  automationId?: string;
};

export function SwitchIcon(props: SwitchIconProps) {
  const { height = 16, width = 16, color = '#ffffff', className, automationId } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 16 16'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9544 0.28951C11.1627 0.0812309 11.5004 0.0812309 11.7086 0.28951L14.3753 2.95618C14.5836 3.16446 14.5836 3.50214 14.3753 3.71042L11.7086 6.37709C11.5004 6.58537 11.1627 6.58537 10.9544 6.37709C10.7461 6.16881 10.7461 5.83112 10.9544 5.62284L13.2439 3.3333L10.9544 1.04376C10.7461 0.835478 10.7461 0.49779 10.9544 0.28951Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.66484 3.86663C4.09905 3.86663 3.55643 4.0914 3.15635 4.49147C2.75627 4.89155 2.53151 5.43417 2.53151 5.99997V7.3333C2.53151 7.62785 2.29273 7.86663 1.99818 7.86663C1.70363 7.86663 1.46484 7.62785 1.46484 7.3333V5.99997C1.46484 5.15128 1.80199 4.33734 2.4021 3.73723C3.00222 3.13711 3.81615 2.79997 4.66484 2.79997H13.9982C14.2927 2.79997 14.5315 3.03875 14.5315 3.3333C14.5315 3.62785 14.2927 3.86663 13.9982 3.86663H4.66484Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.04197 9.62284C5.25025 9.83112 5.25025 10.1688 5.04197 10.3771L2.75242 12.6666L5.04197 14.9562C5.25025 15.1645 5.25025 15.5021 5.04197 15.7104C4.83369 15.9187 4.496 15.9187 4.28772 15.7104L1.62105 13.0438C1.41277 12.8355 1.41277 12.4978 1.62105 12.2895L4.28772 9.62284C4.496 9.41457 4.83369 9.41457 5.04197 9.62284Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9982 8.1333C14.2927 8.1333 14.5315 8.37208 14.5315 8.66663V9.99997C14.5315 10.8487 14.1944 11.6626 13.5943 12.2627C12.9941 12.8628 12.1802 13.2 11.3315 13.2H1.99818C1.70363 13.2 1.46484 12.9612 1.46484 12.6666C1.46484 12.3721 1.70363 12.1333 1.99818 12.1333H11.3315C11.8973 12.1333 12.4399 11.9085 12.84 11.5085C13.2401 11.1084 13.4648 10.5658 13.4648 9.99997V8.66663C13.4648 8.37208 13.7036 8.1333 13.9982 8.1333Z'
        fill={color}
      />
    </svg>
  );
}
