import { SectionWithHeader, Flex } from 'components/layout';
import { AvailableFeeDonation, MethodEnum } from 'models/FeeDonation';
import React, { FC } from 'react';
import styled from 'styled-components';
import { callback1 } from 'models/Misc';
import { colors } from 'css/Theme';
import { Label } from 'components/text';
import { Input, Select, TextArea } from 'components/inputs';

const StyledSectionWithHeader = styled(SectionWithHeader)`
  height: 75%;
  width: 100%;
  margin-bottom: 0.875rem;
`;

const Container = styled(Flex)`
  flex-direction: column;
  padding: 20px;
  padding-left: 27px;
  font-size: 0.875rem;
  color: ${colors.dutchie.almostBlack};
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 6px;
  height: 100%;
  gap: 12px;
`;

type SelectedFeeDonationProps = {
  selected?: AvailableFeeDonation;
  applyTo: MethodEnum;
  setApplyTo: callback1<MethodEnum>;
  calculationValue: string;
  setCalculationValue: callback1<string>;
  cashValue: string;
  setCashValue: callback1<string>;
  comment: string;
  setComment: callback1<string>;
};

export const SelectedFeeDonation: FC<SelectedFeeDonationProps> = (props) => {
  const { selected, applyTo, setApplyTo, calculationValue, cashValue, comment, setCalculationValue, setComment } =
    props;

  if (!selected) {
    return (
      <StyledSectionWithHeader title='Selected Fee / Donation' empty={true}>
        Select Available Fee or Donation
      </StyledSectionWithHeader>
    );
  }

  return (
    <StyledSectionWithHeader title='Selected Fee / Donation'>
      <Container>
        <div>
          <Label>Method : </Label>
          <Select
            placeholder='Select one'
            options={[
              { key: 1, value: 1, label: 'Flat Amount' },
              { key: 2, value: 2, label: 'Percent Of Sub-Total' },
              { key: 3, value: 3, label: 'Round Up To Next' },
            ]}
            onChange={(val) => setApplyTo(parseInt(val) as MethodEnum)}
            value={applyTo}
          />
        </div>
        <div>
          <Label>Fee/Donation Value</Label>
          <Input
            placeholder='...'
            value={calculationValue}
            data-testid='selected-fee-donation_styled-number-input_fee-donation-value'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCalculationValue(e.target.value);
            }}
          />
        </div>
        <div>
          <Label>Calculated Amount</Label>
          <Input
            disabled={true}
            placeholder='...'
            value={cashValue}
            data-testid='selected-fee-donation_styled-number-input_calculated-value'
          />
        </div>
        <div>
          <Label>Notes</Label>
          <TextArea
            data-testid='selected-fee-donation_styled-text-area_name'
            name='comment'
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            placeholder='Comment'
          />
        </div>
      </Container>
    </StyledSectionWithHeader>
  );
};
