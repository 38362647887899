import { useDispatch } from 'react-redux';
import { round } from 'lodash';

import { showLoyaltyPanel } from 'store/actions/CartActions';
import { useRemoveDiscounts } from 'util/hooks/cart/useRemoveDiscounts';
import { sanitizeDiscountReason } from 'util/helpers/cart/sanitizeDiscountReason';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';

import { DiscountRedIcon, LoyaltyBlueIcon, DiscountLabel, LoyaltyLabel } from './Discount.styles';

import type { AppliedDiscount } from 'models/Discounts';
import type { CartItem } from 'models/Cart';

type GetDiscountAmountParams = {
  item: CartItem;
  discount: AppliedDiscount;
};

const getDiscountAmount = ({ item, discount }: GetDiscountAmountParams): string => {
  // CalculationMethodId 2 is percent off, so show % off in UI
  if (discount.CalculationMethodId === 2) {
    const discountPercentage = round((discount.DiscountAmt / item.TotalCost) * 100, 2);
    return `${discountPercentage}% off`;
  }

  // Any calculation method that is not percent should show $ off
  return `$${discount.DiscountAmt.toFixed(2)} off`;
};

type UseDiscountProps = {
  item: CartItem;
  discount: AppliedDiscount;
};

export const useDiscount = ({ item, discount }: UseDiscountProps) => {
  const dispatch = useDispatch();

  const Icon = discount.IsLoyalty ? LoyaltyBlueIcon : DiscountRedIcon;
  const Label = discount.IsLoyalty ? LoyaltyLabel : DiscountLabel;
  const discountDescription = discount.IsLoyalty
    ? `${Math.round(discount.DiscountAmt)} points`
    : sanitizeDiscountReason(discount.DiscountReason);

  const discountAmount = getDiscountAmount({ item, discount });

  const removeDiscounts = useRemoveDiscounts(item);

  const refetchCartDetails = useRefetchCartDetails();

  const onRemoveDiscount = async (discountId: number) => {
    await removeDiscounts(discountId);
    refetchCartDetails();
  };

  const handleClick = ({ IsLoyalty, DiscountId }: AppliedDiscount) => {
    if (IsLoyalty) {
      dispatch(showLoyaltyPanel());
    } else {
      onRemoveDiscount(DiscountId);
    }
  };

  return {
    Icon,
    Label,
    discountDescription,
    discountAmount,
    handleClick,
  };
};
