import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useScanners, BarcodeData, HardwareService } from '@dutchie/capacitor-hardware';

import { HardwareSettings } from '../../types';
import { ScannerSettingsContextData } from '../types';
import { getStoredHardwareIds } from 'util/hardwareLibrary/hardware-library-utils';

// Provides values for the ScannerSettingsProvider.
// Access the values through useScannerSettings.
export const useScannerSettingsProvider = (): ScannerSettingsContextData => {
  const { values: formValues, setFieldValue, submitForm } = useFormikContext<HardwareSettings>();

  const {
    scanners: allScanners,
    deviceBarcode,
    search,
  } = useScanners({
    onDevicesAuthorized: (scanners) => selectScanner(scanners[0].id, false),
  });

  // Monitors last barcode for scanners selected on the form
  const [lastBarcode, setLastBarcode] = useState<BarcodeData | undefined>(undefined);

  const connectOnlyToDeviceId = (id: string | undefined) => {
    try {
      HardwareService.scanner.devices
        .filter((it) => it.isConnected && it.id !== id)
        .forEach((it) => {
          it.disconnect();
        });

      // Ensure stored scanner is connected
      if (id) {
        HardwareService.scanner.deviceById(id)?.connect();
      }
    } catch (e) {
      /* no-op */
    }
  };

  const selectScanner = (id?: string, save?: boolean) => {
    setFieldValue('scannerId', id);
    setLastBarcode(undefined);
    connectOnlyToDeviceId(id);

    // Enable quickly saving the preferred scanner from outside of the dropdown
    if (save === true) {
      submitForm();
    }
  };

  useEffect(() => {
    if (!deviceBarcode) {
      return;
    }

    // Only listen for events from scanners selected on the form
    if (deviceBarcode?.device?.id === deviceBarcode.device.id) {
      setLastBarcode(deviceBarcode.barcode);
    }
  }, [deviceBarcode, formValues.scannerId]);

  // On dispose, disconnect all but the selected scanner
  useEffect(() => {
    return () => {
      const { scannerId } = getStoredHardwareIds();
      connectOnlyToDeviceId(scannerId);
    };
  }, []);

  return {
    formValues,
    lastBarcode,
    scanners: allScanners,
    search,
    selectScanner,
  };
};
