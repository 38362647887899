import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { State } from 'store';
import { colors } from 'css/Theme';
import { Preorder } from 'models/Cart';
import { useCartPopups } from 'components/CartPopups';

import { ReactComponent as AddIcon } from 'assets/icons/add-item-large.svg';
import { ReactComponent as ScanIcon } from 'assets/icons/scan-icon.svg';

type PreorderAssignmentTagProps = {
  preorder: Preorder;
};

export const PreorderAssignmentTag: FC<PreorderAssignmentTagProps> = ({ preorder }) => {
  const cartPopups = useCartPopups();
  const { EnableCartPlusMinus } = useSelector((state: State) => state.settings.features);

  const openAssignmentModal = () => {
    cartPopups.showAssignPackageIDsPopup(preorder);
  };

  return (
    <>
      {EnableCartPlusMinus ? (
        <AssignActionButton onClick={openAssignmentModal} data-testid='preorder_assign-package-button'>
          <AddIcon />
          Assign package
        </AssignActionButton>
      ) : (
        <AssignActionButton disabled data-testid='preorder_scan-package-button'>
          <ScanIcon />
          Scan package
        </AssignActionButton>
      )}
    </>
  );
};

const AssignActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 0.5rem;
  color: ${colors.dutchie.blue};
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;

    path {
      stroke: ${colors.dutchie.blue};
    }
  }

  &:disabled {
    cursor: default;
  }
`;
