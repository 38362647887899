import { CheckedInGuest } from "models/Guest";
import { useSelector } from "react-redux";
import { State } from "store";
import { useCuraleafLoyaltyTier } from "util/hooks/launch-darkly/useCuraleafLoyaltyTier";

export type UseLoyaltyPillParams = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
};

export const useLoyaltyPill = ({ guest, cardOptionsStatus }: UseLoyaltyPillParams) => {
  const isShowExternalLoyaltyFFEnabled = useSelector((state: State) => state.settings.features.ShowExternalLoyalty);
  const isCuraleafLoyaltyTierEnabled = useCuraleafLoyaltyTier();

  const displayLoyaltyBadge = cardOptionsStatus['Loyal vs. Non-loyal'] && guest.IsLoyaltyMember;

  if (isCuraleafLoyaltyTierEnabled && isShowExternalLoyaltyFFEnabled && displayLoyaltyBadge && !!guest.LoyaltyTier) {
    return {
      displayLoyaltyBadge,
      isUsingExternalLoyaltyTiers: true,
    };
  }

  return {
    displayLoyaltyBadge,
    isUsingExternalLoyaltyTiers: false,
  };
};
