import React from 'react';

import { CheckmarkFilledIcon } from 'assets/icons/settings/CheckmarkFilledIcon';
import { UnavailableIcon } from 'assets/icons/settings/UnavailableIcon';
import { AlertBannerStyles } from 'components/misc';
import { SettingsAlertBanner } from './SettingsAlertBanner';

export enum PeripheralSetupConnectionStatus {
  connected,
  unavailable,
  additionalSetupRequired,
}

export type ConnectionStatusBannerProps = {
  status: PeripheralSetupConnectionStatus;
  deviceName?: string;
  deviceTypeName: string;
  customText?: string;
};

export const ConnectionStatusBanner = ({
  status,
  deviceName,
  deviceTypeName,
  customText,
}: ConnectionStatusBannerProps) => {
  const name: string = deviceName ?? deviceTypeName ?? 'Unknown device';

  let text = `${name} connected`;
  let style = AlertBannerStyles.success;
  let icon: JSX.Element | undefined;

  switch (status) {
    case PeripheralSetupConnectionStatus.connected:
      icon = <CheckmarkFilledIcon width={16} height={16} />;
      break;
    case PeripheralSetupConnectionStatus.unavailable:
      icon = <UnavailableIcon width={16} height={16} />;
      text = `${name} connection status unavailable`;
      style = AlertBannerStyles.default;
      break;
    case PeripheralSetupConnectionStatus.additionalSetupRequired:
      text = `${name} connected. Additional setup is required.`;
      style = AlertBannerStyles.warning;
      break;
  }

  return <SettingsAlertBanner style={style} text={customText ?? text} icon={icon} marginTop='24px' />;
};
