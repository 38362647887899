import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Peripheral, PeripheralData, isNativeScanner } from 'util/capacitor/peripheral';

import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { useScanStatus, isAndroid } from 'util/hooks';
import { logger, customEventKeys } from 'util/logger';

import { getSearchScannersDescription } from 'util/logger/helpers/scanning';
import type { SearchScannersContext, UserSelectedScannerContext } from 'util/logger/types/scanning';
import { SelectOption } from 'components/inputs';
import { HardwareService, useScanners as useHwScanners } from '@dutchie/capacitor-hardware';
import { useHardwareLibrary } from 'util/hooks/launch-darkly/useHardwareLibrary';

const useScanners = () => {
  const dispatch = useDispatch();

  const [scanners, setScanners] = useState<PeripheralData[]>([]);
  const [loadingScanners, setLoadingScanners] = useState<boolean>(false);

  const searchScanners = useCallback(
    async ({ userInitiated = false }: { userInitiated?: boolean }) => {
      if (isAndroid) {
        setLoadingScanners(true);
        try {
          const { results: availableScanners } = await Peripheral.searchScanners();
          setScanners(availableScanners);
          logger.info<SearchScannersContext>(getSearchScannersDescription(availableScanners, userInitiated), {
            key: customEventKeys.scanning.bluetooth.search,
            availableScanners,
            userInitiated,
          });
        } catch (e) {
          dispatch(errorNotification(`Error loading scanners: ${e}`));
          logger.error(e, { message: 'searchScanners failed', userInitiated });
        } finally {
          setLoadingScanners(false);
        }
      }
    },
    [dispatch]
  );

  // When onScannerConnectionStatus fires, update the scanner list
  useScanStatus(() => {
    searchScanners({ userInitiated: false });
  });

  // When the page mounts, update the scanner list
  useEffect(() => {
    searchScanners({ userInitiated: false });
  }, [searchScanners]);

  return {
    scanners,
    searchScanners,
    loadingScanners,
  };
};

type UseBluetoothScannerPickerReturn = {
  scannerId?: string;
  scannerDropdownOptions: SelectOption[];
  handleScannerChange: (value: string) => void;
  searchScanners: ({ userInitiated }: { userInitiated: boolean }) => Promise<void>;
  loadingScanners: boolean;
  getFactoryResetBarcode: () => Promise<{ url: string; base64: string }>;
  getConfigurationBarcode: () => Promise<{ url: string; base64: string }>;
};

export const useBluetoothScannerPickerDeprecated = (): UseBluetoothScannerPickerReturn => {
  const { scanners, searchScanners, loadingScanners } = useScanners();

  const getFactoryResetBarcode = useCallback(() => {
    return Peripheral.factoryResetZebraScannerBarcode();
  }, []);

  const getConfigurationBarcode = useCallback(() => {
    return Peripheral.configureZebraScannerBarcode();
  }, []);

  const handleScannerChange = async (value: string) => {
    logger.info<UserSelectedScannerContext>(`user selected bluetooth scanner ${value}`, {
      key: customEventKeys.scanning.bluetooth.userSelectedScanner,
      selectedScannerId: value,
    });

    try {
      Peripheral.connectScanner({ id: value });
    } catch (e) {
      warningNotification('Unable to connect to scanner. Please try again or select a different scanner.');
      logger.error(e, { message: 'connectScanner failed', selectedScannerId: value });
    }
  };

  const scannerDropdownOptions = scanners.map((item) => ({
    value: item.id,
    label: item.name,
    key: item.id,
  }));

  const selectedScannerId = scanners.find((scanner) => !!scanner.connected)?.id;

  return {
    scannerId: selectedScannerId,
    scannerDropdownOptions,
    handleScannerChange,
    searchScanners,
    loadingScanners,
    getFactoryResetBarcode,
    getConfigurationBarcode,
  };
};

export const useBluetoothScannerPicker = (): UseBluetoothScannerPickerReturn => {
  const isHardwareLibraryActive = useHardwareLibrary();
  const deprecatedHook = useBluetoothScannerPickerDeprecated();

  const { scanners } = useHwScanners({ filter: (it) => !isAndroid || isNativeScanner(it) });

  const [loadingScanners, setLoadingScanners] = useState(false);

  if (!isHardwareLibraryActive) {
    return deprecatedHook;
  }

  const searchScanners = async ({ userInitiated }: { userInitiated: boolean }): Promise<void> => {
    setLoadingScanners(true);
    try {
      // Trigger search. Any changes to the available list will
      // update state in the hook.
      const scanners = await HardwareService.scanner.search();

      logger.info<SearchScannersContext>(`${userInitiated ? 'user initiated' : 'code initiated'} scanner search called and found ${scanners.length} scanners`, {
        key: customEventKeys.scanning.bluetooth.search,
        availableScanners: scanners,
        userInitiated: userInitiated,
      });
    } catch (e) {
      logger.error(e, { message: 'scanner search failed', userInitiated: userInitiated });
    } finally {
      setLoadingScanners(false);
    }
  };

  const handleScannerChange = async (value: string) => {
    logger.info<UserSelectedScannerContext>(`user selected bluetooth scanner ${value}`, {
      key: customEventKeys.scanning.bluetooth.userSelectedScanner,
      selectedScannerId: value,
    });

    try {
      // The hardware library doesn't automatically disconnect which supports having
      // multiple scanners actively listening for barcodes at the same time. This is
      // different than the current register behavior
      await scanners.find((it) => it.id !== value && it.isConnected)?.disconnect();
      await scanners.find((it) => it.id === value)?.connect();
    } catch (e) {
      warningNotification('Unable to connect to scanner. Please try again or select a different scanner.');
      logger.error(e, { message: 'scanner connect failed', selectedScannerId: value });
    }
  };

  const scannerDropdownOptions = scanners.map((item) => ({
    value: item.id,
    label: item.name,
    key: item.id,
  }));

  const selectedScannerId = scanners.find((scanner) => !!scanner.isConnected)?.id;

  return {
    scannerId: selectedScannerId,
    scannerDropdownOptions,
    handleScannerChange,
    searchScanners,
    loadingScanners,
    getFactoryResetBarcode: Peripheral.factoryResetZebraScannerBarcode,
    getConfigurationBarcode: Peripheral.configureZebraScannerBarcode,
  };
};


