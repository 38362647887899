import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { debounce } from 'lodash';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { applyVehicleFilters, getVehicles, setVehicleFilterQuery } from 'store/actions/FleetActions';
import { SearchBar, Spacer, Toolbar } from 'components/layout';
import { NavButton } from 'components/buttons';

type SortState = { isAlphaSort: boolean; alphaSortAscending: boolean };

type ActionBarProps = {
  drawerIsOpen: boolean;
  sortState: SortState;
  selectedFilterCount: number;
  setSort: (sortState: SortState) => unknown;
  setDrawerIsOpen: (isOpen: boolean) => unknown;
};

export const ActionBar: FC<ActionBarProps> = ({
  drawerIsOpen,
  sortState,
  selectedFilterCount,
  setSort,
  setDrawerIsOpen,
}) => {
  const userSettings = useSelector((state: State) => state.settings.userSettings);

  const dispatch = useDispatch();

  const onFilter = debounce((search: string) => {
    dispatch(setVehicleFilterQuery(search));
  }, 300);

  const onResetFilters = () => {
    dispatch(applyVehicleFilters([]));
  };

  return (
    <Toolbar>
      {userSettings.showSearch && (
        <SearchBar
          onChange={onFilter}
          onSearch={onFilter}
          placeholder='Search...'
          data-testid='vehicle-list-page_search-bar-large_vehicle-list-input'
        />
      )}
      <Spacer />
      <NavButton title='Refresh' onClick={() => dispatch(getVehicles())} icon={<RefreshIcon />} secondary />
      <NavButton
        title='Sort alphabetically'
        onClick={() => {
          setSort({
            alphaSortAscending: !sortState.alphaSortAscending,
            isAlphaSort: true,
          });
        }}
        icon={<SortIcon />}
        secondary
      />
      <NavButton title='Filter' onClick={() => setDrawerIsOpen(!drawerIsOpen)} icon={<FilterIcon />} secondary />

      {selectedFilterCount > 0 && (
        <NavButton onClick={onResetFilters} secondary>
          Clear Filters ({selectedFilterCount})
        </NavButton>
      )}
    </Toolbar>
  );
};
