import { useLDClient } from 'launchdarkly-react-client-sdk';

export const LD_FLAG_PAYMENTS_HUB_ALERT = 'fintech.payment-processing.payments-hub-alert';

export type PaymentsHubAlert = {
  message: string;
  style: string;
};

export const usePaymentsHubAlert = (): PaymentsHubAlert | null => {
  const ldClient = useLDClient();
  const ldFlagContent = ldClient?.variation(LD_FLAG_PAYMENTS_HUB_ALERT, {});

  // if the ldFlag content is malformed, return null. Style is not needed to successfully render the alert.
  if (!ldFlagContent || !ldFlagContent.message) {
    return null;
  }

  // return the message and style
  return {
    message: ldFlagContent.message,
    style: ldFlagContent.style,
  } as PaymentsHubAlert;
};
