import React, { FC } from 'react';

import { Main } from 'css/DutchieGlobalStyles';

export const PORTAL_ID = 'full-screen-portal';

export const FullScreenPortal: FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <Main id={PORTAL_ID} {...props}>
    {children}
  </Main>
);
