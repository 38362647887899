import styled, { createGlobalStyle } from 'styled-components';
import { colors } from 'css/Theme';

export const DutchieGlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  html {
    body {
      display: flex;
      width: 100%;
      line-height: 24px;
      font-size: 16px;
      box-sizing: border-box;
      background-color: ${colors.white};

      * {
        font-family: Matter;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }

  #react-root {
    height: 100%;
    width: 100%;
    flex: 1 0;
    overflow: hidden;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
