import React, { FC } from 'react';
import styled from 'styled-components';

import { AllotmentCategories } from './components/AllotmentCategories';
import { colors } from 'css/Theme';
import { Loader, LoadingContainer } from 'components/backoffice/loader';
import { LoadingButton } from 'components/buttons';
import { ModalContainer, ModalCloseButton, ModalFooter, ModalVariation } from 'components/modals/Modal';
import { PopupOverlay } from 'components/popups';
import { useViewAllotmentDetailPopup } from './useViewAllotmentDetailPopup';

type ViewAllotmentDetailPopupProps = {
  hide: () => void;
};

export const ViewAllotmentDetailPopup: FC<ViewAllotmentDetailPopupProps> = ({ hide }) => {
  const { allotmentGroups, allotmentLimit, handleRefreshMMURAllotment, isLoading, isMMUREnabled } =
    useViewAllotmentDetailPopup();

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer variation={ModalVariation.Standard} data-testid='view-allotment-details-modal'>
        <ModalHeader>
          <Title>
            Allotment details
            <SubTitle>{allotmentLimit}</SubTitle>
          </Title>
          <ModalCloseButton onClick={hide} data-testid='view-allotment-details-modal_close-button' />
        </ModalHeader>
        <ModalContent>
          {isLoading ? (
            <LoadingContainer>
              <Loader size='2x' variant='black' />
            </LoadingContainer>
          ) : (
            <AllotmentGroupContainer>
              {allotmentGroups?.map((allotmentGroup) => (
                <AllotmentCategories key={allotmentGroup.orderId} {...allotmentGroup} />
              ))}
            </AllotmentGroupContainer>
          )}
        </ModalContent>
        {isMMUREnabled && (
          <ModalFooter>
            <LoadingButton cta onClick={handleRefreshMMURAllotment}>
              Refresh
            </LoadingButton>
          </ModalFooter>
        )}
      </ModalContainer>
    </PopupOverlay>
  );
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  padding: 1.5rem 1.5rem 0;
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  flex: 1;
  padding: 0.5rem 0 2rem;
  margin-left: 3.75rem;
  text-align: center;
`;

const SubTitle = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;

const AllotmentGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;
