import { useDispatch } from 'react-redux';
import { ConnectionStatus } from '@dutchie/capacitor-peripheral';

import { successNotification, warningNotification } from 'store/actions/NotificationsActions';


type HandleStatusChangeParams = {
  deviceType: string;
  status: ConnectionStatus;
};

type UseHandleStatusChangeReturn = (params: HandleStatusChangeParams) => Promise<void>;

export const useHandleStatusChange = (): UseHandleStatusChangeReturn => {
  const dispatch = useDispatch();

  return async ({ deviceType, status }: HandleStatusChangeParams) => {
    // If we get an available status for the previously connected printer ID, we can assume it's a reconnect
    if (status === ConnectionStatus.Available) {
      dispatch(successNotification(`${deviceType} reconnected`));
    }

    // If we get an unavailable status for the previously connected printer ID, we can assume it lost connection.
    // We should leave the currently selected printer so that it can show up as a reconnect when it comes back online
    if (status === ConnectionStatus.Unavailable) {
      dispatch(warningNotification(`${deviceType} lost connection`));
    }
  };
};
