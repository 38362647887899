import React, { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { BackButton, Button } from 'components/buttons';
import { BottomDrawer } from 'components/layout';
import { colors } from 'css/Theme';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';

const drawerHeaderRef = React.createRef<HTMLDivElement>();

type PanelDrawerWrapperProps = {
  isOpen?: boolean;
};

export const PanelDrawerWrapper = ({ children, isOpen }: PropsWithChildren<PanelDrawerWrapperProps>) => {
  const { isCompactLayout } = useCompactCartLayout();

  if (!isCompactLayout) {
    return null;
  }

  return (
    <BottomDrawer
      fullHeight
      open={isOpen}
      onClose={() => {}}
      portalId={FullScreenPortalId}
      marginX={0}
      marginY={40}
      automationId='cart-panels-drawer'
    >
      {isOpen && (
        <Container>
          <Header ref={drawerHeaderRef}></Header>
          <Content>{children}</Content>
        </Container>
      )}
    </BottomDrawer>
  );
};

type DrawerActionProps = {
  label?: string;
  disabled?: boolean;
  onClick: () => void;
};

type DrawerHeader = {
  actionProps?: DrawerActionProps;
  onGoBack?: () => void;
};

export const DrawerHeader = ({ actionProps, children, onGoBack }: PropsWithChildren<DrawerHeader>) => {
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null);

  const headerComponent = (
    <>
      {onGoBack && <BackButton onClick={onGoBack} />}
      <Title>{children}</Title>
      <Button tertiary onClick={actionProps?.onClick} disabled={actionProps?.disabled ?? false}>
        {actionProps?.label ?? 'Close'}
      </Button>
    </>
  );

  useEffect(() => {
    if (portalContainer === null || portalContainer !== drawerHeaderRef.current) {
      setPortalContainer(drawerHeaderRef.current);
    }
  }, [portalContainer]);

  if (portalContainer) {
    return createPortal(headerComponent, portalContainer);
  } else {
    return null;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  align-items: center;
  justify-content: space-between;
  padding: 1.1875rem 2rem;
  gap: 1rem;
  border-bottom: 1px solid ${colors.dutchie.grey90};
`;

const Title = styled.h1`
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 0.5rem 2.5rem;

  & > * {
    max-width: 600px;
  }
`;
