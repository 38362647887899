import { CheckedInGuest } from 'models/Guest';

const MAX_DATE = '8640000000000000';

export const getExpectedTimeWindow = (guest: CheckedInGuest) => ({
  windowStart: guest.DeliveryDepartureDate ?? guest.TimeWindowStartDate,
  windowEnd: guest.DeliveryArrivalDate ?? guest.TimeWindowEndDate,
});

export const getTimeExpectedSortValues = (guest: CheckedInGuest) => {
  const { windowStart, windowEnd } = getExpectedTimeWindow(guest);

  return [
    new Date(windowStart || MAX_DATE).getTime(),
    new Date(windowEnd || MAX_DATE).getTime(),
    new Date(guest.ShipmentDateUTC).getTime(),
  ];
};
