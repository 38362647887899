import React, { createContext } from 'react';
import { usePopupsState } from 'util/hooks/usePopupsState';
import { ProductsPopups } from './ProductsPopups';
import { ProductsPopupsState, emptyProductsPopupsState } from './ProductsPopupsState';

export type ProductsPopupsContextProps = [ProductsPopupsState, (ProductsPopupsState: ProductsPopupsState) => void];
export const ProductsPopupsContext = createContext<ProductsPopupsContextProps | null>(null);

export const ProvideProductsPopups: React.FC = ({ children }) => {
  const { stateTuple, closePopup } = usePopupsState<ProductsPopupsState>(emptyProductsPopupsState);
  const [state] = stateTuple;

  return (
    <ProductsPopupsContext.Provider value={stateTuple}>
      <ProductsPopups productsPopupsState={state} closePopup={closePopup} />
      {children}
    </ProductsPopupsContext.Provider>
  );
};
