import React, { FC } from 'react';

import { ConfirmationPopup } from 'components/popups';
import { Input, Select } from 'components/inputs';
import { Tooltip, TooltipIcon, TooltipIconContainer } from 'components/backoffice/tooltip';

import { VoidTransactionLoadingSkeleton } from './VoidTransactionLoadingSkeleton';
import { LoadingContent } from 'components/buttons/LoadingButton';
import { YesNoSelectOptions } from 'pages/TransactionsPage/TransactionDetails/components/ReturnPopup';
import { useVoidTransactionPopup } from './useVoidTransactionPopup';

import {
  Field,
  Label,
  OrderInfo,
  OrderNumber,
  Payment,
  PaymentTypesHeader,
  PopupContent,
  ToolTipStyles,
  TransactionPaymentTypes,
} from './VoidTransactionPopup.styles';

export type VoidTransactionPopupProps = {
  shipmentId: number;
  reloadTransactions: () => void;
  hide: () => void;
};

export const VoidTransactionPopup: FC<VoidTransactionPopupProps> = ({ shipmentId, reloadTransactions, hide }) => {
  const {
    handleChangeManagerPin,
    handleSelectVoidReason,
    handleSelectVoidType,
    handleVoidTransaction,
    isCashVoid,
    isContinueButtonDisabled,
    isLoadingTransactionDetails,
    isLoadingVoidReasons,
    isVoidingTransaction,
    managerPin,
    payments,
    selectedVoidReason,
    transactionDetails,
    voidReasonOptions,
  } = useVoidTransactionPopup({ shipmentId, reloadTransactions, hide });

  const splitPaymentsMessage = 'Split payments must be voided fully in either cash or other.';

  return (
    <ConfirmationPopup
      isVisible
      hide={hide}
      title='Void transaction'
      confirm={{
        text: isVoidingTransaction ? <LoadingContent>Processing...</LoadingContent> : 'Continue',
        onClick: handleVoidTransaction,
        disabled: isContinueButtonDisabled,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      {isLoadingTransactionDetails || isLoadingVoidReasons ? (
        <VoidTransactionLoadingSkeleton />
      ) : (
        <PopupContent>
          <OrderInfo>
            <OrderNumber>
              Order <b>#{shipmentId}</b> will be voided.
            </OrderNumber>
            <TransactionPaymentTypes>
              <PaymentTypesHeader>
                Transaction payment type:
                {payments.length > 1 && (
                  <>
                    <ToolTipStyles />
                    <Tooltip title={splitPaymentsMessage} placement='right'>
                      <TooltipIconContainer>
                        <TooltipIcon />
                      </TooltipIconContainer>
                    </Tooltip>
                  </>
                )}
              </PaymentTypesHeader>
              {payments.map((payment) => (
                <Payment key={payment.type}>
                  {payment.type}: ${payment.amount}
                </Payment>
              ))}
            </TransactionPaymentTypes>
          </OrderInfo>
          <Field>
            <Label>
              <b>${transactionDetails?.invoiceTotal}</b> returned to customer via cash?
            </Label>
            <Select
              value={isCashVoid ? 'Yes' : 'No'}
              options={YesNoSelectOptions}
              onChange={handleSelectVoidType}
              disabled={isVoidingTransaction}
              data-testid='void-transaction_is-cash-void'
            />
          </Field>
          {voidReasonOptions.length > 0 && (
            <Field>
              <Label bold>Void reason:</Label>
              <Select
                placeholder='Select a reason'
                value={selectedVoidReason}
                options={voidReasonOptions}
                onChange={handleSelectVoidReason}
                disabled={isVoidingTransaction}
                data-testid='void-transaction_void-reason'
              />
            </Field>
          )}
          <Field>
            <Label bold>Manager pin:</Label>
            <Input
              type='password'
              autoComplete='off'
              placeholder='Enter manager pin'
              value={managerPin}
              onChange={handleChangeManagerPin}
              disabled={isVoidingTransaction}
              data-testid='void-transaction_manager-pin'
            />
          </Field>
        </PopupContent>
      )}
    </ConfirmationPopup>
  );
};
