import React from 'react';

import { colors } from 'css/Theme';

type DriversLicenseIconProps = {
  color?: string;
  size?: number;
  automationId?: string;
};

export const DriversLicenseIcon = ({
  color = colors.dutchie.almostBlack,
  size = 22,
  automationId,
}: DriversLicenseIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 22'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.3333 3.53942H3.66667C2.45164 3.53942 1.46667 4.5244 1.46667 5.73942V16.2606C1.46667 17.4756 2.45164 18.4606 3.66667 18.4606H18.3333C19.5484 18.4606 20.5333 17.4756 20.5333 16.2606V5.73942C20.5333 4.52439 19.5484 3.53942 18.3333 3.53942ZM3.66667 2.07275C1.64162 2.07275 0 3.71438 0 5.73942V16.2606C0 18.2856 1.64162 19.9272 3.66667 19.9272H18.3333C20.3584 19.9272 22 18.2856 22 16.2606V5.73942C22 3.71438 20.3584 2.07275 18.3333 2.07275H3.66667Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.48858 10.0209C8.31489 10.0209 8.98474 9.35108 8.98474 8.52477C8.98474 7.69847 8.31489 7.02862 7.48858 7.02862C6.66228 7.02862 5.99243 7.69847 5.99243 8.52477C5.99243 9.35108 6.66228 10.0209 7.48858 10.0209ZM7.48858 11.4876C9.12491 11.4876 10.4514 10.1611 10.4514 8.52477C10.4514 6.88845 9.12491 5.56195 7.48858 5.56195C5.85226 5.56195 4.52576 6.88845 4.52576 8.52477C4.52576 10.1611 5.85226 11.4876 7.48858 11.4876Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.33413 14.9527C4.36565 14.9639 4.40378 14.9714 4.44647 14.9714H10.4558C10.5036 14.9714 10.5458 14.9621 10.5798 14.9485C10.1311 13.5873 9.13792 12.5792 7.48855 12.5792C5.82984 12.5792 4.80043 13.5985 4.33413 14.9527ZM10.4558 16.438C11.4683 16.438 12.3124 15.6002 12.0177 14.6315C11.4566 12.7866 10.0058 11.1125 7.48855 11.1125C4.9703 11.1125 3.47681 12.7879 2.89517 14.6336C2.59084 15.5993 3.43395 16.438 4.44647 16.438H10.4558Z'
        fill={color}
      />
      <path
        d='M13 9.57129H18.1429M15.5713 7V12.1429'
        stroke={color}
        stroke-width='1.71429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
