import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { StyledNavButton } from './NavButton';
import type { StyleProps } from './NavButton';

export type NavLinkButtonProps = {
  to: string;
  icon?: ReactNode;
  automationId?: string;
  margin?: string;
} & StyleProps;

export const NavLinkButton: FC<NavLinkButtonProps> = ({ children, icon, to, automationId, margin, ...styleProps }) => {
  return (
    <StyledNavButton
      as={NavLink}
      {...styleProps}
      to={to}
      icononly={!!icon && !children}
      data-testid={automationId}
      margin={margin}
    >
      {icon}
      {children}
    </StyledNavButton>
  );
};
