import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { Loader } from 'components/backoffice/loader';
import { ReactComponent as SearchIconLarge } from 'assets/icons/magnifying-glass-large.svg';
import { EmptyPlaceHolderCircle } from 'components/image';
import { LargeText } from 'components/backoffice/typography';

type SearchResultsProps = {
  children: ReactElement;
  errMessage?: string;
  isEmpty?: boolean;
  emptyMessage?: string;
  loading?: boolean;
  className?: string;
  loadingText?: string;
};

export const SearchResults: FC<SearchResultsProps> = ({
  errMessage,
  children,
  loading,
  isEmpty,
  emptyMessage,
  className,
  loadingText,
}) => {
  if (errMessage) {
    return <ErrorMessageDiv className={className}>{errMessage}</ErrorMessageDiv>;
  }

  if (loading) {
    return (
      <SpinnerWrapperDiv className={className}>
        <Loader variant='black' size='2x' />
        {loadingText && <LoadingText>{loadingText}</LoadingText>}
      </SpinnerWrapperDiv>
    );
  }

  if (isEmpty) {
    return (
      <EmptyPlaceholderWrapper className={className}>
        <IconBackground>
          <SearchIconLarge />
        </IconBackground>
        <MessageDiv>{emptyMessage}</MessageDiv>
      </EmptyPlaceholderWrapper>
    );
  }

  return children;
};

const MessageDiv = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 16px;
  margin-top: 24px;
`;

const ErrorMessageDiv = styled.div`
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
  color: ${colors.grey};
`;

const SpinnerWrapperDiv = styled.div`
  margin-top: 36px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
`;

const EmptyPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 44px;
`;

const IconBackground = styled(EmptyPlaceHolderCircle)`
  width: 75px;
  height: 75px;
  background-color: ${colors.dutchie.grey};
`;

const LoadingText = styled(LargeText)`
  margin-top: 24px;
`;
