import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cleanScanString } from '../helpers/cleanScanString';
import { isAndroid } from 'util/hooks';
import { parseCanadianBarcode } from 'util/parseCanadianBarcode';
import { playScannerAction } from 'util/scanning';
import { scanItem } from 'api/CartApi';
import { startScan, endScan } from 'store/actions/CartItemsActions';
import { useLookupScannedProduct } from './useLookupScannedProduct';
import { errorNotification } from 'store/actions/NotificationsActions';
import { showScanFailedPopup } from 'store/actions/PopupsActions';
import { PackageNotFound } from 'components/CartPopups/components/ScanFailedPopup/messages';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { ScanResponse } from 'models/Cart';
import type { State } from 'store';
import { customEventKeys, logger } from 'util/logger';

export const useGetScannedProduct = () => {
  const dispatch = useDispatch();
  const customerId = useSelector((state: State) => state.customer.details?.Guest_id);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const selectedLspId = useSelector((state: State) => state.user.selectedLocation?.LspId);
  const isShowErrorModalInCartIfPackageNotFoundEnabled = useSelector((state: State) => state.settings.features.ShowErrorModalInCartIfPackageNotFound);

  const isCanadaBarcodeScanningFeatureActive = useSelector(
    (state: State) => state.settings.features.CanadaBarcodeScanning
  );
  const isPackageItemIdsFeatureActive = useSelector((state: State) => state.settings.features.PackageItemIds);

  const lookupScannedProduct = useLookupScannedProduct();

  return useCallback(
    async (SerialNumber: string, scannerId?: string | number) => {
      dispatch(startScan());
      const serialCleaned = cleanScanString(SerialNumber, selectedLspId);
      try {
        let foundItem: ProductSearchResult | ScanResponse | null | undefined;

        // we need to query the api when scanning in NJ because of the way they do item level package id's
        if (!isPackageItemIdsFeatureActive) {
          const serialNumberParsed = isCanadaBarcodeScanningFeatureActive
            ? parseCanadianBarcode(serialCleaned)
            : serialCleaned;
          foundItem = lookupScannedProduct(serialNumberParsed);
        }

        // if we can't retrieve the package from redux store (NJ locations) or we don't find it in the store, fallback to DB lookup
        if (!foundItem) {
          [foundItem] = await scanItem({
            SerialNumber: serialCleaned,
            CustomerId: customerId || 0,
            Register: registerId || 0,
          });
        } else {
          logger.debug('scanned item found in redux', {
            key: customEventKeys.scanning.itemFoundInRedux,
            comment: 'No API call made',
            item: foundItem,
          });
        }

        if (isAndroid) {
          playScannerAction({
            scannerId,
            isGood: !!foundItem,
          });

          if (!foundItem) {
            if (isShowErrorModalInCartIfPackageNotFoundEnabled) {
              dispatch(showScanFailedPopup({ message: PackageNotFound({ serialNumber: serialCleaned }) }));
            } else {
              dispatch(errorNotification(`Package ${serialCleaned} not found`));
            }
          }
        }

        return foundItem;
      } catch (e) {
        playScannerAction({ scannerId, isGood: false });

        if (isShowErrorModalInCartIfPackageNotFoundEnabled) {
          dispatch(showScanFailedPopup({ message: PackageNotFound({ serialNumber: serialCleaned }) }));
        } else {
          dispatch(errorNotification(`Package ${serialCleaned} not found`));
        }
        return null;
      } finally {
        dispatch(endScan());
      }
    },
    [customerId, dispatch, isCanadaBarcodeScanningFeatureActive, isPackageItemIdsFeatureActive, isShowErrorModalInCartIfPackageNotFoundEnabled, lookupScannedProduct, registerId, selectedLspId]
  );
};
