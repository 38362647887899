import { useGeneralHeaderBannerConfig } from 'util/hooks/launch-darkly/useGeneralHeaderBannerConfig';
import { useNonSandboxBannerRollout } from 'util/hooks/launch-darkly/useNonSandboxBannerRollout';
import { useServerMigrationConfig } from '../ServerMigrationUI/useServerMigrationConfig';
import { useNonSandboxBannerIsVisible } from './NonSandboxBanner';
import { LARGE_BANNER_HEIGHT, SMALL_BANNER_HEIGHT } from './shared';

export function useHeightOffset() {
  const { generalHeaderBannerConfig } = useGeneralHeaderBannerConfig();
  const { nonSandboxBannerRollout } = useNonSandboxBannerRollout();
  const { registerURL } = useServerMigrationConfig();
  const nonSandboxBannerVisible = useNonSandboxBannerIsVisible();

  let offset = 0;

  if (generalHeaderBannerConfig?.visible || registerURL?.length) {
    offset += LARGE_BANNER_HEIGHT;
  }

  if (nonSandboxBannerRollout && nonSandboxBannerVisible) {
    offset += SMALL_BANNER_HEIGHT;
  }

  return `${offset}px`;
}
