import React, { FC, useState } from 'react';
import imagePlaceholder from 'assets/images/image-placeholder.png';
import styled from 'styled-components';

type ImageWithPlaceholderProps = {
  alt: string;
  src?: string;
} & React.ImgHTMLAttributes<HTMLElement>;

export const ImageWithPlaceholder: FC<ImageWithPlaceholderProps> = ({ alt, src, ...props }) => {
  const [showDefault, setShowDefault] = useState(false);

  const onError = () => setShowDefault(true);

  return (
    <Img {...props} alt={alt} src={showDefault ? imagePlaceholder : src} isDefault={showDefault} onError={onError} />
  );
};

const Img = styled.img<{ isDefault: boolean }>`
  ${({ isDefault }) => isDefault && `max-height: 50%;`}
`;

