import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { compareAsc } from 'date-fns';
import { ReactComponent as _DeleteIcon } from 'assets/icons/delete.svg';
import { colors } from 'css/Theme';
import { Document, CustomerDetails } from 'models/Customer';
import { DeleteDocumentPopup } from 'pages/EditCustomerPage/components/DeleteDocumentPopup';
import { usePopup } from 'components/popups';
import { CheckboxState, Checkbox as _Checkbox } from 'components/inputs';
import { downloadDocument } from 'store/actions/CustomerActions';
import { Label } from 'components/text';
import { useDispatch } from 'react-redux';

const DocumentListBaseDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 27rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const Labels = styled.div`
  display: flex;
  width: 100%;
`;

const FileLabelSpan = styled(Label)`
  flex-grow: 1;
  margin-bottom: 1rem;
`;

const DocumentListDiv = styled.div`
  flex-grow: 1;
  border-radius: 0.5rem;
  border: 1px solid ${colors.dutchie.borderGrey};
  overflow: auto;
  padding: 0.75rem;
  margin-bottom: 2rem;
`;

const DocumentHeader = styled.th`
  color: ${colors.dutchie.grey};
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem;
  white-space: nowrap;
`;

const DocumentCell = styled.td`
  color: ${colors.dutchie.grey};
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.5rem;
  vertical-align: middle;
  white-space: nowrap;
`;

const DocumentName = styled(DocumentCell)`
  color: ${colors.dutchie.almostBlack};
  max-width: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

const DeleteIcon = styled(_DeleteIcon)`
  width: 1rem;
  height: 1rem;
  color: ${colors.dutchie.red};
  cursor: pointer;
`;

const Checkbox = styled(_Checkbox)`
  margin-bottom: 1rem;
  margin-right: 0.5rem;
`;

const CheckboxFieldLabel = styled(Label)`
  width: auto;
  white-space: nowrap;
  margin-bottom: 1rem;
`;

type DocumentListProps = {
  documents: Array<Document>;
  customer: CustomerDetails;
  className?: string;
};

export const DocumentList: FC<DocumentListProps> = ({ documents, customer, className }) => {
  const dispatch = useDispatch();
  const { isVisible: deletePopupVisible, toggle: toggleDeletePopup } = usePopup();
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [showExpiredFiles, setShowExpiredFiles] = useState(false);

  const showExpiredFilesIsEnabled = (): CheckboxState => {
    return showExpiredFiles ? 'on' : 'off';
  };

  const availableDocuments = documents.filter((document) => {
    if (!showExpiredFiles && document.ExpirationDate) {
      const dateComp = compareAsc(new Date(), new Date(document.ExpirationDate));
      if (dateComp <= 0) {
        return document;
      }
    } else {
      return document;
    }
    return null;
  });

  const handleDelete = (File: Document) => {
    setSelectedDocument(File);
    toggleDeletePopup();
  };

  const handleDownloadDocument = async (File: Document) => {
    await dispatch(downloadDocument({ PatientId: customer.Guest_id, FileName: File.name }));
  };

  return (
    <>
      <DeleteDocumentPopup
        isVisible={deletePopupVisible}
        hide={toggleDeletePopup}
        FileId={selectedDocument?.FileId}
        PatientId={customer.Guest_id}
      />
      <DocumentListBaseDiv className={className}>
        <Labels>
          <FileLabelSpan>Uploaded Customer files</FileLabelSpan>
          <Checkbox state={showExpiredFilesIsEnabled()} onClick={() => setShowExpiredFiles(!showExpiredFiles)} />
          <CheckboxFieldLabel>Show Expired</CheckboxFieldLabel>
        </Labels>

        <DocumentListDiv>
          <table>
            <thead>
              <tr>
                <DocumentHeader></DocumentHeader>
                <DocumentHeader>File Name</DocumentHeader>
                <DocumentHeader>Expiration</DocumentHeader>
                <DocumentHeader>Uploaded On</DocumentHeader>
                <DocumentHeader>Uploaded By</DocumentHeader>
              </tr>
            </thead>
            <tbody>
              {availableDocuments &&
                availableDocuments.map((document) => {
                  return (
                    <tr key={document.FileId}>
                      <DocumentCell>
                        <DeleteIcon onClick={() => handleDelete(document)} />
                      </DocumentCell>
                      <DocumentName onClick={() => handleDownloadDocument(document)}>{document.name}</DocumentName>
                      <DocumentCell>{document.ExpirationDate}</DocumentCell>
                      <DocumentCell>{document.UploadedDate}</DocumentCell>
                      <DocumentCell>{document.UploadedBy}</DocumentCell>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </DocumentListDiv>
      </DocumentListBaseDiv>
    </>
  );
};
