import React from 'react';
import styled from 'styled-components';

import { PreorderMetadataValues } from 'models/Customer';
import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { CustomerInsights } from 'components/cart/CustomerInsights';
import { PurchaseHistory } from 'components/cart/PurchaseHistory';
import { SidePanel, SidePanelSection } from 'components/layout';
import { LegalLimit } from 'components/cart/LegalLimit';
import { TotalMinimized } from 'components/cart/TotalMinimized';
import { TotalBreakdownDrawer } from 'components/cart/TotalBreakdownDrawer';
import { Overlay } from 'components/cart/Overlay';
import { PreorderCartItem } from '.';
import { useTotalsPanel } from './useTotalsPanel';
import { GuestNotes } from 'components/cart/GuestNotes';
import { PreorderSummary } from './PreCartComponents/PreorderSummary';
import { CustomerType } from 'components/cart/CustomerType';
import { useChangeCustomerTypeInCart } from 'util/hooks/launch-darkly/useChangeCustomerTypeInCart';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

export type OrderPrice = {
  SubTotal: number;
  Discount: number;
  Taxes: number;
  RoundedDifference: number;
  Total: number;
};

type TotalsPanelProps = {
  orderMetadata: PreorderMetadataValues;
  cartItems: PreorderCartItem[];
  orderPrice: OrderPrice;
  isSaving: boolean;
  onSaveAndRelease: () => void;
};

export const TotalsPanel = React.memo(TotalsPanelsImpl);

function TotalsPanelsImpl({
  orderMetadata,
  cartItems,
  orderPrice,
  isSaving,
  onSaveAndRelease,
}: TotalsPanelProps): JSX.Element {
  const { isTotalsDrawerOpen, totals, setIsTotalsDrawerOpen, handleCloseTotalsDrawer } = useTotalsPanel({
    orderMetadata,
    orderPrice,
  });

  const { isChangeCustomerTypeInCartEnabled } = useChangeCustomerTypeInCart();
  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  return (
    <PanelContainer>
      <TopSection>
        <Overlay isActive={isTotalsDrawerOpen} onClick={handleCloseTotalsDrawer} />
        {isChangeCustomerTypeInCartEnabled && <CustomerType />}
        <ScrollContainer>
          <PreorderSummary orderMetadata={orderMetadata} />
          <LegalLimit preorderCartItems={cartItems} showDetailsButton={false} />
          <CustomerInsights />
          {isCustomerInsightsEnabled && <PurchaseHistory />}
          <GuestNotes />
        </ScrollContainer>
        <TotalBreakdownDrawer cart={totals} isOpen={isTotalsDrawerOpen} />
      </TopSection>
      <BottomSection gap='8px'>
        <TotalMinimized
          cart={totals}
          isStatefulCheckoutEnabled={false} // We don't use stateful checkout on the precart, so override to false
          isOpen={isTotalsDrawerOpen}
          setIsOpen={setIsTotalsDrawerOpen}
        />
        <Button cta marginTop={8} onClick={onSaveAndRelease} disabled={isSaving}>
          Submit fulfillment order
        </Button>
      </BottomSection>
    </PanelContainer>
  );
}

const PanelContainer = styled(SidePanel)`
  flex-grow: 1;
`;

const TopSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  border-bottom: 1px solid ${colors.dutchie.backgroundGrey};
`;

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const BottomSection = styled(SidePanelSection)`
  padding: 16px 24px 20px 24px;
`;
