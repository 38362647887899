import React, { FC } from 'react';

import { CashDetailsPanel } from 'pages/RegisterTransactionsPage/CashDetailsPanel';
import { ConfirmTransactionPopup } from '../ConfirmTransactionPopup';
import { Label } from 'components/text';
import { LoadingButton } from 'components/buttons';
import { Select, TextArea } from 'components/inputs';
import { useWithdraw } from './useWithdraw';

import {
  ButtonsContainer,
  CashDetailsButton,
  CashInputContainer,
  Container,
  CurrencyInput,
  Fields,
  InputContainer,
  Row,
  TabTitle,
  TextAreaContainer,
} from '../Styles';

export type WithdrawProps = {
  onWithdraw: () => void;
  registerId: number;
};

export const Withdraw: FC<WithdrawProps> = (props) => {
  const {
    cashCounted,
    handleClickEnterCashDetails,
    handleWithdraw,
    isConfirmPopupVisible,
    isLoading,
    isWithdrawDisabled,
    note,
    setCashCounted,
    setNote,
    setTransactionAdjustmentReason,
    toggleConfirmPopup,
    transactionAdjustmentReason,
    transactionAdjustmentReasonOptions,
  } = useWithdraw(props);

  return (
    <Container data-testid='withdraw-container'>
      <TabTitle>Withdraw</TabTitle>
      <Fields>
        <Row>
          <CashInputContainer>
            <CurrencyInput
              label='Cash Counted'
              allowNegative={false}
              value={cashCounted.formattedValue}
              onValueChange={setCashCounted}
              data-testid='withdraw_input_cash-counted'
            />
            <CashDetailsButton $variant='primary' onClick={handleClickEnterCashDetails}>
              Enter cash details
            </CashDetailsButton>
          </CashInputContainer>
          <InputContainer>
            <Label>Reason</Label>
            <Select
              options={transactionAdjustmentReasonOptions}
              onChange={setTransactionAdjustmentReason}
              value={transactionAdjustmentReason}
              placeholder='Choose a reason'
            />
          </InputContainer>
        </Row>
        <TextAreaContainer>
          <Label>Note</Label>
          <TextArea value={note} onChange={(e) => setNote(e.target.value)} data-testid='withdraw_textarea_notes' />
        </TextAreaContainer>
      </Fields>
      <ButtonsContainer>
        <LoadingButton loading={isLoading} disabled={isWithdrawDisabled} onClick={toggleConfirmPopup}>
          Withdraw
        </LoadingButton>
      </ButtonsContainer>
      {/* Popups */}
      <ConfirmTransactionPopup
        title='Confirm Cash Withdrawal'
        message={`Total: $ ${cashCounted.formattedValue}`}
        onConfirm={handleWithdraw}
        isVisible={isConfirmPopupVisible}
        hide={toggleConfirmPopup}
      />
      <CashDetailsPanel onSave={setCashCounted} />
    </Container>
  );
};
