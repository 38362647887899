import styled from 'styled-components';
import { colors } from 'css/Theme';

export const StyledParentTable = styled.table`
  &,
  table {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }

  td,
  th {
    padding: 12px;

    &.expansion-indicator {
      padding-right: 0;
      width: 12px;
      color: ${colors.dutchie.grey};
    }
  }

  thead {
    font-size: 16px;
    text-transform: capitalize;
    line-height: 24px;

    th {
      color: ${colors.dutchie.grey};
      padding: 8px 12px;
      font-weight: normal;
    }
  }

  tbody > tr {
    background-color: #fff;

    &:not(.embedded):hover {
      box-shadow: inset rgba(0, 0, 0, 0.05) 0 0 0 2px;
      z-index: 1;
    }

    td {
      padding: 12px;
      vertical-align: middle;
    }
  }

  tbody > tr.embedded {
    background-color: rgba(255, 255, 255, 0.25);

    td.embedded {
      padding: 8px;
    }

    tr {
      background-color: ${colors.dutchie.lightGrey};
    }
  }

  tbody > tr.parent.drop-active {
    background-color: rgb(255, 245, 207);
  }

  td.child.placeholder {
    background-color: inherit;
  }
`;
