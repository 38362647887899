import React from 'react';
import styled from 'styled-components';
import { HardwareService } from '@dutchie/capacitor-hardware';
import { PrinterIcon } from 'assets/icons/settings/PrinterIcon';
import { Row } from 'components/layout';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useFulfillmentReceiptPrinterSettings } from '../hooks/useFulfillmentReceiptPrinterSettings';
import { Button } from 'components/buttons';
import { useTestReceipts } from 'util/hooks/printing/useTestReceipt';

export const FulfillmentReceiptPrinterTestPrintListItem = () => {
  const { formValues } = useFulfillmentReceiptPrinterSettings();
  const { printPickList: printTestReceipt } = useTestReceipts();
  const printer = HardwareService.receiptPrinter.deviceById(formValues.fulfillmentPrinterId);
  const disabled = !formValues.fulfillmentPrinterId || !printer;

  return (
    <SettingsListItem
      title='Print test fulfillment ticket'
      subtitle='Test fulfillment ticket printer functionality'
      actions={[
        <Button
          automationId='fulfillment_printer_settings-test_print_button'
          type='button'
          secondary
          disabled={disabled}
          onClick={() => printTestReceipt(formValues.fulfillmentPrinterId)}
        >
          <StyledRow>
            <PrinterIcon />
            Print
          </StyledRow>
        </Button>,
      ]}
    />
  );
};

const StyledRow = styled(Row)`
  align-items: center;
  gap: 10px;
`;
