import React from 'react';
import styled from 'styled-components';

import { CheckedInGuest } from 'models/Guest';
import { InfoContainer, WithTopBorder } from '../../styles';
import { Pill } from 'components/misc/Pill';
import { useCardPills } from './useCardPills';
import { LoyaltyPill } from './components/LoyaltyPill';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

type CardPillsProps = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
  listMode: boolean;
};
export const CardPills: React.FC<CardPillsProps> = React.memo(({ guest, cardOptionsStatus, listMode }) => {
  const { pills, displayPillSection } = useCardPills({ guest, cardOptionsStatus });

  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  return (
    <InfoContainer listMode={listMode} gridArea={listMode ? '2/3' : '4/1'} placeSelf={listMode ? 'end' : 'start'}>
      {displayPillSection && (
        <WithTopBorder listMode={listMode} paddingBottom={isCustomerInsightsEnabled ? guest.IsAnonymous : true}>
          <CardFlexContainer listMode={listMode}>
            {pills.map((pill) => <Pill text={pill.label} variation={pill.variation} automationId={pill.key}></Pill>)}
            <LoyaltyPill guest={guest} cardOptionsStatus={cardOptionsStatus} />
          </CardFlexContainer>
        </WithTopBorder>
      )}
    </InfoContainer>
  );
});

const CardFlexContainer = styled.div<{ listMode?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ listMode }) => (listMode ? 'column' : 'row')};
  text-align: ${({ listMode }) => (listMode ? 'right' : 'left')};
  gap: 5px;
  flex-wrap: wrap;
`;
