import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const DigitalIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <g clip-path='url(#clip0_815_70149)'>
            <rect x='4' y='0.75' width='16' height='22.5' rx='2.25' stroke='#828A8F' stroke-width='1.5' />
            <path
              d='M12 6L8 9H16L12 6Z'
              stroke='#828A8F'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path d='M8 14H16' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            <path d='M4 20H19' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            <path d='M9 11V13.8' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            <path d='M12 11V13.8' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            <path d='M15 11V13.8' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          </g>
          <defs>
            <clipPath id='clip0_815_70149'>
              <rect width={width} height={height} fill='white' />
            </clipPath>
          </defs>
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <g clip-path='url(#clip0_815_70232)'>
          <rect x='4' y='0.5' width='16' height='23' rx='3' fill='#FFF0DB' />
          <rect x='4' y='0.75' width='16' height='22.5' rx='2.25' stroke='#FF9E3E' stroke-width='1.5' />
          <path
            d='M12 6L8 9H16L12 6Z'
            stroke='#FF9E3E'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path d='M8 14H16' stroke='#FF9E3E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M4 20H19' stroke='#FF9E3E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M9 11V13.8' stroke='#FF9E3E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M12 11V13.8' stroke='#FF9E3E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M15 11V13.8' stroke='#FF9E3E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_815_70232'>
            <rect width={width} height={height} fill='white' />
          </clipPath>
        </defs>
      </g>
    </svg>
  );
};
