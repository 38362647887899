import React from 'react';
import { useRowSelect } from '../util/rowSelect';
import { useTableStore } from '../util/useTableStore';
import { usePaginationDetails } from '../util/usePaginationDetails';

export const TableBody: React.FC = () => {
  const {
    dataMatrix,
    props: { onClick },
  } = useTableStore();
  const rowSelect = useRowSelect();
  const pagination = usePaginationDetails();
  const hasPagination = pagination !== null;
  return (
    <tbody className={hasPagination ? 'hasFooter' : undefined} data-testid='tablebody_tbody_transaction-history'>
      {dataMatrix.map((row) => (
        <tr
          data-testid='tablebody_tr_row'
          key={row.key}
          onClick={onClick ? () => onClick(row.item) : undefined}
          className={[onClick ? 'clickable' : '', row.selected ? 'selected' : ''].join(' ')}
        >
          {rowSelect.isEnabled && (
            <td data-testid='tablebody_td_column'>
              <input
                data-testid='tablebody_input_checkbox'
                type='checkbox'
                checked={rowSelect.isSelected(row.item)}
                onChange={() => rowSelect.toggleItem(row.item)}
              />
            </td>
          )}
          {row.cells.map((cell) => (
            <td data-testid='tablebody_td_column_cell' key={cell.key}>
              {cell.contents}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
