import { useHistory } from 'react-router';

import { createAnonymousCustomer } from 'api/GuestApi';
import { customEventKeys } from 'util/logger';
import { errorNotification } from 'store/actions/NotificationsActions';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import {
  showCreatePreOrderPopup,
  showFulfillmentSelectorPopup,
  showVerifyBirthdatePopup,
  closePopup,
} from 'store/actions/PopupsActions';
import { useAppDispatch, useAppSelector } from '../useAppDispatch';
import { useCheckPreorderEligible } from '../useCheckPreorderEligible';
import { useMetrics } from 'util/metrics/useMetrics';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { PreorderMetadataValues } from 'models/Customer';

export const useStartAnonymousOrder = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const metrics = useMetrics();

  const isConfirmBirthdayOnCheckinEnabled = useAppSelector((state) => state.settings.features.ConfirmBirthdayOnCheckin);

  const canCreatePreorder = useCheckPreorderEligible();
  const { goToCart } = useTransactionManager();

  /** Opens an empty cart to start anonymous order */
  const openAnonymousCart = async () => {
    metrics.addTimestamp(customEventKeys.metrics.timestamps.clickAnonymousGuestButton);
    goToCart();
  };

  /** Creates a new anonymous guest and opens the preorder page */
  const createAnonymousPreorder = async (val: PreorderMetadataValues) => {
    try {
      // Create a new anonymous customer
      const response = await createAnonymousCustomer({
        Dob: val.dob?.toDateString(),
        Gender: val.gender,
        PostalCode: val.postalCode,
        CustomerTypeId: val.customerTypeId,
      });

      // Get the customer details and go to the create preorder page
      await dispatch(getCustomerDetails({ guestId: response.AcctId }));
      dispatch(closePopup('createPreOrderPopup'));
      history.push('/create-preorder');
    } catch (e) {
      dispatch(errorNotification(`Error creating anonymous transaction: ${e}`));
    }
  };

  /** Checks birthdate settings then prompts for preorder metadata */
  const configureAnonymousPreorder = async (anonymousDob?: Date) => {
    const showPreorderPopup = (anonymousDob?: Date) =>
      dispatch(
        showCreatePreOrderPopup({
          onCheckIn: createAnonymousPreorder,
          anonymousDob,
        })
      );

    // Check if the birthdate on check in flag is enabled and open popup accordingly
    if (isConfirmBirthdayOnCheckinEnabled && anonymousDob === undefined) {
      dispatch(showVerifyBirthdatePopup({ onSuccess: showPreorderPopup }));
      return;
    }

    showPreorderPopup(anonymousDob);
  };

  const startAnonymousOrder = () => {
    if (canCreatePreorder()) {
      dispatch(
        showFulfillmentSelectorPopup({
          onGoToCart: openAnonymousCart,
          onCreatePreorder: configureAnonymousPreorder,
        })
      );
      return;
    }

    return openAnonymousCart();
  };

  return {
    configureAnonymousPreorder,
    openAnonymousCart,
    startAnonymousOrder,
  };
};
