import { useSelector } from 'react-redux';
import { State } from 'store';

import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

import { isDateBeforeToday } from 'util/helpers/date-helpers/isDateBeforeToday';

import type { ProductSearchResult } from 'queries/v2/product/types';

type UseMETRCUseByDateCheck = {
  showUseByDateAlert: boolean;
};

type UseMETRCUseByDateCheckArgs = {
  product: ProductSearchResult | undefined;
};

export function useMETRCUseByDateCheck({ product }: UseMETRCUseByDateCheckArgs): UseMETRCUseByDateCheck {
  const isMETRCUseByDateEnabled =
    useSelector((state: State) => state.settings.integrations?.UseMETRCUseByDate) ?? false;

  // hooks
  const {
    data: { Cart: cartItems },
  } = useGetCartDetails();

  // computed values
  const isItemAlreadyInCart = cartItems.some((item) => item.SerialNo === product?.serialNo);
  const isProductUseByDateInThePast = !!product?.useByDate && isDateBeforeToday(product.useByDate);

  const showUseByDateAlert = isMETRCUseByDateEnabled && !isItemAlreadyInCart && isProductUseByDateInThePast;

  return { showUseByDateAlert };
}
