import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { checkManagerPin } from 'util/Helpers';
import { logger } from 'util/logger';

import { VoidTransactionServerResponse, TransactionDetail, transformToTransactionDetail } from './types';

const VOID_TRANSACTION = 'v2/cart/Void_Transaction';

type VoidTransactionPayload = {
  isCashVoid?: boolean;
  managerPin?: string;
  registerId?: number;
  transactionId?: number;
  voidReason?: string;
};

export const transformToServerPayload = (payload: VoidTransactionPayload, managerUserId: number) => {
  return {
    isCashVoid: payload.isCashVoid,
    PinUserId: managerUserId.toString(),
    Register: payload.registerId,
    TransactionId: payload.transactionId,
    VoidReason: payload.voidReason,
  };
};

type UseVoidTransactionMutationOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useVoidTransactionMutation = (options?: UseVoidTransactionMutationOptions) => {
  return useMutation(
    async (payload: VoidTransactionPayload): Promise<TransactionDetail[]> => {
      // We need to look up the manager's user id based on the pin they entered
      const [{ UserId: foundManagerUserId }] = await checkManagerPin(payload.managerPin ?? '');

      const response = await post<VoidTransactionServerResponse[]>(
        VOID_TRANSACTION,
        transformToServerPayload(payload, foundManagerUserId)
      );

      return response.map(transformToTransactionDetail);
    },
    {
      onSuccess: () => {
        // We'll update this to add the query invalidation once we load transactions with react-query
        options?.onSuccess?.();
      },
      onError: (error, { transactionId }) => {
        logger.error(error, { message: 'Error occurred voiding transaction', transactionId });
        options?.onError?.();
      },
    }
  );
};
