import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { Label } from '../text';

import type { InputComponentProps, InputContainerProps, InputWrapperProps } from './types';

export const Input = forwardRef<HTMLInputElement | null, InputComponentProps>((props, ref) => {
  const {
    autoComplete = 'off',
    automationId,
    containerWidth,
    containerMargin = '0',
    inputHeight,
    label,
    type = 'text',
    endAdornment,
    startAdornment,
    disabled,
    hasError,
    ...restProps
  } = props;
  const inputId = label?.replace(/ /g, '-');
  const automationIdTitle = automationId ?? (inputId ? `input_${inputId}` : `input-base`);

  return (
    <InputContainer containerWidth={containerWidth} containerMargin={containerMargin} automationId={automationIdTitle}>
      {label?.length && (
        <Label hasError={hasError} htmlFor={inputId}>
          {label}
        </Label>
      )}
      <InputWrapper
        disabled={disabled}
        startAdornment={Boolean(startAdornment)}
        hasError={hasError}
        inputHeight={inputHeight}
        withDarkBackground={restProps.withDarkBackground}
      >
        {startAdornment}
        {type === 'number' ? (
          <NumberFormat inputMode='numeric' id={inputId} disabled={disabled} {...restProps} />
        ) : (
          <input ref={ref} autoComplete={autoComplete} {...restProps} id={inputId} disabled={disabled} type={type} />
        )}
        {endAdornment}
      </InputWrapper>
    </InputContainer>
  );
});

const InputContainer = styled.div.attrs((props: InputContainerProps) => ({
  'data-testid': props.automationId,
}))<InputContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ containerWidth }) => containerWidth ?? '100%'};
  margin: ${({ containerMargin }) => containerMargin ?? '0.75rem 0'};
`;

const InputWrapper = styled.div<InputWrapperProps>`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${({ hasError }) => (hasError ? colors.dutchie.red : colors.dutchie.borderGrey)};
  border-radius: 0.375rem;
  padding: 0.75rem;
  height: ${({ inputHeight }) => inputHeight ?? '3rem'};
  display: flex;
  align-items: center;
  background-color: ${({ disabled, withDarkBackground }) =>
    withDarkBackground ? 'white' : disabled ? colors.dutchie.lightGrey : 'rgba(0,0,0,0)'};
  color: ${({ hasError }) => (hasError ? colors.dutchie.red : colors.realBlack)};

  &:focus-within {
    box-shadow: none;
    border-color: ${colors.dutchie.almostBlack};
    outline: none;
  }

  & input {
    flex: 1;
    min-width: 0;
    height: 100%;
    font-size: 1rem;
    border: none;
    padding: ${({ startAdornment }) => (startAdornment ? '0 0 0 .5rem' : '0')};
    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: ${colors.dutchie.lightGrey};
      & + button:disabled {
        color: ${colors.dutchie.gray50} !important;
      }
    }
    ${({ hasError }) => hasError && `::placeholder {color: ${colors.dutchie.red};}`}
  }

  & svg {
    max-height: 30px;
    max-width: 30px;
    margin: 0;
  }
`;
