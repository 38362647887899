import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PreorderMetadataValues } from 'models/Customer';
import { CheckedInGuest } from 'models/Guest';
import { BarcodeScanEntryResponse } from 'queries/v2/guest/barcode-scan-entry';

import {
  showAnonymousTransactionPopup,
  showCancelTransactionPopup,
  showCreatePreOrderPopup,
  showFulfillmentSelectorPopup,
  showNotifyCustomerPopup,
  showReleasePopup,
  showSecurityCheckinPopup,
  showSelectCollectorPopup,
  showSetStatusPopup,
  showSetTransactionReferencePopup,
  showVerifyBirthdatePopup,
  showVersionCheckerPopup,
  showVisitReasonPopup,
  showScanPopup,
  showScanDetailsPopup,
  showBannedCustomerPopup,
  showCustomerInsightsPopup,
  closeAllPopups,
} from 'store/actions/PopupsActions';
import { PopupsState } from '.';

import { getIsAnonymousCartLDFlagEnabled } from 'pages/CartPage/hooks/useAnonymousCart';

const { isAnonymousCartLDFlagEnabled: isGlobalPopupsEnabled } = getIsAnonymousCartLDFlagEnabled();

export type GuestListPopupsPayloads = {
  visitReasonPopup: { shipmentId: number };
  notifyCustomerPopup: { guest: CheckedInGuest };
  anonymousTransactionPopup: { dob?: Date };
  securityCheckinPopup: {};
  versionCheckerPopup: {};
  setTransactionReferencePopup: { guest: CheckedInGuest };
  /** @deprecated To be removed once we migrate to global popups */
  fulfillmentSelectorPopup: { onGoToCart: () => Promise<void>; onCreatePreorder: () => Promise<void> };
  cancelTransactionPopup: { guest: CheckedInGuest };
  setStatusPopup: { guest: CheckedInGuest };
  releasePopup: { guest: CheckedInGuest };
  /** @deprecated To be removed once we migrate to global popups */
  selectCollectorPopup: { customerId: number; shipmentId: number };
  /** @deprecated To be removed once we migrate to global popups */
  createPreOrderPopup: { onCheckIn: (values: PreorderMetadataValues) => void; anonymousDob?: Date };
  /** @deprecated To be removed once we migrate to global popups */
  verifyBirthdatePopup: { onSuccess: (dob?: Date) => void };
  scanPopup: {};
  scanDetailsPopup: { barcodeScanEntryResponse: BarcodeScanEntryResponse };
  bannedCustomerPopup: { guest: CheckedInGuest };
  customerInsightsPopup: { customerId: number; onGoToCart: () => Promise<void> };
};

export type GuestListPopupsState = {
  visitReasonPopup: GuestListPopupsPayloads['visitReasonPopup'] | null;
  notifyCustomerPopup: GuestListPopupsPayloads['notifyCustomerPopup'] | null;
  anonymousTransactionPopup: GuestListPopupsPayloads['anonymousTransactionPopup'] | null;
  securityCheckinPopup: GuestListPopupsPayloads['securityCheckinPopup'] | null;
  versionCheckerPopup: GuestListPopupsPayloads['versionCheckerPopup'] | null;
  setTransactionReferencePopup: GuestListPopupsPayloads['setTransactionReferencePopup'] | null;
  /** @deprecated To be removed once we migrate to global popups */
  fulfillmentSelectorPopup: GuestListPopupsPayloads['fulfillmentSelectorPopup'] | null;
  cancelTransactionPopup: GuestListPopupsPayloads['cancelTransactionPopup'] | null;
  setStatusPopup: GuestListPopupsPayloads['setStatusPopup'] | null;
  releasePopup: GuestListPopupsPayloads['releasePopup'] | null;
  /** @deprecated To be removed once we migrate to global popups */
  selectCollectorPopup: GuestListPopupsPayloads['selectCollectorPopup'] | null;
  /** @deprecated To be removed once we migrate to global popups */
  createPreOrderPopup: GuestListPopupsPayloads['createPreOrderPopup'] | null;
  /** @deprecated To be removed once we migrate to global popups */
  verifyBirthdatePopup: GuestListPopupsPayloads['verifyBirthdatePopup'] | null;
  scanPopup: GuestListPopupsPayloads['scanPopup'] | null;
  scanDetailsPopup: GuestListPopupsPayloads['scanDetailsPopup'] | null;
  bannedCustomerPopup: GuestListPopupsPayloads['bannedCustomerPopup'] | null;
  customerInsightsPopup: GuestListPopupsPayloads['customerInsightsPopup'] | null;
};

export const guestListPopupsInitialState: GuestListPopupsState = {
  visitReasonPopup: null,
  notifyCustomerPopup: null,
  anonymousTransactionPopup: null,
  securityCheckinPopup: null,
  versionCheckerPopup: null,
  setTransactionReferencePopup: null,
  fulfillmentSelectorPopup: null,
  cancelTransactionPopup: null,
  setStatusPopup: null,
  releasePopup: null,
  selectCollectorPopup: null,
  createPreOrderPopup: null,
  verifyBirthdatePopup: null,
  scanPopup: null,
  scanDetailsPopup: null,
  bannedCustomerPopup: null,
  customerInsightsPopup: null,
};

export const buildGuestListPopupsCases = (builder: ActionReducerMapBuilder<PopupsState>, initialState: PopupsState) => {
  builder.addCase(closeAllPopups, (state, _action) => ({
    ...state,
    ...guestListPopupsInitialState,
  }));
  builder.addCase(showVisitReasonPopup, (_state, action) => ({
    ...initialState,
    visitReasonPopup: action.payload,
  }));

  builder.addCase(showNotifyCustomerPopup, (_state, action) => ({
    ...initialState,
    notifyCustomerPopup: action.payload,
  }));

  builder.addCase(showAnonymousTransactionPopup, (_state, action) => ({
    ...initialState,
    anonymousTransactionPopup: action.payload,
  }));

  builder.addCase(showSecurityCheckinPopup, (_state, action) => ({
    ...initialState,
    securityCheckinPopup: action.payload,
  }));

  builder.addCase(showVersionCheckerPopup, (_state, action) => ({
    ...initialState,
    versionCheckerPopup: action.payload,
  }));

  builder.addCase(showSetTransactionReferencePopup, (_state, action) => ({
    ...initialState,
    setTransactionReferencePopup: action.payload,
  }));

  if (!isGlobalPopupsEnabled) {
    builder.addCase(showFulfillmentSelectorPopup, (_state, action) => ({
      ...initialState,
      fulfillmentSelectorPopup: action.payload,
    }));
  }

  builder.addCase(showCancelTransactionPopup, (_state, action) => ({
    ...initialState,
    cancelTransactionPopup: action.payload,
  }));

  builder.addCase(showSetStatusPopup, (_state, action) => ({
    ...initialState,
    setStatusPopup: action.payload,
  }));

  builder.addCase(showReleasePopup, (_state, action) => ({
    ...initialState,
    releasePopup: action.payload,
  }));

  if (!isGlobalPopupsEnabled) {
    builder.addCase(showSelectCollectorPopup, (_state, action) => ({
      ...initialState,
      selectCollectorPopup: action.payload,
    }));

    builder.addCase(showCreatePreOrderPopup, (_state, action) => ({
      ...initialState,
      createPreOrderPopup: action.payload,
    }));

    builder.addCase(showVerifyBirthdatePopup, (_state, action) => ({
      ...initialState,
      verifyBirthdatePopup: action.payload,
    }));
  }

  builder.addCase(showScanPopup, (_state, action) => ({
    ...initialState,
    scanPopup: action.payload,
  }));
  builder.addCase(showScanDetailsPopup, (_state, action) => ({
    ...initialState,
    scanDetailsPopup: action.payload,
  }));
  builder.addCase(showBannedCustomerPopup, (_state, action) => ({
    ...initialState,
    bannedCustomerPopup: action.payload,
  }));
  builder.addCase(showCustomerInsightsPopup, (_state, action) => ({
    ...initialState,
    customerInsightsPopup: action.payload,
  }));
};
