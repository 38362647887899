import React from 'react';

type UpsellIconProps = {
  height?: number;
  width?: number;
};

export function UpsellIcon(props: UpsellIconProps) {
  const { height = 132, width = 132 } = props;
  return (
    <svg width={width} height={height} viewBox='0 0 132 132' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_4350_5767)'>
        <circle opacity='0.08' cx='66' cy='65.5' r='66' fill='#8DA1B4' />
        <circle opacity='0.1' cx='65.9737' cy='66.4737' r='52.9737' fill='#446583' />
        <path
          d='M14.1288 21.25H117.183C119.601 21.25 121.562 23.2105 121.562 25.6288V91.8783C121.562 92.6157 120.964 93.2136 120.226 93.2136C118.875 93.2136 117.711 94.1674 117.446 95.4927L115.168 106.88C114.758 108.927 112.961 110.4 110.874 110.4H80.041C77.8911 110.4 76.0593 108.839 75.7177 106.717L74.3406 98.1584C73.882 95.3088 71.4227 93.2136 68.5364 93.2136H62.6164C59.7537 93.2136 57.3071 95.2757 56.8225 98.0971L55.3339 106.763C54.9729 108.864 53.1505 110.4 51.0182 110.4H21.1275C19.0368 110.4 17.2378 108.922 16.832 106.871L14.6441 95.8148C14.345 94.303 13.0188 93.2136 11.4778 93.2136C10.5235 93.2136 9.75 92.44 9.75 91.4858V25.6288C9.75 23.2105 11.7105 21.25 14.1288 21.25Z'
          fill='white'
          stroke='#91A1B1'
          stroke-width='1.5'
        />
        <rect
          x='14.3424'
          y='26.0566'
          width='102.577'
          height='62.1871'
          rx='1.70961'
          fill='#F5F6F8'
          stroke='#91A1B1'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <rect x='19.4474' y='37.158' width='27.7268' height='40' rx='2.9008' fill='white' />
        <rect
          x='19.4474'
          y='37.158'
          width='27.7268'
          height='40'
          rx='2.9008'
          stroke='#91A1B1'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <rect x='23.8108' y='47.658' width='19' height='19' rx='9.5' fill='#C1D3F1' />
        <rect x='23.8108' y='47.658' width='19' height='19' rx='9.5' stroke='#3387E1' />
        <path
          d='M29.3108 57.158C29.3108 56.7048 29.6781 56.3374 30.1313 56.3374H36.4903C36.9434 56.3374 37.3108 56.7048 37.3108 57.158C37.3108 57.6111 36.9434 57.9785 36.4903 57.9785H30.1313C29.6781 57.9785 29.3108 57.6111 29.3108 57.158Z'
          fill='#3387E1'
        />
        <path
          d='M33.3108 61.158C32.8576 61.158 32.4903 60.7906 32.4903 60.3374V53.9785C32.4903 53.5253 32.8576 53.158 33.3108 53.158C33.7639 53.158 34.1313 53.5253 34.1313 53.9785V60.3374C34.1313 60.7906 33.7639 61.158 33.3108 61.158Z'
          fill='#3387E1'
        />
        <rect x='51.5839' y='37.158' width='27.7268' height='40' rx='2.9008' fill='white' />
        <rect
          x='51.5839'
          y='37.158'
          width='27.7268'
          height='40'
          rx='2.9008'
          stroke='#91A1B1'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <rect x='55.9474' y='47.658' width='19' height='19' rx='9.5' fill='#C1D3F1' />
        <rect x='55.9474' y='47.658' width='19' height='19' rx='9.5' stroke='#3387E1' />
        <path
          d='M61.4474 57.158C61.4474 56.7048 61.8147 56.3374 62.2679 56.3374H68.6268C69.08 56.3374 69.4474 56.7048 69.4474 57.158C69.4474 57.6111 69.08 57.9785 68.6268 57.9785H62.2679C61.8147 57.9785 61.4474 57.6111 61.4474 57.158Z'
          fill='#3387E1'
        />
        <path
          d='M65.4474 61.158C64.9942 61.158 64.6268 60.7906 64.6268 60.3374V53.9785C64.6268 53.5253 64.9942 53.158 65.4474 53.158C65.9005 53.158 66.2679 53.5253 66.2679 53.9785V60.3374C66.2679 60.7906 65.9005 61.158 65.4474 61.158Z'
          fill='#3387E1'
        />
        <rect x='83.7205' y='37.158' width='27.7268' height='40' rx='2.9008' fill='white' />
        <rect
          x='83.7205'
          y='37.158'
          width='27.7268'
          height='40'
          rx='2.9008'
          stroke='#91A1B1'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <rect x='88.0839' y='47.658' width='19' height='19' rx='9.5' fill='#C1D3F1' />
        <rect x='88.0839' y='47.658' width='19' height='19' rx='9.5' stroke='#3387E1' />
        <path
          d='M93.5839 57.158C93.5839 56.7048 93.9513 56.3374 94.4045 56.3374H100.763C101.217 56.3374 101.584 56.7048 101.584 57.158C101.584 57.6111 101.217 57.9785 100.763 57.9785H94.4045C93.9513 57.9785 93.5839 57.6111 93.5839 57.158Z'
          fill='#3387E1'
        />
        <path
          d='M97.5839 61.158C97.1308 61.158 96.7634 60.7906 96.7634 60.3374V53.9785C96.7634 53.5253 97.1308 53.158 97.5839 53.158C98.0371 53.158 98.4045 53.5253 98.4045 53.9785V60.3374C98.4045 60.7906 98.0371 61.158 97.5839 61.158Z'
          fill='#3387E1'
        />
      </g>
      <defs>
        <clipPath id='clip0_4350_5767'>
          <rect width='132' height='132' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
