import React from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { ErrorBoundary } from 'react-error-boundary';

// utils
import { colors } from 'css/Theme';
import { logger } from 'util/logger';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { openCustomerSelectionPanel } from 'store/actions/CartActions';
import { useGetSegmentIcon } from 'util/hooks/useGetSegmentIcon';
import { useCustomerInsightsSegmentsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsSegmentsFlag';

// assets
import { LockedIcon } from 'assets/icons/cart/LockedIcon';

// queries
import { useAnalyticsQuery } from 'queries/personalization/analytics';
import { useSegmentsQuery } from 'queries/personalization/segments';

// global components
import { Pill } from 'components/misc/Pill';
import { AlertBanner, AlertBannerStyles, Skeleton } from 'components/misc';

// local components
import { Container, Header, Title, StyledLinkButton } from 'components/cart/TotalsSidebarSection.styles';

export type CustomerInsightsProps = {
  isLoading?: boolean;
  showBottomBorder?: boolean;
};

type AnalyticsProps = {
  customerId: number;
};

export function formatDate(isoString: string | null): string | null {
  if (!isoString) {
    return null;
  }

  const date = parseISO(isoString);
  return format(date, 'MMMM d, yyyy');
}

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}

function Analytics(props: AnalyticsProps) {
  const { customerId } = props;

  const { data, isLoading, isError } = useAnalyticsQuery({ customerId });

  if (isError) {
    return (
      <AlertBanner
        marginTop='0'
        style={AlertBannerStyles.error}
        title='Error:'
        text='Unable to load customer analytics'
      />
    );
  }

  if (isLoading) {
    return (
      <AnalyticsContainer data-testid='customer-insights-analytics-loading-state'>
        <Skeleton height={62} width='50%' />
        <Skeleton height={62} width='50%' />
      </AnalyticsContainer>
    );
  }

  if (data?.avg_spend_last_90 && data?.last_order_date_local) {
    return (
      <AnalyticsContainer>
        <AnalyticsCard>
          <AnalyticsCardTitle>Avg. cart over last 90 days</AnalyticsCardTitle>
          <AnalyticsCardValue>{formatCurrency(data.avg_spend_last_90)}</AnalyticsCardValue>
        </AnalyticsCard>
        <AnalyticsCard>
          <AnalyticsCardTitle>Last purchase date</AnalyticsCardTitle>
          <AnalyticsCardValue>{formatDate(data.last_order_date_local)}</AnalyticsCardValue>
        </AnalyticsCard>
      </AnalyticsContainer>
    );
  }

  return (
    <AnalyticsMessage>
      <span>Insights available after 1 completed order</span>
      <LockedIcon />
    </AnalyticsMessage>
  );
}

type SegmentsProps = {
  customerId: number;
};

function Segments(props: SegmentsProps) {
  const { customerId } = props;

  const getSegmentIcon = useGetSegmentIcon();

  const { data, isError, isLoading } = useSegmentsQuery({ customerId });

  if (isError) {
    return (
      <AlertBanner
        marginTop='0'
        style={AlertBannerStyles.error}
        title='Error:'
        text='Unable to load customer segments'
      />
    );
  }

  if (isLoading) {
    return (
      <SegmentsContainer data-testid='customer-insights-segments-loading-state'>
        <Skeleton height={24} width={98} />
        <Skeleton height={24} width={129} />
        <Skeleton height={24} width={73} />
      </SegmentsContainer>
    );
  }

  if (data?.length) {
    return (
      <SegmentsContainer>
        {data.map((segment) => (
          <Pill
            icon={getSegmentIcon(segment.Icon)}
            text={segment.BadgeName}
            textColor='#ffffff'
            backgroundColor={segment.Color}
          />
        ))}
      </SegmentsContainer>
    );
  }

  return null;
}

export function useShowCustomerInsights() {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const isAnonymous = useAppSelector((state) => state.customer.details?.IsAnonymous) ?? true;

  return !isAnonymous || isAnonymousCartLDFlagEnabled;
}

function CustomerInsightsImplementation(props: CustomerInsightsProps) {
  const { isLoading, showBottomBorder = true } = props;

  const dispatch = useAppDispatch();

  const showSegments = useCustomerInsightsSegmentsFlag();
  const showCustomerInsights = useShowCustomerInsights();

  const customerId = useAppSelector((state) => state.customer.details?.Guest_id) ?? 0;
  const isAnonymous = useAppSelector((state) => state.customer.details?.IsAnonymous) ?? true;

  const handleAssignCustomerButtonClick = () => {
    dispatch(openCustomerSelectionPanel());
  };

  if (!showCustomerInsights) {
    return null;
  }

  return (
    <Container showBottomBorder={showBottomBorder} gap='0.5rem' data-testid='customer-insights-container'>
      <Header style={{ padding: '0.5rem 0' }}>
        <Title>Customer insights</Title>
        {isAnonymous && (
          <StyledLinkButton onClick={handleAssignCustomerButtonClick} label='Assign customer' disabled={isLoading} />
        )}
      </Header>

      {isAnonymous ? (
        <AnalyticsMessage data-testid='customer-insights_locked-message'>
          <span>Assign customer to view insights</span>
          <LockedIcon />
        </AnalyticsMessage>
      ) : (
        <>
          <Analytics customerId={customerId} />
          {showSegments && <Segments customerId={customerId} />}
        </>
      )}
    </Container>
  );
}

export function CustomerInsights(props: CustomerInsightsProps) {
  const handleError = (error: Error, errorInfo: { componentStack: string }) => {
    logger.error(error, { componentStack: errorInfo.componentStack });
  };

  return (
    <ErrorBoundary fallback={null} onError={handleError}>
      <CustomerInsightsImplementation {...props} />
    </ErrorBoundary>
  );
}

const AnalyticsContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const AnalyticsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 12px;
  background: ${colors.dutchie.grey10};
  color: ${colors.dutchie.gray80};
  font-size: 16px;
  font-weight: 600;
`;

const AnalyticsCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${colors.dutchie.grey10};
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
`;

const AnalyticsCardTitle = styled.span`
  color: ${colors.dutchie.grey70};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

const AnalyticsCardValue = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const SegmentsContainer = styled.div`
  display: flex;
  gap: 8px;
`;
