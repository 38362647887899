import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { breakpoints, colors, headerHeight, panelWidth } from 'css/Theme';
import { ProductSearchFilter } from 'components/tables/ProductSearchFilter';
import { ProductsPreviewPanel } from './components/ProductsPreviewPanel';
import { ProvideProductsPopups } from 'components/ProductsPopups';
import { SearchIcon } from 'components/backoffice/icons/search-icon';

import type { ProductSearchResult } from 'queries/v2/product/types';

export const ProductsPageWithoutPopups: FC = () => {
  const [selectedProduct, setSelectedProduct] = useState<ProductSearchResult>();

  return (
    <ProductsPageWrapperDiv>
      <ProductsPageLeft>
        <ProductsPageHeader>
          <h1>Products</h1>
        </ProductsPageHeader>
        <ProductsPageContent>
          <ProductSearchFilter selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
        </ProductsPageContent>
      </ProductsPageLeft>
      <ProductsPageRight data-testid='products_preview-panel'>
        {selectedProduct ? (
          <ProductsPreviewPanel selectedProduct={selectedProduct} />
        ) : (
          <NoActiveProductContainer>
            <IconContainer>
              <SearchIcon width='55' height='55' color='#C1C7CA' />
            </IconContainer>
            <p>Search for Products</p>
          </NoActiveProductContainer>
        )}
      </ProductsPageRight>
    </ProductsPageWrapperDiv>
  );
};

export const ProductsPage = () => (
  <ProvideProductsPopups>
    <ProductsPageWithoutPopups />
  </ProvideProductsPopups>
);

const ProductsPageLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ProductsPageRight = styled.div`
  border-left: 1px solid ${colors.borderGrey};
  min-width: ${panelWidth.minWidth};
  width: ${panelWidth.width};
  height: 100%;
  padding: 2rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const ProductsPageHeader = styled.div`
  border-bottom: 1px solid ${colors.borderGrey};
  padding: 1.5rem;
  & h1 {
    color: ${colors.dutchie.almostBlack};
    font-size: 24px;
    font-weight: bold;
  }
`;

const ProductsPageContent = styled.div`
  height: calc(100% - ${headerHeight} - 30px - 1rem);
  overflow: auto;
  margin: 0;
  padding: 1.5rem 0 0 1.5rem;
  flex-grow: 1;

  ${breakpoints.smallTablet.maxQuery} {
    margin: 2rem 0 0;
    padding: 0 1.5rem 0;
    height: calc(100% - ${headerHeight} - 30px - 2rem);
  }
`;

const NoActiveProductContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    color: ${colors.dutchie.grey};
    font-size: 18px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

const ProductsPageWrapperDiv = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
`;
