import React, { FC, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import * as api from 'api/GuestApi';
import { State, store } from 'store';
import { getGuests } from 'store/actions/GuestListActions';
import { ConfirmationPopup } from 'components/popups';
import { TextArea } from 'components/inputs';
import { useScanner } from 'components/Scanner';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';

const StyledTextArea = styled(TextArea)`
  margin: 0;
  flex: 2 0;
  height: 300px;
`;

const Fieldset = styled.div`
  display: flex;
  width: 640px;
  padding: 0 1.5rem;
  flex-direction: row;
  margin: 0 auto;
`;

type SecurityCheckinPopupProps = {
  hide: () => void;
};

export const SecurityCheckinPopup: FC<SecurityCheckinPopupProps> = ({ hide }) => {
  const isUseMCDMSEnabled = useSelector((state: State) => state.settings.integrations?.UseMCDMS);
  const [driverLicense, setDriverLicense] = useState<string>();
  const guests = useSelector((state: State) => state.guestList.guests);
  const dispatch = useDispatch<typeof store.dispatch>();
  const history = useHistory();
  const scanner = useScanner();

  const onScan = useCallback(
    async (barcode: string) => {
      if (barcode.length === 0) {
        return;
      }

      try {
        if (isUseMCDMSEnabled) {
          const mcdmsResponse = await api.mcdmsIdScan({ id: barcode });
          history.push(`/edit-customer?id=${mcdmsResponse.PatientId}`);
        } else {
          const response = await api.validatePatientId({ id: barcode });
          setDriverLicense('');
          const checkedIn = guests.filter((x) => {
            return x.Guest_id === response.PatientId;
          });
          if (!response.IsValid) {
            dispatch(errorNotification('Invalid Scan'));
          } else if (response.IsPatientBanned) {
            dispatch(errorNotification('Customer status is Banned'));
          } else if (response.IsMMJLicenseValid) {
            if (checkedIn.length > 0) {
              dispatch(warningNotification('User is already checked in'));
            } else {
              dispatch(successNotification('User checked in successfully'));
              dispatch(getGuests());
            }
          } else if (!response.IsMMJLicenseValid) {
            if (response.PatientExists) {
              dispatch(warningNotification('User MMJ License is not valid, loading profile.'));
              history.push(`/edit-customer?id=${response.PatientId}`);
            } else {
              history.push(`/edit-customer?mmj=${barcode[0] === '@' ? '' : barcode}`);
            }
          }
        }
        hide();
      } catch (e) {
        dispatch(errorNotification(`Error validating patient ID: ${e}`));
      }
    },
    [isUseMCDMSEnabled, hide, history, guests, dispatch]
  );

  useEffect(() => {
    scanner.changeAction(onScan);
  }, [scanner, onScan]);

  return (
    <ConfirmationPopup
      isVisible
      hide={hide}
      title='Check-In'
      medium
      confirm={{
        text: 'Scan',
        onClick: () => {
          if (driverLicense) {
            onScan(driverLicense);
          }
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      <Fieldset>
        <StyledTextArea
          placeholder={`Scan Driver's License, State ID, or Medical ID`}
          value={driverLicense}
          onChange={(e) => setDriverLicense(e.target.value)}
        />
      </Fieldset>
    </ConfirmationPopup>
  );
};
