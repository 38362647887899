import { useRef, useEffect } from 'react';
import {
  Peripheral as EloPeripheralLibrary,
  isNativeLabelPrinter,
  isNativeReceiptPrinter,
} from '@dutchie/capacitor-peripheral';

import { getStoredHardware } from 'util/hardwareLibrary/hardware-library-utils';
import type { State } from 'store';
import { customEventKeys, logger } from 'util/logger';
import { updateLabelPrinterStatus, updateReceiptPrinterStatus } from 'store/actions/SettingsActions';
import { useAppDispatch, useLabelPrinterConnection, useReceiptPrinterConnection } from '.';
import { useHandleStatusChange } from 'util/local-peripherals';
import { useHardwareLibrary } from './launch-darkly/useHardwareLibrary';
import {
  LabelPrinterStatusEventContext,
  SearchLabelPrintersContext,
  ReceiptPrinterStatusEventContext,
  SearchReceiptPrintersContext,
} from 'util/logger/types/printing';
import { getSearchLabelPrintersDescription, getSearchReceiptPrintersDescription } from 'util/logger/helpers/printing';
import { HardwareManager } from 'util/hardwareLibrary/hardware-library-manager';
import { useSelector } from 'react-redux';
import { useNewSettingsUi } from './launch-darkly/useNewSettingsUi';

export const useHardwareLibraryPackage = (): void => {
  const dispatch = useAppDispatch();
  const isLDFlagEnabled = useHardwareLibrary();
  const handleStatusChange = useHandleStatusChange();
  const hardwareManager = useRef<HardwareManager | null>(null);

  const isNewSettingsUi = useNewSettingsUi();

  const locationId = useSelector((state: State) => state.user.selectedLocation?.location_id);
  const lastLocationId = useRef<number | undefined>();

  const register = useSelector((state: State) => state.settings.selectedRegister);
  const registers = useSelector((state: State) => state.settings.registers);
  const selectedRegisterDetails = registers.find((it) => it.id === register?.value);
  const lastRegisterId = useRef<number | undefined>();

  // Handle library initialization and disposal
  useEffect(() => {
    if (isLDFlagEnabled && (!hardwareManager.current || hardwareManager.current.loadingState === 'idle')) {
      if (!hardwareManager.current) {
        hardwareManager.current = new HardwareManager(dispatch);
      }
      hardwareManager.current.initialize();
    } else if (!isLDFlagEnabled && hardwareManager.current?.loadingState === 'loaded') {
      // reset local ref values
      lastRegisterId.current = undefined;
      lastLocationId.current = undefined;

      hardwareManager.current.dispose();
    }
  }, [dispatch, isLDFlagEnabled]);

  // Clear printer preferences when the location changes and update printer list
  useEffect(() => {
    if (!isLDFlagEnabled || !locationId) {
      return;
    }

    // Only run on location change, not the initial setting
    if (lastLocationId.current && lastLocationId.current !== locationId) {
      hardwareManager.current?.invalidatePrintNodeServices();
    }

    lastLocationId.current = locationId;
  }, [dispatch, isLDFlagEnabled, locationId]);

  // Reset selected printers when the register id changes
  useEffect(() => {
    if (!isLDFlagEnabled || !selectedRegisterDetails) {
      return;
    }

    // calculate before lastRegisterId is set during async execution
    const isNewRegister = !lastRegisterId.current && !!selectedRegisterDetails.id;

    // Load PrintNode printers now that we have register details
    if (isNewRegister) {
      hardwareManager.current?.registerPrintNodeServices();
    } else {
      // When the register changes, select default printers for the new register based on current device lists
      hardwareManager.current?.resetDefaultPrinters();
    }

    lastRegisterId.current = selectedRegisterDetails.id;
  }, [dispatch, isLDFlagEnabled, selectedRegisterDetails]);

  useEffect(() => {
    // Ensures the services will be registered and unregistered as the LD rollout targeting changes
    hardwareManager.current?.registerBrowserApiServices(isNewSettingsUi);
  }, [isNewSettingsUi]);

  // Handle existing native printer status values
  useLabelPrinterConnection(async ({ peripheral, statusName, status }) => {
    if (isNewSettingsUi) {
      return;
    }

    logger.info<LabelPrinterStatusEventContext>(`label printer ${peripheral.id} status changed to ${statusName}`, {
      key: customEventKeys.printing.local.label.status,
      peripheral,
      statusName,
      status,
    });

    if (isLDFlagEnabled) {
      const stored = getStoredHardware();
      const isLabelPrinter = peripheral.id === stored.labelPrinter?.id;

      // Only update the status if the peripheral id matches the selected printer and the printer is local
      if (isLabelPrinter && stored.labelPrinter && isNativeLabelPrinter(stored.labelPrinter)) {
        handleStatusChange({ deviceType: 'Label printer', status });
      }

      // This try/catch block is only used for logging
      try {
        const { results: availablePrinters } = await EloPeripheralLibrary.searchLabelPrinters();

        logger.debug<SearchLabelPrintersContext>(getSearchLabelPrintersDescription(availablePrinters, false), {
          key: customEventKeys.printing.local.label.search,
          availablePrinters,
          userInitiated: false,
        });
      } catch (e) {
        logger.error(e, { message: 'searchLabelPrinters failed', userInitiated: false });
      }
    } else {
      dispatch(
        updateLabelPrinterStatus({
          deviceId: peripheral?.id?.toString(),
          status: statusName,
        })
      );
    }
  });

  useReceiptPrinterConnection(async ({ peripheral, statusName, status }) => {
    if (isNewSettingsUi) {
      return;
    }

    logger.info<ReceiptPrinterStatusEventContext>(`receipt printer ${peripheral.id} status changed to ${statusName}`, {
      key: customEventKeys.printing.local.receipt.status,
      peripheral,
      statusName,
      status,
    });

    if (isLDFlagEnabled) {
      // Get selected peripherals as hardware lib objects
      const stored = getStoredHardware();

      const isFulfillmentPrinter = peripheral.id === stored.fulfillmentPrinter?.id;
      const isReceiptPrinter = peripheral.id === stored.receiptPrinter?.id;

      // Only update the status if the peripheral id matches the selected printer and the printer is local
      if (isFulfillmentPrinter && stored.fulfillmentPrinter && isNativeReceiptPrinter(stored.fulfillmentPrinter)) {
        handleStatusChange({ deviceType: 'Fulfillment printer', status });
      } else if (isReceiptPrinter && stored.receiptPrinter && isNativeReceiptPrinter(stored.receiptPrinter)) {
        handleStatusChange({ deviceType: 'Receipt printer', status });
      }

      // This try/catch block is only used for logging
      try {
        const { results: availablePrinters } = await EloPeripheralLibrary.searchReceiptPrinters();

        logger.debug<SearchReceiptPrintersContext>(getSearchReceiptPrintersDescription(availablePrinters, false), {
          key: customEventKeys.printing.local.receipt.search,
          availablePrinters,
          userInitiated: false,
        });
      } catch (e) {
        logger.error(e, { message: 'searchReceiptPrinters failed', userInitiated: false });
      }
    } else {
      dispatch(
        updateReceiptPrinterStatus({
          deviceId: peripheral?.id?.toString(),
          status: statusName,
        })
      );
    }
  });
};
