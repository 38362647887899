import React, { FC } from 'react';
import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { ReactComponent as WarningIcon } from 'assets/icons/icon-warning.svg';
import {
  CancelIntegratedPaymentTransactionBase,
  Message,
  Buttons,
  CancelButton,
  ConfirmButton,
  CancelContainerLeft,
  SubjectInput,
  BackButton,
  Red,
} from './styles';
import { CancelIntegratedPaymentChildProps, CancelStates } from '.';
import { Label } from 'components/text';

export const CancelWithoutRefund: FC<CancelIntegratedPaymentChildProps> = ({
  cancelReason,
  amount,
  setCancelReason,
  setCancelState,
  cancelNoRefund,
  hide,
}) => {
  return (
    <CancelIntegratedPaymentTransactionBase caption='Cancel order without refund' isVisible hide={hide}>
      <div data-testid='cancel-integrated-payment_cancel-without-refund'>
        <CancelContainerLeft>
          <AlertBanner
            style={AlertBannerStyles.warning}
            title='You are cancelling without a refund.'
            text='Discrepancies in reporting may occur if debit payment is captured and the sale is not finalized.'
            icon={<WarningIcon />}
            marginTop='0px'
          />
          <div>
            <Label>
              <b>
                Cancellation reason:<Red>*</Red>
              </b>
            </Label>
            <SubjectInput
              placeholder='Type reason'
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            />
          </div>
          <Message>
            <b>Note:</b> To record the sale, you can create a new cart with the same items, enter the payment as cash,
            and adjust the payment type later.
          </Message>
          <Buttons>
            <BackButton
              type='button'
              data-testid='confirmation-popup_cancel-button_cancel'
              onClick={() => setCancelState(CancelStates.budtenderPrompt)}
            >
              Back
            </BackButton>
            <CancelButton type='button' data-testid='confirmation-popup_cancel-button_cancel' onClick={hide}>
              Cancel
            </CancelButton>
            <ConfirmButton
              type='button'
              automationId='confirmation-popup_confirm-button_confirm'
              disabled={cancelReason.length < 10}
              onClick={cancelNoRefund}
            >
              Confirm
            </ConfirmButton>
          </Buttons>
        </CancelContainerLeft>
      </div>
    </CancelIntegratedPaymentTransactionBase>
  );
};
