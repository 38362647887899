import React, { PropsWithChildren, createContext } from 'react';
import { LabelPrinterSettingsContextData } from '../types';
import { useLabelPrinterSettingsProvider } from '../hooks/useLabelPrinterSettingsProvider';

export const LabelPrinterSettingsContext = createContext<LabelPrinterSettingsContextData>(
  {} as LabelPrinterSettingsContextData
);

export const LabelPrinterSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useLabelPrinterSettingsProvider();
  return <LabelPrinterSettingsContext.Provider value={data}>{children}</LabelPrinterSettingsContext.Provider>;
};
