import { format as tz_format, utcToZonedTime } from 'date-fns-tz';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from 'util/helpers/date-helpers/formatDate';

import { ReactComponent as DutchiePayLogoSmall } from 'assets/dutchie-pay-logo-small.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { DisplayDateRange } from 'components/text';
import { colors } from 'css/Theme';
import { CheckedInGuest } from 'models/Guest';
import { hasLength } from 'util/Helpers';
import { getExpectedTimeWindow } from 'util/get-expected-time-window';
import { CardDetail } from './CardDetail';
import { OrderTotal } from './OrderTotal';
import { CardFieldValue, CardLabel, CardText, InfoContainer, WithTopBorder } from '../styles';

type Props = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
  listMode: boolean;
};

const usePaymentStatus = (guest: CheckedInGuest) => {
  let paymentStatus: string | JSX.Element = 'Unpaid';

  if (guest.IsPaid) {
    paymentStatus = 'Is Paid';
  }
  if (guest.HasDutchiePay) {
    paymentStatus = <StyledDutchiePayLogoSmall title='dutchiePay' />;
  }
  return paymentStatus;
};

export const OrderInfo: React.FC<Props> = React.memo(({ cardOptionsStatus, guest, listMode }) => {
  const paymentStatus = usePaymentStatus(guest);

  const { windowStart, windowEnd } = getExpectedTimeWindow(guest);

  const discountGroups = [
    ...(guest.DiscountGroup1 ? [guest.DiscountGroup1] : []),
    ...(guest.DiscountGroup2 ? [guest.DiscountGroup2] : []),
    ...(guest.DiscountGroup3 ? [guest.DiscountGroup3] : []),
    ...(guest.DiscountGroup4 ? [guest.DiscountGroup4] : []),
    ...(guest.DiscountGroup5 ? [guest.DiscountGroup5] : []),
  ];

  const dateReceived = guest.ShipmentDateUTC;

  const displayNumberOrTotalInCart =
    (cardOptionsStatus['Number of Items in Cart'] || cardOptionsStatus['Total Value of Items in Cart']) &&
    guest.TotalItems > 0;
  const displayNumberInCart = cardOptionsStatus['Number of Items in Cart'] && guest.TotalItems >= 0;
  const displayValueInCart = cardOptionsStatus['Total Value of Items in Cart'] && guest.OrderTotal > 0;
  const displayDateReceived = cardOptionsStatus['Date Received'] && hasLength(dateReceived);
  const displayTimeWindow = cardOptionsStatus['Time Window'] && windowStart && windowEnd;
  const displayLastPurchase = cardOptionsStatus['Last Purchase Date'] && hasLength(guest.LastPurchaseDate);
  const displayDiscountGroups = cardOptionsStatus['Discount Group'] && discountGroups.length > 0;
  const displayPaymentStatus = cardOptionsStatus['Payment Status'];
  const displayOrderSourceOrType = cardOptionsStatus['Order Source'] || cardOptionsStatus['Order Type'];
  const displayOrderSource = cardOptionsStatus['Order Source'] && hasLength(guest.OrderSource);
  const displayOrderSourceTypeSeparator =
    cardOptionsStatus['Order Source'] && cardOptionsStatus['Order Type'] && hasLength(guest.OrderSource);
  const displayOrderType = cardOptionsStatus['Order Type'] && hasLength(guest.OrderType);
  const displayDeliveryVehicle = cardOptionsStatus['Delivery Vehicle'] && hasLength(guest.CarName);
  const displayRegister = cardOptionsStatus['Register'] && hasLength(guest.Register);
  const displayRoom = cardOptionsStatus['Room'] && hasLength(guest.Room);
  const displayRefNumber = cardOptionsStatus['Transaction Reference Number'] && hasLength(guest?.TransactionReference);

  const displayAnyOrderDetail =
    displayDateReceived ||
    displayTimeWindow ||
    displayLastPurchase ||
    displayDiscountGroups ||
    displayPaymentStatus ||
    displayOrderSourceOrType ||
    displayOrderSource ||
    displayOrderSourceTypeSeparator ||
    displayOrderType ||
    displayRegister ||
    displayRoom ||
    displayRefNumber;

  const formattedDateReceived = dateReceived
    ? tz_format(
        utcToZonedTime(`${dateReceived}Z`, Intl.DateTimeFormat().resolvedOptions().timeZone),
        'LLL d, yyyy | h:mm a'
      )
    : '';

  // LastPurchaseDate is now coming from the backend set as local time not UTC
  // so no need to convert timezones here anymore
  const formattedLastPurchaseDate = guest.LastPurchaseDate
    ? formatDate({ date: guest.LastPurchaseDate, dateFormat: 'LLL d, yyyy | h:mm a' })
    : '';

  return (
    <InfoContainer listMode={listMode} gridArea={listMode ? '2/2' : '3/1'}>
      {displayNumberOrTotalInCart && (
        <WithTopBorder listMode={listMode} paddingBottom>
          <OrderTotal
            orderTotal={guest.OrderTotal}
            totalItems={guest.TotalItems}
            displayValueInCart={displayValueInCart}
            displayNumberInCart={displayNumberInCart}
          />
        </WithTopBorder>
      )}
      {displayAnyOrderDetail && (
        <WithTopBorder listMode={listMode} paddingBottom={displayDeliveryVehicle}>
          {displayDateReceived && (
            <CardDetail testId='order-card_date-received_p' label='Received' value={formattedDateReceived} />
          )}
          {displayTimeWindow && (
            <CardText data-testid='order-card_time-window_p'>
              <CardLabel>Window:</CardLabel>
              <CardFieldValue>
                <DisplayDateRange from={windowStart} to={windowEnd} />
              </CardFieldValue>
            </CardText>
          )}
          {displayLastPurchase && (
            <CardDetail
              testId='order-card_last-purchase-date_p'
              label='Last Purchase'
              value={formattedLastPurchaseDate}
            />
          )}
          {displayDiscountGroups && (
            <CardDetail
              testId='order-card_discount-groups_p'
              label='Discount Groups'
              value={discountGroups.toString().replace(/,/g, ', ')}
            />
          )}
          {displayPaymentStatus && (
            <CardDetail testId='order-card_payment-status_p' label='Status' value={paymentStatus} />
          )}
          {displayOrderSourceOrType && (
            <CardText>
              <CardLabel>Order:</CardLabel>
              <CardFieldValue>
                {displayOrderSource && <span data-testid='order-card_order-source_span'>{guest.OrderSource}</span>}
                {displayOrderSourceTypeSeparator && (
                  <span data-testid='order-card_order-source-separator_span'> | </span>
                )}
                {displayOrderType && <span data-testid='order-card_order-type_span'>{guest.OrderType}</span>}
              </CardFieldValue>
            </CardText>
          )}
          {displayRoom && <CardDetail testId='order-card_room_p' label='Room' value={guest.Room} />}
          {displayRegister && <CardDetail testId='order-card_room_p' label='Order #' value={guest.ShipmentId} />}
          {displayRefNumber && guest?.TransactionReference && (
            <CardDetail
              testId='order-card_transaction-ref-number_p'
              label='REF'
              value={guest.TransactionReference.substring(0, 50)}
            />
          )}
        </WithTopBorder>
      )}
      {displayDeliveryVehicle && (
        <WithTopBorder listMode={listMode}>
          <CardText>
            <CardLabel>Vehicle:</CardLabel>
            <CardCarContainer>
              <StyledCarIcon color={guest.Status?.Color || colors.primary} data-testid='order-card_delivery_svg' />
              <CardCarText data-testid='order-card_delivery-vehicle_p'>{guest.CarName}</CardCarText>
            </CardCarContainer>
          </CardText>
        </WithTopBorder>
      )}
    </InfoContainer>
  );
});

const CardCarContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${colors.dutchie.almostBlack};
  width: 60%;
`;

const StyledCarIcon = styled(CarIcon)<{ color: string }>`
  &&& {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  path {
    fill: ${({ color }) => `${color}`};
  }
`;

const CardCarText = styled.div`
  font-weight: 700;
`;

const StyledDutchiePayLogoSmall = styled(DutchiePayLogoSmall)`
  vertical-align: middle;
`;
