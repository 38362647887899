import { validateBioTrackUserCredentials, resetBioTrackUserCredentialsRequest, updateBioTrackPassword } from 'api/SettingsApi';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/icon-close.svg';
import { BioTrackUserCredentialStatus } from 'models/Settings';
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SectionGridContainer, SectionHeader, SectionContent, Caption } from './SettingPageComponents';
import { State } from 'store';
import { colors } from 'css/Theme';
import { ReactComponent as AccountIcon } from 'assets/icons/icon-account.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/icon-password.svg';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import { LinkButton } from 'components/backoffice/button';

/** @deprecated Ensure any changes are replicated in ./Tabs/Biotrack/BiotrackSettings.tsx */
export const BioTrackSettings: FC = () => {
  const useBioTrack = useSelector((state: State) => state.settings.integrations?.UseBioTrack);
  const dispatch = useDispatch();
  const [username, setUsername] = useState<string>('');
  const [status, setStatus] = useState<BioTrackUserCredentialStatus>(BioTrackUserCredentialStatus.Loading);
  const [message, setMessage] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [showAddtlInfo, setShowAddtlInfo] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    switch (status) {
      case BioTrackUserCredentialStatus.UserAccountIsValid: {
        setInfo('Your BioTrack account is currently active.');
        setShowSuccess(true);
        setShowAddtlInfo(false);
        break;
      }
      case BioTrackUserCredentialStatus.ResetPasswordRequired: {
        setInfo('We are unable to authenticate BioTrack using your credentials. If you have changed your password, enter it below.');
        setShowAddtlInfo(true);
        setShowError(true);
        break;
      }
      case BioTrackUserCredentialStatus.UsernameIsNotAValidEmail: {
        setInfo('The username provided is not a valid email address.');
        setShowError(true);
        break;
      }
      case BioTrackUserCredentialStatus.IndividualUserAccountsNotRequired: {
        setInfo('Individual user account is not required.');
        break;
      }
      case BioTrackUserCredentialStatus.IndividualUserAccountCannotBeCreated: {
        setInfo(message ?? 'Error creating user account. Please contact support.');
        setShowError(true);
        break;
      }
      case BioTrackUserCredentialStatus.LoginFailed: {
        setInfo(message ?? 'Login Failed. Please contact support.');
        setShowError(true);
        break;
      }
      default: {
        setInfo('BioTrack not enabled.');
        break;
      }
    }
  }, [status, message]);

  useEffect(() => {
    if (useBioTrack) {
      validateBioTrackUserCredentials().then((resp) => {
        setUsername(resp?.Username || '');
        setStatus(resp?.Status);
        setMessage(resp?.Message);
      });
    }
  }, [useBioTrack]);

  const sendResetPasswordRequest = async (clearCredentials: boolean) => {
    await resetBioTrackUserCredentialsRequest({
      Username: username,
      Password: null,
      ClearCredentials: clearCredentials,
    }).then((resp) => {
      if (!resp.Result) {
        dispatch(errorNotification(resp.Message));
      } else {
        dispatch(successNotification(`Reset password request sent to ${username}`));
        if (clearCredentials) {
          validateBioTrackUserCredentials().then((resp) => {
            setStatus(resp?.Status);
            setMessage(resp?.Message);
          });
        }
      }
    });
  };

  const resetPassword = async () => {
    await updateBioTrackPassword({
      Username: username,
      Password: password,
    }).then((resp) => {
      if (!resp.Result) {
        dispatch(errorNotification(resp.Message));
      } else {
        dispatch(successNotification(`Password updated successfully`));
        validateBioTrackUserCredentials().then((resp) => {
          setStatus(resp?.Status);
          setMessage(resp?.Message);
        });
      }
    });
  };

  return (
    <SectionGridContainer>
      <SectionHeader>BioTrack Credentials</SectionHeader>
      {status !== BioTrackUserCredentialStatus.Loading && (
        <>
          <SectionContent>
            <Caption>{showSuccess ? <StyledCheckMarkIcon /> : showError ? <StyledErrorIcon /> : null}{info}</Caption>
            {showAddtlInfo && (
              <>
               <br />
               <Caption>
                 Otherwise, you can <StyledLinkButton label="reset your BioTrack password" onClick={() => sendResetPasswordRequest(false)} />.
                 BioTrack will send a reset password email.
                 Once you have updated your password in BioTrack,
                 you can enter your password in the password box below,
                 then press update.
               </Caption>
              </>
            )}
          </SectionContent>
          <SectionContent>
            <InputContainer>
              <Input
                label='Username'
                value={username}
                disabled
                onChange={(e) => setUsername(e.target.value)}
                startAdornment={<StyledUserIcon />}
                placeholder='Username'
              />
            </InputContainer>
            {status !== BioTrackUserCredentialStatus.ResetPasswordRequired && (
              <LeftAlignedDiv>
                <LinkButton label='Click here to reset your BioTrack password' onClick={() => sendResetPasswordRequest(true)} />
              </LeftAlignedDiv>
            )}
            {status === BioTrackUserCredentialStatus.ResetPasswordRequired && (
              <>
                <InputContainer>
                  <Input
                    label='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    startAdornment={<StyledPasswordIcon />}
                    type='password'
                    placeholder='Password'
                  />
                </InputContainer>
                <Button tertiary onClick={resetPassword} disabled={!password}>
                  Update
                </Button>
              </>
            )}
          </SectionContent>
        </>
      )}
    </SectionGridContainer>
  );
};

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const iconStyles = {
  color: colors.dutchie.grey,
};

const StyledUserIcon = styled(AccountIcon)`
  ${iconStyles}
`;

const StyledPasswordIcon = styled(PasswordIcon)`
  ${iconStyles}
`;

const StyledCheckMarkIcon = styled(CheckMarkIcon)`
  color: ${colors.dutchie.green};
  margin-right: 10px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  color: ${colors.dutchie.red};
  margin-right: 10px;
  height: 8px;
  width: 8px;
`;

const StyledLinkButton = styled(LinkButton)`
  font-size: 1rem;
  background: none;
  padding: 0;
  padding-bottom: 1px;
  cursor: pointer;
`;

const LeftAlignedDiv = styled.div`
text-align: left
`;
