import React from 'react';
import styled from 'styled-components';
import { EmbeddedParentTableRowV2 } from './EmbeddedParentTableRowV2';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';

export function EmbeddedParentTableV2<ParentItem, ChildItem>() {
  const { parentItemMap, parentColumns, parentActions } = useEmbeddedTableStore();

  return (
    <THTableStyle>
      <thead className='parent'>
        <TRParentStyle className='parent'>
          <THDefaultStyle className='parent expansion-indicator' />
          {parentColumns.map(({ header, width }, i) => (
            <THParentStyle className='parent' key={`parent-header-${i}`} width={width}>
              {header}
            </THParentStyle>
          ))}
          {parentActions && <THDefaultStyle className='parent action-menu' />}
        </TRParentStyle>
      </thead>
      <tbody className='parent'>
        {Object.keys(parentItemMap).map((id) => (
          <EmbeddedParentTableRowV2<ParentItem, ChildItem> id={id} key={id} />
        ))}
      </tbody>
    </THTableStyle>
  );
}

const THTableStyle = styled.table`
  display: block;
  min-width: 80rem;
  height: calc(100vh - 147px);
`;

const THDefaultStyle = styled.th`
  width: 3rem;
  height: 2.5rem;
  content: '';
`;

const TRParentStyle = styled.tr`
  white-space: nowrap;
  border-bottom: 1px solid #e3e7e9;
`;

const THParentStyle = styled.th<{ width?: string }>`
  width: ${({ width }) => width};
  padding: 0.75rem 1rem;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 145.455% */
  letter-spacing: 0.01375rem;
  text-transform: uppercase;
  color: #646d72;
  vertical-align: middle;
`;
