import { useAppSelector } from 'util/hooks';
import { getLDClient } from 'util/launchDarkly';

export const getIsAnonymousCartLDFlagEnabled = () => {
  const ldClient = getLDClient();
  const isAnonymousCartLDFlagEnabled: boolean = ldClient?.variation('pos.register.anon-cart-workflow.rollout', false);
  return { isAnonymousCartLDFlagEnabled };
};

/** This will be a permanent hook to determine whether or not to use anonymous cart workflow */
export const useAnonymousCart = () => {
  const areAnonymousTransactionsEnabled = useAppSelector((state) => state.settings.features.RecreationalFlag);
  const isStartNewCartAfterCheckoutEnabled = useAppSelector(
    (state) => state.settings.features.StartNewCartAfterCheckout
  );

  const { isAnonymousCartLDFlagEnabled } = getIsAnonymousCartLDFlagEnabled();
  const isAnonymousCartEnabled = isAnonymousCartLDFlagEnabled && areAnonymousTransactionsEnabled;

  return {
    /** Enable Recreational Use FF */
    areAnonymousTransactionsEnabled,
    /** pos.register.anon-cart-workflow.rollout LD flag */
    isAnonymousCartLDFlagEnabled,
    /** Both FF and LD flags are enabled */
    isAnonymousCartEnabled,
    /** Anonymous cart FF + StartNewCartAfterCheckout FF + LD flag enabled */
    isStartNewCartAfterCheckoutEnabled: isStartNewCartAfterCheckoutEnabled && isAnonymousCartEnabled,
  };
};
