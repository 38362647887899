import { post } from './HttpHelpers';
import {
  AddShipmentsToRouteRequest,
  AutoCheckoutDeliveryRequest,
  Delivery,
  DeliveryDriver,
  DeliveryHistory,
  DeliveryRouteId,
  DeliveryRouteNoteRequest,
  DeliveryShipmentId,
  GetOptimizedDirectionsRequest,
  GetRouteDirectionsRequest,
  SetRouteOrderRequest,
  SetRouteReadyForDeliveryRequest,
  UpdateRoute,
  SetCarOfflineRequest,
  SetCarOnlineRequest,
  UpdateRouteResponse,
} from 'models/Delivery';
import { Zone } from 'models/Guest';
import { LocationSettingsResponse } from 'models/Location';
import { Vehicle } from 'models/Vehicle';
import { DeliveryRoute } from 'models/DeliveryRoute';

export const getAllZones = () => post<Array<Zone>>('v2/delivery/get-all-zones');
export const getDrivers = () => post<Array<DeliveryDriver>>('v2/delivery/get-drivers');
export const setRouteReadyForDelivery = (body: SetRouteReadyForDeliveryRequest) =>
  post('v2/delivery/ready-for-delivery', body);
export const getLocationGeocode = () => post<LocationSettingsResponse>('v2/delivery/get-dispensary-location');
export const getVehicles = () => post<Array<Vehicle>>('v2/delivery/get-fleet');
export const getDeliveryOrders = () => post<Array<Delivery>>('v2/delivery/delivery-orders');
export const setCarOffline = (body: SetCarOfflineRequest) => post<Array<Delivery>>('v2/delivery/set-car-offline', body);
export const setCarOnline = (body: SetCarOnlineRequest) => post<Array<Delivery>>('v2/delivery/set-car-online', body);
export const getDeliveryRoutes = () => post<Array<DeliveryRoute>>('v2/delivery/get-routes');
export const getRouteDeliveries = (body: DeliveryRouteId) =>
  post<Array<Delivery>>('v2/delivery/get-delivery-route-orders', body);
export const updateRoute = (body: UpdateRoute) => post<UpdateRouteResponse>('v2/delivery/update-route', body);
export const getDeliveryImage = (body: DeliveryShipmentId) => post<String>('v2/delivery/get-delivery-image', body);
export const autoCheckoutDeliveries = (body: AutoCheckoutDeliveryRequest) =>
  post('v2/guest/auto-checkout-delivery', body);
export const completeDeliveries = (body: DeliveryShipmentId) => post('v2/delivery/complete-delivery', body);
export const endRoute = (body: DeliveryRoute) => post('v2/delivery/end-route', body);
export const setDeliveryRouteNote = (body: DeliveryRouteNoteRequest) =>
  post('v2/delivery/set-delivery-route-note', body);
export const cancelRoute = (body: DeliveryRoute) => post('v2/delivery/cancel-route', body);
export const startRoute = (body: DeliveryRouteId) => post('v2/delivery/route-out-for-delivery', body);
export const suspendRoute = (body: DeliveryRouteId) => post('v2/delivery/suspend-route', body);
export const getRouteHistory = (body: DeliveryRouteId) => post<DeliveryHistory>('v2/delivery/get-route-history', body);
export const getRouteDirections = (body: GetRouteDirectionsRequest) =>
  post<string>('v2/delivery/get-route-directions', body);
export const getOptimizedRouteDirections = (body: GetOptimizedDirectionsRequest) =>
  post<string>('v2/delivery/get-optimized-route-directions', body);
export const addShipmentsToRoute = (body: AddShipmentsToRouteRequest) =>
  post<unknown>('v2/delivery/add-shipments-to-route', body);
export const setRouteOrder = (body: SetRouteOrderRequest) => post<unknown>('v2/delivery/set-route-order', body);
