import React, { createContext, FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { callback, callback2 } from 'models/Misc';
import { Input } from 'components/inputs';
import { checkManagerPin } from 'util/Helpers';

const ManagerSectionBase = styled.div`
  height: 2.5rem;
  min-height: 2.5rem;
  width: 100%;
  margin-bottom: 1rem;
`;

type ManagerSectionProps = {
  disabled?: boolean;
  needPin: boolean;
  placeholder?: string;
};

export const ManagerSection: FC<ManagerSectionProps> = (props) => {
  const { needPin, placeholder } = props;

  const { managerPinWrong, onManagerPinChange, managerPin, onSubmit } = useContext(ManagerSectionContext);

  return (
    <ManagerSectionBase data-testid='manager-section_div_section'>
      <form
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {needPin && (
          <Input
            data-testid='manager-section_styled-input'
            hasError={managerPinWrong}
            value={managerPin}
            onChange={(e) => {
              //remote non-numbers since pins are numbers only
              const cleaned = e.target.value.replace(/\D/g, '');
              onManagerPinChange(cleaned);
            }}
            placeholder={placeholder || 'Manager PIN'}
            type='password'
          />
        )}
      </form>
    </ManagerSectionBase>
  );
};

type ManagerSectionContextState = {
  onSubmit: () => void;
  managerPinWrong: boolean;
  onManagerPinChange: (pin: string) => void;
  managerPin?: string;
};

const defaultManagerSectionContextState = {
  onSubmit: () => {},
  managerPinWrong: false,
  onManagerPinChange: (pin: string) => {},
  managerPin: undefined,
};

export const ManagerSectionContext = createContext<ManagerSectionContextState>(defaultManagerSectionContextState);

type ManagerSectionProviderProps = {
  needPin: boolean;
  onSuccess: callback2<number, string> | callback;
};

export const ManagerSectionProvider: FC<ManagerSectionProviderProps> = ({ children, needPin, onSuccess }) => {
  const dispatch = useDispatch();
  const [managerPin, setManagerPin] = useState('');
  const [managerPinWrong, setManagerPinWrong] = useState(false);

  const onManagerPinChange = (pin: string) => {
    managerPinWrong && setManagerPinWrong(false);
    setManagerPin(pin);
  };

  const onSubmit = async () => {
    setManagerPinWrong(false);

    let ApprovingManagerUserId;
    try {
      if (needPin) {
        ApprovingManagerUserId = await checkManagerPin(managerPin);
        onSuccess(ApprovingManagerUserId[0]?.UserId, managerPin);
        dispatch(successNotification('PIN verified'));
      } else {
        onSuccess(0, '');
      }

      setManagerPin('');
    } catch {
      setManagerPinWrong(true);
      dispatch(errorNotification('Invalid PIN'));
    }
  };

  const value = {
    onSubmit,
    managerPinWrong,
    onManagerPinChange,
    managerPin,
    setManagerPin,
  };

  return <ManagerSectionContext.Provider value={value}>{children}</ManagerSectionContext.Provider>;
};
