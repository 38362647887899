import React, { FC } from 'react';
import styled from 'styled-components';

type TextAlignment = 'left' | 'center' | 'right';

const SplitHeader = styled.div<{ align: TextAlignment }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) => (align === 'left' ? 'flex-start' : align === 'center' ? 'center' : 'flex-end')};
`;

type SplitHeaderCellProps = {
  line1: string;
  line2?: string;
  align?: TextAlignment;
};

export const SplitHeaderCell: FC<SplitHeaderCellProps> = ({ line1, line2, align = 'left' }) => {
  return (
    <SplitHeader align={align}>
      <div>{line1}</div>
      {line2 && <div>{line2}</div>}
    </SplitHeader>
  );
};
