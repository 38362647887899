import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const CustomerDisplayIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16634 2.99984C2.61406 2.99984 2.16634 3.44755 2.16634 3.99984V12.3332C2.16634 12.8855 2.61406 13.3332 3.16634 13.3332H16.4997C17.052 13.3332 17.4997 12.8855 17.4997 12.3332V3.99984C17.4997 3.44755 17.052 2.99984 16.4997 2.99984H3.16634ZM0.833008 3.99984C0.833008 2.71117 1.87768 1.6665 3.16634 1.6665H16.4997C17.7883 1.6665 18.833 2.71117 18.833 3.99984V12.3332C18.833 13.6218 17.7883 14.6665 16.4997 14.6665H3.16634C1.87768 14.6665 0.833008 13.6218 0.833008 12.3332V3.99984Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83301 17.3332C5.83301 16.965 6.13148 16.6665 6.49967 16.6665H13.1663C13.5345 16.6665 13.833 16.965 13.833 17.3332C13.833 17.7014 13.5345 17.9998 13.1663 17.9998H6.49967C6.13148 17.9998 5.83301 17.7014 5.83301 17.3332Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.83301 13.3332C10.2012 13.3332 10.4997 13.6316 10.4997 13.9998V17.3332C10.4997 17.7014 10.2012 17.9998 9.83301 17.9998C9.46482 17.9998 9.16634 17.7014 9.16634 17.3332V13.9998C9.16634 13.6316 9.46482 13.3332 9.83301 13.3332Z" fill={color} />
    </svg>
  );
};
