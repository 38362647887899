import React, { useCallback, useState } from 'react';
import { ButtonProps as MUIButtonProps, MenuProps as MUIMenuProps } from '@mui/material';

import { CaretDown } from '../icons/caret-down';
import { Button } from '../button';
import { DropdownMenuMultiple, DropdownMenuOption } from '../dropdown/menu';

type MultiFilterSelectProps = {
  options: DropdownMenuOption[];
  activeFilter: DropdownMenuOption[];
  disabled?: boolean;
  onChange: (updatedValue: DropdownMenuOption[]) => void;
  ButtonProps?: MUIButtonProps;
  MenuProps?: MUIMenuProps;
  label: string;
  loading?: boolean;
  selectionCopy?: string;
  className?: string;
};

export function MultiFilterSelect(props: MultiFilterSelectProps) {
  const { ButtonProps, MenuProps, label, loading, options, activeFilter, disabled, onChange, selectionCopy, className } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = (newValue) => {
    onChange(newValue);

    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleMenuOnChange = (event, newValue) => {
    onChange(newValue);
  };

  const multiFilterLabel = activeFilter.length === 0 ? label : `${activeFilter.length} ${selectionCopy ?? ''} selected`;

  return (
    <>
      <Button
        {...ButtonProps}
        aria-controls='filter-menu'
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        disabled={disabled}
        endIcon={<CaretDown />}
        id='multi-filter-menu'
        label={multiFilterLabel}
        variant='white'
        onClick={handleClick}
        className={className}
      />
      <DropdownMenuMultiple
        {...MenuProps}
        anchorEl={anchorEl}
        handleClose={handleClose}
        id='multi-filter-menu'
        loading={loading}
        open={open}
        options={options}
        placement='bottom-start'
        value={activeFilter}
        onChange={handleMenuOnChange}
      />
    </>
  );
}
