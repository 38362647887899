import React, { FC, useCallback, useEffect, useState } from 'react';
import { PaymentsHubProductUpsell } from 'models/Checkout';
import { logger } from 'util/logger';
import { cancelPaymentsHubTransaction } from 'api/CheckoutApi';
import { useAddQtyFromProduct, useAppDispatch } from 'util/hooks';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { useSelector } from 'react-redux';
import { SimpleModal } from 'components/modals/SimpleModal';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { UpsellIcon } from 'assets/icons/upsell';
import { Column } from 'components/layout';
import { LoadingButton } from 'components/buttons';
import { checkPinForDigitalPayment, digitalPayment } from 'store/actions/CheckoutActions';
import { v4 as uuidv4 } from 'uuid';
import { State } from 'store';
import { usePaymentsHubSplitPayments } from 'util/hooks/launch-darkly/usePaymentsHubSplitPayments';
import { usePaymentsHubCreatePaymentMethod } from 'pages/CartPage/CartPanels/CheckoutSidebar/usePaymentsHubCreatePaymentMethod';

type ProductUpsellPopUpProps = {
  data: PaymentsHubProductUpsell;
  hide: () => void;
};

const ProductUpsellPopUp: FC<ProductUpsellPopUpProps> = ({ data, hide }) => {
  const addQtyToCart = useAddQtyFromProduct();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const paymentMethodId = uuidv4();
  const managerPin = useSelector((state: State) => state.checkout.managerPin);
  const checkOutPinRequired = useSelector((state: State) => state.settings.features.CheckOutPinRequired);
  const totalDue = useSelector((state: State) => state.checkout.totalDue);
  const totalRemaining = useSelector((state: State) => state.checkout.totalRemaning);
  const hubSplitPaymentsRollout = usePaymentsHubSplitPayments();
  const [isReadyToRestartTransaction, setIsReadyToRestartTransaction] = useState(false);
  const createPaymentMethod = usePaymentsHubCreatePaymentMethod();

  const startTransaction = useCallback(async () => {
    setIsLoading(true);
    try {
      let amount;
      if (hubSplitPaymentsRollout) {
        amount = totalRemaining;
      } else {
        amount = totalDue;
      }

      const paymentMethod = createPaymentMethod(amount, paymentMethodId);

      if (checkOutPinRequired) {
        await dispatch(checkPinForDigitalPayment({ code: managerPin, paymentMethod })).unwrap();
      } else {
        await dispatch(digitalPayment(paymentMethod)).unwrap();
      }
      hide();
    } catch (e) {
      logger.error('Error starting transaction', { e });
      dispatch(errorNotification(`Failed to start transaction from upsell`));
    } finally {
      setIsReadyToRestartTransaction(false);
      setIsLoading(false);
    }
  }, [
    hubSplitPaymentsRollout,
    hide,
    totalRemaining,
    totalDue,
    paymentMethodId,
    checkOutPinRequired,
    managerPin,
    dispatch,
    createPaymentMethod,
  ]);

  const handleAddToCart = async () => {
    setIsLoading(true);
    try {
      await addQtyToCart(data.Product, 1);
      await cancelTransaction();
      setIsReadyToRestartTransaction(true);
      dispatch(successNotification(`Item successfully added to cart`));
    } catch (e) {
      logger.error('ProductUpsellPopup executing handleAddToCart caught error', { e });
      hide();
      dispatch(errorNotification(`Item was not successfully added to cart`));
    } finally {
      setIsLoading(false);
    }
  };

  const cancelTransaction = async () => {
    try {
      await cancelPaymentsHubTransaction({ ShipmentId: data.ShipmentId });
    } catch (e) {
      logger.error('ProductUpsellPopup executing cancelPaymentsHubTransaction caught error', { e });
      dispatch(errorNotification('Unable to cancel upsell transaction, please try again'));
      throw e;
    }
  };

  const closeModal = async () => {
    setIsLoading(true);
    await cancelTransaction();
    hide();
    setIsLoading(false);
  };

  // This is required to start a transaction again after adding a product then canceling the order since the cancellation
  // uses polling there is a delay when the payment is ready to start the transaction again. To wait for the payment to
  // be in a state ready to start the transaction again we use this useEffect.
  useEffect(() => {
    if (!digitalPaymentLoading && !isLoading && isReadyToRestartTransaction) {
      startTransaction();
    }
  }, [digitalPaymentLoading, startTransaction, isLoading, isReadyToRestartTransaction]);

  return (
    <SimpleModal
      showFooterBorder={false}
      showCloseButton={false}
      header={
        <Header>
          <HeaderText>Payment terminal upsell</HeaderText>
        </Header>
      }
      footer={
        <Footer>
          <LoadingButton secondary={true} loading={isLoading} fullWidth={true} onClick={closeModal}>
            Cancel
          </LoadingButton>
          <LoadingButton loading={isLoading} fullWidth={true} onClick={handleAddToCart}>
            Add to Cart
          </LoadingButton>
        </Footer>
      }
      children={
        <Column gap={5} alignItems='center'>
          <UpsellIconContainer>
            <UpsellIcon />
          </UpsellIconContainer>
          <ModalBody>Customer wishes to add</ModalBody>
          <ProductText>{data.ProductName}</ProductText>
          <ModalBody> to their cart. </ModalBody>
        </Column>
      }
      hide={closeModal}
      modalName='ProductUpsellModal'
    />
  );
};

export default ProductUpsellPopUp;

const Header = styled.div`
  padding: 32px 60px;
`;

const ModalBody = styled.div`
  color: ${colors.dutchie.grey30};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
`;

const HeaderText = styled.div`
  color: ${colors.dutchie.almostBlack};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
`;

const ProductText = styled.div`
  color: ${colors.dutchie.almostBlack};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const UpsellIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;
