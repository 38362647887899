import React, { FC } from 'react';
import { SectionGridContainer, SectionHeader, SectionContent, ActionBar, RefreshButton, SelectContainer } from '../SettingPageComponents';
import { Select, Switch } from 'components/inputs';
import { isAndroid } from 'util/hooks';
import { useLabelPrinterPicker } from './hooks/useLabelPrinterPicker';
import { Label } from 'components/text';
import { useLabels } from './hooks/useLabels';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { HardwareLibraryLabelPrinterDropdown } from './HardwareLibraryLabelPrinterDropdown';
import { Column } from 'components/layout';

export const LabelPrinterPicker: FC = () => {
  const { handleSwitchAutoPrint, handleSwitchPrinterTypes, isAutoPrintEnabled, isLocal } = useLabelPrinterPicker();
  const { labelDropdownOptions, handleSearchLabels, handleChangeLabel } = useLabels();
  const preferredLabelId = useSelector((state: State) => state.settings.userSettings.selectedLabel?.id);
  const selectedLabelId = labelDropdownOptions.find((option) => option.value === preferredLabelId)?.value;

  return (
    <SectionGridContainer>
      <SectionHeader>Labels</SectionHeader>
      <SectionContent>
        <Switch label='Auto Print Labels' onChange={handleSwitchAutoPrint} checked={isAutoPrintEnabled} />
        <SelectContainer>
          <Label>Default Label</Label>
          <ActionBar>
            <Column flex={1}>
              <Select
                placeholder='Select option'
                value={selectedLabelId}
                onChange={handleChangeLabel}
                options={labelDropdownOptions}
                errorMessage={!selectedLabelId && isAutoPrintEnabled && 'Please select a default label'}
              />
            </Column>
            <RefreshButton onClick={() => handleSearchLabels({ userInitiated: true })} />
          </ActionBar>
        </SelectContainer>

        {isAndroid && (
          <Switch label='Use Local Label Printer' onChange={handleSwitchPrinterTypes} checked={isLocal} />
        )}
        <HardwareLibraryLabelPrinterDropdown connectionType={isLocal && isAndroid ? 'local' : 'network'} />
      </SectionContent>
    </SectionGridContainer>
  );
};

