import React, { FC, useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import { ManagerSection, ManagerSectionContext, ManagerSectionProvider } from './ManagerSection';
import { ConfirmationPopup } from 'components/popups';
import { callback, callback1 } from 'models/Misc';
import { Reason } from 'models/Settings';
import { Flex } from 'components/layout';
import { Select } from 'components/inputs';

const SelectReason = styled(Select)`
  width: 100%;
`;

const ReasonsSection = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
`;

const Container = styled(Flex)`
  min-width: 472px;
  width: 100%;
  padding: 24px;
`;

export type ManagerPinPopupProps = {
  hide: () => void;
  onSuccess: callback1<number>;
  title: string;
  reasons?: Array<Reason>;
  selectedReason?: string;
  setSelectedReason?: callback1<string>;
  onCancel?: callback;
};

export const ManagerPinPopup: FC<ManagerPinPopupProps> = ({ onSuccess, hide, ...rest }) => {
  const onPinSuccess = (approvalManagerUserId: number) => {
    hide();
    onSuccess(approvalManagerUserId);
  };
  return (
    <ManagerSectionProvider needPin={true} onSuccess={onPinSuccess}>
      <ManagerPinPopupContent {...rest} onSuccess={onPinSuccess} hide={hide} />
    </ManagerSectionProvider>
  );
};

export const ManagerPinPopupContent: FC<ManagerPinPopupProps> = ({
  hide,
  reasons,
  selectedReason,
  setSelectedReason,
  title,
  onCancel,
}) => {
  const [reason, setReason] = useState(selectedReason);
  const { onSubmit } = useContext(ManagerSectionContext);
  const selectReason = (val: string) => {
    setSelectedReason && setSelectedReason(val);
    setReason(val);
  };

  const onHide = useCallback(() => {
    hide();
    onCancel && onCancel();
  }, [hide, onCancel]);

  return (
    <ConfirmationPopup
      isVisible
      small
      title={title || 'Enter manager PIN'}
      hide={onHide}
      confirm={{
        text: 'Continue',
        onClick: () => {
          onSubmit();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: onHide,
      }}
    >
      <Container direction='column' alignItems='center'>
        {reasons && reasons.length > 0 && (
          <ReasonsSection data-testid='reasons-section_div_section'>
            <SelectReason
              automationId='select-reason_div_drop-down'
              defaultValue={reason}
              options={
                !reasons
                  ? []
                  : reasons.map((reason) => ({
                      key: reason.ReasonId,
                      label: reason.Reason,
                      value: reason.Reason,
                    }))
              }
              onChange={(val) => selectReason(val)}
              placeholder='Select a reason'
            />
          </ReasonsSection>
        )}
        <ManagerSection title={title} needPin={true} />
      </Container>
    </ConfirmationPopup>
  );
};
