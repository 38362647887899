import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { Pin, PinSelected, PinSplit, Dispensary as DispensaryPin } from './Pins';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { Marker, Layer, Source, useMap } from 'react-map-gl';
import { Delivery } from 'models/Delivery';
import { colors } from 'css/Theme';
import { toggleSelectedDelivery } from 'store/actions/DeliveryActions';
import { usePins } from './usePins';
import { useBounds } from './useBounds';
import { useExpandedDeliveriesCoords } from './useExpandedDeliveriesCoords';

export const MapContent: FC = () => {
  const mapRef = useMap();
  const dispatch = useDispatch();
  const dispensaryLat = useSelector((state: State) => state.settings.Lat);
  const dispensaryLng = useSelector((state: State) => state.settings.Lng);
  const { selectedPins, splitPins, unselectedPins } = usePins();
  const expandedDeliveriesCoords = useExpandedDeliveriesCoords();
  const bounds = useBounds();

  const onPinClick = useCallback(
    (d: Delivery[]) => {
      dispatch(toggleSelectedDelivery(d));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    mapRef.current.fitBounds(bounds, { padding: 100, duration: 1000 });
  }, [bounds, mapRef]);

  const selectedMarkers = useMemo(
    () =>
      selectedPins.map(
        (selectedPin, i) =>
          selectedPin.Lat &&
          selectedPin.Lng && (
            <Marker latitude={selectedPin.Lat} longitude={selectedPin.Lng} key={i}>
              <PinSelected
                onClick={() => onPinClick(selectedPin.Deliveries)}
                deliverystatusid={selectedPin.Deliveries.slice(-1)[0].DeliveryStatusId}
              />
            </Marker>
          )
      ),
    [onPinClick, selectedPins]
  );

  const splitMarkers = useMemo(
    () =>
      splitPins.map(
        (splitPin, i) =>
          splitPin.Lat &&
          splitPin.Lng && (
            <Marker latitude={splitPin.Lat} longitude={splitPin.Lng} key={i}>
              <PinSplit
                onClick={() => onPinClick(splitPin.Deliveries)}
                deliverystatusid={splitPin.Deliveries.slice(-1)[0].DeliveryStatusId}
              />
            </Marker>
          )
      ),
    [onPinClick, splitPins]
  );

  const unselectedMarkers = useMemo(
    () =>
      unselectedPins.map(
        (unselectedPin, i) =>
          unselectedPin.Lat &&
          unselectedPin.Lng && (
            <Marker latitude={unselectedPin.Lat} longitude={unselectedPin.Lng} key={selectedPins.length + i}>
              <Pin
                onClick={() => onPinClick(unselectedPin.Deliveries)}
                deliverystatusid={unselectedPin.Deliveries.slice(-1)[0].DeliveryStatusId}
              />
            </Marker>
          )
      ),
    [onPinClick, selectedPins.length, unselectedPins]
  );

  const dispensaryMarker = useMemo(
    () =>
      dispensaryLat &&
      dispensaryLng && (
        <Marker latitude={dispensaryLat} longitude={dispensaryLng}>
          <DispensaryPin />
        </Marker>
      ),
    [dispensaryLat, dispensaryLng]
  );

  const expandedDeliveryLines = useMemo(() => {
    if (!expandedDeliveriesCoords.length) {
      return null;
    }

    const geoJson: GeoJSON.Feature<GeoJSON.Geometry> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: expandedDeliveriesCoords,
      },
    };

    return (
      <Source id='expanded-deliveries_line' type='geojson' data={geoJson}>
        <Layer type='line' paint={{ 'line-color': colors.grey, 'line-width': 4 }} />
      </Source>
    );
  }, [expandedDeliveriesCoords]);

  return (
    <>
      {dispensaryMarker}
      {selectedMarkers}
      {splitMarkers}
      {unselectedMarkers}
      {expandedDeliveryLines}
    </>
  );
};
