import React, { FC, useEffect, useState } from 'react';
import { CancelTransaction } from 'components/sharedPopups/CancelTransaction';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { ExistingPaymentMethod } from 'models/Checkout';
import { CancelIntegratedPaymentTransaction } from 'components/sharedPopups/CancelIntegratedPaymentTransaction';
import { getPayments } from 'api/CartApi';
import { ConfirmationPopup } from 'components/popups';
import { Loader, LoadingContainer } from 'components/backoffice/loader';
import { logger } from 'util/logger';

import type { CheckedInGuest } from 'models/Guest';

export type CancelTransactionGuestProps = Pick<
  CheckedInGuest,
  'DeliveryStatus' | 'FullName' | 'Guest_id' | 'ScheduleId' | 'ShipmentId'
>;

type CancelTransactionPopupProps = {
  hide: () => void;
  guest: CancelTransactionGuestProps;
  onCancel?: () => void;
};

enum CancelIntegrationState {
  Loading,
  Integrated,
  NonIntegrated,
}

export const CancelTransactionPopup: FC<CancelTransactionPopupProps> = ({ hide, guest, onCancel }) => {
  const { 'pos.register.cancel.integrated-payments': cancelIntegratedPaymentsFlag } = useFlags();
  const usePaynetworx = useSelector((state: State) => state.settings.integrations?.UsePaynetworx);
  const [paymentMethods, setPaymentMethods] = useState<ExistingPaymentMethod[]>([]);
  const [integratedState, setUseIntegratedState] = useState<CancelIntegrationState>(CancelIntegrationState.Loading);
  const dispatch = useDispatch();

  const shipmentId = guest.ShipmentId;

  useEffect(() => {
    const fn = async () => {
      try {
        if (cancelIntegratedPaymentsFlag && usePaynetworx) {
          const payments = (await getPayments({ ShipmentId: shipmentId }))?.filter((p) => p.RefundSupportedOnCancel);
          if (payments?.length > 0) {
            setPaymentMethods(payments);
            setUseIntegratedState(CancelIntegrationState.Integrated);
            return;
          }
        }
      } catch (ex) {
        logger.error(ex);
      }
      setUseIntegratedState(CancelIntegrationState.NonIntegrated);
    };
    fn();
  }, [dispatch, shipmentId, cancelIntegratedPaymentsFlag, usePaynetworx]);

  if (integratedState === CancelIntegrationState.Loading) {
    return (
      <ConfirmationPopup
        small
        isVisible
        hide={hide}
        title={`Cancel Transaction`}
        cancel={{
          text: 'Cancel',
          onClick: hide,
        }}
        confirm={{
          text: 'Confirm',
          onClick: () => {},
          disabled: true,
        }}
        automationId='cancel-transaction-popup_loading_payments'
      >
        <LoadingContainer>
          <Loader size='2x' variant='black' />
        </LoadingContainer>
      </ConfirmationPopup>
    );
  }

  if (integratedState === CancelIntegrationState.Integrated) {
    return (
      <CancelIntegratedPaymentTransaction
        hide={hide}
        guest={guest}
        onCancel={onCancel}
        paymentMethods={paymentMethods}
      />
    );
  }

  return <CancelTransaction hide={hide} guest={guest} onCancel={onCancel} />;
};
