import React from 'react';

const defaultWidth = 16;
const defaultHeight = 16;

export const WarningIcon = ({
  height = 16,
  width = 16,
  fill = '#615100',
  disabled,
}: {
  width: number;
  height: number;
  fill?: string;
  disabled?: boolean;
}) => {
  if (disabled) {
    return (
      <svg width={width} height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
          <path
            d='M15.8467 13.7133L9.18001 1.04665C9.06701 0.831499 8.89735 0.651327 8.68938 0.525604C8.48141 0.399881 8.24303 0.333388 8.00001 0.333313C7.75699 0.333388 7.51861 0.399881 7.31064 0.525604C7.10267 0.651327 6.93301 0.831499 6.82001 1.04665L0.153344 13.7133C0.0423409 13.9195 -0.0124036 14.1513 -0.00538173 14.3853C0.0016401 14.6194 0.0701805 14.8475 0.193344 15.0466C0.314833 15.2385 0.48332 15.3962 0.682827 15.5047C0.882335 15.6132 1.10625 15.6689 1.33334 15.6666H14.6667C14.8962 15.6666 15.1218 15.6073 15.3216 15.4945C15.5215 15.3817 15.6889 15.2192 15.8075 15.0227C15.9262 14.8263 15.9921 14.6026 15.999 14.3732C16.0059 14.1438 15.9534 13.9165 15.8467 13.7133ZM7.33334 5.66665C7.33334 5.48984 7.40358 5.32027 7.52861 5.19524C7.65363 5.07022 7.8232 4.99998 8.00001 4.99998C8.17682 4.99998 8.34639 5.07022 8.47142 5.19524C8.59644 5.32027 8.66668 5.48984 8.66668 5.66665V9.66665C8.66668 9.84346 8.59644 10.013 8.47142 10.1381C8.34639 10.2631 8.17682 10.3333 8.00001 10.3333C7.8232 10.3333 7.65363 10.2631 7.52861 10.1381C7.40358 10.013 7.33334 9.84346 7.33334 9.66665V5.66665ZM8.03334 13.3333C7.77077 13.3318 7.51889 13.2291 7.33014 13.0465C7.14139 12.864 7.0303 12.6157 7.02001 12.3533C7.01555 12.2215 7.03756 12.0902 7.08473 11.967C7.13189 11.8439 7.20326 11.7315 7.29462 11.6364C7.38598 11.5413 7.49548 11.4655 7.61663 11.4134C7.73779 11.3614 7.86815 11.3341 8.00001 11.3333C8.26259 11.3348 8.51447 11.4375 8.70322 11.6201C8.89197 11.8026 9.00305 12.0509 9.01334 12.3133C9.0178 12.4451 8.9958 12.5764 8.94863 12.6996C8.90146 12.8227 8.83009 12.9352 8.73873 13.0303C8.64737 13.1253 8.53788 13.2012 8.41672 13.2532C8.29557 13.3053 8.16521 13.3325 8.03334 13.3333Z'
            fill='#828A8F'
          />
        </g>
      </svg>
    );
  }

  return (
    <svg width={width} height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d='M15.8467 13.7133L9.18001 1.04665C9.06701 0.831499 8.89735 0.651327 8.68938 0.525604C8.48141 0.399881 8.24303 0.333388 8.00001 0.333313C7.75699 0.333388 7.51861 0.399881 7.31064 0.525604C7.10267 0.651327 6.93301 0.831499 6.82001 1.04665L0.153344 13.7133C0.0423409 13.9195 -0.0124036 14.1513 -0.00538173 14.3853C0.0016401 14.6194 0.0701805 14.8475 0.193344 15.0466C0.314833 15.2385 0.48332 15.3962 0.682827 15.5047C0.882335 15.6132 1.10625 15.6689 1.33334 15.6666H14.6667C14.8962 15.6666 15.1218 15.6073 15.3216 15.4945C15.5215 15.3817 15.6889 15.2192 15.8075 15.0227C15.9262 14.8263 15.9921 14.6026 15.999 14.3732C16.0059 14.1438 15.9534 13.9165 15.8467 13.7133ZM7.33334 5.66665C7.33334 5.48984 7.40358 5.32027 7.52861 5.19524C7.65363 5.07022 7.8232 4.99998 8.00001 4.99998C8.17682 4.99998 8.34639 5.07022 8.47142 5.19524C8.59644 5.32027 8.66668 5.48984 8.66668 5.66665V9.66665C8.66668 9.84346 8.59644 10.013 8.47142 10.1381C8.34639 10.2631 8.17682 10.3333 8.00001 10.3333C7.8232 10.3333 7.65363 10.2631 7.52861 10.1381C7.40358 10.013 7.33334 9.84346 7.33334 9.66665V5.66665ZM8.03334 13.3333C7.77077 13.3318 7.51889 13.2291 7.33014 13.0465C7.14139 12.864 7.0303 12.6157 7.02001 12.3533C7.01555 12.2215 7.03756 12.0902 7.08473 11.967C7.13189 11.8439 7.20326 11.7315 7.29462 11.6364C7.38598 11.5413 7.49548 11.4655 7.61663 11.4134C7.73779 11.3614 7.86815 11.3341 8.00001 11.3333C8.26259 11.3348 8.51447 11.4375 8.70322 11.6201C8.89197 11.8026 9.00305 12.0509 9.01334 12.3133C9.0178 12.4451 8.9958 12.5764 8.94863 12.6996C8.90146 12.8227 8.83009 12.9352 8.73873 13.0303C8.64737 13.1253 8.53788 13.2012 8.41672 13.2532C8.29557 13.3053 8.16521 13.3325 8.03334 13.3333Z'
          fill={fill}
        />
      </g>
    </svg>
  );
};
