import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { AnonymousTransactionResponse, transformToAnonymousTransaction } from './types';
import { checkInCustomer, getCustomerDetails } from 'store/actions/CustomerActions';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { useAppDispatch, useAppSelector, useSearchParams } from 'util/hooks';

import type { AnonymousTransaction } from './types';

const NEW_REC_TRANSACTION = 'v2/cart/New_Rec_Transaction';

export type AnonymousTransactionPayload = {
  customerTypeId?: number;
  dob?: string;
  gender?: string;
  onSuccess?: (transactionDetails?: AnonymousTransaction) => Promise<void>;
  postalCode?: string;
  registerId?: number;
  roomId?: number;
};

const transformToServerPayload = (payload: AnonymousTransactionPayload) => {
  return {
    CustomerTypeId: payload.customerTypeId,
    Dob: payload.dob,
    Gender: payload.gender,
    PostalCode: payload.postalCode,
    RoomId: payload.roomId ?? 0,
    TerminalId: payload.registerId,
  };
};

export const useAnonymousTransactionMutation = () => {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();

  const registerErrorMessage = 'There is no selected register';
  const registerIdFromState = useAppSelector((state) => state.settings.selectedRegister?.value);

  return useMutation(
    async (payload: AnonymousTransactionPayload) => {
      const registerId = payload.registerId ?? registerIdFromState;
      if (registerId === undefined) {
        throw new Error(registerErrorMessage);
      }

      const response = await post<AnonymousTransactionResponse>(
        NEW_REC_TRANSACTION,
        transformToServerPayload({ ...payload, registerId })
      );

      const anonymousTransaction = transformToAnonymousTransaction(response);

      // Update the query params
      setSearchParams(
        () => ({
          guest: anonymousTransaction.guestId.toString(),
          order: anonymousTransaction.shipmentId.toString(),
        }),
        { replace: true }
      );

      await dispatch(checkInCustomer({ guestId: anonymousTransaction.guestId, register: registerId }));
      await dispatch(getCustomerDetails({ guestId: anonymousTransaction.guestId }));

      return anonymousTransaction;
    },
    {
      onSuccess(transactionDetails, payload) {
        // If the onSuccess callback is provided, call it
        if (payload?.onSuccess) {
          payload.onSuccess(transactionDetails);
        }
      },
      onError: (error) => {
        // Remove query params
        setSearchParams('');

        if (error === registerErrorMessage) {
          dispatch(warningNotification(registerErrorMessage));
          return;
        }
        dispatch(errorNotification('Unable to create an anonymous transaction'));
        logger.error(error, { message: 'Failed to create and anonymous transaction' });
      },
    }
  );
};
