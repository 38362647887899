import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { rgba } from 'polished';

import { colors, zIndices } from 'css/Theme';
import { DoubleChevronIcon } from 'assets/icons/navigation/double-chevron';
import { ReactComponent as DutchieLogoWhite } from 'assets/dutchie-logo-white.svg';
import { NavigationItem, NavigationItemVariant } from 'components/layout/NavigationItem';
import { useBuildNumber } from 'util/hooks/useBuildNumber';
import { useNavigationItems } from 'util/hooks/useNavigationItems';

const DRAWER_WIDTH = 216;
const DRAWER_TRANSITION_DURATION = 300;

type NavigationDrawerProps = {
  isOpen: boolean;
  onCloseDrawer: () => void;
};

export function NavigationDrawer(props: NavigationDrawerProps) {
  const { isOpen, onCloseDrawer } = props;

  const buildNumber = useBuildNumber();
  const { primaryItems, secondaryItems } = useNavigationItems();

  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={DRAWER_TRANSITION_DURATION}
        classNames='navigation-drawer-overlay'
        unmountOnExit
      >
        <Overlay onClick={onCloseDrawer} />
      </CSSTransition>
      <CSSTransition
        in={isOpen}
        timeout={DRAWER_TRANSITION_DURATION}
        classNames='navigation-drawer-container'
        unmountOnExit
      >
        <DrawerContainer data-testid='navigation-drawer-container'>
          <PrimaryItemsContainer data-testid='navigation-drawer-primary-items'>
            <LogoLink to='/' data-testid='navigation-drawer-logo-link'>
              <DutchieLogoWhite />
            </LogoLink>
            {primaryItems.map((item) => (
              <NavigationItem
                key={item.label}
                item={item}
                parentWidth={DRAWER_WIDTH}
                variant={NavigationItemVariant.Drawer}
              />
            ))}
          </PrimaryItemsContainer>
          <SecondaryItemsContainer data-testid='navigation-drawer-secondary-items'>
            {secondaryItems.map((item) => (
              <NavigationItem
                key={item.label}
                item={item}
                parentWidth={DRAWER_WIDTH}
                variant={NavigationItemVariant.Drawer}
              />
            ))}
            <Divider />
            <ExpandButton
              onClick={onCloseDrawer}
              data-testid='navigation-drawer-expand-button'
              data-dd-action-name='close navigation drawer'
            >
              <DoubleChevronIcon color={colors.dutchie.opal40} />
              <Label>Collapse</Label>
            </ExpandButton>
            <BuildNumber>Build: {buildNumber}</BuildNumber>
          </SecondaryItemsContainer>
        </DrawerContainer>
      </CSSTransition>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndices.navigationDrawer};
  height: 100%;
  width: 100%;
  background: ${rgba(colors.dutchie.opal80, 0.3)};

  &.navigation-drawer-overlay-enter {
    opacity: 0;
  }
  &.navigation-drawer-overlay-enter-active {
    opacity: 1;
    transition: all ${DRAWER_TRANSITION_DURATION}ms ease;
  }
  &.navigation-drawer-overlay-exit {
    opacity: 1;
  }
  &.navigation-drawer-overlay-exit-active {
    opacity: 0;
    transition: all ${DRAWER_TRANSITION_DURATION}ms ease;
  }
`;

const DrawerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndices.navigationDrawer};
  height: 100%;
  width: ${DRAWER_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.dutchie.opal90};
  box-shadow: 6px 0px 9px 0px rgba(0, 0, 0, 0.25);

  &.navigation-drawer-container-enter {
    opacity: 0;
    transform: translate3D(-100%, 0, 0);
  }
  &.navigation-drawer-container-enter-active {
    opacity: 1;
    transition: all ${DRAWER_TRANSITION_DURATION}ms ease;
    transform: translate3D(0, 0, 0);
  }
  &.navigation-drawer-container-exit {
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }
  &.navigation-drawer-container-exit-active {
    opacity: 0;
    transform: translate3D(-100%, 0, 0);
    transition: all ${DRAWER_TRANSITION_DURATION}ms ease;
  }
`;

const PrimaryItemsContainer = styled.ul`
  background: ${colors.dutchie.opal90};
  overflow: auto;
`;

const LogoLink = styled(NavLink)`
  position: sticky;
  top: 0;
  z-index: 1;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  background: ${colors.dutchie.opal90};
`;

const SecondaryItemsContainer = styled.ul`
  padding-top: 32px;
  padding-bottom: 16px;
  background: ${colors.dutchie.opal90};
`;

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  margin: 0;
  background: ${colors.dutchie.opal70};
  border: none;
`;

const ExpandButton = styled.button`
  appearance: none;
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: none;
  padding: 0 16px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }
`;

const Label = styled.span`
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.dutchie.opal40};
`;

const BuildNumber = styled.span`
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.dutchie.opal60};
`;
