import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: "Matter, -apple-system, 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  transitions: {
    create: () => 'none',
  },
});
