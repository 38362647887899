import { createSlice, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { setLaunchDarklyFlags } from 'store/actions/LaunchDarklyActions';

export type LaunchDarklyFlags = Record<string, unknown>;

const initialState = {};

export const LDFlagSlice = createSlice({
  name: 'LDFlagSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<LaunchDarklyFlags>) => {
    builder.addCase(setLaunchDarklyFlags, (state: LaunchDarklyFlags, action: PayloadAction<LaunchDarklyFlags>) => {
      Object.assign(state, action.payload);
    });
  },
});
