import React from 'react';
import styled from 'styled-components';

import { AssignedPackagePill } from './components/AssignedPackagePill';
import { CartItem } from 'models/Cart';
import { colors } from 'css/Theme';
import { ProductSelectionControls } from 'components/cart/ProductSelectionControls';
import { useCartLineItem } from './useCartLineItem';
import { OverflowMenu, OverflowMenuOption } from 'components/menus/OverflowMenu';
import { ReactComponent as ActionElipsisIcon } from 'assets/icons/actions-elipsis.svg';
import { Button } from 'components/backoffice/button';
import { CART_LINE_ITEM_TEST_IDS } from './CartLineItem.test-ids';

const {
  brandNameTestId,
  discountedCostTestId,
  itemCostStrikethroughTestId,
  itemCostTestId,
  productNameTestId,
  quantityToDisplayTestId,
} = CART_LINE_ITEM_TEST_IDS;

export type CartLineItemProps = {
  children: React.ReactNode;
  overflowOptions: OverflowMenuOption[];
  item: CartItem;
  totalDiscountAmount?: number;
};

export const CartLineItem = ({
  item,
  children,
  overflowOptions,
  totalDiscountAmount,
}: CartLineItemProps): JSX.Element => {
  const {
    canUseQuantityControls,
    discountedCostToDisplay,
    grams,
    handleQuantityChange,
    isItemFromPreorder,
    packageId,
    quantity,
    quantityToDisplay,
    totalCostToDisplay,
  } = useCartLineItem({ item, totalDiscountAmount });

  return (
    <SidebarItemContainer data-testid='sidebar-item_sidebar-item-container_cart-item-container'>
      <SidebarItemWrapper>
        <ItemsInfoWrapper data-testid='sidebar-item_two-column-title_quantity-name-serial'>
          <ItemsInfoProduct data-testid={productNameTestId}>
            {item.BrandName && <ItemBrandName data-testid={brandNameTestId}>{item.BrandName}</ItemBrandName>}
            {item.Product}
          </ItemsInfoProduct>
          <AssignedPackagePill isItemFromPreorder={isItemFromPreorder} packageId={packageId} />
        </ItemsInfoWrapper>

        <ItemsInfoActions>
          {canUseQuantityControls ? (
            <ProductSelectionControls
              isInCartSummary
              product={item}
              onChange={handleQuantityChange}
              includeCartQtyInMax={true}
              amounts={{ grams, count: quantity }}
            />
          ) : (
            <ItemsCost data-testid={quantityToDisplayTestId}>Qty: {quantityToDisplay}</ItemsCost>
          )}

          <ItemCostWrapper>
            {totalDiscountAmount ? (
              <>
                <ItemsCostStrike data-testid={itemCostStrikethroughTestId}>{totalCostToDisplay}</ItemsCostStrike>
                <ItemsCost data-testid={discountedCostTestId}>{discountedCostToDisplay}</ItemsCost>
              </>
            ) : (
              <ItemsCost data-testid={itemCostTestId}>{totalCostToDisplay}</ItemsCost>
            )}
          </ItemCostWrapper>

          <OverflowMenu
            anchor={<StyledActionButton onClick={() => {}} label={<ActionElipsisIcon />} variant='white' />}
            automationId='cart-line-item'
            menuOptions={overflowOptions}
          />
        </ItemsInfoActions>
      </SidebarItemWrapper>
      {children}
    </SidebarItemContainer>
  );
};

const ItemsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`;

const ItemsInfoActions = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`;

const ItemCostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 67px;
  text-align: right;
`;

const ItemsCost = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
`;

const ItemsCostStrike = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dutchie.grey50};
  text-decoration: line-through;
`;

const ItemsInfoProduct = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
`;

const ItemBrandName = styled.div`
  color: ${colors.dutchie.grey50};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.125rem;
`;

const SidebarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.dutchie.primaryWhite};
  padding: 1rem 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
`;

const SidebarItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const StyledActionButton = styled(Button)`
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  min-width: 0;

  svg {
    width: 24px;
  }

  &:hover {
    background: transparent;
  }

  &:focus {
    border: 0;
    outline: 0;
  }
`;
