import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { FormikProps } from 'formik';
import { format } from 'date-fns';

import { State } from 'store';
import {
  getCustomerDetails,
  clearCustomerDetails,
  getReferralList,
  getAvailableGroups,
  clearCustomerDocuments,
  clearSelectedTransaction,
} from 'store/actions/CustomerActions';
import { loadValidatedForms } from 'store/actions/SettingsActions';
import { biotrackScanGuestIn } from 'api/GuestApi';
import { errorNotification } from 'store/actions/NotificationsActions';
import { CustomerDetails } from 'models/Customer';

import type { LocationState } from './types';
import { useFormValidation } from './hooks/useFormValidation';
import { initialValues } from './types';
import { useEditCustomerActions } from './hooks/useEditCustomerActions';
import { useSideTabs } from './hooks/useSideTabs';

export const useEditCustomerPage = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();

  // settings
  const validatedForms = useSelector((state: State) => state.settings.validatedForms);
  const isValidatedFormsLoading = useSelector((state: State) => state.settings.validatedFormsLoading);
  const integrations = useSelector((state: State) => state.settings.integrations);
  const locationSettings = useSelector((state: State) => state.settings.locationSettings);
  const isBlockBannedPatientCheckInEnabled = useSelector(
    (state: State) => state.settings.features.BlockBannedPatientCheckin
  );

  const customerDetails = useSelector((state: State) => state.customer.details);

  const isCustomerBanned = customerDetails?.status.toLowerCase() === 'banned';
  const isCustomerDetailsLoading = useSelector((state: State) => state.customer.detailsLoading);
  const isSubmitting = useSelector((state: State) => state.customer.submitting);
  const isCheckedIn = customerDetails ? customerDetails.ShipmentId > 0 : false;

  const [customerTypeId, setCustomerTypeId] = useState<null | string>(null);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  const formRef = useRef<FormikProps<CustomerDetails> | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const search = queryString.parse(location.search);
  const guestId = Number(search.id) || undefined;
  const guestMmj = search.mmj ? String(search.mmj) : '';

  const formSchema = useFormValidation({ customerTypeId });

  const isCreatingNewCustomer = !guestId && !customerDetails;

  const titleString = guestId ? (customerDetails ? `${customerDetails.FullName}` : '') : 'Create New Guest';

  const createdDate = customerDetails?.CreationDate ? new Date(customerDetails.CreationDate) : null;
  const customerSinceString = createdDate ? `Customer since ${format(createdDate, 'MMMM yyyy')}` : '';

  const isLoading =
    customerDetails?.Guest_id !== guestId || isCustomerDetailsLoading || isValidatedFormsLoading || !validatedForms;

  const { sideTabPages, policyTabIndex } = useSideTabs({ setCustomerTypeId, formSchema, formRef });
  const {
    isActionsLoading,
    handleCheckIn,
    handleClickCancel,
    handleCreateOrder,
    createOrSave,
    handleClickCreateOrSave,
    handleClickVerifyDOB,
    handleClickCheckInCaregiver,
    handleClickConvertToNonAnonymous,
    showCheckInButton,
    showCheckInCaregiverButton,
    showCreateOrderButton,
    showConvertToNonAnonymousButton,
    showVerifyDOBButton,
  } = useEditCustomerActions({ isCreatingNewCustomer, formRef, policyTabIndex, guestId });

  const isValidCustomer =
    !(isBlockBannedPatientCheckInEnabled && customerDetails?.status === 'Banned') && !isActionsLoading;

  useEffect(() => {
    dispatch(clearSelectedTransaction());
  }, [dispatch]);

  useEffect(() => {
    formRef.current?.setValues({ ...initialValues, ...(customerDetails as CustomerDetails) });
  }, [customerDetails]);

  useEffect(() => {
    dispatch(clearCustomerDetails());
    dispatch(clearCustomerDocuments());
    dispatch(getAvailableGroups());
    dispatch(getReferralList());
    dispatch(loadValidatedForms());
    if (guestId) {
      if (integrations?.UseBioTrackPOS && customerDetails?.MJStateIDNo) {
        biotrackScanGuestIn({
          PatientScan: customerDetails?.MJStateIDNo || '',
          ShipmentId: 0,
        })
          .catch((err) => {
            dispatch(errorNotification(err));
          })
          .finally(() => {
            dispatch(getCustomerDetails({ guestId }));
          });
      } else {
        dispatch(getCustomerDetails({ guestId }));
      }
    }

    if (locationSettings?.DefaultCustomerTypeId) {
      initialValues.CustomerTypeId = locationSettings.DefaultCustomerTypeId;
    }

    initialValues.MJStateIDNo = guestMmj;
    if (guestMmj) {
      initialValues.CustomerTypeId = 1;
    }

    initialValues.identifications = initialValues.identifications?.map((id) => {
      let idNumber = id.number;
      switch (id.type) {
        case 'MJStateIDNo':
          idNumber = guestMmj;
          break;
        case 'CustomerTypeID':
          idNumber = guestMmj !== '' ? '1' : id.number;
          break;
      }
      return { ...id, number: idNumber };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestId]);

  return {
    isLoading,
    isSubmitting,
    isCreatingNewCustomer,
    isCustomerBanned,
    isCheckedIn,
    isValidCustomer,
    isFormChanged,
    formSchema,
    formRef,
    headerRef,
    customerDetails,
    customerSinceString,
    titleString,
    showCheckInButton,
    showCheckInCaregiverButton,
    showCreateOrderButton,
    showVerifyDOBButton,
    showConvertToNonAnonymousButton,
    handleClickCancel,
    handleCheckIn,
    handleCreateOrder,
    handleClickCreateOrSave,
    handleClickVerifyDOB,
    handleClickCheckInCaregiver,
    handleClickConvertToNonAnonymous,
    createOrSave,
    setIsFormChanged,
    sideTabPages,
  };
};
