import React, { FC, useCallback, useState } from 'react';
import { ConfirmationPopup } from 'components/popups';
import { Input } from 'components/inputs';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { errorNotification } from 'store/actions/NotificationsActions';
import { callback } from 'models/Misc';
import { NumberFormatValues } from 'react-number-format';

const Container = styled.div`
  padding: 0 1.5rem;
`;

type Last4PopupProps = {
  serialNumber: string;
  onSuccess: callback;
  hide: callback;
  onCancel?: callback;
};

export const Last4Popup: FC<Last4PopupProps> = ({ hide, serialNumber, onSuccess, onCancel }) => {
  const dispatch = useDispatch();
  const [digits, setDigits] = useState<string>('');

  const checkSerial = () => {
    if (serialNumber.length >= 4 && serialNumber.substring(serialNumber.length - 4) === digits) {
      onSuccess();
      hide();
    } else {
      dispatch(errorNotification('Serial Number is not matching'));
    }
  };

  const handleKeyPress = (key: string) => {
    if (key === 'Enter') {
      checkSerial();
    }
  };

  const onHide = useCallback(() => {
    hide();
    onCancel && onCancel();
  }, [hide, onCancel]);

  return (
    <ConfirmationPopup
      title='Verify Serial Number'
      isVisible
      hide={onHide}
      confirm={{
        text: 'Confirm',
        onClick: checkSerial,
        disabled: digits.length < 4,
      }}
      cancel={{
        text: 'Cancel',
        onClick: onHide,
      }}
    >
      <Container>
        <Input
          type='number'
          placeholder='Last 4 digits'
          allowLeadingZeros
          allowNegative={false}
          isAllowed={(values: NumberFormatValues) => {
            if (values.value.length > 4) {
              return false;
            }
            return true;
          }}
          onValueChange={(values) => setDigits(values.value)}
          onKeyPress={(e: { key: string }) => handleKeyPress(e.key)}
        />
      </Container>
    </ConfirmationPopup>
  );
};
