import { clamp } from 'lodash';

import { findCorrespondingUnitWithId } from 'util/Helpers';
import { getAllotmentValueToDisplay } from 'util/helpers/allotment';

import type { CategoryProps } from './Category';

export const useCategory = ({ allotment }: CategoryProps) => {
  const categoryUnit = findCorrespondingUnitWithId(allotment.UnitId ?? 3);
  const usedAmount = allotment.Used + allotment.InCart;
  const limitRemaining = allotment.Limit - usedAmount;
  const over = limitRemaining < 0;

  let usedPercent = (usedAmount / allotment.Limit) * 100;
  if (isNaN(usedPercent)) {
    usedPercent = 100;
  }
  usedPercent = clamp(usedPercent, 0, 100);

  const usedAmountToDisplay = `${getAllotmentValueToDisplay(usedAmount)}${categoryUnit}`;
  const limitAmountToDisplay = `${getAllotmentValueToDisplay(allotment.Limit)}${categoryUnit}`;
  const limitRemainingToDisplay = `${getAllotmentValueToDisplay(limitRemaining)}${categoryUnit}`;
  const percentRemainingToDisplay = `${(100 - usedPercent).toFixed()}%`;

  return {
    limitAmountToDisplay,
    limitRemainingToDisplay,
    over,
    percentRemainingToDisplay,
    usedAmountToDisplay,
    usedPercent,
  };
};
