import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';
import { checkManagerPin } from 'util/Helpers';
import { errorNotification } from 'store/actions/NotificationsActions';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';

const Container = styled.div`
  padding: 0 2rem;
  & p {
    margin: 0 0 1rem;
  }
`;

type PastExpirationDateProps = {
  onConfirm: callback;
  expiredItemsSerialNumbers: string[];
  hide: callback;
};

export const PastExpirationDatePopup: FC<PastExpirationDateProps> = ({
  onConfirm,
  expiredItemsSerialNumbers,
  hide,
}) => {
  const dispatch = useDispatch();
  const [pin, setPin] = useState<string>('');
  const [isManagerValidated, setIsManagerValidated] = useState<boolean>(false);

  const validatePin = async () => {
    try {
      await checkManagerPin(pin);
      setIsManagerValidated(true);
    } catch {
      dispatch(errorNotification('Invalid Manager PIN'));
      return;
    }
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title={`Expired items in cart`}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
      confirm={{
        text: 'Confirm',
        onClick: () => {
          onConfirm();
          hide();
        },
        disabled: !isManagerValidated,
      }}
    >
      <Container>
        <p>
          The following items (by serial number) are expired: {expiredItemsSerialNumbers.join(', ')}. Either remove the
          expired items from the cart or enter a valid manager pin before clicking 'Yes' to continue with the
          transaction.
        </p>
        <Input
          placeholder='Manager PIN'
          type='password'
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          endAdornment={
            <Button onClick={validatePin} isAdornment>
              Verify
            </Button>
          }
        />
      </Container>
    </ConfirmationPopup>
  );
};
