import React, { useState } from 'react';
import styled from 'styled-components';
import { useTable, useFlexLayout, usePagination, useSortBy, Column, PluginHook, Row } from 'react-table';

import { colors, breakpoints } from 'css/Theme';
import { callback1 } from 'models/Misc';
import { Pagination } from 'components/layout';

type ArrowProps = {
  disabled: boolean;
};

type PaginationStyle = 'Standard' | 'Sticky';

export type TableProps<T extends object> = {
  data: Array<T>;
  columns: Array<Column<T>>;
  pageSize?: number;
  plugins?: Array<PluginHook<T>>;
  onClick?: callback1<T | undefined>;
  containerEl?: HTMLTableSectionElement | HTMLDivElement | null;
  paginationStyle?: PaginationStyle;
};

export const TableCompact = <T extends object>({
  columns,
  data,
  plugins = [],
  pageSize: initPageSize = 50,
  onClick,
  containerEl,
  paginationStyle = 'Standard',
}: TableProps<T>) => {
  const [selected, setSelected] = useState<string | undefined>();

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      // minWidth: 30, // minWidth is only used as a limit for resizing
      width: 50, // width is used for both the flex-basis and flex-grow
      // maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const {
    headerGroups: [headerGroup],
    page,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<T>(
    {
      defaultColumn,
      columns,
      data,
      initialState: { pageSize: initPageSize },
      autoResetSortBy: false,
    },
    useSortBy,
    useFlexLayout,
    usePagination,
    ...plugins
  );

  const onSelectRow = (row: Row<T>) => {
    if (row.id === selected) {
      setSelected(undefined);
      onClick && onClick(undefined);
    } else {
      setSelected(row.id);
      onClick && onClick(row.original);
    }
  };

  // useTable uses zero-indexed pages in `gotoPage`
  const goToPage = (page: number) => gotoPage(page - 1);

  return (
    <>
      <TableBaseDiv {...getTableProps()}>
        <TrDiv {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <ThDiv {...column.getHeaderProps()} className='th'>
              {column.render('Header')}
            </ThDiv>
          ))}
        </TrDiv>
        <TBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TrDiv
                {...row.getRowProps()}
                selected={row.id === selected}
                key={row.id}
                onClick={() => onSelectRow(row)}
              >
                {row.cells.map((cell) => (
                  <TdDiv {...cell.getCellProps()} className='td'>
                    {cell.render('Cell')}
                  </TdDiv>
                ))}
              </TrDiv>
            );
          })}
        </TBody>
      </TableBaseDiv>
      {pageSize < data.length && (
        <PaginationContainer sticky={paginationStyle === 'Sticky'}>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            dataLength={data.length}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            goToPage={goToPage}
            setPageSize={setPageSize}
            containerEl={containerEl}
          />
        </PaginationContainer>
      )}
    </>
  );
};

const TableBaseDiv = styled.div`
  border-spacing: 0;
  width: 100%;
  overflow: auto;
`;

const TrDiv = styled.div<{ selected?: boolean }>`
  ${({ selected }) => selected && `background: ${colors.dutchie.lightGrey};`}
  border-radius: 4px;
  padding: 8px;
`;

const ThDiv = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: ${colors.dutchie.darkGrey};

  ${breakpoints.wideTablet.maxQuery} {
    font-size: 14px;
  }

  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const TdDiv = styled.div`
  font-size: 16px;
  color: ${colors.dutchie.almostBlack};

  ${breakpoints.wideTablet.maxQuery} {
    font-size: 14px;
  }

  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const TBody = styled.div`
  overflow-x: hidden;
`;

const PaginationContainer = styled.div<{ sticky?: boolean }>`
  margin-top: 12px;

  ${({ sticky }) =>
    sticky &&
    `
        position: sticky;
        bottom: 0;
        margin-bottom: -1.5rem;
        background: white;
        border-top: 1px solid ${colors.dutchie.backgroundGrey};
    `}
`;
