
import { PeripheralServiceConfig, PeripheralType } from "@dutchie/capacitor-hardware";
import { MockedPeripheralService } from "../../shared/mock/MockedPeripheralService";
import type { MockedEvent, MockedScanEvent } from "../../shared/mock/types";

import { MockScanner } from "./MockScanner";

export class MockScannerService extends MockedPeripheralService<MockScanner> {
  constructor(config?: { service?: Partial<PeripheralServiceConfig>; isSupported?: () => boolean }) {
    super(PeripheralType.scanner, {
      defaultConfig: { name: 'MockScannerService', autoConnect: false, autoEnable: true },
      userConfig: config?.service,
    });

    // ensures that the `this` context is preserved
    this.handleScanEvent = this.handleScanEvent.bind(this);

    window.addEventListener(this.eventName('scan'), this.handleScanEvent);

    this.devices = [
      this.createDevice('1111', 'Mock Scanner 1'),
      this.createDevice('2222', 'Mock Scanner 2'),
    ];
  }

  private handleScanEvent(event: Event) {
    const detail = (event as CustomEvent).detail as MockedEvent<MockedScanEvent>;
    const scanner = this.getById(detail.data.id);
    if (!scanner) {
      throw new Error('Scanner not found');
    }

    scanner.emitBarcodeEvent({
      text: detail.data.barcode,
      bytes: new TextEncoder().encode(detail.data.barcode),
    });
    this.notifyReceived('scan', detail);
  }

  createDevice(id: string, name: string): MockScanner {
    return new MockScanner({ id, name, serviceName: this.name });
  }
}
