import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useRefetchProductsOnFilter = () => {
  const ldClient = useLDClient();
  const isRefetchProductsOnFilterRolledBack: boolean = ldClient?.variation(
    'pos.register.refetch-products-on-filter.rollback',
    false
  );
  return { isRefetchProductsOnFilterEnabled: !isRefetchProductsOnFilterRolledBack };
};
