import { PeripheralService, PeripheralType } from '@dutchie/capacitor-hardware';
import { PrintNodeReceiptPrinter } from './PrintNodeReceiptPrinter';
import { getReceiptPrintersList } from 'api/PosApi';
import { PrinterResponse } from 'models/Misc';
import { logger } from 'util/logger';

export class PrintNodeReceiptPrinterService extends PeripheralService<PrintNodeReceiptPrinter> {
  static serviceName = 'PrintNodeReceiptPrinterService';

  // Prevent additional requests while the first is still in progress
  private requestPromise: Promise<PrinterResponse[]> | undefined;

  constructor() {
    super(PeripheralType.receiptPrinter, {
      autoEnable: true,
      autoConnect: true,
      name: PrintNodeReceiptPrinterService.serviceName,
    });
  }

  get isSupported(): boolean {
    return true;
  }

  async availableDevices(): Promise<PrintNodeReceiptPrinter[]> {
    try {
      if (!this.requestPromise) {
        this.requestPromise = getReceiptPrintersList();
      }
      const results = await this.requestPromise;
      this.requestPromise = undefined;
      return results.map((result) => new PrintNodeReceiptPrinter(result));
    } catch (e) {
      logger.error(e, { message: 'availableDevices for PrintNodeReceiptPrinterService failed' });
    }
    return [];
  }
}
