import { Peripheral as CapacitorPeripheral, PeripheralPlugin } from '@dutchie/capacitor-peripheral';
import { logger, customEventKeys } from 'util/logger';

export class ValidationError extends Error {}
export class NotFoundError extends Error {}

export const PERIPHERAL_ERROR = 'register-peripheral-error';
export type PeripheralError = {
  message: string;
  code: string;
};

class PeripheralWrapper implements PeripheralPlugin {
  // references tryCall block in kotlin
  private handleCapacitorCall = async <CapacitorResponseType extends object>(
    callback: () => Promise<CapacitorResponseType>
  ) => {
    try {
      return await callback();
    } catch (e: any) {
      logger.warn<PeripheralError>(`peripheral error occurred: ${e.message} (code: ${e.code})`, {
        key: customEventKeys.peripheralError,
        message: e.message,
        code: e.code,
      });
      switch (e.code) {
        case 'ValidationException':
          throw new ValidationError(e.message);
        case 'NotFoundException':
          throw new NotFoundError(e.message);
        default:
          throw new Error('An error occurred during device action');
      }
    }
  };

  openCashDrawer = () => this.handleCapacitorCall(() => CapacitorPeripheral.openCashDrawer());
  connectLabelPrinter = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.connectLabelPrinter(data));
  disconnectLabelPrinter = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.disconnectLabelPrinter(data));
  labelPrinterDiagnostics = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.labelPrinterDiagnostics(data));
  printLabel = (data: { id: string; bytes: number[] }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.printLabel(data));
  searchLabelPrinters = () => this.handleCapacitorCall(() => CapacitorPeripheral.searchLabelPrinters());
  searchReceiptPrinters = () => this.handleCapacitorCall(() => CapacitorPeripheral.searchReceiptPrinters());
  connectReceiptPrinter = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.connectReceiptPrinter(data));
  disconnectReceiptPrinter = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.disconnectReceiptPrinter(data));
  printReceipt = (data: { id: string; bytes: number[] }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.printReceipt(data));
  printReceiptImage = (data: { id: string; bytes: number[] }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.printReceiptImage(data));
  receiptPrinterDiagnostics = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.receiptPrinterDiagnostics(data));
  receiptPrinterOpenCashDrawer = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.receiptPrinterOpenCashDrawer(data));
  searchScales = () => this.handleCapacitorCall(() => CapacitorPeripheral.searchScales());
  connectScale = (data: { id: string }) => this.handleCapacitorCall(() => CapacitorPeripheral.connectScale(data));
  disconnectScale = (data: { id: string }) => this.handleCapacitorCall(() => CapacitorPeripheral.disconnectScale(data));
  scaleDiagnostics = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.scaleDiagnostics(data));
  searchScanners = () => this.handleCapacitorCall(() => CapacitorPeripheral.searchScanners());
  connectScanner = (data: { id: string }) => this.handleCapacitorCall(() => CapacitorPeripheral.connectScanner(data));
  disconnectScanner = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.disconnectScanner(data));
  playScannerAction = (data: { id: string; action: number }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.playScannerAction(data));
  scannerDiagnostics = (data: { id: string }) =>
    this.handleCapacitorCall(() => CapacitorPeripheral.scannerDiagnostics(data));
  configureZebraScannerBarcode = () =>
    this.handleCapacitorCall(() => CapacitorPeripheral.configureZebraScannerBarcode());
  factoryResetZebraScannerBarcode = () =>
    this.handleCapacitorCall(() => CapacitorPeripheral.factoryResetZebraScannerBarcode());
  requestUsbPermissions = () => this.handleCapacitorCall(() => CapacitorPeripheral.requestUsbPermissions());
  missingUsbPermissionsCount = () => this.handleCapacitorCall(() => CapacitorPeripheral.missingUsbPermissionsCount());
  connectedDevices = () => this.handleCapacitorCall(() => CapacitorPeripheral.connectedDevices());
  startService = () => this.handleCapacitorCall(() => CapacitorPeripheral.startService());
}

const Peripheral = new PeripheralWrapper();

export * from '@dutchie/capacitor-peripheral';
// This overrides the export of Peripheral in the line above
export { Peripheral };
