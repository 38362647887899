import React from 'react';

import { AnonymousTransactionPopup as NewAnonymousTransactionPopup } from './AnonymousTransactionPopup';
import { AnonymousTransactionPopup as OldAnonymousTransactionPopup } from './AnonymousTransactionPopup-deprecated';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

type AnonymousTransactionPopupProps = React.ComponentProps<typeof NewAnonymousTransactionPopup>;

export const AnonymousTransactionPopup = (props: AnonymousTransactionPopupProps) => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  return isAnonymousCartLDFlagEnabled ? (
    <NewAnonymousTransactionPopup {...props} />
  ) : (
    <OldAnonymousTransactionPopup {...props} />
  );
};

