import { getLDClient, useBooleanLDFlag } from 'util/launchDarkly';
import { isWebViewApp, useLocalStorage } from '..';
import { useNewSettingsUi, getUseNewSettingsUi } from './useNewSettingsUi';
import { getIsStarGraphicSupportEnabled } from './useStarGraphicSupport';

const key = 'pos.register.printing-refactor.rollout';
export const getIsHardwareLibraryActive = () => {
  const ldClient = getLDClient();

  const isNewSettingsUi = getUseNewSettingsUi();
  if (isNewSettingsUi) {
    return true;
  }

  if (process.env.NODE_ENV === 'development' && localStorage.getItem(key) !== null) {
    return localStorage.getItem(key) === 'true';
  }

  return ldClient?.variation(key, false) === true || isWebViewApp || getIsStarGraphicSupportEnabled();
};

export const useHardwareLibrary = () => {
  const { value } = useLocalStorage(key);
  const flag = useBooleanLDFlag(key, false);
  const isNewSettingsUi = useNewSettingsUi();

  if (isNewSettingsUi) {
    return true;
  }

  if (process.env.NODE_ENV === 'development' && value !== null) {
    return value === 'true';
  }

  return flag || isWebViewApp || getIsStarGraphicSupportEnabled();
};
