import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Button } from 'components/buttons';
import { ReactComponent as DutchiePayIcon } from 'assets/icons/icon-dutchiepay.svg';
import { FeePaymentMethod, PaymentType } from 'models/Checkout';
import { colors } from 'css/Theme';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { ReactComponent as ExclamationIcon } from 'assets/icons/icon-exclamation-grey.svg';
import { FeatureSwitchKey, useFeatureSwitches } from 'pages/CartPage/hooks/useFeatureSwitches';
import { GeneriPayPaymentIntegration } from 'queries/v2/payments/generipay';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

type IDutchiePayInStorePaymentTypeButtonProps = {
  handleClick: (paymentType: PaymentType, paymentTypeMetaData: GeneriPayPaymentIntegration | null) => () => void;
};

export const DutchiePayInStorePaymentTypeButton = ({ handleClick }: IDutchiePayInStorePaymentTypeButtonProps) => {
  const checkout = useSelector((state: State) => state.checkout);
  const { getFeatureSwitch } = useFeatureSwitches();
  const dutchiePayFeatureSwitch = getFeatureSwitch(FeatureSwitchKey.DUTCHIEPAY);
  const isDutchiePayError = useSelector((state: State) => state.cart.isDutchiePayError);
  const buttonIsDisabled = checkout.payment?.methods.length !== 0 || !dutchiePayFeatureSwitch.Enabled;
  const buttonDisabledText =
    isDutchiePayError || !dutchiePayFeatureSwitch.Enabled
      ? 'Dutchie Pay is not available'
      : 'Cannot use with other payment types';

  const {
    data: { FeesByPaymentType },
  } = useGetCartDetails();

  const potentialFeeStr = _.chain(FeesByPaymentType)
    .filter((fee) => fee.FeePaymentMethod === FeePaymentMethod.DutchiePay)
    .map((fee) => fee.CashValue)
    .sum()
    .round(2)
    .value()
    ?.toFixed(2);
  return (
    <Container>
      <DutchiePayInstorePaymentButton
        automationId='payment-buttons_payment-type-button_dutchiePayInStore'
        data-testid='payment-buttons_payment-type-button_dutchiePayInStore'
        onClick={handleClick(PaymentType.DutchiePay, null)}
        disabled={buttonIsDisabled}
      >
        Pay with <DutchiePayIcon />
      </DutchiePayInstorePaymentButton>
      {Number(potentialFeeStr) > 0 && !buttonIsDisabled && (
        <DutchiePayFeeContainer>
          <DutchiePayInfoIcon />
          <DutchiePayFeeWeightedText>Additional ${potentialFeeStr} fee</DutchiePayFeeWeightedText>
          <DutchiePayFeeText>&nbsp;added to customer's subtotal</DutchiePayFeeText>
        </DutchiePayFeeContainer>
      )}
      {buttonIsDisabled && (
        <MultiPaymentTypesDisabledMessage>
          <StyledExclamationIcon />
          {buttonDisabledText}
        </MultiPaymentTypesDisabledMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
  gap: 13px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 1px solid ${colors.dutchie.borderGrey};
  box-shadow: 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const DutchiePayInstorePaymentButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 4px;
  background: ${colors.dutchie.green};
  border-color: ${colors.dutchie.green};
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.005em;
  font-feature-settings: 'calt' off;
  color: ${colors.white};
  height: 56px;
  &:disabled {
    background-color: ${colors.dutchie.grey70};
    border-color: ${colors.dutchie.grey70};
    opacity: 1;
  }
`;

const MultiPaymentTypesDisabledMessage = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: ${colors.dutchie.grey40};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DutchiePayFeeContainer = styled.div`
  height: 34px;
  background: ${colors.dutchie.green10};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 16px;
  align-self: stretch;
`;

const DutchiePayFeeWeightedText = styled.span`
  font-weight: 600;
  color: ${colors.dutchie.green};
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  padding-left: 8px;
`;

const DutchiePayFeeText = styled.span`
  color: ${colors.dutchie.green};
  font-family: 'Matter', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
`;

function DutchiePayInfoIcon(): JSX.Element {
  return (
    <svg fill='none' height='16' viewBox='0 0 15 16' width='15' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.5 0.5C6.01664 0.5 4.56659 0.939867 3.33323 1.76398C2.09986 2.58809 1.13856 3.75943 0.570907 5.12987C0.00324964 6.50032 -0.145275 8.00832 0.144114 9.46318C0.433503 10.918 1.14781 12.2544 2.1967 13.3033C3.2456 14.3522 4.58197 15.0665 6.03683 15.3559C7.49168 15.6453 8.99968 15.4968 10.3701 14.9291C11.7406 14.3614 12.9119 13.4001 13.736 12.1668C14.5601 10.9334 15 9.48336 15 8C15 6.01088 14.2098 4.10322 12.8033 2.6967C11.3968 1.29018 9.48913 0.5 7.5 0.5V0.5ZM7.65625 3.625C7.84167 3.625 8.02293 3.67998 8.1771 3.783C8.33127 3.88601 8.45143 4.03243 8.52239 4.20373C8.59335 4.37504 8.61191 4.56354 8.57574 4.7454C8.53956 4.92725 8.45028 5.0943 8.31916 5.22541C8.18805 5.35652 8.02101 5.44581 7.83915 5.48199C7.65729 5.51816 7.46879 5.49959 7.29749 5.42864C7.12618 5.35768 6.97976 5.23752 6.87675 5.08335C6.77374 4.92918 6.71875 4.74792 6.71875 4.5625C6.71875 4.31386 6.81752 4.0754 6.99334 3.89959C7.16915 3.72377 7.40761 3.625 7.65625 3.625ZM9.0625 12.0625H6.5625C6.39674 12.0625 6.23777 11.9967 6.12056 11.8794C6.00335 11.7622 5.9375 11.6033 5.9375 11.4375C5.9375 11.2717 6.00335 11.1128 6.12056 10.9956C6.23777 10.8783 6.39674 10.8125 6.5625 10.8125H7.03125C7.07269 10.8125 7.11244 10.796 7.14174 10.7667C7.17104 10.7374 7.1875 10.6977 7.1875 10.6562V7.84375C7.1875 7.80231 7.17104 7.76257 7.14174 7.73326C7.11244 7.70396 7.07269 7.6875 7.03125 7.6875H6.5625C6.39674 7.6875 6.23777 7.62165 6.12056 7.50444C6.00335 7.38723 5.9375 7.22826 5.9375 7.0625C5.9375 6.89674 6.00335 6.73777 6.12056 6.62056C6.23777 6.50335 6.39674 6.4375 6.5625 6.4375H7.1875C7.51902 6.4375 7.83697 6.5692 8.07139 6.80362C8.30581 7.03804 8.4375 7.35598 8.4375 7.6875V10.6562C8.4375 10.6977 8.45396 10.7374 8.48327 10.7667C8.51257 10.796 8.55231 10.8125 8.59375 10.8125H9.0625C9.22826 10.8125 9.38723 10.8783 9.50444 10.9956C9.62165 11.1128 9.6875 11.2717 9.6875 11.4375C9.6875 11.6033 9.62165 11.7622 9.50444 11.8794C9.38723 11.9967 9.22826 12.0625 9.0625 12.0625Z'
        fill={colors.dutchie.green80}
      />
    </svg>
  );
}

const StyledExclamationIcon = styled(ExclamationIcon)`
  margin-right: 8px;
`;
