import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { rollupDeliveries } from './utils';
import { State } from 'store';

export function usePins() {
  const displayDeliveries = useSelector((state: State) => state.deliveryList.displayDeliveries);
  const selectedDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);

  const pins = useMemo(() => rollupDeliveries(displayDeliveries), [displayDeliveries]);
  const selectedDeliveryIds = useMemo(() => new Set(selectedDeliveries.map((d) => d.ShipmentId)), [selectedDeliveries]);
  const selectedPins = useMemo(
    () => pins.filter((p) => p.Deliveries.every((d) => selectedDeliveryIds.has(d.ShipmentId))),
    [pins, selectedDeliveryIds]
  );
  const splitPins = useMemo(
    () =>
      pins
        .filter((p) => p.Deliveries.some((d) => selectedDeliveryIds.has(d.ShipmentId)))
        .filter((p) => p.Deliveries.some((d) => !selectedDeliveryIds.has(d.ShipmentId))),
    [pins, selectedDeliveryIds]
  );
  const unselectedPins = useMemo(
    () => pins.filter((p) => p.Deliveries.every((d) => !selectedDeliveryIds.has(d.ShipmentId))),
    [pins, selectedDeliveryIds]
  );

  return { selectedPins, splitPins, unselectedPins };
}

