import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { CheckedInGuest } from 'models/Guest';
import { showCancelTransactionPopup } from 'store/actions/PopupsActions';

type UseBannedCustomerPopupParams = {
  guest: CheckedInGuest;
};

type UseBannedCustomerPopupReturn = {
  handleCancelTransaction: () => void;
  handleEditProfile: () => void;
};

export const useBannedCustomerPopup = ({ guest }: UseBannedCustomerPopupParams): UseBannedCustomerPopupReturn => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCancelTransaction = () => {
    dispatch(showCancelTransactionPopup({ guest }));
  };

  const handleEditProfile = () => {
    history.push(`/edit-customer?id=${guest.Guest_id}`);
  };

  return {
    handleCancelTransaction,
    handleEditProfile,
  };
};
