import React, { FC, useCallback, useEffect, useState } from 'react';
import { useCartPopups } from 'components/CartPopups';
import { useSelector } from 'react-redux';
import { State } from 'store';
import styled from 'styled-components';

import { breakpoints } from 'css/Theme';
import { ActionButton, AddItemsButton } from 'components/cart/CartComponents.styles';
import { useRegisterSettings } from 'util/hooks';
import { CartInfoHeader } from './CartInfoHeader';
import { ReactComponent as AddItemsIcon } from 'assets/icons/add-items-icon.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as ElipsisIcon } from 'assets/icons/elipsis-icon.svg';
import { ReactComponent as CarIcon } from 'assets/icons/icon-delivery.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/printer.svg';
import { ReactComponent as LabelsIcon } from 'assets/icons/icon-labels.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/checkmark-filled.svg';
import { MenuItem } from 'components/backoffice/menu-item';
import { Skeleton } from 'components/misc';
import { ButtonWithDropDown } from 'components/buttons';
import { PrintStatus } from 'models/Printing';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { usePrintJobStatus } from 'util/hooks/printing/usePrintJobStatus';
import { useCartOverflowOptions } from './useCartOverflowOptions';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { useAnonymousCart } from '../hooks/useAnonymousCart';
import { useGetCartDetails } from '../hooks/useGetCartDetails';
import { useReplaceGuestObjRefs } from 'util/hooks/launch-darkly/useReplaceGuestObjRefs';

type SkeletonProps = {
  deliveryEnabled?: boolean;
  allowDeliveryReceipts?: boolean;
  showSeparateLabelsButton?: boolean;
};

const CartContextMenuSkeleton: FC<SkeletonProps> = ({
  deliveryEnabled,
  allowDeliveryReceipts,
  showSeparateLabelsButton,
}) => {
  return (
    <CartSummaryContainer>
      <CartInfoHeader />
      <CartActionsWrapper>
        <QuickActions>
          {deliveryEnabled && <Skeleton height={44} width={110} />}
          {(allowDeliveryReceipts || !showSeparateLabelsButton) && <Skeleton height={44} width={100} />}
          {showSeparateLabelsButton && <Skeleton height={44} width={147} />}
        </QuickActions>
        <Skeleton height={44} width={123} />
        <Skeleton height={44} width={50} />
      </CartActionsWrapper>
    </CartSummaryContainer>
  );
};

type PrintAction = {
  label: string;
  success?: boolean;
  onClick: () => void;
  hide?: boolean;
  disabled?: boolean;
};

enum LabelsButtonBreakpoints {
  WithDeliveryOrPrinting = 480,
  WithDeliveryAndPrinting = 820,
}

type CartContextMenuProps = {
  loading?: boolean;
  setAddItemPanelIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CartContextMenu = React.memo(CartContextMenuImpl);

export function CartContextMenuImpl({ loading, setAddItemPanelIsOpen }: CartContextMenuProps): JSX.Element {
  const guest = useSelector((state: State) => state.customer.details);
  const deliveryEnabled = useSelector((state: State) => state.settings.features.EnableDelivery);
  const searchRequiresManagerPin = useSelector((state: State) => state.settings.features.ManagerSearchFlag);
  const allowDeliveryReceipts = useRegisterSettings()?.AllowDeliveryReceipts ?? true;
  const [showSeparateLabelsButton, setShowSeparateLabelsButton] = useState(true);

  const { data: cart } = useGetCartDetails();

  const isPreorder = cart.PreOrders.length > 0;

  const selectedReceiptPrinter = useSelector((state: State) => state.settings.userSettings.selectedReceiptPrinter);
  const cartPopups = useCartPopups();
  const { printCart, printLabels, printPickTicket, printReceipt } = usePrintJob();
  const { printCartStatus, printLabelsStatus, printPickTicketStatus, printReceiptStatus, resetPrintJobStatuses } =
    usePrintJobStatus();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { isReplaceGuestObjRefsEnabled } = useReplaceGuestObjRefs();

  const isPrintReceiptButtonDisabled = printReceiptStatus === PrintStatus.PRINTING;
  const isPrintCartButtonDisabled = printCartStatus === PrintStatus.PRINTING;
  const isPrintPickTicketButtonDisabled = printPickTicketStatus === PrintStatus.PRINTING;
  const isPrintAllLabelsButtonDisabled = printLabelsStatus === PrintStatus.PRINTING;

  useEffect(() => {
    // Reset the print status when loading the cart header
    resetPrintJobStatuses();
  }, [resetPrintJobStatuses]);

  const handleOpenAddItemPanel = useCallback(() => {
    if (setAddItemPanelIsOpen) {
      setAddItemPanelIsOpen(true);
    }
  }, [setAddItemPanelIsOpen]);

  const onPrintReceipt = () => {
    if (cart.ShipmentId) {
      printReceipt({
        ReceiptType: 'Receipt',
        ReceiptParameters: cart.ShipmentId,
        ForDelivery: false,
        PrinterId: selectedReceiptPrinter?.PrinterId || 0,
        subtotal: cart.SubTotal,
        total: cart.GrandTotalRounded,
        localPrinter: selectedReceiptPrinter?.LocalPrinter || false,
        popCashDrawer: false,
      });
    }
  };

  const onPrintCart = () => {
    if (isReplaceGuestObjRefsEnabled) {
      printCart({
        guest: {
          Guest_id: cart.CustomerId,
          ShipmentId: cart.ShipmentId,
        },
        autoPrint: false,
      });
    } else {
      printCart({ guest, autoPrint: false });
    }
  };

  const onPrintPickTicket = () => {
    if (isReplaceGuestObjRefsEnabled) {
      printPickTicket({
        guest: {
          Guest_id: cart.CustomerId,
          ShipmentId: cart.ShipmentId,
        },
      });
    } else {
      printPickTicket({ guest });
    }
  };

  const onPrintLabels = () => {
    if (cart.ShipmentId) {
      printLabels({
        guest: { ShipmentId: cart.ShipmentId },
        items: cart.Cart,
        printAll: true,
        autoPrint: false,
      });
    }
  };

  const overflowMenuActions = useCartOverflowOptions();

  useEffect(() => {
    const updateLayout = () => {
      const width = window.innerWidth;
      if (deliveryEnabled && allowDeliveryReceipts) {
        const breakpoint = LabelsButtonBreakpoints.WithDeliveryAndPrinting;
        setShowSeparateLabelsButton(width > breakpoint);
      } else if (deliveryEnabled || allowDeliveryReceipts) {
        const breakpoint = LabelsButtonBreakpoints.WithDeliveryOrPrinting;
        setShowSeparateLabelsButton(width > breakpoint);
      } else {
        // If delivery is off and printing is not allowed, always show separate labels button
        setShowSeparateLabelsButton(true);
      }
    };
    updateLayout();

    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, [deliveryEnabled, allowDeliveryReceipts]);

  const showLoadingSkeleton = isAnonymousCartLDFlagEnabled ? loading : loading || !guest;

  if (showLoadingSkeleton) {
    return (
      <CartContextMenuSkeleton
        deliveryEnabled={deliveryEnabled}
        allowDeliveryReceipts={allowDeliveryReceipts}
        showSeparateLabelsButton={showSeparateLabelsButton}
      />
    );
  }

  const validatePin = async () => {
    cartPopups.showManagerPinPopup(() => {
      handleOpenAddItemPanel();
    }, 'Enter Manager PIN to unlock search');
  };

  const renderPrintAction = (action: PrintAction, closeDropdown: () => void) => {
    if (action.hide) {
      return null;
    }

    return (
      <PrintMenuItem
        value={action.label}
        disabled={action.disabled}
        onClick={() => {
          action.onClick();
          closeDropdown();
        }}
      >
        {action.label}
        {action.success && <SuccessIcon />}
      </PrintMenuItem>
    );
  };

  const AddItemsButtonComponent = !searchRequiresManagerPin ? AddItemsButton : ActionButton;

  return (
    <CartSummaryContainer>
      <CartInfoHeader loading={loading} />
      <CartActionsWrapper>
        <QuickActions>
          {deliveryEnabled && (
            <ActionButton
              secondary
              icon={CarIcon}
              onClick={() =>
                cartPopups.showDeliverySettingsPopup({ Guest_id: cart.CustomerId, ShipmentId: cart.ShipmentId })
              }
              automationId='cart-context-menu_button_delivery'
            >
              Delivery
            </ActionButton>
          )}
          {(allowDeliveryReceipts || !showSeparateLabelsButton) && (
            <ButtonWithDropDown<PrintAction>
              secondary
              icon={PrintIcon}
              items={[
                {
                  label: 'Print receipt',
                  success: printReceiptStatus === PrintStatus.SUCCESSFUL,
                  onClick: onPrintReceipt,
                  hide: !allowDeliveryReceipts,
                  disabled: isPrintReceiptButtonDisabled,
                },
                {
                  label: 'Print cart',
                  success: printCartStatus === PrintStatus.SUCCESSFUL,
                  onClick: onPrintCart,
                  hide: !allowDeliveryReceipts,
                  disabled: isPrintCartButtonDisabled,
                },
                {
                  label: 'Print pick ticket',
                  success: printPickTicketStatus === PrintStatus.SUCCESSFUL,
                  onClick: onPrintPickTicket,
                  hide: !isPreorder,
                  disabled: isPrintPickTicketButtonDisabled,
                },
                {
                  label: 'Print all labels',
                  success: printLabelsStatus === PrintStatus.SUCCESSFUL,
                  onClick: onPrintLabels,
                  hide: showSeparateLabelsButton,
                  disabled: isPrintAllLabelsButtonDisabled,
                },
              ]}
              customItemRender={renderPrintAction}
              dropDownWidth='12rem'
              buttonHeight='2.75rem'
              buttonPadding='0 0.75rem'
              automationId='cart-context-menu_button_print'
            >
              Print
            </ButtonWithDropDown>
          )}
          {showSeparateLabelsButton && (
            <ActionButton
              secondary
              icon={printLabelsStatus === PrintStatus.SUCCESSFUL ? SuccessIcon : LabelsIcon}
              loading={printLabelsStatus === 'printing'}
              onClick={onPrintLabels}
              automationId={`cart-context-menu_button_print-all-labels_${printLabelsStatus}`}
            >
              Print all labels
            </ActionButton>
          )}
        </QuickActions>
        <AddItemsButtonComponent
          secondary={!searchRequiresManagerPin}
          tertiary={searchRequiresManagerPin}
          icon={searchRequiresManagerPin ? KeyIcon : AddItemsIcon}
          disabled={cart.Locked}
          onClick={() => (searchRequiresManagerPin ? validatePin() : handleOpenAddItemPanel())}
          automationId='cart-context-Menu_button_add-items'
        >
          Add items
        </AddItemsButtonComponent>
        <OverflowMenu
          anchor={
            <ActionButton secondary>
              <ElipsisIcon />
            </ActionButton>
          }
          automationId='cart-overflow-menu'
          menuOptions={overflowMenuActions}
        />
      </CartActionsWrapper>
    </CartSummaryContainer>
  );
}

const PrintMenuItem = styled(MenuItem)`
  display: flex;
  gap: 0.5rem;
`;

const CartSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${breakpoints.smallTablet.maxQuery} {
    padding: 1.5rem 1.5rem 1rem;
  }
`;

const CartActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const QuickActions = styled(CartActionsWrapper)`
  flex-grow: 1;
`;
