import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';

type ArrowProps = {
  side: 'left' | 'right' | 'up' | 'down';
  disabled?: boolean;
  color?: string;
};

export const Arrow = styled(ChevronLeft)<ArrowProps>`
  cursor: pointer;
  width: 1rem;
  height: 1rem;

  ${({ color }) =>
    css`
      color: ${color || colors.dutchie.almostBlack};
    `}

  ${({ side }) =>
    side === 'right'
      ? css`
          transform: rotate(180deg);
        `
      : side === 'up'
      ? css`
          transform: rotate(90deg);
        `
      : side === 'down'
      ? css`
          transform: rotate(-90deg);
        `
      : ``}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: normal;
      opacity: 0.25;
    `}
`;
