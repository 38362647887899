import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const LabelPrinterIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29199 0.624837C6.29199 0.302671 6.55316 0.0415039 6.87533 0.0415039H13.1253C13.4475 0.0415039 13.7087 0.302671 13.7087 0.624837V7.63567L13.7087 7.63776C13.708 7.8196 13.6588 7.99796 13.566 8.15437C13.4733 8.31078 13.3404 8.43954 13.1811 8.52734L13.1809 8.5275C13.0233 8.61424 12.846 8.65861 12.6662 8.65628C12.4975 8.65409 12.332 8.61087 12.184 8.53057L10.0061 7.52053L7.78668 8.59844C7.63233 8.67586 7.46104 8.71351 7.28837 8.70792C7.11391 8.70227 6.9437 8.65266 6.79353 8.56369C6.64154 8.47408 6.5154 8.34653 6.42749 8.19352C6.33933 8.04011 6.29264 7.86639 6.292 7.68945L6.29199 7.68734V0.624837ZM7.45866 1.20817V7.46077L9.74548 6.35012C9.9031 6.27356 10.0868 6.27192 10.2457 6.34564L12.542 7.41056V1.20817H7.45866Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.87533 1.20817C1.50714 1.20817 1.20866 1.50665 1.20866 1.87484V18.1248C1.20866 18.493 1.50714 18.7915 1.87533 18.7915H18.1253C18.4935 18.7915 18.792 18.493 18.792 18.1248V1.87484C18.792 1.50665 18.4935 1.20817 18.1253 1.20817H1.87533ZM0.0419922 1.87484C0.0419922 0.862315 0.862803 0.0415039 1.87533 0.0415039H18.1253C19.1378 0.0415039 19.9587 0.862315 19.9587 1.87484V18.1248C19.9587 19.1374 19.1378 19.9582 18.1253 19.9582H1.87533C0.862803 19.9582 0.0419922 19.1374 0.0419922 18.1248V1.87484Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.042 13.7498C10.042 13.4277 10.3032 13.1665 10.6253 13.1665H16.8753C17.1975 13.1665 17.4587 13.4277 17.4587 13.7498V16.8748C17.4587 17.197 17.1975 17.4582 16.8753 17.4582H10.6253C10.3032 17.4582 10.042 17.197 10.042 16.8748V13.7498ZM11.2087 14.3332V16.2915H16.292V14.3332H11.2087Z" fill={color} />
    </svg>
  );
};
