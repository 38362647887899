import { post } from 'api/HttpHelpers';
import { isAndroid } from "../util/hooks";
import { LDFlagSet } from "launchdarkly-react-client-sdk";

const PIN_LOGIN_URL = 'pinlogin';
const ELO_PIN_LOGIN_URL = 'pinlogin/elo';

type InitiatePinLoginPayloadResponse = {
  Result: boolean;
  Data: {
    UserName?: string;
    SessionId?: string;
    Id?: number;
    FullName?: string;
  };
  Message: string;
};

type InitiatePinLoginPayload = {
  Pin: string;
  DeviceSessionId?: string|null;
  DeviceSessionTime?: string|null;
  ldFlagSet?: LDFlagSet;
};

export const initiatePinLogin = async (payload: InitiatePinLoginPayload) => {
  if (payload.ldFlagSet && payload.ldFlagSet['pos.iam.pin-login-elo.rollout'] && isAndroid) {
    return await initiateEloPinLogin(payload);
  }

  const resp = await post<InitiatePinLoginPayloadResponse>(PIN_LOGIN_URL, payload, { ignoreFormat: true });
  if (resp.Result) {
    // match functionality at src/api/HttpHelpers.ts:89
    return resp.Data;
  } else {
    return Promise.reject({ message: resp.Message, data: resp.Data });
  }
};

const initiateEloPinLogin = async (payload: InitiatePinLoginPayload) => {
  const fullPayload = {
    ...payload,
    DeviceSessionId: window.sessionStorage.getItem('DeviceSessionId'),
    DeviceSessionTime: window.sessionStorage.getItem('DeviceSessionTime'),
  };
  const resp = await post<InitiatePinLoginPayloadResponse>(ELO_PIN_LOGIN_URL, fullPayload, { ignoreFormat: true });

  if (!resp.Result) {
    return Promise.reject({ message: resp.Message, data: resp.Data });
  }

  // set the session id in the session storage
  window.sessionStorage.setItem('SessionId', resp.Data.SessionId ?? '');
  window.sessionStorage.setItem('SessionGId', resp.Data.SessionId ?? '');
  window.sessionStorage.setItem('UserId', `${resp.Data.Id ?? ''}`);
  window.sessionStorage.setItem('FullName', resp.Data.FullName ?? '');
  window.sessionStorage.setItem('UserName', resp.Data.UserName ?? '');

  return resp;
};
