import { PusherChannelType } from 'models/Pusher';
import { PusherChannel } from 'components/PusherChannel';
import React from 'react';
import { useGetFeatureFlagPusherConfiguration } from 'queries/pusher/config/FeatureFlags';
import { useOnFeatureFlagPusherMessage } from './useOnFeatureFlagPusherMessage';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export const FeatureFlagsPusherChannel = () => {
  const ldClient = useLDClient();
  const pushSettingsFlag = ldClient?.variation('Pos.Pusher-Enabled-Settings-Updates', false);
  const { data: pusherChannelConfig, isLoading } = useGetFeatureFlagPusherConfiguration({ enabled: pushSettingsFlag });
  const onPusherMessage = useOnFeatureFlagPusherMessage();

  return (
    <>
      {pusherChannelConfig && !isLoading && (
        <PusherChannel
          enabled={true}
          channelConfig={pusherChannelConfig}
          channelType={PusherChannelType.FeatureFlags}
          onChannelMessage={onPusherMessage}
        />
      )}
    </>
  );
};
