import React, { useRef, useState } from 'react';
import { FormikProps } from 'formik';
import styled from 'styled-components';

import { PageHeader, PageTitle } from 'components/layout/PageHeader/PageHeader';
import { colors } from 'css/Theme';
import { Button } from 'components/buttons';
import { BottomDrawer, Column } from 'components/layout';
import { HardwareSettings } from './types';
import { HardwareSettingsForm } from './Form/HardwareSettingsForm';
import { ScrollHeader, ScrollBody, ScrollLayout } from 'components/layout/ScrollLayout';
import { UnsavedChangesPopup } from 'components/sharedPopups/UnsavedChangesPopup';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';

type SettingsPopupProps = {
  automationId: string;
  title: string;
  hide: () => void;
  open: boolean;
};

export const SettingsBottomDrawerForm = ({
  automationId,
  children,
  hide: hideDrawer,
  open,
  title,
}: React.PropsWithChildren<SettingsPopupProps>) => {
  const formRef = useRef<FormikProps<HardwareSettings> | null>(null);

  const [showConfirm, setShowConfirm] = useState(false);

  const handleHide = () => {
    if (formRef?.current?.dirty) {
      setShowConfirm(true);
      return;
    }

    hideDrawer();
  };

  const closeConfirmation = () => {
    setShowConfirm(false);
  };

  const saveAndContinue = async (): Promise<void> => {
    await formRef?.current?.submitForm();
    hideDrawer();
  };

  return (
    <BottomDrawer fullHeight open={open} onClose={hideDrawer} marginX={0} marginY={40} portalId={FullScreenPortalId}>
      {open && (
        <Content data-testid={automationId}>
          <HardwareSettingsForm formRef={formRef}>
            <ScrollLayout>
              <ScrollHeader>
                <PageHeader padding='0 32px'>
                  <PageTitle>{title}</PageTitle>
                  <Button tertiary type='button' onClick={handleHide}>
                    Return to settings
                  </Button>
                </PageHeader>
              </ScrollHeader>
              <ScrollBody>
                <Body>{children}</Body>
              </ScrollBody>
            </ScrollLayout>
          </HardwareSettingsForm>
        </Content>
      )}
      {showConfirm && (
        <UnsavedChangesPopup
          automationId={`${automationId}_unsaved_changes`}
          save={saveAndContinue}
          discard={hideDrawer}
          close={closeConfirmation}
        />
      )}
    </BottomDrawer>
  );
};

const Body = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Content = styled(Column)`
  background-color: ${colors.dutchie.grey10};
  height: 100%;
`;
