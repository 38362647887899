import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { handleLogout, SelectLocationAction } from 'store/actions/UserActions';
import { colors } from 'css/Theme';
import { State } from 'store';
import {
  loadLabelPrinters,
  loadLabels,
  loadReceiptPrinters,
  loadRegisters,
  selectRegister,
} from 'store/actions/SettingsActions';
import { Select } from 'components/inputs';
import { Button } from 'components/buttons';
import { Loader } from 'components/backoffice/loader';
import { UnauthorizedPageLayout, Center } from 'components/layout';
import { useSessionStorage } from 'util/hooks';
import { getPersistedValue, PersistedValueKey } from 'util/persisted-values';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export const RegisterSelectionPage: FC = () => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const registersContainHardwareIdRollout = ldClient?.variation('pos.register.should-return-register-hardware-id.rollback', false) === true;
  const registers = useSelector((state: State) => state.settings.registers);
  const loading = useSelector((state: State) => state.settings.registersLoading);
  const [selected, setSelected] = useState<number | undefined>();
  const history = useHistory();
  const noneSelected = selected === undefined;
  const placeholder = registers.length ? 'Select register' : 'None available';
  const { clear: clearToggleDismissal } = useSessionStorage('toggleDismissed');
  const hardwareId = useRef<string | null | undefined>(null);

  useEffect(() => {
    clearToggleDismissal(); //clear the toggle dismissal on the register selection screen.  Covers logout, switching register, and refresh scenarios
  }, [clearToggleDismissal]);

  useEffect(() => {
    const setupRegisters = async () => {
      hardwareId.current = await getPersistedValue(PersistedValueKey.hardwareId);
      dispatch(loadRegisters(hardwareId.current));
    };
    setupRegisters();
    dispatch(loadLabels());
    dispatch(loadLabelPrinters());
    dispatch(loadReceiptPrinters());
  }, [dispatch]);

  useEffect(() => {
    if (registersContainHardwareIdRollout && !!(hardwareId.current) && typeof hardwareId.current === "string") {
      const selectedRegister = registers.find((item) => {
        return item.HardwareId === hardwareId.current;
      });
      if (selectedRegister) {
        setSelected(selectedRegister.id);
      }
    } else if (registers?.length === 1) {
      setSelected(registers[0].id);
    }
  }, [registers, registersContainHardwareIdRollout]);

  return (
    <UnauthorizedPageLayout>
      {loading ? (
        <Center>
          <Loader variant='black' size='2x' />
        </Center>
      ) : (
        <>
          <Caption>Select your register</Caption>
          <StyledDutchieSelect
            value={selected}
            automationId='register-selection-page_select_register'
            onChange={(value) => setSelected(parseInt(value, 10))}
            options={registers.map((item) => ({ value: item.id, label: item.TerminalName }))}
            placeholder={placeholder}
            disabled={!registers.length}
          />
          <ButtonsRow>
            <div>
              {registers.length ? (
                <Button
                  onClick={() => {
                    const selectedRegister = registers.find((item) => item.id === selected);
                    if (selectedRegister) {
                      dispatch(
                        selectRegister({
                          value: selectedRegister.id,
                          label: selectedRegister.TerminalName,
                        })
                      );
                    }
                  }}
                  disabled={noneSelected}
                  automationId='register-selection-page_button_select-register'
                >
                  Select Register
                </Button>
              ) : (
                <Button onClick={() => dispatch(SelectLocationAction(undefined))}>Switch Location</Button>
              )}
            </div>
            <div>
              <LogoutLink
                data-testid='register-selection-page_button_logout'
                onClick={() => dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }))}
              >
                Logout
              </LogoutLink>
            </div>
          </ButtonsRow>
        </>
      )}
    </UnauthorizedPageLayout>
  );
};

const LogoutLink = styled.a`
  font-size: 14px;
  color: ${colors.dutchie.grey};
  text-decoration-line: underline;
`;

const Caption = styled.p`
  margin-top: 1.5rem;
  line-height: 1.25rem;
  color: ${colors.dutchie.almostBlack};
  letter-spacing: 0.005em;
`;

const StyledDutchieSelect = styled(Select)`
  margin-top: 1.25rem;
  width: 100%;
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
`;
