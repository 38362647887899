import React, { FC } from 'react';

import { closePopup } from 'store/actions/PopupsActions';
import { CreatePreOrderPopup } from 'components/sharedPopups/CreatePreOrderPopup';
import { SelectCollectorPopup } from 'components/sharedPopups/SelectCollectorPopup';
import { StartOrderPopup } from 'components/sharedPopups/StartOrderPopup';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { VerifyBirthdatePopup } from 'components/sharedPopups/VerifyBirthdatePopup';

export const GlobalPopups: FC = () => {
  const dispatch = useAppDispatch();

  const createPreOrderPopup = useAppSelector((state) => state.popups.createPreOrderPopup);
  const fulfillmentSelectorPopup = useAppSelector((state) => state.popups.fulfillmentSelectorPopup);
  const selectCollectorPopup = useAppSelector((state) => state.popups.selectCollectorPopup);
  const verifyBirthdatePopup = useAppSelector((state) => state.popups.verifyBirthdatePopup);

  const close = (popupName: string) => dispatch(closePopup(popupName));

  return (
    <>
      {createPreOrderPopup && (
        <CreatePreOrderPopup {...createPreOrderPopup} isVisible hide={() => close('createPreOrderPopup')} />
      )}
      {fulfillmentSelectorPopup && (
        <StartOrderPopup {...fulfillmentSelectorPopup} hide={() => close('fulfillmentSelectorPopup')} />
      )}
      {selectCollectorPopup && (
        <SelectCollectorPopup {...selectCollectorPopup} hide={() => close('selectCollectorPopup')} />
      )}
      {verifyBirthdatePopup && (
        <VerifyBirthdatePopup {...verifyBirthdatePopup} hide={() => close('verifyBirthdatePopup')} />
      )}
    </>
  );
};
