import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

import { zIndices } from 'css/Theme';
import { useNewSettingsUi } from 'util/hooks/launch-darkly/useNewSettingsUi';

type PopupOverlayProps = {
  className?: string;
  children: React.ReactNode;
  hide: () => void;
  portalContainer?: Element;
};

export function PopupOverlay(props: PopupOverlayProps) {
  const { children, className, hide, portalContainer } = props;
  const isNewSettingsUIEnabled = useNewSettingsUi();

  return createPortal(
    <Overlay className={className} onClick={hide} isNewSettingsUIEnabled={isNewSettingsUIEnabled}>
      {children}
    </Overlay>,
    portalContainer ?? document.body
  );
}

const Overlay = styled.div<{ isNewSettingsUIEnabled?: boolean }>`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center;
  position: fixed;
  ${({ isNewSettingsUIEnabled }) => isNewSettingsUIEnabled && 'padding: 20px 0;'}
  width: 100vw;
  z-index: ${zIndices.popup};
  top: 0;
  left: 0;
`;
