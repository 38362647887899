import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import styled from 'styled-components';

import { createPreorder, CreatePreorderRequest, pricePreorder, PricePreorderResponse } from 'api/PreorderApi';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { BottomDrawer, Center } from 'components/layout';
import { colors, eloOriPadBreakpoint } from 'css/Theme';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';
import { CartPopups, ProvideCartPopups } from 'components/CartPopups';
import { CartHeader } from 'components/cart/CartHeader';
import { CustomerHistoryPanel } from 'components/cart/CustomerHistoryPanel';
import { AddItemPanel } from './PreCartComponents/AddItemPanel';
import { PreCartSummary } from './PreCartSummary';
import { TotalsPanel } from './TotalsPanel';
import { Loader } from 'components/backoffice/loader';
import { logger, customEventKeys } from 'util/logger';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';

export type ItemPrice = {
  Total: number;
};

export type OrderPrice = {
  SubTotal: number;
  Discount: number;
  Taxes: number;
  RoundedDifference: number;
  Total: number;
};

export type PreorderCartItem = {
  productId: number;
  unitId: number;
  unitPrice: number;
  quantity: number;
  totalAvailable?: number;
  unitAbbr?: string;
  productName?: string;
  brandName?: string;
  category?: string;
  flowerEquivalent?: number;
  daysSupply?: number; // Need to add in backend
};

const CreatePreOrderPageWithoutPopups: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerDetails = useSelector((state: State) => state.customer.details);
  const preorderMetadata = useSelector((state: State) => state.customer.PreOrderMetadata);
  const { printPickTicket } = usePrintJob();
  const [saving, setSaving] = useState(false);
  const [addItemPanelIsOpen, setAddItemPanelIsOpen] = useState(false);
  const [orderPrice, setOrderPrice] = useState<PricePreorderResponse>();

  const isAutoPrintEnabled = useSelector((state: State) => state.settings.userSettings.autoPrintFulfillmentTicket);

  const [cartItems, setCartItems] = useState<PreorderCartItem[]>([]);

  const { itemPrices, calcOrderPrice } = useMemo(() => {
    const itemPrices: Record<number, ItemPrice> = {};
    let calcOrderPrice: OrderPrice;

    if (orderPrice) {
      //use the api-reported price
      calcOrderPrice = { ...orderPrice };
      for (const item of cartItems) {
        const itemPrice = orderPrice.CartItemPrices.find((x) => x.ProductId === item.productId);
        if (itemPrice) {
          const discounts = itemPrice.Discounts?.reduce((prev, cur) => prev + cur.Amount, 0) || 0;
          itemPrices[item.productId] = { Total: itemPrice.Subtotal - discounts };
        } else {
          itemPrices[item.productId] = { Total: 0 };
        }
      }
    } else {
      //something went wrong during cart pricing, use unitPrice * quantity as a rough guess
      for (const item of cartItems) {
        itemPrices[item.productId] = { Total: item.unitPrice * item.quantity };
      }

      const total = cartItems.reduce((prev, cur) => prev + cur.unitPrice * cur.quantity, 0);
      calcOrderPrice = {
        Total: total,
        SubTotal: total,
        Taxes: 0,
        RoundedDifference: 0,
        Discount: 0,
      };
    }

    return { itemPrices, calcOrderPrice };
  }, [orderPrice, cartItems]);

  useEffect(() => {
    if (!customerDetails?.Guest_id || cartItems.length === 0) {
      setOrderPrice(undefined);
      return;
    }

    let cancel = false;
    const func = async () => {
      try {
        const orderPrice = await pricePreorder({
          cartItems: cartItems.map((x) => ({ ProductId: x.productId, Quantity: x.quantity })),
          customerId: customerDetails.Guest_id,
          isDelivery: preorderMetadata?.orderTypeId === 2,
          deliveryStreet: preorderMetadata?.newAddress?.street,
          deliveryStreet2: preorderMetadata?.newAddress?.street2,
          deliveryCity: preorderMetadata?.newAddress?.city,
          deliveryState: preorderMetadata?.newAddress?.state,
          deliveryPostalCode: preorderMetadata?.newAddress?.postal_code,
        });

        if (cancel) {
          return;
        }
        setOrderPrice(orderPrice);
      } catch (e) {
        if (cancel) {
          return;
        }
        dispatch(warningNotification(`Error while pricing order: ${e}`));
      }
    };
    func();

    return () => {
      cancel = true; //another effect has been fired.  Cancel any pending API calls
    };
  }, [cartItems, customerDetails, dispatch, preorderMetadata]);

  const onSaveAndRelease = useCallback(async () => {
    if (saving) {
      return;
    }

    if (
      !preorderMetadata ||
      !customerDetails ||
      !preorderMetadata.orderTypeId ||
      !preorderMetadata.orderSourceId ||
      !preorderMetadata.responsibleForSale
    ) {
      return;
    }

    if (cartItems.length === 0) {
      dispatch(warningNotification('Add items to your order before submitting'));
      return;
    }

    setSaving(true);

    try {
      let req: CreatePreorderRequest = {
        orderSourceId: preorderMetadata.orderSourceId,
        orderTypeId: preorderMetadata.orderTypeId,
        customerId: customerDetails.Guest_id,
        items: cartItems,
        createNewAddress: false,
        dueDate: preorderMetadata.dueDate?.toLocaleString(),
        responsibleForSaleUserId: preorderMetadata.responsibleForSale.UserId,
      };

      if (preorderMetadata.orderTypeId === 2) {
        //delivery
        req = {
          ...req,
          deliveryAddressId: preorderMetadata.deliveryAddressId,
          createNewAddress: preorderMetadata.createNewAddress,
          street: preorderMetadata?.newAddress.street,
          street2: preorderMetadata?.newAddress.street2,
          city: preorderMetadata?.newAddress.city,
          state: preorderMetadata?.newAddress.state,
          zip: preorderMetadata?.newAddress.postal_code,
        };
      }

      const { ShipmentId } = await createPreorder(req);

      logger.info(`preorder created (saved and released) for ${customerDetails.Guest_id}`, {
        key: customEventKeys.cart.preorderCreated,
        shipmentId: ShipmentId,
        guestId: customerDetails.Guest_id,
        cartItems,
        orderType: preorderMetadata.orderTypeId,
        orderSource: preorderMetadata.orderSourceId,
      });
      dispatch(successNotification(`Preorder successfully created`));

      if (isAutoPrintEnabled) {
        printPickTicket({
          guest: { ...customerDetails, ShipmentId: ShipmentId },
        });
      }

      history.push('/guestlist');
    } catch (e) {
      dispatch(errorNotification(`Error creating preorder: ${e}`));
    }

    setSaving(false);
  }, [cartItems, customerDetails, dispatch, printPickTicket, history, preorderMetadata, saving, isAutoPrintEnabled]);

  const handleCloseDrawer = useCallback(() => setAddItemPanelIsOpen(false), []);

  if (!preorderMetadata) {
    // Page was reloaded, redirect to guestlist
    history.replace('/guestlist');
  }

  return (
    <PreOrderPageContainer>
      {!customerDetails && (
        <Centered>
          <Loader size='2x' variant='custom' color={colors.dutchie.almostBlack} />
        </Centered>
      )}
      {customerDetails && preorderMetadata && (
        <>
          <CartHeader showInitialPopup={false} />
          <BaseContainer>
            <LeftContainer>
              <PreCartSummary
                cartItems={cartItems}
                setAddItemPanelIsOpen={setAddItemPanelIsOpen}
                setCartItems={setCartItems}
                itemPrices={itemPrices}
              />
            </LeftContainer>
            <TotalsPanel
              orderMetadata={preorderMetadata}
              cartItems={cartItems}
              orderPrice={calcOrderPrice}
              isSaving={saving}
              onSaveAndRelease={onSaveAndRelease}
            />
          </BaseContainer>
          <BottomDrawer
            fullHeight
            open={addItemPanelIsOpen}
            onClose={handleCloseDrawer}
            portalId={FullScreenPortalId}
            marginX={0}
            marginY={40}
          >
            {addItemPanelIsOpen && (
              <AddItemPanel
                cartItems={cartItems}
                setAddItemPanelIsOpen={setAddItemPanelIsOpen}
                setCartItems={setCartItems}
              />
            )}
          </BottomDrawer>
          <CustomerHistoryPanel />
        </>
      )}
    </PreOrderPageContainer>
  );
};

export const CreatePreOrderPage = () => (
  <ProvideCartPopups>
    <CreatePreOrderPageWithoutPopups />
    <CartPopups />
  </ProvideCartPopups>
);

const BaseContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${colors.dutchie.lightGrey};
  padding: 2rem 3.5rem;
  gap: 1rem;
  ${eloOriPadBreakpoint} {
    padding: 2rem 3.5rem;
  }
`;

const Centered = styled(Center)`
  width: 100%;
`;

const PreOrderPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  height: 100%;

  ${LeftContainer} {
    padding: 1.5rem;
  }
`;
