import { SectionWithHeader, Flex } from 'components/layout';
import { AvailableFeeDonation } from 'models/FeeDonation';
import React, { FC } from 'react';
import styled from 'styled-components';
import { callback, callback1 } from 'models/Misc';
import { colors } from 'css/Theme';
import { P1 } from 'components/text';

type FeeDonationProps = {
  selected: boolean;
  onClick: callback;
};

const FeeDonation: FC<FeeDonationProps> = ({ selected, onClick, children }) => {
  return selected ? (
    <SelectedFeeDonation>{children}</SelectedFeeDonation>
  ) : (
    <UnselectedFeeDonation onClick={onClick}>{children}</UnselectedFeeDonation>
  );
};

type AvailableFeesDonationsProps = {
  selected?: AvailableFeeDonation;
  onSelect: callback1<AvailableFeeDonation>;
  availableFeesDonations: Array<AvailableFeeDonation>;
};

export const AvailableFeesDonations: FC<AvailableFeesDonationsProps> = ({
  selected,
  onSelect,
  availableFeesDonations,
}) => {
  return (
    <StyledSectionWithHeader title='Available Fees & Donations' empty={availableFeesDonations.length === 0}>
      {availableFeesDonations.map((item) => (
        <FeeDonation
          selected={selected?.FeeDonationId === item.FeeDonationId}
          onClick={() => onSelect(item)}
          key={item.FeeDonationId}
        >
          <Flex justifyContent='space-between'>
            <P1 data-testid='fee-or-donation-name'>{item.Name}</P1>
            <P1>{item.FeeDonationId}</P1>
          </Flex>
          <P1>{item.CalculationMethodId !== 2 ? `$${item.CalculationValue}` : `${item.CalculationValue}%`}</P1>
        </FeeDonation>
      ))}
    </StyledSectionWithHeader>
  );
};

const StyledSectionWithHeader = styled(SectionWithHeader)`
  width: 100%;
  height: 100%;
`;

const UnselectedFeeDonation = styled.div`
  cursor: pointer;
  padding: 14px 16px;
  color: ${colors.dutchie.almostBlack};
  border-radius: 6px;
  border: 1px solid ${colors.dutchie.borderGrey};
  margin-bottom: 12px;

  &:hover {
    background-color: ${colors.dutchie.shadowGrey};
  }
`;

const SelectedFeeDonation = styled(UnselectedFeeDonation)`
  pointer-events: none;
  border-color: ${colors.dutchie.blue};
`;
