import React from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { Container, Header, Title } from 'components/cart/TotalsSidebarSection.styles';
import { Chevron as _Chevron, ChevronDirections } from 'assets/icons/chevron';
import { usePreorderSummary } from './usePreorderSummary';
import { getAddressString } from 'models/Customer';

import type { PreorderMetadataValues } from 'models/Customer';

export type PreorderSummaryProps = {
  orderMetadata: PreorderMetadataValues;
};

export const PreorderSummary = ({ orderMetadata }: PreorderSummaryProps) => {
  const { isSummaryOpen, toggleSummary, showAsCollapsed, deliveryAddress } = usePreorderSummary({ orderMetadata });
  return (
    <Container showBottomBorder>
      <Header style={{ cursor: showAsCollapsed ? 'pointer' : 'default' }} onClick={toggleSummary}>
        <Title>Summary</Title>
        {showAsCollapsed && <Chevron open={isSummaryOpen} direction={ChevronDirections.DOWN} />}
      </Header>
      {(isSummaryOpen || !showAsCollapsed) && (
        <Content>
          <SummaryRow>
            <div>Order source</div>
            <div>{orderMetadata.orderSourceName}</div>
          </SummaryRow>
          <SummaryRow>
            <div>Order type</div>
            <div>{orderMetadata.orderTypeName}</div>
          </SummaryRow>
          <SummaryRow>
            <div>Sold by</div>
            <div>{orderMetadata.responsibleForSale.FullName}</div>
          </SummaryRow>
          {orderMetadata.orderTypeId === 2 && deliveryAddress && (
            <SummaryRow>
              <div>Address</div>
              <div>{getAddressString(deliveryAddress)}</div>
            </SummaryRow>
          )}
        </Content>
      )}
    </Container>
  );
};

const Chevron = styled(_Chevron)<{ open?: boolean }>`
  ${({ open }) => open && `transform: rotate(180deg);`}

  transition: transform 0.3s;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryRow = styled.div`
  color: ${colors.dutchie.grey30};
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
`;
