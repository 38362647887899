import React from 'react';
import { StyledTable } from 'components/tables/Table';
import { StyleVariant } from 'components/tables';
import { Skeleton } from 'components/misc';
import styled from 'styled-components';

export const ProductSearchFilterSkeleton = () => {
  return (
    <>
      <DropdownSkeleton>
        <Skeleton width={136} height={44} rounded />
        <Skeleton width={110} height={44} rounded />
        <Skeleton width={110} height={44} rounded />
        <Skeleton width={118} height={44} rounded />
      </DropdownSkeleton>
      <StyledTable styleVariant={StyleVariant.whiteBackground}>
        <thead>
          <tr>
            <th>Product</th>
            <th>Category</th>
            <th>Subcategory</th>
            <th>THC</th>
            <th>CBD</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </tbody>
      </StyledTable>
    </>
  );
};

const SkeletonRow = React.memo(() => {
  const randomIntFromInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);
  return (
    <tr>
      <td>
        <Skeleton width={randomIntFromInterval(89, 161)} height={16} rounded />
      </td>
      <td>
        <Skeleton width={89} height={16} rounded />
      </td>
      <td>
        <Skeleton width={117} height={16} rounded />
      </td>
      <td>
        <Skeleton width={35} height={16} rounded />
      </td>
      <td>
        <Skeleton width={35} height={16} rounded />
      </td>
      <td>
        <Skeleton width={29} height={16} rounded />
      </td>
      <td>
        <Skeleton width={45} height={16} rounded />
      </td>
    </tr>
  );
});

const DropdownSkeleton = styled.div`
  display: flex;
  gap: 8px;
  padding-left: 0.7rem;
  margin-bottom: 1rem;
`;
