import React, { FC } from 'react';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';

const Message = styled.p`
  text-align: center;
`;

type OverLimitPopupProps = {
  onConfirm: callback;
  hide: () => void;
  message: string;
};

export const OverLimitPopup: FC<OverLimitPopupProps> = ({ onConfirm, hide, message }) => {
  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title={`You are over your limit!`}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
      confirm={{
        text: 'Yes',
        onClick: () => {
          onConfirm();
          hide();
        },
      }}
    >
      <Message>{message}</Message>
    </ConfirmationPopup>
  );
};
