import { LaunchDarklyFlags } from 'store/reducers/LaunchDarklyReducer';

export function evaluateBooleanLDFlagFromReduxState(flagStore: LaunchDarklyFlags, flagName: string, defaultValue: boolean) {
  const flagValue = flagStore[flagName];
  if (flagValue === undefined) {
    return defaultValue;
  }

  return Boolean(flagValue);
}
