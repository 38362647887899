import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';

import { PassIcon } from './PassIcon';
import { WarnIcon } from './WarnIcon';
import { FailIcon } from './FailIcon';

export enum CheckListItemStatus {
  PASS = 'pass',
  WARN = 'warn',
  FAIL = 'fail',
}

type CheckListItemProps = {
  title: string;
  subtitle: string;
  passText?: string;
  warnText?: string;
  failText?: string;
  status: CheckListItemStatus;
};

export function CheckListItem(props: CheckListItemProps) {
  const { title, subtitle, passText = 'Pass', warnText = 'Verify', failText = 'Fail', status } = props;
  return (
    <Card status={status}>
      <div>
        <Title>{title}:</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
      <Validity>
        {status === CheckListItemStatus.PASS && (
          <>
            <PassIcon />
            <ValidityText>{passText}</ValidityText>
          </>
        )}
        {status === CheckListItemStatus.WARN && (
          <>
            <WarnIcon />
            <ValidityText>{warnText}</ValidityText>
          </>
        )}
        {status === CheckListItemStatus.FAIL && (
          <>
            <FailIcon />
            <ValidityText>{failText}</ValidityText>
          </>
        )}
      </Validity>
    </Card>
  );
}

const Title = styled.span`
  display: block;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const Subtitle = styled.span`
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
`;

const Validity = styled.div`
  display: flex;
  align-items: center;
`;

const ValidityText = styled.span`
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 0.75rem;
`;

const passStyles = css`
  background: ${colors.dutchie.green10};
  border: 1px solid ${colors.dutchie.green20};

  ${Title} {
    color: ${colors.dutchie.black};
  }

  ${Subtitle} {
    color: ${colors.dutchie.black};
  }

  ${ValidityText} {
    color: ${colors.dutchie.green70};
  }
`;

const warnStyles = css`
  background: ${colors.dutchie.paleYellow};
  border: 1px solid #ffe83e;

  ${Title} {
    color: ${colors.dutchie.black};
  }

  ${Subtitle} {
    color: ${colors.dutchie.black};
  }

  ${ValidityText} {
    color: ${colors.dutchie.crispyUmber};
  }
`;

const failStyles = css`
  background: ${colors.dutchie.red10};
  border: 1px solid ${colors.dutchie.red20};

  ${Title} {
    color: ${colors.dutchie.red60};
  }

  ${Subtitle} {
    color: ${colors.dutchie.red60};
  }

  ${ValidityText} {
    color: ${colors.dutchie.red60};
  }
`;

const Card = styled.div<{ status: CheckListItemStatus }>`
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ status }) => status === CheckListItemStatus.PASS && passStyles}
  ${({ status }) => status === CheckListItemStatus.WARN && warnStyles}
  ${({ status }) => status === CheckListItemStatus.FAIL && failStyles}
`;
