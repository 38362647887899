import React, { FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Capacitor } from '@capacitor/core';
import { State } from 'store';
import { SideTabContainer, StickyHeader } from 'components/layout';
import { loadLabels, loadLabelPrinters, loadReceiptPrinters } from 'store/actions/SettingsActions';
import { LabelPrinterPicker } from './Printers/LabelPrinterPicker';
import { ReceiptPrinterPicker } from './Printers/ReceiptPrinterPicker';
import { FulfillmentPrinterPicker } from './Printers/FulfillmentPrinterPicker';
import { LabelPrinterPicker as LabelPrinterPickerDeprecated } from './Printers-deprecated/LabelPrinterPicker';
import { ReceiptPrinterPicker as ReceiptPrinterPickerDeprecated } from './Printers-deprecated/ReceiptPrinterPicker';
import { FulfillmentPrinterPicker as FulfillmentPrinterPickerDeprecated } from './Printers-deprecated/FulfillmentPrinterPicker';
import { ConfigureGuestSearch } from './ConfigureGuestSearch';
import { AdvancedSettings } from './AdvancedSettings';
import { AssignRegisters } from './AssignRegisters';
import { MassDPHSettings } from './MassDPHSettings-deprecated';
import { BioTrackSettings } from './BioTrackSettings';
import { KeyboardPicker } from './KeyboardPicker';
import { BluetoothScannerPicker } from './BluetoothScannerPicker';
import { CustomerDisplaySettings } from './CustomerDisplaySettings';
import { useNewSettingsUi } from 'util/hooks/launch-darkly/useNewSettingsUi';
import { NewSettingsPage } from './index-new';
import { useHardwareLibrary } from 'util/hooks/launch-darkly/useHardwareLibrary';

export const SettingsPage: FC = () => {
  const { selectedLocation } = useSelector((state: State) => state.user);
  const { integrations, features, permissions, locationSettings } = useSelector((state: State) => state.settings);
  const isAndroid = Capacitor.getPlatform() === 'android';
  const showHardware = features.AssignRegistersToHardware && permissions.POSManager;
  const showMassDPH = integrations?.CanUseMassDPHIntegration;
  const showBioTrackCredentialsMgmt = integrations?.UseBioTrack && locationSettings?.State.toLowerCase() === 'fl';
  const dispatch = useDispatch();
  const headerRef = useRef<HTMLHeadingElement>(null);

  const isHardwareLibraryActive = useHardwareLibrary();
  const isNewSettingsUi = useNewSettingsUi();

  useEffect(() => {
    dispatch(loadLabels());
    dispatch(loadLabelPrinters());
    dispatch(loadReceiptPrinters());
  }, [selectedLocation, dispatch]);

  if (isNewSettingsUi) {
    return <NewSettingsPage />;
  }

  const sideTabPages = [
    {
      label: 'Label Printer',
      component: isHardwareLibraryActive ? <LabelPrinterPicker /> : <LabelPrinterPickerDeprecated />,
    },
    {
      label: 'Receipt Printer',
      component: isHardwareLibraryActive ? <ReceiptPrinterPicker /> : <ReceiptPrinterPickerDeprecated />,
    },
    {
      label: 'Fulfillment Ticket Printer',
      component: isHardwareLibraryActive ? <FulfillmentPrinterPicker /> : <FulfillmentPrinterPickerDeprecated />,
    },
    {
      label: 'Scanner',
      hidden: !isAndroid,
      component: <BluetoothScannerPicker />,
    },
    {
      label: 'Keyboard',
      hidden: !isAndroid,
      component: <KeyboardPicker />,
    },
    {
      label: 'CFD',
      hidden: !isAndroid,
      component: <CustomerDisplaySettings />,
    },
    {
      label: 'Search',
      component: <ConfigureGuestSearch />,
    },
    {
      label: 'Hardware',
      hidden: !showHardware,
      component: <AssignRegisters />,
    },
    {
      label: 'Mass DPH',
      hidden: !showMassDPH,
      component: <MassDPHSettings />,
    },
    {
      label: 'BioTrack Credentials',
      hidden: !showBioTrackCredentialsMgmt,
      component: <BioTrackSettings />,
    },
    {
      label: 'Advanced',
      component: <AdvancedSettings />,
    },
  ];

  return (
    <SettingsPageBase>
      <StickyHeader headerRef={headerRef}>
        <HeaderContent>Settings</HeaderContent>
      </StickyHeader>
      <SideTabContainer pages={sideTabPages} headerRef={headerRef} />
    </SettingsPageBase>
  );
};

const SettingsPageBase = styled.div`
  height: 100%;
`;

const HeaderContent = styled.div`
  font-weight: 700;
  font-size: 24px;
`;
