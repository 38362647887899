import React from 'react';

export const ManualIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_352_23469)">
            <rect x="0.5" y="7" width="24" height="14" rx="2" fill="#DFE6EB"/>
            <path d="M9.5 8H2.5C1.94772 8 1.5 8.44772 1.5 9L1.5 19C1.5 19.5523 1.94772 20 2.5 20H22.5C23.0523 20 23.5 19.5523 23.5 19V15" stroke="#485055" stroke-width="1.5" stroke-linecap="round"/>
            <rect x="13.25" y="3.75" width="10.5" height="7.5" rx="1.25" fill="#DFE6EB" stroke="#485055" stroke-width="1.5"/>
            <path d="M6.04185 17.9955C5.72143 17.9931 5.46296 17.7326 5.46296 17.4122V17.1289C4.70138 16.9865 4.06243 16.5475 3.73182 15.942C3.51496 15.5449 3.81732 15.1095 4.25245 14.9854C4.52813 14.9068 4.80399 15.1028 4.94762 15.3509C5.17777 15.7484 5.60184 15.9911 6.06482 15.9911C6.64815 15.9911 7.02778 15.7244 7.02778 15.3333C7.02778 14.16 3.7037 14.8533 3.7037 12.5689C3.7037 11.6889 4.4537 11.0311 5.46296 10.8622V10.5833C5.46296 10.2612 5.72413 10 6.0463 10C6.36846 10 6.62963 10.2612 6.62963 10.5833V10.88C7.24164 10.9952 7.75645 11.2746 8.09343 11.6699C8.40604 12.0367 8.14154 12.5417 7.69438 12.7213C7.41709 12.8327 7.11252 12.6562 6.92385 12.4245C6.71089 12.1629 6.38798 12.0089 5.99074 12.0089C5.48148 12.0089 5.16667 12.2133 5.16667 12.56C5.16667 13.5467 8.5 12.7556 8.5 15.2978C8.5 16.2578 7.76852 16.9511 6.62963 17.1378V17.4122C6.62963 17.7361 6.36575 17.998 6.04185 17.9955Z" fill="#485055"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 16.75C14.25 17.1642 13.9142 17.5 13.5 17.5H11.25C10.8358 17.5 10.5 17.1642 10.5 16.75C10.5 16.3358 10.8358 16 11.25 16H13.5C13.9142 16 14.25 16.3358 14.25 16.75Z" fill="#485055"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.75 7.75C17.75 8.16421 17.4142 8.5 17 8.5H16.25C15.8358 8.5 15.5 8.16421 15.5 7.75C15.5 7.33579 15.8358 7 16.25 7H17C17.4142 7 17.75 7.33579 17.75 7.75Z" fill="#485055"/>
            </g>
            <defs>
            <clipPath id="clip0_352_23469">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
        </svg>
    );
};
