import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { transacationAdjustmentReasonKeys } from './query-key-factory';

import type { TransactionAdjustmentReason } from './types';

const GET_TXN_ADJUSTMENT_REASONS = 'posv3/maintenance/get-txn-adjustment-reasons';

export const useTransactionAdjustmentReasonsQuery = (options?: UseQueryOptions<TransactionAdjustmentReason[]>) => {
  return useQuery({
    queryKey: transacationAdjustmentReasonKeys.all,
    queryFn: async () => {
      return await post<TransactionAdjustmentReason[]>(GET_TXN_ADJUSTMENT_REASONS);
    },
    ...options,
  });
};
