import { post } from 'api/HttpHelpers';
import {
  Cart,
  CartTotal,
  LoadCartRequest,
  ClearCartRequest,
  ApplyLoyaltyPointsRequest,
  EditCartItemRequest,
  ExpiredItemInfo,
  ReturnItemRequest,
  ScanItem,
  ScanResponse,
  BackdateTransaction,
  CalcCartRequest,
  SaveVisitReasonRequest,
  CustomerVisitReason,
  CompletePreOrder,
  RemoveRedemptionFromPreOrder,
  MarkRedemptionRedeemed,
  RemovePreorderCartItemRequest,
  ReturnItemPollingRequest,
  ReturnItemPollingResponse,
  ReturnPaymentOnlyRequest,
  ShipmentIdRequest,
} from 'models/Cart';
import { ExistingPaymentMethod } from 'models/Checkout';

import {
  GetProductForms,
  ReRouteRequest,
  GetMMURCustomerOrder,
  MMURFormsOrders,
  GetDevicesForProduct,
  DeviceResponse,
  UpdateDispensationDevice,
} from 'models/MMUR';

type BasicFormBody = {
  Guest_id?: number;
  Wgt: string;
  ProductId: number;
  SerialNumber: string;
};

type WeightPriceFormBody = BasicFormBody;

export type WeightPriceResponse = [
  {
    ResultTxt: number;
    WeightBreak: number;
    MaxWeight: number | undefined;
  }
];

export const getWeightPriceCalc = (body?: WeightPriceFormBody) => {
  return post<WeightPriceResponse>('v2/cart/Get_Weight_Price', body);
};

type PurchaseAmountFormBody = {
  Guest_id: number | undefined;
  ProductId: number;
  DollarAmount: string;
  SerialNo: string;
  PricingId: number;
};

export type PurchaseAmountResponse = [
  {
    PurchaseGrams: number;
  }
];

export const getPriceToWeightCalc = (body?: PurchaseAmountFormBody) => {
  return post<PurchaseAmountResponse>('v2/cart/Get_Purchase_Amount', body);
};
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const getCartV2 = (body?: LoadCartRequest) => post<Cart>('v2/cart/load_v2', body);
export const getPayments = (body?: ShipmentIdRequest) =>
  post<ExistingPaymentMethod[]>('v2/cart/get-existing-payments', body);
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const calcCartTotal = (body?: CalcCartRequest) => post<CartTotal>('v2/cart/calculate-cart-total', body);
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const cleanCart = (body?: ClearCartRequest) => post('v2/cart/clear', body);
export const getExpiredItemsInCart = (serialNumbers: Array<string>) =>
  post<ExpiredItemInfo[]>('v2/inventory/get-expired-inventory-items', { serialNumbers });
export const applyPointsOnCart = (body?: ApplyLoyaltyPointsRequest) =>
  post('v2/cart/Update_Loyalty_To_Transaction', body);
export const removePreOrderItemFromCart = (body?: RemovePreorderCartItemRequest) =>
  post('v2/cart/RemovePreOrderItem', body);
export const getCartSummary = (ShipmentId: number) => post<Cart>('v2/cart/cart-summary', { ShipmentId });
export const getActiveCart = (Guest_id: number, ShipmentId: number) =>
  post<Cart>('v2/cart/active_cart', { Guest_id, ShipmentId });
export const editItemInCart = (body?: EditCartItemRequest) => post('v2/cart/edit_item_in_cart', body);
export const returnItem = (body?: ReturnItemRequest) => post('v2/cart/return_item', body);
export const returnItemV2 = (body?: ReturnItemRequest) => post('v2/cart/return_item_v2', body);
export const processPaymentOnlyReturn = (body?: ReturnPaymentOnlyRequest) => post('v2/cart/return_payment_only', body);
export const scanItem = (body?: ScanItem) => post<Array<ScanResponse>>('v2/product/scan_product', body);
export const backdateTransaction = (body?: BackdateTransaction) => post('v2/cart/UpdateTransactionDate', body);
export const saveVisitReason = (body?: SaveVisitReasonRequest) => post('v2/cart/save-customer-visit-reason', body);
export const getVisitReason = (ShipmentId: number) =>
  post<Array<CustomerVisitReason>>('v2/cart/get-visit-reason', { ShipmentId });
export const getMMURProductForms = (body?: GetProductForms) =>
    post<MMURFormsOrders>('mmur/get-product-forms-orders', body);
export const getBioTrackProductForms = (body?: GetProductForms) =>
    post<MMURFormsOrders>('biotrack/get-product-forms-orders', body);
export const reRouteProduct = (body?: ReRouteRequest) => post('mmur/re-route-product', body);
export const refreshMMURAllotment = (body?: GetMMURCustomerOrder) => post('mmur/get-customer-order', body);
export const completePreOrder = (body?: CompletePreOrder) => post('v2/cart/PreOrderCompleteOrder', body);
export const removeRedemption = (body?: RemoveRedemptionFromPreOrder) => post('v2/cart/RemovePreOrderRedemption', body);
export const markRedemptionRedeemed = (body?: MarkRedemptionRedeemed) => post('v2/cart/MarkRedemptionRedeemed', body);
export const getDevicesForProduct = (body?: GetDevicesForProduct) =>
  post<DeviceResponse>('mmur/get-devices-for-product', body);
export const updateDevice = (body?: UpdateDispensationDevice) => post('mmur/save-dispensation-device', body);
export const pollingFallbackApiReturnItem = (body: ReturnItemPollingRequest) =>
  post<ReturnItemPollingResponse>('v2/cart/return_item_pusher_polling_fallback', body);

