import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { secondaryIdentificationKeys } from './query-key-factory';
import { transformToSecondaryIdentification } from './types';

import type { SecondaryIdentificationServerResponse } from './types';

const GET_SECONDARY_IDENTIFICATION = 'v2/guest/secondary-identification';

// Payload that gets passed to the query function
type SecondaryIdentificationPayload = {
  guestId: number;
};

const transformToServerPayload = (payload: SecondaryIdentificationPayload) => {
  return {
    guest_id: payload.guestId,
  };
};

export const useSecondaryIdentificationQuery = (payload: SecondaryIdentificationPayload) => {
  return useQuery({
    queryKey: secondaryIdentificationKeys.many(payload.guestId),
    queryFn: async () => {
      const response = await post<SecondaryIdentificationServerResponse[]>(
        GET_SECONDARY_IDENTIFICATION,
        transformToServerPayload(payload)
      );

      return response.map(transformToSecondaryIdentification);
    },
  });
};
