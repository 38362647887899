import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useEnable100PercentLogRocketSampling = () => {
  const ldClient = useLDClient();

  const is100PercentLogRocketSamplingEnabled: boolean = ldClient?.variation(
    'pos.register.enable-100-percent-log-rocket-sampling',
    false
  );

  return is100PercentLogRocketSamplingEnabled;
};
