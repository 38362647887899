import { colors } from 'css/Theme';
import React from 'react';
import styled from 'styled-components';

import { DutchiePayLogo } from 'assets/DutchiePayLogo';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ButtonWithIcon } from 'components/buttons';
import { CashAmount } from 'components/text';
import { Row, TotalRow } from './OrderTotals.styles';

import { useOrderTotals } from './useOrderTotals';

type OrderTotalProps = {
  grandTotalRounded?: number;
  hideGetTotalBtn?: boolean;
  showDutchiePayPreAuthCheckout?: boolean;
};

export const OrderTotal = ({
  grandTotalRounded,
  hideGetTotalBtn = false,
  showDutchiePayPreAuthCheckout = false,
}: OrderTotalProps): JSX.Element => {
  const { areCartTotalsLoading, useStatefulCheckout, hideTotals, getTotal } = useOrderTotals({
    cart: { GrandTotalRounded: grandTotalRounded },
    hideGetTotalBtn,
  });

  return (
    <>
      <TotalRow onClick={getTotal}>
        <div>Order Total</div>
        {useStatefulCheckout && (
          <GetTotalButton
            automationId='summary_btn_get-total'
            icon={RefreshIcon}
            label={hideTotals ? 'Get total' : undefined}
            hideIcon={hideTotals}
            disabled={areCartTotalsLoading}
          />
        )}
        {!hideTotals && <CashAmount automationId='summary_div_cart-total' value={grandTotalRounded} />}
      </TotalRow>
      {showDutchiePayPreAuthCheckout && (
        <Row data-testid={'dutchie-pay-pre-auth-payment-line-item'}>
          <PreAuthLineItemText>
            <DutchiePayLogo height={14} width={77} /> (Pre-auth)
          </PreAuthLineItemText>

          <CashAmount automationId='dutchie-pay-preauth-total' value={grandTotalRounded} />
        </Row>
      )}
    </>
  );
};

const GetTotalButton = styled(ButtonWithIcon)<{ hideIcon?: boolean }>`
  margin-left: auto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 170%;
  letter-spacing: 0.005em;
  background: none;
  color: ${colors.dutchie.blue};
  padding: 0;
  border: none;
  height: 27px;

  &:hover {
    background: none;
    color: ${colors.dutchie.blue};
  }

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
    path {
      stroke: ${colors.dutchie.blue};
    }
  }

  ${({ hideIcon }) =>
    hideIcon &&
    `
        & > svg {
            display: none;
        }
    `}
`;

const PreAuthLineItemText = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
`;
