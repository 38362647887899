import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from 'store';
import { AppComponent } from 'AppComponent';
import { Loader, LoadingContainer } from 'components/backoffice/loader';
import { DutchieThemeProvider } from 'components/backoffice/theme';
import { Universals } from 'components/backoffice/global-styles/universals';
import { Tokens } from 'components/backoffice/global-styles/tokens';
import { DutchieGlobalStyles } from 'css/DutchieGlobalStyles';

const persistor = persistStore(store);

const Loading = () => {
  return (
    <LoadingContainer>
      <Loader variant='black' size='2x' />
    </LoadingContainer>
  );
};

const AppWithStore = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <DutchieThemeProvider>
        <Universals />
        <Tokens />
        <DutchieGlobalStyles />
        <AppComponent />
      </DutchieThemeProvider>
    </PersistGate>
  </Provider>
);

export const App = process.env.NODE_ENV === 'development' ? hot(AppWithStore) : AppWithStore;
