import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { Peripheral } from '@dutchie/capacitor-peripheral';
import { SelectContainer, RefreshButton, ActionBar } from '../../SettingPageComponents';
import { Loader } from 'components/backoffice/loader';
import { Label } from 'components/text';
import { Select } from 'components/inputs';
import { Button } from 'components/buttons';
import { getLocalPrinterSettings, testLocalReceiptPrinter } from '../utils';
import { LocalReceiptPrinterPickerProps } from '../types';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { logger, customEventKeys } from 'util/logger';
import {
  SearchReceiptPrintersContext,
  UserSelectedPrinterContext,
} from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { getSearchReceiptPrintersDescription } from 'util/logger/helpers/printing';

export const LocalFulfillmentPicker: FC<LocalReceiptPrinterPickerProps> = ({
  connectedReceiptPrinters,
  setConnectedReceiptPrinters,
}) => {
  const dispatch = useDispatch();
  const { selectedFulfillmentPrinter } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintFulfillmentTicket } = useSelector((state: State) => state.settings.userSettings);
  const [loadingFulfillmentPrinters, setLoadingFulfillmentPrinters] = useState<boolean>(false);

  const options = connectedReceiptPrinters.map((item) => ({ value: item.id, label: item.name, key: item.id }));

  const searchFulfillmentPrinters = useCallback(
    async ({ userInitiated = false }: { userInitiated?: boolean }) => {
      try {
        setLoadingFulfillmentPrinters(true);
        const { results } = await Peripheral.searchReceiptPrinters();
        setConnectedReceiptPrinters(results);

        logger.info<SearchReceiptPrintersContext>(getSearchReceiptPrintersDescription(results, userInitiated), {
          key: customEventKeys.printing.local.receipt.search,
          availablePrinters: results,
          userInitiated,
        });
      } catch (e) {
        logger.error(e, { message: 'searchReceiptPrinters failed', userInitiated });
      } finally {
        setLoadingFulfillmentPrinters(false);
      }
    },
    [setConnectedReceiptPrinters]
  );

  useEffect(() => {
    searchFulfillmentPrinters({ userInitiated: false });
  }, [searchFulfillmentPrinters]);

  const testPrint = () => {
    const printerId = selectedFulfillmentPrinter?.PrinterId.toString() ?? '';
    const testString = 'TEST LOCAL FULFILLMENT PRINT';
    testLocalReceiptPrinter(printerId, testString);
  };

  const changeFulfillmentPrinter = (value: string) => {
    const newPrinterSettings = getLocalPrinterSettings(value, connectedReceiptPrinters);
    dispatch(saveUserSettings({ selectedFulfillmentPrinter: newPrinterSettings }));
    // TODO: Fire connectReceiptPrinter here

    logger.info<UserSelectedPrinterContext>(`user selected local pick ticket printer ${newPrinterSettings?.PrinterId}`, {
      key: customEventKeys.printing.userSelectedPrinter,
      job: PrintJob.PICK_TICKET,
      type: 'local',
      selectedPrinterId: newPrinterSettings?.PrinterId ?? '',
    });
  };

  const fulfillmentPicker = loadingFulfillmentPrinters ? (
    <Loader variant='black' />
  ) : (
    <Select
      placeholder='Select Printer'
      defaultValue={selectedFulfillmentPrinter?.PrinterId}
      onChange={changeFulfillmentPrinter}
      options={options}
      errorMessage={!selectedFulfillmentPrinter && autoPrintFulfillmentTicket && 'Please select a fulfillment printer'}
    />
  );

  return (
    <SelectContainer>
      <ActionBar>
        <Label>Local Fulfillment Ticket Printer</Label>
        <RefreshButton onClick={() => searchFulfillmentPrinters({ userInitiated: true })} />
        <Button onClick={testPrint}>Test Print</Button>
      </ActionBar>
      {fulfillmentPicker}
    </SelectContainer>
  );
};
