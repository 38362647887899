import { Loader } from "components/backoffice/loader";
import { Label } from "components/text";
import React, { useState } from "react";
import { SelectContainer, ActionBar, RefreshButton } from "../SettingPageComponents";
import { useHardwareLibraryReceiptPicker, useHardwareLibraryReceiptPrinters } from "./hooks/useHardwareLibraryReceiptPrinters";
import { useReceiptPrinterPicker } from "./hooks/useReceiptPrinterPicker";
import { Select } from "components/inputs";
import { Button } from "components/buttons";
import { Column } from "components/layout";
import { useTestPrintButtons } from "util/hooks/launch-darkly/useTestPrintButtons";

export const HardwareLibraryReceiptPrinterDropdown = ({ connectionType, printerType }: { connectionType: 'network' | 'local'; printerType: 'fulfillment' | 'receipt' }) => {
  const { isLoading, refreshList } = useHardwareLibraryReceiptPrinters({ connectionType });
  const { changeSelection, dropdownOptions, selectedPrinterId, testPrint } = useHardwareLibraryReceiptPicker({ connectionType, printerType });
  const { isAutoPrintEnabled } = useReceiptPrinterPicker();
  const showTestPrintButton = useTestPrintButtons();

  const [isPrinting, setIsPrinting] = useState(false);

  const handleTestPrint = async () => {
    try {
      setIsPrinting(true);
      await testPrint();
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <SelectContainer>
      <Label>{connectionType === 'network' ? 'Network' : 'Local'} Receipt Printer</Label>
      <ActionBar>
        <Column flex={1}>
          {isLoading && <Loader variant='black' />}
          {!isLoading && (
            <Select
              placeholder='Select Printer'
              value={selectedPrinterId}
              onChange={changeSelection}
              options={dropdownOptions}
              errorMessage={!selectedPrinterId && isAutoPrintEnabled && `Please select a default ${printerType} printer`}
            />
          )}
        </Column>
        <RefreshButton onClick={() => refreshList({ userInitiated: true })} />
        { showTestPrintButton && <Button disabled={!selectedPrinterId || isPrinting} onClick={handleTestPrint}>Test Print</Button> }
      </ActionBar>
    </SelectContainer>
  );
};
