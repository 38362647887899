import styled, { css } from 'styled-components';
import { MenuItem as MUIMenuItem } from '@mui/material';

export const RebrandMenuItem = styled(MUIMenuItem)<{ danger?: boolean; disabled?: boolean; $variant: string }>`
  ${({ danger, disabled, $variant }) => css`
    padding: var(--sizes-30) var(--sizes-70);
    color: var(--color-greyscale-black);
    font: var(--font-regular-14pt-normal);

    .MuiCheckbox-root {
      margin-right: var(--sizes-40);
    }

    &:hover,
    &.Mui-selected:hover {
      background-color: var(--color-greyscale-grey-95);
    }

    &.Mui-selected {
      background-color: transparent;
      font-weight: 600;
    }
    ${danger && dangerStyles}
    ${disabled &&
    css`
      pointer-events: none;
    `}
    ${$variant === 'username' && usernameStyles}
    ${['checkbox', 'label'].includes($variant) && labelStyles}
  `}
`;

export const LabelVariantContainer = styled.div<{ $variant: string; $hasFooter?: boolean }>`
  --checkbox-container-total-width: var(--sizes-70);
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ $variant }) =>
    $variant === 'checkbox' &&
    css`
      width: calc(100% - var(--checkbox-container-total-width));
    `}

  .main-label {
    gap: var(--sizes-70);
    display: flex;
    justify-content: space-between;
    ${({ $variant, $hasFooter }) =>
      $variant === 'checkbox' &&
      $hasFooter &&
      css`
        font: var(--font-regular-14pt-semibold);
      `}
  }
`;

export const MenuItemSubText = styled.div`
  display: flex;
  flex-direction: column;
  font: var(--font-regular-14pt-normal);
  color: var(--color-greyscale-grey-50);
`;

const dangerStyles = css`
  color: var(--color-additional-colors-red-46);
`;

const usernameStyles = css`
  flex-direction: column;
  align-items: start;
  padding: var(--sizes-20) var(--sizes-110) var(--sizes-30) var(--sizes-70);
  font: var(--font-extra-small-12pt-normal);

  span {
    color: var(--color-greyscale-grey-65);
    font: var(--font-micro-10pt-bold);
    text-transform: uppercase;
  }
`;

const labelStyles = css`
  .menu-item--label-text {
    margin-top: 1px; // visually looks off-center without this
    color: var(--color-greyscale-grey-65);
    font: var(--font-extra-small-12pt-bold);
    text-transform: uppercase;
    align-self: center;
  }
`;
