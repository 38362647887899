import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { PaymentOption } from '../PaymentOption';
import {
  NonIntegratedPaymentsSetting,
  useNonIntegratedPaymentsQuery,
} from 'queries/v2/settings/get-manual-payment-processors';
import { Loader } from 'components/backoffice/loader';
import styled from 'styled-components';
import PaymentNonIntegratedButtons from './PaymentNonIntegrated/PaymentNonIntegratedButtons';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { PaymentMethod, PaymentType } from 'models/Checkout';
import { v4 as uuidv4 } from 'uuid';
import { addPaymentMethod } from 'store/actions/CheckoutActions';
import { CheckoutContext } from '../CheckoutContext';
import ManualProcessorButton from './PaymentNonIntegrated/ManualProcessorButton';

export const PaymentManual: FC = () => {
  const dispatch = useDispatch();
  const { setSelectedPaymentType } = useContext(CheckoutContext);
  const totalRemaining = useSelector((state: State) => state.checkout.totalRemaning);
  const [selectedManualPayment, setSelectedManualPayment] = useState<NonIntegratedPaymentsSetting>();
  const paymentMethodName = 'manual';
  const automatedTestId = 'manual-payments-panel';

  // Add manual payment method
  const onAddManual = (amount: number) => {
    if (amount <= 0) {
      dispatch(errorNotification('Invalid amount.'));
      return;
    }
    if (!selectedManualPayment) {
      dispatch(errorNotification('Please select a payment method.'));
      return;
    }
    const payment: PaymentMethod = {
      id: uuidv4(),
      name: paymentMethodName,
      type: PaymentType.Manual,
      amount,
      data: {
        ManualPaymentProcessorId: selectedManualPayment.ManualPaymentProcessorId,
        ManualPaymentProcessorName: selectedManualPayment.ManualPaymentProcessorName,
      },
    };
    dispatch(addPaymentMethod(payment));
    dispatch(successNotification('Manual payment added.'));
    setSelectedPaymentType(null);
  };

  // Get all manual payment options
  const { data, isLoading } = useNonIntegratedPaymentsQuery();

  // Filter out manual payment options that are not enabled
  const filteredData = useMemo(() => {
    if (data) {
      return data.filter((payment) => payment.IsEnabled === true);
    }
  }, [data]);

  // Select the only manual payment option if there is only one
  useEffect(() => {
    if (!selectedManualPayment && filteredData?.length === 1) {
      setSelectedManualPayment(filteredData[0]);
    }
  }, [filteredData, selectedManualPayment]);

  // Display loading spinner if data is still loading
  if (isLoading || !filteredData || !data) {
    return (
      <LoadingContainer>
        <Loader variant='black' size='4x' />
      </LoadingContainer>
    );
  }

  return (
    <Container data-testid={automatedTestId}>
      <PaymentOption
        onAddAmount={onAddManual}
        prefilledAmount={totalRemaining}
        disableAddButton={!selectedManualPayment}
      />

      <Heading>Non-Integrated processor</Heading>
      <PaymentNonIntegratedButtons data-testId='payment-options-manual-multiple-provider_panel'>
        {filteredData.map((payment) => {
          return (
            <ManualProcessorButton
              key={payment.ManualPaymentSettingsId}
              selected={selectedManualPayment === payment}
              payment={payment}
              setSelectedManualPayment={setSelectedManualPayment}
            />
          );
        })}
      </PaymentNonIntegratedButtons>
    </Container>
  );
};
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Heading = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 2px;
`;
const Container = styled.div``;
