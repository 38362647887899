import styled, { css } from 'styled-components';
import { InputField as _InputField, TextAreaField, SelectField } from 'components/inputs';
import { ButtonWithIcon } from 'components/buttons';

import { colors, breakpoints } from 'css/Theme';

export const SectionDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  max-width: 56rem;
  flex-wrap: wrap;
`;

const FieldStyle = css`
  flex: 1 0 50%;
  max-width: 27rem;
  margin-right: 1rem;
`;

export const StyledSelectField = styled(SelectField)`
  ${FieldStyle};
`;

export const InputField = styled(_InputField)`
  ${FieldStyle};
  input {
    height: unset;
  }
`;

export const StyledTextAreaField = styled(TextAreaField)`
  ${FieldStyle};
  margin-right: 0;
`;

export const SectionColumn = styled.div`
  flex: 0 0 50%;
  margin-right: 1rem;
  max-width: 27rem;

  ${StyledSelectField} {
    margin-right: 0;
  }

  ${InputField} {
    margin-right: 0;
  }

  ${breakpoints.wideTablet.maxQuery} {
    flex: 0 0 auto;
    max-width: none;
    width: 100%;
  }
`;

export const SectionHeaderSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;
export const SectionH2 = styled.h2`
  color: black;
  font-size: 1rem;
  font-weight: normal;
  height: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const SectionH3 = styled.h3`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 1rem;
`;

export const IconButton = styled(ButtonWithIcon)`
  margin-left: 1rem;
  font-weight: 500;

  & > svg {
    width: 0.5rem;
    height: 0.5rem;
    margin: auto;

    path {
      fill: ${colors.black};
    }
  }
`;
