import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';

import { CustomerFacingDisplay } from '../CustomerFacingDisplay';

/**
 * Data loader for web version of CFD
 * @returns CustomerFacingDisplay
 */
export const WebCustomerFacingDisplay: FC = () => {
  // Loads props from cfd Redux state which is synced
  // with main page Redux state
  const cfdDataProps = useSelector((state: State) => state.cfd);

  return <CustomerFacingDisplay {...cfdDataProps} />;
};
