import { useState } from 'react';

import { checkManagerPin } from 'util/Helpers';
import { getInitialNumberValue } from '../helpers';
import { openCashDetailsPanel, resetCashDetails } from 'store/actions/CashManagementActions';
import { registerDropCash } from 'api/SettingsApi';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { usePopup } from 'components/popups';

import type { DropCashProps } from './DropCash';

export const useDropCash = ({ onDropCash, registerId }: DropCashProps) => {
  const dispatch = useAppDispatch();

  const [cashCounted, setCashCounted] = useState(getInitialNumberValue());
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState('');

  // Global state

  const isMgrPinForDepositEnabled = useAppSelector((state) => state.settings.features.MgrPinForDeposit);

  // Hooks

  const { isVisible: isConfirmPopupVisible, toggle: toggleConfirmPopup } = usePopup();

  // Computed values

  const isDropDisabled = !cashCounted.floatValue || isLoading;

  // Handlers

  const handleClickEnterCashDetails = () => dispatch(openCashDetailsPanel());

  const handleDrop = async (managerPassword: string) => {
    if (cashCounted.floatValue) {
      try {
        setIsLoading(true);
        if (isMgrPinForDepositEnabled) {
          await checkManagerPin(managerPassword);
        }
        await registerDropCash({
          CashCounted: cashCounted.floatValue,
          Note: note,
          RegisterId: registerId,
          EntryTypeId: 5, //CashDropPOS
        });
        setCashCounted(getInitialNumberValue());
        dispatch(resetCashDetails());
        setNote('');
        onDropCash();
        dispatch(successNotification('Cash dropped'));
        setIsLoading(false);
      } catch {
        dispatch(errorNotification('There was an error dropping cash'));
        setIsLoading(false);
      }
    }
  };

  return {
    cashCounted,
    handleClickEnterCashDetails,
    handleDrop,
    isConfirmPopupVisible,
    isDropDisabled,
    isLoading,
    isMgrPinForDepositEnabled,
    note,
    setCashCounted,
    setNote,
    toggleConfirmPopup,
  };
};
