import React from 'react';

type DoubleChevronIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function DoubleChevronIcon(props: DoubleChevronIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5036 1.50481C11.2302 1.77816 11.2302 2.22138 11.5035 2.49476L21.0077 11.9998L11.5035 21.5049C11.2302 21.7782 11.2302 22.2214 11.5036 22.4948C11.777 22.7682 12.2202 22.7681 12.4935 22.4948L22.0212 12.9662C22.1482 12.8394 22.249 12.6887 22.3177 12.5229C22.3865 12.3571 22.4219 12.1793 22.4219 11.9998C22.4219 11.8203 22.3865 11.6425 22.3177 11.4767C22.249 11.3109 22.1482 11.1602 22.0212 11.0334L12.4935 1.50485C12.2202 1.23147 11.777 1.23145 11.5036 1.50481ZM21.0319 11.9756C21.0318 11.9757 21.0317 11.9758 21.0315 11.976L21.0319 11.9756Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.50359 1.50481C1.23021 1.77816 1.23019 2.22138 1.50354 2.49476L11.0077 11.9998L1.50354 21.5049C1.23019 21.7782 1.23021 22.2214 1.50359 22.4948C1.77697 22.7682 2.22018 22.7681 2.49354 22.4948L12.0212 12.9662C12.1482 12.8394 12.249 12.6887 12.3177 12.5229C12.3865 12.3571 12.4219 12.1793 12.4219 11.9998C12.4219 11.8203 12.3865 11.6425 12.3177 11.4767C12.249 11.3109 12.1482 11.1602 12.0212 11.0334L2.49354 1.50485C2.22018 1.23147 1.77697 1.23145 1.50359 1.50481ZM11.0319 11.9756C11.0318 11.9757 11.0317 11.9758 11.0315 11.976L11.0319 11.9756Z'
        fill={color}
      />
    </svg>
  );
}
