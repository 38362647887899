import { createAction } from '@reduxjs/toolkit';

import type { CountMetric, DurationMetric, TimestampMetric } from 'store/reducers/MetricsReducer';

export const addCountMetric = createAction('addCountMetric', (metric: CountMetric) => ({ payload: metric }));

export const addDurationMetric = createAction('addDurationMetric', (metric: DurationMetric) => ({ payload: metric }));

export const addTimestampMetric = createAction('addTimestampMetric', (metric: TimestampMetric) => ({
  payload: metric,
}));
