import { logger } from 'util/logger';

export const isWebViewApp = !!(window as any)['WebviewApp']; // eslint-disable-line @typescript-eslint/no-explicit-any

type WebAppRegion = {
  name: string;
  url: string;
};

type FlutterJavascriptChannel = {
  postMessage: (message: string) => void;
};

type AppResponse<Data = unknown> = {
  id: string;
  method: string;
  data: Data;
};

type AppError = {
  id?: string;
  method?: string;
  message: string;
};

export const getZebraScannerBluetoothPairingImage = async (): Promise<string | undefined> => {
  const response = await callWebviewApp<{ bytes: number[] | undefined }>('getZebraScannerBluetoothPairingImage');

  if (response.bytes && response.bytes.length) {
    const binary = response.bytes.reduce((acc, curr) => acc + String.fromCharCode(curr), '');
    const base64String = btoa(binary);
    return `data:image/png;base64,${base64String}`;
  }

  return undefined;
};

export const getWebViewAppRegion = async (): Promise<WebAppRegion | null> => {
  try {
    return callWebviewApp<WebAppRegion>('getRegion');
  } catch (error) {
    logger.error(error, { message: 'Error getting region' });
  }
  return null;
};

export const setWebViewAppRegion = async ({ name, url }: WebAppRegion) => {
  try {
    await callWebviewApp('setRegion', { name, url });
  } catch (error) {
    logger.error(error, { message: 'Error setting region' });
  }
};

export const setWebviewDatadogUser = async (user: { id: string; name: string; lsp_id?: string; loc_id?: string }) => {
  try {
    await callWebviewApp('setDatadogUser', user);
  } catch (error) {
    logger.error(error, { message: 'Error setting Datadog user' });
  }
};

const callWebviewApp = async <T>(method: string, data?: unknown): Promise<T> => {
  const id = `${method}-${Date.now()}`;

  return new Promise<T>((resolve, reject) => {
    let isCompleted = false;

    const handleError = (event: Event) => {
      const { id: responseId, message } = (event as CustomEvent<AppError>).detail;

      if (responseId === id) {
        window.removeEventListener('onWebviewAppError', handleError);
        window.removeEventListener('onWebviewAppResponse', handleResponse);

        isCompleted = true;
        reject(new Error(message));
        return;
      }
    };

    const handleResponse = (event: Event) => {
      const { id: responseId, data } = (event as CustomEvent<AppResponse<T>>).detail;

      if (responseId === id) {
        window.removeEventListener('onWebviewAppError', handleError);
        window.removeEventListener('onWebviewAppResponse', handleResponse);
        isCompleted = true;
        resolve(data);
        return;
      }
    };

    window.addEventListener('onWebviewAppError', handleError);
    window.addEventListener('onWebviewAppResponse', handleResponse);

    const webview = (window as any)['WebviewApp'] as FlutterJavascriptChannel | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    webview?.postMessage(
      JSON.stringify({
        id,
        method,
        data,
      })
    );

    setTimeout(() => {
      if (!isCompleted && window) {
        window.removeEventListener('onWebviewAppError', handleError);
        window.removeEventListener('onWebviewAppResponse', handleResponse);
        reject(new Error('Timeout'));
      }
    }, 2000);
  });
};
