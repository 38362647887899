import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { mmurCustomerCaregiversKeys } from './query-key-factory';
import { transformToCaregiver } from './types';

import type { Caregiver, MMURCaregiverServerResponse } from './types';

const GET_CUSTOMER_CAREGIVERS = 'mmur/get-customer-caregivers';

// Payload that gets passed to the query function
type MMURCaregiversPayload = {
  guestId: number;
};

const transformToServerPayload = (payload: MMURCaregiversPayload) => {
  return {
    Guest_id: payload.guestId,
  };
};

export const useMMURCaregiversQuery = (payload: MMURCaregiversPayload, options?: UseQueryOptions<Caregiver[]>) => {
  return useQuery({
    queryKey: mmurCustomerCaregiversKeys.many(payload.guestId),
    queryFn: async () => {
      const response = await post<MMURCaregiverServerResponse[]>(
        GET_CUSTOMER_CAREGIVERS,
        transformToServerPayload(payload)
      );

      return response.map(transformToCaregiver);
    },
    ...options,
  });
};
