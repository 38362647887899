import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';

import { ReactComponent as CheckMarkSvg } from 'assets/icons/checkmark-green.svg';

import { CART_LINE_ITEM_TEST_IDS } from '../CartLineItem.test-ids';

type AssignedPackagePillProps = {
  isItemFromPreorder: boolean;
  packageId: string;
};

export const AssignedPackagePill: FC<AssignedPackagePillProps> = ({ packageId }) => {
  return (
    <AssignedPackageIdPill>
      <CheckMarkIcon data-testid={CART_LINE_ITEM_TEST_IDS.packageIdCheckMarkIconTestId} />
      {packageId}
    </AssignedPackageIdPill>
  );
};

const PackageIdPill = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.5%;
  color: ${colors.dutchie.gray90};
  background: ${colors.dutchie.opal10};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.875rem;
`;

const AssignedPackageIdPill = styled(PackageIdPill)`
  color: white;
  background: ${colors.dutchie.green};
`;

const CheckMarkIcon = styled(CheckMarkSvg)`
  width: 12px;
  height: 12px;

  path {
    fill: white;
  }
`;
