import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { SectionWithHeader } from 'components/layout';
import { AvailableDiscount } from 'models/Discounts';
import { colors } from 'css/Theme';
import { Label as _Label } from 'components/text';
import { Input, Select } from 'components/inputs';
import { callback1 } from 'models/Misc';

const Section = styled(SectionWithHeader)`
  flex-grow: 1;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  flex-grow: 1;
  border-radius: inherit;
  border: 1px solid ${colors.dutchie.borderGrey};
  padding: 1.25rem 1.5rem;
`;

const Label = styled(_Label)`
  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const StyledInput = styled(Input) <{ appearDisabled?: boolean }>`
  ${({ appearDisabled }) =>
    appearDisabled && {
      opacity: '0.5',
    }}
`;

export type SelectedExternalLoyaltyDiscountProps = {
  selected?: AvailableDiscount;
  applyTo?: string;
  fee: string;
  percent: string;
  name: string;
  setName: callback1<string>;
  applyToOptions: {
    label: string;
    value: string;
  }[];
};

export const SelectedExternalLoyaltyDiscount: FC<SelectedExternalLoyaltyDiscountProps> = (props) => {
  const { applyToOptions, selected, applyTo, fee, percent, name, setName } = props;

  useEffect(() => {
    if (selected) {
      setName(selected.ProductDesc);
    }
    // Adding setName to the dependency array causes the name to be reset to the default value with every keystroke
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Section title='Selected Discount (Loyalty)'>
      <Container>
        <Label>Apply to:</Label>
        <Select
          value={applyTo}
          options={applyToOptions}
          disabled={true}
          automationId='selected-external-loyalty-discount-apply-to-select'
        />
        <Label>Discount Amount</Label>
        <StyledInput
          value={fee}
          disabled={true}
          data-testid='selected-external-loyalty-discount_styled-input_amount'
        />
        <Label>Discount Percent Off</Label>
        <StyledInput
          value={percent}
          disabled={true}
          data-testid='selected-external-loyalty-discount_styled-input_percent-off'
        />
        <Label>Name</Label>
        <StyledInput
          value={name}
          disabled={true}
          data-testid='selected-external-loyalty-discount_styled-input_name'
        />
      </Container>
    </Section>
  );
};
