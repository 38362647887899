import { logger } from 'util/logger';

/**
 * This is a polyfill for the Element API method `checkVisibility`.
 * @param element An element that extends Element or null.
 * @returns boolean - true if the element is visible, false otherwise.
 */
export const isElementVisible = (element: Element | null) => {
  if (!element) {
    return false;
  }

  if (!(element instanceof Element)) {
    throw Error('isElementVisible received a non-element argument');
  }

  if ('checkVisibility' in element) {
    try {
      return element.checkVisibility();
    } catch (e) {
      logger.error(e, { message: 'Failed to execute checkVisibility on element', element });
    }
  } else {
    logger.warn('checkVisibility is not supported by this browser', { key: 'browser-support-error' });
  }

  const DOMRect = element.getBoundingClientRect();

  return (
    DOMRect.top >= 0 &&
    DOMRect.left >= 0 &&
    DOMRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    DOMRect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
