import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { validateBioTrackUserCredentials, resetBioTrackUserCredentialsRequest, updateBioTrackPassword } from 'api/SettingsApi';
import { ReactComponent as AccountIcon } from 'assets/icons/icon-account.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/icon-password.svg';
import { LinkButton } from 'components/backoffice/button';
import { Button } from 'components/buttons';
import { Input } from 'components/inputs';
import { Column } from 'components/layout';
import { AlertBannerStyles } from 'components/misc';
import { colors } from 'css/Theme';
import { BioTrackUserCredentialStatus } from 'models/Settings';
import { SettingsAlertBanner } from 'pages/SettingsPage/Status/SettingsAlertBanner';
import { State } from 'store';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { useAppSelector, useAppDispatch } from 'util/hooks';

const errorStatuses = [
  BioTrackUserCredentialStatus.ResetPasswordRequired,
  BioTrackUserCredentialStatus.UsernameIsNotAValidEmail,
  BioTrackUserCredentialStatus.IndividualUserAccountCannotBeCreated,
  BioTrackUserCredentialStatus.LoginFailed,
];

const successStatuses = [BioTrackUserCredentialStatus.UserAccountIsValid];

export const BioTrackSettings: FC = () => {
  const useBioTrack = useAppSelector((state: State) => state.settings.integrations?.UseBioTrack);
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState<string>('');
  const [status, setStatus] = useState<BioTrackUserCredentialStatus>(BioTrackUserCredentialStatus.Loading);
  const [message, setMessage] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [info, setInfo] = useState<string>('');

  const displayMessage = <>
    {info}
    {status === BioTrackUserCredentialStatus.ResetPasswordRequired && <NormalWeight>
      &nbsp;Otherwise, you can reset your BioTrack password by clicking the link below.
      Once you have updated your password in BioTrack, you can enter your new password below,
      and then press "update credentials".
      </NormalWeight>}
  </>;

  const isErrorStatus = errorStatuses.indexOf(status) !== -1;
  const isSuccessStatus = successStatuses.indexOf(status) !== -1;

  useEffect(() => {
    switch (status) {
      case BioTrackUserCredentialStatus.UserAccountIsValid: {
        setInfo('Your BioTrack account is currently active.');
        break;
      }
      case BioTrackUserCredentialStatus.ResetPasswordRequired: {
        setInfo('We are unable to authenticate BioTrack using your credentials. If you have changed your password, enter it below.');
        break;
      }
      case BioTrackUserCredentialStatus.UsernameIsNotAValidEmail: {
        setInfo('The username provided is not a valid email address.');
        break;
      }
      case BioTrackUserCredentialStatus.IndividualUserAccountsNotRequired: {
        setInfo('Individual user account is not required.');
        break;
      }
      case BioTrackUserCredentialStatus.IndividualUserAccountCannotBeCreated: {
        setInfo(message ?? 'Error creating user account. Please contact support.');
        break;
      }
      case BioTrackUserCredentialStatus.LoginFailed: {
        setInfo(message ?? 'Login Failed. Please contact support.');
        break;
      }
      default: {
        setInfo('BioTrack not enabled.');
        break;
      }
    }
  }, [status, message]);

  useEffect(() => {
    if (useBioTrack) {
      validateBioTrackUserCredentials().then((resp) => {
        setUsername(resp?.Username || '');
        setStatus(resp?.Status);
        setMessage(resp?.Message);
      });
    }
  }, [useBioTrack]);

  const sendResetPasswordRequest = async (clearCredentials: boolean) => {
    await resetBioTrackUserCredentialsRequest({
      Username: username,
      Password: null,
      ClearCredentials: clearCredentials,
    }).then((resp) => {
      if (!resp.Result) {
        dispatch(errorNotification(resp.Message));
      } else {
        dispatch(successNotification(`Reset password request sent to ${username}`));
        if (clearCredentials) {
          validateBioTrackUserCredentials().then((resp) => {
            setStatus(resp?.Status);
            setMessage(resp?.Message);
          });
        }
      }
    });
  };

  const resetPassword = async () => {
    await updateBioTrackPassword({
      Username: username,
      Password: password,
    }).then((resp) => {
      if (!resp.Result) {
        dispatch(errorNotification(resp.Message));
      } else {
        dispatch(successNotification(`Password updated successfully`));
        validateBioTrackUserCredentials().then((resp) => {
          setStatus(resp?.Status);
          setMessage(resp?.Message);
        });
      }
    });
  };

  return (
    <Column gap={16}>
      <Header>BioTrack Credentials</Header>
      {status !== BioTrackUserCredentialStatus.Loading && (
        <>
          {info && isSuccessStatus && <SettingsAlertBanner text={displayMessage} style={AlertBannerStyles.success} />}
          {info && isErrorStatus && <SettingsAlertBanner text={displayMessage} style={AlertBannerStyles.error} />}
          {info && !isSuccessStatus && !isErrorStatus && <SettingsAlertBanner text={displayMessage} style={AlertBannerStyles.info} />}

          <ContentColumn>
            <InputContainer>
              <Input
                label='Username'
                value={username}
                disabled
                onChange={(e) => setUsername(e.target.value)}
                startAdornment={<StyledUserIcon />}
                placeholder='Username'
              />
            </InputContainer>
            {status === BioTrackUserCredentialStatus.ResetPasswordRequired && (
              <>
                <InputContainer>
                  <Input
                    label='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    startAdornment={<StyledPasswordIcon />}
                    type='password'
                    placeholder='Password'
                  />
                </InputContainer>
                <LeftAlignedDiv>
                  <Button tertiary onClick={resetPassword} disabled={!password}>
                    Update credentials
                  </Button>
                </LeftAlignedDiv>
                <LeftAlignedDiv>
                  <LinkButton label='Click here to reset your BioTrack password' onClick={() => sendResetPasswordRequest(false)} />
                </LeftAlignedDiv>
              </>
            )}
          </ContentColumn>
          {status !== BioTrackUserCredentialStatus.ResetPasswordRequired && (
            <LeftAlignedDiv>
              <StyledLinkButton label='Click here to reset your BioTrack password' onClick={() => sendResetPasswordRequest(true)} />
            </LeftAlignedDiv>
          )}
        </>
      )}
    </Column>
  );
};

const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ContentColumn = styled(Column)`
  max-width: 430px;
  gap: 16px;
`;

const InputContainer = styled.div``;

const iconStyles = {
  color: colors.dutchie.grey,
};

const StyledLinkButton = styled(LinkButton)`
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
`;

const StyledUserIcon = styled(AccountIcon)`
  ${iconStyles}
`;

const StyledPasswordIcon = styled(PasswordIcon)`
  ${iconStyles}
`;

const NormalWeight = styled.span`
  font-weight: normal;
`;

const LeftAlignedDiv = styled.div`
  text-align: left;
`;
