import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { clearCustomerDetails } from 'store/actions/CustomerActions';
import { customEventKeys } from 'util/logger';
import { resetCart, setIsCartInitialized } from 'store/actions/CartActions';
import { showVerifyBirthdatePopup } from 'store/actions/PopupsActions/GlobalPopupsActions';
import { useAnonymousCart, getIsAnonymousCartLDFlagEnabled } from './useAnonymousCart';
import { useCreateAnonymousTransaction } from './useCreateAnonymousTransaction';
import { useAppDispatch, useAppSelector, useSearchParams } from 'util/hooks';
import { useMetrics } from 'util/metrics/useMetrics';

import type { CreateTransactionProps } from './useCreateAnonymousTransaction';
import type { CustomerDetails } from 'models/Customer';

type GoToCartParams = {
  guestId?: number;
  shipmentId?: number;
};

type TransactionLocationProps = {
  accessedWithUI?: boolean;
};

/** Use this hook to track and manage the transaction state from the URL */
export const useTransactionManager = () => {
  const dispatch = useAppDispatch();
  const history = useHistory<TransactionLocationProps>();
  const metrics = useMetrics();
  const [searchParams, setSearchParams] = useSearchParams();

  // URL state
  const isOnCartPage = history?.location.pathname.includes('/cart') ?? false;
  const accessedWithUI = history?.location.state?.accessedWithUI ?? false;
  const guestId = searchParams.get('guest') ? Number(searchParams.get('guest')) : undefined;
  const shipmentId = searchParams.get('order') ? Number(searchParams.get('order')) : undefined;

  // Deprecated Redux state
  const guestIdFromRedux = useAppSelector((state) => state.customer.details?.Guest_id);
  const shipmentIdFromRedux = useAppSelector((state) => state.customer.details?.ShipmentId);

  // Global state
  const isCartInitialized = useAppSelector((state) => state.cart.isInitialized);
  const isConfirmBirthdayOnCheckinEnabled = useAppSelector((state) => state.settings.features.ConfirmBirthdayOnCheckin);

  // Hooks
  const { isAnonymousCartLDFlagEnabled, areAnonymousTransactionsEnabled } = useAnonymousCart();
  const { createTransaction, isCollectAnonymousDemographicsEnabled, isCreatingTransaction } =
    useCreateAnonymousTransaction();

  // Computed values
  const missingSearchParams = useMemo(() => !guestId || !shipmentId, [guestId, shipmentId]);
  const shouldCreateNewTransaction = useMemo(
    () => missingSearchParams && !isCartInitialized && !isCreatingTransaction,
    [missingSearchParams, isCartInitialized, isCreatingTransaction]
  );
  const shouldShowVerifyBirthdatePopup = useMemo(
    () =>
      missingSearchParams &&
      !isCartInitialized &&
      !isCollectAnonymousDemographicsEnabled &&
      isConfirmBirthdayOnCheckinEnabled,
    [isCartInitialized, isCollectAnonymousDemographicsEnabled, isConfirmBirthdayOnCheckinEnabled, missingSearchParams]
  );

  // Transaction props to use (Remove once we complete roll out of anonymous cart workflow)
  const guestIdToReturn = useMemo(
    () => (isAnonymousCartLDFlagEnabled ? guestId : guestIdFromRedux),
    [isAnonymousCartLDFlagEnabled, guestId, guestIdFromRedux]
  );

  const shipmentIdToReturn = useMemo(
    () => (isAnonymousCartLDFlagEnabled ? shipmentId : shipmentIdFromRedux),
    [isAnonymousCartLDFlagEnabled, shipmentId, shipmentIdFromRedux]
  );

  /** Helper method to open a cart with search params */
  const goToCart = useCallback(
    (cartParams?: GoToCartParams, isOkToClearCustomer: boolean = true) => {
      if (isOkToClearCustomer) {
        // Make sure customer details are cleared to avoid race condition where old customer details are used in new cart
        dispatch(clearCustomerDetails());
      }

      const guestId = cartParams?.guestId ?? undefined;
      const shipmentId = cartParams?.shipmentId ?? undefined;

      const params =
        isAnonymousCartLDFlagEnabled && guestId && shipmentId
          ? new URLSearchParams({
              guest: guestId.toString(),
              order: shipmentId.toString(),
            })
          : undefined;

      history.push({
        pathname: '/cart',
        search: params?.toString(),
        state: { accessedWithUI: true },
      });
    },
    [isAnonymousCartLDFlagEnabled, history, dispatch]
  );

  /** Checks if a transaction can be created, then creates a new anonymous transaction */
  const startTransaction = useCallback(
    async (props?: CreateTransactionProps) => {
      dispatch(setIsCartInitialized(true));
      if (shouldCreateNewTransaction) {
        const transactionDetails = await createTransaction(props);
        // Add a log from the time the transaction has started (the anon guest was actually created)
        metrics.addTimestamp(customEventKeys.metrics.timestamps.anonymousTransactionStarted, {
          logData: { anonGuestId: transactionDetails?.guestId, shipmentId: transactionDetails?.shipmentId },
        });
        return transactionDetails;
      } else if (props?.onSuccess) {
        props.onSuccess();
      }
    },
    [dispatch, createTransaction, shouldCreateNewTransaction, metrics]
  );

  const clearTransaction = useCallback(() => {
    if (guestId && shipmentId) {
      dispatch(setIsCartInitialized(false));
      setSearchParams('', { replace: true });
    }
    // Clear out the Redux state
    dispatch(clearCustomerDetails()); // TODO: Convert this to React Query
    dispatch(resetCart()); // Can remove once we remove cart state from Redux
  }, [dispatch, setSearchParams, guestId, shipmentId]);

  // Handle changes to the search params
  useEffect(() => {
    // Don't do anything if anonymous cart is not enabled or not on the cart page
    if (!isOnCartPage || !isAnonymousCartLDFlagEnabled) {
      return;
    }

    if (!areAnonymousTransactionsEnabled && missingSearchParams && !accessedWithUI) {
      // If missing search params and anonymous cart setting is not enabled, redirect to guest list
      history.push('/guestlist');
    } else if (missingSearchParams && !isCartInitialized) {
      // Clear any existing transaction
      clearTransaction();
      // If collect demographics is enabled, create a new anonymous transaction now
      if (isCollectAnonymousDemographicsEnabled) {
        startTransaction();
      }
    }
  }, [
    accessedWithUI,
    clearTransaction,
    history,
    isAnonymousCartLDFlagEnabled,
    areAnonymousTransactionsEnabled,
    isCollectAnonymousDemographicsEnabled,
    isCartInitialized,
    isOnCartPage,
    missingSearchParams,
    startTransaction,
  ]);

  // Show the verify birthdate popup on cart load if necessary
  useEffect(() => {
    if (!isOnCartPage || !isAnonymousCartLDFlagEnabled) {
      return;
    }

    if (shouldShowVerifyBirthdatePopup) {
      dispatch(
        showVerifyBirthdatePopup({
          // After entering the birthdate, start the transaction
          onSuccess: (dob) => startTransaction({ dob: dob?.toDateString() }),
        })
      );
    }
  }, [dispatch, isOnCartPage, isAnonymousCartLDFlagEnabled, shouldShowVerifyBirthdatePopup, startTransaction]);

  return {
    clearTransaction,
    goToCart,
    /** Will be URL param if anon cart is enabled, otherwise guest.Guest_id from Redux */
    guestId: guestIdToReturn,
    isCreatingTransaction,
    /** Will be URL param if anon cart is enabled, otherwise guest.ShipmentId from Redux */
    shipmentId: shipmentIdToReturn,
    startTransaction,
  };
};

/** Helper to use in Redux Async Thunk actions or outside hook components */
export const getLoadedTransactionInfo = (
  reduxGuestDetails: Pick<CustomerDetails, 'Guest_id' | 'ShipmentId'> | undefined
) => {
  const { isAnonymousCartLDFlagEnabled } = getIsAnonymousCartLDFlagEnabled();

  // Redux state
  const guestIdFromRedux = reduxGuestDetails?.Guest_id;
  const shipmentIdFromRedux = reduxGuestDetails?.ShipmentId;

  // URL state
  const urlParams = new URLSearchParams(window.location.search);
  const guestIdFromUrl = urlParams.get('guest') ? Number(urlParams.get('guest')) : undefined;
  const shipmentIdFromUrl = urlParams.get('order') ? Number(urlParams.get('order')) : undefined;

  // Use the URL params if the anonymous cart flag is enabled, otherwise use the Redux state
  const guestIdToUse = isAnonymousCartLDFlagEnabled ? guestIdFromUrl : guestIdFromRedux;
  const shipmentIdToUse = isAnonymousCartLDFlagEnabled ? shipmentIdFromUrl : shipmentIdFromRedux;

  return {
    /** Will be URL param if anon cart is enabled, otherwise guest.Guest_id from Redux */
    guestId: guestIdToUse,
    /** Will be URL param if anon cart is enabled, otherwise guest.ShipmentId from Redux */
    shipmentId: shipmentIdToUse,
  };
};
