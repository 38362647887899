import React, { FC } from 'react';
import { LabsPopup } from '../sharedPopups/LabsPopup';
import { ProductsPopupsState } from './ProductsPopupsState';

type ProductsPopupsProps = {
  productsPopupsState: ProductsPopupsState;
  closePopup: () => void;
};

export const ProductsPopups: FC<ProductsPopupsProps> = ({ productsPopupsState, closePopup }) => {
  const { labsPopup } = productsPopupsState;

  return <>{labsPopup && <LabsPopup {...labsPopup} hide={closePopup} />}</>;
};
