import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { formatPhone } from 'util/helpers/formatPhone';
import { MedicalIcon } from 'assets/icons/MedicalIcon';
import { RecreationalIcon } from 'assets/icons/RecreationalIcon';
import { ResultsContainer, ResultsMessage } from './styles';
import { StyleVariant, Table } from 'components/tables';
import { useGetCheckedInGuestsQuery } from 'queries/v2/guest/checked-in';

import type { Column } from 'components/tables';
import type { CustomerSearchResult } from 'queries/v2/guest/types';

type SearchResultsTableProps = {
  onSelectCustomer?: (selectedCustomer: CustomerSearchResult) => Promise<void>;
  results: CustomerSearchResult[];
  search: string;
};

export const SearchResultsTable = ({ onSelectCustomer, results, search }: SearchResultsTableProps) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [maxTableHeight, setMaxTableHeight] = useState<number>();

  const { data: checkedInGuestIds } = useGetCheckedInGuestsQuery({
    select: (checkedInGuests) => [...new Set(checkedInGuests.map((guest) => guest.guestId))],
  });

  const columns: Column<CustomerSearchResult>[] = [
    {
      header: 'Customer Name',
      field: 'name',
      Cell: ({ value }) => <NameCell>{value}</NameCell>,
    },
    {
      header: 'Last Transaction',
      field: 'lastTransaction',
      Cell: ({ value }) => <>{formatDate({ date: value })}</>,
    },
    {
      header: 'Customer Type',
      field: 'customerType',
      Cell: ({ value }) => (
        <TypeCell>
          {value.toLowerCase().includes('med') ? <MedicalIcon /> : <RecreationalIcon />}
          {value}
        </TypeCell>
      ),
    },
    {
      header: 'Phone',
      field: 'phoneNumber',
      Cell: ({ value }) => <>{value ? formatPhone(value) : '--'}</>,
    },
    {
      header: 'Birthday',
      field: 'dob',
      Cell: ({ value }) => <>{formatDate({ date: value, fallbackString: '--' })}</>,
    },
    {
      header: 'Email',
      field: 'email',
      Cell: ({ value }) => <>{value ? value : '--'}</>,
    },
    {
      header: 'MJ State ID',
      field: 'mmjIdNumber',
      Cell: ({ value }) => <>{value ? value : '--'}</>,
    },
    {
      header: 'ID Expiration Date',
      field: 'mmjIdExpiration',
      Cell: ({ value }) => <>{formatDate({ date: value, fallbackString: '--' })}</>,
    },
    {
      header: '',
      disableSort: true,
      Cell: ({ item }) => {
        const isGuestCheckedIn = checkedInGuestIds?.includes(item.guestId) ?? false;
        return <Link disabled={isGuestCheckedIn}>{isGuestCheckedIn ? 'Order in progress' : 'Attach to cart →'}</Link>;
      },
    },
  ];

  useEffect(() => {
    const adjustTableMaxHeight = () => {
      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        const topOfTable = tableContainer.getBoundingClientRect().y; // The top of the table container
        const windowHeight = window.innerHeight; // The full window height
        const bottomPadding = 32; // The container's bottom padding
        const newMaxHeight = windowHeight - bottomPadding - topOfTable;
        setMaxTableHeight(newMaxHeight);
        return;
      }
      setMaxTableHeight(undefined);
    };
    adjustTableMaxHeight();

    window.addEventListener('resize', adjustTableMaxHeight);
    return () => window.removeEventListener('resize', adjustTableMaxHeight);
  });

  return (
    <ResultsContainer>
      <ResultsMessage>
        Showing {results.length} results for "{search}"
      </ResultsMessage>
      <TableContainer ref={tableContainerRef} maxHeight={maxTableHeight}>
        <Table<CustomerSearchResult>
          data={results}
          columns={columns}
          sortColumn={1}
          sortDirection={'desc'}
          onClick={onSelectCustomer}
          styleVariant={StyleVariant.roundedWithWhiteBackground}
          pageSize={10}
        />
      </TableContainer>
    </ResultsContainer>
  );
};

const TableContainer = styled.div<{ maxHeight?: number }>`
  display: flex;
  width: 100%;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'calc(100% - 7rem)')};
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0.75rem;
  border: 1px solid ${colors.dutchie.gray30};
  overflow-y: auto;

  table {
    border: none;
    max-height: 100%;
  }
`;

const NameCell = styled.b`
  font-weight: 600;
`;

const TypeCell = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const Link = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? colors.dutchie.gray60 : colors.dutchie.blue)};
  font-weight: 600;
`;
