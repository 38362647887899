import styled, { css } from 'styled-components';
import { Button as MuiButton } from '@mui/material';

export const StyledButton = styled(MuiButton)<{ $variant: string; $fontSize?: string; $buttonSize: string }>`
  ${({ $variant, $buttonSize }) => css`
    /* Base */
    width: fit-content;
    border: 1px solid transparent;
    box-shadow: none;
    letter-spacing: var(--button-letter-spacing);
    text-transform: none;
    flex-shrink: 0;
    white-space: nowrap;

    /* Variants */
    ${$variant === 'primary' && primary}
    ${$variant === 'secondary' && secondary}
    ${$variant === 'white' && white}
    ${$variant === 'danger' && danger}

    /* Sizes */
    ${$buttonSize === 'large' && largeButtonStyles}
    ${$buttonSize === 'medium' && mediumButtonStyles}
    ${$buttonSize === 'small' && smallButtonStyles}
    ${$buttonSize === 'extra-small' && extraSmallButtonStyles}


        :hover,
        :focus,
        :active {
      box-shadow: none;
    }

    .MuiButton-startIcon,
    .MuiButton-endIcon {
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .MuiButton-startIcon {
      margin-left: 0;
    }
    .MuiButton-endIcon {
      margin-right: 0;
    }
  `}
`;

// VARIANTS
const primary = css`
  background-color: var(--color-secondary-sapphire);
  color: var(--color-primary-white);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-primary-white);
    }
  }

  :hover {
    background-color: var(--color-additional-colors-blue-35);
  }

  :focus {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  :disabled {
    background-color: var(--color-greyscale-grey-70);
    border-color: transparent;
    color: var(--color-primary-white);
  }
`;

const secondary = css`
  color: var(--color-greyscale-grey-20);
  background-color: var(--color-greyscale-grey-90);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-greyscale-black);
    }
  }

  :hover {
    color: var(--color-greyscale-black);
    background-color: var(--color-greyscale-grey-80);
  }

  :focus {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  :disabled {
    background: var(--color-greyscale-grey-90);
    color: var(--color-greyscale-grey-50);
  }
`;

const white = css`
  background: var(--color-primary-white);
  border-color: var(--color-greyscale-grey-65);
  color: var(--color-greyscale-grey-30);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg {
      fill: var(--color-greyscale-grey-30);
    }
  }

  :hover {
    background: var(--color-greyscale-grey-90);
    border-color: var(--color-greyscale-grey-50);
    color: var(--color-greyscale-grey-20);
  }

  :focus {
    background: var(--color-primary-white);
    border: 1px solid var(--color-greyscale-black);
    color: var(--color-greyscale-black);
  }

  :disabled {
    background: var(--color-primary-white);
    border: 1px solid var(--color-greyscale-grey-65);
    color: var(--color-greyscale-grey-65);
  }
`;

const danger = css`
  color: var(--color-primary-white);
  background-color: var(--color-additional-colors-red-46);
  border: 1px solid var(--color-additional-colors-red-46);

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    svg,
    path {
      fill: var(--color-primary-white);
    }
  }

  :disabled {
    background-color: var(--color-greyscale-grey-70);
    border: 1px solid var(--color-greyscale-grey-70);
    color: var(--color-primary-white);
  }

  :hover {
    background-color: #76000c;
    border: 1px solid #76000c;
  }

  :focus {
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`;

// SIZES
const largeButtonStyles = css`
  padding: var(--sizes-40) var(--sizes-60);
  border-radius: var(--button-border-radius-large);
  font: var(--font-large-16pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 22px;
    height: 22px;
  }
`;

const mediumButtonStyles = css`
  padding: var(--sizes-30) var(--sizes-50);
  border-radius: var(--button-border-radius-medium);
  font: var(--font-regular-14pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 16px;
    height: 16px;
  }
`;

const smallButtonStyles = css`
  padding: var(--sizes-20) var(--sizes-30);
  border-radius: var(--button-border-radius-small);
  font: var(--font-extra-small-12pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 14px;
    height: 14px;
  }
`;

const extraSmallButtonStyles = css`
  padding: var(--sizes-10) var(--sizes-20);
  border-radius: var(--button-border-radius-small);
  font: var(--font-mini-11pt-semibold);

  .MuiButton-startIcon,
  .MuiButton-endIcon,
  svg {
    font-size: 0;
    width: 12px;
    height: 12px;
  }
`;
