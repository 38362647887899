import { post } from 'api/HttpHelpers';
import {
  SetGuestStatusRequest,
  SetGuestToRegisterRequest,
  CreateAnonymousTransactionRequest,
  CreateAnonymousTransactionResponse,
  NotifyCustomerRequest,
  ScanPatientId,
  ScanPatientIdResponse,
  BiotrackScanPatientRequest,
  BiotrackScanPatientResponse,
  ValidatePatientIdRequest,
  ValidatePatientIdResponse,
  JoinGroupRequest,
  PusherAuthRequest,
  CheckedInGuest,
  GuestStatus,
  ReleaseGuest,
  PusherAuthResponse,
  CardStatusDisplayOptionsResponse,
  CreateAnonymousCustomerRequest,
} from 'models/Guest';

export const setGuestStatus = (args: SetGuestStatusRequest) =>
  post<void>('posv3/maintenance/UpdateTransactionStatus', args);
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const setGuestToRegister = (args: SetGuestToRegisterRequest) =>
  post<void>('v2/guest/Select_Guest_To_Register', args);
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const createAnonymousTransaction = (args: CreateAnonymousTransactionRequest) =>
  post<[CreateAnonymousTransactionResponse]>('v2/cart/New_Rec_Transaction', args);
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const createAnonymousCustomer = (args: CreateAnonymousCustomerRequest) =>
  post<CreateAnonymousTransactionResponse>('v2/cart/new_rec_customer', args);
export const notifyCustomer = (body?: NotifyCustomerRequest) => post('v2/guest/NotifyOrderReady', body);
export const scan = (body?: ScanPatientId) => post<ScanPatientIdResponse>('v2/guest/scan', body);
export const biotrackScanGuestIn = (body?: BiotrackScanPatientRequest) =>
  post<BiotrackScanPatientResponse>('posv3/Biotrack/MDScanPatientIn', body);
export const validateBiotrackUser = (body?: BiotrackScanPatientRequest) => post('posv3/Biotrack/ValidatePatient', body);
export const validatePatientId = (body?: ValidatePatientIdRequest) =>
  post<ValidatePatientIdResponse>('v2/guest/validate-id', body);
export const joinGroup = (body?: JoinGroupRequest) => post('poshub/join-group', body);
/** @deprecated use useGetCheckedInGuestsQuery from queries/v2/guest/checked-in instead */
export const getGuests = () => post<Array<CheckedInGuest>>('v2/guest/checked-in');
export const getGuestStatuses = () => post<Array<GuestStatus>>('posv3/maintenance/get-pos-statuses');
export const releaseGuest = (body?: ReleaseGuest) => post<void>('v2/guest/Remove_Guest_From_Register', body);
export const authPusher = (body?: PusherAuthRequest) => post<PusherAuthResponse>('pusher/auth', body);
export const getCardStatusDisplayOptions = () =>
  post<Array<CardStatusDisplayOptionsResponse>>('v2/card-status-display-options');
export const mcdmsIdScan = (body?: ValidatePatientIdRequest) => post<ValidatePatientIdResponse>('MCDMS/scan-id', body);
