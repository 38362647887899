import React from 'react';

type HamburgerIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
  automationId?: string;
};

export function HamburgerIcon(props: HamburgerIconProps) {
  const { height = 20, width = 20, color = '#ffffff', className, automationId } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.85491C0 2.46042 0.319797 2.14062 0.714286 2.14062H19.2857C19.6802 2.14062 20 2.46042 20 2.85491C20 3.2494 19.6802 3.5692 19.2857 3.5692H0.714286C0.319797 3.5692 0 3.2494 0 2.85491Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.99777C0 9.60328 0.319797 9.28348 0.714286 9.28348H19.2857C19.6802 9.28348 20 9.60328 20 9.99777C20 10.3923 19.6802 10.7121 19.2857 10.7121H0.714286C0.319797 10.7121 0 10.3923 0 9.99777Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17.1406C0 16.7461 0.319797 16.4263 0.714286 16.4263H19.2857C19.6802 16.4263 20 16.7461 20 17.1406C20 17.5351 19.6802 17.8549 19.2857 17.8549H0.714286C0.319797 17.8549 0 17.5351 0 17.1406Z" fill={color}/>
    </svg>
  );
}
