import React from 'react';
import { CheckedInGuest } from 'models/Guest';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { ReactComponent as ElipsisSvg } from 'assets/icons/elipsis-icon.svg';
import { useKanbanOverflowOptions } from './useKanbanOverflowOptions';

type Props = {
  guest: CheckedInGuest;
  hasAssignedRegister: boolean;
};

export const PositionedContextMenu: React.FC<Props> = React.memo(({ guest, hasAssignedRegister }) => {
  const kanbanOverflowOptions = useKanbanOverflowOptions({ guest, hasAssignedRegister });

  const menuWidth = 225;
  const anchorPadding = 24;

  return (
    <OverflowMenu
      anchor={<ElipsisIcon />}
      anchorProps={{
        position: { top: '0', right: '0' },
        padding: '1.5rem',
        showCursor: true,
      }}
      menuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 12, horizontal: menuWidth + anchorPadding },
        width: `${menuWidth}px`,
        fontSize: '1rem',
        textAlign: 'center',
      }}
      menuOptions={kanbanOverflowOptions}
      automationId='guest-card_overflow'
    />
  );
});

const ElipsisIcon = styled(ElipsisSvg)`
  path {
    fill: ${colors.dutchie.grey70};
  }
`;
