import { showChangeCustomerTypePopup } from 'store/actions/PopupsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';

export enum CustomerTypeBadgeVariations {
  MEDICAL = 'Medical',
  RECREATIONAL = 'Recreational',
}

export const useCustomerType = () => {
  const dispatch = useAppDispatch();

  // Global state

  const customerTypeId = useAppSelector((state) => state.customer.details?.CustomerTypeId);
  const customerTypes = useAppSelector((state) => state.settings.customerTypes);
  const isChangeCustomerTypeInCartEnabled = useAppSelector((state) => state.settings.features.ChangeCustomerTypeInCart);
  const isMedicalCustomer = useAppSelector((state) => state.customer.details?.IsMedical ?? false);

  // Computed values

  const customerType = isMedicalCustomer
    ? CustomerTypeBadgeVariations.MEDICAL
    : CustomerTypeBadgeVariations.RECREATIONAL;
  const customerTypeDescription = customerTypes?.find((type) => type.CustomerTypeId === customerTypeId)?.CustomerType;

  // Handlers

  const handleClickChange = () => dispatch(showChangeCustomerTypePopup());

  return {
    customerType,
    customerTypeDescription,
    handleClickChange,
    hideCustomerTypeSection: customerTypeId === undefined,
    showChangeButton: isChangeCustomerTypeInCartEnabled,
  };
};
