import styled, { css } from 'styled-components';
import { MenuItem } from '@mui/material';

const textAlignToFlex = (align: 'left' | 'center' | 'right') => {
  switch (align) {
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'left':
      return 'flex-start';
  }
};

export const MenuOption = styled(MenuItem)<{
  danger?: boolean;
  disabled?: boolean;
  fontSize?: string;
  align?: 'left' | 'center' | 'right';
}>`
  ${({ danger, disabled, fontSize, align }) => css`
    padding: var(--sizes-30) var(--sizes-70);
    color: var(--color-greyscale-black);
    font: var(--font-regular-14pt-normal);

    .MuiCheckbox-root {
      margin-right: var(--sizes-40);
    }

    &:hover,
    &.Mui-selected:hover {
      background-color: var(--color-greyscale-grey-95);
    }

    &.Mui-selected {
      background-color: transparent;
      font-weight: 600;
    }
    ${danger &&
    css`
      color: var(--color-additional-colors-red-46);
    `}
    ${disabled &&
    css`
      pointer-events: none;
    `}
    ${fontSize &&
    css`
      font-size: ${fontSize};
    `}
    ${align &&
    css`
      text-align: ${align};
      justify-content: ${textAlignToFlex(align)};
    `}
  `}
`;
