import { CartItem, Preorder } from 'models/Cart';
import { callback, callback1 } from 'models/Misc';
import { Reason } from 'models/Settings';
import { PaymentMethod } from 'models/Checkout';
import { PointSet } from 'components/misc';

import type { CancelTransactionGuestProps } from 'components/sharedPopups/CancelTransactionPopup';
import type { DeliveryOrderProps } from 'components/sharedPopups/DeliverySettingsPopup';
import type { ProductSearchResult } from 'queries/v2/product/types';

export type CartPopupsState = {
  transactionNotesPopup: { guestId?: number; shipmentId?: number } | null;
  registerForLoyaltyPopup: {} | null;
  verifyTransactionPopup: { guestId: number; shipmentId: number } | null;
  remotePayPopup: { shipmentId?: number } | null;
  dutchiePayPopup: { shipmentId?: number } | null;
  backdateTransactionPopup: { shipmentId?: number } | null;
  setTransactionReferencePopup: {} | null;
  confirmReleasePopup: {} | null;
  confirmRemovalPopup: { item: CartItem } | null;
  deliverySettingsPopup: { delivery: DeliveryOrderProps } | null;
  journalPopup: { guestId?: number } | null;
  editProductPopup: { item: CartItem } | null;
  discountPopup: { item: CartItem } | null;
  springBigRedeemPopup: { item?: CartItem } | null;
  alpineIQRedeemPopup: { item?: CartItem } | null;
  fylloRedeemPopup: { item?: CartItem } | null;
  feeDonationPopup: {} | null;
  rerouteProductPopup: { item: CartItem } | null;
  editDevicePopup: { item: CartItem } | null;
  clearCartPopup: {} | null;
  viewAllotmentDetailPopup: {} | null;
  notifyCustomerPopup: {} | null;
  educationalMaterialPopup: {} | null;
  visitReasonPopup: { shipmentId: number } | null;
  bulkCalculatorPopup: {
    product: ProductSearchResult | CartItem;
    inputWeight: string;
    isPreorder?: boolean;
    setWeight?: callback1<string>;
    setTotalCost?: callback1<number>;
    closeProductPreview?: () => void;
  } | null;
  managerPinPopup: {
    onSuccess: callback;
    title: string;
    reasons?: Array<Reason>;
    selectedReason?: string;
    setSelectedReason?: callback1<string>;
    onCancel?: callback;
  } | null;
  cancelTransactionPopup: { guest: CancelTransactionGuestProps } | null;
  setStatusPopup: { action?: callback; title?: string } | null;
  labsPopup: { batchId?: number } | null;
  last4Popup: { onSuccess: callback; serialNumber: string; onCancel?: callback } | null;
  overLimitPopup: { onConfirm: callback; message: string } | null;
  pastExpirationDatePopup: { onConfirm: callback; expiredItemsSerialNumbers: string[] } | null;
  hypurCheckoutPopup: { paymentMethod: PaymentMethod; shipmentId?: number } | null;
  emailPopup: { onEmail: callback1<string> } | null;
  signaturePopup: { setSignatureSaved: callback1<boolean>; shipmentId: number; signatureData?: PointSet } | null;
  addMoreItemsToPreOrderPopup: { onSuccess: callback; onCancel?: callback } | null;
  canPayCheckoutPopup: { paymentMethod: PaymentMethod; shipmentId?: number } | null;
  digitalManifestPopup: {
    manifestLink: string;
    setSignatureSaved?: callback1<boolean>;
    signatureData?: PointSet;
  } | null;
  verifyBirthdatePopup: {
    guestDob: string;
    isAnonymous: boolean;
    onSuccess: callback1<Date | undefined>;
    shipmentId?: number;
    acctId?: number;
  } | null;
  assignPackageIDsPopup: { selectedPreorderItem: Preorder } | null;
  removeCartItem: { item: CartItem; onRemoveItem: () => void } | null;
};

export const emptyCartPopupsState: CartPopupsState = {
  transactionNotesPopup: null,
  registerForLoyaltyPopup: null,
  verifyTransactionPopup: null,
  remotePayPopup: null,
  dutchiePayPopup: null,
  backdateTransactionPopup: null,
  setTransactionReferencePopup: null,
  confirmReleasePopup: null,
  confirmRemovalPopup: null,
  deliverySettingsPopup: null,
  journalPopup: null,
  editProductPopup: null,
  discountPopup: null,
  springBigRedeemPopup: null,
  alpineIQRedeemPopup: null,
  fylloRedeemPopup: null,
  feeDonationPopup: null,
  rerouteProductPopup: null,
  editDevicePopup: null,
  clearCartPopup: null,
  viewAllotmentDetailPopup: null,
  notifyCustomerPopup: null,
  educationalMaterialPopup: null,
  visitReasonPopup: null,
  bulkCalculatorPopup: null,
  managerPinPopup: null,
  cancelTransactionPopup: null,
  setStatusPopup: null,
  labsPopup: null,
  last4Popup: null,
  overLimitPopup: null,
  pastExpirationDatePopup: null,
  hypurCheckoutPopup: null,
  emailPopup: null,
  signaturePopup: null,
  addMoreItemsToPreOrderPopup: null,
  canPayCheckoutPopup: null,
  digitalManifestPopup: null,
  verifyBirthdatePopup: null,
  assignPackageIDsPopup: null,
  removeCartItem: null,
};
