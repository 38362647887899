import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ModalCloseButton, ModalContainer, ModalContent, ModalVariation } from 'components/modals/Modal';
import { PopupOverlay } from 'components/popups';

import { useHistory } from 'react-router';
import { successNotification } from 'store/actions/NotificationsActions';
import { handleLogout } from 'store/actions/UserActions';
import { MigrationIconWarning } from './MigrationIconWarning';
import {
  Container,
  CopyableURLContainer,
  DisplayURL,
  FloatingModalHeader,
  LargeButton,
  Link,
  LinksSection,
  SmallButton,
  StyledHeading,
  Text,
  TextSection,
} from './ServerMigrationPromptModal';
import { useNewRegion } from './useNewRegion';
import { useServerMigrationConfig } from './useServerMigrationConfig';
import { selectUserRegion } from 'store/actions/SettingsActions';

export function AndroidServerMigrationPromptModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { deadlineDateLabel, registerURL, shouldShowBlockingUI, dayBeforeDeadline } = useServerMigrationConfig();
  const [open, setOpen] = useState<boolean | null>(null);
  const newRegion = useNewRegion();

  useEffect(() => {
    if (!shouldShowBlockingUI && open === null && registerURL) {
      setOpen(true);
    }
  }, [shouldShowBlockingUI, open, registerURL]);

  const openingText = deadlineDateLabel?.length
    ? `Your account will be migrated to an upgraded environment on ${deadlineDateLabel}.`
    : 'Your account will soon be migrated to an upgraded environment.';
  const dayBeforeText = `This server will be disabled one day ahead of time on ${dayBeforeDeadline}.`;

  const handleCopyURLToClipboard = async () => {
    await navigator.clipboard.writeText(newRegion ?? '');
    dispatch(successNotification('Server copied to clipboard'));
  };

  async function logout() {
    await dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));
    dispatch(selectUserRegion(newRegion));
  }

  if (!open || !registerURL) {
    return null;
  }

  return (
    <PopupOverlay hide={() => {} /* intentionally only close when X is clicked */}>
      <ModalContainer variation={ModalVariation.Standard}>
        <ModalContent>
          <Container>
            <FloatingModalHeader>
              <ModalCloseButton onClick={() => setOpen(false)} />
            </FloatingModalHeader>
            <MigrationIconWarning />
            <TextSection>
              <StyledHeading>Your server has changed</StyledHeading>
              <Text>
                {openingText} {dayBeforeText} Please click below to log out and switch to your new server. Visit the
                help center to learn more.
              </Text>
            </TextSection>
            <CopyableURLContainer>
              <DisplayURL>{newRegion}</DisplayURL>
              <SmallButton onClick={handleCopyURLToClipboard}>Copy</SmallButton>
            </CopyableURLContainer>
            <LinksSection>
              <LargeButton onClick={logout}>Switch Server</LargeButton>
              <Link onClick={() => window.open('https://support.dutchie.com/hc/en-us/articles/28929066112147')}>
                Visit help center
              </Link>
            </LinksSection>
          </Container>
        </ModalContent>
      </ModalContainer>
    </PopupOverlay>
  );
}
