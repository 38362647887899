import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeAllPopups } from 'store/actions/PopupsActions/GuestListPopupsActions';
import { removeAllNotifications } from 'store/actions/NotificationsActions';
import { useInHouseScanning } from 'util/hooks/launch-darkly/useInHouseScanning';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

// This component's only purpose is to pass up changes in Location to the AppComponent path
// because useLocation hook isn't available outside of a Router.
export const PathListener: FC<{ onChange: (path: string) => void }> = ({ onChange }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isInHouseScanningEnabled = useInHouseScanning();
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  useEffect(() => {
    if (isInHouseScanningEnabled || isAnonymousCartLDFlagEnabled) {
      dispatch(closeAllPopups());
    }
    dispatch(removeAllNotifications());
    onChange(location.pathname);
  }, [dispatch, isInHouseScanningEnabled, isAnonymousCartLDFlagEnabled, location.pathname, onChange]);

  return null;
};
