import { createAction } from '@reduxjs/toolkit';
import { CustomerPopupsPayloads } from 'store/reducers/PopupsReducer/CustomerPopupsReducer';

export const showPotentialDuplicatePopup = createAction(
  'showPotentialDuplicatePopup',
  (payload: CustomerPopupsPayloads['showPotentialDuplicatePopup']) => ({ payload })
);

export const showUnsavedChangesPopup = createAction(
  'showUnsavedChangesPopup',
  (payload: CustomerPopupsPayloads['showUnsavedChangesPopup']) => ({ payload })
);

export const showConvertToNonAnonymousPopup = createAction(
  'showConvertToNonAnonymousPopup',
  (payload: CustomerPopupsPayloads['showConvertToNonAnonymousPopup']) => ({ payload })
);

export const showCheckinCaregiverPopup = createAction(
  'showCheckinCaregiverPopup',
  (payload: CustomerPopupsPayloads['showCheckinCaregiverPopup']) => ({ payload })
);

/** @deprecated Should use showSelectCollectorPopup from GlobalPopups */
export const showSelectCollectorFromProfilePopup = createAction(
  'showSelectCollectorFromProfilePopup',
  (payload: CustomerPopupsPayloads['showSelectCollectorFromProfilePopup']) => ({ payload })
);

/** @deprecated Should use showVerifyBirthdatePopup from GlobalPopups */
export const showVerifyBirthdateFromProfilePopup = createAction(
  'showVerifyBirthdateFromProfilePopup',
  (payload: CustomerPopupsPayloads['showVerifyBirthdateFromProfilePopup']) => ({ payload })
);

/** @deprecated Should use showFulfillmentSelectorPopup from GlobalPopups */
export const showStartOrderPopup = createAction(
  'showStartOrderPopup',
  (payload: CustomerPopupsPayloads['showStartOrderPopup']) => ({ payload })
);

/** @deprecated Should use showCreatePreOrderPopup from GlobalPopups */
export const showCreatePreOrderFromProfilePopup = createAction(
  'showCreatePreOrderFromProfilePopup',
  (payload: CustomerPopupsPayloads['showCreatePreOrderFromProfilePopup']) => ({ payload })
);
