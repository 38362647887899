import React from 'react';
import styled from 'styled-components';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { ReactComponent as GramsIcon } from 'assets/icons/grams.svg';
import { useScaleSettings } from '../hooks/useScaleSettings';
import { Input } from 'components/inputs';

export const ScaleTestListItem = () => {
  const { lastMeasurement: measurement } = useScaleSettings();

  return (
    <SettingsListItem
      title='Test weight reading'
      subtitle='Place item on scale to test weight'
      actions={[
        <InputContainer>
          <Input
            disabled={true}
            automationId='scale_settings-test_input'
            readOnly
            placeholder={'--'}
            endAdornment={<GramsIcon style={{ maxWidth: 'unset' }} />}
            value={measurement?.value?.toString() ?? ''}
          />
        </InputContainer>,
      ]}
    />
  );
};

const InputContainer = styled.div`
  width: 300px;
`;
