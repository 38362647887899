import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const CardIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <path
            d='M4 3.5L4 20.1452C4 21.5 5.45455 21.5 5.45455 21.5H18.5455C20 21.5 20 20.1452 20 20.1452V3.5'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path d='M8 21L8 3' stroke='#828A8F' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='round' />
          <path d='M13 18V11' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M16 18V13' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <line x1='1.75' y1='2.75' x2='22.25' y2='2.75' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <path
          d='M4 3L4 20.1452C4 21.5 5.45455 21.5 5.45455 21.5H18.5455C20 21.5 20 20.1452 20 20.1452V3H4Z'
          fill='#C1D3F1'
        />
        <path
          d='M4 3.5L4 20.1452C4 21.5 5.45455 21.5 5.45455 21.5H18.5455C20 21.5 20 20.1452 20 20.1452V3.5'
          stroke='#0075E0'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path d='M8 21L8 3' stroke='#0075E0' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='round' />
        <path d='M13 18V11' stroke='#0075E0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M16 18V13' stroke='#0075E0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <line x1='1.75' y1='2.75' x2='22.25' y2='2.75' stroke='#0075E0' stroke-width='1.5' stroke-linecap='round' />
      </g>
    </svg>
  );
};
