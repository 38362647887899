import { createAction } from '@reduxjs/toolkit';
import { DeliveryPopupsPayloads } from 'store/reducers/PopupsReducer/DeliveryPopupsReducer';

export const showTransactionPopup = createAction(
  'showTransactionPopup',
  (payload: DeliveryPopupsPayloads['showTransactionPopup']) => ({ payload })
);
export const showConfigureDeliveriesPopup = createAction(
  'showConfigureDeliveriesPopup',
  (payload: DeliveryPopupsPayloads['showConfigureDeliveriesPopup']) => ({ payload })
);
export const showDeliverySettingsPopup = createAction(
  'showDeliverySettingsPopup',
  (payload: DeliveryPopupsPayloads['showDeliverySettingsPopup']) => ({ payload })
);
export const showConfirmTransactionPopup = createAction(
  'showConfirmTransactionPopup',
  (payload: DeliveryPopupsPayloads['showConfirmTransactionPopup']) => ({ payload })
);
export const showBypassStateSystemPopup = createAction(
  'showBypassStateSystemPopup',
  (payload: DeliveryPopupsPayloads['showBypassStateSystemPopup']) => ({ payload })
);

