import { Location, Lsp } from './Location';

export const preOrderStatus = 'I Heart Jane Order';

export type callback = () => void;
export type callback1<A> = (arg: A) => void;
export type asyncCallback1<A, B> = (arg: A) => Promise<B>;
export type callback2<A, B> = (arg1: A, arg2: B) => void;
export type callback3<A, B, C> = (arg1: A, arg2: B, arg3: C) => void;
export type predicate1<A> = (arg: A) => boolean;

export type LabelResponse = {
  id: number;
  LabelDescription: string;
};

export type PrinterResponse = {
  PrinterId: number;
  Name: string;
  PrinterType: PrintNodePrinterType | null;
};
export const PrintNodePrinterTypes = {
  EPSON: 'EPSON',
  ESC: 'ESC',
  PDF: 'PDF',
  STAR_GRAPHIC: 'STAR_GRAPHIC',
  STAR_PRINT: 'STAR_PRINT',
  ZPL: 'ZPL',
} as const;

export type PrintNodePrinterType = typeof PrintNodePrinterTypes[keyof typeof PrintNodePrinterTypes];

export type Register = {
  label: string;
  value?: number;
};

export type CustomerSearchConfig = {
  name: string;
  hidden: boolean;
  order: number;
};

export type ConsumeLoginTokenResponse = {
  SessionId: string;
  LocId: number;
  LspId: number;
  RegisterId?: number;
  HardwareId?: string;
};

export type UserInfoResponse = {
  UserName: string;
  LspId: number;
  FullName: string;
  LocId: number;
  Dispensary: string;
  Cultivation: string;
  OfficeAccess: string;
  ExportReports: string;
  Administrator: string;
  Inventory: string;
  InventoryReadOnly: string;
  Inspector: string;
  ManagerFlag: string;
  AcctType: string;
  locations: Array<Location>;
  lsps: Array<Lsp>;
  UserId: number;
  Region: string;
};

export type EloUserInfoResponse = UserInfoResponse & {
  DeviceSessionId: string;
  DeviceSessionTime: string;
};

export type SessionInfo = {
  Id: string;
  FullName: string;
  LoginType: string;
  PatientAcct: string;
  OnlineStatus: string;
  SessionId: string;
  SessionGid: string;
};

export type ForgotResponse = {
  username: string;
};

export type PrinterSelection = {
  PrinterId: number | string;
  Name: string;
  LocalPrinter?: boolean;
  Status?: string;
};

export type UserSettings = {
  /** @deprecated - Use getStoredHardware().labelPrinter */
  selectedLabelPrinter?: PrinterSelection;
  /** @deprecated - Use getStoredHardware().receiptPrinter */
  selectedReceiptPrinter?: PrinterSelection;
  /** @deprecated - Use getStoredHardware().fulfillmentPrinter */
  selectedFulfillmentPrinter?: PrinterSelection;
  selectedLabel?: LabelResponse;
  /** @deprecated - Android only. Does not apply with Hardware Library */
  useConnectedLabelPrinter: boolean;
  /** @deprecated - Android only. Does not apply with Hardware Library */
  useConnectedReceiptPrinter: boolean;
  /** @deprecated - Android only. Does not apply with Hardware Library */
  useConnectedFulfillmentPrinter: boolean;
  customerSearchConfig?: Array<CustomerSearchConfig>;
  autoPrintLabels: boolean;
  autoPrintReceipts: boolean;
  autoPrintFulfillmentTicket: boolean;
  showPopupNotes: boolean;
  showSearch: boolean;
  showGuests: boolean;
};

export type HardwareRegisterResponse = {
  Id: number;
  TerminalName: string;
  HardwareId: string;
};

export type RegisterResponse = {
  id: number;
  TerminalName: string;
  RoomId: number;
  RoomNo: string;
  ReceiptPrinterId: number;
  LabelPrinterId: number;
  FulfillmentPrinterId: number;
  AutoPrintLabels: boolean;
  AutoPrintReceipts: boolean;
  AutoPrintFulfillmentTicket: boolean;
  DefaultLabelId: number;
  PopUpNotesOnEntry: boolean;
  PopCashDrawer: boolean;
  AllowDeliveryReceipts: boolean;
  RestrictToScaleWeighing: boolean;
  HardwareId?: string;
};

export type RegisterTransactionRecord = {
  EntryType: string;
  StartBalance: number;
  EntryAmount: number;
  EndingBalance: number;
  Reason: string;
  ClosingDate: string;
  Comments: string;
  TotalDueCustomer: number;
  TransactionBy: string;
  TransactionDateTime: string;
  transactionID: number;
  CustomerName: string;
};

export type RegisterTransactionsRequest = {
  Register?: number;
  RegisterId: number;
  fromDate?: string;
  toDate?: string;
};

export type LocationSettings = {
  DefaultLabel: number;
  TimeOffSet: number;
  State: string;
  UsePrintNode: boolean;
  CartPhoneDisclaimer: string;
  StorefrontUrl: string;
  DefaultCustomerTypeId: number;
};

export type PinCheckResponse = {
  UserId: number;
  UserFullName: string;
  MgrPermission: boolean;
};

export type ResultResponse = {
  ResultId: number;
  OverThreshold?: boolean;
};

export type PosApiResponse<T> = {
  Data: Array<T>;
  Message: string;
  Result: boolean;
  IntegrationMessage: string;
  IntegrationResult: boolean;
  IntegrationData: {
    PaymentError: string;
    ErrorCode: string;
    MissingPaymentException: string;
    Message?: string;
  };
};

export type PreorderConfig = {
  OrderSources: OrderSource[];
  WalkInEnabled: boolean;
  CurbsidePickupEnabled: boolean;
  DeliveryEnabled: boolean;
  InStorePickupEnabled: boolean;
  UseFulfillmentModel: boolean;
};

export type OrderSource = {
  OrderSourceName: string;
  OrderSourceId: number;
  IsDefault: boolean;
};

export type EnvironmentDetails = {
  Pos4Url: string;
  Pos3Url: string;
  BackOfficeUrl: string;
  CurbsideUrl: string;
  MobileCheckoutUrl: string;
  DutchieRegisterUrl: string;
  LaunchDarklyClientKey: string;
  Region: string;
};

export type CFDCustomIdleImageResponse = {
  ContentType?: string;
  Data: string | null;
};

export type LogoutRequest = {
  Origin: string;
};
