import React, { FC } from 'react';

import { CashDetailsPanel } from '../CashDetailsPanel';
import { ConfirmTransactionPopup } from '../ConfirmTransactionPopup';
import { Label } from 'components/text';
import { LoadingButton } from 'components/buttons';
import { TextArea } from 'components/inputs';
import { useDropCash } from './useDropCash';

import {
  ButtonsContainer,
  CashDetailsButton,
  CashInputContainer,
  Container,
  CurrencyInput,
  Fields,
  Row,
  TabTitle,
  TextAreaContainer,
} from '../Styles';

export type DropCashProps = {
  onDropCash: () => void;
  registerId: number;
};

export const DropCash: FC<DropCashProps> = ({ onDropCash, registerId }) => {
  const {
    cashCounted,
    handleClickEnterCashDetails,
    handleDrop,
    isConfirmPopupVisible,
    isDropDisabled,
    isLoading,
    isMgrPinForDepositEnabled,
    note,
    setCashCounted,
    setNote,
    toggleConfirmPopup,
  } = useDropCash({
    onDropCash,
    registerId,
  });

  return (
    <Container data-testid='drop-cash-container'>
      <TabTitle>Drop Cash</TabTitle>
      <Fields>
        <Row>
          <CashInputContainer>
            <CurrencyInput
              label='Cash counted'
              value={cashCounted.formattedValue}
              onValueChange={setCashCounted}
              allowNegative={false}
              data-testid='drop-cash_input_cash-counted'
            />
            <CashDetailsButton $variant='primary' onClick={handleClickEnterCashDetails}>
              Enter cash details
            </CashDetailsButton>
          </CashInputContainer>
        </Row>
        <TextAreaContainer>
          <Label>Note</Label>
          <TextArea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder='N/A'
            data-testid='drop-cash_textarea_notes'
          />
        </TextAreaContainer>
      </Fields>
      <ButtonsContainer>
        <LoadingButton loading={isLoading} disabled={isDropDisabled} onClick={toggleConfirmPopup}>
          Drop
        </LoadingButton>
      </ButtonsContainer>
      {/* Popups */}
      <ConfirmTransactionPopup
        pinRequired={isMgrPinForDepositEnabled}
        title='Confirm Cash Drop'
        message={`Total: $ ${cashCounted.formattedValue}`}
        onConfirm={handleDrop}
        isVisible={isConfirmPopupVisible}
        hide={toggleConfirmPopup}
      />
      <CashDetailsPanel onSave={setCashCounted} />
    </Container>
  );
};
