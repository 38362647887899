import { MessageEnvelope, MessagingProviderName } from "models/Messaging";
import { useCallback } from "react";
import { customEventKeys, logger } from "util/logger";
import { useMetrics } from "util/metrics/useMetrics";

export function useOnChannelMessage(channelMessageCallback: (eventName: string, message: string | object) => void, provider: MessagingProviderName) {
    const metrics = useMetrics();

    const onChannelMessage = useCallback((eventName: string, message: string) => {
        // We don't care to handle pusher internal events here, just continue listening
        if (eventName.includes('pusher:') || eventName.includes('pusher_internal:')) {
            return;
        }

        let deserializedMessage: MessageEnvelope | undefined;

        try {
            deserializedMessage = JSON.parse(message);
        } catch (ex: any) {
            metrics.addCount('dpos.deserialization-error', {
                logData: {
                    provider,
                    message_name: eventName,
                },
            });
            logger.error(`${provider} message failed to deserialize`, { Exception: ex, SerializedMessage: message });
        }

        /*
        * Unfortunately, there does not appear to be any real type safety available for JSON.parse out of the box
        * Using these if blocks to assert that:
        * 1. the message is populated with some object -> if not, return without handling message
        * 2. the message is in fact a MessageEnvelope -> if not, route the message through callback as is, without logging (this indicates the new pusher/ably client are not yet in use)
        */
        if (deserializedMessage === undefined) {
            return;
        }

        if (deserializedMessage.Data === undefined) {
            channelMessageCallback(eventName, message);
            return;
        }

        metrics.addCount('dpos.message-received', {
            logData: {
                provider,
                message_name: eventName,
            },
        });
        logger.info<MessageEnvelope>(`${provider} message received`, {
            ...deserializedMessage,
            key: customEventKeys.messaging.ably.messageReceipt,
        });
        channelMessageCallback(eventName, deserializedMessage.Data);
    }, [channelMessageCallback, metrics, provider]);

    return onChannelMessage;
}
