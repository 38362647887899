import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import match from 'match-sorter';

import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { ShortenedText } from '../ShortenedText';
import { useGetProductHistoryQuery } from 'queries/v2/product/product-history';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { Column } from 'components/tables';
import type { ProductHistoryItem } from 'queries/v2/product/types';

const searchProductHistory = (productHistoryItems: ProductHistoryItem[], searchValue?: string) => {
  if (!searchValue) {
    return productHistoryItems;
  }

  const keysToSearch: (keyof ProductHistoryItem)[] = [
    'receiptNumber',
    'serialNo',
    'productDescription',
    'productSku',
    'instructions',
    'locationName',
    'shipmentDate',
    'prescriptionNumber',
  ];

  return match(productHistoryItems, searchValue, { keys: keysToSearch });
};

export const useProductHistory = () => {
  // Local state

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [maxTableHeight, setMaxTableHeight] = useState<number | undefined>(undefined);
  const [searchValue, setSearchValue] = useState('');

  // Hooks

  // Transaction data

  const { guestId } = useTransactionManager();

  const { data: productHistoryItems, isLoading: isProductHistoryLoading } = useGetProductHistoryQuery(
    { guestId: guestId ?? 0 },
    {
      enabled: !!guestId,
      select: (productHistoryItems) => searchProductHistory(productHistoryItems, searchValue),
    }
  );

  // Columns

  const tableColumns: Column<ProductHistoryItem>[] = [
    { header: 'Date', field: 'shipmentDate', Cell: ({ value }) => <>{formatDate({ date: value })}</> },
    { header: 'Receipt', field: 'receiptNumber' },
    { header: 'Serial No', field: 'serialNo', width: '250px', Cell: ({ value }) => <ShortenedText text={value} /> },
    {
      header: 'Description',
      field: 'productDescription',
      width: '250px',
      Cell: ({ value }) => <ShortenedText text={value} />,
    },
    {
      header: 'Instructions',
      field: 'instructions',
      width: '250px',
      Cell: ({ value }) => <ShortenedText text={value} />,
    },
    { header: 'Sold By', field: 'soldBy', width: '200px' },
    { header: 'Lot No', field: 'lotNumber', width: '250px', Cell: ({ value }) => <ShortenedText text={value} /> },
    { header: 'RX No', field: 'prescriptionNumber' },
    { header: 'Grams', field: 'equivalent' },
    { header: 'Product SKU', field: 'productSku' },
    { header: 'Returned By', field: 'returnBy' },
    { header: 'Reason', field: 'returnReason' },
    { header: 'Status', field: 'shipmentStatus' },
    {
      header: 'Integration Sync Date',
      field: 'biotrackDate',
      width: '250px',
      Cell: ({ item, value }) => (
        <>{formatDate({ date: value || item.shipmentDate, dateFormat: 'MM/dd/yyyy hh:mm:ss a' })}</>
      ),
    },
    { header: 'Integration Response', field: 'biotrackResponse', width: '250px' },
    { header: 'Owner', field: 'lspName' },
    { header: 'Location', field: 'locationName' },
    { header: 'Prescription', field: 'prescription' },
  ];

  // Computed values

  const errorMessage = useMemo(() => {
    return searchValue.length && productHistoryItems?.length === 0 ? 'No matching products on history found' : '';
  }, [searchValue, productHistoryItems]);

  // Handlers

  const handleChangeSearch = useCallback(
    debounce((value: string) => {
      setSearchValue(value.trim());
    }, 500),
    []
  );

  const handleSearch = (value: string) => {
    setSearchValue(value.trim());
  };

  // Effects

  useEffect(() => {
    const adjustTableMaxHeight = () => {
      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        const topOfTable = tableContainer.getBoundingClientRect().y; // The top of the table container
        const windowHeight = window.innerHeight; // The full window height
        const bottomPadding = 32; // The container's bottom padding
        const newMaxHeight = windowHeight - bottomPadding - topOfTable;
        setMaxTableHeight(newMaxHeight);
        return;
      }
      setMaxTableHeight(undefined);
    };
    adjustTableMaxHeight();

    window.addEventListener('resize', adjustTableMaxHeight);
    return () => window.removeEventListener('resize', adjustTableMaxHeight);
  });

  return {
    handleChangeSearch,
    handleSearch,
    errorMessage,
    isProductHistoryLoading,
    maxTableHeight,
    productHistoryItems,
    searchValue,
    tableColumns,
    tableContainerRef,
  };
};
