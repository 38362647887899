import React, { FC } from 'react';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField, useFormikContext } from 'formik';
import { isEmpty, isString } from 'lodash';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { DatePickerHeader, Range } from './DatePickerHeader';
import { Label } from 'components/text';
import { DatePickerBase, CalendarInput } from './StyledDatePicker';
import { DatePickerMode, useNumberFormatPattern, useDateFormat } from './common';
import { Tooltip, TooltipIconContainer, TooltipIcon } from 'components/backoffice/tooltip';

type PermittedReactDatePickerProps = Omit<ReactDatePickerProps, 'onChange' | 'dateFormat' | 'showTimeSelect'>;

export type DatePickerFieldProps = PermittedReactDatePickerProps & {
  mode?: DatePickerMode;
  yearRange?: Range;
  labelText?: string;
  automationId?: string;
  updateField?: string;
  width?: string;
  onDateChange?: (date: Date | null) => void;
  tooltipText?: string;
};

export const DatePickerField: FC<DatePickerFieldProps> = ({
  onDateChange,
  labelText,
  className,
  automationId,
  width = '206px',
  mode = 'date',
  yearRange = { start: 1930, end: 2025 },
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name || '');
  const tooltip = props.tooltipText;

  // eslint-disable-next-line prefer-destructuring
  let value = field.value;
  if (value && isString(value) && !isEmpty(value)) {
    value = new Date(field.value);
  }

  const dateFormat = useDateFormat(mode);
  const numberFormatPattern = useNumberFormatPattern(mode, value);

  const handleChange = (value: Date | null) => {
    setFieldValue(field.name, value);

    if (props.updateField && onDateChange && value !== null) {
      setFieldValue(props.updateField, onDateChange(value));
    }
  };

  const calendarInput = (
    <CalendarInput
      width={width}
      format={numberFormatPattern}
      type='number'
      startAdornment={<CalendarIcon />}
      autoComplete='off'
      data-testid={automationId}
      containerMargin='0'
      mask='_'
    />
  );

  return (
    <DatePickerBase className={className}>
      {labelText && (
        <Label>
          {labelText}
          {tooltip && (
            <Tooltip placement='top' title={tooltip}>
              <TooltipIconContainer>
                <TooltipIcon />
              </TooltipIconContainer>
            </Tooltip>
          )}
        </Label>
      )}
      <ReactDatepicker
        name={field.name}
        selected={value}
        onBlur={field.onBlur}
        onChange={(date) => handleChange(date)}
        customInput={calendarInput}
        renderCustomHeader={(dateHeaderProps) => <DatePickerHeader yearRange={yearRange} {...dateHeaderProps} />}
        disabled={props.disabled}
        dateFormat={dateFormat}
        placeholderText={dateFormat.toLowerCase()}
        showTimeSelect={mode === 'datetime'}
        popperPlacement='auto'
        {...props}
      />
    </DatePickerBase>
  );
};
