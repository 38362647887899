import React from 'react';

import { RebrandFormControl } from './form-control-rebrand.styles';

export function FormControl(props) {
  const { gridColumns, className, multilineLabel } = props;
  return (
    <RebrandFormControl
      {...props}
      $gridColumns={gridColumns}
      $multilineLabel={multilineLabel}
      className={`form-control ${className}`}
    />
  );
}
