import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';
import { Select } from 'components/inputs';
import { Popup } from 'components/popups';
import { Button } from 'components/buttons';
import { useCheckPreorderEligible } from 'util/hooks';
import { showCreatePreOrderFromProfilePopup } from 'store/actions/PopupsActions';
import { useCaregivers } from './useCaregivers';

import type { State } from 'store';
import type { Caregiver } from 'queries/v2/guest/types';
import type { callback1 } from 'models/Misc';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type CheckInCaregiverOptions = {
  andEnterCart?: boolean;
  andEnterPreOrderCart?: boolean;
  caregiverId?: string;
};

type CheckInCaregiverPopupProps = {
  isVisible: boolean;
  hide: () => void;
  guestId: number;
  onCheckin: callback1<CheckInCaregiverOptions>;
  loading?: boolean;
};

export const CheckinCaregiverPopup: FC<CheckInCaregiverPopupProps> = ({
  onCheckin,
  guestId,
  isVisible,
  hide,
  loading = false,
}) => {
  const dispatch = useDispatch();
  const [selectedCaregiver, setSelectedCaregiver] = useState<Caregiver>();
  const preorderSettings = useSelector((state: State) => state.settings.preorderSettings);
  const checkPreorderEligible = useCheckPreorderEligible();
  const caregivers = useCaregivers(guestId);

  const onSelectCaregiver = (caregiverId: string) => {
    const caregiver = caregivers.filter((x) => x.caregiverId === parseInt(caregiverId));
    if (caregiver) {
      setSelectedCaregiver(caregiver[0]);
    }
  };

  const handleCheckIn = (options?: CheckInCaregiverOptions) => {
    const { andEnterCart = false, andEnterPreOrderCart = false } = options ?? {};
    onCheckin({ andEnterCart, andEnterPreOrderCart, caregiverId: selectedCaregiver?.caregiverMJStateIdNo });
    hide();
  };

  const { isModalBridgeEnabled } = useModalBridge();

  const ButtonToUse = isModalBridgeEnabled ? Button : StyledDutchieButton;

  return (
    <Popup isVisible={isVisible} hide={hide} caption={`Select Caregiver`}>
      <CheckInCaregiverContainer isModalBridgeEnabled={isModalBridgeEnabled}>
        <CheckinCaregiverSelect
          defaultValue={selectedCaregiver?.caregiverId || ''}
          placeholder={'Select one'}
          options={caregivers.map((caregiver) => ({
            label: caregiver.caregiverName,
            value: String(caregiver.caregiverId),
          }))}
          onChange={(c) => onSelectCaregiver(c)}
        />
        <Buttons isModalBridgeEnabled={isModalBridgeEnabled}>
          {preorderSettings?.UseFulfillmentModel && (
            <ButtonToUse
              type='button'
              onClick={() => {
                dispatch(
                  showCreatePreOrderFromProfilePopup({
                    onCheckIn: () => handleCheckIn({ andEnterPreOrderCart: true }),
                  })
                );
              }}
              disabled={!selectedCaregiver || loading || !checkPreorderEligible(guestId)}
            >
              Create Pre-Order
            </ButtonToUse>
          )}
          <ButtonToUse
            type='button'
            onClick={() => handleCheckIn({ andEnterCart: true })}
            disabled={!selectedCaregiver || loading}
          >
            Create Order
          </ButtonToUse>
          <ButtonToUse type='button' onClick={() => handleCheckIn()} disabled={!selectedCaregiver || loading}>
            Check In
          </ButtonToUse>
        </Buttons>
      </CheckInCaregiverContainer>
    </Popup>
  );
};

const CheckInCaregiverContainer = styled.div<{ isModalBridgeEnabled?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isModalBridgeEnabled }) => (isModalBridgeEnabled ? '100%' : '31.25rem')};
`;

const CheckinCaregiverSelect = styled(Select)`
  margin: 0 32px;
`;

const Buttons = styled.div<{ isModalBridgeEnabled?: boolean }>`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
  box-shadow: 0 -1px 0 ${colors.dutchie.shadowGrey};

  ${({ isModalBridgeEnabled }) =>
    isModalBridgeEnabled &&
    css`
      justify-content: unset;
      gap: 0.75rem;

      & > button {
        flex: 1;
      }
    `}
`;

const StyledDutchieButton = styled(Button)`
  margin-left: 12px;
`;
