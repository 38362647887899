import React, { FC } from 'react';

import { StyledTable, StyledHeaderRow, StyledCell } from 'components/tables';
import { PackageRow } from '../PackageRow';

import type { ProductSearchResult } from 'queries/v2/product/types';

type SelectedPreOrderPackagesType = ProductSearchResult & {
  quantity: number;
  totalCost: number;
};

type PackagesTableProps = {
  products: ProductSearchResult[] | undefined;
  selectedPackages?: SelectedPreOrderPackagesType[];
  setSelectedPackages?: (packages: SelectedPreOrderPackagesType[]) => void;
  useRecPrice: boolean;
};

export const PackagesTable: FC<PackagesTableProps> = ({
  products,
  selectedPackages,
  setSelectedPackages,
  useRecPrice,
}) => {
  return (
    <StyledTable>
      <StyledHeaderRow>
        <StyledCell>Packages</StyledCell>
        <StyledCell>Qty</StyledCell>
        <StyledCell>Price</StyledCell>
        <StyledCell></StyledCell>
      </StyledHeaderRow>
      {products &&
        products.map((product) => (
          <PackageRow
            product={product}
            selectedPackages={selectedPackages}
            setSelectedPackages={setSelectedPackages}
            useRecPrice={useRecPrice}
          />
        ))}
    </StyledTable>
  );
};
