import React, { FC, useState } from 'react';
import { ConfirmationPopup } from 'components/popups';
import { Input } from 'components/inputs';
import { useDispatch } from 'react-redux';
import { callback } from 'models/Misc';
import styled from 'styled-components';
import { verifyCart } from 'api/CheckoutApi';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';

const Container = styled.div`
  padding: 0 1.5rem;
`;

type VerifyTransactionPopupProps = {
  hide: callback;
  shipmentId: number;
  guestId: number;
};

export const VerifyTransactionPopup: FC<VerifyTransactionPopupProps> = ({ hide, shipmentId, guestId }) => {
  const dispatch = useDispatch();
  const refetchCartDetails = useRefetchCartDetails();
  const [pin, setPin] = useState('');
  const [pinWrong, setPinWrong] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!guestId || !shipmentId) {
    return null;
  }

  const verifyTransaction = async () => {
    try {
      const pinParsed = parseInt(pin);
      setLoading(true);
      await verifyCart({ PinCode: pinParsed, ShipmentId: shipmentId });
      hide();
      dispatch(successNotification('Transaction verified'));
    } catch (error) {
      setPinWrong(true);
      dispatch(errorNotification(error ? `${error}` : 'There was an error verifying the cart'));
    } finally {
      setLoading(false);
    }

    await refetchCartDetails({ guestId, shipmentId });
  };

  const onPinChange = (pin: string) => {
    pinWrong && setPinWrong(false);
    setPin(pin);
  };

  return (
    <ConfirmationPopup
      isVisible
      title='Enter Pharmacist PIN'
      hide={hide}
      confirm={{
        text: 'Verify',
        loading: loading,
        disabled: loading || !pin,
        onClick: () => {
          verifyTransaction();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      <Container data-testid='verify-transaction_container'>
        <form
          //@ts-ignore: This is a magic attribute to disble autofill,
          //typescript doesn't know about it, we need this or it will autocomplete your username
          //into the product search bar and search for a package with your
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault();
            verifyTransaction();
          }}
        >
          <Input
            data-testid='verify-transaction_styled-input'
            value={pin}
            hasError={pinWrong}
            onChange={(e) => {
              //remote non-numbers since pins are numbers only
              const cleaned = e.target.value.replace(/\D/g, '');
              onPinChange(cleaned);
            }}
            placeholder='Pharmacist PIN'
            type='password'
            autoComplete='off'
          />
        </form>
      </Container>
    </ConfirmationPopup>
  );
};
