import type { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';
import { BaseCustomEventData } from './customEventKeys';

export enum LoggingLevel {
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export type LoggingProviderInitProps = {
  turnOnForDevelopment?: boolean;
};

export type UserData = Record<string, string | number | boolean>;

export abstract class LoggingProvider {
  public providerId = 'provider_id';

  // Implement any needed initialization steps for providers
  public init = (_props?: LoggingProviderInitProps) => {
    return;
  };

  // Implement to use a Redux middleware for logging to providers
  public getMiddleware = (): Middleware => {
    return (_store: unknown) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
      // Add optional Redux middleware steps here
      return next(action);
    };
  };

  // Implement for providers that use global context properties
  public setGlobalProperty = (_key: string, _value: unknown) => {
    return;
  };

  // Implement the setUser method for providers that use user data
  public setUser = (_userId: string, _userData?: UserData) => {
    return;
  };

  // Implement the track method for providers that use custom event tracking
  public track = <CustomEventData extends BaseCustomEventData>(_customEventDescription: string, _data?: CustomEventData & { level?: LoggingLevel }) => {
    return;
  };

  // Implement the error method to log errors to providers
  public error = (_e: unknown, _data?: object) => {
    return;
  };
}
