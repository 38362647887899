import React, { FC } from 'react';
import { AndroidCfdListener, AndroidCfdNotificationListener, WebCfdListener } from 'util/hooks';

type ProvideCFDListenersProps = {
  path: string;
};

export const ProvideCFDListeners: FC<ProvideCFDListenersProps> = ({ path, children }) => {
  return (
    <>
      <WebCfdListener path={path} />
      <AndroidCfdListener path={path} />
      <AndroidCfdNotificationListener />
      {children}
    </>
  );
};
