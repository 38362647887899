import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import { headerHeight } from 'css/Theme';
import {
  setDutchiePayPreAuthError,
  setIsFalseNegativePinDebitError,
  showProductHistory,
  setBlockScans,
  setIsCartInitialized,
} from 'store/actions/CartActions';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch, useAppSelector, useDynamicViewHeight, usePatientPolicyValidator } from 'util/hooks';
import { customEventKeys, logger } from 'util/logger';
import { useAllotmentChecks } from './hooks/useAllotmentChecks';
import { useAnonymousCart } from './hooks/useAnonymousCart';
import { useAssignGuestToRegisterMutation } from 'queries/v2/guest/select-guest-to-register';
import { useGetCartDetailsQuery } from 'queries/v2/cart/load';
import { useTransactionManager } from './hooks/useTransactionManager';
import { useQueryClient } from '@tanstack/react-query';
import { getAllProductsKeys } from 'queries/v2/product/query-key-factory';
import { customerSearchKeys } from 'queries/v2/guest/query-key-factory';
import { useMetrics } from 'util/metrics/useMetrics';
import { getCartDetailsKeys } from 'queries/v2/cart/query-key-factory';
import { useResetHubCartDisplayOnNavigation } from 'util/hooks/useResetHubCartDisplayOnNavigation';

/** This is the main hook used to initialize and load the cart page */
export const useCartPage = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const patientPolicyValidator = usePatientPolicyValidator();
  const metrics = useMetrics();

  // Redux state
  const guest = useAppSelector((state) => state.customer.details);
  const isCartInitialized = useAppSelector((state) => state.cart.isInitialized);
  const isCheckoutRunning = useAppSelector((state) => state.checkout.running);

  // Local state
  const [isAddItemsPanelOpen, setIsAddItemsPanelOpen] = useState(false);
  const [isReadyToLoadCart, setIsReadyToLoadCart] = useState(false);

  // Transaction state
  const { guestId, shipmentId, startTransaction, clearTransaction } = useTransactionManager();

  // React Query
  const {
    data: cart,
    isError: wasErrorLoadingCart,
    isLoading: isCartLoading,
  } = useGetCartDetailsQuery(
    {
      guestId: guestId ?? 0,
      shipmentId: shipmentId ?? 0,
    },
    {
      enabled: isReadyToLoadCart,
    }
  );

  const { mutate: assignGuestToRegister } = useAssignGuestToRegisterMutation();

  // Hooks
  const { areAnonymousTransactionsEnabled } = useAnonymousCart();
  const { validateAllotment } = useAllotmentChecks(cart);

  // Resets hub device ui to idle state and attempts to cancel the order when leaving cart screen.
  useResetHubCartDisplayOnNavigation();

  // Handlers

  const handleCloseAddItemsPanel = useCallback(() => {
    setIsAddItemsPanelOpen(false);
  }, []);

  // Computed values
  const extendedCartPanelHeight = useDynamicViewHeight(headerHeight, 'svh');
  const shouldShowLoadingState = isCartLoading || (!!guestId && !!shipmentId && !isReadyToLoadCart);

  /* Business logic */

  // Reset the initialized state when the page loads
  useEffect(() => {
    dispatch(setIsCartInitialized(false));
  }, [dispatch]);

  // If the add items panel is opened and transaction hasn't started, create a new one
  useEffect(() => {
    isAddItemsPanelOpen && startTransaction();
  }, [startTransaction, isAddItemsPanelOpen]);

  // Initialize the cart (only runs once each time guestId or shipmentId changes)
  useEffect(() => {
    if (guestId && shipmentId) {
      // Mark the transaction as started
      startTransaction();
      // Assign the guest to the register
      assignGuestToRegister(
        { guestId, shipmentId },
        {
          onSuccess: () => {
            // Need to clear the cache so we refetch at least once when we load the cart
            queryClient.invalidateQueries(
              getCartDetailsKeys.one({ guestId: guestId ?? 0, shipmentId: shipmentId ?? 0 })
            );
            setIsReadyToLoadCart(true);
          },
          onError: () => {
            dispatch(errorNotification('Unable to assign guest to register. Refresh the page to try again'));
          },
        }
      );
    } else {
      clearTransaction();
    }

    // Update Redux dependencies
    dispatch(showProductHistory(false));
    dispatch(setDutchiePayPreAuthError(false));
    dispatch(setIsFalseNegativePinDebitError(false));
    dispatch(setBlockScans(false));
    queryClient.invalidateQueries(getAllProductsKeys.all);
    queryClient.invalidateQueries(customerSearchKeys.all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestId, shipmentId]);

  // If an error occurs loading the cart, reset the cart and clear the search params
  useEffect(() => {
    if (isCartInitialized && wasErrorLoadingCart) {
      if (areAnonymousTransactionsEnabled) {
        clearTransaction();
        setIsReadyToLoadCart(false);
      } else {
        history.push('/guestlist');
      }
    }
  }, [clearTransaction, history, areAnonymousTransactionsEnabled, isCartInitialized, wasErrorLoadingCart]);

  // Validate allotment and days supply
  useEffect(() => {
    // Make sure the guest has loaded before validating allotment
    if (!guest) {
      return;
    }
    validateAllotment();
  }, [guest, validateAllotment]);

  // Validate the patient policy
  useEffect(() => {
    if (!guest) {
      return;
    }

    patientPolicyValidator(guest).then((isValid) => {
      if (!isValid) {
        dispatch(warningNotification('Customer cannot checkout until policy is signed'));
      }
    });
  }, [guest, dispatch, patientPolicyValidator]);

  useEffect(() => {
    // Make sure this is an anonymous cart with no guest created yet
    if (!guestId) {
      // Check if a timestamp exists for the anonymous guest button / cart navigation button
      const hasAnonGuestButtonTimestamp =
        metrics.lookup(customEventKeys.metrics.timestamps.clickAnonymousGuestButton) !== undefined;
      // Add a log marking how long it took for the cart to load from either the anon guest button or done button
      metrics.addDuration(customEventKeys.metrics.durations.anonymousCartLoaded, {
        timestampKey: hasAnonGuestButtonTimestamp
          ? customEventKeys.metrics.timestamps.clickAnonymousGuestButton
          : customEventKeys.metrics.timestamps.clickTransactionDoneButton,
        // Details will always be undefined for anon cart workflow, but track it anyway for consistency
        logData: { anonGuestId: undefined, shipmentId: undefined },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Log the cart load event
  useEffect(() => {
    if (guest && cart) {
      logger.info(`cart loaded for guest ${guest?.FullName ?? guestId}`, {
        key: customEventKeys.cart.loaded,
        shipmentId,
        guestId,
        guestName: guest?.FullName,
        totalItems: cart.totalItems,
        orderTotal: cart.grandTotalRounded,
        isAnonymous: guest?.IsAnonymous,
        isMedical: guest?.IsMedical,
      });
    }
    // This useEffect is only used for logging, so we can be super specific about when we want to trigger it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guest, cart]);

  return {
    extendedCartPanelHeight,
    handleCloseAddItemsPanel,
    isAddItemsPanelOpen,
    isCartLoading: shouldShowLoadingState,
    isCartLocked: cart?.isCartLocked ?? false,
    isCheckoutRunning,
    setIsAddItemsPanelOpen,
  };
};
