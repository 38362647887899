import React, { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { colors } from 'css/Theme';
import { State } from 'store';
import { handleLogout, SelectLsp } from 'store/actions/UserActions';
import { curriedSelectLocation } from 'store/actions/SettingsActions';
import { Select } from 'components/inputs';
import { Button } from 'components/buttons';
import { UnauthorizedPageLayout } from 'components/layout';

const StyledSelect = styled(Select)`
  margin-top: 0.625rem;
  width: 100%;
  margin-bottom: 1.375rem;
`;

const Caption = styled.p`
  line-height: 1.25rem;
  color: ${colors.dutchie.almostBlack};
  letter-spacing: 0.005em;
`;

export const LocationSelectionPage: FC = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: State) => state.user);
  const [selectedLsp, setSelectedLsp] = useState<number | undefined>(userInfo.selectedLsp?.LspId);

  const formatLocationOptions = useCallback(() => {
    return userInfo.locations
      .filter((location) => {
        return location.registersAvailable;
      })
      .map((item) => ({ value: item.location_id, label: item.location_name }));
  }, [userInfo]);

  useEffect(() => {
    setLocationOptions(formatLocationOptions());
  }, [userInfo.locations, formatLocationOptions]);

  const [selectedLocation, setSelectedLocation] = useState<number | undefined>();
  const [locationOptions, setLocationOptions] = useState(formatLocationOptions());
  const history = useHistory();

  const lspOptions = userInfo.lsps.map((item) => ({ value: item.LspId, label: item.LspName }));

  const handleCompanyChange = (value: string) => {
    const lsp = userInfo.lsps.find((item) => item.LspId === parseInt(value));
    if (lsp) {
      setSelectedLsp(parseInt(value, 10));
      dispatch(SelectLsp(lsp));
      setSelectedLocation(undefined);
    }
  };

  const handleLocationChange = (value: string) => setSelectedLocation(parseInt(value, 10));

  const handleSelectClick = () => {
    const location = userInfo.locations.find((item) => item.location_id === selectedLocation);
    if (location) {
      dispatch(curriedSelectLocation({ location: location }));
    }
  };

  const handleLogoutClick = () => dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));

  return (
    <UnauthorizedPageLayout>
      <Caption>Select your company</Caption>
      <StyledSelect
        defaultValue={selectedLsp}
        placeholder='Choose a Company'
        onChange={handleCompanyChange}
        options={lspOptions}
        automationId='location-selection-page_select_company'
      />
      {!isEmpty(locationOptions) ? (
        <div>
          <Caption>Select your location</Caption>
          <StyledSelect
            defaultValue={selectedLocation}
            placeholder='Choose a Location'
            onChange={handleLocationChange}
            options={locationOptions}
            automationId='location-selection-page_select_location'
          />
          <Button onClick={handleSelectClick} automationId='location-selection-page_button_select-location'>
            Select
          </Button>
        </div>
      ) : (
        <>
          <Caption>You do not have permissions for POS</Caption>
          <Button
            marginTop={12}
            secondary
            onClick={handleLogoutClick}
            automationId='location-selection-page_button_logout'
          >
            Logout
          </Button>
        </>
      )}
    </UnauthorizedPageLayout>
  );
};
