import React, { FC, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getAllProductsKeys } from 'queries/v2/product/query-key-factory';
import { store } from 'store';
import { callback } from 'models/Misc';
import { clearCart } from 'store/actions/CartActions';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import { ConfirmationPopup } from 'components/popups';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

const StyledPopupBody = styled.div`
  display: flex;
  justify-content: center;
`;

type ClearCartPopupProps = {
  hide: callback;
};

export const ClearCartPopup: FC<ClearCartPopupProps> = ({ hide }) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const refetchCartDetails = useRefetchCartDetails();
  const { guestId, shipmentId } = useTransactionManager();

  const handleClearCartClick = async () => {
    if (guestId && shipmentId) {
      setLoading(true);
      await dispatch(
        clearCart({
          ShipmentId: shipmentId,
        })
      );

      await refetchCartDetails({
        guestId,
        shipmentId,
      });

      await dispatch(getCustomerDetails({ guestId }));
      // Invalidate the products query so that the cart items are updated
      queryClient.invalidateQueries(getAllProductsKeys.all);
      setLoading(false);
      hide();
    }
  };
  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      data-testid='clear-cart-popup_clear-cart-style_yes-button'
      title='Clear Cart'
      confirm={{
        text: 'Confirm',
        loading,
        onClick: () => {
          handleClearCartClick();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <StyledPopupBody>Are you sure you want to clear the cart?</StyledPopupBody>
    </ConfirmationPopup>
  );
};
