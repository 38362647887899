import wretch from 'wretch';
import { get } from 'lodash';
import LogRocket from 'logrocket';

import { Http } from '@capacitor-community/http';
import { Network } from '@capacitor/network';

import { getIsFallbackErrorOnPostEnabled } from 'util/hooks/launch-darkly/getIsFallbackErrorOnPostEnabled';

let baseUrl = '/api/';

export const getErrorMessage = (error: unknown) => {
  const isFallbackErrorOnPostEnabled = getIsFallbackErrorOnPostEnabled();
  if (!isFallbackErrorOnPostEnabled) {
    return error;
  }

  if (typeof error !== 'string') {
    return error;
  }

  // If error is more than 400 characters or looks like HTML, return a generic error message
  if (error.length > 400 || error.includes('<!DOCTYPE html>')) {
    return 'An error occurred. Please try again.';
  }

  return error;
};

export const combineUrl = (base: string, url: string): string => {
  let baseUrl = base;
  let route = url;
  if (baseUrl && baseUrl.length > 0 && baseUrl.charAt(baseUrl.length - 1) !== '/') {
    baseUrl = `${baseUrl}/`;
  }

  if (route && route.length > 0 && route.charAt(0) === '/') {
    route = route.substring(1);
  }

  return `${baseUrl}${route}`;
};

export const getBaseUrl = () => baseUrl;
export const setBaseUrl = (newUrl: string) => (baseUrl = newUrl);
export const externalApi = () => wretch().url(baseUrl).options({ credentials: 'include', mode: 'cors' });

export type HttpOptions = {
  successMessage?: string;
  errorMessage?: string;
  integrationErrorMessage?: boolean;
  showError?: boolean;
  ignoreFormat?: boolean;
  showServerSuccess?: boolean;
};

export const createAuthBody = (body: object = {}, Guest_id?: number) => {
  const authBody: { [k: string]: string | number | undefined } = {
    ...body,
    SessionId: window.sessionStorage.getItem('SessionGId') || '',
    LspId: window.sessionStorage.getItem('LspId') || 0,
    LocId: window.sessionStorage.getItem('LocId') || 0,
    OrgId: window.sessionStorage.getItem('OrgId') || 0,
  };
  if (!Guest_id) {
    authBody.UserId = get(body, 'UserId') || window.sessionStorage.getItem('UserId');
  } else {
    authBody.Guest_id = Guest_id;
  }
  return authBody;
};

const getAppHeaders = () => {
  const dutchieRegisterEnabled = sessionStorage.getItem('dutchieRegisterEnabled') === '1';
  const result: Record<string, string> = { AppName: dutchieRegisterEnabled ? 'DutchiePOS' : 'POS4' };

  const version = process.env.REACT_APP_VERSION_NUMBER;
  if (version) {
    result.AppVersion = version;
  }

  const logRocketSessionUrl = LogRocket.sessionURL;
  if (logRocketSessionUrl) {
    result['X-LogRocket-URL'] = logRocketSessionUrl;
  }

  return result;
};

export const post = async <T>(
  url: string,
  body?: object,
  options: HttpOptions = { integrationErrorMessage: true }
): Promise<T> => {
  return Http.post({
    url: combineUrl(baseUrl, url),
    headers: { 'content-type': 'application/json', ...getAppHeaders() },
    data: createAuthBody(body),
    webFetchExtra: {
      credentials: 'include',
    },
  })
    .then((resp) => {
      const result = resp.data;
      if (options?.ignoreFormat) {
        return result;
      } else {
        return result.Result
          ? result.Data
          : Promise.reject(getErrorMessage(
            `${result.Message} ${
                result.IntegrationMessage && options?.integrationErrorMessage ? result.IntegrationMessage : ''
              }`
            ));
      }
    })
    .catch(async (error) => {
      const connectionStataus = await Network.getStatus();
      if (!connectionStataus?.connected) {
        return Promise.reject('No internet connection found.');
      }

      if (error?.message) {
        const errorObject = JSON.parse(error.message);
        return errorObject.IntegrationMessage
          ? Promise.reject(getErrorMessage(`${errorObject.Message} ${errorObject.IntegrationMessage}`))
          : Promise.reject(getErrorMessage(errorObject.Message));
      }

      return Promise.reject(getErrorMessage(error));
    });
};

export const getReq = async <T>(url: string, options: HttpOptions = {}): Promise<T> => {
  return Http.get({
    url: combineUrl(baseUrl, url),
    headers: { 'Content-Type': 'application/json', ...getAppHeaders() },
    webFetchExtra: {
      credentials: 'include',
    },
  })
    .then((resp) => {
      const result = resp.data;
      if (options?.ignoreFormat) {
        return result;
      } else {
        return result?.Result ? result?.Data : Promise.reject(result?.Message);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
