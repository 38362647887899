import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, every } from 'lodash';

import { getFilterOptions, getProductsToDisplay } from './helpers';
import { potencyUnitIdToDisplayNameMap, sortByCBD, sortByTHC } from './helpers/sortByPotency/sortByPotency';
import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';
import { useGetInventoryTagsForProductSearchResultsQuery } from 'queries/v2/product/get-inventory-tags-for-product-search-results';
import { useProductSearchPricing } from 'util/hooks/useProductSearchPricing';
import { warningNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { useRefetchProductsAfterEmptySearchResults } from 'util/hooks/launch-darkly/useRefetchProductsAfterEmptySearchResults';
import { useRefetchProductsOnFilter } from 'util/hooks/launch-darkly/useRefetchProductsOnFilter';
import { useInventoryTagFilteringInCart } from 'util/hooks/launch-darkly/useInventoryTagFilteringInCart';

import type { Column } from 'components/tables';
import type { ProductSearchFilterProps } from './ProductSearchFilter';
import type { ProductSearchResult, ProductFilterOption, InventoryTag } from 'queries/v2/product/types';
import type { State } from 'store';

export const useProductSearchFilter = ({
  waitForCustomerToLoad,
}: Pick<ProductSearchFilterProps, 'waitForCustomerToLoad'>) => {
  const dispatch = useDispatch();

  // Global state
  const customerId = useSelector((state: State) => state.customer.details?.Guest_id);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const isScanInProgress = useSelector((state: State) => state.cartItems.scanning);
  const isShowSearchBarEnabled = useSelector((state: State) => state.settings.features.ShowSearchBar);

  // Local state
  const [filterSelections, setFilterSelections] = useState<ProductFilterOption[]>([]);
  const [productSearchInput, setProductSearchInput] = useState('');
  const [searchString, setSearchString] = useState('');
  const [productsWereFetchedAfterEmptySearch, setProductsWereFetchedAfterEmptySearch] = useState(false);

  const { isRefetchProductsAfterEmptySearchResultsEnabled } = useRefetchProductsAfterEmptySearchResults();
  const { isRefetchProductsOnFilterEnabled } = useRefetchProductsOnFilter();
  const { isInventoryTagFilteringInCartEnabled } = useInventoryTagFilteringInCart();

  // Products, inventory tags, and filters
  const productsQueryPayload = useMemo(
    () => ({ registerId, customerId: waitForCustomerToLoad ? customerId : undefined }),
    [customerId, registerId, waitForCustomerToLoad]
  );

  const productsQueryOptions = useMemo(
    () => ({ enabled: waitForCustomerToLoad ? !!customerId : true }),
    [customerId, waitForCustomerToLoad]
  );

  const {
    data: allProducts,
    error,
    isError: wasErrorLoadingProducts,
    isLoading: areProductsLoading,
    isSuccess: areProductsLoaded,
    refetch: refetchProducts,
  } = useGetAllProductsQuery(productsQueryPayload, productsQueryOptions);

  const packageIds = isInventoryTagFilteringInCartEnabled
    ? allProducts?.map((product) => product.packageId ?? -1)?.filter((id) => id > 0) ?? []
    : [];

  const { data: inventoryTags, isLoading: areInventoryTagsLoading } = useGetInventoryTagsForProductSearchResultsQuery<
    InventoryTag[]
  >({ packageIds }, { enabled: isInventoryTagFilteringInCartEnabled && !!packageIds?.length });

  useEffect(() => {
    if (wasErrorLoadingProducts) {
      dispatch(warningNotification('Error attempting to load inventory. Current products displayed may be outdated.'));
      logger.error(error, { message: 'Error loading product search results' });
    }
  }, [areProductsLoaded, wasErrorLoadingProducts, error, allProducts, dispatch]);

  const allProductsWithInventoryTags = useMemo(() => {
    if (!isInventoryTagFilteringInCartEnabled) {
      return [];
    }

    if (inventoryTags?.length && allProducts?.length) {
      const allProductsCopy = [...allProducts];
      allProductsCopy?.forEach((product) => {
        product.inventoryTags = inventoryTags?.filter((inventoryTag) => inventoryTag.packageId === product.packageId);
      });
      return allProductsCopy;
    }

    return [];
  }, [allProducts, inventoryTags, isInventoryTagFilteringInCartEnabled]);

  const displayProducts = useMemo(() => {
    if (isInventoryTagFilteringInCartEnabled && !filterSelections?.length && !searchString.length) {
      return allProducts?.length ? allProducts : [];
    }

    if (allProductsWithInventoryTags?.length) {
      return getProductsToDisplay(allProductsWithInventoryTags, filterSelections, searchString);
    }

    return getProductsToDisplay(allProducts, filterSelections, searchString);
  }, [allProducts, allProductsWithInventoryTags, filterSelections, isInventoryTagFilteringInCartEnabled, searchString]);

  const filterOptions = useMemo(() => {
    if (isInventoryTagFilteringInCartEnabled && !!allProductsWithInventoryTags.length) {
      return getFilterOptions({ products: allProductsWithInventoryTags });
    }
    return getFilterOptions({ products: allProducts });
  }, [allProducts, allProductsWithInventoryTags, isInventoryTagFilteringInCartEnabled]);

  // Computed values

  const noInventoryFound = allProducts?.length === 0 && !searchString.length;
  /** @deprecated clean up with pos.register.refetch-products-on-filter.rollback */
  const noMatchingSearchResults = displayProducts.length === 0 && searchString.length > 0;
  const noMatchingProducts = displayProducts.length === 0 && (searchString.length > 0 || filterSelections.length > 0);

  const selectedFilterCount = filterSelections.reduce(
    (totalSelectedFilters, { options: currOptions }) => totalSelectedFilters + currOptions?.length,
    0
  );

  const showLoadingSkeleton = isScanInProgress || areProductsLoading;

  useEffect(() => {
    if (!isRefetchProductsAfterEmptySearchResultsEnabled) {
      return;
    }

    const shouldRefetchProducts = isRefetchProductsOnFilterEnabled ? noMatchingProducts : noMatchingSearchResults;

    if (!productsWereFetchedAfterEmptySearch && shouldRefetchProducts) {
      refetchProducts();
      setProductsWereFetchedAfterEmptySearch(true);
    }
  }, [
    isRefetchProductsAfterEmptySearchResultsEnabled,
    isRefetchProductsOnFilterEnabled,
    noMatchingProducts,
    noMatchingSearchResults,
    productsWereFetchedAfterEmptySearch,
    refetchProducts,
  ]);

  // Products table

  const { getNewPriceField, getPrice } = useProductSearchPricing();

  const quantityOrGrams = (product: ProductSearchResult): string => {
    switch (product.productType) {
      case 'Qty':
        return `${product.totalAvailable}`;
      case 'Wgt':
        return `${product.totalGrams}g`;
      default:
        return 'unknown';
    }
  };

  const tableColumns: Column<ProductSearchResult>[] = [
    {
      header: 'Product',
      field: (v: ProductSearchResult) => `${v.productDescription.slice(0, -1)} - ${v.serialNo.trim().slice(-4)}`,
    },
    { header: 'Category', field: 'masterCategory' },
    { header: 'Subcategory', field: 'productCategory' },
    {
      header: 'THC',
      field: 'thcContent',
      Cell: ({ item }) => {
        if (typeof item.thcContent !== 'number' || typeof item.thcContentUnitId !== 'number') {
          return <>{'-'}</>;
        }
        return (
          <>{`${item.thcContent}${
            potencyUnitIdToDisplayNameMap[item.thcContentUnitId as keyof typeof potencyUnitIdToDisplayNameMap]
          }`}</>
        );
      },
      customSort: sortByTHC,
    },
    {
      header: 'CBD',
      field: 'cbdContent',
      Cell: ({ item }) => {
        if (typeof item.cbdContent !== 'number' || typeof item.cbdContentUnitId !== 'number') {
          return <>{'-'}</>;
        }
        return (
          <>{`${item.cbdContent}${
            potencyUnitIdToDisplayNameMap[item.cbdContentUnitId as keyof typeof potencyUnitIdToDisplayNameMap]
          }`}</>
        );
      },
      customSort: sortByCBD,
    },
    { header: 'Quantity', field: quantityOrGrams },
    {
      header: 'Price',
      field: getNewPriceField(),
      Cell: ({ item }) => {
        const price = getPrice(item);
        return price ? <>${price.toFixed(2)}</> : <>$0.00</>;
      },
    },
  ];

  // Handlers

  const search = useCallback(
    debounce(async (newSearchString: string) => {
      setSearchString(newSearchString);
    }, 300),
    []
  );

  const onChangeProductSearch = useCallback(
    (inputValue: string) => {
      search(inputValue);
      setProductSearchInput(inputValue);
    },
    [search]
  );

  const onSelectFilters = useCallback((filters: ProductFilterOption[]) => {
    const filtersAreEmpty = every(filters, (filter) => filter.options.length === 0);
    setFilterSelections(filtersAreEmpty ? [] : filters);
  }, []);

  const onResetFilters = useCallback(() => {
    setFilterSelections([]);
  }, []);

  return {
    areInventoryTagsLoading,
    displayProducts,
    isShowSearchBarEnabled,
    filterOptions,
    filterSelections,
    noInventoryFound,
    noMatchingProducts,
    onChangeProductSearch,
    onSelectFilters,
    onResetFilters,
    productSearchInput,
    refetchProducts,
    selectedFilterCount,
    showLoadingSkeleton,
    tableColumns,
    wasErrorLoadingProducts,
  };
};
