import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as CheckoutApi from 'api/CheckoutApi';
import * as DeliveryApi from 'api/DeliveryApi';
import { post } from 'api/HttpHelpers';
import { deliveryProviderToString } from 'api/SettingsApi';
import { ReadyForDeliveryResponse } from 'models/Checkout';
import { Delivery, DeliveryFilterOption, UpdateRoute } from 'models/Delivery';
import { DeliveryRoute } from 'models/DeliveryRoute';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { State } from 'store';
import * as React from 'react';
import { TimeWindow } from 'store/reducers/DeliveryReducer';

export const openPDFInWindow = (data: string, fileName: string) => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' }));
  link.download = fileName;
  link.target = '_blank';
  link.click();
};

export const toggleSelectedDelivery = createAction<Array<Delivery>>('toggleSelectedDelivery');
export const selectDeliveries = createAction<Array<Delivery>>('selectDeliveries');

export const applyDeliveryFilters =
  createAction<{ filters: Array<DeliveryFilterOption>; timeWindow?: TimeWindow }>('applyDeliveryFilters');

export const getDeliveries = createAsyncThunk('getDeliveries', async (body: void, { getState, dispatch }) => {
  const { settings, deliveryList } = getState() as State;

  let fetchedDeliveries: Delivery[] = [];
  try {
    fetchedDeliveries = await DeliveryApi.getDeliveryOrders();
  } catch (ex) {
    dispatch(warningNotification(`Unable to load deliveries ${ex}`));
    fetchedDeliveries = deliveryList.deliveries;
  }

  let fetchedRoutes: DeliveryRoute[] = [];
  try {
    fetchedRoutes = await DeliveryApi.getDeliveryRoutes();
  } catch (ex) {
    dispatch(warningNotification(`Unable to load deliveries ${ex}`));
    fetchedRoutes = deliveryList.routes;
  }

  return { fetchedDeliveries, fetchedRoutes, settings };
});

export const getInitialDeliveries = createAsyncThunk(
  'getInitialDeliveries',
  async (body: void, { getState, dispatch }) => {
    const { settings, deliveryList } = getState() as State;

    let fetchedDeliveries: Delivery[] = [];
    try {
      fetchedDeliveries = await DeliveryApi.getDeliveryOrders();
    } catch (ex) {
      dispatch(warningNotification(`Unable to load deliveries ${ex}`));
      fetchedDeliveries = deliveryList.deliveries;
    }

    let fetchedRoutes: DeliveryRoute[] = [];
    try {
      fetchedRoutes = await DeliveryApi.getDeliveryRoutes();
    } catch (ex) {
      dispatch(warningNotification(`Unable to load deliveries ${ex}`));
      fetchedRoutes = deliveryList.routes;
    }

    return { fetchedDeliveries, fetchedRoutes, settings };
  }
);

export const selectRoute = createAsyncThunk(
  'selectRoute',
  async (DeliveryRouteId: number | undefined, { getState, dispatch }) => {
    if (!DeliveryRouteId) {
      return { route: undefined, routeDeliveries: [] };
    }

    const state = getState() as State;
    const route = state.deliveryList.routes.find((r) => r.DeliveryRouteId === DeliveryRouteId);

    let routeDeliveries: Delivery[] = [];
    try {
      routeDeliveries = await DeliveryApi.getRouteDeliveries({ DeliveryRouteId });
    } catch (ex) {
      dispatch(warningNotification(`Unable to load deliveries ${ex}`));
    }

    return { route, routeDeliveries };
  }
);

export const saveDeliveries = createAsyncThunk('saveDeliveries', async (args: UpdateRoute, { dispatch, getState }) => {
  const state = getState() as State;
  try {
    const result = await DeliveryApi.updateRoute(args);
    const tasksGeneratedClause = getTasksGeneratedClause(
      result.DeliverySystemTasksGenerated,
      result.DeliverySystemTasksUpdated,
      state.settings.locationSettings?.DeliveryProvider
    );
    dispatch(
      successNotification(
        args.CreateRoute
          ? `Route Created!${tasksGeneratedClause}`
          : `(${args.Shipments.length}) Delivery Orders Saved${tasksGeneratedClause}`
      )
    );

    for (const error of result.DeliverySystemErrors) {
      dispatch(errorNotification(`Error creating OnFleet task for shipment ${error.ShipmentId}: ${error.Error}`));
    }
    return result;
  } catch (e) {
    dispatch(errorNotification(`Unable to save route: ${e}`));
  }
});

export const getDeliveryImage = async (d: Delivery) => {
  return await DeliveryApi.getDeliveryImage({ ShipmentId: d.ShipmentId });
};

export const getDeliveryImageForShipment = async (args: { ShipmentId: number }) => {
  return await DeliveryApi.getDeliveryImage({ ShipmentId: args.ShipmentId });
};

export const checkoutDeliveries = createAsyncThunk(
  'checkoutDeliveries',
  async (Deliveries: Array<Delivery>, { dispatch, getState }) => {
    const state = getState() as State;
    let successItems = 0;
    for (const d of Deliveries) {
      try {
        const checkoutParams = {
          ShipmentId: d.ShipmentId,
          TerminalId: state.settings.selectedRegister?.value,
          ReactVersion: React.version,
        };

        await DeliveryApi.autoCheckoutDeliveries(checkoutParams);

        successItems++;
      } catch (ex) {
        dispatch(warningNotification(`Unable to checkout shipment: ${ex}`));
      }
    }

    if (successItems > 0) {
      dispatch(successNotification(`${successItems} of ${Deliveries.length} shipments checked out`));
    }
  }
);

export const completeDeliveries = createAsyncThunk(
  'completeDeliveries',
  async (Deliveries: Array<Delivery>, { dispatch }) => {
    let successItems = 0;
    for (const d of Deliveries) {
      try {
        await DeliveryApi.completeDeliveries({ ShipmentId: d.ShipmentId });
        successItems++;
      } catch (ex) {
        dispatch(warningNotification(`Unable to complete delivery: ${ex}`));
      }
    }

    if (successItems > 0) {
      dispatch(successNotification(`${successItems} of ${Deliveries.length} deliveries completed`));
    }
  }
);

export const cancelDeliveries = createAsyncThunk(
  'cancelDeliveries',
  async (Deliveries: Array<Delivery>, { dispatch }) => {
    let successItems = 0;
    for (const delivery of Deliveries) {
      try {
        await post<void>('v2/guest/cancel_checkin', {
          Guest_id: delivery.Guest_id,
          ShipmentId: delivery.ShipmentId,
          ScheduleId: delivery.ScheduleId,
          CancelReason: '',
        });
        successItems++;
      } catch {
        dispatch(errorNotification('Error canceling guest transaction'));
      }
    }
    if (successItems) {
      dispatch(successNotification(`${successItems} guest transactions canceled`));
    }
  }
);

export const endRoute = createAsyncThunk('endRoute', async (route: DeliveryRoute, { dispatch }) => {
  await DeliveryApi.endRoute(route)
    .then(() => {
      dispatch(successNotification(`Route Completed!`));
    })
    .catch((ex) => {
      dispatch(warningNotification(`Unable to end route: ${ex}`));
    });
});

export const setDeliveryRouteNote = createAsyncThunk(
  'endRoute',
  async (args: { route: DeliveryRoute; note: string }, { dispatch }) => {
    await DeliveryApi.setDeliveryRouteNote({
      DeliveryRouteId: args.route.DeliveryRouteId,
      Note: args.note,
    })
      .then(() => {
        dispatch(successNotification('Note saved!'));
      })
      .catch((ex) => {
        dispatch(warningNotification(`Unable to save note: ${ex}`));
      });
  }
);

export const cancelRoute = createAsyncThunk('cancelRoute', async (args: { route: DeliveryRoute }, { dispatch }) => {
  const routeCancelled = await DeliveryApi.cancelRoute(args.route);
  if (routeCancelled) {
    dispatch(successNotification('This route has been deleted.'));
  } else {
    dispatch(
      warningNotification(
        'This is a dynamic route and cannot be deleted until the vehicle goes offline in the Fleet tab.'
      )
    );
  }
});

export const startRoute = createAsyncThunk('startRoute', async (args: { route: DeliveryRoute }, { dispatch }) => {
  try {
    await DeliveryApi.startRoute({ DeliveryRouteId: args.route.DeliveryRouteId });
    dispatch(successNotification('This route has been started.'));
  } catch {
    dispatch(errorNotification('Failed to start route.'));
  }
});

export const suspendRoute = createAsyncThunk('suspendRoute', async (args: { route: DeliveryRoute }, { dispatch }) => {
  try {
    await DeliveryApi.suspendRoute(args.route);
    dispatch(successNotification('This route has been suspended.'));
  } catch {
    dispatch(errorNotification('Failed to suspend route.'));
  }
});

export const setMultipleReadyForDelivery = createAsyncThunk(
  'setMultipleReadyForDelivery',
  async (args: { routeId: number; shipmentIds: Array<number> }, { getState, dispatch }) => {
    const responses: Array<ReadyForDeliveryResponse> = [];
    const { settings } = getState() as State;
    let successItems = 0;

    await DeliveryApi.setRouteReadyForDelivery({ DeliveryRouteId: args.routeId });

    while (args.shipmentIds.length) {
      const res = await Promise.all(
        args.shipmentIds.splice(0, settings.locationSettings?.DeliveryConcurrency || 6).map(async (ShipmentId) => {
          try {
            responses.push(await CheckoutApi.setReadyForDelivery({ ShipmentId }));
            return 1;
          } catch (ex) {
            dispatch(errorNotification(`Error marking Order (${ShipmentId}) ready for delivery: ${ex}`));
            return 0;
          }
        })
      );
      successItems += res.reduce((acc, i) => acc + i, Number(0));
    }

    const numTasksGenerated = responses.filter((x) => x.DeliverySystemTaskGenerated).length;
    const numTasksUpdated = responses.filter((x) => x.DeliverySystemTaskUpdated).length;

    const ICTViolations = [
      ...new Set(responses.map((r) => r.DeliveryRoomThresholdViolations.MaxICTDollarRoomViolation)),
    ];
    ICTViolations.filter((v) => !!v).forEach((v) => {
      dispatch(warningNotification(`The value of unordered inventory in ${v} is above the defined threshold`));
    });

    const TotalInventoryViolations = [
      ...new Set(responses.map((r) => r.DeliveryRoomThresholdViolations.MaxTotalInventoryDollarRoomViolation)),
    ];
    TotalInventoryViolations.filter((v) => !!v).forEach((v) => {
      dispatch(
        warningNotification(`The value of inventory in ${v} may be violated when the driver picks up these deliveries`)
      );
    });

    const MaxInventoryWeightRoomViolation = [
      ...new Set(responses.map((r) => r.DeliveryRoomThresholdViolations.MaxInventoryWeightRoomViolation)),
    ];
    MaxInventoryWeightRoomViolation.filter((v) => !!v).forEach((v) => {
      dispatch(
        warningNotification(
          `The weight of inventory in ${v}  may be violated when the driver picks up these deliveries`
        )
      );
    });

    const MaxNonConcentrateWeightRoomViolation = [
      ...new Set(responses.map((r) => r.DeliveryRoomThresholdViolations.MaxNonConcentrateWeightRoomViolation)),
    ];
    MaxNonConcentrateWeightRoomViolation.filter((v) => !!v).forEach((v) => {
      dispatch(
        warningNotification(
          `The weight of concentrate in ${v} may be violated when the driver picks up these deliveries`
        )
      );
    });

    const MaxConcentrateWeightRoomViolation = [
      ...new Set(responses.map((r) => r.DeliveryRoomThresholdViolations.MaxConcentrateWeightRoomViolation)),
    ];
    MaxConcentrateWeightRoomViolation.filter((v) => !!v).forEach((v) => {
      dispatch(
        warningNotification(
          `The weight of non-concentrate in ${v} may be violated when the driver picks up these deliveries`
        )
      );
    });

    if (successItems > 0) {
      dispatch(
        successNotification(
          `(${successItems}) Delivery Orders Ready${getTasksGeneratedClause(
            numTasksGenerated,
            numTasksUpdated,
            settings.locationSettings?.DeliveryProvider
          )}`
        )
      );
    }
  }
);

const getTasksGeneratedClause = (numTasksGenerated: number, numTasksUpdated: number, deliveryProvider?: number) => {
  const generatedClause =
    numTasksGenerated > 0
      ? `\n(${numTasksGenerated}) ${deliveryProviderToString(deliveryProvider)} Tasks Generated`
      : '';

  const updatedClause =
    numTasksUpdated > 0 ? `\n(${numTasksUpdated}) ${deliveryProviderToString(deliveryProvider)} Tasks Updated` : '';

  return `${generatedClause}${updatedClause}`;
};

// compact with https://stackoverflow.com/questions/63564530/is-it-possible-to-call-a-reducer-function-from-another-reducer-function-within?
export const printMultipleManifests = createAsyncThunk(
  'printMultipleManifests',
  async (shipmentIds: Array<number>, { getState, dispatch }) => {
    dispatch(successNotification('Printing manifests...'));

    shipmentIds.forEach(async (receiptId) => {
      try {
        const data = await post<string>('v2/manifest/print-manifest', { PosId: receiptId });
        openPDFInWindow(data, `PrintedManifest-${receiptId}.pdf`);
        dispatch(successNotification('Manifest generated'));
        return data;
      } catch (e) {
        dispatch(errorNotification(`Error generating manifest: ${e}`));
      }
    });
  }
);

export const printMergedManifests = createAsyncThunk(
  'printMergedManifests',
  async (routeId: number, { getState, dispatch }) => {
    dispatch(successNotification('Printing manifest...'));

    try {
      const data = await post<string>('v2/manifest/print-route-manifest', { DeliveryRouteId: routeId });
      openPDFInWindow(data, `PrintedManifest-${routeId}.pdf`);
      dispatch(successNotification('Manifest generated'));
      return data;
    } catch (e) {
      dispatch(errorNotification(`Error generating manifest: ${e}`));
    }
  }
);

export const bypassMultipleStateSystem = createAsyncThunk(
  'bypassMultipleStateSystem',
  async (args: { ManagerPIN: string; ShipmentIds: Array<number> }, { getState, dispatch }) => {
    let successItems = 0;
    const { settings } = getState() as State;

    while (args.ShipmentIds.length) {
      const res = await Promise.all(
        args.ShipmentIds.splice(0, settings.locationSettings?.DeliveryConcurrency || 6).map(async (ShipmentId) => {
          try {
            await CheckoutApi.bypassStateSystem({ ManagerPIN: args.ManagerPIN, ShipmentId });
            return 1;
          } catch (ex) {
            dispatch(errorNotification(`Error marking (${ShipmentId}) Delivery Order to Bypass State System: ${ex}`));
            return 0;
          }
        })
      );
      successItems += res.reduce((acc, i) => acc + i, Number(0));
    }

    if (successItems > 0) {
      dispatch(successNotification(`(${successItems}) Delivery Orders Bypass State System`));
    }
  }
);

export const getRouteHistory = createAsyncThunk('getRouteHistory', async (DeliveryRouteId: number) => {
  return await DeliveryApi.getRouteHistory({ DeliveryRouteId });
});

export const getOptimizedRouteDirections = createAsyncThunk(
  'getOptimizedRouteDirections',
  async (args: { Shipments: Array<number>; DeliveryRouteId: number | undefined }, { dispatch }) => {
    try {
      const ret = await DeliveryApi.getOptimizedRouteDirections({ Shipments: args.Shipments });
      dispatch(successNotification('Directions Fetched'));
      return ret;
    } catch (error) {
      dispatch(errorNotification(`Unable to fetch directions ${error}`));
    }
  }
);

export const getRouteDirections = createAsyncThunk(
  'getRouteDirections',
  async (args: { DeliveryRouteId: number; ShipmentIds: Array<number> }, { dispatch }) => {
    try {
      const ret = await DeliveryApi.getRouteDirections({
        DeliveryRouteId: args.DeliveryRouteId,
        ShipmentIds: args.ShipmentIds,
      });
      dispatch(successNotification('Directions Fetched'));
      return ret;
    } catch (error) {
      dispatch(errorNotification(`Unable to fetch directions ${error}`));
    }
  }
);

export const moveRoute = createAsyncThunk(
  'moveRoute',
  async (
    args: { DeliveryRouteId: number | null; ShipmentIds: Array<number>; UsePlaceholderDirections: boolean },
    { dispatch }
  ) => {
    try {
      const ret = await DeliveryApi.addShipmentsToRoute({
        DeliveryRouteId: args.DeliveryRouteId,
        ShipmentIds: args.ShipmentIds,
      });
      dispatch(successNotification('Changed route'));
      return ret;
    } catch (error) {
      dispatch(errorNotification(`Unable to change route: ${error}`));
    }
  }
);

export const setRouteOrder = createAsyncThunk(
  'setRouteOrder',
  async (
    args: { DeliveryRouteId: number; ShipmentIds: Array<number>; UsePlaceholderDirections: boolean },
    { dispatch }
  ) => {
    try {
      const ret = await DeliveryApi.setRouteOrder({
        DeliveryRouteId: args.DeliveryRouteId,
        ShipmentIds: args.ShipmentIds,
      });
      dispatch(successNotification('Reordered route'));
      return ret;
    } catch (error) {
      dispatch(errorNotification(`Unable to change route: ${error}`));
    }
  }
);

export const setDeliveryFilterQuery = createAction<string>('setFilter');
export const setDisableAutorefresh = createAction<boolean>('setDisableAutorefresh');
export const setExpandedRoute = createAction<DeliveryRoute | null>('setExpandedRoute');
export const setMapsOpen = createAction<boolean>('setMapsOpen');
export const setRouteToCancel = createAction<DeliveryRoute | null>('setRouteToCancel');
export const setRouteToStart = createAction<DeliveryRoute | null>('setRouteToStart');
export const setRouteToSuspend = createAction<DeliveryRoute | null>('setRouteToSuspend');
