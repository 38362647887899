import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';
import { colors } from 'css/Theme';
import { Input } from 'components/inputs';
import { Box, Column } from 'components/layout';
import { useCashDrawerDeprecated, useAppDispatch, isAndroid } from 'util/hooks';
import { useNewSettingsUi } from 'util/hooks/launch-darkly/useNewSettingsUi';
import { ConfirmationModal } from 'components/modals/ConfirmationModal';
import { useConnectedReceiptPrinter } from 'util/hooks/hardware/useConnectedHardware';
import { checkManagerPin } from 'util/Helpers';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { Peripheral } from '@dutchie/capacitor-peripheral';
import { isPrintNodeReceiptPrinter } from 'hardware/receipt-printer';
import { ModalVariation } from 'components/modals/Modal';

type ConfirmOpenCashDrawerPopupProps = {
  isVisible: boolean;
  hide: callback;
};

export const ConfirmOpenCashDrawerPopup: FC<ConfirmOpenCashDrawerPopupProps> = (props) => {
  const isNewSettingsUi = useNewSettingsUi();
  return isNewSettingsUi ? (
    <ConfirmOpenCashDrawerPopupNew {...props} />
  ) : (
    <ConfirmOpenCashDrawerPopupDeprecated {...props} />
  );
};

const ConfirmOpenCashDrawerPopupNew: FC<ConfirmOpenCashDrawerPopupProps> = ({ isVisible, hide }) => {
  const dispatch = useAppDispatch();
  const title = 'Open cash drawer';
  const message = 'Manager PIN required to open cash drawer';
  const { device: receiptPrinter } = useConnectedReceiptPrinter();
  const [pin, setPin] = useState('');

  const handleContinue = async () => {
    try {
      await checkManagerPin(pin);

      if (!receiptPrinter && !isAndroid) {
        throw new Error('Unable to connect to cash drawer');
      }

      // Open through selected receipt printer
      await receiptPrinter?.popCashDrawer();

      // Support cash drawer connected to ELO stand
      if (isAndroid && receiptPrinter && isPrintNodeReceiptPrinter(receiptPrinter)) {
        await Peripheral.openCashDrawer();
      }

      dispatch(successNotification('Cash drawer opened'));
      handleHide();
    } catch (err) {
      setPin('');
      if (err instanceof Error) {
        dispatch(errorNotification(err.message));
      } else {
        dispatch(errorNotification('Error when opening cash drawer'));
      }
    }
  };

  const handleHide = () => {
    setPin('');
    hide();
  };

  if (!isVisible) {
    return null;
  }

  const hasEnteredPin = pin.length > 0;

  return (
    <ConfirmationModal
      variation={ModalVariation.Standard}
      closeButtonStyles={CloseButtonStyles}
      headerStyles={HeaderStyles}
      footerStyles={FooterStyles}
      hide={handleHide}
      header={title}
      modalName='cash-drawer'
      message={
        <Column>
          <MessageDiv>{message}</MessageDiv>
          <Input
            automationId='settings-open_cash_drawer_input'
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            placeholder='Manager PIN'
            type='password'
          />
        </Column>
      }
      leftButton={{
        automationId: 'settings-open_cash_drawer_cancel',
        text: 'Cancel',
        tertiary: true,
        onClick: handleHide,
      }}
      rightButton={{
        automationId: 'settings-open_cash_drawer_confirm',
        disabled: !hasEnteredPin,
        text: 'Open cash drawer',
        onClick: handleContinue,
      }}
    />
  );
};

const FooterStyles = css`
  padding: 32px;
`;

const HeaderStyles = css`
  padding: 24px;
  line-height: 40px;
`;

const CloseButtonStyles = css`
  top: 24px;
  right: 24px;
`;

const ConfirmOpenCashDrawerPopupDeprecated: FC<ConfirmOpenCashDrawerPopupProps> = ({ isVisible, hide }) => {
  const title = 'Open Cash Drawer';
  const message = 'Please enter your PIN:';
  const { openCashDrawer } = useCashDrawerDeprecated();
  const [pin, setPin] = useState('');

  const onContinue = () => {
    hide();
    openCashDrawer(pin);
  };

  return (
    <ConfirmationPopup
      hide={hide}
      isVisible={isVisible}
      title={title}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
      confirm={{
        text: 'Save',
        onClick: () => {
          onContinue();
        },
      }}
      small
    >
      <Body>
        <MessageDiv>{message}</MessageDiv>
        <Box marginBottom='2.25rem'>
          <Input value={pin} onChange={(e) => setPin(e.target.value)} placeholder='Manager PIN' type='password' />
        </Box>
      </Body>
    </ConfirmationPopup>
  );
};

const MessageDiv = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.75rem 0 2.5rem;
  text-align: center;
`;

const Body = styled.div`
  margin: 0 1.5rem;
`;
