import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { CheckedInGuest } from 'models/Guest';
import { ConfirmationPopup } from 'components/popups';
import { releaseGuest, getGuests } from 'store/actions/GuestListActions';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';

const ReleasePopupStyle = styled(ConfirmationPopup)`
  width: 29.5rem;
`;

const ReleasePopupContainer = styled.div`
  width: 100%;
  padding: 0 1.5rem;
`;

type ReleasePopupProps = {
  hide: () => void;
  guest: CheckedInGuest;
};

export const ReleasePopup: FC<ReleasePopupProps> = ({ hide, guest }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  //Putting these in component state so that when the guest is release these values persist
  const [shipmentId] = useState(guest.ShipmentId);
  const [register] = useState(guest.Register);

  const isSmartCheckinActive = useSmartCheckinActive();

  const releaseGuestOnClick = async () => {
    setLoading(true);
    await dispatch(
      releaseGuest({
        guestId: guest.Guest_id,
        shipmentId: guest.ShipmentId,
        registerId: guest.RegisterId,
        scheduleId: guest.ScheduleId,
      })
    );

    if (!isSmartCheckinActive) {
      await dispatch(getGuests());
    }
    setLoading(false);
    hide();
  };
  //
  return (
    <ReleasePopupStyle
      isVisible
      hide={hide}
      title={`Release Transaction`}
      confirm={{
        text: 'Save',
        loading: loading,
        disabled: loading,
        onClick: () => {
          releaseGuestOnClick();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      <ReleasePopupContainer>
        Are you sure you want to release transaction #{shipmentId} from {register}?
      </ReleasePopupContainer>
    </ReleasePopupStyle>
  );
};
