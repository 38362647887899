import { StorageType, setStorage } from 'store/actions/StorageActions';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { useEffect, useCallback } from 'react';

export const useLocalStorage = (key: string) => {
  return useStorage(key, 'local');
};
export const useSessionStorage = (key: string) => {
  return useStorage(key, 'session');
};

const useStorage = (key: string, storageType: StorageType) => {
  const reduxValue = useSelector((state: State) => state.storageState[storageType][key]);
  const dispatch = useDispatch();
  const storage = storageType === 'local' ? localStorage : sessionStorage;
  const storageValue = storage.getItem(key);

  const setAndSave = useCallback(
    (val: string | null) => {
      dispatch(setStorage({ key, value: val, storageType }));

      if (val !== null) {
        storage.setItem(key, val);
      } else {
        storage.removeItem(key);
      }
    },
    [dispatch, key, storageType, storage]
  );

  const clear = useCallback(() => {
    dispatch(setStorage({ key, value: null, storageType }));
    storage.removeItem(key);
  }, [dispatch, key, storage, storageType]);

  // listen for modifications to storage made outside this tab's context
  useEffect(() => {
    const handleStorageEvent = (e: StorageEvent) => {
      if (e.key === key && e.newValue !== storageValue) {
        setAndSave(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [dispatch, key, setAndSave, storageType, storageValue]);

  useEffect(() => {
    //either the storage value was updated out of band with this hook or it hasn't been pulled yet - update it
    if (storageValue !== reduxValue) {
      dispatch(setStorage({ key, value: storageValue, storageType }));
    }
  }, [dispatch, key, reduxValue, storageType, storageValue]);

  return { value: storageValue, setValue: setAndSave, clear };
};
