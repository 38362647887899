import { cancelPaymentsHubTransaction } from 'api/CheckoutApi';
import { PaymentsHubProductUpsell, PaymentsHubProductUpsellPusherNotification } from 'models/Checkout';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { errorNotification } from 'store/actions/NotificationsActions';
import { showProductUpsellPopup } from 'store/actions/PopupsActions';
import { logger } from 'util/logger';
import { useMetrics } from 'util/metrics/useMetrics';

export const useOnPusherProductUpsell = () => {
  const { shipmentId } = useTransactionManager();
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const guestId = useSelector((state: State) => state.customer.details?.Guest_id);

  const dispatch = useDispatch();
  const { data: products } = useGetAllProductsQuery({ customerId: guestId, registerId });
  const metrics = useMetrics();

  const onPusherProductUpsell = useCallback(
    async (notification: PaymentsHubProductUpsellPusherNotification) => {
      metrics.addCount('payments.pusher.upsell-request.message-receipt');

      if (notification.ShipmentId !== shipmentId || !products) {
        metrics.addCount('payments.pusher.upsell-request.invalid-shipment-id');
        return;
      }

      const product = products ? products.find((product) => product.productId === notification.ProductId) : undefined;

      if (!product) {
        metrics.addCount('payments.pusher.upsell-request.invalid-product');
        logger.error('ProductUpsellPopup product not found', { notification });

        const defaultPhrase = 'product';

        dispatch(
          errorNotification(
            `Customer requested to add ${
              notification.ProductName || defaultPhrase
            } to cart. Unable to find product, or that product is out of stock.`
          )
        );

        try {
          await cancelPaymentsHubTransaction({ ShipmentId: shipmentId });
        } catch (e) {
          metrics.addCount('payments.pusher.upsell-request.cancel-transaction-failed');
          logger.error('ProductUpsellPopup executing cancelPaymentsHubTransaction caught error', { e });
          dispatch(errorNotification('Unable to cancel the upsell request. Please try again.'));
          throw e;
        }
        return;
      }

      const productUpsell: PaymentsHubProductUpsell = {
        ShipmentId: notification.ShipmentId,
        ProductName: notification.ProductName,
        Product: product,
      };

      dispatch(showProductUpsellPopup({ productDetails: productUpsell }));
    },
    [shipmentId, products, dispatch, metrics]
  );

  return onPusherProductUpsell;
};
