import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

export const CHANGE_PAYMENT_TYPE_ENDPOINT = "v2/transaction_history/update-payment-type";

export type ChangePaymentTypePayload = {
  NewCashPaid?: number;
  NewCheckPaid?: number;
  NewCreditPaid?: number;
  NewDebitPaid?: number;
  NewGiftPaid?: number;
  NewManualPaid?: number;
  NewManualPaymentProcessorId?: number;
  NewTotalDueCustomer?: number;
  Register?: number;
  ShipmentId?: number;
};

export function useChangePaymentTypeMutation() {
  return useMutation({
    mutationFn: (payload: ChangePaymentTypePayload) =>
      post<ChangePaymentTypePayload>(CHANGE_PAYMENT_TYPE_ENDPOINT, payload),
  });
}
