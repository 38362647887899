import { LabelPrinter, ConnectionType } from '@dutchie/capacitor-hardware';
import { printBytes } from 'api/PosApi';
import { PrinterResponse, PrintNodePrinterType, PrintNodePrinterTypes } from 'models/Misc';
import { GetPrintJobResponse } from 'util/hardwareLibrary/hardware-library-utils';

export class PrintNodeLabelPrinter extends LabelPrinter {
  private config: PrinterResponse;
  job: GetPrintJobResponse | undefined;

  constructor(config: PrinterResponse) {
    super(ConnectionType.lan);
    this.config = config;
  }

  get id(): string {
    return `pn-label-${this.printNodeId}`;
  }

  // Indicates whether the code is actively listening for printer events
  get isConnected(): boolean {
    return false;
  }

  get name(): string {
    return this.config.Name;
  }

  // Enables finding printer info in db by the print node id
  get printNodeId(): number {
    return this.config.PrinterId;
  }

  // Passed to label job endpoint to avoid an extra db lookup
  get printNodeType(): PrintNodePrinterType {
    return this.config.PrinterType ?? PrintNodePrinterTypes.ZPL;
  }

  async doConnect(): Promise<boolean> {
    return true;
  }

  async doDisconnect(): Promise<boolean> {
    return true;
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    const result: { JobId: number } = await printBytes({
      bytes,
      printerId: this.printNodeId,
      printerType: this.printNodeType,
      title: this.job?.title,
    });
    return result && result.JobId > 0;
  }
}
