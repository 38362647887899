import React, { FC } from 'react';
import { RemovalPopup } from 'components/popups';
import { CartItem } from 'models/Cart';

type CartItemRemovalPopup = {
  item: CartItem;
  onRemoveItem: () => void;
  hide: () => void;
};

export const CartItemRemovalPopup: FC<CartItemRemovalPopup> = ({ item, onRemoveItem, hide }) => {
  const count = item.WgtCnt === 'Wgt' ? item?.Grams ?? 0 : item.QtySelected;
  const unit = item.WgtCnt === 'Wgt' ? 'g' : 'x';

  const itemDesc = `${count}${unit} - ${item.Product}`;
  const removalMessage = (
    <>
      Do you want to remove <b>{itemDesc}</b> from the cart?
    </>
  );

  return (
    <RemovalPopup
      message={removalMessage}
      confirm={{
        text: 'Yes, remove item',
        action: onRemoveItem,
      }}
      hide={hide}
    />
  );
};
