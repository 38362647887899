import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { SelectContainer } from '../../SettingPageComponents';
import { Label } from 'components/text';
import { Select } from 'components/inputs';
import { logger, customEventKeys } from 'util/logger';
import { UserSelectedPrinterContext } from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';

export const NetworkFulfillmentPicker: FC = () => {
  const dispatch = useDispatch();
  const { receiptPrinters } = useSelector((state: State) => state.settings);
  const { selectedFulfillmentPrinter } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintFulfillmentTicket } = useSelector((state: State) => state.settings.userSettings);

  const changePrinter = (value: string) => {
    const selectedFulfillmentPrinter = receiptPrinters.find((item) => item.PrinterId === parseInt(value, 10));

    if (selectedFulfillmentPrinter) {
      dispatch(saveUserSettings({ selectedFulfillmentPrinter }));

      logger.info<UserSelectedPrinterContext>(`user selected network pick ticket printer ${value}`, {
        key: customEventKeys.printing.userSelectedPrinter,
        job: PrintJob.PICK_TICKET,
        type: 'network',
        selectedPrinterId: value,
      });
    }
  };

  const options = receiptPrinters.map((item) => ({
    value: item.PrinterId,
    label: item.Name,
    key: item.PrinterId,
  }));

  return (
    <SelectContainer>
      <Label>Network Fulfillment Ticket Printer</Label>
      <Select
        placeholder='Select Printer'
        defaultValue={selectedFulfillmentPrinter?.PrinterId}
        onChange={changePrinter}
        options={options}
        errorMessage={
          !selectedFulfillmentPrinter && autoPrintFulfillmentTicket && 'Please select a default fulfillment printer'
        }
      />
    </SelectContainer>
  );
};
