import { useSelector } from 'react-redux';
import { useCaregiversQuery } from 'queries/v2/guest/caregiver-list';
import { useMMURCaregiversQuery } from 'queries/mmur/get-customer-caregivers';

import type { State } from 'store';

export const useCaregivers = (guestId: number) => {
  const isUseMMUREnabled = useSelector((state: State) => state.settings.integrations?.UseMMUR);
  const { data: caregivers } = useCaregiversQuery({ guestId }, { enabled: !isUseMMUREnabled });
  const { data: mmurCaregivers } = useMMURCaregiversQuery({ guestId }, { enabled: isUseMMUREnabled });

  // With MMUR, caregivers are not managed in POS because they are reported to the
  // state not through state ids but rather through MMUR-internal integer ids returned
  // from the MMUR PatientSearch endpoint.
  if (isUseMMUREnabled) {
    return mmurCaregivers ?? [];
  }

  return caregivers ?? [];
};
