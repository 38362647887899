import React, { FC, useState } from 'react';

import { Menu } from 'components/backoffice/menu';
import { MenuItem } from 'components/backoffice/menu-item';
import { NavButton } from 'components/buttons';
import { ListIcon } from 'assets/icons/guest-list/ListIcon';

type ChangeViewButtonProps = {
  onToggleListMode: (mode: string) => unknown;
  isListMode: boolean;
};

export const ChangeViewButton: FC<ChangeViewButtonProps> = ({ onToggleListMode, isListMode }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const baseActions = [
    {
      text: 'List view',
      onClick: () => {
        onToggleListMode('List');
        handleClose();
      },
    },
    {
      text: 'Column view',
      onClick: () => {
        onToggleListMode('Column');
        handleClose();
      },
    },
  ];

  const testId = isListMode ? 'column_svg_kanban-mode' : 'list_svg_list-mode';

  return (
    <>
      <NavButton
        title='Change view mode'
        aria-controls='filter-menu'
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        icon={<ListIcon width={16} height={16} automationId={testId} />}
        onClick={handleClick}
        margin='0'
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {baseActions.map((action) => (
          <MenuItem onClick={action.onClick} value={action.text} key={action.text}>
            {action.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
