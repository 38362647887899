import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PreorderConfig } from 'models/Misc';
import { getAddressString, CustomerDetails, PreorderMetadataValues } from 'models/Customer';
import { getUsersWithPermission } from 'api/PosApi';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { UserState } from 'store/reducers/UserReducer';
import { GenderOptions } from 'components/inputs/defaults';
import { DatePicker, Input, Select, SelectOption } from 'components/inputs';
import { colors } from 'css/Theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 2rem;
  gap: 2rem;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  > div {
    flex-grow: 1;
    margin-bottom: 0;
  }
`;

const FieldLabel = styled.span`
  display: block;
  color: ${colors.dutchie.grey30};
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 113px;
  flex: 0 0 113px;
`;

type PreorderMetadataProps = {
  values?: PreorderMetadataValues;
  config: PreorderConfig;
  onChange: (val: PreorderMetadataValues) => void;
  customerDetails?: CustomerDetails;
  collectAnonymousDemographics: boolean;
};

const NEW_ADDRESS_KEY = 'NEW';

export const PreorderMetadata: FC<PreorderMetadataProps> = ({
  values,
  config,
  customerDetails,
  onChange,
  collectAnonymousDemographics,
}) => {
  const loggedInUser = useSelector((state: State) => state.user);
  const allCustomerTypes = useSelector((state: State) => state.settings.customerTypes);

  const [orderSource, setOrderSource] = useState(
    values?.orderSourceId || config.OrderSources.find((x) => x.IsDefault)?.OrderSourceId
  );
  const [orderType, setOrderType] = useState(values?.orderTypeId);
  const [dueDate, setDueDate] = useState<Date | undefined>(values?.dueDate || new Date());
  const [address, setAddress] = useState(values?.deliveryAddressId);
  const [createNewAddress, setCreateNewAddress] = useState(values?.createNewAddress || collectAnonymousDemographics);
  const [street, setStreet] = useState(values?.newAddress?.street || '');
  const [street2, setStreet2] = useState(values?.newAddress?.street2 || '');
  const [city, setCity] = useState(values?.newAddress?.city || '');
  const [state, setState] = useState(values?.newAddress?.state || '');
  const [zip, setZip] = useState(values?.newAddress?.postal_code || '');
  const [responsibleForSale, setResponsibleForSale] = useState(loggedInUser);
  const [activeUsers, setActiveUsers] = useState<UserState[]>([]);
  const [dob, setDob] = useState(values?.dob);
  const [gender, setGender] = useState(values?.gender);
  const [customerType, setCustomerType] = useState(values?.customerTypeId);
  const [postalCode, setPostalCode] = useState(values?.postalCode);

  const customerTypes = (allCustomerTypes || [])
    .filter((x) => x.IsRetail && !x.IsMedical)
    .map((x) => ({ label: x.CustomerType, value: x.CustomerTypeId }));

  const addresses = useMemo(() => {
    let mapped: SelectOption[] = [];
    if (customerDetails) {
      if (customerDetails?.address?.street !== '') {
        mapped.push({
          label: getAddressString(customerDetails.address),
          value: '-1',
          key: '-1',
        });
      }

      mapped = mapped.concat(
        customerDetails.AddressBook.map((x) => ({
          label: getAddressString(x),
          value: x.AddressId?.toString(),
          key: x.AddressId?.toString(),
        }))
      );
    }

    mapped.push({
      label: 'Create new...',
      value: NEW_ADDRESS_KEY,
      key: NEW_ADDRESS_KEY,
    });

    if (mapped.length === 1) {
      setCreateNewAddress(true);
    }

    return mapped;
  }, [customerDetails]);

  const orderTypes = useMemo(() => {
    const result = [];

    if (config) {
      if (config.WalkInEnabled) {
        result.push({ id: 4, name: 'In-Store' });
      }
      if (config.DeliveryEnabled) {
        result.push({ id: 2, name: 'Delivery' });
      }
      if (config.InStorePickupEnabled) {
        result.push({ id: 1, name: 'In-Store Pickup' });
      }
      if (config.CurbsidePickupEnabled) {
        result.push({ id: 3, name: 'Curbside Pickup' });
      }
    }

    return result;
  }, [config]);

  const onAddressChange = (addrId: string) => {
    setCreateNewAddress(addrId === NEW_ADDRESS_KEY);
    setAddress(addrId === '-1' || addrId === NEW_ADDRESS_KEY ? undefined : Number(addrId));
  };

  const getAddressKey = () => {
    if (createNewAddress) {
      return NEW_ADDRESS_KEY;
    }

    if (address === undefined) {
      return '-1';
    }

    return address;
  };

  useEffect(() => {
    const orderSourceObj = config.OrderSources.find((x) => x.OrderSourceId === orderSource);
    const orderTypeObj = orderTypes.find((x) => x.id === orderType);

    onChange({
      orderSourceId: orderSource,
      orderSourceName: orderSourceObj?.OrderSourceName,
      orderTypeId: orderType,
      orderTypeName: orderTypeObj?.name,
      deliveryAddressId: address,
      dueDate: dueDate,
      createNewAddress: createNewAddress,
      newAddress: {
        street: street,
        street2: street2,
        city: city,
        state: state,
        postal_code: zip,
        Country_Code: '',
      },
      responsibleForSale: responsibleForSale,
      dob: dob,
      postalCode: postalCode,
      customerTypeId: customerType,
      gender: gender,
    });
  }, [
    onChange,
    orderTypes,
    config.OrderSources,
    orderSource,
    orderType,
    street,
    street2,
    city,
    state,
    zip,
    dueDate,
    address,
    createNewAddress,
    responsibleForSale,
    dob,
    postalCode,
    customerType,
    gender,
  ]);

  useEffect(() => {
    async function getActiveUsers() {
      const allUsers = await getUsersWithPermission(6);
      const sortedUsers = allUsers.sort((a, b) => (a.FullName > b.FullName ? 1 : -1));
      setActiveUsers(sortedUsers);
    }
    getActiveUsers();
  }, []);

  function changeResponsibleUser(userId: string) {
    const user = activeUsers.find((u) => u.UserId === parseInt(userId));
    if (user !== undefined) {
      setResponsibleForSale(user);
    }
  }

  return (
    <>
      <Container>
        {collectAnonymousDemographics && (
          <>
            <FieldContainer>
              <FieldLabel>Date of birth:</FieldLabel>
              <DatePicker
                selected={dob}
                onChange={(val) => setDob(val ?? undefined)}
                mode='date'
                selectsStart
                popperPlacement='bottom-end'
                className='full-width'
                placeholderText='Enter date of birth...'
                disabled={!!values?.dob}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>Gender:</FieldLabel>
              <Select
                placeholder='Select gender...'
                options={GenderOptions}
                value={gender}
                onChange={(val) => setGender(val)}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>Postal code:</FieldLabel>
              <Input
                placeholder='Enter postal code...'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              ></Input>
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>Customer type:</FieldLabel>
              <Select
                placeholder='Select customer type...'
                options={customerTypes}
                value={customerType}
                onChange={(val) => setCustomerType(val ? Number(val) : undefined)}
              />
            </FieldContainer>
            <hr style={{ margin: 0 }} />
          </>
        )}
        <FieldContainer>
          <FieldLabel>Source:</FieldLabel>
          <Select
            placeholder='Select source...'
            options={config.OrderSources.map((source) => ({
              label: source.OrderSourceName,
              value: source.OrderSourceId.toString(),
              key: source.OrderSourceId,
            }))}
            value={orderSource?.toString()}
            onChange={(val) => setOrderSource(Number(val))}
          />
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Order type:</FieldLabel>
          <Select
            placeholder='Select order type...'
            options={orderTypes.map((type) => ({
              label: type.name,
              value: type.id.toString(),
              key: type.id,
            }))}
            onChange={(val) => setOrderType(Number(val))}
            value={orderType?.toString()}
          />
        </FieldContainer>
        {orderType === 2 && addresses.length > 1 && (
          <FieldContainer>
            <FieldLabel>Address:</FieldLabel>
            <Select options={addresses} onChange={(val) => onAddressChange(val)} value={getAddressKey()} />
          </FieldContainer>
        )}
        {orderType === 2 && createNewAddress && (
          <>
            <FieldContainer>
              <FieldLabel>Street:</FieldLabel>
              <Input placeholder='Enter street...' value={street} onChange={(e) => setStreet(e.target.value)} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>Apt/suite:</FieldLabel>
              <Input placeholder='Enter apt/suite...' value={street2} onChange={(e) => setStreet2(e.target.value)} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>City:</FieldLabel>
              <Input placeholder='Enter city...' value={city} onChange={(e) => setCity(e.target.value)} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>State:</FieldLabel>
              <Input placeholder='Enter state...' value={state} onChange={(e) => setState(e.target.value)} />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>Zip:</FieldLabel>
              <Input placeholder='Enter zip...' value={zip} onChange={(e) => setZip(e.target.value)} />
            </FieldContainer>
          </>
        )}
        <FieldContainer>
          <FieldLabel>Date/time:</FieldLabel>
          <DatePicker
            selected={dueDate}
            placeholderText='Enter delivery date/time...'
            onChange={(val) => setDueDate(val ?? undefined)}
            mode='datetime'
            selectsStart
            popperPlacement='bottom-end'
            minDate={new Date()}
          />
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Budtender:</FieldLabel>
          <Select
            placeholder='Select budtender...'
            options={activeUsers.map((type) => ({
              label: type.FullName,
              value: type.UserId,
              key: type.UserId,
            }))}
            onChange={(val) => changeResponsibleUser(val)}
            value={responsibleForSale.UserId}
          />
        </FieldContainer>
      </Container>
    </>
  );
};
