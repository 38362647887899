import React, { FC } from 'react';

import { Cart } from 'models/Cart';
import { ItemCount } from './ItemCount';
import { CartItems } from './CartItems';
import { TotalsSummary } from './TotalsSummary';
import { CFDGlobalStyles, CFDCartContainer, SummaryContainer, SummaryHeader, TotalsContainer } from '../../CFD.styles';
import { useTotalItemCount } from 'util/hooks/cart';

type CartScreenProps = {
  show?: boolean;
  cart?: Cart;
  features: Record<string, boolean>;
};

export const CartScreen: FC<CartScreenProps> = ({ show, cart, features }) => {
  const totalItemCount = useTotalItemCount(cart);

  return (
    <CFDCartContainer data-testid='cart-screen' show={show}>
      <CFDGlobalStyles />
      <SummaryContainer>
        <SummaryHeader>
          Items Summary
          <ItemCount count={totalItemCount} automationId='cart-screen_summary_item-count' />
        </SummaryHeader>
        <CartItems cartItems={cart?.Cart ?? []} discounts={cart?.Discounts ?? []} />
      </SummaryContainer>
      <TotalsContainer>
        <TotalsSummary cart={cart} features={features} />
      </TotalsContainer>
    </CFDCartContainer>
  );
};
