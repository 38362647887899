import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { FilterOption, FilterOptionValue } from 'models/Search';
import { setShowEmptyStatusColumns } from 'store/actions/GuestListActions';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { Flex } from 'components/layout';
import { Checkbox, CheckboxState } from 'components/inputs';
import { TimeWindowFilterMetaData } from 'pages/DeliveryPage';
import { TimeWindow } from 'store/reducers/DeliveryReducer';
import { TimeWindowFilter } from './TimeWindowFilter';
import { useTimeWindowFilterState } from './hooks/useTimeWindowFilterState';
import { CheckedInGuest } from 'models/Guest';
import { Delivery } from 'models/Delivery';
import { Vehicle } from 'models/Vehicle';

type FilterMenuProps<T extends object> = {
  appliedFilters: Array<FilterOption<T>>;
  onApply: (appliedFilters: Array<FilterOption<T>>, timeWindow?: TimeWindow) => void;
  onCancel: () => void;
  timeWindowFilterMetaData?: TimeWindowFilterMetaData;
};

export const GuestListFilterMenu = React.memo(GuestListFilterMenuImpl);
function GuestListFilterMenuImpl(props: FilterMenuProps<CheckedInGuest>) {
  const filterOptions = useSelector((state: State) => state.guestList.filterOptions);

  return <FilterMenuImpl {...props} filterOptions={filterOptions} />;
}

export const DeliveryPageFilterMenu = (props: FilterMenuProps<Delivery>) => {
  const filterOptions = useSelector((state: State) => state.deliveryList.filterOptions);

  return <FilterMenuImpl {...props} filterOptions={filterOptions} />;
};

export const FleetPageFilterMenu = (props: FilterMenuProps<Vehicle>) => {
  const filterOptions = useSelector((state: State) => state.fleetList.filterOptions);

  return <FilterMenuImpl {...props} filterOptions={filterOptions} />;
};

export const FilterMenuImpl = <T extends object>({
  appliedFilters,
  onApply,
  onCancel,
  filterOptions,
  timeWindowFilterMetaData,
}: FilterMenuProps<T> & { filterOptions: Array<FilterOption<T>> }) => {
  const dispatch = useDispatch();
  const showEmptyStatusColumns = useSelector((state: State) => state.guestList.showEmptyStatusColumns);
  const [filterSelections, setFilterSelections] = useState<Array<FilterOption<T>>>(appliedFilters);

  // Update filter selections when they change outside this component, e.g. when reset filters is clicked
  useEffect(() => {
    setFilterSelections(appliedFilters);
  }, [appliedFilters, setFilterSelections]);

  const timeWindowFilterState = useTimeWindowFilterState({ timeWindowFilterMetaData });
  const showTimeWindowFilter = !!timeWindowFilterMetaData && !!timeWindowFilterState;

  const allSelected = (key: keyof T, options: Array<FilterOptionValue<T>>): CheckboxState => {
    const selection = filterSelections.find((selected) => selected.key === key);

    if (selection) {
      const delta = options.length - selection.options.length;
      if (delta === 0) {
        return 'on';
      }
      if (delta > 0 && delta < options.length) {
        return 'mixed';
      }
    }
    return 'off';
  };

  const toggleAll = (key: keyof T, options: Array<FilterOptionValue<T>>) => {
    const i = filterSelections.findIndex((selected) => selected.key === key);

    if (i >= 0) {
      const update = cloneDeep(filterSelections);

      if (update[i].options.length < options.length) {
        update[i].options = options;
      } else {
        update.splice(i, 1);
      }
      setFilterSelections(update);
    } else {
      const filterOption = filterOptions.find((x) => x.key === key);
      if (filterOption) {
        const newObj = { ...filterOption };
        setFilterSelections([...filterSelections, newObj]);
      }
    }
  };

  const selectOption = (key: keyof T, option: FilterOptionValue<T>) => {
    const selectionIndex = filterSelections.findIndex((selected) => selected.key === key);

    if (selectionIndex >= 0) {
      const update = cloneDeep(filterSelections);
      const optionIndex = update[selectionIndex].options.findIndex((o) => o === option);

      if (optionIndex >= 0) {
        update[selectionIndex].options.splice(optionIndex, 1);
      } else {
        update[selectionIndex].options.push(option);
      }
      setFilterSelections(update);
    } else {
      const filterOption = filterOptions.find((x) => x.key === key);
      if (filterOption) {
        const newObj = { ...filterOption, options: [option] };
        setFilterSelections([...filterSelections, newObj]);
      }
    }
  };

  const optionIsEnabled = (key: keyof T, option: FilterOptionValue<T>): CheckboxState => {
    const selection = filterSelections.find((selected) => selected.key === key);
    return selection && selection.options.includes(option) ? 'on' : 'off';
  };

  const applyFilters = () => {
    if (timeWindowFilterState) {
      const { endDate, setTimeWindowValidationError, startDate } = timeWindowFilterState;
      const startButNoEnd = !!startDate && !endDate;
      const endButNoStart = !!endDate && !startDate;

      if (startButNoEnd || endButNoStart) {
        setTimeWindowValidationError({ start: endButNoStart, end: startButNoEnd });
        return;
      }

      const timeWindowForFilter: TimeWindow | undefined = startDate && endDate ? [startDate, endDate] : undefined;

      onApply(filterSelections, timeWindowForFilter);
    } else {
      onApply(filterSelections);
    }
  };

  return (
    <FilterMenuContainer data-testid='filter-menu-container'>
      <FilterMenuTopContainer>
        <FilterMenuTop>
          <FilterMenuTitle>Filter</FilterMenuTitle>
          <FilterMenuTopButtons>
            <StyledDutchieButton
              secondary
              onClick={() => {
                setFilterSelections(appliedFilters);
                onCancel();
              }}
              automationId='filter-menu_button_cancel'
            >
              Cancel
            </StyledDutchieButton>
            <StyledDutchieButton onClick={applyFilters} automationId='filter-menu_button_apply'>
              Apply
            </StyledDutchieButton>
          </FilterMenuTopButtons>
        </FilterMenuTop>
      </FilterMenuTopContainer>
      {filterOptions.map(({ key, label, options }) => (
        <FilterSectionDiv key={key.toString()} data-testid={`filter-menu_span_${String(key)}`}>
          <Flex justifyContent='space-between' marginBottom='12px' marginTop='20px'>
            <FilterName>{label}</FilterName>

            {/* WARNING: this is a terrible hack but the lesser of two evils,
                        the other option being to completely redesign how filters currently work
                        this filter is only present on the GuestListPage as of this writing */}
            {label === 'Status' && (
              <FilterMenuFilter
                key='showEmptyStatusColumns'
                onClick={() => dispatch(setShowEmptyStatusColumns(!showEmptyStatusColumns))}
              >
                <Checkbox state={showEmptyStatusColumns ? 'on' : 'off'} />
                <FilterMenuFilterLabel>Show Empty Status Columns</FilterMenuFilterLabel>
              </FilterMenuFilter>
            )}
          </Flex>
          <FilterOptionsDiv>
            <FilterMenuFilter onClick={() => toggleAll(key, options)}>
              <Checkbox state={allSelected(key, options)} />
              <FilterMenuFilterLabel>All</FilterMenuFilterLabel>
            </FilterMenuFilter>
            {options.map((option) => (
              <FilterMenuFilter
                data-testid={`filter-menu_span_${String(key)}-${option}`}
                key={`${String(key)}-${option}`}
                onClick={() => selectOption(key, option)}
              >
                <Checkbox state={optionIsEnabled(key, option)} />
                <FilterMenuFilterLabel>{option}</FilterMenuFilterLabel>
              </FilterMenuFilter>
            ))}
          </FilterOptionsDiv>
        </FilterSectionDiv>
      ))}
      {showTimeWindowFilter && (
        <TimeWindowFilter
          description={timeWindowFilterMetaData.description}
          endDate={timeWindowFilterState.endDate}
          label={timeWindowFilterMetaData.label}
          setTimeWindow={timeWindowFilterState.setTimeWindow}
          setTimeWindowValidationError={timeWindowFilterState.setTimeWindowValidationError}
          startDate={timeWindowFilterState.startDate}
          timeWindowValidationError={timeWindowFilterState.timeWindowValidationError}
        />
      )}
    </FilterMenuContainer>
  );
};

const FilterMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterMenuTopContainer = styled.div`
  position: sticky;
  top: 0;
  background: ${colors.background};
  box-shadow: 0 1px 0 ${colors.dutchie.shadowGrey};
`;

const FilterMenuTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 3rem;
`;
const FilterMenuTitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
`;

const FilterMenuTopButtons = styled.div`
  flex-basis: 180px;
  display: flex;
  justify-content: space-between;
`;

const StyledDutchieButton = styled(Button)`
  height: 45px;
`;

export const FilterName = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: ${colors.dutchie.almostBlack};
`;

const FilterMenuFilter = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
  margin: 0 0 0.5rem;
`;

const FilterMenuFilterLabel = styled.p`
  margin-left: 0.5rem;
`;

export const FilterSectionDiv = styled.div`
  box-shadow: 0 1px 0 ${colors.dutchie.shadowGrey};
  margin: 0 3rem;
`;

const FilterOptionsDiv = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 20px;
  max-height: 140px;
  overflow: auto;
`;
