import { useState, useEffect } from 'react';

export const useDebounce = <T>(value: T, millis: number) => {
  const [state, setState] = useState<T>(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setState(value);
    }, millis);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, millis]);

  return state;
};
