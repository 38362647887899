import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from 'components/buttons';
import { callback1 } from 'models/Misc';
import { State } from 'store';
import styled from 'styled-components';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';
import { useTotalDetailsSection } from '../TotalDetailsSection/useTotalDetailsSection';

type PaymentShortcut = {
  name?: string;
  amount?: number;
  paddingX?: number;
  width?: string;
};

type PaymentShortcutsProps = {
  shortcuts: PaymentShortcut[];
  onAddAmount: callback1<number>;
};

export const PaymentShortcuts = ({ shortcuts, onAddAmount }: PaymentShortcutsProps): JSX.Element => {
  const totalRemaining = useSelector((state: State) => state.checkout.totalRemaning);
  const totalDue = useSelector((state: State) => state.checkout.totalDue);
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const checkoutLoading = useSelector((state: State) => state.checkout.loading);
  const cartLoading = useSelector((state: State) => state.cart.loading);
  const { dutchiePayPreAuthError } = useDutchiePayPreAuth();
  const { totalPaid } = useTotalDetailsSection();

  // used for the Exact [Cash, Debit, etc] buttons and to know when to disable payment shortcut buttons if total order is already paid for
  // if we have a dutchie pay pre-auth payment but it failed, we need to calculate our remaining total differently
  const totalLeftToPay = dutchiePayPreAuthError ? totalDue - totalPaid : totalRemaining;

  const loading = cartLoading || digitalPaymentLoading || checkoutLoading;

  const getLabel = (shortcut: PaymentShortcut) => shortcut.name ?? `$${shortcut.amount}`;
  const getValue = (shortcut: PaymentShortcut) => shortcut.amount ?? totalLeftToPay;

  const paymentDisabled = totalLeftToPay <= 0;

  return (
    <ShortcutRow>
      {shortcuts.map((shortcut) => (
        <ShorcutButton
          tertiary
          loading={loading}
          hideLabelOnLoading
          width={shortcut.width}
          onClick={() => onAddAmount(getValue(shortcut))}
          automationId={`payment-options_button_${getLabel(shortcut)}`}
          disabled={paymentDisabled}
          paddingX={shortcut.paddingX}
          key={shortcut.name ?? shortcut.amount}
          ddActionName={shortcut.name ?? shortcut.amount?.toString()}
        >
          {getLabel(shortcut)}
        </ShorcutButton>
      ))}
    </ShortcutRow>
  );
};

const ShortcutRow = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
`;

const ShorcutButton = styled(LoadingButton)<{ paddingX?: number; width?: string }>`
  ${({ width }) =>
    width &&
    `
    width: ${width};
    flex: 0 0 ${width};
  `}

  ${({ paddingX }) => paddingX && `padding: 0 ${paddingX}px;`}
`;
