import React, { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { colors } from 'css/Theme';
import * as paymentIcons from 'assets/icons/payments';
import { WarningIcon } from 'assets/icons/in-store/WarningIcon';
import { showPaymentMethodDisabledPopup } from 'store/actions/PopupsActions';
import { useFeatureSwitches } from 'pages/CartPage/hooks/useFeatureSwitches';

export enum PaymentButtonType {
  CARD = 'credit',
  CASH = 'cash',
  CHECK = 'check',
  DEBIT = 'debit',
  DIGITAL = 'digital',
  GIFTCARD = 'gift-card',
  LOYALTY = 'loyalty',
  MMAP = 'mmap',
  HUB = 'hub',
  DUTCHIEPAY = 'dutchie-pay',
  MANUAL = 'manual',
  GENERIPAY = 'generiPay',
}

const paymentButtonTestId = (paymentButtonType: PaymentButtonType, isDisabledViaFeatureSwitch: boolean = false) =>
  isDisabledViaFeatureSwitch
    ? `${paymentButtonType}-feature-switch-disablement-content`
    : `payment-buttons_payment-type-button_${paymentButtonType}`;

type PaymentButtonProps = {
  disabled?: boolean;
  hide?: boolean;
  label: string;
  onClick: () => void;
  paymentButtonType: PaymentButtonType;
};

export const PaymentButton = forwardRef<HTMLButtonElement, PaymentButtonProps>(
  ({ disabled, label, hide, onClick, paymentButtonType }, ref) => {
    const dispatch = useDispatch();
    const { getFeatureSwitch } = useFeatureSwitches();

    const PaymentIcon = useMemo(() => {
      switch (paymentButtonType) {
        case PaymentButtonType.CASH:
          return paymentIcons.CashIcon;
        case PaymentButtonType.CHECK:
          return paymentIcons.CheckIcon;
        case PaymentButtonType.CARD:
          return paymentIcons.CardIcon;
        case PaymentButtonType.DEBIT:
          return paymentIcons.DebitIcon;
        case PaymentButtonType.DIGITAL:
          return paymentIcons.DigitalIcon;
        case PaymentButtonType.GIFTCARD:
          return paymentIcons.GiftCardIcon;
        case PaymentButtonType.LOYALTY:
          return paymentIcons.LoyaltyIcon;
        case PaymentButtonType.MMAP:
          return paymentIcons.MMAPIcon;
        case PaymentButtonType.HUB:
          return paymentIcons.HubIcon;
        case PaymentButtonType.MANUAL:
          return paymentIcons.ManualIcon;
        case PaymentButtonType.GENERIPAY:
          return paymentIcons.DebitIcon;
        default:
          return paymentIcons.CashIcon;
      }
    }, [paymentButtonType]);

    if (hide) {
      return null;
    }

    const featureSwitch = getFeatureSwitch(paymentButtonType);
    const isDisabledViaFeatureSwitch = !featureSwitch.Enabled;
    const disablementLabel = `${label} is temporarily unavailable. Please use an alternate payment method.`;

    const handleClick = () => {
      if (isDisabledViaFeatureSwitch) {
        dispatch(showPaymentMethodDisabledPopup({ paymentMethod: label, message: disablementLabel }));
        return;
      }

      onClick();
    };

    return (
      <PaymentButtonComponent
        ref={ref}
        onClick={handleClick}
        disabled={disabled}
        isDisabledViaFeatureSwitch={isDisabledViaFeatureSwitch}
        data-testid={paymentButtonTestId(paymentButtonType, isDisabledViaFeatureSwitch)}
        allowTextWrapping={paymentButtonType === PaymentButtonType.HUB}
      >
        <PaymentIcon disabled={disabled || isDisabledViaFeatureSwitch} />
        <PaymentButtonLabel>{label}</PaymentButtonLabel>
        {isDisabledViaFeatureSwitch && (
          <DisablementContent title={disablementLabel}>
            <WarningIcon height={16} width={16} fill={colors.dutchie.gray70} />
          </DisablementContent>
        )}
      </PaymentButtonComponent>
    );
  }
);

const DisabledPaymentButtonStyles = css`
  background: ${colors.dutchie.gray20};

  label {
    color: ${colors.dutchie.gray70};
  }
`;

export const PaymentButtonComponent = styled.button<{
  allowTextWrapping?: boolean;
  isDisabledViaFeatureSwitch?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 1rem;
  gap: 0.75rem;
  height: 5.5rem;
  min-width: 6.375rem;
  box-sizing: border-box;

  background: none;
  border-radius: 0.375rem;
  border: 1px solid ${colors.dutchie.grey65};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06);

  & > svg {
    flex: 0 0 1.5rem;
  }

  &:disabled {
    ${DisabledPaymentButtonStyles}
  }

  ${({ isDisabledViaFeatureSwitch }) =>
    isDisabledViaFeatureSwitch
      ? css`
          ${DisabledPaymentButtonStyles}

          &:hover ${DisablementContent} {
            svg path {
              fill: ${colors.dutchie.gray80};
            }
          }
        `
      : css`
          &:hover:not(:disabled) {
            border-color: ${colors.dutchie.grey40};
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
            cursor: pointer;
          }
        `}

  ${({ allowTextWrapping }) =>
    allowTextWrapping &&
    css`
      gap: 0.5rem;

      label {
        white-space: normal;
        line-height: 100%;
      }
    `}
`;

export const PaymentButtonLabel = styled.label`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  white-space: nowrap;
`;

const DisablementContent = styled.div`
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
`;
