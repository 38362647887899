import styled, { css } from 'styled-components';
import { Popper, Autocomplete } from '@mui/material';
import { Search } from '../../search';
import { MenuItem } from '../../menu-item';

export const DropdownMenuFooter = styled.div`
  border-top: 1px solid var(--color-greyscale-grey-90);
  height: 50px;
  display: flex;
  gap: var(--sizes-70);
  padding: 0 var(--sizes-50);
  align-items: center;
  justify-content: flex-start;

  > button {
    padding: 0;
    min-width: auto;
  }
`;
export const AutocompleteContainer = styled(Autocomplete)`
  display: flex;
`;
export const PopperLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .MuiAutocomplete-noOptions {
    font: var(--font-regular-14pt-semibold);
    text-align: center;
  }
`;
export const SearchInput = styled(Search)`
  margin: var(--sizes-40) var(--sizes-50);

  .MuiInputBase-formControl,
  .MuiAutocomplete-input {
    padding: 0 !important;
  }
`;

export const DropdownMenuPopper = styled(Popper)<{
  $emptyMultiselect?: boolean;
  $maxWidth?: string;
  $singleSelectionEnabled: boolean;
  $width?: string;
}>`
  background-color: var(--color-primary-white);
  border-radius: 9px;
  border: 1px solid #e1e4e8;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  color: var(--color-greyscale-black);
  font: var(--font-regular-14pt-normal);
  height: ${({ $singleSelectionEnabled, $emptyMultiselect }) =>
    $singleSelectionEnabled || $emptyMultiselect ? 'auto' : '394px'};
  width: ${({ $width }) => $width ?? '396px'};
  z-index: 1400;

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}

  .MuiAutocomplete-paper {
    width: 100%;
    color: unset;
    border-radius: unset;
    box-shadow: unset;
    background-color: unset;
    transition: unset;
  }

  .MuiAutocomplete-popper {
    min-height: 264px;
  }

  .MuiAutocomplete-listbox {
    padding: 0;
    max-height: 240px;
  }

  .MuiMenuItem-root {
    padding: 0;
    min-height: 44px;
  }
`;

export const DropdownMenuItem = styled(MenuItem)<{
  $singleSelection?: boolean;
  $hasSecondaryLabel?: boolean;
  variant: string;
}>`
  &.MuiMenuItem-root {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    padding: var(--sizes-30) var(--sizes-70);

    ${({ variant }) =>
      variant === 'checkbox' &&
      css`
        padding: var(--sizes-30) var(--sizes-50);
      `}

    .menu-item--primary-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }

  ${({ $hasSecondaryLabel }) =>
    $hasSecondaryLabel &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        margin-bottom: 0;
      }
    `}
`;
