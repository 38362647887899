import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { errorNotification } from 'store/actions/NotificationsActions';
import { setPreOrderMetadata, clearCustomerDetails } from 'store/actions/CustomerActions';
import { PreorderMetadata } from 'pages/CreatePreOrderPage/PreorderMetadata';
import { PreorderMetadataValues } from 'models/Customer';
import { getMinorAge } from 'util/Helpers';
import { differenceInYears } from 'date-fns';
import { ConfirmationPopup } from 'components/popups';

const Popup = styled(ConfirmationPopup)`
  width: 576px;
`;

const PopupBody = styled.div`
  margin: 0 2rem;
`;

type CreatePreOrderPopupProps = {
  isVisible: boolean;
  hide: () => void;
  onCheckIn: (values: PreorderMetadataValues) => void;
  anonymousDob?: Date;
};

export const CreatePreOrderPopup: FC<CreatePreOrderPopupProps> = ({ isVisible, hide, onCheckIn, anonymousDob }) => {
  const dispatch = useDispatch();
  const preorderConfig = useSelector((state: State) => state.settings.preorderSettings);
  const features = useSelector((state: State) => state.settings.features);
  const locationSettings = useSelector((state: State) => state.settings.locationSettings);
  const customerDetails = useSelector((state: State) => state.customer.details);
  const preorderMetadata = useSelector((state: State) => state.customer.PreOrderMetadata);
  const [formPreorderMetadata, setFormPreorderMetadata] = useState<PreorderMetadataValues>();
  const [loading, setLoading] = useState(false);

  const showDemographicsFields = useMemo(() => {
    return customerDetails?.DOB !== '' ? false : features.CollectAnonymousDemo;
  }, [features, customerDetails]);

  const hideAndClearDetails = () => {
    hide();
    dispatch(clearCustomerDetails());
  };

  const confirmMetadata = async () => {
    if (loading) {
      return;
    }

    if (!formPreorderMetadata?.orderSourceId) {
      dispatch(errorNotification('You must select an order source'));
      return;
    }

    if (!formPreorderMetadata?.orderTypeId) {
      dispatch(errorNotification('You must select an order type'));
      return;
    }

    if (!formPreorderMetadata?.dueDate) {
      dispatch(errorNotification('You must select a pickup/delivery date'));
      return;
    }

    if (!formPreorderMetadata?.responsibleForSale) {
      dispatch(errorNotification('You must select a budtender responsible for this sale'));
      return;
    }

    if (formPreorderMetadata?.orderTypeId === 2) {
      if (formPreorderMetadata.createNewAddress && !formPreorderMetadata.newAddress.street) {
        dispatch(errorNotification('You must enter a street name'));
        return;
      }
      if (formPreorderMetadata.createNewAddress && !formPreorderMetadata.newAddress.city) {
        dispatch(errorNotification('You must enter a city'));
        return;
      }
      if (formPreorderMetadata.createNewAddress && !formPreorderMetadata.newAddress.state) {
        dispatch(errorNotification('You must enter a state'));
        return;
      }
      if (formPreorderMetadata.createNewAddress && !formPreorderMetadata.newAddress.postal_code) {
        dispatch(errorNotification('You must enter a zip code'));
        return;
      }
    }

    if (showDemographicsFields) {
      if (!formPreorderMetadata?.dob) {
        dispatch(errorNotification('You must select a date of birth'));
        return;
      }

      if (!formPreorderMetadata?.gender) {
        dispatch(errorNotification('You must select a gender'));
        return;
      }

      if (!formPreorderMetadata?.postalCode) {
        dispatch(errorNotification('You must select a postal code'));
        return;
      }

      if (!formPreorderMetadata?.customerTypeId) {
        dispatch(errorNotification('You must select a customer type'));
        return;
      }

      if (features.BlockMinorsFromCheckInFF) {
        const guestAge = formPreorderMetadata.dob;
        const defaultMinorAge = getMinorAge(locationSettings, false);

        const guestYears = differenceInYears(new Date(), new Date(guestAge));

        if (guestYears < defaultMinorAge) {
          dispatch(errorNotification(`Patient is under the age of ${defaultMinorAge}. You cannot proceed`));
          return;
        }
      }
    }

    setLoading(true);
    try {
      await dispatch(setPreOrderMetadata(formPreorderMetadata));
      await onCheckIn(formPreorderMetadata);
      hide();
    } finally {
      setLoading(false);
    }
  };

  if (!preorderConfig) {
    return null;
  }

  return (
    <Popup
      title='Order details'
      hide={hideAndClearDetails}
      isVisible={isVisible}
      contentMaxHeight='calc(100vh - 200px)'
      automationId='preorder-details-popup'
      confirm={{
        text: 'Create order',
        onClick: confirmMetadata,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hideAndClearDetails,
      }}
    >
      <PopupBody>
        {preorderConfig && (
          <>
            <PreorderMetadata
              config={preorderConfig}
              collectAnonymousDemographics={showDemographicsFields}
              values={
                {
                  ...preorderMetadata,
                  dob: anonymousDob,
                  customerTypeId: locationSettings?.DefaultCustomerTypeId ?? undefined,
                } as PreorderMetadataValues
              }
              customerDetails={customerDetails}
              onChange={setFormPreorderMetadata}
            />
          </>
        )}
      </PopupBody>
    </Popup>
  );
};
