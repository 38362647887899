import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { State } from 'store';
import { deleteDocument } from 'store/actions/CustomerActions';
import { callback } from 'models/Misc';

const Message = styled.p`
  padding: 1rem 2rem;
`;

type DeleteDocumentPopupProps = {
  isVisible: boolean;
  hide: callback;
  PatientId: number;
  FileId?: number;
};

export const DeleteDocumentPopup: FC<DeleteDocumentPopupProps> = ({ PatientId, FileId, isVisible, hide }) => {
  const dispatch = useDispatch();
  const deletingDocument = useSelector((state: State) => state.customer.deletingDocument);

  const handleDeleteDocument = async () => {
    await dispatch(deleteDocument({ PatientId, FileId }));
    hide();
  };

  return (
    <ConfirmationPopup
      small
      isVisible={isVisible}
      hide={hide}
      title='Delete'
      confirm={{
        text: 'Confirm',
        disabled: deletingDocument,
        onClick: handleDeleteDocument,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <Message>Are you sure you want to delete the document?</Message>
    </ConfirmationPopup>
  );
};
