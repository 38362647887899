import { useState, useEffect } from 'react';

import { eloTabletHeight } from 'css/Theme';
import { useAppSelector } from 'util/hooks';

import type { PreorderSummaryProps } from './PreorderSummary';

export const usePreorderSummary = ({ orderMetadata }: PreorderSummaryProps) => {
  const [showAsCollapsed, setShowAsCollapsed] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  // Global state
  const guestAddress = useAppSelector((state) => state.customer.details?.address);
  const guestAddressBook = useAppSelector((state) => state.customer.details?.AddressBook);

  // Computed values
  const deliveryAddress = orderMetadata.createNewAddress
    ? orderMetadata.newAddress
    : guestAddressBook?.find((x) => x.AddressId === orderMetadata.deliveryAddressId) ?? guestAddress;

  useEffect(() => {
    const updateLayout = () => setShowAsCollapsed(window.innerHeight <= eloTabletHeight);
    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, []);

  const toggleSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  return {
    deliveryAddress,
    isSummaryOpen,
    showAsCollapsed,
    toggleSummary,
  };
};
