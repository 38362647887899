import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'store';
import { CheckoutState } from 'store/reducers/CheckoutReducer';
import { Cart } from 'models/Cart';
import { CustomerDetails } from 'models/Customer';

import { CheckoutContext } from '../CheckoutContext';
import { PaymentType } from 'models/Checkout';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

type UseTotalDetailsSectionReturn = {
  addPaymentBtnVisible: boolean;
  amountPortalContainerRef: React.RefObject<HTMLDivElement>;
  cart: Cart;
  checkout: CheckoutState;
  features: Record<string, boolean>;
  guest: CustomerDetails | undefined;
  totalPaid: number;
};

export const useTotalDetailsSection = (): UseTotalDetailsSectionReturn => {
  const checkout = useSelector((state: State) => state.checkout);
  const guest = useSelector((state: State) => state.customer.details);
  const settings = useSelector((state: State) => state.settings);
  const { features } = settings;
  const { dutchiePayPreAuthError } = useDutchiePayPreAuth();
  const { addPaymentBtnVisible, amountPortalContainerRef } = useContext(CheckoutContext);

  const { data: cart } = useGetCartDetails();

  const dutchiePayPreAuthPaymentAmount =
    checkout.payment?.methods?.find(({ type }) => type === PaymentType.Dutchie)?.amount ?? 0;

  // if we fail the dutchiepay pre-auth flow, we don't wanna include that pre-auth amount in the totalPaid
  // so we're able to use another payment method to checkout and the total amount due is accurate
  const totalPaid = dutchiePayPreAuthError ? checkout.totalPaid - dutchiePayPreAuthPaymentAmount : checkout.totalPaid;

  return {
    addPaymentBtnVisible,
    amountPortalContainerRef,
    cart,
    checkout,
    features,
    guest,
    totalPaid,
  };
};
