import styled, { css } from 'styled-components';
import { colors } from 'css/Theme';
import { rgba } from 'polished';
import { StyleVariant } from '../TableProps';

const roundedWhiteStyles = css`
  width: 100%;
  background: ${colors.dutchie.primaryWhite};
  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 0.75rem;

  thead,
  tfoot,
  tbody {
    background: none;
  }

  thead th {
    background: ${colors.dutchie.primaryWhite};
  }

  th,
  td {
    color: ${colors.dutchie.almostBlack};
    font-size: 0.8125rem;
    line-height: 1.125rem;
    border-bottom-color: ${colors.dutchie.gray20};
  }

  th,
  th button {
    color: ${colors.dutchie.gray80};
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 600;
  }

  tr:first-child {
    th:first-child {
      border-top-left-radius: 0.75rem;
    }

    th:last-child {
      border-top-right-radius: 0.75rem;
    }
  }

  *:not(.hasFooter) tr:last-child {
    td:first-child {
      border-bottom-left-radius: 0.75rem;
    }

    td:last-child {
      border-bottom-right-radius: 0.75rem;
    }
  }

  tfoot {
    position: sticky;
    bottom: 0;
    background: ${colors.dutchie.primaryWhite};

    tr td {
      border-top: 1px solid ${colors.dutchie.gray20} !important;
    }
  }
`;

const whiteBackgroundStyles = css`
  tfoot,
  tbody {
    background-color: ${colors.white};
  }
`;

const noWrapStyles = css`
  th,
  td {
    white-space: nowrap;
  }
`;

export const StyledTable = styled.table<{ styleVariant: StyleVariant }>`
  font-size: 0.8125rem;
  width: 100%;
  position: relative;
  border-collapse: separate;

  th,
  td {
    border-bottom: 1px solid ${colors.dutchie.shadowGrey};
    padding: 1rem 1rem;

    > * {
      vertical-align: middle;
    }
  }

  thead {
    position: sticky;
    background: white;
    top: 0;

    th {
      vertical-align: middle;
    }
  }

  th,
  th button {
    color: #788287;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.5%;
    text-align: left;
    min-width: max-content;
  }

  th button {
    align-items: center;
    display: flex;
  }

  tr.clickable {
    cursor: pointer;

    &:hover {
      background-color: ${colors.lightGrey};
    }
  }

  tr.selected {
    background-color: ${rgba(colors.dutchie.blue, 0.05)};
  }

  tr:last-child td {
    border: none;
  }

  ${({ styleVariant }) => {
    switch (styleVariant) {
      case StyleVariant.whiteBackground:
        return whiteBackgroundStyles;
      case StyleVariant.noWrap:
        return noWrapStyles;
      case StyleVariant.roundedWithWhiteBackground:
        return roundedWhiteStyles;
      default:
        return '';
    }
  }}
`;
