import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { isAndroid } from 'util/hooks';
import { setCfdStatus } from 'store/actions/SettingsActions';
import { Cart } from 'models/Cart';
import { Cfd, CfdMessageDestination, CfdMessageCallback, CfdMessage } from '@dutchie/capacitor-cfd';
import { getCFDCustomImage } from 'api/helpers/getCFDCustomImage';
import { logger, customEventKeys } from 'util/logger';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

export type CFDSessionData = {
  locationName?: string;
  loggedIn?: boolean;
  customIdleImage?: string;
  features: Record<string, boolean>;
};

export type CFDCartData = {
  cart?: Cart;
  checkoutComplete?: boolean;
  changeDue?: number;
};

export type StatusEventData = {
  available: boolean;
};

export type CFDConnectionStatusContext = {
  isConnected: boolean;
};

export const ANDROID_CFD_PATH = 'android-cfd';

export const useAndroidCfdPlugin = (path: string) => {
  const dispatch = useDispatch();
  const isNotCfdDevice = path !== `/${ANDROID_CFD_PATH}`;
  const isCfdConnected = useSelector((state: State) => state.settings.isCfdConnected);

  const locationName = useSelector((state: State) => state.user.selectedLocation?.location_name);
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const features = useSelector((state: State) => state.settings.features);
  const cart = useSelector((state: State) => state.cart);
  const checkoutComplete = useSelector((state: State) => state.checkout.success);
  const changeDue = useSelector((state: State) => state.checkout.changeDue);
  const [customIdleImage, setCustomIdleImage] = useState<string>();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { data: cartDetails } = useGetCartDetails();

  const clearCart = () =>
    updateCFDCartDetails({
      cart: undefined,
      checkoutComplete: false,
      changeDue: 0,
    });

  const initCfdLogin = useCallback(() => {
    if (isAndroid && isNotCfdDevice) {
      initializeCfdEvent({
        locationName,
        loggedIn: !!register,
        customIdleImage,
        features,
      });
      clearCart();
    }
  }, [locationName, register, customIdleImage, features, isNotCfdDevice]);

  const updateCfdCart = useCallback(() => {
    if (!isAndroid || !isNotCfdDevice) {
      return;
    }

    if (path !== '/cart') {
      clearCart();
    } else if (isAnonymousCartLDFlagEnabled && cartDetails.ShipmentId !== 0) {
      updateCFDCartDetails({
        cart: cartDetails,
        checkoutComplete,
        changeDue,
      });
    } else if (!isAnonymousCartLDFlagEnabled && cart?.details) {
      updateCFDCartDetails({
        cart: cart.details,
        checkoutComplete,
        changeDue,
      });
    }
  }, [cart, cartDetails, isAnonymousCartLDFlagEnabled, checkoutComplete, changeDue, path, isNotCfdDevice]);

  // Launches the new CFD url on the CFD hardware
  const launchCFDUrl = useCallback(
    async (isInitialConnection?: boolean) => {
      if (isAndroid && isNotCfdDevice) {
        const url = `${window.location.protocol}//${window.location.host}/${ANDROID_CFD_PATH}`;
        await Cfd.loadCfd({ url: url });
        // Only set the new connection status if this is the first time running
        if (isInitialConnection) {
          const result = await Cfd.available();
          const isConnected = result.available as boolean;
          dispatch(setCfdStatus(isConnected));
        }
      }
    },
    [dispatch, isNotCfdDevice]
  );

  // Updates connnection status for the CFD hardware
  const isCfdConnectedHandler = useCallback(
    (param: CfdMessage<StatusEventData>) => {
      const isConnected = param.details.available;
      dispatch(setCfdStatus(isConnected));
      logger.info(`Android CFD v2 ${isConnected ? 'connected' : 'disconnected'}`, {
        key: customEventKeys.cfd.status,
        isConnected,
      });
      launchCFDUrl();
    },
    [dispatch, launchCFDUrl]
  );

  // Adds connection listener to CFD hardware
  useCfdStatusEvent(isCfdConnectedHandler);

  useCfdRequestState(() => {
    if (isNotCfdDevice) {
      initCfdLogin();
      updateCfdCart();
    }
  });

  // Launches the CFD url for the first time
  useEffect(() => {
    launchCFDUrl(true);
  }, [launchCFDUrl]);

  useEffect(() => {
    if (isAndroid && isNotCfdDevice) {
      getCFDCustomImage().then(setCustomIdleImage);
    }
  }, [isNotCfdDevice, locationName]);

  useEffect(() => {
    initCfdLogin();
  }, [initCfdLogin, locationName, register, features, isCfdConnected, isNotCfdDevice]);

  useEffect(() => {
    updateCfdCart();
  }, [updateCfdCart, cart, cartDetails, checkoutComplete, changeDue, path, isCfdConnected, isNotCfdDevice]);
};

export const AndroidCfdListener = ({ path }: { path: string }) => {
  useAndroidCfdPlugin(path);
  return null;
};

export const useCfdPluginMessage = <T>(props: { onMessage: CfdMessageCallback<T>; messageName?: string }) => {
  const listener = useCallback(
    (message) => {
      // parse details property. it should always be coming back as a string,
      // but isn't for some reason. appears to be an object when message is sent from cfd
      if (typeof message.details === 'string') {
        message.details = JSON.parse(message.details);
      }

      // remove isTrusted property that gets appended
      const copy = Object.assign({}, message);
      delete copy['isTrusted'];

      // send event to listeners
      if (props.messageName && props.messageName === message.name) {
        props.onMessage(copy);
      }
    },
    [props]
  );

  useEffect(() => {
    window.addEventListener('onCfdPluginMessage', listener);
    return () => window.removeEventListener('onCfdPluginMessage', listener);
  }, [listener]);
};

//loads the cart data on the CFD
export function updateCFDCartDetails(data: CFDCartData): Promise<{ sent: boolean }> {
  return Cfd.sendMessage({
    destination: CfdMessageDestination.cfd,
    name: 'updateCartEvent',
    details: data,
  });
}

// initializeCfdEvent -> fires when lspLocation changes in redux
export function initializeCfdEvent(data: CFDSessionData): Promise<{ sent: boolean }> {
  return Cfd.sendMessage({
    destination: CfdMessageDestination.cfd,
    name: 'initializeCfdEvent',
    details: data,
  });
}

//Used by the CFD on init to ask for the latest cart and login state
export function cfdRequestState(): Promise<{ sent: boolean }> {
  return Cfd.sendMessage({
    destination: CfdMessageDestination.register,
    name: 'cfdRequestState',
    details: null,
  });
}

export const useCfdRequestState = (onEvent: CfdMessageCallback<Object>) =>
  useCfdPluginMessage({ messageName: 'cfdRequestState', onMessage: onEvent });
export const useUpdateCartEvent = (onEvent: CfdMessageCallback<CFDCartData>) =>
  useCfdPluginMessage({ messageName: 'updateCartEvent', onMessage: onEvent });
export const useCfdInitEvent = (onEvent: CfdMessageCallback<CFDSessionData>) =>
  useCfdPluginMessage({ messageName: 'initializeCfdEvent', onMessage: onEvent });
export const useCfdStatusEvent = (onEvent: CfdMessageCallback<StatusEventData>) =>
  useCfdPluginMessage({ messageName: 'CfdStatus', onMessage: onEvent });
