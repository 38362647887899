import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { warningNotification } from 'store/actions/NotificationsActions';
import { PusherChannelConfiguration, PusherChannelType } from 'models/Pusher';
import { post } from 'api/HttpHelpers';

export const getReturnsPusherConfiguration = createPusherConfigurationAsyncThunk(PusherChannelType.Returns);
export const cleanReturnsPusherConfiguration = createAction('cleanReturnsPusherConfiguration');

export function createPusherConfigurationAsyncThunk(channelType: PusherChannelType) {
  return createAsyncThunk(`get${channelType}PusherConfiguration`, async (args, { dispatch }) => {
    const response = await post<PusherChannelConfiguration>(`pusher/config/${channelType}`).catch((error) => {
      const pusherResult: PusherChannelConfiguration = { PusherKey: '', PusherCluster: '', ChannelName: '' };
      dispatch(warningNotification('Unable to connect for real-time notifications'));
      return pusherResult;
    });
    return response;
  });
}
