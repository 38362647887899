import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from '@capacitor/storage';
import { setBaseUrl } from 'api/HttpHelpers';
import { UserSettings, Register, RegisterTransactionsRequest, CustomerSearchConfig } from 'models/Misc';
import * as api from 'api/PosApi';
import * as DeliveryApi from 'api/DeliveryApi';
import { AppDispatch, State } from 'store';
import * as settingsApi from 'api/SettingsApi';
import { HelpDeskLinkResponse, Location } from 'models/Location';
import { FeatureFlagsPaymentResultNotification, MassDphCreds } from 'models/Settings';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { useHistory } from 'react-router';
import { SelectLocationAction } from './UserActions';
import { ConnectingStates, DisconnectedStates, getWebViewAppRegion, isAndroid, isWebViewApp } from 'util/hooks';
import { Peripheral, isNativeReceiptPrinter } from 'util/capacitor/peripheral';
import { RegionList } from 'util/RegionList';
import { loadCardStatusDisplayOptions } from './GuestListActions';
import { PersistedValueKey, getPersistedValue, setPersistedValue } from 'util/persisted-values';
import { getStoredHardware } from 'util/hardwareLibrary/hardware-library-utils';
import { setupRegisterNew, setupRegisterOld } from 'store/helpers/settingsHelpers/selectRegister';
import { getIsHardwareLibraryActive } from 'util/hooks/launch-darkly/useHardwareLibrary';
import { LDServerMigrationConfig } from 'components/layout/ServerMigrationUI/useServerMigrationConfig';
import { getIsLoginImprovementsFlagEnabled } from 'util/hooks/launch-darkly/useLoginImprovementsFlag';
import { getHelpDeskLinkQuery } from 'queries/posv3/lsplocation/get-helpdesk-link';

export type PeripheralStatusUpdate = {
  deviceId?: string;
  status: string;
};

export const popCashDrawer = createAsyncThunk('openCashDrawer', async (args, { dispatch, getState }) => {
  try {
    const { settings } = getState() as State;

    if (getIsHardwareLibraryActive()) {
      const { receiptPrinter } = getStoredHardware();
      // Pop drawer on selected printer
      await receiptPrinter?.popCashDrawer();

      // Ensure cash drawer is opened on ELO when connected to the stand
      if (isAndroid && (!receiptPrinter || !isNativeReceiptPrinter(receiptPrinter))) {
        await Peripheral.openCashDrawer();
      }
    } else {
      const selectedPrinterId = settings.userSettings.selectedReceiptPrinter?.PrinterId;
      const isSelectedPrinterLocal = settings.userSettings.selectedReceiptPrinter?.LocalPrinter;
      const locId = window.sessionStorage.getItem('LocId');

      if (isAndroid && isSelectedPrinterLocal) {
        await Peripheral.openCashDrawer();
      } else if (selectedPrinterId && locId && !isSelectedPrinterLocal) {
        await settingsApi.popCashDrawer({
          PrinterId: selectedPrinterId,
          LocId: locId,
        });
      } else {
        throw new Error('Unable to connect to cash drawer');
      }
    }

    dispatch(successNotification('Cash drawer opened'));
  } catch (err) {
    const { message } = err as Error;
    dispatch(errorNotification(message ?? (err as string)));
  }
});

export const assignRegisterToHardware = createAsyncThunk(
  'assignRegisterToHardware',
  async (payload: number, { dispatch }) => {
    const hardwareId = (await getPersistedValue(PersistedValueKey.hardwareId)) ?? uuidv4();
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    setPersistedValue(PersistedValueKey.hardwareId, hardwareId, { path: '/', expires: expires });
    await settingsApi.setRegisterHardwareId(hardwareId, payload);
    dispatch(successNotification('Register Assigned'));

    // refresh registers so the list will contain the assigned HardwareId info
    dispatch(loadRegisters());
    return payload;
  }
);

export const updateReceiptPrinterStatus = createAsyncThunk(
  'updateReceiptPrinterStatus',
  (payload: PeripheralStatusUpdate, { dispatch, getState }) => {
    const state = getState() as State;
    if (payload.deviceId === state.settings.userSettings.selectedReceiptPrinter?.PrinterId) {
      const currentState = state.settings.userSettings.selectedReceiptPrinter?.Status || '';
      const currentName = state.settings.userSettings.selectedReceiptPrinter?.Name;
      const newState = payload.status;
      if ((ConnectingStates.includes(currentState) || currentState === '') && DisconnectedStates.includes(newState)) {
        dispatch(errorNotification(`Receipt Printer ${currentName} Lost Connection`));
      }

      if (ConnectingStates.includes(newState) && (DisconnectedStates.includes(currentState) || currentState === '')) {
        dispatch(successNotification(`Receipt Printer ${currentName} Reconnected`));
      }
    } else if (payload.deviceId === state.settings.userSettings.selectedFulfillmentPrinter?.PrinterId) {
      const currentState = state.settings.userSettings.selectedFulfillmentPrinter?.Status || '';
      const currentName = state.settings.userSettings.selectedFulfillmentPrinter?.Name;
      const newState = payload.status;
      if ((ConnectingStates.includes(currentState) || currentState === '') && DisconnectedStates.includes(newState)) {
        dispatch(errorNotification(`Fulfillment Printer ${currentName} Lost Connection`));
      }

      if (ConnectingStates.includes(newState) && (DisconnectedStates.includes(currentState) || currentState === '')) {
        dispatch(successNotification(`Fulfillment Printer ${currentName} Reconnected`));
      }
    }

    return payload;
  }
);

export const updateLabelPrinterStatus = createAsyncThunk(
  'updateLabelPrinterStatus',
  (payload: PeripheralStatusUpdate, { dispatch, getState }) => {
    const state = getState() as State;
    if (payload.deviceId === state.settings.userSettings.selectedLabelPrinter?.PrinterId) {
      const currentState = state.settings.userSettings.selectedLabelPrinter?.Status || '';
      const currentName = state.settings.userSettings.selectedLabelPrinter?.Name || '';
      const newState = payload.status;
      if ((ConnectingStates.includes(currentState) || currentState === '') && DisconnectedStates.includes(newState)) {
        dispatch(errorNotification(`Label Printer ${currentName} Lost Connection`));
      }

      if (ConnectingStates.includes(newState) && (DisconnectedStates.includes(currentState) || currentState === '')) {
        dispatch(successNotification(`Label Printer ${currentName} Reconnected`));
      }
    }

    return payload;
  }
);

// Consider saveUserSettingsV2 if changes are made to saveUserSettings
export const saveUserSettings = createAction('saveUserSettings', (payload: Partial<UserSettings>) => ({ payload }));

export const setCfdStatus = createAction('setCfdStatus', (payload: boolean) => ({ payload }));

export const selectUserRegion = createAction('selectUserRegion', (payload: string | undefined) => ({ payload }));

export const loadRegion = createAsyncThunk('loadRegion', async () => {
  if (isWebViewApp) {
    const regionInfo = await getWebViewAppRegion();
    return regionInfo?.name.toLowerCase();
  } else {
    const region = (await Storage.get({ key: 'Region' })).value?.toLowerCase();

    if (region && region in RegionList) {
      setBaseUrl(RegionList[region]);
    }
    return region;
  }
});

export const saveUserTableConfig = createAsyncThunk(
  'saveUserTableConfig',
  async (payload: Array<CustomerSearchConfig>, { dispatch }) => {
    try {
      await settingsApi.saveUserTableConfig(payload);
      dispatch(successNotification('Saved config'));
    } catch {
      dispatch(errorNotification('Error saving config'));
    }
  }
);

export const setExternalSitesLoaded = createAction<boolean>('setExternalSitesLoaded');

export const setSidebarExpanded = createAction<boolean>('setSidebarExpanded');

export const loadLabels = createAsyncThunk('loadLabels', api.getLabelsList);

export const loadLabelPrinters = createAsyncThunk('loadLabelPrinters', api.getLabelPrintersList);

export const loadReceiptPrinters = createAsyncThunk('loadReceiptPrinters', api.getReceiptPrintersList);

export const loadEnvironmentSettings = createAsyncThunk('loadEnvironmentSettings', api.getEnvironmentDetails);

export const loadPreorderConfig = createAsyncThunk('loadPreorderConfigLazy', async (args, { dispatch }) => {
  try {
    return await settingsApi.getPreorderConfig();
  } catch {
    dispatch(errorNotification('Failed to retrieve preorder configuration'));
    return Promise.reject();
  }
});

export const loadRegisters = createAsyncThunk(
  'loadRegisters',
  async (registerId: string | null | undefined, { dispatch }) => {
    try {
      return await api.getRegistersList(registerId);
    } catch {
      dispatch(warningNotification('Failed to retrieve list of registers'));
      return Promise.reject();
    }
  }
);

export const loadLocationSettings = createAsyncThunk(
  'loadLocationSettings',
  async (setDefaults: boolean, { dispatch }) => {
    const resp = await api.getLocationSettings();
    if (
      resp.Features.find((x) => x.FeatureName === 'RequireValidAllotmentCredentials')?.IsEnabled &&
      resp.Integrations.CanUseMassDPHIntegration
    ) {
      try {
        await settingsApi.validateMassDPHSavedCreds();
        resp.MassDphValid = true;
      } catch {
        resp.MassDphValid = false;
        dispatch(errorNotification('Your Mass DPH Credentials are Invalid'));
      }
    }
    if (resp.Features.find((x) => x.FeatureName === 'EnableDelivery')?.IsEnabled) {
      try {
        const geocode = await DeliveryApi.getLocationGeocode();
        resp.Lat = geocode.Lat;
        resp.Lng = geocode.Lng;
      } catch (ex) {
        resp.Lat = 0;
        resp.Lng = 0;
      }
    }

    return { resp, setDefaults };
  }
);

export const loadUserSettings = createAsyncThunk('loadUserSettings', settingsApi.getUserSettings);

export const loadBuildNumber = createAsyncThunk('loadBuildNumber', api.getBuildNumber);

export const validateMassDPHEnteredCredentials = createAsyncThunk(
  'validateMassDPHEnteredCredentials',
  async (creds: MassDphCreds, { dispatch }) => {
    try {
      await settingsApi.validateMassDPHEnteredCreds(creds);
      dispatch(successNotification('Credentials are valid'));
      return true;
    } catch (e) {
      dispatch(errorNotification(`Mass DPH validation failed:\n${e}`));
      return Promise.reject();
    }
  }
);

export const validateMassDPHSavedCredentials = createAsyncThunk(
  'validateMassDPHSavedCredentials',
  async (args, { dispatch }) => {
    try {
      await settingsApi.validateMassDPHSavedCreds();
      dispatch(successNotification('Credentials are valid'));
      return true;
    } catch (e) {
      dispatch(errorNotification(`Mass DPH validation failed:\n${e}`));
      return Promise.reject();
    }
  }
);

export const loadPermissions = createAsyncThunk('loadPermissions', api.getPermissions);

export const loadValidatedForms = createAsyncThunk('loadValidatedForms', settingsApi.getValidatedForms);

export const selectRegister = createAsyncThunk(
  'selectRegisterV2',
  async (payload: Register | undefined, { dispatch, getState }) => {
    const { settings } = getState() as State;
    if (getIsHardwareLibraryActive()) {
      setupRegisterNew(payload, dispatch, settings);
    } else {
      setupRegisterOld(payload, dispatch, settings);
    }
    return payload;
  }
);

export const loadDefaultStatuses = createAsyncThunk('loadDefaultStatuses', settingsApi.getDefaultStatuses);

export const loadCancellationReasons = createAsyncThunk('loadCancellationReasons', settingsApi.getCancellationReasons);

export const loadVisitReasons = createAsyncThunk('loadVisitReasons', settingsApi.getVisitReasons);

export const loadVoidReasons = createAsyncThunk('loadVoidReasons', settingsApi.getVoidReasons);

export const loadCustomerTypes = createAsyncThunk('loadCustomerTypes', settingsApi.getCustomerTypes);

export const loadHelpDeskLink = createAsyncThunk('loadHelpDeskLink', api.getHelpDeskLink);

export const loadRegisterTransactions = createAsyncThunk(
  'loadRegisterTransactions',
  async (args: RegisterTransactionsRequest, { dispatch }) => {
    try {
      return await settingsApi.getRegisterTransactions(args);
    } catch {
      dispatch(warningNotification('Failed to retrieve list of register transactions'));
      return Promise.reject();
    }
  }
);

export const loadBackOfficeSettings = createAsyncThunk('loadBackOfficeSettings', async (args, { dispatch }) => {
  const { isLoginImprovementsFlagEnabled } = getIsLoginImprovementsFlagEnabled();
  dispatch(loadLabels());
  dispatch(loadLabelPrinters());
  dispatch(loadReceiptPrinters());
  dispatch(loadLocationSettings(true));
  dispatch(loadValidatedForms());
  dispatch(loadPreorderConfig());
  dispatch(loadDefaultStatuses());
  dispatch(loadCancellationReasons());
  dispatch(loadVoidReasons());
  dispatch(loadCustomerTypes());
  if (isLoginImprovementsFlagEnabled) {
    await getHelpDeskLinkQuery(dispatch as AppDispatch);
  } else {
    dispatch(loadHelpDeskLink());
  }
  dispatch(loadCardStatusDisplayOptions());
});

export const curriedSelectLocation = createAsyncThunk(
  'curriedSelectLocation',
  async (args: { history?: ReturnType<typeof useHistory>; location?: Location }, { dispatch }) => {
    await dispatch(SelectLocationAction(args.location));

    await dispatch(selectRegister(undefined));

    dispatch(loadBackOfficeSettings());

    if (args.history) {
      args.history.push('/');
    }
  }
);

export const featuresUpdated = createAction('featuresUpdated', (payload: FeatureFlagsPaymentResultNotification) => ({
  payload,
}));

export const settingsUpdated = createAction('settingsUpdated', (payload: FeatureFlagsPaymentResultNotification) => ({
  payload,
}));

export const updateHelpDeskLink = createAction('updateHelpDeskLink', (payload: HelpDeskLinkResponse) => ({ payload }));

export const setServerMigrationConfig = createAction(
  'setServerMigrationConfig',
  (payload: LDServerMigrationConfig) => ({ payload })
);
