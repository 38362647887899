import React, { forwardRef } from 'react';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { DatePickerHeader, Range } from './DatePickerHeader';
import { DatePickerBase, CalendarInput } from './StyledDatePicker';
import { DatePickerMode, useNumberFormatPattern, useDateFormat } from './common';
import 'react-datepicker/src/stylesheets/datepicker.scss';

type PermittedReactDatePickerProps = Omit<ReactDatePickerProps, 'dateFormat' | 'showTimeSelect'>;

export type DatePickerProps = PermittedReactDatePickerProps & {
  automationId?: string;
  endAdornment?: JSX.Element;
  hasError?: boolean;
  hideCalendarIcon?: boolean;
  mode?: DatePickerMode;
  placeholder?: string;
  width?: string;
  yearRange?: Range;
};

export const DatePicker = forwardRef<HTMLInputElement | null, DatePickerProps>(
  (
    {
      automationId,
      className,
      endAdornment,
      hasError = false,
      hideCalendarIcon = false,
      mode = 'date',
      onChange,
      placeholder,
      selected,
      width = '206px',
      yearRange = { start: 1930, end: 2025 },
      ...restProps
    },
    ref
  ) => {
    const dateFormat = useDateFormat(mode);
    const numberFormatPattern = useNumberFormatPattern(mode, selected);

    const calendarInput = (
      <CalendarInput
        ref={ref}
        data-testid={automationId}
        endAdornment={endAdornment ? endAdornment : <></>}
        hasError={hasError}
        width={width}
        startAdornment={!hideCalendarIcon ? <CalendarIcon /> : <></>}
        containerMargin='0'
        type='number'
        format={numberFormatPattern}
        mask='_'
      />
    );

    return (
      <DatePickerBase>
        <ReactDatepicker
          selected={selected}
          onChange={onChange}
          customInput={calendarInput}
          renderCustomHeader={(dateHeaderProps) => <DatePickerHeader yearRange={yearRange} {...dateHeaderProps} />}
          calendarClassName={className}
          dateFormat={dateFormat}
          placeholderText={placeholder ?? dateFormat.toLowerCase()}
          showTimeSelect={mode === 'datetime'}
          popperPlacement='auto'
          {...restProps}
        />
      </DatePickerBase>
    );
  }
);
