import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { Input, Select } from 'components/inputs';
import { Label } from 'components/text';
import { Loader } from 'components/backoffice/loader';

import { updateDevice, getDevicesForProduct } from 'api/CartApi';
import { warningNotification, successNotification } from 'store/actions/NotificationsActions';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CartItem } from 'models/Cart';
import type { DeliveryDevice, MMUROrder } from 'models/MMUR';

type DeviceSelectPopupProps = {
  hide: () => void;
  item: CartItem;
};

export const DeviceSelectPopup: FC<DeviceSelectPopupProps> = ({ hide, item }) => {
  const dispatch = useDispatch();
  const refetchCartDetails = useRefetchCartDetails();

  const { guestId, shipmentId } = useTransactionManager();

  const productId = item?.ProductId || 0;
  const serialNumber = item?.SerialNo || '';

  const [availableOrders, setAvailableOrders] = useState<MMUROrder[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(item.MMURDeviceId?.toString());
  const [availableDevices, setAvailableDevices] = useState<DeliveryDevice[]>([]);
  const [loading, setLoading] = useState(false);

  const form = availableDevices.find((x) => String(x.DeviceId) === selectedDeviceId)?.FormName;
  const order = availableDevices.find((x) => String(x.DeviceId) === selectedDeviceId)?.OrderType;

  useEffect(() => {
    setLoading(true);
    getDevicesForProduct({ ProductId: productId, CustomerId: guestId ?? 0, ShipmentId: shipmentId ?? 0 }).then(
      (data) => {
        setAvailableDevices(data.Devices);
        setAvailableOrders(data.Orders);
        setLoading(false);
      }
    );
  }, [productId, guestId, shipmentId]);

  const reloadCart = () => refetchCartDetails({ guestId, shipmentId });

  const saveDeviceId = async () => {
    if (!selectedDeviceId) {
      dispatch(warningNotification('Please select a device'));
      return;
    }

    try {
      const device = availableDevices.find((x) => String(x.DeviceId) === selectedDeviceId);
      if (!device) {
        dispatch(warningNotification('Please select a device'));
        return;
      }
      const order = availableOrders.find((x) => x.TypeId === device.OrderTypeId);
      if (!order) {
        dispatch(
          warningNotification(
            'Invalid device selected, No Orders for selected device are avaible to dispense delivery devices'
          )
        );
        return;
      }

      const route = order.Routes.find((x) => x.FormId === device.FormId);
      if (!route) {
        dispatch(
          warningNotification(
            'Invalid device selected, No Route for selected device are avaible to dispense delivery devices'
          )
        );
        return;
      }
      await updateDevice({
        ShipmentId: shipmentId ?? 0,
        SerialNumber: serialNumber,
        DeviceId: Number(selectedDeviceId),
        FormId: device.FormId,
        OrderId: order.OrderId,
      });
      reloadCart();
      dispatch(successNotification('Product re-routed'));
      hide();
    } catch {
      dispatch(warningNotification('Error re-routing product'));
    }
  };

  return (
    <ConfirmationPopup
      isVisible
      hide={() => {
        reloadCart();
        hide();
      }}
      title={`Select device for ${serialNumber}`}
      confirm={{
        text: 'Save device',
        onClick: saveDeviceId,
        disabled: !selectedDeviceId || loading,
      }}
    >
      {loading && (
        <Container alignCenter>
          <Loader variant='black' size='2x' />
        </Container>
      )}
      {!loading && (
        <Container>
          <div>
            {selectedDeviceId}
            <Label>Device</Label>
            <Select
              value={selectedDeviceId || ''}
              placeholder={'None found'}
              options={availableDevices.map((device) => ({
                label: `${device.DeviceName} | ${device.FormName} | ${device.OrderType}`,
                value: String(device.DeviceId),
              }))}
              onChange={(c) => setSelectedDeviceId(c)}
            />
          </div>
          <Input label='Order' disabled value={order || ''} />
          <Input label='Form' disabled value={form || ''} />
        </Container>
      )}
    </ConfirmationPopup>
  );
};

const Container = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  gap: 1rem;

  ${({ alignCenter }) => alignCenter && `align-items: center;`}
`;
