import styled from 'styled-components';
import { colors } from 'css/Theme';

export const TextArea = styled.textarea`
  padding: 14px 20px;
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1.5rem;
  resize: none;
  width: 100%;
  color: ${colors.dutchie.almostBlack};

  &:focus {
    box-shadow: none;
    border-color: ${colors.dutchie.almostBlack};
    outline: none;
  }
`;
