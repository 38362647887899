import React, { FC, useCallback } from 'react';
import { callback } from 'models/Misc';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';

const PopupMessage = styled.p`
  padding: 0 3rem;
  text-align: center;
`;

type AddMoreItemsInPreOrderPopupProps = {
  onSuccess: callback;
  hide: callback;
  onCancel?: callback;
};

export const AddMoreItemsInPreOrderPopup: FC<AddMoreItemsInPreOrderPopupProps> = ({ onSuccess, hide, onCancel }) => {
  const onHide = useCallback(() => {
    hide();
    onCancel && onCancel();
  }, [hide, onCancel]);

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={onHide}
      title={'Pre-Order Warning'}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          onHide();
        },
      }}
      confirm={{
        text: 'Save',
        onClick: () => {
          onSuccess();
          hide();
        },
      }}
    >
      <PopupMessage>Item is not in the customer's order. Are you sure you want to add item?</PopupMessage>
    </ConfirmationPopup>
  );
};
