import React from 'react';
import styled from 'styled-components';

// utils
import { colors } from 'css/Theme';

// queries
import { useGuestDetailsQuery } from 'queries/v2/guest/details';

// global components
import { AlertBanner, AlertBannerStyles, Skeleton } from 'components/misc';

type NotesProps = {
  customerId: number;
};

export function Notes(props: NotesProps) {
  const { customerId } = props;

  const { data, isLoading, isError } = useGuestDetailsQuery({ guestId: customerId });

  if (isError) {
    return (
      <AlertBanner marginTop='0' style={AlertBannerStyles.error} title='Error:' text='Unable to load customer notes' />
    );
  }

  if (isLoading) {
    return (
      <Container data-testid='customer-insights-notes-loading-state'>
        <Skeleton height={22} width='100%' />
        <Skeleton height={22} width='75%' />
      </Container>
    );
  }

  if (data?.notes) {
    return <NotesText>{data.notes}</NotesText>;
  }

  return <NotesText>No notes available</NotesText>;
}

type NotesSectionProps = {
  customerId: number;
};

export function NotesSection(props: NotesSectionProps) {
  const { customerId } = props;

  return (
    <Container>
      <Heading>Notes</Heading>
      <Notes customerId={customerId} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Heading = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const NotesText = styled.div`
  color: ${colors.dutchie.grey30};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
