import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { post } from 'api/HttpHelpers';

import { getCartDetailsKeys } from './query-key-factory';
import { getProductId } from 'util/helpers/products/getProductId';
import { setCartItemsLoading } from 'store/actions/CartItemsActions';
import { updateProductQuantities } from './helpers/updateProductQuantities';
import { ProductSearchResult } from '../product/types';
import { getAllProductsKeys } from '../product/query-key-factory';
import { useAppSelector } from 'util/hooks';
import { useRefetchProductsAfterRemoveItem } from 'util/hooks/launch-darkly/useRefetchProductsAfterRemoveItem';

const REMOVE_ITEM_FROM_CART = 'v2/cart/Remove_item_from_cart';

export type RemoveItemFromCartPayload = {
  count?: number;
  guestId: number;
  inventoryId?: number;
  isLoyaltyAsDiscountEnabled: boolean;
  productId: number;
  registerId: number;
  serialNo: string;
  shipmentId: number;
};

const transformToServerPayload = (payload: RemoveItemFromCartPayload, isRemovingSinglePackage: boolean = false) => {
  return {
    AcctId: payload.guestId,
    Cnt: payload.count,
    InventoryId: isRemovingSinglePackage ? payload.inventoryId : undefined,
    LoyaltyAsDiscount: payload.isLoyaltyAsDiscountEnabled,
    ProductId: payload.productId,
    Register: payload.registerId,
    SerialNo: payload.serialNo,
    ShipmentId: payload.shipmentId,
  };
};

export const useRemoveItemFromCartMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const isPackageItemIdsEnabled = useAppSelector((state) => state.settings.features.PackageItemIds);

  const { isRefetchProductsAfterRemoveItemEnabled } = useRefetchProductsAfterRemoveItem();

  return useMutation(
    async (payload: RemoveItemFromCartPayload) => {
      const productId = getProductId({ SerialNo: payload.serialNo, ProductId: payload.productId });

      // Set the loading state for this product to true
      dispatch(setCartItemsLoading({ productId, isLoading: true }));

      // This controls whether we send the InventoryId with the request which will look up
      // the specific package item id to remove. This is only used when the PackageItemIds FF is enabled
      const isRemovingSinglePackage = isPackageItemIdsEnabled && payload.count === 1;

      // Make the request
      return await post(REMOVE_ITEM_FROM_CART, transformToServerPayload(payload, isRemovingSinglePackage));
    },
    {
      onMutate: (payload) => {
        const productList = queryClient.getQueryData<ProductSearchResult[]>(getAllProductsKeys.all);

        const newProductList = updateProductQuantities({
          quantityChanged: -(payload?.count ?? 1),
          serialNumber: payload.serialNo,
          productList,
        });

        if (newProductList !== null) {
          queryClient.setQueryData(getAllProductsKeys.all, newProductList);
        }

        return { productList, newProductList };
      },
      onSuccess: (_data, _payload, context) => {
        if (context?.newProductList === null && isRefetchProductsAfterRemoveItemEnabled) {
          queryClient.invalidateQueries(getAllProductsKeys.all);
        } else {
          // If the rollback LD flag is on, the product will not reappear in the product list
          // Only turn on flag for degraded server performance
        }
      },
      onError: (_error, _payload, context) => {
        if (context?.newProductList === null) {
          return;
        }
        queryClient.setQueryData(getAllProductsKeys.all, context?.productList);
      },
      onSettled: (_data, error, payload) => {
        // Set the loading to false on success or failure
        const productId = getProductId({ SerialNo: payload.serialNo, ProductId: payload.productId });
        dispatch(setCartItemsLoading({ productId, isLoading: false }));

        // Refetch the cart details
        queryClient.invalidateQueries(
          getCartDetailsKeys.one({ guestId: payload.guestId, shipmentId: payload.shipmentId })
        );

        if (error) {
          throw error;
        }
      },
    }
  );
};
