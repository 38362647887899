import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { startOfToday, differenceInCalendarDays } from 'date-fns';
import { ReactComponent as _UploadIcon } from 'assets/icons/upload.svg';
import { colors } from 'css/Theme';
import { Loader } from 'components/backoffice/loader';
import { CustomerDetails } from 'models/Customer';
import { State } from 'store';
import { uploadDocument } from 'store/actions/CustomerActions';
import { Label } from 'components/text';
import { DatePicker } from 'components/inputs';
import { Button } from 'components/buttons';

type FileUploadProps = {
  customer: CustomerDetails;
  className?: string;
};

export const FileUpload: FC<FileUploadProps> = ({ customer, className }) => {
  const dispatch = useDispatch();
  const documentUploading = useSelector((state: State) => state.customer.documentUploading);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [selectedFileType, setSelectedFileType] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<Date>(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  );
  const onDrop = (files: File[]) => {
    const [file] = files;
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      if (binaryStr) {
        setSelectedFile(binaryStr.toString());
        setSelectedFileName(file.name);
        setSelectedFileType(file.type);
      }
    };
    reader.readAsDataURL(file);
  };

  const doUpload = useCallback(() => {
    dispatch(
      uploadDocument({
        ExpirationDate: expirationDate,
        FileBytes: selectedFile,
        FileName: selectedFileName,
        FileType: selectedFileType,
        PatientId: customer.Guest_id,
      })
    );

    setSelectedFileType('');
    setSelectedFile('');
    setSelectedFileName('');
  }, [dispatch, customer, expirationDate, selectedFile, selectedFileName, selectedFileType]);

  const isValid = () => {
    return selectedFile && expirationDate && differenceInCalendarDays(expirationDate, startOfToday()) > 0;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

  return (
    <FileUploadBaseDiv className={className}>
      <LabelSpan>Customer Files</LabelSpan>
      <DropZoneDiv {...getRootProps()}>
        <input {...getInputProps()} />
        {documentUploading ? (
          <UploadAreaDiv>
            <Loader variant='black' size='2x' />
          </UploadAreaDiv>
        ) : (
          <UploadAreaDiv>
            <UploadIcon />
            <UploadTextP>
              {selectedFileName && <> {selectedFileName} </>}
              {!selectedFileName && (
                <>
                  Drag and drop a file
                  <br />
                  or
                  <br />
                  Click to browse for a file
                  <br />
                </>
              )}
            </UploadTextP>
          </UploadAreaDiv>
        )}
      </DropZoneDiv>
      <UploadRow>
        <DatePicker selected={expirationDate} onChange={(val: Date) => setExpirationDate(val)} />
        <UploadButton disabled={!isValid()} type='button' onClick={doUpload}>
          Upload File
        </UploadButton>
      </UploadRow>
    </FileUploadBaseDiv>
  );
};

const FileUploadBaseDiv = styled.div`
  max-width: 27rem;
  width: 100%;
`;

const LabelSpan = styled(Label)`
  margin-bottom: 1rem;
`;

const UploadRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-width: 56rem;
  flex-wrap: wrap;
`;

const DropZoneDiv = styled.div`
  display: flex;
  min-height: 220px;
  border-radius: 0.5rem;
  border: 1px dashed ${colors.dutchie.borderGrey};
  background-color: ${colors.white};
`;

const UploadAreaDiv = styled.div`
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadIcon = styled(_UploadIcon)`
  width: 2.25rem;
  height: 2.25rem;
  margin-bottom: 1rem;
`;

const UploadTextP = styled.p`
  color: ${colors.dutchie.grey};
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
`;

const UploadButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: 0;
`;
