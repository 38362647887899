import React, { PropsWithChildren } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import styled from 'styled-components';

import { useHardwareSettingsForm } from './useHardwareSettingsForm';
import { HardwareSettings } from '../types';

type HardwareSettingsFormProps = {
  formRef: React.MutableRefObject<FormikProps<HardwareSettings> | null>;
};
export const HardwareSettingsForm = ({ children, formRef }: PropsWithChildren<HardwareSettingsFormProps>) => {
  const { initialValues, saveForm } = useHardwareSettingsForm({ formRef });

  return (
    <Formik<HardwareSettings> initialValues={initialValues} onSubmit={saveForm} innerRef={formRef}>
      <StyledForm>{children}</StyledForm>
    </Formik>
  );
};

const StyledForm = styled(Form)`
  height: 100%;
`;
