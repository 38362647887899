import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import { Popup, usePopup } from 'components/popups';

const IdentificationPhotoDiv = styled.div<{ empty?: boolean }>`
  width: 424px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.borderGrey};
  border-radius: 8px;
  overflow: hidden;

  ${({ empty }) =>
    empty &&
    `
        min-height: '17rem';
    `}
`;

const IdentificationImg = styled.img`
  max-width: 27rem;
  height: 15.1875rem;
`;

const PopupImg = styled.img`
  width: 40rem;
  padding: 0 1.5rem 1.5rem;
`;

type IdenfiticationImageProps = {
  alt?: string;
  imageUrl?: string;
};

export const IdentificationImage: FC<IdenfiticationImageProps> = ({ alt, imageUrl }) => {
  const { isVisible: popupVisible, toggle: togglePopup } = usePopup();
  return (
    <IdentificationPhotoDiv empty={!imageUrl} onClick={togglePopup}>
      {!imageUrl && <ImageIcon />}
      {imageUrl && <IdentificationImg src={`data:image/png;base64, ${imageUrl}`} alt={alt} />}
      {imageUrl && (
        <Popup caption={'Identification'} isVisible={popupVisible} hide={togglePopup}>
          <PopupImg src={`data:image/png;base64, ${imageUrl}`} alt={alt} />
        </Popup>
      )}
    </IdentificationPhotoDiv>
  );
};
