import React, { FC, useCallback, useEffect, useState } from 'react';

import { Button } from 'components/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { PreorderProductFilterOption, PreorderProductsSearchFilter } from './PreorderProductSearchFilter';
import { PreorderProductPreview } from './PreorderProductPreview';
import { PreorderCartItem } from 'pages/CreatePreOrderPage';
import { deselectPreorderProduct } from 'store/actions/ProductsActions';
import {
  AddItemsContainer,
  AddItemsTitle,
  AddItemsTop,
  LeftPanel,
  MainContent,
  RightPanel,
  Separator,
} from 'components/cart/AddItemsPanel.styles';

type AddItemPanelProps = {
  cartItems: PreorderCartItem[];
  setAddItemPanelIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCartItems: React.Dispatch<React.SetStateAction<PreorderCartItem[]>>;
};

export const AddItemPanel: FC<AddItemPanelProps> = ({ cartItems, setAddItemPanelIsOpen, setCartItems }) => {
  const dispatch = useDispatch();
  const selectedProduct = useSelector((state: State) => state.products.selectedPreorderProduct);
  const [filters, setFilters] = useState<PreorderProductFilterOption[]>([]);
  const [rightPanel, setRightPanel] = useState<HTMLDivElement | null>(null);

  const handleClose = () => {
    setFilters([]);
    dispatch(deselectPreorderProduct());
    setAddItemPanelIsOpen(false);
  };

  const transitionEnd = useCallback(
    (e) => {
      if (selectedProduct) {
        rightPanel?.classList.remove('content-hidden');
      } else {
        rightPanel?.classList.add('content-hidden');
      }
    },
    [rightPanel, selectedProduct]
  );

  useEffect(() => {
    rightPanel?.addEventListener('transitionend', transitionEnd);

    return () => {
      rightPanel?.removeEventListener('transitionend', transitionEnd);
    };
  }, [transitionEnd, rightPanel]);

  return (
    <AddItemsContainer>
      <AddItemsTop>
        <AddItemsTitle>Add items</AddItemsTitle>
        <Button tertiary onClick={handleClose} automationId='add-item-panel-close-button' ddActionName='Close (add items panel)'>
          Close
        </Button>
      </AddItemsTop>
      <Separator />
      <MainContent>
        <LeftPanel>
          <PreorderProductsSearchFilter filters={filters} setFilters={setFilters} />
        </LeftPanel>
        <RightPanel ref={setRightPanel} isShown={!!selectedProduct} className='content-hidden'>
          {!!selectedProduct && <PreorderProductPreview cartItems={cartItems} setCartItems={setCartItems} />}
        </RightPanel>
      </MainContent>
    </AddItemsContainer>
  );
};
