import React, { ChangeEvent, Children } from 'react';
import { SxProps, SelectProps as MUISelectProps } from '@mui/material';
import { RebrandCaret } from '../icons/rebrand-caret';
import { FormControl } from '../form-control';
import { Label } from '../label';
import { RebrandSelect, StyledPlaceholder, StyledOutlinedInput } from './select-rebrand.styles';

export type SelectProps = MUISelectProps & {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode | string;
  children?: React.ReactNode;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  displayEmpty?: boolean;
  className?: string;
  renderValue?: (selected: string) => React.ReactNode | boolean;
  width?: string;
  required?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  useDefaultIcon?: boolean;
  forceRebrandStyles?: boolean;
  gridColumns?: number;
  tooltip?: string;
  automationId?: string;
  ddActionName?: string;
  id?: string;
  placeholder?: string;
  borderColor?: string;
};

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    label,
    labelPlacement = 'start',
    displayEmpty = true,
    children,
    onChange,
    renderValue,
    value,
    width,
    required = false,
    sx,
    disabled = false,
    useDefaultIcon = false,
    forceRebrandStyles = false,
    tooltip,
    automationId,
    ddActionName,
    gridColumns,
    className = '',
    input,
    MenuProps,
    SelectDisplayProps,
    id,
    placeholder,
    borderColor,
    ...other
  } = props;

  const StyledSelect = RebrandSelect;

  const idFromLabel: string = typeof label === 'string' && label;

  // This grabs the value and label off of each child so that we can use them to find the matching option in the renderValue function below
  const valuesAndLabelsOffChildren = Children.toArray(children).map((child: React.ReactElement) => {
    const { props: childProps } = child;
    if (childProps) {
      return { value: childProps?.value, label: childProps?.children };
    }
    return {};
  });

  return (
    <FormControl
      {...other}
      $fullWidth={!label}
      $labelPlacement={labelPlacement}
      $width={width}
      className={`${className} select-form-control`}
      disabled={disabled}
      forceRebrandStyles={forceRebrandStyles}
      gridColumns={gridColumns}
      ref={ref}
      sx={sx}
    >
      {label && (
        <Label
          className='select-label'
          forceRebrandStyles={forceRebrandStyles}
          id={`select-label_${id ?? idFromLabel}`}
          required={required}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}
      <StyledSelect
        $borderColor={borderColor}
        className='select-select-input'
        data-testid={automationId}
        data-dd-action-name={ddActionName}
        displayEmpty={displayEmpty}
        IconComponent={RebrandCaret}
        input={input ?? <StyledOutlinedInput />}
        labelId={`select-label_${id ?? idFromLabel}`}
        MenuProps={
          MenuProps ?? {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              marginTop: '8px',
            },
          }
        }
        renderValue={
          renderValue ??
          (placeholder &&
            ((selected) => {
              if (!selected) {
                return <StyledPlaceholder>{placeholder}</StyledPlaceholder>;
              }
              const matchingOption = valuesAndLabelsOffChildren.find((option) => option.value === selected);
              return matchingOption?.label;
            }))
        }
        SelectDisplayProps={SelectDisplayProps ?? { style: { paddingRight: useDefaultIcon ? '24px' : '0' } }}
        value={value}
        onChange={onChange}
      >
        {children}
      </StyledSelect>
    </FormControl>
  );
});
