import { OutlinedInput as MUIOutlinedInput } from '@mui/material';
import styled from 'styled-components';
import { Select } from '../../select';
import { InputAdornment } from '../input';

export const StyledAdornmentWrapper = styled(InputAdornment)`
  height: 100%;

  .select-form-control {
    width: 100%;
    border-radius: var(--sizes-10);
  }

  .MuiSelect-outlined {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const StyledOutlinedInput = styled(MUIOutlinedInput)`
  .MuiInputBase-input {
    padding: var(--sizes-10) var(--sizes-70) var(--sizes-10) var(--sizes-20) !important;
  }
`;

export const StyledSelectAdornment = styled(Select)`
  margin-bottom: var(--sizes-0);
  max-height: var(--sizes-70);
  height: var(--sizes-70);

  .MuiOutlinedInput-notchedOutline {
    border-width: var(--sizes-0) !important;
  }

  .MuiSelect-select {
    color: var(--color-greyscale-grey-20);
    font: var(--font-extra-small-12pt-semibold);

    &.Mui-disabled {
      background-color: var(--color-greyscale-grey-90);
    }
  }

  .select-select-input {
    background-color: var(--color-greyscale-grey-90);
    display: flex;
    padding: var(--sizes-20) 0 var(--sizes-20) var(--sizes-10);
    align-items: center;
    height: var(--sizes-70);

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      outline: var(--sizes-0);
      border-width: var(--sizes-0);
    }
  }

  .MuiSelect-icon {
    margin-right: 0;
    top: 14px;
    height: 5px;
    path {
      fill: var(--color-greyscale-grey-20);
    }

    &.Mui-disabled {
      background-color: var(--color-greyscale-grey-90);
    }
  }
`;
