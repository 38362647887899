import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { rgba } from 'polished';
import { State } from 'store';
import { BottomDrawer, LeftContainerDimensions } from 'components/layout';
import { colors, zIndices, headerHeight, panelWidth, eloOriPadBreakpoint, breakpoints } from 'css/Theme';
import { ProvideCartPopups, CartPopups } from 'components/CartPopups';
import { warningNotification } from 'store/actions/NotificationsActions';
import { useDynamicViewHeight, usePatientPolicyValidator } from 'util/hooks';
import { AddItemPanel } from './CartComponents/AddItemPanel';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';
import { CartHeader } from 'components/cart/CartHeader';
import { CartPanels, extendedPanelPortalID } from './CartPanels';
import { CartSummary } from './CartSummary';
import {
  calcCartTotal,
  loadCart,
  clearCheckedInGuest,
  resetCart,
  showProductHistory,
  setDutchiePayPreAuthError,
  setIsFalseNegativePinDebitError,
  setBlockScans,
} from 'store/actions/CartActions';
import { useHistory } from 'react-router';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import { setGuestToRegister } from 'store/actions/GuestListActions';
import { showActionableError } from 'store/actions/ActionableErrorActions';
import { DeliveryPopups } from 'components/DeliveryPopups';
import { customEventKeys, logger } from 'util/logger';
import { OnScanUpdater } from './CartComponents/OnScanUpdater';
import { useQueryClient } from '@tanstack/react-query';
import { getAllProductsKeys } from 'queries/v2/product/query-key-factory';
import { CartFooter } from './CartPanels/CartFooter';
import { useMetrics } from 'util/metrics/useMetrics';
import { CustomerHistoryPanel } from 'components/cart/CustomerHistoryPanel';
import { useResetHubCartDisplayOnNavigation } from 'util/hooks/useResetHubCartDisplayOnNavigation';
import { useTransactionManager } from './hooks/useTransactionManager';

const CartPageWithoutPopups: FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const checkedInGuest = useSelector((state: State) => state.cart.checkedInGuest);
  const checkedInGuestId = useSelector((state: State) => state.cart.checkedInGuest?.Guest_id);
  const checkedInGuestShipmentId = useSelector((state: State) => state.cart.checkedInGuest?.ShipmentId);
  const checkedInGuestScheduleId = useSelector((state: State) => state.cart.checkedInGuest?.ScheduleId);
  const locked = useSelector((state: State) => state.cart.details.Locked);

  const checkoutRunning = useSelector((state: State) => state.checkout.running);

  const features = useSelector((state: State) => state.settings.features);
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  const guest = useSelector((state: State) => state.customer.details);

  const { guestId, shipmentId } = useTransactionManager();

  const patientPolicyValidator = usePatientPolicyValidator();
  const [cartHasBeenReset, setCartHasBeenReset] = useState(false);
  const [cartLoading, setCartLoading] = useState(true);
  const [addItemPanelIsOpen, setAddItemPanelIsOpen] = useState(false);
  const history = useHistory();
  const metrics = useMetrics();

  // Resets hub device ui to idle state and attempts to cancel the order when leaving cart screen.
  useResetHubCartDisplayOnNavigation();

  const assignGuestToRegister = useCallback(
    async (args: { ShipmentId: number; Guest_id: number; ScheduleId: number }) => {
      if (registerId) {
        const result = await dispatch(
          setGuestToRegister({
            ShipmentId: args.ShipmentId,
            Guest_id: args.Guest_id,
            RegisterId: Number(registerId),
            ScheduleId: args.ScheduleId,
          })
        );
        if (result.meta.requestStatus === 'rejected') {
          dispatch(
            showActionableError({
              message: 'Network Error: Unable to assign guest to register',
              action: {
                label: 'Retry',
                onClick: async () => await assignGuestToRegister(args),
              },
              secondaryAction: {
                label: 'Back to guestlist',
                onClick: () => history.push('/guestlist'),
              },
            })
          );
        }
      } else {
        throw new Error('Guest is missing data');
      }
    },
    [dispatch, history, registerId]
  );

  useEffect(() => {
    if (checkedInGuestShipmentId) {
      logger.info(`cart loaded for guest ${checkedInGuest?.FullName ?? checkedInGuestId}`, {
        key: customEventKeys.cart.loaded,
        shipmentId: checkedInGuestShipmentId,
        guestId: checkedInGuestId,
        guestName: checkedInGuest?.FullName,
        totalItems: checkedInGuest?.TotalItems,
        orderType: checkedInGuest?.OrderType,
        orderSource: checkedInGuest?.OrderSource,
        orderTotal: checkedInGuest?.OrderTotal,
        isAnonymous: checkedInGuest?.IsAnonymous,
        isMedical: checkedInGuest?.IsMedical,
        checkInDateUTC: checkedInGuest?.CheckInDateUTC,
        shipmentDateUTC: checkedInGuest?.ShipmentDateUTC,
      });
    }
    // This useEffect is only used for logging, so we can be super specific about when we want to trigger it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedInGuestShipmentId]);

  const loadCustomerInfo = useCallback(async () => {
    const shouldFetchGuestAndAssign =
      registerId && checkedInGuestId && checkedInGuestShipmentId && checkedInGuestScheduleId;
    if (!checkedInGuestId && !guest && !cartHasBeenReset) {
      history.replace('/guestlist');
    } else if (shouldFetchGuestAndAssign) {
      await dispatch(getCustomerDetails({ guestId: checkedInGuestId }));
      await assignGuestToRegister({
        ShipmentId: checkedInGuestShipmentId,
        Guest_id: checkedInGuestId,
        ScheduleId: Number(checkedInGuestScheduleId),
      });
    }
  }, [
    registerId,
    checkedInGuestId,
    checkedInGuestShipmentId,
    checkedInGuestScheduleId,
    guest,
    cartHasBeenReset,
    history,
    dispatch,
    assignGuestToRegister,
  ]);

  useEffect(() => {
    loadCustomerInfo();
    dispatch(setDutchiePayPreAuthError(false));
    dispatch(setIsFalseNegativePinDebitError(false));
    dispatch(setBlockScans(false));
    queryClient.invalidateQueries(getAllProductsKeys.all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const resetAndInitializeCart = async () => {
      if (checkedInGuest && register) {
        // Reset the cart to initialState
        dispatch(resetCart());
        setCartHasBeenReset(true);

        // Load the cart from API
        await dispatch(
          loadCart({
            ShipmentId: checkedInGuest.ShipmentId,
            Timestamp: +new Date(),
            AcctId: checkedInGuest.Guest_id,
            Register: Number(register.value),
          })
        );

        // Set the history tab so show transaction history first
        dispatch(showProductHistory(false));

        // Check if statefulcheckout is being used and
        // checkout has already started. If so, calculate totals
        if (features.StatefulCheckout && checkoutRunning) {
          await dispatch(
            calcCartTotal({
              ShipmentId: checkedInGuest.ShipmentId,
              Timestamp: +new Date(),
              AcctId: checkedInGuest.Guest_id,
              Register: Number(register.value),
            })
          );
        }

        dispatch(clearCheckedInGuest());
      }
    };

    if (cartLoading && !cartHasBeenReset) {
      resetAndInitializeCart().then(() => setCartLoading(false));
    }
  }, [
    cartLoading,
    cartHasBeenReset,
    checkedInGuest,
    register,
    checkoutRunning,
    dispatch,
    features.StatefulCheckout,
    guest,
  ]);

  useEffect(() => {
    if (!guest) {
      return;
    }

    patientPolicyValidator(guest).then((isValid) => {
      if (!isValid) {
        dispatch(warningNotification('Customer cannot checkout until policy is signed'));
      }
    });
  }, [patientPolicyValidator, dispatch, guest]);

  useEffect(() => {
    // Make sure cart has stopped loading before logging
    if (!cartLoading && guest?.IsAnonymous) {
      // Add a timestamp for the anonymous cart starting
      metrics.addTimestamp(customEventKeys.metrics.timestamps.anonymousTransactionStarted, {
        logData: { anonGuestId: guestId, shipmentId },
      });

      // Add a log marking how long it took for the cart to load after clicking the anon guest button
      metrics.addDuration(customEventKeys.metrics.durations.anonymousCartLoaded, {
        timestampKey: customEventKeys.metrics.timestamps.clickAnonymousGuestButton,
        logData: { anonGuestId: guestId, shipmentId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLoading]);

  const onClose = useCallback(() => {
    setAddItemPanelIsOpen(false);
  }, []);

  const handleCloseDrawer = useCallback(() => setAddItemPanelIsOpen(false), []);

  const extendedCartPanelHeight = useDynamicViewHeight(headerHeight, 'svh');

  return (
    <>
      <OnScanUpdater />
      <Container>
        <CartHeader showInitialPopup={true} loading={cartLoading} />
        <BaseContainer>
          <LeftContainer>
            <CartSummary loading={cartLoading} setAddItemPanelIsOpen={setAddItemPanelIsOpen} />
            <CartFooter isLoading={cartLoading} />
          </LeftContainer>
          <CartPanels loading={cartLoading} />
        </BaseContainer>
        <BottomDrawer
          fullHeight
          open={addItemPanelIsOpen}
          onClose={handleCloseDrawer}
          portalId={FullScreenPortalId}
          marginX={0}
          marginY={40}
        >
          {addItemPanelIsOpen && <AddItemPanel onClose={onClose} />}
        </BottomDrawer>
        <CustomerHistoryPanel />
        {checkoutRunning && (
          <CheckoutOverlay>
            {locked && (
              <LockedCartText>
                Cart has been locked by the state system. Additional items cannot be added to this transaction
              </LockedCartText>
            )}
          </CheckoutOverlay>
        )}
        <ExtendedCartPanelPortal id={extendedPanelPortalID} height={extendedCartPanelHeight} />
      </Container>
    </>
  );
};

export const CartPage = () => {
  return (
    <>
      <DeliveryPopups />
      <ProvideCartPopups>
        <CartPopups />
        <CartPageWithoutPopups />
      </ProvideCartPopups>
    </>
  );
};

const BaseContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const LeftContainer = styled.div`
  ${LeftContainerDimensions}
  display: flex;
  flex-direction: column;
  background-color: ${colors.dutchie.lightGrey};
  padding: 2rem 3.5rem;
  gap: 1rem;
  ${eloOriPadBreakpoint} {
    padding: 2rem 2.5rem 2rem 3.5rem;
  }
`;

const CheckoutOverlay = styled.div`
  display: flex;
  position: absolute;
  z-index: ${zIndices.overlay};
  height: 100%;
  background: ${rgba(colors.dutchie.opal12, 0.5)};
  left: 0;
  right: clamp(520px, ${panelWidth.width}, ${panelWidth.width});

  ${breakpoints.smallTablet.maxQuery} {
    display: none;
  }
`;

const LockedCartText = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExtendedCartPanelPortal = styled.div<{ height?: string }>`
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${panelWidth.width};
  min-width: 520px;
  height: ${({ height }) => (height ? height : `calc(100vh - ${headerHeight})`)};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  pointer-events: none;
  z-index: 3;

  & > * {
    width: 100%;
    pointer-events: all;
  }

  ${breakpoints.smallTablet.maxQuery} {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  height: 100%;

  ${LeftContainer} {
    padding: 1.5rem;
  }

  ${ExtendedCartPanelPortal} {
    position: absolute;
    height: 100%;
  }

  ${breakpoints.smallTablet.maxQuery} {
    ${LeftContainer} {
      padding: 0;
      gap: 0;
      overflow-y: auto;
    }
  }
`;
