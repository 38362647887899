import { useFeatureSwitchesQuery } from "queries/v2/settings/get-feature-switches";
import { FeatureSwitch } from "queries/v2/settings/types";

export enum FeatureSwitchKey {
  DUTCHIEPAY = 'dutchie-pay',
  REGALA = 'regala',
}

export function useFeatureSwitches() {
  const featureSwitchesQuery = useFeatureSwitchesQuery();
  const getFeatureSwitch = (key: string) => {
    const { data } = featureSwitchesQuery;
    if (data && key in data) {
      return data[key];
    }
    const defaultValue: FeatureSwitch =  {
      Id: key,
      DisplayName: key,
      Enabled: true,
      Key: key,
      Description: '',
      HasDescription: false,
    };
    return defaultValue;
  };
return {
    getFeatureSwitch,
    featureSwitchesQuery,
};
}
