/**
 *
 * @param allotment number
 * @returns string
 *
 * When the allotment number has 2 or fewer decimal places, we want to display a fixed 2 decimal places.
 * When the allotment number has 3 or more decimal places, we want to display a fixed 3 decimal places.
 * ENG-55004
 */
export const getAllotmentValueToDisplay = (allotment: number): string => {
  const numberOfDigitsAfterDecimal = (allotment.toString().match(/.\d*/g)?.[1]?.length ?? 1) - 1;

  if (!numberOfDigitsAfterDecimal) {
    return allotment.toFixed(2);
  }

  return allotment.toFixed(numberOfDigitsAfterDecimal <= 2 ? 2 : 3);
};
