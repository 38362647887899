import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { App } from '@capacitor/app';
import { State } from 'store';
import { loadBuildNumber } from 'store/actions/SettingsActions';
import { isAndroid } from 'util/hooks';

export function useBuildNumber() {
  const stateBuildNumber = useSelector((state: State) => state.settings.buildNumber?.BuildNumber);
  const [buildNumber, setBuildNumber] = useState<string | null>(stateBuildNumber?.toString() || null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAndroid) {
      App.getInfo().then((info) => {
        if (info.version !== buildNumber) {
          setBuildNumber(info.version);
        }
      });
    } else if (buildNumber === null && !stateBuildNumber) {
      dispatch(loadBuildNumber());
    } else if (buildNumber === null && stateBuildNumber) {
      setBuildNumber(stateBuildNumber.toString());
    }
  }, [buildNumber, dispatch, stateBuildNumber]);

  if (!buildNumber) {
    return null;
  }

  return buildNumber;
}
