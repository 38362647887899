import React from 'react';

export const LogoutIcon = (): JSX.Element => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.16634 3.16659C3.90112 3.16659 3.64677 3.27194 3.45923 3.45948C3.2717 3.64701 3.16634 3.90137 3.16634 4.16659V15.8333C3.16634 16.0985 3.2717 16.3528 3.45923 16.5404C3.64677 16.7279 3.90112 16.8333 4.16634 16.8333H7.49967C7.86786 16.8333 8.16634 17.1317 8.16634 17.4999C8.16634 17.8681 7.86786 18.1666 7.49967 18.1666H4.16634C3.5475 18.1666 2.95401 17.9208 2.51643 17.4832C2.07884 17.0456 1.83301 16.4521 1.83301 15.8333V4.16659C1.83301 3.54775 2.07884 2.95425 2.51643 2.51667C2.95401 2.07908 3.5475 1.83325 4.16634 1.83325H7.49967C7.86786 1.83325 8.16634 2.13173 8.16634 2.49992C8.16634 2.86811 7.86786 3.16659 7.49967 3.16659H4.16634Z" fill="#C8D2DB"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.8616 5.36185C13.122 5.1015 13.5441 5.1015 13.8044 5.36185L17.9711 9.52851C18.2314 9.78886 18.2314 10.211 17.9711 10.4713L13.8044 14.638C13.5441 14.8983 13.122 14.8983 12.8616 14.638C12.6013 14.3776 12.6013 13.9555 12.8616 13.6952L16.5569 9.99992L12.8616 6.30466C12.6013 6.04431 12.6013 5.6222 12.8616 5.36185Z" fill="#C8D2DB"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.83301 9.99992C6.83301 9.63173 7.13148 9.33325 7.49967 9.33325H17.4997C17.8679 9.33325 18.1663 9.63173 18.1663 9.99992C18.1663 10.3681 17.8679 10.6666 17.4997 10.6666H7.49967C7.13148 10.6666 6.83301 10.3681 6.83301 9.99992Z" fill="#C8D2DB"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.16634 3.16659C3.90112 3.16659 3.64677 3.27194 3.45923 3.45948C3.2717 3.64701 3.16634 3.90137 3.16634 4.16659V15.8333C3.16634 16.0985 3.2717 16.3528 3.45923 16.5404C3.64677 16.7279 3.90112 16.8333 4.16634 16.8333H7.49967C7.86786 16.8333 8.16634 17.1317 8.16634 17.4999C8.16634 17.8681 7.86786 18.1666 7.49967 18.1666H4.16634C3.5475 18.1666 2.95401 17.9208 2.51643 17.4832C2.07884 17.0456 1.83301 16.4521 1.83301 15.8333V4.16659C1.83301 3.54775 2.07884 2.95425 2.51643 2.51667C2.95401 2.07908 3.5475 1.83325 4.16634 1.83325H7.49967C7.86786 1.83325 8.16634 2.13173 8.16634 2.49992C8.16634 2.86811 7.86786 3.16659 7.49967 3.16659H4.16634Z" stroke="#C8D2DB" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.8616 5.36185C13.122 5.1015 13.5441 5.1015 13.8044 5.36185L17.9711 9.52851C18.2314 9.78886 18.2314 10.211 17.9711 10.4713L13.8044 14.638C13.5441 14.8983 13.122 14.8983 12.8616 14.638C12.6013 14.3776 12.6013 13.9555 12.8616 13.6952L16.5569 9.99992L12.8616 6.30466C12.6013 6.04431 12.6013 5.6222 12.8616 5.36185Z" stroke="#C8D2DB" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.83301 9.99992C6.83301 9.63173 7.13148 9.33325 7.49967 9.33325H17.4997C17.8679 9.33325 18.1663 9.63173 18.1663 9.99992C18.1663 10.3681 17.8679 10.6666 17.4997 10.6666H7.49967C7.13148 10.6666 6.83301 10.3681 6.83301 9.99992Z" stroke="#C8D2DB" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
