import React from 'react';

import { colors } from 'css/Theme';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const USBIcon = ({ width = 16, height = 16, color = colors.dutchie.gray100 }: IconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 16 16' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.66414 15.7881V13.2828H8.7308L8.7308 15.7881H7.66414Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.9909 6.62271H4.00781C3.49235 6.62271 3.07448 7.04057 3.07448 7.55604V11.2828C3.07448 11.7982 3.49235 12.2161 4.00781 12.2161H11.9909C12.5064 12.2161 12.9242 11.7982 12.9242 11.2828V7.55604C12.9242 7.04057 12.5064 6.62271 11.9909 6.62271ZM4.00781 5.55604C2.90324 5.55604 2.00781 6.45147 2.00781 7.55604V11.2828C2.00781 12.3873 2.90324 13.2828 4.00781 13.2828H11.9909C13.0955 13.2828 13.9909 12.3873 13.9909 11.2828V7.55604C13.9909 6.45147 13.0955 5.55604 11.9909 5.55604H7.99935H4.00781Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.33407 1.27858C4.89224 1.27858 4.53407 1.63675 4.53407 2.07858V6.06133H3.4674V2.07858C3.4674 1.04765 4.30313 0.211914 5.33407 0.211914H10.6646C11.6956 0.211914 12.5313 1.04765 12.5313 2.07858V6.06133H11.4646V2.07858C11.4646 1.63675 11.1065 1.27858 10.6646 1.27858H5.33407Z'
        fill={color}
      />
      <path d='M5.99141 2.73662H7.32475V4.06995H5.99141V2.73662Z' fill={color} />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.99141 2.73662V4.06995H7.32475V2.73662H5.99141Z'
        fill={color}
      />
      <path d='M8.66262 2.73662H9.99595V4.06995H8.66262V2.73662Z' fill={color} />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.66262 2.73662V4.06995H9.99595V2.73662H8.66262Z'
        fill={color}
      />
    </svg>
  );
};
