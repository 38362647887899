import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { OutlinedInput as MUIOutlinedInput, SxProps, SelectProps as MUISelectProps } from '@mui/material';

import { RebrandCaret } from '../../icons/rebrand-caret';
import { FormControl } from '../../form-control';
import { Label } from '../../label';
import { RebrandSelect, StyledPlaceholder } from '../select-rebrand.styles';
import { MenuItem } from '../../menu-item';

export type BooleanSelectProps = MUISelectProps & {
  value?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode | string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  displayEmpty?: boolean;
  className?: string;
  renderValue?: (selected: boolean) => React.ReactNode | boolean;
  width?: string;
  required?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  gridColumns?: number;
  tooltip?: string;
  automationId?: string;
  id?: string;
  placeholder?: string;
};

export const BooleanSelect = React.forwardRef<HTMLSelectElement, BooleanSelectProps>((props, ref) => {
  const {
    label,
    labelPlacement = 'start',
    displayEmpty = true,
    onChange,
    renderValue,
    value,
    width,
    required = false,
    sx,
    disabled = false,
    tooltip,
    automationId,
    gridColumns,
    className = '',
    input,
    MenuProps,
    SelectDisplayProps,
    id,
    placeholder,
    ...other
  } = props;

  const StyledSelect = RebrandSelect;

  const idFromLabel: string = typeof label === 'string' && label;

  return (
    <FormControl
      {...other}
      $fullWidth={!label}
      $labelPlacement={labelPlacement}
      $width={width}
      className={`${className} select-form-control`}
      disabled={disabled}
      forceRebrandStyles
      gridColumns={gridColumns}
      ref={ref}
      sx={sx}
    >
      {label && (
        <Label
          className='select-label'
          forceRebrandStyles
          id={`select-label_${id ?? idFromLabel}`}
          required={required}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}
      <StyledSelect
        className='select-select-input'
        data-testid={automationId}
        displayEmpty={displayEmpty}
        IconComponent={RebrandCaret}
        input={input ?? <StyledMUIOutlinedInput />}
        labelId={`select-label_${id ?? idFromLabel}`}
        MenuProps={
          MenuProps ?? {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              marginTop: '8px',
            },
          }
        }
        renderValue={
          renderValue ??
          ((selected) => {
            if (selected === false) {
              return 'No';
            }
            if (selected === true) {
              return 'Yes';
            }
            return <StyledPlaceholder>{placeholder ?? 'Select an option'}</StyledPlaceholder>;
          })
        }
        SelectDisplayProps={SelectDisplayProps ?? { style: { paddingRight: '0' } }}
        value={value ?? ''}
        onChange={onChange}
      >
        <MenuItem value>Yes</MenuItem>
        <MenuItem value={false}>No</MenuItem>
      </StyledSelect>
    </FormControl>
  );
});

// this fixes an issue where you cant open the dropdown when clicking the arrow icon
const StyledMUIOutlinedInput = styled(MUIOutlinedInput)`
  .MuiInputBase-input {
    padding: 0.75rem 3rem 0.75rem 1.25rem !important;
  }
`;
