import React, { FC, useState } from 'react';
import styled from 'styled-components';
import * as AlpineIQApi from 'api/AlpineIQApi';
import { callback, callback1 } from 'models/Misc';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import { errorNotification } from 'store/actions/NotificationsActions';
import { AlpineIQInfo, GetWalletRequest } from 'models/AlpineIQ';
import { useDispatch } from 'react-redux';
import { TopSection, BottomSection, CancelButton } from './AlpineIQRedeemPopup';
import { colors } from 'css/Theme';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

const ResendButton = styled(Button)`
  color: ${colors.dutchie.blue};
  border: none;
  padding: 0 0.5rem 0 0;
`;

type MFAuthCodeEntryProps = {
  setLoading: callback1<boolean>;
  setWalletExist: callback1<boolean>;
  phoneNumber: string;
  setAlpineIQInfo: callback1<AlpineIQInfo | undefined>;
  hide: callback;
};

export const MFAuthCodeEntry: FC<MFAuthCodeEntryProps> = ({
  setLoading,
  setWalletExist,
  phoneNumber,
  setAlpineIQInfo,
  hide,
}) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const ldClient = useLDClient();
  const canUseBypassEntry = ldClient?.variation('pos.register.aiq_no_phone_entry.rollout', false);

  const { shipmentId } = useTransactionManager();

  const getWallet = async () => {
    setLoading(true);
    try {
      const getWalletRequestParams: GetWalletRequest = {
        PhoneNumbers: [phoneNumber],
        Emails: [],
        Code: code,
        ShipmentId: shipmentId || 0,
      };
      if (!canUseBypassEntry) {
        getWalletRequestParams.PhoneNumber = phoneNumber;
      }
      const data = await AlpineIQApi.getWallet(getWalletRequestParams);
      setAlpineIQInfo(data);
    } catch (message) {
      if (typeof message === 'string') {
        dispatch(errorNotification(message));
      }
    }
    setLoading(false);
  };

  return (
    <>
      <TopSection>
        <Input
          id='2FACode'
          type='text'
          placeholder='Code'
          value={code}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCode(e.target.value);
          }}
          endAdornment={
            <ResendButton
              secondary
              onClick={() => {
                setWalletExist(false);
              }}
            >
              Resend Code
            </ResendButton>
          }
        />
      </TopSection>
      <BottomSection>
        <CancelButton onClick={hide} secondary>
          Cancel
        </CancelButton>
        <Button onClick={getWallet}>Open Wallet</Button>
      </BottomSection>
    </>
  );
};
