import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, FormikProps } from 'formik';
import { State } from 'store';
import { ConfirmationPopup } from 'components/popups';
import { InputField, Select, TextArea } from 'components/inputs';
import { CustomerDetails } from 'models/Customer';
import { adjustLoyaltyPoints, loadAdjustmentLoyaltyReasons } from 'store/actions/CustomerActions';
import { isEmpty } from 'lodash';
import { warningNotification } from 'store/actions/NotificationsActions';
import { Label } from 'components/text';
import { Box } from 'components/layout';

const Popup = styled(ConfirmationPopup)`
  width: 30rem;
`;

const ContentDiv = styled.div`
  margin: 0 2rem;
`;

type EditLoyaltyPointsPopupProps = {
  isVisible: boolean;
  hide: () => void;
  customer: CustomerDetails;
};

type EditLoyaltyPointsFormValues = {
  points: number;
  pin: string | undefined;
};

export const EditLoyaltyPointsPopup: FC<EditLoyaltyPointsPopupProps> = ({ customer, isVisible, hide }) => {
  const dispatch = useDispatch();
  const pendingLoyaltyAdjustment = useSelector((state: State) => state.customer.pendingLoyaltyAdjustment);
  const pinRequired = useSelector((state: State) => state.settings.features.ManagerPasswordForLoyaltyAdjustments);
  const requireLoyaltyAdjustReason = useSelector((state: State) => state.settings.features.RequireAdjustLoyaltyReason);
  const formRef = useRef<FormikProps<EditLoyaltyPointsFormValues> | null>(null);
  const [adjustmentReason, setAdjustmentReason] = useState('');
  const { adjustLoyaltyReasons } = useSelector((state: State) => state.customer.options);

  useEffect(() => {
    dispatch(loadAdjustmentLoyaltyReasons());
  }, [dispatch]);

  const onSubmit = async (values: EditLoyaltyPointsFormValues) => {
    if (requireLoyaltyAdjustReason && !adjustmentReason) {
      dispatch(warningNotification(`Please select an adjustment reason`));
      return;
    }

    await dispatch(
      adjustLoyaltyPoints({
        GuestId: customer.Guest_id,
        AdjustLoyaltyReason: adjustmentReason || '',
        TransactionAmt: values.points - customer.LoyaltyPoints,
        pin: values.pin,
        PinUserId: null,
      })
    );
    hide();
  };

  return (
    <Popup
      isVisible={isVisible}
      hide={hide}
      title='Adjust Loyalty Points'
      confirm={{
        text: 'Adjust',
        disabled: pendingLoyaltyAdjustment,
        onClick: () => {
          formRef.current?.handleSubmit();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <ContentDiv>
        <Formik
          initialValues={{
            points: customer.LoyaltyPoints,
            pin: pinRequired ? '' : undefined,
          }}
          onSubmit={(values) => onSubmit(values)}
          innerRef={(instance) => (formRef.current = instance)}
        >
          <Form>
            <InputField type='number' name='points' label='Loyalty Points' placeholder='Loyalty Points' />
            {pinRequired && <InputField type='password' name='pin' label='Manager PIN' placeholder='Manager PIN' />}
            {requireLoyaltyAdjustReason && (
              <>
                {isEmpty(adjustLoyaltyReasons) ? (
                  <Box>
                    <Label>Adjust Loyalty Reason</Label>
                    <TextArea
                      placeholder='Adjustment Reason'
                      onChange={(e) => setAdjustmentReason(e.target.value)}
                      value={adjustmentReason}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Label>Adjust Loyalty Reason</Label>
                    <Select
                      defaultValue={adjustmentReason}
                      placeholder='Adjustment Reason'
                      onChange={(value) => setAdjustmentReason(value)}
                      options={adjustLoyaltyReasons.map((item) => ({
                        value: item.Reason,
                        label: item.Reason,
                      }))}
                    />
                  </Box>
                )}
              </>
            )}
          </Form>
        </Formik>
      </ContentDiv>
    </Popup>
  );
};
