import React from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useReceiptPrinterSettings } from '../hooks/useReceiptPrinterSettings';
import { Switch } from 'components/inputs';

export const ReceiptPrinterAutoPrintListItem = () => {
  const { formValues, setAutoPrintReceipts } = useReceiptPrinterSettings();

  return (
    <SettingsListItem
      title='Auto-print receipts'
      subtitle='When enabled, all receipts will print automatically'
      actions={[
        <Switch
          automationId='receipt_printer_settings-auto_print_switch'
          checked={formValues.autoPrintReceipts}
          containerMargin='0'
          onChange={() => setAutoPrintReceipts(!formValues.autoPrintReceipts)}
        />,
      ]}
    />
  );
};
