import { useMemo } from 'react';

import { getZebraScannerBluetoothPairingImage } from 'util/hooks/webviewApp';
import hidSetupBarcode from 'assets/images/zebra/zebra-hid-ibm.jpg';
import { isAndroid, isWebViewApp } from 'util/hooks';
import { useHardwareServices } from '../../../hooks/useHardwareServices';
import zebraDisableKeypadEmulation from 'assets/images/zebra/zebra-disable-keypad-emulation.png';
import zebraFactoryReset from 'assets/images/zebra/zebra-factory-reset.jpg';
import zebraUsbSnapi from 'assets/images/zebra/zebra-usb-snapi.jpg';

import type { ScannerBarcodeAsyncProps } from '../setup/ScannerBarcode';

export type SetupBarcode = ScannerBarcodeAsyncProps & {
  instruction: string;
};

// Reset barcodes

const zebraResetBarcode: SetupBarcode = {
  instruction: 'Scan factory reset barcode',
  src: zebraFactoryReset,
  title: 'Factory Reset',
};

// Additional configuration barcodes

const zebraBluetoothBarcode: SetupBarcode = {
  instruction: 'Scan configuration barcode',
  src: getZebraScannerBluetoothPairingImage,
  title: 'Bluetooth Pairing',
};

const zebraDisableKeypadEmulationBarcode: SetupBarcode = {
  instruction: 'Scan configuration barcode to disable keypad emulation',
  src: zebraDisableKeypadEmulation,
  title: 'Disable Keypad Emulation',
};

const zebraHidBarcode: SetupBarcode = {
  instruction: 'Scan configuration barcode',
  src: hidSetupBarcode,
  title: 'Web HID',
};

const zebraUsbBarcode: SetupBarcode = {
  instruction: 'Scan configuration barcode',
  src: zebraUsbSnapi,
  title: 'Native USB',
};

export type ScannerConfiguration = {
  title: string;
  subtitle?: string;
  barcodes: SetupBarcode[];
};

export const useScannerConfigurations = () => {
  const { isWebHidSupported, isWindows } = useHardwareServices();

  const scannerConfigurations = useMemo(() => {
    const configurations: ScannerConfiguration[] = [];

    if (isWebHidSupported && !isAndroid && !isWebViewApp) {
      configurations.push({
        title: 'Zebra - HID',
        subtitle: '(recommended for ID scanning)',
        barcodes: [zebraResetBarcode, zebraHidBarcode],
      });
    }

    if (isWebViewApp) {
      configurations.push({
        title: 'Zebra - Bluetooth',
        subtitle: '(supports ID scanning)',
        barcodes: [zebraResetBarcode, zebraBluetoothBarcode],
      });
    }

    if (isAndroid) {
      configurations.push({
        title: 'Zebra - USB',
        subtitle: '(supports ID scanning)',
        barcodes: [zebraResetBarcode, zebraUsbBarcode],
      });
    }

    configurations.push({
      title: 'Zebra - Basic',
      subtitle: '(supports SKUs and QR codes)',
      barcodes: [
        zebraResetBarcode,
        ...(!isWindows && !isAndroid && !isWebViewApp ? [zebraDisableKeypadEmulationBarcode] : []),
      ],
    });

    return configurations;
  }, [isWebHidSupported, isWindows]);

  return { scannerConfigurations };
};
