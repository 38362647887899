import { createAction } from '@reduxjs/toolkit';

import type { GlobalPopupsPayloads } from 'store/reducers/PopupsReducer/GlobalPopupsReducer';

export const showCreatePreOrderPopup = createAction(
  'showCreatePreOrderPopup',
  (payload: GlobalPopupsPayloads['createPreOrderPopup']) => ({ payload })
);

export const showFulfillmentSelectorPopup = createAction(
  'showFulfillmentSelectorPopup',
  (payload: GlobalPopupsPayloads['fulfillmentSelectorPopup']) => ({ payload })
);

export const showSelectCollectorPopup = createAction(
  'showSelectCollectorPopup',
  (payload: GlobalPopupsPayloads['selectCollectorPopup']) => ({ payload })
);

export const showVerifyBirthdatePopup = createAction(
  'showVerifyBirthdatePopup',
  (payload: GlobalPopupsPayloads['verifyBirthdatePopup']) => ({ payload })
);
