import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { rgba } from 'polished';

import { colors } from 'css/Theme';
import { SingleChevronIcon } from 'assets/icons/navigation/single-chevron';

import { useNavigationItem } from 'components/layout/NavigationItem/useNavigationItem';

import type { ReactNode } from 'react';
import type { NavigationItemData } from 'util/hooks/useNavigationItems';

export enum NavigationItemVariant {
  Sidebar = 'sidebar',
  Drawer = 'drawer',
}

export type NavigationItemProps = {
  variant: NavigationItemVariant;
  item: NavigationItemData;
  parentWidth: number;
};

type DynamicComponentProps = {
  to: string;
  children: ReactNode;
  onClick?: () => void;
};

const SUB_MENU_TRANSITION_DURATION = 300;

function DynamicItemComponent(props: DynamicComponentProps) {
  return props.to ? <LinkItem {...props} /> : <ButtonItem {...props} />;
}

function DynamicSubItemComponent(props: DynamicComponentProps) {
  return props.to ? <LinkSubItem {...props} /> : <ButtonSubItem {...props} />;
}

export function NavigationItem(props: NavigationItemProps) {
  const { item, variant, parentWidth } = props;

  const { to, icon: Icon, label, subItems } = item;

  const { isRouteActive, isSubMenuOpen, handleItemClick, handleSubMenuClick, handleSubMenuClickAway } =
    useNavigationItem({ item });

  if (!item.visible) {
    return null;
  }

  return (
    <>
      {subItems && (
        <CSSTransition
          in={isSubMenuOpen && variant === NavigationItemVariant.Sidebar}
          timeout={SUB_MENU_TRANSITION_DURATION}
          classNames='navigation-item-overlay'
          mountOnEnter
          unmountOnExit
        >
          <Overlay />
        </CSSTransition>
      )}
      <ItemContainer
        data-testid='navigation-item-container'
        isRouteActive={isRouteActive}
        isSubMenuOpen={isSubMenuOpen}
        variant={variant}
      >
        <DynamicItemComponent
          to={to ?? ''}
          onClick={handleItemClick}
          data-testid={item.id}
          data-dd-action-name={`navigation ${variant} "${item.label}" item`}
        >
          <StatusIndicator>
            <Icon color={isRouteActive || isSubMenuOpen ? colors.dutchie.primaryWhite : colors.dutchie.opal40} />
            {variant === NavigationItemVariant.Drawer && <Label data-testid='navigation-item-label'>{label}</Label>}
            {subItems && (
              <ChevronIcon
                color={isRouteActive || isSubMenuOpen ? colors.dutchie.primaryWhite : colors.dutchie.opal40}
              />
            )}
          </StatusIndicator>
        </DynamicItemComponent>
        {subItems && (
          <CSSTransition in={isSubMenuOpen} timeout={SUB_MENU_TRANSITION_DURATION} classNames='sub-menu' unmountOnExit>
            <ClickAwayListener onClickAway={handleSubMenuClickAway}>
              <SubItemMenu
                data-testid='navigation-item-sub-menu'
                key={label}
                onClick={handleSubMenuClick}
                left={parentWidth}
              >
                {subItems?.map((subItem) => {
                  return (
                    <DynamicSubItemComponent
                      key={subItem.label}
                      to={subItem.to ?? ''}
                      onClick={subItem.onClick}
                      data-testid={subItem.id}
                      data-dd-action-name={`navigation ${variant} "${subItem.label}" sub item`}
                    >
                      {subItem.label}
                    </DynamicSubItemComponent>
                  );
                })}
              </SubItemMenu>
            </ClickAwayListener>
          </CSSTransition>
        )}
      </ItemContainer>
    </>
  );
}

const buttonResetStyles = css`
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
`;

const itemStyles = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const subItemStyles = css`
  height: 52px;
  width: 184px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  background: ${colors.dutchie.primaryWhite};
  color: ${colors.dutchie.almostBlack};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const LinkItem = styled(NavLink)`
  ${itemStyles};
`;

const ButtonItem = styled.button`
  ${buttonResetStyles};
  ${itemStyles};
`;

const LinkSubItem = styled(NavLink)`
  ${subItemStyles};
`;

const ButtonSubItem = styled.button`
  ${buttonResetStyles};
  ${subItemStyles};
`;

const StatusIndicator = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.dutchie.opal90};
  border-radius: 10px;
  transition: background 0.2s;
`;

const Label = styled.span`
  display: none;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.dutchie.opal40};
`;

const ChevronIcon = styled(SingleChevronIcon)`
  display: none;
  margin-left: auto;
`;

// fixed position required to take element out of flow and place behind other elements with z-index
const SubItemMenu = styled.ul<{ left: number }>`
  position: fixed;
  left: ${({ left }) => left}px;
  width: 184px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  background: white;
  border-radius: 0 8px 8px 0;
  box-shadow: 0px 6px 20px 0px #00000033;
  color: black;

  &.sub-menu-enter {
    opacity: 0;
    transform: translate3D(-100%, 0, 0);
  }
  &.sub-menu-enter-active {
    opacity: 1;
    transition: all ${SUB_MENU_TRANSITION_DURATION}ms;
    transform: translate3D(0, 0, 0);
  }
  &.sub-menu-exit {
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }
  &.sub-menu-exit-active {
    opacity: 0;
    transform: translate3D(-100%, 0, 0);
    transition: all ${SUB_MENU_TRANSITION_DURATION}ms;
  }
`;

const ItemContainer = styled.li<{ isRouteActive: boolean; isSubMenuOpen: boolean; variant: NavigationItemVariant }>`
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ variant }) =>
    variant === NavigationItemVariant.Drawer &&
    css`
      ${StatusIndicator} {
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        padding: 0 16px;
        border-radius: 0;
      }

      ${Label} {
        display: block;
      }

      ${ChevronIcon} {
        display: block;
      }
    `}

  @media (hover: hover) {
    &:hover {
      ${StatusIndicator} {
        background: ${colors.dutchie.opal80};
      }
    }
  }

  ${({ isRouteActive, isSubMenuOpen }) =>
    (isRouteActive || isSubMenuOpen) &&
    css`
      ${StatusIndicator} {
        background: ${colors.dutchie.opal60} !important;
      }

      ${Label} {
        color: ${colors.dutchie.primaryWhite};
      }
    `}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: ${rgba(colors.dutchie.opal80, 0.3)};

  &.navigation-item-overlay-enter {
    opacity: 0;
  }
  &.navigation-item-overlay-enter-active {
    opacity: 1;
    transition: all ${SUB_MENU_TRANSITION_DURATION}ms;
  }
  &.navigation-item-overlay-exit {
    opacity: 1;
  }
  &.navigation-item-overlay-exit-active {
    opacity: 0;
    transition: all ${SUB_MENU_TRANSITION_DURATION}ms;
  }
`;
