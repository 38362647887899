import React from 'react';

type ExternalWebPageIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function ExternalWebPageIcon(props: ExternalWebPageIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_3570_17694)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.599 9.5671C14.5029 9.1927 15.4717 9 16.45 9C17.4283 9 18.3971 9.1927 19.301 9.5671C20.2049 9.94149 21.0261 10.4903 21.7179 11.1821C22.4097 11.8739 22.9585 12.6951 23.3329 13.599C23.7073 14.5029 23.9 15.4717 23.9 16.45C23.9 17.4283 23.7073 18.3971 23.3329 19.301C22.9585 20.2049 22.4097 21.0261 21.7179 21.7179C21.0261 22.4097 20.2049 22.9585 19.301 23.3329C18.3971 23.7073 17.4283 23.9 16.45 23.9C15.4717 23.9 14.5029 23.7073 13.599 23.3329C12.6951 22.9585 11.8739 22.4097 11.1821 21.7179C10.4903 21.0261 9.94149 20.2049 9.5671 19.301C9.1927 18.3971 9 17.4283 9 16.45C9 15.4717 9.1927 14.5029 9.5671 13.599C9.94149 12.6951 10.4903 11.8739 11.1821 11.1821C11.8739 10.4903 12.6951 9.94149 13.599 9.5671ZM16.45 10.4C15.6555 10.4 14.8688 10.5565 14.1348 10.8605C13.4007 11.1646 12.7338 11.6102 12.172 12.172C11.6102 12.7338 11.1646 13.4007 10.8605 14.1348C10.5565 14.8688 10.4 15.6555 10.4 16.45C10.4 17.2445 10.5565 18.0312 10.8605 18.7652C11.1646 19.4993 11.6102 20.1662 12.172 20.728C12.7338 21.2898 13.4007 21.7354 14.1348 22.0395C14.8688 22.3435 15.6555 22.5 16.45 22.5C17.2445 22.5 18.0312 22.3435 18.7652 22.0395C19.4993 21.7354 20.1662 21.2898 20.728 20.728C21.2898 20.1662 21.7354 19.4993 22.0395 18.7652C22.3435 18.0312 22.5 17.2445 22.5 16.45C22.5 15.6555 22.3435 14.8688 22.0395 14.1348C21.7354 13.4007 21.2898 12.7338 20.728 12.172C20.1662 11.6102 19.4993 11.1646 18.7652 10.8605C18.0312 10.5565 17.2445 10.4 16.45 10.4Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.38281 18.7C9.38281 18.3134 9.69621 18 10.0828 18H22.8148C23.2014 18 23.5148 18.3134 23.5148 18.7C23.5148 19.0866 23.2014 19.4 22.8148 19.4H10.0828C9.69621 19.4 9.38281 19.0866 9.38281 18.7Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.38281 14.2C9.38281 13.8134 9.69621 13.5 10.0828 13.5H22.8148C23.2014 13.5 23.5148 13.8134 23.5148 14.2C23.5148 14.5866 23.2014 14.9 22.8148 14.9H10.0828C9.69621 14.9 9.38281 14.5866 9.38281 14.2Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.1809 9.18331C15.5535 9.28655 15.7718 9.67226 15.6686 10.0448C14.4961 14.276 14.6686 18.767 16.1622 22.8958C16.2938 23.2593 16.1057 23.6606 15.7421 23.7921C15.3786 23.9237 14.9773 23.7356 14.8457 23.372C13.2533 18.9702 13.0694 14.182 14.3194 9.67096C14.4226 9.2984 14.8084 9.08007 15.1809 9.18331Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.7179 9.18347C18.0904 9.07992 18.4763 9.29794 18.5798 9.67041C19.1256 11.6336 19.4013 13.662 19.3994 15.6997C19.4036 18.316 18.9495 20.9129 18.0575 23.3725C17.9257 23.736 17.5242 23.9238 17.1608 23.792C16.7973 23.6602 16.6095 23.2587 16.7413 22.8952C17.5777 20.5891 18.0035 18.1542 17.9994 15.7011L17.9994 15.6992C18.0013 13.7884 17.7427 11.8863 17.231 10.0454C17.1274 9.6729 17.3455 9.28701 17.7179 9.18347Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.307 0H20.0872C20.7002 0.00189364 21.2876 0.24637 21.721 0.680012C22.1544 1.11365 22.3985 1.70121 22.4 2.31427L22.4 2.316L22.4 7.45C22.4 7.8366 22.0866 8.15 21.7 8.15C21.3134 8.15 21 7.8366 21 7.45V2.31651C20.9991 2.07383 20.9023 1.84133 20.7307 1.66966C20.5591 1.49791 20.3266 1.40099 20.0838 1.4H2.30824C2.06768 1.40099 1.83723 1.49699 1.66711 1.66711C1.49699 1.83723 1.40099 2.06768 1.4 2.30824V17.8338C1.40099 18.0765 1.49783 18.3089 1.66945 18.4805C1.84105 18.6521 2.07347 18.749 2.31611 18.75H6.7C7.0866 18.75 7.4 19.0634 7.4 19.45C7.4 19.8366 7.0866 20.15 6.7 20.15H2.315L2.31284 20.15C1.70002 20.1481 1.11284 19.9038 0.679505 19.4705C0.246172 19.0372 0.00189185 18.45 3.33786e-06 17.8372L0 17.835V2.30483C0.00189131 1.69414 0.245331 1.10899 0.677161 0.677161C1.10899 0.245331 1.69414 0.00189465 2.30483 3.33786e-06L2.307 0Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 5.2C0 4.8134 0.313401 4.5 0.7 4.5H21.7C22.0866 4.5 22.4 4.8134 22.4 5.2C22.4 5.5866 22.0866 5.9 21.7 5.9H0.7C0.313401 5.9 0 5.5866 0 5.2Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.7 0C7.0866 0 7.4 0.313401 7.4 0.7V5.2C7.4 5.5866 7.0866 5.9 6.7 5.9C6.3134 5.9 6 5.5866 6 5.2V0.7C6 0.313401 6.3134 0 6.7 0Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.2 0C11.5866 0 11.9 0.313401 11.9 0.7V5.2C11.9 5.5866 11.5866 5.9 11.2 5.9C10.8134 5.9 10.5 5.5866 10.5 5.2V0.7C10.5 0.313401 10.8134 0 11.2 0Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3570_17694'>
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
