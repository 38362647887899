import { createReducer } from '@reduxjs/toolkit';

import { closePopup } from 'store/actions/PopupsActions';
import {
  buildDeliveryPopupsCases,
  deliveryPopupsInitialState,
  DeliveryPopupsPayloads,
  DeliveryPopupsState,
} from './DeliveryPopupsReducer';
import {
  buildGuestListPopupsCases,
  guestListPopupsInitialState,
  GuestListPopupsPayloads,
  GuestListPopupsState,
} from './GuestListPopupsReducer';
import { buildCartPopupsCases, cartPopupsInitialState, CartPopupsPayloads, CartPopupsState } from './CartPopupsReducer';
import {
  buildCustomerPopupsCases,
  customerPopupsInitialState,
  CustomerPopupsPayloads,
  CustomerPopupsState,
} from './CustomerPopupsReducer';
import {
  buildGlobalPopupsCases,
  globalPopupsInitialState,
  GlobalPopupsPayloads,
  GlobalPopupsState,
} from './GlobalPopupsReducer';
import {
  buildSettingsPopupCases,
  settingsPopupInitialState,
  SettingsPopupPayloads,
  SettingsPopupState,
} from './SettingsPopupReducer';

export type PopupsPayloads = GuestListPopupsPayloads &
  DeliveryPopupsPayloads &
  CartPopupsPayloads &
  CustomerPopupsPayloads &
  GlobalPopupsPayloads &
  SettingsPopupPayloads;

export type PopupsState = GuestListPopupsState &
  DeliveryPopupsState &
  CartPopupsState &
  CustomerPopupsState &
  GlobalPopupsState &
  SettingsPopupState;

const initialState: PopupsState = {
  ...guestListPopupsInitialState,
  ...deliveryPopupsInitialState,
  ...cartPopupsInitialState,
  ...customerPopupsInitialState,
  ...globalPopupsInitialState,
  ...settingsPopupInitialState,
};

export const popupsReducer = createReducer(initialState, (builder) => {
  buildDeliveryPopupsCases(builder, initialState);
  buildGuestListPopupsCases(builder, initialState);
  buildCartPopupsCases(builder, initialState);
  buildCustomerPopupsCases(builder, initialState);
  buildGlobalPopupsCases(builder, initialState);
  buildSettingsPopupCases(builder, initialState);

  builder.addCase(closePopup, (state, action) => {
    if (Object.keys(initialState).includes(action.payload)) {
      return {
        ...state,
        [action.payload]: null,
      };
    } else {
      return state;
    }
  });
});
