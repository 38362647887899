import { saveUserSettings } from "store/actions/SettingsActions";
import { useAppDispatch, useAppSelector } from "util/hooks";

type DisplaySettingsTabHook = {
  showPopupNotes: boolean;
  setShowPopupNotes: (show: boolean) => void;

  showSearch: boolean;
  setShowSearch: (show: boolean) => void;

  showGuests: boolean;
  setShowGuests: (show: boolean) => void;
};

export const useDisplaySettingsTab = (): DisplaySettingsTabHook => {
  const dispatch = useAppDispatch();
  const { showGuests, showPopupNotes, showSearch } = useAppSelector(state => state.settings.userSettings);

  const setShowGuests = (show: boolean) => {
    dispatch(saveUserSettings({ showGuests: show }));
  };

  const setShowPopupNotes = (show: boolean) => {
    dispatch(saveUserSettings({ showPopupNotes: show }));
  };

  const setShowSearch = (show: boolean) => {
    dispatch(saveUserSettings({ showSearch: show }));
  };

  return {
    showGuests,
    setShowGuests,
    showPopupNotes,
    setShowPopupNotes,
    showSearch,
    setShowSearch,
  };
};
