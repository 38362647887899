import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { startScan, endScan } from 'store/actions/CartItemsActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { getProductImageURL } from '../helpers/getProductImageUrl';
import { customEventKeys, logger } from 'util/logger';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useAddItemToCart } from 'util/hooks/cart-items/useAddItemToCart';
import { useCartPopups } from 'components/CartPopups';
import { useGetScannedProduct } from './useGetScannedProduct';
import { usePreventAddingExpiredItems } from './usePreventAddingExpiredItems';
import { isDateBeforeToday } from 'util/helpers/date-helpers/isDateBeforeToday';
import { useShouldWarnItemNotInPreorder } from 'util/Helpers';
import { isScanResponse, convertScanResponseToProductSearchResult } from 'models/Cart';
import { showProductBeingAddedPastUsedByDatePopup, showScanFailedPopup } from 'store/actions/PopupsActions';
import { InvalidBarcode } from 'components/CartPopups/components/ScanFailedPopup/messages';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useVerifyLastFourIfNecessary } from 'util/hooks/useVerifyLastFourIfNecessary';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { State } from 'store';

type AddScannedProductToCartProps = {
  guestIdOverride?: number;
  scannerId?: string | number;
  serialNumber?: string;
  shipmentIdOverride?: number;
};

/**
 * A hook to faciliating adding a scanned item to the cart
 * @returns A function to add a product to the cart with a serialNumber string
 */
export const useAddScannedProductToCart = () => {
  const dispatch = useDispatch();

  const isCanadaBarcodeScanningFeatureActive = useSelector(
    (state: State) => state.settings.features.CanadaBarcodeScanning
  );
  const isPackageItemIdsFeatureActive = useSelector((state: State) => state.settings.features.PackageItemIds);
  const isShowErrorModalInCartIfPackageNotFoundEnabled = useSelector(
    (state: State) => state.settings.features.ShowErrorModalInCartIfPackageNotFound
  );

  const isMETRCUseByDateEnabled =
    useSelector((state: State) => state.settings.integrations?.UseMETRCUseByDate) ?? false;

  const { guestId, shipmentId } = useTransactionManager();

  const {
    data: { Cart: cartItems },
  } = useGetCartDetails();

  const getScannedProduct = useGetScannedProduct();
  const shouldWarnItemNotInPreorder = useShouldWarnItemNotInPreorder();
  const cartPopups = useCartPopups();

  const preventAddingExpiredItemsIfNecessary = usePreventAddingExpiredItems();
  const verifyLastFourIfNecessary = useVerifyLastFourIfNecessary();
  const addItemToCart = useAddItemToCart();

  return useCallback(
    async ({ serialNumber, scannerId, guestIdOverride, shipmentIdOverride }: AddScannedProductToCartProps) => {
      if (!serialNumber) {
        if (isShowErrorModalInCartIfPackageNotFoundEnabled) {
          dispatch(showScanFailedPopup({ message: InvalidBarcode() }));
        } else {
          dispatch(errorNotification('Scanner did not recognize a valid barcode. Try scanning again'));
        }
        return;
      }

      const startTime = Date.now();
      const foundItem = await getScannedProduct(serialNumber, scannerId);
      const product =
        foundItem && isScanResponse(foundItem) ? convertScanResponseToProductSearchResult(foundItem) : foundItem;

      const isItemAlreadyInCart = cartItems.some((item) => item.SerialNo === product?.serialNo);

      // made a hook for computing all this but can't use it in this callback and we need to pass in the product to the hook
      // maybe we can clean some of this code up if we refactor this :) -- see POS-2020/src/components/cart/Actions.tsx for usage of the hook
      const showUseByDateAlert =
        isMETRCUseByDateEnabled && !isItemAlreadyInCart && product?.useByDate && isDateBeforeToday(product.useByDate);

      if (product) {
        /**
         * Either adds a 'Qty' item to the cart or returns a formatted ProductSearchResult for a 'Wgt' item
         * @param product ProductSearchResult
         * @returns ProductSearchResult (bulkProduct was found and needs handling) or null (product type was 'Qty')
         */
        const addQtyItemToCartOrGetBulkItem = async (product: ProductSearchResult) => {
          if (product.productType === 'Qty') {
            if (shipmentId || shipmentIdOverride) {
              await verifyLastFourIfNecessary({ cannabisInventory: product.cannabisInventory, serialNo: product.serialNo });

              // Shows a modal to require manager approval if the item is expired
              await preventAddingExpiredItemsIfNecessary(product.serialNo);

              dispatch(startScan()); // Set scanning start while product is being added

              addItemToCart({
                availableOz: product.totalAvailable,
                count: 1,
                defaultLabelId: product.defaultLabelId,
                defaultUnitId: product.defaultUnitId,
                inventoryId: product.invId,
                guestIdOverride,
                packageItemId: typeof product.packageItemId === 'number' ? product.packageItemId : null,
                itemLabelId: typeof product.itemLabelId === 'number' ? product.itemLabelId : null,
                productDescription: product.productDescription,
                productId: product.productId,
                productType: 'Qty',
                recUnitPrice: product.recUnitPrice,
                serialNo: product.serialNo,
                shipmentIdOverride,
                unitPrice: product.unitPrice,
              });

              dispatch(endScan()); // End scanning state
              logger.debug(`add scanned item to cart (ID: ${product.productId})`, {
                key: customEventKeys.cart.addScannedItem,
                duration: Date.now() - startTime,
                shipmentId,
                shipmentIdOverride,
                guestId,
                guestIdOverride,
                foundItem: product,
              });
            }
          } else if (product.productType === 'Wgt') {
            const productImageURL = getProductImageURL(product);

            const bulkProduct: ProductSearchResult = {
              ...product,
              productImageURL,
            };

            return bulkProduct;
          }
          return null;
        };

        const addItemOrShowCalculator = async () => {
          // Check for the FF for warning about adding items to cart.
          // Add if approved or FF not enabled
          if (shouldWarnItemNotInPreorder(product.productId)) {
            cartPopups.showAddMoreItemsInPreOrderPopup(async () => {
              // we will never hit this early return since we already checked for foundItem above but the type system doesn't know that in the scope of this anon callback
              if (!product) {
                return;
              }

              const bulkProduct = await addQtyItemToCartOrGetBulkItem(product);
              bulkProduct && cartPopups.showBulkCalculatorPopup(bulkProduct, '0');
            });
          } else {
            const bulkProduct = await addQtyItemToCartOrGetBulkItem(product);
            bulkProduct && cartPopups.showBulkCalculatorPopup(bulkProduct, '0');
          }
        };

        if (showUseByDateAlert) {
          dispatch(showProductBeingAddedPastUsedByDatePopup({ onContinue: addItemOrShowCalculator }));
        } else {
          addItemOrShowCalculator();
        }
      } else {
        logger.warn(`failed to convert to product search result`, {
          key: customEventKeys.scanning.unableToConvertToProduct,
          apiResponse: foundItem,
          isCanadaBarcodeScanningFeatureActive,
          isPackageItemIdsFeatureActive,
        });
      }

      return null;
    },
    [addItemToCart, cartItems, cartPopups, dispatch, getScannedProduct, guestId, isCanadaBarcodeScanningFeatureActive, isMETRCUseByDateEnabled, isPackageItemIdsFeatureActive, isShowErrorModalInCartIfPackageNotFoundEnabled, preventAddingExpiredItemsIfNecessary, shipmentId, shouldWarnItemNotInPreorder, verifyLastFourIfNecessary]
  );
};
