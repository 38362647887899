import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { KeypadHeader } from './KeypadHeader';
import { KeypadKeys } from './KeypadKeys';
import { KeypadFooter } from './KeypadFooter';
import { PinPopup } from './PinPopup';
import { NewPinDisplay } from './NewPinDisplay';
import { State } from 'store';
import { useSelector } from 'react-redux';
import { welcomeMessages } from './utils/welcome-messages';
import { redirectTo } from 'util/helpers/redirectTo';
import { getDeviceSession, isDeviceSessionExpired } from 'util/helpers/pin-login/is-device-session-expired';
import { PopupType } from './types';
import { resetPinLogin } from 'api/PosApi';
import { isAndroid } from 'util/hooks';

type StyledPageDisplayProps = {
  isAndroid: boolean;
};

const getRandomWelcomeMessage = (): string => {
  const randomIndex = Math.floor(Math.random() * welcomeMessages.length);
  return welcomeMessages[randomIndex];
};

const welcomeMessage = getRandomWelcomeMessage();

export const PinLoginPage = (): JSX.Element => {
  useEffect(() => {
    const pinLoginDeviceSession = getDeviceSession();
    if (isDeviceSessionExpired(pinLoginDeviceSession)) {
      redirectTo('/');
    }
    const resetPinIfSSO = async () => {
      const [, samlSessionId] = window.location.search.toLowerCase().split('samlsessionid=');
      if (samlSessionId) {
        const newPin = await resetPinLogin('', '', selectedLsp?.OrgId ?? 0, samlSessionId);
        setNewPin(newPin?.data?.Data?.Pin);
        setIsDisplayingNewPin(true);
      }
    };
    resetPinIfSSO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedLsp = useSelector((state: State) => state.user.selectedLsp);
  const [popupModalHidden, setPopupModalHidden] = useState<boolean>(true);
  const [newPin, setNewPin] = useState<string>('');
  const [isDisplayingNewPin, setIsDisplayingNewPin] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<PopupType>('resetPin');

  const handleOpenForgotPassword = () => {
    setPopupModalHidden(false);
    setPopupType('forgotPassword');
  };

  const handleOpenResetPin = () => {
    setPopupModalHidden(false);
    setPopupType('resetPin');
  };

  const handleSetNewPin = (pin: string) => {
    setNewPin(pin);
    setIsDisplayingNewPin(true);
  };

  return (
    <StyledPage isAndroid={isAndroid}>
      {!popupModalHidden && (
        <PinPopup
          popupType={popupType}
          setNewPIn={(pin: string) => handleSetNewPin(pin)}
          openForgotPassword={() => handleOpenForgotPassword()}
          openForgotPasswordSuccessful={() => setPopupType('passwordResetSuccess')}
          hide={() => setPopupModalHidden(true)}
        />
      )}
      <KeypadHeader isAndroid={isAndroid} />
      {isDisplayingNewPin && <NewPinDisplay closeNewPinDisplay={() => setIsDisplayingNewPin(false)} newPin={newPin} />}
      {popupModalHidden && !isDisplayingNewPin && (
        <KeypadKeys isAndroid={isAndroid} welcomeMessage={welcomeMessage} handleOpenResetPin={handleOpenResetPin} />
      )}
      <KeypadFooter isAndroid={isAndroid} />
    </StyledPage>
  );
};

const StyledPage = styled.div<StyledPageDisplayProps>`
  height: 100%;
  min-height: ${(props) => (props.isAndroid ? '650px' : '705px')};
  background-color: ${colors.dutchie.opal90};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
