import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { emptyCartDetails } from './types';
import { getCartDetailsKeys } from './query-key-factory';
import { handleLoadCartError } from 'store/helpers/cartHelpers/handleLoadCartError';
import { logger, customEventKeys } from 'util/logger';
import { setCartLoading } from 'store/actions/CartActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useProcessCartResponse } from './helpers/useProcessCartResponse';

import type { LoadCartResponse, CartDetails } from './types';
import { useInfiniteStaleTime } from 'util/hooks/launch-darkly/useInfiniteStaleTime';

const LOAD_CART = 'v2/cart/load_v2';

export type GetCartDetailsPayload = {
  feePaymentMethodIds?: number[];
  guestId: number;
  registerId?: number;
  shipmentId: number;
  timestamp?: number;
};

const transformToServerPayload = (payload: GetCartDetailsPayload) => {
  return {
    AcctId: payload.guestId,
    FeePaymentMethodIds: payload.feePaymentMethodIds,
    Register: payload.registerId,
    ShipmentId: payload.shipmentId,
    Timestamp: payload.timestamp ?? +new Date(),
  };
};

export const useGetCartDetailsRequest = () => {
  const dispatch = useAppDispatch();
  const processCartResponse = useProcessCartResponse();

  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  return async (payload: GetCartDetailsPayload) => {
    try {
      if (!payload.shipmentId || !payload.guestId) {
        logger.warn('get cart request missing shipmentId', {
          key: customEventKeys.cart.missingShipmentId,
          comment: 'Returned an empty cart object instead of calling the API',
          payload,
        });
        return emptyCartDetails;
      }
      // Using this to manually set the Redux state for cart loading
      dispatch(setCartLoading(true));
      const cartResponse = await post<LoadCartResponse>(
        LOAD_CART,
        transformToServerPayload({ ...payload, registerId: payload.registerId ?? registerId })
      );
      return await processCartResponse(cartResponse);
    } catch (error) {
      // Manually set the cart loading state to false in Redux
      dispatch(setCartLoading(false));
      // Intercept the error and handle/log it before throwing to React Query
      handleLoadCartError(dispatch, error);
      logger.error(error, { message: 'Unable to load V2 cart details' });
      throw error;
    }
  };
};

// This type allows you to change the shape of the data returned from the query using the select property
type GetCartDetailsOptions<TData = CartDetails> = Partial<
  Omit<UseQueryOptions<CartDetails, unknown, TData>, 'select'>
> & {
  select?: (cartDetails: CartDetails) => TData;
};

export const useGetCartDetailsQuery = <TData = CartDetails>(
  payload: GetCartDetailsPayload,
  options?: GetCartDetailsOptions<TData>
) => {
  const getCartDetails = useGetCartDetailsRequest();
  const { isInfiniteStaleTimeEnabled } = useInfiniteStaleTime();

  return useQuery({
    queryKey: getCartDetailsKeys.one(payload),
    queryFn: async () => getCartDetails(payload),
    placeholderData: emptyCartDetails, // Set the placeholder data to an empty cart object
    ...(isInfiniteStaleTimeEnabled ? { staleTime: Infinity } : {}),
    ...options,
  });
};
