import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { Storage } from '@capacitor/storage';
import { isAndroid } from 'util/hooks';
import { FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS } from 'util/local-printing';
import { logger, customEventKeys } from 'util/logger';
import { UserChangedPrinterSettingsContext } from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { EscPrinterPickerHookData } from '../types';

export const useFulfillmentPrinterPicker = (): EscPrinterPickerHookData => {
  const dispatch = useDispatch();
  const isLocal = useSelector((state: State) => state.settings.userSettings.useConnectedFulfillmentPrinter);
  const isAutoPrintEnabled = useSelector((state: State) => state.settings.userSettings.autoPrintFulfillmentTicket);

  const handleSwitchPrinterTypes = async (isToggleOn: boolean) => {
    // update Redux and remove selected printer to keep in sync with local toggle
    dispatch(saveUserSettings({ useConnectedFulfillmentPrinter: isToggleOn, selectedFulfillmentPrinter: undefined }));

    if (isAndroid) {
      Storage.set({
        key: FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL,
        value: JSON.stringify(isToggleOn),
      });
    }

    logger.info<UserChangedPrinterSettingsContext>(`user toggled pick ticket printer to ${isToggleOn ? 'local' : 'network'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.PICK_TICKET,
      printerSettings: { isLocalPrinter: isToggleOn },
    });
  };

  const handleSwitchAutoPrint = (isToggleOn: boolean) => {
    dispatch(saveUserSettings({ autoPrintFulfillmentTicket: isToggleOn }));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled auto printing for pick ticket printer ${isToggleOn ? 'ON' : 'OFF'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.PICK_TICKET,
      printerSettings: { isAutoPrintEnabled: isToggleOn },
    });
  };

  return {
    handleSwitchPrinterTypes,
    handleSwitchAutoPrint,
    isLocal,
    isAutoPrintEnabled,
  };
};
