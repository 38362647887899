import styled from 'styled-components';
import { Menu as MUIMenu } from '@mui/material';

export const RebrandMenu = styled(MUIMenu)`
  .MuiPopover-paper {
    border-radius: var(--sizes-20);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);

    ul {
      padding: var(--sizes-20) 0;
    }
  }
`;
