import { useEffect, useRef, useState } from 'react';

type PollingFallbackProps<T> = {
  request: () => Promise<T | undefined>;
  shouldPollAgain: (response: T) => boolean;
  maxPollingAttempts?: number;
  pollingDelay?: number;
};

type PollingFallback<T> = {
  startPolling: () => void;
  isPolling: boolean;
  isPollingError: boolean;
  isPollingSuccess: boolean;
  isPollingTimedOut: boolean;
  pollingError: unknown;
  pollingSuccess: T | null;
  pollingResult: T | null;
  maxPollingAttempts: number;
};

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const usePollingFallback = <T>({
  request,
  shouldPollAgain,
  maxPollingAttempts = 60, // 5 minutes worth of attempts (60/5)*5 = 60,
  pollingDelay = 5000, // 5 seconds,
}: PollingFallbackProps<T>): PollingFallback<T> => {

  const [isPolling, setIsPolling] = useState(false);
  const isPollingRef = useRef(isPolling);
  isPollingRef.current = isPolling;

  const [isPollingError, setIsPollingError] = useState(false);
  const isPollingErrorRef = useRef(isPollingError);
  isPollingErrorRef.current = isPollingError;

  const [isPollingSuccess, setIsPollingSuccess] = useState(false);
  const isPollingSuccessRef = useRef(isPollingSuccess);
  isPollingSuccessRef.current = isPollingSuccess;

  const [pollingError, setPollingError] = useState<unknown>(null);
  const pollingErrorRef = useRef(pollingError);
  pollingErrorRef.current = pollingError;

  const [pollingSuccess, setPollingSuccess] = useState<T | null>(null);
  const pollingSuccessRef = useRef(pollingSuccess);
  pollingSuccessRef.current = pollingSuccess;

  const [isPollingTimedOut, setisPollingTimedOut] = useState(false);
  const isPollingTimedOutRef = useRef(isPollingTimedOut);
  isPollingTimedOutRef.current = isPollingTimedOut;

  const [pollingResult, setPollingResult] = useState<T | null>(null);

  useEffect(() => {
    // stop polling when the component unmounts
    return () => {
      setIsPolling(false);
      isPollingRef.current = false;
    };
  }, []);

  const canPoll = (result: T, pollingAttempt: number) => {
    if (isPollingRef.current !== true) {
      return false;
    }
    if (shouldPollAgain(result) !== true) {
      return false;
    }
    if (isPollingErrorRef.current === true) {
      return false;
    }
    if (isPollingSuccessRef.current === true) {
      return false;
    }
    if (pollingAttempt >= maxPollingAttempts) {
      return false;
    }
    return true;
  };

  //use a while loop to poll
  const onPolling = async () => {
    let result = undefined;
    let pollingAttempt = 0;
    do {
      pollingAttempt = pollingAttempt + 1;
      try {
        result = await request();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
        break;
      }
      await sleep(pollingDelay);
    } while (!result || canPoll(result, pollingAttempt));

    setPollingResult(result || null);

    setIsPolling(false);
    // Should not poll again, probable success
    if (result && !shouldPollAgain(result)) {
      setIsPollingSuccess(true);
      setIsPollingError(false);
      setPollingSuccess(result);
      setPollingError(null);
    } else if (pollingAttempt >= maxPollingAttempts) {
      // Should poll again, but max number of attempts reached
      setIsPollingSuccess(false);
      setIsPollingError(true);
      setPollingSuccess(null);
      setPollingError(null);
      setisPollingTimedOut(true);
    } else {
      // Probable error
      setIsPolling(false);
      setIsPollingSuccess(false);
      setIsPollingError(true);
      setPollingSuccess(null);
      setPollingError(result);
    }
    return result;
  };

  const resetCounters = () => {
    setIsPollingError(false);
    setIsPollingSuccess(false);
    setPollingError(null);
    setPollingSuccess(null);
    setisPollingTimedOut(false);
  };

  useEffect(() => {
    if (isPolling === true) {
      resetCounters();
      // wait for resetCounters to finish before calling onPolling
      setTimeout(() => {
        onPolling();
      }, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  const startPolling = () => {
    setIsPolling(true);
  };

  return {
    startPolling,
    isPolling,
    isPollingError,
    isPollingSuccess,
    pollingError,
    pollingSuccess,
    pollingResult,
    maxPollingAttempts,
    isPollingTimedOut,
  };
};
