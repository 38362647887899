export enum DeliveryStatus {
    Ready = 1,
    OutForDelivery = 2,
    Delivered = 3,
    Undeliverable = 4,
    Completed = 5,
    Created = 6,
    Cancelled = 7,
}

export type DeliveryRoute = {
    DeliveryRouteId: number;
    StartDate: string;
    EndDate: string;
    ActualStartDate: string | null;
    ActualEndDate: string | null;
    Note: string;
    DeliveryStatus: string;
    DeliveryStatusId: DeliveryStatus;
    CarId: number | null;
    CarName: string | null;
    Driver1Id: number | null;
    Driver1Name: string | null;
    Driver2Id: number | null;
    Driver2Name: string | null;
    RegisterId: number | null;
    RegisterName: string | null;
    Directions: string;
    IsDynamicDeliveryRoute: boolean;
    RoomId: number | undefined;
    SubRoomId: number | undefined;
};
