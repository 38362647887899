import React from 'react';
import { PeripheralType } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { ZReportReceiptPrinterStatusPill } from './components/ZReportReceiptPrinterStatusPill';
import { ZReportReceiptPrinterSelectListItem } from './components/ZReportReceiptPrinterSelectListItem';
import { ZReportReceiptPrinterSettingsProvider } from './components/ZReportReceiptPrinterSettingsProvider';
import { ZReportReceiptPrinterStatusBanner } from './components/ZReportReceiptPrinterStatusBanner';
import { ZReportReceiptPrinterTestPrintListItem } from './components/ZReportReceiptPrinterTestPrintListItem';
import { ZReportIcon } from 'assets/icons/settings/ZReportIcon';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const ZReportReceiptPrinterSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  return (
    <>
      <SettingsListItem
        automationId='zreport_printer_settings'
        icon={<ZReportIcon />}
        title='Z Report printer'
        subtitle='Settings for Z report printer'
        onClick={showDrawer}
        actions={[<ZReportReceiptPrinterStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='zreport_printer_settings-drawer'
        title='Z Report printer'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <ZReportReceiptPrinterSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <ZReportReceiptPrinterStatusBanner />
            <ZReportReceiptPrinterSelectListItem />
            <SettingsDivider />
            <ZReportReceiptPrinterTestPrintListItem />
          </SettingsCard>
          <AddDeviceListItem deviceType={PeripheralType.receiptPrinter} />
        </ZReportReceiptPrinterSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
