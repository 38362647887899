import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

export const TopInfoLabel = styled.span`
  color: ${colors.dutchie.grey};
  font-size: 16px;
  height: 1.25rem;
  line-height: 1.25rem;
  flex: 1;
`;

const TopInfoValue = styled(TopInfoLabel)`
  color: ${colors.dutchie.almostBlack};
  flex: 0 0 auto;

  &:last-child {
    margin-right: 4rem;
  }
`;

const TopInfoEdit = styled(EditIcon)`
  flex: 0 0 1rem;
  margin: 0 1rem;

  &:last-child {
    margin-right: 2rem;
  }
`;

const TopInfoDelete = styled(DeleteIcon)`
  flex: 0 0 1rem;
  color: ${colors.dutchie.red};
`;

type InlineEditFieldProps = {
  value?: string | number;
  label: string;
  placeholder?: string;
  showEdit?: boolean;
  showDelete?: boolean;
  suffix?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  className?: string;
  dataTestId?: string;
  valueTestId?: string;
  editTestId?: string;
};

export const TopInfoLine: FC<InlineEditFieldProps> = ({
  value,
  label,
  placeholder,
  showEdit = true,
  showDelete,
  suffix,
  onEdit,
  onDelete,
  className,
  dataTestId,
  valueTestId,
  editTestId,
}) => (
  <div data-testid={dataTestId} className={className}>
    <TopInfoLabel>{label}</TopInfoLabel>
    <TopInfoValue data-testid={valueTestId} className='top-info-line-value'>
      {value || placeholder}
      {suffix}
    </TopInfoValue>
    {showEdit && <TopInfoEdit data-testid={editTestId} onClick={onEdit} />}
    {showDelete && <TopInfoDelete onClick={onDelete} />}
  </div>
);
