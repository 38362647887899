import type { FilterOption, FilterOptionValue } from 'models/Search';

// Product Types
export type InventoryTagServerResponse = {
  PackageId: number;
  TagId: number;
  TagName: string;
};

export type InventoryTag = {
  packageId: number;
  tagId: number;
  tagName: string;
};

export type GetAllProductsServerResponse = {
  AllowSaleToMinor: boolean;
  AutoPrintLabel: boolean;
  BatchId?: number;
  BrandName?: string | null;
  CannabisInventory: string;
  CBDContent?: number;
  CBDContentUnitId?: number;
  ChargeCodeId: string | number | null; // TODO: verify type
  Coupon: string;
  DefaultLabelId?: number;
  DefaultUnitId?: number;
  Description: string;
  InvId: number;
  ItemLabelId?: number;
  MasterCategory: string;
  PackageId?: number;
  PackageItemId?: number;
  PackageItemSerialNumber?: string;
  ProductCategory: string;
  ProductDesc: string;
  ProductId: number;
  ProductImageURL: string;
  ProductNo: string;
  ProductType: 'Wgt' | 'Qty';
  ReceivedDate?: string;
  RecUnitPrice: number;
  SerialNo: string;
  Strain: string;
  THCContent?: number;
  THCContentUnitId?: number;
  TotalAvailable: number;
  TotalGrams: number;
  TotalOz: number;
  UnitGrams: number;
  UnitPrice: number;
  UseByDate?: string;
  Vendor?: string | null;
};

export type ProductSearchResult = ReturnType<typeof transformToProductSearchResult>;
export type ProductFilterOption = FilterOption<ProductSearchResult>;
export type ProductFilterOptionValue = FilterOptionValue<ProductSearchResult>;

export const transformToProductSearchResult = (productData: GetAllProductsServerResponse) => {
  return {
    allowSaleToMinor: productData.AllowSaleToMinor,
    autoPrintLabel: productData.AutoPrintLabel,
    batchId: productData.BatchId,
    brandName: productData.BrandName,
    cannabisInventory: productData.CannabisInventory,
    cbdContent: productData.CBDContent,
    cbdContentUnitId: productData.CBDContentUnitId,
    chargeCodeId: productData.ChargeCodeId,
    coupon: productData.Coupon,
    defaultLabelId: productData.DefaultLabelId,
    defaultUnitId: productData.DefaultUnitId,
    description: productData.Description,
    invId: productData.InvId,
    itemLabelId: productData.ItemLabelId,
    masterCategory: productData.MasterCategory,
    packageId: productData.PackageId,
    packageItemId: productData.PackageItemId,
    packageItemSerialNumber: productData.PackageItemSerialNumber,
    productCategory: productData.ProductCategory,
    productDescription: productData.ProductDesc,
    productId: productData.ProductId,
    productImageURL: productData.ProductImageURL,
    productNo: productData.ProductNo,
    productType: productData.ProductType,
    receivedDate: productData.ReceivedDate,
    recUnitPrice: productData.RecUnitPrice,
    serialNo: productData.SerialNo,
    strain: productData.Strain,
    inventoryTags: [] as InventoryTag[], // empty array until we fetch the tags in a separate query
    thcContent: productData.THCContent,
    thcContentUnitId: productData.THCContentUnitId,
    totalAvailable: productData.TotalAvailable,
    totalGrams: productData.TotalGrams,
    totalOz: productData.TotalOz,
    unitGrams: productData.UnitGrams,
    unitPrice: productData.UnitPrice,
    useByDate: productData.UseByDate,
    vendor: productData.Vendor,
  };
};

// Product History Types

export type ProductHistoryServerResponse = {
  BioTrackDt: string | null;
  BioTrackResponse: string;
  Equivalent: number;
  Grams: number;
  Instructions: string;
  LotNo: string;
  OwnerLocation: string;
  OwnerName: string;
  PatientName: string;
  PosDate: string;
  PosId: number;
  PosQty: number;
  PosStatus: string;
  Prescription: string;
  PrescriptionNumber: number;
  ProdId: number;
  ProductCategory: string;
  ProductDesc: string;
  ProductOz: number;
  ProductSku: string;
  ProductType: 'Quantity' | 'Weight';
  ReceiptNo: string;
  ReturnBy: string;
  ReturnReason: string;
  SerialNo: string;
  SoldBy: string;
  TotalPrice: number;
};

export type ProductHistoryItem = ReturnType<typeof transformToProductHistoryItem>;

export const transformToProductHistoryItem = (response: ProductHistoryServerResponse) => ({
  biotrackDate: response.BioTrackDt,
  biotrackResponse: response.BioTrackResponse,
  equivalent: response.Equivalent,
  grams: response.Grams,
  instructions: response.Instructions,
  locationName: response.OwnerLocation,
  lotNumber: response.LotNo,
  lspName: response.OwnerName,
  patientName: response.PatientName,
  prescription: response.Prescription,
  prescriptionNumber: response.PrescriptionNumber,
  productCategory: response.ProductCategory,
  productDescription: response.ProductDesc,
  productId: response.ProdId,
  productOz: response.ProductOz,
  productSku: response.ProductSku,
  productType: response.ProductType,
  quantity: response.PosQty,
  receiptNumber: response.ReceiptNo,
  returnBy: response.ReturnBy,
  returnReason: response.ReturnReason,
  serialNo: response.SerialNo,
  shipmentDate: response.PosDate,
  shipmentId: response.PosId,
  shipmentStatus: response.PosStatus,
  soldBy: response.SoldBy,
  totalPrice: response.TotalPrice,
});

// Transaction Payment Types

export type TransactionPaymentServerResponse = {
  InvoiceTotal: number;
  CashPaid: number | undefined;
  DebitPaid: number | undefined;
  GiftPaid: number | undefined;
  MMAPPaid: number | undefined;
  CheckPaid: number | undefined;
  CreditPaid: number | undefined;
  TotalDueCustomer: number | undefined;
};

export type PaymentDetail = {
  type: string;
  amount: number;
};

export type TransactionPaymentRecord = ReturnType<typeof transformToTransactionPaymentRecord>;

export const transformToTransactionPaymentRecord = (record: TransactionPaymentServerResponse) => {
  return {
    invoiceTotal: record.InvoiceTotal,
    payments: [
      { type: 'Cash', amount: record.CashPaid ?? 0 },
      { type: 'Debit', amount: record.DebitPaid ?? 0 },
      { type: 'Gift', amount: record.GiftPaid ?? 0 },
      { type: 'MMAP', amount: record.MMAPPaid ?? 0 },
      { type: 'Check', amount: record.CheckPaid ?? 0 },
      { type: 'Credit', amount: record.CreditPaid ?? 0 },
    ] as PaymentDetail[],
    changeDueCustomer: record.TotalDueCustomer,
  };
};
