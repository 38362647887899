import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { transactionPaymentKeys } from './query-key-factory';
import { transformToTransactionPaymentRecord } from './types';

import type { TransactionPaymentServerResponse } from './types';

const GET_TRANSACTION_PAYMENT = 'v2/product/get_transaction_payment';

type TransactionPaymentPayload = {
  shipmentId?: number;
};

const transformToServerPayload = (payload: TransactionPaymentPayload) => {
  return {
    ShipmentId: payload.shipmentId,
  };
};

export const useTransactionPaymentQuery = (payload: TransactionPaymentPayload) => {
  return useQuery({
    queryKey: payload.shipmentId ? transactionPaymentKeys.one(payload.shipmentId) : transactionPaymentKeys.all,
    queryFn: async () => {
      const response = await post<TransactionPaymentServerResponse>(
        GET_TRANSACTION_PAYMENT,
        transformToServerPayload(payload)
      );

      return transformToTransactionPaymentRecord(response);
    },
  });
};

