import { useCallback } from 'react';

import { getExpiredItemsInCart } from 'api/CartApi';
import { useAppSelector } from 'util/hooks';
import { useCartPopups } from 'components/CartPopups';

export const usePreventAddingExpiredItems = () => {
  const isCheckExpirationDateEnabled = useAppSelector((state) => state.settings.features.CheckExpirationDate);

  const cartPopups = useCartPopups();

  return useCallback(
    async (serialNumber: string) => {
      if (isCheckExpirationDateEnabled) {
        const expiredItems = await getExpiredItemsInCart([serialNumber]);
        if (expiredItems?.length > 0 ?? false) {
          return new Promise<void>((resolve, reject) => {
            cartPopups.showManagerPinPopup(
              () => {
                resolve();
              },
              'Add Expired Item',
              undefined,
              undefined,
              undefined,
              () => {
                reject();
              }
            );
          });
        }
      }

      return Promise.resolve();
    },
    [cartPopups, isCheckExpirationDateEnabled]
  );
};
