import { useSelector } from 'react-redux';
import { State } from 'store';
import { useCallback } from 'react';

export const useCheckPreorderEligible = () => {
  const preorderSettings = useSelector((x: State) => x.settings.preorderSettings);
  const checkedInDetails = useSelector((x: State) => x.guestList.guests);

  const callback = useCallback(
    (guestId?: number) => {
      if (!preorderSettings?.UseFulfillmentModel) {
        //can't create preorders if the feature is off
        return false;
      }

      const openTransactions = checkedInDetails.filter((x) => x.Guest_id === guestId);

      if (!openTransactions.length) {
        //if the guest isn't checked in, allow manual preorders
        return true;
      }

      if (openTransactions.some((x) => x.HasPreorder) || openTransactions.some((x) => x.TotalItems > 0)) {
        //don't allow preorders if one exists or the guest has a real cart
        return false;
      }

      return true;
    },
    [preorderSettings, checkedInDetails]
  );

  return callback;
};
