import { useState } from 'react';

import type { OverflowMenuOption } from 'components/menus/OverflowMenu';

type UseOverflowMenuProps = {
  menuOptions: OverflowMenuOption[];
  altMenuOptions?: OverflowMenuOption[];
};

export const useOverflowMenu = ({ menuOptions, altMenuOptions }: UseOverflowMenuProps) => {
  const [menuOptionsToDisplay, setMenuOptionsToDisplay] = useState(menuOptions);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchorElement);

  const handleAnchorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(isMenuOpen ? null : event.currentTarget);
    event.preventDefault();
    event.stopPropagation();

    // Need to include both ctrlKey and metaKey for cross-platform support
    const shouldShowAltMenu = !!altMenuOptions?.length && (event.ctrlKey || event.metaKey);
    setMenuOptionsToDisplay(shouldShowAltMenu ? altMenuOptions : menuOptions);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElement(null);
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>, optionOnClick?: () => void) => {
    handleClose(event);
    optionOnClick?.();
  };

  return {
    anchorElement,
    isMenuOpen,
    handleAnchorClick,
    handleClose,
    handleOptionClick,
    menuOptionsToDisplay,
  };
};
