import { first, includes } from 'lodash';
import { createAction, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import * as PosApi from 'api/PosApi';
import * as CheckoutApi from 'api/CheckoutApi';
import * as DeliveryApi from 'api/DeliveryApi';
import * as CartReducer from 'store/actions/CartActions';
import {
  PaymentMethod,
  CheckoutRequest,
  PaymentType,
  GetDeliveryManifestRequest,
  UpdateDeliveryManifestRequest,
  SetReadyForDeliveryRequest,
  DigitalPaymentRequest,
  GetTransactionNotesRequest,
  SetTransactionNotesRequest,
  CancelJoryTransaction,
  Payments,
  PollingElectronicPaymentRequest,
  ExistingPaymentMethod,
  TaskGeneratedResponse,
  PollingElectronicPaymentResponse,
} from 'models/Checkout';
import { printReceipt } from 'util/hooks/printing/printJobs/usePrintReceipt';
import { AppDispatch, State } from 'store';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { getPaymentSummary, roundChangeDue } from 'util/Helpers';
import React from 'react';
import { deliveryProviderToString } from 'api/SettingsApi';
import { PusherChannelConfiguration, PusherChannelType } from 'models/Pusher';
import { post } from 'api/HttpHelpers';
import { getDutchiePayErrorMessage, getPinDebitErrorMessage } from '@dutchie/error-messaging';
import { refetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { ResultResponse, PosApiResponse } from 'models/Misc';
import { SettingsState } from 'store/reducers/SettingsReducer';
import { CustomerDetails } from 'models/Customer';
import { LaunchDarklyFlags } from 'store/reducers/LaunchDarklyReducer';
import { printRegalaReceipt } from 'util/hooks/printing/printJobs/usePrintRegalaReceipt';
import { getIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';
import { getLoadedTransactionInfo } from 'pages/CartPage/hooks/useTransactionManager';
import { getCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { LD_FLAG_PAYMENTS_HUB_PUSHER } from 'util/hooks/launch-darkly/usePaymentsHubPusher';
import { LD_FLAG_DUTCHIE_PAY_HUMANIZED_ERROR_MESSAGING } from 'util/hooks/launch-darkly/usePaymentsServiceErrorMessaging';
import { LD_FLAG_PAYMENTS_HUB_SPLIT_PAYMENTS } from 'util/hooks/launch-darkly/usePaymentsHubSplitPayments';
import { LD_FLAG_ADD_WEDGE_TO_RECEIPT } from 'util/hooks/launch-darkly/useAddWedgeToReceipt';
import { customEventKeys, logger } from 'util/logger';

export const setApprovalCode = createAction('setApprovalCode', (payload: string) => ({ payload }));
export const setManagerPin = createAction('setManagerPin', (payload: string) => ({ payload }));
export const startCheckout = createAction('startCheckout', (amount: number) => ({
  payload: amount,
}));
export const quitCheckout = createAction('quitCheckout');
export const cancelCheckout = createAction('cancelCheckout');
export const refreshCheckout = createAction('refreshCheckout', (amount: number) => ({ payload: amount }));
export const addPaymentMethod = createAction('addPaymentMethod', (payload: PaymentMethod) => ({ payload }));
export const addPaymentMethod_V2 = createAction('addPaymentMethod_V2', (payload: PaymentMethod) => ({ payload }));
export const updatePaymentMethod = createAction(
  'updatePaymentMethod',
  (payload: { index: number; method: PaymentMethod }) => ({ payload })
);
export const removePaymentMethod = createAction('removePaymentMethod', (payload: string) => ({ payload }));
export const acceptEducationalMaterials = createAction('acceptEducationalMaterials');
export const rejectEducationalMaterials = createAction('rejectEducationalMaterials');
export const paymentConfirmation = createAction('paymentConfirmation');
export const finishCheckout = createAction('finishCheckout');
export const finishCheckoutOnTwoPanelLayout = createAction('finishCheckoutOnTwoPanelLayout');
export const stopSpinning = createAction('stopSpinning');
export const invalidManagerPin = createAction('invalidManagerPin');
export const invalidApprovalCode = createAction('invalidApprovalCode');
export const updateLoyaltySignups = createAction('updateLoyaltySignups', (payload: Record<string, boolean>) => ({
  payload,
}));
export const setSelectedPaymentType = createAction('setSelectedPaymentType', (payload: PaymentType | null) => ({
  payload,
}));
export const setSelectedPayments = createAction('setSelectedPayments', (payload: Payments | null) => ({ payload }));
export const setAuthCode = createAction('setAuthCode', (payload: string) => ({ payload }));
export const clearSelectedPaymentData = createAction('clearSelectedPaymentData');
export const removePaymentType = createAction('removePaymentType', (payload: PaymentType) => ({ payload }));

export const checkPinForDigitalPayment = createAsyncThunk<
  void,
  { code: string; paymentMethod: PaymentMethod },
  { state: State }
>('checkPinForDigitalPayment', async (args, { dispatch }) => {
  try {
    const response = await PosApi.checkEmployeePin(args.code);
    const data = first(response);
    if (data?.UserId !== 0) {
      dispatch(digitalPayment(args.paymentMethod));
    } else {
      dispatch(invalidManagerPin());
      dispatch(errorNotification('Invalid PIN number'));
    }
  } catch {
    dispatch(errorNotification('Could not validate PIN number'));
  }
});

export const setExistingPayments = createAsyncThunk<
  ExistingPaymentMethod[],
  { ExistingPayments: Array<ExistingPaymentMethod>; ShipmentId: number },
  { state: State }
>('setExistingPayments', async (args, { dispatch }) => {
  const pollingPayment = args.ExistingPayments.find((p) => !p.PollingComplete);
  if (pollingPayment) {
    dispatch(
      startPolling({
        request: {
          ElectronicPaymentType: pollingPayment.PaymentProviderType,
          ShipmentId: args.ShipmentId,
        },
        paymentMethod: pollingPayment,
      })
    );
  }
  return args.ExistingPayments.filter((p) => p !== pollingPayment);
});

export const handlePinDebitErrorResponse = async (rawError: unknown, dispatch: Dispatch, defaultMessage: string) => {
  try {
    let errorCode = null;
    if (typeof rawError === 'string') {
      const error = JSON.parse(rawError);
      errorCode = error.code;
    } else if (typeof rawError === 'object' && rawError && 'code' in rawError) {
      errorCode = rawError.code ?? null;
    }
    const errorMessage = getPinDebitErrorMessage(errorCode, defaultMessage);
    await dispatch(errorNotification(errorMessage.pos));
  } catch {
    await dispatch(errorNotification(defaultMessage));
  }
};

export const digitalPayment = createAsyncThunk(
  'digitalPayment',
  async (paymentMethod, { dispatch, getState }) => {
    const { customer, settings, ldFlags } = getState() as State;

    const { guestId, shipmentId } = getLoadedTransactionInfo(customer.details);

    let continueSpinning = false;
    let paymentProviderType = paymentMethod.PaymentProviderType ?? paymentMethod?.name;
    let amount = paymentMethod?.amount;
    let recordAmount = true;
    let data = paymentMethod?.data;

    if (
      (paymentMethod?.type === PaymentType.Debit || paymentMethod?.type === PaymentType.Hub) &&
      settings.integrations?.UseIntegratedDebit
    ) {
      paymentProviderType = settings.integrations?.IntegratedDebitProvider as string;
      amount = paymentMethod?.amount;
      recordAmount = false;
      data =
        ldFlags[LD_FLAG_PAYMENTS_HUB_SPLIT_PAYMENTS] && paymentMethod?.type === PaymentType.Hub
          ? paymentMethod?.data
          : undefined;
    }

    if (shipmentId) {
      const params: DigitalPaymentRequest = {
        ElectronicPaid: amount.toFixed(2),
        ElectronicPaymentData: data,
        ElectronicPaymentType: paymentProviderType,
        ShipmentId: shipmentId,
        RecordAmountAsElectronicPayment: recordAmount,
        Register: settings.selectedRegister?.value || 0,
        SupportsPolling: true,
      };

      try {
        const res = await CheckoutApi.electronicPayment(params);
        if (res.IsPolling) {
          const request = {
            ShipmentId: shipmentId,
            ElectronicPaymentType: paymentProviderType,
          };
          await dispatch(startPolling({ request, paymentMethod }));
          continueSpinning = true;
        } else {
          if (
            paymentProviderType === 'jory' ||
            paymentProviderType === 'paynetworx' ||
            paymentProviderType === 'planetpayments' ||
            paymentProviderType === 'paymentshub'
          ) {
            dispatch(CartReducer.updateElectronicPaymentDetails(res));
            paymentMethod.amount = res.DebitPaid + res.CreditPaid;
            paymentMethod.tipAmount = res.TipAmount;
          }

          dispatch(addPaymentMethod(paymentMethod));
          dispatch(successNotification('Electronic Payment Success'));
        }
      } catch (e) {
        const errMsg = `${JSON.stringify(e)}`;
        if (e === 'Transaction cancelled by user') {
          dispatch(successNotification(e));
        } else if (includes(errMsg, 'Cart is missing payment, please reload')) {
          await dispatch(
            CartReducer.loadMissingPayments({
              ShipmentId: shipmentId,
              Timestamp: +new Date(),
              AcctId: guestId,
              Register: Number(settings.selectedRegister?.value),
            })
          );
          await refetchCartDetails(dispatch as AppDispatch, {
            guestId: guestId ?? 0,
            registerId: Number(settings.selectedRegister?.value),
            shipmentId: shipmentId ?? 0,
          });
          dispatch(successNotification('Previous Payment Succeeded, applying'));
        } else {
          const defaultErrorMessage = `Electronic Payment Failed. ${JSON.stringify(e)}`;
          handlePinDebitErrorResponse(e, dispatch, defaultErrorMessage);
        }
      }
    }

    if (!continueSpinning) {
      dispatch(stopSpinning());
    }
  }
);

export const startPolling = createAsyncThunk<number, PollPaymentArgs, { state: State }>(
  'startPolling',
  async (args: PollPaymentArgs, { dispatch, getState }) => {
    const { ldFlags } = getState() as State;
    // if hub payment and pusher based polling enabled, bypass pollPayment
    if (ldFlags[LD_FLAG_PAYMENTS_HUB_PUSHER] && args.paymentMethod.type === PaymentType.Hub) {
      return;
    }
    const pollingInterval = ldFlags['pos.register.payment-polling-interval.entitlement'] as number;
    const paymentPollingIntervalId = window.setTimeout(async () => {
      await dispatch(pollPayment({ request: args.request, paymentMethod: args.paymentMethod }));
    }, (pollingInterval ?? 5) * 1000);
    return paymentPollingIntervalId;
  }
);

type PollPaymentArgs = {
  request: PollingElectronicPaymentRequest;
  paymentMethod: PaymentMethod;
  pollingIntervalSeconds?: number;
};
export const pollPayment = createAsyncThunk<void, PollPaymentArgs, { state: State }>(
  'pollPayment',
  async (args: PollPaymentArgs, { dispatch, getState }) => {
    const paymentMethod = { ...args.paymentMethod };

    let resp: PollingElectronicPaymentResponse;
    try {
      resp = await CheckoutApi.pollPayment(args.request);
    } catch (e) {
      await dispatch(startPolling({ request: args.request, paymentMethod: args.paymentMethod }));
      return;
    }

    const { checkout, customer, ldFlags, settings } = getState();

    const { guestId, shipmentId } = getLoadedTransactionInfo(customer.details);

    // It's conceivable that pollPayment took some time to complete, and the budtender has done other
    // things in the UI such that it is no longer appropriate to add/update the payment method or
    // continue polling. For example:
    if (args.request.ShipmentId !== shipmentId) {
      // The budtender has loaded a different cart from the one we were polling for
      return;
    } else if (checkout.payment?.methods.some((p) => p.type === paymentMethod.type)) {
      // Logging out and logging in causes a second polling call
      // NOTE: this is causes a known issue where adding a second payment of a given type
      // (for example, split payment across 2 cards with Planet Payments) will cause the
      // spinner to hang.
      return;
    }

    if (resp.PollingComplete) {
      await dispatch(stopPolling());

      if (resp.PaymentSuccess) {
        dispatch(CartReducer.updateElectronicPaymentDetails(resp));
        paymentMethod.tipAmount = resp.TipAmount;

        if (args.request.ElectronicPaymentType === 'paymentshub') {
          paymentMethod.name = 'Card';
          paymentMethod.amount = resp.DebitPaid > 0 ? resp.DebitPaid : resp.CreditPaid;
          paymentMethod.type = resp.DebitPaid > 0 ? PaymentType.Debit : PaymentType.Credit;
        } else if (resp.ElectronicAmount > 0) {
          paymentMethod.name = "Electronic";
          paymentMethod.amount = resp.ElectronicAmount;
          paymentMethod.type = PaymentType.Electronic;
        } else if (resp.DebitPaid > 0) {
          paymentMethod.name = 'Debit';
          paymentMethod.amount = resp.DebitPaid;
          paymentMethod.type = PaymentType.Debit;
        } else {
          paymentMethod.name = 'Credit';
          paymentMethod.amount = resp.CreditPaid;
          paymentMethod.type = PaymentType.Credit;
        }

        const usingHub = settings.integrations?.UsePaymentsHub;

        // Polled payments expect all calls to poll payment to return a payment -- Dutchie Pay
        // charges are preauthorizations, which confuse the register into adding a $0.00 card charge
        // to the payment methods list. Theres no legitimate use case for a zero-sum payment, so we can
        // just strip these payments off the register. Since this is a DutchiePay use case, we use the DutchiePay
        // rollout flag in the interest of extreme caution.
        if (!ldFlags['fintech.payments-hub.dutchie-pay-rollout'] || paymentMethod.amount !== 0) {
          dispatch(addPaymentMethod(paymentMethod));
          dispatch(successNotification('Electronic Payment Success'));
        }

        if (resp.RefreshCart) {
          await refetchCartDetails(dispatch as AppDispatch, {
            guestId: guestId ?? 0,
            registerId: Number(settings.selectedRegister?.value),
            shipmentId: shipmentId ?? 0,
          });
        }

        const usingDcDirect = settings.integrations?.IntegratedDebitProvider === 'paynetworx';
        const usingUsag = settings.integrations?.DebitProcessor === 'usag';
        const paymentWasUsag = resp.PaymentsHubProcessor === 'usag' && resp.DebitPaid > 0;
        //We need to print this receipt only if the payment made was regala pay/USAG
        //Therefor print it if they're using DCDirect with USAG
        // Or if they are using hub and a debit payment was made with USAG
        if ((usingDcDirect && usingUsag) || (usingHub && paymentWasUsag)) {
            await printRegalaReceipt({
              dispatch: dispatch as AppDispatch,
              popCashDrawer: false,
              receiptParameters: shipmentId,
              receiptType: 'Receipt',
              showDeliveryDetails: false,
            });
        }
      } else {
        const defaultMessage = `Electronic Payment Failed. ${resp.PollingError}`;
        await handlePinDebitErrorResponse(resp, dispatch, defaultMessage);
      }

      dispatch(stopSpinning());
    } else {
      await dispatch(startPolling({ request: args.request, paymentMethod: args.paymentMethod }));
    }
  }
);

export const stopPolling = createAsyncThunk('stopPolling', async (_, { getState }) => {
  const { checkout } = getState() as State;
  checkout.paymentPollingIntervalId && clearTimeout(checkout.paymentPollingIntervalId);
});

export const checkPinAndPerformCheckout = createAsyncThunk<void, string, { state: State }>(
  'checkPinAndPerformCheckout',
  async (code, { dispatch, getState }) => {
    const { customer } = getState() as State;
    const { shipmentId } = getLoadedTransactionInfo(customer.details);

    try {
      const response = await PosApi.checkEmployeePin(code, shipmentId);
      const data = first(response);
      if (data?.UserId !== 0) {
        await dispatch(performCheckout(data?.UserId));
      } else {
        dispatch(invalidManagerPin());
        dispatch(errorNotification('Invalid PIN number'));
      }
    } catch {
      dispatch(errorNotification('Could not validate PIN number'));
    }
  }
);

const defaultDutchiePayErrorMessage = 'Unable to complete Dutchie Pay transaction. Please use another payment method.';

const sanitizeErrorMessageString = (message: string) => {
  return message.replaceAll('"', '').trim();
};

const parseErrorCode = (message: string) => {
  const errorCodeDelimiter = ' ';
  const sanitizedMessage = sanitizeErrorMessageString(message);
  const splitMessage = sanitizedMessage.split(errorCodeDelimiter);
  return splitMessage[splitMessage.length - 1];
};

export const isPosApiResponse = <T>(argument: unknown): argument is PosApiResponse<T> => {
  return (argument as PosApiResponse<T>)?.Data !== undefined;
};

export const performCheckout = createAsyncThunk<void, number | undefined, { state: State }>(
  'performCheckout',
  async (userId, { dispatch, getState }) => {
    const { checkout, customer, settings, cart, ldFlags } = getState() as State;

    const { data: cartDetails, guestId, shipmentId } = getCartDetails({ customer, cart });

    const paymentSummary = checkout.payment ? getPaymentSummary(checkout.payment.methods) : {};
    const tip = cartDetails.TipAmount;

    const cash = paymentSummary[PaymentType.Cash];
    const debit = paymentSummary[PaymentType.Debit];
    const check = paymentSummary[PaymentType.Check];
    const creditcard = paymentSummary[PaymentType.Credit];
    const digital = paymentSummary[PaymentType.Digital];
    const prepayment = paymentSummary[PaymentType.Prepayment];
    const gift = paymentSummary[PaymentType['Gift Card']];
    const mmap = paymentSummary[PaymentType.MMAP];
    const manual = paymentSummary[PaymentType.Manual];
    const electronic = paymentSummary[PaymentType.Electronic];

    // This represents the Ecomm DutchiePay Preorder/Prepay value/amount
    const dutchie = paymentSummary[PaymentType.Dutchie];

    // This represents the In-Store intiated order value/amount
    const dutchiePay = paymentSummary[PaymentType.DutchiePay];

    // These values combined represent the total DutchiePay value/amount
    const preauth = (dutchie ?? 0) + (dutchiePay ?? 0);

    let paymentMethod = checkout.payment?.methods.find((m) => m.type === PaymentType.Debit);
    const authCode = paymentMethod?.authCode ?? '';
    const total = cartDetails.GrandTotalRounded;
    const subtotal = cartDetails.SubTotal;
    let change = 0;

    change = Math.abs(
      total -
        ((cash ?? 0) +
          (debit ?? 0) +
          (digital ?? 0) +
          (check ?? 0) +
          (creditcard ?? 0) +
          (gift ?? 0) +
          (mmap ?? 0) +
          (prepayment ?? 0) +
          (preauth ?? 0) +
          (manual ?? 0) +
          (ldFlags[LD_FLAG_ADD_WEDGE_TO_RECEIPT] ? (electronic ?? 0) : 0) -
          (tip ?? 0))
    );

    const changeRounded = roundChangeDue(settings.features, change);
    if (customer.details && settings.selectedRegister) {
      const isDaysSupplyEnabled = getIsDaysSupplyFeatureEnabled({
        isDaysSupplyFFEnabled: settings.features.ShowDaysSupplyCalculator,
        isMedicalCustomer: customer.details.IsMedical,
      });

      const params: CheckoutRequest = {
        ShipmentId: shipmentId,
        MJStateIDNo: customer.details.MJStateIDNo,

        TotalItems: cartDetails.TotalItems,
        DiscountAmt: cartDetails.TotalDiscount,
        LoyaltyPoints: cartDetails.Loyalty?.AppliedLoyaltyPoints ?? 0,
        TaxAmt: cartDetails.Tax,

        CheckPaid: check,
        CCPaid: creditcard,
        AuthCode: authCode,
        TerminalId: Number(settings.selectedRegister.value),
        DebitPaid: debit,
        TipPaid: tip ? tip : 0,
        GiftPaid: gift,
        MMAPPaid: mmap,
        PreAuth: preauth,
        TotalCharges: total,
        Paidamt: cash,
        DueCustomer: changeRounded,
        DueCustomerRounded: changeRounded - change,
        TransType: 'Cash',
        TransId: '',
        SubTotal: subtotal,
        ApprovalCode: checkout.approvalCode,

        NetAmt: 0,
        TaxRt: 0,

        PinUserId: settings.features.CheckOutPinRequired ? userId : undefined,

        EducationalMaterialsAccepted: checkout.educationalMaterialsAccepted,
        ShowDaysSupplyCalculator: isDaysSupplyEnabled,
        CustomerId: customer.details.Guest_id,
        ReactVersion: React.version,
        PaymentMethods: checkout.payment?.methods ?? [],
        Data: cartDetails.PreauthInfo,
      };

      const keys = Object.keys(checkout.signups);
      if (checkout.signups && keys.length > 0) {
        params.Signups = keys.filter((x) => checkout.signups[x]);
      }

      paymentMethod = checkout.payment?.methods.find((m) => m.type === PaymentType.Digital);
      if (paymentMethod) {
        params.ElectronicPaid = paymentMethod.amount.toFixed(2);
        params.ElectronicPaymentData = paymentMethod.data;
        params.ElectronicPaymentType = paymentMethod.name;
      }

      // Logging the checkout request which includes the change due amount to help debug any discrepancies
      logger.info(`Checkout request for ${cartDetails?.ShipmentId ?? 'unknown shipment'}`, {
        key: customEventKeys.checkout.requestSent,
        payload: params ?? {},
      });

      if (ldFlags['pos.payments.typed-checkout-errors.rollout']) {
        try {
          const resp = await CheckoutApi.completeCheckoutNoThrow(params);
          if (!resp.Result) {
            throw resp;
          }
          const [data] = resp.Data;
          await processOverThreshold(data, dispatch as AppDispatch);
          await processBioTrackCheckout(params, settings, customer.details);
          dispatch(paymentConfirmation());
          await printReceipts(params, settings, dispatch as AppDispatch);
          await postCheckoutActions(params, ldFlags);
        } catch (e) {
          let errMsg = getErrorMessageAsString(e);

          if (isPosApiResponse<ResultResponse>(e)) {
            if (e.IntegrationData?.PaymentError === 'dutchiepay') {
              if (ldFlags[LD_FLAG_DUTCHIE_PAY_HUMANIZED_ERROR_MESSAGING]) {
                errMsg = e.IntegrationData?.Message ?? defaultDutchiePayErrorMessage;
              } else {
                const errorMessage = getDutchiePayErrorMessage(
                  e.IntegrationData.ErrorCode,
                  defaultDutchiePayErrorMessage
                );

                if (e.IntegrationData.ErrorCode === 'F103' && e.IntegrationData?.Message) {
                  errMsg = e.IntegrationData?.Message;
                } else {
                  errMsg = `${errorMessage.pos} ${e.IntegrationData.ErrorCode ?? ''}`;
                }
              }

              if (e.IntegrationData.ErrorCode === 'S010') {
                dispatch(removePaymentType(PaymentType.DutchiePay));
                dispatch(quitCheckout());
              }
              dispatch(CartReducer.setDutchiePayPreAuthError(true));
              dispatch(CartReducer.isDutchiePayError(true));
            }
          }

          dispatch(errorNotification(errMsg));

          if (includes(errMsg, 'Invalid Approval Code')) {
            dispatch(invalidApprovalCode());
          }

          if (isPosApiResponse<ResultResponse>(e) && e.IntegrationData?.MissingPaymentException !== undefined) {
            try {
              await dispatch(
                CartReducer.loadMissingPayments({
                  ShipmentId: shipmentId,
                  Timestamp: +new Date(),
                  AcctId: guestId,
                  Register: Number(settings.selectedRegister?.value),
                })
              );
              dispatch(CartReducer.setIsFalseNegativePinDebitError(true));
            } catch (ex) {
              dispatch(errorNotification('An unexpected error occurred. Please refresh the cart page and try again.'));
            }
          }
        }

        return;
      }

      try {
        const [data] = await CheckoutApi.completeCheckout(params);
        if (data && data.ResultId === 1) {
          if (data.OverThreshold) {
            dispatch(warningNotification('Your register is over the threshold, please perform a cash drop'));
          }

          const guest = customer.details;
          if (guest) {
            if (settings.integrations?.UseBioTrackPOS) {
              try {
                await CheckoutApi.biotrackCheckout({
                  PatientScan: params.MJStateIDNo,
                  ShipmentId: params.ShipmentId,
                });
              } catch {
                //biotrack secondary verification failed.  We believe this call is unnecssary, will likely be removed in a later release
              }
            }

            dispatch(paymentConfirmation());
          }
        }

        if (ldFlags['pos.post-checkout-actions']) {
          // MESSAGE FROM THE PAST: We DO NOT want to await here because the FE doesn't really care about the result
          CheckoutApi.postCheckout({
            ShipmentId: params.ShipmentId,
          }).catch(() => {});
        }
      } catch (e) {
        const errMsg = `${JSON.stringify(e)}`;

        if (cartDetails.PreauthInfo.PaymentType === 'dutchiepay' && !cart.dutchiePayPreAuthError) {
          const errorCode = parseErrorCode(errMsg);
          const errorMessage = getDutchiePayErrorMessage(errorCode, defaultDutchiePayErrorMessage);
          dispatch(errorNotification(errorMessage.pos));
          dispatch(CartReducer.setDutchiePayPreAuthError(true));
        } else {
          dispatch(errorNotification(errMsg));
        }

        if (includes(errMsg, 'Invalid Approval Code')) {
          dispatch(invalidApprovalCode());
        }

        // This should be looked at to see if we can check the type of error
        // vice using magic strings
        if (includes(errMsg, 'Unable to process the Dutchie Pay transaction')) {
          dispatch(CartReducer.isDutchiePayError(true));
        }

        // This should be looked at to see if we can check the type of error
        // vice using magic strings
        if (includes(errMsg, 'Cart is missing payment, please reload')) {
          try {
            await dispatch(
              CartReducer.loadMissingPayments({
                ShipmentId: shipmentId,
                Timestamp: +new Date(),
                AcctId: guestId,
                Register: Number(settings.selectedRegister?.value),
              })
            );
            dispatch(CartReducer.setIsFalseNegativePinDebitError(true));
          } catch (ex) {
            dispatch(errorNotification('An unexpected error occurred. Please refresh the cart page and try again.'));
          }
        }
      }
    }

    return;
  }
);

const getErrorMessageAsString = (e: unknown) => {
  if (isPosApiResponse<ResultResponse>(e)) {
    const errorStr = `${e.Message} ${e.IntegrationMessage ? e.IntegrationMessage : ''}`;

    return `${JSON.stringify(errorStr)}`;
  } else {
    return `${JSON.stringify(e)}`;
  }
};

const processOverThreshold = async (data: ResultResponse, dispatch: AppDispatch) => {
  if (data && data.ResultId === 1) {
    if (data.OverThreshold) {
      dispatch(warningNotification('Your register is over the threshold, please perform a cash drop'));
    }
  }
};

const processBioTrackCheckout = async (params: CheckoutRequest, settings: SettingsState, guest: CustomerDetails) => {
  if (guest) {
    if (settings.integrations?.UseBioTrackPOS) {
      try {
        await CheckoutApi.biotrackCheckout({
          PatientScan: params.MJStateIDNo,
          ShipmentId: params.ShipmentId,
        });
      } catch {
        //biotrack secondary verification failed.  We believe this call is unnecssary, will likely be removed in a later release
      }
    }
  }
};

const printReceipts = async (params: CheckoutRequest, settings: SettingsState, dispatch: AppDispatch) => {
  if (settings.userSettings.autoPrintReceipts) {
    await printReceipt({
      dispatch,
      receiptType: 'Receipt',
      receiptParameters: params.ShipmentId,
      showDeliveryDetails: false,
      popCashDrawer: true,
    });
  }
};

const postCheckoutActions = async (params: CheckoutRequest, ldFlags: LaunchDarklyFlags) => {
  if (ldFlags['pos.post-checkout-actions']) {
    // MESSAGE FROM THE PAST: We DO NOT want to await here because the FE doesn't really care about the result
    CheckoutApi.postCheckout({
      ShipmentId: params.ShipmentId,
    }).catch(() => {});
  }
};

export const getDeliveryDrivers = createAsyncThunk('getDeliveryDrivers', async (args, { dispatch }) => {
  try {
    return await DeliveryApi.getDrivers();
  } catch {
    dispatch(warningNotification('An error occurred while retrieving delivery drivers'));
    return Promise.reject();
  }
});

export const getDeliveryCars = createAsyncThunk('getDeliveryCars', async (args, { dispatch }) => {
  try {
    return await CheckoutApi.getCars();
  } catch {
    dispatch(warningNotification('An error occurred while retrieving delivery vehicles'));
    return Promise.reject();
  }
});

export const getDeliveryManifest = createAsyncThunk(
  'getDeliveryManifest',
  async (args: GetDeliveryManifestRequest, { dispatch }) => {
    try {
      return first(await CheckoutApi.getDeliveryManifest(args));
    } catch {
      dispatch(warningNotification('An error occurred while retrieving delivery manifest'));
      return Promise.reject();
    }
  }
);

export const updateDeliveryManifest = createAsyncThunk(
  'updateDeliveryManifest',
  async (args: UpdateDeliveryManifestRequest, { dispatch, getState }) => {
    const state = getState() as State;
    try {
      const response = await CheckoutApi.updateDeliveryManifest(args);
      dispatch(
        successNotification(
          `Manifest saved${getTaskGeneratedClause(response, state.settings.locationSettings?.DeliveryProvider)}`
        )
      );
      return first(await CheckoutApi.getDeliveryManifest({ TransId: args.TransId }));
    } catch {
      dispatch(errorNotification('An error occurred while updating delivery manifest'));
      return Promise.reject();
    }
  }
);

export const setReadyForDelivery = createAsyncThunk(
  'setReadyForDelivery',
  async (args: SetReadyForDeliveryRequest, { dispatch, getState }) => {
    const state = getState() as State;
    try {
      const response = await CheckoutApi.setReadyForDelivery(args);
      dispatch(
        successNotification(
          `Order ready for delivery${getTaskGeneratedClause(
            response,
            state.settings.locationSettings?.DeliveryProvider
          )}`
        )
      );
      if (response.DeliveryRoomThresholdViolations.MaxICTDollarRoomViolation) {
        dispatch(
          warningNotification(
            `The value of unordered inventory in ${response.DeliveryRoomThresholdViolations.MaxICTDollarRoomViolation} is above the defined threshold`
          )
        );
      }
      if (response.DeliveryRoomThresholdViolations.MaxTotalInventoryDollarRoomViolation) {
        dispatch(
          warningNotification(
            `The value of inventory in ${response.DeliveryRoomThresholdViolations.MaxTotalInventoryDollarRoomViolation} may be violated when the driver picks up these deliveries`
          )
        );
      }
      if (response.DeliveryRoomThresholdViolations.MaxInventoryWeightRoomViolation) {
        dispatch(
          warningNotification(
            `The weight of inventory in ${response.DeliveryRoomThresholdViolations.MaxInventoryWeightRoomViolation} may be violated when the driver picks up these deliveries`
          )
        );
      }
      if (response.DeliveryRoomThresholdViolations.MaxNonConcentrateWeightRoomViolation) {
        dispatch(
          warningNotification(
            `The weight of concentrate in ${response.DeliveryRoomThresholdViolations.MaxNonConcentrateWeightRoomViolation} may be violated when the driver picks up these deliveries`
          )
        );
      }
      if (response.DeliveryRoomThresholdViolations.MaxConcentrateWeightRoomViolation) {
        dispatch(
          warningNotification(
            `The weight of non-concentrate in ${response.DeliveryRoomThresholdViolations.MaxConcentrateWeightRoomViolation} may be violated when the driver picks up these deliveries`
          )
        );
      }

      return response;
    } catch (ex) {
      dispatch(errorNotification(`An error occurred while making ready for delivery ${ex}`));
      return Promise.reject();
    }
  }
);

export const getTaskGeneratedClause = (response: TaskGeneratedResponse, deliveryProvider?: number) => {
  if (response.DeliverySystemTaskGenerated) {
    return `\n${deliveryProviderToString(deliveryProvider)} Task Generated`;
  }
  if (response.DeliverySystemTaskUpdated) {
    return `\n${deliveryProviderToString(deliveryProvider)} Task Updated`;
  }

  return '';
};

export const bypassStateSystemAction = createAsyncThunk('bypassStateSystem', CheckoutApi.bypassStateSystem);

export const bypassStateSystem = createAsyncThunk(
  'bypassStateSystem',
  async (args: { ManagerPIN: string; ShipmentId: number }, { dispatch }) => {
    const response = await CheckoutApi.bypassStateSystem(args)
      .then(() => {
        dispatch(successNotification('Delivery bypassed'));
      })
      .catch((error: string) => {
        dispatch(errorNotification(error ? error : 'Delivery bypass failed'));
      });
    return response;
  }
);

export const getTransactionNotes = createAsyncThunk(
  'getTransactionNotes',
  async (args: GetTransactionNotesRequest, { dispatch }) => {
    try {
      return await CheckoutApi.getTransactionNotes(args);
    } catch {
      dispatch(warningNotification('An error occurred while retrieving delivery drivers'));
      return Promise.reject();
    }
  }
);

export const setTransactionNotes = createAsyncThunk(
  'setTransactionNotes',
  async (args: SetTransactionNotesRequest, { dispatch }) => {
    try {
      return await CheckoutApi.setTransactionNotes(args);
    } catch {
      dispatch(warningNotification('Notes failed to update'));
      return Promise.reject();
    }
  }
);

export const getLoyaltySignups = createAsyncThunk('getLoyaltySignups', async (args, { getState, dispatch }) => {
  try {
    const { customer } = getState() as State;
    const signups = {} as Record<string, boolean>;
    const CustomerId = customer.details?.Guest_id;

    if (!CustomerId) {
      return signups;
    }

    const loyaltyPrograms = await CheckoutApi.getLoyaltySignups({ CustomerId });
    loyaltyPrograms.forEach((program: string) => (signups[program] = false));
    return signups;
  } catch {
    dispatch(warningNotification('Failed to fetch Loyalty Signups'));
    return Promise.reject();
  }
});

export const cancelJoryTransaction = createAsyncThunk(
  'cancelJoryTransaction',
  async (args: CancelJoryTransaction, { dispatch }) => {
    return await CheckoutApi.cancelJoryTransaction(args).catch((err) => dispatch(errorNotification(err)));
  }
);

export const stopCheckoutRunning = createAction('stopCheckoutRunning');

export const getDutchiePayPusherConfiguration = createAsyncThunk(
  'getDutchiePayPusherConfiguration',
  async (_, { dispatch }) => {
    const response = await post<PusherChannelConfiguration>(
      `pusher/config/${PusherChannelType.DutchiePayInStore}`
    ).catch((_) => {
      const pusherResult: PusherChannelConfiguration = { PusherKey: '', PusherCluster: '', ChannelName: '' };
      dispatch(warningNotification('Unable to connect for real-time notifications'));
      return pusherResult;
    });
    return response;
  }
);

export const cleanDutchiePayPusherConfiguration = createAction('cleanDutchiePayPusherConfiguration');

export const getPaymentsPusherConfiguration = createAsyncThunk(
  'getPaymentsPusherConfiguration',
  async (_, { dispatch }) => {
    const response = await post<PusherChannelConfiguration>('pusher/config').catch((error) => {
      const pusherResult: PusherChannelConfiguration = { PusherKey: '', PusherCluster: '', ChannelName: '' };
      dispatch(warningNotification('Unable to connect for real-time notifications'));
      return pusherResult;
    });
    return response;
  }
);

export const authPaymentsPusherChannel = createAsyncThunk('authPaymentsPusherChannel', async (_) => {
  const response = await post<PusherChannelConfiguration>('pusher/config').catch(() => {
    const pusherResult: PusherChannelConfiguration = { PusherKey: '', PusherCluster: '', ChannelName: '' };
    return pusherResult;
  });
  return response;
});

export const cleanPaymentsPusherConfiguration = createAsyncThunk(
  'cleanPaymentsPusherConfiguration',
  async (args, { dispatch, getState }) => {
    const pusherConfiguration: PusherChannelConfiguration = {
      ChannelName: '',
      PusherCluster: '',
      PusherKey: '',
    };
    return pusherConfiguration;
  }
);
export const notifyConectionLost = createAction('notifyConectionLost');

export const notifyPusherConnected = createAction('notifyPusherConnected');
