import React from 'react';

import { ErrorNotificationModal } from 'components/modals/ErrorNotificationModal';

type ProductBeingAddedPastUsedByDatePopupProps = {
  hide: () => void;
  onContinue: () => void;
};

const HEADER_COPY = '"Use by" date has passed';
const MESSAGE = 'The use by date for this product has passed. Please notify the customer and proceed appropriately.';

export const ProductBeingAddedPastUsedByDatePopup = ({
  hide,
  onContinue,
}: ProductBeingAddedPastUsedByDatePopupProps): JSX.Element => {
  const handleContinue = () => {
    onContinue();
    hide();
  };

  return (
    <ErrorNotificationModal
      closeButtonLabel='Cancel'
      continueButtonLabel='Add Item'
      handleContinue={handleContinue}
      header={HEADER_COPY}
      hide={hide}
      message={MESSAGE}
    />
  );
};
