import React, { FC } from 'react';

import { PatientPolicy } from '../components/PatientPolicy';

type PatientPolicyTypeProps = {
  guestId?: number;
  disabled?: boolean;
};

export const PatientPolicyTab: FC<PatientPolicyTypeProps> = ({ guestId, disabled }) => {
  return <PatientPolicy guestId={guestId} disabled={disabled} />;
};
