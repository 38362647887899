import { colors } from 'css/Theme';
import React from 'react';
import styled from 'styled-components';
import ConfettiBackground from 'assets/icons/confetti-background.svg';
import { ReactComponent as DutchiePayLogoSmallWhite } from 'assets/icons/logo-dutchiepay-small-white.svg';

type DutchiePayTipBannerProps = {
  tipAmount: number;
  currencySymbol: string;
};
export const DutchiePayTipBanner = ({ tipAmount, currencySymbol }: DutchiePayTipBannerProps) => {
  return (
    <OuterContainer>
      <Container>
        <TextContent>
          <DollarAmountText>{`${currencySymbol}${tipAmount.toFixed(2)}`}</DollarAmountText>
          <AffirmationMessage>Nice one, you got a tip.</AffirmationMessage>
        </TextContent>
        <StyledDutchiePayLogoContainer>
          <DutchiePayLogoSmallWhite />
        </StyledDutchiePayLogoContainer>
      </Container>
    </OuterContainer>
  );
};

const TextContent = styled.div``;
const DollarAmountText = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
`;
const AffirmationMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const StyledDutchiePayLogoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const OuterContainer = styled.div`
  background-color: ${colors.dutchie.green};
  border-radius: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 32px;
  isolation: isolate;
  height: 86px;
  background-image: url(${ConfettiBackground});
  background-repeat: no-repeat;
  background-position: right;
  color: ${colors.white};
`;
