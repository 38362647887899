import * as Yup from 'yup';
import { CustomerDetails } from 'models/Customer';

export type FormSchema = Yup.StringSchema | Yup.NumberSchema<number | null> | Yup.DateSchema;

export type LocationState = {
  guestId?: number;
};

export const initialValues: Partial<CustomerDetails> = {
  address: {
    city: '',
    postal_code: '',
    state: '',
    street: '',
    street2: '',
    Country_Code: 'US',
  },
  CellPhone: '',
  CustomerTypeId: undefined,
  DOB: '',
  Email: '',
  ExternalName: '',
  nickname: '',
  CustomIdentifier: '',
  PhoneNo: '',
  ReferralSourceId: -1,
  status: 'Active',
  discounts: [],
  identifications: [
    {
      StartDate: '',
      ExpirationDate: '',
      number: '',
      image_url: '',
      additionalStateIdentifiers: '',
      type: 'DriversLicenseId',
    },
    {
      StartDate: '',
      ExpirationDate: '',
      number: '',
      image_url: '',
      additionalStateIdentifiers: '',
      type: 'MJStateIDNo',
    },
  ],
  doctor: '',
  CGFirstName: '',
  CGLastName: '',
  CaregiverMJStateIdNo: '',
  CaregiverAddress: '',
  CaregiverAddress2: '',
  CaregiverAddress3: '',
  CaregiverCity: '',
  CaregiverState: '',
  CaregiverPostalCode: '',
  CaregiverDOB: '',
  CaregiverEmail: '',
  CaregiverExpirationDate: '',
  CaregiverNotes: '',
  CaregiverPhone: '',
  CaregiverDOB2: '',
  CaregiverDOB3: '',
  CaregiverDOB4: '',
  CaregiverDOB5: '',
  CaregiverDOB6: '',
  CaregiverEmail2: '',
  CaregiverEmail3: '',
  CaregiverEmail4: '',
  CaregiverEmail5: '',
  CaregiverEmail6: '',
  CaregiverMJStateIdNo2: '',
  CaregiverMJStateIdNo3: '',
  CaregiverMJStateIdNo4: '',
  CaregiverMJStateIdNo5: '',
  CaregiverMJStateIdNo6: '',
  CaregiverName2: '',
  CaregiverName3: '',
  CaregiverName4: '',
  CaregiverName5: '',
  CaregiverName6: '',
  CaregiverNotes2: '',
  CaregiverNotes3: '',
  CaregiverNotes4: '',
  CaregiverNotes5: '',
  CaregiverNotes6: '',
  CaregiverPhone2: '',
  CaregiverPhone3: '',
  CaregiverPhone4: '',
  CaregiverPhone5: '',
  CaregiverPhone6: '',
  Guardian: '',
  notes: '',
  MJStateIDNo: '',
  MJStartDate: '',
  MMJIDState: '',
  MJExpirationDate: '',
  CertificationCollectionDate: '',
  CertificationExpirationDate: '',
  DLExpirationDate: '',
  DriversLicenseId: '',
  additionalStateIdentifiers: '',
  OptedIntoEngagementSMS: null,
  OptedIntoMarketing: null,
  Pronoun: '',
};
