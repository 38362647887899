import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PusherChannelConfiguration } from 'models/Pusher';
import { Transaction, ProductHistoryDetail, TransactionPaymentRecord } from 'models/Transactions';
import { cleanReturnsPusherConfiguration, getReturnsPusherConfiguration } from 'store/actions/PusherActions';
import {
  loadTransactions,
  includeCanceledTransactions,
  getProductHistoryDetail,
  getTransactionPayment,
  loadSalesHistory,
} from 'store/actions/TransactionsActions';

export type TransactionsState = {
  items: Array<Transaction>;
  transactionsLoading: boolean;
  selected?: Array<ProductHistoryDetail>;
  paymentTypeDetails?: TransactionPaymentRecord;
  transactionHistoryLoading: boolean;
  includeCanceledTransactions: boolean;
  paymentTypeDetailsLoading: boolean;
  returnsPusherConfig: PusherChannelConfiguration | null;
};

const initialState = {
  items: [],
  transactionsLoading: false,
  paymentTypeDetails: undefined,
  transactionHistoryLoading: false,
  includeCanceledTransactions: false,
  paymentTypeDetailsLoading: false,
  returnsPusherConfig: null,
};

export const transactionsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TransactionsState>) => {
    builder.addCase(loadTransactions.pending, (state: TransactionsState) => {
      state.transactionsLoading = !state.items.length;
    });
    builder.addCase(loadTransactions.fulfilled, (state: TransactionsState, { payload }) => {
      state.items = payload.Data?.length ? payload.Data : [];
      state.transactionsLoading = false;
    });
    builder.addCase(loadTransactions.rejected, (state: TransactionsState) => {
      state.transactionsLoading = false;
    });

    builder.addCase(loadSalesHistory.pending, (state: TransactionsState) => {
      state.transactionsLoading = !state.items.length;
    });
    builder.addCase(loadSalesHistory.fulfilled, (state: TransactionsState, { payload }) => {
      state.items = payload.Data?.length ? payload.Data : [];
      state.transactionsLoading = false;
    });
    builder.addCase(loadSalesHistory.rejected, (state: TransactionsState) => {
      state.transactionsLoading = false;
    });
    builder.addCase(getProductHistoryDetail.pending, (state: TransactionsState) => {
      state.transactionHistoryLoading = !state.selected?.length;
    });
    builder.addCase(getProductHistoryDetail.fulfilled, (state: TransactionsState, { payload }) => {
      state.selected = payload.Data?.length ? payload.Data : [];
      state.transactionHistoryLoading = false;
    });
    builder.addCase(getProductHistoryDetail.rejected, (state: TransactionsState) => {
      state.transactionHistoryLoading = false;
    });
    builder.addCase(includeCanceledTransactions, (state, action) => {
      state.includeCanceledTransactions = action.payload;
    });
    builder.addCase(getTransactionPayment.pending, (state: TransactionsState) => {
      state.paymentTypeDetailsLoading = true;
    });
    builder.addCase(getTransactionPayment.fulfilled, (state: TransactionsState, { payload }) => {
      state.paymentTypeDetails = payload;
      state.paymentTypeDetailsLoading = false;
    });
    builder.addCase(getTransactionPayment.rejected, (state: TransactionsState) => {
      state.paymentTypeDetailsLoading = false;
    });
    builder.addCase(cleanReturnsPusherConfiguration, (state) => {
      state.returnsPusherConfig = null;
    });
    builder.addCase(getReturnsPusherConfiguration.fulfilled, (state: TransactionsState, { payload }) => {
      state.returnsPusherConfig = payload;
    });
  },
});
