import React, { useRef } from 'react';
import { usePaginationDetails } from '../util/usePaginationDetails';
import { useTableStore } from '../util/useTableStore';
import { Pagination } from 'components/layout';
import { StyleVariant } from '../TableProps';

export const TableFoot: React.FC = () => {
  const { props, dispatch } = useTableStore();
  const colCount = props.columns.length;
  const pagination = usePaginationDetails();
  const containerEl = useRef<HTMLTableSectionElement>(null);

  if (!pagination) {
    return null;
  }

  const { currentPage, pageSize, isAtEnd, isAtStart, numItems } = pagination;
  const previousPage = () => dispatch({ type: 'previousPage' });
  const nextPage = () => dispatch({ type: 'nextPage' });
  const goToPage = (page: number) => dispatch({ type: 'goToPage', page });
  const setPageSize = (nextPageSize: number) => dispatch({ type: 'setPageSize', pageSize: nextPageSize });

  return (
    <tfoot ref={containerEl}>
      {pagination.hasPagination && (
        <tr>
          <td colSpan={colCount}>
            <Pagination
              small={props.styleVariant === StyleVariant.roundedWithWhiteBackground}
              pageIndex={currentPage - 1}
              containerEl={containerEl.current}
              pageSize={pageSize}
              dataLength={numItems}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={!isAtStart}
              canNextPage={!isAtEnd}
              goToPage={goToPage}
              setPageSize={setPageSize}
            />
          </td>
        </tr>
      )}
    </tfoot>
  );
};
