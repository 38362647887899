import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadLabData } from 'store/actions/ProductsActions';
import { usePriceCheckQuery } from 'queries/v2/inventory/price-check';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { State } from 'store';

type UseProductsPreviewPanel = {
  selectedProduct: ProductSearchResult;
};

export const useProductsPreviewPanel = ({ selectedProduct }: UseProductsPreviewPanel) => {
  const dispatch = useDispatch();

  // Global state
  const labData = useSelector((state: State) => state.products.labData);

  const { data: priceCheckDetails, isLoading: isPricingLoading } = usePriceCheckQuery({
    packageSerialNumber: selectedProduct?.serialNo ?? '',
  });

  useEffect(() => {
    dispatch(loadLabData(selectedProduct.batchId));
  }, [dispatch, selectedProduct]);

  return {
    flowerEquivalent: priceCheckDetails?.flowerEquivalent,
    isPricingLoading,
    labData,
    pricingTierDetails: priceCheckDetails?.pricingTier.priceDetails,
    productGrams: priceCheckDetails?.productGrams,
  };
};
