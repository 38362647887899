import mapboxgl from 'mapbox-gl';
import React, { FC } from 'react';
import ReactMapGL, { ViewState } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { useDeliveryPageEnhancements } from 'util/hooks/launch-darkly/useDeliveryPageEnhancements';
import { MapContent } from './MapContent';

// eslint-disable-next-line
(mapboxgl as any).workerClass = require('worker-loader?inline=fallback!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const NORTH_AMERICA_CENTER: Partial<ViewState> = {
  latitude: 47.1164,
  longitude: -101.2996,
  zoom: 11,
};

const MAP_STYLE = 'mapbox://styles/mapbox/streets-v11';

export const MapboxMap: FC = () => {
  const isUseDeliveryPageEnhancements = useDeliveryPageEnhancements();

  const apiKey = useSelector((state: State) => state.settings.MapboxKey);

  return (
    <>
      {isUseDeliveryPageEnhancements ? (
        <ReactMapGL
          mapStyle={MAP_STYLE}
          initialViewState={NORTH_AMERICA_CENTER}
          mapboxAccessToken={apiKey}
          style={{ width: '100%', height: '100%' }}
        >
          <MapContent />
        </ReactMapGL>
      ) : (
        <ReactMapGL mapStyle={MAP_STYLE} initialViewState={NORTH_AMERICA_CENTER} mapboxAccessToken={apiKey}>
          <MapContent />
        </ReactMapGL>
      )}
    </>
  );
};
