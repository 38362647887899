import React, { FC } from 'react';

import { NavButton } from 'components/buttons';
import { ReactComponent as GhostIcon } from 'assets/icons/ghost.svg';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useStartAnonymousOrder } from 'util/hooks/checkin/useStartAnonymousOrder';

export const AnonymousGuestButton: FC = () => {
  const { areAnonymousTransactionsEnabled } = useAnonymousCart();

  const { startAnonymousOrder } = useStartAnonymousOrder();

  if (!areAnonymousTransactionsEnabled) {
    return null;
  }

  return (
    <NavButton
      onClick={() => startAnonymousOrder()}
      icon={<GhostIcon />}
      automationId='action-bar_button_anon-guest'
      margin='0'
    >
      Anonymous Guest
    </NavButton>
  );
};
