import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Capacitor } from '@capacitor/core';
import { Storage } from '@capacitor/storage';
import { loadUserSettings, saveUserSettings } from 'store/actions/SettingsActions';
import { SettingsState } from 'store/reducers/SettingsReducer';
import { Register } from 'models/Misc';
import { FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS, LABEL_PRINTER_LOCAL_STORAGE_KEYS, RECEIPT_PRINTER_LOCAL_STORAGE_KEYS } from 'util/local-printing';

export const setupRegisterNew = async (
  payload: Register | undefined,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  settings: SettingsState
) => {
  const selectedRegister = settings.registers.find((item) => item.id === payload?.value);
  let useLocalLabel = false;
  let useLocalReceipt = false;
  let useLocalFulfillment = false;
  if (selectedRegister) {
    const selectedLabel = settings.labels.find((item) => item.id === selectedRegister.DefaultLabelId);

    if (Capacitor.getPlatform() === 'android') {
      useLocalLabel = ((await Storage.get({ key: LABEL_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL }))?.value ?? 'true') === 'true';
      useLocalReceipt = ((await Storage.get({ key: RECEIPT_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL }))?.value ?? 'true') === 'true';
      useLocalFulfillment =
        ((await Storage.get({ key: FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL }))?.value ?? 'true') === 'true';
    }

    // Update Redux. Printers are handled by Hardware Library hook.
    dispatch({
      type: saveUserSettings.type,
      payload: {
        useConnectedLabelPrinter: useLocalLabel,
        useConnectedReceiptPrinter: useLocalReceipt,
        useConnectedFulfillmentPrinter: useLocalFulfillment,
        selectedLabel: selectedLabel || undefined,
        autoPrintLabels: selectedRegister.AutoPrintLabels,
        autoPrintReceipts: selectedRegister.AutoPrintReceipts,
        autoPrintFulfillmentTicket: selectedRegister.AutoPrintFulfillmentTicket,
        popCashDrawer: selectedRegister.PopCashDrawer,
        showPopupNotes: selectedRegister.PopUpNotesOnEntry,
      },
    });
  } else {
    dispatch({
      type: saveUserSettings.type,
      payload: {
        selectedLabel: undefined,
        autoPrintLabels: false,
        autoPrintFulfillmentTicket: false,
        customerSearchConfig: undefined,
        autoPrintReceipts: false,
        popCashDrawer: false,
        showGuests: false,
        showPopupNotes: false,
        showSearch: true,
      },
    });
  }
  dispatch(loadUserSettings());
  return payload;
};

/** @deprecated */
export const setupRegisterOld = async (
  payload: Register | undefined,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  settings: SettingsState
) => {
  const selectedRegister = settings.registers.find((item) => item.id === payload?.value);
  let useLocalLabel = false;
  let useLocalReceipt = false;
  let useLocalFulfillment = false;
  if (selectedRegister) {
    const selectedLabel = settings.labels.find((item) => item.id === selectedRegister.DefaultLabelId);
    let selectedLabelPrinter = settings.labelPrinters.find(
      (item) => item.PrinterId === selectedRegister.LabelPrinterId
    );
    let selectedReceiptPrinter = settings.receiptPrinters.find(
      (item) => item.PrinterId === selectedRegister.ReceiptPrinterId
    );
    let selectedFulfillmentPrinter = settings.receiptPrinters.find(
      (item) => item.PrinterId === selectedRegister.FulfillmentPrinterId
    );

    if (Capacitor.getPlatform() === 'android') {
      const localLabel = await Storage.get({ key: LABEL_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT });
      const localReceiptPrinter = await Storage.get({ key: RECEIPT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT });
      const localFulfillment = await Storage.get({ key: FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT });

      useLocalLabel = ((await Storage.get({ key: LABEL_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL }))?.value ?? 'true') === 'true';
      useLocalReceipt = ((await Storage.get({ key: RECEIPT_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL }))?.value ?? 'true') === 'true';
      useLocalFulfillment =
        ((await Storage.get({ key: FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL }))?.value ?? 'true') === 'true';

      if (localLabel.value !== null) {
        selectedLabelPrinter = JSON.parse(localLabel.value);
      }
      if (localReceiptPrinter.value !== null) {
        selectedReceiptPrinter = JSON.parse(localReceiptPrinter.value);
      }
      if (localFulfillment.value !== null) {
        selectedFulfillmentPrinter = JSON.parse(localFulfillment.value);
      }
    }

    dispatch({
      type: saveUserSettings.type,
      payload: {
        selectedLabelPrinter,
        selectedReceiptPrinter,
        selectedFulfillmentPrinter,
        useConnectedLabelPrinter: useLocalLabel,
        useConnectedReceiptPrinter: useLocalReceipt,
        useConnectedFulfillmentPrinter: useLocalFulfillment,
        selectedLabel: selectedLabel || undefined,
        autoPrintLabels: selectedRegister.AutoPrintLabels,
        autoPrintReceipts: selectedRegister.AutoPrintReceipts,
        autoPrintFulfillmentTicket: selectedRegister.AutoPrintFulfillmentTicket,
        popCashDrawer: selectedRegister.PopCashDrawer,
        showPopupNotes: selectedRegister.PopUpNotesOnEntry,
      },
    });
  } else {
    dispatch({
      type: saveUserSettings.type,
      payload: {
        selectedLabel: undefined,
        selectedLabelPrinter: undefined,
        selectedReceiptPrinter: undefined,
        selectedFulfillmentPrinter: undefined,
        autoPrintLabels: false,
        autoPrintFulfillmentTicket: false,
        customerSearchConfig: undefined,
        autoPrintReceipts: false,
        popCashDrawer: false,
        showGuests: false,
        showPopupNotes: false,
        showSearch: true,
      },
    });
  }
  dispatch(loadUserSettings());
  return payload;
};
