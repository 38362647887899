import { useCallback, useMemo, useState } from 'react';

export const usePopupsState = <T extends object>(initialState: T) => {
  const [state, setState] = useState(initialState);

  const stateTuple = useMemo<[T, React.Dispatch<React.SetStateAction<T>>]>(() => [state, setState], [state]);

  const closePopup = useCallback(
    (popupName?: string) => {
      const popupNames = Object.keys(state);
      if (typeof popupName === 'string' && popupNames.includes(popupName)) {
        setState({
          ...state,
          [popupName]: null,
        });
      } else {
        setState(initialState);
      }
    },
    [initialState, state]
  );

  return { stateTuple, closePopup };
};

