import React from 'react';

import { AnonymousGuestButton as NewButton } from './AnonymousGuestButton';
import { AnonymousGuestButton as OldButton } from './AnonymousGuestButton-deprecated';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

export const AnonymousGuestButton = () => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  return isAnonymousCartLDFlagEnabled ? <NewButton /> : <OldButton />;
};
