import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { callback1 } from 'models/Misc';
import { ConfirmationPopup } from 'components/popups';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { colors } from 'css/Theme';
import { Input } from 'components/inputs';

const StyledMailIcon = styled(MailIcon)`
  opacity: 0.5;
  color: ${colors.primary};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  min-width: 472px;
  width: 100%;
`;

type EmailPopupProps = {
  onEmail: callback1<string>;
  hide: () => void;
};

export const EmailPopup: FC<EmailPopupProps> = ({ onEmail, hide }) => {
  const guest = useSelector((state: State) => state.customer.details);
  const [email, setEmail] = useState(guest?.Email || '');

  const handleSendClick = () => {
    onEmail(email);
    hide();
  };

  return (
    <ConfirmationPopup
      title='Email Receipt'
      isVisible
      hide={hide}
      confirm={{
        text: 'Send',
        onClick: handleSendClick,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <Container>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} startAdornment={<StyledMailIcon />} />
      </Container>
    </ConfirmationPopup>
  );
};
