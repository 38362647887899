import React, { createContext } from 'react';
import { usePopupsState } from 'util/hooks/usePopupsState';
import { CartPopupsDeprecated } from './CartPopups-deprecated';
import { CartPopupsState, emptyCartPopupsState } from './CartPopupsState';

export type CartPopupsContext = [CartPopupsState, (cartPopupsState: CartPopupsState) => void];
export const cartPopupsContext = createContext<CartPopupsContext | null>(null);

export const ProvideCartPopups: React.FC = ({ children }) => {
  const { stateTuple, closePopup } = usePopupsState<CartPopupsState>(emptyCartPopupsState);
  const [state] = stateTuple;

  return (
    <cartPopupsContext.Provider value={stateTuple}>
      <CartPopupsDeprecated cartPopupsState={state} closePopup={closePopup} />
      {children}
    </cartPopupsContext.Provider>
  );
};
