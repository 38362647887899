import React, { FC, createContext, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { PaymentType, PaymentMethod } from 'models/Checkout';
import { getPaymentSummary } from 'util/Helpers';
import { callback1 } from 'models/Misc';
import { GeneriPayPaymentIntegration } from 'queries/v2/payments/generipay';

type PaymentSummary = Record<PaymentType, number>;

const BLANK_PAYMENT_SUMMARY: PaymentSummary = {
  [PaymentType.Cash]: 0,
  [PaymentType.Credit]: 0,
  [PaymentType.Debit]: 0,
  [PaymentType.Check]: 0,
  [PaymentType['Gift Card']]: 0,
  [PaymentType.Digital]: 0,
  [PaymentType.Prepayment]: 0,
  [PaymentType.SplitPayment]: 0,
  [PaymentType.Dutchie]: 0,
  [PaymentType.DutchiePay]: 0,
  [PaymentType.MMAP]: 0,
  [PaymentType.RethinkEcomm]: 0,
  [PaymentType.RethinkSms]: 0,
  [PaymentType.Hub]: 0,
};

type CheckoutStore = {
  paymentSummary: PaymentSummary;
  addPaymentBtnVisible: boolean;
  paymentMethod: PaymentMethod | undefined;
  setPaymentMethod: callback1<PaymentMethod | undefined>;
  selectedPaymentType: PaymentType | null;
  setSelectedPaymentType: callback1<PaymentType | null>;
  paymentProcessing: boolean;
  setPaymentProcessing: callback1<boolean>;
  amountPortalContainerRef: React.RefObject<HTMLDivElement>;
  paymentTypeMetaData: GeneriPayPaymentIntegration | null;
  setPaymentTypeMetaData: (metadata: GeneriPayPaymentIntegration | null) => void;
};

const nullCheckoutStore: CheckoutStore = {
  paymentSummary: BLANK_PAYMENT_SUMMARY,
  addPaymentBtnVisible: false,
  paymentMethod: undefined,
  setPaymentMethod: () => { },
  selectedPaymentType: null,
  setSelectedPaymentType: () => { },
  paymentProcessing: false,
  setPaymentProcessing: () => { },
  amountPortalContainerRef: { current: null },
  paymentTypeMetaData: null,
  setPaymentTypeMetaData: () => { },
};

export const CheckoutContext = createContext<CheckoutStore>(nullCheckoutStore);

export const CheckoutContextProvider: FC = ({ children }) => {
  const checkout = useSelector((state: State) => state.checkout);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | null>(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const amountPortalContainerRef = useRef<HTMLDivElement>(null);
  const [paymentTypeMetaData, setPaymentTypeMetaData] = useState<GeneriPayPaymentIntegration | null>(null);

  const paymentSummary: PaymentSummary = useMemo(
    () => (checkout.payment ? (getPaymentSummary(checkout.payment.methods) as PaymentSummary) : BLANK_PAYMENT_SUMMARY),
    [checkout.payment]
  );

  const addPaymentBtnVisible = useMemo(
    () => checkout.totalRemaning > 0 && selectedPaymentType !== null,
    [checkout.totalRemaning, selectedPaymentType]
  );

  const checkoutStore: CheckoutStore = {
    paymentSummary,
    addPaymentBtnVisible,
    paymentMethod,
    setPaymentMethod,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentProcessing,
    setPaymentProcessing,
    amountPortalContainerRef,
    paymentTypeMetaData,
    setPaymentTypeMetaData,
  };

  return <CheckoutContext.Provider value={checkoutStore}>{children}</CheckoutContext.Provider>;
};
