import { ComponentType, ReactNode } from 'react';
import { DataMatrixRow } from './util/calculateDataMatrix';

export enum StyleVariant {
  default,
  whiteBackground,
  noWrap,
  middleAlign,
  roundedWithWhiteBackground,
}

export type CustomSortArgs<ItemType> = {
  items: ItemType[];
  sortDirection: 'asc' | 'desc';
};

export type CustomSortType<ItemType> = (customSortArgs: CustomSortArgs<ItemType>) => ItemType[];

export type Column<ItemType extends Record<string, unknown>, FieldType = string, CellProps = {}> = {
  header: ReactNode;
  cellProps?: CellProps;
  Cell?: ComponentType<{ item: ItemType; value: FieldType } & CellProps>;
  field?: keyof ItemType | ((item: ItemType) => FieldType);
  width?: string;
  disableSort?: boolean;
  customSort?: CustomSortType<DataMatrixRow<ItemType>>;
};

export type TableProps<ItemType extends Record<string, unknown>> = {
  onChangeSelected?: (items: ItemType[]) => unknown;
  className?: string;
  columns: Column<ItemType>[];
  selected?: ItemType | ItemType[];
  data: ItemType[];
  pageSize?: number;
  onClick?: (item: ItemType) => unknown;
  styleVariant?: StyleVariant;
  sortColumn?: number;
  sortDirection?: 'asc' | 'desc';
};
