import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints, colors } from 'css/Theme';
import { searchCustomers } from 'api/CustomerApi';
import { CustomerSearchResult } from 'models/Customer';
import { Table, Column, StyleVariant } from 'components/tables';
import { formatPhone } from 'util/helpers/formatPhone';
import { getCustomerDetailsLight, clearCustomerPreviewSelected, loadCollectors } from 'store/actions/CustomerActions';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as UserPlusIcon } from 'assets/icons/user-add.svg';
import { State } from 'store';
import { usePost } from 'util/hooks';
import { setGuestSearchQuery } from 'store/actions/GuestListActions';
import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { SearchBar, Spacer, Toolbar, SearchResults as _SearchResults } from 'components/layout';
import { NavLinkButton } from 'components/buttons';
import { debounce } from 'lodash';
import { errorNotification } from 'store/actions/NotificationsActions';
import { setSidebarExpanded } from 'store/actions/SettingsActions';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';
import { useFlags } from 'launchdarkly-react-client-sdk';

type CustomerSelectedState = [[CustomerSearchResult] | undefined, (value: [CustomerSearchResult] | undefined) => void];

export const CustomersList: FC = () => {
  const dispatch = useDispatch();
  const query = useSelector((state: State) => state.guestList.query);
  const userSettings = useSelector((state: State) => state.settings.userSettings);
  const [selected, setSelected]: CustomerSelectedState = useState();
  const isUseMnEnabled = useIsUseMnEnabled(selected?.[0]);
  const { customerSearchConfig } = userSettings;
  const flags = useFlags();
  const timezoneRollout = flags['posp.timezone-conversion-udf-cleanup.rollout'];

  const columns: Array<Column<CustomerSearchResult>> = [
    {
      header: '',
      disableSort: true,
      Cell: ({ item }) => (
        <Link to={{ pathname: 'edit-customer', search: `?id=${item.Guest_id}` }}>
          <EditIcon />
        </Link>
      ),
      width: '32px',
    },
    { header: 'Name', field: 'Name', Cell: ({ value }) => <CustomerNameSpan>{value}</CustomerNameSpan> },
    {
      header: 'Last Transaction',
      field: 'LastTransaction',
      Cell: ({ value }) => <>{formatDate({ date: value, convertFromEst: timezoneRollout })}</>,
    },
    { header: 'User Type', field: 'PatientType' },
    { header: 'Phone', field: 'PhoneNo', Cell: ({ value }) => <>{formatPhone(value) || value}</> },
    {
      header: 'Email',
      field: 'Email',
      Cell: ({ value }) => (
        <div title={value} style={{ overflowWrap: 'anywhere', paddingRight: '10px' }}>
          {value}
        </div>
      ),
    },
    { header: 'MJ State ID', field: 'MJStateIDNo' },
    {
      header: 'ID Expiration Date',
      field: 'MMJIDExpiration',
      Cell: ({ value }) => <>{formatDate({ date: value })}</>,
    },
    { header: 'DOB', field: 'DOB', Cell: ({ value }) => <>{formatDate({ date: value })}</> },
  ];

  const getConfiguredCols = () => {
    if (customerSearchConfig) {
      const columnsFiltered = columns.filter((x) => {
        const col = customerSearchConfig?.find((y) => y.name.toLowerCase() === x.header);
        return !col?.hidden;
      });
      columnsFiltered.sort((a, b) => {
        const col1 = customerSearchConfig?.find((x) => x.name.toLowerCase() === a.header);
        const col2 = customerSearchConfig?.find((x) => x.name.toLowerCase() === b.header);
        return (col1 ? col1?.order : 0) - (col2 ? col2?.order : 0);
      });
      return columnsFiltered;
    } else {
      return columns;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearCustomerPreviewSelected());
    };
  }, [dispatch]);

  const [searchResults, searchError, searchLoading] = usePost(searchCustomers, { SearchString: query }, [query]);

  const onSearch = debounce((value: string) => {
    if (value.length > 1) {
      dispatch(setGuestSearchQuery(value));
      dispatch(clearCustomerPreviewSelected());
    } else {
      dispatch(errorNotification('Please Provide a Valid Search Term'));
    }
  }, 300);

  return (
    <CustomersListContainer>
      <PageTitle id='customers-page-title'>Guests</PageTitle>
      <Toolbar>
        <SearchBar
          initialValue={query}
          onSearch={onSearch}
          placeholder='Search'
          inputAutomationId='customers-page_search-bar-large_search-customer-input'
        />
        <Spacer />
        <NavLinkButton to='/edit-customer' icon={<UserPlusIcon />} automationId='action-bar_button_new-guest'>
          Create Guest
        </NavLinkButton>
      </Toolbar>
      <SearchResults
        errMessage={searchError}
        isEmpty={!searchResults?.length || !query.length}
        emptyMessage='Search for a customer'
        loading={searchLoading}
      >
        <TableContainer data-testid='customer-list_table_container'>
          <Table
            data={searchResults || []}
            selected={selected}
            columns={getConfiguredCols()}
            onClick={(customer: CustomerSearchResult) => {
              if (isUseMnEnabled) {
                dispatch(loadCollectors({ CustomerId: customer.Guest_id }));
              }
              dispatch(getCustomerDetailsLight({ guestId: customer.Guest_id }));
              dispatch(setSidebarExpanded(true));
              setSelected([customer]);
            }}
            styleVariant={StyleVariant.noWrap}
          />
        </TableContainer>
      </SearchResults>
    </CustomersListContainer>
  );
};

const PageTitle = styled.h1`
  color: ${colors.dutchie.almostBlack};
  padding: 2.5rem 3.75rem 0;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: bold;
  ${breakpoints.smallTablet.maxQuery} {
    padding: 2.5rem 2rem 0;
  }
`;

const SearchResults = styled(_SearchResults)`
  flex: 1 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
`;

//Changed from Auto to Scroll for an HP device inclusion
const TableContainer = styled.div`
  padding-left: 3.75rem;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-y: scroll;

  ${breakpoints.smallTablet.maxQuery} {
    padding-left: 1.75rem;
  }
`;

const CustomerNameSpan = styled.span`
  text-transform: capitalize;
`;

const CustomersListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${PageTitle} {
    padding: 1.5rem 1.5rem 0;
  }
  ${Toolbar} {
    padding: 1.5rem;
  }
  ${TableContainer} {
    padding-left: 1.5rem;
  }
`;
