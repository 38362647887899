import React, { FC } from 'react';
import styled from 'styled-components';
import { SectionGridContainer, SectionHeader, Caption } from 'pages/SettingsPage/SettingPageComponents';
import { State } from 'store';
import { ReactComponent as SuccessIcon } from 'assets/icons/notification-success.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/icon-close.svg';
import { useSelector } from 'react-redux';

export const CustomerDisplaySettings: FC = () => {
  const isCfdConnected = useSelector((state: State) => state.settings.isCfdConnected);

  return (
    <SectionGridContainer>
      <SectionHeader>Customer Facing Display</SectionHeader>
      <StatusContainer>
        {isCfdConnected ? (
          <>
            <GreenCheckmark />
            <Caption>Connected</Caption>
          </>
        ) : (
          <>
            <RedError />
            <Caption>Not connected</Caption>
          </>
        )}
      </StatusContainer>
    </SectionGridContainer>
  );
};

const StatusContainer = styled.div`
  width: 180px;
  height: 180px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  gap: 0.75rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
`;

const GreenCheckmark = styled(SuccessIcon)`
  flex: 1;
  width: 100px;
  fill: green;

  path {
    fill: green;
  }
`;

const RedError = styled(ErrorIcon)`
  flex: 1;
  width: 80px;
  fill: red;

  path {
    fill: red;
  }
`;
