import React from 'react';
import { TextFieldProps } from '@mui/material';

import { SearchIcon } from '../icons/search-icon';
import { InputAdornment } from '../input';
import { SearchInput } from './search-rebrand.styles';

type SearchProps = TextFieldProps & {
  disableClearIcon?: boolean;
  onReset?: () => void;
};

export function Search(props: SearchProps) {
  const { disableClearIcon = false, InputProps, inputProps } = props;

  return (
    <SearchInput
      {...props}
      inputProps={{
        ...inputProps,
        type: disableClearIcon ? undefined : 'search',
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='#788287' />
          </InputAdornment>
        ),
      }}
    />
  );
}
