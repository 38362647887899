import { useReceiptPrinters } from '@dutchie/capacitor-hardware';
import { useFormikContext } from 'formik';

import { ReceiptPrinterSettingsContextData } from '../types';
import { HardwareSettings } from '../../types';

export const useReceiptPrinterSettingsProvider = (): ReceiptPrinterSettingsContextData => {
  const { values: formValues, setFieldValue } = useFormikContext<HardwareSettings>();

  const { receiptPrinters: allPrinters, search } = useReceiptPrinters({
    onDevicesAuthorized: (printers) => selectPrinter(printers[0].id),
  });

  const selectPrinter = (id?: string): void => setFieldValue('receiptPrinterId', id);
  const setAutoPrintReceipts = (value: boolean) => setFieldValue('autoPrintReceipts', value);

  return {
    formValues,
    printers: allPrinters,
    search,
    selectPrinter,
    setAutoPrintReceipts,
  };
};
