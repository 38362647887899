import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from 'assets/icons/icon-trash.svg';
import { colors } from 'css/Theme';
import { PreorderCartItem } from 'pages/CreatePreOrderPage';

import { Button } from 'components/backoffice/button';
import { QuantityPicker } from '../PreCartComponents/QuantityPicker';
import { PriceLoadingAnimation, usePricesLoading } from '../PreCartComponents/PricesLoading';

type ItemPrice = {
  Total: number;
};

type ItemProps = {
  item: PreorderCartItem;
  setCartItems: React.Dispatch<React.SetStateAction<PreorderCartItem[]>>;
  price: ItemPrice;
  automationId?: string;
};

export const Item: FC<ItemProps> = (props) => {
  const { item, setCartItems, price } = props;
  const [quantity, setQuantity] = useState(item.quantity);
  const { pricesLoading } = usePricesLoading();

  useEffect(() => {
    setCartItems((items) => {
      const index = items.findIndex((x) => x === item);
      if (index === -1) {
        return items;
      }
      items[index].quantity = quantity;
      return items.slice();
    });
  }, [item, quantity, setCartItems]);

  const onRemove = useCallback(() => {
    setCartItems((items) => {
      const index = items.findIndex((x) => x === item);
      if (index === -1) {
        return items;
      }

      items.splice(index, 1);
      return items.slice();
    });
  }, [item, setCartItems]);

  return (
    <ItemContainer data-testid={props.automationId}>
      <SidebarItemWrapper>
        <ItemsInfoWrapper>
          <ItemsInfoProduct data-testid='precart_summary_preorder-item_product-name'>
            {item.brandName && <ItemBrandName>{item.brandName}</ItemBrandName>}
            {item.productName}
          </ItemsInfoProduct>
        </ItemsInfoWrapper>
        <ItemsInfoActions>
          <QuantityPicker
            maxQuantity={item.totalAvailable ?? 0}
            initialQuantity={quantity}
            onQuantityChange={setQuantity}
            allowDecimal={item.unitId !== 1}
          />
          <ItemsCost loading={pricesLoading} data-testid='precart_summary_preorder-item_price'>
            ${price.Total.toFixed(2)}
          </ItemsCost>
          <StyledActionButton label={<DeleteIcon />} onClick={onRemove} variant='white' />
        </ItemsInfoActions>
      </SidebarItemWrapper>
    </ItemContainer>
  );
};

const ItemsInfoActions = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`;

const StyledActionButton = styled(Button)`
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  min-width: 0;
  border-radius: 0;

  svg {
    width: 24px;
    height: 22px;
  }

  &:hover {
    background: transparent;
  }

  &:focus {
    border: 0;
    outline: 0;
  }
`;

const ItemsCost = styled.div<{ loading?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
  min-width: 67px;
  text-align: center;

  ${({ loading }) => loading && PriceLoadingAnimation}
`;

const SidebarItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemsInfoProduct = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
`;

const ItemBrandName = styled.div`
  color: ${colors.dutchie.grey50};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.125rem;
`;

const ItemsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.dutchie.primaryWhite};
  padding: 1rem 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
`;
