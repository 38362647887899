import React, { FC, useMemo, SVGProps } from 'react';
import styled, { css } from 'styled-components';

import { Button } from './Button';
import { Loader } from 'components/backoffice/loader';
import { colors } from 'css/Theme';

import type { ButtonProps } from 'components/buttons/types';

type LoadingButtonProps = {
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  loading?: boolean;
  loadingPlacement?: 'left' | 'right';
  hideLabelOnLoading?: boolean;
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: 'button' | 'submit';
} & ButtonProps;

/**
 * A loading button extending the Dutchie Button with loading spinner
 */
export const LoadingButton: FC<LoadingButtonProps> = ({
  icon: Icon,
  loading,
  loadingPlacement = 'left',
  hideLabelOnLoading = false,
  children,
  disabled,
  type = 'button',
  ...props
}) => {
  const loadingSpinnerColor = useMemo(() => {
    let color = 'white';
    if (props.secondary) {
      color = colors.dutchie.gray60;
    } else if (props.tertiary) {
      color = colors.dutchie.gray70;
    }
    return color;
  }, [props.secondary, props.tertiary]);
  const loadingSpinner = <Loader variant='custom' color={loadingSpinnerColor} />;
  const showChildren = (loading && !hideLabelOnLoading) || !loading;

  return (
    <StyledButton disabled={disabled || loading} {...props} type={type}>
      {loading && loadingPlacement === 'left' && loadingSpinner}
      {!loading && Icon && <Icon color={loadingSpinnerColor} />}
      {showChildren && children}
      {loading && loadingPlacement === 'right' && loadingSpinner}
    </StyledButton>
  );
};

type LoadingContentProps = {
  color?: string;
};

/**
 * A generic container with loading spinner and content.
 * Useful for adding to a component-lib component that doesn't have loader
 */
export const LoadingContent: FC<LoadingContentProps> = ({ color = 'white', children }) => {
  return (
    <StyledContainer>
      <Loader variant='custom' color={color} />
      {children}
    </StyledContainer>
  );
};

// Shared styles for Loading Content and Loading Button
const LoadingContainerStyle = css`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  ${LoadingContainerStyle}
`;

const StyledContainer = styled.div`
  ${LoadingContainerStyle}
`;
