import React from 'react';

export const UsernameInputIcon = (): JSX.Element => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 11.3333C7.11745 11.3333 6.10271 11.5676 5.33333 12C4.56396 12.4324 3.79221 13.2461 3.33333 14C3.16924 14.2696 3.09132 14.2522 2.82175 14.0881C2.55217 13.924 2.46665 13.5725 2.63074 13.3029C3.19159 12.3815 3.98012 11.6199 4.92046 11.0915C5.86081 10.563 6.92133 10.2854 8 10.2854C9.07867 10.2854 10.1392 10.563 11.0795 11.0915C12.0199 11.6199 12.8084 12.3815 13.3693 13.3029C13.5333 13.5725 13.4478 13.924 13.1783 14.0881C12.9087 14.2522 12.4974 13.9362 12.3333 13.6667C11.8745 12.9128 11.436 12.4324 10.6667 12C9.89729 11.5676 8.88255 11.3333 8 11.3333Z" fill="#828A8F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315ZM8 1.14286C6.18137 1.14286 4.43723 1.8653 3.15127 3.15127C1.8653 4.43723 1.14286 6.18137 1.14286 8C1.14286 9.81863 1.8653 11.5628 3.15127 12.8487C4.43723 14.1347 6.18137 14.8571 8 14.8571C9.81863 14.8571 11.5628 14.1347 12.8487 12.8487C14.1347 11.5628 14.8571 9.81863 14.8571 8C14.8571 6.18137 14.1347 4.43723 12.8487 3.15127C11.5628 1.8653 9.81863 1.14286 8 1.14286Z" fill="#828A8F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11 6.66667C11 8.32352 9.65685 9.66667 8 9.66667C6.34315 9.66667 5 8.32352 5 6.66667C5 5.00981 6.34315 3.66667 8 3.66667C9.65685 3.66667 11 5.00981 11 6.66667ZM8 8.66667C9.10457 8.66667 10 7.77124 10 6.66667C10 5.5621 9.10457 4.66667 8 4.66667C6.89543 4.66667 6 5.5621 6 6.66667C6 7.77124 6.89543 8.66667 8 8.66667Z" fill="#828A8F"/>
        </svg>
    );
};
