import styled from 'styled-components';
import { breakpoints } from 'css/Theme';

export const Toolbar = styled.div`
  align-items: center;
  display: flex;
  padding: 1.5rem 3.75rem;
  width: 100%;

  ${breakpoints.smallTablet.maxQuery} {
    padding: 1.5rem 1.75rem;
  }

  & > *:not(.secondary) {
    margin: 0 8px;

    ${breakpoints.tallTablet.maxQuery} {
      margin: 0 4px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
