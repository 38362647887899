import { differenceInYears } from 'date-fns';

import { showAnonymousTransactionPopup } from 'store/actions/PopupsActions';
import { useAnonymousTransactionMutation } from 'queries/v2/cart/new-rec-transaction';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useVerifyBirthdateMutation } from 'queries/v2/cart/verify-customer-birthdate';

import type { AnonymousTransactionPayload } from 'queries/v2/cart/new-rec-transaction';

export type CreateTransactionProps = Pick<AnonymousTransactionPayload, 'dob' | 'onSuccess'>;

export type Demographics = Required<
  Pick<AnonymousTransactionPayload, 'customerTypeId' | 'dob' | 'gender' | 'postalCode'>
>;

export const useCreateAnonymousTransaction = () => {
  const dispatch = useAppDispatch();

  const defaultMinorAge = useAppSelector((state) => state.settings.locationSettings?.DefaultMinorAge) ?? 18;
  const isBlockMinorsFromCheckInFFEnabled = useAppSelector((state) => state.settings.features.BlockMinorsFromCheckInFF);
  const isCollectAnonymousDemographicsEnabled = useAppSelector((state) => state.settings.features.CollectAnonymousDemo);

  const { mutateAsync: createAnonymousTransaction, isLoading: isCreatingTransaction } =
    useAnonymousTransactionMutation();
  const { mutateAsync: verifyBirthdate } = useVerifyBirthdateMutation();

  const createTransaction = async (props?: CreateTransactionProps) => {
    if (!isCollectAnonymousDemographicsEnabled) {
      return await createAnonymousTransaction({ dob: props?.dob, onSuccess: props?.onSuccess });
    }

    // Display the popup to collect demographics
    dispatch(showAnonymousTransactionPopup({}));
  };

  const createTransactionWithDemographics = async (demographics: Demographics) => {
    if (isBlockMinorsFromCheckInFFEnabled) {
      const guestDob = new Date(demographics.dob);
      const guestAge = differenceInYears(new Date(), guestDob);

      if (guestAge < defaultMinorAge) {
        throw new Error(`Patient is under the age of ${defaultMinorAge}. You cannot proceed`);
      }

      // If block minors from checkin FF is enabled, perform the same check we do with the
      // verify birthdate popup and throw error if birthdate is invalid
      await verifyBirthdate({ birthdate: guestDob });
    }

    return await createAnonymousTransaction(demographics);
  };

  return {
    createTransaction,
    createTransactionWithDemographics,
    isCollectAnonymousDemographicsEnabled,
    isCreatingTransaction,
  };
};
