import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';

import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { ReactComponent as ElipsisIcon } from 'assets/icons/elipsis-icon.svg';
import { ShortenedText } from '../ShortenedText';
import { SplitHeaderCell } from 'components/tables/Table';
import { transformToOldTransaction } from 'queries/v2/transaction-history/types';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useGetTransactionHistoryQuery } from 'queries/v2/transaction-history/transaction-history';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { warningNotification } from 'store/actions/NotificationsActions';

import type { Column } from 'components/tables';
import type { Transaction } from 'queries/v2/transaction-history/types';
import { useTransactionsPopups } from 'components/TransactionsPopups';
import { getDeliveryImageForShipment } from 'store/actions/DeliveryActions';
import { useLoyaltySettings } from 'pages/CartPage/CartPanels/LoyaltyPoints/useLoyaltySettings';

export const useTransactionHistory = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Global state

  const isBiotrackEnabled = useAppSelector((state) => state.settings.features.UseBioTrack);
  const isCustomerVisitReasonEnabled = useAppSelector((state) => state.settings.features.CustomerVisitReason);
  const isEducationalMaterialsAcceptOnlyEnabled = useAppSelector(
    (state) => state.settings.features.EducationalMaterialsAcceptOnly
  );
  const isEducationalMaterialsAcceptRejectEnabled = useAppSelector(
    (state) => state.settings.features.EducationalMaterialsAcceptReject
  );
  const isJoryEnabled = useAppSelector((state) => state.settings.integrations?.UseJory) === true;

  // Local state

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [maxTableHeight, setMaxTableHeight] = useState<number | undefined>(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [driverImage, setDriverImage] = useState<String | undefined>(undefined);

  // Hooks

  const { previewManifest } = usePrintJob();
  const transactionsPopups = useTransactionsPopups();
  const { loyaltyEnabled } = useLoyaltySettings();

  // Transaction data

  const { guestId } = useTransactionManager();

  const {
    data: transactions,
    isLoading: areTransactionsLoading,
    refetch: reloadTransactions,
  } = useGetTransactionHistoryQuery(
    { guestId },
    {
      select: (transactions) =>
        transactions.filter((transaction) =>
          searchValue ? transaction.shipmentId.toString().includes(searchValue) : true
        ),
    }
  );

  // Columns

  const showVerificationImage = async (args: { ShipmentId: number }) => {
    const image = await getDeliveryImageForShipment({ ShipmentId: args.ShipmentId });
    if (image) {
      setDriverImage(image);
    } else {
      dispatch(warningNotification('No image available'));
    }
  };

  const voidTransaction = (transaction: Transaction) => {
    if (transaction.shipmentStatus.includes('Voided') || transaction.shipmentStatus.includes('Canceled')) {
      dispatch(warningNotification('Transaction has already been voided'));
      return;
    }

    transactionsPopups.showVoidTransactionPopup(transaction.shipmentId ?? 0, reloadTransactions);
  };

  const tableColumns: Column<Transaction>[] = [
    {
      header: '',
      disableSort: true,
      Cell: ({ item }) => (
        <OverflowMenu
          anchor={<ElipsisIcon />}
          anchorProps={{
            padding: '.5rem 0',
            showCursor: true,
          }}
          menuProps={{
            fontSize: '1rem',
            textAlign: 'center',
          }}
          automationId={'transaction-list_context_menu'}
          menuOptions={[
            {
              text: 'Details',
              onClick: () => history.push(`/transactions?transactionId=${item.shipmentId}`),
            },
            {
              text: 'Activity',
              onClick: () => {
                transactionsPopups.showTransactionPopup(transformToOldTransaction(item));
              },
            },
            {
              text: 'Void',
              onClick: () => {
                if (item.isReturn) {
                  dispatch(warningNotification('Return Transaction cannot be voided.'));
                  return;
                } else if (item.hasReturn) {
                  dispatch(warningNotification('Transaction contains a returned item and cannot be voided.'));
                  return;
                }
                voidTransaction(item);
              },
              disabled: !!item.isCanceled,
            },
            {
              text: 'Payment',
              onClick: () => {
                if (item.isVoided) {
                  dispatch(warningNotification('Payment Type cannot be changed for a Voided Transaction.'));
                } else if (item.hasAdjustment) {
                  dispatch(
                    warningNotification(
                      'Payment Type cannot be changed because another Change Payment adjustment has been made for this transaction.  Please void the other adjustment first.'
                    )
                  );
                } else {
                  transactionsPopups.showChangePaymentTypePopup(transformToOldTransaction(item));
                }
              },
              disabled: !!item.isCanceled,
            },
            {
              text: 'Download manifest',
              onClick: () => {
                previewManifest({ PosId: item.shipmentId });
              },
              disabled: !item.manifestId,
            },
            {
              text: 'View photo',
              onClick: () => {
                showVerificationImage({ ShipmentId: item.shipmentId });
              },
              disabled: !item.manifestId,
            },
          ]}
        />
      ),
    },
    { header: 'Receipt No', field: 'shipmentId', Cell: ({ value }) => <ShortenedText text={value} /> },
    {
      header: 'Date',
      field: 'shipmentDate',
      Cell: ({ item, value }) => {
        const date = item.isCanceled && item.cancelDate ? item.cancelDate : value;
        return <>{formatDate({ date })}</>;
      },
    },
    { header: 'Order Source', field: 'orderSource' },
    { header: 'Order Type', field: 'orderType' },
    { header: 'Total Items', field: 'totalItems' },
    { header: 'Total Cost', field: 'productCost', Cell: ({ value }) => <ShortenedText text={value} /> },
    { header: 'Discount', field: 'discountAmount' },
    {
      header: <SplitHeaderCell line1='Fees/Donations' line2='(Revenue)' />,
      field: 'revenueCashValue',
      Cell: ({ value }) => <ShortenedText text={Math.abs(Number(value)).toString()} />,
    },
    {
      header: <SplitHeaderCell line1='Fees/Donations' line2='(Non-Revenue)' />,
      field: 'nonRevenueCashValue',
      Cell: ({ value }) => <ShortenedText text={Math.abs(Number(value)).toString()} />,
    },
    { header: 'Tax', field: 'tax', Cell: ({ value }) => <ShortenedText text={value} /> },
    ...(isJoryEnabled ? ([{ header: 'Tip', field: 'tipAmount' }] as const) : []),
    { header: 'Total Invoice', field: 'totalInvoice', Cell: ({ value }) => <ShortenedText text={value} /> },
    { header: 'Payment', field: 'paymentType' },
    { header: 'Customer Type', field: 'customerType' },
    {
      header: 'Status',
      field: 'shipmentStatus',
      Cell: ({ item, value }) => <ShortenedText text={item.isCanceled ? 'Canceled' : value} />,
    },
    { header: 'Register', field: 'registerName' },
    { header: 'Sold By', field: 'transactionBy' },
    { header: 'Location', field: 'locationName' },
    ...(isCustomerVisitReasonEnabled
      ? ([
          {
            header: 'Visit Reason',
            field: 'visitReason',
          },
        ] as const)
      : []),
    ...(loyaltyEnabled
      ? ([
          {
            header: 'Loyalty Used',
            field: 'loyaltyUsed',
            Cell: ({ value }) => <ShortenedText text={Number(value).toFixed(2).toString()} />,
          },
          {
            header: 'Loyalty Earned',
            field: 'loyaltyEarned',
            Cell: ({ value }) => <ShortenedText text={Number(value).toFixed(2).toString()} />,
          },
        ] as Column<Transaction>[])
      : []),
    ...(isBiotrackEnabled
      ? ([
          {
            header: 'BioTrack Start',
            field: 'biotrackStartAvail',
            Cell: ({ value }) => <ShortenedText text={Number(value).toFixed(2).toString()} />,
          },
          {
            header: 'BioTrack Used',
            field: 'biotrackUsed',
            Cell: ({ value }) => <ShortenedText text={Number(value).toFixed(2).toString()} />,
          },
          {
            header: 'BioTrack End',
            field: 'biotrackEndAvail',
            Cell: ({ value }) => <ShortenedText text={Number(value).toFixed(2).toString()} />,
          },
        ] as Column<Transaction>[])
      : []),
    ...(isEducationalMaterialsAcceptRejectEnabled || isEducationalMaterialsAcceptOnlyEnabled
      ? ([
          {
            header: 'Educational Materials',
            field: 'wereEducationalMaterialsAccepted',
            Cell: ({ value }) => (!value ? null : value ? 'Accepted' : 'Rejected'),
          },
        ] as Column<Transaction>[])
      : []),
  ];

  // Handlers

  const handleChangeSearch = useCallback(
    debounce((value: string) => {
      setSearchValue(value.trim());
    }, 500),
    []
  );

  const handleSearch = (value: string) => {
    setSearchValue(value.trim());
  };

  const handleClosePhoto = () => {
    setDriverImage(undefined);
  };

  // Effects

  useEffect(() => {
    const adjustTableMaxHeight = () => {
      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        const topOfTable = tableContainer.getBoundingClientRect().y; // The top of the table container
        const windowHeight = window.innerHeight; // The full window height
        const bottomPadding = 32; // The container's bottom padding
        const newMaxHeight = windowHeight - bottomPadding - topOfTable;
        setMaxTableHeight(newMaxHeight);
        return;
      }
      setMaxTableHeight(undefined);
    };
    adjustTableMaxHeight();

    window.addEventListener('resize', adjustTableMaxHeight);
    return () => window.removeEventListener('resize', adjustTableMaxHeight);
  });

  return {
    areTransactionsLoading,
    driverImage,
    handleChangeSearch,
    handleClosePhoto,
    handleSearch,
    maxTableHeight,
    searchValue,
    tableColumns,
    tableContainerRef,
    transactions,
  };
};
