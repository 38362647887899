import React from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { LoadingButton } from 'components/buttons';
import { ModalCloseButton, ModalContainer, ModalVariation } from 'components/modals/Modal';
import { PopupOverlay } from 'components/popups';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 0 1.5rem;
`;

export const Title = styled.h3`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  flex: 1;
  padding: 2rem 0;
  margin: 0;
  margin-left: 3.75rem;
  text-align: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 2rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;

export { ModalCloseButton, ModalContainer, ModalVariation };

/**
 * This is a temporary, shared component that will be merged into the main modal once
 * both the anonymous cart LD flag and the new demographics modal LD flag are rolled out
 */
export const NewAnonymousDemographicsModal = ({
  children,
  handleCancel,
  handleSubmit,
  isSubmitDisabled,
  isSubmitting,
}: React.PropsWithChildren<{
  handleCancel: () => void;
  handleSubmit: () => void;
  isSubmitDisabled?: boolean;
  isSubmitting?: boolean;
}>) => {
  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer variation={ModalVariation.Standard}>
        <ModalHeader>
          <Title>New anonymous guest</Title>
          <ModalCloseButton onClick={handleCancel} data-testid='popup_popup-body-div_close-icon' />
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          <LoadingButton cta tertiary onClick={handleCancel}>
            Cancel
          </LoadingButton>
          <LoadingButton
            cta
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitDisabled}
            automationId='confirmation-popup_confirm-button_confirm'
          >
            Save
          </LoadingButton>
        </ModalFooter>
      </ModalContainer>
    </PopupOverlay>
  );
};
