import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const GiftCardIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <path
            d='M10 17.5H4C2.89543 17.5 2 16.6046 2 15.5V3.5C2 2.39543 2.89543 1.5 4 1.5H20C21.1046 1.5 22 2.39543 22 3.5V8.5'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <line x1='2' y1='6.25' x2='22' y2='6.25' stroke='#828A8F' stroke-width='1.5' />
          <path d='M5 10.5H11' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' />
          <path d='M5 14H9' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' />
          <mask id='path-5-inside-1_815_70173' fill='white'>
            <rect x='12.5' y='13' width='10' height='10' rx='1' />
          </mask>
          <rect
            x='12.5'
            y='13'
            width='10'
            height='10'
            rx='1'
            stroke='#828A8F'
            stroke-width='3'
            mask='url(#path-5-inside-1_815_70173)'
          />
          <path
            d='M14.5 10.5L17.5 14L20.5 10.5'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path d='M12.5 18L22.5 18' stroke='#828A8F' stroke-width='1.5' />
          <path d='M17.5 13L17.5 23' stroke='#828A8F' stroke-width='1.5' />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <path d='M22 6.25H2V3.5C2 2.39543 2.89543 1.5 4 1.5H20C21.1046 1.5 22 2.39543 22 3.5V6.25Z' fill='#FAC7F0' />
        <path
          d='M10 17.5H4C2.89543 17.5 2 16.6046 2 15.5V3.5C2 2.39543 2.89543 1.5 4 1.5H20C21.1046 1.5 22 2.39543 22 3.5V8.5'
          stroke='#DE48EF'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <line x1='2' y1='6.25' x2='22' y2='6.25' stroke='#DE48EF' stroke-width='1.5' />
        <path d='M5 10.5H11' stroke='#DE48EF' stroke-width='1.5' stroke-linecap='round' />
        <path d='M5 14H9' stroke='#DE48EF' stroke-width='1.5' stroke-linecap='round' />
        <rect x='12.5' y='13' width='10' height='10' rx='1' fill='#FAC7F0' />
        <mask id='path-7-inside-1_815_70236' fill='white'>
          <rect x='12.5' y='13' width='10' height='10' rx='1' />
        </mask>
        <rect
          x='12.5'
          y='13'
          width='10'
          height='10'
          rx='1'
          stroke='#DE48EF'
          stroke-width='3'
          mask='url(#path-7-inside-1_815_70236)'
        />
        <path
          d='M14.5 10.5L17.5 14L20.5 10.5'
          stroke='#DE48EF'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path d='M12.5 18L22.5 18' stroke='#DE48EF' stroke-width='1.5' />
        <path d='M17.5 13L17.5 23' stroke='#DE48EF' stroke-width='1.5' />
      </g>
    </svg>
  );
};
