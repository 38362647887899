// Customer Caregiver Types

export type MMURCaregiverServerResponse = {
  CaregiverId: number;
  CaregiverName: string;
};

export type Caregiver = ReturnType<typeof transformToCaregiver>;

export const transformToCaregiver = (mmurCaregiver: MMURCaregiverServerResponse) => {
  return {
    caregiverId: mmurCaregiver.CaregiverId,
    caregiverName: mmurCaregiver.CaregiverName,
    caregiverMJStateIdNo: `MMURCaregiverId: ${mmurCaregiver.CaregiverId}`,
  };
};
