import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { Column } from 'components/layout';
import { SetupTitle } from './styled';

export const SetupInstructions = ({
  steps,
  title,
  listStyles,
}: {
  steps: string[] | JSX.Element[];
  title?: string;
  listStyles?: FlattenSimpleInterpolation;
}) => {
  return (
    <Column gap={8}>
      <SetupTitle>{title ?? 'Instructions'}</SetupTitle>
      <StyledList styles={listStyles}>
        {steps.map((step, idx) => (
          <li key={`instruction_${idx}`}>{step}</li>
        ))}
      </StyledList>
    </Column>
  );
};

const StyledList = styled.ol<{ styles?: FlattenSimpleInterpolation }>`
  padding-left: 1.5rem;
  ${({ styles }) => styles}
`;
