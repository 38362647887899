import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PrintJob, PrintPreviewJob } from 'models/Printing';
import { resetPrintJobStatus as resetPrintJobStatusNew, resetPrintPreviewStatus } from 'store/actions/PrintJobsActions';
import { resetPrintJobStatus as resetPrintJobStatusOld } from 'store/actions/PrintActions';

import type { State } from 'store';
import { useHardwareLibrary } from '../launch-darkly/useHardwareLibrary';

export const usePrintJobStatus = () => {
  const dispatch = useDispatch();
  const isHardwareLibraryActive = useHardwareLibrary();

  const { printJobStatus, printPreviewStatus } = useSelector((state: State) => state.printJobs);
  const { printCartStatus, printLabelsStatus, printPickTicketStatus, printReceiptStatus } = useSelector(
    (state: State) => state.print
  );

  const resetPrintJobStatuses = useCallback(() => {
    if (isHardwareLibraryActive) {
      dispatch(resetPrintJobStatusNew());
      dispatch(resetPrintPreviewStatus());
    } else {
      dispatch(resetPrintJobStatusOld());
    }
  }, [dispatch, isHardwareLibraryActive]);

  return {
    printCartStatus: isHardwareLibraryActive ? printJobStatus[PrintJob.CART] : printCartStatus,
    printLabelsStatus: isHardwareLibraryActive ? printJobStatus[PrintJob.LABELS] : printLabelsStatus,
    printPickTicketStatus: isHardwareLibraryActive ? printJobStatus[PrintJob.PICK_TICKET] : printPickTicketStatus,
    printReceiptStatus: isHardwareLibraryActive ? printJobStatus[PrintJob.RECEIPT] : printReceiptStatus,
    previewLabelsStatus: isHardwareLibraryActive ? printPreviewStatus[PrintPreviewJob.LABELS] : undefined,
    previewManifestStatus: isHardwareLibraryActive ? printPreviewStatus[PrintPreviewJob.MANIFEST] : undefined,
    resetPrintJobStatuses,
  };
};
