import { AlertBanner, AlertBannerStyles } from 'components/misc/AlertBanner';
import { ReactComponent as ErrorIcon } from 'assets/icons/icon-error.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/icon-warning.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/icon-success.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/icon-info.svg';

import React, { FC } from 'react';

export type PaymentsHubAlertProps = {
  message: string;
  messageStyle: string;
};

export const PaymentsHubAlert: FC<PaymentsHubAlertProps> = ({ message, messageStyle }) => {
  let alertBannerStyle = AlertBannerStyles.default;
  let alertBannerIcon = <InfoIcon />;
  switch (messageStyle) {
    case 'error':
      alertBannerStyle = AlertBannerStyles.error;
      alertBannerIcon = <ErrorIcon />;
      break;
    case 'info':
      alertBannerStyle = AlertBannerStyles.info;
      alertBannerIcon = <InfoIcon />;
      break;
    case 'success':
      alertBannerStyle = AlertBannerStyles.success;
      alertBannerIcon = <SuccessIcon />;
      break;
    case 'warning':
      alertBannerStyle = AlertBannerStyles.warning;
      alertBannerIcon = <WarningIcon />;
      break;
    default:
      alertBannerStyle = AlertBannerStyles.default;
      alertBannerIcon = <InfoIcon />;
  }
  return (
    <div data-testid='payments-hub_alert'>
      <AlertBanner style={alertBannerStyle} icon={alertBannerIcon} text={message} />
    </div>
  );
};
