import React from 'react';

export function NewCustomerIcon() {
  return (
    <svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.05' cx='48' cy='48' r='48' fill='#0555AE' />
      <ellipse opacity='0.1' cx='48.142' cy='48.142' rx='39.0532' ry='39.3373' fill='#0555AE' />
      <circle opacity='0.1' cx='48.1421' cy='48.1424' r='31.3846' fill='#0555AE' />
      <path
        d='M40.6012 62.8563C36.2972 61.142 35.8675 57.2906 36.2217 54.0952C36.4663 51.878 38.304 50.4746 40.4846 50.4746C42.5303 50.4746 46.6743 50.4838 46.6743 50.4838C48.2377 50.6049 49.3715 52.0289 49.2229 53.5672C48.9943 55.9352 46.1966 56.1912 44.3155 56.6666C45.7577 57.3295 48.5897 59.1695 48.3749 61.1923'
        stroke='#083A7E'
        stroke-width='1.71429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M55.4583 62.8569L56.4938 61.8214C57.3431 60.9724 58.0168 59.9643 58.4765 58.8548C58.9361 57.7453 59.1727 56.5561 59.1726 55.3552V41.1906C59.1726 39.4809 57.7852 38.0957 56.0778 38.0957C54.368 38.0957 52.9806 39.4809 52.9806 41.1906V47.9997C49.2892 46.3403 48.3772 46.1757 44.3155 46.7609V36.2374C44.3155 34.5277 42.9303 33.1426 41.2206 33.1426C39.5109 33.1426 38.1235 34.5277 38.1235 36.2374V51.0443'
        stroke='#083A7E'
        stroke-width='1.71429'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
