import { Peripheral, PeripheralService, PeripheralServiceConfig, PeripheralType } from "@dutchie/capacitor-hardware";

import type { MockedAddPeripheralEvent, MockedEvent, MockedPeripheralIdEvent } from '../mock/types';

type MockedServiceConfig = {
  isSupported?: () => boolean;
};

type MockedPeripheralServiceConfig = {
  mockConfig?: Partial<MockedServiceConfig>;
  defaultConfig: PeripheralServiceConfig;
  userConfig?: Partial<PeripheralServiceConfig>;
};

export abstract class MockedPeripheralService<T extends Peripheral> extends PeripheralService<T> {
  private mockConfig?: MockedServiceConfig;

  protected devices: T[] = [];

  constructor(peripheralType: PeripheralType, config: MockedPeripheralServiceConfig) {
    super(peripheralType, config.defaultConfig, config.userConfig);
    this.mockConfig = config.mockConfig;

    window.addEventListener(this.eventName('add-device'), this.handleAddDeviceEvent);
    window.addEventListener(this.eventName('invalidate-service'), this.handleInvalidateServiceEvent);
    window.addEventListener(this.eventName('remove-device'), this.handleRemoveDeviceEvent);
  }

  get isSupported(): boolean {
    return this.mockConfig?.isSupported ? this.mockConfig.isSupported() : true;
  }

  abstract createDevice(id: string, name: string): T;

  protected getById(id: string): T | undefined {
    return this.devices.find((device) => device.id === id);
  }

  protected eventName(event: string): string {
    return `${this.name}.${event}`;
  }

  protected notifyReceived(action: string, event: MockedEvent<unknown>): void {
    const eventName = `${this.name}.${action}.received`;
    const customEvent = new CustomEvent(eventName, { detail: { eventId: event.eventId } });
    window.dispatchEvent(customEvent);
  }

  private getMockedEvent<T>(event: Event): MockedEvent<T> {
    return (event as CustomEvent).detail as MockedEvent<T>;
  }

  private handleAddDeviceEvent = (event: Event) => {
    const evt = this.getMockedEvent<MockedAddPeripheralEvent>(event);
    const detail = evt.data;

    const existing = this.devices.find((device) => device.id === detail.id);
    if (!existing) {
      this.devices.push(this.createDevice(detail.id, detail.name));
    }

    this.notifyReceived('add-device', evt);
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleInvalidateServiceEvent = (event: Event) => {
    // Causes library to re-fetch and emit device changes
    this.invalidate();
    this.notifyReceived('invalidate-service', this.getMockedEvent(event));
    return false;
  };

  private handleRemoveDeviceEvent = (event: Event) => {
    const evt = this.getMockedEvent<MockedPeripheralIdEvent>(event);
    const detail = evt.data;
    const index = this.devices.findIndex((device) => device.id === detail.id);
    if (index > -1) {
      this.devices.splice(index, 1);
    }

    this.notifyReceived('remove-device', evt);
    return false;
  };

  protected async availableDevices(): Promise<T[]> {
    return this.devices;
  }

  dispose(): void {
    super.dispose();
    window.removeEventListener(this.eventName('add-device'), this.handleAddDeviceEvent);
    window.removeEventListener(this.eventName('invalidate-service'), this.handleInvalidateServiceEvent);
    window.removeEventListener(this.eventName('remove-device'), this.handleRemoveDeviceEvent);
  }
}
