import { getLDClient } from 'util/launchDarkly';

import { useAppSelector } from '../useAppDispatch';

type GetIsDaysSupplyEnabledProps = {
  isDaysSupplyFFEnabled: boolean;
  isMedicalCustomer: boolean;
};

export const getIsDaysSupplyFeatureEnabled = ({
  isDaysSupplyFFEnabled,
  isMedicalCustomer,
}: GetIsDaysSupplyEnabledProps) => {
  const ldClient = getLDClient();
  const isDaysSupplyFixEnabled = ldClient?.variation('pos.register.restrict-days-supply-to-med', false) === true;

  if (isDaysSupplyFixEnabled) {
    return isDaysSupplyFFEnabled && isMedicalCustomer;
  } else {
    return isDaysSupplyFFEnabled;
  }
};

export const useIsDaysSupplyFeatureEnabled = () => {
  const isMedicalCustomer = useAppSelector((state) => state.customer.details?.IsMedical) ?? false;
  const isDaysSupplyFFEnabled = useAppSelector((state) => state.settings.features.ShowDaysSupplyCalculator);

  return getIsDaysSupplyFeatureEnabled({ isDaysSupplyFFEnabled, isMedicalCustomer });
};
