import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { cancelCheckout, finishCheckout } from 'store/actions/CheckoutActions';
import { logout } from 'store/actions/UserActions';
import { getProductId } from 'util/helpers/products/getProductId';
import {
  removeAppliedDiscount,
  applyDiscount,
  startScan,
  endScan,
  setCartItemsLoading,
  removeAllAppliedDiscounts,
} from 'store/actions/CartItemsActions';

export type CartItemsState = {
  loading: Record<string, boolean | false>;
  error: string | null;
  success: boolean | null;
  scanning: boolean;
};

const initialState: CartItemsState = {
  loading: {},
  error: null,
  success: null,
  scanning: false,
};

export const cartItemsSlice = createSlice({
  name: 'cartItems',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CartItemsState>) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(cancelCheckout, (state: CartItemsState) => {
      state.loading = {};
    });
    builder.addCase(finishCheckout, (state: CartItemsState) => {
      state.loading = {};
    });
    builder.addCase(startScan, (state: CartItemsState) => {
      state.scanning = true;
    });
    builder.addCase(endScan, (state: CartItemsState) => {
      state.scanning = false;
    });
    builder.addCase(setCartItemsLoading, (state: CartItemsState, action) => {
      const { productId, isLoading } = action.payload;
      state.loading = { ...state.loading, [productId]: isLoading };
    });
    builder.addCase(applyDiscount.pending, (state: CartItemsState, action) => {
      const cartItemId = getProductId(action.meta.arg);
      state.loading = { ...state.loading, [cartItemId]: true };
    });
    builder.addCase(applyDiscount.fulfilled, (state: CartItemsState, action) => {
      const cartItemId = getProductId(action.meta.arg);
      state.loading = { ...state.loading, [cartItemId]: false };
    });
    builder.addCase(applyDiscount.rejected, (state: CartItemsState, action) => {
      const cartItemId = getProductId(action.meta.arg);
      state.loading = { ...state.loading, [cartItemId]: false };
    });
    builder.addCase(removeAppliedDiscount.pending, (state: CartItemsState, action) => {
      if (action.meta.arg.SerialNo && action.meta.arg.ProductId) {
        const cartItemId = getProductId(action.meta.arg);
        state.loading = { ...state.loading, [cartItemId]: true };
      }
    });
    builder.addCase(removeAppliedDiscount.fulfilled, (state: CartItemsState, action) => {
      if (action.meta.arg.SerialNo && action.meta.arg.ProductId) {
        const cartItemId = getProductId(action.meta.arg);
        state.loading = { ...state.loading, [cartItemId]: false };
      }
    });
    builder.addCase(removeAppliedDiscount.rejected, (state: CartItemsState, action) => {
      if (action.meta.arg.SerialNo && action.meta.arg.ProductId) {
        const cartItemId = getProductId(action.meta.arg);
        state.loading = { ...state.loading, [cartItemId]: false };
      }
    });
    builder.addCase(removeAllAppliedDiscounts.pending, (state: CartItemsState, action) => {
      if (action.meta.arg.SerialNo && action.meta.arg.ProductId) {
        const cartItemId = getProductId(action.meta.arg);
        state.loading = { ...state.loading, [cartItemId]: true };
      }
    });
    builder.addCase(removeAllAppliedDiscounts.fulfilled, (state: CartItemsState, action) => {
      if (action.meta.arg.SerialNo && action.meta.arg.ProductId) {
        const cartItemId = getProductId(action.meta.arg);
        state.loading = { ...state.loading, [cartItemId]: false };
      }
    });
    builder.addCase(removeAllAppliedDiscounts.rejected, (state: CartItemsState, action) => {
      if (action.meta.arg.SerialNo && action.meta.arg.ProductId) {
        const cartItemId = getProductId(action.meta.arg);
        state.loading = { ...state.loading, [cartItemId]: false };
      }
    });
  },
});
