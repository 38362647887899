import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { CustomerDetails, EditAllotmentTypeEnum } from 'models/Customer';
import { clearAllotmentOverride } from 'store/actions/CustomerActions';

type ClearAllotmentOverridePopupProps = {
  isVisible: boolean;
  hide: () => void;
  customer: CustomerDetails;
  type: EditAllotmentTypeEnum;
};

export const ClearAllotmentOverridePopup: FC<ClearAllotmentOverridePopupProps> = ({
  customer,
  type,
  isVisible,
  hide,
}) => {
  const dispatch = useDispatch();
  const clearOverride = async () => {
    await dispatch(clearAllotmentOverride({ PatientId: customer.Guest_id, type }));
    hide();
  };

  return (
    <ConfirmationPopup
      small
      isVisible={isVisible}
      hide={hide}
      title={`Remove`}
      confirm={{
        text: 'Confirm',
        onClick: clearOverride,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <Message>Are you sure you want to remove {type.toLowerCase()} allotment override?</Message>
    </ConfirmationPopup>
  );
};

const Message = styled.p`
  text-align: center;
  padding: 0.75rem 1rem;
`;
