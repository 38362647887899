import { createSlice } from '@reduxjs/toolkit';
import { differenceInMilliseconds, format } from 'date-fns';

import { addCountMetric, addDurationMetric, addTimestampMetric } from 'store/actions/MetricsActions';
import { logger } from 'util/logger';

export enum MetricType {
  COUNT = 'count',
  DURATION = 'duration',
  TIMESTAMP = 'timestamp',
}

export enum DurationUnit {
  MILLISECONDS = 'milliseconds',
  SECONDS = 'seconds',
  MINUTES = 'minutes',
}

type BaseMetric = {
  key: string;
  type: MetricType;
  logData?: Record<string, unknown>;
};

export type CountMetric = BaseMetric & {
  type: MetricType.COUNT;
  /** Integer count */
  value: number;
  /** Optional unit label (plural form) to describe the count */
  unit?: string;
};

export type DurationMetric = BaseMetric & {
  type: MetricType.DURATION;
  /** The timestamp key to calculate duration */
  timestampKey: string;
  duration?: number;
  /** Defaults to milliseconds */
  unit?: DurationUnit;
};

export type TimestampMetric = BaseMetric & {
  type: MetricType.TIMESTAMP;
  timestamp?: Date;
};

export type SavedMetric = CountMetric | TimestampMetric;

export type Metric = CountMetric | DurationMetric | TimestampMetric;

export type MetricsState = {
  metrics: Record<string, SavedMetric>;
};

const initialState: MetricsState = {
  metrics: {},
};

const isCountMetric = (metric: Metric): metric is CountMetric => (metric as CountMetric).value !== undefined;
const isTimestampMetric = (metric: Metric): metric is TimestampMetric =>
  (metric as TimestampMetric).timestamp !== undefined;

type MetricsAction<P extends Metric> = {
  payload: P;
  type: string;
};

export const handleCountMetricAction = (state: MetricsState, action: MetricsAction<CountMetric>): MetricsState => {
  const { key } = action.payload;
  const countLabel = action.payload.unit ?? 'count';
  const foundMetric = state.metrics[key];
  if (foundMetric && isCountMetric(foundMetric)) {
    // If the found metric is a count, increment it, resave and log the new total
    foundMetric.value += action.payload.value;
    state.metrics[key] = foundMetric;
    logger.debug(`[COUNT] incremented ${countLabel} by ${action.payload.value}: ${key}`, {
      ...action.payload,
      newTotal: foundMetric.value,
    });
  } else if (!foundMetric) {
    // If the metric doesn't exist, save it and log it
    state.metrics[key] = action.payload;
    logger.debug(`[COUNT] set ${countLabel} to ${action.payload.value}: ${key}`, {
      ...action.payload,
      newTotal: action.payload.value,
    });
  }

  return state;
};

export const handleDurationMetricAction = (
  state: MetricsState,
  action: MetricsAction<DurationMetric>,
  onCalculateDuration?: (duration: number) => void
): MetricsState => {
  const { key } = action.payload;
  const { timestampKey } = action.payload;
  const timestampMetric = state.metrics[timestampKey];
  if (timestampMetric && isTimestampMetric(timestampMetric)) {
    if (timestampMetric.timestamp) {
      // Calculate the duration and log it
      const unit = action.payload.unit ?? DurationUnit.MILLISECONDS;
      const durationInMilliseconds = differenceInMilliseconds(new Date(), timestampMetric.timestamp);

      let duration = durationInMilliseconds;
      let durationLabel = 'ms';
      switch (unit) {
        case DurationUnit.MINUTES:
          duration = durationInMilliseconds / 1000 / 60;
          durationLabel = 'min';
          break;
        case DurationUnit.SECONDS:
          duration = durationInMilliseconds / 1000;
          durationLabel = 's';
          break;
        case DurationUnit.MILLISECONDS:
        default:
          // Do nothing and use the default values
          break;
      }
      onCalculateDuration?.(duration);
      logger.debug(`[DURATION] ${duration}${durationLabel}: ${key}`, {
        ...action.payload,
        duration: action.payload.duration ?? duration,
        unit,
      });
      // Delete the timestamp to prevent the duration from logging twice
      delete state.metrics[timestampKey];
    }
  }
  return state;
};

export const handleTimestampMetricAction = (
  state: MetricsState,
  action: MetricsAction<TimestampMetric>
): MetricsState => {
  const { key } = action.payload;
  // If a timestamp is provided, use that, otherwise use the current time
  const timestamp = action.payload.timestamp ?? new Date();
  // Save the metric in case we need to calculate a duration
  state.metrics[key] = { ...action.payload, timestamp };
  // Log to DataDog
  logger.debug(`[TIMESTAMP] ${format(timestamp, 'yyyy-MM-dd hh:mm:ssa')}: ${key}`, action.payload);
  return state;
};

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCountMetric, handleCountMetricAction);
    builder.addCase(addDurationMetric, handleDurationMetricAction);
    builder.addCase(addTimestampMetric, handleTimestampMetricAction);
  },
});
