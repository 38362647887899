import _ from 'lodash';
import { createSlice, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Cart, CartItem, CartTotal } from 'models/Cart';
import { ElectronicPaymentResponse, PollingElectronicPaymentResponse } from 'models/Checkout';
import { logout } from 'store/actions/UserActions';
import { cancelCheckout, finishCheckout } from '../actions/CheckoutActions';
import {
  setCartTotalsLoading,
  setCheckedInGuest,
  clearCheckedInGuest,
  loadCart,
  clearCart,
  clearPreOrder,
  applyLoyaltyPoints,
  showProductHistory,
  removeItemFromPreOrder,
  setDaysSupplyInPeriod,
  updateElectronicPaymentDetails,
  calcCartTotal,
  setCartTotalsFromRQ,
  returnItem,
  hideDiscountPanel,
  showDiscountPanel,
  showLoyaltyPanel,
  hideLoyaltyPanel,
  isDutchiePayError,
  setPreauthAmount,
  setSelectedPreOrderQty,
  resetCart,
  returnItemV2,
  returnItemComplete,
  setDutchiePayPreAuthError,
  showPrescriptionPanel,
  hidePrescriptionPanel,
  setIsFalseNegativePinDebitError,
  setBlockScans,
  setCartFromRQ,
  setCartLoading,
  clearCartFromRQ,
  setIsCartInitialized,
  openCustomerSelectionPanel,
  closeCustomerSelectionPanel,
  openCustomerHistoryPanel,
  closeCustomerHistoryPanel,
} from 'store/actions/CartActions';
import { CheckedInGuest } from 'models/Guest';
import { getProductId } from 'util/helpers/products/getProductId';
import { CustomerHistoryPanelTabs } from 'components/cart/CustomerHistoryPanel/CustomerHistoryPanel';

export type CartState = {
  isInitialized: boolean;
  /** @deprecated This only needed until we switch to React Query for load cart */
  areCartTotalsLoading: boolean;
  /** @deprecated This only needed until we switch to React Query for load cart */
  isLoadCartError: boolean;
  /** @deprecated This only needed until we switch to React Query for load cart */
  checkedInGuest: Partial<CheckedInGuest> | undefined;
  isCheckedInWithCaregiver: boolean;
  totalSelectedPreOrderQty: number | undefined;
  returningItem: boolean;
  /** @deprecated This only needed until we switch to React Query for load cart */
  loading: boolean;
  /** @deprecated This only needed until we switch to React Query for load cart */
  itemsById: Record<string, CartItem>;
  /** @deprecated This only needed until we switch to React Query for load cart */
  details: Cart;
  productHistoryShown: boolean;
  showDiscounts: boolean;
  showLoyalty: boolean;
  showPrescription: boolean;
  preOrderIdsToOmit: Array<Number>;
  daysInPeriod: number;
  StatefulCheckoutTotalCalculated: boolean;
  isDutchiePayError: boolean;
  dutchiePayPreAuthError: boolean;
  isFalseNegativePinDebitError: boolean;
  blockScans: boolean;
  isCustomerSelectionPanelOpen: boolean;
  isCustomerHistoryPanelOpen: boolean;
  selectedCustomerHistoryPanelTab: CustomerHistoryPanelTabs;
};

const initialState: CartState = {
  isInitialized: false,
  areCartTotalsLoading: false,
  isLoadCartError: false,
  checkedInGuest: undefined,
  isCheckedInWithCaregiver: false,
  totalSelectedPreOrderQty: undefined,
  StatefulCheckoutTotalCalculated: false,
  returningItem: false,
  loading: false,
  itemsById: {},
  productHistoryShown: true,
  showDiscounts: false,
  showLoyalty: false,
  showPrescription: false,
  isDutchiePayError: false,
  dutchiePayPreAuthError: false,
  isFalseNegativePinDebitError: false,
  details: {
    ShipmentId: 0,
    Cart: [],
    PreOrders: [],
    PreOrderRedemptions: [],
    Discounts: [],
    Allotment: {
      CurrentLimit: 0,
      MaxLimit: 0,
      TotalInCart: 0,
      TotalLimit: 0,
      TotalRemaining: 0,
      TotalUsed: 0,
      ValidFrom: undefined,
      ValidUntil: undefined,
      Details: [],
      Error: false,
      ErrorMessage: undefined,
      Warning: false,
      WarningMessage: undefined,
    },
    Loyalty: {
      TransactionId: 0,
      AppliedLoyaltyPoints: 0,
      AppliedLoyaltyFromDiscounts: 0,
    },
    Payment: {
      ElectronicType: '',
      ElectronicAmount: 0,
    },
    ExistingPayments: [],
    FeesDonations: [],
    SubTotal: 0,
    TotalDiscountAndLoyalty: 0,
    FeesAndDonations: 0,
    FeesByPaymentType: [],
    Tax: 0,
    GrandTotal: 0,
    GrandTotalRounded: 0,
    TotalItems: 0,
    TotalDiscount: 0,
    VerifiedBy: undefined,
    RoundingAmount: 0,
    Locked: false,
    Deliverable: false,
    TipAmount: undefined,
    PreauthInfo: {
      PreauthAmount: 0,
      FinalAmount: 0,
      OrderId: 0,
      ShipmentId: 0,
      PreauthId: '',
      PaymentType: 'cash',
      PreauthDate: new Date(),
      Currency: '',
    },
    ScheduleId: '0',
    TransactionReference: '',
    TransactionStatus: '',
    CurrentRoom: '0',
    CurrentRegister: 0,
    CustomerId: 0,
  },
  preOrderIdsToOmit: [],
  daysInPeriod: 0,
  blockScans: false,
  isCustomerSelectionPanelOpen: false,
  isCustomerHistoryPanelOpen: false,
  selectedCustomerHistoryPanelTab: CustomerHistoryPanelTabs.Transactions,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CartState>) => {
    builder.addCase(setIsCartInitialized, (state: CartState, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    });
    builder.addCase(setCartTotalsLoading, (state: CartState, action: PayloadAction<boolean>) => {
      state.areCartTotalsLoading = action.payload;
    });
    builder.addCase(setCheckedInGuest, (state: CartState, action: PayloadAction<Partial<CheckedInGuest>>) => {
      state.checkedInGuest = action.payload;
      state.isCheckedInWithCaregiver = !!action.payload.TransactionCaregiver;
    });
    builder.addCase(clearCheckedInGuest, (state: CartState) => {
      state.checkedInGuest = undefined;
    });
    builder.addCase(resetCart, (state: CartState) => {
      state.daysInPeriod = 0;
      state.details = initialState.details;
      state.itemsById = initialState.itemsById;
    });
    builder.addCase(logout, (state: CartState) => {
      state.loading = false;
      state.daysInPeriod = 0;
      state.details = initialState.details;
      state.itemsById = initialState.itemsById;
    });
    builder.addCase(cancelCheckout, (state: CartState) => {
      state.loading = false;
      state.daysInPeriod = 0;
      state.details = initialState.details;
      state.itemsById = initialState.itemsById;
    });
    builder.addCase(finishCheckout, (state: CartState) => {
      state.loading = false;
      state.daysInPeriod = 0;
      state.details = initialState.details;
      state.itemsById = initialState.itemsById;
    });
    builder.addCase(calcCartTotal.pending, (state: CartState) => {
      state.loading = true;
      state.areCartTotalsLoading = true;
    });
    builder.addCase(calcCartTotal.fulfilled, (state: CartState, action: PayloadAction<CartTotal>) => {
      state.details.Discounts = action.payload.Discounts;
      state.details.Loyalty = action.payload.Loyalty;
      state.details.FeesDonations = action.payload.FeesDonations;
      state.details.SubTotal = action.payload.SubTotal;
      state.details.TotalDiscountAndLoyalty = action.payload.TotalDiscountAndLoyalty;
      state.details.Tax = action.payload.Tax;
      state.details.FeesAndDonations = action.payload.FeesAndDonations;
      state.details.GrandTotal = action.payload.GrandTotal;
      state.details.GrandTotalRounded = action.payload.GrandTotalRounded;
      state.details.TotalDiscount = action.payload.TotalDiscount;
      state.details.Cart = action.payload.Cart;
      state.details.Payment = action.payload.Payment;
      state.loading = false;
      state.details.RoundingAmount = action.payload.RoundingAmount;
      state.details.TipAmount = action.payload.TipAmount;
      state.StatefulCheckoutTotalCalculated = true;
      state.areCartTotalsLoading = false;
    });
    builder.addCase(calcCartTotal.rejected, (state: CartState) => {
      state.loading = false;
      state.areCartTotalsLoading = false;
    });
    builder.addCase(setCartTotalsFromRQ, (state: CartState, action: PayloadAction<CartTotal>) => {
      state.details.Discounts = action.payload.Discounts;
      state.details.Loyalty = action.payload.Loyalty;
      state.details.FeesDonations = action.payload.FeesDonations;
      state.details.SubTotal = action.payload.SubTotal;
      state.details.TotalDiscountAndLoyalty = action.payload.TotalDiscountAndLoyalty;
      state.details.Tax = action.payload.Tax;
      state.details.FeesAndDonations = action.payload.FeesAndDonations;
      state.details.GrandTotal = action.payload.GrandTotal;
      state.details.GrandTotalRounded = action.payload.GrandTotalRounded;
      state.details.TotalDiscount = action.payload.TotalDiscount;
      state.details.Cart = action.payload.Cart;
      state.details.Payment = action.payload.Payment;
      state.loading = false;
      state.details.RoundingAmount = action.payload.RoundingAmount;
      state.details.TipAmount = action.payload.TipAmount;
      state.StatefulCheckoutTotalCalculated = true;
      state.areCartTotalsLoading = false;
    });
    builder.addCase(loadCart.pending, (state: CartState) => {
      state.loading = true;
      state.isLoadCartError = false;
    });
    builder.addCase(loadCart.fulfilled, (state: CartState, action: PayloadAction<Cart>) => {
      const itemsById = _.reduce(
        action.payload.Cart,
        (acc: Record<string, CartItem>, item: CartItem) => {
          const id = getProductId(item);
          if (acc[id] && item.WgtCnt === 'Wgt') {
            const cartItem = _.clone(acc[id]);
            if (cartItem.Grams && item.Grams) {
              cartItem.Grams += item.Grams;
            }
            acc[id] = cartItem;
          } else {
            acc[id] = item;
          }
          return acc;
        },
        {}
      );

      state.details = { ...action.payload };
      state.details.PreOrders = state.details.PreOrders.filter((item) => !state.preOrderIdsToOmit.includes(item.Id));
      state.itemsById = itemsById;
      state.loading = false;
      state.StatefulCheckoutTotalCalculated = false;
      state.isLoadCartError = false;
    });
    builder.addCase(loadCart.rejected, (state: CartState) => {
      state.loading = false;
      state.isLoadCartError = true;
    });
    builder.addCase(setCartFromRQ, (state: CartState, action: PayloadAction<Cart>) => {
      const itemsById = _.reduce(
        action.payload.Cart,
        (acc: Record<string, CartItem>, item: CartItem) => {
          const id = getProductId(item);
          if (acc[id] && item.WgtCnt === 'Wgt') {
            const cartItem = _.clone(acc[id]);
            if (cartItem.Grams && item.Grams) {
              cartItem.Grams += item.Grams;
            }
            acc[id] = cartItem;
          } else {
            acc[id] = item;
          }
          return acc;
        },
        {}
      );

      state.details = { ...action.payload };
      state.details.PreOrders = state.details.PreOrders.filter((item) => !state.preOrderIdsToOmit.includes(item.Id));
      state.itemsById = itemsById;
      state.loading = false;
      state.StatefulCheckoutTotalCalculated = false;
    });
    builder.addCase(setCartLoading, (state: CartState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    });
    builder.addCase(clearCart.pending, (state: CartState) => {
      state.loading = true;
    });
    builder.addCase(clearCart.fulfilled, (state: CartState) => {
      state.loading = false;
      state.daysInPeriod = 0;
      state.details = initialState.details;
      state.itemsById = initialState.itemsById;
    });
    builder.addCase(clearCart.rejected, (state: CartState) => {
      state.loading = false;
    });
    builder.addCase(clearCartFromRQ, (state: CartState) => {
      state.loading = false;
      state.daysInPeriod = 0;
      state.details = initialState.details;
      state.itemsById = initialState.itemsById;
    });
    builder.addCase(clearPreOrder.fulfilled, (state: CartState) => {
      state.details.PreOrders = [];
    });
    builder.addCase(applyLoyaltyPoints.pending, (state: CartState) => {
      state.loading = true;
    });
    builder.addCase(applyLoyaltyPoints.fulfilled, (state: CartState) => {
      state.loading = false;
    });
    builder.addCase(applyLoyaltyPoints.rejected, (state: CartState) => {
      state.loading = false;
    });
    builder.addCase(returnItem.pending, (state: CartState) => {
      state.returningItem = true;
    });
    builder.addCase(returnItem.fulfilled, (state: CartState) => {
      state.returningItem = false;
    });
    builder.addCase(returnItem.rejected, (state: CartState) => {
      state.returningItem = false;
    });
    builder.addCase(showProductHistory, (state: CartState, action: PayloadAction<boolean>) => {
      state.productHistoryShown = action.payload;
    });
    builder.addCase(removeItemFromPreOrder, (state: CartState, action: PayloadAction<number>) => {
      state.preOrderIdsToOmit = [...state.preOrderIdsToOmit, action.payload];
      state.details.PreOrders = state.details.PreOrders.filter((item) => !state.preOrderIdsToOmit.includes(item.Id));
    });
    builder.addCase(setDaysSupplyInPeriod, (state: CartState, action: PayloadAction<number>) => {
      state.daysInPeriod = action.payload;
    });
    builder.addCase(
      updateElectronicPaymentDetails,
      (state: CartState, action: PayloadAction<ElectronicPaymentResponse | PollingElectronicPaymentResponse>) => {
        state.details.TipAmount = action.payload.TipAmount;
        state.details.Payment.ElectronicAmount = action.payload.DebitPaid + action.payload.CreditPaid + action.payload.TotalPaid;
      }
    );
    builder.addCase(showDiscountPanel, (state: CartState) => {
      state.showDiscounts = true;
      state.showLoyalty = false;
      state.showPrescription = false;
    });
    builder.addCase(hideDiscountPanel, (state: CartState) => {
      state.showDiscounts = false;
    });
    builder.addCase(showLoyaltyPanel, (state: CartState) => {
      state.showLoyalty = true;
      state.showDiscounts = false;
      state.showPrescription = false;
    });
    builder.addCase(hideLoyaltyPanel, (state: CartState) => {
      state.showLoyalty = false;
    });
    builder.addCase(showPrescriptionPanel, (state: CartState) => {
      state.showPrescription = true;
      state.showLoyalty = false;
      state.showDiscounts = false;
    });
    builder.addCase(hidePrescriptionPanel, (state: CartState) => {
      state.showPrescription = false;
    });
    builder.addCase(isDutchiePayError, (state: CartState, action: PayloadAction<boolean>) => {
      state.isDutchiePayError = action.payload;
    });
    builder.addCase(setPreauthAmount, (state: CartState, action: PayloadAction<number>) => {
      state.details.PreauthInfo.PreauthAmount = action.payload;
    });
    builder.addCase(setSelectedPreOrderQty, (state: CartState, action: PayloadAction<number | undefined>) => {
      state.totalSelectedPreOrderQty = action.payload;
    });
    builder.addCase(returnItemV2.pending, (state: CartState) => {
      state.returningItem = true;
    });
    builder.addCase(returnItemV2.rejected, (state: CartState) => {
      state.returningItem = false;
    });
    builder.addCase(returnItemComplete.fulfilled, (state: CartState) => {
      state.returningItem = false;
    });
    builder.addCase(returnItemComplete.rejected, (state: CartState) => {
      state.returningItem = false;
    });
    builder.addCase(setDutchiePayPreAuthError, (state: CartState, action: PayloadAction<boolean>) => {
      state.dutchiePayPreAuthError = action.payload;
    });
    builder.addCase(setIsFalseNegativePinDebitError, (state: CartState, action: PayloadAction<boolean>) => {
      state.isFalseNegativePinDebitError = action.payload;
    });
    builder.addCase(setBlockScans, (state: CartState, action: PayloadAction<boolean>) => {
      state.blockScans = action.payload;
    });
    builder.addCase(openCustomerSelectionPanel, (state: CartState) => {
      state.isCustomerSelectionPanelOpen = true;
    });
    builder.addCase(closeCustomerSelectionPanel, (state: CartState) => {
      state.isCustomerSelectionPanelOpen = false;
    });
    builder.addCase(openCustomerHistoryPanel, (state: CartState, action: PayloadAction<CustomerHistoryPanelTabs>) => {
      state.selectedCustomerHistoryPanelTab = action.payload;
      state.isCustomerHistoryPanelOpen = true;
    });
    builder.addCase(closeCustomerHistoryPanel, (state: CartState) => {
      state.isCustomerHistoryPanelOpen = false;
      state.selectedCustomerHistoryPanelTab = CustomerHistoryPanelTabs.Transactions;
    });
  },
});
