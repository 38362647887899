import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { getPriceToWeightCalc, getWeightPriceCalc, getExpiredItemsInCart } from 'api/CartApi';
import { Input } from 'components/inputs';
import { debounce, round } from 'lodash';
import { ConfirmationPopup } from 'components/popups';
import { ReactComponent as GramsIcon } from 'assets/icons/grams.svg';
import { ReactComponent as DollarSign } from 'assets/icons/dollar-sign.svg';
import { callback, callback1 } from 'models/Misc';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import styled from 'styled-components';
import { findCorrespondingUnitWithId, convertQuantity } from 'util/Helpers';
import { CartItem, ExpiredItemInfo, isCartItemOrProductSearchResult } from 'models/Cart';
import { colors } from 'css/Theme';
import { useCartPopups } from 'components/CartPopups';
import { StyledTable, StyledHeaderRow, StyledRow, StyledCell } from 'components/tables';
import { Label } from 'components/backoffice/label';
import { setSelectedPreOrderQty } from 'store/actions/CartActions';
import { isAndroid, isWebViewApp, useRegisterSettings } from 'util/hooks';
import { ScaleListener, NativeScaleListener, HardwareServiceScaleListener } from 'components/ScaleListener';
import { LoadingContent } from 'components/buttons/LoadingButton';
import { getProductId } from 'util/helpers/products/getProductId';
import { useCartItemActions } from 'util/hooks/cart-items/useCartItemActions';
import { usePriceCheckQuery } from 'queries/v2/inventory/price-check';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getPriceFromWeight, getWeightFromPrice } from 'components/helpers/BulkCalculatorHelper';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useProducts } from 'util/hooks/products/useProducts';
import { useProductsById } from 'util/hooks/products/useProductsById';
import { useVerifyLastFourIfNecessary } from 'util/hooks/useVerifyLastFourIfNecessary';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { ScaleMeasurement } from '@dutchie/capacitor-hardware';
import { useHardwareLibrary } from 'util/hooks/launch-darkly/useHardwareLibrary';

type BulkCalculatorPopupProps = {
  hide: callback;
  product: ProductSearchResult | CartItem;
  inputWeight: string;
  closeProductPreview?: () => void;
  isPreorder?: boolean;
  setWeight?: callback1<string>;
  setTotalCost?: callback1<number>;
};

export const BulkCalculatorPopup: FC<BulkCalculatorPopupProps> = ({
  hide,
  product,
  inputWeight,
  closeProductPreview,
  isPreorder = false,
  setWeight,
  setTotalCost,
}) => {
  const isHardwareLibraryActive = useHardwareLibrary();
  const dispatch = useDispatch();

  const ldClient = useLDClient();
  const useFrontEndCalculator = ldClient?.variation(
    'pos.core.cats.pricing-tiers.bulk-calculation-to-ui.rollback',
    true
  );
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const verifyLastFourIfNecessary = useVerifyLastFourIfNecessary();

  const id = getProductId(product);

  const productItemNew = useProducts((productsById) => productsById[id]);
  const { productsById } = useProductsById();
  const productItem = isAnonymousCartLDFlagEnabled ? productItemNew : productsById?.[id];

  const productId = isCartItemOrProductSearchResult(product) ? product.ProductId : product.productId;
  const serialNo = isCartItemOrProductSearchResult(product) ? product.SerialNo : product.serialNo;

  const guest = useSelector((state: State) => state.customer.details);
  const registerSettings = useRegisterSettings();
  const restrictToScale = registerSettings?.RestrictToScaleWeighing ?? false;
  const { loading, error } = useSelector((state: State) => state.cartItems);
  const cartItemLoading = loading[id];

  const features = useSelector((state: State) => state.settings.features);
  const ActualWeightThreshold = useSelector((state: State) => state.settings.locationSettings?.ActualWeightThreshold);
  const ActualWeightThresholdUnitId = useSelector(
    (state: State) => state.settings.locationSettings?.ActualWeightThresholdUnitId
  );

  const cartPopups = useCartPopups();
  const [isStable, setIsStable] = useState(true);
  const inputRowRef = useRef<HTMLDivElement>(null);

  const { data: priceDetails, isLoading: priceLoading } = usePriceCheckQuery({
    packageSerialNumber: serialNo,
  });
  const [isLoading, setIsLoading] = useState(priceLoading);

  const preOrderQty = useSelector((state: State) => state.cart.totalSelectedPreOrderQty);

  const { addBulkItemToCart } = useCartItemActions();
  const automateWeightOverride = useSelector((state: State) => state.settings.features.AutomateWeighHeavy);

  /* VARIABLES */

  // Actual grams from scale or from input
  const [grams, setGrams] = useState('');
  const [displayedGrams, setDisplayedGrams] = useState('');

  // Calculated price or from input
  const [, setPrice] = useState('');
  const [displayedPrice, setDisplayedPrice] = useState('');

  // Override weight from input only
  const [displayedOverride, setDisplayedOverride] = useState('');
  const [overrideSelected, setOverrideSelected] = useState(false);

  // Final weight and cost
  const [finalWeight, setFinalWeight] = useState('');
  const [finalCost, setFinalCost] = useState('0');

  useEffect(() => {
    const weightField = inputRowRef.current?.querySelector('input');
    // Only focus the field if not on the Elo
    // Native integration doesn't need field to be focused
    if (!isAndroid && !isWebViewApp && weightField) {
      weightField.focus();
    }
  }, []);

  /* HELPER FUNCTIONS */

  // Convert weight to price
  const getPricingTier = useCallback(
    async (wgt: string) => {
      setIsLoading(true);
      const res = await getWeightPriceCalc({
        Guest_id: guest?.Guest_id,
        Wgt: wgt,
        ProductId: productId,
        SerialNumber: serialNo,
      });
      setIsLoading(false);
      return {
        // when we don't find a matching tier we return the original weight
        weightBreak: res?.[0]?.WeightBreak ?? wgt,
        maxWeight: res?.[0]?.MaxWeight,
        // The only time this would be 0 is if something in sql broke otherwise it will return the inventory/catalog price when no tier is found
        price: res?.[0]?.ResultTxt ?? 0,
      };
    },
    [guest, productId, serialNo]
  );

  const wgtToPricingTier = useCallback(
    async (wgt: string) => {
      if (useFrontEndCalculator) {
        setIsLoading(true);
        const pricingTier = getPriceFromWeight({
          product,
          inputStr: wgt,
          priceTier: priceDetails?.pricingTier,
          isMedical: guest?.IsMedical ?? false,
          unitPrice: productItem?.unitPrice,
        });
        setIsLoading(false);
        return {
          weightBreak: pricingTier.weightBreak,
          maxWeight: pricingTier.maxWeight,
          price: round(pricingTier.price * parseFloat(wgt), 2).toString(),
        };
      } else {
        const pricingTier = await getPricingTier(wgt);
        return {
          weightBreak: pricingTier.weightBreak,
          maxWeight: pricingTier.maxWeight,
          price: round(pricingTier.price * parseFloat(wgt), 2).toString(),
        };
      }
    },
    [useFrontEndCalculator, product, productItem, priceDetails, guest, getPricingTier]
  );

  // Convert price to weight
  const priceToWgt = useCallback(
    async (price: string) => {
      if (useFrontEndCalculator) {
        setIsLoading(true);
        const weight = getWeightFromPrice({
          product,
          inputStr: price,
          priceTier: priceDetails?.pricingTier,
          isMedical: guest?.IsMedical ?? false,
          unitPrice: productItem?.unitPrice,
        });
        setIsLoading(false);
        return weight.toString();
      } else {
        setIsLoading(true);
        const res = await getPriceToWeightCalc({
          Guest_id: guest?.Guest_id,
          ProductId: productId,
          DollarAmount: price,
          SerialNo: serialNo,
          PricingId: 0,
        });
        setIsLoading(false);
        return res?.[0]?.PurchaseGrams?.toString() ?? 0;
      }
    },
    [useFrontEndCalculator, product, productItem, priceDetails, guest, productId, serialNo]
  );

  const updateFinalWeight = useCallback(
    debounce(async (weight: string) => {
      setFinalWeight(weight);
      if (+weight > 0) {
        const pricingTier = await wgtToPricingTier(weight);
        setFinalCost(pricingTier.price);
      }
    }, 500),
    [priceDetails]
  );

  // Initialize states
  useEffect(() => {
    if (Number(inputWeight)) {
      setIsLoading(true);
      wgtToPricingTier(inputWeight).then((pricingTier) => {
        setGrams(inputWeight);
        setDisplayedGrams(inputWeight);
        setFinalWeight(inputWeight);
        setPrice(pricingTier.price);
        setDisplayedPrice(pricingTier.price);
        setFinalCost(pricingTier.price);
        const weightBreak = pricingTier?.weightBreak?.toString();
        const thresholdDelta = Math.abs(+pricingTier?.weightBreak - +inputWeight);
        const allowedMax = pricingTier.maxWeight
          ? ActualWeightThreshold
            ? // If we have a location max set that's lower than the pricing tier max, use that instead
              Math.abs(Math.min(ActualWeightThreshold + +weightBreak, pricingTier.maxWeight) - +weightBreak)
            : pricingTier.maxWeight - +weightBreak
          : ActualWeightThreshold
          ? // If we have a location max set but no pricing tier max, use that instead
            ActualWeightThreshold
          : null;
        if (
          weightBreak !== inputWeight &&
          +weightBreak > 0 &&
          automateWeightOverride &&
          thresholdDelta < (allowedMax ?? 0) &&
          thresholdDelta > 0
        ) {
          updateFinalWeight(weightBreak);
          setDisplayedOverride(weightBreak);
        }
        setIsLoading(false);
      });
    }
  }, [inputWeight, updateFinalWeight, automateWeightOverride, priceDetails, ActualWeightThreshold, wgtToPricingTier]);

  /* UPDATE METHODS */
  const updateWeight = useCallback(
    debounce(async (weight: string) => {
      setIsLoading(true);
      setGrams(weight);
      setFinalWeight(weight);
      if (+weight > 0) {
        const pricingTier = await wgtToPricingTier(weight);
        setPrice(pricingTier.price);
        setDisplayedPrice(pricingTier.price);
        setFinalCost(pricingTier.price);
        const weightBreak = pricingTier?.weightBreak?.toString();
        const thresholdDelta = +weight - +pricingTier?.weightBreak;
        const allowedMax = pricingTier.maxWeight
          ? ActualWeightThreshold
            ? // If we have a location max set that's lower than the pricing tier max, use that instead
              Math.abs(Math.min(ActualWeightThreshold + +weightBreak, pricingTier.maxWeight) - +weightBreak)
            : pricingTier.maxWeight - +weightBreak
          : ActualWeightThreshold
          ? // If we have a location max set but no pricing tier max, use that instead
            ActualWeightThreshold
          : null;
        if (
          weightBreak !== inputWeight &&
          +weightBreak > 0 &&
          automateWeightOverride &&
          thresholdDelta < (allowedMax ?? 0) &&
          thresholdDelta > 0
        ) {
          updateFinalWeight(weightBreak);
          setDisplayedOverride(weightBreak);
        }
        // Only applies to Elo integration
        setIsStable(true);
      } else {
        setPrice('0');
        setDisplayedPrice('0');
        setFinalCost('0');
        // Only applies to Elo integration
        setIsStable(true);
      }
      setIsLoading(false);
    }, 500),
    [priceDetails, automateWeightOverride, ActualWeightThreshold]
  );

  const handleHardwareServiceMeasurement = (measurement: ScaleMeasurement) => {
    setIsStable(false);

    const displayWeight = measurement.value.toFixed(2).toString();
    updateWeight(displayWeight);
    setDisplayedGrams(displayWeight);
    setDisplayedOverride('');
  };

  const handleWeightChanges = (weight: string) => {
    updateWeight(weight);
    setDisplayedGrams(weight);
    setDisplayedOverride('');
  };

  const handleScaleWeightChanges = (weight: string, _stable: boolean) => {
    /* NOTE: The stable reading from the scale is only supported with certain configurations
     * so we rely on the debounce function updateWeight to set the stability to make sure
     * we have a unified experience for all scale configurations
     */
    setIsStable(false);
    handleWeightChanges(weight);
  };

  const onWeightChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // If restrictToScale is enabled, do not accept input from keyboard
    // ScaleListener will handle weight input
    if (!restrictToScale) {
      handleWeightChanges(e.target.value);
    }
  };

  const updatePrice = useCallback(
    debounce(async (money: string) => {
      setPrice(money);
      if (+money > 0) {
        setFinalCost(money);
        const newWgt = await priceToWgt(money);
        setGrams(newWgt);
        setDisplayedGrams(newWgt);
        setFinalWeight(newWgt);
      }
    }, 500),
    [priceDetails]
  );

  const onPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePrice(e.target.value);
    setDisplayedPrice(e.target.value);
    setDisplayedOverride('');
  };

  const onOverrideWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFinalWeight(e.target.value);
    setDisplayedOverride(e.target.value);
  };

  const onOverrideFocus = () => {
    setOverrideSelected(true);
    setDisplayedOverride(finalWeight);
    inputRowRef.current?.scrollIntoView();
  };

  const closePopup = () => {
    dispatch(setSelectedPreOrderQty());
    hide();
  };

  if (!guest) {
    closePopup();
    return null;
  }

  const addToCart = async () => {
    const weight = finalWeight ? finalWeight : displayedGrams;

    if (isPreorder && setWeight && setTotalCost) {
      setWeight(weight);
      setTotalCost(Number(finalCost));
      closeProductPreview?.();
      closePopup();
    } else {
      await addBulkItemToCart(product, grams, {
        weight,
        totalCost: round(Number(finalCost) / Number(weight), 4),
        unitPrice: round(Number(finalCost) / Number(weight), 4),
      });
      closeProductPreview?.();
      closePopup();
    }
  };

  const isExpiredItemsInCart = async () => {
    let anyExpiredItemsInCart = false;

    const serialNumbers = [serialNo];
    const expiredItems = (await getExpiredItemsInCart(serialNumbers)) as Array<ExpiredItemInfo>;
    if (expiredItems) {
      if (expiredItems.length > 0) {
        anyExpiredItemsInCart = true;
        cartPopups.showManagerPinPopup(() => {
          addToCart();
        }, 'Add Expired Item');
      }
    }

    return anyExpiredItemsInCart;
  };

  const onAdd = async () => {
    if (features.CheckExpirationDate) {
      if (await isExpiredItemsInCart()) {
        return;
      }
    }

    const cannabisInventory = (isCartItemOrProductSearchResult(product) ? product.CannbisProduct : product.cannabisInventory) ?? '';
    await verifyLastFourIfNecessary({ cannabisInventory, serialNo });

    addToCart();
  };

  const weightInputFieldLength = restrictToScale ? '270px' : '248px';

  const defaultUnitId = isCartItemOrProductSearchResult(product) ? productItem?.defaultUnitId : product.defaultUnitId;

  const ActualWeightThresholdError = Boolean(
    ActualWeightThreshold &&
      ActualWeightThresholdUnitId &&
      Number(grams || 0) > 0 &&
      Number(finalWeight || 0) > 0 &&
      round(convertQuantity(ActualWeightThreshold, ActualWeightThresholdUnitId, defaultUnitId), 2) <=
        Math.abs(Number(grams || 0) - Number(finalWeight || 0))
  );

  const pricingError = Number(finalCost) === 0 && Number(finalWeight || grams) > 0;

  const gramsInvalid = isNaN(+finalWeight) || isNaN(+grams) || (+finalWeight || +grams) <= 0;

  const isOverrideDisabled = restrictToScale && displayedGrams === '';

  const saveDisabled = Boolean(
    isLoading || !isStable || pricingError || ActualWeightThresholdError || cartItemLoading || error || gramsInvalid
  );

  const finalAmount = parseFloat(finalCost);

  return (
    <>
      {isHardwareLibraryActive && (
        <HardwareServiceScaleListener onReceivedMeasurement={handleHardwareServiceMeasurement} />
      )}
      {!isHardwareLibraryActive && restrictToScale && <ScaleListener onReceivedWeight={handleWeightChanges} />}
      {!isHardwareLibraryActive && isAndroid && <NativeScaleListener onReceivedWeight={handleScaleWeightChanges} />}
      <ConfirmationPopup
        medium
        contentMaxHeight='calc(100vh - 90px)'
        title='Calculate weight'
        isVisible
        data-testid='cart_cart-bulk-calculator-popup'
        hide={closePopup}
        confirm={{
          text: isStable ? 'Add' : <LoadingContent>Updating</LoadingContent>,
          disabled: saveDisabled,
          onClick: onAdd,
        }}
      >
        <Container>
          <Header>Product</Header>
          <StyledTable>
            <StyledHeaderRow>
              <StyledCell>Product name</StyledCell>
              <StyledCell>Strain</StyledCell>
              <StyledCell>Qty available</StyledCell>
            </StyledHeaderRow>
            <StyledRow>
              <StyledCell>
                {isCartItemOrProductSearchResult(product) ? product.Product : product.productDescription}
              </StyledCell>
              <StyledCell>{isCartItemOrProductSearchResult(product) ? productItem?.strain : product.strain}</StyledCell>
              <StyledCell>{`${
                isCartItemOrProductSearchResult(product) ? productItem?.totalGrams : product.totalGrams
              }g`}</StyledCell>
            </StyledRow>
          </StyledTable>
          <Divider />
          <Header>Weight and price</Header>
          <InfoGroup>
            {preOrderQty !== undefined && (
              <InfoRow>
                <InfoLabel>Unfulfilled quantity:</InfoLabel>
                <div>
                  <b>
                    {preOrderQty - Number(finalWeight ?? 0) >= 0
                      ? (preOrderQty - Number(finalWeight ?? 0)).toFixed(2)
                      : 0}
                    g
                  </b>
                </div>
              </InfoRow>
            )}
            <InfoRow ref={inputRowRef}>
              <InfoLabel tooltip={!restrictToScale && 'Actual weight (ex 1.15g)'}>
                {restrictToScale ? 'Weight:' : 'Weight/price:'}
              </InfoLabel>
              <Input
                containerWidth={weightInputFieldLength}
                data-testid='bulk-calculator_input_weight'
                placeholder={restrictToScale ? 'Place product on scale' : undefined}
                endAdornment={<GramsIcon style={{ maxWidth: 'unset' }} />}
                onChange={onWeightChange}
                value={displayedGrams}
                disabled={overrideSelected}
                onFocus={() => inputRowRef.current?.scrollIntoView()}
              />
              {!restrictToScale && (
                <>
                  <OrLabel>or</OrLabel>
                  <Input
                    containerWidth='137px'
                    data-testid='bulk-calculator_input_price'
                    startAdornment={<DollarSign />}
                    onChange={onPriceChange}
                    value={displayedPrice}
                    onFocus={() => inputRowRef.current?.scrollIntoView()}
                    disabled={overrideSelected}
                  />
                </>
              )}
            </InfoRow>
            {(!restrictToScale || ActualWeightThreshold) && (
              <InfoRow>
                <InfoLabel tooltip='Weight to charge customer (ex 1g)'>
                  Weight
                  <br />
                  override:
                </InfoLabel>
                <Input
                  containerWidth={weightInputFieldLength}
                  data-testid='bulk-calculator_input_weight-override'
                  endAdornment={<GramsIcon style={{ maxWidth: 'unset' }} />}
                  onChange={onOverrideWeightChange}
                  onFocus={onOverrideFocus}
                  onBlur={() => setOverrideSelected(false)}
                  value={displayedOverride}
                  placeholder={isOverrideDisabled ? 'Must weigh product first' : undefined}
                  disabled={isOverrideDisabled}
                />
              </InfoRow>
            )}
            <InfoRow>
              <TotalLabel>Total price:</TotalLabel>
              <TotalAmount data-testid='bulk-calculator_final-total-amount' bold={finalAmount > 0}>
                {finalAmount > 0 && '$'}
                {finalAmount.toFixed(2)}
              </TotalAmount>
            </InfoRow>
          </InfoGroup>
          {pricingError && !isLoading && (
            <ErrorMessageDiv>Cannot add $0 weighted item to cart, check your pricing configuration</ErrorMessageDiv>
          )}
          {ActualWeightThresholdError && !isLoading && (
            <ErrorMessageDiv data-testid='bulk-calculator_threshold-error-message'>
              "Weight override" must be less than {ActualWeightThreshold}
              {findCorrespondingUnitWithId(ActualWeightThresholdUnitId || 3)} different than "Weight/price"
            </ErrorMessageDiv>
          )}
        </Container>
      </ConfirmationPopup>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

const Header = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5%;
  margin-bottom: 0.5rem;
`;

const Divider = styled.div`
  border-top: 1px solid ${colors.dutchie.backgroundGrey};
  height: 0;
  margin: 2rem 0;
`;

const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin: 0.5rem 0 2rem;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  color: ${colors.dutchie.grey30};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.5%;
`;

const InfoLabel = styled(Label)`
  width: 135px;
  flex: 0 0 135px;
`;

const TotalLabel = styled(InfoLabel)`
  font-weight: 700;
`;

const TotalAmount = styled.div<{ bold: boolean }>`
  color: ${({ bold }) => (bold ? colors.dutchie.almostBlack : colors.dutchie.grey60)};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const OrLabel = styled.span`
  color: ${colors.dutchie.grey65};
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5%;
`;

const ErrorMessageDiv = styled.div`
  margin: 1rem 0;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.5%;
  font-weight: 500;
  color: ${colors.dutchie.red};
`;
