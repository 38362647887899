import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const PrinterIcon = ({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46667 1.33314C3.46667 1.03859 3.70545 0.799805 4 0.799805H12C12.2946 0.799805 12.5333 1.03859 12.5333 1.33314V5.9998C12.5333 6.29436 12.2946 6.53314 12 6.53314C11.7055 6.53314 11.4667 6.29436 11.4667 5.9998V1.86647H4.53334V5.9998C4.53334 6.29436 4.29455 6.53314 4 6.53314C3.70545 6.53314 3.46667 6.29436 3.46667 5.9998V1.33314Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.66667 6.53314C2.4545 6.53314 2.25101 6.61742 2.10098 6.76745C1.95096 6.91748 1.86667 7.12096 1.86667 7.33314V10.6665C1.86667 10.8786 1.95096 11.0821 2.10098 11.2322C2.25101 11.3822 2.4545 11.4665 2.66667 11.4665H4C4.29455 11.4665 4.53334 11.7053 4.53334 11.9998C4.53334 12.2944 4.29455 12.5331 4 12.5331H2.66667C2.1716 12.5331 1.6968 12.3365 1.34674 11.9864C0.996669 11.6363 0.800003 11.1615 0.800003 10.6665V7.33314C0.800003 6.83807 0.996669 6.36327 1.34674 6.01321C1.6968 5.66314 2.1716 5.46647 2.66667 5.46647H13.3333C13.8284 5.46647 14.3032 5.66314 14.6533 6.01321C15.0033 6.36327 15.2 6.83807 15.2 7.33314V10.6665C15.2 11.1615 15.0033 11.6363 14.6533 11.9864C14.3032 12.3365 13.8284 12.5331 13.3333 12.5331H12C11.7055 12.5331 11.4667 12.2944 11.4667 11.9998C11.4667 11.7053 11.7055 11.4665 12 11.4665H13.3333C13.5455 11.4665 13.749 11.3822 13.899 11.2322C14.0491 11.0821 14.1333 10.8786 14.1333 10.6665V7.33314C14.1333 7.12096 14.0491 6.91748 13.899 6.76745C13.749 6.61742 13.5455 6.53314 13.3333 6.53314H2.66667Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46667 9.33314C3.46667 9.03859 3.70545 8.7998 4 8.7998H12C12.2946 8.7998 12.5333 9.03859 12.5333 9.33314V14.6665C12.5333 14.961 12.2946 15.1998 12 15.1998H4C3.70545 15.1998 3.46667 14.961 3.46667 14.6665V9.33314ZM4.53334 9.86647V14.1331H11.4667V9.86647H4.53334Z" fill={color} />
    </svg>
  );
};
