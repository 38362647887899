import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { CartTotalsAndActions } from '../components/CartTotalsAndActions';
import { CustomerType } from 'components/cart/CustomerType';
import { colors } from 'css/Theme';
import { SidePanel } from 'components/layout';
import { LegalLimit } from 'components/cart/LegalLimit';
import { GuestNotes } from 'components/cart/GuestNotes';
import { TotalBreakdownDrawer } from 'components/cart/TotalBreakdownDrawer';
import { Overlay } from 'components/cart/Overlay';

import { PreorderRedemption } from '../../CartComponents/PreorderRedemption/PreorderRedemption';
import { CustomerInsights } from 'components/cart/CustomerInsights';
import { PurchaseHistory } from 'components/cart/PurchaseHistory';
import { useTotalsSidebar } from './useTotalsSidebar';
import { useChangeCustomerTypeInCart } from 'util/hooks/launch-darkly/useChangeCustomerTypeInCart';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

export type TotalsSidebarProps = {
  isLoading?: boolean;
};

export const TotalsSidebar: FC<TotalsSidebarProps> = ({ isLoading = false }) => {
  const { cart, handleCloseTotalsDrawer, isTotalsDrawerOpen, isCartLoading, setIsTotalsDrawerOpen } =
    useTotalsSidebar();

  const { isChangeCustomerTypeInCartEnabled } = useChangeCustomerTypeInCart();
  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  return (
    <PanelContainer>
      <TopSection>
        <Overlay
          isActive={isTotalsDrawerOpen}
          onClick={handleCloseTotalsDrawer}
          data-testid='totals-sidebar-overlay'
          data-dd-action-name='totals sidebar overlay (close totals drawer)'
        />
        {isChangeCustomerTypeInCartEnabled && <CustomerType />}
        <ScrollContainer>
          <LegalLimit loading={isLoading} showDetailsButton={!isLoading} />
          <CustomerInsights isLoading={isLoading} />
          {isCustomerInsightsEnabled && <PurchaseHistory isLoading={isLoading} />}
          <PreorderRedemption />
          <GuestNotes />
        </ScrollContainer>
        <TotalBreakdownDrawer cart={cart} isOpen={isTotalsDrawerOpen} isLoading={isLoading || isCartLoading} />
      </TopSection>
      <CartTotalsAndActions
        cart={cart}
        isLoading={isLoading}
        areTotalsOpen={isTotalsDrawerOpen}
        onToggleTotals={setIsTotalsDrawerOpen}
        actionButtonStyles={actionButtonStyles}
      />
    </PanelContainer>
  );
};

const PanelContainer = styled(SidePanel)`
  flex-grow: 1;
`;

const TopSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  border-bottom: 1px solid ${colors.dutchie.backgroundGrey};
`;

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const actionButtonStyles = css`
  flex: 1;
`;
