import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { barcodeScanEntryKeys } from './query-key-factory';

import type { UseQueryOptions } from '@tanstack/react-query';
import type { IdentificationData } from './types';

const BARCODE_SCAN_ENTRY = 'checkin/barcode-scan-entry';

type ValidatedCustomer = {
  Id: number;
  LspId: number;
  LocId: number;
  Status: string;
  MJStateIdNumber: string;
  MJStateIdStartDate: string;
  MJStateIdExpirationDate: string;
  DriversLicenseNumber: string;
  DriversLicenseExpirationDate: string;
  FullName: string;
  NamePrefix: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  NameSuffix: string;
  DateOfBirth: string;
  Address1: string;
  Address2: string;
  Address3: string;
  City: string;
  State: string;
  PostalCode: string;
  CustomerType: string;
  IsMedical: boolean;
  Nickname: string;
  IsLoyaltyMember: boolean;
  LoyaltyPoints: number;
  LastTransaction: string;
  IsBanned: boolean;
  IsUnderMedicalAge: boolean;
  IsUnderRecreationalAge: boolean;
  IsMJStateIdExpired: boolean;
  IsDriversLicenseExpired: boolean;
};

type BarcodeScanEntryPayload = {
  ID: string;
};

export type BarcodeScanEntryResponse = {
  CreatedCustomerId?: boolean;
  CheckedInShipmentId?: boolean;
  IdentificationData: IdentificationData;
  ValidatedIdentificationCustomer: ValidatedCustomer;
  ValidatedMatchedCustomers: ValidatedCustomer[];
  ValidatedMatchedCaregivers:  ValidatedCustomer[];
};

export const useBarcodeScanEntryQuery = (payload: BarcodeScanEntryPayload, options: Partial<UseQueryOptions>) => {
  return useQuery({
    queryKey: barcodeScanEntryKeys.many(payload.ID),
    queryFn: async () => {
      const response = await post<BarcodeScanEntryResponse>(BARCODE_SCAN_ENTRY, payload);

      return response;
    },
    enabled: options?.enabled ?? true,
    cacheTime: options?.cacheTime ?? 0,
    refetchOnWindowFocus: false,
  });
};
