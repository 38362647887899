import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutContext } from '../../CheckoutContext';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { PaymentMethod, PaymentType } from 'models/Checkout';
import { checkPinForDigitalPayment, digitalPayment } from 'store/actions/CheckoutActions';
import { v4 as uuidv4 } from 'uuid';
import { State } from 'store';
import { PaymentOption } from '../../PaymentOption';
import { PaymentShortcuts } from '../PaymentShortcuts';
import { GeneriPayPaymentIntegration, useGeneriPayCancelPaymentMutation } from 'queries/v2/payments/generipay';
import { Button } from 'components/buttons';
import { Flex } from 'components/layout';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

export type AmountSelectionProps = {
  PaymentTypeMetaData: GeneriPayPaymentIntegration;
};

function useGeneripayUiStrings(PosButtonText: string, Name: string) {
  return {
    invalidAmount: `Invalid ${PosButtonText} amount.`,
    paymentUpdated: `${PosButtonText} Payment updated.`,
    paymentAdded: `${Name} Payment added.`,
    paymentCancelledSuccess: `${Name} Payment cancelled.`,
    paymentCancellationFailure: `Failed to cancel ${Name} payment.`,
    transactionAlreadyInProcess: 'The transaction is currently being processed. Please be patient as it completes.',
  };
}

// Uses the CamelizedIntegrationType to determine the PaymentType enum to use
function useGeneripayPaymentPaymentType(CamelizedIntegrationType: string) {
  return `generipay-${CamelizedIntegrationType}`;
}

export function AmountSelection({ PaymentTypeMetaData }: AmountSelectionProps) {
  const dispatch = useDispatch();

  const { shipmentId } = useTransactionManager();

  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const checkOutPinRequired = useSelector((state: State) => state.settings.features.CheckOutPinRequired);
  const managerPin = useSelector((state: State) => state.checkout.managerPin);
  const [cancelDisabled, setCancelDisabled] = useState(true);

  const { PosButtonText, CamelizedIntegrationType, Name } = PaymentTypeMetaData;
  const uiStrings = useGeneripayUiStrings(PosButtonText, Name);
  const paymentProviderType = useGeneripayPaymentPaymentType(CamelizedIntegrationType);

  const { paymentSummary, setPaymentProcessing, setSelectedPaymentType } = useContext(CheckoutContext);
  const generiPayPaymentSummary = paymentSummary[PaymentType.GeneriPay];
  const [transactionAmount, setTransactionAmount] = useState<number>();

  const cancelGeneriPayPaymentMutation = useGeneriPayCancelPaymentMutation();

  // Disabled the cancel button if the transaction is still processing
  // Prevents rage clicking the cancel button
  useEffect(() => {
    setCancelDisabled(true);
    let timeout: NodeJS.Timeout | null = null;
    if (digitalPaymentLoading) {
      timeout = setTimeout(() => {
        setCancelDisabled(false);
      }, 15_000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [digitalPaymentLoading]);

  // // If the transaction is done processing, reset the payment type to null
  useEffect(() => {
    setPaymentProcessing(digitalPaymentLoading);
    setCancelDisabled(!digitalPaymentLoading);
    if (transactionAmount && !digitalPaymentLoading && generiPayPaymentSummary >= transactionAmount) {
      setSelectedPaymentType(null);
    }
  }, [digitalPaymentLoading, transactionAmount, setPaymentProcessing, setSelectedPaymentType, generiPayPaymentSummary]);

  // /////////////
  // TODO: In future, fees may need to be calculated and added to the payment amount here
  // /////////////

  // Handle the additon of a Generipay payment
  const onPay = useCallback(
    (amount) => {
      if (digitalPaymentLoading) {
        dispatch(errorNotification(uiStrings.transactionAlreadyInProcess));
        return;
      }

      if (amount <= 0) {
        dispatch(errorNotification(uiStrings.invalidAmount));
        return;
      }

      setTransactionAmount(amount);

      const paymentMethod: PaymentMethod = {
        id: uuidv4(),
        name: PaymentTypeMetaData.PosReportingField,
        type: PaymentTypeMetaData.PosReportingFieldId,
        PaymentProcessor: PaymentTypeMetaData.PosButtonText,
        PaymentProviderType: paymentProviderType,
        amount: amount,
        finalized: true,
        data: {
          PaymentIntegrationDefinitionId: PaymentTypeMetaData.PaymentIntegrationDefinitionId,
          ShipmentPaymentType: PaymentTypeMetaData.PosReportingFieldId,
        },
      };

      if (checkOutPinRequired) {
        dispatch(checkPinForDigitalPayment({ code: managerPin, paymentMethod }));
      } else {
        dispatch(digitalPayment(paymentMethod));
      }
    },
    [
      digitalPaymentLoading,
      uiStrings.transactionAlreadyInProcess,
      uiStrings.invalidAmount,
      paymentProviderType,
      PaymentTypeMetaData.PosButtonText,
      PaymentTypeMetaData.PaymentIntegrationDefinitionId,
      PaymentTypeMetaData.PosReportingField,
      PaymentTypeMetaData.PosReportingFieldId,
      checkOutPinRequired,
      dispatch,
      managerPin,
    ]
  );

  const handleCancelPayment = useCallback(async () => {
    try {
      await cancelGeneriPayPaymentMutation.mutateAsync({ ShipmentId: shipmentId, CamelizedIntegrationType });
      dispatch(successNotification(uiStrings.paymentCancelledSuccess));
      setSelectedPaymentType(null);
    } catch {
      dispatch(errorNotification(uiStrings.paymentCancellationFailure));
    }
  }, [
    cancelGeneriPayPaymentMutation,
    dispatch,
    shipmentId,
    setSelectedPaymentType,
    uiStrings.paymentCancellationFailure,
    uiStrings.paymentCancelledSuccess,
    CamelizedIntegrationType,
  ]);

  return (
    <PaymentOption onAddAmount={onPay}>
      <Flex direction='row' alignItems='center' justifyContent='space-between'>
        <PaymentShortcuts shortcuts={[{ name: 'Exact Amount', width: '140px' }]} onAddAmount={onPay} />
        <Button
          marginLeft={6}
          tertiary
          onClick={handleCancelPayment}
          disabled={!digitalPaymentLoading || cancelDisabled}
          automationId='payment-options-generipay_button_cancel'
        >
          Cancel
        </Button>
      </Flex>
    </PaymentOption>
  );
}
