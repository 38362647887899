import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const LoyaltyIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <path
            d='M10 17.9414L6.55854 22.8563L5.72406 19.2201L1.64363 19.4149L5.08509 14.4999'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M14 17.9414L17.4415 22.8563L18.2759 19.2201L22.3564 19.4149L18.9149 14.4999'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <circle cx='12' cy='9.5' r='8.25' stroke='#828A8F' stroke-width='1.5' />
          <path
            d='M13.689 7.40474C13.7208 7.63267 13.8555 7.83336 14.0545 7.94914C14.2534 8.06492 14.4944 8.08296 14.7083 7.99809C14.9536 7.9008 15.2106 7.8313 15.4745 7.79181C16.0732 7.70222 16.6849 7.77044 17.2474 7.98884C17.2311 8.22782 17.1672 8.62172 16.8489 9.11392C16.5797 9.53004 16.1277 10.0184 15.3644 10.5429C15.1921 10.6613 15.077 10.8463 15.0469 11.0532C15.0169 11.2602 15.0746 11.4702 15.2061 11.6328C15.6495 12.1808 15.9789 12.8118 16.1731 13.4899C16.1262 13.5616 16.0558 13.6172 15.9474 13.6618C15.8192 13.7145 15.6645 13.7394 15.5163 13.747C15.1799 13.7641 14.7603 13.7043 14.326 13.586C13.8919 13.4679 13.4454 13.2921 13.0553 13.079C12.8727 12.9792 12.6986 12.8692 12.5429 12.7505C12.2771 12.5479 11.9093 12.5456 11.641 12.745C11.4779 12.8661 11.294 12.978 11.101 13.079C10.6936 13.2922 10.2239 13.4681 9.76663 13.5863C9.30914 13.7045 8.86632 13.7643 8.5124 13.7471C8.33543 13.7385 8.18268 13.7106 8.06224 13.6626L7.78438 14.3592L8.06224 13.6626C7.95201 13.6186 7.8742 13.5599 7.82698 13.4896C8.0211 12.8118 8.35046 12.1809 8.79368 11.6331C8.92523 11.4705 8.9829 11.2603 8.95278 11.0534C8.92266 10.8464 8.80748 10.6614 8.63504 10.543C7.87088 10.0185 7.41886 9.53008 7.15004 9.11395C6.83285 8.62296 6.7691 8.22977 6.75271 7.99055C7.35927 7.78196 8.00819 7.71924 8.64509 7.80815C8.95476 7.85138 9.25698 7.92981 9.54594 8.04107C9.76029 8.1236 10.0006 8.10336 10.1982 7.98616C10.3957 7.86895 10.5286 7.66769 10.5589 7.44002C10.6159 7.01101 10.7238 6.58873 10.8811 6.18202C11.1579 5.46682 11.5817 4.81506 12.1264 4.26789C12.671 4.81516 13.0947 5.46694 13.3714 6.18212C13.5245 6.57765 13.6307 6.9879 13.689 7.40474Z'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <line
            x1='12.25'
            y1='12.75'
            x2='12.25'
            y2='15.25'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
          />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <circle cx='12' cy='9.5' r='9' fill='#FAF6D4' />
        <path
          d='M6.55854 22.8563L10 17.9414L5.08509 14.4999L1.64363 19.4149L5.72406 19.2201L6.55854 22.8563Z'
          fill='#FAF6D4'
        />
        <path
          d='M17.4415 22.8563L14 17.9414L18.9149 14.4999L22.3564 19.4149L18.2759 19.2201L17.4415 22.8563Z'
          fill='#FAF6D4'
        />
        <path
          d='M10 17.9414L6.55854 22.8563L5.72406 19.2201L1.64363 19.4149L5.08509 14.4999'
          stroke='#CCB400'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M14 17.9414L17.4415 22.8563L18.2759 19.2201L22.3564 19.4149L18.9149 14.4999'
          stroke='#CCB400'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <circle cx='12' cy='9.5' r='8.25' stroke='#CCB400' stroke-width='1.5' />
        <path
          d='M13.689 7.40474C13.7208 7.63267 13.8555 7.83336 14.0545 7.94914C14.2534 8.06492 14.4944 8.08296 14.7083 7.99809C14.9536 7.9008 15.2106 7.8313 15.4745 7.79181C16.0732 7.70222 16.6849 7.77044 17.2474 7.98884C17.2311 8.22782 17.1672 8.62172 16.8489 9.11392C16.5797 9.53004 16.1277 10.0184 15.3644 10.5429C15.1921 10.6613 15.077 10.8463 15.0469 11.0532C15.0169 11.2602 15.0746 11.4702 15.2061 11.6328C15.6495 12.1808 15.9789 12.8118 16.1731 13.4899C16.1262 13.5616 16.0558 13.6172 15.9474 13.6618C15.8192 13.7145 15.6645 13.7394 15.5163 13.747C15.1799 13.7641 14.7603 13.7043 14.326 13.586C13.8919 13.4679 13.4454 13.2921 13.0553 13.079C12.8727 12.9792 12.6986 12.8692 12.5429 12.7505C12.2771 12.5479 11.9093 12.5456 11.641 12.745C11.4779 12.8661 11.294 12.978 11.101 13.079C10.6936 13.2922 10.2239 13.4681 9.76663 13.5863C9.30914 13.7045 8.86632 13.7643 8.5124 13.7471C8.33543 13.7385 8.18268 13.7106 8.06224 13.6626L7.78438 14.3592L8.06224 13.6626C7.95201 13.6186 7.8742 13.5599 7.82698 13.4896C8.0211 12.8118 8.35046 12.1809 8.79368 11.6331C8.92523 11.4705 8.9829 11.2603 8.95278 11.0534C8.92266 10.8464 8.80748 10.6614 8.63504 10.543C7.87088 10.0185 7.41886 9.53008 7.15004 9.11395C6.83285 8.62296 6.7691 8.22977 6.75271 7.99055C7.35927 7.78196 8.00819 7.71924 8.64509 7.80815C8.95476 7.85138 9.25698 7.92981 9.54594 8.04107C9.76029 8.1236 10.0006 8.10336 10.1982 7.98616C10.3957 7.86895 10.5286 7.66769 10.5589 7.44002C10.6159 7.01101 10.7238 6.58873 10.8811 6.18202C11.1579 5.46682 11.5817 4.81506 12.1264 4.26789C12.671 4.81516 13.0947 5.46694 13.3714 6.18212C13.5245 6.57765 13.6307 6.9879 13.689 7.40474Z'
          stroke='#CCB400'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <line x1='12.25' y1='12.75' x2='12.25' y2='15.25' stroke='#CCB400' stroke-width='1.5' stroke-linecap='round' />
      </g>
    </svg>
  );
};
