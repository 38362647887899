import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { Input } from 'components/inputs';
import { LoadingButton } from 'components/buttons';
import { callback2 } from 'models/Misc';
import { checkManagerPin } from 'util/Helpers';

const ManagerSectionBase = styled.div<{ withPin: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: ${({ withPin }) => (withPin ? '0.5rem' : '0')};
  align-items: center;
`;

const StyledInput = styled(Input)`
  margin-right: 0.5rem;
`;

const StyledButton = styled(LoadingButton)<{ align?: string }>`
  margin-left: ${({ align }) => (align === 'right' ? 'auto' : '1rem')};
  white-space: nowrap;
`;

type ManagerSectionProps = {
  disabled?: boolean;
  needPin: boolean;
  title: string;
  placeholder?: string;
  onSuccess: callback2<number, string>;
};

export const ManagerSection: FC<ManagerSectionProps> = (props) => {
  const { disabled, needPin, title, placeholder, onSuccess } = props;

  const dispatch = useDispatch();
  const [managerPin, setManagerPin] = useState('');
  const [managerPinWrong, setManagerPinWrong] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    setManagerPinWrong(false);

    let ApprovingManagerUserId;
    try {
      if (needPin) {
        ApprovingManagerUserId = await checkManagerPin(managerPin);
        onSuccess(ApprovingManagerUserId[0]?.UserId, managerPin);
        dispatch(successNotification('PIN verified'));
      } else {
        onSuccess(0, '');
      }

      setManagerPin('');
      setLoading(false);
    } catch {
      setLoading(false);
      setManagerPinWrong(true);
      dispatch(errorNotification('Invalid PIN'));
    }
  };

  const onManagerPinChange = (pin: string) => {
    managerPinWrong && setManagerPinWrong(false);
    setManagerPin(pin);
  };

  return (
    <ManagerSectionBase data-testid='manager-section_div_section' withPin={needPin}>
      {needPin && (
        <StyledInput
          data-testid='manager-section_styled-input'
          hasError={managerPinWrong}
          value={managerPin}
          onChange={(e) => {
            //remote non-numbers since pins are numbers only
            const cleaned = e.target.value.replace(/\D/g, '');
            onManagerPinChange(cleaned);
          }}
          placeholder={placeholder || 'Manager PIN'}
          type='password'
          autoComplete='false'
        />
      )}
      <StyledButton
        automationId='manager-section_styled-button_submit-pin'
        loading={loading}
        align={!needPin ? 'right' : 'auto'}
        disabled={disabled || loading || (needPin && !managerPin)}
        onClick={onSubmit}
      >
        {title}
      </StyledButton>
    </ManagerSectionBase>
  );
};
