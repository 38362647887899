import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionGridContainer, SectionHeader, SectionContent } from './SettingPageComponents';
import { State } from 'store';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { Switch } from 'components/inputs';
import { Button } from 'components/buttons';
import { usePopup } from 'components/popups';
import { isAndroid, CFD_PATH } from 'util/hooks';
import { ConfirmOpenCashDrawerPopup } from 'components/CartPopups/components/ConfirmOpenCashDrawerPopup';

export const AdvancedSettings: FC = () => {
  const { userSettings, selectedRegister, features } = useSelector((state: State) => state.settings);
  const { isVisible: isConfirmOpenCashDrawerPopupVisible, toggle: toggleConfirmOpenCashDrawerPopup } = usePopup();
  const LocId = window.sessionStorage.getItem('LocId');
  const dispatch = useDispatch();
  const { showSearch, showPopupNotes, showGuests } = userSettings;

  const openCartDisplay = () => {
    if (selectedRegister) {
      window.open(
        `${CFD_PATH}?Register=${selectedRegister?.value}&LocId=${LocId}`,
        'popUpWindow',
        'height=700,width=1050,left=200,top=200,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
      );
    } else {
      dispatch(errorNotification('Please select a register to display first'));
    }
  };

  return (
    <>
      <SectionGridContainer>
        <SectionHeader>Advanced</SectionHeader>
        <SectionContent>
          <Switch
            label='Popup Notes when entering POS'
            onChange={(showPopupNotes) => dispatch(saveUserSettings({ showPopupNotes }))}
            checked={showPopupNotes}
          />
          <Switch
            label='Show search in guest list'
            onChange={(showSearch) => dispatch(saveUserSettings({ showSearch }))}
            checked={showSearch}
          />
          <Switch
            label='Show Guests assigned to register'
            onChange={(showGuests) => dispatch(saveUserSettings({ showGuests }))}
            checked={showGuests}
          />
        </SectionContent>
      </SectionGridContainer>
      {features.UseCartDisplay && !isAndroid && (
        <Button tertiary onClick={openCartDisplay} marginTop={6}>
          Open Cart Display
        </Button>
      )}
      <Button tertiary onClick={toggleConfirmOpenCashDrawerPopup} marginTop={6} marginLeft={6}>
        Open Cash Drawer
      </Button>
      <ConfirmOpenCashDrawerPopup
        isVisible={isConfirmOpenCashDrawerPopupVisible}
        hide={toggleConfirmOpenCashDrawerPopup}
      />
    </>
  );
};
