import React, { ChangeEvent } from 'react';
import { InputAdornment as MUIInputAdornment, SxProps, FormControlProps as MUIFormControlProps } from '@mui/material';
import { Loader } from '../loader';
import { Label } from '../label';
import { FormControl } from '../form-control';
import { BaseInputProps } from './base-input';
import { FormInput } from './styled-inputs/form-input';
import { LoaderContainer } from './base-input/base-input.styles';

export const InputAdornment = MUIInputAdornment;

export type InputProps = BaseInputProps &
  MUIFormControlProps & {
    label?: React.ReactNode | string;
    id?: string;
    labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
    value?: number | string;
    type?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    multiline?: boolean;
    width?: string;
    required?: boolean;
    disabled?: boolean;
    sx?: SxProps;
    placeholder?: string;
    rows?: number;
    autoComplete?: string;
    forceRebrandStyles?: boolean;
    endAdornment?: unknown;
    startAdornment?: unknown;
    validator?: (event: ChangeEvent<HTMLInputElement>) => boolean;
    gridColumns?: number;
    tooltip?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    automationId?: string;
    loading?: boolean;
    maxlength?: number;
  };

export function Input(props: InputProps): JSX.Element {
  const {
    label,
    id,
    value,
    type = 'text',
    onChange = () => undefined,
    onBlur,
    multiline = false,
    labelPlacement = 'start',
    width,
    required = false,
    disabled = false,
    sx,
    placeholder,
    rows,
    endAdornment,
    startAdornment,
    InputProps,
    forceRebrandStyles = false,
    validator = null,
    className = '',
    tooltip,
    onClick,
    automationId,
    autoFocus = false,
    onKeyPress,
    loading,
    maxlength,
    ...other
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    (validator && !validator(event)) || onChange(event);
  };

  const idFromLabel: string = typeof label === 'string' && label;

  return (
    <FormControl
      $labelPlacement={labelPlacement}
      $width={width}
      className={`input-form-control ${className}`}
      forceRebrandStyles={forceRebrandStyles}
      hiddenLabel
      multilineLabel={multiline && label}
      sx={sx}
      {...other}
    >
      {label && (
        <Label
          className='input-label'
          forceRebrandStyles={forceRebrandStyles}
          htmlFor={`input-label_${id ?? idFromLabel}`}
          required={required}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}

      {loading ? (
        <LoaderContainer>
          <Loader variant='black' />
        </LoaderContainer>
      ) : (
        <FormInput
          $fullWidth={!label}
          autoFocus={autoFocus}
          automationId={automationId}
          className='input-input'
          disabled={disabled}
          endAdornment={endAdornment}
          id={`input-label_${id ?? idFromLabel}`}
          InputProps={InputProps}
          maxlength={maxlength}
          multiline={multiline}
          placeholder={placeholder}
          rows={rows}
          startAdornment={startAdornment}
          type={type}
          value={value}
          variant='outlined'
          onBlur={onBlur}
          onChange={handleChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
        />
      )}
    </FormControl>
  );
}
