import React, { FC, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNil, map } from 'lodash';
import { Formik, Form, FormikProps } from 'formik';
import { ConfirmationPopup } from 'components/popups';
import { InputField } from 'components/inputs';
import * as checkoutApi from 'api/CheckoutApi';
import { HypurClient, HypurCheckoutRequest, PaymentMethod } from 'models/Checkout';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { checkPinForDigitalPayment, digitalPayment } from 'store/actions/CheckoutActions';

const StyledForm = styled(Form)`
  padding: 0 2rem;
`;

const List = styled.ul`
  max-height: 270px;
  overflow-y: auto;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 1.5rem;
  cursor: pointer;
  &:last-of-type {
    margin: 0;
  }
  & img {
    max-width: 64px;
    margin-right: 1rem;
  }
`;

type HypurCheckoutPopupProps = {
  hide: () => void;
  paymentMethod: PaymentMethod;
  shipmentId?: number;
};

type HypurCheckoutFormValues = {
  pin?: string;
};

const initialValues: Partial<HypurCheckoutFormValues> = {
  pin: '',
};

export const HypurCheckoutPopup: FC<HypurCheckoutPopupProps> = ({ paymentMethod, hide, shipmentId }) => {
  const [selected, setSelected] = useState<HypurClient | undefined>();
  const [hypurClients, setHypurClients] = useState<Array<HypurClient>>([]);
  const formRef = useRef<FormikProps<HypurCheckoutFormValues> | null>(null);
  const { features } = useSelector((state: State) => state.settings);
  const checkout = useSelector((state: State) => state.checkout);
  const dispatch = useDispatch();

  const onSubmit = async (values: HypurCheckoutFormValues) => {
    if (selected) {
      const data: HypurCheckoutRequest = {
        CheckInISN: selected.CheckInISN,
        IndividualISN: selected.IndividualISN,
        Pin: values.pin,
      };
      paymentMethod.data = data;
      if (features.CheckOutPinRequired) {
        dispatch(checkPinForDigitalPayment({ code: checkout.managerPin, paymentMethod }));
      } else {
        dispatch(digitalPayment(paymentMethod));
      }
    }

    hide();
  };

  useEffect(() => {
    checkoutApi
      .getHypurClients()
      .then(setHypurClients)
      .catch(() => setHypurClients([]));
  }, []);

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title={selected ? 'Enter Client PIN' : 'Hypur Client List'}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          setSelected(undefined);
          hide();
        },
      }}
      confirm={{
        text: 'Confirm',
        disabled: isNil(selected),
        id: `payButton_${shipmentId}`,
        onClick: () => {
          formRef.current?.handleSubmit();
        },
      }}
    >
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values) => onSubmit(values)}
        innerRef={(instance) => (formRef.current = instance)}
      >
        <StyledForm>
          {selected ? (
            <InputField type='password' name='pin' placeholder='Client PIN' />
          ) : (
            <List>
              {map(hypurClients, (c) => (
                <Item onClick={() => setSelected(c)}>
                  <img src={`data:image/jpeg;base64, ${c.IndividualBase64Thumbnail}`} alt='hypur' />
                  <p>{c.IndividualName}</p>
                </Item>
              ))}
            </List>
          )}
        </StyledForm>
      </Formik>
    </ConfirmationPopup>
  );
};
