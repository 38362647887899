import React, { useState, useMemo } from 'react';
import { KeypadFooter } from 'pages/PinLoginPage/KeypadFooter';
import { KeypadHeader } from 'pages/PinLoginPage/KeypadHeader';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'css/Theme';
import { State } from 'store';
import { handleLogout, SelectLsp } from 'store/actions/UserActions';
import { curriedSelectLocation } from 'store/actions/SettingsActions';
import { Select } from 'components/inputs';
import { Button } from 'components/buttons';

export const LocationSelectionPageV2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: State) => state.user);
  const [selectedLsp, setSelectedLsp] = useState<number | undefined>(userInfo.selectedLsp?.LspId);

  const locationOptions = useMemo(() => {
    return userInfo.locations
      .filter((location) => location.registersAvailable)
      .map((item) => ({ value: item.location_id, label: item.location_name }));
  }, [userInfo.locations]);

  const [selectedLocation, setSelectedLocation] = useState<number | undefined>();

  const lspOptions = userInfo.lsps.map((item) => ({ value: item.LspId, label: item.LspName }));

  const handleCompanyChange = (value: string) => {
    const lsp = userInfo.lsps.find((item) => item.LspId === parseInt(value));
    if (lsp) {
      setSelectedLsp(parseInt(value, 10));
      dispatch(SelectLsp(lsp));
      setSelectedLocation(undefined);
    }
  };

  const handleLocationChange = (value: string) => setSelectedLocation(parseInt(value, 10));

  const handleSelectClick = () => {
    const location = userInfo.locations.find((item) => item.location_id === selectedLocation);
    if (location) {
      dispatch(curriedSelectLocation({ location: location }));
    }
  };

  const handleLogoutClick = () => dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));

  return (
    <StyledPage>
      <KeypadHeader />
      <FormContainer>
        <StyledTitle>Select your location</StyledTitle>
        <Caption>Company</Caption>
        <StyledSelect
          defaultValue={selectedLsp}
          placeholder='Choose a Company'
          onChange={handleCompanyChange}
          options={lspOptions}
          automationId='location-selection-page_select_company'
        />
        {!isEmpty(locationOptions) ? (
          <>
            <Caption>Location</Caption>
            <StyledSelect
              defaultValue={selectedLocation}
              placeholder='Choose a Location'
              onChange={handleLocationChange}
              options={locationOptions}
              automationId='location-selection-page_select_location'
            />
            <Button
              disabled={!(Boolean(selectedLocation) && Boolean(selectedLsp))}
              fullWidth
              onClick={handleSelectClick}
              automationId='location-selection-page_button_select-location'
            >
              Continue
            </Button>
          </>
        ) : (
          <>
            <StyledInlineError>You do not have permissions for POS</StyledInlineError>
            <Button
              fullWidth
              onClick={handleLogoutClick}
              automationId='location-selection-page_button_logout'
            >
              Logout
            </Button>
          </>
        )}
      </FormContainer>
      <KeypadFooter />
    </StyledPage>
  );
};

const StyledInlineError = styled.div`
  color: ${colors.dutchie.red};
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 2rem;
`;

const StyledSelect = styled(Select)`
  margin-top: 0.625rem;
  width: 100%;
  margin-bottom: 2rem;
`;

const Caption = styled.p`
  color: ${colors.dutchie.primaryWhite};
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const StyledPage = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${colors.dutchie.opal90};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: ${colors.dutchie.primaryWhite};
  margin: 0 auto 2rem;
`;

const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;
