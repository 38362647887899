import React from 'react';
import { cloneDeep } from 'lodash';
import { FilterOption, FilterOptionValue } from 'models/Search';
import { DropdownMenuOption } from 'components/backoffice/dropdown';
import { MultiFilterSelect } from 'components/backoffice/multi-filter-select/multi-filter-select';

import type { PreorderProductSearchResult } from 'api/PreorderApi';
import type { ProductSearchResult } from 'queries/v2/product/types';

type FilterDropdownProps<T extends object> = {
  appliedFilters: Array<FilterOption<T>>;
  filterOptions: Array<FilterOption<T>>;
  filterKey: keyof T;
  loading?: boolean;
  title: string;
  onApply: (appliedFilters: Array<FilterOption<T>>) => void;
  className?: string;
};

export const AddItemsFilterDropdown = React.memo<FilterDropdownProps<ProductSearchResult>>(FilterDropdown);

export const PreorderAddItemsFilterDropdown =
  React.memo<FilterDropdownProps<PreorderProductSearchResult>>(FilterDropdown);

export function FilterDropdown<T extends object>({
  appliedFilters,
  filterOptions,
  filterKey,
  loading = false,
  onApply,
  title,
  className,
}: FilterDropdownProps<T>): JSX.Element | null {
  const applied = appliedFilters?.find((x) => x.key === filterKey);
  const options = filterOptions.find((x) => x.key === filterKey);

  if (!options) {
    return null;
  }

  const dropdownOptions: DropdownMenuOption[] = options.options.map((x) => {
    const asString = x as unknown as string;
    return {
      checkbox: true,
      id: asString,
      label: asString,
      key: asString,
    };
  });

  const selected = dropdownOptions.filter(
    (x) => applied?.options && applied.options.some((y) => (y as unknown as string) === (x.id as string))
  );

  const onValueSet = (values: DropdownMenuOption[]) => {
    const newFilters = cloneDeep(appliedFilters || []);
    const index = newFilters.findIndex((x) => x.key === filterKey);

    const newFilter = cloneDeep(options);
    newFilter.options = values.map((x) => x.key as unknown as FilterOptionValue<T>);

    if (index < 0) {
      newFilters.push(newFilter);
    } else {
      newFilters.splice(index, 1);
      newFilters.push(newFilter);
    }

    onApply(newFilters);
  };

  return (
    <MultiFilterSelect
      options={dropdownOptions}
      activeFilter={selected}
      onChange={onValueSet}
      label={title}
      loading={loading}
      className={className}
    ></MultiFilterSelect>
  );
}
