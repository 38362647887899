import { useClickCard } from './useClickCard';
import { CheckedInGuest } from 'models/Guest';
import { colors } from 'css/Theme';

type UseOrderKanbanCardParams = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
  isDragging: boolean;
};
type UseOrderKanbanCardReturn = {
  onClickCard: () => Promise<void>;
  hasAssignedRegister: boolean;
  draggingStyles: { opacity: string };
  borderColor: string;
  isBanned: boolean;
};
export const useOrderKanbanCard = ({ guest, cardOptionsStatus, isDragging }: UseOrderKanbanCardParams): UseOrderKanbanCardReturn => {
  const onClickCard = useClickCard({ guest });

  const hasAssignedRegister = cardOptionsStatus['Register'] && !!guest.Register;

  const draggingStyles = {
    opacity: isDragging ? '0' : '1',
  };

  const isBanned = guest?.GuestStatus?.toLowerCase() === 'banned';
  const borderColor = isBanned ? colors.dutchie.flora : guest.Status?.Color || colors.primary;

  return {
    onClickCard,
    hasAssignedRegister,
    draggingStyles,
    borderColor,
    isBanned,
  };
};
