import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIdleTimer, IIdleTimer } from 'react-idle-timer';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { handleLogout } from '../../store/actions/UserActions';

import type { State } from '../../store';

// The amount of time to show the modal before ending the session automatically
const PROMPT_TIME = 60_000; // 1 minute

export default function IdleTimer() {
  // The location settings get loaded once the user is logged in
  const ldClient = useLDClient();
  const isUpdateLocationFromCodeEnabled = ldClient?.variation('pos.iam.update-location-from-code', false);
  const timeout = useSelector((state: State) =>
    isUpdateLocationFromCodeEnabled
      ? state.settings.locationSettings?.SessionTimeoutMinutesForRegister
      : state.settings.locationSettings?.SessionTimeoutMinutes
  );

  // If this is not yet present, we can exit early and not load the timer
  if (!timeout) {
    return <div data-testid='location-settings-not-present'></div>;
  }

  return <TrueIdleTimer />;
}

function TrueIdleTimer() {
  let idleTimer: IIdleTimer | null = null;
  const ldClient = useLDClient();
  const isUpdateLocationFromCodeEnabled = ldClient?.variation('pos.iam.update-location-from-code', false);
  const timeout = useSelector((state: State) =>
    isUpdateLocationFromCodeEnabled
      ? state.settings.locationSettings?.SessionTimeoutMinutesForRegister
      : state.settings.locationSettings?.SessionTimeoutMinutes
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  // This is just a type safety check, since this is guaranteed to be present when this component is inside of
  // the one above.
  if (!timeout) {
    throw Error('No session timeout value found. Is the user logged in?');
  }

  const onPrompt = () => {
    // Trigger notification
    setShowModal(true);
  };

  const onIdle = () => {
    dispatch(handleLogout({ history, logoutRequestOrigin: 'SessionTimeout' }));
  };

  const stayLoggedIn = () => {
    idleTimer?.reset();
    setShowModal(false);
  };

  idleTimer = useIdleTimer({
    stopOnIdle: true,
    timeout: timeout * 60_000 - PROMPT_TIME, // 1 minute less than specified, to allow the modal to show for 1 minute,
    promptTimeout: PROMPT_TIME,
    crossTab: true,
    onPrompt,
    onIdle,
  });

  return (
    <ConfirmationPopup
      automationId='session-idle-timer'
      isVisible={showModal}
      hide={stayLoggedIn}
      title={
        <>
          Your session will expire in <Countdown />
        </>
      }
      cancel={{
        text: 'Logout',
        onClick: onIdle,
      }}
      confirm={{
        text: 'Stay logged in',
        onClick: stayLoggedIn,
      }}
      children={
        <PopupText>
          <p>You will be automatically logged out. Do you want to continue your session?</p>
        </PopupText>
      }
    />
  );
}

function Countdown() {
  const [counter, setCounter] = useState(PROMPT_TIME / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    return () => {
      // Clear interval when the component unmounts
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {counter} second{counter > 1 ? 's' : ''}
    </>
  );
}

const PopupText = styled.div`
  padding: 0 1.5rem;
  text-align: center;
  margin-bottom: 12px;
`;
