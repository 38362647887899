import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { Button } from 'components/buttons';
import { PopupOverlay } from 'components/popups';
import { Loader } from 'components/backoffice/loader';
import { ModalContainer, FloatingModalHeader, ModalCloseButton, ModalContent, ModalFooter } from 'components/modals/Modal';
import scanAnimation from 'assets/animations/in-house-scanning/scan.json';
import { useScanPopup } from './useScanPopup';

type ScanPopupProps = {
  hide: () => void;
};

export function ScanPopup(props: ScanPopupProps) {
  const { hide } = props;

  const { error, isError, isLoading } = useScanPopup({ hide });

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer>
        <FloatingModalHeader>
          <ModalCloseButton onClick={hide} />
        </FloatingModalHeader>
        <ModalContent>
          <Content>
            {isLoading ? (
              <LoadingState>
                <Loader size='5x' variant='grey' />
                <LoadingText>Verifying</LoadingText>
              </LoadingState>
            ) : (
              <DefaultState>
                <DefaultText>Scan driver's license, state ID, or medical ID</DefaultText>
                <DefaultAnimationContainer>
                  <Lottie animationData={scanAnimation} loop={true} />
                </DefaultAnimationContainer>
                {isError && (
                  <AlertBannerContainer>
                    <AlertBanner style={AlertBannerStyles.error} title='Error:' text={`${error}`} thick />
                  </AlertBannerContainer>
                )}
              </DefaultState>
            )}
          </Content>
        </ModalContent>
        <ModalFooter>
          <Button cta disabled={isLoading} fullWidth onClick={hide}>
            Return to guest list
          </Button>
        </ModalFooter>
      </ModalContainer>
    </PopupOverlay>
  );
}

const Content = styled.div`
  width: 939px;
`;

const DefaultState = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 420px;
`;

const DefaultText = styled.span`
  display: block;
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 700;
  margin-bottom: 35px;
`;

const DefaultAnimationContainer = styled.div`
  width: 310px;
`;

const LoadingState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  height: 420px;
`;

const LoadingText = styled.span`
  display: block;
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

const AlertBannerContainer = styled.div`
  margin-bottom: 56px;
`;
