import { useState } from 'react';

import { CustomerTypeBadgeVariations } from 'components/cart/CustomerType';
import { useAppSelector } from 'util/hooks';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useLimitSummary } from 'components/cart/LegalLimit/useLimitSummary';
import { useUpdateCartTotals } from 'pages/CartPage/hooks/useUpdateCartTotals';

export const useCartFooter = () => {
  const [isTotalsDrawerOpen, setIsTotalsDrawerOpen] = useState(false);

  const isAnonymous = useAppSelector((state) => state.customer.details?.IsAnonymous) ?? true;
  const isMedicalCustomer = useAppSelector((state) => state.customer.details?.IsMedical ?? false);

  const { data: cart, isLoading: areCartTotalsLoading } = useGetCartDetails();
  const { isLoading: areUpdatedCartTotalsLoading } = useUpdateCartTotals();

  // Computed values

  const { allotmentSummary } = useLimitSummary();
  const isCartLoading = areCartTotalsLoading || areUpdatedCartTotalsLoading;
  const customerType = isMedicalCustomer
    ? CustomerTypeBadgeVariations.MEDICAL
    : CustomerTypeBadgeVariations.RECREATIONAL;

  const { isCompactLayout } = useCompactCartLayout();

  return {
    allotmentSummary,
    cart,
    customerType,
    isCartLoading,
    isTotalsDrawerOpen,
    isAnonymous,
    setIsTotalsDrawerOpen,
    showCartFooter: isCompactLayout,
  };
};
