import React, { FC } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { SectionWithHeader } from 'components/layout';
import { AvailableDiscount } from 'models/Discounts';
import { colors } from 'css/Theme';
import { callback1 } from 'models/Misc';

export type AvailableDiscountsProps = {
  selected?: AvailableDiscount;
  onSelect: callback1<AvailableDiscount>;
  availableDiscounts: Array<AvailableDiscount>;
};

export const AvailableDiscounts: FC<AvailableDiscountsProps> = ({ selected, onSelect, availableDiscounts }) => {
  return (
    <Section title='Available Discounts' empty={availableDiscounts.length === 0}>
      <ScrollableDiscountContainer>
        {availableDiscounts.map((item, idx) => (
          <Discount
            selected={selected?.ProductId === item.ProductId}
            onClick={() => onSelect(item)}
            key={item.ProductId}
            data-testid={`available-discounts_div_discount_${idx}`}
          >
            <DiscountInfo>
              <p data-testid={`avaible-discounts_div_discount-name_${idx}`}>{item.ProductDesc}</p>
              <p data-testid={`avaible-discounts_div_discount-value_${idx}`}>
                {item.FlatFee ? `$${item.FlatFee}` : `${item.PercentOff * 100}%`}
              </p>
            </DiscountInfo>
            <ProductInfo>
              <p data-testid={`avaible-discounts_div_discount-id_${idx}`}>{item.ProductId}</p>
              <p data-testid={`avaible-discounts_div_discount-date_${idx}`}>
                {format(new Date(item.ValidateDt), 'MM/dd/yyyy')}
              </p>
            </ProductInfo>
          </Discount>
        ))}
      </ScrollableDiscountContainer>
    </Section>
  );
};

const Section = styled(SectionWithHeader)`
  flex-grow: 1;
  min-height: 60vh;
`;

const ScrollableDiscountContainer = styled.div`
  height: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    height: 0;
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Discount = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.875rem 1.25rem;
  color: ${colors.dutchie.grey40};
  font-size: 1rem;
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 0.375rem;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  ${({ selected }) =>
    selected &&
    `
        color: ${colors.dutchie.almostBlack};
        border-color: ${colors.dutchie.almostBlack};
    `}

  &:hover {
    color: ${colors.dutchie.almostBlack};
  }
`;

const DiscountInfo = styled.div`
  text-align: left;
`;

const ProductInfo = styled.div`
  text-align: right;
`;
