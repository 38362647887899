import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Popup } from 'components/popups';
import { AlpineIQInfo } from 'models/AlpineIQ';
import { Loader } from 'components/backoffice/loader';
import { callback } from 'models/Misc';
import { CartItem } from 'models/Cart';
import { PhoneEntry } from './PhoneEntry';
import { MFAuthCodeEntry } from './MFAuthCodeEntry';
import { AlpineIQWallet } from './AlpineIQWallet';
import { colors } from 'css/Theme';
import { Button } from 'components/buttons';
import { useAppSelector } from 'util/hooks';

type AlpineIQRedeemPopupProps = {
  hide: callback;
  item?: CartItem;
};

export const AlpineIQRedeemPopup: FC<AlpineIQRedeemPopupProps> = ({ hide, item }) => {
  const [alpineIQInfo, setAlpineIQInfo] = useState<AlpineIQInfo | undefined>();

  const cellPhone = useAppSelector((state) => state.customer.details?.CellPhone);
  const phoneNo = useAppSelector((state) => state.customer.details?.PhoneNo);
  const email = useAppSelector((state) => state.customer.details?.Email);
  const checkCellPhoneFirst = useAppSelector(
    (state) => state.customer.details?.PreferredMethodOfContact === 'CellPhone'
  );

  const [phoneNumber, setPhoneNumber] = useState(
    checkCellPhoneFirst ? cellPhone ?? phoneNo ?? '' : phoneNo ?? cellPhone ?? ''
  );
  const [walletExist, setWalletExist] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <Popup caption='Redeem Alpine IQ rewards' hide={hide} isVisible small>
          <SpinnerContainer>
            <Loader size='2x' variant='black' />
          </SpinnerContainer>
        </Popup>
      ) : !walletExist && !alpineIQInfo ? (
        <Popup caption='Redeem Alpine IQ rewards' hide={hide} isVisible small>
          <PhoneEntry
            guest={{ CellPhone: cellPhone, PhoneNo: phoneNo, Email: email }}
            setWalletExist={setWalletExist}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            hide={hide}
            setAlpineIQInfo={setAlpineIQInfo}
          />
        </Popup>
      ) : walletExist && !alpineIQInfo ? (
        <Popup caption='Redeem Alpine IQ rewards' hide={hide} isVisible small>
          <MFAuthCodeEntry
            setLoading={setLoading}
            setWalletExist={setWalletExist}
            phoneNumber={phoneNumber}
            setAlpineIQInfo={setAlpineIQInfo}
            hide={hide}
          />
        </Popup>
      ) : (
        <Popup caption='Redeem Alpine IQ rewards' hide={hide} isVisible large>
          <AlpineIQWallet
            alpineIQInfo={alpineIQInfo}
            setAlpineIQInfo={setAlpineIQInfo}
            setLoading={setLoading}
            item={item}
          />
        </Popup>
      )}
    </>
  );
};

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  padding-bottom: 1.5rem;
`;

export const TopSection = styled.div`
  padding: 0 1.75rem;
`;

export const BottomSection = styled.div`
  border-top: 1px solid ${colors.dutchie.shadowGrey};
  margin: 1.5rem 0 0;
  padding: 1rem 1.75rem;
  display: flex;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)`
  border: none;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: underline;
  padding: 0;
`;
