import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { differenceInYears, format } from 'date-fns';
import { store, State } from 'store';
import { DatePickerField, InputField, SelectField } from 'components/inputs';
import { CreateAnonymousRequest } from 'models/Customer';
import { createAnonymousTransactionWithDemographics } from 'store/actions/GuestListActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { GenderOptions } from 'components/inputs/defaults';
import { NewAnonymousDemographicsModal } from './index.styles';


import type { FormikContextType } from 'formik';

const initialValues: CreateAnonymousRequest = {
  PostalCode: '',
  CustomerTypeId: 0,
  Dob: '',
  Gender: '',
};

type FormProps = Pick<FormikContextType<CreateAnonymousRequest>, 'dirty' | 'values' | 'validateForm'>;

type AnonymousTransactionPopupProps = {
  hide: () => void;
  dob?: Date;
};

export const AnonymousTransactionPopup: FC<AnonymousTransactionPopupProps> = ({ hide, dob }) => {
  const history = useHistory();
  const submitting = useSelector((state: State) => state.guestList.submitting);
  const settings = useSelector((state: State) => state.settings);
  const { features, locationSettings } = useSelector((state: State) => state.settings);
  const dispatch = useDispatch<typeof store.dispatch>();
  const [verifiedDob, setVerifiedDob] = useState<boolean>(false);

  useEffect(() => {
    if (features.ConfirmBirthdayOnCheckin && dob) {
      const formattedDob = format(new Date(dob), 'MM-dd-yyyy');
      initialValues.Dob = formattedDob;
      setVerifiedDob(true);
    }
  }, [features, dob]);

  useEffect(() => {
    if (locationSettings?.DefaultCustomerTypeId) {
      initialValues.CustomerTypeId = locationSettings.DefaultCustomerTypeId;
    }
  }, [locationSettings]);

  const customerFormSchema = () => {
    return Yup.object().shape({
      PostalCode: Yup.number().required(),
      CustomerTypeId: Yup.number().required().min(1),
      Dob: Yup.date()
        .max(new Date(new Date().setHours(0, 0, 0, 0)), 'Date of birth cannot be in the future')
        .required(),
      Gender: Yup.string().required(),
    });
  };

  const createTransaction = (values: CreateAnonymousRequest) => {
    if (features.BlockMinorsFromCheckInFF) {
      const guestAge = values.Dob;
      const defaultMinorAge = locationSettings?.DefaultMinorAge ? locationSettings?.DefaultMinorAge : 18;
      const guestYears = differenceInYears(new Date(), new Date(guestAge));

      if (guestYears < defaultMinorAge) {
        dispatch(errorNotification(`Patient is under the age of ${defaultMinorAge}. You cannot proceed`));
        return;
      }
    }

    dispatch(createAnonymousTransactionWithDemographics(values)).then(() => {
      hide();
      history.push(`/cart`);
    });
  };

  const customerTypes = settings.customerTypes
    ? settings.customerTypes.reduce(
        (acc, t) => {
          if (t.IsRetail && !t.IsMedical) {
            acc.push({ label: t.CustomerType, value: String(t.CustomerTypeId) });
          }
          return acc;
        },
        [] as Array<{
          value: string;
          label: string;
        }>
      )
    : [];

  return (
    <Formik<CreateAnonymousRequest>
      enableReinitialize
      initialValues={{ ...initialValues }}
      onSubmit={() => {}} // Datepicker may silently fail validation and block this so we validate + show errors on click
      validationSchema={customerFormSchema}
    >
      {({ dirty, values, validateForm }) => (
        <NewAnonymousDemographicsModal
          handleCancel={hide}
          handleSubmit={async () => {
            const errors = await validateForm();
            if (Object.keys(errors).length === 0) {
              createTransaction(values);
            } else {
              for (const message of Object.values(errors)) {
                dispatch(errorNotification(message ?? ''));
              }
            }
          }}
          isSubmitDisabled={submitting || !dirty}
        >
          <Form>
            <DatePickerField
              name='Dob'
              labelText='Date of birth'
              disabled={verifiedDob}
              placeholderText='mm/dd/yyyy'
              showMonthDropdown
              showYearDropdown
              automationId='anonymous-transaction-popup_select-field_dob'
            />
            <SelectField
              name='Gender'
              label='Gender'
              placeholder='Select gender...'
              options={GenderOptions}
              automationId='anonymous-transaction-popup_select-field_gender'
            />
            <InputField
              name='PostalCode'
              label='Postal code'
              placeholder='Enter postal code...'
              type='number'
              automationId='anonymous-transaction-popup_input-field_postal-code'
            />
            <SelectField
              name='CustomerTypeId'
              label='Customer type'
              placeholder='Select customer type...'
              options={customerTypes}
              automationId='anonymous-transaction-popup_select-field_customer-type'
            />
          </Form>
        </NewAnonymousDemographicsModal>
      )}
    </Formik>
  );
};
