import { MutableRefObject, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { FormikErrors, FormikProps, FormikValues } from 'formik';
import { isBefore, isSameDay, parseISO, differenceInYears, isValid } from 'date-fns';
import queryString from 'query-string';

import { State } from 'store';
import { BuildCreateCustomerRequest } from 'util/CustomerRequestBuilder';
import { CustomerDetails, CheckInCustomerResponse } from 'models/Customer';
import { checkDuplicates } from 'api/CustomerApi';
import { biotrackScanGuestIn } from 'api/GuestApi';
import { getMinorAge, isMMJLicenseAboutExpire, mmjLicenseDaysFromExpiration } from 'util/Helpers';
import { createCustomer, updateCustomerDetails, checkInCustomer } from 'store/actions/CustomerActions';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { setCheckedInGuest } from 'store/actions/CartActions';
import { useCheckPreorderEligible, usePatientPolicyValidator, useAppDispatch } from 'util/hooks';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';
import { useShouldBypassDOBCheck } from 'util/hooks/useShouldBypassDOBCheck';
import {
  showCheckinCaregiverPopup,
  showConvertToNonAnonymousPopup,
  showCreatePreOrderFromProfilePopup,
  showSelectCollectorFromProfilePopup,
  showStartOrderPopup,
  showVerifyBirthdateFromProfilePopup,
  showPotentialDuplicatePopup,
} from 'store/actions/PopupsActions';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

type UseEditCustomerActionsProps = {
  isCreatingNewCustomer: boolean;
  formRef: MutableRefObject<FormikProps<CustomerDetails> | null>;
  policyTabIndex: number;
  guestId?: number;
};
export const useEditCustomerActions = ({
  isCreatingNewCustomer,
  formRef,
  policyTabIndex,
  guestId,
}: UseEditCustomerActionsProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory<{ fromCart?: boolean; fromCancel?: boolean }>();
  const patientPolicyValidator = usePatientPolicyValidator();
  const checkPreorderEligible = useCheckPreorderEligible();

  const { goToCart } = useTransactionManager();

  const selectedRegister = useSelector((state: State) => state.settings.selectedRegister);
  const customerDetails = useSelector((state: State) => state.customer.details);
  const locationSettings = useSelector((state: State) => state.settings.locationSettings);
  const integrations = useSelector((state: State) => state.settings.integrations);
  const isMassDPHCredsValid = useSelector((state: State) => state.settings.MassDPHCredsValid);

  // Feature flags
  const isAllowInvalidCustomerCheckInEnabled = useSelector(
    (state: State) => state.settings.features.AllowInvalidCustomerCheckIn
  );
  const isRequireDoctorForCheckInEnabled = useSelector(
    (state: State) => state.settings.features.RequireDoctorForCheckIn
  );
  const isRequirePrescriptionForCheckInEnabled = useSelector(
    (state: State) => state.settings.features.RequirePrescriptionForCheckIn
  );
  const isRequireValidDLEnabled = useSelector((state: State) => state.settings.features.RequireValidDL);
  const isBlockMinorsFromCheckInEnabled = useSelector(
    (state: State) => state.settings.features.BlockMinorsFromCheckInFF
  );
  const isMMJIdExpirationDateCheckEnabled = useSelector(
    (state: State) => state.settings.features.MMJIdExpirationDateCheck
  );
  const isAttestationEnabled = useSelector((state: State) => state.settings.features.Attestation);
  const isPOSNoteUnder18Enabled = useSelector((state: State) => state.settings.features.POSNoteUnder18);
  const isPOSNoteUnder21Enabled = useSelector((state: State) => state.settings.features.POSNoteUnder21);
  const isNotifyCard30DayExpirationEnabled = useSelector(
    (state: State) => state.settings.features.NotifyCard30DayExpiration
  );
  const isRequireValidAllotmentCredentialsEnabled = useSelector(
    (state: State) => state.settings.features.RequireValidAllotmentCredentials
  );
  const isBlockBannedPatientCheckInEnabled = useSelector(
    (state: State) => state.settings.features.BlockBannedPatientCheckin
  );
  const isCollectAnonymousDemoEnabled = useSelector((state: State) => state.settings.features.CollectAnonymousDemo);
  const isConfirmBirthdayOnCheckinEnabled = useSelector(
    (state: State) => state.settings.features.ConfirmBirthdayOnCheckin
  );

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const [isActionsLoading, setIsActionsLoading] = useState<boolean>(false);
  const [birthdateVerified, setBirthdateVerified] = useState<boolean>(false);

  const isCheckedIn = customerDetails ? customerDetails.ShipmentId > 0 : false;
  const isUseMnEnabled = useIsUseMnEnabled(customerDetails);
  const isMedical = customerDetails?.IsMedical ?? false;

  // Birthdate check

  const shouldBypassDOBCheck = useShouldBypassDOBCheck({ customerIsMedical: customerDetails?.IsMedical ?? false });

  const needBirthdateCheck = useMemo(() => {
    const isPageRoutedFromCart = history.location?.state?.fromCart ?? false;
    return (
      !(isPageRoutedFromCart && isAnonymousCartLDFlagEnabled) &&
      !birthdateVerified &&
      isConfirmBirthdayOnCheckinEnabled &&
      !shouldBypassDOBCheck
    );
  }, [
    history.location,
    isAnonymousCartLDFlagEnabled,
    birthdateVerified,
    isConfirmBirthdayOnCheckinEnabled,
    shouldBypassDOBCheck,
  ]);

  // Show header actions

  const showCheckInCaregiverButton = !needBirthdateCheck && !isCheckedIn && isMedical;
  const showCheckInButton = !needBirthdateCheck && !isCheckedIn;
  const showCreateOrderButton = !needBirthdateCheck && !isCreatingNewCustomer;
  const showVerifyDOBButton = needBirthdateCheck;
  const showConvertToNonAnonymousButton = customerDetails?.IsAnonymous;

  const canChooseOrderType = checkPreorderEligible(guestId);
  // If they're already checked in, just always go back to the cart. Don't show Start an order modal
  const showStartOrderModal = canChooseOrderType && (!isCheckedIn || !isAnonymousCartLDFlagEnabled);

  const handleCreateOrUpdateCustomer = async (values: CustomerDetails) => {
    if (isCreatingNewCustomer) {
      const createCustomerRequest = BuildCreateCustomerRequest(values);
      const existingCustomerDetails = await checkDuplicates(createCustomerRequest);
      const isExistingCustomerArchived = existingCustomerDetails?.status === 'Archived';
      const newCustomerDetails = formRef?.current?.values;

      if (existingCustomerDetails && !isExistingCustomerArchived && newCustomerDetails) {
        dispatch(showPotentialDuplicatePopup({ existingCustomerDetails, newCustomerDetails }));
        return false;
      }

      values.ReferralSourceId = null;
      if (newCustomerDetails) {
        const createdCustomer = await dispatch(createCustomer(newCustomerDetails)).unwrap();
        if (createdCustomer) {
          history.push({ search: `?id=${createdCustomer.Guest_id}` });
        }
      }

      return false;
    } else {
      const response = await dispatch(updateCustomerDetails(values)).unwrap();

      return response && response.success;
    }
  };

  const handleCheckIn = async ({
    andEnterCart = false,
    andEnterPreOrderCart = false,
    caregiverId = undefined,
    validate = undefined,
  }: {
    andEnterCart?: boolean;
    andEnterPreOrderCart?: boolean;
    caregiverId?: string;
    validate?: () => Promise<FormikErrors<FormikValues>>;
  }) => {
    setIsActionsLoading(true);
    let validationError = false;
    const guestAge = customerDetails?.DOB ? customerDetails?.DOB : new Date();

    if (!isAllowInvalidCustomerCheckInEnabled && validate) {
      const resp = await validate();
      if (Object.keys(resp).length !== 0) {
        for (const error in resp) {
          dispatch(errorNotification(`${error} is invalid`));
        }
        setIsActionsLoading(false);
        return;
      }
    }

    if (isRequireDoctorForCheckInEnabled) {
      if (customerDetails && customerDetails.IsMedical && !(parseInt(customerDetails.doctor) > 0)) {
        validationError = true;
        dispatch(errorNotification('Patient does not have a doctor on record.'));
      }
    }

    if (isRequirePrescriptionForCheckInEnabled) {
      if (customerDetails && !customerDetails.ValidPrescription) {
        validationError = true;
        dispatch(errorNotification('Patient does not have a valid prescription on record.'));
      }
    }

    if (!customerDetails?.CustomerTypeId) {
      validationError = true;
      dispatch(errorNotification('Patient does not have a customer type on record.'));
    }

    if (isRequireValidDLEnabled) {
      const hasId = customerDetails?.identifications[0].number;
      const isLicenseExpired =
        !customerDetails || isBefore(new Date(customerDetails.identifications[0].ExpirationDate), Date.now());
      if (!hasId || isLicenseExpired) {
        validationError = true;
        dispatch(errorNotification("Customer driver's license missing or expired"));
      }
    }

    if (isMMJIdExpirationDateCheckEnabled && customerDetails?.IsMedical) {
      const hasMMJId = customerDetails?.identifications[1].number;
      const isMMJIdExpired =
        !customerDetails ||
        (isBefore(new Date(customerDetails.identifications[1].ExpirationDate), Date.now()) &&
          !isSameDay(new Date(customerDetails.identifications[1].ExpirationDate), Date.now()));

      if (!hasMMJId || isMMJIdExpired) {
        validationError = true;
        dispatch(errorNotification('Customer MMJ Id missing or expired'));
      }
    }

    if (isBlockMinorsFromCheckInEnabled) {
      const defaultMinorAge = getMinorAge(locationSettings, customerDetails?.IsMedical || false);

      const guestYears = differenceInYears(new Date(), new Date(guestAge));

      if (
        guestYears < defaultMinorAge &&
        !caregiverId &&
        (!customerDetails?.IsAnonymous || isCollectAnonymousDemoEnabled)
      ) {
        validationError = true;
        dispatch(errorNotification(`Patient is under the age of ${defaultMinorAge}. You cannot proceed`));
      }
    }

    if (
      isMMJIdExpirationDateCheckEnabled &&
      customerDetails?.IsMedical &&
      isNotifyCard30DayExpirationEnabled &&
      isMMJLicenseAboutExpire(customerDetails)
    ) {
      const days = mmjLicenseDaysFromExpiration(customerDetails);
      dispatch(warningNotification(`Patient's MMJ License is about to expire in ${days} days.`));
    }

    if (customerDetails?.CustomerTypeId === 1 && isAttestationEnabled) {
      if (customerDetails?.Attestation.toLowerCase() === 'no') {
        validationError = true;
        dispatch(errorNotification('Patient has not completed the Attestation document. You cannot proceed.'));
      }

      if (
        isBefore(parseISO(customerDetails?.AttestationExpirationDate), new Date()) ||
        isSameDay(parseISO(customerDetails?.AttestationExpirationDate), new Date())
      ) {
        validationError = true;
        dispatch(errorNotification('Patient Attestation document has expired. You cannot proceed.'));
      }
    }

    if (
      isPOSNoteUnder18Enabled &&
      (differenceInYears(new Date(), new Date(guestAge)) < 18 || !isValid(new Date(guestAge)))
    ) {
      dispatch(warningNotification('Patient DOB not valid or patient is under 18'));
    } else if (
      isPOSNoteUnder21Enabled &&
      (differenceInYears(new Date(), new Date(guestAge)) < 21 || !isValid(new Date(guestAge)))
    ) {
      dispatch(warningNotification('Patient DOB not valid or patient is under 21'));
    }

    if (andEnterCart || andEnterPreOrderCart) {
      if (!selectedRegister) {
        validationError = true;
        dispatch(errorNotification('Please select a register first'));
      }

      if (
        integrations?.CanUseMassDPHIntegration &&
        customerDetails?.PatientType !== 'Recreational' &&
        isRequireValidAllotmentCredentialsEnabled &&
        !isMassDPHCredsValid
      ) {
        validationError = true;
        dispatch(errorNotification('Your DPH Credentials are invalid! Go to settings and update to continue.'));
      }
    }

    if (customerDetails?.status !== 'Active') {
      validationError = isBlockBannedPatientCheckInEnabled && customerDetails?.status === 'Banned';
      dispatch(errorNotification(`Patient is ${customerDetails?.status}.`));
    }

    if (customerDetails && !(await patientPolicyValidator(customerDetails))) {
      dispatch(errorNotification(`Patient must sign the patient policy`));
      const search = queryString.parse(history.location.search);
      history.push({
        search: queryString.stringify({ ...search, tabIndex: policyTabIndex }),
      });
      validationError = true;
    }

    if (validationError) {
      setIsActionsLoading(false);
      return;
    }

    if (customerDetails && selectedRegister) {
      if (andEnterPreOrderCart) {
        //special case.  We don't actually check the customer in when creating a preorder, so just navigate to the preorder page.
        history.push(`/create-preorder`);
        setIsActionsLoading(false);
        return;
      }
      let checkedInShipmentId = 0;
      let checkedInScheduleId = 0;
      if (!isCheckedIn) {
        const response = await dispatch(
          checkInCustomer({
            guestId: customerDetails.Guest_id,
            caregiverId: caregiverId,
            mjStateId: customerDetails.MJStateIDNo,
            register: customerDetails.CurrentRegister,
            roomId: customerDetails.CurrentRoom,
          })
        );

        // ENG-41650 - payload might be null when checking in a Medical customer
        // into a Rec only location
        if (!response.payload) {
          setIsActionsLoading(false);
          return;
        }

        const [checkInCustomerRes] = response.payload as [CheckInCustomerResponse];
        checkedInShipmentId = checkInCustomerRes.ShipmentId;
        checkedInScheduleId = checkInCustomerRes.ScanResult;
      } else {
        checkedInShipmentId = customerDetails?.ShipmentId;
        checkedInScheduleId = parseInt(customerDetails?.ScheduleId);
      }

      if (checkedInShipmentId && checkedInScheduleId) {
        if (integrations?.UseBioTrackPOS) {
          await biotrackScanGuestIn({
            PatientScan: customerDetails.MJStateIDNo,
            ShipmentId: checkedInShipmentId,
          });
        }
        if (andEnterCart) {
          dispatch(
            setCheckedInGuest({
              ShipmentId: checkedInShipmentId,
              Guest_id: customerDetails.Guest_id,
              TransactionCaregiver: caregiverId,
              ScheduleId: checkedInScheduleId,
            })
          );
          if (isUseMnEnabled) {
            dispatch(
              showSelectCollectorFromProfilePopup({
                customerId: customerDetails.Guest_id,
                shipmentId: checkedInShipmentId,
              })
            );
          } else {
            // Passing false here because we don't want the customer to be cleared.
            // If we clear the customer, then there's a possible race condition with the
            // unsaved changes modal resulting in the modal not being popped when it should be.
            // We don't have to clear the customer in this case because you can only enter the
            // current guest's cart from this page
            goToCart({ guestId: customerDetails.Guest_id, shipmentId: checkedInShipmentId }, false);
          }
        } else {
          history.push(`/guestlist`);
        }
      }
    }
    setIsActionsLoading(false);
  };

  const handleCreateOrder = async () => {
    const validate = formRef?.current?.validateForm;
    if (showStartOrderModal) {
      dispatch(
        showStartOrderPopup({
          onGoToCart: () => handleCheckIn({ andEnterCart: true, validate }),
          onCreatePreorder: async () => {
            dispatch(
              showCreatePreOrderFromProfilePopup({
                onCheckIn: () => {
                  handleCheckIn({ andEnterPreOrderCart: true, validate });
                },
              })
            );
          },
        })
      );
    } else {
      await handleCheckIn({ andEnterCart: true, validate });
    }
  };

  const handleClickCancel = () => {
    history.push({
      pathname: '/guestlist',
      state: { fromCancel: true },
    });
  };

  const createOrSave = async (
    values: CustomerDetails,
    validateForm: (values?: CustomerDetails) => Promise<FormikErrors<CustomerDetails>>
  ) => {
    return (
      (await validateForm().then(async (errors) => {
        if (Object.keys(errors).length === 0) {
          return await handleCreateOrUpdateCustomer(values);
        } else {
          for (const error in errors) {
            dispatch(errorNotification(`${error} is invalid`));
          }
          return false;
        }
      })) ?? false
    );
  };

  const handleClickCreateOrSave = (
    e: React.MouseEvent<HTMLButtonElement>,
    values: CustomerDetails,
    validateForm: (values?: CustomerDetails) => Promise<FormikErrors<CustomerDetails>>
  ) => {
    e.preventDefault();
    createOrSave(values, validateForm);
  };

  const handleClickVerifyDOB = () => {
    dispatch(
      showVerifyBirthdateFromProfilePopup({
        guestDob: customerDetails?.DOB ?? '',
        isAnonymous: customerDetails?.IsAnonymous ?? false,
        onSuccess: () => setBirthdateVerified(true),
        acctId: customerDetails?.Guest_id,
      })
    );
  };

  const handleClickCheckInCaregiver = () => {
    if (customerDetails?.Guest_id) {
      dispatch(showCheckinCaregiverPopup({ guestId: customerDetails.Guest_id, onCheckin: handleCheckIn }));
    }
  };

  const handleClickConvertToNonAnonymous = () => {
    if (customerDetails) {
      // Button doesn't even show if customer details is undefined, so this should always be true
      dispatch(showConvertToNonAnonymousPopup({ customer: customerDetails }));
    }
  };

  return {
    isActionsLoading,
    showCheckInButton,
    showCheckInCaregiverButton,
    showCreateOrderButton,
    showVerifyDOBButton,
    showConvertToNonAnonymousButton,
    handleClickCancel,
    handleCheckIn,
    handleCreateOrUpdateCustomer,
    handleCreateOrder,
    createOrSave,
    handleClickCreateOrSave,
    handleClickVerifyDOB,
    handleClickCheckInCaregiver,
    handleClickConvertToNonAnonymous,
  };
};
