import { SerialScale, ScaleMeasurement, SerialConnectionOptions, HardwareService, DeviceScaleMeasurement, isSerialScale } from "@dutchie/capacitor-hardware";
import { useState, useEffect, useCallback, useRef } from "react";
import { logger, customEventKeys } from "util/logger";
import { SelectOption } from "components/inputs";
import { useAppDispatch } from "util/hooks";
import { successNotification, errorNotification } from "store/actions/NotificationsActions";
import { isEqual } from "lodash";

const BaudRateValues = [600, 1200, 2400, 4800, 9600, 19200] as const;
type BaudRate = typeof BaudRateValues[number];

const DataBitValues = [8, 7] as const;
type DataBits = typeof DataBitValues[number];

const ParityValues = ['none', 'even', 'odd'] as const;
type Parity = typeof ParityValues[number];

const StopBitValues = [1, 2] as const;
type StopBits = typeof StopBitValues[number];

enum ConfigurationStatus {
  idle = 'idle',
  configuring = 'configuring',
  success = 'success',
}

type SetConfigurationDropdownValue = {
  baudRate: string;
  dataBits: string;
  parity: string;
  stopBits: string;
};

type SerialScaleConfigurationData = {
  autoConfigureScale: (props: { onSuccess: () => void }) => Promise<void>;
  formConfiguration: SerialConnectionOptions;
  hasChanges: boolean;
  isConfiguring: boolean;
  measurement: ScaleMeasurement | undefined;
  onCloseModal: () => void;
  options: {
    baudRate: SelectOption[];
    dataBits: SelectOption[];
    parity: SelectOption[];
    stopBits: SelectOption[];
  };
  saveChanges: () => void;
  setConfigurationOption: (option: Partial<SetConfigurationDropdownValue>) => void;
};

export const useSerialScaleConfiguration = ({ scale }: { scale: SerialScale }): SerialScaleConfigurationData => {
  const dispatch = useAppDispatch();

  const [measurement, setMeasurement] = useState<ScaleMeasurement | undefined>();

  const lastSavedConfiguration = useRef<SerialConnectionOptions>(scale.options);
  const [formConfiguration, setFormConfiguration] = useState<SerialConnectionOptions>(scale.options);

  const hasChanges = !isEqual(lastSavedConfiguration.current, formConfiguration);

  const [status, setStatus] = useState<ConfigurationStatus>(ConfigurationStatus.idle);
  const isConfiguring = status === 'configuring';

  // Track disposal to stop the auto-config detection loop if not yet complete
  const isDisposed = useRef(false);

  const options = {
    baudRate: BaudRateValues.map(bps => ({ label: `${bps}`, value: `${bps}` })),
    dataBits: DataBitValues.map(bits => ({ label: `${bits}`, value: `${bits}` })),
    stopBits: StopBitValues.map(bits => ({ label: `${bits}`, value: `${bits}` })),
    parity: ParityValues.map(parity => ({ label: `${parity}`, value: `${parity}` })),
  };

  const getTestConfigurations = useCallback(() => {
    const eloDefaultOptions: SerialConnectionOptions = {
      baudRate: 9600,
      dataBits: 8,
      parity: 'none',
      stopBits: 1,
    };

    const aNdDefaultOptions: SerialConnectionOptions = {
      baudRate: 2400,
      dataBits: 7,
      parity: 'even',
      stopBits: 1,
    };

    // build list of test cases
    const testOptions: SerialConnectionOptions[] = [lastSavedConfiguration.current, aNdDefaultOptions, eloDefaultOptions];
    for (const stopBit of StopBitValues) {
      // Prioritize assumed common baud rates
      for (const bps of [2400, 9600, 19200, 4800, 1200, 600]) {
        for (const dataBits of DataBitValues) {
          for (const parity of ParityValues) {
            testOptions.push({ baudRate: bps, dataBits, parity, stopBits: stopBit });
          }
        }
      }
    }

    return testOptions;
  }, []);

  const connectWithOptions = useCallback(async (options: SerialConnectionOptions) => {
    try {
      await scale.disconnect();
    } catch (e) {
      /* no-op */
    }

    try {
      scale.options = options;
      await scale.connect();
    } catch (e) {
      logger.error(e, { message: 'Failed to connect to scale' });
    }
  }, [scale]);

  const saveChanges = useCallback(async (options?: SerialConnectionOptions) => {
    const newOptions = options ?? formConfiguration;
    lastSavedConfiguration.current = newOptions;
    setFormConfiguration(newOptions);
    await connectWithOptions(newOptions);
  }, [connectWithOptions, formConfiguration]);

  const autoConfigureScale = useCallback(async ({ onSuccess }: { onSuccess: () => void }) => {
    if (isConfiguring) {
      return;
    }

    // Attempt to speed up detection by waiting less time for faster baud rates
    const waitMsByBps: Record<number, number> = {
      600: 1200,
      1200: 1000,
      2400: 1000,
      4800: 800,
      9600: 800,
      19200: 800,
    };

    logger.debug('start auto-configure serial scale', {
      key: customEventKeys.settings.scale.autoConfigureStart,
    });

    let workingConfiguration: SerialConnectionOptions | undefined;

    try {
      setStatus(ConfigurationStatus.configuring);

      const cases = getTestConfigurations();
      for (const options of cases) {
        // Stop loop when disposed
        if (isDisposed.current) {
          break;
        }

        try {
          await connectWithOptions(options);
          const result = await scale.requestMeasurement({ waitMs: waitMsByBps[options.baudRate] });
          if (result != null) {
            setFormConfiguration(options);
            workingConfiguration = options;
            break;
          }
        } catch (e) {
          /* handle any failures that may come from requestMeasurement */
        }
      }
    } catch (e) {
      logger.error(e, {
        message: 'Failed to auto-configure scale',
      });
    } finally {
      const success = !!workingConfiguration;
      setStatus(success ? ConfigurationStatus.success : ConfigurationStatus.idle);
      logger.debug(`scale configuration ${success ? 'successful' : 'failed'}`, {
        key: customEventKeys.settings.scale.autoConfigureSuccess,
        result: {
          success,
          configuration: workingConfiguration,
        },
      });

      if (success) {
        dispatch(successNotification('Scale configuration successful'));
        await saveChanges(workingConfiguration);
        onSuccess();
      } else {
        if (!isDisposed.current) {
          dispatch(errorNotification('Scale configuration failed'));
        }
      }
    }

  }, [connectWithOptions, dispatch, getTestConfigurations, isConfiguring, saveChanges, scale]);

  const onCloseModal = () => {
    // When closing the modal, ensure configuration is set. This is necessary because the configuration
    // is updated on the scale object every time we try a new configuration option, but if not saved we
    // should revert to the original or last saved configuration.

    const currentScaleOptions = HardwareService.scale.devices
      .filter(it => isSerialScale(it))
      .map(it => it as SerialScale)
      .find(it => it.id === scale.id)
      ?.options;

    if (!isEqual(currentScaleOptions, lastSavedConfiguration.current)) {
      connectWithOptions(lastSavedConfiguration.current);
    }
  };

  const setConfigurationOption = (option: Partial<SetConfigurationDropdownValue>) => {
    const options = {
      baudRate: option.baudRate ? parseInt(option.baudRate) as BaudRate : formConfiguration.baudRate,
      dataBits: option.dataBits ? parseInt(option.dataBits) as DataBits : formConfiguration.dataBits,
      parity: option.parity ? option.parity as Parity : formConfiguration.parity,
      stopBits: option.stopBits ? parseInt(option.stopBits) as StopBits : formConfiguration.stopBits,
    };

    setMeasurement(undefined);
    setFormConfiguration(options);
    connectWithOptions(options);
  };

  useEffect(() => {
    const handleMeasurementEvent = (e: Event) => {
      const event = e as CustomEvent<DeviceScaleMeasurement>;
      setMeasurement(event.detail.measurement);
    };

    HardwareService.scale.addEventListener('measurement', handleMeasurementEvent);
    return () => {
      HardwareService.scale.removeEventListener('measurement', handleMeasurementEvent);
    };
  }, []);

  useEffect(() => {
    isDisposed.current = false;
    return () => {
      isDisposed.current = true;
    };
  }, []);

  return {
    autoConfigureScale,
    formConfiguration,
    hasChanges,
    isConfiguring,
    measurement,
    onCloseModal,
    options,
    saveChanges,
    setConfigurationOption,
  };
};
