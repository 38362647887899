import React from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useCfdSettings } from '../hooks/useCfdSettings';
import { Switch } from 'components/inputs';

export const CfdOpenOnLoginListItem = () => {
  const { formValues, setOpenOnLogin } = useCfdSettings();
  const isOpenOnLogin = formValues.openCfdOnLogin;

  return (
    <SettingsListItem
      title='Open window upon login'
      subtitle='When enabled, customer-facing display will open automatically on login'
      actions={[<Switch checked={isOpenOnLogin} containerMargin='0' onChange={() => setOpenOnLogin(!isOpenOnLogin)} />]}
    />
  );
};
