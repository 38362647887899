import { useAppSelector } from 'util/hooks';

import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { getUsedPercentageData } from '../index';

import type { MMCEULimitProps } from './MMCEULimit';

export const useMMCEULimit = ({ preorderCartItems }: MMCEULimitProps) => {
  const guestAllotmentData = useAppSelector((state) => state.customer.details?.AllotmentData);

  const { data: cartDetails } = useGetCartDetails();

  const preorderMMCEU =
    preorderCartItems?.reduce((value, preorderCartItem) => {
      if (preorderCartItem?.flowerEquivalent === undefined) {
        return value;
      }
      return value + preorderCartItem?.flowerEquivalent / 3.5;
    }, 0.0) ?? 0;

  const inCartMMCEU =
    cartDetails.Cart.reduce((value, cartItem) => {
      if (cartItem?.Equivalent === undefined) {
        return value;
      }
      return value + cartItem.Equivalent / 3.5;
    }, 0.0) ?? 0;

  const mmceuInCart = preorderCartItems ? preorderMMCEU : inCartMMCEU;
  const mmceuRemaining = (guestAllotmentData?.TotalRemaining ?? 0) / 3.5;
  const { usedPercentage, over } = getUsedPercentageData(mmceuInCart, mmceuRemaining);

  const supplyLabel = (value: number) => `${value.toFixed(2).replace(/[.,]00$/, '')} MMCEU`;

  return {
    mmceuInCart: supplyLabel(mmceuInCart),
    mmceuRemaining: supplyLabel(mmceuRemaining),
    over,
    usedPercentage,
  };
};
