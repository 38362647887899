import React from 'react';
import styled from 'styled-components';

export const InvalidBarcode = () => {
  return (
    <MessageContainer>
      Scanner did not recognize a valid barcode. <br/>
      Try scanning again or manually add item to cart.
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  line-height: 30px;
`;
