import React, { SVGProps, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { Button } from 'components/buttons/Button';
import { Menu } from 'components/backoffice/menu';

import { colors } from 'css/Theme';

export type ButtonWithDropDownProps<T> = {
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  buttonHeight?: string;
  buttonPadding?: string;
  secondary?: boolean;
  tertiary?: boolean;
  cta?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  items: T[];
  dropDownWidth?: number | string;
  customItemRender: (item: T, closeDropdown: () => void) => JSX.Element | null;
  automationId?: string;
};

export const ButtonWithDropDown = <ItemType extends unknown>({
  icon,
  buttonHeight,
  buttonPadding,
  secondary,
  tertiary,
  cta,
  disabled,
  children,
  items,
  dropDownWidth,
  customItemRender,
  automationId,
}: ButtonWithDropDownProps<ItemType>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(menuOpen ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Icon = icon ?? null;

  return (
    <>
      <DropdownButton
        height={buttonHeight}
        padding={buttonPadding}
        secondary={secondary}
        tertiary={tertiary}
        cta={cta}
        disabled={disabled}
        onClick={handleClick}
        automationId={`${automationId}_button`}
      >
        {Icon && <Icon className='drop-down_icon' />}
        <DropdownButtonLabel data-testid={`${automationId}_button-label`}>{children}</DropdownButtonLabel>
        {menuOpen ? <ArrowUp className='drop-down_icon' /> : <ArrowDown className='drop-down_icon' />}
      </DropdownButton>
      <StyledMenu
        width={dropDownWidth ?? anchorEl?.offsetWidth}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        data-testid={`${automationId}_dropdown-menu`}
      >
        {items.map((item) => customItemRender(item, handleClose))}
      </StyledMenu>
    </>
  );
};

const DropdownButton = styled(Button)<{
  height?: string;
  padding?: string;
  secondary?: boolean;
  tertiary?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  height: ${({ height }) => height ?? '2.5rem'};
  ${({ padding }) => padding && `padding: ${padding};`}

  .drop-down_icon {
    path {
      fill: ${colors.dutchie.primaryWhite};
    }
  }

  ${({ secondary, tertiary }) =>
    secondary
      ? `
    .drop-down_icon {
      path {
        fill: ${colors.dutchie.darkGrey};
      }
    }
  `
      : tertiary
      ? `
  .drop-down_icon {
    path {
      fill: ${colors.dutchie.gunmetal};
    }
  }
  `
      : ''}

  &:disabled {
    .drop-down_icon {
      opacity: 0.5;
    }
  }
`;

const DropdownButtonLabel = styled.div`
  margin-right: auto;
`;

const StyledMenu = styled(Menu)<{ width?: string }>`
  top: 0.25rem;
  .MuiPopover-paper {
    width: ${({ width }) => (width ? `${width}` : '100%')};
  }
`;
