import { PaymentMethod, PaymentType } from 'models/Checkout';
import { Dispatch, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { addPaymentMethod, updatePaymentMethod } from 'store/actions/CheckoutActions';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { v4 as uuidv4 } from 'uuid';
import { CheckoutContext } from '../../../CheckoutContext';

type UsePaymentMMAPReturn = {
  handleAddMMAPPaymentAmount: (amount: number) => void;
};

export function usePaymentMMAP(): UsePaymentMMAPReturn {
  // global state
  const paymentMethods = useSelector((state: State) => state.checkout.payment?.methods);
  const totalRemaining = useSelector((state: State) => state.checkout.totalRemaning);

  // hooks
  const dispatch = useDispatch();
  const { setSelectedPaymentType } = useContext(CheckoutContext);

  // handlers
  const handleAddMMAPPaymentAmount = useCallback(
    (amount: number) => {
      if (amount <= 0) {
        dispatch(errorNotification('Invalid MMAP amount.'));
        return;
      }

      const isAppliedAmountGreaterThanTotalRemaining = amount > totalRemaining;
      const amountToApply = isAppliedAmountGreaterThanTotalRemaining ? totalRemaining : amount;

      const index = paymentMethods?.findIndex((paymentMethod) => paymentMethod.name === 'MMAP') ?? -1;

      const isMMAPPaymentAlreadyInPaymentMethods = index >= 0;

      if (isMMAPPaymentAlreadyInPaymentMethods) {
        updateExistingMMAPPayment({
          amountToApply,
          dispatch,
          index,
          isAppliedAmountGreaterThanTotalRemaining,
          paymentMethods,
        });
      } else {
        addNewMMAPPayment({ amountToApply, dispatch, isAppliedAmountGreaterThanTotalRemaining });
      }
      setSelectedPaymentType(null);
    },
    [dispatch, paymentMethods, setSelectedPaymentType, totalRemaining]
  );

  return { handleAddMMAPPaymentAmount };
}

type UpdateExistingMMAPPaymentParams = {
  amountToApply: number;
  dispatch: Dispatch<unknown>;
  index: number;
  isAppliedAmountGreaterThanTotalRemaining: boolean;
  paymentMethods: PaymentMethod[] | undefined;
};

function updateExistingMMAPPayment({
  amountToApply,
  dispatch,
  index,
  isAppliedAmountGreaterThanTotalRemaining,
  paymentMethods,
}: UpdateExistingMMAPPaymentParams): void {
  const oldAmount = paymentMethods?.[index].amount ?? 0;
  const newAmount = amountToApply + oldAmount;
  const payload = {
    index,
    method: { id: uuidv4(), name: 'MMAP', type: PaymentType.MMAP, amount: newAmount },
  };

  dispatch(updatePaymentMethod(payload));

  if (isAppliedAmountGreaterThanTotalRemaining) {
    dispatch(warningNotification('Cannot apply MMAP payments that exceed total remaining to be paid.'));
  } else {
    dispatch(successNotification('MMAP Payment updated'));
  }
}

type AddNewMMAPPaymentParams = Omit<UpdateExistingMMAPPaymentParams, 'index' | 'paymentMethods'>;

function addNewMMAPPayment({
  amountToApply,
  dispatch,
  isAppliedAmountGreaterThanTotalRemaining,
}: AddNewMMAPPaymentParams): void {
  dispatch(addPaymentMethod({ id: uuidv4(), name: 'MMAP', type: PaymentType.MMAP, amount: amountToApply }));

  if (isAppliedAmountGreaterThanTotalRemaining) {
    dispatch(warningNotification('Cannot apply MMAP payments that exceed total remaining to be paid.'));
  } else {
    dispatch(successNotification('MMAP Payment added'));
  }
}
