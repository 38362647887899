import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { breakpoints, colors } from 'css/Theme';
import { Row, Key, Value } from '../TransactionDetails';
import { useAppSelector } from 'util/hooks';

import type { CustomerDetails } from 'models/Customer';

export const CustomerInfo: FC = () => {
  const customerDetails: CustomerDetails | undefined = useAppSelector((state) => state.customer.details);
  const id = customerDetails?.identifications?.find((item) => item.type === 'MJStateIDNo');
  const groupIds: number[] = [];

  if (!customerDetails) {
    return null;
  }

  return (
    <CustomerInfoContainer data-testid='transaction-details_customer-info'>
      <Name>{customerDetails.FullName}</Name>
      <Section>
        <Row>
          <Key>Nickname:</Key>
          <Value>{customerDetails.nickname}</Value>
        </Row>
        <Row>
          <Key>Reference #:</Key>
          <Value>{customerDetails.TransactionReference}</Value>
        </Row>
        <Row>
          <Key>MMJ ID:</Key>
          <Value>{customerDetails.MJStateIDNo}</Value>
        </Row>
        <Row>
          <Key>ID Exp:</Key>
          <Value>{id?.ExpirationDate}</Value>
        </Row>
        <Row>
          <Key>DOB:</Key>
          <Value>{customerDetails.DOB}</Value>
        </Row>
        <Row>
          <Key>Discount Groups:</Key>
          {customerDetails.discounts?.length > 0 && (
            <Value>
              {customerDetails.discounts
                .filter((item) => !groupIds.includes(item.id) && groupIds.push(item.id))
                .map((item, idx) => (
                  <span key={item.id}>
                    {item.title}
                    {customerDetails.discounts.length > 1 && idx !== customerDetails.discounts.length - 1
                      ? ','
                      : ''}{' '}
                  </span>
                ))}
            </Value>
          )}
        </Row>
        <Row>
          <Key>Last Purchase:</Key>
          <Value>{customerDetails.LastPurchase}</Value>
        </Row>
      </Section>
      <Section showTopBorder>
        <Row>
          <Key>Note:</Key>
          <Value>{customerDetails.notes}</Value>
        </Row>
      </Section>
      <Section showTopBorder>
        <Row>
          <Key>Address:</Key>
          <Value>
            {customerDetails.address.street}, {customerDetails.address.city}
          </Value>
        </Row>
        <Row>
          <Key>Postal Code:</Key>
          <Value>{customerDetails.address.postal_code}</Value>
        </Row>
      </Section>
      <Section>
        <Row>
          <Key>Primary Caregiver:</Key>
          <Value>{customerDetails.CaregiverName2}</Value>
        </Row>
        <Row>
          <Key>Caregiver ID:</Key>
          <Value>{customerDetails.CaregiverMJStateIdNo}</Value>
        </Row>
        <Row>
          <Key>Additional Caregiver:</Key>
          <Value>{customerDetails.CaregiverName3}</Value>
        </Row>
        <Row>
          <Key>Caregiver DOB:</Key>
          <Value>{customerDetails.CaregiverDOB}</Value>
        </Row>
      </Section>
      <Section showTopBorder>
        <Row>
          <LoyaltyData>Loyalty Points:</LoyaltyData>
          <LoyaltyData>{customerDetails.LoyaltyPoints}</LoyaltyData>
        </Row>
      </Section>
    </CustomerInfoContainer>
  );
};

const CustomerInfoContainer = styled.div`
  width: 35%;
  height: 100%;
  overflow-y: auto;

  ${breakpoints.wideTablet.maxQuery} {
    display: none;
  }
`;

const Name = styled.div`
  padding: 1.5rem 2rem;
  color: ${colors.dutchie.black};
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 700;
`;

const Section = styled.div<{ showTopBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 2rem;

  ${({ showTopBorder }) =>
    showTopBorder &&
    css`
      border-top: 1px solid ${colors.dutchie.shadowGrey};
    `}
`;

const LoyaltyData = styled.div`
  color: ${colors.dutchie.gray100};
  font-size: 1.25rem;
  font-weight: 600;
`;
