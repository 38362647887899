import React, { FC } from 'react';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { SecondaryIdentification } from 'queries/v2/guest/types';
import { useRemoveSecondaryIdentificationMutation } from 'queries/v2/guest/remove-secondary-identification';

const Message = styled.p`
  text-align: center;
  padding: 0.75rem 1rem;
`;

type RemoveIdentificationPopupProps = {
  isVisible: boolean;
  hide: () => void;
  guestId: number;
  id: SecondaryIdentification;
};

export const RemoveIdentificationPopup: FC<RemoveIdentificationPopupProps> = ({
  isVisible,
  hide,
  guestId,
  id: { name, identityId },
}) => {
  const { mutate: removeSecondaryIdentification } = useRemoveSecondaryIdentificationMutation();

  const onSubmit = async () => {
    removeSecondaryIdentification({
      guestId,
      identityId,
    });
    hide();
  };

  return (
    <ConfirmationPopup
      small
      isVisible={isVisible}
      hide={hide}
      title='Delete'
      confirm={{
        text: 'Confirm',
        onClick: onSubmit,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <Message>Are you sure you want to delete {name}?</Message>
    </ConfirmationPopup>
  );
};
