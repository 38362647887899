import { ReactNode, useMemo, useEffect, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { CheckoutContext } from './CheckoutSidebar/CheckoutContext';
import { extendedPanelPortalID } from '.';
import { finishCheckoutOnTwoPanelLayout } from 'store/actions/CheckoutActions';
import { PaymentType } from 'models/Checkout';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';

import type { CartPanelsProps } from './CartPanels';

export enum Panels {
  InStoreDutchiePay,
  LoyaltyPoints,
  ManageDiscounts,
  PaymentComplete,
  PaymentSelection,
  TotalsSidebar,
  ViewPrescription,
}

export const useCartPanels = ({ loading }: CartPanelsProps) => {
  const dispatch = useAppDispatch();
  const ldClient = useLDClient();

  // State

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { selectedPaymentType } = useContext(CheckoutContext);

  // LD Flags

  const isMCDMSEnabled = ldClient?.variation('pos.backoffice.mcdms-integration.rollout', false) === true;

  // Global state
  const isCheckoutActive = useAppSelector((state) => state.checkout.running);
  const isCheckoutSuccessful = useAppSelector((state) => state.checkout.success);
  const isDiscountPanelOpen = useAppSelector((state) => state.cart.showDiscounts);
  const isLoyaltyPanelOpen = useAppSelector((state) => state.cart.showLoyalty);
  const isPrescriptionPanelOpen = useAppSelector((state) => state.cart.showPrescription);

  // Computed values

  const portalContainer = document.querySelector(`#${extendedPanelPortalID}`);
  const usePortal = isCheckoutActive;

  const activePanel: Panels = useMemo(() => {
    // If the page is loading always show the totals panel
    if (loading) {
      return Panels.TotalsSidebar;
    }

    // If checkout is active, we'll be showing either payment panels or the loyalty panel
    if (isCheckoutActive) {
      if (isCheckoutSuccessful) {
        return Panels.PaymentComplete;
      } else if (isLoyaltyPanelOpen) {
        return Panels.LoyaltyPoints;
      } else if (selectedPaymentType === PaymentType.DutchiePay) {
        return Panels.InStoreDutchiePay;
      } else {
        return Panels.PaymentSelection;
      }
    }

    // Check if any other panels are open before showing the totals panel as the default

    if (isDiscountPanelOpen) {
      return Panels.ManageDiscounts;
    }

    if (isLoyaltyPanelOpen) {
      return Panels.LoyaltyPoints;
    }

    if (isMCDMSEnabled && isPrescriptionPanelOpen) {
      return Panels.ViewPrescription;
    }

    return Panels.TotalsSidebar;
  }, [
    isCheckoutActive,
    isCheckoutSuccessful,
    isDiscountPanelOpen,
    isLoyaltyPanelOpen,
    isMCDMSEnabled,
    isPrescriptionPanelOpen,
    loading,
    selectedPaymentType,
  ]);

  // Helpers

  const renderPanelInPortal = (panel: ReactNode) => {
    return portalContainer ? createPortal(panel, portalContainer) : null;
  };

  // Layout

  const { isCompactLayout } = useCompactCartLayout();

  useEffect(() => {
    switch (activePanel) {
      case Panels.TotalsSidebar:
        // Hide the drawer if the active panel is the totals sidebar
        setIsDrawerOpen(false);
        break;
      default:
        // Show the drawer for any other active panel
        setIsDrawerOpen(true);
    }
  }, [activePanel]);

  useEffect(() => {
    return () => {
      if (isCheckoutSuccessful) {
        dispatch(finishCheckoutOnTwoPanelLayout());
      }
    };
  }, [dispatch, isCheckoutSuccessful]);

  return {
    activePanel,
    isDrawerOpen,
    renderPanelInPortal,
    showPanelsAsDrawer: isCompactLayout,
    usePortal,
  };
};
