import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import * as FylloApi from 'api/FylloApi';
import { Popup } from 'components/popups';
import { FylloRewardsInfo } from 'models/Fyllo';
import { Loader } from 'components/backoffice/loader';
import { FylloWarning } from './FylloWarning';
import { FylloWallet } from './FylloWallet';
import { errorNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CartItem } from 'models/Cart';
import { logger } from 'util/logger';

type FylloRedeemPopupProps = {
  hide: () => void;
  item?: CartItem;
};

export const FylloRedeemPopup: FC<FylloRedeemPopupProps> = ({ hide, item }) => {
  const [FylloInfo, setFylloInfo] = useState<FylloRewardsInfo | undefined>();

  const phone = useAppSelector((state) =>
    state.customer.details?.CellPhone ? state.customer.details.CellPhone : state.customer.details?.PhoneNo
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { shipmentId } = useTransactionManager();

  useEffect(() => {
    const getRewards = async () => {
      setLoading(true);
      try {
        const rewards = await FylloApi.getRewards({
          ShipmentId: shipmentId ?? 0,
          PhoneNumber: phone ?? '',
        });
        setFylloInfo(rewards);
      } catch (e) {
        const message = 'Error looking up customer, please make sure this phone number exists as a member';
        dispatch(errorNotification(message));
        logger.error(e, { message });
      }
      setLoading(false);
    };

    if (phone) {
      getRewards();
    }
  }, [phone, dispatch, shipmentId]);

  let content = <div></div>;

  if (loading) {
    content = (
      <SpinnerWrapperDiv>
        <Loader variant='black' size='2x' />
      </SpinnerWrapperDiv>
    );
  } else if (!phone) {
    content = <FylloWarning />;
  } else {
    content = <FylloWallet FylloInfo={FylloInfo} setFylloInfo={setFylloInfo} setLoading={setLoading} item={item} />;
  }

  return (
    <RedeemPopup caption='Redeem Fyllo rewards' hide={hide} isVisible>
      {content}
    </RedeemPopup>
  );
};

const SpinnerWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: center;
`;

const RedeemPopup = styled(Popup)`
  min-width: 420px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: scroll;
`;
