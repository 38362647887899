import { useCallback, useState } from 'react';

type UsePopupReturn = {
  isVisible: boolean;
  toggle: () => void;
};

export const usePopup = (popupVisible = false, onClose?: () => void): UsePopupReturn => {
  const [isVisible, setIsShowing] = useState(popupVisible);

  const toggle = useCallback(() => {
    if (isVisible && onClose) {
      onClose();
    }

    setIsShowing(!isVisible);
  }, [setIsShowing, isVisible, onClose]);

  return { isVisible, toggle };
};
