import styled from 'styled-components';
import { colors } from 'css/Theme';

export const CardText = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #5e696e;
  width: 100%;
`;

export const CardLabel = styled.div`
  width: 40%;
`;

export const CardFieldValue = styled.div<{ expired?: boolean }>`
  width: 60%;
  color: ${({ expired }) => (expired ? colors.dutchie.red : colors.dutchie.almostBlack)};
  font-weight: ${({ expired }) => (expired ? 500 : 'normal')};
  overflow-wrap: break-word;
`;
CardFieldValue.defaultProps = {
  expired: false,
};

export const InfoContainer = styled.div<{ gridArea: string; placeSelf?: string; listMode: boolean }>`
  grid-area: ${({ gridArea }) => gridArea};
  place-self: ${({ placeSelf }) => (placeSelf ? placeSelf : 'start')};
  width: 100%;
  ${({ listMode }) => listMode && 'max-width: 250px;'}
  overflow: hidden;
`;

export const WithTopBorder = styled.div<{ listMode: boolean; paddingBottom?: boolean }>`
  border-top: ${({ listMode }) => (listMode ? '0' : '1px')} solid ${colors.dutchie.backgroundGrey};
  padding: ${({ listMode, paddingBottom = false }) =>
    listMode ? '0' : paddingBottom ? '12px 0 12px 0' : '12px 0 0 0'};
`;
