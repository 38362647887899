import React, { FC, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import imageCompression from 'browser-image-compression';
import { colors, zIndices } from 'css/Theme';
import { ReactComponent as Close } from 'assets/icons/icon-close.svg';
import { Loader } from 'components/backoffice/loader';
import { errorNotification } from 'store/actions/NotificationsActions';

type CameraProps = {
  isVisible: boolean;
  hide: () => void;
  onCapture: (imageSrc: string | null | unknown) => void;
};

export const Camera: FC<CameraProps> = ({ isVisible, hide, onCapture }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const capture = useCallback(() => {
    setIsLoading(true);
    if (!webcamRef.current) {
      setIsLoading(false);
      return;
    }
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      imageCompression
        .getFilefromDataUrl(imageSrc, 'tempCameraCap')
        .then((file) =>
          imageCompression(file, {
            maxSizeMB: 0.049,
            maxWidthOrHeight: 1080,
          })
        )
        .then((compressedFile) => toBase64(compressedFile))
        .then((base64String) => {
          onCapture(base64String);
          setIsLoading(false);
          hide();
        })
        .catch(function (error) {
          dispatch(errorNotification(error.message));
        });
    }
  }, [onCapture, hide, dispatch]);

  if (!isVisible) {
    return null;
  }

  return (
    <CameraDiv>
      <WebcamWrapperDiv>
        {isLoading ? (
          <SpinnerWrapperDiv>
            <Loader size='3x' />
          </SpinnerWrapperDiv>
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat='image/png'
              videoConstraints={{
                width: 1280,
                height: 720,
                facingMode: 'environment',
              }}
            />
            <CaptureButton type='button' onClick={capture} />
          </>
        )}
        <CloseIcon onClick={hide} />
      </WebcamWrapperDiv>
    </CameraDiv>
  );
};

const CameraDiv = styled.div`
  background-color: ${colors.realBlack};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndices.camera};
  display: flex;
  justify-content: center;
`;

const WebcamWrapperDiv = styled.div`
  position: relative;
  max-height: 720px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(Close)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${colors.white};
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 1));
`;

const CaptureButton = styled.button`
  background-color: ${colors.lightGrey};
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  border: 0.3rem solid ${colors.borderGrey};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  cursor: pointer;
`;

const SpinnerWrapperDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  & svg {
    width: 100px;
    height: 100px;
    color: #fff;
  }
`;
