import { PeripheralData, ScanBarcodePayload } from '@dutchie/capacitor-peripheral';
import { BarcodeScanEntryResponse } from 'queries/v2/guest/barcode-scan-entry';
import { Scanner as HwScanner } from '@dutchie/capacitor-hardware';
export type ScannerDataEventContext = {
  scanData: Partial<ScanBarcodePayload> & { scannerId?: number | string };
};

export type ScannerStatusEventContext = {
  peripheral: PeripheralData;
  statusName: string;
};

export type SearchScannersContext = {
  availableScanners: PeripheralData[] | HwScanner[];
  userInitiated: boolean;
};

export type UserSelectedScannerContext = {
  selectedScannerId: string;
};

export type PlayedSoundContext = {
  isGood: boolean;
  selectedScannerId: string;
};

export type ScannerIdLookupContext = {
  scannerId: string;
  usedProvidedScannerId: boolean;
};

export enum IDScanResult {
  'AUTO_CHECKIN' = 'auto_checkin',
  'AUTO_CREATE' = 'auto_create',
  'SHOW_SCAN_DETAILS' = 'show_scan_details',
}

type BarcodeScanEntryResponseWithoutPII = Pick<BarcodeScanEntryResponse, 'CheckedInShipmentId' | 'CreatedCustomerId'> & {
  ValidatedIdentificationCustomer: Pick<BarcodeScanEntryResponse['ValidatedIdentificationCustomer'], 'IsBanned' | 'IsDriversLicenseExpired' | 'IsLoyaltyMember' | 'IsMedical' | 'IsMJStateIdExpired' | 'IsUnderMedicalAge' | 'IsUnderRecreationalAge'>;
};


export type IDScanResultContext = {
  result: IDScanResult;
  data?: BarcodeScanEntryResponseWithoutPII;
  isAutoCheckInWithIdScanEnabled?: boolean;
  isAutoCreateWithIdScanEnabled?: boolean;
};
