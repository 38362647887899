import React, { useEffect, useRef, useState } from 'react';
import { KeypadHeader } from '../PinLoginPage/KeypadHeader';
import { KeypadFooter } from '../PinLoginPage/KeypadFooter';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { SelectLocationAction } from 'store/actions/UserActions';
import { colors } from 'css/Theme';
import { State } from 'store';
import {
  loadLabelPrinters,
  loadLabels,
  loadReceiptPrinters,
  loadRegisters,
  selectRegister,
} from 'store/actions/SettingsActions';
import { Select } from 'components/inputs';
import { Button } from 'components/buttons';
import { useSessionStorage } from 'util/hooks';
import { getPersistedValue, PersistedValueKey } from 'util/persisted-values';
import { useLDClient } from 'launchdarkly-react-client-sdk';

type Register = {
  id: number;
  TerminalName: string;
};

export const RegisterSelectionPageV2 = () => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const registersContainHardwareIdRollout =
    ldClient?.variation('pos.register.should-return-register-hardware-id.rollback', false) === true;
  const registers = useSelector((state: State) => state.settings.registers);
  const [selected, setSelected] = useState<number | undefined>();
  const noneSelected = selected === undefined;
  const placeholder = registers.length ? 'Select register' : 'None available';
  const { clear: clearToggleDismissal } = useSessionStorage('toggleDismissed');
  const hardwareId = useRef<string | null | undefined>(null);

  useEffect(() => {
    clearToggleDismissal(); //clear the toggle dismissal on the register selection screen.  Covers logout, switching register, and refresh scenarios
  }, [clearToggleDismissal]);

  useEffect(() => {
    const setupRegisters = async () => {
      hardwareId.current = await getPersistedValue(PersistedValueKey.hardwareId);
      dispatch(loadRegisters(hardwareId.current));
    };
    setupRegisters();
    dispatch(loadLabels());
    dispatch(loadLabelPrinters());
    dispatch(loadReceiptPrinters());
  }, [dispatch]);

  useEffect(() => {
    if (registersContainHardwareIdRollout && !!hardwareId.current && typeof hardwareId.current === 'string') {
      const selectedRegister = registers.find((item) => {
        return item.HardwareId === hardwareId.current;
      });
      if (selectedRegister) {
        setSelected(selectedRegister.id);
      }
    } else if (registers?.length === 1) {
      setSelected(registers[0].id);
    }
  }, [registers, registersContainHardwareIdRollout]);

  const handleSelectRegister = () => {
    const selectedRegister = registers.find((item) => item.id === selected);
    if (selectedRegister) {
      dispatch(
        selectRegister({
          value: selectedRegister.id,
          label: selectedRegister.TerminalName,
        })
      );
    }
  };

  const handleSwitchLocation = () => {
    dispatch(SelectLocationAction(undefined));
  };

  return (
    <StyledPage>
      <KeypadHeader />
      <FormContainer>
        <StyledTitle>Select your register</StyledTitle>
        <StyledSelect
          defaultValue={selected}
          automationId='register-selection-page_select_register'
          onChange={(value) => setSelected(parseInt(value, 10))}
          options={registers.map((item) => ({ value: item.id, label: item.TerminalName }))}
          placeholder={placeholder}
        />
        <div>
          {registers.length ? (
            <Button
              disabled={noneSelected}
              fullWidth
              onClick={handleSelectRegister}
              automationId='register-selection-page_button_select-register'
            >
              Continue
            </Button>
          ) : (
            <Button fullWidth onClick={handleSwitchLocation}>Switch location</Button>
          )}
        </div>
      </FormContainer>
      <KeypadFooter />
    </StyledPage>
  );
};

const StyledSelect = styled(Select)`
  margin-top: 0.625rem;
  width: 100%;
  margin-bottom: 2rem;
`;

const StyledPage = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${colors.dutchie.opal90};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: ${colors.dutchie.primaryWhite};
  margin: 0 auto 2rem;
`;

const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;
