import React from 'react';
import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';
import { StyledTooltip } from './tooltip.styles';

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps>((props, ref) => {
  const { children, className = '', ...other } = props;
  return (
    <MUITooltip {...other} arrow className={className} PopperComponent={StyledTooltip} ref={ref}>
      {children}
    </MUITooltip>
  );
});
