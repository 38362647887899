import React from 'react';
import styled from 'styled-components';

function NoResults() {
  return <NoResultsContainer>No results found</NoResultsContainer>;
}

export default NoResults;

export const NoResultsContainer = styled.p`
  margin: var(--sizes-40);
  padding: var(--sizes-40);
  background: var(--color-greyscale-grey-95);
  border-radius: var(--sizes-20);
  font: var(--font-regular-14pt-normal);
  color: var(--color-greyscale-grey-40);
`;
