import React, { FC } from 'react';

import {
  Title,
  AllotmentHeaderContainer,
  AllotmentBarContainer,
  AllotmentAmounts,
  ProgressBarSkeleton,
  ProgressBar,
  Fillbar,
} from '../../TotalsSidebarSection.styles';
import { Skeleton } from 'components/misc';
import { useDaysSupplyLimit } from './useDaysSupplyLimit';

import type { PreorderCartItem } from 'pages/CreatePreOrderPage';

export type DaysSupplyLimitProps = {
  loading?: boolean;
  preorderCartItems?: PreorderCartItem[];
};

export const DaysSupplyLimit: FC<DaysSupplyLimitProps> = ({ loading, preorderCartItems }) => {
  const { daysSupplyInCart, daysSupplyRemaining, usedPercentage, over } = useDaysSupplyLimit({ preorderCartItems });

  return (
    <>
      <AllotmentHeaderContainer>
        <Title>Days supply</Title>
      </AllotmentHeaderContainer>
      <AllotmentBarContainer>
        {loading ? (
          <ProgressBarSkeleton />
        ) : (
          <ProgressBar>
            <Fillbar used={usedPercentage} over={over} />
          </ProgressBar>
        )}
        {loading ? (
          <Skeleton width='150px' height='14px' />
        ) : (
          <AllotmentAmounts>
            <span data-testid='day-supply-details_in-cart-amount'>{daysSupplyInCart} in cart</span>
            <span>/</span>
            <span data-testid='day-supply-details_remaining-amount'>{daysSupplyRemaining} remaining</span>
          </AllotmentAmounts>
        )}
      </AllotmentBarContainer>
    </>
  );
};
