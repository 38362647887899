import type { PreorderProductSearchResult } from 'api/PreorderApi';
import type { ProductSearchResult } from 'queries/v2/product/types';

export type AnyProductSearchResult = ProductSearchResult | PreorderProductSearchResult;

/**
 * Helper to determine if a product is a ProductSearchResult or PreorderProductSearchResult
 * @param product Either a ProductSearchResult or a PreorderProductSearchResult
 * @returns boolean
 */
export const isProductSearchResult = (product?: AnyProductSearchResult): product is ProductSearchResult =>
  (product as ProductSearchResult).productId !== undefined;
