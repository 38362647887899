import React from 'react';
import styled from 'styled-components';

// queries
import { useGuestDetailsQuery } from 'queries/v2/guest/details';

// global components
import { Skeleton } from 'components/misc';

type TitleProps = {
  customerId: number;
};

export function Title(props: TitleProps) {
  const { customerId } = props;

  const { data, isLoading } = useGuestDetailsQuery({ guestId: customerId });

  if (isLoading) {
    return (
      <Container data-testid='customer-insights-title-loading-state'>
        <Skeleton height={22} width={300} />
      </Container>
    );
  }

  if (data?.FullName) {
    return (
      <Container>
        <Text>{data.FullName}'s insights</Text>
      </Container>
    );
  }

  return (
    <Container>
      <Text>Customer insights</Text>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  margin-left: 44px;
  justify-content: center;
`;

const Text = styled.span`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;
