import React, { FC } from 'react';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { CustomerDetails, Prescription } from 'models/Customer';
import { callback } from 'models/Misc';

const CancelPopup = styled(ConfirmationPopup)`
  width: 31.25rem;
`;

const TextCenter = styled.p`
  width: 80%;
  text-align: center;
  margin: 0 auto;
`;

type DeletePrescriptionPopupProps = {
  isVisible: boolean;
  hide: () => void;
  guest?: CustomerDetails;
  prescription?: Prescription;
  onDelete: callback;
};

export const DeletePrescriptionPopup: FC<DeletePrescriptionPopupProps> = ({
  onDelete,
  isVisible,
  hide,
  guest,
  prescription,
}) => (
  <CancelPopup
    isVisible={isVisible}
    hide={hide}
    title='Delete prescription'
    confirm={{
      text: 'Yes',
      onClick: () => {
        onDelete();
        hide();
      },
    }}
    cancel={{
      text: 'Cancel',
      onClick: hide,
    }}
  >
    <TextCenter>
      Are you sure you want to delete prescription #{prescription?.PrescriptionNumber} for {guest?.FullName}?
    </TextCenter>
  </CancelPopup>
);
