import type { GetCartDetailsPayload } from './load';

export const getCartDetailsKeys = {
  all: ['cart-details'] as const,
  many: (registerId: number) => [...getCartDetailsKeys.all, 'many', registerId] as const,
  one: (props: Omit<GetCartDetailsPayload, 'timestamp'>) =>
    [
      ...getCartDetailsKeys.all,
      'one',
      'shipmentId',
      props.shipmentId.toString(),
      'guestId',
      props.guestId.toString(),
    ] as const,
};
