import React from 'react';
import { Skeleton } from 'components/misc';

import {
  Field,
  OrderInfo,
  OrderNumber,
  PaymentTypesHeader,
  PopupContent,
  TransactionPaymentTypes,
} from './VoidTransactionPopup.styles';

export const VoidTransactionLoadingSkeleton = () => {
  return (
    <PopupContent>
      <OrderInfo>
        <OrderNumber>
          Order <Skeleton height={16} width={60} /> will be voided.
        </OrderNumber>
        <TransactionPaymentTypes>
          <PaymentTypesHeader>Transaction payment type:</PaymentTypesHeader>
          <Skeleton height={16} width={100} />
        </TransactionPaymentTypes>
      </OrderInfo>
      <Field>
        <Skeleton height={18} />
        <Skeleton height={44} />
      </Field>
      <Field>
        <Skeleton height={18} />
        <Skeleton height={44} />
      </Field>
      <Field>
        <Skeleton height={18} />
        <Skeleton height={44} />
      </Field>
    </PopupContent>
  );
};
