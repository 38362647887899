import React from 'react';

export function MoneyIcon() {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_9802_55580)'>
        <path
          d='M6.74953 6.375H5.73303C5.57725 6.37511 5.42636 6.42942 5.30624 6.52862C5.18612 6.62781 5.10426 6.76572 5.07469 6.91867C5.04513 7.07162 5.06971 7.2301 5.14422 7.36691C5.21872 7.50372 5.33851 7.61035 5.48303 7.6685L6.51503 8.0815C6.65956 8.13965 6.77934 8.24628 6.85385 8.38309C6.92835 8.5199 6.95293 8.67838 6.92337 8.83133C6.89381 8.98428 6.81195 9.12219 6.69183 9.22138C6.57171 9.32058 6.42082 9.37489 6.26503 9.375H5.24953'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M6 6.375V6' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6 9.75V9.375' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3.375 4.125H8.625' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M7.77887 2.51796L8.49787 1.07996C8.533 1.00958 8.54511 0.929931 8.53248 0.852289C8.51986 0.774647 8.48313 0.702942 8.42751 0.647319C8.37189 0.591697 8.30018 0.554974 8.22254 0.542347C8.1449 0.52972 8.06525 0.541829 7.99487 0.576959L7.04487 1.05096C6.96313 1.09176 6.86931 1.10132 6.78102 1.07785C6.69273 1.05437 6.61605 0.999472 6.56537 0.923459L6.31387 0.541959C6.27963 0.4906 6.23323 0.448489 6.1788 0.419364C6.12438 0.390239 6.0636 0.375 6.00187 0.375C5.94014 0.375 5.87937 0.390239 5.82494 0.419364C5.77051 0.448489 5.72412 0.4906 5.68987 0.541959L5.43537 0.923459C5.38477 0.999557 5.30808 1.05452 5.21977 1.07801C5.13145 1.10149 5.03759 1.09187 4.95587 1.05096L4.00737 0.576959C3.93697 0.541994 3.85737 0.530004 3.77979 0.54268C3.70221 0.555356 3.63057 0.592059 3.57496 0.647615C3.51935 0.703171 3.48257 0.774779 3.46982 0.852344C3.45707 0.92991 3.46898 1.00952 3.50387 1.07996L4.20887 2.48996'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.58109 5.25L2.20109 6.85C1.8634 7.28897 1.65535 7.81372 1.60051 8.36483C1.54568 8.91594 1.64624 9.4714 1.89082 9.9683C2.13539 10.4652 2.51421 10.8837 2.98437 11.1764C3.45453 11.4691 3.99726 11.6243 4.55109 11.6245H7.44609C7.99992 11.6243 8.54265 11.4691 9.01281 11.1764C9.48298 10.8837 9.86179 10.4652 10.1064 9.9683C10.3509 9.4714 10.4515 8.91594 10.3967 8.36483C10.3418 7.81372 10.1338 7.28897 9.79609 6.85L8.41609 5.25'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_9802_55580'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
