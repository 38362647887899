import React from 'react';
import { capitalize } from 'lodash';
import styled, { css } from 'styled-components';

import { AlertBannerStyles } from 'components/misc';
import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { ModalVariation } from 'components/modals/Modal';
import { PeripheralType } from '@dutchie/capacitor-hardware';
import { Row } from 'components/layout';
import { SettingsAlertBanner } from 'pages/SettingsPage/Status/SettingsAlertBanner';
import { SimpleModal } from 'components/modals/SimpleModal';
import { useAddNewDeviceModal } from './useAddNewDeviceModal';

import type { AuthorizeDeviceOptions } from './useAuthorizeDevice';

export type AddNewDeviceModalProps = {
  deviceDescription: string;
  deviceType: PeripheralType;
  onAuthorize: (options?: AuthorizeDeviceOptions) => void;
};

export const AddNewDeviceModal = ({ deviceDescription, deviceType, onAuthorize }: AddNewDeviceModalProps) => {
  const { handleCloseModal, wasDeviceAdded } = useAddNewDeviceModal({ deviceType });

  return (
    <SimpleModal
      modalName='add-new-device'
      header={`Add new ${deviceDescription}`}
      variation={ModalVariation.SettingModal}
      overlayStyles={css`
        z-index: 1400;
      `}
      hide={handleCloseModal}
      footer={
        <>
          <Button cta tertiary onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button cta onClick={handleCloseModal} disabled={!wasDeviceAdded}>
            Done
          </Button>
        </>
      }
    >
      <Content>
        {wasDeviceAdded && (
          <SettingsAlertBanner
            text={`${capitalize(deviceDescription)} successfully added`}
            style={AlertBannerStyles.success}
          />
        )}
        <SetupInstructions>
          <SetupTitle>Instructions</SetupTitle>
          <SetupSteps>
            <li>Click "Authorize {deviceDescription}" button below.</li>
            <li>
              Authorize your connected device when prompted by your browser. You will only need to do this the first
              time you connect a new {deviceDescription}.
            </li>
          </SetupSteps>
        </SetupInstructions>
        <Row>
          <Button secondary onClick={() => onAuthorize({ showAll: false })}>
            Authorize {deviceDescription}
          </Button>
        </Row>
        <Text>
          If your {deviceDescription} does not appear on the list, try showing{' '}
          <Link onClick={() => onAuthorize({ showAll: true })}>all devices</Link>.
        </Text>
      </Content>
    </SimpleModal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 2rem;
  border-top: 1px solid ${colors.dutchie.gray20};
`;

const SetupInstructions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SetupTitle = styled.div`
  color: ${colors.dutchie.gray70};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

const SetupSteps = styled.ol`
  padding-left: 1.5rem;
`;

const Text = styled.div`
  color: ${colors.dutchie.gray90};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Link = styled.a`
  color: ${colors.dutchie.blue};
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: ${colors.dutchie.blue};
    border-bottom: 2px solid ${colors.dutchie.blue};
  }
`;
