import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// utils
import { colors } from 'css/Theme';

// queries
import { useGuestDetailsQuery } from 'queries/v2/guest/details';

// global components
import { Skeleton } from 'components/misc';

// local components
import { Divider } from './Divider';

// types
type HistorySectionProps = {
  customerId: number;
};

export function HistorySection(props: HistorySectionProps) {
  const { customerId } = props;

  const { data, isLoading } = useGuestDetailsQuery({ guestId: customerId });

  if (isLoading) {
    return (
      <>
        <Container data-testid='customer-insights-history-loading-state'>
          <Skeleton height={24} width={125} />
          <Skeleton height={24} width={95} />
        </Container>
        <Divider />
      </>
    );
  }

  if (data?.LastPurchase) {
    return (
      <>
        <Container>
          <Heading>Purchase history</Heading>
          <Link data-testid='customer-insights-popup-purchase-history-link' to={`/edit-customer?id=${customerId}&tabIndex=2`}>Go to history</Link>
        </Container>
        <Divider />
      </>
    );
  }

  return null;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const Link = styled(NavLink)`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dutchie.blue};
`;
