import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Delivery } from 'models/Delivery';
import { DeliveryRoute } from 'models/DeliveryRoute';
import { State } from '../../../store';

export type DecoratedDeliveryRoute = {
  Id: number | null;
  Driver: string;
  Status?: string;
  Vehicle: string;
  FromTime?: Date;
  ToTime?: Date;
  NumberOfOrders: number;
  OrderValue: number;
  undecoratedRoute: DeliveryRoute | null;
  NeedsDirections: boolean;
  Unassigned: boolean;
  IsDynamicDeliveryRoute: boolean;
};

const calculateTotals = (deliveries: Delivery[]) =>
  deliveries.reduce(
    (memo, delivery) => {
      return {
        NumberOfOrders: memo.NumberOfOrders + 1,
        OrderValue: memo.OrderValue + delivery.InvoiceTotal,
      };
    },
    {
      NumberOfOrders: 0,
      OrderValue: 0,
    }
  );

const decorateRoute = (
  route: DeliveryRoute | null,
  deliveriesForRoute: Delivery[],
  needsDirections: boolean
): DecoratedDeliveryRoute => ({
  Id: route?.DeliveryRouteId ?? null,
  Vehicle: route?.CarName ?? '',
  Driver: (route?.Driver1Name ?? '') + (route?.Driver2Name ? ` + ${route?.Driver2Name}` : ''),
  Status: route?.DeliveryStatus ?? 'Unassigned',
  undecoratedRoute: route,
  FromTime: route?.StartDate ? new Date(route?.StartDate) : undefined,
  ToTime: route?.EndDate ? new Date(route?.EndDate) : undefined,
  NeedsDirections: needsDirections,
  Unassigned: route === null,
  IsDynamicDeliveryRoute: route?.IsDynamicDeliveryRoute || false,
  ...calculateTotals(deliveriesForRoute),
});

const decorateRoutes = (
  deliveries: Delivery[],
  rawDeliveryRoutes: DeliveryRoute[],
  dirtyRoutes: number[]
): {
  deliveries: Delivery[];
  routes: DecoratedDeliveryRoute[];
} => {
  const deliveriesByRoute = deliveries.reduce((memo, delivery) => {
    const list = memo.get(delivery.DeliveryRouteId) ?? [];
    memo.set(delivery.DeliveryRouteId, [delivery, ...list]);
    return memo;
  }, new Map<number | null, Delivery[]>());

  const routes = [
    decorateRoute(null, deliveriesByRoute.get(null) ?? [], false),
    ...rawDeliveryRoutes.map((route) =>
      decorateRoute(
        route,
        deliveriesByRoute.get(route.DeliveryRouteId) ?? [],
        dirtyRoutes.some((x) => x === route.DeliveryRouteId)
      )
    ),
  ];

  return { deliveries, routes };
};

const useDecorated = (deliveries: Delivery[], routes: DeliveryRoute[], dirty: number[]) =>
  useMemo(() => decorateRoutes(deliveries, routes, dirty), [deliveries, routes, dirty]);

export const useDecoratedDeliveryRoutes = (): { deliveries: Delivery[]; routes: DecoratedDeliveryRoute[] } => {
  const rawDeliveries = useSelector((state: State) => state.deliveryList.displayDeliveries);
  const rawRoutes = useSelector((state: State) => state.deliveryList.displayRoutes);
  const dirtyRoutes = useSelector((state: State) => state.deliveryList.routesWithDirtyDirections);
  const { deliveries, routes } = useDecorated(rawDeliveries, rawRoutes, dirtyRoutes);

  return { deliveries, routes };
};
