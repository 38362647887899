import { post } from 'api/HttpHelpers';
import {
    AlpineIQInfo,
    AppliedRewards,
    ApplyAlpineIQRewardRequest,
    GetAppliedRewards,
    GetWalletRequest,
    RemoveRewardRequest,
    VerifyWalletRequest,
} from 'models/AlpineIQ';
import { logger, customEventKeys } from 'util/logger';

export const verifyWallet = (body: VerifyWalletRequest) => post('AlpineIQ/verify-wallet', body);
export const getWallet = (req: GetWalletRequest) => post<AlpineIQInfo>('AlpineIQ/get-wallet', req);
export const addReward = (body: ApplyAlpineIQRewardRequest) => {
  logger.debug<{ body: ApplyAlpineIQRewardRequest }>('Applying Alpine IQ Reward', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('AlpineIQ/apply-reward', body);
};
export const getAppliedRewards = (body: GetAppliedRewards) =>
  post<AppliedRewards>('AlpineIQ/get-applied-rewards', body);
export const removeReward = (body: RemoveRewardRequest) => {
  logger.debug<{ body: RemoveRewardRequest }>('Removing Alpine IQ Reward', {
    key: customEventKeys.postRequest,
    body,
  });
  return post('AlpineIQ/remove-redemption', body);
};
