import { Skeleton } from 'components/misc';
import React from 'react';
import styled from 'styled-components';

type Props = {};

const BoardViewSkeleton = (props: Props) => {
  return (
    <BoardViewSkeletonContainer>
      <CardColumnContainer>
        <Skeleton height={2} />
        <Skeleton width={93} height={16} marginBottom={20} rounded />
        <Card height={405}>
          <Skeleton width={214} height={24} rounded />
          <Skeleton height={69} rounded />
          <Skeleton height={232} rounded />
          <Skeleton width={96} height={24} rounded />
        </Card>
        <Card height={405}>
          <Skeleton width={214} height={24} rounded />
          <Skeleton height={69} rounded />
          <Skeleton height={232} rounded />
          <Skeleton width={96} height={24} rounded />
        </Card>
      </CardColumnContainer>
      <CardColumnContainer>
        <Skeleton height={2} />
        <Skeleton width={75} height={16} marginBottom={20} rounded />
        <Card height={360}>
          <Skeleton width={214} height={24} rounded />
          <Skeleton height={69} rounded />
          <Skeleton height={187} rounded />
          <Skeleton width={96} height={24} rounded />
        </Card>
        <Card height={360}>
          <Skeleton width={214} height={24} rounded />
          <Skeleton height={69} rounded />
          <Skeleton height={187} rounded />
          <Skeleton width={96} height={24} rounded />
        </Card>
      </CardColumnContainer>
      <CardColumnContainer>
        <Skeleton height={2} />
        <Skeleton width={124} height={16} marginBottom={20} rounded />
        <Card height={352}>
          <Skeleton width={214} height={24} rounded />
          <Skeleton height={69} rounded />
          <Skeleton height={187} rounded />
          <Skeleton width={96} height={24} rounded />
        </Card>
      </CardColumnContainer>
      <CardColumnContainer>
        <Skeleton height={2} />
        <Skeleton width={124} height={16} marginBottom={20} rounded />
        <Card height={405}>
          <Skeleton width={214} height={24} rounded />
          <Skeleton height={69} rounded />
          <Skeleton height={187} rounded />
          <Skeleton width={96} height={24} rounded />
        </Card>
      </CardColumnContainer>
    </BoardViewSkeletonContainer>
  );
};

export default BoardViewSkeleton;

const BoardViewSkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const CardColumnContainer = styled.div`
  min-width: 312px;
  width: 100%;
  color: black;
`;

const Card = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  background-color: white;
  border-radius: 6px;
  border-left: 5px solid #e3e7e9;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;
