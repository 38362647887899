import { useState, useEffect } from 'react';

import { breakpoints } from 'css/Theme';

const smallTabletMediaWatcher = window.matchMedia(breakpoints.smallTablet.maxQuery.replace('@media ', ''));

export const useCompactCartLayout = () => {
  const [isCompactLayout, setIsCompactLayout] = useState(smallTabletMediaWatcher.matches);

  useEffect(() => {
    const onMediaChange = (e: MediaQueryListEvent) => setIsCompactLayout(e.matches);

    smallTabletMediaWatcher.addEventListener('change', onMediaChange);
    return () => smallTabletMediaWatcher.removeEventListener('change', onMediaChange);
  }, []);

  return {
    isCompactLayout,
  };
};
