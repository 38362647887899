import { Delivery } from 'models/Delivery';
import { LLPin } from './Pins';

export const rollupDeliveries = (deliveries: Array<Delivery>) => {
  const map = deliveries.reduce((acc, d) => {
    const key = `${d.Lat} ${d.Lng}`;
    if (!acc.has(key)) {
      acc.set(key, { Deliveries: [], Lat: d.Lat, Lng: d.Lng });
    }
    acc.get(key)?.Deliveries.push(d);
    return acc;
  }, new Map<string, LLPin>());

  return Array.from(map.values());
};

const LAT_LNG_MAX_BOUND = 90;
export const buildBbox = (markers: number[][]) => {
  let minLat = LAT_LNG_MAX_BOUND;
  let maxLat = -LAT_LNG_MAX_BOUND;
  let minLng = LAT_LNG_MAX_BOUND;
  let maxLng = -LAT_LNG_MAX_BOUND;

  for (const [lng, lat] of markers) {
    if (lat < minLat) {
      minLat = lat;
    }

    if (lat > maxLat) {
      maxLat = lat;
    }
    if (lng < minLng) {
      minLng = lng;
    }

    if (lng > maxLng) {
      maxLng = lng;
    }
  }

  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ] as mapboxgl.LngLatBoundsLike;
};
