import {
  Allotment,
  Cart,
  CartItem,
  Loyalty,
  PreauthInfo,
  Preorder,
  PreOrderRedemption,
  PrepaymentPayInfo,
} from 'models/Cart';
import { AppliedDiscount } from 'models/Discounts';
import { AppliedFeeDonation } from 'models/FeeDonation';

let idCounter = 0;

export const createMockPreorder = (overrides: Partial<Preorder> = {}, id = idCounter++): Preorder => ({
  Id: id,
  OrderId: 1,
  OrderItemId: 1,
  Grower: `mock Grower for Preorder ${id}`,
  Location: `mock Location for Preorder ${id}`,
  Strain: `mock Strain for Preorder ${id}`,
  Product: `mock Product for Preorder ${id}`,
  InventoryDate: new Date(),
  SerialNo: 1,
  Grams: 1,
  UnitId: 1,
  UnitName: 1,
  Abbreviation: `mock Abbreviation for Preorder ${id}`,
  TotalCost: 1,
  OrderNo: `mock OrderNo for Preorder ${id}`,
  OrderDate: new Date(),
  OrderStatus: `mock OrderStatus for Preorder ${id}`,
  Customer: `mock Customer for Preorder ${id}`,
  QtySelected: 1,
  ProductId: 1,
  Category: `mock Categoryfor Preorder ${id}`,
  ...overrides,
});

export const createMockPreorderRedemption = (
  overrides: Partial<PreOrderRedemption> = {},
  id = idCounter++
): PreOrderRedemption => ({
  AlpineId: `mock AlpineId`,
  SpringBigId: `mock SpringBigId`,
  SpringBigType: `reward`,
  LeafLogixCode: `mock LeafLogixCode`,
  FylloId: 0,
  ProductId: 0,
  RedemptionDescription: `mock RedemptionDescription`,
  OrderRedemptionId: 1,
  OrderId: 1,
  IsRedeemed: true,
  CallbackUrl: `mock CallbackUrl`,
  ...overrides,
});

export const createMockAppliedDiscount = (overrides: Partial<AppliedDiscount> = {}, id = idCounter++) => ({
  InvId: 1,
  SerialNumber: `0`,
  DiscountReason: 'mock DiscountReason',
  DiscountAmt: 1,
  CheckBox: 'mock CheckBox',
  DiscountId: id,
  AppliedDiscountId: id,
  IsLoyalty: 1,
  ProductName: `Product for Discount ${id}`,
  Items: [],
  ...overrides,
});

export const createMockAllotment = (overrides: Partial<Allotment> = {}) => ({
  TotalRemaining: 0,
  ValidFrom: undefined,
  ValidUntil: undefined,
  TotalInCart: 1,
  TotalLimit: 1,
  TotalUsed: 1,
  MaxLimit: 1,
  CurrentLimit: 0,
  Error: false,
  ErrorMessage: undefined,
  Warning: false,
  WarningMessage: undefined,
  Details: [],
  ...overrides,
});

export const createMockCartItem = (overrides: Partial<CartItem> = {}, id = idCounter++): CartItem => ({
  AllowSaleToMinor: undefined,
  AutoPrintLabel: undefined,
  BatchId: undefined,
  CannbisProduct: undefined,
  Customer: undefined,
  DaysSupply: undefined,
  DaysSupplyPerItem: undefined,
  DefaultLabelId: undefined,
  DiscountAmt: undefined,
  Equivalent: undefined,
  Grams: undefined,
  Id: id,
  Instructions: undefined,
  InventoryId: id,
  LoyaltyAmt: undefined,
  MMURFormId: undefined,
  MMUROrderId: undefined,
  Multiplier: undefined,
  OrderDate: undefined,
  OrderNo: `order-for-${id}`,
  PackageItemId: undefined,
  PricedQuantity: undefined,
  Product: `Product for Cart Item ${id}`,
  ProductCategory: undefined,
  ProductId: 1,
  QtyAllocated: 1,
  QtySelected: 1,
  SerialNo: `0`,
  Taxable: undefined,
  TaxAmt: undefined,
  TotalCost: 1,
  WgtCnt: 'Wgt',
  ...overrides,
});

export const createMockPrePayment = (overrides: Partial<PrepaymentPayInfo> = {}) => ({
  Amount: 100,
  OrderId: 69,
  ShipmentId: 420,
  PrepaidOn: new Date(),
  PrepaymentId: 'test',
  PrepaymentType: 'gtipay',
  ...overrides,
});

export const createMockPreauthInfo = (overrides: Partial<PreauthInfo> = {}): PreauthInfo => ({
  PreauthAmount: 100,
  FinalAmount: 90,
  OrderId: 69,
  ShipmentId: 420,
  PreauthId: 'ABCDE',
  PaymentType: 'dutchiepay',
  PreauthDate: new Date(),
  Currency: 'USD',
  ...overrides,
});

export const createMockCart = (overrides: Partial<Cart> = {}): Cart => ({
  ShipmentId: 420,
  Cart: [createMockCartItem()],
  PreOrders: [createMockPreorder()],
  PreOrderRedemptions: [createMockPreorderRedemption()],
  Discounts: [createMockAppliedDiscount()],
  ExistingPayments: [],
  Allotment: createMockAllotment(),
  Loyalty: undefined,
  Payment: {
    ElectronicType: 'mock ElectronicType',
    ElectronicAmount: 1,
  },
  FeesDonations: [],
  SubTotal: 1,
  TotalDiscountAndLoyalty: 1,
  Tax: 1,
  FeesAndDonations: 1,
  GrandTotal: 1,
  GrandTotalRounded: 1,
  TotalItems: 1,
  TotalDiscount: 1,
  VerifiedBy: undefined,
  RoundingAmount: 1,
  Deliverable: true,
  Locked: false,
  PrepaymentPayInfo: [createMockPrePayment()],
  PreauthInfo: createMockPreauthInfo(),
  ScheduleId: 'mock ScheduleId for Customer 0',
  TransactionReference: 'mock TransactionReference for Customer 0',
  TransactionStatus: 'mock TransactionStatus for Customer 0',
  CustomerId: 0,
  CurrentRegister: 1,
  CurrentRoom: 'mock CurrentRoom for Customer 0',
  ...overrides,
});

export const createMockLoyalty = (overrides: Partial<Loyalty> = {}): Loyalty => ({
  TransactionId: 12345,
  AppliedLoyaltyPoints: 0,
  ...overrides,
});

export const createMockAppliedFeeDonation = (overrides: Partial<AppliedFeeDonation> = {}): AppliedFeeDonation => ({
  FeeDonationId: 12345,
  AppliedFeeDonationId: 2345,
  CalculationMethod: 1,
  CashValue: 5,
  Name: '5off',
  IsAutomatic: false,
  ...overrides,
});
