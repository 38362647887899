import React, { FC } from 'react';

import { NavButton } from 'components/buttons';
import { FilterIcon } from 'assets/icons/guest-list/FilterIcon';

type FilterButtonProps = {
  onSelectFilters: () => unknown;
};

export const FilterButton: FC<FilterButtonProps> = ({ onSelectFilters }) => (
  <NavButton
    title='Filter'
    onClick={onSelectFilters}
    icon={<FilterIcon width={16} height={16} />}
    automationId='action-bar_button_filter'
    margin='0'
  />
);
