import React, { ReactNode } from 'react';

import { ErrorNotificationModal } from 'components/modals/ErrorNotificationModal';

import { useScanFailedPopup } from './useScanFailedPopup';

type ScanFailedPopupProps = {
  message: ReactNode;
  hide: () => void;
};

export const ScanFailedPopup = ({ message, hide }: ScanFailedPopupProps): JSX.Element => {
  const { handleClose } = useScanFailedPopup({ hide });

  return (
    <ErrorNotificationModal
      header='Scan failed'
      message={message}
      closeButtonLabel='Close to continue scanning'
      hide={handleClose}
    />
  );
};
