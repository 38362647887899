import { A, COMMA, D, F, FIVE, O, ONE, S, SEVEN, SIX, THREE, TWO, UP_CARROT, X, Z, ZERO } from "./ASCIIConstants";

export const START_LABEL = [
    UP_CARROT,
    X,
    A,
];

// Customize the font used to draw text.
// It can take up to 3 parameters: font type, font height, and font width.
export const SET_FONT = [
    UP_CARROT,
    A,
    ZERO,
    COMMA,
    ONE,
    SIX,
];

// Sets the position of the current field (the Field Origin).
// This command takes two parameters: an X-coordinate and a Y-coordinate.
export const FIELD_ORIGIN = [
    UP_CARROT,
    F,
    O,
    THREE,
    FIVE,
    COMMA,
    TWO,
    SEVEN,
];

// Defines the start for a data string field.
// The field data can be any printable character except those used as command prefixes (^ and ~).
export const START_FIELD = [
    UP_CARROT,
    F,
    D,
];

export const END_FIELD = [
    UP_CARROT,
    F,
    S,
];

export const END_LABEL = [
    UP_CARROT,
    X,
    Z,
];

export const TEST_HEADER = START_LABEL.concat(SET_FONT).concat(FIELD_ORIGIN).concat(START_FIELD);
export const TEST_FOOTER = END_FIELD.concat(END_LABEL);
