import React, { FC } from 'react';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';
import { getGuests, NotifyCustomer } from 'store/actions/GuestListActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CheckedInGuest } from 'models/Guest';

const NotifyCustomerStyle = styled(ConfirmationPopup)`
  width: 29.5rem;
`;

const NotifyCustomerPopupContainer = styled.div`
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  justify-content: center;
`;

type NotifyCustomerPopupProps = {
  hide: callback;
  /** Only used from the GuestlistPopupAction */
  guest?: CheckedInGuest;
};

export const NotifyCustomerPopup: FC<NotifyCustomerPopupProps> = ({ hide, guest }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const browserLocation = useLocation();

  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const { guestId, shipmentId } = useTransactionManager();
  const {
    data: { ScheduleId },
  } = useGetCartDetails();

  const guestIdToUse = guest?.Guest_id ?? guestId;
  const registerIdToUse = guest?.RegisterId ?? registerId;
  const shipmentIdToUse = guest?.ShipmentId ?? shipmentId;
  const scheduleIdToUse = Number(guest?.ScheduleId ?? ScheduleId);

  const notifyCustomerAndRelease = async () => {
    if (guestIdToUse && registerIdToUse && shipmentIdToUse) {
      //Awaiting this so that before we navigate to the guestpage the guest card is updated
      await dispatch(
        NotifyCustomer({
          CustomerId: guestIdToUse,
          ShipmentId: shipmentIdToUse,
          RegisterId: registerIdToUse,
          ScheduleId: scheduleIdToUse,
        })
      );

      if (browserLocation.pathname === '/guestlist') {
        dispatch(getGuests());
      } else {
        history.push('/guestlist');
      }
      hide();
    }
  };

  return (
    <NotifyCustomerStyle
      isVisible
      hide={hide}
      title='Notify Customer'
      confirm={{
        text: 'Confirm',
        onClick: () => {
          notifyCustomerAndRelease();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      <NotifyCustomerPopupContainer>Are you sure you want to notify the customer?</NotifyCustomerPopupContainer>
    </NotifyCustomerStyle>
  );
};
