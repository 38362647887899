import { ProductHistoryOrder } from 'models/Customer';
import { Delivery } from 'models/Delivery';
import { Reason } from 'models/Settings';
import { Transaction } from 'models/Transactions';
import { callback1 } from 'models/Misc';

export type TransactionsPopupsState = {
  changePaymentTypePopup: { selectedTransaction?: Transaction | ProductHistoryOrder } | null;
  managerPinPopup: {
    onSuccess: callback1<number>;
    title: string;
    reasons?: Array<Reason>;
    selectedReason?: string;
    setSelectedReason?: callback1<string>;
  } | null;
  transactionPopup: { selectedTransaction?: Transaction | ProductHistoryOrder | Delivery } | null;
  emailPopup: { onEmail: callback1<string> } | null;
  voidTransactionPopup: { shipmentId: number; reloadTransactions: () => void } | null;
};

export const emptyTransactionsPopupsState: TransactionsPopupsState = {
  changePaymentTypePopup: null,
  managerPinPopup: null,
  transactionPopup: null,
  emailPopup: null,
  voidTransactionPopup: null,
};
