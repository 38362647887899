import PhoneNumber from 'awesome-phonenumber';

export const formatPhone = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  try {
    const pn = new PhoneNumber(phoneNumber, 'US');
    return pn.getNumber('national') || phoneNumber;
  } catch {
    return phoneNumber;
  }
};
