import { post } from 'api/HttpHelpers';
import {
  CheckoutRequest,
  PostCheckoutRequest,
  DigitalPaymentRequest,
  BiotrackCheckoutRequest,
  DeliveryCar,
  DeliveryManifest,
  GetDeliveryManifestRequest,
  UpdateDeliveryManifestRequest,
  HypurClient,
  SetReadyForDeliveryRequest,
  BypassStateSystemRequest,
  GetDirectionsRequest,
  SaveCheckoutSignatureRequest,
  VerifyCartRequest,
  VerifyBirthdateRequest,
  ReadyForDeliveryResponse,
  GetTransactionNotesRequest,
  SetTransactionNotesRequest,
  TransactionNotesResponse,
  DirectionsReponse,
  CancelJoryTransaction,
  CancelPaymentsHubTransaction,
  ElectronicPaymentResponse,
  UploadDLRequest,
  GetLoyaltySignupsRequest,
  PollingElectronicPaymentResponse,
  PollingElectronicPaymentRequest,
  UpdateDeliveryManifestResponse,
  CancelPaynetworxTransaction,
  PaymentsHubResetCartDisplay,
} from 'models/Checkout';
import { PosApiResponse, ResultResponse } from 'models/Misc';

export const completeCheckout = (body: CheckoutRequest) => post<Array<ResultResponse>>('v2/guest/checkOut_Guest', body);
export const completeCheckoutNoThrow = (body: CheckoutRequest) =>
  post<PosApiResponse<ResultResponse>>('v2/guest/checkOut_Guest', body, { ignoreFormat: true });
export const postCheckout = (body: PostCheckoutRequest) => post<Array<ResultResponse>>('v2/guest/post-checkout', body);
export const electronicPayment = (body: DigitalPaymentRequest) => post<ElectronicPaymentResponse>('v2/guest/pay', body);
export const pollPayment = (body: PollingElectronicPaymentRequest) =>
  post<PollingElectronicPaymentResponse>('payment/poll-payment', body);
export const biotrackCheckout = (body: BiotrackCheckoutRequest) => post('posv3/Biotrack/MDScanPatientOut', body);
export const getCars = () => post<Array<DeliveryCar>>('posv3/maintenance/GetCars');
export const getDeliveryManifest = (body: GetDeliveryManifestRequest) =>
  post<Array<DeliveryManifest>>('v2/manifest/GetManifest', body);
export const updateDeliveryManifest = (body: UpdateDeliveryManifestRequest) =>
  post<UpdateDeliveryManifestResponse>('v2/manifest/UpdateManifest', body);
export const setReadyForDelivery = (body: SetReadyForDeliveryRequest) =>
  post<ReadyForDeliveryResponse>('v2/cart/ready-for-delivery', body);
export const getHypurClients = () => post<Array<HypurClient>>('payment/hypur/get-checked-in-clients');
export const bypassStateSystem = (body: BypassStateSystemRequest) => post('v2/cart/bypass-state-system', body);
export const getTransactionNotes = (body: GetTransactionNotesRequest) =>
  post<Array<TransactionNotesResponse>>('posv3/backendpos/GetTransactionNotes', body);
export const setTransactionNotes = (body: SetTransactionNotesRequest) =>
  post('posv3/backendpos/UpdateTransactionNotes', body);
export const getDirections = (body: GetDirectionsRequest) =>
  post<DirectionsReponse>('maps/get-route-distance-by-address', body);
export const saveCheckoutSignature = (body?: SaveCheckoutSignatureRequest) =>
  post('v2/guest/save-checkout-signature', body);
export const verifyCart = (body: VerifyCartRequest) => post('v2/cart/verify-cart', body);
/** @deprecated Use the useVerifyBirthdateMutation instead. Clean up with pos.register.anon-cart-workflow */
export const verifyBirthdate = (body: VerifyBirthdateRequest) => post('v2/cart/verify-customer-birthdate', body);
export const getLoyaltySignups = (body: GetLoyaltySignupsRequest) =>
  post<Array<string>>('v2/guest/CheckForSignups', body);
export const cancelJoryTransaction = (body: CancelJoryTransaction) => post('/jory/cancel-transaction', body);
export const cancelPaymentsHubTransaction = (body: CancelPaymentsHubTransaction) =>
  post('/payments-hub/cancel-transaction', body);
export const resetPaymentsHubCartDisplay = (body: PaymentsHubResetCartDisplay) =>
  post('/payments-hub/reset-cart-display', body);
export const cancelPaynetworxTransaction = (body: CancelPaynetworxTransaction) =>
  post('/paynetworx/transaction/cancel', body);
export const uploadId = (body: UploadDLRequest) => post('curbside/upload-id', body);
