import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';
import { TransactionsList } from 'pages/TransactionsPage/TransactionsList';
import { TransactionDetails } from 'pages/TransactionsPage/TransactionDetails';
import { breakpoints } from 'css/Theme';
import { ProvideTransactionsPopups } from 'components/TransactionsPopups';
import { useTransactionsPage } from './useTransactionsPage';
import { useGuestTransactions } from './hooks/useGuestTransactions';
import { callback, callback1 } from 'models/Misc';
import { TransactionsState } from 'store/reducers/TransactionsReducer';
import { useSalesHistory } from './hooks/useSalesHistory';
import { useIsSalesHistory } from './hooks/useIsSalesHistory/useIsSalesHistory';
import { useIsTransactions } from './hooks/useIsTransactions/useIsTransactions';
import { StickyHeader } from 'components/layout/StickyHeader';

type TransactionsPageWithoutPopupsProps = {
  setSearchText?: callback1<string>;
  loadTransactions: callback;
  transactions: TransactionsState;
};

export const TransactionsPageWithoutPopups: FC<TransactionsPageWithoutPopupsProps> = ({
  transactions,
  loadTransactions,
  setSearchText,
}) => {
  const { handleVoidTransaction, selectedTransaction, setSelectedTransaction, transactionId } = useTransactionsPage(
    transactions.items,
    loadTransactions
  );
  const isSalesHistory = useIsSalesHistory();
  const isTransactions = useIsTransactions();
  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <TransactionsPageBase
      isSalesHistory={isSalesHistory}
      isTransactions={isTransactions}
      isDetailsPage={!!transactionId}
    >
      {isSalesHistory && !transactionId && (
        <>
          <StickyHeader headerRef={headerRef}>
            <HeaderContent>Sales History</HeaderContent>
          </StickyHeader>
        </>
      )}
      <Content>
        {!transactionId ? (
          <TransactionsList
            loadingTransactions={transactions.transactionsLoading}
            onSearch={isSalesHistory ? loadTransactions : undefined}
            onSearchTextUpdated={setSearchText}
            isSalesHistory={isSalesHistory}
            transactions={transactions.items}
            setSelectedTransaction={setSelectedTransaction}
            setIsTransactionVoided={handleVoidTransaction}
            selectedTransaction={selectedTransaction}
          />
        ) : (
          <TransactionDetails onVoid={handleVoidTransaction} selected={selectedTransaction} />
        )}
      </Content>
    </TransactionsPageBase>
  );
};

export const TransactionsPage: FC = () => {
  const {
    transactions: salesHistoryTransactions,
    realoadTransactions: salesHistoryLoad,
    setSearchText,
  } = useSalesHistory();
  const { transactions: pageTransactions, reloadTransactions: pageReload } = useGuestTransactions();
  const isSalesHistory = useIsSalesHistory();

  return (
    <ProvideTransactionsPopups>
      <TransactionsPageWithoutPopups
        transactions={isSalesHistory ? salesHistoryTransactions : pageTransactions}
        loadTransactions={isSalesHistory ? salesHistoryLoad : pageReload}
        setSearchText={isSalesHistory ? setSearchText : undefined}
      />
    </ProvideTransactionsPopups>
  );
};

const Content = styled.div`
  padding: 2rem 4rem;
  ${breakpoints.smallTablet.maxQuery} {
    padding: 1rem 2rem;
  }
`;

const HeaderContent = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

const TransactionsPageBase = styled.div<{
  isSalesHistory: boolean;
  isTransactions: boolean;
  isDetailsPage: boolean;
}>`
  ${({ isSalesHistory, isTransactions, isDetailsPage }) =>
    css`
      ${Content} {
        padding: 1.5rem 0;
      }

      ${(isSalesHistory || isTransactions) &&
      css`
        ${Content} {
          padding: 1.5rem;
        }
      `}

      ${isDetailsPage &&
      css`
        height: 100%;

        ${Content} {
          height: 100%;
          padding: 0;
        }
      `}
    `}
`;
