import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { errorNotification } from 'store/actions/NotificationsActions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleLoadCartError = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, error: any) => {
  const isMCDMSError = error.toString().includes('NY MCDMS');

  if (isMCDMSError) {
    dispatch(
      errorNotification(
        `${error.toString()} \nThe cart is still intact but could not be loaded. Please fix the patient profile in order to continue processing this transaction.`
      )
    );
  } else {
    dispatch(errorNotification('Error loading cart'));
  }
};

