import { disableMassDPHOverride, enableMassDPHOverride, getMassDPHCreds, setMassDPHCreds } from "api/SettingsApi";
import { useEffect, useState } from "react";
import { successNotification, errorNotification } from "store/actions/NotificationsActions";
import { validateMassDPHSavedCredentials, validateMassDPHEnteredCredentials } from "store/actions/SettingsActions";
import { checkManagerPin } from "util/Helpers";
import { useAppDispatch } from "util/hooks";
import { useMassDphSettingsFeature } from "../hooks/useMassDphSettingsFeature";

type MassDphSettingsHook = {
  isFeatureEnabled: boolean;
  overrideEnabled: boolean;
  managerPin: string;
  password: string;
  username: string;
  disableOverride: () => void;
  enableOverride: () => void;
  setManagerPin: (pin: string) => void;
  setPassword: (password: string) => void;
  setUsername: (username: string) => void;
  updateCredentials: () => void;
  validateCredentials: () => void;
};

// Manage DPH credentials. This hook should only be used by the MassDphSettings component.
export const useMassDphSettings = (): MassDphSettingsHook => {
  const dispatch = useAppDispatch();
  const isFeatureEnabled = useMassDphSettingsFeature();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [managerPin, setManagerPin] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [massDPHOverrideEnabled, setMassDPHOverrideEnabled] = useState<boolean>(false);

  const disableOverride = async () => {
    try {
      await checkManagerPin(managerPin);
      await disableMassDPHOverride();
      setMassDPHOverrideEnabled(false);
      dispatch(successNotification('Overide Disabled'));
    } catch {
      dispatch(errorNotification('Invalid Manager PIN'));
    }
  };

  const updateCredentials = async () => {
    await setMassDPHCreds({
      DPHUserId: parseInt(window.sessionStorage.getItem('UserId') || '0'),
      IsEnabled: isEnabled,
      Username: username,
      Password: password,
    });
    dispatch(successNotification('Credentials Updated'));
    dispatch(validateMassDPHSavedCredentials());
  };

  const validateCredentials = async () => {
    dispatch(validateMassDPHEnteredCredentials({ Username: username, Password: password }));
  };

  const enableOverride = async () => {
    try {
      await checkManagerPin(managerPin);
      await enableMassDPHOverride();
      setMassDPHOverrideEnabled(true);
      dispatch(successNotification('Overide Enabled'));
    } catch {
      dispatch(errorNotification('Invalid Manager PIN'));
    }
  };

  useEffect(() => {
    if (isFeatureEnabled) {
      getMassDPHCreds().then((resp) => {
        setUsername(resp?.Username || '');
        setPassword(resp?.Password || '');
        setMassDPHOverrideEnabled(resp?.ManagerOverrideEnabled || false);
        setIsEnabled(resp?.IsEnabled || false);
      });
    }
  }, [isFeatureEnabled]);

  return {
    isFeatureEnabled,
    overrideEnabled: massDPHOverrideEnabled,
    password,
    username,
    managerPin,
    disableOverride,
    enableOverride,
    setManagerPin,
    setPassword,
    setUsername,
    updateCredentials,
    validateCredentials,
  };
};
