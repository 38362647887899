import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { logger } from 'util/logger';

const VERIFY_CUSTOMER_BIRTHDATE = 'v2/cart/verify-customer-birthdate';

type VerifyCustomerBirthdatePayload = {
  shipmentId?: number;
  guestId?: number;
  birthdate: Date;
};

const transformToServerPayload = (payload: VerifyCustomerBirthdatePayload) => {
  return {
    ShipmentId: payload.shipmentId,
    AcctId: payload.guestId,
    Birthdate: payload.birthdate,
  };
};

export const useVerifyBirthdateMutation = () => {
  return useMutation(
    async (payload: VerifyCustomerBirthdatePayload): Promise<void> => {
      await post(VERIFY_CUSTOMER_BIRTHDATE, transformToServerPayload(payload));
    },
    {
      onError: (error) => {
        logger.error(error, { message: 'Error occurred verifying customer birthdate' });
      },
    }
  );
};
