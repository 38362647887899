import { createAction } from '@reduxjs/toolkit';
import { Action } from 'store/reducers/ActionableErrorReducer';

type ErrorPayload = {
  message: string;
  action: Action;
  secondaryAction?: Action;
};

export const showActionableError = createAction('showActionableError', (payload: ErrorPayload) => ({ payload }));

export const hideActionableError = createAction('hideActionableError');
