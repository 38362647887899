import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { UserAvatarIcon } from 'assets/icons/settings/UserAvatarIcon';
import { SectionHeader } from 'pages/SettingsPage/SettingPageComponents';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { Row } from 'components/layout';
import { LinkButton } from 'components/backoffice/button';
import { PasswordIcon } from 'assets/icons/settings/PasswordIcon';
import { useMassDphSettings } from './hooks/useMassDphSettings';

export const MassDphSettings: FC = () => {
  const {
    isFeatureEnabled,
    managerPin,
    overrideEnabled,
    password,
    username,
    disableOverride,
    enableOverride,
    setManagerPin,
    setPassword,
    setUsername,
    updateCredentials,
    validateCredentials,
  } = useMassDphSettings();

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <>
      <SectionHeader>Mass DPH</SectionHeader>
      <Container>
        <Container>
          <Input
            label='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            startAdornment={<UserAvatarIcon size={16} color={colors.dutchie.grey} />}
            placeholder='Username'
          />
        </Container>
        <Container>
          <Input
            label='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            startAdornment={<PasswordIcon size={16} color={colors.dutchie.grey} />}
            type='password'
            placeholder='Password'
          />
        </Container>
        <Row>
          <Button tertiary onClick={updateCredentials}>
            Update credentials
          </Button>
          <Button tertiary onClick={validateCredentials} marginLeft={12}>
            Validate credentials
          </Button>
        </Row>
      </Container>
      <SettingsDivider />
      <SettingsListItem title='Override to use master credentials' subtitle='Enter your manager PIN' />
      <Container>
        <Input
          value={managerPin}
          onChange={(e) => setManagerPin(e.target.value)}
          type='password'
          placeholder='Manager PIN'
          endAdornment={
            <LinkButton
              label={overrideEnabled ? 'Disable Override' : 'Override'}
              onClick={overrideEnabled ? disableOverride : enableOverride}
            />
          }
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 430px;
  margin-bottom: 20px;
`;
