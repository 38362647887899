import React, { FC, useEffect, useRef } from 'react';

import { NumItemsLabel, updateCountClassName } from '../../CFD.styles';

type ItemCountProps = {
  count: number;
  automationId?: string;
};

export const ItemCount: FC<ItemCountProps> = ({ count, automationId }) => {
  const itemCountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    itemCountRef.current?.classList.add(updateCountClassName);
    setTimeout(() => itemCountRef.current?.classList.remove(updateCountClassName), 1000);
  }, [count]);

  return (
    <NumItemsLabel ref={itemCountRef} data-testid={automationId}>
      {count}
    </NumItemsLabel>
  );
};
