import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const ScaleIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9997 16.4165H1.99967C1.66634 16.4165 1.33301 16.3332 1.08301 16.1665C0.833008 15.9998 0.583008 15.7498 0.333008 15.4998C0.166341 15.2498 0.0830078 14.9165 0.0830078 14.5832C0.0830078 14.2498 0.166341 13.9165 0.249674 13.5832L1.58301 10.4998C1.74967 10.1665 1.99967 9.9165 2.33301 9.6665C2.66634 9.49984 2.99967 9.4165 3.33301 9.4165H16.6663C16.9997 9.4165 17.333 9.49984 17.6663 9.74984C17.9997 9.9165 18.2497 10.1665 18.333 10.4998L19.7497 13.6665C19.9163 13.9165 19.9163 14.2498 19.9163 14.5832C19.9163 14.9165 19.833 15.2498 19.6663 15.4998C19.4997 15.7498 19.2497 15.9998 18.9163 16.1665C18.6663 16.3332 18.333 16.4165 17.9997 16.4165ZM3.33301 10.5832C3.16634 10.5832 3.08301 10.5832 2.91634 10.6665C2.83301 10.7498 2.66634 10.8332 2.66634 10.9998L1.33301 14.0832C1.24967 14.2498 1.24967 14.3332 1.24967 14.4998C1.24967 14.6665 1.33301 14.7498 1.33301 14.9165C1.33301 15.0832 1.49967 15.0832 1.58301 15.1665C1.66634 15.2498 1.83301 15.2498 1.99967 15.2498H17.9997C18.1663 15.2498 18.2497 15.2498 18.4163 15.1665C18.4997 15.0832 18.6663 14.9998 18.6663 14.9165C18.6663 14.8332 18.7497 14.6665 18.7497 14.4998C18.7497 14.3332 18.7497 14.2498 18.6663 14.0832L17.2497 10.9165C17.1663 10.8332 17.083 10.7498 16.9997 10.6665C16.9163 10.5832 16.7497 10.5832 16.583 10.5832H3.33301Z" fill={color} />
      <path d="M8.16667 13.9167H4.33333C4 13.9167 3.75 13.6667 3.75 13.3333C3.75 13 4 12.75 4.33333 12.75H8.08333C8.41667 12.75 8.66667 13 8.66667 13.3333C8.66667 13.6667 8.41667 13.9167 8.16667 13.9167Z" fill={color} />
      <path d="M12.1667 13.9168C11.6667 13.9168 11.25 13.5002 11.25 13.0002C11.25 12.5002 11.6667 12.0835 12.1667 12.0835V13.9168Z" fill={color} />
      <path d="M12.167 13.9168V12.0835C12.667 12.0835 13.0837 12.5002 13.0837 13.0002C13.0837 13.5002 12.667 13.9168 12.167 13.9168Z" fill={color} />
      <path d="M15.3337 13.9168C14.8337 13.9168 14.417 13.5002 14.417 13.0002C14.417 12.5002 14.8337 12.0835 15.3337 12.0835V13.9168Z" fill={color} />
      <path d="M15.333 13.9168V12.0835C15.833 12.0835 16.2497 12.5002 16.2497 13.0002C16.2497 13.5002 15.833 13.9168 15.333 13.9168Z" fill={color} />
      <path d="M5.66667 10.5832C5.33333 10.5832 5 10.3332 5 9.99984V7.49984C5 7.1665 5.25 6.9165 5.58333 6.9165C5.91667 6.9165 6.25 7.1665 6.25 7.49984V9.99984C6.25 10.3332 5.91667 10.5832 5.66667 10.5832Z" fill={color} />
      <path d="M14.3333 10.5832C14 10.5832 13.75 10.3332 13.75 9.99984V7.49984C13.75 7.1665 14 6.9165 14.3333 6.9165C14.6667 6.9165 15 7.1665 15 7.49984V9.99984C15 10.3332 14.6667 10.5832 14.3333 10.5832Z" fill={color} />
      <path d="M17.3329 8.0835H2.66627C2.33294 8.0835 1.9996 8.00016 1.66627 7.8335C1.41627 7.66683 1.16627 7.3335 0.999602 7.0835L0.0829355 4.8335C0.0829355 4.75016 -0.000397857 4.5835 0.0829355 4.41683C0.0829355 4.25016 0.166269 4.0835 0.249602 4.00016C0.332935 3.91683 0.416269 3.75016 0.582935 3.66683C0.749602 3.5835 0.832935 3.5835 0.999602 3.5835H19.0829C19.2496 3.5835 19.4163 3.66683 19.4996 3.66683C19.6663 3.75016 19.7496 3.8335 19.8329 4.00016C19.9163 4.0835 19.9163 4.25016 19.9996 4.41683C19.9996 4.5835 19.9996 4.75016 19.9163 4.8335L18.9996 7.00016C18.8329 7.3335 18.5829 7.5835 18.3329 7.75016C17.9996 8.00016 17.6663 8.0835 17.3329 8.0835ZM2.66627 6.91683H17.3329C17.4163 6.91683 17.5829 6.91683 17.6663 6.8335C17.7496 6.75016 17.8329 6.66683 17.9163 6.5835L18.6663 4.75016H1.33294L2.08294 6.5835C2.08294 6.66683 2.16627 6.75016 2.33294 6.8335C2.41627 6.91683 2.4996 6.91683 2.66627 6.91683ZM1.16627 4.3335C1.16627 4.41683 1.16627 4.41683 1.16627 4.3335V4.3335Z" fill={color} />
    </svg>
  );
};
