import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { applyCustomersSort } from 'store/actions/GuestListActions';
import { Menu } from 'components/backoffice/menu';
import { MenuItem } from 'components/backoffice/menu-item';
import { NavButton } from 'components/buttons';
import { SortDownIcon } from 'assets/icons/guest-list/SortDownIcon';

import type { State } from 'store';

export const SortDownButton: FC = () => {
  const dispatch = useDispatch();

  const sortOptions = useSelector((state: State) => state.guestList.sortOptions);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const baseActions = [
    {
      text: 'Sort alphabetically',
      onClick: () => {
        dispatch(
          applyCustomersSort({
            timeReceivedSortAscending: true,
            timeExpectedSortAscending: false,
            alphaSortAscending: !sortOptions.alphaSortAscending,
            isAlphaSort: true,
          })
        );
        handleClose();
      },
    },
    {
      text: 'Sort by newest first',
      onClick: () => {
        dispatch(
          applyCustomersSort({
            timeReceivedSortAscending: true,
            timeExpectedSortAscending: false,
            alphaSortAscending: false,
            isAlphaSort: false,
          })
        );
        handleClose();
      },
    },
    {
      text: 'Sort by oldest first',
      onClick: () => {
        dispatch(
          applyCustomersSort({
            timeReceivedSortAscending: false,
            timeExpectedSortAscending: false,
            alphaSortAscending: false,
            isAlphaSort: false,
          })
        );
        handleClose();
      },
    },
    {
      text: 'Sort by time expected',
      onClick: () => {
        dispatch(
          applyCustomersSort({
            timeReceivedSortAscending: false,
            timeExpectedSortAscending: true,
            alphaSortAscending: false,
            isAlphaSort: false,
          })
        );
        handleClose();
      },
    },
  ];

  return (
    <>
      <NavButton
        aria-controls='filter-menu'
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        title='Sort'
        onClick={handleClick}
        icon={<SortDownIcon width={16} height={16} />}
        automationId='guest-list-sort-button'
        margin='0'
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {baseActions.map((action) => (
          <MenuItem onClick={action.onClick} value={action.text} key={action.text}>
            {action.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
