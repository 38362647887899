import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import { AssignCustomerIcon } from 'assets/icons/cart/AssignCustomer';
import { colors } from 'css/Theme';
import { Chevron as _Chevron, ChevronDirections } from 'assets/icons/chevron';
import { CustomerInfo } from './components/CustomerInfo/CustomerInfo';
import { LoadingButton } from 'components/buttons';
import { logger } from 'util/logger';
import { Pill, Skeleton } from 'components/misc';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as LoyaltyIcon } from 'assets/icons/loyalty-icon.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-profile.svg';
import { StarIcon } from 'assets/icons/star-icon';
import { useCartHeader } from './useCartHeader';

export type CartHeaderProps = {
  loading?: boolean;
  showInitialPopup: boolean;
};

const CartHeaderComponent = (props: CartHeaderProps): JSX.Element => {
  const {
    customerNameText,
    customerNicknameText,
    handleClickCollapseCustomerInfo,
    handleClickCustomerName,
    handleClickHistory,
    handleClickProfile,
    handleClickRegisterForLoyalty,
    hideGuestProfileButton,
    isCustomerInfoOpen,
    isGuestNameClickable,
    showAllGuestInfo,
    showAssignCustomerButton,
    showCollapsedCustomerInfo,
    showCustomerBannedPill,
    showCustomerNickname,
    showGuestActionButtons,
    showLoadingSkeleton,
    showLoyaltyStar,
    showRegisterForLoyalty,
  } = useCartHeader(props);

  return (
    <Container data-testid='cart-header_div_container'>
      <TopRow>
        <GuestNameSection>
          <GuestFullName
            clickable={isGuestNameClickable}
            onClick={handleClickCustomerName}
            data-testid='cart-header_customer-name'
          >
            {showLoadingSkeleton ? <Skeleton height={24} width={219} /> : customerNameText}
            {showCustomerNickname && (
              <NicknameContainer>
                (<Nickname>{customerNicknameText}</Nickname>)
              </NicknameContainer>
            )}
            {showAssignCustomerButton && <AssignCustomerIcon automationId='cart-header_assign-profile-icon' />}
          </GuestFullName>
          {showLoyaltyStar && (
            <StyledStarIcon>
              <StarIcon color={colors.dutchie.starGold} automationId='order-card_loyalty_svg' />
            </StyledStarIcon>
          )}
          {showCustomerBannedPill && (
            <Pill text='Banned customer' variation='red' automationId='banned-customer-pill' />
          )}
          {showCollapsedCustomerInfo && (
            <CollapseCustomerInfoButton
              isOpen={isCustomerInfoOpen}
              width={24}
              height={24}
              fill={colors.dutchie.almostBlack}
              direction={ChevronDirections.DOWN}
              onClick={handleClickCollapseCustomerInfo}
            />
          )}
        </GuestNameSection>
        <ActionButtons>
          {showRegisterForLoyalty && (
            <ActionButton secondary onClick={handleClickRegisterForLoyalty}>
              <LoyaltyIcon width={16} height={16} />
              Register for Loyalty
            </ActionButton>
          )}
          {showGuestActionButtons && (
            <>
              {!hideGuestProfileButton && (
                <ActionButton secondary onClick={handleClickProfile} automationId='cart-header_profile-button'>
                  <UserIcon width={16} height={16} />
                </ActionButton>
              )}
              {showAllGuestInfo && (
                <ActionButton secondary onClick={handleClickHistory} automationId='cart-header_history-button'>
                  <Clock width={16} height={16} />
                </ActionButton>
              )}
            </>
          )}
        </ActionButtons>
      </TopRow>
      {showAllGuestInfo && <CustomerInfo show={isCustomerInfoOpen} loading={showLoadingSkeleton} />}
    </Container>
  );
};

export const CartHeader = (props: CartHeaderProps) => {
  const fallback = (
    <Container>
      <TopRow>
        <GuestNameSection>
          <GuestFullName>Error loading guest...</GuestFullName>
        </GuestNameSection>
      </TopRow>
      <div>Please refresh to try again</div>
    </Container>
  );

  const logError = (error: Error, errorInfo: { componentStack: string }) => {
    logger.error(error, { componentStack: errorInfo.componentStack });
  };

  return (
    <ErrorBoundary fallback={fallback} onError={logError}>
      <CartHeaderComponent {...props} />
    </ErrorBoundary>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  min-height: 5.75rem;
  gap: 0.75rem;
  border-bottom: 1px solid ${colors.dutchie.shadowGrey};
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 6%);
  z-index: 2;
  padding: 1.5rem;
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

const GuestNameSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const GuestFullName = styled.div<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.875rem;
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
  ${({ clickable }) => clickable && `cursor: pointer;`}
`;

const NicknameContainer = styled.div`
  display: flex;
  color: ${colors.dutchie.gray80};
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 400;
  text-transform: none;
`;

const Nickname = styled.div`
  max-width: clamp(0px, 30vw, 500px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CollapseCustomerInfoButton = styled(_Chevron)<{ isOpen?: boolean }>`
  ${({ isOpen }) => isOpen && `transform: rotate(180deg);`}
  transition: transform 0.3s;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
`;

const ActionButton = styled(LoadingButton)`
  gap: 0.5rem;
`;

const StyledStarIcon = styled.div`
  background-color: #f4f2b0;
  border-radius: 20px;
  display: block;
  width: 28px;
  height: 24px;

  svg {
    padding: 4px 6px;
    width: 28px;
    height: 24px;
  }
`;
