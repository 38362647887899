import React, { ComponentProps } from 'react';

import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { VerifyBirthdateModal } from './VerifyBirthdateModal';
import { VerifyBirthdatePopup as VerifyBirthdatePopupDeprecated } from './VerifyBirthdatePopup-deprecated';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type VerifyBirthdateProps = ComponentProps<typeof VerifyBirthdatePopupDeprecated>;

export const VerifyBirthdatePopup = (props: VerifyBirthdateProps) => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { isModalBridgeEnabled } = useModalBridge();

  return isAnonymousCartLDFlagEnabled || isModalBridgeEnabled ? (
    <VerifyBirthdateModal
      guestDob={props.guestDob}
      guestId={props.acctId}
      hide={props.hide}
      isGuestAnonymous={props.isAnonymous}
      onSuccess={(date: Date | null) => props.onSuccess(date ?? undefined)}
      shipmentId={props.shipmentId}
    />
  ) : (
    <VerifyBirthdatePopupDeprecated {...props} />
  );
};
