import React, { FC } from 'react';
import styled from 'styled-components';
import { round } from 'lodash';

import { Table } from 'components/tables';

import type { Column } from 'components/tables';
import type { PriceDetails } from 'queries/v2/inventory/types';

export type PricingTierGridProps = {
  priceDetails: PriceDetails[];
};

export const PricingTierGrid: FC<PricingTierGridProps> = ({ priceDetails }) => {
  const medTotal = (priceDetails: PriceDetails): string => {
    return `$${round(priceDetails.medicalPrice, 2).toFixed(2)} / $${round(
      priceDetails.startWeight * priceDetails.medicalPrice,
      2
    ).toFixed(2)}`;
  };

  const recTotal = (priceDetails: PriceDetails): string => {
    return `$${round(priceDetails.price, 2).toFixed(2)} / $${round(
      priceDetails.startWeight * priceDetails.price,
      2
    ).toFixed(2)}`;
  };

  const columns: Column<PriceDetails>[] = [
    { header: 'WEIGHT (G)', field: 'startWeight', disableSort: true },
    { header: 'REC', field: recTotal, disableSort: true },
    { header: 'MED', field: medTotal, disableSort: true },
  ];

  return (
    <GridTableContainer>
      <Table<PriceDetails> data={priceDetails} columns={columns} />
    </GridTableContainer>
  );
};

const GridTableContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  & table {
    font-size: 0.85rem;
    & th,
    th button {
      font-size: 0.75rem;
    }
    & th,
    td {
      border-bottom: none;
      padding: 0.25rem 0.75rem;
    }
    tr {
      th:last-child,
      td:last-child {
        text-align: right;
      }
    }
  }
`;
