import React, { FC } from 'react';

import { useOverflowMenu } from './useOverflowMenu';
import { MenuAnchor } from './components/MenuAnchor';
import { Menu } from './components/Menu';
import { MenuOption } from './components/MenuOption';
import { MenuDivider } from './components/MenuDivider';

import type { MenuProps, AnchorProps, OverflowMenuOption } from './OverflowMenu.types';

export type OverflowMenuProps = {
  anchor: React.ReactNode;
  anchorProps?: AnchorProps;
  menuOptions: OverflowMenuOption[];
  altMenuOptions?: OverflowMenuOption[];
  menuProps?: MenuProps;
  automationId?: string;
};

export const OverflowMenu: FC<OverflowMenuProps> = ({
  anchor,
  anchorProps,
  menuOptions,
  altMenuOptions,
  menuProps: { textAlign = 'left', fontSize, ...menuProps } = {},
  automationId = 'overflow-menu',
}) => {
  const { anchorElement, isMenuOpen, handleAnchorClick, handleClose, handleOptionClick, menuOptionsToDisplay } =
    useOverflowMenu({
      menuOptions,
      altMenuOptions,
    });

  return (
    <>
      <MenuAnchor {...anchorProps} onClick={handleAnchorClick} data-testid={`${automationId}_menu_anchor`}>
        {anchor}
      </MenuAnchor>
      <Menu
        autoFocus={false}
        {...menuProps}
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={handleClose}
        data-testid={`${automationId}_menu_list`}
      >
        {menuOptionsToDisplay.map((option, i) => {
          if (option.divider) {
            return menuOptions[i - 1].divider ? null : <MenuDivider />;
          } else {
            return (
              <MenuOption
                onClick={(e) => handleOptionClick(e, option.onClick)}
                key={option.text}
                danger={option.danger}
                fontSize={fontSize}
                align={textAlign}
                data-testid={`${automationId}_menu_menu-option_${option.text}`}
              >
                {option.text}
              </MenuOption>
            );
          }
        })}
      </Menu>
    </>
  );
};
