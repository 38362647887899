import { CustomerHistoryPanelTabs } from 'components/cart/CustomerHistoryPanel/CustomerHistoryPanel';
import { openCustomerSelectionPanel, openCustomerHistoryPanel } from 'store/actions/CartActions';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useGetTransactionHistoryQuery } from 'queries/v2/transaction-history/transaction-history';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CustomerInsightsProps } from './CustomerInsights';

export const useCustomerInsights = ({ isLoading }: CustomerInsightsProps) => {
  const dispatch = useAppDispatch();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { guestId } = useTransactionManager();

  // Either an anonymous guest record or no guest record is still anonymous
  const isGuestAnonymous = useAppSelector((state) => state.customer.details?.IsAnonymous) ?? true;

  const { data: numberOfTransactions, isLoading: isLoadingTransactions } = useGetTransactionHistoryQuery(
    { guestId },
    {
      enabled: !!guestId,
      cacheTime: 0,
      select: (transactions) => transactions.length,
    }
  );

  // Computed values

  const isDisabled = (!isAnonymousCartLDFlagEnabled && isLoading) || (!isGuestAnonymous && isLoadingTransactions);
  const isNewCustomer = (numberOfTransactions ?? 3) < 3 && !isLoadingTransactions;
  const showAssignCustomerButton = isAnonymousCartLDFlagEnabled && isGuestAnonymous;

  const title = isNewCustomer && !showAssignCustomerButton ? 'Customer insights' : 'Product history';
  const linkLabel = showAssignCustomerButton ? 'Assign customer' : isNewCustomer ? null : 'View';
  const lockedMessage = showAssignCustomerButton
    ? 'Assign customer to view product history'
    : isNewCustomer
    ? 'Insights available after 3 orders'
    : null;

  const handleOnClick = () => {
    if (showAssignCustomerButton) {
      dispatch(openCustomerSelectionPanel());
    } else {
      dispatch(openCustomerHistoryPanel({ tab: CustomerHistoryPanelTabs.Products }));
    }
  };

  return {
    handleOnClick,
    isDisabled,
    linkLabel,
    lockedMessage,
    title,
  };
};
