import { useCallback, useEffect, useMemo } from 'react';

import { CartDisplayItem, CartItemType } from './CartItems.types';
import { logger, customEventKeys } from 'util/logger';
import { useAppSelector, useChangeCartQtyFromCartItem } from 'util/hooks';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CartItem, Preorder } from 'models/Cart';

export const getQty = (item: CartItem) => {
  return item.WgtCnt === 'Qty' ? item.QtySelected : item?.Grams ?? 0;
};

export const getReducedPreorders = (preorders: Preorder[]): Preorder[] => {
  const preordersWithQty = preorders.filter((item) => !!item.QtySelected);

  const reducedPreorders = preordersWithQty.reduce((array: Preorder[], preorder: Preorder) => {
    const addedIndex = array.findIndex((item) => item.ProductId === preorder.ProductId);
    if (addedIndex >= 0) {
      const alreadyAdded = array[addedIndex];
      array.splice(addedIndex, 1, {
        ...alreadyAdded,
        QtySelected: alreadyAdded.QtySelected + preorder.QtySelected,
      });
    } else {
      array.push(preorder);
    }
    return array;
  }, []);

  return reducedPreorders;
};

/**
 * Get an updated preorder object with the amount and cost not yet fulfilled
 */
const getPreorderItemToBeFulfilled = (preorder: Preorder, qtyAlreadyInCart: number): Preorder => {
  const updatedPreorder = Object.assign({}, preorder);
  updatedPreorder.QtySelected = preorder.QtySelected - qtyAlreadyInCart;
  updatedPreorder.TotalCost = (1 - qtyAlreadyInCart / preorder.QtySelected) * updatedPreorder.TotalCost;

  return updatedPreorder;
};

/**
 * Get all preorder items, unfulfilled AND fulfilled
 * Persist original order. Any fulfilled preorder items populate directly under the unfulfilled item,
 * or take its place.
 */
const getPreorderItems = (preorders: Preorder[], cart: CartItem[]): CartDisplayItem[] => {
  const preorderItems: CartDisplayItem[] = [];

  if (preorders.length) {
    const reducedPreorders = getReducedPreorders(preorders);

    for (const preorder of reducedPreorders) {
      const cartItemForPreorderItem = cart.filter((x: CartItem) => x.ProductId === preorder.ProductId);
      const qtyAlreadyInCart = cartItemForPreorderItem.reduce((prev: number, cur: CartItem) => prev + getQty(cur), 0);

      // Add unfulfilled preorder item first
      if (qtyAlreadyInCart < preorder.QtySelected) {
        const preorderItemToBeFulfilled = getPreorderItemToBeFulfilled(preorder, qtyAlreadyInCart);

        const updatedPreorderItem: CartDisplayItem = {
          ...preorderItemToBeFulfilled,
          type: CartItemType.Preorder,
        };

        preorderItems.push(updatedPreorderItem);
      }

      // Add fulfilled preorder item(s) second
      if (qtyAlreadyInCart) {
        cartItemForPreorderItem.forEach((cartItem: CartItem) => {
          const updatedCartItem: CartDisplayItem = {
            ...cartItem,
            type: CartItemType.Cart,
          };

          preorderItems.push(updatedCartItem);
        });
      }
    }
  }

  return preorderItems;
};

/**
 * Get all cart items that were never part of a preorder
 */
const getCartItems = (preorders: Preorder[], cart: CartItem[]): CartDisplayItem[] => {
  const cartItems: CartDisplayItem[] = [];

  cart.forEach((cartItem: CartItem) => {
    const isNonPreorderCartItem = !preorders.find((preorderItem: Preorder) => {
      const cartItemMatchesPreorder = preorderItem.ProductId === cartItem.ProductId;
      const preorderHasQty = preorderItem.QtySelected > 0;
      return cartItemMatchesPreorder && preorderHasQty;
    });

    if (isNonPreorderCartItem) {
      const nonPreorderCartItem: CartDisplayItem = {
        ...cartItem,
        type: CartItemType.Cart,
      };

      cartItems.push(nonPreorderCartItem);
    }
  });

  return cartItems;
};

const useActions = () => {
  const printNonCannabisLabels = useAppSelector((state) => state.settings.features.PrintNonCannabisLabels);

  const { shipmentId } = useTransactionManager();

  const {
    data: { Cart: cartItems },
  } = useGetCartDetails();

  const { printLabels, previewLabels } = usePrintJob();

  const changeCartQty = useChangeCartQtyFromCartItem();

  const onRemoveItem = useCallback(
    (item: CartItem) => {
      changeCartQty(item, 0);
    },
    [changeCartQty]
  );

  const onPrintOneLabel = useCallback(
    (item: CartItem) => {
      shipmentId && printLabels({ guest: { ShipmentId: shipmentId }, items: item, printAll: false, autoPrint: false });
    },
    [printLabels, shipmentId]
  );

  const onPrintAllLabel = useCallback(
    (item: CartItem) => {
      shipmentId && printLabels({ guest: { ShipmentId: shipmentId }, items: item, printAll: true, autoPrint: false });
    },
    [printLabels, shipmentId]
  );

  const onDownloadAllLabels = useCallback(() => {
    if (printNonCannabisLabels) {
      shipmentId && previewLabels({ guest: { ShipmentId: shipmentId }, items: cartItems, printAll: true });
    } else {
      const cannabisItemsOnly = cartItems.filter((x) => x.CannbisProduct === 'Yes');
      shipmentId && previewLabels({ guest: { ShipmentId: shipmentId }, items: cannabisItemsOnly, printAll: true });
    }
  }, [cartItems, previewLabels, shipmentId, printNonCannabisLabels]);

  return {
    onRemoveItem,
    onPrintOneLabel,
    onPrintAllLabel,
    onDownloadAllLabels,
  };
};

/**
 * cartItems includes unfulfilled preorder items, fulfilled preorder items,
 * and cart items that were never part of a preorder
 */
export const useCartDisplayItems = () => {
  const {
    data: { Cart: items, PreOrders: preorders },
  } = useGetCartDetails();

  const cartItems: CartDisplayItem[] = useMemo(() => {
    const preorderItems = getPreorderItems(preorders, items); // Includes unfulfilled AND fulfilled preorder items
    const cartItems = getCartItems(preorders, items); // Includes only cart items that were never part of a preorder

    return preorderItems.concat(cartItems);
  }, [items, preorders]);

  return { cartItems };
};

export const useCartItems = () => {
  const {
    data: { Discounts: cartDiscounts, Locked: isCartLocked },
  } = useGetCartDetails();

  const { cartItems } = useCartDisplayItems();
  const { onRemoveItem, onPrintOneLabel, onPrintAllLabel, onDownloadAllLabels } = useActions();

  useEffect(() => {
    if (cartItems.length) {
      logger.info(`change in cart items (count: ${cartItems.length})`, {
        key: customEventKeys.cart.itemsChange,
        items: cartItems,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems.length]);

  return {
    cartDiscounts,
    isCartLocked,
    cartItems,
    onRemoveItem,
    onPrintOneLabel,
    onPrintAllLabel,
    onDownloadAllLabels,
  };
};
