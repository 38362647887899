import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useFixedCartTotalsLoadingState = () => {
  const ldClient = useLDClient();
  const isFixedCartTotalsLoadingStateEnabled =
    ldClient?.variation('pos.register.fixed-cart-totals-loading-state', false) === true;

  return {
    /** pos.register.fixed-cart-totals-loading-state */
    isFixedCartTotalsLoadingStateEnabled,
  };
};
