import { useState, useCallback, useEffect } from 'react';

import type { ProductSearchResult } from 'queries/v2/product/types';
import { customEventKeys, logger } from 'util/logger';

export const useAddItemPanel = () => {
  const [rightPanel, setRightPanel] = useState<HTMLDivElement | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<ProductSearchResult>();

  const showSelectedProduct = !!selectedProduct;

  // Handle right panel animation

  const transitionEnd = useCallback(() => {
    if (selectedProduct) {
      rightPanel?.classList.remove('content-hidden');
    } else {
      rightPanel?.classList.add('content-hidden');
    }
  }, [rightPanel, selectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      logger.info(
        `selected product in add items panel: ${selectedProduct?.productDescription.slice(
          0,
          -1
        )} - ${selectedProduct?.serialNo.trim().slice(-4)}`,
        {
          key: customEventKeys.cart.addItemsPanel.selectItem,
          selectedProduct,
        }
      );
    }
  }, [selectedProduct]);

  useEffect(() => {
    rightPanel?.addEventListener('transitionend', transitionEnd);
    return () => {
      rightPanel?.removeEventListener('transitionend', transitionEnd);
    };
  }, [transitionEnd, rightPanel]);

  return {
    showSelectedProduct,
    selectedProduct,
    setSelectedProduct,
    setRightPanelRef: setRightPanel,
  };
};
