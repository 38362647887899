import React from 'react';
import styled from 'styled-components';

import { useProductHistory } from './useProductHistory';
import { SearchBar, SearchResults } from 'components/layout';
import { StyleVariant, Table } from 'components/tables';

import type { ProductHistoryItem } from 'queries/v2/product/types';

export const ProductHistory = () => {
  const {
    handleChangeSearch,
    handleSearch,
    errorMessage,
    isProductHistoryLoading,
    maxTableHeight,
    productHistoryItems,
    searchValue,
    tableColumns,
    tableContainerRef,
  } = useProductHistory();

  return (
    <Container>
      <SearchBar
        placeholder='Search'
        initialValue={searchValue}
        onChange={handleChangeSearch}
        onSearch={handleSearch}
        maxWidth='100%'
      />
      <SearchResults
        errMessage={errorMessage}
        loading={isProductHistoryLoading}
        loadingText='Loading product history'
        isEmpty={productHistoryItems?.length === 0}
        emptyMessage='There is no product history or it could not be retrieved'
      >
        <TableContainer ref={tableContainerRef} maxHeight={maxTableHeight}>
          <Table<ProductHistoryItem>
            data={productHistoryItems ?? []}
            sortColumn={1}
            sortDirection='desc'
            pageSize={10}
            columns={tableColumns}
            styleVariant={StyleVariant.roundedWithWhiteBackground}
          />
        </TableContainer>
      </SearchResults>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
`;

const TableContainer = styled.div<{ maxHeight?: number }>`
  overflow: auto;
  width: 100%;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'calc(100% - 7rem)')};

  table {
    border: none;
    max-height: 100%;
  }
`;
