import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { voidReasonsKeys } from './query-key-factory';
import { transformToVoidReason } from './types';

import type { VoidReasonServerResponse } from './types';

const GET_VOID_REASONS = 'posv3/maintenance/get-void-reasons';

export const useVoidReasonsQuery = () => {
  return useQuery({
    queryKey: voidReasonsKeys.all,
    queryFn: async () => {
      const response = await post<VoidReasonServerResponse[]>(GET_VOID_REASONS);

      return response.map(transformToVoidReason);
    },
  });
};
