import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, every } from 'lodash';

import { State } from 'store';
import { warningNotification } from 'store/actions/NotificationsActions';
import { deselectPreorderProduct, selectPreorderProduct } from 'store/actions/ProductsActions';
import { loadPreorderProducts, PreorderProductSearchResult } from 'api/PreorderApi';

import { useProductSearchPricing } from 'util/hooks/useProductSearchPricing';
import { Column, Table } from 'components/tables';
import { FilterOption } from 'models/Search';
import { searchAndFilter } from 'util/Helpers';
import { PreorderAddItemsFilterDropdown } from 'components/menus/FilterMenu/FilterDropdown';
import { TableContainer, FlexContainer, ResetFilters, StyledSearchBar } from 'components/cart/AddItemsPanel.styles';
import {
  potencyUnitIdToDisplayNameMap,
  sortByCBD,
  sortByTHC,
} from 'components/tables/ProductSearchFilter/helpers/sortByPotency/sortByPotency-preorders';
import { PreorderProductSearchResults } from './components/PreorderProductSearchResults';
import { ProductSearchFilterSkeleton } from 'components/tables/ProductSearchFilter/ProductSearchFilterSkeleton';

export type PreorderProductFilterOption = FilterOption<PreorderProductSearchResult>;

type PreorderProductsSearchFilterProps = {
  filters: PreorderProductFilterOption[];
  setFilters: React.Dispatch<React.SetStateAction<PreorderProductFilterOption[]>>;
};

const quantityOrGrams = (product: PreorderProductSearchResult): string => {
  switch (product.UnitId) {
    case 1:
      return `${product.Quantity}`;
    default:
      return `${product.Quantity}${product.UnitAbbr}`;
  }
};

export const PreorderProductsSearchFilter = React.memo(PreorderProductsSearchFilterImpl);

function PreorderProductsSearchFilterImpl({ filters, setFilters }: PreorderProductsSearchFilterProps): JSX.Element {
  const dispatch = useDispatch();
  const selectedProduct = useSelector((state: State) => state.products.selectedPreorderProduct);
  const [products, setProducts] = useState<PreorderProductSearchResult[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<PreorderProductSearchResult[]>([]);
  const [query, setQuery] = useState('');
  const [loading, setIsLoading] = useState(true);

  const [wasErrorLoadingProducts, setWasErrorLoadingProducts] = useState(false);
  const [noInventoryFound, setNoInventoryFound] = useState(false);
  const noMatchingProductsFound = filteredProducts.length === 0 && (filters.length > 0 || query.length > 0) && !loading;

  const { getPriceField, getPrice } = useProductSearchPricing();

  const loadProducts = useCallback(async () => {
    try {
      setIsLoading(true);
      setWasErrorLoadingProducts(false);
      const newProducts = await loadPreorderProducts();
      setProducts(newProducts);
      setFilteredProducts(newProducts);
      setNoInventoryFound(newProducts.length === 0);
      setIsLoading(false);
    } catch (e) {
      setWasErrorLoadingProducts(true);
      setNoInventoryFound(false);
      setIsLoading(false);
      dispatch(warningNotification(`Error attempting to load inventory`));
    }
  }, [dispatch]);

  useEffect(() => {
    if (products.length === 0 && !wasErrorLoadingProducts) {
      loadProducts();
    }
  }, [loadProducts, products, wasErrorLoadingProducts]);

  useEffect(() => {
    const debouncedSetFilteredProducts = debounce(() => {
      const keysToSearch: Array<keyof PreorderProductSearchResult> = [
        'ProductName',
        'ProductNo',
        'BrandName',
        'VendorName',
      ];

      setFilteredProducts(searchAndFilter<PreorderProductSearchResult>(products, filters, keysToSearch, query));
    }, 500);

    if (products.length) {
      debouncedSetFilteredProducts();
    }
    // discluding products in the deps so we don't unnecessarily set filteredProducts twice on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, query]);

  const handleSearch = (query: string) => {
    dispatch(deselectPreorderProduct());
    setQuery(query);
  };

  const handleSelectProduct = (product: PreorderProductSearchResult) => dispatch(selectPreorderProduct(product));

  const onApplyFilters = useCallback(
    (filters: Array<PreorderProductFilterOption>) => {
      const filtersAreEmpty = every(filters, (filter) => filter.options.length === 0);
      setFilters(filtersAreEmpty ? [] : filters);
    },
    [setFilters]
  );

  const onResetFilters = () => {
    setFilters([]);
  };

  const filterOptions = useMemo(() => {
    const strainOptionsSet = new Set<string>();
    const categoryOptionsSet = new Set<string>();
    const brandOptionsSet = new Set<string>();
    const vendorOptionsSet = new Set<string>();

    products.forEach((p) => {
      if (p.StrainName?.length) {
        strainOptionsSet.add(p.StrainName);
      }
      if (p.Category?.length) {
        categoryOptionsSet.add(p.Category);
      }
      if (p.BrandName?.length) {
        brandOptionsSet.add(p.BrandName);
      }
      if (p.VendorName?.length) {
        vendorOptionsSet.add(p.VendorName);
      }
    });

    const filterOptions: Array<PreorderProductFilterOption> = [
      { label: 'Categories', key: 'Category', options: [...categoryOptionsSet] },
      { label: 'Strains', key: 'StrainName', options: [...strainOptionsSet] },
      { label: 'Brands', key: 'BrandName', options: [...brandOptionsSet] },
      { label: 'Vendors', key: 'VendorName', options: [...vendorOptionsSet] },
    ];
    return filterOptions;
  }, [products]);

  const tableColumns: Column<PreorderProductSearchResult>[] = [
    { header: 'Product', field: 'ProductName' },
    { header: 'Category', field: 'Category' },
    { header: 'Subcategory', field: 'MasterCategory' },
    {
      header: 'THC',
      field: 'THCContent',
      Cell: ({ item }) => {
        if (typeof item.THCContent !== 'number' || typeof item.THCContentUnitId !== 'number') {
          return null;
        }
        return (
          <>{`${item.THCContent}${
            potencyUnitIdToDisplayNameMap[item.THCContentUnitId as keyof typeof potencyUnitIdToDisplayNameMap]
          }`}</>
        );
      },
      customSort: sortByTHC,
    },
    {
      header: 'CBD',
      field: 'CBDContent',
      Cell: ({ item }) => {
        if (typeof item.CBDContent !== 'number' || typeof item.THCContentUnitId !== 'number') {
          return null;
        }
        return (
          <>{`${item.CBDContent}${
            potencyUnitIdToDisplayNameMap[item.CBDContentUnitId as keyof typeof potencyUnitIdToDisplayNameMap]
          }`}</>
        );
      },
      customSort: sortByCBD,
    },
    {
      header: 'Quantity',
      field: quantityOrGrams,
      cellProps: { style: { textAlign: 'right' } },
    },
    {
      header: 'Price',
      field: getPriceField(),
      Cell: ({ item }) => {
        const price = getPrice(item);
        return <>${price ? `${Number(price).toFixed(2)}` : '0.00'}</>;
      },
    },
  ];

  const selectedFilterCount = filters.reduce(
    (totalSelectedFilters, { options: currOptions }) => totalSelectedFilters + currOptions?.length,
    0
  );

  return (
    <div>
      <FlexContainer>
        <StyledSearchBar
          initialValue={query}
          maxWidth='inherit'
          onSearch={handleSearch}
          onChange={handleSearch}
          placeholder='Product search...'
          inputId='productSearchBar'
        />
      </FlexContainer>
      {loading ? (
        <ProductSearchFilterSkeleton />
      ) : (
        <>
          <FlexContainer>
            <PreorderAddItemsFilterDropdown
              title='Categories'
              appliedFilters={filters}
              filterOptions={filterOptions}
              filterKey='Category'
              onApply={onApplyFilters}
            />
            <PreorderAddItemsFilterDropdown
              title='Strains'
              appliedFilters={filters}
              filterOptions={filterOptions}
              filterKey='StrainName'
              onApply={onApplyFilters}
            />
            <PreorderAddItemsFilterDropdown
              title='Brands'
              appliedFilters={filters}
              filterOptions={filterOptions}
              filterKey='BrandName'
              onApply={onApplyFilters}
            />
            <PreorderAddItemsFilterDropdown
              title='Vendors'
              appliedFilters={filters}
              filterOptions={filterOptions}
              filterKey='VendorName'
              onApply={onApplyFilters}
            />
            {selectedFilterCount > 0 && <ResetFilters onClick={onResetFilters}>Clear Filters</ResetFilters>}
          </FlexContainer>

          <PreorderProductSearchResults
            wasErrorLoadingProducts={wasErrorLoadingProducts}
            noInventoryFound={noInventoryFound}
            noMatchingProductsFound={noMatchingProductsFound}
            reloadProducts={loadProducts}
          >
            <TableContainer>
              <Table<PreorderProductSearchResult>
                data={filteredProducts}
                columns={tableColumns}
                sortColumn={0}
                sortDirection={'asc'}
                onClick={handleSelectProduct}
                selected={selectedProduct ? [selectedProduct] : []}
                pageSize={20}
              />
            </TableContainer>
          </PreorderProductSearchResults>
        </>
      )}
    </div>
  );
}
