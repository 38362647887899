import { getLDClient } from 'util/launchDarkly';
import type { ProductSearchResult, ProductFilterOption, InventoryTag } from 'queries/v2/product/types';

type UseFilterOptionProps = {
  products?: ProductSearchResult[];
};

export const getFilterOptions = ({ products }: UseFilterOptionProps) => {
  const ldClient = getLDClient();
  const isInventoryTagFilteringInCartEnabled = ldClient?.variation(
    'pos.register.inventory-tag-filtering-in-cart.rollout',
    false
  );

  const strainOptionsSet = new Set<ProductSearchResult['strain']>();
  const categoryOptionsSet = new Set<ProductSearchResult['productCategory']>();
  const brandOptionsSet = new Set<ProductSearchResult['brandName']>();
  const vendorOptionsSet = new Set<ProductSearchResult['vendor']>();
  const inventoryTagsOptionsSet = new Set<InventoryTag['tagName']>();

  products?.forEach((product) => {
    if (product.strain.length) {
      strainOptionsSet.add(product.strain);
    }
    if (product.productCategory.length) {
      categoryOptionsSet.add(product.productCategory);
    }
    if (product.brandName?.length) {
      brandOptionsSet.add(product.brandName);
    }
    if (product.vendor?.length) {
      vendorOptionsSet.add(product.vendor);
    }
    if (isInventoryTagFilteringInCartEnabled && product.inventoryTags?.length) {
      product.inventoryTags.forEach((inventoryTag) => {
        inventoryTagsOptionsSet.add(inventoryTag.tagName);
      });
    }
  });

  const filterOptions: ProductFilterOption[] = [
    { label: 'Categories', key: 'productCategory', options: [...categoryOptionsSet] },
    { label: 'Strains', key: 'strain', options: [...strainOptionsSet] },
    { label: 'Brands', key: 'brandName', options: [...brandOptionsSet] },
    { label: 'Vendors', key: 'vendor', options: [...vendorOptionsSet] },
  ];

  if (isInventoryTagFilteringInCartEnabled) {
    filterOptions.push({ label: 'Tags', key: 'inventoryTags', options: [...inventoryTagsOptionsSet] });
  }

  return filterOptions;
};
