import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { clamp } from 'lodash';

import { PreorderCartItem } from 'pages/CreatePreOrderPage';
import { AllotmentLimit } from './AllotmentLimit';
import { DaysSupplyLimit } from './DaysSupplyLimit';
import { MMCEULimit } from './MMCEULimit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';

export const getUsedPercentageData = (used: number, total: number) => {
  let usedPercentage = (used / total) * 100;
  usedPercentage = isNaN(usedPercentage) ? 0 : usedPercentage;
  const over = usedPercentage > 100;
  usedPercentage = clamp(usedPercentage, 0, 100);
  return { usedPercentage, over };
};

type LegalLimitProps = {
  loading?: boolean;
  preorderCartItems?: PreorderCartItem[];
  showDetailsButton?: boolean;
};

export const LegalLimit: FC<LegalLimitProps> = ({ loading, preorderCartItems, showDetailsButton = true }) => {
  const isDaysSupplyEnabled = useIsDaysSupplyFeatureEnabled();
  const isMississippiLocation = useSelector(
    (state: State) => state.settings.locationSettings?.State.toLowerCase() === 'ms'
  );
  const showDaysSupply = isDaysSupplyEnabled && !isMississippiLocation;
  const { 'pos.register.enable-mmceu-calculator.rollout': enableMMCEU } = useFlags();
  const showMMCEUSupply = isMississippiLocation && enableMMCEU;

  // Do not show Day Supply on Pre-cart until daySupply property has been added to backend
  if (showDaysSupply && preorderCartItems) {
    return null;
  }

  return (
    <>
      {showDaysSupply && <DaysSupplyLimit loading={loading} preorderCartItems={preorderCartItems} />}
      {showMMCEUSupply && <MMCEULimit loading={loading} preorderCartItems={preorderCartItems} />}
      {!showDaysSupply && !showMMCEUSupply && (
        <AllotmentLimit loading={loading} preorderCartItems={preorderCartItems} showDetailsButton={showDetailsButton} />
      )}
    </>
  );
};
