import React, { ComponentType, HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { DragDropTarget } from 'components/layout/DragDrop/DragDropTarget';

function reorder<T>(items: T[], fromPosition: number, toPosition: number): T[] {
  const newItems = [...items];
  const [element] = newItems.splice(fromPosition, 1);
  newItems.splice(toPosition, 0, element);

  return newItems;
}

type DragReorderProps<ItemType> = {
  items: ItemType[];
  onReorder: (newItemOrder: ItemType[], oldItemIndex: number, newItemIndex: number) => void;
  Item: ComponentType<{ item: ItemType }>;
  className?: string;
  payloadId?: string;
} & HTMLAttributes<HTMLDivElement>;

export function DragReorder<ItemType>({
  items,
  Item,
  onReorder,
  children,
  className = '',
  payloadId = 'application/llx-drag-reorder',
  ...divProps
}: DragReorderProps<ItemType>) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [lastHoverIndex, setLastHoverIndex] = useState<number | null>(null);

  const minPositionDisruption = Math.min(activeIndex ?? -1, lastHoverIndex ?? -1);
  const maxPositionDisruption = Math.max(activeIndex ?? -1, lastHoverIndex ?? -1);
  const calculatePosition = (index: number) => {
    if (activeIndex === null || lastHoverIndex === null) {
      return index;
    }

    if (index === activeIndex) {
      return lastHoverIndex;
    }

    if (index < minPositionDisruption || index > maxPositionDisruption) {
      return index;
    }

    const offset = lastHoverIndex < activeIndex ? 1 : -1;
    return index + offset;
  };

  const handleComplete = () => {
    if (activeIndex === null || lastHoverIndex === null) {
      return;
    }

    const newItems = reorder(items, activeIndex, lastHoverIndex);
    onReorder(newItems, activeIndex, lastHoverIndex);
    setActiveIndex(null);
    setLastHoverIndex(null);
  };

  return (
    <ReorderContainer {...divProps} className={className} data-testid='reorder-container_div_container'>
      {items.map((item, originalIndex) => {
        const newIndex = calculatePosition(originalIndex);
        return (
          <ReorderWrapper position={newIndex} key={`${originalIndex}`} data-testid='reorder-wrapper_div_section'>
            <DragDropTarget
              dragImage={null}
              effect='move'
              onDragActive={() => setActiveIndex(originalIndex)}
              onDragInactive={handleComplete}
              onDropActive={() => setLastHoverIndex(newIndex)}
              payload={`${originalIndex}`}
              payloadId={payloadId}
              data-testid='drag-drop-target_div_section'
            >
              <Item item={item} />
            </DragDropTarget>
          </ReorderWrapper>
        );
      })}
    </ReorderContainer>
  );
}

const ReorderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReorderWrapper = styled.div<{ position: number }>`
  order: ${({ position }) => position};
`;
