import React, { useCallback, useMemo, useState } from 'react';
import { clamp } from 'lodash';
import { CheckedInGuest } from 'models/Guest';

export const useTruncatedOrders = (orders: CheckedInGuest[]) => {
  const chunkSize = 25;
  const [maxShown, setMaxShown] = useState(chunkSize);

  const loadMore = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const div = e.target as HTMLDivElement;
      if (div.scrollTop + 1.5 * div.clientHeight > div.scrollHeight) {
        setMaxShown((x) => clamp(x + chunkSize, chunkSize, orders.length + 1));
      }
    },
    [orders.length]
  );

  const truncatedOrders = useMemo(() => {
    return orders?.slice(0, maxShown);
  }, [maxShown, orders]);

  return { loadMore, truncatedOrders };
};
