import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as CartApi from 'api/CartApi';
import * as DiscountApi from 'api/DiscountApi';
import { RemovePreorderCartItemRequest } from 'models/Cart';
import { AppDispatch } from 'store';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { RemoveAppliedDiscountRequest, ApplyDiscountRequest, RemoveAllAppliedDiscountsRequest } from 'models/Discounts';
import { logger } from 'util/logger';
import { refetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';

export const startScan = createAction('startScan');
export const endScan = createAction('endScan');

export const setCartItemsLoading = createAction(
  'setCartItemsLoading',
  (payload: { productId: string; isLoading: boolean }) => ({ payload })
);

export const removePreorderCartItem = createAsyncThunk(
  'removePreorderCartItem',
  async (params: RemovePreorderCartItemRequest & { ShipmentId: number; Register: number }, { dispatch }) => {
    try {
      const response = await CartApi.removePreOrderItemFromCart(params);

      await refetchCartDetails(dispatch as AppDispatch, {
        guestId: params.AcctId,
        registerId: params.Register,
        shipmentId: params.ShipmentId,
      });

      return response;
    } catch (error) {
      const errorMessage = 'There was an error removing the preorder item from the cart';
      dispatch(warningNotification(error ? error.toString() : errorMessage));
      logger.error(error, { message: errorMessage, params });
      return Promise.reject();
    }
  }
);

export const applyDiscount = createAsyncThunk('applyDiscount', async (params: ApplyDiscountRequest, { dispatch }) => {
  try {
    const response = await DiscountApi.applyDiscount(params);
    dispatch(successNotification('Discount Applied'));
    return response;
  } catch (error) {
    dispatch(errorNotification(error ? JSON.stringify(error) : 'There was an error applying the discount'));
    logger.error(error, { message: 'Error while applying discount', params });
    return Promise.reject();
  }
});

export const removeAppliedDiscount = createAsyncThunk(
  'removeAppliedDiscount',
  async (params: RemoveAppliedDiscountRequest, { dispatch }) => {
    try {
      return await DiscountApi.removeAppliedDiscount(params);
    } catch (error) {
      dispatch(errorNotification(error ? JSON.stringify(error) : 'There was an error removing the discount'));
      logger.error(error, { message: 'Error removing applied discount', params });
      return Promise.reject();
    }
  }
);

export const removeAllAppliedDiscounts = createAsyncThunk(
  'removeAllAppliedDiscounts',
  async (params: RemoveAllAppliedDiscountsRequest, { dispatch }) => {
    try {
      return await DiscountApi.removeAllAppliedDiscounts(params);
    } catch (error) {
      dispatch(errorNotification(error ? JSON.stringify(error) : 'There was an error removing discounts'));
      logger.error(error, { message: 'Error removing all applied discounts', params });
      return Promise.reject();
    }
  }
);
