import React, { useMemo } from 'react';
import styled from 'styled-components';

import { EmbeddedChildTableRow } from './EmbeddedChildTableRow';
import { Checkbox, CheckboxState } from 'components/inputs/Checkbox';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';
import { useSortDrag } from './useSortDrag';

type EmbeddedChildTableProps = {
  parentId: string;
};

const EMPTY_ARRAY: string[] = [];

function identity<Type>(item: Type) {
  return item;
}

export function EmbeddedChildTable<ParentItem, ChildItem>(props: EmbeddedChildTableProps) {
  const { parentId } = props;
  const {
    parentItemMap,
    childItemMap,
    relationshipMap,
    childColumns,
    childActions,
    onChangeParent,
    onChangeChildrenOrder,
    onSelectChild,
    selectedChildren,
    emptyChildMessage,
    getChildId,
  } = useEmbeddedTableStore<ParentItem, ChildItem>();
  const childIds = relationshipMap[parentId] || EMPTY_ARRAY;
  const selectedChildIdSet = useMemo(
    () => new Set((selectedChildren ?? []).map(getChildId)),
    [selectedChildren, getChildId]
  );
  const dragMoveDisabled = !onChangeParent;
  const isSelectionEnabled = !!onSelectChild && !!selectedChildren;
  const selectionState: CheckboxState = useMemo(() => {
    if (!selectedChildren || selectedChildren.length === 0) {
      return 'off' as const;
    }
    if (childIds.length !== selectedChildren.length) {
      return 'mixed' as const;
    }
    return 'on' as const;
  }, [selectedChildren, childIds]);

  const handleChangeOrder = (childIds: string[]) => {
    const parent = parentItemMap[parentId];
    if (!parent) {
      throw new Error(`No parent found for ID ${parentId}`);
    }
    const children = childIds.map((id) => {
      const child = childItemMap[id];
      if (!child) {
        throw new Error(`No child found for ID ${id}`);
      }
      return child;
    });

    if (onChangeChildrenOrder) {
      onChangeChildrenOrder(parent, children);
    }
  };

  const handleToggleSelectAll = () => {
    if (!onSelectChild) {
      return;
    }
    const childrenToSelect = selectionState === 'on' ? [] : childIds;
    onSelectChild(childrenToSelect.map((id) => childItemMap[id]));
  };

  const handleToggleSelectOne = (childId: string) => {
    if (!onSelectChild || !selectedChildren) {
      return;
    }

    if (selectedChildIdSet.has(childId)) {
      onSelectChild(selectedChildren.filter((child) => getChildId(child) !== childId));
      return;
    }

    const child = childItemMap[childId];
    if (!child) {
      throw new Error(`Child not available: ${childId}`);
    }
    onSelectChild([child, ...selectedChildren]);
  };

  const { sortedItems, handleDragStart, handleDragStop, handleDragOver } = useSortDrag(
    childIds,
    identity,
    onChangeChildrenOrder ? handleChangeOrder : undefined
  );

  const numColumns = childColumns.length + (isSelectionEnabled ? 1 : 0) + (childActions ? 1 : 0);

  return (
    <table className='child'>
      <colgroup>
        {isSelectionEnabled && <col width='48px' />}
        {childColumns.map(({ width, style }, i) => (
          <col key={`child-column-${i}`} width={width} style={style} />
        ))}
        {childActions && <col width='48px' />}
      </colgroup>
      <thead className='child'>
        <tr className='child'>
          {isSelectionEnabled && (
            <th>
              <StyledCheckbox state={selectionState} onClick={handleToggleSelectAll} />
            </th>
          )}
          {childColumns.map(({ header, style }, i) => (
            <th className='child' key={`child-header-${i}`} style={style}>
              {header}
            </th>
          ))}
          {childActions && <th className='child action-menu'>{/* for action menu */}</th>}
        </tr>
      </thead>
      <tbody className='child'>
        {sortedItems.map((id) => (
          <EmbeddedChildTableRow<ParentItem, ChildItem>
            {...props}
            key={id}
            id={id}
            numColumns={numColumns}
            selected={selectedChildIdSet.has(id)}
            isSelectionEnabled={isSelectionEnabled}
            dragDropDisabled={!onChangeChildrenOrder && dragMoveDisabled}
            onToggleSelection={() => handleToggleSelectOne(id)}
            onDragStart={() => handleDragStart(id)}
            onDragStop={() => handleDragStop()}
            onDragOver={() => handleDragOver(id)}
          />
        ))}
        {sortedItems.length === 0 && emptyChildMessage && (
          <tr className='child empty'>
            <td className='child empty' colSpan={numColumns}>
              {emptyChildMessage}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

const StyledCheckbox = styled(Checkbox)`
  & > svg {
    width: 1rem;
    height: 1rem;
  }
`;
