import React from 'react';

import { colors } from 'css/Theme';

type BirthdayCakeIconProps = {
  color?: string;
  size?: number;
  automationId?: string;
};

export const BirthdayCakeIcon = ({
  color = colors.dutchie.almostBlack,
  size = 24,
  automationId,
}: BirthdayCakeIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5 9.75H4.5C3.67157 9.75 3 10.4216 3 11.25V17.75C3 18.5784 3.67157 19.25 4.5 19.25H19.5C20.3284 19.25 21 18.5784 21 17.75V11.25C21 10.4216 20.3284 9.75 19.5 9.75Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M12 7.5V9.75' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M13.5 3C13.5 3.39782 13.342 3.77936 13.0607 4.06066C12.7794 4.34196 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.34196 10.9393 4.06066C10.658 3.77936 10.5 3.39782 10.5 3C10.5 2.17 12 0.75 12 0.75C12 0.75 13.5 2.17 13.5 3Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M18 7.5V9.75' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M19.5 3C19.5 3.39782 19.342 3.77936 19.0607 4.06066C18.7794 4.34196 18.3978 4.5 18 4.5C17.6022 4.5 17.2206 4.34196 16.9393 4.06066C16.658 3.77936 16.5 3.39782 16.5 3C16.5 2.17 18 0.75 18 0.75C18 0.75 19.5 2.17 19.5 3Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M6 7.5V9.75' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M7.5 3C7.5 3.39782 7.34196 3.77936 7.06066 4.06066C6.77936 4.34196 6.39782 4.5 6 4.5C5.60218 4.5 5.22064 4.34196 4.93934 4.06066C4.65804 3.77936 4.5 3.39782 4.5 3C4.5 2.17 6 0.75 6 0.75C6 0.75 7.5 2.17 7.5 3Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3 14.2202C3.23042 14.6122 3.55924 14.9371 3.95389 15.1629C4.34854 15.3887 4.79533 15.5075 5.25 15.5075C5.70467 15.5075 6.15146 15.3887 6.54611 15.1629C6.94076 14.9371 7.26958 14.6122 7.5 14.2202C7.73042 14.6122 8.05924 14.9371 8.45389 15.1629C8.84854 15.3887 9.29533 15.5075 9.75 15.5075C10.2047 15.5075 10.6515 15.3887 11.0461 15.1629C11.4408 14.9371 11.7696 14.6122 12 14.2202C12.2304 14.6122 12.5592 14.9371 12.9539 15.1629C13.3485 15.3887 13.7953 15.5075 14.25 15.5075C14.7047 15.5075 15.1515 15.3887 15.5461 15.1629C15.9408 14.9371 16.2696 14.6122 16.5 14.2202C16.7304 14.6122 17.0592 14.9371 17.4539 15.1629C17.8485 15.3887 18.2953 15.5075 18.75 15.5075C19.2047 15.5075 19.6515 15.3887 20.0461 15.1629C20.4408 14.9371 20.7696 14.6122 21 14.2202'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
