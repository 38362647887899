import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import * as AlpineIQApi from 'api/AlpineIQApi';
import { Button } from 'components/buttons';
import { callback, callback1 } from 'models/Misc';
import { errorNotification } from 'store/actions/NotificationsActions';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'components/inputs';
import { SpinnerContainer, TopSection, BottomSection, CancelButton } from './AlpineIQRedeemPopup';
import { AlpineIQInfo, GetWalletRequest } from 'models/AlpineIQ';
import { State } from 'store';
import { Loader } from 'components/backoffice/loader';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type PhoneEntryProps = {
  guest?: { CellPhone?: string; PhoneNo?: string; Email?: string };
  setWalletExist: callback1<boolean>;
  setPhoneNumber: callback1<string>;
  setAlpineIQInfo: callback1<AlpineIQInfo | undefined>;
  phoneNumber: string;
  hide: callback;
};

type CustomerInfo = {
  phoneNumbers?: string[];
  emails?: string[];
};

export const PhoneEntry: FC<PhoneEntryProps> = ({
  guest,
  setWalletExist,
  setPhoneNumber,
  phoneNumber,
  setAlpineIQInfo,
  hide,
}) => {
  const dispatch = useDispatch();
  const UseAlpineIQBypass2FA =
    useSelector((state: State) => state.settings.integrations?.UseAlpineIQBypass2FA) ?? false;
  const UseAlpineIQBypassEntry =
    useSelector((state: State) => state.settings.integrations?.UseAlpineIQBypassEntry) ?? false;
  const [loading, setLoading] = useState(false);
  const [emailOrPhoneInput, setEmailOrPhoneInput] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const ldClient = useLDClient();
  const canUseBypassEntry = ldClient?.variation('pos.register.aiq_no_phone_entry.rollout', false);
  const usePhoneSanitizerFlag = ldClient?.variation('pos.register.aiq_phone_sanitizer.rollout', false);

  const { shipmentId } = useTransactionManager();

  const emailRegex = new RegExp(/(.+)@(.+)\.([\w]{2,})/g);
  const phoneRegex = new RegExp(/[\d+\-(). ]{10,20}/g);
  const phoneDigitsLengthRegex = new RegExp(/^[0-9]{10,12}$/g);

  // Consolidate all phone numbers and emails and shipmentId for guest
  const customerInfo = useMemo(() => {
    const phoneNumbers = [];
    const emails = [];
    if (guest?.CellPhone) {
      phoneNumbers.push(guest.CellPhone);
    }
    if (guest?.PhoneNo) {
      phoneNumbers.push(guest.PhoneNo);
    }
    if (guest?.Email) {
      emails.push(guest.Email);
    }
    return { phoneNumbers, emails };
  }, [guest]);

  const getWallet = useCallback(
    ({ phoneNumbers, emails }: CustomerInfo) => {
      const getWalletRequestParams: GetWalletRequest = {
        PhoneNumbers: phoneNumbers ?? [],
        Emails: emails ?? [],
        Code: '',
        ShipmentId: shipmentId || 0,
      };
      if (!canUseBypassEntry) {
        getWalletRequestParams.PhoneNumber = phoneNumbers?.[0];
      }
      return AlpineIQApi.getWallet(getWalletRequestParams);
    },
    [canUseBypassEntry, shipmentId]
  );

  // Used to disable submit button when criteria has not been met
  const isFormValid = useMemo((): boolean => {
    // Keep current behavior if flag is not set
    if (!usePhoneSanitizerFlag) {
      return true;
    }

    if (UseAlpineIQBypass2FA) {
      if (email.length) {
        return !!email.match(emailRegex);
      }

      // [phone] value can contain non-digits
      const digits = phone.replace(/[^\d]/g, '');
      return !!digits.match(phoneDigitsLengthRegex);
    }

    // [phoneNumber] contains format characters filled with empty chars
    const digits = phoneNumber.replace(/[^\d]/g, '');
    return !!digits.match(phoneDigitsLengthRegex);
  }, [UseAlpineIQBypass2FA, email, emailRegex, phone, phoneDigitsLengthRegex, phoneNumber, usePhoneSanitizerFlag]);

  useEffect(() => {
    const customerHasContactInfo = customerInfo.emails.length > 0 || customerInfo.phoneNumbers.length > 0;
    if (UseAlpineIQBypass2FA && UseAlpineIQBypassEntry && customerHasContactInfo) {
      setLoading(true);
      getWallet(customerInfo)
        .then((data) => {
          // If account is found, set the data and wallet exists to skip to the redeem modal
          setAlpineIQInfo(data);
          setWalletExist(true);
          setLoading(false);
        })
        .catch((_message) => {
          // Will catch if no user is found and set loading false
          setLoading(false);
        });
    }
  }, [UseAlpineIQBypass2FA, UseAlpineIQBypassEntry, customerInfo, getWallet, setAlpineIQInfo, setWalletExist]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmailOrPhoneInput(value);

    setPhone(value.match(phoneRegex) ? value : '');
    setEmail(value.match(emailRegex) ? value : '');
  };

  const getCode = async () => {
    setLoading(true);
    try {
      if (UseAlpineIQBypass2FA) {
        const contactData: CustomerInfo = UseAlpineIQBypassEntry
          ? { phoneNumbers: [phone], emails: [email] }
          : { phoneNumbers: [phoneNumber] };
        const data = await getWallet(contactData);
        setAlpineIQInfo(data);
      } else {
        await AlpineIQApi.verifyWallet({
          // Clean up after pos.register.aiq_no_phone_entry.rollout roll out
          PhoneNumber: phoneNumber,
          PhoneNumbers: [phoneNumber],
          Emails: [],
        });
      }
      setWalletExist(true);
    } catch (message) {
      dispatch(errorNotification(message as string));
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <SpinnerContainer>
        <Loader size='2x' variant='black' />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <TopSection>
        {!UseAlpineIQBypass2FA || !canUseBypassEntry ? (
          <Input
            type='number'
            id='phoneNumber'
            format='(###) ###-####'
            placeholder='Phone Number'
            value={phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPhoneNumber(e.target.value);
            }}
          />
        ) : (
          <Input
            id='emailAndPhone'
            placeholder='Email or Phone number'
            value={emailOrPhoneInput}
            onChange={handleChange}
          />
        )}
      </TopSection>
      <BottomSection>
        <CancelButton onClick={hide} secondary>
          Cancel
        </CancelButton>
        <Button onClick={getCode} disabled={!isFormValid}>
          {UseAlpineIQBypass2FA ? 'Open Wallet' : 'Get Code'}
        </Button>
      </BottomSection>
    </>
  );
};
