import { DutchiePayInvoiceConfirmationNotification } from 'models/Checkout';
import { useCallback } from 'react';

export const useOnDutchiePayPusherMessage = (
  onPusherMessageCallback: (notification: DutchiePayInvoiceConfirmationNotification | null) => Promise<void>
) => {
  return useCallback(
    (eventName: string, data: string | object) => {
      let notification = null;
      if (eventName === 'invoice-confirmed') {
        try {
          // once ably fallback is removed, this function will only accept an object and this check can go away (FINTECH-1688)
          if (typeof data === 'string') {
            notification = JSON.parse(data);
          } else {
            notification = data;
          }
        } catch (error) {
          notification = data;
        }
        onPusherMessageCallback(notification);
      }
    },
    [onPusherMessageCallback]
  );
};
