import React from 'react';
import {
  PeripheralSetupConnectionStatus,
  ConnectionStatusBanner,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { isPrintNodeReceiptPrinter } from 'hardware/receipt-printer';
import { useFulfillmentReceiptPrinterSettings } from '../hooks/useFulfillmentReceiptPrinterSettings';

export const FulfillmentReceiptPrinterStatusBanner = () => {
  const { formValues, printers } = useFulfillmentReceiptPrinterSettings();

  const selectedPrinter = printers.find((printer) => printer.id === formValues.fulfillmentPrinterId);

  if (!selectedPrinter) {
    return null;
  }

  if (isPrintNodeReceiptPrinter(selectedPrinter)) {
    return (
      <ConnectionStatusBanner
        deviceTypeName='Printer'
        status={PeripheralSetupConnectionStatus.unavailable}
        customText='Backoffice printer. Connection status unavailable at the register.'
      />
    );
  }

  return (
    <ConnectionStatusBanner
      deviceTypeName='Printer'
      deviceName={selectedPrinter.name}
      status={PeripheralSetupConnectionStatus.connected}
    />
  );
};
