import styled from 'styled-components';

export const RebrandLabel = styled.label`
  grid-column: 1 / span 1;
  align-self: center;
  color: var(--color-greyscale-grey-30);
  font: var(--font-regular-14pt-normal);
  margin-bottom: 0;

  .required-asterisk {
    margin-left: -2px;
    color: var(--color-additional-colors-red-46);
  }

  .tooltip {
    opacity: 1;
  }
`;
