import { Capacitor } from '@capacitor/core';
import {
  UsbConnectionStatusPayload,
  ConnectionStatusPayload,
  LabelPrinter,
  PeripheralEvents,
  ReceiptPrinter,
  Scanner,
} from 'util/capacitor/peripheral';
import { OnEvent, useEventData } from 'util/hooks';

export const isAndroid = Capacitor.getPlatform() === 'android';
export const ConnectingStatesNew: string[] = ['CONNECTED', 'CONNECTING', 'AVAILABLE'];
export const DisconnectedStatesNew: string[] = ['UNKNOWN', 'DISCONNECTED', 'DISCONNECTING', 'UNAVAILABLE'];
export const ConnectingStates: string[] = ['Connected', 'Connecting', 'Available'];
export const DisconnectedStates: string[] = ['Unknown', 'Disconnected', 'Disconnecting', 'Unavailable'];

export const useScanStatus = (onEvent: OnEvent<ConnectionStatusPayload<Scanner>>) =>
  useEventData<ConnectionStatusPayload<Scanner>>(PeripheralEvents.ScannerConnectionStatus, onEvent);
export const useLabelPrinterConnection = (onEvent: OnEvent<ConnectionStatusPayload<LabelPrinter>>) =>
  useEventData<ConnectionStatusPayload<LabelPrinter>>(PeripheralEvents.LabelPrinterConnectionStatus, onEvent);
export const useReceiptPrinterConnection = (onEvent: OnEvent<ConnectionStatusPayload<ReceiptPrinter>>) =>
  useEventData<ConnectionStatusPayload<ReceiptPrinter>>(PeripheralEvents.ReceiptPrinterConnectionStatus, onEvent);
export const useUsbDeviceConnection = (onEvent: OnEvent<UsbConnectionStatusPayload>) =>
  useEventData<UsbConnectionStatusPayload>(PeripheralEvents.UsbConnectionStatus, onEvent);
