import React, { FC } from 'react';

import { QuantitySelector } from './QuantitySelector';
import { BulkSelector } from './BulkSelector';
import { getProductId } from 'util/helpers/products/getProductId';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useAppSelector } from 'util/hooks';
import { useCartItems } from 'util/hooks/cart/useCartItems';
import { useProducts } from 'util/hooks/products/useProducts';
import { useProductsById } from 'util/hooks/products/useProductsById';

import type { CartItem } from 'models/Cart';
import type { ProductSearchResult } from 'queries/v2/product/types';

export type ProductSelectionChangeProps = {
  countChange?: number;
  weightChange?: number;
};

type ProductSelectionProps = {
  product: ProductSearchResult | CartItem;
  amounts: { count: number; grams: number };
  onChange: (changes: ProductSelectionChangeProps) => void;
  setTotalCost?: (cost: number) => void;
  includeCartQtyInMax: boolean;
  isInCartSummary?: boolean;
  closeProductPreview?: () => void;
};

export const ProductSelectionControls: FC<ProductSelectionProps> = ({
  product,
  amounts,
  onChange,
  setTotalCost,
  includeCartQtyInMax,
  isInCartSummary = false,
  closeProductPreview,
}) => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const id = getProductId(product);

  const cartItemFromRQ = useCartItems((cartItemsById) => cartItemsById[id]);
  const cartItemFromRedux = useAppSelector((state) => state.cart.itemsById[id]);
  const selectedCartItem = isAnonymousCartLDFlagEnabled ? cartItemFromRQ : cartItemFromRedux;

  const selectedProductNew = useProducts((productsById) => productsById[id]);
  const { productsById } = useProductsById();
  const selectedProduct = isAnonymousCartLDFlagEnabled ? selectedProductNew : productsById?.[id];
  const selectedProductType = selectedProduct?.productType ?? selectedCartItem?.WgtCnt;

  const handleOnChange = (change: number) => {
    selectedProductType === 'Qty' ? onChange({ countChange: change }) : onChange({ weightChange: change });
  };

  return selectedProductType === 'Qty' ? (
    <QuantitySelector
      isInCartSummary={isInCartSummary}
      product={product}
      includeCartQtyInMax={includeCartQtyInMax}
      count={amounts.count}
      onCountChange={handleOnChange}
    />
  ) : (
    <BulkSelector
      closeProductPreview={closeProductPreview}
      isInCartSummary={isInCartSummary}
      product={product}
      includeCartQtyInMax={includeCartQtyInMax}
      grams={amounts.grams}
      onWeightChange={handleOnChange}
      setTotalCost={setTotalCost}
    />
  );
};
