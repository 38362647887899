import React, { FC } from 'react';
import styled from 'styled-components';

import { Label } from 'components/text';
import { ReactComponent as CheckboxOn } from 'assets/icons/checkbox-on.svg';
import { ReactComponent as CheckboxOff } from 'assets/icons/checkbox-off.svg';
import { ReactComponent as CheckboxMixed } from 'assets/icons/checkbox-mixed.svg';

export type CheckboxState = 'on' | 'off' | 'mixed';

export type CheckboxProps = {
  state: CheckboxState;
  className?: string;
  onClick?: () => void;
  label?: string;
  leadingLabel?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({ state, className, onClick, label, leadingLabel }) => {
  const renderCheckboxForState = (state: CheckboxState) => {
    switch (state) {
      case 'mixed':
        return <StyledCheckboxMixed data-testid='checkbox-mixed_svg' />;
      case 'on':
        return <StyledCheckboxOn data-testid='checkbox-on_svg' />;
      default:
        return <StyledCheckboxOff data-testid='checkbox-off_svg' />;
    }
  };

  return (
    <CheckboxWrapper onClick={onClick} className={className}>
      {label && leadingLabel && <StyledLabel>{label}</StyledLabel>}
      {renderCheckboxForState(state)}
      {label && !leadingLabel && <StyledLabel>{label}</StyledLabel>}
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 0;
`;

const commonCheckboxStyles = {
  width: '20px',
  height: '20px',
  flexShrink: 0,
};

const StyledCheckboxMixed = styled(CheckboxMixed)`
  ${commonCheckboxStyles}
`;

const StyledCheckboxOn = styled(CheckboxOn)`
  ${commonCheckboxStyles}
`;

const StyledCheckboxOff = styled(CheckboxOff)`
  ${commonCheckboxStyles}
`;
