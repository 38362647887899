import React from 'react';

import { Table } from 'components/tables';
import { SearchResultsContainer, TableContainer } from '../../ProductSearchFilter.styles';
import { ProductSearchMessage } from '../ProductSearchMessage';

import type { Column } from 'components/tables';
import type { ProductSearchResult } from 'queries/v2/product/types';

type ProductSearchResultsProps = {
  displayProducts: ProductSearchResult[];
  noInventoryFound?: boolean;
  noMatchingProducts?: boolean;
  refetchProducts: () => void;
  selectedProduct?: ProductSearchResult;
  setSelectedProduct: (product?: ProductSearchResult) => void;
  tableColumns: Column<ProductSearchResult>[];
  wasErrorLoadingProducts?: boolean;
};

export function ProductSearchResults({
  displayProducts,
  noInventoryFound,
  noMatchingProducts,
  refetchProducts,
  selectedProduct,
  setSelectedProduct,
  tableColumns,
  wasErrorLoadingProducts,
}: ProductSearchResultsProps): JSX.Element {
  if (wasErrorLoadingProducts) {
    return (
      <SearchResultsContainer>
        <ProductSearchMessage
          message='Inventory could not be loaded'
          action={{ label: 'Reload inventory', onClick: refetchProducts }}
        />
      </SearchResultsContainer>
    );
  }

  if (noInventoryFound) {
    return (
      <SearchResultsContainer>
        <ProductSearchMessage
          message='No inventory found'
          instruction='Check inventory and rooms configuration in Dutchie Backoffice.'
        />
      </SearchResultsContainer>
    );
  }

  if (noMatchingProducts) {
    return (
      <SearchResultsContainer>
        <ProductSearchMessage message='No matching products found' instruction='Refine search and try again.' />
      </SearchResultsContainer>
    );
  }

  return (
    <SearchResultsContainer>
      <TableContainer>
        <Table<ProductSearchResult>
          data={displayProducts}
          columns={tableColumns}
          sortColumn={0}
          sortDirection={'asc'}
          onClick={setSelectedProduct}
          selected={selectedProduct ? [selectedProduct] : []}
          pageSize={20}
        />
      </TableContainer>
    </SearchResultsContainer>
  );
}
