import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { HardwareService, Peripheral, PeripheralServiceManager, PeripheralType } from '@dutchie/capacitor-hardware';

import { closePopup } from 'store/actions/PopupsActions';
import { useAppDispatch } from 'util/hooks';

import type { AddNewDeviceModalProps } from './AddNewDeviceModal';
import type { HardwareSettings } from '../../types';

/** Helper to get the correct service manager by peripheral type */
const getService = (type: PeripheralType): PeripheralServiceManager<Peripheral> => {
  switch (type) {
    case PeripheralType.labelPrinter:
      return HardwareService.labelPrinter;
    case PeripheralType.receiptPrinter:
      return HardwareService.receiptPrinter;
    case PeripheralType.scale:
      return HardwareService.scale;
    case PeripheralType.scanner:
      return HardwareService.scanner;
  }
};

export const useAddNewDeviceModal = ({ deviceType }: Pick<AddNewDeviceModalProps, 'deviceType'>) => {
  const dispatch = useAppDispatch();
  const { submitForm } = useFormikContext<HardwareSettings>();

  // Local state
  const [wasDeviceAdded, setWasDeviceAdded] = useState(false);

  // Handlers

  const onDevicesAuthorized = <D extends Peripheral>({ detail }: CustomEvent<{ devices: D[] }>) => {
    if (detail.devices.length) {
      setWasDeviceAdded(true);
    }
  };

  const handleCloseModal = () => {
    // If a new device was authorized, submit the form to save the settings
    if (wasDeviceAdded) {
      submitForm();
    }
    dispatch(closePopup('addNewDevicePopup'));
  };

  // Listeners

  useEffect(() => {
    getService(deviceType).addEventListener('devices-authorized', onDevicesAuthorized);

    return () => {
      getService(deviceType).removeEventListener('devices-authorized', onDevicesAuthorized);
    };
  }, [deviceType]);

  return { handleCloseModal, wasDeviceAdded };
};
