import React, { FC, useState } from 'react';

import { SearchBar, SearchResults } from 'components/layout';
import { callback, callback1 } from 'models/Misc';
import { Transaction } from 'models/Transactions';
import { TransactionsListTable } from './components/TransactionsListTable';
import { ProductHistoryOrder } from 'models/Customer';
import { WithPossibleAlert } from 'components/tables';
import styled from 'styled-components';
import { Button } from 'components/backoffice/button';

type TransactionsListProps = {
  loadingTransactions: boolean;
  transactions?: WithPossibleAlert<Transaction>[];
  setSelectedTransaction: callback1<Transaction>;
  setIsTransactionVoided: (transaction?: Transaction) => void;
  selectedTransaction?: Transaction | ProductHistoryOrder;
  onSearch?: callback;
  onSearchTextUpdated?: callback1<string>;
  isSalesHistory?: boolean;
};

export const TransactionsList: FC<TransactionsListProps> = ({
  transactions,
  setIsTransactionVoided,
  setSelectedTransaction,
  loadingTransactions,
  onSearch,
  onSearchTextUpdated,
  isSalesHistory,
}) => {
  const [searchText, setSearchText] = useState('');

  const searchResultTransactions = isSalesHistory
    ? transactions
    : transactions
        ?.filter((item) => `${item.PosId}`.includes(searchText))
        .map((item) => ({ ...item, alert: item.isVoided }));

  return (
    <>
      <SearchLayout>
        <SearchBar
          placeholder={isSalesHistory ? 'Enter customer name, package ID, or receipt #' : 'Product search...'}
          initialValue={searchText}
          className='customers-page-search-input'
          onChange={(value) => {
            onSearchTextUpdated && onSearchTextUpdated(value);
            setSearchText(value);
          }}
          onSearch={(value) => {
            onSearch && onSearch();
            setSearchText(value);
          }}
          maxWidth='100%'
          showClearButton={true}
        />
        {isSalesHistory && (
          <SearchButton
            label={`Search`}
            disabled={searchText.length === 0}
            onClick={() => {
              onSearch && onSearch();
            }}
          />
        )}
      </SearchLayout>
      <SearchResults
        loading={loadingTransactions}
        loadingText={isSalesHistory ? 'Loading transactions' : undefined}
        isEmpty={transactions && !transactions.length}
        emptyMessage='There are no transactions or they could not be retrieved'
      >
        <TransactionsListTable
          transactions={searchResultTransactions ?? []}
          setSelectedTransaction={setSelectedTransaction}
          setIsTransactionVoided={setIsTransactionVoided}
        />
      </SearchResults>
    </>
  );
};

const SearchLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SearchButton = styled(Button)`
  margin-left: 12px;
`;
