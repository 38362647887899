import React from 'react';
import { HardwareService, PeripheralType } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useScaleSettings } from '../hooks/useScaleSettings';
import { SettingsSelect, SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';
import { useAddDeviceListItem } from '../../components/AddDeviceListItem/useAddDeviceListItem';
import { useHardwareServices } from '../../hooks/useHardwareServices';

export const ScaleSelectListItem = () => {
  const { formValues, scales, search, selectScale } = useScaleSettings();
  const { isWebSerialSupported } = useHardwareServices();

  const handleChange = (option?: SettingsSelectOption) => {
    selectScale(option?.value);
  };

  const handleRefresh = async () => {
    HardwareService.scale.filterEnabled = true;
    search({ requestNewDevices: false });
  };

  const { handleShowAddNewDeviceModal } = useAddDeviceListItem(PeripheralType.scale);

  return (
    <SettingsListItem
      title='Select scale'
      actions={[
        <SettingsSelect
          automationId='scale_settings-select'
          devices={scales}
          value={formValues.scaleId}
          placeholder='Select scale'
          onChange={handleChange}
          onRefresh={handleRefresh}
          secondaryAction={
            isWebSerialSupported ? { label: 'Add new scale', onClick: handleShowAddNewDeviceModal } : undefined
          }
        />,
      ]}
    />
  );
};
