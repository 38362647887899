import React, { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GuestListPusherImp as GuestListPusherImpDeprecated } from './GuestListPusherImp-deprecated';
import { useGuestListPusherChannel } from './useGuestListPusherChannel';

const UpdatedGuestListPusherImpWrapper: FC = () => {
  useGuestListPusherChannel();
  return <></>;
};

export const GuestListPusherImp: FC = () => {
  const { 'pos.register.pusher-version-8.rollout': posRegisterPusherVersion8Rollout } = useFlags();
  if (posRegisterPusherVersion8Rollout === true) {
    return <UpdatedGuestListPusherImpWrapper />;
  }
  return <GuestListPusherImpDeprecated />;
};
