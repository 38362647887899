import React from 'react';

type HelpIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function HelpIcon(props: HelpIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3626_4163)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472ZM12 1.71429C9.27206 1.71429 6.65585 2.79796 4.7269 4.7269C2.79796 6.65585 1.71429 9.27206 1.71429 12C1.71429 14.7279 2.79796 17.3442 4.7269 19.2731C6.65585 21.202 9.27206 22.2857 12 22.2857C14.7279 22.2857 17.3442 21.202 19.2731 19.2731C21.202 17.3442 22.2857 14.7279 22.2857 12C22.2857 9.27206 21.202 6.65585 19.2731 4.7269C17.3442 2.79796 14.7279 1.71429 12 1.71429Z'
          fill={color}
        />
        <path
          d='M8.43799 8.17188C8.87516 7.64062 9.41195 7.22835 10.0483 6.93506C10.6847 6.64176 11.382 6.49512 12.1401 6.49512C13.1916 6.49512 14.0299 6.74414 14.6553 7.24219C15.2806 7.74023 15.5933 8.37939 15.5933 9.15967C15.5933 9.55257 15.5186 9.91227 15.3691 10.2388C15.2197 10.5653 15.0316 10.8337 14.8047 11.0439C14.5778 11.2487 14.3315 11.4535 14.0659 11.6582C13.8058 11.8574 13.5623 12.0345 13.3354 12.1895C13.1086 12.3444 12.9204 12.527 12.771 12.7373C12.6216 12.9476 12.5469 13.1717 12.5469 13.4097C12.5469 13.7085 12.6714 13.9631 12.9204 14.1733L11.4263 14.6714C11.0168 14.2785 10.812 13.797 10.812 13.2271C10.812 12.9448 10.8618 12.6847 10.9614 12.4468C11.061 12.2033 11.1911 11.9958 11.3516 11.8242C11.5176 11.6527 11.6974 11.4922 11.8911 11.3428C12.0848 11.1878 12.2785 11.0439 12.4722 10.9111C12.6659 10.7728 12.8429 10.6344 13.0034 10.4961C13.1694 10.3577 13.3022 10.2028 13.4019 10.0312C13.5015 9.8597 13.5513 9.67708 13.5513 9.4834C13.5513 9.12923 13.4129 8.83594 13.1362 8.60352C12.8651 8.37109 12.4666 8.25488 11.9409 8.25488C11.0112 8.25488 10.2227 8.65332 9.5752 9.4502L8.43799 8.17188ZM10.8452 16.9956C10.8452 16.6746 10.9614 16.3979 11.1938 16.1655C11.4318 15.9276 11.714 15.8086 12.0405 15.8086C12.367 15.8086 12.6465 15.9248 12.8789 16.1572C13.1113 16.3896 13.2275 16.6691 13.2275 16.9956C13.2275 17.3221 13.1086 17.6043 12.8706 17.8423C12.6382 18.0747 12.3615 18.1909 12.0405 18.1909C11.714 18.1909 11.4318 18.0747 11.1938 17.8423C10.9614 17.6043 10.8452 17.3221 10.8452 16.9956Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3626_4163'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
