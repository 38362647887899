import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';

import { errorNotification } from 'store/actions/NotificationsActions';
import { GenderOptions as genderList } from 'components/inputs/defaults';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useCreateAnonymousTransaction } from 'pages/CartPage/hooks/useCreateAnonymousTransaction';

import type { AnonymousTransactionPopupProps } from './AnonymousTransactionPopup';
import type { Demographics } from 'pages/CartPage/hooks/useCreateAnonymousTransaction';

export const initialValues: Demographics = {
  customerTypeId: 0,
  dob: '',
  gender: '',
  postalCode: '',
};

export const useAnonymousTransactionPopup = ({ dob, hide }: AnonymousTransactionPopupProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Hooks
  const { dirty, values, validateForm } = useFormikContext<Demographics>();
  const { createTransactionWithDemographics } = useCreateAnonymousTransaction();

  // Local State
  const [isDobVerified, setIsDobVerified] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Global State
  const customerTypes = useAppSelector((state) => state.settings.customerTypes);
  const defaultCustomerTypeId = useAppSelector((state) => state.settings.locationSettings?.DefaultCustomerTypeId);
  const isConfirmBirthdayOnCheckinEnabled = useAppSelector((state) => state.settings.features.ConfirmBirthdayOnCheckin);

  // Computed Values
  const customerTypeOptions = customerTypes
    ? customerTypes.reduce(
        (acc, t) => {
          if (t.IsRetail && !t.IsMedical) {
            acc.push({ label: t.CustomerType, value: String(t.CustomerTypeId) });
          }
          return acc;
        },
        [] as Array<{
          value: string;
          label: string;
        }>
      )
    : [];

  const isSubmitDisabled = !dirty;

  // Handlers
  const handleSubmit = async () => {
    setIsSubmitting(true);

    const errors = await validateForm();

    if (Object.keys(errors).length > 0) {
      for (const message of Object.values(errors)) {
        dispatch(errorNotification(message ?? ''));
      }

      setIsSubmitting(false);
      return;
    }

    try {
      // Attempt to create the transaction
      // Will fail if birthdate is invalid
      await createTransactionWithDemographics(values);
      hide();
    } catch (e) {
      if (e instanceof Error) {
        dispatch(errorNotification(e.message));
      } else if (typeof e === 'string') {
        dispatch(errorNotification(e));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => {
    hide();
    history.push('/guestlist');
  };

  // Effects
  useEffect(() => {
    if (isConfirmBirthdayOnCheckinEnabled && dob) {
      const formattedDob = format(new Date(dob), 'MM-dd-yyyy');
      initialValues.dob = formattedDob;
      setIsDobVerified(true);
    }
  }, [isConfirmBirthdayOnCheckinEnabled, dob]);

  useEffect(() => {
    if (defaultCustomerTypeId) {
      initialValues.customerTypeId = defaultCustomerTypeId;
    }
  }, [defaultCustomerTypeId]);

  return {
    customerTypeOptions,
    handleCancel,
    handleSubmit,
    isDobVerified,
    isSubmitDisabled,
    isSubmitting,
    genderList,
  };
};
