import { useState } from 'react';

import { ScannerConfiguration, useScannerConfigurations } from './useScannerConfigurations';

import type { SelectChangeEvent } from '@mui/material';

export const useConfigureBarcodeScannerModal = () => {
  const { scannerConfigurations } = useScannerConfigurations();

  // Local state

  const [selectedConfiguration, setSelectedConfiguration] = useState<ScannerConfiguration>(scannerConfigurations[0]);

  // Handlers

  const handleSelectConfiguration = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    const selected = scannerConfigurations.find((configuration) => configuration.title === value);
    if (selected) {
      setSelectedConfiguration(selected);
    }
  };

  return {
    handleSelectConfiguration,
    scannerConfigurations,
    selectedConfiguration,
  };
};
