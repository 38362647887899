import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { CustomerHistoryPanelTabs } from 'components/cart/CustomerHistoryPanel/CustomerHistoryPanel';
import { openCustomerSelectionPanel, openCustomerHistoryPanel } from 'store/actions/CartActions';
import { showNotesPopup } from 'store/actions/PopupsActions';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useCartPopups } from 'components/CartPopups';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';

import type { CartHeaderProps } from './CartHeader';

export const useCartHeader = ({ loading, showInitialPopup }: CartHeaderProps) => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const { pathname } = history.location;
  const isStandardCart = pathname.includes('cart');

  // Local state

  const [initialPopupShown, setInitialPopupShown] = useState(false);
  const [isCustomerInfoOpen, setIsCustomerInfoOpen] = useState(false);

  // Global state

  const guest = useAppSelector((state) => state.customer.details);
  const isIndicateLoyaltyMemberEnabled = useAppSelector((state) => state.settings.features.IndicateLoyaltyMember);
  const isPopupJournalEnabled = useAppSelector((state) => state.settings.features.PopUpJournal);
  const isRegisterForLoyaltyEnabled = useAppSelector((state) => state.settings.features.RegisterForLoyaltyPopup);
  const isShowPopupNotesEnabled = useAppSelector((state) => state.settings.userSettings.showPopupNotes);
  const isUseMCDMSEnabled = useAppSelector((state) => state.settings.integrations?.UseMCDMS) ?? false;

  // Hooks

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const cartPopups = useCartPopups();
  const { isCompactLayout } = useCompactCartLayout();

  // Computed values

  const customerNameText = guest?.FullName ?? 'Guest';
  const guestId = guest?.Guest_id;
  const customerNicknameText = guest?.nickname;

  const showLoadingSkeleton = isAnonymousCartLDFlagEnabled ? loading : loading || !guest;

  const showAllGuestInfo = (guest && !guest.IsAnonymous) || !isAnonymousCartLDFlagEnabled;
  const showAssignCustomerButton =
    (!guest || guest.IsAnonymous) && isStandardCart && !isUseMCDMSEnabled && isAnonymousCartLDFlagEnabled;
  const showCollapsedCustomerInfo = isCompactLayout && showAllGuestInfo;
  const isGuestNameClickable = showAssignCustomerButton || showCollapsedCustomerInfo;
  const showCustomerBannedPill = guest?.status.toLowerCase() === 'banned';
  const showCustomerNickname = !!guest?.nickname;
  const showGuestActionButtons = guest || !isAnonymousCartLDFlagEnabled;
  const hideGuestProfileButton = !isStandardCart;
  const showLoyaltyStar = isIndicateLoyaltyMemberEnabled && (guest?.IsLoyaltyMember ?? false);
  const showRegisterForLoyalty =
    showAllGuestInfo && isRegisterForLoyaltyEnabled && !guest?.IsLoyaltyMember && !guest?.IsAnonymous && isStandardCart;

  // Handlers

  const handleClickCollapseCustomerInfo = () => {
    setIsCustomerInfoOpen((prev) => !prev);
  };

  const handleClickCustomerName = () => {
    if (showAssignCustomerButton) {
      dispatch(openCustomerSelectionPanel());
    } else if (showCollapsedCustomerInfo) {
      handleClickCollapseCustomerInfo();
    }
  };

  const handleClickRegisterForLoyalty = () => cartPopups.showRegisterForLoyaltyPopup();

  const handleClickProfile = () => {
    history.push({ pathname: 'edit-customer', search: `?id=${guestId}`, state: { fromCart: true } });
  };

  const handleClickHistory = () => {
    dispatch(openCustomerHistoryPanel({ tab: CustomerHistoryPanelTabs.Transactions }));
  };

  useEffect(() => {
    if (showInitialPopup && isShowPopupNotesEnabled && guest && !initialPopupShown && !loading) {
      if (isPopupJournalEnabled) {
        cartPopups.showJournalPopup(guest.Guest_id);
        setInitialPopupShown(true);
      } else if (guest.notes?.length > 0) {
        dispatch(showNotesPopup({ guestId: guest.Guest_id, notes: guest.notes }));
        setInitialPopupShown(true);
      }
    }
  }, [
    dispatch,
    showInitialPopup,
    guest,
    isShowPopupNotesEnabled,
    isPopupJournalEnabled,
    cartPopups,
    initialPopupShown,
    loading,
  ]);

  return {
    customerNameText,
    customerNicknameText,
    handleClickCollapseCustomerInfo,
    handleClickCustomerName,
    handleClickHistory,
    handleClickProfile,
    handleClickRegisterForLoyalty,
    hideGuestProfileButton,
    isCustomerInfoOpen,
    isGuestNameClickable,
    showAllGuestInfo,
    showAssignCustomerButton,
    showCollapsedCustomerInfo,
    showCustomerBannedPill,
    showCustomerNickname,
    showGuestActionButtons,
    showLoadingSkeleton,
    showLoyaltyStar,
    showRegisterForLoyalty,
  };
};
