import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { useProductSearchPricing } from 'util/hooks/useProductSearchPricing';
import { Button } from 'components/buttons';
import { PreorderProductSearchResult, pricePreorder } from 'api/PreorderApi';
import { QuantityPicker } from './QuantityPicker';
import { ImageWithPlaceholder } from 'components/image';
import { PreorderCartItem } from '../index';
import { deselectPreorderProduct } from 'store/actions/ProductsActions';
import { successNotification } from 'store/actions/NotificationsActions';

import {
  Container,
  ProductDetailsContainer,
  ProductHeader,
  ProductTitle,
  CloseButton,
  ProductImgContainer,
  ProductDescription,
  DetailsRowContainer,
  DetailsRow,
  DetailName,
  DetailData,
  ProductPricingContainer,
  QtySelectContainer,
  PriceDetailsContainer,
  AvailabilityLabel,
} from 'components/cart/AddItemsPanel.styles';
import { Skeleton } from 'components/misc';

type PreorderProductPreviewProps = {
  cartItems: PreorderCartItem[];
  setCartItems: React.Dispatch<React.SetStateAction<PreorderCartItem[]>>;
};

export const PreorderProductPreview: FC<PreorderProductPreviewProps> = ({ cartItems, setCartItems }) => {
  const dispatch = useDispatch();
  const guest = useSelector((state: State) => state.customer.details);
  const selectedProduct = useSelector((state: State) => state.products.selectedPreorderProduct);
  const recFlowerEquivalentFeatureEnabled = useSelector((state: State) => state.settings.features.RecFlowerEquiv);
  const { getPrice } = useProductSearchPricing();

  const matchingCartItem = cartItems.find((item) => item.productId === selectedProduct?.ProductId);
  const [quantity, setQuantity] = useState(selectedProduct?.Quantity ? 1 : 0);
  const [price, setPrice] = useState(getPrice(selectedProduct));
  const [cartLoading, setCartLoading] = useState(false);

  useEffect(() => {
    setQuantity(selectedProduct?.Quantity ? 1 : 0);
  }, [selectedProduct]);

  useEffect(() => {
    if (!selectedProduct || !guest || quantity === 0) {
      return;
    }

    const totalQuantity = quantity + (matchingCartItem?.quantity ?? 0);

    (async () => {
      setCartLoading(true);
      try {
        const orderPrices = await pricePreorder({
          cartItems: [
            {
              ProductId: selectedProduct.ProductId,
              Quantity: totalQuantity,
            },
          ],
          customerId: guest.Guest_id,
        });
        const index = orderPrices.CartItemPrices.findIndex((item) => item.ProductId === selectedProduct.ProductId);
        if (index === -1) {
          setPrice(getPrice(selectedProduct));
        } else {
          const selectedProductPricing = orderPrices.CartItemPrices[index];
          setPrice(
            selectedProductPricing.Quantity ? selectedProductPricing.Subtotal / selectedProductPricing.Quantity : 0.0
          );
        }
        setCartLoading(false);
      } catch (e) {
        setPrice(getPrice(selectedProduct));
        setCartLoading(false);
      }
    })();
  }, [selectedProduct, guest, quantity, matchingCartItem, setCartLoading, getPrice]);

  const quantityAvailable = (product?: PreorderProductSearchResult): string => {
    const newQuantityAvailable = (product?.Quantity ?? 0) - quantity;
    const remainingQuantityAvailable = Math.max(newQuantityAvailable, 0);
    return product?.UnitId === 1
      ? String(remainingQuantityAvailable)
      : `${remainingQuantityAvailable}${product?.UnitAbbr ?? ''}`;
  };

  const onAddItemToPreCart = () => {
    if (!selectedProduct) {
      return;
    }

    if (quantity > 0 && !matchingCartItem) {
      setCartItems((items) => {
        return [
          ...items,
          {
            productId: selectedProduct.ProductId,
            unitId: selectedProduct.UnitId,
            unitAbbr: selectedProduct.UnitAbbr,
            unitPrice: price,
            quantity: quantity,
            totalAvailable: selectedProduct.Quantity,
            productName: selectedProduct.ProductName,
            brandName: selectedProduct.BrandName,
            flowerEquivalent:
              recFlowerEquivalentFeatureEnabled && !guest?.IsMedical
                ? selectedProduct.RecFlowerEquivalent
                : selectedProduct.FlowerEquivalent,
          },
        ];
      });
    } else if (quantity > 0 && matchingCartItem) {
      setCartItems((items) => {
        const item = items.find((x) => x === matchingCartItem);
        if (!item) {
          return items;
        }
        item.quantity += quantity;
        return items.slice();
      });
    }

    dispatch(successNotification(`Item successfully added to cart`));
    dispatch(deselectPreorderProduct());
    setQuantity(1);
  };

  const showProductPrice = !cartLoading && typeof price === 'number';

  return (
    <Container>
      <ProductDetailsContainer>
        <ProductHeader>
          <ProductTitle>{selectedProduct?.ProductName}</ProductTitle>
          <CloseButton onClick={() => dispatch(deselectPreorderProduct())} />
        </ProductHeader>
        {selectedProduct && selectedProduct.ProductImageURL && (
          <ProductImgContainer>
            <ImageWithPlaceholder alt={selectedProduct.ProductName} src={selectedProduct.ProductImageURL} />
          </ProductImgContainer>
        )}
        {selectedProduct?.ProductDescription && (
          <ProductDescription>{selectedProduct.ProductDescription}</ProductDescription>
        )}
        <DetailsRowContainer>
          {selectedProduct?.BrandName && (
            <DetailsRow>
              <DetailName>Brand</DetailName>
              <DetailData>{selectedProduct.BrandName}</DetailData>
            </DetailsRow>
          )}
          {selectedProduct?.VendorName && (
            <DetailsRow>
              <DetailName>Vendor</DetailName>
              <DetailData>{selectedProduct.VendorName}</DetailData>
            </DetailsRow>
          )}
        </DetailsRowContainer>
      </ProductDetailsContainer>
      <ProductPricingContainer>
        <QtySelectContainer>
          <PriceDetailsContainer>
            <PriceContainer>
              Price: {showProductPrice ? `$${price.toFixed(2)}` : <StyledSkeleton height={20} width={50} />}
            </PriceContainer>
            <AvailabilityLabel>Available: {quantityAvailable(selectedProduct)}</AvailabilityLabel>
          </PriceDetailsContainer>
          <div>
            <QuantityPicker
              maxQuantity={selectedProduct?.Quantity ?? 0}
              initialQuantity={selectedProduct?.Quantity ? quantity : 0}
              onQuantityChange={setQuantity}
              allowDecimal={selectedProduct?.UnitId !== 1}
            ></QuantityPicker>
          </div>
        </QtySelectContainer>
        <ActiveProductAddButtonContainer>
          <Button cta disabled={quantity === 0 || selectedProduct?.Quantity === 0} onClick={onAddItemToPreCart}>
            {price && quantity && selectedProduct?.Quantity
              ? `Add to Cart $(${(price * quantity).toFixed(2)})`
              : `Add to Cart (0)`}
          </Button>
        </ActiveProductAddButtonContainer>
      </ProductPricingContainer>
    </Container>
  );
};

const PriceContainer = styled.p`
  color: black;
  display: flex;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

const ActiveProductAddButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 2rem;
`;

const StyledSkeleton = styled(Skeleton)`
  margin-left: 5px;
`;
