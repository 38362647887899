import React, { DragEvent, FC, HTMLAttributes, ReactNode, CSSProperties, useState } from 'react';

const DEFAULT_DATA_FORMAT = 'application/llx-drag-drop';
const hasRelevantPayload = (event: DragEvent, payloadId: string) => event.dataTransfer.types.includes(payloadId);
const noop = () => {};

type DragHandler = (event: DragEvent) => void;

type TargetAsDivProps = {
  as?: 'div';
  className?: string;
  payloadId?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onDrop' | 'className'>;

type TargetAsTableRowProps = {
  as: 'tr';
} & Omit<HTMLAttributes<HTMLTableRowElement>, 'onDrop' | 'className'>;

type TargetProps = {
  className?: string;
  payloadId?: string;
  effect?: DataTransfer['dropEffect'];
} & (TargetAsDivProps | TargetAsTableRowProps);

type DragTargetProps = {
  payload: string;
  appearanceWhenDragActive?: ReactNode;
  dragDisabled?: boolean;
  dragImage?: null;
  dragPlaceholder?: ReactNode;
  onDragActive?: () => unknown;
  onDragInactive?: () => unknown;
};

type DropTargetProps = {
  appearanceWhenDropActive?: ReactNode;
  dropDisabled?: boolean;
  onDrop?: (payload: string) => unknown;
  onDropActive?: () => unknown;
  onDropInactive?: () => unknown;
};

type DragDropTargetProps = {
  handle?: 'drag' | 'drop' | 'drag-drop';
  canDragOnSelf?: boolean;
} & TargetProps &
  Partial<DragTargetProps> &
  Partial<DropTargetProps>;

const BLANK_IMAGE_EL = document.createElement('img');
BLANK_IMAGE_EL.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const DRAG_PLACEHOLDER_DELAY_MS = 20;
const DRAG_PLACEHOLDER_ACTIVE_STYLES: CSSProperties = { position: 'fixed', top: '100vh' };

export const DragDropTarget: FC<DragDropTargetProps> = ({
  children,
  payload,
  as,
  appearanceWhenDragActive = children,
  appearanceWhenDropActive = children,
  dragPlaceholder,
  canDragOnSelf = !!dragPlaceholder,
  className = '',
  dragDisabled,
  dropDisabled,
  effect = 'none',
  handle = 'drag-drop',
  onDragActive = noop,
  onDragInactive = noop,
  onDrop = noop,
  onDropActive = noop,
  onDropInactive = noop,
  payloadId = DEFAULT_DATA_FORMAT,
  dragImage,
  ...elProps
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [isDropActive, setIsDropActive] = useState(false);
  const [isDragPlaceholderActive, setIsDragPlaceholderActive] = useState(false);

  const isDropTemporarilyBlocked = !canDragOnSelf && isDragActive;

  const isDragHandler = !dragDisabled && (handle === 'drag' || handle === 'drag-drop');
  const isDropHandler = !dropDisabled && (handle === 'drop' || handle === 'drag-drop') && !isDropTemporarilyBlocked;

  const dragClassName = isDragActive ? 'drag-active' : 'drag-inactive';
  const dragPlaceholderClassName = isDragPlaceholderActive ? 'drag-placeholder-active' : 'drag-placeholder-inactive';
  const dropClassName = isDropActive ? 'drop-active' : 'drop-inactive';

  const appearance = (() => {
    if (isDragActive) {
      return appearanceWhenDragActive;
    }
    if (isDropActive) {
      return appearanceWhenDropActive;
    }
    return children;
  })();

  if (isDragHandler && typeof payload === undefined) {
    throw new Error('Payload is required for drag targets.');
  }

  // Drag handlers

  /**
   * Triggered when dragging of the target begins.
   */
  const handleDragStart: DragHandler = (event) => {
    event.dataTransfer.setData(payloadId, payload);
    event.dataTransfer.dropEffect = effect;

    if (dragImage === null) {
      event.dataTransfer.setDragImage(BLANK_IMAGE_EL, 0, 0);
    }

    setIsDragActive(true);
    if (dragPlaceholder) {
      setTimeout(() => setIsDragPlaceholderActive(true), DRAG_PLACEHOLDER_DELAY_MS);
    }
    onDragActive();
  };

  /**
   * Triggered when dragging of the target ends, regardless of the outcome.
   */
  const handleDragEnd: DragHandler = () => {
    setIsDragActive(false);
    setIsDragPlaceholderActive(false);
    onDragInactive();
  };

  // Drop handlers

  /**
   * Triggered when a draggable object enters the target
   */
  const handleDragEnter: DragHandler = (event) => {
    if (!hasRelevantPayload(event, payloadId)) {
      return;
    }

    setIsDropActive(true);
    onDropActive();

    event.dataTransfer.dropEffect = effect;
  };

  /**
   * Repeatedly triggered when a draggable object is hovered over the target
   */
  const handleDragOver: DragHandler = (event) => {
    if (!hasRelevantPayload(event, payloadId)) {
      return;
    }

    if (!isDropActive) {
      setIsDropActive(true);
      onDropActive();
    }

    event.dataTransfer.dropEffect = effect;
    event.preventDefault();
  };

  /**
   * Triggered when a draggable object leaves the target (or occasionally when it's
   * hovered over)
   */
  const handleDragLeave: DragHandler = (event) => {
    if (!hasRelevantPayload(event, payloadId)) {
      return;
    }

    setIsDropActive(false);
    onDropInactive();
  };

  /**
   * Triggered when a draggable object is dropped on the target
   */
  const handleDrop: DragHandler = (event) => {
    if (!hasRelevantPayload(event, payloadId)) {
      return;
    }

    setIsDropActive(false);

    const payload = event.dataTransfer.getData(payloadId);
    onDrop(payload);
    event.preventDefault();
  };

  const handlerProps = {
    draggable: isDragHandler,
    className: [className, dragClassName, dropClassName, dragPlaceholderClassName].join(' '),
    onDragStart: isDragHandler ? handleDragStart : undefined,
    onDragEnd: isDragHandler ? handleDragEnd : undefined,
    onDragEnter: isDropHandler ? handleDragEnter : undefined,
    onDragOver: isDropHandler ? handleDragOver : undefined,
    onDragLeave: isDropHandler ? handleDragLeave : undefined,
    onDrop: isDropHandler ? handleDrop : undefined,
  };

  const styleProps = {
    ...(elProps.style ?? {}),
    ...(isDragPlaceholderActive ? DRAG_PLACEHOLDER_ACTIVE_STYLES : {}),
  };

  switch (as) {
    case 'tr':
      return (
        <>
          <tr {...elProps} style={styleProps} {...handlerProps}>
            {appearance}
          </tr>
          {isDragPlaceholderActive && (
            <tr {...elProps} {...handlerProps}>
              {dragPlaceholder}
            </tr>
          )}
        </>
      );
    default:
      return (
        <>
          <div {...elProps} style={styleProps} {...handlerProps}>
            {appearance}
          </div>
          {isDragPlaceholderActive && (
            <div {...elProps} {...handlerProps}>
              {dragPlaceholder}
            </div>
          )}
        </>
      );
  }
};

// Convenience wrappers for drag-only and drop-only functionality

export const DragTarget: FC<TargetProps & DragTargetProps> = (props) => <DragDropTarget {...props} handle='drag' />;

export const DropTarget: FC<TargetProps & DropTargetProps> = (props) => <DragDropTarget {...props} handle='drop' />;
