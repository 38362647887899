import moment from 'moment';
import { useHistory } from 'react-router';
import { differenceInYears, isBefore, isSameDay, isValid, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';

import { setCheckedInGuest } from 'store/actions/CartActions';
import { getCustomerDetails, loadCollectors } from 'store/actions/CustomerActions';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { isDriversLicenseExpired, isMMJLicenseExpired } from 'util/Helpers';
import { useCheckPreorderEligible } from 'util/hooks';
import { CheckedInGuest } from 'models/Guest';
import { Register } from 'models/Misc';
import {
  showCreatePreOrderPopup,
  showFulfillmentSelectorPopup,
  showSelectCollectorPopup,
  showBannedCustomerPopup,
} from 'store/actions/PopupsActions';
import { useGuestAgeInfo } from 'util/hooks/cart/useGuestAgeInfo';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

export const useClickCard = ({ guest }: { guest: CheckedInGuest }) => {
  const isAttestationEnabled = useSelector((state: State) => state.settings.features.Attestation);
  const isCanUseMassDPHIntegrationEnabled = useSelector(
    (state: State) => state.settings.integrations?.CanUseMassDPHIntegration
  );
  const isMassDPHCredsValidEnabled = useSelector((state: State) => state.settings.MassDPHCredsValid);
  const isMMJIdExpirationDateCheckEnabled = useSelector(
    (state: State) => state.settings.features.MMJIdExpirationDateCheck
  );
  const isPOSNoteUnder18Enabled = useSelector((state: State) => state.settings.features.POSNoteUnder18);
  const isPOSNoteUnder21Enabled = useSelector((state: State) => state.settings.features.POSNoteUnder21);
  const isRequireDoctorForCheckInEnabled = useSelector(
    (state: State) => state.settings.features.RequireDoctorForCheckIn
  );
  const isRequirePrescriptionForCheckInEnabled = useSelector(
    (state: State) => state.settings.features.RequirePrescriptionForCheckIn
  );
  const isRequireValidAllotmentCredentialsEnabled = useSelector(
    (state: State) => state.settings.features.RequireValidAllotmentCredentials
  );
  const isRequireValidDLEnabled = useSelector((state: State) => state.settings.features.RequireValidDL);
  const isBlockBannedPatientCheckinEnabled = useSelector(
    (state: State) => state.settings.features.BlockBannedPatientCheckin
  );
  const isUseMnEnabled = useIsUseMnEnabled(guest);

  const register = useSelector((state: State) => state.settings.selectedRegister);
  const dispatch = useDispatch();
  const checkPreorderEligible = useCheckPreorderEligible();
  const isCheckedInWithCaregiver = !!guest.TransactionCaregiver;
  const guestAgeCheck = useGuestAgeInfo({ guest, isCheckedInWithCaregiver });
  const history = useHistory();
  const medId = guest.identifications.filter((identification) => identification.type === 'MJStateIDNo');
  const medExpDate = medId.length > 0 ? medId[0].ExpirationDate.split(' ')[0] : '';
  const medExpExpired = medExpDate ? moment(new Date(medExpDate)).local().isBefore(new Date()) : false;

  const isBanned = guest?.GuestStatus?.toLowerCase() === 'banned';

  const { goToCart } = useTransactionManager();

  const onGoToCart = async (guest: CheckedInGuest, register: Register) => {
    dispatch(setCheckedInGuest(guest));

    if (isUseMnEnabled) {
      dispatch(loadCollectors({ CustomerId: guest.Guest_id }));
      dispatch(showSelectCollectorPopup({ customerId: guest.Guest_id, shipmentId: guest.ShipmentId }));
    } else {
      goToCart({ guestId: guest.Guest_id, shipmentId: guest.ShipmentId });
    }
  };

  const onClickCard = async () => {
    if (guest && register) {
      const guestAge = guest?.Dob ? guest?.Dob : new Date();

      // This is the case where an order comes in from a third party for a customer who is banned
      if (isBlockBannedPatientCheckinEnabled && isBanned) {
        dispatch(showBannedCustomerPopup({ guest }));
        return;
      }

      if (medExpExpired) {
        dispatch(warningNotification('MMJ expired.'));
      }

      if (isAttestationEnabled) {
        if (guest?.Attestation.toLowerCase() === 'no') {
          dispatch(errorNotification('Patient has not completed the Attestation document. You cannot proceed.'));
          return;
        }

        if (
          isBefore(parseISO(guest?.AttestationExpirationDate), new Date()) ||
          isSameDay(parseISO(guest?.AttestationExpirationDate), new Date())
        ) {
          dispatch(errorNotification('Patient Attestation document has expired. You cannot proceed.'));
          return;
        }
      }

      if (Number(guest.RegisterId) !== 0 && Number(guest.RegisterId) !== register.value) {
        dispatch(warningNotification(`Guest is already assigned to ${guest.Register}`));
        return;
      }

      if (
        isCanUseMassDPHIntegrationEnabled &&
        guest.PatientType !== 'Recreational' &&
        isRequireValidAllotmentCredentialsEnabled &&
        !isMassDPHCredsValidEnabled
      ) {
        dispatch(errorNotification('Your DPH Credentials are invalid! Go to settings and update to continue.'));
        return;
      }

      if (!!isRequireDoctorForCheckInEnabled && guest.IsMedical && !guest.doctor) {
        dispatch(errorNotification('Patient does not have a doctor on record.'));
        return;
      }

      if (!!isRequirePrescriptionForCheckInEnabled && !guest.ValidPrescription) {
        dispatch(errorNotification('Patient does not have a valid prescription on record.'));
        return;
      }

      if (!guest.IsAnonymous && isRequireValidDLEnabled && isDriversLicenseExpired(guest)) {
        dispatch(errorNotification(`Patient's Driver's License has expired.`));
        return;
      }

      if (isMMJIdExpirationDateCheckEnabled && guest.PatientType === 'Medical' && isMMJLicenseExpired(guest)) {
        dispatch(errorNotification(`Patient's MMJ License has expired.`));
        return;
      }

      if (guestAgeCheck.isBlocked) {
        dispatch(errorNotification(`Patient is under the age of ${guestAgeCheck.defaultMinorAge}. You cannot proceed`));
        return;
      }

      if (
        isPOSNoteUnder18Enabled &&
        (differenceInYears(new Date(), new Date(guestAge)) < 18 || !isValid(new Date(guestAge)))
      ) {
        dispatch(warningNotification('Patient DOB not valid or patient is under 18'));
      } else if (
        isPOSNoteUnder21Enabled &&
        (differenceInYears(new Date(), new Date(guestAge)) < 21 || !isValid(new Date(guestAge)))
      ) {
        dispatch(warningNotification('Patient DOB not valid or patient is under 21'));
      }

      if (checkPreorderEligible(guest.Guest_id)) {
        dispatch(
          showFulfillmentSelectorPopup({
            onGoToCart: async () => {
              await onGoToCart(guest, register);
            },
            onCreatePreorder: async () => {
              await dispatch(getCustomerDetails({ guestId: guest.Guest_id }));
              dispatch(
                showCreatePreOrderPopup({
                  onCheckIn: () => {
                    history.push(`/create-preorder`);
                  },
                })
              );
            },
          })
        );
        return;
      } else {
        await onGoToCart(guest, register);
      }
    }
  };

  return onClickCard;
};
