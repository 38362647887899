import React, { FC, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ButtonWithIcon } from './ButtonWithIcon';
import { colors } from 'css/Theme';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';

export const BackButton: FC<ButtonHTMLAttributes<HTMLElement>> = ({ onClick }) => (
  <StyledButtonWithIcon automationId='back-button' icon={ChevronLeft} onClick={onClick} secondary />
);

const StyledButtonWithIcon = styled(ButtonWithIcon)`
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  & svg {
    height: 20px;
  }
`;
