import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { settingsSlice, SettingsState } from 'store/reducers/SettingsReducer';
import { notificationsReducer, NotificationsState } from 'store/reducers/NotificationsReducer';
import { guestListSlice, GuestListState } from 'store/reducers/GuestListReducer';
import { userReducer, UserState } from 'store/reducers/UserReducer';
import { cartSlice, CartState } from 'store/reducers/CartReducer';
import { cartItemsSlice, CartItemsState } from 'store/reducers/CartItemsReducer';
import { productsSlice, ProductState } from 'store/reducers/ProductsReducer';
import { customerSlice, CustomerState } from 'store/reducers/CustomerReducer';
import { printSlice, PrintState } from 'store/reducers/PrintReducer';
import { printJobsSlice, PrintJobsState } from './reducers/PrintJobsReducer';
import { checkoutSlice, CheckoutState } from 'store/reducers/CheckoutReducer';
import { transactionsSlice, TransactionsState } from 'store/reducers/TransactionsReducer';
import { deliveryListSlice, DeliveryListState } from 'store/reducers/DeliveryReducer';
import { fleetListSlice, FleetListState } from 'store/reducers/FleetReducer';
import { LDFlagSlice, LaunchDarklyFlags } from './reducers/LaunchDarklyReducer';
import { StorageSlice, StorageState } from './reducers/StorageReducer';
import { scannerReducer, ScannerState } from './reducers/ScannerReducer';
import { popupsReducer, PopupsState } from './reducers/PopupsReducer';
import { actionableErrorSlice, ActionableErrorState } from './reducers/ActionableErrorReducer';
import { cfdSlice, CFDState } from './reducers/CFDReducer';
import { metricsSlice, MetricsState } from './reducers/MetricsReducer';
import { cashManagementSlice, CashManagementState } from './reducers/CashManagementReducer';
import { logger } from 'util/logger';

export const reducer = {
  settings: settingsSlice.reducer,
  notifications: notificationsReducer,
  actionableError: actionableErrorSlice.reducer,
  guestList: guestListSlice.reducer,
  deliveryList: deliveryListSlice.reducer,
  fleetList: fleetListSlice.reducer,
  user: userReducer,
  cart: cartSlice.reducer,
  products: productsSlice.reducer,
  customer: customerSlice.reducer,
  cartItems: cartItemsSlice.reducer,
  print: printSlice.reducer,
  printJobs: printJobsSlice.reducer,
  checkout: checkoutSlice.reducer,
  transactions: transactionsSlice.reducer,
  ldFlags: LDFlagSlice.reducer,
  storageState: StorageSlice.reducer,
  scanner: scannerReducer,
  popups: popupsReducer,
  cfd: cfdSlice.reducer,
  metrics: metricsSlice.reducer,
  cashManagement: cashManagementSlice.reducer,
};

export type State = {
  settings: SettingsState;
  notifications: NotificationsState;
  actionableError: ActionableErrorState;
  guestList: GuestListState;
  deliveryList: DeliveryListState;
  fleetList: FleetListState;
  user: UserState;
  cart: CartState;
  products: ProductState;
  customer: CustomerState;
  cartItems: CartItemsState;
  print: PrintState;
  printJobs: PrintJobsState;
  checkout: CheckoutState;
  transactions: TransactionsState;
  ldFlags: LaunchDarklyFlags;
  storageState: StorageState;
  scanner: ScannerState;
  popups: PopupsState;
  cfd: CFDState;
  metrics: MetricsState;
  cashManagement: CashManagementState;
};

const persistConfig = { key: 'root', storage: storageSession, whitelist: ['user', 'settings'] };
const persistedReducer = persistReducer(persistConfig, combineReducers(reducer));

const stateSyncMiddleware = createStateSyncMiddleware({
  whitelist: ['clearCFDCart', 'updateCFDCartTotals', 'updateCFDData'],
});

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, isImmutable: false })
      .concat(logger.getAllMiddleware())
      .concat(stateSyncMiddleware),
});
initMessageListener(store);

export type AppDispatch = typeof store.dispatch;
