import { useQuery } from '@tanstack/react-query';

import { post } from 'api/HttpHelpers';

import type { UseQueryOptions } from '@tanstack/react-query';
import type { CustomerDetails } from 'models/Customer';

type GuestDetailsPayload = {
  guestId: number;
};

export const useGuestDetailsQuery = (payload: GuestDetailsPayload, options?: Partial<Omit<UseQueryOptions<CustomerDetails>, 'queryFn'>>) => {
  return useQuery({
    queryKey: ['guestDetails', payload.guestId],
    queryFn: async () => {
      const response = await post<CustomerDetails>('v2/guest/details', {
        Guest_id: payload.guestId,
      });

      return response;
    },
    ...options,
  });
};
