import { useEffect, useState } from "react";

type UseStickyHeaderHeightParams = {
  headerRef: React.RefObject<HTMLDivElement>;
};

/**
 * Use to get the height of the sticky header (StickyHeader.tsx)
 */
export const useStickyHeaderHeight = ({ headerRef }: UseStickyHeaderHeightParams) => {
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState<number>(0);
  const styles = headerRef.current && window.getComputedStyle(headerRef.current).height;

  useEffect(() => {
    const getHeight = (): number => {
      if (headerRef.current) {
        const headerHeight = window.getComputedStyle(headerRef.current).height;
        const headerHeightWithoutSuffix = headerHeight.match(/[0-9]*/);
        const headerHeightValue = headerHeightWithoutSuffix ? parseInt(headerHeightWithoutSuffix[0] ?? 0) : 0;

        setStickyHeaderHeight(headerHeightValue);
        return headerHeightValue;
      }

      return 0;
    };

    // Have to set timeout here because on smaller screen sizes, the header takes a bit longer to set the height
    // and the resize event doesn't fire. This makes it way less likely we end up with an incorrect height measurement.
    setTimeout(() => {
      getHeight();
    }, 500);

    window.addEventListener('resize', getHeight);
    return () => window.removeEventListener('resize', getHeight);
  }, [headerRef, styles]);

  return stickyHeaderHeight;
};
