import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { customEventKeys, logger } from 'util/logger';
import { errorNotification } from 'store/actions/NotificationsActions';
import { getProductHistoryKeys } from './query-key-factory';
import { transformToProductHistoryItem } from './types';
import { useAppDispatch } from 'util/hooks';

import type { ProductHistoryItem, ProductHistoryServerResponse } from './types';

const PRODUCT_HISTORY = 'v2/product/product_history';

export type ProductHistoryPayload = {
  guestId: number;
};

const transformToServerPayload = (payload: ProductHistoryPayload) => {
  return {
    Guest_id: payload.guestId,
  };
};

type GetProductHistoryOptions<TData = ProductHistoryItem[]> = Partial<
  Omit<UseQueryOptions<ProductHistoryItem[], unknown, TData>, 'select'>
> & {
  select?: (productHistoryItems: ProductHistoryItem[]) => TData;
};

export const useGetProductHistoryQuery = <TData = ProductHistoryItem[]>(
  payload: ProductHistoryPayload,
  options?: GetProductHistoryOptions<TData>
) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: getProductHistoryKeys.one(payload.guestId),
    queryFn: async () => {
      try {
        const response = await post<ProductHistoryServerResponse[]>(PRODUCT_HISTORY, transformToServerPayload(payload));

        logger.debug(`[ReactQuery] product history was loaded for ${payload.guestId}`, {
          key: customEventKeys.reactQuery,
          payload,
        });

        return response.map(transformToProductHistoryItem);
      } catch (e) {
        dispatch(errorNotification('Could not load transactions list'));
        return Promise.reject(e);
      }
    },
    ...options,
  });
};
