import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  size?: number;
  color?: string;
};

export const PasswordIcon = ({
  width = 24,
  height = 24,
  size = undefined,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={size ?? width} height={size ?? height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04808 5.14285C1.04808 4.77466 1.34656 4.47618 1.71475 4.47618H14.2862C14.6544 4.47618 14.9528 4.77466 14.9528 5.14285V10.8571C14.9528 11.2253 14.6544 11.5238 14.2862 11.5238H1.71475C1.34656 11.5238 1.04808 11.2253 1.04808 10.8571V5.14285ZM1.71475 3.5238C0.820575 3.5238 0.0957031 4.24868 0.0957031 5.14285V10.8571C0.0957031 11.7513 0.820575 12.4762 1.71475 12.4762H14.2862C15.1804 12.4762 15.9052 11.7513 15.9052 10.8571V5.14285C15.9052 4.24868 15.1804 3.5238 14.2862 3.5238H1.71475ZM3.90522 7.99999C3.90522 7.94739 3.94786 7.90475 4.00046 7.90475C4.05306 7.90475 4.0957 7.94739 4.0957 7.99999C4.0957 8.05259 4.05306 8.09523 4.00046 8.09523C3.94786 8.09523 3.90522 8.05259 3.90522 7.99999ZM4.00046 6.95237C3.42188 6.95237 2.95284 7.42141 2.95284 7.99999C2.95284 8.57858 3.42188 9.04761 4.00046 9.04761C4.57904 9.04761 5.04808 8.57858 5.04808 7.99999C5.04808 7.42141 4.57904 6.95237 4.00046 6.95237ZM7.42903 7.90475C7.37643 7.90475 7.33379 7.94739 7.33379 7.99999C7.33379 8.05259 7.37643 8.09523 7.42903 8.09523C7.48163 8.09523 7.52426 8.05259 7.52426 7.99999C7.52426 7.94739 7.48163 7.90475 7.42903 7.90475ZM6.38141 7.99999C6.38141 7.42141 6.85044 6.95237 7.42903 6.95237C8.00761 6.95237 8.47665 7.42141 8.47665 7.99999C8.47665 8.57858 8.00761 9.04761 7.42903 9.04761C6.85044 9.04761 6.38141 8.57858 6.38141 7.99999ZM10.8576 8.66667C10.5946 8.66667 10.3814 8.87986 10.3814 9.14286C10.3814 9.40585 10.5946 9.61905 10.8576 9.61905H12.5719C12.8349 9.61905 13.0481 9.40585 13.0481 9.14286C13.0481 8.87986 12.8349 8.66667 12.5719 8.66667H10.8576Z" fill={color} />
    </svg>
  );
};
