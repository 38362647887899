export const CashManagementTabs = [
  {
    label: 'Transactions',
    route: 'transactions',
    tabIndex: 0,
  },
  {
    label: 'Close out',
    route: 'closeout',
    tabIndex: 1,
  },
  {
    label: 'Drop cash',
    route: 'dropcash',
    tabIndex: 2,
  },
  {
    label: 'Transfer',
    route: 'transfer',
    tabIndex: 3,
  },
  {
    label: 'Withdraw',
    route: 'withdraw',
    tabIndex: 4,
  },
  {
    label: 'Deposit',
    route: 'deposit',
    tabIndex: 5,
  },
];

type TabLookupProps = {
  index?: number | string | null;
  route?: string;
};

export const lookupTab = ({ index, route }: TabLookupProps) => {
  if (index !== undefined) {
    return CashManagementTabs.find((tab) => tab.tabIndex === Number(index));
  }
  if (route) {
    return CashManagementTabs.find((tab) => tab.route === route);
  }
  return null;
};
