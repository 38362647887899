import React from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';

import { CheckedInGuest } from 'models/Guest';

type Props = {
  status: string;
  onDrop: (guest: CheckedInGuest, destinationStatus: string) => void;
};
export const DropTarget: React.FC<Props> = ({ status, onDrop }) => {
  const handleDrop = (item: { guest: CheckedInGuest }) => {
    const { guest } = item;
    onDrop(guest, status);
  };

  const [{ isOver, canDrop, currentItemStatus }, drop] = useDrop({
    accept: 'card',
    drop: handleDrop,
    collect: (monitor) => {
      return {
        currentItemStatus: monitor.getItem()?.guest?.TransactionStatus,
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  if (currentItemStatus === status || !canDrop) {
    return null;
  }

  return (
    <Wrapper>
      <Target isOver={isOver} ref={drop} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f2f3f4;
  position: absolute;
  z-index: 2;
  height: 100%;
  top: 37px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 7px 12px 0;
`;

const Target = styled.div<{ isOver: boolean }>`
  background-color: ${({ isOver }) => (isOver ? '#F1F8F7' : '#F5F6F7')};
  border: 1px dashed ${({ isOver }) => (isOver ? '#95D2C3' : '#b0b7bb')};
  border-radius: 6px;
  height: 100%;
`;
