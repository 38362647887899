import { getCFDCustomIdleImage } from 'api/PosApi';

/**
 * Calls the get-cfd-custom-idle-image endpoint and formats the image as a data string
 * @returns imageDataString or undefined
 */
export const getCFDCustomImage = async (): Promise<string | undefined> => {
  try {
    const { Data, ContentType } = await getCFDCustomIdleImage();
    if (Data) {
      return `data:${ContentType ?? 'image/png'};base64,${Data}`;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

