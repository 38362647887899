import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { colors } from 'css/Theme';
import { usePageNums } from './usePageNums';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';

import type { callback } from 'models/Misc';

type PaginationButtonsProps = {
  small?: boolean;
  currentPage: number;
  pagesCount: number;
  previousPage: callback;
  nextPage: callback;
  canPreviousPage: boolean;
  canNextPage: boolean;
  goToPage: (nextPage: number) => void;
};

export const PaginationButtons: FC<PaginationButtonsProps> = (props) => {
  const { small, currentPage, pagesCount, previousPage, nextPage, canPreviousPage, canNextPage, goToPage } = props;
  const pageNums = usePageNums(pagesCount, currentPage);

  const handleNextClick = () => {
    if (canNextPage) {
      nextPage();
    }
  };

  const handlePrevClick = () => {
    if (canPreviousPage) {
      previousPage();
    }
  };

  return (
    <ButtonsContainer>
      <PageSwitch
        small={small}
        direction='left'
        onClick={handlePrevClick}
        disabled={!canPreviousPage}
        data-testid='pagination-previous-button'
      />
      {pageNums.map((num, idx) => (
        <PageCircle
          small={small}
          active={num === currentPage}
          key={idx}
          isThreeDots={!num}
          onClick={() => num && goToPage(num)}
          data-testid={`pagination-page-${num || 'placeholder'}`}
        >
          {num || '...'}
        </PageCircle>
      ))}
      <PageSwitch
        small={small}
        direction='right'
        onClick={handleNextClick}
        disabled={!canNextPage}
        data-testid='pagination-next-button'
      />
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BaseCircle = styled.span`
  position: relative;
  width: 38px;
  height: 38px;
  display: inline-block;
  border-radius: 100%;
`;

type PageSwitchProps = {
  small?: boolean;
  disabled?: boolean;
  direction: 'left' | 'right';
  onClick: callback;
};

const ArrowCircle = styled(BaseCircle)<PageSwitchProps>`
  border: 1px solid ${colors.dutchie.backgroundGrey};
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  ${({ small }) =>
    small &&
    css`
      width: 2rem;
      height: 2rem;
    `}

  ${({ disabled }) =>
    !disabled &&
    `
        cursor: pointer;

        &:hover {
            border: 1px solid ${colors.dutchie.borderGrey};
            box-sizing: border-box;
            box-shadow: 0 2px 4px ${rgba(colors.realBlack, 0.1)};
        }
    `}

  ${({ direction }) =>
    direction === 'left' &&
    `
        margin-right: 4px;
    `}

    ${({ direction }) =>
    direction === 'right' &&
    `
        margin-left: 4px;
    `}

    svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 18px;
    height: 18px;
    color: ${colors.dutchie.almostBlack};

    ${({ disabled }) =>
      disabled &&
      `
            color: ${colors.dutchie.grey};
        `}

    ${({ direction }) =>
      direction === 'right' &&
      `
            transform: translateY(-50%) translateX(-50%) rotate(180deg);
        `}
  }
`;

const PageSwitch: FC<PageSwitchProps> = (props) => (
  <ArrowCircle {...props}>
    <ChevronLeft />
  </ArrowCircle>
);

type PageCircleProps = {
  small?: boolean;
  active?: boolean;
  isThreeDots?: boolean;
};

const PageCircle = styled(BaseCircle)<PageCircleProps>`
  cursor: pointer;
  color: ${colors.dutchie.grey40};
  background-color: transparent;
  line-height: 38px;
  text-align: center;
  margin: 0 1px;

  ${({ small }) =>
    small &&
    css`
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
    `}

  &:hover {
    background-color: ${colors.dutchie.lightGrey};
  }

  ${({ active }) =>
    active &&
    `
        cursor: default;
        font-weight: 600;
        color: ${colors.dutchie.almostBlack};

        &:hover {
            background-color: inherit;
        }
    `}

  ${({ isThreeDots }) =>
    isThreeDots &&
    `
        cursor: default;

        &:hover {
            background-color: inherit;
        }
    `}
`;
