export type PaymentIconProps = {
  width?: number;
  height?: number;
  disabled?: boolean;
};

export const PaymentIconDimensions = {
  width: 24,
  height: 24,
};
