import React from 'react';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { MassDphSettings } from '../Hardware/MassDph/MassDphSettings';

// Component access is gated by SettingsTabs
export const MassDphTab = () => {
  return (
    <SettingsCard automationId='mass-dph-settings-card' verticalPadding={true}>
      <MassDphSettings />
    </SettingsCard>
  );
};
