import React from 'react';

import { ConfirmationModal } from 'components/modals/ConfirmationModal';
import { useUnsavedChangesPopup } from './useUnsavedChangesPopup';

type UnsavedChangesPopupProps = {
  hide: () => void;
  save: () => Promise<boolean>;
  navigate: () => void;
};
/** @deprecated - use version in sharedPopups */
export const UnsavedChangesPopup = ({ hide, save, navigate }: UnsavedChangesPopupProps): JSX.Element => {
  const { handleSaveAndContinue, handleDiscard } = useUnsavedChangesPopup({ save, navigate, hide });

  return (
    <ConfirmationModal
      header='Unsaved changes'
      message='Would you like to save your changes?'
      modalName='UnsavedChangesPopup'
      rightButton={{
        text: 'Save and continue',
        onClick: handleSaveAndContinue,
      }}
      leftButton={{
        text: 'Discard',
        transparentRed: true,
        onClick: handleDiscard,
      }}
      hide={hide}
    />
  );
};
