import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { checkedInGuestsKeys } from './query-key-factory';
import { logger, customEventKeys } from 'util/logger';
import { transformToCheckedInGuest } from './types';

import type { CheckedInGuestsServerResponse, CheckedInGuest } from './types';

const CHECKED_IN_GUESTS = 'v2/guest/checked-in';

type GetCheckedInGuestsOptions<TData = CheckedInGuest[]> = Partial<
  Omit<UseQueryOptions<CheckedInGuest[], unknown, TData>, 'select'>
> & {
  select?: (checkedInGuests: CheckedInGuest[]) => TData;
};

export const useGetCheckedInGuestsQuery = <TData = CheckedInGuest[]>(options?: GetCheckedInGuestsOptions<TData>) => {
  return useQuery({
    queryKey: checkedInGuestsKeys.all,
    queryFn: async () => {
      const response = await post<CheckedInGuestsServerResponse[]>(CHECKED_IN_GUESTS);

      logger.debug('[React Query] useGetCheckedInGuestsQuery', {
        key: customEventKeys.reactQuery,
        payload: response,
      });

      return response.map(transformToCheckedInGuest);
    },
    placeholderData: [],
    ...options,
  });
};
