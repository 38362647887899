import React, { FC, useEffect, useState } from 'react';
import { SectionGridContainer, SectionHeader, SectionContent } from './SettingPageComponents';
import { Keyboard, KeyboardStatus } from '@dutchie/capacitor-keyboard';
import { Button } from 'components/buttons';
import styled from 'styled-components';

const SectionContentWithGap = styled(SectionContent)`
  display: flex;
  gap: 1rem;
`;

export const KeyboardPicker: FC = () => {
  const [keyboardStatus, setKeyboardStatus] = useState<KeyboardStatus>();

  useEffect(() => {
    Keyboard.status().then((status) => {
      setKeyboardStatus(status);
    });
  }, []);

  return (
    <SectionGridContainer>
      <SectionHeader>Keyboard settings</SectionHeader>
      <SectionContentWithGap>
        {!keyboardStatus?.installed && <p>No custom keyboards available</p>}
        {keyboardStatus?.installed && !keyboardStatus.enabled && (
          <Button tertiary onClick={() => Keyboard.openSystemSettings()}>
            Open System Settings
          </Button>
        )}
        {keyboardStatus?.installed && (
          <Button tertiary onClick={() => Keyboard.showPicker()}>
            Select Keyboard
          </Button>
        )}
      </SectionContentWithGap>
    </SectionGridContainer>
  );
};
