import { round, reduce, filter, floor, find, every, chain } from 'lodash';
import { differenceInDays, format, isAfter, isBefore, isSameDay } from 'date-fns';
import match from 'match-sorter';
import * as Cookies from 'es-cookie';
import { Http } from '@capacitor-community/http';
import { isAndroid, isWebViewApp } from 'util/hooks';

import {
  getUserDetails,
  logIn,
  checkEmployeePin,
  forgotPassword,
  resetPassword,
  ssoLogin,
  ssoLoginEntity,
  ssoLoginEnabled,
  getPasswordSettings,
  updateDefaultLocation,
  logOut,
  getEloUserDetails,
} from 'api/PosApi';
import { LogoutRequest, Register, SessionInfo, UserInfoResponse } from 'models/Misc';
import { CartItem, Preorder } from 'models/Cart';
import { PaymentMethod } from 'models/Checkout';
import { FilterOption } from 'models/Search';
import { CustomerDetails, Option } from 'models/Customer';
import { CheckedInGuest, GuestListSortOptions, GuestStatus } from 'models/Guest';
import { LocationSettings } from 'models/Location';
import { State } from 'store';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getLocationLogo, getLspLogo } from 'api/SettingsApi';
import { SettingsState } from 'store/reducers/SettingsReducer';
import { Delivery, DeliveryFilterOption } from 'models/Delivery';
import { SearchAndFilterReturn, TimeWindow } from 'store/reducers/DeliveryReducer';
import { DeliveryRoute } from 'models/DeliveryRoute';
import { getTimeExpectedSortValues } from './get-expected-time-window';
import { logger, LoggingProviders } from './logger';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { LDFlagSet } from 'launchdarkly-react-client-sdk';

export const setupFullstoryAndLogRocket = () => {
  try {
    // eslint-disable-next-line
    const FullStory = (window as any).FS || {};
    FullStory.identify(window.sessionStorage.getItem('UserId'), {
      displayName: window.sessionStorage.getItem('FullName'),
      email: window.sessionStorage.getItem('UserName'),
    });
  } catch (ex) {
    //eat the error and continue on
  }

  try {
    logger.setUser(LoggingProviders.LOGROCKET, window.sessionStorage.getItem('UserId') as string, {
      displayName: window.sessionStorage.getItem('FullName') as string,
      email: window.sessionStorage.getItem('UserName') as string,
      isDutchieHardware: isAndroid,
      registerVersion: 'dutchie-register-v1',
    });
  } catch (ex) {
    //eat the error and continue on
  }
};

export const setupPendo = () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pendo = (window as any).pendo ?? {};
    pendo.initialize({
      visitor: {
        id: window.sessionStorage.getItem('UserId'),
        email: window.sessionStorage.getItem('UserName'),
        full_name: window.sessionStorage.getItem('FullName'),
        LocId: window.sessionStorage.getItem('LocId'),
        LspId: window.sessionStorage.getItem('LspId'),
        Deployment: window.sessionStorage.getItem('Region'),
        location_id_and_region: `${window.sessionStorage.getItem('LocId')}${window.sessionStorage.getItem('Region')}`,
        platform: isAndroid ? 'android' : isWebViewApp ? 'ios' : 'browser',
      },
      account: {
        id: window.sessionStorage.getItem('LocId'),
      },
    });
  } catch (error) {
    // eat it
  }
};

export const logInRequestAuto = (sessionId: string, locId: number): Promise<UserInfoResponse> => {
  window.sessionStorage.setItem('SessionId', sessionId);
  window.sessionStorage.setItem('SessionGId', sessionId);

  return getUserDetails().then(async (userInfo) => {
    window.sessionStorage.setItem('UserId', userInfo.UserId?.toString());
    window.sessionStorage.setItem('FullName', userInfo.FullName);
    window.sessionStorage.setItem('UserName', userInfo.UserName);
    window.sessionStorage.setItem('LspId', `${userInfo.LspId}`);
    window.sessionStorage.setItem('LocId', `${locId}`);
    window.sessionStorage.setItem('Region', `${userInfo.Region}`);

    await updateDefaultLocation(locId);
    userInfo.LocId = locId;

    setupFullstoryAndLogRocket();
    setupPendo();
    return userInfo;
  });
};

export const logInRequest = (username: string, password: string, ldFlags?: LDFlagSet): Promise<UserInfoResponse> => {
  const isLoginImprovementsEnabled = ldFlags && ldFlags['pos.iam.login-improvements-2024-05.rollout'];
  return logIn(username, password, isLoginImprovementsEnabled).then((resp) => {
    const json = resp.data;
    if (json.Result) {
      window.localStorage.setItem('traceparent', json.Data.TraceId);
      if (json.Data.Url) {
        return Promise.reject(json.Data);
      }
      window.sessionStorage.setItem('SessionId', json.Data.SessionId);
      window.sessionStorage.setItem('SessionGId', json.Data.SessionGId);
      window.sessionStorage.setItem('UserId', json.Data.Id);
      window.sessionStorage.setItem('FullName', json.Data.FullName);
      window.sessionStorage.setItem('UserName', username);
      setupFullstoryAndLogRocket();

      if (ldFlags && ldFlags['pos.iam.pin-login-elo.rollout'] && isAndroid) {
        return getEloUserDetails().then((json2) => {
          if (json2) {
            window.sessionStorage.setItem('LspId', `${json2.LspId}`);
            window.sessionStorage.setItem('LocId', `${json2.LocId}`);
            window.sessionStorage.setItem('Region', `${json2.Region}`);
            window.sessionStorage.setItem('DeviceSessionId', json2.DeviceSessionId);
            window.sessionStorage.setItem('DeviceSessionTime', json2.DeviceSessionTime);
            setupPendo();

            return Promise.resolve(json2);
          }
          return Promise.reject();
        });
      }

      return getUserDetails().then((json2) => {
        if (json2) {
          window.sessionStorage.setItem('LspId', `${json2.LspId}`);
          window.sessionStorage.setItem('LocId', `${json2.LocId}`);
          window.sessionStorage.setItem('Region', `${json2.Region}`);
          setupPendo();

          return Promise.resolve(json2);
        }
        return Promise.reject();
      });
    }
    return Promise.reject(json.Data);
  });
};

export const logoutRequest = (logoutRequest: LogoutRequest) => {
  logOut(logoutRequest).then((res) => {
    const result = res.data;
    if (result && result.Result && result.Data) {
      window.location.href = result.Data;
    }
  });
};

export const loginRequestFromIdProvider = (): Promise<UserInfoResponse> => {
  if (!isAndroid) {
    const sessionInfoFromCookie: SessionInfo = JSON.parse(Cookies.get('LeafLogixSessionInfo') || '{}');
    window.sessionStorage.setItem('SessionId', sessionInfoFromCookie.SessionId);
    window.sessionStorage.setItem('SessionGId', sessionInfoFromCookie.SessionGid);
    window.sessionStorage.setItem('UserId', sessionInfoFromCookie.Id);
    window.sessionStorage.setItem('FullName', sessionInfoFromCookie.FullName);
    window.sessionStorage.setItem('UserName', sessionInfoFromCookie.Id);
  }

  setupFullstoryAndLogRocket();
  const getUserDetailsFn = isAndroid ? getEloUserDetails : getUserDetails;

  return getUserDetailsFn().then((json2) => {
    if (json2) {
      window.sessionStorage.setItem('LspId', `${json2.LspId}`);
      window.sessionStorage.setItem('LocId', `${json2.LocId}`);
      window.sessionStorage.setItem('Region', `${json2.Region}`);
      setupPendo();
      return Promise.resolve(json2);
    }
    return Promise.reject();
  });
};

export const ssoLoginRequest = (username: string, isResetPinLogin: boolean = false) => {
  return ssoLogin(username, isResetPinLogin)
    .then((json) => {
      if (json) {
        window.location.href = json;
      }
    })
    .catch((error) => Promise.reject(error));
};

export const ssoLoginEntityRequest = (entity: string) => {
  return ssoLoginEntity(entity).then((resp) => {
    const json = resp.data;
    if (json.Result) {
      window.location.href = json.Data;
    }
  });
};

export const ssoLoginEnabledRequest = () => {
  return ssoLoginEnabled().then((json) => {
    return json;
  });
};

export const ForgotRequest = (username: string) => {
  return forgotPassword(username);
};

export const ResetRequest = (password2: string, token: string) => {
  return resetPassword(password2, token);
};

export const getPasswordSettingsRequest = (resetToken: string) => {
  return getPasswordSettings(resetToken);
};

export const clearSessionCookies = () => {
  window.sessionStorage.removeItem('SessionId');
  window.sessionStorage.removeItem('SessionGId');
  window.sessionStorage.removeItem('LspId');
  window.sessionStorage.removeItem('LocId');
  window.sessionStorage.removeItem('UserId');
  window.sessionStorage.removeItem('FullName');
  window.sessionStorage.removeItem('llxNewPosBannerIsOpen');
};

export const getPaymentSummary = (methods: Array<PaymentMethod>) => {
  return reduce<PaymentMethod, Record<number, number>>(
    methods,
    (acc, m) => {
      if (!acc[m.type]) {
        acc[m.type] = m.amount;
      } else {
        acc[m.type] += m.amount;
      }
      return acc;
    },
    {}
  );
};

export const formatCartItemQuantity = (item: CartItem | Preorder) => {
  if (item.Grams && item.Grams > 0) {
    return `${item.QtySelected} @ ${(item.Grams / item.QtySelected).toFixed(2)}g`;
  }

  return `${item.QtySelected}, 0g`;
};

export const formatErrorMessage = (maybeErrorMessage?: unknown): string | null => {
  if (typeof maybeErrorMessage !== 'string') {
    return null;
  }
  const isProbablyJsonString = /[{}[\]]/.test(maybeErrorMessage);
  if (isProbablyJsonString) {
    return null;
  }
  return maybeErrorMessage;
};

export const checkManagerPin = async (pin?: string) => {
  if (!pin || pin.length < 1) {
    throw new Error('Manager PIN Missing');
  }

  const response = await checkEmployeePin(pin || '');

  if (!response[0].MgrPermission) {
    throw new Error('Invalid Manager PIN');
  }
  return response;
};

export const formatDate = (dateString: string) => {
  try {
    return format(new Date(dateString), 'MM/dd/yyyy');
  } catch {
    return dateString;
  }
};

export const dataURItoBlob = (dataURI: string) => {
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    return dataURI.split(',')[1];
  }
  return '';
};

export const totalRoundedDown = (features: Record<string, boolean>, total: number) => {
  let totalRoundedDown = 0;
  if (features['RoundDownToNearestFiver'] && total > 5) {
    totalRoundedDown = floor(total / 5) * 5;
  } else if (features['RoundDownToDollar'] && total > 1) {
    totalRoundedDown = floor(total);
  } else if (features['RoundToNearestDollar']) {
    totalRoundedDown = Number(round(total).toFixed(0));
  } else if (features['RoundDownToQuarter'] && total > 0.25) {
    totalRoundedDown = Number((floor(total / 0.25) * 0.25).toFixed(2));
  }

  return round(totalRoundedDown, 2);
};

export const roundChangeDue = (features: Record<string, boolean>, amount: number): number => {
  if (features['RoundToNearestNickel']) {
    return Number(((round((amount * 100) / 5) / 100) * 5).toFixed(2));
  }
  return round(amount, 2);
};

export const unitsAndUnitIds: Array<Option> = [
  { label: 'qty', value: '1' },
  { label: 'ml', value: '2' },
  { label: 'g', value: '3' },
  { label: 'Gal', value: '4' },
  { label: 'L', value: '5' },
  { label: 'lb', value: '6' },
  { label: 'mg', value: '7' },
  { label: 'oz', value: '8' },
  { label: 'fl oz', value: '9' },
  { label: 'kg', value: '10' },
];

export const findCorrespondingUnitWithId = (unitId: number) => {
  const unitName = find(unitsAndUnitIds, (x) => {
    return Number(x.value) === unitId;
  });

  return unitName?.label;
};

export const labUnitsAndUnitIds = [
  { title: 'mg', option: 1 },
  { title: '%', option: 2 },
  { title: 'mg/g', option: 3 },
  { title: 'n.d.', option: 4 },
  { title: '<LOQ', option: 5 },
];

export const findCorrespondingLabUnitWithId = (unitId: number) => {
  const unitName = find(labUnitsAndUnitIds, (x) => {
    return x.option === unitId;
  });

  return unitName?.title;
};

const unitConversionRatios = [
  { fromUnitId: 1, toUnitId: 1, ratio: 1.0 },
  { fromUnitId: 2, toUnitId: 2, ratio: 1.0 },
  { fromUnitId: 3, toUnitId: 3, ratio: 1.0 },
  { fromUnitId: 4, toUnitId: 4, ratio: 1.0 },
  { fromUnitId: 5, toUnitId: 5, ratio: 1.0 },
  { fromUnitId: 6, toUnitId: 6, ratio: 1.0 },
  { fromUnitId: 7, toUnitId: 7, ratio: 1.0 },
  { fromUnitId: 8, toUnitId: 8, ratio: 1.0 },
  { fromUnitId: 9, toUnitId: 9, ratio: 1.0 },
  { fromUnitId: 2, toUnitId: 4, ratio: 0.00026417 },
  { fromUnitId: 2, toUnitId: 5, ratio: 0.001 },
  { fromUnitId: 2, toUnitId: 9, ratio: 0.033814 },
  { fromUnitId: 4, toUnitId: 2, ratio: 3785.41 },
  { fromUnitId: 4, toUnitId: 5, ratio: 3.78541 },
  { fromUnitId: 4, toUnitId: 9, ratio: 128.0 },
  { fromUnitId: 5, toUnitId: 4, ratio: 0.264172 },
  { fromUnitId: 5, toUnitId: 2, ratio: 1000.0 },
  { fromUnitId: 5, toUnitId: 9, ratio: 33.814 },
  { fromUnitId: 9, toUnitId: 4, ratio: 0.0078125 },
  { fromUnitId: 9, toUnitId: 5, ratio: 0.0295735 },
  { fromUnitId: 9, toUnitId: 2, ratio: 29.5735 },
  { fromUnitId: 3, toUnitId: 6, ratio: 0.00220462 },
  { fromUnitId: 3, toUnitId: 7, ratio: 1000.0 },
  { fromUnitId: 3, toUnitId: 8, ratio: 0.035274 },
  { fromUnitId: 6, toUnitId: 3, ratio: 453.592 },
  { fromUnitId: 6, toUnitId: 7, ratio: 453592.0 },
  { fromUnitId: 6, toUnitId: 8, ratio: 16.0 },
  { fromUnitId: 7, toUnitId: 3, ratio: 0.001 },
  { fromUnitId: 7, toUnitId: 6, ratio: 0.0000022 },
  { fromUnitId: 7, toUnitId: 8, ratio: 0.00003527 },
  { fromUnitId: 8, toUnitId: 3, ratio: 28.3495 },
  { fromUnitId: 8, toUnitId: 6, ratio: 0.0625 },
  { fromUnitId: 8, toUnitId: 7, ratio: 28349.5 },
  { fromUnitId: 10, toUnitId: 3, ratio: 1000.0 },
  { fromUnitId: 10, toUnitId: 6, ratio: 2.204642 },
  { fromUnitId: 10, toUnitId: 7, ratio: 1000000.0 },
  { fromUnitId: 10, toUnitId: 8, ratio: 35.274 },
  { fromUnitId: 3, toUnitId: 10, ratio: 0.001 },
  { fromUnitId: 6, toUnitId: 10, ratio: 0.453592 },
  { fromUnitId: 7, toUnitId: 10, ratio: 0.000001 },
  { fromUnitId: 8, toUnitId: 10, ratio: 0.0283495 },
  { fromUnitId: 10, toUnitId: 10, ratio: 1.0 },
];

export const convertQuantity = (qty: number, fromUnitId: number | null, toUnitId: number | null | undefined) => {
  if (fromUnitId && toUnitId) {
    const x = unitConversionRatios.find((r) => r.fromUnitId === fromUnitId && r.toUnitId === toUnitId);
    if (x) {
      return x.ratio * qty;
    }
  }
  return NaN;
};

type FilterDeliveryRoutesArgs = {
  filters: DeliveryFilterOption[];
  routes: DeliveryRoute[];
  windowEnd: number | null;
  windowStart: number | null;
};

const deepFilterRoutes = (routes: DeliveryRoute[], filters: DeliveryFilterOption[]) => {
  return routes.filter((route) => {
    for (const filterOption of filters) {
      let value: string;
      switch (filterOption.key) {
        case 'CarModel':
          value = route.CarName?.split(' - ')[0] ?? '';
          break;
        case 'TransactionStatus':
          value = route.DeliveryStatus ?? '';
          break;
        default:
          return true;
      }
      if (!filterOption.options?.includes(value)) {
        return false;
      }
    }
    return true;
  });
};

const filterDeliveryRoutes = ({
  filters,
  routes,
  windowEnd,
  windowStart,
}: FilterDeliveryRoutesArgs): DeliveryRoute[] => {
  if (windowStart && windowEnd) {
    if (filters.length === 0) {
      filter(routes, (route) => {
        const routeDate = new Date(route.StartDate ?? route.EndDate).valueOf();
        return windowStart > routeDate || windowEnd < routeDate ? false : true;
      });
    } else {
      const deepfilteredRoutes = deepFilterRoutes(routes, filters);
      filter(deepfilteredRoutes, (route) => {
        const routeDate = new Date(route.StartDate ?? route.EndDate).valueOf();
        return windowStart > routeDate || windowEnd < routeDate ? false : true;
      });
    }
  }
  return deepFilterRoutes(routes, filters);
};

type FilterDeliveriesArgs = {
  filters: DeliveryFilterOption[];
  searchResults: Delivery[];
  windowEnd: number | null;
  windowStart: number | null;
};

const filterDeliveries = ({ filters, searchResults, windowEnd, windowStart }: FilterDeliveriesArgs): Delivery[] => {
  return filter(searchResults, (delivery) => {
    const deliveryDate = delivery.ArrivalDate ?? delivery.DepartureDate;

    if (windowStart && windowEnd && deliveryDate) {
      const orderStart = new Date(deliveryDate).valueOf();

      if (windowStart > orderStart || windowEnd < orderStart) {
        return false;
      }
    }

    return (
      every(filters, (filterCategory) => {
        return filterCategory.options?.includes(delivery[filterCategory.key]) || !filterCategory.options?.length;
      }) || filters.length === 0
    );
  });
};

export const searchAndFilterDeliveryList = (
  source: Delivery[],
  filters: DeliveryFilterOption[],
  keys: string[],
  searchQuery: string,
  routes: DeliveryRoute[],
  timeWindowForFilter?: TimeWindow
): SearchAndFilterReturn => {
  let searchDeliveryResults: Delivery[];
  let searchRoutesResults: DeliveryRoute[];

  if (searchQuery.length === 0) {
    searchDeliveryResults = source;
    searchRoutesResults = routes;
  } else {
    searchDeliveryResults = match(source, searchQuery, { keys, threshold: match.rankings.CONTAINS });
    searchRoutesResults = routes.filter((route) =>
      searchDeliveryResults.some((delivery) => delivery.DeliveryRouteId === route.DeliveryRouteId)
    );
  }

  const windowStart = timeWindowForFilter?.[0] ? timeWindowForFilter[0].valueOf() : null;
  const windowEnd = timeWindowForFilter?.[1] ? timeWindowForFilter[1].valueOf() : null;

  const filteredRoutes = filterDeliveryRoutes({ filters, routes: searchRoutesResults, windowEnd, windowStart });

  const filteredDeliveries = filterDeliveries({
    filters,
    searchResults: searchDeliveryResults,
    windowEnd,
    windowStart,
  });

  return { filteredRoutes, filteredDeliveries };
};

export const searchAndFilterProducts = (
  sourceProducts: Array<ProductSearchResult>,
  filters: Array<FilterOption<ProductSearchResult>>,
  keys: Array<string>,
  searchQuery: string
): Array<ProductSearchResult> => {
  let searchResults: Array<ProductSearchResult>;
  if (searchQuery.length === 0) {
    searchResults = sourceProducts;
  } else {
    searchResults = match(sourceProducts, searchQuery, { keys, threshold: match.rankings.CONTAINS });
  }

  return filter(searchResults, (product) => {
    return (
      every(filters, (filterCategory) => {
        if (filterCategory.key === 'inventoryTags' && filterCategory.options?.length) {
          return filterCategory.options?.some((option) => {
            return product.inventoryTags?.some((inventoryTag) => inventoryTag.tagName === option);
          });
        }

        return filterCategory.options?.includes(product[filterCategory.key]) || !filterCategory.options?.length;
      }) || filters.length === 0
    );
  });
};

export const searchAndFilter = <T>(
  source: Array<T>,
  filters: Array<FilterOption<T>>,
  keys: Array<string>,
  searchQuery: string
): Array<T> => {
  let searchResults;
  if (searchQuery.length === 0) {
    searchResults = source;
  } else {
    searchResults = match(source, searchQuery, { keys, threshold: match.rankings.CONTAINS });
  }

  return filter(searchResults, (product) => {
    return (
      every(filters, (filterCategory) => {
        return filterCategory.options?.includes(product[filterCategory.key]) || !filterCategory.options?.length;
      }) || filters.length === 0
    );
  });
};

export const isMMJLicenseAboutExpire = (guest: CustomerDetails) => {
  const ids = guest.identifications.filter((x) => {
    return x.type === 'MJStateIDNo';
  });

  const dl = ids.length > 0 ? ids[0] : null;
  if (dl && dl.ExpirationDate) {
    const date = format(new Date(dl.ExpirationDate), 'yyyy-MM-dd');
    const remainingDays = differenceInDays(new Date(date), new Date());
    return remainingDays <= 30 && remainingDays > 0;
  }

  return false;
};

export const mmjLicenseDaysFromExpiration = (guest: CustomerDetails) => {
  const ids = guest.identifications.filter((x) => {
    return x.type === 'MJStateIDNo';
  });

  const dl = ids.length > 0 ? ids[0] : null;
  if (dl) {
    const date = format(new Date(dl.ExpirationDate), 'yyyy-MM-dd');
    if (isAfter(new Date(date), new Date())) {
      const today = new Date();
      const expirationDate = new Date(date);
      return differenceInDays(expirationDate, today);
    } else {
      return 0;
    }
  }

  return 0;
};

export const isIdExpired = (guest: CheckedInGuest | CustomerDetails, type: string) => {
  const ids = guest.identifications.filter((x) => {
    return x.type === type;
  });

  const id = ids.length > 0 ? ids[0] : null;
  if (id && id.ExpirationDate) {
    const date = format(new Date(id.ExpirationDate), 'yyyy-MM-dd');
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    return isBefore(new Date(date), new Date(currentDate)) && !isSameDay(new Date(date), new Date(currentDate));
  }

  return false;
};

export const isDriversLicenseExpired = (guest: CheckedInGuest | CustomerDetails) => {
  return isIdExpired(guest, 'DriversLicenseId');
};

export const isMMJLicenseExpired = (guest: CheckedInGuest | CustomerDetails) => {
  return isIdExpired(guest, 'MJStateIDNo');
};

export const useShouldWarnItemNotInPreorder = () => {
  const isAddWarningWhenAddingItemToPreOrderEnabled = useSelector(
    (state: State) => state.settings.features.AddWarningWhenAddingItemToPreOrder
  );

  const {
    data: { Cart: cartItems, PreOrders: preOrders },
  } = useGetCartDetails();

  return useCallback(
    (itemProductId: number) => {
      return (
        isAddWarningWhenAddingItemToPreOrderEnabled &&
        preOrders?.length &&
        !isItemInCurrentPreOrderState(preOrders, cartItems, itemProductId)
      );
    },
    [isAddWarningWhenAddingItemToPreOrderEnabled, cartItems, preOrders]
  );
};

export const isItemInCurrentPreOrderState = (preorders: Preorder[], cartItems: CartItem[], itemProductId: number) => {
  const currentPreOrderStateArr = preorders
    .map((item) => ({
      ...item,
      QtySelected:
        item.QtySelected -
        cartItems
          .filter((cartItem) => cartItem.ProductId === item.ProductId)
          .reduce(
            (amount, cartItem) => amount + (cartItem.WgtCnt === 'Wgt' ? cartItem.Grams ?? 0 : cartItem.QtySelected),
            0
          ),
    }))
    .filter((item) => item.QtySelected > 0);

  if (currentPreOrderStateArr.length === 0) {
    return false;
  }

  return currentPreOrderStateArr.find((preorder) => preorder.ProductId === itemProductId);
};

export const isGuestIdSoonToBeExpired = (id: string | undefined) => {
  let isExpired = false;
  const idExpDate = id && new Date(id);
  const currentDate = new Date();

  idExpDate && differenceInDays(idExpDate, currentDate) < 45 ? (isExpired = true) : (isExpired = false);

  return isExpired;
};

export const getMinorAge = (settings: LocationSettings | undefined, isMedical: boolean) => {
  if (!settings) {
    return 18;
  }

  if (isMedical) {
    return settings.DefaultMinorAge || settings.DefaultRecMinorAge || 18;
  } else {
    return settings.DefaultRecMinorAge || settings.DefaultMinorAge || 18;
  }
};

export const customerSearchConfigCols = [
  { name: 'customer name', hidden: false, order: 0 },
  { name: 'last transaction', hidden: false, order: 1 },
  { name: 'user type', hidden: false, order: 2 },
  { name: 'phone number', hidden: false, order: 3 },
  { name: 'email address', hidden: false, order: 4 },
  { name: 'mj state id', hidden: false, order: 5 },
  { name: 'id expiration date', hidden: false, order: 6 },
  { name: 'dob', hidden: false, order: 7 },
];

//These are all of the "medical" customer types. Checkout CustomerTypes in the database for specifics.
export const medicalCustomerTypes = {
  1: 'medical',
  10: 'mmic',
  14: 'medicalTaxExempt',
  16: 'caregiver',
  17: 'minor',
  20: 'terminalPatient',
  21: 'medicalOutOfState',
  24: 'medicalUnderage',
  25: 'tribalMedicalMember',
};

export const thousandsFormatter = (num: number) => {
  // this is only meant to prevent really large orders from messing up the card styles
  return Math.abs(num) > 9999
    ? `${(Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1)}k`
    : (Math.sign(num) * Math.abs(num)).toFixed(0);
};

export const hasLength = (string: string | null | undefined) => {
  return string ? true : false;
};

export const isStringWithLength = (possiblyString: string | null | undefined) => {
  if (!possiblyString) {
    return false;
  }

  const noWhitespaceString = possiblyString.replace(/\s+/g, '');

  return noWhitespaceString.length > 0;
};

export const getFriendlyLongAgoString = (localDate: Date) => {
  const now = new Date().getTime();
  const diffInSeconds = (now - localDate.getTime()) / 1000;

  if (diffInSeconds < 60) {
    return '< 1 minute ago';
  }

  const diffInMinutes = diffInSeconds / 60;
  if (diffInMinutes < 60) {
    return `${Math.floor(diffInMinutes).toFixed(0)} minute(s) ago`;
  }

  const diffInHours = diffInMinutes / 60;
  if (diffInHours < 24) {
    return `${Math.floor(diffInHours).toFixed(0)} hour(s) ago`;
  }

  const diffInDays = diffInHours / 24;
  if (diffInDays < 7) {
    return `${Math.floor(diffInDays).toFixed(0)} day(s) ago`;
  }

  return '> 7 days ago';
};

export const getImageAsBase64String = async (url: string) => {
  try {
    const response = await Http.get({
      url,
      responseType: 'arraybuffer',
      webFetchExtra: {
        mode: 'cors',
      },
    });

    const bytes = response.data;

    return bytes;
  } catch (e) {
    return '';
  }
};

export const getLogoAsBase64String = async () => {
  try {
    const locationLogo = await getLocationLogo();
    let logoUrl = locationLogo[0].Logo;

    if (!logoUrl) {
      const lspLogo = await getLspLogo();
      logoUrl = lspLogo[0].Logo;
    }

    const logoString = await getImageAsBase64String(logoUrl);
    return logoString;
  } catch (e) {
    return '';
  }
};

export const cartHasRounding = (settings: SettingsState) => {
  return (
    settings.features.RoundDownToQuarter ||
    settings.features.RoundDownToDollar ||
    settings.features.RoundPercentDiscounts ||
    settings.features.RoundToNearestNickel ||
    settings.features.RoundToNearestDollar ||
    settings.features.RoundDownToNearestFiver ||
    settings.features.CurbsideRoundDownToNearestFiver
  );
};

export const cartHasRoundingNew = (features: Record<string, boolean>) => {
  return (
    features.RoundDownToQuarter ||
    features.RoundDownToDollar ||
    features.RoundPercentDiscounts ||
    features.RoundToNearestNickel ||
    features.RoundToNearestDollar ||
    features.RoundDownToNearestFiver ||
    features.CurbsideRoundDownToNearestFiver
  );
};

export const getPDFLink = (data: string, fileName: string) => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' }));
  link.download = fileName;
  return link;
};

export const phoneFormat = (input) => {
  if (!input || isNaN(input)) {
    return;
  }

  let inputAsStr = input;
  if (typeof inputAsStr !== 'string') {
    inputAsStr = input.toString();
  }
  if (inputAsStr.length === 10) {
    return inputAsStr.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
};

export const isTouchDevice = () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;

type FilterAndSortGuestsArgs = {
  guests: CheckedInGuest[];
  onlyShowGuestsAssignedToRegister?: boolean;
  statuses: GuestStatus[];
  selectedRegister: Register | undefined;
  sortOptions: GuestListSortOptions;
};

export const filterAndSortGuests = ({
  guests,
  onlyShowGuestsAssignedToRegister = false,
  statuses,
  selectedRegister,
  sortOptions,
}: FilterAndSortGuestsArgs) => {
  const orderByArgs = getOrderByArgs(sortOptions);

  return (
    chain(guests)
      // eslint-disable-next-line
      .filter((g) => (onlyShowGuestsAssignedToRegister ? Number(g.RegisterId) === selectedRegister?.value : true))
      .orderBy(orderByArgs.cb, orderByArgs.direction)
      .map((g) => ({
        ...g,
        Status: statuses.find((status) => status.POSStatus === g.TransactionStatus),
      }))
      .value()
  );
};

export const removeLeadingZeros = (str: string): string => str.replace(/^0+/, '');

enum DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

const getOrderByArgs = (sortOptions: GuestListSortOptions) => {
  const { isAlphaSort, timeReceivedSortAscending, timeExpectedSortAscending, alphaSortAscending } = sortOptions;
  if (isAlphaSort) {
    return {
      cb: (g: CheckedInGuest) => g.FullName.split(' ')[0].toLowerCase(),
      direction: alphaSortAscending ? DIRECTION.ASC : DIRECTION.DESC,
    };
  } else if (timeExpectedSortAscending) {
    return {
      cb: (g: CheckedInGuest) => getTimeExpectedSortValues(g),
      direction: DIRECTION.ASC,
    };
  }

  return {
    cb: (g: CheckedInGuest) => new Date(g.CheckInDateUTC).getTime(),
    direction: timeReceivedSortAscending ? DIRECTION.DESC : DIRECTION.ASC,
  };
};
