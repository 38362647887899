import React, { FC, ReactNode } from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { colors } from 'css/Theme';
import { Popup } from './Popup';
import { LoadingButton } from 'components/buttons';
import { NewConfirmationModalBridge } from './NewConfirmationModalBridge';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type ButtonProps = {
  text: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  styles?: FlattenSimpleInterpolation;
  id?: string;
};

type ConfirmationPopupProps = {
  isVisible: boolean;
  hide: () => void;
  className?: string;
  title: ReactNode;
  contentMaxHeight?: string;
  confirm?: ButtonProps;
  cancel?: ButtonProps;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  width?: string;
  buttonsSectionVisible?: boolean;
  portalContainer?: Element;
  automationId?: string;
  canClose?: boolean;
};

/**
 * @deprecated Use ConfirmationModal instead
 */
export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  isVisible,
  buttonsSectionVisible = false,
  hide,
  className,
  title,
  contentMaxHeight,
  children,
  confirm,
  cancel,
  canClose,
  ...rest
}) => {
  const { isModalBridgeEnabled } = useModalBridge();

  if (isModalBridgeEnabled) {
    return (
      <NewConfirmationModalBridge
        className={className}
        isVisible={isVisible}
        hide={hide}
        title={title}
        cancel={cancel}
        confirm={confirm}
        canClose={canClose}
        contentMaxHeight={contentMaxHeight}
        {...rest}
      >
        {children}
      </NewConfirmationModalBridge>
    );
  }

  return (
    <Popup
      caption={title}
      isVisible={isVisible}
      hide={hide}
      className={className}
      contentMaxHeight={contentMaxHeight}
      canClose={canClose}
      {...rest}
    >
      {children}
      {(confirm || cancel || buttonsSectionVisible) && (
        <PopupButtons reverse={confirm && !cancel} sticky={contentMaxHeight !== undefined}>
          {cancel && (
            <CancelButton
              type='button'
              data-testid='confirmation-popup_cancel-button_cancel'
              disabled={cancel.disabled}
              styles={cancel.styles}
              id={cancel.id}
              onClick={cancel.onClick}
            >
              {cancel.text || 'Cancel'}
            </CancelButton>
          )}
          {confirm && (
            <ConfirmButton
              automationId='confirmation-popup_confirm-button_confirm'
              disabled={confirm.disabled}
              loading={confirm.loading}
              styles={confirm.styles}
              id={confirm.id}
              onClick={confirm.onClick}
            >
              {confirm.text || 'Confirm'}
            </ConfirmButton>
          )}
        </PopupButtons>
      )}
    </Popup>
  );
};

const PopupButtons = styled.div<{ sticky?: boolean; reverse?: boolean }>`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: space-between;
  padding: 1rem 1.5rem;
  box-shadow: 0px -1px 0px ${colors.dutchie.shadowGrey};

  ${({ sticky }) =>
    sticky
      ? css`
          margin-top: 0;
          position: sticky;
          bottom: 0;
          background-color: white;
          border-radius: 0 0 20px 20px;
        `
      : null}
`;

const CancelButton = styled.button<{ styles?: FlattenSimpleInterpolation }>`
  font-size: 1rem;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: ${colors.white};
  border: none;
  cursor: pointer;
  ${({ styles }) => styles};
`;

const ConfirmButton = styled(LoadingButton)<{ styles?: FlattenSimpleInterpolation }>`
  ${({ styles }) => styles};
`;
