import styled from 'styled-components';
import { colors } from 'css/Theme';

export const H1 = styled.h1`
  font-size: 24px;
  line-height: 30px;
  color: ${colors.dutchie.almostBlack};
  text-transform: capitalize;
  font-weight: bold;
`;

export const H2 = styled(H1)`
  font-size: 20px;
  line-height: 24px;
`;

export const P1 = styled.p<{ lineHeight?: string }>`
  color: ${colors.dutchie.almostBlack};
  font-size: 16px;
  line-height: ${({ lineHeight }) => lineHeight || '32px'};
  letter-spacing: 0.005em;
`;

export const P2 = styled(P1)`
  color: ${colors.dutchie.grey};
`;

export const P3 = styled(P1)`
  line-height: 24px;
  color: ${colors.dutchie.mediumGrey};
`;
