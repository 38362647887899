import { useQuery } from '@tanstack/react-query';

import { post } from 'api/HttpHelpers';

import type { QueryKey } from '@tanstack/react-query';

const GET_SUBROOMS = 'Cultivation/LoadBenchesForLocationandRoom';

type SubroomRequestPayload = {
  filterByRoom?: boolean;
  roomId: number;
};

export const getSubroomsQueryKey: (roomId: number) => QueryKey = (roomId: number) => ['subrooms', roomId];

export type Subroom = {
  id: number;
  RoomNo: string;
  TrayNumber: string;
};

export function useSubroomsQuery({ filterByRoom, roomId }: SubroomRequestPayload, isEnabled: boolean = true) {
  return useQuery({
    queryKey: getSubroomsQueryKey(roomId),
    enabled: isEnabled,
    queryFn: () =>
        post<Subroom[]>(GET_SUBROOMS, { filterByRoom, roomId }),
  });
}
