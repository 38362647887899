import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { State } from 'store';
import { getDoctorList, getDocuments, getQualifyingConditions, updateAttestation } from 'store/actions/CustomerActions';
import {
  SectionDiv,
  SectionH3,
  StyledSelectField,
  SectionColumn,
  InputField,
  SectionHeaderSpan,
  IconButton,
  StyledTextAreaField,
} from 'pages/EditCustomerPage/style';
import { StateOptions } from 'components/inputs/defaults';
import { Label } from 'components/text';
import { CustomerDetails } from 'models/Customer';
import { ReactComponent as PlusIcon } from 'assets/icons/roundedPlus.svg';
import { FileUpload as _FileUpload } from 'pages/EditCustomerPage/components/FileUpload';
import { DocumentList } from 'pages/EditCustomerPage/components/DocumentList';
import { Button } from 'components/buttons';
import { Checkbox, CheckboxState, DatePickerField } from 'components/inputs';
import { times } from 'lodash';
import { PrescriptionPopup } from '../components/PrescriptionManager/PrescriptionPopup';
import { usePopup } from 'components/popups';
import { errorNotification } from 'store/actions/NotificationsActions';
import { ValidatedField } from 'models/Settings';
import { colors } from 'css/Theme';
import { FormikProps } from 'formik';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';

type MedicalTabProps = {
  customer?: CustomerDetails;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: any;
  fieldOptions?: ValidatedField;
  prescriptionFieldOptions?: ValidatedField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef: React.MutableRefObject<FormikProps<any> | null>;
};

export const MedicalTab: FC<MedicalTabProps> = ({
  customer,
  validationSchema,
  fieldOptions,
  prescriptionFieldOptions,
  formRef,
}) => {
  const dispatch = useDispatch();
  const doctors = useSelector((state: State) => state.customer.options.doctors);
  const doctorsLoading = useSelector((state: State) => state.customer.options.doctorsLoading);
  const documents = useSelector((state: State) => state.customer.documents);
  const customerDetails = useSelector((state: State) => state.customer.details);
  const isUseMnEnabled = useIsUseMnEnabled(customerDetails);

  const caregiverLabel = isUseMnEnabled ? 'Collector' : 'Caregiver';
  const features = useSelector((state: State) => state.settings.features);
  const qualifyingConditions = useSelector((state: State) => state.customer.qualifyingConditions);
  const { isVisible: prescriptionPopupVisible, toggle: togglePrescriptionPopup } = usePopup();

  const optionIsEnabled = (): CheckboxState => {
    return attestationCompleted ? 'on' : 'off';
  };

  const [attestationCompleted, setAttestation] = useState(customer?.Attestation === 'yes' ? true : false);
  const handleAttestationClick = () => {
    setAttestation(attestationCompleted ? false : true);
    dispatch(updateAttestation({ GuestId: customer?.Guest_id || 0, Attestation: attestationCompleted ? 'no' : 'yes' }));
  };

  const [numQualifyingConditions, setNumQualifyingConditions] = useState(
    customer?.SecondaryQualifyingConditionIds.length || 1
  );
  useEffect(() => {
    setNumQualifyingConditions(customer?.SecondaryQualifyingConditionIds.length || 1);
  }, [customer]);

  const activePrimaryQualifyingConditions = qualifyingConditions.filter((condition) => {
    return !condition.IsDeleted && condition.IsPrimary;
  });

  const activeSecondaryQualifyingConditions = qualifyingConditions.filter((condition) => {
    return !condition.IsDeleted && condition.IsSecondary;
  });

  const isFieldRequired = (fieldName: string) => {
    return (
      validationSchema &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validationSchema.fields[fieldName].tests.findIndex(({ name }: any) => name === 'required') >= 0
    );
  };

  useEffect(() => {
    dispatch(getDoctorList());
    dispatch(getQualifyingConditions());
  }, [dispatch]);

  useEffect(() => {
    if (customer) {
      dispatch(getDocuments({ PatientId: customer?.Guest_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const relationshipOptions = [
    { label: 'Parent/Legal Guardian', value: 'PLG' },
    { label: 'Caregiver', value: 'CAREGIVER' },
  ];

  const viewPrescriptions = () => {
    if (!Number(customer?.doctor)) {
      dispatch(
        errorNotification(
          'A doctor must be assigned and saved to the patient profile before you can enter prescription manager'
        )
      );
      return;
    } else {
      togglePrescriptionPopup();
    }
  };

  const handleClearCaregiverInfo = (caregiverId: number) => {
    const idString = caregiverId > 1 ? caregiverId.toString() : '';
    if (caregiverId === 1) {
      formRef.current?.setFieldValue(`CGFirstName`, '');
      formRef.current?.setFieldValue(`CGLastName`, '');
      formRef.current?.setFieldValue(`CaregiverAddress`, '');
      formRef.current?.setFieldValue(`CaregiverAddress2`, '');
      formRef.current?.setFieldValue(`CaregiverAddress3`, '');
      formRef.current?.setFieldValue(`CaregiverCity`, '');
      formRef.current?.setFieldValue(`CaregiverState`, '');
      formRef.current?.setFieldValue(`CaregiverPostalCode`, '');
    } else if (caregiverId > 1) {
      formRef.current?.setFieldValue(`CaregiverName${idString}`, '');
    }
    formRef.current?.setFieldValue(`CaregiverRelationship${idString}`, '');
    formRef.current?.setFieldValue(`CaregiverMJStateIdNo${idString}`, '');
    formRef.current?.setFieldValue(`CaregiverExpirationDate${idString}`, '');
    formRef.current?.setFieldValue(`CaregiverPhone${idString}`, '');
    formRef.current?.setFieldValue(`CaregiverEmail${idString}`, '');
    formRef.current?.setFieldValue(`CaregiverDOB${idString}`, '');
    formRef.current?.setFieldValue(`CaregiverNotes${idString}`, '');
  };

  return (
    <>
      <PrescriptionPopup
        isVisible={prescriptionPopupVisible}
        hide={togglePrescriptionPopup}
        guestId={customer?.Guest_id}
        fieldOptions={prescriptionFieldOptions}
      />
      <SectionH3>Doctor</SectionH3>
      <SectionDivCol>
        <StyledSelectField
          name='doctor'
          label="Doctor's Name"
          placeholder='Select'
          disabled={doctorsLoading || customer?.IsAnonymous}
          options={doctors.map((doc) => ({ label: doc.DoctorsName, value: String(doc.Id) }))}
          automationId='medical-tab_section-div_doctor-name'
        />

        {features.PrescriptionManager && !customer?.IsAnonymous && (
          <PrescriptionButton type='button' onClick={viewPrescriptions} secondary>
            Prescription Manager
          </PrescriptionButton>
        )}
      </SectionDivCol>

      {!customer?.IsAnonymous && fieldOptions && !fieldOptions['Qualifying Conditions']?.Hidden && (
        <>
          {' '}
          <SectionHeaderSpan>
            <SectionH3>Qualifying Conditions</SectionH3>
            <IconButton
              icon={PlusIcon}
              disabled={numQualifyingConditions >= activeSecondaryQualifyingConditions.length}
              onClick={() => setNumQualifyingConditions(numQualifyingConditions + 1)}
              automationId='medical-tab_icon-button_plus_qualifying-condition'
              secondary
            />
          </SectionHeaderSpan>
          <SectionDiv>
            {times(1, (index) => (
              <StyledSelectField
                key={index}
                name={`PrimaryQualifyingConditionId`}
                label={`Primary Condition${isFieldRequired('PrimaryQualifyingConditionId') ? '*' : ''}`}
                placeholder='Select'
                options={activePrimaryQualifyingConditions.map((condition) => ({
                  value: String(condition.QualifyingConditionId),
                  label: condition.Name,
                }))}
                disabled={customer?.IsAnonymous}
                automationId='medical-tab_select-field_primary-condition'
              />
            ))}
            {times(numQualifyingConditions, (index) => (
              <StyledSelectField
                key={index}
                name={`SecondaryQualifyingConditionIds[${index}]`}
                label='Secondary Conditions'
                placeholder='Select'
                options={activeSecondaryQualifyingConditions.map((condition) => ({
                  value: String(condition.QualifyingConditionId),
                  label: condition.Name,
                }))}
                disabled={customer?.IsAnonymous}
                automationId='medical-tab_select-field_secondary-condition'
              />
            ))}
          </SectionDiv>
        </>
      )}

      {features.Attestation && (
        <>
          <SectionH3>Attestation Form Completed</SectionH3>
          <SectionDiv>
            <AttestationContainer>
              <AttestationField>
                <FormDatePicker
                  name='AttestationSignedDate'
                  labelText='Attestation Date Signed'
                  placeholderText={'mm/dd/yyyy'}
                  disabled={!attestationCompleted}
                />
              </AttestationField>
              <AttestationField>
                <AttestationLabel>
                  Attestation Date Expires
                  <AttestationCheckbox state={optionIsEnabled()} onClick={handleAttestationClick} />
                  Attestation
                </AttestationLabel>
                <FormDatePicker
                  name='AttestationExpirationDate'
                  placeholderText='mm/dd/yyyy'
                  disabled={!attestationCompleted}
                />
              </AttestationField>
            </AttestationContainer>
          </SectionDiv>
        </>
      )}

      <SectionTitleButtonContainer>
        <SectionH3>{`Primary ${caregiverLabel}`}</SectionH3>
        <Button secondary onClick={() => handleClearCaregiverInfo(1)}>
          Clear Primary {caregiverLabel} Info
        </Button>
      </SectionTitleButtonContainer>
      <SectionDiv>
        <InputField
          name='CGFirstName'
          label='First Name'
          placeholder='...'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_input-field_first-name'
        />
        <InputField
          name='CGLastName'
          label='Last Name'
          placeholder='...'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_input-field_last-name'
        />
        <InputField
          name='CaregiverMJStateIdNo'
          label='MJ State ID'
          placeholder='...'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_input-field_mj-state-id'
        />
        <FormDatePicker
          name='CaregiverExpirationDate'
          labelText='MJ ID Expiration Date'
          placeholderText='mm/dd/yyyy'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_form-date-picker_caregiver-expiration-date'
        />
        {isUseMnEnabled && (
          <StyledSelectField
            name='CaregiverRelationship'
            label='Relationship'
            placeholder='Select'
            disabled
            options={relationshipOptions}
          />
        )}
        {fieldOptions && !fieldOptions['Address 1 (Caregiver)']?.Hidden && (
          <InputField
            name='CaregiverAddress'
            label={`Address 1${isFieldRequired('CaregiverAddress') ? ' *' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous || isUseMnEnabled}
            automationId='medical-tab_input-field_caregiver-address-1'
          />
        )}
        {fieldOptions && !fieldOptions['Address 2 (Caregiver)']?.Hidden && (
          <InputField
            name='CaregiverAddress2'
            label={`Address 2${isFieldRequired('CaregiverAddress2') ? ' *' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous || isUseMnEnabled}
            automationId='medical-tab_input-field_caregiver-address-2'
          />
        )}
        {fieldOptions && !fieldOptions['Address 3 (Caregiver)']?.Hidden && (
          <InputField
            name='CaregiverAddress3'
            label={`Address 3${isFieldRequired('CaregiverAddress3') ? ' *' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous || isUseMnEnabled}
            automationId='medical-tab_input-field_caregiver-address-3'
          />
        )}
        {fieldOptions && !fieldOptions['City (Caregiver)']?.Hidden && (
          <InputField
            name='CaregiverCity'
            label={`City${isFieldRequired('CaregiverCity') ? ' *' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous || isUseMnEnabled}
            automationId='medical-tab_input-field_caregiver-city'
          />
        )}
        {fieldOptions && !fieldOptions['State (Caregiver)']?.Hidden && (
          <StyledSelectField
            name='CaregiverState'
            label={`State${isFieldRequired('CaregiverState') ? ' *' : ''}`}
            placeholder='Select'
            options={StateOptions}
            disabled={customer?.IsAnonymous || isUseMnEnabled}
            automationId='medical-tab_input-field_caregiver-state'
          />
        )}
        {fieldOptions && !fieldOptions['Postal Code (Caregiver)']?.Hidden && (
          <InputField
            name='CaregiverPostalCode'
            label={`Postal Code${isFieldRequired('CaregiverPostalCode') ? ' *' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous || isUseMnEnabled}
            automationId='medical-tab_input-field_caregiver-postal-code'
          />
        )}
        <InputField
          name='CaregiverPhone'
          label='Phone Number'
          placeholder='...'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_input-field_caregiver-phone'
        />
        <InputField
          name='CaregiverEmail'
          label='Email'
          placeholder='...'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_input-field_caregiver-email'
        />
        <FormDatePicker
          name='CaregiverDOB'
          labelText='Date of Birth'
          placeholderText='mm/dd/yyyy'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_form-date-picker_caregiver-dob'
        />
        <InputField
          name='CaregiverNotes'
          label='Notes'
          placeholder='...'
          disabled={customer?.IsAnonymous || isUseMnEnabled}
          automationId='medical-tab_input-field_notes'
        />
      </SectionDiv>

      <SectionTitleButtonContainer>
        <SectionH3>{`Additional ${caregiverLabel} 1`}</SectionH3>
        <Button secondary onClick={() => handleClearCaregiverInfo(2)}>
          Clear {caregiverLabel} 1 Info
        </Button>
      </SectionTitleButtonContainer>
      <SectionDiv>
        <InputField name='CaregiverName2' label='Full Name' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField
          name='CaregiverMJStateIdNo2'
          label='MJ State ID'
          placeholder='...'
          disabled={customer?.IsAnonymous}
        />
        {isUseMnEnabled && (
          <StyledSelectField
            name='CaregiverRelationship2'
            label='Relationship'
            placeholder='Select'
            disabled={customer?.IsAnonymous}
            options={relationshipOptions}
          />
        )}
        <InputField name='CaregiverPhone2' label='Phone Number' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField name='CaregiverEmail2' label='Email' placeholder='...' disabled={customer?.IsAnonymous} />
        <FormDatePicker
          name='CaregiverDOB2'
          labelText='Date of Birth'
          placeholderText='mm/dd/yyyy'
          disabled={customer?.IsAnonymous}
        />
        <InputField name='CaregiverNotes2' label='Notes' placeholder='...' disabled={customer?.IsAnonymous} />
      </SectionDiv>

      <SectionTitleButtonContainer>
        <SectionH3>{`Additional ${caregiverLabel} 2`}</SectionH3>
        <Button secondary onClick={() => handleClearCaregiverInfo(3)}>
          Clear {caregiverLabel} 2 Info
        </Button>
      </SectionTitleButtonContainer>
      <SectionDiv>
        <InputField name='CaregiverName3' label='Full Name' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField
          name='CaregiverMJStateIdNo3'
          label='MJ State ID'
          placeholder='...'
          disabled={customer?.IsAnonymous}
        />
        {isUseMnEnabled && (
          <StyledSelectField
            name='CaregiverRelationship3'
            label='Relationship'
            placeholder='Select'
            disabled={customer?.IsAnonymous}
            options={relationshipOptions}
          />
        )}
        <InputField name='CaregiverPhone3' label='Phone Number' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField name='CaregiverEmail3' label='Email' placeholder='...' disabled={customer?.IsAnonymous} />
        <FormDatePicker
          name='CaregiverDOB3'
          labelText='Date of Birth'
          placeholderText='mm/dd/yyyy'
          disabled={customer?.IsAnonymous}
        />
        <InputField name='CaregiverNotes3' label='Notes' placeholder='...' disabled={customer?.IsAnonymous} />
      </SectionDiv>

      <SectionTitleButtonContainer>
        <SectionH3>{`Additional ${caregiverLabel} 3`}</SectionH3>
        <Button secondary onClick={() => handleClearCaregiverInfo(4)}>
          Clear {caregiverLabel} 3 Info
        </Button>
      </SectionTitleButtonContainer>
      <SectionDiv>
        <InputField name='CaregiverName4' label='Full Name' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField
          name='CaregiverMJStateIdNo4'
          label='MJ State ID'
          placeholder='...'
          disabled={customer?.IsAnonymous}
        />
        {isUseMnEnabled && (
          <StyledSelectField
            name='CaregiverRelationship4'
            label='Relationship'
            placeholder='Select'
            disabled={customer?.IsAnonymous}
            options={relationshipOptions}
          />
        )}
        <InputField name='CaregiverPhone4' label='Phone Number' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField name='CaregiverEmail4' label='Email' placeholder='...' disabled={customer?.IsAnonymous} />
        <FormDatePicker
          name='CaregiverDOB4'
          labelText='Date of Birth'
          placeholderText='mm/dd/yyyy'
          disabled={customer?.IsAnonymous}
        />
        <InputField name='CaregiverNotes4' label='Notes' placeholder='...' disabled={customer?.IsAnonymous} />
      </SectionDiv>

      <SectionTitleButtonContainer>
        <SectionH3>{`Additional ${caregiverLabel} 4`}</SectionH3>
        <Button secondary onClick={() => handleClearCaregiverInfo(5)}>
          Clear {caregiverLabel} 4 Info
        </Button>
      </SectionTitleButtonContainer>
      <SectionDiv>
        <InputField name='CaregiverName5' label='Full Name' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField
          name='CaregiverMJStateIdNo5'
          label='MJ State ID'
          placeholder='...'
          disabled={customer?.IsAnonymous}
        />
        {isUseMnEnabled && (
          <StyledSelectField
            name='CaregiverRelationship5'
            label='Relationship'
            placeholder='Select'
            disabled={customer?.IsAnonymous}
            options={relationshipOptions}
          />
        )}
        <InputField name='CaregiverPhone5' label='Phone Number' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField name='CaregiverEmail5' label='Email' placeholder='...' disabled={customer?.IsAnonymous} />
        <FormDatePicker
          name='CaregiverDOB5'
          labelText='Date of Birth'
          placeholderText='mm/dd/yyyy'
          disabled={customer?.IsAnonymous}
        />
        <InputField name='CaregiverNotes5' label='Notes' placeholder='...' disabled={customer?.IsAnonymous} />
      </SectionDiv>

      <SectionTitleButtonContainer>
        <SectionH3>{`Additional ${isUseMnEnabled ? 'Collector' : 'Caregiver'} 5`}</SectionH3>
        <Button secondary onClick={() => handleClearCaregiverInfo(6)}>
          Clear {caregiverLabel} 5 Info
        </Button>
      </SectionTitleButtonContainer>
      <SectionDiv>
        <InputField name='CaregiverName6' label='Full Name' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField
          name='CaregiverMJStateIdNo6'
          label='MJ State ID'
          placeholder='...'
          disabled={customer?.IsAnonymous}
        />
        {isUseMnEnabled && (
          <StyledSelectField
            name='CaregiverRelationship6'
            label='Relationship'
            placeholder='Select'
            disabled={customer?.IsAnonymous}
            options={relationshipOptions}
          />
        )}
        <InputField name='CaregiverPhone6' label='Phone Number' placeholder='...' disabled={customer?.IsAnonymous} />
        <InputField name='CaregiverEmail6' label='Email' placeholder='...' disabled={customer?.IsAnonymous} />
        <FormDatePicker
          name='CaregiverDOB6'
          labelText='Date of Birth'
          placeholderText='mm/dd/yyyy'
          disabled={customer?.IsAnonymous}
        />
        <InputField name='CaregiverNotes6' label='Notes' placeholder='...' disabled={customer?.IsAnonymous} />
      </SectionDiv>

      {customer && !customer.IsAnonymous && (
        <>
          <SectionH3>Documents</SectionH3>
          <SectionDiv>
            <FileUpload customer={customer} />
            <DocumentList customer={customer} documents={documents} />
          </SectionDiv>
        </>
      )}

      <SectionH3>Guardian</SectionH3>
      <SectionDiv>
        <SectionColumn>
          <InputField
            name='Guardian'
            label='Guardian Name'
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='medical-tab_input-field_guardian-name'
          />
          <StyledTextAreaField
            name='notes'
            label='Medical Notes'
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='medical-tab_input-field_medical-notes'
            rows={4}
          />
        </SectionColumn>
        <SectionColumn>
          <InputField
            name='CreditNote'
            label='Vouchers and Referrals'
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='medical-tab_input-field_referrals'
          />
          <StyledTextAreaField
            name='Conditions'
            label='Conditions'
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='medical-tab_input-field_conditions'
            rows={4}
          />
        </SectionColumn>
      </SectionDiv>
    </>
  );
};

const FileUpload = styled(_FileUpload)`
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const FormDatePicker = styled(DatePickerField)`
  max-width: 27rem;
  flex: 1 0 50%;
  margin: 0 1rem 1rem 0;
`;

const PrescriptionButton = styled(Button)`
  color: ${colors.dutchie.darkGrey};
  margin-right: auto;
  margin-bottom: 2rem;
`;

const AttestationContainer = styled.div`
  display: flex;
  flex: 1 0 100%;
`;

const AttestationField = styled.div`
  flex: 1 0 50%;
  max-width: 27rem;
  margin: 0 1rem 1rem 0;

  & > div {
    margin: 0;
  }
`;

const AttestationLabel = styled(Label)`
  display: flex;
`;

const AttestationCheckbox = styled(Checkbox)`
  margin-left: auto;
  margin-right: 1rem;
`;

export const SectionDivCol = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 56rem;
  flex-wrap: wrap;
`;

const SectionTitleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 55rem;
  @media screen and (max-width: 1215px) {
    max-width: 27rem;
  }
  & h3 {
    margin-bottom: 0;
  }
  & button {
    max-width: 250px;
  }
`;
