import { useEffect, useRef, useCallback } from 'react';
import { useCfd } from './useCfd';
import { useAppSelector, isAndroid, isWebViewApp, CFD_PATH, useAppDispatch } from 'util/hooks';
import { setCfdStatus } from 'store/actions/SettingsActions';
import { ExperimentalWindow, ScreenDetailed } from '../types';
import { logger, customEventKeys } from 'util/logger';
import { getScreenDetailedId } from '../utils';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { useNewSettingsUi } from 'util/hooks/launch-darkly/useNewSettingsUi';

export const useMonitoredCfdWindow = () => {
  const isNewSettingsUi = useNewSettingsUi();
  const expWindow = window as ExperimentalWindow;
  const dispatch = useAppDispatch();

  const { isOpenOnLogin, showCartDisplay } = useCfd();
  const selectedRegisterId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const openedWindow = useRef<Window | null>(null);
  const isCfdWindowOpen = openedWindow.current !== null;

  const cfdWindowCheckInterval = useRef<number | undefined>(undefined);

  const isCfdUrl = window.location.pathname.replace(/^\/+|\/+$/g, '') === CFD_PATH;

  const monitorWindow = useCallback(() => {
    if (openedWindow.current === null) {
      clearInterval(cfdWindowCheckInterval.current);
      return;
    }

    clearInterval(cfdWindowCheckInterval.current);
    cfdWindowCheckInterval.current = window.setInterval(() => {
      if (openedWindow.current?.closed === true) {
        dispatch(setCfdStatus(false));
        openedWindow.current = null;
      }
    }, 5000);
  }, [dispatch]);

  const buildCfdUrl = useCallback(
    (locId: string) => `${CFD_PATH}?Register=${selectedRegisterId}&LocId=${locId}`,
    [selectedRegisterId]
  );
  const openPopup = useCallback(
    ({
      locId,
      left = 200,
      top = 200,
      width = 1050,
      height = 700,
      resizeable = true,
      toolbar = false,
    }: {
      locId: string;
      left?: number;
      top?: number;
      width?: number;
      height?: number;
      resizeable?: boolean;
      toolbar?: boolean;
    }) => {
      const url = buildCfdUrl(locId);
      openedWindow.current = window.open(
        url,
        'popUpWindow',
        `height=${height},width=${width},left=${left},top=${top},resizable=${resizeable},scrollbars=yes,toolbar=${
          toolbar ? 'yes' : 'no'
        },menubar=no,location=no,directories=no,status=yes`
      );
      logger.debug('Opened CFD window', { key: customEventKeys.cfd.opened, url });
      if (openedWindow.current) {
        dispatch(setCfdStatus(true));
      } else {
        dispatch(warningNotification('Failed to open customer facing display. Popup blocked.'));
      }
      monitorWindow();
    },
    [dispatch, buildCfdUrl, monitorWindow]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openToScreen = useCallback(
    async (screen: ScreenDetailed): Promise<Window | null> => {
      try {
        const locId = window.sessionStorage.getItem('LocId');
        if (!locId) {
          throw Error('Location ID not available');
        }

        if (!expWindow.getScreenDetails) {
          return null;
        }

        openPopup({
          locId,
          left: screen.left,
          top: screen.top,
          width: screen.availWidth,
          height: screen.availHeight,
          toolbar: false,
        });
      } catch (e) {
        logger.error(e);
      }
      return null;
    },
    [expWindow.getScreenDetails, openPopup]
  );

  const openCartDisplay = useCallback(
    async (screenId?: string) => {
      try {
        if (!selectedRegisterId) {
          throw Error('Please select a register to display first');
        }

        const locId = window.sessionStorage.getItem('LocId');
        if (!locId) {
          throw Error('Location ID not available');
        }

        if (isAndroid) {
          // Android will auto-reload the CFD page and does not need to be opened directly.
          return null;
        }

        let openToScreenId = screenId;
        if (!openToScreenId) {
          // get preferred secondary screen id
          openToScreenId = localStorage.getItem('hardware.cfd.screenId') || undefined;
        }
        if (openToScreenId && openToScreenId !== 'primary-window' && expWindow.getScreenDetails) {
          try {
            const result = await expWindow.getScreenDetails();
            const screen = result.screens.find((it) => getScreenDetailedId(it) === openToScreenId);
            if (screen) {
              return openToScreen(screen);
            }
          } catch (e) {
            /* no-op */
          }
        }

        return openPopup({ locId });
      } catch (err) {
        if (err instanceof Error) {
          dispatch(errorNotification(err.message));
          dispatch(setCfdStatus(false));
        }
      }
      return null;
    },
    [dispatch, expWindow, openToScreen, openPopup, selectedRegisterId]
  );

  useEffect(() => {
    if (!showCartDisplay) {
      return;
    }

    const handleOpenCfdEvent = (e: Event) => {
      const { detail } = e as CustomEvent<{ screenId?: string }>;
      openCartDisplay(detail.screenId);
    };

    const handleCloseCfdEvent = (e: Event) => {
      openedWindow.current?.close();
      openedWindow.current = null;
    };

    window.addEventListener('openCartDisplay', handleOpenCfdEvent);
    window.addEventListener('closeCartDisplay', handleCloseCfdEvent);
    return () => {
      window.removeEventListener('openCartDisplay', handleOpenCfdEvent);
      window.removeEventListener('closeCartDisplay', handleCloseCfdEvent);
    };
  }, [openCartDisplay, showCartDisplay]);

  useEffect(() => {
    if (
      !isNewSettingsUi ||
      isCfdUrl ||
      isCfdWindowOpen ||
      isAndroid ||
      isWebViewApp ||
      !selectedRegisterId ||
      !showCartDisplay ||
      !isOpenOnLogin ||
      openedWindow.current
    ) {
      return;
    }

    openCartDisplay();
  }, [isNewSettingsUi, isCfdUrl, isCfdWindowOpen, isOpenOnLogin, openCartDisplay, selectedRegisterId, showCartDisplay]);

  // handle in hook
  return {};
};
