import React from 'react';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { BioTrackSettings } from './BioTrackSettings';


// Component access is gated by SettingsTabs
export const BiotrackSettingsTab = () => {
  return (
    <SettingsCard automationId='biotrack-settings-card' verticalPadding={true}>
      <BioTrackSettings />
    </SettingsCard>
  );
};
