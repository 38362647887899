import { filter, uniqBy } from 'lodash';

import { useAppSelector } from 'util/hooks/useAppDispatch';
import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';

import type { ProductSearchResult } from 'queries/v2/product/types';
import { Option } from 'models/Customer';

export const useProductDropDownOptions = () => {
  const customerId = useAppSelector((state) => state.customer.details?.Guest_id);
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const {
    data: products,
    isLoading: areProductsLoading,
    isError: wasErrorLoadingProducts,
  } = useGetAllProductsQuery({ customerId, registerId });

  const uniqueProducts = uniqBy(products, (product) => {
    return product.productDescription;
  });

  const productsOnly: ProductSearchResult[] = filter(
    uniqueProducts,
    (product) => !product.coupon || product.coupon.toLowerCase() !== 'yes'
  );

  const productOptions: Option[] = productsOnly.map((product) => ({
    value: product.productId.toString(),
    label: product.productDescription,
  }));

  return {
    productOptions,
    areProductsLoading,
    wasErrorLoadingProducts,
  };
};
