import React from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { LoadingButton } from 'components/buttons';
import { ModalContainer, ModalVariation, ModalCloseButton } from 'components/modals/Modal';
import { PopupOverlay } from 'components/popups';
import { TextArea } from 'components/inputs';
import { useGuestNotesModal } from './useGuestNotesModal';

export enum GuestNotesModalMode {
  Edit = 'edit',
  View = 'view',
}

export type GuestNotesModalProps = {
  guestId: number;
  mode?: GuestNotesModalMode;
  notes: string | undefined;
  hide: () => void;
};

export const GuestNotesModal = (props: GuestNotesModalProps) => {
  const { hide } = props;

  const {
    adjustHeightToContent,
    handleCancel,
    handleClickPrimaryAction,
    handleEditNotes,
    isDisabled,
    isEditing,
    isSavingNotes,
    primaryActionLabel,
    sanitizedNotes,
    textAreaRef,
  } = useGuestNotesModal(props);

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer variation={ModalVariation.Standard} data-testid='guest-notes-modal'>
        <ModalHeader>
          <Title>Guest notes</Title>
          <ModalCloseButton onClick={hide} data-testid='guest-notes-modal_close-button' />
        </ModalHeader>
        <ModalContent>
          {isEditing ? (
            <EditNotesField
              ref={textAreaRef}
              data-testid='guest-notes-modal_text-area'
              maxLength={2500}
              onKeyUp={adjustHeightToContent}
              onChange={handleEditNotes}
              value={sanitizedNotes}
            />
          ) : (
            <LockedNotes
              data-testid='guest-notes-modal_locked-notes'
              dangerouslySetInnerHTML={{ __html: sanitizedNotes === '' ? 'No notes available' : sanitizedNotes }}
            />
          )}
        </ModalContent>
        <ModalFooter>
          <LoadingButton cta tertiary onClick={handleCancel}>
            Cancel
          </LoadingButton>
          <LoadingButton cta onClick={handleClickPrimaryAction} loading={isSavingNotes} disabled={isDisabled}>
            {primaryActionLabel}
          </LoadingButton>
        </ModalFooter>
      </ModalContainer>
    </PopupOverlay>
  );
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 0 1.5rem;
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  flex: 1;
  padding: 2rem 0;
  margin-left: 3.75rem;
  text-align: center;
`;

const LockedNotes = styled.pre`
  width: 100%;
  max-height: 312px;
  padding: 1.5rem;
  border-radius: 0.375rem;
  font-family: inherit;
  color: ${colors.dutchie.almostBlack};
  background: ${colors.dutchie.grey10};
  font-size: 1rem;
  line-height: 1.5rem;
  overflow-y: auto;
  white-space: pre-line;
`;

const EditNotesField = styled(TextArea)`
  max-height: 312px;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 2rem;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;
