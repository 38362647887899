import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { Storage } from '@capacitor/storage';
import { isAndroid } from 'util/hooks';
import { RECEIPT_PRINTER_LOCAL_STORAGE_KEYS } from 'util/local-printing';
import { logger, customEventKeys } from 'util/logger';
import { UserChangedPrinterSettingsContext } from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { EscPrinterPickerHookData } from '../types';

export const useReceiptPrinterPicker = (): EscPrinterPickerHookData => {
  const dispatch = useDispatch();
  const isLocal = useSelector((state: State) => state.settings.userSettings.useConnectedReceiptPrinter);
  const isAutoPrintEnabled = useSelector((state: State) => state.settings.userSettings.autoPrintReceipts);

  const handleSwitchPrinterTypes = async (isToggleOn: boolean) => {
    // update Redux and remove selected printer to keep in sync with local toggle
    dispatch(saveUserSettings({ useConnectedReceiptPrinter: isToggleOn, selectedReceiptPrinter: undefined }));

    if (isAndroid) {
      Storage.set({
        key: RECEIPT_PRINTER_LOCAL_STORAGE_KEYS.IS_LOCAL,
        value: JSON.stringify(isToggleOn),
      });
    }

    logger.info<UserChangedPrinterSettingsContext>(`user toggled receipt printer to ${isToggleOn ? 'local' : 'network'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.RECEIPT,
      printerSettings: { isLocalPrinter: isToggleOn },
    });
  };

  const handleSwitchAutoPrint = (isToggleOn: boolean) => {
    dispatch(saveUserSettings({ autoPrintReceipts: isToggleOn }));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled auto printing for receipt printer ${isToggleOn ? 'ON' : 'OFF'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.RECEIPT,
      printerSettings: { isAutoPrintEnabled: isToggleOn },
    });
  };

  return {
    handleSwitchPrinterTypes,
    handleSwitchAutoPrint,
    isLocal,
    isAutoPrintEnabled,
  };
};
