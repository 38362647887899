import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { State } from 'store';

import { useGetLspLocations } from 'queries/posv3/maintenance/GetLspLocations';

import { generalBannerStyles, Text, SMALL_BANNER_HEIGHT } from './shared';

export function useNonSandboxBannerIsVisible() {
  const selectedLocation = useSelector((state: State) => state.user.selectedLocation);
  const userName = useSelector((state: State) => state.user.username);
  const permissions = useSelector((state: State) => state.settings.permissions);
  const isSuperUser = permissions?.SuperUser;
  const isInternalUser = userName.includes('@dutchie.com') && isSuperUser;
  const { data: locations } = useGetLspLocations({ enabled: isInternalUser });

  const currentLocation = locations?.find((location) => location.LocId === selectedLocation?.location_id);

  return !currentLocation?.IsSandbox && isInternalUser;
}

export function NonSandboxBanner() {
  const isVisible = useNonSandboxBannerIsVisible();

  if (!isVisible) {
    return null;
  }

  return (
    <Banner>
      <Text>
        <span>Please note you are in production viewing a non sandbox account. Do not make unauthorized changes.</span>
      </Text>
    </Banner>
  );
}

const Banner = styled.div`
  background-color: #ff3e51;
  height: ${SMALL_BANNER_HEIGHT}px;

  ${generalBannerStyles}
`;
