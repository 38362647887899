import { getMassDPHCreds, setMassDPHCreds, enableMassDPHOverride, disableMassDPHOverride } from 'api/SettingsApi';
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SectionGridContainer, SectionHeader, SectionContent } from './SettingPageComponents';
import { State } from 'store';
import { checkManagerPin } from 'util/Helpers';
import { colors } from 'css/Theme';
import { ReactComponent as AccountIcon } from 'assets/icons/icon-account.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/icon-password.svg';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { validateMassDPHEnteredCredentials, validateMassDPHSavedCredentials } from 'store/actions/SettingsActions';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';

const iconStyles = {
  color: colors.dutchie.grey,
};

const StyledUserIcon = styled(AccountIcon)`
  ${iconStyles}
`;

const StyledPasswordIcon = styled(PasswordIcon)`
  ${iconStyles}
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const OverrideButton = styled(Button)`
  border: none;
  background-color: ${colors.dutchie.primaryWhite};
  color: ${colors.dutchie.blue};
  font-weight: 500;
  font-size: 13px;
  margin-right: -0.5rem;
`;
/** @deprecated: Remove w/ 'pos.register.settings-ui.rollout' cleanup. In meantime, updates should also be made to version under Tabs */
export const MassDPHSettings: FC = () => {
  const { integrations } = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const [managerPin, setManagerPin] = useState<string>();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [massDPHOverrideEnabled, setMassDPHOverrideEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (integrations?.CanUseMassDPHIntegration) {
      getMassDPHCreds().then((resp) => {
        setUsername(resp?.Username || '');
        setPassword(resp?.Password || '');
        setMassDPHOverrideEnabled(resp?.ManagerOverrideEnabled || false);
        setIsEnabled(resp?.IsEnabled || false);
      });
    }
  }, [integrations]);

  const updateDPHCreds = async () => {
    await setMassDPHCreds({
      DPHUserId: parseInt(window.sessionStorage.getItem('UserId') || '0'),
      IsEnabled: isEnabled,
      Username: username,
      Password: password,
    });
    dispatch(successNotification('Credentials Updated'));
    dispatch(validateMassDPHSavedCredentials());
  };

  const validateCreds = async () => {
    dispatch(validateMassDPHEnteredCredentials({ Username: username, Password: password }));
  };

  const override = async () => {
    try {
      await checkManagerPin(managerPin);
      await enableMassDPHOverride();
      setMassDPHOverrideEnabled(true);
      dispatch(successNotification('Overide Enabled'));
    } catch {
      dispatch(errorNotification('Invalid Manager PIN'));
    }
  };

  const disableOverride = async () => {
    try {
      await checkManagerPin(managerPin);
      await disableMassDPHOverride();
      setMassDPHOverrideEnabled(false);
      dispatch(successNotification('Overide Disabled'));
    } catch {
      dispatch(errorNotification('Invalid Manager PIN'));
    }
  };

  return (
    <SectionGridContainer>
      <SectionHeader>Mass DPH</SectionHeader>
      <SectionContent>
        <InputContainer>
          <Input
            label='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            startAdornment={<StyledUserIcon />}
            placeholder='Username'
          />
        </InputContainer>
        <InputContainer>
          <Input
            label='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            startAdornment={<StyledPasswordIcon />}
            type='password'
            placeholder='Password'
          />
        </InputContainer>
        <Button tertiary onClick={updateDPHCreds}>
          Update
        </Button>
        <Button tertiary onClick={validateCreds} marginLeft={12}>
          Validate
        </Button>
      </SectionContent>
      <SectionHeader>Manager Pin</SectionHeader>
      <SectionContent>
        <InputContainer>
          <Input
            label='Override to use Master Creds'
            value={managerPin}
            onChange={(e) => setManagerPin(e.target.value)}
            type='password'
            placeholder='Manager PIN'
            endAdornment={
              <OverrideButton onClick={massDPHOverrideEnabled ? disableOverride : override}>
                {massDPHOverrideEnabled ? 'Disable Override' : 'Override'}
              </OverrideButton>
            }
          />
        </InputContainer>
      </SectionContent>
    </SectionGridContainer>
  );
};
