import React, { ChangeEvent } from 'react';
import { SxProps } from '@mui/material';

import { MenuItem } from '../../menu-item';
import { StyledSelectAdornment, StyledAdornmentWrapper, StyledOutlinedInput } from './select-adornment.styles';
import { SelectProps } from '../../select';

export type Option = {
  label: string;
  value: string;
  key?: string;
};

type SelectAdornmentProps = SelectProps & {
  label: string;
  options: Option[];
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disableNoneOption?: boolean;
  position?: 'end' | 'start';
  sx?: SxProps;
  className?: string;
  useDefaultSelectRenderValue?: boolean;
};

export const SelectAdornment = React.forwardRef<HTMLSelectElement, SelectAdornmentProps>((props, ref) => {
  const {
    position = 'end',
    disableNoneOption = false,
    options,
    value,
    onChange,
    label,
    sx,
    className,
    renderValue,
    useDefaultSelectRenderValue,
    ...other
  } = props;

  function defaultRenderValue(option) {
    if (option === '') {
      return label;
    }
    return option;
  }

  return (
    <StyledAdornmentWrapper position={position}>
      <StyledSelectAdornment
        {...other}
        className={className}
        displayEmpty
        forceRebrandStyles
        input={<StyledOutlinedInput />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        ref={ref}
        renderValue={useDefaultSelectRenderValue ? undefined : renderValue ?? defaultRenderValue}
        SelectDisplayProps={{ style: { paddingRight: '10px', paddingLeft: '0' } }}
        sx={sx}
        value={options.find((option) => option.value === value)?.value ?? ''}
        onChange={onChange}
      >
        {!disableNoneOption && (
          <MenuItem key='no-selection' value=''>
            None
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.key ?? option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelectAdornment>
    </StyledAdornmentWrapper>
  );
});
