import { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { loadSalesHistory } from 'store/actions/TransactionsActions';
import { format, addDays } from 'date-fns';
import { first } from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router';

export const useSalesHistory = () => {
  const registerById = (id: number) => {
    return first(
      settings.registers
        .filter((r) => r.id === id)
        .map((r) => ({
          value: r.id,
          label: r.TerminalName,
        }))
    );
  };

  const dispatch = useDispatch();
  const transactions = useSelector((state: State) => state.transactions);
  const search = useRef<string | undefined>();
  const includeCanceled = useSelector((state: State) => state.transactions.includeCanceledTransactions);
  const settings = useSelector((state: State) => state.settings);
  const history = useHistory();
  const registerId = Number(queryString.parse(history.location.search).registerId);
  const register = registerId ? registerById(registerId) : settings.selectedRegister;
  const setSearchText = (searchText: string) => {
    search.current = searchText;
  };

  const realoadTransactions = useCallback(() => {
    const fromDate = format(Date.now(), 'MM/dd/yyyy');
    const toDate = format(addDays(new Date(), 1), 'MM/dd/yyyy');
    dispatch(loadSalesHistory({ Search: search.current, includeCanceledTransactions: includeCanceled, registerId: register?.value || 0, fromDate, toDate }));
  }, [dispatch, includeCanceled, register]);

  return {
    realoadTransactions,
    setSearchText,
    transactions,
  };
};

