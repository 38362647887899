import React, { FC, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionPayment } from 'store/actions/TransactionsActions';
import { Transaction, TransactionPaymentRecord } from 'models/Transactions';
import { callback } from 'models/Misc';
import { State } from 'store';
import { ProductHistoryOrder } from 'models/Customer';
import { Loader } from 'components/backoffice/loader';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { ConfirmationPopup } from 'components/popups';
import { Input } from 'components/inputs';
import { ReactComponent as Dollar } from 'assets/icons/dollar-sign.svg';
import { Flex } from 'components/layout';
import { colors } from 'css/Theme';
import { ManualPaymentTypeSelect } from './ManualPaymentTypeSelect';
import { useChangePaymentTypeMutation } from 'queries/v2/payments/change-payment-type';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type CurrencyProps = {
  label?: string;
  value?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
};

const CurrencyInput = ({ onChange, ...props }: CurrencyProps) => (
  <Input
    type='number'
    defaultValue={0}
    decimalScale={2}
    startAdornment={<Dollar />}
    onValueChange={(values) => {
      if (onChange) {
        onChange(values?.floatValue ?? 0);
      }
    }}
    {...props}
  />
);

type ChangePaymentTypePopupProps = {
  selectedTransaction?: Transaction | ProductHistoryOrder;
  hide: callback;
};

export const ChangePaymentTypePopup: FC<ChangePaymentTypePopupProps> = ({ hide, selectedTransaction }) => {
  const dispatch = useDispatch();
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const CheckOutDebit = useSelector((state: State) => state.settings.features.CheckOutDebit);
  const CheckOutCreditCard = useSelector((state: State) => state.settings.features.CheckOutCreditCard);
  const CheckOutCheck = useSelector((state: State) => state.settings.features.CheckOutCheck);
  const CheckOutGiftCard = useSelector((state: State) => state.settings.features.CheckOutGiftCard);
  const isCheckoutManual = useSelector((state: State) => state.settings.features.EnableManualPayments);
  const { mutate: changePaymentType, isLoading: isChangePaymentTypeLoading } = useChangePaymentTypeMutation();

  const transactionPayment = useSelector((state: State) => state.transactions.paymentTypeDetails);

  const [newCashPaid, setCashPaid] = useState<number>();
  const [newCreditPaid, setCreditPaid] = useState<number>();
  const [newDebitPaid, setDebitPaid] = useState<number>();
  const [newCheckPaid, setCheckPaid] = useState<number>();
  const [newManualPaid, setManualPaid] = useState<number>();
  const [newManualPaidProcessorId, setManualPaidProcessorId] = useState<number>();
  const [newGiftPaid, setGiftPaid] = useState<number>();
  const [newTotalDueCustomer, setTotalDueCustomer] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initPayments: Record<string, number> = useMemo(
    () => ({
      cashPaid: transactionPayment?.CashPaid ?? 0,
      debitPaid: transactionPayment?.DebitPaid ?? 0,
      creditPaid: transactionPayment?.CreditPaid ?? 0,
      checkPaid: transactionPayment?.CheckPaid ?? 0,
      manualPaid: transactionPayment?.ManualPaid ?? 0,
      manualPaidProcessorId: transactionPayment?.ManualPaymentProcessorId ?? -1,
      giftPaid: transactionPayment?.GiftPaid ?? 0,
      totalDueCustomer: transactionPayment?.TotalDueCustomer ?? 0,
    }),
    [transactionPayment]
  );

  const newPayments: Record<string, number> = useMemo(
    () => ({
      cashPaid: newCashPaid ?? 0,
      debitPaid: newDebitPaid ?? 0,
      creditPaid: newCreditPaid ?? 0,
      checkPaid: newCheckPaid ?? 0,
      manualPaid: newManualPaid ?? 0,
      manualPaidProcessorId: newManualPaidProcessorId ?? -1,
      giftPaid: newGiftPaid ?? 0,
      totalDueCustomer: newTotalDueCustomer ?? 0,
    }),
    [
      newCashPaid,
      newCheckPaid,
      newCreditPaid,
      newDebitPaid,
      newGiftPaid,
      newManualPaid,
      newManualPaidProcessorId,
      newTotalDueCustomer,
    ]
  );

  const changesMade = useMemo(() => {
    return Object.keys(newPayments).some((type) => {
      return newPayments[type] !== initPayments[type];
    });
  }, [initPayments, newPayments]);

  useEffect(() => {
    if (selectedTransaction?.PosId) {
      const getData = async () => {
        setIsLoading(true);
        const response = await dispatch(getTransactionPayment(selectedTransaction.PosId));
        const transactionPaymentRes = response.payload as TransactionPaymentRecord;

        setCashPaid(transactionPaymentRes?.CashPaid);
        setCreditPaid(transactionPaymentRes?.CreditPaid);
        setDebitPaid(transactionPaymentRes?.DebitPaid);
        setCheckPaid(transactionPaymentRes?.CheckPaid);
        setManualPaid(transactionPaymentRes?.ManualPaid);
        setManualPaidProcessorId(transactionPaymentRes?.ManualPaymentProcessorId);
        setGiftPaid(transactionPaymentRes?.GiftPaid);
        setTotalDueCustomer(transactionPaymentRes?.TotalDueCustomer);
        setIsLoading(false);
      };
      getData();
    }

    return () => {
      setCashPaid(undefined);
      setCreditPaid(undefined);
      setDebitPaid(undefined);
      setCheckPaid(undefined);
      setManualPaid(undefined);
      setManualPaidProcessorId(undefined);
      setGiftPaid(undefined);
      setTotalDueCustomer(undefined);
    };
  }, [selectedTransaction, dispatch]);

  const handleSubmit = () => {
    changePaymentType(
      {
        NewCashPaid: newCashPaid ?? 0,
        NewCheckPaid: newCheckPaid ?? 0,
        NewCreditPaid: newCreditPaid ?? 0,
        NewDebitPaid: newDebitPaid ?? 0,
        NewGiftPaid: newGiftPaid ?? 0,
        NewManualPaid: newManualPaid ?? 0,
        NewManualPaymentProcessorId:
          !newManualPaidProcessorId || newManualPaidProcessorId < 0 ? undefined : newManualPaidProcessorId,
        NewTotalDueCustomer: newTotalDueCustomer ?? 0,
        Register: register?.value,
        ShipmentId: selectedTransaction?.PosId,
      },
      {
        onSettled: (_data, error) => {
          if (error) {
            dispatch(errorNotification('An error occurred while changing payments'));
          }

          if (!error) {
            dispatch(successNotification('Payment type has been successfully changed'));
          }

          hide();
        },
      }
    );
  };

  const validatePayments = () => {
    const initPayment = Number(
      (
        initPayments.cashPaid +
        initPayments.debitPaid +
        initPayments.creditPaid +
        initPayments.manualPaid +
        initPayments.checkPaid +
        initPayments.giftPaid -
        initPayments.totalDueCustomer
      ).toFixed(2)
    );

    const newPayment = Number(
      (
        newPayments.cashPaid +
        newPayments.debitPaid +
        newPayments.creditPaid +
        newPayments.checkPaid +
        newPayments.manualPaid +
        newPayments.giftPaid -
        newPayments.totalDueCustomer
      ).toFixed(2)
    );

    if (initPayment !== newPayment) {
      const paymentDiff = newPayment - initPayment - newPayments.totalDueCustomer;
      const paymentDiffText = paymentDiff >= 0 ? 'over' : 'short';
      dispatch(
        errorNotification(`Payment totals do not match, ${paymentDiffText} by ${Math.abs(paymentDiff).toFixed(2)}`)
      );
    } else if (newPayments.manualPaid !== 0 && newPayments.manualPaidProcessorId === -1) {
      dispatch(errorNotification('Please select a manual payment type'));
    } else {
      handleSubmit();
    }
  };

  const handleManualPaymentTypeChange = (value: string) => {
    setManualPaidProcessorId(Number(value));
  };

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <ConfirmationPopup
      medium
      contentMaxHeight={isModalBridgeEnabled ? '100%' : 'calc(100vh - 200px)'}
      isVisible
      hide={() => hide()}
      title={'Change Payment Type'}
      confirm={{
        disabled: !changesMade,
        loading: isChangePaymentTypeLoading,
        text: 'Save',
        onClick: () => {
          validatePayments();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      {isLoading ? (
        <Container justifyContent='center' alignItems='center'>
          <PaymentTypeLoadingWrapperDiv>
            <Loader variant='black' size='2x' />
          </PaymentTypeLoadingWrapperDiv>
        </Container>
      ) : (
        <Container justifyContent='center'>
          <ColumnWrapper>
            <Heading>Current Payments</Heading>
            <PaymentsContainer>
              <CurrencyInput
                label='Cash Paid'
                value={transactionPayment?.CashPaid}
                disabled={true}
                data-testid='change-payment-type-popup_input_current-cash-paid'
              />
              {CheckOutDebit && (
                <CurrencyInput
                  label='Debit Paid'
                  value={transactionPayment?.DebitPaid}
                  disabled={true}
                  data-testid='change-payment-type-popup_input_current-debit-paid'
                />
              )}
              {CheckOutCreditCard && (
                <CurrencyInput
                  label='Credit Paid'
                  value={transactionPayment?.CreditPaid}
                  disabled={true}
                  data-testid='change-payment-type-popup_input_current-credit-paid'
                />
              )}
              {CheckOutCheck && (
                <CurrencyInput
                  label='Check Paid'
                  value={transactionPayment?.CheckPaid}
                  disabled={true}
                  data-testid='change-payment-type-popup_input_current-check-out-check'
                />
              )}
              {isCheckoutManual && (
                <>
                  <CurrencyInput
                    label='Manual Paid'
                    value={transactionPayment?.ManualPaid}
                    disabled={true}
                    data-testid='change-payment-type-popup_input_current-check-out-manual'
                  />
                  <ManualPaymentTypeSelect
                    disabled={true}
                    placeholder='Option'
                    value={transactionPayment?.ManualPaymentProcessorId}
                    automationId='change-payment-type-popup_input_current-check-out-manual-type-select'
                  />
                </>
              )}
              {CheckOutGiftCard && (
                <CurrencyInput
                  label='Gift Card Paid'
                  value={transactionPayment?.GiftPaid}
                  disabled={true}
                  data-testid='change-payment-type-popup_input_current-check-out-gift'
                />
              )}
              {!!transactionPayment?.MMAPPaid && (
                <CurrencyInput
                  label='MMAP Paid'
                  value={transactionPayment?.MMAPPaid}
                  disabled={true}
                  data-testid='change-payment-type-popup_input_current-check-out-mmap'
                />
              )}
              <CurrencyInput
                label='Change Paid'
                value={transactionPayment?.TotalDueCustomer}
                disabled={true}
                data-testid='change-payment-type-popup_input_current-change'
              />
            </PaymentsContainer>
          </ColumnWrapper>
          <ColumnWrapper>
            <Heading>New Payments Type</Heading>
            <PaymentsContainer>
              <CurrencyInput
                label='Cash Paid'
                value={newCashPaid}
                onChange={setCashPaid}
                data-testid='change-payment-type-popup_input_new-cash-paid'
              />
              {CheckOutDebit && (
                <CurrencyInput
                  label='Debit Paid'
                  value={newDebitPaid}
                  onChange={setDebitPaid}
                  data-testid='change-payment-type-popup_input_new-debit-paid'
                />
              )}
              {CheckOutCreditCard && (
                <CurrencyInput
                  label='Credit Paid'
                  value={newCreditPaid}
                  onChange={setCreditPaid}
                  data-testid='change-payment-type-popup_input_new-credit-paid'
                />
              )}
              {CheckOutCheck && (
                <CurrencyInput
                  label='Check Paid'
                  value={newCheckPaid}
                  onChange={setCheckPaid}
                  data-testid='change-payment-type-popup_input_new-check-out-check'
                />
              )}
              {isCheckoutManual && (
                <>
                  <CurrencyInput
                    label='Manual Paid'
                    value={newManualPaid}
                    onChange={setManualPaid}
                    data-testid='change-payment-type-popup_input_new-check-out-manual'
                  />
                  <ManualPaymentTypeSelect
                    value={newManualPaidProcessorId}
                    onChange={handleManualPaymentTypeChange}
                    automationId='change-payment-type-popup_input_new-check-out-manual-type-select'
                  />
                </>
              )}
              {CheckOutGiftCard && (
                <CurrencyInput
                  label='Gift Card Paid'
                  value={newGiftPaid}
                  onChange={setGiftPaid}
                  data-testid='change-payment-type-popup_input_new-check-out-gift'
                />
              )}
              {!!transactionPayment?.MMAPPaid && (
                <CurrencyInput
                  label='MMAP Paid'
                  value={transactionPayment?.MMAPPaid}
                  disabled={true}
                  data-testid='change-payment-type-popup_input_new-check-out-mmap'
                />
              )}
              <CurrencyInput
                label='Change Paid'
                value={newTotalDueCustomer}
                onChange={setTotalDueCustomer}
                data-testid='change-payment-type-popup_input_new-change'
              />
            </PaymentsContainer>
          </ColumnWrapper>
        </Container>
      )}
    </ConfirmationPopup>
  );
};

const Container = styled(Flex)`
  min-height: 300px;
  padding: 0 1.5rem 1.5rem;
  gap: 1.5rem;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Heading = styled.h3`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: 0 0 1rem;
`;

const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
`;

const PaymentTypeLoadingWrapperDiv = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: center;
`;
