import { PaymentsHubSplitPaymentMethod, PaymentMethod, PaymentType } from 'models/Checkout';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { usePaymentsHubSplitPayments } from 'util/hooks/launch-darkly/usePaymentsHubSplitPayments';

export const usePaymentsHubCreatePaymentMethod = () => {
  const existingPayments = useSelector((state: State) => state.checkout.payment?.methods);
  const hubSplitPaymentsRollout = usePaymentsHubSplitPayments();

  return useCallback(
    (amount: number, paymentMethodId: string): PaymentMethod => {
      const splitPaymentMethods: PaymentsHubSplitPaymentMethod[] =
        hubSplitPaymentsRollout && existingPayments
          ? existingPayments.map((method) => ({
              name: method.name,
              amount_cents: method.amount * 100,
            }))
          : [];

      return {
        id: paymentMethodId,
        name: 'paymentshub',
        type: PaymentType.Hub,
        amount,
        finalized: true,
        data: splitPaymentMethods,
      };
    },
    [existingPayments, hubSplitPaymentsRollout]
  );
};
