import React, { PropsWithChildren, createContext } from 'react';
import { FulfillmentReceiptPrinterSettingsContextData } from '../types';
import { useFulfillmentReceiptPrinterSettingsProvider } from '../hooks/useFulfillmentReceiptPrinterSettingsProvider';

export const FulfillmentReceiptPrinterSettingsContext = createContext<FulfillmentReceiptPrinterSettingsContextData>(
  {} as FulfillmentReceiptPrinterSettingsContextData
);

export const FulfillmentReceiptPrinterSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useFulfillmentReceiptPrinterSettingsProvider();
  return (
    <FulfillmentReceiptPrinterSettingsContext.Provider value={data}>
      {children}
    </FulfillmentReceiptPrinterSettingsContext.Provider>
  );
};
