import React, { FC } from 'react';
import styled from 'styled-components';

export const FylloWarning: FC = () => {
  return (
    <InputContainer>
      <div>Please enter a phone number for this customer on their profile</div>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 2rem;
`;
