import React, { PropsWithChildren, createContext } from 'react';

import { ScannerSettingsContextData } from '../types';
import { useScannerSettingsProvider } from '../hooks/useScannerSettingsProvider';

export const ScannerSettingsContext = createContext<ScannerSettingsContextData>({} as ScannerSettingsContextData);

export const ScannerSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useScannerSettingsProvider();

  return <ScannerSettingsContext.Provider value={data}>{children}</ScannerSettingsContext.Provider>;
};
