import { getCartDetailsKeys } from 'queries/v2/cart/query-key-factory';
import { getQueryClient } from 'util/reactQuery';
import { loadCart } from 'store/actions/CartActions';
import { getIsAnonymousCartLDFlagEnabled } from './useAnonymousCart';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useTransactionManager } from './useTransactionManager';

import type { GetCartDetailsPayload } from 'queries/v2/cart/load';
import type { AppDispatch } from 'store';

/** Helper to use inside a Redux action */
export const refetchCartDetails = async (dispatch: AppDispatch, payload: GetCartDetailsPayload) => {
  const { isAnonymousCartLDFlagEnabled } = getIsAnonymousCartLDFlagEnabled();
  const queryClient = getQueryClient();

  if (!isAnonymousCartLDFlagEnabled) {
    await dispatch(
      loadCart({
        AcctId: payload.guestId,
        FeePaymentMethodIds: payload.feePaymentMethodIds,
        Register: payload.registerId,
        ShipmentId: payload.shipmentId,
        Timestamp: payload.timestamp ?? +new Date(),
      })
    );
    return;
  }

  if (payload.guestId && payload.shipmentId) {
    queryClient.invalidateQueries(
      getCartDetailsKeys.one({
        guestId: payload.guestId,
        shipmentId: payload.shipmentId,
      })
    );
  } else {
    // If we don't have a guestId or shipmentId, invalidate all cart details queries
    queryClient.invalidateQueries(getCartDetailsKeys.all);
  }
};

/**
* @deprecated
* Use this hook to force a refetch of the cart details.
 * You only need this hook if you are not accessing either the cart data or loading state.
 * If you are, use useGetCartDetails instead.
 * Works with both Redux and React Query.
 */
export const useRefetchCartDetails = () => {
  const dispatch = useAppDispatch();

  const { guestId, shipmentId } = useTransactionManager();
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  return async (payload?: Partial<GetCartDetailsPayload>) => {
    // Always use the payload if provided and fallback to the values from transaction manager
    await refetchCartDetails(dispatch, {
      ...payload,
      guestId: payload?.guestId ?? guestId ?? 0,
      registerId: payload?.registerId ?? registerId,
      shipmentId: payload?.shipmentId ?? shipmentId ?? 0,
    });
  };
};
