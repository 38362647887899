import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useSelector } from 'react-redux';
import { State } from 'store';

type UseDutchiePayPreAuthReturn = {
  dutchiePayPreAuthError: boolean;
  isDutchiePayPreAuth: boolean;
  showDutchiePayPreAuthCheckout: boolean;
};

// say it ain't so :(
export const DUTCHIE_PAY_PRE_AUTH_PAYMENT_TYPE = 'dutchiepay';

export function useDutchiePayPreAuth(): UseDutchiePayPreAuthReturn {
  const dutchiePayPreAuthError = useSelector((state: State) => state.cart.dutchiePayPreAuthError);

  const {
    data: { PreauthInfo: preAuthInfo },
  } = useGetCartDetails();

  // not totally clear what isDutchiePayError is-- tried to see if it was in-store only and we could separate completely from the pre-auth failure
  // state but was not able to clearly determine whether it is only used for in-store or used for things involving pre-auth, too
  const isDutchiePayError = useSelector((state: State) => state.cart.isDutchiePayError);

  const isDutchiePayPreAuth =
    preAuthInfo.PreauthAmount > 0 && preAuthInfo.PaymentType === DUTCHIE_PAY_PRE_AUTH_PAYMENT_TYPE;
  const showDutchiePayPreAuthCheckout = isDutchiePayPreAuth && !isDutchiePayError && !dutchiePayPreAuthError;

  return {
    dutchiePayPreAuthError,
    isDutchiePayPreAuth,
    showDutchiePayPreAuthCheckout,
  };
}
