import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { v4 as uuid } from 'uuid';

import type { State } from 'store';

import { PaymentType } from 'models/Checkout';
import { Cart, CartItem, PrepaymentPayInfo } from 'models/Cart';
import { addPaymentMethod, startCheckout } from 'store/actions/CheckoutActions';
import { warningNotification } from 'store/actions/NotificationsActions';

import { useCartPopups } from 'components/CartPopups';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { getQty, getReducedPreorders } from 'pages/CartPage/CartSummary/CartItems/useCartItems';
import { useUpdateCartTotals } from 'pages/CartPage/hooks/useUpdateCartTotals';
import { useShouldBypassDOBCheck } from 'util/hooks/useShouldBypassDOBCheck';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useFixedCartTotalsLoadingState } from 'util/hooks/launch-darkly/useFixedCartTotalsLoadingState';

const useSelectPaymentType = () => {
  // Global state
  const isStatefulCheckoutEnabled = useSelector((state: State) => state.settings.features.StatefulCheckout);
  const isEducationalMaterialsAcceptRejectEnabled = useSelector(
    (state: State) => state.settings.features.EducationalMaterialsAcceptReject
  );
  const isEducationalMaterialsAcceptOnlyEnabled = useSelector(
    (state: State) => state.settings.features.EducationalMaterialsAcceptOnly
  );

  // Hooks
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const cartPopups = useCartPopups();

  const { data: cart } = useGetCartDetails();
  const { mutate: updateCartTotals, mutateAsync: updateCartTotalsAsync, isLoading: isUpdateCartTotalsLoading } = useUpdateCartTotals();

  // LD flags
  const { isFixedCartTotalsLoadingStateEnabled } = useFixedCartTotalsLoadingState();
  const isSkipPrepaymentsEnabled = ldClient?.variation('fintech.instore.rollout.no-prepay-running-totals', false);

  // Computed values
  const isCartFulfilled = useMemo(() => {
    const reducedPreorders = getReducedPreorders(cart.PreOrders);

    for (const preorder of reducedPreorders) {
      const cartItemForPreorderItem = cart.Cart.filter((x: CartItem) => x.ProductId === preorder.ProductId);
      const qtyAlreadyInCart = cartItemForPreorderItem.reduce((prev: number, cur: CartItem) => prev + getQty(cur), 0);

      if (qtyAlreadyInCart < preorder.QtySelected) {
        return false;
      }
    }

    return true;
  }, [cart.Cart, cart.PreOrders]);

  const handleStartCheckout = ({
    GrandTotalRounded,
    PreOrderRedemptions,
    PrepaymentPayInfo,
  }: Pick<Cart, 'GrandTotalRounded' | 'PreOrderRedemptions' | 'PrepaymentPayInfo'>) => {
    if (!isEducationalMaterialsAcceptRejectEnabled && !isEducationalMaterialsAcceptOnlyEnabled) {
      dispatch(startCheckout(GrandTotalRounded));
    } else {
      cartPopups.showEducationalMaterialPopup();
    }

    const hasUnredeemedRewards = PreOrderRedemptions.filter((reward) => !reward.IsRedeemed).length > 0;
    if (hasUnredeemedRewards) {
      dispatch(warningNotification('There are rewards in the cart that have not been redeemed.'));
    }

    if (!isSkipPrepaymentsEnabled) {
      if (PrepaymentPayInfo && PrepaymentPayInfo.length > 0) {
        let amount = 0;
        let type = '';
        PrepaymentPayInfo.forEach((item) => {
          amount += item.Amount;
          type = item.PrepaymentType;
        });
        dispatch(addPaymentMethod({ id: uuid(), name: type, type: PaymentType.Prepayment, amount }));
      }
    }
  };

  const handleAsyncSelectPaymentType = async () => {
    if (!isCartFulfilled) {
      dispatch(warningNotification('Some items within this pre-order have not yet been fulfilled'));
    }

    // If Cart totals on checkout is disabled, just pass the existing cart to the startCheckout action
    if (!isStatefulCheckoutEnabled) {
      handleStartCheckout(cart);
      return;
    }

    // If Cart totals on checkout is enabled, update the cart totals before starting the checkout
    const cartTotals = await updateCartTotalsAsync({ isCheckout: true });
    handleStartCheckout({ ...cart, ...cartTotals });
  };

  // Handlers
  const handleSelectPaymentType = () => {
    if (!isCartFulfilled) {
      dispatch(warningNotification('Some items within this pre-order have not yet been fulfilled'));
    }

    if (isStatefulCheckoutEnabled) {
      updateCartTotals({ isCheckout: true });
    }

    if (!isEducationalMaterialsAcceptRejectEnabled && !isEducationalMaterialsAcceptOnlyEnabled) {
      dispatch(startCheckout(cart.GrandTotalRounded));
    } else {
      cartPopups.showEducationalMaterialPopup();
    }

    const unredeemedRewards = cart.PreOrderRedemptions.filter((reward) => !reward.IsRedeemed);
    if (unredeemedRewards.length > 0) {
      dispatch(warningNotification('There are rewards in the cart that have not been redeemed.'));
    }

    if (!isSkipPrepaymentsEnabled) {
      if (cart.PrepaymentPayInfo && cart.PrepaymentPayInfo.length > 0) {
        let amount = 0;
        let type = '';
        cart.PrepaymentPayInfo.forEach((item: PrepaymentPayInfo) => {
          amount += item.Amount;
          type = item.PrepaymentType;
        });
        dispatch(addPaymentMethod({ id: uuid(), name: type, type: PaymentType.Prepayment, amount }));
      }
    }
  };

  return {
    handleSelectPaymentType: isFixedCartTotalsLoadingStateEnabled
      ? handleAsyncSelectPaymentType
      : handleSelectPaymentType,
    isButtonLoading: isUpdateCartTotalsLoading,
  };
};

type UseVerifyBirthdateButtonParams = {
  setIsBirthdateConfirmed: Dispatch<SetStateAction<boolean>>;
};

const useVerifyBirthdateButton = ({ setIsBirthdateConfirmed }: UseVerifyBirthdateButtonParams) => {
  // Global state
  const guest = useSelector((state: State) => state.customer.details);

  // Hooks
  const cartPopups = useCartPopups();
  const { guestId, shipmentId } = useTransactionManager();

  // Handlers
  const handleClickVerifyBirthdate = () => {
    if (guest && guestId && shipmentId) {
      cartPopups.showVerifyBirthdatePopup(
        guest.DOB,
        guest.IsAnonymous,
        () => setIsBirthdateConfirmed(true),
        shipmentId,
        guestId
      );
    }
  };

  return {
    label: 'Verify birthdate',
    handleClick: handleClickVerifyBirthdate,
  };
};

const useCheckoutButton = () => {
  // Hooks
  const { handleSelectPaymentType, isButtonLoading } = useSelectPaymentType();

  return {
    label: 'Checkout',
    handleClick: handleSelectPaymentType,
    isButtonLoading,
  };
};

type UseCartActionButtonParams = {
  handleClick: (() => Promise<void>) | (() => void);
  isLoading: boolean;
  label: string;
  shipmentId: number | undefined;
};

export const useCartActionButton = (): UseCartActionButtonParams => {
  // Common props
  const { shipmentId } = useTransactionManager();
  const { isLoading: isCartLoading } = useGetCartDetails();

  const isCartLoadingDeprecated = useSelector((state: State) => state.cart.loading);
  const areCartItemsLoading = useSelector((state: State) => state.cartItems.loading);
  const isAnyItemLoading = Object.values(areCartItemsLoading).some(Boolean);
  const isLoadingDeprecated = isCartLoadingDeprecated || isAnyItemLoading;
  const isLoading = isCartLoading || isAnyItemLoading;

  // LD Flags
  const { isFixedCartTotalsLoadingStateEnabled } = useFixedCartTotalsLoadingState();

  // Verify birthdate button
  const [isBirthdateConfirmed, setIsBirthdateConfirmed] = useState(false);
  const isConfirmBirthdayOnCheckoutEnabled = useSelector(
    (state: State) => state.settings.features.ConfirmBirthdayOnCheckout
  );
  const customerIsMedical = useSelector((state: State) => state.customer.details?.IsMedical) ?? false;
  const shouldBypassDOBCheck = useShouldBypassDOBCheck({ customerIsMedical });
  const isBirthdateCheckNeeded = isConfirmBirthdayOnCheckoutEnabled && !isBirthdateConfirmed && !shouldBypassDOBCheck;

  // Button props
  const { isButtonLoading, ...checkoutButtonProps } = useCheckoutButton();
  const verifyBirthdateButtonProps = useVerifyBirthdateButton({ setIsBirthdateConfirmed });

  if (isBirthdateCheckNeeded) {
    return {
      isLoading: isFixedCartTotalsLoadingStateEnabled ? isLoading : isLoadingDeprecated,
      shipmentId,
      ...verifyBirthdateButtonProps,
    };
  }

  return {
    isLoading: isFixedCartTotalsLoadingStateEnabled ? isCartLoading || isButtonLoading : isLoadingDeprecated,
    shipmentId,
    ...checkoutButtonProps,
  };
};
