import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { CashAmount } from 'components/text';
import { colors } from 'css/Theme';
import { Loader } from 'components/backoffice/loader';
import { TabbedContent } from 'components/layout';
import { useCashManagementPage } from './useCashManagementPage';

export const CashManagementPage: FC = () => {
  const { endingBalance, errorMessage, isBlindCloseoutsEnabled, isLoadingCloseout, registerName, selectedTab, tabs } =
    useCashManagementPage();

  return (
    <Container>
      <Header>
        <Title>Register {registerName}</Title>
        {!isBlindCloseoutsEnabled && (
          <Balance>
            Current Balance: <CashAmount empty={isLoadingCloseout || !!errorMessage} value={endingBalance} />
          </Balance>
        )}
      </Header>
      {isLoadingCloseout && (
        <Placeholder>
          <Loader variant='black' size='2x' />
        </Placeholder>
      )}
      {!isLoadingCloseout && errorMessage && <Placeholder>{errorMessage}</Placeholder>}
      {!isLoadingCloseout && !errorMessage && (
        <TabbedContent
          tabs={tabs}
          selectedTab={selectedTab}
          containerStyles={tabContainerStyles}
          tabBarStyles={tabBarStyles}
          contentStyles={tabContentStyles}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StickyStyles = css`
  position: sticky;
  top: 0;
  background: ${colors.dutchie.primaryWhite};
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1.5rem;
  border-bottom: 1px solid ${colors.dutchie.shadowGrey};
  ${StickyStyles}
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 700;
`;

const Balance = styled.div`
  display: flex;
  gap: 0.4rem;
  color: ${colors.dutchie.gray80};
  font-size: 0.75rem;
  line-height: 1rem;
`;

const Placeholder = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 500;
`;

const tabContainerStyles = css`
  flex: 1;
  ${StickyStyles}
`;

const tabBarStyles = css`
  padding: 0.75rem 1.5rem 0;
  ${StickyStyles}
`;

const tabContentStyles = css`
  padding: 1.5rem;
  overflow-y: auto;
`;
