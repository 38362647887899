import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { callback, callback1 } from 'models/Misc';
import { colors } from 'css/Theme';
import { Input } from 'components/inputs';
import { Box } from 'components/layout';

const MessageDiv = styled.div`
  color: ${colors.realBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.75rem 0 2.5rem;
  text-align: center;
`;

const Body = styled.div`
  margin: 0 1.5rem;
`;

type ConfirmPopupProps = {
  pinRequired?: boolean;
  isVisible: boolean;
  hide: callback;
  onConfirm: callback1<string>;
  title: string;
  message: string;
  successButtonText?: string;
};

export const ConfirmTransactionPopup: FC<ConfirmPopupProps> = ({
  isVisible,
  hide,
  onConfirm,
  title,
  message,
  pinRequired,
  successButtonText,
}) => {
  const [pin, setPin] = useState('');
  const disabled = pinRequired && !pin.length;

  const onContinue = () => {
    hide();
    onConfirm(pin);
  };

  return (
    <ConfirmationPopup
      hide={hide}
      isVisible={isVisible}
      title={title}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
      confirm={{
        disabled: disabled,
        text: successButtonText || 'Save',
        onClick: () => {
          onContinue();
        },
      }}
      small
    >
      <Body>
        <MessageDiv>{message}</MessageDiv>
        {pinRequired && (
          <Box marginBottom='2.25rem'>
            <Input value={pin} onChange={(e) => setPin(e.target.value)} placeholder='Manager PIN' type='password' />
          </Box>
        )}
      </Body>
    </ConfirmationPopup>
  );
};
