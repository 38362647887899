import React from 'react';

import { colors } from 'css/Theme';

const originalDimensions = {
  width: 20,
  height: 20,
};

type AssignCustomerProps = {
  fill?: string;
  width?: number;
  height?: number;
  automationId?: string;
};

export const AssignCustomerIcon = ({
  fill = colors.dutchie.blue,
  width = originalDimensions.width,
  height = originalDimensions.height,
  automationId,
}: AssignCustomerProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={originalDimensions.width}
      height={originalDimensions.height}
      fill='none'
      data-testid={automationId}
    >
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}>
        <g clip-path='url(#clip0_1488_168576)'>
          <path
            d='M1.32857 15.717V15.7181V17.1428C1.32857 17.1981 1.37334 17.2428 1.42857 17.2428H6.42857C6.76783 17.2428 7.04286 17.5179 7.04286 17.8571C7.04286 18.1964 6.76783 18.4714 6.42857 18.4714H0.714286C0.375025 18.4714 0.1 18.1964 0.1 17.8571V15.7143V15.7096C0.100016 15.709 0.100027 15.7084 0.100033 15.7079C0.11249 14.5152 0.427036 13.3451 1.01429 12.3069C1.60154 11.2688 2.44235 10.3964 3.45816 9.77129C4.47397 9.14617 5.63166 8.78872 6.82307 8.73232C8.01448 8.67591 9.20076 8.9224 10.2711 9.44875C10.5755 9.59847 10.701 9.96663 10.5512 10.2711C10.4015 10.5755 10.0334 10.7009 9.72892 10.5512C8.84492 10.1165 7.86517 9.91293 6.88117 9.95951C5.89717 10.0061 4.94102 10.3013 4.10205 10.8176C3.26308 11.3339 2.56865 12.0544 2.08363 12.9118C1.59922 13.7682 1.33947 14.7332 1.32857 15.717ZM15.6143 14.2857C15.6143 14.3409 15.6591 14.3857 15.7143 14.3857H19.2857C19.625 14.3857 19.9 14.6607 19.9 15C19.9 15.3393 19.625 15.6143 19.2857 15.6143H15.7143C15.6591 15.6143 15.6143 15.6591 15.6143 15.7143V19.2857C15.6143 19.625 15.3393 19.9 15 19.9C14.6607 19.9 14.3857 19.625 14.3857 19.2857V15.7143C14.3857 15.6591 14.3409 15.6143 14.2857 15.6143H10.7143C10.375 15.6143 10.1 15.3393 10.1 15C10.1 14.6607 10.375 14.3857 10.7143 14.3857H14.2857C14.3409 14.3857 14.3857 14.3409 14.3857 14.2857V10.7143C14.3857 10.375 14.6607 10.1 15 10.1C15.3393 10.1 15.6143 10.375 15.6143 10.7143V14.2857ZM7.14285 1.32857C5.70691 1.32857 4.54285 2.49263 4.54285 3.92857C4.54285 5.36451 5.70691 6.52857 7.14285 6.52857C8.57879 6.52857 9.74286 5.36451 9.74286 3.92857C9.74286 2.49263 8.57879 1.32857 7.14285 1.32857ZM3.31428 3.92857C3.31428 1.81411 5.02839 0.1 7.14285 0.1C9.25732 0.1 10.9714 1.81411 10.9714 3.92857C10.9714 6.04303 9.25732 7.75714 7.14285 7.75714C5.02839 7.75714 3.31428 6.04303 3.31428 3.92857Z'
            fill={fill}
            stroke={fill}
            stroke-width='0.2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_1488_168576'>
            <rect width={originalDimensions.width} height={originalDimensions.height} fill='white' />
          </clipPath>
        </defs>
      </g>
    </svg>
  );
};
