import { post } from 'api/HttpHelpers';
import {
  AdjustAllotmentRequest,
  AdjustLoyaltyPointsRequest,
  AdjustExternalPatientAllotmentRequest,
  CheckInCustomerRequest,
  CheckInCustomerResponse,
  ClearAllotmentRequest,
  CreateCustomerRequest,
  CustomerDetails,
  CustomerSearchResult,
  Doctor,
  EditCustomerDetailsRequest,
  EditPrimaryIdentificationRequest,
  GetDocumentsResponse,
  ProductHistoryItem,
  ReferralSource,
  ReturnReason,
  UploadDocumentRequest,
  SelectCollectorRequest,
  CustomerCaregiver,
  EditCollectorRequest,
  CheckinCaregiver,
  UpdateAttestationRequest,
  UpdateDaysSupplyRequest,
  CustomerDetailsLight,
  QualifyingCondition,
  Prescription,
  AddPrescriptionRequest,
  CompletePrescriptionRequest,
  RegisterCustomerForLoyaltyRequest,
  RemotePayTextRequest,
  RempotePayEmailRequest,
  LoyaltyAdjustmentReason,
  DownloadDocumentRequest,
  SetPatientPolicyAgreement,
  PatientPolicyAgreement,
  DeletePrescriptionRequest,
  RemoveAddressBookItemRequest,
} from 'models/Customer';
import { AvailableDiscount, DiscountGroup, GetCustomerDiscountsRequest } from 'models/Discounts';
import { GetJournalRequest, JournalEntry, CreateUpdateEntryRequest } from 'models/Journal';
import { AvailableFeeDonation } from 'models/FeeDonation';
import { SetTransactionReferenceRequest } from 'models/Guest';
import { MMURCaregiver } from 'models/MMUR';

export const getAvailableGroups = () => post<Array<DiscountGroup>>('v2/discount/Available_groups');
export const getAvailableDiscounts = (CustId: number, body?: GetCustomerDiscountsRequest) =>
  post<{ Data?: Array<AvailableDiscount> }>(
    'v2/discount/available_discounts',
    { CustId, ...body },
    { ignoreFormat: true }
  );
export const getDoctorList = () => post<Array<Doctor>>('v2/guest/Doctor_list');
export const getPrescriptions = (args: { AcctId: number }) =>
  post<Array<Prescription>>('v2/guest/get-prescriptions', args);
export const addPrescription = (args: AddPrescriptionRequest) => post<void>('v2/guest/add-prescription', args);
export const completePrescription = (args: CompletePrescriptionRequest) =>
  post<void>('v2/guest/complete-prescription', args);
export const deletePrescription = (args: DeletePrescriptionRequest) => post<void>('v2/guest/delete-prescription', args);
export const getReferralList = () => post<{ ReferralSources: Array<ReferralSource> }>('ReferenceData/get-all');
export const getCustomerDetails = (args: { Guest_id: number }) => post<CustomerDetails>('v2/guest/details', args);
export const editCustomerDetails = (args: EditCustomerDetailsRequest) =>
  post<Array<CustomerDetails>>('v2/guest/edit-details', args);
export const checkDuplicates = (args: CreateCustomerRequest) => post<CustomerDetails>('v2/guest/check-duplicate', args);
export const createCustomer = (args: CreateCustomerRequest) => post<Array<CustomerDetails>>('v2/guest/create', args);
export const adjustCustomerLoyaltyPoints = (args: AdjustLoyaltyPointsRequest) =>
  post<void>('posv3/maintenance/LoyaltyAdjustment', args);
export const adjustCustomerExternalDispensed = (args: AdjustAllotmentRequest) =>
  post<void>('v2/guest/UpdateExternalDispensed', args);
export const adjustCustomerMaxAllotment = (args: AdjustAllotmentRequest) =>
  post<void>('v2/guest/UpdateMaxAllotment', args);
export const adjustCustomerCurrentAllotment = (args: AdjustAllotmentRequest) =>
  post<void>('v2/guest/UpdateCurrentAllotment', args);
export const adjustExternalPatientAllotment = (args: AdjustExternalPatientAllotmentRequest) =>
  post<void>('v2/guest/EditExternalPatientAllotment', args);
export const clearCustomerMaxAllotment = (args: ClearAllotmentRequest) =>
  post<void>('v2/guest/ClearMaxAllotment', args);
export const clearCustomerCurrentAllotment = (args: ClearAllotmentRequest) =>
  post<void>('v2/guest/ClearCurrentAllotment', args);
export const checkInCustomer = (args: CheckInCustomerRequest) =>
  post<{ Result: boolean; Message: string; Data?: CheckInCustomerResponse[]; IntegrationMessage?: string }>(
    'v2/guest/checkin_guest',
    args,
    {
      ignoreFormat: true,
    }
  );
export const loadReturnReasons = () => post<Array<ReturnReason>>('posv3/maintenance/LoadReturnReasons');
export const loadProductHistory = (args: { Guest_id: number }) =>
  post<Array<ProductHistoryItem>>('v2/product/product_History', args);
export const getCustomerJournal = (args: GetJournalRequest) =>
  post<Array<JournalEntry>>('v2/guest/View_Patient_Notes', args);
export const createUpdateJournalEntry = (args: CreateUpdateEntryRequest) =>
  post<Array<JournalEntry>>('v2/guest/Update_Patient_Notes', args);
export const editPrimaryIdentification = (args: EditPrimaryIdentificationRequest) =>
  post<void>('v2/guest/update_patient_primary_identification_image', args);
export const saveNotes = (args: { Note: string; GuestId?: number }) => post('v2/guest/UpdateNotes', args);
export const getDocuments = (args: { PatientId: number }) => post<GetDocumentsResponse>('v2/guest/get-file-list', args);
export const uploadDocument = (args: UploadDocumentRequest) => post<void>('v2/guest/upload-file', args);
export const downloadDocument = (args: DownloadDocumentRequest) => post<string>('v2/guest/download-file', args);
export const deleteDocument = (args: { FileId?: number }) => post<void>('v2/guest/delete-file', args);
/** @deprecated Use the new React Query hook useCustomerSearchQuery */
export const searchCustomers = (args?: { SearchString: string }) =>
  post<Array<CustomerSearchResult>>('v2/guest/checkin_search_by_string', args);
/** @deprecated Use the new query instead clean up with pos.register.anon-cart-workflow.rollout */
export const convertGuestToNonAnonymous = (args: { Guest_id: number }) =>
  post<void>('v2/guest/ConvertToNonAnonymous', args, { ignoreFormat: false });
export const getAvailableFeesDonations = () =>
  post<Array<AvailableFeeDonation>>('v2/fee-donation/get-active-fees-donations');
export const getCollectors = (CustomerId: number) =>
  post<Array<CustomerCaregiver>>('MN/get-collectors', { CustomerId });
export const selectCollector = (args: SelectCollectorRequest) => post('MN/select-collector', args);
export const editCollector = (args: EditCollectorRequest) => post<CustomerCaregiver>('MN/edit-collector', args);
export const getMMURCaregivers = (Guest_id: number) =>
  post<Array<MMURCaregiver>>('mmur/get-customer-caregivers', { Guest_id });
export const getCaregiversList = (Guest_id: number) =>
  post<{ Data: Array<CheckinCaregiver> }>('v2/guest/caregiver_list', { Guest_id }, { ignoreFormat: true });
export const updateAttestation = (body: UpdateAttestationRequest) =>
  post<void>('posv3/backendpos/UpdateAttestation', body);
export const updateDaysSupplyRemaining = (args: UpdateDaysSupplyRequest) =>
  post<void>('v2/guest/UpdateDaysSupplyRemaining', args);
export const getCustomerDetailsLight = (args: { CustomerId: number }) =>
  post<CustomerDetailsLight>('v2/guest/details-light', args);
export const getQualifyingConditions = () =>
  post<Array<QualifyingCondition>>('posv3/maintenance/get-qualifying-conditions');
export const registerCustomerForLoyalty = (args: RegisterCustomerForLoyaltyRequest) =>
  post<void>('v2/guest/register-for-loyalty', args);
export const sendRemotePayEmail = (body: RempotePayEmailRequest) => post('remotepay/send-email', body);
export const sendRemotePayText = (body: RemotePayTextRequest) => post('remotepay/send-text', body);
export const loadAdjustmentLoyaltyReasons = () =>
  post<Array<LoyaltyAdjustmentReason>>('posv3/maintenance/LoadAdjustLoyaltyReasons');
export const getPatientPolicy = (args: { AcctId: number }) =>
  post<PatientPolicyAgreement>('v2/guest/get-policy-agreement', args);
export const setPatientPolicy = (body: SetPatientPolicyAgreement) =>
  post<void>('v2/guest/save-policy-agreement-signature', body);
export const removeAddressBookItem = (body: RemoveAddressBookItemRequest) => post('v2/guest/delete-address', body);
export const setTransactionReference = (body: SetTransactionReferenceRequest) =>
  post<string>('v2/cart/set-transaction-reference', body);
