import { useState, useEffect } from 'react';

type usePostHook = <T, B>(
  api: (body?: B) => Promise<T>,
  body?: B,
  dependecies?: React.DependencyList,
  defaultValue?: T
) => [T | undefined, string | undefined, boolean];

export const usePost: usePostHook = <T, B>(
  api: (body?: B) => Promise<T>,
  body?: B,
  dependencies?: React.DependencyList,
  defaultValue?: T
) => {
  const [state, setState] = useState({
    value: defaultValue,
    error: undefined,
    loading: true,
  });

  useEffect(() => {
    api(body)
      .then((value) => setState({ value, error: undefined, loading: false }))
      .catch((error) =>
        setState({
          value: defaultValue,
          error: error.toString(),
          loading: false,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  const { value, error, loading } = state;
  return [value, error, loading];
};
