import React, { useState } from 'react';

import { ConfigureBarcodeScannerModal } from './ConfigureBarcodeScannerModal';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';

export const ScannerConfigListItem = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SettingsListItem
        title='Configure scanner'
        subtitle='Configure a supported scanner for the register'
        onClick={() => setShowModal(true)}
      />
      {showModal && <ConfigureBarcodeScannerModal hide={() => setShowModal(false)} />}
    </>
  );
};
