import { GeneriPayPaymentIntegration } from 'queries/v2/payments/generipay';
import React, { FC } from 'react';
import { AmountSelection } from './Generipay/AmountSelection';
import { Loader } from 'components/backoffice/loader';
import styled from 'styled-components';

type PaymentGeneriPayProps = {
  PaymentTypeMetaData: GeneriPayPaymentIntegration | null;
};

function LoadingSpinner() {
  return (
    <SpinnerWrapperDiv>
      <Loader variant='black' size='2x' />
    </SpinnerWrapperDiv>
  );
}

// This is a mapping of the fields that are returned from the GeneriPay API
// to the components that should be rendered.
// If/when other capture fields are needed, they should be added here (e.g. 'email': EmailCapture).
const GeneriPayTenderCaptureComponents: Record<string, Function> = {
  amount: AmountSelection,
};

// This function takes the PaymentTypeMetaData and maps the fields to the appropriate components
// and adds a key to each component to prevent React from complaining about duplicate keys.
// It also handles filtering out any fields that are not mapped to a GeneriPayTenderCaptureComponent.
function useGeneriPayTenderCaptureComponents(PaymentTypeMetaData: GeneriPayPaymentIntegration | null): JSX.Element[] {
  if (!PaymentTypeMetaData) {
    return [<LoadingSpinner key={'loading-spinner'} />];
  }

  return PaymentTypeMetaData.PaymentCaptureFields.filter((field) => {
    return field in GeneriPayTenderCaptureComponents;
  }).map((field, index) => {
    const Component = GeneriPayTenderCaptureComponents[field];
    return <Component PaymentTypeMetaData={PaymentTypeMetaData} key={`generipay-tender-capture-item-${index}`} />;
  });
}

export const PaymentGeneriPay: FC<PaymentGeneriPayProps> = ({ PaymentTypeMetaData }) => {
  const tenderCaptureComponents = useGeneriPayTenderCaptureComponents(PaymentTypeMetaData);
  return <>{tenderCaptureComponents.map((component) => component)}</>;
};

const SpinnerWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`;
