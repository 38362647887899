import React from 'react';

export function FailIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_359_125569)'>
        <path
          d='M24 12C24 9.62663 23.2962 7.30655 21.9776 5.33316C20.6591 3.35977 18.7849 1.8217 16.5922 0.913451C14.3995 0.00519941 11.9867 -0.232441 9.65892 0.230582C7.33115 0.693605 5.19295 1.83649 3.51472 3.51472C1.83649 5.19295 0.693605 7.33115 0.230582 9.65892C-0.232441 11.9867 0.00519941 14.3995 0.913451 16.5922C1.8217 18.7849 3.35977 20.6591 5.33316 21.9776C7.30655 23.2962 9.62663 24 12 24C15.1811 23.9951 18.2305 22.7293 20.4799 20.4799C22.7293 18.2305 23.9951 15.1811 24 12ZM12 20.3077C10.653 20.3035 9.32776 19.9675 8.14154 19.3292C8.07729 19.2931 8.02222 19.2427 7.9806 19.1819C7.93898 19.121 7.91193 19.0514 7.90154 18.9785C7.89187 18.9083 7.8985 18.8368 7.92091 18.7695C7.94332 18.7023 7.98091 18.6411 8.03077 18.5908L18.6092 8.01231C18.6604 7.96357 18.7216 7.92671 18.7886 7.90438C18.8557 7.88204 18.9268 7.87476 18.9969 7.88308C19.0681 7.89179 19.1359 7.91836 19.194 7.96033C19.2521 8.00229 19.2986 8.05828 19.3292 8.12308C19.9741 9.31339 20.3104 10.6463 20.3077 12C20.3077 13.091 20.0928 14.1713 19.6753 15.1792C19.2578 16.1872 18.6459 17.103 17.8744 17.8744C17.103 18.6459 16.1872 19.2578 15.1792 19.6753C14.1713 20.0928 13.091 20.3077 12 20.3077ZM3.69231 12C3.67512 10.5613 4.03352 9.14309 4.73211 7.88531C5.4307 6.62754 6.44531 5.57375 7.67574 4.82804C8.90616 4.08233 10.3098 3.67049 11.7481 3.63319C13.1864 3.5959 14.6095 3.93445 15.8769 4.61539C15.9412 4.65151 15.9962 4.70193 16.0379 4.76276C16.0795 4.82359 16.1065 4.89319 16.1169 4.96616C16.1266 5.03636 16.12 5.10785 16.0976 5.17509C16.0751 5.24232 16.0376 5.30349 15.9877 5.35385L5.40923 15.9877C5.3581 16.0364 5.29684 16.0733 5.22983 16.0956C5.16281 16.118 5.09169 16.1252 5.02154 16.1169C4.9504 16.1082 4.88261 16.0816 4.82451 16.0397C4.7664 15.9977 4.71987 15.9417 4.68923 15.8769C4.03872 14.688 3.69601 13.3552 3.69231 12Z'
          fill='#BE0517'
        />
      </g>
      <defs>
        <clipPath id='clip0_359_125569'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
