import React, { PropsWithChildren, createContext } from 'react';
import { AssignRegisterSettingsContextData } from '../types';
import { useAssignRegisterSettingsProvider } from '../hooks/useAssignRegisterSettingsProvider';

export const AssignRegisterSettingsContext = createContext<AssignRegisterSettingsContextData>(
  {} as AssignRegisterSettingsContextData
);

export const AssignRegisterSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useAssignRegisterSettingsProvider();
  return <AssignRegisterSettingsContext.Provider value={data}>{children}</AssignRegisterSettingsContext.Provider>;
};
