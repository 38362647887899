import { useTrackedContext } from 'util/hooks';
import { ProductsPopupsState, emptyProductsPopupsState } from './ProductsPopupsState';
import { ProductsPopupsContext, ProductsPopupsContextProps } from './ProvideProductsPopups';

type ProductPopups = {
  showLabsPopup: (batchId?: number) => void;
  productsPopupsState: ProductsPopupsState;
};

const createPopupHandlers = ([
  productsPopupsState,
  setProductsPopupsState,
]: ProductsPopupsContextProps): ProductPopups => {
  const showPopup = (props: Partial<ProductsPopupsState>) =>
    setProductsPopupsState({ ...emptyProductsPopupsState, ...props });
  return {
    showLabsPopup: (batchId?: number) => showPopup({ labsPopup: { batchId } }),
    productsPopupsState,
  };
};

export const useProductsPopups = () =>
  useTrackedContext<ProductsPopupsState, ProductPopups>(ProductsPopupsContext, createPopupHandlers);
