import { useCallback } from 'react';

import { ProductSearchResult } from 'queries/v2/product/types';
import { showLast4Popup } from 'store/actions/PopupsActions';

import { useAppSelector, useAppDispatch } from './useAppDispatch';

type VerifyLastFourIfNecessaryParams = Pick<ProductSearchResult, 'cannabisInventory'> & Pick<ProductSearchResult, 'serialNo'>;

export const useVerifyLastFourIfNecessary = () => {
  const isLast4Enabled = useAppSelector((state) => state.settings.features.Last4);
  const dispatch = useAppDispatch();

  return useCallback(
    ({ cannabisInventory, serialNo }: VerifyLastFourIfNecessaryParams) => {
      return new Promise<void>((resolve, reject) => {
        if (isLast4Enabled && cannabisInventory === 'Yes') {
          dispatch(
            showLast4Popup({
              onSuccess: () => {
                resolve();
              },
              serialNumber: serialNo,
              onCancel: () => {
                reject();
              },
            })
          );
        } else {
          resolve();
        }
      });
    },
    [dispatch, isLast4Enabled]
  );
};
