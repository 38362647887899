import React from 'react';

import { OrderTotalsLoadingSkeleton } from './OrderTotalsLoadingSkeleton';
import { CashAmount } from 'components/text';
import { useOrderTotals } from './useOrderTotals';

import { Container, SubtotalsContainer, Row, SubTotalRow } from './OrderTotals.styles';
import { OrderTotal } from './OrderTotal';

import type { Cart } from 'models/Cart';

export type OrderTotalsProps = {
  align?: 'top' | 'bottom';
  cart: Partial<Cart>;
  hideGetTotalBtn?: boolean;
  loading?: boolean;
  showDutchiePayPreAuthCheckout?: boolean;
  showTotal?: boolean;
};

export const OrderTotals = ({
  align = 'bottom',
  cart,
  hideGetTotalBtn = false,
  loading,
  showDutchiePayPreAuthCheckout = false,
  showTotal = true,
}: OrderTotalsProps): JSX.Element => {
  const {
    areCartTotalsLoading,
    hideTotals,
    showLoyaltyAsDiscount,
    totalLoyalty,
    showFeesAndDonations,
    appliedDutchiePayFees,
    showDutchiePayFee,
    appliedNonCashAdjustmentFee,
    showNonCashAdjustmentFee,
    showCardActivationFee,
    showRounding,
    showTipAmount,
  } = useOrderTotals({ cart, hideGetTotalBtn });

  if (loading || areCartTotalsLoading) {
    return <OrderTotalsLoadingSkeleton align={align} cart={cart} showTotal={showTotal} />;
  }

  return (
    <Container data-testid='order-totals-container' align={align}>
      <SubtotalsContainer>
        <SubTotalRow>
          <div>Subtotal</div>
          <CashAmount automationId='summary_div_cart-subtotal-and-total-item-count' value={cart.SubTotal} />
        </SubTotalRow>
        {showLoyaltyAsDiscount ? (
          <Row data-testid='summary_total-pair_total-discount-loyalty'>
            <div>Discounts & loyalty</div>
            <CashAmount automationId='summary_div_discount-amount' value={cart?.TotalDiscount} hideValue={hideTotals} />
          </Row>
        ) : (
          <>
            <Row data-testid='summary_total-pair_total-discount'>
              <div>Discounts</div>
              <CashAmount
                automationId='summary_div_discount-amount'
                value={cart?.TotalDiscount}
                hideValue={hideTotals}
              />
            </Row>
            <Row data-testid='summary_total-pair_total-loyalty'>
              <div>Loyalty</div>
              <CashAmount automationId='summary_div_loyalty-amount' value={totalLoyalty} hideValue={hideTotals} />
            </Row>
          </>
        )}
        <Row>
          <div>Tax</div>
          <CashAmount automationId='summary_div_tax-amount' value={cart.Tax} hideValue={hideTotals} />
        </Row>
        {showFeesAndDonations && (
          <Row>
            <div>Fees & donations</div>
            <CashAmount
              automationId='summary_div_feedonationtotal-amount'
              value={cart.FeesAndDonations}
              hideValue={hideTotals}
            />
          </Row>
        )}
        {showDutchiePayFee && (
          <Row>
            <div>Dutchie Pay fee</div>
            <CashAmount
              automationId='summary_div_dutchiepayfee-amount'
              value={appliedDutchiePayFees}
              hideValue={hideTotals}
            />
          </Row>
        )}
        {showNonCashAdjustmentFee && (
          <Row>
            <div>Non-cash adjustment fee</div>
            <CashAmount
              automationId='summary_div_noncashadjustmentfee-amount'
              value={appliedNonCashAdjustmentFee}
              hideValue={hideTotals}
            />
          </Row>
        )}
        {showCardActivationFee && (
          <Row>
            <div>Card activation fee</div>
            <CashAmount
              automationId='summary_div_noncashadjustmentfee-amount'
              value={appliedNonCashAdjustmentFee}
              hideValue={hideTotals}
            />
          </Row>
        )}
        {showRounding && (
          <Row>
            <div>Rounding</div>
            <CashAmount automationId='summary_div_rounding-amount' value={cart.RoundingAmount} hideValue={hideTotals} />
          </Row>
        )}
        {showTipAmount && (
          <Row>
            <div>Tip</div>
            <CashAmount automationId='summary_div_tip-amount' value={cart.TipAmount} hideValue={hideTotals} />
          </Row>
        )}
      </SubtotalsContainer>
      {showTotal && (
        <OrderTotal
          grandTotalRounded={cart.GrandTotalRounded}
          hideGetTotalBtn={hideGetTotalBtn}
          showDutchiePayPreAuthCheckout={showDutchiePayPreAuthCheckout}
        />
      )}
    </Container>
  );
};
