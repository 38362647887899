export enum MessagingChannelType {
    Guest = 'Guest',
    DutchiePayInStore = 'DutchiePayInStore',
    Returns = 'Refunds',
    Payments = 'Payments',
    FeatureFlags = 'FeatureFlags',
}

export enum MessagingProvider {
    Pusher = 0,
    Ably = 1,
}

export enum MessagingProviderName {
    Pusher = 'pusher',
    Ably = 'ably',
}

export type MessagingChannelConfiguration = {
    PusherCluster: string;
    PusherKey: string;
    ChannelName: string;
};

export type MessagingConfigRequest = {
    Provider: MessagingProvider;
    ChannelType: MessagingChannelType;
};

export type MessagingAuthRequest = MessagingConfigRequest & {
    SocketId?: string;
};

export type MessagingAuthResponse = {
    auth: string;
};

export type MessageEnvelope = {
    LspId: number;
    LocId: number;
    MessageName: string;
    MessageId?: string;
    Timestamp: Date;
    Provider: string;
    Step: MessageStep;
    ChannelName: string;
    Data: object | string;
  };

export enum MessageStep {
    sent = 'SENT',
    received = 'RECEIVED',
}
