import { useMemo } from 'react';

export const DEFAULT_PAGE_SIZES = [10, 25, 50, 75, 100, 150, 250];

function allPageSizes(currentPageSize: number) {
  if (DEFAULT_PAGE_SIZES.includes(currentPageSize)) {
    return DEFAULT_PAGE_SIZES;
  }

  return [...DEFAULT_PAGE_SIZES, currentPageSize].sort((a, b) => a - b);
}

export function usePageSizeOpts(pageSize: number) {
  return useMemo(() => allPageSizes(pageSize).map((value) => ({ label: value.toString(), value })), [pageSize]);
}
