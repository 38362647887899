import React, { FC, useState, useEffect, useCallback } from 'react';
import { State } from 'store';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ProductHistoryItem } from 'models/Customer';
import { ProductHistoryList } from './components/ProductHistoryList';
import { SearchResults } from 'components/layout';
import { loadProductHistory, searchProductHistory } from 'store/actions/CustomerActions';
import { Input } from 'components/inputs';
import { ReactComponent as SearchIcon } from 'assets/icons/magnifying-glass.svg';
import { colors } from 'css/Theme';
import { useIsPurchases } from './hooks/useIsPurchases/useIsPurchases';

export const PurchasesPage: FC = () => {
  const { searchQuery, products, loading: allOrdersLoading } = useSelector((state: State) => state.customer.history);
  const guest = useSelector((state: State) => state.customer.details);
  const [query, setQuery] = useState('');
  const [displayProducts, setDisplayProducts] = useState<Array<ProductHistoryItem>>([]);
  const dispatch = useDispatch();

  const isPurchases = useIsPurchases();

  useEffect(() => {
    if (guest) {
      dispatch(loadProductHistory(guest.Guest_id));
    }
  }, [dispatch, guest]);

  useEffect(() => {
    if (guest && products) {
      setDisplayProducts(products);
    }
  }, [products, guest]);

  const search = useCallback(
    debounce(async (query: string) => {
      dispatch(searchProductHistory(query));
    }, 300),
    []
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (allOrdersLoading) {
      return;
    }
    search(e.target.value);
    setQuery(e.target.value);
  };

  const deriveErrorMessage = () => {
    if (searchQuery.length && displayProducts.length === 0) {
      return 'No matching products on history found';
    }
  };

  return (
    <Container isPurchases={isPurchases}>
      <Input
        containerMargin='0 0 1rem 0'
        placeholder='Product search...'
        value={query}
        onChange={onChange}
        startAdornment={<SearchIcon color={colors.dutchie.grey} />}
      />
      <SearchResults
        loading={allOrdersLoading}
        errMessage={deriveErrorMessage()}
        isEmpty={!displayProducts.length}
        emptyMessage='Empty product history'
      >
        <ProductHistoryList data={displayProducts} />
      </SearchResults>
    </Container>
  );
};

const Container = styled.div<{ isPurchases: boolean }>`
  padding: 1.5rem 0;

  ${({ isPurchases }) =>
    isPurchases &&
    css`
      padding: 1.5rem;
    `}
`;
