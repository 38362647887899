import React from 'react';
import { PeripheralType } from '@dutchie/capacitor-hardware';

import { AddNewDeviceModal } from './AddNewDeviceModal';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useAddDeviceListItem } from './useAddDeviceListItem';

export const AddDeviceListItem = ({ deviceType }: { deviceType: PeripheralType }) => {
  const { addNewDeviceModalProps, deviceDescription, handleShowAddNewDeviceModal, isSupported } =
    useAddDeviceListItem(deviceType);

  if (!isSupported) {
    return null;
  }

  return (
    <SettingsCard>
      <SettingsListItem
        title={`Add new ${deviceDescription}`}
        subtitle={`Authorize a new ${deviceDescription} to connect to the register`}
        onClick={handleShowAddNewDeviceModal}
      />
      {addNewDeviceModalProps && <AddNewDeviceModal {...addNewDeviceModalProps} />}
    </SettingsCard>
  );
};
