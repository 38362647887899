import driversLicense from 'assets/images/test-barcodes/drivers-license.png';
import { DeviceBarcode, HardwareService, Scanner, BarcodeData } from '@dutchie/capacitor-hardware';
import { ScannerBarcode } from './ScannerBarcode';
import React, { useEffect } from 'react';
import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';
import { useParseIdMutation } from 'queries/v2/guest/parse-id';

export const TestLicenseBarcode = ({ onScan }: { onScan: (success: boolean, barcode: DeviceBarcode) => void }) => {
  const { mutateAsync: parseId, isLoading: validating } = useParseIdMutation();

  useEffect(() => {
    const handleBarcode = async (scanEvent: Event) => {
      if (validating) {
        return;
      }

      const data = (scanEvent as CustomEvent).detail as { barcode: BarcodeData; device: Scanner };
      const request = { id: data.barcode.text };
      try {
        logger.debug('Parsing ID', {
          key: customEventKeys.settings.scanner.parseLicense,
          barcode: data.barcode.text,
        });

        const response = await parseId(data.barcode.text);
        logger.debug('Parse ID Response', {
          key: customEventKeys.settings.scanner.parseLicense,
          request,
          response,
        });

        // Validate against our test user
        const success =
          response.IDNumber === '99999999' &&
          response.FirstName === 'JANICE' &&
          response.LastName === 'SAMPLE' &&
          response.MiddleName === 'ANN' &&
          response.Address1 === '123 MAIN STREET' &&
          response.City === 'HARRISBURG' &&
          response.State === 'PA' &&
          response.PostalCode === '17101' &&
          response.ExpirationDate === '2023-08-04T00:00:00' &&
          response.DateOfBirth === '1969-08-04T00:00:00';

        onScan(success, data);
      } catch (e) {
        logger.error('Failed to parse ID', {
          request,
          error: parseErrorMessage(e),
        });

        onScan(false, data);
      }
    };

    HardwareService.scanner.addEventListener('barcode', handleBarcode);
    return () => HardwareService.scanner.removeEventListener('barcode', handleBarcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ScannerBarcode src={driversLicense} maxHeight={61} aspectRatio={3} />;
};
