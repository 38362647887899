import React, { ReactNode } from "react";
import { css } from "styled-components";
import { AlertBanner, AlertBannerStyles } from "components/misc";

export const SettingsAlertBanner = ({ icon, marginTop, style, text }: {icon?: JSX.Element; marginTop?: string; style: AlertBannerStyles; text: ReactNode }) => {
  return <AlertBanner icon={icon} style={style} text={text} thick textStyles={AlertTextStyles} containerStyles={AlertContainerStyles} marginTop={marginTop ?? '0px'} />;
};

const AlertTextStyles = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const AlertContainerStyles = css`
  padding: 12px;
`;
