import { colors } from 'css/Theme';
import { Skeleton } from 'components/misc';
import React from 'react';
import styled from 'styled-components';

const DeliveryPageSkeleton = () => {
  return (
    <SkeletonLoading>
      <SkeletonMap />
      <DeliverySkeletonContainer>
        <DeliveryTableHeader>
          <Skeleton width={226} height={16} />
          <Skeleton width={134} height={16} />
        </DeliveryTableHeader>
        <DeliveryTable>
          <tbody>
            {[...Array(15)].map((_, i) => (
              <TableSkeletonData key={i} />
            ))}
          </tbody>
        </DeliveryTable>
      </DeliverySkeletonContainer>
    </SkeletonLoading>
  );
};

const TableSkeletonData = React.memo(() => {
  return (
    <tr>
      <td>
        <Skeleton width={74} height={16} />
      </td>
      <td>
        <Skeleton width={45} height={16} />
      </td>
      <td>
        <Skeleton width={78} height={16} />
      </td>
      <td>
        <Skeleton width={30} height={16} />
      </td>
      <td>
        <Skeleton width={42} height={16} />
      </td>
      <td>
        <Skeleton width={48} height={16} />
      </td>
    </tr>
  );
});

export default DeliveryPageSkeleton;

const DeliverySkeletonContainer = styled.div`
  width: 100%;
  margin-right: 3.75rem;
`;

const DeliveryTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeliveryTable = styled.table`
  width: 100%;
  margin-top: 2rem;

  tr {
    border-bottom: 1px solid #e5e5e5;
  }

  tr td {
    padding: 1.5rem 2rem;
  }

  tr td:last-child {
    padding-right: 6rem;
  }

  tr:first-child {
    border-bottom: none;
  }
  tr:last-child {
    border-bottom: none;
  }
`;

const SkeletonLoading = styled.div`
  display: flex;
  height: 100%;
  gap: 24px;
`;

const SkeletonMap = styled.div`
  background-color: ${colors.dutchie.grey10};
  width: 100%;
`;
