import React, {  } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Formik, Form } from 'formik';
import FormikErrorFocus from 'formik-error-focus';

import { SideTabContainer } from 'components/layout';
import { Pill } from 'components/misc';
import { Button } from 'components/buttons';
import { Loader } from 'components/backoffice/loader';
import { colors } from 'css/Theme';
import { CustomerDetails } from 'models/Customer';
import { CustomerPopups } from 'components/CustomerPopups';

import { initialValues } from './types';
import { useEditCustomerPage } from './useEditCustomerPage';
import { StickyHeader } from 'components/layout/StickyHeader';
import { FormObserver } from './hooks/FormObserver';

export const EditCustomerPageWithoutPopups = () => {
  const {
    isLoading,
    isSubmitting,
    isCreatingNewCustomer,
    isCustomerBanned,
    isCheckedIn,
    isValidCustomer,
    isFormChanged,
    setIsFormChanged,
    formSchema,
    formRef,
    headerRef,
    customerDetails,
    customerSinceString,
    titleString,
    showCheckInButton,
    showCheckInCaregiverButton,
    showCreateOrderButton,
    showConvertToNonAnonymousButton,
    showVerifyDOBButton,
    handleClickCancel,
    handleCheckIn,
    handleCreateOrder,
    handleClickCreateOrSave,
    createOrSave,
    handleClickVerifyDOB,
    handleClickCheckInCaregiver,
    handleClickConvertToNonAnonymous,
    sideTabPages,
  } = useEditCustomerPage();

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader variant='black' size='2x' />
      </LoaderContainer>
    );
  }

  return (
    <EditCustomerPageDiv>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues, ...(customerDetails as CustomerDetails) }}
        onSubmit={() => {}}
        validationSchema={formSchema}
        validateOnMount
        innerRef={(instance) => (formRef.current = instance)}
      >
        {({ validateForm, values }) => (
          <Form>
            <StickyHeader headerRef={headerRef}>
              <TopContainerDiv>
                <div>
                  <NameContainer>
                    <CustomerInfoSpan data-testid='edit-customer-page-name'>{titleString}</CustomerInfoSpan>
                    {isCustomerBanned && (
                      <Pill text='Banned customer' variation='red' automationId='banned-customer-pill' />
                    )}
                  </NameContainer>
                  <CreatedOnSpan>{customerSinceString}</CreatedOnSpan>
                </div>
                <ButtonsDiv>
                  {showCheckInCaregiverButton && (
                    <TopButton
                      width={'140px'}
                      type='button'
                      secondary
                      disabled={isCreatingNewCustomer || !isValidCustomer || isCheckedIn}
                      onClick={handleClickCheckInCaregiver}
                      automationId='index_top-button_check-in-caregiver'
                    >
                      Check in caregiver
                    </TopButton>
                  )}
                  {showCheckInButton && (
                    <TopButton
                      type='button'
                      secondary
                      disabled={isCreatingNewCustomer || !isValidCustomer || isCheckedIn}
                      onClick={() => handleCheckIn({ validate: validateForm })}
                      automationId='index_top-button_check-in'
                    >
                      Check in
                    </TopButton>
                  )}
                  {showConvertToNonAnonymousButton && customerDetails && (
                    <TopButton
                      type='button'
                      onClick={handleClickConvertToNonAnonymous}
                      width='12rem'
                    >
                      Convert to
                      <br />
                      Non-Anonymous
                    </TopButton>
                  )}
                  {showVerifyDOBButton && (
                    <TopButton
                      type='button'
                      onClick={handleClickVerifyDOB}
                    >
                      Verify DOB
                    </TopButton>
                  )}
                  {showCreateOrderButton && (
                    <TopButton
                      type='button'
                      disabled={isCreatingNewCustomer || !isValidCustomer}
                      onClick={handleCreateOrder}
                      automationId='index_top-button_enter-pos'
                    >
                      {isCheckedIn ? 'Return to cart' : 'Create order'}
                    </TopButton>
                  )}
                  <DividerLine />
                  <TopButton
                    type='button'
                    tertiary
                    onClick={handleClickCancel}
                    automationId='index_top-button_cancel'
                  >
                    Cancel
                  </TopButton>
                  <TopButton
                    type='button'
                    transparentBlue
                    disabled={isSubmitting || (!isFormChanged && !isCreatingNewCustomer)}
                    onClick={(e) => handleClickCreateOrSave(e, values, validateForm)}
                    id={`SaveCustomerProfile_${customerDetails?.Guest_id ?? 0}`}
                    automationId='index_top-button_create'
                  >
                    {isCreatingNewCustomer ? 'Create' : 'Save'}
                  </TopButton>
                </ButtonsDiv>
              </TopContainerDiv>
            </StickyHeader>
            <SideTabContainer pages={sideTabPages} headerRef={headerRef} />
            <FormikErrorFocus />
            <FormObserver isFormChanged={isFormChanged} setIsFormChanged={setIsFormChanged} save={createOrSave} />
          </Form>
        )}
      </Formik>
    </EditCustomerPageDiv>
  );
};

export const EditCustomerPage = () => (
  <>
    <CustomerPopups />
    <EditCustomerPageWithoutPopups />
  </>
);

const EditCustomerPageDiv = styled.div``;

const TopContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomerInfoSpan = styled.span`
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.75rem;
`;

const CreatedOnSpan = styled.span`
  color: ${rgba('black', 0.5)};
  font-size: 0.75rem;
  height: 1rem;
  line-height: 1rem;
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
`;

const TopButton = styled(Button)<{ secondary?: boolean; width?: string }>`
  margin-left: 12px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DividerLine = styled.div`
  border-left: 1px solid ${colors.dutchie.grey20};
  height: 40px;
  margin-left: 16px;
  margin-right: 4px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 12px;
  align-content: flex-start;
  justify-content: space-between;
  row-gap: 8px;
`;

