import styled from 'styled-components';
import { TextField as MUIInput } from '@mui/material';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 2 / span 2;
`;

export const RebrandInput = styled(MUIInput)<{ $gridColumn?: string; multiline?: boolean; $width?: string }>`
  grid-column: ${({ $fullWidth }) => ($fullWidth ? 'span 6' : '2 / span 3')};
  background-color: var(--color-primary-white);
  width: ${({ $width }) => $width ?? '100%'};
  border-radius: 6px;

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--color-greyscale-grey-65);
    border-radius: 6px;
  }

  .MuiInputBase-input {
    height: auto;
    color: var(--color-greyscale-black);

    ::placeholder {
      color: var(--color-greyscale-grey-50);
      opacity: 1;
    }
  }

  .MuiInputBase-multiline {
    height: auto;
    padding: var(--sizes-40) var(--sizes-50);

    textarea {
      padding: 0;
    }
  }

  .MuiInputBase-adornedStart,
  .MuiInputBase-adornedEnd {
    padding-left: var(--sizes-50);
    padding-right: var(--sizes-50);

    .MuiInputBase-input {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .MuiInputAdornment-root {
    .MuiTypography-root {
      color: var(--color-greyscale-grey-65);
    }
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--color-greyscale-black);
  }

  & .MuiInputAdornment-positionEnd .MuiTypography-root {
    font: var(--font-extra-small-12pt-bold);
  }

  & .Mui-focused .MuiInputAdornment-root {
    .MuiTypography-root {
      color: var(--color-greyscale-black);
    }
  }

  .Mui-disabled {
    background-color: var(--color-greyscale-grey-95);
    border-radius: 6px;
    :hover {
      cursor: not-allowed;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  legend {
    width: 0;
  }
`;
