import React from 'react';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { ScaleIcon } from 'assets/icons/settings/ScaleIcon';
import { ScaleSettingsProvider } from './components/ScaleSettingsProvider';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { ScaleTestListItem } from './components/ScaleTestListItem';
import { ScaleStatusBanner } from './components/ScaleStatusBanner';
import { ScaleStatusPill } from './components/ScaleStatusPill';
import { ScaleSelectListItem } from './components/ScaleSelectListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { PeripheralType } from '@dutchie/capacitor-hardware';
import { ScaleConfigureSelectedSerialListItem } from './components/ScaleConfigureSelectedSerialListItem';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const ScaleSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  return (
    <>
      <SettingsListItem
        automationId='scale_settings'
        icon={<ScaleIcon />}
        title='Scale'
        subtitle='Settings for a scale'
        onClick={showDrawer}
        actions={[<ScaleStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='scale_settings-drawer'
        title='Scale'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <ScaleSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <ScaleStatusBanner />
            <ScaleSelectListItem />
            <SettingsDivider />
            <ScaleTestListItem />
            <ScaleConfigureSelectedSerialListItem />
          </SettingsCard>
          <AddDeviceListItem deviceType={PeripheralType.scale} />
        </ScaleSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
