import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { FormikProps } from 'formik';

import { CustomerDetails } from 'models/Customer';
import { PersonalTab, MedicalTab, HistoryTab, JournalTab, PatientPolicyTab } from '../tabs';
import { SideTabPage } from 'components/layout/SideTabContainer';

import type { CustomerValidationSchema } from './useFormValidation';
import type { LocationState } from '../types';
import type { State } from 'store';

type UseSideTabsParams = {
  setCustomerTypeId: Dispatch<SetStateAction<string | null>>;
  formSchema?: CustomerValidationSchema;
  formRef: MutableRefObject<FormikProps<CustomerDetails> | null>;
};
export const useSideTabs = ({ setCustomerTypeId, formSchema, formRef }: UseSideTabsParams) => {
  const validatedForms = useSelector((state: State) => state.settings.validatedForms);
  const customerDetails = useSelector((state: State) => state.customer.details);
  const isRequirePolicySignatureEnabled = useSelector((state: State) => state.settings.features.RequirePolicySignature);

  const location = useLocation<LocationState>();
  const search = queryString.parse(location.search);
  const guestId = Number(search.id) || undefined;

  const sideTabPages: Array<SideTabPage> = [
    {
      label: 'Personal',
      component: (
        <PersonalTab
          customer={customerDetails}
          fieldOptions={validatedForms?.['Customer Profile (POS)']}
          setCustomerTypeId={setCustomerTypeId}
          validationSchema={formSchema?.describe()}
        />
      ),
    },
    {
      label: 'Medical',
      hidden: customerDetails?.IsAnonymous,
      component: (
        <MedicalTab
          customer={customerDetails}
          fieldOptions={validatedForms?.['Customer Profile (POS)']}
          prescriptionFieldOptions={validatedForms?.['Customer Prescription']}
          validationSchema={formSchema?.describe()}
          formRef={formRef}
        />
      ),
    },
  ];

  if (guestId) {
    sideTabPages.push({
      label: 'History',
      component: <HistoryTab guestId={guestId} />,
      tabContentNoPadding: '0 0 0 17px',
    });
    sideTabPages.push({
      label: 'Journal',
      component: <JournalTab guestId={guestId} disabled={customerDetails?.IsAnonymous} />,
    });

    if (isRequirePolicySignatureEnabled) {
      sideTabPages.push({
        label: 'Patient Policy',
        component: <PatientPolicyTab guestId={guestId} disabled={customerDetails?.IsAnonymous} />,
      });
    }
  }

  const policyTabIndex = sideTabPages.findIndex((x) => x.label === 'Patient Policy');

  return {
    sideTabPages,
    policyTabIndex,
  };
};
