import { DutchiePayInvoiceConfirmationNotification } from 'models/Checkout';
import { PusherChannelType } from 'models/Pusher';
import { PusherChannel } from 'components/PusherChannel';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, store } from 'store';
import { cleanDutchiePayPusherConfiguration, getDutchiePayPusherConfiguration } from 'store/actions/CheckoutActions';
import { useOnDutchiePayPusherMessage } from './useOnDutchiePayPusherMessage';
import { getIsAblyRolloutEnabled } from 'util/hooks/launch-darkly/useAblyRollout';
import { AblyChannel } from 'messaging/ably/useAblyChannel';
import { MessagingChannelType } from 'models/Messaging';

/** @deprecated Clean up with usePusherChannel hook */
export type DutchiePayPusherChannelProps = {
  onPusherMessageCallback: (notification: DutchiePayInvoiceConfirmationNotification | null) => Promise<void>;
};

/** @deprecated Clean up with usePusherChannel hook */
export const DutchiePayPusherChannel = ({ onPusherMessageCallback }: DutchiePayPusherChannelProps) => {
  const pusherChannelConfig = useSelector((state: State) => state.checkout.dutchiePayPusherConfig);
  const dispatch = useDispatch<typeof store.dispatch>();
  const onPusherMessage = useOnDutchiePayPusherMessage(onPusherMessageCallback);
  const ablyRollout = getIsAblyRolloutEnabled();

  useEffect(() => {
    dispatch(cleanDutchiePayPusherConfiguration());
    dispatch(getDutchiePayPusherConfiguration());
  }, [dispatch]);

  return (
    <>
      {pusherChannelConfig && (
        <PusherChannel
          enabled={true}
          channelConfig={pusherChannelConfig}
          channelType={PusherChannelType.DutchiePayInStore}
          onChannelMessage={onPusherMessage}
        />
      )}
      {ablyRollout && (
        <AblyChannel
          channelType={MessagingChannelType.DutchiePayInStore}
          channelMessageCallback={onPusherMessage}
        />
      )}
    </>
  );
};
