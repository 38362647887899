import React from 'react';
import styled, { css } from 'styled-components';

import { BoxProps, Box } from './Box';

type FlexProps = BoxProps & {
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
  flex?: React.CSSProperties['flex'];
  direction?: React.CSSProperties['flexDirection'];
  wrap?: React.CSSProperties['flexWrap'];
  grow?: React.CSSProperties['flexGrow'];
  shrink?: React.CSSProperties['flexShrink'];
  basis?: React.CSSProperties['flexBasis'];
  color?: React.CSSProperties['color'];
  gap?: React.CSSProperties['gap'];
};

export const Flex = styled(Box)<FlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
    ${({ wrap }) =>
    wrap &&
    css`
      flex-wrap: ${wrap};
    `}
    ${({ grow }) =>
    grow &&
    css`
      flex-grow: ${grow};
    `}
    ${({ shrink }) =>
    shrink &&
    css`
      flex-shrink: ${shrink};
    `}
    ${({ basis }) =>
    basis &&
    css`
      flex-basis: ${basis};
    `}
    ${({ gap }) =>
    !!gap &&
    css`
      gap: ${typeof gap === 'number' ? `${gap}px` : gap};
    `}
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;
