import React, { FC } from 'react';
import { callback } from 'models/Misc';
import { ConfirmationModal } from 'components/modals/ConfirmationModal';
import { ModalVariation } from 'components/modals/Modal';

type ConfirmOpenCashDrawerPopupProps = {
  onCancel: callback;
  hide: callback;
};

export const ConfirmHubBackClickPopup: FC<ConfirmOpenCashDrawerPopupProps> = ({ onCancel, hide }) => {
  return (
    <ConfirmationModal
      variation={ModalVariation.Standard}
      hide={hide}
      header='Cancel transaction?'
      modalName='hub-back-click'
      message='Returning to edit the cart will cancel the current payment request on the customer terminal.'
      rightButton={{
        text: 'Continue transaction',
        onClick: hide,
        automationId: 'hub-confirm-cancel-modal_continue-in-flight-transaction-button',
      }}
      leftButton={{
        text: 'Cancel transaction',
        transparentRed: true,
        onClick: () => {
            onCancel();
            hide();
        },
        automationId: 'hub-confirm-cancel-modal_cancel-transaction-button',
      }}
    />
  );
};
