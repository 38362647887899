import React, { FC, useState, useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { State } from 'store';
import { addPaymentMethod, digitalPayment } from 'store/actions/CheckoutActions';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { PaymentMethod, PaymentType } from 'models/Checkout';
import { PaymentOption } from '../PaymentOption';
import { PaymentShortcuts } from './PaymentShortcuts';
import { CheckoutContext } from '../CheckoutContext';

export const PaymentCredit: FC = () => {
  const dispatch = useDispatch();
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const usePlanetPayments = useSelector((state: State) => state.settings.integrations?.UsePlanetPayments);
  const { paymentSummary, setPaymentProcessing, setSelectedPaymentType } = useContext(CheckoutContext);
  const credit = paymentSummary[PaymentType.Credit];
  const [transactionAmount, setTransactionAmount] = useState<number>();
  const caption = usePlanetPayments ? 'Exact Card' : 'Exact Credit';

  useEffect(() => {
    setPaymentProcessing(digitalPaymentLoading);
    if (transactionAmount && !digitalPaymentLoading && credit >= transactionAmount) {
      setSelectedPaymentType(null);
    }
  }, [digitalPaymentLoading, credit, transactionAmount, setPaymentProcessing, setSelectedPaymentType]);

  const onAddCredit = useMemo(
    () => (amount: number) => {
      if (digitalPaymentLoading) {
        dispatch(errorNotification('The transaction is currently being processed. Please be patient as it completes.'));
        return;
      }

      if (amount <= 0) {
        dispatch(errorNotification('Invalid credit amount.'));
        return;
      }

      setTransactionAmount(amount);

      if (amount > 0 && !usePlanetPayments) {
        dispatch(addPaymentMethod({ id: uuidv4(), name: 'Credit', type: PaymentType.Credit, amount }));
        dispatch(successNotification('Credit Payment added'));
      } else {
        const paymentMethod: PaymentMethod = {
          id: uuidv4(),
          name: 'Card',
          PaymentProviderType: 'planetpayments',
          // This might eventually be Debit when payment completes, based on what card the
          // customer swipes. The type will updated when the polling completes
          type: PaymentType.Credit,
          amount,
          finalized: true,
        };

        dispatch(digitalPayment(paymentMethod));
      }
    },
    [digitalPaymentLoading, dispatch, usePlanetPayments]
  );

  return (
    <PaymentOption onAddAmount={onAddCredit}>
      <PaymentShortcuts shortcuts={[{ name: caption }]} onAddAmount={onAddCredit} />
    </PaymentOption>
  );
};
