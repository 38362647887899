import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { warningNotification } from 'store/actions/NotificationsActions';
import { State } from 'store';
import { Vehicle, VehicleFilterOption } from 'models/Vehicle';
import * as DeliveryApi from 'api/DeliveryApi';

export const applyVehicleFilters = createAction<Array<VehicleFilterOption>>('applyVehicleFilters');
export const setSelectedVehicles = createAction<Array<Vehicle>>('setSelectedVehicles');

export const getVehicles = createAsyncThunk('getVehicles', async (body: void, { getState, dispatch }) => {
  let response: Vehicle[] = [];
  try {
    response = await DeliveryApi.getVehicles();
  } catch {
    dispatch(warningNotification('Unable to load fleet'));
  }

  const { settings } = getState() as State;

  return { response, settings };
});

export const setVehicleSearchQuery = createAction<string>('setQuery');
export const setVehicleFilterQuery = createAction<string>('setFilter');
