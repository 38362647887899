import React, { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { callback, callback1 } from 'models/Misc';
import { saveCheckoutSignature } from 'api/CheckoutApi';
import { Popup } from 'components/popups';
import { SignatureCanvas, PointSet, SignatureData, checkIsValidSignature } from 'components/misc';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { colors, popupWidth } from 'css/Theme';
import { Button } from 'components/buttons';
import { post } from 'api/HttpHelpers';
import { useCartPopups } from 'components/CartPopups';
import { Loader } from 'components/backoffice/loader';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

const StyledPopup = styled(Popup)`
  width: ${popupWidth.medium};
  & svg[data-testid='popup_popup-body-div_close-icon'] {
    right: unset;
    top: 1rem;
    left: 1rem;
  }
  .view-policy {
    &__canvas {
      height: 200px;
      width: 100%;
      border-bottom: 1px solid ${colors.dutchie.shadowGrey};
    }
  }
`;

const SaveButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  height: 35px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin: 0.75rem 0 1rem;
  padding: 0 1rem;
  & p {
    color: ${colors.grey};
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: ${colors.blue};
  background: none;
  border: none;
`;

type DigitalManifestSignaturePopupProps = {
  hide: callback;
  setSignatureSaved: callback1<boolean>;
  shipmentId: number;
  signatureData?: PointSet;
};

export const DigitalManifestSignaturePopup: FC<DigitalManifestSignaturePopupProps> = ({
  hide,
  shipmentId,
  setSignatureSaved,
  signatureData,
}) => {
  const dispatch = useDispatch();
  const cartPopups = useCartPopups();
  const [sigData, setSigData] = useState<SignatureData>();
  const [isSignatureValid, setIsSignatureValid] = useState<boolean>(false);
  const [isSavingManifestSignature, setIsSavingManifestSignature] = useState<boolean>(false);
  const [isLoadingManifest, setIsLoadingManifest] = useState<boolean>(false);
  const [manifestImageSrc, setManifestImageSrc] = useState<string>('');

  const handleSave = useCallback(
    async ({ shouldHide = false, shouldNotify = false }) => {
      setIsSavingManifestSignature(true);
      try {
        await saveCheckoutSignature({
          ShipmentId: shipmentId,
          Signature: sigData?.imgDataURI.split(',')[1] ?? '',
        });
        setSignatureSaved(true);
        shouldNotify && dispatch(successNotification('Signature saved'));
        shouldHide && hide();
      } catch (err) {
        dispatch(errorNotification('Could not save signature'));
      }
      setIsSavingManifestSignature(false);
    },
    [dispatch, hide, setSignatureSaved, shipmentId, sigData]
  );

  const handleViewManifestClick = async () => {
    setIsLoadingManifest(true);
    await handleSave({});
    const data = await post<string>('v2/manifest/get-manifest-bytes', { PosId: shipmentId });
    const imgSrc = `data:image/png;base64, ${data}`;
    setManifestImageSrc(imgSrc);
    setIsLoadingManifest(false);
  };

  const handleClose = () => {
    dispatch(warningNotification('Customer Signature Cleared'));
    setSignatureSaved(false);
    hide();
  };

  useEffect(() => {
    if (manifestImageSrc.length > 0 && sigData) {
      cartPopups.showDigitalManifestPopup({
        setSignatureSaved: setSignatureSaved,
        manifestLink: manifestImageSrc,
        signatureData: sigData.pointSet,
      });
    }
  }, [cartPopups, manifestImageSrc, setSignatureSaved, sigData]);

  useEffect(() => {
    if (signatureData) {
      setSigData({ pointSet: signatureData, imgDataURI: '' });
      const isValid = checkIsValidSignature(signatureData);
      setSignatureSaved(isValid);
      setIsSignatureValid(isValid);
    }
  }, [setSignatureSaved, setIsSignatureValid, signatureData]);

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <StyledPopup isVisible hide={handleClose} canClose={true} caption='Customer Signature'>
      {!isModalBridgeEnabled && (
        <SaveButton onClick={() => handleSave({ shouldHide: true, shouldNotify: true })} disabled={!isSignatureValid}>
          Save
        </SaveButton>
      )}
      <SignatureCanvas
        className='view-policy__canvas'
        data={sigData?.pointSet}
        onChange={(data) => {
          setSigData(data);
          setIsSignatureValid(checkIsValidSignature(data.pointSet));
        }}
      />
      <FlexContainer>
        <p>I acknowledge my signature will be applied to the deliver manifest.</p>
        {isSavingManifestSignature || isLoadingManifest ? (
          <Loader variant='black' />
        ) : (
          <LinkButton onClick={handleViewManifestClick}>View Manifest</LinkButton>
        )}
        {isModalBridgeEnabled && (
          <Button onClick={() => handleSave({ shouldHide: true, shouldNotify: true })} disabled={!isSignatureValid}>
            Save
          </Button>
        )}
      </FlexContainer>
    </StyledPopup>
  );
};
