import React, { FC } from 'react';
import styled from 'styled-components';
import { callback } from 'models/Misc';
import { ConfirmationPopup } from 'components/popups';

const StyledDiv = styled.div`
  padding: 0 32px;
`;

type VersionCheckerPopupProps = {
  hide: callback;
};

export const VersionCheckerPopup: FC<VersionCheckerPopupProps> = ({ hide }) => {
  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Refresh Application'
      confirm={{
        text: 'OK',
        onClick: () => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
          hide();
        },
      }}
    >
      <StyledDiv>
        There is a new version available for the Point of Sale. Please refresh to apply the updates.
      </StyledDiv>
    </ConfirmationPopup>
  );
};
