import { HARDWARE_LIBRARY_STORAGE_KEYS } from './hardware-library-utils';
import { AppDispatch } from 'store';
import { setStorage } from 'store/actions/StorageActions';
import { HardwareService, Peripheral } from '@dutchie/capacitor-hardware';
import { isAndroid } from 'util/hooks';
import { Storage } from '@capacitor/storage';
import {
  LABEL_PRINTER_LOCAL_STORAGE_KEYS,
  RECEIPT_PRINTER_LOCAL_STORAGE_KEYS,
  FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS,
} from 'util/local-printing';

export class HardwareLibraryStorageManager {
  private dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  get fulfillmentPrinterId(): string | null {
    return localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.FULFILLMENT_PRINTER_ID);
  }

  set fulfillmentPrinterId(id: string | null) {
    if (this.fulfillmentPrinterId !== id) {
      this.setItem(HARDWARE_LIBRARY_STORAGE_KEYS.FULFILLMENT_PRINTER_ID, id);
      this.setAndroidObject(
        FULFILLMENT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT,
        id === null ? undefined : HardwareService.receiptPrinter.deviceById(id)
      );
    }
  }

  get labelPrinterId(): string | null {
    return localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.LABEL_PRINTER_ID);
  }

  set labelPrinterId(id: string | null) {
    if (this.labelPrinterId !== id) {
      this.setItem(HARDWARE_LIBRARY_STORAGE_KEYS.LABEL_PRINTER_ID, id);
      this.setAndroidObject(
        LABEL_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT,
        id === null ? undefined : HardwareService.labelPrinter.deviceById(id)
      );
    }
  }

  get receiptPrinterId(): string | null {
    return localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.RECEIPT_PRINTER_ID);
  }

  set receiptPrinterId(id: string | null) {
    if (this.receiptPrinterId !== id) {
      this.setItem(HARDWARE_LIBRARY_STORAGE_KEYS.RECEIPT_PRINTER_ID, id);
      this.setAndroidObject(
        RECEIPT_PRINTER_LOCAL_STORAGE_KEYS.SELECTED_OBJECT,
        id === null ? undefined : HardwareService.receiptPrinter.deviceById(id)
      );
    }
  }

  get scaleId(): string | null {
    return localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.SCALE_ID);
  }

  set scaleId(id: string | null) {
    if (this.scaleId !== id) {
      this.setItem(HARDWARE_LIBRARY_STORAGE_KEYS.SCALE_ID, id);
    }
  }

  get scannerId(): string | null {
    return localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.SCANNER_ID);
  }

  set scannerId(id: string | null) {
    if (this.scannerId !== id) {
      this.setItem(HARDWARE_LIBRARY_STORAGE_KEYS.SCANNER_ID, id);
    }
  }

  // Store the Android object. This replaces what is done in the SettingsReducer.
  private setAndroidObject(key: string, device: Peripheral | undefined) {
    if (!isAndroid) {
      return;
    }

    if (device) {
      Storage.set({
        key: key,
        value: JSON.stringify({ PrinterId: device.id, Name: device.name, LocalPrinter: true }),
      });
    } else {
      Storage.remove({ key: key });
    }
  }

  // Save item to local storage and update Redux storage state
  private setItem(key: string, value: string | null) {
    if (value === null) {
      localStorage.removeItem(key);
      this.dispatch(setStorage({ key, value, storageType: 'local' }));
    } else {
      localStorage.setItem(key, value);
      this.dispatch(setStorage({ key, value, storageType: 'local' }));
    }
  }
}
