import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { Peripheral, ReceiptPrinter } from '@dutchie/capacitor-peripheral';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { SectionGridContainer, SectionHeader, SectionContent } from '../../SettingPageComponents';
import { Switch } from 'components/inputs';
import { LocalFulfillmentPicker } from './LocalFulfillmentPicker';
import { NetworkFulfillmentPicker } from './NetworkFulfillmentPicker';
import { isAndroid } from 'util/hooks';
import { switchPrinterSettings } from '../utils';
import { logger, customEventKeys } from 'util/logger';
import {
  AutoConnectedToPrinterContext,
  SearchReceiptPrintersContext,
  UserChangedPrinterSettingsContext,
} from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { getSearchReceiptPrintersDescription } from 'util/logger/helpers/printing';

export const FulfillmentPrinterPicker: FC = () => {
  const dispatch = useDispatch();
  const { receiptPrinters } = useSelector((state: State) => state.settings);
  const { useConnectedFulfillmentPrinter } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintFulfillmentTicket } = useSelector((state: State) => state.settings.userSettings);
  const [connectedFulfillmentPrinters, setConnectedFulfillmentPrinters] = useState<ReceiptPrinter[]>([]);

  useEffect(() => {
    const setLocalPrinters = async () => {
      if (isAndroid) {
        const { results } = await Peripheral.searchReceiptPrinters();
        setConnectedFulfillmentPrinters(results);

        logger.info<SearchReceiptPrintersContext>(getSearchReceiptPrintersDescription(results, false), {
          key: customEventKeys.printing.local.receipt.search,
          availablePrinters: results,
          userInitiated: false,
        });
      }
    };

    setLocalPrinters();
  }, []);

  const handleSwitchFulfillmentPrinterTypes = (useConnectedFulfillmentPrinter: boolean) => {
    const newPrinterSettings = switchPrinterSettings(
      useConnectedFulfillmentPrinter,
      connectedFulfillmentPrinters,
      receiptPrinters
    );

    const newUserSettings = newPrinterSettings
      ? {
          useConnectedFulfillmentPrinter,
          selectedFulfillmentPrinter: newPrinterSettings,
        }
      : { useConnectedFulfillmentPrinter };

    dispatch(saveUserSettings(newUserSettings));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled pick ticket printer to ${useConnectedFulfillmentPrinter ? 'local' : 'network'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.PICK_TICKET,
      printerSettings: { isLocalPrinter: useConnectedFulfillmentPrinter },
    });

    if (newPrinterSettings) {
      logger.info<AutoConnectedToPrinterContext>(`auto-connected to ${newPrinterSettings.LocalPrinter ? 'local' : 'network'} pick ticket printer ${newPrinterSettings.PrinterId}`, {
        key: customEventKeys.printing.autoConnected,
        job: PrintJob.PICK_TICKET,
        type: newPrinterSettings.LocalPrinter ? 'local' : 'network',
        autoSelectedPrinterId: newPrinterSettings.PrinterId,
      });
    }
  };

  const handleSwitchAutoPrintFulfillmentTickets = (autoPrintFulfillmentTicket: boolean) => {
    dispatch(saveUserSettings({ autoPrintFulfillmentTicket }));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled auto printing for pick ticket printer ${autoPrintFulfillmentTicket ? 'ON' : 'OFF'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.PICK_TICKET,
      printerSettings: { isAutoPrintEnabled: autoPrintFulfillmentTicket },
    });
  };

  const printerPicker =
    useConnectedFulfillmentPrinter && isAndroid ? (
      <LocalFulfillmentPicker
        connectedReceiptPrinters={connectedFulfillmentPrinters}
        setConnectedReceiptPrinters={setConnectedFulfillmentPrinters}
      />
    ) : (
      <NetworkFulfillmentPicker />
    );

  return (
    <SectionGridContainer>
      <SectionHeader>Fulfillment Tickets</SectionHeader>
      <SectionContent>
        <Switch
          label='Auto Print Fulfillment Ticket'
          onChange={handleSwitchAutoPrintFulfillmentTickets}
          checked={autoPrintFulfillmentTicket}
        />
        {isAndroid && (
          <Switch
            label='Use Local Fulfillment Printer'
            onChange={handleSwitchFulfillmentPrinterTypes}
            checked={useConnectedFulfillmentPrinter}
          />
        )}
        {printerPicker}
      </SectionContent>
    </SectionGridContainer>
  );
};
