import React from 'react';

import {
  PeripheralSetupConnectionStatus,
  ConnectionStatusBanner,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { isKeyboardScale } from '@dutchie/capacitor-hardware';
import { useScaleSettings } from '../hooks/useScaleSettings';

export const ScaleStatusBanner = () => {
  const { formValues, scales } = useScaleSettings();

  const selectedScale = scales.find((scale) => scale.id === formValues.scaleId);

  if (!selectedScale) {
    return null;
  }

  if (isKeyboardScale(selectedScale)) {
    const isWindows = navigator.userAgent.includes('Windows');
    const customText = isWindows
      ? 'Default scale connected, but requires 232key app for measurements.'
      : 'Default scale connected, but may require additional setup.';
    return (
      <ConnectionStatusBanner
        deviceTypeName='Scale'
        status={PeripheralSetupConnectionStatus.additionalSetupRequired}
        customText={customText}
      />
    );
  }

  return (
    <ConnectionStatusBanner
      deviceTypeName='Scale'
      deviceName={selectedScale.name}
      status={PeripheralSetupConnectionStatus.connected}
    />
  );
};
