import React from 'react';

import { Container, Header, Title, StyledLinkButton, LockedMessage } from 'components/cart/TotalsSidebarSection.styles';
import { LockedIcon } from 'assets/icons/cart/LockedIcon';
import { useCustomerInsights } from './useCustomerInsights';

export type CustomerInsightsProps = {
  isLoading?: boolean;
  showBottomBorder?: boolean;
};

export const CustomerInsights = ({ isLoading, showBottomBorder = true }: CustomerInsightsProps) => {
  const { handleOnClick, isDisabled, linkLabel, lockedMessage, title } = useCustomerInsights({
    isLoading,
  });

  return (
    <Container showBottomBorder={showBottomBorder} gap='0.5rem' data-testid='customer-insights-container'>
      <Header style={{ padding: '0.5rem 0' }}>
        <Title>{title}</Title>
        {linkLabel && <StyledLinkButton onClick={handleOnClick} label={linkLabel} disabled={isDisabled} />}
      </Header>
      {lockedMessage && (
        <LockedMessage data-testid='customer-insights_locked-message'>
          {lockedMessage}
          <LockedIcon />
        </LockedMessage>
      )}
    </Container>
  );
};
