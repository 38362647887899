import React from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsSelect } from 'pages/SettingsPage/Select/SettingsSelect';
import { useLabelPrinterSettings } from '../hooks/useLabelPrinterSettings';

export const DefaultLabelSelectListItem = () => {
  const { formValues, labelOptions, refreshLabels, setDefaultLabel } = useLabelPrinterSettings();

  return (
    <SettingsListItem
      title='Default label'
      subtitle='Select label to be printed by default'
      actions={[
        <SettingsSelect
          automationId='label_printer_settings-default_label_select'
          value={formValues.defaultLabelId}
          placeholder='Select default label'
          onChange={(option) => setDefaultLabel(option?.value)}
          onRefresh={async () => refreshLabels()}
          options={labelOptions}
        />,
      ]}
    />
  );
};
