import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { getGuests } from 'store/actions/GuestListActions';
import { setTransactionReference } from 'store/actions/CustomerActions';
import { Input } from 'components/inputs';
import { callback } from 'models/Misc';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

import type { CheckedInGuest } from 'models/Guest';

const InputField = styled(Input)`
  flex: 2 0;
`;
const Fieldset = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 1.5rem;
`;

type SetTransactionReferencePopupProps = {
  hide: callback;
  guest?: CheckedInGuest;
};

export const SetTransactionReferencePopup: FC<SetTransactionReferencePopupProps> = ({ hide, guest }) => {
  const dispatch = useDispatch();

  const {
    data: { ShipmentId, TransactionReference },
    refetch: refetchCart,
  } = useGetCartDetails();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  // Set up the fallback values if the CheckedInGuest object is not provided
  // That indicates this was opened from the cart page
  const shipmentIdToUse = guest?.ShipmentId ?? ShipmentId ?? 0;
  const transactionReferenceToUse = guest?.TransactionReference ?? TransactionReference;

  const [transactionReference, enterTransactionReference] = useState(transactionReferenceToUse || '');
  const [loading, setLoading] = useState(false);

  const isSmartCheckinActive = useSmartCheckinActive();

  const onConfirm = async () => {
    setLoading(true);

    await dispatch(
      setTransactionReference({
        ShipmentId: shipmentIdToUse,
        TransactionReference: transactionReference,
      })
    );

    if (isAnonymousCartLDFlagEnabled) {
      await refetchCart();
    }

    if (!isSmartCheckinActive) {
      await dispatch(getGuests());
    }
    setLoading(false);
    hide();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => enterTransactionReference(e.target.value);

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title={`Transaction Reference`}
      confirm={{
        text: 'Save',
        loading: loading,
        disabled: loading,
        onClick: () => onConfirm(),
      }}
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
    >
      <Fieldset>
        <InputField value={transactionReference} onChange={handleChange} />
      </Fieldset>
    </ConfirmationPopup>
  );
};
