import { useState } from 'react';

import { getInitialNumberValue } from '../helpers';
import { openCashDetailsPanel, resetCashDetails } from 'store/actions/CashManagementActions';
import { registerWithdrawal } from 'api/SettingsApi';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch } from 'util/hooks';
import { usePopup } from 'components/popups';
import { useTransactionAdjustmentReasonsQuery } from 'queries/posv3/maintenance/get-txn-adjustment-reasons';

import type { WithdrawProps } from './Withdraw';

export const useWithdraw = ({ onWithdraw, registerId }: WithdrawProps) => {
  const dispatch = useAppDispatch();

  const [cashCounted, setCashCounted] = useState(getInitialNumberValue());
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState('');
  const [transactionAdjustmentReason, setTransactionAdjustmentReason] = useState('');

  const { isVisible: isConfirmPopupVisible, toggle: toggleConfirmPopup } = usePopup();
  const { data: transactionAdjustmentReasons } = useTransactionAdjustmentReasonsQuery();

  // Computed values

  const transactionAdjustmentReasonOptions =
    transactionAdjustmentReasons?.map((reason) => ({
      key: reason.TransactionAdjustmentReasonId,
      value: reason.TransactionAdjustmentReasonId,
      label: reason.Reason,
    })) ?? [];

  // Handlers

  const handleClickEnterCashDetails = () => dispatch(openCashDetailsPanel());

  const handleWithdraw = async () => {
    if (cashCounted.floatValue) {
      try {
        setIsLoading(true);
        await registerWithdrawal({
          TransactionAmt: cashCounted.floatValue,
          Comment: note,
          RegisterId: registerId,
          TransactionAdjustmentReasonId: transactionAdjustmentReason ? Number(transactionAdjustmentReason) : undefined,
        });
        setCashCounted(getInitialNumberValue());
        dispatch(resetCashDetails());
        setNote('');
        setTransactionAdjustmentReason('');
        onWithdraw();
        dispatch(successNotification('Cash withdrawn'));
        setIsLoading(false);
      } catch {
        dispatch(errorNotification('There was an error withdrawing cash'));
        setIsLoading(false);
      }
    }
  };

  const isWithdrawDisabled = !cashCounted.value.length || isLoading;

  return {
    cashCounted,
    handleClickEnterCashDetails,
    handleWithdraw,
    isConfirmPopupVisible,
    isLoading,
    isWithdrawDisabled,
    note,
    setCashCounted,
    setNote,
    setTransactionAdjustmentReason,
    toggleConfirmPopup,
    transactionAdjustmentReason,
    transactionAdjustmentReasonOptions,
  };
};
