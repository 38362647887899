import { LoggingLevel, LoggingProvider } from './LoggingProvider';
import { ConsoleLoggingProvider } from './logging-providers/console';
import { DatadogLoggingProvider } from './logging-providers/datadog';
import { LogRocketLoggingProvider } from './logging-providers/logrocket';
import { getLDClient } from 'util/launchDarkly';

import type { LoggingProviderInitProps, UserData } from './LoggingProvider';
import type { Middleware } from '@reduxjs/toolkit';
import { BaseCustomEventData } from './customEventKeys';

export enum LoggingProviders {
  ALL = 'all',
  DATADOG = 'datadog',
  LOGROCKET = 'logrocket',
  NONE = 'none',
}

class Logger {
  private loggingProviders: LoggingProvider[] = [];

  /**
   * Initialize all logging providers
   * @param turnOnForDevelopment - If true, logging providers will be initialized even in development mode
   */
  public init = ({ turnOnForDevelopment }: LoggingProviderInitProps) => {
    // Add providers to use here from LD flag
    const ldClient = getLDClient();
    const providersToUse: LoggingProviders = ldClient?.variation(
      'pos.register.logging-providers',
      LoggingProviders.ALL
    );

    if (providersToUse === LoggingProviders.ALL || providersToUse === LoggingProviders.DATADOG) {
      this.loggingProviders.push(new DatadogLoggingProvider());
    }

    if (providersToUse === LoggingProviders.ALL || providersToUse === LoggingProviders.LOGROCKET) {
      this.loggingProviders.push(new LogRocketLoggingProvider());
    }

    const showLogsInConsole = localStorage.getItem('showLogsInConsole') === '1' || process.env.REACT_APP_SHOW_LOGS_IN_CONSOLE === 'true';
    if (showLogsInConsole) {
      this.loggingProviders.push(new ConsoleLoggingProvider());
    }

    // Initialize all logging providers
    this.loggingProviders.forEach((loggingProvider) => {
      loggingProvider.init({ turnOnForDevelopment });
    });
  };

  private getProviders = (loggingProvidersToUse: LoggingProviders) => {
    if (loggingProvidersToUse === LoggingProviders.ALL) {
      return this.loggingProviders;
    }

    return this.loggingProviders.filter((loggingProvider) => {
      return loggingProvider.providerId === loggingProvidersToUse || loggingProvider.providerId === 'console';
    });
  };

  /**
   * Logs Redux actions to the selected logging providers
   * @param providersToUse - Pass in which logging providers to use for Redux logging (Default: `logrocket`)
   */
  public getAllMiddleware = (providersToUse: LoggingProviders = LoggingProviders.LOGROCKET): Middleware[] => {
    return this.getProviders(providersToUse).map((loggingProvider) => loggingProvider.getMiddleware());
  };

  /**
   * Sets a global property to be included in all logs
   * @param key the name of the property
   * @param value the value of the property
   * @param providersToUse the logging providers to use (Default: `all`)
   */
  public setGlobalProperty = (key: string, value: unknown, providersToUse: LoggingProviders = LoggingProviders.ALL) => {
    this.getProviders(providersToUse).forEach((loggingProvider) => {
      loggingProvider.setGlobalProperty(key, value);
    });
  };

  /**
   * Identifies a user to the selected logging providers
   * @param provider Specify which logging provider to set the user for
   * @param userId the string id of the user
   * @param userData any additional data to track with the user
   */
  public setUser = (provider: LoggingProviders, userId: string, userData?: UserData) => {
    this.getProviders(provider).forEach((loggingProvider) => {
      loggingProvider.setUser(userId, userData);
    });
  };

  /**
   * INFO should be used for main events
   *
   * @param customEventKey a custom event key to track
   * @param data any additional data to track
   * @param providersToUse the logging providers to use (Default: `all`)
   */
  public info = <CustomEventData extends object>(
    customEventDescription: string,
    data: CustomEventData & BaseCustomEventData,
    providersToUse: LoggingProviders = LoggingProviders.ALL
  ) => {
    this.getProviders(providersToUse).forEach((loggingProvider) => {
      loggingProvider.track(customEventDescription, { ...data, level: LoggingLevel.INFO });
    });
  };

  /**
   * DEBUG should be used extra logging that is too verbose for INFO
   * Not intended for unexpected behavior (see WARN)
   *
   * @param customEventKey a custom event key to track
   * @param data any additional data to track
   * @param providersToUse the logging providers to use (Default: `all`)
   */
  public debug = <CustomEventData extends object>(
    customEventDescription: string,
    data: CustomEventData & BaseCustomEventData,
    providersToUse: LoggingProviders = LoggingProviders.ALL
  ) => {
    this.getProviders(providersToUse).forEach((loggingProvider) => {
      loggingProvider.track(customEventDescription, { ...data, level: LoggingLevel.DEBUG });
    });
  };

  /**
   * WARN should be used for unexpected behavior (not errors, see ERROR)
   *
   * @param customEventKey a custom event key to track
   * @param data any additional data to track
   * @param providersToUse the logging providers to use (Default: `all`)
   */
  public warn = <CustomEventData extends object>(
    customEventDescription: string,
    data: CustomEventData & BaseCustomEventData,
    providersToUse: LoggingProviders = LoggingProviders.ALL
  ) => {
    this.getProviders(providersToUse).forEach((loggingProvider) => {
      loggingProvider.track(customEventDescription, { ...data, level: LoggingLevel.WARN });
    });
  };

  /**
   * Logs an error message to the selected logging providers
   * @param e the error to log
   * @param data any additional data to log
   * @param providersToUse the logging providers to use (Default: `all`)
   */
  public error = (e: unknown, data?: object, providersToUse: LoggingProviders = LoggingProviders.ALL) => {
    this.getProviders(providersToUse).forEach((loggingProvider) => {
      loggingProvider.error(e, data);
    });
  };
}

/**
 * To show logs in the console, type `localStorage.setItem('showLogsInConsole', '1');` in the inspector
 */
export const logger = new Logger();
