import React, { FC } from 'react';
import styled from 'styled-components';

import { FylloReward } from 'models/Fyllo';
import { Button } from 'components/buttons';
import { callback } from 'models/Misc';
import { DutchieLogoImage } from 'assets/Assets';
import { ImageAsset } from 'components/image';

const RewardCard = styled.div`
  padding: 10px;
  width: 100%;
  background: #ebebeb;
  display: inline-block;

  &:nth-child(odd) {
    background: white;
  }
`;

const RewardCardImg = styled.img`
  border: 3px solid black;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  float: left;
`;

const RewardCardImgAsset = styled(ImageAsset)`
  border: 3px solid black;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  float: left;
`;

const PointValue = styled.span<{ afford: boolean }>`
  color: ${({ afford }) => (afford ? '' : '#f00a0a')};
  float: right;
`;

const RewardCardDesc = styled.div`
  color: gray;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  min-height: 100px;
`;

const AddRewardButton = styled(Button)`
  float: right;
  vertical-align: bottom;
`;

type FylloRewardCardProps = {
  reward: FylloReward;
  balance: number;
  spentPoints: number;
  onAddReward: callback;
};

export const FylloRewardCard: FC<FylloRewardCardProps> = ({ reward, balance, spentPoints, onAddReward }) => {
  const canAfford = balance - spentPoints >= reward.points;
  const img = reward.image ? <RewardCardImg src={reward.image} /> : <RewardCardImgAsset asset={DutchieLogoImage} />;
  return (
    <RewardCard>
      <h3>{reward.name}</h3>
      <PointValue afford={canAfford}>Point Value:{reward.points}</PointValue>
      {img}
      <RewardCardDesc>{reward.description}</RewardCardDesc>
      <AddRewardButton disabled={!canAfford} onClick={onAddReward}>
        Add
      </AddRewardButton>
    </RewardCard>
  );
};
