import { createReducer } from '@reduxjs/toolkit';
import { Lsp, Location } from 'models/Location';
import {
  login,
  loginSamlEnabled,
  getPasswordSettingsSuccess,
  SelectLsp,
  logout,
  SelectLocationAction,
} from '../actions/UserActions';

export type UserState = {
  FullName: string;
  locations: Array<Location>;
  lsps: Array<Lsp>;
  selectedLocation?: Location;
  selectedLsp?: Lsp;
  username: string;
  enableSso: boolean;
  passwordSettings: PasswordSettings;
  UserId: number;
  orgId: number;
};

export type SSOLoginEnabled = {
  isSSOLoginEnabled: boolean;
};

export type PasswordSettings = {
  passwordLengthEnabled: false;
  passwordLength: number;
};

const initialState: UserState = {
  FullName: '',
  locations: [],
  lsps: [],
  selectedLocation: undefined,
  username: '',
  enableSso: false,
  passwordSettings: {
    passwordLengthEnabled: false,
    passwordLength: 8,
  },
  UserId: 0,
  orgId: 0,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(login, (state, { payload }) => {
    const location = payload.locations.find((l: Location) => l.location_id === payload.LocId);
    const lsp = payload.lsps.find((l: Lsp) => l.LspId === payload.LspId);
    const orgId = lsp?.OrgId ?? 0;
    window.sessionStorage.setItem('OrgId', orgId.toString());
    return {
      ...state,
      FullName: payload.FullName,
      username: payload.UserName,
      UserId: payload.UserId,
      locations: payload.locations,
      selectedLocation: location,
      selectedLsp: lsp,
      lsps: payload.lsps,
      orgId,
    };
  });
  builder.addCase(loginSamlEnabled, (state, { payload }) => {
    return {
      ...state,
      enableSso: payload.isSSOLoginEnabled,
    };
  });
  builder.addCase(getPasswordSettingsSuccess, (state, { payload }) => ({ ...state, passwordSettings: payload }));
  builder.addCase(SelectLsp.fulfilled, (state, { payload }) => {
    state.selectedLsp = payload.lsp;
    state.locations = payload.locs;
    state.selectedLocation = undefined;
  });
  builder.addCase(logout, (state) => ({ ...state, ...initialState }));
  builder.addCase(SelectLocationAction.fulfilled, (state, { payload }) => {
    state.selectedLocation = payload;
  });
});
