import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import type { IdentificationData } from './types';

export const useParseIdMutation = () => {
  return useMutation(
    async (id: string): Promise<IdentificationData> => {
      return post<IdentificationData>('v2/guest/parse-id', {
        ID: id,
      });
    }
  );
};
