import React, { FC, SVGProps, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Button } from './Button';

import type { ButtonProps } from './types';

type ExtendedButtonProps = Omit<ButtonProps, 'height' | 'width'> & {
  label?: string;
  side?: 'left' | 'right';
  height?: number;
  width?: number;
  large?: boolean;
};

export type ButtonWithIconProps = {
  icon: FC<SVGProps<SVGSVGElement>>;
} & ButtonHTMLAttributes<HTMLElement> &
  ExtendedButtonProps;

export const ButtonWithIcon: FC<ButtonHTMLAttributes<HTMLElement> & ButtonWithIconProps> = (props) => {
  const { icon: Icon, side = 'left', label, title, height, width, ...otherProps } = props;

  const tag = `${label ?? ''}${label && title && '-'}${title ?? ''}`;
  const automationId = `button_button-with-icon-base${tag ? `_${tag}` : ''}`;

  return (
    <StyledButtonWithIcon
      side={side}
      label={label}
      onClick={props.onClick}
      automationId={props.automationId ?? automationId}
      {...otherProps}
    >
      {side === 'left' && <Icon height={height ? height : 26} width={width ? width : 34} />}
      {label}
      {side === 'right' && <Icon />}
    </StyledButtonWithIcon>
  );
};

const StyledButtonWithIcon = styled(Button)<ExtendedButtonProps>`
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ large }) => (large ? '44px' : '32px')};
  min-width: 32px;
  ${({ side }) => side === 'right' && 'padding-left: 12px;'}

  & > svg {
    margin-right: ${({ label }) => (label ? 7 : 3)}px;
    margin-left: ${({ label }) => (label ? 7 : 3)}px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
