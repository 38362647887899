export type Asset = {
  src: string;
  alt: string;
};

export const DutchieHeroSvg: Asset = {
  src: require('./dutchie-hero.svg'),
  alt: 'Dutchie hero',
};

export const DutchieLogoImage: Asset = {
  src: require('./dutchie-logo.svg'),
  alt: 'Dutchie logo',
};

export const CanPayLogo: Asset = {
  src: require('./canpay-logo-small.png'),
  alt: 'CanPay',
};

export const HypurLogo: Asset = {
  src: require('./hypur-logo-small.png'),
  alt: 'CanPay',
};
