import { useRef, useEffect } from 'react';

type UseRageClickDetectorParams = {
  timeThreshold?: number;
  clickThreshold?: number;
  elementRef: React.RefObject<HTMLElement>;
  onRageClick: () => void;
};

type UseRageClickDetectorReturn = {
  elementRef: React.RefObject<HTMLElement>;
};

export function useRageClickDetector(params: UseRageClickDetectorParams): UseRageClickDetectorReturn {
  const { clickThreshold = 3, timeThreshold = 2000, elementRef, onRageClick } = params;

  const clickCountRef = useRef(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const element = elementRef.current;

    const reset = () => {
      clickCountRef.current = 0;
      timeoutRef.current = null;
    };

    const handleClick = () => {
      clickCountRef.current += 1;

      if (clickCountRef.current >= clickThreshold) {
        reset();
        onRageClick();
      } else if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(reset, timeThreshold);
      }
    };

    if (element) {
      element.addEventListener('click', handleClick);
    }

    return () => {
      if (element) {
        element.removeEventListener('click', handleClick);
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [clickThreshold, timeThreshold, onRageClick, elementRef]);

  return {
    elementRef,
  };
}
