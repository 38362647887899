/* eslint-disable @typescript-eslint/no-explicit-any */
import { chain, every } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { State, store } from 'store';
import { colors, shadows } from 'css/Theme';
import { Table, Column } from 'components/tables';
import { EmptyPlaceHolderCircle as _EmptyPlaceHolderCircle } from 'components/image';
import { Vehicle, VehicleFilterOption } from 'models/Vehicle';
import { FleetPageFilterMenu } from 'components/menus/FilterMenu/FilterMenu';
import { applyVehicleFilters, getVehicles } from 'store/actions/FleetActions';
import { getDeliveries } from 'store/actions/DeliveryActions';
import * as DeliveryApi from 'api/DeliveryApi';
import { ActionBar } from 'pages/FleetPage/ActionBar';
import { GreenPill, TransparentPill } from 'components/misc';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { ReactComponent as KebabMenuIcon } from 'assets/icons/kebab-menu.svg';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { ConfirmationPopup, usePopup } from 'components/popups';
import { getDeliveryDrivers } from 'store/actions/CheckoutActions';
import { Select } from 'components/inputs';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getFriendlyLongAgoString } from 'util/Helpers';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-alt.svg';
import { Heading2 } from 'components/backoffice/typography';
import { BottomDrawer, SearchContainer, Toolbar } from 'components/layout';

export const FleetPage: FC = () => {
  const selectedFiltersFromAppState = useSelector((state: State) => state.fleetList.filterSelections);
  const vehicles = useSelector((state: State) => state.fleetList.displayVehicles);
  const buildNumber = useSelector((state: State) => state.settings.buildNumber);
  const [sortState, setSort] = useState({
    isAlphaSort: false,
    alphaSortAscending: false,
  });
  const [columns, setColumns] = useState<Array<Column<Vehicle>>>([]);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const features = useSelector((state: State) => state.settings.features);
  const { isVisible, toggle } = usePopup();
  const [selectedCar, setSelectedCar] = useState<Vehicle>();
  const [selectedDriver, setSelectedDriver] = useState<number>();
  const drivers = useSelector((state: State) => state.checkout.drivers);

  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    dispatch(getVehicles());
    dispatch(getDeliveries());
    dispatch(getDeliveryDrivers());
  }, [dispatch]);

  const sortedVehicles = useMemo(() => {
    const { isAlphaSort, alphaSortAscending } = sortState;

    if (!vehicles) {
      return [];
    }

    if (isAlphaSort) {
      return (
        chain(Array.from(vehicles))
          // eslint-disable-next-line
          .orderBy((g) => g.CarModel.split(' ')[0].toLowerCase(), alphaSortAscending ? 'asc' : 'desc')
          .value()
      );
    }

    return (
      chain(Array.from(vehicles))
        // eslint-disable-next-line
        .value()
    );
  }, [vehicles, sortState]);

  const onApplyFilters = (filters: Array<VehicleFilterOption>) => {
    const filtersAreEmpty = every(filters, (filter) => filter.options.length === 0);
    dispatch(applyVehicleFilters(filtersAreEmpty ? [] : filters));
    setDrawerIsOpen(false);
  };

  const setOffline = useCallback(
    async (car: Vehicle) => {
      try {
        await DeliveryApi.setCarOffline({ CarId: car.CarId });
        dispatch(successNotification('Car is now offline.  Integrated menu no longer available'));
        dispatch(getVehicles());
      } catch (e) {
        dispatch(errorNotification(`Could not set car offline: ${e}`));
      }
    },
    [dispatch]
  );

  const openSetOnlinePopup = useCallback(
    (car: Vehicle) => {
      if (!car.IsConfiguredForDynamicDelivery) {
        dispatch(errorNotification(`This car isn't configured for dynamic delivery`));
        return;
      }

      setSelectedCar(car);
      setSelectedDriver(car.CurrentDriverId);
      toggle();
    },
    [dispatch, toggle]
  );

  const setOnline = useCallback(async () => {
    if (!selectedCar || !selectedDriver) {
      return;
    }

    try {
      await DeliveryApi.setCarOnline({ CarId: selectedCar.CarId, DriverId: selectedDriver });
      dispatch(successNotification('Car is now online and accepting orders from integrated menu'));
      await dispatch(getVehicles());
      toggle();
    } catch (e) {
      dispatch(errorNotification(`Could not set car online: ${e}`));
    }
  }, [selectedCar, selectedDriver, dispatch, toggle]);

  const getActionMenu = useCallback(
    (car: Vehicle) => {
      const menuOptions = [
        { text: car.CurrentDriverId ? 'Edit Driver' : 'Go Online', onClick: () => openSetOnlinePopup(car) },
      ];
      if (car.CurrentDriverId) {
        menuOptions.push({ text: 'Go Offline', onClick: () => setOffline(car) });
      }

      return (
        <OverflowMenu
          anchor={<KebabMenuIcon />}
          anchorProps={{ padding: '2px 1rem', showCursor: true }}
          menuOptions={menuOptions}
        />
      );
    },
    [openSetOnlinePopup, setOffline]
  );

  const getLastReportedString = useCallback((car: Vehicle) => {
    if (!car.CurrentDriverId) {
      return '';
    }

    if (!car.LastCoordinateTimeUtc) {
      return 'No coordinates';
    }

    const lastCoordinateDate = zonedTimeToUtc(car.LastCoordinateTimeUtc, 'GMT');
    return getFriendlyLongAgoString(lastCoordinateDate);
  }, []);

  const checkIsNegativeValue = (value: number, isWeight: boolean) => {
    if (Math.sign(value) === -1) {
      return 'No Overage';
    } else {
      return isWeight ? `${value.toFixed(2)}g` : `$${value.toFixed(2)}`;
    }
  };

  useEffect(() => {
    const [firstVehicle] = vehicles;

    const c: Array<Column<Vehicle>> = [
      { header: 'vehicle', field: (v: Vehicle) => `${v.CarMake} ${v.CarModel}`, width: '100' },
      { header: 'year', field: 'CarYear', width: '100' },
      { header: 'name', field: (v: Vehicle) => `${v.CarName}` },
      { header: 'cash on hand', field: (v: Vehicle) => `$${v.CashOnHand?.toFixed(2)}` },
    ];

    if (firstVehicle) {
      if (firstVehicle.DeliveryMaxICTInventoryDollars) {
        c.push({
          header: 'unordered inventory dollar value',
          field: (v: Vehicle) => `$${v.UnorderedInventory?.toFixed(2)}`,
        });
        c.push({
          header: 'unordered threshold dollar overage',
          field: (v: Vehicle) =>
            checkIsNegativeValue(-1 * (v.DeliveryMaxICTInventoryDollars - v.UnorderedInventory), false),
        });
      }
      if (firstVehicle.DeliveryMaxTotalInventoryDollars) {
        c.push({
          header: 'inventory dollar value',
          field: (v: Vehicle) => `$${(v.OrderedInventoryDollarValue + v.UnorderedInventory)?.toFixed(2)}`,
        });
        c.push({
          header: 'inventory dollar threshold overage',
          field: (v: Vehicle) =>
            checkIsNegativeValue(
              -1 * (v.DeliveryMaxTotalInventoryDollars - v.UnorderedInventory - v.OrderedInventoryDollarValue),
              false
            ),
        });
      }

      if (firstVehicle.DeliveryMaxConcentrateWeight) {
        c.push({ header: 'concentrate weight', field: (v: Vehicle) => `${v.ConcentrateWieght?.toFixed(2)} g` });
        c.push({
          header: 'concentrate weight overage',
          field: (v: Vehicle) =>
            checkIsNegativeValue(-1 * (v.DeliveryMaxConcentrateWeight - v.ConcentrateWieght), true),
        });
        c.push({ header: 'non-concentrate weight', field: (v: Vehicle) => `${v.InventoryWeight?.toFixed(2)} g` });
        c.push({
          header: 'non-concentrate weight overage',
          field: (v: Vehicle) => checkIsNegativeValue(-1 * (v.DeliveryMaxTotalWeight - v.InventoryWeight), true),
        });
      } else if (firstVehicle.DeliveryMaxTotalWeight) {
        c.push({ header: 'inventory weight', field: (v: Vehicle) => `${v.InventoryWeight?.toFixed(2)} g` });
        c.push({
          header: 'inventory weight overage',
          field: (v: Vehicle) => checkIsNegativeValue(-1 * (v.DeliveryMaxTotalWeight - v.InventoryWeight), true),
        });
      }
    }

    if (features.LocationBasedDelivery) {
      const statusTooltip =
        "A status of Online will ensure the vehicle's inventory is made available via an integrated menu";
      const onDutyTooltip =
        "This vehicle's online orders will be dispatched to this driver, and their GPS coordinates (if available) will be used to geolocate the vehicle's integrated menu";

      c.push({
        header: (
          <>
            On Duty <TooltipIcon title={onDutyTooltip} />
          </>
        ),
        field: 'CurrentDriverName',
      });
      c.push({ header: 'Last Reported Coordinates', field: (v: Vehicle) => getLastReportedString(v) });
      c.push({
        header: (
          <>
            Status <TooltipIcon title={statusTooltip} />
          </>
        ),
        field: 'CurrentDriverId',
        Cell: ({ value }) => (value ? <GreenPill>Online</GreenPill> : <TransparentPill>Offline</TransparentPill>),
      });
      c.push({
        header: '',
        field: 'CarId',
        Cell: ({ item }) => getActionMenu(item),
        disableSort: true,
      });
    }

    setColumns(c);
  }, [vehicles, features, getActionMenu, getLastReportedString]);

  const selectedFilterCount = selectedFiltersFromAppState.reduce(
    (totalSelectedFilters, { options: currOptions }) => totalSelectedFilters + currOptions?.length,
    0
  );

  return (
    <FleetPageContainer>
      <Header>Fleet</Header>
      <Divider />
      <FleetListPageDiv>
        <BottomDrawer open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
          <FleetPageFilterMenu
            appliedFilters={selectedFiltersFromAppState}
            onApply={onApplyFilters}
            onCancel={() => setDrawerIsOpen(false)}
          />
        </BottomDrawer>
        <ActionBar
          drawerIsOpen={drawerIsOpen}
          sortState={sortState}
          selectedFilterCount={selectedFilterCount}
          setSort={setSort}
          setDrawerIsOpen={setDrawerIsOpen}
        />
        {sortedVehicles?.length === 0 && (
          <EmptyPlaceHolderWrapperDiv>
            <EmptyPlaceHolderCircle>
              No vehicles configured for this location talk to your administrator
            </EmptyPlaceHolderCircle>
          </EmptyPlaceHolderWrapperDiv>
        )}

        {sortedVehicles?.length > 0 && (
          <TableWrapper>
            <Table<Vehicle>
              data={sortedVehicles}
              columns={columns}
              pageSize={10}
              sortColumn={0}
              sortDirection={'asc'}
            />
          </TableWrapper>
        )}

        {buildNumber && <BuildNumberContainer>Build: {buildNumber.BuildNumber}</BuildNumberContainer>}
      </FleetListPageDiv>
      <ConfirmationPopup
        small
        isVisible={isVisible}
        hide={toggle}
        title='Set Car Online'
        cancel={{
          text: 'Cancel',
          onClick: () => {
            toggle();
          },
        }}
        confirm={{
          text: 'Save',
          disabled: !selectedDriver,
          onClick: () => {
            setOnline();
          },
        }}
      >
        <CarOnlineContainer>
          <FullwidthSelect
            placeholder='Assign Driver to Vehicle'
            options={drivers.map((driver) => ({
              label: driver.Name,
              value: String(driver.Id),
            }))}
            onChange={(val) => setSelectedDriver(Number(val))}
            value={selectedDriver}
          />
        </CarOnlineContainer>
      </ConfirmationPopup>
    </FleetPageContainer>
  );
};

const FleetListPageDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  .vehicles-button {
    align-items: flex-start;
    border: none;
    border-radius: 4px;
    padding: 9px 15px 6px 15px;
    text-align: center;
    color: white;
    line-height: 17px;
    height: 32px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    box-shadow: ${shadows.level3};
  }

  .configure-vehicles {
    background: ${colors.accent4};
  }

  ${Toolbar} {
    padding: 0 0 1.5rem 0;
  }

  ${SearchContainer} {
    max-width: none;
  }
`;

const FullwidthSelect = styled(Select)`
  width: 100%;
`;

const CarOnlineContainer = styled.div`
  width: 100%;
  padding: 0 1.5rem;
`;

const BuildNumberContainer = styled.div`
  font-size: 10px;
  color: ${colors.accent1};
  font-weight: bold;
  position: absolute;
  left: 0%;
  bottom: 0;
`;

const EmptyPlaceHolderWrapperDiv = styled.div`
  width: 100%;
  position: absolute;
  top: 16rem;
`;

const EmptyPlaceHolderCircle = styled(_EmptyPlaceHolderCircle)`
  color: ${colors.dutchie.almostBlack};
  font-size: 12px;
  text-align: center;
  padding: 10px;
  background-color: ${colors.dutchie.lightGrey};
  margin: 0 auto;
`;

const TooltipIcon = styled(AlertIcon)`
  cursor: help;
`;

const Header = styled(Heading2)`
  margin-top: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.dutchie.shadowGrey};
  margin-right: 4rem;
  margin-top: 32px;
  margin-bottom: 24px;
`;

const FleetPageContainer = styled.div`
  padding: 1.5rem;
`;

const TableWrapper = styled.div`
  overflow-x: scroll;
  max-width: 100%;
  width: 100%;
`;
