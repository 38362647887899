import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { colors } from 'css/Theme';
import { ReactComponent as DutchieLogoWhite } from 'assets/dutchie-logo-white-md.svg';

interface StyledHeaderDisplayProps {
  isAndroid: boolean;
}

export const KeypadHeader = ({ isAndroid = false }): JSX.Element => {

  const getLocalTime = (): string => format(new Date(), 'h:mm a');

  const [time, setTime] = useState<string>(getLocalTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getLocalTime());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StyledHeader isAndroid={isAndroid} data-testid='login-keypad-header'>
      <DutchieLogoWhite />
      <StyledDateTime>
        <StyledClock data-testid='login-clock'>{time}</StyledClock>
        <StyledDate data-testid='login-date'>{format(new Date(), 'MMMM d, yyyy')}</StyledDate>
      </StyledDateTime>
    </StyledHeader>
  );
};

const StyledHeader = styled.div<StyledHeaderDisplayProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isAndroid ? '48px 48px 0' : '64px 64px 0')};
  color: ${colors.dutchie.primaryWhite};
  background-color: ${colors.dutchie.opal90};
`;

const StyledClock = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: ${colors.dutchie.primaryWhite};
`;

const StyledDate = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.dutchie.primaryWhite};
`;

const StyledDateTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.dutchie.primaryWhite};
`;
