import React from 'react';

import { Skeleton } from 'components/misc';

import { useTotalBreakdownDrawer } from '../useTotalBreakdownDrawer';
import { Container, Row, SubTotalRow } from '../TotalBreakdownDrawer.styles';

export const TotalBreakdownDrawerLoadingSkeleton = (): JSX.Element => {
  const { showLoyaltyAsDiscount, showFeesAndDonations, showRounding, showTipAmount } = useTotalBreakdownDrawer({});

  return (
    <Container data-testid='order-totals-loading-skeleton-container'>
      <SubTotalRow>
        <div>Subtotal</div>
        <Skeleton width={50} />
      </SubTotalRow>
      {showLoyaltyAsDiscount ? (
        <Row>
          <div>Discounts & loyalty</div>
          <Skeleton width={40} />
        </Row>
      ) : (
        <>
          <Row>
            <div>Discounts</div>
            <Skeleton width={40} />
          </Row>
          <Row>
            <div>Loyalty</div>
            <Skeleton width={40} />
          </Row>
        </>
      )}
      <Row>
        <div>Tax</div>
        <Skeleton width={50} />
      </Row>
      {showFeesAndDonations && (
        <Row>
          <div>Fees & donations</div>
          <Skeleton width={50} />
        </Row>
      )}
      {showRounding && (
        <Row>
          <div>Rounding</div>
          <Skeleton width={40} />
        </Row>
      )}
      {showTipAmount && (
        <Row>
          <div>Tip</div>
          <Skeleton width={50} />
        </Row>
      )}
    </Container>
  );
};
