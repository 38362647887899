import React from 'react';
import { Keyboard } from '@dutchie/capacitor-keyboard';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { isAndroid } from 'util/hooks';

export const KeyboardTab = () => {
  if (!isAndroid) {
    return null;
  }

  return (
    <SettingsCard automationId='keyboard-settings-card'>
      <SettingsListItem
        automationId='keyboard-system-settings'
        title='Open System Settings'
        subtitle='Manage available keyboards from system settings'
        onClick={() => Keyboard.openSystemSettings()}
      />
      <SettingsDivider />
      <SettingsListItem
        automationId='keyboard-picker'
        title='Select Keyboard'
        subtitle='Set your default keyboard'
        onClick={() => Keyboard.showPicker()}
      />
    </SettingsCard>
  );
};
