import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { post } from 'api/HttpHelpers';
import { successNotification, errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { State } from 'store';
import { CartItem } from 'models/Cart';
import { openPDFInWindow } from 'util/Printing';
import { logger } from 'util/logger';
import { printLabelsWithDefaultLabelId } from 'store/helpers/printHelpers/printLabelsWithDefaultLabelId';
import { printLabelsWithLabelIdFromSettings } from 'store/helpers/printHelpers/printLabelsWithLabelIdFromSettings';
import { evaluateBooleanLDFlagFromReduxState } from 'store/helpers/launchDarklyHelpers';
import { isDefined, isFulfilled } from 'util/helpers/typeGaurds';

export type PrintJob = {
  printerId: number;
  content: string;
  numToPrint: number;
  contentType: string;
  source: string;
  title: string;
};

export type PrintReceiptArgs = {
  ReceiptType: string;
  ReceiptParameters: number;
  PrinterId: number | string;
  ForDelivery?: boolean;
  total?: number;
  subtotal?: number;
  localPrinter: boolean;
  popCashDrawer: boolean;
};

export const resetPrintJobStatus = createAction('cleanPrint');

// previewLabelsV2 allows for different labelIds configured at the product label to result in multiple PDFs one per labelId
export const previewLabelsV2 = createAsyncThunk(
  'previewLabelsV2',
  async (
    args: { guest?: { ShipmentId: number }; items?: Array<CartItem>; labelId?: number; printAll: boolean },
    { getState, dispatch }
  ) => {
    const { settings } = getState() as State;
    dispatch(successNotification('Downloading Labels...'));

    const items = args.items ?? [];
    const hasSameLabelId = items.every((i) => i.DefaultLabelId === items[0].DefaultLabelId);

    const allowsDefaultLabels =
      settings.features.AllowDefaultProductLabels && !settings.userSettings.selectedLabelPrinter?.LocalPrinter;

    async function doDownload(items: CartItem[] | undefined, labelId: number) {
      if (typeof items == 'undefined') {
        dispatch(errorNotification(`Cart is empty, nothing to print.`));
      }

      if (labelId === 1) {
        dispatch(errorNotification(`No label configured in settings`));
      }

      const packagesToDownloadArr = items?.map((item) => {
        return {
          ShipmentId: args.guest?.ShipmentId,
          AllocatedInventoryId: item.InventoryId,
          Count: args.printAll ? item.QtySelected : 1,
          SerialNumber: item.SerialNo,
          PackageItemId: item.PackageItemId,
          PackageQuantity: item.WgtCnt === 'Wgt' ? (item.Grams ?? 0) / item.QtyAllocated : 1,
        };
      });

      const params = {
        ShipmentId: args.guest?.ShipmentId,
        BatchId: args.guest?.ShipmentId,
        LabelId: labelId,
        PackagesToPrint: packagesToDownloadArr,
        LabelType: 'ProductProd',
        PrinterId: settings.userSettings.selectedLabelPrinter?.PrinterId,
      };

      try {
        return await post<string>('v2/print-jobs/preview-pos-label', params);
      } catch (e) {
        dispatch(errorNotification(`Error generating preview: ${e}`));
        logger.error(e, { method: 'previewLabels' });
      }
    }

    let requests: Promise<string | undefined>[];
    if (!allowsDefaultLabels || hasSameLabelId) {
      requests = [doDownload(args.items, args.labelId ?? settings?.userSettings?.selectedLabel?.id ?? 1)];
    } else {
      // need to print multiple labels, one for each labelId
      const itemGroups: { [key: string]: CartItem[] } = args.items?.reduce((r, item: CartItem) => {
        const labelId = args.labelId ?? item.DefaultLabelId ?? settings?.userSettings?.selectedLabel?.id ?? 1;
        r[labelId.toString()] = r[labelId.toString()] || [];
        r[labelId.toString()].push(item);
        return r;
      }, Object.create(null));

      requests = [];
      Object.keys(itemGroups).forEach((labelId: string) => {
        const items = itemGroups[labelId];
        requests.push(doDownload(items, parseInt(labelId)));
      });
    }

    const results = await Promise.allSettled(requests);
    const fulFilledResults = results.filter(isFulfilled).map((p) => p.value);
    const datas = fulFilledResults.filter(isDefined);

    datas.forEach((data) => {
      openPDFInWindow(data, `Labels.pdf`);
    });
    dispatch(successNotification('Labels generated'));

    return datas;
  }
);

export const previewLabelsV1 = createAsyncThunk(
  'previewLabelsV1',
  async (
    args: { guest?: { ShipmentId: number }; items?: Array<CartItem>; labelId?: number; printAll: boolean },
    { getState, dispatch }
  ) => {
    const { settings } = getState() as State;
    dispatch(successNotification('Downloading Labels...'));

    const PackagesToDownloadArr = args.items?.map((item) => {
      return {
        ShipmentId: args.guest?.ShipmentId,
        AllocatedInventoryId: item.InventoryId,
        Count: args.printAll ? item.QtySelected : 1,
        SerialNumber: item.SerialNo,
        PackageItemId: item.PackageItemId,
        PackageQuantity: item.WgtCnt === 'Wgt' ? (item.Grams ?? 0) / item.QtyAllocated : 1,
      };
    });

    const params = {
      ShipmentId: args.guest?.ShipmentId,
      BatchId: args.guest?.ShipmentId,
      LabelId: 1,
      PackagesToPrint: PackagesToDownloadArr,
      LabelType: 'ProductProd',
      PrinterId: settings.userSettings.selectedLabelPrinter?.PrinterId,
    };

    if (!args.labelId) {
      if (settings.userSettings.selectedLabel) {
        params.LabelId = settings.userSettings.selectedLabel.id;
      }
    } else {
      params.LabelId = args.labelId;
    }

    try {
      const data = await post<string>('v2/print-jobs/preview-pos-label', params);
      openPDFInWindow(data, `Labels.pdf`);
      dispatch(successNotification('Labels generated'));
      return data;
    } catch (e) {
      dispatch(errorNotification(`Error generating preview: ${e}`));
      logger.error(e, { method: 'previewLabels' });
    }
  }
);

export const previewLabels = createAsyncThunk(
  'previewLabels',
  async (
    args: { guest?: { ShipmentId: number }; items?: Array<CartItem>; labelId?: number; printAll: boolean },
    { getState, dispatch }
  ) => {
    const { ldFlags } = getState() as State;
    if (evaluateBooleanLDFlagFromReduxState(ldFlags, 'pos.traceability.preview-labels-v2-eng-52190.rollout', false)) {
      await dispatch(
        previewLabelsV2({ guest: args.guest, items: args.items, labelId: args.labelId, printAll: args.printAll })
      );
    } else {
      await dispatch(
        previewLabelsV1({ guest: args.guest, items: args.items, labelId: args.labelId, printAll: args.printAll })
      );
    }
  }
);

export const printLabels = createAsyncThunk(
  'printLabels',
  async (
    args: {
      guest: { ShipmentId: number };
      items: CartItem[] | CartItem;
      printAll: boolean;
      autoPrint: boolean;
      transactionId?: string;
    },
    { getState, dispatch }
  ) => {
    const { settings } = getState() as State;
    const registerId = settings.selectedRegister?.value;

    if (!registerId) {
      dispatch(errorNotification('No register selected'));
      return Promise.reject('No register selected');
    }

    const itemsFromArgs = Array.isArray(args.items) ? args.items : [args.items];

    const itemsToPrint = !settings.features.PrintNonCannabisLabels
      ? itemsFromArgs.filter((item) => item.CannbisProduct !== 'No')
      : itemsFromArgs;

    if (itemsToPrint.length < 1) {
      dispatch(warningNotification(`Labels print for cannabis items only`));
      return Promise.reject('Not set up as a cannabis product');
    }

    try {
      if (settings.features.AllowDefaultProductLabels && !settings.userSettings.selectedLabelPrinter?.LocalPrinter) {
        await printLabelsWithDefaultLabelId({
          args,
          dispatch,
          itemsToPrint,
          registerId,
          settings,
        });
      } else {
        await printLabelsWithLabelIdFromSettings({
          args,
          dispatch,
          itemsToPrint,
          settings,
        });
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
