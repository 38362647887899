import React, { FC, ImgHTMLAttributes } from 'react';

import { Asset } from 'assets/Assets';

type ImageAssetProps = ImgHTMLAttributes<HTMLImageElement> & {
  asset: Asset;
};

export const ImageAsset: FC<ImageAssetProps> = (props) => (
  <img src={props.asset.src} alt={props.asset.alt} {...props} />
);
