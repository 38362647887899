import React from 'react';
import styled from 'styled-components';

import { BirthdayCakeIcon } from 'assets/icons/cart/BirthdayCakeIcon';
import { breakpoints, colors } from 'css/Theme';
import { CaregiverIcon } from 'assets/icons/cart/CargiverIcon';
import { DriversLicenseIcon } from 'assets/icons/shared/DriversLicenseIcon';
import { GroupsIcon } from 'assets/icons/cart/GroupsIcon';
import { MedicalIDIcon } from 'assets/icons/cart/MedicalIDIcon';
import { PhoneIcon } from 'assets/icons/cart/PhoneIcon';
import { Skeleton } from 'components/misc';
import { StarIcon } from 'assets/icons/star-icon';
import { useCustomerInfo } from './useCustomerInfo';

export type CustomerInfoProps = {
  loading?: boolean;
  show?: boolean;
};

export const CustomerInfo = ({ loading, show = true }: CustomerInfoProps) => {
  const {
    birthdateText,
    caregiverNameText,
    discountGroupsText,
    driversLicenseText,
    loyaltyPointsText,
    loyaltyStarColor,
    loyaltyTierColor,
    loyaltyTierText,
    mjStateIdText,
    phoneNumberText,
    showBirthdate,
    showCaregiver,
    showDiscountsGroup,
    showDriversLicense,
    showLoyaltyPoints,
    showLoyaltyTier,
    showMJStateId,
    showPhoneNumber,
  } = useCustomerInfo({ loading });

  return (
    <CustomerInfoContainer isOpen={show}>
      {showBirthdate && (
        <Info data-testid='cart-header_customer-dob'>
          <BirthdayCakeIcon size={16} />
          {loading ? <Skeleton height={16} width={114} /> : birthdateText}
        </Info>
      )}
      {showDriversLicense && (
        <Info>
          <DriversLicenseIcon size={16} />
          {loading ? <Skeleton height={16} width={104} /> : driversLicenseText}
        </Info>
      )}
      {showMJStateId && (
        <Info>
          <MedicalIDIcon size={16} />
          {loading ? <Skeleton height={16} width={104} /> : mjStateIdText}
        </Info>
      )}
      {showPhoneNumber && (
        <Info>
          <PhoneIcon size={16} />
          {loading ? <Skeleton height={16} width={104} /> : phoneNumberText}
        </Info>
      )}
      {showDiscountsGroup && (
        <Info>
          <GroupsIcon size={16} />
          {loading ? <Skeleton height={16} width={104} /> : discountGroupsText}
        </Info>
      )}
      {showLoyaltyPoints && (
        <Info data-testid='guest-detail-loyalty'>
          <StarIcon width={16} height={16} color={loyaltyStarColor} />
          {loading ? (
            <Skeleton height={16} width={156} />
          ) : (
            <LoyaltyDescription>
              {showLoyaltyTier && (
                <>
                  <LoyaltyTier color={loyaltyTierColor} data-testid='external-loyalty-tier'>
                    {loyaltyTierText}
                  </LoyaltyTier>
                  <span>{'-'}</span>
                </>
              )}
              {loyaltyPointsText}
            </LoyaltyDescription>
          )}
        </Info>
      )}
      {showCaregiver && (
        <Info>
          <CaregiverIcon size={16} />
          {loading ? <Skeleton height={16} width={104} /> : caregiverNameText}
        </Info>
      )}
    </CustomerInfoContainer>
  );
};

const CustomerInfoContainer = styled.div<{ isOpen?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  /* Using a combo of gap and margin-right since flex wrap will use the gap for both left/right and top/bottom spacing */
  gap: 0.25rem;
  flex-wrap: wrap;

  ${breakpoints.smallTablet.maxQuery} {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

const Info = styled.div`
  color: ${colors.dutchie.almostBlack};
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 13px;
  font-weight: 400;

  &:not(:last-child) {
    padding-right: 1rem;
    border-right: 1px solid ${colors.dutchie.grey90};
    margin-right: 0.75rem;
  }
`;

const LoyaltyDescription = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const LoyaltyTier = styled.div<{ color?: string }>`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({ color }) => (color ? `${color}` : `${colors.dutchie.almostBlack}`)};
`;
