import { ConnectionType, Scanner } from '@dutchie/capacitor-hardware';
import type { MockPeripheralConfig } from '../../shared/mock/types';

export class MockScanner extends Scanner {
  private _config: MockPeripheralConfig;
  private _connected = false;

  constructor(config: MockPeripheralConfig) {
    super(config.connectionType ?? ConnectionType.other);
    this._config = config;
  }

  get isConnected(): boolean {
    return this._connected;
  }

  get id(): string {
    return this._config.id;
  }

  get name(): string {
    return this._config.name;
  }

  get metadata() {
    return this._config.metadata ?? {};
  }

  async doConnect(): Promise<boolean> {
    this._connected = true;
    return this._connected;
  }

  async doDisconnect(): Promise<boolean> {
    this._connected = false;
    return !this._connected;
  }
}
