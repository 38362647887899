import { Peripheral } from '@dutchie/capacitor-peripheral';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch } from 'util/hooks/useAppDispatch';
import {
  getStoredHardware,
  peripheralInfo,
  GetPrintJobResponse,
  processHardwarePrintJob,
  selectHardwarePrinterType,
} from 'util/hardwareLibrary/hardware-library-utils';
import { post } from 'api/HttpHelpers';
import { UsePrintReceiptParams } from './usePrintReceipt';
import { AppDispatch } from 'store';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';
import { PrintJob, PrintStatus } from 'models/Printing';
import { logger, customEventKeys } from 'util/logger';
import { PrintJobContext, PrintJobResultContext } from 'util/logger/types/printing';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';
import { isAndroid } from 'util/hooks/android/useAndroidPeripherals';
import { getIsPrintResultLoggingEnabled } from 'util/hooks/launch-darkly/getIsPrintResultLoggingEnabled';

type UsePrintRegalaParams = UsePrintReceiptParams;
export const printRegalaReceipt = async (params: UsePrintRegalaParams & { dispatch: AppDispatch }) => {
  const { receiptPrinter } = getStoredHardware();
  const { dispatch, popCashDrawer, receiptParameters, receiptType, showDeliveryDetails, subtotal, total } = params;

  try {
    if (!receiptPrinter) {
      throw new Error('Printer configuration not found');
    }

    dispatch(successNotification('Printing Payment Receipt...'));
    dispatch(setPrintJobStatus({ printJob: PrintJob.REGALA_RECEIPT, status: PrintStatus.PRINTING }));

    logger.info<PrintJobContext>('print regala receipt started', {
      key: customEventKeys.printing.jobStarted,
      job: PrintJob.REGALA_RECEIPT,
      requestParams: params,
      printer: peripheralInfo(receiptPrinter),
    });

    const job = await post<GetPrintJobResponse>('v2/print-jobs/get-regala-receipt-job', {
      ForDelivery: showDeliveryDetails,
      PopCashDrawer: popCashDrawer,
      PrinterId: 0, // not used by endpoint
      PrinterType: selectHardwarePrinterType(receiptPrinter),
      ReceiptType: receiptType,
      ReceiptParameters: receiptParameters,
      SubTotal: subtotal,
      Total: total,
    });

    if (getIsPrintResultLoggingEnabled()) {
      let success = false;
      try {
        success = await processHardwarePrintJob(job, receiptPrinter);
      } finally {
        logger.info<PrintJobResultContext>('print regala receipt result', {
          key: customEventKeys.printing.jobEnded,
          job: PrintJob.REGALA_RECEIPT,
          requestParams: params,
          printer: peripheralInfo(receiptPrinter),
          success,
        });
      }
    } else {
      await processHardwarePrintJob(job, receiptPrinter);
    }

    if (popCashDrawer && isAndroid) {
      await Peripheral.openCashDrawer();
    }

    dispatch(successNotification('Receipt printed'));
    dispatch(setPrintJobStatus({ printJob: PrintJob.REGALA_RECEIPT, status: PrintStatus.SUCCESSFUL }));
  } catch (e) {
    const message = parseErrorMessage(e);
    dispatch(errorNotification(`Error printing receipt: ${message}`));
    dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.FAILED }));
    logger.error(e, {
      message: 'Failed to print receipt',
      requestParams: params,
      printer: peripheralInfo(receiptPrinter),
    });
  }
};

export const usePrintRegalaReceipt = () => {
  const dispatch = useAppDispatch();

  return async (params: UsePrintRegalaParams) => {
    return printRegalaReceipt({ ...params, dispatch });
  };
};
