import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { priceCheckKeys } from './query-key-factory';
import { transformToPriceCheckDetails } from './types';

import type { PriceCheckDetails, PriceCheckServerResponse } from './types';

const PRICE_CHECK = 'v2/inventory/price-check';

export type PriceCheckPayload = {
  packageSerialNumber: string;
};

const transformToServerPayload = (payload: PriceCheckPayload) => ({
  PackageSerialNumber: payload.packageSerialNumber,
});

export const usePriceCheckQuery = (payload: PriceCheckPayload, options?: UseQueryOptions<PriceCheckDetails>) => {
  return useQuery({
    queryKey: priceCheckKeys.one(payload.packageSerialNumber),
    queryFn: async () => {
      const response = await post<PriceCheckServerResponse>(PRICE_CHECK, transformToServerPayload(payload));
      return transformToPriceCheckDetails(response);
    },
    ...options,
  });
};
