import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'components/popups';
import { AvailableDiscount, AppliedDiscount, ApplyDiscountRequest } from 'models/Discounts';
import * as api from 'api/DiscountApi';
import { State } from 'store';
import { AvailableDiscounts } from './AvailableDiscounts';
import { SelectedDiscount } from './SelectedDiscount';
import { SelectedExternalLoyaltyDiscount } from './SelectedExternalLoyaltyDiscount';
import { ManagerSection } from './ManagerSection';
import { AppliedDiscounts } from './AppliedDiscounts';
import { errorNotification } from 'store/actions/NotificationsActions';
import * as customerApi from 'api/CustomerApi';
import { CartItem } from 'models/Cart';
import { callback } from 'models/Misc';
import { applyDiscount } from 'store/actions/CartItemsActions';
import { Flex } from 'components/layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

const DiscountContainer = styled(Flex)`
  max-height: calc(100vh - 200px - 1.5rem);
  width: 100%;
  padding: 0 1.5rem 1.5rem;

  & > * {
    width: 50%;
    flex-grow: 1;

    &:first-child {
      margin-right: 0.75rem;
    }

    &:last-child {
      margin-left: 0.75rem;
    }
  }
`;

type DiscountPopupProps = {
  item?: CartItem;
  hide: callback;
};

export const DiscountPopup: FC<DiscountPopupProps> = ({ item, hide }) => {
  const dispatch = useDispatch();
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const loyaltyPoints = useSelector((state: State) => state.customer.details?.LoyaltyPoints);
  const [selected, setSelected] = useState<AvailableDiscount | undefined>();
  const [applyTo, setApplyTo] = useState<string>();
  const [appliedDiscounts, setAppliedDiscounts] = useState<Array<AppliedDiscount>>([]);
  const [loading, setLoading] = useState(true);
  const [fee, setFee] = useState('');
  const [percent, setPercent] = useState('');
  const [discountName, setDiscountName] = useState<string>('');
  const [availableDiscounts, setAvailableDiscounts] = useState<Array<AvailableDiscount>>([]);
  const needPin = selected?.ManagerFlag === 'Yes' || selected?.ManagerFlag === 'managerapproval';
  const flags = useFlags();

  const { data: cart, refetch: refetchCartDetails } = useGetCartDetails();
  const { guestId, shipmentId } = useTransactionManager();

  const allowDecimalBasedPercentDiscounts = flags['core.cats.pos.all-decimal-based-percentage.rollout'];

  const manualExternalLoyaltyDiscountsRollout = flags['core.cats-pos.manual-external-loyalty-discounts.rollout'];
  const showExternalLoyalty = useSelector((state: State) => state.settings.features.ShowExternalLoyalty);
  const externalLoyaltyDiscountSelected = useMemo(() => {
    return (
      manualExternalLoyaltyDiscountsRollout && showExternalLoyalty && selected != null && selected.LoyaltyPointValue > 0
    );
  }, [manualExternalLoyaltyDiscountsRollout, selected, showExternalLoyalty]);

  useEffect(() => {
    if (register && guestId) {
      customerApi
        .getAvailableDiscounts(guestId, {
          ProductId: item?.ProductId,
          Register: Number(register.value),
        })
        .then(({ Data }) => setAvailableDiscounts(Data || []))
        .catch(() => setAvailableDiscounts([]));
    }
  }, [register, guestId, item]);

  const applyDiscountAction = async (approvingManagerUserId: number | undefined) => {
    const feeNumber = parseFloat(fee);
    const percentNumber = allowDecimalBasedPercentDiscounts ? parseFloat(percent) : parseInt(percent, 10);

    if (!selected) {
      dispatch(errorNotification('No discount selected'));
      return;
    }
    if (!applyTo) {
      dispatch(errorNotification('No item selected'));
      return;
    }

    if (discountName.length > 100) {
      dispatch(errorNotification('Discount names must be less than 100 characters'));
      return;
    }

    const createDiscountBody = (ProductId: number, SerialNo: string): ApplyDiscountRequest => ({
      DiscountId: selected.ProductId,
      DiscountCode: '',
      OverideDesc: discountName ?? selected.ProductDesc,
      Apply: applyTo,
      DiscountAmt: feeNumber ? feeNumber : 0,
      DiscountPercent: feeNumber ? 0 : percentNumber / 100,
      ProductId,
      SerialNo,
      ShipmentId: shipmentId,
      ApprovingManagerUserId: approvingManagerUserId ? approvingManagerUserId : undefined,
    });

    if ((selected.LoyaltyPointValue ?? 0) > 0 && (loyaltyPoints ?? 0) < (selected.LoyaltyPointValue ?? 0)) {
      dispatch(
        errorNotification(
          `Customer does not have enough loyalty points to apply discount. ${selected.LoyaltyPointValue} needed, ${loyaltyPoints} remaining.`
        )
      );
      return;
    }

    switch (applyTo) {
      case 'ALL': {
        if (feeNumber) {
          let block = 0;
          cart?.Cart?.forEach((item) => {
            const price = item.TotalCost / item.QtyAllocated;
            if (feeNumber > price && (!block || block > price)) {
              block = price;
            }
          });
          if (block) {
            dispatch(
              errorNotification(`Discount Amount can not be greater than unit price. Maximum discount is $${block}`)
            );
            return;
          }
        }
        return dispatch(applyDiscount(createDiscountBody(item?.ProductId || 0, item?.SerialNo || '')));
      }
      case 'ONE': {
        if (item && feeNumber && feeNumber > item.TotalCost / item.QtyAllocated) {
          dispatch(errorNotification('Discount Amount can not be greater than unit price'));
          return;
        }
        return dispatch(applyDiscount(createDiscountBody(item?.ProductId || 0, item?.SerialNo || '')));
      }
      case 'TOTAL': {
        if ((selected.LoyaltyPointValue ?? 0) > 0 && (loyaltyPoints ?? 0) < (selected.LoyaltyPointValue ?? 0)) {
          dispatch(
            errorNotification(
              `Customer does not have enough loyalty points to apply discount. ${selected.LoyaltyPointValue} needed, ${loyaltyPoints} remaining.`
            )
          );
          return;
        }
        return dispatch(applyDiscount(createDiscountBody(item?.ProductId || 0, item?.SerialNo || '')));
      }
      case 'LINE': {
        if (item && feeNumber && feeNumber > item.TotalCost / item.QtyAllocated) {
          dispatch(errorNotification('Discount Amount can not be greater than unit price'));
          return;
        }
        return dispatch(applyDiscount(createDiscountBody(item?.ProductId || 0, item?.SerialNo || '')));
      }
    }
  };

  const onSubmit = async (approvingManagerUserId: number | undefined) => {
    if (!loading && selected && applyTo) {
      setLoading(true);
      await applyDiscountAction(approvingManagerUserId);
      loadApplied();
      refetchCartDetails();
      setSelected(undefined);
    }
  };

  const applyToOptions = [
    {
      label: 'Each Item in Row',
      value: 'LINE',
    },
    {
      label: 'Just One Item',
      value: 'ONE',
    },
    {
      label: 'Every Item in Cart',
      value: 'ALL',
    },
    {
      label: 'Distributed Among Every Item',
      value: 'TOTAL',
    },
  ];

  const filteredApplyToOptions = useMemo(() => {
    if (percent.length > 0) {
      if (applyTo === 'ALL') {
        setApplyTo('TOTAL');
      }
      return applyToOptions.filter((option) => {
        if ((option.value === 'TOTAL' || option.value === 'ALL') && percent.length > 0) {
          option.label = 'Apply to Subtotal';
        }
        return option.value !== 'ALL';
      });
    }
    return applyToOptions;
  }, [percent, applyToOptions, applyTo]);

  const loadApplied = useCallback(async () => {
    if (register) {
      try {
        const discounts = await api.getAppliedDiscounts(register.value, shipmentId);
        setAppliedDiscounts(discounts);
      } catch {
        setAppliedDiscounts([]);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [register, shipmentId]);

  useEffect(() => {
    loadApplied();
  }, [loadApplied]);

  return (
    <Popup
      large
      caption='Apply Discount'
      hide={() => {
        refetchCartDetails();
        hide();
      }}
      isVisible
    >
      <DiscountContainer alignItems='stretch'>
        <AvailableDiscounts
          availableDiscounts={availableDiscounts}
          selected={selected}
          onSelect={(item) => {
            setSelected(item);
            setApplyTo(item.DefaultAppliedTo);
            item.FlatFee ? setFee(item.FlatFee.toString()) : setFee('');
            item.PercentOff ? setPercent((item.PercentOff * 100).toString()) : setPercent('');
          }}
        />
        <Flex direction='column'>
          {externalLoyaltyDiscountSelected ? (
            <SelectedExternalLoyaltyDiscount
              selected={selected}
              applyTo={applyTo}
              applyToOptions={filteredApplyToOptions}
              fee={fee}
              percent={percent}
              name={discountName}
              setName={setDiscountName}
            />
          ) : (
            <SelectedDiscount
              selected={selected}
              applyTo={applyTo}
              applyToOptions={filteredApplyToOptions}
              setApplyTo={setApplyTo}
              fee={fee}
              setFee={setFee}
              percent={percent}
              setPercent={setPercent}
              name={discountName}
              setName={setDiscountName}
            />
          )}
          <ManagerSection
            title='Apply Discount'
            disabled={(!fee && !percent) || !applyTo || loading || !discountName}
            needPin={needPin}
            onSuccess={onSubmit}
          />
          <AppliedDiscounts
            appliedDiscounts={appliedDiscounts}
            loading={loading}
            setLoading={(value) => {
              setLoading(value);
            }}
            loadApplied={loadApplied}
          />
        </Flex>
      </DiscountContainer>
    </Popup>
  );
};
