import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormikErrors, useFormikContext } from 'formik';
import { isEqual } from 'lodash';

import { State } from 'store';
import { CustomerDetails } from 'models/Customer';

import { initialValues } from '../types';
import { showUnsavedChangesPopup } from 'store/actions/PopupsActions';

const customerProfilePathname = '/edit-customer';

type FormObserverProps = {
  isFormChanged: boolean;
  setIsFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
  save: (values: CustomerDetails, validateForm: (values?: CustomerDetails) => Promise<FormikErrors<CustomerDetails>>) => Promise<boolean>;
};

/**
 * FormObserver handles the logic for detecting when the form has been changed and when the user is
 * trying to navigate away from the page, when we show the UnsavedChangesPopup.
 */
export const FormObserver: React.FC<FormObserverProps> = ({ isFormChanged, setIsFormChanged, save }) => {
  const { values, validateForm } = useFormikContext<CustomerDetails>();
  const dispatch = useDispatch();
  const history = useHistory();

  const customerDetails = useSelector((state: State) => state.customer.details);
  const initialFormValues = { ...initialValues, ...(customerDetails as CustomerDetails) };

  useEffect(() => {
    if (!isEqual(values, initialFormValues)) {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  // Avoiding extra checks if initialFormValues was added here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsFormChanged, values]);

  // When user tries to navigate away from the page but has unsaved changes, we pop a confirmation modal
  useEffect(() => {
    const unblock = history.block((l) => {
      const location = l as { pathname: string; search: string; state: { force: boolean; fromCancel: boolean } };

      const isStillOnProfile = location.pathname === customerProfilePathname;
      const didClickCancel = location.state?.fromCancel;
      const isForceNavigated = location.state?.force; // Currently, this should only be if the user just completed a successful save

      if (!isStillOnProfile && !didClickCancel && isFormChanged && !isForceNavigated) {
        dispatch(showUnsavedChangesPopup({ navigate: () => {
          unblock();
          history.push({
            pathname: location.pathname,
            search: location.search,
            state: { force: true },
          });
        }, save: async () => {
          return await save(values, validateForm);
        } }));
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [dispatch, history, isFormChanged, save, setIsFormChanged, validateForm, values]);

  return null;
};
