import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useScales, ScaleMeasurement, HardwareService } from '@dutchie/capacitor-hardware';

import { HardwareSettings } from '../../types';
import { ScaleSettingsContextData } from '../types';
import { getStoredHardwareIds } from 'util/hardwareLibrary/hardware-library-utils';

// Provides values for the ScaleSettingsProvider.
// Access the values through useScaleSettings.
export const useScaleSettingsProvider = (): ScaleSettingsContextData => {
  const { values: formValues, setFieldValue, submitForm } = useFormikContext<HardwareSettings>();

  const {
    scales: allScales,
    deviceMeasurement,
    search,
  } = useScales({
    onDevicesAuthorized: (scales) => selectScale(scales[0].id, false),
  });

  // Monitors last measurement for scales selected on the form
  const [lastMeasurement, setLastMeasurement] = useState<ScaleMeasurement | undefined>(undefined);

  const connectOnlyToDeviceId = (id: string | undefined) => {
    try {
      HardwareService.scale.devices
        .filter((it) => it.isConnected && it.id !== id)
        .forEach((it) => {
          it.disconnect();
        });

      // Ensure stored scale is connected
      if (id) {
        HardwareService.scale.deviceById(id)?.connect();
      }
    } catch (e) {
      /* no-op */
    }
  };

  const selectScale = (id?: string, save?: boolean) => {
    setFieldValue('scaleId', id);
    setLastMeasurement(undefined);
    connectOnlyToDeviceId(id);

    if (save === true) {
      // Save the preferred scale from outside of the dropdown
      submitForm();
    }
  };

  useEffect(() => {
    if (!deviceMeasurement) {
      return;
    }

    // Only update value from scale selected on the form
    if (deviceMeasurement?.device?.id && formValues.scaleId === deviceMeasurement.device.id) {
      setLastMeasurement(deviceMeasurement.measurement);
    }
  }, [deviceMeasurement, formValues.scaleId]);

  // On dispose, disconnect all but the selected scale
  useEffect(() => {
    return () => {
      const { scaleId } = getStoredHardwareIds();
      connectOnlyToDeviceId(scaleId);
    };
  }, []);

  return {
    formValues,
    lastMeasurement,
    scales: allScales,
    search,
    selectScale,
  };
};
