import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { getInventoryTagsForProductSearchResultsKey } from './query-key-factory';
import { customEventKeys, logger } from 'util/logger';

import type { InventoryTag, InventoryTagServerResponse } from './types';
import { InventoryTagsLoadedContext } from 'util/logger/types/products';

const GET_INVENTORY_TAGS_FOR_PRODUCT_SEARCH_RESULTS = 'v2/product/get-inventory-tags-for-product-search-results';

const transformInventoryTags = (inventoryTag: InventoryTagServerResponse): InventoryTag => ({
  packageId: inventoryTag.PackageId,
  tagId: inventoryTag.TagId,
  tagName: inventoryTag.TagName,
});

export type GetInventoryTagsForProductSearchResultsPayload = { packageIds: number[] };

type GetInventoryTagsForProductSearchResultsOptions<TData = InventoryTag[]> = Partial<
  Omit<UseQueryOptions<InventoryTag[], unknown, TData>, 'select'>
> & {
  select?: (inventoryTags: InventoryTag[]) => TData;
};

export const useGetInventoryTagsForProductSearchResultsQuery = <TData = InventoryTag[]>(
  payload: GetInventoryTagsForProductSearchResultsPayload,
  options?: GetInventoryTagsForProductSearchResultsOptions<TData>
) => {
  return useQuery({
    queryKey: getInventoryTagsForProductSearchResultsKey.one(payload.packageIds),
    queryFn: async () => {
      const response = await post<InventoryTagServerResponse[]>(GET_INVENTORY_TAGS_FOR_PRODUCT_SEARCH_RESULTS, payload);

      logger.debug<InventoryTagsLoadedContext>('[ReactQuery] InventoryTags were loaded', {
        key: customEventKeys.productsList.inventoryTagsLoaded,
        payload,
        queryKey: getInventoryTagsForProductSearchResultsKey.all.toString(),
      });

      return response.map(transformInventoryTags);
    },
    ...options,
  });
};
