import { HidScannerService, UsbReceiptPrinterService, SerialScaleService } from "@dutchie/capacitor-hardware";

type UseHardwareServicesData = {
  isWebHidSupported: boolean;
  isWebSerialSupported: boolean;
  isWebUsbSupported: boolean;
  isWindows: boolean;
};

export const useHardwareServices = (): UseHardwareServicesData => {
  const hidService = new HidScannerService();
  const webUsbService = new UsbReceiptPrinterService();
  const serialService = new SerialScaleService();

  const isWebHidSupported = hidService.isSupported;
  const isWebSerialSupported = serialService.isSupported;
  const isWebUsbSupported = webUsbService.isSupported;

  const isWindows = navigator.userAgent.includes('Windows');

  return {
    isWebHidSupported,
    isWebSerialSupported,
    isWebUsbSupported,
    isWindows,
  };
};
