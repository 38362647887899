import { every } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as GuestApi from 'api/GuestApi';
import { getBuildNumber } from 'api/PosApi';
import { BottomDrawer } from 'components/layout';
import { GuestFilterOption } from 'models/Guest';
import { colors, shadows } from 'css/Theme';
import { ProvideScanner, useScanner } from 'components/Scanner';
import { GuestListFilterMenu } from 'components/menus/FilterMenu/FilterMenu';
import { BoardView, ListView } from './components/kanban';
import * as Cookies from 'es-cookie';
import { State } from 'store';
import { hideDiscountPanel, hideLoyaltyPanel, setCheckedInGuest } from 'store/actions/CartActions';
import { stopCheckoutRunning } from 'store/actions/CheckoutActions';
import { playScannerAction } from 'util/scanning';
import {
  checkInCustomer,
  clearPreOrderMetadata,
  clearSelectedTransaction,
  getCustomerDetails,
} from 'store/actions/CustomerActions';
import {
  applyCustomersFilters,
  applyStatusFilters,
  cleanPusherConfiguration,
  getGuests,
  getGuestStatuses,
  getPusherConfiguration,
  getZones,
  loadCardStatusDisplayOptions,
  setGuestFilterQuery,
} from 'store/actions/GuestListActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { loadBuildNumber } from 'store/actions/SettingsActions';
import { Container } from 'util/Styles';
import { ActionBar } from './components/ActionBar';
import { GuestListPusherImp } from './GuestListPusherImp';
import { GuestListPopups } from 'components/GuestListPopups';
import { showVersionCheckerPopup } from 'store/actions/PopupsActions';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';
import { isAndroid } from 'util/hooks/android/useAndroidPeripherals';
import { logger } from 'util/logger';
import { useAppDispatch } from 'util/hooks';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

export const GuestListPageWithoutPopups: FC = () => {
  const selectedFiltersFromAppState = useSelector((state: State) => state.guestList.filterSelections);
  const guestList = useSelector((state: State) => state.guestList);

  const isSmartCheckinActive = useSmartCheckinActive(); // Gated by feature flag and LD killswitch
  const guestListRefreshRate =
    useSelector((state: State) => state.settings.locationSettings?.GuestListRefreshRate) ?? 10;

  const buildNumber = useSelector((state: State) => state.settings.buildNumber);

  const noStatusesConfigured = useSelector((state: State) => state.guestList.noStatusesConfigured);
  const [listModeState, setListModeState] = useState<boolean>(Cookies.get('ListMode') === 'true');
  const listMode = noStatusesConfigured ? true : listModeState;

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSmartCheckinActive) {
      dispatch(cleanPusherConfiguration()).then(() => {
        dispatch(getPusherConfiguration());
      });
    }
  }, [dispatch, isSmartCheckinActive]);

  useEffect(() => {
    dispatch(stopCheckoutRunning());
    dispatch(getGuestStatuses());
    dispatch(getGuests());
    dispatch(loadCardStatusDisplayOptions());
    dispatch(loadBuildNumber());
    dispatch(getZones());
    dispatch(clearPreOrderMetadata());
  }, [dispatch]);

  useEffect(() => {
    Cookies.set('ListMode', `${listModeState}`);
  }, [listModeState]);

  useEffect(() => {
    if (!isSmartCheckinActive) {
      // Prevent any interval less than 10 seconds
      const interval = guestListRefreshRate > 10 ? guestListRefreshRate * 1000 : 10000;
      const id = setInterval(() => dispatch(getGuests()), interval);
      return () => {
        clearInterval(id);
      };
    }
  }, [dispatch, guestListRefreshRate, isSmartCheckinActive]);

  useEffect(() => {
    if (isAndroid) {
      return;
    }

    const oldBuildNumber = buildNumber?.BuildNumber;
    const id = setInterval(async () => {
      const getNewerBuildNumber = await getBuildNumber();
      const newerBuildNumber = getNewerBuildNumber.BuildNumber;
      if (oldBuildNumber === newerBuildNumber) {
        return;
      } else {
        dispatch(showVersionCheckerPopup());
      }
    }, 600000);
    return () => {
      clearInterval(id);
    };
  }, [buildNumber, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setGuestFilterQuery(''));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearSelectedTransaction());
    dispatch(hideDiscountPanel());
    dispatch(hideLoyaltyPanel());
  }, [dispatch]);

  const onApplyFilters = React.useCallback(
    (filters: Array<GuestFilterOption>) => {
      const filtersAreEmpty = every(filters, (filter) => filter.options.length === 0);
      dispatch(applyCustomersFilters(filtersAreEmpty ? [] : filters));
      dispatch(applyStatusFilters(filtersAreEmpty ? [] : filters));
      dispatch(getGuestStatuses());

      setDrawerIsOpen(false);
    },
    [dispatch]
  );

  const onResetFilters = React.useCallback(() => {
    dispatch(applyCustomersFilters([]));
    dispatch(applyStatusFilters([]));
    dispatch(getGuestStatuses());
  }, [dispatch]);

  const selectedFilterCount = selectedFiltersFromAppState.reduce(
    (totalSelectedFilters, { options: currOptions }) => totalSelectedFilters + currOptions?.length,
    0
  );

  const onSelectFilters = React.useCallback(() => setDrawerIsOpen((currentVal) => !currentVal), []);
  const onToggleListMode = React.useCallback((mode: string) => setListModeState(mode === 'List'), []);
  const onCloseDrawer = React.useCallback(() => setDrawerIsOpen(false), []);
  return (
    <>
      <OnScanUpdater />

      {guestList.pusherConfiguration.PusherKey &&
        guestList.pusherConfiguration.PusherCluster &&
        guestList.pusherConfiguration.ChannelName && <GuestListPusherImp />}
      <GuestListPageDiv
        data-testid='guestlistpagediv_div_guest-page'
      >
        <BottomDrawer open={drawerIsOpen} onClose={onCloseDrawer}>
          <GuestListFilterMenu
            appliedFilters={selectedFiltersFromAppState}
            onApply={onApplyFilters}
            onCancel={onCloseDrawer}
          />
        </BottomDrawer>
        <ActionBar
          onSelectFilters={onSelectFilters}
          onResetFilters={onResetFilters}
          onToggleListMode={onToggleListMode}
          isListMode={listMode}
          activeFilterCount={selectedFilterCount}
          data-testid='actionbar_div_guest-page-actions'
        />
        {!isSmartCheckinActive && listMode && (
          <GuestListRefreshInfo>
            <p style={{ paddingLeft: '25px' }}>
              <small>Guest list refreshes every {guestListRefreshRate} seconds</small>
            </p>
          </GuestListRefreshInfo>
        )}
        {isSmartCheckinActive && !guestList.pusherConnected && listMode && (
          <div>
            <p style={{ paddingLeft: '25px' }}>
              <small>
                Real-time guest list updates have been disconnected, please refresh the guest list to update.
              </small>
            </p>
          </div>
        )}
        <KanbanContent listMode={listMode} />
      </GuestListPageDiv>
    </>
  );
};

const KanbanContent = ({ listMode }: { listMode: boolean }) => (
  <>
    {listMode && (
      <ListModeDiv data-testid='listmodediv_div_list-view' hideScrollbars={false}>
        <Columns>
          <ListView />
        </Columns>
      </ListModeDiv>
    )}
    {!listMode && <BoardView />}
  </>
);

const OnScanUpdater = () => {
  const isUseMCDMSEnabled = useSelector((state: State) => state.settings.integrations?.UseMCDMS);
  const guestList = useSelector((state: State) => state.guestList);
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const scanPopup = useSelector((state: State) => state.popups.scanPopup);
  const securityCheckinPopup = useSelector((state: State) => state.popups.securityCheckinPopup);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const scanner = useScanner();

  const { goToCart } = useTransactionManager();

  const processScan = useCallback(
    async (barcode: string, scannerId?: string | number) => {
      if (isUseMCDMSEnabled) {
        try {
          const mcdmsResponse = await GuestApi.mcdmsIdScan({ id: barcode });
          history.push(`/edit-customer?id=${mcdmsResponse.PatientId}`);
        } catch (e) {
          logger.error(e, { barcode: `"${barcode}"` });
        }
      } else {
        try {
          const data = await GuestApi.scan({ barcode: barcode });
          const registerId = register?.value;
          const guest = guestList.guests.find((g) => g.Guest_id === parseInt(data.Id));
          switch (data.Type.toLowerCase()) {
            case 'none':
              playScannerAction({ scannerId, isGood: false });
              dispatch(errorNotification('Guest not found'));
              break;
            case 'guest': {
              const guestId = parseInt(data.Id);

              playScannerAction({ scannerId, isGood: true });

              const customerDetails = await dispatch(getCustomerDetails({ guestId })).unwrap();

              const checkedInGuestResponse = await dispatch(
                checkInCustomer({
                  guestId,
                  caregiverId: customerDetails?.CaregiverMJStateIdNo,
                  mjStateId: customerDetails?.MJStateIDNo,
                  register: registerId,
                  roomId: customerDetails?.CurrentRoom,
                })
              ).unwrap();

              if (checkedInGuestResponse) {
                const [checkedInGuest] = checkedInGuestResponse;

                dispatch(
                  setCheckedInGuest({
                    ShipmentId: checkedInGuest.ShipmentId,
                    Guest_id: guestId,
                    TransactionCaregiver: customerDetails?.CaregiverMJStateIdNo,
                    ScheduleId: checkedInGuest.ScanResult,
                  })
                );
                goToCart({ guestId: guestId, shipmentId: checkedInGuest.ShipmentId });
              }
              break;
            }
            case 'transaction': {
              playScannerAction({ scannerId, isGood: true });

              if (!registerId) {
                dispatch(errorNotification('No register selected'));
                return;
              }
              if (!guest) {
                dispatch(errorNotification('Guest is not checked in'));
                return;
              }
              if (!!guest.RegisterId && guest.RegisterId !== registerId) {
                dispatch(errorNotification(`Guest is already assigned to register ${guest.Register}`));
                return;
              }

              const guestId = parseInt(data.Id);

              const customerDetails = await dispatch(getCustomerDetails({ guestId })).unwrap();

              const checkedInGuestResponse = await dispatch(
                checkInCustomer({
                  guestId,
                  caregiverId: customerDetails?.CaregiverMJStateIdNo,
                  mjStateId: customerDetails?.MJStateIDNo,
                  register: registerId,
                  roomId: customerDetails?.CurrentRoom,
                })
              ).unwrap();

              if (checkedInGuestResponse) {
                const [checkedInGuest] = checkedInGuestResponse;

                dispatch(
                  setCheckedInGuest({
                    ShipmentId: checkedInGuest.ShipmentId,
                    Guest_id: guestId,
                    TransactionCaregiver: customerDetails?.CaregiverMJStateIdNo,
                    ScheduleId: checkedInGuest.ScanResult,
                  })
                );
                goToCart({ guestId: guestId, shipmentId: checkedInGuest.ShipmentId });
              }
              break;
            }
          }
        } catch (e) {
          logger.error(e, { barcode: `"${barcode}"` });
        }
      }
    },
    [dispatch, guestList.guests, history, isUseMCDMSEnabled, register, goToCart]
  );

  useEffect(() => {
    if (!securityCheckinPopup && !scanPopup) {
      scanner.changeAction(processScan);
    }
  }, [scanner, processScan, securityCheckinPopup, scanPopup]);

  return null;
};

export const GuestListPage = () => (
  <ProvideScanner>
    <GuestListPopups />
    <GuestListPageWithoutPopups />
  </ProvideScanner>
);

const GuestListRefreshInfo = styled.div`
  text-align: right;
  width: 100%;
  padding-right: 24px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
`;

const GuestListPageDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #f2f3f4;
  overflow: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .kanban-button {
    align-items: flex-start;
    border: none;
    border-radius: 4px;
    padding: 9px 15px 6px 15px;
    text-align: center;
    color: white;
    line-height: 17px;
    height: 32px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    box-shadow: ${shadows.level3};
  }

  .kanban-create-guest {
    background: ${colors.accent5};

    &:hover {
      background-color: ${colors.primaryHover};
    }
  }

  .kanban-create-recreational-guest {
    background: ${colors.accent4};
  }

  .kanban-security-checkin-guest {
    background: ${colors.gold};
  }

  .kanban-create-preorder-guest {
    color: black;
    background: ${colors.accent6};
  }
`;

const ListModeDiv = styled(ScrollContainer)`
  ${Container};
  display: flex;
  flex: 1 0 0;
  margin-top: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Columns = styled.div`
  flex: 3 0;
`;
