import { useCallback } from 'react';
import {
  HardwareService,
  PeripheralType,
  useLabelPrinters,
  useReceiptPrinters,
  useScales,
  useScanners,
} from '@dutchie/capacitor-hardware';

import type { PeripheralSearchConfig } from '@dutchie/capacitor-hardware';

export type AuthorizeDeviceOptions = {
  showAll?: boolean;
};

export const useAuthorizeDevice = (deviceType: PeripheralType) => {
  const { search: searchLabelPrinters } = useLabelPrinters();
  const { search: searchReceiptPrinters } = useReceiptPrinters();
  const { search: searchScales } = useScales();
  const { search: searchScanners } = useScanners();

  const search = useCallback(
    (props?: Partial<PeripheralSearchConfig>) => {
      switch (deviceType) {
        case PeripheralType.labelPrinter:
          searchLabelPrinters(props);
          break;
        case PeripheralType.receiptPrinter:
          searchReceiptPrinters(props);
          break;
        case PeripheralType.scale:
          searchScales(props);
          break;
        case PeripheralType.scanner:
          searchScanners(props);
          break;
      }
    },
    [deviceType, searchLabelPrinters, searchReceiptPrinters, searchScales, searchScanners]
  );

  const setFilter = useCallback(
    (filter: boolean) => {
      switch (deviceType) {
        case PeripheralType.labelPrinter:
          HardwareService.labelPrinter.filterEnabled = filter;
          break;
        case PeripheralType.receiptPrinter:
          HardwareService.receiptPrinter.filterEnabled = filter;
          break;
        case PeripheralType.scale:
          HardwareService.scale.filterEnabled = filter;
          break;
        case PeripheralType.scanner:
          HardwareService.scanner.filterEnabled = filter;
          break;
      }
    },
    [deviceType]
  );

  const handleAuthorize = useCallback(
    (options?: AuthorizeDeviceOptions) => {
      const shouldFilterDevices = (options?.showAll ?? false) === false;
      setFilter(shouldFilterDevices);
      search({ requestNewDevices: true });
    },
    [setFilter, search]
  );

  return {
    handleAuthorize,
  };
};
