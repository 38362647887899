import React, { FC } from 'react';
import styled from 'styled-components';

import { BottomDrawer, Search } from 'components/layout';
import { Button } from 'components/buttons';
import { breakpoints, colors } from 'css/Theme';
import { NoMatchingCustomers } from './components/NoMatchingCustomers';
import { SearchPlaceholder } from './components/SearchPlaceholder';
import { useCustomerSelectionPanel } from './useCustomerSelectionPanel';
import { SearchResultsTable } from './components/SearchResultsTable';
import { LoadingResults } from './components/LoadingResults';

export const CustomerSelectionPanel: FC = () => {
  const {
    customerSearchResults,
    FullScreenPortalId,
    handleAttachCustomerToCart,
    handleClose,
    handleCreateNewProfile,
    handleSearch,
    isCustomerSelectionPanelOpen,
    isLoadingResults,
    isSearchEmpty,
    isSwitchingCustomer,
    searchInputPlaceholder,
    searchValue,
    switchCustomerLoadingMessage,
  } = useCustomerSelectionPanel();

  return (
    <BottomDrawer
      fullHeight
      open={isCustomerSelectionPanelOpen}
      onClose={handleClose}
      portalId={FullScreenPortalId}
      marginX={0}
      marginY={40}
    >
      {isCustomerSelectionPanelOpen && (
        <CustomerSelectionContainer data-testid='customer-selection-container'>
          <Header>
            <Title>Add existing profile</Title>
            <Actions>
              <Search auto onSearch={handleSearch} placeholder={searchInputPlaceholder} disabled={isLoadingResults} />
              <Buttons>
                <Button
                  transparentBlue
                  onClick={handleCreateNewProfile}
                  automationId='customer-selection-panel_buttons_create-new-profile'
                >
                  Create new profile
                </Button>
                <Button tertiary onClick={handleClose}>
                  Cancel
                </Button>
              </Buttons>
            </Actions>
          </Header>
          <Content>
            {isSwitchingCustomer ? (
              <LoadingResults message={switchCustomerLoadingMessage} />
            ) : (
              <>
                {isSearchEmpty && <SearchPlaceholder onCreateNewProfile={handleCreateNewProfile} />}
                {!isSearchEmpty && isLoadingResults && <LoadingResults />}
                {!isSearchEmpty && !isLoadingResults && !customerSearchResults && (
                  <NoMatchingCustomers search={searchValue} onCreateNewProfile={handleCreateNewProfile} />
                )}
                {!isSearchEmpty && !isLoadingResults && customerSearchResults && customerSearchResults.length > 0 && (
                  <SearchResultsTable
                    search={searchValue}
                    results={customerSearchResults}
                    onSelectCustomer={handleAttachCustomerToCart}
                  />
                )}
              </>
            )}
          </Content>
        </CustomerSelectionContainer>
      )}
    </BottomDrawer>
  );
};

const CustomerSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  align-items: center;
  justify-content: space-between;
  padding: 1.1875rem 2rem;
  gap: 5rem;
  border-bottom: 1px solid ${colors.dutchie.grey90};

  ${breakpoints.smallTablet.maxQuery} {
    gap: 1rem;
  }

  ${breakpoints.tallTablet.maxQuery} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
`;

const Actions = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1rem;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background: ${colors.dutchie.grey10};
`;
