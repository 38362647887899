import type { ProductSearchResult } from 'queries/v2/product/types';
import type { ScanResponse } from 'models/Cart';

type ImageUrl = string;

export const getProductImageURL = (foundItem: ScanResponse | ProductSearchResult): ImageUrl => {
  // ScanResponse
  if ('ProductImageURL' in foundItem && typeof foundItem.ProductImageURL === 'string') {
    return foundItem.ProductImageURL;
  }
  // ProductSearchResult
  if ('productImageURL' in foundItem && typeof foundItem.productImageURL === 'string') {
    return foundItem.productImageURL;
  }
  return '';
};
