import { LocationFeature, LocationIntegrations, LocationSettings } from './Location';

export type ValidatedFormsResponse = {
  FormName: string;
  Required: boolean;
  CustomerOption: number;
  DisplayName: string;
  ReactName: string;
  SystemRequired: boolean;
  ValidatedFormFieldId: number;
};

export type DefaultStatuses = {
  NotifyStatus: string;
  PreorderStatus: string;
  DefaultPosStatus: string;
  DeliveryStatus: string;
};

export type Reason = {
  ReasonId: number;
  Reason: string;
};

export type CloseoutRequest = {
  RegisterId: number;
  CashOpening: number;
  CashEnding: number;
  CashExpected: number;
  CashCounted: number;
  Difference: number;
  Deposit: string;
  SetRegisterBalance: number;
  DifferenceReason: string;
  EntryTypeId: number;
};

type CloseoutResult = {
  ResultId: number;
  ResultTxt: string;
};

export type CloseoutResponse = {
  Data: Array<CloseoutResult>;
  Message: string;
  Result: boolean;
};

export type DropCashRequest = {
  RegisterId: number;
  CashCounted: number;
  Note: string;
  EntryTypeId: number;
};

export type WithdrawCashRequest = {
  RegisterId: number;
  TransactionAmt: number;
  Comment: string;
  TransactionAdjustmentReasonId?: number;
};

export type DepositCashRequest = {
  RegisterId: number;
  TransactionAmt: number;
  Comment: string;
  TransactionAdjustmentReasonId?: number;
};

export type RegisterTranferRequest = {
  RegisterId: number;
  ToRegisterId: number;
  TransactionAmt: number;
  Comment: string;
  TransactionAdjustmentReasonId?: number;
};

export enum CustomerTypeIds {
  Medical = 1,
  Recreational = 2,
  Processor = 3,
  Distributor = 4,
  Retailer = 5,
  Cultivator = 6,
  RetailerTaxExempt = 7,
  ProcessorTaxExempt = 8,
  DistributorTaxExempt = 9,
  MMIC = 10,
  MicroBusiness = 11,
  Manufacturer = 12,
  RecreationalTaxExempt = 13,
  MedicalTaxExempt = 14,
  Lab = 15,
  Caregiver = 16,
  Minor = 17,
  NonCannabis = 18,
  RecreationalOutOfState = 19,
  TerminalPatient = 20,
  MedicalOutOfState = 21,
  Affiliate = 22,
  HempCBDOnly = 23,
  MedicalUnderage = 24,
  TribalMedicalMember = 25,
}

export type CustomerType = {
  CustomerTypeId: CustomerTypeIds;
  CustomerType: string;
  IsRetail: boolean;
  IsMedical: boolean;
};

export type SetMassDPHCreds = {
  IsEnabled: boolean;
  Username: string;
  Password: string;
  DPHUserId: number;
};

export type MassDphCreds = {
  Username: string;
  Password: string;
};

export type MassDphSettings = MassDphCreds & {
  IsEnabled: boolean;
  ManagerOverrideEnabled: boolean;
};

export enum BioTrackUserCredentialStatus {
  Loading = -1,
  NotBioTrack = 0,
  IndividualUserAccountsNotRequired = 1,
  UsernameIsNotAValidEmail = 2,
  UserAccountIsValid = 3,
  ResetPasswordRequired = 4,
  IndividualUserAccountCannotBeCreated = 5,
  LoginFailed = 6,
}

export type BioTrackValidateUserCredentialResult = {
  Status: BioTrackUserCredentialStatus;
  Message: string | null;
  Username: string;
};

export type ResetBioTrackUserCredentials = {
  Username: string;
  Password: string | null;
  ClearCredentials?: boolean | false;
};

export type ValidatedForms = Record<string, ValidatedField>;
export type ValidatedField = Record<string, FieldOption>;
export type FieldOption = {
  Required: boolean;
  Hidden: boolean;
};

export type Company = {
  LspId: number;
  LspName: string;
};

export type GetLspLocationsResponse = {
  IsBioTrackIntegrated: boolean;
  MDCompanyFlag: string;
  LocId: number;
  Name: string;
};

export type Banner = {
  banner: string;
};

export type BannerRequest = {
  url: string;
};

export type LogoResponse = {
  Logo: string;
};

export type OpenCashDrawerRequest = {
  PrinterId: number | string;
  LocId: number | string | null;
};

export type DeliverySettings = {
  Provider: number;
  PlaceholderDirections: string;
};

export type FeatureFlagsPaymentResultNotification = {
  Features: LocationFeature[];
  Integrations: LocationIntegrations;
  DeliverySettings: DeliverySettings;
  DefaultSettings: LocationSettings;
};
