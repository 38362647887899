import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SectionWithHeader } from 'components/layout';
import { callback, callback1 } from 'models/Misc';
import { AppliedDiscount } from 'models/Discounts';

import { colors } from 'css/Theme';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { CashAmount } from 'components/text';
import { removeAppliedDiscount } from 'store/actions/CartItemsActions';
import { useRemoveDiscounts } from 'util/hooks/cart/useRemoveDiscounts';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

export type AppliedDiscountsProps = {
  appliedDiscounts: Array<AppliedDiscount>;
  loadApplied: callback;
  loading: boolean;
  setLoading: callback1<boolean>;
};

export const AppliedDiscounts: FC<AppliedDiscountsProps> = (props) => {
  const { appliedDiscounts, loadApplied, loading, setLoading } = props;

  const dispatch = useDispatch();
  const removeDiscounts = useRemoveDiscounts();

  const { shipmentId } = useTransactionManager();

  const { data: cart } = useGetCartDetails();
  const refetchCartDetails = useRefetchCartDetails();

  const findGroups = (appliedDiscounts: Array<AppliedDiscount>) => {
    const appliedDiscountIds: Array<number> = [];
    const groups: Array<number> = [];

    appliedDiscounts.forEach((item) => {
      if (appliedDiscountIds.includes(item.DiscountId) && !groups.includes(item.DiscountId)) {
        groups.push(item.DiscountId);
      } else {
        appliedDiscountIds.push(item.DiscountId);
      }
    });
    return groups;
  };

  const groups: Array<number> = findGroups(appliedDiscounts);

  const removeDiscount = async (item: AppliedDiscount) => {
    const cartItem = cart?.Cart.find((cartItem) => item.InvId === cartItem.InventoryId);
    if (!loading) {
      setLoading(true);
      await dispatch(
        removeAppliedDiscount({
          InvId: item.AppliedDiscountId,
          ShipmentId: shipmentId,
          ProductId: cartItem?.ProductId,
          SerialNo: cartItem?.SerialNo,
        })
      );

      await refetchCartDetails();

      setLoading(false);
      loadApplied();
    }
  };

  const removeGroup = async (discountId: number) => {
    if (!loading) {
      setLoading(true);
      await removeDiscounts(discountId);

      await refetchCartDetails();

      setLoading(false);
      loadApplied();
    }
  };

  return (
    <Section title='Discounts Already Applied' empty={true}>
      <Container loading={loading} data-testid='applied-discounts_container_discount'>
        {appliedDiscounts.map((item) => (
          <Item key={item.AppliedDiscountId}>
            <ItemLabel>{item.DiscountReason}</ItemLabel>
            <ItemPrice>
              <CashAmount value={item.DiscountAmt} />
            </ItemPrice>
            {!item.IsLoyalty && (
              <Delete
                onClick={() => (groups.includes(item.DiscountId) ? removeGroup(item.DiscountId) : removeDiscount(item))}
              />
            )}
          </Item>
        ))}
      </Container>
    </Section>
  );
};

const Section = styled(SectionWithHeader)`
  flex-grow: 0.25;
`;

const Container = styled.div<{ loading: boolean }>`
  width: 100%;
  flex-grow: 1;
  padding: 1.25rem 1.5rem;
  cursor: ${({ loading }) => (loading ? 'progress' : 'default')};
`;

const Item = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 0.875rem;
  }
`;

const ItemLabel = styled.div`
  color: ${colors.dutchie.grey40};
  font-size: 1rem;
`;

const ItemPrice = styled.div`
  color: ${colors.dutchie.grey40};
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: auto;
`;

const Delete = styled(DeleteIcon)`
  width: 1.5rem;
  color: ${colors.dutchie.borderGrey};
  cursor: pointer;

  &:hover {
    color: ${colors.dutchie.red};
  }
`;
