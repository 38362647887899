import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { cleanScanString } from './helpers/cleanScanString';
import { customEventKeys, logger } from 'util/logger';
import { useAddScannedProductToCart } from './hooks/useAddScannedProductToCart';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useCartPopups } from 'components/CartPopups';
import { useScanner } from 'components/Scanner/useScanner';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { BarcodeScanner } from 'components/Scanner/BarcodeScanner';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

import type { AnonymousTransaction } from 'queries/v2/cart/types';
import type { State } from 'store';

export const OnScanUpdater = () => {
  const {
    data: { Cart: cartItems, Locked: isCartLocked },
  } = useGetCartDetails();

  const isCheckoutRunning = useSelector((state: State) => state.checkout.running);
  const isPackageItemIdsEnabled = useSelector((state: State) => state.settings.features.PackageItemIds);
  const selectedLspId = useSelector((state: State) => state.user.selectedLocation?.LspId);
  const blockScans = useSelector((state: State) => state.cart.blockScans);

  const cartPopups = useCartPopups();
  const addScannedProductToCart = useAddScannedProductToCart();
  const scanner = useScanner();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { guestId, startTransaction } = useTransactionManager();

  const onScan = useCallback(
    async (scanData: string, scannerId?: number | string) => {
      logger.debug(`barcode scanned (onScan): ${scanData}`, {
        key: customEventKeys.scanning.data,
        scannerId,
        string: scanData,
        isBlocked: blockScans,
      });

      if (blockScans) {
        return;
      }

      // Don't scan items into cart on the final checkout page
      // BUT allow users to scan regardless
      const addItem = async (transactionDetails?: AnonymousTransaction) => {
        if (!isCheckoutRunning && !isCartLocked) {
          const serialCleaned = cleanScanString(scanData, selectedLspId);

          if (isPackageItemIdsEnabled) {
            const itemAlreadyInCart = cartItems.find((item) => {
              return item.PackageItemId?.toString() === serialCleaned;
            });

            if (itemAlreadyInCart) {
              //Confirm with user that they want to remove it
              cartPopups.showConfirmRemovalPopup(itemAlreadyInCart);
              return;
            }
          }

          await addScannedProductToCart({
            guestIdOverride: transactionDetails?.guestId,
            scannerId,
            serialNumber: scanData,
            shipmentIdOverride: transactionDetails?.shipmentId,
          });
        }
      };

      // Check for anonymous guest and create transaction if needed
      if (isAnonymousCartLDFlagEnabled) {
        await startTransaction({ onSuccess: addItem });
      } else {
        await addItem();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      guestId,
      blockScans,
      isAnonymousCartLDFlagEnabled,
      isCheckoutRunning,
      isCartLocked,
      startTransaction,
      selectedLspId,
      isPackageItemIdsEnabled,
      addScannedProductToCart,
      cartItems,
      cartPopups,
    ]
  );

  useEffect(() => {
    scanner.changeAction(() => {});
  }, [scanner]);

  return <BarcodeScanner onScan={onScan} />;
};
