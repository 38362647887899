import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { CustomersList } from './CustomersList';
import { CustomerPreview } from './components/CustomerPreview';
import { SidePanel } from 'components/layout';
import { stopCheckoutRunning } from 'store/actions/CheckoutActions';
import { clearPreOrderMetadata } from 'store/actions/CustomerActions';
import { useDispatch } from 'react-redux';
import { store } from 'store';

const CustomersPageBaseDiv = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1 1 auto;
  position: relative;
  height: 100%;
`;

export const CustomersPage: FC = () => {
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    dispatch(stopCheckoutRunning());
    dispatch(clearPreOrderMetadata());
  }, [dispatch]);

  return (
    <CustomersPageBaseDiv>
      <CustomersList />
      <SidePanel>
        <CustomerPreview />
      </SidePanel>
    </CustomersPageBaseDiv>
  );
};
