import React from 'react';

export function HeartIcon() {
  return (
    <svg width='12' height='12' viewBox='0 0 23 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.4998 19.5239L2.71082 10.3572C1.93838 9.58537 1.42856 8.58992 1.25366 7.51205C1.07875 6.43418 1.24766 5.3286 1.73641 4.35212C2.10496 3.61523 2.64333 2.97646 3.30716 2.48843C3.97099 2.00041 4.74128 1.6771 5.55456 1.54515C6.36784 1.4132 7.20083 1.47638 7.98491 1.72949C8.76898 1.9826 9.4817 2.41839 10.0643 3.00095L11.4998 4.43554L12.9353 3.00095C13.518 2.41839 14.2307 1.9826 15.0147 1.72949C15.7988 1.47638 16.6318 1.4132 17.4451 1.54515C18.2584 1.6771 19.0287 2.00041 19.6925 2.48843C20.3563 2.97646 20.8947 3.61523 21.2632 4.35212C21.7513 5.32822 21.92 6.43315 21.7452 7.5104C21.5705 8.58766 21.0613 9.58265 20.2897 10.3545L11.4998 19.5239Z'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
