import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

export const endpoints = {
  GET_NON_INTEGRATED_PAYMENTS_SETTINGS: 'manual-payments/get-configured-processors',
};

export const nonIntegratedPaymentsQueryKey = 'non-integrated-payments-configured-processors';

export type NonIntegratedPaymentsSetting = {
  IsEnabled: boolean;
  LocId: number;
  LspId: number;
  ManualPaymentProcessorName: string;
  ManualPaymentProcessorId: number;
};

export function useNonIntegratedPaymentsConfiguredProcessorsQuery() {
  return useQuery({
    queryKey: [nonIntegratedPaymentsQueryKey],
    queryFn: async () => {
      return  await post<NonIntegratedPaymentsSetting[]>(endpoints.GET_NON_INTEGRATED_PAYMENTS_SETTINGS);
    },
  });
}

export function useEnabledNonIntegratedPaymentsProcessors() {
  const { data } = useNonIntegratedPaymentsConfiguredProcessorsQuery();
  const processors = data?.filter((processor) => processor.IsEnabled) ?? [];
  return {
    processors,
    hasEnabledManualPaymentProcessors: processors.length > 0,
  };
}
