import { round, sumBy } from 'lodash';
import { createSlice, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  PaymentMethod,
  DeliveryCar,
  DeliveryManifest,
  TransactionNotesResponse,
  PaymentType,
  Payments,
} from 'models/Checkout';
import { logout } from 'store/actions/UserActions';
import {
  bypassStateSystem,
  getDeliveryManifest,
  updateDeliveryManifest,
  setReadyForDelivery,
  getTransactionNotes,
  setTransactionNotes,
  getLoyaltySignups,
  cancelJoryTransaction,
  setApprovalCode,
  invalidApprovalCode,
  setManagerPin,
  invalidManagerPin,
  acceptEducationalMaterials,
  rejectEducationalMaterials,
  refreshCheckout,
  startCheckout,
  quitCheckout,
  addPaymentMethod,
  updatePaymentMethod,
  removePaymentMethod,
  cancelCheckout,
  checkPinAndPerformCheckout,
  performCheckout,
  checkPinForDigitalPayment,
  digitalPayment,
  paymentConfirmation,
  finishCheckout,
  getDeliveryDrivers,
  getDeliveryCars,
  updateLoyaltySignups,
  setSelectedPaymentType,
  setSelectedPayments,
  setAuthCode,
  setExistingPayments,
  stopSpinning,
  startPolling,
  stopPolling,
  finishCheckoutOnTwoPanelLayout,
  clearSelectedPaymentData,
  removePaymentType,
  stopCheckoutRunning,
  cleanDutchiePayPusherConfiguration,
  getDutchiePayPusherConfiguration,
  cleanPaymentsPusherConfiguration,
  getPaymentsPusherConfiguration,
} from 'store/actions/CheckoutActions';
import { DeliveryDriver } from 'models/Delivery';
import { PusherChannelConfiguration } from 'models/Pusher';

export type CheckoutState = {
  approvalCode: string;
  managerPin: string;
  loading: boolean;
  digitalPaymentLoading: boolean;
  paymentPollingIntervalId?: number;
  running: boolean;
  success: boolean;
  totalDue: number;
  changeDue: number;
  totalPaid: number;
  totalRemaning: number;
  invalidApprovalCode: boolean;
  invalidManagerPin: boolean;
  payment?: {
    methods: Array<PaymentMethod>;
  };
  existingPayments?: Array<PaymentMethod>;
  drivers: Array<DeliveryDriver>;
  driversLoading: boolean;
  cars: Array<DeliveryCar>;
  carsLoading: boolean;
  deliveryManifest?: DeliveryManifest;
  deliveryManifestLoading: boolean;
  updatingDeliveryManifest: boolean;
  educationalMaterialsAccepted?: boolean;
  transactionNotes?: Array<TransactionNotesResponse>;
  transactionNotesLoading: boolean;
  signups: Record<string, boolean>;
  joryTransactionCancelling: boolean;
  selectedPaymentType: PaymentType | null;
  selectedPaymentTotal?: number | null;
  cash?: number | null;
  debit?: number | null;
  check?: number | null;
  credit?: number | null;
  electronic?: number | null;
  prepayment?: number | null;
  gift?: number | null;
  mmap?: number | null;
  preAuth?: number | null;
  authCode?: string;
  tipAmount?: number;
  dutchiePayPusherConfig: PusherChannelConfiguration | null;
  pusherConfiguration: PusherChannelConfiguration;
};

const initialState: CheckoutState = {
  approvalCode: '',
  managerPin: '',
  loading: false,
  running: false,
  success: false,
  paymentPollingIntervalId: undefined,
  totalDue: 0,
  changeDue: 0,
  totalPaid: 0,
  totalRemaning: 0,
  invalidApprovalCode: false,
  invalidManagerPin: false,
  drivers: [],
  driversLoading: false,
  cars: [],
  carsLoading: false,
  deliveryManifest: undefined,
  deliveryManifestLoading: false,
  updatingDeliveryManifest: false,
  educationalMaterialsAccepted: undefined,
  transactionNotes: [],
  transactionNotesLoading: false,
  signups: {},
  joryTransactionCancelling: false,
  digitalPaymentLoading: false,
  selectedPaymentType: null,
  selectedPaymentTotal: null,
  cash: null,
  debit: null,
  check: null,
  credit: null,
  electronic: null,
  prepayment: null,
  gift: null,
  mmap: null,
  preAuth: null,
  authCode: '',
  tipAmount: undefined,
  dutchiePayPusherConfig: null,
  pusherConfiguration: {
    ChannelName: '',
    PusherCluster: '',
    PusherKey: '',
  },
};

//recalculate and recalculateSelectedPayment should really live outside of the checkoutReducer since it has concerns
//in both the cart and checkout reducer. We really shouldn't have this logic here

const recalculate = (totalDue: number, originalMethods: Array<PaymentMethod>) => {
  let methods = originalMethods;

  const dutchiePayMethod = methods.find((m) => m.name === 'dutchiepay');

  //if dutchie pay is being used, we need to ignore gti pay
  if (dutchiePayMethod) {
    methods = methods.filter((x) => x.name !== 'gtipay');
  }

  const methodsWithoutDutchiePay = methods.filter((m) => m.name !== 'dutchiepay');
  const totalPaidWithoutDutchiePay = sumBy(methodsWithoutDutchiePay, (m) => round(m.amount, 2));
  if (dutchiePayMethod) {
    // add tip to amount for Dutchie Pay to be displayed in POS-2020 UI
    dutchiePayMethod.amount = Math.max(totalDue + (dutchiePayMethod.tipAmount ?? 0) - totalPaidWithoutDutchiePay, 0);
  }

  const gtiPayMethod = methods.find((m) => m.name === 'gtipay');
  if (gtiPayMethod) {
    if (!gtiPayMethod.originalAmount) {
      gtiPayMethod.originalAmount = gtiPayMethod.amount;
    }

    if ((gtiPayMethod.originalAmount ?? 0) > totalDue) {
      gtiPayMethod.amount = totalDue;
    } else {
      gtiPayMethod.amount = gtiPayMethod.originalAmount;
    }
  }

  const totalPaid = sumBy(methods, (m) => round(m.amount, 2));
  const totalTip = sumBy(methods, (m) => round(m.tipAmount || 0, 2));

  let totalRemaning = round(round(totalDue, 2) - round(totalPaid, 2) + round(totalTip, 2), 2);
  totalRemaning = totalRemaning > 0 ? totalRemaning : 0;

  let changeDue = round(totalPaid, 2) - round(totalDue, 2) - round(totalTip, 2);
  changeDue = changeDue > 0 ? changeDue : 0;

  return { totalPaid, totalRemaning, changeDue, methods };
};

const recalculateSelectedPayment = (totalDue: number, selectedPayment: number, tipAmount: number | null) => {
  const totalPaid = selectedPayment;
  const totalTip = tipAmount ?? 0;

  let totalRemaning = round(round(totalDue, 2) - round(totalPaid, 2) + round(totalTip, 2), 2);
  totalRemaning = totalRemaning > 0 ? totalRemaning : 0;

  let changeDue = round(totalPaid, 2) - round(totalDue, 2) - round(totalTip, 2);
  changeDue = changeDue > 0 ? changeDue : 0;

  return { totalPaid, totalRemaning, changeDue };
};
export const _recalculate = recalculate;

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CheckoutState>) => {
    builder.addCase(logout, (state: CheckoutState) => {
      state.running = false;
      state.success = false;
      state.totalDue = 0;
      state.changeDue = 0;
      state.totalPaid = 0;
      state.totalRemaning = 0;
      state.payment = undefined;
      state.approvalCode = '';
      state.managerPin = '';
      state.invalidApprovalCode = false;
      state.invalidManagerPin = false;
      state.educationalMaterialsAccepted = undefined;
    });
    builder.addCase(setApprovalCode, (state: CheckoutState, action: PayloadAction<string>) => {
      state.invalidApprovalCode = action.payload.length !== 8;
      state.approvalCode = action.payload;
    });
    builder.addCase(invalidApprovalCode, (state: CheckoutState) => {
      state.invalidApprovalCode = true;
    });
    builder.addCase(setManagerPin, (state: CheckoutState, action: PayloadAction<string>) => {
      state.managerPin = action.payload;
    });
    builder.addCase(invalidManagerPin, (state: CheckoutState) => {
      state.invalidManagerPin = true;
    });
    builder.addCase(acceptEducationalMaterials, (state: CheckoutState) => {
      state.educationalMaterialsAccepted = true;
    });
    builder.addCase(rejectEducationalMaterials, (state: CheckoutState) => {
      state.educationalMaterialsAccepted = false;
    });
    builder.addCase(refreshCheckout, (state: CheckoutState, action: PayloadAction<number>) => {
      state.totalDue = action.payload;

      const overview = recalculate(state.totalDue, state.payment?.methods || []);
      state.payment = { methods: overview.methods };
      state.totalPaid = overview.totalPaid;
      state.totalRemaning = overview.totalRemaning;
      state.changeDue = overview.changeDue;
    });
    builder.addCase(
      setExistingPayments.fulfilled,
      (state: CheckoutState, action: PayloadAction<Array<PaymentMethod>>) => {
        const methods = action.payload.map((m) => {
          return { finalized: true, ...m };
        });
        state.payment = { methods: methods };
      }
    );
    builder.addCase(startCheckout, (state: CheckoutState, action: PayloadAction<number>) => {
      state.running = true;
      state.success = false;
      state.totalDue = action.payload;
      state.changeDue = 0;
      state.totalPaid = 0;
      state.totalRemaning = action.payload;

      state.payment = state.payment ?? { methods: [] };
      const overview = recalculate(state.totalDue, state.payment.methods);
      state.payment = { methods: overview.methods };
      state.totalPaid = overview.totalPaid;
      state.totalRemaning = overview.totalRemaning;
      state.changeDue = overview.changeDue;
    });
    builder.addCase(quitCheckout, (state: CheckoutState) => {
      state.running = false;
      state.totalDue = 0;
      state.changeDue = 0;
      state.totalPaid = 0;
      state.totalRemaning = 0;
      state.approvalCode = '';
      state.managerPin = '';
      state.invalidApprovalCode = false;
      state.invalidManagerPin = false;
      state.educationalMaterialsAccepted = undefined;
      state.selectedPaymentType = null;
      state.selectedPaymentTotal = null;
      state.loading = false;
      state.digitalPaymentLoading = false;
    });
    builder.addCase(
      addPaymentMethod,
      (state: CheckoutState, action: PayloadAction<PaymentMethod & { dutchiePayDoubleChargeFixRollout?: boolean }>) => {
        if (
          action.payload.dutchiePayDoubleChargeFixRollout &&
          state.payment?.methods?.some((pm) => pm.id === action.payload.id)
        ) {
          return;
        }

        if (state.payment?.methods?.length) {
          const methods = [...state.payment?.methods, action.payload];
          state.payment = { methods };
        } else {
          state.payment = { methods: [action.payload] };
        }

        const overview = recalculate(state.totalDue, state.payment.methods);
        state.payment = { methods: overview.methods };
        state.totalPaid = overview.totalPaid;
        state.totalRemaning = overview.totalRemaning;
        state.changeDue = overview.changeDue;
      }
    );
    builder.addCase(
      updatePaymentMethod,
      (state: CheckoutState, action: PayloadAction<{ index: number; method: PaymentMethod }>) => {
        const methods = state.payment ? [...state.payment.methods] : [];
        methods[action.payload.index] = action.payload.method;
        state.payment = { methods };

        const overview = recalculate(state.totalDue, state.payment.methods);
        state.payment = { methods: overview.methods };
        state.totalPaid = overview.totalPaid;
        state.totalRemaning = overview.totalRemaning;
        state.changeDue = overview.changeDue;
      }
    );
    builder.addCase(removePaymentMethod, (state: CheckoutState, action: PayloadAction<string>) => {
      const methods = state.payment ? [...state.payment.methods] : [];
      state.payment = {
        methods: methods.filter((m) => m.id !== action.payload),
      };

      const overview = recalculate(state.totalDue, state.payment.methods);
      state.payment = { methods: overview.methods };
      state.totalPaid = overview.totalPaid;
      state.totalRemaning = overview.totalRemaning;
      state.changeDue = overview.changeDue;
    });
    // TODO: remove this apparently unused method
    builder.addCase(cancelCheckout, (state: CheckoutState) => {
      state.running = false;
      state.success = false;
      state.totalDue = 0;
      state.changeDue = 0;
      state.totalPaid = 0;
      state.totalRemaning = 0;
      state.payment = undefined;
      state.approvalCode = '';
      state.managerPin = '';
      state.invalidApprovalCode = false;
      state.invalidManagerPin = false;
      state.educationalMaterialsAccepted = undefined;
    });
    builder.addCase(checkPinAndPerformCheckout.pending, (state: CheckoutState) => {
      state.invalidApprovalCode = false;
      state.invalidManagerPin = false;
    });
    builder.addCase(checkPinAndPerformCheckout.fulfilled, (state: CheckoutState) => {});
    builder.addCase(checkPinAndPerformCheckout.rejected, (state: CheckoutState) => {});
    builder.addCase(performCheckout.pending, (state: CheckoutState) => {
      state.loading = true;
    });
    builder.addCase(performCheckout.fulfilled, (state: CheckoutState) => {
      state.loading = false;
    });
    builder.addCase(performCheckout.rejected, (state: CheckoutState) => {
      state.loading = false;
    });
    builder.addCase(checkPinForDigitalPayment.pending, (state: CheckoutState) => {
      state.invalidManagerPin = false;
      state.loading = true;
    });
    builder.addCase(checkPinForDigitalPayment.fulfilled, (state: CheckoutState) => {
      state.loading = false;
    });
    builder.addCase(checkPinForDigitalPayment.rejected, (state: CheckoutState) => {
      state.loading = false;
    });
    builder.addCase(bypassStateSystem.pending, (state: CheckoutState) => {
      state.loading = true;
    });
    builder.addCase(bypassStateSystem.fulfilled, (state: CheckoutState) => {
      state.loading = false;
    });
    builder.addCase(bypassStateSystem.rejected, (state: CheckoutState) => {
      state.loading = false;
    });
    builder.addCase(digitalPayment.pending, (state: CheckoutState) => {
      state.loading = true;
      state.digitalPaymentLoading = true;
    });
    builder.addCase(digitalPayment.rejected, (state: CheckoutState) => {
      state.loading = false;
      state.digitalPaymentLoading = false;
    });
    builder.addCase(stopSpinning, (state: CheckoutState) => {
      state.loading = false;
      state.digitalPaymentLoading = false;
    });
    builder.addCase(startPolling.fulfilled, (state: CheckoutState, { payload }) => {
      state.paymentPollingIntervalId = payload;
      state.loading = true;
      state.digitalPaymentLoading = true;
    });
    builder.addCase(stopPolling.fulfilled, (state: CheckoutState) => {
      state.paymentPollingIntervalId = undefined;
      state.loading = false;
      state.digitalPaymentLoading = false;
    });
    builder.addCase(paymentConfirmation, (state: CheckoutState) => {
      state.running = true;
      state.success = true;
    });
    builder.addCase(finishCheckout, (state: CheckoutState) => {
      state.running = false;
      state.success = false;
      state.totalDue = 0;
      state.changeDue = 0;
      state.totalPaid = 0;
      state.totalRemaning = 0;
      state.payment = undefined;
      state.approvalCode = '';
      state.managerPin = '';
      state.invalidApprovalCode = false;
      state.invalidManagerPin = false;
      state.educationalMaterialsAccepted = undefined;
    });
    builder.addCase(finishCheckoutOnTwoPanelLayout, (state: CheckoutState) => {
      state.running = false;
      state.success = false;
      state.totalDue = 0;
      state.changeDue = 0;
      state.totalPaid = 0;
      state.totalRemaning = 0;
      state.payment = undefined;
      state.approvalCode = '';
      state.managerPin = '';
      state.invalidApprovalCode = false;
      state.invalidManagerPin = false;
      state.educationalMaterialsAccepted = undefined;
      state.authCode = '';
      state.selectedPaymentType = null;
      state.selectedPaymentTotal = null;
    });
    builder.addCase(getDeliveryDrivers.pending, (state: CheckoutState) => {
      state.driversLoading = true;
    });
    builder.addCase(getDeliveryDrivers.fulfilled, (state: CheckoutState, { payload }) => {
      state.driversLoading = false;
      state.drivers = payload;
    });
    builder.addCase(getDeliveryDrivers.rejected, (state: CheckoutState) => {
      state.driversLoading = false;
    });
    builder.addCase(getDeliveryCars.pending, (state: CheckoutState) => {
      state.carsLoading = true;
    });
    builder.addCase(getDeliveryCars.fulfilled, (state: CheckoutState, { payload }) => {
      state.carsLoading = false;
      state.cars = payload;
    });
    builder.addCase(getDeliveryCars.rejected, (state: CheckoutState) => {
      state.carsLoading = false;
    });
    builder.addCase(getDeliveryManifest.pending, (state: CheckoutState) => {
      state.deliveryManifestLoading = true;
    });
    builder.addCase(getDeliveryManifest.fulfilled, (state: CheckoutState, { payload }) => {
      state.deliveryManifestLoading = false;
      state.deliveryManifest = payload;
    });
    builder.addCase(getDeliveryManifest.rejected, (state: CheckoutState) => {
      state.deliveryManifestLoading = false;
    });
    builder.addCase(updateDeliveryManifest.pending, (state: CheckoutState) => {
      state.updatingDeliveryManifest = true;
    });
    builder.addCase(updateDeliveryManifest.fulfilled, (state: CheckoutState, { payload }) => {
      state.updatingDeliveryManifest = false;
      state.deliveryManifest = payload;
    });
    builder.addCase(updateDeliveryManifest.rejected, (state: CheckoutState) => {
      state.updatingDeliveryManifest = false;
    });
    builder.addCase(setReadyForDelivery.pending, (state: CheckoutState) => {
      state.updatingDeliveryManifest = true;
    });
    builder.addCase(setReadyForDelivery.fulfilled, (state: CheckoutState, { payload }) => {
      state.updatingDeliveryManifest = false;
      state.deliveryManifest = payload.DeliveryManifest;
    });
    builder.addCase(setReadyForDelivery.rejected, (state: CheckoutState) => {
      state.updatingDeliveryManifest = false;
    });
    builder.addCase(getTransactionNotes.pending, (state: CheckoutState) => {
      state.transactionNotesLoading = true;
    });
    builder.addCase(getTransactionNotes.fulfilled, (state: CheckoutState, { payload }) => {
      state.transactionNotesLoading = false;
      state.transactionNotes = payload;
    });
    builder.addCase(getTransactionNotes.rejected, (state: CheckoutState) => {
      state.transactionNotesLoading = false;
    });
    builder.addCase(setTransactionNotes.pending, (state: CheckoutState) => {
      state.transactionNotesLoading = true;
    });
    builder.addCase(setTransactionNotes.fulfilled, (state: CheckoutState, { payload }) => {
      state.transactionNotesLoading = false;
    });
    builder.addCase(setTransactionNotes.rejected, (state: CheckoutState) => {
      state.transactionNotesLoading = false;
    });
    builder.addCase(getLoyaltySignups.pending, (state: CheckoutState) => {
      state.loading = true;
    });
    builder.addCase(getLoyaltySignups.fulfilled, (state: CheckoutState, { payload }) => {
      state.loading = false;
      state.signups = payload;
    });
    builder.addCase(getLoyaltySignups.rejected, (state: CheckoutState) => {
      state.loading = false;
    });
    builder.addCase(updateLoyaltySignups, (state: CheckoutState, { payload }) => {
      state.signups = payload;
    });
    builder.addCase(cancelJoryTransaction.pending, (state: CheckoutState) => {
      state.joryTransactionCancelling = true;
    });
    builder.addCase(cancelJoryTransaction.fulfilled, (state: CheckoutState) => {
      state.joryTransactionCancelling = false;
    });
    builder.addCase(cancelJoryTransaction.rejected, (state: CheckoutState) => {
      state.joryTransactionCancelling = false;
    });
    builder.addCase(setSelectedPaymentType, (state: CheckoutState, action: PayloadAction<PaymentType | null>) => {
      state.selectedPaymentType = action.payload;
    });
    builder.addCase(setSelectedPayments, (state: CheckoutState, action: PayloadAction<Payments | null>) => {
      if (action.payload) {
        state.selectedPaymentTotal = action.payload.paymentSum;
        state.cash = action.payload.cash;
        state.debit = action.payload.debit;
        state.check = action.payload.check;
        state.credit = action.payload.credit;
        state.electronic = action.payload.digital;
        state.gift = action.payload.gift;
        state.mmap = action.payload.mmap;
        state.prepayment = action.payload.prepayment;

        if (action.payload.paymentSum) {
          const overview = recalculateSelectedPayment(state.totalDue, action.payload.paymentSum, state.tipAmount ?? 0);
          state.totalPaid = overview.totalPaid;
          state.totalRemaning = overview.totalRemaning;
          state.changeDue = overview.changeDue;
        }
      }
    });
    builder.addCase(setAuthCode, (state: CheckoutState, action: PayloadAction<string>) => {
      state.authCode = action.payload;
    });
    builder.addCase(clearSelectedPaymentData, (state: CheckoutState) => {
      const overview = recalculateSelectedPayment(state.totalDue, 0, 0);
      state.selectedPaymentType = null;
      state.selectedPaymentTotal = null;
      state.authCode = '';
      state.totalPaid = overview.totalPaid;
      state.totalRemaning = overview.totalRemaning;
      state.changeDue = overview.changeDue;

      state.selectedPaymentTotal = null;
      state.cash = null;
      state.debit = null;
      state.check = null;
      state.credit = null;
      state.electronic = null;
      state.prepayment = null;
      state.gift = null;
      state.mmap = null;
      state.preAuth = null;

      if (state.payment) {
        state.payment.methods = [];
      }

      state.existingPayments = [];
    });
    builder.addCase(removePaymentType, (state: CheckoutState, action: PayloadAction<PaymentType>) => {
      const methods = state.payment ? [...state.payment.methods] : [];
      state.payment = {
        methods: methods.filter((m) => m.type !== action.payload),
      };

      const overview = recalculate(state.totalDue, state.payment.methods);
      state.payment = { methods: overview.methods };
      state.totalPaid = overview.totalPaid;
      state.totalRemaning = overview.totalRemaning;
      state.changeDue = overview.changeDue;
    });
    builder.addCase(stopCheckoutRunning, (state) => {
      state.running = false;
    });
    builder.addCase(cleanDutchiePayPusherConfiguration, (state) => {
      state.dutchiePayPusherConfig = null;
    });
    builder.addCase(getDutchiePayPusherConfiguration.fulfilled, (state: CheckoutState, { payload }) => {
      state.dutchiePayPusherConfig = payload;
    });
    builder.addCase(cleanPaymentsPusherConfiguration.fulfilled, (state, action) => {
      state.pusherConfiguration.ChannelName = action.payload.ChannelName;
      state.pusherConfiguration.PusherCluster = action.payload.PusherCluster;
      state.pusherConfiguration.PusherKey = action.payload.PusherKey;
    });
    builder.addCase(getPaymentsPusherConfiguration.fulfilled, (state, action) => {
      if (action.payload) {
        state.pusherConfiguration = action.payload;
      }
    });
  },
});
