import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CheckoutContextProvider } from './CheckoutSidebar/CheckoutContext';
import { CheckoutSidebar } from './CheckoutSidebar';
import { DutchiePayInStorePanel } from './DutchiePayInStorePanel';
import { LoyaltyPoints } from './LoyaltyPoints';
import { ManageDiscounts } from './ManageDiscounts';
import { PanelDrawerWrapper } from './PanelDrawerWrapper';
import { Panels, useCartPanels } from './useCartPanels';
import { PaymentComplete } from './PaymentComplete';
import { RightPanelDimensions, SidePanel } from 'components/layout';
import { TotalsSidebar } from './TotalsSidebar';
import { ViewPrescription } from './ViewPrescription';

export type CartPanelsProps = {
  loading?: boolean;
};

const CartPanelsWithoutContext = ({ loading }: CartPanelsProps) => {
  const { activePanel, isDrawerOpen, renderPanelInPortal, showPanelsAsDrawer, usePortal } = useCartPanels({ loading });

  const panelToRender = useMemo(() => {
    switch (activePanel) {
      case Panels.InStoreDutchiePay:
        return <DutchiePayInStorePanel />;
      case Panels.LoyaltyPoints:
        return <LoyaltyPoints />;
      case Panels.ManageDiscounts:
        return <ManageDiscounts />;
      case Panels.PaymentComplete:
        return <PaymentComplete />;
      case Panels.PaymentSelection:
        return <CheckoutSidebar />;
      case Panels.TotalsSidebar:
        return <TotalsSidebar isLoading={loading} />;
      case Panels.ViewPrescription:
        return <ViewPrescription />;
    }
  }, [activePanel, loading]);

  return (
    <>
      {!showPanelsAsDrawer && (
        <PanelsContainer>
          {usePortal ? (
            <>
              {/* Render a blank panel underneath the portal to preserve the layout */}
              <SidePanel />
              {renderPanelInPortal(panelToRender)}
            </>
          ) : (
            <>{panelToRender}</>
          )}
        </PanelsContainer>
      )}
      <PanelDrawerWrapper isOpen={isDrawerOpen}>{showPanelsAsDrawer ? panelToRender : null}</PanelDrawerWrapper>
    </>
  );
};

export const CartPanels = ({ loading }: CartPanelsProps) => {
  return (
    <CheckoutContextProvider>
      <CartPanelsWithoutContext loading={loading} />
    </CheckoutContextProvider>
  );
};

const PanelsContainer = styled.div`
  ${RightPanelDimensions}

  & > * {
    /* We need to override the 'RightPanelDimensions' for any panel wrapped in this container */
    width: 100% !important;
  }
`;
