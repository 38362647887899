import React, { FC } from 'react';
import { SectionGridContainer, SectionHeader, SectionContent } from '../SettingPageComponents';
import { Switch } from 'components/inputs';
import { isAndroid } from 'util/hooks';
import { useFulfillmentPrinterPicker } from './hooks/useFulfillmentPrinterPicker';
import { HardwareLibraryReceiptPrinterDropdown } from './HardwareLibraryReceiptPrinterDropdown';

export const FulfillmentPrinterPicker: FC = () => {
  const { handleSwitchAutoPrint, handleSwitchPrinterTypes, isAutoPrintEnabled, isLocal } = useFulfillmentPrinterPicker();

  return (
    <SectionGridContainer>
      <SectionHeader>Fulfillment Ticket Printers</SectionHeader>
      <SectionContent>
        <Switch label='Auto Print Fulfillment Ticket' onChange={handleSwitchAutoPrint} checked={isAutoPrintEnabled} />
        {isAndroid && (
          <Switch label='Use Local Fulfillment Printer' onChange={handleSwitchPrinterTypes} checked={isLocal} />
        )}
        <HardwareLibraryReceiptPrinterDropdown printerType='fulfillment' connectionType={isLocal && isAndroid ? 'local' : 'network'} />
      </SectionContent>
    </SectionGridContainer>
  );
};
