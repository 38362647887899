import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as api from 'api/PosApi';
import { LabelResponse } from 'models/Misc';
import { logger, customEventKeys } from 'util/logger';
import { SearchLabelsContext } from 'util/logger/types/printing';
import { saveUserSettings } from 'store/actions/SettingsActions';

export const useLabels = () => {
  const dispatch = useDispatch();
  const [labels, setLabels] = useState<LabelResponse[]>([]);
  const [isLoadingLabels, setIsLoadingLabels] = useState(false);

  const handleSearchLabels = useCallback(
    async ({ userInitiated = false }: { userInitiated?: boolean }): Promise<void> => {
      setIsLoadingLabels(true);
      const labels = await api.getLabelsList();
      setLabels(labels);
      setIsLoadingLabels(false);

      logger.info<SearchLabelsContext>(`${userInitiated ? 'user initiated' : 'code initiated'} searchLabels called and found ${labels.length} labels`, {
        key: customEventKeys.printing.local.label.searchLabels,
        labels,
        userInitiated,
      });
    },
    []
  );

  // Fetch labels on mount
  useEffect(() => {
    handleSearchLabels({ userInitiated: false });
  }, [handleSearchLabels]);

  const handleChangeLabel = (value: string) => {
    const selectedLabel = labels.find((item) => item.id === parseInt(value, 10));
    if (selectedLabel) {
      dispatch(saveUserSettings({ selectedLabel }));
    }
  };

  const labelDropdownOptions = labels.map((label) => ({
    value: label.id,
    label: label.LabelDescription,
    key: label.id,
  }));

  return {
    labels,
    labelDropdownOptions,
    isLoadingLabels,
    handleSearchLabels,
    handleChangeLabel,
  };
};
