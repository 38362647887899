import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useRefetchProductsAfterEmptySearchResults = () => {
  const ldClient = useLDClient();
  const isRefetchProductsAfterEmptySearchResultsRolledBack: boolean = ldClient?.variation(
    'pos.register.refetch-products-after-empty-search-results.rollback',
    false
  );
  return { isRefetchProductsAfterEmptySearchResultsEnabled: !isRefetchProductsAfterEmptySearchResultsRolledBack };
};
