import React from 'react';

import { PaymentOptions as NewPaymentOptions } from './PaymentOptions';
import { PaymentOptions as OldPaymentOptions } from './PaymentOptions-deprecated';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

export const PaymentOptions = () => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  return isAnonymousCartLDFlagEnabled ? <NewPaymentOptions /> : <OldPaymentOptions />;
};

