import { Peripheral } from 'util/capacitor/peripheral';
import { logger } from 'util/logger';
import { PrintJob } from 'store/actions/PrintActions';
import { post } from 'api/HttpHelpers';
import { errorNotification } from 'store/actions/NotificationsActions';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PrintParams } from './types';

type PrintToLocalPrinterArgs = {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  localPrinterId: string;
  params: PrintParams;
};

export async function printToLocalPrinter({
  dispatch,
  params,
  localPrinterId,
}: PrintToLocalPrinterArgs): Promise<void> {
  const job = await post<PrintJob>('v2/print-jobs/get-label-job', { ...params, PrinterType: 'ZPL' });
  const bytes: number[] = [];
  const jobstring = atob(job.content);
  for (let i = 0; i < jobstring.length; i++) {
    bytes.push(jobstring.charCodeAt(i));
  }
  try {
    await Peripheral.printLabel({
      id: localPrinterId,
      bytes: bytes,
    });
  } catch (e) {
    dispatch(errorNotification(`Error Printing Labels: ${e}`));
    logger.error(e);
    return Promise.reject(e);
  }
}
