import React, { FC } from 'react';
import { CartPopupsState } from './CartPopupsState';
import { AddMoreItemsInPreOrderPopup } from './components/AddMoreItemsInPreOrderPopup';
import { AlpineIQRedeemPopup } from './components/AlpineIQRedeemPopup/AlpineIQRedeemPopup';
import { AssignPackageIDsPopup } from './components/AssignPackageIDsPopup';
import { BackdateTransactionPopup } from './components/BackdateTransactionPopup';
import { BulkCalculatorPopup } from './components/BulkCalculatorPopup';
import { CancelTransactionPopup } from '../sharedPopups/CancelTransactionPopup';
import { CanPayCheckoutPopup } from './components/CanPayCheckoutPopup';
import { CartItemRemovalPopup } from './components/CartItemRemovalPopup';
import { ClearCartPopup } from './components/ClearCartPopup';
import { ConfirmReleasePopup } from './components/ConfirmReleasePopup';
import { ConfirmRemovalPopup } from './components/ConfirmRemovalPopup';
import { DeliverySettingsPopup } from '../sharedPopups/DeliverySettingsPopup';
import { DeviceSelectPopup } from './components/DeviceSelectPopup';
import { DigitalManifestPopup } from './components/DigitalManifestPopup';
import { DigitalManifestSignaturePopup } from './components/DigitalManifestSignaturePopup';
import { DiscountPopup } from './components/DiscountPopup/DiscountPopup';
import { DutchiePayPopup } from './components/DutchiePayPopup';
import { EditProductPopup } from './components/EditProductPopup';
import { EducationalMaterialPopup } from './components/EducationalMaterialPopup';
import { EmailPopup } from '../sharedPopups/EmailPopup';
import { FeeDonationPopup } from './components/FeeDonationPopup/FeeDonationPopup';
import { FylloRedeemPopup } from './components/FylloRedeemPopup/FylloRedeemPopup';
import { HypurCheckoutPopup } from './components/HypurCheckoutPopup';
import { JournalPopup } from './components/JournalPopup';
import { LabsPopup } from '../sharedPopups/LabsPopup';
import { Last4Popup } from './components/Last4Popup';
import { ManagerPinPopup } from '../sharedPopups/ManagerPinPopup';
import { NotifyCustomerPopup } from '../sharedPopups/NotifyCustomerPopup';
import { OverLimitPopup } from './components/OverLimitPopup';
import { PastExpirationDatePopup } from './components/PastExpirationDatePopup';
import { RegisterForLoyaltyPopup } from './components/RegisterForLoyaltyPopup';
import { RemotePayPopup } from './components/RemotePayPopup';
import { ReRouteProductPopup } from './components/ReRouteProductPopup';
import { SetOrderStatusPopup } from '../sharedPopups/SetOrderStatusPopup';
import { SetTransactionReferencePopup } from '../sharedPopups/SetTransactionReferencePopup';
import { SpringBigRedeemPopup } from './components/SpringBigRedeemPopup';
import { TransactionNotesPopup } from './components/TransactionNotesPopup';
import { VerifyBirthdatePopup } from '../sharedPopups/VerifyBirthdatePopup';
import { VerifyTransactionPopup } from './components/VerifyTransactionPopup';
import { ViewAllotmentDetailPopup } from './components/ViewAllotmentDetailPopup';
import { VisitReasonPopup } from '../sharedPopups/VisitReasonPopup';

type CartPopupsProps = {
  cartPopupsState: CartPopupsState;
  closePopup: (popName?: string) => void;
};

/**
 * @deprecated: Use redux implementation instead, located in this folder in CartPopups.tsx
 */
export const CartPopupsDeprecated: FC<CartPopupsProps> = ({ cartPopupsState, closePopup }) => {
  const {
    addMoreItemsToPreOrderPopup,
    alpineIQRedeemPopup,
    assignPackageIDsPopup,
    backdateTransactionPopup,
    bulkCalculatorPopup,
    cancelTransactionPopup,
    canPayCheckoutPopup,
    clearCartPopup,
    confirmReleasePopup,
    confirmRemovalPopup,
    deliverySettingsPopup,
    digitalManifestPopup,
    discountPopup,
    dutchiePayPopup,
    editDevicePopup,
    editProductPopup,
    educationalMaterialPopup,
    emailPopup,
    feeDonationPopup,
    fylloRedeemPopup,
    hypurCheckoutPopup,
    journalPopup,
    labsPopup,
    last4Popup,
    managerPinPopup,
    notifyCustomerPopup,
    overLimitPopup,
    pastExpirationDatePopup,
    registerForLoyaltyPopup,
    remotePayPopup,
    removeCartItem,
    rerouteProductPopup,
    setStatusPopup,
    setTransactionReferencePopup,
    signaturePopup,
    springBigRedeemPopup,
    transactionNotesPopup,
    verifyBirthdatePopup,
    verifyTransactionPopup,
    viewAllotmentDetailPopup,
    visitReasonPopup,
  } = cartPopupsState;

  return (
    <>
      {addMoreItemsToPreOrderPopup && (
        <AddMoreItemsInPreOrderPopup
          {...addMoreItemsToPreOrderPopup}
          hide={() => closePopup('addMoreItemsToPreOrderPopup')}
        />
      )}
      {alpineIQRedeemPopup && <AlpineIQRedeemPopup {...alpineIQRedeemPopup} hide={closePopup} />}
      {assignPackageIDsPopup && <AssignPackageIDsPopup {...assignPackageIDsPopup} hide={closePopup} />}
      {backdateTransactionPopup && <BackdateTransactionPopup {...backdateTransactionPopup} hide={closePopup} />}
      {bulkCalculatorPopup && <BulkCalculatorPopup {...bulkCalculatorPopup} hide={closePopup} />}
      {cancelTransactionPopup && <CancelTransactionPopup {...cancelTransactionPopup} hide={closePopup} />}
      {canPayCheckoutPopup && <CanPayCheckoutPopup {...canPayCheckoutPopup} hide={closePopup} />}
      {clearCartPopup && <ClearCartPopup {...clearCartPopup} hide={closePopup} />}
      {confirmReleasePopup && <ConfirmReleasePopup {...confirmReleasePopup} hide={closePopup} />}
      {confirmRemovalPopup && <ConfirmRemovalPopup {...confirmRemovalPopup} hide={closePopup} />}
      {deliverySettingsPopup && <DeliverySettingsPopup {...deliverySettingsPopup} hide={closePopup} />}
      {digitalManifestPopup && <DigitalManifestPopup {...digitalManifestPopup} hide={closePopup} />}
      {discountPopup && <DiscountPopup {...discountPopup} hide={closePopup} />}
      {dutchiePayPopup && <DutchiePayPopup {...dutchiePayPopup} hide={closePopup} />}
      {editDevicePopup && <DeviceSelectPopup {...editDevicePopup} hide={closePopup} />}
      {editProductPopup && <EditProductPopup {...editProductPopup} hide={closePopup} />}
      {educationalMaterialPopup && <EducationalMaterialPopup {...educationalMaterialPopup} hide={closePopup} />}
      {emailPopup && <EmailPopup {...emailPopup} hide={closePopup} />}
      {feeDonationPopup && <FeeDonationPopup {...feeDonationPopup} hide={closePopup} />}
      {fylloRedeemPopup && <FylloRedeemPopup {...fylloRedeemPopup} hide={closePopup} />}
      {hypurCheckoutPopup && <HypurCheckoutPopup {...hypurCheckoutPopup} hide={closePopup} />}
      {journalPopup && <JournalPopup {...journalPopup} hide={closePopup} />}
      {labsPopup && <LabsPopup {...labsPopup} hide={closePopup} />}
      {last4Popup && <Last4Popup {...last4Popup} hide={closePopup} />}
      {managerPinPopup && <ManagerPinPopup {...managerPinPopup} hide={closePopup} />}
      {notifyCustomerPopup && <NotifyCustomerPopup {...notifyCustomerPopup} hide={closePopup} />}
      {overLimitPopup && <OverLimitPopup {...overLimitPopup} hide={closePopup} />}
      {pastExpirationDatePopup && <PastExpirationDatePopup {...pastExpirationDatePopup} hide={closePopup} />}
      {registerForLoyaltyPopup && <RegisterForLoyaltyPopup {...registerForLoyaltyPopup} hide={closePopup} />}
      {remotePayPopup && <RemotePayPopup {...remotePayPopup} hide={closePopup} />}
      {removeCartItem && <CartItemRemovalPopup {...removeCartItem} hide={() => closePopup('removeCartItem')} />}
      {rerouteProductPopup && <ReRouteProductPopup {...rerouteProductPopup} hide={closePopup} />}
      {setStatusPopup && <SetOrderStatusPopup {...setStatusPopup} hide={closePopup} />}
      {setTransactionReferencePopup && (
        <SetTransactionReferencePopup {...setTransactionReferencePopup} hide={closePopup} />
      )}
      {signaturePopup && <DigitalManifestSignaturePopup {...signaturePopup} hide={closePopup} />}
      {springBigRedeemPopup && <SpringBigRedeemPopup {...springBigRedeemPopup} hide={closePopup} />}
      {transactionNotesPopup && <TransactionNotesPopup {...transactionNotesPopup} hide={closePopup} />}
      {verifyBirthdatePopup && <VerifyBirthdatePopup {...verifyBirthdatePopup} hide={closePopup} />}
      {verifyTransactionPopup && <VerifyTransactionPopup {...verifyTransactionPopup} hide={closePopup} />}
      {viewAllotmentDetailPopup && <ViewAllotmentDetailPopup {...viewAllotmentDetailPopup} hide={closePopup} />}
      {visitReasonPopup && <VisitReasonPopup {...visitReasonPopup} hide={closePopup} />}
    </>
  );
};
