import React from 'react';

const originalDimensions = {
  width: 17,
  height: 17,
};

type SortDownIconProps = {
  width?: number;
  height?: number;
};

export const SortDownIcon = ({
  width = originalDimensions.width,
  height = originalDimensions.height,
}: SortDownIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}>
        <g clip-path='url(#clip0_1464_17428)'>
          <path
            d='M14.8434 12.458L12.3313 15.1186L9.75977 12.5153'
            stroke='#464F53'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path d='M12.3164 1.70117V15.1473' stroke='#464F53' stroke-width='1.5' stroke-linecap='round' />
          <path d='M1.52246 2.51807H9.26609' stroke='#464F53' stroke-width='1.5' stroke-linecap='round' />
          <path d='M3.83691 6.05811H9.26696' stroke='#464F53' stroke-width='1.5' stroke-linecap='round' />
          <path d='M6.55176 9.59863H9.26667' stroke='#464F53' stroke-width='1.5' stroke-linecap='round' />
        </g>
        <defs>
          <clipPath id='clip0_1464_17428'>
            <rect width={width} height={height} fill='white' transform='translate(0 0.5)' />
          </clipPath>
        </defs>
      </g>
    </svg>
  );
};
