import React from 'react';

type RegisterIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function RegisterIcon(props: RegisterIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.8 24.0001H2.2C1 24.0001 0 23.0001 0 21.8001V18.3001C0 17.1001 1 16.1001 2.2 16.1001H21.8C23 16.1001 24 17.1001 24 18.3001V21.8001C24 23.0001 23 24.0001 21.8 24.0001ZM2.2 17.5001C1.8 17.5001 1.4 17.9001 1.4 18.3001V21.8001C1.4 22.2001 1.8 22.6001 2.2 22.6001H21.8C22.2 22.6001 22.6 22.2001 22.6 21.8001V18.3001C22.6 17.9001 22.2 17.5001 21.8 17.5001H2.2Z'
        fill={color}
      />
      <path d='M12.1 14.7C11.5 14.7 11 14.2 11 13.6C11 13 11.5 12.5 12.1 12.5V14.7Z' fill={color} />
      <path
        d='M12.1016 14.7V12.5C12.7016 12.5 13.2016 13 13.2016 13.6C13.1016 14.2 12.7016 14.7 12.1016 14.7Z'
        fill={color}
      />
      <path d='M17.1 14.7C16.5 14.7 16 14.2 16 13.6C16 13 16.5 12.5 17.1 12.5V14.7Z' fill={color} />
      <path
        d='M17.1016 14.7V12.5C17.7016 12.5 18.2016 13 18.2016 13.6C18.2016 14.2 17.7016 14.7 17.1016 14.7Z'
        fill={color}
      />
      <path
        d='M6.99844 14.7C6.39844 14.7 5.89844 14.2 5.89844 13.6C5.99844 13 6.39844 12.5 6.99844 12.5V14.7Z'
        fill={color}
      />
      <path d='M7 14.7V12.5C7.6 12.5 8.1 13 8.1 13.6C8.1 14.2 7.6 14.7 7 14.7Z' fill={color} />
      <path
        d='M18.8016 5.2H15.8016C14.6016 5.2 13.6016 4.2 13.6016 3V2.2C13.6016 1 14.6016 0 15.8016 0H18.8016C20.0016 0 21.0016 1 21.0016 2.2V3C21.0016 4.2 20.0016 5.2 18.8016 5.2ZM15.8016 1.4C15.4016 1.4 15.0016 1.8 15.0016 2.2V3C15.0016 3.4 15.4016 3.8 15.8016 3.8H18.8016C19.2016 3.8 19.6016 3.4 19.6016 3V2.2C19.6016 1.8 19.2016 1.4 18.8016 1.4H15.8016Z'
        fill={color}
      />
      <path
        d='M12.8 10.4H5.8C5.3 10.4 5 10.1 5 9.7C5 9.3 5.3 9 5.7 9H12.7C13.1 9 13.4 9.3 13.4 9.7C13.4 10.1 13.1 10.4 12.8 10.4Z'
        fill={color}
      />
      <path
        d='M2.2 17.5C1.8 17.5 1.5 17.1 1.5 16.7L2.2 8.6C2.2 8 2.5 7.5 2.9 7.1C3.3 6.7 3.8 6.5 4.4 6.5C4.8 6.5 5.1 6.8 5.1 7.2C5.1 7.6 4.8 7.9 4.4 7.9C4.2 7.9 4 8 3.8 8.1C3.7 8.3 3.6 8.5 3.6 8.7L2.9 16.8C2.9 17.2 2.6 17.5 2.2 17.5Z'
        fill={color}
      />
      <path
        d='M21.8 17.5C21.4 17.5 21.1 17.2 21.1 16.9L20.4 8.8C20.4 8.6 20.3 8.4 20.1 8.3C20 8 19.8 7.9 19.6 7.9H14.2C13.8 7.9 13.5 7.6 13.5 7.2C13.5 6.8 13.8 6.5 14.2 6.5H19.6C20.2 6.5 20.7 6.7 21.1 7.1C21.5 7.5 21.8 8 21.8 8.6L22.5 16.7C22.5 17.1 22.2 17.4 21.8 17.5Z'
        fill={color}
      />
      <path
        d='M11.2 10.4H7.2C6.8 10.4 6.5 10.1 6.5 9.7V4.5C6.5 4.2 6.7 3.9 7.1 3.8L11.1 3C11.3 3 11.5 3 11.7 3.1C11.9 3.3 12 3.5 12 3.7V9.7C12 10.1 11.6 10.4 11.2 10.4ZM7.9 9H10.5V4.6L7.9 5.1V9Z'
        fill={color}
      />
      <path
        d='M14.3 20.6998H9.7C9.4 20.6998 9 20.3998 9 19.9998C9 19.5998 9.3 19.2998 9.7 19.2998H14.2C14.6 19.2998 14.9 19.5998 14.9 19.9998C14.9 20.3998 14.6 20.6998 14.3 20.6998Z'
        fill={color}
      />
      <path
        d='M17.3016 7.89981C16.9016 7.89981 16.6016 7.5998 16.6016 7.1998V4.4998C16.6016 4.0998 16.9016 3.7998 17.3016 3.7998C17.7016 3.7998 18.0016 4.0998 18.0016 4.4998V7.2998C18.0016 7.5998 17.7016 7.89981 17.3016 7.89981Z'
        fill={color}
      />
    </svg>
  );
}
