import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';

// The base styles for a modal from Figma
const CommonModalStyles = css`
  border-radius: 0.75rem;
  border: 1px solid ${colors.dutchie.grey90};
  background: ${colors.dutchie.primaryWhite};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06);
`;

/** Add these standard modal styles to your modal component */
export const StandardModalStyles = css`
  ${CommonModalStyles}
  width: clamp(400px, calc(100% - 2.5rem), 600px);
`;

export const MediumModalStyles = css`
  ${CommonModalStyles}
  width: clamp(600px, calc(100% - 2.5rem), 700px);
`;

/** Add these settings specific modal styles to your modal component */
export const SettingsModalStyles = css`
  ${CommonModalStyles}
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: clamp(640px, calc(100% - 2.5rem), 700px);
`;

/** Add these large modal styles to your modal component */
export const LargeModalStyles = css`
  ${CommonModalStyles}
  width: clamp(600px, calc(100% - 2.5rem), 900px);
`;

export enum ModalVariation {
  Standard = 'standard',
  Medium = 'medium',
  Large = 'large',
  SettingModal = 'settings',
}

/**
 * TODO: this file should probably have a more unique name, but at this point not sure what
 * other circumstances will use this modal type.
 */

export const ModalContainer = styled.div<{ variation?: ModalVariation }>`
  background: ${colors.dutchie.primaryWhite};
  border-radius: 20px;
  overflow: hidden;

  ${({ variation }) => {
    switch (variation) {
      case ModalVariation.Large:
        return LargeModalStyles;
      case ModalVariation.Medium:
        return MediumModalStyles;
      case ModalVariation.SettingModal:
        return SettingsModalStyles;
      case ModalVariation.Standard:
        return StandardModalStyles;
      default:
        // Not going to default to the standard variation yet since some
        // existing modals may not conform to the standard modal styles (e.g. border, shadow, border radius, etc.)
        return '';
    }
  }}
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${colors.dutchie.gray20};
`;

export const FloatingModalHeader = styled.div`
  padding: 16px;
`;

export const ModalCloseButton = styled.button`
  appearance: none;
  position: relative;
  display: block;
  border: none;
  height: 44px;
  min-width: 44px;
  border-radius: 8px;
  background: ${colors.dutchie.backgroundGrey};
  margin-left: auto;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    height: 2px;
    width: 20px;
    background: ${colors.dutchie.gray70};
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    background: ${colors.dutchie.gray40};
  }

  &:hover:before {
    background: ${colors.dutchie.black};
  }

  &:hover:after {
    background: ${colors.dutchie.black};
  }
`;

export const ModalContent = styled.div``;

export const ModalFooter = styled.div`
  border-top: 1px solid ${colors.dutchie.gray20};
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
`;
