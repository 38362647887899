import { useRef, useState, useMemo } from 'react';

import { useOnClickOutside } from 'util/hooks';

import type { TransitionDuration } from './ExpandableContent';

export const useExpandableContent = ({ transitionDuration }: { transitionDuration: TransitionDuration }) => {
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const expandedContentRef = useRef<HTMLDivElement>(null);

  const [inTransition, setInTransition] = useState(false);
  const [expandedContent, setExpandedContent] = useState<string | null>(null);

  const isContentExpanded = useMemo(() => expandedContent !== null, [expandedContent]);

  const toggleContent = (key: string) => {
    if (expandedContent === key) {
      setInTransition(false);
      // Wait until the animation complete before hiding unmounting the component
      setTimeout(() => {
        setExpandedContent(null);
      }, transitionDuration.exit);
    } else {
      setInTransition(true);
      setExpandedContent(key);
    }
  };

  useOnClickOutside(buttonContainerRef, (e) => {
    const target = e.target as HTMLElement;

    // Don't close the content if the click is inside the expanded content
    if (expandedContentRef.current?.contains(target)) {
      return;
    }

    if (expandedContent) {
      toggleContent(expandedContent);
    }
  });

  return {
    buttonContainerRef,
    expandedContent,
    expandedContentRef,
    inTransition,
    isContentExpanded,
    toggleContent,
  };
};
