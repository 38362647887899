import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { store, State } from 'store';
import { CheckedInGuest } from 'models/Guest';
import { cancelGuest, getGuests } from 'store/actions/GuestListActions';
import { ReturnsPusherChannel } from 'pages/TransactionsPage/TransactionDetails/components/ReturnPopup';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { ReturnItemNotification, ReturnPaymentOnlyRequest } from 'models/Cart';
import { processPaymentOnlyReturn } from 'store/actions/CartActions';
import { ExistingPaymentMethod } from 'models/Checkout';
import {
  CancelIntegratedPaymentTransactionBase,
  CancelContainerCenter,
  ConfrimRightButton,
  SpinnerContainer,
  Buttons,
  SweetGreenCheckmark,
  MessageContent,
  SuccessMessageContainer,
  GreySubHeading,
  GreyHeading,
} from './styles';
import { BudtenderPrompt } from './BudtenderPrompt';
import { CancelWithRefund } from './CancelWithRefund';
import { CancelWithoutRefund } from './CancelWithoutRefund';
import { Loader } from 'components/backoffice/loader';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';

type CancelIntegratedPaymentTransactionProps = {
  hide: () => void;
  guest: Pick<CheckedInGuest, 'Guest_id' | 'ShipmentId' | 'ScheduleId' | 'FullName'>;
  onCancel?: () => void;
  paymentMethods: ExistingPaymentMethod[];
};

export type CancelIntegratedPaymentChildProps = {
  shipmentId: number;
  guestName: string;
  last4: string;
  amount: string;
  hide: () => void;
  setCancelState: (state: CancelStates) => void;
  cancelState: CancelStates;
  setCancelReason: (reason: string) => void;
  cancelReason: string;
  processRefund: () => void;
  backToGuestList: () => void;
  cancelNoRefund: () => void;
};

export const CancelStates = {
  budtenderPrompt: 'budtenderPrompt',
  cancelWithoutRefund: 'cancelWithoutRefund',
  cancelWithRefund: 'cancelWithRefund',
  refunding: 'refunding',
  cancelWithRefundError: 'cancelWithRefundError',
  cancelledWithRefund: 'cancelledWithRefund',
  cancelling: 'cancelling',
} as const;

type CancelStates = typeof CancelStates[keyof typeof CancelStates];

export const CancelIntegratedPaymentTransaction: FC<CancelIntegratedPaymentTransactionProps> = ({
  hide,
  guest,
  onCancel,
  paymentMethods,
}) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  //const dispatch = useDispatch();
  const history = useHistory();

  const selectedRegister = useSelector((state: State) => state.settings.selectedRegister);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [last4, setLast4] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [cancelState, setCancelState] = useState<CancelStates>(CancelStates.budtenderPrompt);

  const isSmartCheckinActive = useSmartCheckinActive();

  const processRefund = () => {
    if (cancelReason?.length < 10) {
      dispatch(warningNotification('Please provide a reason'));
      return;
    }

    if (selectedRegister?.value === undefined) {
      dispatch(warningNotification('Must be selected to a register to process a refund on a debit transaction'));
      return;
    }

    setCancelState(CancelStates.refunding);

    const returnedItem: ReturnPaymentOnlyRequest = {
      ShipmentId: guest.ShipmentId,
      RegisterId: selectedRegister.value,
    };
    dispatch(processPaymentOnlyReturn({ item: returnedItem }));
  };

  const onReturnedCallback = useCallback(
    async (notification?: ReturnItemNotification, parseError?: unknown) => {
      if (
        notification &&
        notification.ShipmentId.toString() === guest.ShipmentId.toString() // redux says it's a number, but it's really a string
      ) {
        if (notification.Success) {
          await dispatch(
            cancelGuest({
              guestId: guest.Guest_id,
              shipmentId: guest.ShipmentId,
              scheduleId: guest.ScheduleId,
              cancelReason: cancelReason || '',
            })
          );

          setCancelState(CancelStates.cancelledWithRefund);
        } else {
          setCancelState(CancelStates.cancelWithRefundError);
        }
      } else if (parseError) {
        dispatch(errorNotification(`Failed to parse Pusher notification: ${parseError}`));
        setCancelState(CancelStates.cancelWithRefundError);
      }
    },
    [cancelReason, dispatch, guest.Guest_id, guest.ScheduleId, guest.ShipmentId]
  );

  const cancelNoRefund = async () => {
    setCancelState(CancelStates.cancelling);
    dispatch(
      cancelGuest({
        guestId: guest.Guest_id,
        shipmentId: guest.ShipmentId,
        scheduleId: guest.ScheduleId,
        cancelReason: cancelReason || '',
      })
    ).then(() => {
      if (onCancel) {
        onCancel();
      } else {
        if (!isSmartCheckinActive) {
          dispatch(getGuests());
        }

        if (history.location.pathname !== '/guestlist') {
          history.push('/guestlist');
        }
      }

      hide();
    });
  };

  useEffect(() => {
    setLast4(paymentMethods[0]?.Last4);
    setAmount(
      paymentMethods
        .map((p) => p.amount)
        .reduce((sum, x) => sum + x, 0)
        .toFixed(2)
    );
  }, [dispatch, paymentMethods]);

  const backToGuestList = async () => {
    if (onCancel) {
      onCancel();
    } else {
      if (!isSmartCheckinActive) {
        dispatch(getGuests());
      }

      if (history.location.pathname !== '/guestlist') {
        history.push('/guestlist');
      }
    }
    hide();
  };

  const CaptionText = () => {
    if (cancelState === CancelStates.budtenderPrompt) {
      return 'Cancel order';
    } else if (cancelState === CancelStates.cancelWithoutRefund) {
      return 'Cancel order without refund';
    } else {
      return 'Cancel order with refund';
    }
  };

  const childProps = {
    shipmentId: guest.ShipmentId,
    guestName: guest.FullName,
    last4,
    amount,
    hide,
    setCancelState,
    cancelState,
    setCancelReason,
    cancelReason,
    processRefund,
    backToGuestList,
    cancelNoRefund,
  };

  if (cancelState === CancelStates.budtenderPrompt) {
    return <BudtenderPrompt {...childProps} />;
  } else if (cancelState === CancelStates.cancelWithRefund || cancelState === CancelStates.cancelWithRefundError) {
    return <CancelWithRefund {...childProps} />;
  } else if (cancelState === CancelStates.cancelWithoutRefund) {
    return <CancelWithoutRefund {...childProps} />;
  }

  return (
    <CancelIntegratedPaymentTransactionBase caption={CaptionText()} isVisible hide={hide}>
      <ReturnsPusherChannel enabled={true} onPusherMessageCallback={onReturnedCallback} />
      <div data-testid='cancel-integrated-payment-popup_div_container'>
        {cancelState === CancelStates.cancelledWithRefund && (
          <CancelContainerCenter>
            <SweetGreenCheckmark />
            <SuccessMessageContainer>
              <MessageContent>
                <GreyHeading>
                  <b>Order #{guest.ShipmentId} has been successfully cancelled.</b>
                </GreyHeading>
                <GreySubHeading>
                  <b>${amount}</b> has been refunded to card ending in <b>{last4}.</b>
                </GreySubHeading>
              </MessageContent>
            </SuccessMessageContainer>
            <Buttons>
              <ConfrimRightButton
                type='button'
                automationId='confirmation-popup_confirm-button_confirm'
                onClick={backToGuestList}
              >
                Return to guest list
              </ConfrimRightButton>
            </Buttons>
          </CancelContainerCenter>
        )}
        {cancelState === CancelStates.refunding && (
          <CancelContainerCenter>
            <SpinnerContainer>
              <Loader size={'3x'} color={'grey'} variant={'grey'} />
              <b>Processing refund</b>
            </SpinnerContainer>
          </CancelContainerCenter>
        )}
        {cancelState === CancelStates.cancelling && (
          <CancelContainerCenter>
            <SpinnerContainer>
              <Loader size={'3x'} color={'grey'} variant={'grey'} />
              <b>Cancelling</b>
            </SpinnerContainer>
          </CancelContainerCenter>
        )}
      </div>
    </CancelIntegratedPaymentTransactionBase>
  );
};
