import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from 'css/Theme';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { Chevron, ChevronDirections } from 'assets/icons/chevron';
import { Button } from 'components/buttons';
import { CashAmount } from 'components/text';
import { Skeleton } from 'components/misc';
import { TotalBreakdownDrawer } from '../TotalBreakdownDrawer';

import { useTotalMinimized } from './useTotalMinimized';

import type { Cart } from 'models/Cart';

type TotalMinimizedProps = {
  cart: Partial<Cart>;
  isCartLoading?: boolean;
  isStatefulCheckoutEnabled: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Prop drilling for cart, isCartLoading, and isStatefulCheckoutEnabled is only used because this component
 * is used by both the regular cart and the precart. Once we get rid of the pre cart, we can just get these
 * values inside useTotalMinimized
 */
export const TotalMinimized = ({
  cart,
  isCartLoading = false,
  isStatefulCheckoutEnabled,
  isOpen,
  setIsOpen,
}: TotalMinimizedProps) => {
  const {
    grandTotalRounded,
    floatingTotalsRef,
    totalWithDiscountsAndLoyalty,
    isLoading,
    showStrikethroughSubtotal,
    showRecalculateTotalIcon,
    showGetTotalButton,
    handleClickArrowButton,
    handleClickGetTotal,
  } = useTotalMinimized({ cart, isCartLoading, isStatefulCheckoutEnabled, isOpen, setIsOpen });

  return (
    <OrderTotalContainer isOpen={isOpen} data-testid='total-minimized'>
      <FloatingTotalBreakdownDrawer
        ref={floatingTotalsRef}
        cart={cart}
        isOpen={isOpen}
        isLoading={isCartLoading}
        automationId='floating-total-breakdown-drawer'
      />
      <Total>
        <Label>Order total</Label>
        {showGetTotalButton ? (
          <GetTotalButton
            automationId='total-minimized-get-total-button'
            disabled={isLoading}
            onClick={handleClickGetTotal}
          >
            Get total
          </GetTotalButton>
        ) : (
          <PriceContainer>
            {showRecalculateTotalIcon && (
              <StyledRefreshIcon data-testid='total-minimized-refresh-total' onClick={handleClickGetTotal} />
            )}
            {isLoading ? (
              <Skeleton width={70} />
            ) : (
              <>
                <GrandTotal automationId='total-minimized-grand-total-amount' value={grandTotalRounded} />
                {showStrikethroughSubtotal && (
                  <SubtotalStrike
                    automationId='total-minimized-subtotal-strike-amount'
                    value={totalWithDiscountsAndLoyalty}
                  />
                )}
              </>
            )}
          </PriceContainer>
        )}
      </Total>
      <ArrowButton
        isOpen={isOpen}
        onClick={handleClickArrowButton}
        data-testid='toggle-total-breakdown-drawer-caret'
        data-dd-action-name={`total breakdown drawer toggle (${isOpen ? 'open' : 'closed'})`}
      >
        <StyledChevron
          height={16}
          width={16}
          direction={ChevronDirections.UP}
          fill={colors.dutchie.black}
          isOpen={isOpen}
        />
      </ArrowButton>
    </OrderTotalContainer>
  );
};

const OrderTotalContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  border: ${({ isOpen }) => `1px solid ${isOpen ? colors.dutchie.gray100 : colors.dutchie.gray30}`};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const FloatingTotalBreakdownDrawer = styled(TotalBreakdownDrawer)`
  display: none;

  ${breakpoints.smallTablet.maxQuery} {
    display: flex;
  }

  left: 0;
  bottom: calc(100% + 0.25rem);
  width: calc(100% - 2.75rem);

  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 0.5rem;

  &.total-breakdown-drawer-enter {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.2s ease;
  }

  &.total-breakdown-drawer-enter-active {
    transform: translateY(0);
    opacity: 1;
  }

  &.total-breakdown-drawer-exit {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s ease;
  }

  &.total-breakdown-drawer-exit-active {
    transform: translateY(20%);
    opacity: 0;
  }
`;

const Total = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Label = styled.div`
  font-weight: 600;
`;

const ArrowButton = styled.button<{ isOpen: boolean }>`
  border: none;
  border-left: ${({ isOpen }) => `1px solid ${isOpen ? colors.dutchie.gray100 : colors.dutchie.gray30}`};
  background: ${({ isOpen }) => (isOpen ? colors.dutchie.grey20 : 'transparent')};
  border-radius: 0 8px 8px 0;
  width: 44px;
  cursor: pointer;
`;

const StyledChevron = styled(Chevron)<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(180deg);
  `}

  transition: all .2s ease-in-out;
  pointer-events: none;
`;

const PriceContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const GrandTotal = styled(CashAmount)`
  font-weight: 600;
`;

const SubtotalStrike = styled(CashAmount)`
  color: ${colors.dutchie.gray70};
  text-decoration: line-through;
`;

const GetTotalButton = styled(Button)`
  margin-left: auto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 170%;
  letter-spacing: 0.005em;
  background: none;
  color: ${colors.dutchie.blue};
  padding: 0;
  border: none;
  height: 27px;

  &:hover {
    background: none;
    color: ${colors.dutchie.blue};
  }
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  width: 24px;
  height: 24px;
  path {
    stroke: ${colors.dutchie.blue};
  }
  cursor: pointer;
`;
