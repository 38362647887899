import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { sendRemotePayEmail, sendRemotePayText } from 'api/CustomerApi';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { callback } from 'models/Misc';

const InputBox = styled.div`
  padding: 0 1.5rem;
  &:not(:first-child) {
    padding-top: 1.5rem;
  }
`;

type RemotePayPopupProps = {
  hide: callback;
  shipmentId?: number;
};

export const RemotePayPopup: FC<RemotePayPopupProps> = ({ hide, shipmentId }) => {
  const dispatch = useDispatch();
  const { details } = useSelector((state: State) => state.customer);

  const [email, setEmail] = useState<string>(details?.Email || '');
  const [phone, setPhone] = useState<string>(details?.CellPhone || details?.PhoneNo || '');

  const sendEmail = async () => {
    try {
      if (shipmentId && email) {
        await sendRemotePayEmail({
          ShipmentId: shipmentId,
          EmailAddress: email,
        });
        dispatch(successNotification('Email Sent'));
        hide();
      }
    } catch {
      dispatch(errorNotification('Email failed to send'));
    }
  };

  const sendText = async () => {
    try {
      if (shipmentId && phone) {
        await sendRemotePayText({
          ShipmentId: shipmentId,
          PhoneNumber: phone,
        });
        dispatch(successNotification('Text Sent'));
        hide();
      }
    } catch {
      dispatch(errorNotification('Text failed to send'));
    }
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Remote Pay'
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <InputBox>
        <Input
          placeholder='Email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          endAdornment={
            <Button isAdornment disabled={email === ''} onClick={sendEmail}>
              Send Email
            </Button>
          }
        />
      </InputBox>
      <InputBox>
        <Input
          placeholder='Cell Phone #'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          endAdornment={
            <Button isAdornment disabled={phone === ''} onClick={sendText}>
              Send Text
            </Button>
          }
        />
      </InputBox>
    </ConfirmationPopup>
  );
};
