import React, { FC } from 'react';

import {
  Title,
  StyledLinkButton,
  AllotmentHeaderContainer,
  AllotmentBarContainer,
  ProgressBar,
  Fillbar,
  AllotmentAmounts,
  ProgressBarSkeleton,
} from '../../TotalsSidebarSection.styles';
import { Skeleton } from 'components/misc';
import { useAllotmentLimit } from './useAllotmentLimit';

import type { PreorderCartItem } from 'pages/CreatePreOrderPage';

export type AllotmentLimitProps = {
  loading?: boolean;
  preorderCartItems?: PreorderCartItem[];
  showDetailsButton?: boolean;
};

export const AllotmentLimit: FC<AllotmentLimitProps> = ({ loading, preorderCartItems, showDetailsButton }) => {
  const { handleClickDetails, over, remaining, used, usedPercentage } = useAllotmentLimit({
    preorderCartItems,
  });

  return (
    <>
      <AllotmentHeaderContainer>
        <Title>Allotment</Title>
        {showDetailsButton && (
          <StyledLinkButton
            label='Details'
            disabled={loading}
            onClick={handleClickDetails}
            automationId='legal-limit_details-button_details'
            data-dd-action-name='clicked details button on allotment bar'
          />
        )}
      </AllotmentHeaderContainer>
      <AllotmentBarContainer>
        {loading ? (
          <ProgressBarSkeleton />
        ) : (
          <ProgressBar>
            <Fillbar used={usedPercentage} over={over} />
          </ProgressBar>
        )}
        {loading ? (
          <Skeleton width='150px' height='14px' />
        ) : (
          <AllotmentAmounts>
            <span data-testid='legal-limit_b_used-amount'>{used} used</span>
            <span>/</span>
            <span data-testid='legal-limit_b_remaining-amount'>{remaining} remaining</span>
          </AllotmentAmounts>
        )}
      </AllotmentBarContainer>
    </>
  );
};
