import React from 'react';

type SalesHistoryIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function SalesHistoryIcon(props: SalesHistoryIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.165 8.90283C12.5516 8.90291 12.8649 9.21637 12.8648 9.60297L12.8638 14.603C12.8638 14.7885 12.7901 14.9665 12.6589 15.0977L9.6599 18.0977C9.38658 18.3711 8.94336 18.3712 8.66995 18.0979C8.39654 17.8246 8.39647 17.3814 8.66979 17.1079L11.4639 14.3129L11.4648 9.60269C11.4649 9.21609 11.7784 8.90275 12.165 8.90283Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2543 0.487917C15.4559 0.817796 15.3519 1.24864 15.022 1.45023L11.3668 3.68397L14.5984 6.30965C14.8985 6.55344 14.9441 6.9943 14.7003 7.29435C14.4565 7.59439 14.0157 7.64 13.7156 7.39621L9.71562 4.14621C9.53924 4.00291 9.44341 3.7829 9.4586 3.55615C9.47379 3.3294 9.59811 3.12414 9.79202 3.00564L14.292 0.255635C14.6219 0.0540429 15.0527 0.158039 15.2543 0.487917Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0953 2.94676C11.9749 2.60107 13.9132 2.77309 15.7025 3.44441C17.4919 4.11574 19.065 5.26108 20.2534 6.75782C21.4418 8.25455 22.2007 10.0463 22.449 11.9413C22.6972 13.8362 22.4254 15.763 21.6627 17.5154C20.8999 19.2677 19.6749 20.7796 18.1188 21.8892C16.5628 22.9988 14.7342 23.6643 12.829 23.8144C10.9237 23.9645 9.01351 23.5936 7.30287 22.7415C5.59222 21.8893 4.14555 20.5879 3.11775 18.9767C2.90984 18.6507 3.00551 18.218 3.33145 18.0101C3.65738 17.8022 4.09014 17.8978 4.29805 18.2238C5.1893 19.6209 6.44376 20.7494 7.92712 21.4884C9.41048 22.2273 11.0669 22.5489 12.719 22.4187C14.3711 22.2885 15.9567 21.7115 17.306 20.7493C18.6553 19.7872 19.7176 18.4762 20.379 16.9566C21.0404 15.4371 21.2761 13.7663 21.0608 12.1231C20.8456 10.4799 20.1875 8.92624 19.157 7.62837C18.1265 6.33049 16.7624 5.33733 15.2108 4.7552C13.6591 4.17307 11.9784 4.02391 10.3485 4.32367C9.9683 4.3936 9.60338 4.14206 9.53345 3.76184C9.46352 3.38161 9.71506 3.01669 10.0953 2.94676Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.44781 4.56875C7.65114 4.89756 7.54942 5.32895 7.22061 5.53228C6.09316 6.22947 5.12834 7.16036 4.39126 8.26214C4.1763 8.58346 3.74155 8.66969 3.42022 8.45472C3.0989 8.23976 3.01267 7.80501 3.22764 7.48369C4.07604 6.21551 5.18656 5.14403 6.48429 4.34155C6.8131 4.13822 7.24448 4.23994 7.44781 4.56875Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.46145 10.811C2.84196 10.8794 3.09497 11.2433 3.02658 11.6238C2.79175 12.9302 2.83299 14.2714 3.14767 15.561C3.23931 15.9366 3.00914 16.3153 2.63356 16.407C2.25798 16.4986 1.87922 16.2684 1.78757 15.8929C1.42658 14.4135 1.37926 12.8749 1.64866 11.3761C1.71705 10.9956 2.08095 10.7426 2.46145 10.811Z'
        fill={color}
      />
    </svg>
  );
}
