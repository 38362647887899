import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';

export function useExpandedDeliveriesCoords() {
  const expandedRoute = useSelector((state: State) => state.deliveryList.expandedRoute);
  const displayDeliveries = useSelector((state: State) => state.deliveryList.displayDeliveries);
  const dispensaryLat = useSelector((state: State) => state.settings.Lat);
  const dispensaryLng = useSelector((state: State) => state.settings.Lng);

  return useMemo(() => {
    if (!expandedRoute) {
      return [];
    }

    const expandedRouteId = expandedRoute.DeliveryRouteId;
    const expandedDeliveries = displayDeliveries
      .filter((delivery) => {
        return delivery.DeliveryRouteId === expandedRouteId;
      })
      .sort((a, b) =>
        (a.ManifestStopNumber || Number.MAX_SAFE_INTEGER) > (b.ManifestStopNumber || Number.MAX_SAFE_INTEGER) ? 1 : -1
      );

    const coords = expandedDeliveries.map((expandedDelivery) => {
      return [expandedDelivery.Lng, expandedDelivery.Lat];
    });

    if (dispensaryLng && dispensaryLng) {
      coords.unshift([dispensaryLng, dispensaryLat]);
    }

    return coords;
  }, [dispensaryLat, dispensaryLng, displayDeliveries, expandedRoute]);
}

