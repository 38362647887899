import { useLabelPrinters, useReceiptPrinters, useScales, useScanners, ReceiptPrinter, LabelPrinter, Scale, Scanner, Peripheral } from "@dutchie/capacitor-hardware";
import { useLocalStorage } from "..";
import { HARDWARE_LIBRARY_STORAGE_KEYS } from "util/hardwareLibrary/hardware-library-utils";

type ConnectedDevice<T extends Peripheral> = {
  hasPreferredDevice: boolean;
  device: T | undefined;
};

export const useConnectedFulfillmentPrinter = (): ConnectedDevice<ReceiptPrinter> => {
  const { value } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.FULFILLMENT_PRINTER_ID);
  const { receiptPrinters } = useReceiptPrinters();

  return {
    hasPreferredDevice: !!value,
    device: receiptPrinters.find(it => it.id === value),
  };
};

export const useConnectedLabelPrinter = (): ConnectedDevice<LabelPrinter> => {
  const { value } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.LABEL_PRINTER_ID);
  const { labelPrinters } = useLabelPrinters();

  return {
    hasPreferredDevice: !!value,
    device: labelPrinters.find(it => it.id === value),
  };
};

export const useConnectedReceiptPrinter = (): ConnectedDevice<ReceiptPrinter> => {
  const { value } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.RECEIPT_PRINTER_ID);
  const { receiptPrinters } = useReceiptPrinters();

  return {
    hasPreferredDevice: !!value,
    device: receiptPrinters.find(it => it.id === value),
  };
};

export const useConnectedScale = (): ConnectedDevice<Scale> => {
  const { value } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.SCALE_ID);
  const { scales } = useScales();

  return {
    hasPreferredDevice: !!value,
    device: scales.find(it => it.id === value),
  };
};

export const useConnectedScanner = (): ConnectedDevice<Scanner> => {
  const { value } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.SCANNER_ID);
  const { scanners } = useScanners();

  return {
    hasPreferredDevice: !!value,
    device: scanners.find(it => it.id === value),
  };
};

export const useConnectedZReportPrinter = (): ConnectedDevice<ReceiptPrinter> => {
  const { value } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.Z_REPORT_PRINTER_ID);
  const { receiptPrinters } = useReceiptPrinters();

  return {
    hasPreferredDevice: !!value,
    device: receiptPrinters.find(it => it.id === value),
  };
};
