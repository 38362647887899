import React from 'react';

import { ShowLoyaltyButtonContainer, DeleteIcon } from '../Discount.styles';
import { Chevron } from 'assets/icons/chevron';

import { CART_LINE_ITEM_TEST_IDS } from '../../../CartLineItem.test-ids';

type DiscountActionButtonProps = {
  onClick: () => void;
  isLoyalty?: number | boolean;
};

export const DiscountActionButton = ({ onClick, isLoyalty }: DiscountActionButtonProps) => {
  return (
    <>
      {isLoyalty ? (
        <ShowLoyaltyButtonContainer onClick={onClick}>
          <Chevron data-testid={CART_LINE_ITEM_TEST_IDS.showLoyaltyPanelTestId} />
        </ShowLoyaltyButtonContainer>
      ) : (
        <DeleteIcon onClick={onClick} data-testid={CART_LINE_ITEM_TEST_IDS.removeDiscountTestId} />
      )}
    </>
  );
};
