import React from 'react';
import { SxProps } from '@mui/material';
import { CheckboxBase, CheckboxBaseProps } from '../checkbox';
import { RebrandMenuItem, MenuItemSubText, LabelVariantContainer } from './menu-item-rebrand.styles';

export type MenuItemVariantTypes = 'checkbox' | 'default' | 'label' | 'username';

// TODO-AIRBENDER: different prop types based on which variant is passed
type MenuItemProps = {
  checkboxLabel?: string;
  checkboxProps?: CheckboxBaseProps;
  checked?: boolean;
  children: React.ReactNode | string;
  danger?: boolean;
  disabled?: boolean;
  key?: string;
  labelSecondary?: string;
  onClick?: (...any) => void;
  footer?: React.ReactNode;
  sx?: SxProps;
  value: boolean | string;
  variant?: MenuItemVariantTypes;
  automationId?: string;
};

export function MenuItem(props: MenuItemProps) {
  const {
    checkboxLabel,
    checkboxProps,
    checked,
    children,
    danger = false,
    disabled = false,
    labelSecondary,
    footer,
    sx,
    variant = 'default',
  } = props;

  return (
    <RebrandMenuItem danger={danger} disabled={disabled} {...props} $variant={variant} sx={sx}>
      {variant === 'checkbox' && <CheckboxBase {...checkboxProps} checked={checked} />}
      {variant === 'username' && <span>Logged in as</span>}
      {['default', 'username'].includes(variant) && children}
      {['checkbox', 'label'].includes(variant) && (
        <LabelVariantContainer $hasFooter={Boolean(footer)} $variant={variant}>
          <div className='main-label'>
            {variant === 'checkbox' && <span className='menu-item--primary-label'>{checkboxLabel}</span>}
            {Boolean(children) && <span className='menu-item--primary-label'>{children}</span>}
            {labelSecondary?.length > 0 && <span className='menu-item--label-text'>{labelSecondary}</span>}
          </div>
          {Boolean(footer) && <MenuItemSubText>{footer}</MenuItemSubText>}
        </LabelVariantContainer>
      )}
    </RebrandMenuItem>
  );
}
