import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { OrderKanbanCard, OrderKanbanCardProps } from '../../OrderKanbanCard';
import { getEmptyImage } from 'react-dnd-html5-backend';
export const DraggableCard = (props: OrderKanbanCardProps) => {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'card',
      item: { guest: props.guest },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.guest]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return <OrderKanbanCard {...props} dragRef={drag} isDragging={isDragging} />;
};
