import React from 'react';
import { format, isFuture, add, isToday, isBefore, isSameDay, isSameMinute } from 'date-fns';

const isWithinAWeek = (date: Date) => isFuture(date) && isBefore(date, add(new Date(), { days: 6 }));

type DisplayDateProps = {
  date?: Date;
};

export const DisplayDate = ({ date }: DisplayDateProps) => {
  if (!date) {
    return null;
  }

  if (isToday(date)) {
    // Today 05:23pm
    return <>Today {format(date, 'p')}</>;
  }

  if (isWithinAWeek(date)) {
    // Tue 05:23pm
    return <>{format(date, 'ccc p')}</>;
  }

  // 13/12/2016 05:23pm
  return <>{format(date, 'P p')}</>;
};

type DisplayDateRangeProps = {
  from?: Date | string;
  to?: Date | string;
};

export const DisplayDateRange = ({ from, to }: DisplayDateRangeProps) => {
  const fromDate = typeof from === 'string' ? new Date(from) : from;
  const toDate = typeof to === 'string' ? new Date(to) : to;

  if (!fromDate || !toDate || isSameMinute(fromDate, toDate)) {
    return <DisplayDate date={fromDate || toDate} />;
  }

  if (isToday(fromDate) && isToday(toDate)) {
    // Today 5:23pm - 5:26pm
    return (
      <>
        Today {format(fromDate, 'p')} - {format(toDate, 'p')}
      </>
    );
  }

  if (isToday(fromDate) && isWithinAWeek(toDate)) {
    // Today 5:23pm - Tue 5:26pm
    return (
      <>
        Today {format(fromDate, 'p')} - {format(toDate, 'ccc p')}
      </>
    );
  }

  if (isWithinAWeek(fromDate) && isWithinAWeek(toDate)) {
    if (isSameDay(fromDate, toDate)) {
      // Tue 5:23pm - 5:26pm
      return (
        <>
          {format(fromDate, 'ccc p')} - {format(toDate, 'p')}
        </>
      );
    } else {
      // Tue 5:23pm - Wed 5:26pm
      return (
        <>
          {format(fromDate, 'ccc p')} - {format(toDate, 'ccc p')}
        </>
      );
    }
  }

  if (isSameDay(fromDate, toDate)) {
    // 13/12/2016 5:23pm - 5:26pm
    return (
      <>
        {format(fromDate, 'P p')} - {format(toDate, 'p')}
      </>
    );
  }

  // 13/12/2016 5:23pm - 14/12/2016 5:26pm
  return (
    <>
      {format(fromDate, 'P p')} - {format(toDate, 'P p')}
    </>
  );
};

