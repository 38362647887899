import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import {
  getItemReturnValue,
  getItemReturnDetails,
  sendEmail,
  ItemReturnValue,
  ItemReturnDetail,
} from 'api/TransactionsApi';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import { getProductHistoryDetail } from 'store/actions/TransactionsActions';
import { PrintStatus } from 'models/Printing';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { usePrintJobStatus } from 'util/hooks/printing/usePrintJobStatus';
import { usePopup } from 'components/popups';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';

import { OverflowMenu } from 'components/menus/OverflowMenu';
import { MenuItem } from 'components/backoffice/menu-item';
import { DescCellSpan } from './TransactionDetails.styles';
import { ReactComponent as ElipsisIcon } from 'assets/icons/elipsis-icon.svg';

import type { State } from 'store';
import type { Column } from 'components/tables';
import type { Transaction, ProductHistoryDetail } from 'models/Transactions';
import type { CartItem } from 'models/Cart';

export type PrintAction = {
  label: string;
  onClick: () => void;
  hide?: boolean;
  disabled?: boolean;
};

type UseTransactionDetails = {
  selectedTransaction?: Transaction;
};

export const useTransactionDetails = ({ selectedTransaction }: UseTransactionDetails) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ldClient = useLDClient();

  // Global state
  const loadedGuestId = useSelector((state: State) => state.customer.details?.Guest_id);
  const loadedGuestName = useSelector((state: State) => state.customer.details?.FullName);
  const registerId = useSelector((state: State) => state.settings.selectedRegister)?.value;
  const isTransactionHistoryLoading = useSelector((state: State) => state.transactions.transactionHistoryLoading);

  // Local state
  const [selectedProduct, setSelectedProduct] = useState<ProductHistoryDetail>();
  const [returnValue, setReturnValue] = useState<ItemReturnValue>();
  const [returnDetail, setReturnDetail] = useState<ItemReturnDetail>();
  const [searchText] = useState('');

  // Features
  const isPackageItemIdsEnabled = useSelector((state: State) => state.settings.features.PackageItemIds);
  const isShowLeafLogixLoyaltyEnabled = useSelector((state: State) => state.settings.features.ShowLeafLogixLoyalty);
  const isShowExternalLoyaltyEnabled = useSelector((state: State) => state.settings.features.ShowExternalLoyalty);

  const productHistoryDetails = useSelector((state: State) => state.transactions.selected);
  const selectedReceiptPrinter = useSelector((state: State) => state.settings.userSettings.selectedReceiptPrinter);

  // Hooks
  const { isVisible: isReturnPopupVisible, toggle: toggleReturnPopup } = usePopup();
  const { printReceipt, printLabels } = usePrintJob();
  const { printReceiptStatus, printLabelsStatus } = usePrintJobStatus();

  // LD Flags
  const areCreditRefundsEnabled: boolean = ldClient?.variation('pos.enable-credit-refunds', false);

  // Calculated values
  const transactionQuantity = productHistoryDetails?.reduce((acc, item) => acc + item.Quantity, 0) || 0;
  const transactionWeight = productHistoryDetails?.reduce((acc, item) => acc + item.ProductGrams, 0) || 0;
  const isPrintReceiptButtonDisabled = printReceiptStatus === PrintStatus.PRINTING || !!selectedTransaction?.CancelDate;
  const isPrintLabelsButtonDisabled =
    printLabelsStatus === PrintStatus.PRINTING || (productHistoryDetails?.length ?? 0) === 0;

  const isTransactionCanceled = !!selectedTransaction?.CancelDate ?? false;
  const isVoidDisabled = useMemo(() => {
    const wasProductReturned = productHistoryDetails?.some((product) => product.PosStatus === 'Returned') ?? false;
    return wasProductReturned || isTransactionCanceled;
  }, [productHistoryDetails, isTransactionCanceled]);

  // Load customer details
  useEffect(() => {
    if (selectedTransaction?.CustomerId && loadedGuestId !== selectedTransaction.CustomerId) {
      dispatch(
        getCustomerDetails({
          guestId: selectedTransaction.CustomerId,
        })
      );
    }
  }, [dispatch, loadedGuestId, selectedTransaction]);

  // Load product history details
  useEffect(() => {
    dispatch(
      getProductHistoryDetail({
        TransactionId: selectedTransaction?.PosId,
        RegisterId: registerId,
        IsCanceledTransaction: isTransactionCanceled,
      })
    );
  }, [dispatch, registerId, selectedTransaction, isTransactionCanceled]);

  // Printing methods

  const printTransactionReceipt = useCallback(() => {
    if (selectedTransaction) {
      printReceipt({
        ReceiptType: 'Receipt',
        ReceiptParameters: selectedTransaction.PosId,
        ForDelivery: false,
        PrinterId: selectedReceiptPrinter?.PrinterId ?? 0,
        subtotal: selectedTransaction.PosProductCost,
        total: selectedTransaction.TotalInvoice,
        localPrinter: selectedReceiptPrinter?.LocalPrinter ?? false,
        popCashDrawer: false,
      });
    }
  }, [printReceipt, selectedReceiptPrinter, selectedTransaction]);

  const convertToCartItem = (productHistoryDetail: ProductHistoryDetail): CartItem => {
    const isBulk = productHistoryDetail.UnitTypeId !== 1;

    // These are all the necessary props from a CartItem needed to print a label
    const cartItem: CartItem = {
      Grams: productHistoryDetail.ProductGrams,
      Id: productHistoryDetail.ProdId,
      InventoryId: productHistoryDetail.PosId,
      OrderNo: productHistoryDetail.ReceiptNo,
      Product: productHistoryDetail.ProductDesc,
      ProductId: productHistoryDetail.ProdId,
      QtyAllocated: isBulk ? 1 : productHistoryDetail.Quantity,
      QtySelected: isBulk ? 1 : productHistoryDetail.Quantity,
      SerialNo: productHistoryDetail.SerialNo,
      TotalCost: 0,
      WgtCnt: isBulk ? 'Wgt' : 'Qty',
    };

    if (productHistoryDetail.PackageItemId) {
      cartItem.PackageItemId = parseInt(productHistoryDetail.PackageItemId);
    }

    return cartItem;
  };

  const printProductLabel = useCallback(
    (productHistoryDetail: ProductHistoryDetail) => {
      if (selectedTransaction) {
        printLabels({
          guest: { ShipmentId: selectedTransaction.PosId },
          items: convertToCartItem(productHistoryDetail),
          printAll: false,
          autoPrint: false,
          transactionId: selectedTransaction.PosId.toString(),
        });
      }
    },
    [printLabels, selectedTransaction]
  );

  const printAllLabels = useCallback(() => {
    if (selectedTransaction && productHistoryDetails) {
      printLabels({
        guest: { ShipmentId: selectedTransaction.PosId },
        items: productHistoryDetails.map(convertToCartItem),
        printAll: true,
        autoPrint: false,
        transactionId: selectedTransaction.PosId.toString(),
      });
    }
  }, [printLabels, productHistoryDetails, selectedTransaction]);

  const printActions: PrintAction[] = useMemo(() => {
    return [
      {
        label: 'Print receipt',
        onClick: printTransactionReceipt,
        disabled: isPrintReceiptButtonDisabled,
      },
      {
        label: 'Print all labels',
        onClick: printAllLabels,
        disabled: isPrintLabelsButtonDisabled,
      },
    ];
  }, [isPrintLabelsButtonDisabled, isPrintReceiptButtonDisabled, printAllLabels, printTransactionReceipt]);

  const renderPrintAction = (action: PrintAction, closeDropdown: () => void) => {
    if (action.hide) {
      return null;
    }

    return (
      <MenuItem
        value={action.label}
        disabled={action.disabled}
        onClick={() => {
          action.onClick();
          closeDropdown();
        }}
      >
        {action.label}
      </MenuItem>
    );
  };

  // Email Receipt
  const emailReceipt = (email: string) => {
    sendEmail({
      Email: email,
      Register: registerId,
      ShipmentId: selectedTransaction?.PosId,
    })
      .then(() => dispatch(successNotification('Email sent')))
      .catch((error) => dispatch(errorNotification(error)));
  };

  // Handlers
  const handleClickBack = () => history.goBack();

  const handleClickProfile = () => {
    loadedGuestId && history.push({ pathname: 'edit-customer', search: `?id=${loadedGuestId}` });
  };

  // Returns
  const onReturn = useCallback(
    async (product: ProductHistoryDetail) => {
      try {
        if (areCreditRefundsEnabled) {
          const creditReturnDetails = await getItemReturnDetails({
            AllocatedInventoryId: product.PosId,
            ShipmentId: selectedTransaction?.PosId ?? 0,
          });
          setReturnDetail(creditReturnDetails);
          setReturnValue(undefined);
        } else {
          const standardReturnValue = await getItemReturnValue({ AllocatedInventoryId: product.PosId });
          setReturnValue(standardReturnValue);
          setReturnDetail(undefined);
        }
      } catch (e) {
        dispatch(errorNotification('Failed to load return amounts'));
        setReturnValue(undefined);
      }

      toggleReturnPopup();
      setSelectedProduct(product);
    },
    [areCreditRefundsEnabled, dispatch, selectedTransaction, toggleReturnPopup]
  );

  // Memoized table data
  const productList: ProductHistoryDetail[] = useMemo(() => {
    return productHistoryDetails?.filter((item) => item.ProductSku.includes(searchText)) ?? [];
  }, [productHistoryDetails, searchText]);

  const tableColumns: Column<ProductHistoryDetail>[] = useMemo(() => {
    const columns: Column<ProductHistoryDetail>[] = [
      {
        width: '15px',
        disableSort: true,
        header: '',
        Cell: (cell) => {
          return (
            <OverflowMenu
              anchor={<ElipsisIcon />}
              anchorProps={{
                padding: '.5rem 0',
                showCursor: true,
              }}
              menuProps={{
                fontSize: '1rem',
                textAlign: 'center',
              }}
              automationId='transaction-details_context_menu'
              menuOptions={[
                {
                  text: 'Return',
                  onClick: () => onReturn(cell.item),
                  disabled: !!cell.item.CanceledDate || cell.item.PosStatus === 'Returned',
                },
                { text: 'Print Label', onClick: () => printProductLabel(cell.item) },
              ]}
            />
          );
        },
      },
      { header: 'SKU', field: 'ProductSku' },
      {
        header: 'Serial No.',
        field: 'SerialNo',
        Cell: ({ value }) => <DescCellSpan title={value}>{value}</DescCellSpan>,
      },
      { header: 'Qty', field: 'Quantity' },
      { header: 'Unit Price', field: 'UnitPrice' },
      {
        header: 'Description',
        field: 'ProductDesc',
        Cell: ({ value }) => <DescCellSpan title={value}>{value}</DescCellSpan>,
      },
      { header: 'Lot No.', field: 'LotNo', Cell: ({ value }) => <DescCellSpan title={value}>{value}</DescCellSpan> },
      { header: 'Vendor', field: 'OwnerName' },
      { header: 'Status', field: (item) => item.PosStatus ?? 'Canceled' },
      { header: 'Weight', field: (item) => `${item.ProductGrams} g` },
      { header: 'Return', field: 'ReturnBy' },
    ];

    // If 'Generate Unique Id Per Item' FF is on, insert column into transaction table
    if (isPackageItemIdsEnabled) {
      columns.push({ header: 'Item ID', field: 'PackageItemId' });
    }

    return columns;
  }, [isPackageItemIdsEnabled, onReturn, printProductLabel]);

  return {
    areCreditRefundsEnabled,
    customerName: loadedGuestName,
    emailReceipt,
    handleClickBack,
    handleClickProfile,
    isLoyaltyEnabled: isShowLeafLogixLoyaltyEnabled || isShowExternalLoyaltyEnabled,
    isReturnPopupVisible,
    isTransactionHistoryLoading,
    isVoidDisabled,
    printActions,
    productList,
    selectedProduct,
    renderPrintAction,
    returnDetail,
    returnValue,
    tableColumns,
    toggleReturnPopup,
    transactionQuantity,
    transactionWeight,
  };
};
