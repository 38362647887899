import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { State } from 'store';

import { medicalCustomerTypes } from 'util/Helpers';
import { requiredField, invalidValue } from '../helpers';

// these are used to check whether we are creating a medical customer so we can require the right fields
export const MEDICAL_CUSTOMER_TYPE_IDS = Object.keys(medicalCustomerTypes);

export type CustomerValidationSchema = ReturnType<typeof useFormValidation>;

type UseFormValidationParams = {
  customerTypeId: string | null;
};
export const useFormValidation = ({ customerTypeId }: UseFormValidationParams) => {
  const validatedForms = useSelector((state: State) => state.settings.validatedForms);
  const customerDetails = useSelector((state: State) => state.customer.details);
  const isRequireValidDLEnabled = useSelector((state: State) => state.settings.features.RequireValidDL);

  const isCustomerTypeValidForMedicalFields =
    !!customerTypeId && MEDICAL_CUSTOMER_TYPE_IDS.includes(customerTypeId) && !customerDetails?.IsAnonymous;

  return useMemo(() => {
    const requiredFieldConfig = validatedForms?.['Customer Profile (POS)'];

    if (!requiredFieldConfig) {
      return;
    }

    const addressShape = Yup.object().shape({
      Country_Code: requiredField(
        Yup.string(),
        requiredFieldConfig['Country']?.Required && !customerDetails?.IsAnonymous
      ),
      city: requiredField(
        Yup.string().notOneOf(invalidValue(requiredFieldConfig, 'City')),
        requiredFieldConfig['City']?.Required && !customerDetails?.IsAnonymous
      ),
      postal_code: requiredField(
        Yup.string().when('Country_Code', {
          is: (CountryCode) => {
            return CountryCode === 'CA';
          },
          then: Yup.string()
            .matches(/^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$/, 'Must match "A1A 1A1" zipcode format')
            .notOneOf(invalidValue(requiredFieldConfig, 'Postal Code')),
          otherwise: Yup.string()
            .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must match 5 or 9 digit zipcode format')
            .notOneOf(invalidValue(requiredFieldConfig, 'Postal Code')),
        }),
        requiredFieldConfig['Postal Code']?.Required
      ),
      state: requiredField(Yup.string(), requiredFieldConfig['State']?.Required && !customerDetails?.IsAnonymous),
      street: requiredField(
        Yup.string().notOneOf(invalidValue(requiredFieldConfig, 'Street')),
        requiredFieldConfig['Street']?.Required && !customerDetails?.IsAnonymous
      ),
      street2: requiredField(
        Yup.string().notOneOf(invalidValue(requiredFieldConfig, 'Street2')),
        requiredFieldConfig['Street2']?.Required && !customerDetails?.IsAnonymous
      ),
    });

    return Yup.object().shape({
      address: addressShape,
      AddressBook: Yup.array().of(addressShape),
      CellPhone: requiredField(
        Yup.string(),
        requiredFieldConfig['Mobile Phone Number']?.Required && !customerDetails?.IsAnonymous
      ),
      CustomerTypeId: requiredField(Yup.number().min(1), true),
      DOB: requiredField(
        Yup.date().max(new Date(new Date().setHours(0, 0, 0, 0)), 'DOB Cannot be in the future'),
        requiredFieldConfig['Date of Birth']?.Required && !customerDetails?.IsAnonymous
      ),
      CycleDate: requiredField(Yup.date(), false),
      Email: requiredField(
        Yup.string().email(),
        requiredFieldConfig['Email Address']?.Required && !customerDetails?.IsAnonymous
      ),
      FirstName: requiredField(Yup.string(), requiredFieldConfig['Name']?.Required && !customerDetails?.IsAnonymous),
      NamePrefix: requiredField(Yup.string(), requiredFieldConfig['Prefix']?.Required && !customerDetails?.IsAnonymous),
      NameSuffix: requiredField(Yup.string(), requiredFieldConfig['Suffix']?.Required && !customerDetails?.IsAnonymous),
      LastName: requiredField(
        Yup.string(),
        requiredFieldConfig['Last Name']?.Required && !customerDetails?.IsAnonymous
      ),
      MiddleName: requiredField(
        Yup.string(),
        requiredFieldConfig['Middle Name']?.Required && !customerDetails?.IsAnonymous
      ),
      PhoneNo: requiredField(
        Yup.string(),
        requiredFieldConfig['Phone Number']?.Required && !customerDetails?.IsAnonymous
      ),
      ReferralSourceId: requiredField(
        Yup.number().nullable(),
        requiredFieldConfig['Referred By']?.Required && !customerDetails?.IsAnonymous
      ),
      status: requiredField(Yup.string(), !customerDetails?.IsAnonymous),
      MJStateIDNo: requiredField(
        Yup.string(),
        isCustomerTypeValidForMedicalFields && requiredFieldConfig['MMJ ID']?.Required
      ),
      MJExpirationDate: requiredField(
        Yup.date(),
        isCustomerTypeValidForMedicalFields && requiredFieldConfig['MMJ ID Expiration Date']?.Required
      ),
      MMJIDState: requiredField(
        Yup.string(),
        isCustomerTypeValidForMedicalFields && requiredFieldConfig['MMJ ID State']?.Required
      ),
      MJStartDate: requiredField(
        Yup.date(),
        isCustomerTypeValidForMedicalFields && requiredFieldConfig['MMJ ID Start Date']?.Required
      ),
      CertificationCollectionDate: requiredField(
        Yup.date(),
        isCustomerTypeValidForMedicalFields && requiredFieldConfig['Patient Certification']?.Required
      ),
      CertificationExpirationDate: requiredField(
        Yup.date(),
        isCustomerTypeValidForMedicalFields && requiredFieldConfig['Patient Certification']?.Required
      ),
      CaregiverPhone: Yup.number(),
      CaregiverEmail: Yup.string().email(),
      CaregiverExpirationDate: requiredField(Yup.date(), false),
      CaregiverDOB: requiredField(Yup.date(), false),
      CaregiverAddress: requiredField(
        Yup.string().notOneOf(invalidValue(requiredFieldConfig, 'Address 1 (Caregiver)')),
        requiredFieldConfig['Address 1 (Caregiver)']?.Required && !customerDetails?.IsAnonymous
      ),
      CaregiverAddress2: requiredField(
        Yup.string().notOneOf(invalidValue(requiredFieldConfig, 'Address 2 (Caregiver)')),
        requiredFieldConfig['Address 2 (Caregiver)']?.Required && !customerDetails?.IsAnonymous
      ),
      CaregiverAddress3: requiredField(
        Yup.string().notOneOf(invalidValue(requiredFieldConfig, 'Address 3 (Caregiver)')),
        requiredFieldConfig['Address 3 (Caregiver)']?.Required && !customerDetails?.IsAnonymous
      ),
      CaregiverCity: requiredField(
        Yup.string().notOneOf(invalidValue(requiredFieldConfig, 'City (Caregiver)')),
        requiredFieldConfig['City (Caregiver)']?.Required && !customerDetails?.IsAnonymous
      ),
      CaregiverState: requiredField(
        Yup.string(),
        requiredFieldConfig['State (Caregiver)']?.Required && !customerDetails?.IsAnonymous
      ),
      CaregiverPostalCode: requiredField(
        Yup.string()
          .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must match 5 or 9 digit zipcode format')
          .notOneOf(invalidValue(requiredFieldConfig, 'Postal Code (Caregiver)')),
        requiredFieldConfig['Postal Code (Caregiver)']?.Required
      ),
      DLExpirationDate: requiredField(
        Yup.date(),
        (isRequireValidDLEnabled && !customerDetails?.IsAnonymous) ||
          requiredFieldConfig['(Drivers) License Expiration']?.Required
      ),
      DriversLicenseId: requiredField(
        Yup.string(),
        (isRequireValidDLEnabled && !customerDetails?.IsAnonymous) ||
          requiredFieldConfig['(Drivers) License Number']?.Required
      ),
      additionalStateIdentifiers: requiredField(
        Yup.string(),
        requiredFieldConfig['Additional State Identifiers']?.Required && !customerDetails?.IsAnonymous
      ),
      nickname: requiredField(Yup.string(), requiredFieldConfig['Nickname']?.Required && !customerDetails?.IsAnonymous),
      CustomIdentifier: requiredField(
        Yup.string(),
        requiredFieldConfig['CustomIdentifier']?.Required && !customerDetails?.IsAnonymous
      ),
      Gender: requiredField(Yup.string(), requiredFieldConfig['Gender']?.Required && !customerDetails?.IsAnonymous),
      Pronoun: requiredField(Yup.string(), requiredFieldConfig['Pronoun']?.Required && !customerDetails?.IsAnonymous),
      PreferredMethodOfContact: requiredField(
        Yup.string(),
        requiredFieldConfig['Preferred Method of Contact']?.Required && !customerDetails?.IsAnonymous
      ),
      OtherReferralSource: requiredField(
        Yup.string().when('ReferralSourceId', {
          is: (ReferralSourceId) => {
            if (
              ReferralSourceId === 7 &&
              requiredFieldConfig['Other Referral Source']?.Required &&
              !customerDetails?.IsAnonymous
            ) {
              return ReferralSourceId;
            } else {
              return 0;
            }
          },
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
        false
      ),
      ExternalName: requiredField(
        Yup.string(),
        requiredFieldConfig['External Name']?.Required && !customerDetails?.IsAnonymous
      ),
      PrimaryQualifyingConditionId: requiredField(
        Yup.number().nullable(),
        requiredFieldConfig['Qualifying Conditions']?.Required && !customerDetails?.IsAnonymous
      ),
      DDNumber: requiredField(
        Yup.string(),
        isCustomerTypeValidForMedicalFields && requiredFieldConfig['DDNumber']?.Required
      ),
    });
  }, [customerDetails, isCustomerTypeValidForMedicalFields, isRequireValidDLEnabled, validatedForms]);
};
