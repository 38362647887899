import styled from 'styled-components';
import { BaseInput } from '../base-input';

export const FormInput = styled(BaseInput)<{ $fullWidth?: boolean }>`
  grid-column: ${({ $fullWidth }) => ($fullWidth ? 'span 6' : '2 / span 3')};
  height: ${({ multiline }) => (multiline ? 'auto' : '44px')};
  width: ${({ $width }) => $width ?? '100%'};

  .MuiInputBase-input {
    padding: var(--sizes-30) var(--sizes-50);
    font: var(--font-regular-14pt-normal);
  }

  & .MuiInputAdornment-positionStart .MuiTypography-root {
    font: var(--font-regular-14pt-semibold);
  }
`;
