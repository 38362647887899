import styled from 'styled-components';
import { CSSObject } from '@emotion/react';

// This should wrap the entire form (Dividers, Button, FormSections, FormTitles, etc)
export const RebrandFormContainer = styled.div`
  width: 816px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  color: #464f53;
`;

// This should wrap only form fields (Input, Select, Toggle, etc)
export const RebrandFormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #464f53;
  gap: var(--sizes-70);
`;

export const RebrandFormTitle = styled.h2<{ noMargin?: boolean; $overrides?: CSSObject }>(
  ({ noMargin, $overrides }) => ({
    font: 'var(--font-large-16pt-semibold)',
    color: '#171a1c',
    width: '100%',
    margin: noMargin ? '0' : '0 0 24px 0',
    textAlign: 'start',
    ...$overrides,
  })
);

export const StyledFormFieldContainer = styled.div`
  gap: var(--sizes-20);
  display: flex;
  flex-direction: column;
`;

export const StyledFormFieldSubContent = styled.div`
  color: var(--color-greyscale-grey-50);
  font: var(--font-extra-small-12pt-normal);
  grid-column: 2 / span 3;
`;
