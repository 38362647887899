import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { State } from 'store';
import { getLoyaltySignups, updateLoyaltySignups } from 'store/actions/CheckoutActions';

import { colors } from 'css/Theme';
import { CustomerDetails } from 'models/Customer';
import { Checkbox } from 'components/inputs';
import { useCartPopups } from 'components/CartPopups';

type RegisterCustomerForLoyaltyProgramsOptionsProps = {
  guest: CustomerDetails;
};

export const RegisterForLoyaltyProgramsOptions: FC<RegisterCustomerForLoyaltyProgramsOptionsProps> = ({ guest }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state: State) => state.settings);
  const signups = useSelector((state: State) => state.checkout.signups);

  useEffect(() => {
    dispatch(getLoyaltySignups());
  }, [dispatch]);

  const showRegisterOptions =
    settings.features.RegisterForLoyaltyPopup &&
    !guest.IsLoyaltyMember &&
    !guest.IsAnonymous &&
    Object.keys(signups).length > 0;

  const handleCheckBoxClick = (key: string) => {
    const updates = Object.assign({}, signups);
    updates[key] = !updates[key];
    dispatch(updateLoyaltySignups(updates));
  };

  const cartPopups = useCartPopups();

  return (
    <>
      {showRegisterOptions && (
        <>
          <Title onClick={() => cartPopups.showRegisterForLoyaltyPopup()}>REGISTER FOR LOYALTY</Title>
          <Wrapper>
            <Subtitle>Signup Customer For:</Subtitle>
            {Object.keys(signups).map((key) => {
              return (
                <CheckboxContainer
                  onClick={() => handleCheckBoxClick(key)}
                  key={key}
                  data-testid='register-for-loyalty-programs-options_div_toggle-signups'
                >
                  <Checkbox state={signups[key] ? 'on' : 'off'} />
                  <Label>{key}</Label>
                </CheckboxContainer>
              );
            })}
          </Wrapper>
        </>
      )}
    </>
  );
};

const Title = styled.div`
  margin: 0 auto;
  color: ${colors.grey};
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 28px;

  &:hover {
    cursor: pointer;
  }
`;

const Subtitle = styled.div`
  margin: 0 auto;
  color: ${colors.primary};
  font-size: 16px;
  line-height: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

const Label = styled.div`
  color: ${colors.primary};
  font-size: 1rem;
  line-height: 1.25rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 15px;
`;
