import _ from 'lodash';
import { UserState } from 'store/reducers/UserReducer';

/** @deprecated */
export type CustomerSearchResult = {
  Guest_id: number;
  Name: string;
  PatientType: string;
  MJStateIDNo: string;
  MMJIDExpiration: string;
  DriversLicenseId: string;
  avatar: string;
  PhoneNo: string;
  LastTransaction: string;
  TransactionReference: null;
  DOB: string;
  Email: string;
};

export type CustomerAddress = {
  AddressId?: number;
  Country_Code: string;
  city: string;
  postal_code: string;
  state: string;
  street: string;
  street2?: string;
};

export function getAddressString(addr: CustomerAddress): string {
  return `${addr.street} ${addr.street2 ? ` ${addr.street2}` : ''}, ${addr.city} ${addr.state} ${addr.postal_code}`;
}

export type CustomerAllotmentData = {
  AllowOverrides: boolean;
  CurrentAllotmentOverriden: boolean;
  CurrentLimit: number;
  // CurrentLimitOverride:
  Details: Array<CustomerAllotmentDetail>;
  Error: boolean;
  // ErrorMessage:
  MaxAllotmentOverriden: boolean;
  MaxLimit: number;
  MaxLimitOverride: number;
  ShowCycleData: boolean;
  TotalInCart: number;
  TotalLimit: number;
  TotalRemaining: number;
  TotalUsed: number;
  ExternalDispensed: number;
  ValidFrom: string;
  ValidUntil: string;
};

export type CustomerAllotmentDetail = {
  Category: string;
  CustomerTypeId: number;
  InCart: number;
  Limit: number;
  Used: number;
};

export type CustomerCaregiver = {
  Address: string;
  CaregiverRelationship: string;
  DOB?: Date;
  ExpirationDate: string;
  Id: string;
  Notes: string;
  Phone: string;
  CaregiverMJStateIdNo: string;
  Email: string;
  name: string;
  CareGiverId?: number;
  CaregiverIndex: number;
};

export type CustomerDiscount = {
  Group: string;
  id: number;
  title: string;
};

export type CustomerIdentification = {
  StartDate: string;
  ExpirationDate: string;
  image_url: string;
  number: string;
  type: string;
  additionalStateIdentifiers: string;
};

export type CustomerIdentificationNew = Omit<CustomerIdentification, 'StartDate' | 'ExpirationDate'> & {
  StartDate?: string;
  ExpirationDate?: string;
};

export type EditPrimaryIdentificationRequest = {
  Guest_id: number;
  MJStateIDNo: string;
  ExpirationDate: string;
  ImgItem: string;
};

export type CustomerDetailsLight = {
  Guest_id: number;
  PatientType: string;
  FullName: string;
  NamePrefix: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  NameSuffix: string;
  DOB: string;
  MJStateIDNo: string;
  MMJIDState: string;
  MJStartDate: string;
  MJExpirationDate: string;
  DriversLicenseId: string;
  DLExpirationDate: string;
  status: string;
  doctor: string;
  street: string;
  city: string;
  state: string;
  Country_Code: string;
  postal_code: string;
  Attestation: string;
  AttestationSignedDate: string;
  AttestationExpirationDate: string;
  PhoneNo: string;
  LastPurchase: string;
  LoyaltyPoints: number;
  LoyaltyTier: string;
  LoyaltyTextColor?: string;
  LoyaltyPillBackgroundColor?: string;
  LoyaltyPillTextColor?: string;
  CellPhone: string;
  CustomerTypeId: number;
  IsMedical: boolean;
  IsAnonymous: boolean;
  IsLoyaltyMember: boolean;
  discounts: Array<CustomerDiscount>;
  address: CustomerAddress;
  Nickname?: string;
  Notes?: string;
  ValidPrescription?: boolean;
};

export type CustomerDetails = {
  AddressBook: Array<CustomerAddress>;
  Allotment: number;
  AllotmentData: CustomerAllotmentData;
  Attestation: string;
  AttestationSignedDate: string;
  AttestationExpirationDate: string;
  CGFirstName: string;
  CGLastName: string;
  CaregiverMJStateIdNo: string;
  CaregiverAddress: string;
  CaregiverAddress2: string;
  CaregiverAddress3: string;
  CaregiverCity: string;
  CaregiverState: string;
  CaregiverPostalCode: string;
  CaregiverDOB: string;
  CaregiverDOB2: string;
  CaregiverDOB3: string;
  CaregiverDOB4: string;
  CaregiverDOB5: string;
  CaregiverDOB6: string;
  CaregiverEmail: string;
  CaregiverEmail2: string;
  CaregiverEmail3: string;
  CaregiverEmail4: string;
  CaregiverEmail5: string;
  CaregiverEmail6: string;
  CaregiverExpirationDate: string;
  CaregiverMJStateIdNo2: string;
  CaregiverMJStateIdNo3: string;
  CaregiverMJStateIdNo4: string;
  CaregiverMJStateIdNo5: string;
  CaregiverMJStateIdNo6: string;
  CaregiverName2: string;
  CaregiverName3: string;
  CaregiverName4: string;
  CaregiverName5: string;
  CaregiverName6: string;
  CaregiverNotes: string;
  CaregiverNotes2: string;
  CaregiverNotes3: string;
  CaregiverNotes4: string;
  CaregiverNotes5: string;
  CaregiverNotes6: string;
  CaregiverPhone: string;
  CaregiverPhone2: string;
  CaregiverPhone3: string;
  CaregiverPhone4: string;
  CaregiverPhone5: string;
  CaregiverPhone6: string;
  CaregiverRelationship: string;
  CaregiverRelationship2: string;
  CaregiverRelationship3: string;
  CaregiverRelationship4: string;
  CaregiverRelationship5: string;
  CaregiverRelationship6: string;
  CellPhone: string;
  Conditions: string;
  CreationDate: string;
  CreditNote: string;
  /** This is wrong, API sends back string not number */
  CurrentRegister: number;
  CurrentRoom: string;
  CustomerTypeId: number;
  TribalAffiliation: string;
  IsMedical: boolean;
  CycleDate: string;
  DDNumber: string;
  DLExpirationDate: string;
  DOB: string;
  DaysSupplyRemaining: number;
  DriversLicenseId: string;
  additionalStateIdentifiers: string;
  Email: string;
  Errors: string[];
  ExternalName: string;
  FirstName: string;
  FullName: string;
  Gender: string;
  Guardian: string;
  Guest_id: number;
  // InventoryPercent:
  IsPaid: boolean;
  LastName: string;
  LastPurchase: string;
  LoyaltyPoints: number;
  LoyaltyTier: string;
  LoyaltyPillBackgroundColor?: string;
  LoyaltyPillTextColor?: string;
  LoyaltyTextColor?: string;
  MJStateIDNo?: string;
  MMJIDState?: string;
  MJStartDate: string;
  MJExpirationDate: string;
  CertificationCollectionDate?: string;
  CertificationExpirationDate?: string;
  MiddleName: string;
  NamePrefix: string;
  NameSuffix: string;
  OtherReferralSource: string | null;
  PatientType: string;
  PatientUpdated?: number;
  PhoneNo: string;
  PreferredMethodOfContact: string;
  PrimaryQualifyingConditionId: string;
  PurchasingForMinor: boolean;
  ReferralSourceId: number | null;
  ScheduleId: string;
  SecondaryQualifyingConditionIds: Array<number>;
  /** @deprecated Will use the URL to track shipmentId for guests in the future - pos.anon-cart-workflow.rollout */
  ShipmentId: number;
  TransactionReference: string;
  TransactionStatus: string;
  // UserName:
  address: CustomerAddress;
  // avatar:
  care_giver: Array<CustomerCaregiver>;
  discounts: Array<CustomerDiscount>;
  doctor: string;
  identifications: Array<CustomerIdentification>;
  nickname: string;
  CustomIdentifier: string;
  notes: string;
  status: string;
  /** This is not sent back from the API. Use CurrentRegister instead */
  RegisterId: number;
  IsAnonymous: boolean;
  OptedIntoMarketing: boolean | null;
  OptedIntoEngagementSMS: boolean | null;
  IsLoyaltyMember: boolean;
  Pronoun?: string;
  IsDutchiePayUser?: boolean;
  ValidPrescription?: boolean;
  DeliveryStatus?: string;
};

export type CustomerDetailsNew = Omit<
  CustomerDetails,
  'DLExpirationDate' | 'DOB' | 'identifications' | 'LastPurchase'
> & {
  DLExpirationDate?: string;
  DOB?: string;
  identifications: CustomerIdentificationNew[];
  LastPurchase?: string;
};

export type EditCustomerDetailsRequest = {
  AddressBook: Array<CustomerAddress>;
  Attestation: string;
  AttestationSignedDate: string;
  AttestationExpirationDate: string;
  CGFirstName: string;
  CGLastName: string;
  CaregiverMJStateIdNo: string;
  CaregiverAddress: string;
  CaregiverAddress2: string;
  CaregiverAddress3: string;
  CaregiverCity: string;
  CaregiverState: string;
  CaregiverPostalCode: string;
  CaregiverDOB: string;
  CaregiverDOB2: string;
  CaregiverDOB3: string;
  CaregiverDOB4: string;
  CaregiverDOB5: string;
  CaregiverDOB6: string;
  CaregiverEmail: string;
  CaregiverEmail2: string;
  CaregiverEmail3: string;
  CaregiverEmail4: string;
  CaregiverEmail5: string;
  CaregiverEmail6: string;
  CaregiverExpirationDate: string;
  CaregiverMJStateIdNo2: string;
  CaregiverMJStateIdNo3: string;
  CaregiverMJStateIdNo4: string;
  CaregiverMJStateIdNo5: string;
  CaregiverMJStateIdNo6: string;
  CaregiverName2: string;
  CaregiverName3: string;
  CaregiverName4: string;
  CaregiverName5: string;
  CaregiverName6: string;
  CaregiverNotes: string;
  CaregiverNotes2: string;
  CaregiverNotes3: string;
  CaregiverNotes4: string;
  CaregiverNotes5: string;
  CaregiverNotes6: string;
  CaregiverPhone: string;
  CaregiverPhone2: string;
  CaregiverPhone3: string;
  CaregiverPhone4: string;
  CaregiverPhone5: string;
  CaregiverPhone6: string;
  CaregiverRelationship: string;
  CaregiverRelationship2: string;
  CaregiverRelationship3: string;
  CaregiverRelationship4: string;
  CaregiverRelationship5: string;
  CaregiverRelationship6: string;
  CellPhone: string;
  Conditions: string;
  CreditNote: string;
  CustomerTypeId: number;
  TribalAffiliation: string;
  CycleDate: string;
  DDNumber: string;
  DLExpirationDate: string;
  DoctorsName: string;
  DriversLicenseId: string;
  additionalStateIdentifiers: string;
  ExpirationDate: string;
  ExternalName: string;
  FirstName: string;
  Group1_Id: number;
  Group2_Id: number;
  Group3_Id: number;
  Group4_Id: number;
  Group5_Id: number;
  Group6_Id: number;
  Guardian: string;
  Guest_id: number;
  LastName: string;
  MJStateIDNo?: string;
  MJStateIDStartDate: string;
  MMJIDState?: string;
  CertificationCollectionDate?: string;
  CertificationExpirationDate?: string;
  MiddleName: string;
  NamePrefix: string;
  NameSuffix: string;
  OtherReferralSource: string | null;
  PhoneNo: string;
  PreferredMethodOfContact: string;
  PrimaryQualifyingConditionId: string;
  Pronoun?: string;
  ReferralSourceId: number | null;
  SecondaryQualifyingConditionIds: number[];
  city: string;
  country_code: string;
  dob: string;
  email: string;
  gender: string;
  nickname: string;
  CustomIdentifier: string;
  notes: string;
  postal_code: string;
  state: string;
  status: string;
  street: string;
  street2: string;
  OptedIntoMarketing: boolean | null;
  OptedIntoEngagementSMS: boolean | null;
};

export type CreateCustomerRequest = {
  NamePrefix: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  NameSuffix: string;
  status: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  postal_code: string;
  MJStateIDNo?: string;
  MJStateIDStartDate: string;
  MMJIDState?: string;
  ExpirationDate: string;
  CertificationCollectionDate?: string;
  CertificationExpirationDate?: string;
  CustomerTypeId: number;
  PhoneNo: string;
  country_code: string; // set to "US"
  DLExpirationDate: string;
  DriversLicenseId: string;
  DOB: string;
  email: string;
};

export type CreateAnonymousRequest = {
  PostalCode: string;
  CustomerTypeId: number;
  Dob: string;
  Gender: string;
};

export type Doctor = {
  DoctorsName: string;
  Id: number;
};

export type AddPrescriptionRequest = {
  PrescriptionId: number;
  AcctId: number;
  PrescriptionDate: string;
  ExpirationDate: string;
  CompletedDate: string;
  Note: string;
  Quantity: number | null;
  UnitId: number | null;
  ProductId: number | null;
  StateRXSerialNumber: string | null;
  ElectronicPrescription?: boolean;
  DoseId?: string;
};

export type CompletePrescriptionRequest = {
  PrescriptionId: number;
  CompletedDate: string;
};

export type DeletePrescriptionRequest = {
  PrescriptionId: number;
};

export enum ReferralSourceIds {
  AnotherCustomer = 1,
  WordOfMouth = 2,
  Weedmaps = 3,
  Leafly = 4,
  SocialMedia = 5,
  OnlineSearch = 6,
  Other = 7,
  Billboard = 8,
  TaxiLyftUber = 9,
  Magazine = 10,
  WalkByDriveBy = 11,
}

export type ReferralSource = {
  ReferralSourceId: ReferralSourceIds;
  ReferralSource: string;
};

export type ProductHistoryOrder = {
  PosId: number;
  ReceiptNo: string;
  PosDate: string;
  SoldBy: string;
  Total: number;
  items: Array<ProductHistoryItem>;
};

export type ProductHistoryItem = {
  ReceiptNo: string;
  PosDate: string;
  SoldBy: string;
  ProductDesc: string;
  PatientName: string;
  ReturnReason: string;
  ReturnBy: string;
  SerialNo: string;
  Grams: number;
  TotalPrice: number;
  ProdId: number;
  PosId: number;
  PosQty: number;
  ProductCategory: string;
  PosStatus: string;
  ProductType: string;
  ProductSku: string;
  Instructions: string;
  OwnerLocation: string;
  PrescriptionNumber: number;
  LotNo: string;
  Equivalent: number;
  BioTrackDt: string;
  BioTrackResponse: string;
  OwnerName: string;
  Prescription: string;
};

export type ReturnReason = {
  ReturnReasonId: number;
  Reason: string;
};

export type LoyaltyAdjustmentReason = {
  AdjustLoyaltyReasonId: number;
  Reason: string;
};

export type CheckInCustomerRequest = {
  AcctId: number;
  CaregiverMJStateIdNo?: string;
  MJStateIDNo?: string;
  Register?: number;
  RoomId?: string;
};

export type CheckInCustomerResponse = {
  ScanId: number;
  ScanResult: number;
  ShipmentId: number;
  IsDutchiePayUser: boolean;
};

export type AdjustLoyaltyPointsRequest = {
  GuestId: number;
  TransactionBy?: number;
  TransactionAmt: number;
  pin?: string;
  AdjustLoyaltyReason?: string;
  PinUserId: number | null;
};

export type AdjustAllotmentRequest = {
  Allotment: number;
  PatientId: number;
};

export type ClearAllotmentRequest = {
  PatientId: number;
};

export type AdjustExternalPatientAllotmentRequest = {
  LicenseNumber: string | null | undefined;
  NewLicenseNumber: string | null | undefined;
  LicenseEffectiveStartDate: Date;
  LicenseEffectiveEndDate: Date;
  RecommendedPlants: number;
  RecommendedSmokableQuantity: number;
  FlowerOuncesAllowed: number;
  ThcOuncesAllowed: number;
  ConcentrateOuncesAllowed: number;
  InfusedOuncesAllowed: number;
  MaxConcentrateThcPercentAllowed: number;
  MaxFlowerThcPercentAllowed: number;
  HasSalesLimitExemption: boolean;
  PatientId: number;
};

export enum EditAllotmentTypeEnum {
  Max = 'Max',
  Current = 'Current',
  External = 'External',
  BackfillExternal = 'External from Current',
}

export type UpdateDaysSupplyRequest = {
  PatientId: number;
  DaysSupplyRemaining: number;
};

export type UploadDocumentRequest = {
  ExpirationDate?: Date;
  FileBytes: string;
  FileName: string;
  FileType?: string;
  PatientId: number;
};

export type DownloadDocumentRequest = {
  FileName?: string;
  PatientId: number;
};

export type GetDocumentsResponse = Record<string, Document>;

export type Document = {
  name: string;
  UploadedDate: string;
  UploadedBy: string;
  FileId: number;
  ExpirationDate?: string;
  FileType?: string;
};

export type QualifyingCondition = {
  QualifyingConditionId: number;
  IsPrimary: boolean;
  IsSecondary: boolean;
  Name: string;
  IsDeleted: boolean;
};

export type Prescription = {
  PrescriptionId: number;
  PrescriptionNumber: number;
  AcctId: number;
  ExpirationDate: string;
  PrescriptionDate: string;
  CompletedDate: string;
  DoctorId: string;
  DoctorsName: string;
  LocId: number;
  LspId: number;
  Note: string;
  ProductId: number;
  Quantity: string | null;
  UnitId: number;
  abbreviation: string;
  StateRXSerialNumber: string;
  ElectronicPrescription?: boolean;
  DoseId?: string;
};

export type Option = {
  label: string;
  value: string;
};

export type RegisterCustomerForLoyaltyRequest = {
  AcctId: number;
  CellPhone: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  StreetAddress: string | number;
  City: string;
  State: string;
  PostalCode: string;
};

export enum EditIdentificationTypeEnum {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum ActivityFilter {
  Transaction,
  ProfileUpdate,
  Void,
  Return,
  JournalEntry,
  PointsAdjustment,
}

export const ALL_ACTIVITY_FILTERS = _.map(ActivityFilter, (v) => v).filter((v) => !isNaN(v)) as Array<number>;

export type GetCustomerActivityRequest = {
  CustomerId: number;
  Filters: Array<ActivityFilter>;
  FromDate: Date;
  ToDate: Date;
};

export type CustomerTransactionActivity = {
  TransactionId: number;
  Timestamp: string;
  TotalCharges?: number;
  TransactionBy: string;
};

export type ProfileUpdateActivity = {
  Timestamp: string;
  AddressUpdated: boolean;
  StateIdUpdated: boolean;
  NameUpdated: boolean;
};

export type ItemReturnActivity = {
  Timestamp: string;
  SaleDate: string;
  PackageId: string;
  ReturnedBy: string;
  ReturnReason: string;
  ProductName: string;
  TransactionId: string;
};

export type TransactionVoidActivity = {
  TransactionId: number;
  TransactionDate: string;
  Timestamp: string;
  TotalCharges: number;
  VoidedBy: string;
};

export type JournalEntryActivity = {
  JournalEntryId: number;
  Subject: string;
  Entry: string;
  EmployeeName: string;
  EntryDate: string;
  Timestamp: string;
};

export type LoyaltyPointsAdjustmentActivity = {
  LoyaltyAdjustment: number;
  AdjustmentBy: string;
  Timestamp: string;
};

export type CustomerActivityItem =
  | CustomerTransactionActivity
  | ProfileUpdateActivity
  | ItemReturnActivity
  | TransactionVoidActivity
  | JournalEntryActivity
  | LoyaltyPointsAdjustmentActivity;

export type CustomerActivity = {
  Transactions: Array<CustomerTransactionActivity>;
  ProfileUpdates: Array<ProfileUpdateActivity>;
  ItemReturns: Array<ItemReturnActivity>;
  TransactionVoids: Array<TransactionVoidActivity>;
  JournalEntries: Array<JournalEntryActivity>;
  PointsAdjustments: Array<LoyaltyPointsAdjustmentActivity>;
};

export type CustomerListItem = {
  Id: number;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  CellPhone?: string;
  LastTransaction?: string;
  LoyaltyBalance?: number;
  LifetimeSpent?: number;
  AverageSpend?: number;
  TotalTransactions?: number;
  PostalCode?: string;
};

export enum SortDirection {
  ASC,
  DESC,
}

export enum FilterOperator {
  Like,
  Equal,
  NotEqual,
  Between,
  GreaterThan,
  LessThan,
  GreaterThanOrEqual,
  LessThanOrEqual,
  Contains,
}

export type CustomerFilter = {
  Key: keyof CustomerListItem;
  Operator: FilterOperator;
  Value?: string | Date;
  Values?: Array<string>;
  StartValue?: string | Date;
  EndValue?: string | Date;
};

export type CustomerSort = {
  Key: string;
  Direction: SortDirection;
};

export type CustomerListRequest = {
  RequirePhoneNumber: boolean;
  ExcludeOptedOutOfMarketing: boolean;
  PageSize: number;
  currentPage: number;
  Sorting: Array<CustomerSort>;
  Filters: Array<CustomerFilter>;
  Categories: Array<number>;
};

export type CustomerListResponse = {
  TotalPages: number;
  TotalRows: number;
  Data: Array<CustomerListItem>;
};

export type SelectCollectorRequest = {
  CustomerId?: number;
  ShipmentId?: number;
  CaregiverId?: string;
  CaregiverName?: string;
  CaregiverRelationship?: string;
  CaregiverDob?: Date;
  CaregiverPhone?: string;
  CaregiverEmail?: string;
  caregiverNotes?: string;
  caregiverIndex?: number;
  Register?: number;
};

export type EditCollectorRequest = {
  CustomerId?: number;
  CaregiverId?: string;
  CaregiverName?: string;
  CaregiverRelationship?: string;
  CaregiverDob?: Date;
  CaregiverPhone?: string;
  CaregiverEmail?: string;
  caregiverNotes?: string;
  caregiverIndex?: number | string;
  InitialCaregiverId?: string;
  InitialCaregiverName?: string;
  InitialCaregiverRelationship?: string;
  InitialCaregiverDob?: Date;
  InitialCaregiverPhone?: string;
  InitialCaregiverEmail?: string;
  InitialCaregiverNotes?: string;
};

export type CheckinCaregiver = {
  CaregiverId: number;
  CaregiverName: string;
  CaregiverMJStateIdNo?: string;
  /** The API still seems to be sending this property name in some cases */
  CaregiverMJStateIDNo?: string;
};

export type UpdateAttestationRequest = {
  GuestId: number;
  Attestation: string;
};

export type RemotePayTextRequest = {
  ShipmentId: number;
  PhoneNumber: string;
};

export type RempotePayEmailRequest = {
  ShipmentId: number;
  EmailAddress: string;
};

export type SetPatientPolicyAgreement = {
  AcctId: number;
  SignatureBase64: string;
  WasAccepted: boolean;
};

export type PatientPolicyAgreement = {
  AgreementHTML: string;
  SignatureBase64: string;
  DateAcknowledged?: string;
  ExpirationDate?: string;
  Witness?: string;
  WasAccepted?: boolean;
  IsValid: boolean;
};

export type RemoveAddressBookItemRequest = {
  AddressId: number;
  GuestId: number;
};

export type PreorderMetadataValues = {
  orderSourceId?: number;
  orderSourceName?: string;
  orderTypeId?: number;
  orderTypeName?: string;
  dueDate?: Date;
  deliveryAddressId?: number;
  createNewAddress: boolean;
  newAddress: CustomerAddress;
  responsibleForSale: UserState;

  //these 4 fields are used for anonymous patients
  customerTypeId?: number;
  dob?: Date;
  gender?: string;
  postalCode?: string;
};
