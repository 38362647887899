import React, { FC } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { Label } from 'components/text';
import { TextArea } from './TextArea';

export type TextAreaFieldProps = {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  rows?: number;
  automationId?: string;
};

export const TextAreaField: FC<TextAreaFieldProps> = ({
  name,
  label,
  placeholder,
  className,
  disabled,
  rows,
  automationId,
}) => {
  const [field, meta] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    <TextAreaContainer className={className} title={hasError ? meta.error : ''}>
      <Label className={hasError ? 'error' : ''}>{label}</Label>
      <StyledTextArea
        data-testid={automationId}
        rows={rows}
        className={hasError ? 'error' : ''}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
      />
    </TextAreaContainer>
  );
};

const TextAreaContainer = styled.div`
  margin-bottom: 1rem;
`;

const StyledTextArea = styled(TextArea)`
  &.error {
    border-color: ${colors.dutchie.red};
    color: ${colors.dutchie.red};
  }
`;
