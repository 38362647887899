import React, { ChangeEvent } from 'react';
import { TextFieldProps as MUIInputProps } from '@mui/material';

import { RebrandInput } from './base-input.styles';

export type BaseInputProps = MUIInputProps & {
  id?: string;
  value?: number | string;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  gridColumn?: string;
  width?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  rows?: number;
  autoComplete?: string;
  endAdornment?: unknown;
  startAdornment?: unknown;
  validator?: (event: ChangeEvent<HTMLInputElement>) => boolean;
  tooltip?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  automationId?: string;
  ddActionName?: string;
};

export function BaseInput(props: BaseInputProps): JSX.Element {
  const {
    id,
    value,
    type = 'text',
    onChange = () => undefined,
    onBlur,
    multiline = false,
    width,
    gridColumn,
    required = false,
    disabled = false,
    placeholder,
    rows,
    endAdornment,
    startAdornment,
    InputProps,
    validator = null,
    className = '',
    onClick,
    automationId,
    ddActionName,
    autoFocus = false,
    onKeyPress,
    ...other
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    (validator && !validator(event)) || onChange(event);
  };

  return (
    <RebrandInput
      {...other}
      $gridColumn={gridColumn}
      $width={width}
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      id={id}
      inputProps={{
        'data-testid': automationId,
        'data-dd-action-name': ddActionName,
      }}
      InputProps={InputProps ?? { endAdornment, startAdornment }}
      multiline={multiline}
      placeholder={placeholder}
      required={required}
      rows={rows}
      type={type}
      value={value}
      variant='outlined'
      onBlur={onBlur}
      onChange={handleChange}
      onClick={onClick}
      onKeyPress={onKeyPress}
    />
  );
}
