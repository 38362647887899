import styled from 'styled-components';
import { colors } from 'css/Theme';
import React, { FC } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/icon-info.svg';
export const DebitWarning: FC = () => {
  return (
    <DebitWarningContainer>
      <InfoIcon style={{ flex: '0 0 1rem' }} />
      <DebitText>
        <DebitWeightedText>Regala not available.</DebitWeightedText>
        &nbsp;Regala transactions are not supported on orders totaling $2000 or more.
      </DebitText>
    </DebitWarningContainer>
  );
};

const DebitWarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: ${colors.dutchie.blue10};
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const DebitText = styled.span`
  font-weight: 400;
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: ${colors.dutchie.deepBlue};
  padding-left: 8px;
`;

const DebitWeightedText = styled.span`
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: ${colors.dutchie.deepBlue};
`;
