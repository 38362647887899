import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { colors } from 'css/Theme';
import { ReactComponent as _CloseIcon } from 'assets/icons/close.svg';

type ExpandContentButtonProps = {
  automationId?: string;
  isInactive?: boolean;
  isSelected?: boolean;
  label: React.ReactNode;
  onClick?: () => void;
  secondaryLabel?: string;
  styles?: FlattenSimpleInterpolation;
};

export const ExpandContentButton = ({
  automationId,
  isInactive,
  isSelected,
  label,
  onClick,
  secondaryLabel,
  styles,
}: ExpandContentButtonProps) => {
  return (
    <Button isInactive={isInactive} onClick={onClick} data-testid={automationId} styles={styles}>
      {label}
      {isSelected && <CloseIcon />}
      {secondaryLabel && !isSelected && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}
    </Button>
  );
};

const Button = styled.div<{ isInactive?: boolean; styles?: FlattenSimpleInterpolation }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  flex: 1;
  height: 52px;
  padding: 0.875rem 1.5rem;
  white-space: nowrap;

  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${colors.dutchie.blue};
  background: ${({ isInactive }) => (isInactive ? colors.dutchie.grey10 : colors.dutchie.primaryWhite)};
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    border-right: 1px solid ${colors.dutchie.grey20};
  }

  ${({ styles }) => styles && styles}
`;

const SecondaryLabel = styled.span`
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: ${colors.dutchie.grey30};
  text-align: right;
`;

const CloseIcon = styled(_CloseIcon)`
  & > path {
    fill: ${colors.dutchie.almostBlack};
  }
`;
