import { isDutchiePayError, setCartFromRQ } from 'store/actions/CartActions';
import { logger, customEventKeys } from 'util/logger';
import { PaymentType } from 'models/Checkout';
import { refreshCheckout, removePaymentType, setExistingPayments, stopPolling } from 'store/actions/CheckoutActions';
import { transformToCartDetails } from '../types';
import { updateCFDData } from 'store/actions/CFDActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';

import type { LoadCartResponse, CartDetails } from '../types';

/** This handles all the logic that used to be in the Redux LoadCart async action */
export const useProcessCartResponse = () => {
  const dispatch = useAppDispatch();

  const hasDutchiePayError = useAppSelector((state) => state.cart.isDutchiePayError);
  const currentShipmentIdInRedux = useAppSelector((state) => state.cart.details?.ShipmentId);

  return async (cartResponse: LoadCartResponse): Promise<CartDetails> => {
    const cart = transformToCartDetails(cartResponse);

    // Handle payment errors

    // May not need to reference the previously stored value from redux here anymore
    let dutchiePayError = hasDutchiePayError;

    // This if statement doesn't make sense anymore since we're not comparing against the old cart
    // but I'm leaving it here for now until we can clean up the cart store in Redux
    if (currentShipmentIdInRedux !== cart.shipmentId) {
      // If there are any existing payments on this shipment, and they are not Dutchie Pay,
      // we need to hide the Dutchie Pay panel. We check the payments and set the 'error'
      // flag appropriately
      const hasNonDutchiePayPayment = cart.existingPayments.some((payment) => payment.type !== PaymentType.Dutchie);
      dutchiePayError = hasNonDutchiePayPayment;
      dispatch(isDutchiePayError(hasNonDutchiePayPayment));

      await dispatch(stopPolling());
      await dispatch(setExistingPayments({ ExistingPayments: cart.existingPayments, ShipmentId: cart.shipmentId }));
    }

    // If we are in a state of dutchie pay error, we need to check on each cart reload to ensure
    // the preauth is reset before returning the cart, or else it will show up in the summary
    if (dutchiePayError) {
      dispatch(removePaymentType(PaymentType.Dutchie));
    }

    // Refresh the checkout totals
    dispatch(refreshCheckout(cart?.grandTotalRounded ?? 0));

    // Update the CFD store with the old cart object until we can update it later
    dispatch(updateCFDData({ cart: cartResponse }));

    // Send the old cart response to the reducer to maintain the old cart state for now
    /** @deprecated Remove this once we clean up the cart store in Redux */
    dispatch(setCartFromRQ(cartResponse));

    logger.debug(`[React Query] useGetCartDetailsQuery completed`, {
      key: customEventKeys.reactQuery,
      payload: cart,
    });

    return cart;
  };
};
