import React from 'react';

type BiotrackIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function BiotrackIcon(props: BiotrackIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.2737 3.20596C15.5685 1.21698 8.43007 1.21712 1.72493 3.20637L1.48114 2.38461L1.72652 3.2059C1.72299 3.20696 1.7199 3.20912 1.71769 3.21206C1.71556 3.21492 1.71437 3.21837 1.71429 3.22193V7.70074C1.71421 10.8471 2.66766 13.9195 4.44892 16.513C6.23018 19.1065 8.7556 21.0993 11.6922 22.2285C11.8903 22.3047 12.1096 22.3047 12.3077 22.2285C15.2443 21.0993 17.7698 19.1065 19.5511 16.513C21.3323 13.9195 22.2858 10.8471 22.2857 7.70074V3.22196C22.2856 3.21839 22.2844 3.21493 22.2823 3.21206C22.2801 3.20917 22.2771 3.20703 22.2737 3.20596ZM1.23736 1.56285C8.26116 -0.520949 15.7388 -0.520949 22.7626 1.56285L22.7642 1.56332C23.1204 1.66974 23.4329 1.88796 23.6554 2.18572C23.878 2.48348 23.9988 2.84496 24 3.21671L24 3.21949L24 7.70074C24.0001 11.1936 22.9416 14.6045 20.9642 17.4836C18.9867 20.3628 16.1831 22.575 12.9231 23.8286C12.3289 24.0571 11.6711 24.0571 11.0769 23.8286C7.81688 22.575 5.01329 20.3628 3.03584 17.4836C1.05839 14.6045 -7.90909e-05 11.1936 4.43255e-09 7.70074V3.21671C0.00120712 2.84497 0.122024 2.48348 0.344574 2.18572C0.567124 1.88796 0.879594 1.66974 1.23577 1.56332L1.23736 1.56285Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5811 6.23882C18.0581 6.61881 18.1399 7.31748 17.7637 7.79934L11.9247 15.2785C11.5993 15.6944 11.0948 16.0004 10.4958 16.0004C9.89677 16.0004 9.39233 15.6944 9.06688 15.2785L9.06496 15.2761L6.23521 11.9245C5.8598 11.442 5.94266 10.7435 6.42029 10.3643C6.89793 9.98505 7.58945 10.0688 7.96487 10.5512L10.4963 13.5194L16.0362 6.42326C16.4124 5.9414 17.1041 5.85883 17.5811 6.23882Z'
        fill={color}
      />
    </svg>
  );
}
