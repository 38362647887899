import React from 'react';

import styled from 'styled-components';

import { useGeneralHeaderBannerConfig } from 'util/hooks/launch-darkly/useGeneralHeaderBannerConfig';
import { generalBannerStyles, LARGE_BANNER_HEIGHT, Text } from './shared';

export function GeneralHeaderBanner() {
  const { generalHeaderBannerConfig } = useGeneralHeaderBannerConfig();

  if (!generalHeaderBannerConfig.copy || !generalHeaderBannerConfig.visible) {
    return null;
  }

  return (
    <Banner>
      <Text>
        <span>{generalHeaderBannerConfig.copy}</span>
      </Text>
    </Banner>
  );
}

const Banner = styled.div`
  background-color: #3c96fe;
  height: ${LARGE_BANNER_HEIGHT}px;

  ${generalBannerStyles}
`;
