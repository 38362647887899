import { useDispatch, useSelector } from 'react-redux';
import { round } from 'lodash';

import { logger } from 'util/logger';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useAddWeightItemToCartMutation } from 'queries/v2/cart/add-weight-item-to-cart';
import { useAutoPrintLabels } from '../cart/useAutoPrintLabels';
import { useIsDaysSupplyFeatureEnabled } from '../features/useIsDaysSupplyFeatureEnabled';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { errorNotification } from 'store/actions/NotificationsActions';

import type { AddWeightItemToCartPayload } from 'queries/v2/cart/add-weight-item-to-cart';
import type { CartItem } from 'models/Cart';
import type { State } from 'store';

export type UseAddBulkItemToCart = {
  batchId?: number;
  cannabisInventory?: string;
  defaultLabelId?: number;
  productDescription: string;
  productId: number;
  recUnitPrice: number;
  serialNo: string;
  totalCost?: number;
  unitPrice: number;
  actualWeight: string | number;
  weightOverride?: string | number;
};

export const useAddBulkItemToCart = () => {
  const dispatch = useDispatch();

  // Global state
  const isLoyaltyAsDiscountEnabled = useSelector((state: State) => state.settings.features.LoyaltyAsDiscount);
  const isRunAutoDiscountEnabled = useSelector((state: State) => state.settings.features.RunAutoDiscount);
  const isRunAutoPriceEnabled = useSelector((state: State) => state.settings.features.RunAutoPrice);
  const isUsingDaysSupply = useIsDaysSupplyFeatureEnabled();
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  const { guestId, shipmentId } = useTransactionManager();

  const { data: cart, refetch: refetchCartDetails } = useGetCartDetails();

  // Computed values

  const availableOz = round(cart.Allotment.TotalLimit - cart.Allotment.TotalInCart - cart.Allotment.TotalUsed, 2);

  // Hooks

  const { mutateAsync: addWeightItemToCart } = useAddWeightItemToCartMutation();
  const isAutoPrintLabelsEnabled = useSelector((state: State) => state.settings.userSettings.autoPrintLabels);
  const autoPrintLabels = useAutoPrintLabels();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  return async ({
    batchId,
    cannabisInventory,
    defaultLabelId,
    productDescription,
    productId,
    recUnitPrice,
    serialNo,
    totalCost,
    unitPrice,
    actualWeight,
    weightOverride,
  }: UseAddBulkItemToCart) => {
    if (!guestId || !registerId || !shipmentId) {
      return;
    }

    const payload: AddWeightItemToCartPayload = {
      availableOz,
      batchId,
      cannabisProduct: cannabisInventory,
      comments: '',
      discountAmt: 0,
      grouping: 'No',
      guestId,
      isLoyaltyAsDiscountEnabled,
      isRunAutoDiscountEnabled,
      isRunAutoPriceEnabled,
      isUsingDaysSupply,
      orderId: 0,
      pricedWeight: Number(weightOverride || actualWeight),
      productDescription,
      productId,
      recUnitPrice,
      registerId,
      serialNo,
      shipmentId,
      totalCost: totalCost ?? unitPrice * Number(actualWeight),
      unitPrice,
      weight: Number(actualWeight || weightOverride),
    };

    try {
      const inventoryId = await addWeightItemToCart(payload);

      // Auto print labels if enabled
      if (isAutoPrintLabelsEnabled) {
        const cartItem: CartItem = {
          BatchId: batchId,
          CannbisProduct: cannabisInventory,
          DefaultLabelId: defaultLabelId,
          Grams: payload.weight,
          Id: 0,
          InventoryId: inventoryId,
          OrderNo: '',
          Product: productDescription,
          ProductId: productId,
          QtyAllocated: 1,
          QtySelected: 1,
          SerialNo: serialNo,
          TotalCost: payload.totalCost,
          WgtCnt: 'Wgt',
        };

        autoPrintLabels({ cartItem });
      }
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : 'There was an error adding the item to the cart';
      dispatch(errorNotification(errorMessage));
      logger.error(error, { message: errorMessage, payload });
      // Need to throw the error to prevent the success notification from displaying
      throw error;
    } finally {
      // Will not need to do this manually if React Query is enabled
      // The mutation invalidates the cache automatically
      if (!isAnonymousCartLDFlagEnabled) {
        // Refetch the cart with Redux
        refetchCartDetails();
      }
    }
  };
};
