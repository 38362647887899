import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnonymousTransactionPopup } from './components/AnonymousTransactionPopup';
import { CancelTransactionPopup } from '../sharedPopups/CancelTransactionPopup';
import { CreatePreOrderPopup } from '../sharedPopups/CreatePreOrderPopup';
import { NotifyCustomerPopup } from '../sharedPopups/NotifyCustomerPopup';
import { ReleasePopup } from './components/ReleasePopup';
import { SecurityCheckinPopup } from './components/SecurityCheckinPopup';
import { SelectCollectorPopup } from '../sharedPopups/SelectCollectorPopup';
import { SetOrderStatusPopup } from '../sharedPopups/SetOrderStatusPopup';
import { SetTransactionReferencePopup } from '../sharedPopups/SetTransactionReferencePopup';
import { StartOrderPopup } from '../sharedPopups/StartOrderPopup';
import { VerifyBirthdatePopup } from '../sharedPopups/VerifyBirthdatePopup';
import { VersionCheckerPopup } from './components/VersionCheckerPopup';
import { VisitReasonPopup } from '../sharedPopups/VisitReasonPopup';
import { ScanPopup } from './components/ScanPopup';
import { CustomerInsightsPopup } from './components/CustomerInsightsPopup';
import { ScanDetailsPopup } from './components//ScanDetailsPopup/ScanDetailsPopup';
import { BannedCustomerPopup } from './components/BannedCustomerPopup';
import { closePopup as closeGuestListPopup } from 'store/actions/PopupsActions';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

import type { State } from 'store';

export const GuestListPopups: FC = () => {
  const dispatch = useDispatch();

  const {
    anonymousTransactionPopup,
    cancelTransactionPopup,
    createPreOrderPopup,
    fulfillmentSelectorPopup,
    notifyCustomerPopup,
    releasePopup,
    securityCheckinPopup,
    selectCollectorPopup,
    setStatusPopup,
    setTransactionReferencePopup,
    verifyBirthdatePopup,
    versionCheckerPopup,
    visitReasonPopup,
    scanPopup,
    scanDetailsPopup,
    customerInsightsPopup,
    bannedCustomerPopup,
  } = useSelector((state: State) => state.popups);

  const closePopup = (popupName: string) => dispatch(closeGuestListPopup(popupName));

  // Some popups have been moved to GlobalPopups and don't need to be defined here
  // once we clean up the anonymous cart flag
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  return (
    <>
      {anonymousTransactionPopup && (
        <AnonymousTransactionPopup
          {...anonymousTransactionPopup}
          hide={() => closePopup('anonymousTransactionPopup')}
        />
      )}
      {cancelTransactionPopup && (
        <CancelTransactionPopup {...cancelTransactionPopup} hide={() => closePopup('cancelTransactionPopup')} />
      )}
      {createPreOrderPopup && !isAnonymousCartLDFlagEnabled && (
        <CreatePreOrderPopup {...createPreOrderPopup} isVisible hide={() => closePopup('createPreOrderPopup')} />
      )}
      {fulfillmentSelectorPopup && !isAnonymousCartLDFlagEnabled && (
        <StartOrderPopup {...fulfillmentSelectorPopup} hide={() => closePopup('fulfillmentSelectorPopup')} />
      )}
      {notifyCustomerPopup && (
        <NotifyCustomerPopup {...notifyCustomerPopup} hide={() => closePopup('notifyCustomerPopup')} />
      )}
      {releasePopup && <ReleasePopup {...releasePopup} hide={() => closePopup('releasePopup')} />}
      {securityCheckinPopup && (
        <SecurityCheckinPopup {...securityCheckinPopup} hide={() => closePopup('securityCheckinPopup')} />
      )}
      {selectCollectorPopup && !isAnonymousCartLDFlagEnabled && (
        <SelectCollectorPopup {...selectCollectorPopup} hide={() => closePopup('selectCollectorPopup')} />
      )}
      {setStatusPopup && <SetOrderStatusPopup {...setStatusPopup} hide={() => closePopup('setStatusPopup')} />}
      {setTransactionReferencePopup && (
        <SetTransactionReferencePopup
          {...setTransactionReferencePopup}
          hide={() => closePopup('setTransactionReferencePopup')}
        />
      )}
      {verifyBirthdatePopup && !isAnonymousCartLDFlagEnabled && (
        <VerifyBirthdatePopup {...verifyBirthdatePopup} hide={() => closePopup('verifyBirthdatePopup')} />
      )}
      {versionCheckerPopup && (
        <VersionCheckerPopup {...versionCheckerPopup} hide={() => closePopup('versionCheckerPopup')} />
      )}
      {visitReasonPopup && <VisitReasonPopup {...visitReasonPopup} hide={() => closePopup('visitReasonPopup')} />}
      {scanPopup && <ScanPopup {...scanPopup} hide={() => closePopup('scanPopup')} />}
      {customerInsightsPopup && <CustomerInsightsPopup {...customerInsightsPopup} hide={() => closePopup('customerInsightsPopup')} />}
      {scanDetailsPopup && <ScanDetailsPopup {...scanDetailsPopup} hide={() => closePopup('scanDetailsPopup')} />}
      {bannedCustomerPopup && (
        <BannedCustomerPopup {...bannedCustomerPopup} hide={() => closePopup('bannedCustomerPopup')} />
      )}
    </>
  );
};
