import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTransactionPaymentQuery } from 'queries/v2/product/get-transaction-payment';
import { useVoidReasonsQuery } from 'queries/posv3/maintenance/get-void-reasons';
import { useVoidTransactionMutation } from 'queries/v2/cart/void-transaction';
import { successNotification, warningNotification } from 'store/actions/NotificationsActions';

import type { VoidTransactionPopupProps } from './VoidTransactionPopup';
import type { State } from 'store';
import type { SelectOption } from 'components/inputs';

export const useVoidTransactionPopup = ({ shipmentId, reloadTransactions, hide }: VoidTransactionPopupProps) => {
  const dispatch = useDispatch();

  // State
  const registerId = useSelector((state: State) => state.settings.selectedRegister)?.value ?? 0;
  const [isCashVoid, setIsCashVoid] = useState(true);
  const [selectedVoidReason, setSelectedVoidReason] = useState('');
  const [managerPin, setManagerPin] = useState('');

  // React Query
  const { data: transactionDetails, isLoading: isLoadingTransactionDetails } = useTransactionPaymentQuery({
    shipmentId,
  });
  const { data: voidReasons, isLoading: isLoadingVoidReasons } = useVoidReasonsQuery();
  const { mutate: voidTransaction, isLoading: isVoidingTransaction } = useVoidTransactionMutation({
    onSuccess: () => {
      reloadTransactions();
      dispatch(successNotification('Transaction voided successfully'));
      hide();
    },
    onError: () => {
      dispatch(warningNotification('There was an issue voiding the transaction. Please check your pin and try again.'));
      setManagerPin('');
    },
  });

  // Calculated values
  const voidReasonOptions: SelectOption[] =
    voidReasons?.map((reason) => ({
      key: reason.reasonId,
      label: reason.reason,
      value: reason.reason,
    })) ?? [];

  const payments = useMemo(() => {
    return (
      transactionDetails?.payments
        .filter((details) => details.amount > 0)
        .sort((a, b) => {
          if (a.amount === b.amount) {
            return 0;
          }
          return a.amount > b.amount ? -1 : 1;
        }) ?? []
    );
  }, [transactionDetails]);

  const isContinueButtonDisabled =
    (voidReasonOptions.length > 0 && selectedVoidReason === '') ||
    managerPin === '' ||
    isLoadingTransactionDetails ||
    isLoadingVoidReasons ||
    isVoidingTransaction;

  // Event handlers
  const handleSelectVoidType = (newValue: string) => setIsCashVoid(newValue === 'Yes');

  const handleChangeManagerPin = (e: React.ChangeEvent<HTMLInputElement>) => setManagerPin(e.target.value);

  const handleVoidTransaction = async () => {
    voidTransaction({
      isCashVoid,
      managerPin,
      registerId,
      transactionId: shipmentId,
      voidReason: selectedVoidReason,
    });
  };

  return {
    handleChangeManagerPin,
    handleSelectVoidReason: setSelectedVoidReason,
    handleSelectVoidType,
    handleVoidTransaction,
    isCashVoid,
    isContinueButtonDisabled,
    isLoadingTransactionDetails,
    isLoadingVoidReasons,
    isVoidingTransaction,
    managerPin,
    payments,
    selectedVoidReason,
    transactionDetails,
    voidReasonOptions,
  };
};
