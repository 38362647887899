import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { HardwareService } from '@dutchie/capacitor-hardware';
import { useLabelPrinterSettingsListItem } from '../useLabelPrinterSettingsListItem';
import { PrinterIcon } from 'assets/icons/settings/PrinterIcon';
import { Row } from 'components/layout';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { HardwareSettings } from '../../types';
import { Button } from 'components/buttons';

export const LabelPrinterTestPrintListItem = () => {
  const { printTestLabel } = useLabelPrinterSettingsListItem();
  const { values } = useFormikContext<HardwareSettings>();
  const printer = HardwareService.labelPrinter.deviceById(values.labelPrinterId);
  const disabled = !values.labelPrinterId || !printer;

  return (
    <SettingsListItem
      title='Print label'
      subtitle='Test label printer functionality'
      actions={[
        <Button
          automationId='label_printer_settings-test_print_button'
          type='button'
          secondary
          disabled={disabled}
          onClick={() => printTestLabel(values.labelPrinterId)}
        >
          <StyledRow>
            <PrinterIcon />
            Print
          </StyledRow>
        </Button>,
      ]}
    />
  );
};

const StyledRow = styled(Row)`
  align-items: center;
  gap: 10px;
`;
