import React, { useEffect, useState, forwardRef, SVGProps } from 'react';
import { useField } from 'formik';
import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';
import { Select } from './Select';
import { FieldMessage, Label } from 'components/text';

import type { SelectOption, SelectOptionValue } from './Select';

export type SelectFieldProps = {
  name: string;
  label?: string;
  message?: string;
  placeholder?: string;
  disabled?: boolean;
  options: SelectOption[];
  className?: string;
  automationId?: string;
  onChange?: (newValue: string) => void;
  isInputAdornment?: boolean;
  defaultValue?: SelectOptionValue;
  endAdornment?: React.ReactElement | React.FC<SVGProps<SVGSVGElement>>;
};

// ENG-41650 - value is valid if it's included in the options.
// If it's not included, we don't want it to be pre-selected initially.
const isValueValid = (value: SelectOptionValue, options: SelectOption[]) => {
  return options.some((option: SelectOption) => String(option.value) === String(value));
};

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const {
    name,
    label,
    message,
    placeholder,
    disabled,
    options,
    className,
    automationId,
    onChange,
    isInputAdornment,
    defaultValue,
    endAdornment,
  } = props;
  const [field, meta] = useField(name);
  const [value, setValue] = useState(isValueValid(field.value, options) ? field.value : '');

  useEffect(() => {
    if (isValueValid(field.value, options)) {
      setValue(field.value);
    }
  }, [field.value, options]);

  const hasError = meta.touched && meta.error;

  const handleChange = (value: string, event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(value);
    field.onChange(event);
  };

  return (
    <SelectFieldContainer className={className} title={hasError ? meta.error : ''} isInputAdornment={isInputAdornment}>
      {label && <Label className={hasError ? 'error' : ''}>{label}</Label>}
      <FieldContainer>
        <StyledSelect
          {...field}
          className={hasError ? 'error' : ''}
          data-testid={automationId}
          automationId={automationId}
          value={value || ''}
          disabled={disabled}
          ref={ref}
          options={options}
          onChange={handleChange}
          placeholder={placeholder}
          isInputAdornment={isInputAdornment}
          defaultValue={defaultValue}
          endAdornment={endAdornment}
        />
        {message && <FieldMessage data-testid={`${automationId}_message`}>{message}</FieldMessage>}
      </FieldContainer>
    </SelectFieldContainer>
  );
});

const SelectFieldContainer = styled.div<{ isInputAdornment?: boolean }>`
  margin-bottom: 1rem;

  ${({ isInputAdornment }) =>
    isInputAdornment &&
    css`
      margin-bottom: 0;
    `}
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const StyledSelect = styled(Select)`
  &.error {
    select {
      border-color: ${colors.dutchie.red};
      color: ${colors.dutchie.red};
    }
  }
`;
