import React, { FC } from 'react';
import { ChangePaymentTypePopup } from './components/ChangePaymentTypePopup';
import { EmailPopup } from '../sharedPopups/EmailPopup';
import { ManagerPinPopup } from '../sharedPopups/ManagerPinPopup';
import { TransactionPopup } from '../sharedPopups/TransactionPopup';
import { VoidTransactionPopup } from './components/VoidTransactionPopup';
import { TransactionsPopupsState } from './TransactionsPopupsState';

type TransactionsPopupsProps = {
  transactionsPopupsState: TransactionsPopupsState;
  closePopup: () => void;
};

export const TransactionsPopups: FC<TransactionsPopupsProps> = ({ transactionsPopupsState, closePopup }) => {
  const { changePaymentTypePopup, emailPopup, managerPinPopup, transactionPopup, voidTransactionPopup } =
    transactionsPopupsState;

  return (
    <>
      {changePaymentTypePopup && <ChangePaymentTypePopup {...changePaymentTypePopup} hide={closePopup} />}
      {emailPopup && <EmailPopup {...emailPopup} hide={closePopup} />}
      {managerPinPopup && <ManagerPinPopup {...managerPinPopup} hide={closePopup} />}
      {transactionPopup && <TransactionPopup {...transactionPopup} hide={closePopup} />}
      {voidTransactionPopup && <VoidTransactionPopup {...voidTransactionPopup} hide={closePopup} />}
    </>
  );
};
