import React from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

// utils
import { colors } from 'css/Theme';
import { useGetSegmentIcon } from 'util/hooks/useGetSegmentIcon';
import { useCustomerInsightsSegmentsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsSegmentsFlag';

// assets
import { LockedIcon } from 'assets/icons/cart/LockedIcon';

// queries
import { useAnalyticsQuery } from 'queries/personalization/analytics';
import { useSegmentsQuery } from 'queries/personalization/segments';

// global components
import { Pill } from 'components/misc/Pill';
import { AlertBanner, AlertBannerStyles, Skeleton } from 'components/misc';

// local components
import { Divider } from './Divider';

type StatisticsProps = {
  customerId: number;
};

type InsightsSectionProps = {
  customerId: number;
};

export function formatDate(isoString: string | null): string | null {
  if (!isoString) {
    return null;
  }

  const date = parseISO(isoString);
  return format(date, 'MMMM d, yyyy');
}

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}

function Analytics(props: StatisticsProps) {
  const { customerId } = props;

  const { data, isLoading, isError } = useAnalyticsQuery({ customerId });

  if (isLoading) {
    return (
      <AnalyticsContainer data-testid='customer-insights-analytics-loading-state'>
        <Skeleton height={62} width='50%' />
        <Skeleton height={62} width='50%' />
      </AnalyticsContainer>
    );
  }

  if (isError) {
    return (
      <AlertBanner

        marginTop='0'
        style={AlertBannerStyles.error}
        title='Error:'
        text='Unable to load customer analytics'
      />
    );
  }

  if (data?.avg_spend_last_90 && data?.last_order_date_local) {
    return (
      <AnalyticsContainer>
        <AnalyticsCard>
          <AnalyticsCardTitle>Avg. cart over last 90 days</AnalyticsCardTitle>
          <AnalyticsCardValue>{formatCurrency(data.avg_spend_last_90)}</AnalyticsCardValue>
        </AnalyticsCard>
        <AnalyticsCard>
          <AnalyticsCardTitle>Last purchase date</AnalyticsCardTitle>
          <AnalyticsCardValue>{formatDate(data.last_order_date_local)}</AnalyticsCardValue>
        </AnalyticsCard>
      </AnalyticsContainer>
    );
  }

  return (
    <AnalyticsMessage>
      <span>Insights available after 1 completed order</span>
      <LockedIcon />
    </AnalyticsMessage>
  );
}

type SegmentsProps = {
  customerId: number;
};

function Segments(props: SegmentsProps) {
  const { customerId } = props;

  const getSegmentIcon = useGetSegmentIcon();

  const { data, isError, isLoading } = useSegmentsQuery({ customerId });

  if (isError) {
    return (
      <AlertBanner
        marginTop='0'
        style={AlertBannerStyles.error}
        title='Error:'
        text='Unable to load customer segments'
      />
    );
  }

  if (isLoading) {
    return (
      <SegmentsContainer data-testid='customer-insights-segments-loading-state'>
        <Skeleton height={24} width={98} />
        <Skeleton height={24} width={129} />
        <Skeleton height={24} width={73} />
      </SegmentsContainer>
    );
  }

  if (data?.length) {
    return (
      <SegmentsContainer>
        {data.map((segment) => (
          <Pill icon={getSegmentIcon(segment.Icon)} text={segment.BadgeName} textColor='#ffffff' backgroundColor={segment.Color} />
        ))}
      </SegmentsContainer>
    );
  }

  return null;
}

export function InsightsSection(props: InsightsSectionProps) {
  const { customerId } = props;

  const showSegments = useCustomerInsightsSegmentsFlag();

  return (
    <>
      <Container>
        <Analytics customerId={customerId} />
        {showSegments && <Segments customerId={customerId} />}
      </Container>
      <Divider />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AnalyticsContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const AnalyticsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 12px;
  background: ${colors.dutchie.grey10};
  color: ${colors.dutchie.gray80};
  font-size: 16px;
  font-weight: 600;
`;

const AnalyticsCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${colors.dutchie.grey10};
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
`;

const AnalyticsCardTitle = styled.span`
  color: ${colors.dutchie.grey70};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

const AnalyticsCardValue = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const SegmentsContainer = styled.div`
  display: flex;
  gap: 8px;
`;
