import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMapsOpen } from 'store/actions/DeliveryActions';
import styled from 'styled-components';

const MapTableToggle = (props: { icons: ReactElement[]; mapsOpen: boolean }) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(props.mapsOpen ? 1 : 0);
  return (
    <ToggleContainer>
      {props.icons.map((option, index) => (
        <ToggleOption
          key={index}
          active={index === activeIndex}
          onClick={() => {
            if (index === activeIndex) {
              return;
            }
            dispatch(setMapsOpen(!props.mapsOpen));
            setActiveIndex(index);
          }}
        >
          {option}
        </ToggleOption>
      ))}
    </ToggleContainer>
  );
};

export default MapTableToggle;

const ToggleContainer = styled.div`
  display: flex;
  background-color: #e3e7e9;
  padding: 0.25rem;
  border-radius: 0.375rem;
  gap: 0.5rem;
  overflow: hidden;
  margin-right: 5px;
  height: 100%;
`;

const ToggleOption = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0.5rem 0.75rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: ${(props) => (props.active ? '#FFFFFF' : '#E3E7E9')};
  color: ${(props) => (props.active ? '#121516' : '#828A8F')};
  transition: all 0.3s ease-in-out;
`;
