import { useReceiptPrinters } from '@dutchie/capacitor-hardware';
import { useFormikContext } from 'formik';

import { FulfillmentReceiptPrinterSettingsContextData } from '../types';
import { HardwareSettings } from '../../types';

export const useFulfillmentReceiptPrinterSettingsProvider = (): FulfillmentReceiptPrinterSettingsContextData => {
  const { values: formValues, setFieldValue } = useFormikContext<HardwareSettings>();

  const { receiptPrinters: allPrinters, search } = useReceiptPrinters({
    onDevicesAuthorized: (printers) => selectPrinter(printers[0].id),
  });

  const selectPrinter = (id?: string): void => setFieldValue('fulfillmentPrinterId', id);
  const setAutoPrintTickets = (value: boolean) => setFieldValue('autoPrintFulfillmentTickets', value);

  return {
    formValues,
    printers: allPrinters,
    search,
    selectPrinter,
    setAutoPrintTickets,
  };
};
