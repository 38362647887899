import styled from 'styled-components';

import { colors, zIndices } from 'css/Theme';

export const TOTAL_BREAKDOWN_DRAWER_TRANSITION_DURATION = 300;

export const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 2px 20px 0px #0000004D;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: ${zIndices.totalBreakdownDrawer};
  background: white;

  &.total-breakdown-drawer-enter {
    transform: translateY(100%);
    opacity: 0;
    transition: all ${TOTAL_BREAKDOWN_DRAWER_TRANSITION_DURATION}ms ease;
  }

  &.total-breakdown-drawer-enter-active {
    transform: translateY(0);
    opacity: 1;
  }

  &.total-breakdown-drawer-exit {
    transform: translateY(0);
    opacity: 1;
    transition: all ${TOTAL_BREAKDOWN_DRAWER_TRANSITION_DURATION}ms ease;
  }

  &.total-breakdown-drawer-exit-active {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  color: ${colors.dutchie.black}
`;

export const SubTotalRow = styled(Row)`
  font-weight: 600;
`;

export const PreAuthRow = styled(Row)`
  border-top: 1px solid ${colors.dutchie.grey90};
  padding-top: 16px;
`;

export const PreAuthLineItemText = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
`;

export const NegativeValueContainer = styled.div`
`;
