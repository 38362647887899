import React, { FC } from 'react';

import { DiscountContainer, Description, DiscountAmount } from './Discount.styles';
import { DiscountActionButton } from './components/DiscountsActionButton';
import { useDiscount } from './useDiscount';
import { CART_LINE_ITEM_TEST_IDS } from '../../CartLineItem.test-ids';

import type { AppliedDiscount } from 'models/Discounts';
import type { CartItem } from 'models/Cart';

const { discountItemTestId, discountDescriptionTestId, discountAmountTestId } = CART_LINE_ITEM_TEST_IDS;

type DiscountProps = {
  item: CartItem;
  discount: AppliedDiscount;
};

export const Discount: FC<DiscountProps> = ({ item, discount }) => {
  const { Icon, Label, discountDescription, discountAmount, handleClick } = useDiscount({ item, discount });

  return (
    <DiscountContainer data-testid={discountItemTestId}>
      <Icon />
      <Label>{discount.IsLoyalty ? 'Loyalty' : 'Discount'} applied</Label>
      <Description data-testid={discountDescriptionTestId}>{discountDescription}</Description>
      <DiscountAmount data-testid={discountAmountTestId}>
        Discount: <b>{discountAmount}</b>
      </DiscountAmount>
      <DiscountActionButton isLoyalty={discount.IsLoyalty} onClick={() => handleClick(discount)} />
    </DiscountContainer>
  );
};
