import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useRefetchProductsAfterFailedAddItem = () => {
  const ldClient = useLDClient();
  const isRefetchProductsAfterFailedAddItemRolledBack: boolean = ldClient?.variation(
    'pos.register.refetch-products-after-failed-add-item.rollback',
    false
  );
  return { isRefetchProductsAfterFailedAddItemEnabled: !isRefetchProductsAfterFailedAddItemRolledBack };
};
