import React from 'react';

import { colors } from 'css/Theme';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const GlobeIcon = ({ width = 16, height = 16, color = colors.dutchie.gray90 }: IconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 16 16' fill='none'>
      <g clip-path='url(#clip0_2661_38160)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M6.88153 0.262868C7.10455 0.425718 7.15332 0.738522 6.99047 0.961534C6.03481 2.27023 5.33333 4.96447 5.33333 8.03333C5.33333 11.0974 6.03304 13.7301 6.99366 15.0762C7.15407 15.301 7.10189 15.6132 6.87711 15.7737C6.65234 15.9341 6.34008 15.8819 6.17968 15.6571C5.04697 14.0699 4.33333 11.1959 4.33333 8.03333C4.33333 4.87553 5.04519 1.92977 6.18287 0.3718C6.34572 0.148788 6.65852 0.100017 6.88153 0.262868Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9.11847 0.262868C9.34148 0.100017 9.65428 0.148788 9.81713 0.3718C10.9548 1.92977 11.6667 4.87553 11.6667 8.03333C11.6667 11.1959 10.953 14.0699 9.82033 15.6571C9.65992 15.8819 9.34766 15.9341 9.12289 15.7737C8.89811 15.6132 8.84593 15.301 9.00634 15.0762C9.96697 13.7301 10.6667 11.0974 10.6667 8.03333C10.6667 4.96447 9.96519 2.27023 9.00953 0.961534C8.84668 0.738522 8.89546 0.425718 9.11847 0.262868Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0 8C0 7.72386 0.223858 7.5 0.5 7.5H15.5C15.7761 7.5 16 7.72386 16 8C16 8.27614 15.7761 8.5 15.5 8.5H0.5C0.223858 8.5 0 8.27614 0 8Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0.866667 11.5C0.866667 11.2239 1.09052 11 1.36667 11H14.6333C14.9095 11 15.1333 11.2239 15.1333 11.5C15.1333 11.7761 14.9095 12 14.6333 12H1.36667C1.09052 12 0.866667 11.7761 0.866667 11.5Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0.866667 4.5C0.866667 4.22386 1.09052 4 1.36667 4H14.6333C14.9095 4 15.1333 4.22386 15.1333 4.5C15.1333 4.77614 14.9095 5 14.6333 5H1.36667C1.09052 5 0.866667 4.77614 0.866667 4.5Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2661_38160'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
