import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { MessagingChannelConfiguration, MessagingConfigRequest } from 'models/Messaging';
import { getIsAblyRolloutEnabled } from 'util/hooks/launch-darkly/useAblyRollout';

// This is meant to be used with all messaging providers, but for now, will only be in use with Ably
// If implemented for pusher, will need to be retested
export function useMessagingConfigQuery(payload: MessagingConfigRequest) {
    const enabled = getIsAblyRolloutEnabled();

    return useQuery<MessagingChannelConfiguration>({
        queryKey: ['Messaging-Config', payload.Provider, payload.ChannelType],
        queryFn: () => post<MessagingChannelConfiguration>(`messaging/config/${payload.ChannelType}`, payload),
        enabled,
    });
}
