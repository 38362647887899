import React, {
  FC,
  ReactElement,
  ChangeEvent,
  forwardRef,
  SVGProps,
  useState,
  useEffect,
  useRef,
  RefObject,
} from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';

import { colors } from 'css/Theme';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

export type SelectOptionValue = string | number | string[];

export type SelectOption = {
  key?: string | number;
  value?: SelectOptionValue;
  label: string;
};

export type SelectProps = {
  options: SelectOption[];
  defaultValue?: SelectOptionValue;
  value?: SelectOptionValue;
  onChange?: (newValue: string, event: ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  placeholder?: string;
  automationId?: string;
  allowOther?: boolean;
  disabled?: boolean;
  name?: string;
  isInputAdornment?: boolean;
  endAdornment?: ReactElement | FC<SVGProps<SVGSVGElement>>;
  errorMessage?: string | false;
  height?: string;
};

const useRefWidth = (ref: RefObject<HTMLElement>) => {
  const [widthPx, setWidthPx] = useState<number | null>(null);

  useEffect(() => {
    if (ref.current) {
      setWidthPx(ref.current.offsetWidth);
    }
  }, [ref]);

  return widthPx;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    disabled,
    className,
    defaultValue,
    value,
    onChange,
    options,
    placeholder,
    automationId,
    allowOther,
    name,
    isInputAdornment,
    errorMessage,
    endAdornment,
    height,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!onChange) {
      return;
    }

    onChange((event.target as HTMLSelectElement).value, event);
  };

  const endAdornmentRef = useRef<HTMLDivElement>(null);
  const endAdornmentWidth = useRefWidth(endAdornmentRef);

  return (
    <>
      <SelectWrapper className={className} isInputAdornment={isInputAdornment}>
        <StyledSelect
          height={height}
          {...(defaultValue ? { defaultValue } : { value: value || '' })}
          data-testid={automationId}
          onChange={handleChange}
          disabled={disabled}
          name={name}
          ref={ref}
          endAdornmentWidth={endAdornmentWidth}
        >
          {placeholder && (
            <option value='' disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <StyledOption key={`${option.label}-${option.value}-${option.key}`} value={option.value}>
              {option.label}
            </StyledOption>
          ))}
          {allowOther && (
            <StyledOption key='other' value='other'>
              other
            </StyledOption>
          )}
        </StyledSelect>
        {endAdornment && <EndAdormentWrapper ref={endAdornmentRef}>{endAdornment}</EndAdormentWrapper>}
        <StyledChevron />
      </SelectWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
});

const StyledSelect = styled.select<{ height?: string; endAdornmentWidth?: number | null }>`
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid ${colors.dutchie.borderGrey};
  height: ${({ height }) => height || '3rem'};
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  padding-right: 3rem;
  appearance: none;
  color: ${colors.dutchie.almostBlack};
  background-color: ${colors.dutchie.primaryWhite};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: ${colors.dutchie.almostBlack};
  }

  ${({ value, defaultValue }) =>
    isEmpty(value) &&
    !value &&
    !defaultValue &&
    `
        color: ${colors.dutchie.grey};
    `}

  ${({ disabled }) =>
    disabled &&
    `
        background-color: ${colors.dutchie.lightGrey};
    `}

    ${({ endAdornmentWidth }) =>
    endAdornmentWidth &&
    `
        padding-right: calc(3rem + ${endAdornmentWidth}px + 1rem);
    `}
`;

const StyledOption = styled.option`
  color: ${colors.dutchie.darkGrey};
`;

const StyledChevron = styled(ChevronDown)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.dutchie.grey};
  pointer-events: none;
`;

const EndAdormentWrapper = styled.div`
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
`;

const SelectWrapper = styled.div<{ isInputAdornment?: boolean }>`
  position: relative;

  ${({ isInputAdornment }) =>
    isInputAdornment &&
    css`
      max-width: 150px;

      ${StyledSelect} {
        padding-right: 2rem;
        text-overflow: ellipsis;
        font-size: 14px;
        border: none;
        background-color: ${colors.dutchie.backgroundGrey};
        height: 32px;
        font-weight: 600;
        color: ${colors.dutchie.gunmetal};
      }

      ${StyledChevron} {
        width: 10px;
        color: ${colors.dutchie.gunmetal};
      }
    `}
`;

const ErrorMessage = styled.div`
  color: ${colors.dutchie.red};
  font-size: 0.825rem;
  font-weight: normal;
  line-height: 1rem;
  margin-top: 4px;
`;
