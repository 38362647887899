import { getLDClient } from 'util/launchDarkly';
import { searchAndFilter, searchAndFilterProducts } from 'util/Helpers';

import type { ProductSearchResult, ProductFilterOption } from 'queries/v2/product/types';

const keysToSearch: (keyof ProductSearchResult)[] = [
  'productDescription',
  'productNo',
  'serialNo',
  'brandName',
  'vendor',
];

export const getProductsToDisplay = (
  products: ProductSearchResult[] | undefined,
  filters: ProductFilterOption[],
  searchString: string
): ProductSearchResult[] => {
  const ldClient = getLDClient();
  const isInventoryTagFilteringInCartEnabled = ldClient?.variation(
    'pos.register.inventory-tag-filtering-in-cart.rollout',
    false
  );

  if (products === undefined) {
    return [];
  }

  if (isInventoryTagFilteringInCartEnabled) {
    return searchAndFilterProducts(products, filters, keysToSearch, searchString);
  }

  return searchAndFilter(products, filters, keysToSearch, searchString);
};
