import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { forgotPassword, handleLoginV2, ssoLoginV2, ssoLoginEnabled } from 'store/actions/UserActions';
import { removeNotification } from 'store/actions/NotificationsActions';
import { Input } from 'components/inputs';
import { Button, LoadingButton } from 'components/buttons';
import { ReactComponent as AccountIcon } from 'assets/icons/icon-account.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/icon-password.svg';
import { ReactComponent as EyeOpen } from 'assets/icons/icon-eye-open.svg';
import { ReactComponent as EyeClosed } from 'assets/icons/icon-eye-closed.svg';
import { colors } from 'css/Theme';
import { isAndroid, isWebViewApp, setWebViewAppRegion } from 'util/hooks';
import { RegionList } from 'util/RegionList';
import { selectUserRegion } from 'store/actions/SettingsActions';
import { UnauthorizedPageLayout, Flex, Box } from 'components/layout';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-alt.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoginPageV2 } from './LoginPageV2';
import { handleEnforcingPinLoginRedirect } from './PinLoginPage/utils/login-utils';
import { useServerMigrationConfig } from 'components/layout/ServerMigrationUI/useServerMigrationConfig';
import { useNewRegion } from 'components/layout/ServerMigrationUI/useNewRegion';

const REGION_TOOLTIP =
  'To find the Region/Server, reference the first part of the URL you use to access LeafLogix POS via web browser. For example, the region for west.leaflogix.net is “West”';

export type LoginProps = {
  isSSO: boolean;
};

type InputContainerStyleProps = {
  isNewLoginEnabled: boolean;
};

export const LoginPageLoadingVersion: FC<LoginProps> = ({ isSSO }) => {
  const notifications = useSelector((state: State) => state.notifications);
  const dispatch = useDispatch();
  const [region, setRegion] = useState('');
  const [regionError, setRegionError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [loginBtnLoading, setLoginBtnLoading] = useState(false);
  const isSSOEnabled = useSelector((state: State) => state.user.enableSso);
  const [isSSOLogin, setIsSSOLogin] = useState(isSSO);
  const selectedRegion = useSelector((state: State) => state.settings.region);
  const flags = useFlags();
  const isNewLoginEnabled = flags && flags['pos.iam.register-ux-updates-2023-11.rollout'];
  const { registerURL } = useServerMigrationConfig();
  const newRegion = useNewRegion();

  const isRegionSelectionEnabled = isAndroid || isWebViewApp;

  useEffect(() => {
    handleEnforcingPinLoginRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(ssoLoginEnabled());
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    if (flags && flags['sso.disable-user-password-login']) {
      setIsSSOLogin(true);
    }
  }, [flags]);

  const selectRegion = () => {
    const fixedRegion = region.toLowerCase();
    if (!(fixedRegion in RegionList)) {
      setRegionError('Invalid Region');
      return;
    }

    /**
     * The user has been migrated to a new server, and the region they selected is not their new one.
     */
    if (registerURL && !RegionList[fixedRegion].includes(registerURL) && newRegion) {
      setRegion(newRegion);
      setRegionError(
        'The server you specified has been disabled. We have autopopulated your new server, please use it going forward.'
      );
      return;
    }

    if (isWebViewApp) {
      const regionUrl = RegionList[fixedRegion].replace('/api', '');
      setWebViewAppRegion({ name: fixedRegion, url: regionUrl });
    }

    dispatch(selectUserRegion(fixedRegion));
  };

  const clearRegion = () => {
    dispatch(selectUserRegion(undefined));
  };

  const _initSsoLogin = async () => {
    setLoginBtnLoading(true);
    isNewLoginEnabled && notifications.length > 0 && notifications.map((n) => dispatch(removeNotification(n.id)));
    await dispatch(ssoLoginV2({ username }));
    setLoginBtnLoading(false);
  };

  const handleSSOLoginFromForm = () => {
    _initSsoLogin();
  };

  const handleSSOLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    _initSsoLogin();
  };

  const loginUser = async () => {
    setLoginBtnLoading(true);
    await dispatch(handleLoginV2({ username, password, ldFlags: flags }));
    setLoginBtnLoading(false);
  };

  const LoginCommonCode = (
    <>
      {isRegionSelectionEnabled && !selectedRegion && (
        <>
          {isNewLoginEnabled && (
            <>
              <Caption>
                Select your region <TooltipIcon title={REGION_TOOLTIP} />
              </Caption>
              <InputContainer isNewLoginEnabled={isNewLoginEnabled}>
                <Input
                  withDarkBackground
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  placeholder='Region / Server'
                  data-testid='login-page_input_region'
                />
              </InputContainer>
              {regionError && <StyledInlineError>{regionError}</StyledInlineError>}
              <Button
                disabled={!region}
                fullWidth
                onClick={selectRegion}
                automationId='login-page_button_select-region'
              >
                Select
              </Button>
            </>
          )}
          {!isNewLoginEnabled && (
            <>
              <SelectRegionCaption>
                Select your region
                <TooltipIcon title={REGION_TOOLTIP} />
              </SelectRegionCaption>
              <InputContainer isNewLoginEnabled={isNewLoginEnabled}>
                <Input
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  placeholder='Region / Server'
                  data-testid='login-page_input_region'
                />
              </InputContainer>
              {regionError && <RegionError>{regionError}</RegionError>}
              <Box>
                <Button onClick={selectRegion} automationId='login-page_button_select-region'>
                  Select
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {(!isRegionSelectionEnabled || selectedRegion) && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              isNewLoginEnabled &&
                notifications.length > 0 &&
                notifications.map((n) => dispatch(removeNotification(n.id)));
              if (!isSSOLogin) {
                loginUser();
              } else {
                handleSSOLoginFromForm();
              }
            }}
          >
            {isRegionSelectionEnabled && (
              <RegionServerName $isNewLogin={isNewLoginEnabled}>Region / Server: {selectedRegion}</RegionServerName>
            )}
            <InputContainer isNewLoginEnabled={isNewLoginEnabled}>
              <Input
                withDarkBackground={isNewLoginEnabled}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                startAdornment={<StyledUserIcon />}
                placeholder='Username'
                data-testid='login-page_input_username'
              />
            </InputContainer>
            {!isSSOLogin && (
              <>
                <InputContainer isNewLoginEnabled={isNewLoginEnabled}>
                  <Input
                    withDarkBackground={isNewLoginEnabled}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    startAdornment={<StyledPasswordIcon />}
                    endAdornment={
                      !passwordShown ? (
                        <StyledEyeIcon onClick={() => setPasswordShown(!passwordShown)} />
                      ) : (
                        <StyledSlashedEyeIcon onClick={() => setPasswordShown(!passwordShown)} />
                      )
                    }
                    type={passwordShown ? 'text' : 'password'}
                    placeholder='Password'
                    data-testid='login-page_input_password'
                  />
                </InputContainer>
                {!isNewLoginEnabled && (
                  <Flex alignItems='center' justifyContent='space-between' marginBottom='12px'>
                    <Box display='flex'>
                      <LoadingButton loading={loginBtnLoading} type='submit' automationId='login-page_button_sign-in'>
                        Sign in
                      </LoadingButton>
                      {!isRegionSelectionEnabled && isSSOEnabled && (
                        <LoadingButton loading={loginBtnLoading} secondary marginLeft={8} onClick={handleSSOLogin}>
                          SSO
                        </LoadingButton>
                      )}
                    </Box>
                    <Box>
                      <ForgotPasswordLink
                        data-testid='login-page_button_forgot-password'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(forgotPassword(username));
                        }}
                      >
                        Forgot password?
                      </ForgotPasswordLink>
                    </Box>
                  </Flex>
                )}
                {isNewLoginEnabled && (
                  <>
                    {notifications &&
                      notifications.map((notif) => (
                        <StyledInlineError key={notif.id}>{notif.message}</StyledInlineError>
                      ))}
                    <Flex alignItems='center' justifyContent='space-between'>
                      {!isRegionSelectionEnabled && isSSOEnabled && (
                        <Box marginRight={'16px'} width={'100%'}>
                          <LoadingButton loading={loginBtnLoading} secondary fullWidth onClick={handleSSOLogin}>
                            SSO
                          </LoadingButton>
                        </Box>
                      )}
                      <Box width={'100%'}>
                        <LoadingButton
                          loading={loginBtnLoading}
                          type='submit'
                          automationId='login-page_button_sign-in'
                          disabled={!username || !password}
                          fullWidth
                        >
                          Login
                        </LoadingButton>
                      </Box>
                    </Flex>
                  </>
                )}
              </>
            )}
            {/* `/ssologin` page, only username field and SSO button displayed */}
            {!isRegionSelectionEnabled && isSSOLogin && (
              <>
                {isNewLoginEnabled &&
                  notifications &&
                  notifications.map((notif) => <StyledInlineError key={notif.id}>{notif.message}</StyledInlineError>)}
                <LoadingButton
                  loading={loginBtnLoading}
                  fullWidth={isNewLoginEnabled}
                  type='submit'
                  onClick={handleSSOLogin}
                >
                  {isNewLoginEnabled ? 'Login with SSO' : 'SSO'}
                </LoadingButton>
              </>
            )}
          </form>

          {isRegionSelectionEnabled && (
            <Button
              onClick={clearRegion}
              automationId='login-page_button_select-region'
              secondary={true}
              marginTop={12}
              fullWidth={isNewLoginEnabled}
            >
              Change Region
            </Button>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      {isNewLoginEnabled ? (
        <LoginPageV2 isSSOLogin={isSSOLogin}>{LoginCommonCode}</LoginPageV2>
      ) : (
        <UnauthorizedPageLayout>{LoginCommonCode}</UnauthorizedPageLayout>
      )}
    </>
  );
};

const iconStyles = {
  color: colors.dutchie.grey,
};

const passwordIconStyles = css`
  ${iconStyles}
  cursor: pointer;
`;

const StyledUserIcon = styled(AccountIcon)`
  ${iconStyles}
`;

const StyledPasswordIcon = styled(PasswordIcon)`
  ${iconStyles}
`;

const StyledEyeIcon = styled(EyeOpen)`
  ${passwordIconStyles}
`;

const StyledSlashedEyeIcon = styled(EyeClosed)`
  ${passwordIconStyles}
`;

const InputContainer = styled.div<InputContainerStyleProps>`
  margin-bottom: ${(props) => (props.isNewLoginEnabled ? '24px' : '20px')};
`;

const ForgotPasswordLink = styled.a`
  font-size: 14px;
  color: ${colors.dutchie.grey};
  text-decoration-line: underline;
`;

const SelectRegionCaption = styled.div`
  color: ${colors.dutchie.almostBlack};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const RegionError = styled.p`
  color: ${colors.dutchie.red};
  margin-top: 6px;
`;

const TooltipIcon = styled(AlertIcon)`
  cursor: help;
`;

const StyledInlineError = styled.div`
  color: ${colors.dutchie.red};
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 32px;
`;

const Caption = styled.p`
  color: ${colors.dutchie.primaryWhite};
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const RegionServerName = styled.p<{ $isNewLogin?: boolean }>`
  color: ${({ $isNewLogin }) => ($isNewLogin ? colors.dutchie.primaryWhite : colors.dutchie.almostBlack)};
`;
