import { CustomSortType } from '../TableProps';
import { DataMatrixRow } from './calculateDataMatrix';

const assertExhaustive = (value: never): never => {
  throw new Error(`Encountered unexpected value: ${value}`);
};

export const toggleSortDetails = (
  newSortColumn,
  existingSortColumn,
  sortDirection
): { sortColumn: number; sortDirection: 'asc' | 'desc' } => {
  if (newSortColumn !== existingSortColumn) {
    return { sortColumn: newSortColumn, sortDirection: 'asc' };
  }

  if (sortDirection === 'asc') {
    return { sortColumn: newSortColumn, sortDirection: 'desc' };
  } else {
    return { sortColumn: newSortColumn, sortDirection: 'asc' };
  }
};

const matchLeadingNumber = /^(\d+)/;
const compareAsc = (a: unknown, b: unknown): number => {
  if (typeof a === 'string' && typeof b === 'string') {
    const aNumberMatch = a.match(matchLeadingNumber);
    const bNumberMatch = b.match(matchLeadingNumber);
    const aNumber = aNumberMatch && aNumberMatch[1] * 1;
    const bNumber = bNumberMatch && bNumberMatch[1] * 1;

    if (aNumber !== bNumber && typeof aNumber === 'number' && typeof bNumber === 'number') {
      return aNumber - bNumber;
    }

    return a.localeCompare(b);
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  if (typeof a === 'string' || typeof a === 'number') {
    return 1;
  }

  return 0;
};

export const sortItems = <ItemType>(
  items: DataMatrixRow<ItemType>[],
  column: number,
  direction: 'asc' | 'desc',
  customSort?: CustomSortType<DataMatrixRow<ItemType>>
): DataMatrixRow<ItemType>[] => {
  const mutableItems = [...items];

  if (customSort) {
    return customSort({ items: mutableItems, sortDirection: direction });
  }

  mutableItems.sort((a, b) => {
    const comparison = compareAsc(a.cells[column]?.value, b.cells[column]?.value);
    switch (direction) {
      case 'asc':
        return comparison;
      case 'desc':
        return 0 - comparison;
      default:
        return assertExhaustive(direction);
    }
  });
  return mutableItems;
};
