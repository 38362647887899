import React, { useEffect, useCallback } from 'react';
import { ReturnItemNotification } from 'models/Cart';
import { PusherChannelType } from 'models/Pusher';
import { PusherChannel } from 'components/PusherChannel';
import { useDispatch, useSelector } from 'react-redux';
import { State, store } from 'store';
import { cleanReturnsPusherConfiguration, getReturnsPusherConfiguration } from 'store/actions/PusherActions';
import { getIsAblyRolloutEnabled } from 'util/hooks/launch-darkly/useAblyRollout';
import { AblyChannel } from 'messaging/ably/useAblyChannel';
import { MessagingChannelType } from 'models/Messaging';

export function useOnJSONPusherMessage<TNotification>(
  pusherEventName: string,
  onPusherMessageCallback: (notification?: TNotification, parseError?: unknown) => void
) {
  return useCallback(
    (eventName: string, data: string | object) => {
      if (eventName === pusherEventName) {
        try {
          let notification = null;
          if (typeof data === 'string') {
            notification = JSON.parse(data);
          } else {
            notification = data;
          }
          onPusherMessageCallback(notification, undefined);
        } catch (error) {
          onPusherMessageCallback(undefined, error);
        }
      }
    },
    [onPusherMessageCallback, pusherEventName]
  );
}

export type ReturnsPusherChannelProps = {
  enabled: boolean;
  onPusherMessageCallback: (notification?: ReturnItemNotification, parseError?: unknown) => Promise<void>;
};

export const ReturnsPusherChannel = ({ enabled, onPusherMessageCallback }: ReturnsPusherChannelProps) => {
  const pusherChannelConfig = useSelector((state: State) => state.transactions.returnsPusherConfig);
  const dispatch = useDispatch<typeof store.dispatch>();
  const onPusherMessage = useOnJSONPusherMessage('refund', onPusherMessageCallback);
  const ablyRollout = getIsAblyRolloutEnabled();

  useEffect(() => {
    dispatch(cleanReturnsPusherConfiguration());
    dispatch(getReturnsPusherConfiguration());
  }, [dispatch]);

  return (
    <>
      {pusherChannelConfig && (
        <PusherChannel
          enabled={enabled}
          channelConfig={pusherChannelConfig}
          channelType={PusherChannelType.Returns}
          onChannelMessage={onPusherMessage}
        />
      )}
      {ablyRollout && (
        <AblyChannel
          channelType={MessagingChannelType.Returns}
          channelMessageCallback={onPusherMessage}
        />
      )}
    </>
  );
};
