import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useAppDispatch } from 'util/hooks';
import { openCustomerHistoryPanel } from 'store/actions/CartActions';
import { CustomerHistoryPanelTabs } from 'components/cart/CustomerHistoryPanel/CustomerHistoryPanel';

import { CustomerInsights } from 'components/cart/CustomerInsights';
import { CustomerType, CustomerTypeBadge } from 'components/cart/CustomerType';
import { GuestNotes } from 'components/cart/GuestNotes';
import { LegalLimit } from 'components/cart/LegalLimit';
import { PreorderRedemption } from 'pages/CartPage/CartComponents/PreorderRedemption';

import { colors, zIndices } from 'css/Theme';
import { CartTotalsAndActions as _CartTotalsAndActions } from '../components/CartTotalsAndActions';
import { ExpandableContent } from './components/ExpandableContent';
import { useCartFooter } from './useCartFooter';
import { useChangeCustomerTypeInCart } from 'util/hooks/launch-darkly/useChangeCustomerTypeInCart';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

type CartFooterProps = {
  isLoading?: boolean;
};

export const CartFooter = ({ isLoading }: CartFooterProps) => {
  const dispatch = useAppDispatch();

  const {
    allotmentSummary,
    cart,
    customerType,
    isCartLoading,
    isTotalsDrawerOpen,
    isAnonymous,
    setIsTotalsDrawerOpen,
    showCartFooter,
  } = useCartFooter();

  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  const { isChangeCustomerTypeInCartEnabled } = useChangeCustomerTypeInCart();

  const expandableContent = useMemo(() => {
    if (isCustomerInsightsEnabled) {
      const content = [
        { key: 'allotment', label: 'Allotment', secondaryLabel: allotmentSummary, content: <LegalLimit /> },
        { key: 'notes', label: 'Notes', content: <GuestNotes /> },
        {
          key: 'customerInsights',
          label: 'Insights',
          content: <CustomerInsights showBottomBorder={false} />,
        },
      ];

      if (!isAnonymous) {
        content.push({
          key: 'history',
          label: 'History',
          onClick: () => dispatch(openCustomerHistoryPanel({ tab: CustomerHistoryPanelTabs.Products })),
        });
      }

      if (isChangeCustomerTypeInCartEnabled) {
        content.push({
          key: 'customerType',
          label: <CustomerTypeBadge customerType={customerType} />,
          content: <CustomerType />,
          buttonStyles: css`
            flex: unset;
          `,
        });
      }

      return content;
    }

    const content = [
      { key: 'allotment', label: 'Allotment', secondaryLabel: allotmentSummary, content: <LegalLimit /> },
      { key: 'notes', label: 'Notes', content: <GuestNotes /> },
      {
        key: 'customerInsights',
        label: 'Customer insights',
        content: <CustomerInsights showBottomBorder={false} />,
      },
      {
        key: 'customerType',
        label: <CustomerTypeBadge customerType={customerType} />,
        content: <CustomerType />,
        buttonStyles: css`
          flex: unset;
        `,
      },
    ];

    if (isChangeCustomerTypeInCartEnabled) {
      return content;
    }

    return content.filter((section) => section.key !== 'customerType');
  }, [isCustomerInsightsEnabled, allotmentSummary, customerType, isChangeCustomerTypeInCartEnabled, isAnonymous, dispatch]);

  if (!showCartFooter) {
    return null;
  }

  return (
    <FooterContainer data-testid='cart-page_cart-footer'>
      <ExpandableContent content={expandableContent} />
      <InnerContainer>
        <PreorderRedemption />
        <CartTotalsAndActions
          cart={cart}
          isLoading={isCartLoading || isLoading}
          actionButtonStyles={actionButtonStyles}
          areTotalsOpen={isTotalsDrawerOpen}
          onToggleTotals={setIsTotalsDrawerOpen}
        />
      </InnerContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  flex-shrink: 0;
  background: ${colors.dutchie.primaryWhite};
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.16);
`;

/** Prevents the expanding content from showing through the other components during animations */
const InnerContainer = styled.div`
  background: ${colors.dutchie.primaryWhite};
  z-index: ${zIndices.cartStickyPanel};
`;

const CartTotalsAndActions = styled(_CartTotalsAndActions)`
  padding: 1.5rem;
  gap: 0.25rem;
`;

const actionButtonStyles = css`
  width: 86px;
`;
