import React, { FC, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentType } from 'models/Checkout';
import { v4 as uuidv4 } from 'uuid';
import { addPaymentMethod } from 'store/actions/CheckoutActions';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { PaymentOption } from '../PaymentOption';
import { PaymentShortcuts } from './PaymentShortcuts';
import { CheckoutContext } from '../CheckoutContext';

export const PaymentGift: FC = () => {
  const dispatch = useDispatch();
  const { setSelectedPaymentType } = useContext(CheckoutContext);

  const onAddGift = useMemo(
    () => (amount: number) => {
      if (amount <= 0) {
        dispatch(errorNotification('Invalid gift card amount.'));
        return;
      }

      dispatch(addPaymentMethod({ id: uuidv4(), name: 'Gift', type: PaymentType['Gift Card'], amount }));
      dispatch(successNotification('Gift Payment added'));
      setSelectedPaymentType(null);
    },
    [dispatch, setSelectedPaymentType]
  );

  return (
    <PaymentOption onAddAmount={onAddGift}>
      <PaymentShortcuts shortcuts={[{ name: 'Exact Gift Card' }]} onAddAmount={onAddGift} />
    </PaymentOption>
  );
};
