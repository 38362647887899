import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.p`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`;

const SectionContent = styled.div<{ empty?: boolean }>`
  flex-grow: 1;
  border-radius: 0.375rem;
  overflow-y: auto;
  ${({ empty }) =>
    empty && {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.dutchie.grey40,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      border: `1px solid ${colors.dutchie.borderGrey}`,
    }}
`;

type SectionWithHeaderProps = {
  title: string;
  className?: string;
  empty?: boolean;
};

export const SectionWithHeader: FC<SectionWithHeaderProps> = ({ title, className, children, empty }) => {
  return (
    <Section className={className}>
      <SectionHeader>{title}</SectionHeader>
      <SectionContent empty={empty}>{children}</SectionContent>
    </Section>
  );
};
