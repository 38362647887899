import React, { useMemo, useCallback } from 'react';
import { debounce } from 'lodash';

import { CashEntryMode, isCoinDenomination } from 'store/reducers/CashManagementReducer';
import { Input } from 'components/inputs';
import { setCashAmount, setEntryErrorStatus } from 'store/actions/CashManagementActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';

import type { CashDenomination } from 'store/reducers/CashManagementReducer';
import type { NumberFormatValues } from 'react-number-format';

type CashInputProps = {
  denomination: CashDenomination;
  entryMode: CashEntryMode;
};

export const CashInput = ({ denomination, entryMode }: CashInputProps) => {
  const dispatch = useAppDispatch();

  const hasError = useAppSelector((state) => state.cashManagement.entryErrors[denomination]);
  const value = useAppSelector((state) => state.cashManagement.cashDetails[denomination]);

  const placeholder = useMemo(() => {
    if (denomination === 'all') {
      return 'Enter total coin value';
    }

    if (entryMode === CashEntryMode.VALUE) {
      return 'Enter dollar amount';
    } else {
      if (isCoinDenomination(denomination)) {
        return 'Enter number of coins';
      }
      return 'Enter number of bills';
    }
  }, [denomination, entryMode]);

  const validateValue = useCallback(
    debounce((amount: string) => {
      if (amount && entryMode === CashEntryMode.VALUE && denomination !== 'all') {
        // Double check that the amount is a multiple of the denomination and set error status
        const dividedQuantity = Math.floor(Number(amount) / Number(denomination));
        const recalculatedValue = (dividedQuantity * Number(denomination)).toFixed(2);
        const isValueValid = amount === recalculatedValue;
        dispatch(setEntryErrorStatus({ denomination, hasError: !isValueValid }));
      }
    }, 300),
    [entryMode, denomination, dispatch]
  );

  const handleValueChange = (values: NumberFormatValues) => {
    const amount = entryMode === CashEntryMode.VALUE ? values.formattedValue : values.value;
    dispatch(setCashAmount({ denomination, amount }));

    validateValue(amount);
  };

  return (
    <Input
      automationId={`cash-input_${denomination}`}
      type='number'
      allowNegative={false}
      decimalScale={entryMode === CashEntryMode.VALUE ? 2 : 0}
      fixedDecimalScale={entryMode === CashEntryMode.VALUE}
      placeholder={placeholder}
      value={value}
      onValueChange={handleValueChange}
      hasError={hasError}
      endAdornment={hasError ? <span data-testid='invalid-cash-entry'>Invalid</span> : undefined}
    />
  );
};
