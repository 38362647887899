import React, { ReactNode } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { ReactComponent as TimesIcon } from 'assets/icons/icon-times.svg';
import { colors } from 'css/Theme';

import { AlertBannerStyle, AlertBannerStyles } from './AlertBanner.styles';

export type AlertBannerProps = {
  style?: AlertBannerStyles;
  containerStyles?: FlattenSimpleInterpolation;
  textStyles?: FlattenSimpleInterpolation;
  icon?: JSX.Element;
  title?: string;
  text: ReactNode;
  marginTop?: string;
  thick?: boolean;
  onClose?: () => void;
};

export const AlertBanner = ({ style, containerStyles, textStyles, icon, title, text, marginTop, thick, onClose }: AlertBannerProps) => {
  let { background, textColor } = AlertBannerStyle.default;
  const { displayIcon } = AlertBannerStyle.default;
  let renderedIcon = displayIcon;

  if (!!style && Object.values(AlertBannerStyles).includes(style)) {
    const styleKey = AlertBannerStyles[style];
    // eslint-disable-next-line prefer-destructuring
    background = AlertBannerStyle[styleKey].background;

    // eslint-disable-next-line prefer-destructuring
    textColor = AlertBannerStyle[styleKey].textColor;
    renderedIcon = AlertBannerStyle[styleKey].displayIcon;
  }

  if (icon) {
    renderedIcon = icon;
  }

  return (
    <AlertBannerContainer background={background} marginTop={marginTop} thick={thick} styles={containerStyles}>
      <AlertBannerTextContainer textColor={textColor} styles={textStyles}>
        {renderedIcon && <AlertBannerIcon data-testid='alert-banner-icon'>{renderedIcon}</AlertBannerIcon>}
        <AlertBannerText>
          {title && <AlertBannerTitle>{title}</AlertBannerTitle>}
          {text}
        </AlertBannerText>
        {onClose && (
          <CloseButton data-testid='onclose-button'>
            <StyledTimesIcon onClick={onClose} />
          </CloseButton>
        )}
      </AlertBannerTextContainer>
    </AlertBannerContainer>
  );
};

const AlertBannerContainer = styled.div<{ background?: string; marginTop?: string; thick?: boolean; styles?: FlattenSimpleInterpolation }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ thick }) => (thick ? '16px' : '8px 12px')};
  gap: 8px;

  background: ${({ background }) => (background ? background : colors.dutchie.backgroundGrey)};
  border-radius: 8px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '24px')};

  ${({ styles }) => styles}
`;

const AlertBannerTextContainer = styled.div<{ textColor?: string; styles?: FlattenSimpleInterpolation }>`
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex: 2;
  color: ${({ textColor }) => (textColor ? textColor : colors.dutchie.almostBlack)};

  ${({ styles }) => styles}
`;

const AlertBannerIcon = styled.div`
  margin-top: 0 !important;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

const AlertBannerText = styled.div``;

const AlertBannerTitle = styled.div`
  font-weight: 600;
  margin-right: 4px;
  display: inline-block;
`;

const CloseButton = styled.div`
  flex: 1;
  text-align: right;
  width: 16px;
  height: 16px;
`;

const StyledTimesIcon = styled(TimesIcon)`
  cursor: pointer;
`;
