import React, { FC, useEffect, useMemo, useState } from 'react';
import { refreshMMURAllotment } from 'api/CartApi';
import * as CheckoutApi from 'api/CheckoutApi';
import { InputField, TextAreaField, DateRangeField, SelectField, SelectOption } from 'components/inputs';
import { Button, LoadingButton } from 'components/buttons';
import { Label } from 'components/text';
import { Popup } from 'components/popups';
import { Loader } from 'components/backoffice/loader';
import { CustomerAddress, getAddressString } from 'models/Customer';
import { Delivery } from 'models/Delivery';
import { callback } from 'models/Misc';
import { colors, popupWidth } from 'css/Theme';
import { format } from 'date-fns';
import { Form, Formik, FormikErrors } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import {
  bypassStateSystem,
  getDeliveryCars,
  getDeliveryDrivers,
  getDeliveryManifest,
  setReadyForDelivery,
  updateDeliveryManifest,
} from 'store/actions/CheckoutActions';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { showBypassStateSystemPopup } from 'store/actions/PopupsActions';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

export type DeliveryOrderProps = Pick<Delivery, 'Guest_id' | 'ShipmentId'>;

type ManifestFormValues = {
  startDate?: Date;
  endDate?: Date;
  driver?: number;
  driver2?: number;
  driver3?: number;
  driver4?: number;
  car?: number;
  address: string;
  directions: string;
  comments: string;
  title: string;
};

type DeliverySettingsProps = {
  onSave?: () => void;
  hide: callback;
  delivery: DeliveryOrderProps;
};

export const DeliverySettingsPopup: FC<DeliverySettingsProps> = ({ onSave, hide, delivery }) => {
  const guest = useSelector((state: State) => state.customer.details);
  const settings = useSelector((state: State) => state.settings);
  const isStatefulCheckoutEnabled = useSelector((state: State) => state.settings.features.StatefulCheckout);
  const hasStatefulCheckoutTotalBeenCalculated = useSelector(
    (state: State) => state.cart.StatefulCheckoutTotalCalculated
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [directionsLoading, setDirectionsLoading] = useState<boolean>(false);
  const [isMarkingReadyForDelivery, setIsMarkingReadyForDelivery] = useState<boolean>(false);
  const [cachedDistanceToDestination, setCachedDistanceToDestination] = useState<number | undefined>(undefined);
  const [cachedDistanceToDestinationAddress, setCachedDistanceToDestinationAddress] = useState<string | undefined>(
    undefined
  );
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const deliveryManifest = useSelector((state: State) => state.checkout.deliveryManifest);
  const deliveryManifestLoading = useSelector((state: State) => state.checkout.deliveryManifestLoading);
  const deliveryDrivers = useSelector((state: State) => state.checkout.drivers);
  const deliveryDriversLoading = useSelector((state: State) => state.checkout.driversLoading);
  const deliveryCars = useSelector((state: State) => state.checkout.cars);
  const deliveryCarsLoading = useSelector((state: State) => state.checkout.carsLoading);
  const updatingDeliveryManifest = false;
  const dispatch = useDispatch();
  const hasDeliveryTitleFormatSet = !!useSelector((state: State) => state.settings.DeliveryTitleFormat);
  const [manifestFetched, setManifestFetched] = useState(false);
  const { previewManifest, printReceipt } = usePrintJob();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const { data: cart, refetch: refetchCartDetails } = useGetCartDetails({
    guestId: delivery.Guest_id,
    registerId: guest?.RegisterId,
    shipmentId: delivery.ShipmentId,
  });

  const addresses = useMemo(() => {
    const mapped: CustomerAddress[] = [];

    if (guest?.address && guest?.address?.street !== '') {
      mapped.push(guest.address);
    }

    guest?.AddressBook.forEach((item) => {
      mapped.push(item);
    });

    return mapped;
  }, [guest]);

  const addressList = useMemo(() => {
    let mapped: SelectOption[] = addresses.map((a) => ({
      label: getAddressString(a),
      value: a.AddressId?.toString() ?? 0,
      key: a.AddressId?.toString() ?? 0,
    }));

    if (mapped.length === 0) {
      mapped = [
        {
          label: 'No addresses found',
        },
      ];
    }

    return mapped;
  }, [addresses]);

  const addressLookup = (addressId: string) => {
    return addresses.find((a) => addressId === (a.AddressId?.toString() ?? '0'));
  };

  /**
   * !! Tech Debt !!
   * The lines below are a mild hack to extract existing delivery information. It feels like
   * we're overloading this component, using it for both checkout and existing delivery, and
   * should refactor (either abstracting the model, or splitting into two modals) to better
   * separate concerns.
   * See also the openSingleDelivery fn in DeliveryPage/index.tsx
   */
  const disableRouteChanges = !!deliveryManifest?.DeliveryRouteId;
  /* END Tech Debt */

  useEffect(() => {
    dispatch(getDeliveryDrivers());
    dispatch(getDeliveryCars());
  }, [dispatch]);

  useEffect(() => {
    // Cleaning up the logic here a bit
    if (isAnonymousCartLDFlagEnabled) {
      // Fetch guest details if necessary
      const shouldFetchGuestDetails = delivery && guest?.Guest_id !== delivery.Guest_id;
      if (shouldFetchGuestDetails) {
        dispatch(getCustomerDetails({ guestId: delivery.Guest_id, noLoading: true }));
      }
      // Fetch manifest if necessary
      if (!manifestFetched) {
        // We can just use shipmentId now since that will be either
        // deliveryToUse.ShipmentId or the search param depending on which page this is
        dispatch(getDeliveryManifest({ TransId: delivery.ShipmentId }));
        setManifestFetched(true);
      }
      return;
    }

    const guestDoesNotMatchDelivery = !guest || guest?.Guest_id !== delivery?.Guest_id;
    // Delivery Page logic
    if (delivery && guestDoesNotMatchDelivery) {
      dispatch(getCustomerDetails({ guestId: delivery.Guest_id, noLoading: true }));
    }
    // Cart Page logic
    if (!delivery && guest) {
      // Not removing this reference to guest.ShipmentId since it's gated by LD flag
      dispatch(getDeliveryManifest({ TransId: guest.ShipmentId }));
      // Delivery Page Logic
    } else if (delivery.ShipmentId && !manifestFetched) {
      dispatch(getDeliveryManifest({ TransId: delivery.ShipmentId }));
      setManifestFetched(true);
    }
  }, [isAnonymousCartLDFlagEnabled, delivery, dispatch, guest, manifestFetched, delivery.ShipmentId]);

  const onSaveManifest = async (values: ManifestFormValues) => {
    if (!addressLookup(values.address)) {
      dispatch(errorNotification('Delivery NOT saved, Please specify an address'));
      return;
    }
    setIsLoading(true);
    const DistanceToDestination =
      cachedDistanceToDestinationAddress === values.address ? cachedDistanceToDestination : undefined;

    if (delivery.ShipmentId && registerId) {
      const drivers = [];
      if (values.driver) {
        drivers.push({ DriverId: values.driver, DriverPosition: drivers.length + 1 });
      }
      if (values.driver2) {
        drivers.push({ DriverId: values.driver2, DriverPosition: drivers.length + 1 });
      }
      if (values.driver3) {
        drivers.push({ DriverId: values.driver3, DriverPosition: drivers.length + 1 });
      }
      if (values.driver4) {
        drivers.push({ DriverId: values.driver4, DriverPosition: drivers.length + 1 });
      }
      await dispatch(
        updateDeliveryManifest({
          Departs: values.startDate ? format(values.startDate, 'MM/dd/yyyy HH:mm aa') : null,
          Arrives: values.endDate ? format(values.endDate, 'MM/dd/yyyy HH:mm aa') : null,
          CarId: values.car,
          Comments: values.comments,
          Directions: values.directions,
          Drivers: drivers,
          AddressId: Number(values.address),
          TransId: delivery.ShipmentId,
          Title: values.title,
          Register: registerId,
          DistanceToDestination: DistanceToDestination,
        })
      );

      await refetchCartDetails();

      setIsLoading(false);
      onSave && onSave();
    }
    hide();
  };

  const onReadyForDelivery = async (
    validateForm: () => Promise<FormikErrors<ManifestFormValues>>,
    values: ManifestFormValues
  ) => {
    if (!addressLookup(values.address)) {
      dispatch(errorNotification('Delivery NOT saved, Please specify an address'));
      return;
    }
    if (guest) {
      setIsMarkingReadyForDelivery(true);
      // this was copied over from DeliverySettings.tsx
      // need to do this until we update to formik > 2.1.6
      // https://github.com/formium/formik/issues/2652
      const resp = await validateForm();
      const canSetReadyForDelivery = values.car && values.driver;
      if (Object.keys(resp).length === 0 && canSetReadyForDelivery) {
        const DistanceToDestination =
          cachedDistanceToDestinationAddress === values.address ? cachedDistanceToDestination : undefined;
        if (guest && registerId) {
          const drivers = [];
          if (values.driver) {
            drivers.push({ DriverId: values.driver, DriverPosition: drivers.length + 1 });
          }
          if (values.driver2) {
            drivers.push({ DriverId: values.driver2, DriverPosition: drivers.length + 1 });
          }
          if (values.driver3) {
            drivers.push({ DriverId: values.driver3, DriverPosition: drivers.length + 1 });
          }
          if (values.driver4) {
            drivers.push({ DriverId: values.driver4, DriverPosition: drivers.length + 1 });
          }
          await dispatch(
            updateDeliveryManifest({
              Departs: values.startDate ? format(values.startDate, 'MM/dd/yyyy HH:mm aa') : null,
              Arrives: values.endDate ? format(values.endDate, 'MM/dd/yyyy HH:mm aa') : null,
              CarId: values.car,
              Comments: values.comments,
              Directions: values.directions,
              Drivers: drivers,
              AddressId: Number(values.address),
              TransId: delivery.ShipmentId,
              Title: values.title,
              Register: registerId,
              DistanceToDestination: DistanceToDestination,
            })
          );
        }

        await dispatch(setReadyForDelivery({ ShipmentId: delivery.ShipmentId }));

        if (settings.integrations?.UseMMUR) {
          await refreshMMURAllotment({
            CustomerId: delivery.Guest_id,
            ShipmentId: delivery.ShipmentId,
          });
        }

        await refetchCartDetails();

        hide();
        setIsLoading(false);
      } else {
        dispatch(errorNotification('Delivery NOT saved, Please specify car/driver/title'));
      }
      setIsMarkingReadyForDelivery(false);
    }
  };

  const onPrintManifest = async () => {
    if (delivery.ShipmentId) {
      setIsLoading(true);
      previewManifest({ PosId: delivery.ShipmentId });
      setIsLoading(false);
    }
  };

  const onPrintReceipt = () => {
    if (isStatefulCheckoutEnabled && !hasStatefulCheckoutTotalBeenCalculated) {
      return dispatch(errorNotification('Please calculate totals prior to printing receipt'));
    }
    if (delivery.ShipmentId) {
      printReceipt({
        ReceiptType: 'Receipt',
        ReceiptParameters: delivery.ShipmentId,
        ForDelivery: true,
        PrinterId: settings.userSettings.selectedReceiptPrinter?.PrinterId || 0,
        subtotal: cart.SubTotal,
        total: cart.GrandTotalRounded,
        localPrinter: settings.userSettings.selectedReceiptPrinter?.LocalPrinter || false,
        popCashDrawer: false,
      });
    }
  };

  const handlePrintSelect = (value: string) => {
    if (value === 'manifest') {
      onPrintManifest();
    } else {
      onPrintReceipt();
    }
  };

  const customerFormSchema = () => {
    if (hasDeliveryTitleFormatSet) {
      return Yup.object().shape({});
    } else {
      return Yup.object().shape({
        title: Yup.string().required(),
      });
    }
  };

  const isDoneLoadingAndReady = React.useMemo(
    () => isMarkingReadyForDelivery || deliveryManifestLoading || isLoading,
    [isMarkingReadyForDelivery, deliveryManifestLoading, isLoading]
  );

  const { isModalBridgeEnabled } = useModalBridge();

  const PopupToUse = isModalBridgeEnabled ? Popup : DeliverySettingsPopupContainer;

  return (
    <PopupToUse
      medium={isModalBridgeEnabled}
      caption='Delivery Options'
      isVisible
      hide={hide}
      contentMaxHeight={isModalBridgeEnabled ? '100%' : undefined}
    >
      {isDoneLoadingAndReady ? (
        <LoadingWrapper>
          <Loader size='2x' variant='black' />
        </LoadingWrapper>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            startDate: deliveryManifest?.DepartureDate ? new Date(deliveryManifest.DepartureDate) : undefined,
            endDate: deliveryManifest?.ArrivalDate ? new Date(deliveryManifest.ArrivalDate) : undefined,
            driver: deliveryManifest?.DriverId,
            driver2: deliveryManifest?.SecondaryDriverId,
            driver3: deliveryManifest?.ThirdDriverId,
            driver4: deliveryManifest?.FourthDriverId,
            car: deliveryManifest?.CarId,
            directions:
              deliveryManifest?.RouteDetails ||
              (settings.features.UsePlaceholderDirections
                ? settings.locationSettings?.PlaceholderDirections || ''
                : ''),
            comments: deliveryManifest?.Comments || '',
            address: deliveryManifest?.DeliveryAddressId?.toString() ?? addressList[0].key?.toString() ?? '0',
            title: deliveryManifest?.Title || '',
          }}
          onSubmit={onSaveManifest}
          validationSchema={customerFormSchema}
          validateOnMount
        >
          {(props) => {
            const { isValid, setFieldValue, values, validateForm } = props;

            const drivers = deliveryDrivers.map((driver) => ({
              label: driver.Name,
              value: String(driver.Id),
            }));

            return (
              <Form>
                <DeliverySettingsPopupInterior>
                  <InputField
                    automationId='delivery-settings-title'
                    label='Title'
                    name='title'
                    disabled={hasDeliveryTitleFormatSet}
                    placeholder={hasDeliveryTitleFormatSet ? 'Auto-Generated' : ''}
                  />
                  <DateRangeField startName='startDate' endName='endDate' label='Departure and arrival time*' />
                  <SelectField name='address' label='Address' options={addressList} />
                  {settings.integrations?.UseMETRC && (
                    <MetricContainer>
                      <Label>METRC Delivery ID: {deliveryManifest?.ExternalDeliveryId}</Label>
                      <FullWidthButton
                        secondary
                        disabled={!(deliveryManifest && deliveryManifest.METRCStateCode)}
                        onClick={() => {
                          if (deliveryManifest) {
                            window.open(
                              `https://${deliveryManifest.METRCStateCode}.metrc.com/reports/sales/deliveries/manifest?id=${deliveryManifest.ExternalDeliveryId}`
                            );
                          }
                        }}
                      >
                        Open METRC Manifest
                      </FullWidthButton>
                    </MetricContainer>
                  )}
                  {!settings.features.UsePlaceholderDirections && (
                    <DeliveryButton
                      secondary
                      loading={directionsLoading}
                      disabled={updatingDeliveryManifest}
                      onClick={async () => {
                        if (delivery.ShipmentId) {
                          const selectedAddress = addressLookup(values.address);
                          if (selectedAddress) {
                            setDirectionsLoading(true);
                            try {
                              const directions = await CheckoutApi.getDirections({
                                ShipmentId: delivery.ShipmentId,
                                DestinationAddress1: selectedAddress?.street,
                                DestinationAddress2: selectedAddress?.street2,
                                DestinationCity: selectedAddress?.city,
                                DestinationState: selectedAddress?.state,
                                DestinationPostalCode: selectedAddress?.postal_code,
                              });
                              setFieldValue('directions', directions.Directions);
                              setCachedDistanceToDestination(directions.Distance);
                              setCachedDistanceToDestinationAddress(values.address);
                              setDirectionsLoading(false);
                            } catch (e) {
                              dispatch(errorNotification(`Couldn't fetch directions: ${e}`));
                              setDirectionsLoading(false);
                            }
                          }
                        }
                      }}
                    >
                      Get Directions
                    </DeliveryButton>
                  )}
                  <TextAreaField name='directions' label='Directions' rows={5} />
                  <DriverCarContainer>
                    <SelectField
                      name='driver'
                      label='Driver'
                      placeholder='Select Driver'
                      options={drivers.filter(
                        (x) =>
                          String(x.value) !== String(values.driver2) &&
                          String(x.value) !== String(values.driver3) &&
                          String(x.value) !== String(values.driver4)
                      )}
                      disabled={deliveryDriversLoading || disableRouteChanges}
                      automationId='delivery-delivery-options-select-driver'
                    />
                    <SelectField
                      name='driver2'
                      label='Secondary Driver'
                      placeholder='Select Driver'
                      options={drivers.filter(
                        (x) =>
                          String(x.value) !== String(values.driver) &&
                          String(x.value) !== String(values.driver3) &&
                          String(x.value) !== String(values.driver4)
                      )}
                      disabled={deliveryDriversLoading || disableRouteChanges}
                    />
                    {settings.features.EnableFourDriversManifest && (
                      <>
                        <SelectField
                          name='driver3'
                          label='Third Driver'
                          placeholder='Select Driver'
                          options={drivers.filter(
                            (x) =>
                              String(x.value) !== String(values.driver) &&
                              String(x.value) !== String(values.driver2) &&
                              String(x.value) !== String(values.driver4)
                          )}
                          disabled={deliveryDriversLoading || disableRouteChanges}
                        />
                        <SelectField
                          name='driver4'
                          label='Fourth Driver'
                          placeholder='Select Driver'
                          options={drivers.filter(
                            (x) =>
                              String(x.value) !== String(values.driver) &&
                              String(x.value) !== String(values.driver2) &&
                              String(x.value) !== String(values.driver3)
                          )}
                          disabled={deliveryDriversLoading || disableRouteChanges}
                        />
                      </>
                    )}
                    <SelectField
                      name='car'
                      label='Car'
                      placeholder='Select Car'
                      options={deliveryCars.map((car) => ({
                        label: `${car.CarModel} - ${car.CarLicId}`,
                        value: String(car.id),
                      }))}
                      disabled={deliveryCarsLoading || disableRouteChanges}
                      automationId='delivery-delivery-options-select-car'
                    />
                  </DriverCarContainer>
                  <TextAreaField
                    name='comments'
                    label='Comments'
                    rows={5}
                    placeholder='Notes entered will be transferred to the manifest'
                  />
                </DeliverySettingsPopupInterior>
                <BottomButtonContainer isModalBridgeEnabled={isModalBridgeEnabled}>
                  <CancelButton onClick={hide}>Cancel</CancelButton>
                  <BottomButton
                    secondary
                    type='button'
                    disabled={updatingDeliveryManifest}
                    onClick={() => {
                      dispatch(
                        showBypassStateSystemPopup({
                          onSuccess: (pin: string) => {
                            delivery.ShipmentId &&
                              dispatch(
                                bypassStateSystem({
                                  ManagerPIN: pin,
                                  ShipmentId: delivery.ShipmentId,
                                })
                              );
                          },
                          title: 'Bypass',
                        })
                      );
                    }}
                  >
                    Bypass State System
                  </BottomButton>
                  <BottomButton
                    secondary
                    type='button'
                    disabled={updatingDeliveryManifest || !isValid}
                    onClick={() => onReadyForDelivery(validateForm, props.values)}
                  >
                    Ready For Delivery
                  </BottomButton>
                  <PrintButtonsSelect
                    name='print'
                    placeholder='Print'
                    options={[
                      { value: 'manifest', label: 'Manifest' },
                      { value: 'receipt', label: 'Receipt' },
                    ]}
                    disabled={deliveryDriversLoading || disableRouteChanges}
                    onChange={(e) => handlePrintSelect(e)}
                  />
                  <Button type='submit' disabled={updatingDeliveryManifest || !isValid}>
                    Save
                  </Button>
                </BottomButtonContainer>
              </Form>
            );
          }}
        </Formik>
      )}
    </PopupToUse>
  );
};

const DeliverySettingsPopupContainer = styled(Popup)`
  width: ${popupWidth.medium};
  max-height: 90vh;
  overflow-y: auto;
`;

const DeliverySettingsPopupInterior = styled.div`
  padding: 0 1.5rem;
`;

const MetricContainer = styled.div`
  margin-bottom: 1rem;
`;

const FullWidthButton = styled(LoadingButton)`
  width: 100%;
`;

const DeliveryButton = styled(FullWidthButton)`
  margin: 0 0 1rem;
`;

const DriverCarContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
`;

const BottomButtonContainer = styled.div<{ isModalBridgeEnabled?: boolean }>`
  border-top: 1px solid ${colors.dutchie.shadowGrey};
  padding: 1rem 1.5rem;
  display: flex;

  ${({ isModalBridgeEnabled }) =>
    isModalBridgeEnabled &&
    css`
      position: sticky;
      bottom: 0;
      background: ${colors.dutchie.primaryWhite};
      z-index: 1;
    `}
`;

const CancelButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  font-size: 1rem;
  margin-right: auto;
`;

const BottomButton = styled(Button)`
  margin: 0 0.5rem 0 0;
  background: ${colors.dutchie.backgroundGrey};
  color: ${colors.dutchie.gunmetal};
  border: none;
`;

const PrintButtonsSelect = styled(SelectField)`
  margin: 0 0.5rem 0 0;
  & select {
    height: 44px;
    background: ${colors.dutchie.backgroundGrey};
    color: ${colors.dutchie.gunmetal};
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  & svg {
    width: 10px;
    color: ${colors.dutchie.gunmetal};
    right: 0.75rem;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem;
`;
