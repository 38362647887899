import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ConfirmationPopup } from 'components/popups';
import { CustomerDetails } from 'models/Customer';
import { convertGuestToNonAnonymous, getCustomerDetails } from 'store/actions/CustomerActions';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useConvertToNonAnonymousMutation } from 'queries/v2/guest/convert-to-non-anonymous';

const Container = styled.div`
  padding: 0 1.5rem;
  text-align: center;
`;

type ConvertToNonAnomyousPopupProps = {
  hide: () => void;
  customer: CustomerDetails;
};

export const ConvertToNonAnomyousPopup: FC<ConvertToNonAnomyousPopupProps> = ({ customer, hide }) => {
  const dispatch = useDispatch();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { mutateAsync: convertGuest, isLoading: isConvertingGuest } = useConvertToNonAnonymousMutation();

  const convert = async () => {
    if (isAnonymousCartLDFlagEnabled) {
      // Convert the guest to non-anonymous
      await convertGuest({ guestId: customer.Guest_id });
      // Reload the customer details
      dispatch(getCustomerDetails({ guestId: customer.Guest_id }));
    } else {
      dispatch(convertGuestToNonAnonymous({ Guest_id: customer.Guest_id }));
    }

    hide();
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Convert Guest'
      confirm={{
        text: 'Confirm',
        loading: isConvertingGuest,
        onClick: convert,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <Container>
        <p>Are you sure you want to convert this guest to non-anonymous?</p>
      </Container>
    </ConfirmationPopup>
  );
};
