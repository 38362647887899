import styled, { css } from 'styled-components';
import { colors } from 'css/Theme';

import { ReactComponent as LoyaltyIcon } from 'assets/icons/loyalty-icon.svg';
import { ReactComponent as DiscountsIcon } from 'assets/icons/discounts-red.svg';
import { ReactComponent as Close } from 'assets/icons/icon-close.svg';

const BaseDiscountsAndLoyaltyFont = css`
  color: ${colors.dutchie.gray90};
  font-size: 0.815rem;
  line-height: 1.125rem;
`;

const BoldFont = css`
  ${BaseDiscountsAndLoyaltyFont}
  font-weight: 600;
`;

export const DiscountContainer = styled.div`
  ${BaseDiscountsAndLoyaltyFont}
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: 0.25rem;
`;

export const DiscountRedIcon = styled(DiscountsIcon)`
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
  margin-right: 0.25rem;
  & path {
    fill: ${colors.dutchie.red};
  }
`;

export const LoyaltyBlueIcon = styled(LoyaltyIcon)`
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
  margin-right: 0.25rem;
  & path {
    fill: ${colors.dutchie.blue};
  }
`;

export const DiscountLabel = styled.div`
  ${BoldFont}
  color: ${colors.dutchie.red};
  white-space: nowrap;
`;

export const LoyaltyLabel = styled.div`
  ${BoldFont}
  color: ${colors.dutchie.blue};
  white-space: nowrap;
`;

export const Description = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DiscountAmount = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  white-space: nowrap;
`;

export const ShowLoyaltyButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteIcon = styled(Close)`
  color: ${colors.dutchie.grey30};
  width: 1.25rem;
  flex: 0 0 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
  cursor: pointer;
`;
