import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

const CONVERT_TO_NON_ANONYMOUS = 'v2/guest/ConvertToNonAnonymous';

type ConvertToNonAnonymousPayload = {
  guestId: number;
};

const transformToServerPayload = (payload: ConvertToNonAnonymousPayload) => {
  return { Guest_id: payload.guestId };
};

export const useConvertToNonAnonymousMutation = () => {
  return useMutation(async (payload: ConvertToNonAnonymousPayload) => {
    await post<void>(CONVERT_TO_NON_ANONYMOUS, transformToServerPayload(payload));
    return;
  });
};
