import { Network } from '@capacitor/network';
import { Overlay } from '@dutchie/capacitor-overlay';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as PosApi from 'api/PosApi';
import { Location, Lsp, LspLocation } from 'models/Location';
import { LogoutRequest, UserInfoResponse } from 'models/Misc';
import { useHistory } from 'react-router';
import { State, AppDispatch } from 'store';
import {
  ForgotRequest,
  ResetRequest,
  clearSessionCookies,
  getPasswordSettingsRequest,
  logInRequest,
  logInRequestAuto,
  loginRequestFromIdProvider,
  logoutRequest,
  ssoLoginEnabledRequest,
  ssoLoginRequest,
} from 'util/Helpers';
import { isAndroid, isWebViewApp } from 'util/hooks';
import { LoggingProviders, customEventKeys, logger } from 'util/logger';
import { PersistedValueKey, getPersistedValue } from 'util/persisted-values';
import { clearGuestFilters, loadCardStatusDisplayOptions } from '../actions/GuestListActions';
import { errorNotification, successNotification, warningNotification } from '../actions/NotificationsActions';
import {
  loadCancellationReasons,
  loadCustomerTypes,
  loadDefaultStatuses,
  loadHelpDeskLink,
  loadLabelPrinters,
  loadLabels,
  loadLocationSettings,
  loadPermissions,
  loadPreorderConfig,
  loadReceiptPrinters,
  loadRegisters,
  loadUserSettings,
  loadVoidReasons,
  selectRegister,
  setExternalSitesLoaded,
  setServerMigrationConfig,
} from '../actions/SettingsActions';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { getIsLoginImprovementsFlagEnabled } from 'util/hooks/launch-darkly/useLoginImprovementsFlag';
import { getHelpDeskLinkQuery } from 'queries/posv3/lsplocation/get-helpdesk-link';
import { stopPolling } from './CheckoutActions';
import { setWebviewDatadogUser } from 'util/hooks/webviewApp';

export type PasswordSettings = {
  passwordLengthEnabled: false;
  passwordLength: number;
};

export type SSOLoginEnabled = {
  isSSOLoginEnabled: boolean;
};

export const login = createAction('login', (payload: UserInfoResponse) => ({ payload }));

export const loadUserData = createAsyncThunk(
  'loadUserData',
  async (args: { userInfo: UserInfoResponse }, { dispatch }) => {
    const lsps = await PosApi.getLsps();
    const { isLoginImprovementsFlagEnabled } = getIsLoginImprovementsFlagEnabled();
    args.userInfo.lsps = lsps;
    if (isLoginImprovementsFlagEnabled) {
      await Promise.allSettled([
        dispatch(login(args.userInfo)),
        dispatch(loadLocationSettings(true)),
        getHelpDeskLinkQuery(dispatch as AppDispatch),
        dispatch(loadDefaultStatuses()),
        dispatch(loadCancellationReasons()),
        dispatch(loadVoidReasons()),
        dispatch(loadCardStatusDisplayOptions()),
        dispatch(loadCustomerTypes()),
        dispatch(loadPermissions()),
        dispatch(loadUserSettings()),
        dispatch(loadPreorderConfig()),
      ]);
    } else {
      await Promise.allSettled([
        dispatch(login(args.userInfo)),
        dispatch(loadLocationSettings(true)),
        dispatch(loadHelpDeskLink()),
        dispatch(loadDefaultStatuses()),
        dispatch(loadCancellationReasons()),
        dispatch(loadVoidReasons()),
        dispatch(loadCardStatusDisplayOptions()),
        dispatch(loadCustomerTypes()),
        dispatch(loadPermissions()),
        dispatch(loadUserSettings()),
        dispatch(loadPreorderConfig()),
      ]);
    }
    logger.setUser(LoggingProviders.DATADOG, args.userInfo.UserId.toString(), {
      name: args.userInfo.UserName ?? window.sessionStorage.getItem('UserName'),
      lsp_id: args.userInfo.LspId ?? window.sessionStorage.getItem('LspId'),
      loc_id: args.userInfo.LocId ?? window.sessionStorage.getItem('LocId'),
    });

    if (isWebViewApp) {
      setWebviewDatadogUser({
        id: args.userInfo.UserId.toString(),
        name: args.userInfo.UserName ?? window.sessionStorage.getItem('UserName'),
        lsp_id: args.userInfo.LspId?.toString() ?? window.sessionStorage.getItem('LspId') ?? undefined,
        loc_id: args.userInfo.LocId?.toString() ?? window.sessionStorage.getItem('LocId') ?? undefined,
      });
    }

    logger.debug('code set user info', {
      key: customEventKeys.settingUserInfo,
      sessionStorage: {
        id: window.sessionStorage.getItem('UserId'),
        name: window.sessionStorage.getItem('UserName'),
        lspId: window.sessionStorage.getItem('LspId'),
        locId: window.sessionStorage.getItem('LocId'),
      },
      loadedData: {
        id: args.userInfo.UserId.toString(),
        name: args.userInfo.UserName,
        lspId: args.userInfo.LspId,
        locId: args.userInfo.LocId,
      },
    });
  }
);

export const handleAutoLogin = createAsyncThunk(
  'handleAutoLogin',
  async (
    args: { sessionId: string; locId: number; registerId?: number; hardwareId?: string },
    { dispatch, getState }
  ) => {
    await logInRequestAuto(args.sessionId, args.locId).then(async (payload: UserInfoResponse) => {
      const hardwareId = args.hardwareId ?? (await getPersistedValue(PersistedValueKey.hardwareId));

      await Promise.allSettled([
        dispatch(loadUserData({ userInfo: payload })),
        dispatch(loadLabels()),
        dispatch(loadLabelPrinters()),
        dispatch(loadReceiptPrinters()),
        dispatch(loadRegisters(hardwareId)),
      ]);

      const { settings } = getState() as State;

      if (settings.registers && args.registerId) {
        const selectedRegister = settings.registers.find((item) => item.id === args.registerId);
        if (selectedRegister) {
          dispatch(
            selectRegister({
              value: selectedRegister.id,
              label: selectedRegister.TerminalName,
            })
          );
        }
      } else if (args.registerId) {
        await dispatch(selectRegister({ value: args.registerId, label: '' })); //This call only really uses the 'value' field.  We should clean this up
      }

      return payload.UserId;
    });
  }
);

export const handleSSOLogin = createAsyncThunk('handleSSOLogin', (args, { dispatch }) => {
  loginRequestFromIdProvider().then((payload: UserInfoResponse) => {
    dispatch(loadUserData({ userInfo: payload })).then(() => {
      if (window.location.search.toLowerCase().indexOf('samlsessionid') > 0) {
        const [, samlSessionId] = window.location.search.toLowerCase().split('samlsessionid=');
        window.location.replace(`/pinlogin?SAMLSessionId=${samlSessionId}`);
      }
    });
  });
});

export const handlePinLogin = createAsyncThunk('handlePinLogin', async (args, { dispatch }) => {
  loginRequestFromIdProvider().then((payload: UserInfoResponse) => {
    dispatch(loadUserData({ userInfo: payload })).then(() => window.location.replace('/'));
  });
});

export const handleLogin = createAsyncThunk(
  'handleLogin',
  (args: { username: string; password: string; ldFlags?: LDFlagSet }, { dispatch }) => {
    logInRequest(args.username, args.password, args.ldFlags)
      .then((payload: UserInfoResponse) => {
        dispatch(loadUserData({ userInfo: payload }));
      })
      .catch(async (data) => {
        const connectionStataus = await Network.getStatus();
        if (!connectionStataus?.connected) {
          dispatch(errorNotification('No internet connection found.'));
          return;
        }

        if (data !== null && data !== undefined) {
          if (data.shouldShowBlockingUI) {
            dispatch(setServerMigrationConfig(data));
            return;
          }

          if (data?.Url) {
            dispatch(warningNotification('Your password has expired and must be changed'));
            setTimeout(() => (window.location = data.Url), 2000);
            return;
          } else if (
            (!data?.LoginFailureCount && !data?.MaxLoginFailureCount && typeof data !== 'string') ||
            data?.LoginFailureCount < data?.MaxLoginFailureCount
          ) {
            dispatch(errorNotification('Your username or password is wrong'));
            return;
          } else if (data?.MaxLoginFailureCount && data?.MaxRetryMinutes && data?.RetryMinutes) {
            dispatch(
              errorNotification(
                `Your account has been blocked after ${data.MaxLoginFailureCount} login failures. Please wait ${
                  data.MaxRetryMinutes - data.RetryMinutes
                } minute${data.MaxRetryMinutes - data.RetryMinutes > 1 ? 's' : ''} and try again.`
              )
            );
            return;
          }
          dispatch(errorNotification(data));
          return;
        } else {
          dispatch(errorNotification('Something went wrong, please contact support for assistance'));
          return;
        }
      });
  }
);

export const handleLoginV2 = createAsyncThunk(
  'handleLoginV2',
  async (args: { username: string; password: string; ldFlags?: LDFlagSet }, { dispatch }) => {
    try {
      const payload: UserInfoResponse = await logInRequest(args.username, args.password, args.ldFlags);
      dispatch(loadUserData({ userInfo: payload }));
    } catch (data: any) {
      const connectionStataus = await Network.getStatus();
      if (!connectionStataus?.connected) {
        dispatch(errorNotification('No internet connection found.'));
        throw data;
      }

      if (data !== null && data !== undefined) {
        if (data.shouldShowBlockingUI) {
          dispatch(setServerMigrationConfig(data));
          return;
        }

        if (data?.Url) {
          dispatch(warningNotification('Your password has expired and must be changed'));
          setTimeout(() => (window.location = data.Url), 2000);
          throw data;
        } else if (
          (!data?.LoginFailureCount && !data?.MaxLoginFailureCount && typeof data !== 'string') ||
          data?.LoginFailureCount < data?.MaxLoginFailureCount
        ) {
          dispatch(errorNotification('Your username or password is wrong'));
          throw data;
        } else if (data?.MaxLoginFailureCount >= 0 && data?.MaxRetryMinutes >= 0 && data?.RetryMinutes >= 0) {
          dispatch(
            errorNotification(
              `Your account has been blocked after ${data.MaxLoginFailureCount} login failures. Please wait ${
                data.MaxRetryMinutes - data.RetryMinutes
              } minute${data.MaxRetryMinutes - data.RetryMinutes > 1 ? 's' : ''} and try again.`
            )
          );
          throw data;
        }
        dispatch(errorNotification(data));
        throw data;
      } else {
        dispatch(errorNotification('Something went wrong, please contact support for assistance'));
        throw data;
      }
    }
  }
);

export const loginSamlEnabled = createAction('loginSamlEnabled', (payload: SSOLoginEnabled) => ({ payload }));

export const logout = createAction('logout');

export const handleLogout = createAsyncThunk(
  'handleLogout',
  async (args: { history: ReturnType<typeof useHistory>; logoutRequestOrigin: string }, { dispatch }) => {
    const logoutRequestPayload: LogoutRequest = {
      Origin: args.logoutRequestOrigin ?? 'UserInitiatedLogout',
    };
    clearSessionCookies();
    dispatch(clearGuestFilters());
    if (isAndroid) {
      dispatch(setExternalSitesLoaded(false));
      Overlay.clearCookies();
      Overlay.hide();
    }
    dispatch(logout());
    logoutRequest(logoutRequestPayload);
    dispatch(stopPolling());
    if (args.history) {
      args.history.push('/');
    }
  }
);

export const getPasswordSettingsSuccess = createAction('getPasswordSettingsSuccess', (payload: PasswordSettings) => ({
  payload,
}));

export const getCurrentServer = createAsyncThunk('getCurrentServer', async () => {
  const result: string = await PosApi.getCurrentServer();
  if (result) {
    window.location.href = result;
  }
});

export const SelectLocationAction = createAsyncThunk('selectLocation', async (payload: Location | undefined) => {
  if (payload) {
    window.sessionStorage.setItem('LocId', `${payload.location_id}`);
  }
  await PosApi.updateDefaultLsp(payload?.LspId || 0);
  await PosApi.updateDefaultLocation(payload?.location_id || 0);

  return payload;
});

export const SelectLsp = createAsyncThunk('SelectLsp', async (lsp: Lsp) => {
  window.sessionStorage.setItem('LspId', `${lsp.LspId}`);
  window.sessionStorage.setItem('LocId', '');
  const locs = (await PosApi.getLspLocations()).map((loc: LspLocation) => {
    return {
      BioTrackingIntegration: loc.IsBiotrackIntegrated,
      CompanyFlag: loc.MDCompanyFlag,
      LspId: lsp.LspId,
      location_id: loc.LocId,
      location_name: loc.Name,
      registersAvailable: loc.RegistersAvailable,
      IsSandbox: loc.IsSandbox,
    };
  });
  return { lsp, locs };
});

export const forgotPassword = createAsyncThunk('forgotPassword', async (username: string, { dispatch }) => {
  if (username.length === 0) {
    dispatch(errorNotification('Username must not be empty'));
    return;
  }
  ForgotRequest(username).finally(() => {
    dispatch(successNotification('An email has been sent to this user if it exists in our system'));
  });
});

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (params: { password2: string; token: string }, { dispatch }) => {
    if (params.password2.length === 0) {
      dispatch(errorNotification('Password must not be empty'));
      return;
    }
    ResetRequest(params.password2, params.token)
      .then(() => {
        window.location.href = '/login';
      })
      .catch((error) => {
        dispatch(errorNotification(error));
      });
  }
);

export const getPasswordSettings = createAsyncThunk('getPasswordSettings', async (resetToken: string, { dispatch }) => {
  getPasswordSettingsRequest(resetToken).then((rep) => {
    const result = rep.data;
    if (result.Result) {
      const passwordSettings: PasswordSettings = {
        passwordLengthEnabled: result.Data.PasswordLengthEnabled,
        passwordLength: result.Data.PasswordLengthEnabled ? result.Data.PasswordLength : 8,
      };
      dispatch(getPasswordSettingsSuccess(passwordSettings));
    }
  });
});

export const ssoLogin = createAsyncThunk(
  'ssoLogin',
  async (params: { username: string; isResetPinLogin?: boolean }, { dispatch }) => {
    ssoLoginRequest(params.username, params.isResetPinLogin).catch((error) => {
      if (error?.data?.shouldShowBlockingUI) {
        dispatch(setServerMigrationConfig(error.data));
        return;
      }
      dispatch(errorNotification(error?.message));
    });
  }
);

export const ssoLoginV2 = createAsyncThunk(
  'ssoLoginV2',
  async (params: { username: string; isResetPinLogin?: boolean }, { dispatch }) => {
    await ssoLoginRequest(params.username, params.isResetPinLogin).catch((error) => {
      if (error?.data?.shouldShowBlockingUI) {
        dispatch(setServerMigrationConfig(error.data));
        return;
      }
      dispatch(errorNotification(error));
    });
  }
);

export const ssoLoginEntity = createAsyncThunk('ssoLoginEntity', async (params: { entity: string }, { dispatch }) => {
  ssoLoginRequest(params.entity).catch((error) => {
    if (error?.data?.shouldShowBlockingUI) {
      dispatch(setServerMigrationConfig(error.data));
      return;
    }
    dispatch(errorNotification(error));
  });
});

export const ssoLoginEnabled = createAsyncThunk('ssoLoginEntity', async (args, { dispatch }) => {
  ssoLoginEnabledRequest().then((resp) => {
    const result = resp.data;
    dispatch(loginSamlEnabled({ isSSOLoginEnabled: result.isSSOLoginEnabled }));
  });
});
