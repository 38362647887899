import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { useSelector } from 'react-redux';
import { State } from 'store';

const GET_LSP_LOCATIONS = 'posv3/maintenance/GetLspLocations';

type GetLspLocationsResponse = {
  City: string;
  HasPermission: boolean;
  IsBioTrackIntegrated: boolean;
  IsLive: boolean;
  IsSandbox: boolean;
  LocId: number;
  MDCompanyFlag: string;
  Name: string;
  RegistersAvailable: number;
  State: string;
};

export const useGetLspLocations = ({ enabled }: { enabled: boolean }) => {
  const selectedLsp = useSelector((state: State) => state.user.selectedLsp);

  return useQuery({
    queryKey: ['get-lsp-locations', selectedLsp?.LspId],
    queryFn: () => post<GetLspLocationsResponse[]>(GET_LSP_LOCATIONS),
    enabled,
  });
};
