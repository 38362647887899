/* eslint-disable no-throw-literal */
import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';
import { format } from 'date-fns';
import { colors } from 'css/Theme';
import { Button } from 'components/buttons';
import { MultipleDeliverySettings } from 'models/Delivery';
import { getDeliveryDrivers, getDeliveryCars } from 'store/actions/CheckoutActions';
import { State } from 'store';
import { TextAreaField, DateRangeField, SelectField } from 'components/inputs';
import { Popup } from 'components/popups';
import { CustomerAddress } from 'models/Customer';
import { Loader } from 'components/backoffice/loader';
import {
  saveDeliveries,
  setMultipleReadyForDelivery,
  printMultipleManifests,
  bypassMultipleStateSystem,
  getDeliveries,
  selectRoute,
  printMergedManifests,
  getOptimizedRouteDirections,
  getRouteDirections,
} from 'store/actions/DeliveryActions';
import { errorNotification } from 'store/actions/NotificationsActions';
import { showBypassStateSystemPopup } from 'store/actions/PopupsActions';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { useRoomsQuery } from 'queries/v2/delivery/get-rooms';
import { useSubroomsQuery } from 'queries/v2/delivery/get-subrooms';
import { useInventoryKitsLD } from 'util/hooks/launch-darkly/useInventoryKits';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type ManifestFormValues = {
  startDate: Date;
  endDate: Date;
  driver?: string;
  driver2?: string;
  car?: string;
  address: number;
  directions?: string;
  comments: string;
  title: string;
  roomId?: number;
  subRoomId?: number;
};

type ConfigureDeliveriesProps = {
  hide: () => void;
  createRoute: boolean;
};

export const ConfigureDeliveries: FC<ConfigureDeliveriesProps> = ({ hide, createRoute }) => {
  const dispatch = useDispatch();
  const guest = useSelector((state: State) => state.customer.details);
  const settings = useSelector((state: State) => state.settings);
  const addresses: Array<CustomerAddress> = [];
  if (guest?.address) {
    addresses.push(guest?.address);
  }
  guest?.AddressBook.forEach((item) => {
    addresses.push(item);
  });
  const [roomId, setSelectedRoomId] = useState(0);
  const [creatingRoute, setCreatingRoute] = useState(createRoute);
  const selectedDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);
  const selectedRoute = useSelector((state: State) => state.deliveryList.selectedRoute);
  const deliveryDrivers = useSelector((state: State) => state.checkout.drivers);
  const deliveryDriversLoading = useSelector((state: State) => state.checkout.driversLoading);
  const deliveryCars = useSelector((state: State) => state.checkout.cars);
  const deliveryCarsLoading = useSelector((state: State) => state.checkout.carsLoading);
  const [loading, setLoading] = useState(false);
  const [isMarkingReadyForDelivery, setIsMarkingReadyForDelivery] = useState(false);
  const [isDepartureOrArrivalModified, setIsDepartureOrArrivalModified] = useState(false);
  const [initalValues, setInitialValues] = useState<MultipleDeliverySettings>();
  const updatingDeliveryManifest = false;
  const { printDeliveryReceipts } = usePrintJob();
  const useKits = useInventoryKitsLD();
  const { data: rooms } = useRoomsQuery({ isEnabled: useKits });
  const { data: subrooms } = useSubroomsQuery({ FilterByRoom: Number(roomId) !== 0, RoomId: roomId }, useKits);
  const useLocationDelivery = settings.features.LocationBasedDelivery;

  const todayMidnight = new Date();
  todayMidnight.setHours(0, 0, 0, 0);

  useEffect(() => {
    dispatch(getDeliveryDrivers());
    dispatch(getDeliveryCars());
  }, [dispatch]);

  useEffect(() => {
    const drivers = [...new Set(selectedDeliveries.map((d) => d.DriverId))];
    const secondaryDrivers = [...new Set(selectedDeliveries.map((d) => d.SecondaryDriverId))];
    const cars = [...new Set(selectedDeliveries.map((d) => d.CarId))];
    const directions = [...new Set(selectedDeliveries.map((d) => d.Directions))];
    const departureDate = selectedRoute?.StartDate === undefined ? todayMidnight : new Date(selectedRoute?.StartDate);
    const arrivalDate = selectedRoute?.EndDate === undefined ? todayMidnight : new Date(selectedRoute?.EndDate);
    setSelectedRoomId(selectedRoute?.RoomId || 0);
    setInitialValues({
      driverPlaceholder: drivers.length > 1 ? 'Multiple Drivers Selected' : 'Select Driver',
      driver: selectedRoute?.Driver1Id || (drivers.length === 1 ? drivers[0] : undefined),
      secondaryDriverPlaceholder: secondaryDrivers.length > 1 ? 'Multiple Drivers Selected' : 'Select Driver',
      driver2: selectedRoute?.Driver2Id || (secondaryDrivers.length === 1 ? secondaryDrivers[0] : undefined),
      carPlaceholder: cars.length > 1 ? 'Multiple Cars Selected' : 'Select Car',
      car: selectedRoute?.CarId || (cars.length === 1 ? cars[0] : undefined),
      startDate: departureDate,
      endDate: arrivalDate,
      roomId: selectedRoute?.RoomId,
      subRoomId: selectedRoute?.SubRoomId,
      directions: selectedRoute?.Directions || (directions.length === 1 ? directions[0] : ''),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveries, selectedRoute]);

  const onSaveManifest = async (values: ManifestFormValues) => {
    if (!deliveryFormIsValid(values)) {
      return;
    }

    setLoading(true);
    const id = await save(values);
    if (id) {
      await reloadData(id);
    }
    setLoading(false);
    dispatch(selectRoute(undefined));
    hide();
  };

  const reloadData = async (id: number) => {
    await dispatch(getDeliveries());
    await dispatch(selectRoute(id));
  };

  const deliveryFormIsValid = (values: ManifestFormValues, validateReadyForDelivery?: boolean) => {
    try {
      if (validateReadyForDelivery && (!values.driver || !values.car)) {
        throw 'Route NOT saved, Please specify a Driver and a Car';
      }
      if (!isDepartureOrArrivalModified && creatingRoute) {
        throw 'Departure or Arrival Time must be modified before saving.';
      }
      return true;
    } catch (e) {
      dispatch(errorNotification(e as string));
      return false;
    }
  };

  const save = async (values: ManifestFormValues) => {
    try {
      const saveResult = await dispatch(
        saveDeliveries({
          DeliveryRouteId: selectedRoute?.DeliveryRouteId,
          Shipments: selectedDeliveries,
          CarId: values.car ? parseInt(values.car) : undefined,
          DriverId: values.driver ? parseInt(values.driver) : undefined,
          SecondaryDriverId: values.driver2 ? parseInt(values.driver2) : undefined,
          Departs: format(values.startDate, 'MM/dd/yyyy HH:mm aa'),
          Arrives: format(values.endDate, 'MM/dd/yyyy HH:mm aa'),
          CreateRoute: creatingRoute,
          Comments: values.comments,
          Directions: settings.features.UsePlaceholderDirections
            ? values.directions || settings.locationSettings?.PlaceholderDirections
            : '',
          RoomId: values.roomId,
          SubRoomId: values.subRoomId,
        })
      );

      return unwrapResult(saveResult)?.RouteId;
    } catch {
      //if the save failed, leave the dialog as is.  Error messages are handled within the AsyncThunk
      return null;
    }
  };

  const onGetOptimizedDirections = async (values: ManifestFormValues) => {
    if (!deliveryFormIsValid(values)) {
      return;
    }

    setLoading(true);
    const id = await save(values);

    if (id) {
      //save succeeded
      await dispatch(
        getOptimizedRouteDirections({ Shipments: selectedDeliveries.map((d) => d.ShipmentId), DeliveryRouteId: id })
      );
      await dispatch(getDeliveries());
      setCreatingRoute(false);
      dispatch(selectRoute(id));
    }
    setLoading(false);
  };

  const onGetDirections = async () => {
    if (!selectedRoute) {
      dispatch(errorNotification('You must save the route before fetching existing directions'));
      return;
    }

    setLoading(true);
    await dispatch(
      getRouteDirections({
        DeliveryRouteId: selectedRoute.DeliveryRouteId,
        ShipmentIds: _.sortBy(selectedDeliveries, (x) => x.ManifestStopNumber).map((d) => d.ShipmentId),
      })
    );
    await dispatch(getDeliveries());
    setLoading(false);
  };

  const onReadyForDelivery = async (values: ManifestFormValues) => {
    if (!deliveryFormIsValid(values, true)) {
      return;
    }

    setIsMarkingReadyForDelivery(true);
    const id = await save(values);

    if (id) {
      //save succeeded
      await dispatch(
        setMultipleReadyForDelivery({
          routeId: id,
          shipmentIds: selectedDeliveries.map((d) => d.ShipmentId),
        })
      );
      await reloadData(id);
      dispatch(selectRoute(undefined));
      hide();
    }

    setIsMarkingReadyForDelivery(false);
  };

  const onPrintMergedManifest = () => {
    if (selectedRoute) {
      dispatch(printMergedManifests(selectedRoute.DeliveryRouteId));
    } else {
      dispatch(errorNotification('You must save the route before printing a route manifest'));
    }
  };

  const onPrintManifests = () => {
    dispatch(printMultipleManifests(selectedDeliveries.map((d) => d.ShipmentId)));
  };

  const onPrintReceipt = async () => {
    printDeliveryReceipts({
      deliveries: selectedDeliveries,
      PrinterId: settings.userSettings.selectedReceiptPrinter?.PrinterId,
      IsLocalPrinter: settings.userSettings.selectedReceiptPrinter?.LocalPrinter ?? false,
    });
  };

  const handlePrintSelect = (value: string) => {
    if (value === 'manifests') {
      onPrintManifests();
    } else if (value === 'route_manifest') {
      onPrintMergedManifest();
    } else {
      onPrintReceipt();
    }
  };

  const isSpinning = React.useMemo(() => loading || isMarkingReadyForDelivery, [loading, isMarkingReadyForDelivery]);

  const roomOptions = [{ label: '--None--', value: '' }];
  if (rooms) {
    roomOptions.push(
      ...rooms
        .filter((x) => x.IsDeliveryStagingRoom)
        .map((room) => ({ label: room.RoomName, value: String(room.RoomId) }))
    );
  }

  const subRoomOptions = [{ label: '--None--', value: '' }];
  if (subrooms) {
    subRoomOptions.push(...subrooms.map((subroom) => ({ label: subroom.TrayNumber, value: String(subroom.id) })));
  }

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <Popup
      medium
      caption={creatingRoute ? 'Create Route' : 'Configure Deliveries'}
      hide={() => {
        dispatch(selectRoute(undefined));
        hide();
      }}
      isVisible
      contentMaxHeight={isModalBridgeEnabled ? '100%' : undefined}
    >
      {isSpinning && (
        <ManifestLoadingWrapperDiv>
          <Loader size='2x' variant='black' />
        </ManifestLoadingWrapperDiv>
      )}
      {!isSpinning && (
        <>
          <Formik
            enableReinitialize
            initialValues={{
              startDate: initalValues?.startDate || todayMidnight,
              endDate: initalValues?.endDate || todayMidnight,
              driver: initalValues?.driver?.toString(),
              driver2: initalValues?.driver2?.toString(),
              car: initalValues?.car?.toString(),
              directions: initalValues?.directions?.toString(),
              roomId: initalValues?.roomId,
              subRoomId: initalValues?.subRoomId,
              comments: '',
              address: 0,
              title: '',
            }}
            onSubmit={onSaveManifest}
          >
            {(props) => {
              const { isValid, setFieldValue } = props;

              return (
                <Form>
                  <ContainerWithPadding>
                    <DateRangeField
                      startName='startDate'
                      endName='endDate'
                      label='Departure and Arrival Time*'
                      isFieldModified={setIsDepartureOrArrivalModified}
                    />
                    {!settings.features.UsePlaceholderDirections && (
                      <ManifestButtonsDiv>
                        <Button secondary type='button' disabled={updatingDeliveryManifest} onClick={onGetDirections}>
                          Get Existing Directions
                        </Button>
                        <Button
                          secondary
                          type='button'
                          disabled={updatingDeliveryManifest}
                          onClick={() => onGetOptimizedDirections(props.values)}
                        >
                          Get Optimized Directions
                        </Button>
                      </ManifestButtonsDiv>
                    )}
                    {settings.features.UsePlaceholderDirections && (
                      <TextAreaField
                        name='directions'
                        label='Directions'
                        rows={3}
                        placeholder={settings.locationSettings?.PlaceholderDirections}
                      />
                    )}
                    <DriversOptionsContainer>
                      <StyledSelectField
                        name='driver'
                        label='Driver'
                        placeholder={initalValues?.driverPlaceholder || ''}
                        options={deliveryDrivers.map((driver) => ({
                          label: driver.Name,
                          value: String(driver.Id),
                        }))}
                        disabled={deliveryDriversLoading || selectedRoute?.IsDynamicDeliveryRoute}
                        automationId='delivery-delivery_options-select-driver'
                      />
                      <StyledSelectField
                        name='car'
                        label='Car'
                        placeholder={initalValues?.carPlaceholder || ''}
                        options={deliveryCars.map((car) => ({
                          label: `${car.CarModel} - ${car.CarLicId}`,
                          value: String(car.id),
                        }))}
                        disabled={deliveryCarsLoading || selectedRoute?.IsDynamicDeliveryRoute}
                        automationId='delivery-delivery_options-select-car'
                      />
                      <StyledSelectField
                        name='driver2'
                        label='Secondary Driver'
                        placeholder={initalValues?.secondaryDriverPlaceholder || ''}
                        options={deliveryDrivers.map((driver) => ({
                          label: driver.Name,
                          value: String(driver.Id),
                        }))}
                        disabled={deliveryDriversLoading}
                      />
                    </DriversOptionsContainer>
                    {useLocationDelivery && useKits && (
                      <DriversOptionsContainer>
                        <StyledSelectField
                          name='roomId'
                          label='Inventory Room'
                          options={roomOptions}
                          onChange={(e) => {
                            setSelectedRoomId(Number(e));
                            setFieldValue('subRoomId', '');
                          }}
                          disabled={deliveryDriversLoading}
                        />
                        <StyledSelectField
                          name='subRoomId'
                          label='Inventory Sub Room'
                          options={subRoomOptions}
                          disabled={deliveryDriversLoading || !roomId}
                        />
                      </DriversOptionsContainer>
                    )}
                    <TextAreaField
                      name='comments'
                      label='Comments'
                      placeholder='Notes entered will be transferred to the manifest'
                      rows={3}
                    />
                  </ContainerWithPadding>
                  <ButtonsContainer isModalBridgeEnabled={isModalBridgeEnabled}>
                    <Button type='submit' disabled={updatingDeliveryManifest || !isValid}>
                      Save
                    </Button>
                    <PrintButtonsSelect
                      name='print'
                      placeholder='Print'
                      options={[
                        { value: 'route_manifest', label: 'Route' },
                        { value: 'manifests', label: 'Manifests' },
                        { value: 'receipt', label: 'Receipt' },
                      ]}
                      disabled={deliveryDriversLoading}
                      onChange={(e) => handlePrintSelect(e)}
                    />
                    <Button
                      type='button'
                      disabled={updatingDeliveryManifest || !isValid}
                      onClick={() => onReadyForDelivery(props.values)}
                      tertiary
                    >
                      Ready For Delivery
                    </Button>
                    <Button
                      type='button'
                      disabled={updatingDeliveryManifest}
                      onClick={() => {
                        dispatch(
                          showBypassStateSystemPopup({
                            onSuccess: (pin: string) => {
                              dispatch(
                                bypassMultipleStateSystem({
                                  ManagerPIN: pin,
                                  ShipmentIds: selectedDeliveries.map((d) => d.ShipmentId),
                                })
                              );
                            },
                            title: 'Bypass',
                          })
                        );
                      }}
                      tertiary
                    >
                      Bypass State System
                    </Button>
                    <CancelButton type='button' onClick={() => hide()}>
                      Cancel
                    </CancelButton>
                  </ButtonsContainer>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Popup>
  );
};

const ContainerWithPadding = styled.div`
  padding: 24px;
`;

const ManifestLoadingWrapperDiv = styled.div`
  display: flex;
  flex: 1 0;
  align-items: center;
  justify-content: center;
  height: 38rem;
`;

const ManifestButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  gap: 28px;

  button {
    width: 310px;
  }
`;

const ButtonsContainer = styled.div<{ isModalBridgeEnabled?: boolean }>`
  padding: 24px;
  display: flex;
  flex-direction: row-reverse;
  box-shadow: 0 -1px 0 ${colors.dutchie.shadowGrey};
  gap: 0.625rem;

  ${({ isModalBridgeEnabled }) =>
    isModalBridgeEnabled &&
    css`
      position: sticky;
      bottom: 0;
      background: ${colors.dutchie.primaryWhite};
      z-index: 1;
    `}
`;

const CancelButton = styled(Button)`
  font-size: 1rem;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: ${colors.white};
  border: none;
  cursor: pointer;
  font-weight: normal;
  margin-right: auto;
`;

const PrintButtonsSelect = styled(SelectField)`
  margin-bottom: 0;
  margin-left: 10px;
  & select {
    height: 44px;
    background: ${colors.dutchie.backgroundGrey};
    color: ${colors.dutchie.gunmetal};
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  & svg {
    width: 10px;
    color: ${colors.dutchie.gunmetal};
    right: 0.75rem;
  }
`;

const StyledSelectField = styled(SelectField)`
  margin-right: 0;
  width: 30%;
`;

const DriversOptionsContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  ${StyledSelectField} {
    width: 47%;
    margin-right: 3%;
  }

  ${StyledSelectField}:last-of-type {
    width: 47%;
    margin-right: 0%;
  }
`;
