import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import styled from 'styled-components';
import { RegisterTransactionRecord } from 'models/Misc';
import { Table, Column, StyleVariant } from 'components/tables';
import { loadRegisterTransactions } from 'store/actions/SettingsActions';
import { format, addDays } from 'date-fns';
import { CashAmount } from 'components/text';

import { TabTitle } from './Styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 1rem;
  thead {
    top: 0;
  }
`;

type RegisterTransactionsProps = {
  registerId: number;
};

export const RegisterTransactions: FC<RegisterTransactionsProps> = ({ registerId }) => {
  const transactions = useSelector((state: State) => state.settings.registerTransactions);
  const register = useSelector((state: State) => state.settings.selectedRegister?.value);
  const fromDate = format(new Date(), 'MM/dd/yyyy');
  const toDate = addDays(new Date(), 1);
  const dispatch = useDispatch();

  const columns: Array<Column<RegisterTransactionRecord>> = [
    { header: 'ID', field: 'transactionID' },
    { header: 'Customer Name', field: 'CustomerName' },
    { header: 'Action', field: 'EntryType' },
    {
      header: 'Date',
      Cell: ({ item }) => <>{format(new Date(item['TransactionDateTime']), 'MM/dd/yyyy - hh:mm a')}</>,
    },
    {
      header: 'Start Balance',
      Cell: ({ item }) => <CashAmount thousandSeparator={true} allowNegative={true} value={item['StartBalance']} />,
    },
    {
      header: 'Amount',
      Cell: ({ item }) => <CashAmount thousandSeparator={true} allowNegative={true} value={item['EntryAmount']} />,
    },
    {
      header: 'Ending Balance',
      Cell: ({ item }) => <CashAmount thousandSeparator={true} allowNegative={true} value={item['EndingBalance']} />,
    },
    {
      header: 'Due Customer',
      Cell: ({ item }) => (
        <CashAmount
          thousandSeparator={true}
          allowNegative={true}
          hideValue={item['TotalDueCustomer'] === null}
          value={item['TotalDueCustomer']}
        />
      ),
    },
    { header: 'Cashier', field: 'TransactionBy' },
    { header: 'Comments', field: 'Comments' },
  ];

  useEffect(() => {
    dispatch(
      loadRegisterTransactions({
        RegisterId: registerId,
        Register: register,
        fromDate: fromDate,
        toDate: format(toDate, 'MM/dd/yyyy'),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container data-testid='register_transactions_page'>
      <TabTitle>Transactions</TabTitle>
      <TableContainer>
        <Table<RegisterTransactionRecord>
          styleVariant={StyleVariant.noWrap}
          data={transactions || []}
          columns={columns}
        />
      </TableContainer>
    </Container>
  );
};
