import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { StyledRow, StyledCell } from 'components/tables';
import { ProductSelectionChangeProps, ProductSelectionControls } from 'components/cart/ProductSelectionControls';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { SelectedPreOrderPackagesType } from '../AssignPackageIDsPopup';
import type { State } from 'store';

type PackageRowType = {
  product: ProductSearchResult;
  selectedPackages?: SelectedPreOrderPackagesType[];
  setSelectedPackages?: (packages: SelectedPreOrderPackagesType[]) => void;
  useRecPrice: boolean;
};

export const PackageRow: FC<PackageRowType> = ({ product, selectedPackages, setSelectedPackages, useRecPrice }) => {
  const { PackageItemIds, EnableCartPlusMinus } = useSelector((state: State) => state.settings.features);

  const packageInSelection = selectedPackages?.find(
    (selectedPreOrderPackage) => selectedPreOrderPackage.serialNo === product.serialNo
  );
  const selectedPackagesClone = selectedPackages ? [...selectedPackages] : [];
  const objIndex = selectedPackagesClone.findIndex(
    (selectedPreOrderPackage) => selectedPreOrderPackage.serialNo === product.serialNo
  );

  const [count, setCount] = useState<number>(0);
  const [grams, setWeight] = useState<number>(0);

  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if (count > 0 || grams > 0) {
      const quantity = product.productType === 'Qty' ? count : grams;
      if (packageInSelection) {
        selectedPackagesClone[objIndex].quantity = quantity;
      } else {
        selectedPackagesClone.push({
          ...product,
          quantity: quantity,
          totalCost: totalCost,
        });
      }
    } else if (packageInSelection) {
      selectedPackagesClone.splice(objIndex, 1);
    }

    setSelectedPackages?.(selectedPackagesClone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, grams]);

  const productID =
    PackageItemIds && product.packageItemSerialNumber ? product.packageItemSerialNumber : product.serialNo;

  const productQty = product.productType === 'Qty' ? product.totalAvailable : `${product.totalGrams}g`;

  return (
    <StyledRow>
      <StyledCell>
        {product.productDescription.split(' /')[0]} | {productID}
      </StyledCell>
      <StyledCell>{productQty}</StyledCell>
      <StyledCell>${useRecPrice ? product.recUnitPrice : product.unitPrice}</StyledCell>
      <StyledSelectorCell>
        {EnableCartPlusMinus && (
          <ProductSelectionControls
            product={product}
            amounts={{ count, grams }}
            setTotalCost={setTotalCost}
            includeCartQtyInMax={false}
            onChange={({ countChange, weightChange }: ProductSelectionChangeProps) => {
              countChange && setCount(count + countChange);
              weightChange && setWeight(grams + weightChange);
            }}
          />
        )}
      </StyledSelectorCell>
    </StyledRow>
  );
};

const StyledSelectorCell = styled(StyledCell)`
  display: flex;
  justify-content: center;
`;
