import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { returnItem } from 'store/actions/CartActions';
import { ReturnItemRequest } from 'models/Cart';
import { warningNotification } from 'store/actions/NotificationsActions';
import { Input, Select, TextArea } from 'components/inputs';
import { Label } from 'components/text';
import { ConfirmationPopup } from 'components/popups';
import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { ReactComponent as CardIcon } from 'assets/icons/icon-card.svg';
import {
  Container,
  FieldWrapper,
  ReturnPopupProps,
  useReturnPopup,
  useReturnReasons,
  YesNoSelectOptions,
} from './useReturnPopup';

export const ReturnPopup: FC<ReturnPopupProps> = ({ isVisible, item, hide, returnInfo }) => {
  const { returnReasons } = useSelector((state: State) => state.customer.options);
  const { features, selectedRegister } = useSelector((state: State) => state.settings);
  const [managerPin, setManagerPin] = useState<string>();
  const [returnReason, setReturnReason] = useState<string>();
  const [cashReturned, setCashReturned] = useState<boolean>(false);
  const [returnedToInventory, setReturnToInventory] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCardPresentReturn, setIsCardPresentReturn] = useState<boolean>(false);
  const dispatch = useDispatch();

  useReturnReasons();

  const onRefund = () => {
    if (!returnReason) {
      dispatch(warningNotification('Please provide a reason'));
      return;
    }

    if (selectedRegister && selectedRegister.value) {
      const returnedItem: ReturnItemRequest = {
        CashRet: cashReturned ? 'Yes' : 'No',
        PutBackInInventory: returnedToInventory ? 'Yes' : 'No',
        RegisterId: selectedRegister.value,
        ReturnReason: returnReason,
        InventoryId: item.PosId,
        ShipmentId: item.ReceiptNo,
      };
      dispatch(returnItem({ item: returnedItem, pinCode: managerPin }));
    } else {
      dispatch(warningNotification('Please select a register before continuing'));
    }

    hide();
  };
  const { onChangeManagerPin, confirmReturn } = useReturnPopup({ setManagerPin, onRefund });

  return (
    <ConfirmationPopup
      isVisible={isVisible}
      hide={hide}
      title='Return Product'
      confirm={{
        text: 'Save',
        onClick: confirmReturn,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <Container>
        <FieldWrapper>
          <Label>{returnInfo ? `$${returnInfo.CashBack.toFixed(2)} ` : ''}Cash Returned</Label>
          <Select
            onChange={(value) => setCashReturned(value === 'Yes')}
            defaultValue='No'
            options={YesNoSelectOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Returned to Inventory?</Label>
          <Select
            onChange={(value) => setReturnToInventory(value === 'Yes')}
            defaultValue='No'
            options={YesNoSelectOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Return Reason</Label>
          {isEmpty(returnReasons) ? (
            <TextArea
              placeholder='Return Reason'
              onChange={(e) => setReturnReason(e.target.value)}
              value={returnReason}
            />
          ) : (
            <Select
              defaultValue={returnReason}
              placeholder='Saved Reasons'
              onChange={(value) => setReturnReason(value)}
              automationId='return-popup_styled-select_reason_for_return'
              options={returnReasons.map((item) => ({ value: item.Reason, label: item.Reason }))}
            />
          )}
        </FieldWrapper>

        {features.MgrPwForReturns && (
          <>
            <Input placeholder='Manager PIN' type='password' value={managerPin} onChange={onChangeManagerPin} />
          </>
        )}
        {isCardPresentReturn && (
          <AlertBanner
            style={AlertBannerStyles.info}
            text='Ask customer to tap, insert, or swipe card'
            icon={<CardIcon />}
          />
        )}
      </Container>
    </ConfirmationPopup>
  );
};
