import React from 'react';

import { useServerMigrationConfig } from './useServerMigrationConfig';
import { Banner, StyledButton, Text } from './ServerMigrationBanner';
import { handleLogout } from 'store/actions/UserActions';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNewRegion } from './useNewRegion';
import { selectUserRegion } from 'store/actions/SettingsActions';

export function AndroidServerMigrationBannerWithUpdatedMessaging() {
  const dispatch = useDispatch();
  const history = useHistory();
  const newRegion = useNewRegion();
  const { registerURL, deadlineDateLabel } = useServerMigrationConfig();

  async function logout() {
    await dispatch(handleLogout({ history, logoutRequestOrigin: 'UserInitiatedLogout' }));
    dispatch(selectUserRegion(newRegion));
  }

  if (!registerURL) {
    return null;
  }

  return (
    <Banner>
      <Text>
        <span>
          {deadlineDateLabel.length ? `As of ${deadlineDateLabel}` : 'Soon'} this URL will be disabled. Please{' '}
          <LinkButton onClick={logout}>click here</LinkButton> to logout and switch to your new server.
        </span>
      </Text>
      <StyledButton tertiary onClick={logout}>
        Switch Server
      </StyledButton>
    </Banner>
  );
}

const LinkButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  font: inherit;
  text-decoration: underline;
`;
