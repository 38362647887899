const isEqual = (arr1: unknown[], arr2: unknown[]) => {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const memoized = <ArgArray extends any[], ReturnType>(cb: (...args: ArgArray) => ReturnType) => {
  let memoizedArgs: ArgArray | null = null;
  let memoizedReturn: ReturnType | null = null;

  return (...args: ArgArray) => {
    if (isEqual(args, memoizedArgs)) {
      return memoizedReturn;
    }

    const result = cb(...args);

    memoizedArgs = args;
    memoizedReturn = result;

    return result;
  };
};
