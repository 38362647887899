import React, { useMemo } from 'react';
import { isSerialScale } from '@dutchie/capacitor-hardware';

import { ScaleConfigureSerialListItem } from './ScaleConfigureSerialListItem';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { useScaleSettings } from '../hooks/useScaleSettings';

export const ScaleConfigureSelectedSerialListItem = () => {
  const { formValues, scales } = useScaleSettings();

  const selectedSerialScale = useMemo(() => {
    return scales.filter(isSerialScale).find((scale) => scale.id === formValues.scaleId);
  }, [formValues.scaleId, scales]);

  if (!selectedSerialScale) {
    return null;
  }

  return (
    <>
      <SettingsDivider />
      <ScaleConfigureSerialListItem scale={selectedSerialScale} />
    </>
  );
};
