import React from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useFulfillmentReceiptPrinterSettings } from '../hooks/useFulfillmentReceiptPrinterSettings';
import { Switch } from 'components/inputs';

export const FulfillmentReceiptPrinterAutoPrintListItem = () => {
  const { formValues, setAutoPrintTickets } = useFulfillmentReceiptPrinterSettings();

  return (
    <SettingsListItem
      title='Auto-print fulfillment tickets'
      subtitle='When enabled, all fulfillment tickets will print automatically'
      actions={[
        <Switch
          automationId='fulfillment_printer_settings-auto_print_switch'
          checked={formValues.autoPrintFulfillmentTickets}
          containerMargin='0'
          onChange={() => setAutoPrintTickets(!formValues.autoPrintFulfillmentTickets)}
        />,
      ]}
    />
  );
};
