import React, { useMemo } from 'react';

import { Pill } from 'components/misc';
import { CheckmarkFilledIcon } from 'assets/icons/settings/CheckmarkFilledIcon';
import { UnavailableIcon } from 'assets/icons/settings/UnavailableIcon';
import { WarningIcon } from 'assets/icons/in-store/WarningIcon';
import { colors } from 'css/Theme';
import { css } from 'styled-components';

export type ConnectionStatusPillProps = {
  automationId?: string;
  connected: boolean;
  deviceName?: string;
  isActionNeeded?: boolean;
};

export const ConnectionStatusPill = ({
  automationId,
  connected,
  deviceName,
  isActionNeeded = false,
}: ConnectionStatusPillProps) => {
  const text = useMemo(() => {
    if (!connected) {
      return 'Not connected';
    }

    if (isActionNeeded) {
      return 'Action needed';
    }

    return 'Connected';
  }, [connected, isActionNeeded]);

  const variation = useMemo(() => {
    if (!connected) {
      return 'grey';
    }

    if (isActionNeeded) {
      return 'yellow';
    }

    return 'green';
  }, [connected, isActionNeeded]);

  const icon = useMemo(() => {
    if (!connected) {
      return <UnavailableIcon />;
    }

    if (isActionNeeded) {
      return <WarningIcon width={12} height={12} fill={colors.dutchie.yellow100} />;
    }

    return <CheckmarkFilledIcon />;
  }, [connected, isActionNeeded]);

  const pill = (
    <Pill
      automationId={automationId}
      icon={icon}
      text={text}
      variation={variation}
      pillStyles={pillStyles}
      iconStyles={iconStyles}
    />
  );

  if (deviceName) {
    return <span title={`Connected to ${deviceName}`}>{pill}</span>;
  }

  return pill;
};

const iconStyles = css`
  margin-right: 0;
  width: 0.75rem;
  height: 0.75rem;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const pillStyles = css`
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  line-height: 1rem;
  border-radius: 0.875rem;
  letter-spacing: 0.00375rem;
`;
