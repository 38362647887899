import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { colors } from 'css/Theme';
import { DrawerHeader } from '../PanelDrawerWrapper';
import { SidePanel, SidePanelSection } from 'components/layout';
import { BackButton } from 'components/buttons';
import { hidePrescriptionPanel } from 'store/actions/CartActions';
import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

export const ViewPrescription: FC = () => {
  const dispatch = useDispatch();

  const {
    data: { PrescriptionData: prescriptionData },
  } = useGetCartDetails();

  const prescriptionExpiration = formatDate({ date: prescriptionData?.Expiration ?? '', fallbackString: 'Unknown' });

  const onBackClick = () => {
    dispatch(hidePrescriptionPanel());
  };

  const { isCompactLayout } = useCompactCartLayout();

  return (
    <SidePanel>
      {isCompactLayout ? (
        <DrawerHeader actionProps={{ label: 'Close', onClick: onBackClick }}>View Prescription</DrawerHeader>
      ) : (
        <Header>
          <TitleContainer>
            <BackButton onClick={onBackClick} />
            <Title>View Prescription</Title>
          </TitleContainer>
        </Header>
      )}
      <Content>
        <InfoHeader>Prescription expiration date</InfoHeader>
        <Info>{prescriptionExpiration}</Info>
        {prescriptionData?.Dosage && prescriptionData.Dosage.length > 0 ? (
          <>
            <InfoHeader>Dosing ratio</InfoHeader>
            {prescriptionData.Dosage.map((dosingRatio) => (
              <Info>{dosingRatio}</Info>
            ))}
          </>
        ) : (
          <>
            <InfoHeader>Dosing recommendation</InfoHeader>
            <Info>{prescriptionData?.Recommendation}</Info>
          </>
        )}
        {prescriptionData?.Notes && (
          <>
            <InfoHeader>Practitioner recommendation</InfoHeader>
            <ScrollableInfo>{prescriptionData.Notes}</ScrollableInfo>
          </>
        )}
      </Content>
    </SidePanel>
  );
};

const headerHeight = '90px';

const Header = styled(SidePanelSection)`
  height: ${headerHeight};
`;

const Content = styled(SidePanelSection)`
  height: calc(100% - ${headerHeight});
  border-bottom: none;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const InfoHeader = styled.div`
  color: ${colors.dutchie.gray70};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.125rem;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const Info = styled.div`
  color: ${colors.dutchie.black};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const ScrollableInfo = styled(Info)<{ maxHeight?: number }>`
  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
  margin-top: 0.5rem;
  overflow-y: auto;
`;
