import React, { useMemo } from 'react';

import { ReactComponent as KebabMenuIcon } from 'assets/icons/kebab-menu.svg';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { DropTarget } from 'components/layout/DragDrop';
import { Chevron, ChevronDirections } from 'assets/icons/chevron';
import { EmbeddedChildTable } from './EmbeddedChildTable';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';

type EmbeddedParentTableRowProps = {
  id: string;
};

export function EmbeddedParentTableRow<ParentItem, ChildItem>(props: EmbeddedParentTableRowProps) {
  const { id } = props;
  const {
    expandedParentId,
    parentItemMap,
    parentColumns,
    childItems,
    getChildId,
    onChangeParent,
    parentActions,
    dispatch,
    onExpandParent,
  } = useEmbeddedTableStore<ParentItem, ChildItem>();

  const item = parentItemMap[id];
  const isExpanded = id === expandedParentId;
  const dragMoveDisabled = !onChangeParent;
  const toggleExpanded = () => {
    dispatch({ type: 'toggleExpandedParent', parentId: id });
    if (onExpandParent) {
      onExpandParent(parentItemMap[id] ?? null);
    }
  };
  const extraColumns = 1 /* the expansion indicator */ + (parentActions ? 1 : 0);
  const actions = useMemo(
    () => (typeof parentActions === 'function' ? parentActions(item) : parentActions),
    [parentActions, item]
  );

  if (!item) {
    console.warn(`No parent item for ID ${id}`); // eslint-disable-line
    return null;
  }

  const handleOnDrop = (idPayload: string) => {
    const payloadChild = childItems.find((item) => getChildId(item) === idPayload);
    if (!payloadChild) {
      return;
    }
    if (onChangeParent) {
      onChangeParent([payloadChild], item);
    }
  };

  return (
    <React.Fragment>
      <DropTarget
        className={['parent', isExpanded ? 'expanded' : 'collapsed'].join(' ')}
        as='tr'
        effect='copy'
        onDrop={handleOnDrop}
        dropDisabled={dragMoveDisabled}
      >
        <td onClick={() => toggleExpanded()} className='parent expansion-indicator'>
          <Chevron direction={isExpanded ? ChevronDirections.DOWN : ChevronDirections.RIGHT} />
        </td>
        {parentColumns.map(({ Cell, cellProps, style }, i) => (
          <td className='parent' key={`parent-${id}-column-${i}`} style={style}>
            <Cell {...cellProps} item={item} />
          </td>
        ))}
        {!!actions?.length && (
          <td className='parent actions'>
            <OverflowMenu
              anchor={<KebabMenuIcon />}
              anchorProps={{ padding: '2px 1rem', showCursor: true }}
              menuOptions={actions.map((action) => ({
                text: action.label,
                onClick: () => action.onClick(item),
              }))}
            />
          </td>
        )}
      </DropTarget>
      {isExpanded && (
        <tr className='embedded'>
          <td colSpan={parentColumns.length + extraColumns} className='embedded'>
            <EmbeddedChildTable<ParentItem, ChildItem> parentId={id} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
