export const openPDFInWindow = (data: string, fileName: string) => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' }));
  link.download = fileName;
  link.target = '_blank';
  link.click();
};
