import React from 'react';
import styled from 'styled-components';

import { eloOriPadBreakpoint } from 'css/Theme';
import { Loader } from 'components/backoffice/loader';
import { CheckedInGuest } from 'models/Guest';
import { OrderKanbanCard } from '../../OrderKanbanCard';
import { useOptionsStatus } from '../../useOptionsStatus';

type Props = {
  orders: Array<CheckedInGuest>;
  posStatusId: number;
};

export const CardList = React.memo(CardListImpl);

function CardListImpl({ orders, posStatusId }: Props) {
  const { cardOptions, cardOptionsStatus } = useOptionsStatus({ posStatusId });

  return (
    <Container>
      {orders?.map((order: CheckedInGuest) => {
        return cardOptions ? (
          <OrderKanbanCard key={order.ShipmentId} guest={order} cardOptionsStatus={cardOptionsStatus} listMode />
        ) : (
          <SpinnerWrapperDiv>
            <Loader variant='grey' size='2x' data-testid='order-kaban-card_div_loading-spinner' />
          </SpinnerWrapperDiv>
        );
      })}
      {orders?.length > 0 && <BottomPaddedSpace />}
    </Container>
  );
}

export const Container = styled.div`
  flex: 1 0 0%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 7px;
  z-index: 1;
  overflow-y: scroll;
`;

export const BottomPaddedSpace = styled.div`
  pointer-events: none;
  height: 50px;
  flex: 0 0 50px;

  ${eloOriPadBreakpoint} {
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }
`;

export const SpinnerWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
