import React, { useEffect, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import {
  addOrUpdateGuest,
  notifyConectionLost,
  notifyPusherConnected,
  removeGuestByTransactionIdFromList,
  removeGuestFromList,
  UpdateGuestListRequest,
} from 'store/actions/GuestListActions';
import Pusher, * as PusherTypes from 'pusher-js_v7.0.3';
import * as GuestApi from 'api/GuestApi';
import { CheckedInGuest, PusherAuthRequest, PusherAuthResponse, PusherConnectionStates } from 'models/Guest';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';

/**
 * @deprecated Use useGuestListPusherChannel instead
 */
export const GuestListPusherImp: FC = () => {
  const pusherConfig = useSelector((state: State) => state.guestList.pusherConfiguration);
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();

  const isSmartCheckinActive = useSmartCheckinActive();
  const { ChannelName: channelName, PusherKey: key, PusherCluster: cluster } = pusherConfig;

  const onNewPusherMessages = useCallback(
    (data: string, notificationType: string) => {
      if (notificationType === 'checkin') {
        const parsedGuest: CheckedInGuest = JSON.parse(data);
        const guestUpdatedType: UpdateGuestListRequest = {
          guestUpdated: parsedGuest,
          settings: settings,
        };
        dispatch(addOrUpdateGuest(guestUpdatedType));
      } else if (notificationType === 'checkout') {
        dispatch(removeGuestFromList({ guestId: parseInt(data), settings }));
      } else if (notificationType === 'checkoutByTransactionId') {
        dispatch(removeGuestByTransactionIdFromList({ transactionId: parseInt(data), settings }));
      } else if (notificationType === 'updateGuest') {
        const parsedGuest: CheckedInGuest = JSON.parse(data);
        const guestUpdatedType: UpdateGuestListRequest = {
          guestUpdated: parsedGuest,
          settings: settings,
        };
        dispatch(addOrUpdateGuest(guestUpdatedType));
      }
    },
    [settings, dispatch]
  );

  useEffect(() => {
    if (isSmartCheckinActive) {
      const pusher = new Pusher(key, {
        cluster: cluster,
        authorizer: function (channel, options): PusherTypes.Authorizer {
          return {
            authorize: function (socketId: string, callback: PusherTypes.AuthorizerCallback) {
              const authPusherRequest: PusherAuthRequest = {
                SocketId: socketId,
              };
              const authString = GuestApi.authPusher(authPusherRequest);
              authString.then((res: PusherAuthResponse) => {
                callback(null, { auth: res.auth });
              });
            },
          };
        },
      });
      const handleStateChange = (states: PusherConnectionStates) => {
        if (states.current === 'connected') {
          dispatch(notifyPusherConnected());
        } else {
          dispatch(notifyConectionLost());
        }
      };

      pusher.connection.bind('state_change', handleStateChange);

      const channel = pusher.subscribe(channelName);
      channel.bind('checkin', (data: string) => onNewPusherMessages(data, 'checkin'));
      channel.bind('checkout', (data: string) => onNewPusherMessages(data, 'checkout'));
      channel.bind('checkoutByTransactionId', (data: string) => onNewPusherMessages(data, 'checkoutByTransactionId'));
      channel.bind('updateGuest', (data: string) => onNewPusherMessages(data, 'updateGuest'));

      return () => {
        pusher.disconnect();
        channel.unbind();
        pusher.unbind('state_change', handleStateChange);
      };
    }
  }, [isSmartCheckinActive, channelName, key, cluster, dispatch, onNewPusherMessages]);

  return <></>;
};
