import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useInventoryKitsLD = () => {
  const ldClient = useLDClient();
  const inventoryKitsRollout: boolean = ldClient?.variation(
    'pos.backoffice.inventory-kits.rollout',
    false
  );
  return inventoryKitsRollout;
};
