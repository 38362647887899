import React, { ReactNode, useEffect } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { colors } from 'css/Theme';

import { PopupOverlay } from 'components/popups';
import { ModalContainer, ModalCloseButton, ModalVariation } from 'components/modals/Modal';
import { LoadingButton } from 'components/buttons';
import { customEventKeys, logger } from 'util/logger';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

import type { ButtonProps } from 'components/buttons/types';

type ButtonConfig = ButtonProps & {
  loading?: boolean;
  disabled?: boolean;
  text: ReactNode;
  onClick: () => void;
};

type ConfirmationModalProps = {
  header: ReactNode;
  children?: ReactNode;
  message: ReactNode;
  rightButton?: ButtonConfig;
  leftButton?: ButtonConfig;
  hide: () => void;
  modalName: string; // Used for tracking
  closeButtonStyles?: FlattenSimpleInterpolation;
  headerStyles?: FlattenSimpleInterpolation;
  footerStyles?: FlattenSimpleInterpolation;
  variation?: ModalVariation;
  automationId?: string;
  className?: string;
  canClose?: boolean;
};

export const ConfirmationModal = ({
  children,
  closeButtonStyles,
  footerStyles,
  header,
  headerStyles,
  message,
  rightButton,
  leftButton,
  hide,
  modalName,
  variation,
  automationId,
  className,
  canClose = true,
}: ConfirmationModalProps): JSX.Element => {
  useEffect(() => {
    logger.info(`${modalName} mounted`, { key: customEventKeys.modalMounted });
  }, [modalName]);

  const { isModalBridgeEnabled } = useModalBridge();

  const ContainerToUse = isModalBridgeEnabled ? ModalContainer : Container;

  return (
    <PopupOverlay hide={() => {}}>
      <ContainerToUse
        data-testid={automationId ?? 'confirmation-modal'}
        variation={isModalBridgeEnabled ? variation : undefined}
        className={className}
      >
        <Header isSticky={isModalBridgeEnabled} styles={headerStyles}>
          <HeaderText data-testid='confirmation-modal-header'>{header}</HeaderText>
          {canClose && (
            <CloseButtonContainer styles={closeButtonStyles}>
              <ModalCloseButton onClick={hide} data-testid='confirmation-modal-close-button' />
            </CloseButtonContainer>
          )}
        </Header>
        <Content>{children ? children : <Info data-testid='confirmation-modal-info'>{message}</Info>}</Content>
        {(leftButton || rightButton) && (
          <Footer styles={footerStyles}>
            {leftButton && (
              <LoadingButton
                cta
                automationId={leftButton.automationId}
                disabled={leftButton.disabled}
                loading={leftButton.loading}
                onClick={leftButton.onClick}
                secondary={leftButton.secondary}
                tertiary={leftButton.tertiary}
                transparentRed={leftButton.transparentRed}
                data-dd-action-name={`click on ${leftButton.text} (${modalName})`}
              >
                {leftButton.text}
              </LoadingButton>
            )}
            {rightButton && (
              <LoadingButton
                cta
                automationId={rightButton.automationId}
                disabled={rightButton.disabled}
                loading={rightButton.loading}
                onClick={rightButton.onClick}
                secondary={rightButton.secondary}
                tertiary={rightButton.tertiary}
                transparentRed={rightButton.transparentRed}
                data-dd-action-name={`click on ${rightButton.text} (${modalName})`}
              >
                {rightButton.text}
              </LoadingButton>
            )}
          </Footer>
        )}
      </ContainerToUse>
    </PopupOverlay>
  );
};

const Container = styled.div<{ variation?: unknown }>`
  background: ${colors.dutchie.primaryWhite};
  border-radius: 12px;
  overflow: hidden;
  width: 600px;
  position: relative;
`;

const CloseButtonContainer = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  position: absolute;
  right: 15px;
  top: 15px;

  ${({ styles }) => styles}
`;

export const Header = styled.div<{ isSticky?: boolean; styles?: FlattenSimpleInterpolation }>`
  padding: 48px 24px 24px 24px;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
      background: ${colors.dutchie.primaryWhite};
      z-index: 1;
    `}

  ${({ styles }) => styles}
`;

const HeaderText = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: ${colors.dutchie.almostBlack};
`;

const Info = styled.div`
  color: ${colors.dutchie.darkGrey};
  text-align: center;
  padding: 0 50px;
  font-size: 18px;
`;

const Content = styled.div``;

const Footer = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  padding: 40px;
  display: flex;
  align-items: center;
  gap: 16px;

  ${({ styles }) => styles}
`;
