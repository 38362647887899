import React from 'react';

import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { LabelPrinterSettingsListItem } from './LabelPrinter/LabelPrinterSettingsListItem';
import { ReceiptPrinterSettingsListItem } from './ReceiptPrinter/ReceiptPrinterSettingsListItem';
import { ScaleSettingsListItem } from './Scale/ScaleSettingsListItem';
import { ScannerSettingsListItem } from './Scanner/ScannerSettingsListItem';
import { FulfillmentReceiptPrinterSettingsListItem } from './FulfillmentReceiptPrinter/FulfillmentReceiptPrinterSettingsListItem';
import { CfdSettingsListItem } from './Cfd/CfdSettingsListItem';
import { useCfd } from './Cfd/hooks/useCfd';
import { ZReportReceiptPrinterSettingsListItem } from './ZReportReceiptPrinter/ZReportReceiptPrinterSettingsListItem';
import { useAssignRegister } from './AssignRegister/hooks/useAssignRegister';
import { AssignRegisterSettingsListItem } from './AssignRegister/AssignRegisterSettingsListItem';
import { isWebViewApp } from 'util/hooks';

export const HardwareTab = () => {
  const { showCartDisplay } = useCfd();
  const { showAssignRegister } = useAssignRegister();

  return (
    <SettingsCard automationId='hardware-settings-card'>
      <ScannerSettingsListItem />
      <SettingsDivider />
      <LabelPrinterSettingsListItem />
      <SettingsDivider />
      <ReceiptPrinterSettingsListItem />
      <SettingsDivider />
      <FulfillmentReceiptPrinterSettingsListItem />
      <SettingsDivider />
      <ZReportReceiptPrinterSettingsListItem />
      <SettingsDivider />
      <ScaleSettingsListItem />
      {showCartDisplay && !isWebViewApp && (
        <>
          <SettingsDivider />
          <CfdSettingsListItem />
        </>
      )}
      {showAssignRegister && (
        <>
          <SettingsDivider />
          <AssignRegisterSettingsListItem />
        </>
      )}
    </SettingsCard>
  );
};
