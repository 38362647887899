import { useState, useMemo } from 'react';

import type { Tab, TabbedContentProps } from './TabbedContent';

export const useTabbedContent = ({ tabs, selectedTab }: TabbedContentProps) => {
  const [currentTab, setCurrentTab] = useState(selectedTab ?? tabs[0].label);

  const handleClickTab = (tab: Tab) => {
    tab.onClick?.();
    setCurrentTab(tab.label);
  };

  const selectedTabContent = useMemo(() => {
    const tab = tabs.find((tab) => tab.label === currentTab);
    return tab?.content ?? null;
  }, [currentTab, tabs]);

  return {
    currentTab,
    handleClickTab,
    selectedTabContent,
  };
};
