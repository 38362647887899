import { useCallback, useRef, useState, useEffect } from 'react';
import { debounce } from 'lodash';

type UseSearchProps = {
  initialValue?: string;
  isAutoSearchEnabled?: boolean;
  onSearch?: (value: string) => unknown;
  onAsyncSearch?: (value: string) => Promise<unknown>;
};

export const useSearch = ({
  initialValue = '',
  isAutoSearchEnabled = false,
  onSearch: onSyncSearch,
  onAsyncSearch,
}: UseSearchProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [hasFocus, setHasFocus] = useState(false);
  const [isProcessingAsyncSearch, setIsProcessingAsyncSearch] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleFocusInput = () => inputRef.current?.focus({ preventScroll: true });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    handleAutoSearch(newValue);
  };

  const onSearch = useCallback(
    (newValue: string) => {
      const trimmedValue = newValue.trim();

      if (onSyncSearch) {
        onSyncSearch(trimmedValue);
      }

      if (onAsyncSearch) {
        setIsProcessingAsyncSearch(true);
        onAsyncSearch(trimmedValue).finally(() => setIsProcessingAsyncSearch(false));
      }
    },
    [onSyncSearch, onAsyncSearch]
  );

  const handleAutoSearch = useCallback(
    debounce(async (newValue: string) => {
      if (isAutoSearchEnabled) {
        onSearch(newValue);
      }
    }, 1000),
    [isAutoSearchEnabled, onSearch]
  );

  const handleSearchButtonClicked = useCallback(() => {
    onSearch(value);
    inputRef.current?.blur();
  }, [value, onSearch]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && value.trim() !== '') {
        onSearch(value);
      }
    },
    [onSearch, value]
  );

  useEffect(() => handleFocusInput(), []);

  return {
    handleChange,
    handleFocusInput,
    handleKeyPress,
    handleSearchButtonClicked,
    hasFocus,
    inputRef,
    isProcessingAsyncSearch,
    onSearch,
    searchString: value.trim(),
    setHasFocus,
    value,
  };
};
