import React from 'react';
import styled from 'styled-components';

import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { colors } from 'css/Theme';
import { LoadingButton } from 'components/buttons';
import { ModalContainer, ModalCloseButton, ModalVariation } from 'components/modals/Modal';
import { PopupOverlay } from 'components/popups';
import { Select } from 'components/inputs';
import { useChangeCustomerTypeModal } from './useChangeCustomerTypeModal';

export type ChangeCustomerTypeModalProps = {
  hide: () => void;
};

export const ChangeCustomerTypeModal = ({ hide }: ChangeCustomerTypeModalProps) => {
  const {
    customerTypeOptions,
    handleClickConfirm,
    handleSelectCustomerType,
    isConfirmDisabled,
    isUpdatingCustomer,
    selectedCustomerType,
    showWarningMessage,
    warningMessage,
  } = useChangeCustomerTypeModal({ hide });

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer variation={ModalVariation.Standard} data-testid='change-customer-type-modal'>
        <ModalHeader>
          <Title>Change customer type</Title>
          <ModalCloseButton onClick={hide} data-testid='change-customer-type-modal_close-button' />
        </ModalHeader>
        <ModalContent>
          <Message>Select an option from the dropdown to change the customer type.</Message>
          <Select
            defaultValue={selectedCustomerType}
            options={customerTypeOptions}
            onChange={handleSelectCustomerType}
            automationId='change-customer-type-modal_select-customer-type'
          />
          {showWarningMessage && (
            <AlertBanner style={AlertBannerStyles.warning} marginTop='0' title='Warning.' text={warningMessage} />
          )}
        </ModalContent>
        <ModalFooter>
          <LoadingButton cta tertiary onClick={hide} data-dd-action-name='cancel on the change customer type modal'>
            Cancel
          </LoadingButton>
          <LoadingButton
            cta
            onClick={handleClickConfirm}
            disabled={isConfirmDisabled}
            loading={isUpdatingCustomer}
            data-dd-action-name='confirm on the change customer type modal'
          >
            Confirm
          </LoadingButton>
        </ModalFooter>
      </ModalContainer>
    </PopupOverlay>
  );
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 0 1.5rem;
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  flex: 1;
  padding: 2rem 0;
  margin-left: 3.75rem;
  text-align: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 2rem;
`;

const Message = styled.div`
  color: ${colors.dutchie.grey30};
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;
