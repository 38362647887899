import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const ZReportIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0481 18.5132C19.0481 18.1521 18.7568 17.8595 18.3975 17.8595L1.48354 17.8595C1.12426 17.8595 0.833006 18.1521 0.833006 18.5132C0.833006 18.8742 1.12426 19.1668 1.48354 19.1668L18.3975 19.1668C18.7568 19.1668 19.0481 18.8742 19.0481 18.5132Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1663 1.48717C19.1663 1.12616 18.8751 0.833498 18.5158 0.833498L13.3115 0.833497C12.9522 0.833497 12.661 1.12616 12.661 1.48717L12.661 18.4827C12.661 18.8437 12.9522 19.1364 13.3115 19.1364L18.5158 19.1364C18.8751 19.1364 19.1663 18.8437 19.1663 18.4827L19.1663 1.48717ZM17.8653 2.14085L17.8653 17.829L13.962 17.829L13.962 2.14085L17.8653 2.14085Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.898 6.69381C13.898 6.33279 13.6067 6.04013 13.2474 6.04013L7.93471 6.04013C7.57543 6.04013 7.28418 6.33279 7.28418 6.69381L7.28417 18.5132C7.28417 18.8742 7.57543 19.1668 7.93471 19.1668L13.2474 19.1668C13.6067 19.1668 13.898 18.8742 13.898 18.5132L13.898 6.69381ZM12.5969 7.34748L12.5969 17.8595L8.58525 17.8595L8.58525 7.34748L12.5969 7.34748Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.58525 12.4147C8.58525 12.0537 8.294 11.7611 7.93471 11.7611L3.00147 11.7611C2.64218 11.7611 2.35093 12.0537 2.35093 12.4147L2.35093 18.5132C2.35093 18.8742 2.64218 19.1668 3.00146 19.1668L7.93471 19.1668C8.29399 19.1668 8.58525 18.8742 8.58525 18.5132L8.58525 12.4147ZM7.28418 13.0684L7.28417 17.8595L3.652 17.8595L3.652 13.0684L7.28418 13.0684Z" fill={color} />
    </svg>
  );
};
