export const RegionList: RList = {
  develop: 'http://<ip>:3000/api/', //Replace this with your ip but do not check into git
  staging: 'https://test-newpos.leaflogix.net/api/',
  paralleluat: 'https://webapi-uat.dutchie.dev/api/',
  uat: 'https://uat-tf-newpos.leaflogix.net/api/',
  herokutest: 'https://leaflogix-automation-newpos.herokuapp.com/api/',
  staging2: 'https://test-newpos.leaflogix.net/api/',
  harvest: 'https://harvest.pos.dutchie.com/api/',
  verano: 'https://verano.pos.dutchie.com/api/',
  east: 'https://eastsupport.pos.dutchie.com/api/',
  shango: 'https://shango.pos.dutchie.com/api/',
  bloom: 'https://bloom-medicinals.pos.dutchie.com/api/',
  mng: 'https://mng.pos.dutchie.com/api/',
  p13: 'https://planet13.pos.dutchie.com/api/',
  greendragon: 'https://greendragon.pos.dutchie.com/api/',
  curaleaf: 'https://curaleaf.pos.dutchie.com/api/',
  ayr: 'https://ayr.pos.dutchie.com/api/',
  ascend: 'https://ascend.pos.dutchie.com/api/',
  gage: 'https://gage.pos.dutchie.com/api/',
  gdf: 'https://gooddayfarm.pos.dutchie.com/api/',
  grassroots: 'https://grassroots.pos.dutchie.com/api/',
  greengrowth: 'https://greengrowth.pos.dutchie.com/api/',
  holistic: 'https://holistic.pos.dutchie.com/api/',
  marimed: 'https://marimed.pos.dutchie.com/api/',
  atg: 'https://atg.pos.dutchie.com/api/',
  gti: 'https://gti.pos.dutchie.com/api/',
  columbiacare: 'https://columbiacare.pos.dutchie.com/api/',
  windycity: 'https://windycity.pos.dutchie.com/api/',
  cresco: 'https://cresco.pos.dutchie.com/api/',
  demo: 'https://demo.pos.dutchie.com/api/',
  canada: 'https://canada.pos.dutchie.com/api/',
  redstone: 'https://redstone.pos.dutchie.com/api/',
  horizon: 'https://horizon.pos.dutchie.com/api/',
  tempest: 'https://tempest.pos.dutchie.com/api/',
  delta: 'https://delta.pos.dutchie.com/api/',
  acreage: 'https://acreage.pos.dutchie.com/api/',
  higherlove: 'https://higherlove.pos.dutchie.com/api/',
  stiiizy: 'https://stiiizy.pos.dutchie.com/api/',
  titan: 'https://titan.pos.dutchie.com/api/',
  echo: 'https://echo.pos.dutchie.com/api/',
  atlas: 'https://atlas.pos.dutchie.com/api/',
  storm: 'https://storm.pos.dutchie.com/api/',
  oasis: 'https://oasis.pos.dutchie.com/api/',
  bolt: 'https://bolt.pos.dutchie.com/api/',
  spark: 'https://spark.pos.dutchie.com/api/',
  omega: 'https://omega.pos.dutchie.com/api/',
  ember: 'https://ember.pos.dutchie.com/api/',
  surge: 'https://surge.pos.dutchie.com/api/',
  nobo: 'https://nobo.pos.dutchie.com/api/',
  jushi: 'https://jushi.pos.dutchie.com/api/',
  illicit: 'https://illicit.pos.dutchie.com/api/',
  medfarms: 'https://medfarms.pos.dutchie.com/api/',
  puff: 'https://puff.pos.dutchie.com/api/',
  story: 'https://story.pos.dutchie.com/api/',
  stash: 'https://stash.pos.dutchie.com/api/',
  nectar: 'https://nectar.pos.dutchie.com/api/',
  pecos: 'https://pecos.pos.dutchie.com/api/',
  consume: 'https://consume.pos.dutchie.com/api/',
  travelagency: 'https://travel-agency.pos.dutchie.com/api/',
  finefettle: 'https://fine-fettle.pos.dutchie.com/api/',
  strainstars: 'https://strain-stars.pos.dutchie.com/api/',
  trp: 'https://trp.pos.dutchie.com/api/',
  nuera: 'https://nuera.pos.dutchie.com/api/',
  happyvalley: 'https://happy-valley.pos.dutchie.com/api/',
  pure: 'https://pure.pos.dutchie.com/api/',
  florafarms: 'https://flora-farms.pos.dutchie.com/api/',
  commoncitizen: 'https://cc.pos.dutchie.com/api/',
  occ: 'https://occ.pos.dutchie.com/api/',
  ash: 'https://ash.pos.dutchie.com/api/',
  canyon: 'https://canyon.pos.dutchie.com/api/',
  dawn: 'https://dawn.pos.dutchie.com/api/',
  dusk: 'https://dusk.pos.dutchie.com/api/',
  fern: 'https://fern.pos.dutchie.com/api/',
  glade: 'https://glade.pos.dutchie.com/api/',
  maple: 'https://maple.pos.dutchie.com/api/',
  pine: 'https://pine.pos.dutchie.com/api/',
  sage: 'https://sage.pos.dutchie.com/api/',
  shore: 'https://shore.pos.dutchie.com/api/',
  slate: 'https://slate.pos.dutchie.com/api/',
  apex: 'https://apex.pos.dutchie.com/api/',
  cove: 'https://cove.pos.dutchie.com/api/',
  dune: 'https://dune.pos.dutchie.com/api/',
  eden: 'https://eden.pos.dutchie.com/api/',
  frost: 'https://frost.pos.dutchie.com/api/',
  haven: 'https://haven.pos.dutchie.com/api/',
  mesa: 'https://mesa.pos.dutchie.com/api/',
  shift: 'https://shift.pos.dutchie.com/api/',
  sky: 'https://sky.pos.dutchie.com/api/',
  tide: 'https://tide.pos.dutchie.com/api/',
  topcrop: 'https://top-crop.pos.dutchie.com/api/',
  peak: 'https://peak.pos.dutchie.com/api/',
  crest: 'https://crest.pos.dutchie.com/api/',
  c3: 'https://c3.pos.dutchie.com/api/',
  goldleaf: 'https://gold-leaf.pos.dutchie.com/api/',
  curio: 'https://curio.pos.dutchie.com/api/',
  insa: 'https://insa.pos.dutchie.com/api/',
  ianthus: 'https://ianthus.pos.dutchie.com/api/',
  hod: 'https://hod.pos.dutchie.com/api/',
  cedar: 'https://cedar.pos.dutchie.com/api/',
  birch: 'https://birch.pos.dutchie.com/api/',
  oak: 'https://oak.pos.dutchie.com/api/',
  jars: 'https://jars.pos.dutchie.com/api/',
  spruce: 'https://spruce.pos.dutchie.com/api/',
  iris: 'https://iris.pos.dutchie.com/api/',
  lily: 'https://lily.pos.dutchie.com/api/',
  willow: 'https://willow.pos.dutchie.com/api/',
  performance: 'https://leaflogix-uat-tf-newpos.azurewebsites.net/',
  'grave-euler': 'https://grave-euler-newpos.defnotprod.com/api/',
  'big-wescoff': 'https://big-wescoff-newpos.defnotprod.com/api/',
  'suspicious-bell': 'https://suspicious-bell-newpos.defnotprod.com/api/',
  'awesome-blackwell': 'https://awesome-blackwell-newpos.defnotprod.com/api/',
  'condescending-wing': 'https://condescending-wing-newpos.defnotprod.com/api/',
  'backstabbing-yalow': 'https://backstabbing-yalow-newpos.defnotprod.com/api/',
  'hopeful-boyd': 'https://hopeful-boyd-newpos.defnotprod.com/api/',
  'elated-bell': 'https://elated-bell-newpos.defnotprod.com/api/',
  'furious-snyder': 'https://furious-snyder-newpos.defnotprod.com/api/',
  'mad-bartik': 'https://mad-bartik-newpos.defnotprod.com/api/',
  'loving-borg': 'https://loving-borg-newpos.defnotprod.com/api/',
  'tender-curie': 'https://tender-curie-newpos.defnotprod.com/api/',
  'goofy-hopper': 'https://goofy-hopper-newpos.defnotprod.com/api/',
  'kickass-lichterman': 'https://kickass-lichterman-newpos.defnotprod.com/api/',
  'lonely-euclid': 'https://lonely-euclid-newpos.defnotprod.com/api/',
  'naughty-leavitt': 'https://naughty-leavitt-newpos.defnotprod.com/api/',
  'naughty-minsky': 'https://naughty-minsky-newpos.defnotprod.com/api/',
  'naughty-snyder': 'https://naughty-snyder-newpos.defnotprod.com/api/',
  'jolly-pasteur': 'https://jolly-pasteur-newpos.defnotprod.com/api/',
  'angry-mayer': 'https://angry-mayer-newpos.defnotprod.com/api/',
  'furious-pare': 'https://furious-pare-newpos.defnotprod.com/api/',
  'gigantic-pike': 'https://gigantic-pike-newpos.defnotprod.com/api/',
  'suspicious-liskov': 'https://suspicious-liskov-newpos.defnotprod.com/api/',
  'sick-mirzakhani': 'https://sick-mirzakhani-newpos.defnotprod.com/api/',
  'loving-perlman': 'https://loving-perlman-newpos.defnotprod.com/api/',
  'prickly-albattani': 'https://prickly-albattani-newpos.defnotprod.com/api/',
  'distracted-einstein': 'https://distracted-einstein-newpos.defnotprod.com/api/',
  'thirsty-mcclintock': 'https://thirsty-mcclintock-newpos.defnotprod.com/api/',
  'admiring-boyd': 'https://admiring-boyd-newpos.defnotprod.com/api/',
  'berserk-stallman': 'https://berserk-stallman-newpos.defnotprod.com/api/',
  medmen: 'https://medmen.pos.dutchie.com/api/',
};

export type RList = {
  [key: string]: string;
};
