import { useState, useEffect } from 'react';

export const useNodeWidth = (ref?: HTMLElement | null) => {
  const [widthPx, setWidthPx] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setWidthPx(ref?.offsetWidth ?? null);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return widthPx;
};
