import { Box } from "@mui/material";
import { Loader } from "components/backoffice/loader";
import { Label } from "components/text";
import React, { useMemo, useState } from "react";
import { SelectContainer, ActionBar, RefreshButton } from "../SettingPageComponents";
import { useHardwareLibraryLabelPicker, useHardwareLibraryLabelPrinters } from "./hooks/useHardwareLibraryLabelPrinters";
import { useLabelPrinterPicker } from "./hooks/useLabelPrinterPicker";
import { Select } from "components/inputs";
import { Button } from "components/buttons";
import { useTestPrintButtons } from "util/hooks/launch-darkly/useTestPrintButtons";

export const HardwareLibraryLabelPrinterDropdown = ({ connectionType }: { connectionType: 'network' | 'local' }) => {
  const { isLoading, refreshList } = useHardwareLibraryLabelPrinters({ connectionType });
  const { changeSelection, dropdownOptions, selectedPrinterId, testPrint } = useHardwareLibraryLabelPicker({ connectionType });
  const { isAutoPrintEnabled } = useLabelPrinterPicker();
  const showTestPrintButton = useTestPrintButtons();

  const [isPrinting, setIsPrinting] = useState(false);

  // When toggling the rollout flag, the selected printer may no longer be available and this attempts to prevent selecting
  // a printer that is not available since the dropdown does not update when that value changes.
  const defaultValue: string | undefined = useMemo(() => {
    return dropdownOptions.find((item) => item.value === selectedPrinterId)?.value?.toString();
  }, [selectedPrinterId, dropdownOptions]);

  const handleTestPrint = async () => {
    try {
      setIsPrinting(true);
      await testPrint();
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <SelectContainer>
      <Label>{connectionType === 'network' ? 'Network' : 'Local'} Label Printer</Label>
      <ActionBar>
        <Box flex={1}>
          {isLoading && <Loader variant='black' />}
          {!isLoading && (
            <Select
              placeholder='Select Printer'
              value={defaultValue}
              onChange={changeSelection}
              options={dropdownOptions}
              errorMessage={!selectedPrinterId && isAutoPrintEnabled && `Please select a default label printer`}
            />
          )}
        </Box>
        <RefreshButton onClick={() => refreshList({ userInitiated: true })} />
        {showTestPrintButton && <Button disabled={!selectedPrinterId || isPrinting} onClick={handleTestPrint}>Test Print</Button> }
      </ActionBar>
    </SelectContainer>
  );
};
