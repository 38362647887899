import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as DiscountApi from 'api/DiscountApi';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { ApplyDiscountRequest } from 'models/Discounts';
import { errorNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { useRefetchCartDetails } from '../hooks/useRefetchCartDetails';
import { useTransactionManager } from '../hooks/useTransactionManager';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const StyledButton = styled(Button)`
  &:disabled {
    border-color: ${colors.dutchie.grey70};
    background: ${colors.dutchie.grey70};
    opacity: 1;
  }
`;

export const ApplyDiscountCode: FC = () => {
  const dispatch = useDispatch();

  const [code, setCode] = useState('');
  const refetchCartDetails = useRefetchCartDetails();

  const { guestId, shipmentId } = useTransactionManager();

  const createDiscountBody = (discountCode: string, shipmentId: number, customerId: number): ApplyDiscountRequest => ({
    DiscountCode: discountCode,
    ShipmentId: shipmentId,
    DiscountId: 0,
    OverideDesc: '',
    Apply: 'ONE',
    DiscountAmt: 0,
    DiscountPercent: 0,
    ProductId: 0,
    SerialNo: '',
    CustomerId: customerId,
  });

  const applyDiscountCode = async () => {
    try {
      if (guestId && shipmentId) {
        await DiscountApi.applyDiscountCode(createDiscountBody(code, shipmentId, guestId));
        refetchCartDetails();
        setCode('');
      }
    } catch (err) {
      logger.error(err, { message: 'Error applying discount' });
      return dispatch(errorNotification(typeof err === 'string' ? err : 'Error applying discount'));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (code !== '' && e.key === 'Enter') {
      applyDiscountCode();
    }
  };

  return (
    <Container>
      <Input
        placeholder='Enter discount code'
        value={code}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) => setCode(e.target.value)}
        onKeyPress={handleKeyPress}
        automationId='apply-discount-code_dutchie-input_input'
      />
      <StyledButton
        onClick={applyDiscountCode}
        disabled={code === ''}
        automationId='apply-discount-code_styled-button_apply'
      >
        Apply
      </StyledButton>
    </Container>
  );
};
