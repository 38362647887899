import React from 'react';
import { CardFieldValue, CardLabel, CardText } from '../styles';

type Props = {
  testId: string;
  label: string;
  value?: React.ReactNode;
  expired?: boolean;
};
export const CardDetail = React.memo(({ testId, label, value, expired = false }: Props) => {
  return (
    <CardText data-testid={testId}>
      <CardLabel>{label}:</CardLabel>
      <CardFieldValue expired={expired}>{value ?? ''}</CardFieldValue>
    </CardText>
  );
});
