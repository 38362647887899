import { useCallback } from 'react';

import { DurationUnit, MetricType } from 'store/reducers/MetricsReducer';
import { addCountMetric, addDurationMetric, addTimestampMetric } from 'store/actions/MetricsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';

import type { Metric } from 'store/reducers/MetricsReducer';

type MetricsActionOptions = {
  logData?: Record<string, unknown>;
};

type AddCountOptions = MetricsActionOptions & {
  value?: number;
  unit?: string;
};

type AddDurationParams = MetricsActionOptions & {
  timestampKey: string;
  unit?: DurationUnit;
};

type MetricsActions = {
  /** Logs and increments a count */
  addCount: (key: string, options?: AddCountOptions) => void;
  /** Logs the difference in milliseconds from a previous timestamp to now */
  addDuration: (key: string, params: AddDurationParams) => void;
  /** Logs a timestamp that can be used to calculate durations */
  addTimestamp: (key: string, options?: MetricsActionOptions) => void;
  /** Lookup a metric by a key */
  lookup: (key: string) => Metric | undefined;
};

export const useMetrics = (): MetricsActions => {
  const dispatch = useAppDispatch();
  const metrics = useAppSelector((state) => state.metrics.metrics);

  const addCount = useCallback(
    (key: string, options?: AddCountOptions) => {
      const { value, unit, logData } = options ?? {};
      dispatch(addCountMetric({ key, type: MetricType.COUNT, value: value ?? 1, unit, logData }));
    },
    [dispatch]
  );

  const addDuration = useCallback(
    (key: string, params: AddDurationParams) => {
      const { timestampKey, unit, logData } = params;
      dispatch(addDurationMetric({ key, type: MetricType.DURATION, timestampKey, unit, logData }));
    },
    [dispatch]
  );

  const addTimestamp = useCallback(
    (key: string, options?: MetricsActionOptions) => {
      const { logData } = options ?? {};
      dispatch(addTimestampMetric({ key, type: MetricType.TIMESTAMP, logData }));
    },
    [dispatch]
  );

  const lookup = useCallback((key: string) => metrics[key], [metrics]);

  return {
    addCount,
    addDuration,
    addTimestamp,
    lookup,
  };
};
