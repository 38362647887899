import React from 'react';

type DeliveryIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function DeliveryIcon(props: DeliveryIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.6998 18.6684V3.03606C15.6998 2.08928 14.9323 1.32178 13.9855 1.32178H2.91741C1.97064 1.32178 1.20312 2.08928 1.20312 3.03606V18.3943C1.20312 19.3411 1.97064 20.1086 2.91741 20.1086H4.20312'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.7959 13.4346H15.7031'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.4869 19.7889H21.0778C22.0246 19.7889 22.7921 19.0214 22.7921 18.0746V12.9302L20.4051 7.04299C20.1176 6.45437 19.5199 6.08105 18.8647 6.08105H16.0078'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.28906 20.1602C4.28906 20.4745 4.35096 20.7857 4.47123 21.076C4.5915 21.3664 4.76777 21.6302 4.99 21.8524C5.21222 22.0747 5.47604 22.2509 5.76639 22.3712C6.05674 22.4915 6.36793 22.5534 6.68221 22.5534C6.99648 22.5534 7.30767 22.4915 7.59802 22.3712C7.88837 22.2509 8.15219 22.0747 8.37441 21.8524C8.59664 21.6302 8.77291 21.3664 8.89318 21.076C9.01345 20.7857 9.07535 20.4745 9.07535 20.1602C9.07535 19.846 9.01345 19.5348 8.89318 19.2444C8.77291 18.9541 8.59664 18.6902 8.37441 18.468C8.15219 18.2458 7.88837 18.0695 7.59802 17.9493C7.30767 17.829 6.99648 17.7671 6.68221 17.7671C6.36793 17.7671 6.05674 17.829 5.76639 17.9493C5.47604 18.0695 5.21222 18.2458 4.99 18.468C4.76777 18.6902 4.5915 18.9541 4.47123 19.2444C4.35096 19.5348 4.28906 19.846 4.28906 20.1602Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1523 20.1602C15.1523 20.7949 15.4045 21.4036 15.8533 21.8524C16.3021 22.3012 16.9108 22.5534 17.5455 22.5534C18.1802 22.5534 18.7889 22.3012 19.2377 21.8524C19.6865 21.4036 19.9386 20.7949 19.9386 20.1602C19.9386 19.846 19.8767 19.5348 19.7565 19.2444C19.6362 18.9541 19.4599 18.6902 19.2377 18.468C19.0155 18.2458 18.7517 18.0695 18.4613 17.9493C18.171 17.829 17.8598 17.7671 17.5455 17.7671C17.2312 17.7671 16.92 17.829 16.6297 17.9493C16.3393 18.0695 16.0755 18.2458 15.8533 18.468C15.6311 18.6902 15.4548 18.9541 15.3345 19.2444C15.2142 19.5348 15.1523 19.846 15.1523 20.1602Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7037 20.1084H9.66797'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
