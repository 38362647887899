import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { closeCustomerHistoryPanel, openCustomerHistoryPanel } from 'store/actions/CartActions';
import { CustomerHistoryPanelTabs } from './CustomerHistoryPanel';
import { getProductHistoryKeys } from 'queries/v2/product/query-key-factory';
import { getTransactionHistoryKeys } from 'queries/v2/transaction-history/query-key-factory';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';
import { ProductHistory } from './components/ProductHistory';
import { TransactionHistory } from './components/TransactionHistory';
import { useAppDispatch, useAppSelector } from 'util/hooks';

import type { Tab } from 'components/layout';

export const useCustomerHistoryPanel = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const isCustomerHistoryPanelOpen = useAppSelector((state) => state.cart.isCustomerHistoryPanelOpen);
  const selectedTab = useAppSelector((state) => state.cart.selectedCustomerHistoryPanelTab);

  // Tabs

  const handleClickTab = (newTab: CustomerHistoryPanelTabs) => {
    dispatch(openCustomerHistoryPanel({ tab: newTab }));
  };

  const customerHistoryTabs: Tab[] = [
    {
      label: CustomerHistoryPanelTabs.Transactions,
      onClick: () => handleClickTab(CustomerHistoryPanelTabs.Transactions),
      content: <TransactionHistory />,
    },
    {
      label: CustomerHistoryPanelTabs.Products,
      onClick: () => handleClickTab(CustomerHistoryPanelTabs.Products),
      content: <ProductHistory />,
    },
  ];

  // Handlers

  const handleClose = () => {
    dispatch(closeCustomerHistoryPanel());
  };

  useEffect(() => {
    if (isCustomerHistoryPanelOpen) {
      queryClient.invalidateQueries(getProductHistoryKeys.all);
      queryClient.invalidateQueries(getTransactionHistoryKeys.all);
    }
  }, [queryClient, isCustomerHistoryPanelOpen]);

  return {
    customerHistoryTabs,
    FullScreenPortalId,
    handleClose,
    isCustomerHistoryPanelOpen,
    selectedTab,
  };
};
