import { useState, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import type { NavigationItemData } from 'util/hooks/useNavigationItems';

type UseNavigationItemParams = {
  item: NavigationItemData;
};

export type UseNavigationItemReturn = {
  isRouteActive: boolean;
  isSubMenuOpen: boolean;
  handleItemClick: () => void;
  handleSubMenuClick: () => void;
  handleSubMenuClickAway: () => void;
};

export function useNavigationItem(params: UseNavigationItemParams): UseNavigationItemReturn {
  const { item } = params;

  // state
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

  // hooks
  const location = useLocation();

  // computed
  const isRouteActive = !!useRouteMatch(item.activeRoutes ?? []);

  // handlers
  const handleItemClick = () => {
    item.onClick?.() ?? setIsSubMenuOpen((prevState) => !prevState);
  };

  const handleSubMenuClick = () => {
    setIsSubMenuOpen(false);
  };

  const handleSubMenuClickAway = () => {
    setIsSubMenuOpen(false);
  };

  // effects
  useEffect(() => {
    setIsSubMenuOpen(false);
  }, [location]);

  return {
    isRouteActive,
    isSubMenuOpen,
    handleItemClick,
    handleSubMenuClick,
    handleSubMenuClickAway,
  };
}
