import React from 'react';
import styled from 'styled-components';

import { CheckedInGuest } from 'models/Guest';
import { colors } from 'css/Theme';
import { InfoContainer } from '../../styles';
import { useCustomerName } from './useCustomerName';

type Props = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
  listMode: boolean;
};

export const CustomerName: React.FC<Props> = React.memo(({ listMode, guest, cardOptionsStatus }) => {
  const {
    displayCustomerName,
    isBanned,
  } = useCustomerName({ guest, cardOptionsStatus });

  return (
    <InfoContainer listMode={listMode} gridArea={'1/1'}>
      {displayCustomerName && (
        <NameText isBanned={isBanned} data-testid='order-card_customer-name_p'>
          {guest.FullName}
        </NameText>
      )}
    </InfoContainer>
  );
});

const NameText = styled.div<{ isBanned: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isBanned }) => (isBanned ? colors.dutchie.flora : colors.dutchie.almostBlack)};
  font-weight: bold;
  font-size: 18px;
  width: 250px;
  line-height: 18px;
  gap: 8px;
`;
