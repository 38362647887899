import React, { FC, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import match from 'match-sorter';
import * as DOMPurify from 'dompurify';

import { JournalForm } from 'components/inputs/Journal/JournalForm';
import { SearchBar } from 'components/layout/SearchBar';
import { getCustomerJournal, createUpdateJournalEntry } from 'store/actions/CustomerActions';
import { Column, Table } from 'components/tables';
import { JournalEntry } from 'models/Journal';
import { State } from 'store';
import { SearchResults as _SearchResults } from 'components/layout/SearchResults';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

const SearchResults = styled(_SearchResults)`
  height: 12.5rem;
`;

const EditIconSvg = styled(EditIcon)`
  width: 16px;
  margin-right: 8px;
`;

const columns: Column<JournalEntry>[] = [
  { header: '', Cell: () => <EditIconSvg />, disableSort: true, width: '20px' },
  { header: 'Subject', field: 'NoteSubject', disableSort: true },
  {
    header: 'Note',
    field: 'Note',
    disableSort: true,
    Cell: (params) => {
      const clean = DOMPurify.sanitize(params?.value);
      return <div dangerouslySetInnerHTML={{ __html: clean }} />;
    },
  },
  {
    header: 'Date',
    field: 'NoteDate',
    disableSort: true,
    Cell: ({ value }) => <>{format(new Date(value), 'MMM d, Y')}</>,
  },
  { header: 'Created by', disableSort: true, field: 'NoteUserName' },
];

const keysToSearch: Array<keyof JournalEntry> = ['Note', 'NoteSubject'];

type JournalProps = {
  className?: string;
  guestId?: number;
  disabled?: boolean;
  rows?: number;
};

export const Journal: FC<JournalProps> = ({ className, guestId, disabled, rows }) => {
  const { entries, loading } = useSelector((state: State) => state.customer.journal);
  const [selected, setSelected] = useState<JournalEntry | undefined>();
  const [displayEntries, setDisplayEntries] = useState<Array<JournalEntry>>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (guestId) {
      dispatch(getCustomerJournal(guestId));
    }
  }, [dispatch, guestId]);

  useEffect(() => {
    if (guestId) {
      setDisplayEntries(entries);
    }
  }, [entries, guestId]);

  const onSubmit = (subject: string, note: string) => {
    if (guestId) {
      const cleanNote = DOMPurify.sanitize(note);

      dispatch(
        createUpdateJournalEntry({
          guestId,
          noteId: selected?.NoteId || 0,
          note: cleanNote,
          subject,
        })
      );
      setSelected(undefined);
    }
  };

  const search = useCallback(
    debounce(async (query: string) => {
      if (query.length) {
        const results = match(entries, query, { keys: keysToSearch });
        setDisplayEntries(results);
      } else {
        setDisplayEntries(entries);
      }
    }, 300),
    [entries]
  );

  const handleSearchChange = (query: string) => search(query);

  return (
    <>
      <JournalForm
        disabled={!guestId || disabled}
        className={className}
        onSubmit={onSubmit}
        rows={rows}
        entry={selected}
      />
      <SearchBar onSearch={handleSearchChange} onChange={handleSearchChange} placeholder='Search...' maxWidth='100%' />
      <SearchResults
        loading={loading}
        isEmpty={!displayEntries.length}
        emptyMessage='There are no journal entries or they could not be retrieved'
      >
        <Table<JournalEntry> data={displayEntries} columns={columns} onClick={setSelected} />
      </SearchResults>
    </>
  );
};
