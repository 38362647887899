import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const DebitIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <rect x='0.75' y='2.25' width='22.5' height='19.5' rx='2.25' stroke='#828A8F' stroke-width='1.5' />
          <line x1='1' y1='5.75' x2='23' y2='5.75' stroke='#828A8F' stroke-width='1.5' />
          <path
            d='M12 8.5L7 12.5H17L12 8.5Z'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path d='M7 18.5H17' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M9 14.5V18.5' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M12 14.5V18.5' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M15 14.5V18.5' stroke='#828A8F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <rect y='1.5' width='24' height='21' rx='3' fill='#D7C7FA' />
        <rect x='0.75' y='2.25' width='22.5' height='19.5' rx='2.25' stroke='#743CEE' stroke-width='1.5' />
        <line x1='1' y1='5.75' x2='23' y2='5.75' stroke='#743CEE' stroke-width='1.5' />
        <path
          d='M12 8.5L7 12.5H17L12 8.5Z'
          stroke='#743CEE'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path d='M7 18.5H17' stroke='#743CEE' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M9 14.5V18.5' stroke='#743CEE' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M12 14.5V18.5' stroke='#743CEE' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M15 14.5V18.5' stroke='#743CEE' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      </g>
    </svg>
  );
};
