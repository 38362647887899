import { useAddItemToCart } from './useAddItemToCart';
import { useAddBulkItemToCart } from './useAddBulkItemToCart';
import { useRemoveItemFromCart } from './useRemoveItemFromCart';
import { useChangeCartQty } from '../useChangeCartQty';

import type { CartItem } from 'models/Cart';
import type { ChangeCartQtyParam } from '../useChangeCartQty';
import type { ProductSearchResult } from 'queries/v2/product/types';

type AddRemoveItemProps = ChangeCartQtyParam | CartItem | ProductSearchResult;

type AddWeightItemOverrides = {
  weight?: string | number;
  unitPrice?: number;
  totalCost?: number;
};

export const useCartItemActions = () => {
  const getChangeCartQtyParams = useChangeCartQty();

  const addItemToCart = useAddItemToCart();

  const addItemToCartWrapper = async (props: AddRemoveItemProps, count: number) => {
    const product = getChangeCartQtyParams(props);
    await addItemToCart({
      batchId: product.batchId,
      cannabisInventory: product.cannabisInventory,
      count,
      defaultLabelId: product.defaultLabelId,
      defaultUnitId: product.defaultUnitId,
      productDescription: product.productDescription,
      productId: product.productId,
      productType: product.productType,
      recUnitPrice: product.recUnitPrice,
      serialNo: product.serialNumber,
      unitPrice: product.unitPrice,
    });
  };

  const addBulkItemToCart = useAddBulkItemToCart();

  // Need the overrides since the BulkCalculatorPopup uses other values than the rest of the code
  const addBulkItemToCartWrapper = async (
    props: AddRemoveItemProps,
    actualWeight: string | number,
    overrides?: AddWeightItemOverrides
  ) => {
    const product = getChangeCartQtyParams(props);
    await addBulkItemToCart({
      batchId: product.batchId,
      defaultLabelId: product.defaultLabelId,
      cannabisInventory: product.cannabisInventory,
      productDescription: product.productDescription,
      productId: product.productId,
      recUnitPrice: product.recUnitPrice,
      serialNo: product.serialNumber,
      totalCost: overrides?.totalCost,
      unitPrice: overrides?.unitPrice ?? product.unitPrice,
      actualWeight,
      weightOverride: overrides?.weight,
    });
  };

  const removeItemFromCart = useRemoveItemFromCart();

  const removeItemFromCartWrapper = async (props: AddRemoveItemProps, count: number) => {
    const product = getChangeCartQtyParams(props);
    await removeItemFromCart({
      count,
      inventoryId: product.inventoryId,
      productId: product.productId,
      serialNo: product.serialNumber,
    });
  };

  return {
    addItemToCart: addItemToCartWrapper,
    addBulkItemToCart: addBulkItemToCartWrapper,
    removeItemFromCart: removeItemFromCartWrapper,
  };
};
