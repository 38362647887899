import { CheckedInGuest } from 'models/Guest';
import { hasLength } from 'util/Helpers';
import { variations } from 'components/misc/Pill';

type GetPillsParams = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
};
type CardPill = {
  label: string;
  variation: keyof typeof variations;
  key: string;
};
type CardPills = CardPill[];

const getPills = ({ guest, cardOptionsStatus }: GetPillsParams): CardPills => {
  const pills: CardPills = [];

  // Register pill
  const displayRegister = cardOptionsStatus['Register'] && hasLength(guest.Register);
  if (displayRegister) {
    pills.push({
      label: guest.Register,
      variation: 'grey',
      key: 'register-pill',
    });
  }

  // Banned pill
  const displayBannedCustomer = guest?.GuestStatus?.toLowerCase() === 'banned';
  if (displayBannedCustomer) {
    pills.push({
      label: 'Banned customer',
      variation: 'red',
      key: 'banned-customer-pill',
    });
  }

  // New customer
  const displayNewCustomer = cardOptionsStatus['New vs. Existing Customer'] && guest.IsNewCustomer;
  if (displayNewCustomer) {
    pills.push({
      label: 'New customer',
      variation: 'blue',
      key: 'new-customer-pill',
    });
  }

  return pills;
};

type UseCardPillsParams = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
};
type UseCardPillsReturn = {
  pills: CardPills;
  displayPillSection: boolean;
};
export const useCardPills = ({ guest, cardOptionsStatus }: UseCardPillsParams): UseCardPillsReturn => {
  const pills = getPills({ guest, cardOptionsStatus });
  const displayLoyaltyBadge = cardOptionsStatus['Loyal vs. Non-loyal'] && guest.IsLoyaltyMember;
  const displayPillSection = !!pills.length || displayLoyaltyBadge;

  return {
    pills,
    displayPillSection,
  };
};
