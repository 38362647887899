import React from 'react';

type CustomersIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function CustomersIcon(props: CustomersIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0008 1.4498C5.72646 1.4498 1.45078 5.72549 1.45078 10.9998C1.45078 16.2741 5.72646 20.5498 11.0008 20.5498C16.2751 20.5498 20.5508 16.2741 20.5508 10.9998C20.5508 5.72549 16.2751 1.4498 11.0008 1.4498ZM0.0507812 10.9998C0.0507812 4.95229 4.95326 0.0498047 11.0008 0.0498047C17.0483 0.0498047 21.9508 4.95229 21.9508 10.9998C21.9508 17.0473 17.0483 21.9498 11.0008 21.9498C4.95326 21.9498 0.0507812 17.0473 0.0507812 10.9998Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.38449 6.13351C9.07837 5.43963 10.0195 5.0498 11.0008 5.0498C11.9821 5.0498 12.9232 5.43963 13.6171 6.13351C14.311 6.82739 14.7008 7.7685 14.7008 8.7498C14.7008 9.73111 14.311 10.6722 13.6171 11.3661C12.9232 12.06 11.9821 12.4498 11.0008 12.4498C10.0195 12.4498 9.07837 12.06 8.38449 11.3661C7.6906 10.6722 7.30078 9.73111 7.30078 8.7498C7.30078 7.7685 7.6906 6.82739 8.38449 6.13351ZM11.0008 6.4498C10.3908 6.4498 9.80577 6.69213 9.37444 7.12346C8.9431 7.55479 8.70078 8.13981 8.70078 8.7498C8.70078 9.3598 8.9431 9.94482 9.37444 10.3762C9.80577 10.8075 10.3908 11.0498 11.0008 11.0498C11.6108 11.0498 12.1958 10.8075 12.6271 10.3762C13.0585 9.94482 13.3008 9.3598 13.3008 8.7498C13.3008 8.13981 13.0585 7.55479 12.6271 7.12346C12.1958 6.69213 11.6108 6.4498 11.0008 6.4498Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00262 13.5052C8.878 12.8834 9.92516 12.5493 10.9989 12.5493C12.0727 12.5493 13.1198 12.8834 13.9952 13.5052C14.8706 14.127 15.5308 15.0058 15.8844 16.0196C16.0117 16.3847 15.8189 16.7838 15.4539 16.9111C15.0889 17.0384 14.6897 16.8457 14.5624 16.4806C14.3046 15.7411 13.823 15.1001 13.1844 14.6465C12.5459 14.193 11.7821 13.9493 10.9989 13.9493C10.2157 13.9493 9.45189 14.193 8.81337 14.6465C8.17485 15.1001 7.69327 15.7411 7.43537 16.4806C7.30807 16.8457 6.90896 17.0384 6.54392 16.9111C6.17888 16.7838 5.98615 16.3847 6.11345 16.0196C6.46701 15.0058 7.12725 14.127 8.00262 13.5052Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.7519 17.7529C18.0253 17.4795 18.4685 17.4795 18.7418 17.7529L23.7439 22.7549C24.0172 23.0282 24.0172 23.4715 23.7439 23.7448C23.4705 24.0182 23.0273 24.0182 22.7539 23.7448L17.7519 18.7428C17.4785 18.4695 17.4785 18.0262 17.7519 17.7529Z'
        fill={color}
      />
    </svg>
  );
}
