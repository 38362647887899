import { useMutation, useQueryClient } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { clearCartFromRQ } from 'store/actions/CartActions';
import { getAllProductsKeys } from '../product/query-key-factory';
import { getCartDetailsKeys } from './query-key-factory';
import { logger } from 'util/logger';
import { useAppDispatch, useAppSelector } from 'util/hooks';

const CLEAR_CART = 'v2/cart/clear';

export type ClearCartPayload = {
  shipmentId: number;
};

const transformToServerPayload = (payload: ClearCartPayload) => {
  return {
    ShipmentId: payload.shipmentId,
  };
};

export const useClearCartMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const guestId = useAppSelector((state) => state.customer.details?.Guest_id);

  return useMutation(
    async (payload: ClearCartPayload) => {
      if (!payload.shipmentId) {
        throw new Error('ShipmentId is required to clear the cart');
      }
      await post(CLEAR_CART, transformToServerPayload(payload));

      // Temporary: Clear the cart in redux
      dispatch(clearCartFromRQ());

      return;
    },
    {
      onError: (error) => {
        logger.error(error, { message: 'There was an error clearing the cart' });
      },
      onSettled: (_data, _error, payload) => {
        // Invalidate the products list to reset quanitities
        queryClient.invalidateQueries(getAllProductsKeys.all);
        // Invalidate the cart details to refetch the cart
        queryClient.invalidateQueries(
          getCartDetailsKeys.one({
            guestId: guestId ?? 0,
            shipmentId: payload.shipmentId,
          })
        );
      },
    }
  );
};
