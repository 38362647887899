import { ConnectionType, LabelPrinter } from '@dutchie/capacitor-hardware';
import type { MockPeripheralConfig, MockedEvent, MockedPeripheralIdEvent } from '../../shared/mock/types';

export class MockLabelPrinter extends LabelPrinter {
  private _config: MockPeripheralConfig;
  private _connected = false;

  constructor(config: MockPeripheralConfig) {
    super(config.connectionType ?? ConnectionType.other);
    this._config = config;
  }

  get isConnected(): boolean {
    return this._connected;
  }

  get id(): string {
    return this._config.id;
  }

  get name(): string {
    return this._config.name;
  }

  get metadata() {
    return this._config.metadata ?? {};
  }

  async doConnect(): Promise<boolean> {
    this._connected = true;
    return this._connected;
  }

  async doDisconnect(): Promise<boolean> {
    this._connected = false;
    return !this._connected;
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    const eventData: MockedEvent<MockedPeripheralIdEvent & { bytes: Uint8Array }> = {
      eventId: Math.random().toString(36),
      data: { id: this.id, bytes },
    };

    window.dispatchEvent(new CustomEvent(`${this._config.serviceName}.print`, { detail: eventData }));
    return true;
  }
}
