import styled, { css } from 'styled-components';
import { ReactComponent as SweetGreenCheckmarkIcon } from './SweetGreenCheckmark.svg';
import { Popup } from 'components/popups';
import { colors, popupWidth } from 'css/Theme';
import { Button } from 'components/buttons';
import { Input, Select } from 'components/inputs';

export const StickyFooter = css`
  margin-top: 0;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-radius: 0 0 20px 20px;
`;

type ButtonsStyleProps = {
  sticky?: boolean;
  reverse?: boolean;
};

export const Buttons = styled.div<ButtonsStyleProps>`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  align-items: right;
  width: 100%;
  box-shadow: 0px -1px 0px ${colors.dutchie.shadowGrey};
  ${({ sticky }) => (sticky ? StickyFooter : null)}
`;

export const CancelButton = styled(Button)`
  background-color: ${colors.dutchie.backgroundGrey};
  border: none;
  color: ${colors.dutchie.almostBlack};
  margin-right: 1rem;
  ${({ styles }) => styles};
`;

export const ConfirmButton = styled(Button)`
  ${({ styles }) => styles};
`;

export const CancelIntegratedPaymentTransactionBase = styled(Popup)`
  width: 600px;
  max-width: ${popupWidth.medium};
`;

export const BackButton = styled.button`
  font-size: 1rem;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: ${colors.white};
  border: none;
  cursor: pointer;
  margin-right: auto;
  padding: 0;
`;

export const CancelContainerCenter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  overflow: auto;
  padding: 0 24px;
`;

export const SpinnerContainer = styled.div`
  padding: 31px 0px 40px 0px;
  gap: 49px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CancelContainerLeft = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: auto;
  padding: 0 24px;
  gap: 24px;
`;

export const SubjectInput = styled(Input)`
  width: 100%;
  height: 48px;
  padding-left: 20px;
  border: 1px solid ${colors.dutchie.borderGrey};
`;

export const LeftConent = styled.div`
  align-items: left;
`;

export const GreyHeading = styled.p`
  font-size: 18px;
  line-height: 1.5rem;
  text-align-last: center;
  color: ${colors.dutchie.darkGrey};
`;

export const GreySubHeading = styled.p`
  font-size: 16px;
  line-height: 1.5rem;
  text-align-last: center;
  color: ${colors.dutchie.darkGrey};
`;

export const Message = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.dutchie.almostBlack};
`;

export const Red = styled.span`
  color: ${colors.dutchie.red};
`;

export const CancelSelect = styled(Select)`
  width: 100%;
  margin: 1.5rem 0 0;
`;

export const SweetGreenCheckmark = styled(SweetGreenCheckmarkIcon)`
  margin-bottom: 16px;
`;

export const ConfrimRightButton = styled(ConfirmButton)`
  margin-left: auto;
`;

export const MessageContainer = styled.div`
  /* Frame 9 */

  margin: auto;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 520px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const SuccessMessageContainer = styled(MessageContainer)`
  margin: 0px auto 24px auto;
`;

export const MessageContent = styled.div`
  /* Frame 3173 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 520px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;

export const MessageHeader = styled.div`
  /* Cancel order */

  margin: auto;

  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 30px;
  /* identical to box height, or 94% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Greyscale/Black */

  color: #171a1c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
