import styled, { CSSObject } from 'styled-components';

export const ValidityErrorMessage = styled.div<{ $overrides?: CSSObject }>(({ $overrides }) => ({
  font: 'var(--font-small-13pt-semibold)',
  borderRadius: '6px',
  backgroundColor: 'var(--color-additional-colors-red-95)',
  color: 'var(--color-flora-shades-flora-46)',
  padding: 'var(--sizes-40) var(--sizes-60)',
  marginTop: 'var(--sizes-50)',
  width: '536px',
  ul: {
    listStylePosition: 'inside',
  },
  ...$overrides,
}));
