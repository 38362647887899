import React from 'react';
import { Switch } from 'components/inputs';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useDisplaySettingsTab } from './useDisplaySettingsTab';

export const DisplaySettingsTab = () => {
  return (
    <SettingsCard automationId='display-settings-card'>
      <ShowPopupNotesListItem />
      <SettingsDivider />
      <SearchInGuestListListItem />
      <SettingsDivider />
      <ShowAssignedGuestsListItem />
    </SettingsCard>
  );
};

const SearchInGuestListListItem = () => {
  const { showSearch, setShowSearch } = useDisplaySettingsTab();
  return (
    <SettingsListItem
      title='Show search in guest list'
      actions={[
        <Switch
          automationId='display_tab-search_guestlist'
          containerMargin='0'
          checked={showSearch}
          onChange={setShowSearch}
        />,
      ]}
    />
  );
};

const ShowAssignedGuestsListItem = () => {
  const { showGuests, setShowGuests } = useDisplaySettingsTab();
  return (
    <SettingsListItem
      title='Hide orders assigned to other registers'
      actions={[
        <Switch
          automationId='display_tab-show_assigned_guests'
          containerMargin='0'
          checked={showGuests}
          onChange={setShowGuests}
        />,
      ]}
    />
  );
};

const ShowPopupNotesListItem = () => {
  const { showPopupNotes, setShowPopupNotes } = useDisplaySettingsTab();
  return (
    <SettingsListItem
      title='Pop up notes when entering cart'
      actions={[
        <Switch
          automationId='display_tab-show_popup_notes'
          containerMargin='0'
          checked={showPopupNotes}
          onChange={setShowPopupNotes}
        />,
      ]}
    />
  );
};
