import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { DebitWarning } from '../payments/DebitWarning';
import { DutchiePayInStorePaymentTypeButton } from '../DutchiePayInStorePaymentTypeButton';
import { DutchiePayLogo } from 'assets/DutchiePayLogo';
import { PaymentButton, PaymentButtonType } from './components/PaymentButton';
import { PaymentType } from 'models/Checkout';
import { usePaymentButtons } from './usePaymentButtons';
import { usePaymentsHubFeatureSwitch } from 'util/hooks/usePaymentsHubFeatureSwitch';
import { PaymentsHubUnavailableWarning } from '../payments/PaymentsHubWarning';
import { GeneriPayPaymentIntegration } from 'queries/v2/payments/generipay';

type PaymentButtonsProps = {
  handleClick: (paymentType: PaymentType, paymentTypeMetaData: GeneriPayPaymentIntegration | null) => () => void;
};

export const PaymentButtons: FC<PaymentButtonsProps> = ({ handleClick }) => {
  const {
    cashButtonRef,
    creditButtonLabel,
    handleClickLoyaltyButton,
    isCheckEnabled,
    isCreditButtonDisabled,
    isCreditEnabled,
    isDebitDisabled,
    isDebitEnabled,
    isDebitProcessorUSAG,
    isDigitalEnabled,
    isGiftCardEnabled,
    isLoyaltyPaymentEnabled,
    isMMAPEnabled,
    isPreAuthOrder,
    numberOfEnabledPaymentMethods,
    showDutchiePayInStoreButton,
    showDutchiePayPreAuthCheckout,
    useVerticalButtons,
    isPaymentsHubEnabled,
    showManualPayments,
    isManualPaymentButtonDisabled,
    isGeneriPayEnabled,
    generiPayButtons,
    isPaysolEnabled,
    paysolDebitButtonEnabled,
  } = usePaymentButtons();

  const isPaymentsHubFeatureSwitchEnabled = usePaymentsHubFeatureSwitch();

  // ENG-62820 -- for compliance reasons, we need to change the name of this
  // button to be something other than `Debit` for USAG payments, but for product
  // reasons we need to leave the name as `Debit` for those using this button for
  // non integrated payments. We use `Pay with Hub` instead of `Hub` to differentiate
  // this button from the existing payments hub button. This will be deprecated once
  // payments hub is more widely adopted and we eliminate non-hub USAG "debit" payments.
  const debitSectionName = () => {
    if (isDebitEnabled && isDebitProcessorUSAG) {
      return 'Terminal';
    }
    return 'Debit';
  };

  return (
    <>
      <Container>
        {!isPaymentsHubFeatureSwitchEnabled && <PaymentsHubUnavailableWarning />}
        {isDebitDisabled && <DebitWarning />}
        {!isPreAuthOrder && (
          <ButtonContainer useVerticalButtons={useVerticalButtons} numberOfButtons={numberOfEnabledPaymentMethods}>
            <PaymentButton
              ref={cashButtonRef}
              paymentButtonType={PaymentButtonType.CASH}
              label='Cash'
              onClick={handleClick(PaymentType.Cash, null)}
            />
            {paysolDebitButtonEnabled ? (
              <PaymentButton
                paymentButtonType={PaymentButtonType.DEBIT}
                label={debitSectionName()}
                onClick={handleClick(PaymentType.Debit, null)}
                /* hide debit button under the following conditions:
                  1. Checkout debit flag is disabled (FFId: 33)
                  2. Payments hub (Integration flag) is enabled and Paysol/SPin is disabled (FFId: 163)
                    a. Paysol is currently not integrated with hub, but if that ever changes this check can be updated
                */
                hide={!isDebitEnabled || (isPaymentsHubEnabled && !isPaysolEnabled)}
                disabled={isDebitDisabled}
              />
            ) : (
              <PaymentButton
                paymentButtonType={PaymentButtonType.DEBIT}
                label={debitSectionName()}
                onClick={handleClick(PaymentType.Debit, null)}
                hide={isPaymentsHubEnabled || !isDebitEnabled}
                disabled={isDebitDisabled}
              />
            )}
            <PaymentButton
              disabled={isCreditButtonDisabled}
              paymentButtonType={PaymentButtonType.CARD}
              label={creditButtonLabel}
              onClick={handleClick(PaymentType.Credit, null)}
              hide={!isCreditEnabled}
            />
            <PaymentButton
              disabled={!isPaymentsHubFeatureSwitchEnabled}
              paymentButtonType={PaymentButtonType.HUB}
              label='Dutchie Hub'
              onClick={handleClick(PaymentType.Hub, null)}
              hide={!isPaymentsHubEnabled}
            />
            <PaymentButton
              paymentButtonType={PaymentButtonType.MMAP}
              label='MMAP'
              onClick={handleClick(PaymentType.MMAP, null)}
              hide={!isMMAPEnabled}
            />
            <PaymentButton
              paymentButtonType={PaymentButtonType.DIGITAL}
              label='Digital'
              onClick={handleClick(PaymentType.Digital, null)}
              hide={!isDigitalEnabled}
            />
            <PaymentButton
              paymentButtonType={PaymentButtonType.CHECK}
              label='Check'
              onClick={handleClick(PaymentType.Check, null)}
              hide={!isCheckEnabled}
            />
            <PaymentButton
              paymentButtonType={PaymentButtonType.GIFTCARD}
              label='Gift Card'
              onClick={handleClick(PaymentType['Gift Card'], null)}
              hide={!isGiftCardEnabled}
            />
            <PaymentButton
              paymentButtonType={PaymentButtonType.LOYALTY}
              label='Loyalty'
              onClick={handleClickLoyaltyButton}
              hide={!isLoyaltyPaymentEnabled}
            />
            <PaymentButton
              paymentButtonType={PaymentButtonType.MANUAL}
              label='Manual'
              onClick={handleClick(PaymentType.Manual, null)}
              disabled={isManualPaymentButtonDisabled}
              hide={!showManualPayments}
            />
            {generiPayButtons &&
              generiPayButtons?.length > 0 &&
              generiPayButtons?.map((gPayButton) => (
                <PaymentButton
                  paymentButtonType={PaymentButtonType.GENERIPAY}
                  label={gPayButton.PosButtonText}
                  onClick={handleClick(PaymentType.GeneriPay, gPayButton)}
                  disabled={!isGeneriPayEnabled}
                  hide={!isGeneriPayEnabled}
                />
              ))}
          </ButtonContainer>
        )}
        {showDutchiePayPreAuthCheckout && (
          <DutchiePayCard data-testid='payment-buttons_payment-type-button_dutchie'>
            <LogoContainer>
              <DutchiePayLogo height={18} width={101} />
            </LogoContainer>
            This order was paid for using DutchiePay. No payment selection is needed. To complete this transaction,
            simply click the "<strong>Complete order</strong>" button below.
          </DutchiePayCard>
        )}
      </Container>
      {showDutchiePayInStoreButton && <DutchiePayInStorePaymentTypeButton handleClick={handleClick} />}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const ButtonContainer = styled.div<{
  useVerticalButtons?: boolean;
  numberOfButtons: number;
}>`
  width: 100%;
  display: flex;
  gap: 0.75rem;
  flex-wrap: ${({ numberOfButtons }) => (numberOfButtons > 3 ? 'wrap' : 'nowrap')};

  & > button {
    ${({ useVerticalButtons }) =>
      useVerticalButtons
        ? css`
            flex-direction: column;
          `
        : css`
            gap: 0.75rem;

            label {
              text-align: left;
            }
          `}

    ${({ numberOfButtons }) => {
      switch (numberOfButtons) {
        case 4:
          return css`
            width: 48%;
          `;
        case 5:
        case 6:
          return css`
            width: 31%;
          `;
        case 7:
        case 8:
          return css`
            width: 23%;
          `;
        case 1:
        case 2:
        case 3:
        default:
          return css`
            flex: 1;
          `;
      }
    }}
  }
`;

const Card = styled.div`
  width: 420px;
  border: 1px solid #d8dee3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  padding: 30px 44.5px;
  margin-top: 1rem;
`;

const DutchiePayCard = styled(Card)`
  background-color: #ebf8f5;
  border: none;
  border-radius: 6px;
  flex: 1;
`;

const LogoContainer = styled.div`
  padding: 5px;
`;
