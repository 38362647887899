import React, { FC, useEffect, useState } from 'react';
import { SectionHeader, SelectContainer, RefreshButton } from '../SettingPageComponents';
import { Loader } from 'components/backoffice/loader';
import { Select } from 'components/inputs';
import { Label } from 'components/text';
import { Box } from 'components/layout';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { useBluetoothScannerPicker } from './useBluetoothScannerPicker';

type BarCodeProps = {
  scannerConfigureFn: () => Promise<{ url: string; base64: string }>;
  imgAlt: string;
  label: string;
};

const BarCode: FC<BarCodeProps> = React.memo(({ scannerConfigureFn, imgAlt, label }) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    scannerConfigureFn().then((data) => {
      setUrl(data.url);
    });
  }, [scannerConfigureFn]);

  if (url == null) {
    return null;
  }

  return (
    <Box>
      <Label>{label}</Label>
      <img alt={imgAlt} src={url} width={312} height={77} />
    </Box>
  );
});

export const BluetoothScannerPicker: FC = () => {
  const {
    scannerId,
    scannerDropdownOptions,
    handleScannerChange,
    searchScanners,
    getFactoryResetBarcode,
    getConfigurationBarcode,
    loadingScanners,
  } = useBluetoothScannerPicker();

  return (
    <Box maxWidth='1000px'>
      <SectionHeader>
        Scanner
        <RefreshButton onClick={() => searchScanners({ userInitiated: true })} />
      </SectionHeader>
      <ColsContainer>
        <Box>
          {!loadingScanners && (
            <SelectContainer>
              <Label>Barcode Scanner</Label>
              <Select
                placeholder='Select option'
                defaultValue={scannerId}
                onChange={handleScannerChange}
                options={scannerDropdownOptions}
              />
            </SelectContainer>
          )}
          {loadingScanners && <Loader variant='black' />}
          {!!scannerId && (
            <Box>
              <ScannerConnectedIndicator>Connected</ScannerConnectedIndicator>
            </Box>
          )}
          {!scannerDropdownOptions.length && (
            <Box marginTop='20px'>
              <p>Connection Instructions</p>
              <InstructionsList>
                <li>Make sure the cradle is detatched from the tablet and unplugged/unpowered.</li>
                <li>Remove the scanner from the cradle.</li>
                <li>Attach the cradle to the tablet via USB.</li>
                <li>Scan Return to Factory Defaults barcode (Seen above or on page 5 of the booklet).</li>
                <li>Place the scanner in the cradle. Wait for the blue Bluetooth light to stop flashing.</li>
                <li>
                  Remove the scanner from the cradle. Tapping the button with three squares should show a light and the
                  aim assist (green dot) but the Bluetooth light should not illuminate.
                </li>
                <li>
                  Scan the Configuration barcode (seen above or on page 15 in the booklet called SNAPI with Imaging).
                </li>
              </InstructionsList>
            </Box>
          )}
        </Box>
        <Box>
          <BarCode label='Factory Reset Barcode' imgAlt='reset barcode' scannerConfigureFn={getFactoryResetBarcode} />
          <Box marginTop='36px' />
          <BarCode
            label='Configuration Barcode'
            imgAlt='pairing barcode'
            scannerConfigureFn={getConfigurationBarcode}
          />
        </Box>
      </ColsContainer>
    </Box>
  );
};

const InstructionsList = styled.ol`
  padding-inline-start: 24px;
  margin-top: 1rem;
`;

const ColsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8rem;
`;

const ScannerConnectedIndicator = styled.p`
  color: ${colors.dutchie.moss};
  font-weight: 600;
  font-size: 13px;

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background-color: ${colors.dutchie.moss};
  }
`;
