import { useMemo } from 'react';
import { CartItem } from 'models/Cart';
import { AppliedDiscount } from 'models/Discounts';

export const useApplicableItemDiscounts = (cartItem?: CartItem, discounts?: AppliedDiscount[]) => {
  return useMemo(() => {
    if (!cartItem || !discounts) {
      return [];
    }
    return discounts.filter((discount) => cartItem.RowInventoryIds?.includes(discount.InvId));
  }, [cartItem, discounts]);
};
