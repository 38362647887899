import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';
import { Container, StyledLinkButton } from '../TotalsSidebarSection.styles';
import { MedicalIcon } from 'assets/icons/MedicalIcon';
import { RecreationalIcon } from 'assets/icons/RecreationalIcon';
import { useCustomerType, CustomerTypeBadgeVariations } from './useCustomerType';

export const CustomerType = () => {
  const { customerType, customerTypeDescription, handleClickChange, hideCustomerTypeSection, showChangeButton } =
    useCustomerType();

  if (hideCustomerTypeSection) {
    return null;
  }

  return (
    <Container showBottomBorder data-testid='customer-type-section'>
      <Header>
        <CustomerTypeBadge
          automationId='customer-type-section_customer-type-badge'
          customerType={customerType}
          customerTypeDescription={customerTypeDescription}
        />
        {showChangeButton && (
          <StyledLinkButton label='Change' onClick={handleClickChange} ddActionName='change customer type button' />
        )}
      </Header>
    </Container>
  );
};

type CustomerTypeBadgeProps = {
  automationId?: string;
  customerType: CustomerTypeBadgeVariations;
  customerTypeDescription?: string;
};

export const CustomerTypeBadge = ({ automationId, customerType, customerTypeDescription }: CustomerTypeBadgeProps) => {
  return (
    <TypeBadge variation={customerType} data-testid={automationId}>
      {customerType === CustomerTypeBadgeVariations.MEDICAL ? (
        <MedicalIcon width={12} height={12} fill={colors.dutchie.primaryWhite} />
      ) : (
        <RecreationalIcon width={12} height={12} fill={colors.dutchie.primaryWhite} strokeWidth={1.1} />
      )}
      {customerTypeDescription ?? customerType}
    </TypeBadge>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
`;

const TypeBadge = styled.div<{ variation?: CustomerTypeBadgeVariations }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0.125rem;

  color: ${colors.dutchie.primaryWhite};
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.125rem;
  text-transform: uppercase;

  ${({ variation }) => {
    switch (variation) {
      case CustomerTypeBadgeVariations.MEDICAL:
        return css`
          background: ${colors.dutchie.blue80};
        `;
      case CustomerTypeBadgeVariations.RECREATIONAL:
      default:
        return css`
          background: ${colors.dutchie.gray80};
        `;
    }
  }}
`;
