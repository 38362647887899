import React, { FC } from 'react';
import { SectionGridContainer, SectionHeader, SectionContent } from '../SettingPageComponents';
import { Switch } from 'components/inputs';
import { isAndroid } from 'util/hooks';
import { useReceiptPrinterPicker } from './hooks/useReceiptPrinterPicker';
import { HardwareLibraryReceiptPrinterDropdown } from './HardwareLibraryReceiptPrinterDropdown';

export const ReceiptPrinterPicker: FC = () => {
  const { handleSwitchAutoPrint, handleSwitchPrinterTypes, isAutoPrintEnabled, isLocal } = useReceiptPrinterPicker();

  return (
    <SectionGridContainer>
      <SectionHeader>Receipt Printers</SectionHeader>
      <SectionContent>
        <Switch label='Auto Print Receipts' onChange={handleSwitchAutoPrint} checked={isAutoPrintEnabled} />
        {isAndroid && (
          <Switch label='Use Local Receipt Printer' onChange={handleSwitchPrinterTypes} checked={isLocal} />
        )}
        <HardwareLibraryReceiptPrinterDropdown printerType='receipt' connectionType={isLocal && isAndroid ? 'local' : 'network'} />
      </SectionContent>
    </SectionGridContainer>
  );
};

