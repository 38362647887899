import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import React from 'react';

import { useLabelPrinterSettings } from '../hooks/useLabelPrinterSettings';
import { Switch } from 'components/inputs';

export const LabelPrinterAutoPrintListItem = () => {
  const { formValues, setAutoPrintLabels } = useLabelPrinterSettings();

  return (
    <SettingsListItem
      title='Auto-print labels'
      subtitle='When enabled, all labels will print automatically'
      actions={[
        <Switch
          automationId='label_printer_settings-auto_print_switch'
          checked={formValues.autoPrintLabels}
          containerMargin='0'
          onChange={() => setAutoPrintLabels(!formValues.autoPrintLabels)}
        />,
      ]}
    />
  );
};
