import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { TimeWindowFilterMetaData } from 'pages/DeliveryPage';

export type NullableDate = Date | null | undefined;

export type TimeWindowState = [NullableDate, NullableDate] | [];

type UseTimeWindowFilterStateReturn = {
  endDate: NullableDate;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindowState>>;
  setTimeWindowValidationError: React.Dispatch<React.SetStateAction<{ start: boolean; end: boolean }>>;
  startDate: NullableDate;
  timeWindowValidationError: { start: boolean; end: boolean };
};

type UseTimeWindowFilterStateArgs = {
  timeWindowFilterMetaData: TimeWindowFilterMetaData | undefined;
};

export function useTimeWindowFilterState({
  timeWindowFilterMetaData,
}: UseTimeWindowFilterStateArgs): UseTimeWindowFilterStateReturn | null {
  const timeWindowForFilter = useSelector((state: State) => state.deliveryList.timeWindowForFilter);
  const [[startDate, endDate], setTimeWindow] = useState<TimeWindowState>(timeWindowForFilter ?? []);
  const [timeWindowValidationError, setTimeWindowValidationError] = useState({ start: false, end: false });

  const startTimeFromStore = timeWindowForFilter?.[0];
  const endTimeFromStore = timeWindowForFilter?.[1];
  const shouldResetTimeWindow = timeWindowFilterMetaData?.shouldResetTimeWindow;
  const setShouldResetTimeWindow = timeWindowFilterMetaData?.setShouldResetTimeWindow;

  useEffect(() => {
    setTimeWindow(startTimeFromStore && endTimeFromStore ? [startTimeFromStore, endTimeFromStore] : []);
    setTimeWindowValidationError({ start: false, end: false });
    if (setShouldResetTimeWindow) {
      setShouldResetTimeWindow(false);
    }
  }, [
    endTimeFromStore,
    setShouldResetTimeWindow,
    setTimeWindowValidationError,
    // this needs to be in this deps array bc we want to reset the time window to the value from redux state when this changes to true from cancel button
    shouldResetTimeWindow,
    startTimeFromStore,
  ]);

  if (!timeWindowFilterMetaData) {
    return null;
  }

  return {
    endDate,
    setTimeWindow,
    setTimeWindowValidationError,
    startDate,
    timeWindowValidationError,
  };
}
