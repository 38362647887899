import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import type { UseQueryOptions } from '@tanstack/react-query';

export type SegmentsQueryPayload = {
  customerId: number;
};

export type SegmentIcon = 'money' | 'heart';

export type Segment = {
  SegmentId: number;
  BadgeName: string;
  Color: string;
  Icon: SegmentIcon;
};

export type SegmentsQueryResponse = Segment[] | null;

export const useSegmentsQuery = (payload: SegmentsQueryPayload, options?: Partial<Omit<UseQueryOptions<SegmentsQueryResponse>, 'queryFn'>>) => {
  const { customerId } = payload;

  return useQuery({
    queryKey: ['segments', customerId],
    queryFn: async () => {
      const response = await post<SegmentsQueryResponse>(`personalization/${customerId}/segments`);

      return response;
    },
    ...options,
  });
};
