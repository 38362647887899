import React, { memo, useCallback } from 'react';
import { PaginationProps } from './common';
import { AdvancedPagination } from './AdvancedPagination';
import { CompactPagination } from './CompactPagination';
import { useNodeWidth } from 'util/hooks';

const MIN_ADVANCED_PAGINATION_WIDTH = 700;

export const Pagination = memo<PaginationProps>(({ containerEl, ...props }) => {
  const containerWidth = useNodeWidth(containerEl);
  const renderPaginationControl = useCallback(() => {
    if (!containerWidth) {
      return null;
    }

    return containerWidth > MIN_ADVANCED_PAGINATION_WIDTH ? (
      <AdvancedPagination {...props} />
    ) : (
      <CompactPagination {...props} />
    );
  }, [containerWidth, props]);

  return <div>{renderPaginationControl()}</div>;
});
