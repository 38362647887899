import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { compareAsc } from 'date-fns';
import styled from 'styled-components';

import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { verifyBirthdate } from 'api/CheckoutApi';
import { ConfirmationPopup } from 'components/popups';
import { DatePicker } from 'components/inputs';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

import type { callback, callback1 } from 'models/Misc';

const Container = styled.div`
  padding: 0 1.5rem;
`;

type VerifyBirthdatePopupProps = {
  hide: callback;
  onSuccess: callback1<Date | undefined>;
  shipmentId?: number;
  guestDob?: string;
  isAnonymous?: boolean;
  acctId?: number;
};

export const VerifyBirthdatePopup: FC<VerifyBirthdatePopupProps> = ({
  hide,
  onSuccess,
  guestDob,
  isAnonymous = true,
  shipmentId,
  acctId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dob, setDob] = useState<Date | undefined>();
  const [loading, setLoading] = useState(false);

  const isOnCartPage = history.location.pathname.includes('/cart');
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  useEffect(() => {
    setDob(undefined);
  }, []);

  const validateDob = (value: Date | undefined) => {
    const guestDobDate = guestDob && new Date(guestDob);
    guestDobDate && guestDobDate.setHours(0, 0, 0, 0);

    if (isAnonymous) {
      return true;
    } else if (!value) {
      dispatch(errorNotification('Please provide a date of birth'));
      return false;
    } else if (!guestDob) {
      dispatch(errorNotification('No date of birth set on customer profile'));
      return false;
    } else if (value && guestDobDate && compareAsc(new Date(value), new Date(guestDobDate)) !== 0) {
      dispatch(errorNotification('Date of birth does not match customer profile'));
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = async () => {
    setLoading(true);

    if (!validateDob(dob)) {
      setLoading(false);
      return;
    }

    try {
      if (dob) {
        await verifyBirthdate({ Shipmentid: shipmentId, Birthdate: dob, AcctId: acctId });
      }
      hide();
      onSuccess(dob);
      dispatch(successNotification('Verified'));
    } catch (e) {
      dispatch(errorNotification(`Error: ${e}`));
    } finally {
      setLoading(false);
    }
  };

  const onCancel = async () => {
    hide();
    // If the user is on the cart page when they cancel entering a birthdate,
    // redirect them back to the guest list
    if (isOnCartPage && isAnonymousCartLDFlagEnabled) {
      history.push('/guestlist');
    }
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={onCancel}
      title='Enter Birthdate'
      confirm={{
        text: 'Verify',
        onClick: onSubmit,
        disabled: loading || !dob,
      }}
      automationId='verify-birthdate-modal'
    >
      <Container>
        <DatePicker selected={dob} onChange={(val) => setDob(val as Date)} />
      </Container>
    </ConfirmationPopup>
  );
};
