import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import * as api from 'api/TransactionsApi';
import { errorNotification } from 'store/actions/NotificationsActions';
import { State } from 'store';

export const loadTransactions = createAsyncThunk(
  'loadTransactions',
  async (body: api.TransactionsRequest, { dispatch, getState }) => {
    const { ldFlags } = getState() as State;

    const useTransactionHistoryV2 = ldFlags[
      'growth.partners-and-delivery.use-new-transaction-history-controller'
    ] as boolean;

    if (useTransactionHistoryV2) {
      return api.getTransactionsV2(body).catch(() => {
        dispatch(errorNotification('Could not load transactions list'));
        return Promise.reject();
      });
    } else {
      return api.getTransactions(body).catch(() => {
        dispatch(errorNotification('Could not load transactions list'));
        return Promise.reject();
      });
    }
  }
);

export const loadSalesHistory = createAsyncThunk(
  'loadSalesHistory',
  async (body: api.SearchSalesHistoryRequest, { dispatch }) => {
    return api.searchSalesistory(body).catch(() => {
      dispatch(errorNotification('Could not load transactions list'));
      return Promise.reject();
    });
  }
);

export const includeCanceledTransactions = createAction<boolean>('includeCanceledTransactions');

export const getProductHistoryDetail = createAsyncThunk(
  'getProductHistoryDetail',
  async (args: api.TransactionHistoryRequest & { RegisterId?: number }, { dispatch }) => {
    return api.getProductHistoryDetail(args).catch(() => {
      dispatch(errorNotification('An error occurred while getting product history details'));
      return Promise.reject();
    });
  }
);

export const getTransactionPayment = createAsyncThunk(
  'getTransactionPayment',
  async (ShipmentId: number, { dispatch }) => {
    return api.getTransactionPayment({ ShipmentId }).catch(() => {
      dispatch(errorNotification('An error occurred while getting payment type details'));
      return Promise.reject();
    });
  }
);
