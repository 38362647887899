import React from 'react';
import styled from 'styled-components';

import { useAppDispatch } from 'util/hooks';
import { showCustomerInsightsPopup } from 'store/actions/PopupsActions/GuestListPopupsActions';

import { colors } from 'css/Theme';
import { ChevronIcon } from 'assets/icons/settings/ChevronIcon';
import { InfoContainer } from 'pages/GuestListPage/components/kanban/OrderKanbanCard/styles';

import type { MouseEventHandler } from 'react';
import type { CheckedInGuest } from 'models/Guest';

type CustomerInsightsProps = {
  guest: CheckedInGuest;
  listMode: boolean;
  onGoToCart: () => Promise<void>;
};

export function CustomerInsightsButton(props: CustomerInsightsProps) {
  const { guest, listMode, onGoToCart } = props;

  const dispatch = useAppDispatch();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    // prevent default guest card click behavior
    event.stopPropagation();
    dispatch(showCustomerInsightsPopup({
      customerId: guest.Guest_id,
      onGoToCart,
    }));
  };

  if (listMode || guest.IsAnonymous) {
    return null;
  }

  return (
    <InfoContainer listMode={listMode} gridArea='5/1' placeSelf='start'>
      <InsightsButton data-dd-action-name='guest card customer insights' onClick={handleClick}>
        <span>Customer insights</span>
        <ChevronIcon size={16} />
      </InsightsButton>
    </InfoContainer>
  );
}

const InsightsButton = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  padding: 0;
  background: none;
  border-top: 1px solid ${colors.dutchie.backgroundGrey};
  border-right: none;
  border-bottom: none;
  border-left: none;
  font-weight: 600;
  color: ${colors.dutchie.blue};
  cursor: pointer;
`;
