import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { useEffect } from 'react';
import { getExternalWebsites } from 'api/SettingsApi';
import { isAndroid } from 'util/hooks';
import { Overlay } from '@dutchie/capacitor-overlay';
import { setExternalSitesLoaded } from 'store/actions/SettingsActions';
import { useLoginImprovementsFlag } from 'util/hooks/launch-darkly/useLoginImprovementsFlag';

export const useExternalWebsites = () => {
  const selectedLocation = useSelector((state: State) => state.user.selectedLocation);
  const fullName = useSelector((state: State) => state.user.FullName);
  const isLoginImprovementsEnabled = useLoginImprovementsFlag();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadExternalSites = async () => {
      if (isAndroid) {
        const response = await getExternalWebsites();
        const overlayTabs = response.map((site) => {
          return { url: site, title: site };
        });
        if (overlayTabs.length > 0) {
          await Overlay.load({ sites: overlayTabs });
          dispatch(setExternalSitesLoaded(true));
        }
      }
    };
    if (isLoginImprovementsEnabled && !fullName) {
      return;
    }
    loadExternalSites();
  }, [selectedLocation, dispatch, fullName, isLoginImprovementsEnabled]);
};
