import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';

import type { PrintJobContext, PrintJobResultContext } from 'util/logger/types/printing';

import { calcCartTotal } from 'api/CartApi';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';

import { PrintJob, PrintStatus } from 'models/Printing';

import type { Delivery } from 'models/Delivery';
import {
  getStoredHardware,
  peripheralInfo,
  GetPrintJobResponse,
  processHardwarePrintJob,
  selectHardwarePrinterType,
} from 'util/hardwareLibrary/hardware-library-utils';
import { useAppDispatch } from 'util/hooks';
import { post } from 'api/HttpHelpers';
import { getIsPrintResultLoggingEnabled } from 'util/hooks/launch-darkly/getIsPrintResultLoggingEnabled';

type UsePrintDeliveryReceiptParams = {
  deliveries: Delivery[];
};

export const usePrintDeliveryReceipts = () => {
  const dispatch = useAppDispatch();

  return async ({ deliveries }: UsePrintDeliveryReceiptParams) => {
    const { receiptPrinter } = getStoredHardware();
    try {
      if (!receiptPrinter) {
        throw new Error('No printer selected');
      }

      dispatch(successNotification('Printing receipts...'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.PRINTING }));

      const requests = deliveries.map(async (delivery) => {
        const cartData = await calcCartTotal({
          ShipmentId: delivery.ShipmentId,
          AcctId: delivery.Guest_id,
          Timestamp: +new Date(),
          Register: undefined,
          UpdateCache: false,
        });

        const printRequest = {
          ForDelivery: true,
          PopCashDrawer: false,
          PrinterId: 0, // This is not used by the endpoint
          PrinterType: selectHardwarePrinterType(receiptPrinter),
          ReceiptType: 'Receipt',
          ReceiptParameters: delivery.ShipmentId,
          SubTotal: cartData.SubTotal,
          Total: cartData.GrandTotalRounded,
        };

        logger.info<PrintJobContext>('print delivery receipt started', {
          key: customEventKeys.printing.jobStarted,
          job: PrintJob.RECEIPT,
          requestParams: printRequest,
          printer: peripheralInfo(receiptPrinter),
        });

        const job = await post<GetPrintJobResponse>('v2/print-jobs/get-receipt-job', printRequest);

        if (getIsPrintResultLoggingEnabled()) {
          let success = false;
          try {
            success = await processHardwarePrintJob(job, receiptPrinter);
          } finally {
            logger.info<PrintJobResultContext>('print delivery receipt result', {
              key: customEventKeys.printing.jobEnded,
              job: PrintJob.RECEIPT,
              requestParams: printRequest,
              printer: peripheralInfo(receiptPrinter),
              success,
            });
          }

          return success;
        }

        return await processHardwarePrintJob(job, receiptPrinter);
      });

      const results = await Promise.allSettled(requests);
      if (results.some((result) => result.status === 'rejected')) {
        throw new Error('One or more receipts failed to print');
      }

      dispatch(successNotification(`${results.length} Receipts printed`));
      dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.SUCCESSFUL }));
    } catch (e) {
      const message = parseErrorMessage(e);
      dispatch(errorNotification(`Error printing receipts: ${message}`));
      dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.FAILED }));
      logger.error(e, {
        message: 'Failed to print receipts for deliveries',
        requestParams: deliveries,
        printer: peripheralInfo(receiptPrinter),
      });
    }
  };
};
