import React, { FC } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { breakpoints, colors } from 'css/Theme';
import { Button } from 'components/buttons';
import { ClearAllotmentOverridePopup } from 'pages/EditCustomerPage/components/ClearAllotmentOverridePopup';
import {
  ContactMethodOptions,
  CountryOptions,
  CustomerStatusOptions,
  OptInOptions,
  ProvinceOptions,
  StateOptions,
} from 'components/inputs/defaults';
import { DatePickerField } from 'components/inputs';
import { DaysSupplyCalculator } from '../../components/DaysSupplyCalculator';
import { DeleteCustomerAddressPopup } from 'components/CustomerPopups/components/DeleteCustomerAddressPopup';
import { EditAllotmentPopup } from 'pages/EditCustomerPage/components/EditAllotmentPopup';
import { EditAllotmentTypeEnum, EditIdentificationTypeEnum } from 'models/Customer';
import { EditExternalPatientAllotmentPopup } from 'pages/EditCustomerPage/components/EditExternalPatientAllotmentPopup';
import { EditIdentificationPopup } from 'pages/EditCustomerPage/components/EditIdentificationPopup';
import { EditLoyaltyPointsPopup } from 'pages/EditCustomerPage/components/EditLoyaltyPointsPopup';
import { getAllotmentValueToDisplay } from 'util/helpers/allotment';
import {
  IconButton,
  InputField,
  SectionDiv,
  SectionH3,
  SectionHeaderSpan,
  StyledSelectField,
} from 'pages/EditCustomerPage/style';
import { Identification } from 'pages/EditCustomerPage/components/Identification';
import { IdentificationImage } from 'pages/EditCustomerPage/components/IdentificationImage';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/roundedPlus.svg';
import {
  TopInfoLine as _TopInfoLine,
  TopInfoLabel as _TopInfoLabel,
} from 'pages/EditCustomerPage/components/TopInfoLine';
import { usePersonalTab } from './usePersonalTab';

import type { CustomerDetails } from 'models/Customer';
import type { SchemaDescription } from 'yup';
import type { ValidatedField } from 'models/Settings';

export type PersonalTabProps = {
  customer?: CustomerDetails;
  fieldOptions?: ValidatedField;
  setCustomerTypeId: React.Dispatch<React.SetStateAction<null | string>>;
  validationSchema?: SchemaDescription;
};

export const PersonalTab: FC<PersonalTabProps> = (props) => {
  const { customer } = props;

  const {
    addAddressBookItem,
    addDiscountGroup,
    addresses,
    allotmentEditType,
    areReferralSourcesLoading,
    canAdjustLoyalty,
    checkBiotrack,
    customerImage,
    customerTypeMessage,
    customerTypeOptions,
    deleteAddressBookItem,
    deletedAddressIndex,
    deleteDiscountGroup,
    discountGroupOptions,
    driversLicenseYearRange,
    genderOptions,
    handleChangeCustomerType,
    hasEditCustomerPermission,
    hasEditDiscountGroupPermission,
    internalDispensed,
    isAddNewAddressDisabled,
    isAddNewDiscountGroupDisabled,
    isAllotmentPopupVisible,
    isBioTrackEnabled,
    isCanadaLocation,
    isCollectAnonymousDemoEnabled,
    isCRMEnabled,
    isCuraleafLoyaltyTierEnabled,
    isCustomerTypeDisabled,
    isDaysSupplyCalculatorEnabled,
    isDaysSupplyCalcVisible,
    isDeleteAddressPopupVisible,
    isExternalLoyaltyEnabled,
    isExternalPatientAllotmentPopupVisible,
    isFieldHidden,
    isFieldRequired,
    isIdentificationPopupVisible,
    isLeafLogixLoyaltyEnabled,
    isLoyaltyPopupVisible,
    isMaskDriversLicenseIDEnabled,
    isMetrcEnabled,
    isMETRCUpdatePatientsEnabled,
    isMMJOutOfState,
    isNestedFieldRequired,
    isOtherReferralSourceSelected,
    isOverridePopupVisible,
    isShowAllotmentValidDatesEnabled,
    mjExpirationToolTip,
    referralSourceOptions,
    secondaryIdentifications,
    selectedDiscountGroups,
    showAllotmentPopup,
    showClearOverridePopup,
    showDeleteAddressPopup,
    showMedicalInfo,
    showNewAllotmentFields,
    toggleAllotmentPopup,
    toggleDaysSupplyCalc,
    toggleDeleteAddressPopup,
    toggleExternalPatientAllotmentPopup,
    toggleIdentificationPopup,
    toggleLoyaltyPopup,
    toggleOverridePopup,
    useMississippiUnits,
  } = usePersonalTab(props);

  return (
    <>
      <DeleteCustomerAddressPopup
        onConfirm={deleteAddressBookItem(deletedAddressIndex)}
        isVisible={isDeleteAddressPopupVisible}
        hide={toggleDeleteAddressPopup}
      />
      {customer && (
        <>
          <EditLoyaltyPointsPopup customer={customer} isVisible={isLoyaltyPopupVisible} hide={toggleLoyaltyPopup} />
          <EditAllotmentPopup
            customer={customer}
            type={allotmentEditType}
            isVisible={isAllotmentPopupVisible}
            hide={toggleAllotmentPopup}
          />
          <ClearAllotmentOverridePopup
            customer={customer}
            type={allotmentEditType}
            isVisible={isOverridePopupVisible}
            hide={toggleOverridePopup}
          />
          <EditExternalPatientAllotmentPopup
            customer={customer}
            type={allotmentEditType}
            isVisible={isExternalPatientAllotmentPopupVisible}
            hide={toggleExternalPatientAllotmentPopup}
          />
          {isDaysSupplyCalcVisible && <DaysSupplyCalculator customer={customer} hide={toggleDaysSupplyCalc} />}
          <EditIdentificationPopup
            guestId={customer.Guest_id}
            type={EditIdentificationTypeEnum.Secondary}
            isVisible={isIdentificationPopupVisible}
            hide={toggleIdentificationPopup}
          />
          <TopSectionDiv>
            <IdImageContainer>
              <IdentificationImage imageUrl={customerImage} alt='Customer ID' />
            </IdImageContainer>
            {isDaysSupplyCalculatorEnabled ? (
              <TopInfoContainer>
                <TopInfoLine label='Last Purchase' value={customer?.LastPurchase} placeholder='-' showEdit={false} />
                {isLeafLogixLoyaltyEnabled && (
                  <TopInfoLine
                    label='Available Loyalty Points'
                    value={String(customer?.LoyaltyPoints)}
                    onEdit={toggleLoyaltyPopup}
                    showEdit={!customer?.IsAnonymous}
                    valueTestId='personal-tab-loyalty_points'
                  />
                )}
                <TopInfoLabel>Days Supply Info</TopInfoLabel>
                <TopInfoLine
                  label='Days Supply Remaining'
                  dataTestId='personal-tab-days-supply-remaining'
                  editTestId='personal-tab-days-supply-remaining-edit'
                  value={customer?.DaysSupplyRemaining}
                  onEdit={toggleDaysSupplyCalc}
                  showEdit={!customer?.IsAnonymous}
                />
              </TopInfoContainer>
            ) : (
              <TopInfoContainer>
                <TopInfoLine label='Last Purchase' value={customer?.LastPurchase} placeholder='-' showEdit={false} />
                {(isLeafLogixLoyaltyEnabled || isExternalLoyaltyEnabled) && (
                  <>
                    <TopInfoLine
                      label='Available Loyalty Points'
                      value={String(customer?.LoyaltyPoints)}
                      onEdit={toggleLoyaltyPopup}
                      showEdit={canAdjustLoyalty}
                      valueTestId='personal-tab-loyalty_points'
                    />
                    {isExternalLoyaltyEnabled && (
                      <LoyaltyTier
                        label='Loyalty Tier'
                        value={String(customer?.LoyaltyTier)}
                        showEdit={false}
                        isUseCuraleafLoyaltyTierEnabled={isCuraleafLoyaltyTierEnabled}
                        color={customer.LoyaltyTextColor}
                        valueTestId='personal-tab-loyalty_tier'
                      />
                    )}
                  </>
                )}
                <TopInfoLabel>Allotment Info</TopInfoLabel>
                {isShowAllotmentValidDatesEnabled && (
                  <>
                    <TopInfoLine
                      label='Allotment Valid From'
                      value={customer?.AllotmentData?.ValidFrom}
                      placeholder='-'
                      showEdit={false}
                    />
                    <TopInfoLine
                      label='Allotment Valid Until'
                      value={customer?.AllotmentData?.ValidUntil}
                      placeholder='-'
                      showEdit={false}
                    />
                  </>
                )}
                {customer?.AllotmentData?.MaxLimit != null && (
                  <TopInfoLine
                    label='Max Allotment'
                    dataTestId='personal-tab-max-allotment'
                    editTestId='personal-tab-max-allotment-edit'
                    value={getAllotmentValueToDisplay(customer?.AllotmentData?.MaxLimit)}
                    suffix='g'
                    showEdit={customer?.AllotmentData?.AllowOverrides}
                    showDelete={customer?.AllotmentData?.MaxAllotmentOverriden}
                    onEdit={() => showAllotmentPopup(EditAllotmentTypeEnum.Max)}
                    onDelete={() => showClearOverridePopup(EditAllotmentTypeEnum.Max)}
                  />
                )}
                {showNewAllotmentFields && (
                  <>
                    <TopInfoLine
                      dataTestId='internal-dispensed-allotment'
                      label='Internal Dispensed'
                      value={internalDispensed()}
                      suffix='g'
                      showEdit={false}
                      showDelete={false}
                    />
                    <TopInfoLine
                      dataTestId='external-dispensed-allotment'
                      editTestId='personal-tab-external-dispensed-edit'
                      label='External Dispensed'
                      value={customer?.AllotmentData?.ExternalDispensed ?? 0}
                      suffix='g'
                      showEdit={true}
                      showDelete={false}
                      onEdit={() => showAllotmentPopup(EditAllotmentTypeEnum.External)}
                    />
                  </>
                )}
                {useMississippiUnits ? (
                  <>
                    <TopInfoLine
                      label='Current MMCEU Allotment'
                      value={getAllotmentValueToDisplay((customer?.AllotmentData?.CurrentLimit ?? 0) / 3.5)}
                      showEdit={false}
                      showDelete={false}
                    />
                  </>
                ) : (
                  <>
                    <TopInfoLine
                      dataTestId='personal-tab-current-allotment'
                      editTestId='personal-tab-current-allotment-edit'
                      label='Current Allotment'
                      value={getAllotmentValueToDisplay(customer?.AllotmentData?.CurrentLimit ?? 0)}
                      suffix='g'
                      showEdit={customer?.AllotmentData?.AllowOverrides}
                      showDelete={customer?.AllotmentData?.CurrentAllotmentOverriden}
                      onEdit={() =>
                        showNewAllotmentFields
                          ? showAllotmentPopup(EditAllotmentTypeEnum.BackfillExternal)
                          : showAllotmentPopup(EditAllotmentTypeEnum.Current)
                      }
                      onDelete={() => showClearOverridePopup(EditAllotmentTypeEnum.Current)}
                    />
                  </>
                )}
                {customer?.AllotmentData?.ShowCycleData && moment(customer?.CycleDate).isValid() && (
                  <TopInfoLine
                    label='Cycle Date'
                    value={moment(customer?.CycleDate).format('MM/DD/YYYY')}
                    showEdit={false}
                    showDelete={false}
                  />
                )}
                {isMetrcEnabled && isMETRCUpdatePatientsEnabled && (
                  <TopButton secondary onClick={toggleExternalPatientAllotmentPopup}>
                    Edit Allotments
                  </TopButton>
                )}
              </TopInfoContainer>
            )}
          </TopSectionDiv>
        </>
      )}

      <SectionH3>Personal Details</SectionH3>
      <SectionDiv>
        {!isFieldHidden('Prefix') && (
          <InputField
            name='NamePrefix'
            label={`Prefix${isFieldRequired('NamePrefix') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_prefix'
          />
        )}
        {!isFieldHidden('Name') && (
          <InputField
            name='FirstName'
            label={`First Name${isFieldRequired('FirstName') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_first-name'
          />
        )}
        {!isFieldHidden('Last Name') && (
          <InputField
            name='LastName'
            label={`Last Name${isFieldRequired('LastName') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_last-name'
          />
        )}
        {!isFieldHidden('Suffix') && (
          <InputField
            name='NameSuffix'
            label={`Suffix${isFieldRequired('NameSuffix') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_name-suffix'
          />
        )}
        {!isFieldHidden('Middle Name') && (
          <InputField
            name='MiddleName'
            label={`Middle Name${isFieldRequired('MiddleName') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_middel-name'
          />
        )}
        {!isFieldHidden('External Name') && (
          <InputField
            name='ExternalName'
            label={`External Name${isFieldRequired('nickname') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_tab_input-field_externalName'
          />
        )}
        {!isFieldHidden('Nickname') && (
          <InputField
            name='nickname'
            label={`Nickname${isFieldRequired('nickname') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_nickname'
          />
        )}
        {!isFieldHidden('Custom Identifier') && (
          <InputField
            name='CustomIdentifier'
            label={`Custom Identifier${isFieldRequired('CustomIdentifier') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_CustomIdentifier'
          />
        )}
        {!isFieldHidden('Gender') && (
          <StyledSelectField
            name='Gender'
            label={`Gender${isFieldRequired('Gender') ? '*' : ''}`}
            placeholder='Select'
            options={genderOptions}
            disabled={customer?.IsAnonymous && !isCollectAnonymousDemoEnabled}
            automationId='personal-tab_select-field_gender'
          />
        )}
        {!isFieldHidden('Pronoun') && (
          <InputField
            name='Pronoun'
            label={`Preferred Pronoun${isFieldRequired('Pronoun') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous && !isCollectAnonymousDemoEnabled}
            automationId='personal-tab_select-field_pronoun'
          />
        )}
        {!isFieldHidden('Date of Birth') && (
          <FormDatePicker
            name='DOB'
            labelText={`Date of birth${isFieldRequired('DOB') ? '*' : ''}`}
            placeholderText='mm/dd/yyyy'
            disabled={customer?.IsAnonymous && !isCollectAnonymousDemoEnabled}
            automationId='personal-tab_format-date-picker_dob'
          />
        )}
        {customer?.AllotmentData?.ShowCycleData && (
          <FormDatePicker
            name='CycleDate'
            labelText='Cycle Date'
            placeholderText='mm/dd/yyyy'
            disabled={customer?.IsAnonymous && !isCollectAnonymousDemoEnabled}
            automationId='personal-tab_format-date-picker_cycle_date'
          />
        )}
        {!isFieldHidden('Customer Type') && (
          <StyledSelectField
            name='CustomerTypeId'
            label={`Customer Type${isFieldRequired('CustomerTypeId') ? '*' : ''}`}
            placeholder='Select'
            message={customerTypeMessage}
            onChange={handleChangeCustomerType}
            options={customerTypeOptions}
            disabled={isCustomerTypeDisabled}
            automationId='personal-tab_select-field_customer-type'
          />
        )}
        {!isFieldHidden('Tribal Affiliation') && (
          <InputField
            name='TribalAffiliation'
            label={`Tribal Affiliation${isFieldRequired('TribalAffiliation') ? '*' : ''}`}
            placeholder='Tribal Affiliation'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_tribal-affiliation'
          />
        )}
        {!isFieldHidden('Status') && (
          <StyledSelectField
            name='status'
            label={`Status${isFieldRequired('status') ? '*' : ''}`}
            placeholder='Select'
            options={CustomerStatusOptions}
            disabled={!hasEditCustomerPermission || customer?.IsAnonymous}
            automationId='personal-tab_select-field_status'
          />
        )}
        {customer && !isFieldHidden('Referred By') && (
          <StyledSelectField
            name='ReferralSourceId'
            label={`Referred by${isFieldRequired('ReferralSourceId') ? '*' : ''}`}
            placeholder='Select'
            disabled={areReferralSourcesLoading || customer?.IsAnonymous}
            options={referralSourceOptions}
            automationId='personal-tab_select-field_referral-source'
          />
        )}
        {isOtherReferralSourceSelected && !isFieldHidden('Other Referral Source') && (
          <InputField
            name='OtherReferralSource'
            label={`Other Referral Source${isFieldRequired('OtherReferralSource') ? '*' : ''}`}
            placeholder='Other Referral Souce'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_other'
          />
        )}
      </SectionDiv>
      {showMedicalInfo && (
        <>
          <SectionH3>MMJ State ID</SectionH3>
          <SectionDiv>
            {(isMMJOutOfState || !isFieldHidden('MMJ ID State')) && (
              <StyledSelectField
                name='MMJIDState'
                label={`State${isFieldRequired('MMJIDState') ? '*' : ''}`}
                placeholder='Select'
                options={StateOptions}
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_select-field_mmjid-state'
              />
            )}
            {!isFieldHidden('MMJ ID Start Date') && (
              <FormDatePicker
                name='MJStartDate'
                labelText={`Start Date${isFieldRequired('MJStartDate') ? '*' : ''}`}
                placeholderText='mm/dd/yyyy'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_format-date-picker_mj-start-date'
              />
            )}
            {!isFieldHidden('MMJ ID') && (
              <InputField
                name='MJStateIDNo'
                label={`Number${isFieldRequired('MJStateIDNo') ? '*' : ''}`}
                placeholder='...'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_input-field_mj-state-id'
              />
            )}
            {!isFieldHidden('MMJ expiration date') && (
              <FormDatePicker
                name='MJExpirationDate'
                labelText={`Expiration Date${isFieldRequired('MJExpirationDate') ? '*' : ''}`}
                tooltipText={mjExpirationToolTip}
                placeholderText='mm/dd/yyyy'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_format-date-picker_mj-expiration-date'
              />
            )}
            {!isFieldHidden('DDNumber') && (
              <InputField
                name='DDNumber'
                label={`Doctor Designation Number${isFieldRequired('DDNumber') ? '*' : ''}`}
                placeholder='...'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_input-field_dd-number'
              />
            )}
          </SectionDiv>
          {!isFieldHidden('Patient Certification') && (
            <>
              <SectionH3>Patient Certification</SectionH3>
              <SectionDiv>
                <FormDatePicker
                  name='CertificationCollectionDate'
                  labelText={`Collection Date${isFieldRequired('CertificationCollectionDate') ? '*' : ''}`}
                  placeholderText='mm/dd/yyyy'
                  disabled={customer?.IsAnonymous}
                  automationId='personal-tab_format-date-picker_certification-collection-date'
                />

                <FormDatePicker
                  name='CertificationExpirationDate'
                  labelText={`Expiration Date${isFieldRequired('CertificationExpirationDate') ? '*' : ''}`}
                  placeholderText='mm/dd/yyyy'
                  disabled={customer?.IsAnonymous}
                  automationId='personal-tab_format-date-picker_certification-expiration-date'
                />
              </SectionDiv>
            </>
          )}
        </>
      )}
      <SectionH3>Driver's License</SectionH3>
      <SectionDiv>
        {!isFieldHidden('(Drivers) License Number') && (
          <InputField
            autoComplete='new-password'
            name='DriversLicenseId'
            label={`Number${isFieldRequired('DriversLicenseId') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            type={isMaskDriversLicenseIDEnabled ? 'password' : 'text'}
            automationId='personal-tab_input-field_driver-license'
          />
        )}
        {!isFieldHidden('(Drivers) License Expiration') && (
          <FormDatePicker
            name='DLExpirationDate'
            labelText={`Expiration Date${isFieldRequired('DLExpirationDate') ? '*' : ''}`}
            placeholderText='mm/dd/yyyy'
            yearRange={driversLicenseYearRange}
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_format-date-picker_dl-expiration-date'
          />
        )}
        {!isFieldHidden('Additional State Identifiers') && (
          <InputField
            name='additionalStateIdentifiers'
            label={`Additional State Identifiers${isFieldRequired('additionalStateIdentifiers') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_additional-state-id'
          />
        )}
      </SectionDiv>
      {customer && (
        <>
          {!customer?.IsAnonymous && (
            <SectionHeaderSpan>
              <SectionH3>Identification</SectionH3>
              <IconButton
                automationId='customer-page_button_add-identification'
                icon={PlusIcon}
                onClick={toggleIdentificationPopup}
                secondary
              />
            </SectionHeaderSpan>
          )}
          <SectionDiv>
            <Identification
              type={EditIdentificationTypeEnum.Primary}
              id={customer.identifications[1]}
              guestId={customer.Guest_id}
            />
            {secondaryIdentifications?.map((sId) => (
              <Identification
                key={JSON.stringify(sId)}
                type={EditIdentificationTypeEnum.Secondary}
                id={sId}
                guestId={customer.Guest_id}
              />
            ))}
          </SectionDiv>
        </>
      )}
      {isBioTrackEnabled && (
        <SectionDiv>
          <Button onClick={checkBiotrack}>Check Biotrack</Button>
        </SectionDiv>
      )}

      <SectionHeaderSpan>
        <SectionH3>Addresses</SectionH3>
        <IconButton
          icon={PlusIcon}
          disabled={isAddNewAddressDisabled}
          onClick={addAddressBookItem}
          automationId='personal-tab_icon-button_plus-address'
          secondary
        />
      </SectionHeaderSpan>
      <SectionDiv>
        {!isFieldHidden('Street') && (
          <InputField
            name='address.street'
            label={`Street${isNestedFieldRequired('address', 'street') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_street'
          />
        )}
        {!isFieldHidden('Street2') && (
          <InputField
            name='address.street2'
            label={`Street 2${isNestedFieldRequired('address', 'street2') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_street2'
          />
        )}
        {!isFieldHidden('City') && (
          <InputField
            name='address.city'
            label={`City${isNestedFieldRequired('address', 'city') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_city'
          />
        )}
        {!isFieldHidden('Postal Code') && (
          <InputField
            name='address.postal_code'
            label={`Postal Code${isNestedFieldRequired('address', 'postal_code') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous && !isCollectAnonymousDemoEnabled}
            automationId='personal-tab_input-field_postal-code'
          />
        )}
        {!isFieldHidden('State') && (
          <StyledSelectField
            name='address.state'
            label={`${isCanadaLocation ? 'Province' : 'State'}${isNestedFieldRequired('address', 'state') ? ' *' : ''}`}
            placeholder='Select'
            options={isCanadaLocation ? ProvinceOptions : StateOptions}
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_select-field_state'
          />
        )}
        {!isFieldHidden('Country') && (
          <StyledSelectField
            name='address.Country_Code'
            label={`Country${isNestedFieldRequired('address', 'Country_Code') ? ' *' : ''}`}
            options={CountryOptions}
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_select-field_country'
          />
        )}
      </SectionDiv>

      <SectionDiv>
        {addresses.map((address, index) => (
          <>
            <InputField
              name={`AddressBook[${index}].street`}
              label={`Street${isNestedFieldRequired('address', 'street') ? '*' : ''}`}
              automationId={`personal-tab_input-field_street-${index}`}
              placeholder='...'
            />
            <InputField
              name={`AddressBook[${index}].city`}
              label={`City${isNestedFieldRequired('address', 'city') ? '*' : ''}`}
              automationId={`personal-tab_input-field_city-${index}`}
              placeholder='...'
            />
            <InputField
              name={`AddressBook[${index}].postal_code`}
              label={`Postal Code${isNestedFieldRequired('address', 'postal_code') ? '*' : ''}`}
              automationId={`personal-tab_field_postal-code-${index}`}
              placeholder='...'
              type='number'
            />
            <StyledSelectField
              name={`AddressBook[${index}].state`}
              label={`${address.Country_Code === 'CA' ? 'Province' : 'State'}${
                isNestedFieldRequired('address', 'state') ? ' *' : ''
              }`}
              automationId={`personal-tab_input-field_state-${index}`}
              placeholder='Select'
              options={address.Country_Code === 'CA' ? ProvinceOptions : StateOptions}
            />
            <StyledSelectField
              name={`AddressBook[${index}].Country_Code`}
              label={`Country${isNestedFieldRequired('address', 'Country_Code') ? ' *' : ''}`}
              automationId={`personal-tab_input-field_country-${index}`}
              options={CountryOptions}
            />
            <TrashIconDiv>
              <TrashIconButton
                icon={DeleteIcon}
                onClick={(e) => {
                  e.preventDefault();
                  showDeleteAddressPopup(index);
                }}
                secondary
              />
            </TrashIconDiv>
          </>
        ))}
      </SectionDiv>

      <SectionH3>Contact Details</SectionH3>
      <SectionDiv>
        {!isFieldHidden('Phone Number') && (
          <InputField
            name='PhoneNo'
            label={`Phone Number${isFieldRequired('PhoneNo') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_phone'
          />
        )}
        {!isFieldHidden('Mobile Phone Number') && (
          <InputField
            name='CellPhone'
            label={`Mobile Phone Number${isFieldRequired('CellPhone') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_cellphone'
          />
        )}
        {!isFieldHidden('Preferred Method of Contact') && (
          <StyledSelectField
            name='PreferredMethodOfContact'
            label={`Preferred Contact Method${isFieldRequired('PreferredMethodOfContact') ? '*' : ''}`}
            placeholder='Select'
            options={ContactMethodOptions}
            disabled={customer?.IsAnonymous}
          />
        )}
        {!isFieldHidden('Email Address') && (
          <InputField
            name='Email'
            label={`Email Address${isFieldRequired('Email') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_email'
          />
        )}
        {isCRMEnabled && (
          <>
            <StyledSelectField
              name='OptedIntoEngagementSMS'
              label={`Opt Into Informational SMS`}
              placeholder='-'
              options={OptInOptions}
              disabled={customer?.IsAnonymous}
              automationId='personal-tab_select-field_opt-sms'
            />
            <StyledSelectField
              name='OptedIntoMarketing'
              label={`Opt Into Marketing SMS`}
              placeholder='-'
              options={OptInOptions}
              disabled={customer?.IsAnonymous}
              automationId='personal-tab_select-field_opt-marketing'
            />
          </>
        )}
      </SectionDiv>

      {customer && (
        <>
          <SectionHeaderSpan>
            <SectionH3>Discount Groups</SectionH3>
            <IconButton
              icon={PlusIcon}
              disabled={isAddNewDiscountGroupDisabled}
              onClick={addDiscountGroup}
              automationId='personal-tab_icon-button_plus-discount-group'
              secondary
            />
          </SectionHeaderSpan>

          <SectionDiv>
            {selectedDiscountGroups.map((_item, index) => (
              <StyledSelectField
                key={index}
                name={`discounts[${index}].id`}
                placeholder='Select'
                options={discountGroupOptions}
                disabled={!hasEditDiscountGroupPermission || customer?.IsAnonymous}
                automationId='personal-tab_select-field_discount-group'
                endAdornment={
                  <Button onClick={() => deleteDiscountGroup(index)} isAdornment>
                    Remove
                  </Button>
                }
              />
            ))}
          </SectionDiv>
        </>
      )}
    </>
  );
};

const FormDatePicker = styled(DatePickerField)`
  max-width: 27rem;
  flex: 1 0 50%;
  margin: 0 1rem 1rem 0;
`;

const TopSectionDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 2.1875rem;
  ${breakpoints.smallTablet.maxQuery} {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`;

const IdImageContainer = styled.div`
  margin-right: 1rem;
  padding: 0.25rem 0;
  width: fit-content;
`;

export const TopInfoContainer = styled.div`
  margin-right: 1rem;
  max-width: 27rem;
  padding: 0.25rem 0;
  width: 100%;
  ${StyledSelectField} {
    margin-right: 0;
  }
  ${InputField} {
    margin-right: 0;
  }
`;

const TopInfoLine = styled(_TopInfoLine)`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

const LoyaltyTier = styled(TopInfoLine)<{ isUseCuraleafLoyaltyTierEnabled: boolean; color?: string }>`
  ${({ isUseCuraleafLoyaltyTierEnabled }) =>
    isUseCuraleafLoyaltyTierEnabled &&
    `
    > .top-info-line-value {
      text-transform: uppercase;
      font-weight: 600;
    }
  `}

  ${({ isUseCuraleafLoyaltyTierEnabled, color }) =>
    isUseCuraleafLoyaltyTierEnabled &&
    color &&
    `
    > .top-info-line-value {
      color: ${color}
    }
  `}
`;

const TopInfoLabel = styled(_TopInfoLabel)`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

const TopButton = styled(Button)<{ secondary?: boolean; width?: string }>`
  height: 2rem;

  &:disabled {
    opacity: 0.5;
  }
`;

const TrashIconDiv = styled.div`
  flex-basis: 100%;
  margin-bottom: 0.75rem;
`;

const TrashIconButton = styled(IconButton)`
  margin-left: 0;

  > svg {
    width: 0.8rem;
    height: 0.8rem;

    path,
    line {
      color: ${colors.dutchie.red};
      fill: none;
    }
  }
`;
