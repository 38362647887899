import React from 'react';

type WarnIconProps = {
  className?: string;
};

export function WarnIcon({ className }: WarnIconProps) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_401_215054)'>
        <path
          d='M23.77 20.57L13.77 1.57C13.6004 1.24728 13.346 0.977021 13.034 0.788436C12.7221 0.599852 12.3645 0.500113 12 0.5C11.6354 0.500113 11.2779 0.599852 10.9659 0.788436C10.6539 0.977021 10.3995 1.24728 10.23 1.57L0.229955 20.57C0.0634503 20.8793 -0.0186664 21.227 -0.00813363 21.578C0.00239912 21.9291 0.10521 22.2713 0.289955 22.57C0.472189 22.8578 0.724918 23.0943 1.02418 23.2571C1.32344 23.4198 1.65932 23.5034 1.99995 23.5H22C22.3442 23.4999 22.6826 23.4109 22.9824 23.2417C23.2822 23.0725 23.5332 22.8288 23.7112 22.5341C23.8892 22.2395 23.9881 21.9039 23.9984 21.5598C24.0087 21.2157 23.93 20.8748 23.77 20.57ZM11 8.5C11 8.23478 11.1053 7.98043 11.2928 7.79289C11.4804 7.60536 11.7347 7.5 12 7.5C12.2652 7.5 12.5195 7.60536 12.7071 7.79289C12.8946 7.98043 13 8.23478 13 8.5V14.5C13 14.7652 12.8946 15.0196 12.7071 15.2071C12.5195 15.3946 12.2652 15.5 12 15.5C11.7347 15.5 11.4804 15.3946 11.2928 15.2071C11.1053 15.0196 11 14.7652 11 14.5V8.5ZM12.05 20C11.6561 19.9977 11.2783 19.8436 10.9951 19.5698C10.712 19.296 10.5454 18.9236 10.53 18.53C10.5233 18.3323 10.5563 18.1353 10.627 17.9506C10.6978 17.7659 10.8048 17.5972 10.9419 17.4546C11.0789 17.312 11.2432 17.1982 11.4249 17.1202C11.6066 17.0421 11.8022 17.0012 12 17C12.3938 17.0023 12.7716 17.1564 13.0548 17.4302C13.3379 17.704 13.5045 18.0764 13.52 18.47C13.5266 18.6677 13.4936 18.8647 13.4229 19.0494C13.3521 19.2341 13.2451 19.4028 13.108 19.5454C12.971 19.688 12.8068 19.8018 12.625 19.8798C12.4433 19.9579 12.2477 19.9988 12.05 20Z'
          fill='#615100'
        />
      </g>
      <defs>
        <clipPath id='clip0_401_215054'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
