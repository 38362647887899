import { PeripheralServiceConfig, PeripheralType } from "@dutchie/capacitor-hardware";
import { MockedPeripheralService } from "../../shared/mock/MockedPeripheralService";

import { MockReceiptPrinter } from "./MockReceiptPrinter";

export class MockReceiptPrinterService extends MockedPeripheralService<MockReceiptPrinter> {
  constructor(config?: { service?: Partial<PeripheralServiceConfig>}) {
    super(PeripheralType.receiptPrinter, {
      defaultConfig: { name: 'MockReceiptPrinterService', autoConnect: false, autoEnable: true },
      userConfig: config?.service,
    });

    this.devices = [
      this.createDevice('1111', 'Mock Receipt Printer 1'),
      this.createDevice('2222', 'Mock Receipt Printer 2'),
    ];
  }

  createDevice(id: string, name: string): MockReceiptPrinter {
    return new MockReceiptPrinter({ id, name, serviceName: this.config.name });
  }
}
