import React, { FC, useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { callback1 } from 'models/Misc';
import { LoadingButton } from 'components/buttons';
import { Input } from 'components/inputs';
import { ReactComponent as DollarSign } from 'assets/icons/dollar-sign.svg';
import { ReactComponent as NonCashAdjustmentFeeIcon } from 'assets/icons/non-cash-adjustment-fee-icon.svg';
import { CheckoutContext } from './CheckoutContext';
import { FeePaymentMethod } from 'models/Checkout';
import { useSelector } from 'react-redux';
import { State } from 'store';

type PaymentOptionProps = {
  onAddAmount: callback1<number>;
  feeDetail?: FeeDetail;
  prefilledAmount?: number;
  disableAddButton?: boolean;
  addButtonLabel?: string;
};

export type FeeDetail = {
  feeAmount: string;
  feePaymentMethod: FeePaymentMethod;
};

export const PaymentOption: FC<PaymentOptionProps> = ({ onAddAmount, feeDetail, prefilledAmount, disableAddButton, addButtonLabel, children }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [amount, setAmount] = useState<number | undefined>(prefilledAmount);
  const { paymentProcessing } = useContext(CheckoutContext);
  const useUSAG = useSelector((state: State) => state.settings.integrations?.DebitProcessor) === 'usag';
  const feeText = useUSAG ? 'Card Activation Fee' : 'Non-Cash Adjustment Fee';
  useEffect(() => {
    inputRef.current?.focus();
  }, []);



  const handleAmountAdd = () => onAddAmount(amount ?? 0);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setAmount(Number(e.target.value));
  const handleInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !paymentProcessing) {
      handleAmountAdd();
    }
  };

  return (
    <Container>
      {Number(feeDetail?.feeAmount) > 0 && (
        <NonCashAdjustmentFeeContainer>
          <NonCashAdjustmentFeeIcon />
          <NonCashAdjustmentFeeText>
            <NonCashAdjustmentFeeWeightedText>
              Additional ${feeDetail?.feeAmount} {feeText}
            </NonCashAdjustmentFeeWeightedText>
            &nbsp;added to customer's subtotal
          </NonCashAdjustmentFeeText>
        </NonCashAdjustmentFeeContainer>
      )}
      <Controls>
        <Input
          type='number'
          getInputRef={inputRef}
          value={amount}
          onChange={handleInputChange}
          onKeyDown={handleInputKeydown}
          decimalScale={2}
          allowNegative={false}
          fixedDecimalScale={true}
          placeholder='Enter Amount'
          data-testid='payment-options_amount-input'
          startAdornment={<DollarSign />}
        />
        <AddButton
          loading={paymentProcessing}
          hideLabelOnLoading
          onClick={handleAmountAdd}
          disabled={paymentProcessing || disableAddButton}
        >
          {addButtonLabel ?? 'Add'}
        </AddButton>
      </Controls>
      {children && <Extras data-testid='payment-options_children'>{children}</Extras>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 0.9rem 0 0;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const AddButton = styled(LoadingButton)`
  width: 68px;
  flex: 0 0 68px;
`;

const Extras = styled.div`
  display: flex;
  flex-direction: column;
  height: min-content;
  gap: 1rem;
`;

const NonCashAdjustmentFeeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: ${colors.dutchie.blue10};
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 1rem;
`;

const NonCashAdjustmentFeeText = styled.span`
  font-weight: 400;
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: ${colors.dutchie.deepBlue};
  padding-left: 8px;
`;

const NonCashAdjustmentFeeWeightedText = styled.span`
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: ${colors.dutchie.deepBlue};
`;
