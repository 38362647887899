import { useMemo } from 'react';
import { differenceInYears } from 'date-fns';

import { colors } from 'css/Theme';
import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { phoneFormat } from 'util/Helpers';
import { useAppSelector } from 'util/hooks';
import { useCuraleafLoyaltyTier } from 'util/hooks/launch-darkly/useCuraleafLoyaltyTier';
import { useLoyaltySettings } from 'pages/CartPage/CartPanels/LoyaltyPoints/useLoyaltySettings';

import type { CustomerInfoProps } from './CustomerInfo';

export const useCustomerInfo = ({ loading }: CustomerInfoProps) => {
  // Global state

  const guest = useAppSelector((state) => state.customer.details);

  // Hooks

  const { loyaltyEnabled: showLoyaltyPoints } = useLoyaltySettings();
  const isUseCuraleafLoyaltyTierEnabled = useCuraleafLoyaltyTier();

  // Computed values

  const birthdateText = guest
    ? `${formatDate({ date: guest.DOB })} - ${differenceInYears(new Date(), new Date(guest.DOB))} yrs`
    : '';
  const caregiverNameText = `${guest?.CGFirstName ?? ''} ${guest?.CGLastName ?? ''}`;
  const discountGroupsText = useMemo(() => {
    const uniqueDiscountGroups = guest?.discounts ? [...new Set(guest.discounts)] : [];
    return uniqueDiscountGroups
      .map((discount, index) => {
        const addComma = uniqueDiscountGroups.length > 1 && index < uniqueDiscountGroups.length - 1;
        return discount.title ? `${discount.title}${addComma ? ',' : ''}` : '';
      })
      .join(' ');
  }, [guest]);
  const driversLicenseText = `${guest?.DriversLicenseId ?? ''}${
    guest?.DLExpirationDate ? ` - Exp: ${formatDate({ date: guest.DLExpirationDate })}` : ''
  }`;
  const loyaltyPointsText = `${guest?.LoyaltyPoints ?? 0} pts`;
  const loyaltyStarColor =
    isUseCuraleafLoyaltyTierEnabled === true
      ? guest?.LoyaltyTextColor ?? colors.dutchie.black
      : colors.dutchie.almostBlack;
  const loyaltyTierColor = guest?.LoyaltyTextColor;
  const loyaltyTierText = guest?.LoyaltyTier ?? '';
  const mjStateIdText = `${guest?.MJStateIDNo ?? ''}${
    guest?.MJExpirationDate ? ` - Exp: ${formatDate({ date: guest.MJExpirationDate })}` : ''
  }`;
  const phoneNumberText = guest?.PhoneNo ? phoneFormat(guest.PhoneNo) : '';

  const showBirthdate = !!guest && !!guest.DOB;
  const showCaregiver = !!guest?.CGFirstName && !!guest?.CGLastName;
  const showDiscountsGroup = discountGroupsText.length > 0;
  const showDriversLicense = !!guest?.DriversLicenseId || !!guest?.DLExpirationDate;
  const showLoyaltyTier = isUseCuraleafLoyaltyTierEnabled === true && !!guest?.LoyaltyTier;
  const showMJStateId = !!guest?.MJStateIDNo || !!guest?.MJExpirationDate;
  const showPhoneNumber = !!guest && !!guest.PhoneNo && !!phoneNumberText;

  return {
    birthdateText,
    caregiverNameText,
    discountGroupsText,
    driversLicenseText,
    loyaltyPointsText,
    loyaltyStarColor,
    loyaltyTierColor,
    loyaltyTierText,
    mjStateIdText,
    phoneNumberText,
    showBirthdate: showBirthdate || loading,
    showCaregiver,
    showDiscountsGroup: showDiscountsGroup || loading,
    showDriversLicense: showDriversLicense || loading,
    showLoyaltyPoints: showLoyaltyPoints || loading,
    showLoyaltyTier,
    showMJStateId,
    showPhoneNumber: showPhoneNumber || loading,
  };
};
