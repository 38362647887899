import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { findCorrespondingLabUnitWithId } from 'util/Helpers';
import * as labsApi from 'api/LabsApi';
import { LabDataMapping, LabData } from 'models/Labs';
import { State } from 'store';
import { PreorderProductSearchResult } from 'api/PreorderApi';

export const selectPreorderProduct = createAction('selectPreorderProduct', (payload: PreorderProductSearchResult) => ({
  payload,
}));
export const deselectPreorderProduct = createAction('deselectPreorderProduct');

export const loadLabData = createAsyncThunk('loadLabData', async (BatchId: number | undefined, { getState }) => {
  const state = getState() as State;
  let loadRefData;

  if (!state.products.labRefData) {
    loadRefData = labsApi.GetLabDataFieldMapping();
  } else {
    loadRefData = new Promise((resolve) => resolve(state.products.labRefData));
  }

  const labData = labsApi.LoadLabResultInventory(BatchId);

  return Promise.all([loadRefData, labData]).then((data) => {
    const refData = data[0] as Array<LabDataMapping>;
    const labD = data[1] as Record<string, number>;

    return {
      LabData: refData
        .map((m) => {
          return {
            Name: m.FriendlyName,
            Value: labD[m.ValueField],
            Unit: findCorrespondingLabUnitWithId(labD[m.UnitField]),
            IsCannabinoid: m.IsCannabinoid,
          } as LabData;
        })
        .filter((r) => !!r.Value || r.Unit === 'n.d.' || r.Unit === '<LOQ')
        .sort((a, b) => (a.Value > b.Value ? -1 : 1)),
      LabRefData: refData,
    };
  });
});

export const clearLabData = createAction('clearLabData');
