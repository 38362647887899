import React from 'react';
import { PaymentOption } from '../../PaymentOption';
import { PaymentShortcuts } from '../PaymentShortcuts';
import { usePaymentMMAP } from './usePaymentMMAP';

export function PaymentMMAP(): JSX.Element {
  const { handleAddMMAPPaymentAmount } = usePaymentMMAP();

  return (
    <PaymentOption onAddAmount={handleAddMMAPPaymentAmount}>
      <PaymentShortcuts
        shortcuts={[
          { name: 'Exact amount', paddingX: 11 },
          { amount: 20 },
          { amount: 40 },
          { amount: 60 },
          { amount: 100 },
        ]}
        onAddAmount={handleAddMMAPPaymentAmount}
      />
    </PaymentOption>
  );
}
