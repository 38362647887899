import React from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { CustomerDetailsTable } from './components/CustomerDetailsTable';
import { ExistingProfileIcon } from 'assets/icons/customer-profile/exisiting-profile';
import { LoadingButton } from 'components/buttons';
import { ModalContainer, ModalVariation, ModalCloseButton } from 'components/modals/Modal';
import { NewProfileIcon } from 'assets/icons/customer-profile/new-profile';
import { PopupOverlay } from 'components/popups';
import { usePotentialDuplicateCustomerModal } from './usePotentialDuplicateCustomerModal';

import type { CustomerDetails } from 'models/Customer';

export type PotentialDuplicateCustomerModalProps = {
  existingCustomerDetails: CustomerDetails;
  newCustomerDetails: CustomerDetails;
  hide: () => void;
};

export const PotentialDuplicateCustomerModal = (props: PotentialDuplicateCustomerModalProps) => {
  const { existingCustomerDetails, newCustomerDetails, hide } = props;

  const {
    handleClickCreateNewProfile,
    handleClickUseExistingProfile,
    isCreatingNewCustomer,
    matchingAttributes,
    showMJStateId,
  } = usePotentialDuplicateCustomerModal(props);

  return (
    <PopupOverlay hide={hide}>
      <ModalContainer variation={ModalVariation.Large} data-testid='potential-duplicate-modal'>
        <ModalHeader>
          <Title>Potential duplicate customer</Title>
          <ModalCloseButton onClick={hide} data-testid='potential-duplicate-modal_close-button' />
        </ModalHeader>
        <ModalContent>
          <Message>
            A profile with <HighlightedText>matching details</HighlightedText> was found. Use existing profile or
            continue creating a new profile?
          </Message>
          <TablesContainer>
            <Table>
              <TableHeader>
                <NewProfileIcon />
                New Profile
              </TableHeader>
              <CustomerDetailsTable
                automationId='potential-duplicate-modal_new-profile'
                customerDetails={newCustomerDetails}
                matchingAttributes={matchingAttributes}
                showMJStateId={showMJStateId}
              />
            </Table>
            <Table>
              <TableHeader>
                <ExistingProfileIcon />
                Existing Profile
              </TableHeader>
              <CustomerDetailsTable
                automationId='potential-duplicate-modal_existing-profile'
                customerDetails={existingCustomerDetails}
                matchingAttributes={matchingAttributes}
                showMJStateId={showMJStateId}
              />
            </Table>
          </TablesContainer>
        </ModalContent>
        <ModalFooter>
          <LoadingButton
            cta
            fullWidth
            tertiary
            loading={isCreatingNewCustomer}
            onClick={handleClickCreateNewProfile}
            ddActionName='create new profile (PotentialDuplicateCustomerModal)'
          >
            Create new profile
          </LoadingButton>
          <LoadingButton
            cta
            fullWidth
            disabled={isCreatingNewCustomer}
            onClick={handleClickUseExistingProfile}
            ddActionName='use existing profile (PotentialDuplicateCustomerModal)'
          >
            Use existing profile
          </LoadingButton>
        </ModalFooter>
      </ModalContainer>
    </PopupOverlay>
  );
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 0 1.5rem;
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  flex: 1;
  padding: 2rem 0;
  margin-left: 3.75rem;
  text-align: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 2rem;
`;

const Message = styled.div`
  color: ${colors.dutchie.black};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const HighlightedText = styled(Message)`
  display: inline;
  color: ${colors.dutchie.green};
  font-weight: 600;
`;

const TablesContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  gap: 1rem;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${colors.dutchie.black};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;
