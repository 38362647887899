import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';
import { formatDate } from 'util/helpers/date-helpers/formatDate';
import { formatPhone } from 'util/helpers/formatPhone';
import { useCustomerDetailsTable } from './useCustomerDetailsTable';

import type { CustomerDetails } from 'models/Customer';

type CustomerDetailsTableProps = {
  customerDetails: CustomerDetails;
  matchingAttributes?: Record<keyof CustomerDetails, boolean>;
  showMJStateId?: boolean;
  automationId?: string;
};

export const CustomerDetailsTable = ({
  customerDetails,
  matchingAttributes,
  showMJStateId = false,
  automationId,
}: CustomerDetailsTableProps) => {
  const { customerTypeDescription } = useCustomerDetailsTable({ customerDetails });

  return (
    <TableContent data-testid={automationId}>
      <TableHeaderCell>First Name</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['FirstName']}>{customerDetails.FirstName}</TableCell>
      <TableHeaderCell>Last Name</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['LastName']}>{customerDetails.LastName}</TableCell>
      <TableHeaderCell>ID</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['Guest_id']}>{customerDetails.Guest_id}</TableCell>
      <TableHeaderCell>DOB</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['DOB']}>{formatDate({ date: customerDetails.DOB })}</TableCell>
      <TableHeaderCell>Phone</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['PhoneNo']}>{formatPhone(customerDetails.PhoneNo)}</TableCell>
      <TableHeaderCell>Cell</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['CellPhone']}>{formatPhone(customerDetails.CellPhone)}</TableCell>
      <TableHeaderCell>Email</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['Email']}>{customerDetails.Email}</TableCell>
      <TableHeaderCell>Type</TableHeaderCell>
      <TableCell highlight={matchingAttributes?.['CustomerTypeId']}>{customerTypeDescription}</TableCell>
      {showMJStateId && (
        <>
          <TableHeaderCell>MJID</TableHeaderCell>
          <TableCell highlight={matchingAttributes?.['MJStateIDNo']}>{customerDetails.MJStateIDNo}</TableCell>
        </>
      )}
      <TableHeaderCell>Status</TableHeaderCell>
      <TableCell>{customerDetails.status}</TableCell>
    </TableContent>
  );
};

const TableContent = styled.div`
  display: grid;
  grid-template-columns: 65px minmax(0, 1fr);
  grid-template-rows: auto;
  align-items: center;
  gap: 0 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${colors.dutchie.gray20};
`;

const cellProps = css`
  display: flex;
  align-items: center;
  height: 2.125rem;
  line-height: 1.25rem;
`;

const TableHeaderCell = styled.div`
  ${cellProps}
  color: ${colors.dutchie.gray80};
  font-size: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
`;

const hightlightedText = css`
  color: ${colors.dutchie.green};
  font-weight: 600;
`;

const TableCell = styled.div<{ highlight?: boolean }>`
  ${cellProps}
  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  word-break: break-all;
  ${({ highlight }) => (highlight ? hightlightedText : '')}
`;
