import React from 'react';

type ProductsIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function ProductsIcon(props: ProductsIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.34884 0.822719C3.34884 0.368344 3.72367 0 4.18605 0H11.8649C12.5306 0.00013966 13.1691 0.259949 13.64 0.722347L23.7548 10.6621C24.0817 10.9834 24.0817 11.5043 23.7548 11.8256C23.4278 12.1469 22.8977 12.1469 22.5708 11.8256L12.4564 1.88622C12.2995 1.73225 12.0867 1.64553 11.8649 1.64544H4.18605C3.72367 1.64544 3.34884 1.27709 3.34884 0.822719Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.735639 4.01378C1.20666 3.55091 1.8455 3.29087 2.51163 3.29087H9.35404C10.0197 3.29145 10.658 3.55172 11.1285 4.01447L20.3378 13.0633C20.8087 13.5261 21.0733 14.1539 21.0733 14.8084C21.0733 15.4629 20.8088 16.0905 20.3379 16.5534L13.4975 23.2765C13.2642 23.5059 12.9873 23.6878 12.6825 23.812C12.3776 23.9361 12.0509 24 11.7209 24C11.391 24 11.0642 23.9361 10.7594 23.812C10.4546 23.6878 10.1776 23.5059 9.94437 23.2765L0.73526 14.2268C0.50207 13.9975 0.31694 13.7251 0.190815 13.4256C0.0647383 13.1261 -9.94889e-05 12.8052 1.14584e-07 12.4812V5.75903C1.14584e-07 5.10443 0.264618 4.47665 0.735639 4.01378ZM2.51163 4.93631C2.28959 4.93631 2.07664 5.02299 1.91963 5.17728C1.76262 5.33157 1.67442 5.54083 1.67442 5.75903V12.4812C1.67437 12.5893 1.69598 12.6966 1.73802 12.7965C1.78003 12.8962 1.84161 12.9869 1.91925 13.0633L11.1286 22.1132C11.2063 22.1897 11.2988 22.2505 11.4004 22.2919C11.502 22.3333 11.6109 22.3546 11.7209 22.3546C11.8309 22.3546 11.9398 22.3333 12.0414 22.2919C12.1431 22.2505 12.2354 22.1899 12.3131 22.1134L19.1538 15.3901C19.3107 15.2358 19.3989 15.0265 19.3989 14.8084C19.3989 14.5903 19.3108 14.3811 19.1539 14.2268L9.94419 5.17764C9.78741 5.02338 9.57473 4.93659 9.35288 4.93631H2.51163Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.18549 7.28526C5.4225 7.18879 5.67652 7.13913 5.93306 7.13913C6.1896 7.13913 6.44362 7.18879 6.68063 7.28526C6.91764 7.38173 7.13299 7.52313 7.31439 7.70139C7.49578 7.87965 7.63968 8.09128 7.73785 8.32418C7.83602 8.55709 7.88655 8.80672 7.88655 9.05881C7.88655 9.31091 7.83602 9.56053 7.73785 9.79344C7.63968 10.0263 7.49578 10.238 7.31439 10.4162C7.13299 10.5945 6.91764 10.7359 6.68063 10.8324C6.44362 10.9288 6.1896 10.9785 5.93306 10.9785C5.67652 10.9785 5.4225 10.9288 5.18549 10.8324C4.94848 10.7359 4.73313 10.5945 4.55174 10.4162C4.37034 10.238 4.22644 10.0263 4.12827 9.79344C4.0301 9.56053 3.97957 9.31091 3.97957 9.05881C3.97957 8.80671 4.0301 8.55709 4.12827 8.32418C4.22644 8.09128 4.37034 7.87965 4.55174 7.70139C4.73313 7.52314 4.94848 7.38173 5.18549 7.28526ZM5.93306 8.41892C5.84755 8.41892 5.76287 8.43547 5.68387 8.46763C5.60487 8.49978 5.53309 8.54692 5.47262 8.60634C5.41215 8.66576 5.36419 8.7363 5.33146 8.81393C5.29874 8.89157 5.2819 8.97478 5.2819 9.05881C5.2819 9.14284 5.29874 9.22605 5.33146 9.30369C5.36419 9.38132 5.41215 9.45186 5.47262 9.51128C5.53309 9.5707 5.60487 9.61784 5.68387 9.64999C5.76287 9.68215 5.84755 9.6987 5.93306 9.6987C6.01857 9.6987 6.10325 9.68215 6.18225 9.64999C6.26125 9.61784 6.33304 9.5707 6.3935 9.51128C6.45397 9.45186 6.50193 9.38132 6.53466 9.30369C6.56738 9.22605 6.58422 9.14284 6.58422 9.05881C6.58422 8.97478 6.56738 8.89157 6.53466 8.81393C6.50193 8.7363 6.45397 8.66576 6.3935 8.60634C6.33304 8.54692 6.26125 8.49978 6.18225 8.46763C6.10325 8.43547 6.01857 8.41892 5.93306 8.41892Z'
        fill={color}
      />
    </svg>
  );
}
