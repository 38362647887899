export enum PusherChannelType {
    Guest = 'Guest',
    DutchiePayInStore = 'DutchiePayInStore',
    Returns = 'Refunds',
    Payments = 'Payments',
    FeatureFlags = 'FeatureFlags',
}

export type PusherChannelConfiguration = {
    PusherCluster: string;
    PusherKey: string;
    ChannelName: string;
};

export type PusherAuthRequest = {
    SocketId: string;
};

export type PusherAuthResponse = {
    auth: string;
};

export type PusherConnectionStates = {
    previous: string;
    current: string;
};
