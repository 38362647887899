import React from 'react';
import styled from 'styled-components';

import { Category } from './Category';
import { colors } from 'css/Theme';

import type { AllotmentByCategory } from '../useViewAllotmentDetailPopup';

export const AllotmentCategories = ({ orderType, categories }: AllotmentByCategory) => {
  return (
    <GroupContainer>
      {orderType && <OrderType>{orderType}</OrderType>}
      {categories.map((allotment) => (
        <Category key={allotment.Category} allotment={allotment} />
      ))}
    </GroupContainer>
  );
};

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderType = styled.div`
  color: ${colors.dutchie.gray80};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
`;
