import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import { format, add, sub } from 'date-fns';
import { Popup, usePopup } from 'components/popups';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import {
  getPrescriptions,
  addPrescription,
  completePrescription,
  deletePrescription,
  getCustomerDetails,
} from 'store/actions/CustomerActions';
import { findCorrespondingUnitWithId, unitsAndUnitIds } from 'util/Helpers';
import * as Yup from 'yup';
import { Prescription } from 'models/Customer';
import { Cell, Column } from 'react-table';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { ReactComponent as ElipsisIcon } from 'assets/icons/elipsis-icon.svg';
import { DeletePrescriptionPopup } from './DeletePrescriptionPopup';
import { TableCompact } from 'components/tables';
import { errorNotification } from 'store/actions/NotificationsActions';
import { Button, CancelButton as _CancelButton } from 'components/buttons';
import { InputField, TextAreaField, DatePickerField, SelectField } from 'components/inputs';
import { colors } from 'css/Theme';
import { ValidatedField } from 'models/Settings';
import { useProductDropDownOptions } from 'util/hooks/products/useProductDropDownOptions';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type FormSchema = Yup.StringSchema | Yup.NumberSchema<number | null> | Yup.DateSchema;

const CancelButton = styled(_CancelButton)`
  padding-left: 0;
`;

const PrescriptionPopupBase = styled(Popup)`
  width: 80%;
  max-width: 1500px;
`;

const PrescriptionFormWrapper = styled.div`
  margin: 0 2rem 0.5rem 2rem;
  border-bottom: 1px solid ${colors.dutchie.shadowGrey};
`;

const PrescriptionColsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;
  box-shadow: 0 -1px 0 ${colors.dutchie.shadowGrey};
`;

const TableContainer = styled.div`
  margin: 0 2rem;
`;

type PrescriptionFormValues = {
  prescriptionDate: string;
  expirationDate: string;
  completeDate: string;
  PrescriptionNumber: number;
  note: string;
  quantity: string | null;
  unitId: number | null;
  productId: number | null;
  StateRXSerialNumber: string | null;
  ElectronicPrescription?: string;
  DoseId?: string;
};

type PrescriptionPopupProps = {
  isVisible: boolean;
  hide: () => void;
  guestId?: number;
  fieldOptions?: ValidatedField;
};

export const PrescriptionPopup: FC<PrescriptionPopupProps> = ({ isVisible, hide, guestId, fieldOptions }) => {
  const guest = useSelector((state: State) => state.customer.details);
  const prescriptions = useSelector((state: State) => state.customer.prescriptions);
  const { productOptions } = useProductDropDownOptions();
  const locationSettings = useSelector((state: State) => state.settings.locationSettings);
  const [selected, setSelected] = useState<Prescription | undefined>();
  const { isVisible: deletePopupVisible, toggle: toggleDeletePopup } = usePopup();
  const register = useSelector((state: State) => state.settings.selectedRegister);

  const dispatch = useDispatch();

  useEffect(() => {
    if (guest) {
      dispatch(getPrescriptions({ guestId: guest.Guest_id }));
    }
  }, [dispatch, guest, register]);

  const columns: Array<Column<Prescription>> = [
    { Header: 'Rx Number', accessor: 'PrescriptionNumber' },
    {
      Header: 'Prescription Date',
      accessor: (p) =>
        `${p.PrescriptionDate ? format(new Date(p.PrescriptionDate), 'MM/dd/yyyy') : ''} - ${
          p.ExpirationDate ? format(new Date(p.ExpirationDate), 'MM/dd/yyyy') : ''
        }`,
    },
    {
      Header: 'Completed Date',
      accessor: 'CompletedDate',
      Cell: ({ value }) => (value ? format(new Date(value), 'MM/dd/yyyy') : ''),
    },
    { Header: 'Note', accessor: 'Note' },
  ];

  if (locationSettings?.State !== 'OH') {
    columns.push({ Header: 'State', accessor: 'StateRXSerialNumber' });
    columns.push({
      Header: 'Quantity',
      accessor: (p) =>
        `${p.Quantity ? Number(p.Quantity).toFixed(2) : 0} ${p.UnitId ? findCorrespondingUnitWithId(p.UnitId) : ''}`,
    });
    columns.push({ Header: 'Doctor', accessor: 'DoctorsName' });
    columns.push({
      Header: 'Electronic',
      accessor: (p) => {
        if (p.ElectronicPrescription === true) {
          return 'Yes';
        } else if (p.ElectronicPrescription === false) {
          return 'No';
        } else {
          return '';
        }
      },
    });
  }

  if (locationSettings?.State === 'NY') {
    columns.push({ Header: 'Dose ID', accessor: 'DoseId' });
  }

  columns.push({
    id: 'context',
    disableSortBy: true,
    width: 10,
    Cell: (cell: Cell<Prescription>) => (
      <OverflowMenu
        anchor={<ElipsisIcon />}
        menuOptions={[
          {
            text: 'Delete',
            onClick: () => {
              setSelected(cell.row.original);
              toggleDeletePopup();
            },
          },
        ]}
      />
    ),
  });

  const onSavePrescription = async (
    values: PrescriptionFormValues,
    validate?: () => Promise<FormikErrors<FormikValues>>
  ) => {
    if (validate) {
      const resp = await validate();
      if (Object.keys(resp).length !== 0) {
        for (const error in resp) {
          dispatch(errorNotification(`${error} is invalid`));
        }
        return;
      }
    }

    if (guest) {
      await dispatch(
        addPrescription({
          PrescriptionId: selected?.PrescriptionId ? selected?.PrescriptionId : 0,
          AcctId: guest?.Guest_id,
          PrescriptionDate: values.prescriptionDate,
          ExpirationDate: values.expirationDate,
          CompletedDate: values.completeDate,
          Note: values.note,
          Quantity: Number(values.quantity) ? Number(values.quantity) : null,
          UnitId: values.unitId ? values.unitId : null,
          ProductId: values.productId ? values.productId : null,
          StateRXSerialNumber: values.StateRXSerialNumber ? values.StateRXSerialNumber : null,
          ElectronicPrescription: values.ElectronicPrescription ? values.ElectronicPrescription === 'true' : undefined,
          DoseId: values.DoseId ? values.DoseId : undefined,
        })
      );
      dispatch(getPrescriptions({ guestId: guest.Guest_id }));
      //setSelected doesn't technically change when adding a prescription but we want initialValues to reinitialize
      //so briefly changing it and then setting it back to undefined does that
      setSelected(prescriptions[0]);
      setSelected(undefined);
      await dispatch(getCustomerDetails({ guestId: guest.Guest_id }));
    }
  };

  const onCompletePrescription = async (
    values: PrescriptionFormValues,
    validate?: () => Promise<FormikErrors<FormikValues>>
  ) => {
    if (validate) {
      const resp = await validate();
      if (Object.keys(resp).length !== 0) {
        for (const error in resp) {
          dispatch(errorNotification(`${error} is invalid`));
        }
        return;
      }
    }

    if (!values.completeDate) {
      dispatch(errorNotification('Please enter a completion date'));
      return;
    }

    if (guest && selected) {
      await dispatch(
        completePrescription({
          PrescriptionId: selected.PrescriptionId,
          CompletedDate: values.completeDate,
        })
      );
      setSelected(undefined);
      dispatch(getPrescriptions({ guestId: guest.Guest_id }));
    }
  };

  const onDeletePrescription = async () => {
    if (guest && selected) {
      await dispatch(
        deletePrescription({
          PrescriptionId: selected.PrescriptionId,
        })
      );
      setSelected(undefined);
      dispatch(getPrescriptions({ guestId: guest.Guest_id }));
    }
  };

  const requiredField = (field: FormSchema, required: boolean) => {
    return required ? field.required() : field.nullable();
  };

  const isFieldRequired = (fieldName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validationSchema: any = PrescriptionFormSchema?.describe();
    return (
      validationSchema &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validationSchema.fields[fieldName].tests.findIndex(({ name }: any) => name === 'required') >= 0
    );
  };

  const getFieldLabelSuffix = (required: boolean) => {
    return required ? ' *' : '';
  };

  const isOhioLocation = locationSettings?.State === 'OH';
  const isNYLocation = locationSettings?.State === 'NY';

  const PrescriptionFormSchema = useMemo(() => {
    if (!fieldOptions) {
      return Yup.object().shape({
        prescriptionDate: Yup.date().required(),
        expirationDate: Yup.date().required(),
      });
    }

    return Yup.object().shape({
      prescriptionDate: requiredField(Yup.date(), fieldOptions['Prescription Date']?.Required),
      expirationDate: requiredField(Yup.date(), fieldOptions['Prescription Expiration Date']?.Required),
      StateRXSerialNumber: requiredField(Yup.string(), !isOhioLocation && fieldOptions['State RX Number']?.Required),
      ElectronicPrescription: requiredField(Yup.string(), fieldOptions['Electronic Prescription']?.Required),
      productId: requiredField(Yup.number(), !isOhioLocation && fieldOptions['Prescription Product']?.Required),
      quantity: requiredField(Yup.number(), !isOhioLocation && fieldOptions['Prescription Quantity']?.Required),
      unitId: requiredField(Yup.number(), !isOhioLocation && fieldOptions['Prescription Unit']?.Required),
      note: requiredField(Yup.string(), fieldOptions['Prescription Notes']?.Required),
    });
  }, [fieldOptions, isOhioLocation]);

  const yearFromNowMinusOneDay = (value: Date | null) => {
    let date = value;
    date = date && add(date, { years: 1 });
    date = date && sub(date, { days: 1 });
    return date;
  };

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <PrescriptionPopupBase
      caption='Manage Prescriptions'
      isVisible={isVisible}
      hide={hide}
      contentMaxHeight={isModalBridgeEnabled ? '100%' : undefined}
    >
      <DeletePrescriptionPopup
        isVisible={deletePopupVisible}
        hide={toggleDeletePopup}
        guest={guest}
        prescription={selected}
        onDelete={onDeletePrescription}
      />
      <Formik
        initialValues={{
          PrescriptionNumber: selected?.PrescriptionNumber || 0,
          PrescriptionId: selected?.PrescriptionId || 0,
          prescriptionDate: selected?.PrescriptionDate ? selected?.PrescriptionDate : '',
          expirationDate: selected?.ExpirationDate ? selected?.ExpirationDate : '',
          completeDate: selected?.CompletedDate ? selected?.CompletedDate : '',
          note: selected?.Note || '',
          quantity: selected?.Quantity ? selected?.Quantity : '',
          unitId: selected?.UnitId || null,
          productId: selected?.ProductId || null,
          StateRXSerialNumber: selected?.StateRXSerialNumber || '',
          ElectronicPrescription: selected?.ElectronicPrescription?.toString(),
          DoseId: selected?.DoseId || '',
        }}
        validationSchema={PrescriptionFormSchema}
        validateOnMount
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {({ validateForm, values }) => {
          return (
            <>
              <Form>
                <PrescriptionFormWrapper>
                  <PrescriptionColsContainer>
                    {fieldOptions && !fieldOptions['Prescription Date']?.Hidden && (
                      <DatePickerField
                        labelText={`Prescription Date${getFieldLabelSuffix(isFieldRequired('prescriptionDate'))}`}
                        name='prescriptionDate'
                        updateField='expirationDate'
                        onDateChange={yearFromNowMinusOneDay}
                        placeholderText='mm/dd/yyyy'
                      />
                    )}
                    {fieldOptions && !fieldOptions['Prescription Expiration Date']?.Hidden && (
                      <DatePickerField
                        labelText={`Expiration Date${getFieldLabelSuffix(isFieldRequired('expirationDate'))}`}
                        name='expirationDate'
                        placeholderText='mm/dd/yyyy'
                      />
                    )}
                    {!isOhioLocation && fieldOptions && !fieldOptions['State RX Number']?.Hidden && (
                      <InputField
                        name='StateRXSerialNumber'
                        label={`State RX Number${getFieldLabelSuffix(isFieldRequired('StateRXSerialNumber'))}`}
                        placeholder='...'
                      />
                    )}
                    {isNYLocation && <InputField label='Dose ID' name='DoseId' placeholder='...' />}
                    {selected && (
                      <DatePickerField labelText='Completed Date' name='completeDate' placeholderText='mm/dd/yyyy' />
                    )}
                    {fieldOptions && !fieldOptions['Electronic Prescription']?.Hidden && (
                      <SelectField
                        label={`Electronic Prescription${getFieldLabelSuffix(
                          isFieldRequired('ElectronicPrescription')
                        )}`}
                        name={'ElectronicPrescription'}
                        placeholder='-'
                        options={[
                          { label: 'Yes', value: 'true' },
                          { label: 'No', value: 'false' },
                        ]}
                      />
                    )}
                    {!isOhioLocation && (
                      <>
                        {fieldOptions && !fieldOptions['Prescription Product']?.Hidden && (
                          <SelectField
                            name='productId'
                            label={`Product${getFieldLabelSuffix(isFieldRequired('productId'))}`}
                            placeholder='Select Product'
                            options={productOptions}
                          />
                        )}
                        {fieldOptions && !fieldOptions['Prescription Quantity']?.Hidden && (
                          <InputField
                            label={`Quantity${getFieldLabelSuffix(isFieldRequired('quantity'))}`}
                            name='quantity'
                            placeholder='...'
                          />
                        )}
                        {fieldOptions && !fieldOptions['Prescription Unit']?.Hidden && (
                          <SelectField
                            label={`Unit${getFieldLabelSuffix(isFieldRequired('unitId'))}`}
                            name={'unitId'}
                            placeholder='Select Unit'
                            options={unitsAndUnitIds}
                          />
                        )}
                      </>
                    )}
                  </PrescriptionColsContainer>
                  {fieldOptions && !fieldOptions['Prescription Notes']?.Hidden && (
                    <TextAreaField
                      name='note'
                      label={`Notes${getFieldLabelSuffix(isFieldRequired('note'))}`}
                      rows={2}
                    />
                  )}
                </PrescriptionFormWrapper>
              </Form>
              <TableContainer>
                <TableCompact<Prescription>
                  data={prescriptions || []}
                  pageSize={5}
                  columns={columns}
                  onClick={setSelected}
                />
              </TableContainer>
              <ButtonContainer>
                <CancelButton type='button' onClick={hide}>
                  Cancel
                </CancelButton>
                <div>
                  {selected && !selected.CompletedDate && (
                    <Button
                      marginRight={12}
                      secondary
                      type='button'
                      onClick={() => onCompletePrescription(values, validateForm)}
                    >
                      Complete
                    </Button>
                  )}
                  <Button type='button' onClick={() => onSavePrescription(values, validateForm)}>
                    Save
                  </Button>
                </div>
              </ButtonContainer>
            </>
          );
        }}
      </Formik>
    </PrescriptionPopupBase>
  );
};
