import React, { ComponentProps } from 'react';

import { PersonalTab as NewPersonalTab } from './PersonalTab';
import { PersonalTab as OldPersonalTab } from './PersonalTab-deprecated';
import { useChangeCustomerTypeInCart } from 'util/hooks/launch-darkly/useChangeCustomerTypeInCart';

type PersonalTabProps = ComponentProps<typeof NewPersonalTab>;

export const PersonalTab = (props: PersonalTabProps) => {
  const { isChangeCustomerTypeInCartEnabled } = useChangeCustomerTypeInCart();
  return isChangeCustomerTypeInCartEnabled ? <NewPersonalTab {...props} /> : <OldPersonalTab {...props} />;
};
