import React, { FC } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { Input } from './Input';

import type { InputComponentProps } from './types';

export type InputFieldComponentProps = {
  autoComplete?: string;
  name: string;
  className?: string;
  automationId?: string;
} & InputComponentProps;

export const InputField: FC<InputFieldComponentProps> = (props) => {
  const { name, className, automationId, ...inputProps } = props;
  const [field, meta] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    <InputFieldContainer className={className} title={hasError ? meta.error : ''}>
      <Input {...inputProps} {...field} hasError={Boolean(hasError)} data-testid={automationId} />
    </InputFieldContainer>
  );
};

const InputFieldContainer = styled.div`
  margin-bottom: 1rem;
`;
