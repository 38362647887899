import React, { useEffect, useCallback } from 'react';
import ReactBarcodeReader from './ReactBarcodeReader';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { ScanBarcodePayload, Peripheral } from 'util/capacitor/peripheral';
import { isAndroid } from 'util/hooks';
import { logger, customEventKeys } from 'util/logger';

import type { ScannerAction } from 'store/reducers/ScannerReducer';
import type { ScannerDataEventContext } from 'util/logger/types/scanning';

import { BarcodeData, HardwareService, Scanner } from '@dutchie/capacitor-hardware';
import { useHardwareLibrary } from 'util/hooks/launch-darkly/useHardwareLibrary';

export type BarcodeReaderProps = {
  onScan?: (barcode: string) => void;
  onError?: (barcode: string) => void;
  onReceive?: (char: string) => void;
  onKeyDetect?: (char: string) => void;
  timeBeforeScanTest?: number;
  avgTimeByChar?: number;
  minLength?: number;
  endChar?: number[];
  startChar?: number[];
  scanButtonKeyCode?: number;
  scanButtonLongPressThreshold?: number;
  onScanButtonLongPressed?: () => void;
  stopPropagation?: boolean;
  preventDefault?: boolean;
  testCode?: string;
};

export function BarcodeReaderWrapper(props: BarcodeReaderProps): JSX.Element {
  return <ReactBarcodeReader {...props} />;
}

type BarcodeScannerProps = {
  onScan?: ScannerAction;
};

export function BarcodeScanner({ onScan }: BarcodeScannerProps): JSX.Element {
  const scannerAction = useSelector((state: State) => state.scanner.onScan);

  const isHardwareLibraryActive = useHardwareLibrary();

  const handleScan = useCallback(
    async (scannedString: string, scannerId?: number) => {
      if (!scannedString.trim().length) {
        logger.warn('scanned barcode contains only spaces', {
          key: customEventKeys.scanning.invalidBarcode,
          scanData: { scannerId, string: scannedString },
        });

        return;
      }

      if (isAndroid && !isHardwareLibraryActive) {
        try {
          const { results: availableScanners } = await Peripheral.searchScanners();
          logger.error('Web scanning triggered on Elo', { scannedString, availableScanners });
        } catch (e) {
          logger.error('Web scanning trigger on Elo', { scannedString });
        }
      } else {
        logger.info<ScannerDataEventContext>(`barcode scanned: ${scannedString}`, {
          key: customEventKeys.scanning.data,
          scanData: { scannerId, string: scannedString },
        });
      }

      if (onScan) {
        onScan(scannedString, scannerId);
      } else {
        scannerAction?.(scannedString, scannerId);
      }
    },
    [isHardwareLibraryActive, onScan, scannerAction]
  );

  useEffect(() => {
    if (isHardwareLibraryActive) {
      // The hardware library handles listening for the Elo events through the
      // NativeScannerService implementation
      return;
    }

    if (isAndroid) {
      // @ts-ignore-next-line // onScannerData is an event used in capicator but the event type doesn't match up with a window event type, so we are gonna allow TS to think it's any
      const handleScanEvent = (scanEvent) => {
        const scanData = scanEvent as ScanBarcodePayload;
        logger.info<ScannerDataEventContext>(`barcode scanned: ${scanData?.string}`, {
          key: customEventKeys.scanning.data,
          scanData,
        });

        if (onScan) {
          onScan(scanData?.string, scanData?.scanner?.id);
        } else {
          scannerAction?.(scanData?.string, scanData?.scanner?.id);
        }
      };

      window.addEventListener('onScannerData', handleScanEvent);

      return () => {
        window.removeEventListener('onScannerData', handleScanEvent);
      };
    }
  }, [isHardwareLibraryActive, scannerAction, onScan]);

  useEffect(() => {
    if (!isHardwareLibraryActive) {
      return;
    }

    const handleScanEvent = (scanEvent: Event) => {
      const data = (scanEvent as CustomEvent).detail as {barcode: BarcodeData; device: Scanner};

      // Decoding bytes feels like the safest approach for consistent values that have been through multiple
      // rounds of serialization and deserialization.
      const barcode = new TextDecoder().decode(data.barcode.bytes);

      // Temporary to learn whether this is a potential issue or not
      if (barcode !== data.barcode.text) {
        logger.debug('decoded barcode mismatch', {
          key: customEventKeys.scanning.mismatchedBarcode,
          original: data.barcode.text,
          decoded: barcode,
        });
      }

      handleScan(barcode);
    };

    HardwareService.scanner.addEventListener('barcode', handleScanEvent);

    return () => {
      HardwareService.scanner.removeEventListener('barcode', handleScanEvent);
    };
  }, [handleScan, isHardwareLibraryActive]);

  return (
    <BarcodeReaderWrapper
      endChar={[]}
      stopPropagation
      preventDefault
      timeBeforeScanTest={250}
      minLength={3}
      onScan={handleScan}
    />
  );
}
