import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const ReceiptIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41699 6.66683C4.41699 6.34466 4.67816 6.0835 5.00033 6.0835H10.0003C10.3225 6.0835 10.5837 6.34466 10.5837 6.66683C10.5837 6.989 10.3225 7.25016 10.0003 7.25016H5.00033C4.67816 7.25016 4.41699 6.989 4.41699 6.66683Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41699 9.99984C4.41699 9.67767 4.67816 9.4165 5.00033 9.4165H10.0003C10.3225 9.4165 10.5837 9.67767 10.5837 9.99984C10.5837 10.322 10.3225 10.5832 10.0003 10.5832H5.00033C4.67816 10.5832 4.41699 10.322 4.41699 9.99984Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41699 13.3333C4.41699 13.0112 4.67816 12.75 5.00033 12.75H10.0003C10.3225 12.75 10.5837 13.0112 10.5837 13.3333C10.5837 13.6555 10.3225 13.9167 10.0003 13.9167H5.00033C4.67816 13.9167 4.41699 13.6555 4.41699 13.3333Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.167 6.66683C13.167 6.34466 13.4282 6.0835 13.7503 6.0835H15.0003C15.3225 6.0835 15.5837 6.34466 15.5837 6.66683C15.5837 6.989 15.3225 7.25016 15.0003 7.25016H13.7503C13.4282 7.25016 13.167 6.989 13.167 6.66683Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.167 9.99984C13.167 9.67767 13.4282 9.4165 13.7503 9.4165H15.0003C15.3225 9.4165 15.5837 9.67767 15.5837 9.99984C15.5837 10.322 15.3225 10.5832 15.0003 10.5832H13.7503C13.4282 10.5832 13.167 10.322 13.167 9.99984Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.167 13.3333C13.167 13.0112 13.4282 12.75 13.7503 12.75H15.0003C15.3225 12.75 15.5837 13.0112 15.5837 13.3333C15.5837 13.6555 15.3225 13.9167 15.0003 13.9167H13.7503C13.4282 13.9167 13.167 13.6555 13.167 13.3333Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.27709 0.0859246C2.49507 -0.00436435 2.74597 0.0455432 2.9128 0.212375L5.00033 2.2999L7.08785 0.212375C7.31565 -0.0154305 7.685 -0.0154305 7.9128 0.212375L10.0003 2.2999L12.0878 0.212375C12.3157 -0.0154305 12.685 -0.0154305 12.9128 0.212375L15.0003 2.2999L17.0878 0.212375C17.2547 0.0455432 17.5056 -0.00436435 17.7236 0.0859246C17.9415 0.176213 18.0837 0.388918 18.0837 0.624854V19.3749C18.0837 19.6108 17.9415 19.8235 17.7236 19.9138C17.5056 20.0041 17.2547 19.9542 17.0878 19.7873L15.0003 17.6998L12.9128 19.7873C12.685 20.0151 12.3157 20.0151 12.0878 19.7873L10.0003 17.6998L7.9128 19.7873C7.685 20.0151 7.31565 20.0151 7.08785 19.7873L5.00033 17.6998L2.9128 19.7873C2.74597 19.9542 2.49507 20.0041 2.27709 19.9138C2.05912 19.8235 1.91699 19.6108 1.91699 19.3749V0.624854C1.91699 0.388918 2.05912 0.176213 2.27709 0.0859246ZM3.08366 2.03315V17.9666L4.58785 16.4624C4.81565 16.2346 5.185 16.2346 5.4128 16.4624L7.50033 18.5499L9.58785 16.4624C9.81565 16.2346 10.185 16.2346 10.4128 16.4624L12.5003 18.5499L14.5878 16.4624C14.8157 16.2346 15.185 16.2346 15.4128 16.4624L16.917 17.9666V2.03315L15.4128 3.53733C15.185 3.76514 14.8157 3.76514 14.5878 3.53733L12.5003 1.44981L10.4128 3.53733C10.185 3.76514 9.81565 3.76514 9.58785 3.53733L7.50033 1.44981L5.4128 3.53733C5.185 3.76514 4.81565 3.76514 4.58785 3.53733L3.08366 2.03315Z" fill={color} />
    </svg>
  );
};
