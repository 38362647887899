import React, { FC, useState, useEffect } from 'react';
import { CashAmount } from 'components/text';
import { Cart } from 'models/Cart';
import { cartHasRoundingNew } from 'util/Helpers';

import { DiscountBanner } from './DiscountBanner';
import { TotalsSummaryContainer, BalanceDueSection, BalanceAmount, TotalRow, TotalAmount } from '../../CFD.styles';

type TotalsSummaryProps = {
  cart?: Cart;
  features: Record<string, boolean>;
};

export const TotalsSummary: FC<TotalsSummaryProps> = ({ cart, features }) => {
  const useStatefulCheckout = features.StatefulCheckout;
  const totalIsZero = (cart?.GrandTotalRounded ?? 0) === 0;
  const [hideTotals, setHideTotals] = useState(useStatefulCheckout && totalIsZero);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hideTotals && !totalIsZero) {
      setLoading(true);
      // This is a bit of a hack, but we need a little delay after receiving new totals
      // from register so we can see the animation when transitioning from dashes to numbers
      timeout = setTimeout(() => {
        setHideTotals(false);
        setLoading(false);
      }, 1000);
    } else if (!hideTotals && totalIsZero) {
      setHideTotals(true);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hideTotals, totalIsZero, cart]);

  const useFeesDonations = features.UseFeesDonations;
  const showFeesDonations = useFeesDonations && (cart?.FeesAndDonations ?? 0) !== 0;
  const hasDiscount = (cart?.TotalDiscountAndLoyalty ?? 0) > 0;
  const showTip = (cart?.TipAmount ?? 0) > 0;

  return (
    <TotalsSummaryContainer>
      <BalanceDueSection>
        Balance Due
        <BalanceAmount>
          <CashAmount
            value={cart?.GrandTotalRounded}
            empty={hideTotals}
            loading={loading}
            automationId='totals-summary_balance-due_balance-amount'
          />
        </BalanceAmount>
      </BalanceDueSection>
      <TotalRow>
        Subtotal
        <TotalAmount>
          <CashAmount value={cart?.SubTotal} automationId='totals-summary_total-row_subtotal-amount' />
        </TotalAmount>
      </TotalRow>
      <TotalRow highlight={hasDiscount} data-testid='totals-summary_total-row_discounts-row'>
        Discounts & loyalty
        <TotalAmount>
          <CashAmount
            value={Math.abs(cart?.TotalDiscountAndLoyalty ?? 0) * -1}
            empty={hideTotals}
            loading={loading}
            automationId='totals-summary_total-row_discount-amount'
          />
        </TotalAmount>
      </TotalRow>
      <TotalRow>
        Total tax
        <TotalAmount>
          <CashAmount
            value={cart?.Tax}
            empty={hideTotals}
            loading={loading}
            automationId='totals-summary_total-row_tax-amount'
          />
        </TotalAmount>
      </TotalRow>
      {showFeesDonations && (
        <TotalRow>
          Fees & donations
          <TotalAmount>
            <CashAmount
              value={cart?.FeesAndDonations}
              empty={hideTotals}
              loading={loading}
              automationId='totals-summary_total-row_fees-donations-amount'
            />
          </TotalAmount>
        </TotalRow>
      )}
      {cartHasRoundingNew(features) && (
        <TotalRow>
          Rounding
          <TotalAmount>
            <CashAmount
              value={cart?.RoundingAmount}
              empty={hideTotals}
              loading={loading}
              automationId='totals-summary_total-row_rounding-amount'
            />
          </TotalAmount>
        </TotalRow>
      )}
      {showTip && (
        <TotalRow>
          Tip
          <TotalAmount>
            <CashAmount
              value={cart?.TipAmount}
              empty={hideTotals}
              loading={loading}
              automationId='totals-summary_total-row_tip-amount'
            />
          </TotalAmount>
        </TotalRow>
      )}
      {hasDiscount && !hideTotals && <DiscountBanner amount={cart?.TotalDiscountAndLoyalty} />}
    </TotalsSummaryContainer>
  );
};
