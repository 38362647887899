import { ReactNode, createElement } from 'react';
import { DefaultCell } from '../components/DefaultCell';
import { Column, CustomSortType } from '../TableProps';
import { memoized } from './memoized';
import { getPaginationDetails } from './pagination';
import { sortItems } from './sort';

const hasId = (item: unknown): item is { id: string } => typeof item?.id === 'string' || typeof item?.id === 'number';
// Attempt to use the item's ID as a key to prevent unnecessary renders
const getKey = (item: unknown, index: number): string => (hasId(item) ? `id::${item.id}` : `index::${index}`);

type DataMatrixCell<ItemType> = {
  key: string;
  value: unknown;
  contents: ReactNode;
};

export type DataMatrixRow<ItemType> = {
  key: string;
  item: ItemType;
  selected: boolean;
  cells: DataMatrixCell<ItemType>[];
};

const calculateCell = <ItemType>(
  item: ItemType,
  column: Column<ItemType>,
  columnIndex: number
): DataMatrixCell<ItemType> => {
  const key = `column-${columnIndex}`;
  const value: unknown = typeof column.field === 'function' ? column.field(item) : item[column.field];
  const Cell = column.Cell ?? DefaultCell;
  const cellProps = column.cellProps ?? {};
  const contents = createElement(Cell, { ...cellProps, item, value }, null);

  return {
    key,
    contents,
    value,
  };
};

const calculateDataMatrix = <ItemType>(
  items: ItemType[],
  columns: Column<ItemType>[],
  pageSize: number,
  currentPage: number,
  sort: {
    column: number;
    direction: 'asc' | 'desc';
    customSort?: CustomSortType<DataMatrixRow<ItemType>>;
  } | null,
  selected?: ItemType | ItemType[]
): DataMatrixRow<ItemType>[] => {
  const { zeroBasedCurrentPageStartIndex, zeroBasedNextPageStartIndex, hasPagination } = getPaginationDetails(
    items.length,
    pageSize,
    currentPage
  );
  const selectedSet = ((): Set<ItemType> => {
    if (!selected) {
      return new Set();
    }

    if (selected instanceof Array) {
      return new Set(selected);
    }

    // single item
    return new Set([selected]);
  })();

  const transformedItems: DataMatrixRow<ItemType>[] = items.map((item, itemIndex) => ({
    key: getKey(item, itemIndex),
    item,
    cells: columns.map((column, columnIndex) => calculateCell(item, column, columnIndex)),
    selected: selectedSet.has(item),
  }));

  const sortedItems = sort
    ? sortItems(transformedItems, sort.column, sort.direction, sort.customSort)
    : transformedItems;

  const paginatedItems = hasPagination
    ? [...sortedItems].slice(zeroBasedCurrentPageStartIndex, zeroBasedNextPageStartIndex)
    : sortedItems;

  return paginatedItems;
};

export const memoizedCalculateDataMatrix = memoized(calculateDataMatrix);
