import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as KebabMenuIcon } from 'assets/icons/kebab-menu.svg';
import { OverflowMenu } from 'components/menus/OverflowMenu';
import { DragDropTarget } from 'components/layout/DragDrop';
import { Checkbox } from 'components/inputs/Checkbox';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';

type EmbeddedTableChildRowProps = {
  id: string;
  isSelectionEnabled: boolean;
  dragDropDisabled: boolean;
  selected: boolean;
  numColumns: number;
  onDragStart: () => void;
  onDragStop: () => void;
  onDragOver: () => void;
  onToggleSelection: () => void;
};

export function EmbeddedChildTableRow<ParentItem, ChildItem>(props: EmbeddedTableChildRowProps) {
  const {
    id,
    dragDropDisabled,
    onDragStart,
    onDragStop,
    onDragOver,
    selected,
    numColumns,
    isSelectionEnabled,
    onToggleSelection,
  } = props;
  const {
    childItemMap,
    childColumns,
    childActions,
    onDragStart: storeDragStart,
    onDragStop: storeDragStop,
  } = useEmbeddedTableStore<ParentItem, ChildItem>();
  const item = childItemMap[id];
  const actions = useMemo(
    () => (typeof childActions === 'function' ? childActions(item) : childActions),
    [childActions, item]
  );

  const combinedDragStart = useCallback(() => {
    storeDragStart && storeDragStart();
    onDragStart();
  }, [onDragStart, storeDragStart]);

  const combinedDragStop = useCallback(() => {
    storeDragStop && storeDragStop();
    onDragStop();
  }, [onDragStop, storeDragStop]);

  if (!item) {
    console.warn(`No child with ID ${id}`); // eslint-disable-line
    return null;
  }

  return (
    <DragDropTarget
      className='child'
      as='tr'
      effect='move'
      payload={id}
      dragDisabled={dragDropDisabled}
      dropDisabled={dragDropDisabled}
      onDragActive={combinedDragStart}
      onDragInactive={combinedDragStop}
      onDropActive={onDragOver}
      dragPlaceholder={
        <td className='child placeholder' colSpan={numColumns}>
          &nbsp;
        </td>
      }
    >
      {isSelectionEnabled && (
        <td className='child'>
          <StyledCheckbox state={selected ? 'on' : 'off'} onClick={() => onToggleSelection()} />
        </td>
      )}
      {childColumns.map(({ Cell, cellProps, style }, i) => (
        <td className='child' key={`child-${id}-column-${i}`} style={style}>
          <Cell {...cellProps} item={item} />
        </td>
      ))}
      {!!actions?.length && (
        <td className='child actions'>
          <OverflowMenu
            anchor={<KebabMenuIcon />}
            anchorProps={{ padding: '2px 1rem', showCursor: true }}
            menuOptions={actions.map((action) => ({
              text: action.label,
              onClick: () => action.onClick(item),
            }))}
          />
        </td>
      )}
    </DragDropTarget>
  );
}

const StyledCheckbox = styled(Checkbox)`
  & > svg {
    width: 1rem;
    height: 1rem;
  }
`;
