import React, { FC } from 'react';

import { ReactComponent as UserPlusIcon } from 'assets/icons/user-add.svg';
import { NavLinkButton } from 'components/buttons';

export const CreateGuestButton: FC = () => (
  <NavLinkButton to='/edit-customer' icon={<UserPlusIcon />} automationId='action-bar_button_new-guest' margin='0'>
    Create Guest
  </NavLinkButton>
);
