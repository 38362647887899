import React from 'react';
import styled from 'styled-components';

type ShortenedTextProps = {
  text: string;
};

export const ShortenedText = ({ text }: ShortenedTextProps) => {
  return <Text title={text}>{text.length > 135 ? `${text.substring(0, 135)}...` : text}</Text>;
};

const Text = styled.div`
  overflow-wrap: anywhere;
  padding-right: 10px;
`;
