import React, { FC, SVGProps } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';

import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { LinkButton } from 'components/backoffice/button';

type ProductSearchMessageProps = {
  icon?: FC<SVGProps<SVGSVGElement>>;
  message: string;
  instruction?: string;
  action?: {
    label: string;
    onClick: () => void;
  };
};

export const ProductSearchMessage: FC<ProductSearchMessageProps> = ({
  icon = Refresh,
  message,
  instruction,
  action,
}) => {
  const IconComponent = icon;

  return (
    <MessageContainer>
      <IconContainer>
        <IconComponent />
      </IconContainer>
      <Message>{message}</Message>
      {instruction && <Instruction>{instruction}</Instruction>}
      {action && <Link label={action.label} onClick={action.onClick} />}
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const IconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  margin: 0.75rem 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${colors.dutchie.gray30};

  & > svg {
    width: 100%;
    height: 100%;

    path {
      stroke: ${colors.dutchie.gray70};
    }
  }
`;

const Message = styled.div`
  color: ${colors.dutchie.gray80};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

const Instruction = styled.div`
  color: ${colors.dutchie.gray80};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Link = styled(LinkButton)`
  font-size: 1rem;
`;
