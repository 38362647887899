import React, { useReducer, useContext, createContext, PropsWithChildren, Dispatch, Reducer, useEffect } from 'react';
import { EmbeddedTableProps } from './EmbeddedTableProps';

type ParentId = string;
type ChildId = string;

type State<ParentItem, ChildItem> = EmbeddedTableProps<ParentItem, ChildItem> & {
  expandedParentId: ParentId | null;
  parentItemMap: Record<ParentId, ParentItem>;
  childItemMap: Record<ChildId, ChildItem>;
  relationshipMap: Record<ParentId, ChildId[]>;
  openMenuId: ParentId | ChildId | null;
};

type Action<ParentItem, ChildItem> =
  | { type: 'toggleExpandedParent'; parentId: string }
  | { type: 'updateProps'; props: EmbeddedTableProps<ParentItem, ChildItem> }
  | { type: 'openMenuItem'; id: ParentId | ChildId }
  | { type: 'closeMenu' };

function reducer<ParentItem, ChildItem>(state: State<ParentItem, ChildItem>, action: Action<ParentItem, ChildItem>) {
  if (action.type === 'toggleExpandedParent') {
    return {
      ...state,
      expandedParentId: action.parentId === state.expandedParentId ? null : action.parentId,
    };
  }

  if (action.type === 'openMenuItem') {
    return {
      ...state,
      openMenuId: action.id,
    };
  }

  if (action.type === 'closeMenu') {
    return {
      ...state,
      openMenuId: null,
    };
  }

  if (action.type === 'updateProps') {
    const { props } = action;
    const parentItemMap = Object.fromEntries(props.parentItems.map((item) => [props.getParentId(item), item]));
    const childItemMap = Object.fromEntries(props.childItems.map((item) => [props.getChildId(item), item]));
    const relationshipMap = props.childItems.reduce((memo, item) => {
      const parentId = props.getRelationship(item);
      const childId = props.getChildId(item);
      const siblings = memo[parentId] || [];
      return { ...memo, [parentId]: [childId, ...siblings] };
    }, {} as Record<string, string[]>);

    return {
      ...state,
      ...props,
      parentItemMap,
      childItemMap,
      relationshipMap,
    };
  }

  return state;
}

type ContextState<ParentItem, ChildItem> =
  | [State<ParentItem, ChildItem>, Dispatch<Action<ParentItem, ChildItem>>]
  | null;

const context = createContext<ContextState<unknown, unknown>>(null);

export function useEmbeddedTableStore<ParentItem, ChildItem>() {
  const contextState = useContext(context) as ContextState<ParentItem, ChildItem>;
  if (contextState === null) {
    throw new Error('State not initialized - did you use ProvideEmbeddedTableState?');
  }
  const [state, dispatch] = contextState;

  return { ...state, dispatch };
}

export function ProvideEmbeddedTableStore<ParentItem, ChildItem>({
  children,
  props,
}: PropsWithChildren<EmbeddedTableProps<ParentItem, ChildItem>>) {
  const initialState: State<ParentItem, ChildItem> = {
    ...props,
    expandedParentId: props.expandOnInit ?? null,
    parentItemMap: {},
    childItemMap: {},
    relationshipMap: {},
    openMenuId: null,
  };

  const contextState = useReducer<Reducer<State<ParentItem, ChildItem>, Action<ParentItem, ChildItem>>>(
    reducer,
    initialState
  );

  const [, dispatch] = contextState;
  useEffect(() => {
    dispatch({ type: 'updateProps', props });
  }, [dispatch, props]);

  return <context.Provider value={contextState as ContextState<unknown, unknown>}>{children}</context.Provider>;
}
