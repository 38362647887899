import styled from 'styled-components';
import { colors } from 'css/Theme';

export const EmptyPlaceHolderCircle = styled.div`
  border-radius: 75px;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dutchie.grey};
`;
