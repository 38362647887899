import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Formik, Form, FormikProps } from 'formik';

import { ConfirmationPopup } from 'components/popups';
import { callback } from 'models/Misc';
import { CanPayCheckoutRequest, PaymentMethod } from 'models/Checkout';
import { InputField } from 'components/inputs';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { checkPinForDigitalPayment, digitalPayment } from 'store/actions/CheckoutActions';

const ContentDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
`;

const StyledForm = styled(Form)`
  width: 100%;
  margin: 0 2rem;
`;

type CanPayCheckoutPopupProps = {
  paymentMethod: PaymentMethod;
  hide: callback;
  shipmentId?: number;
};

type CanPayCheckoutFormValues = {
  token?: string;
};

const initialValues: Partial<CanPayCheckoutFormValues> = {
  token: '',
};

export const CanPayCheckoutPopup: FC<CanPayCheckoutPopupProps> = ({ paymentMethod, hide, shipmentId }) => {
  const formRef = useRef<FormikProps<CanPayCheckoutFormValues> | null>(null);
  const { features } = useSelector((state: State) => state.settings);
  const checkout = useSelector((state: State) => state.checkout);
  const dispatch = useDispatch();

  const onSubmit = async (values: CanPayCheckoutFormValues) => {
    if (values) {
      if (values?.token?.length === 0) {
        return;
      }

      const data: CanPayCheckoutRequest = {
        Token: values?.token,
      };

      const pmClone = {
        id: paymentMethod.id,
        name: paymentMethod.name,
        type: paymentMethod.type,
        amount: paymentMethod.amount,
        data: data,
      };

      if (features.CheckOutPinRequired) {
        dispatch(checkPinForDigitalPayment({ code: checkout.managerPin, paymentMethod: pmClone }));
      } else {
        dispatch(digitalPayment(pmClone));
      }
    }
    hide();
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Enter Client CanPay Token'
      cancel={{
        text: 'Cancel',
        onClick: () => {
          hide();
        },
      }}
      confirm={{
        text: 'Confirm',
        id: `payButton_${shipmentId}`,
        onClick: () => {
          formRef.current?.handleSubmit();
        },
      }}
    >
      <ContentDiv>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={(values) => onSubmit(values)}
          innerRef={(instance) => (formRef.current = instance)}
        >
          <StyledForm>
            <InputField name='token' placeholder='CanPay Token' />
          </StyledForm>
        </Formik>
      </ContentDiv>
    </ConfirmationPopup>
  );
};
