import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { transformToNewCartDetails } from './types';

import type { SwitchCustomerResponse } from './types';

const SWITCH_CUSTOMER = `v2/cart/switch-customer`;

type SwitchCustomerPayload = {
  currentCustomerId: number;
  newCustomerId: number;
  shipmentId: number;
};

const transformToServerPayload = (payload: SwitchCustomerPayload) => {
  return {
    CurrentCustomerId: payload.currentCustomerId,
    NewCustomerId: payload.newCustomerId,
    ShipmentId: payload.shipmentId,
  };
};

export const useSwitchCustomerMutation = () => {
  return useMutation(async (payload: SwitchCustomerPayload) => {
    const response = await post<SwitchCustomerResponse>(SWITCH_CUSTOMER, transformToServerPayload(payload));
    return transformToNewCartDetails(response);
  });
};
