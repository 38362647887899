import React from 'react';
import styled from 'styled-components';

import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

type SkeletonProps = {
  containerHeight?: string;
  height?: number | string;
  width?: number | string;
  circle?: boolean;
  rounded?: boolean;
  mr?: number | string;
  borderRadius?: number | string;
  marginBottom?: number | string;
};

export function Skeleton({
  containerHeight,
  width,
  height,
  circle,
  rounded,
  marginBottom,
  ...props
}: SkeletonProps): JSX.Element {
  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  const style: SkeletonProps = {};

  if (width) {
    style.width = width;
  }

  if (height) {
    style.height = height;
  }

  if (width && height && circle) {
    style.borderRadius = `50%`;
  }

  if (rounded) {
    style.borderRadius = `10px`;
  }

  if (marginBottom) {
    style.marginBottom = `${marginBottom}px`;
  }

  if (isCustomerInsightsEnabled) {
    return (
      <StyledSkeleton style={style} {...props}>
        &zwnj;
      </StyledSkeleton>
    );
  }

  return (
    <SkeletonContainer containerHeight={containerHeight}>
      <StyledSkeleton style={style} {...props}>
        &zwnj;
      </StyledSkeleton>
    </SkeletonContainer>
  );
}

const SkeletonContainer = styled.div<{ containerHeight?: string }>`
  display: flex;
  ${({ containerHeight }) => containerHeight && `height: ${containerHeight}`}
`;

export const StyledSkeleton = styled.div`
  @keyframes loading {
    0% {
      background-color: #d9dfe1;
    }
    25% {
      background-color: #e8ecee;
    }
    50% {
      background-color: #eef1f2;
    }
    75% {
      background-color: #e8ecee;
    }
    100% {
      background-color: #d9dfe1;
    }
  }
  animation: loading 1s linear infinite;
  background-color: #e3e7e9;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 100%;
`;
