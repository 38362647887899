import { useCallback } from 'react';
import { PusherChannelType } from 'models/Pusher';
import { usePusherChannel } from 'messaging/pusher/usePusherChannel';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';
import { CheckedInGuest } from 'models/Guest';
import {
  UpdateGuestListRequest,
  addOrUpdateGuest,
  notifyConectionLost,
  notifyPusherConnected,
  removeGuestByTransactionIdFromList,
  removeGuestFromList,
} from 'store/actions/GuestListActions';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';

export const useOnNewGuestListPusherMessages = () => {
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();

  const parseCheckedInGuest = (data: string | object): CheckedInGuest => {
      if (typeof data === 'string') {
        return JSON.parse(data);
      } else {
        return data as CheckedInGuest;
      }
  };

  const onNewPusherMessages = useCallback(
    (eventName: string, data: string | object) => {
      if (eventName === 'checkin') {
        const guestUpdatedType: UpdateGuestListRequest = {
          guestUpdated: parseCheckedInGuest(data),
          settings: settings,
        };
        dispatch(addOrUpdateGuest(guestUpdatedType));
      // String casting below is a hack
      // With ably rollout, pusher/ably JSON notification is already parsed at this point
      // JSON.parse would cast to a number for below three cases, but the function returns a string
      // TS will handle this and cast the number back to string
      // Thus data here will definitively be a string and casting is safe
      } else if (eventName === 'checkout') {
        dispatch(removeGuestFromList({ guestId: parseInt(data as string), settings }));
      } else if (eventName === 'checkoutByTransactionId') {
        dispatch(removeGuestByTransactionIdFromList({ transactionId: parseInt(data as string), settings }));
      } else if (eventName === 'updateGuest') {
        const guestUpdatedType: UpdateGuestListRequest = {
          guestUpdated: parseCheckedInGuest(data),
          settings: settings,
        };
        dispatch(addOrUpdateGuest(guestUpdatedType));
      }
    },
    [settings, dispatch]
  );
  return onNewPusherMessages;
};

export const useGuestListPusherChannel = () => {
  const isSmartCheckinActive = useSmartCheckinActive();
  const onChannelMessage = useOnNewGuestListPusherMessages();
  const dispatch = useDispatch();

  return usePusherChannel({
    channelType: PusherChannelType.Guest,
    enabled: isSmartCheckinActive,
    onChannelMessage,
    onConnected: () => dispatch(notifyPusherConnected()),
    onDisconnected: () => dispatch(notifyConectionLost()),
  });
};
