import { isCartItemOrProductSearchResult } from 'models/Cart';
import { isProductSearchResult } from 'util/helpers/products/isProductSearchResult';

import type { CartItem } from 'models/Cart';
import type { ProductSearchResult } from 'queries/v2/product/types';

export type GenericProductType = { SerialNo?: string | number; ProductId?: string | number; ProdId?: string | number };

const isGenericProductType = (
  product: ProductSearchResult | CartItem | GenericProductType
): product is GenericProductType => Object.keys(product as GenericProductType).length <= 3;

export const getProductId = (product: ProductSearchResult | CartItem | GenericProductType): string => {
  if (!isGenericProductType(product) && !isCartItemOrProductSearchResult(product) && isProductSearchResult(product)) {
    return `${product.serialNo}${product.productId}`;
  } else if (isGenericProductType(product)) {
    return `${product.SerialNo ?? ''}${product.ProductId ?? product.ProdId ?? ''}`;
  }

  return `${product.SerialNo}${product.ProductId}`;
};
