import React from 'react';
import { ConnectionStatusPill } from 'pages/SettingsPage/Status/ConnectionStatusPill';
import { useConnectedLabelPrinter } from 'util/hooks/hardware/useConnectedHardware';

export const LabelPrinterStatusPill = () => {
  const { device } = useConnectedLabelPrinter();

  return (
    <ConnectionStatusPill
      automationId='label_printer_settings-connection_status'
      deviceName={device?.name}
      connected={!!device}
    />
  );
};
