import { useMutation, useQueryClient } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { secondaryIdentificationKeys } from './query-key-factory';

const REMOVE_SECONDARY_IDENTIFICATION = 'v2/guest/remove-secondary-identification';

type RemoveSecondaryIdentificationPayload = {
  guestId: number;
  identityId: number;
};

const transformToServerPayload = (payload: RemoveSecondaryIdentificationPayload) => {
  return {
    Guest_id: payload.guestId,
    IdentityId: payload.identityId,
  };
};

export const useRemoveSecondaryIdentificationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: RemoveSecondaryIdentificationPayload): Promise<void> => {
      await post(REMOVE_SECONDARY_IDENTIFICATION, transformToServerPayload(payload));
    },
    {
      onSuccess: (_data, payload) => {
        void queryClient.invalidateQueries(secondaryIdentificationKeys.many(payload.guestId));
      },
    }
  );
};
