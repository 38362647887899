import React from 'react';
import { ConfirmationModal } from '../modals/ConfirmationModal';

type UnsavedChangesPopupProps = {
  automationId?: string;
  close: () => void;
  discard: () => void;
  save: () => void;
};

export const UnsavedChangesPopup = ({ automationId, close, discard, save }: UnsavedChangesPopupProps): JSX.Element => {

  const handleSave = () => {
    try {
      save();
    } finally {
      close();
    }
  };

  const handleDiscard = () => {
    try {
      discard();
    } finally {
      close();
    }
  };

  return (
    <ConfirmationModal
      data-testid={automationId}
      header='Unsaved changes'
      message='Would you like to save your changes?'
      modalName='UnsavedChangesPopup'
      rightButton={{
        text: 'Save and continue',
        onClick: handleSave,
      }}
      leftButton={{
        text: 'Discard',
        transparentRed: true,
        onClick: handleDiscard,
      }}
      hide={() => close?.()}
    />
  );
};
