import React from 'react';
import { HardwareService, PeripheralType } from '@dutchie/capacitor-hardware';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsSelect } from 'pages/SettingsPage/Select/SettingsSelect';
import { useAddDeviceListItem } from '../../components/AddDeviceListItem/useAddDeviceListItem';
import { useLabelPrinterSettings } from '../hooks/useLabelPrinterSettings';
import { useHardwareServices } from '../../hooks/useHardwareServices';

export const LabelPrinterSelectListItem = () => {
  const { formValues, printers, search, selectPrinter } = useLabelPrinterSettings();
  const { isWebUsbSupported } = useHardwareServices();

  const handleRefresh = async () => {
    HardwareService.labelPrinter.filterEnabled = true;
    search({ requestNewDevices: false });
  };

  const { handleShowAddNewDeviceModal } = useAddDeviceListItem(PeripheralType.labelPrinter);

  return (
    <SettingsListItem
      title='Select label printer'
      actions={[
        <SettingsSelect
          automationId='label_printer_settings-select'
          devices={printers}
          emptyOptionsMessage='No printers available'
          value={formValues.labelPrinterId}
          placeholder='Select label printer'
          onChange={(option) => selectPrinter(option?.value)}
          onRefresh={handleRefresh}
          secondaryAction={
            isWebUsbSupported ? { label: 'Add new printer', onClick: handleShowAddNewDeviceModal } : undefined
          }
        />,
      ]}
    />
  );
};
