import React, { FC } from 'react';

import { Journal } from 'components/inputs';

type JournalTypeProps = {
  guestId?: number;
  disabled?: boolean;
};

export const JournalTab: FC<JournalTypeProps> = ({ guestId, disabled }) => {
  return <Journal guestId={guestId} disabled={disabled} />;
};
