import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, eloOriPadBreakpoint, panelWidth, zIndices } from 'css/Theme';

import { SearchBar } from 'components/layout';
import { ButtonWithIcon } from 'components/buttons';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg';
import { ReactComponent as LabBeakerIcon } from 'assets/icons/lab-results-beaker.svg';
import { rgba } from 'polished';

// Add Item Drawer styles

export const AddItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

export const AddItemsTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 2.2rem 1.125rem;
  ${eloOriPadBreakpoint} {
    padding: 1.5rem 2rem 1.125rem 1.5rem;
  }
`;

export const AddItemsTitle = styled.h1`
  font-weight: bolder;
  font-size: 1.6rem;
`;

export const Separator = styled.hr`
  margin: 0;
  background-color: ${colors.dutchie.shadowGrey};
  height: 1px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: top;
  position: relative;
  overflow: auto;
`;

export const RightPanel = styled.div<{ isShown: boolean }>`
  flex: 0 0 ${({ isShown }) => (isShown ? panelWidth.minWidth : '0px')};
  padding: 2rem 0;
  transition: 0.25s ease-in-out;

  &.content-hidden > * {
    display: none;
  }

  ${({ isShown }) =>
    isShown &&
    `
        border-left: 1px solid ${colors.dutchie.shadowGrey};
    `}
`;

export const LeftPanel = styled.div`
  position: relative;
  padding: 0 1.5rem 2rem;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    margin-top: 2rem;
  }
`;

// Product Search List

export const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  grid-gap: 10px;
  padding: 0.1rem 0.7rem 1rem;
`;

export const StyledSearchBar = styled(SearchBar)`
  background-color: ${colors.dutchie.lightGrey};
  height: 44px;
`;

export const TableContainer = styled.div`
  thead {
    top: 0;
  }
  tr.clickable:hover {
    background-color: ${rgba(colors.dutchie.blue, 0.025)};
  }
  tr.selected,
  tr.selected:hover {
    background-color: ${rgba(colors.dutchie.blue, 0.1)};
  }
`;

export const ResetFilters = styled.p`
  color: ${colors.dutchie.grey};
  margin-left: 1rem;
  cursor: pointer;
`;

// Product Preview Panel styles

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProductDetailsContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 2rem;
  position: relative;
  ${eloOriPadBreakpoint} {
    padding: 0 2rem;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProductHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1rem;
  background: ${colors.dutchie.primaryWhite};
  position: sticky;
  top: 0;
  z-index: ${zIndices.header};
`;

export const ProductTitle = styled.h2`
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  align-self: center;
  color: ${colors.dutchie.almostBlack};
  ${eloOriPadBreakpoint} {
    font-size: 1.25rem;
  }
`;

const CloseButtonStyle = styled(ButtonWithIcon)`
  &&& {
    margin-left: 1rem;
    border-radius: 1.5rem;
    & svg {
      color: ${colors.dutchie.grey};
      width: 0.75rem;
      height: 0.75rem;
      margin: auto;
    }
  }
`;

type CloseButtonProps = {
  onClick: () => void;
};

export const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  return <CloseButtonStyle icon={CloseIcon} secondary onClick={onClick} />;
};

export const ProductImgContainer = styled.div`
  border: 1px solid ${colors.dutchie.backgroundGrey};
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  margin-bottom: 2rem;
  ${eloOriPadBreakpoint} {
    height: 200px;
    margin-bottom: 1.5rem;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
  }
`;

export const ProductDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${colors.dutchie.almostBlack};
`;

export const DetailsRowContainer = styled.div`
  margin: 1rem 0;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const DetailFont = styled.p`
  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  line-height: 180%;
`;

export const DetailName = styled(DetailFont)`
  color: ${colors.dutchie.grey};
  font-weight: 600;
`;

export const DetailData = styled(DetailFont)`
  margin-left: 1rem;
`;

const LabPopupButtonStyle = styled(ButtonWithIcon)`
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0 2rem;
  padding: 1.5rem 1rem;
  & > svg {
    margin-left: 0;
    height: 1rem;
    width: 1rem;
  }
`;

type LabButtonProps = {
  onClick: () => void;
};

export const LabPopupButton: FC<LabButtonProps> = ({ onClick }) => {
  return <LabPopupButtonStyle secondary icon={LabBeakerIcon} label='Lab Results' onClick={onClick} />;
};

export const ProductPricingContainer = styled.div`
  border-top: 1px solid ${colors.dutchie.shadowGrey};
`;

export const LoadingContainer = styled.div`
  height: 100%;
  min-height: 225px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.dutchie.shadowGrey};
  padding: 0 0 2rem;
  margin-bottom: 2rem;
`;

export const QtySelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;
  ${eloOriPadBreakpoint} {
    padding: 1rem 2rem;
  }
`;

export const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceContainer = styled.div`
  display: flex;
  & p {
    color: black;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
  & p:nth-child(1) {
    margin-right: 0.625rem;
  }
`;

export const AvailabilityLabel = styled.p`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 1.5rem;
`;
