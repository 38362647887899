import { useState } from "react";

type HardwareSettingsDrawerHook = {
  isDrawerOpen: boolean;
  hideDrawer: () => void;
  showDrawer: () => void;
};

export const useHardwareSettingsDrawer = (): HardwareSettingsDrawerHook => {
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);

  const hideDrawer = () => setIsDrawerOpen(false);
  const showDrawer = () => setIsDrawerOpen(true);

  return {
    isDrawerOpen,
    hideDrawer,
    showDrawer,
  };
};
