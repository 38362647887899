import styled from 'styled-components';
import { Checkbox as MUICheckbox } from '@mui/material';
import { FormControlLabel as MUIFormControlLabel, FormGroup } from '../form';

export const CheckboxGroup = styled(FormGroup)`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: var(--sizes-50) var(--sizes-50);

  .checkbox-form-control {
    grid-column: span 2;
  }
`;

export const RebrandFormControlLabel = styled(MUIFormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  font: var(--font-regular-14pt-normal);
  white-space: nowrap;

  .Mui-disabled {
    label {
      color: var(--color-greyscale-grey-65);
    }
  }

  &.MuiFormControlLabel-labelPlacementTop {
    align-items: start;
    margin-left: 0;

    span {
      padding: 0;

      &.MuiFormControlLabel-label {
        font-weight: 600;
      }
    }
  }
`;

export const RebrandCheckbox = styled(MUICheckbox)`
  margin-right: var(--sizes-30);
  padding: 0;

  :hover {
    background-color: transparent;
  }
`;
