import { useDispatch, useSelector } from 'react-redux';
import { round } from 'lodash';

import { applyLoyaltyPoints } from 'store/actions/CartActions';
import { logger } from 'util/logger';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useAddItemToShoppingCartMutation } from 'queries/v2/cart/add-item-to-shopping-cart';
import { useAutoPrintLabels } from '../cart/useAutoPrintLabels';
import { useIsDaysSupplyFeatureEnabled } from '../features/useIsDaysSupplyFeatureEnabled';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { errorNotification } from 'store/actions/NotificationsActions';

import type { AddItemToShoppingCartPayload } from 'queries/v2/cart/add-item-to-shopping-cart';
import type { CartItem } from 'models/Cart';
import type { State } from 'store';

export type UseAddItemToCart = {
  availableOz?: number;
  batchId?: number;
  cannabisInventory?: string;
  count: number;
  defaultLabelId?: number;
  defaultUnitId?: number;
  guestIdOverride?: number;
  inventoryId?: number;
  itemLabelId?: number | null;
  packageItemId?: number | null;
  productDescription: string;
  productId: number;
  productType: 'Wgt' | 'Qty';
  recUnitPrice: number;
  serialNo: string;
  shipmentIdOverride?: number;
  unitPrice: number;
};

export const useAddItemToCart = () => {
  const dispatch = useDispatch();

  // Global state
  const isApplyLoyaltyPointsAutomaticallyEnabled = useSelector(
    (state: State) => state.settings.features.ApplyLoyaltyPointsAutomatically
  );
  const isLoyaltyAsDiscountEnabled = useSelector((state: State) => state.settings.features.LoyaltyAsDiscount);
  const isRunAutoDiscountEnabled = useSelector((state: State) => state.settings.features.RunAutoDiscount);
  const isRunAutoPriceEnabled = useSelector((state: State) => state.settings.features.RunAutoPrice);
  const isUsingDaysSupply = useIsDaysSupplyFeatureEnabled();
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  const { guestId, shipmentId } = useTransactionManager();

  const { data: cart, refetch: refetchCartDetails } = useGetCartDetails();

  // Computed values

  const availOzComputed = round(cart.Allotment.TotalLimit - cart.Allotment.TotalInCart - cart.Allotment.TotalUsed, 2);
  const appliedLoyaltyPoints = cart.Loyalty?.AppliedLoyaltyPoints;

  // Hooks

  const { mutateAsync: addItemToCart } = useAddItemToShoppingCartMutation();
  const isAutoPrintLabelsEnabled = useSelector((state: State) => state.settings.userSettings.autoPrintLabels);
  const autoPrintLabels = useAutoPrintLabels();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  return async ({
    availableOz,
    batchId,
    cannabisInventory,
    count,
    defaultLabelId,
    defaultUnitId,
    guestIdOverride,
    inventoryId,
    itemLabelId,
    packageItemId,
    productDescription,
    productId,
    productType,
    recUnitPrice,
    serialNo,
    shipmentIdOverride,
    unitPrice,
  }: UseAddItemToCart) => {
    const guestIdToUse = guestIdOverride ?? guestId;
    const shipmentIdToUse = shipmentIdOverride ?? shipmentId;

    if (!guestIdToUse || !registerId || !shipmentIdToUse) {
      return;
    }

    const payload: AddItemToShoppingCartPayload = {
      availableOz: availableOz ?? availOzComputed,
      batchId,
      cannabisProduct: cannabisInventory,
      count,
      defaultLabelId,
      defaultUnitId,
      grouping: 'No',
      guestId: guestIdToUse,
      inventoryId,
      isLoyaltyAsDiscountEnabled,
      isQuantityItem: productType === 'Qty',
      isRunAutoDiscountEnabled,
      isRunAutoPriceEnabled,
      isUsingDaysSupply,
      itemLabelId,
      packageItemId,
      productDescription,
      productId,
      recUnitPrice,
      registerId,
      serialNo,
      shipmentId: shipmentIdToUse,
      unitPrice,
      weight: 0,
    };

    try {
      const response = await addItemToCart(payload);

      // Auto print labels if enabled
      if (isAutoPrintLabelsEnabled) {
        const cartItem: CartItem = {
          BatchId: batchId,
          CannbisProduct: cannabisInventory,
          DefaultLabelId: defaultLabelId,
          Id: 0,
          InventoryId: response.inventoryId,
          OrderNo: '',
          PackageItemId: response.packageItemId,
          Product: productDescription,
          ProductId: productId,
          QtyAllocated: 1,
          QtySelected: count,
          SerialNo: serialNo,
          TotalCost: unitPrice,
          WgtCnt: 'Qty',
        };

        autoPrintLabels({ cartItem });
      }

      // Apply loyalty points if enabled
      if (isApplyLoyaltyPointsAutomaticallyEnabled) {
        dispatch(
          applyLoyaltyPoints({
            TransactionId: shipmentIdToUse,
            Register: registerId,
            UserId: parseInt(window.sessionStorage.getItem('UserId') ?? '0'),
            GuestId: guestIdToUse,
            LoyaltyAmt: appliedLoyaltyPoints ?? 0,
            LoyaltyAsDiscount: isLoyaltyAsDiscountEnabled,
          })
        );
      }
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : 'There was an error adding the item to the cart';
      dispatch(errorNotification(errorMessage));
      logger.error(error, { message: errorMessage, payload });
      // Need to throw the error to prevent the success notification from displaying
      throw error;
    } finally {
      // Will not need to do this manually if React Query is enabled
      // The mutation invalidates the cache automatically
      if (!isAnonymousCartLDFlagEnabled) {
        // Refetch the cart with Redux
        refetchCartDetails();
      }
    }
  };
};
