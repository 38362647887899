import React, { FC, useState, useEffect } from 'react';
import { Button } from 'components/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { SectionGridContainer, SectionHeader, SectionContent, SelectContainer } from './SettingPageComponents';
import { assignRegisterToHardware, selectRegister } from 'store/actions/SettingsActions';
import { Label } from 'components/text';
import { Select } from 'components/inputs';

import { State } from 'store';
import { HardwareRegisterResponse } from 'models/Misc';
import { getAllRegisters } from 'api/SettingsApi';
import { getPersistedValue, PersistedValueKey } from 'util/persisted-values';

export const AssignRegisters: FC = () => {
  const { registers } = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();

  const [assignedRegisterId, setAssignedRegisterId] = useState<number>();
  const [selectedRegisterId, setSelectedRegisterId] = useState<number>();
  const [availableRegisters, setAvailableRegisters] = useState<HardwareRegisterResponse[]>([]);

  const saveAssignedRegister = async () => {
    const newRegister = registers.find((item) => item.id === selectedRegisterId);
    if (newRegister) {
      dispatch(
        selectRegister({
          value: newRegister.id,
          label: newRegister.TerminalName,
        })
      );
      dispatch(assignRegisterToHardware(newRegister.id));
      setAssignedRegisterId(newRegister.id);
    } else {
      dispatch(
        selectRegister({
          value: undefined,
          label: '',
        })
      );
      setAssignedRegisterId(0);
    }
  };

  useEffect(() => {
    const setupRegisters = async () => {
      const getAllRegistersResponse = await getAllRegisters();
      const hardwareId = await getPersistedValue(PersistedValueKey.hardwareId);
      const assignedRegister = getAllRegistersResponse.find(
        (register) => register.HardwareId === hardwareId && register.HardwareId
      );
      setAssignedRegisterId(assignedRegister?.Id);
      setSelectedRegisterId(assignedRegister?.Id);
      setAvailableRegisters(getAllRegistersResponse);
    };

    setupRegisters();
  }, []);

  return (
    <SectionGridContainer>
      <SectionHeader>Hardware</SectionHeader>
      <SectionContent>
        <SelectContainer>
          <Label>Assigned Register</Label>
          <Select
            placeholder='Select Register'
            value={selectedRegisterId}
            onChange={(value) => setSelectedRegisterId(parseInt(value, 10))}
            options={availableRegisters.map((item) => ({ value: item.Id, label: item.TerminalName }))}
          />
        </SelectContainer>
        {assignedRegisterId !== selectedRegisterId && <Button onClick={saveAssignedRegister}>Save</Button>}
      </SectionContent>
    </SectionGridContainer>
  );
};
