import { useMemo, useRef, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { showLoyaltyPanel } from 'store/actions/CartActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';
import { useLoyaltySettings } from '../../LoyaltyPoints/useLoyaltySettings';
import { PaymentType } from 'models/Checkout';
import { useEnabledNonIntegratedPaymentsProcessors } from 'queries/v2/payments/manual-payments';
import { useGeneriPayPaymentIntegrationsPOSQuery } from 'queries/v2/payments/generipay';
import { useGeneriPay } from 'util/hooks/launch-darkly/useGeneriPay';
import { usePaysolDebitButton } from 'util/hooks/launch-darkly/usePaysolDebitButton';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

export const usePaymentButtons = () => {
  const dispatch = useAppDispatch();

  const ldClient = useLDClient();
  const dutchiePayInPersonEnrolledDispensaries = ldClient?.variation(
    'dutchie-pay.pos.in-person-enrolled-dispensaries',
    false
  );

  const dutchiePayOnHubRollout = ldClient?.variation('fintech.payments-hub.dutchie-pay-rollout', false);

  const {
    data: { GrandTotalRounded: cartGrandTotal },
  } = useGetCartDetails();

  // Global state
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);
  const isCanPayEnabled = useAppSelector((state) => state.settings.integrations?.AcceptCanPay);
  const isCheckEnabled = useAppSelector((state) => state.settings.features.CheckOutCheck);
  const isCreditEnabled = useAppSelector((state) => state.settings.features.CheckOutCreditCard);
  const isDebitEnabled = useAppSelector((state) => state.settings.features.CheckOutDebit);
  const isLegacyDutchiePayCheckoutEnabled = useAppSelector((state) => state.settings.features.HasLegacyDutchiePayCheckOut);
  const isDebitProcessorUSAG = useAppSelector((state) => state.settings.integrations?.DebitProcessor === 'usag');
  const isDutchiePayEnabled = useAppSelector((state) => state.settings.integrations?.UseDutchiePay);
  const isGiftCardEnabled = useAppSelector((state) => state.settings.features.CheckOutGiftCard);
  const isHypurEnabled = useAppSelector((state) => state.settings.integrations?.AcceptHypur);
  const isMMAPEnabled = useAppSelector((state) => state.settings.features.CheckOutMMAP);
  const isPlanetPaymentsEnabled = useAppSelector((state) => state.settings.integrations?.UsePlanetPayments);
  const isPaymentsHubEnabled = useAppSelector((state) => state.settings.integrations?.UsePaymentsHub);
  const isManualPaymentsEnabled = useAppSelector((state) => state.settings.features.EnableManualPayments);
  const paymentMethods = useAppSelector((state) => state.checkout.payment?.methods) ?? [];
  const isPaysolEnabled = useAppSelector((state) => state.settings.features.UseSpinDebitIntegration);

  // Hooks
  const { hasEnabledManualPaymentProcessors } = useEnabledNonIntegratedPaymentsProcessors();
  const isGeneriPayEnabled = useGeneriPay();
  const paysolDebitButtonEnabled = usePaysolDebitButton();
  const { data: generiPayButtons } = useGeneriPayPaymentIntegrationsPOSQuery(registerId);
  const { showDutchiePayPreAuthCheckout } = useDutchiePayPreAuth();
  const { showLoyaltyPaymentOption: isLoyaltyPaymentEnabled } = useLoyaltySettings();

  // Memoized values
  const creditButtonLabel = useMemo(() => {
    if (isPlanetPaymentsEnabled) {
      return 'Card';
    }
    return 'Credit';
  }, [isPlanetPaymentsEnabled]);

  const isDebitDisabled = useMemo(() => {
    // USAG transactions cannot be for over $2000
    // SOP is to make the budtender split up into multiple transactions
    return isDebitProcessorUSAG && cartGrandTotal >= 2000;
  }, [isDebitProcessorUSAG, cartGrandTotal]);

  const isDigitalEnabled = useMemo(() => {
    return !!(isHypurEnabled || isCanPayEnabled);
  }, [isHypurEnabled, isCanPayEnabled]);

  const isManualPaymentButtonDisabled = useMemo(() => {
    return paymentMethods.findIndex((o) => o.type === PaymentType.Manual) !== -1;
  }, [paymentMethods]);

  const numberOfEnabledPaymentMethods = useMemo(() => {
    const methods =
      1 + // Cash is always enabled
      Number(isCheckEnabled) +
      Number(isCreditEnabled) +
      Number(isDebitEnabled) +
      Number(isDigitalEnabled) +
      Number(isGiftCardEnabled) +
      Number(isLoyaltyPaymentEnabled) +
      Number(isMMAPEnabled);
    return methods;
  }, [
    isCheckEnabled,
    isCreditEnabled,
    isDebitEnabled,
    isDigitalEnabled,
    isGiftCardEnabled,
    isLoyaltyPaymentEnabled,
    isMMAPEnabled,
  ]);

  // Computed values
  const hideLegacyDutchiePayCheckoutButton = dutchiePayOnHubRollout && isPaymentsHubEnabled && !isLegacyDutchiePayCheckoutEnabled;
  const isDutchiePayInStoreEnabled = dutchiePayInPersonEnrolledDispensaries && isDutchiePayEnabled;
  const isPreAuthOrder = showDutchiePayPreAuthCheckout;
  const showDutchiePayInStoreButton = isDutchiePayInStoreEnabled && !isPreAuthOrder && !hideLegacyDutchiePayCheckoutButton;
  const isCreditButtonDisabled = false;
  const showManualPayments = isManualPaymentsEnabled && hasEnabledManualPaymentProcessors;

  // Handlers
  const handleClickLoyaltyButton = () => dispatch(showLoyaltyPanel());

  // Layout
  const cashButtonRef = useRef<HTMLButtonElement>(null);
  const [useVerticalButtons, setUseVerticalButtons] = useState(false);

  useEffect(() => {
    const calculateButtonOrientation = () => {
      // We only check the cash button since it's the only button that is always shown
      if (cashButtonRef.current) {
        const { width } = cashButtonRef.current.getBoundingClientRect();
        // Determine if the width of the cash button is less than 140px
        // If so, we need to switch to vertical buttons instead of horizontal
        setUseVerticalButtons(width < 140);
      }
    };
    // Call the function once to set the initial state
    calculateButtonOrientation();

    // Note: The resize lisenter is will only be called if the browser window
    //       is resized or the orientation of the device is changed
    window.addEventListener('resize', calculateButtonOrientation);
    return () => window.removeEventListener('resize', calculateButtonOrientation);
  }, []);

  return {
    cashButtonRef,
    creditButtonLabel,
    handleClickLoyaltyButton,
    isCheckEnabled,
    isCreditButtonDisabled,
    isCreditEnabled,
    isDebitDisabled,
    isDebitEnabled,
    isDebitProcessorUSAG,
    isDigitalEnabled,
    isGiftCardEnabled,
    isLoyaltyPaymentEnabled,
    isMMAPEnabled,
    isPreAuthOrder,
    numberOfEnabledPaymentMethods,
    showDutchiePayInStoreButton,
    showDutchiePayPreAuthCheckout,
    useVerticalButtons,
    isPaymentsHubEnabled,
    showManualPayments,
    isManualPaymentButtonDisabled,
    isGeneriPayEnabled,
    generiPayButtons,
    isPaysolEnabled,
    paysolDebitButtonEnabled,
  };
};
