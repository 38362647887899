import { CancelTransactionPopup } from 'components/sharedPopups/CancelTransactionPopup';
import React, { FC, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, ButtonWithIcon } from 'components/buttons';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { colors } from 'css/Theme';
import { ReactComponent as ElipsisIcon } from 'assets/icons/elipsis-icon.svg';
import { OverflowMenu, OverflowMenuOption } from 'components/menus/OverflowMenu';
import { Table, Column as TableColumn } from 'components/tables';
import { State } from 'store';
import styled from 'styled-components';
import { TextAreaField } from 'components/inputs';
import { Delivery, DeliveryHistory } from 'models/Delivery';
import {
  getDeliveries,
  getDeliveryImage,
  completeDeliveries,
  selectRoute,
  getRouteHistory,
  endRoute,
  setDeliveryRouteNote,
  cancelDeliveries,
  checkoutDeliveries,
} from 'store/actions/DeliveryActions';
import { warningNotification } from 'store/actions/NotificationsActions';
import { BatchCheckoutPopup } from './batchCheckoutPopup';
import { usePopup, Popup } from 'components/popups';
import { Formik, Form } from 'formik';
import { CheckedInGuest } from 'models/Guest';
import { DeliveryRouteHistoryPopup } from './deliveryRouteHistoryPopup';
import { DisplayDate } from 'components/text';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';

type DecoratedDelivery = Delivery & {
  PaymentType: string;
};

export const DeliveryRoutePage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const guestList = useSelector((state: State) => state.guestList.guests);
  const selectedRoute = useSelector((state: State) => state.deliveryList.selectedRoute);
  const routeDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);
  const settings = useSelector((state: State) => state.settings);
  const totalValue = routeDeliveries.reduce((acc, d) => acc + d.InvoiceTotal, 0);
  const totalTip = routeDeliveries.reduce((acc, d) => acc + d.TipAmount, 0);
  const ordersCompleted = routeDeliveries.filter((d) => d.DeliveryStatusId > 2 && d.DeliveryStatusId < 6).length;
  const totalCash = routeDeliveries.reduce((acc, d) => acc + d.TotalPaid - d.TotalDueCustomer, 0);
  const startDate = selectedRoute?.StartDate ? new Date(selectedRoute.StartDate) : undefined;
  const endDate = selectedRoute?.EndDate ? new Date(selectedRoute.EndDate) : undefined;
  const actualStartDate = selectedRoute?.ActualStartDate ? new Date(selectedRoute.ActualStartDate) : undefined;
  const actualEndDate = selectedRoute?.ActualEndDate ? new Date(selectedRoute.ActualEndDate) : undefined;
  const { toggle: toggleCancelDeliveryPopup } = usePopup();
  const { isVisible: batchCheckoutPopupVisible, toggle: toggleBatchCheckoutPopup } = usePopup();
  const [nonCheckedOutCount, setNonCheckedOutCount] = useState(0);
  const [nonCheckedOutValue, setNonCheckedOutValue] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [guest, setGuest] = useState<CheckedInGuest | undefined>(undefined);
  const [driverImage, setDriverImage] = useState<String | undefined>(undefined);
  const [deliveryHistory, setDeliveryHistory] = useState<Array<DeliveryHistory> | undefined>(undefined);
  const { previewManifest } = usePrintJob();

  const getPaymentType = (delivery: Delivery) => {
    const types = [];
    if (delivery.TotalPaid) {
      types.push('Cash');
    }
    if (delivery.CreditPaid) {
      types.push('Credit');
    }
    if (delivery.DebitPaid) {
      types.push('Debit');
    }
    if (delivery.GiftPaid) {
      types.push('Gift Card');
    }
    if (delivery.DutchiePayPaid) {
      types.push('Dutchie Pay');
    }
    return types.join(', ');
  };

  const decoratedDeliveries: DecoratedDelivery[] = useMemo(() => {
    return routeDeliveries.map((delivery) => ({
      PaymentType: getPaymentType(delivery),
      ...delivery,
    }));
  }, [routeDeliveries]);

  const onPrintManifest = (d: Delivery) => {
    previewManifest({ PosId: d.ShipmentId });
  };

  const showVerificationImage = async (d: Delivery) => {
    const image = await getDeliveryImage(d);
    if (image) {
      setDriverImage(image);
    } else {
      dispatch(warningNotification('No image available'));
    }
  };

  const columns: Array<TableColumn<DecoratedDelivery>> = [
    { header: 'Order Id', width: '125px', field: (v) => `${v.ShipmentId}` },
    {
      header: 'Delivery Status',
      field: (v) =>
        v.DeliveryStatus ? `${v.DeliveryStatus} ${v.ManifestHasSignature ? ' - Signature Collected' : ''}` : 'Created',
    },
    { header: 'Address', field: (d) => `${d.street}, ${d.city} ${d.postal_code}` },
    { header: 'Name', field: (v) => v.FullName },
    { header: 'QTY', field: (v) => `${v.TotalItems}`, width: '75px' },
    { header: 'Payment Type', field: (v) => v.PaymentType },
    { header: 'Tip', field: (v) => `$${v.TipAmount.toFixed(2)}`, width: '100px' },
    { header: 'Total', field: (v) => `$${v.InvoiceTotal.toFixed(2)}`, width: '100px' },
    {
      width: '15px',
      disableSort: true,
      header: '',
      Cell: (cell) => {
        const delivery = cell.item;
        const options: OverflowMenuOption[] = [];
        const altKeyOptions: OverflowMenuOption[] = [];

        if (delivery.DeliveryStatusId === 4) {
          options.push({ text: 'Cancel', onClick: () => openCancelDelivery(delivery) });
        } else if (delivery.DeliveryStatusId === 3) {
          options.push({ text: 'Finish Sale', onClick: () => completeDelivery(delivery) });
          altKeyOptions.push({ text: 'Force Cancel', onClick: () => openCancelDelivery(delivery) });
        } else {
          options.push({ text: 'Finish Sale', onClick: () => {}, disabled: true });
          altKeyOptions.push({ text: 'Force Cancel', onClick: () => openCancelDelivery(delivery) });
        }

        return (
          <OverflowMenu
            anchor={<ElipsisIcon />}
            anchorProps={{ padding: '0.25rem 0', showCursor: true }}
            menuOptions={[
              ...options,
              { text: 'View Manifest', onClick: () => onPrintManifest(delivery) },
              { text: 'View Photo', onClick: () => showVerificationImage(delivery) },
            ]}
            altMenuOptions={altKeyOptions}
          />
        );
      },
    },
  ];

  const goToDropCash = () => {
    if (selectedRoute?.RegisterId) {
      history.push(`/register?tabIndex=1&registerId=${selectedRoute?.RegisterId}`);
    } else if (selectedRoute?.CarName) {
      dispatch(
        warningNotification(
          `No register is available for car ${selectedRoute?.CarName}, please ensure this car is an MSU and correctly configured in backend`
        )
      );
    } else {
      dispatch(warningNotification(`No car assigned to route`));
    }
  };

  const loadDeliveryRouteHistory = async () => {
    if (selectedRoute) {
      const history = await dispatch(getRouteHistory(selectedRoute?.DeliveryRouteId));
      setDeliveryHistory(history.payload as Array<DeliveryHistory>);
    }
  };

  const openCancelDelivery = async (d: Delivery) => {
    const guest = guestList.find((g) => g.ScheduleId === d.ScheduleId);
    if (guest) {
      setGuest(guest);
      toggleCancelDeliveryPopup();
    } else {
      dispatch(warningNotification('Unable to load guest - please go to the guest list'));
    }
  };

  const completeDelivery = async (d: Delivery) => {
    await dispatch(completeDeliveries([d]));
    await dispatch(selectRoute(selectedRoute?.DeliveryRouteId));
  };

  const saveNote = async (note: string) => {
    if (selectedRoute) {
      await dispatch(setDeliveryRouteNote({ route: selectedRoute, note }));
      await dispatch(getDeliveries());
    }
  };

  const doBatchCheckout = async () => {
    setIsSaving(true);
    toggleBatchCheckoutPopup();
    await dispatch(checkoutDeliveries(routeDeliveries.filter((d) => !d.ShipmentDelivered && d.DeliveryStatusId === 3)));
    await doCompleteAll();
  };

  const completeAll = async () => {
    const notCheckedOut = routeDeliveries.filter((x) => !x.ShipmentDelivered && x.DeliveryStatusId === 3);
    if (settings.integrations?.OnFleetChangeGuestListStatus && notCheckedOut.length) {
      setNonCheckedOutCount(notCheckedOut.length);
      setNonCheckedOutValue(notCheckedOut.reduce((acc, cur) => acc + cur.InvoiceTotal, 0));
      toggleBatchCheckoutPopup();
    } else {
      await doCompleteAll();
    }
  };

  const doCompleteAll = async () => {
    setIsSaving(true);
    await dispatch(cancelDeliveries(routeDeliveries.filter((d) => d.DeliveryStatusId === 4)));
    await dispatch(completeDeliveries(routeDeliveries.filter((d) => d.DeliveryStatusId === 3)));
    selectedRoute && (await dispatch(endRoute(selectedRoute)));
    await dispatch(selectRoute(selectedRoute?.DeliveryRouteId));
    setIsSaving(false);
    history.push('/delivery');
  };

  return (
    <RegisterTransactionsDiv className='container'>
      <BreadcrumbDiv>
        Delivery <StyledArrowRight /> Return To Store Checklist
      </BreadcrumbDiv>
      <TitleContainerDiv>
        <StyledDutchieButtonWithIcon secondary onClick={history.goBack} icon={StyledArrowLeft} />
        <StyledBackTitle>Register {selectedRoute?.RegisterName}</StyledBackTitle>
        <Expander />
        <StyledButton secondary onClick={loadDeliveryRouteHistory}>
          Route History
        </StyledButton>
        <StyledButton secondary onClick={goToDropCash}>
          Drop Cash
        </StyledButton>
        <StyledButton disabled={isSaving} onClick={completeAll}>
          Complete All
        </StyledButton>
      </TitleContainerDiv>
      <ColumnContainer>
        <Column flexgrow={1}>
          <Field>
            <Label>Driver</Label>
            <Value> {selectedRoute?.Driver1Name}</Value>
          </Field>
          <Field>
            <Label>Secondary Driver</Label>
            <Value> {selectedRoute?.Driver2Name}</Value>
          </Field>
          <Field>
            <Label>Total Orders</Label>
            <Value> {routeDeliveries.length}</Value>
          </Field>
          <Field>
            <Label>Orders Completed</Label>
            <Value> {ordersCompleted}</Value>
          </Field>
          <Field>
            <Label>Total Value</Label>
            <Value> {totalValue.toFixed(2)}</Value>
          </Field>
          <Field>
            <Label>Total Tip Value</Label>
            <Value> {totalTip.toFixed(2)}</Value>
          </Field>
          <Field>
            <Label>Expected Cash</Label>
            <Value>{totalCash.toFixed(2)}</Value>
          </Field>
        </Column>
        <Column flexgrow={1}>
          <Field>
            <Label>Vehicle</Label>
            <Value> {selectedRoute?.CarName}</Value>
          </Field>
          <Field>
            <Label>Estimated Start Time</Label>
            <Value>
              {' '}
              <DisplayDate date={startDate} />
            </Value>
          </Field>
          <Field>
            <Label>Estimated End Time</Label>
            <Value>
              {' '}
              <DisplayDate date={endDate} />
            </Value>
          </Field>
          <Field>
            <Label>Actual Start Time</Label>
            <Value>
              {' '}
              <DisplayDate date={actualStartDate} />
            </Value>
          </Field>
          <Field>
            <Label>Actual End Time</Label>
            <Value>
              {' '}
              <DisplayDate date={actualEndDate} />
            </Value>
          </Field>
          <Field>
            <Label>Register</Label>
            <Value> {selectedRoute?.RegisterName}</Value>
          </Field>
          <Field>
            <Label>Status</Label>
            <Value> {selectedRoute?.DeliveryStatus}</Value>
          </Field>
        </Column>
        <Column flexgrow={2}>
          <Formik
            enableReinitialize
            initialValues={{
              notes: selectedRoute?.Note ?? '',
            }}
            onSubmit={(e) => {
              saveNote(e.notes);
            }}
            validateOnMount
          >
            {(props) => {
              return (
                <Form>
                  <TextAreaField name='notes' label='Note' rows={5} />
                  <SingleButtonDivContainer>
                    <Button type='submit'>Save</Button>
                  </SingleButtonDivContainer>
                </Form>
              );
            }}
          </Formik>
        </Column>
      </ColumnContainer>
      <Table<DecoratedDelivery> data={decoratedDeliveries} columns={columns} onClick={() => {}} pageSize={20} />
      {guest && (
        <CancelTransactionPopup
          hide={toggleCancelDeliveryPopup}
          guest={guest}
          onCancel={() => dispatch(selectRoute(selectedRoute?.DeliveryRouteId))}
        />
      )}
      <BatchCheckoutPopup
        isVisible={batchCheckoutPopupVisible}
        hide={toggleBatchCheckoutPopup}
        message={`You have ${nonCheckedOutCount} orders that are delivered but not tendered. Are you sure you want to tender ${nonCheckedOutCount} sales for cash total of $${nonCheckedOutValue.toFixed(
          2
        )}?`}
        confirmButtonMessage={`Yes, tender ${nonCheckedOutCount} sales`}
        onConfirm={() => {
          doBatchCheckout();
        }}
      />
      <Popup medium caption='Verification Photo' isVisible={!!driverImage} hide={() => setDriverImage(undefined)}>
        <StyledImage alt='' src={`data:image/jpeg;base64,${driverImage}`} />
      </Popup>
      <DeliveryRouteHistoryPopup
        isVisible={!!deliveryHistory}
        hide={() => setDeliveryHistory(undefined)}
        deliveryHistory={deliveryHistory}
      />
    </RegisterTransactionsDiv>
  );
};

const RegisterTransactionsDiv = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const StyledArrowRight = styled(ArrowRight)`
  height: 8px;
  width: 8px;
  margin: 1px 5px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  height: 14px;
`;

const StyledDutchieButtonWithIcon = styled(ButtonWithIcon)`
  border-radius: 20px;
  width: 40px;
  height: 40px;
`;
const StyledButton = styled(Button)`
  margin-left: 20px;
`;

const BreadcrumbDiv = styled.div`
  color: ${colors.dutchie.grey};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
`;

const StyledBackTitle = styled.span`
  margin-left: 20px;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.dutchie.almostBlack};
`;

const TitleContainerDiv = styled.div`
  align-items: center;
  display: flex;
  color: ${colors.black};
  flex-direction: row;
  font-size: 24px;
  margin-bottom: 48px;
`;

const Expander = styled.div`
  flex-grow: 1;
`;

const SingleButtonDivContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  border-bottom: 1px solid ${colors.dutchie.shadowGrey};
  margin-bottom: 60px;
`;

const Column = styled.div<{ flexgrow: number }>`
  flex-grow: ${({ flexgrow }) => flexgrow};
`;

const Label = styled.span`
  color: ${colors.dutchie.grey};
  min-width: 200px;
  padding-right: 24px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;

const Value = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 16px;
  line-height: 24px;
`;

const Field = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StyledImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
`;
