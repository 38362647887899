import { useReceiptPrinters } from '@dutchie/capacitor-hardware';
import { useFormikContext } from 'formik';

import { ZReportReceiptPrinterSettingsContextData } from '../types';
import { HardwareSettings } from '../../types';

export const useZReportReceiptPrinterSettingsProvider = (): ZReportReceiptPrinterSettingsContextData => {
  const { values: formValues, setFieldValue } = useFormikContext<HardwareSettings>();

  const { receiptPrinters: allPrinters, search } = useReceiptPrinters({
    onDevicesAuthorized: (printers) => selectPrinter(printers[0].id),
  });

  const selectPrinter = (id?: string): void => setFieldValue('zReportPrinterId', id);

  return {
    formValues,
    printers: allPrinters,
    search,
    selectPrinter,
  };
};
