import { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { errorNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { updateCustomerDetails } from 'store/actions/CustomerActions';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useClearCartMutation } from 'queries/v2/cart/clear';
import { useGetCartDetailsQuery } from 'queries/v2/cart/load';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { SelectOption } from 'components/inputs';

export const useChangeCustomerTypeModal = ({ hide }: { hide: () => void }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isCartPage = history.location.pathname.includes('/cart');

  // Global state

  const customerDetails = useAppSelector((state) => state.customer.details);
  const currentCustomerTypeId = useAppSelector((state) => state.customer.details?.CustomerTypeId);
  const customerTypes = useAppSelector((state) => state.settings.customerTypes);
  const hasItemsInCartRedux = useAppSelector((state) => state.cart.details.Cart.length > 0);

  // Hooks

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { mutateAsync: clearCart } = useClearCartMutation();

  const { guestId, shipmentId } = useTransactionManager();

  const { data: hasItemsInCartReactQuery } = useGetCartDetailsQuery(
    { guestId: guestId ?? 0, shipmentId: shipmentId ?? 0 },
    {
      select: (cartDetails) => cartDetails.cartItems.length > 0,
    }
  );
  const hasItemsInCart = isAnonymousCartLDFlagEnabled ? hasItemsInCartReactQuery : hasItemsInCartRedux;

  // Local state

  const [isUpdatingCustomer, setIsUpdatingCustomer] = useState<boolean>(false);
  const [selectedCustomerType, setSelectedCustomerType] = useState<number | undefined>(currentCustomerTypeId);

  // Computed values

  const customerTypeOptions: SelectOption[] =
    customerTypes?.map((type) => ({
      key: type.CustomerTypeId,
      value: type.CustomerTypeId,
      label: type.CustomerType,
    })) ?? [];

  const isConfirmDisabled = useMemo(
    () => currentCustomerTypeId === selectedCustomerType,
    [currentCustomerTypeId, selectedCustomerType]
  );

  const showWarningMessage = useMemo(
    () => currentCustomerTypeId !== selectedCustomerType && isCartPage && hasItemsInCart === true,
    [currentCustomerTypeId, selectedCustomerType, isCartPage, hasItemsInCart]
  );

  const warningMessage =
    'Changing the customer type while an order is in progress will refresh the cart to reflect the change made.';

  // Handlers

  const handleSelectCustomerType = (newValue: string) => {
    const newCustomerTypeId = Number(newValue);
    setSelectedCustomerType(newCustomerTypeId);
  };

  const handleClickConfirm = useCallback(async () => {
    try {
      if (customerDetails && selectedCustomerType) {
        setIsUpdatingCustomer(true);

        await dispatch(
          updateCustomerDetails({
            ...customerDetails,
            CustomerTypeId: selectedCustomerType,
          })
        ).unwrap();

        // If the budtender is change from med to rec or vice versa, we need to clear the cart
        if (showWarningMessage) {
          await clearCart({ shipmentId: shipmentId ?? 0 });
          dispatch(
            errorNotification('You will need to re-add the items to the cart because the customer type has changed.')
          );
        }

        hide();
      }
    } catch (error) {
      logger.error(error, { message: 'Failed to update customer type' });
    } finally {
      setIsUpdatingCustomer(false);
    }
  }, [clearCart, customerDetails, dispatch, hide, selectedCustomerType, shipmentId, showWarningMessage]);

  return {
    customerTypeOptions,
    handleClickConfirm,
    handleSelectCustomerType,
    isConfirmDisabled,
    isUpdatingCustomer,
    selectedCustomerType,
    showWarningMessage,
    warningMessage,
  };
};
