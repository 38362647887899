import React, { FC } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { registerForLoyalty } from 'store/actions/CustomerActions';
import { callback } from 'models/Misc';
import { InputField } from 'components/inputs';
import { Button } from 'components/buttons';
import { ConfirmationPopup } from 'components/popups';
import { colors } from 'css/Theme';
import { Flex } from 'components/layout';
import { P1 } from 'components/text';
import { useAppDispatch, useAppSelector } from 'util/hooks';

const RegisterInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 24px;
`;

const SingleButtonDiv = styled.div`
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  box-shadow: 0 -1px 0 ${colors.dutchie.shadowGrey};
  padding: 24px;
`;

const StyledInput = styled(InputField)<{ width?: string; marginRight?: string }>`
  width: ${({ width }) => width || '100%'};
  margin-right: ${({ marginRight }) => marginRight || 0};
`;

const InputGroup = styled(Flex)`
  gap: 12px;
`;

type RegisterForLoyaltyPopupProps = {
  hide: callback;
};

type RegisterForLoyaltyFormValues = {
  cellPhone: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  streetAddress: string | number;
  city: string;
  state: string;
  postalCode: string;
};

export const RegisterForLoyaltyPopup: FC<RegisterForLoyaltyPopupProps> = ({ hide }) => {
  const dispatch = useAppDispatch();

  const guest = useAppSelector((state) => state.customer.details);

  const onSignUp = (values: RegisterForLoyaltyFormValues) => {
    if (guest?.Guest_id) {
      dispatch(
        registerForLoyalty({
          AcctId: guest.Guest_id,
          CellPhone: values.cellPhone,
          FirstName: values.firstName,
          LastName: values.lastName,
          EmailAddress: values.emailAddress,
          StreetAddress: values.streetAddress,
          City: values.city,
          State: values.state,
          PostalCode: values.postalCode,
        })
      );
      hide();
    }
  };

  if (!guest) {
    return null;
  }

  return (
    <ConfirmationPopup medium title='Loyalty Registration' isVisible hide={hide}>
      <Formik
        initialValues={{
          firstName: guest.FirstName || '',
          lastName: guest.LastName || '',
          emailAddress: guest.Email || '',
          cellPhone: guest.CellPhone || '',
          streetAddress: guest.address.street || '',
          city: guest.address.city || '',
          state: guest.address.state || '',
          postalCode: guest.address.postal_code || '',
        }}
        onSubmit={onSignUp}
      >
        <Form>
          <RegisterInfoContainer>
            <InputGroup>
              <StyledInput marginRight='16px' label='First Name' name='firstName' />
              <StyledInput label='Last Name' name='lastName' />
            </InputGroup>

            <InputGroup>
              <StyledInput marginRight='16px' label='Email Address' name='emailAddress' />
              <StyledInput label='Cell Phone #' name='cellPhone' />
            </InputGroup>

            <Flex>
              <StyledInput label='Street Address' name='streetAddress' />
            </Flex>

            <InputGroup justifyContent='space-between'>
              <StyledInput marginRight='16px' label='City' name='city' />
              <StyledInput marginRight='16px' label='State' name='state' />
              <StyledInput label='Postal Code' name='postalCode' />
            </InputGroup>
            <Flex style={{ paddingTop: 24, paddingBottom: 24 }}>
              <P1 lineHeight='24px'>
                By clicking sign up, you are agreeing to share your information, including information from your state
                issued ID to receive marketing and loyalty messages from this facility. You may opt out at anytime of
                marketing messages via unsubscribe links in email and by replying STOP to text messages.
              </P1>
            </Flex>
          </RegisterInfoContainer>
          <SingleButtonDiv>
            <Button type='submit'>Sign Up</Button>
          </SingleButtonDiv>
        </Form>
      </Formik>
    </ConfirmationPopup>
  );
};
