import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadingButton } from 'components/buttons';
import { LinkButton } from 'components/backoffice/button';
import { zIndices, headerHeight } from 'css/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { hideActionableError } from 'store/actions/ActionableErrorActions';

export const ActionableError: FC = () => {
  const dispatch = useDispatch();
  const { showErrorMessage, message, action, secondaryAction } = useSelector((state: State) => state.actionableError);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (message !== '') {
      setLoading(false);
    }
  }, [message]);

  if (!showErrorMessage) {
    return null;
  }

  return (
    <ActionableErrorContainer>
      <Message>{message}</Message>
      <ActionButton
        loading={loading}
        onClick={() => {
          setLoading(true);
          dispatch(hideActionableError());
          action.onClick();
        }}
      >
        {action.label}
      </ActionButton>
      {secondaryAction && (
        <ActionLink
          label={secondaryAction.label}
          onClick={() => {
            dispatch(hideActionableError());
            secondaryAction.onClick();
          }}
        />
      )}
    </ActionableErrorContainer>
  );
};

const ActionableErrorContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - ${headerHeight});
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: ${zIndices.popup};
  align-items: center;
  justify-content: center;
  gap: 1.125rem;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.5rem);
`;

const Message = styled.div`
  color: white;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  max-width: 500px;
  text-align: center;
`;

const ActionButton = styled(LoadingButton)<{ secondary?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
`;

const ActionLink = styled(LinkButton)`
  color: white;
  font-size: 1rem;
  background: none;
  border-radius: 0;
  padding: 0;
  letter-spacing: 0.02em;
  border-bottom: 2px solid transparent;

  &:hover {
    border-color: white;
  }
`;
