import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { State } from 'store';

import { ReactComponent as ProductScan } from 'assets/icons/scan-icon.svg';
import { breakpoints, colors } from 'css/Theme';

import { CartContextMenu } from './CartContextMenu';
import { CartItems } from './CartItems';
import { Skeleton } from 'components/misc';
import { customEventKeys, logger } from 'util/logger';
import { useAnonymousCart } from '../hooks/useAnonymousCart';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';
import { useGetCartDetails } from '../hooks/useGetCartDetails';

import type { Preorder } from 'models/Cart';

const CartSummarySkeleton: FC = () => {
  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  return (
    <>
      <CartContextMenu loading />
      <StyledSkeleton containerHeight='100%' width={isCustomerInsightsEnabled ? 'auto' : undefined} />
    </>
  );
};

type CartSummaryProps = {
  loading?: boolean;
  setAddItemPanelIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CartSummary = React.memo(CartSummaryImpl);

export function CartSummaryImpl({ loading, setAddItemPanelIsOpen }: CartSummaryProps): JSX.Element {
  const guest = useSelector((state: State) => state.customer.details);

  const { data: cart } = useGetCartDetails();

  const preorderItems = cart.PreOrders;

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  useEffect(() => {
    if (cart.ShipmentId && preorderItems.length > 0) {
      const cartHasZeroQtyPreorderItems = preorderItems.some((preorder) => preorder.QtySelected === 0);
      if (cartHasZeroQtyPreorderItems) {
        logger.warn<{ key: string; shipmentId: number; preorderItems: Preorder[] }>(
          'order with preorder items of 0 qty received',
          {
            key: customEventKeys.cart.zeroQtyPreorderReceived,
            shipmentId: cart.ShipmentId,
            preorderItems,
          }
        );
      }
    }
  }, [cart.ShipmentId, preorderItems]);

  const showLoadingSkeleton = isAnonymousCartLDFlagEnabled ? loading : loading || !guest;

  if (showLoadingSkeleton) {
    return <CartSummarySkeleton />;
  }

  const cartIsEmpty = cart?.Cart?.length === 0 && cart?.PreOrders?.length === 0;

  return (
    <>
      <CartContextMenu setAddItemPanelIsOpen={setAddItemPanelIsOpen} />
      {cartIsEmpty && (
        <ProductScanContainer data-testid='cart-summary_div_empty-cart'>
          <ProductScan />
          <EmptyMessageWrapper>
            <EmptyMessageHeader>Scan or add items to begin</EmptyMessageHeader>
            <EmptyMessage>
              There are no items in this cart. Scan an item or add items manually to get started
            </EmptyMessage>
          </EmptyMessageWrapper>
        </ProductScanContainer>
      )}
      <CartItems />
    </>
  );
}

const ProductScanContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #a0a8ab;
  border-radius: 12px;
  background-color: ${colors.dutchie.backgroundGrey};
  height: 100%;

  ${breakpoints.smallTablet.maxQuery} {
    margin: 0 1.5rem 1.5rem;
  }
`;

const EmptyMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;

const EmptyMessage = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25rem;
  color: #5e696e;
`;

const EmptyMessageHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.375rem;
  color: #5e696e;
`;

const StyledSkeleton = styled(Skeleton)`
  border: 2px dashed #a0a8ab;

  ${breakpoints.smallTablet.maxQuery} {
    margin: 0 1.5rem 1.5rem;
  }
`;
