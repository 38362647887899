import React, { useEffect, useState } from 'react';
import { SerialScale } from '@dutchie/capacitor-hardware';
import styled from 'styled-components';

import { SimpleModal } from 'components/modals/SimpleModal';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { Input, Select } from 'components/inputs';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { ReactComponent as GramsIcon } from 'assets/icons/grams.svg';
import { Button } from 'components/buttons';
import { Row } from 'components/layout';
import { useSerialScaleConfiguration } from '../hooks/useSerialScaleConfiguration';
import { Loader } from 'components/backoffice/loader';
import { colors } from 'css/Theme';
import { useScaleSettings } from '../hooks/useScaleSettings';
import { UnsavedChangesPopup } from 'components/sharedPopups/UnsavedChangesPopup';
import { ModalVariation } from 'components/modals/Modal';

type ScaleConfigurationProps = {
  scale: SerialScale;
};

// Avoid re-rendering when the serial options and connection status change
const isSameScale = (prev: ScaleConfigurationProps, next: ScaleConfigurationProps) => {
  return prev.scale.id !== next.scale.id;
};

const ScaleConfigureSerialListItemImpl = ({ scale }: ScaleConfigurationProps) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <SettingsListItem
        automationId='scale_configure_serial'
        title='Advanced settings'
        subtitle='Manually configure your scale'
        onClick={() => setShowModal(true)}
      />
      {showModal && <ConfigurationModal scale={scale} hide={() => setShowModal(false)} />}
    </>
  );
};

export const ScaleConfigureSerialListItem = React.memo(ScaleConfigureSerialListItemImpl, isSameScale);

const ConfigurationModal = ({ hide: hideModal, scale }: { hide: () => void; scale: SerialScale }) => {
  const {
    autoConfigureScale,
    formConfiguration,
    hasChanges,
    isConfiguring,
    measurement,
    onCloseModal,
    options,
    saveChanges,
    setConfigurationOption,
  } = useSerialScaleConfiguration({ scale });

  const [showConfirm, setShowConfirm] = useState(false);

  const { selectScale } = useScaleSettings();

  const closeConfirmation = () => {
    setShowConfirm(false);
  };

  const saveAndContinue = async () => {
    saveChanges();
    hideModal();
  };

  const selectAndContinue = () => {
    selectScale(scale.id);
    hideModal();
  };

  const handleAutoConfigure = () => {
    autoConfigureScale({ onSuccess: selectAndContinue });
  };

  const handleHide = () => {
    if (hasChanges) {
      setShowConfirm(true);
      return;
    }

    hideModal();
  };

  useEffect(() => {
    return onCloseModal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SimpleModal
        header={`Configure ${scale.name} scale`}
        hide={hideModal}
        variation={ModalVariation.SettingModal}
        modalName='serial-configuration-modal'
        footer={
          <ButtonRow>
            <Button tertiary onClick={handleHide}>
              Cancel
            </Button>
            <Button onClick={saveAndContinue} disabled={isConfiguring || !hasChanges}>
              Save
            </Button>
          </ButtonRow>
        }
      >
        <Content>
          {isConfiguring && <ConfigurationLoader />}
          {!isConfiguring && (
            <>
              <SettingsListItem
                automationId='scale_configuration-baud_rate'
                title={'Baud Rate'}
                subtitle='Set transfer rate (bits/sec)'
                actions={[
                  <StyledSelect
                    onChange={(value) => setConfigurationOption({ baudRate: value })}
                    options={options.baudRate}
                    value={formConfiguration.baudRate.toString()}
                  />,
                ]}
              />
              <SettingsListItem
                automationId='scale_configuration-data_bit'
                title={'Data Bits'}
                subtitle='Set bits per character'
                actions={[
                  <StyledSelect
                    onChange={(value) => setConfigurationOption({ dataBits: value })}
                    options={options.dataBits}
                    value={formConfiguration.dataBits.toString()}
                  />,
                ]}
              />
              <SettingsListItem
                automationId='scale_configuration-stop_bit'
                title={'Stop Bits'}
                subtitle='Set bits after each character'
                actions={[
                  <StyledSelect
                    onChange={(value) => setConfigurationOption({ stopBits: value })}
                    options={options.stopBits}
                    value={formConfiguration.stopBits.toString()}
                  />,
                ]}
              />
              <SettingsListItem
                automationId='scale_configuration-parity'
                title={'Parity'}
                subtitle='Set error detection method'
                actions={[
                  <StyledSelect
                    onChange={(value) => setConfigurationOption({ parity: value })}
                    options={options.parity}
                    value={formConfiguration.parity}
                  />,
                ]}
              />
              <SettingsListItem
                title={'Test weight reading'}
                subtitle={'Place item on scale to test weight'}
                actions={[
                  <InputContainer>
                    <Input
                      disabled={true}
                      readOnly
                      placeholder={'--'}
                      endAdornment={<GramsIcon style={{ maxWidth: 'unset' }} />}
                      value={measurement?.value.toString() ?? ''}
                    />
                  </InputContainer>,
                ]}
              />
              <SettingsDivider />
              <SettingsListItem
                title='A&D FX series scales'
                subtitle='Settings can be detected automatically'
                actions={[
                  <Button secondary onClick={handleAutoConfigure}>
                    Auto-configure scale
                  </Button>,
                ]}
              />
              <SettingsDivider />
            </>
          )}
        </Content>
      </SimpleModal>
      {showConfirm && (
        <UnsavedChangesPopup
          automationId={`serial_config_unsaved_changes`}
          save={saveAndContinue}
          discard={hideModal}
          close={closeConfirmation}
        />
      )}
    </>
  );
};

const ConfigurationLoader = () => {
  return (
    <LoadingContainer>
      <Loader size='3x' variant='grey' />
      Auto-configuring your scale
    </LoadingContainer>
  );
};

const ButtonRow = styled(Row)`
  gap: 16px;
  justify-content: space-between;
  width: 100%;

  & > ${Button} {
    flex-grow: 1;
    height: 56px;
  }
`;

const InputContainer = styled.div`
  width: 200px;
`;

const LoadingContainer = styled.div`
  align-items: center;
  color: ${colors.dutchie.gray80};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 24px 0;
`;

const Content = styled.div`
  padding: 0 32px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;
