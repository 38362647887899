import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { callback } from 'models/Misc';
import { Chevron } from 'assets/icons/chevron';

export type BreadCrumb = {
  title: string;
  onClick?: callback;
  active?: boolean;
};

type BreadCrumbsProps = {
  items: BreadCrumb[];
};

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ items }) => {
  if (!items.length) {
    return null;
  }

  return (
    <BreadcrumbsContainer>
      {items.map((item, index) => (
        <BreadcrumbContainer key={item.title}>
          {item.active ? (
            <BreadcrumbActive onClick={item.onClick}>{item.title}</BreadcrumbActive>
          ) : (
            <BreadcrumbStep onClick={item.onClick}>{item.title}</BreadcrumbStep>
          )}
          {index !== items.length - 1 && (
            <BreadcrumbBreak>
              <Chevron width={12} height={12} />
            </BreadcrumbBreak>
          )}
        </BreadcrumbContainer>
      ))}
    </BreadcrumbsContainer>
  );
};

const BreadcrumbsContainer = styled.div`
  display: flex;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Breadcrumb = styled.span`
  font-size: 16px;
  color: ${colors.dutchie.darkGrey};
`;

const BreadcrumbStep = styled(Breadcrumb)`
  font-weight: normal;
  cursor: pointer;
`;

const BreadcrumbActive = styled(Breadcrumb)`
  font-weight: 500;
`;

const BreadcrumbBreak = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
`;
