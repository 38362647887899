import React from 'react';

import { Skeleton } from 'components/misc';
import { Container, Row, SubTotalRow, TotalRow } from './OrderTotals.styles';

import { useOrderTotals } from './useOrderTotals';

import type { Cart } from 'models/Cart';

type OrderTotalsLoadingSkeletonProps = {
  align?: 'top' | 'bottom';
  cart: Partial<Cart>;
  showTotal: boolean;
};

export const OrderTotalsLoadingSkeleton = ({
  align,
  cart,
  showTotal,
}: OrderTotalsLoadingSkeletonProps): JSX.Element => {
  const { showLoyaltyAsDiscount, showFeesAndDonations, showRounding, showTipAmount } = useOrderTotals({
    cart,
    hideGetTotalBtn: false,
  });

  return (
    <Container data-testid='order-totals-loading-skeleton-container' align={align}>
      <SubTotalRow>
        <div>Subtotal</div>
        <Skeleton width={50} />
      </SubTotalRow>
      {showLoyaltyAsDiscount ? (
        <Row>
          <div>Discounts & loyalty</div>
          <Skeleton width={40} />
        </Row>
      ) : (
        <>
          <Row>
            <div>Discounts</div>
            <Skeleton width={40} />
          </Row>
          <Row>
            <div>Loyalty</div>
            <Skeleton width={40} />
          </Row>
        </>
      )}
      <Row>
        <div>Tax</div>
        <Skeleton width={50} />
      </Row>
      {showFeesAndDonations && (
        <Row>
          <div>Fees & donations</div>
          <Skeleton width={50} />
        </Row>
      )}
      {showRounding && (
        <Row>
          <div>Rounding</div>
          <Skeleton width={40} />
        </Row>
      )}
      {showTipAmount && (
        <Row>
          <div>Tip</div>
          <Skeleton width={50} />
        </Row>
      )}
      {showTotal && (
        <TotalRow>
          <div>Order Total</div>
          <Skeleton width={60} />
        </TotalRow>
      )}
    </Container>
  );
};
