import React from 'react';
import { ConnectionStatusPill } from 'pages/SettingsPage/Status/ConnectionStatusPill';
import { useConnectedFulfillmentPrinter } from 'util/hooks/hardware/useConnectedHardware';

export const FulfillmentReceiptPrinterStatusPill = () => {
  const { device } = useConnectedFulfillmentPrinter();

  return (
    <ConnectionStatusPill
      automationId='fulfillment_printer_settings-connection_status'
      connected={!!device}
      deviceName={device?.name}
    />
  );
};
