import React from 'react';

import { PaymentIconDimensions } from './types';
import type { PaymentIconProps } from './types';

export const CashIcon = ({
  width = PaymentIconDimensions.width,
  height = PaymentIconDimensions.height,
  disabled,
}: PaymentIconProps) => {
  if (disabled) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
        <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
          <rect x='0.75' y='2.75' width='18.5' height='14.5' rx='1.25' stroke='#828A8F' stroke-width='1.5' />
          <path
            d='M23 10.5V19C23 20.1046 22.1046 21 21 21H6.5'
            stroke='#828A8F'
            stroke-width='1.5'
            stroke-linecap='round'
          />
          <circle cx='4' cy='6' r='1' fill='#828A8F' />
          <circle cx='16' cy='14' r='1' fill='#828A8F' />
          <circle cx='10' cy='10' r='2.5' stroke='#828A8F' stroke-width='1.5' />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / PaymentIconDimensions.width}, ${height / PaymentIconDimensions.height})`}>
        <rect y='2' width='20' height='16' rx='3' fill='#C4E5DD' />
        <rect x='0.75' y='2.75' width='18.5' height='14.5' rx='1.25' stroke='#00A47C' stroke-width='1.5' />
        <path
          d='M23 10.5V19C23 20.1046 22.1046 21 21 21H6.5'
          stroke='#00A47C'
          stroke-width='1.5'
          stroke-linecap='round'
        />
        <circle cx='4' cy='6' r='1' fill='#00A47C' />
        <circle cx='16' cy='14' r='1' fill='#00A47C' />
        <circle cx='10' cy='10' r='2.5' stroke='#00A47C' stroke-width='1.5' />
      </g>
    </svg>
  );
};
