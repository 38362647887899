import { SyntheticEvent, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { compareAsc } from 'date-fns';

import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch, useSearchParams } from 'util/hooks';
import { useCloseBirthdateModalFix } from 'util/hooks/launch-darkly/useCloseBirthdateModalFix';
import { useVerifyBirthdateMutation } from 'queries/v2/cart/verify-customer-birthdate';

import type { VerifyBirthdateModalProps } from './VerifyBirthdateModal';

export const useVerifyBirthdateModal = ({
  guestDob,
  guestId,
  hide,
  isGuestAnonymous = true,
  onSuccess,
  shipmentId,
}: VerifyBirthdateModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchParams] = useSearchParams();

  const isCartPage = history.location.pathname.includes('/cart');
  const { isCloseBirthdateModalFixEnabled } = useCloseBirthdateModalFix();
  // Note: this is the same value we could pull from useTransactionManager; however, that hook
  // includes useEffects that could trigger this modal to reopen.
  // TODO: We will want to move those useEffects outside the hook to prevent this from happening.
  const isExistingOrder = searchParams.get('guest') !== null;

  const shouldReturnToGuestList = useMemo(() => {
    if (!isCloseBirthdateModalFixEnabled) {
      return isCartPage;
    }

    /* Currently, we should only return to the guest list when closing this modal if:
     * - The modal is opened on the cart page
     * - We're not opening an existing order that already had the DOB verified (missing guestId in URL)
     */
    const shouldReturnToGuestlist = isCartPage && !isExistingOrder;

    return shouldReturnToGuestlist;
  }, [isCloseBirthdateModalFixEnabled, isCartPage, isExistingOrder]);

  const [birthdate, setBirthdate] = useState<Date | null>(null);

  const { mutateAsync: verifyBirthdate, isLoading: isVerifyingBirthdate } = useVerifyBirthdateMutation();

  const validateBirthdate = () => {
    const guestDobDate = guestDob ? new Date(guestDob) : undefined;

    if (guestDobDate) {
      guestDobDate.setHours(0, 0, 0, 0);
    }

    if (isGuestAnonymous) {
      // We can't validate the birthdate against an anonymous guest
      // since there is not profile to compare it to
      return true;
    } else if (!birthdate) {
      dispatch(warningNotification('Please enter a birthdate'));
      return false;
    } else if (!guestDob) {
      dispatch(warningNotification('No birthdate set on customer profile'));
      return false;
    } else if (guestDobDate && compareAsc(birthdate, guestDobDate) !== 0) {
      dispatch(errorNotification('Birthdate does not match customer profile'));
      return false;
    } else {
      return true;
    }
  };

  // Handlers

  const handleChangeBirthdate = (date: Date | null, event: SyntheticEvent<unknown>) => setBirthdate(date);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && birthdate && !isVerifyingBirthdate) {
      onSubmit();
    }
  };

  const onCancel = () => {
    hide();
    if (shouldReturnToGuestList) {
      history.push('/guestlist');
    }
  };

  const onSubmit = async () => {
    if (!birthdate || !validateBirthdate()) {
      return;
    }

    try {
      await verifyBirthdate({ birthdate, guestId, shipmentId });
      hide();
      onSuccess(birthdate);
      dispatch(successNotification('Birthdate successfully verified'));
    } catch (e) {
      dispatch(errorNotification('Customer does not meet age requirements'));
    }
  };

  return {
    birthdate,
    handleChangeBirthdate,
    handleKeyDown,
    isSubmitDisabled: !birthdate || isVerifyingBirthdate,
    onCancel,
    onSubmit,
  };
};
