import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { getGuestStatuses, getGuests, loadCardStatusDisplayOptions } from 'store/actions/GuestListActions';
import { NavButton } from 'components/buttons';
import { RefreshIcon } from 'assets/icons/guest-list/RefreshIcon';

export const RefreshButton: FC = () => {
  const dispatch = useDispatch();

  const handleRefresh = () => {
    dispatch(getGuestStatuses());
    dispatch(getGuests());
    dispatch(loadCardStatusDisplayOptions());
  };
  return (
    <NavButton
      title='Refresh'
      onClick={handleRefresh}
      icon={<RefreshIcon width={16} height={16} />}
      automationId='action-bar_button_refresh'
      margin='0'
    />
  );
};
