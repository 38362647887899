import { css } from 'styled-components';

export const additionalTokens = css`
  :root {
    --button-border-active-color-default: rgba(0, 0, 0, 0.5);
    --button-border-radius-large: 8px;
    --button-border-radius-medium: 6px;
    --button-border-radius-small: 4px;
    --button-border-width: 1px;
    --button-letter-spacing: 0.00625rem;

    --font-family-text: Matter, -apple-system, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --font-body-default-size: 16px;
    --font-body-default: 400 var(--font-body-default-size) var(--font-family-text);
    --font-weight-bold: 700;
    --font-weight-normal: 400;
    --font-weight-semibold: 600;
    --font-letter-spacing: 0.00625rem;

    --color-additional-colors-rose: #ffe5e8;
  }
`;
