import React from 'react';

import { colors } from 'css/Theme';

type ExistingProfileIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function ExistingProfileIcon(props: ExistingProfileIconProps) {
  const { height = 24, width = 24, color = colors.dutchie.blue, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.6369 22H19.0302C19.5526 22 20.0537 21.8049 20.4231 21.4576C20.7925 21.1103 21 20.6392 21 20.1481V6.48169C21 6.07426 20.8276 5.68258 20.5213 5.3946L17.3874 2.45002C17.0806 2.16187 16.6648 2 16.2311 2H4.6369C4.42199 1.99988 4.20915 2.03957 4.01056 2.11681C3.81197 2.19405 3.63151 2.30732 3.4795 2.45015C3.32748 2.59298 3.20689 2.76258 3.12462 2.94924C3.04235 3.1359 3 3.33598 3 3.53803V20.461C3.00026 20.869 3.17284 21.2602 3.47979 21.5487C3.78674 21.8371 4.20294 22 4.6369 22Z'
        fill={color}
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.42915 8.999C9.76329 9.00847 10.0959 8.95084 10.4074 8.82951C10.7189 8.70819 11.0029 8.52563 11.2425 8.29264C11.4822 8.05965 11.6728 7.78096 11.8029 7.47305C11.933 7.16514 12 6.83427 12 6.5C12 6.16573 11.933 5.83486 11.8029 5.52695C11.6728 5.21904 11.4822 4.94035 11.2425 4.70736C11.0029 4.47437 10.7189 4.29181 10.4074 4.17049C10.0959 4.04916 9.76329 3.99153 9.42915 4.001C8.77864 4.01945 8.16096 4.29082 7.70736 4.75746C7.25376 5.2241 7 5.84923 7 6.5C7 7.15077 7.25376 7.7759 7.70736 8.24254C8.16096 8.70918 8.77864 8.98055 9.42915 8.999Z'
        fill='white'
      />
      <path d='M13 12C12.5427 10.2854 11.1281 9 9.50042 9C7.87271 9 6.45727 10.2864 6 12' fill='white' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.25 15C5.25 14.5858 5.58579 14.25 6 14.25H18C18.4142 14.25 18.75 14.5858 18.75 15C18.75 15.4142 18.4142 15.75 18 15.75H6C5.58579 15.75 5.25 15.4142 5.25 15Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.25 18C5.25 17.5858 5.58579 17.25 6 17.25H14C14.4142 17.25 14.75 17.5858 14.75 18C14.75 18.4142 14.4142 18.75 14 18.75H6C5.58579 18.75 5.25 18.4142 5.25 18Z'
        fill='white'
      />
    </svg>
  );
}
