import React, { FC } from 'react';
import styled from 'styled-components';

import { PaginationButtons } from './PaginationButtons';
import { PaginationProps } from './common';

const CompactContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 3.5rem;
  width: 100%;
`;

export const CompactPagination: FC<PaginationProps> = ({
  pageIndex,
  pageSize,
  dataLength,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  goToPage,
}) => {
  const currentPage = pageIndex + 1;
  const pagesCount = Math.ceil(dataLength / pageSize);

  return (
    <CompactContainer data-testid='pagination_compact_container'>
      <PaginationButtons
        currentPage={currentPage}
        pagesCount={pagesCount}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        goToPage={goToPage}
      />
    </CompactContainer>
  );
};
