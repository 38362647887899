import React, { FC } from 'react';

import { Cart } from 'models/Cart';
import { roundChangeDue } from 'util/Helpers';
import { CartScreen } from './screens/CartScreen';
import { IdleScreen } from './screens/IdleScreen';
import { ThankYouScreen } from './screens/ThankYouScreen';

export type CFDProps = {
  locationName?: string;
  loggedIn?: boolean;
  customIdleImage?: string;
  features?: Record<string, boolean>;
  cart?: Cart;
  checkoutComplete?: boolean;
  changeDue?: number;
};

/**
 * Main component for CFD
 * @param CFDProps an object containing all needed props for CFD
 * - locationName - name of the dispensary for thank you message
 * - loggedIn - whether logged in to show CFD or idle screen
 * - customIdleImage - loads a custom image instead of default welcome
 * - features - object of all features of POS
 * - cart - current cart to show or undefined
 * - checkoutComplete - whether payment has been completed
 * - changeDue - amount of change for customer or undefined
 */
export const CustomerFacingDisplay: FC<CFDProps> = ({
  locationName,
  loggedIn,
  customIdleImage,
  features = {}, // if the prop is undefined, default to {}
  cart,
  checkoutComplete,
  changeDue,
}) => {
  const cartHasItems = (cart?.Cart ?? []).length > 0;
  const showCart = loggedIn && cart && cartHasItems;
  const roundedChangeDue = roundChangeDue(features, changeDue ?? 0);

  return (
    <>
      <IdleScreen customIdleImage={customIdleImage} />
      <CartScreen show={showCart && !checkoutComplete} cart={cart} features={features} />
      <ThankYouScreen show={showCart && checkoutComplete} locationName={locationName} changeDue={roundedChangeDue} />
    </>
  );
};
