import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, differenceInDays, differenceInYears, parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { showScanPopup } from 'store/actions/PopupsActions';
import { checkInCustomer, createCustomer } from 'store/actions/CustomerActions';
import { BarcodeScanEntryResponse } from 'queries/v2/guest/barcode-scan-entry';
import { getVariationForCustomerStatus } from 'components/misc/Pill';
import { CheckListItemStatus } from './components/CheckListItem';
import type { State } from 'store';
import type { CustomerDetails } from 'models/Customer';
import { customEventKeys, logger } from 'util/logger';

type UseScanDetailsPopupParams = {
  barcodeScanEntryResponse: BarcodeScanEntryResponse;
  hide: () => void;
};

export function useScanDetailsPopup(params: UseScanDetailsPopupParams) {
  const { hide, barcodeScanEntryResponse } = params;
  const { ValidatedIdentificationCustomer, ValidatedMatchedCustomers, ValidatedMatchedCaregivers } = barcodeScanEntryResponse;

  // state
  const matchCount = ValidatedMatchedCustomers?.length ?? 0;
  const isMatch = matchCount > 0;
  const isMultiMatch = matchCount > 1;
  const defaultCustomer = isMatch ? ValidatedMatchedCustomers[0] : ValidatedIdentificationCustomer;
  const [customer, setCustomer] = useState(defaultCustomer);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // caregiver
  const matchCaregiverCount = ValidatedMatchedCaregivers?.length ?? 0;
  const caregiver = matchCaregiverCount > 0 ? ValidatedMatchedCaregivers[0] : null;

  // hooks
  const history = useHistory();
  const dispatch = useAppDispatch();
  const features = useAppSelector((state: State) => state.settings.features);
  const register = useAppSelector((state: State) => state.settings.selectedRegister);

  // computed
  const now = new Date();
  const age = customer.DateOfBirth && differenceInYears(now, parseISO(String(customer.DateOfBirth)));
  const driversLicenseExpirationCountdownDays =
    customer.DriversLicenseExpirationDate ? differenceInDays(parseISO(customer.DriversLicenseExpirationDate), Date.now()) : 0;
  const mjStateIdExpirationCountdownDays =
    customer.MJStateIdExpirationDate ? differenceInDays(parseISO(customer.MJStateIdExpirationDate), Date.now()) : 0;
  const formattedAge = ` - ${age} years old`;
  const formattedDateOfBirth = customer.DateOfBirth && format(parseISO(String(customer.DateOfBirth)), 'MMMM dd, yyyy');
  const formattedDriversLicenseExpirationDate =
    customer.DriversLicenseExpirationDate && format(parseISO(String(customer.DriversLicenseExpirationDate)), 'MMMM dd, yyyy');
  const formattedDriversLicenseExpirationCountdown =
    driversLicenseExpirationCountdownDays > 0 && driversLicenseExpirationCountdownDays < 60
      ? ` - expires in ${driversLicenseExpirationCountdownDays} days`
      : '';
  const formattedMJStateIdExpirationDate =
    customer.MJStateIdExpirationDate && format(parseISO(customer.MJStateIdExpirationDate), 'MMMM dd, yyyy');
  const formattedMJStateIdExpirationCountdown =
    mjStateIdExpirationCountdownDays > 0 && mjStateIdExpirationCountdownDays < 60
      ? ` - expires in ${mjStateIdExpirationCountdownDays} days`
      : '';
  const showStatusAttribute = !!customer.Status;
  const showNicknameAttribute = !!customer.Nickname;
  const showCustomerTypeAttribute = !!customer.CustomerType;
  const showLastPurchaseAttribute = !!customer.LastTransaction;
  const showMedicalIdAttribute = !!customer.IsMedical && !!customer.MJStateIdNumber;
  const showLoyaltyPointsAttribute = !!customer.IsLoyaltyMember;
  const showDateOfBirthCheck = !!customer.DateOfBirth;
  const showDriversLicenseCheck = !!customer.DriversLicenseNumber && !!customer.DriversLicenseExpirationDate;
  const showMedicalIdCheck = !!customer.IsMedical && !!customer.MJStateIdNumber && !!customer.MJStateIdExpirationDate;
  const showChecks = showDateOfBirthCheck || showDriversLicenseCheck || showMedicalIdCheck;
  const isBannedStatusBlocking = customer.IsBanned && features.BlockBannedPatientCheckin;
  const isMedicalIdExpirationBlocking = customer.IsMJStateIdExpired && customer.IsMedical;
  const isDriversLicenseExpirationBlocking = customer.IsDriversLicenseExpired && features.RequireValidDL;
  const isCreateDisabled = isMedicalIdExpirationBlocking || isDriversLicenseExpirationBlocking;
  const isCheckInDisabled =
    isBannedStatusBlocking || isMedicalIdExpirationBlocking || isDriversLicenseExpirationBlocking;
  const isCheckable = showDateOfBirthCheck || showDriversLicenseCheck || showMedicalIdCheck;
  const isBanned = customer.IsBanned;
  const dateOfBirthCheckStatus = !isMatch && customer.IsUnderRecreationalAge && !customer.IsUnderMedicalAge
  ? CheckListItemStatus.WARN
  : (customer.IsMedical && customer.IsUnderMedicalAge) ||
    (!customer.IsMedical && customer.IsUnderRecreationalAge)
  ? CheckListItemStatus.FAIL
  : CheckListItemStatus.PASS;
  const driversLicenseCheckStatus = customer.IsDriversLicenseExpired ? CheckListItemStatus.FAIL : CheckListItemStatus.PASS;
  const mjStateIdCheckStatus = customer.IsMJStateIdExpired ? CheckListItemStatus.FAIL : CheckListItemStatus.PASS;
  const showNewCustomerSubtitle = !isMatch && isCheckable;
  const showMatchCountSubtitle = isMultiMatch;
  const showCaregiverName = !!caregiver?.FullName;
  const nameText = customer.FullName;
  const matchCountSubtitleText = `${matchCount} matches found`;
  const statusAttributeText = customer.Status;
  const statusAttributeVariation = getVariationForCustomerStatus(customer.Status);
  const nicknameAttributeText = customer.Nickname;
  const customerTypeAttributeText = customer.CustomerType;
  const lastTransactionAttributeText = customer.LastTransaction && format(parseISO(String(customer.LastTransaction)), 'MMMM dd, yyyy');
  const mjStateIdNumberAttributeText = customer.MJStateIdNumber;
  const loyaltyPointsAttributeText = customer.LoyaltyPoints;
  const caregiverNameAttributeText = caregiver?.FullName;
  const dateOfBirthCheckText = `${formattedDateOfBirth}${formattedAge}`;
  const driversLicenseCheckText = `${formattedDriversLicenseExpirationDate}${formattedDriversLicenseExpirationCountdown}`;
  const mjStateIdCheckText = `${formattedMJStateIdExpirationDate}${formattedMJStateIdExpirationCountdown}`;

  const customerSelectOptions = ValidatedMatchedCustomers.map((customer) => ({
    value: String(customer.Id),
    displayValueName: customer.FullName,
    displayValueLastTransaction: `${customer.LastTransaction ? ` — last purchase: ${format(parseISO(customer.LastTransaction), 'MMMM dd, yyyy')}` : ''}`,
  }));

  // tracking
  useEffect(() => {
    logger.info('ScanDetailsPopup mounted', {
      key: customEventKeys.scanning.id.scanDetailsPopupMounted,
      buttonState: {
        isCreateDisabled,
        isCheckInDisabled,
      },
      createDisabledReasons: {
        isMedicalIdExpirationBlocking,
        isDriversLicenseExpirationBlocking,
      },
      checkInDisabledReasons: {
        isBannedStatusBlocking,
        isMedicalIdExpirationBlocking,
        isDriversLicenseExpirationBlocking,
      },
      data: {
        isCheckable,
        isBanned,
        dateOfBirthCheckStatus,
        driversLicenseCheckStatus,
        mjStateIdCheckStatus,
      },
    });
  }, [dateOfBirthCheckStatus, driversLicenseCheckStatus, isBanned, isBannedStatusBlocking, isCheckInDisabled, isCheckable, isCreateDisabled, isDriversLicenseExpirationBlocking, isMedicalIdExpirationBlocking, mjStateIdCheckStatus]);

  // handlers
  const handleCustomerSelect = (id: string) => {
    const selectedCustomer = ValidatedMatchedCustomers.find((customer) => String(customer.Id) === id);

    if (selectedCustomer) {
      setCustomer(selectedCustomer);
    }
  };

  const handleScanAnotherButtonClick = () => {
    dispatch(showScanPopup({}));
  };

  const handleCheckInButtonClick = async () => {
    setIsSubmitting(true);

    const response = await dispatch(
      checkInCustomer({
        guestId: customer.Id,
        mjStateId: customer.MJStateIdNumber,
        register: register?.value,
      })
    ).unwrap();

    setIsSubmitting(false);

    const shipmentId = response?.[0]?.ShipmentId;

    if (shipmentId) {
      hide();
    }
  };

  const handleCreateProfileButtonClick = async () => {
    setIsSubmitting(true);

    const response = await dispatch(
      createCustomer({
        status: 'Active',
        DOB: customer.DateOfBirth ?? '',
        FullName: customer.FullName,
        NamePrefix: customer.NamePrefix,
        FirstName: customer.FirstName,
        MiddleName: customer.MiddleName,
        LastName: customer.LastName,
        NameSuffix: customer.NameSuffix,
        MJStateIDNo: customer.MJStateIdNumber,
        MJStartDate: customer.MJStateIdStartDate ?? '',
        MJExpirationDate: customer.MJStateIdExpirationDate ?? '',
        DriversLicenseId: customer.DriversLicenseNumber,
        DLExpirationDate: customer.DriversLicenseExpirationDate ?? '',
        IsMedical: customer.IsMedical,
        CustomerTypeId: customer.IsMedical ? 1 : 2,
        address: {
          Country_Code: '',
          city: customer.City ?? '',
          postal_code: customer.PostalCode,
          street: customer.Address1 ?? '',
          street2: customer.Address2,
          state: customer.State ?? '',
        },
      } as CustomerDetails)
    ).unwrap();

    setIsSubmitting(false);

    if (response?.Guest_id) {
      history.push(`/edit-customer?id=${response.Guest_id}`);
    }
  };

  return {
    id: customer.Id,
    isMatch,
    isMultiMatch,
    isBanned,
    isSubmitting,
    isCheckable,
    isCreateDisabled,
    isCheckInDisabled,
    showNewCustomerSubtitle,
    showMatchCountSubtitle,
    showStatusAttribute,
    showNicknameAttribute,
    showCustomerTypeAttribute,
    showLastPurchaseAttribute,
    showMedicalIdAttribute,
    showLoyaltyPointsAttribute,
    showDateOfBirthCheck,
    showDriversLicenseCheck,
    showMedicalIdCheck,
    showChecks,
    showCaregiverName,
    dateOfBirthCheckStatus,
    driversLicenseCheckStatus,
    mjStateIdCheckStatus,
    nameText,
    matchCountSubtitleText,
    statusAttributeText,
    statusAttributeVariation,
    nicknameAttributeText,
    customerTypeAttributeText,
    lastTransactionAttributeText,
    mjStateIdNumberAttributeText,
    loyaltyPointsAttributeText,
    dateOfBirthCheckText,
    driversLicenseCheckText,
    mjStateIdCheckText,
    caregiverNameAttributeText,
    customerSelectOptions,
    handleCustomerSelect,
    handleCheckInButtonClick,
    handleCreateProfileButtonClick,
    handleScanAnotherButtonClick,
  };
}
