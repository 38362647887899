// Price check types

export type PriceCheckServerResponse = {
  FlowerEquivalent: string;
  Price: string;
  PricingTier: {
    ChargeCodeId: number;
    IsExactWeightBundle: boolean;
    LspId: number;
    PricingId: number;
    Name: string | null;
    PriceDetails:
      | {
          StartWeight: number;
          EndWeight: number;
          MedicalPrice: number;
          Price: number;
          MaxWeight?: number;
        }[]
      | null;
    PricingTierPerProduct: boolean;
  };
  ProductGrams: string;
  ProductName: string;
  Quantity: number;
};

export type PriceDetails = {
  startWeight: number;
  endWeight: number;
  medicalPrice: number;
  price: number;
  maxWeight?: number;
};

export type PricingTier = {
  chargeCodeId: number;
  isExactWeightBundle: boolean;
  isPricingTierPerProduct: boolean;
  lspId: number;
  name: string | null;
  priceDetails: PriceDetails[] | null;
  pricingId: number;
};

export type PriceCheckDetails = ReturnType<typeof transformToPriceCheckDetails>;

export const transformToPriceCheckDetails = (serverResponse: PriceCheckServerResponse) => {
  const priceDetails: PriceDetails[] | null =
    serverResponse.PricingTier.PriceDetails?.map((priceDetail) => ({
      startWeight: priceDetail.StartWeight,
      endWeight: priceDetail.EndWeight,
      medicalPrice: priceDetail.MedicalPrice,
      price: priceDetail.Price,
      maxWeight: priceDetail.MaxWeight,
    })) ?? null;

  const pricingTier: PricingTier = {
    chargeCodeId: serverResponse.PricingTier.ChargeCodeId,
    isExactWeightBundle: serverResponse.PricingTier.IsExactWeightBundle,
    isPricingTierPerProduct: serverResponse.PricingTier.PricingTierPerProduct,
    lspId: serverResponse.PricingTier.LspId,
    name: serverResponse.PricingTier.Name,
    priceDetails,
    pricingId: serverResponse.PricingTier.PricingId,
  };

  return {
    flowerEquivalent: serverResponse.FlowerEquivalent,
    price: serverResponse.Price,
    pricingTier,
    productGrams: serverResponse.ProductGrams,
    productName: serverResponse.ProductName,
    quantity: serverResponse.Quantity,
  };
};
