import { useEffect, useRef } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash';

import { isWebViewApp } from './webviewApp';

enum ScrollInputModes {
  OFF = 'off',
  DELAYED_SMOOTH = 'delayed-smooth',
  DELAYED_INSTANT = 'delayed-instant',
  SMOOTH = 'smooth',
}

/**
 * Utility that listens for focus events and scrolls inputs elements into view if necessary.
 * @note Only enabled for iOS app when the LaunchDarkly flag 'pos.register.scroll-inputs-into-view' is enabled.
 */
export const useScrollInputsIntoView = () => {
  const ldClient = useLDClient();
  const scrollInputsIntoViewMode: ScrollInputModes = ldClient?.variation(
    'pos.register.scroll-inputs-into-view',
    ScrollInputModes.OFF
  );

  const focusedInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isWebViewApp || scrollInputsIntoViewMode === ScrollInputModes.OFF) {
      return;
    }

    const isDebounceEnabled =
      scrollInputsIntoViewMode === ScrollInputModes.DELAYED_SMOOTH || scrollInputsIntoViewMode === ScrollInputModes.DELAYED_INSTANT;
    const scrollOptions: ScrollIntoViewOptions = {
      behavior: scrollInputsIntoViewMode === ScrollInputModes.DELAYED_SMOOTH ? 'smooth' : 'instant',
      block: 'center',
      inline: 'nearest',
    };

    const handleFocus = (e: FocusEvent) => {
      if (e.target instanceof HTMLInputElement) {
        focusedInputRef.current = e.target;
      } else {
        focusedInputRef.current = null;
      }
    };

    const handleBlur = () => {
      focusedInputRef.current = null;
    };

    const scrollInputIntoViewIfNeeded = () => {
      if (focusedInputRef.current === null) {
        return;
      }

      // This will only scroll the input into view (centered by default) if the input is not already in view.
      focusedInputRef.current.scrollIntoView(scrollOptions);
    };

    // Debounce prevents the scroll into view from happening multiple times in quick succession and waits until resize is complete.
    const handleResize = isDebounceEnabled ? debounce(scrollInputIntoViewIfNeeded, 100) : scrollInputIntoViewIfNeeded;

    window.addEventListener('focus', handleFocus, true);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('resize', handleResize);
    };
  }, [scrollInputsIntoViewMode]);
};
