import React, { useEffect, FC } from 'react';
import Pusher, * as PusherTypes from 'pusher-js_v7.0.3';
import {
  PusherAuthRequest,
  PusherAuthResponse,
  PusherChannelConfiguration,
  PusherChannelType,
  PusherConnectionStates,
} from 'models/Pusher';
import { authPusherChannel } from 'api/PusherApi';
import { useOnChannelMessage } from 'util/hooks/messaging/useMessageLogging';
import { MessagingProviderName } from 'models/Messaging';
import { getIsAblyRolloutEnabled } from 'util/hooks/launch-darkly/useAblyRollout';

export type PusherChannelProps = {
  enabled: boolean;
  channelType: PusherChannelType;
  channelConfig: PusherChannelConfiguration;
  onChannelMessage: (eventName: string, data: string | object) => void;
  onConnected?: () => void;
  onDisconnected?: () => void;
};

/** @deprecated Clean up with usePusherChannel hook */
export const PusherChannel: FC<PusherChannelProps> = (props: PusherChannelProps) => {
  const { ChannelName: channelName, PusherKey: key, PusherCluster: cluster } = props.channelConfig;
  const { enabled, onChannelMessage, onDisconnected, onConnected, channelType } = props;
  const handleChannelMessage = useOnChannelMessage(onChannelMessage, MessagingProviderName.Pusher);
  const ablyRollout = getIsAblyRolloutEnabled();

  useEffect(() => {
    if (enabled) {
      const pusher = new Pusher(key, {
        cluster: cluster,
        authorizer: function (channel, options): PusherTypes.Authorizer {
          return {
            authorize: function (socketId: string, callback: PusherTypes.AuthorizerCallback) {
              const authPusherRequest: PusherAuthRequest = {
                SocketId: socketId,
              };
              const authString = authPusherChannel(channelType, authPusherRequest);
              authString.then((res: PusherAuthResponse) => {
                callback(null, { auth: res.auth });
              });
            },
          };
        },
      });
      const handleStateChange = (states: PusherConnectionStates) => {
        if (states.current === 'connected') {
          if (onConnected) {
            onConnected();
          }
        } else {
          if (onDisconnected) {
            onDisconnected();
          }
        }
      };

      pusher.connection.bind('state_change', handleStateChange);

      const channel = pusher.subscribe(channelName);

      if (onChannelMessage) {
        if (ablyRollout) {
          channel.bind_global(handleChannelMessage);
        } else {
          channel.bind_global(onChannelMessage);
        }
      }

      return () => {
        pusher.disconnect();
        channel.unbind();
        pusher.unbind('state_change', handleStateChange);
      };
    }
  }, [enabled, channelName, key, cluster, channelType, onChannelMessage, onConnected, onDisconnected, ablyRollout, handleChannelMessage]);

  return <></>;
};
