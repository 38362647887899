import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import { colors } from "css/Theme";

export const PageHeader = ({ bottomBorder = true, children, padding = '0 24px' }: PropsWithChildren<{ padding?: string; bottomBorder?: boolean}>) => {
  const child = <HeaderInner padding={padding}>{children}</HeaderInner>;
  if (bottomBorder) {
    return <HeaderOuter>{child}</HeaderOuter>;
  }
  return child;
};

const HeaderOuter = styled.div`
  border-bottom: 1px solid ${colors.dutchie.shadowGrey};
`;

const HeaderInner = styled.div<{ padding: string}>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ padding }) => padding};
`;

export const PageTitle = styled.h1`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1.5rem 0;
  padding: 1px 0;
`;
