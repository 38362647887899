import React, { FC, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import * as Yup from 'yup';
import imageCompression from 'browser-image-compression';
import { State } from 'store';
import { ConfirmationPopup, usePopup } from 'components/popups';
import { Camera } from 'components/Camera';
import { EditIdentificationTypeEnum, CustomerIdentification } from 'models/Customer';
import { editPrimaryIdentification } from 'store/actions/CustomerActions';
import { colors } from 'css/Theme';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-circle.svg';
import { dataURItoBlob } from 'util/Helpers';
import { errorNotification } from 'store/actions/NotificationsActions';
import { DatePickerField, InputField } from 'components/inputs';
import { ButtonWithIcon } from 'components/buttons';
import { useDynamicYearRange } from 'util/helpers/date-helpers/getDynamicYearRange';
import { Camera as CapacitorCamera, CameraResultType, CameraSource } from '@capacitor/camera';
import { isAndroid } from 'util/hooks';
import { useUpdateSecondaryIdentificationMutation } from 'queries/v2/guest/edit-secondary-identification';

import type { SecondaryIdentification } from 'queries/v2/guest/types';

type CommonEditIdentificationProps = {
  isVisible: boolean;
  hide: () => void;
  guestId: number;
};

type EditPrimaryIdentificationProps = CommonEditIdentificationProps & {
  type: EditIdentificationTypeEnum.Primary;
  id: CustomerIdentification;
};

type EditSecondaryIdentificationProps = CommonEditIdentificationProps & {
  type: EditIdentificationTypeEnum.Secondary;
  id?: SecondaryIdentification;
};

type EditIdentificationPopupProps = EditPrimaryIdentificationProps | EditSecondaryIdentificationProps;

export type EditIdentificationFormValues = {
  name?: string;
  idNumber?: string;
  expiration?: string;
  imageUrl?: string;
};

export const EditIdentificationPopup: FC<EditIdentificationPopupProps> = (props) => {
  const dispatch = useDispatch();
  const { isVisible, hide, guestId } = props;
  const isAddingNewId = props.type === EditIdentificationTypeEnum.Secondary && !props.id;

  const { isVisible: cameraIsVisible, toggle: toggleCamera } = usePopup();
  // Only indicates pending edit for Primary ID in Redux, not Secondary ID from React Query
  const isUpdatingPrimaryId = useSelector((state: State) => state.customer.pendingIdentificationEdit);
  const formRef = useRef<FormikProps<EditIdentificationFormValues> | null>(null);
  const driverLicenseYearRange = useDynamicYearRange({ numberOfYearsAhead: 20 });

  const { mutate: updateSecondaryIdentification, isLoading: isUpdatingSecondaryId } =
    useUpdateSecondaryIdentificationMutation();

  const isSavingIdentification = isUpdatingPrimaryId || isUpdatingSecondaryId;

  const onSubmit = async (values: EditIdentificationFormValues) => {
    if (props.type === EditIdentificationTypeEnum.Primary) {
      if (values.idNumber && values.expiration) {
        await dispatch(
          editPrimaryIdentification({
            Guest_id: guestId,
            MJStateIDNo: values.idNumber,
            ExpirationDate: values.expiration,
            ImgItem: dataURItoBlob(values.imageUrl || ''),
          })
        );
        hide();
      }
    } else {
      if (values.idNumber && values.name) {
        updateSecondaryIdentification({
          guestId,
          identityId: props.id?.identityId,
          stateId: values.idNumber,
          image: dataURItoBlob(values.imageUrl || ''),
          name: values.name,
        });
        hide();
      }
    }
  };

  const onDrop = useCallback(
    (files: File[]) => {
      const [file] = files;
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        if (formRef && formRef.current) {
          formRef.current.setFieldValue('imageUrl', binaryStr);
        }
      };
      imageCompression(file, {
        maxSizeMB: 0.049,
        maxWidthOrHeight: 1080,
      })
        .then((compressedFile) => {
          return reader.readAsDataURL(compressedFile);
        })
        .catch((error) => {
          dispatch(errorNotification(error.message));
        });
    },
    [dispatch]
  );

  const takePhoto = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // Use Capacitor to capture image on Android devices
    if (isAndroid) {
      // Permissions should be automatically granted, but confirmation is a best practice
      const permissions = await CapacitorCamera.requestPermissions();
      if (permissions.camera === 'denied') {
        dispatch(errorNotification('Camera permissions are required to take a photo'));
        return;
      }

      const image = await CapacitorCamera.getPhoto({
        quality: 90,
        // specifying source avoids an unnecessary popup
        source: CameraSource.Camera,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
      });

      if (image.dataUrl && formRef && formRef.current) {
        formRef.current.setFieldValue('imageUrl', image.dataUrl);
      }

      return;
    }

    // Toggle popup for other platforms
    toggleCamera();
  };

  const initialValues: EditIdentificationFormValues = {
    name: props.type === EditIdentificationTypeEnum.Secondary ? props.id?.name : undefined,
    idNumber: props.type === EditIdentificationTypeEnum.Secondary ? props.id?.stateId : props.id.number,
    expiration: props.type === EditIdentificationTypeEnum.Primary ? props.id.ExpirationDate : undefined,
    imageUrl:
      props.type === EditIdentificationTypeEnum.Secondary
        ? props.id?.image
          ? `data:image/png;base64, ${props.id?.image}`
          : undefined
        : props.id.image_url
        ? `data:image/png;base64, ${props.id.image_url}`
        : undefined,
  };

  const getValidationSchema = () => {
    if (props.type === EditIdentificationTypeEnum.Primary) {
      return Yup.object().shape({
        idNumber: Yup.string().required().max(35, 'ID number must be 35 characters or less'),
        expiration: Yup.string().required(),
      });
    } else {
      return Yup.object().shape({
        idNumber: Yup.string().required().max(35, 'ID number must be 35 characters or less'),
        name: Yup.string().required(),
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: '.jpg, .jpeg, .jfif, .png, .tiff, .gif',
  });

  const uploadButtons = (
    <>
      <FileUploadButton large icon={PlusIcon} onClick={takePhoto} type='button' label='Take Photo' />
      <FileUploadButton large icon={PlusIcon} type='button' label='Upload Photo' />
    </>
  );

  return (
    <ConfirmationPopup
      small
      automationId='identification-popup_container'
      isVisible={isVisible}
      hide={hide}
      title={`${isAddingNewId ? 'Add' : 'Edit'} Identification`}
      confirm={{
        text: 'Save',
        disabled: isSavingIdentification,
        onClick: () => {
          formRef.current?.handleSubmit();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <ContentDiv>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          innerRef={(instance) => (formRef.current = instance)}
          validationSchema={getValidationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {props.type === EditIdentificationTypeEnum.Primary && (
                <>
                  <DatePickerField
                    automationId='identification-popup_expiration'
                    name='expiration'
                    labelText='Expiration'
                    placeholderText='mm/dd/yyyy'
                    yearRange={driverLicenseYearRange}
                  />
                  <InputField automationId='identification-popup_number' label='Number' name='idNumber' />
                </>
              )}
              {props.type === EditIdentificationTypeEnum.Secondary && (
                <>
                  <InputField automationId='identification-popup_name' label='Name' name='name' placeholder='Name' />
                  <InputField
                    automationId='identification-popup_number'
                    label='Number'
                    name='idNumber'
                    placeholder='Number'
                  />
                </>
              )}
              <ImageWrapperDiv {...getRootProps()}>
                <input {...getInputProps()} />
                {values.imageUrl ? (
                  <EditImageDiv data-testid='identification-popup_image-ready' imageUrl={values.imageUrl}>
                    {uploadButtons}
                  </EditImageDiv>
                ) : (
                  <ImagePlaceholderDiv data-testid='identification-popup_image-not-ready'>
                    {uploadButtons}
                  </ImagePlaceholderDiv>
                )}
              </ImageWrapperDiv>
              <Camera
                isVisible={cameraIsVisible}
                hide={toggleCamera}
                onCapture={(imageSrc) => setFieldValue('imageUrl', imageSrc)}
              />
            </Form>
          )}
        </Formik>
      </ContentDiv>
    </ConfirmationPopup>
  );
};

const ContentDiv = styled.div`
  margin: 0 2rem;
`;

const ImagePlaceholderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 6px;
`;

const FileUploadButton = styled(ButtonWithIcon)`
  padding: 0.875rem 1.25rem;

  & > svg {
    height: 1rem;
    width: 1rem;
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
  }

  &:last-of-type {
    margin-top: 0.875rem;
  }
`;

const ImageWrapperDiv = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  height: 16rem;
`;

const EditImageDiv = styled.div`
  background-image: url(${(props: { imageUrl: string }) => props.imageUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
