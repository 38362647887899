import { getLDClient } from 'util/launchDarkly';
import { isWebViewApp } from '..';

// This flag adds TSP100 support for Star Micronics printers. This printer uses the StarGraphic command set and does
// not support ESC commands. This flag is also used to change the test print command in the new settings UI.
// Enabling this flag will also enable settings ui flag if not yet enabled.
const key = 'pos.register.star-graphic-support.rollout';
export const getIsStarGraphicSupportEnabled = () => {
  if (isWebViewApp) {
    return true;
  }

  // Dependent on the separate print commands flag
  if (!getIsSeparatePrintCommandsEnabled()) {
    return false;
  }

  const ldClient = getLDClient();

  if (process.env.NODE_ENV === 'development' && localStorage.getItem(key) !== null) {
    return localStorage.getItem(key) === 'true';
  }

  return ldClient?.variation(key, false) === true;
};

// The star graphic work is dependent on separate commands being enabled.
const separateCommandsKey = 'pos.register.separate-print-commands.rollout';
export const getIsSeparatePrintCommandsEnabled = () => {
  if (isWebViewApp) {
    return true;
  }

  const ldClient = getLDClient();

  if (process.env.NODE_ENV === 'development' && localStorage.getItem(separateCommandsKey) !== null) {
    return localStorage.getItem(separateCommandsKey) === 'true';
  }

  return ldClient?.variation(separateCommandsKey, false) === true;
};
