import styled from 'styled-components';
import { colors } from 'css/Theme';

export const Pill = styled.span`
  display: inline-flex;
  justify-content: center;
  line-height: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  white-space: nowrap;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
`;

export const GreenPill = styled(Pill)`
  background-color: ${colors.dutchie.green70};
`;

export const LightGreenPill = styled(GreenPill)`
  opacity: 0.5;
`;

export const BluePill = styled(Pill)`
  background-color: ${colors.dutchie.blue};
`;

export const DarkBluePill = styled(Pill)`
  background-color: ${colors.dutchie.opal80};
`;

export const OrangePill = styled(Pill)`
  background-color: ${colors.dutchie.terra};
`;

export const RedPill = styled(Pill)`
  background-color: ${colors.dutchie.red};
`;

export const GreyPill = styled(Pill)`
  border: 1px solid #828a8f;
  color: #828a8f;
`;

export const TransparentPill = styled(Pill)`
  border: 2px solid ${colors.dutchie.shadowGrey};
  padding: 2px 12px 2px 12px;
  color: ${colors.dutchie.shadowGrey};
`;
