import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { logger, customEventKeys } from 'util/logger';
import { v4 as uuidv4 } from 'uuid';

export const successNotificationInternal = createAction(
  'successNotificationInternal',
  (payload: { id: string; message: string }) => ({ payload })
);

export const warningNotificationInternal = createAction(
  'warningNotificationInternal',
  (payload: { id: string; message: string }) => ({ payload })
);

export const removeNotification = createAction('removeNotification', (payload: string) => ({ payload }));
export const removeAllNotifications = createAction('removeAllNotifications');
export const successNotification = createAsyncThunk('successNotification', async (message: string, { dispatch }) => {
  logger.info(`notification displayed: (Success) "${message}"`, {
    key: customEventKeys.notification,
    notificationType: 'success',
    message,
  });

  const id = uuidv4();
  dispatch(successNotificationInternal({ id, message }));
  setTimeout(() => dispatch(removeNotification(id)), 2500);
});
export const warningNotification = createAsyncThunk('warningNotification', async (message: string, { dispatch }) => {
  logger.info(`notification displayed: (Warning) "${message}"`, {
    key: customEventKeys.notification,
    notificationType: 'warning',
    message,
  });

  const id = uuidv4();
  dispatch(warningNotificationInternal({ id, message }));
  setTimeout(() => dispatch(removeNotification(id)), 5000);
});
export const errorNotification = createAction('errorNotification', (payload: string) => {
  logger.info(`notification displayed: (Error) "${payload}"`, {
    key: customEventKeys.notification,
    notificationType: 'error',
    message: payload,
  });

  return { payload };
});
