import React, { FC } from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/icon-error.svg';
import { AlertBanner, AlertBannerStyles } from 'components/misc';

export const PaymentsHubUnavailableWarning: FC = () => (
  <AlertBanner
    style={AlertBannerStyles.error}
    icon={<WarningIcon />}
    text='Dutchie Hub is currently unavailable. Payments cannot be processed via Dutchie Hub at this time.'
  />
);

export const PaymentsHubRegalaWarning: FC = () => (
  <AlertBanner
    style={AlertBannerStyles.error}
    icon={<WarningIcon />}
    text='Regala is currently unavailable. Card payments cannot be processed via Regala at this time.'
  />
);

export const PaymentsHubDutchiePayWarning: FC = () => (
  <AlertBanner
    style={AlertBannerStyles.error}
    icon={<WarningIcon />}
    text='Dutchie Pay is currently unavailable. Payments cannot be processed via DutchiePay at this time.'
  />
);

// Warning message to be displayed in PaymentsButtons component
export const PaymentsHubWarning: FC<{ message: string }> = ({ message }) => (
  <AlertBanner style={AlertBannerStyles.error} icon={<WarningIcon />} text={message} />
);
