import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { loadTransactions } from 'store/actions/TransactionsActions';

export const useGuestTransactions = () => {
  const dispatch = useDispatch();
  const guest = useSelector((state: State) => state.customer.details);
  const transactions = useSelector((state: State) => state.transactions);
  const includeCanceled = useSelector((state: State) => state.transactions.includeCanceledTransactions);

  const reloadTransactions = useCallback(() => {
    dispatch(loadTransactions({ guest_id: guest?.Guest_id, includeCanceledTransactions: includeCanceled }));
  }, [dispatch, guest, includeCanceled]);

  return {
   reloadTransactions,
   transactions,
  };
};
