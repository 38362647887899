export const caregiverListKeys = {
  all: ['customer-caregivers'] as const,
  many: (guestId: number) => [...caregiverListKeys.all, 'many', guestId] as const,
};

export const secondaryIdentificationKeys = {
  all: ['secondary-identification'] as const,
  many: (guestId: number) => [...secondaryIdentificationKeys.all, 'many', guestId] as const,
  one: (identityId: number) => [...secondaryIdentificationKeys.all, 'one', identityId] as const,
};

export const barcodeScanEntryKeys = {
  all: ['barcode-scan-entry'] as const,
  many: (barcode: string) => [...barcodeScanEntryKeys.all, 'many', barcode] as const,
};

export const checkedInGuestsKeys = {
  all: ['checked-in-guests'] as const,
};

export const customerSearchKeys = {
  all: ['customer-search-by-string'] as const,
  one: (searchString: string) => [...customerSearchKeys.all, 'one', searchString] as const,
};
