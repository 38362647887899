import React from 'react';
import styled from 'styled-components';
import AuthCode from 'react-auth-code-input';
import { Button } from 'components/buttons';
import { ReactComponent as LinkingIcon } from 'assets/icons/icon-linking-small.svg';
import { colors } from 'css/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { ApiStates, handleDevPinCodeAddendum, validatePinInput } from './Common';
import { lookUpPinCodeRequest } from 'api/DutchiePayApi';
import { getCustomerDetails } from 'store/actions/CustomerActions';
import { Popup } from 'components/popups';
import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

type ModalActionButtonProps = {
  buttonState: ModalButtonState;
  onClickHandler: () => void;
};

type ModalButtonState = {
  icon?: JSX.Element;
  content: string;
  disabled: boolean;
};

const StyledLinkingIconContainer = styled.div`
  animation: spin 3s linear infinite;
  transform-origin: 8px 16px;
  height: 16px;
  width: 16px;
  margin-top: -15px;
`;
const StyledButtonText = styled.div`
  margin-left: 8px;
`;
const StyledLinkingIcon = styled(LinkingIcon)`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ModalButtonStates = {
  disabled: {
    content: 'Connect account',
    disabled: true,
  },
  enabled: {
    content: 'Connect account',
    disabled: false,
  },
  linking: {
    icon: (
      <StyledLinkingIconContainer>
        <StyledLinkingIcon />
      </StyledLinkingIconContainer>
    ),
    content: 'Linking account...',
    disabled: true,
  },
  errorEnabled: {
    content: 'Retry connecting account',
    disabled: false,
  },
  errorDisabled: {
    content: 'Retry connecting account',
    disabled: true,
  },
};

const ModalActionButton = ({ onClickHandler, buttonState }: ModalActionButtonProps) => {
  const handleClick = () => {
    onClickHandler();
  };

  return (
    <StyledModalActionButton secondary onClick={handleClick} disabled={buttonState.disabled}>
      <StyledModalActionButtonContent>
        {buttonState.icon}
        <StyledButtonText>{buttonState.content}</StyledButtonText>
      </StyledModalActionButtonContent>
    </StyledModalActionButton>
  );
};

type ManualPinCodeInputModalProps = {
  popupIsVisible: boolean;
  setPopupIsVisible: (arg0: boolean) => void;
  handleDutchiePayInStoreCartCreation: () => void;
};

export const ManualPinCodeInputModal = ({
  popupIsVisible,
  setPopupIsVisible,
  handleDutchiePayInStoreCartCreation,
}: ManualPinCodeInputModalProps) => {
  const guestId = useSelector((state: State) => state.customer.details?.Guest_id);

  const [result, setResult] = React.useState<string>('');
  const [authCodeInputDisabled, setAuthCodeDisabled] = React.useState(false);
  const [buttonState, setButtonState] = React.useState(ModalButtonStates.disabled);
  const [apiState, setApiState] = React.useState(ApiStates.idle);
  const [customerLinkSuccessful, setCustomerLinkSuccessful] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchUpdatedCustomer = async () => {
      if (guestId) {
        await dispatch(getCustomerDetails({ guestId }));
      }
    };

    switch (apiState) {
      case ApiStates.idle:
        if (validatePinInput(result)) {
          setButtonState(ModalButtonStates.enabled);
        } else {
          setButtonState(ModalButtonStates.disabled);
        }
        break;
      case ApiStates.active:
        setButtonState(ModalButtonStates.linking);
        setAuthCodeDisabled(true);
        break;
      case ApiStates.success:
        if (!customerLinkSuccessful) {
          setCustomerLinkSuccessful(true);
          fetchUpdatedCustomer();
          handleDutchiePayInStoreCartCreation();
          setPopupIsVisible(false);
        }
        break;
      case ApiStates.error:
        setAuthCodeDisabled(false);
        if (validatePinInput(result)) {
          setButtonState(ModalButtonStates.errorEnabled);
        } else {
          setButtonState(ModalButtonStates.errorDisabled);
        }
        break;
      default:
        break;
    }
  }, [
    apiState,
    customerLinkSuccessful,
    dispatch,
    guestId,
    handleDutchiePayInStoreCartCreation,
    result,
    setPopupIsVisible,
  ]);

  const handleOnChange = (res: string) => {
    if (apiState === ApiStates.idle || apiState === ApiStates.error) {
      setResult(res);
    }
  };

  const handleOnClick = async () => {
    const customerPinCode = `${result}`;

    if ((apiState === ApiStates.idle || apiState === ApiStates.error) && validatePinInput(customerPinCode) && guestId) {
      setApiState(ApiStates.active);

      const processedPinCode = handleDevPinCodeAddendum(customerPinCode);
      const requestBody = {
        PinCode: processedPinCode,
        AccountId: guestId,
      };

      await lookUpPinCodeRequest(requestBody)
        .then(() => {
          setApiState(ApiStates.success);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.warn('Error validating DutchiePay customer linking code with LLx backend.', error);
          setApiState(ApiStates.error);
        });
    }
  };

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <StyledPopup
      isVisible={popupIsVisible}
      hide={() => setPopupIsVisible(false)}
      caption='Code Entry'
      contentMaxHeight={isModalBridgeEnabled ? '100%' : undefined}
    >
      <div>
        <ModalTextContentContainer>
          <ModalHeadline>Enter the customer's code</ModalHeadline>
          <ModalBodyText>
            Have the customer show you their phone and type in their code to connect their account.
          </ModalBodyText>
        </ModalTextContentContainer>
        <StyledAuthCodeInputContainer>
          <AuthCode onChange={handleOnChange} allowedCharacters={'numeric'} disabled={authCodeInputDisabled} />
        </StyledAuthCodeInputContainer>
        {apiState === ApiStates.error && (
          <ModalTextContentContainer>
            <AlertBanner style={AlertBannerStyles.error} text="The entered code doesn't match." />
          </ModalTextContentContainer>
        )}
      </div>
      <ModalFooter>
        <ModalActionButton buttonState={buttonState} onClickHandler={handleOnClick} />
      </ModalFooter>
    </StyledPopup>
  );
};

const StyledAuthCodeInputContainer = styled.div`
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    input {
      width: 55px;
      padding: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.022em;

      color: ${colors.dutchie.gunmetal};
      background: ${colors.dutchie.lightGrey};
      border-radius: 8px;
      border: none;

      &:focus {
        outline: 1px solid #00a47c;
        filter: drop-shadow(0 1px 6px rgba(0, 164, 124, 0.4));
        background: transparent;
      }
    }
  }
`;

const StyledPopup = styled(Popup)`
  width: 500px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  padding: 0;
`;

const ModalFooter = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${colors.dutchie.backgroundGrey};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 24px;
  gap: 8px;
`;

const ModalHeadline = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
`;
const ModalBodyText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
`;

const ModalTextContentContainer = styled.div`
  padding: 0 24px 24px 24px;
`;

const StyledModalActionButton = styled(Button)`
  padding: 12px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: ${colors.dutchie.green};
  color: ${colors.white};
  border: none;
  display: flex;

  &:hover {
    color: ${colors.white};
    border: none;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &:disabled {
    background: ${colors.dutchie.grey70};
  }
`;
const StyledModalActionButtonContent = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`;
