import React from 'react';
import styled, { css } from 'styled-components';

export type BoxProps = Pick<
  React.CSSProperties,
  | 'display'
  | 'alignSelf'
  | 'justifySelf'
  | 'padding'
  | 'margin'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingTop'
  | 'paddingBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
  | 'marginBottom'
  | 'width'
  | 'height'
  | 'maxWidth'
  | 'maxHeight'
  | 'overflow'
  | 'flex'
  | 'color'
>;

export const Box = styled.div<BoxProps>`
  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `}
  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}
    ${({ justifySelf }) =>
    justifySelf &&
    css`
      justify-self: ${justifySelf};
    `}
    ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
    ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
    ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft};
    `}
    ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: ${paddingRight};
    `}
    ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${paddingTop};
    `}
    ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom};
    `}
    ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
    ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}
    ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
    ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
    ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
    ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
    ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
    ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
    ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
    ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;
