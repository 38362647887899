import React, { PropsWithChildren, createContext } from 'react';
import { ReceiptPrinterSettingsContextData } from '../types';
import { useReceiptPrinterSettingsProvider } from '../hooks/useReceiptPrinterSettingsProvider';

export const ReceiptPrinterSettingsContext = createContext<ReceiptPrinterSettingsContextData>(
  {} as ReceiptPrinterSettingsContextData
);

export const ReceiptPrinterSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useReceiptPrinterSettingsProvider();
  return <ReceiptPrinterSettingsContext.Provider value={data}>{children}</ReceiptPrinterSettingsContext.Provider>;
};
