import * as customerApi from 'api/CustomerApi';
import * as api from 'api/FeeDonationApi';
import { AppliedFeesDonations } from './AppliedFeesDonations';
import { AvailableFeesDonations } from './AvailableFeesDonations';
import { SelectedFeeDonation } from './SelectedFeeDonation';
import { AppliedFeeDonation, ApplyFeeDonationRequest, AvailableFeeDonation, MethodEnum } from 'models/FeeDonation';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import styled from 'styled-components';
import { callback } from 'models/Misc';
import { Cart } from 'models/Cart';
import { Location } from 'models/Location';
import { errorNotification } from 'store/actions/NotificationsActions';
import { Popup } from 'components/popups';
import { Flex } from 'components/layout';
import { Button } from 'components/buttons';
import { logger } from 'util/logger';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type FeeDonationPopupProps = {
  hide: callback;
};

export const FeeDonationPopup: FC<FeeDonationPopupProps> = ({ hide }) => {
  const dispatch = useDispatch();
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const selectedLocation = useSelector((state: State) => state.user.selectedLocation);
  const [selected, setSelected] = useState<AvailableFeeDonation | undefined>();
  const [appliedFeesDonation, setAppliedFeesDonations] = useState<Array<AppliedFeeDonation>>([]);
  const [loading, setLoading] = useState(true);
  const [applyTo, setApplyTo] = useState<MethodEnum>(0);

  const { shipmentId } = useTransactionManager();

  const { data: cart, refetch: refetchCartDetails } = useGetCartDetails();

  const applyFeeDonationAction = (params: {
    selected: AvailableFeeDonation;
    comment: string;
    applyTo: MethodEnum;
    calculationValue: string;
    cashValue: string;
    cart: Cart;
    selectedLocation?: Location;
  }) => {
    const { selected, comment, calculationValue, cashValue } = params;
    const feeNumber = parseFloat(calculationValue);
    const percentNumber = parseFloat(cashValue);
    const promises = [];

    const createFeeDonationBody = (): ApplyFeeDonationRequest => ({
      FeeDonationId: selected.FeeDonationId,
      CalculationMethodId: selected.CalculationMethodId,
      CalculationValue: feeNumber,
      CashValue: percentNumber,
      Description: comment,
      ShipmentId: shipmentId,
    });

    promises.push(api.applyFeeDonation(createFeeDonationBody()));
    return Promise.all(promises);
  };

  useEffect(() => {
    if (selected) {
      setApplyTo(selected?.CalculationMethodId as MethodEnum);
    }
  }, [selected]);

  const [calculationValue, setCalculationValue] = useState('');
  const [cashValue, setCashValue] = useState('');

  useEffect(() => {
    if (selected) {
      const calcVal = parseFloat(calculationValue);
      switch (applyTo) {
        case 1:
          setCashValue(calcVal.toFixed(2));
          break;
        case 2:
          setCashValue(((cart.SubTotal - cart.TotalDiscountAndLoyalty) * (calcVal / 100.0)).toFixed(2));
          break;
        case 3:
          setCashValue((calcVal - (cart.GrandTotal % calcVal)).toFixed(2));
          break;
        default:
          break;
      }
    }
  }, [
    selected,
    calculationValue,
    applyTo,
    cart.SubTotal,
    cart.TotalDiscountAndLoyalty,
    cart.GrandTotal,
    cart.GrandTotalRounded,
  ]);

  const [comment, setComment] = useState('');

  useEffect(() => {
    if (selected) {
      setComment(selected?.Name);
    }
  }, [selected]);
  const [availableFeesDonations, setAvailableFeesDonations] = useState<Array<AvailableFeeDonation>>([]);
  const loadApplied = useCallback(async () => {
    if (registerId) {
      try {
        const feesDonations = await api.getAppliedFeeDonation(shipmentId);
        setAppliedFeesDonations(feesDonations);
      } catch {
        setAppliedFeesDonations([]);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [registerId, shipmentId]);

  useEffect(() => {
    if (registerId) {
      customerApi
        .getAvailableFeesDonations()
        .then(setAvailableFeesDonations)
        .catch((e) => {
          logger.error(e, { message: 'Unable to retrieve available fees and donations' });
          setAvailableFeesDonations([]);
        });
    }
  }, [registerId]);

  useEffect(() => {
    loadApplied();
  }, [loadApplied]);

  const applyFeeDonation = async () => {
    if (!loading && selected) {
      setLoading(true);
      try {
        await applyFeeDonationAction({
          selected,
          comment,
          applyTo,
          calculationValue,
          cashValue,
          cart,
          selectedLocation,
        });
      } catch (message) {
        logger.error(message, { message });
        dispatch(errorNotification(message));
      } finally {
        loadApplied();
        setLoading(false);
        setComment('');
      }
    }
  };

  const buttonDisabled = useMemo(() => {
    return (!calculationValue && !cashValue) || loading;
  }, [calculationValue, cashValue, loading]);

  return (
    <Popup
      caption='Apply Fee or Donation'
      hide={() => {
        refetchCartDetails();
        hide();
      }}
      isVisible
      large
    >
      <MainContainer>
        <Flex style={{ width: '50%', paddingRight: 24 }}>
          <AvailableFeesDonations
            availableFeesDonations={availableFeesDonations.filter((f) => !f.IsAutomatic)}
            selected={selected}
            onSelect={(item) => {
              setSelected(item);
              setCalculationValue(item.CalculationValue.toString());
            }}
          />
        </Flex>
        <Flex style={{ width: '50%' }}>
          <AppliedSection>
            <SelectedFeeDonation
              selected={selected}
              applyTo={applyTo}
              setApplyTo={setApplyTo}
              calculationValue={calculationValue}
              setCalculationValue={setCalculationValue}
              cashValue={cashValue}
              setCashValue={setCashValue}
              comment={comment}
              setComment={setComment}
            />
            <Flex justifyContent='flex-end'>
              <Button onClick={applyFeeDonation} disabled={buttonDisabled}>
                Apply Fee / Donation
              </Button>
            </Flex>
            <AppliedFeesDonations
              appliedFeesDonation={appliedFeesDonation}
              loading={loading}
              setLoading={(value) => {
                setLoading(value);
              }}
              loadApplied={loadApplied}
            />
          </AppliedSection>
        </Flex>
      </MainContainer>
    </Popup>
  );
};

const MainContainer = styled(Flex)`
  height: 692px;
  padding: 0 24px 24px;
`;
const AppliedSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
