import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setBlockScans } from 'store/actions/CartActions';

type UseScanFailedPopupParams = {
  hide: () => void;
};

export const useScanFailedPopup = ({ hide }: UseScanFailedPopupParams) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBlockScans(true));

    return () => {
      dispatch(setBlockScans(false));
    };
  }, [dispatch]);

  const handleClose = () => {
    dispatch(setBlockScans(false));
    hide();
  };

  return {
    handleClose,
  };
};
