import React from 'react';
import { ConnectionStatusPill } from 'pages/SettingsPage/Status/ConnectionStatusPill';
import { useConnectedReceiptPrinter } from 'util/hooks/hardware/useConnectedHardware';

export const ReceiptPrinterStatusPill = () => {
  const { device } = useConnectedReceiptPrinter();

  return (
    <ConnectionStatusPill
      automationId='receipt_printer_settings-connection_status'
      deviceName={device?.name}
      connected={!!device}
    />
  );
};
