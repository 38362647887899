import { useSelector, useDispatch } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { State } from 'store';
import {
  showDiscountPanel,
  showLoyaltyPanel,
  showPrescriptionPanel,
} from 'store/actions/CartActions';
import { useCartPopups } from 'components/CartPopups';
import { post } from 'api/HttpHelpers';
import { getPDFLink } from 'util/Helpers';
import { useAppSelector } from 'util/hooks';
import { useLoyaltySettings } from '../CartPanels/LoyaltyPoints/useLoyaltySettings';
import { useGetCartDetails } from '../hooks/useGetCartDetails';

import type { OverflowMenuOption } from 'components/menus/OverflowMenu';

export const useCartOverflowOptions = (): OverflowMenuOption[] => {
  const { data: cart } = useGetCartDetails();

  const guest = useSelector((state: State) => state.customer.details);
  const isPreorder = cart.PreOrders.length > 0;
  const features = useSelector((state: State) => state.settings.features);
  const integrations = useSelector((state: State) => state.settings.integrations);
  const { loyaltyPanelEnabled } = useLoyaltySettings();

  const ldClient = useLDClient();
  const moveExternalLoyaltyButtonsToCartLevelMenu = useAppSelector(
    (state) => state.settings.features.LoyaltyAsCartDiscount
  );

  const canUseMCDMS = ldClient?.variation('pos.backoffice.mcdms-integration.rollout', false);
  const isUseMCDMSEnabled = useSelector((state: State) => state.settings.integrations?.UseMCDMS);
  const isUseUtahICSEnabled = useSelector((state: State) => state.settings.integrations?.UseUtahICS);
  const hasPrescriptionData = cart.PrescriptionData !== undefined;
  const showUtahPrescription = isUseUtahICSEnabled && hasPrescriptionData;
  const isViewPrescriptionEnabled = canUseMCDMS && isUseMCDMSEnabled && hasPrescriptionData;

  const dispatch = useDispatch();
  const cartPopups = useCartPopups();

  const handleViewManifest = async () => {
    const data = await post<string>('v2/manifest/get-manifest-bytes', { PosId: cart.ShipmentId });
    const link = getPDFLink(data, `PrintedManifest-${cart.ShipmentId}.pdf`);
    cartPopups.showDigitalManifestPopup({
      manifestLink: link.href,
    });
  };

  const divider = { divider: true };

  // Default Actions

  const discounts = {
    text: 'Discounts',
    onClick: () => dispatch(showDiscountPanel()),
  };

  const applyLoyalty = {
    text: 'Apply loyalty',
    onClick: () => dispatch(showLoyaltyPanel()),
  };

  const redeemSB: OverflowMenuOption = {
    text: 'Redeem SB',
    onClick: () => cartPopups.showSpringBigRedeemPopup(),
  };
  const redeemAIQ: OverflowMenuOption = {
    text: 'Redeem AIQ',
    onClick: () => cartPopups.showAlpineIQRedeemPopup(),
  };
  const redeemFyllo: OverflowMenuOption = {
    text: 'Redeem Fyllo',
    onClick: () => cartPopups.showFylloRedeemPopup(),
  };

  const feesAndDonations = {
    text: 'Fees and donations',
    onClick: () => cartPopups.showFeeDonationPopup(),
  };

  const journal = {
    text: 'Journal',
    onClick: () => cartPopups.showJournalPopup(cart.CustomerId),
  };

  const transactionNotes = {
    text: 'Transaction notes',
    onClick: () => cartPopups.showTransactionNotesPopup(cart.CustomerId, cart.ShipmentId),
  };

  // Destructive Actions

  const clearCart = {
    text: 'Clear cart',
    onClick: () => cartPopups.showClearCartPopup(),
  };

  const cancelOrder = {
    danger: true,
    text: 'Cancel order',
    onClick: () => {
      if (guest) {
        cartPopups.showCancelTransactionPopup({
          DeliveryStatus: guest.DeliveryStatus ?? '',
          FullName: guest.FullName,
          Guest_id: cart.CustomerId,
          ScheduleId: Number(cart.ScheduleId),
          ShipmentId: cart.ShipmentId,
        });
      }
    },
  };

  // Feature Flag Controlled Actions

  const backDate = {
    text: 'Back date',
    onClick: () => cartPopups.showBackdateTransactionPopup(cart.ShipmentId),
  };

  const pharmacistVerification = {
    text: 'Pharmacist verification',
    onClick: () => cartPopups.showVerifyTransactionPopup(cart.CustomerId, cart.ShipmentId),
  };

  const remotePay = {
    text: 'Remote pay',
    onClick: () => cartPopups.showRemotePayPopup(cart.ShipmentId),
  };

  const viewManifest = {
    text: 'View delivery manifest',
    onClick: handleViewManifest,
  };

  const viewPrescription = {
    text: 'View prescription',
    onClick: () => dispatch(showPrescriptionPanel()),
  };

  const visitReason = {
    text: 'Visit reason',
    onClick: () => cartPopups.showVisitReasonPopup(cart.ShipmentId),
  };

  const actions: OverflowMenuOption[] = [discounts];

  if (loyaltyPanelEnabled) {
    actions.push(applyLoyalty);
  }

  if (moveExternalLoyaltyButtonsToCartLevelMenu) {
    if (integrations?.UseSpringBig) {
      actions.push(redeemSB);
    }

    if (integrations?.UseAlpineIQ) {
      actions.push(redeemAIQ);
    }

    if (integrations?.UseFyllo) {
      actions.push(redeemFyllo);
    }
  }

  actions.push(feesAndDonations, journal, transactionNotes, divider);

  // New section for FF actions

  if (features.AllowBackdateTransaction) {
    actions.push(backDate);
  }

  if (features.PharmacistVerification) {
    actions.push(pharmacistVerification);
  }

  if (features.RemotePay) {
    actions.push(remotePay);
  }

  if (features.EnableDelivery && cart.Deliverable) {
    actions.push(viewManifest);
  }

  if (isViewPrescriptionEnabled || showUtahPrescription) {
    actions.push(viewPrescription);
  }

  if (features.CustomerVisitReason && !isPreorder && cart.ShipmentId) {
    actions.push(visitReason);
  }

  // Destructive actions
  actions.push(divider);

  if (!cart.Locked) {
    actions.push(clearCart);
  }

  actions.push(cancelOrder);

  return actions;
};
