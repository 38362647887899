import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'css/Theme';
import { useTableStore } from '../util/useTableStore';
import { ReactComponent as DownArrow } from 'assets/icons/sort-down.svg';

export const SortControls: React.FC<{ columnIndex: number }> = ({ columnIndex, children }) => {
  const { dispatch, sortColumn, sortDirection } = useTableStore();

  const isSorted = sortColumn === columnIndex;
  const isSortAsc = isSorted && sortDirection === 'asc';
  const isSortDesc = isSorted && sortDirection === 'desc';

  return (
    <Container
      isSorted={isSorted}
      data-testid='sort-control_container_arrow'
      onClick={() => dispatch({ type: 'sortByColumn', columnIndex })}
    >
      {children}

      <ArrowContainer>
        {isSortDesc && <UpArrow />}
        {isSortAsc && <DownArrow />}
      </ArrowContainer>
    </Container>
  );
};

const Container = styled.button<{ isSorted?: boolean }>`
  background-color: transparent;
  border: 0 none transparent;
  cursor: pointer;
  display: flex;
  padding: 0;

  ${({ isSorted }) =>
    isSorted &&
    css`
      color: ${colors.dutchie.grey30} !important;
      font-weight: 700 !important;
    `}

  .non-hover {
    display: block;
  }
  .hover {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 16px;
  margin-left: 4px;
  vertical-align: middle;
  width: 16px;
`;

const UpArrow = styled(DownArrow)`
  transform: rotate(180deg);
`;
