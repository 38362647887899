import { useDispatch } from 'react-redux';
import { CartItem } from 'models/Cart';

import { removeAllAppliedDiscounts } from 'store/actions/CartItemsActions';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

/**
 * Remove discount by discountId
 * @param discountId Make sure to pass DiscountId and not AppliedDiscountId
 * @param cartItem (optional) Remove all discounts in a line item
 */
export const useRemoveDiscounts = (cartItem?: CartItem) => {
  const dispatch = useDispatch();

  const { shipmentId } = useTransactionManager();

  const removeDiscountsById = async (discountId: number, cartItem?: CartItem) => {
    return dispatch(
      removeAllAppliedDiscounts({
        DiscountId: discountId,
        ShipmentId: shipmentId,
        ProductId: cartItem?.ProductId,
        SerialNo: cartItem?.SerialNo,
      })
    );
  };

  return async (discountId: string | number) => {
    const id = typeof discountId === 'number' ? discountId : parseInt(discountId);
    return await removeDiscountsById(id, cartItem);
  };
};
