import React, { PropsWithChildren, createContext } from 'react';
import { ZReportReceiptPrinterSettingsContextData } from '../types';
import { useZReportReceiptPrinterSettingsProvider } from '../hooks/useZReportReceiptPrinterSettingsProvider';

export const ZReportReceiptPrinterSettingsContext = createContext<ZReportReceiptPrinterSettingsContextData>(
  {} as ZReportReceiptPrinterSettingsContextData
);

export const ZReportReceiptPrinterSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useZReportReceiptPrinterSettingsProvider();
  return (
    <ZReportReceiptPrinterSettingsContext.Provider value={data}>
      {children}
    </ZReportReceiptPrinterSettingsContext.Provider>
  );
};
