import React, { FC, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import zxcvbn from 'zxcvbn';
import { Input } from 'components/inputs';
import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { getPasswordSettings, resetPassword } from 'store/actions/UserActions';
import { ReactComponent as PasswordIcon } from 'assets/icons/icon-password.svg';
import { UnauthorizedPageLayout, Box } from 'components/layout';
import { removeNotification } from 'store/actions/NotificationsActions';
import { ResetPageV2 } from './ResetPageV2';

const StyledPasswordIcon = styled(PasswordIcon)`
  color: ${colors.dutchie.grey};
`;

type PasswordScoreProps = {
  score: number;
};

type NewLoginStyleProps = {
  isNewLoginEnabled: boolean;
};

const scoreColor = (score: number) => {
  switch (score) {
    case 0:
      return colors.dutchie.red;
    case 1:
      return colors.dutchie.red;
    case 2:
      return colors.dutchie.terra;
    case 3:
      return colors.dutchie.blue62;
    case 4:
      return colors.dutchie.green60;
    default:
      return colors.dutchie.green60;
  }
};

const PasswordScore = styled.p<PasswordScoreProps>`
  color: ${({ score }) => scoreColor(score)};
  display: inline;
`;

const StyledResetPage = styled.span<NewLoginStyleProps>`
  .password-strength-meter {
    text-align: left;
    width: ${(props) => (props.isNewLoginEnabled ? '100%' : '300px')};
    height: 110px;
    border: 2px solid ${colors.black};
    padding: 7px;
    border-radius: 5px;
  }

  .password-strength-meter-progress {
    appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 10px;
  }

  .password-strength-meter-progress::-webkit-progress-bar {
    background-color: ${(props) => (props.isNewLoginEnabled ? 'transparent' : '#eee')};
    border-radius: 3px;
    border: ${(props) => (props.isNewLoginEnabled ? `1px solid ${colors.dutchie.gray70}` : 'none')};
  }

  .password-strength-meter-label {
    font-size: 0.875rem;
    color: ${(props) => (props.isNewLoginEnabled ? '#eee' : colors.dutchie.almostBlack)};
  }

  .password-strength-validation {
    font-size: 0.875rem;
    color: ${colors.dutchie.grey};
  }

  .password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .strength-Weak::-webkit-progress-value {
    background-color: ${colors.dutchie.red};
  }
  .strength-Fair::-webkit-progress-value {
    background-color: ${colors.dutchie.terra};
  }
  .strength-Good::-webkit-progress-value {
    background-color: ${colors.dutchie.blue62};
  }
  .strength-Strong::-webkit-progress-value {
    background-color: ${colors.dutchie.green60};
  }
`;

const ResetPageBase = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: 22vw;

  @media (max-width: 1264px) {
    width: 30vw;
  }

  .reset-page-field {
    margin-bottom: 20px;
  }
`;

const InputContainer = styled.div<NewLoginStyleProps>`
  margin-bottom: ${(props) => (props.isNewLoginEnabled ? '24px' : '20px')};
`;

const FullWidthForm = styled.form`
  width: 100%;
`;

export const ResetPage: FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: State) => state.notifications);
  const flags = useFlags();
  const isNewLoginEnabled = flags['pos.iam.register-ux-updates-2023-11.rollout'] ?? false;
  const isFixErrMessageEnabled = flags['pos.ent.fix-pw-reset-err-message-2024-08.rollout'] ?? false;
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token: string = params.get('token')?.toString() || '';
  const checker = zxcvbn(password1);
  const passwordLength = useSelector((state: State) => state.user.passwordSettings.passwordLength);

  useEffect(() => {
    dispatch(getPasswordSettings(token));
  }, [dispatch, token]);

  const passwordsMatch = () => {
    if (password1 !== password2) {
      return false;
    }
    return true;
  };

  const createPasswordLabel = (score: number) => {
    switch (score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  };

  const createPasswordSuggestion = (score: number) => {
    switch (score) {
      case 0:
        return checker.feedback.warning;
      case 1:
        return checker.feedback.warning;
      case 2:
        return checker.feedback.warning;
      case 3:
        return '';
      case 4:
        return '';
      default:
        return 'Weak';
    }
  };

  const isNewLoginAndHasNotifications = isFixErrMessageEnabled && isNewLoginEnabled && notifications?.length > 0;

  const CommonCode = (
    <div>
      <FullWidthForm
        onSubmit={(e) => {
          e.preventDefault();
          isNewLoginAndHasNotifications && notifications.forEach((n) => dispatch(removeNotification(n.id)));
          dispatch(resetPassword({ password2, token }));
        }}
      >
        <InputContainer isNewLoginEnabled={isNewLoginEnabled}>
          <Input
            id='password1'
            withDarkBackground={isNewLoginEnabled}
            startAdornment={<StyledPasswordIcon />}
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            type='password'
            placeholder='Password'
          />
        </InputContainer>
        <InputContainer isNewLoginEnabled={isNewLoginEnabled}>
          <Input
            id='password2'
            withDarkBackground={isNewLoginEnabled}
            startAdornment={<StyledPasswordIcon />}
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            type='password'
            placeholder='Confirm Password'
          />
        </InputContainer>
        {isNewLoginAndHasNotifications && notifications.map((notif) => (
          <StyledInlineError key={notif.id}>{notif.message}</StyledInlineError>
        ))}
        <Button
          fullWidth={isNewLoginEnabled}
          type='submit'
          marginBottom={isNewLoginEnabled ? 32 : 12}
          disabled={!password1 || !password2 || !passwordsMatch() || checker.feedback.warning.length > 0}
        >
          Reset
        </Button>
      </FullWidthForm>
      <div>
        <progress
          className={`password-strength-meter-progress strength-${createPasswordLabel(checker.score)}`}
          value={checker.score}
          max='4'
        />
        <label className='password-strength-meter-label'>
          {password1 && (
            <Box marginTop={isNewLoginEnabled ? '16px' : '6px'} marginBottom={isNewLoginEnabled ? '16px' : '12px'}>
              Password Strength:{' '}
              <PasswordScore data-testid='password-strength-score' score={checker.score}>
                {createPasswordLabel(checker.score)}
              </PasswordScore>
            </Box>
          )}
        </label>
        <ul className='password-strength-validation'>
          <li>{createPasswordSuggestion(checker.score)}</li>
          {!passwordsMatch() && <li>Passwords don't match</li>}
          {password1.length < passwordLength && (
            <li>Password does not meet {passwordLength} character length requirement</li>
          )}
        </ul>
      </div>
    </div>
  );

  return (
    <StyledResetPage isNewLoginEnabled={isNewLoginEnabled}>
      {isNewLoginEnabled ? (
        <ResetPageV2>{CommonCode}</ResetPageV2>
      ) : (
        <UnauthorizedPageLayout>
          <ResetPageBase>{CommonCode}</ResetPageBase>
        </UnauthorizedPageLayout>
      )}
    </StyledResetPage>
  );
};

const StyledInlineError = styled.div`
color: ${colors.dutchie.red};
font-size: 18px;
font-weight: 600;
line-height: 22px;
margin-bottom: 32px;
`;
