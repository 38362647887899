import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { isAndroid } from 'util/hooks';

import { clearCFDCart, updateCFDData } from 'store/actions/CFDActions';
import { getCFDCustomImage } from 'api/helpers/getCFDCustomImage';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

export const CFD_PATH = 'customer-facing-display';

const useWebCfdPlugin = (path: string) => {
  const dispatch = useDispatch();
  const isNotCfdDevice = path !== `/${CFD_PATH}`;

  const locationName = useSelector((state: State) => state.user.selectedLocation?.location_name);
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const features = useSelector((state: State) => state.settings.features);
  const cart = useSelector((state: State) => state.cart);
  const checkoutComplete = useSelector((state: State) => state.checkout.success);
  const changeDue = useSelector((state: State) => state.checkout.changeDue);
  const [customIdleImage, setCustomIdleImage] = useState<string>();

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { data: cartDetails } = useGetCartDetails();

  const updateCFD = useCallback(() => {
    if (!isAndroid && isNotCfdDevice) {
      dispatch(
        updateCFDData({
          locationName,
          loggedIn: !!register,
          features,
          customIdleImage,
        })
      );
      if (path !== '/cart') {
        dispatch(clearCFDCart());
      } else if (isAnonymousCartLDFlagEnabled && cartDetails.ShipmentId !== 0) {
        dispatch(
          updateCFDData({
            cart: cartDetails,
            checkoutComplete,
            changeDue,
          })
        );
      } else if (!isAnonymousCartLDFlagEnabled && cart?.details) {
        dispatch(
          updateCFDData({
            cart: cart.details,
            checkoutComplete,
            changeDue,
          })
        );
      }
    }
  }, [
    isNotCfdDevice,
    dispatch,
    locationName,
    register,
    features,
    customIdleImage,
    path,
    cart,
    cartDetails,
    isAnonymousCartLDFlagEnabled,
    checkoutComplete,
    changeDue,
  ]);

  useEffect(() => {
    if (!isAndroid && isNotCfdDevice) {
      getCFDCustomImage().then(setCustomIdleImage);
    }
  }, [isNotCfdDevice, locationName]);

  useEffect(() => updateCFD(), [updateCFD]);
};

export const WebCfdListener = ({ path }: { path: string }) => {
  useWebCfdPlugin(path);
  return null;
};
