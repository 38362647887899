import React, { FC } from 'react';
import { MenuItem } from 'components/backoffice/menu-item';

import { ButtonWithDropDown, ButtonWithDropDownProps } from './button-with-dropdown';

export type DropDownOption = {
  label: string;
  value?: boolean | string;
  onClick?: () => void;
  closeOnClick?: boolean;
};

/**
 * A default method used to render a generic dropdown item
 */
const renderDefault = (item: DropDownOption, closeDropdown: () => void) => {
  const handleClick = () => {
    if (item.onClick) {
      item.onClick();
    }

    if (item.closeOnClick) {
      closeDropdown();
    }
  };

  return (
    <MenuItem value={item.value ?? true} onClick={handleClick}>
      {item.label}
    </MenuItem>
  );
};

/**
 * Extends a button with dropdown to use a generic item with a simple label
 */
export const ButtonWithOptions: FC<Omit<ButtonWithDropDownProps<DropDownOption>, 'customItemRender'>> = (props) => {
  return <ButtonWithDropDown<DropDownOption> customItemRender={renderDefault} {...props} />;
};
