import { useSelector } from 'react-redux';
import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';
import { PrintJob, PrintStatus } from 'models/Printing';
import { post } from 'api/HttpHelpers';
import {
  getStoredHardware,
  peripheralInfo,
  GetPrintJobResponse,
  processHardwarePrintJob,
  selectHardwarePrinterType,
} from 'util/hardwareLibrary/hardware-library-utils';
import { useAppDispatch } from 'util/hooks';
import type { State } from 'store';
import type { PrintJobContext, PrintJobResultContext } from 'util/logger/types/printing';
import { getIsPrintResultLoggingEnabled } from 'util/hooks/launch-darkly/getIsPrintResultLoggingEnabled';

export type PrintZReportParams = {
  registerId: number;
};

export const usePrintZReport = () => {
  const dispatch = useAppDispatch();
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  return async () => {
    if (!registerId) {
      dispatch(errorNotification('Error printing Z Report: Register not selected'));
      return;
    }

    const { receiptPrinter: mainReceiptPrinter, zReportPrinter } = getStoredHardware();
    const receiptPrinter = zReportPrinter || mainReceiptPrinter;

    try {
      if (!receiptPrinter) {
        throw new Error('Receipt printer not detected and unable to print Z Report.');
      }

      dispatch(successNotification('Printing Z Report...'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.Z_REPORT, status: PrintStatus.PRINTING }));

      const printRequest = {
        PrinterId: 0, // not used by endpoint
        PrinterType: selectHardwarePrinterType(receiptPrinter),
        RegisterId: registerId,
        RequestId: registerId,
      };

      logger.info<PrintJobContext>('print z-report started', {
        key: customEventKeys.printing.jobStarted,
        job: PrintJob.Z_REPORT,
        requestParams: printRequest,
        printer: peripheralInfo(receiptPrinter),
      });

      const job = await post<GetPrintJobResponse>('register/print/get-z-report-job', printRequest);

      if (getIsPrintResultLoggingEnabled()) {
        let success = false;
        try {
          success = await processHardwarePrintJob(job, receiptPrinter);
        } finally {
          logger.info<PrintJobResultContext>('print z-report result', {
            key: customEventKeys.printing.jobEnded,
            job: PrintJob.Z_REPORT,
            requestParams: printRequest,
            printer: peripheralInfo(receiptPrinter),
            success,
          });
        }
      } else {
        await processHardwarePrintJob(job, receiptPrinter);
      }

      dispatch(successNotification('Z Report printed'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.Z_REPORT, status: PrintStatus.SUCCESSFUL }));
    } catch (e) {
      const message = parseErrorMessage(e);
      dispatch(errorNotification(`Error printing Z Report: ${message}`));
      dispatch(setPrintJobStatus({ printJob: PrintJob.Z_REPORT, status: PrintStatus.FAILED }));
      logger.error(e, { message: 'Failed to print Z report', printer: peripheralInfo(receiptPrinter) });
    }
  };
};
