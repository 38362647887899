import styled, { css } from 'styled-components';

import { colors } from 'css/Theme';

import type { ButtonProps } from './types';

export const Button = styled.button.attrs((props: ButtonProps) => ({
  'data-testid': props.automationId,
  'data-dd-action-name': props.ddActionName,
}))<ButtonProps>`
  background-color: ${colors.dutchie.blue};
  border: solid 1px ${colors.dutchie.blue};
  color: ${colors.dutchie.primaryWhite};
  cursor: pointer;
  border-radius: 6px;
  padding: ${({ padding }) => padding || '0 20px'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  height: ${({ height }) => height || '44px'};

  ${({ width }) => width && `width: ${width};`}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  &:disabled {
    pointer-events: none;
    color: ${colors.dutchie.primaryWhite};
    background: ${colors.dutchie.gray50};
    border-color: ${colors.dutchie.gray50};
  }

  &:hover {
    background-color: ${colors.dutchie.blue70};
  }

  ${({ secondary }) =>
    secondary &&
    css`
      color: ${colors.dutchie.darkGrey};
      background-color: ${colors.white};
      border: solid 1px ${colors.dutchie.borderGrey};
      &:hover {
        color: ${colors.dutchie.gray100};
        border-color: ${colors.dutchie.grey70};
        background-color: ${colors.dutchie.grey20};
      }
      &:disabled {
        color: ${colors.dutchie.gray60};
        background: ${colors.dutchie.primaryWhite};
        border-color: ${colors.dutchie.gray60};
      }
    `}

  ${({ tertiary }) =>
    tertiary &&
    css`
      color: ${colors.dutchie.gunmetal};
      background-color: ${colors.dutchie.backgroundGrey};
      border: solid 1px ${colors.dutchie.backgroundGrey};
      &:disabled {
        color: ${colors.dutchie.gray70};
        background: ${colors.dutchie.gray20};
        border-color: ${colors.dutchie.gray20};
      }
      &:hover {
        color: ${colors.dutchie.black};
        background-color: ${colors.dutchie.gray40};
        border-color: ${colors.dutchie.gray40};
      }
    `}

  ${({ transparentBlue }) =>
    transparentBlue &&
    css`
      color: ${colors.dutchie.blue};
      background-color: transparent;
      border: solid 1px ${colors.dutchie.blue};
      &:disabled {
        color: ${colors.dutchie.gray60};
        background-color: transparent;
        border-color: ${colors.dutchie.gray60};
      }
      &:hover {
        color: ${colors.dutchie.blue70};
        background-color: transparent;
        border-color: ${colors.dutchie.blue70};
      }
    `}

  ${({ transparentRed }) =>
    transparentRed &&
    css`
      color: ${colors.dutchie.red};
      background-color: transparent;
      border: solid 1px ${colors.dutchie.red};
      &:hover {
        color: ${colors.dutchie.red70};
        background-color: transparent;
        border-color: ${colors.dutchie.red70};
      }
    `}

    ${({ dutchiePayThemed }) =>
    dutchiePayThemed &&
    css`
      background-color: ${colors.dutchie.green};
      border: solid 1px ${colors.dutchie.green};

      &:hover {
        background-color: ${colors.dutchie.green70};
        border-color: ${colors.dutchie.green70};
      }
    `}

    ${({ cta, height }) =>
    cta &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: ${height ?? '3.5rem'};
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 1rem 1.5rem;
      letter-spacing: 0.5%;
    `}

    ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
    ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
    ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}
    ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}

    ${({ isAdornment }) =>
    isAdornment &&
    css`
      &&& {
        font-weight: 500;
        font-size: inherit;
        line-height: inherit;
        height: unset;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        color: ${colors.dutchie.blue};

        &:disabled {
          color: ${colors.dutchie.gray60};
        }
      }
    `}
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
`;
