import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { releaseGuest } from 'store/actions/GuestListActions';
import { ConfirmationPopup } from 'components/popups';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

const StyledConfirmReleasePopupBody = styled.div`
  display: flex;
  justify-content: center;
`;

type ConfirmReleasePopupProps = {
  hide: () => void;
};

export const ConfirmReleasePopup: FC<ConfirmReleasePopupProps> = ({ hide }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { guestId, shipmentId } = useTransactionManager();
  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  const {
    data: { ScheduleId },
  } = useGetCartDetails();

  const releaseGuestOnClick = async () => {
    if (guestId && shipmentId && registerId) {
      setLoading(true);
      await dispatch(
        releaseGuest({
          guestId,
          shipmentId,
          registerId: registerId,
          scheduleId: Number(ScheduleId),
        })
      );
      history.push('/guestlist');
      setLoading(false);
    }
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Release Guest'
      confirm={{
        text: 'Confirm',
        disabled: loading,
        onClick: () => {
          releaseGuestOnClick();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <StyledConfirmReleasePopupBody>Are you sure you want to release the guest?</StyledConfirmReleasePopupBody>
    </ConfirmationPopup>
  );
};
