import { logger } from 'util/logger';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { post } from 'api/HttpHelpers';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { PrintParams } from './types';

type PrintToNetworkPrinterArgs = {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  params: PrintParams;
};

export async function printToNetworkPrinter({ dispatch, params }: PrintToNetworkPrinterArgs): Promise<void> {
  await post('v2/print-jobs/print-label', params)
    .then(() => {
      dispatch(successNotification('Labels Printed'));
    })
    .catch((e: string) => {
      dispatch(errorNotification(`Error Printing Labels: ${e}`));
      logger.error(e, { method: 'printLabel' });
      return Promise.reject(e);
    });
}
