import React from 'react';

import { Tooltip, TooltipIconContainer, TooltipIcon } from '../tooltip';
import { RebrandLabel } from './label-rebrand.styles';

export function Label(props) {
  const { children, required = false, tooltip } = props;

  return (
    <RebrandLabel {...props}>
      {children} {required && <span className='required-asterisk'>*</span>}
      {tooltip && (
        <Tooltip placement='top' title={tooltip}>
          <TooltipIconContainer>
            <TooltipIcon />
          </TooltipIconContainer>
        </Tooltip>
      )}
    </RebrandLabel>
  );
}
