import React, { FC, useMemo, useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { chain } from 'lodash';
import { State } from 'store';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import {
  addPaymentMethod,
  digitalPayment,
  checkPinForDigitalPayment,
  cancelJoryTransaction,
  setManagerPin,
} from 'store/actions/CheckoutActions';
import { errorNotification, warningNotification, successNotification } from 'store/actions/NotificationsActions';
import { FeePaymentMethod, PaymentType } from 'models/Checkout';
import { Input } from 'components/inputs';
import { LoadingButton as Button } from 'components/buttons';
import { Flex, Box } from 'components/layout';
import { PaymentOption } from '../PaymentOption';
import { CheckoutContext } from '../CheckoutContext';
import { PaymentShortcuts } from './PaymentShortcuts';
import { cancelPaymentsHubTransaction, cancelPaynetworxTransaction } from 'api/CheckoutApi';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

export const PaymentDebit: FC = () => {
  const dispatch = useDispatch();
  const [authCode, setAuthCode] = useState('');

  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const managerPin = useSelector((state: State) => state.checkout.managerPin);
  const invalidManagerPin = useSelector((state: State) => state.checkout.invalidManagerPin);
  const requireAuthCode = useSelector(
    (state: State) => state.settings.features.RequireAuthCodeOnElectronicTransactions
  );
  const checkOutPinRequired = useSelector((state: State) => state.settings.features.CheckOutPinRequired);
  const useIntegratedDebit = useSelector((state: State) => state.settings.integrations?.UseIntegratedDebit);
  const useJory = useSelector((state: State) => state.settings.integrations?.UseJory);
  const usePaynetworx = useSelector((state: State) => state.settings.integrations?.UsePaynetworx);
  const usePaymentsHub = useSelector((state: State) => state.settings.integrations?.UsePaymentsHub);
  const { paymentSummary, setPaymentProcessing, setSelectedPaymentType } = useContext(CheckoutContext);
  const debit = paymentSummary[PaymentType.Debit];
  const electronic = paymentSummary[PaymentType.Digital];
  const [transactionAmount, setTransactionAmount] = useState<number>();
  const [cancelDisabled, setCancelDisabled] = useState(true);

  const {
    data: { FeesByPaymentType },
  } = useGetCartDetails();

  const potentialFees = FeesByPaymentType?.filter((f) => f.FeePaymentMethod === FeePaymentMethod.PinDebit);
  const potentialFeeStr = chain(potentialFees)
    .map((fee) => fee.CashValue)
    .sum()
    .value()
    ?.toFixed(2);

  const { shipmentId } = useTransactionManager();

  useEffect(() => {
    setCancelDisabled(true);
    let timeout: NodeJS.Timeout | null = null;
    if (digitalPaymentLoading) {
      timeout = setTimeout(() => {
        setCancelDisabled(false);
      }, 15_000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [digitalPaymentLoading]);

  useEffect(() => {
    setPaymentProcessing(digitalPaymentLoading);
    if (transactionAmount && !digitalPaymentLoading && debit >= transactionAmount) {
      setSelectedPaymentType(null);
    }
  }, [digitalPaymentLoading, debit, transactionAmount, setPaymentProcessing, setSelectedPaymentType]);

  const onAddDebit = useMemo(
    () => (amount: number) => {
      if (digitalPaymentLoading) {
        dispatch(errorNotification('The transaction is currently being processed. Please be patient as it completes.'));
        return;
      }

      if (amount <= 0) {
        dispatch(errorNotification('Invalid debit amount.'));
        return;
      }

      setTransactionAmount(amount);

      if (amount > 0 && !useIntegratedDebit) {
        dispatch(addPaymentMethod({ id: uuidv4(), name: 'Debit', type: PaymentType.Debit, amount, authCode }));
        dispatch(successNotification('Debit Payment added'));
      } else if (electronic && electronic > 0) {
        dispatch(warningNotification("You can't process an integrated debit payment within electronic payment."));
      } else {
        const paymentMethod = {
          id: uuidv4(),
          name: 'Debit',
          type: PaymentType.Debit,
          amount,
          finalized: true,
          authCode,
        };
        if (checkOutPinRequired) {
          dispatch(checkPinForDigitalPayment({ code: managerPin, paymentMethod }));
        } else {
          dispatch(digitalPayment(paymentMethod));
        }
      }
    },
    [digitalPaymentLoading, useIntegratedDebit, electronic, dispatch, authCode, checkOutPinRequired, managerPin]
  );

  const onChangeAuthCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthCode(e.target.value);
  };

  const handleJoryCancel = () => {
    if (shipmentId) {
      dispatch(cancelJoryTransaction({ ShipmentId: shipmentId, SupportsPolling: true }));
    }
  };

  const handlePaynetworxCancel = async () => {
    if (shipmentId) {
      try {
        await cancelPaynetworxTransaction({ ShipmentId: shipmentId });
      } catch (e) {
        dispatch(errorNotification(e));
      }
    }
  };

  const handlePaymentsHubCancel = async () => {
    if (shipmentId) {
      try {
        await cancelPaymentsHubTransaction({ ShipmentId: shipmentId });
      } catch (e) {
        dispatch(errorNotification(e));
      }
    }
  };

  const onChangeManagerPin = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setManagerPin(e.target.value));
  };

  const feeDetail = usePaynetworx
    ? {
        feeAmount: potentialFeeStr,
        feePaymentMethod: FeePaymentMethod.PinDebit,
      }
    : undefined;

  return (
    <PaymentOption onAddAmount={onAddDebit} feeDetail={feeDetail}>
      {requireAuthCode && (
        <Box marginBottom={6}>
          <Input placeholder='Auth Code' type='password' value={authCode} onChange={onChangeAuthCode} />
        </Box>
      )}
      {!!checkOutPinRequired && !!useIntegratedDebit && (
        <Box marginBottom={6}>
          <Input
            hasError={invalidManagerPin}
            placeholder='Employee PIN'
            type='password'
            value={managerPin}
            onChange={onChangeManagerPin}
            autoComplete='new-password'
          />
          {invalidManagerPin && <ErrorMessage>Wrong Pin</ErrorMessage>}
        </Box>
      )}
      <Flex direction='row' alignItems='center'>
        <PaymentShortcuts shortcuts={[{ name: 'Exact Amount', width: '120px' }]} onAddAmount={onAddDebit} />
        {useJory && (
          <Button
            marginLeft={6}
            tertiary
            onClick={handleJoryCancel}
            disabled={!digitalPaymentLoading}
            automationId='payment-options_button_cancel'
          >
            Cancel
          </Button>
        )}
        {usePaynetworx && (
          <Button
            marginLeft={6}
            tertiary
            onClick={handlePaynetworxCancel}
            disabled={!digitalPaymentLoading || cancelDisabled}
            automationId='payment-options_button_cancel'
          >
            Cancel
          </Button>
        )}
        {usePaymentsHub && (
          <Button
            loading={digitalPaymentLoading}
            marginLeft={6}
            tertiary
            onClick={handlePaymentsHubCancel}
            disabled={!digitalPaymentLoading || cancelDisabled}
            automationId='payment-options_button_cancel'
          >
            Cancel
          </Button>
        )}
      </Flex>
    </PaymentOption>
  );
};

const ErrorMessage = styled.span`
  color: ${colors.dutchie.red};
  font-size: 14px;
  margin-top: 4px;
`;
