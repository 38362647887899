import React, { FC } from 'react';
import styled from 'styled-components';
import { ImageAsset } from 'components/image';
import { DutchieHeroSvg } from 'assets/Assets';

const LayoutBase = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

const HeroContainer = styled.div`
  align-self: flex-end;
  display: flex;
  height: 100%;
  width: 60vw;
  position: relative;
`;

const HeroImage = styled(ImageAsset)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AsideForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: 22vw;

  @media (max-width: 1264px) {
    width: 30vw;
  }
`;

export const UnauthorizedPageLayout: FC = ({ children }) => (
  <LayoutBase>
    <HeroContainer>
      <HeroImage asset={DutchieHeroSvg} />
    </HeroContainer>
    <AsideForm>{children}</AsideForm>
  </LayoutBase>
);
