import { useState } from 'react';

import { getAllotmentValueToDisplay } from 'util/helpers/allotment';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { refreshMMURAllotment } from 'api/CartApi';
import { errorNotification } from 'store/actions/NotificationsActions';

import type { AllotmentCategory } from 'models/Cart';

export type AllotmentByCategory = {
  orderId?: number;
  orderType?: string;
  categories: AllotmentCategory[];
};

export const useViewAllotmentDetailPopup = () => {
  const dispatch = useAppDispatch();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const isMMUREnabled = useAppSelector((state) => state.settings.integrations?.UseMMUR);

  const { guestId, shipmentId } = useTransactionManager();

  const {
    data: { Allotment: allotment },
    isLoading: isLoadingAllotment,
    refetch: refetchAllotment,
  } = useGetCartDetails();

  // Computed values

  const allotmentGroups = allotment?.Details.reduce((acc, category) => {
    const existingEntryIndex = acc.findIndex((entry) => entry.orderId === category.OrderId);
    if (existingEntryIndex >= 0) {
      acc[existingEntryIndex].categories.push(category);
    } else {
      acc.push({
        orderId: category.OrderId,
        orderType: category.OrderType,
        categories: [category],
      });
    }
    return acc;
  }, [] as AllotmentByCategory[]);

  // If CurrentLimit is defined, we want to use that. If it's not defined, we're likely
  // looking at "realtime" allotment data, in which case we need to use TotalLimit.
  const allotmentLimit = `Limit: ${getAllotmentValueToDisplay(allotment?.CurrentLimit ?? allotment?.TotalLimit ?? 0)}g`;

  const isLoading = isLoadingAllotment || isRefreshing;

  // Handlers

  const handleRefreshMMURAllotment = async () => {
    try {
      setIsRefreshing(true);
      await refreshMMURAllotment({
        CustomerId: guestId ?? 0,
        ShipmentId: shipmentId ?? 0,
      });
    } catch (error) {
      if (typeof error === 'string') {
        dispatch(errorNotification(error));
      }
    } finally {
      setIsRefreshing(false);
    }
    await refetchAllotment();
  };

  return {
    allotmentGroups,
    allotmentLimit,
    handleRefreshMMURAllotment,
    isLoading,
    isMMUREnabled,
  };
};
