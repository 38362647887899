import React, { FC } from 'react';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { useChangeCartQtyFromCartItem } from 'util/hooks';

import type { CartItem } from 'models/Cart';

type ConfirmRemovalPopupProps = {
  hide: () => void;
  item: CartItem;
};

const StyledPopupBody = styled.div`
  padding: 0 24px;
  text-align: center;
`;

export const ConfirmRemovalPopup: FC<ConfirmRemovalPopupProps> = ({ hide, item }) => {
  const { PackageItemId: packageItemId, Product: product } = item ?? {};
  const changeCartQty = useChangeCartQtyFromCartItem();

  const onConfirmItemRemoval = async () => {
    hide();
    changeCartQty(item, 0);
  };

  return (
    <ConfirmationPopup
      small
      isVisible
      hide={hide}
      title='Remove Item'
      confirm={{
        text: 'Remove',
        onClick: onConfirmItemRemoval,
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <StyledPopupBody>
        <p>
          The item you scanned ({product} {packageItemId}) is already in the cart. Do you want to remove it?
        </p>
      </StyledPopupBody>
    </ConfirmationPopup>
  );
};
