import React, { FC } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { ProductHistoryItem } from 'models/Customer';
import { Column, Table } from 'components/tables';

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

type ProductHistoryListProps = {
  data: Array<ProductHistoryItem>;
};

export const ProductHistoryList: FC<ProductHistoryListProps> = ({ data }) => {
  const largeValueFormattedDiv = (value: string) => {
    return (
      <div title={value} style={{ overflowWrap: 'anywhere', paddingRight: '10px' }}>
        {value.length > 135 ? `${value.substring(0, 135)}...` : value}
      </div>
    );
  };

  const columns: Array<Column<ProductHistoryItem>> = [
    { header: 'Date', field: 'PosDate', Cell: ({ value }) => <>{format(new Date(value), 'MM/dd/yyyy')}</> },
    { header: 'Receipt', field: 'ReceiptNo' },
    { header: 'Serial No', field: 'SerialNo', width: '250px', Cell: ({ value }) => largeValueFormattedDiv(value) },
    {
      header: 'Description',
      field: 'ProductDesc',
      width: '250px',
      Cell: ({ value }) => largeValueFormattedDiv(value),
    },
    {
      header: 'Instructions',
      field: 'Instructions',
      width: '250px',
      Cell: ({ value }) => largeValueFormattedDiv(value),
    },
    { header: 'Sold By', field: 'SoldBy', width: '200px' },
    { header: 'Lot No', field: 'LotNo', width: '250px', Cell: ({ value }) => largeValueFormattedDiv(value) },
    { header: 'RX No', field: 'PrescriptionNumber' },
    { header: 'Grams', field: 'Equivalent' },
    { header: 'Product SKU', field: 'ProductSku' },
    { header: 'Returned By', field: 'ReturnBy' },
    { header: 'Reason', field: 'ReturnReason' },
    { header: 'Status', field: 'PosStatus' },
    {
      header: 'Integration Sync Date',
      field: 'BioTrackDt',
      width: '250px',
      Cell: ({ item, value }) => <>{format(new Date(value || item.PosDate), 'MM/dd/yyyy hh:mm:ss a')}</>,
    },
    { header: 'Integration Response', field: 'BioTrackResponse', width: '250px' },
    { header: 'Owner', field: 'OwnerName' },
    { header: 'Location', field: 'OwnerLocation' },
    { header: 'Prescription', field: 'Prescription' },
  ];

  const sortedProducts = data.concat().sort((a, b) => b.PosId - a.PosId);

  return (
    <TableWrapper>
      <Table<ProductHistoryItem> data={sortedProducts || []} pageSize={10} columns={columns} />
    </TableWrapper>
  );
};
