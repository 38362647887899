import _, { sortBy, round } from 'lodash';

import { getIsAnonymousCartLDFlagEnabled } from 'pages/CartPage/hooks/useAnonymousCart';

import { CartItem } from 'models/Cart';
import { PriceDetails, PricingTier } from 'queries/v2/inventory/types';
import { ProductSearchResult } from 'queries/v2/product/types';

type PriceTierRef = {
  priceDetails: PriceDetails[];
  isExactWeightBundle: boolean;
};

export type BulkPricingInput = {
  /** @deprecated Use unitPrice instead */
  product: ProductSearchResult | CartItem;
  inputStr: string;
  priceTier?: PricingTier;
  isMedical: boolean;
  unitPrice?: number;
};

export const getWeightFromPrice = (input: BulkPricingInput) => {
  const priceTier = input?.priceTier;
  const { isAnonymousCartLDFlagEnabled } = getIsAnonymousCartLDFlagEnabled();
  // We should not be casting this to a ProductSearchResult since it could be a CartItem
  const basePrice = isAnonymousCartLDFlagEnabled
    ? input.unitPrice ?? 0
    : (input?.product as ProductSearchResult)?.unitPrice ?? 0;
  const inputPrice = parseFloat(input.inputStr);
  const sortedTiers = sortBy(priceTier?.priceDetails, (tier) => tier?.startWeight)?.reverse();

  if (priceTier) {
    if (priceTier.isExactWeightBundle) {
      for (let i = 0; i < (sortedTiers?.length ?? 0); i++) {
        const tier = sortedTiers[i];
        const price: number = input.isMedical ? round(tier.medicalPrice, 6) : round(tier.price, 6);
        const tempPrice: number = round(inputPrice, 6);
        if (round(price * tier.startWeight, 2) === round(tempPrice, 2)) {
          return tier.startWeight;
        }
      }
    } else {
      for (let i = 0; i < (sortedTiers?.length ?? 0); i++) {
        const tier = sortedTiers[i];
        const price: number = input.isMedical ? round(tier?.medicalPrice, 6) : round(tier?.price, 6);
        const tempPrice: number = round(inputPrice, 6);
        const tierStartPrice: number = round(price * tier.startWeight, 6);
        const maxWeight = round(tier?.maxWeight ?? 9999, 6);
        const outputWeight = round(tempPrice / price, 2);
        if (outputWeight > maxWeight) {
          continue;
        } else if (tempPrice >= tierStartPrice) {
          return outputWeight;
        }
      }
    }
  }
  return round(inputPrice / basePrice, 2);
};

export const getPriceFromWeight = (input: BulkPricingInput) => {
  const priceTier = input?.priceTier;
  const { isAnonymousCartLDFlagEnabled } = getIsAnonymousCartLDFlagEnabled();
  // We should not be casting this to a ProductSearchResult since it could be a CartItem
  const basePrice = isAnonymousCartLDFlagEnabled
    ? input.unitPrice ?? 0
    : (input?.product as ProductSearchResult)?.unitPrice ?? 0;
  const inputWeight = parseFloat(input.inputStr);

  let matchedTier = null;
  if (priceTier?.isExactWeightBundle) {
    matchedTier = matchExactWeightToTier(priceTier?.priceDetails ?? [], inputWeight);
  } else {
    matchedTier = matchWeightToTier(priceTier?.priceDetails ?? [], inputWeight);
  }

  if (!!matchedTier && input?.isMedical) {
    return {
      price: matchedTier.medicalPrice,
      maxWeight: matchedTier.maxWeight,
      weightBreak: matchedTier.startWeight,
    };
  } else if (matchedTier) {
    return {
      price: matchedTier.price,
      maxWeight: matchedTier.maxWeight,
      weightBreak: matchedTier.startWeight,
    };
  } else {
    return {
      price: basePrice,
      maxWeight: 9999,
      weightBreak: input.inputStr,
    };
  }
};

const matchWeightToTier = (tiers: PriceDetails[], inputWeight: number) => {
  const sortedTiers = _.sortBy(tiers, (tier) => tier?.startWeight);
  for (let i = 0; i < (sortedTiers.length ?? 0); i++) {
    const tier = sortedTiers[i];
    const startWeight = round(tier.startWeight, 6);
    const endWeight = round(tier.endWeight, 6);
    const maxWeight = round(tier?.maxWeight ?? 9999, 6);
    if (inputWeight >= startWeight && inputWeight < endWeight && inputWeight <= maxWeight) {
      return tier;
    }
  }
};

const matchExactWeightToTier = (tiers: PriceDetails[], inputWeight: number) => {
  const sortedTiers = _.sortBy(tiers, (tier) => tier?.startWeight);
  for (let i = 0; i < (sortedTiers?.length ?? 0); i++) {
    const tier = sortedTiers[i];
    const weight: number = round(tier.startWeight, 6);
    const input: number = round(inputWeight, 6);
    if (weight === input) {
      return tier;
    }
  }
};
