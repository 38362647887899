import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Preorder } from 'models/Cart';
import { PreorderLineItem } from './PreorderLineItem';
import { removePreorderCartItem } from 'store/actions/CartItemsActions';
import { useTransactionManager } from '../hooks/useTransactionManager';

import type { State } from 'store';

type PreorderItemProps = {
  item: Preorder;
};

export const PreorderItem: FC<PreorderItemProps> = ({ item }) => {
  const dispatch = useDispatch();

  const register = useSelector((state: State) => state.settings.selectedRegister);

  const { guestId, shipmentId } = useTransactionManager();

  const actions = [
    {
      text: 'Remove item',
      danger: true,
      onClick: async () => {
        if (guestId && shipmentId) {
          dispatch(
            removePreorderCartItem({
              ProdId: item.ProductId,
              AcctId: guestId,
              ShipmentId: shipmentId,
              Register: Number(register?.value),
            })
          );
        }
      },
    },
  ];

  return (
    <PreorderLineItem
      data-testid='cart-sidebar-item_side-bar-item_item'
      item={item}
      overflowOptions={actions}
    ></PreorderLineItem>
  );
};
