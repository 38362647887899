import {
  showCreatePreOrderPopup,
  showFulfillmentSelectorPopup,
  showSelectCollectorPopup,
  showVerifyBirthdatePopup,
} from 'store/actions/PopupsActions';

import { getIsAnonymousCartLDFlagEnabled } from 'pages/CartPage/hooks/useAnonymousCart';

import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { PopupsState } from '.';
import type { PreorderMetadataValues } from 'models/Customer';

const { isAnonymousCartLDFlagEnabled: isGlobalPopupsEnabled } = getIsAnonymousCartLDFlagEnabled();

export type GlobalPopupsPayloads = {
  createPreOrderPopup: { onCheckIn: (values: PreorderMetadataValues) => void; anonymousDob?: Date };
  fulfillmentSelectorPopup: { onGoToCart: () => Promise<void>; onCreatePreorder: () => Promise<void> };
  selectCollectorPopup: { customerId: number; shipmentId: number };
  verifyBirthdatePopup: { onSuccess: (dob?: Date) => void };
};

export type GlobalPopupsState = {
  createPreOrderPopup: GlobalPopupsPayloads['createPreOrderPopup'] | null;
  fulfillmentSelectorPopup: GlobalPopupsPayloads['fulfillmentSelectorPopup'] | null;
  selectCollectorPopup: GlobalPopupsPayloads['selectCollectorPopup'] | null;
  verifyBirthdatePopup: GlobalPopupsPayloads['verifyBirthdatePopup'] | null;
};

export const globalPopupsInitialState: GlobalPopupsState = {
  createPreOrderPopup: null,
  fulfillmentSelectorPopup: null,
  selectCollectorPopup: null,
  verifyBirthdatePopup: null,
};

export const buildGlobalPopupsCases = (builder: ActionReducerMapBuilder<PopupsState>, initialState: PopupsState) => {
  if (isGlobalPopupsEnabled) {
    builder.addCase(showCreatePreOrderPopup, (_state, action) => ({
      ...initialState,
      createPreOrderPopup: action.payload,
    }));
    builder.addCase(showFulfillmentSelectorPopup, (_state, action) => ({
      ...initialState,
      fulfillmentSelectorPopup: action.payload,
    }));
    builder.addCase(showSelectCollectorPopup, (_state, action) => ({
      ...initialState,
      selectCollectorPopup: action.payload,
    }));
    builder.addCase(showVerifyBirthdatePopup, (_state, action) => ({
      ...initialState,
      verifyBirthdatePopup: action.payload,
    }));
  }
};
