import { Realtime, Types } from 'ably';
import { authenticate } from 'queries/messaging/auth';
import { MessagingChannelType, MessagingProvider, MessagingProviderName } from 'models/Messaging';
import { getIsAblyRolloutEnabled } from 'util/hooks/launch-darkly/useAblyRollout';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMessagingConfigQuery } from 'queries/messaging/config';
import { useOnChannelMessage } from 'util/hooks/messaging/useMessageLogging';

/*
    * This is using ably 1.2.5
    * ideally we would use the latest (2.4.0 as of this commit), but we need to upgrade the POS-2020 node version to >=16
    * this will have to wait for the monorepo to release s.t. curbside and POS are decoupled and the dependency on react-scripts/node-sass can be removed
    * slack thread: https://getdutchie.slack.com/archives/C02TQ6D11CG/p1727964609747649
    * official migration docs when the time comes https://github.com/ably/ably-js/blob/main/docs/migration-guides/v2/lib.md
    * FINTECH-1688
 */

export function useAblyAuth(channelType: MessagingChannelType) {
    const auth = useCallback(() => {
        return new Realtime({
            authCallback: async (tokenParams, callback) => {
                let tokenRequest;
                try {
                    tokenRequest = await authenticate<string>({ Provider: MessagingProvider.Ably, ChannelType: channelType });
                } catch (err: any) {
                    callback(err, tokenRequest ? JSON.parse(tokenRequest) : '');
                    return;
                }
                callback('', JSON.parse(tokenRequest));
            },
        });
    }, [channelType]);

    return auth;
}

export const useAblyChannel = (channelType: MessagingChannelType, channelMessageCallback: (eventName: string, data: string | object) => void) => {
    const [client, setClient] = useState<Realtime>();
    const [channel, setChannel] = useState<Types.RealtimeChannelCallbacks>();
    const rollout = getIsAblyRolloutEnabled();
    const onChannelMessage = useOnChannelMessage(channelMessageCallback, MessagingProviderName.Ably);
    const auth = useAblyAuth(channelType);
    const { data: config } = useMessagingConfigQuery({
        Provider: MessagingProvider.Ably,
        ChannelType: channelType,
    });

    // Upon cleanup (FINTECH-1688), this useEffect should be removed, and we should instead auth/create client on mount
    useEffect(() => {
        if (rollout) {
            const client = auth();
            setClient(client);
        }
    }, [rollout, auth]);

    useEffect(() => {
        client?.connection.on('connected', () => {
            // this should be safe as we would not be able to set a client without a valid config
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const channel = client.channels.get(config!.ChannelName);
            channel?.subscribe((message) => onChannelMessage(message.name, message.data));
            setChannel(channel);
        });
    }, [client, config, onChannelMessage, channel]);

    useEffect(() => {
        // only run this on hook/FC unmount
        return () => {
            channel?.unsubscribe();
            client?.close();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export type AblyChannelProps = {
    channelType: MessagingChannelType;
    channelMessageCallback: (eventName: string, data: string | object) => void;
};

export const AblyChannel: FC<AblyChannelProps> = ({ channelType, channelMessageCallback }) => {
    useAblyChannel(channelType, channelMessageCallback);
    return <></>;
};
