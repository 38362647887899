import { createAction } from '@reduxjs/toolkit';

import { CashEntryMode } from 'store/reducers/CashManagementReducer';

import type { CashDenomination } from 'store/reducers/CashManagementReducer';

export const setCashEntryMode = createAction<CashEntryMode>('setCashEntryMode');

export const setCashAmount = createAction<{ denomination: CashDenomination; amount: string }>('setCashAmount');
export const resetCashDetails = createAction('resetCashDetails');

export const setEntryErrorStatus =
  createAction<{ denomination: CashDenomination; hasError: boolean }>('setEntryErrorStatus');

export const openCashDetailsPanel = createAction('openCashDetailsPanel');
export const closeCashDetailsPanel = createAction('closeCashDetailsPanel');
