import { useMemo } from 'react';

const MAX_ITEMS = 6;

export function usePageNums(pagesCount: number, currentPage: number) {
  return useMemo(() => {
    // null values are later displayed as '...'
    const pageNums: (number | null)[] = [];

    if (pagesCount <= MAX_ITEMS) {
      for (let i = 1; i <= pagesCount; i++) {
        pageNums.push(i);
      }

      return pageNums;
    }

    pageNums.push(1);

    if (currentPage > 3) {
      pageNums.push(null);
    }

    if (currentPage === pagesCount) {
      pageNums.push(currentPage - 2);
    }

    if (currentPage > 2) {
      pageNums.push(currentPage - 1);
    }

    if (currentPage !== 1 && currentPage !== pagesCount) {
      pageNums.push(currentPage);
    }

    if (currentPage < pagesCount - 1) {
      pageNums.push(currentPage + 1);
    }

    if (currentPage === 1) {
      pageNums.push(currentPage + 2);
    }

    if (currentPage < pagesCount - 2) {
      pageNums.push(null);
    }

    pageNums.push(pagesCount);

    return pageNums;
  }, [currentPage, pagesCount]);
}
