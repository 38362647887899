import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { SelectContainer } from '../../SettingPageComponents';
import { Label } from 'components/text';
import { Select } from 'components/inputs';
import { logger, customEventKeys } from 'util/logger';
import { UserSelectedPrinterContext } from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';

export const NetworkReceiptPicker: FC = () => {
  const dispatch = useDispatch();
  const { receiptPrinters } = useSelector((state: State) => state.settings);
  const { selectedReceiptPrinter } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintReceipts } = useSelector((state: State) => state.settings.userSettings);

  const changePrinter = (value: string) => {
    const selectedReceiptPrinter = receiptPrinters.find((item) => item.PrinterId === parseInt(value, 10));
    if (selectedReceiptPrinter) {
      dispatch(saveUserSettings({ selectedReceiptPrinter }));

      logger.info<UserSelectedPrinterContext>(`user selected network receipt printer ${value}`, {
        key: customEventKeys.printing.userSelectedPrinter,
        job: PrintJob.RECEIPT,
        type: 'network',
        selectedPrinterId: value,
      });
    }
  };

  const options = receiptPrinters.map((printer) => ({
    value: printer.PrinterId,
    label: printer.Name,
    key: printer.PrinterId,
  }));

  return (
    <SelectContainer>
      <Label>Network Receipt Printer</Label>
      <Select
        placeholder='Select Printer'
        defaultValue={selectedReceiptPrinter?.PrinterId}
        onChange={changePrinter}
        options={options}
        errorMessage={!selectedReceiptPrinter && autoPrintReceipts && 'Please select a default receipt printer'}
      />
    </SelectContainer>
  );
};
