import { Button } from 'components/buttons';
import { ButtonProps } from 'components/buttons/types';
import { colors } from 'css/Theme';
import { NonIntegratedPaymentsSetting } from 'queries/v2/settings/get-manual-payment-processors';
import React from 'react';
import styled, { css } from 'styled-components';

interface ManualProcessorButtonProps {
  payment: NonIntegratedPaymentsSetting;
  setSelectedManualPayment: (payment: NonIntegratedPaymentsSetting) => void;
  selected: boolean;
}

const ManualProcessorButton: React.FC<ManualProcessorButtonProps> = ({
  selected,
  payment,
  setSelectedManualPayment,
}) => {
  const automationId = selected
    ? 'payment-options-manual-multiple-provider_button-selected'
    : 'payment-options-manual-multiple-provider_button';
  return (
    <FlexButton
      automationId={automationId}
      selected={selected}
      fullWidth
      height={'56px'}
      tertiary
      onClick={() => setSelectedManualPayment(payment)}
    >
      {payment.ManualPaymentProcessorName}
    </FlexButton>
  );
};

export default ManualProcessorButton;

type FlexButtonProps = ButtonProps & {
  selected?: boolean;
};
const FlexButton = styled(Button)<FlexButtonProps>`
  display: inline-block;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${colors.dutchie.gray100};
      color: ${colors.white};
      &:hover {
        background-color: ${colors.dutchie.gray100};
        color: ${colors.white};
        border: solid 1px ${colors.dutchie.backgroundGrey};
      }
    `}
`;
