import React, { ComponentProps, FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ModalContainer, ModalContent, ModalCloseButton, ModalHeader, ModalVariation } from 'components/modals/Modal';
import { Popup } from './Popup';
import { PopupOverlay } from './PopupOverlay';
import { colors } from 'css/Theme';

type PopupProps = ComponentProps<typeof Popup>;

export const NewModalBridge: FC<PopupProps> = ({
  children,
  hide,
  large,
  medium,
  width,
  automationId,
  caption,
  className,
  isVisible,
  contentMaxHeight,
  canClose = true,
}) => {
  const modalVariation = useMemo(() => {
    if (medium) {
      return ModalVariation.Medium;
    } else if (large) {
      return ModalVariation.Large;
    } else {
      return ModalVariation.Standard;
    }
  }, [medium, large]);

  if (isVisible === false) {
    return null;
  }

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainerWithWidthProp
        className={className}
        data-testid={automationId ?? 'popup_popup-body-div_section'}
        variation={modalVariation}
        width={width}
        contentMaxHeight={contentMaxHeight}
      >
        <Header data-testid='popup_popup-header-div_section'>
          <HeaderText>{caption}</HeaderText>
          {canClose && (
            <CloseButtonContainer>
              <ModalCloseButton onClick={hide} data-testid='popup_popup-body-div_close-icon' />
            </CloseButtonContainer>
          )}
        </Header>
        <ModalContent data-testid='popup_popup-content-div_section'>{children}</ModalContent>
      </ModalContainerWithWidthProp>
    </PopupOverlay>
  );
};

const ModalContainerWithWidthProp = styled(ModalContainer)<{ contentMaxHeight?: string; width?: string }>`
  position: relative;

  ${({ width }) =>
    width
      ? css`
          width: clamp(400px, calc(100% - 2.5rem), ${width});
        `
      : ''}

  ${({ contentMaxHeight }) =>
    contentMaxHeight
      ? css`
          max-height: ${contentMaxHeight};
          overflow-y: auto;
        `
      : css`
          max-height: 100%;
        `}
`;

const Header = styled(ModalHeader)`
  position: sticky;
  top: 0;
  background: ${colors.dutchie.primaryWhite};
  z-index: 1;

  justify-content: center;
  border: none;
`;

const HeaderText = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: ${colors.dutchie.almostBlack};
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;
