import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { ConfirmationPopup } from 'components/popups';
import { PackagesTable } from './PackagesTable';
import { useAddQtyFromProduct } from 'util/hooks';
import { setSelectedPreOrderQty } from 'store/actions/CartActions';
import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';

import type { Preorder } from 'models/Cart';
import type { ProductSearchResult } from 'queries/v2/product/types';
import type { State } from 'store';

export type SelectedPreOrderPackagesType = ProductSearchResult & {
  quantity: number;
  totalCost: number;
};

type AssignPackageIDsPopupProps = {
  selectedPreorderItem: Preorder;
  hide: () => void;
};

export const AssignPackageIDsPopup: FC<AssignPackageIDsPopupProps> = ({ selectedPreorderItem, hide }) => {
  const dispatch = useDispatch();

  const guestId = useSelector((state: State) => state.customer.details?.Guest_id);
  const isDualPricingEnabled = useSelector((state: State) => state.settings.features.DualPricing);
  const isMedicalGuest = useSelector((state: State) => state.customer.details?.IsMedical);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  const useRecreationalPrice = isDualPricingEnabled && !isMedicalGuest;

  const [matchingProducts, setMatchingProducts] = useState<ProductSearchResult[]>();
  const [selectedPackages, setSelectedPackages] = useState<SelectedPreOrderPackagesType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: products, isLoading: isLoadingProducts } = useGetAllProductsQuery({
    customerId: guestId,
    registerId,
  });

  const addItemToCart = useAddQtyFromProduct();

  const closePopup = () => {
    dispatch(setSelectedPreOrderQty(undefined));
    hide();
  };

  useEffect(() => {
    if (selectedPreorderItem) {
      const selectedItemProducts =
        products
          ?.filter((item) => item.productId === selectedPreorderItem?.ProductId)
          .sort((a, b) => {
            const dateA = a.receivedDate ? new Date(a.receivedDate).valueOf() : new Date().valueOf();
            const dateB = b.receivedDate ? new Date(b.receivedDate).valueOf() : new Date().valueOf();
            return dateA - dateB;
          }) ?? [];
      setMatchingProducts(selectedItemProducts);

      // Set the state for total qty selected for use in bulk calculator
      dispatch(setSelectedPreOrderQty(selectedPreorderItem.QtySelected));
    }
  }, [dispatch, selectedPreorderItem, products]);

  const handleConfirmPackageId = async () => {
    setIsLoading(true);
    if (selectedPackages) {
      for (const selectedPackage of selectedPackages) {
        try {
          await addItemToCart(selectedPackage, selectedPackage.quantity);
        } catch (e) {
          //no-op;  continue adding packages
        }
      }
      closePopup();
      setSelectedPackages([]);
      setIsLoading(false);
    }
  };

  const currentSum = selectedPackages?.reduce((prev, cur) => prev + cur.quantity, 0) ?? 0;

  return (
    <ConfirmationPopup
      title='Assign Packages'
      width='730px'
      isVisible={true}
      hide={closePopup}
      confirm={{
        text: 'Assign',
        onClick: handleConfirmPackageId,
        loading: isLoading || isLoadingProducts,
        disabled: currentSum < selectedPreorderItem.QtySelected,
      }}
    >
      <Container>
        <Message>
          Assign packages for the <b>{selectedPreorderItem.QtySelected} items</b> on this order of &ldquo;
          {selectedPreorderItem.Product}&rdquo;
        </Message>
        <PackagesTable
          useRecPrice={useRecreationalPrice}
          products={matchingProducts}
          selectedPackages={selectedPackages}
          setSelectedPackages={setSelectedPackages}
        />
      </Container>
    </ConfirmationPopup>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
`;

const Message = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.5%;

  & b {
    font-weight: 700;
  }
`;
