import React from 'react';
import styled from 'styled-components';
import { ConnectDragSource } from 'react-dnd';

import { colors } from 'css/Theme';
import { CheckedInGuest } from 'models/Guest';

import { CustomerName } from './components/CustomerName';
import { CustomerInfo } from './components/CustomerInfo';
import { OrderInfo } from './components/OrderInfo';
import { PositionedContextMenu } from './components/PositionedContextMenu';
import { CardPills } from './components/CardPills';
import { CustomerInsightsButton } from './components/CustomerInsightsButton';
import { useOrderKanbanCard } from './useOrderKanbanCard';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

export const OrderKanbanCard = React.memo(OrderKanbanCardImpl);

export type OrderKanbanCardProps = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
  listMode: boolean;
  dragRef?: ConnectDragSource;
  isDragging?: boolean;
};

export function OrderKanbanCardImpl({
  guest,
  cardOptionsStatus,
  listMode,
  dragRef,
  isDragging = false,
}: OrderKanbanCardProps) {
  const {
    onClickCard,
    hasAssignedRegister,
    draggingStyles,
    borderColor,
    isBanned,
  } = useOrderKanbanCard({ guest, cardOptionsStatus, isDragging });

  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  return (
    <OrderCardContainer
      style={draggingStyles}
      borderColor={borderColor}
      data-testid='order-item-card_order-item-base_card'
      onClick={onClickCard}
      ref={dragRef}
      data-dont-scroll-on-drag={true}
      listMode={listMode}
      aria-label={`Draggable guest card for ${guest.FullName}`}
      data-dd-action-name={`guestlist card for ${guest.FullName}`}
      isBanned={isBanned}
    >
      <CustomerName cardOptionsStatus={cardOptionsStatus} listMode={listMode} guest={guest} />
      <CustomerInfo listMode={listMode} cardOptionsStatus={cardOptionsStatus} guest={guest} />
      <OrderInfo listMode={listMode} cardOptionsStatus={cardOptionsStatus} guest={guest} />
      <CardPills listMode={listMode} cardOptionsStatus={cardOptionsStatus} guest={guest} />
      {isCustomerInsightsEnabled && <CustomerInsightsButton listMode={listMode} guest={guest} onGoToCart={onClickCard} />}
      <PositionedContextMenu guest={guest} hasAssignedRegister={hasAssignedRegister} />
    </OrderCardContainer>
  );
}

const OrderCardContainer = styled.div<{ borderColor: string; listMode: boolean; isBanned: boolean }>`
  position: relative;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 12px 0 rgb(0 0 0 / 16%);
  display: grid;
  grid-template: ${({ listMode }) => (listMode ? 'repeat(2, auto) / repeat(2, 40%) 1fr' : 'repeat(4, auto) / auto')};
  border: ${({ isBanned }) => isBanned ? `1px solid ${colors.dutchie.flora}` : 'none'};
  border-left: ${({ borderColor }) => `5px solid ${borderColor}`};
  height: auto;
  padding: ${({ listMode }) => (listMode ? '16px' : '16px 16px 0 16px')};
  gap: 12px;
  min-width: 312px;
  margin-bottom: 11px;
`;
