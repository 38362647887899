import React from 'react';
import styled from 'styled-components';

type PackageNotFoundProps = {
  serialNumber: string;
};

export const PackageNotFound = ({ serialNumber }: PackageNotFoundProps): JSX.Element => {
  return (
    <MessageContainer>
      <TopContainer>
        Package not found
        <BoldSerialNumber>{serialNumber}</BoldSerialNumber>
      </TopContainer>
      <BottomContainer>
        Try scanning again or manually add item to cart.
      </BottomContainer>
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BoldSerialNumber = styled.div`
  font-weight: 700;
`;

const TopContainer = styled.div`
  line-height: 30px;
`;

const BottomContainer = styled.div`
  line-height: 22px;
`;
