import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProductSelectionChangeProps } from 'components/cart/ProductSelectionControls';
import { usePriceCheckQuery } from 'queries/v2/inventory/price-check';
import { useProductSearchPricing } from 'util/hooks/useProductSearchPricing';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { State } from 'store';

type UseQuantityAvailable = {
  selectedProduct?: ProductSearchResult;
};

const useQuantityAvailable = ({ selectedProduct }: UseQuantityAvailable) => {
  const [qtySelected, setQtySelected] = useState<number>(1);
  const [gramsSelected, setGramsSelected] = useState<number>(0);

  const onQuantityControlChange = ({ countChange, weightChange }: ProductSelectionChangeProps) => {
    countChange && setQtySelected(qtySelected + countChange);
    weightChange && setGramsSelected(gramsSelected + weightChange);
  };

  const isWeightedItem = selectedProduct?.productType === 'Wgt';
  const totalGramsAvailable = selectedProduct?.totalGrams ?? 0;
  const totalQuantityAvailable = selectedProduct?.totalAvailable ?? 0;

  const quantityAvailable = isWeightedItem
    ? `${Math.max(totalGramsAvailable - gramsSelected, 0)}g`
    : `${Math.max(totalQuantityAvailable - qtySelected, 0)}`;

  useEffect(() => {
    setQtySelected(1);
    setGramsSelected(0);
  }, [selectedProduct]);

  return {
    isWeightedItem,
    qtySelected,
    gramsSelected,
    quantityAvailable,
    onQuantityControlChange,
  };
};

type UseProductDetails = {
  selectedProduct?: ProductSearchResult;
};

const useProductDetails = ({ selectedProduct }: UseProductDetails) => {
  const { getPrice: getBasicPrice } = useProductSearchPricing();

  const { data: priceCheckDetails, isLoading: isLoadingPricing } = usePriceCheckQuery(
    { packageSerialNumber: selectedProduct?.serialNo ?? '' },
    { enabled: !!selectedProduct }
  );

  return {
    isLoadingPricing,
    pricingTierDetails: priceCheckDetails?.pricingTier.priceDetails,
    flowerEquivalent: priceCheckDetails?.flowerEquivalent,
    productGrams: priceCheckDetails?.productGrams,
    getBasicPrice,
  };
};

type UseProductPreview = {
  selectedProduct?: ProductSearchResult;
  setSelectedProduct: (product?: ProductSearchResult) => void;
};

export const useProductPreview = ({ selectedProduct, setSelectedProduct }: UseProductPreview) => {
  const isEnableSearchPlusMinusEnabled = useSelector((state: State) => state.settings.features.EnableSearchPlusMinus);
  const isManagerSearchFlagEnabled = useSelector((state: State) => state.settings.features.ManagerSearchFlag);
  const isPackageItemsIdsEnabled = useSelector((state: State) => state.settings.features.PackageItemIds);

  const { isWeightedItem, qtySelected, gramsSelected, quantityAvailable, onQuantityControlChange } =
    useQuantityAvailable({ selectedProduct });

  const { isLoadingPricing, pricingTierDetails, flowerEquivalent, productGrams, getBasicPrice } = useProductDetails({
    selectedProduct,
  });

  const closeProductPreview = () => setSelectedProduct(undefined);

  const productIdentifier =
    isPackageItemsIdsEnabled && selectedProduct?.packageItemSerialNumber
      ? selectedProduct?.packageItemSerialNumber
      : selectedProduct?.serialNo;

  return {
    closeProductPreview,
    productIdentifier,
    pricingTierDetails,
    flowerEquivalent,
    productGrams,
    getBasicPrice,
    isLoadingPricing,
    showPlusMinusBtns: isEnableSearchPlusMinusEnabled || isManagerSearchFlagEnabled,
    isWeightedItem,
    qtySelected,
    gramsSelected,
    quantityAvailable,
    onQuantityControlChange,
  };
};
