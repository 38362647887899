import { Reducer, useCallback, useMemo, useReducer } from 'react';

type State<Item> = {
  activeItem: Item | null;
  sortedItems: Item[];
  unsortedItems: Item[];
};

type Action<Item> =
  | { type: 'select'; item: Item }
  | { type: 'complete' }
  | { type: 'replace'; item: Item }
  | { type: 'reset'; items: Item[] };

const reorder = <Item>(items: Item[], replaceItem: Item, withItem: Item) => {
  if (replaceItem === withItem) {
    return items;
  }

  const insertIndex = items.indexOf(replaceItem);
  const removeIndex = items.indexOf(withItem);

  const itemsClone = [...items];
  itemsClone.splice(removeIndex, 1);
  itemsClone.splice(insertIndex, 0, withItem);

  return itemsClone;
};

const sortDragReducer = <Item>(state: State<Item>, action: Action<Item>): State<Item> => {
  if (action.type === 'reset') {
    return { ...state, sortedItems: action.items, unsortedItems: action.items };
  }

  if (action.type === 'select') {
    return { ...state, activeItem: action.item };
  }

  if (action.type === 'complete') {
    return { ...state, activeItem: null };
  }

  if (action.type === 'replace') {
    if (state.activeItem === null) {
      throw new Error(`Attempted to reorder without an active item`);
    }

    return { ...state, sortedItems: reorder(state.sortedItems, action.item, state.activeItem) };
  }

  return state;
};

const noop = () => {};

const equal = <Item>(a: Item[], b: Item[]) => {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

export const useSortDrag = <Item>(
  items: Item[],
  getId: (item: Item) => string,
  saveOrder?: (newSortedItems: Item[]) => void
) => {
  const itemMap = useMemo(() => new Map(items.map((item) => [getId(item), item])), [items, getId]);
  const getItem = useCallback(
    (id: string): Item => {
      const item = itemMap.get(id);
      if (!item) {
        throw new Error(`Could not get item ${id}`);
      }
      return item;
    },
    [itemMap]
  );

  const [{ sortedItems, unsortedItems }, dispatch] = useReducer(sortDragReducer as Reducer<State<Item>, Action<Item>>, {
    unsortedItems: items,
    sortedItems: items,
    activeItem: null,
  });

  // Not doing this in a useEffect block as the update needs to happen synchronously lest we render stale
  // items for a cycle.
  if (unsortedItems !== items) {
    dispatch({ type: 'reset', items });
  }

  const handleDragStart = useCallback(
    (id: string) =>
      dispatch({
        type: 'select',
        item: getItem(id),
      }),
    [dispatch, getItem]
  );

  const handleDragStop = useCallback(() => {
    dispatch({ type: 'complete' });
    if (!equal(items, sortedItems)) {
      saveOrder?.(sortedItems);
    }
  }, [dispatch, items, sortedItems, saveOrder]);

  const handleDragOver = useCallback(
    (id: string) =>
      dispatch({
        type: 'replace',
        item: getItem(id),
      }),
    [dispatch, getItem]
  );

  const enabled = !!saveOrder && items.length > 0;

  return {
    sortedItems,
    handleDragStart: enabled ? handleDragStart : noop,
    handleDragStop: enabled ? handleDragStop : noop,
    handleDragOver: enabled ? handleDragOver : noop,
  };
};
