import React from 'react';

type Props = {
  height: number | 32;
  width: number | 31;
};

export const DutchieLogoSmallWhite = ({ height = 32, width = 31 }: Props): JSX.Element => {
    return (
        <svg width={width} height={height} viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.72 22.4997C27.1163 23.3733 25.374 24.0284 23.701 24.4156C21.7904 24.8524 19.8304 25.0013 17.8802 24.8524C14.6035 24.5744 11.4258 23.0655 9.23804 20.5838C8.97075 20.286 8.75296 19.9187 8.33719 19.8194C7.67393 19.6705 7.20867 20.1271 7.05028 20.7327C5.43668 26.7385 10.9209 32.4664 16.9298 31.97C22.3349 31.6424 27.3242 28.4757 29.9079 23.7008C30.0663 23.4229 30.0564 23.1052 29.898 22.8372C29.6604 22.4203 29.1358 22.2614 28.72 22.4997Z" fill="white"/>
            <path d="M15.1972 1.63307C16.7514 2.59599 18.1967 3.7773 19.3648 5.03803C20.7012 6.47744 21.8099 8.10547 22.6613 9.87247C24.0571 12.8605 24.3343 16.3647 23.2948 19.5116C23.1661 19.8987 22.9583 20.266 23.0869 20.673C23.2948 21.3183 23.9086 21.497 24.5224 21.3381C30.5214 19.73 32.719 12.116 29.284 7.14255C26.2943 2.60592 21.0675 -0.13393 15.6525 0.00504727C15.3357 0.0149743 15.0586 0.173808 14.9101 0.441837C14.6626 0.848843 14.7913 1.39482 15.1972 1.63307Z" fill="white"/>
            <path d="M16.6327 11.4014C15.4151 11.2227 14.1875 11.5205 13.1976 12.2551C12.6828 12.6323 12.2572 13.1088 11.9404 13.6647C11.0197 15.2629 11.1286 17.2583 12.2275 18.7374C12.96 19.7301 14.039 20.3853 15.2567 20.564C16.4743 20.7426 17.7018 20.4448 18.6917 19.7102C19.2065 19.333 19.6322 18.8565 19.949 18.3006C20.8696 16.7024 20.7607 14.707 19.6619 13.2279C18.9392 12.2352 17.8602 11.58 16.6327 11.4014Z" fill="white"/>
            <path d="M15.3359 7.84736C15.7319 7.76795 16.1576 7.75802 16.4447 7.45028C16.9 6.94401 16.7515 6.31861 16.2962 5.87189C11.9107 1.46431 4.22883 3.3703 1.655 8.84006C-0.760448 13.7043 -0.52286 19.6108 2.30836 24.2467C2.47665 24.5247 2.74393 24.6736 3.06071 24.6736C3.53588 24.6736 3.93186 24.2964 3.93186 23.8199C3.98135 21.9933 4.27833 20.1469 4.7832 18.499C5.35737 16.6228 6.20871 14.8459 7.31744 13.2179C9.20822 10.5078 12.0988 8.51247 15.3359 7.84736Z" fill="white"/>
        </svg>
    );
};
