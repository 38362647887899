import React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { colors } from 'css/Theme';
import { useTabbedContent } from './useTabbedContent';

export type Tab = {
  label: string;
  onClick?: () => void;
  content: React.ReactNode;
};

export type TabbedContentProps = {
  tabs: Tab[];
  selectedTab?: string;
  actionBar?: React.ReactNode;
  containerStyles?: FlattenSimpleInterpolation;
  contentStyles?: FlattenSimpleInterpolation;
  tabBarStyles?: FlattenSimpleInterpolation;
};

export const TabbedContent = ({
  tabs,
  selectedTab,
  actionBar,
  containerStyles,
  contentStyles,
  tabBarStyles,
}: TabbedContentProps) => {
  const { currentTab, handleClickTab, selectedTabContent } = useTabbedContent({ tabs, selectedTab });
  return (
    <TabContainer data-testid='tabbed-content' containerStyles={containerStyles}>
      <TabBar data-testid='tabbed-content_tab-bar' tabBarStyles={tabBarStyles}>
        <Buttons>
          {tabs.map((tab) => (
            <TabButton
              key={tab.label}
              active={currentTab === tab.label}
              onClick={() => handleClickTab(tab)}
              data-testid={currentTab === tab.label ? 'tabbed-content_active-tab' : undefined}
              data-dd-action-name={`clicked on ${tab.label} tab`}
            >
              {tab.label}
            </TabButton>
          ))}
        </Buttons>
        {actionBar && <TabActions data-testid='tabbed-content_tab-actions'>{actionBar}</TabActions>}
      </TabBar>
      <TabContent data-testid='tabbed-content_content' contentStyles={contentStyles}>
        {selectedTabContent}
      </TabContent>
    </TabContainer>
  );
};

const TabContainer = styled.div<{ containerStyles?: FlattenSimpleInterpolation }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ containerStyles }) => containerStyles}
`;

const TabBar = styled.div<{ tabBarStyles?: FlattenSimpleInterpolation }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid ${colors.dutchie.gray40};

  ${({ tabBarStyles }) => tabBarStyles}
`;

const Buttons = styled.div`
  display: flex;
  gap: 2.5rem;
`;

const TabButton = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${colors.dutchie.gray80};
  background: none;
  border: none;
  height: 3.125rem;
  padding: 0.5rem 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  ${({ active }) =>
    active &&
    css`
      color: ${colors.dutchie.almostBlack};
      font-weight: 600;
      border-bottom-color: ${colors.dutchie.red40};
    `}
`;

const TabActions = styled.div`
  display: flex;
  align-items: center;
`;

const TabContent = styled.div<{ contentStyles?: FlattenSimpleInterpolation }>`
  ${({ contentStyles }) => contentStyles}
`;
