import { useMemo } from 'react';

import { useAppSelector } from 'util/hooks';

import type { CustomerDetails } from 'models/Customer';

type UseCustomerDetailsTable = {
  customerDetails: CustomerDetails;
};

export const useCustomerDetailsTable = ({ customerDetails }: UseCustomerDetailsTable) => {
  const customerTypes = useAppSelector((state) => state.settings.customerTypes);

  const customerTypeDescription = useMemo(() => {
    if (!customerDetails.CustomerTypeId) {
      return '';
    }

    return (
      customerTypes?.find(
        (customerType) => customerType.CustomerTypeId.toString() === customerDetails.CustomerTypeId.toString()
      )?.CustomerType ?? ''
    );
  }, [customerTypes, customerDetails]);

  return {
    customerTypeDescription,
  };
};
