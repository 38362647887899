import { useMemo, useState } from 'react';

import { ConnectionType } from '@dutchie/capacitor-hardware';
import { getSortedDeviceOptions } from './getSortedDeviceOptions';

import type { SelectChangeEvent } from '@mui/material';
import type { SettingsSelectOption, SettingsSelectProps } from './SettingsSelect';

export type DeviceSettingsSelectOption = SettingsSelectOption & {
  connectionType: ConnectionType;
};

export const useSettingsSelect = ({
  devices,
  onChange,
  onRefresh,
  options: unsortedOptions,
  secondaryAction,
  value,
}: Partial<SettingsSelectProps>) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Memoized values

  // Final, sorted options list to use in the dropdown
  const options: SettingsSelectOption[] = useMemo(() => {
    if (devices) {
      return getSortedDeviceOptions(devices);
    }

    const sortedOptions = unsortedOptions?.sort((a, b) => a.label.localeCompare(b.label)) || [];
    return sortedOptions;
  }, [devices, unsortedOptions]);

  /** Ensure value is one of the available devices */
  const availableValue = options.find((option) => option.value === value)?.value ?? '';

  // Handlers

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = String(event.target.value);

    const foundOption = options.find((option) => option.value === value);
    if (foundOption) {
      onChange?.(foundOption);
    }
  };

  const handleClickSecondaryAction = async () => {
    try {
      setIsRefreshing(true);
      await secondaryAction?.onClick();
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await onRefresh?.();
    } finally {
      setIsRefreshing(false);
    }
  };

  return {
    availableValue,
    handleChange,
    handleClickSecondaryAction,
    handleRefresh,
    isRefreshing,
    options,
  };
};
