import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { post } from 'api/HttpHelpers';
import * as GuestApi from 'api/GuestApi';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import {
  CheckedInGuest,
  SetGuestToRegisterRequest,
  GuestFilterOption,
  NotifyCustomerAction,
  JoinGroupRequest,
  GuestListSortOptions,
  PusherConfiguration,
  SetGuestStatusAction,
} from 'models/Guest';

import { AppDispatch, State } from 'store';
import { getCustomerDetails, checkInCustomer } from 'store/actions/CustomerActions';
import { setCheckedInGuest } from 'store/actions/CartActions';
import { CreateAnonymousRequest, CustomerDetails } from 'models/Customer';
import { resetPrintJobStatus } from './PrintActions';
import { getAllZones } from 'api/DeliveryApi';
import { SettingsState } from 'store/reducers/SettingsReducer';
import { getIsSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';
import { refetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';

type UpdateGuestAction = {
  response: Array<CheckedInGuest>;
  settings: SettingsState;
};

export type RemoveGuestByTransactionRequest = {
  settings: SettingsState;
  transactionId: number;
};

export type UpdateGuestListRequest = {
  settings: SettingsState;
  guestUpdated: CheckedInGuest;
};

export type RemoveGuestRequest = {
  settings: SettingsState;
  guestId: number;
};

export const applyCustomersFilters = createAction<Array<GuestFilterOption>>('applyCustomersFilters');

export const applyStatusFilters = createAction<Array<GuestFilterOption>>('applyStatusFilters');

export const applyCustomersSort = createAction<GuestListSortOptions>('applyCustomersSort');

export const getGuestStatuses = createAsyncThunk('getGuestStatuses', async (args, { dispatch }) => {
  const response = await GuestApi.getGuestStatuses().catch(() => {
    dispatch(warningNotification('Unable to load statuses'));
    return [];
  });

  if (response.length === 0) {
    dispatch(warningNotification('There are no statuses available. Please set statuses to use.'));
  }
  return response;
});

export const getZones = createAsyncThunk('getZones', async (args, { dispatch }) => {
  const response = await getAllZones().catch(() => {
    dispatch(warningNotification('Unable to load delivery zones'));
    return [];
  });
  return response;
});

export const getGuests = createAsyncThunk('getGuests', async (body: void, { dispatch, getState }) => {
  const response = await GuestApi.getGuests().catch(() => {
    dispatch(warningNotification('Unable to load guests'));
    return [];
  });

  const { settings } = getState() as State;

  return { response, settings };
});

export const joinGroup = createAsyncThunk('joinGroup', async (args: JoinGroupRequest, { dispatch }) => {
  try {
    const body: JoinGroupRequest = {
      ConnectionId: args.ConnectionId,
    };
    return await GuestApi.joinGroup(body);
  } catch {
    dispatch(warningNotification('Unable to connect for real-time notifications'));
  }
});

export const setGuestStatus = createAsyncThunk(
  'setGuestStatus',
  async (args: SetGuestStatusAction, { getState, dispatch, rejectWithValue }) => {
    const { reloadCurrentCustomer, TransId, TransactionStatus } = args;
    const { customer } = getState() as State;
    try {
      await GuestApi.setGuestStatus({ TransId, TransactionStatus });
      if (reloadCurrentCustomer) {
        const guestId = customer.details?.Guest_id;
        guestId && (await dispatch(getCustomerDetails({ guestId })));
      }
      const { settings, guestList } = getState() as State;
      const isSmartCheckinActive = getIsSmartCheckinActive(settings.features.UseSmartCheckin);
      if (!isSmartCheckinActive) {
        return await post<Array<CheckedInGuest>>('v2/guest/checked-in');
      } else {
        return new Promise<Array<CheckedInGuest>>((resolve) => {
          resolve(guestList.guests);
        });
      }
    } catch {
      const errorMessage = 'Unable to update guest status';
      dispatch(warningNotification(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const releaseGuest = createAsyncThunk(
  'releaseGuest',
  async (args: { guestId: number; shipmentId: number; registerId: number; scheduleId: number }, { dispatch }) => {
    try {
      await GuestApi.releaseGuest({
        Guest_id: args.guestId,
        ShipmentId: args.shipmentId,
        RegisterId: args.registerId,
        ScheduleId: args.scheduleId,
      });
      await refetchCartDetails(dispatch as AppDispatch, {
        guestId: args.guestId,
        shipmentId: args.shipmentId,
        registerId: args.registerId,
      });
    } catch {
      dispatch(errorNotification('Unable to release guest'));
    }
  }
);

export const cancelGuest = createAsyncThunk(
  'cancelGuest',
  async (
    args: { guestId: number; shipmentId: number; scheduleId: string | number; cancelReason: string },
    { dispatch }
  ) => {
    try {
      const response = await post<void>('v2/guest/cancel_checkin', {
        Guest_id: args.guestId,
        ShipmentId: args.shipmentId,
        ScheduleId: args.scheduleId,
        CancelReason: args.cancelReason,
      });
      dispatch(successNotification('Guest transaction canceled'));
      return response;
    } catch {
      dispatch(errorNotification('Error canceling guest transaction'));
    }
  }
);

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const setGuestToRegister = createAsyncThunk(
  'setGuestToRegister',
  async (args: SetGuestToRegisterRequest, { dispatch }) => {
    try {
      await GuestApi.setGuestToRegister(args);
      dispatch(resetPrintJobStatus());
      return Promise.resolve();
    } catch {
      return Promise.reject('Network Error: Unable to assign guest to register');
    }
  }
);

export const NotifyCustomer = createAsyncThunk('notifyCustomer', async (args: NotifyCustomerAction, { dispatch }) => {
  try {
    await GuestApi.notifyCustomer({
      CustomerId: args.CustomerId,
      ShipmentId: args.ShipmentId,
    });
    await GuestApi.releaseGuest({
      Guest_id: args.CustomerId,
      ShipmentId: args.ShipmentId,
      RegisterId: args.RegisterId,
      ScheduleId: args.ScheduleId,
    });
    dispatch(successNotification('Customer notified and released from register'));
  } catch {
    dispatch(errorNotification('Error notifying customer and releasing from register'));
  }
});

/** @deprecated Use the new mutation instead
 * Clean up with pos.register.anon-cart-workflow.rollout
 */
export const createAnonymousTransaction = createAsyncThunk(
  'createAnonymousTransaction',
  async (arg: void, { dispatch, getState }) => {
    const state = getState() as State;
    const register = state.settings.selectedRegister;

    if (!register?.value) {
      dispatch(warningNotification('There is no selected register'));
      return Promise.reject();
    }
    try {
      const [response] = await GuestApi.createAnonymousTransaction({ TerminalId: register.value, RoomId: 0 });
      await dispatch(checkInCustomer({ guestId: response.AcctId }));
      const { payload: customerDetails } = await dispatch(getCustomerDetails({ guestId: response.AcctId }));
      // Need the customer details to store the "checked in guest" for use in the cart page
      if (customerDetails) {
        // Not ideal, but can't get payload to be typed correctly, but the
        // type will either be CustomerDetails or undefined hence the gated 'if'
        const details = customerDetails as CustomerDetails;
        // setCheckedInGuest helps prevent Cart page from kicking back to guestlist
        dispatch(
          setCheckedInGuest({
            ShipmentId: response.Id,
            Guest_id: response.AcctId,
            ScheduleId: Number(details.ScheduleId),
          })
        );
      }
    } catch {
      dispatch(errorNotification('Unable to create an anonymous transaction'));
      return Promise.reject();
    }
  }
);

/** @deprecated Use the new mutation instead
 * Clean up with pos.register.anon-cart-workflow.rollout */
export const createAnonymousTransactionWithDemographics = createAsyncThunk(
  'createAnonymousTransactionWithDemographics',
  async (args: CreateAnonymousRequest, { dispatch, getState }) => {
    const state = getState() as State;
    const register = state.settings.selectedRegister;
    if (!register?.value) {
      dispatch(warningNotification('There is no selected register'));
      return Promise.reject();
    }

    try {
      const [response] = await GuestApi.createAnonymousTransaction({
        TerminalId: register.value,
        RoomId: 0,
        ...args,
      });
      await refetchCartDetails(dispatch as AppDispatch, {
        guestId: response.AcctId,
        registerId: register.value,
        shipmentId: response.Id,
      });
      await dispatch(checkInCustomer({ guestId: response.AcctId }));
      await dispatch(getCustomerDetails({ guestId: response.AcctId }));
    } catch {
      dispatch(errorNotification('Unable to create an anonymous transaction'));
      return Promise.reject();
    }
  }
);

export const getPusherConfiguration = createAsyncThunk('getPusherConfiguration', async (args, { dispatch }) => {
  const response = await post<PusherConfiguration>('pusher/config').catch((error) => {
    const pusherResult: PusherConfiguration = { PusherKey: '', PusherCluster: '', ChannelName: '' };
    dispatch(warningNotification('Unable to connect for real-time notifications'));
    return pusherResult;
  });
  return response;
});

export const authPusherChannel = createAsyncThunk('authPusherChannel', async (args, { dispatch }) => {
  const response = await post<PusherConfiguration>('pusher/config').catch(() => {
    const pusherResult: PusherConfiguration = { PusherKey: '', PusherCluster: '', ChannelName: '' };
    return pusherResult;
  });
  return response;
});

export const cleanPusherConfiguration = createAsyncThunk(
  'cleanPusherConfiguration',
  async (args, { dispatch, getState }) => {
    const pusherConfiguration: PusherConfiguration = {
      ChannelName: '',
      PusherCluster: '',
      PusherKey: '',
    };
    return pusherConfiguration;
  }
);

export const addOrUpdateGuest = createAction<UpdateGuestListRequest>('addOrUpdateGuest');

export const removeGuestFromList = createAction<RemoveGuestRequest>('removeGuestFromList');

export const removeGuestByTransactionIdFromList = createAction<RemoveGuestByTransactionRequest>(
  'removeGuestByTransactionIdFromList'
);

export const setGuestSearchQuery = createAction<string>('setQuery');

export const clearGuestSearchQuery = createAction('clearQuery');

export const setGuestFilterQuery = createAction<string>('setFilter');

export const updateGuestList = createAction<UpdateGuestAction>('updateGuestList');

export const clearGuestFilters = createAction('clearGuestFilters');

export const setShowEmptyStatusColumns = createAction<boolean>('setShowEmptyStatusColumns');

export const notifyConectionLost = createAction('notifyConectionLost');

export const notifyPusherConnected = createAction('notifyPusherConnected');

export const loadCardStatusDisplayOptions = createAsyncThunk(
  'loadCardStatusDisplayOptions',
  GuestApi.getCardStatusDisplayOptions
);
