import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckedInGuest, GuestStatus } from 'models/Guest';
import { OrdersByStatus } from '../types';
import { State } from 'store';
import { setGuestStatus } from 'store/actions/GuestListActions';
import { successNotification } from 'store/actions/NotificationsActions';
import { ordersByStatusSelector } from 'store/reducers/GuestListReducer';
import { filterAndSortGuests } from 'util/Helpers';

/** Used in GuestListReducer */
export const sortOrdersByStatus = (
  statuses: Array<GuestStatus>,
  orders: Array<CheckedInGuest>,
  defaultPosStatus?: string
): OrdersByStatus => {
  const defaultStatus = defaultPosStatus || statuses[0]?.POSStatus;
  const statusNames = statuses.map((status) => status.POSStatus);
  const statusNamesSet = new Set(statusNames);

  const ordersByStatus: OrdersByStatus = statusNames.reduce((acc, statusName) => {
    return { ...acc, [statusName]: [] };
  }, {});

  if (defaultStatus && !ordersByStatus[defaultStatus]) {
    ordersByStatus[defaultStatus] = [];
  }

  orders.forEach((order) => {
    if (statusNamesSet.has(order.TransactionStatus)) {
      ordersByStatus[order.TransactionStatus].push(order);
      return;
    }

    if (defaultStatus) {
      ordersByStatus[defaultStatus].push({ ...order, TransactionStatus: defaultStatus });
    }
  });

  return ordersByStatus;
};

export const useOrdersByStatus = () => {
  const dispatch = useDispatch();
  const ordersByStatus = useSelector(ordersByStatusSelector);
  const statuses = useSelector((state: State) => state.guestList.statuses);
  const selectedRegister = useSelector((state: State) => state.settings.selectedRegister);
  const sortOptions = useSelector((state: State) => state.guestList.sortOptions);

  const [ordersByStatusLocal, setOrdersByStatusLocal] = useState<OrdersByStatus>(ordersByStatus);

  const moveOrder = useCallback(
    async (guest: CheckedInGuest, destinationStatus: string) => {
      const sourceStatus = guest.TransactionStatus;
      const sourceClone = Array.from(ordersByStatus[sourceStatus]);
      const destinationClone = Array.from(ordersByStatus[destinationStatus]);
      const guestIndexFromSource = sourceClone.findIndex(({ Guest_id }) => Guest_id === guest.Guest_id);

      const [movedGuest] = sourceClone.splice(guestIndexFromSource, 1);
      destinationClone.push({ ...movedGuest, TransactionStatus: destinationStatus });

      const newSourceArray = filterAndSortGuests({
        guests: sourceClone,
        statuses,
        selectedRegister,
        sortOptions,
      });

      const newDestinationArray = filterAndSortGuests({
        guests: destinationClone,
        statuses,
        selectedRegister,
        sortOptions,
      });

      const newOrdersByStatusLocal = {
        ...ordersByStatus,
        [sourceStatus]: newSourceArray,
        [destinationStatus]: newDestinationArray,
      };

      setOrdersByStatusLocal(newOrdersByStatusLocal);
      const response = await dispatch(
        setGuestStatus({ TransId: guest.ShipmentId, TransactionStatus: destinationStatus })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(response as any)?.error) {
        dispatch(successNotification('Order status has been updated.'));
      } else {
        setOrdersByStatusLocal(ordersByStatus);
      }
    },
    [dispatch, ordersByStatus, selectedRegister, sortOptions, statuses]
  );

  /**
   * This useEffect is necessary for keeping local state synced with redux state.
   */
  useEffect(() => {
    if (JSON.stringify(ordersByStatus) !== JSON.stringify(ordersByStatusLocal)) {
      setOrdersByStatusLocal(ordersByStatus);
    }

    // Rationale for ignoring rule: don't run when updating local state or else we'll never see the optimistic UI update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersByStatus]);

  return { moveOrder, orders: ordersByStatusLocal };
};
