import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Skeleton } from 'components/misc';
import { colors } from 'css/Theme';

export enum ScannerBarcodeType {
  reset,
  configure,
}

type ScannerBarcodeProps = {
  aspectRatio?: number;
  maxHeight?: React.CSSProperties['maxHeight'];
  src: string;
  title?: string;
};

export const ScannerBarcode = ({ aspectRatio, maxHeight, src, title }: ScannerBarcodeProps) => {
  return (
    <Container title={title}>
      <Code src={src} aspectRatio={aspectRatio} maxHeight={maxHeight} />
    </Container>
  );
};

enum ScannerBarcodeStatus {
  initial,
  loading,
  loaded,
  error,
}

export type ScannerBarcodeAsyncProps = {
  aspectRatio?: number;
  maxHeight?: React.CSSProperties['maxHeight'];
  src: string | (() => Promise<string | undefined>);
  title?: string;
};

export const ScannerBarcodeAsync = ({ aspectRatio, maxHeight, src, title }: ScannerBarcodeAsyncProps) => {
  const [status, setStatus] = useState<ScannerBarcodeStatus>(
    typeof src === 'string' ? ScannerBarcodeStatus.loaded : ScannerBarcodeStatus.initial
  );
  const [barcodeSrc, setBarcodeSrc] = useState<string | undefined>(typeof src === 'string' ? src : undefined);

  useEffect(() => {
    if (status === ScannerBarcodeStatus.initial && typeof src === 'function') {
      setStatus(ScannerBarcodeStatus.loading);
      src()
        .then((newSrc) => {
          setBarcodeSrc(newSrc);
          setStatus(ScannerBarcodeStatus.loaded);
        })
        .catch((_e) => setStatus(ScannerBarcodeStatus.error));
    }
  }, [barcodeSrc, src, status]);

  if (status === ScannerBarcodeStatus.error || (status === ScannerBarcodeStatus.loaded && !barcodeSrc)) {
    return (
      <Container>
        <Error>Failed to load barcode. Please try again</Error>
      </Container>
    );
  }

  if (status === ScannerBarcodeStatus.loaded && barcodeSrc) {
    return (
      <Container title={title}>
        <Code src={barcodeSrc} aspectRatio={aspectRatio} maxHeight={maxHeight} />
      </Container>
    );
  }

  return (
    <Container title={title}>
      <Skeleton width={200} height={61} />
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
`;

const Code = styled.div<ScannerBarcodeProps>`
  aspect-ratio: ${(props) => props.aspectRatio ?? 3};
  background: url(${(props) => props.src}) no-repeat left top;
  background-size: contain;
  overflow: hidden;
  width: ${(props) => (props.aspectRatio === 1 ? '200px' : '300px')};

  ${(props) =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight}px;
  `}
`;

const Error = styled.div`
  color: ${colors.dutchie.red};
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
`;
