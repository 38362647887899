import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getCartDetailsKeys } from './query-key-factory';
import { validateFeesInCart } from './helpers/validateFeesInCart';
import { PaymentType } from 'models/Checkout';
import { useGetCartDetailsRequest } from './load';

import type { GetCartDetailsPayload } from './load';

export type UpdateCartFeesPayload = Omit<GetCartDetailsPayload, 'feePaymentMethodIds'> & {
  selectedPaymentType: PaymentType | null;
};

/**
 * This is a special mutation helper for the GET method we use to load cart that specifies
 * a payment type to be used for fee calculations
 */
export const useUpdateCartFeesMutation = () => {
  const queryClient = useQueryClient();
  // Note: This is the same function as the GET cart details we use to load the cart
  const getCartDetails = useGetCartDetailsRequest();

  return useMutation(
    async (payload: UpdateCartFeesPayload) => {
      const { shouldRecalculateFees, newFeePaymentMethodIds } = validateFeesInCart(payload);
      if (!shouldRecalculateFees) {
        return undefined;
      }
      return await getCartDetails({
        ...payload,
        feePaymentMethodIds: newFeePaymentMethodIds,
      });
    },
    {
      onSuccess: (adjustedCartDetails, payload) => {
        if (adjustedCartDetails) {
          // Override the query data with the adjusted cart details
          // This will include the updated fees
          queryClient.setQueryData(getCartDetailsKeys.one(payload), adjustedCartDetails);
        }
      },
    }
  );
};
