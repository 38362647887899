import { useCallback } from 'react';
import { clone, reduce } from 'lodash';

import { convertToOldCartItems } from 'queries/v2/cart/types';
import { getProductId } from 'util/helpers/products/getProductId';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useGetCartDetailsQuery } from 'queries/v2/cart/load';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { CartItem } from 'models/Cart';

type CartItemsById = Record<string, CartItem>;
type CartItemSelector<TData = CartItemsById> = (cartItemsById: CartItemsById) => TData;

export const transformCartItems = <TData = CartItemsById>(
  cartItems: CartItem[],
  selector?: CartItemSelector<TData>
) => {
  const cartItemsById = reduce(
    cartItems,
    (acc: CartItemsById, cartItem: CartItem) => {
      const productId = getProductId(cartItem);
      if (acc[productId] && cartItem.WgtCnt === 'Wgt') {
        const matchingCartItem = clone(acc[productId]);
        if (matchingCartItem.Grams && cartItem.Grams) {
          matchingCartItem.Grams += cartItem.Grams;
        }
        acc[productId] = matchingCartItem;
      } else {
        acc[productId] = cartItem;
      }

      return acc;
    },
    {}
  );

  // This ensures the type is always forwarded up as TData and doesn't explicitly include CartItemsById as a potential type
  // If a selector is passed in, the type will always be the return type of the selector transformation function
  const selectorToUse = selector ?? (<TData = CartItemsById>(cartItemsById: CartItemsById) => cartItemsById as TData);

  return selectorToUse(cartItemsById);
};

export const useCartItems = <TData = CartItemsById>(selector?: CartItemSelector<TData>) => {
  const { guestId, shipmentId } = useTransactionManager();
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  // Use the cart details query to select and transform the cart items
  const { data } = useGetCartDetailsQuery(
    { guestId: guestId ?? 0, shipmentId: shipmentId ?? 0 },
    {
      enabled: isAnonymousCartLDFlagEnabled,
      select: useCallback(
        (cartDetails) => {
          const cartItems = convertToOldCartItems(cartDetails.cartItems);
          return transformCartItems(cartItems, selector);
        },
        [selector]
      ),
    }
  );

  // This data type will either be CartItemsById (default) or the return type of the selector function
  return data;
};
