import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { colors, zIndices } from 'css/Theme';
import { Button } from 'components/buttons';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';
import { ModalCloseButton } from 'components/modals/Modal';

type ButtonProps = {
  text?: string;
  action?: () => void;
};

type RemovalPopupProps = {
  title?: string;
  message: string | ReactNode;
  confirm?: ButtonProps;
  cancel?: ButtonProps;
  hide: () => void;
  width?: string;
  portalContainer?: Element;
};

export const RemovalPopup: FC<RemovalPopupProps> = ({
  title,
  message,
  confirm,
  cancel,
  hide,
  width = '600px',
  portalContainer,
}) => {
  const { isModalBridgeEnabled } = useModalBridge();

  return createPortal(
    <PopupOverlay>
      <PopupContainer data-testid='removal-popup_popup-div' width={width}>
        {isModalBridgeEnabled ? (
          <CloseButtonContainer>
            <ModalCloseButton onClick={hide} data-testid='removal-popup_popup-div_close-icon' />
          </CloseButtonContainer>
        ) : (
          <CloseIcon onClick={hide} data-testid='removal-popup_popup-div_close-icon' />
        )}
        <PopupContent data-testid='removal-popup_popup-content'>
          <PopupTitle data-testid='removal-popup_popup-content_title'>{title ?? 'Are you sure?'}</PopupTitle>
          <PopupMessage data-testid='removal-popup_popup-content_message'>{message}</PopupMessage>
        </PopupContent>
        <PopupButtons>
          <Button
            cta
            tertiary
            onClick={() => {
              cancel?.action?.();
              hide();
            }}
            data-testid='removal-popup_popup-content_cancel-button'
          >
            {cancel?.text ?? 'Cancel'}
          </Button>
          <RedDutchieButton
            cta
            isModalBridgeEnabled={isModalBridgeEnabled}
            onClick={() => {
              confirm?.action?.();
              hide();
            }}
            data-testid='removal-popup_popup-content_confirm-button'
          >
            {confirm?.text ?? 'Yes, remove'}
          </RedDutchieButton>
        </PopupButtons>
      </PopupContainer>
    </PopupOverlay>,
    portalContainer ?? document.body
  );
};

const PopupOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: ${zIndices.popup};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PopupContainer = styled.div<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  background: ${colors.dutchie.primaryWhite};
  border: 1px solid ${colors.dutchie.grey90};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0.75rem;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  right: 1.125rem;
  top: 1.125rem;
  color: ${colors.dutchie.gray70};
`;

const PopupContent = styled.div<{ contentMaxHeight?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PopupTitle = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 2rem;
  line-height: 1.875rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

const PopupMessage = styled.div`
  color: ${colors.dutchie.grey30};
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
`;

const PopupButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const RedDutchieButton = styled(Button)<{ isModalBridgeEnabled?: boolean }>`
  background: ${colors.dutchie.red};

  ${({ isModalBridgeEnabled }) =>
    isModalBridgeEnabled &&
    css`
      border-color: ${colors.dutchie.red};

      &:hover {
        background: ${colors.dutchie.deepRed};
      }
    `}
`;
