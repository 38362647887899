import React, { FC } from 'react';

import { Button } from 'components/buttons';
import { ProductPreview } from 'pages/CartPage/CartComponents/ProductPreview';
import { ProductSearchFilter } from 'components/tables/ProductSearchFilter';
import { useAddItemPanel } from './useAddItemPanel';

import {
  AddItemsContainer,
  AddItemsTop,
  AddItemsTitle,
  Separator,
  MainContent,
  RightPanel,
  LeftPanel,
} from 'components/cart/AddItemsPanel.styles';

type AddItemPanelProps = {
  onClose: () => void;
};

export const AddItemPanel: FC<AddItemPanelProps> = ({ onClose }) => {
  const { showSelectedProduct, selectedProduct, setSelectedProduct, setRightPanelRef } = useAddItemPanel();

  return (
    <AddItemsContainer>
      <AddItemsTop>
        <AddItemsTitle>Add items</AddItemsTitle>
        <Button
          tertiary
          onClick={onClose}
          automationId='add-item-panel-close-button'
          ddActionName='Close (add items panel)'
        >
          Close
        </Button>
      </AddItemsTop>
      <Separator />
      <MainContent>
        <LeftPanel>
          <ProductSearchFilter
            waitForCustomerToLoad
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </LeftPanel>
        <RightPanel ref={setRightPanelRef} isShown={showSelectedProduct} className='content-hidden'>
          {showSelectedProduct && (
            <ProductPreview selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
          )}
        </RightPanel>
      </MainContent>
    </AddItemsContainer>
  );
};
