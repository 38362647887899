import React from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useCfdSettings } from '../hooks/useCfdSettings';
import { useCfd } from '../hooks/useCfd';

export const CfdTestOpenListItem = () => {
  const { formValues } = useCfdSettings();
  const { openCartDisplay } = useCfd();

  return (
    <SettingsListItem
      automationId='cfd_settings-test_button'
      title='Test open display'
      subtitle='Test open the customer-facing display'
      onClick={() => openCartDisplay(formValues.secondaryScreenId)}
    />
  );
};
