import { format, isBefore, isSameDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

type FormatDateProps = {
  date?: Date | string;
  dateFormat?: string;
  cutOffDate?: Date | string;
  fallbackString?: string;
  convertFromEst?: boolean;
};

/**
 * A helper for easier date formatting with a cutoff date
 * to prevent weird dates like 01/01/1900 or 01/01/0001
 * @param date The date or datestring to format
 * @param dateFormat Takes a string to format the date (Default: 'MM/dd/yyyy')
 * @param cutOffDate The date to switch to using the fallback string (Default: 01/01/1900)
 * @param fallbackString What to so if format fails or is before cutoff (Default: '')
 * @param convertFromEst If the date is in EST, convert to UTC
 * @returns formatted date or fallback string
 */
export const formatDate = ({
  date,
  dateFormat = 'MM/dd/yyyy',
  cutOffDate = '01/01/1900',
  fallbackString = '',
  convertFromEst = false,
}: FormatDateProps) => {
  if (!date) {
    return fallbackString;
  }

  let dateToFormat = new Date(date);
  const dateToCompare = new Date(cutOffDate);

  if (convertFromEst) {
    dateToFormat = zonedTimeToUtc(dateToFormat, 'America/New_York');
  }

  if (isBefore(dateToFormat, dateToCompare) || isSameDay(dateToFormat, dateToCompare)) {
    return fallbackString;
  }

  try {
    return format(dateToFormat, dateFormat);
  } catch (e) {
    return fallbackString;
  }
};
