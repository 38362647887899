import styled from 'styled-components';
import { BooleanSelect } from '../../select/boolean-select/index';
import { BaseInput } from '../base-input';
import { SelectAdornment } from '../select-adornment';

export const TableInput = styled(BaseInput)`
  height: var(--sizes-70);

  .MuiInputBase-input {
    padding: var(--sizes-20) var(--sizes-40);
    font: var(--font-extra-small-12pt-normal);
  }

  .MuiInputBase-adornedEnd {
    padding-left: var(--sizes-40);
    padding-right: var(--sizes-40);

    .MuiInputBase-input {
      padding-left: 0;
    }
  }
`;

export const EndAdornment = styled.span`
  font: var(--font-extra-small-12pt-bold);
  text-transform: uppercase;
  color: var(--color-greyscale-grey-65);
`;

export const TableSelectAdornment = styled(SelectAdornment)`
  display: flex;

  .select-select-input {
    height: var(--sizes-60);
    padding: var(--sizes-10) 10px var(--sizes-10) var(--sizes-20);

    .MuiInputBase-input {
      font: var(--font-extra-small-12pt-semibold);
      padding: 0;
    }

    .MuiSelect-icon {
      top: 10px;
    }
  }
`;

export const TableBooleanSelect = styled(BooleanSelect)`
  display: flex;
  height: var(--sizes-70);

  .MuiSelect-select {
    padding: var(--sizes-20) var(--sizes-40) !important;
  }

  .select-select-input {
    height: var(--sizes-70);
    font-size: var(--sizes-30);
  }

  .MuiSelect-icon {
    top: 14px;
  }
`;
