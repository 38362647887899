import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { DatePickerField, InputField, SelectField } from 'components/inputs';
import { NewAnonymousDemographicsModal } from './index.styles';
import { useAnonymousTransactionPopup, initialValues } from './useAnonymousTransactionPopup';

export type AnonymousTransactionPopupProps = {
  hide: () => void;
  dob?: Date;
};

const AnonymousTransactionPopupWithoutForm: FC<AnonymousTransactionPopupProps> = ({ hide, dob }) => {
  const { customerTypeOptions, handleCancel, handleSubmit, isDobVerified, isSubmitDisabled, isSubmitting, genderList } =
    useAnonymousTransactionPopup({ dob, hide });

  return (
    <NewAnonymousDemographicsModal
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isSubmitDisabled={isSubmitDisabled}
    >
      <Form>
        <DatePickerField
          name='dob'
          labelText='Date of birth'
          disabled={isDobVerified}
          placeholderText='mm/dd/yyyy'
          showMonthDropdown
          showYearDropdown
          automationId='anonymous-transaction-popup_select-field_dob'
        />
        <SelectField
          name='gender'
          label='Gender'
          placeholder='Select gender...'
          options={genderList}
          automationId='anonymous-transaction-popup_select-field_gender'
        />
        <InputField
          name='postalCode'
          label='Postal code'
          placeholder='Enter postal code...'
          type='number'
          automationId='anonymous-transaction-popup_input-field_postal-code'
        />
        <SelectField
          name='customerTypeId'
          label='Customer type'
          placeholder='Select customer type...'
          options={customerTypeOptions}
          automationId='anonymous-transaction-popup_select-field_customer-type'
        />
      </Form>
    </NewAnonymousDemographicsModal>
  );
};

const customerFormSchema = () => {
  return Yup.object().shape({
    postalCode: Yup.number().required(),
    customerTypeId: Yup.number().required().min(1),
    dob: Yup.date()
      .max(new Date(new Date().setHours(0, 0, 0, 0)), 'Date of birth cannot be in the future')
      .required(),
    gender: Yup.string().required(),
  });
};

export const AnonymousTransactionPopup: FC<AnonymousTransactionPopupProps> = ({ hide, dob }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      onSubmit={() => {}} // Datepicker may silently fail validation and block this so we validate + show errors on click
      validationSchema={customerFormSchema}
    >
      <AnonymousTransactionPopupWithoutForm hide={hide} dob={dob} />
    </Formik>
  );
};
