import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Checkbox, CheckboxState } from 'components/inputs/Checkbox';
import { EmbeddedChildTableRowV2 } from './EmbeddedChildTableRowV2';
import { useEmbeddedTableStore } from './useEmbeddedTableStore';
import { useSortDrag } from './useSortDrag';

type EmbeddedChildTableV2Props = {
  parentId: string;
};

const EMPTY_ARRAY: string[] = [];

function identity<Type>(item: Type) {
  return item;
}

export function EmbeddedChildTableV2<ParentItem, ChildItem>(props: EmbeddedChildTableV2Props) {
  const { parentId } = props;
  const {
    parentItemMap,
    childItemMap,
    relationshipMap,
    childColumns,
    childActions,
    onChangeParent,
    onChangeChildrenOrder,
    onSelectChild,
    selectedChildren,
    emptyChildMessage,
    getChildId,
  } = useEmbeddedTableStore<ParentItem, ChildItem>();
  const childIds = relationshipMap[parentId] || EMPTY_ARRAY;
  const selectedChildIdSet = useMemo(
    () => new Set((selectedChildren ?? []).map(getChildId)),
    [selectedChildren, getChildId]
  );
  const dragMoveDisabled = !onChangeParent;
  const isSelectionEnabled = !!onSelectChild && !!selectedChildren;
  const selectionState: CheckboxState = useMemo(() => {
    if (!selectedChildren || selectedChildren.length === 0) {
      return 'off' as const;
    }
    if (childIds.length !== selectedChildren.length) {
      return 'mixed' as const;
    }
    return 'on' as const;
  }, [selectedChildren, childIds]);

  const handleChangeOrder = (childIds: string[]) => {
    const parent = parentItemMap[parentId];
    if (!parent) {
      throw new Error(`No parent found for ID ${parentId}`);
    }
    const children = childIds.map((id) => {
      const child = childItemMap[id];
      if (!child) {
        throw new Error(`No child found for ID ${id}`);
      }
      return child;
    });

    if (onChangeChildrenOrder) {
      onChangeChildrenOrder(parent, children);
    }
  };

  const handleToggleSelectAll = () => {
    if (!onSelectChild) {
      return;
    }
    const childrenToSelect = selectionState === 'on' ? [] : childIds;
    onSelectChild(childrenToSelect.map((id) => childItemMap[id]));
  };

  const handleToggleSelectOne = (childId: string) => {
    if (!onSelectChild || !selectedChildren) {
      return;
    }

    if (selectedChildIdSet.has(childId)) {
      onSelectChild(selectedChildren.filter((child) => getChildId(child) !== childId));
      return;
    }

    const child = childItemMap[childId];
    if (!child) {
      throw new Error(`Child not available: ${childId}`);
    }
    onSelectChild([child, ...selectedChildren]);
  };

  const { sortedItems, handleDragStart, handleDragStop, handleDragOver } = useSortDrag(
    childIds,
    identity,
    onChangeChildrenOrder ? handleChangeOrder : undefined
  );

  const numColumns = childColumns.length + (isSelectionEnabled ? 1 : 0) + (childActions ? 1 : 0);

  return (
    <TableStyle className='child'>
      <thead className='child'>
        <TRChildStyle className='child'>
          {isSelectionEnabled && (
            <THDefaultStyle>
              <StyledCheckbox state={selectionState} onClick={handleToggleSelectAll} />
            </THDefaultStyle>
          )}
          {childColumns.map(({ header, width }, i) => (
            <THChildStyle className='child' key={`child-header-${i}`} width={width}>
              {header}
            </THChildStyle>
          ))}
          {childActions && <THDefaultStyle className='child action-menu' />}
        </TRChildStyle>
      </thead>
      <tbody className='child'>
        {sortedItems.map((id) => (
          <EmbeddedChildTableRowV2<ParentItem, ChildItem>
            {...props}
            key={id}
            id={id}
            numColumns={numColumns}
            selected={selectedChildIdSet.has(id)}
            isSelectionEnabled={isSelectionEnabled}
            dragDropDisabled={!onChangeChildrenOrder && dragMoveDisabled}
            onToggleSelection={() => handleToggleSelectOne(id)}
            onDragStart={() => handleDragStart(id)}
            onDragStop={() => handleDragStop()}
            onDragOver={() => handleDragOver(id)}
          />
        ))}
        {sortedItems.length === 0 && emptyChildMessage && (
          <tr className='child empty'>
            <td className='child empty' colSpan={numColumns}>
              {emptyChildMessage}
            </td>
          </tr>
        )}
      </tbody>
    </TableStyle>
  );
}

const TableStyle = styled.table`
  background-color: #f5f6f7;
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: center;
  padding: 0.75rem 1rem;
  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
const TRChildStyle = styled.tr`
  white-space: nowrap;
  border-bottom: 1px solid #e3e7e9;
`;

const THDefaultStyle = styled.th`
  width: 3rem;
  height: 2.5rem;
  content: '';
`;

const THChildStyle = styled.th<{ width?: string }>`
  width: ${({ width }) => width};
  padding: 0.75rem 1rem;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 145.455% */
  letter-spacing: 0.01375rem;
  text-transform: uppercase;
  color: #646d72;
  vertical-align: middle;
`;
