import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, zIndices } from 'css/Theme';
import { Button } from 'components/buttons';
import { ModalCloseButton } from 'components/modals/Modal';
import { MessageContainer, MessageHeader, MessageContent, GreyHeading, GreySubHeading } from './styles';
import { CancelIntegratedPaymentChildProps, CancelStates } from '.';
import { ReactComponent as Close } from 'assets/icons/icon-close.svg';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

export const BudtenderPrompt: FC<CancelIntegratedPaymentChildProps> = ({
  last4,
  guestName,
  shipmentId,
  setCancelState,
  hide,
}) => {
  const { isModalBridgeEnabled } = useModalBridge();

  const NoRefundButtonToUse = isModalBridgeEnabled ? Button : NoRefundButton;
  const RefundButtonToUse = isModalBridgeEnabled ? Button : RefundButton;

  return (
    <PopupBaseDiv>
      <BudtenderPromptBase data-testid='cancel-integrated-payment_budtender-prompt'>
        {isModalBridgeEnabled ? (
          <CloseIconContainer data-testid='popup_popup-header-div_section'>
            <ModalCloseButton onClick={hide} data-testid='popup_popup-body-div_close-icon' />
          </CloseIconContainer>
        ) : (
          <PopupHeader data-testid='popup_popup-header-div_section'>
            <CloseIcon onClick={hide} data-testid='popup_popup-body-div_close-icon' />
          </PopupHeader>
        )}
        <MessageContainer>
          <MessageHeader>Cancel order</MessageHeader>
          <MessageContent>
            <GreyHeading>
              <b>
                #{shipmentId} from {guestName}
              </b>
            </GreyHeading>
            <GreySubHeading>
              To cancel with refund, you must be able to
              <br />
              swipe the debit card ending in <b>{last4}.</b>
            </GreySubHeading>
          </MessageContent>
        </MessageContainer>
        <Buttons>
          <NoRefundButtonToUse
            cta={isModalBridgeEnabled}
            type='button'
            data-testid='confirmation-popup_cancel-button_cancel'
            onClick={() => {
              setCancelState(CancelStates.cancelWithoutRefund);
            }}
            tertiary
          >
            Cancel without refund
          </NoRefundButtonToUse>
          <RefundButtonToUse
            cta={isModalBridgeEnabled}
            type='button'
            automationId='confirmation-popup_confirm-button_confirm'
            onClick={() => {
              setCancelState(CancelStates.cancelWithRefund);
            }}
          >
            Cancel with refund
          </RefundButtonToUse>
        </Buttons>
      </BudtenderPromptBase>
    </PopupBaseDiv>
  );
};

const PopupBaseDiv = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: ${zIndices.popup};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PopupBodyDiv = styled.div<{ width?: string }>`
  background-color: ${colors.dutchie.primaryWhite};
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  position: relative;
  width: 43.5rem;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const HeaderH3 = styled.h3<{ margin?: string }>`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: ${({ margin }) => margin || '0 auto'};
`;

const BudtenderPromptBase = styled(PopupBodyDiv)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  gap: 32px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #e3e6e7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 600px;
  height: 300px;
`;

const PopupHeader = styled.div`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background: ${colors.dutchie.primaryWhite};
  box-shadow: 0 1px 0 ${colors.dutchie.shadowGrey};
  position: absolute;
  left: 16px;
  top: 5px;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  color: ${colors.dutchie.grey};
`;

const Buttons = styled.div`
  /* Buttons */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 520px;
  height: 56px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
`;

const NoRefundButton = styled(Button)`
  /* primary-button */

  /* Auto layout */
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;

  width: 252px;
  height: 56px;

  /* Greyscale/Grey 90 */

  background: #e3e6e7;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`;

const RefundButton = styled(Button)`
  /* primary-button */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;

  width: 252px;
  height: 56px;

  /* Brand/Secondary/Sapphire */

  background: #0075e0;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
`;
