import { useMemo, useRef, useState } from 'react';
import { reduce } from 'lodash';

import { CashEntryMode } from 'store/reducers/CashManagementReducer';
import { closeCashDetailsPanel, resetCashDetails, setCashEntryMode } from 'store/actions/CashManagementActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';

import type { CashDetailsProps } from './CashDetailsPanel';

export const useCashDetailsPanel = ({ onSave }: CashDetailsProps) => {
  const dispatch = useAppDispatch();
  const headerRef = useRef<HTMLDivElement>(null);

  const [showConfirmClearEntries, setShowConfirmClearEntries] = useState(false);

  // Global state
  const cashDetails = useAppSelector((state) => state.cashManagement.cashDetails);
  const entryMode = useAppSelector((state) => state.cashManagement.cashEntryMode);
  const hasEntryErrors = useAppSelector((state) => Object.values(state.cashManagement.entryErrors).some(Boolean));
  const isCashDetailsPanelOpen = useAppSelector((state) => state.cashManagement.isCashDetailsPanelOpen);

  // Memoized values

  const totalCounted = useMemo(() => {
    return reduce(
      cashDetails,
      (acc, amount, denomination) => {
        if (entryMode === CashEntryMode.VALUE || denomination === 'all') {
          return acc + Number(amount);
        }
        const value = Number(amount) * Number(denomination);
        return acc + value;
      },
      0
    );
  }, [cashDetails, entryMode]);

  // Handlers

  const handleClear = () => {
    setShowConfirmClearEntries(true);
  };

  const handleCancelClearEntries = () => {
    setShowConfirmClearEntries(false);
  };

  const handleConfirmClearEntries = () => {
    dispatch(resetCashDetails());
    setShowConfirmClearEntries(false);
  };

  const handleClose = () => {
    dispatch(closeCashDetailsPanel());
  };

  const handleSave = () => {
    onSave({
      floatValue: totalCounted,
      formattedValue: totalCounted.toFixed(2),
      value: totalCounted.toString(),
    });

    dispatch(closeCashDetailsPanel());
  };

  const handleSwitchEntryMode = () => {
    const newEntryMode = entryMode === CashEntryMode.VALUE ? CashEntryMode.QUANTITY : CashEntryMode.VALUE;
    dispatch(setCashEntryMode(newEntryMode));
  };

  return {
    cashDetails,
    entryMode,
    handleCancelClearEntries,
    handleClear,
    handleClose,
    handleConfirmClearEntries,
    handleSave,
    handleSwitchEntryMode,
    headerRef,
    isCashDetailsPanelOpen,
    isSaveDisabled: hasEntryErrors,
    showConfirmClearEntries,
    totalCounted,
  };
};
