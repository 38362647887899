import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ScannerAction } from 'store/reducers/ScannerReducer';
import { setScannerAction, clearScannerAction } from 'store/actions/ScannerActions';

type Scanner = {
  /** Changes to a new event handler for scanning */
  changeAction: (newAction: ScannerAction) => void;
  /** Clears any handlers for the scanner */
  clearAction: () => void;
};

export const useScanner = () => {
  const dispatch = useDispatch();

  const [scanner] = useState<Scanner>({
    // New method moves the comparison with the current and new action to the Redux reducer to prevent re-renders
    changeAction: (newAction: ScannerAction) => dispatch(setScannerAction({ onScan: newAction })),
    clearAction: () => dispatch(clearScannerAction()),
  });

  return scanner;
};
