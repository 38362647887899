import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NumberFormatValues } from 'react-number-format';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { State } from 'store';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { registerCloseout } from 'api/SettingsApi';
import { initialCashValues, CashDetails } from 'pages/RegisterTransactionsPage/CashDetailsPopup';
import { getInitialNumberValue } from 'pages/RegisterTransactionsPage/helpers';
import { usePopup } from 'components/popups';
import { checkManagerPin } from 'util/Helpers';
import { callback } from 'models/Misc';
import { ManagerPinPopupProps } from 'components/sharedPopups/ManagerPinPopup';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { openCashDetailsPanel, resetCashDetails } from 'store/actions/CashManagementActions';

type UseCloseoutParams = {
  registerId: number;
  startingBalance: number | null;
  endingBalance: number | null;
  transactionTotal: number;
  onCloseout: callback;
};

type UseCloseoutReturn = {
  loading: boolean;
  disableCloseout: boolean;
  closeout: (managerPassword: string) => Promise<void>;
  reprintLastZReport: () => Promise<void>;
  printZReportOnCloseOut: boolean;
  setPrintZReportOnCloseOut: Dispatch<SetStateAction<boolean>>;
  useBlindRegisterCloseOutFF: boolean;
  mgrPinForDepositFF: boolean;
  cashCounted: NumberFormatValues;
  setCashCounted: Dispatch<SetStateAction<NumberFormatValues>>;
  difference: number;
  newBalance: NumberFormatValues;
  setNewBalance: Dispatch<SetStateAction<NumberFormatValues>>;
  handleClickEnterCashDetails: () => void;
  /** @deprecated Will be managed in Redux */
  cashDetails: CashDetails;
  /** @deprecated Will be managed in Redux */
  setCashDetails: Dispatch<SetStateAction<CashDetails>>;
  deposit: number;
  differenceReason: string;
  setDifferenceReason: Dispatch<SetStateAction<string>>;
  /** @deprecated Will be converted to a drawer */
  toggleCashDetailsPopup: () => void;
  /** @deprecated Will be converted to a drawer */
  isCashDetailsPopupVisible: boolean;
  toggleConfirmPopup: () => void;
  isConfirmPopupVisible: boolean;
  managerPinPopup?: ManagerPinPopupProps;
  isZReportEnabled: boolean;
};

const useCloseoutRegister = ({
  registerId,
  startingBalance,
  endingBalance,
  transactionTotal,
  onCloseout,
}: UseCloseoutParams) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [cashCounted, setCashCounted] = useState(getInitialNumberValue());
  const [newBalance, setNewBalance] = useState(getInitialNumberValue(startingBalance));
  const [differenceReason, setDifferenceReason] = useState('');
  const [cashDetails, setCashDetails] = useState(initialCashValues);

  const deposit = (cashCounted.floatValue ?? 0) - (newBalance.floatValue ?? 0);
  const difference = Number(cashCounted.formattedValue) - (endingBalance || 0);
  const disableCloseout = !cashCounted.value.length || !newBalance.value.length || loading;

  const checkManagerPinForCloseout = async (managerPassword: string): Promise<boolean> => {
    try {
      setLoading(true);
      await checkManagerPin(managerPassword);
      return true;
    } catch (error) {
      dispatch(errorNotification(`${error}`));
      setLoading(false);
      return false;
    }
  };

  const closeoutRegister = async (): Promise<boolean> => {
    if (cashCounted.floatValue !== undefined && newBalance.floatValue !== undefined) {
      try {
        setLoading(true);
        const result = await registerCloseout({
          RegisterId: registerId,
          CashOpening: Number(startingBalance),
          CashEnding: Number(endingBalance),
          CashExpected: Number(transactionTotal),
          CashCounted: cashCounted.floatValue,
          Difference: cashCounted.floatValue - Number(endingBalance),
          Deposit: (cashCounted.floatValue - newBalance.floatValue).toFixed(2),
          SetRegisterBalance: newBalance.floatValue,
          DifferenceReason: differenceReason,
          EntryTypeId: 8, //CloseoutPOS
        });

        const resultId = result.Data && result.Data.length > 0 ? result.Data[0].ResultId : undefined;

        if (resultId === 1) {
          setCashCounted(getInitialNumberValue());
          setNewBalance(getInitialNumberValue());
          setCashDetails(initialCashValues);
          setDifferenceReason('');
          onCloseout();
          dispatch(successNotification('Register closeout successful'));
          setLoading(false);
          return true;
        } else {
          setLoading(false);
          throw new Error();
        }
      } catch {
        dispatch(errorNotification('There was an error closing out the register'));
        setLoading(false);
        return false;
      }
    }

    return false;
  };

  return {
    loading,
    checkManagerPinForCloseout,
    closeoutRegister,
    cashCounted,
    setCashCounted,
    difference,
    differenceReason,
    setDifferenceReason,
    newBalance,
    setNewBalance,
    cashDetails,
    setCashDetails,
    deposit,
    disableCloseout,
  };
};

export const useCloseout = ({
  registerId,
  startingBalance,
  endingBalance,
  transactionTotal,
  onCloseout,
}: UseCloseoutParams): UseCloseoutReturn => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();

  const isZReportEnabled = ldClient?.variation('pos.register.z-report.rollout', false) === true;
  const { printZReport } = usePrintJob();

  const {
    loading: isCloseoutLoading,
    checkManagerPinForCloseout,
    closeoutRegister,
    cashCounted,
    setCashCounted,
    difference,
    differenceReason,
    setDifferenceReason,
    newBalance,
    setNewBalance,
    cashDetails,
    setCashDetails,
    deposit,
    disableCloseout,
  } = useCloseoutRegister({
    registerId,
    startingBalance,
    endingBalance,
    transactionTotal,
    onCloseout,
  });

  const [printZReportOnCloseOut, setPrintZReportOnCloseOut] = useState(true);
  const [managerPinPopup, setManagerPinPopup] = useState<ManagerPinPopupProps | undefined>();

  const { isVisible: isCashDetailsPopupVisible, toggle: toggleCashDetailsPopup } = usePopup();
  const { isVisible: isConfirmPopupVisible, toggle: toggleConfirmPopup } = usePopup();

  const mgrPinForDepositFF = useSelector((state: State) => state.settings.features.MgrPinForDeposit);
  const useBlindRegisterCloseOutFF = useSelector((state: State) => state.settings.features.UseBlindRegisterCloseOut);

  const closeout = async (managerPassword: string) => {
    if (cashCounted.floatValue !== undefined && newBalance.floatValue !== undefined) {
      if (mgrPinForDepositFF) {
        const isManagerPinCheckSuccessful = await checkManagerPinForCloseout(managerPassword);

        if (!isManagerPinCheckSuccessful) {
          return Promise.reject();
        }
      }

      const isCloseoutSuccessful = await closeoutRegister();

      if (isCloseoutSuccessful && isZReportEnabled && printZReportOnCloseOut) {
        await printZReport();
      }
    }
  };

  const handleClickEnterCashDetails = () => dispatch(openCashDetailsPanel());

  const reprintLastZReport = async () => {
    if (mgrPinForDepositFF) {
      setManagerPinPopup({
        onSuccess: async () => {
          await printZReport();
        },
        title: 'Enter Manager PIN to reprint last Z Report',
        hide: () => setManagerPinPopup(undefined),
      });
    } else {
      await printZReport();
    }
  };

  useEffect(() => {
    dispatch(resetCashDetails());
  }, [dispatch]);

  return {
    loading: isCloseoutLoading,
    disableCloseout,
    closeout,
    reprintLastZReport,
    printZReportOnCloseOut,
    setPrintZReportOnCloseOut,
    useBlindRegisterCloseOutFF,
    mgrPinForDepositFF,
    cashCounted,
    setCashCounted,
    difference,
    newBalance,
    setNewBalance,
    handleClickEnterCashDetails,
    cashDetails,
    setCashDetails,
    deposit,
    differenceReason,
    setDifferenceReason,
    toggleCashDetailsPopup,
    isCashDetailsPopupVisible,
    toggleConfirmPopup,
    isConfirmPopupVisible,
    managerPinPopup,
    isZReportEnabled,
  };
};
