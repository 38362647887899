import { useSelector } from 'react-redux';
import { chain } from 'lodash';

import type { State } from 'store';
import { FeePaymentMethod } from 'models/Checkout';
import type { AppliedFeeDonation } from 'models/FeeDonation';

import { cartHasRoundingNew } from 'util/Helpers';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';

import type { Cart } from 'models/Cart';

type UseTotalBreakdownDrawerParams = {
  cart?: Partial<Cart>;
};

export const useTotalBreakdownDrawer = ({ cart }: UseTotalBreakdownDrawerParams) => {
  // Global state
  const isCurbsideRoundDownToNearestFiverEnabled = useSelector(
    (state: State) => state.settings.features.CurbsideRoundDownToNearestFiver
  );
  const isRoundDownToDollarEnabled = useSelector((state: State) => state.settings.features.RoundDownToDollar);
  const isRoundDownToNearestFiverEnabled = useSelector(
    (state: State) => state.settings.features.RoundDownToNearestFiver
  );
  const isRoundDownToQuarterEnabled = useSelector((state: State) => state.settings.features.RoundDownToQuarter);
  const isRoundPercentDiscountsEnabled = useSelector((state: State) => state.settings.features.RoundPercentDiscounts);
  const isRoundToNearestDollarEnabled = useSelector((state: State) => state.settings.features.RoundToNearestDollar);
  const isRoundToNearestNickelEnabled = useSelector((state: State) => state.settings.features.RoundToNearestNickel);
  const isStatefulCheckoutEnabled = useSelector((state: State) => state.settings.features.StatefulCheckout);
  const showLoyaltyAsDiscount = useSelector((state: State) => state.settings.features.LoyaltyAsDiscount);
  const isUseFeesDonationsEnabled = useSelector((state: State) => state.settings.features.UseFeesDonations);

  // Hooks
  const { showDutchiePayPreAuthCheckout } = useDutchiePayPreAuth();

  // Computed values
  const showRounding = cartHasRoundingNew({
    CurbsideRoundDownToNearestFiver: isCurbsideRoundDownToNearestFiverEnabled,
    RoundDownToDollar: isRoundDownToDollarEnabled,
    RoundDownToNearestFiver: isRoundDownToNearestFiverEnabled,
    RoundDownToQuarter: isRoundDownToQuarterEnabled,
    RoundPercentDiscounts: isRoundPercentDiscountsEnabled,
    RoundToNearestDollar: isRoundToNearestDollarEnabled,
    RoundToNearestNickel: isRoundToNearestNickelEnabled,
  });
  const hasNotCalculatedTotalYet = cart?.GrandTotalRounded === 0;
  const hideTotals = isStatefulCheckoutEnabled && hasNotCalculatedTotalYet;
  const totalLoyalty = (cart?.TotalDiscountAndLoyalty ?? 0) - (cart?.TotalDiscount ?? 0);
  const showFeesAndDonations = isUseFeesDonationsEnabled && (cart?.FeesAndDonations ?? 0) !== 0;
  const useUSAG = useSelector((state: State) => state.settings.integrations?.DebitProcessor) === 'usag';
  const appliedDutchiePayFees = chain(cart?.FeesDonations)
    .filter((appliedFee: AppliedFeeDonation) => appliedFee.FeePaymentMethod === FeePaymentMethod.DutchiePay)
    .first()
    .value()?.CashValue;
  const showDutchiePayFee = appliedDutchiePayFees > 0;
  const paymentFee = chain(cart?.FeesDonations)
    .filter(
      (appliedFee: AppliedFeeDonation) =>
        appliedFee.FeePaymentMethod === FeePaymentMethod.PinDebit ||
        appliedFee.FeePaymentMethod === FeePaymentMethod.Credit
    )
    .first()
    .value()?.CashValue;
  const showNonCashAdjustmentFee = paymentFee > 0 && !useUSAG;
  const showCardActivationFee = paymentFee > 0 && useUSAG;
  const showTipAmount = !!cart?.TipAmount;
  const subtotal = cart?.SubTotal;
  const totalDiscount = cart?.TotalDiscount;
  const tax = cart?.Tax;
  const feesAndDonations = cart?.FeesAndDonations;
  const roundingAmount = cart?.RoundingAmount;
  const tipAmount = cart?.TipAmount;
  const grandTotalRounded = cart?.GrandTotalRounded;

  return {
    subtotal,
    totalDiscount,
    tax,
    feesAndDonations,
    roundingAmount,
    tipAmount,
    grandTotalRounded,
    totalLoyalty,
    paymentFee,
    appliedDutchiePayFees,
    hideTotals,
    showLoyaltyAsDiscount,
    showFeesAndDonations,
    showDutchiePayFee,
    showNonCashAdjustmentFee,
    showCardActivationFee,
    showRounding,
    showTipAmount,
    showDutchiePayPreAuthCheckout,
  };
};
