import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { DutchiePayLogo } from 'assets/DutchiePayLogo';
import { Chevron } from 'assets/icons/chevron';
import { LoadingButton } from 'components/buttons';

import { useDutchiePayPreauthButton } from './useDutchiePayPreauthButton';

type DutchiePayPreauthButtonProps = {
  disabled: boolean;
};

export const DutchiePayPreauthButton: FC<DutchiePayPreauthButtonProps> = ({ disabled }) => {
  const { handleClick } = useDutchiePayPreauthButton(); // todo: add loading state

  return (
    <>
      <StyledDutchieButton
        automationId='dutchie-pay-pre-auth-continue-button'
        disabled={disabled}
        dutchiePayThemed
        onClick={handleClick}
      >
        <DutchiePayLogo fill={colors.dutchie.primaryWhite} width={83.2} height={16} />
        <Chevron fill='white' width={16} height={16} />
      </StyledDutchieButton>
    </>
  );
};

const StyledDutchieButton = styled(LoadingButton)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  height: 52px;
  flex: 2;
`;
