import { DatePickerField, InputField as _InputField, SelectField } from 'components/inputs';
import { ConfirmationPopup } from 'components/popups';
import { CustomerDetails, EditAllotmentTypeEnum } from 'models/Customer';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { adjustExternalPatientAllotment } from 'store/actions/CustomerActions';
import styled from 'styled-components';

type EditAllotmentPopupProps = {
  isVisible: boolean;
  hide: () => void;
  customer: CustomerDetails;
  type: EditAllotmentTypeEnum;
};

type EditAllotmentFormValues = {
  licenseNumber: string | undefined;
  newLicenseNumber: string | undefined | null;
  licenseEffectiveStartDate: Date;
  licenseEffectiveEndDate: Date;
  recommendedPlants: number;
  recommendedSmokableQuantity: number;
  flowerOuncesAllowed: number;
  thcOuncesAllowed: number;
  concentrateOuncesAllowed: number;
  infusedOuncesAllowed: number;
  maxConcentrateThcPercentAllowed: number;
  maxFlowerThcPercentAllowed: number;
  hasSalesLimitExemption: string;
};

export const EditExternalPatientAllotmentPopup: FC<EditAllotmentPopupProps> = ({ customer, type, isVisible, hide }) => {
  const dispatch = useDispatch();
  const pendingAllotmentAdjustment = useSelector((state: State) => state.customer.pendingAllotmentAdjustment);
  const formRef = useRef<FormikProps<EditAllotmentFormValues> | null>(null);

  const onSubmit = async (values: EditAllotmentFormValues) => {
    const requestArgs = {
      LicenseNumber: customer.MJStateIDNo,
      NewLicenseNumber: values.licenseNumber,
      LicenseEffectiveStartDate: values.licenseEffectiveStartDate,
      LicenseEffectiveEndDate: values.licenseEffectiveEndDate,
      RecommendedPlants: values.recommendedPlants,
      RecommendedSmokableQuantity: values.recommendedSmokableQuantity,
      FlowerOuncesAllowed: values.flowerOuncesAllowed,
      ThcOuncesAllowed: values.thcOuncesAllowed,
      ConcentrateOuncesAllowed: values.concentrateOuncesAllowed,
      InfusedOuncesAllowed: values.infusedOuncesAllowed,
      MaxConcentrateThcPercentAllowed: values.maxConcentrateThcPercentAllowed,
      MaxFlowerThcPercentAllowed: values.maxFlowerThcPercentAllowed,
      HasSalesLimitExemption: values.hasSalesLimitExemption === 'Yes' ? true : false,
      CustomerType: customer.PatientType,
      PatientId: customer.Guest_id,
    };
    await dispatch(adjustExternalPatientAllotment(requestArgs));
    hide();
  };

  return (
    <ConfirmationPopup
      contentMaxHeight='calc(100vh - 200px)'
      medium
      isVisible={isVisible}
      hide={hide}
      title={`Edit Allotments`}
      confirm={{
        text: 'Save',
        disabled: pendingAllotmentAdjustment,
        onClick: () => {
          formRef.current?.handleSubmit();
        },
      }}
      cancel={{
        text: 'Cancel',
        onClick: hide,
      }}
    >
      <ContentDiv>
        <Formik
          initialValues={
            {
              licenseNumber: customer.MJStateIDNo ?? '',
            } as EditAllotmentFormValues
          }
          onSubmit={(values) => onSubmit(values)}
          innerRef={(instance) => (formRef.current = instance)}
        >
          <Form>
            <AllotmentDiv>
              <InputField type='text' name='licenseNumber' label='MMJ ID #' placeholder='...' />
            </AllotmentDiv>
            <AllotmentDiv>
              <DateField name='licenseEffectiveStartDate' labelText='License Effective Start Date' />
              <DateField name='licenseEffectiveEndDate' labelText='License Effective End Date' />
            </AllotmentDiv>
            <AllotmentDiv>
              <InputField type='number' name='recommendedPlants' label='Recommended Plants' placeholder='...' />
              <InputField
                type='number'
                name='recommendedSmokableQuantity'
                label='Recommended Smokable Quantity'
                placeholder='...'
              />
            </AllotmentDiv>
            <AllotmentDiv>
              <InputField type='number' name='flowerOuncesAllowed' label='Flower Ounces Allowed' placeholder='...' />
              <InputField type='number' name='thcOuncesAllowed' label='THC Ounces Allowed' placeholder='...' />
            </AllotmentDiv>
            <AllotmentDiv>
              <InputField
                type='number'
                name='concentrateOuncesAllowed'
                label='Concentrate Ounces Allowed'
                placeholder='...'
              />
              <InputField type='number' name='infusedOuncesAllowed' label='Infused Ounces Allowed' placeholder='...' />
            </AllotmentDiv>
            <AllotmentDiv>
              <InputField
                type='number'
                name='maxConcentrateThcPercentAllowed'
                label='Max Concentrate THC % Allowed'
                placeholder='...'
              />
              <InputField
                type='number'
                name='maxFlowerThcPercentAllowed'
                label='Max Flower THC % Allowed'
                placeholder='...'
              />
            </AllotmentDiv>
            <AllotmentDiv>
              <StyledSelectField
                name='hasSalesLimitExemption'
                label='Has Sales Limit Exemption'
                placeholder='Select'
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </AllotmentDiv>
          </Form>
        </Formik>
      </ContentDiv>
    </ConfirmationPopup>
  );
};

const ContentDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 2rem 0.5rem;

  & > form {
    width: 100%;
  }
`;

const InputField = styled(_InputField)`
  flex: 2 0;
`;

const StyledSelectField = styled(SelectField)`
  flex: 2 0;
`;

const DateField = styled(DatePickerField)`
  flex: 2 0;
`;

const AllotmentDiv = styled.div`
  display: flex;
  gap: 1rem;
`;
