import { addYears, subYears } from 'date-fns';
import { Range } from 'components/inputs';

type DynamicYearRangeProps = {
  numberOfYearsAhead: number;
  numberOfYearsBack?: number;
};

export const getDynamicYearRange = ({ numberOfYearsAhead, numberOfYearsBack = 0 }: DynamicYearRangeProps): Range => {
  const today = new Date();
  const startYear = subYears(today, numberOfYearsBack).getFullYear();
  const endYear = addYears(today, numberOfYearsAhead).getFullYear();

  return { start: startYear, end: endYear };
};

export const useDynamicYearRange = (props: DynamicYearRangeProps) => {
  return getDynamicYearRange(props);
};
