import React from 'react';
import { PeripheralType } from '@dutchie/capacitor-hardware';

import { ReceiptIcon } from 'assets/icons/settings/ReceiptIcon';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { FulfillmentReceiptPrinterStatusPill } from './components/FulfillmentReceiptPrinterStatusPill';
import { FulfillmentReceiptPrinterSelectListItem } from './components/FulfillmentReceiptPrinterSelectListItem';
import { FulfillmentReceiptPrinterAutoPrintListItem } from './components/FulfillmentReceiptPrinterAutoPrintListItem';
import { FulfillmentReceiptPrinterSettingsProvider } from './components/FulfillmentReceiptPrinterSettingsProvider';
import { FulfillmentReceiptPrinterStatusBanner } from './components/FulfillmentReceiptPrinterStatusBanner';
import { FulfillmentReceiptPrinterTestPrintListItem } from './components/FulfillmentReceiptPrinterTestPrintListItem';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const FulfillmentReceiptPrinterSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  return (
    <>
      <SettingsListItem
        automationId='fulfillment_printer_settings'
        icon={<ReceiptIcon />}
        title='Fulfillment ticket printer'
        subtitle='Settings for fulfillment ticket printer'
        onClick={showDrawer}
        actions={[<FulfillmentReceiptPrinterStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='fulfillment_printer_settings-drawer'
        title='Fulfillment ticket printer'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <FulfillmentReceiptPrinterSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <FulfillmentReceiptPrinterStatusBanner />
            <FulfillmentReceiptPrinterSelectListItem />
            <SettingsDivider />
            <FulfillmentReceiptPrinterAutoPrintListItem />
            <SettingsDivider />
            <FulfillmentReceiptPrinterTestPrintListItem />
          </SettingsCard>
          <AddDeviceListItem deviceType={PeripheralType.receiptPrinter} />
        </FulfillmentReceiptPrinterSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
