import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from 'css/Theme';
import { BarcodeScanEntryResponse } from 'queries/v2/guest/barcode-scan-entry';
import { Button, LoadingButton } from 'components/buttons';
import { Select } from 'components/backoffice/select';
import { MenuItem } from 'components/backoffice/menu-item';
import { ModalContainer, ModalHeader, ModalCloseButton, ModalContent, ModalFooter } from 'components/modals/Modal';
import { Pill } from 'components/misc/Pill';
import { PopupOverlay } from 'components/popups';
import { NewCustomerIcon } from './components/NewCustomerIcon';
import { CheckListItem } from './components/CheckListItem';
import { WarnIcon } from './components/WarnIcon';
import { useScanDetailsPopup } from './useScanDetailsPopup';

import type { SelectChangeEvent } from '@mui/material';

type ScanDetailsPopupProps = {
  barcodeScanEntryResponse: BarcodeScanEntryResponse;
  hide: () => void;
};

export function ScanDetailsPopup(props: ScanDetailsPopupProps) {
  const { hide, barcodeScanEntryResponse } = props;

  const {
    id,
    isMatch,
    isMultiMatch,
    isBanned,
    isSubmitting,
    isCheckable,
    isCreateDisabled,
    isCheckInDisabled,
    showNewCustomerSubtitle,
    showMatchCountSubtitle,
    showStatusAttribute,
    showNicknameAttribute,
    showCustomerTypeAttribute,
    showLastPurchaseAttribute,
    showMedicalIdAttribute,
    showLoyaltyPointsAttribute,
    showDateOfBirthCheck,
    showDriversLicenseCheck,
    showMedicalIdCheck,
    showChecks,
    showCaregiverName,
    dateOfBirthCheckStatus,
    driversLicenseCheckStatus,
    mjStateIdCheckStatus,
    nameText,
    matchCountSubtitleText,
    statusAttributeText,
    statusAttributeVariation,
    nicknameAttributeText,
    customerTypeAttributeText,
    lastTransactionAttributeText,
    mjStateIdNumberAttributeText,
    loyaltyPointsAttributeText,
    dateOfBirthCheckText,
    driversLicenseCheckText,
    mjStateIdCheckText,
    caregiverNameAttributeText,
    customerSelectOptions,
    handleCustomerSelect,
    handleCheckInButtonClick,
    handleCreateProfileButtonClick,
    handleScanAnotherButtonClick,
  } = useScanDetailsPopup({ hide, barcodeScanEntryResponse });

  return (
    <PopupOverlay hide={() => {}}>
      <ModalContainer data-testid='scan-details-popup'>
        <ModalHeader>
          <HeaderContent>
            <HeaderText>
              <Title data-testid='scan-details-popup-title'>Customer check in</Title>
              {showNewCustomerSubtitle && (
                <Subtitle data-testid='scan-details-popup-new-customer-subtitle'>New customer</Subtitle>
              )}
              {showMatchCountSubtitle && (
                <Subtitle data-testid='scan-details-popup-match-count-subtitle'>{matchCountSubtitleText}</Subtitle>
              )}
            </HeaderText>
            {isMultiMatch && (
              <StyledSelect
                value={String(id)}
                renderValue={(value: unknown) => {
                  const selectedOption = customerSelectOptions.find((option) => option.value === value);
                  return (
                    <>
                      <SelectRenderValueBold>{selectedOption?.displayValueName}</SelectRenderValueBold>
                      {selectedOption?.displayValueLastTransaction}
                    </>
                  );
                }}
                onChange={(event: SelectChangeEvent<unknown>) => handleCustomerSelect(String(event.target.value))}
                ddActionName='Select customer (ScanDetailsPopup)'
              >
                {customerSelectOptions.map(({ value, displayValueName, displayValueLastTransaction }) => (
                  <MenuItem value={value}>
                    {displayValueName}
                    {displayValueLastTransaction}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            <ModalCloseButton onClick={hide} data-dd-action-name='Close (ScanDetailsPopup)' />
          </HeaderContent>
        </ModalHeader>
        <ModalContent>
          <Content>
            {!isMatch && !isCheckable ? (
              <NewCustomerContainer>
                <NewCustomerTitle>New customer</NewCustomerTitle>
                <NewCustomerIcon />
                <NewCustomerMessage>Welcome your new customer!</NewCustomerMessage>
              </NewCustomerContainer>
            ) : (
              <>
                <Details>
                  <Name empty={!nameText} banned={isBanned}>
                    {nameText || 'Name not added'}
                  </Name>
                  {isMatch ? (
                    <>
                      <ProfileLink
                        to={{ pathname: 'edit-customer', search: `?id=${id}` }}
                        data-dd-action-name='Edit profile (ScanDetailsPopup)'
                      >
                        Edit profile
                      </ProfileLink>
                      <Attributes>
                        {showStatusAttribute && (
                          <Attribute>
                            <AttributeLabel>Status:</AttributeLabel>
                            <AttributeValue>
                              <Pill text={statusAttributeText} variation={statusAttributeVariation} />
                            </AttributeValue>
                          </Attribute>
                        )}
                        {showNicknameAttribute && (
                          <Attribute>
                            <AttributeLabel>Nickname:</AttributeLabel>
                            <AttributeValue>{nicknameAttributeText}</AttributeValue>
                          </Attribute>
                        )}
                        {showCustomerTypeAttribute && (
                          <Attribute>
                            <AttributeLabel>Customer type:</AttributeLabel>
                            <AttributeValue>{customerTypeAttributeText}</AttributeValue>
                          </Attribute>
                        )}
                        {showLastPurchaseAttribute && (
                          <Attribute>
                            <AttributeLabel>Last purchase:</AttributeLabel>
                            <AttributeValue>{lastTransactionAttributeText}</AttributeValue>
                          </Attribute>
                        )}
                        {showMedicalIdAttribute && (
                          <Attribute>
                            <AttributeLabel>MJID number:</AttributeLabel>
                            <AttributeValue>{mjStateIdNumberAttributeText}</AttributeValue>
                          </Attribute>
                        )}
                        {showLoyaltyPointsAttribute && (
                          <Attribute>
                            <AttributeLabel>Loyalty points:</AttributeLabel>
                            <AttributeValue>{loyaltyPointsAttributeText}</AttributeValue>
                          </Attribute>
                        )}
                        {showCaregiverName && (
                          <Attribute>
                            <AttributeLabel>Caregiver name:</AttributeLabel>
                            <AttributeValue>{caregiverNameAttributeText}</AttributeValue>
                          </Attribute>
                        )}
                      </Attributes>
                    </>
                  ) : (
                    <NewCustomerAttributes>
                      <NewCustomerIcon />
                      <NewCustomerMessage>Welcome your new customer!</NewCustomerMessage>
                    </NewCustomerAttributes>
                  )}
                </Details>
                <Checklist center={showDateOfBirthCheck && showDriversLicenseCheck && showMedicalIdCheck}>
                  {showChecks ? (
                    <>
                      {showDateOfBirthCheck && (
                        <CheckListItem
                          title='Birthday'
                          subtitle={dateOfBirthCheckText}
                          status={dateOfBirthCheckStatus}
                          failText='Underage'
                        />
                      )}
                      {showDriversLicenseCheck && (
                        <CheckListItem
                          title='ID Expiration'
                          subtitle={driversLicenseCheckText}
                          status={driversLicenseCheckStatus}
                          failText='Expired'
                        />
                      )}
                      {showMedicalIdCheck && (
                        <CheckListItem
                          title='MJID Expiration'
                          subtitle={mjStateIdCheckText}
                          status={mjStateIdCheckStatus}
                          failText='Expired'
                        />
                      )}
                    </>
                  ) : (
                    <ChecksPlaceholder>
                      <ChecksPlaceholderIcon />
                      <ChecksPlaceholderTitle>Incomplete profile</ChecksPlaceholderTitle>
                      <ChecksPlaceholderText>Update customer profile for faster check in.</ChecksPlaceholderText>
                    </ChecksPlaceholder>
                  )}
                </Checklist>
              </>
            )}
          </Content>
        </ModalContent>
        <ModalFooter>
          <Button cta tertiary fullWidth onClick={handleScanAnotherButtonClick} ddActionName='Scan another ID (ScanDetailsPopup)'>
            Scan another ID
          </Button>
          {isMatch ? (
            <LoadingButton
              cta
              fullWidth
              loading={isSubmitting}
              disabled={isCheckInDisabled}
              onClick={handleCheckInButtonClick}
              ddActionName='Check in (ScanDetailsPopup)'
            >
              Check in
            </LoadingButton>
          ) : (
            <LoadingButton
              cta
              fullWidth
              loading={isSubmitting}
              disabled={isCreateDisabled}
              onClick={handleCreateProfileButtonClick}
              ddActionName='Create new profile (ScanDetailsPopup)'
            >
              Create new profile
            </LoadingButton>
          )}
        </ModalFooter>
      </ModalContainer>
    </PopupOverlay>
  );
}

const SelectRenderValueBold = styled.span`
  font-weight: 600;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  min-width: calc(40% - 5px);
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

const Subtitle = styled.div`
  color: ${colors.dutchie.gray80};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  margin-top: 5px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
`;

const Content = styled.div`
  display: flex;
  width: 939px;
`;

const Details = styled.div`
  border-right: 1px solid ${colors.dutchie.gray20};
  width: 40%;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const Checklist = styled.div<{ center?: boolean }>`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
`;

const ChecksPlaceholder = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.dutchie.paleYellow};
  border: 1px solid #ffe83e;
  color: ${colors.dutchie.crispyUmber};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 8px;
`;

const ChecksPlaceholderIcon = styled(WarnIcon)`
  height: 40px;
  width: 40px;
  margin-bottom: 12px;
`;

const ChecksPlaceholderTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
`;

const ChecksPlaceholderText = styled.div`
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 400;
`;

const Name = styled.span<{ empty?: boolean; banned?: boolean }>`
  color: ${colors.dutchie.almostBlack};
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  margin-bottom: 8px;
  ${({ empty }) => empty && `color: ${colors.dutchie.gray70};`}
  ${({ banned }) => banned && `color: ${colors.dutchie.flora};`}
`;

const ProfileLink = styled(Link)`
  color: ${colors.dutchie.blue};
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
`;

const Attributes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 2rem;
`;

const Attribute = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AttributeLabel = styled.span`
  color: ${colors.dutchie.gray90};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

const AttributeValue = styled.span`
  color: ${colors.dutchie.gray90};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
`;

const NewCustomerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px;
  gap: 16px;
`;

const NewCustomerTitle = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
`;

const NewCustomerMessage = styled.span`
  color: ${colors.dutchie.blue80};
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
`;

const NewCustomerAttributes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  gap: 8px;
`;
