import * as Cookies from 'es-cookie';
import { isAndroid } from 'util/hooks';
import { Storage } from '@capacitor/storage';

export enum PersistedValueKey {
  /**
   * The key for the hardware ID on a device or browser. Used for assigning a register to a device.
   *
   * The key is 'LeafLogixHardwareData' rather than something like 'hardware_id' (which would make more sense)
   * because that's how previous versions stored the ID and we don't want to break functionality for devices
   * that currently have it stored this way.
   */
  hardwareId = 'LeafLogixHardwareData',
}

/**
 * Sets a key/value pair in Android SharedPreferences when running in Android.
 * Sets it in a cookie when running in web browser.
 */
export const setPersistedValue = async (
  key: PersistedValueKey,
  value: string,
  attributes?: Cookies.CookieAttributes
): Promise<void> => {
  if (isAndroid) {
    await Storage.set({ key, value });
  } else {
    Cookies.set(key, value, attributes);
  }
};

/**
 * Returns a value matching the key from Android SharedPreferences when running in Android.
 * Returns the cookie value when running in web browser.
 */
export const getPersistedValue = async (key: PersistedValueKey): Promise<string | null | undefined> => {
  if (isAndroid) {
    const { value } = await Storage.get({ key });
    return value;
  } else {
    return Cookies.get(key);
  }
};
