import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { ReactComponent as RightArrow } from 'assets/icons/pointing-arrow-right.svg';
import { DatePicker } from 'components/inputs/DatePicker';
import { Label } from 'components/text';

export type DateRangeFieldProps = {
  startName: string;
  endName: string;
  label?: string;
  isFieldModified?: (wasModified: boolean) => void;
};

export const DateRangeField: FC<DateRangeFieldProps> = ({ startName, endName, label, isFieldModified }) => {
  const { setFieldValue } = useFormikContext();
  const [startField, startMeta] = useField(startName);
  const [endField, endMeta] = useField(endName);
  const hasError = (startMeta.touched && startMeta.error) || (endMeta.touched && endMeta.error);

  const onFieldChange = (fieldName: string, val: Date | null) => {
    setFieldValue(fieldName, val);
    isFieldModified?.(true);
  };

  return (
    <Container title={hasError ? startMeta.error || endMeta.error : ''}>
      <Label hasError={Boolean(hasError)}>{label}</Label>
      <DatePickerContainer>
        <DatePicker
          automationId='dutchie-date-range-picker_0'
          selected={startField.value}
          onChange={(val) => onFieldChange(startField.name, val)}
          mode='datetime'
          popperPlacement='bottom-start'
          selectsStart
          startDate={startField.value}
          endDate={endField.value}
        />
        <StyledRightArrow />
        <DatePicker
          automationId='dutchie-date-range-picker_1'
          selected={endField.value}
          onChange={(val) => onFieldChange(endField.name, val)}
          mode='datetime'
          popperPlacement='bottom-end'
          selectsEnd
          startDate={startField.value}
          endDate={endField.value}
          minDate={startField.value}
        />
      </DatePickerContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 1rem;
`;

const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    margin-bottom: 0;
    flex: 1;
  }
`;

const StyledRightArrow = styled(RightArrow)`
  margin: 0 0.5rem;
`;
