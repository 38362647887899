import styled from 'styled-components';
import { colors } from 'css/Theme';

import { LoadingButton } from 'components/buttons';

export const ActionButton = styled(LoadingButton)`
  gap: 0.5rem;
  height: 2.75rem;
  padding: 0 1rem;
  white-space: nowrap;

  & svg {
    flex: 0 0 1rem;
  }
`;

export const AddItemsButton = styled(ActionButton)`
  color: ${colors.dutchie.blue};
  border-color: ${colors.dutchie.blue};

  & svg {
    path {
      fill: ${colors.dutchie.blue};
    }
  }

  &:hover {
    color: ${colors.dutchie.blue70};
    border-color: ${colors.dutchie.blue70};
  }
`;

export const ItemCount = styled.div`
  color: ${colors.dutchie.primaryWhite};
  background: ${colors.dutchie.opal90};
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
  padding: 1px 12px;
  border-radius: 100px;
`;
