import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store';
import { LabelPrinter, Peripheral } from '@dutchie/capacitor-peripheral';
import { saveUserSettings } from 'store/actions/SettingsActions';
import { SectionGridContainer, SectionHeader, SectionContent, SelectContainer } from '../../SettingPageComponents';
import { Label } from 'components/text';
import { Select, Switch } from 'components/inputs';
import { LocalLabelPrinterPicker } from './LocalLabelPicker';
import { NetworkLabelPrinterPicker } from './NetworkLabelPicker';
import { isAndroid } from 'util/hooks';
import { switchPrinterSettings } from '../utils';
import { logger, customEventKeys } from 'util/logger';
import {
  AutoConnectedToPrinterContext,
  SearchLabelPrintersContext,
  UserChangedPrinterSettingsContext,
} from 'util/logger/types/printing';
import { PrintJob } from 'models/Printing';
import { getSearchLabelPrintersDescription } from 'util/logger/helpers/printing';

export const LabelPrinterPicker: FC = () => {
  const dispatch = useDispatch();
  const { labels, labelPrinters } = useSelector((state: State) => state.settings);
  const { selectedLabel } = useSelector((state: State) => state.settings.userSettings);
  const { autoPrintLabels } = useSelector((state: State) => state.settings.userSettings);
  const { useConnectedLabelPrinter } = useSelector((state: State) => state.settings.userSettings);
  const [connectedLabelPrinters, setConnectedLabelPrinters] = useState<LabelPrinter[]>([]);

  useEffect(() => {
    const setLocalPrinters = async () => {
      if (isAndroid) {
        const { results } = await Peripheral.searchLabelPrinters();
        setConnectedLabelPrinters(results);

        logger.info<SearchLabelPrintersContext>(getSearchLabelPrintersDescription(results, false), {
          key: customEventKeys.printing.local.label.search,
          availablePrinters: results,
          userInitiated: false,
        });
      }
    };

    setLocalPrinters();
  }, []);

  const handleSwitchLabelPrinterTypes = (useConnectedLabelPrinter: boolean) => {
    const newPrinterSettings = switchPrinterSettings(useConnectedLabelPrinter, connectedLabelPrinters, labelPrinters);

    const newUserSettings = newPrinterSettings
      ? {
          useConnectedLabelPrinter,
          selectedLabelPrinter: newPrinterSettings,
        }
      : { useConnectedLabelPrinter };

    dispatch(saveUserSettings(newUserSettings));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled label printer to ${useConnectedLabelPrinter ? 'local' : 'network'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.LABELS,
      printerSettings: { isLocalPrinter: useConnectedLabelPrinter },
    });

    if (newPrinterSettings) {
      logger.info<AutoConnectedToPrinterContext>(`auto-connected to ${newPrinterSettings.LocalPrinter ? 'local' : 'network'} label printer ${newPrinterSettings.PrinterId}`, {
        key: customEventKeys.printing.autoConnected,
        job: PrintJob.LABELS,
        type: newPrinterSettings.LocalPrinter ? 'local' : 'network',
        autoSelectedPrinterId: newPrinterSettings.PrinterId,
      });
    }
  };

  const handleSwitchAutoPrintLabels = (autoPrintLabels: boolean) => {
    dispatch(saveUserSettings({ autoPrintLabels }));

    logger.info<UserChangedPrinterSettingsContext>(`user toggled auto printing for label printer ${autoPrintLabels ? 'ON' : 'OFF'}`, {
      key: customEventKeys.printing.userChangedPrinterSettings,
      job: PrintJob.LABELS,
      printerSettings: { isAutoPrintEnabled: autoPrintLabels },
    });
  };

  const changeLabel = (value: string) => {
    const selectedLabel = labels.find((item) => item.id === parseInt(value, 10));
    if (selectedLabel) {
      dispatch(saveUserSettings({ selectedLabel }));
    }
  };

  const options = labels.map((item) => ({ value: item.id, label: item.LabelDescription, key: item.id }));

  const printerPicker =
    useConnectedLabelPrinter && isAndroid ? (
      <LocalLabelPrinterPicker
        connectedLabelPrinters={connectedLabelPrinters}
        setConnectedLabelPrinters={setConnectedLabelPrinters}
      />
    ) : (
      <NetworkLabelPrinterPicker />
    );

  return (
    <SectionGridContainer>
      <SectionHeader>Labels</SectionHeader>
      <SectionContent>
        <Switch label='Auto Print Labels' onChange={handleSwitchAutoPrintLabels} checked={autoPrintLabels} />
        <SelectContainer>
          <Label>Default Label</Label>
          <Select
            placeholder='Select option'
            defaultValue={selectedLabel?.id}
            onChange={changeLabel}
            options={options}
            errorMessage={!selectedLabel && autoPrintLabels && 'Please select a default label'}
          />
        </SelectContainer>

        {isAndroid && (
          <Switch
            label='Use Local Label Printer'
            onChange={handleSwitchLabelPrinterTypes}
            checked={useConnectedLabelPrinter}
          />
        )}
        {printerPicker}
      </SectionContent>
    </SectionGridContainer>
  );
};
