import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  showReleasePopup,
  showNotifyCustomerPopup,
  showSetStatusPopup,
  showSetTransactionReferencePopup,
  showVisitReasonPopup,
  showCancelTransactionPopup,
} from 'store/actions/PopupsActions';

import type { State } from 'store';
import type { CheckedInGuest } from 'models/Guest';
import type { OverflowMenuOption } from 'components/menus/OverflowMenu';

type KanbanOverflowProps = {
  guest: CheckedInGuest;
  hasAssignedRegister: boolean;
};

export const useKanbanOverflowOptions = ({ guest, hasAssignedRegister }: KanbanOverflowProps): OverflowMenuOption[] => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isUseSproutEnabled = useSelector((state: State) => state.settings.features.UseSprout);
  const isApiPreOrderNotifyEnabled = useSelector((state: State) => state.settings.features.ApiPreOrderNotify);
  const isCustomerVisitReasonEnabled = useSelector((state: State) => state.settings.features.CustomerVisitReason);

  const divider = { divider: true };

  const profile = {
    text: 'Profile',
    onClick: () => history.push(`/edit-customer?id=${guest.Guest_id}`),
  };

  const release = {
    text: 'Release',
    onClick: () => dispatch(showReleasePopup({ guest })),
    disabled: guest.RegisterId === 0,
  };

  const notifyCustomer = {
    text: 'Notify',
    onClick: () => dispatch(showNotifyCustomerPopup({ guest })),
  };

  const changeStatus = {
    text: 'Change status',
    onClick: () => dispatch(showSetStatusPopup({ guest })),
  };

  const transactionReference = {
    text: 'Transaction reference',
    onClick: () => dispatch(showSetTransactionReferencePopup({ guest })),
    disabled: guest.RegisterId === 0,
  };

  const visitReason = {
    text: 'Visit reason',
    onClick: () => dispatch(showVisitReasonPopup({ shipmentId: guest.ShipmentId })),
  };

  const cancelTransaction = {
    text: 'Cancel transaction',
    danger: true,
    onClick: () => dispatch(showCancelTransactionPopup({ guest })),
  };

  const actions: OverflowMenuOption[] = [profile];

  if (hasAssignedRegister) {
    actions.push(release);
  }

  if (isUseSproutEnabled || isApiPreOrderNotifyEnabled) {
    actions.push(notifyCustomer);
  }

  if (isCustomerVisitReasonEnabled) {
    actions.push(visitReason);
  }

  actions.push(changeStatus, transactionReference, divider, cancelTransaction);

  return actions;
};
