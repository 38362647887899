import React from 'react';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { useAssignRegister } from './hooks/useAssignRegister';
import { AssignRegisterSettingsProvider } from './components/AssignRegisterSettingsProvider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { AssignRegisterSelectListItem } from './components/AssignRegisterSelectListItem';
import { RegisterIcon } from 'assets/icons/navigation/register';
import { colors } from 'css/Theme';

export const AssignRegisterSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();
  const { showAssignRegister } = useAssignRegister();

  if (!showAssignRegister) {
    return null;
  }

  return (
    <>
      <SettingsListItem
        icon={<RegisterIcon color={colors.dutchie.black} />}
        automationId='assign_register_settings'
        title='Default register'
        subtitle={'Assign a default register for this device'}
        onClick={showDrawer}
      />
      <SettingsBottomDrawerForm
        automationId='assign_register_settings_drawer'
        title='Default register'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <AssignRegisterSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <AssignRegisterSelectListItem />
          </SettingsCard>
        </AssignRegisterSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
