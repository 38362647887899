import { useCallback, useMemo } from 'react';

import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';

import type { CartDetails } from 'queries/v2/cart/types';

export const useAllotmentChecks = (cart?: CartDetails) => {
  const dispatch = useAppDispatch();

  const daysSupplyRemaining = useAppSelector((state) => state.customer.details?.DaysSupplyRemaining);
  const isDaysSupplyEnabled = useIsDaysSupplyFeatureEnabled();
  const isMississippi = useAppSelector((state) => state.settings.locationSettings?.State.toLowerCase() === 'ms');

  const allotment = useMemo(() => cart?.allotment, [cart]);
  const cartItems = useMemo(() => cart?.cartItems ?? [], [cart]);
  const daysSupplyInCart = useMemo(
    () =>
      cartItems.reduce(
        (value, cartItem) =>
          value + cartItem.quantitySelected * (Number(cartItem.daysSupply) !== 0 ? Number(cartItem.daysSupply) : 0),
        0.0
      ),
    [cartItems]
  );
  const hasCannabisItemInCart = useMemo(
    () => cartItems.some((item) => item.cannabisProduct === 'Yes') ?? false,
    [cartItems]
  );

  const validateAllotment = useCallback(() => {
    // Handle days supply and allotment warnings
    if (!isDaysSupplyEnabled && !isMississippi) {
      if (allotment && allotment.error) {
        dispatch(errorNotification(`Error loading allotment: ${allotment.errorMessage}`));
      } else if (hasCannabisItemInCart && allotment && allotment.totalRemaining < 0) {
        dispatch(warningNotification(`Patient allotment exceeded by ${Math.abs(allotment.totalRemaining)}g.`));
      }
    } else if (isDaysSupplyEnabled) {
      if (Number.isFinite(daysSupplyInCart) && Number.isFinite(daysSupplyRemaining)) {
        if (daysSupplyRemaining && daysSupplyInCart > daysSupplyRemaining) {
          dispatch(
            warningNotification(`Patient days supply exceeded by ${Math.abs(daysSupplyInCart - daysSupplyRemaining)}.`)
          );
        }
      }
    }
  }, [
    allotment,
    daysSupplyInCart,
    daysSupplyRemaining,
    dispatch,
    hasCannabisItemInCart,
    isDaysSupplyEnabled,
    isMississippi,
  ]);

  return {
    validateAllotment,
  };
};
