import React from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';

type StyledKeyDisplayProps = {
  isAndroid?: boolean;
};

type KeyProps = {
  value: string;
  testid?: string;
  onClick: () => void;
  children?: React.ReactNode;
  isAndroid?: boolean;
};

export const Key = ({ isAndroid = false, testid, value, onClick, children }: KeyProps): JSX.Element => {
  const [isPressed, setIsPressed] = React.useState<boolean>(false);
  const pressedStyles = isPressed ? { backgroundColor: '#3b4e5e' } : {};
  const clearKeyStyles = ['Clear', 'Enter'].includes(value) ? { fontSize: 20 } : {};

  return (
    <StyledKeypadKey
      isAndroid={isAndroid}
      data-testid={testid}
      onTouchCancel={() => setIsPressed(false)}
      onTouchEnd={() => setIsPressed(false)}
      onTouchStart={() => setIsPressed(true)}
      onMouseLeave={() => setIsPressed(false)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      style={{ ...pressedStyles, ...clearKeyStyles }}
      key={value}
      onClick={() => onClick()}
    >
      {!children && value}
      {children}
    </StyledKeypadKey>
  );
};

const StyledKeypadKey = styled.div<StyledKeyDisplayProps>`
  width: 30%;
  height: ${(props) => (props.isAndroid ? '56px' : '72px')};
  background: transparent;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.isAndroid ? '18px' : '24px')};
  font-weight: 600;
  line-height: ${(props) => (props.isAndroid ? '22px' : '28px')};
  color: ${colors.dutchie.primaryWhite};
  margin-bottom: ${(props) => (props.isAndroid ? '14px' : '16px')};
  border: 1px solid ${colors.dutchie.primaryWhite};
`;
