import React from 'react';

import { colors } from 'css/Theme';

type RecreationalIconProps = {
  fill?: string;
  strokeWidth?: number;
  width?: number;
  height?: number;
  className?: string;
};

export const RecreationalIcon = ({
  fill = colors.dutchie.almostBlack,
  strokeWidth = 0.9,
  width = 16,
  height = 16,
  className,
}: RecreationalIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ flexBasis: `${width}px` }}
      viewBox='0 0 12 12'
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_1822_17976)'>
        <path
          d='M2.83594 6C2.83594 6.83916 3.16929 7.64395 3.76267 8.23734C4.35605 8.83069 5.16084 9.16406 6 9.16406C6.83916 9.16406 7.64395 8.83069 8.23734 8.23734C8.83069 7.64395 9.16406 6.83916 9.16406 6C9.16406 5.16084 8.83069 4.35605 8.23734 3.76267C7.64395 3.16929 6.83916 2.83594 6 2.83594C5.16084 2.83594 4.35605 3.16929 3.76267 3.76267C3.16929 4.35605 2.83594 5.16084 2.83594 6Z'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6 1.42969V0.726562'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M9.23145 2.76834L9.72879 2.271'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M10.5703 6H11.2734'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M9.23145 9.23145L9.72879 9.72879'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6 10.5703V11.2734'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M2.76834 9.23145L2.271 9.72879'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M1.42969 6H0.726562'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M2.76834 2.76834L2.271 2.271'
          stroke={fill}
          stroke-width={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1822_17976'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
