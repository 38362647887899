import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getPatientPolicy, setPatientPolicy } from 'store/actions/CustomerActions';
import { Policy } from './Policy';
import { State } from 'store';
import { SignatureCanvas, SignatureData, checkIsValidSignature } from 'components/misc';
import { Button as _Button } from 'components/buttons';
import { Loader } from 'components/backoffice/loader';
import { DisplayDate } from 'components/text';
import { colors } from 'css/Theme';

const ButtonsContainerDiv = styled.div`
  display: block;
  margin-top: 16px;

  button + button {
    margin-left: 16px;
  }
`;

const SignatureDiv = styled.div`
  width: 500px;
  height: 175px;
  border-bottom: 1px solid ${colors.black};
  .view-policy {
    &__canvas {
      height: 100%;
      width: 100%;
    }
  }
`;

const Button = styled(_Button)`
  margin-left: 10px;
  height: 47px;
`;
const SpinnerWrapper = styled.div`
  text-align: center;
`;

type JournalProps = {
  className?: string;
  guestId?: number;
  disabled?: boolean;
};

export const PatientPolicy: FC<JournalProps> = ({ className, guestId, disabled }) => {
  const policyAgreement = useSelector((state: State) => state.customer.patientPolicy);
  const [signatureData, setSignatureData] = useState<SignatureData>();
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (guestId) {
      dispatch(getPatientPolicy({ AcctId: guestId }));
    }
  }, [dispatch, guestId]);

  const onClear = () => {
    setSignatureData(undefined);
    setIsValid(false);
  };

  const onSubmit = (accepted: boolean) => {
    if (guestId) {
      dispatch(
        setPatientPolicy({
          guest_id: guestId,
          signature64: accepted ? signatureData?.imgDataURI.split(',')[1] ?? '' : '',
          wasAccepted: accepted,
        })
      );
    }
  };

  if (!policyAgreement) {
    return (
      <SpinnerWrapper>
        <Loader variant='black' size='2x' />
      </SpinnerWrapper>
    );
  }

  const acknowledgedDate = policyAgreement.DateAcknowledged ? new Date(policyAgreement.DateAcknowledged) : undefined;
  const expirationDate = policyAgreement.ExpirationDate ? new Date(policyAgreement.ExpirationDate) : undefined;

  return (
    <>
      <Policy className={className} policy={policyAgreement} />
      {policyAgreement.IsValid && (
        <>
          <p>
            Date {policyAgreement.WasAccepted ? 'Accepted' : 'Declined'}: <DisplayDate date={acknowledgedDate} />
          </p>
          <p>
            Expires: <DisplayDate date={expirationDate} />
          </p>
          <p>Witness: {policyAgreement.Witness}</p>
          {policyAgreement.SignatureBase64 && (
            <img alt='policySignature' src={`data:image/png;base64, ${policyAgreement.SignatureBase64}`} />
          )}
        </>
      )}
      {!policyAgreement.IsValid && (
        <>
          <SignatureDiv>
            <SignatureCanvas
              className='view-policy__canvas'
              data={signatureData?.pointSet}
              onChange={(data) => {
                setSignatureData(data);
                setIsValid(checkIsValidSignature(data.pointSet));
              }}
            />
          </SignatureDiv>
          <ButtonsContainerDiv>
            <Button onClick={() => onSubmit(true)} disabled={!isValid || disabled}>
              Accept
            </Button>
            <Button onClick={() => onSubmit(false)} disabled={disabled} secondary>
              Reject
            </Button>
            <Button secondary onClick={onClear}>
              Clear
            </Button>
          </ButtonsContainerDiv>
        </>
      )}
    </>
  );
};
