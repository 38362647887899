import React from 'react';

// utils
import { useAppDispatch, useAppSelector } from 'util/hooks';

// redux
import { openCustomerHistoryPanel } from 'store/actions/CartActions';

// global components
import { Container, Header, Title, StyledLinkButton } from 'components/cart/TotalsSidebarSection.styles';

// enums
import { CustomerHistoryPanelTabs } from 'components/cart/CustomerHistoryPanel/CustomerHistoryPanel';

export type PurchaseHistoryProps = {
  isLoading?: boolean;
};

export function useShowPurchaseHistory() {
  const isAnonymous = useAppSelector((state) => state.customer.details?.IsAnonymous) ?? true;
  const LastPurchase = useAppSelector((state) => state.customer.details?.LastPurchase);

  return !isAnonymous && !!LastPurchase;
}

export function PurchaseHistory(props: PurchaseHistoryProps) {
  const { isLoading } = props;

  const dispatch = useAppDispatch();
  const showPurchaseHistory = useShowPurchaseHistory();

  const handleViewButtonClick = () => {
    dispatch(openCustomerHistoryPanel({ tab: CustomerHistoryPanelTabs.Products }));
  };

  if (!showPurchaseHistory) {
    return null;
  }

  return (
    <Container showBottomBorder gap='0.5rem' data-testid='purchase-history-container'>
      <Header style={{ padding: '0.5rem 0' }}>
        <Title>Purchase history</Title>
        <StyledLinkButton onClick={handleViewButtonClick} label='View' disabled={isLoading} />
      </Header>
    </Container>
  );
}
