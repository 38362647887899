import React from 'react';

import { colors } from 'css/Theme';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const BluetoothIcon = ({ width = 16, height = 16, color = colors.dutchie.gray100 }: IconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 16 16' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.9959 0.0406132C8.1952 -0.0419367 8.42459 0.00369303 8.57712 0.156225L12.2438 3.82289C12.4521 4.03117 12.4521 4.36886 12.2438 4.57714L8.95425 7.86668L12.2438 11.1562C12.4521 11.3645 12.4521 11.7022 12.2438 11.9105L8.57712 15.5771C8.42459 15.7297 8.1952 15.7753 7.9959 15.6928C7.79661 15.6102 7.66667 15.4157 7.66667 15.2V9.15426L4.91046 11.9105C4.70218 12.1188 4.36449 12.1188 4.15621 11.9105C3.94793 11.7022 3.94793 11.3645 4.15621 11.1562L7.44575 7.86668L4.15621 4.57714C3.94793 4.36886 3.94793 4.03117 4.15621 3.82289C4.36449 3.61461 4.70218 3.61461 4.91046 3.82289L7.66667 6.5791V0.533349C7.66667 0.317636 7.79661 0.123163 7.9959 0.0406132ZM8.73333 9.15426L11.1124 11.5333L8.73333 13.9124V9.15426ZM8.73333 6.5791V1.82093L11.1124 4.20002L8.73333 6.5791Z'
        fill={color}
      />
    </svg>
  );
};
