import { TableProps } from '../TableProps';
import { DataMatrixRow, memoizedCalculateDataMatrix } from './calculateDataMatrix';
import { toggleSortDetails } from './sort';

export type State<ItemType> = {
  props: TableProps<ItemType>;
  dataMatrix: DataMatrixRow<ItemType>[];
  page: number;
  pageSize: number;
  sortColumn: number | null;
  sortDirection: 'asc' | 'desc';
};

export type Action<ItemType> =
  | { type: 'updateProps'; props: TableProps<ItemType> }
  | { type: 'nextPage' }
  | { type: 'previousPage' }
  | { type: 'goToPage'; page: number }
  | { type: 'sortByColumn'; columnIndex: number }
  | { type: 'setPageSize'; pageSize: number };

export const withCalculatedData = <ItemType>(state: State<ItemType>): State<ItemType> => ({
  ...state,
  dataMatrix: memoizedCalculateDataMatrix(
    state.props.data,
    state.props.columns,
    state.pageSize,
    state.page,
    state.sortColumn === null
      ? null
      : {
          column: state.sortColumn,
          direction: state.sortDirection,
          customSort: state.props.columns.find((_col, index) => index === state.sortColumn)?.customSort,
        },
    state.props.selected
  ),
});

export const reducer = <ItemType>(state: State<ItemType>, action: Action<ItemType>) => {
  switch (action.type) {
    case 'updateProps':
      return withCalculatedData({ ...state, props: action.props });
    case 'nextPage':
      return withCalculatedData({ ...state, page: state.page + 1 });
    case 'previousPage':
      return withCalculatedData({ ...state, page: state.page - 1 });
    case 'goToPage':
      return withCalculatedData({ ...state, page: action.page });
    case 'sortByColumn':
      return withCalculatedData({
        ...state,
        ...toggleSortDetails(action.columnIndex, state.sortColumn, state.sortDirection),
      });
    case 'setPageSize':
      return withCalculatedData({
        ...state,
        pageSize: action.pageSize,
      });
    default:
      return state;
  }
};

export const defaultState: Omit<State<unknown>, 'props'> = {
  dataMatrix: [],
  page: 1,
  pageSize: 25,
  sortColumn: null,
  sortDirection: 'asc',
};
