import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { State } from 'store';

import { successNotification } from 'store/actions/NotificationsActions';
import { showProductBeingAddedPastUsedByDatePopup } from 'store/actions/PopupsActions';

import { LoadingButton } from 'components/buttons';
import { useAddQtyFromProduct } from 'util/hooks';
import { useProductSearchPricing } from 'util/hooks/useProductSearchPricing';
import { useMETRCUseByDateCheck } from 'pages/CartPage/hooks/useMETRCUseByDateCheck';
import { getProductId } from 'util/helpers/products/getProductId';

import type { ProductSearchResult } from 'queries/v2/product/types';

type OrderActionsAddToCartProps = {
  product: ProductSearchResult;
  closeProductPreview?: () => void;
  count: number;
  usesPricingTiers?: boolean;
};

export const OrderActionsAddToCart: FC<OrderActionsAddToCartProps> = ({
  product,
  closeProductPreview,
  count,
  usesPricingTiers,
}) => {
  const cartLoading = useSelector((state: State) => state.cart.loading);
  const itemsLoading = useSelector((state: State) => state.cartItems.loading);
  const dispatch = useDispatch();

  const { getPrice } = useProductSearchPricing();

  // Create the product/cartItem id and look up both
  const id = getProductId(product);

  const unitPrice = getPrice(product);
  const total = unitPrice * count;
  const isLoading = itemsLoading[id] || cartLoading;

  const { showUseByDateAlert } = useMETRCUseByDateCheck({ product });

  const addQtyToCart = useAddQtyFromProduct();

  const addButtonId = useMemo(() => `addToCart_${product.productId}`, [product]);

  const addProductToCart = useCallback(async () => {
    try {
      await addQtyToCart(product, count);
      dispatch(successNotification(`Item successfully added to cart`));
      closeProductPreview?.();
    } catch (e) {
      //empty catch by default - addQtyToCart throws its own error notifications
    }
  }, [dispatch, addQtyToCart, count, product, closeProductPreview]);

  const handleAddButtonClick = useCallback(async () => {
    if (showUseByDateAlert) {
      dispatch(showProductBeingAddedPastUsedByDatePopup({ onContinue: addProductToCart }));
    } else {
      await addProductToCart();
    }
  }, [addProductToCart, dispatch, showUseByDateAlert]);

  return (
    <OrderActionsDiv>
      <LoadingButton
        cta
        loading={isLoading}
        disabled={count === 0}
        onClick={handleAddButtonClick}
        id={addButtonId}
        automationId='actions_button_add-to-cart-button'
      >
        {usesPricingTiers ? 'Add to Cart' : `Add to Cart ($${total.toFixed(2)})`}
      </LoadingButton>
    </OrderActionsDiv>
  );
};

const OrderActionsDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 2rem;
`;
