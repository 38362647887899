import { LabelPrinter, ReceiptPrinter } from "@dutchie/capacitor-peripheral";
import { LabelPrinter as HwLabelPrinter, ReceiptPrinter as HwReceiptPrinter } from "@dutchie/capacitor-hardware";

import { PrinterResponse } from "models/Misc";

export const getSearchReceiptPrintersDescription = (availablePrinters: ReceiptPrinter[] | HwReceiptPrinter[], userInitiated: boolean) => {
  return `${userInitiated ? 'user initiated' : 'code initiated'} searchReceiptPrinters called and found ${availablePrinters.length} printers`;
};

export const getSearchLabelPrintersDescription = (availablePrinters: LabelPrinter[] | HwLabelPrinter[], userInitiated: boolean) => {
  return `${userInitiated ? 'user initiated' : 'code initiated'} searchLabelPrinters called and found ${availablePrinters.length} printers`;
};

export const getPrintNodePrintersDescription = (availablePrinters: PrinterResponse[] | HwReceiptPrinter[] | HwLabelPrinter[], userInitiated: boolean, printerType: string) => {
  return `${userInitiated ? 'user initiated' : 'code initiated'} search for PrintNode ${printerType} printers called and found ${availablePrinters.length} printers`;
};
