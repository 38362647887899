import React from 'react';
import { useDispatch } from 'react-redux';

import { NavButton } from 'components/buttons';
import { ReactComponent as SecurityCardIcon } from 'assets/icons/security-card.svg';
import { showScanPopup, showSecurityCheckinPopup } from 'store/actions/PopupsActions';
import { useInHouseScanning } from 'util/hooks/launch-darkly/useInHouseScanning';

export const CheckinButton: React.FC = () => {
  const dispatch = useDispatch();
  const isInHouseScanningEnabled = useInHouseScanning();

  function handleClick() {
    if (isInHouseScanningEnabled) {
      dispatch(showScanPopup());
    } else {
      dispatch(showSecurityCheckinPopup());
    }
  }

  return (
    <NavButton
      onClick={handleClick}
      icon={<SecurityCardIcon />}
      automationId='action-bar_button_id-check-in'
      margin='0'
    >
      {isInHouseScanningEnabled ? 'Scan ID' : 'Check-In'}
    </NavButton>
  );
};
