import React, { FC } from 'react';
import styled from 'styled-components';

import { CashDetailsPanel } from 'pages/RegisterTransactionsPage/CashDetailsPanel';
import { ConfirmTransactionPopup } from '../ConfirmTransactionPopup';
import { callback } from 'models/Misc';
import { Label } from 'components/text';
import { LoadingButton } from 'components/buttons';
import { Switch, TextArea } from 'components/inputs';
import { useCloseout } from './useCloseout';
import { ManagerPinPopup } from 'components/sharedPopups/ManagerPinPopup';

import {
  ButtonsContainer,
  CashDetailsButton,
  CashInputContainer,
  Container,
  CurrencyInput,
  Fields,
  Row,
  TabTitle,
  TextAreaContainer,
} from '../Styles';

import type { CloseoutDetails } from 'queries/posv3/backendpos/types';

type CloseoutProps = {
  onCloseout: callback;
  registerId: number;
} & CloseoutDetails;

export const Closeout: FC<CloseoutProps> = ({
  endingBalance,
  startingBalance,
  transactionTotal,
  onCloseout,
  registerId,
}) => {
  const {
    loading,
    disableCloseout,
    closeout,
    reprintLastZReport,
    printZReportOnCloseOut,
    setPrintZReportOnCloseOut,
    useBlindRegisterCloseOutFF,
    mgrPinForDepositFF,
    cashCounted,
    setCashCounted,
    difference,
    newBalance,
    setNewBalance,
    handleClickEnterCashDetails,
    deposit,
    differenceReason,
    setDifferenceReason,
    toggleConfirmPopup,
    isConfirmPopupVisible,
    managerPinPopup,
    isZReportEnabled,
  } = useCloseout({ registerId, startingBalance, endingBalance, transactionTotal, onCloseout });

  return (
    <Container>
      <TabTitle>Close Out</TabTitle>
      <Fields>
        {!useBlindRegisterCloseOutFF && (
          <Row>
            <CurrencyInput
              disabled
              label='Start balance'
              value={startingBalance ?? 0}
              data-testid='closeout_input_starting-balance'
            />
            <CurrencyInput
              disabled
              label='Cash income'
              value={transactionTotal ?? 0}
              data-testid='closeout_input_cash-income'
            />
            <CurrencyInput
              disabled
              label='Ending balance'
              value={endingBalance ?? 0}
              data-testid='closeout_input_ending-balance'
            />
          </Row>
        )}
        <Row>
          <CashInputContainer>
            <CurrencyInput
              label='Cash counted'
              value={cashCounted.formattedValue}
              data-testid='closeout_input_cash-counted'
              allowNegative={false}
              onValueChange={setCashCounted}
            />
            <CashDetailsButton
              $variant='primary'
              onClick={handleClickEnterCashDetails}
              data-testid='closeout_button_edit-cash-details'
            >
              Enter cash details
            </CashDetailsButton>
          </CashInputContainer>
          {!useBlindRegisterCloseOutFF && (
            <CurrencyInput label='Difference' disabled value={difference} data-testid='closeout_input_difference' />
          )}
        </Row>
        <Row>
          <CurrencyInput
            label='New Balance'
            allowNegative={false}
            value={newBalance.formattedValue}
            onValueChange={setNewBalance}
            data-testid='closeout_input_new_balance'
          />
          <CurrencyInput label='Deposit' disabled value={deposit ?? 0} data-testid='closeout_input_deposit' />
        </Row>
        <Row>
          <TextAreaContainer>
            <Label>Note</Label>
            <TextArea
              value={differenceReason}
              placeholder='N/A'
              onChange={(e) => setDifferenceReason(e.target.value)}
              data-testid='closeout_textarea_notes'
            />
          </TextAreaContainer>
        </Row>
        {isZReportEnabled && (
          <ZReportContainer>
            <Switch
              checked={printZReportOnCloseOut}
              label='Print Z Report on close out'
              onChange={(printZReportOnCloseOut) => setPrintZReportOnCloseOut(printZReportOnCloseOut)}
              containerMargin='0'
            />
          </ZReportContainer>
        )}
      </Fields>
      <ButtonsContainer>
        {isZReportEnabled && (
          <LoadingButton tertiary onClick={reprintLastZReport}>
            Reprint last Z report
          </LoadingButton>
        )}
        <LoadingButton
          loading={loading}
          disabled={disableCloseout}
          onClick={toggleConfirmPopup}
          automationId='closeout_button_close-out'
        >
          Close Out
        </LoadingButton>
      </ButtonsContainer>
      {/* Popups */}
      <ConfirmTransactionPopup
        pinRequired={mgrPinForDepositFF}
        title='Confirm Close Out'
        message={`Total: $${deposit.toFixed(2)}`}
        onConfirm={closeout}
        isVisible={isConfirmPopupVisible}
        hide={toggleConfirmPopup}
      />
      {managerPinPopup && <ManagerPinPopup {...managerPinPopup} />}
      <CashDetailsPanel onSave={setCashCounted} />
    </Container>
  );
};

const ZReportContainer = styled.div`
  padding: 0.25rem 0;
`;
