/**
 * Small View Height (svh) is the best way to calculate the total
 * view height for iOS since it gives you the view height without the
 * browser chrome height. However, isn't broadly supported in Chrome or Android yet.
 * This hook will return the best, supported view height unit depending on
 * the userAgent.
 */

import { useState, useEffect, useCallback } from 'react';

/**
 * Parses a version string and returns an array split by either '.' or '_'
 */
export const parseVersion = (version: string) => {
  return version
    .replace(/_/g, '.')
    .split('.')
    .map((str) => Number(str));
};

/**
 * Compares two version strings and determines if the first meets the
 * minimum version
 * @note version string format supports: 15.6.1 or 15_6_1
 */
export const isMinimumVersion = (versionString: string, minimumVersion: string) => {
  const [major, minor, patch] = parseVersion(versionString);
  const [minMajor, minMinor, minPatch] = parseVersion(minimumVersion);

  if (major === minMajor) {
    if (minor === minMinor) {
      return (patch ?? -1) >= (minPatch ?? -1);
    } else {
      return (minor ?? -1) >= (minMinor ?? -1);
    }
  } else {
    return major > minMajor;
  }
};

/**
 * Returns whether the device supports dvh, svh, and lvh units
 */
export const supportsNewVhUnits = (ua?: string) => {
  // Minimum version of iOS/iPadOS/macOS(safari) that supports
  // new dvh, svh, and lvh view height units
  const minimumVersion = '15.4';

  const userAgent = ua ?? navigator.userAgent;

  // Check Safari version first
  const versionRegex = new RegExp(/version\/([\d.]+)/gi);
  const [, safariVersion] = versionRegex.exec(userAgent) ?? ['', null];

  if (safariVersion) {
    return isMinimumVersion(safariVersion, minimumVersion);
  }

  // Otherwise, check iOS/iPadOS version (Chrome for iOS)
  const osVersionRegex = new RegExp(/OS ([\d_]+) like Mac OS X/gi);
  const [, osVersion] = osVersionRegex.exec(userAgent) ?? ['', null];

  if (osVersion) {
    return isMinimumVersion(osVersion, minimumVersion);
  }

  return false;
};

/**
 * Gets the best, supported view height unit from user agent
 * @param subtractFromHeight a string containing number and unit to subtract from total height
 * @param viewHeightUnit (optional) the dynamic unit to use to calculate the view height
 * @returns a css string calculating height from supported view height unit
 */
export const useDynamicViewHeight = (
  subtractFromHeight?: string,
  viewHeightUnit: 'dvh' | 'svh' | 'lvh' = 'dvh',
  ua?: string
) => {
  const getHeightString = useCallback(() => {
    // Use svh if possible, otherwise calculate the innerHeight manually
    const viewHeight = supportsNewVhUnits(ua) ? `100${viewHeightUnit}` : `${window.innerHeight}px`;
    return subtractFromHeight ? `calc(${viewHeight} - ${subtractFromHeight})` : viewHeight;
  }, [subtractFromHeight, viewHeightUnit, ua]);

  const [heightString, setHeightString] = useState(() => {
    return getHeightString();
  });

  useEffect(() => {
    const adjustHeight = () => {
      const newHeightString = getHeightString();
      if (newHeightString !== heightString) {
        setHeightString(newHeightString);
      }
    };
    adjustHeight();

    window.addEventListener('resize', adjustHeight);
    return () => window.removeEventListener('resize', adjustHeight);
  }, [getHeightString, heightString]);

  return heightString;
};
