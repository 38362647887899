import React from 'react';

type RebrandCaretProps = {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
};

export function RebrandCaret({ className, width, height, color }: RebrandCaretProps): JSX.Element {
  return (
    <svg
      className={className}
      fill='none'
      height={height ?? '10'}
      viewBox='0 0 16 10'
      width={width ?? '16'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M0.434156 0.453342C0.794677 0.0763923 1.37844 0.0771912 1.73802 0.455127L7.99241 7.02877L14.2637 0.472883C14.6243 0.095968 15.2081 0.096824 15.5676 0.474795C15.9272 0.852765 15.9263 1.46472 15.5658 1.84164L8.96837 8.7384C8.83991 8.87276 8.68698 8.9798 8.51926 9.05243C8.35132 9.12514 8.17135 9.16244 7.98966 9.16218C7.80797 9.16192 7.6281 9.12412 7.46035 9.05093C7.29282 8.97783 7.14066 8.87087 7.01255 8.73615L0.432453 1.82018C0.0728724 1.44225 0.0736345 0.830292 0.434156 0.453342Z'
        fill={color ?? 'black'}
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M0.318861 0.332162C0.743224 -0.11154 1.43036 -0.110599 1.85361 0.334262L7.99273 6.78674L14.1485 0.351691C14.5729 -0.091969 15.26 -0.0909614 15.6832 0.353942C16.1064 0.798844 16.1055 1.51917 15.6811 1.96283L9.08366 8.85959C8.94003 9.00981 8.76912 9.12941 8.58159 9.21062C8.39381 9.29192 8.19259 9.33362 7.98944 9.33333C7.78629 9.33304 7.58518 9.29078 7.39761 9.20894C7.21029 9.1272 7.04016 9.00761 6.89692 8.85698L0.316857 1.94105C-0.1064 1.49619 -0.105503 0.775863 0.318861 0.332162ZM1.62242 0.575991C1.32651 0.264982 0.84613 0.264324 0.549451 0.574522C0.252772 0.884721 0.252145 1.38831 0.54805 1.69932L7.12765 8.61477C7.24063 8.73357 7.37534 8.82845 7.52309 8.89291C7.67103 8.95746 7.82965 8.9908 7.98988 8.99103C8.15012 8.99126 8.30882 8.95837 8.45693 8.89424C8.60484 8.83019 8.73927 8.73625 8.85256 8.61776L15.4505 1.72044C15.7472 1.41027 15.7479 0.906686 15.452 0.595648C15.1561 0.284609 14.6757 0.283905 14.379 0.594074L8.1077 7.14996C8.04385 7.2167 7.94049 7.21656 7.87682 7.14964L1.62242 0.575991ZM7.7814 7.24927C7.84508 7.31617 7.84499 7.42451 7.78119 7.49128C7.71736 7.55802 7.61345 7.55848 7.54977 7.49158C7.48609 7.42469 7.48619 7.31635 7.54998 7.24957C7.61381 7.18284 7.71772 7.18238 7.7814 7.24927ZM8.20286 7.24987C8.26671 7.18316 8.37005 7.18332 8.43371 7.25024C8.49733 7.31719 8.49771 7.42612 8.43386 7.49283C8.37001 7.55955 8.26666 7.55939 8.203 7.49247C8.13938 7.42552 8.139 7.31659 8.20286 7.24987Z'
        fill={color ?? 'black'}
        fillRule='evenodd'
      />
    </svg>
  );
}
