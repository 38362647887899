import { useLDClient } from 'launchdarkly-react-client-sdk';

export const LD_FLAG_PAYMENTS_HUB_ALERT_MESSAGE = 'fintech.payment-processing.payments-hub-alert-message';

export const usePaymentsHubAlertMessage = (): string | null => {
  const ldClient = useLDClient();
  const ldFlagString = ldClient?.variation(LD_FLAG_PAYMENTS_HUB_ALERT_MESSAGE, '');
  if (!ldFlagString) {
    return null;
  }
  return ldFlagString;
};
