export function parseCanadianBarcode(barcode: string): string {
  /*
        Canadian Barcodes include 3 Application Identifiers (AI) mushed into one barcode.
        * 14 character GTIN, left padded w/ zero (0) characters
            - AI code: 01
        * 6 character Expiration / package Date, in 'YYMMDD' format
            - AI code: 17 (expiration)
            - AI code: 13 (package?)
        * Up to 20 character Batch / Lot Number
            - AI code: 10
        * The AI code is left prepented to one the properties above. Consider this example:
        Real Barcode: "01095011015300031714070410AB-123"
        Format:       "[01]09501101530003[17]140704[10]AB-123"
        Explain:      "[AI]   GTIN       [AI]YYMMDD[AI] batch"
        If you have canadian barcode scanning enabled, package ids will have the form of:
        "<GTIN>-Batch/Lot"
        This utility will take a canadian barcode, and parse out the individual pieces,
        and return the parsed package id:
        Input: "01095011015300031714070410AB-123"
        Output: "09501101530003-AB-123"
     */
  let result = barcode;

  const barcodeRegex = /^01(\d{14})1[3|7](\d{6})10(\S{1,20})$/im;
  if (barcodeRegex.test(barcode)) {
    const captures = barcode.match(barcodeRegex);
    // first capture is the full string the regex matched, were interested in the groupings that are in order indexed
    result = `${captures?.[1]}-${captures?.[3]}`;
  }

  return result;
}
