import { useCallback, useMemo, useState } from 'react';
import { logger, customEventKeys } from 'util/logger';
import { GetPrintNodePrintersContext, UserSelectedPrinterContext } from 'util/logger/types/printing';
import { getPrintNodePrintersDescription } from 'util/logger/helpers/printing';
import { isPrintNodeReceiptPrinter } from 'hardware/receipt-printer';
import { HardwareService, ReceiptPrinter, useReceiptPrinters } from '@dutchie/capacitor-hardware';
import { errorNotification } from 'store/actions/NotificationsActions';
import { PrinterListHookData, PrinterPickerHookData } from '../types';
import { isNativeReceiptPrinter } from '@dutchie/capacitor-peripheral';
import { HARDWARE_LIBRARY_STORAGE_KEYS } from 'util/hardwareLibrary/hardware-library-utils';
import { TEST_FOOTER, TEST_HEADER } from '../Constants/ESCEncodingConstants';
import { useLocalStorage, useAppDispatch } from 'util/hooks';
import { HardwareLibraryStorageManager } from 'util/hardwareLibrary/hardware-library-storage';

export const useHardwareLibraryReceiptPicker = ({ connectionType, printerType }: { connectionType: 'network' | 'local'; printerType: 'receipt' | 'fulfillment' }): PrinterPickerHookData => {
  const dispatch = useAppDispatch();
  const { printers } = useHardwareLibraryReceiptPrinters({ connectionType });
  const options = useMemo(() => {
    return printers.map((printers) => {
      return {
        key: printers.id,
        label: printers.name,
        value: printers.id,
      };
    });
  }, [printers]);

  const storageManager = new HardwareLibraryStorageManager(dispatch);
  const { value: selectedFulfillmentPrinterId } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.FULFILLMENT_PRINTER_ID);
  const { value: selectedReceiptPrinterId } = useLocalStorage(HARDWARE_LIBRARY_STORAGE_KEYS.RECEIPT_PRINTER_ID);

  const setSelectedFulfillmentPrinterId = (id: string) => {
    storageManager.fulfillmentPrinterId = id;
  };

  const setSelectedReceiptPrinterId = (id: string) => {
    storageManager.receiptPrinterId = id;
  };

  const testPrint = async (): Promise<void> => {
    const bytes: number[] = [
      ...TEST_HEADER,
      ...Array.from(new TextEncoder().encode('Successful Test Print!')),
      ...TEST_FOOTER,
    ];
    await printers.find(it => it.id === selectedPrinterId)?.print(Uint8Array.from(bytes));
  };
  // To avoid the Select component from resetting to the first option when the list changes, we need to make sure the selected option is in the list
  const selectedPrinterId: string | undefined = useMemo(() => {
    return printers.find((option) => {
      switch (printerType) {
        case 'fulfillment':
          return option.id === selectedFulfillmentPrinterId;
        case 'receipt':
          return option.id === selectedReceiptPrinterId;
      }
      return false;
    })?.id;
  }, [printerType, printers, selectedFulfillmentPrinterId, selectedReceiptPrinterId]);

  const handleReceiptPrinterChange = (value: string) => {
    // update stored value for future sessions
    switch (printerType) {
      case 'fulfillment': {
        setSelectedFulfillmentPrinterId(value);
        break;
      }
      case 'receipt': {
        setSelectedReceiptPrinterId(value);
        break;
      }
    }

    logger.info<UserSelectedPrinterContext>(`user selected ${connectionType} ${printerType} printer ${value}`, {
      key: customEventKeys.printing.userSelectedPrinter,
      type: connectionType,
      selectedPrinterId: value,
    });
  };

  return {
    changeSelection: handleReceiptPrinterChange,
    dropdownOptions: options,
    selectedPrinterId,
    testPrint,
  };
};

export const useHardwareLibraryReceiptPrinters = ({ connectionType }: { connectionType: 'network' | 'local' }): PrinterListHookData<ReceiptPrinter> => {
  const dispatch = useAppDispatch();

  const { receiptPrinters: availablePrinters } = useReceiptPrinters();
  const receiptPrinters = useMemo(() => {
    return availablePrinters.filter((printer) => {
      if (connectionType === 'local') {
        return isNativeReceiptPrinter(printer);
      }
      return isPrintNodeReceiptPrinter(printer);
    });
  }, [availablePrinters, connectionType]);

  const [isLoadingReceiptPrinters, setIsLoadingReceiptPrinters] = useState(false);

  const handleSearchReceiptPrinters = useCallback(
    async ({ userInitiated = false }: { userInitiated?: boolean }): Promise<void> => {
      try {
        setIsLoadingReceiptPrinters(true);

        // Searching will handle updating the hook
        const availablePrinters = await HardwareService.receiptPrinter.search();

        logger.info<GetPrintNodePrintersContext>(getPrintNodePrintersDescription(availablePrinters, userInitiated, connectionType), {
          key: customEventKeys.printing.network.getPrinters,
          availablePrinters: availablePrinters,
          userInitiated,
          type: 'receipt',
        });
      } catch (e) {
        dispatch(errorNotification(`Error loading receipt printers: ${e}`));
        logger.error(e, { message: 'searchReceiptPrinters failed', userInitiated });
      } finally {
        setIsLoadingReceiptPrinters(false);
      }
    },
    [dispatch, connectionType]
  );

  return {
    isLoading: isLoadingReceiptPrinters,
    printers: receiptPrinters,
    refreshList: handleSearchReceiptPrinters,
  };
};
