import React from 'react';

import { colors } from 'css/Theme';

type MedicalIconProps = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const MedicalIcon = ({
  fill = colors.dutchie.almostBlack,
  width = 16,
  height = 16,
  className,
}: MedicalIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      className={className}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.86527 1.86515C6.07781 1.65261 6.36608 1.5332 6.66666 1.5332H9.33333C9.6339 1.5332 9.92217 1.65261 10.1347 1.86515C10.3473 2.07769 10.4667 2.36596 10.4667 2.66654V5.5332H13.3333C13.6339 5.5332 13.9222 5.65261 14.1347 5.86515C14.3473 6.07769 14.4667 6.36596 14.4667 6.66654V9.3332C14.4667 9.63378 14.3473 9.92205 14.1347 10.1346C13.9222 10.3471 13.6339 10.4665 13.3333 10.4665H10.4667V13.3332C10.4667 13.6338 10.3473 13.9221 10.1347 14.1346C9.92217 14.3471 9.6339 14.4665 9.33333 14.4665H6.66666C6.36608 14.4665 6.07781 14.3471 5.86527 14.1346C5.65273 13.9221 5.53333 13.6338 5.53333 13.3332V10.4665H2.66666C2.36608 10.4665 2.07781 10.3471 1.86527 10.1346C1.65273 9.92205 1.53333 9.63378 1.53333 9.3332V6.66654C1.53333 6.36596 1.65273 6.07769 1.86527 5.86515C2.07781 5.65261 2.36608 5.5332 2.66666 5.5332H5.53333V2.66654C5.53333 2.36596 5.65273 2.07769 5.86527 1.86515ZM6.66666 2.46654C6.61362 2.46654 6.56275 2.48761 6.52524 2.52512C6.48773 2.56262 6.46666 2.61349 6.46666 2.66654V5.99987C6.46666 6.2576 6.25772 6.46654 5.99999 6.46654H2.66666C2.61362 6.46654 2.56274 6.48761 2.52524 6.52512C2.48773 6.56262 2.46666 6.61349 2.46666 6.66654V9.3332C2.46666 9.38625 2.48773 9.43712 2.52524 9.47462C2.56274 9.51213 2.61362 9.5332 2.66666 9.5332H5.99999C6.25772 9.5332 6.46666 9.74214 6.46666 9.99987V13.3332C6.46666 13.3862 6.48773 13.4371 6.52524 13.4746C6.56274 13.5121 6.61362 13.5332 6.66666 13.5332H9.33333C9.38637 13.5332 9.43724 13.5121 9.47475 13.4746C9.51225 13.4371 9.53333 13.3862 9.53333 13.3332V9.99987C9.53333 9.74214 9.74226 9.5332 9.99999 9.5332H13.3333C13.3864 9.5332 13.4372 9.51213 13.4747 9.47462C13.5123 9.43712 13.5333 9.38625 13.5333 9.3332V6.66654C13.5333 6.61349 13.5123 6.56262 13.4747 6.52512C13.4372 6.48761 13.3864 6.46654 13.3333 6.46654H9.99999C9.74226 6.46654 9.53333 6.2576 9.53333 5.99987V2.66654C9.53333 2.61349 9.51225 2.56262 9.47475 2.52512C9.43724 2.48761 9.38637 2.46654 9.33333 2.46654H6.66666Z'
        fill={fill}
      />
    </svg>
  );
};
