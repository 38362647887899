import type { PrintJobContext, PrintJobResultContext } from 'util/logger/types/printing';
import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';
import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';
import { PrintJob, PrintStatus } from 'models/Printing';
import {
  getStoredHardware,
  peripheralInfo,
  GetPrintJobResponse,
  processHardwarePrintJob,
  selectHardwarePrinterType,
} from 'util/hardwareLibrary/hardware-library-utils';
import { useAppDispatch } from 'util/hooks';
import { post } from 'api/HttpHelpers';
import { getIsPrintResultLoggingEnabled } from 'util/hooks/launch-darkly/getIsPrintResultLoggingEnabled';

type UsePrintCartParams = {
  guestId: number;
  shipmentId: number;
};

export const usePrintCart = () => {
  const dispatch = useAppDispatch();

  return async (params: UsePrintCartParams) => {
    const { receiptPrinter } = getStoredHardware();

    try {
      if (!receiptPrinter) {
        throw new Error('Printer configuration not found');
      }

      dispatch(successNotification('Printing cart...'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.CART, status: PrintStatus.PRINTING }));

      logger.info<PrintJobContext>('print cart started', {
        key: customEventKeys.printing.jobStarted,
        job: PrintJob.CART,
        requestParams: params,
        printer: peripheralInfo(receiptPrinter),
      });

      const job = await post<GetPrintJobResponse>('v2/print-jobs/get-picklist-job', {
        Guest_id: params.guestId,
        PopCashDrawer: false,
        PrinterId: 0, // not used by endpoint
        PrinterType: selectHardwarePrinterType(receiptPrinter),
        ShipmentId: params.shipmentId,
      });

      if (getIsPrintResultLoggingEnabled()) {
        let success = false;
        try {
          success = await processHardwarePrintJob(job, receiptPrinter);
        } finally {
          logger.info<PrintJobResultContext>('print cart result', {
            key: customEventKeys.printing.jobEnded,
            job: PrintJob.CART,
            requestParams: params,
            printer: peripheralInfo(receiptPrinter),
            success,
          });
        }
      } else {
        await processHardwarePrintJob(job, receiptPrinter);
      }

      dispatch(successNotification('Cart printed'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.CART, status: PrintStatus.SUCCESSFUL }));
    } catch (e) {
      const message = parseErrorMessage(e);
      dispatch(errorNotification(`Error generating cart preview: ${message}`));
      dispatch(setPrintJobStatus({ printJob: PrintJob.CART, status: PrintStatus.FAILED }));
      logger.error(e, {
        message: 'Failed to print cart',
        requestParams: params,
        printer: peripheralInfo(receiptPrinter),
      });
    }
  };
};
