import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  resetPrintJobStatus,
  printLabels,
  previewLabels,
} from 'store/actions/PrintActions';
import { logout } from 'store/actions/UserActions';
import { PrintStatus } from 'models/Printing';

export type PrintingStatus = 'idle' | 'printing' | 'successful' | 'failed';

export type PrintState = {
  printingReceipt: boolean;
  printingLabel: boolean;
  previewingLabel: boolean;
  printingPickList: boolean;
  printOrPreview: boolean;
  printingPreOrder: boolean;
  printReceiptStatus: PrintingStatus;
  printCartStatus: PrintingStatus;
  printPickTicketStatus: PrintingStatus;
  printLabelsStatus: PrintingStatus;
};

const initialState: PrintState = {
  printingReceipt: false,
  printingLabel: false,
  previewingLabel: false,
  printingPickList: false,
  printOrPreview: false,
  printingPreOrder: false,
  printReceiptStatus: PrintStatus.IDLE,
  printCartStatus: PrintStatus.IDLE,
  printPickTicketStatus: PrintStatus.IDLE,
  printLabelsStatus: PrintStatus.IDLE,
};

export const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PrintState>) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(resetPrintJobStatus, () => initialState);

    builder.addCase(printLabels.pending, (state: PrintState) => {
      state.printingLabel = true;
      state.printOrPreview = false;
      state.printLabelsStatus = PrintStatus.PRINTING;
    });
    builder.addCase(printLabels.fulfilled, (state: PrintState) => {
      state.printingLabel = false;
      state.printOrPreview = true;
      state.printLabelsStatus = PrintStatus.SUCCESSFUL;
    });
    builder.addCase(printLabels.rejected, (state: PrintState) => {
      state.printingLabel = false;
      state.printOrPreview = false;
      state.printLabelsStatus = PrintStatus.FAILED;
    });
    builder.addCase(previewLabels.pending, (state: PrintState) => {
      state.previewingLabel = true;
      state.printOrPreview = false;
    });
    builder.addCase(previewLabels.fulfilled, (state: PrintState) => {
      state.previewingLabel = false;
      state.printOrPreview = true;
    });
    builder.addCase(previewLabels.rejected, (state: PrintState) => {
      state.previewingLabel = false;
      state.printOrPreview = false;
    });
  },
});
