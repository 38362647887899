import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { caregiverListKeys } from './query-key-factory';
import { transformToCaregiver } from './types';

import type { Caregiver, CaregiversServerResponse } from './types';

const GET_CAREGIVER_LIST = 'v2/guest/caregiver_list';

// Payload that gets passed to the query function
type CaregiversPayload = {
  guestId: number;
};

const transformToServerPayload = (payload: CaregiversPayload) => {
  return {
    Guest_id: payload.guestId,
  };
};

export const useCaregiversQuery = (payload: CaregiversPayload, options?: UseQueryOptions<Caregiver[]>) => {
  return useQuery({
    queryKey: caregiverListKeys.many(payload.guestId),
    queryFn: async () => {
      const response = await post<CaregiversServerResponse[]>(GET_CAREGIVER_LIST, transformToServerPayload(payload));

      return response.map(transformToCaregiver);
    },
    ...options,
  });
};
