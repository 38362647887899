// well thats a bummer.
// https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/?tab=browser#feature-flag-naming
export function cleanLdKey(input: string): string {
    // Static array of characters to remove
    const charsToRemove = ['.', ':', '+', '-'];

    // Create a regular expression that matches any of the characters in the array
    const pattern = new RegExp(`[${charsToRemove.map((c) => `\\${c}`).join('')}]`, 'g');
    return input.replace(pattern, '_');
}
