import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';

// utils
import { colors, zIndices } from 'css/Theme';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { useChangeCustomerTypeInCart } from 'util/hooks/launch-darkly/useChangeCustomerTypeInCart';

// redux
import { openCustomerHistoryPanel } from 'store/actions/CartActions';

// global components
import { GuestNotes } from 'components/cart/GuestNotes';
import { LegalLimit } from 'components/cart/LegalLimit';
import { useShowPurchaseHistory } from 'components/cart/PurchaseHistory/PurchaseHistory';
import { CustomerInsights, useShowCustomerInsights } from 'components/cart/CustomerInsights';
import { useLimitSummary } from 'components/cart/LegalLimit/useLimitSummary';
import { CustomerHistoryPanelTabs } from 'components/cart/CustomerHistoryPanel/CustomerHistoryPanel';
import { CustomerType, CustomerTypeBadge, CustomerTypeBadgeVariations } from 'components/cart/CustomerType';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useUpdateCartTotals } from 'pages/CartPage/hooks/useUpdateCartTotals';
import { PreorderRedemption } from 'pages/CartPage/CartComponents/PreorderRedemption';

// local components
import { ExpandableContent } from './components/ExpandableContent';
import { CartTotalsAndActions as _CartTotalsAndActions } from '../components/CartTotalsAndActions';

import type { ExpandableContentItem } from './components/ExpandableContent';

export type CartFooterProps = {
  isLoading?: boolean;
};

export const CartFooter = ({ isLoading }: CartFooterProps) => {
  const [isTotalsDrawerOpen, setIsTotalsDrawerOpen] = useState(false);

  const dispatch = useAppDispatch();
  const isMedicalCustomer = useAppSelector((state) => state.customer.details?.IsMedical ?? false);

  const { allotmentSummary } = useLimitSummary();
  const { isCompactLayout } = useCompactCartLayout();
  const showPurchaseHistory = useShowPurchaseHistory();
  const showCustomerInsights = useShowCustomerInsights();
  const { isChangeCustomerTypeInCartEnabled } = useChangeCustomerTypeInCart();
  const { isLoading: areUpdatedCartTotalsLoading } = useUpdateCartTotals();
  const { data: cart, isLoading: areCartTotalsLoading } = useGetCartDetails();
  const isCartLoading = areCartTotalsLoading || areUpdatedCartTotalsLoading;

  const customerType = isMedicalCustomer
    ? CustomerTypeBadgeVariations.MEDICAL
    : CustomerTypeBadgeVariations.RECREATIONAL;

  const expandableContent = useMemo(() => {
    const content: ExpandableContentItem[] = [
      { key: 'allotment', label: 'Allotment', secondaryLabel: allotmentSummary, content: <LegalLimit /> },
      { key: 'notes', label: 'Notes', content: <GuestNotes /> },
    ];

    if (showCustomerInsights) {
      content.push({
        key: 'customerInsights',
        label: 'Insights',
        content: <CustomerInsights showBottomBorder={false} />,
      });
    }

    if (showPurchaseHistory) {
      content.push({
        key: 'history',
        label: 'History',
        onClick: () => dispatch(openCustomerHistoryPanel({ tab: CustomerHistoryPanelTabs.Products })),
      });
    }

    if (isChangeCustomerTypeInCartEnabled) {
      content.push({
        key: 'customerType',
        label: <CustomerTypeBadge customerType={customerType} />,
        content: <CustomerType />,
        buttonStyles: css`
          flex: unset;
        `,
      });
    }

    return content;
  }, [
    allotmentSummary,
    showCustomerInsights,
    showPurchaseHistory,
    isChangeCustomerTypeInCartEnabled,
    dispatch,
    customerType,
  ]);

  if (!isCompactLayout) {
    return null;
  }

  return (
    <FooterContainer data-testid='cart-page_cart-footer'>
      <ExpandableContent content={expandableContent} />
      <InnerContainer>
        <PreorderRedemption />
        <CartTotalsAndActions
          cart={cart}
          isLoading={isCartLoading || isLoading}
          actionButtonStyles={actionButtonStyles}
          areTotalsOpen={isTotalsDrawerOpen}
          onToggleTotals={setIsTotalsDrawerOpen}
        />
      </InnerContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  flex-shrink: 0;
  background: ${colors.dutchie.primaryWhite};
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.16);
`;

/** Prevents the expanding content from showing through the other components during animations */
const InnerContainer = styled.div`
  background: ${colors.dutchie.primaryWhite};
  z-index: ${zIndices.cartStickyPanel};
`;

const CartTotalsAndActions = styled(_CartTotalsAndActions)`
  padding: 1.5rem;
  gap: 0.25rem;
`;

const actionButtonStyles = css`
  width: 86px;
`;
