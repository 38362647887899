import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { Button } from 'components/buttons';
import { Row } from 'components/layout';

export const SaveSettingsFooter = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const formRef = useFormikContext();

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      formRef?.submitForm();

      // Provides feedback that the button was clicked
      await new Promise((resolve) => setTimeout(resolve, 500));
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Row justifyContent='end'>
      <Button disabled={formRef?.dirty !== true || isUpdating} onClick={handleSave}>
        Save settings
      </Button>
    </Row>
  );
};
