import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ssoLoginEntity } from 'store/actions/UserActions';
import styled from 'styled-components';

const SamlPageBase = styled.div`
  display: flex;
  align-items: center;
`;

export const SamlPage: FC = () => {
  const dispatch = useDispatch();
  const url = window.location.pathname;

  useEffect(() => {
    if (url.startsWith('/api/saml/Login')) {
      const urlSplit = url.split('/');
      const entity = urlSplit[urlSplit.length - 1];
      dispatch(ssoLoginEntity({ entity }));
    }
  });
  return (
    <SamlPageBase>
      <div></div>
    </SamlPageBase>
  );
};
