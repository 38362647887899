import React, { FC, ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { colors, zIndices } from 'css/Theme';
import { NotificationsContainerID } from 'components/Notifications/Notifications';

import type { callback } from 'models/Misc';

type BottomDrawerProps = {
  automationId?: string;
  open?: boolean;
  portalId?: string;
  children: ReactNode;
  onClose: callback;
  fullHeight?: boolean;
  marginX?: number;
  marginY?: number;
};

const DRAWER_TRANSITION_DURATION = 300;

export const BottomDrawer: FC<BottomDrawerProps> = ({
  automationId,
  portalId,
  children,
  open,
  onClose,
  fullHeight = false,
  marginX = 20,
  marginY = 20,
}) => {
  const component = (
    <>
      {open && <AdjustedNotificationContainer />}
      <CSSTransition in={open} timeout={DRAWER_TRANSITION_DURATION} classNames='bottom-drawer-overlay' unmountOnExit>
        <OverlayDiv onClick={onClose} />
      </CSSTransition>
      <CSSTransition in={open} timeout={DRAWER_TRANSITION_DURATION} classNames='bottom-drawer-container' unmountOnExit>
        <DrawerDiv fullHeight={fullHeight} marginX={marginX} marginY={marginY} data-testid={automationId}>
          {children}
        </DrawerDiv>
      </CSSTransition>
    </>
  );

  const portalEl = document.getElementById(portalId || '');
  if (portalEl) {
    return createPortal(component, portalEl);
  }

  return component;
};

const AdjustedNotificationContainer = createGlobalStyle`
  #${NotificationsContainerID} {
    top: 115px;
  }
`;

const OverlayDiv = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: ${zIndices.bottomDrawer};
  pointer-events: auto;
  background: rgba(16, 48, 77, 0.3);

  &.bottom-drawer-overlay-enter {
    opacity: 0;
  }
  &.bottom-drawer-overlay-enter-active {
    opacity: 1;
    transition: opacity ${DRAWER_TRANSITION_DURATION}ms ease;
  }
  &.bottom-drawer-overlay-exit {
    opacity: 1;
  }
  &.bottom-drawer-overlay-exit-active {
    opacity: 0;
    transition: opacity ${DRAWER_TRANSITION_DURATION}ms ease;
  }
`;

const DrawerDiv = styled.div<{
  fullHeight?: boolean;
  marginX: number;
  marginY: number;
}>`
  box-shadow: 0 0 18px rgba(33, 33, 33, 0.2);
  background-color: ${colors.background};
  bottom: 0;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  max-height: calc(100% - ${({ marginY }) => marginY}px);
  overflow: auto;
  position: absolute;
  width: calc(100% - ${({ marginX }) => marginX * 2}px);
  left: ${({ marginX }) => marginX}px;
  z-index: ${zIndices.bottomDrawer + 1};
  pointer-events: auto;
  border-radius: 20px 20px 0 0;

  &.bottom-drawer-container-enter {
    transform: translate3D(0, 100%, 0);
  }
  &.bottom-drawer-container-enter-active {
    transition: transform ${DRAWER_TRANSITION_DURATION}ms ease;
    transform: translate3D(0, 0, 0);
  }
  &.bottom-drawer-container-exit {
    transform: translate3D(0, 0, 0);
  }
  &.bottom-drawer-container-exit-active {
    transition: transform ${DRAWER_TRANSITION_DURATION}ms ease;
    transform: translate3D(0, 100%, 0);
  }
`;
