import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { isBefore, isSameDay, parseISO, differenceInYears, format } from 'date-fns';
import styled, { css } from 'styled-components';

import { biotrackScanGuestIn } from 'api/GuestApi';
import { validateMassDPHSavedCreds } from 'api/SettingsApi';
import { CustomerAddress } from 'models/Customer';
import { CheckedInGuest } from 'models/Guest';
import { getCustomerDetails, checkInCustomer } from 'store/actions/CustomerActions';
import { setCheckedInGuest } from 'store/actions/CartActions';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';

import { colors } from 'css/Theme';
import { getMinorAge, isGuestIdSoonToBeExpired } from 'util/Helpers';
import { SidePanelSection } from 'components/layout';
import { LoadingButton, NavLinkButton } from 'components/buttons';
import { ReactComponent as _UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as UserEditIcon } from 'assets/icons/edit.svg';
import { useAppDispatch, useCheckPreorderEligible } from 'util/hooks';
import { CustomerPopups } from 'components/CustomerPopups';
import { Pill } from 'components/misc';
import { useShouldBypassDOBCheck } from 'util/hooks/useShouldBypassDOBCheck';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useCuraleafLoyaltyTier } from 'util/hooks/launch-darkly/useCuraleafLoyaltyTier';

import type { State } from 'store';
import {
  showCheckinCaregiverPopup,
  showCreatePreOrderFromProfilePopup,
  showSelectCollectorFromProfilePopup,
  showStartOrderPopup,
  showVerifyBirthdateFromProfilePopup,
} from 'store/actions/PopupsActions';

const GuestEditButton: React.FC<{ guestId?: number }> = ({ guestId }) => (
  <NavLinkButton
    to={`/edit-customer?id=${guestId}`}
    icon={<UserEditIcon />}
    automationId='customer-preview_link-button_edit-guest'
  >
    Edit Profile
  </NavLinkButton>
);

const addressFormat = (address: CustomerAddress): string => {
  const { street, street2, city, state } = address;

  return [street, street2, city, state].filter(Boolean).join(', ');
};

export const CustomerPreviewWithoutPopups: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const guests = useSelector((state: State) => state.guestList.guests);
  const selected = useSelector((state: State) => state.customer.previewSelected);
  const isUseMnEnabled = useIsUseMnEnabled(selected);
  const detailsLightLoading = useSelector((state: State) => state.customer.detailsLightLoading);
  const isUseCuraleafLoyaltyTierEnabled = useCuraleafLoyaltyTier();

  const { goToCart } = useTransactionManager();

  let checkedInDetails: CheckedInGuest | undefined;

  const { features, integrations, locationSettings } = useSelector((state: State) => state.settings);

  const [birthdateVerified, setBirthdateVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const customerIsMedical = selected?.IsMedical ?? false;
  const isCustomerBanned = selected?.status.toLowerCase() === 'banned';
  const shouldBypassDOBCheck = useShouldBypassDOBCheck({ customerIsMedical });
  const needBirthdateCheck = !birthdateVerified && features.ConfirmBirthdayOnCheckin && !shouldBypassDOBCheck;

  const idExpired = isGuestIdSoonToBeExpired(selected?.MJExpirationDate);
  const checkPreorderEligible = useCheckPreorderEligible();
  const groupIds: number[] = [];

  if (selected) {
    checkedInDetails = guests.find((x) => x.Guest_id === selected?.Guest_id);
  }

  useEffect(() => {
    return () => {
      if (features.ConfirmBirthdayOnCheckin) {
        setBirthdateVerified(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  let checkedInShipmentId = checkedInDetails?.ShipmentId;
  let checkedInScheduleId = checkedInDetails?.ScheduleId;
  const isCheckedIn = checkedInShipmentId ? checkedInShipmentId > 0 : false;

  const checkIn = async ({
    andEnterCart = false,
    andEnterPreOrderCart = false,
    caregiverId = undefined,
  }: {
    andEnterCart?: boolean;
    andEnterPreOrderCart?: boolean;
    caregiverId?: string;
  }) => {
    setLoading(true);
    let validationError = false;
    if (
      checkedInDetails &&
      register &&
      Number(checkedInDetails.RegisterId) !== 0 &&
      Number(checkedInDetails.RegisterId) !== register.value
    ) {
      validationError = true;
      dispatch(warningNotification(`Guest is already assigned to ${checkedInDetails.Register}`));
    }

    if (features.RequireDoctorForCheckIn) {
      if (selected && selected.IsMedical && !(parseInt(selected.doctor) > 0)) {
        validationError = true;
        dispatch(errorNotification('Patient does not have a doctor on record.'));
      }
    }

    if (features.RequirePrescriptionForCheckIn) {
      if (selected && !selected.ValidPrescription) {
        validationError = true;
        dispatch(errorNotification('Patient does not have a valid prescription on record.'));
      }
    }

    if (!selected?.CustomerTypeId) {
      validationError = true;
      dispatch(errorNotification('Patient does not have a customer type on record.'));
    }

    if (features.RequireValidDL) {
      const hasId = selected?.DriversLicenseId;
      const isLicenseExpired = !selected || isBefore(new Date(selected.DLExpirationDate), Date.now());
      if (!hasId || isLicenseExpired) {
        validationError = true;
        dispatch(errorNotification("Customer driver's license missing or expired"));
      }
    }

    if (features.MMJIdExpirationDateCheck && selected?.IsMedical) {
      const hasMMJId = selected?.MJStateIDNo;
      const isMMJIdExpired =
        !selected ||
        (isBefore(new Date(selected.MJExpirationDate), Date.now()) &&
          !isSameDay(new Date(selected.MJExpirationDate), new Date()));

      if (!hasMMJId || isMMJIdExpired) {
        validationError = true;
        dispatch(errorNotification('Customer MMJ Id missing or expired'));
      }
    }

    if (features.BlockMinorsFromCheckInFF) {
      const guestAge = selected?.DOB ? selected?.DOB : new Date();
      const defaultMinorAge = getMinorAge(locationSettings, selected?.IsMedical || false);

      const guestYears = differenceInYears(new Date(), new Date(guestAge));

      if (guestYears < defaultMinorAge && !caregiverId && (!selected?.IsAnonymous || features.CollectAnonymousDemo)) {
        validationError = true;
        dispatch(errorNotification(`Patient is under the age of ${defaultMinorAge}. You cannot proceed`));
      }
    }

    if (selected?.CustomerTypeId === 1 && features.Attestation) {
      if (selected?.Attestation.toLowerCase() === 'no') {
        validationError = true;
        dispatch(errorNotification('Patient has not completed the Attestation document. You cannot proceed.'));
      }

      if (
        isBefore(parseISO(selected?.AttestationExpirationDate), new Date()) ||
        isSameDay(parseISO(selected?.AttestationExpirationDate), new Date())
      ) {
        validationError = true;
        dispatch(errorNotification('Patient Attestation document has expired. You cannot proceed.'));
      }
    }

    if (andEnterCart || andEnterPreOrderCart) {
      if (!register) {
        validationError = true;
        dispatch(errorNotification('Please select a register first'));
      }
      if (
        integrations?.CanUseMassDPHIntegration &&
        selected?.PatientType !== 'Recreational' &&
        features.RequireValidAllotmentCredentials
      ) {
        try {
          await validateMassDPHSavedCreds();
        } catch (ex) {
          validationError = true;
          dispatch(errorNotification('Your DPH Credentials are invalid! Go to settings and update to continue.'));
        }
      }
    }

    if (selected?.status !== 'Active') {
      validationError = features.BlockBannedPatientCheckin && selected?.status === 'Banned';
      dispatch(errorNotification(`Patient is ${selected?.status}.`));
    }

    if (validationError) {
      setLoading(false);
      return;
    }

    if (selected && register) {
      if (andEnterPreOrderCart) {
        history.push(`/create-preorder`);
        setLoading(false);
        return;
      }

      if (!checkedInShipmentId || !checkedInScheduleId) {
        const checkedInGuestResponse = await dispatch(
          checkInCustomer({
            guestId: selected.Guest_id,
            caregiverId: caregiverId,
            mjStateId: selected.MJStateIDNo,
            register: register.value,
          })
        ).unwrap();

        // ENG-41650 - payload might be null when checking in a Medical customer
        // into a Rec only location
        if (!checkedInGuestResponse) {
          setLoading(false);
          return;
        }

        const [checkedInGuest] = checkedInGuestResponse;
        checkedInShipmentId = checkedInGuest.ShipmentId;
        checkedInScheduleId = checkedInGuest.ScanResult;
      }

      if (checkedInShipmentId && checkedInScheduleId) {
        if (features.UseBioTrackPOS) {
          await biotrackScanGuestIn({
            PatientScan: selected.MJStateIDNo,
            ShipmentId: checkedInShipmentId,
          });
        }

        if (andEnterCart) {
          dispatch(
            setCheckedInGuest({
              ShipmentId: checkedInShipmentId,
              Guest_id: selected.Guest_id,
              TransactionCaregiver: caregiverId,
              ScheduleId: checkedInScheduleId,
            })
          );
          if (isUseMnEnabled) {
            dispatch(
              showSelectCollectorFromProfilePopup({
                customerId: selected.Guest_id,
                shipmentId: checkedInShipmentId,
              })
            );
          } else {
            goToCart({ guestId: selected.Guest_id, shipmentId: checkedInShipmentId });
          }
        } else {
          history.push(`/guestlist`);
        }
      }
    }
    setLoading(false);
  };

  const handleCreateOrder = async (guestId: number) => {
    if (checkPreorderEligible(guestId)) {
      setLoading(true);
      await dispatch(getCustomerDetails({ guestId }));
      setLoading(false);

      dispatch(
        showStartOrderPopup({
          onGoToCart: () => checkIn({ andEnterCart: true }),
          onCreatePreorder: async () => {
            dispatch(
              showCreatePreOrderFromProfilePopup({
                onCheckIn: () => checkIn({ andEnterPreOrderCart: true }),
              })
            );
          },
        })
      );
    } else {
      checkIn({ andEnterCart: true });
    }
  };

  const handleCheckinCaregiver = async (guestId: number) => {
    dispatch(
      showCheckinCaregiverPopup({
        onCheckin: checkIn,
        guestId,
      })
    );
  };

  return (
    <>
      {selected && !detailsLightLoading ? (
        <>
          {selected && (
            <Header>
              <NameSection>
                <SidePanelName>
                  <SelectedName>{selected.FullName}</SelectedName>
                </SidePanelName>
                {isCustomerBanned && (
                  <Pill text='Banned customer' variation='red' automationId='banned-customer-pill' />
                )}
              </NameSection>
              <GuestEditButton guestId={selected.Guest_id} />
            </Header>
          )}
          <SidePanelSection>
            <InfoRow>
              <InfoLabel>Nickname</InfoLabel>
              <InfoValue>{selected?.Nickname || '-'}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Driver's License #</InfoLabel>
              <InfoValue>{selected?.DriversLicenseId ?? '-'}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Driver's License Exp</InfoLabel>
              <InfoValue>
                {selected?.DLExpirationDate ? format(new Date(selected.DLExpirationDate), 'MM/dd/yyyy') || '-' : '-'}
              </InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>MMJ ID</InfoLabel>
              <InfoValue>{selected?.MJStateIDNo || '-'}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel idExpired={idExpired}>ID Exp</InfoLabel>
              <InfoValue idExpired={idExpired}>
                {selected?.MJExpirationDate ? format(new Date(selected?.MJExpirationDate), 'MM/dd/yyyy') : '-'}
              </InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Discount Groups</InfoLabel>
              <InfoValue>
                {selected.discounts?.length > 0 && selected.discounts[0].title !== ''
                  ? selected.discounts
                      .filter((item) => !groupIds.includes(item.id) && groupIds.push(item.id))
                      .map((item, idx) => (
                        <InfoValue key={item.id}>
                          {item.title}
                          {selected.discounts.length > 1 && idx !== selected.discounts.length - 1 ? ',' : ''}{' '}
                        </InfoValue>
                      ))
                  : '-'}
              </InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Last Purchase</InfoLabel>
              <InfoValue>
                {selected?.LastPurchase ? format(new Date(selected.LastPurchase), 'MM/dd/yyyy') || '-' : '-'}
              </InfoValue>
            </InfoRow>
            {(features.ShowLeafLogixLoyalty || features.ShowExternalLoyalty) && (
              <>
                <InfoRow>
                  <InfoLabel>Loyalty Points</InfoLabel>
                  <InfoValue>{selected?.LoyaltyPoints}</InfoValue>
                </InfoRow>
                {features.ShowExternalLoyalty && (
                  <InfoRow>
                    <InfoLabel>Loyalty Tier</InfoLabel>
                    <LoyaltyTier
                      isUseCuraleafLoyaltyTierEnabled={isUseCuraleafLoyaltyTierEnabled}
                      color={selected?.LoyaltyTextColor}
                      data-testid='customer-preview-loyalty-tier'
                    >
                      {selected?.LoyaltyTier}
                    </LoyaltyTier>
                  </InfoRow>
                )}
              </>
            )}
          </SidePanelSection>
          <SidePanelSection>
            <InfoRow>
              <InfoLabel>Note</InfoLabel>
              <InfoValue>
                {/* TODO: what field is it */}
                {selected?.Notes || '-'}
              </InfoValue>
            </InfoRow>
          </SidePanelSection>
          <SidePanelSection showBorder={false}>
            <InfoRow>
              <InfoLabel>Address</InfoLabel>
              <InfoValue>{selected?.address ? addressFormat(selected.address) : '-'}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Postal code</InfoLabel>
              <InfoValue>{selected?.address?.postal_code || ':'}</InfoValue>
            </InfoRow>
          </SidePanelSection>
          {selected && features.AllowCheckInFromCustomerSearch && (
            <>
              {needBirthdateCheck ? (
                <SidePanelSection sticky>
                  <CheckinButton
                    cta
                    onClick={() => {
                      dispatch(
                        showVerifyBirthdateFromProfilePopup({
                          guestDob: selected.DOB,
                          isAnonymous: selected.IsAnonymous,
                          onSuccess: () => {
                            setBirthdateVerified(true);
                          },
                          acctId: selected.Guest_id,
                        })
                      );
                    }}
                  >
                    Verify Birthdate
                  </CheckinButton>
                </SidePanelSection>
              ) : (
                <SidePanelSection sticky>
                  <ButtonGroup>
                    <CheckinButton
                      cta
                      secondary
                      disabled={isCheckedIn || loading || isCustomerBanned}
                      onClick={() => handleCheckinCaregiver(selected.Guest_id)}
                      automationId='customer-preview_button_check-in-caregiver'
                    >
                      Caregiver
                    </CheckinButton>
                    <CheckinButton
                      cta
                      secondary
                      disabled={isCheckedIn || loading || isCustomerBanned}
                      onClick={() => checkIn({})}
                      automationId='customer-preview_button_check-in'
                    >
                      Check In
                    </CheckinButton>
                  </ButtonGroup>
                  <CheckinButton
                    cta
                    loading={loading}
                    disabled={loading || isCustomerBanned}
                    onClick={() => handleCreateOrder(selected.Guest_id)}
                    automationId='customer-preview_button_enter-pos'
                  >
                    Create Order
                  </CheckinButton>
                </SidePanelSection>
              )}
            </>
          )}
        </>
      ) : (
        <NoCustomerSelectedDiv>
          <UserIcon width={100} height={100} />
          <NoCustomerSelectedMessage>Please select a guest</NoCustomerSelectedMessage>
        </NoCustomerSelectedDiv>
      )}
    </>
  );
};

export const CustomerPreview = () => (
  <>
    <CustomerPopups />
    <CustomerPreviewWithoutPopups />
  </>
);

const CheckinButton = styled(LoadingButton)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Header = styled(SidePanelSection)`
  position: sticky;
  top: 0;
  background: white;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NameSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SidePanelName = styled.div`
  text-overflow: ellipsis;
`;

const SelectedName = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.dutchie.almostBlack};
`;

const InfoRow = styled.div`
  color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoBaseStyles = css`
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.5%;
  line-height: 2rem;
`;

const InfoLabel = styled.span<{ idExpired?: boolean }>`
  ${InfoBaseStyles}
  text-align: left;
  color: ${({ idExpired }) => (idExpired ? colors.dutchie.red : colors.dutchie.grey)};
  &:after {
    content: ':';
  }
`;

const InfoValue = styled.span<{ idExpired?: boolean }>`
  ${InfoBaseStyles}
  text-align: right;
  color: ${({ idExpired }) => (idExpired ? colors.dutchie.red : colors.dutchie.almostBlack)};
`;

const LoyaltyTier = styled(InfoValue)<{ isUseCuraleafLoyaltyTierEnabled: boolean; color?: string }>`
  ${({ isUseCuraleafLoyaltyTierEnabled }) => isUseCuraleafLoyaltyTierEnabled && `
    text-transform: uppercase;
    font-weight: 600;
  `}

  ${({ isUseCuraleafLoyaltyTierEnabled, color }) => isUseCuraleafLoyaltyTierEnabled && color && `
    color: ${color}
  `}
`;

const NoCustomerSelectedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.primary};
  height: 100%;
  width: 100%;
`;

const NoCustomerSelectedMessage = styled.span`
  font-size: 16px;
  color: ${colors.grey};
`;

const UserIcon = styled(_UserIcon)`
  margin-bottom: 20px;
`;
