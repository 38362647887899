import React, { Fragment } from 'react';
import moment from 'moment';
import { CheckedInGuest } from 'models/Guest';
import { hasLength, isStringWithLength } from 'util/Helpers';
import { CardDetail } from './CardDetail';
import { InfoContainer } from '../styles';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { formatDate } from 'util/helpers/date-helpers/formatDate';

type Props = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
  listMode: boolean;
};

export const CustomerInfo: React.FC<Props> = React.memo(({ guest, cardOptionsStatus, listMode }) => {
  const displayPatientType = cardOptionsStatus['Customer Type'] && hasLength(guest.PatientType);
  const formattedDOB = formatDate({ date: guest.Dob, dateFormat: 'LLL d, yyyy', fallbackString: '--' });
  const displayTransactionCaregiver = isStringWithLength(guest.TransactionCaregiver);
  const displayDOB = cardOptionsStatus['Customer Date of Birth'] && hasLength(guest.Dob);

  return (
    <InfoContainer listMode={listMode} gridArea='2/1'>
      {displayTransactionCaregiver && (
        <CardDetail testId='order-card_transaction-caregiver_p' label='Caregiver' value={guest.TransactionCaregiver} />
      )}
      <NicknamePronouns cardOptionsStatus={cardOptionsStatus} guest={guest} />
      {displayDOB && <CardDetail testId='order-card_dob_p' label='DOB' value={formattedDOB} />}
      <LocationInfo cardOptionsStatus={cardOptionsStatus} guest={guest} />
      <DriversLicenseInfo cardOptionsStatus={cardOptionsStatus} guest={guest} />
      {displayPatientType && (
        <CardDetail testId='order-card_patient-type_p' label='Patient Type' value={guest.PatientType} />
      )}
      <MedicalInfo guest={guest} cardOptionsStatus={cardOptionsStatus} />
    </InfoContainer>
  );
});

const NicknamePronouns: React.FC<Omit<Props, 'listMode'>> = React.memo(({ cardOptionsStatus, guest }) => {
  const displayNicknameOrPronouns = cardOptionsStatus['Nickname'] || cardOptionsStatus['Pronouns'];
  const displayNickname = cardOptionsStatus['Nickname'] && hasLength(guest.nickname);
  const displayPronouns = cardOptionsStatus['Pronouns'] && hasLength(guest.Pronoun);

  return (
    <Fragment>
      {displayNicknameOrPronouns && (
        <Fragment>
          {displayNickname && (
            <CardDetail testId='order-card_customer-nickname_p' label='Nickname' value={guest.nickname} />
          )}
          {displayPronouns && (
            <CardDetail testId='order-card_customer-pronouns_p' label='Pronouns' value={guest.Pronoun} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

const LocationInfo: React.FC<Omit<Props, 'listMode'>> = React.memo(({ cardOptionsStatus, guest }) => {
  const displayAddressOrState = cardOptionsStatus['Address'] || cardOptionsStatus['State'];
  const displayAddress = cardOptionsStatus['Address'];
  const displayOnlyState = !cardOptionsStatus['Address'] && cardOptionsStatus['State'] && hasLength(guest.State);

  return displayAddressOrState ? (
    <>
      {displayAddress && (
        <>
          {guest.Street1 && <CardDetail testId='order-card_street_one_p' label='Street 1' value={guest.Street1} />}
          {guest.Street2 && <CardDetail testId='order-card_street_two_p' label='Street 2' value={guest.Street2} />}
          {(guest.City || guest.State || guest.PostalCode) && (
            <CardDetail
              testId='order-card_city-state-zip_p'
              label='Address'
              value={`${guest.City}, ${guest.State}, ${guest.PostalCode}`}
            />
          )}
        </>
      )}
      {displayOnlyState && <CardDetail testId='order-card_state_p' label='State' value={guest.State} />}
    </>
  ) : null;
});

const DriversLicenseInfo: React.FC<Omit<Props, 'listMode'>> = React.memo(({ guest, cardOptionsStatus }) => {
  const driversLicense = guest.identifications.filter((identification) => identification.type === 'DriversLicenseId');
  const driversLicenseNumber = driversLicense.length > 0 ? driversLicense[0].number : '';
  const driversLicenseId = guest.identifications.filter((identification) => identification.type === 'DriversLicenseId');
  const driversLicenseExp = driversLicenseId.length > 0 ? driversLicenseId[0].ExpirationDate.split(' ')[0] : '';
  const displayDriversLicenseExp = cardOptionsStatus["Driver's License Exp Date"] && hasLength(driversLicenseExp);
  const displayDriversLicenseNumber = cardOptionsStatus["Driver's License Number"] && hasLength(driversLicenseNumber);

  return (
    <Fragment>
      {displayDriversLicenseNumber && (
        <CardDetail testId='order-card_drivers-license-number_p' label='ID #' value={driversLicenseNumber} />
      )}
      {displayDriversLicenseExp && (
        <CardDetail testId='order-card_drivers-license-exp-date_p' label='ID Expires' value={driversLicenseExp} />
      )}
    </Fragment>
  );
});

const isMMJIDExpired = (features: Record<string, boolean>, medExpDate: string | null) => {
  if (!medExpDate) {
    return false;
  }

  let expireDate = moment(new Date(medExpDate));
  if (features?.ThroughMMJIdExpiry) {
    expireDate = expireDate.endOf('day');
  } else {
    expireDate = expireDate.startOf('day');
  }

  const now = moment().local();
  return expireDate.isBefore(now);
};

const MedicalInfo: React.FC<Omit<Props, 'listMode'>> = React.memo(({ guest, cardOptionsStatus }) => {
  const medId = guest.identifications.filter((identification) => identification.type === 'MJStateIDNo');
  const medExpDate = medId.length > 0 ? medId[0].ExpirationDate.split(' ')[0] : '';
  const features = useSelector((state: State) => state.settings.features);
  const medExpExpired = isMMJIDExpired(features, medExpDate);
  const displayMedIdNumber = cardOptionsStatus['Medical Card ID'] && hasLength(guest.MJStateIDNo);
  const displayMedExpDate = cardOptionsStatus['Med Card Exp Date'] && hasLength(medExpDate);

  return (
    <Fragment>
      {displayMedIdNumber && (
        <CardDetail
          testId='order-card_mmj-id_p'
          label='MMJ ID #'
          value={medExpExpired ? 'EXPIRED' : guest.MJStateIDNo}
          expired={medExpExpired}
        />
      )}
      {displayMedExpDate && <CardDetail testId='order-card_mmj-expire-date_p' label='MMJ Expire' value={medExpDate} />}
    </Fragment>
  );
});
