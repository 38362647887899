import { useCallback, useMemo } from 'react';
import { PeripheralType, peripheralTypeName } from '@dutchie/capacitor-hardware';

import { showAddNewDevicePopup } from 'store/actions/PopupsActions/SettingsPopupActions';
import { useHardwareServices } from '../../hooks/useHardwareServices';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useAuthorizeDevice } from './useAuthorizeDevice';

export const useAddDeviceListItem = (deviceType: PeripheralType) => {
  const dispatch = useAppDispatch();

  const addNewDeviceModalProps = useAppSelector((state) => state.popups.addNewDevicePopup);

  const { isWebHidSupported, isWebSerialSupported, isWebUsbSupported } = useHardwareServices();

  // Memoized values

  const deviceDescription = useMemo(() => {
    if (deviceType === PeripheralType.labelPrinter || deviceType === PeripheralType.receiptPrinter) {
      return 'printer';
    }
    return peripheralTypeName(deviceType).toLowerCase();
  }, [deviceType]);

  const isSupported = useMemo(() => {
    switch (deviceType) {
      case PeripheralType.labelPrinter:
      case PeripheralType.receiptPrinter:
        return isWebUsbSupported;
      case PeripheralType.scale:
        return isWebSerialSupported;
      case PeripheralType.scanner:
        return isWebHidSupported;
    }
  }, [deviceType, isWebHidSupported, isWebSerialSupported, isWebUsbSupported]);

  // Handlers

  const { handleAuthorize } = useAuthorizeDevice(deviceType);

  const handleShowAddNewDeviceModal = useCallback(() => {
    dispatch(
      showAddNewDevicePopup({
        deviceDescription,
        deviceType,
        onAuthorize: handleAuthorize,
      })
    );
  }, [dispatch, deviceDescription, deviceType, handleAuthorize]);

  return {
    addNewDeviceModalProps,
    deviceDescription,
    handleShowAddNewDeviceModal,
    isSupported,
  };
};
