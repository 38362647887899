import React, { ReactNode } from 'react';
import { colors } from 'css/Theme';
import styled from 'styled-components';
import { createDutchieInStoreCartRequest, pollingFallbackApiRequest } from 'api/DutchiePayApi';
import { CreateDutchieInStoreCartRequest, PollingFallbackRequest } from 'models/DutchiePay';

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
`;
export const StepContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0;
  gap: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
`;
export const PrimaryTextContainer = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: 'kern' off, 'calt' off;
  color: ${colors.dutchie.gunmetal};
`;
export const SecondaryTextContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  font-feature-settings: 'calt' off;
  color: ${colors.dutchie.darkGrey};
`;

type DPInStoreStepProps = {
  illustration: ReactNode;
  primaryText: string;
  secondaryText: string;
};

export const ApiStates = {
  idle: 'idle',
  active: 'active',
  success: 'success',
  error: 'error',
};

export const createDutchieInStoreCart = async (shipmentId: number, customerCode?: string) => {
  const LspId = window.sessionStorage.getItem('LspId');
  const LocId = window.sessionStorage.getItem('LocId');
  const SessionId = window.sessionStorage.getItem('SessionId');

  if (!SessionId || !LocId || !LspId) {
    return;
  }

  const request: CreateDutchieInStoreCartRequest = {
    SessionId: SessionId,
    LspId: LspId,
    LocId: LocId,
    ShipmentId: shipmentId,
  };
  if (customerCode) {
    request.PinCode = customerCode;
  }

  return createDutchieInStoreCartRequest(request);
};

export const validatePinInput = (code: string): boolean => {
  if (code.length === 6) {
    return true;
  }
  return false;
};

// Appends '_isdp' when the sessionStorage item 'DevDutchiePayInStoreMode' is set to 'develop'
// for testing in development environments
export const handleDevPinCodeAddendum = (customerPinCode: string) => {
  const devTestBypass = window.sessionStorage.getItem('DevDutchiePayInStoreMode');
  let processedCustomerPinCode = customerPinCode;
  if (devTestBypass === 'develop') {
    if (customerPinCode) {
      processedCustomerPinCode = `${customerPinCode}_isdp`;
    }
  }
  return processedCustomerPinCode;
};

export const DPInStoreStep = ({ illustration, primaryText, secondaryText }: DPInStoreStepProps) => {
  return (
    <Container>
      <StepContentContainer>
        {illustration}
        <PrimaryTextContainer>{primaryText}</PrimaryTextContainer>
        <SecondaryTextContainer>{secondaryText}</SecondaryTextContainer>
      </StepContentContainer>
    </Container>
  );
};

export const sendPollingFallbackRequest = async (shipmentId: number) => {
  const LspId = window.sessionStorage.getItem('LspId');
  const LocId = window.sessionStorage.getItem('LocId');
  const SessionId = window.sessionStorage.getItem('SessionId');

  if (!SessionId || !LocId || !LspId) {
    return;
  }

  const request: PollingFallbackRequest = {
    LspId: LspId,
    LocId: LocId,
    ShipmentId: shipmentId,
  };

  return pollingFallbackApiRequest(request);
};
