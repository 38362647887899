import React, { FC } from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from 'css/Theme';

import { SidePanelSection as _SidePanelSection } from 'components/layout';
import { ReactComponent as LoyaltySvg } from 'assets/icons/loyalty-icon.svg';
import { Button, ButtonWithDropDown } from 'components/buttons';
import { MenuItem } from 'components/backoffice/menu-item';
import { useRedeemReward } from './PreorderRedemption.hooks';
import { PreOrderRedemption } from 'models/Cart';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

export const PreorderRedemption: FC = () => {
  const {
    data: { PreOrderRedemptions: rewards },
  } = useGetCartDetails();

  const remainingRewards = rewards.filter((reward) => !reward.IsRedeemed);
  const redeemReward = useRedeemReward();

  if (remainingRewards.length === 0) {
    return null;
  }

  const renderReward = (reward: PreOrderRedemption) => {
    return (
      <Reward value onClick={() => redeemReward(reward)}>
        <RewardDescription>{reward.RedemptionDescription}</RewardDescription>
        <RewardButton isAdornment>Redeem</RewardButton>
      </Reward>
    );
  };

  return (
    <SidePanelSection>
      <RewardsContainer>
        <RewardLabel>
          <LoyaltyIcon />
          Rewards
        </RewardLabel>
        {remainingRewards.length === 1 ? (
          <SingleRewardButton secondary onClick={() => redeemReward(remainingRewards[0])}>
            <RewardDescription>{remainingRewards[0].RedemptionDescription}</RewardDescription>
            <RewardButton isAdornment>Redeem</RewardButton>
          </SingleRewardButton>
        ) : (
          <ButtonWithDropDown<PreOrderRedemption>
            secondary
            buttonHeight='2.5rem'
            items={remainingRewards}
            customItemRender={renderReward}
          >
            {remainingRewards.length} rewards remaining to be redeemed
          </ButtonWithDropDown>
        )}
      </RewardsContainer>
    </SidePanelSection>
  );
};

const SidePanelSection = styled(_SidePanelSection)`
  padding: 1rem;
`;

const RewardsContainer = styled.div`
  background: ${colors.dutchie.blue10};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${breakpoints.smallTablet.maxQuery} {
    flex-direction: row;
    gap: 1.25rem;

    & > *:last-child {
      flex: 1;
    }
  }
`;

const RewardLabel = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LoyaltyIcon = styled(LoyaltySvg)`
  width: 1rem;
  height: 1rem;
`;

const SingleRewardButton = styled(Button)`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1rem;
`;

const RewardButton = styled(Button)`
  font-size: 0.8125rem !important;
  line-height: 1rem !important;
`;

const Reward = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const RewardDescription = styled.div`
  text-align: left;
  flex-grow: 1;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
