import React, { FC } from 'react';
import styled from 'styled-components';

import { billDenominations, CashEntryMode, coinDenominations } from 'store/reducers/CashManagementReducer';
import { BottomDrawer, StickyHeader } from 'components/layout';
import { Button } from 'components/buttons';
import { breakpoints, colors } from 'css/Theme';
import { CashAmount } from 'components/text';
import { CashInput } from './CashInput';
import { PORTAL_ID as FullScreenPortalId } from 'components/FullScreenPortal';
import { RemovalPopup } from 'components/popups';
import { SwitchIcon } from 'assets/icons/navigation/switch';
import { useCashDetailsPanel } from './useCashDetailsPanel';

import type { NumberFormatValues } from 'react-number-format';

export type CashDetailsProps = {
  onSave: (values: NumberFormatValues) => void;
};

export const CashDetailsPanel: FC<CashDetailsProps> = (props) => {
  const {
    cashDetails,
    entryMode,
    handleCancelClearEntries,
    handleClear,
    handleClose,
    handleConfirmClearEntries,
    handleSave,
    handleSwitchEntryMode,
    headerRef,
    isCashDetailsPanelOpen,
    isSaveDisabled,
    showConfirmClearEntries,
    totalCounted,
  } = useCashDetailsPanel(props);

  return (
    <BottomDrawer
      fullHeight
      open={isCashDetailsPanelOpen}
      onClose={handleClose}
      portalId={FullScreenPortalId}
      marginX={0}
      marginY={40}
    >
      <Container data-testid='cash-details-panel-container'>
        <StickyHeader headerRef={headerRef}>
          <Header>
            <Title>Enter cash details</Title>
            <HeaderSection>
              <ActionButton tertiary onClick={handleClose}>
                Close
              </ActionButton>
              <ActionButton onClick={handleSave} disabled={isSaveDisabled}>
                Save
              </ActionButton>
            </HeaderSection>
          </Header>
        </StickyHeader>
        <Fields>
          <Section>
            <SectionHeader>Bills</SectionHeader>
            <SectionContent>
              {billDenominations.map((billDenomination) => (
                <InputRow key={billDenomination}>
                  <Denomination>${billDenomination}</Denomination>
                  <CashInput entryMode={entryMode} denomination={billDenomination} />
                  {entryMode === CashEntryMode.QUANTITY && (
                    <StyledCashAmount value={Number(cashDetails[billDenomination]) * Number(billDenomination)} />
                  )}
                </InputRow>
              ))}
            </SectionContent>
          </Section>
          <Section>
            <SectionHeader>Coins</SectionHeader>
            <SectionContent>
              {coinDenominations.map((coinDenomination) => (
                <InputRow key={coinDenomination}>
                  <Denomination>{coinDenomination === 'all' ? 'Total' : `$${coinDenomination}`}</Denomination>
                  <CashInput
                    entryMode={coinDenomination === 'all' ? CashEntryMode.VALUE : entryMode}
                    denomination={coinDenomination}
                  />
                  {entryMode === CashEntryMode.QUANTITY && (
                    <StyledCashAmount
                      value={
                        coinDenomination === 'all'
                          ? Number(cashDetails.all)
                          : Number(cashDetails[coinDenomination]) * Number(coinDenomination)
                      }
                    />
                  )}
                </InputRow>
              ))}
            </SectionContent>
          </Section>
        </Fields>
        <TotalFooter>
          <TotalCounted>
            Total counted:
            <CashAmount automationId='cash-details-total-counted' thousandSeparator={true} value={totalCounted} />
          </TotalCounted>
          <ActionButton secondary onClick={handleSwitchEntryMode} automationId='cash-details-toggle-entry-mode'>
            <SwitchIcon color={colors.dutchie.darkGrey} />
            {entryMode === CashEntryMode.VALUE ? 'Switch to quantity' : 'Switch to cash counted'}
          </ActionButton>
          <ActionButton tertiary onClick={handleClear}>
            Clear entries
          </ActionButton>
        </TotalFooter>
      </Container>
      {showConfirmClearEntries && (
        <RemovalPopup
          message='Are you sure you want to clear all entries?'
          hide={handleCancelClearEntries}
          confirm={{ action: handleConfirmClearEntries, text: 'Yes, clear entries' }}
          cancel={{ action: handleCancelClearEntries }}
        />
      )}
    </BottomDrawer>
  );
};

const StyledCashAmount = ({ value }: { value: number }) => {
  const renderCashAmountText = (formattedValue: string) => {
    if (!formattedValue || formattedValue === '$ 0.00') {
      return <DisplayAmount>$ ##.##</DisplayAmount>;
    }
    return <DisplayAmount>{formattedValue}</DisplayAmount>;
  };

  return <CashAmount prefix='$ ' thousandSeparator={true} renderText={renderCashAmountText} value={value} />;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const ActionButton = styled(Button)`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Fields = styled.div`
  flex: 1;
  display: flex;
  gap: 2rem;
  padding: 1.5rem 2rem;

  ${breakpoints.smallTablet.maxQuery} {
    flex-direction: column;
  }
`;

const SectionHeader = styled.header`
  color: ${colors.dutchie.gray90};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Denomination = styled.label`
  width: 3rem;
  flex: 0 0 3rem;
  color: ${colors.dutchie.grey30};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const DisplayAmount = styled.div`
  width: 5.6rem;
  flex: 0 0 5.6rem;
  margin-left: 1rem;
  color: ${colors.dutchie.grey50};
  font-size: 0.875rem;
  line-height: 1.25rem;
  white-space: nowrap;
`;

const TotalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 1.5rem 2rem;

  position: sticky;
  left: 0;
  bottom: 0;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: ${colors.dutchie.primaryWhite};
`;

const TotalCounted = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  flex: 1;
`;
