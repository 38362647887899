import { useContext, useState } from 'react';
import { State } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { usePatientPolicyValidator } from 'util/hooks';
import { PaymentType } from 'models/Checkout';
import { useCartPopups } from 'components/CartPopups';
import { CheckoutContext } from './CheckoutContext';
import { warningNotification, errorNotification } from 'store/actions/NotificationsActions';
import { performCheckout, checkPinAndPerformCheckout, setApprovalCode } from 'store/actions/CheckoutActions';
import {
  isDriversLicenseExpired,
  isMMJLicenseAboutExpire,
  isMMJLicenseExpired,
  mmjLicenseDaysFromExpiration,
} from 'util/Helpers';
import { differenceInYears, isBefore, isSameDay, isValid, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';
import * as CartApi from 'api/CartApi';
import { ExpiredItemInfo } from 'models/Cart';
import { logger, customEventKeys } from 'util/logger';
import { useGuestAgeInfo } from 'util/hooks/cart/useGuestAgeInfo';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useIsUseMnEnabled } from 'util/hooks/useIsUseMnEnabled';
import { useIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type useOnCheckoutProps = {
  imgRef: React.RefObject<HTMLInputElement> | null;
};

export const useOnCheckout = ({ imgRef }: useOnCheckoutProps) => {
  const dispatch = useDispatch();
  const settings = useSelector((state: State) => state.settings);
  const checkout = useSelector((state: State) => state.checkout);

  const guest = useSelector((state: State) => state.customer.details);
  const isUseMnEnabled = useIsUseMnEnabled(guest);
  const isCheckedInWithCaregiver = useSelector((state: State) => state.cart.isCheckedInWithCaregiver);
  const guestAgeCheck = useGuestAgeInfo({ guest, isCheckedInWithCaregiver });
  const cartPopups = useCartPopups();
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [dlSaved, setDlSaved] = useState(false);
  const { paymentSummary } = useContext(CheckoutContext);
  const patientPolicyValidator = usePatientPolicyValidator();
  const ldClient = useLDClient();
  const useNewPinCheck = ldClient?.variation('pos.register.use-new-pin-check.rollout', false);
  const useMMCEUAllotmentCheck = ldClient?.variation('pos.register.enable-mmceu-calculator.rollout', false);

  const isDaysSupplyEnabled = useIsDaysSupplyFeatureEnabled();

  // these will all evaluate to undefined if the payment type is not a key on paymentSummary, they all need a ?? 0 on the end to coalesce them to a number
  const cash = paymentSummary[PaymentType.Cash];
  const debit = paymentSummary[PaymentType.Debit];
  const check = paymentSummary[PaymentType.Check];
  const creditcard = paymentSummary[PaymentType.Credit];
  const electronic = paymentSummary[PaymentType.Digital];
  const prepayment = paymentSummary[PaymentType.Prepayment];
  const preauth = paymentSummary[PaymentType.Dutchie];
  const mmap = paymentSummary[PaymentType.MMAP];
  const hub = paymentSummary[PaymentType.Hub];

  const { guestId, shipmentId } = useTransactionManager();

  const { data: cart } = useGetCartDetails();

  const total = cart.GrandTotalRounded;

  const dlPhotoRequired = () => {
    return settings.features.RequireDLPhotoPOS && cart.Deliverable && !dlSaved;
  };

  const signatureRequired = () => {
    return (
      !signatureSaved &&
      (settings.features.RequireSignatureCheck || (settings.features.RequireDeliverySignature && cart.Deliverable))
    );
  };

  const hasCannabisItemInCart = () => {
    const hasCannabisItemInCart = cart.Cart.some((cartItem) => cartItem.CannbisProduct === 'Yes');
    return hasCannabisItemInCart;
  };

  const doCheckout = async () => {
    logger.info(`perform checkout for shipment ${cart.ShipmentId}`, {
      key: customEventKeys.cart.checkout,
      shipmentId: cart.ShipmentId,
      customerId: cart.CustomerId,
      cart: cart.Cart,
      preorders: cart.PreOrders,
      discounts: cart.Discounts,
      grandTotal: cart.GrandTotal,
      totalDiscount: cart.TotalDiscount,
      totalItems: cart.TotalItems,
      transactionReference: cart.TransactionReference,
      currentRegister: cart.CurrentRegister,
    });
    if (settings.features.CheckOutPinRequired) {
      if (useNewPinCheck) {
        await dispatch(checkPinAndPerformCheckout(checkout.managerPin));
      } else {
        dispatch(checkPinAndPerformCheckout(checkout.managerPin));
      }
    } else {
      await dispatch(performCheckout(undefined));
    }
  };

  const onCheckout = async () => {
    if (!guest || !guestId || !shipmentId) {
      return null;
    }

    if (settings.features.PharmacistVerification && !cart.VerifiedBy) {
      cartPopups.showVerifyTransactionPopup(guestId, shipmentId);
      return;
    }

    if (signatureRequired()) {
      cartPopups.showSignaturePopup({
        setSignatureSaved: setSignatureSaved,
        shipmentId: shipmentId,
      });
      return;
    }

    if (dlPhotoRequired()) {
      imgRef?.current?.click();
      return;
    }

    if (isUseMnEnabled && !validateCheckout()) {
      dispatch(setApprovalCode(checkout.approvalCode));
    }

    if (checkout.loading || validateCart() || !validateCheckout()) {
      return;
    }

    if (settings.features.CheckExpirationDate) {
      const containsExpiredItems = await isExpiredItemsInCart();
      if (containsExpiredItems) {
        return;
      }
    }

    const guestAge = guest?.DOB ? guest?.DOB : new Date();

    if (settings.features.RequireDoctorForCheckIn) {
      if (guest && guest.IsMedical && !(parseInt(guest.doctor) > 0)) {
        dispatch(errorNotification('Patient does not have a doctor on record.'));
        return;
      }
    }

    if (settings.features.RequirePrescriptionForCheckIn) {
      if (guest && !guest.ValidPrescription) {
        dispatch(errorNotification('Patient does not have a valid prescription on record.'));
        return;
      }
    }

    if (!guest?.CustomerTypeId) {
      dispatch(errorNotification('Patient does not have a customer type on record.'));
      return;
    }

    if (!guest?.IsAnonymous && settings.features.RequireValidDL) {
      const hasId = guest?.identifications[0].number;
      const isLicenseExpired = isDriversLicenseExpired(guest);
      if (!hasId || isLicenseExpired) {
        dispatch(errorNotification("Customer driver's license missing or expired"));
        return;
      }
    }

    if (settings.features.MMJIdExpirationDateCheck && guest?.IsMedical) {
      const hasMMJId = guest?.identifications[1].number;
      const hasExpirationDate = guest?.identifications[1].ExpirationDate;
      const isMMJIdExpired = isMMJLicenseExpired(guest);
      if (!hasMMJId || !hasExpirationDate || isMMJIdExpired) {
        dispatch(errorNotification('Customer MMJ Id missing or expired'));
        return;
      }
    }

    if (guest && !(await patientPolicyValidator(guest))) {
      dispatch(errorNotification('Customer cannot checkout until policy is signed'));
      return;
    }

    if (guestAgeCheck.isBlocked) {
      dispatch(errorNotification(`Patient is under the age of ${guestAgeCheck.defaultMinorAge}. You cannot proceed`));
      return;
    }

    if (settings.features.MMJIdExpirationDateCheck && guest?.IsMedical && settings.features.NotifyCard30DayExpiration) {
      const ids = guest.identifications.filter((x) => {
        return x.type === 'MJStateIDNo';
      });
      const dl = ids.length > 0 ? ids[0] : null;
      if (!dl || !dl.ExpirationDate) {
        dispatch(warningNotification('Patient does not have an MMJ License expiration date on file'));
      } else if (isMMJLicenseAboutExpire(guest)) {
        const days = mmjLicenseDaysFromExpiration(guest);
        dispatch(warningNotification(`Patient's MMJ License is about to expire in ${days} days.`));
      }
    }

    if (guest?.CustomerTypeId === 1 && settings.features.Attestation) {
      if (guest?.Attestation.toLowerCase() === 'no') {
        dispatch(errorNotification('Patient has not completed the Attestation document. You cannot proceed.'));
        return;
      }

      if (
        isBefore(parseISO(guest?.AttestationExpirationDate), new Date()) ||
        isSameDay(parseISO(guest?.AttestationExpirationDate), new Date())
      ) {
        dispatch(errorNotification('Patient Attestation document has expired. You cannot proceed.'));
        return;
      }
    }

    if (
      settings.features.POSNoteUnder18 &&
      (differenceInYears(new Date(), new Date(guestAge)) < 18 || !isValid(new Date(guestAge)))
    ) {
      dispatch(warningNotification('Patient DOB not valid or patient is under 18'));
    } else if (
      settings.features.POSNoteUnder21 &&
      (differenceInYears(new Date(), new Date(guestAge)) < 21 || !isValid(new Date(guestAge)))
    ) {
      dispatch(warningNotification('Patient DOB not valid or patient is under 21'));
    }

    if (isDaysSupplyEnabled && isOverDaysSupplyLimit()) {
      return;
    }

    const isMississippi = settings.locationSettings?.State.toLowerCase() === 'ms' && useMMCEUAllotmentCheck;
    const checkAllotment = !isDaysSupplyEnabled;
    if (!checkAllotment || !isOverAllotment(isMississippi)) {
      await doCheckout();
    }
  };

  const validateCheckout = () => {
    return !(
      isUseMnEnabled &&
      //If there's no cannabis items this won't get transmitted to the state so don't require an approval code
      cart.Cart.filter((x) => x.CannbisProduct?.toLowerCase() === 'yes').length > 0 &&
      (!checkout.approvalCode || checkout.approvalCode.length !== 8)
    );
  };

  const validateCart = () => {
    let validationError = false;
    /* don't allow checkout for empty cart */

    if (cart.Cart.length === 0) {
      dispatch(
        warningNotification(
          'Cart is empty. Please add an item or assign a package to a preorder item before attempting to complete order.'
        )
      );
      validationError = true;
    }

    if (!guest) {
      return null;
    }

    if (settings.features.RequireAuthCodeOnElectronicTransactions) {
      const paymentMethod = checkout.payment?.methods.find((m) => m.type === PaymentType.Debit);
      if (paymentMethod?.amount && isEmpty(paymentMethod.authCode)) {
        dispatch(warningNotification('Must use Auth Code with debit transaction'));
        validationError = true;
      }
    }
    // some of these are gonna be undefined so payment is always NaN so the payment < total check is doing nothing right now
    // we need a less manual way to compute payment so it is robust against future changes to our payment types and still computes an accurate payment total
    const payment = cash + debit + electronic + check + creditcard + prepayment + preauth + mmap + hub;

    if (payment < total) {
      dispatch(warningNotification('Please make sure that total payment is provided'));
      validationError = true;
    }
    return validationError;
  };

  const isExpiredItemsInCart = async () => {
    let anyExpiredItemsInCart = false;

    const serialNumbers = cart.Cart.map((item) => item.SerialNo.toString());
    const expiredItems = (await CartApi.getExpiredItemsInCart(serialNumbers)) as Array<ExpiredItemInfo>;
    if (expiredItems && expiredItems.length > 0) {
      anyExpiredItemsInCart = true;
      const expiredSerialNumbers = expiredItems.map((expiredItem) => expiredItem.SerialNumber.toString());
      cartPopups.showPastExpirationDatePopup(() => {
        const isMississippi = settings.locationSettings?.State.toLowerCase() === 'ms' && useMMCEUAllotmentCheck;
        if (!isOverAllotment(isMississippi)) {
          doCheckout();
        }
      }, expiredSerialNumbers);
    }

    return anyExpiredItemsInCart;
  };

  const isOverDaysSupplyLimit = () => {
    // if guest is already over their allotment for the day due to an override from a previous transaction, we still want to allow them to purchase non-cannabis items
    if (!hasCannabisItemInCart()) {
      return false;
    }

    const daysSupplyInCart = cart.Cart.reduce(
      (total, cartItem) =>
        total +
        (typeof cartItem.DaysSupply === 'string'
          ? parseFloat(cartItem.DaysSupply)
          : cartItem.DaysSupply
            ? cartItem.DaysSupply
            : 0.0),
      0.0
    );
    const daysSupplyRemaining = guest?.DaysSupplyRemaining ?? 0;
    if (Number.isFinite(daysSupplyInCart) && Number.isFinite(daysSupplyRemaining)) {
      if (daysSupplyRemaining < daysSupplyInCart || daysSupplyRemaining < 0) {
        const overlimitTotal = daysSupplyInCart - daysSupplyRemaining;
        if (settings.features.EnforceDaysSupplyLimit) {
          dispatch(
            errorNotification(`Unable to checkout. Patient days supply exceeded by ${Math.abs(overlimitTotal)}.`)
          );
        } else {
          cartPopups.showOverLimitPopup(
            doCheckout,
            `You are over your days supply limit by ${Math.abs(overlimitTotal)}. Do you wish to continue?`
          );
        }
        return true;
      }
    }
    return false;
  };

  const isOverAllotment = (isMississippi = false) => {
    // if guest is already over their allotment for the day due to an override from a previous transaction, we still want to allow them to purchase non-cannabis items
    if (!hasCannabisItemInCart()) {
      return false;
    }

    const totalAllotmentRemaining = cart.Allotment.TotalRemaining;
    if (totalAllotmentRemaining < 0) {
      const limitExceededBy = Math.abs(totalAllotmentRemaining);
      const errorDetails = !isMississippi ? `${limitExceededBy}g` : `${limitExceededBy}g or ${(limitExceededBy / 3.5).toFixed(2)} MMCEUs`;
      if (settings.features.EnforcePurchaseLimit) {
        dispatch(
          errorNotification(`Unable to checkout. Patient allotment exceeded by ${errorDetails}.`)
        );
      } else {
        cartPopups.showOverLimitPopup(
          doCheckout,
          `You are over your limit by ${errorDetails}. Do you wish to continue?`
        );
      }
      return true;
    }
    return false;
  };

  return {
    onCheckout,
    doCheckout,
    signatureSaved,
    setSignatureSaved,
    signatureRequired,
    dlSaved,
    setDlSaved,
    dlPhotoRequired,
  };
};
