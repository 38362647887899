import { popupWidth } from 'css/Theme';

type PopupSizeProps = {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  width?: string;
};

export const getPopupSize = ({ small, medium, large, width }: PopupSizeProps): string => {
  if (small) {
    return popupWidth.small;
  }

  if (medium) {
    return popupWidth.medium;
  }

  if (large) {
    return popupWidth.large;
  }

  return width || popupWidth.small;
};
