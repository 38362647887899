
import { resetPaymentsHubCartDisplay } from 'api/CheckoutApi';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { logger } from 'util/logger/Logger';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { usePaymentsHubStreamCancellationV2 } from './launch-darkly/usePaymentsHubStreamCancellationV2';
import { useHistory } from 'react-router';

export function useResetHubCartDisplayOnNavigation() {
  const isUsePaymentsHubEnabled = useSelector((state: State) => state.settings.integrations?.UsePaymentsHub);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const guest = useSelector((state: State) => state.customer.details);
  const ldClient = useLDClient();
  const usePaymentsHubStreamCancellationV2Flag = usePaymentsHubStreamCancellationV2();
  const cartPathName = '/cart';
  const history = useHistory();

  useEffect(() => {
    if (usePaymentsHubStreamCancellationV2Flag) {
      const listener = history.listen((l) => {
        const location = l as { pathname: string };
        const newLocationIsNotCart = location.pathname !== cartPathName;

        if (newLocationIsNotCart && isUsePaymentsHubEnabled && registerId != null) {
          resetPaymentsHubCartDisplay({ RegisterId: registerId }).catch((error) => {
            logger.error('resetPaymentsHubCartDisplay listener caught error', { error });
          });
        }
      });
      return () => listener();
    }
  }, [guest, history, isUsePaymentsHubEnabled, ldClient, registerId, usePaymentsHubStreamCancellationV2Flag]);
}
