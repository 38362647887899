import { useMemo, useState } from 'react';

import type { OrderPrice } from './TotalsPanel';
import type { PreorderMetadataValues } from 'models/Customer';

type UseTotalsPanelParams = {
  orderMetadata: PreorderMetadataValues;
  orderPrice: OrderPrice;
};

export const useTotalsPanel = ({ orderMetadata, orderPrice }: UseTotalsPanelParams) => {
  // Local state
  const [isTotalsDrawerOpen, setIsTotalsDrawerOpen] = useState(false);

  // Computed values

  const totals = useMemo(() => {
    return {
      SubTotal: orderPrice.SubTotal,
      TotalDiscountAndLoyalty: orderPrice.Discount,
      Tax: orderPrice.Taxes,
      RoundingAmount: orderPrice.RoundedDifference,
      GrandTotalRounded: orderPrice.Total,
    };
  }, [orderPrice]);

  // Handlers
  const handleCloseTotalsDrawer = () => {
    if (isTotalsDrawerOpen) {
      setIsTotalsDrawerOpen(false);
    }
  };

  return {
    isTotalsDrawerOpen,
    totals,
    setIsTotalsDrawerOpen,
    handleCloseTotalsDrawer,
  };
};
