import { EmbeddedTable } from 'components/tables';
import _ from 'lodash';
import { Delivery } from 'models/Delivery';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { State } from 'store';
import {
  getDeliveries,
  moveRoute,
  printMergedManifests,
  printMultipleManifests,
  selectDeliveries,
  selectRoute,
  setDisableAutorefresh,
  setExpandedRoute,
  setRouteOrder,
  setRouteToCancel,
  setRouteToStart,
  setRouteToSuspend,
} from 'store/actions/DeliveryActions';
import { warningNotification } from 'store/actions/NotificationsActions';
import {
  showConfigureDeliveriesPopup,
  showDeliverySettingsPopup,
  showTransactionPopup,
} from 'store/actions/PopupsActions';
import styled from 'styled-components';
import {
  DeliveryAddressCell,
  DeliveryCell,
  DeliveryDispatchCell,
  DeliveryStatusCell,
  DeliveryWindowCell,
  RouteCell,
  RouteStatusCell,
  RouteTimeCell,
} from './TableCell';
import { DecoratedDeliveryRoute, useDecoratedDeliveryRoutes } from './useDecoratedDeliveryRoutes';

const EmptyMessage = styled.div`
  color: rgba(0, 0, 0, 0.5);
  padding: 24px 0;
  text-align: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #e3e7e9;
`;

export const DeliveryRoutesTableV2: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const permissions = useSelector((state: State) => state.settings.permissions);
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const { deliveries, routes } = useDecoratedDeliveryRoutes();
  const features = useSelector((state: State) => state.settings.features);
  const UsePlaceholderDirections = useSelector((state: State) => state.settings.features.UsePlaceholderDirections);
  const selectedDeliveries = useSelector((state: State) => state.deliveryList.selectedDeliveries);

  const UNKNOWN_ROUTE_ID = 'UNKNOWN';
  // Convert numerical IDs to string ones
  const getDeliveryId = (deliveryNumber: number) => `delivery-${deliveryNumber}`;
  const getRouteId = (routeNumber: number | null) => `route-${routeNumber ?? UNKNOWN_ROUTE_ID}`;

  const sortedDeliveries = useMemo(() => {
    return _.orderBy(
      deliveries,
      [(x) => x.ManifestStopNumber, (x) => x.TimeWindowStartDate, (x) => x.CheckInDateUTC],
      ['desc', 'desc', 'desc']
    );
  }, [deliveries]);

  const sortedRoutes = useMemo(() => {
    return _.orderBy(
      routes,
      [(x) => x.Unassigned, (x) => x.FromTime, (x) => x.ToTime, (x) => x.Id],
      ['desc', 'asc', 'asc', 'asc']
    );
  }, [routes]);

  const handleEditRoute = useCallback(
    async (route: DecoratedDeliveryRoute) => {
      if (route.Id) {
        await dispatch(selectRoute(route.Id));
        dispatch(showConfigureDeliveriesPopup({ createRoute: false }));
      }
    },
    [dispatch]
  );

  const handleCancelRoute = useCallback(
    (route: DecoratedDeliveryRoute) => {
      dispatch(setRouteToCancel(route.undecoratedRoute));
    },
    [dispatch]
  );

  const handleEndRoute = useCallback(
    (route: DecoratedDeliveryRoute) => {
      const id = route.Id;
      if (id === null) {
        // TODO: prevent handleEndRoute from showing in null route menu
        return;
      }

      dispatch(selectRoute(id));
      history.push('/deliveryRoute');
    },
    [dispatch, history]
  );

  const handlePrintManifests = useCallback(
    (route: DecoratedDeliveryRoute) => {
      dispatch(
        printMultipleManifests(
          deliveries.filter(({ DeliveryRouteId }) => DeliveryRouteId === route.Id).map(({ ShipmentId }) => ShipmentId)
        )
      );
    },
    [deliveries, dispatch]
  );

  const handlePrintMergedManifest = useCallback(
    (route: DecoratedDeliveryRoute) => {
      if (route.Id) {
        dispatch(printMergedManifests(route.Id));
      }
    },
    [dispatch]
  );

  const handleMoveRoute = useCallback(
    (deliveries: Delivery[], toRoute: DecoratedDeliveryRoute) => {
      if (permissions.EditRoutes) {
        // endpoint on the delivery - delivery controller "delivery/add-shipments-to-route" { deliveryRouteId: number|null, shipmentIds: number[] }
        dispatch(
          moveRoute({
            ShipmentIds: deliveries.map(({ ShipmentId }) => ShipmentId),
            DeliveryRouteId: toRoute.Id,
            UsePlaceholderDirections,
          })
        ).finally(() => dispatch(getDeliveries()));
      }
    },
    [UsePlaceholderDirections, dispatch, permissions.EditRoutes]
  );

  const handleChangeOrder = useCallback(
    (toRoute: DecoratedDeliveryRoute, deliveries: Delivery[]) => {
      if (permissions.EditRoutes && toRoute?.Id) {
        dispatch(
          setRouteOrder({
            ShipmentIds: deliveries.map(({ ShipmentId }) => ShipmentId),
            DeliveryRouteId: toRoute.Id,
            UsePlaceholderDirections,
          })
        ).finally(() => dispatch(getDeliveries()));
      }
    },
    [UsePlaceholderDirections, dispatch, permissions.EditRoutes]
  );

  const openSingleDelivery = useCallback(
    async (delivery?: Delivery) => {
      const deliveryToUse = delivery ?? selectedDeliveries[0];
      if (!register) {
        dispatch(warningNotification('You must select a register to open Delivery Settings for 1 Order'));
      } else {
        dispatch(showDeliverySettingsPopup({ onSave: () => dispatch(getDeliveries()), delivery: deliveryToUse }));
      }
    },
    [selectedDeliveries, register, dispatch]
  );

  const handleSelectDeliveries = useCallback(
    (deliveries: Delivery[]) => {
      dispatch(selectDeliveries(deliveries));
    },
    [dispatch]
  );

  const handleExpandRoute = useCallback(
    (route: DecoratedDeliveryRoute | null) => {
      handleSelectDeliveries([]);
      dispatch(setExpandedRoute(route?.undecoratedRoute ?? null));
    },
    [dispatch, handleSelectDeliveries]
  );

  const disableAutoRefresh = useCallback(() => {
    dispatch(setDisableAutorefresh(true));
  }, [dispatch]);

  const enableAutoRefresh = useCallback(() => {
    dispatch(setDisableAutorefresh(false));
  }, [dispatch]);

  const startRoute = useCallback(
    (route: DecoratedDeliveryRoute) => {
      dispatch(setRouteToStart(route.undecoratedRoute));
    },
    [dispatch]
  );

  const suspendRoute = useCallback(
    (route: DecoratedDeliveryRoute) => {
      dispatch(setRouteToSuspend(route.undecoratedRoute));
    },
    [dispatch]
  );

  return (
    <EmbeddedTable
      expandOnInit={getRouteId(null)}
      parentItems={sortedRoutes}
      getParentId={(route) => getRouteId(route.Id)}
      getChildId={(delivery) => getDeliveryId(delivery.ShipmentId)}
      getRelationship={(delivery) => getRouteId(delivery.DeliveryRouteId)}
      childItems={sortedDeliveries}
      onDragStart={disableAutoRefresh}
      onDragStop={enableAutoRefresh}
      onChangeParent={handleMoveRoute}
      onChangeChildrenOrder={handleChangeOrder}
      onSelectChild={handleSelectDeliveries}
      selectedChildren={selectedDeliveries}
      emptyChildMessage={<EmptyMessage>No deliveries assigned</EmptyMessage>}
      onExpandParent={handleExpandRoute}
      parentColumns={[
        {
          header: 'Status',
          cellProps: { propKey: 'Status' },
          Cell: RouteStatusCell,
          width: '10rem',
        },
        {
          header: 'Driver',
          cellProps: { propKey: 'Driver' },
          Cell: RouteCell,
          width: '14.5rem',
        },
        {
          header: 'Vehicle',
          cellProps: { propKey: 'Vehicle' },
          Cell: RouteCell,
          width: '12.5rem',
        },
        {
          header: '# Orders',
          cellProps: { propKey: 'NumberOfOrders' },
          Cell: RouteCell,
          width: '7.5rem',
        },
        {
          header: 'Order value',
          cellProps: { propKey: 'OrderValue' },
          Cell: RouteCell,
          width: '7.5rem',
        },
        {
          header: 'Time',
          Cell: RouteTimeCell,
          width: '33rem',
        },
      ]}
      parentActions={(route) =>
        route.Id === null
          ? []
          : [
              { label: 'Print manifests', onClick: handlePrintManifests },
              { label: 'Print route manifest', onClick: handlePrintMergedManifest },
              { label: 'Edit route', onClick: handleEditRoute },
              ...(route.Status === 'Ready' ? [{ label: 'Start route', onClick: startRoute }] : []),
              ...(route.Status === 'Out For Delivery' ? [{ label: 'Suspend route', onClick: suspendRoute }] : []),
              { label: 'End route', onClick: handleEndRoute },
              { label: 'Delete route', onClick: handleCancelRoute },
            ]
      }
      childColumns={[
        { header: 'Customer Name', cellProps: { propKey: 'FullName' }, Cell: DeliveryCell, width: '10rem' },
        { header: 'Order ID', cellProps: { propKey: 'ShipmentId' }, Cell: DeliveryCell, width: '6rem' },
        {
          header: '# Items',
          cellProps: { propKey: 'TotalItems', cellFormat: 'number' },
          Cell: DeliveryCell,
          width: '4.5rem',
        },
        { header: 'Address', Cell: DeliveryAddressCell, width: '11rem' },
        {
          header: 'Total Invoice',
          cellProps: { propKey: 'InvoiceTotal' },
          Cell: DeliveryCell,
          width: '7.5rem',
        },
        ...(features.LocationBasedDelivery ? [{ header: 'Dispatch', Cell: DeliveryDispatchCell, width: '6rem' }] : []),
        {
          header: 'Zone',
          cellProps: { propKey: 'Zone' },
          Cell: DeliveryCell,
          width: '7rem',
        },
        { header: 'Status', Cell: DeliveryStatusCell },
        {
          header: 'Fulfilled',
          cellProps: { propKey: 'FulfilledBy' },
          Cell: DeliveryCell,
        },
        {
          header: 'Delivery Window',
          Cell: DeliveryWindowCell,
          width: '7rem',
        },
      ]}
      childActions={[
        { label: 'Edit delivery', onClick: openSingleDelivery },
        {
          label: 'View Activity',
          onClick: (delivery: Delivery) => {
            dispatch(showTransactionPopup({ selectedTransaction: delivery }));
          },
        },
      ]}
    />
  );
};
