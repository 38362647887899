import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PrintJob, PrintStatus, PrintPreviewJob, PrintPreviewStatus } from 'models/Printing';

import {
  setPrintJobStatus,
  resetPrintJobStatus,
  setPrintPreviewStatus,
  resetPrintPreviewStatus,
} from 'store/actions/PrintJobsActions';

export type PrintJobsState = {
  printJobStatus: Record<PrintJob, PrintStatus>;
  printPreviewStatus: Record<PrintPreviewJob, PrintPreviewStatus>;
};

const initialState: PrintJobsState = {
  printJobStatus: {
    [PrintJob.CART]: PrintStatus.IDLE,
    [PrintJob.LABELS]: PrintStatus.IDLE,
    [PrintJob.PICK_TICKET]: PrintStatus.IDLE,
    [PrintJob.RECEIPT]: PrintStatus.IDLE,
    [PrintJob.Z_REPORT]: PrintStatus.IDLE,
  },
  printPreviewStatus: {
    [PrintPreviewJob.LABELS]: PrintPreviewStatus.IDLE,
    [PrintPreviewJob.MANIFEST]: PrintPreviewStatus.IDLE,
  },
};

export const printJobsSlice = createSlice({
  name: 'printJobs',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PrintJobsState>) => {
    builder.addCase(setPrintJobStatus, (state: PrintJobsState, action) => {
      const { printJob, status } = action.payload;
      state.printJobStatus = { ...state.printJobStatus, [printJob]: status };
    });
    builder.addCase(setPrintPreviewStatus, (state: PrintJobsState, action) => {
      const { printPreviewJob, status } = action.payload;
      state.printPreviewStatus = { ...state.printPreviewStatus, [printPreviewJob]: status };
    });
    builder.addCase(resetPrintJobStatus, (state: PrintJobsState) => {
      state.printJobStatus = initialState.printJobStatus;
    });
    builder.addCase(resetPrintPreviewStatus, (state: PrintJobsState) => {
      state.printPreviewStatus = initialState.printPreviewStatus;
    });
  },
});
