import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useRefetchProductsAfterRemoveItem = () => {
  const ldClient = useLDClient();
  const isRefetchProductsAfterRemoveItemRolledBack: boolean = ldClient?.variation(
    'pos.register.refetch-products-after-remove-item.rollback',
    false
  );
  return { isRefetchProductsAfterRemoveItemEnabled: !isRefetchProductsAfterRemoveItemRolledBack };
};
