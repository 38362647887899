import { useAppSelector } from 'util/hooks';

import { getUsedPercentageData } from '../index';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

import type { DaysSupplyLimitProps } from './DaysSupplyLimit';

export const useDaysSupplyLimit = ({ preorderCartItems }: DaysSupplyLimitProps) => {
  const guestDaysSupplyRemaining = useAppSelector((state) => state.customer.details?.DaysSupplyRemaining);

  const { data: cartDetails } = useGetCartDetails();

  // Note: This will always be zero because daysSupply is not returned with preorderCartItem
  // Need to add daysSupply to preorder items later
  const preorderDaysInCart =
    preorderCartItems?.reduce((value, preorderCartItem) => {
      const isWeightBased = preorderCartItem.unitAbbr === 'g';
      const amount = isWeightBased ? 1 : preorderCartItem.quantity;
      return value + amount * (preorderCartItem?.daysSupply ?? 0);
    }, 0.0) ?? 0;

  const orderDaysInCart = cartDetails.Cart.reduce((value, cartItem) => {
    const isWeightBased = cartItem.WgtCnt === 'Wgt';
    const amount = isWeightBased ? 1 : cartItem.QtySelected;
    return value + amount * (cartItem.DaysSupply ?? 0);
  }, 0.0);

  const daysSupplyInCart = preorderCartItems ? preorderDaysInCart : orderDaysInCart;
  const daysSupplyRemaining = guestDaysSupplyRemaining ?? 0;
  const { usedPercentage, over } = getUsedPercentageData(daysSupplyInCart, daysSupplyRemaining);

  const daysLabel = (numDays: number) => `${numDays.toFixed(2).replace(/[.,]00$/, '')} day${numDays === 1 ? '' : `s`}`;

  return {
    daysSupplyInCart: daysLabel(daysSupplyInCart),
    daysSupplyRemaining: daysLabel(daysSupplyRemaining),
    over,
    usedPercentage,
  };
};
