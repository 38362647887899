import { useState, Dispatch, SetStateAction } from 'react';

import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useUpdateCartTotals } from 'pages/CartPage/hooks/useUpdateCartTotals';

import type { Cart } from 'models/Cart';

export type UseTotalsSidebarReturn = {
  cart: Cart;
  handleCloseTotalsDrawer: () => void;
  isTotalsDrawerOpen: boolean;
  isCartLoading: boolean;
  setIsTotalsDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

export const useTotalsSidebar = (): UseTotalsSidebarReturn => {
  // Local state
  const [isTotalsDrawerOpen, setIsTotalsDrawerOpen] = useState<boolean>(false);

  const { data: cart, isLoading: areCartTotalsLoading } = useGetCartDetails();
  const { isLoading: areUpdatedCartTotalsLoading } = useUpdateCartTotals();

  // Computed values
  const isCartLoading = areCartTotalsLoading || areUpdatedCartTotalsLoading;

  // Handlers
  const handleCloseTotalsDrawer = () => {
    if (isTotalsDrawerOpen) {
      setIsTotalsDrawerOpen(false);
    }
  };

  return {
    cart,
    handleCloseTotalsDrawer,
    isTotalsDrawerOpen,
    isCartLoading,
    setIsTotalsDrawerOpen,
  };
};
