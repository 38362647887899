import React, { FC } from 'react';
import styled from 'styled-components';

import { ButtonWithIcon } from 'components/buttons';

import { colors } from 'css/Theme';
import { ReactComponent as _DownloadIcon } from 'assets/icons/download-cloud.svg';
import { Checkbox } from 'components/inputs';
import { useHistoryTab } from './useHistoryTab';
import { TabbedContent } from 'components/layout';

export enum HistoryPanelTabs {
  Transactions = 'Transaction history',
  Products = 'Product history',
}

export type HistoryTabProps = {
  guestId?: number;
};

export const HistoryTab: FC<HistoryTabProps> = ({ guestId }) => {
  const {
    historySelected,
    historyTabs,
    handleDownloadTransactions,
    handleToggleCanceledTransactions,
    includeCanceled,
    showDownloadButton,
  } = useHistoryTab({ guestId });

  return (
    <Container>
      <Header>
        <Title>Customer history</Title>
        {showDownloadButton && (
          <DownloadButton secondary icon={DownloadIcon} onClick={handleDownloadTransactions} large />
        )}
      </Header>
      <TabbedContent
        tabs={historyTabs}
        selectedTab={historySelected}
        actionBar={
          historySelected !== HistoryPanelTabs.Products && (
            <CheckboxContainer onClick={handleToggleCanceledTransactions}>
              <Checkbox state={includeCanceled ? 'on' : 'off'} label='Include Canceled Transactions' />
            </CheckboxContainer>
          )
        }
      />
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadIcon = styled(_DownloadIcon)`
  height: 19px;
  width: 19px;
`;

const DownloadButton = styled(ButtonWithIcon)`
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 56px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  margin-top: -0.5rem;
`;
