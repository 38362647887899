import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { customerSearchKeys } from './query-key-factory';
import { transformToCustomerSearchResult } from './types';

import type { CustomerSearchResult, CustomerSearchServerResponse } from './types';

const SEARCH_CUSTOMERS_BY_STRING = 'v2/guest/checkin_search_by_string';

type CustomerSearchPayload = {
  searchString: string;
};

const transformToServerPayload = (payload: CustomerSearchPayload) => {
  return { SearchString: payload.searchString };
};

export const useCustomerSearchQuery = (
  payload: CustomerSearchPayload,
  options?: UseQueryOptions<CustomerSearchResult[]>
) => {
  return useQuery<CustomerSearchResult[]>({
    queryKey: customerSearchKeys.one(payload.searchString),
    queryFn: async () => {
      const response = await post<CustomerSearchServerResponse[]>(
        SEARCH_CUSTOMERS_BY_STRING,
        transformToServerPayload(payload)
      );
      return response.map(transformToCustomerSearchResult);
    },
    ...options,
  });
};
