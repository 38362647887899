import { createSlice } from '@reduxjs/toolkit';

import {
  openCashDetailsPanel,
  closeCashDetailsPanel,
  resetCashDetails,
  setCashAmount,
  setCashEntryMode,
  setEntryErrorStatus,
} from 'store/actions/CashManagementActions';

export const billDenominations = ['100', '50', '20', '10', '5', '2', '1'] as const;
export const coinDenominations = ['1.00', '0.50', '0.25', '0.10', '0.05', '0.01', 'all'] as const;
export const totalDenominations = [...billDenominations, ...coinDenominations] as const;

export type BillDenomination = typeof billDenominations[number];
export type CoinDenomination = typeof coinDenominations[number];
export type CashDenomination = typeof totalDenominations[number];
export type CashDetails = Record<CashDenomination, string>;
export type CashEntryErrors = Record<CashDenomination, boolean>;

export const isBillDenomination = (denomination: string): boolean => {
  return billDenominations.includes(denomination as BillDenomination);
};

export const isCoinDenomination = (denomination: string): boolean => {
  return coinDenominations.includes(denomination as CoinDenomination);
};

const initialCashValues = totalDenominations.reduce(
  (acc, denomination) => ({ ...acc, [denomination]: '' }),
  {}
) as CashDetails;

const initialErrorStatus = totalDenominations.reduce(
  (acc, denomination) => ({ ...acc, [denomination]: false }),
  {}
) as CashEntryErrors;

export enum CashEntryMode {
  QUANTITY = 'quantity-entry',
  VALUE = 'value-entry',
}

export type CashManagementState = {
  cashEntryMode: CashEntryMode;
  cashDetails: CashDetails;
  entryErrors: CashEntryErrors;
  isCashDetailsPanelOpen: boolean;
};

export const initialState: CashManagementState = {
  cashEntryMode: CashEntryMode.QUANTITY,
  cashDetails: initialCashValues,
  entryErrors: initialErrorStatus,
  isCashDetailsPanelOpen: false,
};

export const cashManagementSlice = createSlice({
  name: 'cashManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetCashDetails, (state) => {
      state.cashDetails = initialCashValues;
      state.entryErrors = initialErrorStatus;
    });
    builder.addCase(setCashEntryMode, (state, action) => {
      const newMode = action.payload;

      totalDenominations.forEach((denomination) => {
        const current = state.cashDetails[denomination];
        if (current === '' || denomination === 'all') {
          return;
        }

        const denominationValue = Number(denomination);
        if (newMode === CashEntryMode.QUANTITY) {
          const value = Number(current);
          const quantity = Math.floor(value / denominationValue);
          state.cashDetails[denomination] = quantity.toString();
        } else {
          const quantity = Number(current);
          const value = (quantity * denominationValue).toFixed(2);
          state.cashDetails[denomination] = value;
        }
      });

      if (newMode === CashEntryMode.QUANTITY) {
        // We can clear the errors when switching to quantity entry
        // because the user can't enter invalid values in this mode
        state.entryErrors = initialErrorStatus;
      }

      state.cashEntryMode = newMode;
    });
    builder.addCase(setCashAmount, (state, action) => {
      const { denomination, amount } = action.payload;
      state.cashDetails[denomination] = amount;
    });
    builder.addCase(setEntryErrorStatus, (state, action) => {
      const { denomination, hasError } = action.payload;
      state.entryErrors[denomination] = hasError;
    });
    builder.addCase(openCashDetailsPanel, (state) => {
      state.isCashDetailsPanelOpen = true;
    });
    builder.addCase(closeCashDetailsPanel, (state) => {
      state.isCashDetailsPanelOpen = false;
    });
  },
});
