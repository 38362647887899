import React from 'react';

const originalDimensions = {
  width: 18,
  height: 18,
};

type ListIconProps = {
  width?: number;
  height?: number;
  automationId?: string;
};

export const ListIcon = ({
  width = originalDimensions.width,
  height = originalDimensions.height,
  automationId,
}: ListIconProps) => {
  return (
    <svg data-testid={automationId} xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}>
        <path
          d='M2.14272 3.9285C2.45831 3.9285 2.71415 3.67266 2.71415 3.35707C2.71415 3.04148 2.45831 2.78564 2.14272 2.78564C1.82713 2.78564 1.57129 3.04148 1.57129 3.35707C1.57129 3.67266 1.82713 3.9285 2.14272 3.9285Z'
          stroke='#464F53'
          stroke-width='1.42857'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6.14258 3.35693H16.4283'
          stroke='#464F53'
          stroke-width='1.42857'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M2.14272 9.07108C2.45831 9.07108 2.71415 8.81524 2.71415 8.49965C2.71415 8.18406 2.45831 7.92822 2.14272 7.92822C1.82713 7.92822 1.57129 8.18406 1.57129 8.49965C1.57129 8.81524 1.82713 9.07108 2.14272 9.07108Z'
          stroke='#464F53'
          stroke-width='1.42857'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6.14258 8.5H16.4283'
          stroke='#464F53'
          stroke-width='1.42857'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M2.14272 14.2141C2.45831 14.2141 2.71415 13.9583 2.71415 13.6427C2.71415 13.3271 2.45831 13.0713 2.14272 13.0713C1.82713 13.0713 1.57129 13.3271 1.57129 13.6427C1.57129 13.9583 1.82713 14.2141 2.14272 14.2141Z'
          stroke='#464F53'
          stroke-width='1.42857'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6.14258 13.6431H16.4283'
          stroke='#464F53'
          stroke-width='1.42857'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  );
};
