import React from 'react';

type CartIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

export function CartIcon(props: CartIconProps) {
  const { height = 24, width = 24, color = '#ffffff', className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.9992 19.8999C18.3992 19.8999 18.6992 19.5999 18.6992 19.1999C18.6992 18.7999 18.3992 18.4999 17.9992 18.4999H7.99922C7.79922 18.4999 7.59922 18.3999 7.49922 18.2999C7.39922 18.1999 7.29922 17.9999 7.19922 17.7999L4.59922 2.1999C4.49922 1.6999 4.19922 1.1999 3.79922 0.899902C3.49922 0.599902 2.99922 0.399902 2.39922 0.399902H1.39922C0.999219 0.399902 0.699219 0.699902 0.699219 1.0999C0.699219 1.4999 0.999219 1.7999 1.39922 1.7999H2.39922C2.59922 1.7999 2.79922 1.8999 2.99922 1.9999C3.09922 2.0999 3.19922 2.2999 3.29922 2.4999L5.89922 17.9999C5.99922 18.4999 6.29922 18.9999 6.69922 19.2999C7.09922 19.5999 7.59922 19.7999 8.09922 19.7999H17.9992V19.8999Z'
        fill={color}
      />
      <path
        d='M16.1016 22.9002C16.7016 22.9002 17.2016 22.4002 17.2016 21.8002C17.2016 21.2002 16.7016 20.7002 16.1016 20.7002V22.9002Z'
        fill={color}
      />
      <path
        d='M16.1 22.9002V20.7002C15.5 20.7002 15 21.2002 15 21.8002C15.1 22.4002 15.5 22.9002 16.1 22.9002Z'
        fill={color}
      />
      <path
        d='M8.60156 22.9002C9.20156 22.9002 9.70156 22.4002 9.70156 21.8002C9.70156 21.2002 9.20156 20.7002 8.60156 20.7002V22.9002Z'
        fill={color}
      />
      <path
        d='M8.6 22.9002V20.7002C8 20.7002 7.5 21.2002 7.5 21.8002C7.5 22.4002 8 22.9002 8.6 22.9002Z'
        fill={color}
      />
      <path
        d='M6.00078 15.2999H18.1008C18.9008 15.2999 19.7008 14.9999 20.4008 14.4999C21.0008 13.9999 21.5008 13.2999 21.7008 12.4999L23.2008 6.6999C23.3008 6.4999 23.3008 6.2999 23.2008 5.9999C23.2008 5.7999 23.1008 5.5999 22.9008 5.3999C22.8008 5.1999 22.6008 5.0999 22.4008 4.9999C22.2008 4.8999 22.0008 4.8999 21.8008 4.8999H4.50078C4.10078 4.8999 3.80078 5.1999 3.80078 5.5999C3.80078 5.9999 4.10078 6.2999 4.50078 6.2999H21.8008L20.4008 12.1999C20.3008 12.6999 20.0008 13.0999 19.6008 13.4999C19.2008 13.7999 18.7008 13.9999 18.2008 13.9999H6.00078C5.60078 13.9999 5.30078 14.2999 5.30078 14.6999C5.30078 15.0999 5.60078 15.2999 6.00078 15.2999Z'
        fill={color}
      />
    </svg>
  );
}
