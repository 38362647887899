import React from 'react';

import { SearchCustomerIcon } from 'assets/icons/cart/SearchCustomerIcon';
import { TextContainer, MessageContainer, Message, SecondaryMessage, Link } from './styles';

type SearchPlaceholderProps = {
  onCreateNewProfile?: () => void;
};

export const SearchPlaceholder = ({ onCreateNewProfile }: SearchPlaceholderProps) => {
  return (
    <TextContainer>
      <MessageContainer>
        <SearchCustomerIcon />
        <Message>
          Search for customer
          <SecondaryMessage>Search by name, phone number or drivers license</SecondaryMessage>
        </Message>
      </MessageContainer>
      {onCreateNewProfile && <Link label='Or create new profile' onClick={onCreateNewProfile} />}
    </TextContainer>
  );
};
