import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import { SectionWithHeader } from 'components/layout';
import { AvailableDiscount } from 'models/Discounts';
import { colors } from 'css/Theme';
import { Label as _Label } from 'components/text';
import { Input, Select } from 'components/inputs';
import { callback1 } from 'models/Misc';

import { useSelector } from 'react-redux';
import { State } from 'store';

const Section = styled(SectionWithHeader)`
  flex-grow: 1;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  flex-grow: 1;
  border-radius: inherit;
  border: 1px solid ${colors.dutchie.borderGrey};
  padding: 1.25rem 1.5rem;
`;

const Label = styled(_Label)`
  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const StyledInput = styled(Input)<{ appearDisabled?: boolean }>`
  ${({ appearDisabled }) =>
    appearDisabled && {
      opacity: '0.5',
    }}
`;

export type SelectedDiscountProps = {
  selected?: AvailableDiscount;
  applyTo?: string;
  setApplyTo: callback1<string>;
  fee: string;
  setFee: callback1<string>;
  percent: string;
  setPercent: callback1<string>;
  name: string;
  setName: callback1<string>;
  applyToOptions: {
    label: string;
    value: string;
  }[];
};

export const SelectedDiscount: FC<SelectedDiscountProps> = (props) => {
  const { applyToOptions, selected, applyTo, setApplyTo, fee, percent, name, setFee, setPercent, setName } = props;

  const [selectedDiscountType, setSelectedDiscountType] = useState('');

  useEffect(() => {
    if (selected) {
      setSelectedDiscountType(selected.FlatFee ? 'amount' : 'percent');
      setName(selected.ProductDesc);
    }
    // Adding setName to the dependency array causes the name to be reset to the default value with every keystroke
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const permissions = useSelector((state: State) => state.settings.permissions);

  return (
    <Section title='Selected Discount' empty={!selected}>
      {!selected ? (
        'Select Available Discount'
      ) : (
        <Container>
          <Label>Apply to:</Label>
          <Select
            placeholder='Select one'
            value={applyTo}
            options={applyToOptions}
            onChange={(value) => setApplyTo(value)}
            automationId='selected-discount-apply-to-select'
          />
          <Label>Discount Amount</Label>
          <StyledInput
            value={fee}
            onChange={(e) => {
              setFee(e.target.value);
              setPercent('');
            }}
            onFocus={() => {
              setSelectedDiscountType('amount');
            }}
            appearDisabled={!(selectedDiscountType === 'amount')}
            disabled={!permissions.OverrideDiscounts}
            data-testid='selected-discount_styled-input_amount'
          />
          <Label>Discount Percent Off</Label>
          <StyledInput
            value={percent}
            onChange={(e) => {
              setPercent(e.target.value);
              setFee('');
            }}
            onFocus={() => {
              setSelectedDiscountType('percent');
            }}
            appearDisabled={!(selectedDiscountType === 'percent')}
            disabled={!permissions.OverrideDiscounts}
            data-testid='selected-discount_styled-input_percent-off'
          />
          <Label>Name</Label>
          <StyledInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            data-testid='selected-discount_styled-input_name'
          />
        </Container>
      )}
    </Section>
  );
};
