import React, { FC } from 'react';

import { DiscountContainer, DollarSign, DiscountTitle } from '../../CFD.styles';

export const DiscountBanner: FC<{ amount?: number }> = ({ amount }) => {
  return (
    <DiscountContainer data-testid='totals-summary_discount-banner'>
      <DollarSign />
      <div>
        <DiscountTitle>Cha-ching!</DiscountTitle>
        You saved a total of <b>${amount?.toFixed(2)}</b> on this order!
      </div>
    </DiscountContainer>
  );
};
