import React, { useState } from 'react';
import styled from 'styled-components';
import { Capacitor } from '@capacitor/core';

import { downloadProductHistory, downloadTransactionHistory } from 'api/PosApi';
import { HistoryPanelTabs } from './HistoryTab';
import { includeCanceledTransactions } from 'store/actions/TransactionsActions';
import { PurchasesPage } from 'pages/PurchasesPage';
import { TransactionsPage } from 'pages/TransactionsPage';
import { useAppDispatch, useAppSelector } from 'util/hooks';

import type { HistoryTabProps } from './HistoryTab';
import type { Tab } from 'components/layout';

export const useHistoryTab = ({ guestId }: HistoryTabProps) => {
  const dispatch = useAppDispatch();

  // Local state
  const [historySelected, setHistorySelected] = useState(HistoryPanelTabs.Transactions);

  // Global state
  const includeCanceled = useAppSelector((state) => state.transactions.includeCanceledTransactions);

  // Hooks
  const isWeb = Capacitor.getPlatform() === 'web';

  // Handlers

  const handleToggleCanceledTransactions = () => {
    dispatch(includeCanceledTransactions(!includeCanceled));
  };

  const handleDownloadTransactions = () => {
    if (historySelected === HistoryPanelTabs.Products) {
      downloadProductHistory(guestId);
    } else {
      downloadTransactionHistory(guestId);
    }
  };

  // Tabs

  const historyTabs: Tab[] = [
    {
      label: HistoryPanelTabs.Transactions,
      onClick: () => setHistorySelected(HistoryPanelTabs.Transactions),
      content: <StyledTransactionsPage />,
    },
    {
      label: HistoryPanelTabs.Products,
      onClick: () => setHistorySelected(HistoryPanelTabs.Products),
      content: <StyledProductPage />,
    },
  ];

  return {
    historySelected,
    historyTabs,
    handleDownloadTransactions,
    handleToggleCanceledTransactions,
    includeCanceled,
    showDownloadButton: isWeb,
  };
};

const StyledProductPage = styled(PurchasesPage)`
  flex-grow: 1;
`;

const StyledTransactionsPage = styled(TransactionsPage)`
  flex-grow: 1;
`;
