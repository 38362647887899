import { useHistory } from 'react-router-dom';

import { Overlay } from '@dutchie/capacitor-overlay';
import { openUrl } from 'util/helpers/openUrl';
import { isAndroid, useAppDispatch, useAppSelector } from 'util/hooks';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useStartAnonymousOrder } from 'util/hooks/checkin/useStartAnonymousOrder';
import { curriedSelectLocation, selectRegister } from 'store/actions/SettingsActions';

import { CartIcon } from 'assets/icons/navigation/cart';
import { GuestListIcon } from 'assets/icons/navigation/guest-list';
import { ProductsIcon } from 'assets/icons/navigation/products';
import { DeliveryIcon } from 'assets/icons/navigation/delivery';
import { CashManagementIcon } from 'assets/icons/navigation/cash-management';
import { SalesHistoryIcon } from 'assets/icons/navigation/sales-history';
import { CustomersIcon } from 'assets/icons/navigation/customers';
import { ExternalWebPageIcon } from 'assets/icons/navigation/external-web-page';
import { BiotrackIcon } from 'assets/icons/navigation/biotrack';
import { SettingsIcon } from 'assets/icons/navigation/settings';
import { RegisterIcon } from 'assets/icons/navigation/register';
import { HelpIcon } from 'assets/icons/navigation/help';

import type { ElementType } from 'react';

export type NavigationSubItemData = {
  id: string;
  label: string;
  onClick?: () => void;
  to?: string;
  visible: boolean;
};

export type NavigationItemData = {
  id: string;
  icon: ElementType;
  label: string;
  onClick?: () => void;
  subItems?: NavigationSubItemData[];
  to?: string;
  visible: boolean;
  activeRoutes?: string[];
};

type UseNavigationItemsReturn = {
  primaryItems: NavigationItemData[];
  secondaryItems: NavigationItemData[];
};

export function useNavigationItems(): UseNavigationItemsReturn {
  // hooks
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { startAnonymousOrder } = useStartAnonymousOrder();
  const { isAnonymousCartLDFlagEnabled, areAnonymousTransactionsEnabled } = useAnonymousCart();

  // global state
  const helpDeskUrl = useAppSelector((state) => state.settings.helpDeskLink?.HelpDeskUrl);
  const registerName = useAppSelector((state) => state.settings.selectedRegister?.label) ?? 'Register';
  const isBioTrackEnabled = useAppSelector((state) => state.settings.integrations?.UseBioTrack) ?? false;
  const isDeliveryEnabled = useAppSelector((state) => state.settings.features.EnableDelivery);
  const isTransactionsEnabled = useAppSelector((state) => state.settings.features.ShowPOSTransactionsTab);
  const hasEditRoutesPermission = useAppSelector((state) => state.settings.permissions.EditRoutes);
  const hasViewRoutesPermission = useAppSelector((state) => state.settings.permissions.ViewRoutes);

  // computed
  const showHelpDeskItem = !!helpDeskUrl;
  const showCartItem = isAnonymousCartLDFlagEnabled && areAnonymousTransactionsEnabled;
  const showDeliveryItem = isDeliveryEnabled && (hasEditRoutesPermission || hasViewRoutesPermission);

  // handlers
  function handleCartClick() {
    startAnonymousOrder();
  }

  function handleExternalWebPageClick() {
    Overlay.show({ width: 0.8 });
  }

  function handleChangeLocationClick() {
    dispatch(curriedSelectLocation({ history }));
  }

  function handleChangeRegisterClick() {
    dispatch(selectRegister(undefined));
  }

  function handleHelpCenterClick() {
    openUrl('https://support.dutchie.com/hc/en-us');
  }

  function handleInternalHelpDeskClick() {
    openUrl(`//${helpDeskUrl}`);
  }

  const primaryItems: NavigationItemData[] = [
    {
      id: 'cart',
      label: 'Cart',
      icon: CartIcon,
      onClick: handleCartClick,
      visible: showCartItem,
      activeRoutes: ['/cart'],
    },
    {
      id: 'guest-list',
      label: 'Guest list',
      icon: GuestListIcon,
      to: '/guestlist',
      visible: true,
      activeRoutes: ['/guestlist'],
    },
    {
      id: 'products',
      label: 'Products',
      icon: ProductsIcon,
      to: '/products',
      visible: true,
      activeRoutes: ['/products'],
    },
    {
      id: 'delivery',
      label: 'Delivery',
      icon: DeliveryIcon,
      visible: showDeliveryItem,
      subItems: [
        {
          id: 'routes',
          label: 'Routes',
          to: '/delivery',
          visible: true,
        },
        {
          id: 'fleet',
          label: 'Fleet',
          to: '/fleet',
          visible: true,
        },
      ],
      activeRoutes: ['/delivery', '/fleet'],
    },
    {
      id: 'cash-management',
      label: 'Cash management',
      icon: CashManagementIcon,
      to: '/register',
      visible: isTransactionsEnabled,
      activeRoutes: ['/register'],
    },
    {
      id: 'sales-history',
      label: 'Sales history',
      icon: SalesHistoryIcon,
      to: '/saleshistory',
      visible: isTransactionsEnabled,
      activeRoutes: ['/saleshistory'],
    },
    {
      id: 'customers',
      label: 'Customers',
      icon: CustomersIcon,
      to: '/customers',
      visible: true,
      activeRoutes: ['/customers'],
    },
    {
      id: 'external-web-page',
      label: 'External web page',
      icon: ExternalWebPageIcon,
      onClick: handleExternalWebPageClick,
      visible: isAndroid,
    },
    {
      id: 'biotrack',
      label: 'Biotrack',
      icon: BiotrackIcon,
      to: '/biotrack',
      visible: isBioTrackEnabled,
      activeRoutes: ['/biotrack'],
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: SettingsIcon,
      to: '/settings',
      visible: true,
      activeRoutes: ['/settings'],
    },
  ];

  const secondaryItems: NavigationItemData[] = [
    {
      id: 'register',
      label: registerName,
      icon: RegisterIcon,
      visible: true,
      subItems: [
        {
          id: 'change-location',
          label: 'Change location',
          onClick: handleChangeLocationClick,
          visible: true,
        },
        {
          id: 'change-register',
          label: 'Change register',
          onClick: handleChangeRegisterClick,
          visible: true,
        },
      ],
    },

    ...(showHelpDeskItem
      ? [
          {
            id: 'help',
            label: 'Help',
            icon: HelpIcon,
            visible: true,
            subItems: [
              {
                id: 'help-center',
                label: 'Help center',
                visible: true,
                onClick: handleHelpCenterClick,
              },
              {
                id: 'internal-help-desk',
                label: 'Internal help desk',
                visible: true,
                onClick: handleInternalHelpDeskClick,
              },
            ],
          },
        ]
      : [
          {
            id: 'help',
            label: 'Help',
            icon: HelpIcon,
            visible: true,
            onClick: handleHelpCenterClick,
          },
        ]),
  ];

  return {
    primaryItems,
    secondaryItems,
  };
}
