import { useEffect, useState, useMemo, ChangeEvent, useRef } from 'react';
import DOMPurify from 'dompurify';

import { GuestNotesModalMode } from './GuestNotesModal';
import { saveNotes } from 'store/actions/CustomerActions';
import { useAppDispatch } from 'util/hooks';

import type { GuestNotesModalProps } from './GuestNotesModal';

export const useGuestNotesModal = ({ guestId, mode = GuestNotesModalMode.View, notes, hide }: GuestNotesModalProps) => {
  const dispatch = useAppDispatch();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [currentMode, setCurrentMode] = useState(mode);
  const [currentNotes, setCurrentNotes] = useState(notes);
  const [isSavingNotes, setIsSavingNotes] = useState(false);

  const sanitizedNotes = useMemo(() => DOMPurify.sanitize(currentNotes ?? ''), [currentNotes]);

  const isEditing = currentMode === GuestNotesModalMode.Edit;
  const isSaveDisabled = isEditing && currentNotes === notes;
  const primaryActionLabel = isEditing ? 'Save' : 'Edit note';

  const handleClickEditNote = () => {
    setCurrentMode(GuestNotesModalMode.Edit);
  };

  const handleEditNotes = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentNotes(e.target.value);
  };

  const handleClickSave = async () => {
    setIsSavingNotes(true);
    await dispatch(saveNotes({ guestId, notes: sanitizedNotes })).unwrap();
    setIsSavingNotes(false);
    hide();
  };

  const handleClickPrimaryAction = () => {
    if (isEditing) {
      handleClickSave();
    } else {
      handleClickEditNote();
    }
  };

  const handleCancel = () => {
    if (isEditing) {
      setCurrentMode(GuestNotesModalMode.View);
    } else {
      hide();
    }
  };

  const adjustHeightToContent = () => {
    textAreaRef.current?.style.setProperty('height', 'inherit');
    const { scrollHeight, clientHeight } = textAreaRef.current ?? {};
    const isContentHidden = (scrollHeight ?? 0) > (clientHeight ?? 0);
    const newHeight = isContentHidden ? `${scrollHeight}px` : 'inherit';
    textAreaRef.current?.style.setProperty('height', newHeight);
    return;
  };

  useEffect(() => {
    if (isEditing) {
      adjustHeightToContent();
    }
  }, [isEditing]);

  return {
    adjustHeightToContent,
    handleCancel,
    handleClickPrimaryAction,
    handleEditNotes,
    isDisabled: isSaveDisabled,
    isEditing,
    isSavingNotes,
    primaryActionLabel,
    sanitizedNotes,
    textAreaRef,
  };
};
