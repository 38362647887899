import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { AppDispatch } from 'store';
import { CalculateCartTotalResponse, CartDetails, transformToCartTotals } from './types';
import { getCartDetailsKeys } from './query-key-factory';
import { getQueryClient } from 'util/reactQuery';
import { customEventKeys, logger } from 'util/logger';
import { refreshCheckout } from 'store/actions/CheckoutActions';
import { setCartLoading, setCartTotalsFromRQ, setCartTotalsLoading } from 'store/actions/CartActions';
import { updateCFDCartTotals } from 'store/actions/CFDActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { warningNotification } from 'store/actions/NotificationsActions';

import type { GetCartDetailsPayload } from './load';

const CALCULATE_CART_TOTAL = `v2/cart/calculate-cart-total`;

export type CalculateCartTotalsPayload = GetCartDetailsPayload & {
  /** @deprecated Controls where to include Payment in the return object */
  isCheckout?: boolean;
};

const transformToServerPayload = (payload: CalculateCartTotalsPayload) => {
  return {
    AcctId: payload.guestId,
    // Always set to true to avoid type issues with the Payment object in the response
    IsCheckout: true,
    Register: payload.registerId,
    ShipmentId: payload.shipmentId,
    Timestamp: payload.timestamp ?? +new Date(),
  };
};

/** The API call helper to update the cart totals */
const updateCartTotalsRequest = async (dispatch: AppDispatch, payload: CalculateCartTotalsPayload) => {
  if (!payload.guestId && !payload.shipmentId) {
    return undefined;
  }

  // Using this to manually set the Redux state for cart loading
  dispatch(setCartLoading(true));
  dispatch(setCartTotalsLoading(true));
  const cartTotals = await post<CalculateCartTotalResponse>(CALCULATE_CART_TOTAL, transformToServerPayload(payload));

  dispatch(refreshCheckout(cartTotals.GrandTotalRounded));
  dispatch(updateCFDCartTotals(cartTotals));

  /** @deprecated Remove this once we clean up the cart store in Redux */
  dispatch(setCartTotalsFromRQ(cartTotals));

  logger.debug(`[React Query] useUpdateCartTotalsMutation`, {
    key: customEventKeys.reactQuery,
    payload,
    cartTotals,
  });

  return transformToCartTotals(cartTotals);
};

/** This mutation relies on helper methods to maintain support for Redux
 * Consolidate the logic back into this hook once we clean up the cart store in Redux
 */
export const useUpdateCartTotalsMutation = () => {
  const dispatch = useAppDispatch();

  const registerId = useAppSelector((state) => state.settings.selectedRegister?.value);

  return useMutation(
    async (payload: CalculateCartTotalsPayload) =>
      await updateCartTotalsRequest(dispatch, { ...payload, registerId: payload.registerId ?? registerId }),
    {
      onSuccess: (newCartTotals, payload) => {
        if (newCartTotals) {
          const queryClient = getQueryClient();
          const cachedCartDetails = queryClient.getQueryData<CartDetails>(getCartDetailsKeys.one(payload));
          if (cachedCartDetails) {
            // Update the cache with the new data from the cart totals API
            queryClient.setQueryData(getCartDetailsKeys.one(payload), {
              ...cachedCartDetails,
              ...newCartTotals,
            });
          } else {
            // If the cart details aren't cached, then refetch them
            queryClient.invalidateQueries(getCartDetailsKeys.one(payload));

            // Adding this log to track if we reach this condition, will probably remove the invalidateQueries call
            logger.debug('[React Query] no cached cart details found', {
              key: customEventKeys.cart.calcCartTotalsCacheError,
              payload,
              cachedCartResponse: cachedCartDetails,
            });
          }
        }
        // Manually set the cart loading state to false in Redux
        dispatch(setCartLoading(false));
        dispatch(setCartTotalsLoading(false));
      },
      onError: (error) => {
        const errorMessage = 'There was an error updating the cart totals.';
        logger.error(error, { message: errorMessage });
        const message = typeof error === 'string' ? error : errorMessage;
        dispatch(warningNotification(message));
        // Manually set the cart loading state to false in Redux
        dispatch(setCartLoading(false));
        dispatch(setCartTotalsLoading(false));
      },
    }
  );
};

/** REDUX HELPER: Use this helper in other actions in the cart since you can't use hooks in Redux */
export const updateCartTotals = async (dispatch: AppDispatch, payload: CalculateCartTotalsPayload) => {
  try {
    const newCartTotals = await updateCartTotalsRequest(dispatch, payload);
    if (newCartTotals) {
      const queryClient = getQueryClient();
      const cachedCartDetails = queryClient.getQueryData<CartDetails>(getCartDetailsKeys.one(payload));
      if (cachedCartDetails) {
        // Update the cache with the new data from the cart totals API
        queryClient.setQueryData(getCartDetailsKeys.one(payload), {
          ...cachedCartDetails,
          ...newCartTotals,
        });
      } else {
        // If the cart details aren't cached, then refetch them
        queryClient.invalidateQueries(getCartDetailsKeys.one(payload));
      }
    }
    // Manually set the cart loading state to false in Redux
    dispatch(setCartLoading(false));
    dispatch(setCartTotalsLoading(false));
  } catch (error) {
    const errorMessage = 'There was an error updating the cart totals.';
    logger.error(error, { message: errorMessage });
    const message = typeof error === 'string' ? error : errorMessage;
    dispatch(warningNotification(message));
    // Manually set the cart loading state to false in Redux
    dispatch(setCartLoading(false));
    dispatch(setCartTotalsLoading(false));
  }
};
