import { useMutation } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';
import { MessagingAuthRequest } from 'models/Messaging';

// This is meant to be used with all messaging providers, but for now, will only be in use with Ably
// If implemented for pusher, will need to be retested
export function useMessagingAuthMutation<T>(payload: MessagingAuthRequest) {
    return useMutation({
        mutationKey: ['Messaging-Auth', payload.Provider, payload.ChannelType],
        mutationFn: () => authenticate<T>(payload),
    });
}

export async function authenticate<T>(payload: MessagingAuthRequest) {
    return await post<T>(`messaging/auth/${payload.ChannelType}`, payload);
}
