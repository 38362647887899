import React from 'react';

import { colors } from 'css/Theme';

const originalDimensions = {
  width: 60,
  height: 60,
};

type NoResultsIconProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export const NoResultsIcon = ({
  fill = colors.dutchie.gray70,
  width = originalDimensions.width,
  height = originalDimensions.height,
}: NoResultsIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.2835 10.283C15.5126 5.05391 22.6049 2.11621 30 2.11621C37.3951 2.11621 44.4873 5.05391 49.7165 10.283C54.9456 15.5122 57.8833 22.6044 57.8833 29.9995C57.8833 37.3947 54.9456 44.4869 49.7165 49.716C44.4873 54.9452 37.3951 57.8829 30 57.8829C22.6049 57.8829 15.5126 54.9452 10.2835 49.716C5.05434 44.4869 2.11664 37.3947 2.11664 29.9995C2.11664 22.6044 5.05434 15.5122 10.2835 10.283ZM30 5.38288C23.4712 5.38288 17.2099 7.97641 12.5934 12.5929C7.97684 17.2095 5.3833 23.4708 5.3833 29.9995C5.3833 36.5283 7.97684 42.7896 12.5934 47.4062C17.2099 52.0227 23.4712 54.6162 30 54.6162C36.5287 54.6162 42.7901 52.0227 47.4066 47.4062C52.0231 42.7896 54.6166 36.5283 54.6166 29.9995C54.6166 23.4708 52.0231 17.2095 47.4066 12.5929C42.7901 7.97641 36.5287 5.38288 30 5.38288Z'
          fill={fill}
          stroke={fill}
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M49.7166 10.2831C50.3545 10.9209 50.3545 11.9551 49.7166 12.593L12.5933 49.7163C11.9554 50.3541 10.9212 50.3541 10.2834 49.7163C9.64553 49.0784 9.64553 48.0443 10.2834 47.4064L47.4067 10.2831C48.0446 9.64522 49.0787 9.64522 49.7166 10.2831Z'
          fill={fill}
          stroke={fill}
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  );
};
