import React, { FC } from 'react';
import ReactSwitch, { ReactSwitchProps } from 'react-switch';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';

export type SwitchProps = {
  automationId?: string;
  label?: string | React.ReactElement;
  id?: string;
  containerMargin?: string;
  onChange: (enabled: boolean) => void;
  checked: boolean;
};

export const Switch: FC<SwitchProps> = ({ automationId, label, id, onChange, checked, containerMargin }) => (
  <SwitchLabel containerMargin={containerMargin} data-testid={automationId}>
    <ReactSwitch {...BASE_PROPS} checked={checked} onChange={onChange} id={id} />
    {label && <SwitchLabelText>{label}</SwitchLabelText>}
  </SwitchLabel>
);

const SwitchLabel = styled.label<{ containerMargin?: string }>`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  font-weight: normal;
  height: 1.25rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  margin: ${({ containerMargin }) => containerMargin ?? '0 0 1.5rem'};
`;

const SwitchLabelText = styled.span`
  margin-left: 0.5rem;
`;

const StyledCheckMarkIcon = styled(CheckMarkIcon)`
  color: ${colors.dutchie.blue};
  width: 100%;
`;

const BASE_PROPS: Partial<ReactSwitchProps> = {
  onColor: colors.dutchie.blue,
  offColor: colors.dutchie.grey80,
  onHandleColor: colors.dutchie.primaryWhite,
  handleDiameter: 22,
  uncheckedIcon: false,
  activeBoxShadow: 'none',
  height: 28,
  width: 49,
  checkedIcon: false,
  checkedHandleIcon: <StyledCheckMarkIcon />,
};
