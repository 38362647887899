import React from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { State } from 'store';
import { isTouchDevice } from 'util/Helpers';
import { CheckedInGuest } from 'models/Guest';
import { colors } from 'css/Theme';
import { OrdersByStatus } from '../types';
import { BoardViewOrderColumn } from './BoardViewOrderColumn';
import { DragPreview } from './DragPreview';
import { useOrdersByStatus } from './useOrdersByStatus';
import BoardViewSkeleton from './BoardViewSkeleton';
import { CustomDragLayer } from './CustomDragLayer';

export const BoardView = React.memo(BoardViewImpl);

export function BoardViewImpl() {
  const { moveOrder, orders } = useOrdersByStatus();

  const isTouch = isTouchDevice();
  const backend = isTouch ? TouchBackend : HTML5Backend;
  const options = isTouch ? { delayTouchStart: 150, enableMouseEvents: true } : undefined;

  return (
    <DndProvider backend={backend} options={options}>
      {isTouch && <DragPreview />}
      {!isTouch && <CustomDragLayer />}
      <KanbanScrollContainer
        ignoreElements='[data-dont-scroll-on-drag]'
        hideScrollbars={false}
        data-testid='kanbanscrollcontainer_div_kanban-view'
      >
        <OrderColumns ordersByStatus={orders} onDrop={moveOrder} />
      </KanbanScrollContainer>
    </DndProvider>
  );
}

const OrderColumns = React.memo(OrderColumnsImpl);

type OrderColumnsProps = {
  ordersByStatus: OrdersByStatus;
  onDrop: (guest: CheckedInGuest, destinationStatus: string) => void;
};

function OrderColumnsImpl({ ordersByStatus, onDrop }: OrderColumnsProps) {
  const statuses = useSelector((state: State) => state.guestList.statuses);
  const loading = useSelector((state: State) => state.guestList.loadingGuests || state.guestList.loadingStatuses);

  return (
    <Columns>
      <BoardContainer>
        {loading ? (
          <BoardViewSkeleton />
        ) : (
          statuses.map((status, index) => (
            <BoardViewOrderColumn
              key={index}
              status={status}
              orders={ordersByStatus[status.POSStatus]}
              onDrop={onDrop}
            />
          ))
        )}
      </BoardContainer>
    </Columns>
  );
}

const BoardContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding: 0 1.5rem;
  width: 100%;
  gap: 1px;

  &::-webkit-scrollbar {
    width: 0;
    background-color: ${colors.dutchie.almostBlack};
  }
`;

const Columns = styled.div`
  flex: 3 0;
  margin: 0 -12px;
`;

const KanbanScrollContainer = styled(ScrollContainer)`
  display: flex;
  flex: 1 0 0;
  margin-top: 1rem;
  width: 100%;
`;
