
import { PeripheralServiceConfig, PeripheralType } from "@dutchie/capacitor-hardware";
import { MockedPeripheralService } from "../../shared/mock/MockedPeripheralService";
import type { MockedEvent, MockedMeasurementEvent } from "../../shared/mock/types";

import { MockScale } from "./MockScale";

export class MockScaleService extends MockedPeripheralService<MockScale> {
  constructor(config?: { service?: Partial<PeripheralServiceConfig>; isSupported?: () => boolean }) {
    super(PeripheralType.scale, {
      defaultConfig: { name: 'MockScaleService', autoConnect: false, autoEnable: true },
      userConfig: config?.service,
    });

    window.addEventListener(this.eventName('measurement'), this.handleMeasurementEvent);

    this.devices = [
      this.createDevice('1111', 'Mock Scale 1'),
      this.createDevice('2222', 'Mock Scale 2'),
    ];
  }

  dispose(): void {
    super.dispose();
    window.removeEventListener(this.eventName('measurement'), this.handleMeasurementEvent);
  }

  private handleMeasurementEvent = (event: Event) => {
    const detail = (event as CustomEvent).detail as MockedEvent<MockedMeasurementEvent>;
    const scale = this.getById(detail.data.id);
    if (!scale) {
      throw new Error('Scale not found');
    }

    if (!scale.isConnected) {
      return;
    }

    scale.emitMeasurementEvent(detail.data.measurement);
    this.notifyReceived('measurement', detail);
  };

  createDevice(id: string, name: string): MockScale {
    return new MockScale({ id, name, serviceName: this.name });
  }
}
