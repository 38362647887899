import { createMockPreorderRedemption } from 'tests/mocks/createMockCart';

export const mockRedemptions = [
  createMockPreorderRedemption({
    RedemptionDescription: 'AlpineIQ: 20% Off Flower',
    SpringBigId: undefined,
    LeafLogixCode: undefined,
    IsRedeemed: false,
  }),
  createMockPreorderRedemption({
    RedemptionDescription: 'SpringBig Reward: $5 Off Any Pre-roll',
    AlpineId: undefined,
    LeafLogixCode: undefined,
    IsRedeemed: false,
  }),
  createMockPreorderRedemption({
    RedemptionDescription: 'SpringBig Offer: 20% Off For New Customer',
    SpringBigType: 'offer',
    AlpineId: undefined,
    LeafLogixCode: undefined,
    IsRedeemed: false,
  }),
  createMockPreorderRedemption({
    RedemptionDescription: 'LeafLogix: $5 Off Any Grams',
    AlpineId: undefined,
    SpringBigId: undefined,
    IsRedeemed: false,
  }),
  createMockPreorderRedemption({
    RedemptionDescription: 'Fyllo: $10 Off Cannabis Beverages',
    AlpineId: undefined,
    SpringBigId: undefined,
    LeafLogixCode: undefined,
    FylloId: 123,
    IsRedeemed: false,
  }),
];
