import React from 'react';

import { ClearCartPopup as NewClearCartPopup } from './ClearCartPopup';
import { ClearCartPopup as OldClearCartPopup } from './ClearCartPopup-deprecated';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';

import type { ClearCartPopupProps } from './ClearCartPopup';

export const ClearCartPopup = (props: ClearCartPopupProps) => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  return isAnonymousCartLDFlagEnabled ? <NewClearCartPopup {...props} /> : <OldClearCartPopup {...props} />;
};
