import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getLDClient } from 'util/launchDarkly';

const FLAG_NAME = 'pos.iam.login-improvements-2024-05.rollout';

export const useLoginImprovementsFlag = () => {
  const ldClient = useLDClient();

  const isLoginImprovementsEnabled = ldClient?.variation(FLAG_NAME, false) === true;

  return isLoginImprovementsEnabled;
};

export const getIsLoginImprovementsFlagEnabled = () => {
  const ldClient = getLDClient();
  const isLoginImprovementsFlagEnabled = ldClient?.variation(FLAG_NAME, false) === true;
  return { isLoginImprovementsFlagEnabled };
};
