import React, { MouseEventHandler } from 'react';
import { SxProps } from '@mui/material';

import { Loader } from '../../loader';
import { ButtonProps } from '../button';
import { StyledLinkButton } from './link-button.styles';

export type LinkButtonProps = ButtonProps & {
  label: React.ReactNode | string;
  sx?: SxProps;
  variant?: 'primary' | 'secondary';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  automationId?: string;
  ddActionName?: string;
  className?: string;
};

// THIS IS A BUTTON STYLED TO LOOK LIKE A LINK
export function LinkButton({
  label,
  variant = 'primary',
  onClick,
  sx,
  disabled = false,
  loading = false,
  automationId = '',
  ddActionName,
  className,
}: LinkButtonProps): JSX.Element {
  return (
    <StyledLinkButton
      $variant={variant}
      className={className}
      data-testid={automationId}
      data-dd-action-name={ddActionName}
      disabled={disabled}
      sx={sx}
      onClick={onClick}
    >
      {!loading && label}
      {loading && <Loader variant={variant === 'primary' ? 'blue' : 'black'} />}
    </StyledLinkButton>
  );
}
