import React from 'react';

import { CustomerInsights as NewCustomerInsights, useShowCustomerInsights } from './CustomerInsights';
import { CustomerInsights as OldCustomerInsights } from './CustomerInsights-deprecated';
import { useCustomerInsightsFlag } from 'util/hooks/launch-darkly/useCustomerInsightsFlag';

import type { CustomerInsightsProps } from './CustomerInsights';

const CustomerInsights = (props: CustomerInsightsProps) => {
  const isCustomerInsightsEnabled = useCustomerInsightsFlag();

  return isCustomerInsightsEnabled ? <NewCustomerInsights {...props} /> : <OldCustomerInsights {...props} />;
};

export {
  CustomerInsights,
  useShowCustomerInsights,
};
