import React from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { useCategory } from './useCategory';

import type { AllotmentCategory } from 'models/Cart';

export type CategoryProps = {
  allotment: AllotmentCategory;
};

export const Category = ({ allotment }: CategoryProps) => {
  const {
    limitAmountToDisplay,
    limitRemainingToDisplay,
    over,
    percentRemainingToDisplay,
    usedAmountToDisplay,
    usedPercent,
  } = useCategory({ allotment });

  return (
    <CategoryContainer>
      <AllotmentBarContainer>
        <CategoryName>
          {allotment.Category}
          {allotment.AllowDeviceDispensation && <AuthorizationLabel>Delivery Devices Authorized</AuthorizationLabel>}
        </CategoryName>
        <ProgressBar>
          <FillBar over={over} used={usedPercent} />
        </ProgressBar>
      </AllotmentBarContainer>
      <AllotmentDetails>
        <Used data-testid='allotment-details-allotment-used'>
          {usedAmountToDisplay} / {limitAmountToDisplay}
        </Used>
        <Remaining data-testid='allotment-details-allotment-remaining'>
          <BoldRemaining>
            {limitRemainingToDisplay} ({percentRemainingToDisplay})
          </BoldRemaining>
          remaining
        </Remaining>
      </AllotmentDetails>
    </CategoryContainer>
  );
};

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &:not(:last-of-type) {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid ${colors.dutchie.backgroundGrey};
  }
`;

const AllotmentBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const CategoryName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${colors.dutchie.almostBlack};
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
`;

const AuthorizationLabel = styled.div`
  color: ${colors.dutchie.gray70};
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
`;

const FillBar = styled.div<{ used: number; over: boolean }>`
  width: ${({ used }) => used.toFixed(2)}%;
  border-radius: inherit;
  height: 100%;
  background: ${({ over }) => (over ? colors.dutchie.red : colors.dutchie.green)};
`;

const AllotmentDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Used = styled.div`
  color: ${colors.dutchie.grey30};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Remaining = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  color: ${colors.dutchie.almostBlack};
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
`;

const BoldRemaining = styled.div`
  line-height: 1.5rem;
  font-weight: 700;
`;
