import { useQuery } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

export type NonIntegratedPaymentsSetting = {
    IsEnabled: boolean;
    LocId: number;
    LspId: number;
    ManualPaymentProcessorName: string;
    ManualPaymentProcessorId: number;
    ManualPaymentSettingsId: number;
  };

  export const nonIntegratedPaymentsQueryKey = 'non-integrated-payments';

  export function useNonIntegratedPaymentsQuery() {
    return useQuery({
      queryKey: [nonIntegratedPaymentsQueryKey],
      queryFn: () => {
        return post<NonIntegratedPaymentsSetting[]>('manual-payments/get-configured-processors');
      },
    });
  }
