import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import * as FylloApi from 'api/FylloApi';
import { FylloRewardCard } from './FylloRewardCard';
import { SectionWithHeader } from 'components/layout';
import { FylloAppliedRewardCard } from './FylloAppliedRewardCard';
import { errorNotification } from 'store/actions/NotificationsActions';
import { logger } from 'util/logger';
import { callback1 } from 'models/Misc';
import { FylloReward, LoyaltyRedemption, FylloRewardsInfo } from 'models/Fyllo';
import { CartItem } from 'models/Cart';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useAppSelector } from 'util/hooks';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type FylloWalletProps = {
  FylloInfo: FylloRewardsInfo | undefined;
  setFylloInfo: callback1<FylloRewardsInfo | undefined>;
  setLoading: callback1<boolean>;
  item: CartItem | undefined;
};

export const FylloWallet: FC<FylloWalletProps> = ({ FylloInfo, setFylloInfo, setLoading, item }) => {
  const dispatch = useDispatch();
  const refetchCartDetails = useRefetchCartDetails();

  const moveExternalLoyaltyButtonsToCartLevelMenu = useAppSelector(
    (state) => state.settings.features.LoyaltyAsCartDiscount
  );

  const { guestId, shipmentId } = useTransactionManager();

  const reloadAppliedRewards = async () => {
    try {
      const rewards = await FylloApi.getAppliedRewards({
        ShipmentId: shipmentId ?? 0,
      });
      const FylloInfoCopy = cloneDeep(FylloInfo);
      if (FylloInfoCopy) {
        FylloInfoCopy.redemptions = rewards.redemptions;
        FylloInfoCopy.pointsSpent = rewards.pointsSpent;
        setFylloInfo(FylloInfoCopy);
      }
    } catch (error) {
      dispatch(errorNotification(error));
    }
  };

  const addReward = async (reward: FylloReward) => {
    setLoading(true);
    try {
      if (moveExternalLoyaltyButtonsToCartLevelMenu) {
        await FylloApi.addReward({
          ShipmentId: shipmentId ?? 0,
          CustomerId: guestId ?? 0,
          Reward: reward,
        });
      } else {
        await FylloApi.addReward({
          ShipmentId: shipmentId ?? 0,
          AllocatedInventoryId: item?.InventoryId ?? 0,
          ProductId: item?.ProductId ?? 0,
          CustomerId: guestId ?? 0,
          SerialNumber: item?.SerialNo ?? '',
          Reward: reward,
        });
      }

      await refetchCartDetails();

      await reloadAppliedRewards();
    } catch (message) {
      logger.error(`Error adding Fyllo reward to cart`, { message: message });
      dispatch(errorNotification(message));
    }
    setLoading(false);
  };

  const removeReward = async (reward: LoyaltyRedemption) => {
    setLoading(true);
    try {
      await FylloApi.removeReward({
        ShipmentId: shipmentId,
        LoyaltyDiscountRedemptionId: reward.LoyaltyDiscountRedemptionId,
      });

      await refetchCartDetails();

      await reloadAppliedRewards();
    } catch (message) {
      logger.error(`Error removing Fyllo reward from cart`, { message: message });
      dispatch(errorNotification(message));
    }
    setLoading(false);
  };

  return (
    <FylloWalletContainer>
      <FylloWalletTopColumn title='Balance Info'>
        <InfoRow>
          <span>Available Points:</span> {FylloInfo?.customer.current_points || '-'}
        </InfoRow>
        <InfoRow>
          <span>Points Redeemed:</span> {FylloInfo?.pointsSpent || '-'}
        </InfoRow>
        <InfoRow>
          <span>Points Remaining:</span> {FylloInfo ? FylloInfo?.customer.current_points - FylloInfo.pointsSpent : '-'}
        </InfoRow>
      </FylloWalletTopColumn>
      <FylloWalletTopColumn title='Redeemed Rewards and Offers'>
        {!!FylloInfo?.redemptions &&
          FylloInfo?.redemptions.map((redemption) => (
            <FylloAppliedRewardCard
              key={redemption.LoyaltyDiscountRedemptionId}
              reward={redemption}
              onRemove={() => {
                removeReward(redemption);
              }}
            />
          ))}
      </FylloWalletTopColumn>
      <WalletSection title='Rewards'>
        {FylloInfo?.rewards ? (
          FylloInfo?.rewards.map((discountTemplate) => (
            <FylloRewardCard
              key={discountTemplate.id}
              reward={discountTemplate}
              balance={FylloInfo?.customer.current_points || 0}
              spentPoints={FylloInfo?.pointsSpent || 0}
              onAddReward={() => {
                addReward(discountTemplate);
              }}
            />
          ))
        ) : (
          <div>No Rewards Available</div>
        )}
      </WalletSection>
    </FylloWalletContainer>
  );
};

const FylloWalletContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 1.5rem 2rem;
`;

const FylloWalletTopColumn = styled(SectionWithHeader)`
  height: 11rem;
  overflow-x: hidden;
  flex: 0 1 49%;
`;

const WalletSection = styled(SectionWithHeader)`
  flex: 1 1 100%;
  margin: 1em auto;
`;

const InfoRow = styled.p`
  margin: 0.5rem;
  font-weight: bold;
`;
