import React from 'react';

import { colors } from 'css/Theme';

type LockedIconProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export const LockedIcon = ({ fill = colors.dutchie.gray80, width = 14, height = 16 }: LockedIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 14 16' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.33542 7.86647C1.89359 7.86647 1.53542 8.22464 1.53542 8.66647V13.3331C1.53542 13.775 1.89359 14.1331 2.33542 14.1331H11.6687C12.1106 14.1331 12.4688 13.775 12.4688 13.3331V8.66647C12.4688 8.22464 12.1106 7.86647 11.6687 7.86647H2.33542ZM0.46875 8.66647C0.46875 7.63554 1.30449 6.7998 2.33542 6.7998H11.6687C12.6997 6.7998 13.5354 7.63554 13.5354 8.66647V13.3331C13.5354 14.3641 12.6997 15.1998 11.6687 15.1998H2.33542C1.30449 15.1998 0.46875 14.3641 0.46875 13.3331V8.66647Z'
        fill={fill}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.00208 1.86647C6.25948 1.86647 5.54729 2.16147 5.02218 2.68657C4.49708 3.21167 4.20208 3.92387 4.20208 4.66647V7.33314C4.20208 7.62769 3.9633 7.86647 3.66875 7.86647C3.3742 7.86647 3.13542 7.62769 3.13542 7.33314V4.66647C3.13542 3.64097 3.5428 2.65747 4.26794 1.93233C4.99308 1.20718 5.97658 0.799805 7.00208 0.799805C8.02759 0.799805 9.01109 1.20718 9.73623 1.93233C10.4614 2.65747 10.8688 3.64097 10.8688 4.66647V7.33314C10.8688 7.62769 10.63 7.86647 10.3354 7.86647C10.0409 7.86647 9.80208 7.62769 9.80208 7.33314V4.66647C9.80208 3.92387 9.50708 3.21167 8.98198 2.68657C8.45688 2.16147 7.74469 1.86647 7.00208 1.86647Z'
        fill={fill}
      />
    </svg>
  );
};
