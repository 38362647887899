import React, { FC } from 'react';
import styled from 'styled-components';
import { callback, callback1 } from 'models/Misc';
import { popupWidth } from 'css/Theme';
import { useCartPopups } from 'components/CartPopups';
import { Popup } from 'components/popups';
import { PointSet } from 'components/misc';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

const StyledPopup = styled(Popup)`
  width: ${popupWidth.large};
  height: 90%;
  background-color: #2a2a2e;
  & > div:first-of-type {
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    padding: 1rem 0;
    margin: 0 0 1.5rem 0;
  }
  & > div:last-of-type {
    height: 100%;
    & img {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;
    }
  }
`;

type DigitalManifestPopupProps = {
  hide: callback;
  manifestLink: string;
  setSignatureSaved?: callback1<boolean>;
  signatureData?: PointSet;
};

export const DigitalManifestPopup: FC<DigitalManifestPopupProps> = ({
  hide,
  manifestLink,
  setSignatureSaved,
  signatureData,
}) => {
  const cartPopups = useCartPopups();

  const { shipmentId } = useTransactionManager();

  const { isModalBridgeEnabled } = useModalBridge();

  const PopupToUse = isModalBridgeEnabled ? Popup : StyledPopup;

  const handleClose = () => {
    hide();
    if (setSignatureSaved && shipmentId && signatureData) {
      cartPopups.showSignaturePopup({
        setSignatureSaved: setSignatureSaved,
        shipmentId: shipmentId,
        signatureData: signatureData,
      });
    }
  };

  return (
    <PopupToUse
      isVisible
      hide={handleClose}
      canClose={true}
      caption='Manifest(s)'
      large={isModalBridgeEnabled}
      contentMaxHeight={isModalBridgeEnabled ? '100%' : undefined}
    >
      <img src={manifestLink} alt='Customer Manifest' />
    </PopupToUse>
  );
};
