import { getLDClient, useBooleanLDFlag } from 'util/launchDarkly';
import { isWebViewApp, useLocalStorage } from '..';

const key = 'pos.register.settings-ui.rollout';
export const getUseNewSettingsUi = () => {
  const ldClient = getLDClient();

  if (process.env.NODE_ENV === 'development' && localStorage.getItem(key) !== null) {
    return localStorage.getItem(key) === 'true';
  }

  return ldClient?.variation(key, false) === true || isWebViewApp;
};

export const useNewSettingsUi = () => {
  const { value } = useLocalStorage(key);
  const flag = useBooleanLDFlag(key, false);

  if (process.env.NODE_ENV === 'development' && value !== null) {
    return value === 'true';
  }

  return flag || isWebViewApp;
};
