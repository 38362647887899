import { useMutation, useQueryClient } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { secondaryIdentificationKeys } from './query-key-factory';
import {
  SecondaryIdentificationServerResponse,
  SecondaryIdentification,
  transformToSecondaryIdentification,
} from './types';

const UPDATE_SECONDARY_IDENTIFICATION = 'v2/guest/edit-secondary-identification';

type UpdateSecondaryIdentificationPayload = {
  identityId?: number;
  guestId: number;
  stateId: string;
  image: string;
  name: string;
};

const transformToServerPayload = (payload: UpdateSecondaryIdentificationPayload) => {
  return {
    IdentityId: payload.identityId,
    Guest_id: payload.guestId,
    StateId: payload.stateId,
    Image: payload.image,
    name: payload.name,
  };
};

export const useUpdateSecondaryIdentificationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateSecondaryIdentificationPayload): Promise<SecondaryIdentification> => {
      const response = await post<SecondaryIdentificationServerResponse>(
        UPDATE_SECONDARY_IDENTIFICATION,
        transformToServerPayload(payload)
      );

      return transformToSecondaryIdentification(response);
    },
    {
      onSuccess: (_data, payload) => {
        void queryClient.invalidateQueries(secondaryIdentificationKeys.many(payload.guestId));
      },
    }
  );
};
