import React, { FC } from 'react';
import styled from 'styled-components';

import { CheckedInGuest, GuestStatus } from 'models/Guest';
import { colors } from 'css/Theme';

import { DropTarget } from './DropTarget';
import { CardList } from './CardList';

export type BoardViewOrderColumnProps = {
  status: GuestStatus;
  orders: Array<CheckedInGuest>;
  onDrop?: (guest: CheckedInGuest, destinationStatus: string) => void;
};
export const BoardViewOrderColumn: FC<BoardViewOrderColumnProps> = ({ status, orders, onDrop }) => {
  const posStatusId = orders?.length ? orders[0].Status?.PosStatusId || 0 : 0;
  return (
    <ColumnContainer color={status.Color} data-testid='column-container_div_order-column'>
      <ColumnTitleWrapper color={status.Color} data-testid='column-title-wrapper_div_title-wrapper'>
        <GuestColumnTitle data-testid='column-title_div_title'>{status.POSStatus}</GuestColumnTitle>
        <GuestColumnSize data-testid='column-size_div_order-qty'>{`(${orders?.length || 0})`}</GuestColumnSize>
      </ColumnTitleWrapper>
      <CardListContainer data-testid='droppable_div_order-list'>
        {onDrop && <DropTarget status={status.POSStatus} onDrop={onDrop} />}
        <CardList data-testid='inner-list_div_order-list' orders={orders} posStatusId={posStatusId} />
      </CardListContainer>
    </ColumnContainer>
  );
};
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 336px;
  width: 336px;
  overflow: hidden;
`;

export const ColumnTitleWrapper = styled.div<{ color: string }>`
  border-top: 2px solid ${(props) => props.color};
  display: flex;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${colors.dutchie.darkGrey};
  padding: 12px 0 2px 0;
  margin: 0 12px;
`;

export const GuestColumnTitle = styled.div`
  margin-right: 0.25rem;
  font-weight: 600;
`;

export const GuestColumnSize = styled.div`
  text-align: right;
  font-weight: 400;
`;

const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
`;
