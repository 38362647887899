import React from 'react';
import { TableBody } from './components/TableBody';
import { TableFoot } from './components/TableFoot';
import { TableHead } from './components/TableHead';
import { TableRoot } from './components/TableRoot';
import { StyleVariant, TableProps } from './TableProps';
import { ProvideTableStore } from './util/useTableStore';

export const Table = <ItemType extends Record<string, unknown>>(props: TableProps<ItemType>) => (
  <ProvideTableStore props={props}>
    <TableRoot className={props.className ?? ''} styleVariant={props.styleVariant ?? StyleVariant.default}>
      <TableHead />
      <TableBody />
      <TableFoot />
    </TableRoot>
  </ProvideTableStore>
);
