import React from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';

import { Skeleton } from 'components/misc';
import { InputAdornment } from 'components/backoffice/input';
import { SearchIcon } from 'assets/icons/SearchIcon';
import { SearchInput } from './dropdown-menu.styles';
import { SEARCH_PLACEHOLDER_DEFAULT } from './constants';

export function LoadingSkeleton() {
  return (
    <>
      <StyledSearchInput
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon fill={colors.dutchie.grey50} />
            </InputAdornment>
          ),
        }}
        placeholder={SEARCH_PLACEHOLDER_DEFAULT}
      />

      <StyledSkeleton rounded height={20} width={354} />
      <StyledSkeleton rounded height={20} width={354} />
      <StyledSkeleton rounded height={20} width={354} />
      <StyledSkeleton rounded height={20} width={354} />
      <StyledSkeleton rounded height={20} width={354} />
      <StyledSkeleton rounded height={20} width={354} />
    </>
  );
}

const StyledSearchInput = styled(SearchInput)`
  width: 354px;
`;

const StyledSkeleton = styled(Skeleton)`
  margin: var(--sizes-30) var(--sizes-50);
`;
