import { useAppSelector } from 'util/hooks/useAppDispatch';
import { useLDClient } from 'launchdarkly-react-client-sdk';

/**
 * Determines if the Pin Login feature should be used
 */
export const usePinLoginActive = () => {
  const ldClient = useLDClient();
  const ldFlagEnabled = ldClient?.variation('pos.iam.pin-login.rollout', false) ?? false;
  return useAppSelector((state) => state.settings.features.UsePinLogin && ldFlagEnabled);
};
