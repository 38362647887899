import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { searchAndFilter as _searchAndFilter } from 'util/Helpers';
import { GuestStatus, Zone } from 'models/Guest';
import { Vehicle, VehicleFilterOption } from 'models/Vehicle';
import {
  getVehicles,
  setVehicleSearchQuery,
  setVehicleFilterQuery,
  applyVehicleFilters,
  setSelectedVehicles,
} from 'store/actions/FleetActions';

export type FleetListState = {
  vehicles: Array<Vehicle>;
  displayVehicles: Array<Vehicle>;
  filterOptions: Array<VehicleFilterOption>;
  filterSelections: Array<VehicleFilterOption>;
  statuses: Array<GuestStatus>;
  zones: Array<Zone>;
  filterQuery: string;
  query: string;
  submitting: boolean;
  selectedVehicles: Array<Vehicle>;
};

export const initialState: FleetListState = {
  vehicles: [],
  displayVehicles: [],
  filterOptions: [],
  filterSelections: [],
  statuses: [],
  filterQuery: '',
  query: '',
  submitting: false,
  selectedVehicles: [],
  zones: [],
};

const searchAndFilter = (
  allDeliveries: Array<Vehicle>,
  filters: Array<VehicleFilterOption>,
  searchQuery: string
): Array<Vehicle> => {
  const keysToSearch: Array<keyof Vehicle> = ['CarModel', 'CarMake'];

  return _searchAndFilter<Vehicle>(allDeliveries, filters, keysToSearch, searchQuery);
};

export const fleetListSlice = createSlice({
  name: 'fleetList',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<FleetListState>) => {
    builder.addCase(getVehicles.pending, (state) => {
      state.vehicles = [];
    });
    builder.addCase(getVehicles.fulfilled, (state, action) => {
      const allVehicles = action.payload.response;
      const carModelsOptionsSet = new Set<Vehicle['CarModel']>();
      const carMakeOptionsSet = new Set<Vehicle['CarMake']>();

      allVehicles.forEach((v) => {
        if (v.CarModel?.length) {
          carModelsOptionsSet.add(v.CarModel);
        }
        if (v.CarMake?.length) {
          carMakeOptionsSet.add(v.CarMake);
        }
      });

      const filterOptions: Array<VehicleFilterOption> = [
        { label: 'Vehicle Make', key: 'CarMake', options: [...carMakeOptionsSet] },
        { label: 'Vehicle Model', key: 'CarModel', options: [...carModelsOptionsSet] },
      ];

      state.vehicles = allVehicles;
      state.filterOptions = filterOptions;
      state.displayVehicles = searchAndFilter(allVehicles, state.filterSelections, state.filterQuery);
    });

    builder.addCase(setVehicleSearchQuery, (state, action) => {
      state.query = action.payload;
    });

    builder.addCase(setVehicleFilterQuery, (state, action) => {
      state.filterQuery = action.payload;
      state.displayVehicles = searchAndFilter(state.vehicles, state.filterSelections, state.filterQuery);
    });

    builder.addCase(applyVehicleFilters, (state, action) => {
      const filterSelections = action.payload;
      state.filterSelections = filterSelections;
      state.displayVehicles = searchAndFilter(state.vehicles, state.filterSelections, state.filterQuery);
    });

    builder.addCase(setSelectedVehicles, (state, action) => {
      const selectedVehicles = action.payload;
      state.selectedVehicles = selectedVehicles;
    });
  },
});
