import React from 'react';

type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const BarcodeScannerIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_354_15797)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4566 8.34387C13.4126 8.24464 13.3457 8.15725 13.2614 8.08889L13.9961 7.18262C14.2237 7.36713 14.4043 7.60298 14.5231 7.87082C14.6396 8.13353 14.6934 8.41967 14.6804 8.70662C14.6789 9.04253 14.6246 9.37616 14.5193 9.69521L14.519 9.69624C14.277 10.4249 14.2798 11.2127 14.5269 11.9397L14.5287 11.9452C14.7724 12.6864 14.8931 13.4625 14.8862 14.2427C14.8874 14.8193 14.8222 15.3942 14.692 15.9559L14.6914 15.9582C14.5989 16.3501 14.5774 16.7554 14.6281 17.1549C14.6849 17.5932 14.9134 17.9911 15.2634 18.261C15.6136 18.5311 16.0567 18.651 16.4952 18.5943C16.9338 18.5377 17.3319 18.3091 17.6019 17.959C17.872 17.6088 17.9918 17.1657 17.9352 16.7272L16.7543 7.5376L17.9115 7.3889L19.0922 16.5777C19.1885 17.3231 18.9848 18.0762 18.5258 18.6714C18.0668 19.2666 17.3901 19.6551 16.6447 19.7514C15.8993 19.8477 15.1461 19.6439 14.551 19.1849C13.9558 18.7259 13.5673 18.0492 13.471 17.3038L13.4709 17.3027C13.4025 16.7648 13.4312 16.219 13.5557 15.6912C13.6658 15.2161 13.7208 14.7299 13.7195 14.2422L13.7195 14.2353C13.7256 13.5823 13.6249 12.9327 13.4212 12.3122C13.0934 11.3455 13.09 10.2981 13.4116 9.32918C13.4794 9.12358 13.5138 8.90845 13.5137 8.69195L13.5137 8.67584L13.5146 8.65976C13.5205 8.55136 13.5006 8.4431 13.4566 8.34387Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99834 1.20742C2.97005 1.20602 2.94179 1.21116 2.91577 1.22248C2.88785 1.23462 2.86338 1.2535 2.84455 1.27742L2.84157 1.2812C2.8034 1.32885 2.77521 1.38371 2.75873 1.4425C2.74224 1.50129 2.73778 1.56281 2.74561 1.62336L2.74663 1.63123L3.33935 6.76303L3.34031 6.77989C3.34438 6.85107 3.37438 6.9183 3.42464 6.96886C3.47172 7.01623 3.53352 7.04591 3.59964 7.05317H16.4572C16.9978 7.01882 17.5057 6.78097 17.8782 6.38716C18.2536 5.99044 18.4633 5.46544 18.4646 4.9194C18.4582 4.39978 18.267 3.89937 17.9251 3.50791C17.5843 3.1177 17.1161 2.86123 16.604 2.78405L2.99834 1.20742ZM19.0479 4.91651L19.6312 4.91024C19.6226 4.11152 19.3292 3.3421 18.8038 2.74048C18.2784 2.13886 17.5555 1.74455 16.7652 1.62853L16.7564 1.62724L3.11073 0.0459694L3.10134 0.0451879C2.87881 0.0266554 2.65516 0.0635776 2.45039 0.152651C2.24639 0.241394 2.06747 0.379144 1.92952 0.553653C1.79403 0.723307 1.694 0.918461 1.63538 1.12753C1.57695 1.33593 1.56085 1.55392 1.58802 1.76861L2.17676 6.86546C2.20126 7.2143 2.35035 7.54298 2.59717 7.79131C2.84847 8.04414 3.18367 8.19617 3.53944 8.21868L3.55783 8.21984H16.491L16.5074 8.21892C17.3512 8.17139 18.1449 7.80289 18.7257 7.18896C19.3065 6.57502 19.6305 5.76216 19.6313 4.91701L19.0479 4.91651Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.827474 12.5415C1.14964 12.5415 1.41081 12.8221 1.41081 13.1682V18.5398C1.41081 18.8859 1.14964 19.1665 0.827474 19.1665C0.505308 19.1665 0.244141 18.8859 0.244141 18.5398V13.1682C0.244141 12.8221 0.505308 12.5415 0.827474 12.5415Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32747 12.5415C3.64964 12.5415 3.91081 12.8221 3.91081 13.1682V13.8396C3.91081 14.1857 3.64964 14.4663 3.32747 14.4663C3.00531 14.4663 2.74414 14.1857 2.74414 13.8396V13.1682C2.74414 12.8221 3.00531 12.5415 3.32747 12.5415Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32747 15.8989C3.64964 15.8989 3.91081 16.1795 3.91081 16.5256V18.54C3.91081 18.8861 3.64964 19.1666 3.32747 19.1666C3.00531 19.1666 2.74414 18.8861 2.74414 18.54V16.5256C2.74414 16.1795 3.00531 15.8989 3.32747 15.8989Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8275 15.8989C11.1496 15.8989 11.4108 16.1795 11.4108 16.5256V18.54C11.4108 18.8861 11.1496 19.1666 10.8275 19.1666C10.5053 19.1666 10.2441 18.8861 10.2441 18.54V16.5256C10.2441 16.1795 10.5053 15.8989 10.8275 15.8989Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8275 12.5415C11.1496 12.5415 11.4108 12.8221 11.4108 13.1682V13.8396C11.4108 14.1857 11.1496 14.4663 10.8275 14.4663C10.5053 14.4663 10.2441 14.1857 10.2441 13.8396V13.1682C10.2441 12.8221 10.5053 12.5415 10.8275 12.5415Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.32747 12.5415C8.64964 12.5415 8.91081 12.8221 8.91081 13.1682V18.5398C8.91081 18.8859 8.64964 19.1665 8.32747 19.1665C8.00531 19.1665 7.74414 18.8859 7.74414 18.5398V13.1682C7.74414 12.8221 8.00531 12.5415 8.32747 12.5415Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.82747 12.5415C6.14964 12.5415 6.41081 12.8221 6.41081 13.1682V15.1825C6.41081 15.5286 6.14964 15.8092 5.82747 15.8092C5.50531 15.8092 5.24414 15.5286 5.24414 15.1825V13.1682C5.24414 12.8221 5.50531 12.5415 5.82747 12.5415Z" fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.82747 17.2417C6.14964 17.2417 6.41081 17.5223 6.41081 17.8684V18.5398C6.41081 18.8859 6.14964 19.1665 5.82747 19.1665C5.50531 19.1665 5.24414 18.8859 5.24414 18.5398V17.8684C5.24414 17.5223 5.50531 17.2417 5.82747 17.2417Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_354_15797">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
