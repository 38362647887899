import React from 'react';

type LogoutIconProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
  automationId?: string;
};

export function LogoutIcon(props: LogoutIconProps) {
  const { height = 16, width = 16, color = '#ffffff', className, automationId } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 16 16'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.33151 2.53346C3.11934 2.53346 2.91585 2.61775 2.76582 2.76778C2.6158 2.91781 2.53151 3.12129 2.53151 3.33346V12.6668C2.53151 12.879 2.6158 13.0825 2.76582 13.2325C2.91585 13.3825 3.11934 13.4668 3.33151 13.4668H5.99818C6.29273 13.4668 6.53151 13.7056 6.53151 14.0001C6.53151 14.2947 6.29273 14.5335 5.99818 14.5335H3.33151C2.83644 14.5335 2.36165 14.3368 2.01158 13.9867C1.66151 13.6367 1.46484 13.1619 1.46484 12.6668V3.33346C1.46484 2.83839 1.66151 2.3636 2.01158 2.01353C2.36165 1.66346 2.83644 1.4668 3.33151 1.4668H5.99818C6.29273 1.4668 6.53151 1.70558 6.53151 2.00013C6.53151 2.29468 6.29273 2.53346 5.99818 2.53346H3.33151Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2877 4.28967C10.496 4.08139 10.8337 4.08139 11.042 4.28967L14.3753 7.62301C14.5836 7.83129 14.5836 8.16897 14.3753 8.37725L11.042 11.7106C10.8337 11.9189 10.496 11.9189 10.2877 11.7106C10.0794 11.5023 10.0794 11.1646 10.2877 10.9563L13.2439 8.00013L10.2877 5.04392C10.0794 4.83564 10.0794 4.49795 10.2877 4.28967Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.46484 8.00013C5.46484 7.70558 5.70363 7.4668 5.99818 7.4668H13.9982C14.2927 7.4668 14.5315 7.70558 14.5315 8.00013C14.5315 8.29468 14.2927 8.53346 13.9982 8.53346H5.99818C5.70363 8.53346 5.46484 8.29468 5.46484 8.00013Z'
        fill={color}
      />
    </svg>
  );
}
