import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Column } from 'react-table';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Popup } from 'components/popups';
import { TableCompact } from 'components/tables';
import { Transaction, TransactionHistoryRecord } from 'models/Transactions';
import { callback } from 'models/Misc';
import * as api from 'api/TransactionsApi';
import { State } from 'store';
import { usePost } from 'util/hooks';
import { SearchResults } from 'components/layout';
import { ProductHistoryOrder } from 'models/Customer';
import { Delivery } from 'models/Delivery';
import { useModalBridge } from 'util/hooks/launch-darkly/useModalBridge';

const createColumns = (): Array<Column<TransactionHistoryRecord>> => {
  return [
    {
      Header: 'Date',
      accessor: 'TransactionDate',
      Cell: ({ value }) => <span>{format(new Date(value), 'd MMMM yyyy - hh:mm:ss a')}</span>,
    },
    { Header: 'Action', accessor: 'TransactionAction' },
    { Header: 'User', accessor: 'FullName' },
    { Header: 'Comment', accessor: 'TransactionComment' },
  ];
};

type TransactionPopupProps = {
  selectedTransaction?: Transaction | ProductHistoryOrder | Delivery;
  hide: callback;
};

export const TransactionPopup: FC<TransactionPopupProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const register = useSelector((state: State) => state.settings.selectedRegister);
  const { hide, selectedTransaction } = props;
  const columns = createColumns();

  const [transactionHistory, loadingError, transactionHistoryLoading] = usePost(
    api.getTransactionHistory,
    {
      TransactionId: selectedTransaction?.PosId,
      Register: register?.value,
    },
    [selectedTransaction, register]
  );

  const { isModalBridgeEnabled } = useModalBridge();

  return (
    <Popup
      isVisible
      large={!isModalBridgeEnabled}
      width={isModalBridgeEnabled ? '90vw' : undefined}
      caption={`${selectedTransaction?.PosId} - Transaction Details - User Activity`}
      hide={hide}
    >
      <ActivityContainer ref={containerRef}>
        <SearchResults
          loading={transactionHistoryLoading}
          isEmpty={(transactionHistory && !transactionHistory.length) || !!loadingError}
          emptyMessage='There are no activity records or they could not be retrieved'
        >
          <TableCompact<TransactionHistoryRecord>
            data={transactionHistory || []}
            columns={columns}
            containerEl={containerRef.current}
            paginationStyle='Sticky'
          />
        </SearchResults>
      </ActivityContainer>
    </Popup>
  );
};

const ActivityContainer = styled.div`
  height: 100%;
  padding: 0 1.5rem 1.5rem;
`;
