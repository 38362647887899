import styled from 'styled-components';
import { colors } from 'css/Theme';

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0;
  max-width: 936px;
  justify-content: flex-start;
`;

export const InfoColumn = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0 58px;
  flex-grow: 1;
`;

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const InfoTitle = styled.div`
  margin-left: auto;
  width: 50%;
  padding-right: 15px;
  text-align: left;
  color: ${colors.dutchie.grey};
  font-size: 16px;
  margin-right: 32px;
`;

export const InfoData = styled.div`
  margin-right: auto;
  width: 50%;
  padding-left: 15px;
  text-align: left;
  font-size: 16px;
  color: ${colors.dutchie.almostBlack};
`;

export const TableContainer = styled.div`
  clear: both;
  max-width: 100%;
  overflow-x: scroll;
  margin-top: 64px;
  margin-left: 58px;
`;

export const DescCellSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
`;

export const TransactionDetailsWrapper = styled.div`
  width: 65%;
`;

export const CustomerWrapper = styled.div`
  width: 35%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: ${colors.dutchie.shadowGrey};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.dutchie.shadowGrey};
  margin-top: 16px;
`;

