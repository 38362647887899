import React, { FC } from 'react';
import styled from 'styled-components';
import { acceptEducationalMaterials, rejectEducationalMaterials, startCheckout } from 'store/actions/CheckoutActions';
import { ConfirmationPopup } from 'components/popups';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';

const PopupMessage = styled.p`
  text-align: center;
`;

type EducationalMaterialPopupProps = {
  hide: () => void;
};

export const EducationalMaterialPopup: FC<EducationalMaterialPopupProps> = ({ hide }) => {
  const EducationalMaterialsAcceptReject = useAppSelector(
    (state) => state.settings.features.EducationalMaterialsAcceptReject
  );
  const EducationalMaterialsAcceptOnly = useAppSelector(
    (state) => state.settings.features.EducationalMaterialsAcceptOnly
  );
  const dispatch = useAppDispatch();

  const {
    data: { GrandTotalRounded },
  } = useGetCartDetails();

  const checkout = () => {
    dispatch(startCheckout(GrandTotalRounded));
    hide();
  };

  let reject = undefined;
  let accept = undefined;
  if (EducationalMaterialsAcceptReject) {
    accept = {
      text: 'Accepted',
      onClick: () => {
        dispatch(acceptEducationalMaterials());
        checkout();
      },
    };

    reject = {
      text: 'Rejected',
      onClick: () => {
        dispatch(rejectEducationalMaterials());
        checkout();
      },
    };
  }

  if (EducationalMaterialsAcceptOnly) {
    accept = {
      text: 'Accepted',
      onClick: () => {
        dispatch(acceptEducationalMaterials());
        checkout();
      },
    };
  }

  return (
    <ConfirmationPopup small isVisible hide={hide} title={'Educational Materials'} cancel={reject} confirm={accept}>
      <PopupMessage>Educational materials have been offered to the customer</PopupMessage>
    </ConfirmationPopup>
  );
};
