import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { colors, zIndices } from 'css/Theme';
import { ExpandContentButton } from './components/ExpandContentButton';
import { useExpandableContent } from './useExpandableContent';

export type ExpandableContentItem = {
  key: string;
  label: React.ReactNode;
  content?: React.ReactNode;
  secondaryLabel?: string;
  buttonStyles?: FlattenSimpleInterpolation;
  onClick?: () => void;
};

export type TransitionDuration = { appear?: number; enter?: number; exit?: number };

type ExpandableContentProps = {
  content: ExpandableContentItem[];
  transitionDuration?: TransitionDuration;
};

export const ExpandableContent = ({
  content,
  transitionDuration = { enter: 250, exit: 150 },
}: ExpandableContentProps) => {
  const { buttonContainerRef, expandedContent, expandedContentRef, inTransition, isContentExpanded, toggleContent } =
    useExpandableContent({
      transitionDuration,
    });

  return (
    <>
      <CSSTransition in={inTransition} classNames='expanded-content' timeout={transitionDuration} unmountOnExit>
        <ExpandedContent ref={expandedContentRef} duration={transitionDuration}>
          {content.find(({ key }) => key === expandedContent)?.content}
        </ExpandedContent>
      </CSSTransition>
      <ButtonContainer ref={buttonContainerRef}>
        {content.map(({ key, label, secondaryLabel, buttonStyles, onClick }) => (
          <ExpandContentButton
            key={key}
            automationId={`expand-content-button-${key}`}
            isSelected={key === expandedContent}
            isInactive={isContentExpanded && key !== expandedContent}
            label={label}
            secondaryLabel={secondaryLabel}
            onClick={() => onClick?.() ?? toggleContent(key)}
            styles={buttonStyles}
          />
        ))}
      </ButtonContainer>
    </>
  );
};

const ExpandedContent = styled.div<{ duration: TransitionDuration }>`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background: ${colors.dutchie.primaryWhite};
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 0.875rem;
  border-top-right-radius: 0.875rem;

  overflow: hidden;
  z-index: ${zIndices.cartStickyPanelActions};

  &.expanded-content-enter {
    transform: translateY(100%);
    opacity: 0;
  }

  &.expanded-content-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all ${({ duration }) => duration?.enter ?? 250}ms ease-in-out;
  }

  &.expanded-content-enter-done {
    transform: translateY(0);
    opacity: 1;
  }

  &.expanded-content-exit {
    transform: translateY(0);
    opacity: 1;
  }

  &.expanded-content-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: all ${({ duration }) => duration?.exit ?? 150}ms ease-in-out;
  }

  &.expanded-content-exit-done {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.dutchie.grey20};
  z-index: ${zIndices.cartStickyPanel};

  border-top: 1px solid ${colors.dutchie.grey20};
`;
