// From ASCII
export const NUL = 0;
export const SOH = 1;
export const BELL = 7;
export const NEW_LINE = 10;
export const CARRIAGE_RETURN = 13;
export const DC4 = 20;
export const ESC = 27;
export const EXCLAMATION_POINT = 33;
export const COMMA = 44;
export const ZERO = 48;
export const ONE = 49;
export const TWO = 50;
export const THREE = 51;
export const FIVE = 53;
export const SIX = 54;
export const SEVEN = 55;
export const AT_SIGN = 64;
export const A = 65;
export const B = 66;
export const D = 68;
export const F = 70;
export const N = 78;
export const O = 79;
export const S = 83;
export const W = 87;
export const X = 88;
export const Z = 90;
export const UP_CARROT = 94;
export const d = 100;
export const i = 105;
export const p = 112;
