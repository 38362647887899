import React, { FC, useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CanPayLogo, HypurLogo } from 'assets/Assets';
import { PaymentOption } from '../PaymentOption';
import { colors } from 'css/Theme';
import Select, { OptionProps, ValueType } from 'react-select';
import { State } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { PaymentType } from 'models/Checkout';
import { CheckoutContext } from '../CheckoutContext';
import { useCartPopups } from 'components/CartPopups';
import { v4 as uuidv4 } from 'uuid';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

export type GatewayOption = {
  label: string;
  value: string;
  image: string;
};

export const Option = (props: OptionProps<GatewayOption, false>) => {
  const { data, innerProps } = props;
  return (
    <Item {...innerProps}>
      <Logo src={data.image} width={61} />
      <Text>{data.label}</Text>
    </Item>
  );
};

export const PaymentDigital: FC = () => {
  const dispatch = useDispatch();

  const useIntegratedDebit = useSelector((state: State) => state.settings.integrations?.UseIntegratedDebit);
  const acceptHypur = useSelector((state: State) => state.settings.integrations?.AcceptHypur);
  const acceptCanPay = useSelector((state: State) => state.settings.integrations?.AcceptCanPay);
  const digitalPaymentLoading = useSelector((state: State) => state.checkout.digitalPaymentLoading);
  const [gateway, setGateway] = useState<GatewayOption>();
  const { setPaymentMethod, paymentSummary, setPaymentProcessing, setSelectedPaymentType } =
    useContext(CheckoutContext);
  const cartPopups = useCartPopups();
  const debit = paymentSummary[PaymentType.Debit];
  const electronic = paymentSummary[PaymentType.Digital];
  const [transactionAmount, setTransactionAmount] = useState<number>();

  const { shipmentId } = useTransactionManager();

  useEffect(() => {
    setPaymentProcessing(digitalPaymentLoading);
    if (transactionAmount && !digitalPaymentLoading && electronic >= transactionAmount) {
      setSelectedPaymentType(null);
    }
  }, [digitalPaymentLoading, electronic, transactionAmount, setPaymentProcessing, setSelectedPaymentType]);

  const onAddDigital = useMemo(
    () => (amount: number) => {
      if (!gateway) {
        dispatch(errorNotification('No payment gateway selected.'));
        return;
      }

      if (amount <= 0) {
        dispatch(errorNotification(`Invalid ${gateway.label} amount.`));
        return;
      }

      if (debit && debit > 0 && useIntegratedDebit) {
        dispatch(warningNotification("You can't process electronic payment within an integrated debit payment."));
      } else if (electronic && electronic > 0) {
        dispatch(warningNotification("You can't process more than 1 electronic payment."));
      } else {
        const addPaymentMethod = {
          id: uuidv4(),
          name: gateway.value,
          type: PaymentType.Digital,
          amount,
        };
        setTransactionAmount(amount);
        setPaymentMethod(addPaymentMethod);
        if (gateway.value === 'hypur') {
          cartPopups.showHypurCheckoutPopup({ paymentMethod: addPaymentMethod, shipmentId });
        } else if (gateway.value === 'canpay') {
          cartPopups.showCanPayCheckoutPopup({ paymentMethod: addPaymentMethod, shipmentId });
        }
      }
    },
    [cartPopups, debit, dispatch, electronic, gateway, useIntegratedDebit, setPaymentMethod, shipmentId]
  );

  const onChangeGateway = (selectedOption: ValueType<GatewayOption, false>) => {
    setGateway(selectedOption as GatewayOption);
  };

  const gateways: Array<GatewayOption> = [];

  if (acceptHypur) {
    gateways.push({ label: 'Hypur', value: 'hypur', image: HypurLogo.src });
  }

  if (acceptCanPay) {
    gateways.push({ label: 'CanPay', value: 'canpay', image: CanPayLogo.src });
  }

  return (
    <PaymentOption onAddAmount={onAddDigital}>
      <div data-testid='payment-digital_controls'>
        <StyledSelect
          placeholder='Payment Gateway'
          classNamePrefix={'Select'}
          onChange={onChangeGateway}
          options={gateways}
          components={{ Option }}
          value={gateway}
          isClearable
        />
      </div>
    </PaymentOption>
  );
};

const StyledSelect = styled(Select)`
  & .Select__control {
    font-size: 1rem;
    line-height: 1.5rem;
    height: 3rem;
    font-weight: normal;
    border: 1px solid ${colors.dutchie.borderGrey};
  }

  .Select__indicator-separator {
    visibility: hidden;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Logo = styled.img`
  margin: 3px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;
