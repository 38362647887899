import { createAction } from '@reduxjs/toolkit';
import { GuestListPopupsPayloads } from 'store/reducers/PopupsReducer/GuestListPopupsReducer';

export const closeAllPopups = createAction('closeAllPopups');

export const showVisitReasonPopup = createAction(
  'showVisitReasonPopup',
  (payload: GuestListPopupsPayloads['visitReasonPopup']) => ({
    payload,
  })
);

export const showNotifyCustomerPopup = createAction(
  'showNotifyCustomerPopup',
  (payload: GuestListPopupsPayloads['notifyCustomerPopup']) => ({ payload })
);

export const showAnonymousTransactionPopup = createAction(
  'showAnonymousTransactionPopup',
  (payload: GuestListPopupsPayloads['anonymousTransactionPopup']) => ({ payload })
);

export const showSecurityCheckinPopup = createAction(
  'showSecurityCheckinPopup',
  (payload?: GuestListPopupsPayloads['securityCheckinPopup']) => ({ payload: payload ?? {} })
);

export const showVersionCheckerPopup = createAction(
  'showVersionCheckerPopup',
  (payload?: GuestListPopupsPayloads['versionCheckerPopup']) => ({ payload: payload ?? {} })
);

export const showSetTransactionReferencePopup = createAction(
  'showSetTransactionReferencePopup',
  (payload: GuestListPopupsPayloads['setTransactionReferencePopup']) => ({ payload })
);

/** @deprecated To be removed once we migrate to global popups */
export const showFulfillmentSelectorPopup = createAction(
  'showFulfillmentSelectorPopup',
  (payload: GuestListPopupsPayloads['fulfillmentSelectorPopup']) => ({ payload })
);

export const showCancelTransactionPopup = createAction(
  'showCancelTransactionPopup',
  (payload: GuestListPopupsPayloads['cancelTransactionPopup']) => ({ payload })
);

export const showSetStatusPopup = createAction(
  'showSetStatusPopup',
  (payload: GuestListPopupsPayloads['setStatusPopup']) => ({
    payload,
  })
);

export const showReleasePopup = createAction(
  'showReleasePopup',
  (payload: GuestListPopupsPayloads['releasePopup']) => ({
    payload,
  })
);

/** @deprecated To be removed once we migrate to global popups */
export const showSelectCollectorPopup = createAction(
  'showSelectCollectorPopup',
  (payload: GuestListPopupsPayloads['selectCollectorPopup']) => ({ payload })
);

/** @deprecated To be removed once we migrate to global popups */
export const showCreatePreOrderPopup = createAction(
  'showCreatePreOrderPopup',
  (payload: GuestListPopupsPayloads['createPreOrderPopup']) => ({ payload })
);

/** @deprecated To be removed once we migrate to global popups */
export const showVerifyBirthdatePopup = createAction(
  'showVerifyBirthdatePopup',
  (payload: GuestListPopupsPayloads['verifyBirthdatePopup']) => ({ payload })
);

export const showScanPopup = createAction('showScanPopup', (payload?: GuestListPopupsPayloads['scanPopup']) => ({
  payload: payload ?? {},
}));

export const showScanDetailsPopup = createAction(
  'showScanDetailsPopup',
  (payload: GuestListPopupsPayloads['scanDetailsPopup']) => ({ payload })
);

export const showBannedCustomerPopup = createAction(
  'showBannedCustomerPopup',
  (payload: GuestListPopupsPayloads['bannedCustomerPopup']) => ({ payload })
);

export const showCustomerInsightsPopup = createAction(
  'showCustomerInsightsPopup',
  (payload: GuestListPopupsPayloads['customerInsightsPopup']) => ({ payload })
);
