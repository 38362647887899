import { colors } from 'css/Theme';

export const DEFAULT_CARD_OPTIONS = {
  StatusId: 0,
  StatusDescription: '',
  StatusColor: colors.primary,
  Options: [
    {
      DisplayOptionId: 1,
      DisplayOptionName: 'Discount Group',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 2,
      DisplayOptionName: 'Customer Name',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 3,
      DisplayOptionName: 'Nickname',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 4,
      DisplayOptionName: 'Pronouns',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 5,
      DisplayOptionName: 'Medical Card ID',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 6,
      DisplayOptionName: 'Customer Type',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 7,
      DisplayOptionName: 'Room',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 8,
      DisplayOptionName: 'Med Card Exp Date',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 9,
      DisplayOptionName: 'Number of Items in Cart',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 10,
      DisplayOptionName: 'Total Value of Items in Cart',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 11,
      DisplayOptionName: 'Transaction Reference Number',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 12,
      DisplayOptionName: 'Customer Date of Birth',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 13,
      DisplayOptionName: 'Time Window',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 14,
      DisplayOptionName: 'New vs. Existing Customer',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 15,
      DisplayOptionName: 'Loyal vs. Non-loyal',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 16,
      DisplayOptionName: 'State',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 17,
      DisplayOptionName: 'Order Type',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 18,
      DisplayOptionName: 'Order Source',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 19,
      DisplayOptionName: 'Register',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 20,
      DisplayOptionName: 'Date Received',
      IsEnabled: true,
    },
    {
      DisplayOptionId: 21,
      DisplayOptionName: "Driver's License Number",
      IsEnabled: false,
    },
    {
      DisplayOptionId: 22,
      DisplayOptionName: "Driver's License Exp Date",
      IsEnabled: false,
    },
    {
      DisplayOptionId: 23,
      DisplayOptionName: 'Last Purchase Date',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 24,
      DisplayOptionName: 'Address',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 25,
      DisplayOptionName: 'Payment Status',
      IsEnabled: false,
    },
    {
      DisplayOptionId: 26,
      DisplayOptionName: 'Delivery Vehicle',
      IsEnabled: true,
    },
  ],
};
