import React from 'react';
import styled from 'styled-components';

function isButtonDisplayWidth50Percent(numberOfButtons: number, index: number): boolean {
  switch (numberOfButtons) {
    case 1:
    case 2:
      return true;
    case 3:
      return false;
    case 4:
      return true;
    case 5:
      // Top 2 buttons are 50% width, bottom 3 are 33% width
      return index < 2;
    case 6:
      return false;
    default:
      return true;
  }
}

function PaymentNonIntegratedButtons({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <Container>
      {Array.isArray(children) &&
        children.map((child: React.ReactNode, index: number) => (
          <ChildContainer use50PercentMinWidth={isButtonDisplayWidth50Percent(children.length, index)}>
            {child}
          </ChildContainer>
        )
        )}
        {Array.isArray(children) && children.length === 1 && <ChildContainer use50PercentMinWidth={isButtonDisplayWidth50Percent(children.length, 1)} />}
    </Container>
  );
}

export default PaymentNonIntegratedButtons;
const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
`;
const ChildContainer = styled.div<{ use50PercentMinWidth?: boolean }>`
  flex: ${(props) => (props.use50PercentMinWidth ? '2 2 160px' : '3 3 120px')};
`;
