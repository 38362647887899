import { useContext, useState, useEffect } from 'react';

type TrackedState<TrackedStateType> = [TrackedStateType, (state: TrackedStateType) => void];

type StateFormatter<TrackedStateType, FormattedStateType> = (
  getAndSetState: TrackedState<TrackedStateType>
) => FormattedStateType;

/**
 * useTrackedContext
 * A hook for managing contexts with a [state, setState] tuple as their value.
 * This hook will automatically limit rendering based on state changes and usage.
 * @param context Any context can be passed here that complies with the [state, setState] model
 * @param formatter (Optional) A function that takes the context value and returns a formatted output.
 * If formatter is not included, the default return value will be the [state, setState] tuple
 */
export const useTrackedContext = <TrackedStateType, FormattedStateType = TrackedState<TrackedStateType>>(
  context: React.Context<TrackedState<TrackedStateType> | null>,
  formatter: StateFormatter<TrackedStateType, FormattedStateType> = (getAndSetState) =>
    getAndSetState as unknown as FormattedStateType
) => {
  const maybeGetAndSetState = useContext(context);
  if (!maybeGetAndSetState) {
    throw new Error('Context is not available, did you use the Provider?');
  }

  const [formattedState, setFormattedState] = useState(formatter(maybeGetAndSetState));

  useEffect(() => {
    setFormattedState(formatter(maybeGetAndSetState));
  }, [maybeGetAndSetState, formatter]);

  return formattedState;
};
