import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useReplaceGuestObjRefs = () => {
  const ldClient = useLDClient();

  const isReplaceGuestObjRefsEnabled = ldClient?.variation('pos.register.replace-guest-obj-refs', false) === true;

  return {
    /** pos.register.replace-guest-obj-refs */
    isReplaceGuestObjRefsEnabled,
  };
};
