import React, { FC, useState, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import {
  Container,
  FieldWrapper,
  ProductLabel,
  useReturnPopup,
  useReturnReasons,
  YesNoSelectOptions,
  ButtonsContainer,
  Buttons,
  CancelButton,
  ConfirmButton,
  RefundDescription,
  ReturnValueWithMethodPrompt,
  ReturnPopupEnhancedProps,
} from './useReturnPopup';
import { Input, Select, TextArea } from 'components/inputs';
import { Label } from 'components/text';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { ItemReturnDetail, RefundDetail, RefundType } from 'api/TransactionsApi';
import { errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { ConfirmationPopup } from 'components/popups';
import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { ReactComponent as CardIcon } from 'assets/icons/icon-card.svg';
import {
  ReturnItemNotification,
  ReturnItemPollingRequest,
  ReturnItemPollingResponse,
  ReturnItemRequest,
} from 'models/Cart';
import { returnItemComplete, returnItemV2 } from 'store/actions/CartActions';
import { ReturnsPusherChannel } from './ReturnsPusherChannel';
import { pollingFallbackApiReturnItem } from 'api/CartApi';
import { usePollingFallback } from 'pages/CartPage/CartPanels/CheckoutSidebar/usePollingFallback';

const isCardPresentRequired = (returnInfo: ItemReturnDetail): boolean => {
  return !!returnInfo.RefundDetails.find((refundDetail) => refundDetail.CardPresentRequired === true);
};

const getRefundAmountFor = (refundType: string, returnInfo: ItemReturnDetail) => {
  const refundDetail = returnInfo.RefundDetails.find((refundDetail) => refundDetail.RefundType === refundType);
  return refundDetail?.RefundValue || 0;
};

type IGetRefundAmounts = {
  cashReturned: boolean;
  creditReturned: boolean;
  debitReturned: boolean;
  returnInfo: ItemReturnDetail;
};

const getRefundAmounts = ({ cashReturned, creditReturned, debitReturned, returnInfo }: IGetRefundAmounts) => ({
  cashAmount: cashReturned ? getRefundAmountFor(RefundType.Cash, returnInfo) : 0,
  creditAmount: creditReturned ? getRefundAmountFor(RefundType.Credit, returnInfo) : 0,
  debitAmount: debitReturned ? getRefundAmountFor(RefundType.Debit, returnInfo) : 0,
});

const pollingFallback = async (InventoryId: number) => {
  const LspId = window.sessionStorage.getItem('LspId');
  const LocId = window.sessionStorage.getItem('LocId');

  if (!LocId || !LspId) {
    return;
  }

  const request: ReturnItemPollingRequest = {
    LspId,
    LocId,
    InventoryId,
  };
  return pollingFallbackApiReturnItem(request);
};

export const ReturnPopupEnhanced: FC<ReturnPopupEnhancedProps> = ({ isVisible, item, hide, returnInfo }) => {
  const { returnReasons } = useSelector((state: State) => state.customer.options);
  const { features, selectedRegister } = useSelector((state: State) => state.settings);
  const { returningItem } = useSelector((state: State) => state.cart);
  const [managerPin, setManagerPin] = useState<string>();
  const [returnReason, setReturnReason] = useState<string>();
  const [cashReturned, setCashReturned] = useState<boolean>(false);
  const [creditReturned, setCreditReturned] = useState<boolean>(false);
  const [debitReturned, setDebitReturned] = useState<boolean>(false);
  const [returnedToInventory, setReturnToInventory] = useState<boolean>(false);
  const [showCardPresentBanner, setShowCardPresentBanner] = useState<boolean>(false);
  const [returnRequestFailureCount, setReturnRequestFailureCount] = useState<number>(0);
  const { posPaymentsPusherPollingFallback } = useFlags(); //'pos.payments.pusher.polling-fallback'

  const apiRequest = () => {
    return pollingFallback(returnInfo.ItemInventoryId);
  };

  const shouldPollAgain = (response: ReturnItemPollingResponse) => {
    return response.IsFinal === false;
  };
  const { startPolling, pollingResult } = usePollingFallback<ReturnItemPollingResponse>({
    request: apiRequest,
    shouldPollAgain,
  });

  const dispatch = useDispatch();
  useReturnReasons();

  const refundTypeMethods = {
    Cash: setCashReturned,
    Credit: setCreditReturned,
    Debit: setDebitReturned,
  };

  const getIsRefund = () => {
    const refundAmounts = getRefundAmounts({ cashReturned, creditReturned, debitReturned, returnInfo });
    const total = Object.values(refundAmounts).reduce((partialTotal, value) => partialTotal + value, 0);
    return total > 0 ? 'Yes' : 'No';
  };

  // Initial 'Yes'/'No' value for refund dropdowns, based on current state
  const getIsRefundDefault = (refundDetail: RefundDetail) => {
    switch (refundDetail.RefundType) {
      case RefundType.Cash:
        return cashReturned ? 'Yes' : 'No';
      case RefundType.Credit:
        return creditReturned ? 'Yes' : 'No';
      case RefundType.Debit:
        return debitReturned ? 'Yes' : 'No';
      default:
      return 'No';
    }
  };

  const onRefund = () => {
    if (!returnReason) {
      dispatch(warningNotification('Please provide a reason'));
      return;
    }

    if (selectedRegister && selectedRegister.value) {
      const refundAmounts = getRefundAmounts({ cashReturned, creditReturned, debitReturned, returnInfo });
      const isRefund = getIsRefund();
      const returnedItem: ReturnItemRequest = {
        PutBackInInventory: returnedToInventory ? 'Yes' : 'No',
        RegisterId: selectedRegister.value,
        ReturnReason: returnReason,
        InventoryId: item.PosId,
        ShipmentId: item.ReceiptNo,
        CashRefundAmount: refundAmounts.cashAmount,
        CreditRefundAmount: refundAmounts.creditAmount,
        DebitRefundAmount: refundAmounts.debitAmount,
        // CashRet represents whether value is returned to the customer regardless of tender method
        CashRet: isRefund,
      };
      dispatch(returnItemV2({ item: returnedItem, pinCode: managerPin }));
      if (posPaymentsPusherPollingFallback) {
        startPolling();
      }
    } else {
      dispatch(warningNotification('Please select a register before continuing'));
      setReturnRequestFailureCount(returnRequestFailureCount + 1);
    }
  };

  const { onChangeManagerPin, confirmReturn } = useReturnPopup({ setManagerPin, onRefund });

  React.useEffect(() => {
    const cardPresentNeededForReturn = isCardPresentRequired(returnInfo);
    const refundAmounts = getRefundAmounts({ cashReturned, creditReturned, debitReturned, returnInfo });
    setShowCardPresentBanner(cardPresentNeededForReturn && returningItem && refundAmounts.debitAmount > 0);
  }, [cashReturned, creditReturned, debitReturned, returnInfo, returningItem]);

  const shipmentId = item.ReceiptNo;
  const inventoryId = returnInfo.ItemInventoryId;
  const registerId = selectedRegister?.value;
  const onReturnedCallback = useCallback(
    async (notification?: ReturnItemNotification, parseError?: unknown) => {
      if (
        notification &&
        notification.ShipmentId.toString() === shipmentId &&
        notification.InventoryId === inventoryId
      ) {
        dispatch(returnItemComplete({ notification, shipmentId: notification.ShipmentId, registerId }));
        hide();
      } else if (parseError) {
        dispatch(errorNotification(`Failed to parse Pusher notification: ${parseError}`));
        hide();
      }
    },
    [dispatch, hide, shipmentId, inventoryId, registerId]
  );

  React.useEffect(() => {
    if (pollingResult && pollingResult.IsFinal === true) {
      onReturnedCallback(pollingResult.ReturnItemNotification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingResult]);

  return (
    <>
      <ReturnsPusherChannel enabled={isVisible || returningItem} onPusherMessageCallback={onReturnedCallback} />
      <ConfirmationPopup
        isVisible={isVisible}
        hide={hide}
        title='Return Product'
        canClose={!returningItem}
        buttonsSectionVisible={false}
      >
        <Container>
          <ProductLabel>{returnInfo.ItemName}</ProductLabel>
          {returnInfo.RefundDetails.map((refundDetail, index) => (
            <RefundDescription
              amount={refundDetail.RefundValue}
              cardNumberLastFour={refundDetail.CardLast4}
              refundType={refundDetail.RefundType}
              key={`RefundDescription-${index}`}
            />
          ))}
          {returnInfo.RefundDetails.map((refundDetail, index) => (
            <ReturnValueWithMethodPrompt
              refundType={refundDetail.RefundType}
              amount={refundDetail.RefundValue}
              defaultValue={getIsRefundDefault(refundDetail)}
              refundTypeMethods={refundTypeMethods}
              key={`ReturnValueWithMethodPrompt-${index}`}
              disabled={returningItem}
            />
          ))}
          <FieldWrapper>
            <Label>Returned to Inventory?</Label>
            <Select
              onChange={(value) => setReturnToInventory(value === 'Yes')}
              defaultValue={returnedToInventory ? 'Yes' : 'No'}
              options={YesNoSelectOptions}
              disabled={returningItem}
            />
          </FieldWrapper>
          <FieldWrapper data-testid={'reason-for-return-fieldset'}>
            <Label>Return Reason</Label>
            {_.isEmpty(returnReasons) ? (
              <TextArea
                placeholder='Return Reason'
                onChange={(e) => setReturnReason(e.target.value)}
                value={returnReason}
                disabled={returningItem}
              />
            ) : (
              <Select
                defaultValue={returnReason}
                placeholder='Saved Reasons'
                onChange={(value) => setReturnReason(value)}
                automationId='return-popup_styled-select_reason_for_return'
                options={returnReasons.map((item) => ({ value: item.Reason, label: item.Reason }))}
                disabled={returningItem}
              />
            )}
          </FieldWrapper>

          {features.MgrPwForReturns && (
            <>
              <Input placeholder='Manager PIN' type='password' value={managerPin} onChange={onChangeManagerPin} />
            </>
          )}
          {showCardPresentBanner && (
            <AlertBanner
              style={AlertBannerStyles.info}
              text='Ask customer to tap, insert, or swipe card'
              icon={<CardIcon />}
            />
          )}
        </Container>

        <ButtonsContainer>
          {returnRequestFailureCount > 0 && (
            <AlertBanner
              style={AlertBannerStyles.error}
              title='No response from terminal:'
              text='[Ask engineering what things can be done to try and alleviate this error]'
            />
          )}
          <Buttons>
            <CancelButton
              disabled={returningItem}
              type='button'
              data-testid='confirmation-popup_cancel-button_cancel'
              onClick={hide}
            >
              {!returningItem ? 'Cancel' : ''}
            </CancelButton>

            <ConfirmButton
              automationId='confirmation-popup_confirm-button_confirm'
              loading={returningItem}
              disabled={!returnReason}
              onClick={confirmReturn}
            >
              {!returningItem && returnRequestFailureCount === 0 && 'Process return'}
              {!returningItem && returnRequestFailureCount > 0 && 'Retry return'}
            </ConfirmButton>
          </Buttons>
        </ButtonsContainer>
      </ConfirmationPopup>
    </>
  );
};
