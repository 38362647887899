import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { Select } from 'components/inputs';
import { Flex, Box } from 'components/layout/Flexbox';
import { PaginationButtons } from './PaginationButtons';
import { PaginationProps } from './common';
import { usePageSizeOpts } from './usePageSizeOpts';

export const AdvancedPagination: FC<PaginationProps> = ({
  small = false,
  pageIndex,
  pageSize,
  dataLength,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  goToPage,
  setPageSize,
}) => {
  const currentPage = pageIndex + 1;
  const pagesCount = Math.ceil(dataLength / pageSize);
  const rangeStart = 1 + pageIndex * pageSize;
  const rangeEnd = canNextPage ? pageIndex * pageSize + pageSize : dataLength;
  const pageSizeOpts = usePageSizeOpts(pageSize);

  const handlePageSizeChange = (val: string) => {
    setPageSize(parseInt(val));
  };

  return (
    <PaginationContainer small={small} data-testid='pagination_advanced_container'>
      <PaginationButtons
        small={small}
        currentPage={currentPage}
        pagesCount={pagesCount}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        goToPage={goToPage}
      />
      <Flex direction='row' alignItems='center'>
        <PageCounter data-testid='pagination-current-page-details'>
          Displaying{' '}
          <strong>
            {rangeStart} - {rangeEnd}
          </strong>{' '}
          of {dataLength}
        </PageCounter>
        <Box width='100px' marginLeft='20px' marginRight='10px'>
          <Select
            height={small ? '2rem' : undefined}
            options={pageSizeOpts}
            value={pageSize}
            onChange={handlePageSizeChange}
            automationId='pagination-per-page-select'
          />
        </Box>
        <Box>Per page</Box>
      </Flex>
    </PaginationContainer>
  );
};

export const PaginationContainer = styled.div<{ small?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ small }) => small !== true && `height: 6rem;`}
  width: 100%;
`;

export const PageCounter = styled.div`
  color: ${colors.dutchie.almostBlack};
`;
