import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Chevron, ChevronDirections } from 'assets/icons/chevron';
import { Column, Row } from 'components/layout';
import { colors } from 'css/Theme';

export type SettingsListItemProps = {
  automationId?: string;
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
  actions?: JSX.Element[];
  onClick?: () => void;
  padding?: string;
};

export const SettingsListItem = ({
  automationId,
  icon,
  title,
  subtitle,
  actions,
  onClick,
  padding,
}: SettingsListItemProps) => {
  const displayActions = useMemo(() => {
    const displayActions = [...(actions ?? [])];

    if (onClick) {
      displayActions.push(<Chevron width={16} height={16} direction={ChevronDirections.RIGHT} />);
    }

    return displayActions;
  }, [actions, onClick]);

  return (
    <Container data-testid={automationId} clickable={!!onClick} onClick={onClick} padding={padding ?? '24px 0'}>
      <Row alignItems='center' width={'100%'}>
        {icon && <IconContainer>{icon}</IconContainer>}
        <Column width='100%'>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Column>
        {displayActions.length > 0 && (
          <ActionRow alignItems='center'>
            {displayActions.map((action, index) => (
              <ActionContainer key={`action_${index}`}>{action}</ActionContainer>
            ))}
          </ActionRow>
        )}
      </Row>
    </Container>
  );
};

export const SettingsListItemChevronPlaceholder = () => <Spacer />;

const Container = styled.div<{ clickable: boolean; padding: string }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  padding: ${({ padding }) => padding};
`;

const Spacer = styled.div`
  height: 16px;
  width: 16px;
`;

const Title = styled.div`
  color: ${colors.dutchie.black};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const Subtitle = styled.div`
  color: ${colors.dutchie.gray80};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ActionRow = styled(Row)`
  gap: 10px;
  margin-left: 8px;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 20px;
  white-space: nowrap;
`;

const IconContainer = styled.div`
  background: ${colors.dutchie.blueGrey};
  border-radius: 8px;
  display: inline-block;
  margin-right: 16px;
  padding: 10px;
  min-width: 36px;
  width: 36px;
  height: 36px;

  svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
`;
