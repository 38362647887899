import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { cloneDeep } from 'lodash';

import * as AlpineIQApi from 'api/AlpineIQApi';
import { errorNotification } from 'store/actions/NotificationsActions';
import { callback1 } from 'models/Misc';
import { AlpineIQReward, AlpineIQInfo, LoyaltyRedemption } from 'models/AlpineIQ';
import { CartItem } from 'models/Cart';
import { borderStyles, colors } from 'css/Theme';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ImageAsset } from 'components/image';
import { DutchieLogoImage } from 'assets/Assets';
import { logger } from 'util/logger';
import { Button } from 'components/buttons';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useAppSelector } from 'util/hooks';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

type AlpineIQWalletProps = {
  alpineIQInfo: AlpineIQInfo | undefined;
  setAlpineIQInfo: callback1<AlpineIQInfo | undefined>;
  setLoading: callback1<boolean>;
  item: CartItem | undefined;
};

export const AlpineIQWallet: FC<AlpineIQWalletProps> = ({ alpineIQInfo, setAlpineIQInfo, setLoading, item }) => {
  const dispatch = useDispatch();
  const refetchCartDetails = useRefetchCartDetails();

  const { guestId, shipmentId } = useTransactionManager();

  const moveExternalLoyaltyButtonsToCartLevelMenu = useAppSelector(
    (state) => state.settings.features.LoyaltyAsCartDiscount
  );

  const reloadAppliedRewards = async () => {
    try {
      const rewards = await AlpineIQApi.getAppliedRewards({
        ShipmentId: shipmentId ?? 0,
      });
      const alpineIQInfoCopy = cloneDeep(alpineIQInfo);
      if (alpineIQInfoCopy) {
        alpineIQInfoCopy.redemptions = rewards.redemptions;
        alpineIQInfoCopy.pointsSpent = rewards.pointsSpent;
        setAlpineIQInfo(alpineIQInfoCopy);
      }
    } catch (error) {
      dispatch(errorNotification(String(error)));
    }
  };

  const addReward = async (reward: AlpineIQReward) => {
    setLoading(true);
    try {
      if (moveExternalLoyaltyButtonsToCartLevelMenu) {
        await AlpineIQApi.addReward({
          ShipmentId: shipmentId ?? 0,
          CustomerId: guestId ?? 0,
          Reward: reward,
        });
      } else {
        await AlpineIQApi.addReward({
          ShipmentId: shipmentId ?? 0,
          AllocatedInventoryId: item?.InventoryId ?? 0,
          ProductId: item?.ProductId ?? 0,
          CustomerId: guestId ?? 0,
          SerialNumber: item?.SerialNo ?? '',
          Reward: reward,
        });
      }
      await refetchCartDetails({
        guestId: guestId,
        shipmentId: shipmentId,
      });
      await reloadAppliedRewards();
    } catch (message) {
      logger.error(message, { message: 'Error AlpineIQ adding reward to cart' });
      dispatch(errorNotification(message));
    }
    setLoading(false);
  };

  const removeReward = async (reward: LoyaltyRedemption) => {
    setLoading(true);
    try {
      await AlpineIQApi.removeReward({
        ShipmentId: shipmentId,
        LoyaltyDiscountRedemptionId: reward.LoyaltyDiscountRedemptionId,
      });

      await refetchCartDetails({
        guestId: guestId,
        shipmentId: shipmentId,
      });

      await reloadAppliedRewards();
    } catch (message) {
      logger.error(message, { message: 'Error removing AlpineIQ reward from cart' });
      dispatch(errorNotification(message));
    }
    setLoading(false);
  };

  return (
    <WalletContainer>
      <WalletSectionHalf>
        <p>Balance Info</p>
        <WalletSectionContentTop>
          <WalletText>Available Points: {alpineIQInfo?.wallet.loyaltyPoints || 0}</WalletText>
          <WalletText>Points Redeemed: {alpineIQInfo?.pointsSpent || 0}</WalletText>
          <WalletText>
            Points Remaining: {alpineIQInfo ? alpineIQInfo.wallet.loyaltyPoints - alpineIQInfo.pointsSpent : 0}
          </WalletText>
        </WalletSectionContentTop>
      </WalletSectionHalf>
      <WalletSectionHalf>
        <p>Redeemed Rewards and Offers</p>
        <WalletSectionContentTop>
          {!!alpineIQInfo?.redemptions &&
            alpineIQInfo?.redemptions.map((redemption) => (
              <WalletSectionContentCard key={redemption.LoyaltyDiscountRedemptionId}>
                <WalletText>
                  {redemption.RedemptionName} - {redemption.LoyaltyPointCost} points
                </WalletText>
                <StyledDelete onClick={() => removeReward(redemption)}></StyledDelete>
              </WalletSectionContentCard>
            ))}
        </WalletSectionContentTop>
      </WalletSectionHalf>
      <WalletSectionFull>
        <p>Offers</p>
        <WalletSectionContent>
          {alpineIQInfo?.wallet?.discountTemplates ? (
            alpineIQInfo?.wallet.discountTemplates.map((discountTemplate) => {
              const canAfford =
                (alpineIQInfo?.wallet.loyaltyPoints || 0) - (alpineIQInfo?.pointsSpent || 0) >=
                discountTemplate.pointsDeduction;
              return (
                <WalletSectionContentCardBordered key={discountTemplate.id}>
                  {discountTemplate.avatar ? (
                    <RewardCardImg src={discountTemplate.avatar} />
                  ) : (
                    <RewardCardImgAsset asset={DutchieLogoImage} />
                  )}
                  <div>
                    <WalletText>{discountTemplate.name}</WalletText>
                    <WalletTextAfford afford={canAfford}>
                      Point Value:{discountTemplate.pointsDeduction}
                    </WalletTextAfford>
                    <WalletText>{discountTemplate.pageTitle}</WalletText>
                  </div>
                  <Button disabled={!canAfford} onClick={() => addReward(discountTemplate)}>
                    Add
                  </Button>
                </WalletSectionContentCardBordered>
              );
            })
          ) : (
            <div>No Rewards Available</div>
          )}
        </WalletSectionContent>
      </WalletSectionFull>
    </WalletContainer>
  );
};

const imageStyles = css`
  border: ${borderStyles.border};
  border-radius: ${borderStyles.borderRadius};
  width: 100px;
  height: 100px;
  margin-right: 0.75rem;
  padding: 0.5rem;
`;

const WalletContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

const WalletSectionFull = styled.div`
  flex-basis: 100%;
`;

const WalletSectionHalf = styled.div`
  flex-basis: 49%;
`;

const WalletSectionContent = styled.div`
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 470px);
  overflow-y: auto;
`;

const WalletSectionContentTop = styled(WalletSectionContent)`
  justify-content: center;
  height: 150px;
  border: ${borderStyles.border};
  border-radius: ${borderStyles.borderRadius};
  padding: 1rem 1.25rem;
`;

const WalletSectionContentCard = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  & > *:last-child {
    margin-left: auto;
  }
`;

const WalletSectionContentCardBordered = styled(WalletSectionContentCard)`
  border: ${borderStyles.border};
  border-radius: ${borderStyles.borderRadius};
  padding: 1.25rem;
`;

const WalletText = styled.p`
  color: ${colors.dutchie.grey};
`;

const WalletTextAfford = styled(WalletText)<{ afford: boolean }>`
  ${({ afford }) => !afford && `color: ${colors.dutchie.red}`};
`;

const RewardCardImg = styled.img`
  ${imageStyles};
`;

const RewardCardImgAsset = styled(ImageAsset)`
  ${imageStyles};
`;

const StyledDelete = styled(Delete)`
  color: ${colors.dutchie.grey};
  cursor: pointer;
`;
