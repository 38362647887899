import React, { PropsWithChildren, createContext } from 'react';
import { useCfdSettingsProvider } from '../hooks/useCfdSettingsProvider';
import { CfdSettingsContextData } from '../types';

export const CfdSettingsContext = createContext<CfdSettingsContextData>({} as CfdSettingsContextData);

export const CfdSettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const data = useCfdSettingsProvider();
  return <CfdSettingsContext.Provider value={data}>{children}</CfdSettingsContext.Provider>;
};
