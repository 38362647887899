import React from 'react';
import styled from 'styled-components';

import type { CheckedInGuest } from 'models/Guest';

import { StarIcon } from 'assets/icons/star-icon';
import { colors } from 'css/Theme';

import { useLoyaltyPill } from './useLoyaltyPill';

type LoyaltyPillProps = {
  guest: CheckedInGuest;
  cardOptionsStatus: { [key: string]: boolean };
};
export const LoyaltyPill: React.FC<LoyaltyPillProps> = ({ guest, cardOptionsStatus }) => {
  const { displayLoyaltyBadge, isUsingExternalLoyaltyTiers } = useLoyaltyPill({ guest, cardOptionsStatus });

  if (!displayLoyaltyBadge) {
    return null;
  }

  if (isUsingExternalLoyaltyTiers) {
    return (
      <ExternalLoyaltyStyledStarIcon data-testid='guest-card-loyalty-pill' color={guest.LoyaltyPillBackgroundColor}>
        <StarIcon color={guest.LoyaltyPillTextColor ?? colors.dutchie.starGold} data-testid='order-card_loyalty_svg' />
        <LoyaltyTier color={guest.LoyaltyPillTextColor}>{guest.LoyaltyTier}</LoyaltyTier>
      </ExternalLoyaltyStyledStarIcon>
    );
  }

  return (
    <StyledStarIcon data-testid='guest-card-loyalty-pill'>
      <StarIcon color={colors.dutchie.starGold} data-testid='order-card_loyalty_svg' />
    </StyledStarIcon>
  );
};

const StyledStarIcon = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color ?? '#f4f2b0'};
  border-radius: 20px;
  display: block;
  width: 28px;
  height: 24px;

  svg {
    padding: 4px 6px;
    width: 100%;
    height: 100%;
  }
`;

const ExternalLoyaltyStyledStarIcon = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color ?? '#f4f2b0'};
  border-radius: 20px;
  height: 24px;
  display: inline-flex;
  gap: 8px;

  svg {
    padding: 6px 0px 6px 12px;
    width: 100%;
    height: 100%;
  }
`;

const LoyaltyTier = styled.span<{ color?: string }>`
  padding-right: 12px;
  font-size: 11px;
  font-weight: 600;
  color: ${({ color }) => color ?? colors.dutchie.starGold};
`;
