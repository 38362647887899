import { redirectTo } from 'util/helpers/redirectTo';
import { getDeviceSession, isDeviceSessionExpired } from 'util/helpers/pin-login/is-device-session-expired';

export const handleEnforcingPinLoginRedirect = () => {
  const pinLoginDeviceSession = getDeviceSession();
  const isResetPinWithSSO = window.location.search.toLowerCase().includes('samlsessionid');
  const urlParams = new URLSearchParams(window.location.search);
  const isPinLoginRedirect = urlParams.get('fromPin') === 'true';

  if (
    !isPinLoginRedirect &&
    pinLoginDeviceSession &&
    !isDeviceSessionExpired(pinLoginDeviceSession) &&
    !isResetPinWithSSO
  ) {
    redirectTo('/pinlogin');
  }
};
