import { Peripheral, PeripheralData } from '@dutchie/capacitor-peripheral';
import { PrinterResponse } from 'models/Misc';
import { TEST_FOOTER, TEST_HEADER } from './Constants/ESCEncodingConstants';

// Prints a test string to a local ESC receipt printer
export const testLocalReceiptPrinter = (printerId: string, testString: string) => {
  let bytes: number[] = [];
  bytes = bytes.concat(TEST_HEADER);
  for (let i = 0; i < testString.length; i++) {
    bytes.push(testString.charCodeAt(i));
  }
  bytes = bytes.concat(TEST_FOOTER);
  Peripheral.printReceipt({ id: printerId, bytes });
};

// Returns the connected local printer from the list or returns the first disconnected one from the list
export const findConnectedLocalPrinter = (printers: PeripheralData[]) => {
  const connectedPrinter = printers.find((p) => p.connected);
  const printer = connectedPrinter ?? printers?.[0];
  return printer;
};

// Returns a partial userSettings object for the given printerId in the list
export const getLocalPrinterSettings = (printerId: string, printers: PeripheralData[]) => {
  const newPrinter = printers.find((item) => item.id === printerId);
  const newPrinterSettings =
    newPrinter === undefined
      ? undefined
      : {
          PrinterId: newPrinter.id,
          Name: `${newPrinter.name}`,
          LocalPrinter: true,
        };
  return newPrinterSettings;
};

// Returns a partial userSettings object for the given printerId in the list
export const getNetworkPrinterSettings = (printerId: number, printers: PrinterResponse[]) => {
  const newPrinter = printers.find((item) => item.PrinterId === printerId);
  const newPrinterSettings =
    newPrinter === undefined
      ? undefined
      : {
          PrinterId: newPrinter.PrinterId,
          Name: `${newPrinter.Name}`,
          LocalPrinter: false,
        };
  return newPrinterSettings;
};

export const switchPrinterSettings = (
  switchToLocalPrinter: boolean,
  localPrinters: PeripheralData[],
  networkPrinters: PrinterResponse[]
) => {
  if (switchToLocalPrinter) {
    const newLocalPrinter = findConnectedLocalPrinter(localPrinters);
    const newLocalPrinterId = newLocalPrinter?.id;
    return getLocalPrinterSettings(newLocalPrinterId, localPrinters);
  } else {
    const newNetworkPrinter = networkPrinters?.[0];
    const newNetworkPrinterId = newNetworkPrinter?.PrinterId;
    return getNetworkPrinterSettings(newNetworkPrinterId, networkPrinters);
  }
};
