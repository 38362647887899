import React from 'react';
import styled from 'styled-components';

import { StyledGridContainer } from './no-results-overlay';
import { RegularText } from '../typography';
import { Loader } from './loader';

export function PageLoader({ copy }: { copy?: string }) {
  return (
    <StyledGridContainer>
      <Loader size='3x' variant='grey' />
      {copy && (
        <StyledRegularText aria-busy='true' role='alert'>
          {copy}
        </StyledRegularText>
      )}
    </StyledGridContainer>
  );
}

const StyledRegularText = styled(RegularText)`
  color: var(--color-greyscale-grey-40);
  margin-top: var(--sizes-60);
`;
