import React, { FC } from 'react';
import styled from 'styled-components';

import { LoadingButton } from 'components/buttons';
import { Chevron } from 'assets/icons/chevron';

import { useCartActionButton } from './useCartActionButton';

type CartActionButtonProps = {
  disabled: boolean;
};

export const CartActionButton: FC<CartActionButtonProps> = ({ disabled }) => {
  const {
    shipmentId,
    isLoading,
    label,
    handleClick,
  } = useCartActionButton();

  return (
    <StyledLoadingButton
      loading={isLoading}
      disabled={disabled}
      height='52px'
      onClick={handleClick}
      id={`checkoutButton_${shipmentId}`}
      automationId='cart-action-button'
    >
      {label}
      <Chevron fill='white' width={16} height={16} />
    </StyledLoadingButton>
  );
};

const StyledLoadingButton = styled(LoadingButton)`
  flex: 2;
`;
