import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { colors } from 'css/Theme';
import { Input } from 'components/inputs';
import { LoadingButton } from 'components/buttons';
import { callback2 } from 'models/Misc';
import { checkManagerPin } from 'util/Helpers';

type ManagerSectionProps = {
  disabled?: boolean;
  needPin: boolean;
  title: string;
  placeholder?: string;
  onSuccess: callback2<number, string>;
};

export const DutchieManagerSection: FC<ManagerSectionProps> = (props) => {
  const { disabled, needPin, title, placeholder, onSuccess } = props;

  const dispatch = useDispatch();
  const [managerPin, setManagerPin] = useState('');
  const [managerPinWrong, setManagerPinWrong] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    setManagerPinWrong(false);

    let ApprovingManagerUserId;
    try {
      if (needPin) {
        ApprovingManagerUserId = await checkManagerPin(managerPin);
        onSuccess(ApprovingManagerUserId[0]?.UserId, managerPin);
        dispatch(successNotification('PIN verified'));
      } else {
        onSuccess(0, '');
      }

      setManagerPin('');
      setLoading(false);
    } catch {
      setLoading(false);
      setManagerPinWrong(true);
      dispatch(errorNotification('Invalid PIN'));
    }
  };

  const onManagerPinChange = (pin: string) => {
    managerPinWrong && setManagerPinWrong(false);
    setManagerPin(pin);
  };

  return (
    <ManagerSectionBase data-testid='manager-section_div_section' wrong={managerPinWrong}>
      <form
        //@ts-ignore: This is a magic attribute to disble autofill,
        //typescript doesn't know about it, we need this or it will autocomplete your username
        //into the product search bar and search for a package with your
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {needPin && (
          <StyledInput
            data-testid='manager-section_styled-input'
            value={managerPin}
            onChange={(e) => {
              //remote non-numbers since pins are numbers only
              const cleaned = e.target.value.replace(/\D/g, '');
              onManagerPinChange(cleaned);
            }}
            placeholder={placeholder || 'Manager PIN'}
            type='password'
          />
        )}
        <StyledButton
          automationId='manager-section_styled-button_submit-pin'
          loading={loading}
          disabled={disabled || (needPin && !managerPin)}
          onClick={onSubmit}
        >
          {title}
        </StyledButton>
      </form>
    </ManagerSectionBase>
  );
};

const ManagerSectionBase = styled.div<{ wrong?: boolean }>`
  margin: 24px;
  margin-bottom: 1rem;
  border: 1px solid ${({ wrong }) => (wrong ? colors.dutchie.red : colors.dutchie.primaryWhite)} !important;
  border-radius: 7px;
`;

const StyledInput = styled(Input)`
  width: 12.8rem;
`;

const StyledButton = styled(LoadingButton)`
  float: right;
  margin: 24px 0;
`;
