import { CreateCustomerRequest, CustomerDetails, EditCustomerDetailsRequest } from 'models/Customer';

export const BuildEditCustomerDetailsRequest = (customer: CustomerDetails): EditCustomerDetailsRequest => ({
  AddressBook: customer.AddressBook,
  Attestation: customer.Attestation,
  AttestationSignedDate: customer.AttestationSignedDate,
  AttestationExpirationDate: customer.AttestationExpirationDate,
  CGFirstName: customer.CGFirstName,
  CGLastName: customer.CGLastName,
  CaregiverMJStateIdNo: customer.CaregiverMJStateIdNo,
  CaregiverAddress: customer.CaregiverAddress,
  CaregiverAddress2: customer.CaregiverAddress2,
  CaregiverAddress3: customer.CaregiverAddress3,
  CaregiverCity: customer.CaregiverCity,
  CaregiverState: customer.CaregiverState,
  CaregiverPostalCode: customer.CaregiverPostalCode,
  CaregiverDOB: customer.CaregiverDOB,
  CaregiverDOB2: customer.CaregiverDOB2,
  CaregiverDOB3: customer.CaregiverDOB3,
  CaregiverDOB4: customer.CaregiverDOB4,
  CaregiverDOB5: customer.CaregiverDOB5,
  CaregiverDOB6: customer.CaregiverDOB6,
  CaregiverEmail: customer.CaregiverEmail,
  CaregiverEmail2: customer.CaregiverEmail2,
  CaregiverEmail3: customer.CaregiverEmail3,
  CaregiverEmail4: customer.CaregiverEmail4,
  CaregiverEmail5: customer.CaregiverEmail5,
  CaregiverEmail6: customer.CaregiverEmail6,
  CaregiverExpirationDate: customer.CaregiverExpirationDate,
  CaregiverMJStateIdNo2: customer.CaregiverMJStateIdNo2,
  CaregiverMJStateIdNo3: customer.CaregiverMJStateIdNo3,
  CaregiverMJStateIdNo4: customer.CaregiverMJStateIdNo4,
  CaregiverMJStateIdNo5: customer.CaregiverMJStateIdNo5,
  CaregiverMJStateIdNo6: customer.CaregiverMJStateIdNo6,
  CaregiverName2: customer.CaregiverName2,
  CaregiverName3: customer.CaregiverName3,
  CaregiverName4: customer.CaregiverName4,
  CaregiverName5: customer.CaregiverName5,
  CaregiverName6: customer.CaregiverName6,
  CaregiverNotes: customer.CaregiverNotes,
  CaregiverNotes2: customer.CaregiverNotes2,
  CaregiverNotes3: customer.CaregiverNotes3,
  CaregiverNotes4: customer.CaregiverNotes4,
  CaregiverNotes5: customer.CaregiverNotes5,
  CaregiverNotes6: customer.CaregiverNotes6,
  CaregiverPhone: customer.CaregiverPhone,
  CaregiverPhone2: customer.CaregiverPhone2,
  CaregiverPhone3: customer.CaregiverPhone3,
  CaregiverPhone4: customer.CaregiverPhone4,
  CaregiverPhone5: customer.CaregiverPhone5,
  CaregiverPhone6: customer.CaregiverPhone6,
  CaregiverRelationship: customer.CaregiverRelationship,
  CaregiverRelationship2: customer.CaregiverRelationship2,
  CaregiverRelationship3: customer.CaregiverRelationship3,
  CaregiverRelationship4: customer.CaregiverRelationship4,
  CaregiverRelationship5: customer.CaregiverRelationship5,
  CaregiverRelationship6: customer.CaregiverRelationship6,
  CellPhone: customer.CellPhone,
  Conditions: customer.Conditions,
  CreditNote: customer.CreditNote,
  CustomerTypeId: customer.CustomerTypeId,
  TribalAffiliation: customer.TribalAffiliation,
  CycleDate: customer.CycleDate,
  DDNumber: customer.DDNumber,
  DLExpirationDate: customer.DLExpirationDate,
  additionalStateIdentifiers: customer.additionalStateIdentifiers,
  DoctorsName: customer.doctor,
  DriversLicenseId: customer.DriversLicenseId,
  MJStateIDStartDate: customer.MJStartDate,
  MMJIDState: customer.MMJIDState,
  ExpirationDate: customer.MJExpirationDate,
  CertificationCollectionDate: customer.CertificationCollectionDate,
  CertificationExpirationDate: customer.CertificationExpirationDate,
  ExternalName: customer.ExternalName,
  FirstName: customer.FirstName,
  Group1_Id: customer.discounts?.[0]?.id,
  Group2_Id: customer.discounts?.[1]?.id,
  Group3_Id: customer.discounts?.[2]?.id,
  Group4_Id: customer.discounts?.[3]?.id,
  Group5_Id: customer.discounts?.[4]?.id,
  Group6_Id: customer.discounts?.[5]?.id,
  Guardian: customer.Guardian,
  Guest_id: customer.Guest_id,
  LastName: customer.LastName,
  MJStateIDNo: customer.MJStateIDNo,
  MiddleName: customer.MiddleName,
  NamePrefix: customer.NamePrefix,
  NameSuffix: customer.NameSuffix,
  PhoneNo: customer.PhoneNo,
  PreferredMethodOfContact: customer.PreferredMethodOfContact,
  PrimaryQualifyingConditionId: customer.PrimaryQualifyingConditionId,
  Pronoun: customer.Pronoun,
  ReferralSourceId: customer.ReferralSourceId,
  OtherReferralSource: customer.OtherReferralSource,
  SecondaryQualifyingConditionIds: customer.SecondaryQualifyingConditionIds?.filter((val) => val > 0),
  city: customer.address.city,
  country_code: customer.address.Country_Code,
  dob: customer.DOB,
  email: customer.Email,
  gender: customer.Gender,
  nickname: customer.nickname,
  CustomIdentifier: customer.CustomIdentifier,
  notes: customer.notes,
  postal_code: customer.address.postal_code,
  state: customer.address.state,
  status: customer.status,
  street: customer.address.street,
  street2: customer.address.street2,
  OptedIntoEngagementSMS: customer.OptedIntoEngagementSMS,
  OptedIntoMarketing: customer.OptedIntoMarketing,
});

export const BuildCreateCustomerRequest = (customer: CustomerDetails): CreateCustomerRequest => ({
  NamePrefix: customer.NamePrefix,
  FirstName: customer.FirstName,
  MiddleName: customer.MiddleName,
  LastName: customer.LastName,
  NameSuffix: customer.NameSuffix,
  status: customer.status,
  street: customer.address.street,
  street2: customer.address.street2,
  city: customer.address.city,
  state: customer.address.state,
  postal_code: customer.address.postal_code,
  MJStateIDNo: customer.MJStateIDNo,
  MJStateIDStartDate: customer.MJStartDate,
  MMJIDState: customer.MMJIDState,
  ExpirationDate: customer.MJExpirationDate,
  CertificationCollectionDate: customer.CertificationCollectionDate,
  CertificationExpirationDate: customer.CertificationExpirationDate,
  CustomerTypeId: customer.CustomerTypeId,
  PhoneNo: customer.PhoneNo,
  country_code: customer.address.Country_Code,
  DLExpirationDate: customer.DLExpirationDate,
  DriversLicenseId: customer.DriversLicenseId,
  DOB: customer.DOB,
  email: customer.Email,
});
