import React from 'react';

import { NoResultsIcon } from 'assets/icons/cart/NoResultsIcon';
import { TextContainer, MessageContainer, Message, Link } from './styles';

type NoMatchingCustomersProps = {
  onCreateNewProfile?: () => Promise<void>;
  search: string;
};

export const NoMatchingCustomers = ({ onCreateNewProfile, search }: NoMatchingCustomersProps) => {
  return (
    <TextContainer>
      <MessageContainer>
        <NoResultsIcon />
        <Message>No matches found for {search}</Message>
      </MessageContainer>
      {onCreateNewProfile && <Link label='Create new profile' onClick={onCreateNewProfile} />}
    </TextContainer>
  );
};
