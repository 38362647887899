import { previewManifest } from './requests';
import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';

import type { PrintJobContext } from 'util/logger/types/printing';

import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { setPrintPreviewStatus } from 'store/actions/PrintJobsActions';

import { PrintPreviewJob, PrintPreviewStatus } from 'models/Printing';
import { useAppDispatch } from 'util/hooks';

type UsePreviewManifestParams = {
  shipmentId: number;
};

export const usePreviewManifest = () => {
  const dispatch = useAppDispatch();

  return async (params: UsePreviewManifestParams) => {
    try {
      dispatch(successNotification('Generating manifest...'));
      dispatch(
        setPrintPreviewStatus({ printPreviewJob: PrintPreviewJob.MANIFEST, status: PrintPreviewStatus.GENERATING })
      );
      logger.info<PrintJobContext>('download manifest job started', {
        key: customEventKeys.printing.jobStarted,
        job: PrintPreviewJob.MANIFEST,
        requestParams: params,
        printer: { id: 'n/a', name: 'n/a', service: 'n/a', vendorId: undefined, productId: undefined },
      });

      await previewManifest(params);

      dispatch(successNotification('Manifest generated'));
      dispatch(
        setPrintPreviewStatus({ printPreviewJob: PrintPreviewJob.MANIFEST, status: PrintPreviewStatus.SUCCESSFUL })
      );
    } catch (e) {
      const message = parseErrorMessage(e);
      dispatch(errorNotification(`Error generating manifest: ${message}`));
      dispatch(setPrintPreviewStatus({ printPreviewJob: PrintPreviewJob.MANIFEST, status: PrintPreviewStatus.FAILED }));
      logger.error(e, { message: 'Failed to preview manifest', requestParams: params });
    }
  };
};
