// Transaction Adjustment Reason Types

export type TransactionAdjustmentReason = {
  Reason: string;
  TransactionAdjustmentReasonId: number;
};

// Void Reason Types

export type VoidReasonServerResponse = {
  ReasonId: number;
  Reason: string;
};

export type VoidReason = ReturnType<typeof transformToVoidReason>;

export const transformToVoidReason = (reason: VoidReasonServerResponse) => {
  return {
    reasonId: reason.ReasonId,
    reason: reason.Reason,
  };
};
