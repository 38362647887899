import React, { FC } from 'react';
import { ImageWithPlaceholder } from 'components/image';
import { ProductSelectionControls } from 'components/cart/ProductSelectionControls';
import { Loader } from 'components/backoffice/loader';
import { PricingTierGrid } from 'components/tables/PricingTierGrid';
import { useCartPopups } from 'components/CartPopups';
import { OrderActionsAddToCart } from 'components/cart/Actions';
import {
  Container,
  ProductDetailsContainer,
  ProductHeader,
  ProductTitle,
  CloseButton,
  ProductImgContainer,
  ProductDescription,
  DetailsRowContainer,
  DetailsRow,
  DetailName,
  DetailData,
  LabPopupButton,
  ProductPricingContainer,
  LoadingContainer,
  FlexColumnContainer,
  QtySelectContainer,
  PriceDetailsContainer,
  PriceContainer,
  AvailabilityLabel,
} from 'components/cart/AddItemsPanel.styles';
import { useProductPreview } from './useProductPreview';

import type { ProductSearchResult } from 'queries/v2/product/types';

type ProductPreviewProps = {
  selectedProduct?: ProductSearchResult;
  setSelectedProduct: (product?: ProductSearchResult) => void;
};

export const ProductPreview: FC<ProductPreviewProps> = ({ selectedProduct, setSelectedProduct }) => {
  const cartPopups = useCartPopups();

  const {
    closeProductPreview,
    productIdentifier,
    pricingTierDetails,
    flowerEquivalent,
    productGrams,
    getBasicPrice,
    isLoadingPricing,
    showPlusMinusBtns,
    isWeightedItem,
    qtySelected,
    gramsSelected,
    quantityAvailable,
    onQuantityControlChange,
  } = useProductPreview({ selectedProduct, setSelectedProduct });

  if (!selectedProduct) {
    return null;
  }

  return (
    <Container>
      <ProductDetailsContainer>
        <ProductHeader>
          <ProductTitle>
            {selectedProduct.productDescription}
            {productIdentifier}
          </ProductTitle>
          <CloseButton onClick={closeProductPreview} />
        </ProductHeader>
        {selectedProduct.productImageURL && (
          <ProductImgContainer>
            <ImageWithPlaceholder alt='product' src={selectedProduct.productImageURL} />
          </ProductImgContainer>
        )}
        {pricingTierDetails && (
          <FlexColumnContainer>
            <PricingTierGrid priceDetails={pricingTierDetails} />
          </FlexColumnContainer>
        )}
        <ProductDescription>{selectedProduct.description}</ProductDescription>
        <DetailsRowContainer>
          {selectedProduct.brandName && (
            <DetailsRow>
              <DetailName>Brand</DetailName>
              <DetailData>{selectedProduct.brandName}</DetailData>
            </DetailsRow>
          )}
          {selectedProduct.vendor && (
            <DetailsRow>
              <DetailName>Vendor</DetailName>
              <DetailData>{selectedProduct.vendor}</DetailData>
            </DetailsRow>
          )}
          {isWeightedItem ? (
            <DetailsRow>
              <DetailName>Weight</DetailName>
              <DetailData>{selectedProduct.totalGrams}g</DetailData>
            </DetailsRow>
          ) : (
            <>
              {flowerEquivalent && (
                <DetailsRow>
                  <DetailName>Flower Equivalent</DetailName>
                  <DetailData>{flowerEquivalent}</DetailData>
                </DetailsRow>
              )}
              {productGrams && (
                <DetailsRow>
                  <DetailName>Product Grams</DetailName>
                  <DetailData>{productGrams}</DetailData>
                </DetailsRow>
              )}
            </>
          )}
        </DetailsRowContainer>
        <LabPopupButton onClick={() => cartPopups.showLabsPopup(selectedProduct.batchId)} />
      </ProductDetailsContainer>
      <ProductPricingContainer>
        {isLoadingPricing ? (
          <LoadingContainer>
            <Loader variant='black' />
          </LoadingContainer>
        ) : (
          <>
            <QtySelectContainer>
              <PriceDetailsContainer>
                <PriceContainer data-testid='price-container'>
                  {pricingTierDetails ? (
                    <p>See Pricing Tier</p>
                  ) : (
                    <>
                      <p>Price</p>
                      <p>${getBasicPrice(selectedProduct).toFixed(2)}</p>
                    </>
                  )}
                </PriceContainer>
                <AvailabilityLabel>Available: {quantityAvailable}</AvailabilityLabel>
              </PriceDetailsContainer>
              {showPlusMinusBtns && (
                <ProductSelectionControls
                  product={selectedProduct}
                  amounts={{ count: qtySelected, grams: gramsSelected }}
                  closeProductPreview={closeProductPreview}
                  includeCartQtyInMax={false}
                  onChange={onQuantityControlChange}
                />
              )}
            </QtySelectContainer>
            {showPlusMinusBtns && (
              <OrderActionsAddToCart
                product={selectedProduct}
                closeProductPreview={closeProductPreview}
                count={isWeightedItem ? gramsSelected : qtySelected}
                usesPricingTiers={(pricingTierDetails?.length ?? 0) > 0}
              />
            )}
          </>
        )}
      </ProductPricingContainer>
    </Container>
  );
};
