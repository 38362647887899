import { useRef, useState, useEffect } from 'react';
import { debounce } from 'lodash';

type Coordinates = {
  top: number;
  left: number;
};

type UseNavigationHintParams = {
  isOpen: boolean;
};

type UseNavigationHintReturn = {
  ref: React.RefObject<HTMLDivElement>;
  coordinates: Coordinates;
};

export function useNavigationHint(params: UseNavigationHintParams): UseNavigationHintReturn {
  const { isOpen } = params;

  // refs
  const ref = useRef<HTMLDivElement>(null);

  // state
  const [coordinates, setCoordinates] = useState<Coordinates>({ top: 0, left: 0 });

  // effects
  useEffect(() => {
    const handleResize = () => {
      const sidebar = document.querySelector('[data-testid="navigation-sidebar-container"]');
      const registerItem = sidebar?.querySelector('[data-testid="register"]');
      const hintItem = ref.current;

      if (registerItem && hintItem) {
        const hintRect = hintItem.getBoundingClientRect();
        const registerItemRect = registerItem.getBoundingClientRect();

        setCoordinates({
          top: registerItemRect.top - hintRect.height / 2 + registerItemRect.height / 2,
          left: registerItemRect.right - 4,
        });
      }
    };

    const debouncedHandleResize = debounce(handleResize, 300);

    if (isOpen) {
      handleResize();

      window.addEventListener('resize', debouncedHandleResize);
    }

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [isOpen]);

  return {
    ref,
    coordinates,
  };
}
