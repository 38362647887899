export const getAllProductsKeys = {
  all: ['inventory_level_products'] as const,
};

export const getProductHistoryKeys = {
  all: ['product_history'] as const,
  one: (guestId: number) => [...getProductHistoryKeys.all, 'one', guestId] as const,
};

export const getInventoryTagsForProductSearchResultsKey = {
  all: ['inventory_tags_for_product_search_results'] as const,
  one: (packageIds: number[]) =>
    [...getInventoryTagsForProductSearchResultsKey.all, 'one', JSON.stringify(packageIds)] as const,
};

export const transactionPaymentKeys = {
  all: ['transaction-payment'] as const,
  one: (shipmentId: number) => [...transactionPaymentKeys.all, 'one', shipmentId] as const,
};
