import React from 'react';
import styled, { css } from 'styled-components';
import { HardwareTab } from './Hardware/HardwareTab';
import { isAndroid } from 'util/hooks';
import { KeyboardTab } from './Keyboard/KeyboardTab';
import { DisplaySettingsTab } from './DisplaySettings/DisplaySettingsTab';
import { useMassDphSettingsFeature } from './Hardware/MassDph/hooks/useMassDphSettingsFeature';
import { MassDphTab } from './MassDPH/MassDphTab';
import { TabbedContent, Tab } from 'components/layout';
import { colors } from 'css/Theme';
import { useBiotrackSettingsFeature } from './Biotrack/useBiotrackSettings';
import { BiotrackSettingsTab } from './Biotrack/BiotrackSettingsTab';

const useSettingsTabs = (): Tab[] => {
  const isMassDphEnabled = useMassDphSettingsFeature();
  const isBiotrackEnabled = useBiotrackSettingsFeature();

  const tabs: Tab[] = [
    {
      label: 'Hardware',
      content: (
        <SettingTabContainer>
          <HardwareTab />
        </SettingTabContainer>
      ),
    },
    {
      label: 'Display',
      content: (
        <SettingTabContainer>
          <DisplaySettingsTab />
        </SettingTabContainer>
      ),
    },
  ];

  if (isAndroid) {
    tabs.push({
      label: 'Keyboard',
      content: (
        <SettingTabContainer>
          <KeyboardTab />
        </SettingTabContainer>
      ),
    });
  }

  if (isMassDphEnabled) {
    tabs.push({
      label: 'Mass DPH',
      content: (
        <SettingTabContainer>
          <MassDphTab />
        </SettingTabContainer>
      ),
    });
  }

  if (isBiotrackEnabled) {
    tabs.push({
      label: 'BioTrack',
      content: (
        <SettingTabContainer>
          <BiotrackSettingsTab />
        </SettingTabContainer>
      ),
    });
  }

  return tabs;
};

export const SettingsTabs = () => {
  const tabs = useSettingsTabs();
  return <TabbedContent tabs={tabs} tabBarStyles={TabBarStyles} />;
};

const TabBarStyles = css`
  border-bottom: 1px solid ${colors.dutchie.gray30};
  border-top: 1px solid ${colors.dutchie.gray30};
  background-color: ${colors.white};
  padding: 0px 24px;
  position: sticky;
  top: 0;

  button {
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const SettingTabContainer = styled.div`
  padding: 1rem;
`;
