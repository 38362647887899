import React from 'react';

import { colors } from 'css/Theme';

type GroupsIconProps = {
  color?: string;
  size?: number;
  automationId?: string;
};

export const GroupsIcon = ({ color = colors.dutchie.almostBlack, size = 22, automationId }: GroupsIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 22'
      data-testid={automationId}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.2034 1.24124C6.72771 1.02685 7.28965 0.916504 7.85714 0.916504C8.42464 0.916504 8.98658 1.02685 9.51088 1.24124C10.0352 1.45562 10.5116 1.76986 10.9129 2.16599C11.3141 2.56213 11.6325 3.03241 11.8496 3.54999C12.0668 4.06757 12.1786 4.62231 12.1786 5.18253C12.1786 5.74275 12.0668 6.29749 11.8496 6.81507C11.6325 7.33265 11.3141 7.80293 10.9129 8.19907C10.5116 8.5952 10.0352 8.90944 9.51088 9.12382C8.98658 9.33821 8.42464 9.44855 7.85714 9.44855C7.28965 9.44855 6.72771 9.33821 6.2034 9.12382C5.6791 8.90944 5.20271 8.5952 4.80143 8.19907C4.40015 7.80293 4.08184 7.33264 3.86466 6.81507C3.64749 6.29749 3.53571 5.74275 3.53571 5.18253C3.53571 4.62231 3.64749 4.06757 3.86466 3.54999C4.08184 3.03241 4.40015 2.56213 4.80143 2.16599C5.20271 1.76986 5.6791 1.45562 6.2034 1.24124ZM7.85714 2.46779C7.49601 2.46779 7.13841 2.538 6.80476 2.67443C6.47112 2.81086 6.16796 3.01083 5.9126 3.26292C5.65724 3.515 5.45468 3.81427 5.31648 4.14364C5.17828 4.47301 5.10714 4.82602 5.10714 5.18253C5.10714 5.53904 5.17828 5.89205 5.31648 6.22142C5.45468 6.55079 5.65724 6.85006 5.9126 7.10214C6.16796 7.35423 6.47112 7.5542 6.80476 7.69063C7.13841 7.82705 7.49601 7.89727 7.85714 7.89727C8.21828 7.89727 8.57588 7.82705 8.90952 7.69063C9.24317 7.5542 9.54633 7.35423 9.80169 7.10214C10.0571 6.85006 10.2596 6.55078 10.3978 6.22142C10.536 5.89205 10.6071 5.53904 10.6071 5.18253C10.6071 4.82602 10.536 4.47301 10.3978 4.14364C10.2596 3.81428 10.057 3.515 9.80169 3.26292C9.54633 3.01083 9.24317 2.81086 8.90952 2.67443C8.57588 2.538 8.21828 2.46779 7.85714 2.46779Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.85714 11.7755C6.19007 11.7755 4.59127 12.4292 3.41247 13.5929C2.23367 14.7566 1.57143 16.3349 1.57143 17.9806V19.5319H14.1429V17.9806C14.1429 16.3349 13.4806 14.7566 12.3018 13.5929C11.123 12.4292 9.52422 11.7755 7.85714 11.7755ZM2.3013 12.496C3.7748 11.0414 5.7733 10.2242 7.85714 10.2242C9.94099 10.2242 11.9395 11.0414 13.413 12.496C14.8865 13.9506 15.7143 15.9235 15.7143 17.9806V20.3075C15.7143 20.7359 15.3625 21.0832 14.9286 21.0832H0.785714C0.351776 21.0832 0 20.7359 0 20.3075V17.9806C0 15.9235 0.827804 13.9506 2.3013 12.496Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.1429 2.46779C13.928 2.4678 13.7138 2.49219 13.5046 2.54049C13.0821 2.63803 12.6595 2.37896 12.5606 1.96184C12.4618 1.54472 12.7243 1.1275 13.1468 1.02996C13.4733 0.954583 13.8075 0.916515 14.1428 0.916504C15.2889 0.916504 16.3881 1.36596 17.1986 2.16599C18.009 2.96603 18.4643 4.05111 18.4643 5.18253C18.4643 6.31395 18.009 7.39903 17.1986 8.19907C16.3881 8.9991 15.289 9.44855 14.1429 9.44855C13.834 9.44845 13.5257 9.41614 13.2237 9.35218C12.7995 9.26232 12.5293 8.84993 12.6203 8.43109C12.7114 8.01224 13.1291 7.74555 13.5534 7.83542C13.7471 7.87645 13.9447 7.89718 14.1429 7.89727M14.1429 2.46779C14.8722 2.46779 15.5717 2.75381 16.0874 3.26292C16.6031 3.77203 16.8929 4.46254 16.8929 5.18253C16.8929 5.90252 16.6031 6.59303 16.0874 7.10214C15.5717 7.61119 14.8721 7.8972 14.1429 7.89727'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.9096 10.996C15.0037 10.5779 15.4234 10.3142 15.847 10.4071C17.5925 10.7899 19.1536 11.7491 20.2723 13.1261C21.3909 14.5031 22.0004 16.2155 22 17.9806C22 17.9807 22 17.9805 22 17.9806V20.3075C22 20.7359 21.6482 21.0832 21.2143 21.0832H18.0714C17.6375 21.0832 17.2857 20.7359 17.2857 20.3075C17.2857 19.8792 17.6375 19.5319 18.0714 19.5319H20.4286V17.9806C20.4289 16.5685 19.9414 15.1983 19.0464 14.0967C18.1514 12.995 16.9026 12.2277 15.5062 11.9215C15.0826 11.8285 14.8154 11.4142 14.9096 10.996Z'
        fill={color}
      />
    </svg>
  );
};
