import React from 'react';
import { HardwareService, PeripheralType } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useScannerSettings } from '../hooks/useScannerSettings';
import { SettingsSelect } from 'pages/SettingsPage/Select/SettingsSelect';
import { useAddDeviceListItem } from '../../components/AddDeviceListItem/useAddDeviceListItem';
import { useHardwareServices } from '../../hooks/useHardwareServices';

import type { SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';

export const ScannerSelectListItem = () => {
  const { formValues, scanners, search, selectScanner } = useScannerSettings();
  const { isWebHidSupported } = useHardwareServices();

  const handleChange = (option?: SettingsSelectOption) => {
    selectScanner(option?.value);
  };

  const handleRefresh = async () => {
    HardwareService.scanner.filterEnabled = true;
    search({ requestNewDevices: false });
  };

  const { handleShowAddNewDeviceModal } = useAddDeviceListItem(PeripheralType.scanner);

  return (
    <SettingsListItem
      title='Select barcode scanner'
      actions={[
        <SettingsSelect
          automationId='scanner_settings-select'
          devices={scanners}
          value={formValues.scannerId}
          placeholder='Select scanner'
          onChange={handleChange}
          onRefresh={handleRefresh}
          secondaryAction={
            isWebHidSupported ? { label: 'Add new scanner', onClick: handleShowAddNewDeviceModal } : undefined
          }
        />,
      ]}
    />
  );
};
