import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useGetAllProductsQuery } from 'queries/v2/product/product-search-v2';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';

import type { ProductSearchResult } from 'queries/v2/product/types';
import type { State } from 'store';

type FoundItem = ProductSearchResult;

type UseLookupScannedProductProps = {
  mockProducts?: ProductSearchResult[];
};

export const useLookupScannedProduct = (props?: UseLookupScannedProductProps) => {
  const customerId = useSelector((state: State) => state.customer.details?.Guest_id);
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();
  const { guestId } = useTransactionManager();

  const isQueryEnabled = useMemo(() => {
    if (isAnonymousCartLDFlagEnabled) {
      // If the guestId from the url is undefined, we are opening an empty anonymous cart
      // We should not prevent the query from running in this case
      return guestId === undefined ? true : undefined;
    } else {
      return !!customerId;
    }
  }, [guestId, customerId, isAnonymousCartLDFlagEnabled]);

  const { data: products } = useGetAllProductsQuery({ customerId, registerId }, { enabled: isQueryEnabled });

  return (scannedSerialNumber: string): FoundItem | undefined => {
    const productListToSearch = props?.mockProducts ?? products ?? [];
    const serialNumberToSearch = scannedSerialNumber.toLowerCase();
    return productListToSearch.find((product) => product.serialNo.toLowerCase() === serialNumberToSearch);
  };
};
