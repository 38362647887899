import { DutchiePayLogo } from 'assets/DutchiePayLogo';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { SectionHeader, SidePanel, SidePanelSection } from 'components/layout';
import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { CashAmount } from 'components/text';
import { colors } from 'css/Theme';
import { PaymentMethod, PaymentType } from 'models/Checkout';
import { PaymentOptions } from 'pages/CartPage/CartPanels/CheckoutSidebar/PaymentOptions';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { removePaymentMethod } from 'store/actions/CheckoutActions';
import styled, { css } from 'styled-components';
import { useDutchiePayPreAuth } from 'util/hooks/useDutchiePayPreAuth';
import { useApplyLoyaltyPoints } from '../LoyaltyPoints/useApplyLoyaltyPoints';
import { useLoyaltyCalculations } from '../LoyaltyPoints/useLoyaltyCalculations';
import { useLoyaltySettings } from '../LoyaltyPoints/useLoyaltySettings';
import { OrderDetailsSection } from './OrderDetailsSection';
import { RegisterForLoyaltyProgramsOptions } from './RegisterForLoyaltyProgramsOptions';
import { TotalDetailsSection } from './TotalDetailsSection';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { PaymentsPusherWrapper } from './PaymentsPusherWrapper';
import { usePaymentsHubPusher } from 'util/hooks/launch-darkly/usePaymentsHubPusher';
import { getIsAblyRolloutEnabled } from 'util/hooks/launch-darkly/useAblyRollout';
import { AblyChannel } from 'messaging/ably/useAblyChannel';
import { MessagingChannelType } from 'models/Messaging';
import { useOnPaymentsPusherMessage } from './useOnPaymentsPusherMessge';

export const CheckoutSidebar = (): JSX.Element | null => {
  const paymentMethods = useSelector((state: State) => state.checkout.payment?.methods);
  const totalDue = useSelector((state: State) => state.checkout.totalDue);
  const totalRemaning = useSelector((state: State) => state.checkout.totalRemaning);
  const guest = useSelector((state: State) => state.customer.details);
  const isFalseNegativePinDebitError = useSelector((state: State) => state.cart.isFalseNegativePinDebitError);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const imgRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { dutchiePayPreAuthError } = useDutchiePayPreAuth();
  const enablePaymentsHubPusher = usePaymentsHubPusher();
  const ablyRollout = getIsAblyRolloutEnabled();
  const { data: cart } = useGetCartDetails();
  const onAblyChannelMessage = useOnPaymentsPusherMessage();
  const { appliedPoints } = useLoyaltyCalculations();
  const { clearApplied } = useApplyLoyaltyPoints();
  const { showLoyaltyPaymentOption } = useLoyaltySettings();
  const showRedeemedLoyaltyPoints = appliedPoints > 0 && showLoyaltyPaymentOption;

  const filteredPaymentMethods = useMemo(() => {
    if (!paymentMethods) {
      return [];
    }

    return dutchiePayPreAuthError
      ? paymentMethods.filter((method) => method.type !== PaymentType.Dutchie)
      : paymentMethods;
  }, [dutchiePayPreAuthError, paymentMethods]);

  if (!guest) {
    return null;
  }

  const getLabel = (method: PaymentMethod) => {
    if (method.type === PaymentType.Digital) {
      return `Digital (${method.name})`;
    }
    if (method.type === PaymentType.Prepayment) {
      return `Pre Payment (${method.name})`;
    }
    if (method.type === PaymentType.Dutchie) {
      return (
        <DutchiePayLabelContainer>
          <DutchiePayLogo height={16} width={85} /> (Pre-auth)
        </DutchiePayLabelContainer>
      );
    }
    if (method.PaymentProviderType === 'paymentshub-debit') {
      return 'Debit';
    }
    if (method.type === PaymentType.Manual) {
      return `Manual (${method.data?.ManualPaymentProcessorName})`;
    }
    if (method.PaymentProviderType?.toLowerCase().startsWith('generipay')) {
      return `${method.name} (${method.PaymentProcessor})`;
    }
    return method.name;
  };

  const paymentInProgress = totalRemaning < totalDue;

  return (
    <SidePanel>
      <SidePanelSection gap='0.25rem'>
        <PaymentOptions />
        {showRedeemedLoyaltyPoints && (
          <>
            <PaymentsHeader>Loyalty</PaymentsHeader>
            <Row>
              <div>Points redeemed</div>
              <CashAmount automationId='index_loyalty-amount_payment' value={appliedPoints} />
              <DeleteIcon data-testid='remove-loyalty-payment' onClick={clearApplied} title='Remove Loyalty Points' />
            </Row>
          </>
        )}
        {isFalseNegativePinDebitError && (
          <FalseNegativePinDebitErrorContainer>
            <AlertBanner
              style={AlertBannerStyles.error}
              text={'Please follow instructions provided below to resolve.'}
              title={'DUPLICATE TRANSACTION DETECTED.'}
            ></AlertBanner>
            <FalseNegativePinDebitErrorBoldCopy>
              The original debit transaction was successful
            </FalseNegativePinDebitErrorBoldCopy>
            <FalseNegativePinDebitErrorCopy>
              Please review the payment methods below and press pay to complete the debit transaction.
            </FalseNegativePinDebitErrorCopy>
          </FalseNegativePinDebitErrorContainer>
        )}
        {filteredPaymentMethods.length > 0 && (
          <>
            <PaymentsHeader>Payments</PaymentsHeader>
            {filteredPaymentMethods.map((method) => (
              <Row data-testid='payment-options_payment-row'>
                <div>{getLabel(method)}</div>
                <CashAmount automationId='index_cash-amount_payment' value={method.amount} />
                {method.type !== PaymentType.Prepayment && method.finalized !== true && (
                  <DeleteIcon
                    data-testid='remove-icon_span_icon'
                    onClick={() => dispatch(removePaymentMethod(method.id))}
                    title='Remove Payment Method'
                  />
                )}
              </Row>
            ))}
          </>
        )}
      </SidePanelSection>

      <RegisterForLoyaltyProgramsOptions guest={guest} />
      <OrderDetailsSection setShowOrderDetails={setShowOrderDetails} showOrderDetails={showOrderDetails} cart={cart} />

      <TotalDetailsSection paymentInProgress={paymentInProgress} imgRef={imgRef} />
      {enablePaymentsHubPusher && <PaymentsPusherWrapper />}
      {ablyRollout && <AblyChannel channelType={MessagingChannelType.Payments} channelMessageCallback={onAblyChannelMessage} />}
    </SidePanel>
  );
};

const PaymentsHeader = styled(SectionHeader)`
  margin-top: 1rem;
`;

const BaseFontStyles = css`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 0.005em;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  ${BaseFontStyles}

  & > :first-child {
    margin-right: auto;
  }
`;

const DutchiePayLabelContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
`;

const FalseNegativePinDebitErrorContainer = styled.div`
  background: ${colors.dutchie.primaryWhite};
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  height: fit-content;
  margin-top: 20px;
  padding: 0 24px 24px;
`;

const FalseNegativePinDebitErrorBoldCopy = styled.p`
  align-items: center;
  color: ${colors.dutchie.almostBlack};
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  padding: 16px 0 8px;
  text-align: center;
`;

const FalseNegativePinDebitErrorCopy = styled.p`
  align-items: center;
  color: ${colors.dutchie.almostBlack};
  display: flex;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
