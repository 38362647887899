import React, { FC } from 'react';

import {
  Title,
  AllotmentHeaderContainer,
  AllotmentBarContainer,
  ProgressBar,
  Fillbar,
  AllotmentAmounts,
  ProgressBarSkeleton,
} from '../../TotalsSidebarSection.styles';
import { Skeleton } from 'components/misc';
import { useMMCEULimit } from './useMMCEULimit';

import type { PreorderCartItem } from 'pages/CreatePreOrderPage';

export type MMCEULimitProps = {
  loading?: boolean;
  preorderCartItems?: PreorderCartItem[];
};

export const MMCEULimit: FC<MMCEULimitProps> = ({ loading, preorderCartItems }) => {
  const { mmceuInCart, mmceuRemaining, over, usedPercentage } = useMMCEULimit({ preorderCartItems });

  return (
    <>
      <AllotmentHeaderContainer>
        <Title>MMCEU limit</Title>
      </AllotmentHeaderContainer>
      <AllotmentBarContainer>
        {loading ? (
          <ProgressBarSkeleton />
        ) : (
          <ProgressBar>
            <Fillbar used={usedPercentage} over={over} />
          </ProgressBar>
        )}
        {loading ? (
          <Skeleton width='150px' height='14px' />
        ) : (
          <AllotmentAmounts>
            <span data-testid='mmceu-details_in-cart-amount'>{mmceuInCart} in cart</span>
            <span>/</span>
            <span data-testid='mmceu-details_remaining-amount'>{mmceuRemaining} allowed</span>
          </AllotmentAmounts>
        )}
      </AllotmentBarContainer>
    </>
  );
};
