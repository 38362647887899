import { useLDClient } from 'launchdarkly-react-client-sdk';

import { isWebViewApp } from '../webviewApp';

export const useDeliveryPageEnhancements = () => {
  const ldClient = useLDClient();

  const isUseDeliveryPageEnhancements: boolean = ldClient?.variation(
    'pos.delivery.delivery-page-enhancements.rollout',
    false
  );

  return isUseDeliveryPageEnhancements || isWebViewApp;
};
