import React from 'react';

const originalDimensions = {
  width: 18,
  height: 18,
};

type RefreshIconProps = {
  width?: number;
  height?: number;
};

export const RefreshIcon = ({
  width = originalDimensions.width,
  height = originalDimensions.height,
}: RefreshIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none'>
      <g transform={`scale(${width / originalDimensions.width}, ${height / originalDimensions.height})`}>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.1513 9.72415C16.2812 9.80212 16.3749 9.92853 16.4116 10.0756L16.9831 12.3613C17.0596 12.6674 16.8735 12.9777 16.5673 13.0542C16.2611 13.1308 15.9509 12.9446 15.8743 12.6385L15.4415 10.9071L13.7102 11.3399C13.404 11.4165 13.0937 11.2303 13.0172 10.9242C12.9407 10.618 13.1268 10.3077 13.433 10.2312L15.7187 9.65978C15.8657 9.62302 16.0213 9.64618 16.1513 9.72415Z'
          fill='#464F53'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.0474 9.67529C16.345 9.78031 16.5012 10.1067 16.3961 10.4043C15.851 11.9491 14.8607 13.298 13.5502 14.2809C12.2396 15.2638 10.6675 15.8367 9.03181 15.9276C9.0213 15.9281 9.01077 15.9284 9.00024 15.9284C7.47505 15.9287 5.98673 15.4595 4.73746 14.5846C3.48818 13.7096 2.53849 12.4714 2.01738 11.038C1.90955 10.7414 2.06258 10.4135 2.35918 10.3057C2.65578 10.1979 2.98363 10.3509 3.09146 10.6475C3.5324 11.8604 4.33598 12.9081 5.39306 13.6485C6.44584 14.3858 7.69924 14.7826 8.98428 14.7856C10.389 14.7045 11.7387 14.2109 12.8645 13.3666C13.9945 12.5191 14.8484 11.356 15.3184 10.024C15.4234 9.72638 15.7498 9.57026 16.0474 9.67529Z'
          fill='#464F53'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M1.43297 3.94549C1.73914 3.86895 2.04939 4.0551 2.12593 4.36127L2.55877 6.09262L4.29011 5.65978C4.59628 5.58324 4.90653 5.76939 4.98307 6.07555C5.05962 6.38172 4.87347 6.69197 4.5673 6.76851L2.28158 7.33994C2.13456 7.3767 1.97895 7.35354 1.84899 7.27557C1.71904 7.1976 1.62538 7.07119 1.58863 6.92417L1.0172 4.63845C0.940654 4.33228 1.1268 4.02204 1.43297 3.94549Z'
          fill='#464F53'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M5.17754 3.67881C4.02438 4.5793 3.11828 5.78085 2.67974 6.98161C2.57148 7.27805 2.2434 7.43059 1.94696 7.32233C1.65052 7.21406 1.49798 6.88598 1.60624 6.58954C2.1277 5.16173 3.17589 3.79185 4.47415 2.77805C5.77195 1.76462 7.36655 1.07129 9.00014 1.07129H9.00174C10.5344 1.0756 12.0281 1.55385 13.2783 2.44047C14.5284 3.3271 15.4737 4.57869 15.9846 6.02367C16.0898 6.32122 15.9339 6.6477 15.6363 6.75289C15.3388 6.85809 15.0123 6.70216 14.9071 6.40462C14.4748 5.18195 13.675 4.1229 12.6171 3.37268C11.5596 2.62262 10.2959 2.21796 8.99934 2.21415C7.6846 2.21437 6.33095 2.77814 5.17754 3.67881Z'
          fill='#464F53'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.1513 9.72415C16.2812 9.80212 16.3749 9.92853 16.4116 10.0756L16.9831 12.3613C17.0596 12.6674 16.8735 12.9777 16.5673 13.0542C16.2611 13.1308 15.9509 12.9446 15.8743 12.6385L15.4415 10.9071L13.7102 11.3399C13.404 11.4165 13.0937 11.2303 13.0172 10.9242C12.9407 10.618 13.1268 10.3077 13.433 10.2312L15.7187 9.65978C15.8657 9.62302 16.0213 9.64618 16.1513 9.72415Z'
          stroke='#464F53'
          stroke-width='0.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.0474 9.67529C16.345 9.78031 16.5012 10.1067 16.3961 10.4043C15.851 11.9491 14.8607 13.298 13.5502 14.2809C12.2396 15.2638 10.6675 15.8367 9.03181 15.9276C9.0213 15.9281 9.01077 15.9284 9.00024 15.9284C7.47505 15.9287 5.98673 15.4595 4.73746 14.5846C3.48818 13.7096 2.53849 12.4714 2.01738 11.038C1.90955 10.7414 2.06258 10.4135 2.35918 10.3057C2.65578 10.1979 2.98363 10.3509 3.09146 10.6475C3.5324 11.8604 4.33598 12.9081 5.39306 13.6485C6.44584 14.3858 7.69924 14.7826 8.98428 14.7856C10.389 14.7045 11.7387 14.2109 12.8645 13.3666C13.9945 12.5191 14.8484 11.356 15.3184 10.024C15.4234 9.72638 15.7498 9.57026 16.0474 9.67529Z'
          stroke='#464F53'
          stroke-width='0.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M1.43297 3.94549C1.73914 3.86895 2.04939 4.0551 2.12593 4.36127L2.55877 6.09262L4.29011 5.65978C4.59628 5.58324 4.90653 5.76939 4.98307 6.07555C5.05962 6.38172 4.87347 6.69197 4.5673 6.76851L2.28158 7.33994C2.13456 7.3767 1.97895 7.35354 1.84899 7.27557C1.71904 7.1976 1.62538 7.07119 1.58863 6.92417L1.0172 4.63845C0.940654 4.33228 1.1268 4.02204 1.43297 3.94549Z'
          stroke='#464F53'
          stroke-width='0.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M5.17754 3.67881C4.02438 4.5793 3.11828 5.78085 2.67974 6.98161C2.57148 7.27805 2.2434 7.43059 1.94696 7.32233C1.65052 7.21406 1.49798 6.88598 1.60624 6.58954C2.1277 5.16173 3.17589 3.79185 4.47415 2.77805C5.77195 1.76462 7.36655 1.07129 9.00014 1.07129H9.00174C10.5344 1.0756 12.0281 1.55385 13.2783 2.44047C14.5284 3.3271 15.4737 4.57869 15.9846 6.02367C16.0898 6.32122 15.9339 6.6477 15.6363 6.75289C15.3388 6.85809 15.0123 6.70216 14.9071 6.40462C14.4748 5.18195 13.675 4.1229 12.6171 3.37268C11.5596 2.62262 10.2959 2.21796 8.99934 2.21415C7.6846 2.21437 6.33095 2.77814 5.17754 3.67881Z'
          stroke='#464F53'
          stroke-width='0.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  );
};
