import React from 'react';
import styled from 'styled-components';

import { ConfirmOpenCashDrawerPopup } from 'components/CartPopups/components/ConfirmOpenCashDrawerPopup';
import { Button } from 'components/buttons';
import { PageHeader, PageTitle } from 'components/layout/PageHeader/PageHeader';
import { usePopup } from 'components/popups';
import { SettingsTabs } from './Tabs/SettingsTabs';
import { useCfd } from './Tabs/Hardware/Cfd/hooks/useCfd';
import { colors } from 'css/Theme';
import { isAndroid, isWebViewApp } from 'util/hooks';
import { Row } from 'components/layout';
import { ScrollLayout, ScrollHeader, ScrollBody } from 'components/layout/ScrollLayout';

export const NewSettingsPage = () => {
  const { isVisible: isConfirmOpenCashDrawerPopupVisible, toggle: toggleConfirmOpenCashDrawerPopup } = usePopup();
  const { openCartDisplay, showCartDisplay } = useCfd();

  return (
    <SettingsPageBase>
      <ScrollLayout>
        <ScrollHeader>
          <PageHeader bottomBorder={false}>
            <PageTitle>Settings</PageTitle>
            <StyledRow>
              {showCartDisplay && !isAndroid && !isWebViewApp && (
                <Button secondary automationId='settings-open_cart_display_button' onClick={() => openCartDisplay()}>
                  Open customer-facing display
                </Button>
              )}
              <Button
                secondary
                automationId='settings-open_cash_drawer_button'
                onClick={toggleConfirmOpenCashDrawerPopup}
              >
                Open cash drawer
              </Button>
            </StyledRow>
          </PageHeader>
        </ScrollHeader>
        <ScrollBody>
          <SettingsTabs />
        </ScrollBody>
      </ScrollLayout>

      <ConfirmOpenCashDrawerPopup
        isVisible={isConfirmOpenCashDrawerPopupVisible}
        hide={toggleConfirmOpenCashDrawerPopup}
      />
    </SettingsPageBase>
  );
};

const SettingsPageBase = styled.div`
  background-color: ${colors.dutchie.grey10};
  padding: 0;
  height: 100%;
`;

const StyledRow = styled(Row)`
  gap: 12px;
`;
