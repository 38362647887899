import React from 'react';
import { ConnectionStatusPill } from 'pages/SettingsPage/Status/ConnectionStatusPill';
import { useConnectedScanner } from 'util/hooks/hardware/useConnectedHardware';
import { isNativeScanner } from '@dutchie/capacitor-peripheral';
import { isKeyboardScanner } from '@dutchie/capacitor-hardware';

export const ScannerStatusPill = () => {
  const { device } = useConnectedScanner();

  const isActionNeeded = !!device && isKeyboardScanner(device);

  return (
    <ConnectionStatusPill
      automationId='scanner_settings-connection_status'
      deviceName={device?.name}
      connected={device?.isConnected === true || (device && isNativeScanner(device)) === true}
      isActionNeeded={isActionNeeded}
    />
  );
};
