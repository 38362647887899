import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { post } from 'api/HttpHelpers';

import { closeoutDetailsKeys } from './query-key-factory';

import type { CloseoutDetails } from './types';

const GET_CLOSEOUT_DETAILS = 'posv3/backendpos/CurrentCloseoutDetails';

export type GetCloseoutDetailsPayload = {
  registerId?: number;
};

const transformToServerPayload = (payload?: GetCloseoutDetailsPayload) => {
  if (!payload) {
    return undefined;
  }

  return {
    RegisterId: payload.registerId,
  };
};

export const useCloseoutDetailsQuery = (
  payload?: GetCloseoutDetailsPayload,
  options?: UseQueryOptions<CloseoutDetails>
) => {
  return useQuery({
    queryKey: payload?.registerId ? closeoutDetailsKeys.one(payload.registerId) : closeoutDetailsKeys.all,
    queryFn: async () => {
      const response = await post<[CloseoutDetails]>(GET_CLOSEOUT_DETAILS, transformToServerPayload(payload));
      return response[0];
    },
    ...options,
  });
};
