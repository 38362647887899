import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { ImageWithPlaceholder } from 'components/image';
import { ButtonWithIcon } from 'components/buttons';
import { ReactComponent as LabBeakerIcon } from 'assets/icons/lab-results-beaker.svg';
import { useProductsPopups } from 'components/ProductsPopups';
import { PricingTierGrid } from 'components/tables/PricingTierGrid';
import { useProductsPreviewPanel } from './useProductsPreviewPanel';
import { Loader, LoadingContainer } from 'components/backoffice/loader';

import type { ProductSearchResult } from 'queries/v2/product/types';

type ProductsPreviewPanelProps = {
  selectedProduct: ProductSearchResult;
};

export const ProductsPreviewPanel: FC<ProductsPreviewPanelProps> = ({ selectedProduct }) => {
  const { flowerEquivalent, isPricingLoading, labData, pricingTierDetails, productGrams } = useProductsPreviewPanel({
    selectedProduct,
  });
  const productsPopups = useProductsPopups();

  if (!selectedProduct) {
    return null;
  }

  if (isPricingLoading) {
    return (
      <ExpandedLoadingContainer>
        <Loader variant='black' size='2x' />
      </ExpandedLoadingContainer>
    );
  }

  return (
    <>
      <ProductName data-testid='products_preview-panel_product-name'>
        {selectedProduct.productDescription.slice(0, -1)}
      </ProductName>
      <ProductImageContainer>
        <ImageWithPlaceholder alt='product' src={selectedProduct.productImageURL} />
      </ProductImageContainer>
      <>
        <ProductDescription>{selectedProduct.description}</ProductDescription>
        <DetailsRowContainer>
          {selectedProduct.brandName && (
            <DetailsRow>
              <p>Brand</p>
              <p>{selectedProduct.brandName}</p>
            </DetailsRow>
          )}
          {selectedProduct.vendor && (
            <DetailsRow>
              <p>Vendor</p>
              <p>{selectedProduct.vendor}</p>
            </DetailsRow>
          )}
          {selectedProduct.productType === 'Wgt' ? (
            <DetailsRow>
              <p>Weight</p>
              <p>{selectedProduct.totalGrams}g</p>
            </DetailsRow>
          ) : (
            <>
              {flowerEquivalent && (
                <DetailsRow>
                  <p>Flower Equivalent</p>
                  <p>{flowerEquivalent}</p>
                </DetailsRow>
              )}
              {productGrams && (
                <DetailsRow>
                  <p>Product Grams</p>
                  <p>{productGrams}</p>
                </DetailsRow>
              )}
            </>
          )}
        </DetailsRowContainer>
        {labData.length > 0 && (
          <LabsPopupButton
            secondary
            icon={LabBeakerIcon}
            label='Lab Results'
            onClick={() => {
              productsPopups.showLabsPopup(selectedProduct.batchId);
            }}
          />
        )}
        <BottomDetailsContainer>
          {pricingTierDetails ? (
            <PricingTierGrid priceDetails={pricingTierDetails} />
          ) : (
            <>
              <PriceRow>
                <p>Price</p>
                <p data-testid='products_preview-panel_product-price'>${selectedProduct.unitPrice.toFixed(2)}</p>
              </PriceRow>
              <QuantityRow>
                <p>Available</p>
                <p>{selectedProduct.totalAvailable}</p>
              </QuantityRow>
            </>
          )}
        </BottomDetailsContainer>
      </>
    </>
  );
};

const ExpandedLoadingContainer = styled(LoadingContainer)`
  height: 100%;
  width: 100%;
`;

const ProductName = styled.h2`
  font-size: 24px;
  font-weight: bold;
`;

const ProductImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  height: 200px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ProductDescription = styled.p`
  margin: 1rem 0 0;
`;

const DetailsRowContainer = styled.div`
  margin: 1rem 0 0;
`;

const DetailsRow = styled.div`
  display: flex;
  margin: 0.25rem 0;
  & p:nth-child(1) {
    margin-right: auto;
    color: ${colors.dutchie.grey};
    text-transform: uppercase;
  }
  & p:nth-child(2) {
    margin-left: auto;
  }
`;

const LabsPopupButton = styled(ButtonWithIcon)`
  font-size: 18px;
  color: ${colors.dutchie.almostBlack};
  width: 100%;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  & svg {
    height: 18px;
    width: 18px;
  }
`;

const BottomDetailsContainer = styled.div`
  border-top: 1px solid ${colors.borderGrey};
  margin: auto 0 0;
  padding-top: 1rem;
  & > div {
    display: flex;
    & p:nth-child(1) {
      width: 100px;
    }
  }
`;

const PriceRow = styled.div`
  & p {
    font-size: 20px;
    font-weight: 600;
  }
`;

const QuantityRow = styled.div`
  & p {
    font-size: 18px;
  }
`;
