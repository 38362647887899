import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { useTotalItemCount } from 'util/hooks/cart';
import { useSelector } from 'react-redux';
import { State } from 'store';
import { PrepaymentPayInfo } from 'models/Cart';
import { Skeleton } from 'components/misc';
import { useCartPopups } from 'components/CartPopups';
import { ItemCount } from 'components/cart/CartComponents.styles';
import { useAnonymousCart } from '../hooks/useAnonymousCart';
import { useTransactionManager } from '../hooks/useTransactionManager';
import { useGetCartDetails } from '../hooks/useGetCartDetails';

const CartInfoHeaderSkeleton = (): JSX.Element => {
  return (
    <CartInfoHeaderContainer>
      <CartSummary>
        Cart
        <ItemCount>{0}</ItemCount>
      </CartSummary>
      <Skeleton height={16} width={112} />
    </CartInfoHeaderContainer>
  );
};

type CartInfoHeaderProps = {
  loading?: boolean;
};

export const CartInfoHeader: FC<CartInfoHeaderProps> = ({ loading }) => {
  const cartPopups = useCartPopups();

  const guest = useSelector((state: State) => state.customer.details);

  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const { shipmentId } = useTransactionManager();

  const { data: cart } = useGetCartDetails();
  const totalItems = useTotalItemCount(cart);

  const isPreorder = !!cart.PreOrders.length && cart.PreOrders.length > 0;
  const prePaymentInfo = cart.PrepaymentPayInfo;
  let prePaymentAmount = 0;
  let prePaymentType = '';
  const isPrePaid = prePaymentInfo != null && prePaymentInfo.length > 0;

  if (isPrePaid) {
    prePaymentInfo?.forEach((item: PrepaymentPayInfo) => {
      prePaymentAmount += item.Amount;
      prePaymentType = item.PrepaymentType;
    });
  }

  const showLoadingSkeleton = isAnonymousCartLDFlagEnabled ? loading : loading || !guest;

  if (showLoadingSkeleton) {
    return <CartInfoHeaderSkeleton />;
  }

  return (
    <CartInfoHeaderContainer>
      <CartSummary>
        Cart
        <ItemCount data-testid='cart_cart-summary_item-count'>{totalItems}</ItemCount>
      </CartSummary>
      <OrderNumber data-testid='cart-context-menu_order-number_items'>
        <span data-testid='cart-context-menu_order-number_order'>
          {isPreorder ? 'Pre-' : ''}Order
          {shipmentId &&
            ` #: ${shipmentId} ${
              isPrePaid ? ` - Pre Payment: $${prePaymentAmount.toFixed(2)} (${prePaymentType})` : ''
            }`}
        </span>
        <span data-testid='cart-context-menu_order-number_reference'>
          {cart.TransactionReference && <> | Ref: {cart.TransactionReference}</>}
        </span>
        <Link onClick={() => cartPopups.showSetTransactionReferencePopup()}>Edit</Link>
      </OrderNumber>
    </CartInfoHeaderContainer>
  );
};

const CartInfoHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartSummary = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
`;

const OrderNumber = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 400;
  overflow-wrap: anywhere;
  display: flex;
  gap: 0.25rem;
`;

const Link = styled.button`
  color: ${colors.dutchie.blue};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.5%;
  background: none;
  border: none;
  padding: 0;
  padding-left: 0.25rem;
  margin: 0;
  cursor: pointer;
`;
