import { CheckedInGuest } from 'models/Guest';

type UseCustomerNameParams = {
  cardOptionsStatus: { [key: string]: boolean };
  guest: CheckedInGuest;
};
type UseCustomerNameReturn = {
  displayCustomerName: boolean;
  isBanned: boolean;
};
export const useCustomerName = ({ cardOptionsStatus, guest }: UseCustomerNameParams): UseCustomerNameReturn => {
  const displayCustomerName = cardOptionsStatus['Customer Name'];
  const isBanned = guest?.GuestStatus?.toLowerCase() === 'banned';

  return {
    displayCustomerName,
    isBanned,
  };
};
