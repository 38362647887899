import { Button } from 'components/buttons';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'css/Theme';
import { formatCountdown } from './utils/format-countdown';
import { EyeClosedIcon, EyeOpenIcon } from 'assets/icons/eye-icons';

type NewPinDisplayProps = {
  newPin: string;
  closeNewPinDisplay: () => void;
};

interface StyledNewPinDisplayProps {
  pinIsMasked: boolean;
}

interface StyledEyeIconDisplayProps {
  pinIsMasked: boolean;
  onClick: () => void;
}

export const NewPinDisplay = ({ closeNewPinDisplay, newPin }: NewPinDisplayProps): JSX.Element => {
  const [timeLeft, setTimeLeft] = React.useState<number>(120);
  const [pinIsMasked, setPinIsMasked] = React.useState<boolean>(true);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft((timeLeft) => (timeLeft > 0 ? timeLeft - 1 : 0));
    }, 1000);

    const timer = setTimeout(() => {
      closeNewPinDisplay();
    }, 120000); // close in 2 minutes

    return () => {
      clearInterval(countdown);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maskPin = (pin: string): string => {
    const maskedPin = pin ? pin.replace(/\d/g, '•') : '';
    return maskedPin;
  };

  const pinChars = pinIsMasked ? maskPin(newPin) : newPin;
  const splitChars = pinChars.split('');

  return (
    <StyledNewPinDisplay>
      <StyledNewPinDisplayText>Your new PIN</StyledNewPinDisplayText>
      <StyledCountdown>
        The screen will close in <span style={{ fontWeight: 700 }}>{formatCountdown(timeLeft)}</span>
      </StyledCountdown>
      <StyledNewPin pinIsMasked={pinIsMasked}>
        {splitChars.map((digit: string, index: number) => (
          <StyledPinChar key={index}>{digit}</StyledPinChar>
        ))}
        <StyledEyeIcon pinIsMasked={pinIsMasked} onClick={() => setPinIsMasked(!pinIsMasked)}>
          {pinIsMasked ? <EyeClosedIcon /> : <EyeOpenIcon />}
        </StyledEyeIcon>
      </StyledNewPin>
      <div style={{ maxWidth: 397, width: '100%' }}>
        <Button cta onClick={closeNewPinDisplay} marginTop={40} width='100%'>
          Return to login
        </Button>
      </div>
    </StyledNewPinDisplay>
  );
};

const StyledNewPinDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 auto;
`;

const StyledNewPin = styled.div<StyledNewPinDisplayProps>`
  border-radius: 20px;
  background-color: #06101b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ pinIsMasked }) => (pinIsMasked ? '12px 32px 20px' : '20px 32px')};
  width: 100%;
`;

const StyledPinChar = styled.span`
  margin-right: 24px;
  color: ${colors.dutchie.primaryWhite};
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
`;

const StyledCountdown = styled.div`
  color: ${colors.dutchie.primaryWhite};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 32px;
`;

const StyledNewPinDisplayText = styled.span`
  color: ${colors.dutchie.primaryWhite};
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
  margin-bottom: 32px;
`;

const StyledEyeIcon = styled.div<StyledEyeIconDisplayProps>`
  cursor: pointer;
  position: relative;
  top: ${({ pinIsMasked }) => (pinIsMasked ? '10px' : '5px')};
`;
